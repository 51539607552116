import { createFeatureActionType } from '@optx/utils/feature';
import { featureName } from '../../featureName';

export const APPLY_VIEW = createFeatureActionType(featureName, 'APPLY_VIEW');

export const APPLY_VIEW_SUCCESS = createFeatureActionType(featureName, 'APPLY_VIEW_SUCCESS');

export const RESET_VIEW_TO_SOURCING_ORDER = createFeatureActionType(
  featureName,
  'RESET_VIEW_TO_SOURCING_ORDER'
);
