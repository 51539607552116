import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// models
import { FilterTag } from '@optx/models/tags';
// redux
import { actions } from '@optx/features/pipeline-report/state';
import { selectors as userInformationSelectors } from '@redux/user/information';
// components
import { FilterTagsOverflow } from '@optx/features/tags/overflow-tags';

export const FilterTags: React.FC<{ options: FilterTag[] }> = ({ options }) => {
  const dispatch = useDispatch();
  const defaultScore = useSelector(userInformationSelectors.getDefaultScore);

  const handleFilterRemove = (tag: FilterTag) => () => {
    dispatch(actions.resetFilter({ tag, defaultScore }));
  };

  return <FilterTagsOverflow options={options} onClose={handleFilterRemove} multipleValue />;
};

export default FilterTags;
