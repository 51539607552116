import React, { useMemo } from 'react';
import { Typography } from 'antd';
import { merge, Dictionary, isEqual } from 'lodash';
import { CommonProps, GroupType } from 'react-select';
// models
import { SelectOption } from '@optx/models/Option';
// utils
import { hasGroups } from '@optx/utils/select/select';
// Local
// models
import { MultiSelectProps } from '../../interfaces';

interface MultiValueContainerProps extends CommonProps<SelectOption> {
  data: SelectOption;
}

function reduceValues(acumulator: Dictionary<string>, nextValue: SelectOption) {
  return merge(acumulator, { [nextValue.value]: nextValue.value });
}

const MultiValueContainer: React.FC<MultiValueContainerProps> = props => {
  const { selectProps, data } = props;
  const { placeholder } = selectProps as MultiSelectProps;

  const selectedValue = useMemo(() => {
    const options = selectProps.options;
    const value = selectProps.value as Array<SelectOption> | null;

    if (!value || !value.length || data.value !== value[0].value) {
      return '';
    }

    let allOptions: Array<SelectOption> = [];
    const includesGroups = hasGroups(options);
    const valuesDictionary: Dictionary<string> = value.reduce(reduceValues, {});
    const selectedGroups: Array<number> = [];

    // get all options
    if (includesGroups) {
      options?.forEach((option: SelectOption | GroupType<SelectOption>, index: number) => {
        const groupOptions = (option as GroupType<SelectOption>).options;

        if (groupOptions) {
          // grouped options
          allOptions = allOptions.concat(groupOptions);

          if (!groupOptions.some(item => valuesDictionary[item.value] === undefined)) {
            selectedGroups.push(index);
          }
        } else {
          // single option
          allOptions.push(option as SelectOption);
        }
      });
    } else {
      allOptions = [...(options as Array<SelectOption>)];
    }

    // All option
    if (includesGroups) {
      // check if we have single group selected.
      if (selectedGroups.length === 1) {
        const groupIndex = selectedGroups[0];
        const group = options![groupIndex] as GroupType<SelectOption>;
        const groupValues = group.options.reduce(reduceValues, {});

        // Set group label as value when one and only one group is selected.
        if (isEqual(groupValues, valuesDictionary)) {
          return group.label as string;
        }
      }

      // Set label value if only one options is selected.
      if (value.length === 1) {
        return value[0].label;
      }
    }

    if (value.length === 1 && value.length !== allOptions.length) {
      return value[0].label;
    }

    const someOptionsDisabled = allOptions.some(item => item.disabled);

    if (value.length === allOptions.length && !someOptionsDisabled) {
      if (value.length === 1) {
        return value[0].label;
      }

      return 'All';
    }

    return `${value.length} Selected`;
  }, [data.value, selectProps.options, selectProps.value]);

  if (selectedValue) {
    return (
      <Typography.Text ellipsis>
        {placeholder}:{' '}
        <Typography.Text style={{ color: '#1890FF' }}>{selectedValue}</Typography.Text>
      </Typography.Text>
    );
  }

  return null;
};

export default MultiValueContainer;
