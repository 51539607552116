import React, { useMemo } from 'react';
import { Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// redux
import {
  actions as CompaniesETActions,
  selectors,
} from '@optx/features/equity-touch-integration/state';
// components
import { MainHeader } from '@optx/core/layout';
import { lib } from '@features/company-search';
import ClearSearchButton from '@shared/view/molecules/Buttons/ClearSearchButton';
import FilterTags from './FilterTags';
import ShowFilterButton from './ShowFilterButton';
import ETIntegrationSearchDropdown from '../ETIntegrationSearchDropdown';

const { HeaderContent } = lib;

const Header = () => {
  const dispatch = useDispatch();
  const isClearedFilter = useSelector(selectors.filters.isCLearedFilter);

  const options = useSelector(selectors.filters.getTags);

  const onClear = () => {
    dispatch(CompaniesETActions.filters.clearFilters());
  };

  const filteredOptions = useMemo(() => options.filter(item => !item.isDefault), [options]);

  return (
    <MainHeader className="main-header fixed">
      <HeaderContent>
        <Space size={4}>
          <div style={{ width: 700 }}>
            <FilterTags options={filteredOptions} />
          </div>
          <ClearSearchButton isVisible={isClearedFilter} title="Clear All" onClick={onClear} />
          <ShowFilterButton />
          <ETIntegrationSearchDropdown />
        </Space>
      </HeaderContent>
    </MainHeader>
  );
};

export default Header;
