import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Tooltip } from 'antd';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
// utils
import { profileRedirectPath } from '@utils/routes';
// components
import Icon from '@components/common/Icon';

const TableCellCompanyName = (value: any, record: any) => (
  <CompanyNameRender name={value} companyId={record?.company_id} />
);

interface CompanyNameRenderProps {
  name: string;
  companyId?: string | number;
  showOpenInNewTab?: boolean;
  stopClickPropagation?: boolean;
}

const stopEventPropagation = (event: React.MouseEvent) => {
  event.stopPropagation();
};

export const CompanyNameRender: React.FC<CompanyNameRenderProps> = ({
  name,
  companyId,
  stopClickPropagation,
}) => {
  const location = useLocation();

  const handleClick = (event: React.MouseEvent) => {
    stopClickPropagation && stopEventPropagation(event);
  };

  return (
    <div className="d-flex">
      {companyId ? (
        <>
          <Tooltip title={name || DEFAULT_CELL_VALUE}>
            <Link
              onClick={handleClick}
              className="company-name__profile-link"
              to={profileRedirectPath(Number(companyId), location.pathname)}
            >
              {name || DEFAULT_CELL_VALUE}
            </Link>
          </Tooltip>
          <Link
            onClick={handleClick}
            className="open-in-new"
            to={profileRedirectPath(Number(companyId), location.pathname)}
            target="_blank"
          >
            <Icon iconName="open-in-new" />
          </Link>
        </>
      ) : (
        <Tooltip title={name || DEFAULT_CELL_VALUE}>
          <span className="company-name__no-link text-ellipsis">{name || DEFAULT_CELL_VALUE}</span>
        </Tooltip>
      )}
    </div>
  );
};

CompanyNameRender.defaultProps = {
  showOpenInNewTab: true,
};

export default TableCellCompanyName;
