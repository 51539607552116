import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

const BaseText = styled(Typography.Text)`
  font-size: 20px;
  white-space: normal;
`;

const Title = styled(BaseText)`
  color: #334d6e;
  font-weight: 600;
  font-size: 20px;
`;

const Subtitle = styled(BaseText)`
  font-weight: normal;
  display: block;
  color: #90a0b7;
`;

interface CardTitleProps {
  title: string;
  subtitle?: string;
}

const CardTitle: React.FC<CardTitleProps> = ({ title, subtitle }) => (
  <>
    <Title>{title}</Title>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
  </>
);

export default CardTitle;
