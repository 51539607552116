import { fork, put, takeLatest, take, select } from 'redux-saga/effects';
// models
import { PageInfo } from '@models/table/Pagination';
// redux
import searchSagas from './search';
import filters from './filters';
import { actions } from '../actions';
import { selectors } from '../selectors';
import {
  selectors as paginationSelectors,
  actions as paginationActions,
} from '@features/grid/pagination';

function* initSaga() {
  const filtersLoaded: boolean = yield select(selectors.filters.loaded);
  const outreachPagination: PageInfo = yield select(paginationSelectors.getPagination('outreach'));

  if (!filtersLoaded) {
    yield put(actions.filters.fetch());
    yield take(actions.filters.fetchSuccess);
    yield put(actions.search.fetchSourcingOutreach());
  }

  yield put(
    paginationActions.changePaginationSuccess({
      gridKey: 'outreach',
      data: { ...outreachPagination, pageNumber: 1 },
    })
  );
}

export function* saga() {
  yield fork(searchSagas);
  yield fork(filters);
  yield takeLatest(actions.init, initSaga);
}
