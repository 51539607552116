/**
 * Sets the focus on the next input element after a specified delay.
 * @param currentFocus - The CSS selector of the current input element.
 * @param nextFocus - The CSS selector of the next input element to focus on.
 * @returns void
 */
export const focusNextElActions = (currentFocus: string, nextFocus: string, delay: number = 10) => {
  setTimeout(() => {
    const button = document.querySelector(currentFocus);

    const nextInput = (button as HTMLElement)?.parentElement?.querySelector(nextFocus);

    if (nextInput) {
      (nextInput as HTMLElement)?.focus();
    }
  }, delay);
};
