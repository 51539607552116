import styled from 'styled-components';

const Container = styled.div`
  .dropdown-trigger-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  span {
    margin-left: 8px;
  }

  svg {
    margin-right: 4px;
  }
`;

const Title = styled.span`
  text-transform: capitalize;
`;

export { Container, Title };
