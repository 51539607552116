import styled from 'styled-components';

export const LegendText = styled.span`
  /* Gray */
  color: #90a0b7;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  margin: 0 15px 0 0;
  letter-spacing: 0.5px;
  font-family: 'Lato', sans-serif;
`;
