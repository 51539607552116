import React, { useState, FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
// redux
import {
  selectors as importSelectors,
  actions as importActions,
} from '@optx/features/list-import/state';
import {
  actions as favoriteListsActions,
  selectors as favoriteListsSelectors,
} from '@redux/favorite-lists/lists';
import { actions as customGlobalLoaderActions } from '@features/custom-global-loader';
// components
import { BulkEditCompletion, SimilarCompaniesModal } from './bulk-import-progress';
// styles
import { Styled } from './BulkImportWatchlist.styled';

interface BulkImportWatchlistProps {
  isModalVisible: boolean;
  setIsModalVisible: (visible: boolean) => void;
}

export const BulkImportWatchlist: FC<BulkImportWatchlistProps> = ({
  isModalVisible,
  setIsModalVisible,
}) => {
  const [confirmationVisibility, setConfirmationVisibility] = useState(false);
  const [confirmationLeaveVisibility, setConfirmationLeaveVisibility] = useState(false);
  const isInProgress = useSelector(importSelectors.isInProgress);
  const isCanceling = useSelector(importSelectors.cancel);
  const isCompleted = useSelector(importSelectors.isCompleted);
  const totalCount = useSelector(importSelectors.getImportCompaniesCount);
  const successfulCount = useSelector(importSelectors.successfulCount);

  const query = useSelector(favoriteListsSelectors.getQuery);
  const sorting = useSelector(favoriteListsSelectors.getSortBy);

  const dispatch = useDispatch();

  useEffect(() => {
    !isModalVisible && dispatch(importActions.resetProgress());
  }, [isModalVisible, dispatch]);

  const handleOk = () => {
    if (isCompleted) {
      if (totalCount - successfulCount > 0) {
        setConfirmationLeaveVisibility(true);

        return;
      }

      dispatch(importActions.resetProgress());
      setIsModalVisible(false);
    }
  };

  const handleOkConfirmation = () => {
    dispatch(importActions.cancel(true));
    dispatch(customGlobalLoaderActions.toggle({ loading: true, customText: 'Canceling...' }));
    setConfirmationVisibility(false);
  };

  const handleCancelConfirmation = () => {
    setConfirmationVisibility(false);
  };

  const handleOkLeaveConfirmation = () => {
    dispatch(
      favoriteListsActions.fetchFavoriteLists({
        query: query as string,
        sortBy: sorting,
        fetchAll: true,
      })
    );
    dispatch(importActions.resetProgress());
    setIsModalVisible(false);
    setConfirmationLeaveVisibility(false);
  };

  const handleCancelLeaveConfirmation = () => {
    setConfirmationLeaveVisibility(false);
  };

  const handleCancel = () => {
    if (isInProgress) {
      setConfirmationVisibility(true);
    } else {
      dispatch(importActions.resetProgress());
      setIsModalVisible(false);
    }
  };

  return (
    <>
      <Styled.ModalWrapper
        title="Import New Watchlist"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={840}
        okButtonProps={{ loading: isInProgress }}
        destroyOnClose
        maskClosable={false}
        cancelButtonProps={{
          disabled: isCompleted || isCanceling,
        }}
      >
        <BulkEditCompletion visibilityChange={setConfirmationVisibility} />
        <SimilarCompaniesModal />
        <Modal
          title="Confirmation"
          visible={confirmationVisibility}
          onOk={handleOkConfirmation}
          onCancel={handleCancelConfirmation}
          maskClosable={false}
        >
          Are you sure you want to cancel the operation?
        </Modal>
        <Modal
          title="Confirmation"
          visible={confirmationLeaveVisibility}
          onOk={handleOkLeaveConfirmation}
          onCancel={handleCancelLeaveConfirmation}
          maskClosable={false}
        >
          Are you sure? You will not be able to edit errors after exiting.
        </Modal>
      </Styled.ModalWrapper>
    </>
  );
};
