import { useInjectSaga } from 'redux-injectors';
import { saga } from '../../../redux/user/region-settings';

const key = 'regionSettings';

const useInjectRegionSettings = () => {
  useInjectSaga({ key, saga });
};

export default useInjectRegionSettings;
