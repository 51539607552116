import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ValueType } from 'react-select';
// models
import { SelectOption } from '@models/Option';
import { PreselectedFilter } from '@optx/models/filters';
// utils
import { getSingleSelectOptions } from '@optx/utils/filters/mapFiltersOptions';
// redux
import { selectors, actions } from '../../state';
// components
import { SingleSelect, SingleSelectProps } from '@shared/view/molecules/Select';

interface FilterSingleSelectProps extends SingleSelectProps {
  filterKey: 'il_optx_score' | 'optx_score' | 'psg_fit' | 'is_software';
}

export const FilterSingleSelect: React.FC<FilterSingleSelectProps> = ({
  filterKey,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState<Array<SelectOption>>();

  const { normalized, values } = useSelector(selectors.filters.getFilters);

  const normalizedData = normalized[filterKey];
  const value = values[filterKey];

  useEffect(() => {
    if (normalizedData) {
      const sortedOptions = getSingleSelectOptions(normalizedData as any) as any;

      setOptions(sortedOptions);
    }
  }, [normalizedData]);

  const updateValue = useCallback(
    (newValue: ValueType<SelectOption>) => {
      const findedValue = (value as Array<SelectOption>).find(
        option => option.value === (newValue as SelectOption).value
      );

      const isValueExist = !!findedValue;

      // do not work action if user select same value
      if (isValueExist) {
        return;
      }

      dispatch(
        actions.filters.updateFilters({ value: [newValue] as PreselectedFilter, key: filterKey })
      );
    },
    [dispatch, value, filterKey]
  );

  return (
    <SingleSelect
      options={options}
      onChange={updateValue}
      value={value as ValueType<SelectOption>}
      placeholder={normalizedData ? normalizedData.placeholders : undefined}
      valuePrefix={normalizedData ? `${normalizedData.placeholders}:` : undefined}
      {...restProps}
    />
  );
};
