import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
// models
import { ReferrerUrlParam, ReferrerUrlOrigin } from '@optx/models/routes';
// utils
import { getCompanyProfileURL, stringifyReferrerUrlParam } from '@optx/utils/routes';

const useReferrerProfileLink = (companyId: number) => {
  const location = useLocation();

  const searchParams: ReferrerUrlParam = queryString.parse(location.search);
  const referrer =
    searchParams.referrer === ReferrerUrlOrigin.parentCompanySearch
      ? searchParams.referrer
      : location.pathname;
  const referrerUrl = getCompanyProfileURL(companyId, stringifyReferrerUrlParam(referrer));

  return { referrerUrl, referrer };
};

export default useReferrerProfileLink;
