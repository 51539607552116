import { createAction } from '@reduxjs/toolkit';
import { OpportunityPresentation, UpdateOpportunity } from '@optx/models/Company';
import * as types from './types';
import { AddYearPayload } from './interfaces';

// fetch opportunity presentation actions
export const fetchOpportunityPresentation = createAction<number>(
  types.FETCH_OPPORTUNITY_PRESENTATION
);
export const fetchOpportunityPresentationSuccess = createAction<OpportunityPresentation | null>(
  types.FETCH_OPPORTUNITY_PRESENTATION_SUCCESS
);
export const fetchOpportunityPresentationFail = createAction<string>(
  types.FETCH_OPPORTUNITY_PRESENTATION_FAIL
);

export const setAddYearsOpenModal = createAction(types.SET_ADD_YEARS_OPEN_MODAL);

export const editAddYearsOpenModal = createAction<{ year: number }>(
  types.EDIT_ADD_YEARS_OPEN_MODAL
);

// update opportunity presentation actions
export const updateOpportunityPresentation = createAction(
  types.UPDATE_OPPORTUNITY_PRESENTATION,
  (dealData: UpdateOpportunity, isFromAuditField: boolean = false) => ({
    payload: dealData,
    meta: isFromAuditField,
  })
);
export const updateOpportunityPresentationSuccess = createAction<OpportunityPresentation>(
  types.UPDATE_OPPORTUNITY_PRESENTATION_SUCCESS
);
export const updateOpportunityPresentationFail = createAction<string>(
  types.UPDATE_OPPORTUNITY_PRESENTATION_FAIL
);

export const addFinancialYear = createAction<{
  value: AddYearPayload;
  companyId: number;
}>(types.ADD_FINANCIAL_YEAR);
export const addFinancialYearSuccess = createAction<OpportunityPresentation>(
  types.ADD_FINANCIAL_YEAR_SUCCESS
);
export const addFinancialYearFail = createAction<string>(types.ADD_FINANCIAL_YEAR_FAIL);

export const deleteFinancialYear = createAction<{ companyId: number; year: number }>(
  types.DELETE_FINANCIAL_YEAR
);

export const deleteFinancialYearSuccess = createAction<OpportunityPresentation>(
  types.DELETE_FINANCIAL_YEAR_SUCCESS
);
export const deleteFinancialYearFail = createAction<string>(types.DELETE_FINANCIAL_YEAR_FAIL);

export const editFinancialYear = createAction<{ value: AddYearPayload; companyId: number }>(
  types.EDIT_FINANCIAL_YEAR
);
export const editFinancialYearSuccess = createAction<OpportunityPresentation>(
  types.EDIT_FINANCIAL_YEAR_SUCCESS
);
export const editFinancialYearFail = createAction<string>(types.EDIT_FINANCIAL_YEAR_FAIL);
