import styled from 'styled-components';

const ScoreTrending = styled.div`
  font-weight: 600;
  text-align: center;
  display: inline-block;
  white-space: nowrap;
`;

const GrowthScore = styled.span`
  /* blue-6 */
  color: #1890ff;
  margin: 0 0 0 5px;
  font-size: 1rem;
`;

const Label = styled.div`
  /* dark blue */
  color: #334d6e;
  font-size: 1rem;
`;

export default {
  ScoreTrending,
  GrowthScore,
  Label,
};
