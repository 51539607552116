import { AdditionalStatistics } from '@optx/models/feature/analystsLeaderboard/AdditionalStatistics';
import { createAction } from '@reduxjs/toolkit';

// types
import * as types from './types';

// fetch
export const fetch = createAction(types.FETCH);
export const fetchSuccess = createAction<AdditionalStatistics>(types.FETCH_SUCCESS);
export const fetchFail = createAction<string>(types.FETCH_FAIL);
