import { Radio, RadioChangeEvent } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// redux
import { selectors, actions } from '@features/analyst-home/state';
// components
import { LineSkeleton } from '../../Skeleton';

const DateFilterButton = () => {
  const dispatch = useDispatch();

  const dates = useSelector(selectors.getActivityInsightsDates);
  const loading = useSelector(selectors.getFiltersLoading);
  const value = useSelector(selectors.getActivityInsightsDateFilter);

  const changeTabValue = (e: RadioChangeEvent) => {
    dispatch(
      actions.filters.updateFilter({
        value: e.target.value.split(','),
      })
    );
  };

  if (loading) {
    return <LineSkeleton margin={[4, 0, 4, 0]} />;
  }

  return (
    <Radio.Group value={value.toString()} onChange={changeTabValue} className="date-buttons">
      {dates.map(item => (
        <Radio.Button value={[item.start, item.end].toString()} key={item.label}>
          {item.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export default DateFilterButton;
