import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';

/**
 * function used to set default thumbs up/down value for score rationale,
 * using the value returned for optx rationale. if api returns "-", it means
 * thumbs upshould be checked, if value is null, no button should be checked,
 * if value is a string, thumbs down button should be checked
 * @param {string | null | undefined} defaultRationaleScore value for score rationale
 */
export const getRationaleDefaultValue = (defaultRationaleScore: string | null | undefined) => {
  if (defaultRationaleScore === DEFAULT_CELL_VALUE) {
    return true;
  }

  if (typeof defaultRationaleScore === 'string') {
    return false;
  }

  return undefined;
};

/**
 * function to convert string range into array of max and min values
 * @param {string} range string to be converted
 */
export const getRangeValues = (range: string) => {
  const rangeValues = [];

  if (range.includes('<')) {
    rangeValues.push(Number(range.slice(range.indexOf('<') + 1)));
  } else {
    const dashIndex = range.indexOf('-');
    const firstValue = range.slice(0, dashIndex);
    rangeValues.push(Number(firstValue));
    const secondValue = range.slice(dashIndex + 1);
    rangeValues.push(Number(secondValue));
  }

  return rangeValues;
};

/**
 * function to convert score number into string range of max and min values
 * @param {number} score number to be converted
 */
export const getRange = (score: number) => {
  let range = '< 50';

  if (score >= 50 && score <= 59) {
    return '50-59';
  } else if (score >= 60 && score <= 69) {
    return '60-69';
  } else if (score >= 70 && score <= 79) {
    return '70-79';
  } else if (score >= 80 && score <= 89) {
    return '80-89';
  } else if (score >= 90 && score <= 100) {
    return '90-100';
  }

  return range;
};

/**
 * function to determine the ratio of values
 * @param {string[]} range array of min, max range values
 * @param {number} score value for comparison
 */
export const getValuesRalation = (range: number[], score: number) => {
  let valuesRalation = 'equal';

  if (range.length === 1) {
    if (score >= range[0]) {
      valuesRalation = 'lower';
    }
  } else if (score < range[0]) {
    valuesRalation = 'upper';
  } else if (score > range[1]) {
    valuesRalation = 'lower';
  }

  return valuesRalation;
};
