import React, { useContext, useState, useEffect, useRef } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Steps, Button } from 'antd';
import { useSelector } from 'react-redux';
// constants
import ROUTES from '@optx/constants/routes';
// redux
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';
// components
import useValidateIndividualReview from '@optx/features/bulk-actions/equity-touch/hooks/useValidateIndividualReview';
import { SelectedCompaniesGrids } from '@optx/models/bulkActions';
import { HeaderWrapper, StepsWrapper, ButtonWrapper, TextCount } from '../EquityTouchBulk.styled';
import { Context } from '../common/Context';

const { Step } = Steps;

const Header: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { step, setSubmitForm, title } = useContext(Context);
  const [btnText, setBtnText] = useState('Review Each Company');
  // @ts-ignore
  const { grid } = useParams();

  const locationStateRef = useRef<Record<string, any>>({});

  const companyIds = useSelector(bulkActionsSelectors.equityTouch.getAllIds);
  const position = useSelector(bulkActionsSelectors.equityTouch.getEditCompanyPosition);

  const { validate } = useValidateIndividualReview();

  const { advancedSearchPathname } = useIsAnalyst();

  const handleGoBack = () => {
    switch (grid as SelectedCompaniesGrids) {
      case 'advancedSearch':
        return history.push(advancedSearchPathname);
      case 'myCompanies':
        return history.push(ROUTES.myCompanies);
      case 'watchlists':
        const { listId, listType } = locationStateRef.current;

        return history.push(`/${listType}/${listId}`);

      case 'companiesOutReach':
        return history.push(ROUTES.reports.sourcingOutreachSummaryCompanies);

      default:
        return history.push(advancedSearchPathname);
    }
  };

  useEffect(() => {
    if (location.state) locationStateRef.current = location.state as Record<string, any>;
  }, [location]);

  useEffect(() => {
    if (step === 0) {
      setBtnText('Review Each Company');
    } else if (step === 1 && position !== companyIds.length) {
      setBtnText('Review Next Company');
    } else if (position === companyIds.length) {
      setBtnText('Finish');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, position]);

  const handleSkip = () => {
    if (step === 0) {
      setSubmitForm('bulk');
    } else if (validate()) {
      setSubmitForm('update-and-submit');
    } else {
      setSubmitForm(null);
    }
  };

  const handleReview = () => {
    if (step === 0) {
      setSubmitForm('bulk-single');
    } else if (step === 1) {
      if (validate()) {
        setSubmitForm('single');
      } else {
        setSubmitForm(null);
      }
    }
  };

  const handlePrevious = () => {
    setSubmitForm('previous');
  };

  const handleClick = () => {
    if (step === 1) {
      setSubmitForm('single-bulk');
    }
  };

  return (
    <HeaderWrapper>
      <TextCount level={4}>{title.includes('undefined') ? 'Loading...' : title}</TextCount>
      <StepsWrapper>
        <Steps progressDot current={step}>
          <Step
            title="Step 1"
            description="Enter Bulk Values"
            onClick={handleClick}
            style={{ cursor: step === 1 ? 'pointer' : 'default' }}
          />
          <Step title="Step 2" description="Review Each Company" />
          <Step title="Step 3" description="Review and Finish" />
        </Steps>
      </StepsWrapper>
      <ButtonWrapper>
        <Button onClick={handleGoBack}>Cancel</Button>
        <Button onClick={handleSkip}>Skip Review & Finish</Button>
        {!position ||
          (position > 1 && step === 1 && (
            <Button onClick={handlePrevious}>Previous Company</Button>
          ))}
        <Button type="primary" onClick={handleReview}>
          {btnText}
        </Button>
      </ButtonWrapper>
    </HeaderWrapper>
  );
};

export default Header;
