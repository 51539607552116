import { createFeatureActionType } from '@utils/feature';
import { featureName } from '@features/grid/featureName';

export const CHANGE_LONG_CARD_PAGINATION = createFeatureActionType(
  featureName,
  'CHANGE_LONG_CARD_PAGINATION'
);

export const CHANGE_LONG_CARD_PAGINATION_SUCCESS = createFeatureActionType(
  featureName,
  'CHANGE_LONG_CARD_PAGINATION_SUCCESS'
);
