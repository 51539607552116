import { call, put, takeLatest, fork, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { Dictionary } from 'lodash';

import { UserInformation } from '@optx/models/user';
import { BaseFilter, PreselectedFilter } from '@optx/models/filters';
import mapFiltersToURLParams from '@utils/filters/mapFiltersToURLParams';
import { getErrorMessage } from '@utils/api/errors';
import { AnalystService } from '@services/api';
import {
  actions as userInformationActions,
  selectors as userInformationSelectors,
} from '@redux/user/information';
import NotificationService from '@optx/services/NotificationService';
import { UpdateValuePayload, FilterMeta } from '../filters/interfaces';
import * as actions from '../actions';
import * as selectors from '../selectors';
import { pipelineCriteria } from '../../constants/custom-criteria';

export function* searchSaga(updateSettings: boolean = true) {
  try {
    const commonValues: Dictionary<PreselectedFilter> = yield select(
      selectors.filters.getCommonValues
    );
    const criteriaValues: Dictionary<PreselectedFilter> = yield select(state =>
      selectors.filters.getValuesByCriteria(state, pipelineCriteria)
    );
    const filtersByColumn: Dictionary<BaseFilter<any>> = yield select(
      selectors.filters.getNormalizedFilters
    );
    const values: Dictionary<PreselectedFilter> = {
      ...commonValues,
      ...criteriaValues,
    };

    // the pipeline_companies_rank filter has two options, one of them having the value as "is_rank_of_5",
    // but when applying this filter api expects to receive "true". this should ideally be fixed from api, but
    // for now will use this workaround.
    if (values.pipeline_companies_rank === 'is_rank_of_5') {
      values.pipeline_companies_rank = 'true';
    }

    const criteriaQueryValues = mapFiltersToURLParams(filtersByColumn, values);
    const query = queryString.stringify(criteriaQueryValues, { arrayFormat: 'comma' });

    const res: AxiosResponse = yield call(AnalystService.getPipelineInfo, query);

    yield put(actions.pipelineInformation.fetchSuccess(res.data));

    if (updateSettings) {
      yield fork(updateUserSettings, query);
    }
  } catch (error: any) {
    const message = getErrorMessage(error, 'Failed to fetch analyst Pipeline Information!');
    yield put(actions.pipelineInformation.fetchFail(message));
  }
}

function* updateUserSettings(query: string) {
  try {
    const userSettings: UserInformation | null = yield select(
      userInformationSelectors.getUserSettings
    );

    const oldQuery = userSettings!.settings.session_settings?.analysts_leaderboard_filters;

    if (query !== oldQuery) {
      yield put(userInformationActions.updateUserSettings({ analysts_leaderboard_filters: query }));
    }
  } catch (error: any) {
    const message = getErrorMessage(error, 'Failed to update persisted filters!');
    NotificationService.error(message);
  }
}

export function* updateValueSaga(action: PayloadAction<UpdateValuePayload>) {
  let shouldSearch = action.payload.criteria === pipelineCriteria;

  if (!shouldSearch) {
    shouldSearch = yield select(state =>
      selectors.filters.isCommonFilter(state, action.payload.filterKey)
    );
  }

  if (shouldSearch) {
    yield put(actions.pipelineInformation.fetch());
    yield fork(searchSaga);
  }
}

// Clear single filter.
export function* clearFilterSaga(action: PayloadAction<FilterMeta>) {
  const { criteria } = action.payload;

  if (criteria === pipelineCriteria) {
    yield put(actions.pipelineInformation.fetch());
    yield fork(searchSaga);
  }
}

export function* clearAllFiltersSaga() {
  yield put(actions.pipelineInformation.fetch());
  // Don't update settings. They are already updated.
  yield fork(searchSaga, false);
}

export function* initSaga() {
  yield put(actions.pipelineInformation.fetch());
  yield fork(searchSaga);
}

export default function* pipelineInformationSagas() {
  yield takeLatest(actions.filters.fetchSuccess, initSaga);
  yield takeLatest(actions.filters.clearAllFilters, clearAllFiltersSaga);
  yield takeLatest(actions.filters.clearFilter, clearFilterSaga);
  yield takeLatest(actions.filters.updateValue, updateValueSaga);
}
