import { Dictionary } from 'lodash';
import { createAction, PrepareAction } from '@reduxjs/toolkit';
// models
import { PreselectedFilter } from '@optx/models/filters';
import { interfaces } from '@features/histograms/histograms-advanced-search';
// redux
import * as types from './types';

export const fetchHistogramFilters = createAction<
  PrepareAction<interfaces.FetchHistogramsActionPayload>
>(
  types.fetchHistograms.REQUEST,
  (filter: Dictionary<PreselectedFilter>, histogramList: Array<string>) => ({
    payload: { filter, histogramList },
  })
);

export const fetchHistogramFiltersSuccess =
  createAction<interfaces.FetchHistogramsSuccessActionPayload>(types.fetchHistograms.SUCCESS);

export const fetchHistogramFiltersFail = createAction<string>(types.fetchHistograms.FAILURE);

export const toggleRefresh = createAction<PrepareAction<interfaces.ToggleRefreshPayload>>(
  types.TOGGLE_REFRESH,
  (enabled: boolean, filterValues: Dictionary<PreselectedFilter>) => ({
    payload: {
      enabled,
      values: filterValues,
    },
  })
);

export const filterChanged = createAction<PrepareAction<interfaces.FilterChangedPayload>>(
  types.FILTER_CHANGED,
  (filterKey: string, values?: Dictionary<PreselectedFilter>) => ({
    payload: {
      filterKey,
      values,
    },
  })
);

export const edit = createAction<boolean>(types.EDIT);

export const applyEdit = createAction(types.APPLY_EDIT);

// Action called when histogram searches for default histogram but the default histogram is already cached.
export const resetToDefault = createAction(types.RESET_TO_DEFAULT);

export const init = createAction(types.INIT);
