/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { stringify } from 'query-string';
// models
import { DropdownAddProps } from '@models/DropdownAdd';
import { CompanyNotification, Company } from '@models/Company';
// utils
import { convertToURL } from '@utils/url';
import { iframePostMessage } from '@optx/utils/window';
import extensionStorage from '@optx/storage/localStorage/extensionStorage';
import { getAddToSalesLoftURL } from '@optx/utils/routes';
// hooks
import { useInjectLogin } from '@optx/common/hooks/inject';
import { useDropdownAdd } from '@components/DropdownAdd/useDropdownAdd';
// redux
import { selectors as userSelectors, actions as userActions } from '@redux/user/information';
import { selectors } from 'src/components/feature/company-individual-edit/state/index';
import { actions as cognitoLogoutActions } from '@redux/auth/cognitoLogout';
import { actions as modalActionsAddResearch } from '@optx/features/add-research-rationale/state';
// components
import { ClaimAsOwnerMenuItem } from '@features/company/claim-as-owner';
import { LoadingBlock } from '@optx/shared/view/molecules/loader';
import AddCompanyToListPopover from '@components/DropdownAdd/AddCompanyToListPopover/AddCompanyToListPopover';
import AddResearchRationale from '@optx/features/add-research-rationale';
import LogOut from './LogOut';
// style
import Styled from './styled/Header.styled';

const ActionDropdown: React.FC<DropdownAddProps> = ({ record, onLogOut, className, index }) => {
  const {
    is_in_et: isInEt,
    et_url: companyEtUrl,
    links,
    note_unique_id: noteId,
    company_id: companyId,
    company_name: companyName,
    company_owner: companyOwnerName,
  } = record as CompanyNotification & Company;
  const etUrl = (links?.['Equity Touch'] || companyEtUrl) ?? '';
  const isLoading = useSelector(selectors.companyIndividualEdit.isLoadingInfo);
  const getIsPartnerOnly = useSelector(userSelectors.getIsPartnerOnly);
  const userLastName = useSelector(userSelectors.getLastName);

  useInjectLogin();
  const dispatch = useDispatch();

  const company = record as Company;
  const {
    toggleSelectList,
    openDropdownSelectList,
    toggleCompanyTouchesModal,
    toggleEditAllModal,
  } = useDropdownAdd(companyId, companyName, company, index, noteId);

  const extensionDisplay: boolean = extensionStorage.isDisplay() !== 'false';

  const fetchCognitoLogoutURL = () => {
    dispatch(cognitoLogoutActions.fetchCognitoLogoutURL());
  };

  const [isHidden, setIsHidden] = useState(false);
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [isDisplay, setDisplay] = useState(Boolean(extensionDisplay));
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const sendExtensionStatus = () =>
    setInterval(() => {
      const extensionDisplay: boolean = extensionStorage.isDisplay() !== 'false';
      setDisplay(Boolean(extensionDisplay));
      iframePostMessage('hide-optx-extension', Boolean(extensionDisplay));
    }, 5000);

  const hideOPTX = () => {
    iframePostMessage('hide-optx-extension', !isDisplay);
    setDisplay(!isDisplay);
    extensionStorage.toggleDisplay(!isDisplay);
  };

  const handleResearchRationale = () => {
    setDropdownVisible(false);
    iframePostMessage('open-drawer', true);
    setOpenDrawer(true);
    dispatch(modalActionsAddResearch.toggleAddResearchRationale({ companyId }));
  };

  const handleLogout = () => {
    if (isOpenDrawer && !dropdownVisible) {
      handleResearchRationale();
    }

    onLogOut && onLogOut();
  };

  useEffect(() => {
    if (companyOwnerName?.endsWith(userLastName)) {
      setIsHidden(true);
    } else {
      setIsHidden(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyOwnerName]);

  useEffect(() => {
    sendExtensionStatus();
    fetchCognitoLogoutURL();
    iframePostMessage('open-drawer', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const companyInfoParams = stringify(
    { id: companyId, name: companyName, url: record.company_url },
    { arrayFormat: 'comma' }
  );

  const handleClick = () => {
    dispatch(
      userActions.updateUserSettings({
        et_form_auto_save: null,
      } as Partial<any>)
    );

    const etWindow = window.open(`/add-to-equity-touch/${companyId}?ref=extension`, '_blank');
    setInterval(() => {
      if (etWindow && etWindow.closed) {
        window.location.reload();
      }
    }, 1000);
  };

  const hasTouchAccess = useSelector(userSelectors.getHasAccessToTouches);

  const menu = (
    <>
      {isLoading && isInEt && !companyOwnerName?.endsWith(userLastName) && !isHidden && (
        <LoadingBlock loading={isLoading} />
      )}
      <Styled.ActionMenu selectedKeys={[]}>
        {isInEt && hasTouchAccess && (
          <Menu.Item
            className="ant-dropdown-menu-item"
            onClick={() => {
              setDropdownVisible(false);
              toggleCompanyTouchesModal();
            }}
          >
            Add Touch Note
          </Menu.Item>
        )}

        <Menu.Item
          className="ant-dropdown-menu-item"
          onClick={() => {
            setDropdownVisible(false);
            toggleEditAllModal();
          }}
        >
          Edit Company Info
        </Menu.Item>

        {!getIsPartnerOnly && (
          <Menu.Item className="ant-dropdown-menu-item" onClick={handleResearchRationale}>
            Verify Key Info
          </Menu.Item>
        )}

        {!isInEt && (
          <Menu.Item className="ant-dropdown-menu-item" onClick={handleClick}>
            Add to Equity Touch
          </Menu.Item>
        )}

        {isInEt && etUrl && (
          <Menu.Item className="ant-dropdown-menu-item">
            <a href={convertToURL(etUrl)} target="_blank" rel="noopener noreferrer">
              Open in Equity Touch
            </a>
          </Menu.Item>
        )}

        {isInEt && !companyOwnerName?.endsWith(userLastName) ? (
          <ClaimAsOwnerMenuItem companyId={companyId} companyOwnerName={companyOwnerName} />
        ) : (
          ''
        )}

        <Menu.Item className="ant-dropdown-menu-item">
          <Link to={getAddToSalesLoftURL(companyId, companyInfoParams)} target="_blank">
            Add to Sales Loft
          </Link>
        </Menu.Item>
        <Menu.Item
          className="ant-dropdown-menu-item"
          onClick={() => {
            setDropdownVisible(false);
            toggleSelectList();
          }}
        >
          Add to List
        </Menu.Item>
        <Menu.Item className="ant-dropdown-menu-item" onClick={hideOPTX}>
          {extensionDisplay != true ? 'Show OPTX Tab' : 'Hide OPTX Tab'}
        </Menu.Item>
        <LogOut onClick={handleLogout} />
      </Styled.ActionMenu>
    </>
  );

  return (
    <div
      className={`dropdown-block dropdown-tags empty-popover-wrapper ${
        isOpenDrawer ? 'open-drawer' : ''
      }`}
    >
      <Dropdown
        overlay={menu}
        trigger={['click']}
        placement="bottomRight"
        className={className}
        onVisibleChange={setDropdownVisible}
        visible={dropdownVisible}
      >
        <Button id="action-button" className="extension--header-action" type="primary">
          Actions <DownOutlined width={9} height={6} />
        </Button>
      </Dropdown>
      <AddCompanyToListPopover
        companyId={companyId}
        isOpen={openDropdownSelectList}
        toggle={toggleSelectList}
        isExtension
      />
      <AddResearchRationale isInChromeExtension />
    </div>
  );
};

export default ActionDropdown;
