// common reusable reducers
import { PayloadAction } from '@reduxjs/toolkit';

type FetchState = Required<{
  loading: boolean;
  error: string | null;
}>;

type FetchSuccessLoading = Required<{
  loading: boolean;
  error: string | null;
}>;

type FetchSuccessState = FetchState & { data: any };

/**
 * Reducer to handle default fetch call response.
 * @param draftState
 */
export const fetchReducer = (draftState: FetchState) => {
  draftState.loading = true;
  draftState.error = '';
};

/**
 * Reducer to handle default fetch success call response.
 * @param draftState
 * @param action
 */
export const fetchSuccessReducer = (draftState: FetchSuccessState, action: PayloadAction<any>) => {
  draftState.loading = false;
  draftState.data = action.payload;
};

/**
 * Reducer to handle default fetch success call response
 * for cases where we only need to change the loading
 * @param draftState
 */
export const fetchSuccessLoadingReducer = (draftState: FetchSuccessLoading) => {
  draftState.loading = false;
};

/**
 * Reducer to handle default fetch error call response.
 * @param draftState
 * @param action
 */
export const fetchFailReducer = (draftState: FetchState, action: PayloadAction<string>) => {
  draftState.loading = false;
  draftState.error = action.payload;
};
