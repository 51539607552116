export const TOGGLE_DOCUMENT_UPLOAD_MODAL = 'TOGGLE_DOCUMENT_UPLOAD_MODAL';

export const UPDATE_FILE_LIST = 'UPDATE_FILE_LIST';
export const UPDATE_FILE_ENCODING_LIST = 'UPDATE_FILE_ENCODING_LIST';
export const REMOVE_FILE_ENCODING = 'REMOVE_FILE_ENCODING';

export const UPLOAD_FILES = 'UPLOAD_FILES';
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';
export const UPLOAD_FILES_ERROR = 'UPLOAD_FILES_ERROR';

export const FAILED_UPLOAD_FILES = 'FAILED_UPLOAD_FILES';
