import React from 'react';
import { Tag } from 'antd';
import { TagProps } from 'antd/lib/tag';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// models
import { SpecialFilterKey } from '@optx/models/filters';
// utils
import { searchInNewTab } from '@optx/utils/search';
// redux
import { actions as searchActions } from '@features/grid/search';
import { selectors as filterSourcesSelectors } from '@redux/company/filters';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';

interface IsProfolioTagProps extends TagProps {
  isPortfolio: boolean;
  newTab?: boolean;
}

const filterKey: SpecialFilterKey = 'is_platform';

const IsProfolioTag: React.FC<IsProfolioTagProps> = ({
  isPortfolio,
  newTab,
  onClick,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const clearedFilter = useSelector(filterSelectors.getClearedFilter('advancedSearch'));
  const sources = useSelector(filterSourcesSelectors.getFiltersMap);
  const { advancedSearchPathname } = useIsAnalyst();

  if (!isPortfolio) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (newTab) {
      searchInNewTab(clearedFilter, sources, filterKey, '1', {}, advancedSearchPathname);
    } else {
      history.push(advancedSearchPathname);
      dispatch(
        searchActions.loadCompaniesByFilter(filterKey, { gridKey: 'advancedSearch', data: '1' })
      );
      onClick && onClick(event);
    }
  };

  return <Tag className="special-tag show-child" onClick={handleClick} {...restProps} />;
};

export default IsProfolioTag;
