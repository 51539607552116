import { Table as ANTDTable } from 'antd';
import styled, { css } from 'styled-components';
import { TableProps } from 'antd/es/table';
import { styledColors } from '@optx/utils/theme/colors';
import { Styled as StyledInlineEdit } from '@shared/view/molecules/edit/InlineEdit';

interface Props extends TableProps<any> {
  $wrapTableHead?: boolean;
  /**
   * Forces body cells to show ellipsis if text overflows cell.
   */
  $forceCellEllipsis?: boolean;
  isInEt?: boolean;
}

// Styles to wrap table header th elements even when ellipsis is set in column configuration.
const wrapTableHeadEllipssis = css`
  .ant-table-thead {
    .ant-table-cell-ellipsis {
      white-space: normal;
    }
    .ant-table-cell-content {
      margin-right: -8px;
      padding-right: 8px;
    }
  }
`;

const Table = styled<React.FC<Props>>(ANTDTable)`
  .ant-table {
    color: ${styledColors.gray8};
    width: 100%;

    .ant-table-body {
      overflow-x: scroll;
    }
  }

  .ant-table-tbody {
    > tr {
      td {
        /* transition: none; */
      }

      &.ant-table-row:hover {
        > td {
          background: ${styledColors.blue1};
        }
      }
    }
  }

  .ant-table-column-sorters {
    width: 100%;

    > span:first-child {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ant-table-column-sorter {
    margin-top: 0;
    margin-right: -8px;
    margin-left: 2px;
    line-height: 1;
  }

  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    font-size: 18px;
    display: none;

    &.active {
      display: inline-block;
    }
  }

  .ant-table-column-sorter-down {
    margin-top: 0;
  }

  /* sort backgrounds */
  td.ant-table-column-sort {
    background: #fff;
  }

  .ant-table-thead > tr > th {
    background: #fafafa;

    &.ant-table-column-sort {
      background: #f5f5f5;
    }
  }

  th.ant-table-cell {
    /* color: #616f82; */

    &:before {
      display: inline-block;
      content: attr(data-order-priority);
      position: absolute;
      top: 2px;
      right: 6px;
      font-size: 12px;
      color: ${styledColors.blue6};
    }
  }

  /* row hover */
  .ant-table-row {
    :hover {
      ${StyledInlineEdit.ActionWrapper}, .edit-popover-wrapper .icon-pen {
        visibility: visible;
      }
    }
  }

  .ant-table-thead {
    tr {
      /* Add otional flag later to force th text alignment in head */
      th {
        /* Alignment is added directly to element and needs override */
        text-align: left !important;

        /* Remove transition causing header cells delay in firefox on horizontal scroll. */
        transition: none;
      }
    }
  }

  ${props => props.$wrapTableHead && wrapTableHeadEllipssis}

  /* bordered virtual table */
  ${props =>
    props.bordered &&
    css`
      .ant-table.ant-table-bordered
        > .ant-table-container
        > .ant-table-header
        table
        > tbody
        > tr
        > th,
      .ant-table.ant-table-bordered
        > .ant-table-container
        > .ant-table-body
        table
        > tbody
        > tr
        > td {
        border-right: 1px solid #f0f0f0;
      }
    `}

    ${props =>
    props.$forceCellEllipsis &&
    css`
      /* Force cell ellipsis without breaking sticky column shadow */
      .ant-table-tbody {
        .ant-table-cell {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-break: keep-all;

          > * {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: keep-all;
          }
        }
      }
    `}

    @media (min-width: 1700px) {
    .ant-table {
      overflow-y: ${props => props.isInEt && 'scroll'};
      background: ${props => (props.isInEt ? 'transparent' : 'white')};

      &::-webkit-scrollbar {
        visibility: hidden;
      }
    }
  }

  @media (min-width: 0px) {
    .ant-table {
      scrollbar-width: none;
      &::-webkit-scrollbar {
        visibility: hidden;
      }
    }
  }
`;

Table.defaultProps = {
  $wrapTableHead: true,
  pagination: false,
  showSorterTooltip: false,
};

export default Table;
