import React, { ChangeEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Typography, Radio, Button, Drawer, Divider } from 'antd';
import { Form } from 'formik-antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import { MenuInfo } from 'rc-menu/lib/interface';
// models
import { AddYearPayload } from '@optx/redux/company/opportunity-presentation/interfaces';
import { SelectOption } from '@optx/models/Option';
import { FinancialFieldRangeValue } from '@optx/models/Company';
import { AllFinancialYearsType } from '@optx/models/Financial';
// constants
import {
  ONLY_DIGITS_ERROR_MESSAGE,
  ONLY_DIGITS_AND_PERCENTAGE_ERROR_MESSAGE,
  ONLY_DIGITS_AND_OPTIONS_ERROR_MESSAGE,
  message,
} from '@optx/constants/table/cells';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { generateYears, isOptionChecked } from '@optx/utils/option';
import { checkFinancialValues, financialYearValidationSchema } from '@optx/utils/proprietaryInfo';
import { validateField } from '@utils/editFields';
// redux
import { selectors, actions } from '@redux/company/opportunity-presentation';
import { getFinancialFilterOptions } from '@optx/redux/company/filters/selectors';
// components
import InputWithDropdown from '@optx/shared/view/molecules/FormFields/InputWithDropdown';
import * as Styled from '../styles/DealPresentation.styled';

interface AddFinancialYearModalProps {
  companyId: number;
  isOpen: boolean;
  isChromeExtension?: boolean;
  toggle: VoidFunction;
}

const AddFinancialYearModal: React.FC<AddFinancialYearModalProps> = ({
  companyId,
  isOpen,
  toggle,
  isChromeExtension,
}) => {
  const formRef = useRef<FormikProps<any> | null>(null);
  const dispatch = useDispatch();
  const currentEditableYear = useSelector(selectors.getCurrentEditableYear);
  const ebitdaOptions: SelectOption[] = useSelector(getFinancialFilterOptions('ebitda_numeric'));
  const revenueOptions: SelectOption[] = useSelector(
    getFinancialFilterOptions('last_rev_update_amount')
  );
  const arrOptions: SelectOption[] = useSelector(getFinancialFilterOptions('last_arr_value'));
  const gmOptions: SelectOption[] = useSelector(getFinancialFilterOptions('last_gross_margin'));
  const currentFinancialYearInfo = useSelector(selectors.getCurrentFinancialYearInfo);

  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [isYearExists, setYearExists] = useState<boolean>(false);
  const [showYearError, setShowYearError] = useState<boolean>(false);
  const getAllFinancialYears = useSelector(selectors.getAllFinancialYears);
  const [values, setValues] = useState<{ year: string | number | null; estimated: boolean | null }>(
    {
      year: '',
      estimated: null,
    }
  );

  const [isSelectOpen, setSelectOpen] = useState<boolean>(false);
  const isEditable = currentEditableYear !== null;

  const listOfYears = generateYears(
    undefined,
    getAllFinancialYears,
    formRef.current?.values.estimated,
    true
  );

  const options = useMemo(
    () => ({
      ebitda: ebitdaOptions,
      revenue: revenueOptions,
      arr: arrOptions,
      gm: gmOptions,
    }),
    [arrOptions, ebitdaOptions, gmOptions, revenueOptions]
  );

  const initialValues: {
    year: number | string | null;
    estimated: boolean | null;
    arr: number | string | FinancialFieldRangeValue;
    revenue: number | string | FinancialFieldRangeValue;
    ebitda: number | string | FinancialFieldRangeValue;
    gm: number | string | FinancialFieldRangeValue;
  } = Object.keys(currentFinancialYearInfo).reduce(
    (acc, key) => {
      return {
        ...acc,
        [key]:
          key === 'estimated'
            ? currentFinancialYearInfo[key]
            : checkFinancialValues(
                //@ts-ignore
                currentFinancialYearInfo[key] as string,
                key,
                listOfYears,
                isEditable,
                //@ts-ignore
                options[key] ?? []
              ),
      };
    },
    {
      year: null,
      estimated: null,
      arr: '',
      revenue: '',
      ebitda: '',
      gm: '',
    }
  );

  const handleOk = useCallback(() => {
    if (!values.year) {
      setShowYearError(true);
    }

    if (formRef.current) {
      values.year = '';
      formRef.current.submitForm();
    }
  }, [values]);

  const getFinancialValue = useCallback(
    (fieldName: 'ebitda' | 'revenue' | 'arr' | 'gm') => {
      const value = formRef.current?.values[fieldName];

      if (value === '') {
        return DEFAULT_EMPTY_VALUE;
      }

      if (!isNaN(Number(value)) && fieldName !== 'gm') {
        return value * 1000000;
      }

      const isFromDropdown = isOptionChecked(value, options[fieldName]);

      if (isFromDropdown) {
        const option = options[fieldName].find(item => item.value === value);

        if (option) {
          return option.rangeValue ?? option.value;
        }
      }

      return value;
    },
    [options]
  );

  const onSubmit = useCallback(() => {
    const payload: AddYearPayload = {
      ebitda: {
        estimated: formRef.current?.values.estimated,
        value: getFinancialValue('ebitda'),
        year: formRef.current?.values.year,
      },
      revenue: {
        estimated: formRef.current?.values.estimated,
        value: getFinancialValue('revenue'),
        year: formRef.current?.values.year,
      },
      annual_recurring_revenue: {
        estimated: formRef.current?.values.estimated,
        year: formRef.current?.values.year,
        value: getFinancialValue('arr'),
      },
      gm: {
        estimated: formRef.current?.values.estimated,
        year: formRef.current?.values.year,
        value: getFinancialValue('gm'),
      },
    };
    setButtonDisabled(true);
    setSelectOpen(false);
    setButtonDisabled(false);
    toggle();

    if (isEditable) {
      dispatch(
        actions.editFinancialYear({
          companyId,
          value: payload,
        })
      );
    } else {
      dispatch(
        actions.addFinancialYear({
          companyId,
          value: payload,
        })
      );
    }
  }, [getFinancialValue, toggle, isEditable, dispatch, companyId]);

  const handleChange = (value: number | string | boolean, type: 'year' | 'estimated') => {
    if (Number.isNaN(Number(value))) {
      return message().error(ONLY_DIGITS_ERROR_MESSAGE);
    }

    let formattedValue: any;

    if (type === 'year') {
      formattedValue = value ? Number(value) : null;
    } else {
      formattedValue = value;
    }

    setValues(prev => ({ ...prev, [type]: formattedValue }));

    formRef.current?.setFieldValue(type, formattedValue, true);
  };

  useEffect(() => {
    if (!isOpen) {
      formRef.current?.resetForm();
    }

    if (isOpen) {
      setValues({
        year: initialValues.year,
        estimated: initialValues.estimated,
      });
      setTimeout(() => {
        setSelectOpen(true);
      }, 700);
    }
  }, [initialValues.estimated, initialValues.year, isOpen, values.estimated]);

  useEffect(() => {
    if (values.year) {
      const findYear = getAllFinancialYears.filter(
        (item: AllFinancialYearsType) => item.year === values.year
      );

      // if user select year that already exists
      if (findYear.length === 1 && !isEditable) {
        setButtonDisabled(true);
        setYearExists(true);
      } else {
        setButtonDisabled(false);
        setYearExists(false);
        formRef.current?.setFieldError('year', undefined);
      }

      setShowYearError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter' && formRef.current?.values.estimated !== null) {
      onSubmit();
    }
  };

  const handleSelectOption = (event: MenuInfo) => {
    handleChange(Number(event.key), 'year');
  };

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    handleChange(value, 'year');

    if (value === '') {
      setButtonDisabled(true);
    }
  };

  const handleCancel: React.MouseEventHandler<HTMLButtonElement> = () => {
    toggle();
    values.year = '';
    setShowYearError(false);
  };

  const drawerTitle = (
    <Row>
      <Typography.Text style={{ fontWeight: 'bold' }}>
        {currentEditableYear ? `Edit ${currentEditableYear}` : 'Add New Year'}
      </Typography.Text>
      <div style={{ marginLeft: currentEditableYear ? '22rem' : '20rem' }}>
        <Button style={{ marginRight: '12px' }} className="btn-cancel" onClick={handleCancel}>
          CANCEL
        </Button>
        <Button type="primary" className="btn-send" onClick={handleOk} disabled={isButtonDisabled}>
          SAVE
        </Button>
      </div>
    </Row>
  );

  const modalContent = (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={financialYearValidationSchema({
        ebitdaOptions,
        revenueOptions,
        arrOptions,
        gmOptions,
      })}
      innerRef={instance => {
        formRef.current = instance;
      }}
    >
      {({ setFieldValue }) => (
        <Form layout="vertical" onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
          <Field name="estimated">
            {({ field, form }: FieldProps) => (
              <Radio.Group
                {...field}
                options={[
                  { label: 'Actual', value: false },
                  { label: 'Estimated', value: true },
                ]}
                style={{ marginTop: '8px', marginBottom: '16px' }}
                onChange={e => handleChange(e.target.value, 'estimated')}
                optionType="button"
                buttonStyle="solid"
              />
            )}
          </Field>
          <Row>
            <Col span={isChromeExtension ? 5 : 8}>
              <Typography.Text>Year</Typography.Text>
              <Field name="year">
                {({ field, form }: FieldProps) => (
                  <InputWithDropdown
                    {...field}
                    options={
                      currentEditableYear
                        ? generateYears(formRef.current?.values.year)
                        : generateYears(
                            formRef.current?.values.year,
                            getAllFinancialYears,
                            formRef.current?.values.estimated,
                            true
                          )
                    }
                    onInputChange={handleInput}
                    onSelectOption={handleSelectOption}
                    placeholder="Select Year"
                    open={isSelectOpen}
                    dropDownStyle={{ display: 'block', width: 180 }}
                  />
                )}
              </Field>
              {formRef.current?.values.year === null && (
                <span className="error-message">Field is required!</span>
              )}
              {isButtonDisabled && isYearExists && (
                <Styled.ErrorMessage style={{ marginTop: '180px' }}>
                  Year already exists
                </Styled.ErrorMessage>
              )}
              {showYearError && (
                <Styled.ErrorMessage style={{ marginTop: '180px' }}>
                  Field is required!
                </Styled.ErrorMessage>
              )}
            </Col>
            <Col span={isChromeExtension ? 5 : 8}>
              <Col>Arr Amount</Col>
              <Col style={{ position: 'relative' }}>
                <Field name="arr">
                  {({ field, form }: FieldProps) => (
                    <InputWithDropdown
                      {...field}
                      options={arrOptions}
                      showDropdown
                      customWidth="auto"
                      placeholder="in millions of dollars"
                      onSelectOption={event => setFieldValue('arr', event.key)}
                    />
                  )}
                </Field>
                {formRef.current?.values.arr !== null &&
                  !validateField(formRef.current?.values.arr, 'ARR', arrOptions, false) && (
                    <span className="error-message error-message-right" style={{ color: 'red' }}>
                      {ONLY_DIGITS_ERROR_MESSAGE}
                    </span>
                  )}
              </Col>
              <Col style={{ marginTop: '16px' }}>Revenue Amount</Col>
              <Col style={{ position: 'relative' }}>
                <Field name="revenue">
                  {({ field, form }: FieldProps) => (
                    <InputWithDropdown
                      {...field}
                      options={revenueOptions}
                      showDropdown
                      customWidth="auto"
                      placeholder="in millions of dollars"
                      onSelectOption={event => setFieldValue('revenue', event.key)}
                    />
                  )}
                </Field>
                {formRef.current?.values.revenue !== null &&
                  !validateField(
                    formRef.current?.values.revenue,
                    'Revenue',
                    revenueOptions,
                    false
                  ) && (
                    <span className="error-message error-message-right" style={{ color: 'red' }}>
                      {ONLY_DIGITS_ERROR_MESSAGE}
                    </span>
                  )}
              </Col>
              <Col style={{ marginTop: '16px' }}>GM%</Col>
              <Col style={{ position: 'relative' }}>
                <Field name="gm">
                  {({ field, form }: FieldProps) => (
                    <InputWithDropdown
                      {...field}
                      options={gmOptions}
                      showDropdown
                      customWidth="auto"
                      placeholder="Value"
                      onSelectOption={event => setFieldValue('gm', event.key)}
                    />
                  )}
                </Field>
                {formRef.current?.values.gm !== null &&
                  !validateField(formRef.current?.values.gm, 'GM %', gmOptions, false) && (
                    <span className="error-message error-message-right" style={{ color: 'red' }}>
                      {ONLY_DIGITS_AND_PERCENTAGE_ERROR_MESSAGE}
                    </span>
                  )}
              </Col>
              <Col style={{ marginTop: '16px' }}>EBITDA</Col>
              <Col style={{ position: 'relative' }}>
                <Field name="ebitda">
                  {({ field, form }: FieldProps) => (
                    <InputWithDropdown
                      {...field}
                      options={ebitdaOptions}
                      showDropdown
                      customWidth="auto"
                      placeholder="in millions of dollars"
                      onSelectOption={event => setFieldValue('ebitda', event.key)}
                    />
                  )}
                </Field>
                {formRef.current?.values.ebitda !== null &&
                  !validateField(
                    formRef.current?.values.ebitda,
                    'EBITDA',
                    ebitdaOptions,
                    false
                  ) && (
                    <span className="error-message error-message-right" style={{ color: 'red' }}>
                      {ONLY_DIGITS_AND_OPTIONS_ERROR_MESSAGE}
                    </span>
                  )}
              </Col>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );

  if (isChromeExtension) {
    return (
      <Drawer
        title={drawerTitle}
        placement="bottom"
        width={'640px'}
        height={'calc(100% - 75px)'}
        visible={isOpen}
        onClose={() => {
          setSelectOpen(false);
          setTimeout(() => {
            setButtonDisabled(false);
            setShowYearError(false);
            toggle();
          }, 100);
        }}
      >
        {modalContent}
        <Divider style={{ width: '53%', minWidth: '0' }} />
        {currentEditableYear && (
          <Styled.DeleteFinancialYearButton
            onClick={() => {
              dispatch(
                actions.deleteFinancialYear({ companyId: companyId, year: currentEditableYear })
              );
              toggle();
            }}
          >
            Delete Year
          </Styled.DeleteFinancialYearButton>
        )}
      </Drawer>
    );
  }

  return (
    <Styled.ModalWrapper
      style={{ fontWeight: 'bold' }}
      title={currentEditableYear ? `Edit ${currentEditableYear}` : 'Add New Year'}
      visible={isOpen}
      onCancel={() => {
        setSelectOpen(false);
        setTimeout(() => {
          setButtonDisabled(false);
          setShowYearError(false);
          toggle();
        }, 100);
      }}
      okButtonProps={{ disabled: isButtonDisabled }}
      onOk={handleOk}
      width={860}
      destroyOnClose
      okText="Save"
    >
      {modalContent}
      {currentEditableYear && (
        <Button
          onClick={() => {
            dispatch(
              actions.deleteFinancialYear({ companyId: companyId, year: currentEditableYear })
            );
            toggle();
          }}
          style={{ marginTop: '2rem' }}
          icon={<DeleteOutlined style={{ color: 'red' }} />}
        >
          Delete Year
        </Button>
      )}
    </Styled.ModalWrapper>
  );
};

export default memo(AddFinancialYearModal);
