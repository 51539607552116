import { Menu, MenuProps } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
// hooks
import { useDisplayColumnModal } from '@optx/common/hooks/init';

const DisplayColumnToggle = ({ onClick, ...restProps }: MenuItemProps) => {
  const [, toggleModalDisplay] = useDisplayColumnModal();

  const handleClick: MenuProps['onClick'] = info => {
    toggleModalDisplay();
    onClick && onClick(info);
  };

  return (
    <Menu.Item onClick={handleClick} {...restProps}>
      Columns and Source Management
    </Menu.Item>
  );
};

export default DisplayColumnToggle;
