import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import featureName from './featureName';
import reducer from './state/reducer';
import saga from './state/sagas';

const useInject = () => {
  useInjectReducer({ key: featureName, reducer });
  useInjectSaga({ key: featureName, saga });
};

export default useInject;
