import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
// models
import { FileDetails } from '@optx/models/files';
import { DataSettingsResponse } from './interfaces';
import { SortByRule } from '@optx/models/table/sorting';
// Services
import NotificationService from '@optx/services/NotificationService';
import { CompanyService } from '@optx/services/api';
// utils
import { getErrorMessage } from '@optx/utils/api/errors';
// redux
import { selectors as profileSelectors } from '@redux/company/profile';
import * as actions from './actions';
import * as selectors from './selectors';

export function* fetchFilesSaga() {
  try {
    const filters: { [key: string]: string } = yield select(selectors.getFilters);
    const sortBy: Array<SortByRule<any>> = yield select(selectors.sortBy);
    const companyId: number = yield select(profileSelectors.getCompanyId);

    const payload = {
      companyId: companyId,
      sortBy: sortBy,
      filters: filters,
    };
    const res: AxiosResponse<FileDetails[]> = yield call(CompanyService.getFiles, payload);

    if (res.data) {
      yield put(actions.fetchFilesSuccess(res.data));
    } else {
      const errorMessage = 'Failed to fetch files!';
      yield put(actions.fetchFilesFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to fetch files!');
    yield put(actions.fetchFilesFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* sortFilesSaga(action: PayloadAction<Array<SortByRule<any>>>) {
  try {
    const companyId: number = yield select(selectors.fetchForCompanyId); // get company id from state

    const res: AxiosResponse<FileDetails[]> = yield call(CompanyService.getFiles, {
      companyId,
      sortBy: action.payload,
    });

    if (res.data) {
      yield put(actions.fetchFilesSuccess(res.data));
    } else {
      const errorMessage = 'Failed to fetch files!';
      yield put(actions.fetchFilesFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to fetch files!');
    yield put(actions.fetchFilesFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* downloadFileSaga(action: PayloadAction<{ id: number; name: string }>) {
  let errorMessage = 'Failed to download file!';

  try {
    const res: AxiosResponse<Blob> = yield call(CompanyService.downloadFile, action.payload);

    if (res.data) {
      NotificationService.success('File downloaded successfully!');
    } else {
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    if (error.response.status === 404) {
      errorMessage = 'Requested file is not registered';
    }

    NotificationService.error(errorMessage);
  }
}

export function* fetchFilesFiltersSaga() {
  try {
    const companyId: number = yield select(profileSelectors.getCompanyId);

    const res: AxiosResponse<DataSettingsResponse> = yield call(
      CompanyService.getFilesDataSettings,
      companyId
    );

    if (res.data) {
      yield put(actions.fetchFilesFiltersSuccess(res.data));
    } else {
      const errorMessage = 'Failed to fetch filters!';
      yield put(actions.fetchFilesFiltersFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to fetch filters!');
    yield put(actions.fetchFilesFiltersFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* filesSaga() {
  yield takeLatest(actions.fetchFiles, fetchFilesSaga);
  yield takeLatest(actions.sortFiles, fetchFilesSaga);
  yield takeLatest(actions.changeFilter, fetchFilesSaga);
  yield takeLatest(actions.downloadFile, downloadFileSaga);
  yield takeLatest(actions.fetchFilesFilters, fetchFilesFiltersSaga);
}
