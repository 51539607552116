import React, { useState } from 'react';
import { Typography, Modal, Table } from 'antd';
import moment from 'moment';
// models
import { SalesloftBulkContactResponse } from '@models/bulkActions';
import { SalesloftContact } from '@optx/models/Salesloft';
import { EquityTouchContact } from '@optx/models/equityTouch';
// constants
import { defaultColumns } from '@components/common/table/TableBulkResolve';
import { PRESENT_DATE_FORMAT, ISO_DATE_FORMAT } from '@optx/constants/format/date';
// components
import { SalesloftAddContact } from '@optx/screens/App/SalesloftBulk/components/salesloft/SalesloftAddContact';

interface ActionsProps {
  companyId: number;
  companyName: string;
  url: string;
  contactsResponse: SalesloftBulkContactResponse[];
  sentContacts: SalesloftContact[];
  onResolve: VoidFunction;
  isEtBulk?: boolean;
}

export const Actions: React.FC<ActionsProps> = ({
  companyId,
  companyName,
  url,
  contactsResponse,
  sentContacts,
  onResolve,
  isEtBulk,
}) => {
  const [editContactVisible, setEditContactVisible] = useState(false);
  const [addContactVisible, setAddContactVisible] = useState(false);

  const failedContactIds = contactsResponse
    ?.filter(contact => contact.sl_resolve)
    ?.map(contact => contact.person_id);

  if (!failedContactIds?.length) {
    return null;
  }

  const failedContacts = sentContacts
    .filter(sentContact => failedContactIds.includes(sentContact.person_id as number))
    .map(sentContact => {
      return {
        ...sentContact,
        company_id: companyId,
        full_name: `${sentContact.first_name} ${sentContact.last_name}`,
        tenure_start_date: sentContact.tenure_start_date
          ? moment(sentContact.tenure_start_date).format(ISO_DATE_FORMAT)
          : sentContact.tenure_start_date,
        tenure_end_date:
          sentContact.tenure_end_date && sentContact.tenure_end_date !== PRESENT_DATE_FORMAT
            ? moment(sentContact.tenure_end_date).format(ISO_DATE_FORMAT)
            : sentContact.tenure_end_date,
      };
    }) as EquityTouchContact[];

  const handleEditCancel = () => {
    setEditContactVisible(false);
  };

  const handleAddCancel = () => {
    setAddContactVisible(false);
  };

  const handleOk = () => {
    onResolve();
    handleEditCancel();
    handleAddCancel();
  };

  const handleResolve = () => {
    setEditContactVisible(true);
  };

  const handleAdd = () => {
    setAddContactVisible(true);
  };

  if (
    contactsResponse.some(
      contact =>
        contact.sl_message !== 'No contacts available' && contact.sl_resolve !== 'Add contact to ET'
    )
  ) {
    return (
      <>
        <Typography.Link onClick={handleResolve} style={{ color: '#1890ff' }}>
          Resolve
        </Typography.Link>
        <Modal
          style={{ minWidth: '900px' }}
          title="Resolve"
          okText="Resolve"
          visible={editContactVisible}
          onOk={handleOk}
          onCancel={handleEditCancel}
        >
          <Table
            columns={defaultColumns}
            dataSource={failedContacts}
            rowKey={contact => contact.person_id as number}
            bordered
            pagination={false}
            tableLayout="fixed"
            className="overflow-auto legacy-table"
          />
        </Modal>
      </>
    );
  }

  const companyData = {
    company_id: companyId,
    company_name: companyName,
    company_url: url,
  };

  return (
    <>
      <Typography.Link onClick={handleAdd} style={{ color: '#1890ff' }}>
        Add Contact
      </Typography.Link>
      <SalesloftAddContact
        contactData={companyData}
        visible={addContactVisible}
        onCancel={handleAddCancel}
        shouldResolve
        isEtBulk={isEtBulk}
        maxEmails={4}
      />
    </>
  );
};

export default Actions;
