import { AppState } from '@optx/redux/interfaces';
import { createSelector } from 'reselect';
import { selectors as fetchSelectors } from '../../../feature/fetch';

export const userInvitationDialogState = (state: AppState) => state.ui.modals.userInvitation;

export const isLoading = createSelector(
  userInvitationDialogState,
  fetchSelectors.isLoadingSelector
);

export const userInvitationDialog = createSelector(userInvitationDialogState, state => state.open);
