import styled from 'styled-components';

const UserAvatar = styled.div`
  clear: right;

  .user-avatar {
    align-items: center;

    &__name {
      color: #90a0b7;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      text-align: right;
      white-space: nowrap;
    }

    &__name--clickable {
      cursor: pointer;
    }

    &__title {
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      text-align: right;
      text-transform: uppercase;
      color: #334d6e;
    }

    &__image {
      width: 46px;
      height: 46px;
      overflow: hidden;
      border-radius: 50%;

      > img {
        max-width: 100%;
      }
    }
  }
`;

export const Styled = {
  UserAvatar,
};
