import React, { useMemo } from 'react';
import { Menu } from 'antd';
import { useDispatch } from 'react-redux';
// models
import Company from '@optx/models/Company';
import CompanyTouch from '@optx/models/company/CompanyTouch';
// utils
import { sendEmail } from '../utils/emailAttendees';
// redux
import { actions as modalActions } from '@redux/ui/modals/company-touches';
// styles
import Styled from './TouchMenu.styled';

interface TouchMenuProps {
  touch: CompanyTouch;
  company: Company;
  handleVisibilityChange: (visible: boolean) => void;
}

const TouchMenu: React.FC<TouchMenuProps> = ({ touch, company, handleVisibilityChange }) => {
  const dispatch = useDispatch();

  const disableMenuItemByTouch = useMemo(
    () => touch.touchtype === 'Document Upload',
    [touch.touchtype]
  );

  const handleEmailClick = () => {
    document.body.classList.remove('popover-visible');
    handleVisibilityChange(false);

    if (company.et_company_id === touch.companyid && touch.attendees_email) {
      sendEmail(touch, company.company_name);
    }
  };

  const handleEditClick = (setCompleted: boolean = false) => {
    document.body.classList.remove('popover-visible');
    handleVisibilityChange(false);
    const updatedTouch = {
      ...touch,
      touchIsComplete: touch.iscomplete,
      iscomplete: setCompleted ? true : touch.iscomplete,
    };
    dispatch(
      modalActions.toggleCompanyTouchesDialog({
        companyId: company.company_id,
        companyName: company.company_name,
        company,
        touch: updatedTouch,
      })
    );
  };

  return (
    <Styled.MenuList className="dropdown-add dropdown-menu" selectable={false}>
      {!touch.iscomplete && (
        <Menu.Item key="0" onClick={e => handleEditClick(true)} disabled={disableMenuItemByTouch}>
          Log Complete Touch
        </Menu.Item>
      )}
      <Menu.Item key="1" onClick={e => handleEditClick(false)} disabled={disableMenuItemByTouch}>
        Edit Touch
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={handleEmailClick}
        disabled={!touch.attendees_email || disableMenuItemByTouch}
      >
        Email Attendees
      </Menu.Item>
    </Styled.MenuList>
  );
};

export default TouchMenu;
