import React from 'react';
import { FilterSource, Filter, BaseFilter } from '@optx/models/filters';
import { Tabs, Row, Col } from 'antd';
import FilterFactory from '../Filter/FilterFactory';

const { TabPane } = Tabs;

interface SourcePaneContentProps {
  source: FilterSource<Filter<any>>;
}

const SourcePaneContent: React.FC<SourcePaneContentProps> = ({ source }) => {
  const halfIndex =
    source.data.length % 2 === 0 ? source.data.length / 2 : (source.data.length - 1) / 2 + 1;

  const left = source.data.slice(0, halfIndex);
  const right = source.data.slice(halfIndex);

  return (
    <Row gutter={20}>
      <Col xs={24} sm={12}>
        {left.map(filter => (
          <FilterFactory key={filter.column} filter={filter as BaseFilter} source={source} />
        ))}
      </Col>
      <Col xs={24} sm={12}>
        {right.map(filter => (
          <FilterFactory key={filter.column} filter={filter as BaseFilter} source={source} />
        ))}
      </Col>
    </Row>
  );
};

const MemoizedSourcePaneContent = React.memo(SourcePaneContent);

interface FilterTabsProps {
  sources: Array<FilterSource<Filter<any>>>;
  activeKey: string;
  onTabChange: (activeKey: string) => void;
}

const FilterTabs: React.FC<FilterTabsProps> = ({ sources, activeKey, onTabChange }) => {
  return (
    <Tabs style={{ padding: '40px 50px' }} activeKey={activeKey} onChange={onTabChange}>
      {sources.map((source, index) => (
        <TabPane tab={source.label} key={index.toString()}>
          <MemoizedSourcePaneContent source={source} />
        </TabPane>
      ))}
    </Tabs>
  );
};

export default FilterTabs;
