import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
// models
import { SocialMediaChartData } from '@optx/models/charts/data';
// services
import { CompanyService } from '@services/api';
import NotificationService from '@services/NotificationService';
// utils
import { getErrorMessage } from '@utils/api/errors';
// redux
import { INITIAL_STATE_CHART } from '@optx/constants/charts';
import * as actions from './actions';

export function* G2DataSaga(action: PayloadAction<number, any, boolean>) {
  const companyId = action.payload;

  try {
    const res: AxiosResponse<SocialMediaChartData> = yield call(
      CompanyService.getCompanySocialMediaG2,
      companyId
    );
    const chartData = res.data;

    if (chartData) {
      yield put(actions.fetchG2DataSuccess(chartData));
    } else {
      yield put(actions.fetchG2DataSuccess(INITIAL_STATE_CHART as SocialMediaChartData));
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Failed to fetch G2 Crowd data');
    yield put(actions.fetchG2DataFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* g2DataSaga() {
  yield takeLatest(actions.fetchG2Data, G2DataSaga);
}
