import { useInjectSaga } from 'redux-injectors';
import { saga } from '../../../redux/company/score';

const useInjectScore = () => {
  useInjectSaga({
    key: 'score',
    saga,
  });
};

export default useInjectScore;
