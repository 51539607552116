import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../../../redux/user-management';

export const useResendUserInvitation = () => {
  const dispatch = useDispatch();
  const resendUserInvite = useCallback(
    payload => dispatch(actions.resendUserInvite(payload)),
    [dispatch]
  );

  return resendUserInvite;
};
