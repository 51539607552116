// Initial Fetch favorite lists
export const INITIAL_FETCH_FAVORITE_LISTS = 'favorite-lists/INITIAL_FETCH_FAVORITE_LISTS';
export const INITIAL_FETCH_FAVORITE_LISTS_SUCCESS =
  'favorite-lists/lists/INITIAL_FETCH_FAVORITE_LISTS_SUCCESS';

// Fetch favorite lists
export const FETCH_FAVORITE_LISTS = 'favorite-lists/lists/FETCH_FAVORITE_LISTS';
export const FETCH_FAVORITE_LISTS_SUCCESS = 'favorite-lists/lists/FETCH_FAVORITE_LISTS_SUCCESS';
export const FETCH_FAVORITE_LISTS_FAIL = 'favorite-lists/lists/FETCH_FAVORITE_LISTS_FAIL';

// Create favorite list
export const CREATE_FAVORITE_LIST = 'favorite-lists/lists/CREATE_FAVORITE_LIST';
export const CREATE_FAVORITE_LIST_SUCCESS = 'favorite-lists/lists/CREATE_FAVORITE_LIST_SUCCESS';
export const CREATE_FAVORITE_LIST_FAIL = 'favorite-lists/lists/CREATE_FAVORITE_LIST_FAIL';

// Delete favorite list
export const DELETE_FAVORITE_LIST = 'favorite-lists/lists/DELETE_FAVORITES_LIST';
export const DELETE_FAVORITE_LIST_SUCCESS = 'favorite-lists/lists/DELETE_FAVORITES_LIST_SUCCESS';
export const DELETE_FAVORITE_LIST_FAIL = 'favorite-lists/lists/DELETE_FAVORITES_LIST_FAIL';

// Rename favorite list
export const RENAME_FAVORITE_LIST = 'favorite-lists/lists/RENAME_FAVORITE_LIST';
export const RENAME_FAVORITE_LIST_SUCCESS = 'favorite-lists/lists/RENAME_FAVORITE_LIST_SUCCESS';
export const RENAME_FAVORITE_LIST_FAIL = 'favorite-lists/lists/RENAME_FAVORITE_LIST_FAIL';

// update favorite list count after bulk remove
export const UPDATE_FAVORITE_LIST_COUNT = 'favorite-list/list/UPDATE_FAVORITE_LIST_COUNT';

// sort favorite list
export const SORT_FAVORITE_LISTS = 'favorite-list/list/SORT_FAVORITE_LISTS';

// Delete company from list
export const DELETE_COMPANY_FROM_LIST = 'favorite-lists/lists/DELETE_COMPANY_FROM_LIST';
export const DELETE_COMPANY_FROM_LIST_SUCCESS =
  'favorite-lists/lists/DELETE_COMPANY_FROM_LIST_SUCCESS';
export const DELETE_COMPANY_FROM_LIST_FAIL = 'favorite-lists/lists/DELETE_COMPANY_FROM_LIST_FAIL';

// Remove company from list
export const REMOVE_SHARED_LIST = 'favorite-lists/lists/REMOVE_SHARED_LIST';
export const REMOVE_SHARED_LIST_SUCCESS = 'favorite-lists/lists/REMOVE_SHARED_LIST_SUCCESS';
export const REMOVE_SHARED_LIST_FAIL = 'favorite-lists/lists/REMOVE_SHARED_LIST_FAIL';

// Add company in list
export const ADD_COMPANY_TO_LIST = 'favorite-lists/lists/ADD_COMPANY_TO_LIST';
export const ADD_COMPANY_TO_LIST_SUCCESS = 'favorite-lists/lists/ADD_COMPANY_TO_LIST_SUCCESS';
export const ADD_COMPANY_TO_LIST_FAIL = 'favorite-lists/lists/ADD_COMPANY_TO_LIST_FAIL';
export const ADD_COMPANY_TO_LIST_BULK_SUCCESS =
  'favorite-lists/lists/ADD_COMPANY_TO_LIST_BULK_SUCCESS';

// save search as list
export const SAVE_SEARCH_AS_LIST = 'favorite-lists/lists/SAVE_SEARCH_AS_LIST';
export const SAVE_SEARCH_AS_LIST_SUCCESS = 'favorite-lists/lists/SAVE_SEARCH_AS_LIST_SUCCESS';
export const SAVE_SEARCH_AS_LIST_FAIL = 'favorite-lists/lists/SAVE_SEARCH_AS_LIST_FAIL';
export const updateCompleted = 'favorite-lists/lists/save_search/UPDATE_COMPLETED';
export const updatePercentage = 'favorite-lists/lists/save_search/UPDATE_PERCENTAGE';
export const saveTitle = 'favorite-lists/lists/save_search/SAVE_TITLE';
export const tryAgainSaveSearchAsList =
  'favorite-lists/lists/save_search/TRY_AGAIN_SAVE_SEARCH_AS_LIST';
export const cancelSaveSearchAsList = 'favorite-lists/lists/save_search/CANCEL_SAVE_SEARCH_AS_LIST';
export const resetSaveSearchAsList = 'favorite-lists/lists/save_search/RESET_SAVE_SEARCH_AS_LIST';

// pagination
export const FAVORITE_LIST_PAGINATION = 'favorite-lists/lists/FAVORITE_LIST_PAGINATION';

// update
export const UPDATE_FAVORITE_LIST_PERMISSION =
  'favorite-lists/lists/UPDATE_FAVORITE_LIST_PERMISSION';

// associate company to list (add-on)
export const UPDATE_FAVORITE_LIST_ASSOCIATION =
  'favorite-lists/lists/UPDATE_FAVORITE_LIST_ASSOCIATION';
export const UPDATE_FAVORITE_LIST_ASSOCIATION_SUCCESS =
  'favorite-lists/lists/UPDATE_FAVORITE_LIST_ASSOCIATION_SUCCESS';
export const UPDATE_FAVORITE_LIST_ASSOCIATION_FAIL =
  'favorite-lists/lists/UPDATE_FAVORITE_LIST_ASSOCIATION_FAIL';

export const resetFetchedAt = 'favorite-lists/lists/RESET_FETCHED_AT';
export const UPDATE_LIST_COUNT = 'lists/UPDATE_LIST_COUNT';
// public list
export const PUBLIC_WATCH_LIST = 'PUBLIC_WATCH_LIST';
export const PUBLIC_WATCH_LIST_SUCCESS = 'PUBLIC_WATCH_LIST_SUCCESS';
export const PUBLIC_WATCH_LIST_FAIL = 'PUBLIC_WATCH_LIST_FAIL';
