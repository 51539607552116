import { createAction } from '@reduxjs/toolkit';
// models
import { types } from '../types';
import { CompanyActivityResponse } from '../interfaces';

export const fetchCompanyActivity = createAction(types.company.FETCH_COMPANY_ACTIVITY);
export const fetchCompanyActivitySuccess = createAction<CompanyActivityResponse>(
  types.company.FETCH_COMPANY_ACTIVITY_SUCCESS
);
export const fetchCompanyActivityFail = createAction<string>(
  types.company.FETCH_COMPANY_ACTIVITY_FAIL
);
