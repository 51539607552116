import styled from 'styled-components';
import { Button, Menu } from 'antd';
import headerBg from '@optx/assets/svg/chrome-header-bg.svg';

const ChromeExtensionHeader = styled.div.attrs(props => ({ className: 'chrome-extension-header' }))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px;
  height: 60px;
  background-color: black;
  position: relative;
  top: -1px;
  left: -1px;
  width: 640px;
  margin-top: 1px;
  z-index: 2;

  background-image: url(${headerBg});
  .view-in-optx-btn {
    background: #1890ff33;
    border: 1px solid #1890ff33;
    margin-right: 10px;
  }

  .buttons-container {
    margin-right: 0px;
    display: flex;
    align-items: center;

    .dropdown-block {
      margin-right: 10px;
    }
  }

  .optx-logo {
    width: auto;
    height: auto;
  }
`;

const DotsButton = styled(Button).attrs(props => ({ type: 'text' }))`
  width: 24px;
  height: 24px;
  padding: 0;
  border: 0;
  margin-right: 13px;
  .dots-button {
    width: 24px;
    height: 24px;
  }
`;

const ActionMenu = styled(Menu)`
  &.ant-menu-root.ant-menu-vertical {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    li.ant-menu-item.ant-menu-item-only-child {
      margin: 0;
      margin-bottom: 0;
      height: 32px;
      line-height: 32px;
    }
  }
`;

export default {
  ChromeExtensionHeader,
  DotsButton,
  ActionMenu,
};
