import React from 'react';
import { ColumnsType } from 'antd/lib/table';
// models
import { SelectedCompanies } from '@optx/models/bulkActions';
import { CompanyKeys } from '@models/table/Columns';
// components
import TableCellCompanyURL from '@components/common/table/AntTable/cells/TableCellCompanyURL';
import { TableCellDefault, TableCellCompanyName } from '@components/common/table/AntTable/cells';

interface CompanyColumnType extends ColumnsType<SelectedCompanies> {
  dataIndex?: CompanyKeys;
}

export const BULK_MERGE_COMPANIES_COLUMNS: CompanyColumnType = [
  {
    dataIndex: 'company_url',
    title: '',
    className: 'company_url',
    render: (value: string | null, record: SelectedCompanies, index: number) => {
      return (
        <TableCellCompanyURL
          value={value}
          record={{ raw_url: record.raw_url || '', urlStatus: record.url_status }}
        />
      );
    },
    width: 42,
    fixed: 'left',
    align: 'center',
  },
  {
    dataIndex: 'company_name',
    title: 'Company Name',
    render: TableCellCompanyName,
  },
  {
    dataIndex: 'location',
    title: 'Location',
    render: TableCellDefault,
  },
  {
    dataIndex: 'year_founded',
    title: 'Founded',
    render: TableCellDefault,
  },
  {
    dataIndex: 'company_id',
    title: 'OPTX ID',
    render: TableCellDefault,
  },
];
