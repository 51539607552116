import React from 'react';
import { GlobalOutlined } from '@ant-design/icons';
import Company from '@optx/models/Company';
import styled from 'styled-components';
import { Cell } from '../../../../../models/table/Cell';

const CompanyLink = styled.a`
  &.not-active {
    color: red;
  }
  color: #c2cfe0;
  font-size: 22px;
  line-height: 1;

  > svg {
    width: auto;
    height: auto;
  }
`;

const TableCellCompanyURL: React.FC<Cell<Company>> = ({ record }) => {
  const { raw_url: rawUrl, url_status: status } = record;
  const urlStatus = status === 'active';

  return (
    <>
      {rawUrl && (
        <CompanyLink
          href={rawUrl}
          title={rawUrl}
          className={urlStatus ? undefined : 'not-active'}
          target="_blank"
        >
          <GlobalOutlined />
        </CompanyLink>
      )}
    </>
  );
};

export default React.memo(TableCellCompanyURL);
