import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ValueType } from 'react-select';
// models
import { SelectOption } from '@models/Option';
import { PreselectedFilter } from '@optx/models/filters';
// utils
import { getSingleSelectOptions } from '@optx/utils/filters/mapFiltersOptions';
// components
import { SingleSelect, SingleSelectProps } from '@shared/view/molecules/Select';

// Local
import { selectors, actions } from '../state';

interface FilterSingleSelectProps extends SingleSelectProps {
  filterKey: 'stage_change';
}

export const FilterSingleSelect: React.FC<FilterSingleSelectProps> = ({
  filterKey,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const [option, setOption] = useState<Array<SelectOption>>();

  const filterData = useSelector(selectors.filters.getFilters);
  const normalizedData = filterData.normalized[filterKey];

  const valuesData = useSelector(selectors.filters.getValues);
  const value = valuesData[filterKey];

  useEffect(() => {
    if (normalizedData) {
      const sortedOptions = getSingleSelectOptions(normalizedData as any) as any;

      setOption(sortedOptions);
    }
  }, [normalizedData]);

  const updateValue = useCallback(
    (newValue: ValueType<SelectOption>) => {
      dispatch(
        actions.filters.updateFilters({ value: [newValue] as PreselectedFilter, key: filterKey })
      );
    },
    [dispatch, filterKey]
  );

  return (
    <SingleSelect
      options={option}
      onChange={updateValue}
      value={value as ValueType<SelectOption>}
      placeholder={normalizedData ? normalizedData.placeholders : undefined}
      valuePrefix={normalizedData ? `${normalizedData.placeholders}:` : undefined}
      {...restProps}
    />
  );
};
