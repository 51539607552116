import React from 'react';
import styled from 'styled-components';

const MatchedText = styled.span`
  font-weight: 500;
`;

const TableCellMatch = (value: string) => {
  return value ? <MatchedText>YES</MatchedText> : 'No';
};

export default TableCellMatch;
