import { Progress } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 0 0 5px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* Dark BLue */
  color: #334d6e;
`;

const MainStatValue = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  /* Daybreak Blue / blue-6 */
  color: #c2cfe0;

  span {
    color: #1890ff;

    &:hover {
      cursor: pointer;
      color: #096dd9;
    }
  }
`;

const ComparedValue = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  /* Gray */
  color: #90a0b7;
`;

const StyledPropgress = styled(Progress)`
  display: block;
  line-height: 1;
  margin: 6px 0 4px;

  &.ant-progress-line {
    font-size: 4px;

    .ant-progress-inner {
      background: transparent;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .ant-progress-bg {
      /* Height is set directly on element and can't be overriden otherwise. */
      height: 5px !important;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

const MainStatProgress = styled(StyledPropgress)`
  .ant-progress-bg {
    /* Daybreak Blue / blue-6 */
    background-color: #1890ff;
  }
`;

const ComparedProgress = styled(StyledPropgress)`
  .ant-progress-bg {
    /* Gray */
    background-color: #c2cfe0;
  }
`;

const ExtraData = styled.div`
  padding: 4px 0px;
`;

const Styles = {
  Wrapper,
  Title,
  MainStatValue,
  MainStatProgress,
  ComparedValue,
  ComparedProgress,
  ExtraData,
};

export default Styles;
