import { takeLatest, put, call, select } from 'redux-saga/effects';
import { Dictionary } from 'lodash';
import { PayloadAction } from '@reduxjs/toolkit';
// models
import { SearchPayloadRequest } from '@models/api/contacts';
import { PageInfo } from '@optx/models/table/Pagination';
import { PreselectedFilter } from '@optx/models/filters';
import { SortByRule } from '@optx/models/table/sorting';
import { BulkMergeCompanies } from './interfaces';
// services
import { UserService } from '@optx/services/api';
import NotificationService from '@optx/services/NotificationService';
// utils
import { getErrorMessage } from '@utils/api/errors';
import { getRouteAlias } from '@optx/utils/routes';
// redux
import { actions as customGlobalLoaderActions } from '@features/custom-global-loader';
import { actions as editFieldsActions } from '@features/bulk-actions/edit-fields';
import { selectors as companySearchSelectors } from '@redux/company/search/search';
import { actions as searchActions, selectors as searchSelectors } from '@features/grid/search';
import {
  actions as paginationActions,
  selectors as paginationSelectors,
} from '@features/grid/pagination';
import { selectors as listsSearchSelectors } from '@optx/redux/lists/search/search';
import { actions as searchCountActions } from '@features/grid/search-count';
import { getFiltersQuery } from '@redux/company/search/search/sagasReusable';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import { selectors as profileSelectors } from '@redux/company/profile';
import * as actions from './actions';

function* bulkMergeCompaniesSaga(action: PayloadAction<BulkMergeCompanies>) {
  const { selectedCompanies } = action.payload;
  const companies = [...selectedCompanies];

  const companyIds = companies.map(company => company.company_id);

  try {
    // @ts-ignore
    const res = yield call(UserService.bulkMergeCompanies, companyIds);

    if (res.data) {
      const successMessage = 'Companies merged successfully';
      NotificationService.success(successMessage);

      const view = getRouteAlias();

      if (view === 'advancedSearch') {
        const pagination: PageInfo = yield select(
          paginationSelectors.getPagination('advancedSearch')
        );
        const searchKey: string = yield select(searchSelectors.getSearchKey('advancedSearch'));
        const filter: Dictionary<PreselectedFilter> = yield select(
          filterSelectors.getFilter('advancedSearch')
        );
        const filterQuery: Dictionary<string | (string | number)[]> = yield call(
          getFiltersQuery,
          filter
        );
        const sortBy: SortByRule<any>[] = yield select(companySearchSelectors.getSorting);
        const searchData: SearchPayloadRequest = {
          searchKey,
          filter: filterQuery,
          pagination,
          sortBy,
        };

        yield put(searchCountActions.searchCount({ data: searchData, gridKey: 'advancedSearch' }));
        yield put(
          paginationActions.changePagination({ gridKey: 'advancedSearch', data: pagination })
        );
      } else if (view === 'userLists' || view === 'sourceScrubLists') {
        const pagination: PageInfo = yield select(paginationSelectors.getPagination('watchlists'));
        const searchKey: string = yield select(searchSelectors.getSearchKey('lists'));
        const filter: Dictionary<PreselectedFilter> = yield select(
          filterSelectors.getFilter(view === 'sourceScrubLists' ? 'sslists' : 'watchlists')
        );
        const filterQuery: Dictionary<string | (string | number)[]> = yield call(
          getFiltersQuery,
          filter
        );
        const sortBy: SortByRule<any>[] = yield select(listsSearchSelectors.getSorting);
        const searchData: SearchPayloadRequest = {
          searchKey,
          filter: filterQuery,
          pagination,
          sortBy,
        };

        yield put(
          searchCountActions.searchCount({
            data: searchData,
            gridKey: view === 'sourceScrubLists' ? 'sslists' : 'watchlists',
          })
        );
        yield put(paginationActions.changePagination({ gridKey: 'watchlists', data: pagination }));
      } else if (view === 'companyOutReach') {
        const gridKey = 'outreach';
        const tableGridQuery: string = yield select(searchSelectors.getTableGridQuery(gridKey));

        yield put(searchActions.initialCompaniesSearch({ gridKey, data: { tableGridQuery } }));
      } else if (view === 'companyProfile') {
        const gridKey = 'addons';
        const companyId: number | undefined = yield select(profileSelectors.getCompanyId);
        const pagination: PageInfo = yield select(paginationSelectors.getPagination(gridKey));
        const searchKey: string = yield select(searchSelectors.getSearchKey(gridKey));

        if (companyId) {
          const filter: Dictionary<PreselectedFilter> = yield select(
            filterSelectors.getFilter(gridKey)
          );
          const filterQuery: Dictionary<string | (string | number)[]> = yield call(
            getFiltersQuery,
            filter
          );
          const sortBy: SortByRule<any>[] = yield select(listsSearchSelectors.getSorting);
          const searchData: SearchPayloadRequest = {
            searchKey,
            filter: filterQuery,
            pagination,
            sortBy,
            companyId: companyId,
          };
          yield put(searchCountActions.searchCount({ data: searchData, gridKey }));
          yield put(paginationActions.changePagination({ gridKey, data: pagination }));
        }
      }

      yield put(editFieldsActions.updateCompleted(true));
    }
  } catch (e: any) {
    const message = getErrorMessage(e, 'Failed to merge companies!');
    NotificationService.error(message);
    yield put(editFieldsActions.updateCompleted(true));
  }

  yield put(editFieldsActions.updateProgress(false));
  yield put(editFieldsActions.updateCompleted(false));
  yield put(editFieldsActions.cancel(false));
  yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
}

export default function* bulkEditSaga() {
  yield takeLatest(actions.bulkMergeCompanies, bulkMergeCompaniesSaga);
}
