import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import reducer, { saga } from '@features/add-research-rationale/state';

const useInjectAddRationale = () => {
  useInjectReducer({
    key: 'researchRationale',
    reducer,
  });
  useInjectSaga({ key: 'researchRationale', saga });
};

export default useInjectAddRationale;
