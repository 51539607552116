import { useInjectReducer } from 'redux-injectors';
import myPipelineReducer from '../../../redux/my-pipeline';

const useInjectMyPipeline = () => {
  useInjectReducer({
    key: 'myPipeline',
    reducer: myPipelineReducer,
  });
};

export default useInjectMyPipeline;
