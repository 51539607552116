import { createSelector } from 'reselect';
import { PageInfo } from '@optx/models/table/Pagination';
import { PaginationState } from '../interfaces';

export const total = (state: PaginationState) => state.total;
export const pageCount = (state: PaginationState) => state.pageCount;
export const pageNumber = (state: PaginationState) => state.pageNumber;
export const pageSize = (state: PaginationState) => state.pageSize;
export const selectPagination = createSelector(
  pageNumber,
  pageSize,
  (pageNumber, pageSize): PageInfo => ({
    pageNumber,
    pageSize,
  })
);
