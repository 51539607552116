import React from 'react';
import { Card } from 'antd';

import { Container } from './CollapsabeCardInfo.styled';

const { Meta } = Card;

interface CollapsableCardInfoProps {
  title: JSX.Element | string;
  description: string;
}

const CollapsableCardInfo: React.FC<CollapsableCardInfoProps> = ({
  title,
  description,
  children,
}) => {
  return (
    <Container>
      <div>
        <Meta title={title} description={description} />
      </div>
      {children}
    </Container>
  );
};

export default CollapsableCardInfo;
