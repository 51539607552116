import React from 'react';
import { LabelProps } from 'recharts';
import { ChartBarItem } from '@optx/models/charts/data';

interface BarCustomizedLabelProps extends LabelProps {
  data: ChartBarItem[];
}

const BarCustomizedLabel: React.FC<BarCustomizedLabelProps> = props => {
  const { data, width, x, value } = props;
  const tooltipData = data.find(item => item.x_axis === value);

  const radius = 13;
  const id = `x${Math.trunc(x as number)}`;

  return (
    <>
      {tooltipData?.round_data && tooltipData.total_investment === null && (
        <g>
          <clipPath id={id}>
            <rect x={x} y={92} width={width} height={radius} />
          </clipPath>
          <circle
            cx={(x as number) + (width as number) / 2}
            cy={105}
            r={radius}
            fill="#f4ac56"
            clipPath={`url(#${id})`}
          />
        </g>
      )}
      ;
    </>
  );
};

export default BarCustomizedLabel;
