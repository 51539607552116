import React, { useRef, useEffect, useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Formik, FormikValues, FormikProps } from 'formik';
import { Dictionary } from 'lodash';
import { Row, Col, Button, Typography, Space, Tooltip } from 'antd';
import Truncate from 'react-truncate';
import { CloseOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
// models
import { FilterSource, PreselectedFilter, Filter } from '@optx/models/filters';
// utils
import { createGeoRangeGroup } from '@utils/filters/filterGeoRange';
import { createLastDataUpdateGroup } from '@utils/filters/filterLastDataUpdate';
// redux
import { actions as histogramActions } from '@features/histograms/histograms-list-details';
import { selectors as searchSelectors } from '@redux/lists/search/search';
import { selectors as listsSelectors } from '@optx/common/selectors/lists';
import {
  FiltersContext,
  IFiltersContext,
} from '@components/feature/company-filters/FiltersContext';
import { HistogramRefreshToggler } from '@optx/features/histograms/base-histograms';
// components
import SearchInputTemp from '@features/grid/filter/components/SearchInputTemp';
import { FiltersForm } from '@features/grid/filter/components/FiltersForm';
import ShowingTitle from '@optx/components/common/ShowingTitle';

const { Title } = Typography;

interface FiltersModalContentProps {
  filter: Dictionary<PreselectedFilter>;
  filterSources: Array<FilterSource>;
  // clear
  onClearFilter: (shouldSort: boolean) => void;
  //   search
  keySearch: string;
  onSearch: (key: string, filter: Dictionary<PreselectedFilter>) => void;
  validationSchema?: any;
  onClose?: () => void;
  modalIsOpen: boolean;
}

const FiltersModalContent: React.FC<FiltersModalContentProps> = ({
  filter,
  filterSources,
  onClearFilter,
  keySearch,
  onSearch,
  validationSchema,
  onClose,
  modalIsOpen,
}) => {
  const dispatch = useDispatch();

  // everything from getHistogram request
  const histograms = useSelector(searchSelectors.selectEditHistograms);
  const filtersRefresh = useSelector(searchSelectors.histogramsEnabled);
  const listName = useSelector(listsSelectors.getSelectedListName);

  // search input
  const [inputSearch, setInputSearch] = useState(keySearch);

  // form
  const formRef = useRef<FormikProps<FormikValues> | null>(null);

  // formik submit handler
  const handleSubmit = () => {
    handleSearch(inputSearch);
  };

  // manually trigger submit
  const triggerSubmit = () => {
    dispatch(histogramActions.applyEdit());

    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  // search
  const handleSearch = (searchKey: string) => {
    if (formRef.current) {
      // Make any parent state updates with batched state updates.
      // This happens in an async callback or any event from WebAPIs https://developer.mozilla.org/en-US/docs/Web/Events
      // More info: https://github.com/facebook/react/issues/14259
      ReactDOM.unstable_batchedUpdates(() => onSearch(searchKey, formRef.current!.values));
    }
  };

  // when a filter is selected/changed/checked, save it's name to use afterwards.
  // this function is used inside each filter component. Eg: checkbox, radio, select, etc.
  const onFilterFieldChange = (fieldName: string) => {
    dispatch(histogramActions.filterChanged(fieldName, formRef.current?.values));
  };

  // used to add histogram and disabled properties and display updated filters
  const filterSourcesModified = useMemo(
    () =>
      filterSources.map(filterGroup => {
        const newFilterGroup: FilterSource<Filter<any>> = { ...filterGroup };
        const newGeoGroupFilterData: Array<Filter<any>> = createGeoRangeGroup(newFilterGroup.data);
        const newLastUpdateGroupFilterData: Array<Filter<any>> =
          createLastDataUpdateGroup(newGeoGroupFilterData);

        newFilterGroup.data = newLastUpdateGroupFilterData;

        return newFilterGroup;
      }),
    [filterSources]
  );

  const filtersContext: IFiltersContext = { histograms, onManualFilterChange: onFilterFieldChange };

  useEffect(() => {
    setInputSearch(keySearch);
  }, [keySearch]);

  const handleResetSearch = () => {
    // use same function to reset and clear filters and sorting
    // send a boolean to check if only reseting
    // filters or also resetting sorting
    setInputSearch('');

    if (formRef.current) {
      formRef.current.resetForm();
    }

    onClearFilter(true);
  };

  const handleClose = () => {
    onClose && onClose();
    // clear temporary state
    formRef.current && formRef.current.resetForm(filter);
    setInputSearch('');

    dispatch(histogramActions.edit(false));
  };

  // Backup histograms when modal is opened.
  useEffect(() => {
    if (modalIsOpen) {
      // Enable edit mode for histograms.
      dispatch(histogramActions.edit(modalIsOpen));
    }
  }, [dispatch, modalIsOpen]);

  const handleToggleHistogramRefresh = (enable: boolean) => {
    if (enable) {
      dispatch(histogramActions.toggleRefresh(enable, formRef.current?.values));
    } else {
      dispatch(histogramActions.toggleRefresh(enable));
    }
  };

  return (
    <FiltersContext.Provider value={filtersContext}>
      <div className="filter__wrapper">
        <div className="filter-submit-wrapper">
          <SearchInputTemp
            onSearch={handleSearch}
            onChange={setInputSearch}
            keySearch={inputSearch}
          />

          <Row style={{ padding: '20px 40px' }}>
            <Col xs={24} md={18} lg={16}>
              <Space align="center" style={{ width: '100%', flexWrap: 'wrap' }}>
                <Title level={2} style={{ fontWeight: 'normal', margin: '0 15px 0 0' }}>
                  Filters
                </Title>

                <Title
                  level={4}
                  style={{ margin: '2px 0 0 0', color: '#1890ff', fontWeight: 'normal' }}
                >
                  <Tooltip title={`"${listName}"`}>
                    <Truncate lines={1} ellipsis={<span>...</span>}>
                      {`"${listName}"`}
                    </Truncate>
                  </Tooltip>
                </Title>

                <ShowingTitle gridKey={'lists'} />
              </Space>
            </Col>
            <Col xs={24} md={18} lg={8}>
              <Space style={{ width: '100%', justifyContent: 'flex-end' }} align="center">
                <Button type="default" size="large" onClick={handleResetSearch}>
                  Deselect All
                </Button>

                <Button type="primary" size="large" onClick={triggerSubmit}>
                  Apply Filters
                </Button>

                <Button
                  type="link"
                  icon={<CloseOutlined />}
                  size="large"
                  onClick={handleClose}
                  style={{ color: '#000' }}
                />
              </Space>
            </Col>
          </Row>
        </div>
        <Row justify="end" style={{ padding: '20px 80px 0', textAlign: 'right' }}>
          <Col span={5}>
            <HistogramRefreshToggler
              checked={filtersRefresh}
              onChange={handleToggleHistogramRefresh}
            />
          </Col>
        </Row>
        <Formik
          initialValues={filter}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={validationSchema}
          innerRef={formRef}
          validateOnChange
          validateOnBlur
        >
          <FiltersForm filterSources={filterSourcesModified} />
        </Formik>
      </div>
    </FiltersContext.Provider>
  );
};

export default FiltersModalContent;
