/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { Drawer, Tabs, Badge, Button } from 'antd';
import { BellOutlined, ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
// models
import {
  NotificationPayload,
  ParsedUpcomingTouchesNotificationData,
} from '@optx/models/notifications';
import { ViewOption } from '@optx/models/search';
import { LoadingBlock } from '@optx/shared/view/molecules/loader';
import Icon from '@optx/components/common/Icon';
import {
  NotificationTabState,
  UpcomingTouchesNotificationTabState,
  PastDueTouchesNotificationTabState,
} from '@features/alerts/state/interfaces';
// redux
import { actions as alertActions } from '@features/alerts';
import { initialState } from '../state/reducer';
import { actions } from '../state';
import { getRoadmapUrl } from '../state/selectors';
// components
import NotificationItems from './NotificationItems';
import TouchesNotificationItems from './TouchesNotificationItems';
// styles
import './style.scss';

interface RecentActvityProps {
  isOpen: boolean;
  toggleModal: VoidFunction;
  alertsInboxState: NotificationTabState;
  alertsUpcomingTouchesState: UpcomingTouchesNotificationTabState;
  alertsUpcomingTouchesData: ParsedUpcomingTouchesNotificationData | boolean;
  alertsPastDueTouchesData: ParsedUpcomingTouchesNotificationData | boolean;
  alertsPastDueTouchesState: PastDueTouchesNotificationTabState;
  alertsHistoryState: NotificationTabState;
  alertsNewList: Array<number>;
  onDismissNotification: (id: number) => void;
  onDismissUpcomingTouch: (id: number) => void;
  onDismissPastDueTouch: (id: number) => void;
  filtersLoading: boolean;
  views: ViewOption[];
}

const { TabPane } = Tabs;

const TABS = {
  inbox: '1',
  history: '2',
  upcomingTouches: '3',
  pastDue: '4',
};

const RecentActivity: React.FC<RecentActvityProps> = ({
  isOpen,
  toggleModal,
  onDismissNotification,
  onDismissUpcomingTouch,
  onDismissPastDueTouch,
  alertsInboxState,
  alertsUpcomingTouchesState,
  alertsUpcomingTouchesData,
  alertsPastDueTouchesData,
  alertsPastDueTouchesState,
  alertsHistoryState,
  alertsNewList,
  filtersLoading,
  views,
}) => {
  const dispatch = useDispatch();
  const roadmapUrl = useSelector(getRoadmapUrl);
  const [activeTab, setActiveTab] = useState('1');
  const [loading, setLoading] = useState(false);

  const modalClass = classnames('custom-modal', 'recent-activity-modal', {
    'close-modal': !isOpen,
  });

  useEffect(() => {
    setLoading(false);
  }, [alertsHistoryState.data]);

  useEffect(() => {
    setLoading(false);
  }, [alertsUpcomingTouchesState.data]);

  useEffect(() => {
    setLoading(alertsUpcomingTouchesState.loading);
  }, [alertsUpcomingTouchesState.loading]);

  useEffect(() => {
    setLoading(false);
  }, [alertsPastDueTouchesState.data]);

  useEffect(() => {
    setLoading(alertsPastDueTouchesState.loading);
  }, [alertsPastDueTouchesState.loading]);

  useEffect(() => {
    setLoading(alertsInboxState.loading);
  }, [alertsInboxState.loading]);

  useEffect(() => {
    setLoading(false);
  }, [alertsHistoryState.sorting]);

  const handleTabChange = (nextTab: string) => {
    if (activeTab !== nextTab) {
      if (activeTab === '1' && alertsNewList.length && isOpen) {
        dispatch(alertActions.updateAlerts(alertsNewList));
      }

      setActiveTab(nextTab);

      if (nextTab === TABS.history) {
        setLoading(true);
        dispatch(actions.fetchAlertsHistory(initialState.history));
      }
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const pageNr = activeTab === TABS.history ? alertsHistoryState.pageNr : alertsInboxState.pageNr;
    const loaded = activeTab === TABS.history ? alertsHistoryState.loaded : alertsInboxState.loaded;
    const sorting =
      activeTab === TABS.history ? alertsHistoryState.sorting : alertsInboxState.sorting;
    const history = activeTab === TABS.history;

    if (loaded) return;
    const elm = e.currentTarget;

    if (Math.round(elm.scrollTop) + elm.clientHeight >= elm.scrollHeight - 1) {
      const payload: NotificationPayload = {
        history,
        pageNr,
        sorting,
      };
      dispatch(actions.loadMoreAlerts(payload));
    }
  };

  const handleTouchesScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const loaded = alertsPastDueTouchesState.loaded;

    if (loaded) return;
    const elm = e.currentTarget;

    if (Math.round(elm.scrollTop) + elm.clientHeight >= elm.scrollHeight - 1) {
      dispatch(actions.loadMorePastDueTouches(alertsPastDueTouchesState));
    }
  };

  const handleSort = () => {
    const payload = {
      sorting: alertsHistoryState.sorting === 'asc' ? 'desc' : 'asc',
    };
    setLoading(true);
    dispatch(actions.sortAlertsHistory(payload));
  };

  const handleDismissNotification = (id: number) => {
    onDismissNotification(id);
    const sorting = alertsInboxState.sorting;

    if (alertsInboxState.data.length <= 2) {
      setLoading(true);
      const payload: NotificationPayload = {
        history: false,
        pageNr: 0,
        sorting,
      };
      dispatch(actions.loadMoreAlerts(payload));
    }
  };

  const sortNotificationsButton = (
    <div>
      <Button type="text" onClick={handleSort}>
        {alertsHistoryState.sorting === 'desc' ? (
          <>
            <ArrowUpOutlined style={{ color: '#1890ff' }} />
            Newest to Oldest
          </>
        ) : (
          <>
            <ArrowDownOutlined style={{ color: '#1890ff' }} />
            Oldest to Newest
          </>
        )}
      </Button>
    </div>
  );

  return (
    <div className="recent-activity">
      <Drawer
        visible={isOpen}
        onClose={toggleModal}
        getContainer={() => document.getElementsByClassName('right-section')[0] as HTMLElement}
        title={
          <div className="notification-title">
            <span className="notifications-anticon-wrapper">
              <Badge
                className="ant-badge--notifications"
                count={
                  alertsInboxState.newAlertCount +
                  ((typeof alertsUpcomingTouchesData === 'object' &&
                    alertsUpcomingTouchesData.count) ||
                    0)
                }
                overflowCount={99}
              />
              <BellOutlined />
            </span>
            <span className="flex-grow-1">Notifications</span>
            <Button
              icon={<Icon iconName="map" className="map-icon" />}
              className="mr-5 roadmap"
              href={roadmapUrl}
              target="_blank"
            >
              View OPTX Roadmap
            </Button>
          </div>
        }
        className={modalClass}
        width="650"
        placement="left"
      >
        <Tabs
          onTabClick={key => handleTabChange(key)}
          animated={false}
          tabBarExtraContent={activeTab === TABS.history ? sortNotificationsButton : ''}
        >
          <TabPane
            key={TABS.inbox}
            className={classnames({
              active: activeTab === TABS.inbox,
            })}
            tab={`Inbox (${alertsInboxState.newAlertCount})`}
          >
            <div className="recent-activity-modal__content" onScroll={handleScroll}>
              <LoadingBlock loading={loading} />
              {alertsInboxState.data && !isEmpty(alertsInboxState.data) && !filtersLoading && (
                <div key={TABS.inbox + 1} className="notification-wrapper mt-0 mb-0">
                  <div className="notification-body pt-2">
                    <NotificationItems
                      name="inbox"
                      data={alertsInboxState.data}
                      onDismissNotification={handleDismissNotification}
                      views={views}
                    />
                  </div>
                </div>
              )}
            </div>
          </TabPane>
          <TabPane
            key={TABS.history}
            className={classnames({
              active: activeTab === TABS.history,
            })}
            tab="History"
          >
            <div className="recent-activity-modal__content" onScroll={handleScroll}>
              <LoadingBlock loading={loading} />
              {alertsHistoryState.data && !isEmpty(alertsHistoryState.data) && (
                <div key={TABS.history + 2} className="notification-wrapper mt-0 mb-0">
                  <div className="notification-body pt-2">
                    <NotificationItems
                      name="history"
                      data={alertsHistoryState.data}
                      views={views}
                    />
                  </div>
                </div>
              )}
            </div>
          </TabPane>
          <TabPane
            key={TABS.upcomingTouches}
            className={classnames({
              active: activeTab === TABS.upcomingTouches,
            })}
            tab={`Upcoming Touches (${
              (typeof alertsUpcomingTouchesData === 'object' && alertsUpcomingTouchesData.count) ||
              0
            })`}
          >
            <div className="recent-activity-modal__content">
              <LoadingBlock loading={loading} />
              {typeof alertsUpcomingTouchesData === 'object' &&
                alertsUpcomingTouchesData.count !== 0 && (
                  <div key={TABS.upcomingTouches + 3} className="notification-wrapper mt-0 mb-0">
                    <div className="notification-body pt-2">
                      <TouchesNotificationItems
                        name="upcomingTouches"
                        data={alertsUpcomingTouchesData}
                        onDismissTouch={onDismissUpcomingTouch}
                      />
                    </div>
                  </div>
                )}
            </div>
          </TabPane>
          <TabPane
            key={TABS.pastDue}
            className={classnames({
              active: activeTab === TABS.pastDue,
            })}
            tab="Past Due"
          >
            <div className="recent-activity-modal__content" onScroll={handleTouchesScroll}>
              <LoadingBlock loading={loading} />
              {typeof alertsPastDueTouchesData === 'object' &&
                alertsPastDueTouchesData.count !== 0 && (
                  <div key={TABS.pastDue + 4} className="notification-wrapper mt-0 mb-0">
                    <div className="notification-body pt-2">
                      <TouchesNotificationItems
                        name="pastDueTouches"
                        data={alertsPastDueTouchesData}
                        onDismissTouch={onDismissPastDueTouch}
                      />
                    </div>
                  </div>
                )}
            </div>
          </TabPane>
        </Tabs>
      </Drawer>
    </div>
  );
};

RecentActivity.defaultProps = {
  isOpen: false,
  alertsInboxState: initialState.inbox,
  alertsHistoryState: initialState.history,
};

export default RecentActivity;
