import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
// models
import { EquityTouchContact } from '@models/equityTouch';
import { BulkFormType } from '@models/bulkActions';
import { SelectOption } from '@models/Option';
// redux
import { useInject as useInjectCompanyIndividualEdit } from '@components/feature/company-individual-edit';
import { useInject as useInjectCompanyCard } from '@features/long-card/company-card';
import {
  actions as salesloftActions,
  selectors as salesloftSelectors,
} from '@redux/contacts/salesloft';
import { actions as eqtBulkActions } from '@features/bulk-actions/equity-touch';
// hooks
import { useInjectSalesloft } from '@hooks/inject';
import { useSalesloftAuth } from '@optx/common/hooks/salesloft';
import { useInitFilters } from '@optx/common/hooks/init';
// components
import ErrorComponent from '@optx/components/common/ErrorComponent';
import { Context } from './common/Context';
import Header from './header/Header';
import Steps from './steps/Steps';

const EquityTouchBulk: React.FC = () => {
  useInjectSalesloft();
  useInjectCompanyIndividualEdit();
  useInitFilters();
  useInjectCompanyCard();
  const location = useLocation();

  // @ts-ignore
  const { grid, step: stepNumber } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);
  const [error, setError] = useState<boolean>();
  const [title, setTitle] = useState('');
  const [submitForm, setSubmitForm] = useState<BulkFormType>(null);
  const [cadence, setCadence] = useState<number | undefined>(undefined);
  const [selectedContacts, setSelectedContacts] = useState<EquityTouchContact[]>([]);
  const [addon, setAddon] = useState<SelectOption | undefined>(undefined);
  const [isAddon, setIsAddon] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const cadences = useSelector(salesloftSelectors.getCadences);
  const { fetchSalesloftAuth } = useSalesloftAuth();

  const fetchCadences = useCallback(
    (payload: string, cb?: () => void) =>
      dispatch(salesloftActions.fetchSalesloftCadences(payload, cb)),
    [dispatch]
  );

  const initializeEquityTouchBulk = useCallback(() => {
    dispatch(
      eqtBulkActions.initializeEquityTouchBulk({
        grid,
        ...((location.state as any)?.listId !== undefined &&
          (location.state as any)?.listType !== '' && {
            listId: (location.state as any)?.listId,
            listType: (location.state as any)?.listType,
          }),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, grid]);

  useEffect(() => {
    const whichStep = Number(stepNumber) - 1;
    setStep(whichStep);

    if (stepNumber !== '1' && stepNumber !== '2' && stepNumber !== '3') {
      setError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    history.replace(`/equity-touch-bulk/step-${Number(step + 1)}/${grid}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (cadences.length <= 1) {
      fetchCadences('', () => {
        fetchSalesloftAuth('', url => {
          window.open(
            url,
            'popUpWindow',
            // eslint-disable-next-line max-len
            'height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
          );
        });
      });
    }

    initializeEquityTouchBulk();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) return <ErrorComponent />;

  return (
    <Context.Provider
      value={{
        step,
        setStep,
        title,
        setTitle,
        submitForm,
        setSubmitForm,
        cadence,
        setCadence,
        selectedContacts,
        setSelectedContacts,
        addon,
        setAddon,
        isAddon,
        setIsAddon,
        errors,
        setErrors,
      }}
    >
      {step !== 2 && <Header />}
      <Steps />
    </Context.Provider>
  );
};

export default EquityTouchBulk;
