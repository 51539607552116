import React from 'react';
import classNames from 'classnames';
// styles
import './styles.scss';
// components
import { IconCompany } from '../../../icons';

interface CompanyAvatarProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  logoUrl: string | null;
  companyName: string;
}

const CompanyAvatar: React.FC<CompanyAvatarProps> = ({
  companyName,
  logoUrl,
  className,
  ...restProps
}) => {
  return (
    <div className={classNames('company-avatar', className)} {...restProps}>
      {logoUrl ? <img src={logoUrl} alt={companyName} /> : <IconCompany />}
    </div>
  );
};

export default React.memo(CompanyAvatar);
