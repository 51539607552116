import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// models
import { FilterTag } from '@optx/models/tags';
// redux
import {
  actions as searchContactsActions,
  selectors as searchContactsSelectors,
} from '@redux/contacts/search/search';
import { actions as filtersModalActions } from '@redux/ui/modals/gridModals';
// components
import { FilterTagsOverflow } from '@optx/features/tags/overflow-tags';

const FilterTags = () => {
  const dispatch = useDispatch();
  const options = useSelector(searchContactsSelectors.getTags);
  const toggleFiltersModal = useCallback(
    () => dispatch(filtersModalActions.toggleModal({ gridKey: 'contacts' })),
    [dispatch]
  );

  const handleFilterRemove = (tag: FilterTag) => () => {
    dispatch(searchContactsActions.removeFilter(tag));
  };

  const filteredOptions = useMemo(
    () => options.filter(item => !item.isDefault && item.values?.length > 0),
    [options]
  );

  return (
    <FilterTagsOverflow
      options={filteredOptions}
      onClick={toggleFiltersModal}
      onClose={handleFilterRemove}
      multipleValue
    />
  );
};

export default FilterTags;
