import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
// models
import Company from '@optx/models/Company';
// constants
import { cardRowGutter } from '../../../../../constants/spacing';
// components
import GoogleNews from './GoogleNews';

interface NewsFeedProps {
  company: Company;
}

const GoogleNewsCol = styled(Col)`
  margin-left: auto;
`;

const NewsFeed = ({ company }: NewsFeedProps) => (
  <Row gutter={cardRowGutter}>
    <GoogleNewsCol span={24} xl={12}>
      <GoogleNews url={company.links && company.links.GoogleNews} />
    </GoogleNewsCol>
  </Row>
);

export default NewsFeed;
