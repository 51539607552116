import React from 'react';
import { Tooltip } from 'antd';
// models
import { TableCellSearches } from '@optx/models/table/Cell';
import { ColumnKeys } from '@optx/models/table/Columns';
// utils
import { getColumnHeaders } from '@optx/utils/columnHeaders';
// hooks
import useCompanySavedSearches from '@hooks/saved-searches/useCompanySavedSearches';

const TableCellSorting: React.FC<TableCellSearches> = ({ row }) => {
  const { handleSearchResults } = useCompanySavedSearches();

  const { sortBy } = row;

  const getColumns = () => {
    return getColumnHeaders(
      sortBy ? sortBy.map(sortColumn => sortColumn.id as ColumnKeys) : []
    ).join(', ');
  };

  const handleClick = () => {
    if (row.unique_id) {
      handleSearchResults(row.unique_id, row.title, row.search_info.type || '');
    }
  };

  return (
    <div onClick={handleClick} role="presentation">
      {sortBy ? <Tooltip title={getColumns}>Yes</Tooltip> : 'No'}
    </div>
  );
};

export default TableCellSorting;
