import { PipelineInformationState } from './interfaces';

export const selectPipelineInformation = (state: PipelineInformationState) => state.data;

export const selectCount = (state: PipelineInformationState) =>
  state.data ? state.data.companies_count : 0;

export const selectOtherDealStages = (state: PipelineInformationState) =>
  state.data ? state.data.other_deal_stages : [];

export const selectPipelineInfo = (state: PipelineInformationState) =>
  state.data ? state.data.pipeline_data : [];

export const selectSelectedPeriodDate = (state: PipelineInformationState) =>
  state.data ? state.data.selected_period_date : null;

export const selectPreviousPeriodDate = (state: PipelineInformationState) =>
  state.data ? state.data.previous_period_date : null;
