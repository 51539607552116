import React from 'react';
import { useSelector } from 'react-redux';
import { ClockCircleOutlined } from '@ant-design/icons';
// components
import { StatsCompareAndSource } from '@optx/shared/view/molecules/statistics';
// redux
import { selectors as CompaniesETSelectors } from '../state';
// styles
import StyledStatsSection from './StatsSection/StatsSection.styles';

export const CompaniesInETStatistics = () => {
  const mergeHistoryData = useSelector(CompaniesETSelectors.highlights.etMergeData);

  return (
    <StyledStatsSection.SubSection>
      <StyledStatsSection.SubSectionTitle>
        <ClockCircleOutlined />
        {mergeHistoryData?.title}
      </StyledStatsSection.SubSectionTitle>
      {mergeHistoryData?.items.map((item, index) => {
        const listenIndex = index % 2 === 0;
        if (!listenIndex) return null;

        const mainValue = mergeHistoryData?.items[index].value;
        const combineValue = mergeHistoryData?.items[index + 1].value;

        return (
          <StatsCompareAndSource
            key={index}
            title={mergeHistoryData?.items[index].label}
            isPercentage
            values={[mainValue, combineValue]}
          />
        );
      })}
    </StyledStatsSection.SubSection>
  );
};

export default CompaniesInETStatistics;
