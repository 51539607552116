import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  ReactText,
  useMemo,
  SyntheticEvent,
} from 'react';
import queryString from 'query-string';
import Company from '@optx/models/Company';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useVT } from 'virtualizedtableforantd4';
import { useSize } from '@umijs/hooks';
import { pick } from 'lodash';
import { ResizeCallbackData } from 'react-resizable';
// utils
import { checkOverflow } from '@optx/utils/utils';
// models
import { ColumnType } from 'antd/lib/table';
import { ColumnKeys } from '@optx/models/table/Columns';
import { TableChangeCallback } from '@optx/models/table/antd-table';
import { SortRule } from '@optx/models/table/sorting';
// constants
import { BULK_ACTIONS_FIELDS } from '@constants/equitytouch';
import defaultColumns from '@components/common/table/Companies/columns/virtual-column-config';
// utils
import { mapSorting } from '@utils/table/sorting';
import { stripUrlSlashes } from '@utils/url';
import scrollTableOnSorting from '@optx/utils/scrollTable';
// redux
import { actions as selectedCompaniesActions } from '@features/bulk-actions/selected-companies';
import { actions as mergeCompaniesActions } from '@features/bulk-actions/bulk-merge-companies';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { selectors as searchCountSelectors } from '@features/grid/search-count';
import * as sortActions from '@redux/company/search/sort/actions';
import {
  selectors as columnSelectors,
  actions as columnActions,
} from '@redux/company/search/columns';
import { getSessionSettings } from '@redux/user/information/selectors';
import { selectors as fetchCountSelectors } from '@optx/features/grid/fetched-count';
import { selectors as advancedSearchSearchSelectors } from '@redux/company/search/search';
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as paginationSelectors } from '@features/grid/pagination';
// hooks
import useNonVirtualizedTable from '@hooks/useNonVirtualizedTable';
import useIsMount from '@hooks/useIsMount';
import { useVirtualScrollPosition, VirtualTableScrollYContext } from '@features/scroll-history';
import useTableLayoutHeight from '@hooks/useTableLayoutHeight';
import useIsAnalyst from '@hooks/useIsAnalyst';
import useMultiSortNumbers from '@hooks/useMultiSortNumbers';
import useRefreshView from '@hooks/saved-searches/useRefreshView';
import useNavigateOnCompanyNameClick from '@optx/common/hooks/useNavigateOnCompanyNameClick';
// components
import { Styled } from '@components/common/table/Companies/CompanyTable.styled';
import { ResizeColumn } from '@optx/features/company-search/lib/Table/columns/ResizeColumn';
import { COMPANY_DEFAULT_SORT } from '@optx/constants/table/sort/defaultSort';
import DisplayColumnModal from './DisplayColumnModal';
import * as selectors from './PageLoader/selectors';
import { GridPagination } from '@features/grid/pagination/components';
import ExternalScroll from '../../common/external-scroll';
import SearchGridTable from './SearchGridTable';

const rowKey: keyof Company = 'company_id';
const paginationId = 'advanced-search-pagination';
let isMouseDown = false;

const SearchGrid: React.FC = () => {
  useRefreshView('advancedSearch');
  useNavigateOnCompanyNameClick('advancedSearch');
  // hooks
  const dispatch = useDispatch();
  const isMount = useIsMount();
  const location = useLocation();
  const virtualizedActive = useNonVirtualizedTable();
  const { advancedSearchPathname } = useIsAnalyst();
  const { onScroll, initialScrollPosition } = useVirtualScrollPosition(advancedSearchPathname);
  const [windowSize] = useSize(window.document.documentElement);
  const ctx = useContext(VirtualTableScrollYContext);
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const vtImperativeScroll = useRef<any>();
  // useSize triggers a typescript error for class selectors,
  // but works fine with tag selectors
  // @ts-ignore
  const [tableHeader] = useSize(document.querySelector('.ant-table-header'));
  const [mainHeaderHeight] = useSize(document.getElementById('mainHeader'));

  // selectors
  const isLoading = useSelector(selectors?.isLoading);
  const initCompanies = useSelector(searchSelectors.getCompanies('advancedSearch'));
  const reduxColumns = useSelector(columnSelectors.getColumns);
  const sorting = useSelector(advancedSearchSearchSelectors.getSorting);
  const isMultiSort = useSelector(advancedSearchSearchSelectors.isMultiSort);
  const sessionSettings = useSelector(getSessionSettings);
  const fetchedCount = useSelector(fetchCountSelectors.getFetchedCount('advancedSearch'));
  const { pageNumber, pageSize } = useSelector(paginationSelectors.getPagination('advancedSearch'));
  const isLoadingCount = useSelector(searchCountSelectors.getSearchCountLoading('advancedSearch'));
  const searchTitle = useSelector(searchSelectors.getSearchName('advancedSearch'));
  const totalItemsCount = useSelector(searchCountSelectors.getSearchCount('advancedSearch'));
  const getMergeCompaniesStatus: boolean = useSelector(
    bulkActionsSelectors.mergeCompanies.getMergeCompaniesStatus
  );
  const gridSelectedCompanyIds = useSelector(
    bulkActionsSelectors.selectedCompanies.getSelectedCompanies('advancedSearch')
  );

  // state
  const [selectedRowKeys, setSelectedRowKeys] = useState<ReactText[]>([]);
  const [columns, setColumns] = useState(defaultColumns);
  const [currentScroll, setCurrentScroll] = useState(0);
  const [externalScroll, setExternalScroll] = useState<Element | null>(null);

  const [vt, setVt] = useVT(
    () => ({
      initTop: initialScrollPosition,
      scroll: { y: currentScroll },
      onScroll,
      ref: vtImperativeScroll,
    }),
    [currentScroll, initialScrollPosition]
  );

  const searchParams = queryString.parse(location.search);

  const externalScrollElement = document.querySelector('.external-scroll');

  const contentRef = useRef<HTMLDivElement | null>(
    tableWrapperRef.current?.querySelector('.ant-table-body') || null
  );

  if (!contentRef.current && tableWrapperRef.current) {
    contentRef.current = tableWrapperRef.current.querySelector('.ant-table-body') || null;
  }

  const [tableSize] = useSize(
    () => document.querySelector('.advanced-companies-grid-wrapper table') as HTMLElement
  );

  useMultiSortNumbers(tableWrapperRef, sorting, isMultiSort, isLoading);

  useEffect(() => {
    if (externalScrollElement !== null) {
      setExternalScroll(externalScrollElement);
    }
  }, [externalScrollElement]);

  const handleChange: TableChangeCallback<Company> = (pagination, filters, sorter, extra) => {
    switch (extra.action) {
      case 'sort': {
        // while resizing columns, prevent sorting from being triggered.
        // this happens when decreasing the width of a column and letting go of
        // the mouse button over the column header
        if (!isMouseDown) {
          const parsedSort =
            sessionSettings?.company_filters &&
            queryString.parse(sessionSettings?.company_filters, {
              arrayFormat: 'comma',
            });

          const sort = mapSorting(
            sorter,
            sorting,
            parsedSort && (parsedSort?.query as string),
            COMPANY_DEFAULT_SORT,
            isMultiSort
          );
          dispatch(sortActions.changeSortAction(sort as SortRule<any>));

          // scroll table to the left when sorting, issue for firefox
          scrollTableOnSorting(tableWrapperRef);
        }

        break;
      }

      default:
        break;
    }
  };

  const onSelectChange = (selectedRowKeys: ReactText[], selectedRows: Company[]) => {
    const selectedCompaniesData = selectedRows.map(company => {
      return {
        ...pick(company, BULK_ACTIONS_FIELDS),
        company_url: company.company_url ? stripUrlSlashes(company.company_url) : null,
      };
    });

    setSelectedRowKeys(selectedRowKeys);
    dispatch(
      selectedCompaniesActions.selectCompanyIds({
        gridName: 'advancedSearch',
        // selectedCompanies object properties are picked from Company model
        // strange conflict between Partial and Pick
        // @ts-ignore
        selectedCompanies: selectedCompaniesData,
      })
    );
  };

  const handleResizeStart = () => {
    isMouseDown = true;
  };

  const handleResizeStop = (e: SyntheticEvent, data: ResizeCallbackData) => {
    dispatch(
      columnActions.saveColumnWidth({
        userSetting: 'column_widths_advanced_search',
        columnId: data.node.offsetParent?.classList[1] as ColumnKeys,
        width: data.size.width,
      })
    );
    setTimeout(() => {
      isMouseDown = false;
    }, 0);
  };

  useMemo(
    () =>
      setVt({
        header: {
          cell: props => {
            return (
              <ResizeColumn
                handleResizeStart={handleResizeStart}
                handleResizeStop={handleResizeStop}
                {...props}
              />
            );
          },
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setVt]
  );

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
    getCheckboxProps: (record: Company) => ({
      name: record.company_id.toString(),
    }),
  };

  const updateFinalColumns = (columns: ColumnType<any>[]) => {
    return columns.map((col: any, index: number) => ({
      ...col,
      onHeaderCell: (column: any) => ({
        width: column.width,
        onResize: handleResize(index),
      }),
      ...(virtualizedActive
        ? {}
        : {
            onCell: () => ({
              style: {
                'max-width': '409px',
                'min-width': '120px',
              },
            }),
          }),
    }));
  };

  const mergedColumns = useMemo(() => {
    return updateFinalColumns(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, virtualizedActive]);

  const handleResize = (index: number) => (e: any, other: any) => {
    const nextColumns = [...columns];
    nextColumns[index] = {
      ...nextColumns[index],
      width: other.size.width,
    };

    setColumns(nextColumns);
  };

  useEffect(() => {
    // @ts-ignore
    setColumns(reduxColumns);
  }, [reduxColumns]);

  useEffect(() => {
    if (gridSelectedCompanyIds.length) {
      setSelectedRowKeys(gridSelectedCompanyIds.map(company => company.company_id));
    } else {
      setSelectedRowKeys([]);
    }
  }, [gridSelectedCompanyIds]);

  useEffect(() => {
    // fix table being cropped when navigating to other pages and coming back
    setTimeout(() => {
      vtImperativeScroll.current?.scrollTo(initialScrollPosition);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Each time a new search success is made reset scroll.
   */
  useEffect(() => {
    if (!isMount && !getMergeCompaniesStatus) {
      vtImperativeScroll.current?.scrollTo(0);
    }

    if (getMergeCompaniesStatus) {
      dispatch(mergeCompaniesActions.invertStatusAfterMerge());
    }

    if (selectedRowKeys.length) {
      setSelectedRowKeys([]);
      dispatch(
        selectedCompaniesActions.selectCompanyIds({
          gridName: 'advancedSearch',
          selectedCompanies: [],
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedCount]);

  useEffect(() => {
    if (!isMount && !getMergeCompaniesStatus) {
      vtImperativeScroll.current?.scrollTo(0);
    }

    if (getMergeCompaniesStatus) {
      dispatch(mergeCompaniesActions.invertStatusAfterMerge());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const { tableScroll } = useTableLayoutHeight(
    tableWrapperRef.current,
    paginationId,
    windowSize.height,
    tableHeader.height,
    mainHeaderHeight.height,
    [isLoadingCount, ctx.height]
  );

  useEffect(() => {
    setCurrentScroll(tableScroll);
  }, [tableScroll]);

  return (
    <Styled.CompanyTableWrapper>
      {initCompanies.length && !isLoading && !searchParams.usefor ? (
        <ExternalScroll refElement={contentRef} contentWidth={tableSize.width} isVirtualGrid />
      ) : null}
      <div className="advanced-companies-grid-wrapper" ref={tableWrapperRef}>
        {searchParams.usefor && searchParams.usefor === 'testing' ? (
          <div className="customTableWrap">
            <SearchGridTable companies={initCompanies} columns={mergedColumns} />
          </div>
        ) : (
          <Styled.CompanyTable
            size="small"
            rowKey={rowKey}
            rowSelection={rowSelection}
            columns={mergedColumns}
            dataSource={initCompanies}
            pagination={false}
            bordered
            onChange={handleChange}
            showSorterTooltip={false}
            // virtualization
            scroll={{ y: currentScroll, x: true }} // It's important for using VT!!! DO NOT FORGET!!!
            components={virtualizedActive ? vt : undefined}
            isEmpty={!initCompanies.length}
            overflowHeight={checkOverflow(externalScroll)}
          />
        )}
        <DisplayColumnModal />

        <GridPagination
          id={paginationId}
          gridKey="advancedSearch"
          pageNumber={pageNumber}
          pageSize={pageSize}
          searchCountPageSize={initCompanies.length}
          totalItemsCount={totalItemsCount}
          isLoadingCount={isLoadingCount}
          selectedCountType="advancedSearch"
          searchTitle={searchTitle}
          listType={{
            one: 'Company',
            many: 'Companies',
          }}
          isLoadingList={isLoading}
        />
      </div>
    </Styled.CompanyTableWrapper>
  );
};

export default SearchGrid;
