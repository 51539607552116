import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// models
import { RadioFilterColumns } from '@optx/models/feature/analystsLeaderboard/filters';
import { AppState } from '@optx/redux/interfaces';
import { SelectOption } from '@optx/models/Option';
// hooks
import useSingleSelect from '@optx/common/hooks/filters/useSingleSelect';
// components
import SingleSelectButtons from '@components/common/filters/SingleSelectButtons';
// Local
// redux
import { selectors, actions } from '../state';

interface FilterSingleSelectButtonsProps {
  filterKey: RadioFilterColumns;
  criteria?: string;
}

const FilterSingleSelectButtons: React.FC<FilterSingleSelectButtonsProps> = ({
  filterKey,
  criteria,
}) => {
  const dispatch = useDispatch();
  const stageChangeStatuses = useSelector((state: AppState) =>
    selectors.filters.getSelectOptions(state, filterKey)
  ) as Array<SelectOption>;
  const value = useSelector((state: AppState) =>
    selectors.filters.getRadioValue(state, filterKey, criteria)
  );
  const updateValue = useCallback(
    (newValue: string) =>
      dispatch(actions.filters.updateValue({ value: newValue, filterKey, criteria })),
    [dispatch, filterKey, criteria]
  );
  const { options, onClick } = useSingleSelect(value, stageChangeStatuses, updateValue);

  return <SingleSelectButtons options={options} onClick={onClick} />;
};

export default FilterSingleSelectButtons;
