// generic selectors for saved searches
import { Dictionary, reduce } from 'lodash';
import { SearchSave } from '@optx/models/search';
import { ContactsSavedSearchesState } from './interfaces';

export const getSavedSearchesSelector = (state: ContactsSavedSearchesState) => {
  const { allIds, byId } = state;

  return allIds.map(id => byId[id]);
};

export const getAllViewsSelector = (state: ContactsSavedSearchesState) => {
  const { viewIds, views } = state;

  if (Object.keys(views).length) {
    return viewIds.map(id => views[id]);
  }

  return [];
};

export const getTitlesSelector = (state: ContactsSavedSearchesState) => {
  const { byId } = state;

  return reduce<Dictionary<SearchSave>, Dictionary<boolean>>(
    byId,
    (acumulator, item) => {
      const newAcumulator = acumulator;
      newAcumulator[item.title] = true;

      return newAcumulator;
    },
    {}
  );
};
