import { call, put, takeLatest } from 'redux-saga/effects';
// services
import NotificationService from '@services/NotificationService';
import { UserService } from '@services/api';
// redux
import { AxiosResponse } from 'axios';
import { RegionOptions } from '@optx/models/user';
import * as actions from './actions';

export function* fetchRegionOptionsSaga() {
  try {
    const res: AxiosResponse<RegionOptions[]> = yield call(UserService.getRegionOptions);

    if (res.data) {
      yield put(actions.fetchRegionOptionsSuccess(res.data));
    } else {
      const errorMessage = 'Failed to fetch Region Settings!!!';
      yield put(actions.fetchRegionOptionsFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = 'Failed to fetch Region Settings!!!';
    yield put(actions.fetchRegionOptionsFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* regionSettingsSaga() {
  yield takeLatest(actions.fetchRegionOptions, fetchRegionOptionsSaga);
}
