import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 0;
  align-items: center;
`;

const Email = styled.a`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  /* Neutral / Gray */
  color: #90a0b7;
`;

const Name = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* Dark / White extra */
  color: #afbdd1;
  margin: 0;
`;

const UserAvatar = styled.div`
  width: 46px;
  height: 46px;
  overflow: hidden;
  border-radius: 50%;

  img {
    max-width: 100%;
  }
`;

export default {
  Wrapper,
  Email,
  Name,
  UserAvatar,
};
