import React from 'react';
// components
import { ColumnsType } from 'antd/lib/table/interface';
import { SearchSave } from '@optx/models/search';
import { TableCellFilters, TableCellSearch, TableCellSorting } from '../cells/suggestedsearches';
// models

const suggestedSearchColumns: ColumnsType<SearchSave> = [
  {
    key: 'title',
    dataIndex: 'title',
    title: 'Search Name',
    render: (value, row) => <TableCellSearch value={value} row={row} />,
    width: 50,
  },
  {
    key: 'filters',
    dataIndex: 'filters',
    title: 'Filters Applied',
    render: (value, row) => <TableCellFilters value={value} row={row} />,
    width: 150,
  },
  {
    key: 'sortBy',
    dataIndex: 'sortBy',
    title: 'Sorting',
    render: (value, row) => <TableCellSorting value={value} row={row} />,
    width: 150,
  },
];

export default suggestedSearchColumns;
