import React from 'react';
import styled, { css } from 'styled-components';
import { CheckCircleTwoTone } from '@ant-design/icons';
// components
import Icon from '@optx/components/common/Icon';

interface VerifyButtonProps {
  isVerified: boolean;
  onClick: () => void;
}

interface ButtonProps {
  type: string;
  onClick: () => void;
  isVerified?: boolean;
}
const ButtonStyle = styled.button<ButtonProps>`
  ${({ isVerified }) =>
    !isVerified &&
    css`
      padding: 4px 5px 5px 6px;
      border-radius: 50%;
      border: 1px solid #52c41a;
      width: 30px;
      height: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: 6px;
      top: -5px;
      position: absolute;
    `};

  .anticon-check-circle {
    position: relative;
    top: 3px;
  }
`;

const VerifyButton: React.FC<VerifyButtonProps> = ({ isVerified, onClick }) => {
  return (
    <ButtonStyle type="button" onClick={onClick} isVerified={isVerified}>
      {isVerified ? (
        <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 19 }} />
      ) : (
        <Icon iconName="thumbs-up" style={{ color: '#E8E8E8', fontSize: 19 }} />
      )}
    </ButtonStyle>
  );
};

export default VerifyButton;
