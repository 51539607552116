import styled from 'styled-components';
import { styledColors } from '@utils/theme/colors';
import DefaultTable from '@shared/view/organims/Table';

const Table = styled(DefaultTable)`
  .row_actions {
    .anticon-ellipsis:hover {
      color: ${styledColors.blue6};
    }
  }
`;

export const Styled = {
  Table,
};
