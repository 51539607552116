import React from 'react';
import { Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
// models
import { Notification } from '@optx/models/user';
import { SuccessErrorCallback } from '@optx/models/callback';
// services
import NotificationService from '@optx/services/NotificationService';
// redux
import { actions, selectors as userSelectors } from '@redux/user/information/';
import { selectors as companySelectors } from '@redux/company/filters';

import { Container, Title } from './CellNotificationStyled';

interface CellNotificatioOptions {
  typeOptions: 'deliver' | 'frequency';
  value: string;
  rowIndex: number;
  record: Notification;
}

const CellNotificationOptions: React.FC<CellNotificatioOptions> = ({
  typeOptions,
  value,
  rowIndex,
  record,
}) => {
  const dispatch = useDispatch();
  const recommendedOptions = useSelector(userSelectors.getDefaultRecommendedOptxNotifications);
  const options = useSelector(companySelectors.getAlertSettings);

  const fieldKey = Object.keys(recommendedOptions);

  const callback: SuccessErrorCallback = (success, error) => {
    if (error) {
      NotificationService.error('OPTX Recommended Notifications not updated!');
    } else {
      success && NotificationService.success('OPTX Recommended Notifications was updated!');
    }
  };

  const handleClick = (option: string) => {
    const newValue = {
      ...record,
      [typeOptions]: option,
    };

    const payload = { ...recommendedOptions, [fieldKey[rowIndex]]: newValue };

    dispatch(
      actions.updateUserSettings(
        {
          alert_settings: payload,
        },
        callback
      )
    );
  };

  const handleOptions = (value: string) => {
    if (value === 'frequency') {
      return options.frequency;
    }

    return options.deliver;
  };

  const menu = (
    <Menu style={{ width: `${typeOptions === 'deliver' ? '175px' : '140px'}` }}>
      {handleOptions(typeOptions)?.map(value => (
        <Menu.Item style={{ textTransform: 'capitalize' }} onClick={() => handleClick(value)}>
          {value.replace('_', ' ')}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Container>
      <Dropdown
        trigger={['click']}
        overlay={menu}
        placement={'bottomCenter'}
        children={
          <div className={'dropdown-trigger-wrapper'}>
            <Title>{value?.replace('_', ' ')}</Title>
            <DownOutlined
              style={{ color: '#096dd9', alignItems: 'center', justifyContent: 'flex-end' }}
            />
          </div>
        }
      ></Dropdown>
    </Container>
  );
};

export default CellNotificationOptions;
