import { createAction } from '@reduxjs/toolkit';
import * as types from './types';
import * as models from './interfaces';

export const updateCompanyPortfolio = createAction<models.CompanyPortfolio>(
  types.ADD_COMPANY_PORTFOLIO
);

export const updateCompanyPortfolioSuccess = createAction<models.CompanyPortfolio>(
  types.ADD_COMPANY_PORTFOLIO_SUCCESS
);

export const updateCompanyPortfolioFail = createAction<string>(types.ADD_COMPANY_PORTFOLIO_FAIL);
