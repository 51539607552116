import { createAction } from '@reduxjs/toolkit';
import { CompanyUserValue } from '@models/Company';
import { StageResponse } from '@optx/components/feature/company-individual-edit/state/saga/interfaces';
import { SuccessErrorCallback } from '@optx/models/callback';
import * as types from './types';
import {
  OptxScoreUpdateResponse,
  TrendingLinesUpdate,
  EmployeeGrowthPercentage,
} from './interfaces';

export const updateCompanyUserValues = createAction(
  types.UPDATE_COMPANY_USER_VALUES,
  (payload: CompanyUserValue, callback?: (data: SuccessErrorCallback) => void) => ({
    payload,
    meta: callback,
  })
);

export const updateCompanyOptxScoreSuccess = createAction(
  types.UPDATE_COMPANY_OPTX_SCORE,
  (res: OptxScoreUpdateResponse | StageResponse) => ({
    payload: {
      score: res.optx_score,
      il_score: res.il_optx_score,
      il_score_growth: res.optx_score_growth,
      scoreGrowth: res.optx_score_growth,
    },
  })
);

export const updateCompanyTrendingLines = createAction<TrendingLinesUpdate>(
  types.UPDATE_COMPANY_TRENDING_LINES
);

export const updateCompanyUserValuesSuccess = createAction<CompanyUserValue>(
  types.UPDATE_COMPANY_USER_VALUES_SUCCESS
);

export const updateCompanyEmployeeGrowthPercentage = createAction<EmployeeGrowthPercentage>(
  types.UPDATE_COMPANY_EMPLOYEE_GROWTH_PERCENTAGE
);
