/* eslint-disable no-nested-ternary */
import React from 'react';
import { Row, Col, Rate, Typography } from 'antd';
import numeral from 'numeral';
import { Styles } from './LineChart.style';

interface ChartHeaderProps {
  stars?: number;
  icon: React.ReactNode;
  title: string;
  headerValue: number;
  headerLabel: string;
  headerValueExtra?: number;
  headerLabelExtra?: string;
}

const ChartHeader: React.FC<ChartHeaderProps> = ({
  stars,
  icon,
  title,
  headerValue,
  headerLabel,
  headerLabelExtra,
  headerValueExtra,
}) => {
  return (
    <Row>
      <Styles.ChartHeaderLogoColumn span={10}>
        <Typography.Title level={4}>
          {icon} {title}
        </Typography.Title>
      </Styles.ChartHeaderLogoColumn>
      <Col span={14}>
        <Row justify="end">
          {stars ? (
            <Col span={10}>
              <Styles.RatingLabel>{stars} / 5 Overall Stars</Styles.RatingLabel>
              <Rate disabled allowHalf value={stars} />
            </Col>
          ) : (
            ''
          )}
          <Styles.ChartHeaderColumn span={8}>
            <span className="value">
              {' '}
              {typeof headerValue === 'number' ? numeral(headerValue).format('0,0') : 'N/A'}
            </span>
            <span className="label">{headerLabel}</span>
          </Styles.ChartHeaderColumn>
          {headerValueExtra && (
            <Styles.ChartHeaderColumn span={8}>
              <span className="value">{numeral(headerValueExtra).format('0,0')}</span>
              <span className="label">{headerLabelExtra} </span>
            </Styles.ChartHeaderColumn>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default ChartHeader;
