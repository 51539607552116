import React from 'react';
import { Result } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
// redux
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { SelectedCompaniesGrids } from '@optx/models/bulkActions';

const ResultWrapper = styled(Result)`
  padding: 48px 32px;
`;

interface CompletedProps {
  fieldName: string;
  gridName: SelectedCompaniesGrids;
}

const Completed: React.FC<CompletedProps> = ({ fieldName, gridName }) => {
  const successfulCount = useSelector(
    fieldName === 'Software'
      ? bulkActionsSelectors.software.getSuccessCount
      : fieldName === 'AI / ML'
      ? bulkActionsSelectors.aiMl.getSuccessCount
      : bulkActionsSelectors.editFields.successfulCount
  );
  const totalCount = useSelector(
    bulkActionsSelectors.selectedCompanies.getSelectedCompanies(gridName)
  );

  let proccessName: string;

  if (fieldName === 'Watchlist') {
    proccessName = 'added to the Watchlists';
  } else if (fieldName === 'AI / ML') {
    proccessName = 'Updated for AI / ML';
  } else {
    proccessName = 'Set as Software Company';
  }

  return (
    <>
      {fieldName === 'Watchlist' || fieldName === 'Software' || fieldName === 'AI / ML' ? (
        <ResultWrapper
          status={!successfulCount ? 'error' : 'success'}
          title={`${successfulCount || 0}/${totalCount.length} ${
            totalCount.length === 1 ? 'Company' : 'Companies'
          } ${successfulCount === 0 ? proccessName : `Successfully ${proccessName}`}`}
        />
      ) : (
        <ResultWrapper
          status={!successfulCount ? 'error' : 'success'}
          title={`${successfulCount || 0}/${totalCount.length} ${
            totalCount.length === 1 ? 'Company' : "Companies'"
          } ${fieldName === 'Company Owner' ? 'Owner' : fieldName} Successfully Set`}
          subTitle="It may take up to 5 minutes for the data to show in  Equity Touch"
        />
      )}
    </>
  );
};

export default Completed;
