import { createFeatureActionType } from '@optx/utils/feature';
import { featureName } from '../../featureName';

export const FETCH_INSIGHTS_STATS = createFeatureActionType(featureName, 'FETCH_INSIGHTS_STATS');
export const FETCH_INSIGHTS_STATS_SUCCESS = createFeatureActionType(
  featureName,
  'FETCH_INSIGHTS_STATS_SUCCESS'
);
export const FETCH_INSIGHTS_STATS_FAIL = createFeatureActionType(
  featureName,
  'FETCH_INSIGHTS_STATS_FAIL'
);
