import React from 'react';
// hooks
import useSourceScrubLists from '@hooks/saved-searches/useSourceScrubLists';

interface CellLinkProps {
  id: string;
  children: React.ReactNode;
}

const CellLink = ({ id, children }: CellLinkProps) => {
  const { handleSourceScrubListsResults } = useSourceScrubLists();

  const handleClick = () => {
    if (id) {
      handleSourceScrubListsResults(id.toString());
    }
  };

  return (
    <div role="presentation" onClick={handleClick}>
      {children}
    </div>
  );
};

export default CellLink;
