import React from 'react';
import classNames from 'classnames';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { Styled } from './Trending.styled';
// utils
import { formatPercentDefault } from '../../../utils/format';
import { normalizeNegativeSignValues } from '@optx/utils/utils';
// components
import Icon from '../Icon';

type FormatValue = number | null | undefined;

type TrendingValueProps = React.HTMLAttributes<HTMLSpanElement> & {
  value: number | null;
  customFontSize?: string;
  format?: (value: FormatValue) => string | FormatValue;
};

const CLASS_NAME = 'trending-value';

const TrendingValue: React.FC<TrendingValueProps> = ({
  value,
  format,
  className,
  customFontSize,
  ...restProps
}) => {
  let icon: React.ReactNode = <></>;
  let finalClassName = classNames(CLASS_NAME, className);

  if (typeof value === 'string') {
    return null;
  }

  if (value) {
    if (value > 0) {
      icon = <Icon iconName="trending-up" />;
      finalClassName = classNames(finalClassName, `${CLASS_NAME}--up`);
    } else if (value < 0) {
      icon = <Icon iconName="trending-down" />;
      finalClassName = classNames(finalClassName, `${CLASS_NAME}--down`);
    }
  }

  const valueFormated = value === null ? DEFAULT_EMPTY_VALUE : format!(value);

  return (
    <Styled.TrendingValueSpan className={finalClassName} {...restProps}>
      {icon}
      <span style={{ fontSize: customFontSize || '' }}>
        {normalizeNegativeSignValues(valueFormated?.toString() as string)}
      </span>
    </Styled.TrendingValueSpan>
  );
};

TrendingValue.defaultProps = {
  format: formatPercentDefault,
};

export default React.memo(TrendingValue);
