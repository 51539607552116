import { Table } from 'antd';
import styled from 'styled-components';

const RecommendedNotificationsTable = styled(Table)`
  .ant-table-thead .ant-table-cell {
    color: black;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 14px;
  }
`;

export { RecommendedNotificationsTable };
