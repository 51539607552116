import { useInjectSaga } from 'redux-injectors';
import { saga } from '@redux/company/employee-growth';

const useInjectEmployeeGrowth = () => {
  useInjectSaga({
    key: 'employeeGrowth',
    saga,
  });
};

export default useInjectEmployeeGrowth;
