import React, { Dispatch, SetStateAction } from 'react';
import { Dictionary } from 'lodash';
import { useSelector } from 'react-redux';
// models
import { AppState } from '@optx/redux/interfaces';
import { CompanyWatchList } from '@optx/models/WatchList';
import { WatchListAction } from './interfaces';
// redux
import { selectors as companyListsSelectors } from '../../../redux/favorite-lists/company-lists';
// components
import AddWatchList from './AddWatchList';
import WatchListOptions from './WatchListOptions';

interface PopoverContentProps {
  companyId: number;
  action: WatchListAction;
  selectedOptions: Dictionary<boolean>;
  title: string;
  companyWatchLists: Dictionary<CompanyWatchList>;
  handleSelectChange: (
    listId?: string[],
    item?: CompanyWatchList,
    isEmpty?: boolean,
    companyActiveWatchlist?: Dictionary<boolean>
  ) => void;
  handleTitleChange: (newTitle: string) => void;
  showCreateList?: VoidFunction;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  isOpen: boolean;
  handleDropdownKeyEvent: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const PopoverContent: React.FC<PopoverContentProps> = ({
  action,
  selectedOptions,
  title,
  companyWatchLists,
  handleSelectChange,
  handleTitleChange,
  companyId,
  showCreateList,
  searchValue,
  setSearchValue,
  isOpen,
  handleDropdownKeyEvent,
}) => {
  //   const { listsIncludingCompany } = useInitWatchListsIncludingCompany(companyId);
  const listsIncludingCompany = useSelector((state: AppState) =>
    companyListsSelectors.getCompanyLists(state, companyId)
  );

  return (
    <>
      {action === 'Add to' ? (
        <WatchListOptions
          options={companyWatchLists}
          selectedOptions={selectedOptions}
          disabledOptions={listsIncludingCompany}
          onChange={handleSelectChange}
          showCreateList={showCreateList}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          isOpen={isOpen}
          handleDropdownKeyEvent={handleDropdownKeyEvent}
        />
      ) : (
        <AddWatchList
          title={title}
          onTitleChange={handleTitleChange}
          handleDropdownKeyEvent={handleDropdownKeyEvent}
        />
      )}
    </>
  );
};

export default PopoverContent;
