export const emailUser = `Greetings from OPTX,

Please follow the link below to the OPTX platform

{link}

Access pending, one login registration which will be set by PSG IT.
To access OPTX you will need to be connected to the PSG network or connected via VPN.

From the {link} page select "Connect using OneLogin"

{login_form_image}

Thank you for registering.
Sincerely,
PSG`;

export const emailAdmin = `Greetings from OPTX,

Please provision SSO for new OPTX User.

{email_list}

Thank you.
Sincerely,
PSG`;
