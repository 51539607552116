import { useCallback } from 'react';
import { Modal } from 'antd';
// models
import { PreselectedFilter } from '@optx/models/filters';
import { GridKeysMain } from '@models/grid';
// utils
import validationSchema from '@utils/filtersValidationSchema';
import { normalizeFilter } from '@optx/utils/filters/filterHelpers';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { Dictionary } from 'lodash';
import { SuccessErrorCallback } from '@optx/models/callback';
import { actions as searchActions } from '@features/grid/search';
import { selectors as companyFiltersSelectors } from '@redux/company/filters';
import { selectors as modalSelectors, actions as modalActions } from '@redux/ui/modals/gridModals';
import { selectors as advancedSearchSearchSelectors } from '@redux/company/search/search';
import { selectors as myCompaniesSortSelectors } from '@redux/my-companies/sort';
import { selectors as listsSearchSelectors } from '@redux/lists/search/search';
import { selectors as addonSelectors } from '@redux/company/addon-management';
import { selectors as outreachSearchSelectors } from '@redux/company-outreach/search';
// components
import useSaveViewMessage from '@hooks/filters/useSaveViewMessage';
import FiltersModalContent from './FiltersModalContent';

interface FilterModalProps {
  gridName: 'Companies OutReach' | 'Advanced Search';
  filter: Dictionary<PreselectedFilter>;
  keySearch: string;
  gridKey: GridKeysMain;
}

const FiltersModal = ({ gridName, keySearch, filter, gridKey }: FilterModalProps) => {
  const dispatch = useDispatch();

  const sortingSelectors = {
    advancedSearch: advancedSearchSearchSelectors,
    myCompanies: myCompaniesSortSelectors,
    lists: listsSearchSelectors,
    outreach: outreachSearchSelectors,
    addon: addonSelectors,
  };

  const sorting = useSelector(
    sortingSelectors[gridKey as keyof typeof sortingSelectors].getSorting
  );
  const onSearch = useCallback(
    (searchKey: string, filter: Dictionary<PreselectedFilter>) =>
      dispatch(
        searchActions.searchCompanies({
          gridKey,
          data: {
            searchKey,
            filter,
            sortBy: sorting,
            shouldResetPageNumber: true,
            shouldHandleTouchFilters: true,
          },
        })
      ),
    [dispatch, gridKey, sorting]
  );

  const filterSources = useSelector(companyFiltersSelectors.getCompanyFilters);
  const defaultFilters = useSelector(companyFiltersSelectors.getDefaultFilters);

  const clearSearch = useCallback(
    (shouldSort: boolean, callback: SuccessErrorCallback) => {
      dispatch(
        shouldSort || gridKey === 'outreach'
          ? searchActions.clearSearch(
              {
                gridKey,
                data: undefined,
              },
              callback
            )
          : searchActions.resetSearch({ gridKey, data: true }, callback)
      );
    },
    [dispatch, gridKey]
  );

  // modal
  const modalIsOpen = useSelector(modalSelectors.isOpen(gridKey));
  const toggleModalFilters = () => dispatch(modalActions.toggleModal({ gridKey }));
  useSaveViewMessage(modalIsOpen && !!Object.keys(defaultFilters).length, toggleModalFilters);

  const handleSearch = (key: string, filter: Dictionary<PreselectedFilter>) => {
    const normalizedFilter = normalizeFilter(filter);
    onSearch(key, normalizedFilter);
    toggleModalFilters();
  };

  return (
    <Modal
      className="ant-modal-full ant-modal--filters"
      title={null}
      visible={modalIsOpen}
      footer={null}
      onCancel={toggleModalFilters}
      closable={false}
      destroyOnClose
    >
      <FiltersModalContent
        filter={filter}
        gridName={gridName}
        filterSources={filterSources}
        keySearch={keySearch}
        onClearFilter={clearSearch}
        onSearch={handleSearch}
        validationSchema={validationSchema}
        onClose={toggleModalFilters}
        modalIsOpen={modalIsOpen}
        gridKey={gridKey}
      />
    </Modal>
  );
};

export default FiltersModal;
