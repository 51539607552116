import { utils as requestUtils } from '@optx/features/request';
import { createFeatureActionType } from '@optx/utils/feature';
import { featureName } from '../../featureName';

export const fetchFilters = requestUtils.createActionTypes(featureName, 'FETCH_FILTERS');
export const clear = createFeatureActionType(featureName, 'CLEAR_FILTERS');
export const resetToDefault = createFeatureActionType(featureName, 'RESET_TO_DEFAULT');
export const applyFilters = createFeatureActionType(featureName, 'APPLY_FILTERS');
export const updateValue = createFeatureActionType(featureName, 'UPDATE_FILTERS');
export const resetFilter = createFeatureActionType(featureName, 'RESET_FILTER');
