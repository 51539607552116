import React from 'react';
import { Table } from 'antd';
// models
import { CompanyFundingRoundsHistory } from '@optx/models/Company';
// constants
import { defaultColumns } from '@components/common/table/TableFundingRoundsInformation';
// components
import { generateUniqueKey } from '@optx/utils/uuid';

interface FundingTableProps {
  investmentHistory: Array<CompanyFundingRoundsHistory>;
}

const FundingTable: React.FC<FundingTableProps> = ({ investmentHistory }) => {
  return (
    <Table
      tableLayout="fixed"
      columns={defaultColumns}
      dataSource={investmentHistory}
      pagination={false}
      rowKey={generateUniqueKey}
      bordered
      className="legacy-table"
    />
  );
};

export default FundingTable;
