import { createReducer } from '@reduxjs/toolkit';
// redux
import { toggleReducer } from '@redux/feature/toggle/reducers';
import { toggleUserEditModal } from './actions';

const userEditModal = createReducer(false, builder =>
  builder.addCase(toggleUserEditModal, toggleReducer)
);

export default userEditModal;
