import { createAction } from '@reduxjs/toolkit';
// models
import { SalesloftEditData } from '@models/Salesloft';
import { CompanyUserContact } from '@models/api/contacts';
import * as types from './types';

export const fetchPrimaryContacts = createAction<string>(types.FETCH_PRIMARY_CONTACTS);
export const fetchPrimaryContactsSuccess = createAction<CompanyUserContact[]>(
  types.FETCH_PRIMARY_CONTACTS_SUCCESS
);
export const fetchPrimaryContactsFail = createAction<string>(types.FETCH_PRIMARY_CONTACTS_FAIL);

export const updatePrimaryContacts = createAction<SalesloftEditData>(types.UPDATE_PRIMARY_CONTACT);

// Edit profile contact
export const editPrimaryContact = createAction<Partial<CompanyUserContact>>(
  types.EDIT_PRIMARY_CONTACT
);
export const editPrimaryContactSuccess = createAction<Partial<CompanyUserContact>>(
  types.EDIT_PRIMARY_CONTACT_SUCCESS
);
export const editPrimaryContactFail = createAction<string>(types.EDIT_PRIMARY_CONTACT_FAIL);

export const editingPrimaryContact = createAction<CompanyUserContact>(
  types.EDITING_PRIMARY_CONTACT
);
export const cancelEditingPrimaryContact = createAction(types.CANCEL_EDITING_PRIMARY_CONTACT);

export const clearPrimaryContacts = createAction(types.CLEAR_PRIMARY_CONTACTS);
