import { useSelector } from 'react-redux';
// constants
import appRoutes from '@constants/routes';
// redux
import { selectors as userSelectors } from '@redux/user/information';
// storage
import { AnalystStorage } from '@optx/storage/localStorage';

/**
 * Function to check if the user is an analyst.
 * Fallback to the redux store value because
 * after login localStorage doesn't get read in time.
 * @returns
 */
const useIsAnalyst = () => {
  const isAnalystStorage = AnalystStorage.getIsAnalyst();
  const isAnalystSelector = useSelector(userSelectors.getIsAnalyst);
  const isAnalyst = isAnalystStorage || isAnalystSelector;

  return {
    isAnalyst,
    advancedSearchPathname: isAnalyst ? appRoutes.advancedSearch : appRoutes.home,
  };
};

export default useIsAnalyst;
