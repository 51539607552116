import React, { useCallback } from 'react';
import {
  ResponsiveContainer,
  ResponsiveContainerProps,
  CartesianGrid,
  XAxis,
  Legend,
  Tooltip,
  YAxis,
  BarChart,
  Bar,
  YAxisProps,
} from 'recharts';
import { useSelector } from 'react-redux';
import { merge } from 'lodash';

// components
import {
  yAxisProps,
  legendProps,
  cartesianGridProps,
} from '@optx/components/common/charts/LineChart/config';
import EmptyChart from '@optx/components/common/charts/EmptyChart';
import { PipelineInterval } from '@optx/models/feature/analystsLeaderboard/PipelineInformation';
import { MinimumSizeBar } from '@optx/components/common/charts/components';
// Local
// redux
import { selectors } from '../../../state';
import { TooltipContent } from './Tooltip';
import { Styled } from './PipelineChart.styled';
import { createLegendFormatter } from './Legend';

interface LeaderboardChartProps extends Omit<ResponsiveContainerProps, 'children'> {}

type OptionKey = keyof PipelineInterval;

const labelKey: OptionKey = 'label';
const selectedPeriodKey: OptionKey = 'selected_period';
const previousPeriodKey: OptionKey = 'previous_period';

const valueYAxisDifferentProps: YAxisProps = {
  tick: {
    fill: '#334D6E',
  },
};

const valueYAxisProps = merge({}, yAxisProps, valueYAxisDifferentProps);

const tooltipCursor: React.CSSProperties = {
  opacity: 0.2,
};

export const PipelineChart = (props: LeaderboardChartProps) => {
  const data = useSelector(selectors.pipelineInformation.selectPipelineInfo);
  const selectedPeriodDate = useSelector(selectors.pipelineInformation.selectSelectedPeriodDate);
  const previousPeriodDate = useSelector(selectors.pipelineInformation.selectPreviousPeriodDate);
  const selectedPeriodName = useSelector(selectors.filters.getSelectedPeriodLabel);
  const previousPeriodName = useSelector(selectors.pipelineInformation.selectPreviousPeriodName);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedPeriodLegendFormatter = useCallback(createLegendFormatter(selectedPeriodDate), [
    selectedPeriodDate,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const previousPeriodLegendFormatter = useCallback(createLegendFormatter(previousPeriodDate), [
    previousPeriodDate,
  ]);

  const isAllTime = selectedPeriodName.toLowerCase() === 'all time';

  if (!data || !data.length) {
    return <EmptyChart />;
  }

  return (
    <Styled.ChartsWrapper>
      <Styled.SelectedPeriodWrapper fillContent={isAllTime}>
        <ResponsiveContainer width="100%" height={330} {...props}>
          <BarChart
            data={data}
            margin={{ bottom: 20 }}
            style={{ margin: '20px 0 0' }}
            layout="vertical"
            syncId="syncId"
            barGap={0}
            barCategoryGap={5}
            barSize={18}
          >
            <CartesianGrid {...cartesianGridProps} />
            {/* YAxis are displayed in reversed order. */}
            <YAxis
              {...valueYAxisProps}
              dataKey={selectedPeriodKey}
              type="category"
              yAxisId={0}
              axisLine={false}
              tickMargin={6}
              width={50}
              tick={{ dx: -30, textAnchor: 'start' }}
            />
            <YAxis
              {...yAxisProps}
              dataKey={labelKey}
              type="category"
              yAxisId={1}
              width={140}
              tickMargin={16}
            />
            <XAxis dataKey={selectedPeriodKey} domain={['dataMin', 'dataMax']} type="number" hide />
            <Tooltip separator=": " content={TooltipContent} cursor={tooltipCursor} />
            <Legend
              {...legendProps}
              wrapperStyle={{ paddingLeft: 188 }}
              formatter={selectedPeriodLegendFormatter}
            />
            <Bar
              dataKey={selectedPeriodKey}
              fill="#096DD9"
              name={selectedPeriodName}
              shape={MinimumSizeBar}
            />
          </BarChart>
        </ResponsiveContainer>
      </Styled.SelectedPeriodWrapper>
      {!isAllTime && (
        <Styled.PreviousPeriodWrapper>
          <ResponsiveContainer width="100%" height={330} {...props}>
            <BarChart
              data={data}
              margin={{ bottom: 20 }}
              style={{ margin: '20px 0 0' }}
              layout="vertical"
              syncId="syncId"
              barGap={0}
              barCategoryGap={5}
              barSize={18}
            >
              <CartesianGrid {...cartesianGridProps} />
              {/* YAxis are displayed in reversed order. */}
              <YAxis
                {...valueYAxisProps}
                dataKey={previousPeriodKey}
                type="category"
                yAxisId={0}
                axisLine={false}
                width={50}
                tickMargin={6}
                tick={{ dx: -30, textAnchor: 'start' }}
              />
              <YAxis
                {...yAxisProps}
                dataKey={previousPeriodKey}
                type="category"
                yAxisId={1}
                tick={false}
                width={2}
              />
              <XAxis
                dataKey={previousPeriodKey}
                domain={['dataMin', 'dataMax']}
                type="number"
                hide
              />
              <Tooltip separator=": " content={TooltipContent} cursor={tooltipCursor} />
              <Legend
                {...legendProps}
                wrapperStyle={{ paddingLeft: 51 }}
                formatter={previousPeriodLegendFormatter}
              />
              <Bar
                dataKey={previousPeriodKey}
                fill="#F5222D"
                name={previousPeriodName}
                shape={MinimumSizeBar}
              />
            </BarChart>
          </ResponsiveContainer>
        </Styled.PreviousPeriodWrapper>
      )}
    </Styled.ChartsWrapper>
  );
};

export default PipelineChart;
