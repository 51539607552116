import styled from 'styled-components';
import { Form } from 'formik-antd';
import { Menu } from 'antd';

const FormWrapper = styled(Form)`
  .ant-form-item {
    width: 50%;
  }
`;

const MenuWrapper = styled(Menu)`
  button {
    display: flex;
    width: 100%;
  }
`;

const Styles = {
  FormWrapper,
  MenuWrapper,
};

export default Styles;
