import { createFeatureAction } from '@utils/feature';
import CompanyIndividualEditOptions from '@models/feature/companyIndividualEdit/CompanyIndividualEditOptions';
import featureName from '../../featureName';
import * as actionTypes from './actionTypes';

export const fetchSelectOptions = createFeatureAction<string | null>(
  featureName,
  actionTypes.FETCH_SELECT_OPTIONS
);
export const fetchSelectOptionsSuccess = createFeatureAction<CompanyIndividualEditOptions>(
  featureName,
  actionTypes.FETCH_SELECT_OPTIONS_SUCCESS
);

export const fetchSelectOptionsFail = createFeatureAction<string>(
  featureName,
  actionTypes.FETCH_SELECT_OPTIONS_FAIL
);
