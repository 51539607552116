import React from 'react';
import Styled from './LoadingBlock.styled';

interface LoadingBlockProps {
  loading: boolean;
}

const LoadingBlock = ({ loading }: LoadingBlockProps) => {
  if (loading) {
    return (
      <Styled.Wrapper>
        <Styled.LoadingIcon />
      </Styled.Wrapper>
    );
  }

  return null;
};

export default LoadingBlock;
