import { createReducer, PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { SortByRule, SortRule } from '@models/table/sorting';
import { isEqual } from 'lodash';
// models
import { SearchPayload } from '@optx/models/api/contacts';
import Company from '@optx/models/Company';
import { UserInformation } from '@optx/models/user';
import { GridPayload } from '@models/grid';
// interfaces
import { MyCompaniesSortState } from '@redux/my-companies/sort/interfaces';
// constants
import { MYCOMPANIES_DEFAULT_SORT_US } from '@constants/table/sort/defaultSort';
// utils
import { parseSorting } from '@optx/utils/filters/parseSorting';
// redux
import { actions as userInformationActions } from '@redux/user/information';
import * as fetchReducers from '../../feature/fetch/reducers';
import * as actions from './actions';
import { actions as searchActions } from '@features/grid/search';

const initialState: MyCompaniesSortState = {
  multiSort: false,
  sortBy: MYCOMPANIES_DEFAULT_SORT_US,
  loading: false,
  error: '',
};

export const changeSortReducer: CaseReducer<MyCompaniesSortState, PayloadAction<SortRule<any>>> = (
  draftState,
  action
) => {
  if (action.payload.sortBy) {
    draftState.sortBy = action.payload.sortBy;
  } else {
    draftState.sortBy = action.payload as any;
  }
};

export const createMultiSortReducer =
  (
    initState = initialState
  ): CaseReducer<
    MyCompaniesSortState,
    PayloadAction<boolean, any, Array<SortByRule<any>> | undefined>
  > =>
  (draftState, action) => {
    const multiSort = action.payload;
    draftState.multiSort = multiSort;

    if (!multiSort) {
      draftState.sortBy = initState.sortBy;
    }
  };

const multiSortReducer = createMultiSortReducer(initialState);

const changeSortActionSuccessReducer: CaseReducer<
  MyCompaniesSortState,
  PayloadAction<SearchPayload>
> = (draftState, action) => {
  if (!isEqual(draftState.sortBy, action.payload.sortBy)) {
    draftState.sortBy = action.payload.sortBy;
  }
};

const resetMultiSortActionReducer: CaseReducer<MyCompaniesSortState> = draftState => {
  draftState.multiSort = false;
};

const resetSortSuccessReducer: CaseReducer<
  MyCompaniesSortState,
  PayloadAction<Array<SortByRule<any>> | undefined>
> = (draftState, action) => {
  draftState.loading = false;
  draftState.error = '';
  draftState.sortBy = action.payload ? action.payload : MYCOMPANIES_DEFAULT_SORT_US;
};

const searchSuccessReducer: CaseReducer<
  MyCompaniesSortState,
  PayloadAction<Company[], any, GridPayload<Partial<SearchPayload>>>
> = (draftState, action) => {
  const { data, gridKey } = action.meta;

  if (gridKey === 'myCompanies') {
    if (!data.sortBy) {
      draftState.sortBy = MYCOMPANIES_DEFAULT_SORT_US;
    } else {
      draftState.sortBy = data.sortBy;
    }
  }
};

export const resetSortReducer: CaseReducer<MyCompaniesSortState> = draftState => {
  fetchReducers.fetchReducer(draftState);
  draftState.multiSort = false;
};

export const fetchUserInformationSuccessReducer: CaseReducer<
  MyCompaniesSortState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const sorting = action.payload.settings.session_settings?.my_companies_sorting;

  if (sorting) {
    const { sortBy, isMultiSort } = parseSorting(sorting);
    draftState.sortBy = sortBy;
    draftState.multiSort = isMultiSort;
  } else if (sorting === '') {
    draftState.sortBy = [];
    draftState.multiSort = false;
  }
};

const reducer = createReducer<MyCompaniesSortState>(initialState, builder =>
  builder
    .addCase(actions.multiSortAction, multiSortReducer)
    .addCase(actions.changeSortAction, changeSortReducer)
    .addCase(actions.changeSortActionSuccess, changeSortActionSuccessReducer)
    .addCase(actions.resetMultiSortAction, resetMultiSortActionReducer)
    .addCase(searchActions.searchCompaniesSuccess, searchSuccessReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
    .addCase(actions.resetSortAction, resetSortReducer)
    .addCase(actions.resetSortSuccessAction, resetSortSuccessReducer)
);

export default reducer;
