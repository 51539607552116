import styled from 'styled-components';
import { Title } from '@optx/shared/view/atoms/Typography';

const PageTitle = styled(Title)`
  h1& {
    margin: 0;
  }
`;

export default {
  PageTitle,
};
