import { combineReducers } from 'redux';

import { reducer as histogramFiltersReducer } from '@features/histograms/histograms-my-companies';
// models
import { SourcingOutReachState } from './interfaces';
// reducers
import sortReducer from './sort';
import filterReducer from './filters';
import columns from './columns';

const reducer = combineReducers<SourcingOutReachState>({
  filter: filterReducer,
  histogramFilters: histogramFiltersReducer,
  sort: sortReducer,
  columns,
});

export default reducer;
