import React from 'react';
import { Form } from 'formik-antd';
import { Row } from 'antd';
// models
import { FilterSource } from '@optx/models/filters';
// hooks
import { useTabs } from '@optx/common/hooks/tabs';
import { useIsTabletOrSmaller } from '@optx/common/hooks/responsive';
// components
import SearchFilterGroup from '@optx/components/feature/company-filters/Filter/SearchFilterGroup';
import FilterTabs from '@optx/components/feature/company-filters/FilterTabs';

const DEFAULT_TAB = '0';

interface FiltersFormProps {
  filterSources: Array<FilterSource>;
  className?: string;
}

export const FiltersForm = ({ filterSources, className }: FiltersFormProps) => {
  const [tab, toggleTab] = useTabs(DEFAULT_TAB);
  const isTabletOrSmaller = useIsTabletOrSmaller();

  return (
    <Form layout="vertical" className={className}>
      {isTabletOrSmaller ? (
        <FilterTabs sources={filterSources} activeKey={tab} onTabChange={toggleTab} />
      ) : (
        <Row gutter={20} style={{ padding: '40px 50px' }}>
          {filterSources.map((filterSource: any) => (
            <SearchFilterGroup key={filterSource.id} source={filterSource} />
          ))}
        </Row>
      )}
    </Form>
  );
};

// Export memoized version of filters form due to some issues regarding entire form rerender.
export default React.memo(FiltersForm);
