import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// constants
import { USER_DEFAULT_SORT } from '@constants/table/sort/defaultSort';
// models/interfaces
import { SortByRule } from '@models/table/sorting';
import { UserManagement, UserInfo, UserEdit } from '@models/UserManagement';
import { UserManagementState } from './interfaces';
// redux
import * as actions from './actions';
// external reducers
import { fetchReducer, fetchFailReducer, fetchSuccessReducer } from '../feature/fetch/reducers';

const initialState: UserManagementState = {
  loading: false,
  error: '',
  data: [],
  fetchedAt: '',
  sortBy: USER_DEFAULT_SORT,
  edit: {
    loading: false,
    error: '',
    data: {
      access_role_options: [],
      user_info: {
        user_id: null,
        display_name: '',
        role_id: null,
        email_list: [],
      },
    },
  },
};

const fetchUserManagementReducer: CaseReducer<
  UserManagementState,
  PayloadAction<Array<SortByRule<any>> | undefined>
> = draftState => {
  fetchReducer(draftState);
};

const fetchUserManagementReducerSuccess: CaseReducer<
  UserManagementState,
  PayloadAction<Array<UserManagement>>
> = (draftState, action) => {
  fetchSuccessReducer(draftState, action);
  draftState.fetchedAt = new Date().toISOString();
};

const resendUserInviteSuccessReducer: CaseReducer<
  UserManagementState,
  PayloadAction<Array<{ userId: number }>>
> = draftState => {
  draftState.loading = false;
};

const disableUserSuccessReducer: CaseReducer<UserManagementState, PayloadAction<number>> = (
  draftState,
  action
) => {
  const index = draftState.data.findIndex(item => item.id === action.payload);

  if (index !== -1) {
    draftState.data[index].status = 'No Access';
  }
};

const unlockUserSuccessReducer: CaseReducer<UserManagementState, PayloadAction<number>> = (
  draftState,
  action
) => {
  draftState.loading = false;
  const index = draftState.data.findIndex(item => item.id === action.payload);

  if (index !== -1) {
    draftState.data[index].locked = false;
  }
};

const submitUserInfoSuccessReducer: CaseReducer<UserManagementState, PayloadAction<UserInfo>> = (
  draftState,
  action
) => {
  const { payload } = action;
  const index = draftState.data.findIndex(user => user.id === payload.user_id);

  if (index !== -1) {
    draftState.data[index].display_name = payload.display_name;
    draftState.data[index].email = payload.email_list as string;
    draftState.data[index].role = payload.roleName as string;
  }
};

const changeSortSuccessReducer: CaseReducer<
  UserManagementState,
  PayloadAction<Array<SortByRule<any>>>
> = (draftState, action) => {
  draftState.sortBy = action.payload;
};

// editing
const fetchUserEditInfoReducer: CaseReducer<UserManagementState> = draftState => {
  fetchReducer(draftState.edit);
};

const fetchUserEditInfoSuccessReducer: CaseReducer<UserManagementState, PayloadAction<UserEdit>> = (
  draftState,
  action
) => {
  fetchSuccessReducer(draftState.edit, action);
};

const fetchUserEditInfoFailReducer: CaseReducer<UserManagementState, PayloadAction<string>> = (
  draftState,
  action
) => {
  fetchFailReducer(draftState.edit, action);
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.fetchUserManagement, fetchUserManagementReducer)
    .addCase(actions.fetchUserManagementSuccess, fetchUserManagementReducerSuccess)
    .addCase(actions.fetchUserManagementFail, fetchFailReducer)
    .addCase(actions.resendUserInvite, fetchReducer)
    .addCase(actions.resendUserInviteSuccess, resendUserInviteSuccessReducer)
    .addCase(actions.resendUserInviteFail, fetchFailReducer)
    .addCase(actions.disableUserSuccess, disableUserSuccessReducer)
    .addCase(actions.unlockUser, fetchReducer)
    .addCase(actions.unlockUserSuccess, unlockUserSuccessReducer)
    .addCase(actions.unlockUserFail, fetchFailReducer)
    .addCase(actions.submitUserInfoSuccess, submitUserInfoSuccessReducer)
    .addCase(actions.changeSortSuccess, changeSortSuccessReducer)
    // edit
    .addCase(actions.fetchUserEditInfo, fetchUserEditInfoReducer)
    .addCase(actions.fetchUserEditInfoSuccess, fetchUserEditInfoSuccessReducer)
    .addCase(actions.fetchUserEditInfoFail, fetchUserEditInfoFailReducer)
);

export default reducer;
