import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import filterSourcesReducer, { sagas as filterSourcesSaga } from '../../../redux/contacts/filters';

const useInjectContactsFilterSources = () => {
  useInjectReducer({
    key: 'contactsFilters',
    reducer: filterSourcesReducer,
  });
  useInjectSaga({
    key: 'contactsFilters',
    saga: filterSourcesSaga,
  });
};

export default useInjectContactsFilterSources;
