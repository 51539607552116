import React, { useContext } from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actions as bulkSalesloftActions } from '@features/bulk-actions/salesloft';
import { Context } from '../common/Context';

const Header = () => {
  const { cadence } = useContext(Context);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (cadence && cadence.id) {
      dispatch(bulkSalesloftActions.addPersonBulk(cadence));
      dispatch(bulkSalesloftActions.updateProgress(true));
    }
  };

  return (
    <div className="salesloft-bulk-header">
      <h4>Bulk Add to Sales Loft Cadence</h4>
      <div>
        <Button type="default" onClick={() => history.goBack()}>
          Cancel
        </Button>
        <Button type="primary" disabled={!cadence} onClick={handleSubmit}>
          Add To SL Cadence
        </Button>
      </div>
    </div>
  );
};

export default Header;
