import { CaseReducer, PayloadAction, createReducer } from '@reduxjs/toolkit';
// models
import { ChartData } from '@optx/models/charts/data';
// constants
import { INITIAL_STATE_CHART } from '@optx/constants/charts';
// redux
import { fetchFailReducer, fetchReducer } from '@redux/feature/fetch/reducers';
import { actions as companyEditFields } from '@features/company/edit-fields/state';
import * as actions from './actions';
import { ScoreGrowthState } from './interfaces';
import { EditFieldSuccess } from '@optx/features/company/edit-fields/state/interfaces';

const initialState: ScoreGrowthState = {
  data: INITIAL_STATE_CHART,
  psgDate: null,
  loading: false,
  error: '',
};

const fetchScoreGrowthSuccessReducer: CaseReducer<
  ScoreGrowthState,
  PayloadAction<{ data: ChartData; psgDate: string | null }>
> = (draftState, action) => {
  const { data, psgDate } = action.payload;

  draftState.data = data;
  draftState.psgDate = psgDate;

  draftState.loading = false;
};

const updateFieldsSuccessReducer: CaseReducer<ScoreGrowthState, PayloadAction<EditFieldSuccess>> = (
  draftState,
  action
) => {
  const { psg_date: psgDate } = action.payload;

  if (psgDate) {
    draftState.psgDate = psgDate;
  }
};

export const reducer = createReducer<ScoreGrowthState>(initialState, builder =>
  builder
    .addCase(actions.fetchScoreGrowth, fetchReducer)
    .addCase(actions.fetchScoreGrowthSuccess, fetchScoreGrowthSuccessReducer)
    .addCase(actions.fetchScoreGrowthFail, fetchFailReducer)
    .addCase(companyEditFields.updateFieldsSuccess, updateFieldsSuccessReducer)
);
