import React from 'react';
// models
import { ColumnType } from 'antd/lib/table';
import { SalesloftContact } from '@optx/models/Salesloft';
// cells
import {
  TableCellIcons,
  TableContactName,
} from '@components/common/table/TableContactsEquityTouch/cells';
import { TableCellDateInterval, TableCellCeoRating } from '@components/common/table/AntTable/cells';
import { TableCellActions } from './cells';

const columns: ColumnType<SalesloftContact>[] = [
  {
    dataIndex: 'full_name',
    title: 'Name',
    className: 'full_name',
    width: 190,
    render: TableContactName,
  },
  {
    dataIndex: 'title',
    title: 'Title',
    className: 'title',
    width: 160,
  },
  {
    dataIndex: 'ceo_approval',
    title: 'Rank',
    className: 'rank',
    width: 80,
    render: value => TableCellCeoRating(value, '-'),
  },
  {
    dataIndex: 'tenure_start_date',
    title: 'Tenure',
    className: 'tenure',
    width: 170,
    render: TableCellDateInterval,
  },
  {
    dataIndex: 'sources',
    title: 'Sources',
    className: 'sources',
    width: 180,
    render: TableCellIcons,
  },
  {
    dataIndex: 'actions',
    title: '',
    className: 'row_actions sticky sticky-right sources',
    width: 45,
    render: (value, record) => <TableCellActions value={value} record={record} />,
  },
];

export default columns;
