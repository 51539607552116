import React from 'react';
import Company from '@optx/models/Company';
import { Cell } from '../../../../../models/table/Cell';
import { formatMoney } from '../../../../../utils/format';
import { cellIsEqual } from './memoize';
import { DEFAULT_CELL_VALUE } from '../../../../../constants/table/cells';

export const TableCellMoney: React.FC<Cell<Company>> = ({ value }) => (
  <>{value === DEFAULT_CELL_VALUE ? DEFAULT_CELL_VALUE : formatMoney(value)}</>
);

export default React.memo(TableCellMoney, cellIsEqual);
