import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { GrowthOverTime } from '@optx/models/charts/data';
import { FETCH_REVENUE_GROWTH } from './actionTypes';
import { fetchCompanyRevenueSuccess, fetchCompanyRevenueFail } from './actions';
import { CompanyService } from '../../../services/api';
import NotificationService from '../../../services/NotificationService';
import { FetchCompanyRevenueGrowthAction } from './interfaces';

export function* fetchCompanyRevenueGrowthSaga(action: FetchCompanyRevenueGrowthAction) {
  const { payload: companyId, meta: callback } = action;

  try {
    const res: AxiosResponse<Array<GrowthOverTime>> = yield call(
      CompanyService.revenueGrowth,
      companyId
    );

    if (res.data) {
      yield put(fetchCompanyRevenueSuccess(res.data));
      callback && callback(res.data);
    } else {
      const errorMessage = 'Failed to fetch Revenue Growth!';
      yield put(fetchCompanyRevenueFail(errorMessage));
      callback && callback(null, errorMessage);
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = 'Failed to fetch Revenue Growth!';
    yield put(fetchCompanyRevenueFail(errorMessage));
    callback && callback(null, errorMessage);
    NotificationService.error(errorMessage);
  }
}

export default function* companyRevenueGrowthSaga() {
  yield takeLatest(FETCH_REVENUE_GROWTH, fetchCompanyRevenueGrowthSaga);
}
