import { createAction } from '@reduxjs/toolkit';
import { SalesloftCadences, SalesloftResponse } from '@models/Salesloft';
import * as types from './types';
import { PostSalesloftData } from './interfaces';

export const fetchSalesloftAuthUrl = createAction(
  types.FETCH_SALESLOFT_AUTH_URL,
  (payload: string, callback: (url: string) => void) => ({
    payload,
    meta: callback,
  })
);
export const fetchSalesloftAuthUrlSuccess = createAction<string>(
  types.FETCH_SALESLOFT_AUTH_URL_SUCCESS
);

export const fetchSalesloftCadences = createAction(
  types.FETCH_SALESLOFT_CADENCES,
  (payload: string, callback?: () => void | undefined) => ({
    payload,
    meta: callback,
  })
);
export const fetchSalesloftCadencesSuccess = createAction<Array<SalesloftCadences>>(
  types.FETCH_SALESLOFT_CADENCES_SUCCESS
);

export const postSalesloftData = createAction(
  types.POST_SALESLOFT_DATA,
  (payload: PostSalesloftData, callback: (res: SalesloftResponse) => void) => ({
    payload: { ...payload },
    meta: callback,
  })
);

export const postSalesloftDataSuccess = createAction(types.POST_SALESLOFT_DATA_SUCCESS);
export const postSalesloftDataFail = createAction(types.POST_SALESLOFT_DATA_FAIL);

export const salesloftPreloadContacts = createAction(types.SALESLOFT_PRELOAD_CONTACTS);

export const salesloftSelectContacts = createAction<number[]>(types.SALESLOFT_SELECT_CONTACT);
export const salesloftSelectCadence = createAction<SalesloftCadences | undefined>(
  types.SALESLOFT_SELECT_CADENCE
);

export const salesloftReset = createAction(types.SALESLOFT_RESET);
