import { createReducer, PayloadAction, CaseReducer } from '@reduxjs/toolkit';
// models
import { FilterSource } from '@optx/models/filters';
import { UserInformation } from '@optx/models/user';
// utils
import { FilterTag } from '@optx/models/tags';
import { injectFilter } from '@optx/utils/filters/injectFilter';
import { normalizeFiltersByColumn } from '@optx/utils/filters/normalize';
import { parseFilter } from '../../../../utils/filters/parseFilters';
import { mapPreselectedFilters } from '../../../../utils/filters/preselectedValues';
// redux
import { SearchContactsPayload, ResetSearchPayload } from '../search/interfaces';
import { SearchContactsFilterState } from './interfaces';
import { actions as searchActions } from '../search';
import { actions as contactsFiltersActions } from '../../filters';
import { actions as userInformationActions } from '../../../user/information';

const initialState: SearchContactsFilterState = {
  data: [],
  filter: {},
  preselected: {},
  normalized: {},
  clear: {},
};

// external reducers
const searchContactsReducer: CaseReducer<
  SearchContactsFilterState,
  PayloadAction<Partial<SearchContactsPayload> | undefined>
> = (draftState, action) => {
  if (action.payload && action.payload.filter) {
    draftState.filter = action.payload.filter;
  }
};

const resetReducer: CaseReducer<SearchContactsFilterState, PayloadAction<ResetSearchPayload>> = (
  draftState,
  action
) => {
  const { clearFilter, resetTo } = action.payload;

  if (clearFilter) {
    draftState.filter = draftState.clear;
  } else {
    draftState.filter = draftState.preselected;
  }

  if (resetTo) {
    const { filter } = resetTo;

    draftState.filter = filter || {};
  }
};

// external reducers
const fetchFiltersSuccessReducer: CaseReducer<
  SearchContactsFilterState,
  PayloadAction<Array<FilterSource>>
> = (draftState, action) => {
  draftState.data = action.payload;
  const preselected = mapPreselectedFilters(action.payload);
  draftState.preselected = preselected;
  draftState.clear = mapPreselectedFilters(action.payload, false);
  draftState.normalized = normalizeFiltersByColumn(action.payload);

  if (draftState.persistedFilter !== undefined) {
    const [parsedFilters] = parseFilter(draftState.persistedFilter, action.payload);
    draftState.filter = parsedFilters;
    // remove persisted filter, is not needed anymore.
    delete draftState.persistedFilter;
  } else {
    draftState.filter = preselected;
  }
};

// Update persisted filter to be used when filters are loaded.
const fetchUserInformationSuccess: CaseReducer<
  SearchContactsFilterState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const persistedFilter = action.payload.settings.session_settings
    ? action.payload.settings.session_settings.contact_filters
    : undefined;

  if (persistedFilter !== undefined) {
    draftState.persistedFilter = persistedFilter;
  }
};

const removeFilter: CaseReducer<SearchContactsFilterState, PayloadAction<FilterTag>> = (
  draftState,
  action
) => {
  const tag = action.payload;

  const { normalized, clear, filter } = draftState;

  if (tag.filter === 'keyword') {
    tag.filter = 'query';
  }

  const newFilters = injectFilter(normalized as any, filter, clear, tag.filter, tag.optionId);
  draftState.filter = newFilters;
};

const reducer = createReducer<SearchContactsFilterState>(initialState, builder =>
  builder
    .addCase(searchActions.searchContacts, searchContactsReducer)
    .addCase(searchActions.reset, resetReducer)
    .addCase(searchActions.removeFilter, removeFilter)
    // external reducers
    .addCase(contactsFiltersActions.fetchFiltersSuccess, fetchFiltersSuccessReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccess)
);

export default reducer;
