export const USER_VALUES_FIELDS = [
  'Last Raised Amount',
  'Capital Raised',
  'Last Funding Date',
  'Revenue',
  'EBITDA',
  'Last Round',
];

export const MONTH_DATE_YEAR_FORMAT_FIELDS = ['IC Status Date', 'Expected Close Date'];
