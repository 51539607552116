import { createSelector } from 'reselect';
import { AppState } from '@optx/redux/interfaces';
// models
import { ColumnKeys } from '@optx/models/table/Columns';
// utils
import { getVisibleColumns } from '@optx/utils/columns';

const listColumnsState = (state: AppState) => state.lists.search.columns;

export const getColumnOrder = createSelector(listColumnsState, state => state.columnOrder);

export const getVisibleColumnString = createSelector(listColumnsState, state =>
  getVisibleColumns({ columnDisplay: state.columnDisplay, columnOrder: state.columnOrder })
);

export const getPinnedColumnsString = createSelector(listColumnsState, state => {
  const columnDisplay = state.columnDisplay;

  return Object.keys(columnDisplay)
    .filter(columnId => columnDisplay[columnId as ColumnKeys].pinned)
    .join(',');
});
