import React, { useRef } from 'react';
import { Modal, Typography, Col } from 'antd';
import { isEmpty } from 'lodash';
// services
import { SubmitButton, Form, Input, Select } from 'formik-antd';
import { Formik, FormikProps } from 'formik';
// hooks
import { useSelector } from 'react-redux';
import {
  useUserInvitationModal,
  useSendUserInvitation,
} from '../../common/hooks/init/useUserInvitationModal';
import { validationSchema } from './validation';
import { UserInvitationModalProps } from './interfaces';
import { selectors } from '../../redux/ui/modals/user-invitation';

const userRoleList = [
  { value: 1, label: 'Administrator' },
  { value: 2, label: 'Analyst' },
  { value: 3, label: 'Partner' },
];

const defaultInitialValues: UserInvitationModalProps = {
  email: '',
  role: '',
};

const UserInvitationModal: React.FC = () => {
  // form
  const { Option } = Select;
  const { Paragraph } = Typography;
  const formRef = useRef<FormikProps<UserInvitationModalProps> | null>(null);

  const loading = useSelector(selectors.isLoading);

  // hooks
  const [open, toggleUserInvitation] = useUserInvitationModal();
  const userInvitation = useSendUserInvitation();

  const handleSubmit = (values: UserInvitationModalProps) => {
    if (!isEmpty(values)) {
      userInvitation(values);
    }
  };

  return (
    <Modal
      visible={open}
      title="Invite New User"
      footer={null}
      onCancel={() => {
        if (formRef.current) {
          formRef.current.resetForm();
        }

        toggleUserInvitation();
      }}
    >
      <Formik
        initialValues={defaultInitialValues}
        onSubmit={handleSubmit}
        innerRef={formRef}
        enableReinitialize
        validationSchema={validationSchema}
        component={({ dirty }) => (
          <Form layout="vertical" name="userInvitationForm">
            {dirty}
            <Col span={24}>
              <Paragraph>
                We&apos;ll invite your new users to the site and onboard them. During signup
                they&apos;ll enter their full name.
              </Paragraph>
              <Form.Item label="Email addresses" name="email" style={{ marginBottom: '0' }}>
                <Input name="email" />
              </Form.Item>
              <Paragraph type="secondary">
                Enter up to 10 email addresses, separated by a comma. We can&apos;t send invitations
                to distribution lists.
              </Paragraph>
            </Col>
            <Col span={24}>
              <Form.Item label="Role" name="role">
                <Select name="role" placeholder="Select a role">
                  {userRoleList.map(role => (
                    <Option key={role.label} value={role.value}>
                      {role.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} style={{ textAlign: 'right' }}>
              <SubmitButton
                type="primary"
                className="submit-user-invitation"
                disabled={!dirty}
                loading={loading}
              >
                Invite Users
              </SubmitButton>
            </Col>
          </Form>
        )}
      />
    </Modal>
  );
};

export default UserInvitationModal;
