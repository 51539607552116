import styled, { css } from 'styled-components';
import { Radio, Tag as $Tag } from 'antd';
import Icon from '@optx/components/common/Icon';
import { IconProps } from '@optx/components/common/Icon/Icon';
import { RadioButtonProps } from 'antd/lib/radio/radioButton';
import { RadioGroupProps, RadioProps } from 'antd/lib/radio';

const ContentContainer = styled.div`
  .profile-information__subtitle {
    margin: 22px 0 0 -16px;
    min-width: 390px;
    width: calc(100% + 32px);
    padding-top: 10px;
    border-top: 1px solid #d9d9d9;
    padding: 14px 54px 12px 16px;
  }

  .ant-typography {
    padding-bottom: 2px;
  }

  .ant-radio-group {
    width: 100%;

    .ant-radio-button-wrapper {
      width: 100%;
    }
  }
`;

interface RadioButtonProp extends RadioButtonProps {
  checked?: boolean;
}

const RadioButton = styled(Radio.Button)<RadioButtonProp>`
  width: 42px !important;
  height: 42px !important;
  border-radius: 100% !important;
  padding: 0 !important;
  border-width: 1px !important;

  ${({ checked }) => css`
    &:first-child {
      background: ${checked === true ? '#52C41A' : '#fff'};
    }
    &:last-child {
      background: ${checked === false ? '#F5222D' : '#fff'};
    }
  `}

  &::before {
    content: none !important;
  }

  span:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .icon {
      margin: 0 !important;
    }
  }
`;

const ThumbsIcon = styled(Icon)`
  width: 22px;
  height: 22px;
  color: #e8e8e8;
`;

interface ThumbProps extends IconProps {
  checked?: boolean;
}

const ThumbsUpIcon = styled(ThumbsIcon)<ThumbProps>`
  ${({ checked }) => css`
    color: ${checked === true ? '#fff' : '#ddd'};
  `}
`;

const ThumbsDownIcon = styled(ThumbsIcon)<ThumbProps>`
  ${({ checked }) => css`
    color: ${checked === false ? '#fff' : '#ddd'};
  `}
`;

const ScoreContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 15px auto;
  border: 1px solid #dee4ee;
  border-radius: 8px;
  padding: 10px;
  align-items: center;
`;

const CustomRadio = styled(Radio)<RadioProps>`
  .ant-radio-inner {
    border-color: #1890ff !important;
  }
`;

interface CustomRadioGroupProps extends RadioGroupProps {
  columns: number;
}

const CustomRadioGroup = styled(Radio.Group)<CustomRadioGroupProps>`
  display: grid;
  ${({ columns }) => css`
    grid-template-columns: repeat(${columns}, 1fr);
  `}

  .ant-radio-wrapper {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    margin-right: 0;
  }

  .ant-radio-wrapper::after {
    position: absolute;
    content: '';
    background: #f5f5f5;
    height: 4px;
    width: 100%;
    top: 6px;
  }

  .ant-radio {
    z-index: 1;
  }
`;

const Wrapper = styled.div`
  .edit-field--container {
    display: flex;
  }

  .edit-field--value {
    display: inline-block;
    width: 100%;

    span {
      display: flex;
      justify-content: flex-end;
      margin-right: 8px;
    }
  }

  &.psg-company {
    .edit-field--value {
      text-overflow: ellipsis;
      overflow: hidden;

      span {
        display: inline;
      }
    }
  }

  .flex-grow-1 {
    text-align: center;
    max-width: 70px;
    margin-left: auto;
  }

  .icon-pen {
    cursor: pointer;
    visibility: hidden;
  }

  &:hover {
    .icon-pen {
      visibility: visible;
    }
  }
`;

const Tag = styled($Tag)`
  margin-top: 2px;
  margin-bottom: 2px;
  width: max-content;
  white-space: inherit;
  word-break: break-word;
`;

const ContainerProfileBanner = styled.div`
  .edit-field--container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 5px;
  }

  .icon-pen {
    cursor: pointer;
    visibility: hidden;
    margin-bottom: 3px;
  }

  &:hover {
    .icon-pen {
      visibility: visible;
    }
  }
`;

export default {
  RadioButton,
  ContentContainer,
  ThumbsUpIcon,
  ThumbsDownIcon,
  ScoreContainer,
  CustomRadioGroup,
  CustomRadio,
  Wrapper,
  Tag,
  ContainerProfileBanner,
};
