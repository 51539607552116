import moment from 'moment';
// models
import { ChartDataInterval, ChartIntervalKeys } from '@optx/models/charts/data';
import { ScoreGrowthState } from './interfaces';
// constants
import { ISO_DATE_FORMAT } from '@optx/constants/format/date';

export const getData = (state: ScoreGrowthState) => {
  const psgDateMoment = moment(state.psgDate, ISO_DATE_FORMAT);
  const data = Object.keys(state.data).reduce((acc: any, item: string) => {
    const scoreData = state.data[item as ChartIntervalKeys] as ChartDataInterval;
    // eslint-disable-next-line no-param-reassign
    acc[item] = {
      ...scoreData,
      data: scoreData.data.filter((item: any) => {
        const itemDateMoment = moment(item.date, ISO_DATE_FORMAT);

        if (psgDateMoment.isSame(itemDateMoment)) {
          if (item.is_psg) {
            return item;
          }

          return false;
        } else if (item.is_psg) {
          return false;
        } else {
          return item;
        }
      }),
    };

    return acc;
  }, {});

  return data;
};

export const getPSGDate = (state: ScoreGrowthState) => state.psgDate;
