import { Dictionary, each } from 'lodash';
import * as yup from 'yup';
import queryString from 'query-string';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
// utils
import { numberToMillions } from '@utils/number';
// models
import {
  EquityTouchDatabaseMatch,
  EquityTouchFetchedField,
  EquityTouchSubmitField,
  GroupsSubmitField,
  EquityTouchContact,
  WatchlistsSubmitField,
} from '@models/equityTouch';
import { ProductCategory } from '@optx/models/Company';
import { SelectOption } from '@models/Option';
import { SelectedCompanies, SelectedCompaniesGrids } from '@models/bulkActions';
import { CompanyUserContact } from '@models/api/contacts';
import { CompanyWatchList } from '@models/WatchList';
import { SourceScrubList } from '@models/SourceScrubList';
// utils
import { stripUrlSlashes } from './url';

/**
 * function to parse form related data coming from api
 * @param {Dictionary<EquityTouchFetchedField>} initialFormData data coming from api
 */
export const parseInitialFormData = (initialFormData: Dictionary<EquityTouchFetchedField>) => {
  const formInitialValues: Dictionary<
    string | number | boolean | number[] | string[] | null | SelectOption | ProductCategory[]
  > = {};

  Object.keys(initialFormData).forEach((field: string) => {
    if (initialFormData[field].type === 'select') {
      if (field === 'Country' || field === 'State') {
        if (initialFormData[field].database_matched) {
          formInitialValues[field] = initialFormData[field].database_matched?.text as string;
        } else {
          formInitialValues[field] = initialFormData[field].from_database;
        }
      } else {
        formInitialValues[field] = initialFormData[field].database_matched?.itemId as string;
      }
    } else if (field === 'Investors') {
      if (initialFormData[field].from_database) {
        formInitialValues[field] = (initialFormData[field].from_database as string[]).join('; ');
      }
    } else if (field === 'Add-On') {
      formInitialValues[field] = initialFormData[field].from_database;
    } else if (field === 'Add-On For') {
      formInitialValues[field] = initialFormData[field].database_matched?.text as string;
    } else if (field === 'Equity Check') {
      if (typeof initialFormData[field].from_database === 'number') {
        formInitialValues[field] = (initialFormData[field].from_database as number) / 1000000;
      } else {
        formInitialValues[field] = initialFormData[field].from_database;
      }
    } else if (initialFormData[field].from_database) {
      formInitialValues[field] = initialFormData[field].from_database;
    }
  });

  return formInitialValues;
};

/**
 * get a string of emails and parse them into an array of objects
 * @param value string of emails
 */
export const getEmailsFromString = (value: string | null | undefined) => {
  return value ? value.split(',').map(item => ({ email: item.trim() })) : [{ email: '' }];
};

/**
 * function to get value for special fields, like Company Type
 * @param {string} fieldName input field name
 * @param {Dictionary<string | SelectOption<String> | string[]>} formData form values
 * @param {Dictionary<EquityTouchFetchedField>} initialFormData initial form values
 */
export const getFieldValue = (
  fieldName: string,
  formData: Dictionary<
    string | number | boolean | number[] | string[] | null | SelectOption | ProductCategory[]
  >,
  initialFormData?: Dictionary<EquityTouchFetchedField>
) => {
  let value: string | number = '';

  each(initialFormData![fieldName].received_values, item => {
    if (
      (item as EquityTouchDatabaseMatch).itemId === formData[fieldName] ||
      (item as EquityTouchDatabaseMatch).text === formData[fieldName]
    ) {
      value = (item as EquityTouchDatabaseMatch).itemId;

      return false;
    }

    return null;
  });

  return value;
};

const getFTEsValues = (value: number) => {
  return !value || isNaN(value) ? '' : value;
};

/**
 * get the form values and map each field with the required data
 * @param {Dictionary<string | SelectOption<String> | string[]>} formData form values
 * @param {Dictionary<EquityTouchFetchedField>} initialFormData initial form values
 */
export const mapEquityTouchForm = (
  formData: Dictionary<
    string | number | boolean | number[] | string[] | null | SelectOption | ProductCategory[]
  >,
  initialFormData?: Dictionary<EquityTouchFetchedField>
) => {
  const jsonForm = {} as Dictionary<
    string | EquityTouchSubmitField | GroupsSubmitField | WatchlistsSubmitField[]
  >;

  // for the post json some fields can only be sent with the value,
  // some need to have other properties attached
  if (initialFormData) {
    Object.keys(initialFormData).forEach(formKey => {
      const field = initialFormData![formKey];

      if (formKey === 'Sub-Sector') {
        let value;

        if (typeof formData[formKey] === 'number') {
          value = formData[formKey];
        } else {
          const findSubSectorId = initialFormData!['Sub-Sector'].received_values.find(
            (item: EquityTouchDatabaseMatch | SelectOption) =>
              (item as EquityTouchDatabaseMatch).text === formData['Sub-Sector']
          );

          if (findSubSectorId) {
            value = (findSubSectorId as EquityTouchDatabaseMatch).itemId;
          } else {
            value = '';
          }
        }

        jsonForm[formKey] = {
          fieldId: initialFormData!['Sub-Sector'].fieldId,
          fieldTypeId: initialFormData!['Sub-Sector'].fieldTypeId,
          value: Number(value) || '',
        };
      } else if (formKey === 'Product Category') {
        let value;

        if (typeof formData[formKey] === 'string') {
          value = (formData[formKey] as string).replaceAll(', ', ',').split(',') as string[];
        } else {
          value = (formData[formKey] as ProductCategory[]).map(item => item.category);
        }

        jsonForm[formKey] = {
          fieldId: initialFormData!['Product Category'].fieldId,
          fieldTypeId: initialFormData!['Product Category'].fieldTypeId,
          value: value || [],
        };
      } else if (formKey === 'addressLine1') {
        jsonForm[formKey] = {
          locTypeId: field.locTypeId,
          locId: field.locId,
          value: (formData[formKey] as string) || '',
        };
      } else if (formKey === 'Company Type') {
        jsonForm[formKey] = {
          value: getFieldValue(formKey, formData, initialFormData),
        };
      } else if (formKey === 'Investors') {
        jsonForm[formKey] = {
          fieldId: field.fieldId,
          fieldTypeId: field.fieldTypeId,
          value: (formData[formKey] && (formData[formKey] as string).toString()) || '',
        };
      } else if (formKey === 'FTEs') {
        jsonForm[formKey] = {
          fieldId: field.fieldId,
          fieldTypeId: field.fieldTypeId,
          value: getFTEsValues(Number(formData[formKey])),
        };
      } else if (formKey === 'Groups') {
        if (formData[formKey] && (formData[formKey] as string[]).length > 0) {
          const value = formData[formKey] as string[];

          const selectedDealTeamLead = initialFormData['Deal Team Lead'].received_values.find(
            val => (val as EquityTouchDatabaseMatch).itemId === formData['Deal Team Lead']
          );
          const groups = (selectedDealTeamLead as EquityTouchDatabaseMatch).groups;
          const personid = groups && (groups[0] as EquityTouchDatabaseMatch).personid;
          jsonForm[formKey] = {
            personid,
            groupids:
              (groups as EquityTouchDatabaseMatch[])
                ?.filter(group => !group?.type && value.includes(group.itemId))
                ?.map(value => value.itemId) || [],
          };

          const watchlists = (groups as EquityTouchDatabaseMatch[])
            ?.filter(group => !!group?.type && value.includes(group.itemId))
            ?.map(watchlist => ({ id: watchlist.itemId, title: watchlist.text }));
          jsonForm.Watchlists = watchlists || [];
        } else {
          jsonForm[formKey] = '';
        }
      } else if (formKey === 'Equity Check') {
        const value = formData[formKey] as number | null;

        jsonForm[formKey] = {
          fieldId: field.fieldId,
          fieldTypeId: field.fieldTypeId,
          value: value ? numberToMillions(value) : '',
        };
      } else if (field.fieldId) {
        jsonForm[formKey] = {
          fieldId: field.fieldId,
          fieldTypeId: field.fieldTypeId,
          value: (formData[formKey] as string) || '',
        };
      } else {
        jsonForm[formKey] = (formData[formKey] as string) || '';
      }
    });

    jsonForm['Add-On For'] = {
      fieldId: initialFormData!['Add-On For'].fieldId,
      fieldTypeId: initialFormData!['Add-On For'].fieldTypeId,
      value: formData['Add-On For'] ? (formData['Add-On For'] as SelectOption).value : '',
    };
  }

  return jsonForm;
};

/**
 * map company properties to equity touch props
 * @param {SelectedCompanies} company company properties
 */
export const mapEquityTouchProps = (company: SelectedCompanies) => {
  const {
    city,
    company_name: name,
    country,
    company_description: description,
    sw_website_rank: WWWebsiteRank,
    sw_website_rank_per_country: USWebsiteRank,
    num_employees: employeeCount,
    product_category: category,
    state,
    sub_sector: subSector,
    company_url: url,
    street,
  } = company;

  const companyData: Dictionary<
    | string
    | number
    | boolean
    | number[]
    | null
    | string[]
    | SelectOption
    | CompanyUserContact[]
    | ProductCategory[]
  > = {
    City: city === DEFAULT_CELL_VALUE ? '' : city,
    'Company Name': name,
    Country: country === DEFAULT_CELL_VALUE ? '' : country,
    Description: description,
    'Website Global Rank': WWWebsiteRank === DEFAULT_CELL_VALUE ? '' : WWWebsiteRank,
    'Website US Rank': USWebsiteRank === DEFAULT_CELL_VALUE ? '' : USWebsiteRank,
    FTEs: employeeCount?.toString() === DEFAULT_CELL_VALUE ? '' : employeeCount,
    'Product Category': category,
    State: state === DEFAULT_CELL_VALUE ? '' : state,
    'Sub-Sector': subSector === DEFAULT_CELL_VALUE ? '' : subSector,
    Website: url ? stripUrlSlashes(url) : '',
    addressLine1: street === DEFAULT_CELL_VALUE ? '' : street,
  };

  return companyData;
};

/**
 * validation for equity touch form
 */
const message = 'Field is required!';

export const equityTouchValidationSchema = yup
  .object()
  .shape<Dictionary<string | number | boolean | number[] | string[] | null | SelectOption>>(
    {
      'Company Name': yup.string().required(message),
      'Company Type': yup.string().required(message),
      Rank: yup.string().required(message),
      Fund: yup.string().required(message),
      Sector: yup.string().required(message),
      Rationale: yup.string().max(500, 'Maximum  500 characters'),
      'Add-On For': yup.string().when('Add-On', {
        is: addon => addon,
        then: yup.string().required(message),
        otherwise: yup.string(),
      }),
      'Add-On': yup.bool().when('Add-On For', {
        is: addonFor => {
          if (addonFor !== undefined) return false;

          return true;
        },
        then: yup.bool(),
        otherwise: yup.bool().oneOf([true], message).required(message),
      }),
    },
    [['Add-On For', 'Add-On']]
  );

/** H
 * function to return primary contact in a list of contacts
 * @param {CompanyUserContact[]} contacts list of contacts
 */
export const getPrimaryContact = (contacts: EquityTouchContact[]) => {
  let primaryContact: EquityTouchContact | undefined;
  each(contacts, (contact, index) => {
    if (contact.primary_contact) {
      primaryContact = contact;

      return false;
    }

    return true;
  });

  return primaryContact;
};

/**
 * get the text for the back to grid button for equity touch bulk actions
 * if adding from watchlist or source scrub lists get the name of the list and
 * display it inside the button
 * @param {Dictionary<CompanyWatchList>} userLists watchlists
 * @param {SourceScrubList[]} sourceScrubLists source scrub lists
 */
export const getBackButtonText = (
  userLists: Dictionary<CompanyWatchList>,
  sourceScrubLists: SourceScrubList[],
  advancedSearchPathname: string
) => {
  const parsedUrl = queryString.parseUrl(window.location.href);
  const { query } = parsedUrl;
  const referrer = query.referrer as string;

  const btnText = 'Back to ';

  if (referrer === advancedSearchPathname) {
    return `${btnText}Advanced Grid`;
  }

  if (referrer) {
    const listId = referrer.replace(/^\D+/g, '');

    if (referrer.includes('user-lists')) {
      const list = userLists[listId];

      if (list) {
        return btnText + list.title;
      }
    } else if (referrer.includes('ss-lists')) {
      const list = sourceScrubLists.find(list => list.id.toString() === listId);

      if (list) {
        return btnText + list.title;
      }
    } else if (referrer.includes('my-companies')) {
      return `${btnText}My Companies`;
    }
  }

  return 'Go Back';
};

/**
 * get grid name
 */
export const getGridName: () => SelectedCompaniesGrids | null = () => {
  const { pathname } = window.location;

  if (pathname.includes('advancedSearch')) {
    return 'advancedSearch';
  }

  if (pathname.includes('companiesOutReach')) {
    return 'companiesOutReach';
  }

  if (pathname.includes('watchlists')) {
    return 'watchlists';
  }

  if (pathname.includes('myCompanies')) {
    return 'myCompanies';
  }

  return null;
};
