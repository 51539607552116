import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { featureName } from './featureName';
import reducer, { saga } from './state';

const useInject = () => {
  useInjectSaga({ key: featureName, saga });
  useInjectReducer({ key: featureName, reducer });
};

export default useInject;
