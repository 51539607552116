import React, { useCallback } from 'react';
import ReactSelect from 'react-select';
// interface
import { SelectOption } from '@optx/models/Option';
// components
import { AllMultiValueContainer, SelectAllMenuList, SelectableGroupHeading } from './components';
import {
  Option,
  MultiSelectTags,
  PreviousMultiSelectedTags,
  MultiValueRemove,
  MultiValueLabel,
  ValueContainer,
  DropdownIndicator,
} from '../components';
// Local
import { MultiSelectProps } from '../interfaces';
import { Styled } from './MultiSelect.styled';
import { isOptionDisabled } from '../utils';

const Select: React.FC<MultiSelectProps> = ({
  hasCustomNoTouchesOption,
  options,
  value,
  onChange,
  allowSelectAll,
  className,
  previousSelectedOptions,
  previousSelectedOptionsListTitle,
  selectedOptionsListTitle,
  hasButtons = false,
  ...restProps
}) => {
  // eslint-disable-next-line
  const MenuListMemoized = useCallback(SelectAllMenuList(hasCustomNoTouchesOption), []);

  return (
    <Styled.MultiSelectWrapper
      className={className}
      value={value as SelectOption<string>}
      hasButtons={hasButtons}
    >
      <ReactSelect
        options={options}
        value={value}
        onChange={onChange}
        hideSelectedOptions={false}
        components={{
          MultiValueContainer: AllMultiValueContainer,
          MultiValueLabel,
          MultiValueRemove,
          ValueContainer,
          DropdownIndicator,
          Option,
          GroupHeading: SelectableGroupHeading,
          MenuList: MenuListMemoized,
        }}
        {...restProps}
        // Leave isMulti at the end to not be overriden.
        backspaceRemovesValue={false}
        isMulti
      />
      {previousSelectedOptions && (
        <PreviousMultiSelectedTags
          options={options as Array<SelectOption>}
          previousSelectedOptions={previousSelectedOptions}
          previousSelectedOptionsListTitle={previousSelectedOptionsListTitle}
        />
      )}
      <MultiSelectTags
        value={value as Array<SelectOption>}
        options={options as Array<SelectOption>}
        allowSelectAll={allowSelectAll}
        onChange={onChange}
        selectedOptionsListTitle={selectedOptionsListTitle}
      />
    </Styled.MultiSelectWrapper>
  );
};

Select.defaultProps = {
  placeholder: 'search',
  classNamePrefix: 'multiselect',
  isClearable: false,
  hideSelectedOptions: false,
  closeMenuOnSelect: false,
  // custom
  allowSelectAll: true,
  isOptionDisabled,
  // doesn't work with undefined value - needs explicit empty array
  value: [],
};

export default Select;
