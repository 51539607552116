import { createFeatureActionType } from '@optx/utils/feature';
import featureName from '../featureName';

export const fetchCompaniesContacts = createFeatureActionType(
  featureName,
  'FETCH_COMPANIES_CONTACTS'
);
export const fetchCompaniesContactsSuccess = createFeatureActionType(
  featureName,
  'FETCH_COMPANIES_CONTACTS_SUCCESS'
);

export const setModalVisible = createFeatureActionType(featureName, 'SET_MODAL_VISIBLE');
export const addContact = createFeatureActionType(featureName, 'BULK_SALESLOFT_ADD_CONTACT');
export const addContactSuccess = createFeatureActionType(
  featureName,
  'BULK_SALESLOFT_ADD_CONTACT_SUCCESS'
);
export const resolveSalesloft = createFeatureActionType(featureName, 'RESOLVE_SALESLOFT');
export const resolveSalesloftSuccess = createFeatureActionType(
  featureName,
  'RESOLVE_SALESLOFT_SUCCESS'
);

export const addPersonBulk = createFeatureActionType(featureName, 'ADD_PERSON_BULK');
export const addPersonBulkFail = createFeatureActionType(featureName, 'ADD_PERSON_BULK_FAIL');

export const salesloftSelectContactsBulk = createFeatureActionType(
  featureName,
  'SALESLOFT_SELECT_CONTACTS_BULK'
);

export const updateProgress = createFeatureActionType(featureName, 'UPDATE_PROGRESS');

export const updateCompleted = createFeatureActionType(featureName, 'UPDATE_COMPLETED');

export const updatePercentage = createFeatureActionType(featureName, 'UPDATE_PERCENTAGE');

export const saveResults = createFeatureActionType(featureName, 'SAVE_RESULTS');

export const resetProgress = createFeatureActionType(featureName, 'RESET_PROGRESS');

export const cancel = createFeatureActionType(featureName, 'CANCEL');
