import styled from 'styled-components';
import { Dropdown, Typography, Menu, Row, Col } from 'antd';
import { styledColors } from '@optx/utils/theme/colors';
import { Form } from 'formik-antd';

const SecondaryQuickFiltersPanel = styled(Row)`
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding: 20px 40px;
  flex-flow: row nowrap;
`;

const FiltersWrapper = styled(Form)`
  position: relative;
  display: flex;
  flex-flow: row wrap;

  &.addon-management-filters {
    display: inline-flex;
  }
`;

const ButtonsWrapper = styled(Col)`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: center;
`;

const DropdownWrapper = styled(Dropdown)`
  text-align: left;
  display: inline-block;
  background-color: rgba(194, 207, 224, 0.25);
  border: 1px solid rgba(194, 207, 224, 0.25);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.0015);
  border-radius: 2px;
  margin: 0px 13px 5px 0px;
`;

const TriggerWrapper = styled.div`
  color: ${styledColors.gray8};
`;

const SelectedLabel = styled(Typography.Text)`
  color: ${styledColors.blue6};
  font-weight: 500;
  margin-left: 5px;
  margin-right: 5px;
  .label-popover {
    color: ${styledColors.blue6};
  }
`;

const MenuWrapper = styled(Menu)`
  min-width: 350px;
  .ant-card-meta-detail {
    overflow: visible;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .company_owner_id--multiselect,
  .stage--multiselect:not(.is-addon) {
    width: 260px;
    margin-right: 135px;
  }

  .ant-card-bordered {
    border: 0;
  }
  .menu-buttons-wrapper {
    padding: 10px 0;
    button {
      margin-right: 8px;
    }
  }
`;

const LocationMenuWrapper = styled(Menu)`
  position: relative;
  width: 700px;
  height: 700px;

  .menu-wrapper {
    height: 650px;
    overflow-y: auto;
  }

  .multiselect-tags-wrapper {
    width: 650px;
  }

  .ant-card {
    margin: 0;
    width: 350px;
    vertical-align: top;

    .ant-card-body {
      padding-bottom: 10px;
    }
  }

  .ant-card-meta-detail {
    overflow: visible;
  }

  .ant-card:nth-child(3) {
    .multiselect-tags-wrapper {
      width: 100%;
    }
  }

  .ant-card:nth-child(4) {
    display: inline-block;
    width: 302px;

    .ant-card-body {
      padding: 24px 0 10px 0;

      .multiselect-tags-wrapper {
        width: 100%;
      }
    }
  }

  .ant-card:nth-child(3) {
    display: inline-block;
  }

  .ant-card:nth-child(6) {
    display: inline-block;
  }

  .ant-card-meta-description {
    position: relative;

    .start_typing {
      top: -27px;
    }
  }

  .filter-form__card-group .ant-card-meta-description > .ant-card:last-child {
    margin-bottom: 0px !important;
  }

  .filter-form__card-group {
    .ant-row {
      flex-flow: nowrap;
    }

    .ant-form-item {
      display: block;
      flex-flow: nowrap;
    }

    .ant-col-24:first-of-type {
      margin-right: 24px;
    }

    .ant-col-24: last-child {
      display: inline-block;
    }
  }

  .ant-card-bordered {
    border: 0;
  }

  .ant-card-meta-description {
    position: relative;

    .ant-card {
      width: 100%;
    }
  }

  .menu-buttons-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 10px 0;
    button {
      margin-right: 8px;
    }
  }
`;

const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span.ant-typography-ellipsis-single-line {
    vertical-align: middle;
  }
`;

export default {
  SecondaryQuickFiltersPanel,
  FiltersWrapper,
  ButtonsWrapper,
  DropdownWrapper,
  TriggerWrapper,
  MenuWrapper,
  LocationMenuWrapper,
  MenuItem,

  SelectedLabel,
};
