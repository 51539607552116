import React from 'react';
import { Button, Space } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import styled from 'styled-components';
// models
import { SelectableOption } from '@optx/models/Option';
// hooks

const StyledButton = styled(Button)<ButtonProps>`
  font-weight: 600;

  color: ${props => (props.type === 'default' ? '#1890ff' : '#fff')};
`;

interface SingleSelectButtons extends Omit<ButtonProps, 'onClick'> {
  options: Array<SelectableOption>;
  onClick: (nextValue: string) => void;
}

const SingleSelectButtons = ({ options, onClick, ...props }: SingleSelectButtons) => {
  return (
    <Space>
      {options.map(option => {
        return (
          <StyledButton
            key={option.value}
            type={option.selected ? 'primary' : 'default'}
            shape="round"
            onClick={() => onClick(option.value)}
          >
            {option.label}
          </StyledButton>
        );
      })}
    </Space>
  );
};

export default SingleSelectButtons;
