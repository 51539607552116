import { PageInfo } from '@optx/models/table/Pagination';
import { URLStatus, DatePresented, FinancialFieldRangeValue } from '@optx/models/Company';
import { APIBoolean } from '@optx/models/api/generic';

export enum FieldChangedValue {
  revenue = 'Revenue',
  lastRaisedAmount = 'Last Raised Amount',
  lastRevUpdateAmount = 'Last Rev Update Amount',
  lastRound = 'Last Round',
  lastInvestors = 'Last Investors',
  parentCompany = 'Parent Company',
  sector = 'Sector',
  subSector = 'Sub-Sector',
  subSectorAPI = 'Sub Sector',
  addonBool = 'Add-on',
  crunchbaseRank = 'Crunchbase Rank',
  optxScore = 'Optx Score',
  numberOfEmployees = 'Number of Employees',
  capitalRaised = 'Capital Raised',
  lastFundingDate = 'Last Funding Date',
  pipelineRank = 'Pipeline Rank',
  stage = 'Stage',
  companyOwner = 'Company Owner',
  addon = 'Add-on For',
  addonCompany = 'Add-on Company',
  datePresented = 'Date Presented',
  softwareCompany = 'Software Company',
  aiMl = 'AI / ML',
  psgFit = 'PSG Fit',
  psgPlatformCompany = 'PSG Platform Company',
  lastRevenue = 'Last Revenue',
  leadSource = 'Lead Source',
  seniorAdvisor = 'Senior Advisor Thesis',
  fund = 'Fund',
  isInBusiness = 'Business Evaluation',
  icStatusDate = 'Ic Status Date',
  expectedCloseDate = 'Expected Close Date',
  productCategory = 'Product Category',
  subVertical = 'Sub Vertical',
  activeOnPipeline = 'Active on Pipeline',
  ebitda = 'EBITDA',
  dealTeam = 'Deal Team',
  opsTeam = 'Ops Team',
  seniorDealTeamLead = 'Senior Deal Team Lead',
  opportunityType = 'Opportunity Type',
  linkedinUrl = 'Linkedin URL',
  useParentCompany = 'Use Parent Company',
  nextSteps = 'Next Steps',
  websiteActive = 'Website Checked As',
  companyType = 'Company Type',
  askingAmount = 'Asking Amount',
  banker = 'Banker',
  bankerName = 'Banker Name',
  dealType = 'Deal Type',
  arr = 'ARR',
  yearFounded = 'Year Founded',
  generatedDescription = 'Generated Description',
  gm = 'GM %',
}

export type FinancialField = {
  value: string | number | FinancialFieldRangeValue | null;
  year: number | null;
};

export type FieldValue =
  | string
  | string[]
  | number
  | FinancialField
  | boolean
  | null
  | URLStatus
  | DatePresented[];

export interface ChangedCompanyField {
  modified_at: string;
  user?: string | null;
  field_changed: string;
  before_value: FieldValue | null;
  before_value_rationale?: string | null;
  before_value_opinion?: string | null;
  internal: boolean;
  after_value: FieldValue | null;
  after_value_rationale?: string | null;
  after_value_opinion?: string | null;
  optx?: boolean;
  after_optx?: number | null;
  before_optx?: number | null;
  opinion?: string | null;
  regeneration_type?: string | null;
  score_type?: string;
}
export interface ChangedCompanyFieldsResponse {
  results: ChangedCompanyField[];
  count: number;
}

export interface ChangedCompayFieldsState {
  data: ChangedCompanyField[];
  count: number;
  error: string;
  loading: false;
  pagination: PageInfo;
  searchQuery?: string;
  hasReachedResultsEnd: boolean;
  startDate?: string;
  endDate?: string;
  fieldsToFilter: number;
  internal: APIBoolean;
  optx: APIBoolean;
}

export interface ChangedCompanyFieldsPayload {
  companyId: number;
  pagination: PageInfo;
  startDate?: string;
  endDate?: string;
  field?: string;
  internal?: APIBoolean;
  optx?: APIBoolean;
}
