import React from 'react';
import { Row, Col, Typography } from 'antd';
import { useSelector } from 'react-redux';
// redux
import { selectors } from '@redux/user/information';
// components
import { EditFieldPassword, EditFieldText, EditProfilePicture } from './Fields';
// style
import { Styled } from '../UserProfile.styled';

const Information = () => {
  const { name, email, role, title } = useSelector(selectors.getProfileInformation);

  return (
    <>
      <EditProfilePicture />
      <Styled.InformationWrapper className="user-profile-information">
        <Typography.Title level={5} className="user-profile-information__section-title">
          My Information
        </Typography.Title>
        <Row>
          <Col className="user-profile-information__label">Name</Col>
          <EditFieldText initialValue={name as string} fieldKey="name" />
        </Row>
        <Row>
          <Col className="user-profile-information__label">Title</Col>
          <EditFieldText initialValue={title as string} fieldKey="title" />
        </Row>
        <Row>
          <Col className="user-profile-information__label">User Role</Col>
          <EditFieldText initialValue={role as string} fieldKey="user-role" />
        </Row>
        <Row>
          <Col className="user-profile-information__label">Password</Col>
          <EditFieldPassword />
        </Row>
        <Row>
          <Col className="user-profile-information__label">Email</Col>
          <EditFieldText initialValue={email as string} fieldKey="email" />
        </Row>
      </Styled.InformationWrapper>
    </>
  );
};

export default Information;
