import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// models
import { UserInformation } from '@optx/models/user';
import { UpdateUserSettingsPayload } from '@optx/models/api/user';
// redux
import { actions as userInformationActions } from '@redux/user/information';

const initialState: number = 50;

// external actions reducers
const fetchUserInformationSuccessReducer: CaseReducer<number, PayloadAction<UserInformation>> = (
  draftState,
  action
) => action.payload.settings.lists_and_searches_results_per_page || 50;

const updatePageSizeReducer: CaseReducer<
  number,
  PayloadAction<Partial<UpdateUserSettingsPayload>>
> = (draftState, action) => {
  const pageSize = action.payload.lists_and_searches_results_per_page;

  if (pageSize) {
    return pageSize;
  }

  return draftState;
};

const reducer = createReducer<number>(initialState, builder =>
  builder
    // external reducers
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
    .addCase(userInformationActions.updateUserSettingsSuccess, updatePageSizeReducer)
);

export default reducer;
