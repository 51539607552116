import styled from 'styled-components';

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  > * {
    white-space: nowrap;
    margin-left: auto;
  }
`;
