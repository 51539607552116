import { AppState } from '@optx/redux/interfaces';
import { createSelector } from 'reselect';

const selectCognitoLogin = (state: AppState) => state.auth.cognitoLogin;

export const cognitoButtons = createSelector(selectCognitoLogin, state => state.cognitoButtons);

export const isLoading = createSelector(selectCognitoLogin, state => state.loading);

export const shouldFetchCognitoURL = createSelector(
  selectCognitoLogin,
  state => !state.loading && !state.cognitoButtons.length
);
