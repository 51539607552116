import React from 'react';
import { useSelector } from 'react-redux';
// redux
import { selectors as importSelectors } from '@optx/features/list-import/state';
// components
import InProgress from './InProgress';
import Completed from './Completed';
import BulkEditingResults from './BulkEditingResults';

interface BulkEditCompletionProps {
  visibilityChange: (visibility: boolean) => void;
}

const BulkEditCompletion: React.FC<BulkEditCompletionProps> = ({ visibilityChange }) => {
  const completed = useSelector(importSelectors.isCompleted);

  return (
    <>
      {completed ? <Completed /> : <InProgress visibilityChange={visibilityChange} />}
      <BulkEditingResults />
    </>
  );
};

export default BulkEditCompletion;
