import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// constants
import { HTML } from '@constants/fullScreen';
// redux
import { pageLoading } from '@optx/features/checklist-research-insights/state';
import { actions } from '@optx/features/checklist-research-insights/';
import {
  selectors as fullscreenSelectors,
  actions as fullScreenActions,
} from '@redux/ui/settings/fullscreen';
import { selectors as userSelectors } from '@redux/user/information';
// hooks
import { useInject } from '@optx/features/checklist-research-insights/';
import { useInjectCompanyReview } from '@optx/features/company-review';
import { useReduxFullScreen } from '@optx/common/hooks/fullscreen';
import {
  useInitialFetchEditOptions,
  useInject as useInjectCompanyIndividualEdit,
} from '@components/feature/company-individual-edit';
import { useInjectUI } from '@optx/common/hooks/inject';
// components
import PageLoader from './PageLoader';
import GridFullScreen from '@optx/shared/view/molecules/FullScreen/GridFullScreen';
import ReportBreadcrumb from '../AllReports/components/ReportBreadcrumb';
import {
  GridDropdown,
  DisplayColumnModal,
  ViewBy,
  SearchGrid,
  StatsCard,
  Header,
  FilterSingleSelectDateFilter,
  FiltersModal,
} from '@optx/features/checklist-research-insights/components';
import CompanyTouches from '@optx/features/add-touch/components/CompanyTouches';
import Styled from './ChecklistResearchInsights.styled';
import EditAllDialog from '@optx/features/edit-all-info/components/EditAllDialog';
import DocumentUploadModal from '@optx/features/add-touch/components/DocumentUploadModal';

const ChecklistResearchInsights = () => {
  useInjectCompanyIndividualEdit();
  useInitialFetchEditOptions();
  useInjectUI();
  useInject();
  useInjectCompanyReview();

  const dispatch = useDispatch();

  const defaultOptxScore = useSelector(userSelectors.getDefaultScore);
  const loading = useSelector(pageLoading);
  const fullscreen = useSelector(fullscreenSelectors.isFullscreen);
  const toggle = useCallback(() => dispatch(fullScreenActions.toggleFullScreen()), [dispatch]);

  useReduxFullScreen({ dom: HTML, fullscreen, toggle });

  useEffect(() => {
    dispatch(actions.init());
  }, [dispatch]);

  return (
    <>
      <PageLoader loading={loading} />
      <Header />
      <Styled.Wrapper isFullScreen={fullscreen}>
        <Styled.MainContent>
          <Styled.PageFiltersWrapper>
            <ReportBreadcrumb
              onClick={() => fullscreen && dispatch(fullScreenActions.toggleFullScreen())}
              reportPage="Checklist Research Insights"
            />
            <Styled.PageTitleContent>
              <Styled.PageTitle>Checklist Research Insights</Styled.PageTitle>
              <FilterSingleSelectDateFilter />
            </Styled.PageTitleContent>
            <Styled.QuickFiltersContent>
              <Styled.QuickFilterWrapper>
                <Styled.MultiSelectFilter filterKey="user_id" allowSelectAll />
              </Styled.QuickFilterWrapper>
              <Styled.QuickFilterWrapper>
                <Styled.SingleSelectFilter
                  filterKey={defaultOptxScore === 'il' ? 'il_optx_score' : 'optx_score'}
                />
              </Styled.QuickFilterWrapper>
              <Styled.QuickFilterWrapper>
                <Styled.SingleSelectFilter filterKey="psg_fit" />
              </Styled.QuickFilterWrapper>
              <Styled.QuickFilterWrapper>
                <Styled.SingleSelectFilter filterKey="is_software" />
              </Styled.QuickFilterWrapper>
            </Styled.QuickFiltersContent>
          </Styled.PageFiltersWrapper>
          <Styled.CardsWrapper>
            <StatsCard />
          </Styled.CardsWrapper>
          <Styled.TableContentWrapper>
            <Styled.TableTitleContainer>
              <ViewBy />
              <div>
                {!fullscreen && <GridFullScreen />}
                <GridDropdown />
              </div>
            </Styled.TableTitleContainer>
            <DisplayColumnModal />
            <SearchGrid />
          </Styled.TableContentWrapper>
        </Styled.MainContent>
        <CompanyTouches />
        <EditAllDialog />
        <FiltersModal />
        <DocumentUploadModal />
      </Styled.Wrapper>
    </>
  );
};

export default ChecklistResearchInsights;
