import React, { useRef, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, Tooltip } from 'antd';
import { Formik, FormikProps } from 'formik';
import { Form, Input } from 'formik-antd';
import { useToggle } from '@umijs/hooks';
import { Dictionary } from 'lodash';
// redux
import {
  selectors as savedSearchesSelectors,
  actions as savedSearchesActions,
} from '../../../redux/contacts/saved-searches';
import { selectors as searchSelectors } from '../../../redux/contacts/search/search';
// components
import Icon from '../../common/Icon';
import { saveListSchema } from '../../../common/validation/save-search';

interface SaveSearchFormState {
  title: string;
}

const initialState: SaveSearchFormState = {
  title: '',
};

const SaveSearch = () => {
  const dispatch = useDispatch();

  const formRef = useRef<FormikProps<SaveSearchFormState> | null>(null);
  // modal
  const { state: modalIsOpen, toggle } = useToggle();

  const toggleModal = () => {
    if (formRef.current) {
      formRef.current.resetForm();
    }

    toggle();
  };

  // submit
  const filter = useSelector(searchSelectors.getFilter);

  const saveSearch = useCallback(
    (title: string) => dispatch(savedSearchesActions.saveContactSearch(title, filter)),
    [dispatch, filter]
  );

  const handleSubmit = (values: SaveSearchFormState) => {
    saveSearch(values.title);
    toggleModal();
  };

  // validation
  const savedListTitles: Dictionary<boolean> = useSelector(savedSearchesSelectors.getTitles);
  const validationSchema = useMemo(() => saveListSchema(savedListTitles), [savedListTitles]);

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      formRef.current?.submitForm();
    }
  };

  return (
    <>
      <Tooltip title="Save this view">
        <Button type="text" onClick={toggleModal} icon={<Icon iconName="bookmark" />} />
      </Tooltip>
      <Formik
        initialValues={initialState}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
        innerRef={instance => {
          formRef.current = instance;
        }}
        component={({ submitForm, isValid }) => (
          <Modal
            title="Save this view"
            visible={modalIsOpen}
            onCancel={toggleModal}
            okButtonProps={{ onClick: submitForm }}
            okText="Save View"
          >
            <Form layout="vertical" onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
              <Form.Item
                label="Please give your view a name"
                name="title"
                validateStatus={isValid ? 'success' : 'error'}
              >
                <Input name="title" id="success" />
              </Form.Item>
            </Form>
          </Modal>
        )}
      />
    </>
  );
};

export default SaveSearch;
