import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
// services
import NotificationService from '@services/NotificationService';
import { UserService } from '@services/api';
// models
import { SuccessErrorCallback } from '@optx/models/callback';
// redux
import * as actions from './actions';

export function* updateProfileImageSaga(
  action: PayloadAction<Blob | null, any, SuccessErrorCallback>
) {
  const imageBlob = action.payload;
  const callback = action.meta;

  try {
    if (imageBlob) {
      const res: AxiosResponse<Blob> = yield call(UserService.updateProfilePicture, imageBlob);
      yield put(actions.updateProfileImageSuccess(res.data));
      callback && callback(true);
      NotificationService.success('Profile image updated');
    }
  } catch (error: any) {
    const errorMessage = 'Failed to update profile image!!!';
    callback && callback(null, errorMessage);
    NotificationService.error(errorMessage);
  }
}

export default function* profileSaga() {
  yield takeLatest(actions.updateProfileImage, updateProfileImageSaga);
}
