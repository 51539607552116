import React, { useRef, useMemo, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, Tooltip } from 'antd';
import { Formik, FormikProps } from 'formik';
import { Dictionary, pickBy } from 'lodash';
// models
import { SaveView } from '@optx/models/search';
import { CustomUIViewIds } from '@optx/models/user';
// constants
import { customUIViewIds } from '@optx/constants/table/columnDisplay/company-search';
// utils
import { hasFilters } from '@optx/utils/search';
// redux
import {
  selectors as savedSearchesSelectors,
  actions as savedSearchesActions,
} from '@redux/company/saved-searches';
import { selectors as modalSelectors, actions as modalActions } from '@redux/ui/modals/save-search';
// components
import Icon from '@optx/components/common/Icon';
import getValidationSchema from '@optx/common/validation/save-search';
import SaveSearchForm from './SaveSearchForm';

const initialState: SaveView = {
  title: '',
  filters: true,
  sorting: true,
  columns: true,
  pinnedColumns: true,
  isDefault: false,
  isOverwritten: false,
  hasSameName: false,
};

interface SaveSearchProps {
  viewId?: string | number;
  showButton?: boolean;
}

const SaveSearch: React.FC<SaveSearchProps> = ({ viewId, showButton = true }) => {
  const dispatch = useDispatch();
  const [initialFormData, setInitialFormData] = useState(initialState);
  const view = useSelector(savedSearchesSelectors.getViewData);
  const isSaveBtnDisabled = useRef<boolean>(false);
  const isSearchNameOverwritten = useRef<boolean>(false);
  const modalIsOpen = useSelector(modalSelectors.isOpen);

  const toggle = useCallback(() => dispatch(modalActions.toggleSaveSearchModal()), [dispatch]);

  const formRef = useRef<FormikProps<SaveView> | null>(null);

  const toggleModal = () => {
    if (formRef.current) {
      formRef.current.resetForm();
    }

    toggle();
    isSaveBtnDisabled.current = false;
    isSearchNameOverwritten.current = false;
  };

  const isCustomUIView = useMemo(() => {
    return customUIViewIds.includes(view?.unique_id as CustomUIViewIds);
  }, [view]);

  const handleSubmit = (values: SaveView) => {
    // if optx view is default or no changes have been made,
    // when clicking on save button don't dispatch just close the modal

    if (
      view &&
      (view.unique_id === 'default' || isCustomUIView) &&
      (view.is_default || !values.isDefault)
    ) {
      toggle();
    } else if (view) {
      if (isCustomUIView) {
        dispatch(
          savedSearchesActions.modifyCustomUIView(
            view.unique_id as CustomUIViewIds,
            values.isDefault
          )
        );
      } else {
        dispatch(savedSearchesActions.modifyView({ ...values, id: view.unique_id }, view));
      }

      toggleModal();
    } else {
      dispatch(savedSearchesActions.saveView(values));
      toggleModal();
    }
  };

  const handleDelete = () => {
    dispatch(savedSearchesActions.deleteSearch({ id: view!.unique_id, viewId }));
    toggle();
  };

  useEffect(() => {
    // when editing, check the view's saved data and
    // update the form accordingly
    if (view) {
      const formData: Partial<SaveView> = {};

      if (view.unique_id !== 'default') {
        if (!hasFilters(view.search_criteria) && !view.searchKey) {
          formData.filters = false;
        }

        // combine search does not have filters in search_criteria
        // so we need to set it to true manually
        if (view.search_info.type === 'combined') {
          formData.filters = true;
        }

        if (!view.sortBy) {
          formData.sorting = false;
        }

        if (!view.columns) {
          formData.columns = false;
        }

        if (view.pinned_columns === null) {
          formData.pinnedColumns = false;
        }
      }

      if (view.is_default) {
        formData.isDefault = true;
      }

      setInitialFormData({ ...initialState, ...formData, title: view.title });
    } else {
      setInitialFormData(initialState);
    }
  }, [view]);

  const isDisabled = useMemo(() => {
    return view?.unique_id === 'default' || isCustomUIView;
  }, [view, isCustomUIView]);

  // validation
  const savedListTitles: Dictionary<boolean> = useSelector(savedSearchesSelectors.getTitles);
  // when validating in edit mode, remove current title from list, so that when
  // saving, it doesn't trigger an error by having the title seem like it's not unique
  const validationSchema = useMemo(
    () =>
      getValidationSchema(
        view ? pickBy(savedListTitles, (value, key) => key !== view.title) : savedListTitles
      ),
    [savedListTitles, view]
  );

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      formRef.current?.submitForm();
    }
  };

  const setIsSaveBtnDisabled = (value: boolean) => {
    isSaveBtnDisabled.current = value;
  };

  return (
    <>
      {showButton && (
        <Tooltip title="Save This Search" placement="bottom">
          <Button type="text" onClick={toggleModal} icon={<Icon iconName="bookmark" />} />
        </Tooltip>
      )}
      <Formik
        initialValues={initialFormData}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
        innerRef={instance => {
          formRef.current = instance;
        }}
        component={({ submitForm, errors }) => (
          <Modal
            title={view ? 'Manage Saved Search' : 'Save This Search'}
            visible={modalIsOpen}
            onCancel={toggleModal}
            okButtonProps={{
              onClick: submitForm,
              disabled: isSaveBtnDisabled.current && !view,
            }}
            okText="Save Search"
          >
            <SaveSearchForm
              errors={errors}
              view={view}
              savedListTitles={savedListTitles}
              areOptionsDisabled={isDisabled}
              handletoggleModal={toggleModal}
              handleDropdownKeyEvent={handleDropdownKeyEvent}
              handleDelete={handleDelete}
              handleSetSaveBtnDisabled={setIsSaveBtnDisabled}
            />
          </Modal>
        )}
      />
    </>
  );
};

export default React.memo(SaveSearch);
