import numeral from 'numeral';
import { orderBy } from 'lodash';
// models
import Company, {
  CompanyProfile,
  CompanyTag,
  FinancialFieldRangeValue,
} from '@optx/models/Company';
import { SelectOption } from '@optx/models/Option';
// constants
import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';

/**
 * Function to return company address based on different company properties
 * @param {Company} company company data
 * @param defaultValue optional default display value to compare against
 */
export const getCompanyAddress = (company: Company, defaultValue: string | null = null) => {
  // if these company properties have the default value
  // or they're null, consider them undefined
  const street = company.street !== defaultValue ? company.street : undefined;
  const city = company.city !== defaultValue ? company.city : undefined;
  const state = company.state !== defaultValue ? company.state : undefined;
  const country = company.country !== defaultValue ? company.country : undefined;

  // display the value, if it has one, then check whether or not we have
  // subsequent values and add a comma
  // eslint-disable-next-line max-len
  return `${street || ''}${street && (city || state || country) ? ', ' : ''} ${city || ''}${
    city && (state || country) ? ', ' : ''
  } ${state || ''}${state && country ? ', ' : ''} ${country || ''}`.trim();
};

/**
 * Function to return the previous and next company ids of a company in a list
 *  @param companyId the company id for which the previous and next company id is returned
 */
export const getPreviousNextCompanyIds = (companyId: number) => (state: Company[]) => {
  const index = state.findIndex((company: Company) => company.company_id === companyId);

  return [
    state[index - 1] && state[index - 1].company_id,
    state[index + 1] && state[index + 1].company_id,
  ];
};

/**
 * for revenue and ebitda we are using revenue_list, respectively ebitda_list properties,
 * for showing corresponding amounts for available years.
 * for revenue we have a special case, there is additional data coming from
 * last_rev_update_amount and last_rev_update_year. this function is used to check those
 * properties and add that data to revenue_list
 * @param {Company | CompanyProfile} stateData company data, from profile or grid
 */
export const updateRevenueList = (stateData: Company | CompanyProfile) => {
  const revenue = numeral(stateData.last_rev_update_amount).value();
  let revenueValue: number | FinancialFieldRangeValue | null = revenue;

  // if last_rev_update_amount_high has value means it's a range value (selected from dropdown)
  if (revenue && stateData.last_rev_update_amount_high !== DEFAULT_CELL_VALUE) {
    const revenueMax = numeral(stateData.last_rev_update_amount_high).value();

    revenueValue = { min: revenue, max: revenueMax };
  }

  if (revenueValue) {
    if (stateData.revenue_list === null) {
      // eslint-disable-next-line no-param-reassign
      stateData.revenue_list = [
        {
          value: revenueValue,
          year: stateData.last_rev_update_year || new Date().getFullYear(),
        },
      ];
    } else if (!stateData.revenue_list.some(item => item.year === stateData.last_rev_update_year)) {
      const newRevenueList = [
        ...stateData.revenue_list,
        {
          value: revenueValue,
          year: stateData.last_rev_update_year || new Date().getFullYear(),
        },
      ];

      // eslint-disable-next-line no-param-reassign
      stateData = { ...stateData, revenue_list: orderBy(newRevenueList, ['year'], 'desc') };
    }
  }
};

/**
 * Checks if a company is a PSG company
 * @param {Company} company - The company to check
 * @returns {boolean} - true if the company is meeting the conditions, false otherwise
 */
export const getIsPsgCompany = (company: Company) => {
  return (
    company.is_psg_company ||
    (company.stage === 'Portfolio Company' && company.fund !== 'PEP Flagship')
  );
};

/**
 * getTags - Returns an array of SelectOption objects from a given array of CompanyTag objects
 * @param {CompanyTag[] | null} specialties - An array of CompanyTag objects, coming from api
 * @returns {SelectOption[]} A sorted array of SelectOption objects
 */
export const getTags = (specialties: CompanyTag[] | null) => {
  if (!specialties) {
    return [];
  }

  return specialties
    .map(specialty => {
      const tag: SelectOption = {
        label: specialty.tag,
        value: specialty.tag_id.toString(),
        closable: specialty.from_user,
      };

      return tag;
    })
    .sort((firstTag, secondTag) => firstTag.label.localeCompare(secondTag.label));
};
