import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import * as actions from './actions';
import { toggleReducer } from '../../../feature/toggle/reducers';
import {
  fetchReducer,
  fetchFailReducer,
  fetchSuccessReducer,
} from '../../../feature/fetch/reducers';
import { UserInvitationState } from './interfaces';

const initialState: UserInvitationState = {
  loading: false,
  error: '',
  data: [],
  open: false,
};

const toggleUserInvitationDialogReducer: CaseReducer<UserInvitationState, PayloadAction> = (
  draftState,
  action
) => {
  draftState.open = toggleReducer(draftState.open);
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.toggleUserInvitationDialog, toggleUserInvitationDialogReducer)
    .addCase(actions.sendUserInvite, fetchReducer)
    .addCase(actions.sendUserInviteSuccess, fetchSuccessReducer)
    .addCase(actions.sendUserInviteFail, fetchFailReducer)
);

export default reducer;
