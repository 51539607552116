import React from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { SelectOption } from '@optx/models/Option';
import { Styled } from './PreviousPeriodSelect.styled';
import { pipelineCriteria } from '../../constants/custom-criteria';
import { selectors } from '../../state';

const emptyValue: [SelectOption] = [{ label: 'None', value: '' }];

export const PreviousPeriodSelect = () => {
  const isAllTimeRange = useSelector(selectors.filters.isAllTimeDate);
  const storeValue = useSelector((state: any) =>
    selectors.filters.getSelectValue(state, 'previous_period', pipelineCriteria)
  );
  const value = isAllTimeRange ? emptyValue : storeValue;

  return (
    <Styled.Select
      filterKey="previous_period"
      criteria={pipelineCriteria}
      valuePrefix="Compare with:"
      SearchIcon={<CalendarOutlined />}
      isDisabled={isAllTimeRange}
      value={value}
    />
  );
};
