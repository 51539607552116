import React from 'react';
import { Breadcrumb } from 'antd';
import classNames from 'classnames';
import { BreadcrumbProps } from 'antd/lib/breadcrumb';
// styles
import './styles.scss';
// components
import { RestoreDefaults, GoBackProfile, CompanyProfileItem } from '../breadcrumb-items';

interface MainBreadcrumbProps extends BreadcrumbProps {
  /**
   * Color of the breadcrumb is white and contrasts with darker background.
   */
  inverted?: boolean;
  /**
   * whether to go to the previous page or redirect to advanced search page and reset search
   */
  goBack?: boolean;
}

const MainBreadcrumb: React.FC<MainBreadcrumbProps> = ({
  className,
  inverted,
  goBack,
  ...restProps
}) => {
  const finalClassName = classNames(
    'main-breadcrumb',
    {
      'main-breadcrumb--inverted': inverted,
    },
    className
  );

  return (
    <Breadcrumb className={finalClassName} {...restProps}>
      {goBack ? <GoBackProfile /> : <RestoreDefaults />}
      <CompanyProfileItem />
    </Breadcrumb>
  );
};

export default React.memo(MainBreadcrumb);
