import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { Form, Input } from 'formik-antd';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
// redux
import { actions } from '@components/feature/company-individual-edit/state/rationale';

export interface RationaleForm {
  rationale: string;
}

const initialValues: RationaleForm = {
  rationale: '',
};

const validationSchema = yup.object<RationaleForm>({
  rationale: yup.string().required('Field is required!').max(500, 'Maximum 500 characters'),
});

type EditFundProfileProps = {
  companyId: number;
  isOpen: boolean;
  toggle: () => void;
};

const EditRationaleProfile: React.FC<EditFundProfileProps> = ({ companyId, isOpen, toggle }) => {
  const dispatch = useDispatch();

  const formRef = useRef<FormikProps<RationaleForm> | null>(null);

  const handleSubmit = (values: RationaleForm) => {
    dispatch(actions.addRationale({ companyId, rationale: values.rationale }));
    toggle();
  };

  const handleOk = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
      handleOk();
    }
  };

  return (
    <>
      <Modal
        title="Edit Rationale"
        visible={isOpen}
        onCancel={toggle}
        onOk={handleOk}
        okText="Save"
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          innerRef={instance => {
            formRef.current = instance;
          }}
          component={() => (
            <Form layout="vertical" onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
              <Form.Item name="rationale">
                <Input.TextArea name="rationale" rows={6} />
              </Form.Item>
            </Form>
          )}
        />
      </Modal>
    </>
  );
};

export default EditRationaleProfile;
