import * as yup from 'yup';
// constants
import { phoneRegExp } from '@optx/constants/regex';

const validation = yup.object({
  first_name: yup.string().required('Field is required!'),
  last_name: yup.string().required('Field is required!'),
  tenure_start_date: yup
    .string()
    .nullable()
    .test({
      name: 'tenureStartDateCheck',
      message: 'Tenure Start Date can not be greater than Tenure End Date',
      test(value) {
        const endDate = this.parent.tenure_end_date;

        if (!value) {
          return true;
        }

        if (endDate && Date.parse(value) > Date.parse(endDate)) {
          return false;
        }

        return true;
      },
    })
    .test({
      name: 'tenureStartDateEmptyCheck',
      message: 'Tenure Start Date can not be empty if Tenure End Date is present',
      test(value) {
        const endDate = this.parent.tenure_end_date;

        if (!value && endDate) {
          return false;
        }

        return true;
      },
    }),
  tenure_end_date: yup
    .string()
    .nullable()
    .test({
      name: 'tenureEndDateCheck',
      message: 'Tenure End Date must be equal or greater than Tenure Start Date',
      test(value) {
        const startDate = this.parent.tenure_start_date;

        if (!value) {
          return true;
        }

        if (startDate && Date.parse(startDate) > Date.parse(value)) {
          return false;
        }

        return true;
      },
    }),
  title: yup.string().nullable().required('Field is required!'),
  emails: yup.array().of(
    yup.object().shape({
      email: yup.string().email('Invalid'),
    })
  ),
  primary_contact: yup.boolean(),
  // if you select phone type, show error if no phone number is added and vice versa
  additional_phones: yup.array().of(
    yup.object().shape({
      phone_number: yup
        .string()
        .max(20, 'Maximum 20 characters')
        // max character with symbol, space and number. Max length is in French Guiana ex: +594 700 XXX XXX XXX
        .matches(
          phoneRegExp,
          'Only numbers, dashes, dots and "+" are allowed. Minimum 4 characters, maximum 20 characters'
        )
        .test({
          name: 'phoneValueCheck',
          message: 'Please add phone number',
          test(value) {
            if (!value && !this.parent.phone_type_id) {
              return true;
            }

            if (!value) {
              return false;
            }

            return true;
          },
        }),
      phone_type_id: yup.string().test({
        name: 'phoneTypeCheck',
        message: 'Please select phone type',
        test(value) {
          if (!value && !this.parent.phone_number) {
            return true;
          }

          if (!value) {
            return false;
          }

          return true;
        },
      }),
    })
  ),
  email: yup.string().nullable(),
});

export default validation;
