import { createReducer, CaseReducer } from '@reduxjs/toolkit';
// constants
import { INITIAL_STATE_CHART } from '@constants/charts';
// utils
import { parseReversedValues } from '@utils/charts';
// redux
import { fetchFailReducer, fetchReducer } from '@redux/feature/fetch/reducers';
import { SocialMediaChartData } from '@models/charts/data';
import { LOCATION_CHANGE } from 'connected-react-router';
import * as actions from './actions';
import { SocialMediaGraphState } from '../interfaces';

const initialState: SocialMediaGraphState = {
  data: INITIAL_STATE_CHART as SocialMediaChartData,
  loading: false,
  error: '',
};

const fetchCrunchbaseDataSuccessReducer: CaseReducer<SocialMediaGraphState> = (
  draftState,
  action
) => {
  draftState.data = parseReversedValues(action.payload);
  draftState.loading = false;
};

const clearStateReducer: CaseReducer<SocialMediaGraphState> = (draftState, action) => {
  if (!action.payload.location.pathname.includes('profile')) {
    draftState.data = INITIAL_STATE_CHART as SocialMediaChartData;
  }
};

export const reducer = createReducer<SocialMediaGraphState>(initialState, builder =>
  builder
    .addCase(actions.fetchCrunchbaseData, fetchReducer)
    .addCase(actions.fetchCrunchbaseDataSuccess, fetchCrunchbaseDataSuccessReducer)
    .addCase(actions.fetchCrunchbaseDataFail, fetchFailReducer)
    .addCase(LOCATION_CHANGE, clearStateReducer)
);
