import { createReducer } from '@reduxjs/toolkit';
// redux
import { reducers as fetchReducers } from '@redux/feature/fetch';
// local
import { AnalystsSmartTouchScoreState } from './interfaces';
import * as actions from './actions';

const initialState: AnalystsSmartTouchScoreState = {
  data: null,
  error: '',
  loading: false,
  fetchedAt: null,
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.fetch, fetchReducers.fetchReducer)
    .addCase(actions.fetchSuccess, fetchReducers.fetchSuccessReducer)
    .addCase(actions.fetchFail, fetchReducers.fetchFailReducer)
);

export default reducer;
