import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FileSearchOutlined } from '@ant-design/icons';
// models
import { AnalystSearchCardId } from '@optx/features/analyst-home/state/interfaces';
import { SearchPayload } from '@optx/models/api/contacts';
// utils
import { parseFilter } from '@utils/filters/parseFilters';
// redux
import { actions as searchActions } from '@features/grid/search';
import { selectors as filterSourcesSelectors } from '@redux/company/filters';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';
// components
import Styled from '@features/analyst-home/components/AnalystSearchCard/AnalystSearchCard.styled';
import Icon from '@components/common/Icon';

interface AnalystSearchCardProps {
  id: AnalystSearchCardId;
  title: string;
  subTitle: string;
  searchQuery: string;
}

const AnalystSearchCard: FC<AnalystSearchCardProps> = ({ id, title, subTitle, searchQuery }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const filterSources = useSelector(filterSourcesSelectors.getCompanyFilters);
  const { advancedSearchPathname } = useIsAnalyst();

  const clickHandler = () => {
    const payloadFilter = parseFilter(searchQuery, filterSources)[0];

    const payloadData: Partial<SearchPayload> = {
      searchKey: '',
      shouldResetPageNumber: true,
      filter: payloadFilter,
    };

    dispatch(searchActions.searchCompanies({ gridKey: 'advancedSearch', data: payloadData }));
    history.push(advancedSearchPathname);
  };

  const iconFontSize = id === 'file-search' ? '26px' : id === 'employee-growth' ? '38px' : '41px';

  return (
    <Styled.StyledCard onClick={clickHandler} hoverable>
      <Styled.IconWrapper>
        {id === 'file-search' ? (
          <FileSearchOutlined style={{ fontSize: iconFontSize, color: '#08478A' }} />
        ) : (
          <Icon
            iconName={id}
            style={{
              fontSize: iconFontSize,
            }}
          />
        )}
      </Styled.IconWrapper>
      <Styled.CardTitle>{title}</Styled.CardTitle>
      <Styled.SubTitle>{subTitle}</Styled.SubTitle>
    </Styled.StyledCard>
  );
};

export default AnalystSearchCard;
