import React from 'react';
import { Dictionary } from 'lodash';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
// models
import { AppState } from '@optx/redux/interfaces';
// Local
// styles
import { SecondaryTitle, TypographyTitle } from '../styled';
// redux
import { selectors } from '../../state';
// components
import FilterSingleSelectButtons from '../FilterSingleSelectButtons';
import FilterMultiSelect from '../FilterMultiSelect';

const headOptions: Dictionary<React.ReactNode> = {
  touches: (
    <>
      <Row justify="space-between">
        <Col>
          <TypographyTitle level={4}># of Touches by Date</TypographyTitle>
          <SecondaryTitle>This is the total # of touches across the sourcing group</SecondaryTitle>
        </Col>
        <Col>
          <FilterMultiSelect filterKey="touch_type_id" criteria="touches" />
        </Col>
      </Row>
      <FilterSingleSelectButtons filterKey="touch_type_status" criteria="touches" />
    </>
  ),

  companies_reached: (
    <>
      <Row justify="space-between">
        <Col>
          <TypographyTitle level={4}># of Companies Touched by Date</TypographyTitle>
          <SecondaryTitle>This is the total # of companies touched over time</SecondaryTitle>
        </Col>
        <Col>
          <FilterMultiSelect filterKey="deal_stage_id" criteria="companies_reached" />
        </Col>
      </Row>
      <FilterSingleSelectButtons
        filterKey="companies_reached_status"
        criteria="companies_reached"
      />
    </>
  ),
  stage_changes: (
    <>
      <Row justify="space-between">
        <Col>
          <TypographyTitle level={4}># of Stage Changes by Date</TypographyTitle>
          <SecondaryTitle>
            This is the total # of stage changes across the sourcing group
          </SecondaryTitle>
        </Col>
        <Col>
          <FilterMultiSelect filterKey="past_stage_id" criteria="stage_changes" />
        </Col>
      </Row>
      <FilterSingleSelectButtons filterKey="stage_change_status" criteria="stage_changes" />
    </>
  ),
};

const ChartCardHead = () => {
  const searchCategory = useSelector((state: AppState) =>
    selectors.filters.getRadioValue(state, 'filter_criteria')
  );

  if (searchCategory) {
    return <>{headOptions[searchCategory]}</>;
  }

  return null;
};

export default ChartCardHead;
