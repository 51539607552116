import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const LoadingIcon = styled(LoadingOutlined)`
  font-size: 42px;
  color: #4ce1b6;
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(223, 223, 223, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default {
  Wrapper,
  LoadingIcon,
};
