import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
// models
import { SocialMediaChartData } from '@optx/models/charts/data';
// services
import { CompanyService } from '@services/api';
import NotificationService from '@services/NotificationService';
// utils
import { getErrorMessage } from '@utils/api/errors';
// redux
import { INITIAL_STATE_CHART } from '@optx/constants/charts';
import * as actions from './actions';

export function* fetchLinkedinDataaga(action: PayloadAction<number, any, boolean>) {
  const companyId = action.payload;

  try {
    const res: AxiosResponse<SocialMediaChartData> = yield call(
      CompanyService.getCompanySocialMediaLinkedin,
      companyId
    );
    const chartData = res.data;

    if (chartData) {
      yield put(actions.fetchLinkedinDataSuccess(chartData));
    } else {
      yield put(actions.fetchLinkedinDataSuccess(INITIAL_STATE_CHART as SocialMediaChartData));
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Failed to fetch Linkedin Data');
    yield put(actions.fetchLinkedinDataFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* linkedinDataSaga() {
  yield takeLatest(actions.fetchLinkedinData, fetchLinkedinDataaga);
}
