import React from 'react';
import { TooltipPayload, TooltipProps } from 'recharts';
// models
import { ChartIntervalOption } from '@optx/models/feature/analystsLeaderboard/chart';
// utils
import { tooltipValueFormat } from '@optx/utils/chart/tooltip';
// components
import StyledTooltip from '@optx/components/common/charts/Tooltip.styles';
import Styled from './LeaderboardChartTooltip.styled';

interface LeaderboardChartTooltipProps extends TooltipProps {
  // override default tooltip props
  payload: ReadonlyArray<TooltipPayload>;
}

const LeaderboardChartTooltip = ({
  active,
  payload,
  label,
  labelStyle,
  separator,
}: LeaderboardChartTooltipProps) => {
  if (active) {
    const current = payload.find(item => item.dataKey === 'selectedPeriod');
    const previous = payload.find(item => item.dataKey === 'previousPeriod');

    const showCurrent = current && current.payload.selectedPeriod !== null;
    const showPrevious = previous && previous.payload.previousPeriod !== null;
    const previousPeriodLabel = previous
      ? (previous.payload as ChartIntervalOption).previous_date
      : null;

    return (
      <StyledTooltip.Wrapper>
        {showCurrent && current && (
          <>
            <StyledTooltip.Label style={{ ...labelStyle }}>{label}</StyledTooltip.Label>
            <div style={{ color: current.color }}>{tooltipValueFormat(current, separator)}</div>
          </>
        )}

        {showPrevious && previous && (
          <>
            <Styled.PreviousPeriodLabel style={{ ...labelStyle }}>
              {previousPeriodLabel}
            </Styled.PreviousPeriodLabel>
            <div style={{ color: previous.color }}>{tooltipValueFormat(previous, separator)}</div>
          </>
        )}
      </StyledTooltip.Wrapper>
    );
  }

  return null;
};

export default LeaderboardChartTooltip;
