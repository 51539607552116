// models
import { ColumnType } from 'antd/lib/table';
import { SalesloftBulkResults } from '@optx/models/bulkActions';
// components
import { TableCellURL, TableCellCompanyName } from '@components/common/table/AntTable/cells';
import {
  TableCellStatusSalesloft,
  TableCellErrorReasonSalesloft,
  TableCellSalesloftBulkUrl,
  TableCellActionsSalesloft,
} from './cells';

const columns: ColumnType<SalesloftBulkResults>[] = [
  {
    dataIndex: 'company_website',
    title: '',
    render: (value: string, record: SalesloftBulkResults) => (
      <TableCellURL value={value} record={{ url: record.company_raw_url }} />
    ),
    width: 42,
    align: 'center',
  },
  {
    dataIndex: 'company_name',
    title: 'Company Name',
    render: TableCellCompanyName,
    width: 250,
  },
  {
    title: 'View in Sales Loft',
    render: TableCellSalesloftBulkUrl,
    width: 250,
  },
  {
    title: 'Sync Status',
    render: TableCellStatusSalesloft,
    width: 100,
  },
  {
    title: 'Error Reason',
    render: TableCellErrorReasonSalesloft,
    width: 250,
  },
  {
    title: 'Action',
    render: TableCellActionsSalesloft,
    className: 'contact-cell',
    width: 380,
  },
];

export default columns;
