import styled from 'styled-components';
import { Row } from 'antd';
// utils
import { styledColors } from '@optx/utils/theme/colors';
// components
import Icon from '@components/common/Icon';

const FinancialYear = styled.span`
  &:hover {
    cursor: pointer;
    .icon {
      visibility: visible;
    }
  }
`;

const PenIcon = styled(Icon)<{ $visible?: boolean }>`
  cursor: pointer;
  color: ${styledColors.blue6};
  font-size: 14px;
  vertical-align: top;
  margin-left: 5px;
  margin-top: 3px;
  visibility: hidden;

  :hover {
    color: ${styledColors.blue6};
  }
`;
const FinancialRow = styled(Row)`
  .financial-validation > div {
    max-width: 100%;
  }
  .has-errors.financial-validation .ant-input,
  .has-errors.financial-validation .ant-input-affix-wrapper {
    border: 1px solid #ff7875;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  }
  .valid-input.financial-validation .ant-input-affix-wrapper:hover,
  .valid-input.financial-validation .ant-input-affix-wrapper,
  .valid-input.financial-validation .ant-input,
  .valid-input.financial-validation .ant-input:hover {
    border: 1px solid #d9d9d9;
  }
  .editda-dropdown .ant-dropdown-menu {
    @media (max-height: 1080px) {
      max-height: 350px;
      overflow-y: auto;
    }
  }
`;
const MillionsLabel = styled.span`
  font-size: 14px;
  color: #90a0b7;
  font-weight: 400;
`;
export default {
  FinancialYear,
  PenIcon,
  FinancialRow,
  MillionsLabel,
};
