import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
// redux
import { selectors } from '@features/checklist-research-insights/state';
// components
import * as Styled from './StatsCards.styled';
import StatsLabel from './components/StatsLabel';

const SoftwareCompanyAccuracy = () => {
  const softwareData = useSelector(selectors.cards.getSoftware);
  const loading = useSelector(selectors.search.getLoading);

  return (
    <Styled.StatsCardsContainer title="Software Company Accuracy" loading={loading}>
      <Row>
        {Object.keys(softwareData!).map((item: string, index: number) => {
          let firstValue = null;
          let lastValue = null;

          if (softwareData![item] !== null) {
            if (item.includes('Accuracy')) {
              firstValue = null;
              lastValue = softwareData![item];
            } else {
              firstValue = softwareData![item]?.split('|')[0];
              lastValue = softwareData![item]?.split('|')[1];
            }
          }

          if (firstValue === null && lastValue === null) {
            return (
              <Col span={12} key={index}>
                <StatsLabel value={['0', undefined]} label={item.toUpperCase()} />
              </Col>
            );
          }

          return (
            <Col span={12} key={index}>
              <StatsLabel
                value={[firstValue, lastValue]}
                label={item.toUpperCase()}
                color={item === '# changed' ? '#C41D7F' : undefined}
                isSmall={index !== 0 && index !== 1}
              />
            </Col>
          );
        })}
      </Row>
    </Styled.StatsCardsContainer>
  );
};

export default SoftwareCompanyAccuracy;
