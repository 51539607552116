import React, { useMemo, useRef, useContext, useState, useCallback, useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import { ActionMeta, ValueType } from 'react-select';
import { StylesConfigFunction } from 'react-select/src/styles';
// models
import { MultiSelectFilter, FilterSource, Filter } from '@optx/models/filters';
import Option, { SelectOption } from '@optx/models/Option';
// utils
import { Dictionary } from 'lodash';
import { mapToSelectOption } from '@utils/option';
import {
  arrayUnion,
  checkHelperValues,
  helperValueIndex,
  removeElementByValue,
  removeMultipleElements,
} from '@utils/filters/filterHelpers';
// components
import { MultiSelect } from '@shared/view/molecules/Select';
import SearchFilterCard from './SearchFilterCard';
import { FiltersContext } from '../FiltersContext';

interface FilterMultiSelectOptions {
  source: FilterSource<Filter<any>>;
  filter: MultiSelectFilter<Array<Option>>;
  isAddon?: boolean;
}

const getFilterLabel = (label: string) => {
  let newLabel = label;
  const touchTypes = ['Completed', 'Scheduled'];

  touchTypes.forEach(touchType =>
    label.includes(touchType) ? (newLabel = label.replace(touchType, '').trim()) : undefined
  );

  return newLabel;
};

const NO_TOUCHES_OPTION_LABEL = 'No Touches';

const FilterMultiSelect: React.FC<FilterMultiSelectOptions> = ({ source, filter, isAddon }) => {
  const [field, , helpers] = useField<Array<SelectOption> | undefined>(filter.column);
  const isChanged = useRef(false);
  const [isFilter, setFilter] = useState<boolean>(false);
  const { values: formFields, getFieldHelpers } =
    useFormikContext<Dictionary<Array<SelectOption>>>();
  const { onManualFilterChange } = useContext(FiltersContext);
  const onlyFilters = filter.data.filter(
    obj => obj.relatedoptions && obj.relatedoptions.length > 0
  );

  const options = useMemo(() => {
    /*  This function filters the data of a given filter by checking if the parent of
        each child object matches the name of the given object. It then maps each child
        object to a select option using the provided format name and adds it to the
        accumulator. This function is used in the FilterMultiSelect component of the
        company-filters feature. 
    */
    return filter.data.reduce((acc: Option[], obj: Option) => {
      if (!obj.parent) {
        const option: Option = mapToSelectOption(obj, filter.format_name);
        acc.push(option);

        const childObjs = filter.data.filter(
          (childObj: Option<string>) => childObj.parent === obj.name
        );

        if (childObjs.length > 0) {
          childObjs.forEach(childObj => {
            const childOption: Option = mapToSelectOption(childObj, filter.format_name);
            acc.push(childOption);
          });
        }
      }

      return acc;
    }, []);
  }, [filter.data, filter.format_name]);

  const hasNoTouchOption = options.some(option => option.label === NO_TOUCHES_OPTION_LABEL);
  const hasCustomNoTouchesOption =
    hasNoTouchOption && (filter.column === 'schedule_touch' || filter.column === 'completed_touch');

  useEffect(() => {
    if (filter.column === 'company_owner_id' && Array.isArray(field.value)) {
      const checkedValues = field.value?.map(element => element.value);

      if (onlyFilters.length > 0) {
        for (const filter of onlyFilters) {
          if (Array.isArray(filter.relatedoptions)) {
            const allValuesPresent = filter.relatedoptions.every(value =>
              checkedValues.includes(value.value)
            );

            const elementChecked = checkedValues.some(e => e === filter.value);

            if (allValuesPresent && !elementChecked) {
              const normalizedValues = [
                ...(field.value as []),
                {
                  value: filter.value,
                  label: filter.name,
                },
              ];
              helpers.setValue(normalizedValues as Array<SelectOption>);
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  useEffect(() => {
    if (filter.used_for.length && field.value) {
      const helperValues = checkHelperValues(
        field.value as Array<Option>,
        filter.used_for[0],
        source
      );

      getFieldHelpers(filter.used_for[0]).setValue(helperValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (values: ValueType<Option>, action: ActionMeta<SelectOption>) => {
    isChanged.current = true;
    helpers.setValue(values as Array<SelectOption>);
    const valuesArray = [...(values as Array<SelectOption>)];
    let unionArray: SelectOption<string>[] = [];

    if (filter.column === 'company_owner_id') {
      if (onlyFilters.length > 0) {
        for (const filter of onlyFilters) {
          if (
            action.action === 'select-option' &&
            valuesArray!.some(e => e.value === filter.value)
          ) {
            let filteredArray = arrayUnion(filter.relatedoptions as SelectOption[], valuesArray);
            helpers.setValue(filteredArray as SelectOption[]);
          }

          if (
            action.action === 'select-option' &&
            filter.relatedoptions?.some(obj => obj.value === action.option!.value) &&
            filter.relatedoptions?.every(obj2 =>
              valuesArray.some((obj1: { value: string }) => obj1.value === obj2.value)
            )
          ) {
            valuesArray.push({ value: action.option!.value, label: action.option!.label });
            let filteredArray = arrayUnion(filter.relatedoptions as SelectOption[], valuesArray);
            helpers.setValue(filteredArray);
          }

          if (action.action === 'select-option') {
            if (valuesArray.some(va => va.value === filter.value)) {
              unionArray = arrayUnion(unionArray, filter.relatedoptions as SelectOption[]);
            }

            let filteredArray = arrayUnion(unionArray, valuesArray);
            helpers.setValue(filteredArray);
          }

          if (
            (action.action === 'deselect-option' || action.action === 'remove-value') &&
            valuesArray!.some(e => e.value === filter.value)
          ) {
            if (filter.relatedoptions!.some(e => e.value === action.option!.value)) {
              let filteredArray = removeElementByValue(valuesArray, filter.value);
              helpers.setValue(filteredArray);
            }
          }

          if (action.action === 'deselect-option' && action.option!.value === filter.value) {
            helpers.setValue(
              removeMultipleElements(valuesArray, filter.relatedoptions as SelectOption[])
            );
          }
        }
      }
    }
    // check if multi select has a helper filter and
    // update the helper filter's values

    if (filter.used_for.length) {
      // if selecting option check values against helper
      // filter's entries and update it's options
      if (action.action === 'select-option' || action.action === 'set-value') {
        const helperValues = checkHelperValues(values as Array<Option>, filter.used_for[0], source);

        getFieldHelpers(filter.used_for[0]).setValue(helperValues);
      } else if (action.action === 'deselect-option' || action.action === 'remove-value') {
        // if removing option check if value is included in one of the
        // helper's filter selected options and remove it
        const index = helperValueIndex(action.option!.value, formFields[filter.used_for[0]]);

        if (index !== undefined) {
          const newValues = formFields[filter.used_for[0]];
          newValues.splice(index, 1);
          getFieldHelpers(filter.used_for[0]).setValue(newValues);
        } else if (action.option!.value === '*') {
          getFieldHelpers(filter.used_for[0]).setValue([]);
        }
      }
    }

    filterChanged();
  };

  const filterChanged = () => {
    if (isChanged.current) {
      isChanged.current = false;
      // Call on change on the next tick.

      setTimeout(() => {
        onManualFilterChange && onManualFilterChange(filter.column);
      }, 0);
    }
  };

  const handleBlur = () => {
    filterChanged();
  };

  const changeMultiselectStyleBehavior = useCallback(
    (value: string) => {
      const optionsSelected = options.filter(
        option =>
          option.label?.toLowerCase().includes(value.toLowerCase()) ||
          option.value?.toLowerCase().includes(value.toLowerCase())
      );
      const filteredOptions =
        optionsSelected.find(option => option.label === 'Unowned' || option.label === 'Unknown') ||
        filter.column === 'stage';

      if (!filteredOptions) {
        setFilter(true);
      } else {
        setFilter(false);
      }
    },
    [options, filter.column]
  );

  const transformedOptions = options.map(option => {
    for (const filter of onlyFilters) {
      if (option.value === filter.value) {
        return { ...option, isBold: true };
      }
    }

    return option;
  });

  const customNoTouchOptionStyle: StylesConfigFunction<any> = (base, state) => {
    const isNoTouchOption =
      hasCustomNoTouchesOption && state.data.label === NO_TOUCHES_OPTION_LABEL;

    return {
      ...base,
      padding: isNoTouchOption ? '8px 12px 12px' : base.padding,
      borderBottom: isNoTouchOption ? '1px solid #d9d9d9' : base.borderBottom,
      marginBottom: isNoTouchOption ? '8px' : base.marginBottom,
    };
  };

  return (
    <SearchFilterCard label={getFilterLabel(filter.label)}>
      <MultiSelect
        styles={{ option: customNoTouchOptionStyle }}
        hasCustomNoTouchesOption={hasCustomNoTouchesOption}
        allowSelectAll
        options={transformedOptions as any}
        onChange={handleChange}
        onInputChange={value => changeMultiselectStyleBehavior(value)}
        value={field.value}
        placeholder={filter.placeholders}
        onBlur={handleBlur}
        menuPlacement="auto"
        minMenuHeight={250}
        className={`${filter.column}${isFilter ? '' : '--multiselect'} ${
          isAddon ? 'is-addon' : ''
        }`}
        menuIsOpen={isAddon}
      />
    </SearchFilterCard>
  );
};

export default React.memo(FilterMultiSelect);
