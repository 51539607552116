import styled from 'styled-components';
import { Title } from '@optx/shared/view/atoms/Typography';

const PageTitle = styled(Title)`
  h1& {
    color: #1d1d1d;
    margin-bottom: 16px;
    font-weight: normal;
  }
`;

export default {
  PageTitle,
};
