import React from 'react';
import { useSelector } from 'react-redux';
// models
import { AuthorizationComponentType } from '@models/auth';
// utils
import { verifyAccess } from '@utils/verifyAccess';
// redux
import { selectors } from '@redux/user/information';

export interface PrivateComponentProps {
  accessFor: AuthorizationComponentType | Array<AuthorizationComponentType>;
}

export const PrivateComponent: React.FC<PrivateComponentProps> = ({ accessFor, children }) => {
  const components = useSelector(selectors.authorizedComponents);

  if (verifyAccess(components, accessFor)) {
    return <>{children}</>;
  }

  return null;
};

export default PrivateComponent;
