import styled, { css } from 'styled-components';
import { StyledOption } from '@shared/view/molecules/Select/components';

const Option = styled(StyledOption.Option)`
  ${props =>
    props.highlight &&
    css`
      font-weight: 600;
    `};
`;

export default {
  Option,
};
