import { Dictionary, forEach } from 'lodash';
import { createSelector } from 'reselect';
import { GroupedOptionsType, GroupType } from 'react-select';
import createCachedSelector from 're-reselect';
import queryString from 'query-string';
// models
import { selectors as fetchSelectors } from '@optx/redux/feature/fetch';
import { DateRangeOption, SelectOption } from '@optx/models/Option';
import { FilterOptions, PreselectedFilter } from '@optx/models/filters';
import mapTags from '@optx/utils/filters/mapTags';
import { FilterColumns } from '@optx/models/feature/analystsLeaderboard/filters';
// utils
import mapFiltersToURLParams from '@utils/filters/mapFiltersToURLParams';
// Local
// constants
import { AnalystsLeaderboardFiltersState } from './interfaces';
import { tagsOmmitedFilters } from './constants';
import { lines } from '../../constants/statsChart';
import { pipelineCriteria } from '../../constants/custom-criteria';

export const selectNormalizedFilters = (state: AnalystsLeaderboardFiltersState) => state.byColumn;
export const selectSources = (state: AnalystsLeaderboardFiltersState) => state.data;
export const selectCommonValues = (state: AnalystsLeaderboardFiltersState) => state.commonValues;
export const selectCriteriaValues = (state: AnalystsLeaderboardFiltersState) =>
  state.criteriaValues;
export const selectOptions = (state: AnalystsLeaderboardFiltersState) => state.options;
export const selectDefaultValues = (state: AnalystsLeaderboardFiltersState) => state.defaultValues;
export const selectDefaultCriteriaValues = (state: AnalystsLeaderboardFiltersState) =>
  state.defaultCriteriaValues;
export const selectClearValues = (state: AnalystsLeaderboardFiltersState) => state.clearValues;

export const getSearchCriteria = createSelector(
  selectCommonValues,
  commonValues => commonValues.filter_criteria as string | undefined
);

/**
 * Select common and current criteria values.
 * This will ommit special criteria like pipeline_information.
 */
export const selectValues = createSelector(
  selectCommonValues,
  selectCriteriaValues,
  getSearchCriteria,
  (commonValues, valuesByCriteria, criteria) => {
    let values: Dictionary<PreselectedFilter> = {};

    if (!criteria) {
      return values;
    }

    const criteriaValues = valuesByCriteria[criteria];

    // Join common and current criteria values.
    values = {
      ...commonValues,
      ...criteriaValues,
    };

    return values;
  }
);

export const getAllValues = createSelector(
  selectCommonValues,
  selectCriteriaValues,
  (commonValues, valuesByCriteria) => {
    const allValues: Dictionary<PreselectedFilter> = {
      ...commonValues,
    };

    forEach(valuesByCriteria, (criteriaValues, criteria) => {
      forEach(criteriaValues, (value, filterColumn) => {
        allValues[filterColumn] = value;
      });
    });

    return allValues;
  }
);

export const isLoading = (state: AnalystsLeaderboardFiltersState) =>
  fetchSelectors.isLoadingSelector(state);

export const shouldFetch = (state: AnalystsLeaderboardFiltersState) =>
  fetchSelectors.shouldInitialFetchSelector(state);

export const getFilter = createCachedSelector(
  selectNormalizedFilters,
  (state: AnalystsLeaderboardFiltersState, filterKey: string) => filterKey,
  (filters, filterKey) => filters[filterKey] || null
)((state, filterKey) => filterKey);

// Multi select filters
export const getSelectOptions = createCachedSelector(
  selectOptions,
  (state: AnalystsLeaderboardFiltersState, filterKey: string) => filterKey,
  (filtersOptions, filterKey) => {
    const options = getFilterOptions(filterKey, filtersOptions) as
      | Array<SelectOption>
      | GroupedOptionsType<SelectOption>
      | undefined;

    return options || [];
  }
)((state, filterKey) => filterKey);

/**
 * Should analysts allow selection in analysts multiselect.
 * Special situation when the only option on analysts multiselect is the analyst itself.
 */
export const allowAnalystsEditing = createSelector(
  (state: AnalystsLeaderboardFiltersState) => getSelectOptions(state, 'analyst_id'),
  options => {
    return options.length !== 1 || !!(options[0] as GroupType<SelectOption>).options;
  }
);

export interface FilterSelectValueInformation {
  filterKey: string;
  criteria?: string;
}

export const getSelectValue = createCachedSelector(
  selectValues,
  selectCriteriaValues,
  (state: AnalystsLeaderboardFiltersState, selectInfo: FilterSelectValueInformation) => selectInfo,
  (values, criteriaValues, { filterKey, criteria }) => {
    if (criteria) {
      return (
        (criteriaValues[criteria] &&
          (criteriaValues[criteria][filterKey] as Array<SelectOption>)) ||
        []
      );
    }

    return (values[filterKey] as Array<SelectOption>) || [];
  }
)((state, { filterKey, criteria }) => `${filterKey}${criteria || ''}`);

export const getRadioValue = createCachedSelector(
  selectValues,
  selectCriteriaValues,
  (state: AnalystsLeaderboardFiltersState, selectInfo: FilterSelectValueInformation) => selectInfo,
  (values, criteriaValues, { filterKey, criteria }) => {
    if (criteria) {
      if (criteria) {
        return (criteriaValues[criteria] && (criteriaValues[criteria][filterKey] as string)) || '';
      }
    }

    return (values[filterKey] as string) || '';
  }
)((state, { filterKey, criteria }) => `${filterKey}${criteria || ''}`);

type DateRangeValue = [string | null, string | null];

export const getSingleDateRangesValue = createCachedSelector(
  selectValues,
  (state: AnalystsLeaderboardFiltersState, filterKey: string) => filterKey,
  (values, filterKey) => (values[filterKey] || [null, null]) as DateRangeValue
)((state, filterKey) => filterKey);

export const getSingleDateRangesOptions = createCachedSelector(
  selectOptions,
  (state: AnalystsLeaderboardFiltersState, filterKey: string) => filterKey,
  (filtersOptions, filterKey) => {
    const options = getFilterOptions(filterKey, filtersOptions) as
      | Array<DateRangeOption>
      | undefined;

    return options || [];
  }
)((state, filterKey) => filterKey);

function getFilterOptions(column: string, filtersOptions: Dictionary<FilterOptions | undefined>) {
  return filtersOptions[column];
}

export const isAllTimeDate = createSelector(
  (state: AnalystsLeaderboardFiltersState) => getCurrentDateRangeOption(state, 'startdatetime'),
  option => !!option && option.label?.toLowerCase() === 'all time'
);

export const getTags = createSelector(
  selectSources,
  selectValues,
  selectClearValues,
  selectCriteriaValues,
  isAllTimeDate,
  (filterSources, values, clearValues, criteriaValues, isAllTime) => {
    // Include special criteria values like pipeline_information.
    let pipelineValues = criteriaValues[pipelineCriteria];

    if (isAllTime) {
      pipelineValues = { ...pipelineValues, previous_period: clearValues.previous_period };
    }

    const finalValues: Dictionary<PreselectedFilter> = {
      ...values,
      ...pipelineValues,
    };

    const sourcesOptxUserIdLength = filterSources[0]?.data.find(
      item => item.column === 'analyst_id'
    )?.data.length;
    const filterOptxUserIdLength = (values.analyst_id as Array<SelectOption>)?.length;

    let tags;

    if (sourcesOptxUserIdLength === filterOptxUserIdLength) {
      tags = mapTags(
        { ...finalValues, analyst_id: undefined },
        filterSources,
        clearValues,
        tagsOmmitedFilters
      );
    } else {
      tags = mapTags(finalValues, filterSources, clearValues, tagsOmmitedFilters);
    }

    return tags;
  }
);

export const getDefaultTags = createSelector(
  selectSources,
  selectValues,
  selectDefaultValues,
  selectCriteriaValues,
  (filterSources, values, defaultValues, criteriaValues) => {
    // Include special criteria values like pipeline_information.
    const pipelineValues = criteriaValues[pipelineCriteria];
    const finalValues: Dictionary<PreselectedFilter> = {
      ...values,
      ...pipelineValues,
    };

    const tags = mapTags(finalValues, filterSources, defaultValues, tagsOmmitedFilters);

    return tags;
  }
);

export const showClearAll = createSelector(getDefaultTags, tags => {
  const tagNotDefault = tags.find(tag => {
    if (tag.isDefault === false && tag.values?.length > 0) {
      return true;
    }

    return false;
  });

  return !!tagNotDefault;
});

export const getCurrentDateRangeOption = createCachedSelector(
  (state: AnalystsLeaderboardFiltersState, filterKey: string) => filterKey,
  getSingleDateRangesOptions,
  getSingleDateRangesValue,
  (filterKey, options, value) => {
    const preselectedValue = options.find(
      option => option.start === value[0] && option.end === value[1]
    );

    if (preselectedValue) {
      return preselectedValue;
    }

    return null;
  }
)((state, filterKey) => filterKey);

export const getSelectedPeriodLabel = createSelector(
  (state: AnalystsLeaderboardFiltersState) => getCurrentDateRangeOption(state, 'startdatetime'),
  option => {
    return option ? (option.label as string) : 'Selected Period';
  }
);

function getNextPeriodLabel(label: string) {
  return label.replace('Last ', 'Previous ');
}

export const getChartLines = createCachedSelector(
  (state: AnalystsLeaderboardFiltersState, filterKey: FilterColumns) => filterKey,
  getCurrentDateRangeOption,
  isAllTimeDate,
  (key, option, isAllTime) => {
    const currentLabel = option ? (option.label as string) : '';
    const finalLines = isAllTime ? lines.filter(line => line.dataKey === 'selectedPeriod') : lines;

    return finalLines
      .map((line, index) => {
        if (line.dataKey === 'selectedPeriod') {
          return {
            ...line,
            name: currentLabel || 'SELECTED PERIOD',
          };
        }

        return {
          ...line,
          name: getNextPeriodLabel(currentLabel) || 'PREVIOUS PERIOD',
        };
      })
      .reverse(); // reverse lines  to have current/last period first.
  }
)((state, filterKey) => filterKey);

/**
 * Check if current filter is a common filter (top filter).
 */
export const isCommonFilter = createCachedSelector(
  selectCommonValues,
  (state: AnalystsLeaderboardFiltersState, filterKey: string) => filterKey,
  (commonValues, filterKey) => {
    return Object.prototype.hasOwnProperty.call(commonValues, filterKey);
  }
)((state, filterKey) => filterKey);

export const getCommonFiltersQuery = createSelector(
  selectCommonValues,
  selectNormalizedFilters,
  (commonValues, filtersByColumn) => {
    const queryValues = mapFiltersToURLParams(filtersByColumn, commonValues);
    const query = queryString.stringify(queryValues, { arrayFormat: 'comma' });

    return query;
  }
);

export const getValuesByCriteria = createCachedSelector(
  selectCriteriaValues,
  (state: AnalystsLeaderboardFiltersState, criteria: string) => criteria,
  (criteriaValues, criteria) => {
    return criteriaValues[criteria];
  }
)((state, criteria) => criteria);
