import styled from 'styled-components';
import { Card } from 'antd';

const DoubleChartWrapper = styled(Card)`
  height: 100%;
  .profile-chart-card {
    &__no-data {
      background: rgb(240, 240, 240);
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(240, 240, 240, 1) 100%);
    }

    &__container {
      position: relative;
      width: calc(100% + 35px);

      transform: translateX(-50px);
      height: 135px;

      &.has-legend {
        .chart-empty-labels {
          height: calc(100% - 80px);
        }
      }
    }

    .ant-card-head-title {
      font-size: 20px;
    }

    .ant-card-extra {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .recharts-wrapper {
      font-size: 10px;
    }

    .recharts-yAxis {
      font-size: 12px;
    }

    .recharts-xAxis {
      text-transform: uppercase;
    }

    .kpi__value {
      line-height: 1.6em;
    }

    // styling for empty state
    &__empty {
      position: absolute;
      top: 0;
      left: 24px;
      transform: translateX(58px);
      width: calc(100% - 65px);
      height: calc(100% - 35px);
      display: flex;
      align-items: center;
      justify-content: center;

      .gray-rectangle-button {
        cursor: default;
      }
    }

    .chart-empty-labels {
      background: linear-gradient(180deg, #f0f0f0 -11.27%, rgba(196, 196, 196, 0) 100%);
      height: calc(100% - 35px);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      border-right: 1px solid $blue-6;
    }

    // tooltip
    &__tooltip {
      height: 100%;
      padding: 5px 8px;
      font-size: 12px;
      background: $gray-9;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      border-radius: 2px;
      color: #fff;
    }
  }

  @include media-breakpoint-down(md) {
    .profile-chart-card {
      .kpi {
        margin-left: 10px;
      }

      .kpi__label {
        max-width: 50px;
        line-height: normal;
      }
    }
  }
`;

export const Styles = {
  DoubleChartWrapper,
};
