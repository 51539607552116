/* eslint-disable max-len */
import { Dictionary } from 'lodash';
// models
import { ViewOption, SearchSave } from '@optx/models/search';
import { CustomUIViewIds } from '@optx/models/user';
import { CompanyColumnDisplay, ColumnKeys } from '@optx/models/table/Columns';
// constants
import { COMPANY_DEFAULT_SORT } from '@constants/table/sort/defaultSort';
import {
  YEARS_LABELS,
  generateArrCurrentColumnKey,
  generateEbitdaCurrentColumnKey,
  generateGrowthCurrentColumnKey,
} from '@optx/constants/search';
// utils
import { getDefaultVisibleColumns } from '@optx/utils/columnsDefaultVisible';

export const columnDisplayInitialState: CompanyColumnDisplay<ColumnKeys> = {
  company_url: {
    id: 'company_url',
    name: 'Company URL',
    visible: true,
    pinned: true,
    width: 42,
  },
  company_name: {
    id: 'company_name',
    name: 'Company Name',
    visible: true,
    pinned: true,
    width: 240,
  },
  score: {
    id: 'score',
    name: 'OPTX Score',
    visible: true,
    pinned: true,
    width: 120,
  },
  il_optx_score: {
    id: 'il_optx_score',
    name: 'IL OPTX Score',
    visible: true,
    pinned: true,
    width: 120,
  },
  parentcompany: {
    id: 'parentcompany',
    name: 'Parent Company',
    visible: false,
    pinned: false,
    width: 240,
  },
  is_in_et: {
    id: 'is_in_et',
    name: 'In Equity Touch',
    visible: false,
    pinned: false,
    width: 85,
  },
  company_description: {
    id: 'company_description',
    name: 'Description',
    visible: false,
    pinned: false,
    width: 520,
  },
  year_founded: {
    id: 'year_founded',
    name: 'Year Founded',
    visible: true,
    pinned: false,
    width: 90,
  },
  is_software: {
    id: 'is_software',
    name: 'Software',
    visible: true,
    pinned: false,
    width: 85,
  },
  is_interesting: {
    id: 'is_interesting',
    name: 'PSG Fit',
    visible: true,
    pinned: false,
    width: 110,
  },
  diversity: {
    id: 'diversity',
    name: 'Diversity Spotlight',
    visible: false,
    pinned: false,
    width: 200,
  },
  addon_bool: {
    id: 'addon_bool',
    name: 'Show Add-Ons',
    visible: false,
    pinned: false,
    width: 150,
  },
  addon: {
    id: 'addon',
    name: 'Add-On for a Specific Company',
    visible: true,
    pinned: false,
    width: 230,
  },
  date_presented: {
    id: 'date_presented',
    name: 'Date Presented',
    visible: false,
    pinned: false,
    width: 200,
  },
  company_owner: {
    id: 'company_owner',
    name: 'Company Owner',
    visible: true,
    pinned: false,
    width: 160,
  },
  senior_advisor_thesis: {
    id: 'senior_advisor_thesis',
    name: 'Senior Advisor Thesis',
    visible: false,
    pinned: false,
    width: 160,
  },
  lead_source: {
    id: 'lead_source',
    name: 'Lead Source',
    visible: false,
    pinned: false,
    width: 160,
  },
  pipeline_rank: {
    id: 'pipeline_rank',
    name: 'Pipeline Rank',
    visible: true,
    pinned: false,
    width: 85,
  },
  stage: {
    id: 'stage',
    name: 'Stage',
    visible: true,
    pinned: false,
    width: 110,
  },
  fund: {
    id: 'fund',
    name: 'Fund',
    visible: false,
    pinned: false,
    width: 110,
  },
  num_employees: {
    id: 'num_employees',
    name: 'Number of Employees',
    visible: true,
    pinned: false,
    width: 120,
  },
  emp_growth_percent: {
    id: 'emp_growth_percent',
    name: 'Employee Growth Percentage Y/Y',
    visible: true,
    pinned: false,
    width: 150,
  },
  job_openings: {
    id: 'job_openings',
    name: 'Job Openings',
    visible: true,
    pinned: false,
    width: 90,
  },
  job_openings_percent: {
    id: 'job_openings_percent',
    name: 'Job Openings %',
    visible: true,
    pinned: false,
    width: 110,
  },
  sw_website_rank: {
    id: 'sw_website_rank',
    name: 'WW Website Rank',
    visible: true,
    pinned: false,
    width: 140,
  },
  sw_website_rank_per_country: {
    id: 'sw_website_rank_per_country',
    name: 'US Website Rank',
    visible: true,
    pinned: false,
    width: 110,
  },
  opportunity_type: {
    id: 'opportunity_type',
    name: 'Opportunity Type',
    visible: false,
    pinned: false,
    width: 110,
  },
  company_type: {
    id: 'company_type',
    name: 'Company Type',
    visible: false,
    pinned: false,
    width: 110,
  },
  sector: {
    id: 'sector',
    name: 'Sector',
    visible: false,
    pinned: false,
    width: 100,
  },
  sub_sector: {
    id: 'sub_sector',
    name: 'Sub-sector',
    visible: false,
    pinned: false,
    width: 100,
  },
  product_category: {
    id: 'product_category',
    name: 'Product Category',
    visible: false,
    pinned: false,
    width: 100,
  },
  sub_vertical: {
    id: 'sub_vertical',
    name: 'Sub Vertical',
    visible: false,
    pinned: false,
    width: 100,
  },
  raise_date: {
    id: 'raise_date',
    name: 'Last Funding',
    visible: true,
    pinned: false,
    width: 120,
  },
  last_round: {
    id: 'last_round',
    name: 'Last Round',
    visible: false,
    pinned: false,
    width: 110,
  },
  last_raised_amount: {
    id: 'last_raised_amount',
    name: 'Last Raised Amount',
    visible: true,
    pinned: false,
    width: 110,
  },
  last_investors: {
    id: 'last_investors',
    name: 'Investors',
    visible: false,
    pinned: false,
    width: 120,
  },
  capital_raised: {
    id: 'capital_raised',
    name: 'Total Raised',
    visible: true,
    pinned: false,
    width: 210,
  },
  last_arr_value: {
    id: 'last_arr_value',
    name: 'ARR',
    visible: false,
    pinned: false,
    width: 140,
  },
  [generateArrCurrentColumnKey]: {
    id: `${generateArrCurrentColumnKey}`,
    name: `${YEARS_LABELS.current} ARR`,
    visible: false,
    pinned: false,
    width: 140,
  },
  last_rev_growth: {
    id: 'last_rev_growth',
    name: 'Growth %',
    visible: false,
    pinned: false,
    width: 100,
  },
  [generateGrowthCurrentColumnKey]: {
    id: `${generateGrowthCurrentColumnKey}`,
    name: `${YEARS_LABELS.current} Growth %`,
    visible: false,
    pinned: false,
    width: 150,
  },
  last_rev_update_amount: {
    id: 'last_rev_update_amount',
    name: 'Revenue',
    visible: true,
    pinned: false,
    width: 130,
  },
  last_gross_margin: {
    id: 'last_gross_margin',
    name: 'GM %',
    visible: false,
    pinned: false,
    width: 100,
  },
  ebitda_amount: {
    id: 'ebitda_amount',
    name: 'EBITDA',
    visible: false,
    pinned: false,
    width: 130,
  },
  deal_type: {
    id: 'deal_type',
    name: 'Deal Type',
    visible: false,
    pinned: false,
    width: 120,
  },
  [generateEbitdaCurrentColumnKey]: {
    id: `${generateEbitdaCurrentColumnKey}`,
    name: `${YEARS_LABELS.current} EBITDA`,
    visible: false,
    pinned: false,
    width: 130,
  },
  last_valuation: {
    id: 'last_valuation',
    name: 'Valuation',
    visible: true,
    pinned: false,
    width: 110,
  },
  last_touch_date: {
    id: 'last_touch_date',
    name: 'Last Touch',
    visible: true,
    pinned: false,
    width: 110,
  },
  next_touch_initiator_name: {
    id: 'next_touch_initiator_name',
    name: 'Scheduled Touch Initiated By',
    visible: false,
    pinned: false,
    width: 160,
  },
  last_touch_initiator_name: {
    id: 'last_touch_initiator_name',
    name: 'Last Touch Initiaded By',
    visible: false,
    pinned: false,
    width: 160,
  },
  next_touch_date: {
    id: 'next_touch_date',
    name: 'Scheduled Touch',
    visible: true,
    pinned: false,
    width: 120,
  },
  current_ceo: {
    id: 'current_ceo',
    name: 'CEO',
    visible: false,
    pinned: false,
    width: 90,
  },
  ceo_approval: {
    id: 'ceo_approval',
    name: 'CEO Approval',
    visible: false,
    pinned: false,
    width: 90,
  },
  g2_rating: {
    id: 'g2_rating',
    name: 'G2Crowd Rating',
    visible: false,
    pinned: false,
    width: 90,
  },
  g2_review_count: {
    id: 'g2_review_count',
    name: 'G2Crowd Reviews',
    visible: false,
    pinned: false,
    width: 90,
  },
  ct_rating: {
    id: 'ct_rating',
    name: 'Capterra Rating',
    visible: false,
    pinned: false,
    width: 90,
  },
  ct_review_count: {
    id: 'ct_review_count',
    name: 'Capterra Reviews',
    visible: false,
    pinned: false,
    width: 90,
  },
  cb_rank: {
    id: 'cb_rank',
    name: 'Crunchbase Rank',
    visible: true,
    pinned: false,
    width: 110,
  },
  city: {
    id: 'city',
    name: 'Town/City',
    visible: true,
    pinned: false,
    width: 100,
  },
  state: {
    id: 'state',
    name: 'State/Province',
    visible: true,
    pinned: false,
    width: 129,
  },
  country: {
    id: 'country',
    name: 'Country',
    visible: true,
    pinned: false,
    width: 100,
  },
  source_tag: {
    id: 'source_tag',
    name: 'Source',
    visible: true,
    pinned: false,
    width: 180,
  },
  linkedin: {
    id: 'linkedin',
    name: 'Linkedin Profile',
    visible: false,
    pinned: false,
    width: 90,
  },
  is_in_business: {
    id: 'is_in_business',
    name: 'In Business',
    visible: false,
    pinned: false,
    width: 90,
  },
  active: {
    id: 'active',
    name: 'Active on Pipeline',
    visible: false,
    pinned: false,
    width: 90,
  },
  url_status: {
    id: 'url_status',
    name: 'Website',
    visible: false,
    pinned: false,
    width: 110,
  },
  next_steps: {
    id: 'next_steps',
    name: 'Next Steps',
    visible: false,
    pinned: false,
    width: 110,
  },
  created_on_optx: {
    id: 'created_on_optx',
    name: 'Date Created',
    visible: false,
    pinned: false,
    width: 120,
  },
  deal_team: {
    id: 'deal_team',
    name: 'Deal Team',
    visible: false,
    pinned: false,
    width: 150,
  },
  senior_deal_team_lead: {
    id: 'senior_deal_team_lead',
    name: 'Senior Deal Team Lead',
    visible: false,
    pinned: false,
    width: 150,
  },
  asking_amount: {
    id: 'asking_amount',
    name: 'Equity Check (asking amount)',
    visible: false,
    pinned: false,
    width: 200,
  },
  prev_stage: {
    id: 'prev_stage',
    name: 'Previous Stage',
    visible: false,
    pinned: false,
    width: 110,
  },
  is_ai_ml: {
    id: 'is_ai_ml',
    name: 'AI / ML',
    visible: false,
    pinned: false,
    width: 110,
  },
};

export const columnDisplayInitialStateSourcingOutReachGrid: CompanyColumnDisplay<ColumnKeys> = {
  company_url: {
    id: 'company_url',
    name: 'Company URL',
    visible: true,
    pinned: true,
    width: 42,
  },
  company_name: {
    id: 'company_name',
    name: 'Company Name',
    visible: true,
    pinned: true,
    width: 240,
  },
  score: {
    id: 'score',
    name: 'OPTX Score',
    visible: true,
    pinned: false,
    width: 120,
  },
  il_optx_score: {
    id: 'il_optx_score',
    name: 'IL OPTX Score',
    visible: true,
    pinned: false,
    width: 120,
  },
  parentcompany: {
    id: 'parentcompany',
    name: 'Parent Company',
    visible: false,
    pinned: false,
    width: 240,
  },
  is_in_et: {
    id: 'is_in_et',
    name: 'In Equity Touch',
    visible: true,
    pinned: false,
    width: 85,
  },
  company_description: {
    id: 'company_description',
    name: 'Description',
    visible: false,
    pinned: false,
    width: 520,
  },
  year_founded: {
    id: 'year_founded',
    name: 'Year Founded',
    visible: true,
    pinned: false,
    width: 90,
  },
  is_software: {
    id: 'is_software',
    name: 'Software',
    visible: true,
    pinned: false,
    width: 85,
  },
  is_interesting: {
    id: 'is_interesting',
    name: 'PSG Fit',
    visible: true,
    pinned: false,
    width: 110,
  },
  diversity: {
    id: 'diversity',
    name: 'Diversity Spotlight',
    visible: false,
    pinned: false,
    width: 200,
  },
  addon_bool: {
    id: 'addon_bool',
    name: 'Show Add-ons',
    visible: false,
    pinned: false,
    width: 150,
  },
  addon: {
    id: 'addon',
    name: 'Add-On for a Specific Company',
    visible: true,
    pinned: false,
    width: 230,
  },
  date_presented: {
    id: 'date_presented',
    name: 'Date Presented',
    visible: false,
    pinned: false,
    width: 200,
  },
  company_owner: {
    id: 'company_owner',
    name: 'Company Owner',
    visible: true,
    pinned: false,
    width: 160,
  },
  senior_advisor_thesis: {
    id: 'senior_advisor_thesis',
    name: 'Senior Advisor Thesis',
    visible: false,
    pinned: false,
    width: 160,
  },
  lead_source: {
    id: 'lead_source',
    name: 'Lead Source',
    visible: false,
    pinned: false,
    width: 160,
  },
  pipeline_rank: {
    id: 'pipeline_rank',
    name: 'Pipeline Rank',
    visible: true,
    pinned: false,
    width: 85,
  },
  stage: {
    id: 'stage',
    name: 'Stage',
    visible: true,
    pinned: false,
    width: 110,
  },
  fund: {
    id: 'fund',
    name: 'Fund',
    visible: false,
    pinned: false,
    width: 110,
  },
  num_employees: {
    id: 'num_employees',
    name: 'Number of Employees',
    visible: true,
    pinned: false,
    width: 120,
  },
  emp_growth_percent: {
    id: 'emp_growth_percent',
    name: 'Employee Growth Percentage Y/Y',
    visible: true,
    pinned: false,
    width: 150,
  },
  job_openings: {
    id: 'job_openings',
    name: 'Job Openings',
    visible: true,
    pinned: false,
    width: 90,
  },
  job_openings_percent: {
    id: 'job_openings_percent',
    name: 'Job Openings %',
    visible: true,
    pinned: false,
    width: 110,
  },
  sw_website_rank: {
    id: 'sw_website_rank',
    name: 'WW Website Rank',
    visible: true,
    pinned: false,
    width: 140,
  },
  sw_website_rank_per_country: {
    id: 'sw_website_rank_per_country',
    name: 'US Website Rank',
    visible: true,
    pinned: false,
    width: 110,
  },
  opportunity_type: {
    id: 'opportunity_type',
    name: 'Opportunity Type',
    visible: false,
    pinned: false,
    width: 110,
  },
  company_type: {
    id: 'company_type',
    name: 'Company Type',
    visible: false,
    pinned: false,
    width: 110,
  },
  sector: {
    id: 'sector',
    name: 'Sector',
    visible: false,
    pinned: false,
    width: 100,
  },
  sub_sector: {
    id: 'sub_sector',
    name: 'Sub-sector',
    visible: false,
    pinned: false,
    width: 100,
  },
  product_category: {
    id: 'product_category',
    name: 'Product Category',
    visible: false,
    pinned: false,
    width: 100,
  },
  sub_vertical: {
    id: 'sub_vertical',
    name: 'Sub Vertical',
    visible: false,
    pinned: false,
    width: 100,
  },
  raise_date: {
    id: 'raise_date',
    name: 'Last Funding',
    visible: true,
    pinned: false,
    width: 120,
  },
  last_round: {
    id: 'last_round',
    name: 'Last Round',
    visible: false,
    pinned: false,
    width: 110,
  },
  last_raised_amount: {
    id: 'last_raised_amount',
    name: 'Last Raised Amount',
    visible: true,
    pinned: false,
    width: 110,
  },
  last_investors: {
    id: 'last_investors',
    name: 'Investors',
    visible: false,
    pinned: false,
    width: 120,
  },
  capital_raised: {
    id: 'capital_raised',
    name: 'Total Raised',
    visible: true,
    pinned: false,
    width: 180,
  },
  last_arr_value: {
    id: 'last_arr_value',
    name: 'ARR',
    visible: false,
    pinned: false,
    width: 140,
  },
  [generateArrCurrentColumnKey]: {
    id: `${generateArrCurrentColumnKey}`,
    name: `${YEARS_LABELS.current} ARR`,
    visible: false,
    pinned: false,
    width: 140,
  },
  last_rev_growth: {
    id: 'last_rev_growth',
    name: 'Growth %',
    visible: false,
    pinned: false,
    width: 100,
  },
  [generateGrowthCurrentColumnKey]: {
    id: `${generateGrowthCurrentColumnKey}`,
    name: `${YEARS_LABELS.current} Growth %`,
    visible: false,
    pinned: false,
    width: 150,
  },
  last_rev_update_amount: {
    id: 'last_rev_update_amount',
    name: 'Revenue',
    visible: true,
    pinned: false,
    width: 100,
  },
  last_gross_margin: {
    id: 'last_gross_margin',
    name: 'GM %',
    visible: false,
    pinned: false,
    width: 100,
  },
  ebitda_amount: {
    id: 'ebitda_amount',
    name: 'EBITDA',
    visible: false,
    pinned: false,
    width: 100,
  },
  deal_type: {
    id: 'deal_type',
    name: 'Deal Type',
    visible: false,
    pinned: false,
    width: 120,
  },
  [generateEbitdaCurrentColumnKey]: {
    id: `${generateEbitdaCurrentColumnKey}`,
    name: `${YEARS_LABELS.current} EBITDA`,
    visible: false,
    pinned: false,
    width: 130,
  },
  last_valuation: {
    id: 'last_valuation',
    name: 'Valuation',
    visible: true,
    pinned: false,
    width: 110,
  },
  last_touch_date: {
    id: 'last_touch_date',
    name: 'Last Touch',
    visible: true,
    pinned: false,
    width: 110,
  },
  next_touch_initiator_name: {
    id: 'next_touch_initiator_name',
    name: 'Future Touch Initiated By',
    visible: false,
    pinned: false,
    width: 160,
  },
  last_touch_initiator_name: {
    id: 'last_touch_initiator_name',
    name: 'Last Touch Initiaded By',
    visible: false,
    pinned: false,
    width: 160,
  },
  next_touch_date: {
    id: 'next_touch_date',
    name: 'Scheduled Touch',
    visible: true,
    pinned: false,
    width: 120,
  },
  current_ceo: {
    id: 'current_ceo',
    name: 'CEO',
    visible: false,
    pinned: false,
    width: 90,
  },
  ceo_approval: {
    id: 'ceo_approval',
    name: 'CEO Approval',
    visible: false,
    pinned: false,
    width: 90,
  },
  g2_rating: {
    id: 'g2_rating',
    name: 'G2Crowd Rating',
    visible: false,
    pinned: false,
    width: 90,
  },
  g2_review_count: {
    id: 'g2_review_count',
    name: 'G2Crowd Reviews',
    visible: false,
    pinned: false,
    width: 90,
  },
  ct_rating: {
    id: 'ct_rating',
    name: 'Capterra Rating',
    visible: false,
    pinned: false,
    width: 90,
  },
  ct_review_count: {
    id: 'ct_review_count',
    name: 'Capterra Reviews',
    visible: false,
    pinned: false,
    width: 90,
  },
  cb_rank: {
    id: 'cb_rank',
    name: 'Crunchbase Rank',
    visible: true,
    pinned: false,
    width: 110,
  },
  city: {
    id: 'city',
    name: 'Town/City',
    visible: true,
    pinned: false,
    width: 100,
  },
  state: {
    id: 'state',
    name: 'State/Province',
    visible: true,
    pinned: false,
    width: 120,
  },
  country: {
    id: 'country',
    name: 'Country',
    visible: true,
    pinned: false,
    width: 100,
  },
  source_tag: {
    id: 'source_tag',
    name: 'Source',
    visible: true,
    pinned: false,
    width: 180,
  },
  linkedin: {
    id: 'linkedin',
    name: 'Linkedin Profile',
    visible: false,
    pinned: false,
    width: 90,
  },
  is_in_business: {
    id: 'is_in_business',
    name: 'In Business',
    visible: false,
    pinned: false,
    width: 90,
  },
  active: {
    id: 'active',
    name: 'Active on Pipeline',
    visible: false,
    pinned: false,
    width: 90,
  },
  url_status: {
    id: 'url_status',
    name: 'Website',
    visible: false,
    pinned: false,
    width: 110,
  },
  next_steps: {
    id: 'next_steps',
    name: 'Next Steps',
    visible: false,
    pinned: false,
    width: 110,
  },
  created_on_optx: {
    id: 'created_on_optx',
    name: 'Date Created',
    visible: false,
    pinned: false,
    width: 120,
  },
  deal_team: {
    id: 'deal_team',
    name: 'Deal Team',
    visible: false,
    pinned: false,
    width: 150,
  },
  senior_deal_team_lead: {
    id: 'senior_deal_team_lead',
    name: 'Senior Deal Team Lead',
    visible: false,
    pinned: false,
    width: 150,
  },
  asking_amount: {
    id: 'asking_amount',
    name: 'Equity Check (asking amount)',
    visible: false,
    pinned: false,
    width: 200,
  },
  prev_stage: {
    id: 'prev_stage',
    name: 'Previous Stage',
    visible: false,
    pinned: false,
    width: 110,
  },
  is_ai_ml: {
    id: 'is_ai_ml',
    name: 'AI / ML',
    visible: false,
    pinned: false,
    width: 110,
  },
};

export const initialColumnOrder: ColumnKeys[] = [
  'company_url',
  'company_name',
  'score',
  'il_optx_score',
  'parentcompany',
  'is_in_et',
  'company_description',
  'year_founded',
  'is_software',
  'is_interesting',
  'diversity',
  'addon_bool',
  'addon',
  'date_presented',
  'company_owner',
  'senior_advisor_thesis',
  'lead_source',
  'pipeline_rank',
  'stage',
  'fund',
  'num_employees',
  'emp_growth_percent',
  'job_openings',
  'job_openings_percent',
  'sw_website_rank',
  'sw_website_rank_per_country',
  'opportunity_type',
  'company_type',
  'sector',
  'sub_sector',
  'product_category',
  'sub_vertical',
  'raise_date',
  'last_round',
  'last_raised_amount',
  'last_investors',
  'capital_raised',
  'last_arr_value',
  `${generateArrCurrentColumnKey}`,
  'last_rev_growth',
  `${generateGrowthCurrentColumnKey}`,
  'last_rev_update_amount',
  'last_gross_margin',
  'ebitda_amount',
  `${generateEbitdaCurrentColumnKey}`,
  'last_valuation',
  'last_touch_date',
  'next_touch_date',
  'next_touch_initiator_name',
  'last_touch_initiator_name',
  'current_ceo',
  'ceo_approval',
  'g2_rating',
  'g2_review_count',
  'ct_rating',
  'ct_review_count',
  'cb_rank',
  'city',
  'state',
  'country',
  'source_tag',
  'linkedin',
  'is_in_business',
  'active',
  'url_status',
  'created_on_optx',
  'asking_amount',
  'deal_type',
  'next_steps',
  'senior_deal_team_lead',
  'deal_team',
  'prev_stage',
  'is_ai_ml',
] as ColumnKeys[];

export const initialColumnOrderSourcingOutReachGrid: ColumnKeys[] = [
  'company_url',
  'company_name',
  'is_in_et',
  'company_owner',
  'stage',
  'last_touch_date',
  'next_touch_date',
  'num_employees',
  'emp_growth_percent',
  'capital_raised',
  'city',
  'state',
  'country',
  'score',
  'il_optx_score',
  'parentcompany',
  'company_description',
  'year_founded',
  'is_software',
  'is_interesting',
  'diversity',
  'addon_bool',
  'addon',
  'date_presented',
  'senior_advisor_thesis',
  'lead_source',
  'pipeline_rank',
  'fund',
  'job_openings',
  'job_openings_percent',
  'sw_website_rank',
  'sw_website_rank_per_country',
  'opportunity_type',
  'company_type',
  'sector',
  'sub_sector',
  'product_category',
  'sub_vertical',
  'raise_date',
  'last_round',
  'last_raised_amount',
  'last_arr_value',
  `${generateArrCurrentColumnKey}`,
  'last_rev_growth',
  'last_investors',
  'last_arr_value',
  'last_rev_growth',
  `${generateGrowthCurrentColumnKey}`,
  'last_rev_update_amount',
  'last_gross_margin',
  'ebitda_amount',
  `${generateEbitdaCurrentColumnKey}`,
  'last_valuation',
  'next_touch_initiator_name',
  'last_touch_initiator_name',
  'current_ceo',
  'ceo_approval',
  'g2_rating',
  'g2_review_count',
  'ct_rating',
  'ct_review_count',
  'cb_rank',
  'source_tag',
  'linkedin',
  'is_in_business',
  'active',
  'url_status',
  'asking_amount',
  'deal_type',
  'next_steps',
  'senior_deal_team_lead',
  'deal_team',
  'prev_stage',
  'is_ai_ml',
] as ColumnKeys[];

// if only a few columns are made visible and their total width is
// smaller than the total available width of the table, then choose one
// of the columns and increase it's width, to keep the rest of the
// columns' widths from proportionally increasing. this array serves as a priority list
// for finding the right column to resize
export const changeColumnWidthPriority: ColumnKeys[] = [
  'company_name',
  'company_description',
  'parentcompany',
  'diversity',
  'company_owner',
  'next_touch_initiator_name',
  'last_touch_initiator_name',
  'current_ceo',
  'senior_advisor_thesis',
  'lead_source',
  'source_tag',
  'addon_bool',
  'addon',
  'date_presented',
  'product_category',
  'sector',
  'sub_sector',
  'num_employees',
  'stage',
  'opportunity_type',
  'company_type',
  'score',
  'il_optx_score',
  'city',
  'state',
  'country',
  'raise_date',
  'sub_vertical',
  'last_round',
  'last_raised_amount',
  'last_investors',
  'ebitda_amount',
  'last_arr_value',
  'capital_raised',
  'last_rev_growth',
  'last_rev_update_amount',
  'last_gross_margin',
  'deal_type',
  'last_valuation',
  'last_touch_date',
  'next_touch_date',
  'sw_website_rank',
  'sw_website_rank_per_country',
  'emp_growth_percent',
  'job_openings',
  'job_openings_percent',
  'ceo_approval',
  'year_founded',
  'g2_rating',
  'g2_review_count',
  'ct_rating',
  'ct_review_count',
  'cb_rank',
  'pipeline_rank',
  'is_interesting',
  'is_in_et',
  'is_software',
  'linkedin',
  'company_url',
  'is_in_business',
  'active',
  'url_status',
  'next_steps',
  'created_on_optx',
  'asking_amount',
  'prev_stage',
  'is_ai_ml',
];

export const defaultOptxView: ViewOption = {
  columns: getDefaultVisibleColumns(columnDisplayInitialState),
  column_order: initialColumnOrder.join(),
  pinned_columns: 'company_url, company_name, score',
  count: 0,
  created_at: '',
  is_default: false,
  filters: {},
  sortBy: COMPANY_DEFAULT_SORT,
  search_criteria: 'sort_fields=company_name&sort_orders=asc',
  title: 'OPTX Default',
  unique_id: 'default',
  updated_at: null,
  search_info: {
    filters: [],
    keyword: '',
    type: '',
  },
};

// use this object to add custom hardcoded views
// the only property that can be changed is "is_default"
export const customUIViews: Dictionary<SearchSave> = {
  sourcingOrder: {
    columns: `company_url,company_name,is_in_et,company_owner,stage,last_touch_date,next_touch_date,num_employees,emp_growth_percent,capital_raised,last_arr_value,${generateArrCurrentColumnKey},city,state,country,score,year_founded,is_software,is_interesting,addon,pipeline_rank,job_openings,job_openings_percent,sw_website_rank,sw_website_rank_per_country,raise_date,last_raised_amount,last_rev_growth,${generateGrowthCurrentColumnKey},last_rev_update_amount,last_valuation,cb_rank,source_tag,is_ai_ml`,
    column_order: `company_url,company_name,is_in_et,company_owner,stage,last_touch_date,next_touch_date,num_employees,emp_growth_percent,capital_raised,last_arr_value,${generateArrCurrentColumnKey},city,state,country,score,il_optx_score,parentcompany,company_description,year_founded,is_software,is_interesting,diversity,addon_bool,addon,date_presented,senior_advisor_thesis,lead_source,pipeline_rank,fund,job_openings,job_openings_percent,sw_website_rank,sw_website_rank_per_country,opportunity_type,company_type,sector,sub_sector,product_category,sub_vertical,raise_date,last_round,last_raised_amount,last_rev_growth,${generateGrowthCurrentColumnKey},last_rev_update_amount,last_gross_margin,ebitda_amount,${generateEbitdaCurrentColumnKey},last_valuation,next_touch_initiator_name,last_touch_initiator_name,current_ceo,ceo_approval,g2_rating,g2_review_count,ct_rating,ct_review_count,cb_rank,source_tag,linkedin,is_in_business,active,url_status,created_on_optx,asking_amount,deal_type,next_steps,senior_deal_team_lead,deal_team,prev_stage,is_ai_ml`,
    pinned_columns: 'company_url,company_name',
    count: 0,
    created_at: '',
    is_default: false,
    filters: {},
    search_criteria: '',
    title: 'Sourcing Order',
    unique_id: 'sourcingOrder',
    updated_at: null,
    search_info: {
      filters: [],
      keyword: '',
      type: '',
    },
  },
};

// list of custom hardcoded view ids
export const customUIViewIds: CustomUIViewIds[] = ['sourcingOrder'];
