import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const LegendWrapper = styled.div`
  background: #f9f0ff;
  border: 1px solid #722ed1;
  width: 75px;
  height: 20px;
  padding: 0 5px;
  display: flex;
  align-items: center;
`;

const Legend = styled.span`
  border: 1px dashed #722ed1;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
`;

const LegendText = styled.div`
  color: #595959;
  font-size: 16px;
  line-height: 24px;
  margin-left: 10px;
`;

const Styles = {
  Wrapper,
  LegendWrapper,
  Legend,
  LegendText,
};

export default Styles;
