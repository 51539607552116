import { createFeatureAction } from '@utils/feature';
import featureName from '../../featureName';
import * as actionTypes from './actionTypes';
import { EditSeniorDealTeamLead } from './interfaces';

export const addSeniorDealTeamLead = createFeatureAction<EditSeniorDealTeamLead>(
  featureName,
  actionTypes.ADD_SENIOR_DEAL_TEAM_LEAD
);

export const addSeniorDealTeamLeadSuccess = createFeatureAction<EditSeniorDealTeamLead>(
  featureName,
  actionTypes.ADD_SENIOR_DEAL_TEAM_LEAD_SUCCESS
);

export const addSeniorDealTeamLeadFail = createFeatureAction<string>(
  featureName,
  actionTypes.ADD_SENIOR_DEAL_TEAM_LEAD_FAIL
);
