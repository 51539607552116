import React, { useEffect, useState } from 'react';
import { Col, Typography, Input, Button, Form, Tooltip } from 'antd';
import { useField } from 'formik';
// models
import { BaseField } from '@optx/models/companyFields';
import { FieldUsedFor } from './interface';
// constants
import { numberPercentageRegex } from '@constants/regex';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import EditPopover from '@optx/components/common/popover/EditPopover';

interface InputNumberPercentageFieldProps {
  field?: BaseField;
  value?: string;
  fieldName?: string;
  fieldType?: string;
  isChromePlugin?: boolean;
  onSave?: (fieldType?: string) => void;
  onCancel?: () => void;
  fieldUsedFor?: FieldUsedFor;
}

const InputNumberPercentageField: React.FC<InputNumberPercentageFieldProps> = ({
  field,
  value,
  fieldName,
  fieldType,
  onSave,
  isChromePlugin,
  onCancel,
  fieldUsedFor,
}) => {
  const [, , setSelectedField] = useField<number>(field?.id || fieldType || '');
  const [selected, setSelected] = useState<string>('');
  const [closePopup, setClosePopup] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;

    if (numberPercentageRegex.test(inputValue) || inputValue === '') {
      setSelected(inputValue);
      setSelectedField.setValue(parseInt(inputValue.replace('%', '')));
    }
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setClosePopup(true);

    if (value) {
      setTimeout(() => {
        setSelectedField.setValue(Number(value));
      }, 1);
    }
  };

  useEffect(() => {
    if ((field?.value || field?.value === 0) && fieldUsedFor !== 'editAllInfo') {
      setSelectedField.setValue(field.value);
      setSelected(field.value);
    }

    if (value) {
      setSelected(value);
      setSelectedField.setValue(Number(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, value]);

  if (field) {
    return (
      <Col span={8} className={field.id}>
        <Typography.Text>{field.label}</Typography.Text>
        <Input
          value={selected ?? ''}
          onChange={handleChange}
          placeholder={field.placeholder}
          disabled={!field.editable}
        />
      </Col>
    );
  }

  const content = (
    <Form.Item style={{ height: '100px' }} name={fieldType ?? ''}>
      <Typography.Text>Edit "{fieldName}" value</Typography.Text>
      <Input
        name={fieldType ?? ''}
        onChange={handleChange}
        value={selected ?? ''}
        placeholder="value"
      />
      <div className="profile-information__popover-buttons">
        <Button className="profile-information__cancel" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className="profile-information__save"
          type="primary"
          onClick={() => {
            onSave && onSave(fieldType);
            setClosePopup(true);
          }}
        >
          Save
        </Button>
      </div>
    </Form.Item>
  );

  return (
    <EditPopover
      setClosePopup={setClosePopup}
      closePopup={closePopup}
      placement={isChromePlugin ? 'topRight' : 'top'}
      content={content}
    >
      <Tooltip title={value ? value.toString() : DEFAULT_EMPTY_VALUE}>
        {value ? value.toString() : DEFAULT_EMPTY_VALUE}
      </Tooltip>
    </EditPopover>
  );
};

export default React.memo(InputNumberPercentageField);
