import { createAction } from '@reduxjs/toolkit';
// models
import { FileDetails } from '@optx/models/files';
import { SortByRule } from '@optx/models/table/sorting';
import { DataSettingsResponse, UpdateFiltersModels } from './interfaces';
import * as types from './types';

export const fetchFiles = createAction(types.FETCH_FILES);
export const fetchFilesSuccess = createAction<Array<FileDetails>>(types.FETCH_FILES_SUCCESS);
export const fetchFilesFail = createAction<string>(types.FETCH_FILES_FAIL);

export const fetchFilesFilters = createAction(types.FETCH_FILES_FILTERS);
export const fetchFilesFiltersSuccess = createAction<DataSettingsResponse>(
  types.FETCH_FILES_FILTERS_SUCCESS
);
export const fetchFilesFiltersFail = createAction<string>(types.FETCH_FILES_FILTERS_FAIL);

export const downloadFile = createAction<{ id: number; name: string }>(types.DOWNLOAD_FILE);
export const fetchForCompanyId = createAction<number | null>(types.FETCH_FOR_COMPANY_ID);
export const clearFilesData = createAction(types.CLEAR_FILES_DATA);

export const sortFiles = createAction<Array<SortByRule<any>>>(types.SORT_FILES);
export const changeFilter = createAction<UpdateFiltersModels>(types.CHANGE_FILTER);
