import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import reducer, { saga } from '@redux/scheduled-touches';
import featureName from '@optx/redux/scheduled-touches/featureName';

const useInjectScheduledTouches = () => {
  useInjectReducer({
    key: featureName,
    reducer,
  });
  useInjectSaga({
    key: featureName,
    saga,
  });
};

export default useInjectScheduledTouches;
