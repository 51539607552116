import { selectors as fetchSelectors } from '@redux/feature/fetch';
import { EquityTouchDatabaseMatch } from '@models/equityTouch';
import { CompanyIndividualEditState } from './interfaces';
// utils
import { getYearsList } from '@utils/date';

export const isLoading = (state: CompanyIndividualEditState) =>
  fetchSelectors.isLoadingSelector(state);

export const getError = (state: CompanyIndividualEditState) => fetchSelectors.errorSelector(state);

export const isSubmitting = (state: CompanyIndividualEditState) => !!state.data && state.loading;

export const failedLoadingInfo = (state: CompanyIndividualEditState) =>
  !state.data && !!state.error;
export const getData = (state: CompanyIndividualEditState) => state.data;

export const getSelectOptionsByField =
  (
    field:
      | 'rank'
      | 'deal_team_leader'
      | 'deal_team'
      | 'stage'
      | 'fund'
      | 'sector'
      | 'sub_sector'
      | 'person_phone_type'
      | 'source'
      | 'senior_advisor_thesis'
      | 'opportunity_type'
  ) =>
  (state: CompanyIndividualEditState) => {
    if (!state.data || !state.data[field]) {
      return [];
    }

    return state.data[field].received_values.map(item => ({
      value: (item as EquityTouchDatabaseMatch).itemId,
      label: (item as EquityTouchDatabaseMatch).text,
    }));
  };

export const shouldInitialFetch = (state: CompanyIndividualEditState) =>
  !state.loading && !state.fetchedAt;

export const getYearFoundedOptions = () => getYearsList();
