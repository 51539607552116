import { call, put, takeLatest, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary, isEqual } from 'lodash';
import queryString from 'query-string';
// models
import { CompanyUserContact, SearchPayloadRequest } from '@models/api/contacts';
import { BaseFilter } from '@models/filters';
import { PageInfo } from '@models/table/Pagination';
import { GridPayload, GetGridSearchData, GridKeysMain } from '@models/grid';
import { SearchStateData } from '@models/search';
import { SearchSave } from '@models/search';
import { AxiosResponse } from 'axios';
import { SelectOption } from '@models/Option';
// services
import { SearchService } from '@services/api';
import NotificationService from '@services/NotificationService';
// utils
import mapFiltersToURLParams from '@utils/filters/mapFiltersToURLParams';
import { matchSavedSearches } from '@utils/search';
// redux
import { selectors as searchSelectors } from '@features/grid/search';
import { searchCompanies, searchCompaniesFail } from '@features/grid/search/state/sagasReusable';
import { getSavedSearches } from '@redux/contacts/saved-searches/selectors';
import {
  selectors as checklistResearchSelectors,
  actions as checklistResearchActions,
} from '@features/checklist-research-insights/state';
import { selectors as addonSelectors } from '@redux/company/addon-management';
import { changePagination, changePaginationSuccess } from './actions';
import { selectors as myCompaniesFilterCardsSelectors } from '@optx/redux/my-companies/filters-cards';
import { actions as contactsSearchActions } from '@redux/contacts/search/search';
import { getSearchData } from '@redux/contacts/search/search/selectors';
import { selectors as contactsFiltersSelectors } from '@redux/contacts/filters';
import { selectors as companyOutreachFilterSelectors } from '@redux/company-outreach/filters';
import { getGridSearchDataSaga, userInfoUpdateSaga } from '@features/grid/state/sagasReusable';
import { getViewId } from '@optx/features/grid/view/state/selectors';
import { getListType } from '@optx/features/grid/search/state/selectors';

export function* changePaginationSaga(action: PayloadAction<GridPayload<PageInfo>>) {
  const { gridKey, data: pagination } = action.payload;

  const gridSearchData: GetGridSearchData = yield call(getGridSearchDataSaga, gridKey, pagination);
  const { searchKey, filter, originalFilter, sortBy } = gridSearchData;
  const id: number = yield select(getViewId(gridKey));
  let listType = '';

  if (gridKey === 'watchlists' || gridKey === 'sslists') {
    const mainGridKey = 'lists' as GridKeysMain;
    listType = yield select(getListType(mainGridKey));
  } else {
    listType = yield select(getListType(gridKey as GridKeysMain));
  }

  const searchData: SearchPayloadRequest = {
    searchKey,
    filter,
    pagination,
    sortBy,
    fromSavedList: id,
    listType,
  };

  switch (gridKey) {
    case 'advancedSearch': {
      const searchName: string = yield select(searchSelectors.getSearchName('advancedSearch'));

      try {
        yield call(
          searchCompanies,
          searchData,
          'advancedSearch',
          originalFilter,
          undefined,
          searchName,
          false
        );
        yield put(changePaginationSuccess(action.payload));

        yield call(userInfoUpdateSaga, gridKey, pagination);
      } catch (error: any) {
        yield call(searchCompaniesFail, 'advancedSearch', error);
      }

      break;
    }

    case 'myCompanies': {
      const selectedCard: SearchStateData = yield select(
        myCompaniesFilterCardsSelectors.getSelectedCard
      );
      const subFilter: string = yield select(myCompaniesFilterCardsSelectors.getSubFilter);

      const searchData: SearchPayloadRequest = {
        searchKey,
        filter: subFilter
          ? // @ts-ignore
            // @ts-ignore
            { ...selectedCard.filter, ...selectedCard.data[subFilter], ...filter }
          : { ...selectedCard.filter, ...filter },
        pagination,
        sortBy,
        fromSavedList: id,
        listType,
      };

      try {
        const userSettings: { [key: string]: number } = yield call(
          userInfoUpdateSaga,
          gridKey,
          pagination,
          true
        );

        // fetch companies and update user pagination settings on server if page size or page number has changed
        yield call(
          searchCompanies,
          searchData,
          'myCompanies',
          originalFilter,
          undefined,
          undefined,
          isEqual(userSettings, {}) ? false : userSettings
        );

        yield put(changePaginationSuccess(action.payload));
      } catch (error: any) {
        yield call(searchCompaniesFail, 'myCompanies', error);
      }

      break;
    }

    case 'contacts': {
      const { ...searchPayload } = yield select(getSearchData);
      const filterMap: Dictionary<BaseFilter> = yield select(
        contactsFiltersSelectors.getFiltersMap
      );
      const filterURLParam = mapFiltersToURLParams(filterMap, searchPayload.filter);
      const payloadRequest: SearchPayloadRequest = {
        ...searchPayload,
        filter: filterURLParam,
        pagination,
      };

      try {
        const res: AxiosResponse<CompanyUserContact[]> = yield call(
          SearchService.searchContacts,
          payloadRequest
        );

        if (res.data) {
          const savedSearches: Array<SearchSave> = yield select(getSavedSearches);

          let title = '';

          if (typeof payloadRequest.searchTitle === 'string') {
            title = payloadRequest.searchTitle;
          }

          if (!title) {
            title = matchSavedSearches(payloadRequest, savedSearches);
          }

          yield put(contactsSearchActions.searchContactsSuccess(res.data, title));

          yield call(userInfoUpdateSaga, gridKey, pagination);

          yield put(changePaginationSuccess(action.payload));
        } else {
          yield put(contactsSearchActions.searchContactsFail('Search contacts fail!'));
        }
      } catch (e: any) {
        const errorMessage = 'Search contacts fail, Server error!';
        yield put(contactsSearchActions.searchContactsFail(errorMessage));
        NotificationService.error(errorMessage);
      }

      break;
    }

    case 'addons': {
      const currentCompanyId: number = yield select(searchSelectors.getCompanyId('addons'));
      const additionalFiltersQuery: Dictionary<string | (string | number)[]> = yield select(
        addonSelectors.getAdditionalFiltersQuery
      );

      searchData.companyId = currentCompanyId;

      const normalizedSearchData = {
        ...searchData,
        filter: { ...searchData.filter, ...additionalFiltersQuery },
      };

      yield call(searchCompanies, normalizedSearchData, 'addons', originalFilter);

      yield put(changePaginationSuccess(action.payload));

      yield call(userInfoUpdateSaga, 'addons', pagination);

      break;
    }

    case 'sslists':
    case 'watchlists':
      try {
        yield call(searchCompanies, searchData, 'lists', originalFilter);

        yield put(
          changePaginationSuccess({
            gridKey,
            data: searchData.pagination,
          })
        );

        yield call(userInfoUpdateSaga, 'lists', pagination);
      } catch (error: any) {
        yield call(searchCompaniesFail, 'lists', error);
      }

      break;

    case 'checklistResearchInsights':
      const getDefaultTableView: 'company' | 'analyst' = yield select(
        checklistResearchSelectors.ui.defaultTableView
      );

      try {
        yield put(
          changePaginationSuccess({
            gridKey: 'checklistResearchInsights',
            data: searchData.pagination,
          })
        );

        if (getDefaultTableView === 'analyst') {
          yield put(checklistResearchActions.search.fetchAnalyst());
        } else {
          yield put(checklistResearchActions.search.fetchCompany());
        }
      } catch (error: any) {
        yield call(searchCompaniesFail, gridKey, error);

        if (getDefaultTableView === 'analyst') {
          yield put(
            checklistResearchActions.search.fetchAnalystFail('Get analysts fail, Server error')
          );
        } else {
          yield put(
            checklistResearchActions.search.fetchCompanyFail('Get companies fail, Server error')
          );
        }
      }

      break;

    case 'outreach': {
      const tableGridQuery: string = yield select(searchSelectors.getTableGridQuery('outreach'));

      const analystIdFilter: [string | null, string | null] | SelectOption<string> | undefined =
        yield select(companyOutreachFilterSelectors.getSourcingOptitonsFilterValues('analyst_id'));
      const columnNameSourcingOutReachFilter:
        | [string | null, string | null]
        | SelectOption<string>
        | undefined = yield select(
        companyOutreachFilterSelectors.getSourcingOptitonsFilterValues('column_name')
      );

      const dateRangeValues: [string, string] = yield select(
        companyOutreachFilterSelectors.getSourcingFilterOptionsDateValue
      );

      // @ts-ignore
      const listOfAnalystIds: number[] = analystIdFilter?.map(
        (list: { label: string; value: number }) => list.value
      );

      const sourcingOutReachColumnName = (columnNameSourcingOutReachFilter as SelectOption)?.value;

      const parsedTableGridQuery = queryString.parse(tableGridQuery);

      const customTableGridQuery = {
        ...parsedTableGridQuery,
        ddate_lower: dateRangeValues[0],
        ddate_upper: dateRangeValues[1],
      };

      const searchDataOutreach = {
        ...searchData,
        analystId: listOfAnalystIds?.toString(),
        tableGridQuery: queryString.stringify(customTableGridQuery),
        sourcingOutReachColumnName,
      };

      try {
        const userSettings: { [key: string]: number } = yield call(
          userInfoUpdateSaga,
          gridKey,
          pagination,
          true
        );

        // fetch companies and update user pagination settings on server if page size or page number has changed
        yield call(
          searchCompanies,
          searchDataOutreach,
          gridKey,
          originalFilter,
          undefined,
          undefined,
          isEqual(userSettings, {}) ? false : userSettings
        );

        yield put(changePaginationSuccess(action.payload));
      } catch (error) {
        yield call(searchCompaniesFail, gridKey, error);
      }

      break;
    }

    default:
      break;
  }
}

export default function* companiesSaga() {
  yield takeLatest(changePagination, changePaginationSaga);
}
