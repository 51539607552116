import styled from 'styled-components';

export const TableYearTitleContainer = styled.div`
  display: flex;
  .edit-icon {
    display: none;
  }
  :hover .edit-icon {
    display: inline-block;
    margin-left: 3rem;
    font-size: 20px;
    color: #1890ff;
  }
`;
