import { createAction } from '@reduxjs/toolkit';
import { CompanyDatePresented } from '@optx/models/Company';
import * as types from './types';

export const updateCompanyDatePresented = createAction<CompanyDatePresented>(
  types.UPDATE_COMPANY_DATE_PRESENTED
);

export const updateCompanyDatePresentedSuccess = createAction<string[] | null[]>(
  types.UPDATE_COMPANY_DATE_PRESENTED_SUCCESS
);
