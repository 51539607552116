import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
import CompanyIndividualEditOptions from '@models/feature/companyIndividualEdit/CompanyIndividualEditOptions';
import { reducers as fetchReducers } from '@redux/feature/fetch';
import { CompanyIndividualEditState } from './interfaces';
import * as actions from './actions';

export const initialState: CompanyIndividualEditState = {
  data: null,
  error: null,
  loading: false,
  fetchedAt: null,
};

const fetchSelectOptionsSuccess: CaseReducer<
  CompanyIndividualEditState,
  PayloadAction<CompanyIndividualEditOptions>
> = (draftState, action) => {
  fetchReducers.fetchSuccessReducer(draftState, action);
  draftState.fetchedAt = new Date().toISOString();
};

const reducer = createReducer(
  initialState,
  builder =>
    builder
      // fetch individual edit options
      .addCase(actions.fetchSelectOptions, fetchReducers.fetchReducer)
      .addCase(actions.fetchSelectOptionsSuccess, fetchSelectOptionsSuccess)
      .addCase(actions.fetchSelectOptionsFail, fetchReducers.fetchFailReducer)
  // share list
  // .addCase(actions.shareList, fetchReducers.fetchReducer)
  // .addCase(actions.shareListSuccess, shareListSuccessReducer)
  // .addCase(actions.shareListFail, fetchReducers.fetchFailReducer)
  // // edit share list
  // .addCase(actions.editShareList, editShareListReducer)
  // .addCase(actions.cancelEditShareList, cancelEditShareListReducer)
);

export default reducer;
