import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'antd';
import classNames from 'classnames';
import numeral from 'numeral';
import { useLocation, useHistory } from 'react-router-dom';
// constants
import APP_ROUTES from '@constants/routes';
// redux
import { selectors as favoritesSelectors } from '@redux/favorites';
import { selectors as fullscreenSelectors } from '@redux/ui/settings/fullscreen';
import { selectors as favoriteListsSelectors } from '@redux/favorite-lists/lists';
import {
  selectors as sourceScrubListsSelectors,
  actions as sourceScrubListsActions,
} from '@redux/user/source-scrub-lists';
import { selectors as suggestedSearchesSelectors } from '@redux/company/suggested-searches';
import { selectors as savedSearchesSelectors } from '@redux/company/saved-searches';
import { selectors as contactsSavedSearchesSelectors } from '@redux/contacts/saved-searches';
import { selectors as listSelectors } from '@redux/lists/details';
// hooks
import { useInjectCompanyReview } from '@optx/features/company-review';
import {
  useInitFavorites,
  useFetchUserList,
  useInitFilters,
  useFetchSuggestedSearches,
  useFetchSavedSearches,
  useInitContactFilters,
  useFetchContactsSavedSearches,
} from '@optx/common/hooks/init';
// components
import PageLoader from './PageLoader';
import MyWatchlists from './MyWatchlists';
import SourceScrubLists from './SourceScrubLists';
import SuggestedSearchesTable from './SuggestedSearches';
import CompanySavedSeachesTable from './CompanySearches';
import ContactsSavedSeachesTable from './ContactsSeaches';
import TabsExtraContent from './TabsExtraContent';
// styles
import Styled from './CompanyList.styles';
import './styles.scss';

interface ListTableProps {
  name: string;
  content: React.ReactNode;
  total: number | string | undefined;
  extraContent?: JSX.Element | null;
  className?: string;
  showUniversalSearchBar?: boolean;
  path: string;
}

const { TabPane } = Tabs;

const CompanyList: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  useInitFilters();
  useInitFavorites();
  useFetchUserList(true);
  useFetchSuggestedSearches();
  useFetchSavedSearches();
  useInitContactFilters();
  useFetchContactsSavedSearches();
  useInjectCompanyReview();

  const fetchSourceScrubLists = useCallback(
    () => dispatch(sourceScrubListsActions.fetchSourceScrubListsTotal()),
    [dispatch]
  );

  useEffect(() => {
    fetchSourceScrubLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const favoriteListsTotal = useSelector(favoriteListsSelectors.getTotal);
  const favorites = useSelector(favoritesSelectors.getFavorites);
  const sourceScrubListsTotal = useSelector(sourceScrubListsSelectors.getTotal);
  const suggestedSearchesTotal = useSelector(suggestedSearchesSelectors.getTotal);
  const savedSearchesTotal = useSelector(savedSearchesSelectors.getTotal);
  const contactsSavedSearchesTotal = useSelector(contactsSavedSearchesSelectors.getTotal);
  const myWatchListData = useSelector(listSelectors.getLists);

  let totalWatchlist = favoriteListsTotal;

  if (favorites.length && myWatchListData.some(item => item.title === 'Favorites')) {
    totalWatchlist++;
  }

  const listTabs: Array<ListTableProps> = [
    {
      name: 'My Watchlists',
      content: <MyWatchlists />,
      className: 'list-tab',
      total: numeral(totalWatchlist).format('0,0'),
      showUniversalSearchBar: true,
      path: APP_ROUTES.lists.myWatchList,
    },
    {
      name: 'Source Scrub Lists',
      content: <SourceScrubLists />,
      className: 'source-scrub-lists',
      total: numeral(sourceScrubListsTotal).format('0,0'),
      showUniversalSearchBar: true,
      path: APP_ROUTES.lists.sourceScrubLists,
    },
    {
      name: 'Suggested Searches',
      content: <SuggestedSearchesTable />,
      total: suggestedSearchesTotal,
      showUniversalSearchBar: false,
      path: APP_ROUTES.lists.suggestedSearches,
    },
    {
      name: 'Saved Company Searches',
      content: <CompanySavedSeachesTable />,
      className: 'list-tab',
      total: savedSearchesTotal,
      showUniversalSearchBar: true,
      path: APP_ROUTES.lists.companySavedSearches,
    },
    {
      name: 'Saved Contact Searches',
      content: <ContactsSavedSeachesTable />,
      className: 'list-tab',
      total: contactsSavedSearchesTotal,
      showUniversalSearchBar: true,
      path: APP_ROUTES.lists.contactSearches,
    },
  ];

  const initialTab = listTabs.find(tab => tab.path === location.pathname)!;
  const [tab, setTab] = useState(initialTab);

  const onChangeTab = (activeKey: string) => {
    history.push(activeKey);
  };

  useEffect(() => {
    const tabItem = listTabs.find(tab => tab.path === location.pathname)!;
    setTab(tabItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const fullscreen = useSelector(fullscreenSelectors.isFullscreen);

  const contentClassNames = classNames('grid-page', {
    fullscreen: fullscreen,
    'has-search': tab.showUniversalSearchBar,
  });

  return (
    <div className="company-list">
      <PageLoader />
      <div className={contentClassNames}>
        <div>
          <div className="company-list__header">
            <Styled.PageTitle>{tab.name}</Styled.PageTitle>
          </div>
          <Tabs
            onChange={onChangeTab}
            activeKey={location.pathname}
            tabBarExtraContent={<TabsExtraContent />}
          >
            {listTabs.map((tab: ListTableProps) => (
              <TabPane tab={`${tab.name} (${tab.total})`} key={tab.path} className={tab.className}>
                {tab.content}
              </TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default CompanyList;
