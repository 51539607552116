import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { SuccessErrorCallback } from '@optx/models/callback';
// models
import { SalesloftCadences, SalesloftPostData, SalesloftRequest } from '@models/Salesloft';
import { CompanyUserContact } from '@optx/models/api/contacts';
import { PostSalesloftData } from '@redux/contacts/salesloft/interfaces';
// services
import { SalesloftService } from '@services/api';
import NotificationService from '@services/NotificationService';
// utils
import { handleSalesloftError, getSelectedContacts } from '@optx/utils/salesloft';
// redux
import { selectors as contactSelectors } from '@redux/company/primary-contacts';
import { AxiosResponse } from 'axios';
import { ResolveSalesloftSuccess } from '@optx/models/bulkActions';
import * as types from './types';
import * as actions from './actions';
import * as selectors from './selectors';

export function* fetchSalesloftAuthSaga(action: PayloadAction<boolean, any, SuccessErrorCallback>) {
  const { meta: callback } = action;

  try {
    const res: AxiosResponse<string> = yield call(SalesloftService.getAuthUrl);

    if (res.data) {
      yield put(actions.fetchSalesloftAuthUrlSuccess(res.data));
      callback && callback(res.data);
    } else {
      const errorMessage = 'Failed to fetch Salesloft authentication URL!';
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = 'Failed to fetch Salesloft authentication URL!';
    NotificationService.error(errorMessage);
  }
}

export function* fetchSalesloftCadencesSaga(
  action: PayloadAction<boolean, any, SuccessErrorCallback>
) {
  const { meta: callback } = action;

  try {
    const res: AxiosResponse<SalesloftCadences[]> = yield call(SalesloftService.getCadences);

    if (res.data) {
      yield put(actions.fetchSalesloftCadencesSuccess(res.data));
    } else {
      const errorMessage = 'Failed to fetch Salesloft cadences!';
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    if (error.response.status === 424) {
      callback && callback(false);
    } else {
      const errorMessage = 'Failed to fetch Salesloft cadences!';
      NotificationService.error(errorMessage);
    }
  }
}

export function* postSalesloftDataSaga(
  action: PayloadAction<PostSalesloftData, any, SuccessErrorCallback>
) {
  const { payload, meta: callback } = action;
  const postData: SalesloftPostData = yield select(selectors.getPostData);
  const contacts: CompanyUserContact[] = yield select(contactSelectors.getPrimaryContacts);
  // keep fields that are not null
  const requestData: SalesloftRequest = {
    cadence_id: postData.cadence_id,
    cadence_name: postData.cadence_name,
    team_cadence: postData.team_cadence,
    company_name: payload.company_name ? payload.company_name : postData.company_name,
    company_website: payload.company_url ? payload.company_url : postData.company_website,
    company_id: postData.company_id,
    contacts: getSelectedContacts(contacts, postData.contactIds),
  };

  try {
    const res: AxiosResponse<ResolveSalesloftSuccess> = yield call(
      SalesloftService.postSalesloftData,
      requestData
    );

    if (res) {
      yield put(actions.postSalesloftDataSuccess());
      callback && callback(res.data);
    } else {
      yield put(actions.postSalesloftDataFail());
    }
  } catch (error: any) {
    yield put(actions.postSalesloftDataFail());
    const errorMessage = handleSalesloftError(
      error.response,
      'Failed to submit Salesloft form!',
      callback
    );
    NotificationService.error(errorMessage);
  }
}

export function* preloadContactsSaga() {
  try {
    yield call(SalesloftService.preloadContacts);
  } catch (error: any) {
    const errorMessage = 'Failed to preload contacts!';
    NotificationService.error(errorMessage);
  }
}

export default function* salesloftSaga() {
  yield takeLatest(types.FETCH_SALESLOFT_AUTH_URL, fetchSalesloftAuthSaga);
  yield takeLatest(types.FETCH_SALESLOFT_CADENCES, fetchSalesloftCadencesSaga);
  yield takeLatest(types.POST_SALESLOFT_DATA, postSalesloftDataSaga);
  yield takeLatest(types.SALESLOFT_PRELOAD_CONTACTS, preloadContactsSaga);
}
