import React from 'react';
import { useSelector } from 'react-redux';
// models
import { Cell } from '@models/table/Cell';
import { CompanyImportFailedData } from '@models/Company';
// redux
import { selectors as importSelectors } from '@features/list-import/state';
// components
import EditCompany from '../EditCompany';

const TableCellActions: React.FC<Cell<CompanyImportFailedData>> = ({ record, index }) => {
  const canceledImports = useSelector(importSelectors.getCanceledImports);
  const wasCanceled = canceledImports.find(({ Company }) => Company === record.Company);

  if (!record?.Reason || wasCanceled) return null;

  return <EditCompany companyImportData={record} companyIndex={index} />;
};

export default TableCellActions;
