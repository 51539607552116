import React from 'react';
import { GlobalOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Cell } from '@optx/models/table/Cell';

const CompanyLink = styled.a`
  display: flex;
  justify-content: center;
  color: #c2cfe0;
  font-size: 22px;
  line-height: 1;

  > svg {
    width: auto;
    height: auto;
  }
`;

interface TableCellURLProps {
  url: string | null;
}

const TableCellURL: React.FC<Cell<TableCellURLProps>> = ({ record: { url } }) => {
  if (!url?.includes('.')) {
    return null;
  }

  return (
    <>
      {url && (
        <CompanyLink href={url} title={url} target="_blank" rel="noopener noreferrer">
          <GlobalOutlined className="icon-globe" />
        </CompanyLink>
      )}
    </>
  );
};

export default TableCellURL;
