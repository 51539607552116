import React, { useState, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import { ActionMeta, OptionsType, ValueType } from 'react-select';
import { Tag } from 'antd';
// models
import { SelectOption } from '@optx/models/Option';
import { SelectedList } from './interfaces';
// constants
import { SELECT_NONE } from './constants';

interface UseAsyncSelectProps {
  onChange: (values: OptionsType<SelectOption>, actionMeta: ActionMeta<SelectOption>) => void;
  preDefinedSelectedValues: OptionsType<SelectOption> | undefined;
  onTagClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  /**
   * Allow value manual control from parent.
   */
  value?: OptionsType<SelectOption>;
  allowSelectNone?: boolean;
}

export const useAsyncSelect = ({
  onChange,
  preDefinedSelectedValues,
  onTagClick,
  value,
  allowSelectNone,
}: UseAsyncSelectProps) => {
  const [selectedOption, setSelectedOption] = useState<OptionsType<SelectOption>>();
  const [selectedList, setSelectedList] = useState<Array<SelectedList>>();

  useEffect(() => {
    // If is controlled.
    if (value) {
      setSelectedOption(value);
    } else if (!selectedList?.length && preDefinedSelectedValues) {
      // if there are options already selected, set them.
      setSelectedOption(preDefinedSelectedValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preDefinedSelectedValues, value]);

  /**
   * removeSelectedItem - called when a selected option is removed.
   * @param optionValue
   */
  const removeSelectedItem = (optionValue: SelectOption) => {
    const newSelectedList: Array<SelectedList> = selectedList || [];
    let selectedFilter: Array<SelectedList> = [...newSelectedList];

    selectedFilter = newSelectedList
      .filter(item => {
        return item.value !== optionValue.value;
      })
      .map(item => item);

    setSelectedList([...selectedFilter]);
  };

  /**
   * customMultiValueContainer - replace the plugin's original function.
   */
  const MultiValueContainer: React.FC = ({ innerProps, children, data }: any) => {
    const newSelectedList: Array<SelectedList> = selectedList || [];

    const selectedFilter = newSelectedList.filter(item => {
      return item.value === data.value;
    });
    // fixes the cannot update a component while rendering a different component warning
    useEffect(() => {
      if (!selectedFilter.length) {
        newSelectedList.push({
          value: data.value,
          label: data.label,
          children,
        });

        setSelectedList([...newSelectedList]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div {...innerProps}>{children}</div>;
  };

  /**
   * handleChange - called whenever a option is selected or removed.
   * @param optionValue
   * @param optionAction
   */
  const handleChange = (
    optionValue: ValueType<SelectOption>,
    actionMeta: ActionMeta<SelectOption>
  ) => {
    const newSelectedOption: OptionsType<SelectOption> = optionValue
      ? (optionValue as OptionsType<SelectOption>)
      : [];

    if (actionMeta.action === 'select-option' && allowSelectNone) {
      if (actionMeta.option === SELECT_NONE) {
        setSelectedOption([]);
        setSelectedList([]);

        return onChange && onChange([], actionMeta);
      }

      const isNoneSelected = newSelectedOption.find(
        (val: SelectOption) => val.value === SELECT_NONE.value
      );

      if (isNoneSelected) {
        const options: OptionsType<SelectOption> = newSelectedOption.filter(
          (val: SelectOption) => val.value !== SELECT_NONE.value
        );

        removeSelectedItem(isNoneSelected);
        setSelectedOption(options);

        return onChange && onChange(options, actionMeta);
      }
    }

    // If option is removed
    if (actionMeta.action === 'remove-value' || actionMeta.action === 'deselect-option') {
      // @ts-ignore
      const removedValue: SelectOption = actionMeta.removedValue || actionMeta.option;

      removeSelectedItem(removedValue);
      setSelectedOption(newSelectedOption);
    } else if (!value) {
      // If we don't control value update selection.
      setSelectedOption(newSelectedOption);
    }

    return onChange && onChange(newSelectedOption, actionMeta);
  };

  /**
   * listSelectedItems - list all selected values below the dropdown.
   */
  const listSelectedItems = useMemo(() => {
    return selectedList?.map(item => {
      const tagClassName = classnames('multiselect-list__body-tag', {
        'multiselect-list__body-tag__none': allowSelectNone && item.value === SELECT_NONE.value,
      });

      return (
        <Tag color="blue" className={tagClassName} key={item.value} onClick={onTagClick}>
          {item.children}
        </Tag>
      );
    });
  }, [selectedList, onTagClick, allowSelectNone]);

  return {
    selectedOption,
    handleChange,
    MultiValueContainer,
    listSelectedItems,
  };
};
