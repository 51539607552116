import { SuccessErrorCallback } from '@optx/models/callback';
import { GrowthOverTime } from '@optx/models/charts/data';
import {
  FetchCompanyRevenueGrowthSuccessAction,
  FetchCompanyRevenueGrowthAction,
  FetchCompanyRevenueGrowthFailAction,
} from './interfaces';
import * as types from './actionTypes';

export const fetchCompanyRevenueGrowth = (
  companyId: number,
  callback: SuccessErrorCallback<Array<GrowthOverTime>>
): FetchCompanyRevenueGrowthAction => ({
  type: types.FETCH_REVENUE_GROWTH,
  payload: companyId,
  meta: callback,
});

export const fetchCompanyRevenueSuccess = (
  growth: Array<GrowthOverTime>
): FetchCompanyRevenueGrowthSuccessAction => ({
  type: types.FETCH_REVENUE_GROWTH_SUCCESS,
  payload: growth,
});

export const fetchCompanyRevenueFail = (error: string): FetchCompanyRevenueGrowthFailAction => ({
  type: types.FETCH_REVENUE_GROWTH_FAIL,
  payload: error,
});
