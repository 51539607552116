import { fork, delay } from 'redux-saga/effects';

const TIMEOUT_INTERVAL = 60 * 1000;

/**
 * Create pooling saga to be called at specified intervals.
 * @param saga saga generator function to be called when interval ends.
 * @param interval timeout interval in miliseconds. By default is 1 minute.
 */
export function createPool(saga: () => Generator, interval = TIMEOUT_INTERVAL) {
  function* poolGenerator() {
    while (true) {
      // Call generator without blocking.
      if (document.visibilityState === 'visible') {
        yield fork(saga);
      }

      yield delay(interval);
    }
  }

  return poolGenerator;
}
