import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import * as actions from './actions';
import { UserListState, UserListPageState } from './interfaces';
import { fetchReducer, fetchSuccessReducer, fetchFailReducer } from '../../feature/fetch/reducers';

export const initialState: UserListState = {
  share: { data: [], loading: false, error: '', fetchedAt: '' },
  field: { data: [], loading: false, error: '', fetchedAt: '' },
  psgUsers: { data: [], loading: false, error: '', fetchedAt: '' },
};

const fetchUserListReducer: CaseReducer<UserListState, PayloadAction<boolean>> = (
  draftState,
  action
) => {
  const isShare = action.payload;

  if (isShare) {
    fetchReducer(draftState.share);
  } else {
    fetchReducer(draftState.field);
  }
};

const fetchUserListFieldSuccessReducer: CaseReducer<
  UserListState,
  PayloadAction<Array<UserListPageState>>
> = (draftState, action) => {
  fetchSuccessReducer(draftState.field, action);
  draftState.field.fetchedAt = new Date().toISOString();
};

const fetchPsgUserListFieldSuccessReducer: CaseReducer<
  UserListState,
  PayloadAction<Array<UserListPageState>>
> = (draftState, action) => {
  fetchSuccessReducer(draftState.psgUsers, action);
  draftState.field.fetchedAt = new Date().toISOString();
};

const fetchPsgUserListFieldFailReducer: CaseReducer<UserListState, PayloadAction<string>> = (
  draftState,
  action
) => {
  fetchFailReducer(draftState.psgUsers, action);
};

const fetchUserListFieldFailReducer: CaseReducer<UserListState, PayloadAction<string>> = (
  draftState,
  action
) => {
  fetchFailReducer(draftState.field, action);
};

const fetchUserListShareFailReducer: CaseReducer<UserListState, PayloadAction<string>> = (
  draftState,
  action
) => {
  fetchFailReducer(draftState.share, action);
};

const fetchUserListShareSuccessReducer: CaseReducer<
  UserListState,
  PayloadAction<Array<UserListPageState>>
> = (draftState, action) => {
  fetchSuccessReducer(draftState.share, action);
  draftState.share.fetchedAt = new Date().toISOString();
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.fetchUserList, fetchUserListReducer)
    .addCase(actions.fetchUserListFieldSuccess, fetchUserListFieldSuccessReducer)
    .addCase(actions.fetchUserListFieldFail, fetchUserListFieldFailReducer)
    .addCase(actions.fetchPsgUserListFieldSuccess, fetchPsgUserListFieldSuccessReducer)
    .addCase(actions.fetchPsgUserListFieldFail, fetchPsgUserListFieldFailReducer)
    .addCase(actions.fetchUserListShareSuccess, fetchUserListShareSuccessReducer)
    .addCase(actions.fetchUserListShareFail, fetchUserListShareFailReducer)
);

export default reducer;
