import React from 'react';
import { FieldProps } from 'formik';
import { ValueType } from 'react-select';
import { MultiSelect, MultiSelectProps } from '@shared/view/molecules/Select';
import { SelectOption } from '@optx/models/Option';
// hooks
import useLocalMultiSelect from '@optx/common/hooks/select/useLocalMultiSelect';

interface FormikMultiSelectCustomProps {
  customOnChange?: (value: Array<ValueType<SelectOption<string | number>>>) => void;
}

type FormikMultiSelectProps = FieldProps &
  FormikMultiSelectCustomProps &
  // @ts-ignore ignore for now to temporary fix more complex issue with number values.
  MultiSelectProps<SelectOption<string | number>>;

const FormikMultiSelect = ({
  form,
  field,
  customOnChange,
  meta,
  ...restProps
}: FormikMultiSelectProps) => {
  const { setFieldValue } = form;
  const { name, value } = field;

  const handleChange = (value: ValueType<SelectOption<string | number>>) => {
    setFieldValue(name, value);
    customOnChange && customOnChange(value as Array<ValueType<SelectOption<string | number>>>);
  };

  const { localValue, onBlur, onChange } = useLocalMultiSelect(value, handleChange);

  return (
    // @ts-ignore
    <MultiSelect
      {...restProps}
      name={name}
      onChange={onChange}
      value={localValue}
      onBlur={onBlur}
    />
  );
};

export default FormikMultiSelect;
