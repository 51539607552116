import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
import * as actions from './actions';
import { CompanyNotesDialogState, EditCompanyNotes } from './interfaces';

const initialState: CompanyNotesDialogState = {
  open: false,
  companyId: -1,
  companyName: '',
};

const companyNotesReducer: CaseReducer<CompanyNotesDialogState, PayloadAction<EditCompanyNotes>> = (
  draftState,
  action
) => {
  const { companyId, companyName } = action.payload;
  draftState.open = !draftState.open;
  draftState.companyId = companyId;
  draftState.companyName = companyName;
};

export default createReducer(initialState, builder =>
  builder.addCase(actions.toggleCompanyNotesDialog, companyNotesReducer)
);
