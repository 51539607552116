import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SubSectionWrapper = styled.div``;

const Title = styled.h3`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 20px 20px 20px 0px;
`;

const SubTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  line-height: 20px;
  /* Dark BLue */
  color: #334d6e;
`;

const MainStatValue = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* Daybreak Blue / blue-6 */
  color: #1890ff;
`;

const Styles = {
  Wrapper,
  SubSectionWrapper,
  Title,
  SubTitle,
  MainStatValue,
};

export default Styles;
