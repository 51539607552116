import { Typography } from 'antd';
import styled from 'styled-components';

export const Title = styled(Typography.Title)`
  h1& {
    /* Dark BLue */
    color: #334d6e;

    font-style: normal;
    font-size: 24px;
    line-height: 1.33334;
  }

  h2& {
    /* Dark BLue */
    color: #334d6e;

    font-style: normal;
    font-size: 20px;
    line-height: 1.33334;
  }
`;
