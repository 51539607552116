// models
import { CaseReducer, createReducer } from '@reduxjs/toolkit';
import { LoaderState } from './interfaces';
// redux
import * as actions from './actions';

const initialState: LoaderState = {
  isConnecting: false,
};

const startConnectingReducer: CaseReducer<LoaderState> = draftState => {
  draftState.isConnecting = true;
};

const endConnectingReducer: CaseReducer<LoaderState> = draftState => {
  draftState.isConnecting = false;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.startConnecting, startConnectingReducer)
    .addCase(actions.endConnecting, endConnectingReducer)
);

export default reducer;
