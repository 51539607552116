import { createAction } from '@reduxjs/toolkit';
// models
import { EditAllInfo, EditAllFieldsGroup } from './interfaces';
import { CompanyEditAllDataPayload, UpdateEditAllFields } from '@models/api/editAllInfo';
import { CompanyFieldsGroup } from '@optx/models/companyFields';
import { TouchesFinancialPayload } from '@optx/models/api/touches';
import { AllFinancialYearsType } from '@optx/models/Financial';
// constants
import {
  GET_EDIT_ALL_INFO,
  GET_EDIT_ALL_INFO_FAIL,
  GET_EDIT_ALL_INFO_SUCCESS,
  TOGGLE_COMPANY_EDIT_ALL_FORM_DIALOG,
  UPDATE_COMPANY_EDIT_ALL_INFO,
  UPDATE_COMPANY_EDIT_ALL_INFO_SUCCESS,
  UPDATE_ALL_COMPANY_EDIT_ALL_INFO_SUCCESS,
  UPDATE_COMPANY_EDIT_ALL_INFO_FAIL,
  CHANGE_SAVE_BTN_STATUS,
  ADD_YEAR_CHANGE_LOG,
} from './types';

export const toggleCompanyEditAllFormDialog = createAction<EditAllInfo>(
  TOGGLE_COMPANY_EDIT_ALL_FORM_DIALOG
);

export const getEditAllInfo = createAction<string | number>(GET_EDIT_ALL_INFO);
export const getEditAllInfoSuccess =
  createAction<Record<EditAllFieldsGroup, CompanyFieldsGroup[]>>(GET_EDIT_ALL_INFO_SUCCESS);
export const getEditAllInfoFail = createAction<string>(GET_EDIT_ALL_INFO_FAIL);

export const updateCompanyEditAllInfo = createAction<UpdateEditAllFields>(
  UPDATE_COMPANY_EDIT_ALL_INFO
);
export const updateCompanyEditAllInfoSuccess = createAction<Partial<CompanyEditAllDataPayload>>(
  UPDATE_COMPANY_EDIT_ALL_INFO_SUCCESS
);
export const updateAllCompanyEditAllInfoSuccess = createAction<Partial<TouchesFinancialPayload>>(
  UPDATE_ALL_COMPANY_EDIT_ALL_INFO_SUCCESS
);
export const updateCompanyEditAllInfoFail = createAction<string>(UPDATE_COMPANY_EDIT_ALL_INFO_FAIL);

export const changeSaveBtnStatus = createAction<string | null>(CHANGE_SAVE_BTN_STATUS);

export const addYearChangeLog = createAction<AllFinancialYearsType>(ADD_YEAR_CHANGE_LOG);
