import { createFeatureActionType } from '@optx/utils/feature';
import { featureName } from '../featureName';

export const ADD_COMPANY_REVIEW = createFeatureActionType(featureName, 'ADD_COMPANY_REVIEW');
export const ADD_COMPANY_REVIEW_SUCCESS = createFeatureActionType(
  featureName,
  'ADD_COMPANY_REVIEW_SUCCESS'
);
export const ADD_COMPANY_REVIEW_FAIL = createFeatureActionType(
  featureName,
  'ADD_COMPANY_REVIEW_FAIL'
);
