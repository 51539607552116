import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, Row, Tooltip, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { DownOutlined } from '@ant-design/icons';
// models
import { FilterSource, Filter, BaseFilter } from '@optx/models/filters';
import { FilterTagValue, FilterTag } from '@optx/models/tags';
import { GridKeysMain } from '@optx/models/grid';
// utils
import { createGeoRangeGroup } from '@optx/utils/filters/filterGeoRange';
import { mapSourcesByFilter } from '@optx/utils/filters/mapSourcesByFilter';
import { getLocationMainGridKey } from '@optx/utils/grid';
// redux
import { selectors as searchSelectors } from '@features/grid/search';
// components
import TagsWithPopover from '@optx/components/common/TagsWithPopover';
import FilterFactory from '../company-filters/Filter/FilterFactory';
// styles
import Styled from './SecondaryQuickFilters.styles';

const { Title } = Typography;

interface LocationFilterGroupProps {
  options: FilterTag[];
  sources: FilterSource<Filter<any>>[];
  filters: {
    [k: string]: BaseFilter<any>;
  };
  onReset: () => void;
}

const LocationFilterGroup: React.FC<LocationFilterGroupProps> = ({
  sources,
  filters,
  onReset,
  options,
}) => {
  const listType = useSelector(
    searchSelectors.getListType(getLocationMainGridKey() as GridKeysMain)
  );
  const isFilterDisabled = listType === 'combined';

  const [isVisible, setIsVisible] = useState(false);
  const FILTER_TAG_DEFAULT_VALUE = [
    {
      value: 'Any',
    },
  ];
  // For Submit and validation
  const { submitForm, errors } = useFormikContext();

  const [filterTagValues, setFilterTagValues] =
    useState<FilterTagValue[]>(FILTER_TAG_DEFAULT_VALUE);
  // Filter all filters
  const locationFilters = Object.values(filters).filter(
    singleFilter => singleFilter.is_location && singleFilter.is_quick_filter
  );

  // for location filter group
  const sourcesByFilter = useMemo(() => mapSourcesByFilter(sources), [sources]);

  // for Geo Range Group
  const filterSourcesModified = useMemo(
    () =>
      sources.map(filterGroup => {
        const newFilterGroup: FilterSource<Filter<any>> = { ...filterGroup };
        const newFilterData: Array<Filter<any>> = createGeoRangeGroup(newFilterGroup.data);

        // add the onFilterFieldChange property to the filters.
        newFilterGroup.data = newFilterData;

        return newFilterGroup;
      }),
    [sources]
  );

  // Choose correct group by label
  const geoFiltersGroup = filterSourcesModified.filter(
    ({ label }) => label === 'Company Background'
  );

  // Filter location group
  const locationGroup = locationFilters.filter(
    filter =>
      filter.column === 'country' ||
      filter.column === 'state' ||
      filter.column === 'city' ||
      filter.column === 'europe_tier'
  );

  // Store Countries tag values
  function filterColumn(filtersArr: BaseFilter<any>[]): string[] {
    const arrayColumns = [];

    for (let i = 0; i < filtersArr.length; i++) {
      arrayColumns.push(filtersArr[i].column);
    }

    return arrayColumns;
  }

  useEffect(() => {
    const filterTags = options.filter(option =>
      filterColumn(locationGroup).includes(option.filter)
    );

    if (
      filterTags?.length === 1 &&
      filterTags[0].values?.length > 0 &&
      !filterColumn(locationGroup).includes('source_tag_filter')
    ) {
      setFilterTagValues(filterTags[0].values);
    } else if (filterTags?.length > 0 && filterTags[0].values?.length > 0) {
      const modifiedFilterTagValues = filterTags.reduce(
        (array: FilterTagValue[], filterValue: FilterTag) => {
          filterValue.values.forEach(item =>
            array.push({
              ...item,
              value: `${item.value}`,
            })
          );

          return array;
        },
        []
      );
      setFilterTagValues(modifiedFilterTagValues);
    } else {
      setFilterTagValues([
        {
          value: 'Any',
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  if (!Object.keys(filters).length) {
    return null;
  }

  const handleCancel = () => {
    onReset();
    setIsVisible(false);
  };

  const handleApply = () => {
    submitForm();

    if (!Object.keys(errors).length) {
      setIsVisible(false);
    }
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLUListElement>) => {
    if (event.key === 'Enter') {
      handleApply();
    }
  };

  const menu = (
    <Styled.LocationMenuWrapper onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
      <div className="menu-wrapper">
        <Title
          level={5}
          style={{
            marginBottom: 0,
            fontWeight: 500,
            lineHeight: 1,
            paddingLeft: 24,
            paddingTop: 20,
            paddingBottom: 6,
          }}
        >
          Location
        </Title>
        {Object.values(locationGroup).map(filter => (
          <FilterFactory
            filter={filter}
            source={sourcesByFilter[filter.column]}
            key={filter.column}
          />
        ))}
        {geoFiltersGroup.map(source =>
          source.data.map(filter =>
            filter.groupItems ? (
              <FilterFactory filter={filter as BaseFilter} source={source} key={filter.column} />
            ) : null
          )
        )}
      </div>
      <Row justify="end" className="menu-buttons-wrapper">
        <Button style={{ marginRight: 8 }} onClick={handleCancel}>
          Cancel
        </Button>
        <Button type="primary" onClick={handleApply}>
          Apply
        </Button>
      </Row>
    </Styled.LocationMenuWrapper>
  );

  const content: JSX.Element = (
    <div>
      <Styled.DropdownWrapper
        visible={isVisible}
        onVisibleChange={flag => {
          !flag ? handleCancel() : setIsVisible(flag);
        }}
        overlay={menu}
        trigger={['click']}
        placement="bottomLeft"
        disabled={isFilterDisabled}
      >
        <Button>
          <Styled.TriggerWrapper>
            Location:
            <Styled.SelectedLabel ellipsis>
              <TagsWithPopover filterTagValues={filterTagValues} />
            </Styled.SelectedLabel>
            <DownOutlined />
          </Styled.TriggerWrapper>
        </Button>
      </Styled.DropdownWrapper>
    </div>
  );

  return isFilterDisabled ? (
    <Tooltip title="Not supported for combined searches" placement="bottom">
      {content}
    </Tooltip>
  ) : (
    content
  );
};

export default LocationFilterGroup;
