import { useCallback, useEffect } from 'react';
import { useFullscreen } from '@umijs/hooks';
import { Options } from '@umijs/hooks/es/useFullscreen';
import { useDispatch } from 'react-redux';
// redux
import { actions as fullscreenActions } from '@redux/ui/settings/fullscreen';

interface UseReduxFullScreenOptions<T> extends Options<T> {
  /**
   * Current redux state for full screen.
   */
  fullscreen: boolean;
  /**
   * Redux toggle action.
   */
  toggle: VoidFunction;
}

const useReduxFullScreen = <T extends HTMLElement = HTMLElement>({
  fullscreen,
  toggle,
  ...originalOptions
}: UseReduxFullScreenOptions<T>) => {
  const { onExitFull, ...restOriginalOptions } = originalOptions;

  const dispatch = useDispatch();

  const toggleFullScreen = useCallback(
    () => dispatch(fullscreenActions.toggleFullScreen()),
    [dispatch]
  );

  const handleExitFull = () => {
    // full screen was done by Escape key or pressing x. We should update redux.
    if (fullscreen !== isFullscreen) {
      // Weird issue needs small timeout to update redux store properly.
      setTimeout(toggle, 0);
    }

    onExitFull && onExitFull();
  };

  const { ref, isFullscreen, setFull, exitFull, ...restProps } = useFullscreen<T>({
    onExitFull: handleExitFull,
    ...restOriginalOptions,
  });

  useEffect(() => {
    if (fullscreen) {
      toggleFullScreen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update fullscreen when redux store updates value
  useEffect(() => {
    if (fullscreen) {
      setFull();
    } else {
      exitFull();
    }
  }, [fullscreen, setFull, exitFull]);

  return {
    ref,
    isFullscreen,
    setFull,
    exitFull,
    ...restProps,
  };
};

export default useReduxFullScreen;
