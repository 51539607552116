import styled from 'styled-components';

const GrayButton = styled.button`
  padding: 5px 0;
  background: #fff;
  width: 216px;
  font-size: 16px;
  color: $gray-7;
  border: 1px solid $gray-5;
`;

export default {
  GrayButton,
};
