import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// models
import { ColumnKeys } from '@optx/models/table/Columns';
// constants
import routes from '@constants/routes';
import {
  columnDisplayInitialStateSourcingOutReachGrid,
  initialColumnOrderSourcingOutReachGrid,
} from '@optx/constants/table/columnDisplay/company-search';
// redux
import { selectors as viewSelectors, actions as viewActions } from '@features/grid/view';
import {
  selectors as columnSelectors,
  actions as columnsActions,
} from '@optx/redux/company-outreach/columns';
import { incrementFetchedCount } from '@optx/features/grid/fetched-count/state/actions';
import { actions as scrollHistoryActions } from '@features/scroll-history';
// hooks
import { useDisplayColumnModal } from '@optx/common/hooks/init';
// components
import GridView from '@optx/features/grid/view/components/GridView';
import TableColumnFilter from '@optx/components/common/table/TableColumnFilter';
import { TableColumnFilterContext } from '@optx/components/common/table/TableColumnFilterContext';
import { DrawerHeader } from '@optx/features/columns/DrawerHeader';
// style
import { Styled } from '@components/common/table/DisplayColumnModal.styled';

const DisplayColumnModal: React.FC = () => {
  const dispatch = useDispatch();
  const [open, toggle] = useDisplayColumnModal();

  const [sortable, pinned] = useSelector(columnSelectors.getColumnLists);
  const columnData = useSelector(columnSelectors.getColumnDisplay);
  const columnOrder = useSelector(columnSelectors.getColumnOrder);
  const activeView = useSelector(viewSelectors.getView('outreach'));
  const applyView = viewActions.applyView;

  const [columnDisplay, setColumnDisplay] = useState(columnData);
  const [sortableColumns, setSortableColumns] = useState(sortable);
  const [pinnedColumns, setPinnedColumns] = useState(pinned);

  const handleApply = () => {
    toggle();
    dispatch(
      columnsActions.saveColumns(
        columnDisplay,
        pinnedColumns
          .map(column => column.id)
          .concat(sortableColumns.map(column => column.id)) as ColumnKeys[]
      )
    );

    dispatch(scrollHistoryActions.resetScrollHistory(routes.lists.myWatchList));
    dispatch(incrementFetchedCount('outreach'));
  };

  const handlePin = (columnId: ColumnKeys, shouldPin: boolean) => {
    const pinIndex = sortableColumns.findIndex(column => column.id === columnId);

    if (pinIndex !== -1) {
      setPinnedColumns([
        ...pinnedColumns,
        { ...columnDisplay[columnId], pinned: true, visible: true },
      ]);
      sortableColumns.splice(pinIndex, 1);

      setSortableColumns(sortableColumns);
    } else {
      const unPinnedIndex = pinnedColumns.findIndex(column => column.id === columnId);
      pinnedColumns.splice(unPinnedIndex, 1);

      setPinnedColumns(pinnedColumns);
      setSortableColumns([
        { ...columnDisplay[columnId], pinned: false, visible: true },
        ...sortableColumns,
      ]);
    }

    setColumnDisplay({
      ...columnDisplay,
      [columnId]: { ...columnDisplay[columnId], pinned: shouldPin, visible: true },
    });
  };

  const handleClose = () => {
    toggle();
    setColumnDisplay(columnData);
    setSortableColumns(sortable);
    setPinnedColumns(pinned);
  };

  return (
    <TableColumnFilterContext.Provider
      value={{
        columnDisplay,
        setColumnDisplay,
        sortableColumns,
        setSortableColumns,
        pinnedColumns,
        setPinnedColumns,
      }}
    >
      <Styled.Drawer
        visible={open}
        onClose={handleClose}
        placement="right"
        closable
        width="485"
        title={<DrawerHeader onApply={handleApply} />}
        destroyOnClose
      >
        <div>
          <TableColumnFilter
            gridView={<GridView gridKey="outreach" activeView={activeView} applyView={applyView} />}
            handlePin={handlePin}
            columnData={columnData}
            columnOrder={columnOrder}
            initialColumnState={columnDisplayInitialStateSourcingOutReachGrid}
            initialColumnOrder={initialColumnOrderSourcingOutReachGrid}
          />
        </div>
      </Styled.Drawer>
    </TableColumnFilterContext.Provider>
  );
};

export default DisplayColumnModal;
