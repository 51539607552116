import { createAction } from '@reduxjs/toolkit';
import { SearchViewType } from '@optx/models/user';
import * as types from './types';
import { GridPayloadMain } from '@optx/models/grid';

export const toggleSearchView = createAction<GridPayloadMain<SearchViewType>>(
  types.TOGGLE_SEARCH_VIEW
);
export const changedCompanyCard = createAction<GridPayloadMain<boolean>>(
  types.CHANGED_COMPANY_CARD
);
export const toggleSearchViewSuccess = createAction<GridPayloadMain<SearchViewType>>(
  types.TOGGLE_SEARCH_VIEW_SUCCESS
);
