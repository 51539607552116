import { createAction } from '@reduxjs/toolkit';
import {
  EditField,
  EditFieldFail,
  EditFieldSuccess,
  UpdateVerifyCompanyPayload,
} from './interfaces';
import * as types from './types';

export const updateFields = createAction<EditField>(types.updateEditFields);
export const updateVerifyCompanySoftware = createAction<UpdateVerifyCompanyPayload>(
  types.updateVerifyCompanySoftware
);
export const updateVerifyCompanySoftwareSuccess = createAction<UpdateVerifyCompanyPayload>(
  types.updateVerifyCompanySoftwareSuccess
);
export const updateVerifyCompanySoftwareFail = createAction<string>(
  types.updateVerifyCompanySoftwareFail
);
export const updateFieldsSuccess = createAction<EditFieldSuccess>(types.updateEditFieldsSuccess);
export const updateFieldsFail = createAction<EditFieldFail>(types.updateEditFieldFail);
