import { createSelector } from 'reselect';
import { ColumnType } from 'antd/lib/table';
// models
import { AppState } from '@redux/interfaces';
// utils
import { hasEditPermission } from '@utils/lists';
import { sortColumns } from '@utils/table/sorting';
// redux
import { selectors as fetchSelectors } from '@redux/feature/fetch';
import { selectors as userSelectors } from '@redux/user/information';
import { getDefaultScore } from '@redux/user/information/selectors';

// specific selectors
const selectListsState = (state: AppState) => state.favoriteLists;

const selectFavoriteListsState = createSelector(selectListsState, state => state.lists);

export const isLoading = createSelector(selectFavoriteListsState, fetchSelectors.isLoadingSelector);

export const isLoadingPublic = createSelector(
  selectFavoriteListsState,
  state => state.loadingPublic
);

export const getError = createSelector(selectFavoriteListsState, fetchSelectors.errorSelector);

export const shouldFetch = createSelector(
  selectFavoriteListsState,
  state => !state.loading && !state.fetchedAt && !state.error
);

export const shouldInitialFetch = createSelector(
  userSelectors.loaded,
  shouldFetch,
  (userInfoLoaded, shouldFetch) => userInfoLoaded && shouldFetch
);

export const getLists = createSelector(selectFavoriteListsState, state => state.byId);

export const getAllListIds = createSelector(selectFavoriteListsState, state => state.allIds);

export const getListsNormalized = createSelector(selectFavoriteListsState, state =>
  state.allIds.map(id => state.byId[id])
);

export const getAllWatchlists = createSelector(
  selectFavoriteListsState,
  state => state.allWatchlists
);

export const getAllListAccessRightLists = createSelector(selectFavoriteListsState, state =>
  Object.values(state.allWatchlists).filter(list => hasEditPermission(list.share_permission))
);

export const getTotal = createSelector(selectFavoriteListsState, state => state.total);

export const getQuery = createSelector(selectFavoriteListsState, state => state.query);

export const getSortBy = createSelector(selectFavoriteListsState, state => state.sortBy);

export const getGridColumns = (defaultColumns: ColumnType<any>[]) =>
  createSelector(getSortBy, getDefaultScore, (sorting, defaultOptxScore) =>
    sortColumns(defaultColumns, sorting, defaultOptxScore)
  );

export const getPageNumber = createSelector(selectFavoriteListsState, state => state.pageNumber);

export const getWatchlists = (lists: number[]) => {
  return createSelector(selectFavoriteListsState, state => {
    const allWatchlists = state.allWatchlists;

    return lists.map(id =>
      Object.values(allWatchlists).find(watchlist => watchlist.unique_id === id)
    );
  });
};

export const isCompleted = createSelector(selectFavoriteListsState, state => state.completed);
export const getPercentage = createSelector(selectFavoriteListsState, state => state.percentage);
export const getTitle = createSelector(selectFavoriteListsState, state => state.title);
export const isCancel = createSelector(selectFavoriteListsState, state => state.cancel);
export const showEmptyLists = createSelector(
  selectFavoriteListsState,
  state => state.showEmptyLists
);
