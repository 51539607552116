import React from 'react';
import { UpOutlined } from '@ant-design/icons';

interface PanelProps {
  isActive?: boolean;
}

const ExpandIcon: React.FC<PanelProps> = ({ isActive }) => {
  const rotate = isActive ? 180 : 0;
  const color = isActive ? '#1890FF' : '#D9D9D9';

  return <UpOutlined rotate={rotate} style={{ color }} />;
};

export default ExpandIcon;
