export const INIT_COMPANY_TOUCHES_DIALOG = 'INIT_COMPANY_TOUCHES_DIALOG';
export const TOGGLE_COMPANY_TOUCHES_DIALOG = 'TOGGLE_COMPANY_TOUCHES_DIALOG';

export const GET_TOUCHES_COMPANY_CONTACTS = 'GET_TOUCHES_COMPANY_CONTACTS';
export const GET_TOUCHES_COMPANY_CONTACTS_SUCCESS = 'GET_TOUCHES_COMPANY_CONTACTS_SUCCESS';
export const GET_TOUCHES_COMPANY_CONTACTS_FAIL = 'GET_TOUCHES_COMPANY_CONTACTS_FAIL';

export const GET_PSG_PARTICIPANTS = 'GET_PSG_PARTICIPANTS';
export const GET_PSG_PARTICIPANTS_SUCCESS = 'GET_PSG_PARTICIPANTS_SUCCESS';
export const GET_PSG_PARTICIPANTS_FAIL = 'GET_PSG_PARTICIPANTS_FAIL';

export const GET_TOUCHES_FILTERS = 'GET_TOUCHES_FILTERS';
export const GET_TOUCHES_FILTERS_SUCCESS = 'GET_TOUCHES_FILTERS_SUCCESS';
export const GET_TOUCHES_FILTERS_FAIL = 'GET_TOUCHES_FILTERS_FAIL';

export const ADD_COMPANY_TOUCHES = 'ADD_COMPANY_TOUCHES';
export const ADD_COMPANY_TOUCHES_SUCCESS = 'ADD_COMPANY_TOUCHES_SUCCESS';
export const ADD_COMPANY_TOUCHES_ERROR_VALIDATION = 'ADD_COMPANY_TOUCHES_ERROR_VALIDATION';
export const ADD_COMPANY_TOUCHES_FAIL = 'ADD_COMPANY_TOUCHES_FAIL';

export const CHANGE_MODAL_TITLE = 'CHANGE_MODAL_TITLE';

export const CHANGE_SAVE_BTN_STATUS = 'CHANGE_SAVE_BTN_STATUS';
export const CHANGE_SAVE_BTN_STATUS_BY_ADDITIONAL_FIELDS =
  'CHANGE_SAVE_BTN_STATUS_BY_ADDITIONAL_FIELDS';

export const EDIT_TOUCH = 'EDIT_TOUCH';
export const EDIT_TOUCH_FAIL = 'EDIT_TOUCH_FAIL';

export const GET_TOUCHES_FINANCIAL_FILTERS = 'GET_TOUCHES_FINANCIAL_FILTERS';
export const GET_TOUCHES_FINANCIAL_FILTERS_SUCCESS = 'GET_TOUCHES_FINANCIAL_FILTERS_SUCCESS';
export const GET_TOUCHES_FINANCIAL_FILTERS_FAIL = 'GET_TOUCHES_FINANCIAL_FILTERS_FAIL';

export const UPDATE_TOUCH_COMPANY_INFO_SUCCESS = 'UPDATE_TOUCH_COMPANY_INFO_SUCCESS';
export const UPDATE_TOUCH_COMPANY_INFO_FAIL = 'UPDATE_TOUCH_COMPANY_INFO_FAIL';
export const CLEAR_ADDITIONAL_FIELDS_ERROR = 'CLEAR_ADDITIONAL_FIELDS_ERROR';
export const SET_ADDITIONAL_FIELDS_ERROR = 'SET_ADDITIONAL_FIELDS_ERROR';
export const CHANGE_ADDITIONAL_FIELDS_ERROR_TEXT = 'CHANGE_ADDITIONAL_FIELDS_ERROR_TEXT';
