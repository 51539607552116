import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { Dictionary, forEach } from 'lodash';
// models
import { AddCompanyToWatchList, DeleteCompanyFromWatchList } from '@models/WatchList';
import { CompanyListsState, FetchListsExcludingCompanySuccessPayload } from './interfaces';
// redux
import * as actions from './actions';
import { fetchReducer, fetchFailReducer } from '../../feature/fetch/reducers';
import * as favoriteListsActions from '../lists/actions';
import * as favoriteListsActionsPopup from '../lists-popup/actions';

export const initialState: CompanyListsState = {
  byId: {},
  error: '',
  loading: false,
  fetchedAt: '',
};

// Fetch lists excluding company success.
const fetchListsExclusingCompanySuccessReducer: CaseReducer<
  CompanyListsState,
  PayloadAction<FetchListsExcludingCompanySuccessPayload>
> = (draftState, action) => {
  const { companyId, lists } = action.payload;

  draftState.loading = false;
  draftState.fetchedAt = new Date().toISOString();
  const listsExcludingCompany: Dictionary<boolean> = {};

  forEach(lists, list => {
    listsExcludingCompany[list.unique_id] = true;
  });

  draftState.byId[companyId] = listsExcludingCompany;
};

/**
 * Add company to favorite list success reducer.
 * @param draftState company lists state.
 * @param action action.
 */
const addCompanyToListSuccessReducer: CaseReducer<
  CompanyListsState,
  PayloadAction<AddCompanyToWatchList>
> = (draftState, action) => {
  const { companyId, watchLists } = action.payload;

  if (draftState.byId[companyId]) {
    watchLists.forEach(list => {
      delete draftState.byId[companyId][list.unique_id];
    });
  }
};

/**
 * Delete company from favorite list success.
 * @param draftState company lists state.
 * @param action action.
 */
const deleteCompanyFromListSuccessReducer: CaseReducer<
  CompanyListsState,
  PayloadAction<DeleteCompanyFromWatchList>
> = (draftState, action) => {
  const { companyId, listId } = action.payload;

  if (draftState.byId[companyId]) {
    draftState.byId[companyId][listId] = true;
  }
};

/**
 * By default data contains lists excluding company.
 */
const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.fetchListsExcludingCompany, fetchReducer)
    .addCase(actions.fetchListsExcludingCompanySuccess, fetchListsExclusingCompanySuccessReducer)
    .addCase(actions.fetchListsExcludingCompanyFail, fetchFailReducer)
    // add/delete company to/from list.
    .addCase(favoriteListsActions.addCompanyToListSuccess, addCompanyToListSuccessReducer)
    .addCase(favoriteListsActions.deleteCompanyFromListSuccess, deleteCompanyFromListSuccessReducer)
    .addCase(favoriteListsActionsPopup.addCompanyToListPopupSuccess, addCompanyToListSuccessReducer)
    .addCase(
      favoriteListsActionsPopup.deleteCompanyFromListPopupSuccess,
      deleteCompanyFromListSuccessReducer
    )
);

export default reducer;
