import { memo, useMemo } from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
// redux
import { selectors } from '@features/checklist-research-insights/state';
// components
import StatsLabel from './components/StatsLabel';
import VerifiedFitChart from './components/VerifiedFitChart';
import * as Styled from './StatsCards.styled';

const PSGFitAssessment = () => {
  const psgFitData = useSelector(selectors.cards.getPsgFit);
  const verifiedFit = psgFitData['Verified Fit'];
  const loading = useSelector(selectors.search.getLoading);

  const chartData = useMemo(() => {
    if (verifiedFit) {
      return [
        {
          label: 'verified',
          value: Number(verifiedFit!.slice(0, -1)),
        },
        {
          label: 'unverified',
          value: 100 - Number(verifiedFit!.slice(0, -1)),
        },
      ];
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifiedFit, loading]);

  return (
    <Styled.StatsCardsContainer title="PSG Fit Assessment" loading={loading}>
      <Row>
        {Object.keys(psgFitData!).map((item: string, index: number) => {
          if (item === 'Verified Fit') return null;

          let firstValue = null;
          let lastValue = null;

          if (psgFitData![item] !== null) {
            firstValue = psgFitData![item]?.split('|')[0];
            lastValue = psgFitData![item]?.split('|')[1];
          }

          return (
            <Col span={12} key={index}>
              <StatsLabel
                value={[firstValue, lastValue]}
                label={item.toUpperCase()}
                isSmall={index !== 0}
              />
            </Col>
          );
        })}
      </Row>
      <VerifiedFitChart data={chartData} verifiedFit={verifiedFit as string} />
    </Styled.StatsCardsContainer>
  );
};

export default memo(PSGFitAssessment);
