import React, { useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'antd';

// components
import SearchInput, { SearchInputProps } from '@components/common/form/input/SearchInput';
// redux
import { actions, selectors } from '@features/checklist-research-insights/state';

interface SearchProps extends Omit<SearchInputProps, 'onSearch' | 'value'> {}

const Search: React.FC<SearchProps> = ({ onFocus, onBlur, ...restProps }) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const inputRef = useRef<Input | null>(null);

  const defaultTableView = useSelector(selectors.ui.defaultTableView);

  const search = useCallback(
    (searchKey: string) => {
      dispatch(actions.filters.applyFilters({ searchKey }));
    },
    [dispatch]
  );

  const handleSearch = (searchKey: string) => {
    // close the dropdown by clicking on input.
    inputRef.current!.input?.click();
    search(searchKey);
    setQuery('');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  return (
    <SearchInput
      ref={inputRef}
      {...restProps}
      onChange={handleChange}
      onSearch={handleSearch}
      value={query}
      placeholder={`Search for ${
        defaultTableView === 'analyst' ? 'analysts' : 'companies'
      } by keyword`}
    />
  );
};

export default Search;
