/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-wrap-multilines */
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate';
import { useSelector } from 'react-redux';
import { Tag } from 'antd';
// models
import { CompanyProfile } from '@optx/models/Company';
import { ReferrerUrlOrigin } from '@optx/models/routes';
// constants
import { DEFAULT_CELL_VALUE, DEFAULT_NEXT_TOUCH } from '@constants/table/cells';
import globalConfig from '@optx/constants/config';
// utils
import { getCompanyProfileURL, stringifyReferrerUrlParam } from '@optx/utils/routes';
import { searchInNewTab } from '@optx/utils/search';
import { formatLastTouch } from '@optx/utils/touches';
import { getCompanyAddress } from '@optx/utils/company';
// redux
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import { selectors as filterSourcesSelectors } from '@redux/company/filters';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';
// components
import Icon from '@components/common/Icon';
import { PoweredByAITag } from '@optx/components/common/powered-by-ai-tag';
import { CompanyTagsOverflow, ListTagsOverflow } from '@optx/features/tags/overflow-tags';
import { MultipleDate } from '@components/common/MultipleDate';
import Styled from './CustomTooltip.styled';

interface CustomTooltipProps {
  company: CompanyProfile;
  pathName: string;
  children: ReactNode | ReactNode[];
  onVisibleChange?: (visible: boolean) => void;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  children,
  company,
  pathName,
  onVisibleChange,
}) => {
  const {
    raw_url: rawUrl,
    company_description: companyDescription,
    company_name: companyName,
    company_id: companyId,
    parentcompany: parentCompany,
    is_in_et: isOnEquityTouch,
    company_owner: companyOwner,
    last_touch_date: lastTouchDate,
    next_touch_date: nextTouchDate,
    street,
    city,
    state,
    country,
  } = company;

  const clearedFilter = useSelector(filterSelectors.getClearedFilter('advancedSearch'));
  const sources = useSelector(filterSourcesSelectors.getFiltersMap);
  const companyWatchLists =
    company.et_watchlists && company.regular_watchlists
      ? [...company.et_watchlists, ...company.regular_watchlists]
      : company.et_watchlists
      ? company.et_watchlists
      : company.regular_watchlists;

  const { advancedSearchPathname } = useIsAnalyst();

  const handleParentCompanyClick = () => {
    // some parent companies have ' dba ' in their names
    // in that case we only search for name after ' dba '
    // ex: 'Football1x2, Ltd. dba 1X2 Network'
    const [firstPart, secondPart] = company.parentcompany.split(' dba ');

    searchInNewTab(
      clearedFilter,
      sources,
      'query',
      secondPart || firstPart,
      {
        referrer: ReferrerUrlOrigin.parentCompanySearch,
      },
      advancedSearchPathname
    );
  };

  return (
    <Styled.Popover
      arrowPointAtCenter
      placement="right"
      onVisibleChange={onVisibleChange}
      title={
        <Styled.PopoverTitleWrapper>
          <Styled.PopoverTitle fullWidth={parentCompany === DEFAULT_CELL_VALUE}>
            {companyName}
          </Styled.PopoverTitle>
          <Link
            to={getCompanyProfileURL(companyId, stringifyReferrerUrlParam(pathName))}
            target="_blank"
          >
            <Icon iconName="open-in-new" />
          </Link>

          {parentCompany === 'unknown' ? (
            <Tag color="red" className="parent-company-tag">
              {parentCompany}
            </Tag>
          ) : (
            parentCompany !== DEFAULT_CELL_VALUE && (
              <Tag
                color="red"
                onClick={handleParentCompanyClick}
                className="parent-company-tag enabled-tag"
              >
                {parentCompany}
              </Tag>
            )
          )}
        </Styled.PopoverTitleWrapper>
      }
      content={
        <>
          {(street !== DEFAULT_CELL_VALUE ||
            city !== DEFAULT_CELL_VALUE ||
            state !== DEFAULT_CELL_VALUE ||
            country !== DEFAULT_CELL_VALUE) && (
            <Styled.PopoverContentDescription>
              <Styled.SubTitle>Address: </Styled.SubTitle>
              {getCompanyAddress(company, DEFAULT_CELL_VALUE)}
            </Styled.PopoverContentDescription>
          )}
          {rawUrl && (
            <Styled.PopoverContentDescription>
              <Styled.SubTitle>URL: </Styled.SubTitle>

              <a href={rawUrl} title={rawUrl} target="__blank">
                {rawUrl}
              </a>
            </Styled.PopoverContentDescription>
          )}
          {typeof isOnEquityTouch === 'boolean' && (
            <Styled.PopoverContentDescription>
              <Styled.SubTitle>In EquityTouch: </Styled.SubTitle>
              {isOnEquityTouch ? 'Yes' : 'No'}
            </Styled.PopoverContentDescription>
          )}
          {companyOwner && (
            <Styled.PopoverContentDescription>
              <Styled.SubTitle>Company Owner: </Styled.SubTitle>
              {companyOwner}
            </Styled.PopoverContentDescription>
          )}
          {lastTouchDate && (
            <Styled.PopoverContentDescription>
              <Styled.SubTitle>Last Touch Date: </Styled.SubTitle>
              {formatLastTouch(lastTouchDate, 'monthDayYear')}
            </Styled.PopoverContentDescription>
          )}
          {nextTouchDate && (
            <Styled.PopoverContentDescription>
              <Styled.SubTitle>Scheduled Touch Date: </Styled.SubTitle>
              {typeof nextTouchDate === 'string' ? (
                nextTouchDate
              ) : (
                <MultipleDate
                  dates={nextTouchDate}
                  emptyValue={DEFAULT_NEXT_TOUCH}
                  format={globalConfig.grid_date.DATE_FORMAT}
                />
              )}
            </Styled.PopoverContentDescription>
          )}
          {companyDescription && (
            <Styled.PopoverContentDescription isMarginItem>
              <Styled.SubTitle>Description: </Styled.SubTitle>
              {company.is_ai_powered && <PoweredByAITag />}
              <br />
              <Truncate lines={4} ellipsis={<span>...</span>}>
                {companyDescription}
              </Truncate>
            </Styled.PopoverContentDescription>
          )}
          <CompanyTagsOverflow company={company} isNewTab />
          <ListTagsOverflow tags={companyWatchLists} className="list-tags__custom-styling" />
        </>
      }
    >
      {/* Popover children must be an element that accepts events, not components, in this case we wrap it with span */}
      <span>{children}</span>
    </Styled.Popover>
  );
};

export default React.memo(CustomTooltip);
