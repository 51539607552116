import styled from 'styled-components';
import { Drawer as AntDrawer } from 'antd';

const Drawer = styled(AntDrawer)`
  .ant-drawer-header {
    display: flex;
    justify-content: space-between;
  }

  .ant-drawer-title {
    display: flex;
  }

  .ant-drawer-close {
    position: relative;
    padding: 0;
    line-height: 0;
  }

  .ant-drawer-body {
    padding: 0;
  }
`;

export const Styled = {
  Drawer,
};
