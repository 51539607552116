import { call, put, takeLatest, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary } from 'lodash';
import queryString from 'query-string';
import { Location } from 'history';
import { AxiosResponse } from 'axios';
// services
import NotificationService from '@services/NotificationService';
import { FilterService } from '@services/api';
// models
import { SearchPayloadRequest } from '@optx/models/api/contacts';
import { PreselectedFilter } from '@optx/models/filters';
import { SortByRule, SortRule } from '@models/table/sorting';
import { PageInfo } from '@optx/models/table/Pagination';
import Company from '@optx/models/Company';
import { SearchViewType } from '@optx/models/user';
import { SelectOption } from '@optx/models/Option';
// constants
import { COMPANY_DEFAULT_SORT } from '@optx/constants/table/sort/defaultSort';
import { FILTER_PATH_KEYWORDS } from '@optx/constants/sourcingOutReachFilters';
import { LONG_CARD_VIEW_DEFAULT_PAGE_SIZE } from '@optx/constants/pagination';
// redux
import { actions as searchKeyActions } from '@features/grid/searchkey';
import { selectors as routerSelectors } from '@redux/router';
import { getFiltersQuery } from '@redux/company/search/search/sagasReusable';
import { actions as userInformationActions } from '@redux/user/information';
import {
  selectors as paginationSelectors,
  actions as paginationActions,
} from '@features/grid/pagination';
import { actions as longCardPaginationActions } from '@features/grid/pagination-long-card';
import { selectors as searchSelectors, actions as searchActions } from '@features/grid/search';
import { actions as sortActions, selectors as sortSelectors } from '../sort';
import { selectors as viewTypeSelectors } from '@features/grid/view-type';
import { selectors as filterSelectors, actions as filterActions } from '../filters';
import { userInfoUpdateSaga } from '@features/grid/state/sagasReusable';
import { searchCompanies, searchCompaniesFail } from '@features/grid/search/state/sagasReusable';
import { getViewId } from '@optx/features/grid/view/state/selectors';
import { getListType } from '@optx/features/grid/search/state/selectors';

export function* resetFilterSettings() {
  const location: Location<unknown> = yield select(routerSelectors.getLocation);

  const splitedLocationPathName = location.pathname.split('/');

  if (!splitedLocationPathName.some(path => FILTER_PATH_KEYWORDS.includes(path))) {
    try {
      yield put(
        userInformationActions.updateUserSettings({
          sourcing_outreach_detail_sorting: '',
          sourcing_outreach_detail_filters: '',
          sourcing_outreach_detail_page_number: 1,
          sourcing_outreach_results_page_number: 1,
        })
      );
      yield put(filterActions.resetStoreFilter());
      yield put(searchKeyActions.setSearchKey({ data: '', gridKey: 'outreach' }));
      yield put(searchActions.shouldUpdateSourcingOutReachAnalystInfo(true));
    } catch (error) {}
  } else {
    yield put(searchActions.shouldUpdateSourcingOutReachAnalystInfo(false));
  }
}

export function* getSourcingOutReachFilters() {
  try {
    const res: AxiosResponse<any> = yield call(FilterService.companiesOutReachFilters);
    yield put(filterActions.fetchSourcingOutReachFiltersSuccess(res.data.sourcing_filters));
    const tableGridQuery: string = yield select(searchSelectors.getTableGridQuery('outreach'));

    const parsedGridQuery = queryString.parse(tableGridQuery);
    const analystIdFilter: [string | null, string | null] | SelectOption<string> | undefined =
      yield select(filterSelectors.getSourcingFiltersOptions('analyst_id'));
    const listOfColumnNames: [string | null, string | null] | SelectOption<string> | undefined =
      yield select(filterSelectors.getSourcingFiltersOptions('column_name'));

    yield put(
      filterActions.setDefaultSourcingOutReachFilters({
        tableGridQuery: parsedGridQuery,
        listOfAnalyst: analystIdFilter,
        listOfColumNames: listOfColumnNames,
      })
    );
  } catch (error) {
    const errorMessage = 'Failed to Fetch SourcingOutReach Filters!';
    NotificationService.error(errorMessage);
  }
}

export function* changeSortSaga(action: PayloadAction<SortRule<Company>>) {
  const sortBy = action.payload.sortBy;
  const gridKey = 'outreach';
  const id: number = yield select(getViewId(gridKey));
  const listType: string = yield select(getListType(gridKey));

  const analystIdFilter: [string | null, string | null] | SelectOption<string> | undefined =
    yield select(filterSelectors.getSourcingOptitonsFilterValues('analyst_id'));
  const columnNameSourcingOutReachFilter:
    | [string | null, string | null]
    | SelectOption<string>
    | undefined = yield select(filterSelectors.getSourcingOptitonsFilterValues('column_name'));
  const tableGridQuery: string = yield select(searchSelectors.getTableGridQuery(gridKey));
  const sourcingOutReachDateFilter: string = yield select(
    filterSelectors.getSourcingFilterOptionsDateValue
  );
  const filter: Dictionary<PreselectedFilter> = yield select(filterSelectors.getFilter);
  const searchKey: string = yield select(searchSelectors.getSearchKey(gridKey));
  const regularPagination: PageInfo = yield select(paginationSelectors.getPagination(gridKey));
  const view: SearchViewType = yield select(viewTypeSelectors.searchView('outreach'));

  const sourcingOutReachColumnName = (columnNameSourcingOutReachFilter as SelectOption)?.value;

  // @ts-ignore
  const listOfAnalystIds: number[] = analystIdFilter?.map(
    (list: { label: string; value: number }) => list.value
  );

  const pagination = {
    pageNumber: 1,
    pageSize: view === 'table' ? regularPagination.pageSize : LONG_CARD_VIEW_DEFAULT_PAGE_SIZE,
  };

  const customFilter = {
    ...filter,
    ddate: sourcingOutReachDateFilter,
  };

  const filterQuery: Dictionary<string | Array<number | string>> = yield call(
    getFiltersQuery,
    customFilter
  );

  const searchData: SearchPayloadRequest = {
    searchKey,
    analystId: listOfAnalystIds?.toString(),
    sourcingOutReachColumnName,
    tableGridQuery,
    filter: filterQuery,
    pagination: {
      ...pagination,
      pageNumber: 1,
    },
    sortBy,
    fromSavedList: id,
    listType,
  };

  try {
    const userSettings: { [key: string]: number } = yield call(
      userInfoUpdateSaga,
      gridKey,
      pagination,
      true,
      true
    );

    yield call(searchCompanies, searchData, gridKey, filter, false, undefined, userSettings);

    if (view === 'long-card') {
      yield put(
        longCardPaginationActions.changeLongCardPagination({
          gridKey: 'outreach',
          data: pagination,
        })
      );
    } else {
      yield put(
        paginationActions.changePaginationSuccess({ gridKey: 'outreach', data: pagination })
      );
    }
  } catch (error) {
    yield call(searchCompaniesFail, gridKey, error);
  }
}

export function* resertSortSaga() {
  const sortBy = COMPANY_DEFAULT_SORT;
  const gridKey = 'outreach';
  const tableGridQuery: string = yield select(searchSelectors.getTableGridQuery(gridKey));
  const sourcingOutReachDateFilter: string = yield select(
    filterSelectors.getSourcingFilterOptionsDateValue
  );
  const analystIdFilter: [string | null, string | null] | SelectOption<string> | undefined =
    yield select(filterSelectors.getSourcingOptitonsFilterValues('analyst_id'));
  const filter: Dictionary<PreselectedFilter> = yield select(filterSelectors.getFilter);
  const columnNameSourcingOutReachFilter:
    | [string | null, string | null]
    | SelectOption<string>
    | undefined = yield select(filterSelectors.getSourcingOptitonsFilterValues('column_name'));
  const searchKey: string = yield select(searchSelectors.getSearchKey(gridKey));
  const regularPagination: PageInfo = yield select(paginationSelectors.getPagination(gridKey));
  const view: SearchViewType = yield select(viewTypeSelectors.searchView('outreach'));

  const pagination = {
    pageNumber: 1,
    pageSize: view === 'table' ? regularPagination.pageSize : LONG_CARD_VIEW_DEFAULT_PAGE_SIZE,
  };

  const customFilter = {
    ...filter,
    ddate: sourcingOutReachDateFilter,
  };

  const filterQuery: Dictionary<string | Array<number | string>> = yield call(
    getFiltersQuery,
    customFilter
  );

  const sourcingOutReachColumnName = (columnNameSourcingOutReachFilter as SelectOption<string>)
    ?.value;

  //@ts-ignore
  const listOfAnalystIds: number[] = analystIdFilter?.map(
    (list: { label: string; value: number }) => list.value
  );

  const searchData: SearchPayloadRequest = {
    searchKey,
    tableGridQuery,
    analystId: listOfAnalystIds?.toString(),
    sourcingOutReachColumnName,
    filter: filterQuery,
    pagination: {
      ...pagination,
      pageNumber: 1,
    },
    sortBy,
  };

  try {
    yield call(searchCompanies, searchData, gridKey, filter, true);
    yield put(sortActions.resetSortSuccessAction());

    yield call(userInfoUpdateSaga, gridKey, pagination, false, true);

    if (view === 'long-card') {
      yield put(
        longCardPaginationActions.changeLongCardPagination({
          gridKey: 'outreach',
          data: pagination,
        })
      );
    } else {
      yield put(paginationActions.changePaginationSuccess({ gridKey, data: pagination }));
    }
  } catch (error) {
    yield call(searchCompaniesFail, gridKey, error);
  }
}

export function* multiSortSaga(
  action: PayloadAction<boolean, any, Array<SortByRule<any>> | undefined>
) {
  const isMultiSort = action.payload;
  const gridKey = 'outreach';
  const currentSorting: SortByRule<any>[] = yield select(sortSelectors.getSorting);

  const sortingObject: SortRule<any> = {
    sortBy: currentSorting,
  };

  try {
    // Only make the call to API if previous sort was changed to single sort
    if (!isMultiSort) {
      yield put(sortActions.changeSortAction(sortingObject));
    }
  } catch (error) {
    yield call(searchCompaniesFail, gridKey, error);
  }
}

export default function* companiesSaga() {
  // external
  yield takeLatest(filterActions.resetSessionFilter, resetFilterSettings);
  yield takeLatest(sortActions.changeSortAction, changeSortSaga);
  yield takeLatest(sortActions.multiSortAction, multiSortSaga);
  yield takeLatest(sortActions.resetSortAction, resertSortSaga);
}
