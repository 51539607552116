import { createFeatureAction } from '@optx/utils/feature';
import featureName from '../../featureName';
import * as types from './actionTypes';
import { EditDealTeam } from './interfaces';

export const addDealTeamMember = createFeatureAction<EditDealTeam>(
  featureName,
  types.ADD_COMPANY_DEAL_TEAM_MEMBER
);

export const addDealTeamMemberSuccess = createFeatureAction<EditDealTeam>(
  featureName,
  types.ADD_COMPANY_DEAL_TEAM_MEMBER_SUCCESS
);

export const addDealTeamMemberFail = createFeatureAction<string>(
  featureName,
  types.ADD_COMPANY_DEAL_TEAM_MEMBER_FAIL
);
