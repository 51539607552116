import React from 'react';
import { ColumnType } from 'antd/lib/table/interface';
import { CompanyWatchList } from '@optx/models/WatchList';
import {
  TableCellDate,
  TableCellActions,
  TableCellLink,
  TableCellName,
  TableCellDefault,
} from '../cells/defaultCells';
import { TableCellAssociatedCompany } from '../cells';

type WatchListKey = keyof CompanyWatchList;
interface WatchListColumnType<T = unknown> extends ColumnType<T> {
  dataIndex: WatchListKey | 'row_actions';
}

const cellUpdates: Array<WatchListColumnType<CompanyWatchList>> = [
  {
    dataIndex: 'title',
    title: 'List Name',
    render: (value, row, index) => <TableCellName value={value} row={row} index={index} />,
    sorter: true,
    width: 250,
  },
  {
    dataIndex: 'owner_name',
    title: 'Owner',
    render: (value, row, index) => <TableCellDefault value={value} row={row} index={index} />,
    sorter: true,
    width: 80,
  },
  {
    dataIndex: 'origin',
    title: 'Origin',
    render: (value, row, index) => <TableCellDefault value={value} row={row} index={index} />,
    sorter: true,
    width: 80,
  },
  {
    dataIndex: 'addon_company',
    title: 'Associated Company',
    render: (value, row, index) => (
      <TableCellAssociatedCompany value={value} row={row} index={index} />
    ),
    sorter: true,
    width: 90,
  },
  {
    dataIndex: 'date',
    title: 'Date Created',
    align: 'right',
    sorter: true,
    render: (value, row, index) => <TableCellDate value={value} row={row} index={index} />,
    width: 80,
  },
  {
    dataIndex: 'modified_at',
    title: 'Date Updated',
    align: 'right',
    sorter: true,
    render: (value, row, index) => <TableCellDate value={value} row={row} index={index} />,
    width: 80,
  },
  {
    dataIndex: 'count',
    title: '# of Companies',
    align: 'right',
    render: (value, row, index) => <TableCellLink value={value} row={row} index={index} />,
    width: 80,
  },
  {
    dataIndex: 'row_actions',
    title: 'Action',
    align: 'center',
    className: 'cell-actions',
    render: (value, row, index) => <TableCellActions value={value} row={row} index={index} />,
    width: 60,
  },
];

export default cellUpdates;
