import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

interface TooltipLabelProps {
  label: string;
  tooltip: string | null;
}

const TooltipLabel: React.FC<TooltipLabelProps> = ({ label, tooltip }) => {
  return (
    <>
      <span className="d-inline-block mr-2">{label}</span>
      {tooltip && (
        <Tooltip trigger="click" title={tooltip}>
          <InfoCircleOutlined />
        </Tooltip>
      )}
    </>
  );
};

export default TooltipLabel;
