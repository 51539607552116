import React, { useMemo } from 'react';
import { SelectComponentsConfig } from 'react-select';
import { SearchOutlined } from '@ant-design/icons';
// components
import { DropdownIndicator } from '../components';
import { Styled } from './SingleSelect.styled';
import { SingleSelectProps } from './interfaces';
import { Control } from './components/Control';

const defaultComponents: Partial<SelectComponentsConfig<any>> = {
  DropdownIndicator,
  Control,
};

const SingleSelect: React.FC<SingleSelectProps> = ({ options, components, ...restProps }) => {
  const selectComponents = useMemo(
    () => ({
      ...defaultComponents,
      ...(components || {}),
    }),
    [components]
  );

  return (
    // @ts-ignore for now until we add fix later for theme type.
    <Styled.Select
      options={options}
      components={selectComponents}
      {...restProps}
      // Leave isMulti at the end to not be overriden.
      isMulti={false}
    />
  );
};

SingleSelect.defaultProps = {
  placeholder: 'search',
  isClearable: false,
  hideSelectedOptions: false,
  SearchIcon: <SearchOutlined />,
};

export default SingleSelect;
