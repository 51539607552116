import { createSelector } from 'reselect';
import { AppState } from '@optx/redux/interfaces';
import createCachedSelector from 're-reselect';
import { ColumnType } from 'antd/lib/table';
// models
import { ViewOption } from '@optx/models/search';
import { CustomUIViewIds } from '@optx/models/user';
// constants
import {
  defaultOptxView,
  columnDisplayInitialState,
  customUIViewIds,
} from '@optx/constants/table/columnDisplay/company-search';
// utils
import { hasFilters } from '@optx/utils/search';
import { sortColumns } from '@optx/utils/table/sorting';
import { mapPreselectedFilters } from '@optx/utils/filters/preselectedValues';
import { getDefaultVisibleColumns } from '@optx/utils/columnsDefaultVisible';
// redux
import { getSavedSearchId } from '@redux/ui/modals/save-search/selectors';
import { getDefaultScore } from '@optx/redux/user/information/selectors';
import { selectors as fetchSelectors } from '../../feature/fetch';
import { selectors as filterSelectors } from '../filters';
import {
  getSavedSearchesSelector,
  getTitlesSelector,
  getAllViewsSelector,
} from '../../feature/saved-search/selectors';

// specific selectors
const selectCompanySavedSearchesState = (state: AppState) => state.companySavedSearches;

export const isLoading = createSelector(
  selectCompanySavedSearchesState,
  fetchSelectors.isLoadingSelector
);

// used for triggering a new fetch. it's value is changed
// directly from a property passed inside an action, like saveSearchSuccess
export const getShouldFetch = createSelector(
  selectCompanySavedSearchesState,
  fetchSelectors.getShouldFetch
);

export const getError = createSelector(
  selectCompanySavedSearchesState,
  fetchSelectors.errorSelector
);

export const getSavedSearches = createSelector(
  selectCompanySavedSearchesState,
  getSavedSearchesSelector
);

export const getGridColumns = (defaultColumns: ColumnType<any>[]) =>
  createSelector(getSortBy, getDefaultScore, (sorting, defaultOptxScore) =>
    sortColumns(defaultColumns, sorting, defaultOptxScore)
  );

export const getAllViews = createSelector(selectCompanySavedSearchesState, getAllViewsSelector);

export const getFilteredSavedSearches = createSelector(getSavedSearches, searches =>
  searches.filter(
    search =>
      hasFilters(search.search_criteria) ||
      search.searchKey ||
      customUIViewIds.includes(search.unique_id as CustomUIViewIds)
  )
);

export const filteredSavedSearchesCount = createSelector(
  getFilteredSavedSearches,
  searches => searches.length
);

export const getDefaultSearchId = createSelector(getSavedSearches, searches => {
  const defaultSearch = searches.find(search => search.is_default);

  if (defaultSearch) {
    return defaultSearch.unique_id;
  }

  return undefined;
});

export const shouldFetch = createSelector(
  selectCompanySavedSearchesState,
  state => !state.loading && !state.fetchedAt && !state.error
);

export const loaded = createSelector(
  selectCompanySavedSearchesState,
  state => !state.loading && !!state.fetchedAt
);

export const shouldInitialFetch = createSelector(
  filterSelectors.filtersLoaded,
  shouldFetch,
  (fetchedFilters, shouldFetch) => fetchedFilters && shouldFetch
);

export const getSearch = createCachedSelector(
  selectCompanySavedSearchesState,
  (state: AppState, searchId: number | string) => searchId,
  (state, searchId) => state.byId[searchId]
)((state, searchId) => searchId);

export const getAllSearch = createSelector(selectCompanySavedSearchesState, state =>
  state.allIds.map(id => state.byId[id])
);

export const getTitles = createSelector(selectCompanySavedSearchesState, getTitlesSelector);

export const getTotal = createSelector(
  selectCompanySavedSearchesState,
  state => state.allIds.length
);

export const getQuery = createSelector(selectCompanySavedSearchesState, state => state.query);

export const getSortBy = createSelector(
  selectCompanySavedSearchesState,
  state => state.sortBy || []
);

export const getPageNumber = createSelector(
  selectCompanySavedSearchesState,
  state => state.pageNumber
);

export const getSavedSearchesById = createSelector(
  selectCompanySavedSearchesState,
  state => state.allIds
);

// we don't have an OPTX Default saved search coming from api,
// so we make one and add default filters and columns
export const getViews = createSelector(
  getAllViews,
  filterSelectors.getCompanyFilters,
  (searches, filterSources) => {
    const preselectedFilters = mapPreselectedFilters(filterSources);
    defaultOptxView.filters = preselectedFilters;
    defaultOptxView.columns = getDefaultVisibleColumns(columnDisplayInitialState);

    let defaultView = defaultOptxView;
    const views: ViewOption[] = [...searches];

    views.unshift(defaultView);

    let isOptxDefault = true;

    searches.forEach(search => {
      if (search.is_default) {
        defaultView = search;
        isOptxDefault = false;
      }
    });

    if (isOptxDefault) {
      views[0].is_default = true;
    } else {
      views[0].is_default = false;
    }

    return { views, defaultView, defaultOptxView };
  }
);

export const getViewData = createSelector(getViews, getSavedSearchId, ({ views }, id) => {
  if (id) {
    return views.find(view => view.unique_id.toString() === id);
  }

  return undefined;
});
