import { Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

const Label = styled(Text)`
  font-size: 12px;
  font-weight: 600;
  display: block;
`;

const Value = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  color: #1890ff;
  display: block;
  margin-bottom: 5px;
`;

const Styles = {
  Label,
  Value,
};

export default Styles;
