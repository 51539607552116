import { reducer as histogramFiltersReducer } from '@features/histograms/histograms-addon-management/state/reducer';
import reducer from '@redux/company/addon-management/reducer';
import { combineReducers } from 'redux';
import { AddonManagementTab } from './interfaces';

const combinedReducer = combineReducers<AddonManagementTab>({
  search: reducer,
  histogramFilters: histogramFiltersReducer,
});

export default combinedReducer;
