import { createSelector } from 'reselect';
import { selectors as listsSearchSelectors } from '@redux/lists/search';
import { selectors as companyFiltersSelectors } from '@redux/company/filters';
import { selectors as saveSearchSelectors } from '@redux/company/saved-searches';
import { selectors as userListSelectors } from '@redux/user/user-list';
import { selectors as equityTouchSelectors } from '@redux/company/equity-touch';
import { selectors as listDetailsSelectors } from '@redux/lists/details';
import { selectors as searchCountSelectors } from '@features/grid/search-count';
import { isLoading as searchIsLoading } from '@features/grid/search/state/selectorsReusable';

export const isLoading = (isSourceScrub: boolean) =>
  createSelector(
    companyFiltersSelectors.isLoading,
    saveSearchSelectors.isLoading,
    userListSelectors.isShareLoading,
    equityTouchSelectors.isLoading,
    listsSearchSelectors.loadingHistograms,
    searchIsLoading('lists'),
    listDetailsSelectors.isLoading,
    searchCountSelectors.getSearchCountLoading(isSourceScrub ? 'sslists' : 'watchlists'),
    (
      loadingFilters,
      savingSearch,
      userManagement,
      equityTouch,
      loadingHistograms,
      listsSearchSelectors,
      listDetailsSelectors,
      searchCountSelectors
    ) =>
      loadingFilters ||
      savingSearch ||
      userManagement ||
      equityTouch ||
      loadingHistograms ||
      listsSearchSelectors ||
      listDetailsSelectors ||
      searchCountSelectors
  );

export const listIsLoading = createSelector(
  companyFiltersSelectors.isLoading,
  saveSearchSelectors.isLoading,
  userListSelectors.isShareLoading,
  equityTouchSelectors.isLoading,
  listsSearchSelectors.loadingHistograms,
  searchIsLoading('lists'),
  listDetailsSelectors.isLoading,
  (
    loadingFilters,
    savingSearch,
    userManagement,
    equityTouch,
    loadingHistograms,
    listsSearchSelectors,
    listDetailsSelectors
  ) =>
    loadingFilters ||
    savingSearch ||
    userManagement ||
    equityTouch ||
    loadingHistograms ||
    listsSearchSelectors ||
    listDetailsSelectors
);

export const saveSearchLoading = createSelector(
  saveSearchSelectors.isLoading,
  searchIsLoading('lists'),
  (savingSearch, searchSelectors) => savingSearch || searchSelectors
);
