import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
// models
import { CompanyTabLists } from '@optx/models/Company';
import {
  AddCompanyToWatchList,
  DeleteCompanyFromWatchList,
  CompanyWatchList,
} from '@optx/models/WatchList';
import { UserInformation } from '@optx/models/user';
import {
  CompanyTabListsState,
  CompanyTabListPaginationState,
  CompanyTabListResponse,
} from '@redux/company/lists/interfaces';
// constants
import { COMPANY_TAB_LISTS_DEFAULT_SORT } from '@constants/table/sort/defaultSort';
// utils
import { parseSorting } from '@optx/utils/filters/parseSorting';
// redux
import * as favoriteListsActions from '@optx/redux/favorite-lists/lists/actions';
import * as favoriteListsActionsPopup from '@optx/redux/favorite-lists/lists-popup/actions';
import { actions as userInformationActions } from '@redux/user/information';
import { fetchReducer, fetchFailReducer } from '@redux/feature/fetch/reducers';
import * as actions from './actions';

const initialState: CompanyTabListsState = {
  error: '',
  loading: false,
  data: [],
  fetchedAt: '',
  hasMore: false,
  pagination: {
    pageNumber: 1,
    pageSize: 50,
    sortBy: COMPANY_TAB_LISTS_DEFAULT_SORT,
  },
};

const companyTabListsSuccessReducer: CaseReducer<
  CompanyTabListsState,
  PayloadAction<CompanyTabListResponse, any, CompanyTabListPaginationState>
> = (draftState, action) => {
  draftState.loading = false;

  if (action.payload.page === 1) {
    draftState.data = action.payload.lists;
  }

  if (action.payload.page !== 1) {
    draftState.data = [...draftState.data, ...action.payload.lists];
  }

  draftState.fetchedAt = new Date().toISOString();
  draftState.pagination.pageNumber = action.payload.page;
  draftState.hasMore =
    action.payload.page * draftState.pagination.pageSize < action.payload.total_list_count;
  draftState.pagination.sortBy = action.meta.sortBy;
};

const addCompanyToListSuccessReducer: CaseReducer<
  CompanyTabListsState,
  PayloadAction<AddCompanyToWatchList>
> = (draftState, action) => {
  const { watchLists } = action.payload;

  const newWatchlists = watchLists
    .map(list => {
      const newList: CompanyTabLists = {
        unique_id: list.unique_id,
        title: list.title,
        owner_name: list.owner_name,
        origin: 'OPTX',
        list_type: 'Watchlist',
        count: list.count,
        clickable: true,
        created_at: list.date,
        modified_at: list.modified_at,
      };

      return newList;
    })
    .concat(draftState.data);

  draftState.data = newWatchlists;
};

const deleteCompanyToListSuccessReducer: CaseReducer<
  CompanyTabListsState,
  PayloadAction<DeleteCompanyFromWatchList>
> = (draftState, action) => {
  const { listId } = action.payload;

  const index = draftState.data.findIndex(item => item.unique_id === listId);

  if (index !== -1) {
    draftState.data.splice(index, 1);
  }
};

const clearCompanyTabList: CaseReducer<CompanyTabListsState> = draftState => {
  draftState.data = [];
  draftState.pagination = initialState.pagination;
};

const createCompanyToListSuccessReducer: CaseReducer<
  CompanyTabListsState,
  PayloadAction<{
    companyId: number;
    list: CompanyWatchList;
  }>
> = (draftState, action) => {
  const { list } = action.payload;

  draftState.data.unshift({
    ...list,
    origin: list.origin as string,
    list_type: 'Watchlist',
    clickable: true,
    created_at: new Date().toISOString(),
  });
};

export const fetchUserInformationSuccessReducer: CaseReducer<
  CompanyTabListsState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const sorting = action.payload.settings.session_settings?.profile_lists_tab_sorting;

  if (sorting) {
    const { sortBy } = parseSorting(sorting);
    draftState.pagination.sortBy = sortBy;
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.getCompanyTabLists, fetchReducer)
    .addCase(actions.clearCompanyTabList, clearCompanyTabList)
    .addCase(actions.getCompanyTabListsSuccess, companyTabListsSuccessReducer)
    .addCase(actions.getCompanyTabListsFail, fetchFailReducer)
    // Company Profile
    .addCase(favoriteListsActions.addCompanyToListSuccess, addCompanyToListSuccessReducer)
    .addCase(favoriteListsActions.deleteCompanyFromListSuccess, deleteCompanyToListSuccessReducer)
    .addCase(favoriteListsActions.createFavoriteListSuccess, createCompanyToListSuccessReducer)
    .addCase(favoriteListsActionsPopup.addCompanyToListPopupSuccess, addCompanyToListSuccessReducer)
    .addCase(
      favoriteListsActionsPopup.deleteCompanyFromListPopupSuccess,
      deleteCompanyToListSuccessReducer
    )
    .addCase(
      favoriteListsActionsPopup.createFavoriteListPopupSuccess,
      createCompanyToListSuccessReducer
    )
    // Sorting
    .addCase(actions.sortCompanyTabLists, fetchReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
);

export default reducer;
