import React from 'react';

interface TimelineIntervalLabelProps {
  count?: number;
  title?: string;
  renderTitle?: () => React.ReactNode;
  renderCount?: () => React.ReactNode;
}

/**
 * Generate count lablel.
 * @param count count value.
 * @param renderCount count render function.
 */
const getCountLabel = (count: number | undefined, renderCount?: () => React.ReactNode) => {
  if (renderCount) {
    return renderCount();
  }

  return `${count} ${count === 1 ? 'touch' : 'touches'}`;
};

const TimelineIntervalLabel: React.FC<TimelineIntervalLabelProps> = ({
  title,
  renderTitle,
  count,
  renderCount,
}) => {
  const Label = renderTitle ? (
    renderTitle()
  ) : (
    <span className="touch-timeline-interval__title">{title}</span>
  );

  return (
    <span className="touch-timeline-interval">
      {Label}
      <span className="touch-timeline-interval__count">{getCountLabel(count, renderCount)}</span>
    </span>
  );
};

export default TimelineIntervalLabel;
