import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dictionary } from 'lodash';
// models
import { GridKeys } from '@optx/models/grid';
import { PreselectedFilter } from '@optx/models/filters';
// utils
import { normalizeFilter } from '@optx/utils/filters/filterHelpers';
// redux
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import { selectors as listsSearchSelectors } from '@redux/lists/search/search';
import { selectors as searchSelectors } from '@features/grid/search';
import { actions as searchActions } from '@features/grid/search';
import { selectors as viewSelectors } from '@features/grid/view';
// components
import SecondaryQuickFiltersPanel from '@components/feature/secondary-quick-filters/SecondaryQuickFiltersPanel';
import ExportToCSV from './Header/FilterHeader/CompanySearchDropdown/SearchMenu/ExportToCSV';
import ShowFilterButton from '@optx/features/grid/filter/components/ShowFilterButton';

const SecondaryQuickFiltersContainer: React.FC = () => {
  const dispatch = useDispatch();

  const { pathname } = window.location;
  const gridKey = (pathname.includes('ss-lists') ? 'sslists' : 'watchlists') as GridKeys;

  const filter = useSelector(filterSelectors.getFilter(gridKey));
  const histograms = useSelector(listsSearchSelectors.selectHistograms);
  const keySearch = useSelector(searchSelectors.getSearchKey('lists'));
  const options = useSelector(filterSelectors.getTags(gridKey));
  const view = useSelector(viewSelectors.getView(gridKey));

  // call /search query
  const onSearch = useCallback(
    (searchKey: string, filter: Dictionary<PreselectedFilter>) => {
      const normalizedFilter = normalizeFilter(filter);
      dispatch(
        searchActions.searchCompanies({
          gridKey: 'lists',
          data: { searchKey, filter: normalizedFilter, shouldResetPageNumber: true },
        })
      );
    },
    [dispatch]
  );

  return (
    <SecondaryQuickFiltersPanel
      onSearch={onSearch}
      filter={filter}
      histograms={histograms}
      keySearch={keySearch}
      options={options}
      viewId={view.id}
    >
      <ShowFilterButton gridKey={gridKey} />
      <ExportToCSV />
    </SecondaryQuickFiltersPanel>
  );
};

export default React.memo(SecondaryQuickFiltersContainer);
