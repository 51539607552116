import { Row as AntRow } from 'antd';
import styled from 'styled-components';

const Row = styled(AntRow)`
  overflow: hidden;
`;

export default {
  Row,
};
