import React from 'react';
import { Tag, Tooltip, Col } from 'antd';
// models
import { CompanyUserContact } from '@models/api/contacts';
import Styled from './TableCellName.styled';

const TableCellName = (value: string, record: CompanyUserContact, index: number) => {
  return (
    <Styled.RowWrapper justify="space-between">
      <Col>
        <Tooltip title={value}>{value}</Tooltip>
      </Col>
      <Col>
        {record.is_edited && <Tag color="default">edited</Tag>}
        {/* to update when api will send a boolean instead of a string */}
        {/* @ts-ignore */}
        {record.primary_contact !== 'False' && record.primary_contact !== false && (
          <Tag color="blue">Primary</Tag>
        )}
      </Col>
    </Styled.RowWrapper>
  );
};

export default TableCellName;
