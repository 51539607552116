import { call, put, takeLatest, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
// models
import { CompanyNotification } from '@optx/models/Company';
// services
import NotificationService from '@optx/services/NotificationService';
import { UserService } from '@optx/services/api';
// utils
import { preProcessSearch } from '@optx/utils/search';
// local
import * as models from './interfaces';
import * as searchNotesActions from './actions';
import { getSearchQuery } from './selectors';

export function* searchCompanyNotesSaga(action: PayloadAction<models.NotesSearchQuery>) {
  let queryData = action.payload;

  if (!queryData) {
    queryData = (yield select(getSearchQuery)) as models.NotesSearchQuery;
  }

  try {
    const query = { ...queryData, query: preProcessSearch(queryData.query || '') };
    const res: AxiosResponse<CompanyNotification[]> = yield call(UserService.searchNotes, query);

    if (res.data) {
      yield put(searchNotesActions.searchNotesSuccess(res.data, query));
    } else {
      const errorMessage = 'Search notes fail!';
      yield put(searchNotesActions.searchNotesFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = 'Search notes fail, Server error!';
    yield put(searchNotesActions.searchNotesFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* updateNoteSaga(action: PayloadAction<CompanyNotification, any, string>) {
  const note = action.payload;
  const text = action.meta;

  try {
    // @ts-ignore
    const res = yield call(UserService.updateCompanyNote, note, text);

    if (res.data) {
      yield put(searchNotesActions.updateNoteSuccess(note, text));
    } else {
      const errorMessage = 'Update note fail!';
      yield put(searchNotesActions.updateNoteFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = 'Update note fail, Server error!';
    yield put(searchNotesActions.updateNoteFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* searchNotesSaga() {
  yield takeLatest(searchNotesActions.searchNotes, searchCompanyNotesSaga);
  yield takeLatest(searchNotesActions.updateNote, updateNoteSaga);
}
