import React from 'react';
import { Col, Row, Typography } from 'antd';
// models
import { CompanyProfile } from '@optx/models/Company';
// constants
import { NUMBER_FORMAT } from '@optx/constants/format/number';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { abbreviateValue, roundNumber } from '@optx/utils/number';
import { formatDate, formatMoney } from '@optx/utils/format';
// components
import CompanySourceDescriptions from '@optx/components/pages/CompanyProfile/ProfileBanner/CompanySourceDescriptions';
import CompanyTagsCollapsable from '@optx/features/tags/overflow-tags/CompanyTagsCollapsable';
import { ListTagsCollapsable } from '@optx/features/tags/overflow-tags';

// styles
import Styles from './OPTXInsights.styles';

const { Text } = Typography;

interface OPTXInsightsProps {
  companyProfile: CompanyProfile;
}

const OPTXInsights: React.FC<OPTXInsightsProps> = ({ companyProfile }) => {
  const companyProfileWatchLists =
    companyProfile.et_watchlists && companyProfile.regular_watchlists
      ? [...companyProfile.et_watchlists, ...companyProfile.regular_watchlists]
      : companyProfile.et_watchlists
      ? companyProfile.et_watchlists
      : companyProfile.regular_watchlists;

  return (
    <>
      <Row justify="space-between" gutter={[0, 16]}>
        <Col>
          <Styles.Value>
            {roundNumber(companyProfile.capital_raised, `$${NUMBER_FORMAT}`).toUpperCase() ||
              DEFAULT_EMPTY_VALUE}
          </Styles.Value>
          <Styles.Label>TOTAL RAISED</Styles.Label>
        </Col>
        <Col>
          <Styles.Value>
            {formatDate(companyProfile.raise_date) || DEFAULT_EMPTY_VALUE}
          </Styles.Value>
          <Styles.Label>LAST FUNDING DATE</Styles.Label>
        </Col>
        <Col>
          <Styles.Value>{abbreviateValue(companyProfile.num_employees)}</Styles.Value>
          <Styles.Label>TOTAL # OF EMPLOYEES</Styles.Label>
        </Col>
        <Col>
          <Styles.Value>
            {companyProfile.emp_growth_percent !== null
              ? companyProfile.emp_growth_percent
              : DEFAULT_EMPTY_VALUE}
          </Styles.Value>
          <Styles.Label>Y/Y EMPLOYEE GROWTH %</Styles.Label>
        </Col>
        <Col>
          <Styles.Value>
            {companyProfile.sw_website_rank_per_country || DEFAULT_EMPTY_VALUE}
          </Styles.Value>
          <Styles.Label>US WEBSITE RANK</Styles.Label>
        </Col>
      </Row>
      <Row gutter={[0, 4]}>
        <CompanyTagsCollapsable company={companyProfile} />
        {companyProfileWatchLists && <ListTagsCollapsable tags={companyProfileWatchLists} />}
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={5}>
          <Text type="secondary" strong>
            Company Owner
          </Text>
        </Col>
        <Col>
          <Text strong>{companyProfile.company_owner || DEFAULT_EMPTY_VALUE}</Text>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={5}>
          <Text type="secondary" strong>
            CEO
          </Text>
        </Col>
        <Col>
          <Text strong>{companyProfile.current_ceo || DEFAULT_EMPTY_VALUE}</Text>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={5}>
          <Text type="secondary" strong>
            Stage
          </Text>
        </Col>
        <Col>
          <Text strong>{companyProfile.stage || DEFAULT_EMPTY_VALUE}</Text>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={5}>
          <Text type="secondary" strong>
            Revenue
          </Text>
        </Col>
        <Col>
          <Text strong>
            {formatMoney(companyProfile.last_rev_update_amount) || DEFAULT_EMPTY_VALUE}
          </Text>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={5}>
          <Text type="secondary" strong>
            Crunchbase Rank
          </Text>
        </Col>
        <Col span={5}>
          <Text strong>{companyProfile.cb_rank || DEFAULT_EMPTY_VALUE}</Text>
        </Col>
      </Row>
      <Row gutter={[0, 16]}>
        <CompanySourceDescriptions company={companyProfile} />
      </Row>
    </>
  );
};

export default OPTXInsights;
