import { CompanyWithContacts } from '@features/bulk-actions/salesloft/state/interfaces';
/* eslint-disable no-param-reassign */
import { createSelector } from 'reselect';
import { selectors as localSelectors } from '../../salesloft';
import { selectFeatureState } from './selectFeature';

const salesloftState = createSelector(selectFeatureState, state => {
  return state.salesloft;
});

// slice selectors
const salesloftBulkCompanies = createSelector(salesloftState, localSelectors.companies);
const salesloftBulkContacts = createSelector(salesloftState, localSelectors.contacts);
const results = createSelector(salesloftState, localSelectors.results);
const isCompleted = createSelector(salesloftState, localSelectors.isCompleted);
const inProgress = createSelector(salesloftState, localSelectors.inProgress);
const cancel = createSelector(salesloftState, localSelectors.cancel);
const modalVisible = createSelector(salesloftState, localSelectors.modalVisible);
const percentageCompleted = createSelector(salesloftState, localSelectors.getPercentageCompleted);
const getCadenceId = createSelector(salesloftState, localSelectors.getCadenceId);
const getAttentionCount = createSelector(salesloftBulkCompanies, salesloftBulkCompanies => {
  const count = salesloftBulkCompanies.reduce((acc: number, company: CompanyWithContacts) => {
    const hasPrimaryContact = company.contacts?.some(contact => contact.primary_contact);
    acc = hasPrimaryContact ? acc : acc + 1;

    return acc;
  }, 0);

  return count;
});
const successfulCount = createSelector(results, results => {
  const successCompanies = results.reduce((acc: number, item) => {
    if (item.contacts.every(contact => contact.sl_sync_status)) {
      acc += 1;
    }

    return acc;
  }, 0);

  return successCompanies;
});
export default {
  salesloftBulkCompanies,
  salesloftBulkContacts,
  results,
  getCadenceId,
  percentageCompleted,
  getAttentionCount,
  successfulCount,
  inProgress,
  isCompleted,
  modalVisible,
  cancel,
};
