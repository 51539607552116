import { createAction } from '@reduxjs/toolkit';
import { CompanyTabListPaginationState, CompanyTabListResponse } from './interfaces';
import * as types from './types';

export const getCompanyTabLists = createAction<CompanyTabListPaginationState | undefined>(
  types.GET_COMPANY_LISTS
);

export const getCompanyTabListsSuccess = createAction(
  types.GET_COMPANY_LISTS_SUCCESS,
  (companyTabLists: CompanyTabListResponse, searchPayload: CompanyTabListPaginationState) => ({
    payload: companyTabLists,
    meta: searchPayload,
  })
);

export const sortCompanyTabLists = createAction<CompanyTabListPaginationState>(
  types.SORT_COMPANY_LISTS
);

export const getCompanyTabListsFail = createAction<string>(types.GET_COMPANY_LISTS_FAIL);

export const clearCompanyTabList = createAction(types.CLEAR_COMPANY_LİSTS);
