import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
// models
import { EmailStatistics } from '@optx/models/feature/analystsLeaderboard/EmailStatistics';
// services
import { AnalystService } from '@services/api';
// Local
// redux
import { AxiosResponse } from 'axios';
import * as actions from '../actions';
import * as selectors from '../selectors';
import { UpdateValuePayload, FilterMeta } from '../filters/interfaces';

export function* fetchEmailStatistics() {
  try {
    yield put(actions.emailStatistics.fetch());
    const query: string = yield select(selectors.filters.getCommonFiltersQuery);
    const res: AxiosResponse<EmailStatistics> = yield call(
      AnalystService.getEmailStatistics,
      query
    );

    yield put(actions.emailStatistics.fetchSuccess(res.data));
  } catch (e: any) {
    yield put(actions.emailStatistics.fetchFail('Error fetching Email Statistics!'));
  }
}

/**
 * Call fetch email only when filter is common.
 * @param filterKey
 */
export function* commonFilterChangeSaga(filterKey: string) {
  const isCommonFilter: boolean = yield select(state =>
    selectors.filters.isCommonFilter(state, filterKey)
  );

  if (isCommonFilter) {
    yield call(fetchEmailStatistics);
  }
}

export function* clearFilterSaga(action: PayloadAction<FilterMeta>) {
  const { filterKey } = action.payload;
  yield call(commonFilterChangeSaga, filterKey);
}

export function* updateValueSaga(action: PayloadAction<UpdateValuePayload>) {
  const { filterKey } = action.payload;
  yield call(commonFilterChangeSaga, filterKey);
}

export default function* emailStatisticsSagas() {
  yield takeLatest(actions.filters.fetchSuccess, fetchEmailStatistics);
  yield takeLatest(actions.filters.clearAllFilters, fetchEmailStatistics);
  yield takeLatest(actions.filters.clearFilter, clearFilterSaga);
  yield takeLatest(actions.filters.updateValue, updateValueSaga);
}
