import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
// redux
import { selectors as searchSelectors } from '@redux/lists/search/search';
import { actions as sortActions } from '@redux/lists/search/sort';
import { actions as searchActions } from '@features/grid/search';

const ToggleSortType = ({ onClick, ...restProps }: MenuItemProps) => {
  const dispatch = useDispatch();
  const isMultiSort = useSelector(searchSelectors.isMultiSort);
  const sorting = useSelector(searchSelectors.getSorting);

  const multiSort = useCallback(() => {
    if (!isMultiSort) {
      const tableElement = document.querySelector('.ant-table-body');
      tableElement?.scrollTo(0, 0);
    }

    dispatch(sortActions.multiSortAction(!isMultiSort, sorting));
  }, [dispatch, isMultiSort, sorting]);

  const resetCancelSearch = () => {
    // reset cancelSearch onBlur
    dispatch(
      searchActions.hideCancelSearch({
        gridKey: 'lists',
        data: false,
      })
    );
  };

  return (
    <Menu.Item onClick={multiSort} onBlur={resetCancelSearch} {...restProps}>
      {isMultiSort ? 'Enable Single Sort' : 'Enable Multi Sort'}
    </Menu.Item>
  );
};

export default ToggleSortType;
