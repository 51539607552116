import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// utils
import { getSourceScrubListURL } from '@optx/utils/routes';
// redux
import { loadCompanyList } from '@redux/lists/details/actions';
import { hideCancelSearch } from '@features/grid/search/state/actions';
import { selectors as searchSelectors } from '@features/grid/search';

const useSourceScrubLists = (): {
  handleSourceScrubListsResults: (id: string) => void;
} => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isInitialSearch: boolean = useSelector(searchSelectors.isInitialSearch('lists'));

  const handleSourceScrubListsResults = (id: string) => {
    history.push(getSourceScrubListURL(id));
    dispatch(hideCancelSearch({ gridKey: 'lists', data: true }));

    if (!isInitialSearch) {
      dispatch(loadCompanyList({ id, isSourceScrub: true }));
    }
  };

  return { handleSourceScrubListsResults };
};

export default useSourceScrubLists;
