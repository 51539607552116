export const LOAD_COMPANY_LIST = 'lists/LOAD_COMPANY_LIST';
export const LOAD_COMPANY_LIST_SUCCESS = 'lists/LOAD_COMPANY_LIST_SUCCESS';

export const FILTER_COMPANY_LIST = 'lists/FILTER_COMPANY_LIST';
export const FILTER_COMPANY_LIST_SUCCESS = 'lists/LIST_FILTER_COMPANY_LIST_SUCCESS';

export const CLEAR_COMPANY_FILTER = 'lists/CLEAR_COMPANY_FILTER';
export const DELETE_COMPANY_IN_LIST = 'lists/DELETE_COMPANY_IN_LIST';

export const FILTER_CSV_COMPANY_ERROR_LIST = 'FILTER_CSV_COMPANY_ERROR_LIST';

export const LOAD_COMPANY_LIST_DETAILS = 'lists/LOAD_COMPANY_LIST_DETAILS';
export const LOAD_COMPANY_LIST_DETAILS_SUCCESS = 'lists/LOAD_COMPANY_LIST_DETAILS_SUCCESS';
export const LOAD_COMPANY_LIST_DETAILS_FAILURE = 'lists/LOAD_COMPANY_LIST_DETAILS_FAILURE';
export const LOAD_COMPANY_LIST_DETAILS_RESET = 'lists/LOAD_COMPANY_LIST_DETAILS_RESET';

export const RESET_LOADING_STATE = 'lists/RESET_LOADING_STATE';
