import React, { useMemo } from 'react';
import { Radio } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { RadioChangeEvent } from 'antd/lib/radio';
import styled from 'styled-components';
// models
import { DateRangeOption } from '@optx/models/Option';
// utils
import { formatDateInterval } from '@optx/utils/format';
// Local
// components
import RadioButtonPicker from './RadioButtonPicker';

interface SingleSelectDateRangesProps {
  onChange: (option: DateRangeOption) => void;
  value: [string | null, string | null];
  data: Array<DateRangeOption>;
}

const StyledCalendarIcon = styled(CalendarOutlined)`
  margin-right: 6px;
`;

const SingleSelectDateRanges: React.FC<SingleSelectDateRangesProps> = ({
  data,
  value,
  onChange,
}) => {
  const lastIndex = data.length - 1;

  const handleChange = (event: RadioChangeEvent) => {
    const index = event.target.value;
    const option = data[index];

    // Don't call change action when clicking on date picker toggler.
    if (index === data.length - 1) {
      return;
    }

    onChange(option);
  };

  const selectedValue = useMemo(() => {
    const index = data.findIndex(option => option.start === value[0] && option.end === value[1]);

    return index === -1 ? data.length - 1 : index;
  }, [value, data]);

  return (
    <Radio.Group onChange={handleChange} value={selectedValue}>
      {data.map((option, index) => {
        const customIsSelected = index === selectedValue;
        const [start, end] = value;
        const label = customIsSelected
          ? formatDateInterval(start!, end!, { prefix: option.label })
          : option.label;

        if (lastIndex === index) {
          return (
            <RadioButtonPicker
              onChange={onChange}
              option={option}
              key={index}
              value={index}
              style={{ position: 'relative' }}
            >
              <StyledCalendarIcon />
              {label}
            </RadioButtonPicker>
          );
        }

        return (
          <Radio.Button key={index} value={index}>
            {label}
          </Radio.Button>
        );
      })}
    </Radio.Group>
  );
};

export default SingleSelectDateRanges;
