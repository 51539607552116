import { createAction } from '@reduxjs/toolkit';
// models
import { types } from '../types';
import { InsightsDataState } from '../interfaces';

export const fetchInsightsStats = createAction(types.insights.FETCH_INSIGHTS_STATS);
export const fetchInsightsStatsSuccess = createAction<InsightsDataState>(
  types.insights.FETCH_INSIGHTS_STATS_SUCCESS
);
export const fetchInsightsStatsFail = createAction<string>(
  types.insights.FETCH_INSIGHTS_STATS_FAIL
);
