import React from 'react';
import { Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// redux
import {
  actions as viewTypeActions,
  selectors as viewTypeSelectors,
} from '@features/grid/view-type';
//constants
import { LONG_CARD_TITLE } from '@optx/constants/titles';
//  components;
import ToggleLongCardView from '@optx/components/common/long-card/ToggleLongCardView';

const ToggleLongCardViewContainer = () => {
  const dispatch = useDispatch();
  const searchView = useSelector(viewTypeSelectors.searchView('outreach'));

  const toggleLongCardView = () => {
    dispatch(viewTypeActions.toggleSearchView({ gridKey: 'outreach', data: 'long-card' }));
  };

  const active = searchView === 'long-card';

  return (
    <Tooltip title={LONG_CARD_TITLE}>
      <ToggleLongCardView color="white" onClick={toggleLongCardView} active={active} />
    </Tooltip>
  );
};

export default React.memo(ToggleLongCardViewContainer);
