import { Card } from 'antd';
import styled from 'styled-components';
import { CardProps } from 'antd/lib/card';

const CardPannelWrapper = styled<React.FC<CardProps>>(Card)`
  &.no-company {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 61px;
  }
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  .card-header-row {
    margin-bottom: 0px;
  }

  .ant-card-body {
    padding: 0px;
  }

  .extension-tabs {
    .ant-tabs-nav-wrap {
      justify-content: center;

      .ant-tabs-nav-list {
        width: 98%;
      }
    }
    .profile-chart-card {
      .ant-card-head-title {
        flex: 2;
      }
      .ant-card-extra {
        flex: 3;

        > div {
          flex: 1;
          display: flex;
          gap: 24px;
          justify-content: flex-end;

          .kpi,
          .score-trending {
            margin: 0px;

            .score-trending__label,
            .kpi__label {
              font-size: 10px;
              font-weight: 600;
              color: #334d6e;
            }
            .trending-value trending-value--down,
            .kpi__value {
              font-size: 14px;
              font-weight: 600;
            }
            .score-trending__growth-score {
              font-size: 18px;
              font-weight: 700;
            }
          }
          .kpi--positive {
            .kpi__value {
              color: #52c41a;
            }
          }
        }
      }
    }

    .ant-tabs-nav {
      width: 630px;
      max-width: 630px;
      margin: 0px;
      border-top: 1px solid #c2cfe080;
      border-bottom: 1px solid #c2cfe080;
    }
    .ant-tabs-nav-more {
      width: 84px;
    }

    .ant-tabs-ink-bar {
      background-color: #2196f3;
    }

    .ant-tabs-tab {
      padding: 16px 7px;
      margin: 0;
      justify-content: center;
      .ant-tabs-tab-btn {
        line-height: 1;
      }
    }
  }

  .no-company {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;

    flex-flow: column wrap;
    svg {
      width: 44px;
      height: 40px;
      margin-bottom: 15px;
    }
  }

  .kpi-row {
    gap: 11px;
    .kpi-col {
      width: 18% !important;
      max-width: none;
      flex: 1;
    }
  }
  .insights-row,
  .kpi-row,
  .funding-row {
    padding: 18px;
    border-bottom: 1px solid rgba(194, 207, 224, 0.5);
  }

  .insights-row {
    .key-info-ceo {
      display: flex;
      align-items: center;
      padding-top: 2px;
    }
    .company-tags .tags-select {
      max-width: 150px;
      width: 150px;
      margin-right: 50px;
    }
    .insights-social-media {
      width: 444px;
      display: inline-flex;
      align-items: center;
      gap: 5px;
      margin-left: 10px;
      vertical-align: sub;

      .emails {
        display: inline-block;
        width: 50%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }

      .socialmedia {
        display: flex;
        align-items: center;
        padding: 0;
      }
    }
    .key-info-ceo-value {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
  }

  .funding-row {
    .extension-funding {
      flex: 1;
      .ant-card-head-title {
        font-size: 18px;
      }
      .ant-card-extra {
        & > div {
          display: flex;
          flex: 1;
          gap: 10px;

          .kpi__label {
            max-width: 120px;
          }
        }
      }
      .ant-radio-group {
        display: none;
      }
    }
    .legacy-table {
      margin-top: 20px;
    }
  }

  .source-description {
    margin: 15px 0;
  }

  .contacts {
    &-primary-contact-row {
      padding: 0 18px;
      margin-bottom: 0;

      h2.ant-typography {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0;
      }
      .ant-card {
        /* Gray / gray-1 */
        background: #ffffff;
        border-radius: 3.04225px;
        /* Elevation 0 */
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        overflow: hidden;
        max-width: 216px;
      }
      .ant-card-cover {
        height: 119px;
        box-sizing: border-box;
        position: relative;

        &::before {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          height: 50%;
          background: linear-gradient(79.88deg, #072d4f -1.05%, #074d92 105.51%);
          width: 100%;
        }

        img {
          object-fit: contain;
          position: absolute;
          width: 76px;
          height: 76px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
        }
      }
      .ant-card-body {
        padding: 0;
      }
      .ant-card-meta {
        text-align: center;

        &-title {
          margin: 0;
          color: #000000;
          font-weight: 600;
          font-size: 15.21px;
          line-height: 21.3px;
        }
        &-description {
          padding-bottom: 12px;

          div.contact-title {
            margin: 0;
            font-style: normal;
            font-weight: normal;
            font-size: 10.6479px;
            line-height: 17px;
            color: #000000;
          }
          div.contact-location {
            margin: 0 0 6px;
            font-style: normal;
            font-weight: normal;
            font-size: 10.6479px;
            line-height: 17px;
            /* Gray / gray-6 */
            color: #bfbfbf;
          }
          div.contact-social-media {
            margin: 0;
          }
        }
      }
    }

    &-table-row {
      padding: 4px 18px 18px;

      .ant-card-head {
        min-height: 0;
        padding: 0;
        margin-bottom: 17px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        border: none;
      }
      .ant-card-head-title {
        padding: 0;
      }

      .ant-card-body {
        padding-top: 0;
      }
    }
  }
  .ant-tabs-top > .ant-tabs-nav:before {
    border-bottom: transparent !important;
  }
`;

const MoreIcon = styled.span`
  margin-left: 5px;
`;

const TouchesTabContainer = styled.div`
  width: 95px;
  margin-left: 20px;
`;

export const Styled = {
  CardPannelWrapper,
  MoreIcon,
  TouchesTabContainer,
};
