/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Col, Row, Tag } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
// models
import { Company } from '@models/Company';
import { ChartBarIntervalKeys } from '@optx/models/charts/data';
// consts
import { DEFAULT_EMPTY_VALUE } from '@constants/value';
// utils
import { getCompanyAddress, getIsPsgCompany } from '@utils/company';
import { formatLastTouch } from '@utils/touches';
import { abbreviateValue } from '@optx/utils/number';
// redux
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as userSelectors } from '@redux/user/information';
// hooks
import useReferrerProfileLink from '@hooks/useReferrerLink';
// components
import { IconCompany, IconLocation } from '@components/icons';
import Icon from '@components/common/Icon/Icon';
import { ScoreTrending } from '@components/common/trending';
import { CompanyTagsOverflow, ListTagsOverflow } from '@optx/features/tags/overflow-tags';
import IsraelFlag from '@optx/assets/images/flags/israel_flag.jpg';
import { TrendingLine } from '@optx/components/common/charts';
import Styled from './LongCardView.styled';

interface CompanyCardProps {
  company: Company;
  newTab?: boolean;
}

const LongCard: React.FC<CompanyCardProps> = ({ company, newTab }) => {
  const [period, setPeriod] = useState<ChartBarIntervalKeys>('1Y');
  const { pathname } = useLocation();
  const myCompaniesTrending = useSelector(searchSelectors.getTrending('myCompanies'));
  const sourcingOutReachTrending = useSelector(searchSelectors.getTrending('outreach'));
  const searchTrending = useSelector(searchSelectors.getTrending('advancedSearch'));
  const watchlistTrending = useSelector(searchSelectors.getTrending('lists'));
  const defautlOptxScore = useSelector(userSelectors.getDefaultScore);
  const urlStatus = company.url_status === 'active';

  const companyWatchLists =
    company.et_watchlists && company.regular_watchlists
      ? [...company.et_watchlists, ...company.regular_watchlists]
      : company.et_watchlists
      ? company.et_watchlists
      : company.regular_watchlists;

  const { referrerUrl } = useReferrerProfileLink(company.company_id);

  useEffect(() => {
    if (pathname.startsWith('/user-lists') || pathname.startsWith('/ss-lists')) {
      setPeriod(watchlistTrending);
    } else if (pathname.startsWith('/my-companies')) {
      setPeriod(myCompaniesTrending);
    } else if (pathname.startsWith('/all-reports')) {
      setPeriod(sourcingOutReachTrending);
    } else {
      setPeriod(searchTrending);
    }
  }, [myCompaniesTrending, pathname, searchTrending, sourcingOutReachTrending, watchlistTrending]);

  const address = getCompanyAddress(company, DEFAULT_EMPTY_VALUE);

  return (
    <Row style={{ padding: '16px 16px', height: '162px' }}>
      <Styled.LogoColumn>
        <Styled.ImageWrapper>
          {/* When there is no image, the logo returns - instead of null. */}
          {company.logo_url === DEFAULT_EMPTY_VALUE || !company.logo_url ? (
            <IconCompany />
          ) : (
            <img src={company.logo_url} alt={company.company_name} />
          )}
        </Styled.ImageWrapper>
        <Styled.ScoreTrendingWrapper>
          {defautlOptxScore === 'il' ? (
            <ScoreTrending
              score={company.il_optx_score}
              growth={
                isNaN(parseInt(company.il_score_growth as string))
                  ? null
                  : Number(company.il_score_growth)
              }
              label="IL OPTX SCORE"
              isPSGCompany={getIsPsgCompany(company)}
              isLongCardView={true}
            />
          ) : (
            <ScoreTrending
              score={company.score}
              growth={
                isNaN(parseInt(company.score_growth as string))
                  ? null
                  : Number(company.score_growth)
              }
              label="OPTX SCORE"
              isPSGCompany={getIsPsgCompany(company)}
              isLongCardView={true}
            />
          )}
          {!getIsPsgCompany(company) && company?.optx_score_trends && (
            <TrendingLine
              points={company?.optx_score_trends[period]}
              color="#1890FF"
              viewBoxWidth={70}
              svgWidth={70}
            />
          )}
        </Styled.ScoreTrendingWrapper>
      </Styled.LogoColumn>
      <Col style={{ width: 'calc(100% - 79px' }}>
        <Row gutter={[8, 0]}>
          <Col style={{ width: 'calc(100% - 218px' }}>
            <Styled.CompanyNameWrapper>
              <Styled.CompanyName ellipsis>{company.company_name}</Styled.CompanyName>
              {company.is_in_israel && (
                <Styled.IsraelFlagImg
                  src={IsraelFlag}
                  alt="Israel"
                  style={{ borderRadius: 4, marginLeft: '5px', maxWidth: '32px' }}
                />
              )}
              {company.raw_url && (
                <Styled.WebsiteIcon
                  href={company.raw_url}
                  title={company.raw_url}
                  className={urlStatus ? undefined : 'not-active'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GlobalOutlined />
                </Styled.WebsiteIcon>
              )}
              <Styled.CompanyProfileLink to={referrerUrl} target="_blank">
                <Icon
                  style={{ marginBottom: company.is_in_israel ? '5px' : '0px' }}
                  iconName="open-in-new"
                />
              </Styled.CompanyProfileLink>
            </Styled.CompanyNameWrapper>
            <Styled.CompanyDetailsWrapper>
              {address && address !== '-' && (
                <Styled.CompanyLocation ellipsis>
                  <IconLocation />
                  {address}
                </Styled.CompanyLocation>
              )}
              {company.year_founded !== '-' && (
                <Styled.CompanyDetails type="secondary">
                  <Styled.YearLabel strong>Year Founded:</Styled.YearLabel>{' '}
                  <Styled.YearValue>{company.year_founded}</Styled.YearValue>
                </Styled.CompanyDetails>
              )}
            </Styled.CompanyDetailsWrapper>
          </Col>
          <Col style={{ width: '218px' }}>
            <Row style={{ flexFlow: 'row nowrap' }}>
              <Styled.CenteredColumn xs={20} lg={5}>
                <Styled.DateValue>{formatLastTouch(company.last_touch_date)}</Styled.DateValue>
                <Styled.Label>LAST TOUCH</Styled.Label>
              </Styled.CenteredColumn>
              <Styled.EmployeeColumn>
                <Styled.Value>{abbreviateValue(company.num_employees)}</Styled.Value>
                <Styled.Label># OF EMPLOYEES</Styled.Label>
                {company?.employee_count_trends && (
                  <Styled.EmployeeChart>
                    <TrendingLine
                      points={company?.employee_count_trends[period]}
                      color="#52C41A"
                      viewBoxWidth={100}
                      svgWidth={100}
                    />
                  </Styled.EmployeeChart>
                )}
              </Styled.EmployeeColumn>
            </Row>
          </Col>
        </Row>
        <Row>
          <Styled.Description>{company.company_description}</Styled.Description>
        </Row>
        <Row>
          <Col>
            {!company.source_tag.includes('et') && !company.is_in_et ? (
              <Tag color="gray">Not in ET</Tag>
            ) : (
              <Tag color="green">In ET</Tag>
            )}
          </Col>
          <Col style={{ flex: '1' }}>
            <Row>
              <Col span={16}>
                <CompanyTagsOverflow company={company} isNewTab={newTab} />
              </Col>
              <Col span={8}>
                <ListTagsOverflow tags={companyWatchLists} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default LongCard;
