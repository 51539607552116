import { createReducer } from '@reduxjs/toolkit';
// constants
import { INITIAL_STATE_CHART } from '@optx/constants/charts';
// redux
import { fetchFailReducer, fetchReducer, fetchSuccessReducer } from '@redux/feature/fetch/reducers';
import * as actions from './actions';
import { EmployeeGrowthState } from './interfaces';

const initialState: EmployeeGrowthState = {
  data: INITIAL_STATE_CHART,
  loading: false,
  error: '',
};

export const reducer = createReducer<EmployeeGrowthState>(initialState, builder =>
  builder
    .addCase(actions.fetchEmployeeGrowth, fetchReducer)
    .addCase(actions.fetchEmployeeGrowthSuccess, fetchSuccessReducer)
    .addCase(actions.fetchEmployeeGrowthFail, fetchFailReducer)
);
