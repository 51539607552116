import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// models
import { TableCellWatchlists } from '@optx/models/table/Cell';
// constants
import { CompanyProfileTabs } from '@optx/constants/routes';
import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';
// utils
import { getCompanyProfileURL } from '@optx/utils/routes';

const StyledCompanyLink = styled(Link)`
  display: inline;
`;

const TableCellAssociatedCompany: React.FC<TableCellWatchlists> = ({ value, row }) => {
  if (!row.addon_company) return <>{DEFAULT_CELL_VALUE}</>;

  if (!row.addon_company_id) return <>{row.addon_company}</>;

  return (
    <div role="presentation">
      <StyledCompanyLink
        className="company-name__profile-link"
        to={getCompanyProfileURL(row.addon_company_id, `tab=${CompanyProfileTabs.ADDON_MGMT}`)}
        target="_blank"
      >
        {row.addon_company}
      </StyledCompanyLink>
    </div>
  );
};

export default TableCellAssociatedCompany;
