/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
// utils
import { groupDataByQuarter } from '@optx/utils/chart/recharts';
// components
import { StackedChart } from '@optx/features/charts/StackedChart/';
// redux
import { selectors as CompaniesETSelectors } from '../../state';

const abbreviateNumber = (number: number = 0): string => {
  return Intl.NumberFormat('en-US', {
    // @ts-ignore
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(number);
};

const NewCompaniesAddedBarChart = () => {
  const chartData = useSelector(CompaniesETSelectors.charts.getData);

  const { groupData } = groupDataByQuarter(chartData);

  return (
    <StackedChart
      data={chartData}
      barKeys={[
        {
          key: item => item.added_value,
          color: '#1890FF',
        },
        { key: item => item.total_value - item.added_value, color: '#C2CFE0' },
      ]}
      tooltipConfig={{
        label: '# of New Companies Added to ET',
        secondLabel: item => {
          const isDay = item.x_axis.split('-').length > 2;
          const isAllTime = item.x_axis.split(' ').length === 1 || item.x_axis === '';

          const labelFormat = '[Q]Q YY';
          const quarters = moment(item.date).format(labelFormat);

          if (isDay) return moment(item.date).format('MMMM DD, YYYY');
          if (isAllTime) return quarters;

          return item.x_axis;
        },
        dataKeys: [
          {
            label: 'Total Companies:',
            isBlue: false,
            value: item => item.total_value,
          },
          {
            label: 'Added from OPTX:',
            isBlue: true,
            value: item => item.added_value,
          },
        ],
      }}
      yAxisProps={{
        dataKey: 'total_value',
        tickFormatter: value => abbreviateNumber(value),
      }}
      xAxisProps={{
        ticks: groupData,
        dataKey: 'x_axis',
      }}
      periodLabel={item => {
        if (!item) return '';

        const { color } = item;

        const isTotal = color === '#C2CFE0';

        if (isTotal) return 'TOTAL COMPANIES ADDED';

        return '# OF COMPANIES ADDED FROM OPTX';
      }}
    />
  );
};

export default NewCompaniesAddedBarChart;
