import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { actions as listsActions } from '@optx/redux/company/lists';
import { CompanyTabListPaginationState } from '@optx/redux/company/lists/interfaces';

export const useFetchLists = (companyId: number) => {
  const dispatch = useDispatch();

  const fetchCompanyTabLists = useCallback(
    (payload?: CompanyTabListPaginationState) => dispatch(listsActions.getCompanyTabLists(payload)),
    [dispatch]
  );

  return fetchCompanyTabLists;
};
