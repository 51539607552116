import { createFeatureAction } from '@utils/feature';
import featureName from '../../featureName';
import * as actionTypes from './actionTypes';
import { Callback, EditDateFields } from './interfaces';

export const addDateFields = createFeatureAction<EditDateFields | Callback>(
  featureName,
  actionTypes.ADD_DATE_FIELDS
);

export const addDateFieldsSuccess = createFeatureAction<EditDateFields>(
  featureName,
  actionTypes.ADD_DATE_FIELDS_SUCCESS
);
