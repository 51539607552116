import { createSelector } from 'reselect';
// interfaces
import { AppState } from '@optx/redux/interfaces';
import { Column } from '@optx/models/table/Columns';
// constants
import columns from '@components/common/table/Companies/columns/virtual-column-config';
import { SORT_DESCENDING_BY_DEFAULT } from '@optx/constants/table/sort/defaultSort';
// utils
import { filterSortableVisibleColumns } from '@utils/table/column-visibility';
import { sortColumns } from '@utils/table/sorting';
// redux
import {
  getSorting as getSortingSelector,
  isMultiSort as isMultiSortSelector,
} from '@redux/lists/search/sort/selectors';
import { getDefaultScore } from '@optx/redux/user/information/selectors';

const listColumnsState = (state: AppState) => state.lists.search.columns;

export const getColumnDisplay = createSelector(listColumnsState, state => state.columnDisplay);
export const getColumnOrder = createSelector(listColumnsState, state => state.columnOrder);

/**
 * Get displayed columns.
 * Used for defining the columns table
 */
export const getDisplayedColumns = createSelector(
  getColumnDisplay,
  getColumnOrder,
  (columnDisplay, order) => filterSortableVisibleColumns(columns, columnDisplay, order)
);

export const getColumns = createSelector(
  getDisplayedColumns,
  getSortingSelector,
  isMultiSortSelector,
  getDefaultScore,
  (columns, sorting, isMultiSort, defaultOptxScore) =>
    // @ts-ignore
    sortColumns(columns, sorting, defaultOptxScore, {
      isMultiSort,
      // Use descending by default only for single sort.
      sortDirectionsKeys: isMultiSort ? undefined : SORT_DESCENDING_BY_DEFAULT,
    })
);

// get list of columns separated into pinned columns and sortable columns
export const getColumnLists = createSelector(
  getColumnDisplay,
  getColumnOrder,
  (columnDisplay, columnOrder) => {
    const sortableColumns: Column[] = [];
    const pinnedColumns: Column[] = [];

    columnOrder.forEach(columnId => {
      const column = {
        id: columnId,
        name: columnDisplay[columnId].name,
        visible: columnDisplay[columnId].visible,
        pinned: columnDisplay[columnId].pinned,
        width: columnDisplay[columnId].width,
      };

      if (!columnDisplay[columnId].pinned) {
        sortableColumns.push(column);
      } else {
        pinnedColumns.push(column);
      }
    });

    return [sortableColumns, pinnedColumns];
  }
);
