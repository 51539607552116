import React from 'react';
import Company from '@optx/models/Company';
import { cellIsEqual } from '@components/common/table/AntTable/cells/memoize';
import { TooltipText } from '../../../tooltip';
import { Cell } from '../../../../../models/table/Cell';
import { DEFAULT_CELL_VALUE } from '../../../../../constants/table/cells';

const TableCellList: React.FC<Cell<Company>> = ({ value }) =>
  value && value.length && value[0] !== '' ? (
    <TooltipText text={value.join(', ')} />
  ) : (
    <>{DEFAULT_CELL_VALUE}</>
  );

export default React.memo(TableCellList, cellIsEqual);
