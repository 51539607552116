import React from 'react';
import { Menu, MenuProps } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { useSelector } from 'react-redux';
// constants
import appRoutes from '@constants/routes';
// redux
import { selectors as viewTypeSelectors } from '@features/grid/view-type';
// hooks
import { useDisplayColumnModal } from '@optx/common/hooks/init';
import useIsAnalyst from '@hooks/useIsAnalyst';

const DisplayColumnToggle = ({ onClick, ...restProps }: MenuItemProps) => {
  const searchView = useSelector(viewTypeSelectors.searchView('advancedSearch'));
  const myCompaniesView = useSelector(viewTypeSelectors.searchView('myCompanies'));
  const listView = useSelector(viewTypeSelectors.searchView('lists'));
  const companyOutReachView = useSelector(viewTypeSelectors.searchView('outreach'));
  const [, toggleModalDisplay] = useDisplayColumnModal();
  const { advancedSearchPathname } = useIsAnalyst();

  const handleClick: MenuProps['onClick'] = info => {
    toggleModalDisplay();
    onClick && onClick(info);
  };

  if (window.location.pathname === advancedSearchPathname && searchView === 'long-card') {
    return null;
  }

  if (window.location.pathname.startsWith('/user-lists') && listView === 'long-card') {
    return null;
  }

  if (window.location.pathname.startsWith('/ss-lists') && listView === 'long-card') {
    return null;
  }

  if (window.location.pathname === appRoutes.myCompanies && myCompaniesView === 'long-card') {
    return null;
  }

  if (window.location.pathname.startsWith('/all-reports') && companyOutReachView === 'long-card') {
    return null;
  }

  return (
    <Menu.Item onClick={handleClick} {...restProps}>
      Columns and Source Management
    </Menu.Item>
  );
};

export default DisplayColumnToggle;
