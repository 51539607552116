import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import * as actions from './actions';
import { SavedSearchesService } from '../../../services/api';
import NotificationService from '../../../services/NotificationService';

export function* fetchListsExcludingCompanySaga(action: PayloadAction<number>) {
  const companyId = action.payload;

  try {
    // @ts-ignore
    const res = yield call(SavedSearchesService.getFavoriteLists, null, companyId);
    yield put(actions.fetchListsExcludingCompanySuccess(companyId, res.data.lists));
  } catch (error: any) {
    const message = `Fetch lists excluding company ${companyId} fail!`;
    yield put(actions.fetchListsExcludingCompanyFail(message));
    NotificationService.error(message);
  }
}

export default function* companyListsSagas() {
  yield takeLatest(actions.fetchListsExcludingCompany, fetchListsExcludingCompanySaga);
}
