import React from 'react';
import { Card } from 'antd';
// models
import { CompanyProfile } from '@optx/models/Company';
// components
// eslint-disable-next-line max-len
import FundingOwnershipStructure from '@optx/components/pages/CompanyProfile/ProfileTabs/DealPresentation/components/FundingOwnershipStructure';
// eslint-disable-next-line max-len
import DealPreparation from '@optx/components/pages/CompanyProfile/ProfileTabs/DealPresentation/components/DealPreparation';
import Financials from '@optx/components/pages/CompanyProfile/ProfileTabs/DealPresentation/components/Financials';
import CustomerKPI from '@optx/components/pages/CompanyProfile/ProfileTabs/DealPresentation/components/CustomerKPI';
import Technology from '@optx/components/pages/CompanyProfile/ProfileTabs/DealPresentation/components/Technology';
import { CardContainer } from '../styled/ProprietaryInfo.styled';

interface ProprietaryInfoProps {
  company: CompanyProfile;
}

const ProprietaryInfo: React.FC<ProprietaryInfoProps> = ({ company }) => {
  return (
    <CardContainer>
      <Card title="Deal Info" className="card-deal-preparation">
        <DealPreparation company={company} isChromeExtension={true} />
      </Card>
      <Card title="Financials" className="card-proprietary-info">
        <Financials companyId={company.company_id} isChromeExtension={true} />
      </Card>
      <Card title="Funding / Ownership Structure" className="card-proprietary-info">
        <FundingOwnershipStructure company={company} isChromeExtension={true} />
      </Card>
      <Card title="Customer KPI" className="card-proprietary-info">
        <CustomerKPI company={company} isChromePlugin={true} />
      </Card>
      <Card title="Technology" className="card-proprietary-info">
        <Technology company={company} isChromeExtension={true} />
      </Card>
    </CardContainer>
  );
};

export default ProprietaryInfo;
