import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { has } from 'lodash';
// models
import { CompanyBulkEditingResults, EquityTouchBulkCompanies } from '@optx/models/bulkActions';
import { UserInformation } from '@optx/models/user';
import { EditingFieldsState } from '@features/bulk-actions/edit-fields/state/interfaces';
// redux
import {
  actions as selectedCompaniesActions,
  interfaces as selectedCompaniesModels,
} from '@features/bulk-actions/selected-companies';
import { actions as userInformationActions } from '@redux/user/information';
import * as actions from './actions';

const initialState: EditingFieldsState = {
  results: [],
  inProgress: false,
  completed: false,
  percentageCompleted: 0,
  selectedCompaniesCount: 0,
  cancel: false,
};

const saveResultsReducer: CaseReducer<
  EditingFieldsState,
  PayloadAction<CompanyBulkEditingResults[]>
> = (draftState, action) => {
  draftState.results = action.payload;
};

const updateProgressReducer: CaseReducer<EditingFieldsState, PayloadAction<boolean>> = (
  draftState,
  action
) => {
  draftState.inProgress = action.payload;
};

const updateCompletedReducer: CaseReducer<EditingFieldsState, PayloadAction<boolean>> = (
  draftState,
  action
) => {
  draftState.completed = action.payload;
};

const updatePercentageReducer: CaseReducer<EditingFieldsState, PayloadAction<number>> = (
  draftState,
  action
) => {
  const addProgress = action.payload;

  if (addProgress === 0 || addProgress === 100) {
    draftState.percentageCompleted = action.payload;
  } else {
    draftState.percentageCompleted += action.payload;
  }
};

const selectCompanyIdsReducer: CaseReducer<
  EditingFieldsState,
  PayloadAction<selectedCompaniesModels.SelectedCompaniesPayload>
> = (draftState, action) => {
  draftState.selectedCompaniesCount = action.payload.selectedCompanies.length;
};

const resetProgressReducer: CaseReducer<EditingFieldsState> = draftState => {
  draftState.results = [];
  draftState.completed = false;
  draftState.percentageCompleted = 0;
};

const cancelReducer: CaseReducer<EditingFieldsState, PayloadAction<boolean>> = (
  draftState,
  action
) => {
  draftState.cancel = action.payload;
};

// External reducers
// Update persisted filter to be used when filters are loaded.
const fetchUserInformationSuccess: CaseReducer<
  EditingFieldsState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const persistedFilter = action.payload.settings.session_settings
    ? action.payload.settings.session_settings.et_form_auto_save
    : undefined;

  if (persistedFilter !== null && persistedFilter !== undefined) {
    if (has(persistedFilter, 'Company Name')) {
      return;
    }

    const companiesById = (persistedFilter?.companiesById ?? {}) as EquityTouchBulkCompanies;
    const allCompanyIds: string[] = [];
    Object.keys(companiesById).forEach(companyId => {
      if (Number.isInteger(Number(companyId))) {
        allCompanyIds.push(companyId);
      }
    });
    draftState.selectedCompaniesCount = allCompanyIds.filter(item => item).map(item => Number(item))
      .length as number;
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.saveResults, saveResultsReducer)
    .addCase(actions.updateProgress, updateProgressReducer)
    .addCase(actions.updateCompleted, updateCompletedReducer)
    .addCase(actions.updatePercentage, updatePercentageReducer)
    .addCase(actions.resetProgress, resetProgressReducer)
    .addCase(actions.cancel, cancelReducer)
    .addCase(selectedCompaniesActions.selectCompanyIds, selectCompanyIdsReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccess)
);

export default reducer;
