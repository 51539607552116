import { fork } from 'redux-saga/effects';
import { saga as websiteRankSaga } from './website-rank';
import { saga as g2DataSaga } from './g2';
import { saga as glassDoorSaga } from './glassdoor';
import { saga as linkedinDataSaga } from './linkedin';
import { saga as crunchbaseDataSaga } from './crunchbase';
import { saga as twitterDataSaga } from './twitter';
import { saga as facebookDataSaga } from './facebook';
import { saga as capterraDataSaga } from './capterra';

export default function* CompanyGraphsSaga() {
  yield fork(websiteRankSaga);
  yield fork(g2DataSaga);
  yield fork(glassDoorSaga);
  yield fork(linkedinDataSaga);
  yield fork(crunchbaseDataSaga);
  yield fork(twitterDataSaga);
  yield fork(facebookDataSaga);
  yield fork(capterraDataSaga);
}
