/**
 * toggle popover-visible class on body depending on visible param
 * @param {boolean} visible param of menu visibility
 */
export const handleMenuVisibleChange = (visible: boolean) => {
  // Use timeout due to menu transition animation being blocked/delayed while the DOM changes and API call are made.
  setTimeout(() => {
    if (!visible) {
      document.body.classList.remove('popover-visible');
    } else {
      document.body.classList.add('popover-visible');
    }
  }, 0);
};
