import { Skeleton } from 'antd';
import styled from 'styled-components';

interface SkeletonProps {
  style?: React.CSSProperties;
  width?: string;
  isCenter?: boolean;
  margin?: [number, number, number, number];
}

const StyledInputSkeleton = styled(Skeleton.Input)`
  margin: ${(props: SkeletonProps) => props.margin && props.margin.join('px ') + 'px'};
  width: ${(props: SkeletonProps) => props.width || '100%'} !important;
  height: 18px !important;

  .ant-skeleton-input {
    display: block !important;
    width: ${(props: SkeletonProps) => props.width || '100%'} !important;
    margin: ${(props: SkeletonProps) => props.isCenter && '0px auto'};
    height: 18px !important;
  }
`;

const StyledImageSkeleton = styled(Skeleton.Avatar)`
  width: ${(props: SkeletonProps) => props.width || '100%'} !important;
  height: ${(props: SkeletonProps) => props.width || '100%'} !important;
  margin: ${(props: SkeletonProps) => props.margin && props.margin.join('px ') + 'px'};

  .ant-skeleton-avatar {
    display: block !important;
    width: ${(props: SkeletonProps) => props.width || '100%'} !important;
    height: ${(props: SkeletonProps) => props.width || '100%'} !important;
    margin: ${(props: SkeletonProps) => props.isCenter && '0px auto'};
  }
`;

const LineSkeleton: React.FC<SkeletonProps> = ({ width, isCenter = true, margin, style }) => {
  return (
    <StyledInputSkeleton
      size="small"
      active
      style={style}
      isCenter={isCenter}
      margin={margin}
      width={width}
    />
  );
};

const ImageSkeleton: React.FC<SkeletonProps> = ({ width, isCenter = true, margin, style }) => {
  return (
    <StyledImageSkeleton
      size="small"
      shape="square"
      active
      style={style}
      margin={margin}
      isCenter={isCenter}
      width={width}
    />
  );
};

export { LineSkeleton, ImageSkeleton };
