import React, { useMemo, useCallback } from 'react';
import Select, {
  ActionMeta,
  ValueType,
  Props as SelectProps,
  SelectComponentsConfig,
} from 'react-select';
// interface
import { SelectOption } from '@optx/models/Option';
// components
import { ValueContainer, DropdownIndicator } from '../components';
// styles
import './style.scss';
import { MenuListWithLoader } from '@optx/shared/view/molecules/Select/components';

const defaultComponents: Partial<SelectComponentsConfig<any>> = {
  ValueContainer,
  DropdownIndicator,
};

const SingleSelect: React.FC<SelectProps<SelectOption>> = ({
  options,
  value,
  components,
  isLoading,
  onChange,
  ...restProps
}) => {
  const selectedOptions = useMemo(() => {
    if (value) {
      let availableOptions: Array<SelectOption> = [...(options as Array<SelectOption>)];

      // this way, only the enabled options are returned and validated
      availableOptions = availableOptions.filter(option => !option.disabled);

      if ((value as Array<SelectOption>).length === availableOptions.length) {
        return [...(options as Array<SelectOption>)];
      }

      return value;
    }

    return [];
  }, [value, options]);

  const handleChange = useCallback(
    (value: ValueType<SelectOption>, action: ActionMeta<SelectOption>) => {
      return onChange && onChange(value, action);
    },
    [onChange]
  );

  const selectComponents = useMemo(
    () => ({
      ...defaultComponents,
      ...(components || {}),
      MenuList: MenuListWithLoader(isLoading || false),
    }),
    [components, isLoading]
  );

  const customFilter = useCallback((option: SelectOption, input: string) => {
    if (input) {
      return option.label.toLowerCase().includes(input.toLowerCase());
    }

    return true;
  }, []);

  return (
    <Select
      className="singleselect-wrapper"
      options={options}
      value={selectedOptions}
      onChange={handleChange}
      components={selectComponents}
      {...restProps}
      filterOption={customFilter}
      menuPlacement="auto"
      // Leave isMulti at the end to not be overriden.
      isMulti={false}
    />
  );
};

SingleSelect.defaultProps = {
  placeholder: 'search',
  classNamePrefix: 'singleselect-select',
  isClearable: false,
  hideSelectedOptions: false,
  closeMenuOnSelect: false,
};

export default SingleSelect;
