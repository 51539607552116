import styled from 'styled-components';

export const InlineBlockLink = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const TooltipDefaultLabel = styled.span`
  width: 100%;
  display: block;
`;
