import { Dictionary } from 'lodash';
import { AuthorizationComponentType } from '@models/auth';

/** Function that verify if the user has access
 * return true/false
 * At least one item should be accessible to return as true.
 @param {string} components - Dictionary
 @param {string} accessFor - String/Array
 */

export const verifyAccess = (
  components: Dictionary<boolean>,
  accessFor: AuthorizationComponentType | Array<AuthorizationComponentType>
) => {
  if (Array.isArray(accessFor)) {
    let hasAccess = false;

    for (let index = 0; index < accessFor.length; index++) {
      const element = accessFor[index];

      if (components[element] === true) {
        hasAccess = true;
        break;
      }
    }

    if (!hasAccess) {
      return false;
    }
  } else if (components[accessFor] !== true) {
    return false;
  }

  return true;
};
