import React, { useContext } from 'react';
import { Row, Col } from 'antd';
import { Radio } from 'formik-antd';
import { BaseFilter } from '@models/filters';
import Option from '@models/Option';
import SearchFilterCard from './SearchFilterCard';
import { FiltersContext } from '../FiltersContext';

interface FilterRadioProps {
  filter: BaseFilter<Array<Option>>;
}

const FilterRadio: React.FC<FilterRadioProps> = ({ filter }) => {
  const { onManualFilterChange } = useContext(FiltersContext);

  const onChange = () => {
    setTimeout(() => {
      // Call change on next tick.
      onManualFilterChange && onManualFilterChange(filter.column);
    }, 0);
  };

  return (
    <SearchFilterCard label={filter.label}>
      <Radio.Group name={filter.column}>
        <Row>
          {filter.data.map((option, index) => (
            <Col key={index} span={24}>
              <Radio
                name={filter.column}
                value={option.value}
                disabled={option.disabled}
                onChange={onChange}
                fast
              >
                {option.name}
              </Radio>
            </Col>
          ))}
        </Row>
      </Radio.Group>
    </SearchFilterCard>
  );
};

export default React.memo(FilterRadio);
