import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
// redux
import { actions as searchActions } from '@features/grid/search';
import { selectors } from '@features/analyst-home/state';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';
// components
import { HorizontalBarChartRightLabel } from '@optx/features/charts';
import { PieChartStats, TotalsCard } from './components';
import Styled from './components/Sidebar.styles';
import { ImageSkeleton, LineSkeleton } from '../Skeleton';

const PipelineOutreach = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { advancedSearchPathname } = useIsAnalyst();

  const pipelineData = useSelector(selectors.getPipelineData);
  const loading = useSelector(selectors.getInsightsLoading);
  const getOutreachRecencyFilters = useSelector(selectors.getOutreachRecencyFilters);

  const handleRedirects = (label: string) => {
    dispatch(
      searchActions.searchCompanies({
        gridKey: 'advancedSearch',
        data: { filter: getOutreachRecencyFilters[label] },
      })
    );

    history.push(advancedSearchPathname);
  };

  return (
    <Styled.SubSection>
      {loading ? (
        Array.from({ length: 6 }).map((_, index) => <LineSkeleton key={index} />)
      ) : (
        <HorizontalBarChartRightLabel
          labelKey="label"
          data={pipelineData.stages}
          handleRedirects={handleRedirects}
          dataKey="value"
          chartHeight={30 * pipelineData.stages.length}
        />
      )}
      <TotalsCard
        isPipeline
        data={pipelineData.totals}
        loading={loading}
        handleRedirects={handleRedirects}
      />
      {loading ? (
        <div className="pie-chart-loading">
          <ImageSkeleton width="120px" margin={[0, 20, 0, 0]} />
          <div className="pie-chart-loading__text">
            <LineSkeleton margin={[4, 0, 4, 0]} />
            <LineSkeleton margin={[4, 0, 4, 0]} />
            <LineSkeleton margin={[4, 0, 4, 0]} />
            <LineSkeleton />
          </div>
        </div>
      ) : (
        <PieChartStats
          data={pipelineData.touches}
          handleRedirects={handleRedirects}
          totalCompany={pipelineData.touchTotal}
        />
      )}
    </Styled.SubSection>
  );
};

export default PipelineOutreach;
