import React, { useCallback, useEffect, ReactText, useState } from 'react';
import { Table, Card, Button, Alert } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { TableProps } from 'antd/lib/table';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
// utils
import { stripUrlSlashes } from '@utils/url';
import columns from '@components/common/table/TableCompanyMerges/defaultColumns';
// models
import { CompanyMerge } from '@optx/models/company/CompanyMerges';
import { useLocation } from 'react-router-dom';
import { getIsAnalyst } from '@optx/redux/user/information/selectors';
import { CompanyMergesPayload } from '../state/interfaces';
// redux
import { selectors as localSelectors, actions as localActions } from '../state';
// components
import UnmergeModal from './UnmergeModal';

interface MergeHistoryProps extends TableProps<any> {
  companyId: number;
}

const MergeHistory: React.FC<MergeHistoryProps> = ({ companyId }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<ReactText[]>([]);
  const companySources = useSelector(localSelectors.getCompanyMerges);
  const isLoading = useSelector(localSelectors.isLoading);
  const pagination = useSelector(localSelectors.getCompanyMergesPagination);
  const selectedCompanies = useSelector(localSelectors.getSelectedCompanies);
  const hasMore = useSelector(localSelectors.getHasMore);
  const isAnalyst = useSelector(getIsAnalyst);
  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedCompanies.length === 0) {
      setSelectedRowKeys([]);
    }
  }, [selectedCompanies]);

  const fetchMerges = useCallback(
    (payload: CompanyMergesPayload) => dispatch(localActions.fetchMerges(payload)),
    [dispatch]
  );

  const handleMore = () => {
    fetchMerges({ companyId, page: pagination.pageNumber + 1 });
  };

  const infiniteScrollRef = useInfiniteScroll<HTMLDivElement>({
    loading: isLoading,
    hasNextPage: hasMore,
    onLoadMore: handleMore,
  });

  useEffect(() => {
    fetchMerges({
      companyId,
      page: 1,
      reset: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(localActions.resetUnmerge());
    };
  }, [location, dispatch]);

  const onSelectChange = (selectedRowKeys: ReactText[], selectedRows: CompanyMerge[]) => {
    const selectedCompaniesData = selectedRows.map(company => {
      return {
        ...company,
        company_url: company.url ? stripUrlSlashes(company.url) : null,
      };
    });
    setSelectedRowKeys(selectedRowKeys);
    dispatch(
      localActions.setSelectedCompanies({
        selectedCompanies: selectedCompaniesData,
      })
    );
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: CompanyMerge) => ({
      name: record.id.toString(),
    }),
  };

  return (
    <>
      <div ref={infiniteScrollRef}>
        <Card title="Merge History">
          <div className="d-flex flex-column">
            {!isAnalyst && (
              <Button
                className="mb-4 ml-auto"
                type="primary"
                onClick={() => dispatch(localActions.toggleUnmergeModal(true))}
                disabled={
                  selectedCompanies.length === 0 ||
                  selectedCompanies.length === companySources.length
                }
              >
                Unmerge Companies
              </Button>
            )}
            {!isAnalyst &&
              selectedCompanies.length !== 0 &&
              selectedCompanies.length === companySources.length && (
                <Alert
                  message="At least one company should remain unselected"
                  type="error"
                  className="mb-4"
                />
              )}
            <Table
              dataSource={companySources}
              columns={columns}
              pagination={false}
              rowKey="id"
              bordered
              className="legacy-table overflow-auto"
              tableLayout="fixed"
              rowSelection={isAnalyst ? undefined : rowSelection}
            />
          </div>
        </Card>
      </div>
      <UnmergeModal companyId={companyId} />
    </>
  );
};

export default MergeHistory;
