import moment from 'moment';
import React, { useMemo } from 'react';
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
// utils
import * as config from './config';
// components
import { MinimumSizeBar } from '@optx/components/common/charts/components';

interface EmailsSentChartProps {
  data: Array<{ label: string; analyst: number | null; analyst_team_avg: number | null }>;
}

const EmailsSentChart: React.FC<EmailsSentChartProps> = ({ data }) => {
  const labelFormatter = useMemo(
    () => () => (label: string) => {
      return `# of emails sent ${label}`;
    },
    []
  );

  return (
    <ResponsiveContainer height={86} width="90%">
      <BarChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 10 }} barSize={8}>
        <XAxis
          dataKey={'label'}
          {...config.xAxisProps}
          tickFormatter={(value: string) => {
            return moment(value, 'ddd MMMM DD YYYY').format('dddd').charAt(0);
          }}
        />
        <Tooltip separator=": " labelFormatter={labelFormatter} {...config.tooltipProps} />
        <Bar
          dataKey={'analyst'}
          name="Total Touches"
          {...config.leftBarProps}
          shape={MinimumSizeBar}
        />
        <Bar
          dataKey={'analyst_team_avg'}
          name="Team Average"
          {...config.rightBarProps}
          shape={MinimumSizeBar}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default EmailsSentChart;
