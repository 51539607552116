import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import searchReducer from '@redux/company-outreach/reducer';
import { saga } from '@redux/company-outreach/search';
import { saga as columnsSaga } from '@redux/company-outreach/columns';

const useInjectCompanyListSourcingOutReach = () => {
  useInjectReducer({
    key: 'companyOutSourcing',
    reducer: searchReducer,
  });
  useInjectSaga({
    key: 'companiesOutReachSearchSaga',
    saga,
  });
  useInjectSaga({
    key: 'companiesOutReachColumnsSaga',
    saga: columnsSaga,
  });
};

export default useInjectCompanyListSourcingOutReach;
