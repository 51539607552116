import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dictionary } from 'lodash';
import styled from 'styled-components';
// models
import { PreselectedFilter } from '@optx/models/filters';
// utils
import { normalizeFilter } from '@optx/utils/filters/filterHelpers';
// redux
import { selectors as profileSelectors } from '@redux/company/profile';
import {
  selectors as addonManagementSelectors,
  actions as addonManagementActions,
} from '@redux/company/addon-management';
import { actions as searchKeyActions } from '@features/grid/searchkey';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
// components
import SearchHeader from './SearchHeader';

// eslint-disable-next-line max-len
import SecondaryQuickFiltersPanel from '@components/feature/secondary-quick-filters/SecondaryQuickFiltersPanel';
import ExportToCSV from './ExportToCSV';
import ShowFilterButton from '@optx/features/grid/filter/components/ShowFilterButton';

const Container = styled.div`
  margin: -40px -40px 0 -40px;
`;

const SecondaryQuickFiltersContainer: React.FC = () => {
  const dispatch = useDispatch();

  const filter = useSelector(filterSelectors.getFilter('addons'));
  const histograms = useSelector(addonManagementSelectors.selectHistograms);
  const keySearch = useSelector(addonManagementSelectors.getSearchKey);
  const options = useSelector(addonManagementSelectors.getTags);
  const companyId = useSelector(profileSelectors.getCompanyId);

  // call /search query
  const onSearch = useCallback(
    (searchKey: string, filter: Dictionary<PreselectedFilter>) => {
      if (companyId !== undefined) {
        const normalizedFilter = normalizeFilter(filter);
        dispatch(addonManagementActions.setSearchKey(searchKey));
        dispatch(searchKeyActions.setSearchKey({ data: searchKey, gridKey: 'addons' }));
        dispatch(addonManagementActions.setSelectedFilters(normalizedFilter));
        dispatch(
          addonManagementActions.fetchCompanyAddonManagement({
            companyId: companyId,
            shouldResetPageNumber: true,
            filter: normalizedFilter,
          })
        );
      }
    },
    [dispatch, companyId]
  );

  return (
    <Container>
      <SearchHeader />
      <SecondaryQuickFiltersPanel
        onSearch={onSearch}
        filter={filter}
        histograms={histograms}
        keySearch={keySearch}
        options={options}
        isAddonManagement
      >
        <ShowFilterButton gridKey={'addons'} placement={'bottom'} />
        <ExportToCSV />
      </SecondaryQuickFiltersPanel>
    </Container>
  );
};

export default React.memo(SecondaryQuickFiltersContainer);
