import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
// redux
import { selectors as searchSelectors } from '@redux/company/search/search';
import { actions as searchActions } from '@features/grid/search';
import { actions as sortActions } from '@redux/company/search/sort';

const ToggleSortType = ({ onClick, ...restProps }: MenuItemProps) => {
  const dispatch = useDispatch();
  const isMultiSort = useSelector(searchSelectors.isMultiSort);
  const sorting = useSelector(searchSelectors.getSorting);
  const multiSort = useCallback(() => {
    // Hide cancel search button when clicking on sort button
    if (!isMultiSort) {
      const tableElement = document.querySelector('.ant-table-body');
      tableElement?.scrollTo(0, 0);
    }

    dispatch(sortActions.multiSortAction(!isMultiSort, sorting));
  }, [dispatch, isMultiSort, sorting]);

  const resetCancelSearch = () => {
    // reset cancelSearch onBlur
    dispatch(searchActions.hideCancelSearch({ gridKey: 'advancedSearch', data: false }));
  };

  return (
    <Menu.Item onClick={multiSort} onBlur={resetCancelSearch} {...restProps}>
      {isMultiSort ? 'Enable Single Sort' : 'Enable Multi Sort'}
    </Menu.Item>
  );
};

export default ToggleSortType;
