export function replaceObjectForCopy(obj: JSX.Element[]) {
  const mapList = obj?.map((item: any) => item?.props?.children);

  const childrenFlatted = mapList?.flat()?.join(', ');

  const textWithoutObject = childrenFlatted
    ?.replace(/\[object Object\],/g, '')
    ?.replace(/, \[object Object\]/g, '');

  return textWithoutObject;
}
