import * as yup from 'yup';
import { Dictionary } from 'lodash';
import { SaveView } from '@optx/models/search';
import { SaveSearchValues } from '@optx/components/common/dropdowns/DropdownSaveSearch/DropdownSaveSearch';

const getValidationSchema = (searchTitles: Dictionary<boolean>) => {
  if (searchTitles) {
    const validationSchema: yup.ObjectSchema<Omit<SaveView, 'isDefault'>> = yup.object<
      Omit<SaveView, 'isDefault'>
    >({
      title: yup
        .string()
        .required('Field is required!')
        .test('title_consists_only_of_space', 'Search name can not contain only spaces', value => {
          if (typeof value === 'string' && !value.trim()) {
            return false;
          }

          return true;
        })
        .test(
          'title_ends_or_starts_with_space',
          'Search name can not end or start with empty space',
          value => {
            if (typeof value === 'string') {
              const initialValueLength = (value as string).length;

              if (value.trim().length < initialValueLength) {
                return false;
              }
            }

            return true;
          }
        ),
      // eslint-disable-next-line func-names
      filters: yup.boolean().test('oneOfRequired', ' ', function (value) {
        return value || this.parent.sorting || this.parent.columns || this.parent.pinnedColumns;
      }),
      // eslint-disable-next-line func-names
      sorting: yup.boolean().test('oneOfRequired', ' ', function (value) {
        return value || this.parent.filters || this.parent.columns || this.parent.pinnedColumns;
      }),
      // eslint-disable-next-line func-names
      columns: yup.boolean().test('oneOfRequired', ' ', function (value) {
        return value || this.parent.filters || this.parent.sorting || this.parent.pinnedColumns;
      }),
      // eslint-disable-next-line func-names
      pinnedColumns: yup.boolean().test('oneOfRequired', ' ', function (value) {
        return value || this.parent.filters || this.parent.sorting || this.parent.columns;
      }),
      isOverwritten: yup
        .boolean()
        .test(
          'oneOfRequired',
          'Please check Overwrite search name and settings in order to proceed',
          function (value) {
            if (this.parent.hasSameName && !value) {
              return false;
            }

            return true;
          }
        )
        .test('oneOfRequired', ' ', function (value) {
          return value || this.parent.filters || this.parent.sorting || this.parent.columns;
        }),
      hasSameName: yup.boolean(),
    });

    return validationSchema;
  }

  return undefined;
};

export default getValidationSchema;

export const saveListSchema = (searchTitles: Dictionary<boolean>) => {
  if (searchTitles) {
    const validationSchema: yup.ObjectSchema<SaveSearchValues> = yup.object<SaveSearchValues>({
      title: yup
        .string()
        .required('Field is required!')
        .test('title_consists_only_of_space', 'Search name can not contain only spaces', value => {
          if (typeof value === 'string' && !value.trim()) {
            return false;
          }

          return true;
        })
        .test(
          'title_ends_or_starts_with_space',
          'Search name can not end or start with empty space',
          value => {
            if (typeof value === 'string') {
              const initialValueLength = value.length;

              if (value.trim().length < initialValueLength) {
                return false;
              }
            }

            return true;
          }
        )
        .test({
          name: 'titleTaken',
          exclusive: true,
          params: { searchTitles },
          // eslint-disable-next-line no-template-curly-in-string
          message: '"${value}" search title already exists!',
          test: value => {
            if (typeof value === 'string') {
              const trimmedSearchTitles = Object.keys(searchTitles).map(title =>
                title.trim().toLowerCase()
              );

              return !trimmedSearchTitles.includes(value.trim().toLowerCase());
            }

            return true;
          },
        }),
    });

    return validationSchema;
  }

  return undefined;
};
