import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { CustomGlobalLoader } from './interfaces';
import * as actions from './actions';

const initialState: CustomGlobalLoader = {
  loading: false,
};

const loadingReducer: CaseReducer<CustomGlobalLoader, PayloadAction<CustomGlobalLoader>> = (
  draftState,
  action
) => {
  draftState.loading = action.payload.loading;
  draftState.customText = action.payload.customText;
};

const reducer = createReducer(initialState, builder =>
  builder.addCase(actions.toggle, loadingReducer)
);

export default reducer;
