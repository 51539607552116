import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DatePicker, Tooltip, Row } from 'antd';
import { CloseCircleTwoTone } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { useLocation } from 'react-router';
import styled from 'styled-components';
// models
import Company, { CompanyProfile, CompanyUserValuesKeys } from '@optx/models/Company';
// constants
import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';
import { ISO_DATE_FORMAT } from '@optx/constants/format/date';
import { LIST_OF_FUTURE_DATE_FIELDS } from '@optx/constants/future-date-fields';
// utils
import { isFutureDate, getLastRoundDisabledDate } from '@optx/utils/date';
// redux
import {
  actions as fundingRoundsActions,
  selectors as fundingRoundsSelectors,
} from '@redux/company/funding-rounds';
// hooks
import useUpdateFields from '../hooks/useUpdateFields';
import { ServiceType } from '../state/interfaces';
import { useOnClickOutside } from '@optx/common/hooks/useOnClickOutside';
// components
import { Styled } from '@optx/features/company/edit-fields/components/TableCellFit.styled';
import EditPopover from '@optx/components/common/popover/EditPopover';
import { InlineEdit } from '@optx/shared/view/molecules/edit/InlineEdit';

const DefaultTooltip = styled(Tooltip)`
  display: block;
`;

const loadValue = (dateValue: string | string[] | null, dateformat: string) => {
  if (dateValue && typeof dateValue === 'string') {
    const date = moment(dateValue).format(dateformat);
    if (date.toLocaleLowerCase() === 'invalid date') return [DEFAULT_CELL_VALUE];

    return [date];
  }

  if (Array.isArray(dateValue) && dateValue.length !== 0) {
    const dates = dateValue
      .filter(date => moment(date).format(dateformat).toLocaleLowerCase() !== 'invalid date')
      .map(date => moment(date).format(dateformat));

    return dates;
  }

  return [DEFAULT_CELL_VALUE];
};

interface EditFielDateProps {
  value: string | string[] | null;
  fieldName: 'Last Funding Date' | 'Date Presented' | 'Expected Close Date' | 'IC Status Date';
  isFromGrid: boolean;
  fieldKey: CompanyUserValuesKeys;
  hidePenIcon?: boolean;
  service: ServiceType;
  successMessage?: string;
  errorMessage?: string;
  record: Company | CompanyProfile;
  format: string;
  getPopupContainer?: ((triggerNode: HTMLElement) => HTMLElement) | undefined;
  isFromHistory?: boolean;
  isDynamicPopoverHeight?: boolean;
  destroyOnHide?: boolean;
}

const EditFieldDate: React.FC<EditFielDateProps> = ({
  record,
  value,
  fieldName,
  isFromGrid,
  hidePenIcon,
  service,
  successMessage,
  errorMessage,
  format,
  isFromHistory,
  fieldKey,
  getPopupContainer,
  isDynamicPopoverHeight,
  destroyOnHide,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { updateField } = useUpdateFields({
    fieldName,
    companyId: record.company_id,
    companyUrl: record.company_url,
    service,
    successMessage,
    errorMessage,
    isFromHistory,
  });

  const [dateValues, setDateValues] = useState<Array<string | number>>(loadValue(value, format));
  const [displayedDates, setDisplayedDates] = useState<Array<Moment | null>>([]);
  const formattedValue = loadValue(value, format);
  const [closePopup, setClosePopup] = useState<boolean>(false);
  const [isShowingToolTip, setIsShowingToolTip] = useState<boolean>(false);
  const [forceRenderKey, setForceRenderKey] = useState(0);
  const changedValue = useRef<string | number | undefined>(undefined);
  const lastValue = useRef<Array<string | number>>([]);
  const title = `Edit "${fieldName}" value`;
  const label = formattedValue.join(', ');

  const secondToLastRoundDate = useSelector(fundingRoundsSelectors.getSecondToLastRoundDate);
  const numberOfRounds = useSelector(fundingRoundsSelectors.getNumberOfRounds);

  const popoverContentRef = useRef(null);

  const isDatePresented = fieldKey === 'date_presented';

  const handleDateChange = (value: Moment | null, dateString: string, index: number) => {
    const newDateValues = [...dateValues];
    const newDates = [...displayedDates];
    newDates[index] = value;
    newDateValues[index] = dateString;

    setDateValues(newDateValues);
    lastValue.current = newDateValues;
    setDisplayedDates(newDates);
    changedValue.current = dateString;
  };

  const updateFieldCallback = () => {
    if (fieldName === 'Last Funding Date' && location.pathname.includes('profile')) {
      dispatch(fundingRoundsActions.getCompanyFundingRounds(record.company_id));
    }
  };

  const handleSave = () => {
    if (fieldName === 'Date Presented') {
      const values = lastValue.current
        .filter(
          value => !!value && moment(value, format).format(ISO_DATE_FORMAT) !== 'Invalid date'
        )
        .map(value => moment(value, format).format(ISO_DATE_FORMAT))
        .sort()
        .reverse();
      updateField(values.length !== 0 ? values : null, fieldKey, undefined);
    } else {
      updateField(
        lastValue.current[0] ? moment(lastValue.current[0], format).format(ISO_DATE_FORMAT) : null,
        fieldKey,
        undefined,
        updateFieldCallback
      );
    }

    setClosePopup(true);
    changedValue.current = undefined;
    handleCancel();
  };

  const handleCancel = () => {
    setClosePopup(true);
    setForceRenderKey(prevKey => prevKey + 1);
    changedValue.current = undefined;

    if (typeof value === 'string' && value && value !== DEFAULT_CELL_VALUE) {
      setDisplayedDates([moment(value)]);
      setDateValues(loadValue(value, format));
      lastValue.current = loadValue(value, format);
    } else if (Array.isArray(value) && value.length !== 0) {
      const dates = value
        .filter(date => moment(date).format(format).toLocaleLowerCase() !== 'invalid date')
        .map(date => moment(date));
      setDisplayedDates(dates);
      setDateValues(loadValue(value, format));
      lastValue.current = loadValue(value, format);
    } else {
      setDisplayedDates([null]);
      setDateValues(loadValue(value, format));
      lastValue.current = loadValue(value, format);
    }
  };

  const handleAddDate = () => {
    if (displayedDates.length >= 10) return;

    setDisplayedDates(prevState => [...prevState, null]);
  };

  const handleRemoveDate = (itemIndex: number) => {
    setDisplayedDates(prevState => [...prevState].filter((item, index) => index !== itemIndex));
    setDateValues(prevState => [...prevState].filter((item, index) => index !== itemIndex));
    changedValue.current = '';
  };

  useEffect(() => {
    lastValue.current = [...dateValues];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValues.length]);

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setTimeout(() => {
        handleSave();
      }, 400);
    }
  };

  const lastRoundDisabledDate = getLastRoundDisabledDate(
    moment(value),
    moment(secondToLastRoundDate),
    numberOfRounds
  );

  useOnClickOutside(popoverContentRef, handleCancel);

  const content = (
    <div
      ref={popoverContentRef}
      style={{ width: '320px' }}
      role="presentation"
      onKeyDown={handleDropdownKeyEvent}
      tabIndex={0}
    >
      {displayedDates.map((date, index) => (
        <Row key={index} style={{ padding: 0 }}>
          <DatePicker
            key={forceRenderKey}
            onChange={(value, dateString) => handleDateChange(value, dateString, index)}
            allowClear={fieldName !== 'Last Funding Date'}
            disabledDate={
              LIST_OF_FUTURE_DATE_FIELDS.includes(fieldName)
                ? undefined
                : fieldName === 'Last Funding Date'
                ? lastRoundDisabledDate
                : isFutureDate
            }
            value={date || undefined}
            defaultValue={date || undefined}
            style={{ width: isDatePresented ? '90%' : '100%', margin: '5px 0' }}
            format={format}
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          />
          {fieldName === 'Date Presented' && index > 0 && (
            <CloseCircleTwoTone
              className="remove_icon"
              twoToneColor="#f5222d"
              style={{
                color: '#f5222d',
                fontSize: 19,
                marginTop: 12,
                marginLeft: 10,
              }}
              onClick={() => handleRemoveDate(index)}
            />
          )}
        </Row>
      ))}
      {fieldName === 'Date Presented' && displayedDates.length < 10 && (
        <Button
          size="small"
          onClick={handleAddDate}
          style={{ marginTop: '10px', width: '90%', color: '#1890ff' }}
        >
          Add Another Date
        </Button>
      )}

      <div className="profile-information__popover-buttons">
        <Button className="profile-information__cancel" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          disabled={
            typeof changedValue.current !== 'string' || changedValue.current === formattedValue[0]
          }
          className="profile-information__save"
          type="primary"
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </div>
  );

  useEffect(() => {
    setDateValues(loadValue(value, format));

    if (typeof value === 'string' && value && value !== DEFAULT_CELL_VALUE) {
      setDisplayedDates([moment(value)]);
    } else if (Array.isArray(value) && value.length !== 0) {
      const dates = value
        .filter(date => moment(date).format(format).toLocaleLowerCase() !== 'invalid date')
        .map(date => moment(date));
      setDisplayedDates(dates);
    } else {
      setDisplayedDates([null]);
    }
  }, [format, value]);

  if (!isFromGrid) {
    return (
      <EditPopover
        content={content}
        title={title}
        closePopup={closePopup}
        onVisibilityChange={() => setIsShowingToolTip(false)}
        onClick={() => setIsShowingToolTip(true)}
        hidePenIcon={hidePenIcon}
        setClosePopup={setClosePopup}
        isDynamicPopoverHeight={isDynamicPopoverHeight}
        getPopupContainer={getPopupContainer}
        destroyOnHide={destroyOnHide}
      >
        {isFromHistory ? (
          !isShowingToolTip ? (
            <Tooltip overlayInnerStyle={{ padding: 6 }} title={label}>
              {hidePenIcon ? 'Edit Field' : label}
            </Tooltip>
          ) : hidePenIcon ? (
            'Edit Field'
          ) : (
            label
          )
        ) : (
          <Tooltip
            overlayClassName={'ant-tooltip-custom'}
            overlayInnerStyle={{ padding: 6 }}
            title={label}
          >
            {hidePenIcon ? 'Edit Field' : label}
          </Tooltip>
        )}
      </EditPopover>
    );
  }

  return (
    <InlineEdit
      action={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <EditPopover
          content={content}
          title={title}
          closePopup={closePopup}
          setClosePopup={setClosePopup}
        />
      }
      fill
    >
      <DefaultTooltip title={label}>
        <Styled.TableCellInner>{label}</Styled.TableCellInner>
      </DefaultTooltip>
    </InlineEdit>
  );
};

export default React.memo(EditFieldDate);
