import { fork } from 'redux-saga/effects';
// redux
import filters from './filters';
import search from './search';
import init from './init';
import touchesAcrossRegion from './touchesAcrossRegion';
import emailStatistics from './emailStatistics';
import smartTouchScore from './smartTouchScore';
import additionalStatistics from './additionalStatistics';
import pipelineInformation from './pipeline-information';

export default function* analystsLeaderboardSaga() {
  yield fork(init);
  yield fork(filters);
  yield fork(search);
  yield fork(touchesAcrossRegion);
  yield fork(emailStatistics);
  yield fork(smartTouchScore);
  yield fork(additionalStatistics);
  yield fork(pipelineInformation);
}
