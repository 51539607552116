import styled from 'styled-components';

const PrefixWrapper = styled.span`
  margin: 0 0 0 16px;
`;

const PrefixIconWrapper = styled.span`
  color: #595959;
`;

const Prefix = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: #595959;
  margin: 0 0 0 8px;
`;

export const Styled = {
  PrefixWrapper,
  Prefix,
  PrefixIconWrapper,
};
