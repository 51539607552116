import { createAction } from '@reduxjs/toolkit';
import { CompanyTouchesOptionsResponse } from '@optx/models/company/CompanyTouch';
import { CompanyTouchesFiltersInterface } from './interfaces';
import * as types from './types';

// fetch touches filters
export const fetchTouchesFilters = createAction<number | CompanyTouchesFiltersInterface>(
  types.FETCH_TOUCHES_FILTERS
);
export const fetchTouchesFiltersSuccess = createAction<CompanyTouchesOptionsResponse>(
  types.FETCH_TOUCHES_FILTERS_SUCCESS
);
export const fetchTouchesFiltersFail = createAction<string>(types.FETCH_TOUCHES_FILTERS_FAIL);
