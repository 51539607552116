/* eslint-disable no-irregular-whitespace */
import { Modal as M } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import styled from 'styled-components';

interface ModalWrapperProps extends ModalProps {
  isExtension?: boolean;
}
const Modal = styled(M)<ModalWrapperProps>`
  width: ${({ isExtension }) => (isExtension ? '590px' : '724px')} !important;
  transform: ${({ isExtension }) => (isExtension ? 'translateX(-250px)' : 'inherit')} !important;
`;

const Styles = {
  Modal,
};

export default Styles;
