import React from 'react';
import {
  PhoneOutlined,
  MailOutlined,
  ClockCircleOutlined,
  FileProtectOutlined,
  ContactsOutlined,
  FileTextOutlined,
  FileExclamationOutlined,
  DoubleRightOutlined,
} from '@ant-design/icons';
import Icon from '@optx/components/common/Icon';
import { TouchType } from '@models/company/CompanyTouch';

interface TouchIconProps {
  type: TouchType;
  className?: string;
  color?: string;
}

const styles: React.CSSProperties = {
  fontSize: 19,
  color: '#1890FF',
};

const TouchIcon: React.FC<TouchIconProps> = ({ type, className, color }) => {
  const iconColor = color ? color : styles.color;

  switch (type) {
    case 'Call':
      return <PhoneOutlined style={{ ...styles, color: iconColor }} className={className} />;
    case 'Contact Import':
      return <ContactsOutlined style={{ ...styles, color: iconColor }} className={className} />;
    case 'CrunchBase':
      return (
        <Icon iconName="crunchbase" style={{ ...styles, color: iconColor }} className={className} />
      );
    case 'Internal Note':
      return (
        <FileExclamationOutlined style={{ ...styles, color: iconColor }} className={className} />
      );
    case 'E-mail':
    case 'Voice Mail':
      return <MailOutlined style={{ ...styles, color: iconColor }} className={className} />;
    case 'Meeting':
    case 'Introduction':
    case 'Follow-up':
    case 'Mgmt Call':
    case 'Expert Call':
    case 'Product Demo':
    case 'Pre-analysis':
    case 'VC Meeting':
    case 'IB Meeting':
    case 'Other':
      return (
        <Icon
          iconName="people-outlined"
          style={{ ...styles, color: iconColor }}
          className={className}
        />
      );
    case 'NDA Signed':
      return <FileProtectOutlined style={{ ...styles, color: iconColor }} className={className} />;
    case 'Stage Modified':
      return <ClockCircleOutlined style={{ ...styles, color: iconColor }} className={className} />;
    case 'Transferred':
      return <DoubleRightOutlined style={{ ...styles, color: iconColor }} className={className} />;
    default:
      return <FileTextOutlined style={{ ...styles, color: iconColor }} className={className} />;
  }
};

export default TouchIcon;
