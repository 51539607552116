/**
 * @description The storage item name
 * @type {string}
 */
export const MENU_COLLAPSED = 'menu_collapsed';

/**
 * @description Set value to localStorage
 * @param {boolean} value item value
 */
const setMenuCollapsed = (value: boolean) => {
  localStorage.setItem(MENU_COLLAPSED, value.toString());
};

/**
 * @description remove menu_collapsed from localStorage
 */
const removeMenuCollapsed = () => {
  localStorage.removeItem(MENU_COLLAPSED);
};

export default {
  setMenuCollapsed,
  removeMenuCollapsed,
};
