import React from 'react';
import styled from 'styled-components';
import { Checkbox as $Checkbox } from 'antd';

interface SelectAllWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  hasCustomNoTouchesOption?: boolean;
}

const SelectAllWrapper = styled.div<SelectAllWrapperProps>`
  cursor: pointer;
  padding: ${props => (props.hasCustomNoTouchesOption ? '12px 12px 8px' : '15px 12px 17px')};
  border-bottom: ${props => (props.hasCustomNoTouchesOption ? 'inherit' : '1px solid #d9d9d9')};
  margin-bottom: ${props => (props.hasCustomNoTouchesOption ? '0' : '8px')};

  background-color: transparent;
  display: block;
  font-size: inherit;
  width: 100%;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;

  &:hover {
    background-color: #deebff;
  }
`;

const Checkbox = styled($Checkbox)`
  margin: 0 8px 0 0;
`;

export const Styled = {
  SelectAllWrapper,
  Checkbox,
};
