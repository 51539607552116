import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import queryString from 'query-string';
// models
import { FilterSource } from '@optx/models/filters';
import { CompanyFiltersMeta } from '@optx/models/search';
import Company from '@optx/models/Company';
import { FormCheckboxOption, SelectOption } from '@optx/models/Option';
import { SearchPayload } from '@optx/models/api/contacts';
import { UserInformation } from '@optx/models/user';
import { SearchStateData } from '@models/search';
import { GridPayload } from '@models/grid';
// constants
// utils
import { mapPreselectedFilters } from '@optx/utils/filters/preselectedValues';
import { parseFilter } from '@utils/filters/parseFilters';
// interfaces
import {
  SourcingOutReachFilterState,
  SourcingFilterOptions,
  DefaultSourcingOutReachFilters,
  UpdateSourcingOutReachFiltersPayload,
} from './interfaces';
// redux
import { actions as userInformationActions } from '../../user/information';
import { actions as companyFiltersActions } from '../../company/filters';
import { actions as searchActions } from '@features/grid/search';
import * as actions from './actions';

export const initialState: SourcingOutReachFilterState = {
  filter: {},
  sourcingOutReachFilter: {} as FilterSource,
  sourcingOutReachFilterOptions: {},
  customClearedfilter: {},
  clear: {},
  label: null,
  preselectedFilter: {},
  fetchedAt: '',
  cards: {},
  selectedCard: null,
  selectedCardId: '',
  subFilter: '',
  panelFilters: '',
};

const fetchSourcingOutReachSearchSuccessReducer: CaseReducer<
  SourcingOutReachFilterState,
  PayloadAction<Array<Company>, any, GridPayload<Partial<SearchPayload>>>
> = (draftState, action) => {
  const { gridKey, data } = action.meta;

  if (gridKey === 'outreach' && data.filter) draftState.filter = data.filter;
};

const updateSourcingOutReachFilterOptionsSuccessReducer: CaseReducer<
  SourcingOutReachFilterState,
  PayloadAction<SourcingFilterOptions>
> = (draftState, action) => {
  if (draftState.sourcingOutReachFilterOptions) {
    draftState.sourcingOutReachFilterOptions[action.payload.filterKey] = action.payload.value;
    draftState.label = action.payload.label;
  }
};

const updateSourcingOutReachFiltersReducer: CaseReducer<
  SourcingOutReachFilterState,
  PayloadAction<UpdateSourcingOutReachFiltersPayload>
> = (draftState, action) => {
  // for panelFiltersQuery we currently have 2 cases:
  // 1. if shouldRemoveFilter is true, then panelFiltersQuery contains just the filter key
  // 2. otherwise, it's the entire query of filters
  const { panelFiltersQuery, shouldRemoveFilter } = action.payload;
  const { pipeline_rank, ...restFilters } = queryString.parse(panelFiltersQuery, {
    arrayFormat: 'comma',
  });

  if (draftState.panelFilters !== panelFiltersQuery) {
    if (shouldRemoveFilter) {
      draftState.panelFilters = queryString.stringify(restFilters);

      // when removing the filter we use the first case,
      // so we can use panelFiltersQuery as a key in filter and clear Dictionaries
      draftState.filter[panelFiltersQuery] = draftState.clear[panelFiltersQuery];

      return;
    } else {
      draftState.panelFilters = panelFiltersQuery;
    }
  }

  if (pipeline_rank) {
    const newPipelineRank = (draftState.filter.pipeline_rank as FormCheckboxOption[]).map(rank =>
      rank.value === pipeline_rank ? { ...rank, checked: true } : rank
    );

    draftState.filter = { ...draftState.filter, pipeline_rank: newPipelineRank };
  }
};

const clearSourcingOutReachFilterOptionsReducer: CaseReducer<
  SourcingOutReachFilterState
> = draftState => {
  const filterKeys = ['analyst_id', 'column_name', 'ddate'];

  if (draftState.sourcingOutReachFilterOptions) {
    filterKeys.forEach((_, index) => {
      draftState.sourcingOutReachFilterOptions![filterKeys[index]] = undefined;
    });
  }
};

const fetchSourcingOutReachFiltersSuccessReducer: CaseReducer<
  SourcingOutReachFilterState,
  PayloadAction<FilterSource>
> = (draftState, action) => {
  // @ts-ignore
  draftState.sourcingOutReachFilter = action.payload[0];
};

// Update persisted filter to be used when filters are loaded.
const fetchUserInformationSuccessReducer: CaseReducer<
  SourcingOutReachFilterState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const persistedFilter = action.payload.settings.session_settings
    ? action.payload.settings.session_settings.sourcing_outreach_detail_filters
    : undefined;

  const selectedCardId = action.payload.settings.session_settings
    ? action.payload.settings.session_settings.my_companies_selected_card_id
    : '';

  if (persistedFilter !== undefined) {
    draftState.persistedFilter = persistedFilter;
  }

  if (selectedCardId) {
    draftState.selectedCardId = selectedCardId;
  }

  draftState.sessionFilter =
    action.payload.settings.session_settings?.sourcing_outreach_detail_filters;
};

const setDefaultSourcingOutReachFiltersReducer: CaseReducer<
  SourcingOutReachFilterState,
  PayloadAction<DefaultSourcingOutReachFilters>
> = (draftState, action) => {
  if (draftState.sourcingOutReachFilterOptions) {
    const defaultDate = [
      action.payload.tableGridQuery.ddate_lower,
      action.payload.tableGridQuery.ddate_upper,
    ];

    const listOfAnalystIds = action.payload.listOfAnalyst as unknown as SelectOption<string>[];

    const listOfColumnNames = action.payload.listOfColumNames as unknown as SelectOption<string>[];

    const columnName = listOfColumnNames.find(column => {
      return column.value === action.payload.tableGridQuery.column_name;
    });

    draftState.sourcingOutReachFilterOptions.column_name =
      columnName as unknown as SelectOption<string>;

    draftState.columnNamesFilterDefault = columnName as unknown as SelectOption<string>[];

    draftState.dateFilterDefault = defaultDate as [string | null, string | null];

    draftState.sourcingOutReachFilterOptions.ddate = defaultDate as [string | null, string | null];

    const persistedFiltersKeys = queryString.parse(draftState.sessionFilter!);

    let tableGridQueryAnalystId = '';

    if (persistedFiltersKeys.analyst_id === '') {
      draftState.sourcingOutReachFilterOptions.analyst_id = [] as unknown as SelectOption<string>;
      draftState.analystIdsFilterDefault = [] as unknown as SelectOption<string>[];
    } else if (persistedFiltersKeys.analyst_id) {
      tableGridQueryAnalystId = persistedFiltersKeys.analyst_id as string;
    } else {
      const payloadAnalystIdList = action.payload.tableGridQuery.analyst_id;

      if (payloadAnalystIdList) {
        tableGridQueryAnalystId = Array.isArray(payloadAnalystIdList)
          ? payloadAnalystIdList.join(',')
          : payloadAnalystIdList;
      }
    }

    if (tableGridQueryAnalystId) {
      let analystId: SelectOption[] = [];

      if (tableGridQueryAnalystId === 'All') {
        analystId = [{ label: 'All', value: 'All' }];
      } else {
        const listOfPersistedAnalystIds = tableGridQueryAnalystId
          .split(',')
          .map(list => Number(list));

        analystId = listOfAnalystIds.filter(analyst =>
          listOfPersistedAnalystIds.includes(analyst.value as unknown as number)
        );
      }

      draftState.sourcingOutReachFilterOptions.analyst_id =
        analystId as unknown as SelectOption<string>;

      draftState.analystIdsFilterDefault = analystId as unknown as SelectOption<string>[];
    }

    if (persistedFiltersKeys.column_name) {
      const listOfColumnNames = action.payload
        .listOfColumNames as unknown as SelectOption<string>[];

      const columnName = listOfColumnNames.find(column => {
        return column.value === persistedFiltersKeys.column_name;
      });

      draftState.sourcingOutReachFilterOptions.column_name =
        columnName as unknown as SelectOption<string>;
    }

    if (persistedFiltersKeys.ddate_lower && persistedFiltersKeys.ddate_upper) {
      draftState.sourcingOutReachFilterOptions.ddate = [
        persistedFiltersKeys.ddate_lower as any,
        persistedFiltersKeys.ddate_upper as any,
      ];
    }
  }
};

export const fetchFiltersSuccessReducer: CaseReducer<
  SourcingOutReachFilterState,
  PayloadAction<Array<FilterSource>, any, CompanyFiltersMeta>
> = (draftState, action) => {
  const clearedFilter = mapPreselectedFilters(action.payload, false);
  const customClearedfilter = {
    ...clearedFilter,
    ...draftState.preselectedFilter,
  };
  draftState.customClearedfilter = customClearedfilter;
  draftState.clear = clearedFilter;

  if (draftState.persistedFilter !== undefined) {
    // Load persisted filter if available.
    const [parsedFilters] = parseFilter(draftState.persistedFilter, action.payload);
    draftState.filter = parsedFilters;
  } else {
    draftState.filter = customClearedfilter;
  }

  // remove persisted filter, is not needed anymore.
  delete draftState.persistedFilter;
  draftState.fetchedAt = new Date().toISOString();
};

const resetSessionFilterReducer: CaseReducer<SourcingOutReachFilterState> = draftState => {
  const customClearedFilter = draftState.customClearedfilter;
  draftState.sessionFilter = '';
  draftState.filter = customClearedFilter;
  draftState.sourcingOutReachFilterOptions = {};
};

const changeCardReducer: CaseReducer<SourcingOutReachFilterState, PayloadAction<string>> = (
  draftState,
  action
) => {
  draftState.selectedCard = { ...draftState.cards[action.payload], id: action.payload };
  draftState.selectedCardId = action.payload;
  draftState.subFilter = '';

  draftState.filter = {
    ...draftState.preselectedFilter,
    ...draftState.filter,
  };
  draftState.customClearedfilter = {
    ...draftState.customClearedfilter,
    ...draftState.preselectedFilter,
  };
};

const changeCardSubfilterSuccessReducer: CaseReducer<
  SourcingOutReachFilterState,
  PayloadAction<{ cardId: string; subFilter: string }>
> = (draftState, action) => {
  const { cardId, subFilter } = action.payload;
  draftState.selectedCard = { ...draftState.cards[cardId], id: cardId };
  draftState.selectedCardId = cardId;
  draftState.subFilter = subFilter;
};

const clearFilterSuccessReducer: CaseReducer<
  SourcingOutReachFilterState,
  PayloadAction<SearchStateData | null | undefined>
> = (draftState, action) => {
  const { payload: selectedCard } = action;

  if (selectedCard) {
    draftState.selectedCard = selectedCard;
  }

  draftState.subFilter = '';
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(companyFiltersActions.fetchCompanyFiltersSuccess, fetchFiltersSuccessReducer)
    .addCase(actions.updateSourcingFilterOptions, updateSourcingOutReachFilterOptionsSuccessReducer)
    .addCase(actions.updateSourcingOutReachFilters, updateSourcingOutReachFiltersReducer)
    .addCase(actions.resetStoreFilter, resetSessionFilterReducer)
    .addCase(actions.setDefaultSourcingOutReachFilters, setDefaultSourcingOutReachFiltersReducer)
    .addCase(
      actions.fetchSourcingOutReachFiltersSuccess,
      fetchSourcingOutReachFiltersSuccessReducer
    )
    .addCase(searchActions.searchCompaniesSuccess, fetchSourcingOutReachSearchSuccessReducer)
    .addCase(searchActions.clearSearch, clearSourcingOutReachFilterOptionsReducer)
    .addCase(searchActions.clearFilterSuccess, clearFilterSuccessReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
    .addCase(actions.changeCard, changeCardReducer)
    .addCase(actions.changeCardSubfilterSuccess, changeCardSubfilterSuccessReducer)
);

export default reducer;
