import React from 'react';
import { CompanyColumnDisplay, Column } from '@optx/models/table/Columns';
import { columnDisplayInitialState } from '@optx/constants/table/columnDisplay/company-search';
import { cloneDeep } from 'lodash';

interface TableColumnFilterContextContext {
  columnDisplay: CompanyColumnDisplay<any>;
  setColumnDisplay: (columnDisplay: CompanyColumnDisplay<any>) => void;
  sortableColumns: Column[];
  setSortableColumns: (sortableColumns: Column[]) => void;
  pinnedColumns?: Column[];
  setPinnedColumns?: (pinnedColumns: Column[]) => void;
}

export const TableColumnFilterContext = React.createContext<TableColumnFilterContextContext>({
  columnDisplay: cloneDeep(columnDisplayInitialState),
  setColumnDisplay: columnDisplay => {},
  sortableColumns: [],
  setSortableColumns: sortableColumns => {},
  pinnedColumns: [],
  setPinnedColumns: pinnedColumns => {},
});
