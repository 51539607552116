import { forEach } from 'lodash';
import { HistogramSlider } from '@optx/models/filters';
import { HistogramFiltersState } from './interfaces';

/**
 * Select histogram when in edit mode.
 * @param state
 */
export const selectEditHistograms = (state: HistogramFiltersState) => {
  if (state.edit) {
    return state.edit.data;
  }

  return state.data;
};

/**
 * Histogram data.
 * @param state
 */
export const histograms = (state: HistogramFiltersState) => state.data;

export const allFiltersWithHistograms = (state: HistogramFiltersState) => state.allFilters;

export const searchCount = (state: HistogramFiltersState) => state.searchCount;

export const changedFilters = (state: HistogramFiltersState) => {
  return state.edit ? state.edit.changedFilters : state.changedFilters;
};

export const loading = (state: HistogramFiltersState) => state.loading;

export const isInitialized = (state: HistogramFiltersState) => !!state.fetchedAt;

export const histogramRequestFilters = (state: HistogramFiltersState) => {
  const { lastChangedFilter, allFilters } = state;

  // Should return all filters except the last changed filter if the filter contains histogram.
  return allFilters.filter(filter => filter !== lastChangedFilter);
};

export const defaultHistograms = (state: HistogramFiltersState) => state.defaultHistograms;

export const isEnabled = (state: HistogramFiltersState) => state.enabled;

export const histogramsAreEmpty = (state: HistogramFiltersState) => {
  const { data, enabled } = state;

  if (!enabled) {
    return true;
  }

  let histogramsAreEmpty = true;

  forEach(data, histogram => {
    const { data: histogramInfo } = histogram as HistogramSlider;

    if (histogramInfo) {
      histogramsAreEmpty = false;

      return false;
    }

    return undefined;
  });

  return histogramsAreEmpty;
};
