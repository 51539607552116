import { GridKeys } from '@optx/models/grid';

const ownStylesOutReach: React.CSSProperties = {
  fontSize: 16,
  fontWeight: 500,
  color: 'white',
};

export const searchCountFullScreenStyle: React.CSSProperties = {
  fontSize: 16,
  color: '#8997B1',
  display: 'inline-flex',
  gap: '4px',
  alignItems: 'center',
  minWidth: 'fit-content',
  flex: 1,
};

const ownStylesAdvancedSearch: React.CSSProperties = {
  fontSize: 16,
  color: '#8997B1',
  display: 'inline-flex',
  gap: '4px',
  alignItems: 'center',
  minWidth: 'fit-content',
  flex: 1,
};

const ownStylesContacts: React.CSSProperties = {
  fontSize: 20,
  color: '#8997B1',
};

const ownStylesWatchlistsAndSSlists: React.CSSProperties = {
  fontSize: 16,
  color: '#8997B1',
};

const ownStylesChecklistResearchInsights: React.CSSProperties = {
  fontSize: '20px',
  fontWeight: 400,
  color: '#8997B1',
};

export const searchCountOwnStyles: Record<GridKeys, React.CSSProperties> = {
  outreach: ownStylesOutReach,
  advancedSearch: ownStylesAdvancedSearch,
  addons: ownStylesAdvancedSearch,
  contacts: ownStylesContacts,
  myCompanies: {},
  sslists: ownStylesWatchlistsAndSSlists,
  watchlists: ownStylesWatchlistsAndSSlists,
  lists: {},
  checklistResearchInsights: ownStylesChecklistResearchInsights,
};
