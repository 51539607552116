import { isEqual } from 'lodash';
import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// models
import { SortByRule, SortRule } from '@models/table/sorting';
import { UserInformation } from '@optx/models/user';
// constants
import { DEFAULT_SORT_ANALYST } from '../../constants/table';
// utils
import { parseSorting } from '@optx/utils/filters/parseSorting';
// redux
import { actions as userInformationActions } from '@redux/user/information';
import { SortState } from '../interfaces';
import { actions } from '../actions';

export const initialState: SortState = {
  sortBy: DEFAULT_SORT_ANALYST,
  multiSort: false,
};

const sortReducer: CaseReducer<SortState, PayloadAction<Array<SortByRule<any>>>> = (
  draftState,
  action
) => {
  draftState.sortBy = action.payload;
};

export const createMultiSortReducer =
  (
    initState = initialState
  ): CaseReducer<SortState, PayloadAction<boolean, any, Array<SortByRule<any>> | undefined>> =>
  (draftState, action) => {
    const multiSort = action.payload;
    draftState.multiSort = multiSort;

    if (!multiSort) {
      draftState.sortBy = initState.sortBy;
    }
  };

const multiSortReducer = createMultiSortReducer(initialState);

const resetMultiSortReducer: CaseReducer<SortState> = draftState => {
  draftState.multiSort = false;
};

export const fetchUserInformationSuccessReducer: CaseReducer<
  SortState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const filters = action.payload.settings.session_settings?.checklist_insights_companies_filters;

  if (filters) {
    const { sortBy, isMultiSort } = parseSorting(filters);
    draftState.sortBy = sortBy;
    draftState.multiSort = isMultiSort;
  }
};

export const changeSortReducer: CaseReducer<SortState, PayloadAction<SortRule<any>>> = (
  draftState,
  action
) => {
  if (action.payload.sortBy) {
    draftState.sortBy = action.payload.sortBy;
  } else {
    draftState.sortBy = action.payload as any;
  }
};

const changeSortSuccessReducer: CaseReducer<SortState, PayloadAction<SortRule<any>>> = (
  draftState,
  action
) => {
  if (!isEqual(draftState.sortBy, action.payload.sortBy)) {
    draftState.sortBy = action.payload.sortBy;
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.sort.sort, sortReducer)
    .addCase(actions.sort.multiSort, multiSortReducer)
    .addCase(actions.sort.resetMultiSort, resetMultiSortReducer)
    .addCase(actions.sort.changeSort, changeSortReducer)
    .addCase(actions.sort.changeSortSuccess, changeSortSuccessReducer)
    // external reducer
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
);

export default reducer;
