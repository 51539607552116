import React, { useEffect } from 'react';
import { Col, Typography } from 'antd';
import { Form, Input } from 'formik-antd';
import { useField } from 'formik';
import { isEqual } from 'lodash';
// models
import { BaseField } from '@optx/models/companyFields';
import { FieldUsedFor } from './interface';

interface InputPhoneFieldProps {
  field: BaseField;
  fieldUsedFor?: FieldUsedFor;
}

const InputPhoneField: React.FC<InputPhoneFieldProps> = ({ field, fieldUsedFor }) => {
  const [selected, , setSelected] = useField<string>(field?.id || '');

  useEffect(() => {
    if ((field?.value || field?.value === '') && fieldUsedFor !== 'editAllInfo') {
      if (!isEqual(field.value, selected.value)) {
        setSelected.setValue(field.value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field?.value]);

  return (
    <Col span={8} className={field.id}>
      <Form.Item name={field.id}>
        <Typography.Text>{field.label}</Typography.Text>
        <Input
          name={field.id}
          placeholder={field.placeholder}
          disabled={!field.editable}
          value={selected.value ?? ''}
          fast
        />
      </Form.Item>
    </Col>
  );
};

export default React.memo(InputPhoneField);
