import React from 'react';
import { ColumnType } from 'antd/lib/table';

// models
import { SourcingOutreachUserData } from '@optx/features/sourcing-outreach-summary/models/SourcingOutreach';
// components
import { TableCellDefault } from '@optx/components/common/table/AntTable/cells';
import { TableCellCompaniesCount } from './cells/TableCellCompaniesCount';
import { TableColumnTitle } from './cells/TableColumnTitle';

const columns: Array<ColumnType<SourcingOutreachUserData>> = [
  {
    dataIndex: 'user_name',
    title: 'Analysts / Associate Name',
    render: TableCellDefault,
    sorter: true,
    width: 150,
    fixed: 'left',
    className: 'user_name',
  },
  {
    dataIndex: 'companies_reached',
    title: 'Companies Touched',
    render: (value: number, record: SourcingOutreachUserData) => (
      <TableCellCompaniesCount
        value={value}
        analystName={record.user_name}
        columnName="companies_reached"
      />
    ),
    sorter: true,
    width: 105,
    className: 'companies_reached',
  },
  {
    dataIndex: 'total_dtl',
    title: 'Owned Companies',
    render: (value: number, record: SourcingOutreachUserData) => (
      <TableCellCompaniesCount
        value={value}
        analystName={record.user_name}
        columnName="total_dtl"
      />
    ),
    sorter: true,
    width: 105,
    className: 'total_dtl',
  },
  {
    dataIndex: 'past_due_touches',
    title: 'Companies with Incomplete Touches',
    render: (value: number, record: SourcingOutreachUserData) => (
      <TableCellCompaniesCount
        value={value}
        analystName={record.user_name}
        columnName="past_due_touches"
      />
    ),
    sorter: true,
    width: 105,
    className: 'past_due_touches',
  },
  {
    dataIndex: 'stale_touches',
    title: 'Companies with Stale Touches',
    render: (value: number, record: SourcingOutreachUserData) => (
      <TableCellCompaniesCount
        value={value}
        analystName={record.user_name}
        columnName="stale_touches"
      />
    ),
    sorter: true,
    width: 100,
    className: 'stale_touches',
  },
  {
    dataIndex: 'stale_accounts',
    title: () => <TableColumnTitle title="Stale Accounts" columnName="stale_accounts" />,
    render: (value: number, record: SourcingOutreachUserData) => (
      <TableCellCompaniesCount
        value={value}
        analystName={record.user_name}
        columnName="stale_accounts"
      />
    ),
    sorter: true,
    width: 100,
    className: 'stale_accounts',
  },
  {
    dataIndex: 'companies_added',
    title: 'New Companies',
    render: (value: number, record: SourcingOutreachUserData) => (
      <TableCellCompaniesCount
        value={value}
        analystName={record.user_name}
        columnName="companies_added"
      />
    ),
    sorter: true,
    width: 105,
    className: 'companies_added',
  },
  {
    dataIndex: 'revived_touches',
    title: () => <TableColumnTitle title="Revived" columnName="revived_touches" />,
    render: (value: number, record: SourcingOutreachUserData) => (
      <TableCellCompaniesCount
        value={value}
        analystName={record.user_name}
        columnName="revived_touches"
      />
    ),
    sorter: true,
    width: 90,
    className: 'revived_touches',
  },
  {
    dataIndex: 'transferred_to_touches',
    title: 'Transferred To',
    render: (value: number, record: SourcingOutreachUserData) => (
      <TableCellCompaniesCount
        value={value}
        analystName={record.user_name}
        columnName="transferred_to_touches"
      />
    ),
    sorter: true,
    width: 105,
    className: 'transferred_to',
  },
  {
    dataIndex: 'transferred_from_touches',
    title: 'Transferred From',
    render: (value: number, record: SourcingOutreachUserData) => (
      <TableCellCompaniesCount
        value={value}
        analystName={record.user_name}
        columnName="transferred_from_touches"
      />
    ),
    sorter: true,
    width: 105,
    className: 'transferred_from_touches',
  },
  {
    dataIndex: 'emails_sent',
    title: 'Emails Sent',
    render: (value: number, record: SourcingOutreachUserData) => (
      <TableCellCompaniesCount
        value={value}
        analystName={record.user_name}
        columnName="emails_sent"
      />
    ),
    sorter: true,
    width: 90,
    className: 'emails_sent',
  },
  {
    dataIndex: 'calls',
    title: 'Calls Completed',
    render: (value: number, record: SourcingOutreachUserData) => (
      <TableCellCompaniesCount value={value} analystName={record.user_name} columnName="calls" />
    ),
    sorter: true,
    width: 100,
    className: 'calls',
  },
  {
    dataIndex: 'meetings',
    title: 'Meetings Completed',
    render: (value: number, record: SourcingOutreachUserData) => (
      <TableCellCompaniesCount value={value} analystName={record.user_name} columnName="meetings" />
    ),
    sorter: true,
    width: 100,
    className: 'meetings',
  },
  {
    dataIndex: 'stage_change',
    title: 'Stage Modified',
    render: (value: number, record: SourcingOutreachUserData) => (
      <TableCellCompaniesCount
        value={value}
        analystName={record.user_name}
        columnName="stage_change"
      />
    ),
    sorter: true,
    width: 100,
    className: 'stage_change',
  },
  {
    dataIndex: 'start_qo_touches',
    title: 'Start (QO)',
    render: (value: number, record: SourcingOutreachUserData) => (
      <TableCellCompaniesCount
        value={value}
        analystName={record.user_name}
        columnName="start_qo_touches"
      />
    ),
    sorter: true,
    width: 105,
    className: 'start_qo_touches',
  },
  {
    dataIndex: 'end_qo_touches',
    title: 'End (QO)',
    render: (value: number, record: SourcingOutreachUserData) => (
      <TableCellCompaniesCount
        value={value}
        analystName={record.user_name}
        columnName="end_qo_touches"
      />
    ),
    sorter: true,
    width: 105,
    className: 'end_qo_touches',
  },
  {
    dataIndex: 'qualified_opportunities_platform',
    title: () => (
      <TableColumnTitle title="Net New (QO)" columnName="qualified_opportunities_platform" />
    ),
    render: (value: number, record: SourcingOutreachUserData) => (
      <TableCellCompaniesCount
        value={value}
        analystName={record.user_name}
        columnName="qualified_opportunities_platform"
      />
    ),
    sorter: true,
    width: 110,
    className: 'qualified_opportunities_platform',
  },
];

export default columns;
