import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// models
import { SaveSearchDialogState } from '@redux/ui/modals/save-search/interfaces';
// redux
import { toggleReducer } from '@redux/feature/toggle/reducers';
import { toggleSaveSearchModal } from './actions';

const initialState: SaveSearchDialogState = {
  isOpen: false,
};

const toggleSaveSearchModalReducer: CaseReducer<
  SaveSearchDialogState,
  PayloadAction<string | number | undefined>
> = (draftState, action) => {
  draftState.isOpen = toggleReducer(draftState.isOpen);

  if (action.payload) {
    draftState.id = action.payload;
  } else if (draftState.id && !draftState.isOpen) {
    draftState.id = undefined;
  }
};

const saveSearchReducer = createReducer(initialState, builder =>
  builder.addCase(toggleSaveSearchModal, toggleSaveSearchModalReducer)
);

export default saveSearchReducer;
