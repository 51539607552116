import { createSelector } from 'reselect';
import { AppState } from '@optx/redux/interfaces';
import { WatchlistsSorting } from '@optx/models/WatchList';
import { hasEditPermission } from '@optx/utils/lists';
import { selectors as fetchSelectors } from '@redux/feature/fetch';
import { selectors as userSelectors } from '@redux/user/information';

// specific selectors
const selectListsState = (state: AppState) => state.favoriteLists;

const selectListsPopupSate = createSelector(selectListsState, state => state.listsPopup);

export const isLoading = createSelector(selectListsPopupSate, fetchSelectors.isLoadingSelector);

export const getError = createSelector(selectListsPopupSate, fetchSelectors.errorSelector);

export const shouldFetch = createSelector(
  selectListsPopupSate,
  state => !state.loading && !state.fetchedAt && !state.error
);

export const shouldInitialFetch = createSelector(
  userSelectors.loaded,
  shouldFetch,
  (userInfoLoaded, shouldFetch) => userInfoLoaded && shouldFetch
);

export const getAllListIds = createSelector(selectListsPopupSate, state => state.allIds);

export const getListsNormalized = createSelector(selectListsPopupSate, state =>
  state.allIds.map(id => state.allWatchlists[id])
);

export const getAllWatchlists = createSelector(selectListsPopupSate, state => state.allWatchlists);

export const getAllListAccessRightLists = createSelector(selectListsPopupSate, state =>
  Object.values(state.allWatchlists).filter(list => hasEditPermission(list.share_permission))
);

export const getSortBy = createSelector(selectListsPopupSate, state => state.sortBy);

export const getSortSelect = createSelector(selectListsPopupSate, state => {
  let newValue: WatchlistsSorting = 'recently';
  let label = '';

  if (state.sortBy.length) {
    if (state.sortBy[0].id === 'recently_used' && state.sortBy[0].desc === true) {
      newValue = 'recently';
      label = 'Recently accessed';
    } else if (state.sortBy[0].id === 'title' && state.sortBy[0].desc === false) {
      newValue = 'title-asc';
      label = 'Name A to Z';
    } else if (state.sortBy[0].id === 'title' && state.sortBy[0].desc === true) {
      newValue = 'title-desc';
      label = 'Name Z to A';
    } else if (state.sortBy[0].id === 'owner_name' && state.sortBy[0].desc === false) {
      newValue = 'owner';
      label = 'Owner';
    }
  }

  return { value: newValue, label };
});

export const getWatchlists = (lists: number[]) => {
  return createSelector(selectListsPopupSate, state => {
    const allWatchlists = state.allWatchlists;

    return lists.map(id =>
      Object.values(allWatchlists).find(watchlist => watchlist.unique_id === id)
    );
  });
};

export const getWatchlistsById = () => {
  return createSelector(selectListsPopupSate, state => {
    const allWatchlists = state.allWatchlists;
    const allIds = state.allIds;
    const allWatchlistsById: Array<any> = [];
    allIds.forEach(itemId => {
      allWatchlistsById.push(allWatchlists[itemId]);
    });

    return allWatchlistsById;
  });
};

export const showEmptyLists = createSelector(selectListsPopupSate, state => state.showEmptyLists);
