import { createReducer } from '@reduxjs/toolkit';
// models
import { DefaultState, CompanyActivityResponse } from '../interfaces';
// redux
import { actions } from '../actions';
import { fetchReducer, fetchFailReducer, fetchSuccessReducer } from '@redux/feature/fetch/reducers';

export const initialState: DefaultState<CompanyActivityResponse> = {
  data: null,
  loading: false,
  error: '',
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.company.fetchCompanyActivity, fetchReducer)
    .addCase(actions.company.fetchCompanyActivitySuccess, fetchSuccessReducer)
    .addCase(actions.company.fetchCompanyActivityFail, fetchFailReducer)
);

export default reducer;
