import React from 'react';
import { TooltipProps } from 'recharts';
// styles
import StyledTooltip from '@optx/components/common/charts/Tooltip.styles';
import { ChartResponse } from '@optx/models/feature/equity-touch-integration/charts';
import Styled from './ChartTooltip.styled';

interface ChartTooltip extends TooltipProps {
  config: {
    label: string;
    secondLabel: (item: ChartResponse) => string;
    dataKeys: {
      label: string;
      isBlue: boolean;
      value: (item: ChartResponse) => number | string;
    }[];
  };
}

const ChartTooltip = ({ active, payload, config }: ChartTooltip) => {
  if (active && payload) {
    const item = payload[0]?.payload as ChartResponse;

    return (
      <StyledTooltip.Wrapper>
        <Styled.Label>{config.label}</Styled.Label>
        <Styled.Label>{config.secondLabel(item)}</Styled.Label>
        {config.dataKeys.map((data, index) => (
          <Styled.Total key={index} $selected={data.isBlue}>
            {`${data.label} ${data.value(item)}`}
          </Styled.Total>
        ))}
      </StyledTooltip.Wrapper>
    );
  }

  return null;
};

export default ChartTooltip;
