import React, { useMemo } from 'react';
import { keys } from 'lodash';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
// inteface
import { MyCompaniesCardDataProps } from '../../common/cards/MyCompaniesCard/interface';
// utils
import { getMyCompaniesCardsList } from '../../../utils/myCompanies';
// redux
import {
  actions as filterCardsActions,
  selectors as filterCardsSelectors,
} from '../../../redux/my-companies/filters-cards';
import { selectors as userInformationSelectors } from '../../../redux/user/information';
// components
import MyCompaniesCard from '../../common/cards/MyCompaniesCard';

const Cards = () => {
  const dispatch = useDispatch();

  const showCards = useSelector(userInformationSelectors.showMyCompaniesCards);
  const cards = useSelector(filterCardsSelectors.getMyCompaniesCards);
  const selectedCard = useSelector(filterCardsSelectors.getSelectedCard);
  const subFilter = useSelector(filterCardsSelectors.getSubFilter);

  const handleClick = (key: string) => {
    if (selectedCard?.id !== key || !!subFilter) {
      dispatch(filterCardsActions.changeCard(key));
    }
  };

  const cardsList: Array<MyCompaniesCardDataProps> = useMemo(
    () => getMyCompaniesCardsList(cards),
    [cards]
  );

  if (!keys(cards).length) {
    return null;
  }

  return (
    <CSSTransition in={showCards} timeout={300} classNames="quick-filters-transition" unmountOnExit>
      <div className="my-companies-card-group">
        <Row gutter={[20, 0]} className="my-companies-card-group__content">
          {cardsList.map((card, index) => (
            <Col xs={24} sm={12} lg={12} xl={6} xxl={6} key={index}>
              <MyCompaniesCard
                data={{ ...card }}
                onClick={() => handleClick(card.id)}
                selected={selectedCard?.id === card.id}
                colSize={
                  card.colSizeFirstBigger
                    ? [{ sm: 12 }, { sm: 6 }, { sm: 6 }]
                    : [{ sm: 6 }, { sm: 6 }, { sm: 12 }]
                }
              />
            </Col>
          ))}
        </Row>
      </div>
    </CSSTransition>
  );
};

export default React.memo(Cards);
