import React, { useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import classNames from 'classnames';
// constants
import { MENU_COLLAPSED } from '@optx/storage/localStorage/menuStorage';
// styles
import './styles.scss';

interface ExternalScrollProps {
  refElement: React.RefObject<HTMLDivElement>;
  contentWidth?: number | null;
  isVirtualGrid?: boolean;
}

const ExternalScroll: React.FC<ExternalScrollProps> = ({
  refElement,
  contentWidth,
  isVirtualGrid,
}) => {
  const scrollWrapper = useRef<HTMLDivElement>(null);
  const isMenuCollapsed = localStorage.getItem(MENU_COLLAPSED);
  const normalizedIsMenuCollapsed = isMenuCollapsed !== null ? JSON.parse(isMenuCollapsed) : false;

  /**
   * Move the external scroll or the grid/refElement according
   */
  const handleScroll = (isScroll: boolean) => {
    const scrollCurrent = scrollWrapper.current;
    const gridCurrent = isVirtualGrid ? refElement.current : refElement.current?.children[0];

    if (scrollCurrent && gridCurrent && scrollCurrent.scrollLeft !== gridCurrent.scrollLeft) {
      if (isScroll) {
        const scrollLeftMax = gridCurrent.scrollWidth - gridCurrent.clientWidth;
        const { scrollLeft } = scrollCurrent;

        let addedSize = 0; // fix for last column not showing up properly
        if (scrollLeftMax - 1 === scrollLeft) addedSize = 1; // chrome and other browsers
        else if (scrollLeftMax - 18 === scrollLeft) addedSize = 18; // firefox

        gridCurrent.scrollTo({ left: scrollLeft + addedSize });
      } else {
        scrollCurrent.scrollTo({ left: gridCurrent.scrollLeft });
      }
    }
  };

  /**
   * When the external scroll moves
   */
  const handleScrollElement = () => {
    handleScroll(true);
  };

  /**
   * When the grid/refElement moves
   */
  const handleGridElement = () => {
    handleScroll(false);
  };

  /**
   * If there is change in the content width, this updates the scroll width to match it.
   */
  useEffect(() => {
    if (scrollWrapper.current) {
      const scrollElementDiv = scrollWrapper.current.children[0];
      (scrollElementDiv as HTMLElement).style.width = `${contentWidth}px`;
    }

    const gridCurrent = isVirtualGrid ? refElement.current : refElement.current?.children[0];
    const scrollCurrent = scrollWrapper.current;

    // move scroll to the previous position once content width changed
    if (scrollCurrent && gridCurrent && scrollCurrent.scrollLeft !== gridCurrent.scrollLeft) {
      scrollCurrent.scrollTo({ left: gridCurrent.scrollLeft });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentWidth]);

  /**
   * Define the scroll event for the elements
   */
  useEffect(() => {
    if (refElement.current && scrollWrapper.current) {
      const gridCurrent = refElement.current;
      const scrollCurrent = scrollWrapper.current;

      const scrollElementDiv = scrollCurrent.children[0];

      const refElementContent = document.querySelector(`.${gridCurrent.className} table`);

      if (refElementContent) {
        const offsetWidth = (refElementContent as HTMLElement).offsetWidth;

        // set the external scroll size to be the same as the grid content
        (scrollElementDiv as HTMLElement).style.width = `${
          // for some reason scroll width in virtual grid needs to be a bit bigger to be able to scoll entire content.
          // isVirtualGrid ? offsetWidth + 19 : offsetWidth
          // tested above and saw no difference in scrolling behaviour. Let it commented for now.
          isVirtualGrid && offsetWidth
        }px`;
      }

      (scrollCurrent as GlobalEventHandlers).onscroll = debounce(handleScrollElement, 2);
      (gridCurrent as unknown as GlobalEventHandlers).onscroll = debounce(handleGridElement, 10);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refElement.current]);

  const customClassName = classNames(
    'external-scroll-wrapper',
    normalizedIsMenuCollapsed && 'external-scroll-menu-collapsed'
  );

  return (
    <div className={customClassName} ref={scrollWrapper}>
      <div className="external-scroll" />
    </div>
  );
};

export default ExternalScroll;
