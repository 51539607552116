import { createReducer, PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { SortByRule, SortRule } from '@models/table/sorting';
import { isEqual } from 'lodash';
// models
import { SearchPayload } from '@optx/models/api/contacts';
import Company from '@optx/models/Company';
import { UserInformation } from '@optx/models/user';
import { GridPayload } from '@models/grid';
// interfaces
import { SourcingOutReachSortState } from '@redux/company-outreach/sort/interfaces';
// constants
import { COMPANY_DEFAULT_SORT } from '@constants/table/sort/defaultSort';
// utils
import { parseSorting } from '@optx/utils/filters/parseSorting';
// redux
import { actions as userInformationActions } from '@redux/user/information';
import * as fetchReducers from '../../feature/fetch/reducers';
import * as actions from './actions';
import { actions as searchActions } from '@features/grid/search';

const initialState: SourcingOutReachSortState = {
  multiSort: false,
  sortBy: COMPANY_DEFAULT_SORT,
  loading: false,
  error: '',
};

export const changeSortReducer: CaseReducer<
  SourcingOutReachSortState,
  PayloadAction<SortRule<any>>
> = (draftState, action) => {
  if (action.payload.sortBy) {
    draftState.sortBy = action.payload.sortBy;
  } else {
    draftState.sortBy = action.payload as any;
  }
};

export const createMultiSortReducer =
  (
    initState = initialState
  ): CaseReducer<
    SourcingOutReachSortState,
    PayloadAction<boolean, any, Array<SortByRule<any>> | undefined>
  > =>
  (draftState, action) => {
    const multiSort = action.payload;
    draftState.multiSort = multiSort;

    if (!multiSort) {
      draftState.sortBy = initState.sortBy;
    }
  };

const multiSortReducer = createMultiSortReducer(initialState);

const changeSortActionSuccessReducer: CaseReducer<
  SourcingOutReachSortState,
  PayloadAction<SearchPayload>
> = (draftState, action) => {
  if (!isEqual(draftState.sortBy, action.payload.sortBy)) {
    draftState.sortBy = action.payload.sortBy;
  }
};

const resetMultiSortActionReducer: CaseReducer<SourcingOutReachSortState> = draftState => {
  draftState.multiSort = false;
};

const resetSortSuccessReducer: CaseReducer<
  SourcingOutReachSortState,
  PayloadAction<Array<SortByRule<any>> | undefined>
> = (draftState, action) => {
  draftState.loading = false;
  draftState.multiSort = false;
  draftState.error = '';
  draftState.sortBy = action.payload ? action.payload : COMPANY_DEFAULT_SORT;
};

const searchSuccessReducer: CaseReducer<
  SourcingOutReachSortState,
  PayloadAction<Company[], any, GridPayload<Partial<SearchPayload>>>
> = (draftState, action) => {
  const { data } = action.meta;

  if (!data.sortBy) {
    draftState.sortBy = COMPANY_DEFAULT_SORT;
  } else {
    draftState.sortBy = data.sortBy;
  }
};

export const fetchUserInformationSuccessReducer: CaseReducer<
  SourcingOutReachSortState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const sorting = action.payload.settings.session_settings?.sourcing_outreach_detail_sorting;

  if (sorting) {
    const { sortBy, isMultiSort } = parseSorting(sorting);
    draftState.sortBy = sortBy;
    draftState.multiSort = isMultiSort;
  } else if (sorting === '') {
    draftState.sortBy = [];
    draftState.multiSort = false;
  }
};

const reducer = createReducer<SourcingOutReachSortState>(initialState, builder =>
  builder
    .addCase(actions.multiSortAction, multiSortReducer)
    .addCase(actions.changeSortAction, changeSortReducer)
    .addCase(actions.changeSortActionSuccess, changeSortActionSuccessReducer)
    .addCase(actions.resetMultiSortAction, resetMultiSortActionReducer)
    .addCase(searchActions.searchCompaniesSuccess, searchSuccessReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
    .addCase(actions.resetSortAction, fetchReducers.fetchReducer)
    .addCase(actions.resetSortSuccessAction, resetSortSuccessReducer)
);

export default reducer;
