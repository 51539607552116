import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
// models
import { SocialMediaChartData } from '@optx/models/charts/data';
import { WebsiteRankPayload } from '@optx/models/company/SocialMedia';
// services
import { CompanyService } from '@services/api';
import NotificationService from '@services/NotificationService';
// utils
import { getErrorMessage } from '@utils/api/errors';
// constants
import { INITIAL_STATE_CHART } from '@optx/constants/charts';
// redux
import * as actions from './actions';

export function* fetchWWWebsiteRankSaga(action: PayloadAction<WebsiteRankPayload, any, boolean>) {
  try {
    const res: AxiosResponse<SocialMediaChartData> = yield call(
      CompanyService.getCompanySocialMediaWebsiteRank,
      {
        companyId: action.payload.companyId,
        usOnly: false,
      }
    );
    const chartData = res.data;

    if (chartData) {
      yield put(actions.fetchWWWebsiteRankSuccess(chartData));
    } else {
      yield put(actions.fetchWWWebsiteRankSuccess(INITIAL_STATE_CHART as SocialMediaChartData));
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Failed to fetch WW Website Rank data');
    yield put(actions.fetchWWWebsiteRankFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* fetchUSWebsiteRankSaga(action: PayloadAction<WebsiteRankPayload, any, boolean>) {
  try {
    const res: AxiosResponse<SocialMediaChartData> = yield call(
      CompanyService.getCompanySocialMediaWebsiteRank,
      {
        companyId: action.payload.companyId,
        usOnly: true,
      }
    );
    const chartData = res.data;

    if (chartData) {
      yield put(actions.fetchUSWebsiteRankSuccess(chartData));
    } else {
      yield put(actions.fetchUSWebsiteRankSuccess(INITIAL_STATE_CHART as SocialMediaChartData));
    }
  } catch (e: any) {
    // need to change message
    const errorMessage = getErrorMessage(e, 'Failed to fetch US Website Rank data');
    yield put(actions.fetchUSWebsiteRankFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* websiteRankSaga() {
  yield takeLatest(actions.fetchWWWebsiteRank, fetchWWWebsiteRankSaga);
  yield takeLatest(actions.fetchUSWebsiteRank, fetchUSWebsiteRankSaga);
}
