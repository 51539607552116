import { createAction } from '@reduxjs/toolkit';
// models
import { ChartData } from '@optx/models/charts/data';
import { ScoreGrowth } from '@optx/models/Company';
import * as types from './types';

export const fetchScoreGrowth = createAction<ScoreGrowth>(types.fetchScoreGrowth.REQUEST);

export const fetchScoreGrowthSuccess = createAction<{ data: ChartData; psgDate: string | null }>(
  types.fetchScoreGrowth.SUCCESS
);

export const fetchScoreGrowthFail = createAction<string>(types.fetchScoreGrowth.FAILURE);
