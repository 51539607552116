import { createSelector } from 'reselect';
import { AppState } from '@optx/redux/interfaces';

const selectCognitoLogOut = (state: AppState) => state.auth?.cognitoLogout;

export const shouldFetchCognitoLogoutURL = createSelector(
  selectCognitoLogOut,
  state => !state.loading && !state.url
);

export const loadingCognitoLogoutURL = createSelector(selectCognitoLogOut, state => state.loading);

export const getCognitoLogoutURL = createSelector(selectCognitoLogOut, state => state && state.url);
