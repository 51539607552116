import { createAction } from '@reduxjs/toolkit';
// models
import { SortByRule } from '@models/table/sorting';
import { UserManagement, UserEdit, UserInfo } from '@models/UserManagement';
// constants
import * as types from './types';

export const fetchUserManagement = createAction<Array<SortByRule<any>> | undefined>(
  types.FETCH_USER_MANAGEMENT
);
export const fetchUserManagementSuccess = createAction<Array<UserManagement>>(
  types.FETCH_USER_MANAGEMENT_SUCCESS
);
export const fetchUserManagementFail = createAction<string>(types.FETCH_USER_MANAGEMENT_FAIL);

export const resendUserInvite = createAction<{ userId: number }>(types.RESEND_USER_INVITE);
export const resendUserInviteSuccess = createAction<Array<{ userId: number }>>(
  types.RESEND_USER_INVITE_SUCCESS
);
export const resendUserInviteFail = createAction<string>(types.RESEND_USER_INVITE_FAIL);

export const disableUser = createAction<number>(types.USER_DISABLE);

export const disableUserSuccess = createAction<number>(types.USER_DISABLE_SUCCESS);

export const unlockUser = createAction<number>(types.USER_UNLOCK);
export const unlockUserSuccess = createAction<number>(types.USER_UNLOCK_SUCCESS);
export const unlockUserFail = createAction<string>(types.USER_UNLOCK_FAIL);

// editing
export const fetchUserEditInfo = createAction<number>(types.FETCH_USER_EDIT_INFO);

export const fetchUserEditInfoSuccess = createAction<UserEdit>(types.FETCH_USER_EDIT_INFO_SUCCESS);

export const fetchUserEditInfoFail = createAction<string>(types.FETCH_USER_EDIT_INFO_FAIL);

export const submitUserInfo = createAction<UserInfo>(types.SUBMIT_USER_INFO);

export const submitUserInfoSuccess = createAction<UserInfo>(types.SUBMIT_USER_INFO_SUCCESS);

// sorting
export const changeSortSuccess = createAction<Array<SortByRule<any>>>(types.CHANGE_SORT_SUCCESS);
