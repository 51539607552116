import { Card } from 'antd';
import { CardProps } from 'antd/lib/card';
import styled, { css } from 'styled-components';

type CardSize = 'small';

interface StyledCardProps extends CardProps {
  $transparent?: boolean;
  $size?: CardSize;
}

/**
 * Get card border radius.
 * @param size border rasius size in px.
 */
const getBorderRadius = (size: number) => css`
  border-radius: ${size}px;
  /* Fix issue when hoverable active makes body lose border radius. */
  .ant-card-body {
    border-bottom-left-radius: ${size}px;
    border-bottom-right-radius: ${size}px;
  }
`;

const StyledCard = styled(Card)<StyledCardProps>`
  box-shadow: 0px 0px 10px rgba(66, 17, 115, 0.06);

  ${props => {
    switch (props.$size) {
      case 'small':
        return getBorderRadius(6);
      default:
        return getBorderRadius(8);
    }
  }};

  background: ${props => (props.$transparent ? 'rgba(255, 255, 255, 0.85)' : '#ffffff')};
`;

export default StyledCard;
