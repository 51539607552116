import React from 'react';
import { Card, Table, Empty } from 'antd';
// models
import { CompanyFundingRoundsHistory } from '@optx/models/Company';
// constants
import { defaultColumns } from '@components/common/table/TableFundingRoundsInformation';
// components
import { generateUniqueKey } from '@optx/utils/uuid';
import ExtraContent from './ExtraContent';

interface FundingRoundsProps {
  investmentHistory: Array<CompanyFundingRoundsHistory>;
  fundingGraphMessage: string;
}

const FundingRounds: React.FC<FundingRoundsProps> = ({
  investmentHistory,
  fundingGraphMessage,
}) => {
  return investmentHistory ? (
    <Card
      title="Funding Rounds"
      className="profile-card card-funding-rounds fill-height profile-box"
      extra={<ExtraContent />}
    >
      <Table
        tableLayout="fixed"
        columns={defaultColumns}
        dataSource={investmentHistory}
        pagination={false}
        rowKey={generateUniqueKey}
        bordered
        className="legacy-table"
        locale={{
          emptyText: (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={fundingGraphMessage} />
          ),
        }}
      />
    </Card>
  ) : null;
};

export default React.memo(FundingRounds);
