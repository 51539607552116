import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Row, Tooltip, Typography } from 'antd';
import { OptionTypeBase, OptionsType, ValueType } from 'react-select';
import { useField } from 'formik';
import { Select, FormItem } from 'formik-antd';
// models
import { BaseField } from '@optx/models/companyFields';
import { SelectOption } from '@optx/models/Option';
import { SuccessErrorCallback } from '@optx/models/callback';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { SELECT_UNKNOWN } from '@optx/components/common/select/constants';
import { COMPANY_NAME_ENDPOINT } from '@constants/asyncEndpoints';
// utils
import { handleDropdownKeyEvent } from '@optx/utils/handleDropdownKeyEvent';
import { checkSaveButton } from '@optx/utils/proprietaryInfo';
// hooks
import useAsyncSearch from '@optx/common/hooks/select/useAsyncSearch';
//components
import EditPopover from '@optx/components/common/popover/EditPopover';
import { AsyncSingleSelect } from '@optx/shared/view/molecules/Select';

const { Option } = Select;

interface SingleSelectAsyncFieldProps {
  field?: BaseField;
  value?: OptionTypeBase;
  parentCompany?: string;
  fieldName?: string;
  fieldType?: string;
  options?: OptionTypeBase[];
  isChromePlugin?: boolean;
  onSave?: (fieldType?: string, acquiringCompany?: ValueType<SelectOption<string>>) => void;
  onCancel?: () => void;
}

const SingleSelectAsyncField: React.FC<SingleSelectAsyncFieldProps> = ({
  field,
  fieldName,
  fieldType,
  options,
  parentCompany,
  isChromePlugin,
  value,
  onSave,
  onCancel,
}) => {
  const [, , setSelectedOption] = useField<OptionTypeBase>(field?.id || fieldType || '');
  const [selectedState, setSelectedState] = useState<string | number>();
  const { loadOptions } = useAsyncSearch({ endpoint: COMPANY_NAME_ENDPOINT });
  const [fetchedOptions, setFetchedOptions] = useState<OptionsType<SelectOption>>([SELECT_UNKNOWN]);
  const [acquiringCompany, setAcquiringCompany] = useState<ValueType<SelectOption<string>>>({
    label: parentCompany!,
    value: parentCompany!,
  });
  const [closePopup, setClosePopup] = useState(false);

  const selectedOption = options?.find((option: OptionTypeBase) => option.label === value);

  const label = selectedOption?.label;
  const showAcquiringCompany = useMemo(() => {
    if (fieldType === 'last_round' && selectedState === 1) {
      return true;
    }

    return false;
  }, [fieldType, selectedState]);

  useEffect(() => {
    if (fieldType === 'last_round' && value) {
      setSelectedOption.setValue({
        value: selectedOption?.value,
        label: label,
      });
      setSelectedState(selectedOption?.value as unknown as number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldType, label, selectedOption, value]);

  const handleChange = (value: string) => {
    setSelectedState(value);

    if (fieldType) {
      setSelectedOption.setValue({
        value: value,
        label: options?.find((option: OptionTypeBase) => option.value === value)?.label,
      });
    }
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setClosePopup(true);
  };

  const handleVisibilityChange = (visibility: boolean) => {
    if (value && fieldType === 'last_round' && visibility) {
      setSelectedState(
        options?.find((option: OptionTypeBase) => option.label === value)
          ?.value as unknown as number
      );
    } else if (value && visibility) {
      setSelectedState(value?.value);
    }

    if (!value && visibility) {
      setSelectedState('');
    }
  };

  useEffect(() => {
    if (typeof field?.value === 'object') {
      setSelectedState(field.value?.value);
    }

    if (typeof value === 'object') {
      setSelectedState(value?.value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, value]);

  useEffect(() => {
    if (field) {
      setSelectedOption.setValue({
        value: selectedState,
        label: field.data.find((option: OptionTypeBase) => option.value === selectedState)?.label,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);

  useEffect(() => {
    if (fieldType === 'last_round') {
      setAcquiringCompany({
        label: parentCompany!,
        value: parentCompany!,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentCompany]);

  const getLoadOptionsDelay = (query: string, callback: SuccessErrorCallback) => {
    if (query.length > 2) {
      loadOptions(query, options => {
        options.unshift(SELECT_UNKNOWN);
        const callbackResult = callback(options);

        // set current filter to a cached variable.
        setFetchedOptions(options);

        return callbackResult;
      });
    }
  };

  const handleSaveButtonVisibility = () => {
    const valueToNumber = options?.find(option => option.label === value)?.value;

    if (fieldType === 'last_round' && selectedState === 1) {
      const condition =
        checkSaveButton(selectedState, value as unknown as string) &&
        parentCompany === (acquiringCompany as SelectOption).value;

      return condition;
    }

    return checkSaveButton(selectedState, valueToNumber);
  };

  const onAsyncMultiselectMenuClose = () => {
    setFetchedOptions([SELECT_UNKNOWN]);
  };

  if (field) {
    return (
      <Col span={8} className={field.id}>
        <Typography.Text>{field.label}</Typography.Text>
        <FormItem name={field.id}>
          <Select
            showSearch
            name="LastRound"
            value={selectedState}
            onChange={handleChange}
            getPopupContainer={trigger => trigger.parentElement!}
            filterOption={(input: string, option: OptionTypeBase | undefined) => {
              const fullName = option ? `${option?.children.props.children}` : '';

              return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {field.data &&
              field.data.length &&
              field.data?.map((option: OptionTypeBase) => (
                <Option key={option.value as string} value={option.value as string}>
                  <span>{option.label}</span>
                </Option>
              ))}
          </Select>
        </FormItem>
      </Col>
    );
  }

  const content = (
    <FormItem name={fieldType ?? ''}>
      <div
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
          handleDropdownKeyEvent(event, setClosePopup, onSave, fieldType)
        }
        tabIndex={0}
      >
        <Typography.Text>Edit "{fieldName}" value</Typography.Text>
        <Select
          showSearch
          name={fieldType ?? ''}
          style={{ width: '300px' }}
          value={selectedState}
          onChange={handleChange}
          getPopupContainer={trigger => trigger.parentElement!}
          filterOption={(input: string, option: OptionTypeBase | undefined) => {
            const fullName = option ? `${option?.children.props.children}` : '';

            return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {options?.map((option: OptionTypeBase) => (
            <Option key={option.value as string} value={option.value as string}>
              <span>{option.label}</span>
            </Option>
          ))}
        </Select>
        {showAcquiringCompany && (
          <Row>
            <Typography.Text>Acquiring company</Typography.Text>
            <AsyncSingleSelect
              className="addon--multiselect-async"
              value={acquiringCompany}
              defaultValue={acquiringCompany}
              onChange={value => setAcquiringCompany(value)}
              loadOptions={getLoadOptionsDelay}
              controlShouldRenderValue
              onMenuClose={onAsyncMultiselectMenuClose}
              closeMenuOnSelect
              defaultOptions={fetchedOptions}
              closeOnSelect
              onSelectResetsInput={false}
              blurInputOnSelect={false}
              autoFocus
            />
          </Row>
        )}

        <div className="profile-information__popover-buttons">
          <Button className="profile-information__cancel" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="profile-information__save"
            type="primary"
            disabled={handleSaveButtonVisibility()}
            onClick={() => {
              onSave && onSave(fieldType, acquiringCompany);
              setClosePopup(true);
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </FormItem>
  );

  return (
    <EditPopover
      getPopupContainer={element => element}
      setClosePopup={setClosePopup}
      customOverlayStyle={{ width: '480px' }}
      onVisibilityUpdate={handleVisibilityChange}
      closePopup={closePopup}
      content={content}
      placement={isChromePlugin ? 'topRight' : 'top'}
    >
      <Tooltip
        placement={isChromePlugin ? 'leftTop' : 'top'}
        title={label ? label : DEFAULT_EMPTY_VALUE}
      >
        {label ? label : DEFAULT_EMPTY_VALUE}
      </Tooltip>
    </EditPopover>
  );
};

export default React.memo(SingleSelectAsyncField);
