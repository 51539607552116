import styled from 'styled-components';

const IconWrapper = styled.span`
  position: absolute;
  top: 12px;
  left: 11px;
  height: 14px;

  > span {
    vertical-align: top;
  }
`;

export const Styled = {
  IconWrapper,
};
