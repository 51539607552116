import { useDispatch, useSelector } from 'react-redux';
// utils
import { searchInNewTab } from '@optx/utils/search';
// redux
import { actions as searchActions } from '@features/grid/search';
import { selectors as filterSourcesSelectors } from '@redux/company/filters';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';

interface LoadSpecialFilter {
  (filterKey: string): {
    loadCompaniesByFilter: (filterValue: string, newTab?: boolean) => void;
  };
}

const useLoadSpecialFilter: LoadSpecialFilter = filterKey => {
  const dispatch = useDispatch();
  const clearedFilter = useSelector(filterSelectors.getClearedFilter('advancedSearch'));
  const sources = useSelector(filterSourcesSelectors.getFiltersMap);
  const { advancedSearchPathname } = useIsAnalyst();

  const loadCompaniesByFilter = (filterValue: string, newTab?: boolean) => {
    if (newTab) {
      // insert filter info in filters.
      searchInNewTab(
        clearedFilter,
        sources,
        'company_tag',
        [{ value: filterValue, label: filterValue }],
        {},
        advancedSearchPathname
      );
    } else {
      dispatch(
        searchActions.loadCompaniesByFilter(filterKey, {
          gridKey: 'advancedSearch',
          data: filterValue,
        })
      );
    }
  };

  return { loadCompaniesByFilter };
};

export default useLoadSpecialFilter;
