import { combineReducers } from 'redux';

import { reducers as histogramFiltersReducers } from '@features/histograms/histograms-advanced-search';
import { HomeSearchState } from './interfaces';
// reducers
import sortReducer from './sort';
import columns from './columns';

const reducer = combineReducers<HomeSearchState>({
  histogramFilters: histogramFiltersReducers.reducer,
  sort: sortReducer,
  columns,
});

export default reducer;
