import { createSelector } from 'reselect';
import { AppState } from '@optx/redux/interfaces';

const selectContactsFiltersState = (state: AppState) => state.contactsFilters;

export const isLoading = createSelector(selectContactsFiltersState, state => state.loading);

export const loaded = createSelector(selectContactsFiltersState, state => !!state.fetchedAt);

export const shouldInitialFetch = createSelector(
  selectContactsFiltersState,
  state => !state.fetchedAt && !state.loading
);

export const getFilterSources = createSelector(selectContactsFiltersState, state => state.data);

export const fetchedFilters = createSelector(
  selectContactsFiltersState,
  state => !!state.fetchedAt
);

export const getFiltersMap = createSelector(selectContactsFiltersState, state => state.filters);
