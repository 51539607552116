import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { actions } from '../../../redux/contacts/salesloft';

interface useSalesloftAuthFunction {
  (): { fetchSalesloftAuth(payload: string, cb: (url: string) => void): void };
}

const useSalesloftAuth: useSalesloftAuthFunction = () => {
  const dispatch = useDispatch();

  const fetchSalesloftAuth = useCallback(
    (payload: string, cb: (url: string) => void) =>
      dispatch(actions.fetchSalesloftAuthUrl(payload, cb)),
    [dispatch]
  );

  return { fetchSalesloftAuth };
};

export default useSalesloftAuth;
