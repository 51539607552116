import styled from 'styled-components';
import { Title } from '@optx/shared/view/atoms/Typography';
import { MainContent } from '@optx/components/common/layout';

const StyledMainContent = styled(MainContent)``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  .ant-breadcrumb {
    padding-top: 23px;
    padding-left: 40px;
  }

  .ant-typography,
  .ant-tabs-nav,
  .ant-tabs-content-holder {
    padding-left: 40px;
  }

  .ant-breadcrumb,
  .ant-typography,
  .ant-tabs-nav {
    background: white;
  }

  .ant-col {
    flex: 0 0 24%;
    max-width: 24%;
  }

  ${StyledMainContent} {
    min-width: 1px;
    width: 100%;
  }

  @media (min-width: 1600px) {
    flex-wrap: nowrap;

    ${StyledMainContent} {
      flex: 1;
      margin: 0;
    }
`;

const PageTitle = styled(Title)`
  margin 0 !important;
  padding-top: 10px;
  padding-bottom: 12px;
`;

export default {
  PageTitle,
  Wrapper,
  MainContent: StyledMainContent,
};
