import { createAction } from '@reduxjs/toolkit';
import { PageInfo } from '@optx/models/table/Pagination';
import * as types from './types';
import {
  prepareFetchSuccess,
  prepareSaveSearch,
  prepareSaveSearchSuccess,
  renameSearchPrepare,
  prepareSavedSearch,
} from '../../feature/saved-search/actions';

// fetch contacts saved searches actions
export const fetchContactSavedSearches = createAction<string | undefined>(
  types.FETCH_SAVED_SEARCHES
);
export const fetchContactSavedSearchesSuccess = createAction(
  types.FETCH_SAVED_SEARCHES_SUCCESS,
  prepareFetchSuccess
);
export const fetchContactSavedSearchesFail = createAction<string>(types.FETCH_SAVED_SEARCHES_FAIL);

// save search
export const saveContactSearch = createAction(types.SAVE_SEARCH, prepareSaveSearch);
export const saveContactSearchSuccess = createAction(
  types.SAVE_SEARCH_SUCCESS,
  prepareSaveSearchSuccess
);
export const saveContactSearchFail = createAction<string>(types.SAVE_SEARCH_FAIL);

// delete search
export const deleteContactSearch = createAction<number | string>(types.DELETE_SEARCH);
export const deleteContactSearchSuccess = createAction<number>(types.DELETE_SEARCH_SUCCESS);
export const deleteContactSearchFail = createAction<string>(types.DELETE_SEARCH_FAIL);

// rename search
export const renameContactSearch = createAction(types.RENAME_SEARCH, renameSearchPrepare);
export const renameContactSearchSuccess = createAction(
  types.RENAME_SEARCH_SUCCESS,
  renameSearchPrepare
);
export const renameContactSearchFail = createAction<string>(types.RENAME_SEARCH_FAIL);

// go to search
export const resetToSearch = createAction(types.RESET_TO_SEARCH, prepareSavedSearch);

// pagination
export const savedContactsSearchPagination = createAction<PageInfo>(
  types.SAVED_CONTACTS_SEARCH_PAGINATION
);
