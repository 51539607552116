import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { PipelineInformation } from '@models/feature/analystsLeaderboard/PipelineInformation';
// local
import { PipelineInformationState } from './interfaces';
// redux
import * as actions from './actions';

const initialState: PipelineInformationState = {
  data: null,
};

const fetchSuccessReducer: CaseReducer<
  PipelineInformationState,
  PayloadAction<PipelineInformation>
> = (draftState, action) => {
  draftState.data = action.payload;
};

const reducer = createReducer(initialState, builder =>
  builder.addCase(actions.fetchSuccess, fetchSuccessReducer)
);

export default reducer;
