import React, { useState, useContext, useEffect } from 'react';
// models
import { SelectOption } from '@models/Option';
// components
import { DropdownAddContext } from '@optx/components/DropdownAdd/DropdownAddContext';
import EditPopover from '@components/common/popover/EditPopover';
import SingleSelect from '@components/common/select/SingleSelect';
// hooks
import { useHistoryTab } from '@optx/common/hooks/history';
import useStageEditableField from '../../hooks/useStageEditableField';
import Styled from '../CellPopover.styled';

type EditableTextProps = {
  companyId: number;
  hidePenIcon?: boolean;
  labelValue?: string;
  currentValue?: string;
  lastTemporaryFieldValue?: string;
  getPopupContainer?: ((triggerNode: HTMLElement) => HTMLElement) | undefined;
  fieldChanged?: string;
};

const EditStageProfile: React.FC<EditableTextProps> = ({
  companyId,
  hidePenIcon,
  labelValue,
  currentValue,
  getPopupContainer,
  fieldChanged,
  lastTemporaryFieldValue,
}) => {
  const { updateHistoryFields } = useHistoryTab();

  const {
    initialSelectValue,
    value: companyStage,
    addCompanyStage,
    selectOptions,
  } = useStageEditableField(currentValue as string);
  const value = currentValue !== undefined ? currentValue : companyStage;
  const [closePopup, setClosePopup] = useState(false);
  const [selectedValue, setSelectedValue] = useState(initialSelectValue);
  const { dropdownVisible, setDropdownVisible } = useContext(DropdownAddContext);

  const title = 'Edit "Stage" value';

  useEffect(() => {
    setSelectedValue(
      currentValue
        ? selectOptions.filter(option => currentValue === option.label)
        : selectOptions[0]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  let label: string | React.ReactNode = '';

  if (labelValue !== undefined) {
    label = labelValue;
  } else if (value && !labelValue) {
    label = value;
  } else {
    label = '-';
  }

  const content = (
    <Styled.CellPopover>
      <SingleSelect
        options={selectOptions}
        onChange={option => {
          setSelectedValue(option as SelectOption);
          addCompanyStage(
            companyId,
            (option as SelectOption).value,
            (option as SelectOption).label === 'None' ? '-' : (option as SelectOption).label
          );
          updateHistoryFields({
            afterValue: (option as SelectOption).label,
            beforeValue: ((lastTemporaryFieldValue || currentValue) ?? null) as string,
            fieldChanged: 'Stage',
          });
          setClosePopup(true);
          setDropdownVisible(!dropdownVisible);
        }}
        value={selectedValue}
        closeMenuOnSelect
      />
    </Styled.CellPopover>
  );

  return (
    <EditPopover
      content={content}
      title={title}
      closePopup={closePopup}
      setClosePopup={setClosePopup}
      hidePenIcon={hidePenIcon}
      getPopupContainer={getPopupContainer || (() => document.body as HTMLElement)}
    >
      {label}
    </EditPopover>
  );
};

export default React.memo(EditStageProfile);
