import React from 'react';
import { Row, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
// models
import { EditAllTabsProps } from '@optx/models/company/CompanyEditAll';
// constants
import { EDIT_ALL_TABS } from '../constants/editAllCompany';
// redux
import { selectors as editAllDialogSelectors } from '@optx/features/edit-all-info/state';

const { TabPane } = Tabs;

const TabsRow = styled(Row)`
  &.disabled {
    opacity: 0.3;
    .ant-tabs-tab:hover {
      cursor: text;
    }
  }
`;

interface EditAllTabsGroupProps {
  handleTabChange: (activeKey: string) => void;
  activeTab: string;
}

const EditAllTabsGroup: React.FC<EditAllTabsGroupProps> = ({ handleTabChange, activeTab }) => {
  const saveBtnStatus = useSelector(editAllDialogSelectors.getDisableSaveBtnFor);

  const tabChange = (activeKey: string) => {
    if (saveBtnStatus) {
      return;
    }

    handleTabChange && handleTabChange(activeKey);
  };

  return (
    <TabsRow className={`tabs-container edit-all-tabs-group ${saveBtnStatus ? 'disabled' : ''}`}>
      <Tabs onChange={tabChange} activeKey={activeTab}>
        {EDIT_ALL_TABS.map((tab: EditAllTabsProps) => (
          <TabPane tab={tab.name} key={tab.key} className={tab.className} />
        ))}
      </Tabs>
    </TabsRow>
  );
};

export default React.memo(EditAllTabsGroup);
