import { Dictionary, groupBy } from 'lodash';
import { GroupType } from 'react-select';
// models
import { BaseFilter, FilterSource, MultiSelectFilter, FilterOptions } from '@models/filters';
import Option, { DateRangeOption, SelectOption } from '@models/Option';
import { Identifiable } from '@optx/models/Identifiable';
import { mapToSelectOption } from '../option';

/**
 * Generate options for filters.
 * OBS: Some filters will not include options.
 * @return {object} dictionary with column key and options as value.
 */
export function mapFiltersOptions(sources: Array<FilterSource>) {
  const filtersOptions: Dictionary<FilterOptions> = {};

  sources.forEach(source => {
    if (source.data) {
      (source.data as Array<BaseFilter>).forEach(filter => {
        switch (filter.type) {
          case 'range_input': {
            // const options: Array<FormCheckableRangeOption> = getRangeInputOptions(
            //   filter as RangeFilter<[RangeOptionBase, SelectOption]>
            // );

            // preselectedFilters[filter.column] = options;
            break;
          }

          case 'checkbox': {
            // const options = getCheckboxOptions(filter, includePreselected);
            // preselectedFilters[filter.column] = options;
            // // custom option
            // const customOption = getCheckboxCustomOption(filter as MultiCheckboxFilter);

            // if (customOption) {
            //   preselectedFilters[`${filter.column}_custom`] = customOption;
            // }

            break;
          }

          case 'multi_text': {
            // const options = getMultiTextOptions(
            //   filter as BaseFilter<Array<{ name: string; placeholder?: string }>>
            // );
            // preselectedFilters[filter.column] = options;
            break;
          }

          case 'logic_checkbox': {
            // const options = getLogicCheckboxOptions(
            //   filter as BaseFilter<Array<LogicOptionResponse>>
            // );
            // preselectedFilters[filter.column] = options;
            break;
          }

          case 'multiple_range_nr': {
            // const options = getMultiRangeNumberOptions(filter as MultiRangeNumberFilter);
            // preselectedFilters[filter.column] = options;
            // // custom option
            // const customOption = getMultiRangeCustomOption(filter as MultiRangeNumberFilter);

            // if (customOption) {
            //   preselectedFilters[`${filter.column}_custom`] = customOption;
            // }

            break;
          }

          case 'date_range': {
            // const options = getDateRangeOptions(filter as RangeFilter<null>);
            // preselectedFilters[filter.column] = options;
            break;
          }

          case 'date_range_radio': {
            const options = getDateRangesOptions(filter as BaseFilter<Array<DateRangeOption>>);
            filtersOptions[filter.column] = options;
            break;
          }

          case 'radio': {
            filtersOptions[filter.column] = getRadioOptions(filter as BaseFilter<Array<Option>>);
            break;
          }

          case 'multi_select': {
            const options = getMultiSelectOptions(filter as MultiSelectFilter<Array<Option>>);
            filtersOptions[filter.column] = options;

            break;
          }

          case 'single_select': {
            const options = getSingleSelectOptions(filter);
            filtersOptions[filter.column] = options;

            break;
          }

          default:
            break;
        }
      });
    }
  });

  return filtersOptions;
}

export function getRadioOptions(filter: BaseFilter<Array<Option>>) {
  const options = filter.data.map(option => mapToSelectOption(option));

  return options;
}

export function getMultiSelectOptions(filter: MultiSelectFilter<Array<Option>>) {
  if (filter.data.some(option => option.group)) {
    const groupedOptions = groupBy(filter.data, 'group' as keyof Option);
    const data: Array<GroupType<SelectOption> | SelectOption> = [];

    // Keep included groups in order not to add the same group in options.
    const addedGroups: Array<string> = [];

    // Add options in the same order they are received.
    filter.data.forEach(option => {
      if (!option.group) {
        // Add single option.
        data.push(mapToSelectOption(option));
      } else if (!addedGroups.find(item => item === option.group)) {
        // Add grouped options.
        addedGroups.push(option.group);

        data.push({
          label: option.group,
          options: groupedOptions[option.group].map(groupOption => ({
            label: groupOption.name as string,
            value: groupOption.value.toString(),
            disabled: groupOption.disabled,
          })),
        });
      }
    });

    return data;
  }

  const data: Array<SelectOption> = filter.data.map(option => mapToSelectOption(option));

  return data;
}

export function getDateRangesOptions(
  filter: BaseFilter<Array<DateRangeOption>>
): Array<Identifiable<DateRangeOption>> {
  return filter.data.map(item => ({ ...item, id: Symbol(filter.column) }));
}

export function getSingleSelectOptions(filter: BaseFilter<Array<Option>>) {
  return filter.data.map(option => mapToSelectOption(option));
}

/**
 * Reorder options for Analysts user by custom.
 * @param data all options after grouped mapping.
 */
export function orderAnalystsByCustom(data: Array<SelectOption>) {
  const order = ['UK Team', 'US Team', 'Other', 'Past Users'];

  return data.sort((a: SelectOption, b: SelectOption) => {
    return order.indexOf(a.label) - order.indexOf(b.label);
  });
}

/**
 * Reorder options for location by custom.
 * @param data all options after grouped mapping.
 */
export function orderLocationByCustom(data: Array<SelectOption>) {
  const order = ['All regions', 'North America + Israel', 'Europe'];

  return data.sort((a: SelectOption, b: SelectOption) => {
    return order.indexOf(a.label) - order.indexOf(b.label);
  });
}
