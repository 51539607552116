import { NotificationPayload } from '@optx/models/notifications';
import { createAction } from '@reduxjs/toolkit';
import {
  NotificationError,
  NotificationsResponse,
  TouchesNotificationResponse,
} from './interfaces';
import * as types from './types';

export const fetchAlertsInbox = createAction<NotificationPayload>(types.FETCH_ALERTS_INBOX);
export const fetchAlertsHistory = createAction<NotificationPayload>(types.FETCH_ALERTS_HISTORY);
export const fetchAlertsSuccess = createAction<NotificationsResponse>(types.FETCH_ALERTS_SUCCESS);
export const fetchAlertsFail = createAction<NotificationError>(types.FETCH_ALERTS_FAIL);
export const fetchTouches = createAction<NotificationPayload>(types.FETCH_TOUCHES);
export const fetchTouchesSuccess = createAction<TouchesNotificationResponse>(
  types.FETCH_TOUCHES_SUCCESS
);
export const fetchTouchesFail = createAction<NotificationError>(types.FETCH_TOUCHES_FAIL);
export const loadMoreAlerts = createAction<NotificationPayload>(types.LOAD_MORE_ALERTS);
export const sortAlertsHistory = createAction<NotificationPayload>(types.SORT_ALERTS_HISTORY);

export const updateAlerts = createAction<Array<number>>(types.UPDATE_ALERTS);
export const dismissAlert = createAction<number>(types.DISMISS_ALERT);
export const dismissAlertSuccess = createAction<number>(types.DISMISS_ALERT_SUCCESS);
export const deleteUpcomingTouch = createAction<number>(types.DELETE_UPCOMING_TOUCH);
export const deleteUpcomingTouchSuccess = createAction<number>(types.DELETE_UPCOMING_TOUCH_SUCCESS);
export const deleteUpcomingTouchFail = createAction<NotificationError>(
  types.DELETE_UPCOMING_TOUCH_FAIL
);
export const deletePastDueTouch = createAction<number>(types.DELETE_PAST_DUE_TOUCH);
export const deletePastDueTouchSuccess = createAction<number>(types.DELETE_PAST_DUE_TOUCH_SUCCESS);
export const deletePastDueTouchFail = createAction<NotificationError>(
  types.DELETE_PAST_DUE_TOUCH_FAIL
);
export const loadMorePastDueTouches = createAction<NotificationPayload>(
  types.LOAD_MORE_PAST_DUE_TOUCHES
);
