import styled from 'styled-components';
import { Tabs as TabsAntd } from 'antd';

const Tabs = styled(TabsAntd)`
  background: #fafafa;

  .ant-tabs-nav {
    background: #fff;
    padding: 0 35px;
    margin-bottom: 0;
  }

  .ant-tabs-tabpane {
    position: relative;
  }

  .ant-tabs-content {
    padding: 0 35px;
  }
`;

const ActionsHeader = styled.div<{ isEmpty?: boolean }>`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  padding: 10px 0;

  &:nth-child(1) {
    margin-right: auto !important;
    margin-top: 10px;

    &:before  {
      content: '';
      display: flex;
      position: absolute;
      width: ${({ isEmpty }) => (isEmpty ? '0px' : '1px')};
      height: 67px;
      background: #e8e8e8;
      left: 149px;
      top: -1px;
    }
  }

  .ant-select-single {
    .prefixed-selection-item__label {
      color: #1890ff;
    }
  }
`;

export default {
  Tabs,
  ActionsHeader,
};
