import React from 'react';
import styled from 'styled-components';
import { GlobalOutlined } from '@ant-design/icons';
// models
import { URLStatus } from '@optx/models/Company';
import { Cell } from '@optx/models/table/Cell';

interface TableCellCompanyURLProps {
  raw_url: string | null;
  urlStatus: URLStatus;
}

const CompanyLink = styled.a`
  color: #c2cfe0;
  font-size: 22px;
  line-height: 1;

  &.not-active {
    color: red;
  }

  > svg {
    width: auto;
    height: auto;
  }
`;

const TableCellCompanyURL: React.FC<Cell<TableCellCompanyURLProps>> = ({
  record: { raw_url: url, urlStatus },
}) => {
  return (
    <>
      {url && (
        <CompanyLink
          href={url}
          title={url}
          className={urlStatus === 'active' ? undefined : 'not-active'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <GlobalOutlined />
        </CompanyLink>
      )}
    </>
  );
};

export default TableCellCompanyURL;
