import React, { useMemo, useContext } from 'react';
import { useField } from 'formik';
import { Form } from 'formik-antd';
import { OptionsType, ValueType, ActionMeta } from 'react-select';
// models
import { MultiSelectFilter } from '@optx/models/filters';
import Option, { SelectOption } from '@optx/models/Option';
// utils
import { mapToSelectOption } from '@utils/option';
// components
import SingleSelect from '@optx/components/common/select/SingleSelect';
import TooltipLabel from '@optx/components/common/TooltipLabel';
import SearchFilterCard from './SearchFilterCard';
import { FiltersContext } from '../FiltersContext';

interface FilterSingleSelectOptions {
  filter: MultiSelectFilter<Array<Option>>;
}

// this single select helper is a type that is not send to getHistogram or search query.
// it just add/remove values on dependent filters.
const FilterSingleSelectHelper: React.FC<FilterSingleSelectOptions> = ({ filter }) => {
  const [field, , helpers] = useField<Array<SelectOption> | undefined>(filter.column);
  const { onManualFilterChange } = useContext(FiltersContext);

  const options: OptionsType<SelectOption> = useMemo(
    () => filter.data.map(option => mapToSelectOption(option, filter.format_name)),
    [filter.data, filter.format_name]
  );

  const handleChange = (value: ValueType<Option>, action?: ActionMeta<SelectOption>) => {
    helpers.setValue(value as Array<SelectOption>);
  };

  const handleBlur = () => {
    // Special onChange handler.
    filter.onChange && filter.onChange();
    // Call on change on the next tick.
    setTimeout(() => {
      onManualFilterChange && onManualFilterChange(filter.column);
    }, 0);
  };

  return (
    <SearchFilterCard
      className="filter-single-select"
      label={<TooltipLabel label={filter.label} tooltip={filter.tooltip} />}
    >
      <Form.Item name={field.name}>
        <SingleSelect
          name={field.name}
          options={options}
          onChange={handleChange}
          value={field.value}
          closeMenuOnSelect
          placeholder={filter.placeholders}
          onBlur={handleBlur}
        />
      </Form.Item>
    </SearchFilterCard>
  );
};

export default React.memo(FilterSingleSelectHelper);
