import React from 'react';
import { Col, Typography } from 'antd';
import { PluginPrimaryContact } from '@optx/models/Company';
import { CompanyUserContact } from '@models/api/contacts';
import PrimaryContactCard from './PrimaryContactCard';
import PrimaryContactList from './PrimaryContactList';

const { Title } = Typography;

interface PrimaryContactProps {
  primaryContact: CompanyUserContact;
  logo: string | null;
  primaryContactDescription: PluginPrimaryContact | null;
}

export const PrimaryContact: React.FC<PrimaryContactProps> = ({
  primaryContact,
  logo,
  primaryContactDescription,
}) => {
  return (
    <>
      <Col span={24}>
        <Title level={2}>Primary Contact</Title>
      </Col>
      <Col span={10}>
        <PrimaryContactCard primaryContact={primaryContact} logo={logo} />
      </Col>
      <Col span={13} offset={1}>
        <PrimaryContactList primaryContactDescription={primaryContactDescription} />
      </Col>
    </>
  );
};
