import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { PageInfo } from '@optx/models/table/Pagination';
import { actions as userInformationActions } from '@redux/user/information';
import { UserInformation } from '@optx/models/user';
import { CompaniesInEquityTouchResponse } from '../../models';
import { PaginationState } from '../interfaces';
import { actions } from '../actions';

const initialState: PaginationState = {
  pageNumber: 1,
  pageSize: 50,
  total: 0,
  pageCount: 0,
};

const changePaginationReducer: CaseReducer<PaginationState, PayloadAction<PageInfo>> = (
  draftState,
  action
) => {
  const { pageNumber, pageSize } = action.payload;

  draftState.pageNumber = pageNumber;
  draftState.pageSize = pageSize;
};

const resetPageReducer: CaseReducer<PaginationState> = draftState => {
  draftState.pageNumber = 1;
};

const fetchETCompaniesSuccessReducer: CaseReducer<
  PaginationState,
  PayloadAction<CompaniesInEquityTouchResponse>
> = (draftState, action) => {
  const { count } = action.payload;

  draftState.total = count.total;
  draftState.pageCount = count.on_request;
};

export const fetchUserInformationSuccessReducer: CaseReducer<
  PaginationState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  if (action.payload.settings.session_settings?.new_et_companies_page_number) {
    draftState.pageNumber = action.payload.settings.session_settings?.new_et_companies_page_number;
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.search.fetchETCompaniesSuccess, fetchETCompaniesSuccessReducer)
    .addCase(actions.pagination.changePage, changePaginationReducer)
    .addCase(actions.filters.clearFilters, resetPageReducer)
    .addCase(actions.filters.resetToDefault, resetPageReducer)
    .addCase(actions.sort.toggleMultisort, resetPageReducer)
    .addCase(actions.filters.applyFilters, resetPageReducer)
    .addCase(actions.filters.updateValue, resetPageReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
);

export default reducer;
