import React from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'antd';
import { Link, useLocation } from 'react-router-dom';
// redux
import { selectors as fullscreenSelectors } from '@redux/ui/settings/fullscreen';
import { selectors as viewTypeSelectors } from '@features/grid/view-type';
import { selectors as viewSelectors, actions as viewActions } from '@features/grid/view';
import { selectors as filterSelectors } from '@features/grid/filter';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
// components
import { GridContext } from '@components/pages/Home/HomeContext';
import GridFullScreen from '@optx/shared/view/molecules/FullScreen/GridFullScreen';
import SearchCount from '@optx/features/grid/search-count/components/SearchCount';
import { BulkActionsButton } from '@features/bulk-actions/actions-menu';
import ToggleButton from '@optx/components/common/molecules/buttons/ToggleButton';
import GridDropdown from './GridDropdown';
import GridView from '@features/grid/view/components/GridView';
import ToggleLongCardViewContainer from '../Header/ToggleLongCardViewContainer';
import ToggleTableViewContainer from '../Header/ToggleTableViewContainer';
import CardPannelSorting from './CardPannelSorting';
import TrendingControls from './TrendingControls';
// styles
import Styled from './SearchGridControls.styles';

interface SearchGridControlsProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const SearchGridControls: React.FC<SearchGridControlsProps> = ({ open, setOpen }) => {
  const location = useLocation();
  const isFullscreen = useSelector(fullscreenSelectors.isFullscreen);
  const searchView = useSelector(viewTypeSelectors.searchView('advancedSearch'));
  const activeView = useSelector(viewSelectors.getView('advancedSearch'));
  const similarCompanyInfo = useSelector(filterSelectors.getSimilarCompanyName());
  const selectedCompanies = useSelector(
    bulkActionsSelectors.selectedCompanies.getSelectedCompanies('advancedSearch')
  );

  const similarCompanyName = similarCompanyInfo?.label;
  const similarCompanyId = Number(similarCompanyInfo?.value.split(',').pop());

  const applyView = viewActions.applyView;

  const handleClick = () => {
    setOpen(!open);
  };

  const title = `${open ? 'Hide' : 'Show'} Quick Filters`;

  return (
    <Styled.SearchGridWrapper
      justify="space-between"
      align="middle"
      $cardView={searchView === 'long-card'}
    >
      <Col flex="auto" style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Styled.PageTitle ellipsis>
          {`${similarCompanyName ? 'Similar Companies to' : 'Advanced Search'}`}
          <Link style={{ marginLeft: '6px' }} to={`/profile/${similarCompanyId}`}>
            {similarCompanyName}
          </Link>
        </Styled.PageTitle>
        <SearchCount gridName="advancedSearch" searchViewType={searchView} />
        <Styled.ControlsWrapper>
          <TrendingControls />
          {searchView === 'table' && (
            <GridView gridKey="advancedSearch" activeView={activeView} applyView={applyView} />
          )}
          {searchView === 'long-card' && <CardPannelSorting />}
          <ToggleTableViewContainer />
          <ToggleLongCardViewContainer />
          {searchView === 'table' && !isFullscreen && <GridFullScreen />}
          <ToggleButton onClick={handleClick} hide={open} title={title} />
          <GridDropdown />
          {!!selectedCompanies.length && searchView === 'table' && (
            <BulkActionsButton
              context={GridContext}
              selectedCompanies={selectedCompanies}
              referrer={encodeURIComponent(location.pathname)}
            />
          )}
        </Styled.ControlsWrapper>
      </Col>
    </Styled.SearchGridWrapper>
  );
};

export default React.memo(SearchGridControls);
