import React from 'react';
import { Typography } from 'antd';
// models
import { EquityTouchSimilarities } from '@optx/models/equityTouch';
import { Cell } from '@models/table/Cell';

const TableCellName: React.FC<Cell<EquityTouchSimilarities>> = ({ value, record }) => {
  return (
    <Typography.Link href={record.et_web_url} target="_blank" rel="noopener noreferrer">
      {value}
    </Typography.Link>
  );
};

export default TableCellName;
