import { createAction } from '@reduxjs/toolkit';
import { CompanyImport } from '@optx/models/Company';
import { SourceScrubList } from '@models/SourceScrubList';
import { CompanyWatchList } from '@models/WatchList';
import * as types from './types';

export const loadCompanyList = createAction<{ id: string; isSourceScrub: boolean }>(
  types.LOAD_COMPANY_LIST
);
export const loadCompanyListSuccess = createAction<{ id: string; isSourceScrub: boolean }>(
  types.LOAD_COMPANY_LIST_SUCCESS
);

// filter the current company list
export const filterCompanyList = createAction<string>(types.FILTER_COMPANY_LIST);
export const filterCompanyListSuccess = createAction<string>(types.FILTER_COMPANY_LIST_SUCCESS);

export const clearCompanyList = createAction<void>(types.CLEAR_COMPANY_FILTER);

/**
 * Delete company from current active list.
 */
export const deleteCompanyInCurrentList = createAction<number>(types.DELETE_COMPANY_IN_LIST);

/**
 * Save Companies list that have error in the CSV import.
 */
export const filterCSVCompanyErrorList = createAction<{ data: CompanyImport; title: string }>(
  types.FILTER_CSV_COMPANY_ERROR_LIST
);

export const loadCompanyListDetails = createAction<{ id: string; isSourceScrub: boolean }>(
  types.LOAD_COMPANY_LIST_DETAILS
);
export const loadCompanyListDetailsSuccess = createAction(
  types.LOAD_COMPANY_LIST_DETAILS_SUCCESS,
  (list: SourceScrubList | CompanyWatchList) => ({ payload: list })
);
export const loadCompanyListDetailsFailure = createAction<string>(
  types.LOAD_COMPANY_LIST_DETAILS_FAILURE
);
export const loadCompanyListDetailsReset = createAction(types.LOAD_COMPANY_LIST_DETAILS_RESET);
export const resetLoadingState = createAction(types.RESET_LOADING_STATE);
