import styled from 'styled-components';
import { Menu } from 'antd';

const DropdownMenu = styled(Menu)`
  &.ant-menu-root {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }

  .ant-menu-item,
  .ant-menu-item:not(:last-child) {
    line-height: 22px;
    margin: 0;
    padding: 5px 12px;
    height: auto;
  }
  .ant-menu-item.ant-menu-item-selected {
    background: transparent none;
  }
`;
export const Styled = {
  DropdownMenu,
};
