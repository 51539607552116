import React from 'react';
import { Menu } from 'antd';
// components
import ToggleSortType from '@optx/components/pages/MyCompanies/SecondaryHeader/ToggleSortType';
import { ResetColumnWidthsMenu } from '@features/columns/update-columns/components';
import DisplayColumnToggle from './DisplayColumnToggle';
import { ClearSortingMenuItem } from './ClearSortingMenuItem';

const MyCompaniesMenu = () => {
  return (
    <Menu>
      <DisplayColumnToggle key={1} />
      <ResetColumnWidthsMenu key={2} />
      <ClearSortingMenuItem key={3} />
      <ToggleSortType key={4} />
    </Menu>
  );
};

export default MyCompaniesMenu;
