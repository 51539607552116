import { CompanyProfile } from '@optx/models/Company';
import { createAction } from '@reduxjs/toolkit';
import * as types from './actionTypes';
import {
  AiDescriptionResponse,
  ViewSimilarCompaniesOnGrid,
  AiRelationshipSummaryResponse,
} from './interfaces';

export const fetchCompanyProfile = createAction<number>(types.FETCH_COMPANY_PROFILE);
export const fetchCompanyProfileSuccess = createAction<CompanyProfile>(
  types.FETCH_COMPANY_PROFILE_SUCCESS
);
export const fetchCompanyProfileFail = createAction<string>(types.FETCH_COMPANY_PROFILE_FAIL);

export const backToSearchResults = createAction<string>(types.BACK_TO_RESULTS);

export const resetCompanyProfile = createAction(types.RESET_COMPANY_PROFILE);

export const setSelectedDescription = createAction<string | number>(types.SET_SELECTED_DESCRIPTION);

export const viewSimilarCompaniesOnGrid = createAction<ViewSimilarCompaniesOnGrid>(
  types.VIEW_SIMILAR_COMPANIES_ON_GRID
);

// AI Description
export const regenerateAiDescription = createAction<(value: string) => void>(
  types.REGENERATE_AI_DESCRIPTION
);
export const regenerateAiDescriptionSuccess = createAction<AiDescriptionResponse>(
  types.REGENERATE_AI_DESCRIPTION_SUCCESS
);
export const regenerateAiDescriptionFail = createAction(types.REGENERATE_AI_DESCRIPTION_FAIL);

export const opinionAiDescription = createAction(
  types.OPINION_AI_DESCRIPTION,
  (status: boolean, cb: (value: string) => void) => ({
    payload: status,
    meta: cb,
  })
);
export const opinionAiDescriptionSuccess = createAction<AiDescriptionResponse>(
  types.OPINION_AI_DESCRIPTION_SUCCESS
);
export const opinionAiDescriptionFail = createAction(types.OPINION_AI_DESCRIPTION_FAIL);

export const aiRelationshipSummary = createAction(
  types.AI_RELATIONSHIP_SUMMARY,
  (status: boolean, cb?: () => void) => ({
    payload: status,
    meta: cb,
  })
);
export const aiRelationshipSummarySuccess = createAction<AiRelationshipSummaryResponse>(
  types.AI_RELATIONSHIP_SUMMARY_SUCCESS
);
export const aiRelationshipSummaryFail = createAction(types.AI_RELATIONSHIP_SUMMARY_FAIL);
