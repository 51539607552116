import { utils as requestUtils } from '@features/request';
import { createFeatureActionType } from '@optx/utils/feature';
import { featureName } from '../featureName';

// fetch company merges
export const fetchCompanyMerges = requestUtils.createActionTypes(featureName, 'FETCH_MERGES');
// unmerge
export const unmergeCompanies = createFeatureActionType(featureName, 'UNMERGE_COMPANIES');
export const setSelectedCompanies = createFeatureActionType(featureName, 'SET_SELECTED_COMAPNIES');
export const setUnmergeLoading = createFeatureActionType(featureName, 'SET_UNMERGE_LOADING');
export const setUnmergeCompleted = createFeatureActionType(featureName, 'SET_UNMERGE_COMPLETED');
export const resetUnmerge = createFeatureActionType(featureName, 'RESET_UNMERGE');
export const toggleUnmergeModal = createFeatureActionType(featureName, 'TOGGLE_UNMERGE_MODAL');
export const toggleShouldCopyData = createFeatureActionType(featureName, 'TOGGLE_SHOULD_COPY_DATA');
export const resetPagination = createFeatureActionType(featureName, 'RESET_PAGINATION');
export const setUnmergeCompanyId = createFeatureActionType(featureName, 'SET_UNMERGE_COMPANY_ID');
export const setUnmergeError = createFeatureActionType(featureName, 'SET_UNMERGE_ERROR');
