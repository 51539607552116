import React from 'react';
import { Row, Col } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';
import { SalesloftBulkResults } from '@models/bulkActions';

const RowWrapper = styled(Row)`
  .ant-col .anticon svg {
    height: 23px;
  }
`;

const TableCellStatusSalesloft = (value: boolean, record: SalesloftBulkResults) => {
  const success = record.contacts.every(status => status.sl_sync_status);

  return (
    <RowWrapper justify="space-between">
      <Col>{success ? 'Success' : 'Fail'}</Col>
      <Col>
        {success ? (
          <CheckCircleFilled style={{ color: '#52c41a' }} />
        ) : (
          <CloseCircleFilled style={{ color: '#f5222d' }} />
        )}
      </Col>
    </RowWrapper>
  );
};

export default TableCellStatusSalesloft;
