import { presetPalettes, PalettesProps } from '@ant-design/colors';
import { ANTDColors } from '@optx/models/theme';

/**
 * Generate colors object.
 * @param presetColors
 */
function getColors(presetColors: PalettesProps) {
  const { grey } = presetColors;

  // Replace UK grey word with US gray word.
  const colors = {
    ...presetPalettes,
    gray: grey,
  } as ANTDColors;

  // @ts-ignore
  delete colors.grey;

  return colors;
}

export const colors = getColors(presetPalettes);
