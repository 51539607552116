import React from 'react';
import { useSelector } from 'react-redux';
import { ButtonProps } from 'antd/lib/button';
import { Button } from 'antd';
// utils
import { getLocationMainGridKey } from '@optx/utils/grid';
// redux
import { selectors as searchSelectors } from '@features/grid/search';
// components
import styled from 'styled-components';

const StyledCancelSearchButton = styled(Button)`
  &&& {
    position: fixed;
    top: 60%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2000;
  }
`;

const CancelSearchButton: React.FC<ButtonProps> = ({ children, ...restProps }) => {
  const gridKey = getLocationMainGridKey();

  const cancelSearch = useSelector(
    searchSelectors.isCancelled(gridKey ? gridKey : 'advancedSearch')
  );

  return (
    <StyledCancelSearchButton type="primary" {...restProps} disabled={cancelSearch}>
      {cancelSearch ? 'Canceling' : children}
    </StyledCancelSearchButton>
  );
};

CancelSearchButton.defaultProps = {
  children: 'Cancel Search',
};
export default CancelSearchButton;
