import styled from 'styled-components';
import { only } from 'styled-breakpoints';

const StartTyping = styled.span`
  color: #999;
  font-size: x-small;
  font-style: italic;
  position: absolute;
  top: -25px;
  right: 0;

  ${only('lg')} {
    top: -20px;
    left: 0;
  }
`;

export default { StartTyping };
