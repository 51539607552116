import { select, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
// models
import { ColumnWidth, ColumnKeys, ColumnWidthUserSettings } from '@optx/models/table/Columns';
// constants
import { columnDisplayInitialState } from '@optx/constants/table/columnDisplay/company-search';
// redux
import {
  selectors as userInformationSelectors,
  actions as userInformationActions,
} from '@redux/user/information';
import { Dictionary } from 'lodash';

export function* saveColumnWidthSaga(action: PayloadAction<ColumnWidth>) {
  const { columnId, width, userSetting } = action.payload;
  const columnWidths: Dictionary<number> = yield select(
    userInformationSelectors.columnWidthsAdvancedSearch
  );
  let newColumnWidths = { ...columnWidths };

  newColumnWidths = { ...newColumnWidths, ...{ [columnId as ColumnKeys]: width } };

  if (columnDisplayInitialState[columnId as ColumnKeys].width === width) {
    delete newColumnWidths[columnId as ColumnKeys];
  }

  yield put(
    userInformationActions.updateUserSettings({
      [userSetting]: newColumnWidths,
    })
  );
}

export function* resetColumnWidthsSaga(action: PayloadAction<ColumnWidthUserSettings>) {
  yield put(
    userInformationActions.updateUserSettings({
      [action.payload]: {},
    })
  );
}
