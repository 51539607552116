import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// redux
import { actions as filterCardsActions } from '@optx/redux/my-companies/filters-cards';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
// utils
import { sliceURLQuery } from '@optx/utils/url';
// components
import SearchInput, { SearchInputProps } from '../../common/form/input/SearchInput';

interface CompanySearchProps extends Omit<SearchInputProps, 'onSearch' | 'value'> {}

const MyCompaniesSearch: React.FC<CompanySearchProps> = ({ ...restProps }) => {
  const dispatch = useDispatch();
  const filter = useSelector(filterSelectors.getFilter('myCompanies'));
  const [searchKeyQuery, setSearchKeyQuery] = useState('');

  const search = useCallback(
    (searchKey: string) => {
      dispatch(
        filterCardsActions.fetchMyCompaniesCards({ searchKey, filter, shouldResetPageNumber: true })
      );
      setSearchKeyQuery('');
    },
    [dispatch, filter]
  );

  const handleSearch = (searchKey: string) => {
    const slicedQuery = sliceURLQuery(searchKey);
    search(slicedQuery);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const slicedQuery = sliceURLQuery(event.target.value);
    setSearchKeyQuery(slicedQuery);
  };

  return (
    <SearchInput
      {...restProps}
      onChange={handleChange}
      onSearch={handleSearch}
      value={searchKeyQuery}
      placeholder="Search for My Companies by keyword"
    />
  );
};

export default React.memo(MyCompaniesSearch);
