import React, { useRef } from 'react';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikProps } from 'formik';
//constants
import globalConfig from '@optx/constants/config';
import { NEXT_STEPS_OPINION_ENDPOINT } from '@constants/asyncEndpoints';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// services
import { CompanyService } from '@optx/services/api';
// models
import { CompanyProfile } from '@optx/models/Company';
// redux
import { selectors, actions } from '@redux/company/opportunity-presentation';
// components
import { EditFieldDate } from '@optx/features/company/edit-fields/components';
import EditFieldNumber from './inputs/EditFieldNumber';
import EditFieldSingleSelect from './inputs/EditFieldSingleSelect';
import EditFieldCheckboxText from './inputs/EditFieldCheckboxText';
import * as Styled from '../styles/DealPresentation.styled';
import TextareaField from '@optx/features/field-factory/TextareaField';
import SingleSelectOtherField from '@optx/features/field-factory/SingleSelectOtherField';

type DealPreparationProps = {
  company: CompanyProfile;
  isChromeExtension?: boolean;
};

const DealPreparation: React.FC<DealPreparationProps> = ({ company, isChromeExtension }) => {
  const dealValues = useSelector(selectors.getDealPreparationValue);
  const dispatch = useDispatch();
  const formRef = useRef<FormikProps<any> | null>(null);
  let fieldName: string | undefined = undefined;
  let secondFieldName: string | undefined = undefined;

  const handleUpdateField = (fieldType?: string, secondField?: string) => {
    fieldName = fieldType;
    secondFieldName = secondField;

    if (formRef?.current) {
      formRef.current.submitForm();
    }
  };

  const handleSubmit = () => {
    if (formRef?.current) {
      const payload = {
        company_id: company.company_id,
        deal_preparation: {
          [fieldName as string]: formRef.current?.values[fieldName as string],
          ...((secondFieldName as string | undefined)
            ? { [secondFieldName as string]: formRef.current?.values[secondFieldName as string] }
            : {}),
        },
      };
      dispatch(actions.updateOpportunityPresentation(payload));
    }

    formRef.current?.resetForm();
  };

  if (isChromeExtension) {
    return (
      <Styled.DealPresentationWrapper isChromeExtension={isChromeExtension}>
        <Row className="deal-preparation-information">
          <Col className="deal-preparation-information__label">Date Presented</Col>
          <Col className="deal-preparation-information__edit">
            <EditFieldDate
              fieldKey="date_presented"
              record={company}
              value={company.date_presented}
              isFromGrid={false}
              service={CompanyService.updateDatePresented}
              successMessage="Success updating company presentation!"
              fieldName="Date Presented"
              format={globalConfig.short_date.DATE_FORMAT}
            />
          </Col>
        </Row>
        <Row className="deal-preparation-information">
          <Col className="deal-preparation-information__label">Asking Amount</Col>
          <Col className="deal-preparation-information__edit">
            <EditFieldNumber
              companyId={company.company_id}
              fieldPropietaryInfoType="deal_preparation"
              fieldKey="Asking Amount"
              initialValue={(dealValues.asking_amount as number) / 1000000 || 0}
            />
          </Col>
        </Row>
        <Row className="deal-preparation-information">
          <Col className="deal-preparation-information__label">Banker</Col>
          <Col className="deal-preparation-information__edit">
            <EditFieldCheckboxText
              companyId={company.company_id}
              checkboxKey="banker"
              textInputKey="banker_name"
              checkValue={(dealValues.banker?.value as boolean) ?? null}
              textValue={dealValues.banker_name ?? undefined}
              isCheckbox
            />
          </Col>
        </Row>
        <Row className="deal-preparation-information">
          <Col className="deal-preparation-information__label">Name of Banker</Col>
          <Col className="deal-preparation-information__edit">
            <EditFieldCheckboxText
              companyId={company.company_id}
              checkboxKey="banker"
              textInputKey="banker_name"
              checkValue={(dealValues.banker?.value as boolean) ?? null}
              textValue={dealValues.banker_name ?? undefined}
            />
          </Col>
        </Row>
        <Row className="deal-preparation-information">
          <Col className="deal-preparation-information__label">Deal Type</Col>
          <Col className="deal-preparation-information__edit">
            <EditFieldSingleSelect
              companyId={company.company_id}
              fieldPropietaryInfoType="deal_preparation"
              options={dealValues.deal_type?.data as any}
              fieldKey="Deal Type"
              initialValue={dealValues.deal_type?.value as string}
            />
          </Col>
        </Row>
      </Styled.DealPresentationWrapper>
    );
  }

  return (
    <Formik
      initialValues={{}}
      onSubmit={handleSubmit}
      innerRef={instance => {
        formRef.current = instance;
      }}
    >
      <Styled.DealPresentationWrapper>
        <Row className="deal-preparation-information">
          <Col className="deal-preparation-information__label">Deal Type</Col>
          <Col className="deal-preparation-information__edit">
            <EditFieldSingleSelect
              companyId={company.company_id}
              fieldKey="Deal Type"
              options={dealValues.deal_type?.data}
              fieldPropietaryInfoType="deal_preparation"
              initialValue={dealValues.deal_type?.value as string}
            />
          </Col>
        </Row>
        <Row className="deal-preparation-information">
          <Col className="deal-preparation-information__label">Date Presented</Col>
          <Col className="deal-preparation-information__edit">
            <EditFieldDate
              fieldKey="date_presented"
              record={company}
              value={company.date_presented}
              isFromGrid={false}
              service={CompanyService.updateDatePresented}
              successMessage="Success updating company presentation!"
              fieldName="Date Presented"
              format={globalConfig.short_date.DATE_FORMAT}
            />
          </Col>
        </Row>
        <Row className="deal-preparation-information">
          <Col className="deal-preparation-information__label">Equity Check (ask amount)</Col>
          <Col className="deal-preparation-information__edit">
            <EditFieldNumber
              companyId={company.company_id}
              fieldPropietaryInfoType="deal_preparation"
              fieldKey="Asking Amount"
              initialValue={(dealValues.asking_amount as number) / 1000000 || 0}
            />
          </Col>
        </Row>
        <Row className="deal-preparation-information">
          <Col className="deal-preparation-information__label">Banker</Col>
          <Col className="deal-preparation-information__edit">
            <EditFieldCheckboxText
              companyId={company.company_id}
              checkboxKey="banker"
              textInputKey="banker_name"
              checkValue={(dealValues.banker?.value as boolean) ?? null}
              textValue={dealValues.banker_name ?? undefined}
              isCheckbox
            />
          </Col>
        </Row>
        <Row className="deal-preparation-information">
          <Col className="deal-preparation-information__label">Name of Banker</Col>
          <Col className="deal-preparation-information__edit">
            <EditFieldCheckboxText
              companyId={company.company_id}
              checkboxKey="banker"
              textInputKey="banker_name"
              checkValue={(dealValues.banker?.value as boolean) ?? null}
              textValue={dealValues.banker_name ?? undefined}
            />
          </Col>
        </Row>
        <Row className="deal-preparation-information">
          <Col className="deal-preparation-information__label">Next Steps</Col>
          {company.source_tag.includes('et') ? (
            <Col className="deal-preparation-information__edit">
              <SingleSelectOtherField
                fieldName="Next Steps"
                fieldType="next_steps"
                options={[
                  { value: 'blank', label: 'None' },
                  ...(dealValues.next_steps?.data ? dealValues.next_steps?.data : []),
                ]}
                onSave={handleUpdateField}
                value={dealValues.next_steps?.value as string}
                otherAsyncEndpoint={NEXT_STEPS_OPINION_ENDPOINT}
                otherType={'next_steps_opinion'}
                otherValue={dealValues.next_steps_opinion ?? null}
              />
            </Col>
          ) : (
            <Col className="profile-information__value">
              {(dealValues.next_steps?.value as string) || DEFAULT_EMPTY_VALUE}
            </Col>
          )}
        </Row>
        {!isChromeExtension && (
          <>
            <Row style={{ marginTop: '22px' }} className="deal-preparation-information">
              <Col className="deal-preparation-information__label">Desired Transaction Notes</Col>
            </Row>

            <Col
              style={{ paddingLeft: '0px', paddingRight: '0px' }}
              className="deal-preparation-information__notes"
            >
              <TextareaField
                fieldName="Desired Transaction Notes"
                characterLimit={500}
                value={dealValues.transaction_notes}
                fieldType="transaction_notes"
                onSave={handleUpdateField}
              />
            </Col>
          </>
        )}
      </Styled.DealPresentationWrapper>
    </Formik>
  );
};

export default DealPreparation;
