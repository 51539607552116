import { createAction } from '@reduxjs/toolkit';
// redux
import {
  SelectedCompanies,
  ResolveSalesloft,
  ResolveSalesloftSuccess,
  SalesloftContactsBulk,
  SalesloftCompanyContacts,
  SalesloftBulkResults,
  SelectedCompaniesGrids,
} from '@models/bulkActions';
import { CompanyUserContact } from '@optx/models/api/contacts';
import * as types from './types';
import { SalesloftCadences, AddNewContact } from '../../../../models/Salesloft';

export const fetchCompaniesContacts = createAction<SelectedCompaniesGrids>(
  types.fetchCompaniesContacts
);
export const fetchCompaniesContactsSuccess = createAction(
  types.fetchCompaniesContactsSuccess,
  (
    contacts: SalesloftCompanyContacts[],
    selectedCompanies: SelectedCompanies[],
    gridName: SelectedCompaniesGrids
  ) => ({
    payload: {
      contacts,
      selectedCompanies,
      gridName,
    },
  })
);

export const addPersonBulk = createAction<SalesloftCadences>(types.addPersonBulk);

export const addContact = createAction<AddNewContact>(types.addContact);
export const addContactSuccess = createAction<Partial<CompanyUserContact>>(types.addContactSuccess);
export const setModalVisible = createAction<boolean>(types.setModalVisible);
export const addPersonBulkFail = createAction<string>(types.addPersonBulkFail);
export const resolveSalesloft = createAction<ResolveSalesloft>(types.resolveSalesloft);
export const resolveSalesloftSuccess = createAction<ResolveSalesloftSuccess>(
  types.resolveSalesloftSuccess
);

export const salesloftSelectContactsBulk = createAction<SalesloftContactsBulk>(
  types.salesloftSelectContactsBulk
);

export const updateProgress = createAction<boolean>(types.updateProgress);

export const updateCompleted = createAction<boolean>(types.updateCompleted);

export const updatePercentage = createAction<number>(types.updatePercentage);

export const saveResults = createAction<SalesloftBulkResults[]>(types.saveResults);

export const resetProgress = createAction(types.resetProgress);

export const cancel = createAction<boolean>(types.cancel);
