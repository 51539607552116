import React from 'react';
import { components, ValueContainerProps } from 'react-select';
import { SearchOutlined } from '@ant-design/icons';
import { Styled } from './ValueContainer.styled';

/**
 * Dropdown search area.
 */
const ValueContainer: React.FC<ValueContainerProps<any>> = props => {
  const { children } = props;

  const showSearchIcon = props.selectProps.SearchIcon;

  return (
    <components.ValueContainer {...props}>
      <Styled.IconWrapper>
        {showSearchIcon === false ? null : <SearchOutlined />}
      </Styled.IconWrapper>
      {children}
    </components.ValueContainer>
  );
};

export default ValueContainer;
