export const FETCH_COMPANY_PROFILE = 'FETCH_COMPANY_PROFILE';
export const FETCH_COMPANY_PROFILE_SUCCESS = 'FETCH_COMPANY_PROFILE_SUCCESS';
export const FETCH_COMPANY_PROFILE_FAIL = 'FETCH_COMPANY_PROFILE_FAIL';

export const BACK_TO_RESULTS = 'BACK_TO_RESULTS';

export const RESET_COMPANY_PROFILE = 'RESET_COMPANY_PROFILE';

export const SET_SELECTED_DESCRIPTION = 'SET_SELECTED_DESCRIPTION';

export const VIEW_SIMILAR_COMPANIES_ON_GRID = 'VIEW_SIMILAR_COMPANIES_ON_GRID';

// AI Description
export const REGENERATE_AI_DESCRIPTION = 'REGENERATE_AI_DESCRIPTION';
export const REGENERATE_AI_DESCRIPTION_SUCCESS = 'REGENERATE_AI_DESCRIPTION_SUCCESS';
export const REGENERATE_AI_DESCRIPTION_FAIL = 'REGENERATE_AI_DESCRIPTION_FAIL';

export const OPINION_AI_DESCRIPTION = 'OPINION_AI_DESCRIPTION';
export const OPINION_AI_DESCRIPTION_SUCCESS = 'OPINION_AI_DESCRIPTION_SUCCESS';
export const OPINION_AI_DESCRIPTION_FAIL = 'OPINION_AI_DESCRIPTION_FAIL';

// relationship summary
export const AI_RELATIONSHIP_SUMMARY = 'AI_RELATIONSHIP_SUMMARY';
export const AI_RELATIONSHIP_SUMMARY_SUCCESS = 'AI_RELATIONSHIP_SUMMARY_SUCCESS';
export const AI_RELATIONSHIP_SUMMARY_FAIL = 'AI_RELATIONSHIP_SUMMARY_FAIL';
