import { createSelector } from 'reselect';
import { SelectedCompaniesGrids } from '@optx/models/bulkActions';
import {
  getCompanies,
  checkEquityTouch,
  checkCompaniesOwner,
} from '../../selected-companies/state/selectors';
import { selectFeatureState } from './selectFeature';

const selectedCompaniesState = createSelector(selectFeatureState, state => {
  return state.selectedCompanies;
});

// slice selectors
const getSelectedCompanies = (gridName: SelectedCompaniesGrids) => {
  return createSelector(selectedCompaniesState, getCompanies(gridName));
};

const areAllCompaniesInEqt = (gridName: SelectedCompaniesGrids) => {
  return createSelector(selectedCompaniesState, checkEquityTouch(gridName));
};

const areCompaniesAssigned = (gridName: SelectedCompaniesGrids, analyst: string) => {
  return createSelector(selectedCompaniesState, checkCompaniesOwner(gridName, analyst));
};

export default {
  getSelectedCompanies,
  areAllCompaniesInEqt,
  areCompaniesAssigned,
};
