import styled from 'styled-components';

export const CardContainer = styled.div`
  .ant-card-bordered {
    border: none !important;
  }

  .ant-card-head-title {
    font-weight: bold;
  }

  .ant-card-head {
    border-bottom: none;
  }

  .ant-card-head-title {
    padding: 22px 0 8px 0;
  }
`;
