import React from 'react';
import { Tag } from 'antd';

// reselect props for this component are not compatible with
// using an object to render the tags
const ContactTags = (props: any) => {
  const { label } = props;

  return (
    <span
      className="d-inline-flex"
      style={{
        maxWidth: '100%',
        alignItems: 'center',
      }}
    >
      <span className="text-ellipsis">
        {label?.props?.contact?.full_name ||
          `${label?.props?.contact?.first_name} ${label?.props?.contact?.last_name}`}
      </span>
      {label?.props?.contact?.primary_contact && (
        <span className="flex-grow-1">
          <Tag color="blue">Primary</Tag>
        </span>
      )}
    </span>
  );
};

export default ContactTags;
