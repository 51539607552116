import styled from 'styled-components';

const ColumnHeader = styled.div`
  padding: 22px;
  display: flex;

  .ant-dropdown-trigger {
    flex: 1;
  }
`;

const ColumnWrapper = styled.div`
  background: #fff;
  padding: 10px 20px 10px 12px;
  border-top: 1px solid #c2cfe0;
  position: relative;

  &:not(.filtered):hover {
    .handle,
    .pin {
      display: block;
    }
  }

  &.select-all {
    display: flex;
    justify-content: space-between;
  }

  .ant-btn-link {
    padding: 0;
    height: auto;
    border: 0;
  }

  .pin,
  .handle {
    position: absolute;
    top: 14px;
  }

  .pin {
    display: none;
    right: 30px;
    cursor: pointer;
  }

  &.filtered .pin {
    display: block;
  }

  .handle {
    display: none;
    right: 10px;
    cursor: grab;
  }
`;

export default { ColumnWrapper, ColumnHeader };
