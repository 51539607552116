import { createAction } from '@reduxjs/toolkit';
import { Dictionary } from 'lodash';
// models
import { EquityTouchFetchedField, EquityTouchContact } from '@models/equityTouch';
import {
  CompanyBulkData,
  BulkCompanyData,
  EquityTouchBulkSuccess,
  ResolveSalesloft,
  ResolveSalesloftSuccess,
  UpdateCompanyAndSubmit,
  SalesloftContactsBulk,
  EquityTouchBulkResults,
  EquityTouchBulkAutoSave,
  SelectedCompaniesData,
  TemporaryDataEquityTouch,
  EquityTouchBulkCompanies,
} from '@models/bulkActions';
import { CompanyUserContact } from '@models/api/contacts';
import { CompanyProfile } from '@models/Company';
// redux
import * as types from './types';

export const initializeEquityTouchBulk = createAction<SelectedCompaniesData>(
  types.initializeEquityTouchBulk
);

export const initializeEquityTouchBulkSuccess = createAction<BulkCompanyData>(
  types.initializeEquityTouchBulkSuccess
);

export const initializeIndividualEditing = createAction<
  Dictionary<string | number | number[] | boolean>
>(types.initializeIndividualEditing);

export const fetchFormDataSuccess = createAction(
  types.fetchFormDataSuccess,
  (formData: Dictionary<EquityTouchFetchedField>, isInitializing: boolean, companyId?: number) => ({
    payload: {
      formData,
      isInitializing,
      companyId,
    },
  })
);

export const addToEquityTouchBulk = createAction<CompanyBulkData>(types.addToEquityTouchBulk);
export const addToEquityTouchBulkSuccess = createAction<EquityTouchBulkSuccess>(
  types.addToEquityTouchBulkSuccess
);
export const addToEquityTouchBulkFail = createAction<string>(types.addToEquityTouchBulkFail);

export const fetchCompany = createAction(
  types.fetchCompany,
  (companyId: number, fetchEqtData: boolean = true) => ({
    payload: {
      companyId,
      fetchEqtData,
    },
  })
);
export const fetchCompanySuccess = createAction<CompanyProfile>(types.fetchCompanySuccess);

export const addBulkContacts = createAction(
  types.addBulkContacts,
  (contacts: EquityTouchContact[], companyId: number) => ({
    payload: {
      contacts,
      companyId,
    },
  })
);

export const updateBulkContact = createAction(
  types.updateBulkContact,
  (payload: Partial<CompanyUserContact>, callback?: VoidFunction) => ({
    payload,
    meta: callback,
  })
);

export const updateBulkCadence = createAction<EquityTouchBulkCompanies>(types.updateBulkCadence);

export const updateBulkContactSuccess = createAction<Partial<CompanyUserContact>>(
  types.updateBulkContactSuccess
);

export const updateBulkCompany = createAction<UpdateCompanyAndSubmit>(types.updateBulkCompany);

export const updateProgress = createAction<boolean>(types.updateProgress);

export const updateCompleted = createAction<boolean>(types.updateCompleted);

export const updatePercentage = createAction<number>(types.updatePercentage);

export const saveResults = createAction<EquityTouchBulkResults[]>(types.saveResults);

export const resetProgress = createAction(types.resetProgress);

export const cancel = createAction<boolean>(types.cancel);

export const resolveSalesloft = createAction<ResolveSalesloft>(types.resolveSalesloft);
export const resolveSalesloftSuccess = createAction<ResolveSalesloftSuccess>(
  types.resolveSalesloftSuccess
);

export const salesloftSelectContactsBulk = createAction<SalesloftContactsBulk>(
  types.salesloftSelectContactsBulk
);

export const selectCadenceIndividually = createAction(
  types.selectCadenceIndividually,
  (cadenceId: number | string, companyId: number) => ({
    payload: {
      cadenceId,
      companyId,
    },
  })
);

export const fetchContacts = createAction<number>(types.fetchContacts);
export const fetchContactsSuccess = createAction(
  types.fetchContactsSuccess,
  (contacts: CompanyUserContact[], companyId: number) => ({
    payload: {
      contacts,
      companyId,
    },
  })
);

export const autoSave = createAction<EquityTouchBulkAutoSave | null>(types.autoSave);

export const updateTemporaryData = createAction<TemporaryDataEquityTouch>(
  types.updateTemporaryData
);
