import React from 'react';
import { Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// components
import { MainHeader } from '@components/common/layout';
import ClearSearchButton from '@optx/shared/view/molecules/Buttons/ClearSearchButton';
// Local
import { selectors } from '../../state';
import * as filtersActions from '../../state/filters/actions';
// components
import FilterTags from './FilterTags';

const Header = () => {
  const dispatch = useDispatch();

  const showRestoreIcon = useSelector(selectors.filters.showClearAll);

  return (
    <MainHeader className="main-header fixed">
      <div className="search-header-content" style={{ height: '100%' }}>
        <FilterTags />
        <Space size={4} style={{ marginLeft: 'auto' }}>
          <ClearSearchButton
            title="Clear All"
            isVisible={showRestoreIcon}
            onClick={() => dispatch(filtersActions.clearAllFilters())}
          />
        </Space>
      </div>
    </MainHeader>
  );
};

export default Header;
