import { createSelector } from 'reselect';
// interface
import { AppState } from '@optx/redux/interfaces';
// redux
import { getSearchCount } from '@optx/features/grid/search-count/state/selectors';
import { localSelectors as localHistogramSelectors } from '@features/histograms/base-histograms';

// Histogram filters.
const selectHistogramsState = (state: AppState) => state.myCompanies.histogramFilters;

export const selectEditHistograms = createSelector(
  selectHistogramsState,
  localHistogramSelectors.selectEditHistograms
);

export const selectHistograms = createSelector(
  selectHistogramsState,
  localHistogramSelectors.histograms
);

export const selectAllFiltersWithHistograms = createSelector(
  selectHistogramsState,
  localHistogramSelectors.allFiltersWithHistograms
);

export const getHistogramSearchCount = createSelector(
  selectHistogramsState,
  localHistogramSelectors.searchCount
);

export const selectChangedFilters = createSelector(
  selectHistogramsState,
  localHistogramSelectors.changedFilters
);

export const loadingHistograms = createSelector(selectHistogramsState, state => state.loading);

export const histogramsInitialized = createSelector(
  selectHistogramsState,
  localHistogramSelectors.isInitialized
);

export const selectHistogramRequestFilters = createSelector(
  selectHistogramsState,
  localHistogramSelectors.histogramRequestFilters
);

export const selectDefaultHistograms = createSelector(
  selectHistogramsState,
  localHistogramSelectors.defaultHistograms
);

export const histogramsEnabled = createSelector(
  selectHistogramsState,
  localHistogramSelectors.isEnabled
);

/**
 * Search count for filters modal. Usually if histograms are enabled the call will update this count.
 * When the search is applied the count will be the one in search.
 */
export const getEditModeSearchCount = createSelector(
  getHistogramSearchCount,
  getSearchCount('myCompanies'),
  (histogramSearchCount, searchTotalCount) =>
    typeof histogramSearchCount === 'number' ? histogramSearchCount : searchTotalCount
);
