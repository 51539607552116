import { createSelector } from 'reselect';
import { selectors as websiteRankSelectors } from './website-rank';
import { selectors as capterraSelectors } from './capterra';
import { selectors as crunchbaseSelectors } from './crunchbase';
import { selectors as facebookSelectors } from './facebook';
import { selectors as g2Selectors } from './g2';
import { selectors as glassdoorSelectors } from './glassdoor';
import { selectors as linkedinSelectors } from './linkedin';
import { selectors as twitterSelectors } from './twitter';

export const socialMediaLoading = createSelector(
  websiteRankSelectors.loading,
  capterraSelectors.loading,
  crunchbaseSelectors.loading,
  facebookSelectors.loading,
  g2Selectors.loading,
  glassdoorSelectors.loading,
  linkedinSelectors.loading,
  twitterSelectors.loading,
  (
    websiteRankLoading,
    capterraLoading,
    crunchbaseLoading,
    facebookLoading,
    g2Loading,
    glassdoorLoading,
    linkedinLoading,
    twitterLoading
  ) =>
    websiteRankLoading ||
    capterraLoading ||
    crunchbaseLoading ||
    facebookLoading ||
    g2Loading ||
    glassdoorLoading ||
    linkedinLoading ||
    twitterLoading
);
