import React from 'react';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
// models
import { Company } from '@optx/models/Company';
// redux
import { selectors as cardSelectors } from '@features/long-card/company-card';
// components
import EditContact from '@components/pages/CompanyProfile/ProfileTabs/NotesAndTouches/EditContact';
import ContactsTable from './ContactsTable';
import { PrimaryContact } from './PrimaryContact';

interface ContactsProps {
  company: Company;
}

const Contacts: React.FC<ContactsProps> = ({ company }) => {
  const { logo_url: logoUrl, contacts } = company;

  const primaryContact = contacts.find(item => item.primary_contact);
  const pluginPrimaryContact = useSelector(cardSelectors.getPluginPrimaryContact);

  return (
    <>
      <Row gutter={[2, 16]} className="contacts-primary-contact-row">
        {primaryContact && (
          <PrimaryContact
            primaryContact={primaryContact}
            logo={logoUrl}
            primaryContactDescription={pluginPrimaryContact}
          />
        )}
      </Row>
      <Row gutter={[2, 16]} className="contacts-table-row">
        <Col span={24}>
          <EditContact isExtension />
          <ContactsTable contacts={contacts} />
        </Col>
      </Row>
    </>
  );
};

export default Contacts;
