import { Dictionary } from 'lodash';

// redux
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useInjectSaga } from 'redux-injectors';
import { SuccessErrorCallback } from '@optx/models/callback';
import { actions, saga } from '../../../redux/async-multiselect';

interface SelectSearchPayload {
  query?: string;
  endpoint: string;
  additionalOptions?: Dictionary<number | string | Array<string>>;
}

interface UseAsyncSearchOptions {
  endpoint: string;
  action?: (payload: SelectSearchPayload, callback: SuccessErrorCallback) => void;
  dependenciesOptions?: Dictionary<number | string | Array<string>>;
}

const useInjectAsyncMultiSelect = () => {
  useInjectSaga({ key: 'asyncMultiSelect', saga });
};

// action
const searchAction = actions.searchAsyncMultiSelect;

const useAsyncSearch = (options: UseAsyncSearchOptions) => {
  useInjectAsyncMultiSelect();

  const { endpoint, action = searchAction, dependenciesOptions } = options;

  const dispatch = useDispatch();
  const search = useCallback(
    (query: string, callback: SuccessErrorCallback) => {
      dispatch(
        action(
          {
            query,
            endpoint,
            additionalOptions: dependenciesOptions,
          },
          callback
        )
      );
    },
    [dispatch, action, endpoint, dependenciesOptions]
  );

  return {
    loadOptions: search,
  };
};

export default useAsyncSearch;
