import React, { Context } from 'react';
// redux
import { BulkEditFieldContext } from '@optx/models/bulkActions';
// components
import { EditStage } from '@features/bulk-actions/edit-fields/components/EditStage';
import { EditPipelineRank } from '@features/bulk-actions/edit-fields/components/EditPipelineRank';
import { EditCompanyOwner } from '@features/bulk-actions/edit-fields/components/EditCompanyOwner';
import { EditFund } from '@features/bulk-actions/edit-fields/components/EditFund';
import { EditRationale } from '@features/bulk-actions/edit-fields/components/EditRationale';
import { EditAddon } from '@features/bulk-actions/edit-fields/components/edit-addon/EditAddon';
import { EditLeadSource } from '@features/bulk-actions/edit-fields/components/EditLeadSource';
import { EditSeniorAdvisor } from '@features/bulk-actions/edit-fields/components/EditSeniorAdvisor';
import { EditWatchList } from '@features/bulk-actions/bulk-watchlist';
import { BulkAddTags } from '@optx/features/tags/tags';
import { EditSoftware } from './EditSoftware';
import { EditSubsector } from './EditSubsector';
import { EditProductCategory } from './EditProductCategory';
import { EditAiMl } from './EditAiMl';

interface BulkEditFieldProps {
  field: string;
  context: Context<BulkEditFieldContext>;
  isValid: boolean;
  validation: (isValid: boolean) => void;
}

export const BulkEditField: React.FC<BulkEditFieldProps> = ({
  field,
  context,
  isValid,
  validation,
}) => {
  let component = null;

  switch (field) {
    case 'stage':
      component = <EditStage context={context} />;
      break;
    case 'pipeline_rank':
      component = <EditPipelineRank context={context} />;
      break;
    case 'company_owner_id':
      component = <EditCompanyOwner context={context} />;
      break;
    case 'fund':
      component = <EditFund context={context} />;
      break;
    case 'rationale':
      component = <EditRationale context={context} isValid={isValid} validation={validation} />;
      break;
    case 'addon':
      component = <EditAddon context={context} />;
      break;
    case 'is_software':
      component = <EditSoftware context={context} />;
      break;
    case 'cs_tags':
      component = <BulkAddTags context={context} />;
      break;
    case 'watchlist':
      component = <EditWatchList context={context} />;
      break;
    case 'lead_source':
      component = <EditLeadSource context={context} />;
      break;
    case 'senior_advisor_thesis':
      component = <EditSeniorAdvisor context={context} />;
      break;
    case 'sub_sector':
      component = <EditSubsector context={context} />;
      break;
    case 'product_category':
      component = <EditProductCategory context={context} />;
      break;
    case 'is_ai_ml':
      component = <EditAiMl context={context} />;
      break;
    default:
      return null;
  }

  return <>{component}</>;
};
