import React from 'react';
import { useSelector } from 'react-redux';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
// redux
import { selectors as viewTypeSelectors } from '@features/grid/view-type';
import ToggleSortType from './ToggleSortType';

const ToggleSearchViewSort = ({ ...restProps }: MenuItemProps) => {
  const searchView = useSelector(viewTypeSelectors.searchView('advancedSearch'));

  return <>{searchView === 'table' && <ToggleSortType {...restProps} />}</>;
};

export default ToggleSearchViewSort;
