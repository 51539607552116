import { Button, Tooltip } from 'antd';
import AntIcon from '@ant-design/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { ButtonProps } from 'antd/lib/button';
import styled from 'styled-components';
// models
import { GridKeysMain } from '@optx/models/grid';
// utils
import { getLocationMainGridKey } from '@optx/utils/grid';
// redux
import { selectors as searchSelectors } from '@features/grid/search';
// components
import Icon from '../../Icon';

interface ToggleButtonProps extends ButtonProps {
  hide: boolean;
}

const StyledIcon = styled(Icon)`
  font-size: 18px;
  color: #afbdd1;
`;

interface StyledButtonProps extends ButtonProps {
  $hide: boolean;
}

const StyledButton = styled(Button)<StyledButtonProps>`
  transform: ${props => (props.$hide ? 'rotate(180deg)' : 'none')};
`;

const ArrowIcon = () => <StyledIcon iconName="arrow-down-end" />;

const ToggleButton = ({ hide, title, ...restProps }: ToggleButtonProps) => {
  const listType = useSelector(
    searchSelectors.getListType(getLocationMainGridKey() as GridKeysMain)
  );
  const isFilterDisabled = listType === 'combined';
  const tooltipTitle = isFilterDisabled ? 'Not supported for combined searches' : title;

  return (
    <Tooltip title={tooltipTitle}>
      <StyledButton
        disabled={isFilterDisabled}
        type="text"
        icon={<AntIcon component={ArrowIcon} />}
        $hide={hide}
        {...restProps}
      />
    </Tooltip>
  );
};

export default ToggleButton;
