import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import reducer, { saga } from '../../../redux/favorites';

const useInjectFavorites = () => {
  useInjectReducer({
    key: 'favorites',
    reducer,
  });
  useInjectSaga({ key: 'favorites', saga });
};

export default useInjectFavorites;
