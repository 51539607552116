import { useState, useLayoutEffect } from 'react';

const useTableLayoutHeight = (
  tableWrapperRef: HTMLDivElement | null,
  paginationId: string,
  windowSize: number | undefined,
  tableHeader: number | undefined,
  mainHeaderHeight: number | undefined,
  extraDependentValues: any[]
) => {
  const [tableScroll, setTableScroll] = useState<number>(0);

  const extraDep = [
    tableWrapperRef,
    paginationId,
    windowSize,
    tableHeader,
    mainHeaderHeight,
    extraDependentValues,
  ];
  /**
   * Recompute height each time the height of the window changes or wrapper height changes.
   */
  useLayoutEffect(() => {
    /**
     * Temporary fix (setTimeout) for scroll setup. Initially on page load the grid header has a bigger height and
     * if you click on a company and afterwards come back to home will compute scroll based on first
     * header height and the grid height will be smaller.
     */
    setTimeout(() => {
      if (tableWrapperRef) {
        const tableHeaderHeight =
          tableWrapperRef?.querySelector('.ant-table-header')?.getBoundingClientRect().height || 0;

        // When grid is initializing the header height will get throwgh a few height changes with bigger heights
        // before is actually initialized.
        if (tableHeaderHeight) {
          const tableDOMRect = tableWrapperRef.getBoundingClientRect();
          const windowHeight = window.innerHeight;
          const paginationHeight =
            document.querySelector(`#${paginationId}`)?.getBoundingClientRect().height || 0;
          const bottomSpace = tableDOMRect.bottom - windowHeight + paginationHeight + 2;
          setTableScroll(
            bottomSpace < 0
              ? Math.abs(bottomSpace) + tableDOMRect.height - tableHeaderHeight
              : tableDOMRect.height - tableHeaderHeight - bottomSpace
          );
        }
      }
    }, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, extraDep);

  return { tableScroll };
};

export default useTableLayoutHeight;
