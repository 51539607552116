import { createAction } from '@reduxjs/toolkit';
// models
import * as types from './types';
import { BulkSoftware, BulkSoftwareSuccess } from './interfaces';

export const bulkSoftware = createAction<BulkSoftware>(types.bulkSoftware);
export const bulkSoftwareSuccess = createAction<{
  data: BulkSoftwareSuccess[];
  count: number;
  isAdminOrAnalyst?: boolean;
}>(types.bulkSoftwareSuccess);
