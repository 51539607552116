import React, { memo } from 'react';
// models
import Option from '@models/Option';

interface TouchActivityLegendsProps {
  data: Option<number>[];
  handleRedirect: (label: string) => void;
}

const TouchActivityLegends: React.FC<TouchActivityLegendsProps> = ({ data, handleRedirect }) => {
  return (
    <div className="chart-legends">
      {data.map((item: Option<number>) => {
        return (
          <div key={item.label} className="chart-legend">
            <span className="count">{item.value}</span>
            <div
              className={`label${!item.disabled ? ' clickable' : ''}`}
              onClick={() => !item.disabled && handleRedirect(item.label!)}
            >
              <span className="name">{item.label}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default memo(TouchActivityLegends);
