import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// models
import { CompanyNotification } from '@models/Company';
import { EquityTouchBulkSuccess } from '@models/bulkActions';
import { UpdateCompanyET } from '@models/equityTouch';
// redux
import { actions as equityTouchActions } from '@redux/company/equity-touch';
import { actions as bulkEqtActions } from '@features/bulk-actions/equity-touch';
import { fetchReducer, fetchFailReducer } from '@redux/feature/fetch/reducers';
import { deleteCompanyNoteSuccess } from '@redux/company/notes/companyNotes/actions';
import * as models from './interfaces';
import * as actions from './actions';

export const initialState: models.CompanyNotesSearchState = {
  error: '',
  loading: false,
  byId: {},
  allIds: [],
  fetchedAt: '',
  hasMore: true,
  searchQuery: {
    query: undefined,
    pageNumber: 0,
    pageSize: 30,
  },
};

const searchNotesReducer: CaseReducer<
  models.CompanyNotesSearchState,
  PayloadAction<models.NotesSearchQuery | undefined>
> = (draftState, action) => {
  fetchReducer(draftState);
  draftState.hasMore = false;
};

const searchNotesSuccessReducer: CaseReducer<
  models.CompanyNotesSearchState,
  PayloadAction<Array<CompanyNotification>, any, models.NotesSearchQuery>
> = (draftState, action) => {
  searchSuccessReducer(draftState, action);
};

const updateNoteSuccessReducer: CaseReducer<
  models.CompanyNotesSearchState,
  PayloadAction<CompanyNotification, any, string>
> = (draftState, action) => {
  const { payload: note, meta: text } = action;
  const currentNote = draftState.byId[note.note_unique_id];

  if (currentNote) {
    currentNote.note_text = text;
  }

  draftState.loading = false;
};

const deleteCompanyNoteSuccessReducer: CaseReducer<
  models.CompanyNotesSearchState,
  PayloadAction<number>
> = (draftState, action) => {
  const itemId = action.payload;
  const index = draftState.allIds.findIndex(id => id === itemId);

  if (index !== -1) {
    draftState.allIds.splice(index, 1);
    delete draftState.byId[itemId];
  }
};

const resetSearchReducer: CaseReducer<models.CompanyNotesSearchState> = draftState => {
  return {
    ...initialState,
    searchQuery: {
      ...draftState.searchQuery,
      pageNumber: 0,
    },
  };
};

const updateCompanyETDataReducer: CaseReducer<
  models.CompanyNotesSearchState,
  PayloadAction<UpdateCompanyET>
> = (draftState, action) => {
  const { payload } = action;
  const companyIds: number[] = [];
  companyIds.push(Number((payload as UpdateCompanyET).updateData.companyId));

  draftState.allIds.forEach(noteId => {
    if (companyIds.includes(draftState.byId[noteId].company_id)) {
      draftState.byId[noteId].is_in_et = true;
    }
  });
};

const addToEquityTouchBulkSuccessaReducer: CaseReducer<
  models.CompanyNotesSearchState,
  PayloadAction<EquityTouchBulkSuccess>
> = (draftState, action) => {
  const {
    payload: { results },
  } = action;
  const companyIds: number[] = [];

  results.forEach(result => {
    if (result.et_sync_status) {
      companyIds.push(result.company_id);
    }
  });

  draftState.allIds.forEach(noteId => {
    if (companyIds.includes(draftState.byId[noteId].company_id)) {
      draftState.byId[noteId].is_in_et = true;
    }
  });
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.searchNotes, searchNotesReducer)
    .addCase(actions.searchNotesSuccess, searchNotesSuccessReducer)
    .addCase(actions.searchNotesFail, fetchFailReducer)
    .addCase(actions.updateNote, fetchReducer)
    .addCase(actions.updateNoteSuccess, updateNoteSuccessReducer)
    .addCase(actions.updateNoteFail, fetchReducer)
    .addCase(actions.resetSearch, resetSearchReducer)
    .addCase(deleteCompanyNoteSuccess, deleteCompanyNoteSuccessReducer)
    .addCase(equityTouchActions.updateCompanyETData, updateCompanyETDataReducer)
    .addCase(bulkEqtActions.addToEquityTouchBulkSuccess, addToEquityTouchBulkSuccessaReducer)
);

export default reducer;

function searchSuccessReducer(
  draftState: models.CompanyNotesSearchState,
  action: PayloadAction<Array<CompanyNotification>, any, models.NotesSearchQuery>
) {
  const { payload: notes, meta: queryData } = action;
  const currentQueryData = draftState.searchQuery;

  // reset notes if key search changes or if query is empty
  if (
    queryData.query !== currentQueryData.query ||
    (queryData.query === undefined && queryData.pageNumber === 1)
  ) {
    draftState.byId = {};
    draftState.allIds = [];
  }

  notes.forEach(note => {
    const { note_unique_id: id } = note;
    draftState.allIds.push(id);
    draftState.byId[id] = note;
  });

  draftState.loading = false;

  if (!draftState.fetchedAt) {
    draftState.fetchedAt = new Date().toISOString();
  }

  // handle query update
  draftState.searchQuery = queryData;
  draftState.hasMore = notes.length === draftState.searchQuery.pageSize;
}
