import styled from 'styled-components';

const Value = styled.span`
  color: #1890ff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  flex: 0;
`;

const Label = styled.span`
  margin: 0 10px 0 0;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Styled = {
  Value,
  Label,
  Wrapper,
};
