import React, { useMemo } from 'react';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
// models
import { Cell } from '@models/table/Cell';
// components
import { cellIsEqual } from './memoize';

const DefaultTooltip = styled(Tooltip)`
  display: block;
`;

interface CustomCell extends Cell<any> {
  value: string | string[];
  format: string;
  defaultValue?: string;
}

const TableCellListDate: React.FC<CustomCell> = ({ value, format, defaultValue }) => {
  if (defaultValue) {
    return <>{defaultValue}</>;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const normalizedValues = useMemo(() => {
    return (value as string[])
      .reduce((result: string[], date: string) => {
        result.push(moment(date).format(format));

        return result;
      }, [])
      .join(', ');
  }, [value, format]);

  return (
    <DefaultTooltip title={normalizedValues} className="text-truncate">
      {normalizedValues}
    </DefaultTooltip>
  );
};

export default React.memo(TableCellListDate, cellIsEqual);
