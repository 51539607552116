import React from 'react';
import { Row, Col, Space } from 'antd';
import { useSelector } from 'react-redux';
// redux
import { selectors as fullscreenSelectors } from '@redux/ui/settings/fullscreen';
import { selectors as contactsSelectors } from '@redux/contacts/search/search';
// components
import ToggleSortType from './ToggleSortType';
import SearchCount from '@features/grid/search-count/components/SearchCount';
import GridFullScreen from '@optx/shared/view/molecules/FullScreen/GridFullScreen';
// styles
import Styled from './SecondaryHeader.styles';

const SecondaryHeader = () => {
  const isFullscreen = useSelector(fullscreenSelectors.isFullscreen);
  const contactsSearchTitle = useSelector(contactsSelectors.getSearchTitle);

  if (isFullscreen) {
    return null;
  }

  return (
    <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
      <Col>
        <div className="company-count text-ellipsis">
          <Styled.PageTitle>All Contacts</Styled.PageTitle>
          <SearchCount gridName="contacts" contactsSearchName={contactsSearchTitle} />
        </div>
      </Col>
      <Col>
        <Space size={4}>
          <ToggleSortType />
          <GridFullScreen />
        </Space>
      </Col>
    </Row>
  );
};

export default SecondaryHeader;
