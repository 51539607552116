import { useInjectSaga } from 'redux-injectors';
// redux
import saga from '../../../redux/pooling/saga';

// Inject saga used to pool data from API at specified intervals.
const useInjectPoolingGenerator = () => {
  useInjectSaga({ key: 'pooling', saga });
};

export default useInjectPoolingGenerator;
