import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// models
import { UserInformation } from '@optx/models/user';
// constants
import { SOURCE_SCRUB_LISTS_DEFAULT_SORT } from '@constants/table/sort/defaultSort';
// utils
import { parseSorting } from '@optx/utils/filters/parseSorting';
// redux
import { actions as userInformationActions } from '@redux/user/information';
import { fetchFailReducer, fetchReducer } from '@redux/feature/fetch/reducers';
import * as actions from './actions';
import * as models from './interfaces';
import { SourceScrubListState } from './interfaces';

export const initialState: SourceScrubListState = {
  data: [],
  loading: false,
  error: '',
  fetchedAt: '',
  sortBy: SOURCE_SCRUB_LISTS_DEFAULT_SORT,
  query: '',
  pageNumber: 1,
  total: 0,
  fetchedTotalAt: '',
};

const searchSourceScrubListsSuccessReducer: CaseReducer<
  SourceScrubListState,
  PayloadAction<models.SourceScrubListsResponse, any, models.SearchPayload>
> = (draftState, action) => {
  draftState.loading = false;
  draftState.data = action.payload.lists;
  draftState.query = action.meta.query;
  draftState.pageNumber = action.meta.pagination.pageNumber;
  draftState.sortBy = action.meta.sortBy;
  draftState.total = action.payload.total_list_count;
  draftState.fetchedAt = new Date().toISOString();
};

const fetchSourceScrubListsTotalSuccessReducer: CaseReducer<
  SourceScrubListState,
  PayloadAction<number>
> = (draftState, action) => {
  draftState.total = action.payload;
  draftState.fetchedTotalAt = new Date().toISOString();
};

export const fetchUserInformationSuccessReducer: CaseReducer<
  SourceScrubListState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const sorting = action.payload.settings.session_settings?.source_scrub_list_sorting;
  const query = action.payload.settings.session_settings?.source_scrub_list_filters;
  const pageNumber = action.payload.settings.session_settings?.source_scrub_list_page_number;

  if (sorting) {
    const { sortBy } = parseSorting(sorting);
    draftState.sortBy = sortBy;
  }

  if (query) {
    draftState.query = query;
  }

  if (pageNumber) {
    draftState.pageNumber = pageNumber;
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    // search
    .addCase(actions.searchSourceScrubLists, fetchReducer)
    .addCase(actions.searchSourceScrubListsSuccess, searchSourceScrubListsSuccessReducer)
    .addCase(actions.searchSourceScrubListsFail, fetchFailReducer)
    // change pagination
    .addCase(actions.changeSourceScrubPagination, fetchReducer)
    // sorting
    .addCase(actions.sortSourceScrub, fetchReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
    // total
    .addCase(actions.fetchSourceScrubListsTotalSuccess, fetchSourceScrubListsTotalSuccessReducer)
);

export default reducer;
