/* eslint-disable max-len */
import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Tag, Button, Tooltip, Typography } from 'antd';
import { FileTextOutlined, FilterOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Dictionary } from 'lodash';
import numeral from 'numeral';
// models
import { NotificationDetails, NotificationAttributes } from '@optx/models/notifications';
import { ViewOption } from '@optx/models/search';
import { FilterSource, Filter, PreselectedFilter } from '@models/filters';
// constants
import { NUMBER_FORMAT } from '@optx/constants/format/number';
// utils
import { getCompanyProfileURL, getListURL } from '@utils/routes';
import { mapTagsToNames } from '@optx/utils/filters/mapTagsToNames';
import { parseFilter } from '@utils/filters/parseFilters';
import mapTags from '@utils/filters/mapTags';
// redux
import { selectors as userSelectors } from '@redux/user/information';
import { selectors as filterSourcesSelectors } from '@redux/company/filters';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';
// components
import { IconCompany } from '@components/icons';
import { ScoreTrending } from '@components/common/trending';

interface NotificationItemProps {
  name: string;
  data: Array<NotificationDetails>;
  onDismissNotification?: (id: number) => void;
  views: ViewOption[];
}

const mapDeletedListSearchQuery = (
  searchCriteria: string,
  filterSources: Array<FilterSource<Filter<any>>> = [],
  clearedFilters: Dictionary<PreselectedFilter>
) => {
  const [parsedFilters] = parseFilter(searchCriteria, filterSources);

  return mapTags(parsedFilters, filterSources, clearedFilters);
};

const notificationText = (
  type: string,
  attributes: NotificationAttributes,
  changedBy: string,
  isNotificationType: boolean
) => {
  switch (type) {
    case 'interesting': {
      if (isNotificationType) {
        return <b>PSG Fit Status Change</b>;
      }

      return (
        <span>
          The PSG fit status has been changed by <b>{changedBy}</b> for a company that you own
        </span>
      );
    }

    case 'share_permission': {
      const permissionNotificationTitle = `Your permissions for this list have been changed by ${attributes.sender_name}`;

      if (isNotificationType) {
        return <b>{permissionNotificationTitle}</b>;
      }

      return <span>{attributes.note}</span>;
    }

    case 'list_share': {
      const isWatchList = attributes.list_type === 'watchlist';
      const watchListNotificationTitle = `A New List Has Been Shared With You by ${attributes.sender_name}`;

      if (isNotificationType) {
        return isWatchList ? (
          <Tooltip title={watchListNotificationTitle.length > 50 && watchListNotificationTitle}>
            <b>
              {watchListNotificationTitle.length > 50
                ? watchListNotificationTitle.slice(0, 50).concat('...')
                : watchListNotificationTitle}
            </b>
          </Tooltip>
        ) : (
          <b>A Search Has Been Shared With You</b>
        );
      }

      return isWatchList ? (
        <span>{attributes.note}</span>
      ) : (
        <span>
          A search has been shared with you by <b>{attributes.sender_name}</b>
        </span>
      );
    }

    case 'list_unshare': {
      const isWatchList = attributes.list_type === 'watchlist';
      const watchListNotificationTitle = `A List '${attributes.list_title}' Has Been Unshared With You by ${attributes.sender_name}`;

      if (isNotificationType) {
        return isWatchList ? (
          <b>{watchListNotificationTitle}</b>
        ) : (
          <b>{`A Search '${attributes.list_title}' Has Been Unshared With You`}</b>
        );
      }

      return isWatchList ? (
        <span>{attributes.note}</span>
      ) : (
        <span>
          {`A search '${attributes.list_title}' has been unshared with you by `}
          <b>{attributes.sender_name}</b>
        </span>
      );
    }

    case 'list_delete': {
      const isWatchList = attributes.list_type === 'watchlist';

      if (isNotificationType) {
        return isWatchList ? (
          <b>{`A List '${attributes.list_title}' Has Been Deleted`}</b>
        ) : (
          <b>{`A Search '${attributes.list_title}' Has Been Deleted`}</b>
        );
      }

      return isWatchList ? (
        <span>
          {`A list '${attributes.list_title}' has been deleted by `}
          <b>{attributes.sender_name}</b>
        </span>
      ) : (
        <span>
          {`A search '${attributes.list_title}' has been deleted by `}
          <b>{attributes.sender_name}</b>
        </span>
      );
    }

    case 'software': {
      if (isNotificationType) {
        return <b>Software Company Status Change</b>;
      }

      return (
        <span>
          The software status has been changed by <b>{changedBy}</b> for a company that you own
        </span>
      );
    }

    case 'addon': {
      if (isNotificationType) {
        return <b>A Potential Add-on Company Has Been Identified</b>;
      }

      return (
        <span>
          A potential Add-on company has been identified for a company that you own by{' '}
          <b>{changedBy}</b>
        </span>
      );
    }

    case 'portfolio': {
      if (isNotificationType) {
        return <b>Portfolio Company Status Change</b>;
      }

      return (
        <span>
          The PSG portfolio status has been changed by <b>{changedBy}</b> for a company that you own
        </span>
      );
    }

    case 'user_provided_company_info': {
      let message: JSX.Element | undefined;

      attributes.values_changed?.forEach(item => {
        if (isNotificationType) {
          if (item.field === 'capital_raised') {
            if (item.user_value > 40000000) {
              message = <b>The total amount raised exceeded $40M</b>;
            } else {
              message = <b>The total amount raised has changed</b>;
            }
          } else if (item.field === 'emp_growth_percent' && item.user_value >= 20) {
            message = <b>A change of 20% Y/Y Employee Growth was detected</b>;
          } else {
            message = <b>Proprietary Information Change</b>;
          }
        } else {
          if (item.field === 'capital_raised') {
            if (item.user_value > 40000000) {
              message = (
                <span>
                  {`The total amount raised was changed to ${numeral(item.user_value).format(
                    NUMBER_FORMAT
                  )}`}
                </span>
              );
            } else if (changedBy) {
              message = (
                <span>
                  {`The total amount raised was changed to ${numeral(item.user_value).format(
                    NUMBER_FORMAT
                  )} by `}
                  <b>{changedBy}</b>
                </span>
              );
            } else {
              message = <span>{`The total amount raised was changed to ${item.user_value}`}</span>;
            }
          }

          if (item.field === 'last_raised_amount' || item.field === 'last_round') {
            if (item.field === 'last_raised_amount' && item.user_value >= 40000000) {
              message = (
                <span>The recent funding amount raised for this company has exceeded $40M</span>
              );
            } else {
              message = <span>Company has acquired a new round of funding</span>;
            }
          }

          if (item.field === 'emp_growth_percent' && item.user_value >= 20) {
            message = <span>{`Employee Growth has changed to ${item.user_value}%`}</span>;
          }

          if (item.field === 'num_employees') {
            if (item.user_value < 30) {
              message = <span>The number of FTE’s has dropped below 30</span>;
            } else if (item.user_value >= 30) {
              message = <span>The number of FTE’s has exceeded 30</span>;
            }
          }
        }
      });

      if (message) {
        return message;
      }

      if (changedBy) {
        return (
          <span>
            Proprietary information was changed for a company that you own by <b>{changedBy}</b>
          </span>
        );
      }

      return <span>Proprietary information was changed for a company that you own</span>;
    }

    case 'active': {
      if (isNotificationType) {
        if (attributes.extra_context?.active) {
          return <b>Has become Active on Pipeline</b>;
        }

        return <b>Is no longer Active on Pipeline</b>;
      }

      return (
        <span>
          <b>Active on Pipeline</b> status has been changed by <b>{changedBy}</b> for a company that
          you own
        </span>
      );
    }

    case 'in_business': {
      if (isNotificationType) {
        return <b>Has gone out of Business</b>;
      }

      return (
        <span>
          <b>In Business</b> status has been changed by <b>{changedBy}</b> for a company that you
          own
        </span>
      );
    }

    case 'website_rank': {
      if (isNotificationType) {
        return (
          <b>
            {`The ${
              attributes?.rank_type === 'us' ? 'US' : 'WW'
            } rank of the company has moved into the top 200k`}
          </b>
        );
      }

      return (
        <span>
          {`The ${attributes?.rank_type === 'us' ? 'US' : 'WW'} website rank is ${
            attributes.extra_context?.rank_value
          }`}
        </span>
      );
    }

    case 'parent_company': {
      if (isNotificationType) {
        return <b>Proprietary Information Change</b>;
      }

      if (changedBy) {
        return (
          <span>
            Proprietary information was changed for a company that you own by <b>{changedBy}</b>
          </span>
        );
      }

      return <span>Proprietary information was changed for a company that you own</span>;
    }

    case 'funded': {
      if (isNotificationType) {
        return <b>Proprietary Information Change</b>;
      }

      return <span>Proprietary information was changed for a company that you own</span>;
    }

    case 'note_share': {
      if (isNotificationType) {
        return <b>A New Note Has Been Added</b>;
      }

      return (
        <span>
          A note has been added for a company that you own by <b>{changedBy}</b>
        </span>
      );
    }

    default:
      return '';
  }
};

const getUrl = (item: NotificationDetails, advancedSearchPathname: string) => {
  const hasId = item.attributes.company_id || item.attributes.extra_context?.list_id;

  if (!hasId) {
    return '#';
  }

  const isListAndHasExtraContent =
    (item.type === 'list_share' || item.type === 'list_delete' || item.type === 'list_unshare') &&
    item.attributes.extra_context;

  if (isListAndHasExtraContent) {
    if (item.attributes.list_type === 'watchlist') {
      return getListURL(`${item.attributes.extra_context.list_id!}`);
    }

    return `${advancedSearchPathname}?saved_search_id=${item.attributes.extra_context.list_id!}`;
  }

  if (isListAndHasExtraContent || item.type === 'share_permission') {
    return '#';
  }

  if (item.attributes.company_id) {
    return getCompanyProfileURL(item.attributes.company_id);
  }

  return '#';
};

const getFiltersNr = (id: number, views: ViewOption[]) => {
  const view = views.filter(view => view.unique_id === id);
  const filters = mapTagsToNames(view[0]?.filteredTags!);

  return filters?.length;
};

const NotificationItem: React.FC<NotificationItemProps> = ({
  name,
  data,
  onDismissNotification,
  views,
}) => {
  const defaultOptxScore = useSelector(userSelectors.getDefaultScore);
  const filterSources = useSelector(filterSourcesSelectors.getCompanyFilters);
  const clearedFilters = useSelector(filterSelectors.getClearedFilter('advancedSearch'));

  const { advancedSearchPathname } = useIsAnalyst();

  if (!data || !data.length) {
    return null;
  }

  return (
    <div className={`notification-item ${name}`}>
      <div className="notification-item__block">
        {data.map((item, index) => {
          let deletedListFiltersCount = 0;

          if (
            item.type === 'list_share' &&
            item.attributes.extra_context?.deleted &&
            item.attributes.extra_context?.search_criteria
          ) {
            deletedListFiltersCount = mapTagsToNames(
              mapDeletedListSearchQuery(
                item.attributes.extra_context?.search_criteria,
                filterSources,
                clearedFilters
              )
            ).length;
          }

          const isList =
            item.type === 'list_share' ||
            item.type === 'list_unshare' ||
            item.type === 'list_delete';

          const isListOrHasPermission =
            item.type === 'share_permission' ||
            item.type === 'list_unshare' ||
            item.type === 'list_delete';

          const isNotListAndHasNotPermission =
            item.type !== 'list_share' &&
            item.type !== 'list_unshare' &&
            item.type !== 'list_delete' &&
            item.type !== 'share_permission';

          const isScoreVisible =
            item.type !== 'active' &&
            item.type !== 'share_permission' &&
            item.type !== 'website_rank' &&
            item.type !== 'note_share';

          const hasId = item.attributes.company_id || item.attributes.extra_context?.list_id;
          const title =
            item.type === 'list_share' || item.type === 'share_permission'
              ? item.attributes.list_title
              : `${item.company && item.company.company_name}`;

          return (
            <div key={item.id} className="media-object notification-item__main">
              <div className="media-object-section notification-item__left">
                <div className="notification-item__avatar">
                  <Link
                    to={getUrl(item, advancedSearchPathname)}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e => {
                      if (
                        (item.type === 'list_share' && !item.attributes.extra_context) ||
                        item.type === 'share_permission' ||
                        !hasId
                      )
                        e.preventDefault();
                    }}
                    style={{
                      cursor:
                        (item.type === 'list_share' && !item.attributes.extra_context) ||
                        isListOrHasPermission ||
                        !hasId
                          ? 'default'
                          : 'pointer',
                      pointerEvents:
                        item.type === 'list_unshare' ||
                        item.type === 'list_delete' ||
                        !hasId ||
                        (item.type === 'list_share' && item.attributes.extra_context?.deleted)
                          ? 'none'
                          : 'auto',
                    }}
                  >
                    {isNotListAndHasNotPermission && item.company && item.company.logo_url && (
                      <img src={item.company.logo_url} alt="notification-logo" />
                    )}
                    {isNotListAndHasNotPermission && (!item.company || !item.company.logo_url) && (
                      <IconCompany />
                    )}
                    {isList && item.attributes.list_type === 'watchlist' && <FileTextOutlined />}
                    {item.type === 'list_share' && item.attributes.list_type !== 'watchlist' && (
                      <FilterOutlined />
                    )}
                    {item.type === 'share_permission' && <FileTextOutlined />}
                  </Link>
                </div>
                {name !== 'history' && item.viewed_at === null && <Tag color="green">NEW</Tag>}
              </div>
              <div className="media-object-section" style={{ flex: 1 }}>
                <div className="notification-item__history-info">
                  {item.type === 'list_unshare' && item.attributes.list_type === 'watchlist' && (
                    <Tag color="red">UNSHARED</Tag>
                  )}
                  {((item.type === 'list_delete' && item.attributes.list_type === 'watchlist') ||
                    (item.type === 'list_share' && item.attributes.extra_context?.deleted)) && (
                    <Tag color="red">DELETED</Tag>
                  )}
                  <div className="notification-item__company-name">
                    <Tooltip title={title && title.length > 42 && title}>
                      <Link
                        to={getUrl(item, advancedSearchPathname)}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={e => {
                          if (
                            (item.type === 'list_share' && !item.attributes.extra_context) ||
                            item.type === 'share_permission'
                          )
                            e.preventDefault();
                        }}
                        style={{
                          zIndex: 8,
                          cursor:
                            (item.type === 'list_share' && !item.attributes.extra_context) ||
                            isListOrHasPermission ||
                            !hasId
                              ? 'default'
                              : 'pointer',
                          pointerEvents:
                            item.type === 'list_unshare' ||
                            item.type === 'list_delete' ||
                            !hasId ||
                            (item.type === 'list_share' && item.attributes.extra_context?.deleted)
                              ? 'none'
                              : 'auto',
                        }}
                      >
                        <Typography.Text ellipsis>
                          {item.type === 'list_share' || isListOrHasPermission
                            ? item.attributes.list_title
                            : `${item.company && item.company.company_name}`}
                        </Typography.Text>
                      </Link>
                    </Tooltip>

                    <div className="notification-item__message">
                      <span>
                        {notificationText(
                          item.type,
                          item.attributes,
                          item.modified_by_display_name,
                          true
                        )}
                      </span>
                      <span>
                        DATE:{' '}
                        {item.created_at ? moment(item.created_at).format('MMMM DD, YYYY') : ''}
                      </span>
                      <div className="notification-item__message-history-bottom">
                        {notificationText(
                          item.type,
                          item.attributes,
                          item.modified_by_display_name,
                          false
                        )}
                        {name === 'inbox' && (
                          <Button
                            type="primary"
                            style={{ zIndex: 2 }}
                            size="small"
                            ghost
                            onClick={() => onDismissNotification && onDismissNotification(item.id)}
                          >
                            Dismiss
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="notification-item__history-top">
                    {isList && item.attributes.extra_context ? (
                      <div className="notification-item__list-detail">
                        <span>
                          {item.attributes.extra_context.company_count ||
                            (views.length > 1 &&
                              getFiltersNr(item.attributes.extra_context.list_id!, views)) ||
                            deletedListFiltersCount}
                        </span>
                        {item.attributes.list_type === 'watchlist'
                          ? ' # of Companies'
                          : ' # of Filters'}
                      </div>
                    ) : (
                      item.attributes.extra_context &&
                      isScoreVisible && (
                        <ScoreTrending
                          score={
                            defaultOptxScore !== 'il'
                              ? item.attributes.extra_context.optx_score!
                              : item.attributes.extra_context.il_optx_score!
                          }
                          growth={
                            defaultOptxScore !== 'il'
                              ? item.attributes.extra_context.optx_score_growth!
                              : item.attributes.extra_context.il_optx_score_growth!
                          }
                          label={defaultOptxScore !== 'il' ? 'OPTX SCORE' : 'IL OPTX SCORE'}
                          customFontSize="16px"
                          isPSGCompany={item.company?.is_psg_company}
                          isFromNotifications
                        />
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

NotificationItem.defaultProps = {
  name: '',
  data: [],
};

export default NotificationItem;
