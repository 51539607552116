import { SearchPayload } from '@optx/models/api/contacts';
import { createAction } from '@reduxjs/toolkit';
import { SortByRule, SortRule } from '@models/table/sorting';
// types
import * as types from './types';

export const changeSortAction = createAction<SortRule<any>>(types.SEARCH_COMPANIES_OUT_REACH_SORT);
export const changeSortActionSuccess = createAction<SearchPayload>(
  types.SEARCH_COMPANIES_OUT_REACH_SORT_SUCCESS
);
export const multiSortAction = createAction(
  types.SEARCH_COMPANIES_OUT_REACH_MULTI_SORT,
  (multiSort: boolean, sortBy?: Array<SortByRule<any>>) => ({
    payload: multiSort,
    meta: sortBy,
  })
);

export const resetMultiSortAction = createAction(types.RESET_COMPANIES_OUT_REACH_MULTISORT);

export const resetSortAction = createAction(types.RESET_SORT);

export const resetSortSuccessAction = createAction<Array<SortByRule<any>> | undefined>(
  types.RESET_SORT_SUCCESS
);
