import { fork, put, takeLatest, take, select } from 'redux-saga/effects';
import searchSagas from './search';
import filters from './filters';
import highlights from './highlights';
import { actions } from '../actions';
import { selectors } from '../selectors';

function* initSaga() {
  // @ts-ignore
  const filtersLoaded: boolean = yield select(selectors.filters.loaded);

  if (!filtersLoaded) {
    yield put(actions.filters.fetch());

    yield take(actions.filters.fetchSuccess);
    yield put(actions.search.fetchETCompanies());
    yield put(actions.highlights.etMergeHistoryFetch());
    yield put(actions.highlights.etAlltimeHighlightsFetch());
    yield put(actions.highlights.timeSpentFetch());
  }
}

export function* saga() {
  yield fork(searchSagas);
  yield fork(filters);
  yield fork(highlights);
  yield takeLatest(actions.init, initSaga);
}
