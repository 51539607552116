import styled, { css } from 'styled-components';

const LegendGroup = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 5px;
  top: -5px;
  left: -35px;

  .total {
    position: absolute;
    color: #8083a3;
    text-align: center;
    display: grid;
    line-height: 23px;
    left: -130%;
    top: 45%;

    b {
      color: #171721;
      font-weight: 700;
      text-align: center;
      font-size: 22px;
    }
  }
`;

const LegendItem = styled.li<{ isActive: boolean; isDisable: boolean }>`
  margin: 0;
  padding: 0;
  display: flex;
  gap: 5px;
  padding: 7px 15px;
  border-radius: 15px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 1px solid #e4e6e8;
  font-weight: 500;
  background: ${({ isDisable }) => (isDisable ? '#f5f5f5' : '#fff')};
  color: ##171721;
  width: 150px;
  font-size: 14px;
  transition: 0.4s;

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: #e6f7ff;
      border-color: #40a9ff;
    `};

  div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
`;

const PieChartWrapper = styled.div`
  width: 95%;
  margin: 0px auto;
`;

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 225px;
  flex-wrap: wrap;
  gap: 10px;
`;

export default {
  LegendGroup,
  LegendWrapper,
  PieChartWrapper,
  LegendItem,
};
