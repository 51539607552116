import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// models
import { CompaniesInEquityTouchResponse } from '@features/equity-touch-integration/models/api';
import { SelectOption } from '@optx/models/Option';
// interfaces
import {
  EditFieldSuccess,
  UpdateFieldsPayload,
} from '@optx/features/company/edit-fields/state/interfaces';
// redux
import { actions as companyEditFields } from '@features/company/edit-fields/state';
import { ETCompaniesState } from '../interfaces';
import { actions } from '../actions';

const initialState: ETCompaniesState = {
  allIds: [],
  byCompanyId: {},
  fetchedAt: null,
};

const fetchETCompaniesSuccessReducer: CaseReducer<
  ETCompaniesState,
  PayloadAction<CompaniesInEquityTouchResponse>
> = (draftState, action) => {
  draftState.allIds = [];
  draftState.byCompanyId = {};

  action.payload.results.forEach(item => {
    draftState.allIds.push(item.merge_id);
    draftState.byCompanyId[item.merge_id] = item;
  });

  draftState.fetchedAt = new Date().toISOString();
};

const updateFieldsSuccessReducer: CaseReducer<ETCompaniesState, PayloadAction<EditFieldSuccess>> = (
  draftState,
  action
) => {
  const { companyId, fieldName, value } = action.payload;

  if (draftState.byCompanyId[companyId] && typeof fieldName === 'string') {
    const optionSelected = (value as UpdateFieldsPayload).value as SelectOption;

    if (fieldName === 'Website Status') {
      draftState.byCompanyId[companyId].url_status = optionSelected ? 'active' : 'not active';
    }
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.search.fetchETCompaniesSuccess, fetchETCompaniesSuccessReducer)
    // external
    .addCase(companyEditFields.updateFieldsSuccess, updateFieldsSuccessReducer)
);

export default reducer;
