import React, { useMemo } from 'react';
import AsyncSelect from 'react-select/async';
import { SelectComponents } from 'react-select/src/components';
// interface
import { SelectOption } from '@optx/models/Option';
// components
import { ValueContainer, DropdownIndicator } from '../components';
// Local
import { AsyncSelectProps } from '../interfaces';
import { Styled } from '../MultiSelect/MultiSelect.styled';
import { isOptionDisabled } from '../utils';

const defaultComponents: Partial<SelectComponents<SelectOption>> = {
  ValueContainer,
  DropdownIndicator,
};

const AsyncSingleSelect: React.FC<AsyncSelectProps> = ({
  className,
  components,
  value,
  ...restProps
}) => {
  const selectComponents = useMemo(() => {
    if (components) {
      return {
        ...defaultComponents,
        ...components,
      };
    }

    return defaultComponents;
  }, [components]);

  return (
    <Styled.MultiSelectWrapper className={className}>
      <AsyncSelect
        components={selectComponents}
        value={value}
        {...restProps}
        // Leave properties at the end to not be overriden.
        backspaceRemovesValue={false}
        isMulti={false}
      />
    </Styled.MultiSelectWrapper>
  );
};

AsyncSingleSelect.defaultProps = {
  placeholder: 'search',
  classNamePrefix: 'multiselect',
  isClearable: false,
  hideSelectedOptions: false,
  closeMenuOnSelect: false,
  controlShouldRenderValue: false,
  isOptionDisabled,
};

export default AsyncSingleSelect;
