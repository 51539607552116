import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import * as models from '@optx/models/Notes';
import * as actions from './actions';
import * as selectors from './selectors';
// services
import { CompanyService } from '../../../../services/api';
import NotificationService from '../../../../services/NotificationService';
import { ProfileNotes } from './interfaces';

export function* fetchProfileNotesSaga(action: PayloadAction<models.FetchNote>) {
  try {
    const res: AxiosResponse<ProfileNotes> = yield call(
      CompanyService.getProfileNotes,
      action.payload
    );

    if (res.data) {
      yield put(
        actions.fetchProfileNotesSuccess({ data: res.data, sorting: action.payload.sorting })
      );
    } else {
      const errorMessage = 'Failed to get notes';
      yield put(actions.fetchProfileNotesFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = 'Failed to get notes';
    yield put(actions.fetchProfileNotesFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* editNoteSaga(action: PayloadAction<models.EditNote>) {
  try {
    // @ts-ignore
    const res = yield call(CompanyService.editNote, action.payload);

    if (res.data) {
      yield put(actions.editNoteSuccess(action.payload));
    }
  } catch (e: any) {
    const errorMessage = 'Failed to edit note';
    NotificationService.error(errorMessage);
  }
}

export function* pinNoteSaga(action: PayloadAction<models.PinNote>) {
  try {
    // @ts-ignore
    const res = yield call(CompanyService.pinNote, action.payload);

    if (res.data) {
      const { companyId } = action.payload;
      const sorting: models.noteSorting = yield select(selectors.getSorting);
      yield put(actions.fetchProfileNotes({ companyId, sorting }));
    } else {
      const errorMessage = 'Failed to pin note';
      yield put(actions.fetchProfileNotesFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = 'Failed to pin note';
    yield put(actions.fetchProfileNotesFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* deleteProfileNoteSaga(
  action: PayloadAction<{ companyId: number; pinId: number }>
) {
  try {
    const { companyId, pinId } = action.payload;
    const res: AxiosResponse<number> = yield call(CompanyService.deleteProfileNote, pinId);

    if (res.data) {
      const sorting: models.noteSorting = yield select(selectors.getSorting);
      yield put(actions.fetchProfileNotes({ companyId, sorting }));
    } else {
      const errorMessage = 'Failed to delete note';
      yield put(actions.fetchProfileNotesFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = 'Failed to delete note';
    yield put(actions.fetchProfileNotesFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* profileNotesSaga() {
  yield takeLatest(actions.fetchProfileNotes, fetchProfileNotesSaga);
  yield takeLatest(actions.editNote, editNoteSaga);
  yield takeLatest(actions.pinNote, pinNoteSaga);
  yield takeLatest(actions.deleteProfileNote, deleteProfileNoteSaga);
}
