import React from 'react';
import { Menu } from 'antd';
import { SubMenuProps } from 'antd/lib/menu/SubMenu';
import PrivateComponent, { PrivateComponentProps } from '../../PrivateComponent';

interface PrivateSubmenuProps extends SubMenuProps, PrivateComponentProps {}

const PrivateSubmenu: React.FC<PrivateSubmenuProps> = ({ accessFor, ...restProps }) => {
  return (
    <PrivateComponent accessFor={accessFor}>
      <Menu.SubMenu {...restProps} />
    </PrivateComponent>
  );
};

export default PrivateSubmenu;
