import { createSelector } from 'reselect';
import { Dictionary, isEmpty } from 'lodash';
// models
import { SpecialFilterTag } from '@optx/models/tags';
import { SelectOption } from '@optx/models/Option';
import Company from '@optx/models/Company';
import { BaseFilter } from '@models/filters';
// utils
import mapTags from '@optx/utils/filters/mapTags';
import { generateUniqueKey } from '@optx/utils/uuid';
import mapFiltersToURLParams from '@optx/utils/filters/mapFiltersToURLParams';
// redux
import { AppState } from '@optx/redux/interfaces';
import { localSelectors as localHistogramSelectors } from '@features/histograms/base-histograms';
import { selectors as filtersSourcesSelectors } from '../filters';
import { getSearchKey as searchKey } from '@optx/features/grid/searchkey/state/selectors';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import { getTotalCount } from '@features/grid/search/state/selectorsReusable';

const getCompanyAddonManagementState = (state: AppState) => state.companyAddonManagement;

export const shouldInitialFetch = createSelector(
  getCompanyAddonManagementState,
  state => !state.search.loading
);

export const getData = createSelector(getCompanyAddonManagementState, state => {
  const data: Company[] = [];

  state.search.allIds.forEach(id => {
    const company = state.search.data?.find(company => company.company_id === id);

    if (company) data.push(company);
  });

  return data;
});

export const currentCompanyId = createSelector(
  getCompanyAddonManagementState,
  state => state.search.currentCompanyId
);

export const getError = createSelector(getCompanyAddonManagementState, state => state.search.error);
export const isLoading = createSelector(
  getCompanyAddonManagementState,
  state => state.search.loading
);
export const sortBy = createSelector(getCompanyAddonManagementState, state => state.search.sortBy);

export const getCompanies = createSelector(
  getCompanyAddonManagementState,
  state => state.search.allIds.map(id => state.search.byId[id]) as Company[]
);

export const getCurrentCompanyAddon = (companyId: number) =>
  createSelector(getCompanyAddonManagementState, state => state.search.byId[companyId]?.addon);

// Histogram filters.
const selectHistogramsState = (state: AppState) => state.companyAddonManagement.histogramFilters;

export const selectEditHistograms = createSelector(
  selectHistogramsState,
  localHistogramSelectors.selectEditHistograms
);

export const selectHistograms = createSelector(
  selectHistogramsState,
  localHistogramSelectors.histograms
);

export const selectAllFiltersWithHistograms = createSelector(
  selectHistogramsState,
  localHistogramSelectors.allFiltersWithHistograms
);

export const getHistogramSearchCount = createSelector(
  selectHistogramsState,
  localHistogramSelectors.searchCount
);

export const selectChangedFilters = createSelector(
  selectHistogramsState,
  localHistogramSelectors.changedFilters
);

export const loadingHistograms = createSelector(selectHistogramsState, state => state.loading);

export const histogramsInitialized = createSelector(
  selectHistogramsState,
  localHistogramSelectors.isInitialized
);

export const selectHistogramRequestFilters = createSelector(
  selectHistogramsState,
  localHistogramSelectors.histogramRequestFilters
);

export const selectDefaultHistograms = createSelector(
  selectHistogramsState,
  localHistogramSelectors.defaultHistograms
);

export const histogramsEnabled = createSelector(
  selectHistogramsState,
  localHistogramSelectors.isEnabled
);

export const histogramsAreEmpty = createSelector(
  selectHistogramsState,
  localHistogramSelectors.histogramsAreEmpty
);

export const isInitialSearch = createSelector(
  getCompanyAddonManagementState,
  state => !state.search.fetchedAt
);

export const getSorting = createSelector(
  getCompanyAddonManagementState,
  state => state.search.sortBy
);

export const getSearchKey = searchKey('addons');

/**
 * Show if current search has seach term or a filter set.
 */
export const searchHasValues = createSelector(
  getSearchKey,
  filterSelectors.getFilter('addons'),
  (searchKey, filter) => !!searchKey || !isEmpty(filter)
);

export const getAdditionalFilter = createSelector(
  getCompanyAddonManagementState,
  state => state.search.additionalFilter
);

export const getAdditionalValues = createSelector(getCompanyAddonManagementState, state => {
  const { additionalFiltersValues: values } = state.search;

  return values;
});

export const getAdditionalFilters = createSelector(getCompanyAddonManagementState, state => {
  const { normalizedAdditionalFilters: normalized, additionalFilter: filter } = state.search;

  return { normalized, filter };
});

export const getAdditionalFiltersQuery = createSelector(
  getAdditionalFilters,
  ({ normalized, filter }) => {
    let additionalFilterQuery = {
      ...mapFiltersToURLParams(normalized as Dictionary<BaseFilter<any>>, filter),
    };

    if (additionalFilterQuery.origin && additionalFilterQuery.origin.length === 0) {
      additionalFilterQuery = {
        ...additionalFilterQuery,
        origin: [''],
      };
    }

    return additionalFilterQuery;
  }
);

export const getAdditionalFiltersData = createSelector(getCompanyAddonManagementState, state => {
  const { additionalFiltersData: data } = state.search;

  return data;
});

const getAdditionalFiltersDataLists = createSelector(getCompanyAddonManagementState, state => {
  const { additionalFiltersData: data } = state.search;

  const lists: SelectOption[] = data[0].data[0].data.reduce(
    (result: SelectOption[], item: SelectOption) => {
      if (item.value !== 'blank') {
        result.push(item);
      }

      return result;
    },
    []
  );

  return lists || [];
});

export const getAdditionalFiltersDataListsNames = (listIds: string[]) =>
  createSelector(getAdditionalFiltersDataLists, lists => {
    const selectedNames = (lists as unknown as { name: string; value: string | number }[]).reduce(
      (acc: string[], item) => {
        if (listIds.includes(item.value.toString())) {
          acc.push(item.name);
        }

        return acc;
      },
      []
    );

    return selectedNames;
  });

export const getOriginAdditionalFilterRelatedListsIds = (companyId: number | number[]) =>
  createSelector(getAdditionalFiltersDataLists, lists => {
    const relatedListsIds = lists.reduce((acc: number[], item) => {
      if (item.data && typeof companyId === 'number' && item.data.includes(companyId)) {
        acc.push(Number(item.value));
      }

      if (
        item.data &&
        typeof companyId !== 'number' &&
        item.data.some(id => companyId.includes(id))
      ) {
        acc.push(Number(item.value));
      }

      return acc;
    }, []);

    return relatedListsIds;
  });

export const getListOfCompaniesIdFromAdditionalFilters = (companyIds: number[]) =>
  createSelector(getAdditionalFiltersDataLists, lists => {
    const companiesIds = lists.reduce((acc: { listId: number; companyIds: number[] }[], item) => {
      if (item.data) {
        const commonIds = item.data.filter(id => companyIds.includes(id));
        acc.push({
          listId: Number(item.value),
          companyIds: commonIds,
        });
      }

      return acc;
    }, []);

    return companiesIds;
  });

export const getAdditionalFiltersClear = createSelector(getCompanyAddonManagementState, state => {
  const { clearAdditionalFilters: clear } = state.search;

  return clear;
});

export const getTags = createSelector(
  filterSelectors.getFilter('addons'),
  filtersSourcesSelectors.getCompanyFilters,
  filterSelectors.getClearedFilter('addons'),
  getSearchKey,
  getAdditionalValues,
  getAdditionalFiltersData,
  getAdditionalFiltersClear,

  (
    filter,
    filterSources,
    clearedFilter,
    searchKey,
    additionalValues,
    additionalFiltersData,
    clearAdditionalFilters
  ) => {
    const tags = mapTags(filter, filterSources, clearedFilter);
    const additionalFiltersTags = mapTags(
      additionalValues,
      additionalFiltersData,
      clearAdditionalFilters
    );

    if (searchKey) {
      tags.unshift({
        filter: 'keyword' as SpecialFilterTag,
        uniqueKey: generateUniqueKey(),
        label: 'Keyword',
        isDefault: false,
        values: [
          {
            value: searchKey,
          },
        ],
      });
    }

    return [...tags, ...additionalFiltersTags];
  }
);

export const isClearedFilter = createSelector(getTags, tags => {
  const tagNotDefault = tags.find(tag => {
    if (tag.isDefault === false && tag.values?.length > 0) {
      return true;
    }

    return false;
  });

  return !!tagNotDefault;
});

export const getEditModeSearchCount = createSelector(
  getTotalCount('addons'),
  getHistogramSearchCount,
  (searchCount, histogramSearchCount) =>
    typeof histogramSearchCount === 'number' ? histogramSearchCount : searchCount
);
