import { Dictionary } from 'lodash';
import { FilterColumns } from '@models/feature/analystsLeaderboard/filters';

export const tagsOmmitedFilters: Array<FilterColumns> = ['filter_criteria'];

export const filtersByCriteria: Dictionary<Array<FilterColumns>> = {
  touches: ['touch_type_status', 'touch_type_id'],
  companies_reached: ['companies_reached_status', 'deal_stage_id'],
  stage_changes: ['stage_change_status', 'past_stage_id'],
  pipeline_information: ['pipeline_companies_rank', 'previous_period'],
};
