import React, { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Button, Table } from 'antd';
import { Dictionary } from 'lodash';
import styled from 'styled-components';
// models
import { EquityTouchSimilarities, EquityTouchCheck } from '@models/equityTouch';
import { getOriginPath } from '@optx/utils/routes';
import appRoutes from '@constants/routes';
// constants
import { defaultColumns } from '@components/common/table/TableEquityTouchSimilarities';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';

const HeaderText = styled.div`
  margin-bottom: 24px;
`;

const FooterText = styled.span`
  margin-right: 15px;
`;

/**
 * function to sort results by whether or not the company has an url match
 * the ones that do are displayed first
 * @param {EquityTouchSimilarities} a item to compare with
 * @param {EquityTouchSimilarities} b item to compare with
 */
const sortByUrlMatch = (a: EquityTouchSimilarities, b: EquityTouchSimilarities) => {
  if (a.is_url_match === b.is_url_match) {
    return 0;
  }

  if (a.is_url_match) {
    return -1;
  }

  return 1;
};

interface SimilaritiesModalProps {
  availability?: Dictionary<EquityTouchCheck>;
  id: string;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

const SimilaritiesModal: React.FC<SimilaritiesModalProps> = ({
  availability,
  id,
  showModal,
  setShowModal,
}) => {
  const history = useHistory();
  const { advancedSearchPathname } = useIsAnalyst();

  const handleClose = () => {
    setShowModal(false);
  };

  const handleNoClick = () => {
    if (getOriginPath(document.referrer) === appRoutes.chromeExtension) {
      history.push(advancedSearchPathname);
    } else {
      history.goBack();
    }
  };

  return (
    <Modal
      visible={showModal}
      title="Add to Equity Touch"
      className="add-to-equity-touch__modal"
      onOk={handleClose}
      onCancel={handleClose}
      width={720}
      footer={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <>
          <FooterText>Proceed anyway?</FooterText>
          <Button className="ml-2" onClick={handleNoClick}>
            No
          </Button>
          <Button type="primary" onClick={handleClose}>
            Yes
          </Button>
        </>
      }
    >
      <HeaderText>Similar companies were found in EquityTouch</HeaderText>
      <Table
        className="search-results legacy-table"
        columns={defaultColumns}
        rowKey={'companyId'}
        dataSource={availability ? availability[id].et_api.sort(sortByUrlMatch) : []}
        pagination={false}
        tableLayout="fixed"
        bordered
      />
    </Modal>
  );
};

export default SimilaritiesModal;
