import React from 'react';
import { Typography } from 'antd';
import numeral from 'numeral';
import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
// utils
import { getPageSize } from '@utils/table/pagination';
// redux
import { selectors as etIntegrationSelector } from '@optx/features/equity-touch-integration';
import * as CompaniesETSelectors from '@optx/features/equity-touch-integration/state';

const ShowingTitle = () => {
  const searchCompanyCount = useSelector(etIntegrationSelector.pagination.total);
  const pagination = useSelector(etIntegrationSelector.pagination.pagination);
  const isLoadingSearch = useSelector(etIntegrationSelector.search.isLoading);
  const isLoadingPage = useSelector(CompaniesETSelectors.pageLoading);
  const pageSize = getPageSize(pagination.pageSize, searchCompanyCount);
  const formattedCount = numeral(searchCompanyCount).format('0,0');
  const title = `Showing (${pageSize} of ${formattedCount} ${
    searchCompanyCount !== 1 ? 'Companies' : 'Company'
  })`;

  const showingText = isLoadingPage ? (
    <>Loading...</>
  ) : (
    <>
      Showing (
      {isLoadingSearch ? (
        <LoadingOutlined style={{ color: '#4ce1b6' }} />
      ) : (
        `${pageSize} of ${formattedCount} ${searchCompanyCount !== 1 ? 'Companies' : 'Company'}`
      )}
      )
    </>
  );

  return (
    <Typography.Title
      level={4}
      type="secondary"
      style={{ fontWeight: 'normal', margin: '2px 0 0 0' }}
      title={title}
      ellipsis
    >
      {showingText}
    </Typography.Title>
  );
};

export default ShowingTitle;
