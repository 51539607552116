/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useMemo } from 'react';
import { Row, Col, Typography } from 'antd';
import { Checkbox, InputNumber } from 'formik-antd';
import { useField } from 'formik';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import numeral from 'numeral';
// models
import { SingleCheckboxRangeOption } from '@optx/models/RangeOption';
import { MultiCheckboxFilter } from '@models/filters';
import { FormCheckboxOption } from '@models/Option';
// components
import { abbreviateNumber, parseNumberFromAbbreviation } from '@optx/utils/number';
import SearchFilterCard from './SearchFilterCard';
import { FiltersContext } from '../FiltersContext';

interface FilterCheckboxProps {
  filter: MultiCheckboxFilter;
  isFullRow?: boolean;
}

const FilterMultiRangeCustom: React.FC<FilterCheckboxProps> = ({ filter }) => {
  const baseKey = `${filter.column}_custom`;
  const [field, , fieldHelpers] = useField<SingleCheckboxRangeOption<string>>(baseKey);
  const [checkboxField, ,] = useField<Array<SingleCheckboxRangeOption>>(filter.column);
  const [isFieldChanged, setIsFieldChanged] = useState(false);
  const [isBlur, setIsBlur] = useState(true);
  const { onManualFilterChange } = useContext(FiltersContext);
  const isUSWebsiteRank = filter.column === 'sw_website_rank_per_country';

  const onFocus = () => {
    setIsBlur(false);
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsBlur(true);

    if (isFieldChanged) {
      const currentValue = parseInt(event.target.value);

      let isBlocked = false;
      checkboxField.value.forEach((blockedRange, index) => {
        if (blockedRange.disabled) {
          const nextIndex = index + 1;

          if (
            currentValue >= blockedRange.min &&
            currentValue <= blockedRange.max &&
            currentValue < checkboxField.value[nextIndex].min
          ) {
            isBlocked = true;
          }
        }
      });

      const newMinValue =
        isBlocked && currentValue === parseInt(field.value.min) ? '' : field.value.min;
      const newMaxValue =
        isBlocked && currentValue === parseInt(field.value.max) ? '' : field.value.max;

      if (field.value.min !== '' && field.value.max !== '' && field.value.min > field.value.max) {
        const value =
          newMinValue && newMaxValue
            ? `${numeral(newMaxValue).value()}_${numeral(newMinValue).value()}`
            : '';
        fieldHelpers.setValue({
          ...field.value,
          min: isUSWebsiteRank && newMaxValue !== '' && +newMaxValue <= 0 ? '1' : newMaxValue,
          max: isUSWebsiteRank && newMinValue !== '' && +newMinValue <= 0 ? '1' : newMinValue,
          value,
        });
      } else if (isUSWebsiteRank && field.value.min === '' && field.value.max !== '') {
        const value = newMaxValue ? `${numeral(1).value()}_${numeral(newMaxValue).value()}` : '';
        fieldHelpers.setValue({
          ...field.value,
          min: '1',
          max: newMaxValue !== '' && +newMaxValue <= 0 ? '1' : newMaxValue,
          value,
        });
      } else {
        const value =
          newMinValue && newMaxValue
            ? `${numeral(newMinValue).value()}_${numeral(newMaxValue).value()}`
            : '';
        fieldHelpers.setValue({
          ...field.value,
          min: isUSWebsiteRank && newMinValue !== '' && +newMinValue <= 0 ? '1' : newMinValue,
          max: isUSWebsiteRank && newMaxValue !== '' && +newMaxValue <= 0 ? '1' : newMaxValue,
          value,
        });
      }

      setIsFieldChanged(false);

      // if value has been changed, call the function
      if (!isBlocked) {
        setTimeout(() => {
          onManualFilterChange && onManualFilterChange(filter.column);
        }, 0);
      }
    }
  };

  const formatter = useMemo(
    () => (value: string | number | undefined, inputType: 'min' | 'max') =>
      value ? abbreviateNumber({ value, maxValue: parseInt(field.value[inputType]) }) : '',
    []
  );

  const parser = useMemo(
    () => (value: string | number | undefined, inputType: 'min' | 'max') =>
      value ? parseNumberFromAbbreviation(value as string, undefined, undefined, undefined) : '',
    []
  );

  function handleChangeShowCustomRanges(event: CheckboxChangeEvent) {
    if (event.target.checked) {
      fieldHelpers.setValue({
        ...field.value,
        checked: true,
      });
    } else {
      fieldHelpers.setValue({
        ...field.value,
        checked: false,
        value: '',
        min: '',
        max: '',
      });

      // If we have values for at least one field call change.
      if (typeof field.value.min === 'number' || typeof field.value.max === 'number') {
        // Call on change on the next tick.
        setTimeout(() => {
          onManualFilterChange && onManualFilterChange(filter.column);
        }, 0);
      }
    }
  }

  const onChangeMin = (value: string | number | undefined) => {
    setIsBlur(false);

    if (value !== field.value.min) {
      setIsFieldChanged(true);
    }
  };

  const onChangeMax = (value: string | number | undefined) => {
    setIsBlur(false);

    if (value !== field.value.max) {
      setIsFieldChanged(true);
    }
  };

  if (!filter.custom) {
    return null;
  }

  return (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Checkbox name={`${baseKey}.checked`} onChange={handleChangeShowCustomRanges}>
          Custom Range
        </Checkbox>
      </Col>
      {field.value.checked && (
        <>
          <Col span={11}>
            <InputNumber
              name={`${baseKey}.min`}
              placeholder={filter.placeholders && filter.placeholders.min}
              formatter={
                (isUSWebsiteRank && isBlur) || !isUSWebsiteRank
                  ? (value: string | number | undefined) => formatter(value, 'min')
                  : undefined
              }
              parser={value => parser(value, 'min') as string}
              onBlur={onBlur}
              onFocus={onFocus}
              onChange={onChangeMin}
            />
          </Col>
          <Col span={11} offset={2}>
            <InputNumber
              name={`${baseKey}.max`}
              placeholder={filter.placeholders && filter.placeholders.max}
              formatter={
                isBlur ? (value: string | number | undefined) => formatter(value, 'max') : undefined
              }
              parser={value => parser(value, 'max') as string}
              onBlur={onBlur}
              onFocus={onFocus}
              onChange={onChangeMax}
            />
          </Col>
        </>
      )}
    </Row>
  );
};

const FilterCheckbox: React.FC<FilterCheckboxProps> = ({ filter, isFullRow = false }) => {
  const [field, ,] = useField<Array<FormCheckboxOption>>(filter.column);
  const { onManualFilterChange } = useContext(FiltersContext);

  const onChange = () => {
    // Delay onChange for the next tick in order to allow form values update.
    setTimeout(() => {
      onManualFilterChange && onManualFilterChange(filter.column);
    }, 0);
  };

  return (
    <SearchFilterCard label={filter.label}>
      <Row>
        {field.value.map((option: any, index) => (
          <Col key={index} span={isFullRow ? 24 : 12}>
            <Checkbox
              className="no-wrap-checkbox"
              name={`${filter.column}[${index}].checked`}
              fast
              disabled={option.disabled}
              onChange={onChange}
            >
              <Typography.Text title={option.label} ellipsis>
                {option.label}
              </Typography.Text>
            </Checkbox>
          </Col>
        ))}
      </Row>
      {filter.custom && <FilterMultiRangeCustom filter={filter} />}
    </SearchFilterCard>
  );
};

export default React.memo(FilterCheckbox);
