import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from 'antd';
import {
  selectors as searchesSelectors,
  actions as savedSearchesActions,
} from '@redux/company/saved-searches';

interface MakeDefaultFilterProps {
  id: number | string;
}

const MakeDefaultFilter: React.FC<MakeDefaultFilterProps> = ({ id, ...restProps }) => {
  const dispatch = useDispatch();
  const defaultSearchId = useSelector(searchesSelectors.getDefaultSearchId);

  const handleClick = () => {
    if (defaultSearchId === id) {
      // send undefined to unset the current default filter
      dispatch(savedSearchesActions.makeFiltersDefault(id, undefined));
    } else {
      // send true to set a new default search
      dispatch(savedSearchesActions.makeFiltersDefault(id, true));
    }
  };

  return (
    <Menu.Item {...restProps} onClick={handleClick} key="4">
      {defaultSearchId === id ? 'Remove' : 'Make'} Default Filter
    </Menu.Item>
  );
};

export default MakeDefaultFilter;
