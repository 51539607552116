import React from 'react';
// models
import { TableCellSearches } from '@optx/models/table/Cell';
// utils
import { formatDate } from '@utils/format';
// hooks
import useCompanySavedSearches from '@hooks/saved-searches/useCompanySavedSearches';

const TableCellDate: React.FC<TableCellSearches> = ({ value, row }) => {
  const { handleSearchResults } = useCompanySavedSearches();

  const handleClick = () => {
    if (row.unique_id) {
      handleSearchResults(row.unique_id, row.title, row.search_info.type || '');
    }
  };

  return (
    <div onClick={handleClick} role="presentation">
      {formatDate(value)}
    </div>
  );
};

export default TableCellDate;
