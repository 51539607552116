import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { reducers as fetchReducers } from '@redux/feature/fetch';

import * as actions from './actions';
import { CompanyPipelineRankState, EditCompanyPipelineRank } from './interfaces';

export const initialState: CompanyPipelineRankState = {
  companyId: null,
  error: null,
  loading: false,
};

const addCompanyPipelineRank: CaseReducer<
  CompanyPipelineRankState,
  PayloadAction<EditCompanyPipelineRank>
> = (draftState, action) => {
  const { companyId } = action.payload;
  draftState.companyId = companyId;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.addCompanyPipelineRank, addCompanyPipelineRank)
    .addCase(actions.addCompanyPipelineRankFail, fetchReducers.fetchFailReducer)
);

export default reducer;
