import React from 'react';
import { Button } from 'antd';
import { EnterOutlined } from '@ant-design/icons';

interface ApplySearchButtonProps {
  onSearch: VoidFunction;
  validation: () => boolean;
}

const ApplySearchButton: React.FC<ApplySearchButtonProps> = ({ onSearch, validation }) => {
  const handleClick = () => {
    validation() && onSearch();
  };

  return (
    <Button
      type="primary"
      icon={<EnterOutlined />}
      size="small"
      onClick={handleClick}
      style={{ marginRight: '10px' }}
    >
      Apply Search [ENTER]
    </Button>
  );
};

export default ApplySearchButton;
