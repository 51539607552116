import { TouchesCreateForm } from '@models/api/touches';

const TOUCH_FORM_INITIAL_STATE: TouchesCreateForm = {
  companyId: '',
  touchId: null,
  userId: '',
  touchType: 'complete',
  type: '',
  typeId: '',
  initiator: '',
  initiatorId: '',
  is_optx_id: false,
  initiatorHasEtId: true,
  attendees: [],
  externalAttendees: [],
  touchDate: '',
  touchTime: [],
  subject: '',
  notes: '',
  textarea: '',
  scheduleFollow: false,
  scheduleInterval: '',
  scheduleSubject: '',
  scheduleTouchDate: '',
  scheduleType: '',
  scheduleTypeId: '',
  // financials fields added in order to speed up loading
  annual_recurring_revenue: [],
  revenue: [],
  gm: [],
  ebitda: [],
  revenue_model: [],
  revenue_by_country: '',
  cashflow: '',
  financial_notes: '',
  capital_raised: '',
  cap_table: '',
  last_round_date: '',
  last_round: undefined,
  last_round_amount: undefined,
  acquiring_company: '',
  banker: undefined,
  banker_name: '',
  deal_type: '',
  equity_check: null,
  next_steps: '',
  transaction_notes: '',
  customers_number: undefined,
  customer_type: [],
  sales_and_marketing: '',
  sales_cycle: [],
  acv: '',
  clt: '',
  nps: '',
  net_retention: undefined,
  gross_retention: undefined,
  customer_churn: '',
  customer_concentration: '',
  cac: '',
  ltv_cac: '',
  delivery_model: [],
  cloud_provider: [],
  architecture: [],
  technology_notes: '',
  primary_contact: undefined,
  product_category: [],
  sub_sector: undefined,
  num_employees: undefined,
  competitors: [],
  fte_location: '',
};

export const TOUCH_TIME_FORMAT = 'HH:mm';

export default TOUCH_FORM_INITIAL_STATE;
