import { createSelector } from 'reselect';
// models
import { AppState } from '@optx/redux/interfaces';
// utils
import { sortColumns } from '@utils/table/sorting';
// redux
import { selectors as userInformationSelectors } from '@redux/user/information';
// components
import columns from '@components/common/table/TableSourceScrubLists/sourceScrubColumns';

// specific selectors
const sourceScrubListsState = (state: AppState) => state.sourceScrubLists;

export const shouldFetch = createSelector(
  userInformationSelectors.loaded,
  sourceScrubListsState,
  (loadedUserInfo, state) => loadedUserInfo && !state.loading && !state.fetchedAt
);

export const shouldFetchTotal = createSelector(
  sourceScrubListsState,
  state => !state.loading && !state.fetchedTotalAt
);

export const isLoading = createSelector(sourceScrubListsState, state => state.loading);

export const getSourceScrubLists = createSelector(sourceScrubListsState, state => state.data);

export const getPageNumber = createSelector(sourceScrubListsState, state => state.pageNumber);

export const getSort = createSelector(sourceScrubListsState, state => state.sortBy);

export const getQuery = createSelector(sourceScrubListsState, state => state.query);

export const getTotal = createSelector(sourceScrubListsState, state => state.total);

export const getGridColumns = createSelector(
  getSort,
  userInformationSelectors.getDefaultScore,
  (sorting, defaultOptxScore) => sortColumns(columns, sorting, defaultOptxScore)
);
