import { Select } from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { SelectProps, SelectValue } from 'antd/lib/select';
import { useLocation } from 'react-router-dom';
// models
import { SelectOption } from '@models/Option';
import { ChartBarIntervalKeys } from '@optx/models/charts/data';
// utils
import { getLocationMainGridKey } from '@utils/grid';
// redux
import { actions as searchActions, selectors as searchSelectors } from '@features/grid/search';
// components
import {
  mapOptionsToLabeledValues,
  PrefixedSelectionItem,
} from '@components/common/select/Select/PrefixedSelectionItem';

const StyledLabel = styled(PrefixedSelectionItem)`
  .prefixed-selection-item__label {
    color: #1890ff;
  }
`;

const StyledOption = styled(Select.Option)`
  .prefixed-selection-item__label {
    color: rgba(0, 0, 0, 0.65);
  }
`;

const CardViewSelect = ({ className, ...restProps }: SelectProps<SelectValue>) => {
  return <Select dropdownClassName={className} {...restProps} />;
};

const StyledSelect = styled(CardViewSelect)`
  .prefixed-selection-item__label {
    color: rgba(0, 0, 0, 0.65);
  }
`;

const touchTypes: Array<SelectOption> = [
  { label: 'Last 3 months', value: '3M' },
  { label: 'Last 6 months', value: '6M' },
  { label: 'Last year', value: '1Y' },
  { label: 'Last 2 years', value: '2Y' },
  { label: 'ALL', value: 'ALL' },
];

const typesOptions = mapOptionsToLabeledValues('Trend Range: ', touchTypes, StyledLabel);

const TrendingControls = () => {
  const dispatch = useDispatch();
  const searchOutReachTrending = useSelector(searchSelectors.getTrending('outreach'));
  const searchTrending = useSelector(searchSelectors.getTrending('advancedSearch'));
  const myCompaniesTrending = useSelector(searchSelectors.getTrending('myCompanies'));
  const listTrending = useSelector(searchSelectors.getTrending('lists'));
  const [value, setValue] = useState<ChartBarIntervalKeys>('1Y');
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.startsWith('/user-lists') || pathname.startsWith('/ss-lists')) {
      if (listTrending !== value) {
        setValue(listTrending);
      }
    } else if (pathname.startsWith('/my-companies')) {
      if (myCompaniesTrending !== value) {
        setValue(myCompaniesTrending);
      }

      if (pathname.startsWith('/all-reports')) {
        if (searchOutReachTrending !== value) {
          setValue(searchOutReachTrending);
        }
      }
    } else if (searchTrending !== value) {
      setValue(searchTrending);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listTrending, myCompaniesTrending, pathname, searchTrending]);

  const changeSorting = (value: SelectValue) => {
    const gridKey = getLocationMainGridKey();
    if (gridKey)
      dispatch(
        searchActions.updateTrending({
          gridKey,
          data: value as ChartBarIntervalKeys,
        })
      );

    setValue(value as ChartBarIntervalKeys);
  };

  const handleSelectValue = useCallback(() => {
    if (pathname.startsWith('/user-lists') || pathname.startsWith('/ss-lists')) {
      return listTrending;
    } else if (pathname.startsWith('/my-companies')) {
      return myCompaniesTrending;
    } else if (pathname.startsWith('/all-reports')) {
      return searchOutReachTrending;
    } else {
      return searchTrending;
    }
  }, [listTrending, myCompaniesTrending, pathname, searchOutReachTrending, searchTrending]);

  return (
    <StyledSelect onChange={changeSorting} bordered={false} value={handleSelectValue()}>
      {typesOptions.map(option => (
        <StyledOption key={option.value} value={option.value}>
          {option.label}
        </StyledOption>
      ))}
    </StyledSelect>
  );
};

export default React.memo(TrendingControls);
