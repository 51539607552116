import queryString from 'query-string';
// models
import { SortByRule } from '@models/table/sorting';
import { UserInfo } from '@optx/models/UserManagement';
// constants
import { emailUser, emailAdmin } from '@constants/email';
import SORT_ORDER from '@constants/table/sortOrder';
// services
import ApiClient, { API_URL } from './axios-client';

class UserManagement {
  static client: ApiClient = new ApiClient(`${API_URL}/admin`);

  static getUserList(sorting: Array<SortByRule<any>>) {
    const payload = {
      sort_field: sorting[0].id,
      sort_order: sorting[0].desc ? SORT_ORDER.DESCENDING : SORT_ORDER.ASCENDING,
    };
    const query = queryString.stringify(payload, { arrayFormat: 'comma' });

    return UserManagement.client.get(`/users?${query}`);
  }

  static setInvite(result: { email: string; role: string }) {
    const payload = {
      email: result.email,
      role_id: result.role,
      message_user: emailUser,
      message_admin: emailAdmin,
    };
    const query = queryString.stringify(payload);

    return UserManagement.client.post('/invite', query);
  }

  static setResendInvitation(result: { userId: number }) {
    const payload = {
      user_id: result,
      message: emailUser,
    };
    const query = queryString.stringify(payload);

    return UserManagement.client.post('/resend_invitation', query);
  }

  static getUserEditInfo(userId: number) {
    const payload = {
      user_id: userId,
    };
    const query = queryString.stringify(payload);

    return UserManagement.client.get(`/user_edit_info?${query}`);
  }

  static postUserInfo(payload: UserInfo) {
    return UserManagement.client.put('/user_edit_info', payload);
  }
}

export default UserManagement;
