import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Button } from 'antd';
// models
import { EquityTouchBulkResults, SelectedCompaniesGrids } from '@models/bulkActions';
import { SalesloftContact } from '@optx/models/Salesloft';
// utils
import { getGridName } from '@optx/utils/equityTouch';
// redux
import { selectors as bulkSelectors } from '@features/bulk-actions';
import { actions as eqtBulkActions } from '@features/bulk-actions/equity-touch';
// components
import SelectContacts from '@optx/features/bulk-actions/equity-touch/components/SelectContacts';
import Actions from './Actions';

export const TableCellActionsEquityTouch = (value: string, record: EquityTouchBulkResults) => {
  const dispatch = useDispatch();
  const { company_id: companyId, salesloftContacts: contacts } = record;
  const companyData = useSelector(bulkSelectors.equityTouch.getCompanyEqtData(companyId));
  const selectedCompanies = useSelector(
    bulkSelectors.selectedCompanies.getSelectedCompanies(getGridName() as SelectedCompaniesGrids)
  );
  const company = selectedCompanies.find(company => {
    return company.company_id === companyId;
  });
  const sentContacts = companyData?.salesloft_contacts as SalesloftContact[];

  const handleSubmit = () => {
    dispatch(
      eqtBulkActions.resolveSalesloft({
        companyId,
        all: true,
      })
    );
  };

  if (contacts[0].sl_message === 'No contact has been selected for SalesLoft') {
    return (
      <Row justify="space-between" gutter={[5, 0]}>
        <Col style={{ width: 'calc(100% - 165px)' }}>
          <SelectContacts contacts={company!.contacts} companyId={companyId} />
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <Button type="primary" disabled={!sentContacts?.length} onClick={handleSubmit}>
            Add To SL Cadence
          </Button>
        </Col>
      </Row>
    );
  }

  const handleResolve = () => {
    dispatch(
      eqtBulkActions.resolveSalesloft({
        companyId,
      })
    );
  };

  return (
    <Actions
      companyId={record.company_id}
      companyName={record.company_name}
      url={record.company_url as string}
      contactsResponse={contacts}
      sentContacts={sentContacts}
      onResolve={handleResolve}
      isEtBulk
    />
  );
};

export default TableCellActionsEquityTouch;
