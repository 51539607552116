import { createSelector } from 'reselect';
import { sortColumns } from '@optx/utils/table/sorting';
import { SortState } from '../interfaces';
import columns from '../../components/SourcingOutreachTable/columns';

export const selectSortBy = (state: SortState) => state.sortBy;

export const getGridColumns = createSelector(selectSortBy, sorting =>
  sortColumns(columns, sorting, undefined)
);
