import styled from 'styled-components';

const DatepickerWrapper = styled.div`
  .ant-picker {
    width: 100%;
  }
`;
const DatepickerRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: top;
  margin-bottom: 10px;
`;

export default { DatepickerWrapper, DatepickerRow };
