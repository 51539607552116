import { SuccessErrorCallback } from '@optx/models/callback';
import { createAction } from '@reduxjs/toolkit';
import * as types from './actionTypes';

export const updateProfileImage = createAction(
  types.UPDATE_PROFILE_IMAGE,
  (imageBlob: Blob | null, callback: SuccessErrorCallback) => ({
    payload: imageBlob,
    meta: callback,
  })
);

export const updateProfileImageSuccess = createAction<Blob | null>(
  types.UPDATE_PROFILE_IMAGE_SUCCESS
);

export const updateProfileImageFail = createAction<string>(types.UPDATE_PROFILE_IMAGE_FAIL);
