import React from 'react';
import { Row, Col } from 'antd';

import { Gutter } from 'antd/lib/grid/row';
import { AuditTrail } from '@features/company/audit-trail';
import MergeHistory from './MergeHistory';

const gridGutter: Gutter | [Gutter, Gutter] = [
  { xs: 24, sm: 24, xl: 40 },
  { xs: 24, sm: 24, xl: 40 },
];

interface HistoryProps {
  companyId: number;
  activeProfileKey: string;
}

const History: React.FC<HistoryProps> = ({ companyId, activeProfileKey }) => {
  return (
    <Row gutter={gridGutter}>
      <Col span={24} xl={12}>
        <AuditTrail companyId={companyId} activeProfileKey={activeProfileKey} />
      </Col>
      <Col span={24} xl={12}>
        <MergeHistory companyId={companyId} />
      </Col>
    </Row>
  );
};

export default History;
