import { createAction } from '@reduxjs/toolkit';
import { CompanyTouchesResponse } from '@optx/models/company/CompanyTouch';
import * as types from './types';
import { SearchTouchesPayload } from './interfaces';

// fetch company touches actions
export const fetchTouches = createAction<SearchTouchesPayload>(types.FETCH_COMPANY_TOUCHES);
export const fetchTouchesSuccess = createAction(
  types.FETCH_COMPANY_TOUCHES_SUCCESS,
  (response: CompanyTouchesResponse, searchPayload: SearchTouchesPayload) => ({
    payload: response,
    meta: searchPayload,
  })
);
export const fetchTouchesFail = createAction<string>(types.FETCH_COMPANY_TOUCHES_FAIL);
