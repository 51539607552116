import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { Dictionary } from 'lodash';
// models
import {
  DocumentUploadResponse,
  EncodedFile,
  SuccessResponse,
  UploadFilesPayload,
} from './interfaces';
import { RouteAliases } from '@optx/models/routes';
import { UploadFile } from 'antd/lib/upload/interface';
import { TouchCompany } from '../company-touches/interfaces';
import { TouchesDocumentUploadPayload } from '@optx/redux/company/touches/search/interfaces';
import CompanyTouch from '@optx/models/company/CompanyTouch';
// services
import { CompanyService } from '@optx/services/api';
import NotificationService from '@optx/services/NotificationService';
// utils
import { getRouteAlias } from '@optx/utils/routes';
import { getErrorMessage } from '@optx/utils/api/errors';
// redux
import { selectors as profileSelectors } from '@redux/company/profile';
import { selectors as filesSelectors, actions as filesActions } from '@redux/company/files';
import { selectors as documentUploadTouchSelectors } from '@redux/ui/modals/add-document-upload-touch';
import { selectors as companyTouchesSelectors } from '@redux/ui/modals/company-touches';
import { selectors as userInformationSelectors } from '@redux/user/information';
import * as actions from './actions';

function* uploadFilesSaga(action: PayloadAction<UploadFilesPayload>): Generator<any, any, any> {
  const files: UploadFile[] = yield select(documentUploadTouchSelectors.fileList);
  const filterOptions: Dictionary<string[]> = yield select(filesSelectors.getOptions);
  const encodedFiles: EncodedFile[] = yield select(documentUploadTouchSelectors.encodedFileList);
  const userName: string = yield select(userInformationSelectors.getFullName);
  const userId: number | null = yield select(userInformationSelectors.getUserId);
  const { company_id: touchCompanyId }: TouchCompany = yield select(
    companyTouchesSelectors.getTouchCompany
  );
  const profileCompanyId: number = yield select(profileSelectors.getCompanyId);

  const failedFiles: DocumentUploadResponse[] = [];
  const successFiles: SuccessResponse[] = [];
  var touchId: number | null = action.payload.touchId || null;
  var isMultiple = files.length > 1;
  var callOptionsEndpoint: boolean = false;

  let companyId: number;

  if (touchCompanyId === -1) {
    companyId = profileCompanyId;
  } else {
    companyId = touchCompanyId;
  }

  const pageAlias: RouteAliases | null = getRouteAlias(companyId);

  if (userId && files.length !== 0) {
    try {
      const encodedFile = encodedFiles.find(encodedFile => encodedFile.name === files[0]?.name);

      let payload: TouchesDocumentUploadPayload = {
        company_id: companyId,
        author_user_id: userId,
        author: userName,
        touch_id: null,
        title: files[0]?.name,
        notes: action.payload.notes,
        files_data: true,
        is_multiple: isMultiple,
        data: [
          {
            filename_with_extension: files[0]?.name,
            encoded_content: encodedFile?.base64Encoding || '',
          },
        ],
      };

      const res: AxiosResponse<DocumentUploadResponse> = yield call(
        CompanyService.touchesDocumentUpload,
        payload
      );

      if (res.data?.success && res.data.payload) {
        touchId = res.data?.payload.touchId as number | null;

        const slicedFiles = files.slice(1) as undefined | UploadFile[];
        const newArray = slicedFiles
          ? slicedFiles.map(file => {
              return { ...file, touch_id: touchId };
            })
          : [];
        let progressiveCalls: AxiosResponse<DocumentUploadResponse>[] = [];

        if (newArray.length !== 0) {
          progressiveCalls = yield all(
            newArray.map((file, index) => {
              const encodedFile = encodedFiles.find(encodedFile => encodedFile.name === file.name);
              let payload: TouchesDocumentUploadPayload = {
                company_id: companyId,
                author_user_id: userId,
                author: userName,
                touch_id: file.touch_id as number,
                title: file.name,
                notes: action.payload.notes,
                files_data: true,
                is_multiple: true,
                data: [
                  {
                    filename_with_extension: file.name,
                    encoded_content: encodedFile?.base64Encoding || '',
                  },
                ],
              };

              return call(CompanyService.touchesDocumentUpload, payload);
            })
          );
        }

        const mappedProgressiveCalls = progressiveCalls.map(item => item.data);
        const combinedData = [res.data, ...mappedProgressiveCalls];
        combinedData.map(item => {
          if (item.success) {
            if (item.payload)
              successFiles.push({
                payload: {
                  ...(item.payload as CompanyTouch),
                  initiatorname: userName,
                  touchId: item.payload.touchId,
                },
                files: item.files,
              });
          } else {
            failedFiles.push(item);
          }
        });

        if (failedFiles.length !== 0) {
          const errorMessage = `Failed to create Document Upload Touch for ${failedFiles
            .map(item => item.error_message)
            .join('\n')}`;
          NotificationService.error(errorMessage);
          yield put(actions.uploadFilesError(errorMessage));
        }

        if (successFiles.length !== 0) {
          if (pageAlias === 'companyProfile') {
            if (filterOptions) {
              const combinedOptions = Object.values(filterOptions).flat();
              successFiles.forEach(item => {
                const name = item.files?.name;

                if (!combinedOptions.includes(name!.split('.').pop() as string)) {
                  callOptionsEndpoint = true;

                  return;
                }
              });
            } else {
              callOptionsEndpoint = true;
            }
          }

          if (callOptionsEndpoint) {
            yield put(filesActions.fetchFilesFilters());
          }

          const successMessage = 'Document Upload Touch successfully created/added!';
          NotificationService.success(successMessage);
          yield put(actions.uploadFilesSuccess(successFiles));
        }
      } else {
        if (res.data.error_message === 'Request body error. Required field is missing: touch_id') {
          const errorMessage = res?.data?.error_message;
          NotificationService.error(errorMessage as string);
          yield put(actions.uploadFilesError(errorMessage as string));
        } else if (touchId === null) {
          const errorMessage =
            res?.data?.error_message || 'Failed to create Document Upload Touch!';
          NotificationService.error(errorMessage);
          yield put(actions.failedUploadFiles());

          if (files.length !== 1) {
            yield put(
              actions.uploadFiles({
                notes: action.payload.notes,
              })
            );
          }
        } else {
          const errorMessage =
            res?.data?.error_message || 'Failed to create Document Upload Touch!';
          NotificationService.error(errorMessage);
          yield put(actions.failedUploadFiles());

          if (files.length !== 1) {
            yield put(
              actions.uploadFiles({
                notes: action.payload.notes,
              })
            );
          }
        }
      }
    } catch (e: any) {
      const errorMessageCatch = getErrorMessage(e, 'Failed to create Document Upload Touch!');
      NotificationService.error(errorMessageCatch);

      actions.uploadFilesError(e);
    }
  }
}

export default function* addDocumentUploadTouchSaga() {
  yield takeLatest(actions.uploadFiles, uploadFilesSaga);
}
