// equity-touch
export const BULK_ADD_COMPANY_LIMIT_EQUITY_TOUCH = 2;

// salesloft
export const BULK_ADD_COMPANY_LIMIT_SALESLOFT = 5;

// watchlist
export const BULK_EDIT_COMPANY_LIMIT_ADD_TO_WATCHLIST = 10;
export const BULK_EDIT_COMPANY_LIMIT_REMOVE_FROM_WATCHLIST = 10;

// relates to actions that are synced with ET
export const BULK_EDIT_COMPANY_LIMIT_SYNCED_TO_ET = 7;

// relates to actions that aren't synced with ET
export const BULK_EDIT_COMPANY_LIMIT_ADD_TAGS = 10;
export const MERGE_COMPANIES_LIMIT = 2;
export const BULK_EDIT_COMPANY_LIMIT_SET_AS_SOFTWARE_COMPANY = 25;
export const BULK_EDIT_COMPANY_LIMIT_SET_AS_AI_ML_COMPANY = 25;
