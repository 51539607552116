import React from 'react';
import { Button } from 'antd';
// models
import { Cell } from '@models/table/Cell';
import { UserManagement } from '@optx/models/UserManagement';
// components
import { useResendUserInvitation } from '@components/pages/UserManagement/hooks';

const TableCellStatus: React.FC<Cell<UserManagement>> = ({ value, record }) => {
  const userResendInvitation = useResendUserInvitation();

  const handleClick = () => {
    userResendInvitation(record.id);
  };

  return (
    <>
      {value === 'Invitation Sent' || value === 'No Access' ? (
        <>
          {value} (
          <Button type="link" style={{ padding: 0 }} onClick={handleClick}>
            Resend
          </Button>
          )
        </>
      ) : record.locked ? (
        value + ' / Locked'
      ) : (
        value
      )}
    </>
  );
};

export default TableCellStatus;
