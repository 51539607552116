import React from 'react';
import { Breadcrumb } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
// models
import { BreadcrumbItemInterface } from '@components/common/breadcrumbs/interfaces';
import { ReferrerUrlParam, ReferrerUrlOrigin } from '@optx/models/routes';
// utils
import { getOriginPath, stringifyReferrerUrlParam } from '@optx/utils/routes';
// redux
import { backToSearchResults } from '@redux/company/profile/actions';
import appRoutes from '@constants/routes';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';

const { Item } = Breadcrumb;

// the "search results" breadcrumb should take user back to the grid from where the
// profile page was accessed.
// we have a special case for add to equity touch page: after the company is added and user navigates to
// the profile page, by clicking on "search results" breadcrumb he should be redirected to the grid from where
// add to equity touch pages was accessed.
// this component also takes into account the profile page being opened in a new tab and
// redirecting back to the initial grid in the new tab
export const GoBackProfile: BreadcrumbItemInterface = ({ onClick, ...restProps }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { advancedSearchPathname } = useIsAnalyst();

  const handleClick = () => {
    const searchParams: ReferrerUrlParam = queryString.parse(location.search);

    if (searchParams.referrer === ReferrerUrlOrigin.parentCompanySearch) {
      history.push(`/?${stringifyReferrerUrlParam(searchParams.referrer)}`);
    } else if (location.pathname.length <= 3) {
      // check if page was opened in a new tab, if so,
      // get the pathname of the page it was opened from and redirect to it
      // also take into account profile page being opened in a new tab and
      // add to equity touch page beind accessed from that profile page
      if (getOriginPath(document.referrer) === appRoutes.chromeExtension) {
        dispatch(backToSearchResults(advancedSearchPathname));
      } else {
        dispatch(backToSearchResults(getOriginPath(document.referrer)));
      }
    } else {
      const searchParams: ReferrerUrlParam = queryString.parse(location.search);

      if (searchParams.referrer) {
        history.push(searchParams.referrer);
      } else {
        history.goBack();
      }
    }
  };

  return (
    <Item onClick={handleClick} {...restProps}>
      <button type="button">search results</button>
    </Item>
  );
};

// eslint-disable-next-line no-underscore-dangle
GoBackProfile.__ANT_BREADCRUMB_ITEM = true;

export default GoBackProfile;
