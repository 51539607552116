import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// models
import { FilterSource, PreselectedFilter } from '@models/filters';
import { FilterTag } from '@models/tags';
import { FilterMultiTextOption, SelectOption } from '@optx/models/Option';
import { UserInformation } from '@optx/models/user';
// utils
import { normalizeFiltersByColumn } from '@utils/filters/normalize';
import { injectFilter } from '@utils/filters/injectFilter';
import { mapPreselectedFilters } from '@utils/filters/preselectedValues';
import { parseFilter } from '@optx/utils/filters/parseFilters';
// redux
import { actions as userInformationActions } from '@redux/user/information';
import { FiltersState, ApplyFiltersPayload } from '../interfaces';
import { actions } from '../actions';

const initialState: FiltersState = {
  data: [],
  filter: {},
  values: {},
  clear: {},
  preselected: {},
  persistedFilter: '',
  normalized: {},
  fetchedAt: null,
  searchKey: '',
};

const fetchSuccessReducer: CaseReducer<FiltersState, PayloadAction<Array<FilterSource>>> = (
  draftState,
  action
) => {
  const preselected = mapPreselectedFilters(action.payload, true, 0);
  draftState.preselected = preselected;
  const normalized = normalizeFiltersByColumn(action.payload);
  // @ts-ignore for now
  draftState.normalized = normalized;

  const defaultAnalysts: SelectOption[] = normalized.user_id.data.map((item: any) => ({
    label: item.name,
    value: item.value.toString(),
  }));

  if (draftState.persistedFilter !== '' && draftState.persistedFilter !== undefined) {
    const persistedFilter = draftState.persistedFilter;
    const [parsedFilters] = parseFilter(persistedFilter, action.payload);

    const parsedFiltersData = {
      ...parsedFilters,
      review_date: parsedFilters.review_date
        ? (parsedFilters.review_date as SelectOption[])
        : preselected.review_date,
      is_software: parsedFilters.is_software || preselected.is_software,
      psg_fit: parsedFilters.psg_fit || preselected.psg_fit,
      optx_score: parsedFilters.optx_score || preselected.optx_score,
      il_optx_score: parsedFilters.il_optx_score || preselected.il_optx_score,
      user_id: parsedFilters.user_id
        ? parsedFilters.user_id
        : parsedFilters.user_id === undefined && persistedFilter.includes('user_id')
        ? []
        : defaultAnalysts,
    };

    draftState.values = parsedFiltersData;
    draftState.filter = parsedFiltersData;

    if (parsedFilters.rationale_report_query) {
      draftState.searchKey = parsedFilters.rationale_report_query as string;
    } else if (parsedFilters.query) {
      draftState.searchKey = parsedFilters.query as string;
    }
  }

  if (draftState.persistedFilter === '') {
    const preselectedData = {
      ...preselected,
      user_id:
        (preselected.user_id as FilterMultiTextOption[]).length === 0
          ? defaultAnalysts
          : preselected.user_id,
    };
    draftState.values = preselectedData;
    draftState.filter = preselectedData;
  }

  draftState.clear = {
    ...preselected,
    user_id: defaultAnalysts,
  };
  draftState.preselected = {
    ...preselected,
    user_id: draftState.filter.user_id,
  };
  draftState.data = action.payload;
  draftState.fetchedAt = new Date().toISOString();
};

const clearFiltersReducer: CaseReducer<FiltersState> = draftState => {
  draftState.filter = draftState.clear;
  draftState.values = draftState.clear;
  draftState.searchKey = '';
};

const resetToDefaultReducer: CaseReducer<FiltersState> = draftState => {
  draftState.filter = draftState.preselected;
  draftState.values = draftState.preselected;
  draftState.searchKey = '';
};

const applyFiltersReducer: CaseReducer<
  FiltersState,
  PayloadAction<Partial<ApplyFiltersPayload>>
> = (draftState, action) => {
  if (action.payload && action.payload.filter) {
    draftState.filter = action.payload.filter;
    draftState.values = action.payload.filter;
  }

  if (action.payload) {
    draftState.searchKey = action.payload.searchKey ? action.payload.searchKey : '';
  }
};

const resetFilterReducer: CaseReducer<FiltersState, PayloadAction<FilterTag>> = (
  draftState,
  action
) => {
  const tag = action.payload;

  if (tag.filter === 'keyword') {
    draftState.searchKey = '';
  } else {
    const { normalized, clear, filter } = draftState;

    const newFilters = injectFilter(normalized, filter, clear, tag.filter, tag.optionId);
    draftState.filter = newFilters;
    draftState.values = newFilters;
  }
};

const updateValueReducer: CaseReducer<
  FiltersState,
  PayloadAction<{ key: string; value: PreselectedFilter }>
> = (draftState, action) => {
  const { key, value } = action.payload;
  draftState.filter = { ...draftState.filter, [key]: value };
  draftState.values = { ...draftState.values, [key]: value };
};

// External reducers
// Update persisted filter to be used when filters are loaded.
const fetchUserInformationSuccessReducer: CaseReducer<
  FiltersState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const filters = action.payload.settings.session_settings?.checklist_insights_companies_filters;

  if (filters) {
    draftState.persistedFilter = filters;
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.filters.fetchSuccess, fetchSuccessReducer)
    .addCase(actions.filters.clearFilters, clearFiltersReducer)
    .addCase(actions.filters.resetToDefault, resetToDefaultReducer)
    .addCase(actions.filters.applyFilters, applyFiltersReducer)
    .addCase(actions.filters.resetFilter, resetFilterReducer)
    .addCase(actions.filters.updateFilters, updateValueReducer)
    // External reducer
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
);

export default reducer;
