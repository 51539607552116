import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import authReducer, { saga as authSaga } from '../../../redux/auth';

// Inject reducers/sagas
const useInjectAuth = () => {
  useInjectReducer({ key: 'auth', reducer: authReducer });
  useInjectSaga({ key: 'auth', saga: authSaga });
};

export default useInjectAuth;
