import { createSelector } from 'reselect';
import { AppState } from '@optx/redux/interfaces';
import moment from 'moment';
// constants
import { SHORT_MONTH_YEAR_DATE_FORMAT } from '@optx/constants/format/date';
// redux
import { selectors as fetchSelectors } from '../../../feature/fetch';

// specific selectors
const selectFiltersState = (state: AppState) => state.companyTouches.filters;

export const isLoading = createSelector(selectFiltersState, fetchSelectors.isLoadingSelector);

export const shouldInitialFetch = createSelector(
  selectFiltersState,
  fetchSelectors.shouldInitialFetchSelector
);

export const isLoaded = createSelector(selectFiltersState, state => !!state.fetchedAt);

export const getTypesOptions = createSelector(selectFiltersState, state => state.touchTypes);

export const getCurrentInitiator = createSelector(
  selectFiltersState,
  state => state.currentInititator
);

export const getCurrentTouchType = createSelector(
  selectFiltersState,
  state => state.currentTouchType
);

export const getInitiatorOptions = createSelector(selectFiltersState, state => state.initiators);

const touchDateValue = (date: string) => {
  return moment(date).format(SHORT_MONTH_YEAR_DATE_FORMAT);
};

/**
 * Get summary Key Performance Indicators.
 */
export const getSummaryKPIs = createSelector(selectFiltersState, state => {
  const options: Array<{ label: string; value: string }> = [];

  if (state.data) {
    const {
      number_of_touches: numberOfTouches,
      first_touch_date: firstTouchDate,
      last_touch_date: lastTouchDate,
    } = state.data;
    const totalTouches = numberOfTouches;

    options.push({
      label: 'Total # of Touches',
      value: totalTouches !== 1 ? `${totalTouches} Touches` : `${totalTouches} Touch`,
    });

    if (firstTouchDate) {
      options.push({
        label: 'First Touch Date',
        value: touchDateValue(firstTouchDate),
      });
    }

    if (lastTouchDate) {
      options.push({
        label: 'Last Touch Date',
        value: touchDateValue(lastTouchDate),
      });
    }
  }

  return options;
});
