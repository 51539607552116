import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// components
import Icon from '../Icon';

interface OpenInNewTabProps {
  url: string | null | undefined;
}

const Wrapper = styled.div`
  display: flex;

  &:hover {
    & > a:last-child {
      display: inline-block;
    }
  }
`;

const StyledLink = styled(Link)`
  display: none;
  color: #595959;
  margin: 0 0 0 8px;
`;

const OpenInNewTabHover: React.FC<OpenInNewTabProps> = ({ children, url }) => {
  return (
    <Wrapper>
      {children}
      {url && (
        <StyledLink to={url} target="_blank">
          <Icon iconName="open-in-new" />
        </StyledLink>
      )}
    </Wrapper>
  );
};

export default OpenInNewTabHover;
