import React, { useContext } from 'react';
import { Row, Col, Typography } from 'antd';
import { Checkbox } from 'formik-antd';
import { useField } from 'formik';
import { BaseFilter } from '@models/filters';
import { LogicOption } from '@models/Option';
import SearchFilterCard from './SearchFilterCard';
import { FiltersContext } from '../FiltersContext';

interface FilterLogicCheckboxProps {
  filter: BaseFilter<Array<LogicOption>>;
}

const FilterLogicCheckbox: React.FC<FilterLogicCheckboxProps> = ({ filter }) => {
  const [field, ,] = useField<Array<LogicOption>>(filter.column);
  const { onManualFilterChange } = useContext(FiltersContext);

  const onChange = () => {
    // Call on change on the next tick.
    setTimeout(() => {
      onManualFilterChange && onManualFilterChange(filter.column);
    }, 0);
  };

  return (
    <SearchFilterCard label={filter.label}>
      {field.value.map((option, index) => (
        <Row key={index} className={option.label.replace(/\s+/g, '-').toLowerCase()}>
          <Col span={12}>{option.label}</Col>
          <Col span={12}>
            <Row>
              <Col span={10}>
                <Checkbox
                  className="no-wrap-checkbox"
                  name={`${filter.column}[${index}].in`}
                  disabled={option.disabledIn}
                  onChange={onChange}
                  fast
                >
                  <Typography.Text title="In">In</Typography.Text>
                </Checkbox>
              </Col>
              <Col span={14}>
                <Checkbox
                  className="no-wrap-checkbox"
                  name={`${filter.column}[${index}].notIn`}
                  disabled={option.disabledNotIn}
                  onChange={onChange}
                  fast
                >
                  <Typography.Text title="Not In">Not In</Typography.Text>
                </Checkbox>
              </Col>
            </Row>
          </Col>
        </Row>
      ))}
    </SearchFilterCard>
  );
};

export default React.memo(FilterLogicCheckbox);
