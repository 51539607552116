import { useInjectReducer, useInjectSaga } from 'redux-injectors';
// constants
import { STATE_KEY } from './state/constants';
// redux
import reducer from './state/reducer';
import saga from './state/sagas';

export const useInject = () => {
  useInjectReducer({ key: STATE_KEY, reducer });
  useInjectSaga({ key: STATE_KEY, saga });
};
