// utils
import { createFeatureActionType } from '@optx/utils/feature';
// local
import { featureName } from '../featureName';

export const FETCH_PIPELINE_REPORT_COMPANY = createFeatureActionType(
  featureName,
  'FETCH_PIPELINE_REPORT_COMPANY'
);
export const FETCH_PIPELINE_REPORT_COMPANY_SUCCESS = createFeatureActionType(
  featureName,
  'FETCH_PIPELINE_REPORT_COMPANY_SUCCESS'
);
export const FETCH_PIPELINE_REPORT_COMPANY_FAIL = createFeatureActionType(
  featureName,
  'FETCH_PIPELINE_REPORT_COMPANY_FAIL'
);

export const FETCH_PIPELINE_REPORT_CARD = createFeatureActionType(
  featureName,
  'FETCH_PIPELINE_REPORT_CARD'
);
export const FETCH_PIPELINE_REPORT_CARD_SUCCESS = createFeatureActionType(
  featureName,
  'FETCH_PIPELINE_REPORT_CARD_SUCCESS'
);
export const FETCH_PIPELINE_REPORT_CARD_FAIL = createFeatureActionType(
  featureName,
  'FETCH_PIPELINE_REPORT_CARD_FAIL'
);

// save search as list
export const SAVE_SEARCH_AS_LIST = createFeatureActionType(featureName, 'SAVE_SEARCH_AS_LIST');
export const SAVE_SEARCH_AS_LIST_SUCCESS = createFeatureActionType(
  featureName,
  'SAVE_SEARCH_AS_LIST_SUCCESS'
);
export const SAVE_SEARCH_AS_LIST_FAIL = createFeatureActionType(
  featureName,
  'SAVE_SEARCH_AS_LIST_FAIL'
);
// filterss
export const FETCH_FILTERS = createFeatureActionType(featureName, 'FETCH_FILTERS');
export const FETCH_FILTERS_SUCCESS = createFeatureActionType(featureName, 'FETCH_FILTERS_SUCCESS');
export const FETCH_FILTERS_FAIL = createFeatureActionType(featureName, 'FETCH_FILTER_FAIL');
export const FILTER_CLEAR = createFeatureActionType(featureName, 'FILTER_CLEAR');
export const FILTER_RESET = createFeatureActionType(featureName, 'FILTER_RESET');
export const UPDATE_VALUE = createFeatureActionType(featureName, 'UPDATE_VALUE');
export const RESET_TO_DEFAULT = createFeatureActionType(featureName, 'RESET_TO_DEFAULT');
export const APPLY_FILTERS = createFeatureActionType(featureName, 'APPLY_FILTERS');

// ui
export const TOGGLE_FILTERS_MODAL = createFeatureActionType(featureName, 'TOGGLE_FILTERS_MODAL');
