import React from 'react';
import { ActionMeta, ValueType } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
// models
import { MultiSelectFilterColumns } from '@optx/models/feature/analystsLeaderboard/filters';
import { SelectOption } from '@models/Option';
// components
import { MultiSelectInline, MultiSelectProps, SingleSelect } from '@shared/view/molecules/Select';
import SingleValueContainer from '@optx/shared/view/molecules/Select/SingleSelect/components/SingleValueContainer';
// redux
import {
  selectors as filterSelectors,
  actions as filterActions,
} from '@redux/company-outreach/filters';
import { actions as searchActions, selectors as searchSelectors } from '@features/grid/search';
import { selectors as userInformationSelectors } from '@redux/user/information';

interface FilterMultiSelectProps extends MultiSelectProps {
  filterKey: MultiSelectFilterColumns;
  criteria?: string;
}

const AnalystsSelect: React.FC<FilterMultiSelectProps> = ({
  filterKey,
  criteria,
  ...restProps
}) => {
  const dispatch = useDispatch();

  const searchKey = useSelector(searchSelectors.getSearchKey('outreach'));
  const isDefaultAnalyst = useSelector(userInformationSelectors.getSourcingOutReachDefaultAnalyst);

  const isMultiSelectDisabled = isDefaultAnalyst ? true : false;

  const options = useSelector(
    filterSelectors.getSourcingFiltersOptions(filterKey as 'analyst_id' | 'column_name')
  );

  const value = useSelector(
    filterSelectors.getSourcingOptitonsFilterValues(filterKey as 'analyst_id' | 'column_name')
  );

  const handleChange = (value: ValueType<SelectOption>, actionMeta: ActionMeta<SelectOption>) => {
    dispatch(
      filterActions.updateSourcingFilterOptions({
        filterKey: filterKey as 'ddate' | 'analyst_id' | 'column_name',
        value: value as SelectOption<string>,
      })
    );
    dispatch(
      searchActions.searchCompanies({
        gridKey: 'outreach',
        data: { searchKey, shouldResetPageNumber: true },
      })
    );
  };

  if (filterKey === 'column_name') {
    return (
      <div>
        <SingleSelect
          options={options}
          SearchIcon={false}
          onBlur={() => {}}
          styles={{
            valueContainer: () => ({
              display: 'flex',
              alignItems: 'center',
              marginLeft: '18px',
            }),
          }}
          components={{
            SingleValue: SingleValueContainer,
          }}
          onChange={handleChange}
          {...restProps}
          value={(value as SelectOption<string>) || []}
          placeholder="Show by"
        />
      </div>
    );
  }

  return (
    <div>
      <MultiSelectInline
        options={options}
        SearchIcon={false}
        styles={{
          valueContainer: () => ({
            display: 'flex',
            alignItems: 'center',
            marginLeft: '-18px',
          }),
        }}
        onBlur={() => {}}
        onChange={handleChange}
        {...restProps}
        value={(value as SelectOption<string>) || []}
        isDisabled={isMultiSelectDisabled}
        placeholder="Show by"
      />
    </div>
  );
};

export default AnalystsSelect;
