import React from 'react';
import Styles from './StatsSection.styles';

interface StatsSectionProps {
  title: string;
}

const StatsSection: React.FC<StatsSectionProps> = ({ title, children }) => {
  return (
    <Styles.Section>
      <Styles.Banner />
      <Styles.Body>
        <Styles.BodyContent>
          <Styles.Title>{title}</Styles.Title>
          {children}
        </Styles.BodyContent>
      </Styles.Body>
    </Styles.Section>
  );
};

export default StatsSection;
