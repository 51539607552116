import styled from 'styled-components';
import { Col, Card } from 'antd';
import { Text } from 'recharts';

const LineChartWrapper = styled(Card)`
  height: 100%;

  &.has_overflow {
    svg {
      overflow: visible;
    }
  }

  .area-charts {
    .recharts-surface {
      overflow: visible;
    }
  }
`;

const ChartHeaderColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  @media (max-width: 1700px) {
    margin-left: 35px;
  }

  span {
    color: #90a0b7;
  }

  .value {
    font-size: 14px;
    font-weight: 600;
  }

  .label {
    font-size: 12px;
    text-transform: uppercase;
  }
`;

const ChartHeaderLogoColumn = styled(Col)`
  display: flex;
  align-items: center;
  .chart-icon {
    font-size: 30px;
    margin-right: 10px;
    &:not(.website-rank) {
      path {
        fill: #0050b3;
      }
    }
  }
`;
const ChartLabel = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  padding: 15px 0;
`;

const RatingLabel = styled.p`
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
  color: #1890ff;
`;

export const Styles = {
  LineChartWrapper,
  ChartHeaderColumn,
  ChartHeaderLogoColumn,
  ChartLabel,
  RatingLabel,
};
