import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
// redux
import * as selectors from '@redux/scheduled-touches/selectors';
// components
import { PieChart } from '@optx/features/charts/PieChart';
import ChartLegends from './ChartLegends';
import Styled from '../StatsSection/StatsSection.styles';

const COLORS = [
  '#b659bf',
  '#7958ca',
  '#2552ac',
  '#337bd3',
  '#2868cd',
  '#2c7fdb',
  '#2f96dd',
  '#31abdd',
  '#35d3d6',
  '#37e7d0',
  '#38f9c4',
];

const InsightChart = () => {
  const getInsight = useSelector(selectors.getInsight);
  const getInsightTotal = useSelector(selectors.getInsightTotal);

  const chartData = useMemo(
    () =>
      Object.keys(getInsight).map((item, index) => {
        return {
          label: item,
          value: getInsight[item],
          color: COLORS[index],
        };
      }),
    [getInsight]
  );

  return (
    <Styled.SubSection>
      <div className="pie-chart">
        <PieChart data={chartData} dataKey="value" />
        <span className="total">
          <b>{getInsightTotal}</b># of Touches
        </span>
      </div>
      <ChartLegends data={chartData} />
    </Styled.SubSection>
  );
};

export default InsightChart;
