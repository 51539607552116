// models
import { CompanyColumnDisplay } from '@optx/models/table/Columns';
import { CompanyChecklist } from '@optx/features/checklist-research-insights/models/company';
import { Analyst } from '@optx/features/checklist-research-insights/models/analyst';

export const companyColumnDisplayInitialState: CompanyColumnDisplay<
  Exclude<keyof CompanyChecklist, 'company_id'>
> = {
  company_url: {
    id: 'company_url',
    name: 'Company URL',
    visible: true,
    width: 42,
  },
  company_name: {
    id: 'company_name',
    name: 'Company Name',
    visible: true,
    width: 170,
  },
  checklist_reviewed_by: {
    id: 'checklist_reviewed_by',
    name: 'Reviewer',
    visible: true,
    width: 160,
  },
  stage: {
    id: 'stage',
    name: 'Stage',
    visible: true,
    width: 110,
  },
  pipeline_rank: {
    id: 'pipeline_rank',
    name: 'Pipeline Rank',
    visible: true,
    width: 85,
  },
  checklist_reviewed_date: {
    id: 'checklist_reviewed_date',
    name: 'Date Reviewed',
    visible: true,
    width: 120,
  },
  score: {
    id: 'score',
    name: 'OPTX Score',
    visible: true,
    width: 150,
  },
  il_optx_score: {
    id: 'il_optx_score',
    name: 'IL OPTX Score',
    visible: true,
    width: 150,
  },
  is_interesting: {
    id: 'is_interesting',
    name: 'PSG Fit',
    visible: true,
    width: 110,
  },
  is_software: {
    id: 'is_software',
    name: 'Software',
    visible: true,
    width: 85,
  },
};

export const analystColumnDisplayInitialState: CompanyColumnDisplay<
  Exclude<keyof Analyst, 'analyst_id'>
> = {
  user_name: {
    id: 'user_name',
    name: 'Reviewer',
    visible: true,
    width: 170,
  },
  total_companies_reviewed_cids: {
    id: 'total_companies_reviewed_cids',
    name: 'Total # of Companies Reviewed',
    visible: true,
    width: 180,
  },
  optx_score_reviewed_cids: {
    id: 'optx_score_reviewed_cids',
    name: '# of OPTX Scores Reviewed',
    visible: true,
    width: 140,
  },
  il_optx_score_reviewed_cids: {
    id: 'il_optx_score_reviewed_cids',
    name: '# of IL OPTX Scores Reviewed',
    visible: true,
    width: 140,
  },
  optx_score_agreement_percentage: {
    id: 'optx_score_agreement_percentage',
    name: 'OPTX Score Agreement %',
    visible: true,
    width: 140,
  },
  il_optx_score_agreement_percentage: {
    id: 'il_optx_score_agreement_percentage',
    name: 'IL OPTX Score Agreement %',
    visible: true,
    width: 140,
  },
  psg_fits_reviewed_cids: {
    id: 'psg_fits_reviewed_cids',
    name: '# of PSG Fits Reviewed',
    visible: true,
    width: 140,
  },
  psg_fits_verified_cids: {
    id: 'psg_fits_verified_cids',
    name: '# of PSG Fits Verified Yes',
    visible: true,
    width: 140,
  },
  software_companies_reviewed_cids: {
    id: 'software_companies_reviewed_cids',
    name: '# of Software Y/N Reviewed',
    visible: true,
    width: 140,
  },
  software_changes_cids: {
    id: 'software_changes_cids',
    name: '# of Software Y/N Changes Made',
    visible: true,
    width: 140,
  },
  software_change_accuracy: {
    id: 'software_change_accuracy',
    name: 'Accuracy of Software Y/N',
    visible: true,
    width: 140,
  },
  software_change_no_accuracy: {
    id: 'software_change_no_accuracy',
    name: 'Accuracy of Software No',
    visible: true,
    width: 140,
  },
  software_change_yes_accuracy: {
    id: 'software_change_yes_accuracy',
    name: 'Accuracy of Software Yes',
    visible: true,
    width: 140,
  },
};

export const initialAnalystColumnOrder: Array<keyof Omit<Analyst, 'analyst_id'>> = [
  'user_name',
  'total_companies_reviewed_cids',
  'optx_score_reviewed_cids',
  'il_optx_score_reviewed_cids',
  'optx_score_agreement_percentage',
  'il_optx_score_agreement_percentage',
  'psg_fits_reviewed_cids',
  'psg_fits_verified_cids',
  'software_companies_reviewed_cids',
  'software_changes_cids',
  'software_change_accuracy',
  'software_change_no_accuracy',
  'software_change_yes_accuracy',
];

export const initialCompanyColumnOrder: Array<keyof Omit<CompanyChecklist, 'company_id'>> = [
  'company_url',
  'company_name',
  'checklist_reviewed_by',
  'stage',
  'pipeline_rank',
  'checklist_reviewed_date',
  'score',
  'il_optx_score',
  'is_interesting',
  'is_software',
];
