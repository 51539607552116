import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { TooltipProps } from 'recharts';
// models
import { MultiSelectFilterColumns } from '@models/feature/analystsLeaderboard/filters';
import { SmartTouchScoreOption } from '@optx/models/feature/analystsLeaderboard/SmartTouchScore';
// styles
import StyledTooltip from '@optx/components/common/charts/Tooltip.styles';
// redux
import { AppState } from '@optx/redux/interfaces';
import { selectors } from '../../../state';
import Styled from './ChartTooltip.styled';

const ChartTooltip = ({ payload, active }: TooltipProps) => {
  const analysts = useSelector((state: AppState) =>
    selectors.filters.getSelectValue(state, 'analyst_id' as MultiSelectFilterColumns)
  );

  if (active && payload) {
    const item = payload[0].payload as SmartTouchScoreOption;
    const analystName = !isEmpty(analysts) ? analysts[0].label : '';

    return (
      <StyledTooltip.Wrapper>
        <Styled.Label>
          {item.is_selected
            ? `# of analysts fall into this range ${item.label}
            and the selected analyst ${analystName} falls into this range`
            : `# of analysts fall into this range ${item.label}`}
        </Styled.Label>
        <Styled.Total $selected={item.is_selected}>Total Analysts: {item.y_axis}</Styled.Total>

        {item.users && item.users.length > 0 ? (
          <>
            <Styled.Label>Analysts:</Styled.Label>
            <Styled.UserNames>{item.users.join(', ')}</Styled.UserNames>
          </>
        ) : (
          ''
        )}
      </StyledTooltip.Wrapper>
    );
  }

  return null;
};

export default ChartTooltip;
