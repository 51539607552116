import styled from 'styled-components';
import { MainContent } from '@optx/components/common/layout';

const StyledMainContent = styled(MainContent)``;

const StatsWrapper = styled.div`
  background: #c2cfe0;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 15%);
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${StatsWrapper},
  ${StyledMainContent} {
    min-width: 1px;
    width: 100%;
  }

  @media (min-width: 1600px) {
    flex-wrap: nowrap;

    ${StatsWrapper} {
      flex: 0;
      min-width: 500px;
      max-width: 500px;
    }

    ${StyledMainContent} {
      flex: 1;
      margin-top: 23px;
    }
  }
`;

const Styled = {
  Wrapper,
  StatsWrapper,
  MainContent: StyledMainContent,
};

export default Styled;
