import React from 'react';
import { Col, Typography } from 'antd';
import { OptionTypeBase } from 'react-select';
import { useField } from 'formik';
import { Select, FormItem } from 'formik-antd';
import { useSelector } from 'react-redux';
// models
import { BaseField } from '@optx/models/companyFields';
// redux
import { selectors as companyEditAllSelectors } from '@optx/features/edit-all-info/state';

const { Option } = Select;

interface SingleSelectWithRationaleFieldProps {
  field: BaseField;
}

const SingleSelectWithRationaleField: React.FC<SingleSelectWithRationaleFieldProps> = ({
  field,
}) => {
  const [selected, , setSelectedOption] = useField<string>(field.id || '');

  const saveBtnStatus = useSelector(companyEditAllSelectors.getDisableSaveBtnFor);

  const handleChange = (value: string) => {
    setSelectedOption.setValue(value);
  };

  return (
    <Col span={8} className={field.id}>
      <Typography.Text>{field.label}</Typography.Text>
      <FormItem name={field.id}>
        <Select
          showSearch
          name={field.id}
          value={selected.value}
          onChange={handleChange}
          disabled={!!saveBtnStatus || field.editable === false}
          getPopupContainer={trigger => trigger.parentElement!}
          filterOption={(input: string, option: OptionTypeBase | undefined) => {
            const fullName = option ? `${option?.children.props.children}` : '';

            return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {field.data &&
            field.data.length &&
            field.data.map((option: OptionTypeBase) => (
              <Option key={option.value as string} value={option.value as string}>
                <span>{option.label}</span>
              </Option>
            ))}
        </Select>
      </FormItem>
    </Col>
  );
};

export default React.memo(SingleSelectWithRationaleField);
