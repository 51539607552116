import React from 'react';
import { useSelector } from 'react-redux';

import { pipelineCriteria } from '../../constants/custom-criteria';
import { selectors } from '../../state';
import FilterSingleSelectButtons from '../FilterSingleSelectButtons';
import { Title } from './Title';
import { OtherDealStages } from './OtherDealStages';
import { PipelineChart } from './PipelineChart';
import { PreviousPeriodSelect } from './PreviousPeriodSelect';
import { Styled } from './PipelineInformation.styled';

export const PipelineInformation = () => {
  const isLoading = useSelector(selectors.pipelineInformation.isLoading);

  return (
    <Styled.Card
      $transparent
      title={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <>
          <Title />
          <Styled.SecondaryTitle># of companies in each deal stage</Styled.SecondaryTitle>
        </>
      }
      extra={<PreviousPeriodSelect />}
      loading={isLoading}
    >
      <Styled.GridChart hoverable={false}>
        <FilterSingleSelectButtons
          filterKey="pipeline_companies_rank"
          criteria={pipelineCriteria}
        />
        <PipelineChart />
      </Styled.GridChart>

      <Styled.GridOtherDealStages hoverable={false}>
        <OtherDealStages />
      </Styled.GridOtherDealStages>
    </Styled.Card>
  );
};
