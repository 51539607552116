import { takeLatest, call, put, select, fork } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary } from 'lodash';
import { AxiosResponse } from 'axios';
import { push } from 'react-router-redux';
import queryString from 'query-string';
// constants
import { FAVORITE_LIST_OWNER } from '@constants/lists';
import { LISTS_ROUTES } from '@constants/routes';
import { SAVE_SEARCH_AS_LIST_LIMIT } from './constants';
// models
import { SearchStateData } from '@models/search';
import { BaseFilter, PreselectedFilter } from '@optx/models/filters';
import {
  CompanyWatchList,
  FetchFavoriteLists,
  UpdateListAssociation,
  PublicWatchListActionPayload,
} from '@models/WatchList';
import { SearchPayloadRequest } from '@models/api/contacts';
import { PageInfo } from '@models/table/Pagination';
import { FavoriteListsSearchData, InitialFavoriteListsSearchData } from '@models/lists/search';
import { RouteAliases } from '@optx/models/routes';
import { SortByRule, SortRule } from '@models/table/sorting';
// utils
import { mapSortQuery } from '@optx/utils/search';
import mapFiltersToURLParams from '@utils/filters/mapFiltersToURLParams';
import { getRouteAlias } from '@utils/routes';
import { getErrorMessage } from '@optx/utils/api/errors';
// services
import { SavedSearchesService, UserService } from '@services/api';
import NotificationService from '@services/NotificationService';
// redux
import { selectors as userSelectors, actions as userActions } from '@redux/user/information';
import { selectors as listsAndSearchesSelectors } from '@features/lists-and-searches';
import { actions as listsAndSearchesActions } from '@features/lists-and-searches/pagination';
import { getSorting } from '@redux/company/search/search/selectors';
import { getSearchKey } from '@optx/features/grid/search/state/selectors';
import { selectors as searchCountSelectors } from '@features/grid/search-count';
import { selectors as myCompaniesFilterCardsSelectors } from '@optx/redux/my-companies/filters-cards';
import { selectors as myCompaniesSorthSelectors } from '@redux/my-companies/sort';
import { selectors as filterSourcesSelectors } from '@redux/company/filters';
import { deleteCompanyInCurrentList } from '@redux/lists/details/actions';
import { getSorting as getSortingListSearchSelectors } from '@redux/lists/search/search/selectors';
import { actions as searchActions } from '@features/grid/search';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import { actions as customGlobalLoaderActions } from '@features/custom-global-loader';
import * as actions from './actions';
import * as favoriteListsSelectors from './selectors';
import companyListsSagas from '../company-lists/sagas';
import { selectors as listsSelectors } from '@redux/lists/details';
import { searchLists } from './sagasReusable';

export function* searchListsFail() {
  const errorMessage = 'Error fetching watch lists!';
  yield put(actions.fetchFavoriteListsFail(errorMessage));
  NotificationService.error(errorMessage);
}

export function* initialFetchFavoriteListsSaga(
  action: PayloadAction<FetchFavoriteLists | undefined>
) {
  const fetchAll = action.payload?.fetchAll;
  const showEmptyLists = action.payload?.showEmptyLists;
  const sorting: SortByRule<any>[] = yield select(favoriteListsSelectors.getSortBy);
  const searchData: InitialFavoriteListsSearchData = {
    sortBy: sorting,
    fetchAll,
    showEmptyLists: showEmptyLists ?? true,
  };

  try {
    // @ts-ignore
    const res = yield call(SavedSearchesService.initialFetchFavoriteLists, searchData);

    yield put(
      actions.initialFetchFavoritesListsSuccess({
        data: res.data.lists,
        total: res.data.total_list_count,
        ...searchData,
        showEmptyLists: searchData.showEmptyLists,
      })
    );
  } catch (e: any) {
    yield call(searchListsFail);
  }
}

export function* fetchFavoriteListsSaga(action: PayloadAction<FetchFavoriteLists | undefined>) {
  const payloadQuery = action.payload?.query || '';
  const fetchAll = action.payload?.fetchAll;
  const showEmptyLists = action.payload?.showEmptyLists;
  const pageNumber: number = yield select(favoriteListsSelectors.getPageNumber);
  const pageSize: number = yield select(listsAndSearchesSelectors.pageSize);
  const pagination = { pageNumber, pageSize };
  const userPageNumber: number = yield select(userSelectors.getFavoritesListResultsPageNumber);
  const query: string = yield select(favoriteListsSelectors.getQuery);
  const sorting: SortByRule<any>[] = yield select(favoriteListsSelectors.getSortBy);
  const searchData: FavoriteListsSearchData = {
    query: payloadQuery || '',
    sortBy: sorting,
    pagination:
      action.payload && action.payload.query !== query
        ? { ...pagination, pageNumber: 1 }
        : pagination,
    fetchAll,
    showEmptyLists: showEmptyLists ?? true,
  };

  try {
    yield call(searchLists, searchData);

    if (showEmptyLists) {
      yield put(actions.resetFetchedAt());
    }

    if (searchData.pagination.pageNumber !== userPageNumber) {
      yield put(
        userActions.updateUserSettings({
          favorites_list_page_number: searchData.pagination.pageNumber,
          my_watchlists_filters: payloadQuery,
        })
      );
    } else {
      yield put(userActions.updateUserSettings({ my_watchlists_filters: payloadQuery }));
    }
  } catch (e: any) {
    yield call(searchListsFail);
  }
}

export function* sortFavoriteListsSaga(
  action: PayloadAction<{ data: SortRule<any>; hideHistory?: boolean }>
) {
  const sorting = action.payload.data.sortBy;
  const hideHistory = action.payload.hideHistory;
  const userPageNumber: number = yield select(userSelectors.getFavoritesListResultsPageNumber);
  const showEmptyLists: boolean = yield select(favoriteListsSelectors.showEmptyLists);
  const pageSize: number = yield select(listsAndSearchesSelectors.pageSize);
  const pagination = { pageNumber: 1, pageSize };
  const query: string = yield select(favoriteListsSelectors.getQuery);
  const searchData: FavoriteListsSearchData = {
    query,
    pagination,
    sortBy: sorting,
    fetchAll: true,
    showEmptyLists,
  };
  const sortQuery = queryString.stringify(mapSortQuery(sorting), {
    arrayFormat: 'comma',
  });

  try {
    yield call(searchLists, searchData);

    if (!hideHistory) {
      if (searchData.pagination.pageNumber !== userPageNumber) {
        yield put(
          userActions.updateUserSettings({
            favorites_list_sorting: sortQuery,
            favorites_list_page_number: searchData.pagination.pageNumber,
          })
        );
      } else {
        yield put(userActions.updateUserSettings({ favorites_list_sorting: sortQuery }));
      }
    }
  } catch (e: any) {
    yield call(searchListsFail);
  }
}

export function* favoriteListsPaginationSaga(action: PayloadAction<PageInfo>) {
  const pagination = action.payload;
  const query: string = yield select(favoriteListsSelectors.getQuery);
  const sorting: SortByRule<any>[] = yield select(favoriteListsSelectors.getSortBy);
  const userPageNumber: number = yield select(userSelectors.getFavoritesListResultsPageNumber);
  const pageSize: number = yield select(listsAndSearchesSelectors.pageSize);
  const showEmptyLists: boolean = yield select(favoriteListsSelectors.showEmptyLists);

  const searchData: FavoriteListsSearchData = {
    query,
    pagination,
    sortBy: sorting,
    showEmptyLists,
  };

  try {
    yield call(searchLists, searchData);

    if (
      searchData.pagination.pageSize !== pageSize &&
      searchData.pagination.pageNumber !== userPageNumber
    ) {
      yield put(
        userActions.updateUserSettings({
          lists_and_searches_results_per_page: pagination.pageSize,
          favorites_list_page_number: pagination.pageNumber,
        })
      );
      yield put(
        listsAndSearchesActions.updateMyWatchlistTabsPagination({
          actions: ['sourceScrub', 'companySearches', 'contactSearches'],
          pagination,
        })
      );
    } else if (searchData.pagination.pageSize !== pageSize) {
      yield put(
        userActions.updateUserSettings({
          lists_and_searches_results_per_page: pagination.pageSize,
        })
      );
      yield put(
        listsAndSearchesActions.updateMyWatchlistTabsPagination({
          actions: ['sourceScrub', 'companySearches', 'contactSearches'],
          pagination,
        })
      );
    } else if (searchData.pagination.pageNumber !== userPageNumber) {
      yield put(
        userActions.updateUserSettings({
          favorites_list_page_number: pagination.pageNumber,
        })
      );
    }
  } catch (e: any) {
    yield call(searchListsFail);
  }
}

export function* updateFavoriteListAddonSaga(action: PayloadAction<UpdateListAssociation>) {
  const { payload } = action;
  const { listId, addonCompany } = payload;
  const isDeleting = addonCompany === 'blank';

  try {
    const res: AxiosResponse<{ addon_company_id: number }> = yield call(
      UserService.updateFavoriteListAddon,
      payload
    );

    if (res.data) {
      const { addon_company_id: addonCompanyId } = res.data;

      const updatedPayload: UpdateListAssociation = {
        listId,
        addonCompany: !isDeleting ? addonCompany : undefined,
        addonCompanyId,
      };

      yield put(actions.updateFavoriteListAssociationSuccess(updatedPayload));

      const successMessage = isDeleting
        ? 'List association successfully removed!'
        : `Watchlist successfully associated to ${updatedPayload.addonCompany}!`;

      NotificationService.success(successMessage);
    } else {
      const errorMessage = isDeleting
        ? 'Error removing associated company from Watchlist!'
        : 'Error associating company to Watchlist!';

      NotificationService.error(errorMessage);
      yield put(actions.updateFavoriteListAssociationFail(errorMessage));
    }
  } catch (error: any) {
    const errorMessage = isDeleting
      ? 'Error removing associated company from Watchlist!'
      : 'Error associating company to Watchlist!';

    NotificationService.error(errorMessage);
    yield put(actions.updateFavoriteListAssociationFail(errorMessage));
  }
}

export function* createFavoriteListSaga(
  action: PayloadAction<{ title: string; company_id: number }>
) {
  const { payload } = action;

  try {
    const res: AxiosResponse<CompanyWatchList> = yield call(
      SavedSearchesService.addCompanyToList,
      action.payload
    );
    const sorting: SortByRule<any>[] = yield select(favoriteListsSelectors.getSortBy);

    yield put(actions.createFavoriteListSuccess({ list: res.data, companyId: payload.company_id }));
    yield put(actions.fetchFavoriteLists({ query: '', sortBy: sorting, fetchAll: true }));
    NotificationService.success(`Create watchlist ${payload.title} success!`);
  } catch (error: any) {
    const message = getErrorMessage(error, 'Failed to add to Watchlist!');
    NotificationService.error(message);
    yield put(actions.createFavoriteListFail(message));
  }
}

export function* deleteFavoriteListSaga(action: PayloadAction<number>) {
  try {
    yield call(SavedSearchesService.deleteFavoriteList, action.payload);
    yield put(actions.deleteFavoriteListSuccess(action.payload));

    const successMessage = 'Watch List deleted successfully';
    NotificationService.success(successMessage);
  } catch (error: any) {
    const errorMessage = 'Error deleting watch list!';
    yield put(actions.deleteFavoriteListFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* renameFavoriteListSaga(action: PayloadAction<{ title: string; list_id: number }>) {
  try {
    yield call(SavedSearchesService.renameFavoriteList, action.payload);
    yield put(actions.renameFavoriteListSuccess(action.payload));

    const successMessage = 'Watch List renamed successfully';
    NotificationService.success(successMessage);
  } catch (error: any) {
    let errorMessage: string;

    if (
      Object.values(error.response.data.errors)[0] ===
      `Watchlist with a title ${action.payload.title} already exists.`
    ) {
      errorMessage = Object.values(error.response.data.errors)[0] as string;
    } else {
      errorMessage = 'Error renaming watch list!';
    }

    yield put(actions.renameFavoriteListFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* deleteCompanyFromListSaga(
  action: PayloadAction<{ list_id: string; company_id: number }>
) {
  const { payload } = action;

  try {
    // @ts-ignore
    const res = yield call(SavedSearchesService.deleteCompanyInList, payload);
    yield put(
      actions.deleteCompanyFromListSuccess({
        companyId: payload.company_id,
        listId: payload.list_id,
      })
    );

    if (res.data) {
      const lists: CompanyWatchList[] = yield select(favoriteListsSelectors.getListsNormalized);
      const list = (lists as CompanyWatchList[]).find(
        (item: CompanyWatchList) => item.unique_id === Number(payload.list_id)
      );

      if (list && list.count === 0) {
        const isSourceScrub = window.location.pathname.substr(1).startsWith('ss');

        if (isSourceScrub) {
          yield put(push(LISTS_ROUTES.sourceScrubLists));
        } else {
          yield put(push(LISTS_ROUTES.myWatchList));
        }
      }
    }

    yield put(searchActions.initialCompaniesSearch({ gridKey: 'lists', data: undefined }));
    yield put(deleteCompanyInCurrentList(payload.company_id));

    NotificationService.success('Delete company from list success!');
  } catch (e: any) {
    const errorMessage = 'Delete company from list fail!';
    yield put(actions.deleteCompanyFromListFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* removeSharedListSaga(action: PayloadAction<number | string>) {
  const { payload } = action;

  try {
    yield call(UserService.removeSharedList, payload);
    yield put(actions.removeSharedListSuccess(payload));

    NotificationService.success('Watch List removed successfully');
  } catch (e: any) {
    const errorMessage = 'Error removing watch list!';
    yield put(actions.deleteCompanyFromListFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* addCompanyToListSaga(
  action: PayloadAction<{
    selectedLists: { list_title: string; list_id: number | null }[];
    company_id: number;
    error_on_conflict: boolean;
  }>
) {
  const { company_id: companyId, selectedLists } = action.payload;
  const titles: string[] = selectedLists.map(item => item.list_title);

  const existingListIds: number[] = [];
  const newTitles: string[] = [];

  yield put(customGlobalLoaderActions.toggle({ loading: true, customText: '' }));

  selectedLists.flat().map(data => {
    if (data.list_id !== null) {
      return existingListIds.push(Number(data.list_id));
    }

    return newTitles.push(data.list_title.toString());
  });

  try {
    // @ts-ignore
    const res = yield call(UserService.bulkAddToWatchlist, [companyId], existingListIds, newTitles);

    if (res.data) {
      const failureListIds: number[] = [];
      const successListIds: number[] = [];

      res.data.map((item: { status: boolean; message: string; list_id: number }) => {
        if (item.status) {
          return successListIds.push(item.list_id);
        }

        return failureListIds.push(item.list_id);
      });

      if (failureListIds.length > 0) {
        const list: CompanyWatchList[] = yield select(
          favoriteListsSelectors.getWatchlists(failureListIds)
        );
        const titles: string[] = list.map((item: CompanyWatchList) => item.title);
        NotificationService.error(`Failed to add company to ${titles.join(', ')}`);
      }

      if (successListIds.length > 0) {
        const list: CompanyWatchList[] = yield select(
          favoriteListsSelectors.getWatchlists(successListIds)
        );

        const ownerName: string = yield select(userSelectors.getFullName);
        yield put(actions.addCompanyToListSuccess(companyId, list, ownerName));

        const titles: string[] = list.map((item: CompanyWatchList) => item.title);
        NotificationService.success(`Company was added to ${titles.join(', ')} list(s)!`);
      }
    } else {
      const errorMessage = res.data[0].message;

      yield put(actions.addCompanyToListFail(errorMessage));
      NotificationService.error(errorMessage);
    }

    yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
  } catch (error: any) {
    let errorMessage = '';

    if (error.response.status === 409) {
      errorMessage = 'Company is already part of this list';
    } else if (Object.values(error.response.data.errors)[0] === 'Company is not in Equity Touch') {
      errorMessage = Object.values(error.response.data.errors)[0] as string;
    } else {
      errorMessage = `Failed to add company to ${titles.join(', ')}!`;
    }

    yield put(actions.addCompanyToListFail(errorMessage));
    NotificationService.error(errorMessage);

    yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
  }
}

export function* saveSearchAsListSaga(action: PayloadAction<{ title: string; page?: number }>) {
  const { title, page = 1 } = action.payload;
  const pageAlias: RouteAliases | null = getRouteAlias();

  let searchKey: string;
  let sortBy: SortByRule<any>[];
  let filter: Dictionary<PreselectedFilter>;
  let filterQuery: Dictionary<string | Array<number | string>>;
  let getCompanyCount: number;

  // save search as list separate with homepage and my companies for filters
  if (pageAlias === 'myCompanies') {
    searchKey = yield select(getSearchKey('myCompanies'));
    sortBy = yield select(myCompaniesSorthSelectors.getSorting);
    getCompanyCount = yield select(searchCountSelectors.getSearchCount('myCompanies'));

    const selectedCard: SearchStateData = yield select(
      myCompaniesFilterCardsSelectors.getSelectedCard
    );
    const subFilter: string = yield select(myCompaniesFilterCardsSelectors.getSubFilter);

    if (subFilter) {
      // @ts-ignore
      filter = { ...selectedCard.filter, ...selectedCard.data[subFilter] };
    } else {
      filter = { ...selectedCard.filter };
    }

    // @ts-ignore
    filterQuery = filter;
  } else if (pageAlias === 'userLists' || pageAlias === 'sourceScrubLists') {
    const isSourceScrub: boolean = yield select(listsSelectors.isSourceScrubRoute);

    searchKey = yield select(getSearchKey('lists'));
    sortBy = yield select(getSortingListSearchSelectors);
    filter = yield select(filterSelectors.getFilter(isSourceScrub ? 'sslists' : 'watchlists'));
    getCompanyCount = yield select(
      searchCountSelectors.getSearchCount(isSourceScrub ? 'sslists' : 'watchlists')
    );

    const normalizedFilters: Dictionary<BaseFilter> = yield select(
      filterSourcesSelectors.getFiltersMap
    );

    filterQuery = mapFiltersToURLParams(normalizedFilters, filter);
  } else {
    searchKey = yield select(getSearchKey('advancedSearch'));
    sortBy = yield select(getSorting);
    filter = yield select(filterSelectors.getFilter('advancedSearch'));
    getCompanyCount = yield select(searchCountSelectors.getSearchCount('advancedSearch'));

    const normalizedFilters: Dictionary<BaseFilter> = yield select(
      filterSourcesSelectors.getFiltersMap
    );

    filterQuery = mapFiltersToURLParams(normalizedFilters, filter);
  }

  const userName: string = yield select(userSelectors.getFullName);
  const totalPageCount = Math.ceil(getCompanyCount / 1000);

  const searchData: SearchPayloadRequest = {
    searchKey,
    filter: filterQuery,
    pagination: { pageNumber: page, pageSize: SAVE_SEARCH_AS_LIST_LIMIT },
    sortBy,
  };

  try {
    const res: AxiosResponse<Dictionary<CompanyWatchList>> = yield call(
      SavedSearchesService.saveSearchAsList,
      title,
      searchData
    );
    const isCancel: boolean = yield select(favoriteListsSelectors.isCancel);

    res.data[Object.keys(res.data)[0]] = {
      ...res.data[Object.keys(res.data)[0]],
      owner_name: userName,
      origin: FAVORITE_LIST_OWNER,
    };

    const getKeys = Object.keys(res.data)[0];
    const getCount = res.data[getKeys].count;
    const value = res.data[getKeys];

    const isUnderLimit = getCount < SAVE_SEARCH_AS_LIST_LIMIT && page === 1;

    if (!isCancel) {
      if (res.data) {
        yield put(actions.saveTitle(''));

        if (totalPageCount !== page) {
          yield put(actions.updateCompleted(false));
          yield put(
            actions.updatePercentage(isUnderLimit ? Number(getCount) : Number(getCount) * page)
          );

          yield put(
            actions.saveSearchAsList({
              title,
              page: page + 1,
            })
          );
        } else {
          yield put(actions.updateCompleted(true));
          yield put(actions.updatePercentage(Number(getCompanyCount)));
          yield put(
            actions.saveSearchAsListSuccess({ [getKeys]: { ...value, count: getCompanyCount } })
          );
          NotificationService.success(`Create watchlist ${title} success!`);
        }
      }
    } else {
      yield put(actions.saveTitle(''));
      yield put(actions.updateCompleted(true));
      yield put(
        actions.updatePercentage(isUnderLimit ? Number(getCount) : SAVE_SEARCH_AS_LIST_LIMIT * page)
      );

      const message = `Create watchlist ${title} canceled!`;
      yield put(
        actions.saveSearchAsListSuccess({
          [getKeys]: {
            ...value,
            count: isUnderLimit ? Number(getCount) : SAVE_SEARCH_AS_LIST_LIMIT * page,
          },
        })
      );
      NotificationService.success(message);
    }
  } catch (e: any) {
    // save the title to reducer, create a new action
    yield put(actions.saveTitle(title));
    yield put(actions.updateCompleted(true));
    yield put(actions.updatePercentage(SAVE_SEARCH_AS_LIST_LIMIT * (page - 1)));
    const message = `Create watchlist ${title} failed!`;
    yield put(actions.saveSearchAsListFail(message));
    NotificationService.error(message);
  }
}

export function* tryAgainSaveSearchAsListsaga() {
  const title: string = yield select(favoriteListsSelectors.getTitle);
  const lastPercentage: number = yield select(favoriteListsSelectors.getPercentage);
  yield put(
    actions.saveSearchAsList({ title, page: lastPercentage / SAVE_SEARCH_AS_LIST_LIMIT + 1 })
  );
}

function* publicWatchListSaga(action: PayloadAction<PublicWatchListActionPayload>) {
  const { list_id: listId, is_public: isPublic } = action.payload;

  const payload = {
    list_id: listId as number,
    is_public: isPublic,
  };

  const errorMessage = isPublic ? 'Failed to make list not public!' : 'Failed to make list public!';

  try {
    const res: AxiosResponse<Array<PublicWatchListActionPayload>> = yield call(
      UserService.publicList,
      payload
    );

    if (res.data && res.data.length) {
      const { origin } = res.data[0];

      const successMessage = isPublic
        ? 'List published successfully!'
        : 'List made not public successfully!';

      yield put(
        actions.publicWatchListSuccess({
          list_id: listId as number,
          is_public: isPublic,
          origin: origin as string,
        })
      );

      NotificationService.success(successMessage);
    } else {
      yield put(actions.publicWatchListFail(errorMessage));

      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const message = getErrorMessage(error, errorMessage);

    yield put(actions.publicWatchListFail(message));

    NotificationService.error(message);
  }
}

export default function* favoriteListsSagas() {
  yield takeLatest(actions.initialFetchFavoritesLists, initialFetchFavoriteListsSaga);
  yield takeLatest(actions.fetchFavoriteLists, fetchFavoriteListsSaga);
  yield takeLatest(actions.sortFavoriteLists, sortFavoriteListsSaga);
  yield takeLatest(actions.createFavoriteList, createFavoriteListSaga);
  yield takeLatest(actions.deleteFavoriteList, deleteFavoriteListSaga);
  yield takeLatest(actions.renameFavoriteList, renameFavoriteListSaga);
  yield takeLatest(actions.deleteCompanyFromList, deleteCompanyFromListSaga);
  yield takeLatest(actions.removeSharedList, removeSharedListSaga);
  yield takeLatest(actions.addCompanyToList, addCompanyToListSaga);
  yield takeLatest(actions.saveSearchAsList, saveSearchAsListSaga);
  yield takeLatest(actions.tryAgainSaveSearchAsList, tryAgainSaveSearchAsListsaga);
  yield takeLatest(actions.favoriteListsPagination, favoriteListsPaginationSaga);
  yield takeLatest(actions.updateFavoriteListAssociation, updateFavoriteListAddonSaga);
  yield takeLatest(actions.publicWatchList, publicWatchListSaga);
  yield fork(companyListsSagas);
}
