import { createAction } from '@reduxjs/toolkit';

export function createFeatureActionType(featureName: string, actionName: string) {
  return `[${featureName}] ${actionName}`;
}

export function createFeatureAction<P = void, T extends string = string>(
  featureName: string,
  type: T
) {
  return createAction<P, T>(createFeatureActionType(featureName, type) as T);
}
