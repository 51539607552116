import { useState, useLayoutEffect } from 'react';

const useHeaderHeight = (searchHeaderHeight: number) => {
  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    const header = document.querySelector('#mainHeader');

    function updateHeight() {
      const headerHeight = header?.clientHeight! + searchHeaderHeight;
      if (headerHeight) setHeight(headerHeight);
    }

    window.addEventListener('resize', updateHeight);
    window.addEventListener('scroll', updateHeight);
    updateHeight();

    return () => {
      window.removeEventListener('resize', updateHeight);
      window.removeEventListener('scroll', updateHeight);
    };
  }, [searchHeaderHeight]);

  return height;
};

export default useHeaderHeight;
