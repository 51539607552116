import { takeLatest, call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { CompanyTouchesResponse } from '@optx/models/company/CompanyTouch';
import * as actions from './actions';
import { CompanyService } from '../../../../services/api';
import NotificationService from '../../../../services/NotificationService';
import { SearchTouchesPayload } from './interfaces';

export function* fetchCompanyTouchesSaga(
  action: PayloadAction<SearchTouchesPayload, any, boolean>
) {
  const searchPayload = action.payload;

  try {
    const res: AxiosResponse<CompanyTouchesResponse> = yield call(
      CompanyService.fetchTouches,
      searchPayload
    );
    yield put(actions.fetchTouchesSuccess(res.data, searchPayload));
  } catch (e: any) {
    const errorMessage = 'Error fetch company touches!';
    yield put(actions.fetchTouchesFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* companyTouchesSaga() {
  yield takeLatest(actions.fetchTouches.type, fetchCompanyTouchesSaga);
}
