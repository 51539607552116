import React, { useState, useEffect } from 'react';
// models
import { SelectOption } from '@optx/models/Option';
import { Company } from '@optx/models/Company';
// components
import SingleSelect from '@optx/components/common/select/SingleSelect';
import EditPopover from '@optx/components/common/popover/EditPopover';
// hooks
import { useHistoryTab } from '@optx/common/hooks/history';
import useSectorEditableField from '../../hooks/useSectorEditableField';
// styles
import Styled from '../CellPopover.styled';

type EditableTextProps = {
  company: Company;
  hidePenIcon?: boolean;
  labelValue?: string;
  currentValue?: string | null;
  lastTemporaryFieldValue?: string;
  getPopupContainer?: ((triggerNode: HTMLElement) => HTMLElement) | undefined;
  fieldChanged?: string;
};

const EditSectorProfile: React.FC<EditableTextProps> = ({
  company,
  hidePenIcon,
  labelValue,
  currentValue,
  getPopupContainer,
  fieldChanged,
  lastTemporaryFieldValue,
}) => {
  const { updateHistoryFields } = useHistoryTab();
  const { initialSelectValue, addCompanySector, selectOptions } = useSectorEditableField(company);
  const value = currentValue !== undefined ? currentValue : company.sector;
  const [closePopup, setClosePopup] = useState(false);
  const [selectedValue, setSelectedValue] = useState(initialSelectValue);
  const [label, setLabel] = useState(value || '-');
  const title = 'Edit "Sector" value';

  useEffect(() => {
    if (labelValue !== undefined) {
      setLabel(labelValue);
    }
  }, [labelValue]);

  useEffect(() => {
    if (currentValue !== undefined) {
      setSelectedValue(selectOptions.filter(option => currentValue === option.label));
    } else {
      setLabel(company.sector ? company.sector : '-');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue, company.sector]);

  const content = (
    <Styled.CellPopover>
      <SingleSelect
        options={selectOptions}
        onChange={option => {
          setSelectedValue(option as SelectOption);
          addCompanySector(
            company.company_id,
            (option as SelectOption).value,
            (option as SelectOption).label
          );
          !labelValue &&
            setLabel(
              (option as SelectOption).label === 'None' ? '-' : (option as SelectOption).label
            );
          fieldChanged &&
            lastTemporaryFieldValue &&
            updateHistoryFields({
              afterValue: (option as SelectOption).label,
              beforeValue: lastTemporaryFieldValue,
              fieldChanged,
            });
          setClosePopup(true);
        }}
        value={selectedValue}
        closeMenuOnSelect
      />
    </Styled.CellPopover>
  );

  return (
    <EditPopover
      content={content}
      title={title}
      closePopup={closePopup}
      setClosePopup={setClosePopup}
      hidePenIcon={hidePenIcon}
      getPopupContainer={getPopupContainer}
    >
      {label}
    </EditPopover>
  );
};

export default React.memo(EditSectorProfile);
