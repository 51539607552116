import { createFeatureActionType } from '@optx/utils/feature';
import { featureName } from '../../featureName';

export const FETCH_USER_LISTS_SEARCH_AUTOCOMPLETE = createFeatureActionType(
  featureName,
  'FETCH_USER_LISTS_SEARCH_AUTOCOMPLETE'
);
export const FETCH_USER_LISTS_SEARCH_AUTOCOMPLETE_FAILURE = createFeatureActionType(
  featureName,
  'FETCH_USER_LISTS_SEARCH_AUTOCOMPLETE_FAILURE'
);
export const FETCH_USER_LISTS_SEARCH_AUTOCOMPLETE_SUCCESS = createFeatureActionType(
  featureName,
  'FETCH_USER_LISTS_SEARCH_AUTOCOMPLETE_SUCCESS'
);
