import React, { useCallback } from 'react';
import Overflow from 'rc-overflow';
import styled from 'styled-components';
import { Tag } from 'antd';
import { TagProps } from 'antd/lib/tag';
import { CloseOutlined } from '@ant-design/icons';
// models
import { SpecialFilterKey } from '@optx/models/filters';
import { TagOption } from '@optx/models/tags';
// hooks
import useFilterSearch from '@hooks/filters/useSpecialFilter';
// redux
import { useDispatch } from 'react-redux';
import { actions as tagsActions } from '@redux/company/tags';

const StyledTag = styled(Tag)`
  cursor: pointer;
`;

interface CompanyTagValueProps extends TagProps {
  item: TagOption;
  companyId?: number;
  isNewTab?: boolean;
}

const CompanyTagValue: React.FC<CompanyTagValueProps> = ({
  item,
  companyId = 0,
  isNewTab,
  ...props
}) => {
  const dispatch = useDispatch();

  const { loadCompaniesByFilter } = useFilterSearch('company_tag' as SpecialFilterKey);

  // when a tag is clicked
  const handleTagClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    loadCompaniesByFilter(event.currentTarget.innerText, isNewTab);
  };

  const deleteTag = useCallback(
    event => {
      event.stopPropagation();
      dispatch(
        tagsActions.deleteCompanyTag({
          companyId,
          id: Number.parseInt(item!.value as string),
          label: item!.label,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  return (
    <Overflow.Item component="span">
      <StyledTag color="blue" onClick={handleTagClick} {...props} className={item.className}>
        {item.label}
        {item.closable && <CloseOutlined onClick={deleteTag} />}
      </StyledTag>
    </Overflow.Item>
  );
};

export default CompanyTagValue;
