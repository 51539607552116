import { createAction } from '@reduxjs/toolkit';
import { SocialMediaChartData } from '@optx/models/charts/data';
import * as types from './types';

// fetch company touches actions
export const fetchCrunchbaseData = createAction<number>(types.fetchCrunchbaseData.REQUEST);
export const fetchCrunchbaseDataSuccess = createAction<SocialMediaChartData>(
  types.fetchCrunchbaseData.SUCCESS
);

export const fetchCrunchbaseDataFail = createAction<string>(types.fetchCrunchbaseData.FAILURE);
