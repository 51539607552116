import React from 'react';
import { Menu, MenuProps } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
// redux
import { selectors as searchSelectors } from '@redux/company/search/search';
import {
  selectors as myCompaniesSortSelectors,
  actions as myCompaniesSortActions,
} from '@redux/my-companies/sort';
import { actions as sortActions } from '@redux/company/search/sort';
import { actions as userListSortActions } from '@redux/lists/search/sort';
import { selectors as userListSearchSelectors } from '@redux/lists/search/search';
import { selectors as companyOutReachSearchSelectors } from '@redux/company-outreach/search';
import { actions as companyOutReachSortActions } from '@redux/company-outreach/sort';

interface ClearAllSortingMenuItemProps extends MenuItemProps {
  gridName: 'advancedSearch' | 'myCompanies' | 'userLists' | 'companyOutReach';
}

const ClearAllSortingMenuItem = ({
  onClick,
  gridName,
  ...restProps
}: ClearAllSortingMenuItemProps) => {
  const sortingSelectors = {
    advancedSearch: searchSelectors,
    myCompanies: myCompaniesSortSelectors,
    userLists: userListSearchSelectors,
    companyOutReach: companyOutReachSearchSelectors,
  };

  const sortingActions = {
    advancedSearch: sortActions,
    myCompanies: myCompaniesSortActions,
    userLists: userListSortActions,
    companyOutReach: companyOutReachSortActions,
  };

  const dispatch = useDispatch();
  const sorting = useSelector(sortingSelectors[gridName].getSorting);

  const clearSort = () => dispatch(sortingActions[gridName].resetSortAction());

  if (!sorting.length) {
    return null;
  }

  const handleClick: MenuProps['onClick'] = info => {
    onClick && onClick(info);
    clearSort();
  };

  return (
    <Menu.Item onClick={handleClick} {...restProps}>
      Clear All Sorting
    </Menu.Item>
  );
};

export default ClearAllSortingMenuItem;
