import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { Dictionary } from 'lodash';
import { useDispatch } from 'react-redux';
// models
import { BaseFilter, Filter, FilterSource } from '@optx/models/filters';
import { SelectOption } from '@optx/models/Option';
// redux
import { actions } from '@redux/geo-range';
// hooks
import { useInjectGeoRange } from '@optx/common/hooks/inject';
// components
import FilterGroup from '../FilterGroup';

interface FilterGeoRangeProps {
  source: FilterSource<Filter<any>>;
  filter: BaseFilter<any>;
}

const FilterGeoRange: React.FC<FilterGeoRangeProps> = ({ source, filter }) => {
  useInjectGeoRange();
  const dispatch = useDispatch();

  const { values: formValues } = useFormikContext<Dictionary<Array<SelectOption>>>();

  const handleOnGeoRangeChange = useCallback(
    (zipCode: string, geoCountry: string) => dispatch(actions.setGeoRange(zipCode, geoCountry)),
    [dispatch]
  );

  const handleChange = () => {
    // Validate georange if postal code is outside range.
    if (
      formValues.geo_range[0].value &&
      formValues.geo_country &&
      (formValues.geo_country as unknown as SelectOption<string>).value
    ) {
      const zipCode = formValues.geo_range[0].value;
      const geoCountry = (formValues.geo_country as unknown as SelectOption<string>).value;

      handleOnGeoRangeChange(zipCode, geoCountry);
    }
  };

  // Changing the default onChange to call the geo_range endpoint as well
  const geoRangeFilter: BaseFilter = {
    ...filter,
    groupItems: filter.groupItems
      ? filter.groupItems.map(item => {
          if (item.column === 'geo_range' || item.column === 'geo_country') {
            return { ...item, onChange: handleChange };
          }

          return item;
        })
      : [],
  };

  return <FilterGroup filter={geoRangeFilter} source={source} />;
};

export default FilterGeoRange;
