import { ChartData } from '@optx/models/charts/data';
import {
  FetchCompanyEmployeeGrowthSuccessAction,
  FetchCompanyEmployeeGrowthAction,
  FetchCompanyEmployeeGrowthFailAction,
} from './interfaces';
import * as types from './actionTypes';

export const fetchCompanyEmployeeGrowth = (
  companyId: number,
  callback: (res: ChartData) => void
): FetchCompanyEmployeeGrowthAction => ({
  type: types.FETCH_EMPLOYEE_GROWTH,
  payload: companyId,
  meta: callback,
});

export const fetchCompanyEmployeeGrowthSuccess = (
  employeeGrowth: ChartData
): FetchCompanyEmployeeGrowthSuccessAction => ({
  type: types.FETCH_EMPLOYEE_GROWTH_SUCCESS,
  payload: employeeGrowth,
});

export const fetchCompanyEmployeeGrowthFail = (
  error: string
): FetchCompanyEmployeeGrowthFailAction => ({
  type: types.FETCH_EMPLOYEE_GROWTH_FAIL,
  payload: error,
});
