import React from 'react';
import { Dropdown, Button, Tooltip } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import SearchMenu from './SearchMenu';

interface CompanySearchDropdownProps {
  isAddonManagement?: boolean;
}

const CompanySearchDropdown: React.FC<CompanySearchDropdownProps> = ({
  children,
  isAddonManagement,
}) => {
  const [visible, setVisible] = React.useState(false);

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
  };

  return (
    <Tooltip title="Export">
      <Dropdown
        overlay={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <SearchMenu isAddonManagement={isAddonManagement} hideDropdown={() => setVisible(false)}>
            {children}
          </SearchMenu>
        }
        overlayStyle={{ position: 'fixed' }}
        trigger={['click']}
        visible={visible}
        placement="bottomRight"
        onVisibleChange={handleVisibleChange}
      >
        <Button
          type="text"
          onClick={() => setVisible(!visible)}
          icon={<EllipsisOutlined style={{ fontSize: 28 }} />}
        />
      </Dropdown>
    </Tooltip>
  );
};

export default CompanySearchDropdown;
