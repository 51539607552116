import React from 'react';
import { Switch, Typography, Space, Tooltip } from 'antd';
import { SwitchProps } from 'antd/lib/switch';

interface FiltersRefreshProps extends SwitchProps {
  // Check need to be controlled.
  checked: boolean;
  renderLabel?: (checked: boolean) => React.ReactNode;
}

const RenderLabel = (checked: boolean) => {
  return <Typography.Text>Auto Refresh {checked ? 'On' : 'Off'}</Typography.Text>;
};

export const HistogramRefreshToggler: React.FC<FiltersRefreshProps> = ({
  checked,
  title,
  renderLabel,
  ...restProps
}) => {
  return (
    <div>
      <Tooltip title={title}>
        <Space align="center" size="small">
          <Switch checked={checked} {...restProps} />
          {renderLabel && renderLabel(checked)}
        </Space>
      </Tooltip>
    </div>
  );
};

HistogramRefreshToggler.defaultProps = {
  title: 'Auto refresh filters',
  renderLabel: RenderLabel,
};
