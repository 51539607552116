import { createReducer, CaseReducer, PayloadAction, Dictionary } from '@reduxjs/toolkit';
import { values, find, findKey } from 'lodash';
// models
import { FilterSource, Filter, PreselectedFilter } from '@models/filters';
import { PageInfo } from '@models/table/Pagination';
import { SearchSaveResponse, SearchSave } from '@models/search';
import { CustomUIViewIds, UserInformation } from '@optx/models/user';
import { SortByRule } from '@models/table/sorting';
import { UpdateUserSettingsPayload } from '@optx/models/api/user';
// constants
import { customUIViewIds, customUIViews } from '@optx/constants/table/columnDisplay/company-search';
// utils
import { parseSorting } from '@optx/utils/filters/parseSorting';
// redux
import { actions as userInformationActions } from '@redux/user/information';
import { ContactsSavedSearchesState } from '@redux/feature/saved-search/interfaces';
import * as actions from './actions';
import {
  fetchReducer,
  fetchFailReducer,
  fetchSuccessLoadingReducer,
} from '../../feature/fetch/reducers';
import {
  initialState,
  fetchSavedSearchesReducer,
  fetchSavedSearchesSuccessReducer,
  saveSearchSuccessReducer,
  deleteSearchSuccessReducer,
  renameSearchSuccessReducer,
  mapSearchSave,
} from '../../feature/saved-search/reducers';
import { actions as combinedSearchActions } from '@features/combine-search/state';
import { actions as searchActions } from '@features/grid/search';

const initializeSavedSearchesAndViewsSuccessReducer: CaseReducer<
  ContactsSavedSearchesState,
  PayloadAction<
    Array<SearchSaveResponse> | Dictionary<SearchSaveResponse>,
    any,
    {
      filterSources: Array<FilterSource<Filter<any>>>;
      clearedFilters: Dictionary<PreselectedFilter>;
      total?: number;
      query?: string;
      pagination?: PageInfo;
      sortBy?: Array<SortByRule<any>>;
      defaultCustomUIView?: CustomUIViewIds;
    }
  >
> = (draftState, action) => {
  const decodedPayload = (action.payload as Array<SearchSaveResponse>).map(savedSearch => ({
    ...savedSearch,
    search_criteria: decodeURIComponent(savedSearch.search_criteria),
    ...(savedSearch.search_info && {
      search_info: {
        ...savedSearch.search_info,
        filters: savedSearch.search_info?.filters.map(filter => decodeURIComponent(filter)) || [],
      },
    }),
  }));

  // for saved searches table we have pagination and search functionality, but for views
  // we need to display all of them. so, make an initial call for all saved searches,
  // no pagination included, and store views and save searches in two separate places.
  // we need them separated, because of the search functionality

  // include custom ui views in store and set custom view as default, if any
  const customViews = customUIViewIds.map(id => {
    if (customUIViewIds.includes(action.meta.defaultCustomUIView as CustomUIViewIds)) {
      return { ...customUIViews[id], is_default: true };
    }

    return customUIViews[id];
  });
  const allSavedSearches = decodedPayload.concat(customViews);
  const { filterSources, clearedFilters, total, query, pagination, sortBy } = action.meta;
  const savedSearches = allSavedSearches.slice(0, pagination?.pageSize || 50);

  fetchSavedSearchesSuccessReducer(draftState, {
    payload: savedSearches,
    meta: { filterSources, clearedFilters, total, query, pagination, sortBy },
    type: '',
  });

  draftState.viewIds = [];

  values(allSavedSearches).forEach(search => {
    const { unique_id: id } = search;
    draftState.viewIds.push(id);

    draftState.views[id] = mapSearchSave(search, clearedFilters, filterSources);
  });
};

const makeFiltersDefaultSuccessReducer: CaseReducer<
  ContactsSavedSearchesState,
  PayloadAction<number>
> = (draftState, action) => {
  const id = action.payload;

  if (typeof id === 'number') {
    const currentDefaultId = findKey(draftState.views, search => search.is_default);

    if (currentDefaultId) {
      draftState.views[currentDefaultId].is_default = false;

      if (draftState.byId[currentDefaultId]) {
        draftState.byId[currentDefaultId].is_default = false;
      }
    }

    if (typeof currentDefaultId === 'number' && id !== currentDefaultId) {
      draftState.views[id].is_default = true;

      if (draftState.byId[id]) {
        draftState.byId[id].is_default = false;
      }
    }
  }
};

const modifyViewSuccessReducer: CaseReducer<
  ContactsSavedSearchesState,
  PayloadAction<
    SearchSaveResponse,
    any,
    {
      filterSources: Array<FilterSource<Filter<any>>>;
      clearedFilters: Dictionary<PreselectedFilter>;
    }
  >
> = (draftState, action) => {
  const {
    payload: searchSaveResponse,
    meta: { filterSources, clearedFilters },
  } = action;
  const modifiedView = { ...searchSaveResponse };
  const modifiedViewId = modifiedView.unique_id;
  const currentDefaultView = find(draftState.views, view => view.is_default);

  if (currentDefaultView) {
    if (
      modifiedViewId !== currentDefaultView.unique_id &&
      modifiedView.is_default &&
      currentDefaultView.is_default
    ) {
      draftState.views[currentDefaultView.unique_id].is_default = false;

      if (draftState.byId[currentDefaultView.unique_id]) {
        draftState.byId[currentDefaultView.unique_id].is_default = false;
      }
    }
  }

  if (draftState.byId[modifiedViewId]) {
    draftState.byId[modifiedViewId] = mapSearchSave(modifiedView, clearedFilters, filterSources);
  }

  draftState.views[modifiedViewId] = mapSearchSave(modifiedView, clearedFilters, filterSources);
};

const modifyCustomUIViewSuccessReducer: CaseReducer<
  ContactsSavedSearchesState,
  PayloadAction<{ view?: SearchSave; customUIViewId?: CustomUIViewIds; isDefault?: boolean }>
> = (draftState, action) => {
  const { view, customUIViewId, isDefault } = action.payload;

  if (view) {
    const modifiedViewId = view.unique_id;

    if (draftState.byId[modifiedViewId]) {
      draftState.byId[modifiedViewId] = view;
    }

    draftState.views[modifiedViewId] = view;
  }

  if (customUIViewId) {
    draftState.views[customUIViewId].is_default = !!isDefault;
    draftState.byId[customUIViewId].is_default = !!isDefault;
  }
};

const updateSavedSearchesReducer: CaseReducer<
  ContactsSavedSearchesState,
  PayloadAction<{ shareWith: Array<number>; listId: number }>
> = (draftState, action) => {
  const { shareWith, listId } = action.payload;
  const sharedList: number[] = [...(draftState.byId[listId]?.shared_with || [])];

  shareWith.forEach(item => {
    const alreadySelectedUserIndex = sharedList.indexOf(item);

    if (alreadySelectedUserIndex === -1) {
      sharedList.push(item);
    }
  });

  draftState.byId[listId].shared_with = sharedList;
};

// external actions reducers
const fetchUserInformationSuccessReducer: CaseReducer<
  ContactsSavedSearchesState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const sorting = action.payload.settings.session_settings?.saved_company_serches_sorting;
  const pageNumber = action.payload.settings.session_settings?.saved_company_serches_page_number;

  if (sorting) {
    const { sortBy } = parseSorting(sorting);
    draftState.sortBy = sortBy;
  }

  if (pageNumber) {
    draftState.pageNumber = pageNumber;
  }
};

const updateUserSettingsSuccessReducer: CaseReducer<
  ContactsSavedSearchesState,
  PayloadAction<Partial<UpdateUserSettingsPayload>>
> = (draftState, action) => {
  const { default_view: defaultViewId } = action.payload;

  if (defaultViewId) {
    draftState.byId[defaultViewId].is_default = true;
    draftState.views[defaultViewId].is_default = true;
  } else if (defaultViewId === '') {
    const defaultId = draftState.allIds.find(
      id => customUIViewIds.includes(id as CustomUIViewIds) && draftState.byId[id].is_default
    );

    if (defaultId) {
      draftState.byId[defaultId].is_default = false;
      draftState.views[defaultId].is_default = false;
    }
  }
};

const savedCompanySearchPaginationReducer: CaseReducer<ContactsSavedSearchesState> = draftState => {
  draftState.loading = true;
};

const loadSavedSearchReducer: CaseReducer<ContactsSavedSearchesState> = draftState => {
  draftState.shouldFetch = true;
};

const reducer = createReducer(initialState, builder =>
  builder
    // fetch contacts saved searches
    .addCase(actions.initializeSavedSearchesAndViews, fetchSavedSearchesReducer)
    .addCase(
      actions.initializeSavedSearchesAndViewsSuccess,
      initializeSavedSearchesAndViewsSuccessReducer
    )
    .addCase(actions.fetchSavedSearches, fetchSavedSearchesReducer)
    .addCase(actions.fetchSavedSearchesSuccess, fetchSavedSearchesSuccessReducer)
    .addCase(actions.fetchSavedSearchesFail, fetchFailReducer)
    // save
    .addCase(actions.saveSearchSuccess, saveSearchSuccessReducer)
    .addCase(actions.saveSearchFail, fetchFailReducer)
    // delete search
    .addCase(actions.deleteSearch, fetchReducer)
    .addCase(actions.deleteSearchSuccess, deleteSearchSuccessReducer)
    .addCase(actions.deleteSearchFail, fetchFailReducer)
    // rename search
    .addCase(actions.renameSearch, fetchReducer)
    .addCase(actions.renameSearchSuccess, renameSearchSuccessReducer)
    .addCase(actions.renameSearchFail, fetchFailReducer)
    // view specific actions
    .addCase(actions.makeFiltersDefaultSuccess, makeFiltersDefaultSuccessReducer)
    .addCase(actions.modifyViewSuccess, modifyViewSuccessReducer)
    .addCase(actions.modifyCustomUIViewSuccess, modifyCustomUIViewSuccessReducer)
    // update search
    .addCase(actions.updateSavedSearches, updateSavedSearchesReducer)
    // external
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
    .addCase(userInformationActions.updateUserSettingsSuccess, updateUserSettingsSuccessReducer)
    // pagination
    .addCase(actions.savedCompanySearchPagination, savedCompanySearchPaginationReducer)
    // sort
    .addCase(actions.sortCompanySearchesLists, fetchReducer)
    // combine
    .addCase(combinedSearchActions.combineSearch, fetchReducer)
    .addCase(combinedSearchActions.combineSearchFail, fetchSuccessLoadingReducer)
    // load saved search
    .addCase(searchActions.loadSavedSearch, loadSavedSearchReducer)
);

export default reducer;
