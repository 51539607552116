import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Form, Input } from 'formik-antd';
import { Modal, Button, Row } from 'antd';
import * as yup from 'yup';
// redux
import { actions } from '@redux/user/reset-password';
// models
import { SuccessErrorCallback } from '@optx/models/callback';
import { OverlayLoader } from '@components/common/loader';
// styles
import { Styled } from '../../UserProfile.styled';

interface ChangePasswordValues {
  currentPassword: string;
  newPassword: string;
  newRepeatPassword: string;
}

const validationSchema = yup.object<ChangePasswordValues>({
  currentPassword: yup.string().required('Current Password is a required field'),
  newPassword: yup
    .string()
    .required('New Password is a required field')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/(?=.*[!@#$%^&*])/, 'Password must contain at least one special character.'),
  newRepeatPassword: yup
    .string()
    .required('Confirm New Password is a required field')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

const initialValues: ChangePasswordValues = {
  currentPassword: '',
  newPassword: '',
  newRepeatPassword: '',
};

interface ChangePasswordModalProps {
  isOpen: boolean;
  toggle: VoidFunction;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ isOpen, toggle }) => {
  const dispatch = useDispatch();
  const formRef = useRef<FormikProps<ChangePasswordValues> | null>(null);

  const resetPassword = (password: string, newPassword: string, callback: SuccessErrorCallback) =>
    dispatch(actions.resetPassword(password, newPassword, callback));
  // local state
  const [serverError, setServerError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleToggle = () => {
    setServerError('');
    toggle();

    if (formRef.current) {
      formRef.current.resetForm();
    }
  };

  const handleSubmit = (
    values: ChangePasswordValues,
    actions: FormikHelpers<ChangePasswordValues>
  ) => {
    const callback: SuccessErrorCallback = (success, error) => {
      setLoading(false);
      actions.setSubmitting(false);

      if (error) {
        setServerError(error);
      } else {
        toggle();
      }
    };

    setLoading(true);
    setServerError('');
    resetPassword(values.currentPassword, values.newPassword, callback);

    if (formRef.current) {
      formRef.current.resetForm();
    }
  };

  const footerModal = (isValid: boolean) => {
    return (
      <Row justify="end">
        <Styled.StyledButton type="primary" htmlType="submit" disabled={isValid}>
          Change Password
        </Styled.StyledButton>
        <Button onClick={handleToggle}>Cancel</Button>
      </Row>
    );
  };

  return (
    <Modal visible={isOpen} footer={footerModal} title="Password" onCancel={handleToggle}>
      <OverlayLoader loading={loading} />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        innerRef={instance => {
          formRef.current = instance;
        }}
      >
        {props => (
          <Form layout="vertical">
            <Form.Item name="currentPassword" label="Current Password">
              <Input.Password id="currentPassword" name="currentPassword" />
            </Form.Item>
            <Form.Item
              name="newPassword"
              label="New Password"
              extra="Include at least 8 characters with, both upper and lower case letters"
            >
              <Input.Password id="newPassword" name="newPassword" />
            </Form.Item>
            <Form.Item name="newRepeatPassword" label="Confirm New Password">
              <Input.Password id="newRepeatPassword" name="newRepeatPassword" />
            </Form.Item>
            {serverError && <div className="text-danger">{serverError}</div>}
            {footerModal(!props.isValid)}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ChangePasswordModal;
