import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import { actions as scrollActions, selectors as scrollSelectors } from '../state';

interface VirtualScrollInfo {
  left: number;
  top: number;
  isEnd: boolean;
}

type OnVirtualScrollCallback = (scrollInfo: VirtualScrollInfo) => void;

/**
 * Move the external scroll when the table is scrolled horizontally
 */
const handleExternalScroll: OnVirtualScrollCallback = scrollInfo => {
  const wrapper = document.querySelector('.external-scroll-wrapper');
  if (!wrapper) return;

  wrapper.scrollTo(scrollInfo.left, wrapper.scrollTop);
};

/**
 * Use Scroll Position for virtualized grid.
 * @param pageKey
 * This hook saves the scroll position of a window or that of a scrolled table
 * and sets the scroll position when navigating from a different page.
 */
export const useVirtualScrollPosition = (pageKey: string) => {
  const dispatch = useDispatch();
  const initialScrollPosition = useSelector((state: any) =>
    scrollSelectors.getScrollPosition(state, pageKey)
  );
  const scrollRef = useRef(initialScrollPosition);

  const onScroll: OnVirtualScrollCallback = scrollInfo => {
    scrollRef.current = scrollInfo.top;

    handleExternalScroll(scrollInfo);
  };

  useEffect(() => {
    return () => {
      // If component unmounts but scroll wasn't changed don't update store.
      if (initialScrollPosition !== scrollRef.current) {
        dispatch(scrollActions.saveScrollPosition(pageKey, { scrollPosition: scrollRef.current }));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    /**
     * IMPORTANT: keep scroll 1 if initialScrollPosition is 0,
     * otherwise when comming form other page it will not display all virtual items.
     */
    initialScrollPosition: initialScrollPosition || 1,
    onScroll,
  };
};
