import styled from 'styled-components';
import { Table } from 'antd';
import { TableProps } from 'antd/es/table';
import { CompanyImportFailedData } from '@optx/models/Company';

const CustomTable = styled<React.FC<TableProps<CompanyImportFailedData>>>(Table)`
  .ant-table-body {
    max-height: calc(45vh - 100px) !important;
  }
`;

export default {
  CustomTable,
};
