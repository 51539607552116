import styled from 'styled-components';
import { Modal } from 'antd';

const ModalWrapper = styled(Modal)`
  .ant-modal-header {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .ant-modal-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .btn--open-company-tag {
    margin-right: 30px;
  }

  .ant-modal-close {
    top: 4px;
  }

  .ant-modal-body {
    div:nth-child(1) {
      margin-bottom: 20px;
    }

    div:nth-child(2) {
      margin-bottom: 10px;
    }
  }
`;

export const Styled = {
  ModalWrapper,
};
