import { Space } from 'antd';
import styled from 'styled-components';

const Spacer = styled(Space)`
  margin-left: auto;
  width: 100%;
  justifycontent: right;

  .rc-overflow {
    right: 0;
  }
`;

export const Styled = {
  Spacer,
};
