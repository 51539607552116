import React from 'react';
import { Tag } from 'antd';

interface CompanyTagMoreProps {
  count: number;
  isNewTab?: boolean;
  onTagMoreClick?: VoidFunction;
}

const CompanyTagMore: React.FC<CompanyTagMoreProps> = ({ count, isNewTab, onTagMoreClick }) => {
  return (
    <Tag
      color="blue"
      style={{ cursor: isNewTab ? 'default' : 'pointer' }}
      onClick={onTagMoreClick && onTagMoreClick}
    >
      {`${count} more ${count === 1 ? 'tag' : 'tags'}`}
    </Tag>
  );
};

export default CompanyTagMore;
