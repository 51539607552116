import { useMemo } from 'react';
import { useSelector } from 'react-redux';
// utils
import { computePercent } from '@optx/utils/number';
// redux
import { selectors } from '@features/analyst-home/state';
// components
import { LineSkeleton } from '../../Skeleton';
import Styles from './StatsCompare.styles';

interface StatsCompareProps {
  title: string;
  values: number[];
  handleRedirect: (title: string) => void;
  extraData?: React.ReactNode;
}

const getFilledPercentages = (firstValue: number, secondValue: number) => {
  let firstValueProgress = 100;
  let secondValueProgress = 100;

  // Compute smallest value percent.
  if (firstValue > secondValue) {
    secondValueProgress = computePercent(secondValue, firstValue);
  } else if (firstValue < secondValue) {
    firstValueProgress = computePercent(firstValue, secondValue);
  } else if (firstValue === 0) {
    // If both are equal to zero then we don't fill percentage.
    firstValueProgress = 0;
    secondValueProgress = 0;
  }

  return [firstValueProgress, secondValueProgress];
};

const StatsCompare: React.FC<StatsCompareProps> = ({
  title,
  values,
  handleRedirect,
  extraData,
}) => {
  const [mainValue, comparedValue] = values;
  const [mainfilledProgress, comparedfilledProgress] = useMemo(
    () => getFilledPercentages(mainValue, comparedValue),
    [comparedValue, mainValue]
  );
  const loading = useSelector(selectors.getTouchInsightsLoading);

  if (loading) {
    return (
      <Styles.Wrapper>
        <LineSkeleton margin={[4, 0, 4, 0]} />
        <LineSkeleton margin={[4, 0, 4, 0]} />

        {Array.from({ length: 3 }).map((_, index) => (
          <LineSkeleton
            key={index}
            width={'60px'}
            margin={[15, index === 2 ? 0 : 5, 0, index === 0 ? 0 : 5]}
            isCenter={false}
          />
        ))}
      </Styles.Wrapper>
    );
  }

  return (
    <Styles.Wrapper>
      <Styles.Title>{title}</Styles.Title>
      <Styles.MainStatValue>
        <span onClick={() => handleRedirect(title)}>{mainValue}</span> / {comparedValue}
      </Styles.MainStatValue>
      <Styles.MainStatProgress size="small" percent={mainfilledProgress} showInfo={false} />
      <Styles.ComparedProgress size="small" percent={comparedfilledProgress} showInfo={false} />
      {extraData && <Styles.ExtraData>{extraData}</Styles.ExtraData>}
    </Styles.Wrapper>
  );
};

export default StatsCompare;
