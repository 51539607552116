import { DatePicker, Radio } from 'antd';
import styled from 'styled-components';

const RangePicker = styled(DatePicker.RangePicker)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding: 0;
  border: 0;
`;

const RadioButton = styled(Radio.Button)`
  position: relative;
`;

export default {
  RangePicker,
  RadioButton,
};
