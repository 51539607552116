import { createSelector } from 'reselect';
import { featureName } from '../featureName';
import { CompanyMergesState } from './interfaces';

const getCompanyMergesState = (state: Record<typeof featureName, CompanyMergesState>) =>
  state[featureName];

export const shouldInitialFetch = createSelector(
  getCompanyMergesState,
  state => !state.fetchedAt && !state.loading
);

export const isLoading = createSelector(getCompanyMergesState, state => state.loading);

export const getError = createSelector(getCompanyMergesState, state => state.error);

export const getCompanyMerges = createSelector(getCompanyMergesState, state => state.data);
export const getCompanyMergesPagination = createSelector(
  getCompanyMergesState,
  state => state.pagination
);
export const getCompanyMergesCount = createSelector(getCompanyMergesState, state => state.count);

// unmerge
export const getUnmergeModalVisible = createSelector(
  getCompanyMergesState,
  state => state.unmergeModalVisible
);
export const getUnmergeLoading = createSelector(
  getCompanyMergesState,
  state => state.unmergeLoading
);
export const getUnmergeCompleted = createSelector(
  getCompanyMergesState,
  state => state.unmergeCompleted
);
export const getSelectedCompanies = createSelector(
  getCompanyMergesState,
  state => state.selectedCompanies
);
export const getShouldCopyData = createSelector(
  getCompanyMergesState,
  state => state.shouldCopyData
);
export const getUnmergedCompanyId = createSelector(
  getCompanyMergesState,
  state => state.unmergedCompanyId
);
export const getUnmergeError = createSelector(getCompanyMergesState, state => state.unmergeError);
export const getHasMore = createSelector(getCompanyMergesState, state => state.hasMore);
