import styled from 'styled-components';

const CellPopoverMultiselect = styled.div`
  min-width: 300px;
  .singleselect-wrapper {
    min-width: 300px;
  }
`;

export default {
  CellPopoverMultiselect,
};
