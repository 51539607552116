import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// models
import { AppState } from '@optx/redux/interfaces';
// redux
import {
  selectors as companyListsSelectors,
  actions as companyListsActions,
} from '@redux/favorite-lists/company-lists';
//hooks
import { useIsChromePlugin } from '../useIsChromePlugin';

/**
 * Initialize watch lists inclusing company.
 * @param companyId company identifier.
 * @param shouldInitialize if should initialize to allow postponing call until a rule is accomplished.
 */
const useInitWatchListsIncludingCompany = (
  companyId: number,
  shouldInitialize: boolean = false
) => {
  const dispatch = useDispatch();
  const { isChromePlugin } = useIsChromePlugin();

  const listsIncludingCompany = useSelector((state: AppState) =>
    companyListsSelectors.getCompanyLists(state, companyId)
  );
  const isLoading = useSelector(companyListsSelectors.isLoading);

  useEffect(() => {
    if (shouldInitialize && !isLoading && !listsIncludingCompany && !isChromePlugin) {
      dispatch(companyListsActions.fetchListsExcludingCompany(companyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldInitialize, isLoading, listsIncludingCompany, companyId, dispatch]);

  return {
    listsIncludingCompany,
  };
};

export default useInitWatchListsIncludingCompany;
