import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button } from 'antd';
import moment from 'moment';
// constants
import globalConfig from '@optx/constants/config';
import { MAX_CHAR_LENGTH } from '@constants/notes';
// models
import { CompanyNotification } from '@optx/models/Company';
// redux
import { actions as searchNotesActions } from '@redux/company/notes/search';
import { selectors as userSelectors } from '@redux/user/information';
// components
import avatarImg from '../../assets/images/users/avatar-default.png';
import DropdownDotRemove from '../common/DropdownDotRemove';

interface CardNotificationProps {
  note: CompanyNotification;
  onDeleteCompanyNote: (noteId: number) => void;
}

const CardNotification: React.FC<CardNotificationProps> = ({ note, onDeleteCompanyNote }) => {
  const dispatch = useDispatch();

  const [value, setValue] = useState(note?.note_text);
  const [isEditing, setIsEditing] = useState(false);

  const userName = useSelector(userSelectors.getUserName);

  const updateNote = useCallback(
    (text: string) => dispatch(searchNotesActions.updateNote(note, text)),
    [dispatch, note]
  );

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.currentTarget.value);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setValue(note?.note_text);
  };

  const handleSave = () => {
    setIsEditing(false);
    updateNote(value);
  };

  if (!note) {
    return null;
  }

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
      handleSave();
    }
  };

  return (
    <div className="card-notification">
      <div className="card-notification__user">
        <div className="card-notification__avatar">
          <img src={avatarImg} alt="avatar" />
        </div>
        <div className="card-notification__info">
          <div className="card-notification__name">{userName}</div>
          <div className="card-notification__time">
            {moment(note.note_created_date).format(globalConfig.grid_date.DATE_FORMAT)}
          </div>
        </div>
        <DropdownDotRemove
          id={note.note_unique_id}
          handleDeleteItem={onDeleteCompanyNote}
          handleEditItem={handleEdit}
        />
      </div>

      {isEditing ? (
        <>
          <Input.TextArea
            name="note_text"
            placeholder="Add your note here"
            value={value}
            onChange={handleChange}
            onKeyDown={handleDropdownKeyEvent}
            tabIndex={0}
            maxLength={MAX_CHAR_LENGTH}
          />
          <div className="profile-notes__edit-buttons">
            <Button className="profile-notes__cancel" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSave}>
              Save note
            </Button>
          </div>
        </>
      ) : (
        <div className="card-notification__message">{note.note_text}</div>
      )}
    </div>
  );
};

export default CardNotification;
