import { BarProps, XAxisProps, YAxisProps, TooltipProps } from 'recharts';
// components
import {
  defaultAxisLineProps,
  xAxisTickDefaultProps,
} from '@optx/components/common/charts/LineChart/config';

export const commonBarProps: Omit<BarProps, 'dataKey'> = {
  radius: [10, 10, 0, 0],
};

export const xAxisProps: XAxisProps = {
  axisLine: defaultAxisLineProps,
  tickLine: false,
  tick: xAxisTickDefaultProps,
  tickMargin: 10,
};

export const yAxisProps: YAxisProps = {
  tick: false,
  axisLine: defaultAxisLineProps,
  interval: 0,
  label: {
    fill: '#C2CFE0',
    fontSize: 10,
    fontWeight: 600,
    value: 'FREQUENCY',
    angle: -90,
    dx: 16,
  },
  padding: { top: 0, bottom: 4 },
};

const tooltipWrapperStyles: React.CSSProperties = {
  zIndex: 1,
};

const tooltipCursor: React.CSSProperties = {
  opacity: 0.2,
};

export const tooltipProps: TooltipProps = {
  wrapperStyle: tooltipWrapperStyles,
  allowEscapeViewBox: { y: true },
  cursor: tooltipCursor,
};
