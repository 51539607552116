import React from 'react';
import { UploadProps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// constants
import {
  MAX_FILE_SIZE_MB,
  SUPPORTED_FILE_TYPES_LIST,
  getFileExtension,
} from '../../constants/fileUploadConstants';
// services
import NotificationService from '@optx/services/NotificationService';
// redux
import { actions, selectors } from '@redux/ui/modals/add-document-upload-touch';
// components
import DocumentUploadItem from '../DocumentUploadItem';

import { Styled } from './DocumentDragAndDrop.styled';

// We do not show it in supported file extensions, but we allow it to upload. So I'm removing this.
const formattedFileTypes = SUPPORTED_FILE_TYPES_LIST.sort().join(', ').replace('.docs, ', '');

const DocumentDragAndDrop: React.FC = () => {
  const dispatch = useDispatch();
  const files = useSelector(selectors.fileList);

  const convertFile = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  };

  const props: UploadProps = {
    name: 'file',
    accept: SUPPORTED_FILE_TYPES_LIST.join(),
    fileList: files,
    itemRender: (_originNode, file, _fileList, actions) => (
      <DocumentUploadItem file={file} actions={actions} />
    ),
    customRequest: options => {
      // just send success to show it as ok - upload is done on Save button click
      options.onSuccess?.(options.file);
    },
    beforeUpload: file => {
      const fileExtension = getFileExtension(file.name);
      const isFileTypeValid = (SUPPORTED_FILE_TYPES_LIST as string[]).includes(fileExtension);

      if (!isFileTypeValid) {
        const errorMessage = `"${fileExtension}" files are not supported. Supported file types: ${formattedFileTypes}`;
        NotificationService.error(errorMessage);

        return false;
      }

      const fileSizeMB = file.size / 1024 / 1024;

      if (fileSizeMB > MAX_FILE_SIZE_MB) {
        const errorMessage = `"${file.name}" exceeded max file size (${MAX_FILE_SIZE_MB}MB).`;
        NotificationService.error(errorMessage);

        return false;
      }

      return true;
    },
    onChange: ({ fileList, file }) => {
      let newFileList = [...fileList];

      if (!file.status || file.status === 'removed' || file.status === 'error') {
        newFileList = [...fileList.filter(f => f.uid !== file.uid)];
      }

      if (file.status === 'error') {
        NotificationService.error(`Failed to add file "${file.name}" to upload list!`);
      }

      // need to always update state at the end because of controlled fileList
      dispatch(actions.updateFileList(newFileList.slice()));

      if (file.status === 'done' && file.originFileObj) {
        convertFile(file.originFileObj).then(base64Encoding => {
          dispatch(
            actions.updateFileEncodingList({
              name: file.name,
              base64Encoding: base64Encoding as string,
            })
          );
        });
      } else if (file.status === 'removed') {
        dispatch(actions.removeFileEncoding(file.name));
      }
    },
  };

  return (
    <Styled.DragAndDrop {...props}>
      <Styled.DragAndDropContent>
        <Styled.UploadOutlinedIcon />
        <span>
          Drag and drop here or
          <Styled.UploadText> upload from your computer</Styled.UploadText>
        </span>
      </Styled.DragAndDropContent>
      <Styled.SupportedFileTypes>
        Supported file types: {formattedFileTypes}
      </Styled.SupportedFileTypes>
      <span className="file-size">Maximum file size: {MAX_FILE_SIZE_MB}MB</span>
    </Styled.DragAndDrop>
  );
};

export default DocumentDragAndDrop;
