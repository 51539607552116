import React from 'react';
// styles
import Styles from './LegendCard.styles';

interface LegendProps {
  text: string;
  info: string | number;
}

const LegendCard: React.FC<LegendProps> = ({ text, info }) => {
  return (
    <Styles.Wrapper>
      <Styles.Text ellipsis>{text}</Styles.Text>
      <Styles.Total>{info}</Styles.Total>
    </Styles.Wrapper>
  );
};

export default LegendCard;
