import styled, { css } from 'styled-components';
import { Card } from 'antd';
import { CardProps } from 'antd/lib/card';

interface InfoCardProps extends CardProps {
  $highlighted: boolean;
}

const InfoCard = styled(Card)<InfoCardProps>`
  background: #001529;
  color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  transition: transform 300ms, opacity 300ms;
  transform-origin: left;
  width: 270px;
  height: 165px;
  &:not(:first-child):last-child {
    transform-origin: right;
  }

  ${({ $highlighted }) =>
    $highlighted
      ? css`
          background: #001529;
          &:not(:first-child):last-child {
            transform: translateX(-40px);
          }

          .day {
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #40a9ff;
            opacity: 0.8;
            text-transform: uppercase;
          }

          .ant-card-body {
            padding: 0 !important;
            height: 100%;
            display: flex;
            flex-direction: column;
          }

          .header {
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            padding: 19px 24px;
          }

          .stats {
            display: flex;
            gap: 30px;
            width: 100%;
            height: 100%;
            background: #03213e;
            padding: 15px 25px;
            border-radius: inherit;

            .category {
              font-weight: 600;
              font-size: 12px;
              line-height: 20px;
              opacity: 0.5;
              color: #90a0b7;
            }

            .value {
              font-weight: 600;
              font-size: 14px;
              line-height: 22px;
              color: #ffffff;
            }

            .value.red {
              color: #ff4d4f;
            }
          }
        `
      : css`
          opacity: 0.3;
          filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15));

          .ant-card-body {
            display: none;
          }

          &:first-child {
            background: linear-gradient(270.55deg, #001529 0.52%, rgba(0, 21, 41, 0) 103.03%);
            transform: translateX(20px) scale3d(0.75, 0.85, 1);
          }

          &:last-child {
            background: linear-gradient(-270.55deg, #001529 0.52%, rgba(0, 21, 41, 0) 103.03%);
            transform: translateX(-60px) scale3d(0.75, 0.85, 1);
          }

          &::before {
            content: attr(data-content);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-weight: 600;
            line-height: 20px;
            color: #ffffff;
            opacity: 0.5;
          }
        `}
`;

export default {
  InfoCard,
};
