import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { AnalystService } from '@services/api';
import { getErrorMessage } from '@optx/utils/api/errors';
import NotificationService from '@optx/services/NotificationService';
import { selectors } from '../selectors';
import { actions } from '../actions';

export function* fetchETAlltimeHighlightsSaga() {
  try {
    // @ts-ignore
    const query: string = yield select(selectors.filters.filterQuery);
    const res: AxiosResponse = yield call(AnalystService.getETAllTimeHighlights, query);
    yield put(actions.highlights.etAlltimeHighlightsSuccess(res.data));
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Failed to fetch filters for EquityTouch companies!');
    yield put(actions.highlights.etAlltimeHighlightsFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* fetchETMergeHistorysSaga() {
  try {
    // @ts-ignore
    const query: string = yield select(selectors.filters.filterQuery);
    const res: AxiosResponse = yield call(AnalystService.getETMergeHistory, query);
    yield put(actions.highlights.etMergeHistorySuccess(res.data));
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Failed to fetch filters for EquityTouch companies!');
    yield put(actions.highlights.etMergeHistoryFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* fetchTimeSpentSaga() {
  try {
    // @ts-ignore
    const query: string = yield select(selectors.filters.filterQueryForStats);
    const res: AxiosResponse = yield call(AnalystService.getOPTXStats, query);
    yield put(actions.highlights.timeSpentSuccess(res.data));
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Failed to fetch filters for EquityTouch companies!');
    yield put(actions.highlights.timeSpentFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* widgetsSagas() {
  yield takeLatest(actions.highlights.etAlltimeHighlightsFetch, fetchETAlltimeHighlightsSaga);
  yield takeLatest(actions.highlights.etMergeHistoryFetch, fetchETMergeHistorysSaga);
  yield takeLatest(actions.highlights.timeSpentFetch, fetchTimeSpentSaga);
}
