import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { fetchFailReducer } from '@redux/feature/fetch/reducers';
import * as actions from './actions';
import { CompanyIsInBusinessPayload, CompanyIsInBusinessState } from './interfaces';

const initialState: CompanyIsInBusinessState = {
  companyId: null,
  error: '',
  loading: false,
};

const addCompanyInBusinessEvaluationReducer: CaseReducer<
  CompanyIsInBusinessState,
  PayloadAction<CompanyIsInBusinessPayload>
> = (draftState, action) => {
  draftState.companyId = action.payload.companyId;
  draftState.loading = true;
  draftState.error = '';
};

const addCompanyInBusinessEvaluationSuccessReducer: CaseReducer<
  CompanyIsInBusinessState,
  PayloadAction<{ score: number; companyId: number; isInBusiness: boolean }>
> = draftState => {
  draftState.loading = false;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.addCompanyInBusinessEvaluation, addCompanyInBusinessEvaluationReducer)
    .addCase(
      actions.addCompanyInBusinessEvaluationSuccess,
      addCompanyInBusinessEvaluationSuccessReducer
    )
    .addCase(actions.addCompanyInBusinessEvaluationFail, fetchFailReducer)
);

export default reducer;
