import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// models
import { FilterTag } from '@optx/models/tags';
// components
import { FilterTagsOverflow } from '@optx/features/tags/overflow-tags';
// redux
import { actions } from '../../state';

export const FilterTags: React.FC<{ options: FilterTag[] }> = ({ options }) => {
  const dispatch = useDispatch();

  const toggleFiltersModal = useCallback(
    () => dispatch(actions.ui.toggleFiltersModal()),
    [dispatch]
  );

  const handleFilterRemove = (tag: FilterTag) => () => {
    dispatch(actions.filters.resetFilter(tag));
  };

  // Don't return null or anything prior to this return. The ref will be lost and max displayed index logic won't work.
  return (
    <FilterTagsOverflow
      options={options}
      onClick={toggleFiltersModal}
      onClose={handleFilterRemove}
      multipleValue
    />
  );
};

export default FilterTags;
