import styled from 'styled-components';

const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 16px;
  /* Dark BLue */
  color: #334d6e;
  opacity: 0.8;
`;

const Legend = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;

  text-align: center;
  padding-top: 2px;
  padding-bottom: 6px;

  /* Gray */
  color: #90a0b7;
  opacity: 0.8;
`;

const Styled = { Title, Legend };

export default Styled;
