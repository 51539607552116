import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMount } from '@umijs/hooks';
import { ChartIntervalKeys } from '@optx/models/charts/data';
import Icon from '@components/common/Icon';
import { selectors as localSelectors, actions as localActions } from '../../state/capterra';
import DoubleLineChart from '../DoubleLineChart';

interface SocialMediaProps {
  companyId: number;
  chartInterval: ChartIntervalKeys;
}

const CapterraChart: React.FC<SocialMediaProps> = ({ companyId, chartInterval }) => {
  const dispatch = useDispatch();

  useMount(() => dispatch(localActions.fetchCapterraData(companyId)));

  const chartData = useSelector(localSelectors.getData);

  const activeChartData = chartData[chartInterval];

  return (
    <DoubleLineChart
      icon={<Icon iconName="capterra" className="chart-icon" />}
      dataKey="value"
      secondaryDataKey="secondary_value"
      stars={activeChartData.overall_stars as number}
      labelKey={chartInterval === '3M' || chartInterval === '6M' ? 'date' : 'quarter'}
      kpiLabel="Date"
      labelKeyAlt="Rating"
      title="Capterra"
      chartInterval={chartInterval}
      data={activeChartData}
      headerValue={activeChartData.num_of_reviews as number}
      headerLabel="# of reviews"
      chartLabel="Rating & Number of Reviewers over time"
    />
  );
};

export default React.memo(CapterraChart);
