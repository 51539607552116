import createCachedSelector from 're-reselect';
import featureName from '../../featureName';
import { RequestState } from '../interfaces';
import * as localErrorSelectors from './error';
import * as localLoadingSelectors from './loading';

const selectRequestState = (state: any) => state[featureName] as RequestState;

// error
const selectErrorState = (state: any) => selectRequestState(state).error;

const selectError = createCachedSelector(
  selectErrorState,
  (state: any, action: string) => action,
  localErrorSelectors.selectError
)((state: any, action: string) => action);

export const createErrorSelector = (action: string) => (state: any) => selectError(state, action);

// loading
const selectLoadingState = (state: any) => selectRequestState(state).loading;

const isLoading = createCachedSelector(
  selectLoadingState,
  (state: any, actions: Array<string>) => actions,
  localLoadingSelectors.selectIsLoading
)((state: any, actions: Array<string>) => actions.join('_'));

export const createLoadingSelector = (actions: Array<string>) => (state: any) =>
  isLoading(state, actions);
