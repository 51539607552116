import { createAction } from '@reduxjs/toolkit';
import * as types from './actionTypes';
import { CognitoButton } from './interfaces';

export const fetchCognitoLoginURL = createAction(types.FETCH_COGNITO_LOGIN_URL);

export const fetchCognitoLoginURLSuccess = createAction<CognitoButton[]>(
  types.FETCH_COGNITO_LOGIN_URL_SUCCESS
);

export const fetchCognitoLoginURLFail = createAction<string>(types.FETCH_COGNITO_LOGIN_URL_FAIL);
