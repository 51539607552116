import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { UserService } from '@services/api';
import { AxiosResponse } from 'axios';
import {
  EditShareListForm,
  EditShareListFormUserOption,
} from '@optx/models/feature/shareList/form';
import ShareListInfo, { ShareListUser } from '@optx/models/feature/shareList/ShareListInfo';
import { ShareListRequest } from '@optx/models/feature/shareList/ShareListRequest';
import { ListType, UpdateListPermission } from '@optx/models/WatchList';
import { getErrorMessage } from '@utils/api/errors';
import { selectors as userSelectors } from '@redux/user/information';
import { actions as listActions } from '@redux/favorite-lists/lists';
import { actions as modalActions } from '../ui/editModal';
import * as actions from '../share-list/actions';
import selectors from '../selectors';
import { EditShareListActionPayload } from '../share-list/interfaces';

function* editShareListSaga(action: PayloadAction<EditShareListActionPayload>) {
  yield put(modalActions.toggle(true));
  yield put(actions.fetchShareListInfo(action.payload.listId));
}

function* cancelEditShareListSaga() {
  yield put(modalActions.toggle(false));
}

function* fetchShareListInfoSaga(action: PayloadAction<number>) {
  const listId = action.payload;

  try {
    const res: AxiosResponse<ShareListInfo> = yield call(UserService.getShareListInfo, listId);
    yield put(actions.fetchShareListInfoSuccess(res.data));
  } catch (error: any) {
    const message = getErrorMessage(error, 'Failed fetching share list information!');
    yield put(actions.fetchShareListInfoFail(message));
  }
}

/**
 * Map shared users to response excluding owner.
 * @param sharedWith shared with user options.
 * @param ownerId owner identifier.
 */
function mapSharedUsers(sharedWith: Array<EditShareListFormUserOption>, ownerId: number) {
  return sharedWith
    .filter(user => user.value !== ownerId)
    .map(user => ({
      user_id: user.value,
      permission_id: user.accessRights,
    }));
}

function mapShareFormToRequest(
  editInfo: EditShareListForm,
  listId: number,
  ownerId: number,
  listType: ListType
) {
  const request: ShareListRequest = {
    list_type: listType,
    note: editInfo.note,
    share_with: mapSharedUsers(editInfo.share_with, ownerId),
    owner_id: ownerId,
    list_id: listId,
  };

  return request;
}

function* shareListSaga(action: PayloadAction<EditShareListForm>) {
  const editInfo = action.payload;
  // @ts-ignore
  const owner: ShareListUser = yield select(selectors.shareList.listOwner);
  // @ts-ignore
  const listId: number = yield select(selectors.shareList.editedSharedList);
  // @ts-ignore
  const listType: ListType = yield select(selectors.shareList.listType);
  // @ts-ignore
  const currentUserId = yield select(userSelectors.getUserId);
  const currentUser = editInfo.share_with.filter(user => user.value === currentUserId);

  const currentUserPermission = currentUser[0] && currentUser[0].accessRights;

  try {
    const request = mapShareFormToRequest(editInfo, listId, owner.user_id, listType);
    yield call(UserService.shareList, request);
    yield put(actions.cancelEditShareList());
    yield put(actions.shareListSuccess());
    let updateListCurrentUserPermissionPayload: UpdateListPermission;

    if (currentUserPermission) {
      updateListCurrentUserPermissionPayload = {
        listId,
        userPermission: currentUserPermission,
      };
      yield put(listActions.updateFavoriteListPermission(updateListCurrentUserPermissionPayload));
    } else {
      updateListCurrentUserPermissionPayload = {
        listId,
        userPermission: 2,
      };
      yield put(listActions.updateFavoriteListPermission(updateListCurrentUserPermissionPayload));
    }
  } catch (error: any) {
    const message = getErrorMessage(error, 'Failed sharing list!');
    yield put(actions.shareListFail(message));
  }
}

export default function* shareListSagas() {
  yield takeLatest(actions.editShareList, editShareListSaga);
  yield takeLatest(actions.cancelEditShareList, cancelEditShareListSaga);
  yield takeLatest(actions.fetchShareListInfo, fetchShareListInfoSaga);
  yield takeLatest(actions.shareList, shareListSaga);
}
