import React from 'react';
import { Tooltip } from 'antd';
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';

export const TableCellTooltip = (value: any) => {
  if (!value || value === DEFAULT_CELL_VALUE) {
    return <>{DEFAULT_CELL_VALUE}</>;
  }

  return (
    <Tooltip title={value}>
      <span style={{ width: '100%', display: 'block' }}>{value}</span>
    </Tooltip>
  );
};

export default TableCellTooltip;
