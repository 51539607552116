import { combineReducers } from 'redux';
import { UIState } from './interfaces';
import modalsReducer from './modals';
import fullscreenReducer from './settings/fullscreen';
import messagesReducer from './messages';

const uiReducer = combineReducers<UIState>({
  modals: modalsReducer,
  fullscreen: fullscreenReducer,
  messages: messagesReducer,
});

export default uiReducer;
