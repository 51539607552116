import React from 'react';
// models
import { BaseFilter, Filter, FilterSource } from '@optx/models/filters';
// components
import SearchFilterCard from '../SearchFilterCard';
import FilterFactory from '../FilterFactory';
// styles
import './FilterGroup.styles.scss';

interface FilterGroupProps {
  source: FilterSource<Filter<any>>;
  filter: BaseFilter<any>;
}

const FilterGroup: React.FC<FilterGroupProps> = ({ source, filter }) => {
  return (
    <SearchFilterCard label={filter.label} className="filter-form__card-group">
      {filter.groupItems?.map((filter: any) => (
        <FilterFactory key={filter.column} filter={filter as BaseFilter} source={source} />
      ))}
    </SearchFilterCard>
  );
};

export default FilterGroup;
