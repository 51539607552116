import { FC, memo } from 'react';
import Overflow from 'rc-overflow';
import styled from 'styled-components';
// models
import { ListTag } from '@optx/models/tags';
// components
import { ListTagMore, ListTagValue } from '@optx/features/tags/tags';
import { orderTags } from '@optx/utils/orderTags';

const OverflowTagsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 0;

  &.list-tags__custom-styling {
    margin-top: 5px;
  }

  .special-tag {
    font-size: 12px;
    background: #d6e4ff;
    color: #006d75;
  }
`;

const NormalWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1;
  margin: 0;
`;

interface ListTagsOverflowProps {
  tags: ListTag[] | null;
  onTagMoreClick?: VoidFunction;
  className?: string;
}

const ListTagsOverflow: FC<ListTagsOverflowProps> = ({ tags, onTagMoreClick, ...rest }) => {
  if (!tags) return null;

  return (
    <OverflowTagsWrapper {...rest}>
      <NormalWrapper>
        <Overflow
          data={
            tags &&
            orderTags(tags)?.map((item: ListTag) => ({
              ...item,
              label: item.title,
              value: item.title,
            }))
          }
          renderRawItem={item => <ListTagValue item={item} />}
          renderRest={items => <ListTagMore count={items.length} onTagMoreClick={onTagMoreClick} />}
          maxCount="responsive"
          style={{ justifyContent: 'flex-start' }}
        />
      </NormalWrapper>
    </OverflowTagsWrapper>
  );
};

export default memo(ListTagsOverflow);
