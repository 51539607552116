import { ColumnKeys } from '@optx/models/table/Columns';
import { columnDisplayInitialState } from '@optx/constants/table/columnDisplay/company-search';

/**
 * get a list of column name ids and return list with their titles/headers
 * @param {string[]} columnList list with column name ids
 */
export const getColumnHeaders = (columnList: ColumnKeys[]) => {
  const columnHeaders: string[] = [];

  columnList.forEach(column => {
    columnHeaders.push(columnDisplayInitialState[column].name || '');
  });

  return columnHeaders;
};
