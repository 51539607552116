import numeral from 'numeral';
import moment from 'moment';
// models
import Format from '@optx/models/Format';
// constants
import globalConfig from '@optx/constants/config';
import { NUMBER_FORMAT } from '@optx/constants/format/number';
import { SHORT_MONTH_YEAR_DATE_FORMAT } from '@optx/constants/format/date';

export const formatMoney = (value: number | null | undefined | string, canBeNegative?: boolean) => {
  if (!value && value !== 0) return value;

  return numeral(typeof value === 'string' ? value.toUpperCase() : value)
    .format(canBeNegative ? NUMBER_FORMAT : `$${NUMBER_FORMAT}`)
    .toUpperCase();
};

/**
 * Format a number to millions as a string.
 * @param value any number or string.
 * @returns the value as a formatted string.
 */
export const formatValueToMillions = (value: string | number | null | undefined) => {
  const formattedValue = numeral(value).value();

  if (formattedValue) {
    return (formattedValue / 1000000).toString();
  }

  return numeral(value).format(NUMBER_FORMAT);
};

export type DateFormat = 'monthDayYear' | 'shortDate';

export const formatDate = (date: moment.MomentInput, format?: DateFormat) => {
  if (!date) return date;

  if (format) {
    switch (format) {
      case 'monthDayYear':
        return moment(date).format(globalConfig.grid_date.DATE_FORMAT);
      case 'shortDate':
        return moment(date).format(globalConfig.short_date.DATE_FORMAT);
      default:
        return moment(date).format(SHORT_MONTH_YEAR_DATE_FORMAT);
    }
  }

  return moment(date).format(SHORT_MONTH_YEAR_DATE_FORMAT);
};

/**
 * Formatting based on numeral JS. Value will be modified by library
 * @param value value to be formatted.
 * @param decimalCount decimal count. By default there are no decimals and can be set to maximum three decimals.
 * @returns formatted string if value is valid, value itself otherwise.
 */
export const formatPercent = (
  value: number | null | undefined,
  decimalCount: 0 | 1 | 2 | 3 = 0
) => {
  if (!value && value !== 0) return value;

  let decimals = '';

  for (let index = 0; index <= decimalCount; index++) {
    if (decimals === '') {
      decimals += '.';
    }

    decimals += '0';
  }

  return numeral(value).format(`0${decimals}%`);
};

export const formatQuarter = (date: moment.MomentInput) => {
  if (!date) return date;

  const quarterCount = moment(date).quarter();
  const getYear = moment(date).year();

  return `Q${quarterCount} ${getYear}`;
};

/**
 * Simple percent formatter that just adds percent sign after value.
 * @param value value to be formatted.
 * @param absolute value should be absolute.
 * @returns formatted string if value is valid, value itself otherwise.
 */
export const formatPercentDefault = (
  value: number | string | null | undefined,
  absolute = false
) => {
  if (typeof value !== 'number' || (!value && value !== 0)) return value;

  if (absolute) {
    return `${Math.abs(value)}%`;
  }

  return `${value}%`;
};

const format = (value: any, format?: Format) => {
  switch (format) {
    case 'percent':
      return formatPercentDefault(value, true);

    default:
      return value;
  }
};

export interface FormatDateIntervalOptions {
  prefix?: string;
  dateFormat?: string;
  dateSeparator?: string;
  labelSeparator?: string;
}

export const defaultFormatDateIntervalOptions: FormatDateIntervalOptions = {
  prefix: '',
  dateSeparator: ' - ',
  labelSeparator: ': ',
  dateFormat: globalConfig.short_date.DATE_FORMAT,
};

export function formatDateInterval(
  start: string | Date,
  end: string | Date,
  options: FormatDateIntervalOptions = {}
) {
  const finalOptions = { ...defaultFormatDateIntervalOptions, ...options };
  const formattedStart = moment(start).format(globalConfig.short_date.DATE_FORMAT);
  const formattedEnd = moment(end).format(globalConfig.short_date.DATE_FORMAT);
  const prefix = finalOptions.prefix ? `${finalOptions.prefix}${finalOptions.labelSeparator}` : '';

  return `${prefix}${formattedStart}${finalOptions.dateSeparator}${formattedEnd}`;
}

/**
 * Convert a list of strings to comma separated values.
 * @param list listof values.
 * @param defaultValue default value. By default '-'.
 */
export const stringListToView = (list: Array<string> | null = null, defaultValue: string = '-') => {
  if (!list || !Array.isArray(list)) {
    return defaultValue;
  }

  return list.join(', ');
};

export default format;
