import styled from 'styled-components';

const TextareaContainer = styled.div`
  .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
  }

  .ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }

  .ql-bubble .ql-editor {
    border: 1px solid #ccc;
    border-radius: 0.5em;
  }

  .ql-editor {
    min-height: 150px;
    border: 1px solid #d9d9d9;
    padding: 8px;
    background: #ffffff;
  }
  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
    width: 26px;
  }
  .ql-editor.ql-blank::before {
    color: #bfbfbf;
    font-size: 14px;
    font-style: normal;
  }
`;

export const Styled = {
  TextareaContainer,
};
