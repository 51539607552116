/* eslint-disable no-nested-ternary */
import React, { ChangeEvent, useEffect, useRef, useState, useContext } from 'react';
import { Button, Input, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LinkedinOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import numeral from 'numeral';
// models
import { ChartBarIntervalKeys } from '@optx/models/charts/data';
import Company, { CompanyProfile, CompanyUserValuesKeys } from '@optx/models/Company';
import { CompanyUserContact } from '@optx/models/api/contacts';
// constants
import {
  ABBREVIATION_ERROR_MESSAGE,
  DEFAULT_CELL_VALUE,
  LINK_URL_VALUE_ERROR_MESSAGE,
  message,
} from '@optx/constants/table/cells';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { NUMBER_FORMAT } from '@optx/constants/format/number';
// utils
import { abbreviateValue, parseNumberFromAbbreviation } from '@optx/utils/number';
import { validateFloatAbbreviation, validateLinkedinURL } from '@optx/utils/validation';
import { getRouteAlias } from '@optx/utils/routes';
import { convertToURL } from '@optx/utils/url';
import { validateField } from '@optx/utils/editFields';
// components
import { TrendingLine } from '@optx/components/common/charts';
import EditPopover from '@optx/components/common/popover/EditPopover';
import { InlineEdit } from '@optx/shared/view/molecules/edit/InlineEdit';
import { DropdownAddContext } from '@optx/components/DropdownAdd/DropdownAddContext';
// redux
import { selectors as searchSelectors } from '@features/grid/search';
import { actions as opportunityPresentationActions } from '@redux/company/opportunity-presentation';
// hooks
import { useOnClickOutside } from '@optx/common/hooks/useOnClickOutside';
import useUpdateFields from '../hooks/useUpdateFields';
import { ServiceType } from '../state/interfaces';
// styles
import { Styled } from './EditFieldText.styled';

interface EditFieldTextProps {
  value: string | number;
  record: Company | CompanyProfile | CompanyUserContact | undefined;
  fieldName: EditFieldOptions;
  isFromHistory?: boolean;
  refreshOnChange?: boolean;
  service: ServiceType;
  isFromGrid: boolean;
  triggerLabel?: string;
  isURL?: boolean;
  entityType?: string;
  isContact?: boolean;
  hidePenIcon?: boolean;
  successMessage?: string;
}

export type EditFieldOptions =
  | 'Number of Employees'
  | 'Last Raised Amount'
  | 'Capital Raised'
  | 'Linkedin Profile'
  | 'Asking Amount';

const renderNumberOfEmployeesTrendline = (
  record: Company | CompanyProfile,
  period: ChartBarIntervalKeys
) => {
  let points: (number | null)[] = [];

  if (record.employee_count_trends && record.employee_count_trends[period]) {
    points = record.employee_count_trends[period];
  }

  return <TrendingLine points={points} color="#52C41A" />;
};

const monetaryOptions = ['Last Raised Amount', 'Capital Raised', 'Asking Amount'];

const formattedValue = (fieldName: EditFieldOptions, customValue: string | undefined) => {
  if (fieldName === 'Last Raised Amount' || fieldName === 'Capital Raised') {
    if (customValue === 'Unknown' || customValue === DEFAULT_EMPTY_VALUE) {
      return '0.0';
    }
  }

  return customValue || undefined;
};

const EditFieldText: React.FC<EditFieldTextProps> = ({
  record,
  value,
  fieldName,
  isFromGrid,
  isFromHistory,
  refreshOnChange,
  service,
  triggerLabel,
  isURL,
  entityType,
  isContact,
  hidePenIcon,
  successMessage,
}) => {
  // hooks
  const [period, setPeriod] = useState<ChartBarIntervalKeys>('1Y');
  const { pathname } = useLocation();
  const myCompaniesTrending = useSelector(searchSelectors.getTrending('myCompanies'));
  const searchTrending = useSelector(searchSelectors.getTrending('advancedSearch'));
  const watchlistTrending = useSelector(searchSelectors.getTrending('lists'));
  const companyOutReachTrending = useSelector(searchSelectors.getTrending('outreach'));
  const { updateField } = useUpdateFields({
    fieldName,
    companyId: record?.company_id ?? 0,
    companyUrl: record?.company_url ?? null,
    successMessage,
    service,
    isFromHistory: isFromHistory || refreshOnChange,
    isContact,
    personId: (record as CompanyUserContact)?.person_id as number,
  });

  const dispatch = useDispatch();

  const [closePopup, setClosePopup] = useState<boolean>(false);
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState<boolean>(true);
  // Close DropDownAdd popup
  const { dropdownVisible, setDropdownVisible } = useContext(DropdownAddContext);

  const fieldKey = useRef<CompanyUserValuesKeys>('num_employees');
  const popoverContentRef = useRef(null);

  const loadValue = (lastRaisedAmount: number | string | null, isFormattedToMillions?: boolean) => {
    if (fieldName === 'Capital Raised') {
      if (
        lastRaisedAmount === undefined ||
        lastRaisedAmount === null ||
        lastRaisedAmount === '-' ||
        lastRaisedAmount === '' ||
        lastRaisedAmount === 0
      ) {
        return DEFAULT_CELL_VALUE;
      } else if (lastRaisedAmount === 'Unknown') {
        return 'Unknown';
      }

      let value;

      // after change value in edit field, we have to remove (Unknown) from value.
      if (lastRaisedAmount.toString().indexOf(' (')) {
        value = lastRaisedAmount.toString().split(' (')[0];
      } else {
        value = lastRaisedAmount;
      }

      const formatValue =
        (record as Company)?.is_capital_raise_has_unknown === true ? value : lastRaisedAmount;

      const formattedToMillions = numeral(formatValue).value();

      return isFormattedToMillions && formattedToMillions
        ? formattedToMillions / 1000000
        : numeral(formatValue).format(`$${NUMBER_FORMAT}`);
    }

    if (fieldName === 'Last Raised Amount') {
      if (
        lastRaisedAmount === undefined ||
        lastRaisedAmount === null ||
        lastRaisedAmount === '-' ||
        lastRaisedAmount === ''
      ) {
        return DEFAULT_CELL_VALUE;
      }

      if (lastRaisedAmount === 'Unknown') {
        return 'Unknown';
      }

      const formattedToMillions = numeral(lastRaisedAmount).value();

      return isFormattedToMillions && formattedToMillions
        ? formattedToMillions / 1000000
        : numeral(lastRaisedAmount).format(`$${NUMBER_FORMAT}`);
    }

    if (fieldName === 'Asking Amount') {
      if (
        lastRaisedAmount === undefined ||
        lastRaisedAmount === null ||
        lastRaisedAmount === '-' ||
        lastRaisedAmount === ''
      ) {
        return DEFAULT_CELL_VALUE;
      }

      const formattedToMillions = numeral(lastRaisedAmount).value();

      return isFormattedToMillions && formattedToMillions
        ? formattedToMillions / 1000000
        : numeral(lastRaisedAmount).format(`$${NUMBER_FORMAT}`);
    }

    if (lastRaisedAmount === null || lastRaisedAmount === DEFAULT_CELL_VALUE) {
      return DEFAULT_CELL_VALUE;
    }

    return numeral(lastRaisedAmount).format(`$${NUMBER_FORMAT}`);
  };

  const [customValue, setCustomValue] = useState<string | number | null>(
    monetaryOptions.includes(fieldName)
      ? loadValue(value, true)
      : fieldName === 'Number of Employees'
      ? value === null
        ? DEFAULT_CELL_VALUE
        : value
      : !value
      ? DEFAULT_CELL_VALUE
      : value
  );

  useEffect(() => {
    switch (fieldName) {
      case 'Last Raised Amount':
      case 'Capital Raised':
      case 'Asking Amount':
        setCustomValue(loadValue(value?.toString(), true));
        break;
      case 'Number of Employees':
        setCustomValue(value === null ? DEFAULT_CELL_VALUE : value);
        break;
      default:
        setCustomValue(!value ? DEFAULT_CELL_VALUE : value);
        break;
    }

    if (value === customValue) setIsSaveBtnDisabled(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleCancel = () => {
    setClosePopup(true);

    switch (fieldName) {
      case 'Last Raised Amount':
      case 'Capital Raised':
      case 'Asking Amount':
        setCustomValue(loadValue(value, true));
        break;
      case 'Number of Employees':
        setCustomValue(value === null ? DEFAULT_CELL_VALUE : value);
        break;
      default:
        setCustomValue(!value ? DEFAULT_CELL_VALUE : value);
        break;
    }

    setIsSaveBtnDisabled(true);
  };

  useOnClickOutside(popoverContentRef, handleCancel);

  useEffect(() => {
    if (
      getRouteAlias()?.startsWith('userLists') ||
      getRouteAlias()?.startsWith('sourceScrubLists')
    ) {
      setPeriod(watchlistTrending);
    } else if (getRouteAlias()?.startsWith('myCompanies')) {
      setPeriod(myCompaniesTrending);
    } else if (getRouteAlias()?.startsWith('companyOutReach')) {
      setPeriod(companyOutReachTrending);
    } else {
      setPeriod(searchTrending);
    }
  }, [companyOutReachTrending, myCompaniesTrending, pathname, searchTrending, watchlistTrending]);

  if (!record) return null;

  const isShowTrendingLinesEmployees = fieldName === 'Number of Employees' && isFromGrid;
  const isCapitalRaiseHasUnknown = (record as CompanyProfile).is_capital_raise_has_unknown;

  const fieldNameHandle = (fieldName: EditFieldOptions) => {
    if (fieldName === 'Capital Raised') {
      return 'Total Raised';
    }

    if (fieldName === 'Asking Amount') {
      return 'Equity Check (ask amount)';
    }

    return fieldName;
  };

  const title = `Edit "${fieldNameHandle(fieldName)}" value`;
  const placeholderMessage = `Enter ${fieldNameHandle(fieldName)}`;

  let label = monetaryOptions.includes(fieldName)
    ? loadValue(value)
    : fieldName === 'Number of Employees'
    ? value === undefined || value === null || value === DEFAULT_CELL_VALUE || value === ''
      ? DEFAULT_CELL_VALUE
      : numeral(value).format('0.[0]a')
    : numeral(value).format(undefined);

  if (
    fieldName === 'Capital Raised' &&
    value !== null &&
    value !== DEFAULT_CELL_VALUE &&
    value !== 0 &&
    isCapitalRaiseHasUnknown
  ) {
    label = `${label} ${
      ((record as Company).is_capital_raise_has_unknown as boolean) === true ? '(plus Unknown)' : ''
    }`;
  } else if (!isFromGrid && fieldName === 'Capital Raised' && value === null) {
    label = DEFAULT_CELL_VALUE;
  }

  const customMonetaryValue = customValue?.toString().replace('$', '');

  const handleFieldKey = () => {
    if (fieldName === 'Last Raised Amount') {
      fieldKey.current = 'last_raised_amount';
    }

    if (fieldName === 'Capital Raised') {
      fieldKey.current = 'capital_raised';
    }

    if (fieldName === 'Linkedin Profile') {
      fieldKey.current = 'linkedin';
    }

    if (fieldName === 'Asking Amount') {
      fieldKey.current = 'asking_amount';
    }
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    handleFieldKey();
    setIsSaveBtnDisabled(false);

    if (isURL) {
      setCustomValue(value);
    } else {
      setCustomValue(value.toUpperCase());
    }
  };

  const handleType = () => {
    return monetaryOptions.includes(fieldName)
      ? loadValue(value)
      : abbreviateValue(fieldName === 'Number of Employees' ? value?.toString() : value);
  };

  const handleConfirmSave = (value: number | null) => {
    const formattedToMillions =
      monetaryOptions.includes(fieldName) && value ? value * 1000000 : value;

    if (fieldName === 'Asking Amount') {
      const payload = {
        company_id: record.company_id,
        deal_preparation: {
          [fieldKey.current]: formattedToMillions,
        },
      };

      dispatch(opportunityPresentationActions.updateOpportunityPresentation(payload));
    } else {
      updateField(formattedToMillions, fieldKey.current);
    }

    setClosePopup(true);
    setIsSaveBtnDisabled(true);
  };

  const handleURLSave = () => {
    const currValue = customValue === '' ? null : customValue;

    if (currValue === null) {
      updateField(currValue, fieldKey.current, undefined, undefined, undefined, entityType);
      setClosePopup(true);
      setIsSaveBtnDisabled(true);
    } else if (currValue !== null && validateLinkedinURL(customValue as string)) {
      customValue !== value &&
        updateField(
          convertToURL(currValue as string),
          fieldKey.current,
          undefined,
          undefined,
          undefined,
          entityType
        );
      setClosePopup(true);
      setDropdownVisible(!dropdownVisible);
      setIsSaveBtnDisabled(true);
    } else {
      message().error(LINK_URL_VALUE_ERROR_MESSAGE);
    }
  };

  const handleSave = () => {
    let value;

    if (fieldName === 'Number of Employees') {
      if (!validateFloatAbbreviation(customValue as string)) {
        setIsSaveBtnDisabled(true);

        return message().error(ABBREVIATION_ERROR_MESSAGE);
      }

      value =
        customValue !== ''
          ? parseNumberFromAbbreviation(customValue ? customValue.toString() : '0')
          : null;
    } else if (fieldName === 'Capital Raised') {
      if (customValue === null || customValue === '' || customValue === DEFAULT_EMPTY_VALUE) {
        value = 0;
      } else {
        if (!validateField(customValue, 'Capital Raised', undefined)) {
          setIsSaveBtnDisabled(true);

          return;
        }

        value = parseNumberFromAbbreviation(customValue ? customValue.toString() : '0');
      }
    } else if (fieldName === 'Asking Amount') {
      if (customValue === null || customValue === '' || customValue === DEFAULT_EMPTY_VALUE) {
        value = null;
      } else {
        if (!validateField(customValue, 'Asking Amount', undefined)) {
          setIsSaveBtnDisabled(true);

          return;
        }

        value = parseNumberFromAbbreviation(customValue ? customValue.toString() : '0');
      }
    } else {
      if (!validateField(customValue as string, 'Last Raised Amount', undefined)) {
        setIsSaveBtnDisabled(true);

        return;
      }

      value = parseNumberFromAbbreviation(customValue ? customValue.toString() : '0');
    }

    if (customValue === handleType()) {
      return setClosePopup(true);
    }

    return handleConfirmSave(value);
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      isURL ? handleURLSave() : handleSave();
    }

    if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
      event.stopPropagation();
    }
  };

  const content = (
    <div ref={popoverContentRef}>
      <Input
        value={formattedValue(fieldName, customMonetaryValue)}
        onChange={onChange}
        placeholder={
          monetaryOptions.includes(fieldName) ? 'in millions of dollar' : placeholderMessage
        }
        onKeyDown={handleDropdownKeyEvent}
        tabIndex={0}
      />
      <div className="profile-information__popover-buttons">
        <Button className="profile-information__cancel" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          disabled={isSaveBtnDisabled}
          className="profile-information__save"
          type="primary"
          onClick={isURL ? handleURLSave : handleSave}
        >
          Save
        </Button>
      </div>
    </div>
  );

  if (!isFromGrid && isFromHistory) {
    return (
      <EditPopover
        content={content}
        title={title}
        closePopup={closePopup}
        setClosePopup={setClosePopup}
        hidePenIcon={hidePenIcon}
      >
        {triggerLabel || label}
      </EditPopover>
    );
  }

  return (
    <InlineEdit
      action={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <EditPopover
          content={content}
          title={title}
          closePopup={closePopup}
          setClosePopup={setClosePopup}
        />
      }
      fill={isFromGrid && true}
    >
      <Styled.Wrapper
        className={classnames({ 'd-flex': isShowTrendingLinesEmployees }, 'align-items-center')}
      >
        {isURL && record.linkedin !== '-' && record.linkedin !== null ? (
          <a href={convertToURL(value as string)} target="_blank" rel="noopener noreferrer">
            <LinkedinOutlined style={{ fontSize: 22 }} />
          </a>
        ) : (
          fieldName === 'Linkedin Profile' && DEFAULT_CELL_VALUE
        )}
        {!isURL && (
          <Tooltip title={label} className="flex-grow-1">
            <span
              className={classnames({
                'mr-2':
                  isShowTrendingLinesEmployees &&
                  (record as Company | CompanyProfile).employee_count_trends,
              })}
            >
              {label}
            </span>
          </Tooltip>
        )}
        {isShowTrendingLinesEmployees &&
          renderNumberOfEmployeesTrendline(record as Company | CompanyProfile, period)}
      </Styled.Wrapper>
    </InlineEdit>
  );
};

export default React.memo(EditFieldText);
