import React, { useState } from 'react';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
// redux
import { selectors } from '@redux/contacts/salesloft';
// components
import { SalesloftAddContact } from '@optx/screens/App/SalesloftBulk/components/salesloft/SalesloftAddContact';

const AddContactFormSalesloft = () => {
  const [visible, setVisible] = useState(false);
  const postData = useSelector(selectors.getPostData);
  const contactData = postData
    ? {
        company_id: postData.company_id,
        company_name: postData.company_name,
        company_url: postData.company_website,
      }
    : {};

  return (
    <>
      <Button
        onClick={() => {
          setVisible(true);
        }}
      >
        Add New Contact
      </Button>
      <SalesloftAddContact
        visible={visible}
        contactData={contactData}
        onCancel={() => {
          setVisible(false);
        }}
        maxEmails={4}
      />
    </>
  );
};

export default AddContactFormSalesloft;
