import React, { useEffect, useState } from 'react';
import { Result, Button } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
// constants
import ROUTES from '@optx/constants/routes';
// utils
import { getGridName } from '@optx/utils/equityTouch';
// redux
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';

const ResultWrapper = styled(Result)`
  padding: 48px 32px;
`;

const Completed: React.FC = () => {
  const history = useHistory();
  const successfulCount = useSelector(bulkActionsSelectors.equityTouch.successfulCount);
  const totalCount = useSelector(bulkActionsSelectors.equityTouch.selectedCompaniesCount);
  const listType = useSelector(bulkActionsSelectors.equityTouch.getListType);
  const listId = useSelector(bulkActionsSelectors.equityTouch.getListId);
  const { advancedSearchPathname } = useIsAnalyst();
  const [path, setPath] = useState<string>(
    listType && listId ? `/${listType}/${listId}` : advancedSearchPathname
  );
  const gridName = getGridName();

  useEffect(() => {
    if (gridName?.includes('companiesOutReach')) {
      setPath(ROUTES.reports.sourcingOutreachSummaryCompanies);
    }
  }, [gridName]);

  return (
    <>
      <ResultWrapper
        status={!successfulCount ? 'error' : 'success'}
        title={`${successfulCount || 0}/${totalCount} ${
          totalCount === 1 ? 'Company' : 'Companies'
        } Successfully Added to Equity Touch`}
        subTitle="It may take up to 5 minutes for companies to show in Equity Touch"
        extra={[
          <Button onClick={() => history.push(path)}>
            Back to {listType ? 'List' : 'Advanced Search'}
          </Button>,
        ]}
      />
    </>
  );
};

export default Completed;
