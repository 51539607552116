import { fork, put, takeLatest, take, select } from 'redux-saga/effects';
// constants
import { DEFAULT_SORT_ANALYST, DEFAULT_SORT_COMPANIES } from '../../constants/table';
// redux
import searchSagas from './search';
import filters from './filters';
import { actions } from '../actions';
import { selectors } from '../selectors';

function* initSaga() {
  const filtersLoaded: boolean = yield select(selectors.filters.loaded);

  if (!filtersLoaded) {
    yield put(actions.filters.fetch());
    yield take(actions.filters.fetchSuccess);
    const defaultTableView: 'company' | 'analyst' = yield select(selectors.ui.defaultTableView);
    yield put(actions.card.fetchCard());

    if (defaultTableView === 'analyst') {
      yield put(actions.sort.changeSortSuccess({ sortBy: DEFAULT_SORT_ANALYST }));
      yield put(actions.search.fetchAnalyst());
    } else {
      yield put(actions.sort.changeSortSuccess({ sortBy: DEFAULT_SORT_COMPANIES }));
      yield put(actions.search.fetchCompany());
    }
  }
}

export function* saga() {
  yield fork(searchSagas);
  yield fork(filters);
  yield takeLatest(actions.init, initSaga);
}
