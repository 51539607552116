import React from 'react';
import { ColumnType } from 'antd/lib/table';
// models
import { AllFinancialYearsType, FinancialKeys, TableColumnType } from '@optx/models/Financial';
// components
import { TableCellFinancialValue } from '@optx/shared/view/organims/Table/columns';
import TableYearTitle from '@components/pages/CompanyProfile/ProfileTabs/DealPresentation/components/TableYearTitle';

const initialColumn = {
  title: '',
  dataIndex: 'name',
  fixed: true,
  width: 130,
};

// Function to add a year-estimated objects to the initial data
export const addToUniqueYears = (
  data: AllFinancialYearsType[],
  year: number,
  estimated: boolean
) => {
  data.push({ year, estimated });
};

export const createColumn = (data: AllFinancialYearsType[]) => {
  const columns: ColumnType<TableColumnType>[] = [initialColumn];

  if (data.length > 0) {
    data.forEach((dataItem: AllFinancialYearsType) => {
      columns.push({
        title: () => {
          return <TableYearTitle year={dataItem.year} estimated={dataItem.estimated} />;
        },
        dataIndex: `${dataItem.year}${dataItem.estimated ? '_estimated' : '_actual'}`,
        key: `${dataItem.year}${dataItem.estimated ? '_estimated' : '_actual'}`,
        width: 210,
        render: (value: number, record) => (
          <TableCellFinancialValue
            value={value}
            payload={{
              key: record.name as FinancialKeys,
              year: dataItem.year,
              estimated: dataItem.estimated,
              user: `${record[dataItem.year + '_source_info']}`,
              date: `${record[dataItem.year + '_date']}`,
            }}
            title={`Edit "${record.name}" value`}
            growth={
              record[
                `${dataItem.year}${dataItem.estimated ? '_estimated_growth' : '_actual_growth'}`
              ] as number
            }
          />
        ),
      });
    });
  }

  if (data.length === 0) {
    columns.push({
      title: '',
      dataIndex: 'no_data',
    });
  }

  return columns;
};

/**
 * Rounds a given number to the nearest integer
 *
 * @param {number} value - The number to round
 *
 * @returns {number} The rounded number
 */
export const roundingPresentationValues = (value: number) => {
  const power = Math.pow(10, 0);

  return Math.round(value * power) / power;
};
