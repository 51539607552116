import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { Dictionary } from 'lodash';
// models
import {
  CompanyWatchList,
  AddCompanyToWatchList,
  DeleteCompanyFromWatchList,
} from '@models/WatchList';
import { UserInformation } from '@optx/models/user';
import { SortByRule } from '@models/table/sorting';
import {
  BulkAddToWatchList,
  BulkRemoveFromWatchList,
} from '@optx/features/bulk-actions/bulk-watchlist/state/interfaces';
// utils
import { normalizeData } from '@utils/utils';
import { parseSorting } from '@optx/utils/filters/parseSorting';
// constants
import { FAVORITE_LISTS_POPUP_DEFAULT_SORT } from '@constants/table/sort/defaultSort';
// reducer
import * as bulkWatchlistsActions from '@features/bulk-actions/bulk-watchlist/state/actions';
import { fetchReducer, fetchFailReducer } from '@redux/feature/fetch/reducers';
import { actions as userInformationActions } from '@redux/user/information';
import * as actions from './actions';
import { FavoriteListsPopupState, CreateListPopupSuccessPayload } from './interfaces';

export const initialState: FavoriteListsPopupState = {
  allWatchlists: {},
  allIds: [],
  error: '',
  loading: false,
  fetchedAt: '',
  sortBy: FAVORITE_LISTS_POPUP_DEFAULT_SORT,
  showEmptyLists: true,
};

const initialFetchFavoriteListsPopupSuccessReducer: CaseReducer<
  FavoriteListsPopupState,
  PayloadAction<{
    data: Array<CompanyWatchList>;
    sortBy: Array<SortByRule<any>>;
    showEmptyLists: boolean;
  }>
> = (draftState, action) => {
  const { data, showEmptyLists } = action.payload;

  const allWatchlists: CompanyWatchList[] = data;

  const allWatchlistsById = {} as Dictionary<CompanyWatchList>;

  allWatchlists.forEach((listItem: CompanyWatchList) => {
    allWatchlistsById[listItem.unique_id] = listItem;
  });

  const filteredAllWatchlists = data
    .filter(item => item.share_permission !== 1)
    .reduce(normalizeData('unique_id'), {}) as Dictionary<CompanyWatchList>;

  draftState.allWatchlists = filteredAllWatchlists;

  draftState.allIds = allWatchlists
    .filter(item => item.share_permission !== 1)
    .map(listItem => listItem.unique_id);
  draftState.loading = false;
  draftState.sortBy = action.payload.sortBy;
  draftState.showEmptyLists = showEmptyLists;

  if (!draftState.fetchedAt) {
    draftState.fetchedAt = new Date().toISOString();
  }
};

// fetch favorite lists success reducer
const fetchFavoriteListsPopupSuccessReducer: CaseReducer<
  FavoriteListsPopupState,
  PayloadAction<{
    data: Array<CompanyWatchList>;
    sortBy: Array<SortByRule<any>>;
    showEmptyLists: boolean;
  }>
> = (draftState, action) => {
  const { data, showEmptyLists } = action.payload;
  const allWatchlists: CompanyWatchList[] = data;

  const allWatchlistsById = {} as Dictionary<CompanyWatchList>;

  allWatchlists.forEach((listItem: CompanyWatchList) => {
    allWatchlistsById[listItem.unique_id] = listItem;
  });

  const filteredAllWatchlists = data
    .filter(item => item.share_permission !== 1)
    .reduce(normalizeData('unique_id'), {}) as Dictionary<CompanyWatchList>;

  draftState.allWatchlists = filteredAllWatchlists;

  draftState.allIds = allWatchlists
    .filter(item => item.share_permission !== 1)
    .map(listItem => listItem.unique_id);
  draftState.loading = false;
  draftState.sortBy = action.payload.sortBy;
  draftState.showEmptyLists = showEmptyLists;

  if (!draftState.fetchedAt) {
    draftState.fetchedAt = new Date().toISOString();
  }
};

export const fetchUserInformationSuccessReducer: CaseReducer<
  FavoriteListsPopupState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const sorting = action.payload.settings.session_settings?.favorites_list_popup_sorting;

  if (sorting) {
    const { sortBy } = parseSorting(sorting);
    draftState.sortBy = sortBy;
  }
};

// create list success
const createListPopupSuccessReducer: CaseReducer<
  FavoriteListsPopupState,
  PayloadAction<CreateListPopupSuccessPayload>
> = (draftState, action) => {
  const { list } = action.payload;

  draftState.loading = false;
  draftState.allWatchlists = { ...draftState.allWatchlists, [list.unique_id]: list };
};

// add company to list success
const addCompanyToListPopupSuccessReducer: CaseReducer<
  FavoriteListsPopupState,
  PayloadAction<AddCompanyToWatchList>
> = (draftState, action) => {
  const { watchLists } = action.payload;

  draftState.loading = false;
  watchLists.forEach(list => {
    draftState.allWatchlists[list.unique_id].count =
      Number(draftState.allWatchlists[list.unique_id].count) + watchLists.length;
  });
};

// delete company from list success
const deleteCompanyFromListSuccessReducer: CaseReducer<
  FavoriteListsPopupState,
  PayloadAction<DeleteCompanyFromWatchList>
> = (draftState, action) => {
  draftState.loading = false;
  draftState.allWatchlists[action.payload.listId].count--;
};

const resetFetchedAtReducer: CaseReducer<FavoriteListsPopupState> = draftState => {
  draftState.fetchedAt = '';
};

const bulkRemoveFromWatchListReducer: CaseReducer<
  FavoriteListsPopupState,
  PayloadAction<BulkRemoveFromWatchList>
> = (draftState, action) => {
  const count = action.payload.selectedCompanies.length;
  const { listId } = action.payload;

  if (
    draftState.allWatchlists[listId] &&
    typeof draftState.allWatchlists[listId].count === 'number'
  ) {
    draftState.allWatchlists[listId].count -= count;
  }
};

const bulkAddToWatchListReducer: CaseReducer<
  FavoriteListsPopupState,
  PayloadAction<BulkAddToWatchList>
> = (draftState, action) => {
  const count = action.payload.selectedCompanies.length;
  const { selectedData } = action.payload;

  selectedData.forEach(list => {
    if (draftState.allWatchlists[list.value]) {
      draftState.allWatchlists[list.value].count =
        Number(draftState.allWatchlists[list.value].count) + Number(count);
    }
  });
};

const reducer = createReducer(initialState, builder =>
  builder
    // initial fetch favorites lists
    .addCase(actions.initialFetchFavoritesListsPopup, fetchReducer)
    .addCase(
      actions.initialFetchFavoritesListsPopupSuccess,
      initialFetchFavoriteListsPopupSuccessReducer
    )
    // fetch favorites lists
    .addCase(actions.fetchFavoriteListsPopup, fetchReducer)
    .addCase(actions.fetchFavoriteListsPopupSuccess, fetchFavoriteListsPopupSuccessReducer)
    .addCase(actions.fetchFavoriteListsPopupFail, fetchFailReducer)
    // sort favorites lists
    .addCase(actions.sortFavoriteListsPopup, fetchReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
    // create favorite list
    .addCase(actions.createFavoriteListPopup, fetchReducer)
    .addCase(actions.createFavoriteListPopupSuccess, createListPopupSuccessReducer)
    .addCase(actions.createFavoriteListPopupFail, fetchFailReducer)
    // add company to list
    .addCase(actions.addCompanyToListPopup, fetchReducer)
    .addCase(actions.addCompanyToListPopupSuccess, addCompanyToListPopupSuccessReducer)
    .addCase(actions.addCompanyToListPopupFail, fetchFailReducer)
    // delete company in list
    .addCase(actions.deleteCompanyFromListPopup, fetchReducer)
    .addCase(actions.deleteCompanyFromListPopupSuccess, deleteCompanyFromListSuccessReducer)
    .addCase(actions.deleteCompanyFromListPopupFail, fetchFailReducer)
    // reset fetchedAt
    .addCase(actions.resetFetchedAt, resetFetchedAtReducer)
    // bulk remove
    .addCase(bulkWatchlistsActions.bulkRemoveFromWatchList, bulkRemoveFromWatchListReducer)
    .addCase(bulkWatchlistsActions.bulkAddToWatchList, bulkAddToWatchListReducer)
);

export default reducer;
