// components
import { ImageSkeleton, LineSkeleton } from '../Skeleton';
import Styled from './CompanyActivityCard.styled';

const CompanyActivityCardSkeleton = () => {
  return (
    <Styled.CompanyActivityCard className="ant-card-hoverable">
      <ImageSkeleton width={'42px'} margin={[0, 20, 0, 0]} />
      <div className="content">
        <span className="company">
          <LineSkeleton width={'95px'} margin={[0, 0, 6, 0]} isCenter={false} />
        </span>
        <span className="location">
          <LineSkeleton width={'95px'} isCenter={false} />
        </span>
      </div>
      <div className="stats">
        <LineSkeleton width="60px" />
        <LineSkeleton width="60px" />
      </div>
    </Styled.CompanyActivityCard>
  );
};

export default CompanyActivityCardSkeleton;
