import { useDebounceEffect } from '@optx/common/hooks/useDebounceEffect';
import React, { useEffect, useRef, useState } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const centerAspectCrop = (mediaWidth: number, mediaHeight: number) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      1,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

interface PictureCropProps {
  src: string | null;
  onCropComplete: (imageBlob: Blob | null) => void;
}

const PictureCrop: React.FC<PictureCropProps> = ({ src, onCropComplete }) => {
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const imageRef = useRef<HTMLImageElement>();
  const fileURLRef = useRef<string>('');

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    imageRef.current = e.target as HTMLImageElement;
    const { width, height } = e.target as HTMLImageElement;
    setCrop(centerAspectCrop(width, height));
  };

  const handleCropComplete = async () => {
    if (completedCrop) {
      const imageBlob = await makeClientCrop(completedCrop);
      onCropComplete(imageBlob);
    }
  };

  const getCroppedImg = (image: HTMLImageElement, crop: Crop, fileName: string): Promise<Blob> => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const cropWidth = crop.width || 0;
    const cropHeight = crop.height || 0;
    canvas.width = cropWidth;
    canvas.height = cropHeight;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      ctx.drawImage(
        image,
        (crop.x || 0) * scaleX,
        (crop.y || 0) * scaleY,
        cropWidth * scaleX,
        cropHeight * scaleY,
        0,
        0,
        cropWidth,
        cropHeight
      );
    }

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          // reject(new Error('Canvas is empty'));
          // eslint-disable-next-line no-console
          console.error('Canvas is empty');

          return;
        }

        const newBlob = blob;
        // @ts-ignore
        newBlob.name = fileName;
        window.URL.revokeObjectURL(fileURLRef.current);
        fileURLRef.current = window.URL.createObjectURL(newBlob);
        resolve(newBlob);
      }, 'image/jpeg');
    });
  };

  const makeClientCrop = async (crop: Crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageBlob = await getCroppedImg(imageRef.current, crop, 'newFile.jpeg');

      return croppedImageBlob;
    }

    return null;
  };

  //   clear memory
  useEffect(() => {
    return () => {
      window.URL.revokeObjectURL(fileURLRef.current);
    };
  }, []);

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imageRef?.current) {
        handleCropComplete();
      }
    },
    100,
    // @ts-ignore
    [completedCrop]
  );

  if (!src) {
    return null;
  }

  return (
    <ReactCrop
      crop={crop}
      onChange={c => setCrop(c)}
      aspect={1}
      ruleOfThirds
      circularCrop
      minWidth={60}
      minHeight={60}
      onComplete={c => setCompletedCrop(c)}
    >
      <img src={src} onLoad={handleImageLoad} alt="profile" />
    </ReactCrop>
  );
};

export default React.memo(PictureCrop);
