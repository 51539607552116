import * as typeSuffix from '../constants';

export const createFeaturePrefix = (featureName: string, featureType: string) =>
  `[${featureName}] ${featureType}`;

/**
 * Create loading action types.
 * @param prefix action type prefix.
 * @param type feature type. If type is provided prefix is used as feature name.
 */
export const createActionTypes = (prefix: string, featureType?: string) => {
  let featurePrefix = prefix;

  if (featureType) {
    featurePrefix = createFeaturePrefix(prefix, featureType);
  }

  return {
    SELECTOR: featurePrefix,
    REQUEST: `${featurePrefix}_${typeSuffix.REQUEST}`,
    SUCCESS: `${featurePrefix}_${typeSuffix.SUCCESS}`,
    FAILURE: `${featurePrefix}_${typeSuffix.FAILURE}`,
  };
};
