import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
// models
import { RelationshipManagement } from './interfaces';
// services
import { CompanyService } from '@services/api';
import NotificationService from '@services/NotificationService';
// utils
import { getErrorMessage } from '@utils/api/errors';
// redux
import * as actions from './actions';

export function* fetchRelationshipManagementSaga(action: PayloadAction<number>) {
  const companyId = action.payload;

  try {
    const res: AxiosResponse<null | RelationshipManagement> = yield call(
      CompanyService.fetchRelationshipManagement,
      companyId
    );

    if (res.data) {
      yield put(actions.fetchRelationshipManagementSuccess(res.data));
    } else {
      const errorMessage = 'Failed to fetch Relationship Management info!';
      yield put(actions.fetchRelationshipManagementFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Failed to fetch Relationship Management info!');
    yield put(actions.fetchRelationshipManagementFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* companyRelationshipManagementSaga() {
  yield takeLatest(actions.fetchRelationshipManagement, fetchRelationshipManagementSaga);
}
