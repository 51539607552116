import moment from 'moment';
// models
import CompanyTouch from '@optx/models/company/CompanyTouch';

/**
 * create a URL string for mailto a touch using email attendees and company name
 * @param {CompanyTouch} touch the touch that we will send email for
 * @param {string} companyName the name of company that has this touch
 */
export const sendEmail = (touch: CompanyTouch, companyName: string) => {
  if (touch.attendees_email) {
    const recipients = touch.attendees_email.join(';');
    const date = moment(touch.startdatetime).format('MM/DD/yy h:mm A');
    const subject = touch.subject ? ' - ' + touch.subject : '';
    const emailSubject = companyName + ' - ' + touch.touchtype + subject + ' - ' + date;
    const url = 'mailto:' + recipients + '?subject=' + encodeURIComponent(emailSubject);
    window.location.href = url;
  }
};
