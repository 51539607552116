import { CompanyUserContact, ContactPhone, PhoneType } from '@models/api/contacts';
import { message } from 'antd';
// models
import { EquityTouchDatabaseMatch } from '@models/equityTouch';
import { SelectOption } from '@optx/models/Option';
// constants
import { CONTACT_SELECT_LIMIT } from '@optx/constants/contacts';

/**
 * Get identifier for contact.
 * person_id is not enough as identifier because we can have same person with multiple companies.
 * @param user user contact.
 */
export const getContactId = (contact: CompanyUserContact) =>
  `${contact.person_id}-${contact.company_id}`;

/**
 * when a user selects a phone type we also need to send the phone type name
 * and set is_primary property for each phone item
 * @param {Partial<CompanyUserContact>} contactInformation contact data
 * @param {EquityTouchDatabaseMatch[]} phoneOptions available options for phone types
 */
export const mapPhoneNumbers = (
  contactInformation: Partial<CompanyUserContact>,
  phoneOptions: EquityTouchDatabaseMatch[]
) => {
  const phoneList: ContactPhone[] = [];

  if (
    contactInformation &&
    contactInformation.additional_phones &&
    contactInformation.additional_phones.length
  ) {
    const phones = contactInformation.additional_phones.filter(
      phone => phone.phone_number && phone.phone_type_id
    );

    phones.forEach(phone => {
      const phoneOption = phoneOptions.find(
        option => Number(option.itemId) === phone.phone_type_id
      );

      if (phoneOption) {
        phoneList.push({
          ...phone,
          phone_type: phoneOption.text as PhoneType,
          // for now send all phones with is_primary set to false
          is_primary: false,
        });
      }
    });
  }

  return phoneList;
};

/**
 * function to get phone numbers from api response
 * to the additional phones
 * @param {CompanyUserContact} contact
 */
export const getPhoneNumbers = (
  contact: Partial<CompanyUserContact>,
  phoneOptions: SelectOption[]
) => {
  const {
    phone: existingPhone,
    additional_phones: additionalPhones,
    phone_type: phoneType,
  } = contact;
  const filteredByType = phoneOptions.find(item => item.label === phoneType);

  // if we only have phone prop just send it back
  if (!additionalPhones?.length && existingPhone) {
    return [
      {
        phone_number: existingPhone,
        phone_type_id: filteredByType ? Number(filteredByType.value) : undefined,
        is_primary: true,
      },
    ];
  }

  // if we have both properties populated, send them both back,
  // considering phone prop being the primary phone and displaying first
  if (additionalPhones?.length && existingPhone) {
    // don't concatenate if existing phone is already included in additional phones
    if (additionalPhones.some(phone => phone.phone_number === existingPhone)) {
      return additionalPhones;
    }

    return (
      [
        {
          phone_number: existingPhone as string,
          phone_type_id: filteredByType ? Number(filteredByType.value) : undefined,
          is_primary: true,
        },
      ] as ContactPhone[]
    ).concat(additionalPhones);
  }

  // if only additional_phones prop has data send it back.
  // this usually happens when user doesn't have any phones and adds some through UI
  if (additionalPhones?.length && !existingPhone) {
    return additionalPhones;
  }

  return [{ phone_number: '', phone_type_id: undefined }];
};

/**
 * check whether a contact has an email set
 * @param {CompanyUserContact} contact contact data
 */
export const hasEmail = (contact: CompanyUserContact) => {
  if (contact.email) {
    return true;
  }

  if (contact.emails && contact.emails.length && contact.emails.some(email => !!email.email)) {
    return true;
  }

  return false;
};

/**
 * limit number of selected contacts and show error message
 * @param {number} count number of selected contacts
 */
export const validateContactLimit = (count: number) => {
  if (count > CONTACT_SELECT_LIMIT) {
    message.error(`Cannot select more than ${CONTACT_SELECT_LIMIT} contacts`);

    return false;
  }

  return true;
};

/**
 * format data coming from edit contact form
 * @param {Partial<CompanyUserContact>} contactInformation
 * @param {EquityTouchDatabaseMatch[]} phoneOptions
 */
export const formatEditContactForm = (
  contactInformation: Partial<CompanyUserContact>,
  phoneOptions: EquityTouchDatabaseMatch[]
) => {
  const {
    psg_function: psgFunction,
    slack_channel: slackChannel,
    phone: existingPhone,
    ...rest
  } = contactInformation;
  // .map() gives an error if used on a variable with multiple types, fixed with any[]
  const psgValues = psgFunction
    ? (psgFunction as any[]).map((item: SelectOption<string> | string) => {
        if (typeof item === 'string') {
          return item;
        }

        return item.value;
      })
    : [];
  // .map() gives an error if used on a variable with multiple types, fixed with any[]
  const slackChannelValues = slackChannel
    ? (slackChannel as any[])?.map((item: SelectOption<string> | string) => {
        if (typeof item === 'string') {
          return item;
        }

        return item.value;
      })
    : [];
  const editedValues: Partial<CompanyUserContact> = {
    psg_function: psgValues ? (psgValues as Array<string>) : [],
    slack_channel: psgValues ? (slackChannelValues as Array<string>) : [],

    ...rest,
    phone:
      contactInformation.additional_phones && contactInformation.additional_phones.length === 1
        ? contactInformation.additional_phones.filter(value => value.phone_number !== undefined)[0]
            .phone_number
        : existingPhone,
    email: contactInformation.emails
      ? contactInformation.emails
          .filter(value => value.email !== '')
          .map(value => value.email)
          .join(',')
      : '',
    additional_phones: mapPhoneNumbers(contactInformation, phoneOptions),
  };

  return editedValues;
};

/**
 * map options for psg function and slack channel
 * @param {string} field
 */
export const mapToSelectOptions = (field: Array<string>) => {
  // If there are no values the api sends [""] and we need to format that
  const editedData: Array<SelectOption> = [];

  if (!field || field[0] === '') {
    return [];
  }

  field.forEach(i => {
    editedData.push({ label: i, value: i });
  });

  return editedData;
};
