import React from 'react';
import { Tooltip } from 'antd';
// models
import { TableCellSourceScrubLists } from '@optx/models/table/Cell';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
// components
import CellLink from '../CellLink';

const TableCellDefaultTooltip: React.FC<TableCellSourceScrubLists> = ({ value, row }) => {
  return (
    <CellLink id={row.id?.toString()}>
      <Tooltip className="keep-space-sequence" overlayClassName="keep-space-sequence" title={value}>
        {value || DEFAULT_CELL_VALUE}
      </Tooltip>
    </CellLink>
  );
};

export default TableCellDefaultTooltip;
