import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'antd';
import { useHistory } from 'react-router';
import queryString from 'query-string';
// models
import { CriteriaKeys } from '@features/sourcing-outreach-summary/models';
import { SourcingOutreachCardKey } from '@optx/features/sourcing-outreach-summary/models/SourcingOutreach';
// utils
import mapFiltersToURLParams from '@optx/utils/filters/mapFiltersToURLParams';
// constants
import appRoutes from '@constants/routes';
import cardInfoDictionary from '../../constants/cardInfo';
// redux
import { selectors } from '../../state';
import { actions as viewActions } from '@features/grid/view';
// components
import Styled from './TotalCards.styles';
import CardTitle from './CardTitle';
import Info from './Info';

const getValue = (value?: number) => {
  return typeof value === 'number' ? value : null;
};

const getCardTitle = (value: string) => {
  switch (value) {
    case 'number_touches': {
      return '# of Touches';
    }

    case 'companies_reached': {
      return '# of Companies Touched';
    }

    case 'stage_change': {
      return '# of Stage Changes';
    }

    case 'qualified_opportunities_platform': {
      return '# of New Qualified Ops';
    }

    case 'companies_added': {
      return '# of Companies Added';
    }

    default: {
      return undefined;
    }
  }
};

const TotalCards = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { normalized, filter } = useSelector(selectors.filters.getFilters);
  const query = useSelector(selectors.filters.searchKey);
  const analystFilterData = normalized.analyst_id?.data;

  const queryData = {
    ...mapFiltersToURLParams(normalized, filter),
    ...(query && { outreach_query: query }),
  };

  const payload = queryString.stringify(queryData, {
    arrayFormat: 'comma',
  });

  const cards = useSelector(selectors.search.cards);
  const usersData = useSelector(selectors.search.data);

  const handleCardClick = (cardKey: SourcingOutreachCardKey, value: number) => {
    if (value) {
      let analystName: string | string[] = 'All';

      if (analystFilterData.length > usersData.length) {
        analystName = usersData.map(user => user.user_name);
      }

      dispatch(viewActions.resetViewToSourcingOrder());

      history.push({
        pathname: appRoutes.reports.sourcingOutreachSummaryCompanies,
        state: { column_name: cardKey, analyst_name: analystName, filters: payload },
      });
    }
  };

  const renderInfo = (cardKey: CriteriaKeys) => {
    const info = cardInfoDictionary[cardKey];

    if (info) {
      return <Info info={info} />;
    }

    return null;
  };

  if (!cards) {
    return null;
  }

  return (
    <Styled.CardRow gutter={20} justify="space-between">
      {Object.keys(cards).map(key => {
        if (key === 'qualified_opportunities_addon') {
          return null;
        }

        return (
          <Col key={key}>
            <Styled.Card
              title={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <CardTitle
                  keyName={key}
                  title={getCardTitle(key) || ''}
                  value={getValue(cards[key as SourcingOutreachCardKey]?.value)}
                  trending={getValue(cards[key as SourcingOutreachCardKey]?.growth)}
                />
              }
              hoverable
              onClick={() =>
                handleCardClick(
                  key as SourcingOutreachCardKey,
                  cards[key as SourcingOutreachCardKey]?.value
                )
              }
              $size="small"
              $transparent
              className={undefined}
            >
              {key === 'qualified_opportunities_platform' &&
                renderInfo('qualified_opportunities_ops')}
            </Styled.Card>
          </Col>
        );
      })}
    </Styled.CardRow>
  );
};

export default TotalCards;
