import { FC, useState } from 'react';
// models
import { CompanyProfile } from '@optx/models/Company';
// components
import { CompanyTagsOverflow } from '@optx/features/tags/overflow-tags';
import { CompanyTags } from '@optx/features/tags/tags';

interface CompanyTagsCollapsableProps {
  company: CompanyProfile;
  isAdd?: boolean;
  closable?: boolean;
  showPSGFit?: boolean;
  setTagsCollapsed?: (areTagsCollapsable: boolean) => void;
}

const CompanyTagsCollapsable: FC<CompanyTagsCollapsableProps> = ({
  company,
  isAdd,
  closable,
  showPSGFit,
  setTagsCollapsed,
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const onTagMoreClick = () => {
    setCollapsed(false);
    setTagsCollapsed && setTagsCollapsed(false);
  };

  return (
    <div className="tags-collapsable__wrapper">
      {collapsed ? (
        <CompanyTagsOverflow
          company={company}
          companyId={company.company_id}
          isAdd={isAdd}
          closable={closable}
          onTagMoreClick={onTagMoreClick}
          isCollapsed={collapsed}
          showPSGFit={showPSGFit}
        />
      ) : (
        <CompanyTags company={company} isAdd={isAdd} closable={closable} isCollapsed={collapsed} />
      )}
    </div>
  );
};

export default CompanyTagsCollapsable;
