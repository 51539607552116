import { createReducer } from '@reduxjs/toolkit';
import { actions as userInformationActions } from '@redux/user/information';
import * as actions from './actions';
import { fetchReducer, fetchFailReducer } from '../../feature/fetch/reducers';

import {
  initialState,
  fetchSavedSearchesReducer,
  fetchSavedSearchesSuccessReducer,
  saveSearchSuccessReducer,
  deleteSearchSuccessReducer,
  renameSearchSuccessReducer,
  fetchUserInformationSuccessReducer,
} from '../../feature/saved-search/reducers';

const reducer = createReducer(initialState, builder =>
  builder
    // fetch contacts saved searches
    .addCase(actions.fetchContactSavedSearches, fetchSavedSearchesReducer)
    .addCase(actions.fetchContactSavedSearchesSuccess, fetchSavedSearchesSuccessReducer)
    .addCase(actions.fetchContactSavedSearchesFail, fetchFailReducer)
    // create contact search
    .addCase(actions.saveContactSearch, fetchReducer)
    .addCase(actions.saveContactSearchSuccess, saveSearchSuccessReducer)
    .addCase(actions.saveContactSearchFail, fetchFailReducer)
    // delete search
    .addCase(actions.deleteContactSearch, fetchReducer)
    .addCase(actions.deleteContactSearchSuccess, deleteSearchSuccessReducer)
    .addCase(actions.deleteContactSearchFail, fetchFailReducer)
    // rename search
    .addCase(actions.renameContactSearch, fetchReducer)
    .addCase(actions.renameContactSearchSuccess, renameSearchSuccessReducer)
    .addCase(actions.renameContactSearchFail, fetchFailReducer)
    // external
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
);

export default reducer;
