import { combineReducers } from 'redux';
// models
import { GridState } from '@optx/models/grid';
// reducers
import pagination from './pagination';
import searchCount from './search-count';
import view from './view';
import autocomplete from './autocomplete';
import fetchedCount from './fetched-count';
import filter from './filter';
import searchkey from './searchkey';
import search from './search';
import viewType from './view-type';
import paginationLongCard from './pagination-long-card';
import viewTransition from './view-transition';

const reducer = combineReducers<GridState>({
  pagination,
  searchCount,
  paginationLongCard,
  view,
  autocomplete,
  filter,
  searchkey,
  search,
  fetchedCount,
  viewType,
  viewTransition,
});

export default reducer;
