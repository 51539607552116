import React from 'react';
import moment from 'moment';
// models
import { ChartIntervalKeys, ChartTicks } from '@optx/models/charts/data';

interface CombinedChartTicksProps extends ChartTicks {
  startDate: string;
  endDate: string;
  intervalKey: ChartIntervalKeys;
}

const CombinedChartTicks: React.FC<CombinedChartTicksProps> = ({
  y,
  // the position of the tick inside the tick list array
  index,
  payload,
  // start and end date of the time interval
  startDate,
  endDate,
  // width of the graph
  width,
  intervalKey,
}) => {
  if (typeof payload.value !== 'string') {
    return null;
  }

  // calculations to determine the position of the tick on the x axis
  // the position is calculated in pixels

  // initial position, starting from 0 pixels
  let x = 0;
  // this is used to center the tick to the point on the graph
  let spacing = 10;

  // total number of days in the time period
  const totalDaysInInterval = moment(endDate).diff(moment(startDate), 'days');
  // how many pixels between two days
  const unit = width / totalDaysInInterval;

  // the first tick will always start from 0, no need to calculate anything for it
  if (index > 0) {
    // the first point's date, from the interval's corresponding data,
    // does not always match the starting date of the time interval,
    // so here we calculate how many days are left until the next month
    let days = moment(startDate).daysInMonth() - Number(moment(startDate).format('D'));
    spacing = 15;

    if (intervalKey === '1Y' || intervalKey === '2Y') {
      // here we calculate how many days until the next quarter,
      // depending on the month's position in the quarter
      const monthInQuarter = Number(moment(startDate).format('M')) % 3;

      // if monthInQuarter is 0, it means it's the last month in the quarter, no need to do anything else,
      // otherwise we need to add the rest of the month or months in the quarter
      if (monthInQuarter > 0 && monthInQuarter < 0.5) {
        days += 30;
      } else if (monthInQuarter > 0.5) {
        days += 60;
      }

      spacing = 23;
    }

    // for the rest of the ticks the distance between them is either 30 or 90 days
    for (let i = 1; i < index; i++) {
      if (intervalKey === '1Y' || intervalKey === '2Y') {
        days += 90;
      } else {
        days += moment(startDate).add(i, 'months').daysInMonth();
      }
    }

    x = days * unit;
  }

  // fix for second tick, to prevent overlapping with the first one
  // this is caused by the interval's starting date being very close
  // to the first point's date
  if (index === 1 && x < 30) {
    x = 30;
  }

  // fix for the last tick going out of bounds
  if (x + spacing > width + 10) {
    x = width - spacing + 10; // + 10 to center the tick to the ending of the line
  }

  return (
    <text x={x + spacing} y={y} dy={7} fill="#C2CFE0">
      {payload.value}
    </text>
  );
};

export default CombinedChartTicks;
