import React from 'react';
import { useSelector } from 'react-redux';
// redux
import { selectors } from '@redux/user/information';

const UserPicture = () => {
  const picture = useSelector(selectors.getPicture);

  return (
    <img src={picture} style={{ width: 35, height: 35 }} alt="user" className="rounded-circle" />
  );
};

export default UserPicture;
