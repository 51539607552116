import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
// models
import { ScoreGrowth } from '@optx/models/Company';
import { ChartData, ChartDataPSGDate, ChartIntervalKeys } from '@optx/models/charts/data';
// constants
import { INITIAL_STATE_CHART } from '@optx/constants/charts';
// services
import { CompanyService } from '@services/api';
import NotificationService from '@services/NotificationService';
// utils
import { getErrorMessage } from '@utils/api/errors';
import { parseLineGraphDataWithoutPosition } from '@optx/utils/charts';
import { stripUrlSlashes } from '@optx/utils/url';
// redux
import { selectors as userSelectors } from '@redux/user/information';
import * as actions from './actions';

export function* fetchScoreGrowthSaga(action: PayloadAction<ScoreGrowth>) {
  const { company_url: url } = action.payload;

  const defaultOptxScore: string = yield select(userSelectors.getDefaultScore);

  try {
    const res: AxiosResponse<ChartDataPSGDate> = yield call(
      CompanyService.getScoreGrowth,
      {
        ...action.payload,
        company_url: url ? stripUrlSlashes(url) : '',
      },
      defaultOptxScore
    );
    const psgDate = res.data.psg_date as string | null;

    // @ts-ignore
    delete res.data.psg_date;
    const growthList = res.data as ChartData;

    if (growthList[Object.keys(growthList)[0] as ChartIntervalKeys].data.length) {
      yield put(
        actions.fetchScoreGrowthSuccess({
          data: parseLineGraphDataWithoutPosition(growthList),
          psgDate,
        })
      );
    } else {
      yield put(actions.fetchScoreGrowthSuccess({ data: INITIAL_STATE_CHART, psgDate }));
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Failed to fetch Score Growth!');
    yield put(actions.fetchScoreGrowthFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* scoreGrowthSaga() {
  yield takeLatest(actions.fetchScoreGrowth, fetchScoreGrowthSaga);
}
