import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// models
import { DateRangeOption } from '@optx/models/Option';
// components
import SingleSelectDateRanges from '@optx/components/common/filters/SingleSelectDateRanges';
// redux
import { selectors, actions } from '../../state';
//components
import Styled from './FilterSingleSelectDateFilter.styled';

export const FilterSingleSelectDateFilter = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState<[string | null, string | null]>(['', '']);
  const [options, setOptions] = useState<DateRangeOption[]>([]);
  const {
    normalized: { review_date: normalizedData },
    values: { review_date: valueDate },
  } = useSelector(selectors.filters.getFilters);

  useEffect(() => {
    valueDate && setValue(valueDate as [string | null, string | null]);
  }, [valueDate]);

  useEffect(() => {
    normalizedData && setOptions(normalizedData.data as unknown as DateRangeOption[]);
  }, [normalizedData]);

  const handleChange = (value: any) => {
    dispatch(
      actions.filters.updateFilters({
        value: [value.start, value.end],
        key: 'review_date',
      })
    );
  };

  return (
    <Styled.StyledSelect>
      <SingleSelectDateRanges
        value={value as [string | null, string | null]}
        data={options as any}
        onChange={handleChange}
      />
    </Styled.StyledSelect>
  );
};

export default FilterSingleSelectDateFilter;
