import React from 'react';
import classnames from 'classnames';
import { IconName } from './interfaces';
import { ReactComponent as AddressBookIcon } from '../../../assets/svg/icons/address-book.svg';
import { ReactComponent as StickyNoteIcon } from '../../../assets/svg/icons/sticky-note-o.svg';
import { ReactComponent as ListIcon } from '../../../assets/svg/icons/list.svg';
import { ReactComponent as SearchIcon } from '../../../assets/svg/icons/search.svg';
import { ReactComponent as FileTextIcon } from '../../../assets/svg/icons/file-text.svg';
import { ReactComponent as CheckmarkFill } from '../../../assets/svg/icons/checkmark-fill.svg';
import { ReactComponent as CapterraIcon } from '../../../assets/svg/icons/capterra.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/svg/icons/facebook.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/svg/icons/linkedin.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/svg/icons/twitter.svg';
import { ReactComponent as WebsiteIcon } from '../../../assets/svg/icons/website.svg';
import { ReactComponent as CrunchbaseIcon } from '../../../assets/svg/icons/crunchbase.svg';
import { ReactComponent as G2CrowdIcon } from '../../../assets/svg/icons/g2crowd.svg';
import { ReactComponent as SourceScrubIcon } from '../../../assets/svg/icons/sourcescrub.svg';
import { ReactComponent as EquityTouchIcon } from '../../../assets/svg/icons/equitytouch.svg';
import { ReactComponent as SimilarWebIcon } from '../../../assets/svg/icons/similarweb.svg';
import { ReactComponent as BVDOrbisWebIcon } from '../../../assets/svg/icons/bvdorbis.svg';
import { ReactComponent as Fullscreen } from '../../../assets/svg/icons/fullscreen.svg';
import { ReactComponent as FullscreenExit } from '../../../assets/svg/icons/fullscreen-exit.svg';
import { ReactComponent as PeopleOutlined } from '../../../assets/svg/icons/social/people-outlined.svg';
import { ReactComponent as People } from '../../../assets/svg/icons/social/people.svg';
import { ReactComponent as Close } from '../../../assets/svg/icons/close.svg';
import { ReactComponent as ChromeCloseIcon } from '../../../assets/svg/icons/chrome-close.svg';
import { ReactComponent as Pen } from '../../../assets/svg/icons/pen.svg';
import { ReactComponent as ThumbsUp } from '../../../assets/svg/icons/thumbs-up.svg';
import { ReactComponent as ThumbsDown } from '../../../assets/svg/icons/thumbs-down.svg';
import { ReactComponent as OpenInNew } from '../../../assets/svg/icons/open-in-new.svg';
import { ReactComponent as Bookmark } from '../../../assets/svg/icons/bookmark.svg';
import { ReactComponent as PipelineIcon } from '../../../assets/svg/icons/pipeline.svg';
import { ReactComponent as BusinessIcon } from '../../../assets/svg/icons/business.svg';
import { ReactComponent as ThreeDotsIcon } from '../../../assets/svg/icons/three-dots.svg';
import { ReactComponent as WebsiteRankIcon } from '../../../assets/svg/icons/website-rank.svg';
import { ReactComponent as DollarIcon } from '../../../assets/svg/icons/dollar.svg';
import { ReactComponent as PersonAdd } from '../../../assets/svg/icons/person-add.svg';
import { ReactComponent as ArrowDownEnd } from '../../../assets/svg/icons/arrow-down-end.svg';
import { ReactComponent as Salesloft } from '../../../assets/svg/icons/salesloft.svg';
import { ReactComponent as TrendingDown } from '../../../assets/svg/icons/trending-down.svg';
import { ReactComponent as TrendingUp } from '../../../assets/svg/icons/trending-up.svg';
import { ReactComponent as GridIcon } from '../../../assets/svg/icons/icon-grid.svg';
import { ReactComponent as TilesIcon } from '../../../assets/svg/icons/icon-tiles.svg';
import { ReactComponent as GlassdoorIcon } from '../../../assets/svg/icons/glassdoor.svg';
import { ReactComponent as WebsiteRankBlueIcon } from '../../../assets/svg/icons/website-rank-blue.svg';
import { ReactComponent as ChromeNewTabIcon } from '../../../assets/svg/icons/chrome-new-tab.svg';
import { ReactComponent as ChromeDotsButtonIcon } from '../../../assets/svg/icons/chrome-dots-button.svg';
import { ReactComponent as ChromeOptxLogoIcon } from '../../../assets/svg/icons/chrome-optx-logo.svg';
import { ReactComponent as PinIcon } from '../../../assets/svg/icons/pin.svg';
import { ReactComponent as NoCompanyIcon } from '../../../assets/svg/icons/no-company.svg';
import { ReactComponent as DragAndDrop } from '../../../assets/svg/icons/drag-and-drop.svg';
import { ReactComponent as MapIcon } from '../../../assets/svg/icons/map.svg';
import { ReactComponent as ReportCardIcon } from '../../../assets/svg/icons/report-card-icon.svg';
import { ReactComponent as ArtificialIntelligenceIcon } from '../../../assets/svg/icons/artificial-intelligence.svg';
import { ReactComponent as EmployeeGrowthIcon } from '../../../assets/svg/icons/employee-growth.svg';
import { ReactComponent as OPTXScoreOverIcon } from '../../../assets/svg/icons/optx-score-over.svg';
import { ReactComponent as ThumbUpIcon } from '../../../assets/svg/icons/thumb-up.svg';
import { ReactComponent as ThumbDownIcon } from '../../../assets/svg/icons/thumb-down.svg';
import { ReactComponent as ReloadIcon } from '../../../assets/svg/icons/reload.svg';
import { ReactComponent as NewOpportunitiesIcon } from '../../../assets/svg/icons/new_opportunities.svg';

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  iconName: IconName;
}

const Icon: React.FC<IconProps> = ({ iconName, className, ...iconProps }) => {
  const iconClassName = classnames(`icon icon-${iconName}`, className);

  switch (iconName) {
    case 'address-book':
      return <AddressBookIcon {...iconProps} className={iconClassName} />;
    case 'website-rank':
      return <WebsiteRankIcon {...iconProps} className={iconClassName} />;
    case 'arrow-down-end':
      return <ArrowDownEnd {...iconProps} className={iconClassName} />;
    case 'bookmark':
      return <Bookmark {...iconProps} className={iconClassName} />;
    case 'business':
      return <BusinessIcon {...iconProps} className={iconClassName} />;
    case 'dollar':
      return <DollarIcon {...iconProps} className={iconClassName} />;
    case 'sticky-note-o':
      return <StickyNoteIcon {...iconProps} className={iconClassName} />;
    case 'list':
      return <ListIcon {...iconProps} className={iconClassName} />;
    case 'search':
      return <SearchIcon {...iconProps} className={iconClassName} />;
    case 'file-text':
      return <FileTextIcon {...iconProps} className={iconClassName} />;
    case 'checkmark-fill':
      return <CheckmarkFill {...iconProps} className={iconClassName} />;
    case 'capterra':
      return <CapterraIcon {...iconProps} className={iconClassName} />;
    case 'chrome-new-tab':
      return <ChromeNewTabIcon {...iconProps} className={iconClassName} />;
    case 'chrome-dots-button':
      return <ChromeDotsButtonIcon {...iconProps} className={iconClassName} />;
    case 'chrome-optx-logo':
      return <ChromeOptxLogoIcon {...iconProps} className={iconClassName} />;
    case 'facebook':
      return <FacebookIcon {...iconProps} className={iconClassName} />;
    case 'linkedin':
      return <LinkedinIcon {...iconProps} className={iconClassName} />;
    case 'twitter':
      return <TwitterIcon {...iconProps} className={iconClassName} />;
    case 'website':
      return <WebsiteIcon {...iconProps} className={iconClassName} />;
    case 'crunchbase':
      return <CrunchbaseIcon {...iconProps} className={iconClassName} />;
    case 'g2crowd':
      return <G2CrowdIcon {...iconProps} className={iconClassName} />;
    case 'sourcescrub':
      return <SourceScrubIcon {...iconProps} className={iconClassName} />;
    case 'equitytouch':
      return <EquityTouchIcon {...iconProps} className={iconClassName} />;
    case 'similarweb':
      return <SimilarWebIcon {...iconProps} className={iconClassName} />;
    case 'bvdorbis':
      return <BVDOrbisWebIcon {...iconProps} className={iconClassName} />;
    case 'fullscreen':
      return <Fullscreen {...iconProps} className={iconClassName} />;
    case 'fullscreen-exit':
      return <FullscreenExit {...iconProps} className={iconClassName} />;
    case 'people':
      return <People {...iconProps} className={iconClassName} />;
    case 'people-outlined':
      return <PeopleOutlined {...iconProps} className={iconClassName} />;
    case 'close':
      return <Close {...iconProps} className={iconClassName} />;
    case 'pen':
      return <Pen {...iconProps} className={iconClassName} />;
    case 'person-add':
      return <PersonAdd {...iconProps} className={iconClassName} />;
    case 'thumbs-up':
      return <ThumbsUp {...iconProps} className={iconClassName} />;
    case 'thumbs-down':
      return <ThumbsDown {...iconProps} className={iconClassName} />;
    case 'trending-down':
      return <TrendingDown {...iconProps} className={iconClassName} />;
    case 'trending-up':
      return <TrendingUp {...iconProps} className={iconClassName} />;
    case 'open-in-new':
      return <OpenInNew {...iconProps} className={iconClassName} />;
    case 'pipeline':
      return <PipelineIcon {...iconProps} className={iconClassName} />;
    case 'three-dots':
      return <ThreeDotsIcon {...iconProps} className={iconClassName} />;
    case 'salesloft':
      return <Salesloft {...iconProps} className={iconClassName} />;
    case 'grid':
      return <GridIcon {...iconProps} className={iconClassName} />;
    case 'tiles':
      return <TilesIcon {...iconProps} className={iconClassName} />;
    case 'pin':
      return <PinIcon {...iconProps} className={iconClassName} />;
    case 'no-company':
      return <NoCompanyIcon {...iconProps} className={iconClassName} />;
    case 'glassdoor':
      return <GlassdoorIcon {...iconProps} className={iconClassName} />;
    case 'website-rank-blue':
      return <WebsiteRankBlueIcon {...iconProps} className={iconClassName} />;
    case 'chrome-close':
      return <ChromeCloseIcon {...iconProps} className={iconClassName} />;
    case 'drag-and-drop':
      return <DragAndDrop {...iconProps} className={iconClassName} />;
    case 'map':
      return <MapIcon {...iconProps} className={iconClassName} />;
    case 'report-card-icon':
      return <ReportCardIcon {...iconProps} className={iconClassName} />;
    case 'artificial-intelligence':
      return <ArtificialIntelligenceIcon {...iconProps} className={iconClassName} />;
    case 'employee-growth':
      return <EmployeeGrowthIcon {...iconProps} className={iconClassName} />;
    case 'optx-score-over':
      return <OPTXScoreOverIcon {...iconProps} className={iconClassName} />;
    case 'thumb-up':
      return <ThumbUpIcon {...iconProps} className={iconClassName} />;
    case 'thumb-down':
      return <ThumbDownIcon {...iconProps} className={iconClassName} />;
    case 'reload':
      return <ReloadIcon {...iconProps} className={iconClassName} />;
    case 'new-opportunities':
      return <NewOpportunitiesIcon {...iconProps} className={iconClassName} />;

    default:
      return null;
  }
};

export default Icon;
