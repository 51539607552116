import React from 'react';
import { useSelector } from 'react-redux';
// redux
import { isLoading, shouldFetchTouches } from '@redux/scheduled-touches/selectors';
// components
import FixedLoader from '@optx/components/common/loader/FixedLoader';

const ScheduledTouchesPageLoader = () => {
  const loading = useSelector(isLoading);
  const shouldFetch = useSelector(shouldFetchTouches);

  return <FixedLoader loading={loading || shouldFetch} />;
};

export default ScheduledTouchesPageLoader;
