import { createFeatureActionType } from '@optx/utils/feature';
import featureName from '../featureName';

export const initializeEquityTouchBulk = createFeatureActionType(
  featureName,
  'INITIALIZE_EQUITY_TOUCH_BULK'
);

export const initializeEquityTouchBulkSuccess = createFeatureActionType(
  featureName,
  'INITIALIZE_EQUITY_TOUCH_BULK_SUCCESS'
);

export const initializeIndividualEditing = createFeatureActionType(
  featureName,
  'INDIVIDUAL_EDITING_INTIALIZATION'
);

export const fetchFormDataSuccess = createFeatureActionType(featureName, 'FETCH_FORM_DATA_SUCCESS');

export const addToEquityTouchBulk = createFeatureActionType(
  featureName,
  'ADD_TO_EQUITY_TOUCH_BULK'
);

export const addToEquityTouchBulkSuccess = createFeatureActionType(
  featureName,
  'ADD_TO_EQUITY_TOUCH_BULK_SUCCESS'
);

export const addToEquityTouchBulkFail = createFeatureActionType(
  featureName,
  'ADD_TO_EQUITY_TOUCH_BULK_FAIL'
);

export const fetchCompany = createFeatureActionType(featureName, 'FETCH_COMPANY');
export const fetchCompanySuccess = createFeatureActionType(featureName, 'FETCH_COMPANY_SUCCESS');

export const addBulkContacts = createFeatureActionType(featureName, 'ADD_BULK_CONTACTS');
export const updateBulkContact = createFeatureActionType(featureName, 'UPDATE_BULK_CONTACT');
export const updateBulkCadence = createFeatureActionType(featureName, 'UPDATE_BULK_CADENCE');
export const updateBulkContactSuccess = createFeatureActionType(
  featureName,
  'UPDATE_BULK_CONTACT_SUCCESS'
);

export const updateBulkCompany = createFeatureActionType(featureName, 'UPDATE_BULK_COMPANY');

export const updateProgress = createFeatureActionType(featureName, 'UPDATE_PROGRESS');

export const updateCompleted = createFeatureActionType(featureName, 'UPDATE_COMPLETED');

export const updatePercentage = createFeatureActionType(featureName, 'UPDATE_PERCENTAGE');

export const saveResults = createFeatureActionType(featureName, 'SAVE_RESULTS');

export const resetProgress = createFeatureActionType(featureName, 'RESET_PROGRESS');

export const cancel = createFeatureActionType(featureName, 'CANCEL');

export const resolveSalesloft = createFeatureActionType(featureName, 'RESOLVE_SALESLOFT');
export const resolveSalesloftSuccess = createFeatureActionType(
  featureName,
  'RESOLVE_SALESLOFT_SUCCESS'
);
export const salesloftSelectContactsBulk = createFeatureActionType(
  featureName,
  'SALESLOFT_SELECT_CONTACTS_BULK'
);

export const selectCadenceIndividually = createFeatureActionType(
  featureName,
  'SELECT_CADENCE_INDIVIDUALLY'
);

export const fetchContacts = createFeatureActionType(featureName, 'FETCH_CONTACTS');
export const fetchContactsSuccess = createFeatureActionType(featureName, 'FETCH_CONTACTS_SUCCESS');

export const autoSave = createFeatureActionType(featureName, 'AUTO_SAVE');
export const updateTemporaryData = createFeatureActionType(featureName, 'UPDATE_TEMPORARY_DATA');
