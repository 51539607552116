import React from 'react';
import { Col, Typography } from 'antd';
import { FilterSource, Filter, BaseFilter } from '@optx/models/filters';
import FilterFactory from './FilterFactory';
import './SearchFilterGroup.styles.scss';

const { Title } = Typography;

interface SearchFilterGroupProps {
  source: FilterSource<Filter<any>>;
}

const SearchFilterGroup: React.FC<SearchFilterGroupProps> = ({ source }) => {
  return (
    <Col span={6} xs={24} sm={12} md={12} lg={6}>
      <Title level={4} style={{ fontWeight: 'normal' }}>
        {source.label}
      </Title>
      {source.data.map(filter => (
        <FilterFactory key={filter.column} filter={filter as BaseFilter} source={source} />
      ))}
    </Col>
  );
};

export default SearchFilterGroup;
