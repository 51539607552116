import styled from 'styled-components';

export const RecommendedContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  b {
    color: #1890ff;
    font-weight: bold;
  }
`;
