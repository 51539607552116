import { createFeatureAction } from '@utils/feature';
import featureName from '../../featureName';
import * as actionTypes from './actionTypes';
import { EditCompanyStageActionPayload } from './interfaces';

export const addCompanyStage = createFeatureAction<EditCompanyStageActionPayload>(
  featureName,
  actionTypes.ADD_COMPANY_STAGE
);

export const addCompanyStageFail = createFeatureAction<string>(
  featureName,
  actionTypes.ADD_COMPANY_STAGE_FAIL
);
