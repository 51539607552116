import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { pick, Dictionary } from 'lodash';
// models
import { FilterSource } from '@models/filters';
import { CompanyFieldsGroup } from '@optx/models/companyFields';
import { CompanyUserContact } from '@optx/models/api/contacts';
import { PsgParticipant } from '@models/api/user';
// utils
import { formatInitialValues } from '@utils/touches';
import { SelectOption } from '@optx/models/Option';
import { FieldOptions } from '@optx/features/company/edit-fields/state/interfaces';
// utils
import { normalizeFiltersByColumn } from '@utils/filters/normalize';
// redux
import { actions as contactAction } from '@optx/features/bulk-actions/salesloft';
import * as actions from './actions';
import {
  CompanyTouchesDialogState,
  EditCompanyTouches,
  companyTouchesInfo,
  ChangeErrorText,
  validationErrorsTypes,
} from './interfaces';

const initialState: CompanyTouchesDialogState = {
  shouldInitialFetch: true,
  open: false,
  companyId: -1,
  companyName: '',
  filters: null,
  financials: [],
  initialFinancialState: null,
  hasFinancialsLoaded: false,
  company: {
    company_id: -1,
    company_owner: '',
    owner_img: '',
    company_name: '',
    company_url: '',
    logo_url: '',
    is_in_et: false,
    location: '',
    year_founded: -1,
    company_owner_id: -1,
    stage: '',
    contacts: [],
    pipeline_rank: '',
    source_tag: [],
    stage_rationale: null,
  },
  psgParticipants: [],
  editTouch: null,
  error: '',
  modalTitle: 'Log Complete Touch',
  disableSaveBtnFor: null,
  disableSaveBtnByAdditionalFields: false,
  additionalFieldsError: {
    error_initial_text: '',
    error_text: '',
    error_value: null,
    error_field: '',
  },
  validationErrors: {
    noTouchErrors: false,
    noEditFieldErrors: false,
    noFinancialErrors: false,
  },
};

const initCompanyTouchesDialogReducer: CaseReducer<CompanyTouchesDialogState> = draftState => {
  draftState.shouldInitialFetch = false;
};

const toggleCompanyTouchesDialogReducer: CaseReducer<
  CompanyTouchesDialogState,
  PayloadAction<EditCompanyTouches>
> = (draftState, action) => {
  const { companyId, companyName, company, touch } = action.payload;
  draftState.hasFinancialsLoaded = false;

  draftState.open = !draftState.open;
  draftState.companyId = companyId;
  draftState.companyName = companyName;
  draftState.company = pick(company, companyTouchesInfo);
  draftState.editTouch = touch || null;
  draftState.disableSaveBtnFor = null;

  if (draftState.open) {
    actions.getTouchesFilters();
  }
};

const getTouchCompanyContactsSuccessReducer: CaseReducer<
  CompanyTouchesDialogState,
  PayloadAction<Partial<CompanyUserContact>[]>
> = (draftState, action) => {
  draftState.company.contacts = [...action.payload];
};

const getTouchesFiltersSuccessReducer: CaseReducer<
  CompanyTouchesDialogState,
  PayloadAction<Array<FilterSource>>
> = (draftState, action) => {
  const normalizedByColumn = normalizeFiltersByColumn(action.payload);
  // @ts-ignore
  draftState.filters = normalizedByColumn;
};

const getPsgParticipantsSuccessReducer: CaseReducer<
  CompanyTouchesDialogState,
  PayloadAction<PsgParticipant[]>
> = (draftState, action) => {
  draftState.psgParticipants = action.payload.filter(user => user.has_psg_email || user.is_et_user);
};

const getTouchesFiltersSuccessFail: CaseReducer<
  CompanyTouchesDialogState,
  PayloadAction<string>
> = (draftState, action) => {
  draftState.error = action.payload;
};

const addContactSuccessReducer: CaseReducer<
  CompanyTouchesDialogState,
  PayloadAction<Partial<CompanyUserContact>>
> = (draftState, action) => {
  const contact = action.payload;

  if (draftState.open && draftState.companyId === contact.company_id) {
    if (contact.primary_contact) {
      const newContacts = draftState.company.contacts.map(contact => {
        return contact.primary_contact === true ? { ...contact, primary_contact: false } : contact;
      });
      newContacts.push(contact);
      draftState.company.contacts = newContacts;
    } else {
      draftState.company.contacts.push(contact);
    }
  }
};

const addTouchToCompanyReducer: CaseReducer<CompanyTouchesDialogState> = draftState => {
  draftState.validationErrors = {
    noTouchErrors: false,
    noEditFieldErrors: false,
    noFinancialErrors: false,
  };
};

const addTouchToCompanySuccessReducer: CaseReducer<
  CompanyTouchesDialogState,
  PayloadAction<validationErrorsTypes>
> = (draftState, action) => {
  draftState.validationErrors[action.payload] = true;

  if (draftState.validationErrors.noEditFieldErrors) {
    draftState.additionalFieldsError = {
      error_initial_text: '',
      error_text: '',
      error_value: null,
      error_field: '',
    };
  }

  if (
    draftState.validationErrors.noTouchErrors &&
    draftState.validationErrors.noEditFieldErrors &&
    draftState.validationErrors.noFinancialErrors
  ) {
    draftState.open = false;
  }
};

const changeModalTitleReducer: CaseReducer<CompanyTouchesDialogState, PayloadAction<string>> = (
  draftState,
  action
) => {
  draftState.modalTitle = action.payload;
};

const changeSaveBtnStatusReducer: CaseReducer<
  CompanyTouchesDialogState,
  PayloadAction<string | null>
> = (draftState, action) => {
  draftState.disableSaveBtnFor = action.payload;
};

const changeSaveBtnStatusByAdditionalFieldsReducer: CaseReducer<
  CompanyTouchesDialogState,
  PayloadAction<boolean>
> = (draftState, action) => {
  draftState.disableSaveBtnByAdditionalFields = action.payload;
};

const getTouchFinancialFiltersSuccessReducer: CaseReducer<
  CompanyTouchesDialogState,
  PayloadAction<Array<CompanyFieldsGroup>>
> = (draftState, action) => {
  draftState.financials = action.payload;
  draftState.initialFinancialState = formatInitialValues(action.payload);
  draftState.hasFinancialsLoaded = true;
};

const clearAdditionalFieldsErrorReducer: CaseReducer<CompanyTouchesDialogState> = draftState => {
  draftState.additionalFieldsError = {
    error_initial_text: '',
    error_text: '',
    error_value: null,
    error_field: '',
  };
};

const setAdditionalFieldsErrorReducer: CaseReducer<
  CompanyTouchesDialogState,
  PayloadAction<string, any, Dictionary<SelectOption>>
> = (draftState, action) => {
  const normalizedError = action.payload.toLowerCase();
  const errorReasonValue = action.meta;

  let errorFieldType = 'Company Owner';

  if (normalizedError.includes('rank')) {
    errorFieldType = 'Pipeline Rank';
  } else if (normalizedError.includes('stage')) {
    errorFieldType = 'Stage';
  }

  draftState.additionalFieldsError = {
    error_initial_text: action.payload,
    error_text: action.payload,
    error_value: errorReasonValue,
    error_field: errorFieldType,
  };
};

const changeAdditionalFieldsErrorTextReducer: CaseReducer<
  CompanyTouchesDialogState,
  PayloadAction<ChangeErrorText>
> = (draftState, action) => {
  const { value, fieldName } = action.payload;

  if (draftState.additionalFieldsError.error_value !== null) {
    const errorValue = draftState.additionalFieldsError.error_value;

    if (
      errorValue &&
      fieldName in errorValue &&
      value.value === errorValue[fieldName as FieldOptions]?.value
    ) {
      draftState.additionalFieldsError.error_text =
        draftState.additionalFieldsError.error_initial_text;
    } else {
      draftState.additionalFieldsError.error_text = '';
    }
  }
};

export default createReducer(initialState, builder =>
  builder
    .addCase(actions.initCompanyTouchesDialog, initCompanyTouchesDialogReducer)
    .addCase(actions.toggleCompanyTouchesDialog, toggleCompanyTouchesDialogReducer)
    .addCase(actions.getTouchCompanyContactsSuccess, getTouchCompanyContactsSuccessReducer)
    .addCase(actions.getPsgParticipantsSuccess, getPsgParticipantsSuccessReducer)
    .addCase(actions.getTouchesFiltersSuccess, getTouchesFiltersSuccessReducer)
    .addCase(actions.getTouchesFiltersFail, getTouchesFiltersSuccessFail)
    .addCase(contactAction.addContactSuccess, addContactSuccessReducer)
    .addCase(actions.addTouchToCompany, addTouchToCompanyReducer)
    .addCase(actions.addTouchToCompanyErrorValidation, addTouchToCompanySuccessReducer)
    .addCase(actions.getTouchFinancialFiltersSuccess, getTouchFinancialFiltersSuccessReducer)
    .addCase(actions.changeModalTitle, changeModalTitleReducer)
    .addCase(actions.changeSaveBtnStatus, changeSaveBtnStatusReducer)
    .addCase(
      actions.changeSaveBtnStatusByAdditionalFields,
      changeSaveBtnStatusByAdditionalFieldsReducer
    )
    .addCase(actions.clearAdditionalFieldsError, clearAdditionalFieldsErrorReducer)
    .addCase(actions.setAdditionalFieldsError, setAdditionalFieldsErrorReducer)
    .addCase(actions.changeAdditionalFieldsErrorText, changeAdditionalFieldsErrorTextReducer)
);
