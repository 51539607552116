import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// redux
import { actions as searchActions, selectors as searchSelectors } from '@features/grid/search';
import { selectors as listDetailsSelectors } from '@redux/lists/details';
import { FixedLoaderWithCancel } from '@components/common/loader';
import * as selectors from './selectors';

const PageLoader: React.FC = () => {
  const isSourceScrub = useSelector(listDetailsSelectors.isSourceScrubRoute);
  const loading = useSelector(selectors.isLoading(isSourceScrub));
  const saveSearchLoading = useSelector(selectors.saveSearchLoading);
  const isInitialSearch = useSelector(searchSelectors.isInitialSearch('lists'));
  const hideCancelSearch = useSelector(searchSelectors.hideCancelSearch('lists'));

  const [localLoading, setlocalLoading] = useState(loading);

  const dispatch = useDispatch();

  useEffect(() => {
    // This makes the loader close after the cancel button.
    if (!loading && !isInitialSearch) {
      setTimeout(() => {
        setlocalLoading(loading);
      }, 100);
    } else {
      setlocalLoading(loading);
    }
  }, [loading, isInitialSearch]);

  const cancelSearch = () => {
    dispatch(
      searchActions.cancelCompanySearch({
        gridKey: 'lists',
        data: true,
      })
    );
  };

  const showCancelButton = () => {
    // @ts-ignore
    if (hideCancelSearch) {
      return false;
    }

    if (saveSearchLoading && !isInitialSearch) {
      return true;
    }

    return undefined;
  };

  return (
    <FixedLoaderWithCancel
      loading={localLoading}
      showCancel={showCancelButton()}
      onCancel={cancelSearch}
    />
  );
};

export default React.memo(PageLoader);
