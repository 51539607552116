import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from 'antd';
// redux
import { selectors as searchSelectors } from '@redux/lists/search/search';
import { actions as sortActions } from '@redux/lists/search/sort';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';

const ClearSortButton = ({ onClick, ...restProps }: MenuItemProps) => {
  const dispatch = useDispatch();
  const sorting = useSelector(searchSelectors.getSorting);
  const clearSort = () => dispatch(sortActions.resetSortAction());

  if (!sorting.length) {
    return null;
  }

  return (
    <Menu.Item onClick={clearSort} {...restProps}>
      Clear All Sorting
    </Menu.Item>
  );
};

export default ClearSortButton;
