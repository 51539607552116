import { takeLatest, call, put, select, fork } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'react-router-redux';
import queryString from 'query-string';
// constants
import { LISTS_ROUTES } from '@constants/routes';
// models
import { CompanyWatchList, FetchFavoriteLists } from '@models/WatchList';
import { InitialFavoriteListsSearchData } from '@models/lists/search';
import {
  SortingRule,
  CreateListPopupPayload,
  CompanyToListPayload,
  DeleteCompanyFromList,
} from '@redux/favorite-lists/lists-popup/interfaces';
// services
import { SavedSearchesService, UserService } from '@services/api';
import NotificationService from '@services/NotificationService';
// utils
import { mapSortQuery } from '@optx/utils/search';
// redux
import { selectors as userSelectors, actions as userActions } from '@redux/user/information';
import { deleteCompanyInCurrentList } from '@redux/lists/details/actions';
import { actions as searchActions } from '@features/grid/search';
import { actions as customGlobalLoaderActions } from '@features/custom-global-loader';
import { getErrorMessage } from '@optx/utils/api/errors';
import { SortByRule } from '@optx/models/table/sorting';
import { AxiosResponse } from 'axios';
import * as actions from './actions';
import * as favoriteListsSelectors from './selectors';
import companyListsSagas from '../company-lists/sagas';
import { searchLists } from './sagasReusable';

function* searchListsFail() {
  const errorMessage = 'Error fetching watch lists!';
  yield put(actions.fetchFavoriteListsPopupFail(errorMessage));
  NotificationService.error(errorMessage);
}

function* initialFetchFavoriteListsPopupSaga(
  action: PayloadAction<FetchFavoriteLists | undefined>
) {
  const fetchAll = action.payload?.fetchAll;
  const showEmptyLists = action.payload?.showEmptyLists;
  const sorting: SortByRule<any>[] = yield select(favoriteListsSelectors.getSortBy);
  const searchData: InitialFavoriteListsSearchData = {
    sortBy: sorting,
    fetchAll,
    showEmptyLists: showEmptyLists ?? true,
    popup: true,
  };

  try {
    // @ts-ignore
    const res = yield call(SavedSearchesService.initialFetchFavoriteLists, searchData);

    if (res.data) {
      yield put(
        actions.initialFetchFavoritesListsPopupSuccess({
          data: res.data.lists,
          ...searchData,
          showEmptyLists: searchData.showEmptyLists,
        })
      );
    }
  } catch (e: any) {
    yield call(searchListsFail);
  }
}

function* fetchFavoriteListsPopupSaga(action: PayloadAction<FetchFavoriteLists | undefined>) {
  const fetchAll = action.payload?.fetchAll;
  const showEmptyLists = action.payload?.showEmptyLists;
  const sorting: SortByRule<any>[] = yield select(favoriteListsSelectors.getSortBy);
  const searchData: InitialFavoriteListsSearchData = {
    sortBy: sorting,
    fetchAll,
    showEmptyLists: showEmptyLists ?? true,
    popup: true,
  };

  try {
    yield call(searchLists, searchData);

    yield put(actions.resetFetchedAt());
  } catch (e: any) {
    yield call(searchListsFail);
  }
}

function* sortFavoriteListsPopupSaga(action: PayloadAction<SortingRule>) {
  const sorting = action.payload.sorting;
  const showEmptyLists: boolean = yield select(favoriteListsSelectors.showEmptyLists);
  let sortBy = [{ id: 'recently_used' as string, desc: true }];

  switch (sorting) {
    case 'recently':
      sortBy = [{ id: 'recently_used' as string, desc: true }];
      break;
    case 'title-asc':
      sortBy = [{ id: 'title' as string, desc: false }];
      break;
    case 'title-desc':
      sortBy = [{ id: 'title' as string, desc: true }];
      break;
    case 'owner':
      sortBy = [{ id: 'owner_name' as string, desc: false }];
      break;
  }

  const searchData: InitialFavoriteListsSearchData = {
    sortBy,
    fetchAll: true,
    showEmptyLists,
    popup: true,
  };
  const sortQuery = queryString.stringify(mapSortQuery(sortBy), {
    arrayFormat: 'comma',
  });

  try {
    yield call(searchLists, searchData);

    yield put(userActions.updateUserSettings({ favorites_list_popup_sorting: sortQuery }));
  } catch (e: any) {
    yield call(searchListsFail);
  }
}

function* createFavoriteListPopupSaga(action: PayloadAction<CreateListPopupPayload>) {
  const { payload } = action;

  try {
    const res: AxiosResponse<CompanyWatchList> = yield call(
      SavedSearchesService.addCompanyToList,
      action.payload
    );
    const sorting: SortByRule<any>[] = yield select(favoriteListsSelectors.getSortBy);
    yield put(
      actions.createFavoriteListPopupSuccess({ list: res.data, companyId: payload.company_id })
    );

    yield put(actions.fetchFavoriteListsPopup({ query: '', sortBy: sorting, fetchAll: true }));

    NotificationService.success(`Create watchlist ${payload.title} success!`);
  } catch (error: any) {
    const message = getErrorMessage(error, 'Failed to add to Watchlist!');
    NotificationService.error(message);
    yield put(actions.createFavoriteListPopupFail(message));
  }
}

function* addCompanyToListPopupSaga(action: PayloadAction<CompanyToListPayload>) {
  const { company_id: companyId, selectedLists } = action.payload;
  const titles: string[] = selectedLists.map(item => item.list_title);

  const existingListIds: number[] = [];
  const newTitles: string[] = [];

  yield put(customGlobalLoaderActions.toggle({ loading: true, customText: '' }));

  selectedLists.flat().map(data => {
    if (data.list_id !== null) {
      return existingListIds.push(Number(data.list_id));
    }

    return newTitles.push(data.list_title.toString());
  });

  try {
    // @ts-ignore
    const res = yield call(UserService.bulkAddToWatchlist, [companyId], existingListIds, newTitles);

    if (res.data) {
      const failureListIds: number[] = [];
      const successListIds: number[] = [];

      res.data.map((item: { status: boolean; message: string; list_id: number }) => {
        if (item.status) {
          return successListIds.push(item.list_id);
        }

        return failureListIds.push(item.list_id);
      });

      if (failureListIds.length > 0) {
        const list: CompanyWatchList[] = yield select(
          favoriteListsSelectors.getWatchlists(failureListIds)
        );
        const titles: string[] = list.map((item: CompanyWatchList) => item.title);
        NotificationService.error(`Failed to add company to ${titles.join(', ')}`);
      }

      if (successListIds.length > 0) {
        const list: CompanyWatchList[] = yield select(
          favoriteListsSelectors.getWatchlists(successListIds)
        );

        const ownerName: string = yield select(userSelectors.getFullName);
        yield put(actions.addCompanyToListPopupSuccess(companyId, list, ownerName));

        const titles: string[] = list.map((item: CompanyWatchList) => item.title);
        NotificationService.success(`Company was added to ${titles.join(', ')} list(s)!`);
      }
    } else {
      const errorMessage = res.data[0].message;

      yield put(actions.addCompanyToListPopupFail(errorMessage));
      NotificationService.error(errorMessage);
    }

    yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
  } catch (error: any) {
    let errorMessage = '';

    if (error.response.status === 409) {
      errorMessage = 'Company is already part of this list';
    } else if (Object.values(error.response.data.errors)[0] === 'Company is not in Equity Touch') {
      errorMessage = Object.values(error.response.data.errors)[0] as string;
    } else {
      errorMessage = `Failed to add company to ${titles.join(', ')}!`;
    }

    yield put(actions.addCompanyToListPopupFail(errorMessage));
    NotificationService.error(errorMessage);

    yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
  }
}

export function* deleteCompanyFromListSaga(action: PayloadAction<DeleteCompanyFromList>) {
  const { payload } = action;

  try {
    // @ts-ignore
    const res = yield call(SavedSearchesService.deleteCompanyInList, payload);

    if (res.data) {
      yield put(
        actions.deleteCompanyFromListPopupSuccess({
          companyId: payload.company_id,
          listId: payload.list_id,
        })
      );

      const lists: CompanyWatchList[] = yield select(favoriteListsSelectors.getListsNormalized);
      const list = (lists as CompanyWatchList[]).find(
        (item: CompanyWatchList) => item.unique_id === Number(payload.list_id)
      );

      if (list && list.count === 0) {
        const isSourceScrub = window.location.pathname.substr(1).startsWith('ss');

        if (isSourceScrub) {
          yield put(push(LISTS_ROUTES.sourceScrubLists));
        } else {
          yield put(push(LISTS_ROUTES.myWatchList));
        }
      }
    }

    yield put(searchActions.initialCompaniesSearch({ gridKey: 'lists', data: undefined }));
    yield put(deleteCompanyInCurrentList(payload.company_id));

    NotificationService.success('Delete company from list success!');
  } catch (e: any) {
    const errorMessage = 'Delete company from list fail!';
    yield put(actions.deleteCompanyFromListPopupFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* favoriteListsPopupSagas() {
  yield takeLatest(actions.initialFetchFavoritesListsPopup, initialFetchFavoriteListsPopupSaga);
  yield takeLatest(actions.fetchFavoriteListsPopup, fetchFavoriteListsPopupSaga);
  yield takeLatest(actions.sortFavoriteListsPopup, sortFavoriteListsPopupSaga);
  yield takeLatest(actions.createFavoriteListPopup, createFavoriteListPopupSaga);
  yield takeLatest(actions.addCompanyToListPopup, addCompanyToListPopupSaga);
  yield takeLatest(actions.deleteCompanyFromListPopup, deleteCompanyFromListSaga);
  yield fork(companyListsSagas);
}
