import { call, put, takeLatest, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { UserService, CompanyService } from '@services/api';
import NotificationService from '@services/NotificationService';
import { getErrorMessage } from '@optx/utils/api/errors';
import { NotificationPayload } from '@optx/models/notifications';
import { initialState } from './reducer';
import * as types from './types';
import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';
import { NotificationsResponse } from './interfaces';

const { PAGE_SIZE } = constants;

export function* fetchAlertsSaga(data: NotificationPayload) {
  try {
    // @ts-ignore
    const res = yield call(
      UserService.getAlerts,
      data.history,
      data.pageNr,
      data.pageSize,
      data.sorting
    );

    if (res.data) {
      const previousPageNum: number = yield select(selectors.getAlertsPageNumber);
      const response: NotificationsResponse = {
        response: res.data.notifications,
        history: data.history!,
        pageNr: data.pageSize! > PAGE_SIZE ? previousPageNum : data.pageNr!,
        sorting: data.sorting!,
        newAlertCount: res.data.new_alert_count,
        roadmapUrl: res.data.roadmap_url,
      };
      yield put(actions.fetchAlertsSuccess(response));
    } else {
      const errorMessage = 'Failed to fetch Alerts!';
      const err = {
        errorMessage,
        history: data.history,
      };
      yield put(actions.fetchAlertsFail(err));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    if (!error.request.responseText.includes('User is not a company owner')) {
      const errorMessage = getErrorMessage(error, 'Failed to fetch Alerts!');
      const err = {
        errorMessage,
        history: data.history,
      };

      yield put(actions.fetchAlertsFail(err));
      NotificationService.error(errorMessage);
    }
  }
}

export function* fetchAlertsHistorySaga() {
  const dataHistory: NotificationPayload = {
    history: true,
    pageNr: initialState.history.pageNr,
    pageSize: PAGE_SIZE,
    sorting: initialState.history.sorting,
  };

  yield call(fetchAlertsSaga, dataHistory);
}

export function* fetchAlertsInboxSaga() {
  const previousPageNum: number = yield select(selectors.getAlertsPageNumber);
  const dataInbox: NotificationPayload = {
    history: false,
    pageNr: previousPageNum > 1 ? 1 : previousPageNum,
    pageSize: previousPageNum > 1 ? previousPageNum * PAGE_SIZE : PAGE_SIZE,
    sorting: initialState.inbox.sorting,
  };
  yield call(fetchAlertsSaga, dataInbox);
}

export function* sortAlertsHistorySaga(action: PayloadAction<NotificationPayload>) {
  const data = {
    history: true,
    pageNr: 1,
    pageSize: PAGE_SIZE,
    sorting: action.payload.sorting,
  };

  yield call(fetchAlertsSaga, data);
}

export function* loadMoreAlertsSaga(action: PayloadAction<NotificationPayload>) {
  const { history, pageNr, sorting } = action.payload;

  const data = {
    history,
    pageNr: pageNr! + 1,
    pageSize: PAGE_SIZE,
    sorting,
  };

  yield call(fetchAlertsSaga, data);
}

export function* updateAlertsSaga(action: PayloadAction<Array<number>>) {
  try {
    yield call(UserService.updateAlerts, action.payload);
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to update Alerts!');
    NotificationService.error(errorMessage);
  }
}

export function* dismissAlertSaga(action: PayloadAction<number>) {
  try {
    yield call(UserService.dismissAlert, action.payload);
    yield put(actions.dismissAlertSuccess(action.payload));
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to dismiss Alert!');
    NotificationService.error(errorMessage);
  }
}

export function* fetchUpcomingTouchesSaga() {
  try {
    // @ts-ignore
    const res = yield call(CompanyService.getUpcomingTouches);

    if (res.data) {
      yield put(
        actions.fetchTouchesSuccess({
          response: {
            upcomingTouches: res.data.upcoming_touches,
            pastdueTouches: res.data.pastdue_touches,
          },
        })
      );
    } else {
      const errorMessage = 'Failed to fetch Upcoming Touches!';
      const err = {
        errorMessage,
      };
      yield put(actions.fetchTouchesFail(err));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Get Upcoming Touches fail! Server error!');
    const err = {
      errorMessage,
    };
    yield put(actions.fetchTouchesFail(err));
    NotificationService.error(errorMessage);
  }
}

export function* deleteUpcomingTouchSaga(action: PayloadAction<number>) {
  const upcomingTouchId = action.payload;

  try {
    const res: AxiosResponse<number> = yield call(CompanyService.deleteTouch, upcomingTouchId);

    if (res.data) {
      NotificationService.success('Delete Upcoming Touch success!');
      yield put(actions.deleteUpcomingTouchSuccess(upcomingTouchId));
    } else {
      const errorMessage = 'Delete Upcoming Touch fail!';
      const err = {
        errorMessage,
      };
      yield put(actions.deleteUpcomingTouchFail(err));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Delete Upcoming Touch fail! Server error!');
    const err = {
      errorMessage,
    };
    yield put(actions.deleteUpcomingTouchFail(err));
    NotificationService.error(errorMessage);
  }
}

export function* deletePastDueTouchSaga(action: PayloadAction<number>) {
  const pastDueTouchId = action.payload;

  try {
    const res: AxiosResponse<number> = yield call(CompanyService.deleteTouch, pastDueTouchId);

    if (res.data) {
      NotificationService.success('Delete Past Due Touch success!');
      yield put(actions.deletePastDueTouchSuccess(pastDueTouchId));
    } else {
      const errorMessage = 'Delete Past Due Touch fail!';
      const err = {
        errorMessage,
      };
      yield put(actions.deletePastDueTouchFail(err));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Delete Past Due Touch fail! Server error!');
    const err = {
      errorMessage,
    };
    yield put(actions.deletePastDueTouchFail(err));
    NotificationService.error(errorMessage);
  }
}

export default function* alertsSaga() {
  yield takeLatest(types.FETCH_ALERTS_INBOX, fetchAlertsInboxSaga);
  yield takeLatest(types.FETCH_ALERTS_HISTORY, fetchAlertsHistorySaga);
  yield takeLatest(types.SORT_ALERTS_HISTORY, sortAlertsHistorySaga);
  yield takeLatest(types.UPDATE_ALERTS, updateAlertsSaga);
  yield takeLatest(types.LOAD_MORE_ALERTS, loadMoreAlertsSaga);
  yield takeLatest(types.DISMISS_ALERT, dismissAlertSaga);
  yield takeLatest(types.FETCH_TOUCHES, fetchUpcomingTouchesSaga);
  yield takeLatest(types.DELETE_UPCOMING_TOUCH, deleteUpcomingTouchSaga);
  yield takeLatest(types.DELETE_PAST_DUE_TOUCH, deletePastDueTouchSaga);
}
