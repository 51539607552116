export const FECTH_COMPANY_FILTERS = 'FECTH_COMPANY_FILTERS';
export const FECTH_COMPANY_FILTERS_SUCCESS = 'FECTH_COMPANY_FILTERS_SUCCESS';
export const FECTH_COMPANY_FILTERS_FAILURE = 'FECTH_COMPANY_FILTERS_FAILURE';
export const STOP_FETCHING_FILTERS = 'STOP_FETCHING_FILTERS';

export const CHANGE_DEFAULT_VIEW = 'CHANGE_DEFAULT_VIEW';

export const SET_FORM_RATIONALES = 'SET_FORM_RATIONALES';
export const SET_NEXT_STEPS = 'SET_NEXT_STEPS';

export const SET_ALERT_SETTINGS = 'SET_ALERT_SETTINGS';
