import { createSelector } from 'reselect';
import queryString from 'query-string';
// models
import { FiltersState } from '../interfaces';
import { SpecialFilterTag } from '@optx/models/tags';
// utils
import mapFiltersToURLParams from '@optx/utils/filters/mapFiltersToURLParams';
import mapTags from '@optx/utils/filters/mapTags';
import { generateUniqueKey } from '@optx/utils/uuid';

export const loaded = (state: FiltersState) => !!state.fetchedAt;

export const getFilter = (state: FiltersState) => state.filter;

export const sources = (state: FiltersState) => state.data;

export const getDefaultFilter = (state: FiltersState) => state.preselected;

export const getClearedFilter = (state: FiltersState) => state.clear;

export const getNormalizedFilters = (state: FiltersState) => state.normalized;

export const getSearchKey = (state: FiltersState, searchKey: string) => searchKey;

export const filterQuery = (state: FiltersState) => {
  const { normalized, filter } = state;

  return queryString.stringify(mapFiltersToURLParams(normalized, filter), {
    arrayFormat: 'comma',
  });
};

export const getFilters = (state: FiltersState) => {
  const { normalized, filter, values } = state;

  return { normalized, filter, values };
};

export const getValues = (state: FiltersState) => {
  const { values } = state;

  return values;
};

export const getTags = createSelector(
  getValues,
  sources,
  getClearedFilter,
  getSearchKey,
  (values, filterSources, clearedFilter, searchKey) => {
    const tags = mapTags(values, filterSources, clearedFilter, [], {
      date_range_radio: 0,
    });

    if (searchKey) {
      tags.unshift({
        filter: 'keyword' as SpecialFilterTag,
        uniqueKey: generateUniqueKey(),
        label: 'Keyword',
        isDefault: false,
        values: [
          {
            value: searchKey,
          },
        ],
      });
    }

    return tags;
  }
);

export const isClearedFilter = createSelector(getTags, tags => {
  const tagNotDefault = tags.find(tag => {
    if (tag.isDefault === false && tag.values?.length > 0) {
      return true;
    }

    return false;
  });

  return !!tagNotDefault;
});
