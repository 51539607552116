import React, { useState } from 'react';
import { Button } from 'antd';
import { SalesloftAddContact } from '@optx/screens/App/SalesloftBulk/components/salesloft/SalesloftAddContact';
import { useSelector } from 'react-redux';
import { selectors as profileSelectors } from '@redux/company/profile';

const AddContactProfile = () => {
  const [visible, setVisible] = useState(false);
  const company = useSelector(profileSelectors.getProfile);

  const contactData = company
    ? {
        company_id: company.company_id,
        company_name: company.company_name,
        company_url: company.company_url,
      }
    : {};

  return (
    <>
      <Button
        onClick={() => {
          setVisible(true);
        }}
      >
        {' '}
        Add Contact
      </Button>
      <SalesloftAddContact
        visible={visible}
        contactData={contactData}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </>
  );
};

export default AddContactProfile;
