import queryString from 'query-string';
import { SearchPayload } from '@optx/redux/user/source-scrub-lists/interfaces';
import ApiClient, { API_URL } from './axios-client';

class UserService {
  static client: ApiClient = new ApiClient(`${API_URL}/lists`);

  // fetch source scrub lists
  static searchSourceScrubLists(searchData: SearchPayload) {
    const payload = {
      ...(searchData.query && { query: searchData.query }),
      page: searchData.pagination.pageNumber,
      per_page: searchData.pagination.pageSize,
      ...(searchData.sortBy.length && { sort_by: searchData.sortBy[0].id }),
      ...(searchData.sortBy.length && { sort_order: searchData.sortBy[0].desc ? 'desc' : 'asc' }),
    };

    return UserService.client.get(
      `/source_scrub?${queryString.stringify(payload, { arrayFormat: 'comma' })}`
    );
  }

  // fetch source scrub total
  static getSourceScrubListsTotal() {
    return UserService.client.get('/source_scrub/count');
  }

  // fetch source scrub list by id
  static getSourceScrubList(listId: number) {
    const payload = { list_id: listId };

    return UserService.client.get(`/source_scrub?${queryString.stringify(payload)}`);
  }
}

export default UserService;
