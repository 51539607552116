import React from 'react';
import { Typography } from 'antd';
// interface
import { MyCompaniesCardProps } from './interface';
// components
import MyCompaniesCardIcon from './MyCompaniesCardIcon';

const MyCompaniesCardTitle: React.FC<MyCompaniesCardProps> = ({ data }) => {
  const { title, icon, secondTitle } = data;

  return (
    <div className="my-companies-card__title">
      {icon ? (
        <div className="my-companies-card__title-icon">
          <MyCompaniesCardIcon type={icon} />
        </div>
      ) : null}
      <div className="my-companies-card__title-text">
        <Typography.Text>{title}</Typography.Text>
      </div>
      {secondTitle ? (
        <div className="my-companies-card__title-total">
          <Typography.Text>{secondTitle}</Typography.Text>
        </div>
      ) : null}
    </div>
  );
};

export default MyCompaniesCardTitle;
