import React, { useContext } from 'react';
import { Row, Col, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { pick } from 'lodash';
// models
import { CompanyProfile } from '@optx/models/Company';
import { SalesloftContact } from '@optx/models/Salesloft';
// utils
import { getSalesloftEmails } from '@optx/utils/salesloft';
// redux
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
// components
import SelectContacts from '@features/bulk-actions/equity-touch/components/SelectContacts';
import SelectContactsIndividually from '@optx/features/bulk-actions/equity-touch/components/SelectContactsIndividually';
import CadenceTitle from '../common/CardTitle';
import SelectCadence from './SelectCadence';
import { CardWrapper } from '../EquityTouchBulk.styled';
import { Context } from '../common/Context';

interface AddCadenceProps {
  readOnly?: boolean;
}

const AddCadence: React.FC<AddCadenceProps> = ({ readOnly }) => {
  const { step } = useContext(Context);

  const companyData = useSelector(
    bulkActionsSelectors.equityTouch.getCompanyData
  ) as CompanyProfile;
  let contacts: SalesloftContact[] = [];

  if (step === 1 && companyData && Object.keys(companyData).length) {
    contacts = companyData.contacts.map(contact => ({
      ...pick(contact, ['first_name', 'last_name', 'person_id', 'title', 'primary_contact']),
      ...getSalesloftEmails(contact.email),
    }));
  }

  return (
    <CardWrapper
      title={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <CadenceTitle
          title="Also add to Sales Loft Cadence (optional)"
          subtitle="The primary contact from each company will automatically be added to this SL Cadence"
        />
      }
    >
      <Row gutter={20} className="add-to-equity-touch__salesloft">
        <Col span={12} className="sl-cadence">
          <Typography.Text>SLCadence</Typography.Text>
          <SelectCadence readOnly={readOnly} />
        </Col>
        <Col span={12}>
          {step === 1 && (
            <>
              <Typography.Text>Contacts</Typography.Text>
              {step === 1 ? (
                <SelectContactsIndividually
                  contacts={contacts}
                  companyId={companyData.company_id}
                />
              ) : (
                <SelectContacts contacts={contacts} companyId={companyData.company_id} />
              )}
            </>
          )}
        </Col>
      </Row>
    </CardWrapper>
  );
};

export default AddCadence;
