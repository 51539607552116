import { takeLatest, call, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { CompanyService } from '@services/api';
import { CompanyTouchesResponse } from '@optx/models/company/CompanyTouch';
import NotificationService from '@services/NotificationService';
import { getErrorMessage } from '@optx/utils/api/errors';
import * as actions from '../actions/search';
import { SearchTouchesPayload } from '../interfaces';

export function* fetchCompanyTouchesSaga(
  action: PayloadAction<SearchTouchesPayload, any, boolean>
) {
  const searchPayload = action.payload;

  try {
    const res: AxiosResponse<CompanyTouchesResponse> = yield call(
      CompanyService.fetchTouches,
      searchPayload
    );
    yield put(actions.fetchTouchesSuccess(res.data, searchPayload));
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Error fetch company touches!');
    yield put(actions.fetchTouchesFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* companyTouchesSaga() {
  yield takeLatest(actions.fetchTouches.type, fetchCompanyTouchesSaga);
}
