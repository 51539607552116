import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import reducer, { saga } from '../../../redux/company/relationship-management';

const useInjectCompanyRelationshipManagement = () => {
  useInjectReducer({
    key: 'companyRelationshipManagement',
    reducer,
  });
  useInjectSaga({
    key: 'companyRelationshipManagement',
    saga,
  });
};

export default useInjectCompanyRelationshipManagement;
