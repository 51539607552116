import { createFeatureAction } from '@utils/feature';
import featureName from '../../featureName';
import * as types from './types';
import { EditCompanySector } from './interfaces';

export const addSector = createFeatureAction<EditCompanySector>(
  featureName,
  types.ADD_COMPANY_SECTOR
);

export const addSectorSuccess = createFeatureAction<EditCompanySector>(
  featureName,
  types.ADD_COMPANY_SECTOR_SUCCESS
);

export const addSectorFail = createFeatureAction<string>(
  featureName,
  types.ADD_COMPANY_SECTOR_FAIL
);
