import React from 'react';
import { ColumnType } from 'antd/lib/table';
// constants
import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';
import globalConfig from '@optx/constants/config';
// models
import { CompanyInEquityTouch } from '@features/equity-touch-integration/models/CompanyInEquityTouch';
// components
import { TableCellDefault, TableCellDate } from '@optx/components/common/table/AntTable/cells';
import { AddedFromOPTX, TableCellCompanyName, TableCellCompanyURL } from './cells';

const isSmallScreen = window.screen.width <= 1920;

const columns: Array<ColumnType<CompanyInEquityTouch>> = [
  {
    dataIndex: 'url',
    render: (value: string | null, record: CompanyInEquityTouch) => (
      <TableCellCompanyURL value={value} record={record} />
    ),
    width: 40,
    className: 'align-center',
  },
  {
    dataIndex: 'name',
    title: 'Company Name',
    render: TableCellCompanyName,
    sorter: true,
    ellipsis: true,
    className: 'name',
  },
  {
    dataIndex: 'deal_team_lead',
    title: 'Company Owner',
    render: TableCellDefault,
    sorter: true,
    width: isSmallScreen ? 100 : 150,
    ellipsis: true,
    className: 'company_owner',
  },
  {
    dataIndex: 'date_added_et',
    title: 'Date Added to ET',
    render: (value: string, record: CompanyInEquityTouch, index: number) => (
      <TableCellDate
        value={value}
        format={globalConfig.grid_date.DATE_FORMAT}
        record={record}
        defaultValue={!value || value === DEFAULT_CELL_VALUE ? DEFAULT_CELL_VALUE : undefined}
      />
    ),
    sorter: true,
    width: isSmallScreen ? 120 : 150,
    ellipsis: true,
    className: 'date_added_et',
  },
  {
    dataIndex: 'stage',
    title: 'Stage',
    render: TableCellDefault,
    sorter: true,
    width: 80,
    ellipsis: true,
    className: 'stage',
  },
  {
    dataIndex: 'portfolio_date',
    title: 'Date Marked as Portco',
    render: (value: string | null, record: CompanyInEquityTouch, index: number) => (
      <TableCellDate
        value={value}
        format={globalConfig.grid_date.DATE_FORMAT}
        record={record}
        defaultValue={!value || value === DEFAULT_CELL_VALUE ? DEFAULT_CELL_VALUE : undefined}
      />
    ),
    sorter: true,
    width: isSmallScreen ? 120 : 150,
    ellipsis: true,
    className: 'portfolio_date',
  },
  {
    dataIndex: 'from_optx_to_et',
    title: 'Added From OPTX',
    render: AddedFromOPTX,
    sorter: true,
    width: isSmallScreen ? 100 : 120,
    ellipsis: true,
    className: 'from_optx_to_et',
  },
  {
    dataIndex: 'created_by',
    title: 'Created By',
    render: TableCellDefault,
    sorter: true,
    width: isSmallScreen ? 80 : 100,
    ellipsis: true,
    className: 'created_by',
  },
];

export default columns;
