import { Card } from 'antd';
import styled from 'styled-components';
import { CardProps } from 'antd/lib/card';
import ROUTES from '@optx/constants/routes';

const CardPannelWrapper = styled<
  React.FC<CardProps & { $location?: string; $topOffset?: string | number }>
>(Card)`
  overflow-y: scroll;
  max-height: ${props =>
    `calc(100vh - ${
      props.$location === ROUTES.myCompanies
        ? 203
        : props.$location?.includes('/all-reports')
        ? 297
        : props.$topOffset
    }px)`};
  position: sticky;
  top: ${props =>
    props.$location === ROUTES.myCompanies
      ? '203px'
      : props.$location?.includes('/all-reports')
      ? '297px'
      : `${props.$topOffset}px`};
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  @media only screen and (max-width: 1780px) {
    .profile-chart-card {
      .ant-card-head-wrapper {
        display: block;
      }

      .ant-card-extra {
        float: none;
        justify-content: flex-start;
        padding-bottom: 16px;
      }

      .ant-card-head-title {
        padding-bottom: 0;
      }
    }

    .ant-timeline.ant-timeline-label .timeline-item--touch-future .ant-timeline-item-label {
      padding: 0 23px 0 0;
    }
  }

  .funding-chart {
    .recharts-tooltip-wrapper {
      top: auto !important;
      bottom: 0;
    }
  }
`;

export const Styled = {
  CardPannelWrapper,
};
