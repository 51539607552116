import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// models
import { UserInformation } from '@optx/models/user';
import { UIState } from '../../interfaces';
// redux
import { actions as userInformationActions } from '@redux/user/information';
import { actions } from '../../actions';

const initialState: UIState = {
  defaultTableView: 'analyst',
};

const toggleDefaultTableViewReducer: CaseReducer<UIState, PayloadAction<'company' | 'analyst'>> = (
  draftState,
  action
) => {
  draftState.defaultTableView = action.payload;
};

// External reducers
const fetchUserInformationSuccessReducer: CaseReducer<UIState, PayloadAction<UserInformation>> = (
  draftState,
  action
) => {
  const defaultView = action.payload.settings.session_settings?.checklist_insights_view_default;

  if (defaultView) {
    draftState.defaultTableView = defaultView;
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.ui.toggleDefaultTableView, toggleDefaultTableViewReducer)
    // External reducer
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
);

export default reducer;
