import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Typography, Input, Form, Button, Tooltip } from 'antd';
import { useField } from 'formik';
import { isEqual } from 'lodash';
// models
import { BaseField } from '@optx/models/companyFields';
import { FieldUsedFor } from './interface';
// constants
import { numberBigRegExp, numberBigRegExpEndingDot } from '@constants/regex';
import { NUMBER_FORMAT } from '@optx/constants/format/number';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { checkSaveButton } from '@optx/utils/proprietaryInfo';
import { roundNumber, numberToMillions } from '@optx/utils/number';
import { handleDropdownKeyEvent } from '@optx/utils/handleDropdownKeyEvent';
// components
import EditPopover from '@optx/components/common/popover/EditPopover';

interface InputNumberFieldProps {
  field?: BaseField;
  value?: string | number;
  fieldName?: string;
  hasUnknown?: boolean | null;
  fieldType?: string;
  isChromePlugin?: boolean;
  onSave?: (fieldType?: string) => void;
  onCancel?: () => void;
  fieldUsedFor?: FieldUsedFor;
}

const InputNumberField: React.FC<InputNumberFieldProps> = ({
  field,
  fieldName,
  fieldType,
  value,
  isChromePlugin,
  hasUnknown = false,
  onSave,
  onCancel,
  fieldUsedFor,
}) => {
  const [selected, , setSelected] = useField<number | string | null>(field?.id || fieldType || '');
  const [amount, setAmount] = useState<string>('');
  const [isInitialAmount, setIsInitialAmount] = useState(true);
  const [closePopup, setClosePopup] = useState(false);

  const isFormattedToMillions = useMemo(() => {
    if (fieldType) {
      return fieldType === 'capital_raised' || fieldType === 'last_round_amount';
    }

    return field?.id === 'capital_raised' || field?.id === 'last_round_amount';
  }, [field?.id, fieldType]);

  const label = useMemo(() => {
    if (value === null || value === undefined || value === DEFAULT_EMPTY_VALUE) {
      return DEFAULT_EMPTY_VALUE;
    }

    if (fieldType === 'capital_raised' || fieldType === 'last_round_amount') {
      if (value === 0) {
        return DEFAULT_EMPTY_VALUE;
      }

      if (value === 'Unknown') {
        return 'Unknown';
      }

      if (hasUnknown) {
        // if there is (plus unknown) inside value check and get only number
        const [capitalRaised, plusUnknownText] = value.toString().split(' (');

        return `${roundNumber(capitalRaised, NUMBER_FORMAT).toUpperCase()} (${plusUnknownText}`;
      }

      return roundNumber(value, NUMBER_FORMAT).toUpperCase();
    }

    return value?.toString();
  }, [fieldType, hasUnknown, value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;

    if (
      numberBigRegExpEndingDot.test(inputValue) ||
      inputValue === '' ||
      inputValue === DEFAULT_EMPTY_VALUE
    ) {
      setAmount(inputValue);
    }
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setClosePopup(true);
  };

  const handleFormattedToMillions = useCallback(
    (value: number) => {
      const formattedValue = isFormattedToMillions ? value / 1000000 : value;

      return formattedValue.toString();
    },
    [isFormattedToMillions]
  );

  const handleVisibilityChange = (visibility: boolean) => {
    if (value && visibility) {
      if (typeof value === 'number') {
        setAmount(handleFormattedToMillions(value));
      } else {
        // if there is (plus unknown) inside value check and get only number
        const splitParanthesis = value.replace(/[^0-9]/g, '');

        if (hasUnknown) {
          setAmount(handleFormattedToMillions(parseInt(splitParanthesis)));
        } else if (value === DEFAULT_EMPTY_VALUE) {
          setAmount(DEFAULT_EMPTY_VALUE);
        } else {
          setAmount(
            value === 'Unknown' ? '0' : handleFormattedToMillions(parseInt(splitParanthesis))
          );
        }
      }
    }

    if (!value && visibility) {
      setAmount('');
    }
  };

  useEffect(() => {
    setIsInitialAmount(false);

    if (fieldUsedFor === 'editAllInfo') {
      setAmount(selected.value ? String(selected.value) : '');
    } else if (field?.value || field?.value === 0) {
      setAmount(handleFormattedToMillions(field.value));
    }

    if (value) {
      if (typeof value === 'number') {
        setAmount(handleFormattedToMillions(value));
      } else {
        // if there is (plus unknown) inside value check and get only number
        const splitParanthesis = value.replace(/[^0-9]/g, '');

        if (hasUnknown) {
          setAmount(handleFormattedToMillions(parseInt(splitParanthesis)));
        } else if (value === DEFAULT_EMPTY_VALUE) {
          setAmount(DEFAULT_EMPTY_VALUE);
        } else {
          setAmount(
            value === 'Unknown' ? '0' : handleFormattedToMillions(parseInt(splitParanthesis))
          );
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, value, fieldUsedFor, handleFormattedToMillions]);

  useEffect(() => {
    if (!isInitialAmount) {
      if (amount && numberBigRegExp.test(amount)) {
        if (!isEqual(parseFloat(amount), selected.value)) {
          setSelected.setValue(parseFloat(amount));
        }
      } else if (amount && numberBigRegExpEndingDot.test(amount)) {
        const amountRemovedDot = amount.slice(0, -1);
        setSelected.setValue(parseFloat(amountRemovedDot));
      } else if (amount === '' || amount === DEFAULT_EMPTY_VALUE) {
        if (selected.value !== null) {
          setSelected.setValue(null);
        }
      } else {
        setSelected.setValue(amount);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, isInitialAmount]);

  const handleSave = () => {
    onSave && onSave(fieldType);
    isFormattedToMillions && setSelected.setValue(numberToMillions(amount));
    setClosePopup(true);
  };

  if (field) {
    return (
      <Col span={8} className={field.id}>
        <Typography.Text>
          {field.label} {isFormattedToMillions ? '(in millions)' : ''}
        </Typography.Text>
        <Input
          value={amount ?? ''}
          onChange={handleChange}
          placeholder={field.placeholder}
          disabled={!field.editable}
        />
      </Col>
    );
  }

  const content = (
    <Form.Item style={{ height: '100px' }} name={fieldType ?? ''}>
      <div
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
          handleDropdownKeyEvent(event, setClosePopup, onSave, fieldType)
        }
        tabIndex={0}
      >
        <Typography.Text>Edit "{fieldName}" value</Typography.Text>
        <Input
          value={amount ?? ''}
          name={fieldType ?? ''}
          onChange={e => handleChange(e)}
          placeholder={isFormattedToMillions ? 'in millions of dollars' : 'value'}
        />
        <div className="profile-information__popover-buttons">
          <Button className="profile-information__cancel" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="profile-information__save"
            type="primary"
            disabled={checkSaveButton(selected.value, value)}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </div>
    </Form.Item>
  );

  return (
    <EditPopover
      setClosePopup={setClosePopup}
      closePopup={closePopup}
      onVisibilityUpdate={handleVisibilityChange}
      content={content}
      placement={isChromePlugin ? 'topRight' : 'top'}
      customOverlayStyle={{ maxWidth: '300px' }}
    >
      <Tooltip title={label}>{label}</Tooltip>
    </EditPopover>
  );
};

export default React.memo(InputNumberField);
