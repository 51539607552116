import { EquityTouchState } from './interfaces';

export const shouldFetch = (state: EquityTouchState) => !state.fetchedAt;
export const loading = (state: EquityTouchState) => state.loading;
export const formData = (state: EquityTouchState) => state.formData;
export const companies = (state: EquityTouchState) => state.companiesById;
export const allIds = (state: EquityTouchState) => state.allCompanyIds;
export const companyData = (state: EquityTouchState) => state.companyData;
export const bulkValues = (state: EquityTouchState) => state.bulkValues;
export const getQueue = (state: EquityTouchState) => state.queue;

export const companyEqtData = (companyId: number) => (state: EquityTouchState) =>
  state.companiesById[companyId];

export const getResults = (state: EquityTouchState) => state.results;
export const isInProgress = (state: EquityTouchState) => state.inProgress;
export const isCompleted = (state: EquityTouchState) => state.completed;
export const getPercentageCompleted = (state: EquityTouchState) => state.percentageCompleted;
export const getSelectedCompaniesCount = (state: EquityTouchState) => state.selectedCompaniesCount;
export const cancel = (state: EquityTouchState) => state.cancel;
export const getTemporaryData = (state: EquityTouchState) => state.temporaryData;
export const getListId = (state: EquityTouchState) => state.listId;
export const getListType = (state: EquityTouchState) => state.listType;
export const getCompanyTemporaryData = (companyId: number) => (state: EquityTouchState) =>
  (state.temporaryData?.step2 as any)?.[companyId] || {};
