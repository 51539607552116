import { createAction } from '@reduxjs/toolkit';
import { AddCompanyReviewPayload, AddCompanyReviewSuccess } from './interfaces';
import {
  ADD_COMPANY_REVIEW,
  ADD_COMPANY_REVIEW_SUCCESS,
  ADD_COMPANY_REVIEW_FAIL,
} from './actionsTypes';

export const addCompanyReview = createAction<AddCompanyReviewPayload>(ADD_COMPANY_REVIEW);

export const addCompanyReviewSuccess = createAction<AddCompanyReviewSuccess>(
  ADD_COMPANY_REVIEW_SUCCESS
);

export const addCompanyReviewFail = createAction<string>(ADD_COMPANY_REVIEW_FAIL);
