import React from 'react';
import { Button, message } from 'antd';
import { CopyTwoTone } from '@ant-design/icons';
import { copyToClipboard } from '../../utils/helpers';

interface CopyIconProps {
  text: string;
}

export const CopyIcon: React.FC<CopyIconProps> = ({ text }) => {
  const onClick = () => {
    copyToClipboard(text);
    message.success('Copied!');
  };

  return (
    <Button type="link" onClick={onClick} className="socialmedia">
      <CopyTwoTone style={{ fontSize: 24, color: '#1890ff' }} />
    </Button>
  );
};

export default CopyIcon;
