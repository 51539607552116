import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, select } from 'redux-saga/effects';
// mdoels
import { RationaleSoftwareResponse } from '@optx/models/score';
import { APIBoolean } from '@optx/models/api/generic';
// services
import { UserService } from '@services/api';
import NotificationService from '@services/NotificationService';
import { getErrorMessage } from '@utils/api/errors';
import { AxiosResponse } from 'axios';
// redux
import { actions as customGlobalLoaderActions } from '@features/custom-global-loader';
import {
  actions as auditActions,
  selectors as auditSelectors,
} from '@features/company/audit-trail/state';
import { UpdateFieldsPayload } from '@optx/features/company/edit-fields/state/interfaces';
import * as actions from './actions';
import { CompanyActivePayload } from './interfaces';

export function* addInBusinessEvaluationSaga(action: PayloadAction<CompanyActivePayload>) {
  yield put(customGlobalLoaderActions.toggle({ loading: true, customText: '' }));
  const { companyId, active, isFromHistory } = action.payload;

  const customPayload: UpdateFieldsPayload = {
    companyId,
    value: active,
  };

  try {
    const res: AxiosResponse<RationaleSoftwareResponse> = yield call(
      UserService.updateActive,
      customPayload
    );

    if (res.data) {
      yield put(actions.addCompanyActiveSuccess({ active, companyId }));

      if (isFromHistory) {
        const { pageSize } = yield select(auditSelectors.getPagination);
        const field: string = yield select(auditSelectors.getSearchQuery);
        const optx: APIBoolean = yield select(auditSelectors.getOPTX);
        const internal: APIBoolean = yield select(auditSelectors.getInternal);
        const startDate: string = yield select(auditSelectors.getStartDate);
        const endDate: string = yield select(auditSelectors.getEndDate);

        yield put(
          auditActions.fetchChangedCompanyFields({
            companyId,
            pagination: {
              pageNumber: 1,
              pageSize,
            },
            field,
            internal,
            optx,
            startDate,
            endDate,
          })
        );
      }

      NotificationService.success('Company active state updated successfully!');
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Failed to update company active state!');
    yield put(actions.addCompanyActiveFail(errorMessage));
    NotificationService.error(errorMessage);
  } finally {
    yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
  }
}

export default function* inBusinessEvaluationSaga() {
  yield takeLatest(actions.addCompanyActive, addInBusinessEvaluationSaga);
}
