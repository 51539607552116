import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Button, Input, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
// models
import { FieldPropertyInfoType } from '@optx/models/Financial';
// constants
import { ONLY_DIGITS_ERROR_MESSAGE, message } from '@optx/constants/table/cells';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { NUMBER_FORMAT } from '@optx/constants/format/number';
// utils
import { validateOnlyNumbers } from '@optx/utils/validation';
import { parseNumberFromAbbreviation, roundNumber } from '@optx/utils/number';
import { roundingPresentationValues } from '@optx/utils/dealPresentation';
import { checkSaveButton } from '@optx/utils/proprietaryInfo';
// hooks
import { useHistoryTab } from '@optx/common/hooks/history';
// redux
import { actions } from '@redux/company/opportunity-presentation';
// components
import EditPopover from '@optx/components/common/popover/EditPopover';

interface EditFieldNumberProps {
  companyId: number;
  fieldKey: string;
  fieldPropietaryInfoType: FieldPropertyInfoType;
  initialValue: number | null;
  isFromAuditField?: boolean;
}

const EditFieldNumber: React.FC<EditFieldNumberProps> = ({
  companyId,
  fieldKey,
  initialValue,
  fieldPropietaryInfoType,
  isFromAuditField,
}) => {
  const title = `Edit "${
    fieldKey === 'Asking Amount' ? 'Equity Check (ask amount)' : fieldKey
  }" value`;
  const formatedValue = initialValue ? initialValue.toString() : initialValue;
  const dispatch = useDispatch();
  const { updateHistoryFields } = useHistoryTab();

  const [fieldValue, setFieldValue] = useState<string | null>(initialValue?.toString() ?? null);
  const inputValue = useRef<string | null>(initialValue?.toString() ?? null);
  const [closePopup, setClosePopup] = useState<boolean>(false);

  const selectedValue = fieldValue ? parseFloat(fieldValue) : 0;

  useEffect(() => {
    setFieldValue(initialValue?.toString() ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    inputValue.current = value;
    setFieldValue(value);
  };

  const handleCancel = () => {
    resetValue();
    setClosePopup(true);
  };

  const resetValue = () => {
    setFieldValue(initialValue?.toString() ?? null);
    inputValue.current = initialValue?.toString() ?? null;
  };

  const handleSubmit = () => {
    let value;

    if (!validateOnlyNumbers(fieldValue as string)) {
      return message().error(ONLY_DIGITS_ERROR_MESSAGE);
    }

    if (fieldValue === null || fieldValue === '' || fieldValue === DEFAULT_EMPTY_VALUE) {
      value = null;
      setFieldValue(null);
    } else {
      value = roundingPresentationValues(parseFloat(fieldValue!.toString()) * 1000000);
    }

    const key = fieldKey?.replaceAll(' ', '_').toLowerCase();
    const payload = {
      company_id: companyId,
      [fieldPropietaryInfoType]: {
        [key as string]: value ? parseNumberFromAbbreviation(value.toString()) : null,
      },
    };
    dispatch(actions.updateOpportunityPresentation(payload));

    updateHistoryFields({
      afterValue: value ? parseNumberFromAbbreviation(value.toString()) : null,
      beforeValue: parseNumberFromAbbreviation(((initialValue as number) * 1000000).toString()),
      fieldChanged: fieldKey,
    });

    setClosePopup(true);
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const content = (
    <div style={{ width: '300px' }} onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
      <Input
        value={['0', 0, null].includes(fieldValue) ? undefined : (fieldValue?.toString() as string)}
        onChange={handleChangeInput}
        placeholder={fieldKey === 'Asking Amount' ? 'in millions of dollars' : title}
        style={{ width: '100%' }}
      />
      <div className="profile-information__popover-buttons">
        <Button className="profile-information__cancel" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className="profile-information__save"
          type="primary"
          onClick={handleSubmit}
          disabled={checkSaveButton(selectedValue, initialValue)}
        >
          Save
        </Button>
      </div>
    </div>
  );

  if (isFromAuditField) {
    return (
      <EditPopover
        content={content}
        title={title}
        closePopup={closePopup}
        onVisibilityChange={resetValue}
        setClosePopup={setClosePopup}
        hidePenIcon
        destroyOnHide
      >
        Edit Field
      </EditPopover>
    );
  }

  return (
    <EditPopover
      content={content}
      title={title}
      closePopup={closePopup}
      onVisibilityChange={resetValue}
      setClosePopup={setClosePopup}
      destroyOnHide
      hidePenIcon={false}
    >
      <Tooltip title={initialValue}>
        <span>
          {formatedValue !== null || Number(formatedValue) !== 0
            ? roundNumber(
                parseFloat(((formatedValue as number) * 1000000).toString()),
                NUMBER_FORMAT
              )
            : 0}
        </span>
      </Tooltip>
    </EditPopover>
  );
};

export default EditFieldNumber;
