import React, { Component } from 'react';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  /**
   * fallback UI.
   */
  placeholder: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, info: any) {
    // eslint-disable-next-line no-console
    console.error(error);
    // Display fallback UI
    this.setState({ hasError: true });
  }

  render() {
    const { placeholder, children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return placeholder; // render custom fallback UI
    }

    return children;
  }
}

export default ErrorBoundary;
