import React from 'react';
import { Menu } from 'antd';
// models
import { SearchViewType } from '@models/user';
// components
import { ResetColumnWidthsMenu } from '@features/columns/update-columns/components';
import DisplayColumnToggle from '@components/pages/Home/SearchGridControls/GridDropdown/GridMenu/DisplayColumnToggle';
import { ClearSortingMenuItem } from './ClearSortingMenuItem';
import ToggleSortType from '../../ToggleSortType';

interface CompaniesOutReachMenuProps {
  viewType: SearchViewType;
}

const CompaniesOutReachMenu: React.FC<CompaniesOutReachMenuProps> = ({ viewType }) => {
  return (
    <Menu>
      <DisplayColumnToggle key={1} />
      <ResetColumnWidthsMenu key={2} />
      <ClearSortingMenuItem key={3} />
      {viewType === 'table' && <ToggleSortType key={4} />}
    </Menu>
  );
};

export default CompaniesOutReachMenu;
