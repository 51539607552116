import React from 'react';
import { components, IndicatorProps } from 'react-select';
import { DownOutlined } from '@ant-design/icons';

/**
 * customDropdownIndicator - style the arrow of the dropdown.
 */
const DropdownIndicator: React.FC<IndicatorProps<any>> = props => (
  <components.DropdownIndicator {...props}>
    <DownOutlined />
  </components.DropdownIndicator>
);

export default DropdownIndicator;
