import { createFeatureActionType } from '@optx/utils/feature';
import { featureName } from '../../featureName';

export const SORT = createFeatureActionType(featureName, 'SORT');

export const CHANGE_SORT = createFeatureActionType(featureName, 'CHANGE_SORT');
export const CHANGE_SORT_SUCCESS = createFeatureActionType(featureName, 'CHANGE_SORT_SUCCESS');

export const MULTI_SORT = createFeatureActionType(featureName, 'MULTI_SORT');
export const RESET_MULTI_SORT = createFeatureActionType(featureName, 'RESET_MULTI_SORT');
