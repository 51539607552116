import React from 'react';
import { useSelector } from 'react-redux';
// constants
import { NUMBER_FORMAT } from '@optx/constants/format/number';
// utils
import { roundNumber } from '@optx/utils/number';
// redux
import { selectors } from '@features/pipeline-report/state';
// components
import Cards from '@optx/components/common/cards';
import { PieChartWithHover } from '@optx/features/charts/';

const COLOR = [
  '#b659bf',
  '#7958ca',
  '#2552ac',
  '#337bd3',
  '#2868cd',
  '#2c7fdb',
  '#2f96dd',
  '#31abdd',
  '#35d3d6',
  '#37e7d0',
  '#38f9c4',
];

const FundAssociationChart = () => {
  const totalFund = useSelector(selectors.totalFund);
  const data = useSelector(selectors.fundAssociation);

  const title = 'Fund Association';

  return (
    <Cards $transparent className="fund-association-chart-wrapper">
      <PieChartWithHover
        data={data.map((item, index) => ({ ...item, fill: COLOR[index] }))}
        dataKey="percentage"
        title={title}
        total={roundNumber(totalFund, `$${NUMBER_FORMAT}`)}
      />
    </Cards>
  );
};

export default FundAssociationChart;
