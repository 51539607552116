import React from 'react';
import styled from 'styled-components';
import { Tooltip, Typography } from 'antd';
import { DEFAULT_CELL_VALUE } from '../../../../../constants/table/cells';

const StyledDefaultCell = styled(Typography.Text)`
  max-width: 100%;
  /* Gray / gray-8 */
  color: #595959;
  margin-bottom: 0 !important;
`;

interface TableCellDefaultProps {
  value: any;
}

export const TableCellDefault: React.FC<TableCellDefaultProps> = ({ value, ...restProps }) => {
  return (
    <StyledDefaultCell ellipsis {...restProps}>
      {value ? <Tooltip title={value}>{value}</Tooltip> : DEFAULT_CELL_VALUE}
    </StyledDefaultCell>
  );
};

/**
 *
 * @param value Default cell render for table column.
 */
const TableCellDefaultRender = (value: any) => {
  return <TableCellDefault value={value} />;
};

export default TableCellDefaultRender;
