import React from 'react';
import { Tooltip } from 'antd';

interface TooltipTextProps {
  text: string;
}

export const TooltipText: React.FC<TooltipTextProps> = ({ text }) => (
  <Tooltip title={text} className="text-truncate">
    <p style={{ margin: 0 }}>{text}</p>
  </Tooltip>
);

export default TooltipText;
