import React, { useState } from 'react';
import { Typography, Row } from 'antd';
import { OptionsType, InputActionMeta } from 'react-select';
// models
import { SelectOption } from '@optx/models/Option';
import { SuccessErrorCallback } from '@optx/models/callback';
// hooks
import useAsyncSearch from '@optx/common/hooks/select/useAsyncSearch';
// components
import { AsyncMultiSelect } from '@optx/shared/view/molecules/Select';

interface MultiSelectAsyncCreatableFieldProps {
  selectedOptions: SelectOption[];
  endpoint: string;
  label: string;
  changeHandler: (value: SelectOption[]) => void;
}

const MultiSelectAsyncCreatableField: React.FC<MultiSelectAsyncCreatableFieldProps> = ({
  selectedOptions,
  endpoint,
  label,
  changeHandler,
}) => {
  const { loadOptions } = useAsyncSearch({ endpoint: endpoint });
  const [fetchedOptions, setFetchedOptions] = useState<OptionsType<SelectOption>>([]);
  const [inputValue, setInputValue] = useState<string>('');

  const getLoadOptionsDelay = (query: string, callback: SuccessErrorCallback) => {
    if (query.length > 2) {
      loadOptions(query, options => {
        const callbackResult = callback(options);

        // set current filter to a cached variable.
        setFetchedOptions(options);

        return callbackResult;
      });
    }
  };

  return (
    <>
      <Row className="form-item-wrapper" gutter={[0, 0]}>
        <Typography.Text>{label}</Typography.Text>
      </Row>
      <Row>
        <div
          role="presentation"
          className="touch--multiselect-async-wrapper multiselect-async-creatable-wrapper"
        >
          <AsyncMultiSelect
            className="multiselect-async-creatable"
            onChange={(options, action) => {
              changeHandler(
                (options as SelectOption[]).map(option => ({
                  value: option.value,
                  label: option.value,
                }))
              );
            }}
            loadOptions={getLoadOptionsDelay}
            defaultOptions={fetchedOptions}
            defaultValue={selectedOptions}
            loadingMessage={({ inputValue }) =>
              inputValue.length > 2 ? 'Loading...' : 'Begin typing'
            }
            closeMenuOnSelect={false}
            onSelectResetsInput={false}
            blurInputOnSelect={false}
            controlShouldRenderValue
            maxLength={500}
            closeOnSelect
            isCreatable
            isValidNewOption={(
              inputValue: string,
              value: SelectOption[],
              options: SelectOption[]
            ) => {
              const itExists = options.some(option => inputValue.trim() === option.value.trim());

              return inputValue.trim() && !itExists;
            }}
            inputValue={inputValue}
            onInputChange={(newValue: string, { action }: InputActionMeta) => {
              if (action === 'input-change') {
                setInputValue(newValue);
              } else if (action === 'menu-close') {
                setInputValue('');
              }
            }}
          />
        </div>
      </Row>
    </>
  );
};

export default React.memo(MultiSelectAsyncCreatableField);
