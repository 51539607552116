import { utils as requestUtils } from '@features/request';
import { featureName } from '../featureName';

export const fetchChangedCompanyFields = requestUtils.createActionTypes(
  featureName,
  'FETCH_CHANGED_COMPANY_FIELDS'
);

export const editCompanyFields = 'audit-trial/EDIT_COMPANY_FIELDS';

export const clearCompanyFields = 'audit-trial/CLEAR_COMPANY_FIELDS';
