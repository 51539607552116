import React from 'react';
import { Dropdown, Button, Menu, Tooltip } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import ExportCSVButton from './ExportCSVButton';
import ToggleSortType from './ToggleSortType';

interface ContactsSearchDropdownProps {
  isFullScreen?: boolean;
}

const ContactsSearchDropdown: React.FC<ContactsSearchDropdownProps> = ({
  children,
  isFullScreen,
}) => {
  const [visible, setVisible] = React.useState(false);

  function handleVisibleChange() {
    setVisible(!visible);
  }

  const menu = (
    <Menu style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <ExportCSVButton handleVisibleChange={handleVisibleChange} />
      {isFullScreen && <ToggleSortType isFullScreen={isFullScreen} />}
    </Menu>
  );

  return (
    <Tooltip title={isFullScreen ? 'Export/MultiSort' : 'Export'}>
      <Dropdown
        visible={visible}
        onVisibleChange={handleVisibleChange}
        overlay={menu}
        trigger={['click']}
        placement="bottomRight"
      >
        <Button type="text" icon={<EllipsisOutlined style={{ fontSize: 28 }} />} />
      </Dropdown>
    </Tooltip>
  );
};

export default ContactsSearchDropdown;
