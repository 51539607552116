import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { SuccessErrorCallback } from '@optx/models/callback';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { formattingSquareBrackets } from '@optx/utils/utils';
import {
  searchAsyncMultiSelect,
  fetchAsyncMultiSelectSuccess,
  fetchAsyncMultiSelectFail,
} from './actions';
import { AsyncMultiSelect } from '../../services/api';
import NotificationService from '../../services/NotificationService';
import { AsyncMultiSelectPayload } from './interfaces';

export function* fetchAsyncMultiSelectSaga(
  action: PayloadAction<AsyncMultiSelectPayload, any, SuccessErrorCallback>
) {
  const { payload, meta: callback } = action;

  try {
    const res: AxiosResponse<Array<any>> = yield call(AsyncMultiSelect.getOptions, payload);

    if (res.data) {
      const modifiedData = res.data.map(item => ({
        value: item.value,
        label: item.location_info
          ? `${item.name} ${formattingSquareBrackets(item.location_info as string)}`
          : item.name || item.label,
        id: item.company_id ?? item.id,
        isDefault: item.is_default,
      }));
      // for some reason API is sending on reponse a tag with a value of a unicode U+00ad
      // and this appear invisible on front-end
      const modifiedDataWithOutSpecialUnicodeAndBlank = modifiedData.filter(
        item => item.label !== DEFAULT_EMPTY_VALUE && item.label !== ''
      );

      yield put(fetchAsyncMultiSelectSuccess(modifiedDataWithOutSpecialUnicodeAndBlank));

      callback && callback(modifiedDataWithOutSpecialUnicodeAndBlank);
    } else {
      const errorMessage = 'Failed to fetch Async MultiSelect!';
      yield put(fetchAsyncMultiSelectFail(errorMessage));
      callback && callback(null, errorMessage);
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = 'Failed to fetch Async MultiSelect!';
    yield put(fetchAsyncMultiSelectFail(errorMessage));
    callback && callback(null, errorMessage);
    NotificationService.error(errorMessage);
  }
}

export default function* AsyncMultiSelectSaga() {
  yield takeLatest(searchAsyncMultiSelect.type, fetchAsyncMultiSelectSaga);
}
