import React, { useCallback, useEffect, useMemo } from 'react';
import { Dictionary, forEach } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, Button } from 'antd';
// redux
import { selectors, actions } from '@optx/features/pipeline-report/state';
import { selectors as favoriteListsSelectors } from '@redux/favorite-lists/lists';
//hooks
import useIsMount from '@optx/common/hooks/useIsMount';
// components
import { DropdownSaveSearch as Dropdown } from '@components/common/dropdowns';
// constants
import { COMPANY_CSV_SIZE_LIMIT } from '@constants/limit';
// validation
import { saveListSchema } from '@optx/common/validation/save-search';

const label = 'Save Search as List';

interface SaveSearchAsListProps {
  onChangeVisible?: () => void;
}

const SaveSearchAsList: React.FC<SaveSearchAsListProps> = ({ onChangeVisible }) => {
  const dispatch = useDispatch();
  const isMount = useIsMount();
  const companyCount = useSelector(selectors.pipelineReportCompanies).length;
  const saveSearchLoading = useSelector(selectors.saveSearchLoading);

  const savedLists = useSelector(favoriteListsSelectors.getLists);

  const savedListTitles = useMemo(() => {
    const titleDictionary: Dictionary<boolean> = {
      Favorites: true,
    };
    forEach(savedLists, (list, key) => {
      titleDictionary[list.title] = true;
    });

    return titleDictionary;
  }, [savedLists]);

  const validationSchema = useMemo(() => saveListSchema(savedListTitles), [savedListTitles]);

  const saveSearchAsList = useCallback(
    (title: string) => dispatch(actions.saveSearchAsList({ title })),
    [dispatch]
  );

  useEffect(() => {
    if (isMount) return;

    if (!saveSearchLoading) {
      onChangeVisible && onChangeVisible();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveSearchLoading]);

  const disabled = !companyCount || companyCount > COMPANY_CSV_SIZE_LIMIT;

  return (
    <Dropdown
      onSave={saveSearchAsList}
      validationSchema={validationSchema}
      label={label}
      disabled={disabled}
      Toggle={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Tooltip title="Up to 100K rows" placement="left">
          <Button
            type="text"
            style={{ padding: '5px 12px' }}
            loading={saveSearchLoading}
            disabled={disabled}
          >
            {label}
          </Button>
        </Tooltip>
      }
    />
  );
};

export default SaveSearchAsList;
