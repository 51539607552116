import React from 'react';
import { Tooltip } from 'antd';
import styled from 'styled-components';
// models
import Company from '@optx/models/Company';
import { Cell } from '@optx/models/table/Cell';
// constants
import { DEFAULT_CELL_VALUE } from '../../../../../constants/table/cells';
import { cellIsEqual } from '../../AntTable/cells/memoize';

const TableCellInner = styled.div`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TableCellDescription: React.FC<Cell<Company>> = ({ value, record }) => {
  return (
    <>
      {value ? (
        <Tooltip title={value}>
          <TableCellInner>{value}</TableCellInner>
        </Tooltip>
      ) : (
        <>{DEFAULT_CELL_VALUE}</>
      )}
    </>
  );
};

export default React.memo(TableCellDescription, cellIsEqual);
