import { useInjectSaga, useInjectReducer } from 'redux-injectors';
import { reducer, saga } from '../../../redux/company/equity-touch';

const useInjectEquityTouch = () => {
  useInjectReducer({
    key: 'companyEquityTouch',
    reducer,
  });
  useInjectSaga({ key: 'equityTouch', saga });
};

export default useInjectEquityTouch;
