/**
 * will communicate with the extension on UI. Extension catches the message on its side.
 * @param {string} type type of post message to iframe
 * @param {any} message can be everything it will pass to the extension
 */
export const iframePostMessage = (type: string, message: any) =>
  window.parent.postMessage(
    {
      type,
      message,
    },
    '*'
  );
