import React from 'react';

// models
import { SourcingOutreachCardKey } from '@optx/features/sourcing-outreach-summary/models/SourcingOutreach';
// utils
import { formatPercentDefault } from '@utils/format';
// components
import { TrendingValue } from '@optx/shared/view/molecules/trending';
import Styles from './CardTitle.styles';

const formatTrending = (value: string | number | null | undefined) =>
  formatPercentDefault(value, true);

interface CardTitleProps {
  className?: string;
  keyName: string;
  title: string;
  value: number | null;
  trending: number | null;
  additionalValue?: number | null;
  additionalTrending?: number | null;
  handleCardClick?: (cardKey: SourcingOutreachCardKey, value: number) => void | undefined;
}

const CardTitle: React.FC<CardTitleProps> = ({
  className,
  keyName,
  title,
  value,
  trending,
  additionalValue,
  additionalTrending,
  handleCardClick,
}) => {
  const isAdditionalData =
    typeof additionalValue !== 'undefined' && typeof additionalTrending !== 'undefined';

  return (
    <Styles.Wrapper className={className}>
      <Styles.Title level={5}>{title}</Styles.Title>
      {!isAdditionalData && (
        <div>
          <Styles.TitleValue>{value}</Styles.TitleValue>
          <TrendingValue value={trending} format={formatTrending} className="trending-percentage" />
          {keyName === 'qualified_opportunities_platform' && (
            <div className="additional-title">Platforms</div>
          )}
        </div>
      )}
      {isAdditionalData && typeof handleCardClick !== 'undefined' && (
        <div className="qualified_opportunities_ops">
          <div
            onClick={
              value ? () => handleCardClick('qualified_opportunities_platform', value) : undefined
            }
            role="presentation"
            className="qualified_opportunities_platform"
          >
            <Styles.TitleValue>{value}</Styles.TitleValue>
            <TrendingValue
              value={trending}
              format={formatTrending}
              className="trending-percentage"
            />
            <div className="additional-title">Platforms</div>
          </div>
          <div
            onClick={
              additionalValue
                ? () => handleCardClick('qualified_opportunities_addon', additionalValue)
                : undefined
            }
            role="presentation"
            className="qualified_opportunities_addon"
          >
            <Styles.TitleValue>{additionalValue}</Styles.TitleValue>
            <TrendingValue
              value={additionalTrending as number | null}
              format={formatTrending}
              className="trending-percentage"
            />
            <div className="additional-title">Add-ons</div>
          </div>
        </div>
      )}
    </Styles.Wrapper>
  );
};

export default CardTitle;
