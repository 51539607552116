import { call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import queryString from 'query-string';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
// models
import Company from '@optx/models/Company';
import { SortByRule, SortRule } from '@optx/models/table/sorting';
import { PageInfo } from '@optx/models/table/Pagination';
import { FilterTag } from '@optx/models/tags';
import { ApplyFiltersPayload } from '../interfaces';
import { PreselectedFilter } from '@optx/models/filters';
import { AnalystResponse } from '@optx/features/checklist-research-insights/models/analyst';
// services
import { AnalystService } from '@services/api';
import NotificationService from '@services/NotificationService';
// constants
import { DEFAULT_SORT_ANALYST, DEFAULT_SORT_COMPANIES } from '../../constants/table';
// utils
import { getErrorMessage } from '@utils/api/errors';
import mapFiltersToURLParams from '@optx/utils/filters/mapFiltersToURLParams';
import { mapSortQuery } from '@optx/utils/search';
// redux
import {
  selectors as paginationSelectors,
  actions as paginationActions,
} from '@features/grid/pagination';
import { actions as searchKeyActions } from '@features/grid/searchkey';
import { actions } from '../actions';
import { selectors } from '../selectors';
import { actions as searchCountActions } from '@features/grid/search-count';
import {
  actions as userInformationActions,
  selectors as userSelectors,
} from '@redux/user/information';
import { CardsState } from '../interfaces';

export function* fetchAnalystSaga() {
  const { normalized, filter } = yield select(selectors.filters.getFilters);
  const normalizedFilter = {
    ...filter,
    ...(filter.user_id && {
      user_id: filter.user_id.length === 0 ? [''] : filter.user_id,
    }),
  };

  const sortBy: SortByRule<any>[] = yield select(selectors.sort.sortBy);
  const query: string = yield select(selectors.filters.searchKey);
  const pagination: PageInfo = yield select(
    paginationSelectors.getPagination('checklistResearchInsights')
  );

  const convertSortBy = mapSortQuery(sortBy);
  const queryDataForRequest = {
    ...convertSortBy,
    ...mapFiltersToURLParams(normalized, normalizedFilter, undefined, {
      dateRangeFormatUpperLower: true,
    }),
    rationale_report_query: query || '',
    page: pagination.pageNumber,
    per_page: pagination.pageSize,
  };

  if ('query' in queryDataForRequest) {
    delete queryDataForRequest.query;
  }

  const payloadForRequest = queryString.stringify(queryDataForRequest, {
    arrayFormat: 'comma',
  });

  const queryDataForSettings = {
    ...convertSortBy,
    ...mapFiltersToURLParams(normalized, normalizedFilter),
    rationale_report_query: query || '',
    page: pagination.pageNumber,
    per_page: pagination.pageSize,
  };

  if ('query' in queryDataForSettings) {
    delete queryDataForSettings.query;
  }

  const payloadForSettings = queryString.stringify(queryDataForSettings, {
    arrayFormat: 'comma',
  });

  try {
    const res: AxiosResponse<AnalystResponse> = yield call(
      AnalystService.getAnalystChecklistInsight,
      payloadForRequest
    );

    if (res.data) {
      yield put(actions.search.fetchAnalystSuccess(res.data));

      yield put(
        userInformationActions.updateUserSettings({
          checklist_insights_companies_filters: payloadForSettings,
        })
      );
    } else {
      const errorMessage = 'Failed to fetch Checklist Research Insights!';
      yield put(actions.search.fetchAnalystFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to fetch Checklist Research Insights!');
    yield put(actions.search.fetchAnalystFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* fetchCompanySaga() {
  const { normalized, filter } = yield select(selectors.filters.getFilters);
  const normalizedFilter = {
    ...filter,
    ...(filter.user_id && {
      user_id: filter.user_id.length === 0 ? [''] : filter.user_id,
    }),
  };
  const sortBy: SortByRule<any>[] = yield select(selectors.sort.sortBy);
  const pagination: PageInfo = yield select(
    paginationSelectors.getPagination('checklistResearchInsights')
  );
  const query: string = yield select(selectors.filters.searchKey);

  const convertSortBy = mapSortQuery(sortBy);
  const queryDataForRequest = {
    ...convertSortBy,
    ...mapFiltersToURLParams(normalized, normalizedFilter, undefined, {
      dateRangeFormatUpperLower: true,
    }),
    query: query || '',
    page: pagination.pageNumber,
    per_page: pagination.pageSize,
  };

  const payloadForRequest = queryString.stringify(queryDataForRequest, {
    arrayFormat: 'comma',
  });

  const queryDataForSettings = {
    ...convertSortBy,
    ...mapFiltersToURLParams(normalized, normalizedFilter),
    query: query || '',
    page: pagination.pageNumber,
    per_page: pagination.pageSize,
  };

  const payloadForSettings = queryString.stringify(queryDataForSettings, {
    arrayFormat: 'comma',
  });

  const queryDataSearchCount = {
    ...convertSortBy,
    ...mapFiltersToURLParams(normalized, filter, undefined, {
      dateRangeFormatUpperLower: true,
    }),
    query: query || '',
  };
  const payloadSearchCount = queryString.stringify(queryDataSearchCount, {
    arrayFormat: 'comma',
  });

  try {
    const res: AxiosResponse<Company[]> = yield call(
      AnalystService.getCompanyChecklistInsight,
      payloadForRequest
    );

    if (res.data) {
      yield put(
        searchCountActions.searchCount({
          data: payloadSearchCount,
          gridKey: 'checklistResearchInsights',
        })
      );

      yield put(actions.search.fetchCompanySuccess(res.data));

      yield put(
        userInformationActions.updateUserSettings({
          checklist_insights_companies_filters: payloadForSettings,
        })
      );
    } else {
      const errorMessage = 'Failed to fetch Checklist Research Insights!';
      yield put(actions.search.fetchAnalystFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to fetch Checklist Research Insights!');
    yield put(actions.search.fetchCompanyFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

function* fetchCardSaga() {
  const { normalized, filter } = yield select(selectors.filters.getFilters);
  const normalizedFilter = {
    ...filter,
    ...(filter.user_id && {
      user_id: filter.user_id.length === 0 ? [''] : filter.user_id,
    }),
  };
  const query: string = yield select(selectors.filters.searchKey);
  const defaultTableView: 'company' | 'analyst' = yield select(selectors.ui.defaultTableView);
  const defaultOptxScore: string = yield select(userSelectors.getDefaultScore);

  const queryData = {
    ...mapFiltersToURLParams(normalized, normalizedFilter, undefined, {
      dateRangeFormatUpperLower: true,
    }),
    ...(defaultTableView === 'company'
      ? { query: query || '' }
      : { rationale_report_query: query || '' }),
  };
  const payload = queryString.stringify(queryData, {
    arrayFormat: 'comma',
  });

  try {
    const res: AxiosResponse<
      Omit<CardsState, 'psgFit'> & { psg_fit: Record<string, string | null> }
    > = yield call(AnalystService.getCardInfoChecklistInsight, payload);

    if (defaultOptxScore === 'il') {
      res.data.score = res.data.il_optx_score;
    } else {
      res.data.score = res.data.optx_score;
    }

    delete res.data.optx_score;
    delete res.data.il_optx_score;

    yield put(actions.card.fetchCardSuccess(res.data));
  } catch (error: any) {
    const errorMessage = getErrorMessage(
      error,
      'Failed to fetch Checklist Research Insights Card!'
    );
    NotificationService.error(errorMessage);
  }
}

function* fetchChecklistResearchDataSaga(
  action: PayloadAction<
    | undefined
    | FilterTag
    | Partial<ApplyFiltersPayload>
    | { key: string; value: PreselectedFilter }
    | Array<SortByRule<any>>
  >
) {
  const defaultTableView: 'company' | 'analyst' = yield select(selectors.ui.defaultTableView);

  if (!action.payload) {
    yield put(searchKeyActions.setSearchKey({ data: '', gridKey: 'checklistResearchInsights' }));
  }

  if (action) {
    const tag = action.payload as FilterTag;
    const payload = action.payload as Partial<ApplyFiltersPayload>;

    if (tag && tag.filter === 'keyword') {
      yield put(searchKeyActions.setSearchKey({ data: '', gridKey: 'checklistResearchInsights' }));
    }

    if (payload && payload.searchKey) {
      yield put(
        searchKeyActions.setSearchKey({
          data: payload.searchKey,
          gridKey: 'checklistResearchInsights',
        })
      );
    }
  }

  if (defaultTableView === 'analyst') {
    yield put(actions.search.fetchAnalyst());
  } else {
    yield put(actions.search.fetchCompany());
  }

  yield put(actions.card.fetchCard());
}

function* toggleDefaultTableViewSaga() {
  const defaultTableView: 'company' | 'analyst' = yield select(selectors.ui.defaultTableView);
  const pagination: PageInfo = yield select(
    paginationSelectors.getPagination('checklistResearchInsights')
  );

  if (defaultTableView === 'analyst') {
    yield put(actions.sort.changeSortSuccess({ sortBy: DEFAULT_SORT_ANALYST }));
  } else {
    yield put(actions.sort.changeSortSuccess({ sortBy: DEFAULT_SORT_COMPANIES }));
  }

  yield put(actions.card.fetchCard());

  yield put(
    paginationActions.changePagination({
      gridKey: 'checklistResearchInsights',
      data: {
        pageNumber: 1,
        pageSize: pagination.pageSize,
      },
    })
  );
  yield put(
    userInformationActions.updateUserSettings({ checklist_insights_view_default: defaultTableView })
  );
}

export function* multiSortSaga(
  action: PayloadAction<boolean, any, Array<SortByRule<any>> | undefined>
) {
  const isMultiSort = action.payload;
  const currentSorting: SortByRule<any>[] = yield select(selectors.sort.sortBy);

  const sortingObject: SortRule<any> = {
    sortBy: currentSorting,
  };

  if (!isMultiSort) {
    yield put(actions.sort.changeSort(sortingObject));
  }
}

export default function* sourcingOutreachSaga() {
  yield takeEvery(actions.search.fetchAnalyst, fetchAnalystSaga);
  yield takeEvery(actions.search.fetchCompany, fetchCompanySaga);
  yield takeEvery(actions.card.fetchCard, fetchCardSaga);
  yield takeEvery(actions.ui.toggleDefaultTableView, toggleDefaultTableViewSaga);
  yield takeLatest(actions.filters.clearFilters, fetchChecklistResearchDataSaga);
  yield takeLatest(actions.filters.resetToDefault, fetchChecklistResearchDataSaga);
  yield takeLatest(actions.filters.applyFilters, fetchChecklistResearchDataSaga);
  yield takeLatest(actions.filters.updateFilters, fetchChecklistResearchDataSaga);
  yield takeLatest(actions.filters.resetFilter, fetchChecklistResearchDataSaga);
  yield takeLatest(actions.sort.sort, fetchChecklistResearchDataSaga);
  yield takeLatest(actions.sort.multiSort, multiSortSaga);
}
