import React, { useState } from 'react';
import { Menu, Tooltip, Popconfirm } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { CompanyService } from '@optx/services/api';
import { columns } from '@optx/components/common/table/Companies/columns/virtual-column-config';
// redux
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as searchCountSelectors } from '@features/grid/search-count';
import { selectors as filterCardsSelectors } from '@optx/redux/my-companies/filters-cards';
import { selectors as sortSelectors } from '@redux/my-companies/sort';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import { getViewId } from '@optx/features/grid/view/state/selectors';
// utils
import { mapSortFieldQuery } from '@utils/mapSortFieldQuery';
import { mapSortOrderQuery } from '@utils/mapSortOrderQuery';
import { exportFileCSV } from '@utils/csvHandler';
// constants
import { COMPANY_CSV_SIZE_LIMIT } from '@constants/limit';

const FILE_NAME = 'my companies saved search';

const RedirectSavedSearchesMenuItem = ({
  onClick,
  hideDropdown,
  ...restProps
}: MenuItemProps & { hideDropdown?: () => void }) => {
  const searchCompanyCount = useSelector(searchCountSelectors.getSearchCount('myCompanies'));
  const searchKey = useSelector(searchSelectors.getSearchKey('myCompanies'));
  const selectedCard = useSelector(filterCardsSelectors.getSelectedCard);
  const subFilter = useSelector(filterCardsSelectors.getSubFilter);
  const filter = useSelector(filterSelectors.getFilter('myCompanies'));
  const sortBy = useSelector(sortSelectors.getSorting);
  const listType = useSelector(searchSelectors.getListType('myCompanies'));
  const id = useSelector(getViewId('myCompanies'));

  const [isLoading, setIsLoading] = useState(false);

  const searchHasValues = !!searchKey || !isEmpty(filter);
  const filterForSub = subFilter ? selectedCard?.data[subFilter] : {};

  if (!searchHasValues) {
    return null;
  }

  const handleExportCSV = (includeDescription: boolean) => {
    const paramsQuery = queryString.stringify(
      {
        ...selectedCard?.filter,
        ...filterForSub,
        ...(listType === 'combined' ? { combined_search_id: id } : {}),
      },
      { arrayFormat: 'comma' }
    );

    const parameterFilter = {
      filters: paramsQuery,
      sortFields: queryString.stringify(
        { sort_fields: sortBy.map(mapSortFieldQuery) },
        { arrayFormat: 'comma' }
      ),
      sortOrders: queryString.stringify(
        {
          sort_orders: sortBy.map(mapSortOrderQuery),
        },
        { arrayFormat: 'comma' }
      ),
    };

    setIsLoading(true);

    exportFileCSV(
      FILE_NAME,
      parameterFilter,
      columns,
      CompanyService.exportToCSV,
      includeDescription
    ).then(() => {
      setIsLoading(false);
    });
  };

  const disabled = !searchCompanyCount || searchCompanyCount > COMPANY_CSV_SIZE_LIMIT || isLoading;

  const handleClick = (includeDescription = false) => {
    if (!disabled) {
      handleExportCSV(includeDescription);
    }

    hideDropdown && hideDropdown();
  };

  //   wrap Menu.Item in span to show tooltip on hovering while Menu.Item is disabled.
  return (
    <Tooltip title="Up to 100K rows" placement="left">
      <span>
        <Popconfirm
          title="Include the description?"
          onConfirm={() => handleClick(true)}
          onCancel={() => handleClick()}
          okText="Yes"
          cancelText="No"
        >
          <Menu.Item disabled={disabled} {...restProps}>
            {isLoading ? 'Exporting' : 'Export to .csv'}
          </Menu.Item>
        </Popconfirm>
      </span>
    </Tooltip>
  );
};

export default React.memo(RedirectSavedSearchesMenuItem);
