import { Modal as M, Row, Button as B } from 'antd';
import styled from 'styled-components';

const Modal = styled(M)`
  .ant-modal-body {
    padding: 0px;
  }

  form {
    padding: 24px;
  }
`;

const ShareUsersHeader = styled(Row)`
  padding: 0 0 5px;
  border-bottom: 1px solid #bfbfbf;
`;

const ShareUserRow = styled(Row)`
  padding: 10px 0;
`;

const SharedUsersWrapper = styled.div`
  max-height: 260px;
  overflow: auto;

  ${ShareUserRow} {
    border-bottom: 1px solid #bfbfbf;
  }
`;

const TitleContentWrapper = styled.div`
  width: 537px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  margin: 0;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px,
  line-height: 22px;
`;

const Button = styled(B)`
  display: flex;
  align-items: center;
  height: fit-content;
`;

const Text = styled.span`
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
`;

export default {
  ShareUserRow,
  SharedUsersWrapper,
  ShareUsersHeader,
  Modal,
  TitleContentWrapper,
  Title,
  Button,
  Text,
};
