import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import { FormItem } from 'formik-antd';
import { Typography } from 'antd';
import { OptionsType } from 'react-select';
import { isEqual } from 'lodash';
// models
import { TouchFieldProps } from './interface';
import { SelectOption } from '@optx/models/Option';
import { SuccessErrorCallback } from '@optx/models/callback';
// constants
import { SELECT_UNKNOWN } from '@optx/components/common/select/constants';
// hooks
import useAsyncSearch from '@optx/common/hooks/select/useAsyncSearch';
// components
import { AsyncSingleSelect } from '@optx/shared/view/molecules/Select';
import Styled from './MultiSelectField.styled';

interface SelectType {
  label: string | null;
  value: string | number | null;
}

const SelectAsyncContainer = styled(AsyncSingleSelect)`
  width: 100%;
  .multiselect__value-container {
    height: 32px;
  }
  .vgrkO {
    top: 9px;
  }
`;

const MultiSelectAsyncRequiredField: React.FC<TouchFieldProps> = ({ field, fieldUsedFor }) => {
  const [selectedField, , setSelectedField] = useField<string | null>(field.id);
  const [selectedOptions, setSelectedOptions] = useState<SelectType | undefined>(undefined);
  const [fieldRequired, ,] = useField<SelectOption>(field.requiredFor?.field ?? '');
  const [visible, setVisible] = useState<boolean>(false);

  const { loadOptions } = useAsyncSearch({ endpoint: field?.endpoint ?? '' });
  const [fetchedOptions, setFetchedOptions] = useState<OptionsType<SelectOption>>(
    field.id !== 'addon' ? [SELECT_UNKNOWN] : []
  );

  useEffect(() => {
    if (fieldUsedFor === 'editAllInfo' && selectedField.value) {
      setSelectedOptions({
        label: selectedField.value,
        value: selectedField.value,
      });
    } else if (field.value || field.value === '') {
      setSelectedOptions({
        label: field.value,
        value: field.value,
      });
    } else {
      setSelectedOptions({
        label: '',
        value: '',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  useEffect(() => {
    if (selectedOptions !== undefined) {
      const selectedValue = selectedOptions.value?.toString();
      setSelectedField.setValue(selectedValue && selectedValue !== '' ? selectedValue : null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  useEffect(() => {
    if (field.requiredFor) {
      const requiredList: any[] = Array.isArray(field.requiredFor.value)
        ? [...field.requiredFor.value]
        : [field.requiredFor.value];
      let isRequiredValue = false;

      if (fieldRequired.value && fieldRequired.value.label !== undefined) {
        isRequiredValue = requiredList.some(item => isEqual(item, fieldRequired.value.label));
      } else if (fieldRequired.value !== undefined) {
        isRequiredValue = requiredList.some(item => isEqual(item, fieldRequired.value));
      }

      if (!!fieldRequired.value?.toString() && isRequiredValue && visible === false) {
        setVisible(true);
      } else if (!!fieldRequired.value?.toString() && !isRequiredValue && visible === true) {
        setVisible(false);

        // reset to null if required is not met
        setSelectedField.setValue(null);
        setSelectedOptions({
          label: '',
          value: '',
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldRequired, field]);

  const getLoadOptionsDelay = (query: string, callback: SuccessErrorCallback) => {
    if (query.length > 2) {
      loadOptions(query, options => {
        let callbackResult;

        if (options) {
          if (field.id !== 'addon') {
            options.unshift(SELECT_UNKNOWN);
          }

          callbackResult = callback(options);

          // set current filter to a cached variable.
          setFetchedOptions(options);
        } else {
          const emptyOptions = [];

          if (field.id !== 'addon') {
            emptyOptions.unshift(SELECT_UNKNOWN);
          }

          callbackResult = callback(emptyOptions);

          // set current filter empty.
          setFetchedOptions(emptyOptions);
        }

        return callbackResult;
      });
    }
  };

  // when menu is closed, clear cached values.
  const onAsyncMultiselectMenuClose = () => {
    setFetchedOptions(field.id !== 'addon' ? [SELECT_UNKNOWN] : []);
  };

  return (
    <>
      {visible && (
        <Styled.SelectWrapper span={8} className={field.id}>
          <Typography.Text>{field.label}</Typography.Text>
          <FormItem name={field.id}>
            <SelectAsyncContainer
              className="addon--multiselect-async"
              onChange={(value: SelectOption) => setSelectedOptions(value)}
              loadOptions={getLoadOptionsDelay}
              controlShouldRenderValue
              closeMenuOnSelect
              defaultOptions={fetchedOptions}
              defaultValue={selectedOptions}
              value={selectedOptions}
              closeOnSelect
              onSelectResetsInput={false}
              blurInputOnSelect={false}
              onMenuClose={onAsyncMultiselectMenuClose}
            />
          </FormItem>
        </Styled.SelectWrapper>
      )}
      <Styled.ContactSelectGlobal />
    </>
  );
};

export default React.memo(MultiSelectAsyncRequiredField);
