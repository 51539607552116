import { CompanyWatchList, CreateCompanyToWatchList } from '@optx/models/WatchList';
import { Company } from '@optx/models/Company';
import { SelectOption } from '@optx/models/Option';

const createCompanyToListSuccess = (
  company: Company,
  watchList: CompanyWatchList | CreateCompanyToWatchList | SelectOption[],
  isBulk?: boolean,
  ownerName?: string
) => {
  function setWatchlist(
    companyItem: Company,
    watchlistItem: CompanyWatchList | SelectOption | CreateCompanyToWatchList
  ) {
    const companyData = companyItem;
    const isEt =
      (watchlistItem as SelectOption)?.isET || (watchlistItem as CompanyWatchList).is_et_group;

    const getLabelWithoutOwner = isBulk && (watchlistItem as SelectOption).label?.split(' (@')[0];

    const watchListData = isBulk
      ? {
          title: getLabelWithoutOwner as string,
          label: getLabelWithoutOwner as string,
          list_id: (watchlistItem as SelectOption).value,
        }
      : {
          title: (watchlistItem as CreateCompanyToWatchList).title,
          list_id: (watchlistItem as CompanyWatchList).unique_id
            ? (watchlistItem as CompanyWatchList).unique_id
            : (watchlistItem as CreateCompanyToWatchList).id,
          unique_id: (watchlistItem as CreateCompanyToWatchList).id,
          owner_name: (watchlistItem as CreateCompanyToWatchList).name,
        };

    if (isEt) {
      // if the list is in ET
      if (companyData?.et_watchlists === null) {
        // if there is no et watchList
        companyData.et_watchlists = [watchListData];

        return companyData;
      }

      const isAlreadyAddedToList = companyData?.et_watchlists?.filter(
        list => parseInt(list?.list_id as string) === parseInt(watchListData?.list_id as string)
      );
      if (isAlreadyAddedToList && isAlreadyAddedToList.length === 0)
        companyData?.et_watchlists.push(watchListData);

      return companyData;
    }

    if (companyData?.regular_watchlists === null) {
      // if there is no regular watchList
      if (watchListData.title) {
        companyData.regular_watchlists = [watchListData];
      }

      return companyData;
    }

    if (watchListData.title) {
      const isAlreadyAddedToList = companyData?.regular_watchlists?.filter(
        list => parseInt(list?.list_id as string) === parseInt(watchListData?.list_id as string)
      );
      if (isAlreadyAddedToList && isAlreadyAddedToList.length === 0)
        companyData?.regular_watchlists.push(watchListData);
    }

    return companyData;
  }

  if (isBulk) {
    (watchList as SelectOption[]).map((listItem: SelectOption) => {
      if (listItem.label.search(`@${ownerName}`) !== -1) {
        return setWatchlist(company, listItem);
      }

      return null;
    });
  }

  return setWatchlist(company, watchList as CompanyWatchList | CreateCompanyToWatchList);
};

const deleteCompanyToListSuccess = (company: Company, listId: string | number) => {
  function setWatchlist(companyItem: Company, listId: string | number) {
    const companyData = companyItem;

    const listIndexNotET =
      companyData?.regular_watchlists?.findIndex(item => item.list_id === listId) ?? -1;
    const listIndex = companyData?.et_watchlists?.findIndex(item => item.list_id === listId) ?? -1; // if null set - 1

    if (listIndex !== -1) {
      companyData?.et_watchlists?.splice(listIndex, 1);
    }

    if (listIndexNotET === -1) {
      companyData?.regular_watchlists?.splice(listIndex, 1);
    }

    return companyData;
  }

  return setWatchlist(company, listId as string | number);
};

export { createCompanyToListSuccess, deleteCompanyToListSuccess };
