import { Dictionary, isEqual } from 'lodash';
import { GroupType } from 'react-select';
import moment from 'moment';
// models
import {
  PreselectedFilter,
  FilterSource,
  BaseFilter,
  RangeFilter,
  MultiRangeNumberFilter,
  MultiCheckboxFilter,
  MultiSelectFilter,
  FilterGroupDataSection,
} from '@optx/models/filters';
import { FilterTag, FilterTagValue } from '@optx/models/tags';
import Option, {
  FormCheckboxOption,
  SelectOption,
  FormCheckableRangeOption,
  LogicOption,
  DateRangeOption,
} from '@optx/models/Option';
import {
  CheckableRangeOption,
  RangeOptionBase,
  SingleCheckboxRangeOption,
} from '@optx/models/RangeOption';
import { OutReachFilterOptions } from '@optx/redux/company-outreach/filters/interfaces';
import { ScheduledTouchesFilterTags } from '@optx/redux/scheduled-touches/interfaces';
// constants
import { FILTER_TAGS_MAX_COUNT } from '../../constants/tags';
import globalConfig from '../../constants/config';
// utils
import { formatDateInterval } from '@optx/utils/format';
import { getMultiSelectOptions } from '@utils/filters/mapFiltersOptions';
import { abbreviateNumber } from '../number';
import { generateUniqueKey } from '../uuid';
// components
import predefinedFilters from '@components/common/table/TableSourceScrubLists/utils/predefinedFilters';

const mapTags = (
  filters: Dictionary<PreselectedFilter>,
  sources: Array<FilterSource>,
  defaultFilters: Dictionary<PreselectedFilter>,
  ommitFilters: Array<string> = [],
  defaultIndex?: { [type: string]: number },
  sourcingFilters?: OutReachFilterOptions
) => {
  const tags: Array<FilterTag> = [];

  /**
   * IVC or Israel Tag is a special case where we don't have a filter linked,
   * but it should display in the general search as a tag.
   */

  if (filters.is_in_israel) {
    const values: Array<FilterTagValue> = [];
    const value: FilterTagValue = {
      value: 'Yes' as string,
    };
    values.push(value);

    tags.push({
      filter: 'is_in_israel',
      label: 'IVC or Israel',
      values,
      isDefault: false,
      stringifiedValues: filters.is_in_israel as string,
      uniqueKey: generateUniqueKey(),
    });
  }

  if (filters.parentcompany) {
    const values: Array<FilterTagValue> = [];
    const formatedValue: string =
      filters.parentcompany instanceof Array
        ? filters.parentcompany.join()
        : (filters.parentcompany as string);
    const value: FilterTagValue = {
      value: formatedValue,
    };
    values.push(value);

    tags.push({
      filter: 'parentcompany',
      label: 'Parent Company',
      values,
      isDefault: false,
      stringifiedValues: formatedValue,
      uniqueKey: generateUniqueKey(),
    });
  }

  if (filters.next_touch_date) {
    const nextTouchDateFilter =
      filters.next_touch_date instanceof Array
        ? (filters.next_touch_date as string[])
        : (filters.next_touch_date as string).split(', ');

    const tag = getSSListTouchDateTag(
      nextTouchDateFilter,
      predefinedFilters.number_of_future_touches.next_touch_date as SingleCheckboxRangeOption[],
      'next_touch_date',
      'Scheduled Next Touch Date'
    );

    tags.push(tag);
  }

  if (filters.last_touch_date) {
    const lastTouchDateFilter =
      filters.last_touch_date instanceof Array
        ? (filters.last_touch_date as string[])
        : (filters.last_touch_date as string).split(', ');

    const tag = getSSListTouchDateTag(
      lastTouchDateFilter,
      predefinedFilters.number_of_last_touch_over_6_months
        .last_touch_date as SingleCheckboxRangeOption[],
      'last_touch_date',
      'Completed Last Touch Date'
    );

    tags.push(tag);
  }

  /**
   * Is platform company is a special case where we don't have a filter linked,
   * but it should display in the general search as a tag.
   */
  if (filters.is_platform) {
    const values: Array<FilterTagValue> = [];
    const value: FilterTagValue = {
      value: 'Yes' as string,
    };
    values.push(value);

    tags.push({
      filter: 'is_platform',
      label: 'Platform Company',
      values,
      isDefault: false,
      stringifiedValues: filters.is_platform as string,
      uniqueKey: generateUniqueKey(),
    });
  }

  if (filters.similarCompanies && (filters.similarCompanies as SelectOption)?.label) {
    const values: Array<FilterTagValue> = [];
    const value: FilterTagValue = {
      value: (filters.similarCompanies as SelectOption).label,
    };

    values.push(value);

    tags.push({
      filter: 'similarCompanies',
      label: 'Similar Companies To',
      values,
      isDefault: false,
      stringifiedValues: (filters.similarCompanies as SelectOption).label,
      uniqueKey: generateUniqueKey(),
    });
  }

  sources.forEach(source => {
    if (source.data) {
      (source.data as Array<BaseFilter>).forEach(filter => {
        if (ommitFilters.indexOf(filter.column) !== -1) {
          return;
        }

        switch (filter.type) {
          case 'range_input': {
            const ranges = filters[filter.column] as Array<FormCheckableRangeOption>;

            const values: Array<FilterTagValue> = [];
            const {
              slider_plus: sliderPlus,
              slider_dots: sliderDots,
              is_formatted: isFormatted,
            } = filter as RangeFilter<Array<RangeOptionBase>>;

            let lastDot: number;

            if (sliderPlus && sliderDots && sliderDots.length) {
              lastDot = sliderDots[sliderDots.length - 1];
            }

            ranges?.forEach((range, index) => {
              let [min, max] = range.range as Array<number | string>;

              const formatter = isFormatted
                ? (value: string | number | undefined) =>
                    abbreviateNumber({ value, maxValue: range.max, dot: lastDot })
                : undefined;

              if (range.min !== min || range.max !== max) {
                if (formatter) {
                  min = formatter(min);
                  max = formatter(max);
                }

                values.push({ value: `${min} — ${max}` });
              }

              if (index === 0 && range.check) {
                range.check.forEach(item => {
                  if (item.checked) {
                    values.push({ value: item.label });
                  }
                });
              }
            });

            if (values.length) {
              const stringifiedValues = values.map(item => item.value).join(', ');
              tags.push({
                filter: filter.column,
                label: filter.label,
                values,
                isDefault: false,
                stringifiedValues,
                uniqueKey: generateUniqueKey(),
              });
            }

            break;
          }

          case 'checkbox': {
            const checkboxTag = getCheckboxTag(defaultFilters, filters, filter);
            if (checkboxTag) tags.push(checkboxTag);

            break;
          }

          case 'multi_text': {
            const options = filters[filter.column] as Array<SelectOption<string>>;
            const defaultOptions = defaultFilters[filter.column] as Array<SelectOption<string>>;
            const values: Array<FilterTagValue> = [];

            options.forEach((option, index) => {
              if (option.value) {
                values.push({
                  value: option.value,
                  isDefault: option.value === defaultOptions[index].value,
                });
              }
            });

            if (values.length) {
              // handle specific cases
              // geographic range
              if (filter.column === 'geo_range') {
                const [zipCode, range] = options;
                const [defaultZipCode, defaultRange] = defaultOptions;
                const stringifiedValues = `${range.value} miles from ${zipCode.value}`;
                tags.push({
                  filter: filter.column,
                  label: 'Range',
                  values: [
                    {
                      value: stringifiedValues,
                    },
                  ],
                  isDefault: zipCode === defaultZipCode && range === defaultRange,
                  stringifiedValues,
                  uniqueKey: generateUniqueKey(),
                });

                break;
              }

              // handle generic case
              const stringifiedValues = values.map(item => item.value).join(', ');
              const isDefault: boolean = values.some(item => item.isDefault);

              tags.push({
                filter: filter.column,
                label: filter.label,
                values: values.map(item => ({ value: item.value })),
                isDefault,
                stringifiedValues,
                uniqueKey: generateUniqueKey(),
              });
            }

            break;
          }

          case 'logic_checkbox': {
            const options = filters[filter.column] as Array<LogicOption>;
            const defaultOptions = defaultFilters[filter.column] as Array<LogicOption>;

            options.forEach((option, index) => {
              if (option.in || option.notIn) {
                const logicOptionValues: Array<FilterTagValue> = [];

                if (option.in) {
                  logicOptionValues.push({ value: 'In', isDefault: defaultOptions[index].in });
                }

                if (option.notIn) {
                  logicOptionValues.push({
                    value: 'Not In',
                  });
                }

                const stringifiedValues = logicOptionValues.map(item => item.value).join(', ');
                tags.push({
                  filter: filter.column,
                  label: option.label,
                  values: logicOptionValues,
                  stringifiedValues,
                  isDefault: defaultOptions[index].notIn,
                  optionId: index,
                  uniqueKey: generateUniqueKey(),
                });
              }
            });

            break;
          }

          case 'multiple_range_nr': {
            const options = filters[filter.column] as Array<CheckableRangeOption<number>>;
            const defaultOptions = defaultFilters[filter.column] as Array<
              CheckableRangeOption<number>
            >;
            const values: Array<FilterTagValue> = [];

            options.forEach((option, index) => {
              if (option.checked) {
                values.push({ value: option.label, isDefault: defaultOptions[index].checked });
              }
            });

            // add values for custom option as well
            if ((filter as MultiRangeNumberFilter).custom) {
              const customOption = filters[
                `${filter.column}_custom`
              ] as CheckableRangeOption<string>;

              if (customOption.checked && customOption.max && customOption.min) {
                values.push({ value: `${customOption.min} — ${customOption.max}` });
              }
            }

            if (values.length) {
              const stringifiedValues = values.map(item => item.value).join(', ');
              tags.push({
                filter: filter.column,
                label: filter.label,
                values,
                stringifiedValues,
                uniqueKey: generateUniqueKey(),
              });
            }

            break;
          }

          case 'date_range': {
            const dateRangeTag = getDateRangeTag(defaultFilters, filters, filter);
            if (dateRangeTag) tags.push(dateRangeTag);
            break;
          }

          case 'radio': {
            const options = (filter as BaseFilter<Array<Option>>).data;
            const currentValue = filters[filter.column] as string;
            const defaultValue = defaultFilters[filter.column] as string;

            options.forEach(option => {
              if (option.value === currentValue) {
                tags.push({
                  filter: filter.column,
                  label: filter.label,
                  values: [{ value: option.name! }],
                  stringifiedValues: option.name,
                  isDefault: currentValue === defaultValue,
                  uniqueKey: generateUniqueKey(),
                });
              }
            });

            break;
          }

          case 'multi_select': {
            tags.push(getMultiSelectTag(defaultFilters, filters, filter));
            break;
          }

          // eslint-disable-next-line no-fallthrough
          case 'single_select': {
            const options = filters[filter.column] as Array<SelectOption> | undefined;
            const defaultOptions = defaultFilters[filter.column] as Array<SelectOption> | undefined;

            const tag = mapMultiSelectTag(filter, options, defaultOptions);

            if (tag) {
              tags.push({ ...tag, isDefault: tag.values.some(item => item.isDefault) });
            }

            break;
          }

          case 'endpoint_query': {
            const options = filters[filter.column] as Array<SelectOption> | undefined;

            if (options) {
              const values: Array<FilterTagValue> = [];

              options.forEach(option => {
                const value: FilterTagValue = {
                  value: option.label,
                };

                values.push(value);
              });

              if (values.length) {
                const stringifiedValues = values.map(item => item.value).join(', ');

                tags.push({
                  filter: filter.column,
                  label: filter.label,
                  values,
                  isDefault: false,
                  stringifiedValues,
                  uniqueKey: generateUniqueKey(),
                });
              }
            }

            break;
          }

          case 'date_range_radio': {
            const value = filters[filter.column] as [string, string];
            const tag = mapDateRangesTag(filter, value, defaultIndex?.date_range_radio);

            tags.push(tag);

            break;
          }

          case 'filter_group_custom': {
            const data = filter.data as FilterGroupDataSection[];

            data.forEach(section => {
              switch (section.type) {
                case 'multi_select': {
                  tags.push(getMultiSelectTag(defaultFilters, filters, section));
                  break;
                }

                case 'checkbox': {
                  const checkboxTag = getCheckboxTag(defaultFilters, filters, section);
                  if (checkboxTag) tags.push(checkboxTag);
                  break;
                }

                case 'radio': {
                  const options = (section as BaseFilter<Array<Option>>).data;
                  const currentValue = filters[section.column] as string;
                  const defaultValue = defaultFilters[section.column] as string;
                  options.forEach(option => {
                    if (option.value === currentValue) {
                      tags.push({
                        filter: section.column,
                        label: section.label ? section.label : section.column.replaceAll('_', ' '),
                        values: [{ value: option.name! }],
                        stringifiedValues: option.name,
                        isDefault: currentValue === defaultValue,
                        uniqueKey: generateUniqueKey(),
                        resetFor: section.reset_for,
                      });
                    }
                  });

                  break;
                }

                case 'date_range': {
                  const dateRangeTag = getDateRangeTag(defaultFilters, filters, section);
                  if (dateRangeTag) tags.push(dateRangeTag);
                  break;
                }

                default:
                  break;
              }
            });

            break;
          }

          default:
            break;
        }
      });
    }
  });

  if (sourcingFilters?.analyst_id) {
    if (Object.keys(sourcingFilters!).length !== 0) {
      const analystInfo = sourcingFilters!.analyst_id as unknown as Array<SelectOption<string>>;
      const analystNames = analystInfo?.map(list => ({
        value: list.label,
      }));

      if (
        analystNames &&
        !isEqual(sourcingFilters?.defaultAnalystId, sourcingFilters?.analyst_id)
      ) {
        tags.push({
          filter: 'analyst_name',
          label: 'Analysts Name',
          values: analystNames,
          uniqueKey: generateUniqueKey(),
        });
      }
    }
  }

  if (sourcingFilters?.column_name) {
    if (Object.keys(sourcingFilters).length !== 0) {
      const analystInfo = sourcingFilters.column_name as unknown as SelectOption<string>;
      const sourcingOutReachColumnNames = analystInfo.label;

      const values: Array<FilterTagValue> = [];
      const anlystValues: FilterTagValue = {
        value: sourcingOutReachColumnNames,
      };

      if (
        sourcingOutReachColumnNames &&
        !isEqual(sourcingFilters.column_name, sourcingFilters.defaultColumnName)
      ) {
        values.push(anlystValues);

        tags.push({
          filter: 'column_name',
          label: 'Column Name',
          values,
          uniqueKey: generateUniqueKey(),
        });
      }
    }
  }

  if (sourcingFilters?.ddate && sourcingFilters?.dateLabel) {
    if (Object.keys(sourcingFilters).length !== 0) {
      const values: Array<FilterTagValue> = [];
      const columnName = sourcingFilters.ddate;
      const [start, end] = columnName;

      const columnValues: FilterTagValue = {
        value: sourcingFilters.dateLabel,
      };
      const customColumnValues: FilterTagValue = {
        value: formatDateInterval(start!, end!),
      };

      if (!isEqual(sourcingFilters.ddate, sourcingFilters.defaultDateRange)) {
        if (columnName && sourcingFilters.dateLabel === 'Custom Range') {
          values.push(customColumnValues);

          tags.push({
            filter: 'date_range',
            label: 'Custom Range',
            values,
            uniqueKey: generateUniqueKey(),
          });
        } else {
          values.push(columnValues);

          tags.push({
            filter: 'date_range',
            label: 'Date Intervals',
            values,
            uniqueKey: generateUniqueKey(),
          });
        }
      }
    }
  }

  return tags;
};

/**
 * Retrieves the filter tag for the old touch filters needed in sslist in order to
 * show them as tags, but hide them from filter panel
 * @param {string[]} touchDateFilters - The array of touch date filters
 * @param {SingleCheckboxRangeOption[]} defaultTouchDateFilters - The array of default touch date filters
 * @param {string} filterColumn - The filter column for tag
 * @param {string} filterLabel - The tag label
 */
const getSSListTouchDateTag = (
  touchDateFilters: string[],
  defaultTouchDateFilters: SingleCheckboxRangeOption[],
  filterColumn: string,
  filterLabel: string
) => {
  const values: SingleCheckboxRangeOption[] = [];

  touchDateFilters.forEach(dateValue => {
    const value = defaultTouchDateFilters.find(filter => filter.value === dateValue);

    if (value) values.push(value);
  });

  return {
    filter: filterColumn,
    label: filterLabel,
    values: values.map(item => ({ value: item.label })),
    isDefault: false,
    stringifiedValues: values.map(item => item.label).join(', '),
    uniqueKey: generateUniqueKey(),
  };
};

const getMultiSelectTag = (
  defaultFilters: Dictionary<PreselectedFilter>,
  filters: Dictionary<PreselectedFilter>,
  filter: BaseFilter<any> | FilterGroupDataSection
) => {
  const defaultValue = (defaultFilters[filter.column] as string | undefined) ?? [];

  let values;

  if (!Array.isArray(filters[filter.column])) {
    values = filters[filter.column] ?? [];
  } else {
    values = (filters[filter.column] as Option[]).map((item: Option) => ({
      value: item.label || item.name || item,
    }));
  }

  const isDefault =
    Array.isArray(defaultValue) &&
    Array.isArray(values) &&
    (defaultValue?.length === values?.length || values?.length === 0);

  return {
    filter: filter.column,
    label: filter.label ? filter.label : filter.column.replaceAll('_', ' '),
    values,
    isDefault,
    stringifiedValues: filters.name,
    uniqueKey: generateUniqueKey(),
  } as FilterTag;
};

const getCheckboxTag = (
  defaultFilters: Dictionary<PreselectedFilter>,
  filters: Dictionary<PreselectedFilter>,
  filter: BaseFilter<any> | FilterGroupDataSection
) => {
  const options = filters[filter.column] as Array<FormCheckboxOption>;
  const defaultOptions = defaultFilters[filter.column] as Array<FormCheckboxOption>;
  const values: Array<FilterTagValue> = [];

  options?.forEach((option, index) => {
    if (option.checked) {
      values.push({
        value: option.label,
        isDefault: defaultOptions[index].checked,
      });
    }
  });

  // add values for custom option as well
  if ((filter as MultiCheckboxFilter).custom) {
    const customOption = filters[`${filter.column}_custom`] as SingleCheckboxRangeOption<string>;

    if (customOption.checked && customOption.value) {
      values.push({
        value: `${customOption.value.split('_')[0]} — ${customOption.value.split('_')[1]}`,
      });
    }
  }

  if (values.length) {
    const stringifiedValues = values.map(item => item.value).join(', ');
    const isDefault: boolean = values.some(item => item.isDefault);

    return {
      filter: filter.column,
      label: filter.label ? filter.label : filter.column.replaceAll('_', ' '),
      values: values.map(item => ({ value: item.value })),
      isDefault,
      stringifiedValues,
      uniqueKey: generateUniqueKey(),
    };
  }

  return null;
};

const getDateRangeTag = (
  defaultFilters: Dictionary<PreselectedFilter>,
  filters: Dictionary<PreselectedFilter>,
  filter: BaseFilter<any> | FilterGroupDataSection
) => {
  const [start, end] = filters[filter.column] as Array<SelectOption<string>>;
  const [defaultStart, defaultEnd] = defaultFilters[filter.column] as Array<SelectOption<string>>;

  if (start.value || end.value) {
    const startValue = start.value
      ? moment(start.value).format(globalConfig.short_date.DATE_FORMAT)
      : '';
    const endValue = end.value ? moment(end.value).format(globalConfig.short_date.DATE_FORMAT) : '';
    const stringifiedValues = `${startValue || ''} — ${endValue || ''}`;

    return {
      filter: filter.column,
      label: filter.label ? filter.label : filter.column.replaceAll('_', ' '),
      values: [
        { value: startValue, isDefault: start.value === defaultStart.value },
        { value: endValue, isDefault: end.value === defaultEnd.value },
      ],
      stringifiedValues,
      showString: true,
      isDefault: start.value === defaultStart.value && end.value === defaultEnd.value,
      isDateInterval: true,
      uniqueKey: generateUniqueKey(),
    };
  }

  return null;
};
/**
 * Map scheduled touches filters to tags.
 * @param filters filters from scheduledTouches page
 */

export const mapScheduledTouchesTags = (filters: ScheduledTouchesFilterTags) => {
  const tags: Array<FilterTag> = [];

  if (filters.rankType && filters.rankType !== 'All') {
    tags.push({
      filter: 'rank_type',
      label: 'Rank Type',
      values: [
        {
          value: filters.rankType,
        },
      ],
      uniqueKey: generateUniqueKey(),
    });
  }

  if (filters.selectedPeriod && filters.selectedPeriod === 'Custom Range') {
    tags.push({
      filter: 'selected_period',
      label: 'Custom Range',
      values: [
        {
          value: formatDateInterval(filters.selectedDateRange[0]!, filters.selectedDateRange[1]!),
        },
      ],
      uniqueKey: generateUniqueKey(),
    });
  }

  if (
    filters.selectedPeriod &&
    filters.selectedPeriod !== 'This Week' &&
    filters.selectedPeriod !== 'Custom Range'
  ) {
    tags.push({
      filter: 'selected_period',
      label: 'Date Intervals',
      values: [
        {
          value: filters.selectedPeriod,
        },
      ],
      uniqueKey: generateUniqueKey(),
    });
  }

  if (filters.stageType && filters.stageType !== 'All') {
    tags.push({
      filter: 'stage_type',
      label: 'Stage Type',
      values: [
        {
          value: filters.stageType,
        },
      ],
      uniqueKey: generateUniqueKey(),
    });
  }

  if (filters.touchType && filters.touchType !== 'All') {
    tags.push({
      filter: 'touch_type',
      label: 'Touch Type',
      values: [
        {
          value: filters.touchType,
        },
      ],
      uniqueKey: generateUniqueKey(),
    });
  }

  return tags;
};

const GROUP_COUNT_DISPLAY = 1;

/**
 * Map multi select value to tag.
 * Grouped tags have following shape:
 * {First Selected Group}, {First option outside first group}, {rest options outside first group}
 * @param filter filter information.
 * @param value current filter value.
 * @param defaultValue filter default value.
 */
export function mapMultiSelectTag(
  filter: BaseFilter<Array<Option>>,
  value: Array<SelectOption> | undefined = [],
  defaultValue: Array<SelectOption> | undefined = []
): FilterTag | null {
  const values: Array<FilterTagValue> = [];
  const valuesDictionary = value.reduce<Dictionary<boolean>>((acumulator, currentValue) => {
    return {
      ...acumulator,
      [currentValue.value]: true,
    };
  }, {});
  let tagIsDefault = true;
  const isGrouped = filter.data.some(item => item.group);
  let groupedOptionsCount = 0;
  const selectedGroups: Array<number> = [];
  const groupedValues: Dictionary<boolean> = {};

  // Handle grouped options.
  if (isGrouped) {
    // Find what groups are fully selected.
    const options = getMultiSelectOptions(filter as MultiSelectFilter<Array<Option>>);

    options.forEach((option, index) => {
      const groupOptions = (option as GroupType<SelectOption>).options;

      if (groupOptions) {
        // grouped options
        if (!groupOptions.some(item => valuesDictionary[item.value] === undefined)) {
          selectedGroups.push(index);
        }
      }
    });

    if (selectedGroups.length) {
      selectedGroups.forEach((optionIndex, index) => {
        const group = options[optionIndex] as GroupType<SelectOption>;

        if (index < GROUP_COUNT_DISPLAY) {
          const tagValue: FilterTagValue = {
            value: group.label,
            isDefault: false, // TODO: add check for all values in group if they are default.
          };

          values.push(tagValue);

          groupedOptionsCount += group.options.length;

          group.options.forEach(option => {
            groupedValues[option.value] = true;
          });
        }
      });
    }
  }

  let displayedOptionsOutsideGroup = 0;

  value.forEach(option => {
    const valueIsDefault = !!defaultValue && defaultValue.some(item => item.value === option.value);
    tagIsDefault = tagIsDefault && valueIsDefault;

    if (groupedOptionsCount === 0) {
      const tagValue: FilterTagValue = {
        value: option.label,
        isDefault: valueIsDefault,
      };

      values.push(tagValue);
    } else if (displayedOptionsOutsideGroup === 0 && !groupedValues[option.value]) {
      // include the first option outside group if any.
      const tagValue: FilterTagValue = {
        value: option.label,
        isDefault: valueIsDefault,
      };

      values.push(tagValue);
      displayedOptionsOutsideGroup++;
    }
  });

  if (groupedOptionsCount !== 0) {
    const countOptionsLeft = value.length - groupedOptionsCount - displayedOptionsOutsideGroup;

    if (countOptionsLeft) {
      const tagValue: FilterTagValue = {
        value: `+${countOptionsLeft} More`,
        isDefault: false,
      };

      values.push(tagValue);
    }
  }

  // Also check default values against current values for any values not included.
  const defaultValueNotInValues = defaultValue?.some(item => !valuesDictionary[item.value]);

  tagIsDefault = tagIsDefault && !defaultValueNotInValues;

  const stringifiedValues = values.map(item => item.value).join(', ');
  const tag: FilterTag = {
    filter: filter.column,
    label: filter.label,
    values,
    stringifiedValues,
    uniqueKey: generateUniqueKey(),
    isDefault: tagIsDefault,
  };

  return tag;
}

export function mapDateRangesTag(
  filter: BaseFilter<Array<DateRangeOption>>,
  value: [string, string],
  defaultIndex?: number
): FilterTag {
  const options = filter.data;
  const defaultValue = filter.default_value;

  const preselectedOption = options.find(option => {
    if (option.start === value[0] && option.end === value[1]) {
      return true;
    }

    return false;
  });

  if (preselectedOption) {
    const stringifiedValues = preselectedOption.label;

    // preselected option
    return {
      filter: filter.column,
      label: filter.label,
      values: [
        {
          value: preselectedOption.label as string,
        },
      ],
      isDefault: defaultValue?.label
        ? preselectedOption.label === defaultValue.label
        : preselectedOption === options[defaultIndex ?? 0],
      stringifiedValues,
      uniqueKey: generateUniqueKey(),
    };
  }

  // custom range
  const tagValues: Array<FilterTagValue> = value.map(option => ({
    value: moment(option).format(globalConfig.short_date.DATE_FORMAT),
  }));

  const [start, end] = value;

  return {
    filter: filter.column,
    label: 'Custom Range',
    values: tagValues,
    isDefault: false,
    showString: true,
    stringifiedValues: formatDateInterval(start, end),
    uniqueKey: generateUniqueKey(),
    isDateInterval: true,
  };
}

/**
 * Convert tag values to labels.
 * @param values tag values
 * @param maxCount maximum number of showed tag values.
 */
export const tagsValue = (tag: FilterTag, maxCount = FILTER_TAGS_MAX_COUNT) => {
  const { values, isDateInterval } = tag;

  if (isDateInterval) {
    return `${values[0]?.value} — ${values[1]?.value}`;
  }

  if (maxCount >= values.length) {
    return values
      .filter(option => !option.isDefault)
      .map(option => {
        if (option.value && Array.isArray(option.value)) {
          if (option.value.includes(' - ')) {
            return option.value.replace('-', '—');
          }

          if (option.value?.includes(' \u2013 ')) {
            return option.value.replace('\u2013', '—');
          }
        }

        return option.value;
      })
      .join(', ');
  }

  const moreCount = values.length - maxCount;
  const displayedValues = values.filter(option => !option.isDefault).slice(0, maxCount);
  const stringifiedValues = `${displayedValues
    .map(option => {
      if (option.value && option.value.includes(' - ')) {
        return option.value.replace('-', '—');
      }

      if (option.value && option.value.includes(' \u2013 ')) {
        return option.value.replace('\u2013', '—');
      }

      return option.value;
    })
    .join(', ')}, ${moreCount} more`;

  return stringifiedValues;
};

export default mapTags;
