import { CaseReducer, PayloadAction, createReducer } from '@reduxjs/toolkit';
// models
import { UploadFile } from 'antd/lib/upload/interface';
import { DocumentUploadModalState, EncodedFile } from './interfaces';
// redux
import { toggleReducer } from '@redux/feature/toggle/reducers';
import * as actions from './actions';

const initialState: DocumentUploadModalState = {
  error: '',
  open: false,
  uploading: false,
  files: [],
  encodedFiles: [],
};

const toggleModalReducer: CaseReducer<DocumentUploadModalState, PayloadAction> = (
  draftState,
  action
) => {
  draftState.open = toggleReducer(draftState.open);
};

const updateFileListReducer: CaseReducer<DocumentUploadModalState, PayloadAction<UploadFile[]>> = (
  draftState,
  action
) => {
  const uniqueFiles = action.payload.filter(
    (file, index, self) => index === self.findIndex(t => t.name === file.name)
  );

  draftState.files = uniqueFiles;
};

const updateFileEncodingListReducer: CaseReducer<
  DocumentUploadModalState,
  PayloadAction<EncodedFile>
> = (draftState, action) => {
  draftState.encodedFiles = [...draftState.encodedFiles, action.payload];
};

const removeFileEncodingReducer: CaseReducer<DocumentUploadModalState, PayloadAction<string>> = (
  draftState,
  action
) => {
  draftState.encodedFiles = draftState.encodedFiles.filter(
    encodedFile => encodedFile.name !== action.payload
  );
};

const uploadFilesReducer: CaseReducer<DocumentUploadModalState> = draftState => {
  draftState.uploading = true;
};

const uploadFilesSuccessReducer: CaseReducer<DocumentUploadModalState> = draftState => {
  draftState.uploading = false;
};

const uploadFilesErrorReducer: CaseReducer<DocumentUploadModalState, PayloadAction<string>> = (
  draftState,
  action
) => {
  draftState.uploading = false;
  draftState.error = action.payload;
};

const failedUploadFilesReducer: CaseReducer<DocumentUploadModalState> = draftState => {
  draftState.uploading = false;
  draftState.files.splice(0, 1);
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.toggleModal, toggleModalReducer)
    .addCase(actions.updateFileList, updateFileListReducer)
    .addCase(actions.updateFileEncodingList, updateFileEncodingListReducer)
    .addCase(actions.removeFileEncoding, removeFileEncodingReducer)
    .addCase(actions.uploadFiles, uploadFilesReducer)
    .addCase(actions.uploadFilesSuccess, uploadFilesSuccessReducer)
    .addCase(actions.uploadFilesError, uploadFilesErrorReducer)
    .addCase(actions.failedUploadFiles, failedUploadFilesReducer)
);

export default reducer;
