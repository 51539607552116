import React from 'react';
import { Row, Col } from 'antd';
// components
import { MyCompaniesCardDataProps, MyCompaniesCardValues } from '../interface';
import CardSubFilters from './CardSubFilters';

interface CardContentColumnProps {
  values: Array<MyCompaniesCardValues>;
  data: MyCompaniesCardDataProps;
}

const CardContentColumn: React.FC<CardContentColumnProps> = ({ values, data }) => {
  return (
    <Row justify="center" className="my-companies-card-columns__content">
      <Col xs={16} lg={16} xl={18} xxl={16}>
        <Row gutter={8}>
          {values.map((itemValue, index) => (
            <Col
              xs={{ span: 10, offset: index % 2 > 0 ? 4 : 0 }}
              lg={{ span: 9, offset: index % 2 > 0 ? 6 : 0 }}
              xl={{ span: 12, offset: 0 }}
              xxl={{ span: 10, offset: index % 2 > 0 ? 4 : 0 }}
              className="my-companies-card-columns__content-column"
              key={index}
            >
              <CardSubFilters
                key={index}
                cardId={data.id}
                value={itemValue.value}
                label={itemValue.label}
                filter={data.filters[index].label}
                filterId={index}
                valueProps={itemValue.valueProps}
                className={itemValue.className}
              />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default CardContentColumn;
