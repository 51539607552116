import styled, { css } from 'styled-components';

import { Styled as StyledCompanyTable } from '@components/common/table/Companies/CompanyTable.styled';

const Table = styled(StyledCompanyTable.CompanyTable)<{
  isEmpty: boolean;
  overflowHeight: number | null;
}>`
  /* Hide body horizontal scroll when there is data in order to display only external horizontal scroll and not both */
  /* This should appear only if there is X-overflow */
  ${props =>
    !props.isEmpty &&
    props.overflowHeight &&
    css`
      .ant-table-body::after {
        content: '';
        display: block;
        position: absolute;
        height: ${props.overflowHeight}px;
        width: 100%;
        bottom: 0;
        z-index: 2;
        background: #fff;
      }
    `}

  .ant-table-header {
    .ant-table-cell::after {
      content: attr(data-order);
      width: auto;
      position: absolute;
      top: unset;
      bottom: 58%;
      right: 5px;
      left: unset;
      font-size: 14px;
      color: #1890ff;
      transform: unset;
      box-shadow: none;
    }
  }
  .ant-table-body {
    min-height: 61px;
  }
`;

export const Styled = {
  Table,
};
