import { createSelector } from 'reselect';
import queryString from 'query-string';
import { isEmpty, isEqual } from 'lodash';
// interface
import { AppState } from '@optx/redux/interfaces';
import { SelectOption } from '@optx/models/Option';
import { SpecialFilterTag } from '@optx/models/tags';
import { OutReachFilterOptions } from '@optx/redux/company-outreach/filters/interfaces';
// utils
import { generateUniqueKey } from '@optx/utils/uuid';
import mapFiltersToURLParams from '@optx/utils/filters/mapFiltersToURLParams';
import mapTags from '@optx/utils/filters/mapTags';
// redux
import { selectors as filtersSelectors } from '@redux/company/filters';
import { selectors as filterSourcingOutReachSelectors } from '@redux/company-outreach/filters';
import { localSelectors as localHistogramSelectors } from '@features/histograms/base-histograms';
import { getSearchKey as searchKey } from '@optx/features/grid/searchkey/state/selectors';
import { getSearchCount } from '@optx/features/grid/search-count/state/selectors';

// specific selectors
const selectCompaniesOutReachState = (state: AppState) => state.companyOutSourcing;

export const getFilters = createSelector(
  selectCompaniesOutReachState,
  state => state.filter.filter
);
export const getCustomClearedFilter = createSelector(
  selectCompaniesOutReachState,
  state => state.filter.customClearedfilter
);
export const getClearedFilter = createSelector(
  selectCompaniesOutReachState,
  state => state.filter.clear
);
export const getSorting = createSelector(selectCompaniesOutReachState, state => state.sort.sortBy);

export const isMultiSort = createSelector(
  selectCompaniesOutReachState,
  state => state.sort.multiSort
);

export const getSourcingFilters = createSelector(
  selectCompaniesOutReachState,
  state => state.filter.sourcingOutReachFilterOptions
);

export const searchHasValues = createSelector(
  searchKey('outreach'),
  getFilters,
  (searchKey, filter) => !!searchKey || !isEmpty(filter)
);

export const getDefaultDateRange = createSelector(
  selectCompaniesOutReachState,
  state => state.filter.dateFilterDefault
);

export const getDefaultAnalystId = createSelector(
  selectCompaniesOutReachState,
  state => state.filter.analystIdsFilterDefault
);

export const getDefaultColumnName = createSelector(
  selectCompaniesOutReachState,
  state => state.filter.columnNamesFilterDefault
);

export const showCustomClearedFilter = createSelector(
  searchKey('outreach'),
  filtersSelectors.getFiltersMap,
  getFilters,
  getCustomClearedFilter,
  getSourcingFilters,
  getDefaultDateRange,
  getDefaultAnalystId,
  getDefaultColumnName,
  (
    searchKey,
    normalizedFilters,
    filter,
    customClearedFilter,
    sourcingFilters,
    dateRange,
    defaultAnalystId,
    defaultColumnName
  ) => {
    if (searchKey) {
      return true;
    }

    const normalizedFilter = { ...filter };

    if (normalizedFilter.saved_list_id !== undefined) {
      delete normalizedFilter.saved_list_id;
    }

    if (normalizedFilter.ddate) {
      const { ddate } = normalizedFilter;

      if (isEqual(ddate, dateRange)) {
        delete normalizedFilter.ddate;
      }
    }

    const filterURLParams = mapFiltersToURLParams(normalizedFilters, normalizedFilter);
    const filterURL = {
      ...filterURLParams,
      geo_range: undefined,
    };
    const filterQuery = queryString.stringify(filterURL, { arrayFormat: 'comma' });

    const customFilterURLParams = mapFiltersToURLParams(normalizedFilters, customClearedFilter);
    const customFilterURL = {
      ...customFilterURLParams,
      geo_range: undefined,
    };
    const customFilterQuery = queryString.stringify(customFilterURL, {
      arrayFormat: 'comma',
    });

    if ((sourcingFilters?.analyst_id as unknown as Array<SelectOption<string>>)?.length === 0) {
      return false;
    }

    if (sourcingFilters?.ddate === undefined) {
      return false;
    }

    const isSourcingFilters =
      !isEqual(sourcingFilters?.analyst_id, defaultAnalystId) ||
      !isEqual(sourcingFilters?.column_name, defaultColumnName) ||
      !isEqual(sourcingFilters?.ddate, dateRange);

    return filterQuery !== customFilterQuery || isSourcingFilters;
  }
);

export const getLabel = createSelector(selectCompaniesOutReachState, state => state.filter.label);

export const getTags = createSelector(
  getFilters,
  filtersSelectors.getCompanyFilters,
  getClearedFilter,
  searchKey('outreach'),
  getSourcingFilters,
  getLabel,
  getDefaultDateRange,
  filterSourcingOutReachSelectors.getSourcingFiltersOptionsDate,
  getDefaultAnalystId,
  getDefaultColumnName,
  (
    filter,
    filterSources,
    clearedFilter,
    searchKey,
    sourcingFilters,
    label,
    defaultDateRange,
    optionsDate,
    defaultAnalystId,
    defaultColumnName
  ) => {
    const optionsDateLabel = optionsDate?.find(
      (item: { start: string; end: string }) =>
        (sourcingFilters?.ddate as unknown as [string | null] | undefined)?.includes(item.start) &&
        (sourcingFilters?.ddate as unknown as [string | null] | undefined)?.includes(item.end)
    );

    const customSourcingFilter = {
      ...sourcingFilters,
      ddate: sourcingFilters?.ddate,
      dateLabel: label || optionsDateLabel?.label,
      defaultAnalystId,
      defaultColumnName,
      defaultDateRange,
    };
    const tags = mapTags(
      filter,
      filterSources,
      clearedFilter,
      undefined,
      undefined,
      customSourcingFilter as unknown as OutReachFilterOptions
    );

    if (searchKey) {
      tags.unshift({
        filter: 'keyword' as SpecialFilterTag,
        uniqueKey: generateUniqueKey(),
        label: 'Keyword',
        values: [
          {
            value: searchKey,
          },
        ],
      });
    }

    return tags;
  }
);

// Histogram filters.
const selectHistogramsState = (state: AppState) => state.companyOutSourcing.histogramFilters;

export const selectEditHistograms = createSelector(
  selectHistogramsState,
  localHistogramSelectors.selectEditHistograms
);

export const selectHistograms = createSelector(
  selectHistogramsState,
  localHistogramSelectors.histograms
);

export const selectAllFiltersWithHistograms = createSelector(
  selectHistogramsState,
  localHistogramSelectors.allFiltersWithHistograms
);

export const getHistogramSearchCount = createSelector(
  selectHistogramsState,
  localHistogramSelectors.searchCount
);

export const selectChangedFilters = createSelector(
  selectHistogramsState,
  localHistogramSelectors.changedFilters
);

export const loadingHistograms = createSelector(selectHistogramsState, state => state.loading);

export const histogramsInitialized = createSelector(
  selectHistogramsState,
  localHistogramSelectors.isInitialized
);

export const selectHistogramRequestFilters = createSelector(
  selectHistogramsState,
  localHistogramSelectors.histogramRequestFilters
);

export const selectDefaultHistograms = createSelector(
  selectHistogramsState,
  localHistogramSelectors.defaultHistograms
);

export const histogramsEnabled = createSelector(
  selectHistogramsState,
  localHistogramSelectors.isEnabled
);

/**
 * Search count for filters modal. Usually if histograms are enabled the call will update this count.
 * When the search is applied the count will be the one in search.
 */
export const getEditModeSearchCount = createSelector(
  selectCompaniesOutReachState,
  getHistogramSearchCount,
  getSearchCount('outreach'),
  (state, histogramSearchCount, totalCount) =>
    typeof histogramSearchCount === 'number' ? histogramSearchCount : totalCount
);
