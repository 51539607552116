import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { getUserId } from '@redux/user/information/selectors';
import { selectors, actions } from '../../../redux/contacts/saved-searches';
import { selectors as contactsFiltersSelectors } from '../../../redux/contacts/filters';
import { useShouldFetch } from '../fetch';

const useFetchContactsSavedSearches = () => {
  const dispatch = useDispatch();
  const filtersLoaded = useSelector(contactsFiltersSelectors.loaded);
  const shouldFetchSearches = useSelector(selectors.shouldFetch);
  const query = useSelector(selectors.getQuery);
  const userId = useSelector(getUserId);

  const fetchSavedSearches = useCallback(
    () => {
      userId && dispatch(actions.fetchContactSavedSearches(query));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, userId]
  );

  // Load saved searches after contacts filters are loaded.
  useShouldFetch(filtersLoaded && shouldFetchSearches && !!userId, fetchSavedSearches);
};

export default useFetchContactsSavedSearches;
