import React from 'react';
// models
import { CompanyProfile } from '@optx/models/Company';
import { Cell } from '@models/table/Cell';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
// hooks
import useReferrerProfileLink from '@hooks/useReferrerLink';
// components
import { Styled } from './TableCellName.styled';

const TableCellNameLink: React.FC<Cell<CompanyProfile>> = ({ value, record }) => {
  const { referrerUrl } = useReferrerProfileLink(record.company_id);

  return (
    <Styled.CompanyLink to={referrerUrl}>{value || <>{DEFAULT_CELL_VALUE}</>}</Styled.CompanyLink>
  );
};

export default React.memo(TableCellNameLink);
