import React, { useMemo, useState, useEffect, useContext, useRef } from 'react';
import { Dictionary, cloneDeep } from 'lodash';
import { useField, FieldArrayRenderProps } from 'formik';
import { Checkbox } from 'formik-antd';
import { Slider, Row, Col, Typography } from 'antd';
import { SliderMarks } from 'antd/lib/slider';
import numeral from 'numeral';
// models
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RangeFilter } from '@optx/models/filters';
import { RangeOptionBase } from '@optx/models/RangeOption';
import { FormCheckableRangeOption } from '@optx/models/Option';
// utils
import { abbreviateNumber, parseNumberFromAbbreviation } from '@utils/number';
import { Histogram } from '@optx/components/feature/company-filters/Histogram';
import { FiltersContext } from '@optx/components/feature/company-filters/FiltersContext';
// components
import RangeInputNumber from './RangeInputNumber';

interface FilterRangeContentProps {
  arrayHelpers: FieldArrayRenderProps;
  filter: RangeFilter<Array<RangeOptionBase>>;
  index: number;
}

const FilterRangeContent: React.FC<FilterRangeContentProps> = ({ arrayHelpers, filter, index }) => {
  const fieldKey = `${filter.column}[${index}]`;
  const [field, , fieldHelpers] = useField<FormCheckableRangeOption>(fieldKey);
  const filtersContext = useContext(FiltersContext);
  const minValue = useRef<number | null>(null);
  const maxValue = useRef<number | null>(null);

  const [minField, , minFieldHelpers] = useField<number | undefined>(`${fieldKey}.range[0]`);
  const [maxField, , maxFieldHelpers] = useField<number | undefined>(`${fieldKey}.range[1]`);

  const checkRef = useRef(field.value.check);
  const rangesRef = useRef();

  const [currentSlideValue, setCurrentSlideValue] = useState<[number, number]>([
    minField.value as number,
    maxField.value as number,
  ]);

  let maxFilterValue: number;
  let lastDot: number | undefined;

  const boxIndex: number = index;

  if (filter.slider_plus && filter.slider_dots && filter.slider_dots.length) {
    lastDot = filter.slider_dots[filter.slider_dots.length - 1];
    maxFilterValue = lastDot;
  } else {
    maxFilterValue = Number(filter.placeholders.max);
  }

  useEffect(() => {
    setCurrentSlideValue([minField.value as number, maxField.value as number]);
    minValue.current = minField.value as number;
    maxValue.current = maxField.value as number;
  }, [minField.value, maxField.value]);

  const formatter = useMemo(
    () =>
      filter.is_formatted
        ? (value: string | number | undefined) =>
            abbreviateNumber({
              value,
              maxValue: field.value.max,
              dot: lastDot,
              roundHalfEven: true,
            })
        : undefined,
    [field.value.max, filter.is_formatted, lastDot]
  );

  const parser = useMemo(
    () => (value: string | number | undefined, inputType?: 'min' | 'max') =>
      parseNumberFromAbbreviation(value as string, field.value.max, field.value.min, inputType),
    [field.value.max, field.value.min]
  );

  /* This function defines the dots/marks in the slider. */
  const marks: SliderMarks | undefined = useMemo(() => {
    if (!field.value.disabled && filter.slider_dots && filter.slider_dots.length) {
      const dotsPosition: Dictionary<number> = {};

      filter.slider_dots.forEach(mark => {
        if (mark >= field.value.min && mark <= field.value.max) {
          dotsPosition[mark] = mark;
        }
      });

      return dotsPosition;
    }

    return undefined;
  }, [filter.slider_dots, field.value.disabled, field.value.min, field.value.max]);

  const onChangeInputMin = (value: string | number | undefined) => {
    const isChanged = value !== minField.value;

    minFieldHelpers.setValue(value as number);
    onAfterChange([value, maxField.value], isChanged);
  };

  const onChangeInputMax = (value: string | number | undefined) => {
    const isChanged = value !== maxField.value;

    maxFieldHelpers.setValue(value as number);
    onAfterChange([minField.value, value], isChanged, value);
  };

  const onAfterChange = (value: any, isChanged: boolean = false, inputValue?: string | number) => {
    const newMaxValue =
      maxField.value! === field.value.max && maxField.value! >= lastDot! ? lastDot : maxField.value;
    let isValueChanged = false;

    const isChangedBoth = isChanged && maxField.value !== value[1];

    // if new slider value is different from current input values
    if (value[0] !== minField.value || value[1] !== newMaxValue) {
      isValueChanged = true;
    }

    setCurrentSlideValue(value);
    minValue.current = value[0];
    maxValue.current = value[1];

    if (lastDot && value[1] === lastDot && inputValue !== lastDot) {
      minFieldHelpers.setValue(value[0]);
      maxFieldHelpers.setValue(field.value.max);
      // check and swap values to corresponding min/max values
    } else if (value[0] > value[1] && isChangedBoth) {
      minFieldHelpers.setValue(value[1]);
      maxFieldHelpers.setValue(value[0]);
    } else {
      minFieldHelpers.setValue(Math.round(value[0]));
      maxFieldHelpers.setValue(value[1]);
    }

    // only call the filter onChange if the value was really changed
    if (isValueChanged || isChanged) {
      // Delay onChange for next tick to allow form to update it's values.
      setTimeout(() => {
        filtersContext.onManualFilterChange && filtersContext.onManualFilterChange(filter.column);
      }, 0);

      formatter && formatter(value);
    }
  };

  // this function gets the current value from the input and display in the slider tooltip
  const generateTooltip = (value: number | undefined) => {
    if (!filter.is_formatted) {
      return value;
    }

    if (value === lastDot) {
      return `${numeral(value).format('0a')}+`;
    }

    return numeral(value).format('0a');
  };

  useEffect(() => {
    const check = field.value.check;

    if (
      check &&
      check.length > 1 &&
      (check[1].checked || check[3]?.checked) &&
      filter.column !== 'ebitda_numeric'
    ) {
      fieldHelpers.setValue({
        ...field.value,
        disabled: true,
      });
    }

    minValue.current = field.value.range[0];
    maxValue.current = field.value.range[1];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckbox = (e: CheckboxChangeEvent) => {
    const fieldCheckboxes = cloneDeep(checkRef.current);

    if (
      fieldCheckboxes &&
      fieldCheckboxes.length > 1 &&
      field.value.check &&
      filter.column !== 'ebitda_numeric'
    ) {
      // issue with antd's type
      // @ts-ignore
      const checkIndex = Number(e.nativeEvent.target.id);
      const disabled = e.target.checked && (checkIndex === 1 || checkIndex === 3);
      const newCheck = [...fieldCheckboxes];
      const isOPTXScoreField = newCheck.length > 2;

      if (e.target.checked) {
        if (checkIndex === 0) {
          newCheck[0].checked = true;
          newCheck[1].checked = false;

          if (isOPTXScoreField) {
            newCheck[3].checked = false;
          }
        } else if (checkIndex === 1) {
          newCheck[0].checked = false;
          newCheck[1].checked = true;

          if (isOPTXScoreField) {
            newCheck[2].checked = false;
            newCheck[3].checked = false;
          }
        } else if (checkIndex === 2) {
          newCheck[1].checked = false;

          if (isOPTXScoreField) {
            newCheck[2].checked = true;
            newCheck[3].checked = false;
          }
        } else if (checkIndex === 3) {
          newCheck[0].checked = false;
          newCheck[1].checked = false;

          if (isOPTXScoreField) {
            newCheck[2].checked = false;
            newCheck[3].checked = true;
          }
        }
      } else if (!e.target.checked) {
        newCheck[checkIndex].checked = false;
      }

      checkRef.current = newCheck;

      fieldHelpers.setValue({
        ...field.value,
        disabled,
        range: disabled
          ? [field.value.min, field.value.max]
          : [minValue.current as number, maxValue.current as number],
        check: newCheck,
      });

      arrayHelpers.form.values[arrayHelpers.name].forEach((item: any, index: number) => {
        arrayHelpers.replace(index, {
          ...field.value,
          disabled,
          // eslint-disable-next-line no-nested-ternary
          range: disabled
            ? [field.value.min, field.value.max]
            : boxIndex !== index
            ? item?.range
            : [minValue.current as number, maxValue.current as number],
          check: newCheck,
        });
      });
    } else {
      if (fieldCheckboxes && filter.column !== 'ebitda_numeric') {
        const newCheck = [...fieldCheckboxes];

        newCheck[0].checked = !!e.target.checked;

        arrayHelpers.form.values[arrayHelpers.name].forEach((item: any, index: number) => {
          arrayHelpers.replace(index, {
            ...field.value,
            range:
              boxIndex !== index
                ? item.range
                : [minValue.current as number, maxValue.current as number],
            check: newCheck,
          });
        });
      }
    }

    setTimeout(() => {
      filtersContext.onManualFilterChange && filtersContext.onManualFilterChange(filter.column);
    }, 0);
  };

  // used to keep refs updated so checkboxes onChange doesn't overwrite other filters
  // with old values when using multiple filters
  useEffect(() => {
    const filters = arrayHelpers.form.values[arrayHelpers.name];
    const currFilter = arrayHelpers.form.values[arrayHelpers.name][boxIndex];

    rangesRef.current = filters.map((filter: any) => filter.range);

    checkRef.current = currFilter.check;

    minValue.current = currFilter.range[0];
    maxValue.current = currFilter.range[1];

    setCurrentSlideValue(currFilter.range);
  }, [boxIndex, arrayHelpers]);

  const handleEBITDAFilterCheckbox = (e: CheckboxChangeEvent) => {
    const fieldCheckboxes = checkRef.current;

    if (fieldCheckboxes) {
      // issue with antd's type
      // @ts-ignore
      const checkboxIndex = Number(e.nativeEvent.target.id);
      const newCheck = [...fieldCheckboxes];

      // update check array
      newCheck[checkboxIndex] = { ...newCheck[checkboxIndex], checked: e.target.checked };

      // update checkboxes ref
      checkRef.current = newCheck;

      // update formik checkbox
      fieldHelpers.setValue({
        ...field.value,
        range: [minValue.current as number, maxValue.current as number],
        check: newCheck,
      });

      // update arrayHelpers
      arrayHelpers.form.values[arrayHelpers.name].forEach((item: any, index: number) => {
        arrayHelpers.replace(index, {
          ...field.value,
          range:
            boxIndex !== index
              ? rangesRef.current?.[index] || item?.range
              : [minValue.current as number, maxValue.current as number],
          check: newCheck,
        });
      });
    }

    setTimeout(() => {
      filtersContext.onManualFilterChange && filtersContext.onManualFilterChange(filter.column);
    }, 0);
  };

  const handleSlideChange = (value: [number, number]) => {
    setCurrentSlideValue(value);
    minValue.current = value[0];
    maxValue.current = value[1];
  };

  return (
    <div>
      {!field.value.disabled && (
        <Histogram
          filterKey={filter.column}
          min={minField.value as number}
          max={maxField.value as number}
        />
      )}
      <Row>
        <Col span={24}>
          <Slider
            range
            min={Number(filter.placeholders.min)}
            max={maxFilterValue}
            marks={marks}
            tipFormatter={generateTooltip}
            onChange={handleSlideChange}
            value={currentSlideValue}
            onAfterChange={onAfterChange}
            disabled={field.value.disabled}
            // step has to be multiple of (max - min)
            step={filter.slider_step || 1}
          />
        </Col>
        <Col span={12} className="filter-form__slider-input">
          <RangeInputNumber
            formatter={formatter}
            parser={value => parser(value, 'min') as number}
            value={minField.value}
            onChange={onChangeInputMin}
            disabled={field.value.disabled}
          />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }} className="filter-form__slider-input">
          <RangeInputNumber
            formatter={formatter}
            parser={value => parser(value, 'max') as number}
            value={maxField.value}
            onChange={onChangeInputMax}
            disabled={field.value.disabled}
          />
        </Col>
        {field.value.check &&
          field.value.check.map((item, index) => (
            <Col key={index} span={24} style={{ marginTop: 8 }}>
              <Checkbox
                name={`${filter.column}[${boxIndex}].check[${index}].checked`}
                onChange={
                  filter.column !== 'ebitda_numeric' ? handleCheckbox : handleEBITDAFilterCheckbox
                }
                disabled={item.disabled}
                className="no-wrap-checkbox"
                id={`${index}`}
                fast
              >
                <Typography.Text title={item.label}>{item.label}</Typography.Text>
              </Checkbox>
            </Col>
          ))}
      </Row>
    </div>
  );
};

export default FilterRangeContent;
