import { Typography } from 'antd';
import styled from 'styled-components';
import { Title } from '@optx/shared/view/atoms/Typography';
import AnalystsMultiSelect from './AnalystsMultiSelect';

export const PageTitle = styled(Title)`
  margin-top: 10px;
`;

export const SecondaryTitle = styled(Typography.Paragraph)`
  /* Gray */
  color: #90a0b7;
`;

export const TypographyTitle = styled(Typography.Title)`
  &.ant-typography {
    /* Dark BLue */
    color: #334d6e;
    margin: 0;
  }
`;

export const ByAnalystsOrTeamMultiSelect = styled(AnalystsMultiSelect)`
  max-width: 350px;

  .multiselect__value-container {
    flex-wrap: nowrap;
  }
`;
