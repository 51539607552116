import React from 'react';
// models
import Company from '@optx/models/Company';
// utils
import { getIsPsgCompany, getTags } from '@optx/utils/company';
// components
import { TagOption } from '@optx/models/tags';
import IsPortfolioTag from './IsPortfolioTag';
import IsSoftwareTag from './IsSoftwareTag';
import DiversityTags from './DiversityTags';
import CompanyTagValue from './CompanyTagValue';
import AddTags from './AddTags';
import IsPSGCompanyTag from './IsPSGCompanyTag';
import IsAIMlTag from './IsAIMl';
// styles
import { Styled } from './CompanyTags.styles';

interface CompanyTagsProps {
  company: Company;
  newTab?: boolean;
  isAdd?: boolean;
  closable?: boolean;
  isCollapsed?: boolean;
}

const CompanyTags: React.FC<CompanyTagsProps> = ({
  company,
  newTab,
  isAdd = false,
  closable,
  isCollapsed,
}) => {
  const companyProfileTags = getTags(company.specialties);

  const companyTag = company.cs_tags
    ? company.cs_tags
        .split('|')
        .slice(1)
        .map(item => ({
          label: item,
          value: item,
          closable: false,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    : [];

  const allTags = closable ? [...companyProfileTags, ...companyTag] : companyTag;

  const uniqueTags = Array.from(new Set(allTags.map(item => item.label))).map(item => ({
    label: item,
    value: allTags.find(a => a.label === item)?.value,
    closable: allTags.find(a => a.label === item)?.closable,
  }));

  return (
    <Styled.TagsWrapper>
      <IsPSGCompanyTag newTab={newTab} isCompany={getIsPsgCompany(company)} />

      <IsSoftwareTag newTab={newTab} isSoftware={company.is_software} />

      <IsAIMlTag newTab={newTab} isAiMl={company.is_ai_ml} />

      <IsPortfolioTag newTab={newTab} isPortfolio={company.is_platform}>
        Platform Company
      </IsPortfolioTag>

      <DiversityTags newTab={newTab} diversityTags={company.diversity} />

      {uniqueTags &&
        uniqueTags.map((item: TagOption) => {
          return <CompanyTagValue key={item.value} item={item} companyId={company.company_id} />;
        })}

      {isAdd && (
        <AddTags
          value={uniqueTags.map(item => ({
            label: item.label,
            value: item.label,
            disabled: true,
          }))}
          companyId={company.company_id as number}
          isCollapsed={isCollapsed}
        />
      )}
    </Styled.TagsWrapper>
  );
};

export default React.memo(CompanyTags);
