import { useInjectSaga } from 'redux-injectors';
// redux
import { saga } from '../../../redux/geo-range';

const useGeoRange = () => {
  useInjectSaga({
    key: 'geoRangeSaga',
    saga,
  });
};

export default useGeoRange;
