import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useField } from 'formik';
import { Checkbox } from 'formik-antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
// models
import { CompanyProfile } from '@models/Company';
// redux
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
// components
import { Context } from '@optx/screens/App/EquityTouchBulk/components/common/Context';

interface BulkEquityTouchAddonCheckboxProps {
  name: string;
  label: string;
  disabled?: boolean;
}

const BulkEquityTouchAddonCheckbox: React.FC<BulkEquityTouchAddonCheckboxProps> = ({
  name,
  label,
  disabled,
}) => {
  const { step, addon: addonContext } = useContext(Context);
  const companyData = useSelector(bulkActionsSelectors.equityTouch.getCompanyData);
  const companiesById = useSelector(bulkActionsSelectors.equityTouch.getCompanies);
  const bulkValues = useSelector(bulkActionsSelectors.equityTouch.getBulkValues);
  const temporaryData = useSelector(bulkActionsSelectors.equityTouch.getTemporaryData);
  const [checked, setChecked] = useState<boolean>(false);
  const [, , helpers] = useField(name);

  useEffect(() => {
    if (Object.keys(companyData).length) {
      const addon = !!companiesById?.[(companyData as CompanyProfile)?.company_id]?.['Add-On'];

      setChecked(addon);
      helpers.setValue(addon);

      if (temporaryData?.step2) {
        const tempDataAddonChecked = !!(
          temporaryData.step2[(companyData as CompanyProfile)?.company_id] as any
        )?.['Add-On'] as boolean;

        if (tempDataAddonChecked !== checked) {
          setChecked(addon);
          helpers.setValue(addon);
        }
      }
    }

    if (step === 0) {
      const bulkAddon = !!(bulkValues['Add-On'] || addonContext || temporaryData.step1?.['Add-On']);

      setChecked(bulkAddon);
      helpers.setValue(bulkAddon);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companiesById, companyData]);

  const handleChange = (event: CheckboxChangeEvent) => {
    const value = event.target.checked;

    setChecked(value);
    helpers.setValue(value);
  };

  return (
    <Checkbox onChange={handleChange} checked={checked} name={name} disabled={disabled}>
      {label}
    </Checkbox>
  );
};

export default BulkEquityTouchAddonCheckbox;
