import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { usePrevious } from '@umijs/hooks';
import { actions, selectors } from '../state';

/**
 * This hook saves the scroll position of a window or that of a scrolled table
 * and sets the scroll position when navigating from a different page or using
 * pagination.
 * This hook applies to normal grid.
 */
export const useScrollPosition = (
  pageKey: string,
  elementToScroll?: React.RefObject<HTMLDivElement>,
  pageNumber?: number
) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const scrollPosition = useSelector(state => {
    return selectors.getScrollPosition(state, pageKey);
  });
  const [currLocation, setCurrLocation] = useState(location.pathname);
  const prevLocation = usePrevious(currLocation);
  const [currPageNumber, setCurrPageNumber] = useState(pageNumber);
  const prevPageNumber = usePrevious(currPageNumber);

  useEffect(() => {
    const element = elementToScroll?.current?.children[0];

    setCurrLocation(location.pathname);
    setCurrPageNumber(pageNumber);

    // only fire the scrollTop if the user came from a different page
    if (prevLocation !== currLocation || prevPageNumber !== currPageNumber) {
      if (element) {
        if (prevPageNumber !== currPageNumber) {
          element.scrollTop = 0;
        } else {
          element.scrollTop = scrollPosition;
        }
      }

      window.scrollTo(0, scrollPosition);
    }

    return () => {
      // determine if there is an element and save the scroll position when component unmounts
      if (element) {
        const scrolled = element.scrollTop;

        dispatch(actions.saveScrollPosition(pageKey, { scrollPosition: scrolled }));
      } else {
        const scrolled = window.scrollY;
        dispatch(actions.saveScrollPosition(pageKey, { scrollPosition: scrolled }));
      }
    };
  }, [
    currLocation,
    dispatch,
    elementToScroll,
    location.pathname,
    pageKey,
    prevLocation,
    scrollPosition,
    pageNumber,
    currPageNumber,
    prevPageNumber,
  ]);
};
