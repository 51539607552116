// models
import { SuccessErrorCallback } from '@optx/models/callback';
import { UserInformation } from '@models/user';
import { UpdateUserSettingsPayload } from '@models/api/user';
// redux
import { createAction } from '@reduxjs/toolkit';
import * as types from './actionTypes';

export const updateUserSettings = createAction(
  types.UPDATE_USER_SETTINGS,
  (payload: Partial<UpdateUserSettingsPayload>, callback?: SuccessErrorCallback) => ({
    payload,
    meta: callback,
  })
);

export const updateUserSettingsSuccess = createAction<Partial<UpdateUserSettingsPayload>>(
  types.UPDATE_USER_SETTINGS_SUCCESS
);

export const updateUserSettingsFail = createAction<string>(types.UPDATE_USER_SETTINGS_FAIL);

export const fetchUserInformation = createAction(types.FETCH_USER_INFORMATION);

export const fetchUserInformationSuccess = createAction<UserInformation>(
  types.FETCH_USER_INFORMATION_SUCCESS
);

export const fetchUserInformationFail = createAction<string>(types.FETCH_USER_INFORMATION_FAIL);
