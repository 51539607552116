export const FETCH_SALESLOFT_AUTH_URL = 'FETCH_SALESLOFT_AUTH_URL';
export const FETCH_SALESLOFT_AUTH_URL_SUCCESS = 'FETCH_SALESLOFT_AUTH_URL_SUCCESS';

export const FETCH_SALESLOFT_CADENCES = 'FETCH_SALESLOFT_CADENCES';
export const FETCH_SALESLOFT_CADENCES_SUCCESS = 'FETCH_SALESLOFT_CADENCES_SUCCESS';

export const POST_SALESLOFT_DATA = 'POST_SALESLOFT_DATA';
export const POST_SALESLOFT_DATA_SUCCESS = 'POST_SALESLOFT_DATA_SUCCESS';
export const POST_SALESLOFT_DATA_FAIL = 'POST_SALESLOFT_DATA_FAIL';

export const SALESLOFT_PRELOAD_CONTACTS = 'SALESLOFT_PRELOAD_CONTACTS';

export const SALESLOFT_SELECT_CONTACT = 'SALESLOFT_SELECT_CONTACT';
export const SALESLOFT_SELECT_CADENCE = 'SALESLOFT_SELECT_CADENCE';

export const SALESLOFT_RESET = 'SALESLOFT_RESET';
