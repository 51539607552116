import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isEqual } from 'lodash';
// models
import { EquityTouchContactForm, EquityTouchDatabaseMatch } from '@models/equityTouch';
import { CompanyUserContact } from '@models/api/contacts';
// constants
import { EQT_CONTACT_FORM_INITIAL_STATE } from '@constants/equitytouch';
import { ISO_DATE_FORMAT } from '@optx/constants/format/date';
// utils
import { mapPhoneNumbers } from '@utils/contact';
// redux
import { selectors as companyIndividualEditSelectors } from '@components/feature/company-individual-edit/state';
import { actions as bulkSalesloftActions } from '@optx/features/bulk-actions/salesloft';
// components
import { ContactForm } from '@optx/features/contact-form';

interface SelectAddContactProps {
  visible: boolean;
  contactData: Partial<CompanyUserContact> | undefined;
  onCancel: () => void;
  maxEmails?: number;
  shouldResolve?: boolean;
  isExtension?: boolean;
}

export const SelectAddContact: React.FC<SelectAddContactProps> = ({
  visible,
  contactData,
  onCancel,
  maxEmails,
  shouldResolve,
  isExtension = false,
}) => {
  const dispatch = useDispatch();

  const individualEditOptions = useSelector(
    companyIndividualEditSelectors.companyIndividualEdit.getAllOptions
  );
  const initialValues: EquityTouchContactForm = EQT_CONTACT_FORM_INITIAL_STATE;

  const handleSubmit = (values: Partial<CompanyUserContact>) => {
    const formValues = { ...values };
    const emailList = formValues.emails;

    const newContact = {
      ...formValues,
      email: emailList!.reduce((accumulator, value) => {
        if (value.email && value.email !== '') {
          let comma = accumulator === '' ? '' : ',';

          return accumulator + comma + value.email;
        }

        return accumulator;
      }, ''),
      person_id: contactData?.person_id as number,
      company_name: contactData?.company_name,
      company_url: contactData?.company_url,
      company_id: contactData?.company_id,
      tenure_start_date: formValues.tenure_start_date
        ? moment(formValues.tenure_start_date).format(ISO_DATE_FORMAT)
        : null,
      tenure_end_date: formValues.tenure_end_date
        ? moment(formValues.tenure_end_date).format(ISO_DATE_FORMAT)
        : null,
      is_edited: true,
      primary_email: contactData?.email,
      phones: mapPhoneNumbers(
        formValues,
        individualEditOptions?.person_phone_type.received_values as EquityTouchDatabaseMatch[]
      ),
      additional_phones: mapPhoneNumbers(
        formValues,
        individualEditOptions?.person_phone_type.received_values as EquityTouchDatabaseMatch[]
      ),
    };

    if (!isEqual(initialValues, values)) {
      dispatch(bulkSalesloftActions.addContact({ contact: newContact, shouldResolve }));
    }

    onCancel();
  };

  return (
    <ContactForm
      type="add"
      visible={visible}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      maxEmails={maxEmails}
      isExtension={isExtension}
    />
  );
};
