import React, { useCallback } from 'react';
import { Empty } from 'antd';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  LegendValueFormatter,
  YAxisProps,
  XAxisProps,
  LegendPayload,
} from 'recharts';
import { ChartResponse } from '@optx/models/feature/equity-touch-integration/charts';
import { LegendText } from '@optx/components/common/charts/LineChart/LegendFormatter/styles';
import numeral from 'numeral';
import ChartTooltip from './ChartTooltip';
import * as config from '../config';
import '../style.scss';

interface StackedChartType {
  data: ChartResponse[];
  barKeys: {
    key: (item: ChartResponse) => number;
    color: string;
  }[];
  tooltipConfig: {
    label: string;
    secondLabel: (item: ChartResponse) => string;
    dataKeys: {
      label: string;
      isBlue: boolean;
      value: (item: ChartResponse) => number | string;
    }[];
  };
  xAxisProps: XAxisProps;
  yAxisProps: YAxisProps;
  periodLabel: (value: LegendPayload | undefined) => string;
  barProps?: BarChart;
}

const StackedChart: React.FC<StackedChartType> = ({
  data,
  barKeys,
  tooltipConfig,
  yAxisProps,
  xAxisProps,
  periodLabel,
  barProps,
}) => {
  let emptyCount: boolean = false;

  const legendFormatter: LegendValueFormatter = useCallback((value, item) => {
    const text = periodLabel(item);

    return <LegendText>{text}</LegendText>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data.length ? (
    <ResponsiveContainer height={400}>
      <BarChart
        className="bar-charts"
        width={500}
        height={260}
        data={data}
        barSize={8}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        {...barProps}
      >
        <XAxis
          type="category"
          interval={0}
          tickLine={false}
          stroke="#C2CFE0"
          tickSize={15}
          tick={props => {
            if (props.payload.value === '') {
              emptyCount = true;
            }

            return (
              <text
                fontSize={10}
                fontWeight={600}
                x={props.index === 0 ? props.x - 20 : props.x}
                dx={emptyCount && props.visibleTicksCount - 1 !== props.index ? -40 : 0}
                y={props.y}
                dy={7}
                fill="#C2CFE0"
              >
                {props.payload.value}
              </text>
            );
          }}
          {...xAxisProps}
        />
        <YAxis
          type="number"
          tickFormatter={value => numeral(value).format('0a')}
          {...config.yAxisProps}
          {...yAxisProps}
        />
        <Tooltip content={<ChartTooltip config={tooltipConfig} />} {...config.tooltipProps} />
        {data && data.length && <Legend formatter={legendFormatter} {...config.legendProps} />}
        {barKeys.map((item, index) => (
          <Bar
            key={index}
            {...config.commonBarProps}
            dataKey={item.key}
            stackId={0}
            fill={item.color}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
};

export default StackedChart;
