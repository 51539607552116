import React from 'react';
import { Card, Col, Typography } from 'antd';
import { CardProps } from 'antd/lib/card';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const { Text } = Typography;

const CompanyCard = styled<React.FC<CardProps & { $active?: boolean }>>(Card)`
  margin-bottom: 20px;
  border-top: 3px solid transparent;
  max-height: 164px;

  .ant-card-body {
    padding: 0;
  }

  &:hover {
    border-top: 3px solid #0092ff;
  }

  .tooltipText {
    width: 240px;
    font-size: 14px;
    background-color: white;
    color: grey;
    text-align: left;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    visibility: hidden;
    z-index: 1;
    box-shadow: inset 0px 0px 0px #d9d9d9, -1px 0px 6px #d9d9d9;
  }
  ${props =>
    props.$active &&
    css`
      border-top: 3px solid #0092ff;
      box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),
        0 5px 12px 4px rgb(0 0 0 / 9%);
    `}
`;

const LogoColumn = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 79px;
  padding-right: 16px;
`;

const EmployeeColumn = styled(Col)`
  display: flex;
  align-items: flex-end;
  text-align: right;
  text-overflow: hidden;
  flex-direction: column;
  min-width: fit-content;
  margin-left: 20px;
  position: relative;
`;

const EmployeeChart = styled.span`
  position: absolute;
  bottom: -22px;
  right: 0;
`;

const ImageWrapper = styled.div`
  box-shadow: 0px 2px 8px 0px #00000026;
  border-radius: 10px;
  padding: 2px;
  height: 50px;
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const IsraelFlagImg = styled.img`
  display: inline-block;
  max-width: 32px;
  margin-left: 5px;
  position: relative;
  top: 2px;
`;

const ScoreTrendingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;

  .score-trending__growth,
  .trending-value {
    gap: 1px;
  }

  .score-trending__label {
    font-size: 10px;
  }

  .score-trending__growth-score {
    font-size: 18px;
  }
`;

const CompanyNameWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const CompanyName = styled(Text)`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  max-width: 100%;
  color: #334d6e;
  line-height: 24px;
`;

const CompanyProfileLink = styled(Link)`
  margin-left: 5px;
  font-size: 21px;
  line-height: 18px;
  color: #334d6e;
`;

const CompanyLocation = styled(Text)`
  line-height: 22px;
  color: #90a0b7;

  svg {
    vertical-align: baseline;
    color: #90a0b7;
    width: 16px;
  }
`;

const CompanyDetailsWrapper = styled.div`
  min-height: 36px;
`;

const CompanyDetails = styled(Text)`
  display: block;
  line-height: 12px;
  margin-top: 2px;
`;

const YearLabel = styled(Text)`
  color: #334d6e;
`;

const YearValue = styled(Text)`
  color: #90a0b7;
`;

const WebsiteIcon = styled.a`
  font-size: 23px;
  margin-left: 5px;
  line-height: 23px;
  align-self: stretch;
  color: #334d6e;

  &.not-active {
    color: red;
  }
`;

const Description = styled(Text)`
  font-size: 14px;
  line-height: 15px;
  margin: 8px 0;
  position: relative;
  color: #90a0b7;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 31.5px;
`;

const Label = styled.span`
  display: block;
  font-size: 12px;
  font-weight: bold;
`;
const Value = styled.span`
  font-size: 16px;
  color: rgb(175, 189, 209);
  font-weight: bold;
`;

const DateValue = styled(Value)`
  color: #fa8c16;
  text-transform: uppercase;
`;

const CenteredColumn = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  min-width: fit-content;
`;

const Styled = {
  CompanyCard,
  LogoColumn,
  ImageWrapper,
  IsraelFlagImg,
  CompanyNameWrapper,
  ScoreTrendingWrapper,
  CompanyName,
  CompanyProfileLink,
  CompanyLocation,
  CompanyDetailsWrapper,
  CompanyDetails,
  YearLabel,
  YearValue,
  WebsiteIcon,
  Description,
  Label,
  Value,
  DateValue,
  CenteredColumn,
  EmployeeColumn,
  EmployeeChart,
};

export default Styled;
