import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actions as searchActions } from '@features/grid/search';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';

const useCompanySavedSearches = (): {
  handleSearchResults: (id: number | string, title: string, listType: string) => void;
} => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { advancedSearchPathname } = useIsAnalyst();

  const handleSearchResults = (id: number | string, title: string, listType: string) => {
    history.push(advancedSearchPathname);

    dispatch(
      searchActions.loadSavedSearch({
        gridKey: 'advancedSearch',
        data: { searchId: id, title, listType },
      })
    );
  };

  return { handleSearchResults };
};

export default useCompanySavedSearches;
