export const FETCH_PROFILE_NOTES = 'FETCH_PROFILE_NOTES';
export const FETCH_PROFILE_NOTES_SUCCESS = 'FETCH_PROFILE_NOTES_SUCCESS';
export const FETCH_PROFILE_NOTES_FAIL = 'FETCH_PROFILE_NOTES_FAIL';

export const PIN_NOTE = 'PIN_NOTE';

export const EDIT_PROFILE_NOTE = 'EDIT_PROFILE_NOTE';
export const EDIT_PROFILE_NOTE_SUCCESS = 'EDIT_PROFILE_NOTE_SUCCESS';

export const DELETE_PROFILE_NOTE = 'DELETE_PROFILE_NOTE';
export const DELETE_PROFILE_NOTE_FAIL = 'DELETE_PROFILE_NOTE_FAIL';
