import React, { useState } from 'react';
// components
import { MainCardTitle, Cards } from './card';
import SelectDate from './SelectDate';
// style
import Styled from './card/MainCard.style';

const ShowcasePanel = () => {
  const [selectedCard, setSelectedCard] = useState<string>('');

  return (
    <Styled.StyledCard title={<MainCardTitle />} $size="small" $transparent>
      <div className="d-flex flex-column">
        <SelectDate selectedCard={selectedCard} setSelectedCard={setSelectedCard} />
        <Cards selectedCard={selectedCard} setSelectedCard={setSelectedCard} />
      </div>
    </Styled.StyledCard>
  );
};

export default ShowcasePanel;
