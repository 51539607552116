import { createAction } from '@reduxjs/toolkit';
import { SocialMediaChartData } from '@optx/models/charts/data';
import * as types from './types';

// fetch company touches actions
export const fetchTwitterData = createAction<number>(types.fetchTwitterData.REQUEST);
export const fetchTwitterDataSuccess = createAction<SocialMediaChartData>(
  types.fetchTwitterData.SUCCESS
);

export const fetchTwitterDataFail = createAction<string>(types.fetchTwitterData.FAILURE);
