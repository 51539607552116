import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// redux
import {
  SourcingOutreachTable,
  actions,
  useInject,
} from '@optx/features/sourcing-outreach-summary';
import {
  selectors as fullscreenSelectors,
  actions as fullScreenActions,
} from '@redux/ui/settings/fullscreen';
// constants
import { HTML } from '@constants/fullScreen';
//hooks
import { useReduxFullScreen } from '@optx/common/hooks/fullscreen';
import { useInjectCompanySavedSearches } from '@optx/common/hooks/inject';
import { useFetchSavedSearches } from '@optx/common/hooks/init';
// components
import { PrivateComponent } from '@optx/components/common/PrivateComponent';
import {
  FilterSingleSelectDateFilter,
  FiltersModal,
  SearchHeader,
  TotalCards,
} from '@features/sourcing-outreach-summary/components';
import PageLoader from './PageLoader';
import Styled from './SourcingOutreachSummary.styled';
import ReportBreadcrumb from '../AllReports/components/ReportBreadcrumb';
import GridFullScreen from '@optx/shared/view/molecules/FullScreen/GridFullScreen';
import { selectors as userInformationSelectors } from '@redux/user/information';

const SourcingOutreachSummary = () => {
  useInject();
  useInjectCompanySavedSearches();
  useFetchSavedSearches();

  const dispatch = useDispatch();
  const isUserInformationLoaded = useSelector(userInformationSelectors.loaded);
  const fullscreen = useSelector(fullscreenSelectors.isFullscreen);
  const toggle = useCallback(() => dispatch(fullScreenActions.toggleFullScreen()), [dispatch]);

  useReduxFullScreen({ dom: HTML, fullscreen, toggle });

  useEffect(() => {
    if (isUserInformationLoaded) {
      dispatch(actions.init());
    }
  }, [dispatch, isUserInformationLoaded]);

  return (
    <PrivateComponent accessFor="sourcing_outreach">
      <PageLoader />
      <SearchHeader />
      <Styled.Wrapper className="sourcing-outreach-summary">
        <Styled.MainContent className="main-content">
          <Styled.PageFiltersWrapper>
            <ReportBreadcrumb
              onClick={() => fullscreen && dispatch(fullScreenActions.toggleFullScreen())}
              reportPage="Sourcing Outreach Summary"
            />
            <Styled.PageTitleContent>
              <Styled.PageTitle>Sourcing Outreach Summary</Styled.PageTitle>
              <FilterSingleSelectDateFilter />
            </Styled.PageTitleContent>
            <Styled.QuickFiltersContent>
              <Styled.QuickFilterWrapper>
                <Styled.MultiSelectFilter filterKey="analyst_id" allowSelectAll />
              </Styled.QuickFilterWrapper>
              <Styled.QuickFilterWrapper>
                <Styled.MultiSelectFilter filterKey="touch_type" allowSelectAll />
              </Styled.QuickFilterWrapper>
              <Styled.QuickFilterWrapper>
                <Styled.MultiSelectFilter filterKey="so_stage" allowSelectAll />
              </Styled.QuickFilterWrapper>
              <Styled.QuickFilterWrapper>
                <Styled.SingleSelectFilter filterKey="stage_change" />
              </Styled.QuickFilterWrapper>
            </Styled.QuickFiltersContent>
            <Styled.CardsWrapper>
              <TotalCards />
            </Styled.CardsWrapper>
          </Styled.PageFiltersWrapper>
          <Styled.TableContentWrapper>
            <Styled.TableTitleContainer>
              <Styled.TableTitle>All Analysts &amp; Associate</Styled.TableTitle>
              {!fullscreen && <GridFullScreen />}
            </Styled.TableTitleContainer>
            <SourcingOutreachTable />
          </Styled.TableContentWrapper>
        </Styled.MainContent>
      </Styled.Wrapper>
      <FiltersModal />
    </PrivateComponent>
  );
};

export default SourcingOutreachSummary;
