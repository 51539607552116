import React from 'react';
import { Row, Col, Typography } from 'antd';
// models
import { PluginPrimaryContact } from '@optx/models/Company';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { getDateColor } from '@utils/text';

const { Text } = Typography;

interface PrimaryContactListProps {
  primaryContactDescription: PluginPrimaryContact | null;
}

const PrimaryContactList: React.FC<PrimaryContactListProps> = ({ primaryContactDescription }) => {
  return (
    <Row justify="space-between" className="primary-contact-list">
      <Col span={9}>
        <Text type="secondary" strong>
          # of Touches
        </Text>
      </Col>
      <Col span={15}>
        <Text strong>{primaryContactDescription?.number_of_touches || DEFAULT_EMPTY_VALUE}</Text>
      </Col>
      <Col span={9}>
        <Text type="secondary" strong>
          Last Touch
        </Text>
      </Col>
      <Col span={15}>
        <Text strong style={{ color: `${getDateColor(primaryContactDescription?.last_touch)}` }}>
          {primaryContactDescription?.last_touch || DEFAULT_EMPTY_VALUE}
        </Text>
      </Col>
      <Col span={9}>
        <Text type="secondary" strong>
          Next Touch
        </Text>
      </Col>
      <Col span={15}>
        <Text strong>{primaryContactDescription?.next_touch || DEFAULT_EMPTY_VALUE}</Text>
      </Col>
      <Col span={9}>
        <Text type="secondary" strong>
          SL Cadence
        </Text>
      </Col>
      <Col span={15}>
        <Text strong>{primaryContactDescription?.cadence_name || DEFAULT_EMPTY_VALUE}</Text>
      </Col>
    </Row>
  );
};

export default PrimaryContactList;
