import createCachedSelector from 're-reselect';
import { defaultPageScrollHistory } from './constants';
import { featureName } from '../featureName';

export const selectScrollState = (state: any) => state[featureName];

export const getScrollPosition = createCachedSelector(
  selectScrollState,
  (state: any, pageKey: string) => pageKey,
  (state, pageKey) => {
    const scrollHistory = state && state[pageKey];

    if (scrollHistory) {
      return scrollHistory.scrollPosition;
    }

    return defaultPageScrollHistory.scrollPosition;
  }
)((state, pageKey) => pageKey);

export const getTableRow = createCachedSelector(
  selectScrollState,
  (state: any, pageKey: string) => pageKey,
  (state, pageKey) => {
    if (state && pageKey in state) {
      const scrollHistory = state[pageKey];

      if (scrollHistory) {
        return scrollHistory.tableRow;
      }

      return defaultPageScrollHistory.tableRow;
    }

    return '';
  }
)((state, pageKey) => pageKey);
