import {
  ChartData,
  ChartBars,
  SocialMediaChartData,
  GlassDoorChartData,
} from '@optx/models/charts/data';

export const CHART_KEYS = ['3M', '6M', '1Y', '2Y'];

export const INITIAL_STATE_CHART: ChartData | SocialMediaChartData = {
  '3M': { data: [] },
  '6M': { data: [] },
  '1Y': { data: [] },
  '2Y': { data: [] },
};

export const INITIAL_STATE_BAR_CHART: ChartBars = {
  '3M': { data: [] },
  '6M': { data: [] },
  '1Y': { data: [] },
  '2Y': { data: [] },
  ALL: { data: [] },
};

export const INITIAL_GlASS_DOOR_CHART: GlassDoorChartData = {
  '3M': {
    date: '',
    overall_stars: 0,
    number_of_reviews: 0,
    percent_recommend_friend: 0,
    percent_recommend_ceo: 0,
    ceo_ratings: 0,
    positive_outlook_percent: 0,
  },
  '6M': {
    date: '',
    overall_stars: 0,
    number_of_reviews: 0,
    percent_recommend_friend: 0,
    percent_recommend_ceo: 0,
    ceo_ratings: 0,
    positive_outlook_percent: 0,
  },
  '1Y': {
    date: '',
    overall_stars: 0,
    number_of_reviews: 0,
    percent_recommend_friend: 0,
    percent_recommend_ceo: 0,
    ceo_ratings: 0,
    positive_outlook_percent: 0,
  },
  '2Y': {
    date: '',
    overall_stars: 0,
    number_of_reviews: 0,
    percent_recommend_friend: 0,
    percent_recommend_ceo: 0,
    ceo_ratings: 0,
    positive_outlook_percent: 0,
  },
};
