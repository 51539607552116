import React, { useEffect, useRef, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, message } from 'antd';
import { Form } from 'formik-antd';
import { Formik, FormikProps } from 'formik';
import { Dictionary, omit } from 'lodash';
// models
import { SelectOption } from '@models/Option';
import { CompanyProfile } from '@models/Company';
// constants
import { psgContext, companyInfo } from '@constants/equitytouch';
// utils
import { equityTouchValidationSchema } from '@utils/equityTouch';
// redux
import { actions as bulkEqtActions } from '@features/bulk-actions/equity-touch';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
// components
import { FormFields } from '@features/company/add-to-equity-touch';
import LongCard from '@features/company/long-card/LongCard';
import AutoSave from '../common/AutoSave';
import AddCadence from '../salesloft/AddCadence';
import { RowWrapper, CardWrapper } from '../EquityTouchBulk.styled';
import CardTitle from '../common/CardTitle';
import ContactsTable from '../equity-touch/ContactsTable';
import { Context } from '../common/Context';
import AddContactBulkEquityTouch from '../salesloft/AddContactBulkEquityTouch';

const Step2: React.FC = () => {
  const dispatch = useDispatch();
  const { submitForm, setSubmitForm, cadence, setStep, addon, setTitle } = useContext(Context);

  const companyIds = useSelector(bulkActionsSelectors.equityTouch.getAllIds);
  const getQueue = useSelector(bulkActionsSelectors.equityTouch.getQueue);
  const companyData = useSelector(
    bulkActionsSelectors.equityTouch.getCompanyData
  ) as CompanyProfile;
  const temporaryData = useSelector(bulkActionsSelectors.equityTouch.getTemporaryData);

  useEffect(() => {
    if (Object.keys(companyData).length === 0) {
      let position;

      if (getQueue === 0) {
        position = companyIds[0];
      } else {
        position = companyIds.find(item => item === getQueue);
      }

      position && dispatch(bulkEqtActions.fetchCompany(position as number, true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyIds]);

  const companyEqtData = useSelector(
    bulkActionsSelectors.equityTouch.getCompanyEqtData(companyData.company_id)
  );
  const position = useSelector(bulkActionsSelectors.equityTouch.getEditCompanyPosition);
  const formData = useSelector(bulkActionsSelectors.equityTouch.getFormData);

  const formRef = useRef<FormikProps<
    Dictionary<string | number | boolean | number[] | null | string[] | SelectOption>
  > | null>(null);

  const initialValues: Dictionary<
    string | number | boolean | number[] | null | string[] | SelectOption
  > = useMemo(() => {
    return {
      'Company Type': formData?.['Company Type']?.database_matched?.itemId || '',
      'Opportunity Type': formData?.['Opportunity Type']?.database_matched?.itemId || '',
      Country: formData?.Country?.database_matched?.itemId || '',
      Source: formData?.Source?.database_matched?.itemId || '',
      State:
        formData?.State?.database_matched?.text ||
        (formData?.State?.from_database as string | null),
      ...(omit(companyEqtData, [
        'add_all_contacts',
        'salesloft_contacts',
        'cadence',
        'Country',
        'State',
      ]) as Dictionary<string | number | boolean | number[] | null | string[] | SelectOption>),
      ...(typeof formData?.Country?.from_database === 'string'
        ? {
            Country: formData.Country.from_database,
          }
        : {}),
    };
  }, [formData, companyEqtData]);

  if (formRef && formRef?.current) {
    formRef?.current?.validateForm(temporaryData?.step2?.[companyData.company_id]);
  }

  if (formRef?.current && !formRef?.current?.isValid) {
    Object.keys(formRef?.current?.errors).forEach(field => {
      formRef?.current?.setTouched({ [field]: true });
    });
  }

  useEffect(() => {
    if (formRef.current) {
      formRef.current.validateForm();

      if (!formRef.current.isValid) {
        Object.keys(formRef.current.errors).forEach(field => {
          formRef.current?.setTouched({ [field]: true });
        });
      } else if (submitForm === 'single' || submitForm === 'update-and-submit') {
        formRef.current.submitForm();
      } else if (submitForm === 'single-bulk' || submitForm === 'previous') {
        dispatch(
          bulkEqtActions.updateBulkCompany({
            formValues: {
              ...(temporaryData?.step2?.[companyData.company_id] as any),
              company_id: companyData.company_id,
              ...(cadence !== undefined && { cadence }),
            },
            shouldSubmit: false,
          })
        );

        if (submitForm === 'single-bulk') {
          setStep(0);
        } else if (submitForm === 'previous') {
          dispatch(bulkEqtActions.fetchCompany(companyIds[Number(position) - 2], true));
        }

        setSubmitForm(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitForm]);

  useEffect(() => {
    setTitle(`Company ${position} of ${companyIds.length}: ${companyData.company_name}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, companyIds, companyData]);

  useEffect(() => {
    if (companyData.is_in_et) {
      message.success('Company is already in Equity Touch', 0);
    } else {
      message.destroy();
    }

    return () => {
      message.destroy();
    };
  }, [companyData]);

  const handleSubmit = (
    values: Dictionary<string | number | boolean | number[] | null | string[] | SelectOption>
  ) => {
    formRef.current?.validateForm(values);
    dispatch(
      bulkEqtActions.updateBulkCompany({
        formValues: {
          ...(temporaryData?.step2?.[companyData.company_id] as any),
          company_id: companyData.company_id,
          ...(cadence !== undefined && { cadence }),
        },
        shouldSubmit: submitForm === 'update-and-submit' || position === companyIds.length,
      })
    );

    if (position !== companyIds.length && submitForm !== 'update-and-submit') {
      dispatch(bulkEqtActions.fetchCompany(companyIds[Number(position)]));
    } else {
      setStep(2);
    }

    setSubmitForm(null);
  };

  const handleBlur = () => {
    if (submitForm) {
      setSubmitForm(null);
    }
  };

  const setActiveAddon = () => {
    formRef?.current?.setTouched({ 'Add-On For': true });
    formRef?.current?.setTouched({ 'Add-On': true });
    formRef?.current?.setTouched({ Fund: true });
  };

  if (temporaryData.step1 && temporaryData.step1['Add-On For']) {
    formRef?.current?.setFieldValue('Add-On For', temporaryData.step1['Add-On For']);
    setActiveAddon();
  }

  if (temporaryData.step1 && temporaryData.step1['Add-On']) {
    formRef?.current?.setFieldValue('Add-On', temporaryData.step1['Add-On']);
    setActiveAddon();
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnChange
        enableReinitialize
        validationSchema={companyData.is_in_et ? undefined : equityTouchValidationSchema}
        innerRef={instance => {
          formRef.current = instance;
        }}
      >
        <Form layout="vertical" onBlur={handleBlur}>
          <AutoSave step={2} />
          <RowWrapper gutter={20}>
            <Col span={24} xxl={12}>
              <CardWrapper>
                {Object.keys(companyData).length !== 0 && <LongCard company={companyData} newTab />}
              </CardWrapper>
              <CardWrapper title={<CardTitle title="Company Info" />}>
                <FormFields
                  readOnly={companyData.is_in_et}
                  fields={companyInfo}
                  initialFormData={formData}
                />
              </CardWrapper>
            </Col>
            <Col span={24} xxl={12}>
              <AddCadence readOnly={companyData.is_in_et} />

              <CardWrapper
                title={<CardTitle title="Contact Info" />}
                extra={<AddContactBulkEquityTouch />}
              >
                <ContactsTable readOnly={companyData.is_in_et} />
              </CardWrapper>
              <CardWrapper
                title={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <CardTitle
                    title="PSG Context and Rank"
                    subtitle="These values will be applied to all companies. You can override values in the next step"
                  />
                }
              >
                <FormFields
                  readOnly={companyData.is_in_et}
                  fields={psgContext}
                  initialFormData={{
                    ...formData,
                    'Add-On For': {
                      ...formData['Add-On For'],
                      received_values: addon ? [{ ...addon }] : [],
                    },
                  }}
                />
              </CardWrapper>
            </Col>
          </RowWrapper>
        </Form>
      </Formik>
    </>
  );
};

export default Step2;
