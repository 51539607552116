import styled from 'styled-components';
import { Tag as AntDTag } from 'antd';

const Wrapper = styled.div`
  display: flex;
`;

const Tag = styled(AntDTag)`
  cursor: pointer;
`;

export const Styled = {
  Wrapper,
  Tag,
};
