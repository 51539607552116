import React from 'react';

interface AttendeesProps extends React.HTMLAttributes<HTMLSpanElement> {
  attendees: Array<string> | null;
}

/**
 * Compute atendees label.
 * @param atendees the list of atendees.
 */
const getAtendeesLabel = (atendees: Array<string>) => {
  const subject = `(${atendees.length > 1 ? 'attendees' : 'attendee'})`;
  const names = atendees.join(', ');

  return `${names} ${subject}`;
};

const Attendees = ({ attendees, ...restProps }: AttendeesProps) => {
  if (!attendees || !attendees.length) {
    return null;
  }

  return <span {...restProps}>{getAtendeesLabel(attendees)}</span>;
};

export default Attendees;
