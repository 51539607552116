import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { Button as Btn } from 'antd';

const Section = styled.section<{ backgroundImage?: string }>`
   {
    background: #c2cfe0;
    background-image: url(${({ backgroundImage }) => backgroundImage});
    background-size: 100% 50%;
    background-position: bottom;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    padding-bottom: ${({ backgroundImage }) => (backgroundImage ? '80px' : '0px')};
    position: relative;

    &.touch  {
      padding-bottom: 15px;
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 15px;
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: 600;
  padding-left: 9px;
  margin-bottom: 0;
  margin-right: auto;
  font-size: 20px;
  /* Gray / gray-1 */
  color: #ffffff;
`;

const Button = styled(Btn)`
  border-color: #1890ff !important;
`;

const Body = styled.div`
  padding: 0 24px;
  position: relative;
  z-index: 3;
  top: 15px;
`;

const BodyContent = styled.div`
  .date-buttons {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    justify-content: center;

    .ant-radio-button-wrapper {
      flex: 1;
      background-color: inherit;
      font-size: 14px;
      color: #fff;
      border: 1px solid #1890ff;
      text-align: center;
    }

    .ant-radio-button-wrapper-checked {
      background-color: #1890ff;
      z-index: 2;
      border-color: #1890ff !important;
    }

    .ant-radio-button-wrapper:not(:first-child)::before {
      z-index: 1;
      background-color: #1890ff;
    }
  }

  .ant-breadcrumb {
    color: #1890ff;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 12px;

    .ant-breadcrumb-separator {
      color: #c2cfe0;
    }

    & > span:last-child {
      color: #c2cfe0;
    }
  }
`;

const Banner = styled.div`
  background: linear-gradient(0deg, #064885 0%, #137edf 119.38%);
  position: absolute;
  width: 100%;
  z-index: 2;
  top: 0;
  left: 0;
`;

const SubSection = styled.div`
  /* Gray / gray-1 */
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;

  padding: 18px 20px 14px 20px;
  margin-bottom: 36px;

  ${up('md')} {
    padding: 15px 20px;
  }

  .extra-data {
    display: flex;
    gap: 20px;

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-weight: 600;
      line-height: 15px;

      b {
        color: #888ba9;
        font-size: 12px;
        font-weight: 600;
      }

      span {
        color: #1890ff;
        font-size: 14px;

        &.clickable:hover {
          cursor: pointer;
          color: #096dd9;
        }
      }
    }
  }

  .chart-legends {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    gap: 10px;

    div.chart-legend {
      min-width: 200px;
      width: 100%;
      max-width: 350px;
      height: 30px;
      display: flex;
    }

    .count {
      text-align: center;
      line-height: 30px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      width: 52px;
      background: #096dd9;
      color: white;
      font-weight: 700;
      color: #fff;
      font-size: 14px;
    }

    .label {
      flex: 1;
      line-height: 30px;
      color: #595959;
      padding-left: 10px;
      font-size: 12px;
      border-top-right-radius: 4px;
      background: rgba(29, 41, 96, 0.1);
      border-bottom-right-radius: 4px;
      color: #262626;
      font-weight: 600;

      &.clickable {
        cursor: pointer;
      }
    }
  }

  .chart-loading-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .chart-loading {
    width: 150%;
    padding: 14px 20px 14px;
    margin: 10px -20px 25px;
    border-bottom: 1px solid #e3e5e8;
    border-top: 1px solid #e3e5e8;
    display: flex;
    justify-content: space-between;
  }

  .pie-chart-loading {
    display: flex;
    justify-content: space-between;

    &__text {
      width: 100%;
    }
  }
`;

const SubSectionTitle = styled.h3`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 20px;

  .anticon:first-child {
    color: #1890ff;
    font-size: 24px;
    margin: 0 15px 0 0;
  }

  .radaricon {
    font-size: 28px;
    margin-right: 12px;
  }
`;

const TotalsCardWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const TotalsCardItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const TotalsCardItemLabel = styled.span`
  font-size: 12px;
  color: #90a0b7;
  font-weight: 600;
`;
const TotalsCardItemValue = styled.span`
  font-weight: 600;
  font-size: 14px;
  width: max-content;
  cursor: pointer;
  color: ${({ color }) => color ?? '#1890ff'};

  &:hover {
    color: ${({ color }) => !color && '#096dd9'};
  }
`;

const ChartTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #334d6e;
  margin-top: 15px;
`;

const Styles = {
  Section,
  TitleWrapper,
  Title,
  Button,
  Banner,
  Body,
  BodyContent,
  // subsection
  SubSection,
  SubSectionTitle,
  ChartTitle,
  // totals
  TotalsCardWrapper,
  TotalsCardItem,
  TotalsCardItemLabel,
  TotalsCardItemValue,
};

export default Styles;
