import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// models
import { SortByRule } from '@optx/models/table/sorting';
import { FileDetails } from '@optx/models/files';
import { DataSettingsResponse, FilesState, UpdateFiltersModels } from './interfaces';
import { SuccessResponse } from '@optx/redux/ui/modals/add-document-upload-touch/interfaces';
// constants
import { FILES_DEFAULT_SORT } from '@optx/constants/table/sort/defaultSort';
// redux
import { actions as uploadFileActions } from '@redux/ui/modals/add-document-upload-touch';
import { fetchFailReducer, fetchReducer } from '../../feature/fetch/reducers';
import * as actions from './actions';

const initialState: FilesState = {
  error: '',
  data: [],
  loading: false,
  fetchedAt: '',
  companyId: null,
  sortBy: FILES_DEFAULT_SORT,
  filter: { values: {}, options: {} },
};

const initiateFetchFilesReducer: CaseReducer<FilesState, PayloadAction<number | null>> = (
  draftState,
  action
) => {
  draftState.companyId = action.payload;
};

const clearFilesDataReducer: CaseReducer<FilesState> = draftState => {
  draftState.companyId = null;
  draftState.data = [];
  draftState.fetchedAt = '';
};

const fetchFilesSuccessReducer: CaseReducer<FilesState, PayloadAction<Array<FileDetails>>> = (
  draftState,
  action
) => {
  draftState.loading = false;
  draftState.data = [...action.payload].map(file => ({
    ...file,
    file_type: `.${file.name.split('.').pop()}`,
  }));
  draftState.fetchedAt = new Date().toISOString();
};

const sortReducer: CaseReducer<FilesState, PayloadAction<Array<SortByRule<any>>>> = (
  draftState,
  action
) => {
  draftState.sortBy = action.payload;
};

const changeFilterReducer: CaseReducer<FilesState, PayloadAction<UpdateFiltersModels>> = (
  draftState,
  action
) => {
  const { key, value } = action.payload;

  if (draftState.filter.values) {
    draftState.filter.values = {
      ...draftState.filter.values,
      [key]: value,
    };
  }
};

const uploadFilesSuccessReducer: CaseReducer<FilesState, PayloadAction<SuccessResponse[]>> = (
  draftState,
  action
) => {
  const payload = action.payload;

  if (payload && draftState.data) {
    const mappedAction = payload.map(item => ({ ...(item.files as FileDetails) }));
    draftState.data.unshift(...mappedAction);
  }
};

const fetchFilesFiltersSuccessReducer: CaseReducer<
  FilesState,
  PayloadAction<DataSettingsResponse>
> = (draftState, action) => {
  draftState.filter.options = action.payload.filters;
  draftState.filter.values = action.payload.filters;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.fetchFiles, fetchReducer)
    .addCase(actions.fetchForCompanyId, initiateFetchFilesReducer)
    .addCase(actions.clearFilesData, clearFilesDataReducer)
    .addCase(actions.fetchFilesSuccess, fetchFilesSuccessReducer)
    .addCase(actions.fetchFilesFail, fetchFailReducer)
    .addCase(actions.sortFiles, sortReducer)
    .addCase(actions.changeFilter, changeFilterReducer)
    .addCase(actions.fetchFilesFiltersSuccess, fetchFilesFiltersSuccessReducer)
    .addCase(uploadFileActions.uploadFilesSuccess, uploadFilesSuccessReducer)
);

export default reducer;
