import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
// utils
import { toURL } from '../../../../../utils/url';
// components
import Card from '../../../../common/cards';
import { ContentLink } from '../../../../common/link';
import googleNewsImage from '../../../../../assets/images/google-news.png';

interface GoogleNewsProps {
  url: string | null;
}

const StyledImage = styled.img`
  max-width: 100%;
  margin: auto;
  display: block;
  position: relative;
  @media (min-width: 1700px) {
    max-width: 60%;
  }
  @media (min-width: 1919px) {
    max-width: 50%;
  }
`;

const NoNewsTitle = styled(Typography.Text)`
  h4& {
    color: #90a0b7;
  }
`;

const ClickHereText = styled(Typography.Text)`
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translateX(-50%);
  color: #90a0b7;
  font-size: 12px;
  max-width: 100%;
  text-align: center;
`;

const GoogleNews = ({ url }: GoogleNewsProps) => {
  return (
    <Card style={{ minHeight: '150px', margin: '5px' }} hoverable={!!url}>
      {url ? (
        <ContentLink target="_blank" href={toURL(url)}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <StyledImage src={googleNewsImage} alt="Google News" />
          </div>{' '}
          <ClickHereText>Click here to view news about this company</ClickHereText>
        </ContentLink>
      ) : (
        <NoNewsTitle>No Google News</NoNewsTitle>
      )}
    </Card>
  );
};

export default GoogleNews;
