export const FETCH_FILES = 'FETCH_FILES';
export const FETCH_FILES_SUCCESS = 'FETCH_FILES_SUCCESS';
export const FETCH_FILES_FAIL = 'FETCH_FILES_FAIL';

export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';

export const FETCH_FOR_COMPANY_ID = 'FETCH_FOR_COMPANY_ID';
export const CLEAR_FILES_DATA = 'CLEAR_FILES_DATA';

export const SORT_FILES = 'SORT_FILES';
export const CHANGE_FILTER = 'CHANGE_FILTER';

export const FETCH_FILES_FILTERS = 'FETCH_FILES_FILTERS';
export const FETCH_FILES_FILTERS_SUCCESS = 'FETCH_FILES_FILTERS_SUCCESS';
export const FETCH_FILES_FILTERS_FAIL = 'FETCH_FILES_FILTERS_FAIL';
