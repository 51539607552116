import styled, { css } from 'styled-components';

const Thumb = styled.div`
  border-radius: 21px;
  width: 42px;
  height: 42px;
  padding: 7px;
  margin-right: 6px;
  cursor: pointer;
`;

interface ThumbProps {
  checked?: boolean | null | string;
}

const ThumbUp = styled(Thumb)<ThumbProps>`
  ${({ checked }) => css`
    background-color: ${checked === true ? '#52c41a' : null};
    padding: 5px 5px 5px 8px;
    border: 1px solid #52c41a;

    span,
    svg {
      color: ${checked === true ? 'white' : '#E8E8E8'};
    }
    ,
    path {
      fill-opacity: 1;
    }
  `}
`;

const ThumbDown = styled(Thumb)<ThumbProps>`
  ${({ checked }) => css`
    background-color: ${checked === false ? '#f5222d' : null};
    padding: 9px 5px 5px 5px;
    border: 1px solid #f5222d;

    span,
    svg {
      color: ${checked === false ? 'white' : '#E8E8E8'};
    }
    ,
    path {
      fill-opacity: 1;
    }
  `}
`;

export default { ThumbDown, ThumbUp };
