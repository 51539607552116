import { call, put, takeLatest, select } from 'redux-saga/effects';
import { Dictionary } from 'lodash';
import { PayloadAction } from '@reduxjs/toolkit';
import queryString from 'query-string';
// models
import { HistogramGroup, PreselectedFilter } from '@optx/models/filters';
// services
import NotificationService from '@services/NotificationService';
import { CompanyService } from '@services/api';
import { getErrorMessage } from '@utils/api/errors';
// utils
import { normalizeFilter } from '@optx/utils/filters/filterHelpers';
// redux
import { SearchStateData } from '@models/search';
import { actions as userInformationActions } from '@redux/user/information';
import { selectors as filtersCardsSelectors } from '@optx/redux/my-companies/filters-cards';
import { getFiltersQuery, handleTouchFilters } from '@redux/company/search/search/sagasReusable';
import { selectors as searchSelectors } from '@redux/my-companies/search';
import { interfaces as histogramInterfaces } from '@features/histograms/histograms-advanced-search';
import * as actions from './actions';
import { selectors as filterSelectors } from '@optx/features/grid/filter';

export function* fetchHistogramFiltersSaga(
  action: PayloadAction<histogramInterfaces.FetchHistogramsActionPayload>
) {
  try {
    const histogramsEnabled: boolean = yield select(searchSelectors.histogramsEnabled);

    if (!histogramsEnabled) {
      return;
    }

    const selectedCard: Dictionary<SearchStateData> = yield select(
      filtersCardsSelectors.getSelectedCard
    );
    const subFilter: string = yield select(filtersCardsSelectors.getSubFilter);

    let filter: Dictionary<PreselectedFilter> = action.payload.filter || {};

    const defaultHistogramKeys: Array<string> = yield select(
      searchSelectors.selectAllFiltersWithHistograms
    );

    filter = yield call(handleTouchFilters, filter);

    let filterQueryPayload: Dictionary<string | Array<number | string>> = yield call(
      getFiltersQuery,
      filter
    );

    filterQueryPayload = subFilter
      ? // @ts-ignore
        { ...selectedCard.filter, ...selectedCard.data[subFilter], ...filterQueryPayload }
      : { ...selectedCard.filter, ...filterQueryPayload };

    delete filterQueryPayload.query;

    const defaultHistograms: HistogramGroup = yield select(searchSelectors.selectDefaultHistograms);
    const filterQueryString = queryString.stringify(filterQueryPayload, { arrayFormat: 'comma' });
    const isDefaultHistogram = !filterQueryString;

    if (isDefaultHistogram && defaultHistograms) {
      yield put(actions.resetToDefault());
    } else {
      // @ts-ignore
      const res = yield call(CompanyService.getHistograms, {
        filterQuery: filterQueryPayload,
        histogramList: defaultHistogramKeys,
      });

      yield put(
        actions.fetchHistogramFiltersSuccess({
          data: res.data,
          companyCount: res.data.company_count,
          isDefault: isDefaultHistogram,
        })
      );
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Error fetching histograms!');
    yield put(actions.fetchHistogramFiltersFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

function* filterChangeSaga(action: PayloadAction<histogramInterfaces.FilterChangedPayload>) {
  let { values } = action.payload;
  values = normalizeFilter(values);
  yield put(actions.fetchHistogramFilters(values));
}

function* toggleRefreshSaga(action: PayloadAction<histogramInterfaces.ToggleRefreshPayload>) {
  let { enabled, values } = action.payload;
  values = normalizeFilter(values);

  if (enabled) {
    yield put(actions.fetchHistogramFilters(values));
  }

  yield put(userInformationActions.updateUserSettings({ my_companies_filters_refresh: enabled }));
}

function* initializeHistogramsSaga() {
  // when retrieving filters also make call to update histograms.
  // this is necessary for when the app is refreshed and histograms
  // have to reflect the state of the filters
  const filter: Dictionary<PreselectedFilter> = yield select(
    filterSelectors.getFilter('myCompanies')
  );
  const allFiltersWithHistograms: Array<string> = yield select(
    searchSelectors.selectAllFiltersWithHistograms
  );

  yield put(actions.fetchHistogramFilters(filter, allFiltersWithHistograms));
}

export default function* companyHistogramsSaga() {
  yield takeLatest(actions.fetchHistogramFilters, fetchHistogramFiltersSaga);
  yield takeLatest(actions.filterChanged, filterChangeSaga);
  yield takeLatest(actions.toggleRefresh, toggleRefreshSaga);
  yield takeLatest(actions.init, initializeHistogramsSaga);
}
