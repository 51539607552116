import React from 'react';
import {
  AlertOutlined,
  ClockCircleOutlined,
  StarOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
// interface
import { MyCompaniesIconType } from './interface';

interface IconProps {
  type: MyCompaniesIconType;
}

const MyCompaniesCardIcon: React.FC<IconProps> = ({ type }) => {
  switch (type) {
    case 'AlertOutlined':
      return <AlertOutlined />;
    case 'ClockCircleOutlined':
      return <ClockCircleOutlined />;
    case 'StarOutlined':
      return <StarOutlined />;
    case 'CalendarOutlined':
      return <CalendarOutlined />;
    default:
      return null;
  }
};

export default MyCompaniesCardIcon;
