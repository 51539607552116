import styled, { css } from 'styled-components';

const ScheduledTouchItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Date = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 125px;
  height: 100%;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  text-align: right;
  color: #616f82;
  position: relative;
`;

const ScheduledTouchItemContentWrapper = styled.div<{ areMoreNextItems?: boolean }>`
  border-left: 1px solid #e8e8e8;
  width: 100%;
  ${props =>
    css`
      min-height: ${props.areMoreNextItems ? '130px' : '85px'};
      display: flex;
      align-items: center;
    `}
`;

const ScheduledTouchItemContent = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  flex-grow: 1;
  margin: 10px 0 10px 30px;
  min-height: 85px;
  padding: 18px 20px;

  .company-avatar {
    width: 50px;
    height: 50px;
    background: #ffffff;
    border-radius: 6px;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
    margin-right: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    svg {
      width: 60%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding-right: 10px;

    .company {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #1890ff;
      display: flex;
      gap: 8px;
      align-items: center;

      a {
        display: none;
      }
      a.company_name {
        display: block;
      }

      &:hover {
        a {
          display: inline-block;
        }
      }
    }
  }

  .stats {
    display: flex;
    align-items: center;
    gap: 25px;

    .last-touch,
    .rank,
    .stage,
    .optx {
      font-weight: 600;
      font-size: 14px;
      text-align: right;
      text-transform: uppercase;
      margin: 0px auto;
    }

    .optx {
      span:last-child {
      }
      span:first-child {
        color: #1890ff;
        margin-right: 5px;
      }
    }

    div > span:last-child {
      font-weight: 600;
      font-size: 10px;
      text-align: right;
      text-transform: uppercase;
      color: #334d6e;
    }
  }
`;

const ScheduledTouchItemDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 24px;
  position: relative;

  .scheduled-touch-item-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 62px;
    align-items: center;

    .scheduled {
      color: #1890ff !important;

      path  {
        fill: #1890ff !important;
      }
    }

    .past-due  {
      color: #f5222d !important;

      path  {
        fill: #f5222d !important;
      }
    }

    .completed {
      color: #13c2c2 !important;
      path  {
        fill: #13c2c2 !important;
      }
    }
    span,
    svg {
      font-size: 10px;
      font-weight: 600;
      text-align: center;
    }

    span.anticon,
    svg {
      font-size: 21px !important;
    }
  }

  &::after {
    content: '';
    position: absolute;
    background: #ffffff;
    border-radius: 100%;
    border: 2px solid #1890ff;
    width: 9px;
    height: 9px;
    right: -5px;
    top: 50%;
  }
`;

const NextTouchDate = styled.div`
  color: #bfbfbf;
  font-weight: 600;
  font-size: 10px;
`;

const PSGCompanyLabel = styled.div`
  color: rgb(30, 58, 81);
  font: bold 32px 'Times New Roman';
`;

export default {
  ScheduledTouchItem,
  Date,
  NextTouchDate,
  ScheduledTouchItemDetail,
  ScheduledTouchItemContent,
  ScheduledTouchItemContentWrapper,
  PSGCompanyLabel,
};
