import React, { useState, useContext } from 'react';
import { Row, Col } from 'antd';
import { useField } from 'formik';
import { Form, Input } from 'formik-antd';
// models
import { BaseFilter } from '@optx/models/filters';
import { FilterMultiTextOption } from '@optx/models/Option';
// components
import SearchFilterCard from './SearchFilterCard';
import { FiltersContext } from '../FiltersContext';

interface FilterMultiTextProps {
  filter: BaseFilter<Array<FilterMultiTextOption>>;
}

const FilterMultiText: React.FC<FilterMultiTextProps> = ({ filter }) => {
  const [field, ,] = useField<Array<FilterMultiTextOption>>(filter.column);
  const [shouldResetField, setShouldResetField] = useState(false);
  const { onManualFilterChange } = useContext(FiltersContext);

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    // prevent call when field is empty and
    // allow call when field previously had a value and it was emptied
    if (shouldResetField || event.target.value !== '') {
      // Special onChange handler.
      filter.onChange && filter.onChange();

      // Call on change on the next tick.
      setTimeout(() => {
        onManualFilterChange && onManualFilterChange(filter.column);
      }, 0);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setShouldResetField(true);
    } else {
      setShouldResetField(false);
    }
  };

  return (
    <SearchFilterCard label={filter.label}>
      <Row>
        {field.value.map((option, index) => (
          <Col key={index} span={24}>
            <Form.Item label={option.label} name={`${filter.column}[${index}].value`}>
              <Input
                name={`${filter.column}[${index}].value`}
                placeholder={option.placeholder}
                onBlur={onBlur}
                fast
                onChange={onChange}
              />
            </Form.Item>
          </Col>
        ))}
      </Row>
    </SearchFilterCard>
  );
};

export default React.memo(FilterMultiText);
