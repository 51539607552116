import React from 'react';
import { Tooltip } from 'antd';
import { orderBy } from 'lodash';
// models
import Company, { FinancialFieldType, CompanyUserValuesKeys } from '@optx/models/Company';
import { CustomValue } from '@optx/features/company/edit-fields/state/interfaces';
import {
  RenderLabelProps,
  RenderLabelOtherProps,
} from '@optx/models/feature/edit-field/editFields';
import { SelectOption } from '@optx/models/Option';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { numberPercentageRegex, numberRegExp } from '@optx/constants/regex';
import {
  ONLY_DIGITS_AND_OPTIONS_ERROR_MESSAGE,
  ONLY_DIGITS_AND_PERCENTAGE_ERROR_MESSAGE,
  ONLY_DIGITS_ERROR_MESSAGE,
  message,
} from '@optx/constants/table/cells';
//utils
import { isOptionChecked } from '@optx/utils/option';

/**
 * depending on where a component for editing fields is accessed, we need to display
 * different text for the item used to open the popover
 */
export const renderLabel = ({
  isFromGridDropDown,
  isFromHistory,
  dropdownLabel,
  value,
  label,
  useTooltip = true,
}: RenderLabelProps) => {
  if (isFromHistory) {
    return 'Edit Field';
  }

  if (isFromGridDropDown) {
    return dropdownLabel;
  }

  if (!useTooltip) {
    return <span>{label}</span>;
  }

  return <Tooltip title={value === 'None' ? DEFAULT_EMPTY_VALUE : value}>{label}</Tooltip>;
};

/**
 * depending on where a component for editing fields is accessed, we need to display
 * different text for the item used to open the popover
 * if the value is 'Other' we need to display the other option
 */
export const renderLabelOther = ({
  isFromGridDropDown,
  isFromHistory,
  dropdownLabel,
  value,
  label,
  useTooltip = true,
  extraValue,
}: RenderLabelOtherProps) => {
  if (isFromHistory) {
    return 'Edit Field';
  }

  if (isFromGridDropDown) {
    return dropdownLabel;
  }

  if (!useTooltip) {
    return <span>{label}</span>;
  }

  return (
    <Tooltip title={renderValueOther(value as string, extraValue as string)}>
      {renderValueOther(value as string, extraValue as string)}
    </Tooltip>
  );
};

/**
 * Renders a value based on specific conditions.
 *
 * This function takes a primary value and an optional secondary value,
 * and returns a formatted string based on the following rules:
 * - If the primary value is 'None', it returns a default empty value.
 * - If the primary value is 'Other' and a secondary value is provided,
 *   it returns the string 'Other:' concatenated with the secondary value.
 * - Otherwise, it returns the primary value.
 *
 * @param {string} value - The primary value to be evaluated.
 * @param {string | null} otherValue - The secondary value to be used if the primary value is 'Other'.
 * @returns {string} - The formatted string based on the provided values.
 */
export const renderValueOther = (value: string | null, otherValue: string | null) => {
  return value === 'None' || value === null
    ? DEFAULT_EMPTY_VALUE
    : value === 'Other' && otherValue
    ? otherValue
    : value;
};

/**
 * validate proprietary info field value
 * for arr and revenue value should be a number
 * for gm % value should be a number, but user can also enter %
 * for ebitda value should be a number or one of the dropdown options
 * user can also leave the input empty
 * @param {string | number} fieldValue input field value
 * @param {string} fieldKey name of the input field
 * @param {SelectOption<string>[] | undefined} options possible dropdown options for input field
 * @param {boolean} showPopupError have posibility to show error message using ant message popup
 * @returns {boolean}
 */
export const validateField = (
  fieldValue: string | number,
  fieldKey: string,
  options: SelectOption<string>[] | undefined,
  showPopupError?: boolean
) => {
  const isFromDropdown = isOptionChecked(fieldValue, options);

  if (isFromDropdown) return true;

  if (
    ((fieldKey === 'ARR' || fieldKey === 'Revenue' || fieldKey === 'Last Round') &&
      isNaN(Number(fieldValue))) ||
    ((fieldKey === 'Capital Raised' ||
      fieldKey === 'Last Raised Amount' ||
      fieldKey === 'Asking Amount') &&
      !numberRegExp.test(fieldValue.toString()))
  ) {
    if (showPopupError === true || showPopupError === undefined) {
      message().error(ONLY_DIGITS_ERROR_MESSAGE);
    }

    return false;
  } else if (
    fieldKey === 'GM %' &&
    typeof fieldValue === 'string' &&
    !numberPercentageRegex.test(fieldValue) &&
    fieldValue !== ''
  ) {
    if (showPopupError === true || showPopupError === undefined) {
      message().error(ONLY_DIGITS_AND_PERCENTAGE_ERROR_MESSAGE);
    }

    return false;
  } else if (fieldKey === 'EBITDA' && !isFromDropdown && isNaN(Number(fieldValue))) {
    if (showPopupError === true || showPopupError === undefined) {
      message().error(ONLY_DIGITS_AND_OPTIONS_ERROR_MESSAGE);
    }

    return false;
  }

  return true;
};

/**
 * function for getting the amount corresponding to the selected year
 * @param {FinancialFieldType[] | null} listValues array with options
 * @param {number} year selected year
 */
export const getAmount = (listValues: FinancialFieldType[] | null, year: number) =>
  listValues?.find(item => Number(item.year) === year)?.value;

/**
 *function for getting if the current year is estimated
 * @param {FinancialFieldType[] | null} listValues - list of FinancialFieldType objects
 * @param {number} year - the year to search for
 * @returns {boolean | undefined} - the estimated value of the FinancialFieldType object with the given year
 */
export const isYearEstimated = (listValues: FinancialFieldType[] | null, year: number) => {
  const initialIsYearEstimated = year >= new Date().getFullYear();

  return listValues?.find(item => Number(item.year) === year)?.estimated ?? initialIsYearEstimated;
};

export const updateFinancialValues = (
  draftStateData: Company,
  fieldKey: CompanyUserValuesKeys,
  fieldValues: CustomValue
) => {
  const financialList = draftStateData[fieldKey] as FinancialFieldType[] | null;
  let newValues: FinancialFieldType[] = [];
  const { value: amount, optionalValue: year, from_source } = fieldValues;

  if (financialList === null) {
    newValues.push({
      value: amount as number | string,
      year: year as number,
      from_source: from_source as boolean,
    });
  } else {
    const financialOptionIndex = financialList.findIndex(
      (item: FinancialFieldType) => Number(item.year) === year
    );

    if (financialOptionIndex !== -1) {
      newValues = [...financialList];
      newValues[financialOptionIndex].value = amount as string | number;
      newValues[financialOptionIndex].from_source = from_source as boolean;

      if (amount === DEFAULT_EMPTY_VALUE) {
        newValues.splice(financialOptionIndex, 1);
      }
    } else {
      newValues = financialList;
      newValues.push({
        value: amount as number | string,
        year: year as number,
        from_source: from_source as boolean,
      });
      const newOrder = orderBy(newValues, 'year', 'desc');
      newValues = newOrder;
    }
  }

  if (fieldKey === 'ebitda_list') {
    draftStateData.ebitda_source_db = newValues.length === 0 ? '' : 'pi';
  } else if (fieldKey === 'revenue_list') {
    draftStateData.last_rev_source_db = newValues.length === 0 ? '' : 'pi';
  }

  // @ts-ignore
  draftStateData[fieldKey] = newValues;
};
