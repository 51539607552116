import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectors, actions } from '@redux/company/filters';
import { useShouldFetch } from '../fetch';

const useInitFilters = () => {
  const shouldFetch = useSelector(selectors.shouldFetchCompanyFilters);
  const dispatch = useDispatch();
  const fetchFilters = useCallback(() => dispatch(actions.fetchCompanyFilters()), [dispatch]);
  useShouldFetch(shouldFetch, fetchFilters);
};

export default useInitFilters;
