import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { forEach, indexOf } from 'lodash';
import {
  CompanyImport,
  CompanyImportFailedData,
  ImportSimilarCompanies,
} from '@optx/models/Company';
import { reducers as fetchReducers } from '@redux/feature/fetch';
import * as actions from './actions';
import { ListImportState, ImportedCompany, ImportResults, PercentageCompleted } from './interfaces';

export const initialState: ListImportState = {
  data: null,
  failedImports: [],
  canceledImports: [],
  importCompanies: [],
  failedImportsOnRequest: [],
  results: [],
  error: '',
  title: '',
  listId: null,
  editingCompanyIndex: null,
  isEditing: false,
  percentageCompleted: { result: 0 },
  importCompaniesCount: 0,
  canceledImportsNumber: 0,
  failedBatches: 0,
  showSimilarCompanies: false,
  similarCompanies: [],
  loading: false,
  completed: false,
  cancel: false,
};

// fetch favorite lists success reducer
const importFavoriteListSuccessReducer: CaseReducer<
  ListImportState,
  PayloadAction<CompanyImport>
> = (draftState, action) => {
  const listImports = action.payload;
  draftState.loading = false;

  forEach(listImports.failed_company_data, (value, key) => {
    if (indexOf(draftState.failedImports, value) < 0) {
      draftState.failedImports.push(value);
    }
  });

  draftState.data = listImports;
};

const updateCanceledImportsReducer: CaseReducer<
  ListImportState,
  PayloadAction<CompanyImportFailedData[]>
> = (draftState, action) => {
  draftState.canceledImports = action.payload;
};

const cancelReducer: CaseReducer<ListImportState, PayloadAction<boolean>> = (
  draftState,
  action
) => {
  draftState.cancel = action.payload;
};

const updateImportCompaniesReducer: CaseReducer<
  ListImportState,
  PayloadAction<{ title: string; data: ImportedCompany[] }>
> = (draftState, action) => {
  draftState.importCompanies = action.payload.data;
  draftState.importCompaniesCount = action.payload.data.length;
  draftState.title = action.payload.title;
};

const updateImportCompaniesIdsReducer: CaseReducer<
  ListImportState,
  PayloadAction<Array<{ companyName: string; id: number; URL: string; raw_url: string | null }>>
> = (draftState, action) => {
  action.payload.forEach(({ companyName, id, URL: companyUrl, raw_url }) => {
    const index = draftState.results.findIndex(
      ({ URL, Company }) => Company === companyName && URL === companyUrl
    );

    draftState.results[index] = {
      ...draftState.results[index],
      raw_url,
      company_id: id,
      Reason: '',
      possible_matches: [],
    };
  });
};

const removeFailedCompamyReducer: CaseReducer<
  ListImportState,
  PayloadAction<{ Company: string; URL: string }>
> = (draftState, { payload }) => {
  const failedCompanieyIndex = draftState.failedImports.findIndex(
    ({ Company, URL }) => Company === payload.Company || URL === payload.URL
  );

  draftState.failedImports.splice(failedCompanieyIndex, 1);
};

const removeFailedCompamiesReducer: CaseReducer<ListImportState, PayloadAction<string[]>> = (
  draftState,
  action
) => {
  action.payload.forEach(companyName => {
    draftState.failedImportsOnRequest = draftState.failedImportsOnRequest.filter(
      ({ Company }) => Company !== companyName
    );
  });
};

const editCompaniesReducer: CaseReducer<
  ListImportState,
  PayloadAction<CompanyImportFailedData[]>
> = (draftState, { payload }) => {
  payload.forEach(importedCompany => {
    const companyIndex = draftState.importCompanies.findIndex(
      ({ Company }) => Company === importedCompany.Company
    );

    if (companyIndex) {
      draftState.importCompanies[companyIndex] = {
        Company: importedCompany.Company,
        URL: importedCompany.URL,
        Website: importedCompany.URL,
        raw_url: importedCompany.raw_url,
      };
      draftState.results = draftState.results.map(result => {
        if (result.Company === importedCompany.Company) {
          return {
            ...result,
            possible_matches: importedCompany.possible_matches,
            count_possible_matches: importedCompany.count_possible_matches,
            Reason: importedCompany.Reason,
          };
        }

        return result;
      });
    }
  });
};

const setIsLoadingReducer: CaseReducer<ListImportState, PayloadAction<boolean>> = (
  draftState,
  action
) => {
  draftState.loading = action.payload;
};

const saveResultsReducer: CaseReducer<ListImportState, PayloadAction<ImportResults>> = (
  draftState,
  action
) => {
  if (action.payload.sort) {
    draftState.results = Array.from(action.payload.data).sort((a, b) => {
      let comparison = 0;

      if (a.Reason < b.Reason) {
        comparison = 1;
      } else if (a.Reason > b.Reason) {
        comparison = -1;
      }

      return comparison;
    });
  } else {
    draftState.results = action.payload.data;
  }
};

const resetProgressReducer: CaseReducer<ListImportState> = draftState => {
  draftState.results = [];
  draftState.failedImportsOnRequest = [];
  draftState.failedImports = [];
  draftState.completed = false;
  draftState.percentageCompleted.result = 0;
  draftState.failedBatches = 0;
  draftState.importCompaniesCount = 0;
  draftState.canceledImportsNumber = 0;
  draftState.editingCompanyIndex = null;
  draftState.title = '';
  draftState.listId = null;
  draftState.data = null;
  draftState.isEditing = false;
  draftState.cancel = false;
  draftState.showSimilarCompanies = false;
  draftState.similarCompanies = [];
  draftState.importCompanies = [];
  draftState.canceledImports = [];
};

const updateCompletedReducer: CaseReducer<ListImportState, PayloadAction<boolean>> = (
  draftState,
  action
) => {
  draftState.completed = action.payload;

  if (draftState.completed) {
    // Show errors on top when completed
    draftState.results = Array.from(draftState.results).sort((a, b) => {
      let comparison = 0;

      if (a.Reason < b.Reason) {
        comparison = 1;
      } else if (a.Reason > b.Reason) {
        comparison = -1;
      }

      return comparison;
    });
  }
};

const updatePercentageReducer: CaseReducer<ListImportState, PayloadAction<PercentageCompleted>> = (
  draftState,
  action
) => {
  const { result: addProgress, isPrevious } = action.payload;

  if (addProgress === 0 || addProgress === 100) {
    draftState.percentageCompleted.result = addProgress;
  } else if (addProgress === 0 || (addProgress === 100 && isPrevious)) {
    draftState.percentageCompleted.result = addProgress;
  } else {
    draftState.percentageCompleted.result += addProgress;
  }
};

const updateProgressReducer: CaseReducer<ListImportState, PayloadAction<boolean>> = (
  draftState,
  action
) => {
  draftState.loading = action.payload;
};

const editCompanyReducer: CaseReducer<ListImportState, PayloadAction<ImportedCompany>> = (
  draftState,
  { payload }
) => {
  const companyIndex = draftState.editingCompanyIndex;

  if (companyIndex) {
    draftState.importCompanies[companyIndex] = payload;
  }
};

const editingCompanyIndexReducer: CaseReducer<ListImportState, PayloadAction<number | null>> = (
  draftState,
  { payload }
) => {
  draftState.editingCompanyIndex = payload;
};

const saveListIdReducer: CaseReducer<ListImportState, PayloadAction<number>> = (
  draftState,
  { payload }
) => {
  draftState.listId = payload;
};

const showSimilarCompaniesReducer: CaseReducer<ListImportState, PayloadAction<boolean>> = (
  draftState,
  { payload }
) => {
  draftState.showSimilarCompanies = payload;
};

const setIsEditingReducer: CaseReducer<ListImportState, PayloadAction<boolean>> = (
  draftState,
  { payload }
) => {
  draftState.isEditing = payload;
};

const setSimilarCompaniesReducer: CaseReducer<
  ListImportState,
  PayloadAction<ImportSimilarCompanies[]>
> = (draftState, { payload }) => {
  draftState.similarCompanies = payload;
};

const updateCanceledImportsNumberReducer: CaseReducer<ListImportState, PayloadAction<number>> = (
  draftState,
  { payload }
) => {
  draftState.canceledImportsNumber = payload;
};

const setFailedBatchesReducer: CaseReducer<ListImportState, PayloadAction<number>> = (
  draftState,
  { payload }
) => {
  draftState.failedBatches = payload;
};

const setFailedImportsOnRequestReducer: CaseReducer<
  ListImportState,
  PayloadAction<ImportedCompany[]>
> = (draftState, action) => {
  draftState.failedImportsOnRequest.push(...action.payload);
};

const removeFailedImportsOnRequestReducer: CaseReducer<ListImportState, PayloadAction<string>> = (
  draftState,
  action
) => {
  draftState.failedImportsOnRequest = draftState.failedImportsOnRequest.filter(
    ({ Company }) => Company !== action.payload
  );
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.importFavoriteList, fetchReducers.fetchReducer)
    .addCase(actions.importFavoriteListSuccess, importFavoriteListSuccessReducer)
    .addCase(actions.resetProgress, resetProgressReducer)
    .addCase(actions.updateCompleted, updateCompletedReducer)
    .addCase(actions.saveResults, saveResultsReducer)
    .addCase(actions.setLoading, setIsLoadingReducer)
    .addCase(actions.cancel, cancelReducer)
    .addCase(actions.updateImportCompanies, updateImportCompaniesReducer)
    .addCase(actions.updatePercentage, updatePercentageReducer)
    .addCase(actions.updateProgress, updateProgressReducer)
    .addCase(actions.editCompany, editCompanyReducer)
    .addCase(actions.editingCompanyIndex, editingCompanyIndexReducer)
    .addCase(actions.saveListId, saveListIdReducer)
    .addCase(actions.showSimilarCompanies, showSimilarCompaniesReducer)
    .addCase(actions.setSimilarCompanies, setSimilarCompaniesReducer)
    .addCase(actions.updateImportCompaniesIds, updateImportCompaniesIdsReducer)
    .addCase(actions.removeFailedCompamy, removeFailedCompamyReducer)
    .addCase(actions.updateCanceledImports, updateCanceledImportsReducer)
    .addCase(actions.updateCanceledImportsNumber, updateCanceledImportsNumberReducer)
    .addCase(actions.setFailedImportsOnRequest, setFailedImportsOnRequestReducer)
    .addCase(actions.removeFailedImportsOnRequest, removeFailedImportsOnRequestReducer)
    .addCase(actions.setFailedBatches, setFailedBatchesReducer)
    .addCase(actions.editCompanies, editCompaniesReducer)
    .addCase(actions.removeFailedCompamies, removeFailedCompamiesReducer)
    .addCase(actions.setIsEditing, setIsEditingReducer)
);

export default reducer;
