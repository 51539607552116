import queryString from 'query-string';
import { Dictionary, each } from 'lodash';
// models
import {
  FilterSource,
  FilterSorting,
  ParsedFilterSorting,
  PreselectedFilter,
  RangeFilter,
  BaseFilter,
  MultiRangeNumberFilter,
  MultiSelectFilter,
  MultiCheckboxFilter,
  FilterGroupDataSection,
} from '@optx/models/filters';
import Option, {
  FormCheckableRangeOption,
  SelectOption,
  FormCheckboxOption,
} from '@optx/models/Option';
import { RangeOptionBase, CheckableRangeOption } from '@optx/models/RangeOption';
// constants
import {
  RANGE_FILTER_UNKNOWN_VALUE,
  RANGE_FILTER_UNKNOWN_ONLY_VALUE,
  RANGE_FILTER_PSG_VALUE,
  RANGE_FILTER_PSG_ONLY_VALUE,
} from '../../constants/filters';
// utils
import {
  getDateRangeOptions,
  getCheckboxOptions,
  getLogicCheckboxOptions,
  getMultiRangeNumberOptions,
  getMultiTextOptions,
  getMultiRangeCustomOption,
  getCheckboxCustomOption,
} from './preselectedValues';
import { lowerRangeKey, upperRangeKey } from './rangeKey';
import { mapToSelectOption } from '../option';

const getDateRangeFilter = (
  filter: RangeFilter<null>,
  filterParsed: queryString.ParsedQuery<string>
) => {
  const defaultOptions = getDateRangeOptions(filter);
  const lowerKey = lowerRangeKey(filter.column);
  const upperKey = upperRangeKey(filter.column);

  if (filterParsed[lowerKey]) {
    defaultOptions[0].value = filterParsed[lowerKey] as string;
  }

  if (filterParsed[upperKey]) {
    defaultOptions[1].value = filterParsed[upperKey] as string;
  }

  return defaultOptions;
};

const getCheckboxFilter = (
  filter: BaseFilter<any>,
  filterParsed: queryString.ParsedQuery<string>
) => {
  const defaultOptions = getCheckboxOptions(filter, false);
  let checkboxFilters = [];
  let checkboxFilter;

  if (filterParsed[filter.column]) {
    const savedValues: Dictionary<string> = {};

    if (typeof filterParsed[filter.column] === 'string') {
      const value = filterParsed[filter.column] as string;
      savedValues[value] = value;
    } else {
      // is array
      (filterParsed[filter.column] as Array<string>).forEach(item => {
        savedValues[item] = item;
      });
    }

    checkboxFilter = defaultOptions.map(item => {
      if (item.value === savedValues[item.value]) {
        return {
          ...item,
          checked: true,
        };
      }

      return item;
    });
  } else {
    checkboxFilter = defaultOptions;
  }

  checkboxFilters.push({
    key: filter.column,
    value: checkboxFilter,
  });

  const customOption = getCheckboxCustomOption(filter as MultiCheckboxFilter);

  // custom option
  if (customOption) {
    const optionsList: string[] | null | undefined = filterParsed[filter.column] as string[];

    //  check if last option is a custom option
    const lastOption =
      optionsList && typeof optionsList === 'object'
        ? optionsList[optionsList.length - 1]
        : optionsList;

    if (lastOption) {
      // check if we have the option in default options
      const defaultItem = defaultOptions.find(item => item.value.toString() === lastOption);

      if (!defaultItem) {
        customOption.checked = true;
        customOption.value = lastOption as string;
        customOption.min = (lastOption as string).split('_')[0];
        customOption.max = (lastOption as string).split('_')[1];
      }
    }

    checkboxFilters.push({
      key: `${filter.column}_custom`,
      value: customOption,
    });
  }

  return checkboxFilters;
};

const getSelectFilter = (
  filter: BaseFilter<any>,
  filterParsed: queryString.ParsedQuery<string>
) => {
  const parsedOptions = filterParsed[filter.column] as string | Array<string> | undefined;

  if (!parsedOptions) return null;

  const options: Array<SelectOption> = [];
  const { data: allOptions, format_name: formatName } = filter as MultiSelectFilter<Array<Option>>;

  const optionByValue: Dictionary<Option> = {};
  allOptions.forEach(option => {
    optionByValue[option.value] = option;
  });

  if (typeof parsedOptions === 'string') {
    const originalOption = optionByValue[parsedOptions];

    if (originalOption) {
      options.push(mapToSelectOption(originalOption, formatName));
    }
  } else {
    // is a list
    parsedOptions.forEach(item => {
      const originalOption = optionByValue[item];

      if (originalOption) {
        options.push(mapToSelectOption(originalOption, formatName));
      }
    });
  }

  return options;
};

/**
 * Parse filter object from query string.
 * @param filterQuery filter query string.
 * @param filterSources all filter sources.
 * @returns object parsed filter
 */
export const parseFilter = (
  filterQuery: string,
  filterSources: Array<FilterSource> = []
): [Dictionary<PreselectedFilter>, string | string[] | null | undefined] => {
  let formattedFilterParsed: queryString.ParsedQuery<string>;

  const filterParsed = queryString.parse(filterQuery, {
    arrayFormat: 'comma',
  });

  if ('product_category' in filterParsed) {
    let productCategory = filterParsed.product_category;

    if (typeof productCategory === 'string') {
      productCategory = [productCategory];
    }

    const mergedCategories = (productCategory as string[]).reduce((acc: string[], curr) => {
      if (curr.startsWith(' ')) {
        const previousCategory = acc.pop();
        acc.push(previousCategory + ', ' + curr.trim());
      } else {
        acc.push(curr);
      }

      return acc;
    }, []);

    formattedFilterParsed = { ...filterParsed, product_category: mergedCategories };
  } else {
    formattedFilterParsed = filterParsed;
  }

  const finalFilter: Dictionary<PreselectedFilter> = {};
  const searchQuery = Array.isArray(filterParsed.query)
    ? filterParsed.query.join(',')
    : filterParsed.query;

  if (!filterParsed) {
    return [{}, undefined];
  }

  if ('company_id' in filterParsed) {
    finalFilter.similarCompanies = {
      label: '',
      value: filterParsed.company_id?.toString(),
    } as PreselectedFilter;
  }

  filterSources.forEach(filterSource => {
    // @ts-ignore
    filterSource.data.forEach((filter: BaseFilter<any>) => {
      switch (filter.type) {
        case 'radio': {
          // @ts-ignore
          finalFilter[filter.column] = filterParsed[filter.column] || '';
          break;
        }

        case 'date_range': {
          finalFilter[filter.column] = getDateRangeFilter(
            filter as RangeFilter<null>,
            filterParsed
          );

          break;
        }

        case 'checkbox': {
          const checkboxFilters = getCheckboxFilter(filter, filterParsed);
          checkboxFilters.forEach(cbFilter => {
            finalFilter[cbFilter.key] = cbFilter.value;
          });

          break;
        }

        case 'logic_checkbox': {
          const defaultOptions = getLogicCheckboxOptions(filter);

          if (filterParsed[filter.column]) {
            const values: Dictionary<boolean> = {};

            if (typeof filterParsed[filter.column] === 'string') {
              // single value selected
              const value = filterParsed[filter.column] as string;
              values[value] = true;
            } else if (Array.isArray(filterParsed[filter.column])) {
              (filterParsed[filter.column] as Array<string>).forEach(item => {
                values[item] = true;
              });
            }

            finalFilter[filter.column] = defaultOptions.map(option => {
              const finalOption = { ...option };

              if (values[`${option.value}-1-0`]) {
                finalOption.in = true;
              }

              if (values[`${option.value}-0-1`]) {
                finalOption.notIn = true;
              }

              return finalOption;
            });
          } else {
            finalFilter[filter.column] = defaultOptions;
          }

          break;
        }

        case 'multi_text': {
          const defaultOptions = getMultiTextOptions(filter);

          if (!filterParsed[filter.column]) {
            finalFilter[filter.column] = defaultOptions;
          } else {
            // If we have one input in filter the filter value should be just a string, otherwise array of strings.
            const values = Array.isArray(filterParsed[filter.column])
              ? (filterParsed[filter.column] as Array<string>)
              : [filterParsed[filter.column] as string];

            finalFilter[filter.column] = defaultOptions.map((option, index) => {
              if (index < values.length) {
                return {
                  ...option,
                  value: values[index],
                };
              }

              return option;
            });
          }

          break;
        }

        case 'range_input': {
          const options: Array<FormCheckableRangeOption> = [];
          const lowerKey = lowerRangeKey(filter.column);
          const upperKey = upperRangeKey(filter.column);
          const { data } = filter as RangeFilter<[RangeOptionBase, SelectOption[]]>;

          // TODO: remove after API update
          if (!data) {
            break;
          }

          const [defaultRange, dataCheck] = data;
          let check: SelectOption[] = [];

          if (Array.isArray(dataCheck)) {
            check = dataCheck;
          } else {
            check = [dataCheck];
          }

          // push default range
          options.push({
            ...defaultRange,
            range: [defaultRange.min, defaultRange.max],
            check: check ? check.map(check => ({ ...check, checked: false })) : undefined,
          });

          if (filterParsed[lowerKey]) {
            if (typeof filterParsed[lowerKey] === 'string') {
              switch (filterParsed[lowerKey]) {
                case RANGE_FILTER_UNKNOWN_VALUE: {
                  const checkIndex = options[0].check?.findIndex(
                    item => item.value === RANGE_FILTER_UNKNOWN_VALUE
                  );

                  if (checkIndex !== undefined && checkIndex !== -1) {
                    options[0].check![checkIndex].checked = true;
                  }

                  break;
                }

                case RANGE_FILTER_UNKNOWN_ONLY_VALUE: {
                  const checkIndex = options[0].check?.findIndex(
                    item => item.value === RANGE_FILTER_UNKNOWN_ONLY_VALUE
                  );

                  if (checkIndex !== undefined && checkIndex !== -1) {
                    options[0].check![checkIndex].checked = true;
                  }

                  break;
                }

                case RANGE_FILTER_PSG_VALUE: {
                  const checkIndex = options[0].check?.findIndex(
                    item => item.value === RANGE_FILTER_PSG_VALUE
                  );

                  if (checkIndex !== undefined && checkIndex !== -1) {
                    options[0].check![checkIndex].checked = true;
                  }

                  break;
                }

                case RANGE_FILTER_PSG_ONLY_VALUE: {
                  const checkIndex = options[0].check?.findIndex(
                    item => item.value === RANGE_FILTER_PSG_ONLY_VALUE
                  );

                  if (checkIndex !== undefined && checkIndex !== -1) {
                    options[0].check![checkIndex].checked = true;
                  }

                  break;
                }

                default: {
                  options[0].range[0] = Number(filterParsed[lowerKey]);
                  options[0].range[1] = Number(filterParsed[upperKey]);
                  break;
                }
              }
            } else {
              // it is a list of options
              (filterParsed[lowerKey] as Array<string>).forEach((item, index) => {
                switch (item) {
                  case RANGE_FILTER_UNKNOWN_VALUE: {
                    const checkIndex = options[0].check?.findIndex(
                      item => item.value === RANGE_FILTER_UNKNOWN_VALUE
                    );

                    if (checkIndex !== undefined && checkIndex !== -1) {
                      options[0].check![checkIndex].checked = true;
                    }

                    return;
                  }

                  case RANGE_FILTER_UNKNOWN_ONLY_VALUE: {
                    const checkIndex = options[0].check?.findIndex(
                      item => item.value === RANGE_FILTER_UNKNOWN_ONLY_VALUE
                    );

                    if (checkIndex !== undefined && checkIndex !== -1) {
                      options[0].check![checkIndex].checked = true;
                    }

                    return;
                  }

                  case RANGE_FILTER_PSG_VALUE: {
                    const checkIndex = options[0].check?.findIndex(
                      item => item.value === RANGE_FILTER_PSG_VALUE
                    );

                    if (checkIndex !== undefined && checkIndex !== -1) {
                      options[0].check![checkIndex].checked = true;
                    }

                    return;
                  }

                  case RANGE_FILTER_PSG_ONLY_VALUE: {
                    const checkIndex = options[0].check?.findIndex(
                      item => item.value === RANGE_FILTER_PSG_ONLY_VALUE
                    );

                    if (checkIndex !== undefined && checkIndex !== -1) {
                      options[0].check![checkIndex].checked = true;
                    }

                    return;
                  }

                  default:
                    break;
                }

                if (index >= 1) {
                  options.push({
                    min: defaultRange.min,
                    max: defaultRange.max,
                    range: [Number(item), Number((filterParsed[upperKey] as Array<string>)[index])],
                    check: check ? check.map(check => ({ ...check, checked: false })) : undefined,
                  });
                } else {
                  options[0].range = [
                    Number(item),
                    Number((filterParsed[upperKey] as Array<string>)[index]),
                  ];
                }
              });
            }
          }

          finalFilter[filter.column] = options;
          break;
        }

        case 'multiple_range_nr':
          {
            // @ts-ignore
            const minData: string | Array<string> =
              filterParsed[lowerRangeKey(filter.column)] || '';
            // @ts-ignore
            const maxData: string | Array<string> =
              filterParsed[upperRangeKey(filter.column)] || '';
            let minList: Array<string> = [];
            let maxList: Array<string> = [];

            if (minData && typeof minData === 'string') {
              minList = [minData];
            } else if (Array.isArray(minData)) {
              minList = minData;
            }

            if (maxData && typeof maxData === 'string') {
              maxList = [maxData];
            } else if (Array.isArray(maxData)) {
              maxList = maxData;
            }

            const parsedOptions: Dictionary<RangeOptionBase<string>> = {};

            minList.forEach((item, index) => {
              const option: RangeOptionBase<string> = {
                min: item,
                max: maxList[index],
              };

              parsedOptions[item] = option;
            });

            const defaultOptions = getMultiRangeNumberOptions(filter as MultiRangeNumberFilter);
            const customOption = getMultiRangeCustomOption(filter as MultiRangeNumberFilter);

            const finalOptions: Array<CheckableRangeOption<number>> = [];

            defaultOptions.forEach(option => {
              const finalOption = { ...option };
              const min = option.min.toString();

              if (parsedOptions[min] && parsedOptions[min].max === option.max.toString()) {
                finalOption.checked = true;
              }

              finalOptions.push(finalOption);
            });

            finalFilter[filter.column] = finalOptions;

            // custom option
            if (customOption) {
              //  check if last option is a custom option
              const lastMinOption = minList[minList.length - 1];

              if (lastMinOption) {
                const customParsedOption = parsedOptions[lastMinOption];
                // check if we have the option in default options
                const defaultItem = defaultOptions.find(
                  item => item.min.toString() === lastMinOption
                );

                if (
                  !defaultItem ||
                  (defaultItem && defaultItem.max.toString() !== customParsedOption.max)
                ) {
                  customOption.checked = true;
                  customOption.min = customParsedOption.min;
                  customOption.max = customParsedOption.max;
                }
              }

              finalFilter[`${filter.column}_custom`] = customOption;
            }
          }

          break;

        case 'endpoint_query': {
          let parsedOptions;

          if (filter.column === 'product_category') {
            parsedOptions = formattedFilterParsed[filter.column] as
              | string
              | Array<string>
              | undefined;
          } else {
            parsedOptions = filterParsed[filter.column] as string | Array<string> | undefined;
          }

          if (parsedOptions) {
            let options: Array<SelectOption> = [];

            if (typeof parsedOptions === 'string') {
              options.push({ label: parsedOptions, value: parsedOptions });
            } else {
              // is a list
              options = parsedOptions.map(item => ({ label: item, value: item }));
            }

            finalFilter[filter.column] = options;
          }

          break;
        }

        case 'multi_select':

        // eslint-disable-next-line no-fallthrough
        case 'single_select': {
          const selectOptions = getSelectFilter(filter, filterParsed);
          if (selectOptions) finalFilter[filter.column] = selectOptions;

          break;
        }

        case 'date_range_radio': {
          const parsedOptions = filterParsed[filter.column] as [string, string] | undefined;

          if (parsedOptions) {
            finalFilter[filter.column] = parsedOptions;
          }

          break;
        }

        case 'single_select_helper': {
          filter.used_by.forEach(columnName => {
            const helpedFilter = filterParsed[columnName];

            if (helpedFilter) {
              const value = {
                label: helpedFilter![helpedFilter!.length - 1],
                value: helpedFilter![helpedFilter!.length - 1],
              } as SelectOption;
              finalFilter[filter.column] = value;
            }
          });

          break;
        }

        case 'filter_group_custom': {
          const data = filter.data as FilterGroupDataSection[];

          data.forEach(section => {
            switch (section.type) {
              case 'multi_select': {
                const selectOptions = getSelectFilter(section as BaseFilter<any>, filterParsed);

                if (selectOptions) finalFilter[section.column] = selectOptions;

                break;
              }

              case 'checkbox': {
                let checkboxFilters = getCheckboxFilter(section as BaseFilter<any>, filterParsed);
                checkboxFilters.forEach(cbFilter => {
                  finalFilter[cbFilter.key] = cbFilter.value;
                });
                break;
              }

              case 'radio': {
                // @ts-ignore
                finalFilter[section.column] = filterParsed[section.column] || '';
                break;
              }

              case 'date_range': {
                finalFilter[section.column] = getDateRangeFilter(
                  section as RangeFilter<null>,
                  filterParsed
                );
                break;
              }

              default:
                break;
            }
          });

          break;
        }

        default:
          break;
      }
    });
  });

  // Handle IVC or Israel tag special case.
  if (filterParsed.is_in_israel) {
    finalFilter.is_in_israel = filterParsed.is_in_israel as string;
  }

  // Handle is platform/portfolio company special case.
  if (filterParsed.is_platform) {
    finalFilter.is_platform = filterParsed.is_platform as string;
  }

  // Handle source scrub list special case.
  if (filterParsed.ss_list_id) {
    finalFilter.ss_list_id = filterParsed.ss_list_id as string;
  }

  // Handle my watchlist list special case.
  if (filterParsed.saved_list_id) {
    finalFilter.saved_list_id = filterParsed.saved_list_id as string;
  }

  // Handle parent company special case.
  if (filterParsed.parentcompany) {
    const formatedValue: string =
      filterParsed.parentcompany instanceof Array
        ? filterParsed.parentcompany.join()
        : (filterParsed.parentcompany as string);
    finalFilter.parentcompany = formatedValue;
  }

  // Handle query special case.
  if (filterParsed.query) {
    const formatedValue: string =
      filterParsed.query instanceof Array
        ? filterParsed.query.join()
        : (filterParsed.query as string);
    finalFilter.query = formatedValue;
  }

  // fix for special case for ebitda_numeric, it has another parameter,
  // ebitda_string, which contains extra checkbox values
  if (filterParsed.ebitda_string) {
    const checkboxes = (finalFilter.ebitda_numeric as FormCheckableRangeOption[])[0]
      .check as FormCheckboxOption[];

    const ebitdaString: string[] =
      typeof filterParsed.ebitda_string === 'string'
        ? filterParsed.ebitda_string.split(',')
        : (filterParsed.ebitda_string as string[]);

    ebitdaString.forEach(value => {
      const index = checkboxes?.findIndex(checkbox => checkbox.value === value);

      if (index && index > -1) {
        checkboxes[index].checked = true;
      }
    });
  }

  // handle special case for next and last touch date (hidden from filters modal)
  if (filterParsed.next_touch_date) {
    finalFilter.next_touch_date = filterParsed.next_touch_date as string[];
  }

  if (filterParsed.last_touch_date) {
    finalFilter.last_touch_date = filterParsed.last_touch_date as string[];
  }

  // this is a hidden filter requested by API
  if (filterParsed.addon_bool) {
    finalFilter.addon_bool = filterParsed.addon_bool as string;
  }

  return [finalFilter, searchQuery];
};

export const parseFilterSorting = (
  filterParsed: Dictionary<Array<string>>,
  filterSources: Array<FilterSource> = []
) => {
  const sorting = [] as Array<FilterSorting>;
  const parsedSorting = [] as Array<ParsedFilterSorting>;

  if (
    typeof filterParsed.sort_fields === 'string' &&
    typeof filterParsed.sort_orders === 'string'
  ) {
    sorting.push({
      id: filterParsed.sort_fields,
      desc: filterParsed.sort_orders === 'asc' ? 'Ascending' : 'Descending',
    });
  } else {
    filterParsed.sort_fields.forEach((sortField: string, index: number) => {
      sorting.push({
        id: sortField,
        desc: filterParsed.sort_orders[index] === 'asc' ? 'Ascending' : 'Descending',
      });
    });
  }

  sorting.forEach((sortItem: FilterSorting, index: number) => {
    filterSources.forEach(filterSource => {
      each(filterSource.data, filter => {
        if (
          sortItem.id === filter.column ||
          (sortItem.id === 'date_presented' && filter.column === 'date_presented_group')
        ) {
          parsedSorting.push({ name: filter.label, order: sorting[index].desc });

          return false;
        }
      });
    });
  });

  return parsedSorting;
};

// eslint-disable-next-line consistent-return
export const parseFiltersKeys = (item: string): string | undefined => {
  // Rank
  if (item.startsWith('score')) {
    return 'OPTX Score';
  }

  if (item.startsWith('il_optx_score')) {
    return 'IL OPTX Score';
  }

  if (item.startsWith('company_id')) {
    return 'Similar Companies';
  }

  if (item.startsWith('pipeline_rank')) {
    return 'Pipeline Rank';
  }

  if (item.startsWith('sw_website_rank_per_country')) {
    return 'US Website Rank';
  }

  if (item.startsWith('sw_website_rank')) {
    return 'WW Website Rank';
  }

  if (item.startsWith('is_interesting')) {
    return 'PSG Fit';
  }

  if (item.startsWith('url_status')) {
    return 'Website';
  }

  if (item.startsWith('is_on_linkedin')) {
    return 'Has Linkedin Profile';
  }

  if (item.startsWith('g2_rating')) {
    return 'G2Crowd Rating';
  }

  if (item.startsWith('g2_review_count')) {
    return 'G2Crowd Reviews';
  }

  if (item.startsWith('ct_rating')) {
    return 'Capterra Rating';
  }

  if (item.startsWith('ct_review_count')) {
    return 'Capterra Reviews';
  }

  if (item.startsWith('cb_rank')) {
    return 'CrunchBase Rank';
  }

  // Company Background
  if (item.startsWith('is_software_verified')) {
    return 'Verified Software Companies';
  }

  if (item.startsWith('is_software')) {
    return 'Show Software Companies';
  }

  if (item.startsWith('is_ai_ml')) {
    return 'Show AI / ML Companies';
  }

  if (item.startsWith('num_employees')) {
    return 'Number of Employees';
  }

  if (item.startsWith('is_company_reviewed')) {
    return 'Show Reviewed Companies';
  }

  if (item.startsWith('emp_growth_percent')) {
    return 'Employee Growth Percentage Y/Y';
  }

  if (item.startsWith('job_openings_percent')) {
    return 'Job Openings (%)';
  }

  if (item.startsWith('job_openings')) {
    return 'Job Openings';
  }

  if (item.startsWith('country')) {
    return 'Country';
  }

  if (item.startsWith('state')) {
    return 'State/Province';
  }

  if (item.startsWith('city')) {
    return 'Town/City';
  }

  if (item.startsWith('geo_range')) {
    return 'Geographic Range';
  }

  if (item.startsWith('year_founded')) {
    return 'Year Founded';
  }

  if (item.startsWith('company_type')) {
    return 'Company Type';
  }

  if (item.startsWith('opportunity_type')) {
    return 'Opportunity Type';
  }

  if (item.startsWith('product_category')) {
    return 'Product Category';
  }

  if (item.startsWith('sector')) {
    return 'Sector';
  }

  if (item.startsWith('sub_sector')) {
    return 'Sub Sector';
  }

  if (item.startsWith('sub_vertical')) {
    return 'Sub Vertical';
  }

  if (item.startsWith('has_parent')) {
    return 'Has Parent Company';
  }

  if (item.startsWith('diversity')) {
    return 'Diversity Spotlight (US Only)';
  }

  // Financial & Stages
  if (item.startsWith('raise_date')) {
    return 'Last Funding Round Date';
  }

  if (item.startsWith('last_round')) {
    return 'Last Round';
  }

  if (item.startsWith('capital_raised')) {
    return 'Total Raised ($)';
  }

  if (item.startsWith('last_rev_update_amount')) {
    return 'Revenue ($)';
  }

  if (item.startsWith('ebitda_numeric') || item.startsWith('ebitda_string')) {
    return 'EBITDA ($)';
  }

  if (item.startsWith('fund')) {
    return 'Fund';
  }

  if (item.startsWith('next_steps')) {
    return 'Next Steps';
  }

  if (item.startsWith('stage')) {
    return 'Stage';
  }

  if (item.startsWith('last_valuation')) {
    return 'Valuation';
  }

  if (item.startsWith('last_arr_value')) {
    return 'ARR ($)';
  }

  if (item.startsWith('last_gross_margin')) {
    return 'GM %';
  }

  if (item.startsWith('last_rev_growth')) {
    return 'Growth %';
  }

  if (item.startsWith('last_raised_amount')) {
    return 'Last Raised Amount';
  }

  // Data & Management
  if (item.startsWith('source_tag_filter')) {
    return 'Data Source Presence';
  }

  if (item.startsWith('company_owner_id')) {
    return 'Company Owner (Analyst)';
  }

  if (item.startsWith('has_date_presented')) {
    return 'Has Date Presented';
  }

  if (item.startsWith('created_on_optx')) {
    return 'Date Created';
  }

  if (item.startsWith('senior_advisor_thesis')) {
    return 'Senior Advisor Thesis';
  }

  if (item.startsWith('lead_source')) {
    return 'Lead Source';
  }

  if (item.startsWith('last_touch_date')) {
    return 'Last Touch';
  }

  if (item.startsWith('last_touch_initiator')) {
    return 'Last Touch Initiator';
  }

  if (item.startsWith('next_touch_date')) {
    return 'Future Touch';
  }

  if (item.startsWith('next_touch_initiator')) {
    return 'Future Touch Initiated By';
  }

  if (item.startsWith('addon')) {
    return 'Add-on';
  }

  if (item.startsWith('active')) {
    return 'Active on Pipeline Companies';
  }

  if (item.startsWith('last_automatic_data_update') || item.startsWith('last_manual_data_update')) {
    return 'Last Data Update';
  }

  // Handle company profile special tags applying
  if (item.startsWith('company_tag')) {
    return 'Tags';
  }

  if (item.startsWith('is_platform')) {
    return 'Platform Company';
  }

  // Handle IVC or Israel special tag applying
  if (item.startsWith('is_in_israel')) {
    return 'IVC or Israel';
  }

  // Handle Touch Type Filters
  if (item.startsWith('completed_touch_option')) {
    return 'Completed Touch Option';
  }

  if (item.startsWith('completed_touch_type')) {
    return 'Completed Touch Type';
  }

  if (item.startsWith('completed_touch_type_date')) {
    return 'Completed Touch Type Date';
  }

  if (item.startsWith('completed_touch_date_lower')) {
    return 'Completed Touch Date Lower';
  }

  if (item.startsWith('completed_touch_date_upper')) {
    return 'Completed Touch Date Upper';
  }

  if (item.startsWith('completed_touch')) {
    return 'Completed Touch Type(s)';
  }

  if (item.startsWith('schedule_touch_type')) {
    return 'Scheduled Touch Type';
  }

  if (item.startsWith('schedule_touch_type_date')) {
    return 'Scheduled Touch Type Date';
  }

  if (item.startsWith('schedule_touch_date_upper')) {
    return 'Scheduled Touch Date Upper';
  }

  if (item.startsWith('schedule_touch_date_lower')) {
    return 'Scheduled Touch Date Lower';
  }

  if (item.startsWith('schedule_touch')) {
    return 'Scheduled Touch Type(s)';
  }
};
