import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import { FilterGroup } from '@models/filters';
import { getErrorMessage } from '@utils/api/errors';
import { FilterService } from '@services/api';
import NotificationService from '@services/NotificationService';
import { actions } from '../actions';

export function* fetchFiltersSaga() {
  try {
    const res: AxiosResponse = yield call(
      FilterService.getOptions,
      FilterGroup.EquityTouchCompanies
    );
    yield put(actions.filters.fetchSuccess(res.data.filters));
  } catch (error: any) {
    const errorMessage = getErrorMessage(
      error,
      'Failed to fetch filters for EquityTouch companies!'
    );

    yield put(actions.filters.fetchFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* filtersSaga() {
  yield takeLatest(actions.filters.fetch, fetchFiltersSaga);
}
