import { createReducer } from '@reduxjs/toolkit';
// models
import { InsightsDataState, DefaultState } from '../interfaces';
// redux
import { actions } from '../actions';
import { fetchReducer, fetchFailReducer, fetchSuccessReducer } from '@redux/feature/fetch/reducers';

export const initialState: DefaultState<InsightsDataState> = {
  data: null,
  loading: false,
  error: null,
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.insights.fetchInsightsStats, fetchReducer)
    .addCase(actions.insights.fetchInsightsStatsSuccess, fetchSuccessReducer)
    .addCase(actions.insights.fetchInsightsStatsFail, fetchFailReducer)
);

export default reducer;
