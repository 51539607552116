import React from 'react';
import moment from 'moment';
// models
import { TableCellPipelineReport } from '@optx/models/table/Cell';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import globalConfig from '@optx/constants/config';
import { ISO_DATE_FORMAT } from '@optx/constants/format/date';

const TableCellDate: React.FC<TableCellPipelineReport> = ({ value }) => {
  const isValid = moment(value, ISO_DATE_FORMAT).isValid();

  if (value === 'TBD') return <>TBD</>;
  if (!isValid) return <>{DEFAULT_EMPTY_VALUE}</>;

  return <>{moment(value, ISO_DATE_FORMAT).format(globalConfig.grid_date.DATE_FORMAT)}</>;
};

export default TableCellDate;
