import React from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '../../state';
import { TypographyTitle } from '../styled';

export const Title = () => {
  const count = useSelector(selectors.pipelineInformation.selectCount);
  const countText = `${count} ${count !== 1 ? 'Companies' : 'Company'}`;
  const text = `Pipeline (${countText})`;

  return <TypographyTitle level={4}>{text}</TypographyTitle>;
};
