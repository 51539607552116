import numeral from 'numeral';

function numeralOrdinal(number: number) {
  const b = number % 10;
  let numeral = '';

  if (Math.floor((number % 100) / 10) === 1) {
    numeral = 'th';
  } else if (b === 1) {
    numeral = 'st';
  } else if (b === 2) {
    numeral = 'nd';
  } else if (b === 3) {
    numeral = 'rd';
  } else {
    numeral = 'th';
  }

  return numeral;
}

numeral.register('locale', 'us', {
  delimiters: { thousands: ',', decimal: '.' },
  abbreviations: { thousand: 'K', million: 'M', billion: 'B', trillion: 'T' },
  ordinal: numeralOrdinal,
  currency: { symbol: '$' },
});

numeral.locale('us');
