// initialization for reminders
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import reducer, { saga } from '../../../redux/company/primary-contacts';

const useInjectPrimaryContacts = () => {
  useInjectReducer({ key: 'primaryContacts', reducer });
  useInjectSaga({ key: 'primaryContacts', saga });
};

export default useInjectPrimaryContacts;
