import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';
import PopoverComponent from '@optx/shared/view/molecules/Popover';

const Icon = styled(InfoCircleOutlined)`
  color: #6d8fb9;
  opacity: 0.8;
  font-size: 21px;
  margin-left: auto;
`;

const Popover = styled(PopoverComponent)`
  max-width: 500px;

  &.ant-popover-placement-rightTop,
  &.ant-popover-placement-leftTop {
    .ant-popover-arrow {
      top: 51px;
    }
  }
`;

const PopoverTitle = styled.span`
  white-space: nowrap;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  /* Gray / gray-9 */
  color: #262626;
`;

const PopoverContentDescription = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* Gray / gray-8 */
  color: #595959;
  margin: 0;

  b {
    /* color: #1890ff; */
  }
`;

export default {
  Icon,
  Popover,
  PopoverTitle,
  PopoverContentDescription,
};
