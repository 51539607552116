import React from 'react';
import { Tooltip } from 'antd';
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
import { convertToURL } from '@optx/utils/url';

export const TableCellLink = (value: any) => {
  if (!value) {
    return <>{DEFAULT_CELL_VALUE}</>;
  }

  return (
    <Tooltip title={value}>
      <a href={convertToURL(value)} target="_blank" rel="noopener noreferrer">
        {value}
      </a>
    </Tooltip>
  );
};

// @ts-ignore
export default TableCellLink;
