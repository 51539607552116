import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
// redux
import { actions as listsActions } from '@redux/favorite-lists/lists';
// components
import SearchInput, { SearchInputProps } from '../../../common/form/input/SearchInput';

interface CompanySearchProps extends Omit<SearchInputProps, 'onSearch' | 'value'> {}

const MyWatchlistsSearch: React.FC<CompanySearchProps> = ({ ...restProps }) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');

  const search = useCallback(
    (searchKey: string) => {
      dispatch(listsActions.fetchFavoriteLists({ query: searchKey, showEmptyLists: false }));
      setQuery('');
    },
    [dispatch]
  );

  const handleSearch = (searchKey: string) => {
    search(searchKey);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  return (
    <SearchInput
      {...restProps}
      onChange={handleChange}
      onSearch={handleSearch}
      value={query}
      placeholder="Search for My Watchlists by keyword"
    />
  );
};

export default MyWatchlistsSearch;
