import React, { useCallback } from 'react';
import {
  ResponsiveContainer,
  ResponsiveContainerProps,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  Legend,
  Tooltip,
  YAxis,
  LegendValueFormatter,
} from 'recharts';
import { useSelector } from 'react-redux';
// models
import { LegendValue } from '@optx/models/feature/analystsLeaderboard/AnalystsLeaderboardResponse';
import { AppState } from '@optx/redux/interfaces';
// components
import {
  xAxisProps,
  yAxisProps,
  lineProps as defaultLineProps,
  legendProps,
  cartesianGridProps,
} from '@optx/components/common/charts/LineChart/config';
import EmptyChart from '@optx/components/common/charts/EmptyChart';
import { LegendText } from '@optx/components/common/charts/LineChart/LegendFormatter/styles';
import { ChartIntervalOption } from '@optx/models/feature/analystsLeaderboard/chart';
// utils
import { formatDate } from '@optx/utils/format';
// redux
import { selectors } from '../../state';
// components
import LeaderboardChartTooltip from './LeaderboardChartTooltip';

interface LeaderboardChartProps extends Omit<ResponsiveContainerProps, 'children'> {}

type OptionKey = keyof ChartIntervalOption;

const LeaderboardChart = (props: LeaderboardChartProps) => {
  const data = useSelector(selectors.getChartInfo);
  const lines = useSelector((state: AppState) =>
    selectors.filters.getChartLines(state, 'startdatetime')
  );

  const searchData = useSelector(selectors.search.getData);

  // Add formatting for date interval in component in order to use proper date label in tooltip.
  const legendFormatter: LegendValueFormatter = useCallback(
    (value, entry) => {
      const periodLabel = (value as string).toUpperCase();
      let text = periodLabel;

      if (searchData) {
        // @ts-ignore
        if (entry.dataKey === 'previousPeriod') {
          text = getLegendInterval(periodLabel, searchData.legend.previous);
        } else {
          text = getLegendInterval(periodLabel, searchData.legend.current);
        }
      }

      return <LegendText>{text}</LegendText>;
    },
    [searchData]
  );

  if (!data || !data.length) {
    return <EmptyChart />;
  }

  return (
    <ResponsiveContainer width="100%" height={300} {...props}>
      <LineChart data={data} margin={{ bottom: 20 }} style={{ margin: '20px 0 0' }}>
        <CartesianGrid {...cartesianGridProps} />
        <XAxis {...xAxisProps} dataKey={'selected_date' as OptionKey} />
        <YAxis {...yAxisProps} />
        <Tooltip content={LeaderboardChartTooltip} filterNull={false} />
        {data && data.length && <Legend formatter={legendFormatter} {...legendProps} />}

        {lines.map(lineProps => (
          <Line key={lineProps.dataKey as string} {...defaultLineProps} {...lineProps} />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

function getLegendInterval(period: string, legendOption: LegendValue) {
  return `${period}: ${formatDate(legendOption.start_date, 'shortDate')} - ${formatDate(
    legendOption.end_date,
    'shortDate'
  )}`;
}

export default LeaderboardChart;
