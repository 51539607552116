import React from 'react';
import Overflow from 'rc-overflow';
import styled from 'styled-components';
// models
import { TagOption } from '@optx/models/tags';
import Company from '@optx/models/Company';
// utils
import { getIsPsgCompany, getTags } from '@optx/utils/company';
// components
import {
  CompanyTagMore,
  CompanyTagValue,
  IsPortfolioTag,
  IsSoftwareTag,
  IsPSGCompanyTag,
  AddTags,
} from '@optx/features/tags/tags';
import PSGFit from '@optx/components/pages/CompanyProfile/ProfileBanner/PSGFit';
import IsAIMlTag from '../tags/IsAIMl';

const OverflowTagsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 0;

  .special-tag {
    font-size: 12px;
    background: #d6e4ff;
    /* Dark BLue */
    color: #334d6e;
  }
`;

const NormalWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1;
`;

interface CompanyTagsOverflowProps {
  company: Company;
  companyId?: number | boolean;
  isNewTab?: boolean;
  isAdd?: boolean;
  closable?: boolean;
  onTagMoreClick?: VoidFunction;
  isCollapsed?: boolean;
  showPSGFit?: boolean;
}

interface Tag {
  label: string;
  value: string;
  closable: boolean;
  className: string;
}

const CompanyTagsOverflow: React.FC<CompanyTagsOverflowProps> = ({
  company,
  companyId,
  isNewTab = false,
  isAdd = false,
  closable = false,
  onTagMoreClick,
  isCollapsed,
  showPSGFit,
}) => {
  const companyProfileTags = getTags(company.specialties);

  const companyTag: Tag[] = company.cs_tags
    ? company.cs_tags
        .split('|')
        .slice(1)
        .filter(item => item !== '­' && /\S/.test(item))
        .map(item => ({
          label: item,
          value: item,
          closable: false,
          className: '',
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    : [];

  const diversityTags: Tag[] = company.diversity
    ? company.diversity.map(item => ({
        label: item,
        value: item,
        closable: false,
        className: 'special-tag show-child',
      }))
    : [];

  const allTags = closable
    ? [...diversityTags, ...companyProfileTags, ...companyTag]
    : [...diversityTags, ...companyTag];

  let uniqueTags = Array.from(new Set(allTags.map(item => item.label))).map(item => ({
    label: item,
    value: allTags.find(a => a.label === item)?.value,
    closable: allTags.find(a => a.label === item)?.closable,
    className: allTags.find(a => a.label === item)?.className,
    type: 'regular',
  }));

  /**
   * Add Platform | Software | PSG Company tags to overflow container
   */

  if (company.is_platform) {
    uniqueTags.unshift({
      label: 'Platform',
      value: 'Platform',
      closable: false,
      className: 'special-tag',
      type: 'platform',
    });
  }

  if (company.is_ai_ml) {
    uniqueTags.unshift({
      label: 'AI / ML',
      value: 'AI / ML',
      closable: false,
      className: 'special-tag ',
      type: 'companyAiMl',
    });
  }

  if (company.is_software) {
    uniqueTags.unshift({
      label: 'Software',
      value: 'Software',
      closable: false,
      className: 'special-tag ',
      type: 'software',
    });
  }

  if (getIsPsgCompany(company)) {
    uniqueTags.unshift({
      label: 'PSG Company',
      value: 'PSG Company',
      closable: false,
      className: 'special-tag',
      type: 'psgCompany',
    });
  }

  return (
    <OverflowTagsWrapper>
      <NormalWrapper>
        <Overflow
          data={uniqueTags && (uniqueTags as TagOption[])}
          renderRawItem={item => {
            switch (item.type) {
              case 'software':
                return (
                  <Overflow.Item component="span">
                    <IsSoftwareTag newTab={isNewTab} isSoftware={company.is_software} />
                  </Overflow.Item>
                );
              case 'companyAiMl':
                return (
                  <Overflow.Item component="span">
                    <IsAIMlTag newTab={isNewTab} isAiMl={company.is_ai_ml} />
                  </Overflow.Item>
                );
              case 'platform':
                return (
                  <Overflow.Item component="span">
                    <IsPortfolioTag newTab={isNewTab} isPortfolio={company.is_platform}>
                      Platform Company
                    </IsPortfolioTag>
                  </Overflow.Item>
                );
              case 'psgCompany':
                return (
                  <Overflow.Item component="span">
                    <IsPSGCompanyTag newTab={isNewTab} isCompany={true} />
                  </Overflow.Item>
                );
              case 'regular':
              default:
                return (
                  <CompanyTagValue
                    companyId={companyId as number}
                    item={item}
                    key={item.value}
                    isNewTab={isNewTab}
                  />
                );
            }
          }}
          renderRest={items => (
            <CompanyTagMore
              count={items.length}
              isNewTab={isNewTab}
              onTagMoreClick={onTagMoreClick}
            />
          )}
          maxCount="responsive"
          style={{ justifyContent: 'flex-start' }}
          suffix={
            isAdd && (
              <div className="add-tags-suffix">
                <AddTags
                  value={uniqueTags.map(item => ({
                    label: item.label,
                    value: item.label,
                    disabled: true,
                  }))}
                  companyId={companyId as number}
                  isCollapsed={isCollapsed}
                />
                {showPSGFit && <PSGFit isFromHistory={false} />}
              </div>
            )
          }
        />
      </NormalWrapper>
    </OverflowTagsWrapper>
  );
};

export default CompanyTagsOverflow;
