import { useEffect } from 'react';
import { useHistory } from 'react-router';

/**
 * Custom hook that navigates to a company's profile page when the company name is clicked in a table.
 *
 * This hook attaches a click event listener to the table body of an Ant Design table. When a cell
 * containing a company name is clicked, the user is navigated to the corresponding company's profile page.
 * The behavior of the navigation depends on the type of grid specified.
 *
 * @param {'advancedSearch' | 'myCompanies' | 'outreach'} gridType - The type of grid in which the table is displayed.
 *    - 'advancedSearch': For advanced search results.
 *    - 'myCompanies': For the user's companies list.
 *    - 'outreach': For outreach-related companies.
 *
 * @example
 * // Usage in a functional component
 * const MyComponent = () => {
 *   useNavigateOnCompanyNameClick('myCompanies');
 *   return <TableComponent />;
 * };
 *
 * @returns {void} This hook does not return any value.
 */
const useNavigateOnCompanyNameClick = (gridType: 'advancedSearch' | 'myCompanies' | 'outreach') => {
  const history = useHistory();
  useEffect(() => {
    const tableBody = document.querySelector('.ant-table-tbody');

    if (tableBody && gridType) {
      const handleClick = (event: Event) => {
        const target = event.target as HTMLTableDataCellElement;

        const isCompanyNameCell =
          (target.classList.contains('ant-table-cell') &&
            target.classList.contains('company_name')) ||
          (gridType === 'outreach' && target.classList.contains('ant-table-cell-content'));

        if (isCompanyNameCell && !target.classList.contains('ant-table-selection-column')) {
          const id =
            (target.parentElement as HTMLTableRowElement).getAttribute('data-row-key') ||
            target.parentElement?.parentElement?.getAttribute('data-row-key');

          if (id) {
            history.push(`/profile/${id}`);
          }
        }
      };

      tableBody.addEventListener('click', handleClick, false);

      return () => {
        tableBody.removeEventListener('click', handleClick, false);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useNavigateOnCompanyNameClick;
