import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// models
import { RadioDateRangeFilterColumns } from '@optx/models/feature/analystsLeaderboard/filters';
import { DateRangeOption } from '@optx/models/Option';
import { AppState } from '@optx/redux/interfaces';
// components
import SingleSelectDateRanges from '@components/common/filters/SingleSelectDateRanges';
// Local
// redux
import { selectors, actions } from '../state';

interface FilterSingleSelectDateRangesProps {
  filterKey: RadioDateRangeFilterColumns;
}

const FilterSingleSelectDateRanges: React.FC<FilterSingleSelectDateRangesProps> = ({
  filterKey,
}) => {
  const dispatch = useDispatch();

  const handleChange = (option: DateRangeOption) => {
    dispatch(
      actions.filters.updateValue({
        value: [option.start, option.end],
        filterKey,
      })
    );
  };

  const options = useSelector((state: AppState) =>
    selectors.filters.getSingleDateRangesOptions(state, filterKey)
  );
  const value = useSelector((state: AppState) =>
    selectors.filters.getSingleDateRangesValue(state, filterKey)
  );

  return <SingleSelectDateRanges value={value} data={options} onChange={handleChange} />;
};

export default FilterSingleSelectDateRanges;
