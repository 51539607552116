import { fork } from 'redux-saga/effects';
import { saga as companyProfileSaga } from '@redux/company/profile';
import { saga as companyRevenueGrowthSaga } from '@redux/company/revenue-growth';
import { sagas as tagsSagas } from '@redux/company/tags';

/**
 * Root saga manages watcher lifecycle
 */
export default function* profileSaga() {
  yield fork(companyProfileSaga);
  yield fork(companyRevenueGrowthSaga);
  yield fork(tagsSagas);
}
