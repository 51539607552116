import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { CompanyNotification } from '@optx/models/Company';
import * as actions from './actions';
import { actions as searchNotesActions } from '../search';
import { fetchReducer, fetchFailReducer } from '../../../feature/fetch/reducers';
import { CompanyNotesState } from './interfaces';

const initialState: CompanyNotesState = {
  error: '',
  loading: false,
  companyNotes: {},
  notesById: {},
};

const fetchCompanyNotesSuccessReducer: CaseReducer<
  CompanyNotesState,
  PayloadAction<Array<CompanyNotification>, any, number>
> = (draftState, action) => {
  const companyId = action.meta;
  const notes = action.payload;

  draftState.loading = false;
  draftState.companyNotes[companyId] = [];

  notes.forEach(note => {
    draftState.companyNotes[companyId].push(note.note_unique_id);
    draftState.notesById[note.note_unique_id] = note;
  });
};

const addCompanyNoteSuccessReducer: CaseReducer<
  CompanyNotesState,
  PayloadAction<CompanyNotification>
> = (draftState, action) => {
  const note = action.payload;

  if (Object.keys(draftState.companyNotes).length) {
    draftState.companyNotes[note.company_id] = [
      note.note_unique_id,
      ...draftState.companyNotes[note.company_id],
    ];
  }

  if (Object.keys(draftState.companyNotes).length) {
    draftState.notesById[note.note_unique_id] = note;
  }

  draftState.loading = false;
};

const deleteCompanyNoteSuccessReducer: CaseReducer<CompanyNotesState, PayloadAction<number>> = (
  draftState,
  action
) => {
  draftState.loading = false;

  if (draftState.notesById[action.payload]) {
    const note = draftState.notesById[action.payload];
    const companyNotes = draftState.companyNotes[note.company_id];
    const noteIndex = companyNotes.findIndex(noteId => noteId === note.note_unique_id);

    delete draftState.notesById[action.payload];
    companyNotes.splice(noteIndex, 1);
  }
};

const updateNoteReducer: CaseReducer<
  CompanyNotesState,
  PayloadAction<CompanyNotification, any, string>
> = (draftState, action) => {
  const { payload: note, meta: text } = action;
  const currentNote = draftState.notesById[note.note_unique_id];

  if (currentNote) {
    currentNote.note_text = text;
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.fetchCompanyNotes, fetchReducer)
    .addCase(actions.fetchCompanyNotesSuccess, fetchCompanyNotesSuccessReducer)
    .addCase(actions.fetchCompanyNotesFail, fetchFailReducer)
    .addCase(actions.addCompanyNote, fetchReducer)
    .addCase(actions.addCompanyNoteSuccess, addCompanyNoteSuccessReducer)
    .addCase(actions.addCompanyNoteFail, fetchFailReducer)
    .addCase(actions.deleteCompanyNote, fetchReducer)
    .addCase(actions.deleteCompanyNoteSuccess, deleteCompanyNoteSuccessReducer)
    .addCase(actions.deleteCompanyNoteFail, fetchFailReducer)
    .addCase(searchNotesActions.updateNote, updateNoteReducer)
);

export default reducer;
