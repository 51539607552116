import React, { useState, useEffect } from 'react';
// components
import APP_ROUTES from '@constants/routes';
import { useHistory } from 'react-router-dom';
import { Tabs } from 'antd';
import {
  getIsAnalyst,
  getHasAccessToPipelineReport,
  getHasAccessToSourcingOutreachSummary,
  getHasAccessToChecklistResearchInsightsReport,
  isLoading,
} from '@optx/redux/user/information/selectors';
import { useSelector } from 'react-redux';
import PageLoader from './PageLoader';
import Styled from './AllReports.styled';
import { Header } from './components/Header';
import ReportCards from './components/Card/ReportCards';
import ReportBreadcrumb from './components/ReportBreadcrumb';

interface ReportTabsProps {
  name: string;
  content: React.ReactNode;
  total?: number | string;
  className?: string;
  showUniversalSearchBar?: boolean;
  hasTotal?: boolean;
  path: string;
}

const { TabPane } = Tabs;

const fixURLLastCharError = (url: string) => {
  if (url.slice(-1) !== '/') return url;

  return url.substring(0, url.length - 1);
};

const AllReports: React.FC = () => {
  const isAnalyst = useSelector(getIsAnalyst);
  const showPipelineReport = useSelector(getHasAccessToPipelineReport);
  const showSourcingOutreachSummary = useSelector(getHasAccessToSourcingOutreachSummary);
  const showChecklistResearchInsightsReport = useSelector(
    getHasAccessToChecklistResearchInsightsReport
  );
  const loading = useSelector(isLoading);

  const history = useHistory();

  const defaultPath = isAnalyst ? APP_ROUTES.defaultReports : APP_ROUTES.reports;

  const getTotal = (obj: any) => {
    let cardsToHide = 0;

    if (!showPipelineReport) {
      cardsToHide += 1;
    }

    if (!showChecklistResearchInsightsReport) {
      cardsToHide += 1;
    }

    if (!showSourcingOutreachSummary) {
      cardsToHide += 2;
    } else {
      cardsToHide += 1;
    }

    return Object.keys(obj).length - 1 - cardsToHide;
  };

  const reportTabs: Array<ReportTabsProps> = [
    {
      name: 'All Reports',
      content: <ReportCards />,
      className: 'list-tab',
      total: getTotal(defaultPath),
      showUniversalSearchBar: true,
      hasTotal: true,
      path: defaultPath.allReports,
    },
  ];

  const pathname = fixURLLastCharError(history.location.pathname);

  const initialTab = reportTabs.find(tab => tab.path === pathname)!;
  const [tab, setTab] = useState(initialTab);

  const onChangeTab = (activeKey: string) => {
    history.push(activeKey);
  };

  useEffect(() => {
    const tabItem = reportTabs.find(tab => tab.path === pathname)!;
    setTab(tabItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <Header />
          <Styled.Wrapper>
            <Styled.MainContent className="main-content">
              <ReportBreadcrumb className="report-breadcrumb" />
              <Styled.PageTitle>{tab.name}</Styled.PageTitle>
              <Tabs onChange={onChangeTab} activeKey={pathname}>
                {reportTabs.map((tab: ReportTabsProps) => (
                  <TabPane
                    tab={tab.hasTotal ? `${tab.name} (${tab.total})` : tab.name}
                    key={tab.path}
                    className={tab.className}
                  >
                    {tab.content}
                  </TabPane>
                ))}
              </Tabs>
            </Styled.MainContent>
          </Styled.Wrapper>
        </>
      )}
    </>
  );
};

export default AllReports;
