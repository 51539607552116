import React, { useState } from 'react';
import { Button } from 'antd';
import { SalesloftAddContact } from '@optx/screens/App/SalesloftBulk/components/salesloft/SalesloftAddContact';
import { useSelector } from 'react-redux';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { CompanyProfile } from '@optx/models/Company';

const AddContactBulkEquityTouch: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const companyData = useSelector(
    bulkActionsSelectors.equityTouch.getCompanyData
  ) as CompanyProfile;

  const contactData = {
    company_id: companyData.company_id,
    company_name: companyData.company_name,
    company_url: companyData.company_url,
  };

  return (
    <>
      <Button
        onClick={() => {
          setVisible(true);
        }}
        disabled={companyData.is_in_et}
      >
        {' '}
        Add Contact
      </Button>
      <SalesloftAddContact
        visible={visible}
        contactData={contactData}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </>
  );
};

export default AddContactBulkEquityTouch;
