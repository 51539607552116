export interface ReferrerUrlParam {
  referrer?: string;
}

export type RouteAliases =
  | 'advancedSearch'
  | 'myCompanies'
  | 'companyOutReach'
  | 'pipelineReport'
  | 'userLists'
  | 'allReports'
  | 'sourceScrubLists'
  | 'companyProfile'
  | 'watchList'
  | 'chromeExtension'
  | 'companyAddonManagement'
  | 'contacts'
  | 'companyFiles'
  | 'companyHistory'
  | 'checklistResearchInsights';

export enum ReferrerUrlOrigin {
  parentCompanySearch = 'parentCompanySearch',
}
