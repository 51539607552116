import styled from 'styled-components';
import { Divider as dividerComp } from 'antd';

const CreateListWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;

  button {
    &.btn-create-list {
      &-closed {
        height: max-content;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
`;

const Divider = styled(dividerComp)`
  margin: 30px 0 20px -30px;
  width: calc(100% + 60px);
`;

export const Styled = {
  CreateListWrapper,
  Divider,
};
