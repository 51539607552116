import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// models
import { DateRangeOption } from '@optx/models/Option';
// redux
import SingleSelectDateRanges from '@optx/components/common/filters/SingleSelectDateRanges';
import { selectors as CompaniesETSelectors, actions as CompaniesETAction } from '../state';
// components

export const FilterSingleSelectDateFilter = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState<[string | null, string | null]>(['', '']);
  const [options, setOptions] = useState<DateRangeOption[]>([]);
  const {
    normalized: { date_added_et: normalizedData },
  } = useSelector(CompaniesETSelectors.filters.getFilters);
  const { date_added_et: valueDate } = useSelector(CompaniesETSelectors.filters.getValues);

  useEffect(() => {
    valueDate && setValue(valueDate as [string | null, string | null]);
  }, [valueDate]);

  useEffect(() => {
    normalizedData && setOptions(normalizedData.data as any);
  }, [normalizedData]);

  const handleChange = (value: any) => {
    dispatch(
      CompaniesETAction.filters.updateValue({
        value: [value.start, value.end],
        key: 'date_added_et',
      })
    );
  };

  return (
    <SingleSelectDateRanges
      value={value as [string | null, string | null]}
      data={options as any}
      onChange={handleChange}
    />
  );
};

export default FilterSingleSelectDateFilter;
