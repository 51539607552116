import { TouchType } from '@models/company/CompanyTouch';

/**
 * Range filter unknown value.
 */
export const RANGE_FILTER_UNKNOWN_VALUE = 'not_blank';
export const RANGE_FILTER_UNKNOWN_ONLY_VALUE = 'blank';
export const RANGE_FILTER_PSG_VALUE = 'exclude_psg';
export const RANGE_FILTER_PSG_ONLY_VALUE = 'is_psg_only';

export const FILTER_TOUCHES: TouchType[] = [
  'Call',
  'Contact Import',
  'CrunchBase',
  'Document Upload',
  'E-mail',
  'Internal Note',
  'Meeting',
  'Introduction',
  'Follow-up',
  'Mgmt Call',
  'Expert Call',
  'Product Demo',
  'Pre-analysis',
  'VC Meeting',
  'IB Meeting',
  'Other',
  'NDA Signed',
  'Stage Modified',
  'Transferred',
  'Voice Mail',
];

export const FILTER_ORIGIN_VALUES = {
  addonManagement: 'addon_management',
};

export const FILTERS_WITH_CHECKBOX_AS_RADIO = ['completed_touch_option'];
