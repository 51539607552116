import React from 'react';

interface SalesloftContextContext {
  cadenceId: number | null;
  setCadenceId: (id: number | null) => void;
  errors: string[];
  setErrors: (errors: string[]) => void;
}

export const SalesloftContext = React.createContext<SalesloftContextContext>({
  cadenceId: null,
  setCadenceId: id => {},
  errors: [],
  setErrors: errors => {},
});
