/* eslint-disable no-param-reassign */
import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { parse } from 'query-string';
// models
import { CompanyUserContact } from '@models/api/contacts';
import { SalesloftCadences, SalesloftPostData } from '@models/Salesloft';
// utils
import { stripUrlSlashes } from '@utils/url';
// redux
import { actions as contactActions } from '@redux/company/primary-contacts';
import { actions as eqtActions } from '@redux/company/equity-touch';
import { fetchReducer } from '../../feature/fetch/reducers';
import { SalesloftState } from './interfaces';
import * as actions from './actions';

const initialState: SalesloftState = {
  loading: false,
  error: '',
  authUrl: '',
  cadences: [],
  postData: {
    contactIds: [],
  },
};

const updateCompanyInfo = (
  postData: SalesloftPostData,
  id: number | undefined,
  name: string | undefined,
  url: string | null | undefined
) => {
  postData.company_id = id;
  postData.company_name = name;
  postData.company_website = url;
};

const fetchSalesloftAuthUrlSuccessReducer: CaseReducer<SalesloftState, PayloadAction<string>> = (
  draftState,
  action
) => {
  draftState.loading = false;
  draftState.authUrl = action.payload;
};

const fetchSalesloftCadencesSuccessReducer: CaseReducer<
  SalesloftState,
  PayloadAction<Array<SalesloftCadences>>
> = (draftState, action) => {
  draftState.loading = false;
  draftState.cadences = action.payload;
};

const submitSalesloftFormSuccessReducer: CaseReducer<SalesloftState> = draftState => {
  draftState.loading = false;
};

const fetchPrimaryContactsSuccessReducer: CaseReducer<
  SalesloftState,
  PayloadAction<CompanyUserContact[]>
> = (draftState, action) => {
  draftState.loading = false;

  if (!action.payload.length) {
    const searchParams = parse(window.location.search);
    updateCompanyInfo(
      draftState.postData,
      Number(searchParams.id),
      searchParams?.name as string,
      searchParams.url ? stripUrlSlashes(searchParams.url as string) : null
    );
  } else {
    const { company_id: id, company_name: name, company_url: url } = action.payload[0];
    updateCompanyInfo(draftState.postData, id, name, url);
  }
};

const salesloftSelectCadenceReducer: CaseReducer<
  SalesloftState,
  PayloadAction<SalesloftCadences | undefined>
> = (draftState, action) => {
  if (!action.payload) {
    draftState.postData.cadence_id = undefined;
    draftState.postData.cadence_name = undefined;
    draftState.postData.team_cadence = undefined;
    draftState.postData.company_id = undefined;
  } else {
    const { id, name, team_cadence: teamCadence, company_id: companyId } = action.payload;
    draftState.postData.cadence_id = id;
    draftState.postData.cadence_name = name;
    draftState.postData.team_cadence = teamCadence;
    draftState.postData.company_id = companyId;
  }
};

const salesloftSelectContactReducer: CaseReducer<SalesloftState, PayloadAction<number[]>> = (
  draftState,
  action
) => {
  draftState.postData.contactIds = action.payload;
};

const salesloftResetReducer: CaseReducer<SalesloftState> = (draftState, action) => {
  draftState.postData = initialState.postData;
};

const postSalesloftDataFailReducer: CaseReducer<SalesloftState> = draftState => {
  draftState.loading = false;
};

const reducer = createReducer(initialState, builder =>
  builder
    // url
    .addCase(actions.fetchSalesloftAuthUrl, fetchReducer)
    .addCase(actions.fetchSalesloftAuthUrlSuccess, fetchSalesloftAuthUrlSuccessReducer)
    // cadences
    .addCase(actions.fetchSalesloftCadences, fetchReducer)
    .addCase(actions.fetchSalesloftCadencesSuccess, fetchSalesloftCadencesSuccessReducer)
    // post data
    .addCase(actions.postSalesloftData, fetchReducer)
    .addCase(actions.postSalesloftDataSuccess, submitSalesloftFormSuccessReducer)
    .addCase(actions.postSalesloftDataFail, postSalesloftDataFailReducer)
    // select data
    .addCase(actions.salesloftSelectCadence, salesloftSelectCadenceReducer)
    .addCase(actions.salesloftSelectContacts, salesloftSelectContactReducer)
    // resset
    .addCase(actions.salesloftReset, salesloftResetReducer)
    // external
    .addCase(contactActions.fetchPrimaryContacts, fetchReducer)
    .addCase(contactActions.fetchPrimaryContactsSuccess, fetchPrimaryContactsSuccessReducer)
    .addCase(eqtActions.resetAddToEquityTouch, salesloftResetReducer)
);

export default reducer;
