import React, { useState, useRef } from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { IconImport } from '../../../icons';

interface DeagAndDropProps {
  data: File | null;
  handleChange: (file: File | null) => void;
  handleRemove: VoidFunction;
  children?: React.ReactNode;
}

const StyledButton = styled(Button)`
  margin-top: -14px;
`;

const DragAndDrop = React.forwardRef<HTMLInputElement, DeagAndDropProps>(
  ({ data, handleChange, handleRemove, children }, ref) => {
    const dragCounterRef = useRef(0);
    const [drag, setDrag] = useState(false);

    const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const handleDragIn = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      dragCounterRef.current += 1;

      if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
        setDrag(true);
      }
    };

    const handleDragOut = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      dragCounterRef.current -= 1;

      if (dragCounterRef.current === 0) {
        setDrag(false);
      }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setDrag(false);

      if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
        handleChange(event.dataTransfer.files[0]);
        dragCounterRef.current = 0;
      }
    };

    return (
      <div
        className="box"
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        {drag && (
          <div className="box__overlayer">
            <div>Drop here !</div>
          </div>
        )}
        {children}
        {data && !data.name ? (
          <IconImport />
        ) : (
          <StyledButton type="link" icon={<CloseOutlined />} size="small" onClick={handleRemove} />
        )}
        <div className="box__input">
          <label htmlFor="upload">
            <input
              type="file"
              name="file"
              placeholder=""
              id="upload"
              className="box__file"
              onChange={e => handleChange((e.target.files && e.target.files[0]) || null)}
              ref={ref}
            />
            <strong>Choose a file</strong>
            <span className="box__dragndrop"> or drag it here.</span>
          </label>
        </div>
      </div>
    );
  }
);

DragAndDrop.defaultProps = { data: null, handleChange: () => {}, handleRemove: () => {} };

export default DragAndDrop;
