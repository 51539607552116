import React from 'react';

// components
import Styles from './MainCardTitle.style';

const MainCardTitle: React.FC = () => {
  return (
    <Styles.StyledCardTitle>
      Touch Management
      <Styles.StyledCardSubTitle>
        A list of all the schedule touches from Equity Touch
      </Styles.StyledCardSubTitle>
    </Styles.StyledCardTitle>
  );
};

export default MainCardTitle;
