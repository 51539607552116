import { CaseReducer, PayloadAction, createReducer } from '@reduxjs/toolkit';
// redux
import { actions } from '..';
import { SearchAutocomplete } from '@optx/models/search-autocomplete';

const initialState: SearchAutocomplete[] = [];

export const fetchSearchAutocompleteSuccessReducer: CaseReducer<
  SearchAutocomplete[],
  PayloadAction<Array<SearchAutocomplete>>
> = (draftState, action) => {
  return action.payload;
};

const reducer = createReducer<SearchAutocomplete[]>(initialState, builder =>
  builder.addCase(actions.fetchSearchAutocompleteSuccess, fetchSearchAutocompleteSuccessReducer)
);

export default reducer;
