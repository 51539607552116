import { DefaultTheme, ThemeProps } from 'styled-components';
import { ANTDColors, PalleteProp } from '@models/theme';

type ANTDColor = keyof ANTDColors;
type PrimaryKey = keyof Pick<PalleteProp, 'primary'>;
type ColorIndex = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | PrimaryKey;

export const getThemeColor = (theme: DefaultTheme, color: ANTDColor, index: ColorIndex) => {
  const colorPalleteIndex = typeof index === 'number' ? index - 1 : index;

  return theme.colors[color][colorPalleteIndex];
};

export const themeColor =
  (color: ANTDColor, index: ColorIndex) => (props: ThemeProps<DefaultTheme>) =>
    getThemeColor(props.theme, color, index);

// used colors
// gray
const gray2 = themeColor('gray', 2);
const gray4 = themeColor('gray', 4);
const gray5 = themeColor('gray', 5);
const gray6 = themeColor('gray', 6);
const gray7 = themeColor('gray', 7);
const gray8 = themeColor('gray', 8);
const gray9 = themeColor('gray', 9);
const gray10 = themeColor('gray', 10);
// blue
const blue1 = themeColor('blue', 1);
const blue6 = themeColor('blue', 6);
const blue7 = themeColor('blue', 7);
const blue8 = themeColor('blue', 8);
// green
const green6 = themeColor('green', 6);
// red
const red6 = themeColor('red', 6);

export const styledColors = {
  // gray
  gray2,
  gray4,
  gray5,
  gray6,
  gray7,
  gray8,
  gray9,
  gray10,
  // blue
  blue1,
  blue6,
  blue7,
  blue8,
  // green
  green6,
  // red
  red6,
};
