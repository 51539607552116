import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// models
import { SourcingOutreachResponse } from '@features/sourcing-outreach-summary/models/api';
import { SourcingOutreachUserData } from '@features/sourcing-outreach-summary/models';
// redux
import { SourcingOutreachState } from '../interfaces';
import { actions } from '../actions';

const initialState: SourcingOutreachState = {
  allIds: [],
  byUserId: {},
  cards: null,
  totalColumnsData: null,
  fetchedAt: null,
};

const fetchSourcingOutreachSuccessReducer: CaseReducer<
  SourcingOutreachState,
  PayloadAction<SourcingOutreachResponse>
> = (draftState, action) => {
  draftState.allIds = [];
  draftState.byUserId = {};

  action.payload.table_data.forEach((item: SourcingOutreachUserData) => {
    draftState.allIds.push(item.analyst_id);
    draftState.byUserId[item.analyst_id] = item;
  });

  draftState.cards = action.payload.cards;
  draftState.totalColumnsData = action.payload.total_columns;
  draftState.fetchedAt = new Date().toISOString();
};

const reducer = createReducer(initialState, builder =>
  builder.addCase(actions.search.fetchSourcingOutreachSuccess, fetchSourcingOutreachSuccessReducer)
);

export default reducer;
