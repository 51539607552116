import React, { useEffect, useCallback, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dictionary } from 'lodash';
import { Card, Col, Row, Typography } from 'antd';
// models
import { ProductCategory } from '@optx/models/Company';
import {
  EquityTouchCheck,
  EquityTouchFetchedField,
  EquityTouchSubmitField,
  GroupsSubmitField,
  WatchlistsSubmitField,
} from '@models/equityTouch';
import { SalesloftResponse } from '@optx/models/Salesloft';
import { CompanyUserContact } from '@optx/models/api/contacts';
import { SelectOption } from '@models/Option';
// utils
import { mapEquityTouchForm } from '@utils/equityTouch';
// redux
import { actions, selectors } from '@redux/company/equity-touch';
import {
  actions as salesloftActions,
  selectors as salesloftSelectors,
} from '@redux/contacts/salesloft';
import {
  actions as primaryContactsActions,
  selectors as primaryContactsSelectors,
} from '@redux/company/primary-contacts';
// hooks
import {
  useInitialFetchEditOptions,
  useInject as useInjectCompanyIndividualEdit,
} from '@optx/components/feature/company-individual-edit';
import { useInjectEquityTouch, useInjectSalesloft } from '@hooks/inject';
import { useInitFilters } from '@optx/common/hooks/init';
import { useSalesloftAuth } from '@optx/common/hooks/salesloft';
// components
import { getContactErrors } from '@optx/utils/salesloft';
import NotificationService from '@optx/services/NotificationService';
import * as salesloftPageLoader from '../../../redux/pages/salesloft/selectors';
import PageLoader from './components/PageLoader';
import Confirmation from './components/Confirmation';
import EquityTouchForm from './components/EquityTouchForm';
import { MainContent } from '../../common/layout';
import EquityTouchContactsTable from './components/EquityTouchContactsTable';
import SelectCadenceOptional from './components/SelectCadenceOptional';
import SalesLoftConfirmation from '../SalesLoft/components/Confirmation';
import CadenceCardTitle from './components/CadenceCardTitle';
import AddContactEquityTouch from './components/AddContactEquityTouch';
import SelectSalesLoftContacts from './components/SelectSalesLoftContacts';
// styles
import './styles.scss';

interface ProfileRouteMatch {
  id: string;
}

const AddToEquityTouch: React.FC = () => {
  useInjectEquityTouch();
  useInjectSalesloft();
  useInjectCompanyIndividualEdit();

  useInitFilters();
  useInitialFetchEditOptions();
  const dispatch = useDispatch();
  const { fetchSalesloftAuth } = useSalesloftAuth();
  // get company id from route
  const match = useRouteMatch<ProfileRouteMatch>();
  const {
    params: { id },
  } = match;

  const isFetched = useSelector(selectors.isFetched);
  const isLoading = useSelector(selectors.isLoading);
  const formData = useSelector(selectors.getData);
  const cadences = useSelector(salesloftSelectors.getCadences);
  const salesLoftLoading = useSelector(salesloftPageLoader.salesloftLoader);

  const temporaryFormValues = useSelector(selectors.getTemporaryFormValues);

  const primaryContacts = useSelector(primaryContactsSelectors.getPrimaryContacts);

  const [showModal, setShowModal] = useState(false);
  const [availability, setAvailability] = useState<Dictionary<EquityTouchCheck>>();
  const [initialFormData, setInitialFormData] = useState<Dictionary<EquityTouchFetchedField>>();
  const [isEtLoading, setEtLoading] = useState(true);
  const [isAvalabilityLoaded, setAvalabilityLoaded] = useState(false);
  const [isFormDataLoaded, setFormDataLoaded] = useState(false);
  const [etUrl, setEtUrl] = useState('');
  const [hasError, setHasError] = useState(false);
  const [salesLoftRes, setSalesLoftRes] = useState<SalesloftResponse>();
  const [errors, setErrors] = useState<string[]>([]);
  const [contacts, setContacts] = useState<CompanyUserContact[]>(primaryContacts);

  const cadence = cadences.find(cadence => cadence.id === temporaryFormValues?.cadence);

  const checkAvailability = useCallback(
    (id: string, cb: (data: Dictionary<EquityTouchCheck>) => void) =>
      dispatch(actions.equityTouchAvailability(id, cb)),
    [dispatch]
  );

  const fetchFormData = useCallback(
    (id: string, cb: (data: Dictionary<EquityTouchFetchedField>) => void) => {
      dispatch(actions.fetchEquityTouchFormData(Number(id), cb));
    },
    [dispatch]
  );

  const fetchCadences = useCallback(
    (payload: string, cb?: () => void) =>
      dispatch(salesloftActions.fetchSalesloftCadences(payload, cb)),
    [dispatch]
  );

  const hasPrimaryContact = contacts.filter((item, index) => {
    return item.primary_contact === true;
  });

  const submitForm = (
    id: string,
    formData: Dictionary<
      string | EquityTouchSubmitField | GroupsSubmitField | WatchlistsSubmitField[]
    >,
    formValues: Dictionary<
      string | number | boolean | number[] | string[] | null | SelectOption | ProductCategory[]
    >,
    cb: (data: { url: string; hasError?: boolean } | string) => void
  ) => {
    if (!temporaryFormValues.cadence && !(temporaryFormValues?.salesloft_contacts as any)?.length) {
      setEtLoading(true);
      dispatch(actions.addToEquityTouch(id, formData, temporaryFormValues, cb));
    } else if (
      (!temporaryFormValues.cadence || cadence === undefined) &&
      temporaryFormValues?.salesloft_contacts &&
      hasPrimaryContact
    ) {
      NotificationService.error('User should choose cadence');
    } else if (
      temporaryFormValues.cadence &&
      !(temporaryFormValues.salesloft_contacts as any)?.length
    ) {
      NotificationService.error(
        'No contact was selected for Sales Loft. Please select at least one contact or deselect the cadence.'
      );
    } else {
      const errors = getContactErrors(
        contacts,
        (temporaryFormValues?.salesloft_contacts as any) || []
      );

      if (errors.length) {
        setErrors(errors);
        NotificationService.error(
          'The selected contact(s) for Salesloft has some missing information'
        );
      } else {
        setEtLoading(true);
        dispatch(actions.addToEquityTouch(id, formData, temporaryFormValues, cb));

        const companyName = formData['Company Name'] ? (formData['Company Name'] as string) : null;
        const companyUrl = formData.Website ? (formData.Website as string) : null;
        dispatch(
          salesloftActions.postSalesloftData(
            { id: '', company_name: companyName, company_url: companyUrl },
            (res: SalesloftResponse) => {
              if (res) {
                setSalesLoftRes(res);
              } else {
                fetchSalesloftAuth('', (url: string) => {
                  window.open(
                    url,
                    'popUpWindow',
                    // eslint-disable-next-line max-len
                    'height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
                  );
                });
              }
            }
          )
        );
      }
    }
  };

  const receiveMessage = (event: any) => {
    if (event.data === 'Salesloft authentication succeeded') {
      if (cadences.length <= 1) {
        fetchCadences('', undefined);
      }
    }
  };

  window.addEventListener('message', receiveMessage, false);

  useEffect(() => {
    if (cadences.length <= 1) {
      fetchCadences('', () => {
        fetchSalesloftAuth('', url => {
          window.open(
            url,
            'popUpWindow',
            // eslint-disable-next-line max-len
            'height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
          );
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkAvailability(id, (data: Dictionary<EquityTouchCheck>) => {
      if (data[id].et_api.length) {
        setShowModal(true);
      }

      setAvalabilityLoaded(true);
      setAvailability(data);
    });

    if (isFetched) {
      setFormDataLoaded(true);
      setInitialFormData(formData);
    } else {
      fetchFormData(id, (data: Dictionary<EquityTouchFetchedField>) => {
        if (typeof data['Equity Check']?.from_database === 'number') {
          const newData = {
            ...data,
            'Equity Check': {
              ...data['Equity Check'],
              from_database: data['Equity Check'].from_database / 1000000,
            },
          };
          setInitialFormData(newData);
        } else {
          setInitialFormData(data);
        }

        setFormDataLoaded(true);
      });
    }

    dispatch(primaryContactsActions.fetchPrimaryContacts(id));

    return () => {
      dispatch(actions.resetAddToEquityTouch());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // hide loader after both requests are made,
    // checking for availability and fetching form data
    if (isAvalabilityLoaded && isFormDataLoaded) {
      setEtLoading(false);
    }
  }, [isAvalabilityLoaded, isFormDataLoaded]);

  useEffect(() => {
    if (errors.length) {
      setErrors([]);
    }

    setContacts(primaryContacts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryContacts]);

  const handleSubmit = (
    formValues: Dictionary<
      string | number | boolean | number[] | string[] | null | SelectOption | ProductCategory[]
    >
  ) => {
    submitForm(id, mapEquityTouchForm(formValues, initialFormData), formValues, data => {
      setEtLoading(false);

      if (typeof data === 'object' && data.url) {
        setEtUrl(data.url);
      }

      if (typeof data === 'object' && data.hasError) {
        setHasError(data.hasError);
      }

      if (typeof data === 'string') {
        setEtUrl('');
      }
    });
  };

  useEffect(() => {
    return () => {
      dispatch(primaryContactsActions.clearPrimaryContacts());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainContent className="add-to-equity-touch">
      <PageLoader isLoading={isEtLoading || salesLoftLoading || isLoading} />
      <div>
        {!etUrl ? (
          <>
            {initialFormData && (
              <>
                <EquityTouchForm
                  initialFormData={initialFormData}
                  availability={availability}
                  id={id}
                  handleSubmit={handleSubmit}
                  showModal={showModal}
                  setShowModal={setShowModal}
                />
                <Row gutter={20} justify="end" className="add-to-equity-touch__wrapper">
                  <Col span={24} xxl={12}>
                    <Card title={<CadenceCardTitle />} className="add-to-equity-touch__card">
                      <Row gutter={50} className="add-to-equity-touch__salesloft">
                        <Col span={12} className="salesloft-label">
                          <Typography.Text>SLCadence</Typography.Text>
                          <SelectCadenceOptional errors={errors} />
                        </Col>
                        <Col span={12} className="salesloft-label">
                          <Typography.Text>Contacts</Typography.Text>
                          <SelectSalesLoftContacts />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col span={24} xxl={12}>
                    <Card
                      title="Contact Info"
                      className="add-to-equity-touch__card"
                      extra={<AddContactEquityTouch id={id} />}
                    >
                      <EquityTouchContactsTable />
                    </Card>
                  </Col>
                </Row>
              </>
            )}
          </>
        ) : (
          <>
            <Confirmation id={Number(id)} showConfirmation={etUrl} hasError={hasError} />
            {salesLoftRes && <SalesLoftConfirmation res={salesLoftRes} />}
          </>
        )}
      </div>
    </MainContent>
  );
};

export default AddToEquityTouch;
