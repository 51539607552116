import { AppState } from '@optx/redux/interfaces';
import { createSelector } from 'reselect';

const selectSearchNotes = (state: AppState) => state.notes.search;

const getAllIds = createSelector(selectSearchNotes, state => state.allIds);

const getById = createSelector(selectSearchNotes, state => state.byId);

export const getSearchNotes = createSelector(getAllIds, getById, (allIds, byId) =>
  allIds.map(id => byId[id])
);

export const isLoading = createSelector(selectSearchNotes, state => state.loading);

export const shouldInitialFetch = createSelector(
  selectSearchNotes,
  state => !state.fetchedAt && !state.loading
);

export const getSearchQuery = createSelector(selectSearchNotes, state => state.searchQuery);

export const hasMore = createSelector(selectSearchNotes, state => state.hasMore);
