import { createAction } from '@reduxjs/toolkit';
import * as search from './search';
import * as filters from './filters';
import * as sort from './sort';
import { types } from '../types';

const init = createAction(types.init);

export const actions = {
  // feature
  init,
  // slices
  search,
  filters,
  sort,
};
