import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// redux
import {
  selectors as userInformationSelectors,
  actions as userInformationActions,
} from '../../../../redux/user/information';
// components
import ToggleButton from '../../../common/molecules/buttons/ToggleButton';

const CardsToggle = () => {
  const dispatch = useDispatch();
  const showCards = useSelector(userInformationSelectors.showMyCompaniesCards);

  const handleClick = useCallback(() => {
    dispatch(userInformationActions.updateUserSettings({ show_my_companies_cards: !showCards }));
  }, [dispatch, showCards]);

  const title = `${showCards ? 'Hide' : 'Show'} Cards`;

  return <ToggleButton onClick={handleClick} hide={showCards} title={title} />;
};

export default React.memo(CardsToggle);
