import React from 'react';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';

const DefaultTooltip = styled(Tooltip)`
  display: block;
`;

export const TableCellInvestorNames = (value: any) => {
  if (!value || value === DEFAULT_CELL_VALUE) {
    return <>{DEFAULT_CELL_VALUE}</>;
  }

  return (
    <DefaultTooltip title={value.join('; ')} className="text-truncate" placement="topRight">
      {value.join('; ')}
    </DefaultTooltip>
  );
};

export default TableCellInvestorNames;
