import React from 'react';
import { Result, Button } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
// utils
import { getBackButtonText } from '@utils/equityTouch';
// redux
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { selectors as favoriteListsSelectors } from '@redux/favorite-lists/lists';
import { selectors as sourceScrubSelectors } from '@redux/user/source-scrub-lists';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';

const ResultWrapper = styled(Result)`
  padding: 48px 32px;
`;

const Completed: React.FC = () => {
  const history = useHistory();
  const successfulCount = useSelector(bulkActionsSelectors.salesloft.successfulCount);
  const totalCount = useSelector(bulkActionsSelectors.salesloft.salesloftBulkCompanies).length;
  const userLists = useSelector(favoriteListsSelectors.getLists);
  const sourceScrubLists = useSelector(sourceScrubSelectors.getSourceScrubLists);
  const { advancedSearchPathname } = useIsAnalyst();

  return (
    <>
      <ResultWrapper
        status={!successfulCount ? 'error' : 'success'}
        title={`${successfulCount || 0}/${totalCount} ${
          totalCount === 1 ? 'Company' : 'Companies'
        } Successfully Added to a SL Cadence`}
        subTitle="It may take up to 5 minutes for companies to show in Sales Loft"
        extra={[
          <Button onClick={() => history.goBack()}>
            {getBackButtonText(userLists, sourceScrubLists, advancedSearchPathname)}
          </Button>,
        ]}
      />
    </>
  );
};

export default Completed;
