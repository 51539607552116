// components
import { LineSkeleton } from '../Skeleton';
import Styled from './components/Sidebar.styles';
import StatsCompareStyles from './components/StatsCompare.styles';

const ActivityInsightsSkeleton = () => {
  return (
    <>
      {Array.from({ length: 4 }).map((_, index) => (
        <Styled.SubSection key={index}>
          <StatsCompareStyles.Wrapper>
            <LineSkeleton margin={[4, 0, 4, 0]} />
            <LineSkeleton margin={[4, 0, 4, 0]} />

            {Array.from({ length: 3 }).map((_, index) => (
              <LineSkeleton
                key={index}
                width={'60px'}
                margin={[15, index === 2 ? 0 : 5, 0, index === 0 ? 0 : 5]}
                isCenter={false}
              />
            ))}
          </StatsCompareStyles.Wrapper>
        </Styled.SubSection>
      ))}
      <Styled.SubSection>
        <LineSkeleton />
        <LineSkeleton />
        {Array.from({ length: 3 }).map((_, index) => (
          <LineSkeleton
            key={index}
            width={'60px'}
            margin={[15, index === 2 ? 0 : 5, 70, index === 0 ? 0 : 5]}
            isCenter={false}
          />
        ))}
        {Array.from({ length: 6 }).map((_, index) => (
          <LineSkeleton key={index} />
        ))}
      </Styled.SubSection>
    </>
  );
};

export default ActivityInsightsSkeleton;
