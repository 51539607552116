import React from 'react';
import { Breadcrumb } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actions as searchActions } from '@features/grid/search';
import { BreadcrumbItemInterface } from '../interfaces';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';

const { Item } = Breadcrumb;

export const RestoreDefaults: BreadcrumbItemInterface = ({ onClick, ...restProps }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { advancedSearchPathname } = useIsAnalyst();

  const handleClick = () => {
    history.push(advancedSearchPathname);

    dispatch(searchActions.resetSearch({ gridKey: 'advancedSearch', data: true }));
  };

  return (
    <Item onClick={handleClick} {...restProps}>
      <button type="button">search results</button>
    </Item>
  );
};

// eslint-disable-next-line no-underscore-dangle
RestoreDefaults.__ANT_BREADCRUMB_ITEM = true;

export default RestoreDefaults;
