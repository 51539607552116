import { createFeatureActionType } from '@optx/utils/feature';
import { featureName } from '../featureName';

export const TOGGLE_COMPANY_EDIT_ALL_FORM_DIALOG = createFeatureActionType(
  featureName,
  'TOGGLE_COMPANY_EDIT_ALL_FORM_DIALOG'
);

export const GET_EDIT_ALL_COMPANY_CONTACTS = createFeatureActionType(
  featureName,
  'GET_EDIT_ALL_COMPANY_CONTACTS'
);
export const GET_EDIT_ALL_COMPANY_CONTACTS_SUCCESS = createFeatureActionType(
  featureName,
  'GET_EDIT_ALL_COMPANY_CONTACTS_SUCCESS'
);
export const GET_EDIT_ALL_COMPANY_CONTACTS_FAIL = createFeatureActionType(
  featureName,
  'GET_EDIT_ALL_COMPANY_CONTACTS_FAIL'
);

export const GET_EDIT_ALL_INFO = createFeatureActionType(featureName, 'GET_EDIT_ALL_INFO');
export const GET_EDIT_ALL_INFO_SUCCESS = createFeatureActionType(
  featureName,
  'GET_EDIT_ALL_INFO_SUCCESS'
);
export const GET_EDIT_ALL_INFO_FAIL = createFeatureActionType(
  featureName,
  'GET_EDIT_ALL_INFO_FAIL'
);

export const UPDATE_COMPANY_EDIT_ALL_INFO = createFeatureActionType(
  featureName,
  'UPDATE_COMPANY_EDIT_ALL_INFO'
);

export const UPDATE_COMPANY_EDIT_ALL_INFO_SUCCESS = createFeatureActionType(
  featureName,
  'UPDATE_COMPANY_EDIT_ALL_INFO_SUCCESS'
);
export const UPDATE_ALL_COMPANY_EDIT_ALL_INFO_SUCCESS = createFeatureActionType(
  featureName,
  'UPDATE_ALL_COMPANY_EDIT_ALL_INFO_SUCCESS'
);

export const UPDATE_COMPANY_EDIT_ALL_INFO_FAIL = createFeatureActionType(
  featureName,
  'UPDATE_COMPANY_EDIT_ALL_INFO_FAIL'
);

export const CHANGE_SAVE_BTN_STATUS = createFeatureActionType(
  featureName,
  'CHANGE_SAVE_BTN_STATUS'
);

export const ADD_YEAR_CHANGE_LOG = createFeatureActionType(featureName, 'ADD_YEAR_CHANGE_LOG');
