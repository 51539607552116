import React from 'react';
import { TextProps } from 'antd/lib/typography/Text';
import { StyledShowingCount } from './ShowingCount.styled';

export { StyledShowingCount };

export const ShowingCount: React.FC<TextProps> = props => (
  <StyledShowingCount.ShowingCount {...props} />
);

ShowingCount.defaultProps = {
  ellipsis: true,
};
