import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { ScrollState, SavePageScrollHistoryPayload } from './interfaces';
import * as actions from './actions';
import { defaultPageScrollHistory } from './constants';

const initialState: ScrollState = {};

const pageScrollHistoryReducer: CaseReducer<
  ScrollState,
  PayloadAction<SavePageScrollHistoryPayload>
> = (draftState, action) => {
  const { key, scrollHistory } = action.payload;
  const currentScrollHistory = draftState[key] || defaultPageScrollHistory;

  draftState[key] = {
    ...currentScrollHistory,
    ...scrollHistory,
  };
};

const resetScrollHistory: CaseReducer<ScrollState, PayloadAction<string>> = (
  draftState,
  action
) => {
  const historyKey = action.payload;

  draftState[historyKey] = defaultPageScrollHistory;
};

const reducer = createReducer<ScrollState>(initialState, builder =>
  builder
    .addCase(actions.saveScrollPosition, pageScrollHistoryReducer)
    .addCase(actions.saveTableRow, pageScrollHistoryReducer)
    .addCase(actions.resetScrollHistory, resetScrollHistory)
);

export default reducer;
