import { ColumnType } from 'antd/lib/table';

// components
import { TableCellCompanyName } from '@components/common/table/AntTable/cells';
import { CompanyWithContacts } from '@optx/features/bulk-actions/salesloft/state/interfaces';

import TableCellContact from '../AntTable/cells/TableCellContact/TableCellContact';

const columns: ColumnType<CompanyWithContacts>[] = [
  {
    dataIndex: 'company_name',
    title: 'Company Name',
    render: TableCellCompanyName,
    width: 300,
  },
  {
    dataIndex: 'primary_contact',
    title: 'Primary Contact',
    className: 'contact-cell',
    render: TableCellContact,
  },
];

export default columns;
