import React from 'react';
import { Title } from '@optx/shared/view/atoms/Typography';

const CadenceCardTitle = () => (
  <>
    <Title level={4}>Also add to Sales Loft Cadence (optional)</Title>
    <Title level={5} type="secondary">
      The primary contact will be added to this SL Cadence
    </Title>
  </>
);

export default CadenceCardTitle;
