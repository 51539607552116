import { Dictionary } from 'lodash';
import { call, put, select, takeLatest, fork } from 'redux-saga/effects';
import queryString from 'query-string';
import { AxiosResponse } from 'axios';
// models
import { UserInformation } from '@models/user';
// utils
import mapFiltersToURLParams from '@utils/filters/mapFiltersToURLParams';
// services
import { AnalystService } from '@services/api';
import NotificationService from '@optx/services/NotificationService';
// Redux
import {
  actions as userInformationActions,
  selectors as userInformationSelectors,
} from '@redux/user/information';
// Local
// redux
import { BaseFilter, PreselectedFilter } from '@optx/models/filters';
import * as actions from '../actions';
import * as selectors from '../selectors';

export function* searchAnalystsLeaderboardSaga() {
  const values: Dictionary<PreselectedFilter> = yield select(selectors.filters.getFilterValues);
  const filtersByColumn: Dictionary<BaseFilter> = yield select(
    selectors.filters.getNormalizedFilters
  );

  try {
    const queryValues = mapFiltersToURLParams(filtersByColumn, values);
    const query = queryString.stringify(queryValues, { arrayFormat: 'comma' });
    const res: AxiosResponse<any> = yield call(AnalystService.getDasboard, query);

    yield put(actions.search.searchSuccess(res.data));
    yield fork(updateFilterSettings);
  } catch (e: any) {
    yield put(actions.search.searchFail('Error fetching analysts leaderboard!'));
  }
}

/**
 * Update user settings filter.
 * @param query
 */
function* updateFilterSettings() {
  // Compute again values but this time include all the values, not only values for current selected criteria.
  const allValues: Dictionary<PreselectedFilter> = yield select(selectors.filters.getAllValues);
  const filtersByColumn: Dictionary<BaseFilter> = yield select(
    selectors.filters.getNormalizedFilters
  );
  const queryValues = mapFiltersToURLParams(filtersByColumn, allValues);
  const query = queryString.stringify(queryValues, { arrayFormat: 'comma' });

  const userSettings: UserInformation | null = yield select(
    userInformationSelectors.getUserSettings
  );

  try {
    // filters are loaded after user info so it should have value.
    const oldQuery = userSettings!.settings.session_settings?.analysts_leaderboard_filters;

    if (query !== oldQuery) {
      yield put(userInformationActions.updateUserSettings({ analysts_leaderboard_filters: query }));
    }
  } catch (error: any) {
    NotificationService.error('Failed persisting filters settings!');
  }
}

export default function* searchAnalystsLeaderboardSagas() {
  yield takeLatest(actions.search.search, searchAnalystsLeaderboardSaga);
}
