import React, { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import moment from 'moment';
// models
import { CompanyUserContact } from '@models/api/contacts';
// constants
import { PRESENT_DATE_FORMAT, ISO_DATE_FORMAT } from '@optx/constants/format/date';
// utils
import { getEmailsFromString } from '@utils/equityTouch';
// components
import ResolveContacts from '@features/bulk-actions/equity-touch/components/ResolveContacts';
import { Cell } from '@optx/models/table/Cell';
import { SalesloftContact } from '@optx/models/Salesloft';

const TableCellActions: React.FC<Cell<SalesloftContact>> = ({ value, record }) => {
  const [visible, setVisible] = useState(false);
  const [contactData, setContactData] = useState<Partial<CompanyUserContact> | undefined>();

  const handleClick = () => {
    setVisible(true);
    setContactData({
      ...record,
      emails: getEmailsFromString(record.email),
      tenure_start_date: record.tenure_start_date
        ? moment(record.tenure_start_date).format(ISO_DATE_FORMAT)
        : record.tenure_start_date,
      tenure_end_date:
        record.tenure_end_date && record.tenure_end_date !== PRESENT_DATE_FORMAT
          ? moment(record.tenure_end_date).format(ISO_DATE_FORMAT)
          : record.tenure_end_date === PRESENT_DATE_FORMAT
          ? null
          : record.tenure_end_date,
    });
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={handleClick}>Edit Contact</Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown trigger={['click']} overlay={menu} placement="bottomRight">
        <EllipsisOutlined className="dropdown-dot__icon" />
      </Dropdown>
      <ResolveContacts
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        contactData={contactData}
      />
    </>
  );
};

export default React.memo(TableCellActions);
