import React, { useState } from 'react';
import { Tooltip, Button } from 'antd';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
//constants
import { CHECKLIST_RESEARCH_INSIGHTS } from '@optx/constants/exportedFileName';
// utils
import { mapSortQuery } from '@optx/utils/search';
import mapFilterToURLParams from '@utils/filters/mapFiltersToURLParams';
import { exportFileCSV } from '@utils/csvHandlerChecklistResearch';
// redux
import { selectors } from '@features/checklist-research-insights';

interface ExportCSVButtonProps {
  hideDropdown?: () => void;
}

const ExportCSVButton: React.FC<ExportCSVButtonProps> = ({ hideDropdown }) => {
  const { filter, normalized } = useSelector(selectors.filters.getFilters);
  const sortBy = useSelector(selectors.sort.sortBy);
  const defaultTableView = useSelector(selectors.ui.defaultTableView);
  const [isLoading, setIsLoading] = useState(false);

  const handleExportCSV = () => {
    const params = mapFilterToURLParams(normalized, filter, undefined, {
      dateRangeFormatUpperLower: true,
    });
    const convertSortBy = mapSortQuery(sortBy);

    const parameterFilter = {
      ...convertSortBy,
      ...params,
    };

    const query = queryString.stringify(parameterFilter, {
      arrayFormat: 'comma',
    });

    setIsLoading(true);

    exportFileCSV(CHECKLIST_RESEARCH_INSIGHTS, query, defaultTableView === 'company').then(() => {
      setIsLoading(false);
    });

    hideDropdown && hideDropdown();
  };

  return (
    <Tooltip title="Up to 100K rows" placement="bottom">
      <Button type="text" onClick={handleExportCSV}>
        {isLoading ? 'Exporting' : 'Export to .csv'}
      </Button>
    </Tooltip>
  );
};

export default ExportCSVButton;
