import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import moment from 'moment';
import { Formik, FormikProps } from 'formik';
import { Form } from 'formik-antd';
import { isEqual, Dictionary } from 'lodash';
// models
import { TouchesCreateForm } from '@models/api/touches';
import {
  EditField,
  UpdateFieldsPayload,
} from '@optx/features/company/edit-fields/state/interfaces';
import { SelectOption } from '@optx/models/Option';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import TOUCH_FORM_INITIAL_STATE from './touchInitialValues';
import { ISO_DATE_FORMAT } from '@optx/constants/format/date';
// services
import NotificationService from '@services/NotificationService';
// utils
import validationTouch from './validation';
import { normalizeValue } from '@optx/utils/helpers';
// redux
import {
  selectors as modalSelectors,
  actions as modalActions,
} from '@redux/ui/modals/company-touches';
import { actions as editFieldActions } from '@optx/features/company/edit-fields/state';
import { selectors as userInformationSelectors } from '@redux/user/information';
import { getFinancialFilterOptions } from '@optx/redux/company/filters/selectors';
import { selectors as individualEditSelectors } from '@components/feature/company-individual-edit/state/index';
// hooks
import { useInjectUI } from '@optx/common/hooks/inject';
import { useInjectEditAllCompany } from '@optx/features/edit-all-info';
import useInitCompanyTouchesDialog from '@optx/common/hooks/init/useInitCompanyTouchesDialog';
// components
import { Styled } from './CompanyTouches.styled';
import TouchInformation from './TouchInformation';
import TouchModalTitle from './TouchModalTitle';
import AdditionalCompanyInfo from './AdditionalCompanyInfo';
import TouchPropInfo from './TouchPropInfo';
import CompanyDrawerBanner from './CompanyDrawerBanner';

const isFieldChanged = (
  normalizedValue: string,
  selectedValue: string | number | null | undefined,
  isCompanyOwner?: boolean
) => {
  const normalizedSelectedValue =
    selectedValue === 'None' || selectedValue === 'Unowned' || selectedValue === DEFAULT_EMPTY_VALUE
      ? null
      : selectedValue;

  if (
    (normalizedValue === 'blank' && normalizedSelectedValue) ||
    (normalizedValue !== 'blank' && !normalizedSelectedValue) ||
    (normalizedValue !== 'blank' &&
      normalizedSelectedValue &&
      normalizedValue.toString() !==
        (isCompanyOwner
          ? normalizedSelectedValue.toString().split(' ')[1]
          : normalizedSelectedValue.toString()))
  ) {
    return true;
  }

  return false;
};

interface CompanyTouchesType {
  isChromeExtension?: boolean;
}

// get the company ID from the URL because the one from redux is read as -1 at first
const companyID = parseInt(window.location.pathname.replace('/profile/', ''));
const isFromGrid = isNaN(companyID);

const CompanyTouches: React.FC<CompanyTouchesType> = ({ isChromeExtension }) => {
  useInjectUI();
  useInitCompanyTouchesDialog(isFromGrid ? undefined : companyID);
  useInjectEditAllCompany();
  const dispatch = useDispatch();
  const location = useLocation();

  const fullName = useSelector(userInformationSelectors.getFullName);
  const userId = useSelector(userInformationSelectors.getUserId);
  const userEtId = useSelector(userInformationSelectors.getUserEtId);
  const company = useSelector(modalSelectors.getTouchCompany);
  const financialsLoaded = useSelector(modalSelectors.hasFinancialsLoaded);
  const isOpen = useSelector(modalSelectors.isOpen);
  const editTouch = useSelector(modalSelectors.editTouch);
  const touchFields = useSelector(modalSelectors.getTouchFinancials);
  const additionalFieldsError = useSelector(modalSelectors.getAdditionalFieldsError);
  const selectedStageType = useSelector(modalSelectors.getTouchCompanyStage);
  const selectedStageRationale = useSelector(modalSelectors.getTouchCompanyStageRationale);
  const selectedRankType = useSelector(modalSelectors.getTouchCompanyRank);
  const selectedCompanyOwnerType = useSelector(modalSelectors.getTouchCompanyOwner);
  const companyOwnerOptions = useSelector(
    individualEditSelectors.companyIndividualEdit.companyOwnerOptions
  );
  const companyStageOptions = useSelector(
    individualEditSelectors.companyIndividualEdit.stageOptions
  );
  const companyRankOptions = useSelector(individualEditSelectors.companyIndividualEdit.rankOptions);
  const [shouldUpdateToInitial, setShouldUpdateToInitial] = useState(false);

  const ebitdaOptions = useSelector(getFinancialFilterOptions('ebitda_numeric'));
  const revenueOptions = useSelector(getFinancialFilterOptions('last_rev_update_amount'));
  const arrOptions = useSelector(getFinancialFilterOptions('last_arr_value'));
  const gmOptions = useSelector(getFinancialFilterOptions('last_gross_margin'));

  const toggle = useCallback(() => {
    if (formRef.current) {
      formRef.current.resetForm();
      setShouldUpdateToInitial(true);
      dispatch(modalActions.clearAdditionalFieldsError());
    }

    return dispatch(
      modalActions.toggleCompanyTouchesDialog({
        companyId: company.company_id,
        companyName: company.company_name,
        company,
        isFromGrid,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, company]);

  const handleCreateCompanyTouch = () => {
    if (formRef.current) {
      formRef.current.submitForm();

      if (formRef.current.errors) {
        const errors = Object.keys(formRef.current.errors);
        errors.forEach(error =>
          NotificationService.error(
            error.charAt(0).toUpperCase() +
              error.toString().replaceAll('_', ' ').slice(1) +
              ' is required!'
          )
        );

        // fix issue when company comes with last round as acquisition and acquiring company is empty
        if (
          errors.length === 0 &&
          formRef.current.values.acquiring_company === '' &&
          formRef.current.values.last_round?.label === 'Acquisition'
        ) {
          NotificationService.error('Acquiring Company is required!');
        }
      }
    }
  };

  // CompanyInfo
  const companyInfoToSaveRef = useRef<EditField | null>(null);

  // Form
  const formRef = useRef<FormikProps<Partial<TouchesCreateForm>> | null>(null);

  let formInitialValues = {
    ...TOUCH_FORM_INITIAL_STATE,
    userId: userId?.toString(),
    initiator: fullName,
    initiatorId: userEtId || userId,
    initiatorHasEtId: userEtId ? true : false,
    companyId: company.company_id?.toString() || '',
    touchDate: moment(new Date()).format(ISO_DATE_FORMAT),
    scheduleTouchDate: moment(new Date()).format(ISO_DATE_FORMAT),
  };

  if (editTouch != null) {
    formInitialValues = {
      ...formInitialValues,
      subject: editTouch.subject,
      type: editTouch.touchtype,
      touchId: editTouch.touchid,
      attendees: editTouch.psgParticipants ? editTouch.psgParticipants : [],
      externalAttendees: editTouch.externalParticipants
        ? editTouch.externalParticipants?.filter(item => item !== null)
        : [],
      companyId: company.company_id.toString(),
      initiator: editTouch.initiatorname,
      initiatorId: editTouch.initiatorid ? editTouch.initiatorid : editTouch.initiatorid_optx,
      initiatorHasEtId: editTouch.initiatorid ? true : false,
      touchType: editTouch.iscomplete ? 'complete' : 'schedule',
      touchDate: moment(editTouch.startdate).format(ISO_DATE_FORMAT),
      scheduleTouchDate: moment(editTouch.startdate).format(ISO_DATE_FORMAT),
      textarea: editTouch.notes,
      touchTime: [
        moment(editTouch.starttime, 'hh:mm:ss').format('HH:mm'),
        moment(editTouch.endtime, 'hh:mm:ss').format('HH:mm'),
      ],
    };
  }

  const handleInitialValues = () => {
    if (isEqual(formRef.current?.values, formInitialValues)) {
      return formRef.current!.values;
    }

    return formInitialValues;
  };

  const handleCompanyInfoChange = (newInfo: EditField | null) => {
    companyInfoToSaveRef.current = newInfo;
  };

  const handleSubmit = (values: Partial<TouchesCreateForm>) => {
    let additionalCompanyInfoPayload = companyInfoToSaveRef.current
      ? { ...companyInfoToSaveRef.current }
      : null;

    if (
      additionalCompanyInfoPayload &&
      additionalCompanyInfoPayload.value &&
      Array.isArray(additionalCompanyInfoPayload.fieldName)
    ) {
      const payloadValue = (additionalCompanyInfoPayload.value as UpdateFieldsPayload).value;
      const payloadRationale = (additionalCompanyInfoPayload.value as UpdateFieldsPayload)
        .rationale;

      if (additionalCompanyInfoPayload.fieldName.includes('Stage')) {
        let isStageChanged = false;
        const isStageRationaleChanged =
          payloadRationale !== undefined && payloadRationale !== selectedStageRationale
            ? true
            : false;

        if ((payloadValue as unknown as Dictionary<SelectOption>).Stage) {
          const companyStage = (payloadValue as unknown as Dictionary<SelectOption>).Stage;
          const normalizedCompanyStage = normalizeValue(companyStage, companyStageOptions);
          isStageChanged = isFieldChanged(normalizedCompanyStage, selectedStageType);
        }

        if (!isStageChanged && !isStageRationaleChanged) {
          additionalCompanyInfoPayload.fieldName = additionalCompanyInfoPayload.fieldName.filter(
            item => item !== 'Stage'
          );

          delete (payloadValue as unknown as Dictionary<SelectOption>).Stage;
          delete (additionalCompanyInfoPayload.value as UpdateFieldsPayload).rationale;
        }
      }

      if (additionalCompanyInfoPayload.fieldName.includes('Pipeline Rank')) {
        let isRankChanged = false;

        if ((payloadValue as unknown as Dictionary<SelectOption>)['Pipeline Rank']) {
          const companyRank = (payloadValue as unknown as Dictionary<SelectOption>)[
            'Pipeline Rank'
          ];
          const normalizedCompanyRank = normalizeValue(companyRank, companyRankOptions);
          isRankChanged = isFieldChanged(normalizedCompanyRank, selectedRankType);
        }

        if (!isRankChanged) {
          additionalCompanyInfoPayload.fieldName = additionalCompanyInfoPayload.fieldName.filter(
            item => item !== 'Pipeline Rank'
          );

          delete (payloadValue as unknown as Dictionary<SelectOption>)['Pipeline Rank'];
        }
      }

      if (additionalCompanyInfoPayload.fieldName.includes('Company Owner')) {
        let isCompanyOwnerChanged = false;

        if ((payloadValue as unknown as Dictionary<SelectOption>)['Company Owner']) {
          const companyOwner = (payloadValue as unknown as Dictionary<SelectOption>)[
            'Company Owner'
          ];
          const normalizedCompanyOwner = normalizeValue(companyOwner, companyOwnerOptions);
          isCompanyOwnerChanged = isFieldChanged(
            normalizedCompanyOwner,
            selectedCompanyOwnerType,
            true
          );
        }

        if (!isCompanyOwnerChanged) {
          additionalCompanyInfoPayload.fieldName = additionalCompanyInfoPayload.fieldName.filter(
            item => item !== 'Company Owner'
          );

          delete (payloadValue as unknown as Dictionary<SelectOption>)['Company Owner'];
        }
      }

      if (!additionalCompanyInfoPayload.fieldName.length) {
        additionalCompanyInfoPayload = null;
      }
    }

    if (!additionalFieldsError.error_field) {
      let formValues = { ...values };

      if (!isEqual(formInitialValues, formValues)) {
        if (typeof formValues.attendees === 'string' && formValues.attendees !== '') {
          formValues.attendees = [formValues.attendees];
        } else if (typeof formValues.attendees === 'string') {
          formValues.attendees = [];
        }

        dispatch(
          modalActions.addTouchToCompany({
            touchInfo: formValues,
            additionalCompanyInfo: additionalCompanyInfoPayload,
            location: location.pathname,
          })
        );
      }
    } else if (additionalCompanyInfoPayload !== null) {
      const payload = {
        ...additionalCompanyInfoPayload,
        canBeDuplicatedLoader: false,
      };

      dispatch(editFieldActions.updateFields(payload));
    } else {
      toggle();
    }
  };

  useEffect(() => {
    if (isOpen && formRef.current?.values && !isEqual(formRef.current.values, formInitialValues)) {
      formRef.current.resetForm();
      setShouldUpdateToInitial(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const title = (
    <div>
      <TouchModalTitle
        isSaveDisabled={!!additionalFieldsError.error_text}
        toggleHandler={toggle}
        handleCreateCompanyTouch={handleCreateCompanyTouch}
      />
    </div>
  );

  return (
    <>
      <Styled.DrawerTouches
        placement={isChromeExtension ? 'bottom' : 'right'}
        onClose={toggle}
        visible={isOpen}
        className={isChromeExtension ? 'company-touches touch-extension' : 'company-touches'}
        height={isChromeExtension ? 'calc(100% - 60px)' : '100%'}
        width={isChromeExtension ? '640px' : '750px'}
        title={title}
      >
        <div className="ant-drawer-body-inner">
          {!isChromeExtension && <CompanyDrawerBanner company={company} />}
          <AdditionalCompanyInfo
            shouldUpdateToInitial={shouldUpdateToInitial}
            setUpdateToInitial={setShouldUpdateToInitial}
            setInfoToSave={handleCompanyInfoChange}
          />
          <Styled.TouchContainer>
            <Formik
              initialValues={handleInitialValues()}
              enableReinitialize
              onSubmit={handleSubmit}
              validationSchema={validationTouch({
                ebitdaOptions,
                arrOptions,
                gmOptions,
                revenueOptions,
              })}
              validateOnMount={true}
              innerRef={instance => {
                formRef.current = instance;
              }}
              component={formikProps => {
                return (
                  <Form layout="vertical" style={{ position: 'relative' }}>
                    <div>
                      <TouchInformation
                        sources={formikProps}
                        isChromeExtension={isChromeExtension}
                        editTouch={editTouch}
                      />
                      {isOpen && financialsLoaded && (
                        <TouchPropInfo
                          touchFields={touchFields}
                          className={isChromeExtension ? 'touch-prop-info--chrome-extension' : ''}
                          touchParentType={
                            formRef.current?.values.touchType === 'complete'
                              ? 'complete'
                              : 'scheduled'
                          }
                        />
                      )}
                    </div>
                  </Form>
                );
              }}
            />
          </Styled.TouchContainer>
        </div>
      </Styled.DrawerTouches>
    </>
  );
};

export default React.memo(CompanyTouches);
