import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { NotificationDetails, NotificationPayload } from '@optx/models/notifications';
import { actions as savedSearchesActions } from '@redux/company/saved-searches';
import * as constants from './constants';
import {
  NotificationError,
  NotificationsState,
  NotificationsResponse,
  TouchesNotificationResponse,
} from './interfaces';
import * as actions from './actions';

export const initialState: NotificationsState = {
  inbox: {
    error: '',
    newAlertCount: 0,
    data: [],
    loaded: false,
    loading: false,
    pageNr: 1,
    sorting: 'desc',
  },
  history: {
    error: '',
    newAlertCount: 0,
    data: [],
    loaded: false,
    pageNr: 1,
    sorting: 'desc',
    loading: false,
  },
  touches: {
    upcoming: {
      error: '',
      data: [],
      loading: false,
      loaded: false,
      pageNr: 1,
      sorting: 'desc',
    },
    pastDue: {
      error: '',
      data: [],
      initialData: [],
      loading: false,
      loaded: false,
      pageNr: 1,
      sorting: 'desc',
    },
  },
  roadmapUrl: '',
};

const { PAGE_SIZE } = constants;

const fetchAlertsSuccessReducer: CaseReducer<
  NotificationsState,
  PayloadAction<NotificationsResponse>
> = (draftState, action) => {
  draftState.roadmapUrl = action.payload.roadmapUrl;
  draftState.inbox.loading = true;

  if (action.payload.history) {
    draftState.history.pageNr = action.payload.pageNr;
    if (action.payload.response.length < PAGE_SIZE || action.payload.response.length === 0)
      draftState.history.loaded = true;

    if (action.payload.pageNr > 1) {
      draftState.history.data = [...draftState.history.data, ...action.payload.response];
    } else {
      draftState.history.data = action.payload.response;
    }

    draftState.history.sorting = action.payload.sorting;
  } else {
    draftState.inbox.pageNr = action.payload.pageNr;
    draftState.inbox.newAlertCount = action.payload.newAlertCount;

    if (action.payload.response.length < PAGE_SIZE || action.payload.response.length === 0)
      draftState.inbox.loaded = true;

    if (action.payload.pageNr > 1 && action.payload.response.length <= PAGE_SIZE) {
      draftState.inbox.data = [...draftState.inbox.data, ...action.payload.response];
    } else {
      draftState.inbox.data = action.payload.response;
    }
  }

  draftState.inbox.loading = false;
};

const fetchAlertsFailReducer: CaseReducer<NotificationsState, PayloadAction<NotificationError>> = (
  draftState,
  action
) => {
  if (action.payload.history) {
    draftState.history.error = action.payload.errorMessage;
  } else {
    draftState.inbox.error = action.payload.errorMessage;
    draftState.inbox.loading = false;
  }
};

const fetchTouchesSuccessReducer: CaseReducer<
  NotificationsState,
  PayloadAction<TouchesNotificationResponse>
> = (draftState, action) => {
  draftState.touches.upcoming.error = '';
  draftState.touches.upcoming.loaded = true;
  draftState.touches.upcoming.data = action.payload.response.upcomingTouches;
  draftState.touches.pastDue.error = '';

  if (action.payload.response.pastdueTouches.length > 25) {
    draftState.touches.pastDue.data = action.payload.response.pastdueTouches.slice(0, 25);
    draftState.touches.pastDue.initialData = action.payload.response.pastdueTouches.slice(25);
  } else {
    draftState.touches.pastDue.data = action.payload.response.pastdueTouches;
    draftState.touches.pastDue.loaded = true;
  }
};

const fetchTouchesFailReducer: CaseReducer<NotificationsState, PayloadAction<NotificationError>> = (
  draftState,
  action
) => {
  draftState.touches.upcoming.loading = false;
  draftState.touches.upcoming.error = action.payload.errorMessage;
  draftState.touches.pastDue.loading = false;
  draftState.touches.pastDue.error = action.payload.errorMessage;
};

// remove new label
const updateAlertsReducer: CaseReducer<NotificationsState, PayloadAction<Array<number>>> = (
  draftState,
  action
) => {
  let count = 0;
  draftState.inbox.data.map((alert: NotificationDetails) => {
    if (action.payload.includes(alert.id)) {
      // eslint-disable-next-line no-param-reassign
      alert.viewed_at = new Date().toISOString();
      count++;
    }

    return draftState;
  });

  draftState.inbox.newAlertCount -= count;
};

const dismissAlertReducer: CaseReducer<NotificationsState, PayloadAction<number>> = (
  draftState,
  action
) => {
  const itemToMove = draftState.inbox.data.filter(alert => alert.id === action.payload);
  const remainingData = draftState.inbox.data.filter(alert => alert.id !== action.payload);
  draftState.inbox.data = remainingData;

  if (itemToMove[0].viewed_at === null) {
    itemToMove[0].viewed_at = new Date().toISOString();
    draftState.inbox.newAlertCount--;
  }

  draftState.history.data.unshift(itemToMove[0]);
};

const deleteUpcomingTouch: CaseReducer<NotificationsState, PayloadAction<number>> = (
  draftState,
  action
) => {
  draftState.touches.upcoming.loading = true;
};

const deleteUpcomingTouchSuccess: CaseReducer<NotificationsState, PayloadAction<number>> = (
  draftState,
  action
) => {
  draftState.touches.upcoming.loading = false;
  draftState.touches.upcoming.error = '';

  const remainingData = draftState.touches.upcoming.data.filter(
    upcomingTouch => upcomingTouch.unique_id !== action.payload
  );
  draftState.touches.upcoming.data = remainingData;
};

const deleteUpcomingTouchFailReducer: CaseReducer<
  NotificationsState,
  PayloadAction<NotificationError>
> = (draftState, action) => {
  draftState.touches.upcoming.loading = false;
  draftState.touches.upcoming.error = action.payload.errorMessage;
};

const deletePastDueTouch: CaseReducer<NotificationsState, PayloadAction<number>> = (
  draftState,
  action
) => {
  draftState.touches.pastDue.loading = true;
};

const loadMoreInboxAlertsReducer: CaseReducer<
  NotificationsState,
  PayloadAction<NotificationPayload>
> = (draftState, action) => {
  draftState.inbox.loading = true;
};

const deletePastDueTouchSuccess: CaseReducer<NotificationsState, PayloadAction<number>> = (
  draftState,
  action
) => {
  draftState.touches.pastDue.loading = false;
  draftState.touches.pastDue.error = '';

  const remainingData = draftState.touches.pastDue.data.filter(
    upcomingTouch => upcomingTouch.unique_id !== action.payload
  );
  draftState.touches.pastDue.data = remainingData;
};

const deletePastDueTouchFailReducer: CaseReducer<
  NotificationsState,
  PayloadAction<NotificationError>
> = (draftState, action) => {
  draftState.touches.pastDue.loading = false;
  draftState.touches.pastDue.error = action.payload.errorMessage;
};

const loadMorePastDueTouchesReducer: CaseReducer<NotificationsState> = draftState => {
  draftState.touches.pastDue.loading = true;

  if (draftState.touches.pastDue.initialData.length > 25) {
    draftState.touches.pastDue.data = [
      ...draftState.touches.pastDue.data,
      ...draftState.touches.pastDue.initialData.slice(0, 25),
    ];
    draftState.touches.pastDue.initialData = draftState.touches.pastDue.initialData.slice(25);
  } else {
    draftState.touches.pastDue.data = [
      ...draftState.touches.pastDue.data,
      ...draftState.touches.pastDue.initialData.slice(),
    ];
    draftState.touches.pastDue.initialData = [];
    draftState.touches.pastDue.loaded = true;
  }

  draftState.touches.pastDue.loading = false;
};

const deleteSearchSuccessReducer: CaseReducer<NotificationsState, PayloadAction<Number>> = (
  draftState,
  action
) => {
  const deletedListId = action.payload;
  let inboxItemId;

  if (draftState.inbox.data.length) {
    inboxItemId = draftState.inbox.data.findIndex(
      item => item.attributes.extra_context?.list_id === deletedListId && item.type === 'list_share'
    );

    if (
      typeof inboxItemId === 'number' &&
      inboxItemId !== -1 &&
      draftState.inbox.data[inboxItemId].attributes.extra_context
    ) {
      draftState.inbox.data[inboxItemId].attributes.extra_context.deleted = true;
    }
  } else if (
    (typeof inboxItemId === 'undefined' || inboxItemId === -1) &&
    draftState.history.data.length
  ) {
    const historyItemId = draftState.history.data.findIndex(
      item => item.attributes.extra_context?.list_id === deletedListId && item.type === 'list_share'
    );

    if (historyItemId !== -1 && draftState.inbox.data[historyItemId].attributes.extra_context) {
      draftState.inbox.data[historyItemId].attributes.extra_context.deleted = true;
    }
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.fetchAlertsSuccess, fetchAlertsSuccessReducer)
    .addCase(actions.fetchAlertsFail, fetchAlertsFailReducer)
    .addCase(actions.updateAlerts, updateAlertsReducer)
    .addCase(actions.dismissAlertSuccess, dismissAlertReducer)
    .addCase(actions.fetchTouchesFail, fetchTouchesFailReducer)
    .addCase(actions.fetchTouchesSuccess, fetchTouchesSuccessReducer)
    .addCase(actions.deleteUpcomingTouch, deleteUpcomingTouch)
    .addCase(actions.deleteUpcomingTouchSuccess, deleteUpcomingTouchSuccess)
    .addCase(actions.deleteUpcomingTouchFail, deleteUpcomingTouchFailReducer)
    .addCase(actions.deletePastDueTouch, deletePastDueTouch)
    .addCase(actions.deletePastDueTouchSuccess, deletePastDueTouchSuccess)
    .addCase(actions.deletePastDueTouchFail, deletePastDueTouchFailReducer)
    .addCase(actions.loadMorePastDueTouches, loadMorePastDueTouchesReducer)
    .addCase(savedSearchesActions.deleteSearchSuccess, deleteSearchSuccessReducer)
    .addCase(actions.loadMoreAlerts, loadMoreInboxAlertsReducer)
);

export default reducer;
