import React, { useEffect, useState } from 'react';
import Overflow from 'rc-overflow';
import styled from 'styled-components';
// utils
import { tagsValue } from '@optx/utils/filters/mapTags';
// components
import { FilterTagValue, FilterTagMore } from '@optx/features/tags/tags';
import { FilterTag } from '@optx/models/tags';

interface FilterTagsOverflowProps {
  options: FilterTag[];
  closable?: (item: FilterTag) => boolean;
  isFullWidth?: boolean;
  multipleValue: boolean;
  onClick?: () => void;
  onClose: (item: FilterTag) => () => void;
}

const NormalWrapper = styled.div`
  max-width: 100%;
  width: 100%;
`;

const FullWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const FilterTagsOverflow: React.FC<FilterTagsOverflowProps> = props => {
  const {
    options,
    closable = () => true,
    isFullWidth = false,
    multipleValue,
    onClick,
    onClose,
  } = props;
  const [activeOptions, setActiveOptions] = useState<FilterTag[]>([]);
  const [clearedFilters, setClearFilters] = useState<string[]>([]);

  let Wrapper = null;

  if (isFullWidth) {
    Wrapper = FullWrapper;
  } else {
    Wrapper = NormalWrapper;
  }

  const onCloseAction = (tag: FilterTag) => {
    if (tag.resetFor?.length) {
      setClearFilters(tag.resetFor);
    }

    onClose(tag)();
  };

  useEffect(() => {
    const filteredOptions = options.filter(item => !clearedFilters.includes(item.filter));
    setActiveOptions(filteredOptions);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearedFilters]);

  useEffect(() => {
    setActiveOptions(options);
    setClearFilters([]);
  }, [options]);

  return (
    <Wrapper>
      <Overflow
        data={
          activeOptions &&
          activeOptions.map((item: FilterTag) => ({
            ...item,
            stringifiedValues: multipleValue ? tagsValue(item, 2) : item.stringifiedValues,
          }))
        }
        renderRawItem={(item: FilterTag) => (
          <FilterTagValue
            item={item}
            key={item.uniqueKey}
            onClose={() => onCloseAction(item)}
            onClick={onClick}
            closable={closable(item)}
            className="keep-space-sequence"
          />
        )}
        renderRest={items => (
          <FilterTagMore
            items={items}
            onClose={onClose}
            toggleFiltersModal={onClick}
            closable={closable}
          />
        )}
        maxCount="responsive"
      />
    </Wrapper>
  );
};

export default FilterTagsOverflow;
