import { GridKeys } from '@optx/models/grid';
import { UserSessionsSettingsSearchKey } from './interface';

export const userSessionsSettingsGridsSearchKeys: Record<UserSessionsSettingsSearchKey, GridKeys> =
  {
    company_filters: 'advancedSearch',
    user_list_filters: 'watchlists',
    my_companies_filters: 'myCompanies',
    checklist_insights_companies_filters: 'checklistResearchInsights',
    addon_filters: 'addons',
    sourcing_outreach_detail_filters: 'outreach',
    contact_filters: 'contacts',
  };
