import { fork, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
// models
import { ViewOption } from '@optx/models/search';
import { GridPayload } from '@optx/models/grid';
// redux
import * as actions from './actions';
import { applyViewSaga as advancedSearchApplyViewSaga } from './sagas/advanced-search/sagas';
import { applyViewSaga as myCompaniesApplyViewSaga } from './sagas/my-companies/sagas';
import { applyViewSaga as listsApplyViewSaga } from './sagas/lists/sagas';
import {
  applyViewSaga as companyOutReachApplyViewSaga,
  resetViewToSourcingOrderSaga,
} from './sagas/company-outreach/sagas';

function* applyView(action: PayloadAction<GridPayload<ViewOption>>) {
  const { gridKey } = action.payload;
  console.log('switch, gridKey: ', gridKey);
  console.log('switch, action: ', action);

  switch (gridKey) {
    case 'advancedSearch':
      yield fork(advancedSearchApplyViewSaga, action);
      break;
    case 'myCompanies':
      yield fork(myCompaniesApplyViewSaga, action);
      break;
    case 'watchlists':
    case 'sslists':
      yield fork(listsApplyViewSaga, action);
      break;
    case 'outreach':
      yield fork(companyOutReachApplyViewSaga, action);
      break;
    default:
      break;
  }
}

export default function* viewSaga() {
  yield takeLatest(actions.applyView, applyView);
  yield takeLatest(actions.resetViewToSourcingOrder, resetViewToSourcingOrderSaga);
}
