import { Dictionary } from 'lodash';
// models
import { ColumnDisplay, ColumnKeys } from '@optx/models/table/Columns';
import { columnDisplayInitialState } from '@optx/constants/table/columnDisplay/company-search';

export const gridResetColumnWidthsReducer = (draftState: ColumnDisplay) => {
  const newColumns = { ...draftState.columnDisplay };
  draftState.columnOrder.forEach(columnId => {
    newColumns[columnId].width = columnDisplayInitialState[columnId].width;
  });

  draftState.columnDisplay = newColumns;
};

export const gridUpdateColumnWidthsReducer = (
  draftState: ColumnDisplay,
  columnWidths: Dictionary<number>
) => {
  const { columnDisplay, columnOrder } = draftState;

  // in case api doesn't return any changes for column widths, whether because they were
  // reset or never changed, check column widths and restore them to default size if necessary
  if (!Object.keys(columnWidths).length) {
    const newColumns = { ...draftState.columnDisplay };

    columnOrder.forEach(columnId => {
      if (columnDisplay[columnId].width !== columnDisplayInitialState[columnId].width) {
        newColumns[columnId as ColumnKeys].width = columnDisplayInitialState[columnId].width;
      }
    });

    draftState.columnDisplay = newColumns;
  } else {
    const newColumns = { ...draftState.columnDisplay };

    columnOrder.forEach(columnId => {
      if (columnWidths[columnId]) {
        newColumns[columnId as ColumnKeys].width = columnWidths[columnId];
      } else {
        newColumns[columnId as ColumnKeys].width = columnDisplayInitialState[columnId].width;
      }
    });

    draftState.columnDisplay = newColumns;
  }
};
