import React from 'react';
import ContentLoader from 'react-content-loader';

const SkeletonLoader = (props: any) => {
  return (
    <ContentLoader
      speed={2}
      width={630}
      height={976}
      viewBox="0 0 630 976"
      backgroundColor="#c4c4c4"
      foregroundColor="#ecebeb"
      style={{ position: 'fixed', background: 'white', zIndex: 10000 }}
      {...props}
    >
      {/* eslint-disable max-len */}
      <path d="M 0 0 h 630 v 86 H 0 z M 20 105 h 89 v 83 H 20 z M 122 105 h 258 v 35 H 122 z M 122 173 h 258 v 15 H 122 z M 122 150 h 258 v 15 H 122 z" />
      <path d="M 429 26 h 135 v 33 H 429 z" />
      <path d="M 431 103 h 133 v 33 H 431 z M 20 217 h 89 v 33 H 20 z M 122 217 h 89 v 33 h -89 z M 224 217 h 89 v 33 h -89 z" />
      <path d="M 20 273 h 147 v 15 H 20 z M 224 273 h 259 v 15 H 224 z M 224 293 h 259 v 15 H 224 z M 224 313 h 259 v 15 H 224 z M 20 293 h 147 v 15 H 20 z M 195 273 h 17 v 15 h -17 z M 20 351 h 147 v 15 H 20 z M 224 351 h 259 v 15 H 224 z M 224 371 h 259 v 15 H 224 z M 224 391 h 259 v 15 H 224 z M 20 371 h 147 v 15 H 20 z M 195 351 h 17 v 15 h -17 z M 20 460 h 147 v 15 H 20 z M 224 460 h 259 v 15 H 224 z M 224 480 h 259 v 15 H 224 z M 224 500 h 259 v 15 H 224 z M 20 480 h 147 v 15 H 20 z M 195 460 h 17 v 15 h -17 z M 20 534 h 147 v 15 H 20 z M 224 534 h 259 v 15 H 224 z M 224 554 h 259 v 15 H 224 z M 224 574 h 259 v 15 H 224 z M 20 554 h 147 v 15 H 20 z M 195 534 h 17 v 15 h -17 z M 20 608 h 147 v 15 H 20 z M 224 608 h 259 v 15 H 224 z M 224 628 h 259 v 15 H 224 z M 224 648 h 259 v 15 H 224 z M 20 628 h 147 v 15 H 20 z M 195 608 h 17 v 15 h -17 z M 20 682 h 147 v 15 H 20 z M 224 682 h 259 v 15 H 224 z M 224 702 h 259 v 15 H 224 z M 224 722 h 259 v 15 H 224 z M 20 702 h 147 v 15 H 20 z M 195 682 h 17 v 15 h -17 z M 20 756 h 147 v 15 H 20 z M 224 756 h 259 v 15 H 224 z M 224 776 h 259 v 15 H 224 z M 224 796 h 259 v 15 H 224 z M 20 776 h 147 v 15 H 20 z M 195 756 h 17 v 15 h -17 z M 20 830 h 147 v 15 H 20 z M 224 830 h 259 v 15 H 224 z M 224 850 h 259 v 15 H 224 z M 224 870 h 259 v 15 H 224 z M 20 850 h 147 v 15 H 20 z M 195 830 h 17 v 15 h -17 z M 20 921 h 147 v 15 H 20 z M 224 921 h 259 v 15 H 224 z M 224 941 h 259 v 15 H 224 z M 224 961 h 259 v 15 H 224 z M 20 941 h 147 v 15 H 20 z M 195 921 h 17 v 15 h -17 z" />
    </ContentLoader>
  );
};

export default SkeletonLoader;
