// fetch saved searches
export const FETCH_SAVED_SEARCHES = 'contacts/search/saved-searches/FETCH_SAVED_SEARCHES';
export const FETCH_SAVED_SEARCHES_SUCCESS =
  'contacts/search/saved-searches/FETCH_SAVED_SEARCHES_SUCCESS';
export const FETCH_SAVED_SEARCHES_FAIL = 'contacts/search/saved-searches/FETCH_SAVED_SEARCHES_FAIL';

// save search
export const SAVE_SEARCH = 'contacts/search/saved-searches/SAVE_SEARCH';
export const SAVE_SEARCH_SUCCESS = 'contacts/search/saved-searches/SAVE_SEARCH_SUCCESS';
export const SAVE_SEARCH_FAIL = 'contacts/search/saved-searches/SAVE_SEARCH_FAIL';

// delete search
export const DELETE_SEARCH = 'contacts/search/saved-searches/DELETE_SEARCH';
export const DELETE_SEARCH_SUCCESS = 'contacts/search/saved-searches/DELETE_SEARCH_SUCCESS';
export const DELETE_SEARCH_FAIL = 'contacts/search/saved-searches/DELETE_SEARCH_FAIL';

// delete search
export const RENAME_SEARCH = 'contacts/search/saved-searches/RENAME_SEARCH';
export const RENAME_SEARCH_SUCCESS = 'contacts/search/saved-searches/RENAME_SEARCH_SUCCESS';
export const RENAME_SEARCH_FAIL = 'contacts/search/saved-searches/RENAME_SEARCH_FAIL';

// go to search
export const RESET_TO_SEARCH = 'contacts/search/saved-searches/RESET_TO_SEARCH';

// pagination
export const SAVED_CONTACTS_SEARCH_PAGINATION =
  'contacts/search/saved-searches/SAVED_CONTACTS_SEARCH_PAGINATION';
