import styled from 'styled-components';

const StyledSelect = styled.div`
  .ant-radio-button-wrapper {
    background: #074681;
    border: 1px solid transparent;
    color: #ffffff;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #ffffff;
    background: #1890ff;
    border: 1px solid #1890ff;
  }
  .ant-radio-button-wrapper:not(:first-child):before {
    display: none;
  }
`;

const Styled = {
  StyledSelect,
};

export default Styled;
