import { createSelector } from 'reselect';
import queryString from 'query-string';
// interfaces
import { AppState } from '@optx/redux/interfaces';
// utils
import mapFiltersToURLParams from '@utils/filters/mapFiltersToURLParams';
// redux
import { selectors as companyFilterSelectors } from '@redux/company/filters';
import { getSearchKey } from '@optx/features/grid/searchkey/state/selectors';

const selectCompaniesOutReachState = (state: AppState) => state.companyOutSourcing.filter;

export const sourcingOutReachFiltersLoaded = createSelector(
  selectCompaniesOutReachState,
  state => !!state.fetchedAt
);

export const getFilter = createSelector(selectCompaniesOutReachState, state => state.filter);
export const getSourcingFiltersOptions = (sourcingField: 'analyst_id' | 'column_name') =>
  createSelector(
    selectCompaniesOutReachState,
    state =>
      state.sourcingOutReachFilter?.data
        ?.find((list: { column: string }) => list.column === sourcingField)
        ?.data.map((item: any) => ({
          label: item.name || item.label,
          value: item.value,
        })) || []
  );

export const getSourcingFiltersOptionsDate = createSelector(
  selectCompaniesOutReachState,
  state =>
    state.sourcingOutReachFilter?.data?.find((list: { column: string }) => list.column === 'ddate')
      ?.data || []
);

export const getSourcingFilterOptionsDateValue = createSelector(
  selectCompaniesOutReachState,
  state => state.sourcingOutReachFilterOptions?.ddate
);

export const getSourcingOptitonsFilterValues = (filterKey: 'analyst_id' | 'column_name') =>
  createSelector(
    selectCompaniesOutReachState,
    state => state.sourcingOutReachFilterOptions![filterKey]
  );

export const getCustomClearedFilter = createSelector(
  selectCompaniesOutReachState,
  state => state.customClearedfilter
);
export const getClearedFilter = createSelector(selectCompaniesOutReachState, state => state.clear);

export const getSelectedCardId = createSelector(selectCompaniesOutReachState, state => {
  if (state.selectedCard) {
    return state.selectedCard.id;
  }

  if (state.selectedCardId) {
    return state.selectedCardId;
  }

  return null;
});

export const getSubFilter = createSelector(selectCompaniesOutReachState, state => state.subFilter);

export const searchKey = getSearchKey('outreach');

/**
 * Check whether or not there are filters applied or we have a search key or subfilter active
 */
export const showClearButton = createSelector(
  getSearchKey('outreach'),
  getSubFilter,
  companyFilterSelectors.getFiltersMap,
  getCustomClearedFilter,
  getFilter,
  (searchKey, subfilter, normalizedFilters, customClearedFilter, filter) => {
    if (searchKey || subfilter) {
      return true;
    }

    const currentFilter = mapFiltersToURLParams(normalizedFilters, filter);
    const filterQuery = queryString.stringify(currentFilter, { arrayFormat: 'comma' });

    const clearedFilter = mapFiltersToURLParams(normalizedFilters, customClearedFilter);
    const clearedFilterQuery = queryString.stringify(clearedFilter, {
      arrayFormat: 'comma',
    });

    return filterQuery !== clearedFilterQuery;
  }
);
