import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip, Button } from 'antd';
import queryString from 'query-string';
//constants
import { PIPELINE_REPORTS } from '@optx/constants/exportedFileName';
// utils
import mapFilterToURLParams from '@utils/filters/mapFiltersToURLParams';
import { exportFileCSV } from '@utils/csvHandlerPipeline';
// redux
import { selectors } from '@features/pipeline-report/state';
import styled from 'styled-components';

const ExportCSVBtn = styled(Button)`
  display: inline-flex;
  width: 100%;
  span {
    margin-left: -3px;
  }
`;

interface ExportCSVButtonProps {
  onChangeVisible?: () => void;
}

const ExportCSVButton: React.FC<ExportCSVButtonProps> = ({ onChangeVisible }) => {
  const { filter, normalized } = useSelector(selectors.getFilters);
  const pipelineReportCompanies = useSelector(selectors.pipelineReportCompanies);
  const [isLoading, setIsLoading] = useState(false);

  const isDisabled = isLoading || pipelineReportCompanies.length === 0;

  const handleExportCSV = () => {
    const params = mapFilterToURLParams(normalized, filter);

    const query = queryString.stringify(
      { ...params, ...(filter?.fund ? { fund: filter.fund } : {}), country: undefined },
      {
        arrayFormat: 'comma',
      }
    );

    setIsLoading(true);

    exportFileCSV(PIPELINE_REPORTS, query).then(() => {
      setIsLoading(false);
    });

    onChangeVisible && onChangeVisible();
  };

  return (
    <Tooltip title="Up to 100K rows" placement="left" trigger={['click', 'hover']}>
      <ExportCSVBtn
        type="text"
        style={{
          display: 'inline-flex',
          width: '100%',
        }}
        onClick={handleExportCSV}
        disabled={isDisabled}
      >
        {isLoading ? 'Exporting' : 'Export to .csv'}
      </ExportCSVBtn>
    </Tooltip>
  );
};

export default ExportCSVButton;
