import React, { useEffect, useState, useContext } from 'react';
import { components, IndicatorProps, ValueContainerProps } from 'react-select';
import { useSelector } from 'react-redux';
import { useField } from 'formik';
import AsyncSelect from 'react-select/async';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
// models
import { SuccessErrorCallback } from '@models/callback';
import { SelectOption } from '@models/Option';
import { EquityTouchDatabaseMatch } from '@models/equityTouch';
import { CompanyProfile } from '@models/Company';
// constants
import { GET_ET_COMPANIES_ENDPOINT } from '@constants/asyncEndpoints';
// redux
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
// hooks
import useAsyncSearch from '@hooks/select/useAsyncSearch';
// styled components
import Styles from '@features/company/add-to-equity-touch/components/EquityTouchAddon.style';
import { Context } from '@optx/screens/App/EquityTouchBulk/components/common/Context';

/**
 * customValueContainer - style the search area of the dropdown.
 */
const ValueContainer: React.FC<ValueContainerProps<any>> = ({ children, ...restProps }) => (
  <components.ValueContainer {...restProps}>
    <span className="multiselect-select__value-container__icon">
      <SearchOutlined />
    </span>
    {children}
  </components.ValueContainer>
);

/**
 * customDropdownIndicator - style the arrow of the dropdown.
 */
const DropdownIndicator: React.FC<IndicatorProps<any>> = props => (
  <components.DropdownIndicator {...props}>
    <DownOutlined />
  </components.DropdownIndicator>
);
interface BulkEquityTouchAddonProps {
  name: string;
  readOnly?: boolean;
}

const BulkEquityTouchAddon: React.FC<BulkEquityTouchAddonProps> = ({ name, readOnly }) => {
  const { step, addon: addonContext } = useContext(Context);
  const companyData = useSelector(bulkActionsSelectors.equityTouch.getCompanyData);
  const companiesById = useSelector(bulkActionsSelectors.equityTouch.getCompanies);
  const bulkValues = useSelector(bulkActionsSelectors.equityTouch.getBulkValues);
  const temporaryData = useSelector(bulkActionsSelectors.equityTouch.getTemporaryData);
  const selectOption = { value: '', label: 'Select' };
  const [defaultOptions, setDefaultOptions] = useState<
    Array<EquityTouchDatabaseMatch | SelectOption>
  >([selectOption]);
  const [value, setValue] = useState<SelectOption>();
  const [, , helpers] = useField(name);

  const { loadOptions } = useAsyncSearch({ endpoint: GET_ET_COMPANIES_ENDPOINT });

  useEffect(() => {
    if (Object.keys(companyData).length) {
      const addon = companiesById?.[(companyData as CompanyProfile)?.company_id]?.[
        'Add-On For'
      ] as SelectOption;

      if (addon && !defaultOptions.find(option => (option as SelectOption).value === addon.value)) {
        setDefaultOptions([...defaultOptions, addon]);
      }

      setValue(addon);
      helpers.setValue(addon);

      if (temporaryData?.step2) {
        const tempDataAddonFor = (
          temporaryData.step2[(companyData as CompanyProfile)?.company_id] as any
        )?.['Add-On For'] as SelectOption;

        if (tempDataAddonFor !== value) {
          setValue(addon);
          helpers.setValue(addon);
        }
      }
    }

    if (step === 0) {
      const bulkAddon =
        bulkValues['Add-On For'] || addonContext || temporaryData.step1?.['Add-On For'];
      setValue(bulkAddon as SelectOption);

      if (bulkAddon) {
        setDefaultOptions([...defaultOptions, bulkAddon as SelectOption]);
      } else {
        setDefaultOptions([...defaultOptions]);
      }

      helpers.setValue(bulkAddon);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companiesById, companyData]);

  const handleChange = (value: any) => {
    // add the option if it doesn't already exist
    if (!defaultOptions.find(option => (option as SelectOption).value === value.value)) {
      setDefaultOptions([...defaultOptions, value]);
    }

    if (value.value === '') {
      setValue(undefined);
      helpers.setValue(undefined);

      return;
    }

    setValue(value);
    helpers.setValue(value);
  };

  const getLoadOptionsDelay = (query: string, callback: SuccessErrorCallback) => {
    if (query.length > 2) {
      loadOptions(query, options => callback(options));
    }
  };

  return (
    <Styles.AsyncWrapper>
      <AsyncSelect
        cacheOptions
        loadOptions={getLoadOptionsDelay}
        defaultOptions={defaultOptions}
        value={value}
        onChange={handleChange}
        classNamePrefix="multiselect-select"
        components={{
          ValueContainer,
          DropdownIndicator,
        }}
        isDisabled={readOnly}
      />
    </Styles.AsyncWrapper>
  );
};

export default BulkEquityTouchAddon;
