import React from 'react';
import FixedLoader from '../../../common/loader';

interface PageLoaderProps {
  isLoading?: boolean;
}

const PageLoader: React.FC<PageLoaderProps> = ({ isLoading }) => {
  return <FixedLoader loading={isLoading} />;
};

export default PageLoader;
