import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer } from 'redux-injectors';
import { useCallback } from 'react';
import {
  actions as recentActivityModalActions,
  selectors as recentActivityModalSelectors,
} from '../../../redux/ui/modals/recent-activity';
import uiReducer from '../../../redux/ui';

const useRecentActivityModal = (): [boolean, VoidFunction] => {
  useInjectReducer({
    key: 'ui',
    reducer: uiReducer,
  });
  const open = useSelector(recentActivityModalSelectors.displayDialog);
  const dispatch = useDispatch();
  const toggle = useCallback(
    () => dispatch(recentActivityModalActions.toggleRecentActivityModal()),
    [dispatch]
  );

  return [open, toggle];
};

export default useRecentActivityModal;
