import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, actions } from '../../../redux/user/information';
import useInitialFetch from '../fetch/useInitialFetch';

// separate user information initialization for readability
const useFetchUserInformation = () => {
  const shouldFetch = useSelector(selectors.shouldFetch);
  const dispatch = useDispatch();
  const fetchUserInformation = useCallback(
    () => dispatch(actions.fetchUserInformation()),
    [dispatch]
  );
  useInitialFetch(shouldFetch, fetchUserInformation);
};

export default useFetchUserInformation;
