import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dictionary } from 'lodash';
import { Modal } from 'antd';
// models
import { PreselectedFilter } from '@optx/models/filters';
// utils
import validationSchema from '@utils/filtersValidationSchema';
import { normalizeFilter } from '@optx/utils/filters/filterHelpers';
// redux
import { selectors as searchSelectors } from '@features/grid/search';
import { actions as filterCardsActions } from '@optx/redux/my-companies/filters-cards';
import { selectors as companyFiltersSelectors } from '@redux/company/filters';
import { selectors as modalSelectors, actions as modalActions } from '@redux/ui/modals/gridModals';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
// hooks
import useSaveViewMessage from '@optx/common/hooks/filters/useSaveViewMessage';
// components
import FiltersModalContent from './FiltersModalContent';
import HistogramLoader from './HistogramLoader';

const FiltersModal = () => {
  const dispatch = useDispatch();
  const filter = useSelector(filterSelectors.getFilter('myCompanies'));
  const keySearch = useSelector(searchSelectors.getSearchKey('myCompanies'));
  const filterSources = useSelector(companyFiltersSelectors.getCompanyFilters);
  const defaultFilters = useSelector(companyFiltersSelectors.getDefaultFilters);

  const onSearch = useCallback(
    (searchKey: string | undefined, filter: Dictionary<PreselectedFilter>) => {
      dispatch(
        filterCardsActions.fetchMyCompaniesCards({
          searchKey,
          filter,
          shouldHandleTouchFilters: true,
          shouldResetPageNumber: true,
        })
      );
    },
    [dispatch]
  );

  // modal
  const modalIsOpen = useSelector(modalSelectors.isOpen('myCompanies'));
  const toggleModalFilters = () => dispatch(modalActions.toggleModal({ gridKey: 'myCompanies' }));
  useSaveViewMessage(modalIsOpen && !!Object.keys(defaultFilters).length, toggleModalFilters);

  const handleSearch = (key: string | undefined, filter: Dictionary<PreselectedFilter>) => {
    const normalizedFilter = normalizeFilter(filter);
    onSearch(key, normalizedFilter);
    toggleModalFilters();
  };

  return (
    <Modal
      className="ant-modal-full ant-modal--filters my-companies--filters"
      title={null}
      visible={modalIsOpen}
      footer={null}
      onCancel={toggleModalFilters}
      closable={false}
      destroyOnClose
    >
      <HistogramLoader />
      <FiltersModalContent
        filter={filter}
        filterSources={filterSources}
        keySearch={keySearch}
        onSearch={handleSearch}
        validationSchema={validationSchema}
        onClose={toggleModalFilters}
        modalIsOpen={modalIsOpen}
      />
    </Modal>
  );
};

export default FiltersModal;
