import NotificationService from '@optx/services/NotificationService';
import { AxiosResponse } from 'axios';
import saveAs from 'file-saver';

export const excelHandler = (
  payload: {},
  fileName: string,
  exportToXls: (data: {}, fileName: string) => Promise<AxiosResponse<any>>
) => {
  return exportToXls(payload, fileName)
    .then(response => {
      saveAs(response.data, `Export ${fileName}.xlsx`);
    })
    .catch(() => {
      NotificationService.error('Export XLSX file error');
    });
};
