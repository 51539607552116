import React from 'react';
import { Col, Tooltip } from 'antd';
import styled from 'styled-components';
// utils
import { styledColors } from '@optx/utils/theme/colors';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// components
import Icon from '@optx/components/common/Icon';

const PenIcon = styled(Icon)<{ $visible?: boolean }>`
  cursor: pointer;

  :hover {
    color: ${styledColors.blue6};
  }
`;

interface InformationValueProps {
  isEditable?: boolean;
  fieldKey?: string;
  initialValue: string;
}

const EditFieldText: React.FC<InformationValueProps> = ({
  isEditable = false,
  fieldKey,
  initialValue,
}) => {
  const value = initialValue === 'None' ? DEFAULT_EMPTY_VALUE : initialValue;

  return (
    <>
      <Col
        className={
          isEditable ? 'user-profile-information__edit' : 'user-profile-information__value'
        }
      >
        <Tooltip title={fieldKey !== 'password' && value}>{value}</Tooltip>
        {isEditable && <PenIcon iconName="pen" />}
      </Col>
    </>
  );
};

export default EditFieldText;
