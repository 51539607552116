import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { reducers as fetchReducers } from '@redux/feature/fetch';

import * as actions from './actions';
import { EditSeniorDealTeamLead, SeniorDealTeamLeadState } from './interfaces';

export const initialState: SeniorDealTeamLeadState = {
  companyId: null,
  error: null,
  loading: false,
};

const addSeniorDealTeamLead: CaseReducer<
  SeniorDealTeamLeadState,
  PayloadAction<EditSeniorDealTeamLead>
> = (draftState, action) => {
  const { companyId } = action.payload;
  draftState.companyId = companyId;
  draftState.loading = true;
};

const addSeniorDealTeamLeadSuccess: CaseReducer<SeniorDealTeamLeadState> = draftState => {
  draftState.loading = false;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.addSeniorDealTeamLead, addSeniorDealTeamLead)
    .addCase(actions.addSeniorDealTeamLeadSuccess, addSeniorDealTeamLeadSuccess)
    .addCase(actions.addSeniorDealTeamLeadFail, fetchReducers.fetchFailReducer)
);

export default reducer;
