import { call, put, takeLatest, select } from 'redux-saga/effects';
import { isEqual } from 'lodash';
import { PayloadAction } from '@reduxjs/toolkit';

// models
import { SearchPayloadRequest } from '@optx/models/api/contacts';
import { PageInfo } from '@optx/models/table/Pagination';
import { SelectOption } from '@optx/models/Option';
import { GetGridSearchData, GridKeysMain, GridPayload } from '@models/grid';
// utils
import { getRouteAlias } from '@optx/utils/routes';
// redux
import { selectors as searchSelectors } from '@features/grid/search';
import { actions as longCardPaginationActions } from '../pagination-long-card';
import { selectors as listsSelectors } from '@redux/lists/details';
import { searchCompanies, searchCompaniesFail } from '@features/grid/search/state/sagasReusable';
import { getGridSearchDataSaga, userInfoUpdateSaga } from '@features/grid/state/sagasReusable';
import { selectors as companyOutreachFilterSelectors } from '@redux/company-outreach/filters';
import { getViewId } from '@optx/features/grid/view/state/selectors';
import { getListType } from '@optx/features/grid/search/state/selectors';

function* changeLongCardPaginationSaga(action: PayloadAction<GridPayload<PageInfo>>) {
  const { gridKey, data: pagination } = action.payload;

  const gridSearchData: GetGridSearchData = yield call(getGridSearchDataSaga, gridKey, pagination);
  const { searchKey, filter, originalFilter, sortBy } = gridSearchData;
  const location = getRouteAlias();
  const updatedGridKey =
    location === 'userLists' ? 'watchlists' : location === 'sourceScrubLists' ? 'sslists' : gridKey;
  const id: number = yield select(getViewId(updatedGridKey));
  const listType: string = yield select(getListType(gridKey as GridKeysMain));

  const searchData: SearchPayloadRequest = {
    searchKey,
    filter,
    pagination,
    sortBy,
    fromSavedList: id,
    listType,
  };
  const searchName: string = yield select(searchSelectors.getSearchName(gridKey as GridKeysMain));
  const isSourceScrubRoute: PageInfo = yield select(listsSelectors.isSourceScrubRoute);

  if (gridKey === 'outreach') {
    const tableGridQuery: string = yield select(searchSelectors.getTableGridQuery('outreach'));

    const analystIdFilter: [string | null, string | null] | SelectOption<string> | undefined =
      yield select(companyOutreachFilterSelectors.getSourcingOptitonsFilterValues('analyst_id'));
    const columnNameSourcingOutReachFilter:
      | [string | null, string | null]
      | SelectOption<string>
      | undefined = yield select(
      companyOutreachFilterSelectors.getSourcingOptitonsFilterValues('column_name')
    );

    // @ts-ignore
    const listOfAnalystIds: number[] = analystIdFilter?.map(
      (list: { label: string; value: number }) => list.value
    );

    const sourcingOutReachColumnName = (columnNameSourcingOutReachFilter as SelectOption)?.value;

    const searchDataOutreach = {
      ...searchData,
      analystId: listOfAnalystIds?.toString(),
      tableGridQuery,
      sourcingOutReachColumnName,
    };

    try {
      const userSettings: { [key: string]: number } = yield call(
        userInfoUpdateSaga,
        gridKey,
        pagination,
        true
      );

      // fetch companies and update user pagination settings on server if page size or page number has changed
      yield call(
        searchCompanies,
        searchDataOutreach,
        gridKey,
        originalFilter,
        undefined,
        undefined,
        isEqual(userSettings, {}) ? false : userSettings
      );

      yield put(longCardPaginationActions.changeLongCardPaginationSuccess(action.payload));
    } catch (error) {
      yield call(searchCompaniesFail, gridKey, error);
    }
  } else {
    if (gridKey !== 'watchlists' && gridKey !== 'sslists' && gridKey !== 'lists') {
      try {
        yield call(
          searchCompanies,
          searchData,
          gridKey as GridKeysMain,
          originalFilter,
          undefined,
          searchName,
          false
        );
        yield put(longCardPaginationActions.changeLongCardPaginationSuccess(action.payload));
      } catch (error: any) {
        yield call(searchCompaniesFail, gridKey as GridKeysMain, error);
      }
    } else {
      try {
        yield call(searchCompanies, searchData, 'lists', originalFilter);
        yield put(
          longCardPaginationActions.changeLongCardPaginationSuccess({
            gridKey: isSourceScrubRoute ? 'sslists' : 'watchlists',
            data: action.payload.data,
          })
        );
      } catch (error: any) {
        yield call(searchCompaniesFail, 'lists', error);
      }
    }
  }
}

export default function* companiesSaga() {
  yield takeLatest(
    longCardPaginationActions.changeLongCardPagination,
    changeLongCardPaginationSaga
  );
}
