import { LoginHeaders } from '@optx/models/auth';
import ApiClient, { API_URL } from './axios-client';

class AuthService {
  static client: ApiClient = new ApiClient(`${API_URL}/auth`);

  /**
   * Login/check authentication token.
   * @param payload
   */
  static login = (payload: { headers: LoginHeaders }) => {
    // @ts-ignore
    return AuthService.client.get('/token', { headers: payload.headers });
  };

  static getCognitoURL = () => AuthService.client.get('/cognito_login_url');

  static getCognitoLogOutURL = () => AuthService.client.get('/cognito_logout_url');

  static logout = () => AuthService.client.post('/logout', {});
}

export default AuthService;
