import Option, { DateRangeOption, SelectOption } from '@optx/models/Option';

interface Pagination {
  pageNumber: number;
  pageSize: number;
}

export interface ScheduledTouchesState {
  dashboard: ScheduledTouchesDashboardState;
  touches: TouchesState;
  count: ScheduledTouchesCount;
  sortBy: ScheduledTouchesSortBy;
  pagination: Pagination;
  stageType: string;
  touchType: string;
  rankType: string;
  selectedTab: string;
  selectedPeriod: string;
  remainedTouches: number;
  totalTouches: number;
  insight: Record<string, number>;
  insightTotal: number;
  selectedDateRage: [string | null, string | null];
}

export interface ScheduledTouchesFilterTags {
  stageType: string;
  selectedPeriod: string;
  selectedDateRange: [string | null, string | null];
  touchType: string;
  rankType: string;
}

export interface ScheduledTouchesDashboardState {
  inputDates: DateRangeOption[];
  cards: Period;
  tabs: Tabs;
  stages: {
    name: string;
    value: string;
  }[];
  ranks: string[];
  touchTypes: Option[];
  loading: boolean;
  error: string | null;
  fetchedAt: string | null;
}

export interface TouchesState {
  data: ScheduledTouch[] | null;
  loading: boolean;
  error: string | null;
  fetchedAt: string | null;
}

export interface ScheduledTouchesCount {
  data: ScheduledTouchesCountResponse | null;
  loading: boolean;
  error: string | null;
  fetchedAt: string | null;
}

export interface ScheduledTouchesCountResponse {
  cards: CardsCount;
  tabs: TabsCount;
  insight: Record<string, number>;
}

export interface Period {
  [key: string]: DateRangeOption[];
}

export interface Cards {
  card1: DateRangeOption;
  card2?: DateRangeOption;
}

export interface Tabs {
  [key: string]: string;
}

export interface CardCount {
  scheduled: number | null;
  past_due: number | null;
}

export interface CardsCount {
  [key: string]: CardCount;
}

export interface TabsCount {
  [key: string]: number | null;
}

export interface DashboardSuccess {
  input_dates: DateRangeOption[];
  cards: Period;
  tabs: Tabs;
  touch_types: Option[];
  pipeline_rank: string[];
  stages: {
    name: string;
    value: string;
  }[];
  sortBy?: ScheduledTouchesSortBy;
}

export interface ScheduledTouchesResponse {
  count: number;
  remained_touch_count: number;
  result: ScheduledTouch[];
}

export interface ScheduledTouch {
  company_id: number;
  company_name: string;
  score: number | null;
  il_optx_score: number | null;
  score_growth: number | null;
  il_score_growth: number | null;
  last_touch_date: string | null;
  next_touch_date: string[];
  created_date: string;
  logo_url: string | null;
  stage: string | null;
  pipeline_rank: number | string | null;
  subject: string | null;
  is_psg_company: boolean | null;
  touch_start_date: string;
  touch_status: 'Scheduled' | 'Past Due' | 'Completed';
  touch_type:
    | 'Voice Mail'
    | 'Contact Import'
    | 'Document Upload'
    | 'CrunchBase'
    | 'Stage Modified'
    | 'E-mail received'
    | 'Internal Note'
    | 'Meeting'
    | 'NDA Signed'
    | 'Call'
    | 'E-mail';
}

export enum ScheduledTouchesSortByEnum {
  'score.desc' = 'OPTX Score descending',
  'score.asc' = 'OPTX Score ascending',
  'touch_start_date.desc' = 'Date Newest to Oldest',
  'touch_start_date.asc' = 'Date Oldest to Newest',
}

export type ScheduledTouchesSortBy = keyof typeof ScheduledTouchesSortByEnum;

export const SCHEDULED_TOUCHES_DEFAULT_SORT: ScheduledTouchesSortBy = 'touch_start_date.asc';

export const scheduledTouchesSortBy = (defaultOptxScore: string) =>
  Object.entries(ScheduledTouchesSortByEnum).reduce((acc: Array<SelectOption>, [key, value]) => {
    if (defaultOptxScore === 'il') {
      const ilItem = { label: '', value: '' };

      if (key === 'score.desc') {
        ilItem.value = 'il_optx_score.desc';
        ilItem.label = 'IL OPTX Score descending';
        acc.push(ilItem);

        return acc;
      }

      if (key === 'score.asc') {
        ilItem.value = 'il_optx_score.asc';
        ilItem.label = 'IL OPTX Score ascending';
        acc.push(ilItem);

        return acc;
      }
    }

    acc.push({ label: value, value: key });

    return acc;
  }, []);
