import React from 'react';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
// models
import { CompanyProfile } from '@models/Company';
// redux
import { actions as favoriteActions } from '@redux/favorites';

interface CompanyRankProps {
  company: CompanyProfile;
}

const CompanyRank: React.FC<CompanyRankProps> = ({ company }) => {
  const dispatch = useDispatch();

  // favorites
  const isFavorite = company.is_in_fav_list;
  const addFavorite = (companyId: number) => dispatch(favoriteActions.addFavorite(companyId));
  const deleteFavorite = (companyId: number) => dispatch(favoriteActions.deleteFavorite(companyId));

  const onSelectFavorites = (company: CompanyProfile, isFavor: boolean) => {
    if (isFavor) {
      addFavorite(company.company_id);
    } else {
      deleteFavorite(company.company_id);
    }
  };

  return (
    <div className="company-rank">
      <button
        type="button"
        className="btn-start"
        onClick={() => onSelectFavorites(company, !isFavorite)}
      >
        {isFavorite ? <StarFilled /> : <StarOutlined className="unfavorited" />}
      </button>
      <div className="rank">
        Pipeline Rank
        <span>{company.pipeline_rank || '-'}</span>
      </div>
    </div>
  );
};

export default CompanyRank;
