import { takeLatest, select, put, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
// models
import { SearchSave } from '@optx/models/search';
import { ColumnDisplay } from '@optx/models/table/Columns';
// utils
import { getVisibleColumns, getPinnedColumns, getNewSorting } from '@optx/utils/columns';
import { matchView, hasFilters } from '@optx/utils/search';
// redux
import { saveColumnWidthSaga, resetColumnWidthsSaga } from '@optx/redux/grid';
import { actions as userInformationActions } from '@redux/user/information';
import {
  selectors as savedSearchSelectors,
  actions as savedSearchesActions,
} from '@redux/company/saved-searches';
import { selectors as searchSelectors, getFiltersQuery } from '@redux/company/search/search';
import { actions as searchActions } from '@features/grid/search';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import * as sortActions from '@redux/company/search/sort/actions';
import columns from '@components/common/table/Companies/columns/virtual-column-config';
import { Dictionary } from 'lodash';
import { PreselectedFilter } from '@optx/models/filters';
import { SortByRule } from '@optx/models/table/sorting';
import * as actions from './actions';

export function* saveColumnsSaga(action: PayloadAction<ColumnDisplay>) {
  const filter: Dictionary<PreselectedFilter> = yield select(
    filterSelectors.getFilter('advancedSearch')
  );
  const sortBy: SortByRule<any>[] = yield select(searchSelectors.getSorting);
  const searchKey: string = yield select(searchSelectors.getSearchKey);
  const views: SearchSave[] = yield select(savedSearchSelectors.getAllViews);
  const { defaultOptxView } = yield select(savedSearchSelectors.getViews);
  const visibleColumns = getVisibleColumns(action.payload);
  const columnOrder =
    action.payload.columnOrder === null ? null : action.payload.columnOrder.join();
  const pinnedColumns = getPinnedColumns(action.payload.columnDisplay);

  const filterQuery: Dictionary<string | (string | number)[]> = yield call(getFiltersQuery, filter);

  let title = '';
  let view;

  if (!title) {
    const { matchedTitle, matchedView } = matchView(
      { filter: filterQuery, sortBy, searchKey, pagination: { pageSize: 50, pageNumber: 1 } },
      visibleColumns,
      columnOrder,
      pinnedColumns,
      views,
      defaultOptxView
    );
    title = matchedTitle || '';
    view = matchedView;
  }

  if (title) {
    if (
      hasFilters((view as SearchSave).search_criteria) &&
      view?.unique_id.toString() !== 'default'
    ) {
      yield put(searchActions.saveSearchTitle({ gridKey: 'advancedSearch', data: title }));
    }

    const { unique_id: id, title: label } = view as SearchSave;
    yield put(savedSearchesActions.applyViewSuccess({ id, label, pageAlias: 'advancedSearch' }));
  } else {
    yield put(searchActions.saveSearchTitle({ gridKey: 'advancedSearch', data: '' }));
    yield put(
      savedSearchesActions.applyViewSuccess({ id: '', label: '', pageAlias: 'advancedSearch' })
    );
  }

  yield put(
    userInformationActions.updateUserSettings({
      company_display_columns: visibleColumns,
      company_column_order: action.payload.columnOrder.join(),
      company_pinned_columns: getPinnedColumns(action.payload.columnDisplay),
    })
  );
  const newSort = getNewSorting(sortBy, action.payload, columns);

  if (newSort) {
    yield put(sortActions.changeSortAction({ sortBy: [newSort] }));
  }
}

export default function* columnDisplaySaga() {
  yield takeLatest(actions.saveColumns, saveColumnsSaga);
  yield takeLatest(actions.saveColumnWidth, saveColumnWidthSaga);
  yield takeLatest(actions.resetColumnWidths, resetColumnWidthsSaga);
}
