import { EditingFieldsState } from './interfaces';

export const getResults = (state: EditingFieldsState) => state.results;

export const isInProgress = (state: EditingFieldsState) => state.inProgress;
export const isCompleted = (state: EditingFieldsState) => state.completed;
export const getPercentageCompleted = (state: EditingFieldsState) => state.percentageCompleted;
export const getSelectedCompaniesCount = (state: EditingFieldsState) =>
  state.selectedCompaniesCount;
export const cancel = (state: EditingFieldsState) => state.cancel;
