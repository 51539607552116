import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import reducer, { saga } from '@redux/company/addon-management';
import { saga as histogramSaga } from '@features/histograms/histograms-addon-management';

const useInjectCompanyAddonManagement = () => {
  useInjectReducer({
    key: 'companyAddonManagement',
    reducer,
  });
  useInjectSaga({
    key: 'companyAddonManagement',
    saga,
  });
  useInjectSaga({
    key: 'histogramsAddonManagement',
    saga: histogramSaga,
  });
};

export default useInjectCompanyAddonManagement;
