import { takeLatest, call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { CompanyTouchesOptionsResponse } from '@optx/models/company/CompanyTouch';
import { CompanyService } from '@services/api';
import NotificationService from '@services/NotificationService';
import { getErrorMessage } from '@utils/api/errors';
import * as actions from '../actions/filters';

export function* fetchCompanyTouchesFiltersSaga(action: PayloadAction<number>) {
  const companyId = action.payload;

  try {
    const res: AxiosResponse<CompanyTouchesOptionsResponse> = yield call(
      CompanyService.fetchTouchesFilters,
      companyId
    );
    yield put(actions.fetchTouchesFiltersSuccess(res.data));
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Error fetch company touches filters!');
    yield put(actions.fetchTouchesFiltersFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* touchesFiltersSaga() {
  yield takeLatest(actions.fetchTouchesFilters.type, fetchCompanyTouchesFiltersSaga);
}
