export const FETCH_ALERTS_INBOX = 'FETCH_ALERTS_INBOX';
export const FETCH_ALERTS_HISTORY = 'FETCH_ALERTS_HISTORY';
export const FETCH_ALERTS_SUCCESS = 'FETCH_ALERTS_SUCCESS';
export const FETCH_ALERTS_FAIL = 'FETCH_ALERTS_FAIL';
export const FETCH_TOUCHES = 'FETCH_TOUCHES';
export const FETCH_TOUCHES_SUCCESS = 'FETCH_TOUCHES_SUCCESS';
export const FETCH_TOUCHES_FAIL = 'FETCH_TOUCHES_FAIL';
export const LOAD_MORE_ALERTS = 'LOAD_MORE_ALERTS';
export const SORT_ALERTS_HISTORY = 'SORT_ALERTS_HISTORY';

export const UPDATE_ALERTS = 'UPDATE_ALERTS';
export const DISMISS_ALERT = 'DISMISS_ALERT';
export const DISMISS_ALERT_SUCCESS = 'DISMISS_ALERT_SUCCESS';
export const DELETE_UPCOMING_TOUCH = 'DELETE_UPCOMING_TOUCH';
export const DELETE_UPCOMING_TOUCH_SUCCESS = 'DELETE_UPCOMING_TOUCH_SUCCESS';
export const DELETE_UPCOMING_TOUCH_FAIL = 'DELETE_UPCOMING_TOUCH_FAIL';

export const DELETE_PAST_DUE_TOUCH = 'DELETE_PAST_DUE_TOUCH';
export const DELETE_PAST_DUE_TOUCH_SUCCESS = 'DELETE_PAST_DUE_TOUCH_SUCCESS';
export const DELETE_PAST_DUE_TOUCH_FAIL = 'DELETE_PAST_DUE_TOUCH_FAIL';
export const LOAD_MORE_PAST_DUE_TOUCHES = 'LOAD_MORE_PAST_DUE_TOUCHES';
