import { createAction } from '@reduxjs/toolkit';
// models
import * as types from './types';
import { BulkAiMl, BulkAiMlSuccess } from './interfaces';

export const bulkAiMl = createAction<BulkAiMl>(types.bulkAiMl);
export const bulkAiMlSuccess = createAction<{
  data: BulkAiMlSuccess[];
  count: number;
  successCount: number;
}>(types.bulkAiMlSuccess);
