import { combineReducers } from 'redux';
import { CompanyIndividualEditFeatureState } from './interfaces';
import companyIndividualEditReducer from './companyIndividualEdit';
import companyOwnerReducer from './companyOwner';

const reducer = combineReducers<CompanyIndividualEditFeatureState>({
  companyIndividualEdit: companyIndividualEditReducer,
  companyOwner: companyOwnerReducer,
});

export default reducer;
