/**
 * Convert string to URL representation.
 * @param url url string.
 */
export function toURL(url: string) {
  if (/.*http.*/.test(url)) {
    return url;
  }

  return `//${url}`;
}

/**
 * remove double slashes from the start of urls
 * main use case is for urls coming from api with this format "//website.com"
 * @param url url string
 */
export const stripUrlSlashes = (url: string) => url.replace('//', '');

/**
 * Convert string to URL representation.
 * @param url url string.
 */
export function convertToURL(url: string): string {
  if (!url) return '#';

  if (url.startsWith('//')) return `http:${url}`;

  if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
    return `http://${url}`;
  }

  return url;
}

export function sliceURLQuery(url: string) {
  const startsWithHttp = url.startsWith('http://');
  const startsWithHttps = url.startsWith('https://');
  const endWithSlash = url.endsWith('/');
  const startWithWWW = url.startsWith('wwww.');

  let result: string = url;

  if (startWithWWW || startsWithHttp || startsWithHttps) {
    if (startsWithHttp) {
      result = `${result.slice(7)}`;
    }

    if (startsWithHttps) {
      result = `${result.slice(8)}`;
    }

    if (endWithSlash) {
      result = `${result.slice(0, -1)}`;
    }
  }

  return result;
}

/**
 * Get multiple parameters from the current URL
 * @param {string} params The parameters from the URL
 */
export const getParametersFromURL = (params: string[]) =>
  params.map(param => new URLSearchParams(window.location.search).get(param));

/**
 * Removes a specific parameter from the current URL and updates the URL without reloading the page.
 * @param {string} param The parameter to be removed from the URL
 */
export const removeParameterFromURL = (param: string) => {
  // Get the current URL
  const url = new URL(window.location.href);

  // Get the search parameters from the URL
  const searchParams = new URLSearchParams(url.search);

  // If parameter doesn't exist in URL, just ignore the call
  if (!searchParams.get(param)) return;

  // Remove the specific parameter
  searchParams.delete(param);

  // Create a new URL with the updated search parameters
  const newUrl = `${url.origin}${url.pathname}?${searchParams.toString()}`;

  // Replace the current URL without reloading the page
  window.history.replaceState(null, '', newUrl);
};

/**
 * Removes all parameters from the current URL and updates the URL without reloading the page.
 */
export const removeAllParametersFromURL = () => {
  // Get the current URL
  const url = new URL(window.location.href);

  // Get the search parameters from the URL
  const searchParams = new URLSearchParams(url.search);

  // If parameters don't exist in URL, just ignore the call
  // We will also ignore this line because TS doesn't recognize size as a property of URLSearchParams,
  // but it is supported on almost every browser engine.
  // @ts-ignore
  if (!searchParams.size) return;

  // Create a new URL with the updated search parameters
  const newUrl = `${url.origin}${url.pathname}`;

  // Replace the current URL without reloading the page
  window.history.replaceState(null, '', newUrl);
};

/**
 * Updates the current history state. This doesn't create the state if it didn't previously exist.
 * @param stateFieldKey
 * @param stateFieldValue
 */
export const updateHistoryState = (stateFieldKey: string, stateFieldValue: any) => {
  const state = window.history.state;

  if (state) {
    state.state[stateFieldKey] = stateFieldValue;

    window.history.replaceState(state, '');
  }
};
