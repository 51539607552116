import { AxiosResponse } from 'axios';
import { Dictionary } from 'lodash';
import columns from '@features/pipeline-report/components/Table/columns';
import { AnalystService } from '../services/api';
import NotificationService from '../services/NotificationService';

interface ExportPipelineColumns {
  columns: Dictionary<string>;
}

export const exportFileCSV = (fileName: string, query: string) => {
  const gridHeaders: ExportPipelineColumns = { columns: {} };
  columns.forEach(column => {
    const headerKey = column.dataIndex as string;
    const title = column.title;

    gridHeaders.columns[headerKey] = title as string;

    if (column.dataIndex === 'company_url') {
      gridHeaders.columns.company_url = 'URL';
    }
  });

  return AnalystService.exportPipelineToCSV(query, gridHeaders)
    .then((response: AxiosResponse<string>) => {
      const element = document.createElement('a');

      element.setAttribute(
        'href',
        `data:application/csv;charset=utf-8, ${encodeURIComponent(response.data)}`
      );
      element.setAttribute('download', `${fileName}.csv`);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      NotificationService.success('Export CSV file success!');
    })
    .catch(() => {
      NotificationService.error('Export CSV file error');
    });
};
