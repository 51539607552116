import { LineProps } from 'recharts';

export const lines: Array<LineProps> = [
  {
    dataKey: 'selectedPeriod',
    stroke: '#096DD9',
    name: 'Selected Period',
  },
  {
    dataKey: 'previousPeriod',
    stroke: '#FA541C',
    name: 'Previous Week',
  },
];
