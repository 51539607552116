import React from 'react';

import { Styled } from './LabelValuePair.styled';

interface ListItemLabelValuePair {
  label: React.ReactNode;
  value: React.ReactNode;
}

export const LabelValuePair: React.FC<ListItemLabelValuePair> = ({ label, value }) => {
  return (
    <Styled.Wrapper>
      <Styled.Label>{label}</Styled.Label>
      <Styled.Value>{value}</Styled.Value>
    </Styled.Wrapper>
  );
};
