import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Formik, FormikValues, FormikProps } from 'formik';
import { Dictionary } from 'lodash';
import { Row, Col, Button, Typography, Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
// models
import { FilterSource, PreselectedFilter, Filter } from '@optx/models/filters';
import { GridKeys } from '@optx/models/grid';
// utils
import { SuccessErrorCallback } from '@optx/models/callback';
import { createGeoRangeGroup } from '@utils/filters/filterGeoRange';
import { createLastDataUpdateGroup } from '@optx/utils/filters/filterLastDataUpdate';
// redux
import { HistogramRefreshToggler } from '@optx/features/histograms/base-histograms';
import { actions as histogramFiltersActions } from '@optx/features/histograms/histograms-advanced-search';
import { selectors as searchSelectors } from '@redux/company/search/search';
// components
import SearchInputTemp from './SearchInputTemp';
import FiltersForm from './FiltersForm';
import {
  IFiltersContext,
  FiltersContext,
} from '@optx/components/feature/company-filters/FiltersContext';
import ShowingTitle from '@optx/components/common/ShowingTitle';

const { Title } = Typography;

interface FilterFormProps {
  filter: Dictionary<PreselectedFilter>;
  gridName: 'Advanced Search' | 'Companies OutReach';
  filterSources: Array<FilterSource>;
  // clear
  onClearFilter?: (shouldSort: boolean, callback: SuccessErrorCallback) => void;
  //   search
  keySearch: string;
  onSearch: (key: string, filter: Dictionary<PreselectedFilter>) => void;
  validationSchema?: any;
  onClose?: () => void;
  modalIsOpen: boolean;
  gridKey: GridKeys;
}

const FiltersModalContent: React.FC<FilterFormProps> = ({
  filter,
  filterSources,
  onClearFilter,
  keySearch,
  onSearch,
  validationSchema,
  onClose,
  gridName,
  modalIsOpen,
  gridKey,
}) => {
  const dispatch = useDispatch();
  // histogram response
  const histograms = useSelector(searchSelectors.selectEditHistograms);
  const filtersRefresh = useSelector(searchSelectors.histogramsEnabled);

  // search input
  const [inputSearch, setInputSearch] = useState(keySearch);

  // fetch histogram and updated filters
  const fetchHistograms = useCallback(
    (filter: Dictionary<PreselectedFilter>) =>
      dispatch(histogramFiltersActions.fetchHistogramFilters(filter)),
    [dispatch]
  );

  // form
  const formRef = useRef<FormikProps<FormikValues> | null>(null);

  // formik submit handler
  const handleSubmit = () => {
    handleSearch(inputSearch);
  };

  // manually trigger submit
  const triggerSubmit = () => {
    dispatch(histogramFiltersActions.applyEdit());

    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  // search
  const handleSearch = (searchKey: string) => {
    if (formRef.current) {
      // Make any parent state updates with batched state updates.
      // This happens in an async callback or any event from WebAPIs https://developer.mozilla.org/en-US/docs/Web/Events
      // More info: https://github.com/facebook/react/issues/14259

      if (!searchKey && formRef.current!.values) {
        formRef.current!.values = { ...formRef.current!.values, query: '' };
      }

      ReactDOM.unstable_batchedUpdates(() => onSearch(searchKey, formRef.current!.values));
    }
  };

  // when a filter is selected/changed/checked, save it's name to use afterwards.
  // this function is used inside each filter component. Eg: checkbox, radio, select, etc.
  const onFilterFieldChange = (fieldName: string) => {
    dispatch(histogramFiltersActions.filterChanged(fieldName, formRef.current?.values));
  };

  const filtersContext: IFiltersContext = { histograms, onManualFilterChange: onFilterFieldChange };

  // used to add histogram and disabled properties and display updated filters
  const filterSourcesModified = useMemo(
    () =>
      filterSources.map(filterGroup => {
        const newFilterGroup: FilterSource<Filter<any>> = { ...filterGroup };
        const newGeoGroupFilterData: Array<Filter<any>> = createGeoRangeGroup(newFilterGroup.data);
        const newLastUpdateGroupFilterData: Array<Filter<any>> =
          createLastDataUpdateGroup(newGeoGroupFilterData);

        newFilterGroup.data = newLastUpdateGroupFilterData;

        return newFilterGroup;
      }),
    [filterSources]
  );

  useEffect(() => {
    setInputSearch(keySearch);
  }, [keySearch]);

  const handleKeyChange = (newKey: string) => {
    setInputSearch(newKey);
  };

  const handleResetSearch = (shouldSort: boolean) => {
    onClearFilter &&
      onClearFilter(shouldSort, () => {
        // use same function to reset and clear filters and sorting
        // send a boolean to check if only reseting
        // filters or also resetting sorting
        if (shouldSort) {
          setInputSearch('');
        }

        if (formRef.current) {
          formRef.current.resetForm();
        }

        // Can be moved outside later and handled in saga.
        // make a new call to getHistogram to get all values without filters
        fetchHistograms({});
      });
  };

  const handleClose = () => {
    onClose && onClose();
    // clear temporary state
    formRef.current && formRef.current.resetForm(filter);
    setInputSearch('');

    dispatch(histogramFiltersActions.edit(false));
  };

  useEffect(() => {
    if (modalIsOpen) {
      // Enable edit mode for histograms.
      dispatch(histogramFiltersActions.edit(modalIsOpen));
    }
  }, [dispatch, modalIsOpen]);

  const handleToggleHistogramRefresh = (enable: boolean) => {
    if (enable) {
      dispatch(histogramFiltersActions.toggleRefresh(enable, formRef.current?.values));
    } else {
      dispatch(histogramFiltersActions.toggleRefresh(enable));
    }
  };

  return (
    <FiltersContext.Provider value={filtersContext}>
      <div
        className={classnames('filter__wrapper', { outreach: gridName === 'Companies OutReach' })}
      >
        <div className="filter-submit-wrapper">
          <SearchInputTemp
            onSearch={handleSearch}
            onChange={handleKeyChange}
            keySearch={inputSearch}
          />

          <Row style={{ padding: '20px 40px' }}>
            <Col xs={24} md={6} lg={12}>
              <Space align="center" style={{ width: '100%', flexWrap: 'wrap' }}>
                <Title level={2} style={{ fontWeight: 'normal', margin: 0 }}>
                  Filters
                </Title>
                <ShowingTitle gridKey={gridKey} />
              </Space>
            </Col>
            <Col xs={24} md={18} lg={12}>
              <Space style={{ width: '100%', justifyContent: 'flex-end' }} align="center">
                <Button type="default" size="large" onClick={() => handleResetSearch(true)}>
                  Deselect All
                </Button>
                <Button type="default" size="large" onClick={() => handleResetSearch(false)}>
                  Restore Defaults
                </Button>
                <Button type="primary" size="large" onClick={triggerSubmit}>
                  Apply Filters
                </Button>
                <Button
                  type="link"
                  icon={<CloseOutlined />}
                  size="large"
                  onClick={handleClose}
                  style={{ color: '#000' }}
                />
              </Space>
            </Col>
          </Row>
        </div>
        <Row justify="end" style={{ padding: '20px 80px 0', textAlign: 'right' }}>
          {gridName === 'Advanced Search' && (
            <Col span={5}>
              <HistogramRefreshToggler
                checked={filtersRefresh}
                onChange={handleToggleHistogramRefresh}
              />
            </Col>
          )}
        </Row>
        <Formik
          initialValues={filter}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={validationSchema}
          innerRef={formRef}
          validateOnChange
          validateOnBlur
        >
          <FiltersForm filterSources={filterSourcesModified} />
        </Formik>
      </div>
    </FiltersContext.Provider>
  );
};

export default FiltersModalContent;
