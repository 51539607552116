import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import * as actions from './actions';
import { MyPipelineState } from './interfaces';

export const initialState: MyPipelineState = {
  initialTab: '2',
  loading: false,
};

// set initial lists tab number
const setInitialListTabReducer: CaseReducer<MyPipelineState, PayloadAction<string>> = (
  draftState,
  action
) => {
  draftState.initialTab = action.payload;
};

const setIsLoadingReducer: CaseReducer<MyPipelineState, PayloadAction<boolean>> = (
  draftState,
  action
) => {
  draftState.loading = action.payload;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.setInitialListTab, setInitialListTabReducer)
    .addCase(actions.setLoading, setIsLoadingReducer)
);

export default reducer;
