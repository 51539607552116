import { combineReducers } from 'redux';
// models
import { UIModalsState } from './interfaces';
// reducers
import displayColumnDialogReducer from './display-column';
import comanyNotesModal from './company-notes';
import comanyTouchesModal from './company-touches';
import UserInvitationModal from './user-invitation';
import userEditModal from './user-edit';
import myWatchListsCSVErrorModal from './my-watchlist-csv-error';
import editProfileContactReducer from './edit-profile-contact';
import saveSearchReducer from './save-search';
import gridToggleReducer from './gridModals';
import documentUploadTouchModalReducer from './add-document-upload-touch';
import recentActivityModalReducer from './recent-activity';

const reducer = combineReducers<UIModalsState>({
  displayColumn: displayColumnDialogReducer,
  companyNotes: comanyNotesModal,
  companyTouches: comanyTouchesModal,
  userInvitation: UserInvitationModal,
  userEdit: userEditModal,
  myWatchListCSVError: myWatchListsCSVErrorModal,
  editProfileContact: editProfileContactReducer,
  saveSearch: saveSearchReducer,
  gridModals: gridToggleReducer,
  documentUploadTouchModal: documentUploadTouchModalReducer,
  recentActivity: recentActivityModalReducer,
});

export default reducer;
