import { CompanyNotification } from '@optx/models/Company';
import { createAction } from '@reduxjs/toolkit';
import * as types from './types';
import * as models from './interfaces';

export const searchNotes = createAction<models.NotesSearchQuery | undefined>(
  types.SEARCH_COMPANY_NOTES
);

export const searchNotesSuccess = createAction(
  types.SEARCH_COMPANY_NOTES_SUCCESS,
  (notes: Array<CompanyNotification>, queryData: models.NotesSearchQuery) => ({
    payload: notes,
    meta: queryData,
  })
);

export const searchNotesFail = createAction<string>(types.SEARCH_COMPANY_NOTES_FAIL);

export const updateNote = createAction(
  types.UPDATE_COMPANY_NOTE,
  (note: CompanyNotification, text: string) => ({
    payload: note,
    meta: text,
  })
);

export const updateNoteSuccess = createAction(
  types.UPDATE_COMPANY_NOTE_SUCCESS,
  (note: CompanyNotification, text: string) => ({
    payload: note,
    meta: text,
  })
);

export const updateNoteFail = createAction<string>(types.UPDATE_COMPANY_NOTE_FAIL);

export const resetSearch = createAction(types.RESET_SEARCH_COMPANY_NOTES);
