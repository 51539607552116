/**
 * Default render check for Table cell props equality.
 * If the column is editable, it always re-renders the cell to get updated data.
 * @param {object} prevProps previous props.
 * @param {object} nextProps next props.
 * @returns {boolean} props are equal.
 */

import Company from '@optx/models/Company';

interface memoizeCell {
  record: Company;
  value: any;
}

export const cellIsEqual = (prevProps: memoizeCell, nextProps: memoizeCell) => {
  return prevProps.value === nextProps.value;
};
