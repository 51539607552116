import React from 'react';
import { useSelector } from 'react-redux';
// components
import FixedLoader from '@components/common/loader';
// redux
import { selectors } from '../state';

const PageLoader = () => {
  const loading = useSelector(selectors.isLoading);

  return <FixedLoader loading={loading} />;
};

export default PageLoader;
