import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col } from 'antd';
// hooks
import { useInjectSalesloft } from '@hooks/inject';
import { useSalesloftAuth } from '@optx/common/hooks/salesloft';
// redux
import { useInject as useInjectCompanyIndividualEdit } from '@components/feature/company-individual-edit';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import {
  actions as salesloftActions,
  selectors as salesloftSelectors,
} from '@redux/contacts/salesloft';
import { actions as bulkSalesloftActions } from '@features/bulk-actions/salesloft';
import { actions as selectedCompaniesActions } from '@features/bulk-actions/selected-companies';
// models
import { SalesloftCadences } from '@optx/models/Salesloft';
// components
import { SalesloftBulkWrapper, SalesloftCardsRow } from './SalesloftBulk.styled';
import Header from './header/Header';
import AddCadence from './salesloft/AddCadence';
import SelectedCompanies from './salesloft/SelectedCompanies';
import { Context } from './common/Context';
import FinalStep from './bulk-progress/FinalStep';

const SalesloftBulk = () => {
  useInjectSalesloft();
  useInjectCompanyIndividualEdit();

  const dispatch = useDispatch();
  const cadences = useSelector(salesloftSelectors.getCadences);

  const inProgress = useSelector(bulkActionsSelectors.salesloft.inProgress);
  const [cadence, setCadence] = useState<SalesloftCadences | undefined>(undefined);

  const { fetchSalesloftAuth } = useSalesloftAuth();
  // @ts-ignore
  const { grid } = useParams();
  const selectedCompanies = useSelector(
    bulkActionsSelectors.selectedCompanies.getSelectedCompanies(grid)
  );
  const fetchCadences = useCallback(
    (payload: string, cb?: () => void) =>
      dispatch(salesloftActions.fetchSalesloftCadences(payload, cb)),
    [dispatch]
  );

  // when user refreshes page show an alert popup to warn the user
  const confirmRefresh = (e: BeforeUnloadEvent) => {
    e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    // Chrome requires returnValue to be set
    // eslint-disable-next-line no-param-reassign
    e.returnValue = '';
  };

  const initBulkSalesloft = useCallback(() => {
    dispatch(bulkSalesloftActions.fetchCompaniesContacts(grid));
  }, [dispatch, grid]);

  useEffect(() => {
    if (cadences.length <= 1) {
      fetchCadences('', () => {
        fetchSalesloftAuth('', url => {
          window.open(
            url,
            'popUpWindow',
            // eslint-disable-next-line max-len
            'height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
          );
        });
      });
    }

    initBulkSalesloft();

    window.addEventListener('beforeunload', confirmRefresh);

    return () => {
      window.removeEventListener('beforeunload', confirmRefresh);
      dispatch(bulkSalesloftActions.resetProgress());

      dispatch(
        selectedCompaniesActions.selectCompanyIds({
          gridName: grid,
          selectedCompanies,
        })
      );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Context.Provider
      value={{
        cadence,
        setCadence,
      }}
    >
      {!inProgress ? (
        <SalesloftBulkWrapper>
          <Header />
          <SalesloftCardsRow gutter={20}>
            <Col span={12}>
              <SelectedCompanies />
            </Col>
            <Col span={12}>
              <AddCadence />
            </Col>
          </SalesloftCardsRow>
        </SalesloftBulkWrapper>
      ) : (
        <FinalStep />
      )}
    </Context.Provider>
  );
};

export default SalesloftBulk;
