import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Divider, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
// models
import { SalesloftContact } from '@optx/models/Salesloft';
// utils
import { validateContactLimit } from '@optx/utils/contact';
import { contactFilterOption, reorderPrimaryContact } from '@optx/utils/select/select';
// redux
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { actions as bulkActions } from '@features/bulk-actions/salesloft';
// components
import ContactOptionIcon from '@optx/shared/view/molecules/contacts/ContactOptionIcon';
import Styled from '@optx/shared/view/molecules/Select/Styled/ContactSelectWrapper.styled';
import ContactOption from '@optx/shared/view/molecules/contacts/ContactOption';
import { CompanyUserContact } from '@optx/models/api/contacts';
import ContactTags from '@optx/shared/view/molecules/contacts/ContactTags';

const { Option } = Select;

interface SelectContactProps {
  contacts: SalesloftContact[];
  companyId: number;
  isCell?: boolean;
  showAddContactForm?: () => void;
}

export const SelectContacts: React.FC<SelectContactProps> = ({
  contacts,
  companyId,
  isCell,
  showAddContactForm,
}) => {
  const dispatch = useDispatch();
  const companies = useSelector(bulkActionsSelectors.salesloft.salesloftBulkCompanies);
  const company = companies.find(company => company.company_id === companyId);
  const [selectedContacts, setSelectedContacts] = useState<number[]>([]);

  useEffect(() => {
    if (company && company.salesloft_contacts) {
      setSelectedContacts(
        reorderPrimaryContact(
          (company.salesloft_contacts as SalesloftContact[]).map(
            contact => contact.person_id as number
          ),
          contacts
        )
      );
    }
  }, [company, contacts]);

  const handleSelection = (value: number, type: 'select' | 'deselect') => {
    const contact = contacts.find(contact => contact.person_id === value);

    if (
      type === 'select' &&
      company &&
      company?.salesloft_contacts &&
      !validateContactLimit(company.salesloft_contacts.length + 1)
    ) {
      return;
    }

    dispatch(
      bulkActions.salesloftSelectContactsBulk({
        id: value,
        companyId,
        type,
        selectedContactData: contact,
      })
    );
  };

  return (
    <div style={{ position: 'relative', overflow: 'visible' }}>
      <Styled.SelectWrapper
        showArrow
        placeholder="Select contacts"
        onSelect={(value, option) => handleSelection(Number(value), 'select')}
        onDeselect={(value, option) => handleSelection(Number(value), 'deselect')}
        filterOption={contactFilterOption}
        optionFilterProp="children"
        mode="multiple"
        value={selectedContacts}
        tagRender={props => <ContactTags {...props} />}
        menuItemSelectedIcon={ContactOptionIcon}
        isCell={isCell}
        className="ant-select-customize-input"
        dropdownMatchSelectWidth={250}
        getPopupContainer={trigger => trigger.parentNode.parentNode}
        dropdownRender={menu => (
          <>
            {menu}
            {showAddContactForm && (
              <>
                <Divider style={{ margin: '8px 0' }} />
                <Button
                  type="link"
                  onClick={showAddContactForm}
                  style={{ color: 'rgba(0,0,0,.85)', fontWeight: 600 }}
                >
                  <PlusOutlined style={{ color: '#1890FF', margin: '0 5px 0 -3px' }} /> Add new
                  contact
                </Button>
              </>
            )}
          </>
        )}
      >
        {contacts.map(contact => (
          <Option
            key={contact.person_id as number}
            value={contact.person_id as number}
            className="contact-option"
          >
            <ContactOption contact={contact as CompanyUserContact} />
          </Option>
        ))}
      </Styled.SelectWrapper>
      <Styled.ContactSelectGlobal />
    </div>
  );
};
