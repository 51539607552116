import React, { useCallback, useEffect, useState, CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { GoogleMap, MarkerClusterer, Marker, useLoadScript } from '@react-google-maps/api';
import { ClustererOptions } from '@react-google-maps/marker-clusterer';
import GLOBAL_CONFIG from '@optx/constants/config';
import { selectors } from '../../state';
import { fitToLocations, worldViewFit } from '../../utils/maps';

const containerStyle: CSSProperties = {
  width: '100%',
  height: 500,
};

const mapOptions: google.maps.MapOptions = {
  minZoom: 2.5,
};

const clusterOptions: ClustererOptions = {
  // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
  imagePath:
    'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
};

const TouchesMap = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GLOBAL_CONFIG.short_date.GOOGLE_MAPS_API_KEY,
  });
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const locations = useSelector(selectors.touchesAcrossRegion.getLocations);

  /**
   * Update zoom level every time new locations are loaded.
   */
  useEffect(() => {
    if (map) {
      if (!locations.length) {
        if (!map.getBounds()) {
          // Need to wait for the first bounds_changed event on the map before the bounds is defined and fit map.
          google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
            worldViewFit(map);
          });
        } else {
          worldViewFit(map);
        }
      } else {
        // Set zoom to locations bound.
        const mapLocations = locations.map(location => location.coordinates);

        if (!map.getBounds()) {
          // Need to wait for the first bounds_changed event on the map before the bounds is defined and fit map.
          google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
            fitToLocations(map, mapLocations);
          });
        } else {
          fitToLocations(map, mapLocations);
        }
      }
    }
  }, [locations, map]);

  // wrapping to a function is useful in case you want to access `window.google`
  // to eg. setup options or create latLng object, it won't be available otherwise
  // feel free to render directly if you don't need that
  const onLoad = useCallback(function onLoad(mapInstance: google.maps.Map) {
    setMap(mapInstance);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  if (isLoaded) {
    return (
      <GoogleMap
        options={mapOptions}
        mapContainerStyle={containerStyle}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <MarkerClusterer options={clusterOptions}>
          {
            clusterer =>
              // @ts-ignore
              locations.map((location, index) => (
                <Marker
                  key={index}
                  position={location.coordinates}
                  clusterer={clusterer}
                  title={location.company_name}
                  noClustererRedraw
                />
              ))
            // eslint-disable-next-line react/jsx-curly-newline
          }
        </MarkerClusterer>
      </GoogleMap>
    );
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return <>Loading...</>;
};

const MemoizedTouchesMap = React.memo(TouchesMap);

export default MemoizedTouchesMap;
