import { createAction } from '@reduxjs/toolkit';
import * as models from '@optx/models/Notes';
import * as types from './types';
import { ProfileNotes } from './interfaces';

// get notes for profile page
export const fetchProfileNotes = createAction<models.FetchNote>(types.FETCH_PROFILE_NOTES);

export const fetchProfileNotesSuccess = createAction<{
  data: ProfileNotes;
  sorting: models.noteSorting;
}>(types.FETCH_PROFILE_NOTES_SUCCESS);

export const fetchProfileNotesFail = createAction<string>(types.FETCH_PROFILE_NOTES_FAIL);

export const pinNote = createAction(
  types.PIN_NOTE,
  (note: models.PinNote, callback: (companyid: number) => void) => ({ payload: note, callback })
);

export const editNote = createAction<models.EditNote>(types.EDIT_PROFILE_NOTE);
export const editNoteSuccess = createAction<models.EditNote>(types.EDIT_PROFILE_NOTE_SUCCESS);

export const deleteProfileNote = createAction<{ companyId: number; pinId: number }>(
  types.DELETE_PROFILE_NOTE
);
export const deleteProfileNoteFail = createAction<string>(types.DELETE_PROFILE_NOTE_FAIL);
