import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { actions as listsActions } from '@redux/lists/details';

// filter saved lists
export function* filterCompanyListSaga(action: PayloadAction<string>) {
  yield put(listsActions.filterCompanyListSuccess(action.payload));
}

export default function* companySearchFiltersSaga() {
  yield takeLatest(listsActions.filterCompanyList, filterCompanyListSaga);
}
