import { combineReducers } from 'redux';
// reducers
import { reducer as websiteRankReducer } from './website-rank';
import { reducer as g2DataReducer } from './g2';
import { reducer as glassDoorDataReducer } from './glassdoor';
import { reducer as linkedinDataReducer } from './linkedin';
import { reducer as crunchbaseDataReducer } from './crunchbase';
import { reducer as twitterDataReducer } from './twitter';
import { reducer as facebookDataReducer } from './facebook';
import { reducer as capterraDataReducer } from './capterra';

import { SocialMediaState } from './interfaces';

const reducer = combineReducers<SocialMediaState>({
  websiteRankData: websiteRankReducer,
  g2Data: g2DataReducer,
  glassDoorData: glassDoorDataReducer,
  linkedinData: linkedinDataReducer,
  crunchbaseData: crunchbaseDataReducer,
  twitterData: twitterDataReducer,
  facebookData: facebookDataReducer,
  capterraData: capterraDataReducer,
});

export default reducer;
