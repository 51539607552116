import { combineReducers } from 'redux';

import cognitoLoginReducer from './cognitoLogin';
import cognitoLogoutReducer from './cognitoLogout';
import { AuthState } from './interfaces';

export default combineReducers<AuthState>({
  cognitoLogin: cognitoLoginReducer,
  cognitoLogout: cognitoLogoutReducer,
});
