import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// models
import { FilterTag } from '@optx/models/tags';
// redux
import { selectors as filterSelectors } from '@features/grid/filter';
import { actions as searchActions } from '@features/grid/search';
import { actions as filtersModalActions } from '@redux/ui/modals/gridModals';
// components
import { FilterTagsOverflow } from '@optx/features/tags/overflow-tags';

const isTagClosable = (item: FilterTag) => {
  if (item.label === 'EquityTouch') {
    return false;
  }

  return true;
};

const FilterTags = () => {
  const dispatch = useDispatch();
  const options = useSelector(filterSelectors.getTags('myCompanies'));
  const toggleFiltersModal = useCallback(
    () => dispatch(filtersModalActions.toggleModal({ gridKey: 'myCompanies' })),
    [dispatch]
  );

  const handleFilterRemove = (tag: FilterTag) => () => {
    dispatch(
      searchActions.removeFilter({
        gridKey: 'myCompanies',
        data: { selectedTag: tag, filterTags: filteredOptions },
      })
    );
  };

  const filteredOptions = useMemo(
    () => options.filter(item => !item.isDefault && item.values?.some(value => !value.isDefault)),
    [options]
  );

  // Don't return null or anything prior to this return. The ref will be lost and max displayed index logic won't work.
  return (
    <FilterTagsOverflow
      options={filteredOptions}
      onClick={toggleFiltersModal}
      onClose={handleFilterRemove}
      closable={(item: FilterTag) => isTagClosable(item)}
      multipleValue
    />
  );
};

export default FilterTags;
