import { createSelector } from 'reselect';
import * as localSelectors from '../companyIndividualEdit/selectors';
import * as companyOwnerSelectors from '../companyOwner/selectors';

import { selectFeatureState } from './selectFeature';

const selectCompanyIndividualEditState = createSelector(selectFeatureState, state => {
  return state.companyIndividualEdit;
});

const selectCompanyOwnerState = createSelector(selectFeatureState, state => {
  return state.companyOwner;
});

// slice selectors
const isLoadingInfo = createSelector(selectCompanyIndividualEditState, localSelectors.isLoading);
const isLoadingCompanyOwnerInfo = createSelector(
  selectCompanyOwnerState,
  companyOwnerSelectors.isLoading
);

const error = createSelector(selectCompanyIndividualEditState, localSelectors.getError);

const isSubmitting = createSelector(selectCompanyIndividualEditState, localSelectors.isSubmitting);

const getAllOptions = createSelector(selectCompanyIndividualEditState, localSelectors.getData);

const failedLoadingInfo = createSelector(
  selectCompanyIndividualEditState,
  localSelectors.failedLoadingInfo
);

const rankOptions = createSelector(
  selectCompanyIndividualEditState,
  localSelectors.getSelectOptionsByField('rank')
);

const companyOwnerOptions = createSelector(
  selectCompanyIndividualEditState,
  localSelectors.getSelectOptionsByField('deal_team_leader')
);

const dealTeamOptions = createSelector(
  selectCompanyIndividualEditState,
  localSelectors.getSelectOptionsByField('deal_team')
);

const leadSourceOptions = createSelector(
  selectCompanyIndividualEditState,
  localSelectors.getSelectOptionsByField('source')
);

const seniorAdvisorOptions = createSelector(
  selectCompanyIndividualEditState,
  localSelectors.getSelectOptionsByField('senior_advisor_thesis')
);

const stageOptions = createSelector(
  selectCompanyIndividualEditState,
  localSelectors.getSelectOptionsByField('stage')
);

const fundOptions = createSelector(
  selectCompanyIndividualEditState,
  localSelectors.getSelectOptionsByField('fund')
);

const shouldInitialFetch = createSelector(
  selectCompanyIndividualEditState,
  localSelectors.shouldInitialFetch
);

const sectorOptions = createSelector(
  selectCompanyIndividualEditState,
  localSelectors.getSelectOptionsByField('sector')
);

const subSectorOptions = createSelector(
  selectCompanyIndividualEditState,
  localSelectors.getSelectOptionsByField('sub_sector')
);

const phoneOptions = createSelector(
  selectCompanyIndividualEditState,
  localSelectors.getSelectOptionsByField('person_phone_type')
);

const opportunityTypeOptions = createSelector(
  selectCompanyIndividualEditState,
  localSelectors.getSelectOptionsByField('opportunity_type')
);

const yearFoundedOptions = createSelector(
  selectCompanyIndividualEditState,
  localSelectors.getYearFoundedOptions
);

export default {
  isLoadingInfo,
  isLoadingCompanyOwnerInfo,
  error,
  isSubmitting,
  failedLoadingInfo,
  rankOptions,
  companyOwnerOptions,
  stageOptions,
  fundOptions,
  shouldInitialFetch,
  getAllOptions,
  sectorOptions,
  subSectorOptions,
  phoneOptions,
  leadSourceOptions,
  seniorAdvisorOptions,
  dealTeamOptions,
  opportunityTypeOptions,
  yearFoundedOptions,
};
