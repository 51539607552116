import { createAction } from '@reduxjs/toolkit';
import { Dictionary } from 'lodash';
import {
  CompanyWatchList,
  DeleteCompanyFromWatchList,
  UpdateListPermission,
  FetchFavoriteLists,
  AddCompanyToWatchListBulk,
  UpdateListAssociation,
  PublicWatchListActionPayload,
} from '@models/WatchList';
import { SortByRule, SortRule } from '@models/table/sorting';
import { PageInfo } from '@optx/models/table/Pagination';
import * as types from './types';

// initial fetch favorite lists
export const initialFetchFavoritesLists = createAction<FetchFavoriteLists | undefined>(
  types.INITIAL_FETCH_FAVORITE_LISTS
);

export const initialFetchFavoritesListsSuccess = createAction(
  types.INITIAL_FETCH_FAVORITE_LISTS_SUCCESS,
  (payload: {
    data: Array<CompanyWatchList>;
    total: number;
    sortBy: Array<SortByRule<any>>;
    showEmptyLists: boolean;
  }) => ({
    payload: {
      data: payload.data,
      total: payload.total,
      sortBy: payload.sortBy,
      showEmptyLists: payload.showEmptyLists,
    },
  })
);

// fetch favorite lists
export const fetchFavoriteLists = createAction<FetchFavoriteLists | undefined>(
  types.FETCH_FAVORITE_LISTS
);
export const fetchFavoriteListsSuccess = createAction(
  types.FETCH_FAVORITE_LISTS_SUCCESS,
  (payload: {
    data: Array<CompanyWatchList>;
    query: string;
    total: number;
    pagination: PageInfo;
    fetchAll?: boolean;
    sortBy: Array<SortByRule<any>>;
    showEmptyLists: boolean;
  }) => ({
    payload: {
      data: payload.data,
      query: payload.query,
      total: payload.total,
      pagination: payload.pagination,
      fetchAll: payload.fetchAll,
      showEmptyLists: payload.showEmptyLists,
      sortBy: payload.sortBy,
    },
  })
);

export const fetchFavoriteListsFail = createAction<string>(types.FETCH_FAVORITE_LISTS_FAIL);

// sort favorite lists
export const sortFavoriteLists = createAction<{ data: SortRule<any>; hideHistory?: boolean }>(
  types.SORT_FAVORITE_LISTS
);

// create favorite list
export const createFavoriteList = createAction<{
  title: string;
  company_id: number;
}>(types.CREATE_FAVORITE_LIST);
export const createFavoriteListSuccess = createAction<{
  companyId: number;
  list: CompanyWatchList;
}>(types.CREATE_FAVORITE_LIST_SUCCESS);
export const createFavoriteListFail = createAction<string>(types.CREATE_FAVORITE_LIST_FAIL);

// delete favorite list
export const deleteFavoriteList = createAction<number | string>(types.DELETE_FAVORITE_LIST);
export const deleteFavoriteListSuccess = createAction<number | string>(
  types.DELETE_FAVORITE_LIST_SUCCESS
);
export const deleteFavoriteListFail = createAction<string>(types.DELETE_FAVORITE_LIST_FAIL);

// update favorite list count after bulk remove
export const updateFavoriteListCount = createAction<{ list_id: number | string; count: number }>(
  types.UPDATE_FAVORITE_LIST_COUNT
);

// rename favorite list
export const renameFavoriteList = createAction<{ list_id: number | string; title: string }>(
  types.RENAME_FAVORITE_LIST
);
export const renameFavoriteListSuccess = createAction<{ list_id: number | string; title: string }>(
  types.RENAME_FAVORITE_LIST_SUCCESS
);
export const renameFavoriteListFail = createAction<string>(types.RENAME_FAVORITE_LIST_FAIL);

// delete company from list
export const deleteCompanyFromList = createAction<{ list_id: string; company_id: number }>(
  types.DELETE_COMPANY_FROM_LIST
);
export const deleteCompanyFromListSuccess = createAction<DeleteCompanyFromWatchList>(
  types.DELETE_COMPANY_FROM_LIST_SUCCESS
);
export const deleteCompanyFromListFail = createAction<string>(types.DELETE_COMPANY_FROM_LIST_FAIL);

// remove shared list from watchlist
export const removeSharedList = createAction<string | number>(types.REMOVE_SHARED_LIST);
export const removeSharedListSuccess = createAction<number | string>(
  types.REMOVE_SHARED_LIST_SUCCESS
);
export const removeSharedListFail = createAction<string>(types.REMOVE_SHARED_LIST_FAIL);

// add company in list
export const addCompanyToList = createAction<{
  selectedLists: { list_title: string; list_id: number | null }[];
  company_id: number;
  error_on_conflict?: boolean;
}>(types.ADD_COMPANY_TO_LIST);
export const addCompanyToListSuccess = createAction(
  types.ADD_COMPANY_TO_LIST_SUCCESS,
  (companyId: number, watchLists: CompanyWatchList[], ownerName?: string) => ({
    payload: { companyId, watchLists, ownerName },
  })
);
export const addCompanyToListFail = createAction<string>(types.ADD_COMPANY_TO_LIST_FAIL);

// save search as list
export const saveSearchAsList = createAction<{ title: string; page?: number }>(
  types.SAVE_SEARCH_AS_LIST
);
export const saveSearchAsListSuccess = createAction<Dictionary<CompanyWatchList>>(
  types.SAVE_SEARCH_AS_LIST_SUCCESS
);
export const saveSearchAsListFail = createAction<string>(types.SAVE_SEARCH_AS_LIST_FAIL);

// pagination
export const favoriteListsPagination = createAction<PageInfo>(types.FAVORITE_LIST_PAGINATION);

// update
export const updateFavoriteListPermission = createAction<UpdateListPermission>(
  types.UPDATE_FAVORITE_LIST_PERMISSION
);

export const resetFetchedAt = createAction(types.resetFetchedAt);

export const addCompanyToListBulkActionSuccess = createAction<AddCompanyToWatchListBulk>(
  types.ADD_COMPANY_TO_LIST_BULK_SUCCESS
);
export const updateListCount = createAction<number>(types.UPDATE_LIST_COUNT);

// associate company to list (add-on)
export const updateFavoriteListAssociation = createAction<UpdateListAssociation>(
  types.UPDATE_FAVORITE_LIST_ASSOCIATION
);
export const updateFavoriteListAssociationSuccess = createAction<UpdateListAssociation>(
  types.UPDATE_FAVORITE_LIST_ASSOCIATION_SUCCESS
);
export const updateFavoriteListAssociationFail = createAction<string>(
  types.UPDATE_FAVORITE_LIST_ASSOCIATION_FAIL
);

// actions for chunk approach to save search as list

export const updateCompleted = createAction<boolean>(types.updateCompleted);

export const updatePercentage = createAction<number>(types.updatePercentage);

export const saveTitle = createAction<string>(types.saveTitle);

export const tryAgainSaveSearchAsList = createAction(types.tryAgainSaveSearchAsList);

export const cancelSaveSearchAsList = createAction(types.cancelSaveSearchAsList);

export const resetSaveSearchAsList = createAction(types.resetSaveSearchAsList);

// public watch list
export const publicWatchList = createAction<PublicWatchListActionPayload>(types.PUBLIC_WATCH_LIST);
export const publicWatchListSuccess = createAction<PublicWatchListActionPayload>(
  types.PUBLIC_WATCH_LIST_SUCCESS
);
export const publicWatchListFail = createAction<string>(types.PUBLIC_WATCH_LIST_FAIL);
