import React, { memo } from 'react';
import { PieChartOutlined } from '@ant-design/icons';
// components
import StatsSection from './StatsSection/StatsSection';
import { InsightChart } from './Insight';
import Styled from './TouchTypeInsight.style';

const TouchTypeInsight = () => {
  return (
    <Styled.Wrapper>
      <StatsSection icon={<PieChartOutlined style={{ fontSize: 34 }} />} title="Touch Type Insight">
        <InsightChart />
      </StatsSection>
    </Styled.Wrapper>
  );
};

export default memo(TouchTypeInsight);
