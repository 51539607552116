import React, { useCallback, useEffect, useState } from 'react';
import { Dictionary } from 'lodash';
import moment from 'moment';
import { Col, Radio, Row, Typography } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { RadioChangeEvent } from 'antd/lib/radio';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FormikProps, useField } from 'formik';
import { Form, Input, DatePicker, Select, Checkbox, FormItem } from 'formik-antd';
import { OptionTypeBase } from 'react-select';
// models
import { TouchesCreateForm } from '@models/api/touches';
import globalConfig from '@optx/constants/config';
import { BaseFilter } from '@optx/models/filters';
import Company from '@optx/models/Company';
import CompanyTouch from '@optx/models/company/CompanyTouch';
import { PsgParticipant } from '@models/api/user';
// constants
import { ISO_DATE_FORMAT } from '@optx/constants/format/date';
import { TOUCH_TIME_FORMAT } from './touchInitialValues';
// redux
import {
  selectors as companyTouchesModalSelectors,
  actions as companyTouchesModalActions,
} from '@redux/ui/modals/company-touches';
import { actions as documentUploadModalActions } from '@redux/ui/modals/add-document-upload-touch';
// Components
import ContactSelect from '@shared/view/molecules/Select/SelectContacts';
import ContactOptionIcon from '@optx/shared/view/molecules/contacts/ContactOptionIcon';
import TextareaEditor from '@shared/view/molecules/TextareaEditor';
import { Styled } from './TouchInformation.styled';

interface TouchInformationProps {
  sources: FormikProps<Partial<TouchesCreateForm>>;
  isChromeExtension?: boolean;
  editTouch?: CompanyTouch | null;
}
const { Option } = Select;

interface DateRadio {
  end: string;
  start: string;
  label: string;
}
interface TouchType {
  value: string;
  name: string;
  parent?: string;
}

// set Time interval
const minutesInterval = 15;
const start = new Date();
const missingMinutes = minutesInterval - (moment(start).minute() % minutesInterval);
const startTime = moment(start).add(missingMinutes, 'minutes');
const endTime = moment(start).add(missingMinutes, 'minutes').add(minutesInterval, 'minutes');

const TouchInformation: React.FC<TouchInformationProps> = ({
  sources,
  editTouch,
  isChromeExtension,
}) => {
  const dispatch = useDispatch();

  const modalTitle = useSelector(companyTouchesModalSelectors.getModalTitle);
  const psgParticipants = useSelector(companyTouchesModalSelectors.getPsgParticipants);

  const company = useSelector(companyTouchesModalSelectors.getTouchCompany);
  const touchFilters = useSelector(
    companyTouchesModalSelectors.getTouchFilters
  ) as Dictionary<BaseFilter>;
  const record = company as Company;
  const [editComplete, setEditComplete] = useState<boolean>(false);

  useEffect(() => {
    if (editTouch && editTouch.touchIsComplete === true) {
      setEditComplete(true);
    } else {
      setEditComplete(false);
    }
  }, [editTouch]);

  const [types, setTypes] = useState<TouchType[]>(
    touchFilters?.touches_types?.data || [{ value: 'select', name: 'Select Type' }]
  );
  const [dateRadio, setDateRadio] = useState<DateRadio[]>(
    touchFilters?.datetime_period?.data || [
      {
        start: moment(startTime).format(ISO_DATE_FORMAT),
        end: moment(startTime).format(ISO_DATE_FORMAT),
        label: 'Custom',
      },
    ]
  );
  useEffect(() => {
    if (touchFilters?.touches_types) {
      setTypes(touchFilters.touches_types.data);
    }

    if (touchFilters?.datetime_period) {
      setDateRadio(touchFilters.datetime_period.data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touchFilters]);

  const dateInterval = dateRadio.map(date => moment(date.end).format(ISO_DATE_FORMAT));

  const [tab, , setTabType] = useField<string>('touchType');

  const handleTabChange = (e: RadioChangeEvent) => {
    setTabType.setValue(e.target.value);
    helpersScheduleTouch.setValue(false);
    setCheckedSchedule(false);
  };

  useEffect(() => {
    if (editComplete) {
      modalTitle !== 'Edit Touch Details' &&
        dispatch(companyTouchesModalActions.changeModalTitle('Edit Touch Details'));
    } else if (tab.value === 'complete') {
      modalTitle !== 'Log Complete Touch' &&
        dispatch(companyTouchesModalActions.changeModalTitle('Log Complete Touch'));
    } else if (editTouch) {
      modalTitle !== 'Edit Touch Details' &&
        dispatch(companyTouchesModalActions.changeModalTitle('Edit Touch Details'));
    } else {
      modalTitle !== 'Schedule Next Touch' &&
        dispatch(companyTouchesModalActions.changeModalTitle('Schedule Next Touch'));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.value, editComplete]);

  const [typeId, , setTypeId] = useField<string>('typeId');
  const [, , setType] = useField<string>('type');

  useEffect(() => {
    if (editTouch && editTouch.touchtype && types) {
      const newTypeId = types.find(type => type.name === editTouch.touchtype)?.value;

      setType.setValue(editTouch.touchtype);
      setTypeId.setValue(newTypeId ? newTypeId : '');
    } else if (types && types.length && types[0].value) {
      setType.setValue(types[0].name);
      setTypeId.setValue(types[0].value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [types.length, editTouch]);

  useEffect(() => {
    if (!typeId.value) {
      const newTypeId = types.find(type => type.name === editTouch?.touchtype)?.value;
      setTypeId.setValue(newTypeId || types[0].value);
    }
  }, [typeId, editTouch, types, setTypeId]);

  const handleTypeChange = (value: string) => {
    const selectedType = types.find(type => type.value === value)?.name;

    if (selectedType === 'Document Upload') {
      dispatch(documentUploadModalActions.toggleModal());
      dispatch(
        companyTouchesModalActions.toggleCompanyTouchesDialog({
          companyId: company.company_id,
          companyName: company.company_name,
          company,
        })
      );
    }

    setTypeId.setValue(value);
    setType.setValue(selectedType || '');
  };

  const [, , setInitiator] = useField<string>('initiator');
  const [initiatorId, , setInitiatorId] = useField<number>('initiatorId');
  const [, , setIsOptxId] = useField<boolean>('is_optx_id');

  const handleInitiatorChange = (value: number) => {
    let selectedUser = psgParticipants.find(
      type => type.value === value || type.optx_id === value
    ) as PsgParticipant;

    setIsOptxId.setValue(!selectedUser.is_et_user);
    setInitiatorId.setValue(value);
    setInitiator.setValue(selectedUser.label || '');
  };

  useEffect(() => {
    let selectedUser = psgParticipants.find(
      user => user.value === initiatorId.value || user.optx_id === initiatorId.value
    )?.label;

    if (!selectedUser && psgParticipants.length) {
      setInitiatorId.setValue(0);
    }

    setIsOptxId.setValue(!sources.values.initiatorHasEtId ?? true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [psgParticipants]);

  // Other Users list
  const [psgUsers, setPsgUsers] = useState<PsgParticipant[]>([]);
  const [psgUsersAttendees, setPsgUsersAttendees] = useState<PsgParticipant[]>([]);
  const [usersSelected, , setUsersSelected] = useField<number[]>('attendees');

  useEffect(() => {
    let psgUsers = [...psgParticipants].filter(user => user.optx_id || user.has_psg_email);

    setPsgUsers(psgUsers);
  }, [psgParticipants]);

  useEffect(() => {
    let updatedPsgAttendees = [...psgParticipants].filter(
      user => initiatorId.value !== user.value && user.optx_id && user.value
    );

    setPsgUsersAttendees(updatedPsgAttendees);

    if (usersSelected.value.length) {
      const updatedSelectedUsers = usersSelected.value.filter(user => user !== initiatorId.value);
      setUsersSelected.setValue(updatedSelectedUsers);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [psgParticipants, initiatorId.value]);

  const handleUsersChange = (value: number, type: 'select' | 'deselect') => {
    if (type === 'select') {
      setUsersSelected.setValue([...usersSelected.value, value]);
    } else if (type === 'deselect') {
      const updatedContacts = usersSelected.value.filter(personId => personId !== value);
      setUsersSelected.setValue([...updatedContacts]);
    }
  };

  // Attendees list
  const [externalState, setExternalState] = useState<number[]>();
  const [, , setExternalAttendees] = useField<number[]>('externalAttendees');

  const handleExternal = useCallback((selectedValues: number[]) => {
    setExternalState(selectedValues);
    setExternalAttendees.setValue(selectedValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (editTouch && editTouch.externalParticipants) {
      setExternalState(editTouch.externalParticipants);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editTouch]);

  // set Date
  const [, , helpersDate] = useField('touchDate');

  function handleDateChange(date: moment.Moment | null) {
    if (date && date.toString() !== 'Invalid Date') {
      helpersDate.setValue(date.format(ISO_DATE_FORMAT));

      if (dateInterval.indexOf(date.format(ISO_DATE_FORMAT).toString()) > -1) {
        setSchedule(date.format(ISO_DATE_FORMAT).toString());
      } else {
        setSchedule('custom');
      }
    }
  }

  const [, , helpersTime] = useField('touchTime');

  // schedule interval
  const [schedule, setSchedule] = useState<string>('');

  const handleScheduleChange = (e: RadioChangeEvent) => {
    setSchedule(e.target.value);

    const updatedDate =
      e.target.value !== 'custom' ? e.target.value : moment(new Date()).format(ISO_DATE_FORMAT);
    helpersDate.setValue(updatedDate);
  };

  // Subject
  const [subject, , subjectChange] = useField('subject');

  const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    subjectChange.setValue(e.target.value);
  };

  // Rich Textarea
  const [textarea, , helpersTextarea] = useField('textarea');

  const textareaChange = (value: string) => {
    helpersTextarea.setValue(value);
  };

  // Schedule new touch
  const [checkedSchedule, setCheckedSchedule] = useState(false);

  const [scheduleInterval, , helpersScheduleInterval] = useField('scheduleInterval');
  const [, , helpersScheduleTouch] = useField('scheduleFollow');

  const followUpChange = (e: CheckboxChangeEvent) => {
    helpersScheduleTouch.setValue(!checkedSchedule);
    setCheckedSchedule(!checkedSchedule);
  };

  const [, , helpersScheduleDate] = useField('scheduleTouchDate');

  const handleScheduleDate = (e: RadioChangeEvent) => {
    helpersScheduleInterval.setValue(e.target.value);
    const updatedDate =
      e.target.value !== 'custom' ? e.target.value : moment(new Date()).format(ISO_DATE_FORMAT);
    helpersScheduleDate.setValue(updatedDate);
  };

  const handleDateChangeSchedule = (date: moment.Moment | null) => {
    if (date && date.toString() !== 'Invalid Date') {
      helpersScheduleDate.setValue(date.format(ISO_DATE_FORMAT));

      if (dateInterval.indexOf(date.format(ISO_DATE_FORMAT).toString()) > -1) {
        helpersScheduleInterval.setValue(date.format(ISO_DATE_FORMAT).toString());
      } else {
        helpersScheduleInterval.setValue('custom');
      }
    }
  };

  const [scheduleType, , setScheduleType] = useField<string>('scheduleType');

  const handleScheduleTypeChange = (value: string) => {
    const selectedType = types.find(type => type.value === value)?.name;

    if (selectedType === 'Document Upload') {
      dispatch(documentUploadModalActions.toggleModal());
      dispatch(
        companyTouchesModalActions.toggleCompanyTouchesDialog({
          companyId: company.company_id,
          companyName: company.company_name,
          company,
        })
      );
    }

    setScheduleType.setValue(selectedType || '');
  };

  useEffect(() => {
    if (types) {
      setScheduleType.setValue(types[0]?.name);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!editTouch) {
      helpersTime.setValue([
        moment(startTime).format(TOUCH_TIME_FORMAT),
        moment(endTime).format(TOUCH_TIME_FORMAT),
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editTouch]);

  return (
    <Styled.TouchContainer>
      <Row gutter={[0, 32]} className="row-spacer">
        {!editComplete && (
          <Radio.Group onChange={handleTabChange} value={tab.value}>
            <Radio.Button name="touch_type" value="complete">
              Log Complete Touch
            </Radio.Button>
            <Radio.Button name="touch_type" value="schedule">
              {editTouch ? 'Edit Touch Details' : 'Schedule Next Touch'}
            </Radio.Button>
          </Radio.Group>
        )}
      </Row>
      {tab.value === 'schedule' && (
        <Row gutter={[0, 16]} className="row-spacer-bottom">
          <Radio.Group onChange={handleScheduleChange} value={schedule}>
            {dateRadio &&
              dateRadio.map((date, index) => (
                <Radio.Button key={index} value={date.end ? date.end : 'custom'}>
                  {date.label}
                </Radio.Button>
              ))}
          </Radio.Group>
        </Row>
      )}
      <Row gutter={[8, 0]} className="row-spacer-bottom">
        <Col span={isChromeExtension ? 8 : 12}>
          <Row gutter={[8, 16]} className="row-spacer-bottom">
            <Col span={24}>
              <Typography.Title className="">Touch Information</Typography.Title>
              <Typography.Text className="">Type</Typography.Text>
              <FormItem name="typeId">
                <Select
                  showSearch
                  name="typeId"
                  value={typeId.value}
                  onChange={handleTypeChange}
                  getPopupContainer={trigger => trigger.parentElement!}
                  filterOption={(input: string, option: OptionTypeBase | undefined) => {
                    const fullType = `${option?.children.props.children}`;

                    return fullType.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                >
                  {types &&
                    types.map((type, index) => (
                      <Option
                        key={index}
                        value={type.value}
                        className={type.parent ? 'sub-option' : ''}
                      >
                        <span>{type.name}</span>
                      </Option>
                    ))}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={[8, 16]} className="row-spacer-bottom">
            <Col span={24}>
              <Typography.Text className="">Date</Typography.Text>
              <Form.Item name="touchDate">
                <DatePicker
                  defaultValue={moment(new Date(), globalConfig.short_date.DATE_FORMAT)}
                  // value={startDate}
                  onChange={handleDateChange}
                  format={globalConfig.short_date.DATE_FORMAT}
                  name="touchDate"
                  allowClear={false}
                  getPopupContainer={trigger => trigger.parentElement!}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={isChromeExtension ? 16 : 12}>
          <Typography.Title className="">Participants</Typography.Title>
          <Row gutter={[8, 16]} className="row-spacer-bottom">
            <Col span={12}>
              <Typography.Text className="">Initiator</Typography.Text>
              <FormItem name="initiatorId">
                <Select
                  showSearch
                  name="initiatorId"
                  value={initiatorId.value || ''}
                  onChange={handleInitiatorChange}
                  getPopupContainer={trigger => trigger.parentElement!}
                  filterOption={(input: string, option: OptionTypeBase | undefined) => {
                    const fullName = `${option?.children.props.children}`;

                    return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                >
                  {psgUsers.map(user => (
                    <Option
                      key={(user.value ?? user.optx_id) as number}
                      value={(user.value ?? user.optx_id) as number}
                      className="contact-option"
                    >
                      <span>{user.label}</span>
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col span={12}>
              <Typography.Text className="">Other PSG Attendee(s)</Typography.Text>
              <FormItem name="attendees">
                <Select
                  name="attendees"
                  showArrow
                  placeholder="Select Attendees"
                  onSelect={(value, option) => handleUsersChange(Number(value), 'select')}
                  onDeselect={(value, option) => handleUsersChange(Number(value), 'deselect')}
                  optionFilterProp="children"
                  menuItemSelectedIcon={ContactOptionIcon}
                  mode="multiple"
                  optionLabelProp={'name'}
                  filterOption={(input: string, option: OptionTypeBase | undefined) => {
                    const fullName = `${option?.children.props.children}`;

                    return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  tagRender={props => {
                    const personName = psgUsersAttendees.find(
                      item => item.value?.toString() === props.value.toString()
                    )?.label;

                    return <span>{personName || ''}</span>;
                  }}
                  value={usersSelected.value}
                  dropdownMatchSelectWidth={250}
                  getPopupContainer={trigger => trigger.parentNode.parentNode}
                  dropdownRender={menu => <>{menu}</>}
                >
                  {psgUsersAttendees.map(user => (
                    <Option
                      key={user.value as number}
                      value={user.value as number}
                      className="contact-option"
                    >
                      <span>{user.label}</span>
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row className="row-spacer-bottom">
            <Col span={24}>
              <Typography.Text className="">External Attendee(s)</Typography.Text>
              <ContactSelect
                companyData={record}
                handleChange={handleExternal}
                selected={externalState}
                selectedPrimary={editTouch ? false : true}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[8, 16]} className="row-spacer-bottom">
        <Col span={24}>
          <Typography.Title className="">Activity Notes / Messages</Typography.Title>
          <Typography.Text className="">Subject</Typography.Text>
          <Form.Item name="subject" className="antd-item-save-search-list">
            <Input
              name="subject"
              value={subject.value}
              onChange={handleSubjectChange}
              placeholder=""
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 16]} className="row-spacer-bottom">
        <Col span={24} className="textarea-bond">
          <TextareaEditor
            textValue={textarea.value}
            placeholder="Enter text here"
            handleChange={textareaChange}
          />
        </Col>
      </Row>
      {tab.value === 'complete' && (
        <Row gutter={[8, 16]} className="row-spacer-bottom">
          <Col span={24}>
            <Checkbox
              checked={checkedSchedule}
              className="no-wrap-checkbox"
              name="scheduleFollow"
              onChange={followUpChange}
              value="schedule"
            >
              Schedule Follow-up Touch
            </Checkbox>
          </Col>
          <Col span={24}>
            <Radio.Group
              value={scheduleInterval.value}
              style={{ marginBottom: 8, paddingTop: 8 }}
              disabled={!checkedSchedule}
              name="schedule_interval"
              onChange={handleScheduleDate}
            >
              {dateRadio &&
                dateRadio.map((date, index) => (
                  <Radio.Button key={index} value={date.end ? date.end : 'custom'}>
                    {date.label}
                  </Radio.Button>
                ))}
            </Radio.Group>
          </Col>
          <Col span={12}>
            <Typography.Text className="">Type</Typography.Text>
            <Select
              name="scheduleTypeId"
              value={scheduleType.value}
              onChange={handleScheduleTypeChange}
              disabled={!checkedSchedule}
              getPopupContainer={trigger => trigger.parentElement!}
            >
              {types &&
                types.map(type => (
                  <Option
                    key={type.value}
                    value={type.value}
                    className={type.parent ? 'sub-option' : ''}
                  >
                    {type.name}
                  </Option>
                ))}
            </Select>
          </Col>
          <Col span={12}>
            <Typography.Text className="">Date</Typography.Text>
            <Form.Item name="scheduleTouchDate">
              <DatePicker
                defaultValue={moment(new Date(), globalConfig.short_date.DATE_FORMAT)}
                onChange={handleDateChangeSchedule}
                format={globalConfig.short_date.DATE_FORMAT}
                name="scheduleTouchDate"
                disabled={!checkedSchedule}
                allowClear={false}
                getPopupContainer={trigger => trigger.parentElement!}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Typography.Text className="">Subject</Typography.Text>
            <Form.Item name="scheduleSubject" className="antd-item-save-search-list">
              <Input name="scheduleSubject" placeholder="" disabled={!checkedSchedule} />
            </Form.Item>
          </Col>
        </Row>
      )}
    </Styled.TouchContainer>
  );
};

export default TouchInformation;
