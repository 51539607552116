import { Row } from 'antd';
import styled from 'styled-components';

export const RowWrapper = styled(Row)`
  flex-flow: row nowrap;

  .ant-col:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Styled = {
  RowWrapper,
};

export default Styled;
