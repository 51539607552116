import React from 'react';
import { Progress, Col } from 'antd';
import Icon from '@components/common/Icon';
import EmptyChart from '@optx/components/feature/charts/EmptyChart';
import { ChartIntervalKeys } from '@optx/models/charts/data';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from '@umijs/hooks';
import ChartHeader from '../ChartHeader';
import { Styles } from './GlassDoorChart.style';
import { actions as localActions, selectors as localSelectors } from '../../state/glassdoor';

interface SocialMediaProps {
  companyId: number;
  chartInterval: ChartIntervalKeys;
}

const removeDecimals = (value: number) => {
  return Math.trunc(value);
};

const GlassDoorChart: React.FC<SocialMediaProps> = ({ companyId, chartInterval }) => {
  const dispatch = useDispatch();

  useMount(() => dispatch(localActions.fetchGlassDoorData(companyId)));

  const data = useSelector(localSelectors.getData);
  const chartData = data[chartInterval];

  return (
    <Styles.GlassDoorChartWrapper
      // Added as inline-style because it doesn't work with styled components. Do not know why.
      style={{ height: '100%' }}
      title={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <ChartHeader
          icon={<Icon iconName="glassdoor" className="chart-icon" />}
          title="Glassdoor"
          headerLabel="# of reviews"
          headerValue={Number(chartData?.number_of_reviews)}
          stars={chartData?.overall_stars}
        />
      }
    >
      <Styles.ChartRow>
        {!chartData || !Object.keys(chartData).length ? (
          <EmptyChart className="chart-empty-labels" text="No History" />
        ) : (
          <>
            <Col span={16} className="charts-progress">
              <Progress
                type="circle"
                percent={removeDecimals(chartData.percent_recommend_friend)}
                strokeColor="#188fff76"
                trailColor="#188fff76"
                width={110}
                success={{ strokeColor: '#188fff76' }}
                format={percent => `${percent}%`}
              />

              <Progress
                type="circle"
                percent={removeDecimals(chartData.percent_recommend_ceo)}
                strokeColor="#188fff76"
                trailColor="#188fff76"
                width={110}
                success={{ strokeColor: '#188fff76' }}
                format={percent => `${percent}%`}
              />
              <Progress
                type="circle"
                percent={removeDecimals(chartData.positive_outlook_percent)}
                strokeColor="#188fff76"
                trailColor="#188fff76"
                width={110}
                success={{ strokeColor: '#188fff76' }}
                format={percent => `${percent}%`}
              />
            </Col>
            <Col span={8} className="chart-stats">
              <Col>
                <span className="value">{removeDecimals(chartData.percent_recommend_friend)}%</span>
                <span className="label">Recommended to a friend</span>
              </Col>
              <Col>
                <span className="value">{removeDecimals(chartData.percent_recommend_ceo)}%</span>
                <span className="label">CEO Approval</span>
              </Col>
              <Col>
                <span className="value">{removeDecimals(chartData.positive_outlook_percent)}%</span>
                <span className="label">Positive Business Outlook</span>
              </Col>
            </Col>
          </>
        )}
      </Styles.ChartRow>
    </Styles.GlassDoorChartWrapper>
  );
};

export default React.memo(GlassDoorChart);
