import React from 'react';
import { useSelector } from 'react-redux';
import StyledStatsCompare from '@optx/shared/view/molecules/statistics/StatsCompare/StatsCompare.styles';
// redux
import { selectors } from '../../state';

const EmailsSentTitle = () => {
  const selectedPeriodLabel = useSelector(selectors.filters.getSelectedPeriodLabel);

  return (
    <StyledStatsCompare.Title>
      # OF EMAILS SENT {selectedPeriodLabel.toUpperCase()}
    </StyledStatsCompare.Title>
  );
};

export default EmailsSentTitle;
