import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// models
import { FilterTag, SpecialFilterTag } from '@optx/models/tags';
import { GridKeys } from '@optx/models/grid';
// redux
import { selectors as filterSelectors } from '@features/grid/filter';
import { actions as searchActions } from '@features/grid/search';
import { actions as filtersModalActions } from '@redux/ui/modals/gridModals';
// components
import { FilterTagsOverflow } from '@optx/features/tags/overflow-tags';

const FilterTags = () => {
  const dispatch = useDispatch();

  const { pathname } = window.location;
  const gridKey = (pathname.includes('ss-lists') ? 'sslists' : 'watchlists') as GridKeys;

  const options = useSelector(filterSelectors.getTags(gridKey));

  const toggleFiltersModal = useCallback(
    () => dispatch(filtersModalActions.toggleModal({ gridKey: gridKey })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const handleFilterRemove = (tag: FilterTag) => () => {
    // Handle removal of search keyword special tag.

    if ((tag.filter as SpecialFilterTag) === 'keyword') {
      dispatch(
        searchActions.searchCompanies({
          gridKey: 'lists',
          data: { searchKey: '', shouldResetPageNumber: true },
        })
      );

      return;
    }

    dispatch(
      searchActions.resetFilter({
        gridKey: 'lists',
        data: { selectedTag: tag, filterTags: filteredOptions },
      })
    );

    // fix for hiding more filters tag, when removing the last tag
    // that prevents all from being displayed
  };

  const filteredOptions = useMemo(
    () => options.filter(item => !item.isDefault && item.values?.length > 0),
    [options]
  );

  return (
    <FilterTagsOverflow
      options={filteredOptions}
      onClick={toggleFiltersModal}
      onClose={handleFilterRemove}
      multipleValue
    />
  );
};

export default FilterTags;
