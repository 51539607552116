import { call, put, takeLatest, select } from 'redux-saga/effects';
import queryString from 'query-string';
import { push } from 'react-router-redux';
// models
import { CompanyUserContact } from '@optx/models/api/contacts';
import { SalesloftCadences, AddNewContact, SalesloftContact } from '@optx/models/Salesloft';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  SelectedCompanies,
  ResolveSalesloft,
  SalesloftBulkContactResponse,
  SalesloftCompanyContacts,
  SalesloftBulkResponse,
  SalesloftBulkResults,
  SelectedCompaniesGrids,
  ResolveSalesloftSuccess,
} from '@models/bulkActions';
// constants
import { BULK_ADD_COMPANY_LIMIT_SALESLOFT } from '@optx/config/bulkConfig';
// utils
import { getErrorMessage } from '@utils/api/errors';
// services
import { CompanyService, SalesloftService } from '@services/api';
import NotificationService from '@services/NotificationService';
// redux
import { actions as loaderActions } from '@features/custom-global-loader';
import { selectors as salesLoftSelector } from '@redux/contacts/salesloft';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { actions as eqtBulkActions } from '@features/bulk-actions/equity-touch';
import { pick } from 'lodash';
import { CompanyWithContacts } from './interfaces';
import * as actions from './actions';

function* fetchCompaniesContactsSaga(action: PayloadAction<SelectedCompaniesGrids>) {
  yield put(loaderActions.toggle({ loading: true }));

  const selectedCompanies: SelectedCompanies[] = yield select(
    bulkActionsSelectors.selectedCompanies.getSelectedCompanies(action.payload)
  );
  const defaultErrorMessage = 'Failed to fetch contacts!';

  if (!selectedCompanies.length) {
    const urlParams = queryString.parse(window.location.search.substr(1));
    yield put(push(urlParams.referrer as string));
  } else {
    try {
      const res: AxiosResponse<SalesloftCompanyContacts[]> = yield call(
        CompanyService.getCompaniesContacts,
        selectedCompanies.map(company => company.company_id)
      );

      if (res.data) {
        yield put(
          actions.fetchCompaniesContactsSuccess(res.data, selectedCompanies, action.payload)
        );
      }
    } catch (error: any) {
      const message = getErrorMessage(error, defaultErrorMessage);
      NotificationService.error(message);
    }
  }

  yield put(loaderActions.toggle({ loading: false }));
}

function* addPersonBulkSaga(action: PayloadAction<SalesloftCadences>) {
  const cadence = action.payload;
  const defaultErrorMessage = 'Failed to add to SL Cadence';

  const companies: CompanyWithContacts[] = yield select(
    bulkActionsSelectors.salesloft.salesloftBulkCompanies
  );
  const selectedCompanies = [...companies];
  const currentResults: SalesloftBulkResults[] = yield select(
    bulkActionsSelectors.salesloft.results
  );
  const companiesBatch = selectedCompanies.splice(
    currentResults.length,
    BULK_ADD_COMPANY_LIMIT_SALESLOFT
  );

  const mappedCompanies = companiesBatch.map(company => ({
    company_id: company.company_id,
    company_name: company.company_name,
    company_website: company.company_url,
    contacts: company?.salesloft_contacts.map(item => ({
      ...pick(item, [
        'first_name',
        'last_name',
        'person_id',
        'title',
        'primary_email',
        'secondary_email',
        'tertiary_email',
        'quarternary_email',
        'phone',
        'phone_type',
        'additional_phones',
      ]),
    })),
  }));

  try {
    const res: AxiosResponse<SalesloftBulkResponse> = yield call(
      SalesloftService.addPersonBulk,
      cadence,
      mappedCompanies
    );

    if (res.data) {
      yield put(
        actions.updatePercentage(
          Math.round((BULK_ADD_COMPANY_LIMIT_SALESLOFT / companies.length) * 100)
        )
      );

      const results = res.data.companies.map(result => {
        const companyData = companies.find(company => company.company_id === result.company_id);

        return {
          company_id: result.company_id,
          company_website: companyData!.company_url,
          company_raw_url: companyData!.raw_url,
          company_name: result.company_name,
          cadence_id: res.data.cadence_id,
          contacts: [...(result.contacts as SalesloftBulkContactResponse[])],
        };
      });
      const finalResults = [...currentResults, ...results];
      yield put(actions.saveResults(results));

      const cancelSearch: boolean = yield select(bulkActionsSelectors.salesloft.cancel);

      if (!cancelSearch) {
        if (companies.length !== finalResults.length) {
          yield put(actions.addPersonBulk(cadence));
        } else {
          yield put(actions.updateCompleted(true));
          yield put(actions.updatePercentage(100));
        }
      } else {
        const canceledCompanies: SalesloftBulkResults[] = [];
        const prevResults: SalesloftBulkResults[] = yield select(
          bulkActionsSelectors.salesloft.results
        );

        const prevResultsIds = prevResults.map(result => result.company_id);

        const remainingCompanies = companies.filter(
          company => !prevResultsIds.includes(company.company_id)
        );

        if (remainingCompanies?.length) {
          remainingCompanies.forEach(company => {
            canceledCompanies.push({
              company_id: company.company_id,
              company_website: company.company_url,
              company_raw_url: company.raw_url,
              company_name: company.company_name,
              cadence_id: res.data.cadence_id,
              contacts: [
                {
                  person_id: null,
                  sl_message: 'Canceled by user',
                  sl_resolve: null,
                  sl_sync_status: null,
                  sl_url: null,
                },
              ],
            });
          });
        }

        yield put(actions.saveResults(canceledCompanies));
        yield put(actions.updateCompleted(true));
        yield put(actions.cancel(false));
        yield put(loaderActions.toggle({ loading: false, customText: '' }));
      }
    } else {
      NotificationService.error(defaultErrorMessage);
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, defaultErrorMessage);
    yield put(actions.addPersonBulkFail(errorMessage));
    NotificationService.error(errorMessage);
  }

  yield put(loaderActions.toggle({ loading: false }));
}

export function* addNewContactSaga(action: PayloadAction<AddNewContact>) {
  yield put(loaderActions.toggle({ loading: true }));

  const { contact, shouldResolve, isEtBulk } = action.payload;

  try {
    const res: AxiosResponse<CompanyUserContact> = yield call(CompanyService.addContactToOptx, {
      ...contact,
      company_url: contact.company_url || null,
    });

    if (res.data) {
      yield put(actions.addContactSuccess({ ...res.data, company_id: contact.company_id }));

      if (shouldResolve) {
        if (isEtBulk) {
          yield put(eqtBulkActions.addBulkContacts([res.data], res.data.company_id as number));

          if (!res.data.primary_contact) {
            yield put(
              eqtBulkActions.salesloftSelectContactsBulk({
                id: res.data.person_id as number,
                companyId: res.data.company_id as number,
                type: 'select',
                selectedContactData: res.data,
              })
            );
          }

          yield put(eqtBulkActions.resolveSalesloft({ companyId: contact.company_id as number }));
        } else {
          yield put(actions.resolveSalesloft({ companyId: contact.company_id as number }));
        }
      }

      if (!shouldResolve) {
        yield put(loaderActions.toggle({ loading: false }));
      }
    }
  } catch (error: any) {
    const message = getErrorMessage(error, 'Failed to add new contact!');
    NotificationService.error(message);
    yield put(loaderActions.toggle({ loading: false }));
  }
}

function* resolveSalesloftSaga(action: PayloadAction<ResolveSalesloft>) {
  yield put(loaderActions.toggle({ loading: true }));
  const { companyId, all } = action.payload;
  const companies: CompanyWithContacts[] = yield select(
    bulkActionsSelectors.salesloft.salesloftBulkCompanies
  );
  const cadenceId: number = yield select(bulkActionsSelectors.salesloft.getCadenceId);
  const cadences: SalesloftCadences[] = yield select(salesLoftSelector.getCadences);
  const results: SalesloftBulkResults[] = yield select(bulkActionsSelectors.salesloft.results);
  const cadence = cadences.find((item: SalesloftCadences) => item.id === cadenceId);
  const company = companies.find((item: CompanyWithContacts) => item.company_id === companyId);
  const result = results.find(result => result.company_id === companyId);
  const failedContactIds = result?.contacts
    .filter(contact => contact.sl_resolve)
    ?.map(contact => contact.person_id);

  let contacts: SalesloftContact[] = [];

  if (all || (result?.contacts.length === 1 && result?.contacts[0].person_id === null)) {
    contacts = company?.salesloft_contacts as SalesloftContact[];
  } else if (failedContactIds) {
    contacts = company?.salesloft_contacts.filter((contact: SalesloftContact) =>
      failedContactIds.includes(contact.person_id as number)
    ) as SalesloftContact[];
  }

  const formData = {
    cadence_id: cadence?.id,
    cadence_name: cadence?.name,
    team_cadence: cadence?.team_cadence,
    company_website: company?.company_url,
    company_id: companyId,
    company_name: company?.company_name,
    contacts: contacts.map((contact: SalesloftContact) =>
      pick(contact, [
        'first_name',
        'last_name',
        'person_id',
        'title',
        'primary_email',
        'secondary_email',
        'tertiary_email',
        'quarternary_email',
      ])
    ),
  };

  try {
    const res: AxiosResponse<ResolveSalesloftSuccess> = yield call(
      SalesloftService.postSalesloftData,
      formData
    );

    if (res) {
      yield put(actions.resolveSalesloftSuccess(res.data));
      yield put(loaderActions.toggle({ loading: false }));
    }
  } catch (error: any) {
    yield put(loaderActions.toggle({ loading: false }));

    const message = getErrorMessage(error, 'Failed to add contact to Sales Loft!');

    NotificationService.error(message);
  }
}

export default function* salesloftBulkSaga() {
  yield takeLatest(actions.fetchCompaniesContacts, fetchCompaniesContactsSaga);
  yield takeLatest(actions.addPersonBulk, addPersonBulkSaga);
  yield takeLatest(actions.addContact, addNewContactSaga);
  yield takeLatest(actions.resolveSalesloft, resolveSalesloftSaga);
}
