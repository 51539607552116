import { CaseReducer, PayloadAction, createReducer } from '@reduxjs/toolkit';
// models
import { GridBase, GridKeys } from '@optx/models/grid';
// constants
import { GRID_FETCHED_COUNT_KEYS } from '@optx/constants/grid';
// utils
import { generateGridReducerInitialState } from '@optx/utils/grid';
// redux
import { incrementFetchedCount } from './actions';

const initialState: GridBase<number> = generateGridReducerInitialState(GRID_FETCHED_COUNT_KEYS, 0);

const incrementFetchedCountReducer: CaseReducer<GridBase<number>, PayloadAction<GridKeys>> = (
  draftState,
  action
) => {
  const gridKey = action.payload;

  draftState[gridKey] += 1;
};

const reducer = createReducer<GridBase<number>>(initialState, builder => {
  builder.addCase(incrementFetchedCount, incrementFetchedCountReducer);
});

export default reducer;
