import { useInjectSaga } from 'redux-injectors';
import { saga } from '../../../redux/company/portfolio';

const useInjectPortfolio = () => {
  useInjectSaga({
    key: 'portfolio',
    saga,
  });
};

export default useInjectPortfolio;
