import { createSelector } from 'reselect';
import { AppState } from '@optx/redux/interfaces';

const selectMyCompaniesState = (state: AppState) => state.myCompanies.filterCards;

export const getMyCompaniesCards = createSelector(selectMyCompaniesState, state => state.cards);
export const getSelectedCard = createSelector(selectMyCompaniesState, state => state.selectedCard);
export const getSelectedCardId = createSelector(selectMyCompaniesState, state => {
  if (state.selectedCard) {
    return state.selectedCard.id;
  }

  if (state.selectedCardId) {
    return state.selectedCardId;
  }

  return null;
});

export const getSubFilter = createSelector(selectMyCompaniesState, state => state.subFilter);
