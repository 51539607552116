import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
// redux
import { fetchReducer, fetchFailReducer } from '@redux/feature/fetch/reducers';
import Company from '@optx/models/Company';
import { actions as editFieldActions } from '@features/bulk-actions/edit-fields';
import { CompanyBulkEditingResults } from '@optx/models/bulkActions';
import { CompanyFavoritesState } from './interfaces';
import * as actions from './actions';

export const initialState: CompanyFavoritesState = {
  allIds: [],
  byId: {},
  loading: false,
  error: '',
  fetchedAt: null,
};

const fetchFavoritesSuccessReducer: CaseReducer<CompanyFavoritesState, PayloadAction<Company[]>> = (
  draftState,
  action
) => {
  draftState.allIds = [];
  draftState.byId = {};

  const companies = action.payload;
  companies.forEach(company => {
    const id = company.company_id;
    draftState.allIds.push(id);
    draftState.byId[id] = company;
  });
  draftState.loading = false;
  draftState.fetchedAt = new Date().toISOString();
};

const addFavoriteSuccessReducer: CaseReducer<
  CompanyFavoritesState,
  PayloadAction<number>
> = draftState => {
  draftState.loading = false;
};

const deleteFavoriteSuccessReducer: CaseReducer<CompanyFavoritesState, PayloadAction<number>> = (
  draftState,
  action
) => {
  const companyId = action.payload;
  const companyNameIdx = draftState.allIds.findIndex(id => id === companyId);
  draftState.allIds.splice(companyNameIdx, 1);
  delete draftState.byId[companyId];
  draftState.loading = false;
};

const bulkDeleteFavoriteSuccessReducer: CaseReducer<
  CompanyFavoritesState,
  PayloadAction<CompanyBulkEditingResults[]>
> = (draftState, action) => {
  const companies = action.payload;
  companies.map(item => {
    const companyNameIdx = draftState.allIds.findIndex(id => id === item.company_id);

    if (companyNameIdx !== -1) {
      draftState.allIds.splice(companyNameIdx, 1);
      delete draftState.byId[item.company_id];

      return true;
    }

    return true;
  });
  draftState.loading = false;
};

const reducer = createReducer(initialState, builder =>
  builder
    // fetch
    .addCase(actions.fetchFavorites, fetchReducer)
    .addCase(actions.fetchFavoritesSuccess, fetchFavoritesSuccessReducer)
    .addCase(actions.fetchFavoritesFail, fetchFailReducer)
    // add
    .addCase(actions.addFavorite, fetchReducer)
    .addCase(actions.addFavoriteSuccess, addFavoriteSuccessReducer)
    .addCase(actions.addFavoriteFail, fetchFailReducer)
    // delete
    .addCase(actions.deleteFavorite, fetchReducer)
    .addCase(actions.deleteFavoriteSuccess, deleteFavoriteSuccessReducer)
    .addCase(actions.deleteFavoriteFail, fetchFailReducer)
    .addCase(editFieldActions.saveResults, bulkDeleteFavoriteSuccessReducer)
);

export default reducer;
