import { takeLatest, call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { CompanyService } from '@services/api';
import NotificationService from '@services/NotificationService';
import { getErrorMessage } from '@optx/utils/api/errors';
import { AxiosResponse } from 'axios';
import * as actions from './actions';
import { ChangedCompanyFieldsPayload, ChangedCompanyFieldsResponse } from './interfaces';

export function* fetchCompanyChangedFieldsSaga(
  action: PayloadAction<ChangedCompanyFieldsPayload, any, boolean>
) {
  const searchPayload = action.payload;
  let response: ChangedCompanyFieldsResponse = { results: [], count: 0 };

  try {
    const res: AxiosResponse<ChangedCompanyFieldsResponse | []> = yield call(
      CompanyService.fetchCompanyChangedFields,
      searchPayload
    );

    // The API sends [] if there are no fields and I had to account for that;
    if (res.data) {
      if ((res.data as []).length === 0) {
        response = { results: [], count: 0 };
      } else {
        response = res.data as ChangedCompanyFieldsResponse;
      }
    }

    yield put(actions.fetchChangedCompanyFieldsSuccess(response, searchPayload));
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Error fetching company changed fields');
    yield put(actions.fetchChangedCompanyFieldsFail(errorMessage));
    yield put(actions.fetchChangedCompanyFieldsSuccess(response, searchPayload));
    NotificationService.error(errorMessage);
  }
}

export default function* companyChangedFieldsSaga() {
  yield takeLatest(actions.fetchChangedCompanyFields, fetchCompanyChangedFieldsSaga);
}
