import { PageInfo } from '@models/table/Pagination';

export const DEFAULT_PAGINATION_PAGE_NUMBER = 1;
export const DEFAULT_PAGINATION_PAGE_SIZE = 50;
export const LONG_CARD_VIEW_DEFAULT_PAGE_SIZE = 50;
export const LONG_CARD_VIEW_DEFAULT_LOAD_MORE_SIZE = 10;

export const DEFAULT_PAGINATION: PageInfo = {
  pageNumber: DEFAULT_PAGINATION_PAGE_NUMBER,
  pageSize: DEFAULT_PAGINATION_PAGE_SIZE,
};
