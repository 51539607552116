import { GroupedOptionsType, GroupType, OptionsType, OptionTypeBase } from 'react-select';
// models
import { CompanyUserContact } from '@optx/models/api/contacts';
import { SalesloftContact } from '@optx/models/Salesloft';

/**
 * Check if select options are grouped.
 * @param options select options.
 */

export function hasGroups(options: OptionsType<any> | GroupedOptionsType<any> | undefined) {
  return options && options.some(option => (option as GroupType<any>).options);
}

/**
 * filter dropdown options
 * @param {string} input field value
 * @param {OptionTypeBase | undefined} option drodpdown value to be checked
 */
export const filterOption = (input: string, option: OptionTypeBase | undefined) => {
  if (typeof option?.children === 'string') {
    return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  if (option?.children?.props?.group?.text) {
    return option?.children.props.group.text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  return false;
};

/**
 * special case for filter dropdown options for contact selects
 * @param {string} input field value
 * @param {OptionTypeBase | undefined} option drodpdown value to be checked
 */
export const contactFilterOption = (input: string, option: OptionTypeBase | undefined) => {
  const fullName = `${option?.children.props.contact.first_name} ${option?.children.props.contact.last_name}`;

  return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

/**
 * check dropdown options
 * @param {string} showKey field value
 * @param {(item: string) => boolean} filterLabel handle label filtering
 * @param {OptionTypeBase} option dropdown value to be checked
 */
export const checkOption = (
  showKey: string,
  filterLabel: (item: string) => boolean,
  option: OptionTypeBase
) => {
  return (
    (showKey === 'value' && filterLabel(option.label)) ||
    option.label === '' ||
    option.label === null
  );
};

/**
 * Reorders the selected contact ids to ensure that the primary contact is always first in the list.
 * @param {number[]} selectedContactIds - An array of selected contact ids.
 * @param {CompanyUserContact[]} contacts - An array of company contacts.
 * @returns {number[]} The reordered array of selected contact ids.
 */
export const reorderPrimaryContact = (
  selectedContactIds: number[],
  contacts: CompanyUserContact[] | SalesloftContact[]
) => {
  const primaryContactId = (contacts as CompanyUserContact[]).find(
    contacts => contacts.primary_contact
  )?.person_id;

  if (primaryContactId && selectedContactIds.includes(primaryContactId)) {
    const rearrangedIds = [
      primaryContactId,
      ...selectedContactIds.filter(id => id !== primaryContactId),
    ];

    return rearrangedIds;
  }

  return selectedContactIds;
};
