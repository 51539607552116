import { createFeatureAction } from '@utils/feature';
import featureName from '../../featureName';
import * as actionTypes from './actionTypes';
import { EditCompanyOwner, EditCompanyOwnerSuccess } from './interfaces';

export const addCompanyOwner = createFeatureAction<EditCompanyOwner>(
  featureName,
  actionTypes.ADD_COMPANY_OWNER
);

export const addCompanyOwnerSuccess = createFeatureAction<EditCompanyOwnerSuccess>(
  featureName,
  actionTypes.ADD_COMPANY_OWNER_SUCCESS
);

export const addCompanyOwnerFail = createFeatureAction<string>(
  featureName,
  actionTypes.ADD_COMPANY_OWNER_FAIL
);
