import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Typography } from 'antd';
// models
import { CompanyCompetitor } from '@optx/models/Company';
// constants
import { NUMBER_OF_SELECTED_COMPETITORS } from './constants';
// redux
import { actions as companyProfileActions } from '@redux/company/profile';
// hooks
import useIsAnalyst from '@optx/common/hooks/useIsAnalyst';
// components
import { CompetitorCard } from '../../../../../common/cards';
import Styled from './Competitors.styles';

interface CompetitorsProps {
  competitors: Array<CompanyCompetitor>;
}

const Competitors = ({ competitors }: CompetitorsProps) => {
  const dispatch = useDispatch();
  const { advancedSearchPathname } = useIsAnalyst();
  const selectedCompetitors = useMemo(
    () => competitors.slice(0, NUMBER_OF_SELECTED_COMPETITORS),
    [competitors]
  );

  const numberOfRemainingCompanies = competitors.length - selectedCompetitors.length;

  const handleViewAll = useCallback(() => {
    dispatch(
      companyProfileActions.viewSimilarCompaniesOnGrid({ pathname: advancedSearchPathname })
    );
  }, [advancedSearchPathname, dispatch]);

  return (
    <>
      <Styled.StyledCard
        title={`Similar Companies (${selectedCompetitors.length}/${competitors.length})`}
        className="fill-height competitors-card profile-box"
        extra={competitors.length > 0 && <Button onClick={handleViewAll}>View All in Grid</Button>}
      >
        {competitors && competitors.length ? (
          selectedCompetitors.map((competitor, index) => {
            const lastIndex = selectedCompetitors.length - 1;

            return (
              <React.Fragment key={competitor.company_id}>
                <CompetitorCard
                  key={index}
                  competitor={competitor}
                  to={competitor.raw_url || '#'}
                />
                {index === lastIndex && numberOfRemainingCompanies >= 1 && (
                  <Typography.Text strong>
                    {`${numberOfRemainingCompanies} more ${
                      numberOfRemainingCompanies === 1 ? 'company' : 'companies'
                    }`}
                  </Typography.Text>
                )}
              </React.Fragment>
            );
          })
        ) : (
          <Typography.Text style={{ color: '#90a0b7' }}>No Similar Companies</Typography.Text>
        )}
      </Styled.StyledCard>
    </>
  );
};

export default React.memo(Competitors);
