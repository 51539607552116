import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Tooltip } from 'antd';
// models
import { ScheduledTouch } from '@optx/redux/scheduled-touches/interfaces';
// constants
import { SHORT_MONTH_YEAR_DATE_FORMAT } from '@optx/constants/format/date';
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
import { CompanyProfileTabs } from '@optx/constants/routes';
// utils
import { getCompanyProfileURL } from '@optx/utils/routes';
import { getDateColor } from '@optx/utils/text';
// redux
import { selectors as userSelectors } from '@redux/user/information';
// components
import { IconCompany } from '@optx/components/icons';
import { TrendingValue } from '@optx/components/common/trending';
import Icon from '@components/common/Icon';
import Styled from './ScheduledTouchItem.style';
import ScheduledTouchItemInfo from './ScheduledTouchItemInfo';

// this number is to show in tooltip only dates that are not displayed
const startDateListIndex = 2;

const nextTouchDateFormat = 'MMM Do YYYY';

interface ScheduledTouchItemProps {
  data: ScheduledTouch;
}

const ScheduledTouchItem: FC<ScheduledTouchItemProps> = ({ data }) => {
  const { next_touch_date: nextTouchDate } = data;

  const normalizedNextTouchDate = useMemo(() => {
    return nextTouchDate
      .slice(startDateListIndex)
      .reduce((result: string[], date: string) => {
        result.push(moment(date).format(nextTouchDateFormat));

        return result;
      }, [])
      .join(', ');
  }, [nextTouchDate]);

  const defaultOptxScore = useSelector(userSelectors.getDefaultScore);
  const lastValue = defaultOptxScore === 'il' ? data.il_optx_score : data.score;

  const isPSGCompany = data.is_psg_company;
  const areMoreNextItems = nextTouchDate.length > 2;

  return (
    <Styled.ScheduledTouchItem>
      <Styled.ScheduledTouchItemDetail>
        {data.touch_status !== null && (
          <ScheduledTouchItemInfo title={data.touch_status} type={data.touch_type} />
        )}
        <Styled.Date>{moment(new Date(data.touch_start_date)).format('MMMM Do YYYY')}</Styled.Date>
        {nextTouchDate[0] && (
          <Styled.NextTouchDate>
            Next: {moment(nextTouchDate[0]).format(nextTouchDateFormat)}
          </Styled.NextTouchDate>
        )}
        {nextTouchDate[1] && (
          <Styled.NextTouchDate>
            Next: {moment(nextTouchDate[1]).format(nextTouchDateFormat)}
          </Styled.NextTouchDate>
        )}
        {areMoreNextItems && (
          <Tooltip
            overlayStyle={{ maxWidth: '115px' }}
            title={normalizedNextTouchDate}
            className="text-truncate"
          >
            <Styled.NextTouchDate>
              {nextTouchDate.length - startDateListIndex} more touch
              {nextTouchDate.length > 3 ? 'es' : ''}
            </Styled.NextTouchDate>
          </Tooltip>
        )}
      </Styled.ScheduledTouchItemDetail>
      <Styled.ScheduledTouchItemContentWrapper areMoreNextItems={areMoreNextItems}>
        <Styled.ScheduledTouchItemContent>
          <div className="company-avatar">
            {data.logo_url ? <img src={data.logo_url} alt={data.company_name} /> : <IconCompany />}
          </div>
          <div className="content">
            <span className="company">
              <Link
                to={getCompanyProfileURL(
                  data.company_id,
                  `tab=${CompanyProfileTabs.RELATIONSHIP_MANAGEMENT}`
                )}
                className="company_name"
              >
                {data.company_name}
              </Link>
              <Link
                to={getCompanyProfileURL(
                  data.company_id,
                  `tab=${CompanyProfileTabs.RELATIONSHIP_MANAGEMENT}`
                )}
                target="_blank"
              >
                <Icon iconName="open-in-new" />
              </Link>
            </span>
            <span>{data.subject}</span>
          </div>
          <div className="stats">
            <div className="d-flex flex-column">
              <span className="stage">{data.stage ?? DEFAULT_CELL_VALUE}</span>
              <span>Stage</span>
            </div>
            <div className="d-flex flex-column">
              <span className="rank">{data.pipeline_rank ?? DEFAULT_CELL_VALUE}</span>
              <span>Rank</span>
            </div>
            <div className="d-flex flex-column">
              <span className="last-touch" style={{ color: getDateColor(data.last_touch_date) }}>
                {data.last_touch_date
                  ? moment(new Date(data.last_touch_date)).format(SHORT_MONTH_YEAR_DATE_FORMAT)
                  : DEFAULT_CELL_VALUE}
              </span>
              <span>LAST TOUCH</span>
            </div>
            <div className="d-flex flex-column">
              {isPSGCompany ? (
                <Styled.PSGCompanyLabel>PSG</Styled.PSGCompanyLabel>
              ) : (
                <>
                  <span className="optx d-flex align-items-center">
                    <span>{lastValue ?? 'N/A'}</span>
                    <TrendingValue
                      value={defaultOptxScore === 'il' ? data.il_score_growth : data.score_growth}
                    />
                  </span>
                  <span>{defaultOptxScore === 'il' ? 'IL OPTX SCORE' : 'OPTX SCORE'}</span>
                </>
              )}
            </div>
          </div>
        </Styled.ScheduledTouchItemContent>
      </Styled.ScheduledTouchItemContentWrapper>
    </Styled.ScheduledTouchItem>
  );
};

export default ScheduledTouchItem;
