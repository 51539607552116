import { createReducer, CaseReducer, PayloadAction, Dictionary } from '@reduxjs/toolkit';
// models
import { SearchSaveResponse } from '@optx/models/search';
import { Filter, FilterSource, PreselectedFilter } from '@optx/models/filters';
import { PageInfo } from '@optx/models/table/Pagination';
import { SortByRule } from '@optx/models/table/sorting';
// redux
import { actions as searchActions } from '@features/grid/search';
import * as actions from './actions';
import { fetchFailReducer, fetchReducer } from '../../feature/fetch/reducers';
import { ContactsSavedSearchesState } from '@optx/redux/feature/saved-search/interfaces';
import {
  initialState,
  fetchSavedSearchesReducer,
  fetchSavedSearchesSuccessReducer,
} from '../../feature/saved-search/reducers';

const loadCompaniesByFilterCompletedReducer: CaseReducer = draftState => {
  draftState.loading = false;
  draftState.newOpportunitiesLoading = false;
};

const fetchSuggestedSearchesSuccessReducer: CaseReducer<
  ContactsSavedSearchesState,
  PayloadAction<
    Array<SearchSaveResponse> | Dictionary<SearchSaveResponse>,
    any,
    {
      filterSources: Array<FilterSource<Filter<any>>>;
      clearedFilters: Dictionary<PreselectedFilter>;
      total?: number;
      query?: string;
      pagination?: PageInfo;
      sortBy?: Array<SortByRule<any>>;
    }
  >
> = (draftState, action) => {
  const suggestedSearches = (action.payload as Array<SearchSaveResponse>).map(suggestedSearch => ({
    ...suggestedSearch,
    search_criteria: decodeURIComponent(suggestedSearch.search_criteria),
    ...(suggestedSearch.search_info && {
      search_info: {
        ...suggestedSearch.search_info,
        filters:
          suggestedSearch.search_info?.filters.map(filter => decodeURIComponent(filter)) || [],
      },
    }),
  }));

  const { filterSources, clearedFilters, total, query, pagination, sortBy } = action.meta;

  fetchSavedSearchesSuccessReducer(draftState, {
    payload: suggestedSearches,
    meta: { filterSources, clearedFilters, total, query, pagination, sortBy },
    type: '',
  });
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(searchActions.loadSuggestedSearch, fetchReducer)
    .addCase(searchActions.loadCompaniesByFilterCompleted, loadCompaniesByFilterCompletedReducer)
    .addCase(actions.fetchSuggestedSearches, fetchSavedSearchesReducer)
    .addCase(actions.fetchSuggestedSearchesSuccess, fetchSuggestedSearchesSuccessReducer)
    .addCase(actions.fetchSuggestedSearchesFail, fetchFailReducer)
);

export default reducer;
