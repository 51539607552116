import React from 'react';
import FixedLoader from './FixedLoader';
import { LoadIcon32 } from '../../LoadingIcon';
import CancelSearchButton from '../buttons/CancelSearchButton';

interface FixedLoaderProps {
  loading?: boolean;
  showCancel?: boolean;
  onCancel: VoidFunction;
}

const FixedLoaderWithCancel: React.FC<FixedLoaderProps> = ({ loading, showCancel, onCancel }) => {
  return (
    <FixedLoader loading={loading}>
      <LoadIcon32 />
      {showCancel && <CancelSearchButton onClick={onCancel} />}
    </FixedLoader>
  );
};

export default FixedLoaderWithCancel;
