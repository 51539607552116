import React, { useContext } from 'react';

import { Histogram as HistogramChart, HistogramProps } from '@features/histograms/base-histograms';
import { HistogramSlider } from '@optx/models/filters';
import { FiltersContext } from './FiltersContext';

type HistogramWithContextProps = Pick<HistogramProps, 'max' | 'min'> & {
  filterKey: string;
};

/**
 * Histogram connected to FiltersContext.
 */
export const Histogram: React.FC<HistogramWithContextProps> = ({ filterKey, ...rest }) => {
  const { histograms } = useContext(FiltersContext);

  const filterHistograms = histograms[filterKey]
    ? (histograms[filterKey] as HistogramSlider).data
    : [];

  return <HistogramChart histogramList={filterHistograms} {...rest} />;
};
