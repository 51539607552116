import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import reducer, { saga, selectors, actions } from '../../../redux/user-management';
import useInitialFetch from '../fetch/useInitialFetch';

const useFetchUserManagement = () => {
  useInjectReducer({
    key: 'userManagement',
    reducer,
  });
  useInjectSaga({ key: 'userManagementSaga', saga });

  const shouldFetch = useSelector(selectors.shouldFetch);
  const dispatch = useDispatch();
  const fetchUserManagement = useCallback(
    () => dispatch(actions.fetchUserManagement()),
    [dispatch]
  );
  useInitialFetch(shouldFetch, fetchUserManagement);
};

export default useFetchUserManagement;
