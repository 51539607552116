import React from 'react';
import { LinkedinOutlined } from '@ant-design/icons';
import { convertToURL } from '@optx/utils/url';
import { DEFAULT_CELL_VALUE } from '../../../../../constants/table/cells';

export const TableCellLinkedin = (value: string) => {
  if (!value || value === '-') {
    return <>{DEFAULT_CELL_VALUE}</>;
  }

  return (
    <>
      <a href={convertToURL(value)} target="_blank" rel="noopener noreferrer">
        <LinkedinOutlined style={{ fontSize: 22 }} />
      </a>
    </>
  );
};

export default TableCellLinkedin;
