import React, { useMemo } from 'react';
import Styles from './StatsCompare.styles';

type StatsCompareValues = [number, number];
type RenderValueFunc = (value: number) => React.ReactNode;

interface StatsCompareProps {
  title: string;
  values: StatsCompareValues;
  renderMainValue?: RenderValueFunc;
  renderCompareValue?: RenderValueFunc;
}

const computePercent = (value1: number, value2: number) => (value1 / value2) * 100;

const getFilledPercentages = (firstValue: number, secondValue: number) => {
  let firstValueProgress = 100;
  let secondValueProgress = 100;

  // Compute smallest value percent.
  if (firstValue > secondValue) {
    secondValueProgress = computePercent(secondValue, firstValue);
  } else if (firstValue < secondValue) {
    firstValueProgress = computePercent(firstValue, secondValue);
  } else if (firstValue === 0) {
    // If both are equal to zero then we don't fill percentage.
    firstValueProgress = 0;
    secondValueProgress = 0;
  }

  return [firstValueProgress, secondValueProgress];
};

const defaultRenderValue: RenderValueFunc = (value: number) => value;

const StatsCompare: React.FC<StatsCompareProps> = ({
  title,
  values,
  renderMainValue = defaultRenderValue,
  renderCompareValue = defaultRenderValue,
}) => {
  const [mainValue, comparedValue] = values;
  const [mainfilledProgress, comparedfilledProgress] = useMemo(
    () => getFilledPercentages(mainValue, comparedValue),
    [comparedValue, mainValue]
  );

  return (
    <Styles.Wrapper>
      <Styles.Title>{title}</Styles.Title>
      <Styles.MainStatValue>{renderMainValue(mainValue)}</Styles.MainStatValue>
      <Styles.MainStatProgress size="small" percent={mainfilledProgress} showInfo={false} />
      <Styles.ComparedProgress size="small" percent={comparedfilledProgress} showInfo={false} />
      <Styles.ComparedValue>{renderCompareValue(comparedValue)}</Styles.ComparedValue>
    </Styles.Wrapper>
  );
};

export default StatsCompare;
