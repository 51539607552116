import { useInjectReducer, useInjectSaga } from 'redux-injectors';
// hooks
import { useInject as useInjectCompanyMerges } from '@features/company/merges';
import { useInjectAuditTrail } from '@features/company/audit-trail';
import { useInject as useInjectCompanySocialMedia } from '@features/company/social-media';
import {
  useInjectCompanyTouches,
  useInjectPortfolio,
  useInjectEquityTouch,
  useInjectDatePresented,
  useInjectScoreGrowth,
  useInjectEmployeeGrowth,
  useInjectCompanyFundingRounds,
  useInjectFavorites,
  useInjectCompanyUserValues,
  useInjectCompanyInBusinessEvaluation,
  useInjectCompanyActive,
  useInjectCompanyAddonManagement,
  useInjectScore,
} from '../../../common/hooks/inject';
import companyProfileReducer from '../../../redux/company/profile';
import profileSaga from './saga';

export const useInjectCompanyProfile = () => {
  useInjectReducer({ key: 'companyProfile', reducer: companyProfileReducer });
  useInjectSaga({ key: 'companyProfile', saga: profileSaga });
};

/**
 * Inject need reducers and sagas for profile page.
 */
export const useInjectProfilePage = () => {
  useInjectCompanyProfile();
  useInjectCompanyTouches();
  useInjectPortfolio();
  useInjectEquityTouch();
  useInjectDatePresented();
  useInjectScoreGrowth();
  useInjectEmployeeGrowth();
  useInjectCompanyFundingRounds();
  useInjectCompanyMerges();
  useInjectAuditTrail();
  useInjectCompanySocialMedia();
  useInjectFavorites();
  useInjectCompanyUserValues();
  useInjectCompanyInBusinessEvaluation();
  useInjectCompanyActive();
  useInjectCompanyAddonManagement();
  useInjectScore();
};
