import React from 'react';
import { Checkbox } from 'antd';
// models
import { SelectProps } from './interface';

const CheckboxIcon: React.FC<SelectProps> = ({ isSelected }) => {
  return <Checkbox checked={isSelected} />;
};

export default CheckboxIcon;
