import { createAction } from '@reduxjs/toolkit';
// models
import { FilterSource, PreselectedFilter } from '@models/filters';
import { FilterTag } from '@optx/models/tags';
import { ApplyFiltersPayload } from '../interfaces';
import { types } from '../types';

export const fetch = createAction(types.filters.fetchFilters.REQUEST);
export const fetchSuccess = createAction<Array<FilterSource>>(types.filters.fetchFilters.SUCCESS);
export const fetchFail = createAction<string>(types.filters.fetchFilters.FAILURE);

export const clearFilters = createAction(types.filters.clearFilters);
export const resetToDefault = createAction(types.filters.resetToDefault);

export const applyFilters = createAction<Partial<ApplyFiltersPayload>>(types.filters.applyFilters);

export const resetFilter = createAction<FilterTag>(types.filters.resetFilter);
export const updateFilters = createAction<{ key: string; value: PreselectedFilter }>(
  types.filters.updateFilters
);
