import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ValueType } from 'react-select';
import { Col } from 'antd';
//models
import { SelectOption } from '@optx/models/Option';
import { CompanyProfile } from '@optx/models/Company';
//services
import { CompanyService } from '@optx/services/api';
//redux
import { selectors as profileSelectors } from '@optx/redux/company/profile';
//utils
import { excelHandler } from '@optx/utils/excelHandler';
//components
import { SingleSelect } from '@optx/shared/view/molecules/Select';
import * as Styled from '../styles/DealPresentation.styled';

interface ExportModalProps {
  isOpen: boolean;
  company: CompanyProfile;
  toggle: VoidFunction;
}

const ExportModal: React.FC<ExportModalProps> = ({ isOpen, toggle, company }) => {
  const selectedDescription = useSelector(profileSelectors.getSelectedDescription);
  const [selectedOption, setSelectedOption] = useState<ValueType<SelectOption> | null>(null);

  useEffect(() => {
    setSelectedOption({
      label: selectedDescription as string,
      value: selectedDescription as string,
    });
  }, [selectedDescription]);

  const handleOk = () => {
    const payload = {
      company_id: company.company_id,
      description_source: (selectedOption as SelectOption)?.value,
    };

    excelHandler(payload, company.company_name, CompanyService.exportPropietaryInfoToExcel);

    toggle();
    setSelectedOption({
      label: selectedDescription as string,
      value: selectedDescription as string,
    });
  };

  const modalOptions = useMemo(() => {
    const keyNames = Object.keys(company.descriptions);
    const options = keyNames.map(key => ({
      label: key,
      value: key,
    }));

    return options;
  }, [company]);

  return (
    <Styled.ModalWrapper
      visible={isOpen}
      width={680}
      title="Export Options"
      onOk={handleOk}
      onCancel={() => {
        toggle();
        setSelectedOption({
          label: selectedDescription as string,
          value: selectedDescription as string,
        });
      }}
    >
      <Col style={{ marginTop: '20px' }}>
        <Col>Select a Description to Export</Col>
        <Styled.DropDownExportContainer>
          <SingleSelect
            SearchIcon={false}
            value={selectedOption}
            options={modalOptions}
            closeMenuOnSelect
            onChange={setSelectedOption}
          />
        </Styled.DropDownExportContainer>
      </Col>
    </Styled.ModalWrapper>
  );
};

export default ExportModal;
