import React from 'react';
import { useSelector } from 'react-redux';
import { SalesloftBulkResults } from '@models/bulkActions';
import { selectors as bulkSelectors } from '@features/bulk-actions';
import SalesloftUrl from './SalesloftUrl';

const TableCellSalesloftBulkUrl = (value: string, record: SalesloftBulkResults) => {
  const companies = useSelector(bulkSelectors.salesloft.salesloftBulkCompanies);

  const { company_id: companyId, contacts } = record;
  const sentContacts = companies.find(
    company => company.company_id === companyId
  )?.salesloft_contacts;

  if (!sentContacts) {
    return 'Not in Sales Loft';
  }

  return <SalesloftUrl contactsResponse={contacts} sentContacts={sentContacts} />;
};

export default TableCellSalesloftBulkUrl;
