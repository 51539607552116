import { createAction } from '@reduxjs/toolkit';
import * as types from './types';
import {
  ChangedCompanyField,
  ChangedCompanyFieldsPayload,
  ChangedCompanyFieldsResponse,
} from './interfaces';

export const fetchChangedCompanyFields = createAction<ChangedCompanyFieldsPayload>(
  types.fetchChangedCompanyFields.REQUEST
);
export const fetchChangedCompanyFieldsSuccess = createAction(
  types.fetchChangedCompanyFields.SUCCESS,
  (response: ChangedCompanyFieldsResponse, searchPayload: ChangedCompanyFieldsPayload) => ({
    payload: response,
    meta: searchPayload,
  })
);

export const fetchChangedCompanyFieldsFail = createAction<string>(
  types.fetchChangedCompanyFields.FAILURE
);

export const editCompanyAuditTrailFields = createAction<ChangedCompanyField>(
  types.editCompanyFields
);

export const clearCompanyFields = createAction(types.clearCompanyFields);
