import { createSelector } from 'reselect';
//models
import { GridKeys } from '@optx/models/grid';
//utils
import { preProcessSearch } from '@utils/search';
//redux
import { selectFeatureState } from '../../selectFeature';

export const getSearchKey = (gridKey: GridKeys) =>
  createSelector(selectFeatureState, state => preProcessSearch(state.searchkey[gridKey]));

export const getListsSearchKey = createSelector(selectFeatureState, state => {
  const url = window.location.pathname;

  const isSourceScrub = url.includes('ss-list');

  if (isSourceScrub) {
    return preProcessSearch(state.searchkey.sslists);
  } else {
    return preProcessSearch(state.searchkey.watchlists);
  }
});
