import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import reducer, { saga, selectors, actions } from '../../../redux/favorites';
import { useInitialFetch } from '../fetch';

const useInjectFavorites = () => {
  useInjectReducer({
    key: 'favorites',
    reducer,
  });
  useInjectSaga({ key: 'favorites', saga });
};

const useInitialFetchFavorites = () => {
  const dispatch = useDispatch();
  const shouldFetch = useSelector(selectors.shouldInitialFetch);
  const fetchFavorites = useCallback(() => dispatch(actions.fetchFavorites()), [dispatch]);

  useInitialFetch(shouldFetch, fetchFavorites);
};

const useFavorites = () => {
  useInjectFavorites();
  useInitialFetchFavorites();
};

export default useFavorites;
