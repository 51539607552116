import { CaseReducer, PayloadAction, createReducer } from '@reduxjs/toolkit';
//models
import { SearchCountState, SearchCountPayload } from './interfaces';
import { GridKeys, GridPayload } from '@optx/models/grid';
import { SearchPayloadRequest } from '@optx/models/api/contacts';
//constants
import { GRID_KEYS, SEARCH_COUNT_INITAL_STATE } from '@optx/constants/grid';
//utils
import { generateGridReducerInitialState } from '@optx/utils/grid';
//redux
import * as actions from './actions';
import { actions as favoriteListActions } from '@redux/favorites';

const initialState: SearchCountState = generateGridReducerInitialState(
  GRID_KEYS,
  SEARCH_COUNT_INITAL_STATE
);

export const searchCountSuccessReducer: CaseReducer<
  SearchCountState,
  PayloadAction<SearchCountPayload>
> = (draftState, action) => {
  const { gridName, searchCount } = action.payload;
  draftState[gridName].searchCount = searchCount;
  draftState[gridName].loading = false;
};

export const searchCountFailureReducer: CaseReducer<SearchCountState, PayloadAction<GridKeys>> = (
  draftState,
  action
) => {
  const gridKey = action.payload;
  draftState[gridKey].loading = false;
};

const decrementCountReducer: CaseReducer<SearchCountState> = draftState => {
  draftState.watchlists.searchCount -= 1;
};

export const decrementAddonCountReducer: CaseReducer<SearchCountState> = draftState => {
  if (draftState.addons.searchCount !== 0) {
    draftState.addons.searchCount -= 1;
  }
};

export const searchCountReducer: CaseReducer<
  SearchCountState,
  PayloadAction<GridPayload<SearchPayloadRequest | string>>
> = (draftState, action) => {
  const { gridKey } = action.payload;
  draftState[gridKey].loading = true;
};

const reducer = createReducer<SearchCountState>(initialState, builder => {
  builder.addCase(actions.searchCount, searchCountReducer);
  builder.addCase(actions.searchCountFailure, searchCountFailureReducer);
  builder.addCase(actions.searchCountSuccess, searchCountSuccessReducer);
  builder.addCase(favoriteListActions.deleteFavoriteSuccess, decrementCountReducer);
  builder.addCase(actions.decrementAddonCount, decrementAddonCountReducer);
});

export default reducer;
