import { createSelector } from 'reselect';
import { AppState } from '@optx/redux/interfaces';

const selectListImportState = (state: AppState) => state.favoriteLists.listImport;

export const isInProgress = createSelector(selectListImportState, state => state.loading);
export const isCompleted = createSelector(selectListImportState, state => state.completed);
export const getPercentageCompleted = createSelector(
  selectListImportState,
  state => state.percentageCompleted.result
);
export const getFailedImports = createSelector(selectListImportState, state => state.failedImports);
export const getCanceledImports = createSelector(
  selectListImportState,
  state => state.canceledImports
);
export const getImportCompaniesCount = createSelector(
  selectListImportState,
  state => state.importCompaniesCount
);
export const getImportCompanies = createSelector(
  selectListImportState,
  state => state.importCompanies
);
export const getWatchlistTitle = createSelector(selectListImportState, state => state.title);
export const getEditingCompanyIndex = createSelector(
  selectListImportState,
  state => state.editingCompanyIndex
);
export const getResults = createSelector(selectListImportState, state => state.results);
export const getListId = createSelector(selectListImportState, state => state.listId);
export const cancel = createSelector(selectListImportState, state => state.cancel);
export const showSimilarCompanies = createSelector(
  selectListImportState,
  state => state.showSimilarCompanies
);
export const getSimilarCompanies = createSelector(
  selectListImportState,
  state => state.similarCompanies
);

export const successfulCount = createSelector(selectListImportState, state => {
  const successCompanies =
    state.importCompaniesCount -
    state.failedImports.length -
    state.failedImportsOnRequest.length -
    state.canceledImportsNumber;

  if (successCompanies) {
    return successCompanies;
  }

  return 0;
});

export const getCanceledImportsNumber = createSelector(
  selectListImportState,
  state => state.canceledImportsNumber
);

export const getFailedImportsOnRequestNumber = createSelector(
  selectListImportState,
  state => state.failedImportsOnRequest.length
);

export const getFailedImportsOnRequest = createSelector(
  selectListImportState,
  state => state.failedImportsOnRequest
);

export const getFailedBatches = createSelector(selectListImportState, state => state.failedBatches);
export const getIsEditing = createSelector(selectListImportState, state => state.isEditing);
