import React, { useState, useMemo, useRef, useCallback } from 'react';
import { Dictionary, some } from 'lodash';
import Papa from 'papaparse';
import { Popover, Button, Input, Alert } from 'antd';
// models
import { CompanyWatchList } from '@optx/models/WatchList';
// utils
import { regexCsvMimeType } from '@constants/regex';
import { IS_WINDOWS } from '@constants/os';
import { importCsvTemplate } from '@constants/csv/import-csv-sample';
// components
import DragAndDrop from '@components/common/form/input/FileDragAndDrop';

const errorMessages = {
  duplicateTitle: 'There is already a list with this name, please choose a different title !',
  missingTitle: 'List title is empty!',
  fileFormat: 'Invalid import file format! Please use a CSV file.',
  notSupported: 'File not supported!',
};

interface DropdownImportProps {
  listsFavorites: Dictionary<CompanyWatchList>;
  onImportList: (title: string, data: Array<object>) => void;
}

const REQUIRED_FIEDS = ['company', 'url'];

const DropdownImport: React.FC<DropdownImportProps> = ({ listsFavorites, onImportList }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [csvfile, setCsvfile] = useState<File | null>(null);
  const [title, setTitle] = useState('');
  const [error, setError] = useState('');

  const toggle = useCallback(() => {
    setTitle('');
    setCsvfile(null);
    setError('');
    setIsOpen(prevState => !prevState);

    if (isOpen) {
      inputRef.current!.value = '';
    }
  }, [isOpen]);

  const inputRef = useRef<HTMLInputElement>(null);

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTitle(value);
  };

  const handleChange = (file: File | null) => {
    if (file) {
      const subStrName = file.name.split('.');
      let title = '';

      if (subStrName.length > 2) {
        title = subStrName.slice(0, subStrName.length - 1).join('');
      } else {
        title = subStrName[0];
      }

      /*
        These Windows system validations exist because Windows has an issue that
        returns an empty type if the file doesn't have a proper program set to read it's content
        or if the extension itself is not inserted in the Registry Editor.

        Here are some links explaining the issue:
        https://github.com/transferwise/neptune-web/pull/158
        https://stackoverflow.com/questions/51724649/mime-type-of-file-returning-empty-in-javascript-on-some-machines
      */
      const validateWindowsFileName =
        file.name.split('.')[file.name.split('.').length - 1].toLowerCase() === 'csv';

      if (
        (IS_WINDOWS && validateWindowsFileName && !file.type) ||
        regexCsvMimeType.test(file.type)
      ) {
        setCsvfile(file);
        setTitle(title);
        setError('');
      } else {
        setError(errorMessages.fileFormat);
      }
    }
  };

  const handleRemove = () => {
    setCsvfile(null);
    setTitle('');
    setError('');
    inputRef.current!.value = '';
  };

  const getErrors = (result: Papa.ParseResult<any>) => {
    const { fields } = result.meta;
    const missingFields: Array<string> = [];

    REQUIRED_FIEDS.forEach(requiredField => {
      if (!some(fields, field => field.toLocaleLowerCase() === requiredField.toLocaleLowerCase())) {
        missingFields.push(requiredField);
      }
    });

    if (missingFields.length) {
      if (missingFields.length > 1) {
        return `${missingFields.join(' , ')} columns are required!`;
      }

      return `${missingFields.join(' , ')} column is required!`;
    }

    return '';
  };

  const AlertMessage = useMemo(
    () => (
      <>
        File type - CSV <br />
        Required columns - Company, URL <br />
        Column headers should be named - Company, URL <br />
        Download -{' '}
        <a
          href={`data:text/csv;charset=utf-8,${importCsvTemplate}`}
          download="defaultCSVTemplate.csv"
        >
          CSV Template
        </a>
      </>
    ),
    []
  );

  const DropdownContent = useMemo(() => {
    const handleImportList = (data: Array<any>) => {
      if (some(listsFavorites, ['title', title])) {
        setError(errorMessages.duplicateTitle);
      } else {
        onImportList(title, data);
        inputRef.current!.value = '';
        toggle();
      }
    };

    const updateData = (result: Papa.ParseResult<any>) => {
      const error = getErrors(result);

      if (error) {
        setError(error);
        setCsvfile(null);
      } else {
        handleImportList(result.data);
      }
    };

    const importCSV = () => {
      if (!title) {
        setError(errorMessages.missingTitle);

        return;
      }

      if (csvfile) {
        Papa.parse(csvfile, {
          complete: updateData,
          header: true,
        });
      }
    };

    const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        importCSV();
      }
    };

    return (
      <div onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
        <Alert type="info" className="dropdownimport__info" message={AlertMessage} />
        <div className="dropdownimport__choosefile">
          <DragAndDrop
            ref={inputRef}
            data={csvfile}
            handleChange={handleChange}
            handleRemove={handleRemove}
          >
            {!error && csvfile && csvfile.name ? (
              <div className="file-name text-ellipsis">{csvfile.name}</div>
            ) : (
              ''
            )}
            {error && <div className="text-danger">{error}</div>}
          </DragAndDrop>
        </div>

        <div className="dropdownimport__input">
          <Input
            name="title"
            value={title}
            placeholder="List Name"
            autoFocus
            onChange={onChangeInput}
          />
        </div>

        <div className="dropdownimport__button">
          <Button type="primary" disabled={!csvfile} onClick={importCSV}>
            Import
          </Button>
        </div>
      </div>
    );
  }, [AlertMessage, csvfile, error, title, listsFavorites, onImportList, toggle]);

  return (
    <Popover
      content={DropdownContent}
      title="Select File .CSV"
      visible={isOpen}
      trigger="click"
      onVisibleChange={toggle}
      placement="bottomRight"
      overlayClassName="dropdownimport"
    >
      <Button type="primary">+ Import New Watchlist</Button>
    </Popover>
  );
};

DropdownImport.defaultProps = {
  listsFavorites: {},
  onImportList: () => {},
};

export default DropdownImport;
