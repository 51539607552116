import React, { FC, useEffect, useState, useRef, useMemo } from 'react';
import { RadioChangeEvent, Row, Col, Typography, Button } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
// models
import { SelectOption } from '@optx/models/Option';
import { FieldOptions, EditField } from '@optx/features/company/edit-fields/state/interfaces';
import { AppState } from '@optx/redux/interfaces';
// constants
import { CompanyProfileTabs } from '@optx/constants/routes';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// services
import { CompanyService } from '@optx/services/api';
// utils
import { normalizeValue, showRationaleForStage } from '@optx/utils/helpers';
// redux
import {
  selectors as modalSelectors,
  actions as modalActions,
} from '@redux/ui/modals/company-touches';
import { selectors as individualEditSelectors } from '@components/feature/company-individual-edit/state/index';
import { getFormRationales } from '@optx/redux/company/filters/selectors';
// components
import CustomSelect from './CustomSelect';
import RationaleSection from '@features/company/edit-fields/components/RationaleSection';
import Icon from '@components/common/Icon';
import { Styled } from './AdditionalCompanyInfo.styled';

const normalizeValues = (
  options: SelectOption[],
  selectedValue: string | number | null | undefined,
  isCompanyOwner: boolean = false
) => {
  const additionalOption = isCompanyOwner
    ? { label: 'Unowned', value: 'blank' }
    : { label: 'None', value: 'blank' };

  const normalizedValues = options.map(item => ({
    ...item,
    value: item.value.toString(),
  }));

  const normalizedValue =
    normalizedValues.find(item => item.label === selectedValue?.toString()) || additionalOption;

  return normalizedValue;
};

interface AdditionalCompanyInfoProps {
  shouldUpdateToInitial: boolean;
  setUpdateToInitial: (value: boolean) => void;
  setInfoToSave: (value: EditField | null) => void;
}

const AdditionalCompanyInfo: FC<AdditionalCompanyInfoProps> = ({
  shouldUpdateToInitial,
  setUpdateToInitial,
  setInfoToSave,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { tab } = queryString.parse(location.search);
  const isOpen = useSelector(modalSelectors.isOpen);
  const company = useSelector(modalSelectors.getTouchCompany);
  const selectedStageType = useSelector(modalSelectors.getTouchCompanyStage);
  const selectedStageRationale = useSelector(modalSelectors.getTouchCompanyStageRationale);
  const selectedRankType = useSelector(modalSelectors.getTouchCompanyRank);
  const selectedCompanyOwnerType = useSelector(modalSelectors.getTouchCompanyOwner);
  const companyOwnerOptions = useSelector(
    individualEditSelectors.companyIndividualEdit.companyOwnerOptions
  );
  const companyStageOptions = useSelector(
    individualEditSelectors.companyIndividualEdit.stageOptions
  );
  const companyRankOptions = useSelector(individualEditSelectors.companyIndividualEdit.rankOptions);
  const additionalFieldsError = useSelector(modalSelectors.getAdditionalFieldsError);
  const rationaleOptions = useSelector(
    (state: AppState) => getFormRationales(state, 'stage_pass_dead_rationales') ?? []
  );
  const [companyStage, setCompanyStage] = useState<SelectOption>(
    normalizeValues(companyStageOptions, selectedStageType)
  );
  const [companyOwner, setCompanyOwner] = useState<SelectOption>(
    normalizeValues(companyOwnerOptions, selectedCompanyOwnerType?.split(' ')[1], true)
  );
  const [companyRank, setCompanyRank] = useState<SelectOption>(
    normalizeValues(companyRankOptions, selectedRankType)
  );
  const [stageRationaleVisible, setStageRationaleVisible] = useState<boolean>(false);
  const [rationaleValue, setRationaleValue] = useState<string | null>(selectedStageRationale);
  const [temporaryRationaleValue, setTemporaryRationaleValue] = useState<string | null>(
    selectedStageRationale
  );
  const [isRationaleSaveBtnDisabled, setIsRationaleSaveBtnDisabled] = useState<boolean>(true);
  const stageRelatedRef = useRef<{
    stage: SelectOption;
  } | null>(null);

  const onSelectChange = (value: SelectOption, fieldName: FieldOptions) => {
    if (fieldName === 'Stage') {
      if (showRationaleForStage(value.label)) {
        if (value.label !== companyStage.label) {
          setTemporaryRationaleValue(null);
        }

        setStageRationaleVisible(true);
      } else {
        setRationaleValue(null);
        setTemporaryRationaleValue(null);
        setStageRationaleVisible(false);
      }

      stageRelatedRef.current!.stage = companyStage;
      setCompanyStage(value);

      if (additionalFieldsError.error_field === 'Stage') {
        dispatch(
          modalActions.changeAdditionalFieldsErrorText({ value: value, fieldName: fieldName })
        );
      }
    } else if (fieldName === 'Company Owner') {
      setCompanyOwner(value);

      if (additionalFieldsError.error_field === 'Company Owner') {
        dispatch(
          modalActions.changeAdditionalFieldsErrorText({ value: value, fieldName: fieldName })
        );
      }
    } else if (fieldName === 'Pipeline Rank') {
      setCompanyRank(value);

      if (additionalFieldsError.error_field === 'Pipeline Rank') {
        dispatch(
          modalActions.changeAdditionalFieldsErrorText({ value: value, fieldName: fieldName })
        );
      }
    }
  };

  useEffect(() => {
    const normalizedCompanyStage = normalizeValue(companyStage, companyStageOptions);
    const normalizedCompanyOwner = normalizeValue(companyOwner, companyOwnerOptions);
    const normalizedCompanyRank = normalizeValue(companyRank, companyRankOptions);

    const companyOwnerValue = {
      label: `${companyOwner.value === 'blank' ? 'Unowned' : normalizedCompanyOwner}`,
      value: companyOwner,
    };
    const companyRankValue = {
      label: `${companyRank.value === 'blank' ? 'None' : normalizedCompanyRank}`,
      value: companyRank,
    };
    const companyStageValue = {
      label: `${companyStage.value === 'blank' ? 'None' : normalizedCompanyStage}`,
      value: companyStage,
    };

    const payload = {
      companyId: company.company_id,
      value: {
        'Company Owner': companyOwnerValue.value,
        Stage: companyStageValue.value,
        'Pipeline Rank': companyRankValue.value,
      },
      ...(showRationaleForStage(companyStage.label) ? { rationale: rationaleValue as string } : {}),
      successMessage: 'Company data updated successfully!',
    };

    setInfoToSave({
      companyId: company.company_id,
      value: payload,
      service: CompanyService.updateCompanyMultipleFields,
      fieldName: ['Company Owner', 'Stage', 'Pipeline Rank'] as FieldOptions[],
      isFromHistory: tab === CompanyProfileTabs.HISTORY,
      canBeDuplicatedLoader: true,
      isFromTouch: true,
      companyUrl: company.company_url,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    companyStage,
    companyStageOptions,
    companyOwner,
    companyOwnerOptions,
    companyRank,
    companyRankOptions,
    selectedStageType,
    selectedRankType,
    selectedCompanyOwnerType,
    rationaleValue,
  ]);

  useEffect(() => {
    if (shouldUpdateToInitial || !isOpen) {
      const companyStageValue = normalizeValues(companyStageOptions, selectedStageType);
      setCompanyStage(companyStageValue);
      setCompanyOwner(
        normalizeValues(companyOwnerOptions, selectedCompanyOwnerType?.split(' ')[1], true)
      );
      setCompanyRank(normalizeValues(companyRankOptions, selectedRankType));
      stageRelatedRef.current = {
        stage: companyStageValue,
      };
      setRationaleValue(selectedStageRationale);
      setTemporaryRationaleValue(selectedStageRationale);
      setStageRationaleVisible(false);
      setIsRationaleSaveBtnDisabled(true);
      setInfoToSave(null);
      setUpdateToInitial(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdateToInitial, isOpen]);

  useEffect(() => {
    const companyStageValue = normalizeValues(companyStageOptions, selectedStageType);
    setCompanyStage(companyStageValue);
    setCompanyOwner(
      normalizeValues(companyOwnerOptions, selectedCompanyOwnerType?.split(' ')[1], true)
    );
    setCompanyRank(normalizeValues(companyRankOptions, selectedRankType));
    setRationaleValue(selectedStageRationale);
    setTemporaryRationaleValue(selectedStageRationale);
    stageRelatedRef.current = {
      stage: companyStageValue,
    };
    setStageRationaleVisible(false);
    setIsRationaleSaveBtnDisabled(true);
    setInfoToSave(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedStageType,
    companyStageOptions,
    selectedRankType,
    companyRankOptions,
    selectedCompanyOwnerType,
    companyOwnerOptions,
    selectedStageRationale,
  ]);

  useEffect(() => {
    if (stageRationaleVisible) {
      dispatch(modalActions.changeSaveBtnStatusByAdditionalFields(true));
    } else {
      dispatch(modalActions.changeSaveBtnStatusByAdditionalFields(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageRationaleVisible]);

  if (!company.source_tag.includes('et')) {
    return null;
  }

  const handleRationaleChange = (e: RadioChangeEvent) => {
    const newValue = e.target.value;
    const isStageChanged = stageRelatedRef.current!.stage.label === companyStage.label;
    setTemporaryRationaleValue(newValue);

    if ((newValue !== rationaleValue && isStageChanged) || !isStageChanged) {
      setIsRationaleSaveBtnDisabled(false);
    } else {
      setIsRationaleSaveBtnDisabled(true);
    }
  };

  const handleRationaleCancel = () => {
    setStageRationaleVisible(false);
    setCompanyStage(stageRelatedRef.current!.stage);
    setTemporaryRationaleValue(rationaleValue);
    setIsRationaleSaveBtnDisabled(true);
  };

  const handleRationaleSave = () => {
    setStageRationaleVisible(false);
    setRationaleValue(temporaryRationaleValue);
    setIsRationaleSaveBtnDisabled(true);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const normalizedRationaleValue = useMemo(() => {
    const rationaleOption = rationaleOptions.find(option => option.value === rationaleValue);

    if (rationaleOption) {
      return rationaleOption.label;
    }

    return rationaleValue;
  }, [rationaleValue, rationaleOptions]);

  const handleOtherChange = (newValue: React.SetStateAction<string | null>) => {
    setTemporaryRationaleValue(newValue);
    const isStageChanged = stageRelatedRef.current!.stage.label === companyStage.label;

    if ((newValue !== rationaleValue && isStageChanged) || !isStageChanged) {
      setIsRationaleSaveBtnDisabled(false);
    } else {
      setIsRationaleSaveBtnDisabled(true);
    }
  };

  const showIsOtherOrTag = (key?: string) => {
    const values = rationaleOptions.map(option => option.value).slice(0, -1);

    if (key === 'tag') {
      return !!(
        rationaleValue &&
        rationaleValue !== DEFAULT_EMPTY_VALUE &&
        !values.includes(rationaleValue) &&
        companyStage.label === stageRelatedRef.current!.stage.label
      );
    }

    if (!temporaryRationaleValue) {
      return false;
    }

    return !!(
      !values.includes(temporaryRationaleValue) &&
      temporaryRationaleValue !== DEFAULT_EMPTY_VALUE &&
      temporaryRationaleValue.length > 0
    );
  };

  const customRationaleValue = showIsOtherOrTag() ? 'other' : temporaryRationaleValue;

  return (
    <Row className="company-additional-info-wrapper">
      <div className="company-additional-info">
        <CustomSelect
          type="Stage"
          initialValue={companyStage}
          setNewValue={onSelectChange}
          isError={
            additionalFieldsError.error_field === 'Stage' && !!additionalFieldsError.error_text
          }
          disabled={stageRationaleVisible}
        />
        <CustomSelect
          type="Pipeline Rank"
          initialValue={companyRank}
          setNewValue={onSelectChange}
          isError={
            additionalFieldsError.error_field === 'Pipeline Rank' &&
            !!additionalFieldsError.error_text
          }
        />
        <CustomSelect
          type="Company Owner"
          initialValue={companyOwner}
          setNewValue={onSelectChange}
          isError={
            additionalFieldsError.error_field === 'Company Owner' &&
            !!additionalFieldsError.error_text
          }
        />
      </div>
      {!stageRationaleVisible && rationaleValue && rationaleValue !== DEFAULT_EMPTY_VALUE && (
        <Styled.RationaleVerifiedWrapper>
          <CheckCircleTwoTone twoToneColor="#52c41a" />
          <span className="rationale-label">{companyStage.label} Rationale: </span>
          <div
            className="rationale-value"
            onClick={() => {
              setStageRationaleVisible(true);
              stageRelatedRef.current!.stage = companyStage;
            }}
          >
            <span>{normalizedRationaleValue}</span>
            <div className="rationale-edit-icon">
              <Icon iconName="pen" />
            </div>
          </div>
        </Styled.RationaleVerifiedWrapper>
      )}
      {stageRationaleVisible && (
        <Styled.ChangeRationaleWrapper>
          <Row gutter={[0, 0]}>
            <Col span={24}>
              <Row justify="space-between" className="heading-wrapper">
                <Col>
                  <Typography.Title>
                    Add {companyStage.label} Rationale <span>(Required)</span>
                  </Typography.Title>
                </Col>
                <Col>
                  <Button onClick={handleRationaleCancel} className="cancel-btn">
                    Cancel
                  </Button>
                  <Button
                    onClick={handleRationaleSave}
                    className="save-rationale-btn"
                    disabled={isRationaleSaveBtnDisabled}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
              <Row className="body-row">
                <RationaleSection
                  rationaleOptions={rationaleOptions}
                  rationaleValue={customRationaleValue}
                  handleRationaleChange={handleRationaleChange}
                  currRationaleValue={rationaleValue}
                  showIsOtherOrTag={showIsOtherOrTag}
                  handleOtherChange={handleOtherChange}
                  isFromTouch={true}
                />
              </Row>
            </Col>
          </Row>
        </Styled.ChangeRationaleWrapper>
      )}
      {additionalFieldsError.error_text && (
        <div className="company-additional-info__error">{additionalFieldsError.error_text}</div>
      )}
    </Row>
  );
};

export default AdditionalCompanyInfo;
