import React from 'react';
import { useSelector } from 'react-redux';
// redux
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
// components
import InProgress from '../bulk-progress/InProgress';
import Completed from '../bulk-progress/Completed';
import BulkEditingResults from '../bulk-progress/BulkEditingResults';
import AutoSave from '../common/AutoSave';

const Step3: React.FC = () => {
  const completed = useSelector(bulkActionsSelectors.equityTouch.isCompleted);

  return (
    <>
      {completed ? <Completed /> : <InProgress />}
      <BulkEditingResults />
      <AutoSave step={3} />
    </>
  );
};

export default Step3;
