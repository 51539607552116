import styled, { css } from 'styled-components';
// components
import { Value } from '@components/common/Text/OptionValue';
import TrendingValueStyles from '@optx/shared/view/molecules/trending/TrendingValue.styles';
import Card from '@components/common/cards';
// components
import CardTitle from './CardTitle';
import CardTitleStyles from './CardTitle.styles';
import StyledInfo from './Info.styled';

const CardOptionsWrapper = styled.div`
  display: flex;

  & > * {
    flex: 1;
  }
`;

const PastDue = styled.span<{ $value: number }>`
  color: ${props => (props.$value > 0 ? '#FF4D4F' : 'inherit')};
`;

const cardActiveStyles = css`
  background: #001529;

  .ant-card-body {
    background: #03213e;
  }

  ${CardTitleStyles.Title} {
    /* Daybreak Blue / blue-6 */
    color: #1890ff;
  }

  ${CardTitleStyles.TitleValue} {
    color: #fff;
  }

  ${Value} {
    color: #fff;
  }

  ${StyledInfo.Icon} {
    opacity: 1;
  }
`;

const StyledCard = styled(Card)<{ $active?: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex: 1;
    padding: 15px 24px;
  }

  ${StyledInfo.Icon} {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  ${props => props.$active && cardActiveStyles};
`;

const StyledCardTitle = styled(CardTitle)<{ active: boolean }>`
  /*
  Trending value is black when trending is 0.
  Because on highlight background is dark color we need to make text white.
  */
  ${props =>
    props.active &&
    props.trending === 0 &&
    css`
      ${TrendingValueStyles.TrendingValue} {
        color: #fff;
      }
    `}
`;

export default {
  CardOptionsWrapper,
  PastDue,
  Card: StyledCard,
  CardTitle: StyledCardTitle,
};
