import { Dictionary } from 'lodash';
// models
import { PreselectedFilter } from '@models/filters';
import { FormCheckboxOption } from '@models/Option';
import { FilterTag } from '@models/tags';
// constants
import { FILTER_TOUCHES, FILTERS_WITH_CHECKBOX_AS_RADIO } from '@constants/filters';
// utils
import { getMappedTouchFilters } from '@utils/filters/filterHelpers';

/**
 * Remove touch filters
 * @param filters - filters
 * @param removedFilter - the filter to be removed
 * @returns the cleared touch filters
 */
export const removeTouchFilters = (
  filters: Dictionary<PreselectedFilter>,
  filterTag: FilterTag
) => {
  const removedFilter = filterTag.filter;

  if (
    removedFilter !== 'completed_touch' &&
    removedFilter !== 'schedule_touch' &&
    !FILTERS_WITH_CHECKBOX_AS_RADIO.includes(removedFilter)
  ) {
    return {};
  }

  const touchKey = removedFilter.split('_')[0];

  const filterTouchTypeDate = filters[`${touchKey}_touch_type_date`] as FormCheckboxOption[];
  const filterTouchType = filters[`${touchKey}_touch_type`] as FormCheckboxOption[];
  const filterTouchDate = filters[`${touchKey}_touch_date`] as FormCheckboxOption[];

  // handle special case when the completed_touch or schedule_touch filter is removed
  // but it only has a few types selected, not all of them. In this case we should select (add)
  // all the types, not remove all the touch filters, but only if another option is selected
  // (like date range, exclude or date checkboxes)
  const areTouchOptionsChecked =
    filterTouchTypeDate.some(touch => touch.checked) ||
    filterTouchType.some(touch => touch.checked) ||
    filterTouchDate.some(touch => touch.value !== '');

  const removedFilterValues = filterTag.values;

  if (
    areTouchOptionsChecked &&
    (removedFilterValues.length !== FILTER_TOUCHES.length ||
      removedFilterValues.map(valueObj => valueObj.value).includes('None')) &&
    !FILTERS_WITH_CHECKBOX_AS_RADIO.includes(removedFilter)
  ) {
    return { [filterTag.filter]: getMappedTouchFilters() };
  }

  const touchTypeDate = filterTouchTypeDate.map(item => ({
    ...item,
    checked: false,
  }));

  const touchType = filterTouchType.map(item => ({
    ...item,
    checked: false,
  }));

  const touchDate = filterTouchDate.map(item => ({
    ...item,
    value: '',
  }));

  return {
    ...(FILTERS_WITH_CHECKBOX_AS_RADIO.includes(removedFilter)
      ? { [`${touchKey}_touch`]: [] }
      : {}),
    [`${touchKey}_touch_type_date`]: touchTypeDate,
    [`${touchKey}_touch_type`]: touchType,
    [`${touchKey}_touch_date`]: touchDate,
  };
};
