import { CaseReducer, PayloadAction, createReducer } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { Dictionary } from 'lodash';
//models
import { Filter, FilterSource } from '@optx/models/filters';
import { SearchKeyState, UserSessionsSettingsSearchKey } from './interface';
import { CompanyFiltersMeta, SearchSaveResponse } from '@optx/models/search';
import { GridPayload } from '@optx/models/grid';
import { UserInformation, UserSessionSettings } from '@optx/models/user';
//constants
import { userSessionsSettingsGridsSearchKeys } from './constants';
import { GRID_KEYS } from '@optx/constants/grid';
import appRoutes from '@constants/routes';
//utils
import { generateGridReducerInitialState } from '@optx/utils/grid';
import { parseFilter } from '@utils/filters/parseFilters';
//redux
import * as actions from './actions';
import { actions as userInformationActions } from '@redux/user/information';
import { actions as companyFiltersActions } from '@redux/company/filters';
import { actions as savedSearchesActions } from '@redux/company/saved-searches';
import { actions as addonManagementActions } from '@optx/redux/company/addon-management';

const initialState: SearchKeyState = generateGridReducerInitialState(GRID_KEYS, '');

// clear Addons Mgmt search key on clear icon
const clearAddonSearchKeyReducer: CaseReducer<SearchKeyState> = draftState => {
  draftState.addons = '';
};

const setSearchKeyReducer: CaseReducer<SearchKeyState, PayloadAction<GridPayload<string>>> = (
  draftState,
  action
) => {
  const { data, gridKey } = action.payload;
  draftState[gridKey] = data;
};

const fetchUserInformationSuccessReducer: CaseReducer<
  SearchKeyState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const sessionSettingsKeys = Object.keys(action.payload.settings.session_settings);

  sessionSettingsKeys.forEach(key => {
    const persistedSearch = action.payload.settings.session_settings
      ? action.payload.settings.session_settings[key as keyof UserSessionSettings]
      : undefined;

    if (persistedSearch !== undefined && /^.*query.*$/.test(persistedSearch as string)) {
      const data = queryString.parse(persistedSearch as string);

      if ((persistedSearch as string).includes('ss_list_id')) {
        draftState.sslists = data.query as string;
      }

      if (data.rationale_report_query) {
        draftState.checklistResearchInsights = data.rationale_report_query as string;
      } else {
        draftState[userSessionsSettingsGridsSearchKeys[key as UserSessionsSettingsSearchKey]] =
          data.query as string;
      }
    }
  });
};

/**
 * When filters are loaded if there are filters in URL it was a redirect and should reset search key.
 * @param draftState
 * @param action
 */
const fetchFiltersSuccessReducer: CaseReducer<
  SearchKeyState,
  PayloadAction<Array<FilterSource>, any, CompanyFiltersMeta>
> = (draftState, action) => {
  const searchPathname = action.meta.isAnalyst ? appRoutes.advancedSearch : appRoutes.home;

  if (window.location.search && window.location.pathname === searchPathname) {
    const queryFilter = window.location.search.replace('?', '');
    const [parsedFilters] = parseFilter(queryFilter, action.payload);

    if (parsedFilters?.query) {
      draftState.advancedSearch = (parsedFilters?.query as string) || '';
    }
  }
};

const initializeSavedSearchesAndViewsSuccessReducer: CaseReducer<
  SearchKeyState,
  PayloadAction<
    Array<SearchSaveResponse> | Dictionary<SearchSaveResponse>,
    any,
    {
      filterSources: Array<FilterSource<Filter<any>>>;
      isAnalyst?: boolean;
    }
  >
> = (draftState, action) => {
  const allSavedSearches = action.payload as Array<SearchSaveResponse>;
  const { filterSources, isAnalyst } = action.meta;

  const query = window.location.search ? window.location.search.replace('?', '') : undefined;

  const listId = query?.split('=')[1];
  const list = query?.split('=')[0] === 'saved_search_id';

  const searchPathname = isAnalyst ? appRoutes.advancedSearch : appRoutes.home;

  if (list && window.location.pathname === searchPathname) {
    const savedSearch = allSavedSearches.find(search => search.unique_id.toString() === listId);

    if (savedSearch) {
      const queryFilter = savedSearch.search_criteria;
      const [parsedFilters] = parseFilter(queryFilter, filterSources);

      if (parsedFilters?.query) {
        draftState.advancedSearch = (parsedFilters?.query as string) || '';
      }
    }
  }
};

const reducer = createReducer<SearchKeyState>(initialState, builder => {
  // custom clear addons searchKey on Clear All button
  builder.addCase(addonManagementActions.clearSearch, clearAddonSearchKeyReducer);
  builder.addCase(actions.setSearchKey, setSearchKeyReducer);
  builder.addCase(
    userInformationActions.fetchUserInformationSuccess,
    fetchUserInformationSuccessReducer
  );
  builder.addCase(companyFiltersActions.fetchCompanyFiltersSuccess, fetchFiltersSuccessReducer);
  builder.addCase(
    savedSearchesActions.initializeSavedSearchesAndViewsSuccess,
    initializeSavedSearchesAndViewsSuccessReducer
  );
});

export default reducer;
