import { NavigationPathItem } from '@optx/models/menu/main-navigation';
import appRoutes from './routes';

export const NAVIGATION_SUBMENUES = {
  pipeline: 'pipeline',
  findNewCompanies: 'findNewCompanies',
  contacts: 'contacts',
  admin: 'admin',
  profile: 'profile',
  workforceManagement: 'reports',
  newOpportunities: 'newOpportunities',
};

const navPaths: Array<NavigationPathItem> = [
  {
    type: 'submenu',
    path: NAVIGATION_SUBMENUES.pipeline,
    items: [
      {
        type: 'item',
        path: appRoutes.lists.myWatchList,
      },
      {
        type: 'item',
        path: appRoutes.lists.companySavedSearches,
      },
      {
        type: 'item',
        path: appRoutes.notes,
      },
    ],
  },
  {
    type: 'submenu',
    path: NAVIGATION_SUBMENUES.findNewCompanies,
    items: [
      {
        type: 'item',
        path: appRoutes.lists.suggestedSearches,
      },
      {
        type: 'item',
        path: appRoutes.lists.sourceScrubLists,
      },
    ],
  },
  {
    type: 'submenu',
    path: NAVIGATION_SUBMENUES.contacts,
    items: [
      {
        type: 'item',
        path: appRoutes.contacts,
      },
      {
        type: 'item',
        path: appRoutes.lists.contactSearches,
      },
    ],
  },
  {
    type: 'submenu',
    path: NAVIGATION_SUBMENUES.workforceManagement,
    items: [
      {
        type: 'item',
        path: appRoutes.reports.sourcingAnalystsLeaderboard,
      },
    ],
  },
  {
    type: 'submenu',
    path: NAVIGATION_SUBMENUES.admin,
    items: [
      {
        type: 'item',
        path: appRoutes.admin.users,
      },
      {
        type: 'item',
        path: appRoutes.reports.equityTouchIntegration,
      },
    ],
  },
  {
    type: 'submenu',
    path: NAVIGATION_SUBMENUES.profile,
    items: [
      {
        type: 'item',
        path: appRoutes.user.profile,
      },
    ],
  },
  {
    type: 'submenu',
    path: NAVIGATION_SUBMENUES.newOpportunities,
    items: [],
  },
];

export default navPaths;
