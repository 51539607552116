import { call, takeLatest, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import * as savedSearchesActions from '@redux/company/saved-searches/actions';
import { AxiosResponse } from 'axios';
import * as actions from './actions';
import { ShareList } from './interfaces';
import NotificationService from '../../../services/NotificationService';
import { UserService } from '../../../services/api';

export function* shareListSaga(action: PayloadAction<ShareList>) {
  try {
    const res: AxiosResponse<any> = yield call(UserService.shareList, action.payload);

    if (res.data) {
      const { share_with: shareWithList, list_id: listId } = action.payload;
      const shareWithListTransformed = shareWithList.map(item => +item);
      const successMessage = 'Succeed to share the list';

      yield put(savedSearchesActions.updateSavedSearches(shareWithListTransformed, listId));
      NotificationService.success(successMessage);
    }
  } catch (error: any) {
    const errorMessage = 'Failed to share the list';
    NotificationService.error(errorMessage);
  }
}

export default function* shareSearchListSaga() {
  yield takeLatest(actions.shareListAction.type, shareListSaga);
}
