import { ColumnsType } from 'antd/lib/table';
import { CompanyColumnDisplay } from '@optx/models/table/Columns';

export const filterSortableVisibleColumns = (
  columns: ColumnsType<any>,
  columnDisplay: CompanyColumnDisplay<any>,
  order: string[],
  existActionColumn = true
) => {
  const mappedOrder = order
    .filter(columnId => columnDisplay[columnId].visible)
    .map(columnId => {
      // @ts-ignore
      const column = columns.find(column => column.dataIndex === columnId);

      if (columnDisplay[columnId].pinned) {
        return { ...column, width: columnDisplay[columnId].width, fixed: 'left' };
      }

      return { ...column, width: columnDisplay[columnId].width, fixed: undefined };
    });

  if (existActionColumn) {
    // @ts-ignore
    return mappedOrder.concat(columns[columns.length - 1]);
  } else {
    return mappedOrder;
  }
};
