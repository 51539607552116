import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { CompanyMergesResponse } from '@optx/models/company/CompanyMerges';
import { fetchFailReducer } from '@redux/feature/fetch/reducers';
import * as actions from './actions';
// models
import {
  CompanyMergesPayload,
  CompanyMergesState,
  SelectedCompaniesPayload,
  UnmergeCompaniesResponse,
} from './interfaces';

const initialState: CompanyMergesState = {
  data: [],
  loading: false,
  error: '',
  fetchedAt: '',
  count: 0,
  per_page: 200,
  pagination: {
    pageNumber: 1,
    pageSize: 200,
  },
  selectedCompanies: [],
  unmergeCompleted: false,
  unmergeLoading: false,
  unmergeModalVisible: false,
  shouldCopyData: false,
  unmergedCompanyId: null,
  resetData: false,
  unmergeError: '',
  hasMore: false,
};

// fetch merges
const fetchMergesReducer: CaseReducer<CompanyMergesState, PayloadAction<CompanyMergesPayload>> = (
  draftState,
  action
) => {
  draftState.loading = true;
  draftState.resetData = action.payload.reset ?? false;
  draftState.error = '';
};

const fetchMergesSuccessReducer: CaseReducer<
  CompanyMergesState,
  PayloadAction<CompanyMergesResponse, any, CompanyMergesPayload>
> = (draftState, action) => {
  const results = action.payload.results;

  if (draftState.resetData) {
    draftState.data = results;
    draftState.pagination.pageNumber = 1;
  } else {
    draftState.data = [...draftState.data, ...results];
    draftState.pagination.pageNumber += 1;
  }

  draftState.loading = false;
  draftState.count = action.payload.count;
  draftState.hasMore =
    results.length === action.payload.per_page && results.length < action.payload.count;
};

const selectCompanyIdsReducer: CaseReducer<
  CompanyMergesState,
  PayloadAction<SelectedCompaniesPayload>
> = (draftState, { payload }) => {
  draftState.selectedCompanies = payload.selectedCompanies;
};

const setUnmergeCompletedReducer: CaseReducer<CompanyMergesState, PayloadAction<boolean>> = (
  draftState,
  { payload }
) => {
  draftState.unmergeCompleted = payload;
};

const setUnmergeLoadingReducer: CaseReducer<CompanyMergesState, PayloadAction<boolean>> = (
  draftState,
  { payload }
) => {
  draftState.unmergeLoading = payload;
};

const resetUnmergeReducer: CaseReducer<CompanyMergesState, PayloadAction> = draftState => {
  draftState.selectedCompanies = [];
  draftState.unmergedCompanyId = null;
  draftState.resetData = false;
  draftState.unmergeError = '';
  draftState.unmergeCompleted = false;
  draftState.unmergeLoading = false;
  draftState.unmergeModalVisible = false;
  draftState.shouldCopyData = false;
};

const toggleUnmergeModalReducer: CaseReducer<CompanyMergesState, PayloadAction<boolean>> = (
  draftState,
  { payload }
) => {
  draftState.unmergeModalVisible = payload;
};

const resetPaginationReducer: CaseReducer<CompanyMergesState, PayloadAction> = draftState => {
  draftState.pagination = {
    pageNumber: 1,
    pageSize: 25,
  };
};

const toggleShouldCopyDataReducer: CaseReducer<CompanyMergesState, PayloadAction> = draftState => {
  draftState.shouldCopyData = !draftState.shouldCopyData;
};

const setUnmergeCompanyIdReducer: CaseReducer<
  CompanyMergesState,
  PayloadAction<UnmergeCompaniesResponse>
> = (draftState, { payload }) => {
  draftState.unmergedCompanyId = payload.company_id;
};

const setUnmergeErrorReducer: CaseReducer<CompanyMergesState, PayloadAction<string>> = (
  draftState,
  { payload }
) => {
  draftState.unmergeError = payload;
};

const reducer = createReducer(initialState, builder =>
  builder
    // fetch company merges
    .addCase(actions.fetchMerges, fetchMergesReducer)
    .addCase(actions.fetchMergesSuccess, fetchMergesSuccessReducer)
    .addCase(actions.fetchMergesFail, fetchFailReducer)
    // unmerge companies
    .addCase(actions.setSelectedCompanies, selectCompanyIdsReducer)
    .addCase(actions.setUnmergeCompleted, setUnmergeCompletedReducer)
    .addCase(actions.setUnmergeLoading, setUnmergeLoadingReducer)
    .addCase(actions.resetUnmerge, resetUnmergeReducer)
    .addCase(actions.toggleUnmergeModal, toggleUnmergeModalReducer)
    .addCase(actions.toggleShouldCopyData, toggleShouldCopyDataReducer)
    .addCase(actions.resetPagination, resetPaginationReducer)
    .addCase(actions.setUnmergeCompanyId, setUnmergeCompanyIdReducer)
    .addCase(actions.setUnmergeError, setUnmergeErrorReducer)
);

export default reducer;
