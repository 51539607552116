import { createAction } from '@reduxjs/toolkit';
import { ChartData } from '@optx/models/charts/data';
import * as types from './types';

export const fetchEmployeeGrowth = createAction<number>(types.fetchEmployeeGrowth.REQUEST);

export const fetchEmployeeGrowthSuccess = createAction<ChartData>(
  types.fetchEmployeeGrowth.SUCCESS
);

export const fetchEmployeeGrowthFail = createAction<string>(types.fetchEmployeeGrowth.FAILURE);
