import React from 'react';

import { Route, Redirect, RouteProps } from 'react-router-dom';
import JWTStore from '../../../storage/localStorage/jwtStorage';
// constants
import appRoutes from '../../../constants/routes';

interface PublicRouteProps extends RouteProps {
  component: React.ComponentType<RouteProps>;
}

const PublicRoute = ({ component: Component, ...rest }: PublicRouteProps) => {
  return (
    <Route
      {...rest}
      render={props => (
        <>
          {!JWTStore.exists() && <Component {...props} />}

          {JWTStore.exists() && (
            <Redirect to={{ pathname: appRoutes.home, state: { from: props.location } }} />
          )}
        </>
      )}
    />
  );
};

export default PublicRoute;
