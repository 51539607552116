import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import { matchPath } from 'react-router';
// models
import { CompanyWatchList } from '@optx/models/WatchList';
// constants
import { FAVORITE_LIST_ID, FAVORITE_LIST_NAME, FAVORITE_LIST_OWNER } from '@constants/lists';
import routes from '@constants/routes';
// utils
import { getListURL } from '@utils/routes';
import { hasEditPermission } from '@utils/lists';
// redux
import { selectors as favoriteListsSelectors } from '@redux/favorite-lists/lists';
import { getFavorites } from '@redux/favorites/selectors';
import { selectors as routeSelectors } from '@redux/router';
import { selectors as userSelectors } from '@redux/user/information';
import { AppState } from '@optx/redux/interfaces';
import { ListDetails } from './interfaces';

const selectedListState = (state: AppState) => state.lists.details;

export const getFilterList = createSelector(
  selectedListState,
  state => state && state.filterList.filter
);

export const getListEditPermission = createSelector(selectedListState, state => {
  const data = state.data as CompanyWatchList;

  return data && data.share_permission ? hasEditPermission(data.share_permission) : true;
});

export const isLoading = createSelector(selectedListState, state => state.loading);

export const getLists = createSelector(
  getFavorites,
  favoriteListsSelectors.getLists,
  favoriteListsSelectors.getAllListIds,
  favoriteListsSelectors.getQuery,
  userSelectors.getFullName,
  (favorites, savedLists, savedListIds, query, userName) => {
    const lists: Array<ListDetails> = [];

    if (
      favorites &&
      favorites.length &&
      FAVORITE_LIST_NAME.toLowerCase().includes(query!.toLowerCase())
    ) {
      lists.push({
        title: FAVORITE_LIST_NAME,
        count: favorites.length,
        date: '',
        unique_id: FAVORITE_LIST_ID,
        global_list: undefined,
        owner_name: userName,
        origin: FAVORITE_LIST_OWNER,
        modified_at: null,
        // View only.
        share_permission: 1,
        is_public: false,
        is_owner: false,
      });
    }

    if (savedLists && !isEmpty(savedLists)) {
      savedListIds.map(id => lists.push({ ...savedLists[id], unique_id: id }));
    }

    return lists.filter(list => list.count !== 0);
  }
);

export const getCSVImportCompanies = createSelector(
  selectedListState,
  state => state && state.CSVImportCompanies
);

export const getListData = createSelector(selectedListState, state => state && state.data);

export const getRouteListId = createSelector(
  routeSelectors.getLocation,
  state => state && state.pathname.split('/')[2]
);
export const getRouteListUrl = createSelector(
  routeSelectors.getLocation,
  state => state && state.pathname
);

export const isSourceScrubRoute = createSelector(routeSelectors.getLocation, state => {
  const match = matchPath(state && state.pathname, {
    path: routes.userLists.sourceScrubLists,
    exact: true,
    strict: false,
  });

  return Boolean(match);
});

export const getFilterListId = createSelector(getListData, getRouteListUrl, (list, listUrl) => {
  if (!list) {
    if (listUrl) return listUrl.split('/').slice(-1)[0];

    return '';
  }

  if ('id' in list) return list.id.toString();

  // If on a different page return ''. This prevents the listId from persisting in store when navigating to other pages
  // like advanced search, global notes - whererver DropdownAdd is used
  const location = getListURL(list.unique_id as string);

  if (listUrl !== location) return '';

  return list.unique_id.toString();
});
