import { createAction } from '@reduxjs/toolkit';
import { ScoreGrowth } from '@optx/models/Company';
import * as types from './types';
import { ChartSuccess } from '../../../models/charts/data';

export const fetchScoreGrowth = createAction(
  types.FETCH_SCORE_GROWTH,
  (data: ScoreGrowth, callback: ChartSuccess) => ({
    payload: data,
    meta: callback,
  })
);
