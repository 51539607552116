import { createFeatureAction } from '@utils/feature';
import featureName from '../../featureName';
import * as actionTypes from './actionTypes';
import { EditLeadSource } from './interfaces';

export const addLeadSource = createFeatureAction<EditLeadSource>(
  featureName,
  actionTypes.ADD_LEAD_SOURCE
);

export const addLeadSourceSuccess = createFeatureAction<EditLeadSource>(
  featureName,
  actionTypes.ADD_LEAD_SOURCE_SUCCESS
);

export const addLeadSourceFail = createFeatureAction<string>(
  featureName,
  actionTypes.ADD_LEAD_SOURCE_FAIL
);
