import React from 'react';
import { useSelector } from 'react-redux';
import { ChartIntervalKeys } from '@optx/models/charts/data';
import Icon from '@components/common/Icon';
import LineChart from '../LineChart';
import { selectors as localSelectors } from '../../state/linkedin';

interface SocialMediaProps {
  companyId: number;
  chartInterval: ChartIntervalKeys;
}

const LinkedinChart: React.FC<SocialMediaProps> = ({ companyId, chartInterval }) => {
  const chartData = useSelector(localSelectors.getData);
  const activeChartData = chartData[chartInterval];

  return (
    <LineChart
      icon={<Icon iconName="linkedin" className="chart-icon" />}
      dataKey="value"
      labelKey={chartInterval === '3M' || chartInterval === '6M' ? 'date' : 'quarter'}
      kpiLabel="Date"
      labelKeyAlt="date"
      title="LinkedIn"
      chartInterval={chartInterval}
      data={activeChartData}
      headerValue={activeChartData.total_followers as number}
      headerLabel="total followers"
      chartLabel="Followers over time"
    />
  );
};

export default React.memo(LinkedinChart);
