import React, { useState } from 'react';
import { Input, Button, Card, Row } from 'antd';
import styled from 'styled-components';
// constants
import { MAX_CHAR_LENGTH } from '@constants/notes';

const EditNoteCard = styled(Card)`
  width: 500px;

  border-radius: 0;

  .ant-card-body {
    padding: 0;
  }

  .note-textarea-wrapper {
    padding: 10px;
  }

  .edit-note-actions {
    border-top: 1px solid #f0f0f0;

    padding: 10px;
    .ant-btn {
      margin-left: 20px;
    }
  }

  .edit-note-textarea {
    resize: none;
    width: 100%;

    box-sizing: border-box;
  }
`;
interface EditNoteContentProps {
  noteText?: string;
  onNoteSave: (noteText: string) => void;
  handleToggle: () => void;
}

const EditNoteContent: React.FC<EditNoteContentProps> = ({
  noteText,
  onNoteSave,
  handleToggle,
}) => {
  const [textValue, setTextValue] = useState(noteText || '');

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
      onNoteSave(textValue);
    }
  };

  return (
    <EditNoteCard title="Edit Note">
      <div className="note-textarea-wrapper">
        <Input.TextArea
          rows={6}
          className="edit-note-textarea"
          name="note_text"
          onKeyDown={handleDropdownKeyEvent}
          value={textValue}
          onChange={e => {
            setTextValue(e.target.value);
          }}
          placeholder="Add your note here"
          maxLength={MAX_CHAR_LENGTH}
        />
      </div>
      <Row justify="end" align="middle" className="edit-note-actions">
        <Button onClick={() => handleToggle()}>Cancel</Button>
        <Button
          type="primary"
          disabled={textValue === noteText}
          onClick={() => onNoteSave(textValue)}
        >
          Save
        </Button>
      </Row>
    </EditNoteCard>
  );
};

export default EditNoteContent;
