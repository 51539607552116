import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMount } from '@umijs/hooks';
import { ChartIntervalKeys } from '@optx/models/charts/data';
import Icon from '@components/common/Icon';
import LineChart from '../LineChart';
import { selectors as localSelectors, actions as localActions } from '../../state/crunchbase';

interface SocialMediaProps {
  companyId: number;
  chartInterval: ChartIntervalKeys;
}

const CrunchbaseChart: React.FC<SocialMediaProps> = ({ companyId, chartInterval }) => {
  const dispatch = useDispatch();

  useMount(() => dispatch(localActions.fetchCrunchbaseData(companyId)));

  const chartData = useSelector(localSelectors.getData);

  const activeChartData = chartData[chartInterval];

  return (
    <LineChart
      icon={<Icon iconName="crunchbase" className="chart-icon" />}
      dataKey="reversedValue"
      labelKey={chartInterval === '3M' || chartInterval === '6M' ? 'date' : 'quarter'}
      kpiLabel="Date"
      labelKeyAlt="date"
      title="Crunchbase"
      chartInterval={chartInterval}
      data={activeChartData}
      headerValue={activeChartData.current_rank as number}
      headerLabel="current rank"
      chartLabel="Rank over time"
      isReversed
      range={[1, 'dataMax']}
      ticks={[1, activeChartData.lowest_rank as number]}
    />
  );
};

export default React.memo(CrunchbaseChart);
