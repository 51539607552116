import styled from 'styled-components';

const SortWrapperStyled = styled.div`
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  border-radius: 2px;
  margin: 0px 13px 5px 15px;
  .watch-list__sorting {
    min-width: 250px;
  }
`;
export default SortWrapperStyled;
