import { RefObject, useEffect } from 'react';
import { SortByRule } from '@optx/models/table/sorting';

/**
 * Custom hook for setting numbers for column sorting priority
 *
 * @param tableWrapperRef A reference to the HTMLDivElement containing the table.
 * @param sorting An array of SortByRule objects representing the sorting rules.
 * @param isMultiSort A boolean indicating whether multi-sorting is enabled.
 * @param isLoading A boolean indicating whether the table is in a loading state.
 */

const useMultiSortNumbers = (
  tableWrapperRef: RefObject<HTMLDivElement>,
  sorting: SortByRule<any>[],
  isMultiSort: boolean,
  isLoading?: boolean
) => {
  useEffect(() => {
    if (tableWrapperRef.current) {
      const header = tableWrapperRef.current.querySelector('.ant-table-header');

      if (header) {
        const allCells = header.querySelectorAll('th');
        allCells.forEach(cell => {
          // eslint-disable-next-line no-param-reassign
          delete cell.dataset.order;
        });

        sorting.forEach((sortRule, index) => {
          const sortingCell = header.querySelector(`th.${sortRule.id}`) as HTMLElement;

          if (sortingCell && isMultiSort) {
            sortingCell.dataset.order = (index + 1).toString();
          }

          return null;
        });
      }
    }
  }, [isMultiSort, sorting, tableWrapperRef, isLoading]);
};

export default useMultiSortNumbers;
