import { createAction } from '@reduxjs/toolkit';
import * as types from './types';
import { prepareFetchSuccess } from '../../feature/saved-search/actions';

// fetch contacts saved searches actions
export const fetchSuggestedSearches = createAction(types.FETCH_SUGGESTED_SEARCHES);
export const fetchSuggestedSearchesSuccess = createAction(
  types.FETCH_SUGGESTED_SEARCHES_SUCCESS,
  prepareFetchSuccess
);
export const fetchSuggestedSearchesFail = createAction<string>(types.FETCH_SUGGESTED_SEARCHES_FAIL);
