import React from 'react';
// models
import { CompanyProfile } from '@optx/models/Company';
import { Cell } from '@models/table/Cell';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
// hooks
import useReferrerProfileLink from '@hooks/useReferrerLink';
// components
import { InlineEdit } from '@optx/shared/view/molecules/edit/InlineEdit';
import CustomTooltip from './CustomTooltip';
import { Styled } from './TableCellName.styled';

const TableCellName: React.FC<Cell<CompanyProfile>> = ({ value, record }) => {
  const { referrerUrl, referrer } = useReferrerProfileLink(record.company_id);

  return (
    <InlineEdit
      action={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Styled.LinkOpenInNewTab to={referrerUrl} target="_blank">
          <Styled.OpenInNewTabIcon iconName="open-in-new" />
        </Styled.LinkOpenInNewTab>
      }
      hideActionOnChildrenHover
    >
      <CustomTooltip company={record} pathName={referrer}>
        <Styled.CompanyLink to={referrerUrl}>
          {value || <>{DEFAULT_CELL_VALUE}</>}
        </Styled.CompanyLink>
      </CustomTooltip>
    </InlineEdit>
  );
};

export default React.memo(TableCellName);
