import React from 'react';
import { useSelector } from 'react-redux';
// redux
import { isLoading } from '../selectors';
// components
import FixedLoader from '../../../common/loader';

const PageLoader = () => {
  const loading = useSelector(isLoading);

  return <FixedLoader loading={loading} />;
};

export default React.memo(PageLoader);
