import { useInitialFetch } from '@optx/common/hooks/fetch';
import { useSelector, useDispatch } from 'react-redux';
import { selectors as companyEditSelectors } from '@components/feature/company-individual-edit/state';
import { actions as editActions } from '../state/companyIndividualEdit';

export const useInitialFetchEditOptions = () => {
  const dispatch = useDispatch();

  const shouldInitialFetchEditOptions = useSelector(
    companyEditSelectors.companyIndividualEdit.shouldInitialFetch
  );
  const fetchCompanyIndividualEditOptionsSelectOptions = () =>
    dispatch(editActions.fetchSelectOptions(''));
  useInitialFetch(shouldInitialFetchEditOptions, fetchCompanyIndividualEditOptionsSelectOptions);
};
