import styled from 'styled-components';

const SelectContact = styled.div`
  .change-contact-cell {
    width: 100%;
    color: #1890ff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .anticon svg {
      width: 16px;
      height: 16px;
    }
  }
  button:disabled,
  button[disabled] {
    color: gray;
    opacity: 0.5;
  }
  .ant-select {
    vertical-align: top;
  }
  .ant-select-disabled.ant-select-multiple {
    .ant-select-selector {
      background: initial;
      opacity: 0.5;
    }
    .ant-select-arrow .anticon svg {
      color: gray;
      opacity: 0.5;
    }
  }
  .ant-select-selector {
    line-height: 30px;
    .ant-select-selection-search {
      bottom: 0px;
    }
  }
  .ant-select-selection-overflow {
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }
  .ant-select-multiple:not(.ant-select-open) {
    .ant-select-selection-overflow-item {
      z-index: 1;
      display: inline-block;
      &:not(:first-child):not(.ant-select-selection-placeholder):not(
          .ant-select-selection-search
        ):not(.ant-select-selection-overflow-item-suffix):before {
        content: ', ';
      }
    }
  }
  .ant-select-sm .ant-select-selector {
    line-height: 22px;
  }
`;

export const Styled = { SelectContact };
