import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest } from 'redux-saga/effects';
// services
import NotificationService from '@services/NotificationService';
import { UserService } from '@services/api';
// models
import { SuccessErrorCallback } from '@optx/models/callback';
// redux
import * as actions from './actions';

export function* resetPasswordSaga(
  action: PayloadAction<{ currentPassword: string; newPassword: string }, any, SuccessErrorCallback>
) {
  const { currentPassword, newPassword } = action.payload;
  const callback = action.meta;

  try {
    yield call(UserService.resetPassword, currentPassword, newPassword);
    callback && callback(true);
    NotificationService.success('Password succesfully updated');
  } catch (error: any) {
    const errorMessage = 'Failed to update password!!!';
    callback && callback(null, errorMessage);
  }
}

export default function* passwordSaga() {
  yield takeLatest(actions.resetPassword, resetPasswordSaga);
}
