/**
 * Asynchronously checks if the provided image URL has a transparent background or contains white pixels.
 *
 * @param imageUrl - The URL of the image to be checked.
 * @returns A promise that resolves to one of the following:
 *   - 'white-transparent' if the image has white pixels and a transparent background.
 *   - 'transparent' if the image has a transparent background but no white pixels.
 *   - false if the image does not have a transparent background.
 *   - 'error' if an error occurs during the process.
 */
export async function getBackgroundType(imageUrl: string) {
  const whiteColorInterval = 230;

  try {
    const result = await new Promise((resolve: (value: string) => void, reject) => {
      const image = new Image();
      image.crossOrigin = 'Anonymous';

      image.onload = function () {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = image.width;
        canvas.height = image.height;

        context?.drawImage(image, 0, 0);

        const imageData = context?.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData?.data;

        if (data?.length) {
          let isTransparent = false;
          let totalWhitePX = 0;
          let hasWhiteTransparent = false;

          // data.length / 4 === total number of PX
          // data.length / 20 === 20% of the total number of PX
          const threshold = data.length / 20;

          for (let i = 0; i < data.length; i += 4) {
            const R = data[i];
            const G = data[i + 1];
            const B = data[i + 2];
            const alpha = data[i + 3];

            if (alpha < 255) {
              isTransparent = true; // Image has a transparent background
            }

            if (R > whiteColorInterval && G > whiteColorInterval && B > whiteColorInterval) {
              totalWhitePX++; // count white/grey pixels

              if (isTransparent && totalWhitePX > threshold) {
                hasWhiteTransparent = true;
                break; // Early exit if condition is met
              }
            }
          }

          if (hasWhiteTransparent) {
            // Image has a transparent background and white pixels
            resolve('white-transparent');

            return;
          } else if (isTransparent) {
            // Image has a transparent background
            resolve('transparent');

            return;
          }
        }

        // Image does not have a transparent background
        resolve('opaque');
      };

      image.onerror = function () {
        reject(new Error('Failed to load image'));
      };

      image.src = imageUrl;
    });

    return result;
  } catch (error) {
    console.error(error);

    return 'error';
  }
}
