import { pick } from 'lodash';

/**
 * @typedef PassThroughFunction
 * @param {Object} props main component props.
 * @param {String} node node name inside the main component.
 * @param {Object} instance reference to main component instance (this - usually the class component instance).
 * @returns {Object} main component or primitive component props.
 */

export interface PassTroughFunction<TProps, TNode, TInstance = any> {
  (prop: TProps, nodeName: TNode, instance?: TInstance): object;
}

export interface PassThroughProps<TProps = any, TNodeKey = string> {
  passThrow?: PassTroughFunction<TProps, TNodeKey>;
}

/**
 * Get pass through function.
 * @param {Array|Function} option pass through option.
 * It can be an array to filter a single component props, and receive only the props declared in option.
 * Also it can be a function for more complex components built with multiple smaller ones and filter props
 * for each one of them based on node name.
 * This could be used to inject props not available in some sub-components and  make
 * something(ex: render in a specific color...) based on their values.
 * It can also be used to inject new props for default components.
 * @returns {PassThroughFunction} pass through function
 */
export const getPassThrough = <TProps extends object = {}, TNode = string, TInstance = any>(
  option: Array<string> | PassTroughFunction<TProps, TNode> = []
) => {
  return (props: TProps, node: TNode, instance?: TInstance) => {
    return Array.isArray(option) ? pick(props, option) : option(props, node, instance);
  };
};

export default getPassThrough;
