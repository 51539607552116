import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
// redux
import { selectors } from '@features/checklist-research-insights/state';
import { selectors as userSelectors } from '@redux/user/information';
// components
import StatsLabel from './components/StatsLabel';
import * as Styled from './StatsCards.styled';

const OptxScoreConfidence = () => {
  const scoreData = useSelector(selectors.cards.getScore);
  const defaultOptxScore = useSelector(userSelectors.getDefaultScore);
  const loading = useSelector(selectors.search.getLoading);

  return (
    <Styled.StatsCardsContainer
      loading={loading}
      title={`${defaultOptxScore === 'il' ? 'IL OPTX' : 'OPTX'} Score Confidence`}
    >
      <Row>
        {Object.keys(scoreData!).map((item: string, index: number) => {
          let firstValue = null;
          let lastValue = null;

          if (scoreData![item] !== null) {
            firstValue = scoreData![item]?.split('|')[0];
            lastValue = scoreData![item]?.split('|')[1];
          }

          if (firstValue === null && lastValue === null) {
            return (
              <Col span={12} key={index}>
                <StatsLabel
                  value={['0', undefined]}
                  label={item.toUpperCase()}
                  isSmall={index !== 0 && index !== 1}
                />
              </Col>
            );
          }

          return (
            <Col span={12} key={index}>
              <StatsLabel
                value={[firstValue, lastValue]}
                label={item.toUpperCase()}
                isSmall={index !== 0 && index !== 1}
              />
            </Col>
          );
        })}
      </Row>
    </Styled.StatsCardsContainer>
  );
};

export default OptxScoreConfidence;
