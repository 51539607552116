import { call, put, select } from 'redux-saga/effects';
// models
import { ListsSearchData } from '@optx/models/lists/search';
// services
import { SavedSearchesService } from '@services/api';
// redux
import { FilterSource, PreselectedFilter } from '@optx/models/filters';
import { Dictionary } from 'lodash';
import { AxiosResponse } from 'axios';
import * as actions from './actions';
import { selectors as filterSourcesSelectors } from '../filters';
import { selectors as searchSelectors } from '../search/search';

// search
export function* searchSavedSearches(data: ListsSearchData) {
  const filterSources: FilterSource[] = yield select(filterSourcesSelectors.getFilterSources);
  const clearedFilters: Dictionary<PreselectedFilter> = yield select(
    searchSelectors.getClearedFilter
  );
  const res: AxiosResponse<any> = yield call(SavedSearchesService.getContactSearches, data);
  yield put(
    actions.fetchContactSavedSearchesSuccess(
      res.data.lists,
      filterSources,
      clearedFilters,
      res.data.total_list_count,
      data.query,
      data.pagination
    )
  );
}
