import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useField, useFormikContext } from 'formik';
import { DatePicker } from 'formik-antd';
import moment, { Moment } from 'moment';
// models
import { RangeFilter, PreselectedFilter } from '@optx/models/filters';
import { SelectOption } from '@optx/models/Option';
// constants
import globalConfig from '@constants/config';
// components
import { Dictionary } from '@reduxjs/toolkit';
import SearchFilterCard from './SearchFilterCard';
import { FiltersContext } from '../FiltersContext';

interface FilterDateRangeProps {
  filter: RangeFilter<null>;
}

const FilterDateRange: React.FC<FilterDateRangeProps> = ({ filter }) => {
  const [field] = useField<Array<SelectOption>>(filter.column);
  const { onManualFilterChange } = useContext(FiltersContext);
  const [startDate, setStartDate] = useState(new Map<string, Moment | null>());
  const [endDate, setEndDate] = useState(new Map<string, Moment | null>());
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const formik = useFormikContext<Dictionary<PreselectedFilter>>();

  useEffect(() => {
    if (field.value[0].value === '') {
      setStartDate(prevState => prevState.set(`${filter.column}${window.location.pathname}`, null));
    } else {
      setStartDate(prevState =>
        prevState.set(`${filter.column}${window.location.pathname}`, moment(field.value[0].value))
      );
    }

    if (field.value[1].value === '') {
      setEndDate(prevState => prevState.set(`${filter.column}${window.location.pathname}`, null));
    } else {
      setEndDate(prevState =>
        prevState.set(`${filter.column}${window.location.pathname}`, moment(field.value[1].value))
      );
    }
  }, [field, filter.column]);

  useEffect(() => {
    if (filter.active_for?.length) {
      const usesActiveFor = filter.active_for.some((item: string) => {
        return formik.values[item] !== '';
      });

      if (usesActiveFor && isDisabled) {
        setIsDisabled(false);

        // @ts-ignore
        formik.setTouched({ [field.name]: undefined });
      } else if (!usesActiveFor && !isDisabled) {
        setIsDisabled(true);

        formik.setFieldValue(field.name, [
          { label: 'Date Start', value: '' },
          { label: 'Date End', value: '' },
        ]);

        // @ts-ignore
        formik.setTouched({ [field.name]: [{ value: true }, { value: true }] });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, formik.values]);

  const onChange = (value: Moment | null, label: string) => {
    // Call on change on the next tick.
    setTimeout(() => {
      onManualFilterChange && onManualFilterChange(filter.column);
    }, 0);

    if (label.toLocaleLowerCase().includes('start') && value) {
      setStartDate(prevState =>
        prevState.set(`${filter.column}${window.location.pathname}`, value)
      );
    } else if (label.toLocaleLowerCase().includes('end') && value) {
      setEndDate(prevState => prevState.set(`${filter.column}${window.location.pathname}`, value));
    }
  };

  const disabledDate = (current: Moment, { label }: SelectOption<string>) => {
    const dateStart = startDate.get(`${filter.column}${window.location.pathname}`);
    const dateEnd = endDate.get(`${filter.column}${window.location.pathname}`);

    if (label.toLocaleLowerCase().includes('start') && dateEnd) {
      return current.isAfter(dateEnd, 'day');
    }

    if (label.toLocaleLowerCase().includes('end') && dateStart) {
      return current.isBefore(dateStart, 'day');
    }

    return false;
  };

  return (
    <SearchFilterCard label={filter.label}>
      <Row gutter={8}>
        {field.value.map((option, index) => (
          <Col key={index} span={12}>
            <DatePicker
              format={globalConfig.short_date.DATE_FORMAT}
              placeholder={option.label}
              picker="date"
              name={`${filter.column}[${index}].value`}
              onChange={value => onChange(value, option.label)}
              disabledDate={date => disabledDate(date, option)}
              disabled={isDisabled}
            />
          </Col>
        ))}
      </Row>
    </SearchFilterCard>
  );
};

export default React.memo(FilterDateRange);
