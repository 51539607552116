import React, { useEffect, useState } from 'react';
import { Typography, Dropdown, Timeline, Collapse } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
// models
import CompanyTouch from '@optx/models/company/CompanyTouch';
import Company from '@optx/models/Company';
// utils
import { handleMenuVisibleChange } from '@utils/menu';
import replaceEmailTouchNotes from '@optx/utils/replaceEmailTouchNotes';
// components
import DangerousInnerHTML from '@optx/components/common/DangerousInnerHTML';
import TimelineIntervalLabel from './TimelineIntervalLabel';
import ExpandIcon from './ExpandIcon';
import TouchHeader from './TouchHeader';
import TouchMenu from './TouchMenu';

interface TimelineTouchItemProps {
  touch: CompanyTouch;
  touchesCount: number;
  index: number;
  timelineInterval: string;
  company: Company;
  colorInterval: string;
  setActiveKey: (key: string | string[]) => void;
  activeKey: string | string[];
  hasTouchAccess: boolean;
  isExtension?: boolean;
  className?: string;
  renderCount?: () => React.ReactNode;
}

const TimelineTouchItem: React.FC<TimelineTouchItemProps> = ({
  touch,
  touchesCount,
  index,
  timelineInterval,
  company,
  colorInterval,
  setActiveKey,
  activeKey,
  hasTouchAccess,
  isExtension,
  className,
  renderCount,
}) => {
  const [dropdownVisible, setDropdownVisibility] = useState<boolean>(false);
  const [scheduled, setScheduled] = useState<boolean>(false);

  const onChange = (key: string | string[]) => {
    setActiveKey(key);
  };

  const onVisibleChange = (visible: boolean) => {
    handleMenuVisibleChange(visible);
    setDropdownVisibility(visible);
  };

  useEffect(() => {
    if (~(className as string).search('future')) {
      setScheduled(true);
    }
  }, [className]);

  return (
    <Timeline.Item
      key={touch.touchid}
      className={`timeline-item--touch ant-timeline-item-left ${className}`}
      label={
        !index && (
          <TimelineIntervalLabel
            renderTitle={() => (
              <Typography.Text style={{ color: colorInterval }}>{timelineInterval}</Typography.Text>
            )}
            count={touchesCount}
            renderCount={renderCount}
          />
        )
      }
    >
      <Collapse
        activeKey={activeKey}
        onChange={onChange}
        expandIconPosition="right"
        accordion
        bordered={false}
        expandIcon={ExpandIcon}
      >
        <Collapse.Panel
          className="collapse-item--touch"
          header={<TouchHeader touch={touch} scheduled={scheduled} />}
          key={touch.touchid}
        >
          <DangerousInnerHTML
            type="p"
            innerHTML={replaceEmailTouchNotes(touch.notes)}
            className="touch-item__description"
          />
        </Collapse.Panel>
      </Collapse>
      {hasTouchAccess && (
        <Dropdown
          overlay={
            <TouchMenu
              touch={touch}
              company={company}
              handleVisibilityChange={setDropdownVisibility}
            />
          }
          trigger={['click']}
          getPopupContainer={(trigger: HTMLElement) => trigger as HTMLElement}
          placement="bottomRight"
          className={activeKey === touch.touchid?.toString() ? 'menu-active' : ''}
          onVisibleChange={onVisibleChange}
          visible={dropdownVisible}
        >
          <EllipsisOutlined />
        </Dropdown>
      )}
    </Timeline.Item>
  );
};

export default TimelineTouchItem;
