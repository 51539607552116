import { call, takeLatest, select } from 'redux-saga/effects';
import { ChartData, ChartSuccess } from '@optx/models/charts/data';
import { PayloadAction } from '@reduxjs/toolkit';
import { ScoreGrowth } from '@optx/models/Company';
import { selectors as userSelectors } from '@redux/user/information';
import { AxiosResponse } from 'axios';
import * as actions from './actions';
import { CompanyService } from '../../../services/api';
import NotificationService from '../../../services/NotificationService';

export function* fetchCompanyScoreGrowthSaga(
  action: PayloadAction<ScoreGrowth, any, ChartSuccess>
) {
  const { meta: callback } = action;

  const defaultOptxScore: string = yield select(userSelectors.getDefaultScore);

  try {
    const res: AxiosResponse<ChartData> = yield call(
      CompanyService.getScoreGrowth,
      action.payload,
      defaultOptxScore
    );

    if (res.data) {
      callback && callback(res.data);
    } else {
      const errorMessage = 'Failed to fetch Score Growth!';
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = 'Failed to fetch Score Growth!';
    NotificationService.error(errorMessage);
  }
}

export default function* companyScoreGrowthSaga() {
  yield takeLatest(actions.fetchScoreGrowth, fetchCompanyScoreGrowthSaga);
}
