// constants
import { featureName } from '../../featureName';
// utils
import { createFeatureActionType } from '@optx/utils/feature';
// redux
import * as company from './company';
import * as touch from './touch';
import * as insights from './insights';
import * as filters from './filters';
import * as activity from './activity';

const init = createFeatureActionType(featureName, 'INIT');

export const types = {
  init,
  company,
  touch,
  insights,
  filters,
  activity,
};
