import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// models
import { FilterTag } from '@optx/models/tags';
// components
import { FilterTagsOverflow } from '@optx/features/tags/overflow-tags';
// redux
import { actions } from '../../state';
import { actions as modalActions } from '@redux/ui/modals/gridModals';

export const FilterTags: React.FC<{ options: FilterTag[] }> = ({ options }) => {
  const dispatch = useDispatch();

  const toggleFiltersModal = useCallback(
    () => dispatch(modalActions.toggleModal({ gridKey: 'outreach' })),
    [dispatch]
  );

  const handleFilterRemove = (tag: FilterTag) => () => {
    dispatch(actions.filters.resetFilter(tag));
  };

  return (
    <FilterTagsOverflow
      options={options}
      onClick={toggleFiltersModal}
      onClose={handleFilterRemove}
      multipleValue
    />
  );
};

export default FilterTags;
