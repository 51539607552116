import React from 'react';
import { Tag } from 'antd';
import { TagProps } from 'antd/lib/tag';
import { useDispatch, useSelector } from 'react-redux';
// constants
import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';
// utils
import { searchInNewTab } from '@optx/utils/search';
// redux
import { actions as searchActions } from '@features/grid/search';
import { selectors as filterSourcesSelectors } from '@redux/company/filters';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';

const filterIsAiMlSelected = [
  { label: 'Yes', value: '1', checked: true },
  { label: 'No', value: '0', checked: false },
  { label: 'Unknown', value: 'blank', checked: false },
];

interface IsAiMlTagProps extends TagProps {
  isAiMl: boolean | null | string;
  newTab?: boolean;
}

const IsAIMlTag: React.FC<IsAiMlTagProps> = ({ isAiMl, newTab, onClick, ...restProps }) => {
  const dispatch = useDispatch();
  const clearedFilter = useSelector(filterSelectors.getClearedFilter('advancedSearch'));
  const sources = useSelector(filterSourcesSelectors.getFiltersMap);
  const { advancedSearchPathname } = useIsAnalyst();

  if (!isAiMl || isAiMl === DEFAULT_CELL_VALUE) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (newTab) {
      searchInNewTab(
        clearedFilter,
        sources,
        'is_ai_ml',
        filterIsAiMlSelected,
        {},
        advancedSearchPathname
      );
    } else {
      dispatch(
        searchActions.loadCompaniesByFilter('is_ai_ml', {
          gridKey: 'advancedSearch',
          data: filterIsAiMlSelected,
        })
      );

      onClick && onClick(event);
    }
  };

  return (
    <Tag className="special-tag show-child" onClick={handleClick} {...restProps}>
      AI / ML
    </Tag>
  );
};

export default IsAIMlTag;
