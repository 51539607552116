import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// models
import { FilterTag, SpecialFilterTag } from '@optx/models/tags';
// redux
import {
  actions as savedContactSearchesActions,
  selectors as savedContactSelectors,
} from '@redux/contacts/saved-searches';
import { actions as listsActions, selectors as listsSelectors } from '@redux/favorite-lists/lists';
import {
  actions as listsSourceScrubAction,
  selectors as listsSourceScrubSelectors,
} from '@redux/user/source-scrub-lists';
import {
  actions as savedSearchesActions,
  selectors as savedSearchesSelectors,
} from '@redux/company/saved-searches';
import { selectors as notesSearchSelectors } from '@redux/company/notes/search';
import { actions as filtersModalActions } from '@redux/ui/modals/gridModals';
import { selectors as listDetailsSelectors } from '@optx/redux/lists/details';
// components
import { FilterTagsOverflow } from '@optx/features/tags/overflow-tags';
import { generateUniqueKey } from '@optx/utils/uuid';
// hooks
import { useFetchNotes } from '../Notes/hooks';

interface FilterTagsProps {
  listType?:
    | 'Favorite Lists'
    | 'Source Scrub'
    | 'Save Company Search'
    | 'Saved Contact Search'
    | 'My Global Notes';
}

const FilterTags: React.FC<FilterTagsProps> = ({ listType }) => {
  const dispatch = useDispatch();
  const fetchNotes = useFetchNotes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const arrayOfTags: FilterTag[] = [];
  const favoriteListTag = useSelector(listsSelectors.getQuery);
  const sourceScrubListTag = useSelector(listsSourceScrubSelectors.getQuery);
  const savedContactTag = useSelector(savedContactSelectors.getQuery);
  const savedCompanyTag = useSelector(savedSearchesSelectors.getQuery);
  const myGlobalNotesTag = useSelector(notesSearchSelectors.getSearchQuery);

  const isSourceScrubList = useSelector(listDetailsSelectors.isSourceScrubRoute);
  const toggleFiltersModal = useCallback(
    () =>
      dispatch(
        filtersModalActions.toggleModal({ gridKey: isSourceScrubList ? 'sslists' : 'watchlists' })
      ),
    [dispatch, isSourceScrubList]
  );

  const handleFilterRemove = (tag: FilterTag) => () => {
    // Handle removal of search keyword special tag.

    if ((tag.filter as SpecialFilterTag) === 'keyword') {
      handleFetchLists();
    }

    // fix for hiding more filters tag, when removing the last tag
    // that prevents all from being displayed
  };

  const handleFetchLists = () => {
    if (listType === 'Favorite Lists') {
      return dispatch(listsActions.fetchFavoriteLists({ query: '', showEmptyLists: false }));
    }

    if (listType === 'Source Scrub') {
      return dispatch(listsSourceScrubAction.searchSourceScrubLists({ query: '' }));
    }

    if (listType === 'Save Company Search') {
      return dispatch(savedSearchesActions.fetchSavedSearches(''));
    }

    if (listType === 'Saved Contact Search') {
      return dispatch(savedContactSearchesActions.fetchContactSavedSearches(''));
    }

    if (listType === 'My Global Notes') {
      return fetchNotes({
        pageNumber: 1,
        pageSize: 30,
        query: '',
      });
    }

    return null;
  };

  const handleListTags = useCallback(() => {
    if (listType === 'Favorite Lists') {
      return favoriteListTag || null;
    }

    if (listType === 'Source Scrub') {
      return sourceScrubListTag || null;
    }

    if (listType === 'Save Company Search') {
      return savedCompanyTag || null;
    }

    if (listType === 'Saved Contact Search') {
      return savedContactTag || null;
    }

    if (listType === 'My Global Notes') {
      return myGlobalNotesTag.query || null;
    }

    return null;
  }, [
    favoriteListTag,
    listType,
    myGlobalNotesTag,
    savedCompanyTag,
    savedContactTag,
    sourceScrubListTag,
  ]);

  const handleTags = useCallback(() => {
    arrayOfTags.unshift({
      filter: 'keyword' as SpecialFilterTag,
      uniqueKey: generateUniqueKey(),
      label: 'Keyword',
      optionId: listType,
      values: [
        {
          value: handleListTags(),
        },
      ],
    });
  }, [arrayOfTags, handleListTags, listType]);

  if (handleListTags() !== null && listType) {
    if (!arrayOfTags.find(item => item.filter !== 'Keyword')) {
      handleTags();
    }
  }

  return (
    <FilterTagsOverflow
      options={arrayOfTags}
      onClick={toggleFiltersModal}
      onClose={handleFilterRemove}
      multipleValue
    />
  );
};

export default FilterTags;
