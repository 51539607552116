import React from 'react';
import { TableCellSearches } from '@optx/models/table/Cell';

const TableCellColumnsConfiguration: React.FC<TableCellSearches> = ({ row }) => {
  const { columns, pinned_columns: pinnedColumns } = row;

  return <>{columns !== null || pinnedColumns !== null ? 'Yes' : 'No'}</>;
};

export default TableCellColumnsConfiguration;
