import { createSelector } from 'reselect';
import { AppState } from '@optx/redux/interfaces';

const selectDocumentUploadTouchModal = (state: AppState) =>
  state.ui.modals.documentUploadTouchModal;

export const isOpen = createSelector(selectDocumentUploadTouchModal, state => state.open);

export const fileList = createSelector(selectDocumentUploadTouchModal, state => state.files);

export const encodedFileList = createSelector(
  selectDocumentUploadTouchModal,
  state => state.encodedFiles
);

export const getUploading = createSelector(
  selectDocumentUploadTouchModal,
  state => state.uploading
);
