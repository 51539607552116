import { createSelector } from 'reselect';
import { AppState } from '@optx/redux/interfaces';

const companyEquityTouchState = (state: AppState) => state.companyEquityTouch;

export const isLoading = createSelector(companyEquityTouchState, state => state.loading);
export const isFetched = createSelector(companyEquityTouchState, state => !!state.fetchedAt);

export const getData = createSelector(companyEquityTouchState, state => state.data);

export const getFormValues = createSelector(companyEquityTouchState, state => state.formValues);

export const getSelectedContacts = createSelector(
  companyEquityTouchState,
  state => state.formValues.contacts
);

export const getTemporaryFormValues = createSelector(
  companyEquityTouchState,
  state => state.temporaryFormValues
);
