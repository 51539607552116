import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// models
import { FilterTag, SpecialFilterTag } from '@optx/models/tags';
// redux
import { actions as searchActions } from '@features/grid/search';
import { selectors as filterSelectors } from '@features/grid/filter';
import { actions as filtersModalActions } from '@redux/ui/modals/gridModals';
// components
import { FilterTagsOverflow } from '@optx/features/tags/overflow-tags';

const FilterTags = () => {
  const dispatch = useDispatch();
  const options = useSelector(filterSelectors.getTags('advancedSearch'));
  const toggleFiltersModal = useCallback(
    () => dispatch(filtersModalActions.toggleModal({ gridKey: 'advancedSearch' })),
    [dispatch]
  );

  const handleFilterRemove = (tag: FilterTag) => () => {
    // Handle removal of search keyword special tag.
    if ((tag.filter as SpecialFilterTag) === 'keyword') {
      dispatch(
        searchActions.searchCompanies({
          gridKey: 'advancedSearch',
          data: { searchKey: '', shouldResetPageNumber: true },
        })
      );

      return;
    }

    dispatch(
      searchActions.resetFilter({
        gridKey: 'advancedSearch',
        data: { selectedTag: tag, filterTags: filteredOptions },
      })
    );
  };

  const filteredOptions = useMemo(
    () => options.filter(item => !item.isDefault && item.values?.length > 0),
    [options]
  );

  return (
    <FilterTagsOverflow
      options={filteredOptions}
      onClick={toggleFiltersModal}
      onClose={handleFilterRemove}
      multipleValue
    />
  );
};

export default FilterTags;
