// Initial Fetch favorite lists
export const INITIAL_FETCH_FAVORITE_LISTS_POPUP =
  'favorite-lists/listspopup/INITIAL_FETCH_FAVORITE_LISTS_POPUP';
export const INITIAL_FETCH_FAVORITE_LISTS_POPUP_SUCCESS =
  'favorite-lists/listspopup/INITIAL_FETCH_FAVORITE_LISTS_POPUP_SUCCESS';

// Fetch favorite lists
export const FETCH_FAVORITE_LISTS_POPUP = 'favorite-lists/listspopup/FETCH_FAVORITE_LISTS_POPUP';
export const FETCH_FAVORITE_LISTS_POPUP_SUCCESS =
  'favorite-lists/listspopup/FETCH_FAVORITE_LISTS_POPUP_SUCCESS';
export const FETCH_FAVORITE_LISTS_FAIL =
  'favorite-lists/listspopup/FETCH_FAVORITE_LISTS_POPUP_FAIL';

// Create favorite list
export const CREATE_FAVORITE_LIST = 'favorite-lists/listspopup/CREATE_FAVORITE_LIST';
export const CREATE_FAVORITE_LIST_SUCCESS =
  'favorite-lists/listspopup/CREATE_FAVORITE_LIST_SUCCESS';
export const CREATE_FAVORITE_LIST_FAIL = 'favorite-lists/listspopup/CREATE_FAVORITE_LIST_FAIL';

// sort favorite list
export const SORT_FAVORITE_LISTS = 'favorite-list/listspopup/SORT_FAVORITE_LISTS_POPUP';

// Delete company from list
export const DELETE_COMPANY_FROM_LIST = 'favorite-lists/listspopup/DELETE_COMPANY_FROM_LIST_POPUP';
export const DELETE_COMPANY_FROM_LIST_SUCCESS =
  'favorite-lists/listspopup/DELETE_COMPANY_FROM_LIST_POPUP_SUCCESS';
export const DELETE_COMPANY_FROM_LIST_FAIL =
  'favorite-lists/listspopup/DELETE_COMPANY_FROM_LIST_POPUP_FAIL';

// Add company in list
export const ADD_COMPANY_TO_LIST = 'favorite-lists/listspopup/ADD_COMPANY_TO_LIST_LISTS_POPUP';
export const ADD_COMPANY_TO_LIST_SUCCESS =
  'favorite-lists/listspopup/ADD_COMPANY_TO_LIST_LISTS_POPUP_SUCCESS';
export const ADD_COMPANY_TO_LIST_FAIL =
  'favorite-lists/listspopup/ADD_COMPANY_TO_LIST_LISTS_POPUP_FAIL';
export const ADD_COMPANY_TO_LIST_BULK_SUCCESS =
  'favorite-lists/listspopup/ADD_COMPANY_TO_LIST_LISTS_POPUP_BULK_SUCCESS';

// update
export const resetFetchedAt = 'favorite-lists/listspopup/RESET_FETCHED_AT_LISTS_POPUP';
