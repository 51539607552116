import { createAction } from '@reduxjs/toolkit';
import { PageInfo } from '@optx/models/table/Pagination';
import {
  SaveView,
  ModifyView,
  ViewOption,
  ViewSuccess,
  DeleteCompanySearch,
  SearchSave,
} from '@optx/models/search';
import { CustomUIViewIds } from '@optx/models/user';
import { SortByRule } from '@optx/models/table/sorting';
import * as types from './types';
import {
  prepareFetchSuccess,
  prepareSaveSearch,
  prepareSaveSearchSuccess,
  renameSearchPrepare,
} from '../../feature/saved-search/actions';

// fetch contacts saved searches actions
export const initializeSavedSearchesAndViews = createAction(
  types.INITIALIZE_SAVED_SEARCHES_AND_VIEWS
);
export const initializeSavedSearchesAndViewsSuccess = createAction(
  types.INITIALIZE_SAVED_SEARCHES_AND_VIEWS_SUCCESS,
  prepareFetchSuccess
);

export const fetchSavedSearches = createAction<string | undefined>(types.FETCH_SAVED_SEARCHES);
export const fetchSavedSearchesSuccess = createAction(
  types.FETCH_SAVED_SEARCHES_SUCCESS,
  prepareFetchSuccess
);
export const fetchSavedSearchesFail = createAction<string>(types.FETCH_SAVED_SEARCHES_FAIL);

// save search
export const saveSearch = createAction(types.SAVE_SEARCH, prepareSaveSearch);
export const saveSearchSuccess = createAction(types.SAVE_SEARCH_SUCCESS, prepareSaveSearchSuccess);
export const saveSearchFail = createAction<string>(types.SAVE_SEARCH_FAIL);

// save view
export const saveView = createAction<SaveView>(types.SAVE_VIEW);
export const modifyView = createAction(
  types.MODIFY_VIEW,
  (formData: ModifyView, searchData: ViewOption) => ({
    payload: formData,
    meta: searchData,
  })
);
export const modifyCustomUIView = createAction(
  types.MODIFY_CUSTOM_UI_VIEW,
  (id: CustomUIViewIds, isDefault: boolean) => ({
    payload: {
      id,
      isDefault,
    },
  })
);

export const updateSavedSearches = createAction(
  types.UPDATE_SAVED_SEARCHES,
  (shareWith: Array<number>, listId: number) => ({
    payload: {
      shareWith,
      listId,
    },
  })
);

// apply view success
export const applyViewSuccess = createAction<ViewSuccess>(types.APPLY_VIEW_SUCCESS);

export const modifyViewSuccess = createAction(types.MODIFY_VIEW_SUCCESS, prepareSaveSearchSuccess);
export const modifyCustomUIViewSuccess = createAction(
  types.MODIFY_CUSTOM_UI_VIEW_SUCCESS,
  (view?: SearchSave, customUIViewId?: CustomUIViewIds, isDefault?: boolean) => ({
    payload: {
      view,
      customUIViewId,
      isDefault,
    },
  })
);

// delete search
export const deleteSearch = createAction<DeleteCompanySearch>(types.DELETE_SEARCH);
export const deleteSearchSuccess = createAction<number>(types.DELETE_SEARCH_SUCCESS);
export const deleteSearchFail = createAction<string>(types.DELETE_SEARCH_FAIL);

// rename search
export const renameSearch = createAction(types.RENAME_SEARCH, renameSearchPrepare);
export const renameSearchSuccess = createAction(types.RENAME_SEARCH_SUCCESS, renameSearchPrepare);
export const renameSearchFail = createAction<string>(types.RENAME_SEARCH_FAIL);

// pagination
export const savedCompanySearchPagination = createAction<PageInfo>(
  types.SAVED_COMPANY_SEARCH_PAGINATION
);

// sort
export const sortCompanySearchesLists = createAction<SortByRule<any>[]>(types.SORT_SAVED_SEARCHES);

export const makeFiltersDefault = createAction(
  types.MAKE_FILTERS_DEFAULT,
  (id: number | string, isDefault?: boolean) => ({ payload: id, meta: isDefault })
);
export const makeFiltersDefaultSuccess = createAction<number>(types.MAKE_FILTERS_DEFAULT_SUCCESS);

export const setLoader = createAction(types.SET_LOADER);
