import styled from 'styled-components';
import { Card, Row } from 'antd';

const GlassDoorChartWrapper = styled(Card)`
  .charts-progress {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;

    .ant-progress {
      margin-right: 10px;
      .ant-progress-text {
        color: #1890ff;
        font-weight: 600;
      }
    }
  }

  .chart-stats {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .value {
      display: block;
      font-size: 16px;
      font-weight: 600;
      color: #1890ff;
    }

    .label {
      font-size: 12px;
      font-weight: 600;
      color: #595959;
      text-transform: uppercase;
    }
  }
`;

const ChartRow = styled(Row)`
  min-height: 135px;
  position: relative;

  // styling for empty state
  &__empty {
    position: absolute;
    top: 0;
    left: 24px;
    width: calc(100% - 65px);
    height: calc(100% - 30px);
    display: flex;
    align-items: center;
    justify-content: center;

    .gray-rectangle-button {
      cursor: default;
    }
  }

  .chart-empty-labels {
    background: linear-gradient(180deg, #f0f0f0 -11.27%, rgba(196, 196, 196, 0) 100%);
    height: calc(100% - 35px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border-right: 1px solid #1890ff;
  }
`;

export const Styles = {
  GlassDoorChartWrapper,
  ChartRow,
};
