import React, { useEffect } from 'react';
import { Row } from 'antd';
import { useSelector } from 'react-redux';
// models
import { CompanyProfile } from '@models/Company';
// redux
import { selectors as fundingSelectors } from '@redux/company/funding-rounds';
// hooks
import { useInitCompanyFundingRounds } from '@hooks/init';
// components
import BarChartIntervals from '@components/feature/charts/BarChartIntervals';
import { KPI } from '../../kpi';
import FundingTable from './FundingTable';

interface FundingProps {
  companyProfile: CompanyProfile;
}

const Funding: React.FC<FundingProps> = ({ companyProfile }) => {
  const { fetchCompanyFundingRounds } = useInitCompanyFundingRounds();

  useEffect(() => {
    fetchCompanyFundingRounds(companyProfile.company_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const investmentHistory = useSelector(fundingSelectors.getInvestmentHistory);
  const fundingGraphData = useSelector(fundingSelectors.getFundingRoundsGraph);
  const isFundingGraphLoading = useSelector(fundingSelectors.isLoading);
  const roundsTotal = useSelector(fundingSelectors.getNumberOfRounds);
  const totalRaised = useSelector(fundingSelectors.getTotalRaised);
  const totalInvestors = useSelector(fundingSelectors.getTotalInvestors);
  const fundingGraphMessage = useSelector(fundingSelectors.getFundingGraphMessage);

  const extraFunding = [
    {
      label: 'Total # of rounds',
      value: roundsTotal.toString(),
    },
    {
      label: 'Total # of investors',
      value: totalInvestors ? totalInvestors.toString() : '0',
    },
    {
      label: 'Total raised',
      value: totalRaised.toString(),
      format: true,
    },
  ];

  return (
    <>
      <KPI companyProfile={companyProfile} />
      <Row gutter={[2, 16]} className="funding-row">
        <BarChartIntervals
          data={fundingGraphData}
          isLoading={isFundingGraphLoading}
          label="x_axis"
          title="Funding Rounds"
          dataKey="total_investment"
          extra={extraFunding}
          emptyText={fundingGraphMessage}
          className="profile-chart funding-chart extension-funding"
        />
        <FundingTable investmentHistory={investmentHistory} />
      </Row>
    </>
  );
};

export default Funding;
