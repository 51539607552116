import React from 'react';
import { Notification } from '@optx/models/user';
import { ColumnType } from 'antd/lib/table';
import CellNotificationOptions from './cells/Select/CellNotificationOptions';
import { CellNotifcationName } from './cells/CellNotificationName';

const columns: ColumnType<Notification>[] = [
  {
    dataIndex: 'label',
    title: 'Notification',
    sorter: false,
    render: value => <CellNotifcationName value={value} customStyle={{ marginLeft: '7px' }} />,
    width: 250,
  },
  {
    dataIndex: 'deliver',
    title: 'Deliver to',
    render: (value: string, record, index: number) => (
      <CellNotificationOptions
        value={value}
        record={record}
        typeOptions="deliver"
        rowIndex={index}
      />
    ),
    sorter: false,
    width: 55,
  },
  {
    dataIndex: 'frequency',
    title: 'Frequency',
    render: (value: string, record, index: number) => (
      <CellNotificationOptions
        value={value}
        record={record}
        typeOptions="frequency"
        rowIndex={index}
      />
    ),
    sorter: false,
    width: 38,
  },
];

export default columns;
