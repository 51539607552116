import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { reducer, saga } from './state';
import featureName from './featureName';

const useInject = () => {
  useInjectReducer({ key: featureName, reducer });
  useInjectSaga({ key: featureName, saga });
};

export default useInject;
