import { createFeatureAction } from '@utils/feature';
import featureName from '../../featureName';
import * as actionTypes from './actionTypes';
import { EditCompanyPipelineRank } from './interfaces';

export const addCompanyPipelineRank = createFeatureAction<EditCompanyPipelineRank>(
  featureName,
  actionTypes.ADD_COMPANY_PIPELINE_RANK
);

export const addCompanyPipelineRankSuccess = createFeatureAction<EditCompanyPipelineRank>(
  featureName,
  actionTypes.ADD_COMPANY_PIPELINE_RANK_SUCCESS
);

export const addCompanyPipelineRankFail = createFeatureAction<string>(
  featureName,
  actionTypes.ADD_COMPANY_PIPELINE_RANK_FAIL
);
