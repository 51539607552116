import React from 'react';
import Company from '@optx/models/Company';
import { CellFormat } from '../../../../../models/table/Cell';
import { cellIsEqual } from './memoize';
import { DEFAULT_CELL_VALUE } from '../../../../../constants/table/cells';

export const TableCellFormatMoney: React.FC<CellFormat<Company>> = ({ value, formatValue }) => (
  <>{value === DEFAULT_CELL_VALUE ? DEFAULT_CELL_VALUE : formatValue(value)}</>
);

export default React.memo(TableCellFormatMoney, cellIsEqual);
