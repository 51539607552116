import { useMemo } from 'react';
import {
  CloseOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileWordOutlined,
  FileZipOutlined,
} from '@ant-design/icons';
// models
import { UploadFile } from 'antd/lib/upload/interface';
// utils
import { bytesToMB } from '../../utils';
// constants
import { SUPPORTED_FILE_TYPES, getFileExtension } from '../../constants/fileUploadConstants';
// Styles
import { Styled } from './DocumentUploadItem.styled';

const {
  DocumentUploadItem: StyledDocumentUploadItem,
  FileIconWrapper,
  FileInfo,
  FileName,
  FileSize,
  UploadProgress,
} = Styled;

const FILE_ICONS: Record<SUPPORTED_FILE_TYPES, React.ReactNode> = {
  '.doc': <FileWordOutlined />,
  '.docs': <FileWordOutlined />,
  '.docx': <FileWordOutlined />,
  '.pdf': <FilePdfOutlined />,
  '.pps': <FilePptOutlined />,
  '.ppsx': <FilePptOutlined />,
  '.ppt': <FilePptOutlined />,
  '.pptx': <FilePptOutlined />,
  '.txt': <FileTextOutlined />,
  '.xls': <FileExcelOutlined />,
  '.xlsx': <FileExcelOutlined />,
  '.zip': <FileZipOutlined />,
};

type DocumentUploadItemProps = {
  file: UploadFile;
  actions: { remove: () => void };
};

const DocumentUploadItem: React.FC<DocumentUploadItemProps> = ({ file, actions }) => {
  const { name, status, percent, size } = file;

  const FileIcon: React.ReactNode = useMemo(
    () => FILE_ICONS[getFileExtension(name) as SUPPORTED_FILE_TYPES],
    [name]
  );

  const itemClassName =
    status === 'success' || status === 'done' ? 'upload--success' : 'upload--progress';

  return (
    <StyledDocumentUploadItem className={itemClassName}>
      <FileInfo>
        <FileIconWrapper>{FileIcon}</FileIconWrapper>
        <span>
          <FileName>{name}</FileName>
          <FileSize>{bytesToMB(size)}</FileSize>
        </span>
      </FileInfo>
      {status === 'uploading' && <UploadProgress strokeWidth={8} percent={percent} size="small" />}
      <CloseOutlined onClick={actions.remove} />
    </StyledDocumentUploadItem>
  );
};

export default DocumentUploadItem;
