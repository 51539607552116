import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { SearchTouchesPayload } from '@optx/features/company-touches/state/interfaces';
import { actions as profileActions, TOUCHES_PER_PAGE } from '@features/long-card/company-card';
import { actions as touchesActions } from '@features/company-touches';
import { actions as employeGrowthActions } from '@features/company/graphs/employee';
import { actions as scoreGrowthActions } from '@features/company/graphs/score';
import { actions as fundingRoundsActions } from '@redux/company/funding-rounds';

const useLoadCompanyData = () => {
  const dispatch = useDispatch();

  const getProfile = useCallback(
    (companyId: number) => dispatch(profileActions.fetchCompanyProfile(companyId)),
    [dispatch]
  );

  // TOUCHES
  const fetchTouchesFilters = useCallback(
    (companyId: number) => dispatch(touchesActions.filters.fetchTouchesFilters(companyId)),
    [dispatch]
  );

  const fetchTouches = useCallback(
    (payload: SearchTouchesPayload) => dispatch(touchesActions.search.fetchTouches(payload)),
    [dispatch]
  );

  const loadCompanyData = (companyId: number | undefined, companyUrl: string | null) => {
    if (companyId) {
      getProfile(companyId);
      fetchTouchesFilters(companyId);
      fetchTouches({
        companyId,
        pagination: { pageSize: TOUCHES_PER_PAGE, pageNumber: 1 },
      });

      dispatch(employeGrowthActions.fetchEmployeeGrowth(companyId));
      dispatch(
        scoreGrowthActions.fetchScoreGrowth({
          company_id: companyId,
          company_url: companyUrl,
        })
      );
      dispatch(fundingRoundsActions.getCompanyFundingRounds(companyId));
    }
  };

  return { loadCompanyData };
};

export default useLoadCompanyData;
