import React from 'react';

// models
import { BaseFilter, Filter, FilterSource, FilterGroupDataSection } from '@optx/models/filters';

// components
import SearchFilterCard from '../SearchFilterCard';
import FilterGroupCustomSection from './FilterGroupCustomSection';

interface FilterGroupCustomProps {
  source: FilterSource<Filter<any>>;
  filter: BaseFilter<FilterGroupDataSection[]>;
}

const FilterGroupCustom: React.FC<FilterGroupCustomProps> = ({ source, filter }) => {
  return (
    <SearchFilterCard label={filter.label} className="filter-form__card-group">
      {filter.data.map(section => (
        <FilterGroupCustomSection key={section.column} section={section} source={source} />
      ))}
    </SearchFilterCard>
  );
};

export default React.memo(FilterGroupCustom);
