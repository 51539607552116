import { createAction } from '@reduxjs/toolkit';
import * as types from './actionTypes';

export const fetchCognitoLogoutURL = createAction(types.FETCH_COGNITO_LOGOUT_URL);

export const fetchCognitoLogoutURLSuccess = createAction<string>(
  types.FETCH_COGNITO_LOGOUT_URL_SUCCESS
);

export const fetchCognitoLogoutURLFail = createAction<string>(types.FETCH_COGNITO_LOGOUT_URL_FAIL);
