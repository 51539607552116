import styled from 'styled-components';

const ContainerCompanyLocation = styled.div`
  .edit-field--container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 5px;
  }

  .icon-pen {
    cursor: pointer;
    visibility: hidden;
  }
  .ant-popover-open,
  &:hover {
    .icon-pen {
      visibility: visible;
      color: #1890ff;
    }
  }
`;
const LocationPopover = styled.div`
  width: 300px;
  .error-message {
    color: red;
    padding-top: 5px;
    font-size: 12px;
  }
  .location-input-wrapper {
    position: relative;
    z-index: 1;
  }
  .location-search-menu {
    position: absolute;
    left: 0;
    top: 100%;
    min-width: 100%;
    width: 100%;
    z-index: 3;
    padding: 5px 0;
  }
  .location-search-menu li {
    line-height: 20px;
    height:auto;
    margin: 0 !important;
    padding: 5px 12px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    cursor: pointer;
  }
  .location-search-menu li:hover {
    background-color: #f5f5f5;
  }
  .location-search-menu li.ant-select-item-option-selected {
    background-color: #f5f5f5;
  }

  }
`;
const LocationButtonsContainer = styled.div`
  margin: 22px 0 0 -16px;
  width: calc(100% + 32px);
  padding: 10px 10px 0 10px;
  border-top: 1px solid #d9d9d9;
  text-align: right;
  .location-change__cancel {
    margin-right: 8px;
  }
`;

export default {
  LocationPopover,
  LocationButtonsContainer,
  ContainerCompanyLocation,
};
