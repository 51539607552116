/* eslint-disable no-irregular-whitespace */
import { Button, Modal as M } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import styled from 'styled-components';

interface ModalWrapperProps extends ModalProps {
  isExtension?: boolean;
}
const Modal = styled(M)<ModalWrapperProps>`
  width: ${({ isExtension }) => (isExtension ? '610px' : '724px')} !important;
  transform: ${({ isExtension }) => (isExtension ? 'translateX(-260px)' : 'inherit')} !important;
  .ant-modal-content {
    min-height: calc(100vh - 200rem);
    overflow: visible;
  }
`;

const FormWrapper = styled.div`
  .ant-col,
  .ant-form-item-control-input {
    position: static;
  }

  .ant-form-item-with-help {
    margin-bottom: 24px;
  }

  .ant-form-item {
    width: 256px;
    position: relative;
  }

  .ant-form-item.tenure_date {
    width: 220px;
  }

  .email .ant-form-item-explain {
    display: none;
  }

  .ant-row {
    .email + span {
      visibility: hidden;
    }
    &:hover {
      .email + span {
        visibility: visible;
      }
    }

    .phone + span {
      visibility: hidden;
    }
    &:hover {
      .phone + span {
        visibility: visible;
      }
    }
  }
`;

const SwitchWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 100px;

  .ant-form-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
  }

  .ant-form-item-label {
    padding: 0 0 0 10px;
    order: 2;
  }
`;

const AddEmailButton = styled.div`
  position: absolute;
  bottom: -67px;
  left: 20px;

  .ant-btn {
    border-color: #1890ff;
    color: #1890ff;
  }
`;

const AddPhoneButton = styled.div`
  position: absolute;
  bottom: -67px;
  left: 220px;

  .ant-btn {
    border-color: #1890ff;
    color: #1890ff;
  }
`;

const ValidationMessage = styled.span.attrs(props => ({ className: 'validation-message' }))`
  position: absolute;
  width: 100%;
  left: calc(100% + 20px);
  top: 34px;
`;

const IconsWrapper = styled.div`
  .ant-col {
    padding-right: 10px;
  }

  .anticon {
    color: #262626;
  }

  .icon-salesloft {
    width: 100px;
    height: 100px;
    position: absolute;
    left: 0;
    bottom: -48px;
  }
`;

const Cancel = styled(Button)`
  margin: 0 10px 0 0;
`;

const ContactName = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Styles = {
  SwitchWrapper,
  FormWrapper,
  Modal,
  AddEmailButton,
  AddPhoneButton,
  ValidationMessage,
  IconsWrapper,
  Cancel,
  ContactName,
};

export default Styles;
