/**
 * Handles key events for dropdown component.
 * @param event - The keyboard event.
 * @param setClosePopup - A function to set the visibility of the popup.
 * @param onSave - A function to save the field type.
 * @param fieldType - The type of field.
 * @param isNoteField - A boolean indicating if the field is a note field.
 */
export const handleDropdownKeyEvent = (
  event: React.KeyboardEvent<HTMLDivElement>,
  setClosePopup: (visibility: boolean) => void,
  onSave?: (fieldType?: string) => void,
  fieldType?: string,
  isNoteField?: boolean,
  callback?: () => void | undefined
) => {
  if (isNoteField) {
    if (event.key === 'Enter' && event.ctrlKey) {
      onSave && onSave(fieldType);
      setClosePopup(true);
    }
  } else {
    if (event.key === 'Enter') {
      callback && callback();
      onSave && onSave(fieldType);
      setClosePopup(true);
    }
  }
};
