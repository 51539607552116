import { createSelector } from 'reselect';
import { selectors as loaderSelectors } from '@optx/features/request';
import { featureName } from '../../featureName';
import { SourcingOutreachCombinedState } from '../interfaces';
import * as localSearch from './search';
import * as localFilters from './filters';
import * as localSort from './sort';
import { types } from '../types';

type StateSlice = Record<typeof featureName, SourcingOutreachCombinedState>;

export const selectFeatureState = (state: StateSlice) => state[featureName];

const selectSearchState = createSelector(selectFeatureState, state => state.search);
const selectFiltersState = createSelector(selectFeatureState, state => state.filters);
const selectSortState = createSelector(selectFeatureState, state => state.sort);

// search
export const isLoadingSearch = loaderSelectors.createLoadingSelector([types.search.fetch.SELECTOR]);

const sourcingOutreachUsers = createSelector(
  selectSearchState,
  localSearch.selectSourcingOutreachUsers
);

const sourcingOutreachCards = createSelector(selectSearchState, localSearch.sourcingOutreachCards);

const sourcingOutreachTotalColumns = createSelector(
  selectSearchState,
  localSearch.sourcingOutreachTotalColumns
);

const isInitialized = createSelector(selectSearchState, localSearch.isInitialized);

const search = {
  isLoading: isLoadingSearch,
  data: sourcingOutreachUsers,
  isInitialized,
  cards: sourcingOutreachCards,
  totalColumnsData: sourcingOutreachTotalColumns,
};

// filters
const isLoadingFilters = loaderSelectors.createLoadingSelector([
  types.filters.fetchFilters.SELECTOR,
]);

const filters = {
  isLoading: isLoadingFilters,
  selectFilter: createSelector(selectFiltersState, localFilters.getFilter),
  selectDefaultFilter: createSelector(selectFiltersState, localFilters.getDefaultFilter),
  selectClearedFilter: createSelector(selectFiltersState, localFilters.getClearedFilter),
  selectNormalizedFilters: createSelector(selectFiltersState, localFilters.getNormalizedFilters),
  filterQuery: createSelector(selectFiltersState, localFilters.filterQuery),
  getFilters: createSelector(selectFiltersState, localFilters.getFilters),
  getValues: createSelector(selectFiltersState, localFilters.getValues),
  sources: createSelector(selectFiltersState, localFilters.sources),
  isCLearedFilter: createSelector(selectFiltersState, localFilters.isClearedFilter),
  getTags: createSelector(selectFiltersState, localFilters.getTags),
  loaded: createSelector(selectFiltersState, localFilters.loaded),
  searchKey: createSelector(selectFiltersState, localFilters.getSearchKey),
};

// sort
const sort = {
  sortBy: createSelector(selectSortState, localSort.selectSortBy),
  getGridColumns: createSelector(selectSortState, localSort.getGridColumns),
};

// ui
const ui = {
  isLoading: loaderSelectors.createLoadingSelector([
    types.filters.fetchFilters.SELECTOR,
    types.search.fetch.SELECTOR,
  ]),
};

export const pageLoading = createSelector(
  ui.isLoading,
  filters.isLoading,
  (isLoading, filters) => isLoading || filters
);

export const selectors = {
  search,
  filters,
  sort,
  ui,
};
