import React from 'react';
import { Menu, Popconfirm } from 'antd';

interface DownloadMenuItemProps {
  onConfirm?: (includeDescription?: boolean) => void;
}

const DownloadMenuItem: React.FC<DownloadMenuItemProps> = ({ onConfirm, ...restProps }) => {
  return (
    <Popconfirm
      title="Include the description?"
      onConfirm={() => onConfirm && onConfirm(true)}
      onCancel={() => onConfirm && onConfirm()}
      okText="Yes"
      cancelText="No"
    >
      {/* prevent dropdown from closing when clicking on this menu item */}
      <Menu.Item {...restProps} onClick={e => e.domEvent.stopPropagation()} key="3">
        Download list
      </Menu.Item>
    </Popconfirm>
  );
};

export default DownloadMenuItem;
