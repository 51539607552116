import React, { useCallback, useMemo, useRef } from 'react';
import { Modal, Skeleton, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select } from 'formik-antd';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
// utils
import { mappingRole } from '@optx/utils/auth';
// models
import { UserInfo } from '@optx/models/UserManagement';
// redux
import { selectors as modalSelectors, actions as modalActions } from '@redux/ui/modals/user-edit';
import { selectors, actions } from '@redux/user-management';
// styles
import Styles from './UserManagement.style';

const { Option } = Select;

const message = 'Field is required!';

const validationSchema = yup.object<Partial<UserInfo>>({
  display_name: yup.string().required(message),
  // user can add multiple emails, separated by comma,
  // this validates each individual email
  email_list: yup
    .array()
    // eslint-disable-next-line func-names
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }

      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(yup.string().email(({ value }) => `${value} is not a valid email`))
    .required(message),
  role_id: yup.number().typeError('Please select a role'),
});

const EditUserModal = () => {
  const formRef = useRef<FormikProps<UserInfo> | null>(null);
  const dispatch = useDispatch();
  const modalIsOpen = useSelector(modalSelectors.isOpen);
  const loadingInfo = useSelector(selectors.modalLoading);
  const editData = useSelector(selectors.getEditData);

  const submit = useCallback(
    (values: UserInfo) => {
      dispatch(actions.submitUserInfo(values));
    },
    [dispatch]
  );

  const onCancel = () => {
    if (formRef.current) {
      formRef.current.resetForm();
    }

    dispatch(modalActions.toggleUserEditModal());
  };

  const handleSubmit = (values: UserInfo) => {
    submit(values);
    dispatch(modalActions.toggleUserEditModal());
  };

  const initialValues: UserInfo = useMemo(() => {
    return {
      user_id: editData.user_info.user_id as number,
      display_name: editData.user_info.display_name,
      email_list: (editData.user_info.email_list as string[]).join(', '),
      role_id: editData.user_info.role_id as number,
    };
  }, [editData]);

  const footer = loadingInfo ? null : undefined;

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      formRef.current?.submitForm();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      innerRef={instance => {
        formRef.current = instance;
      }}
      validationSchema={validationSchema}
      component={({ submitForm }) => {
        return (
          <Modal
            visible={modalIsOpen}
            title="Edit user"
            centered
            onCancel={onCancel}
            okText="Save"
            okButtonProps={{
              onClick: submitForm,
            }}
            footer={footer}
          >
            <Skeleton loading={loadingInfo} active>
              <Styles.FormWrapper layout="vertical" onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
                <Row>
                  <Form.Item name="display_name" label="Name" required>
                    <Input name="display_name" />
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item name="email_list" label="Email" required>
                    <Input name="email_list" />
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item name="role_id" label="Role">
                    <Select name="role_id" placeholder="Please select role">
                      {editData.access_role_options.map((role, index) => (
                        <Option value={role.value} key={index}>
                          {mappingRole(role.label)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Row>
              </Styles.FormWrapper>
            </Skeleton>
          </Modal>
        );
      }}
    />
  );
};

export default EditUserModal;
