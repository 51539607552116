import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { CompanyProfile, PluginPrimaryContact } from '@optx/models/Company';
import NotificationService from '@optx/services/NotificationService';
import { CompanyService } from '@optx/services/api';
import { getErrorMessage } from '@optx/utils/api/errors';
import * as actions from './actions';
import { FetchCompanyProfileActionPayload } from './interfaces';

export function* fetchCompanyProfileSaga(action: FetchCompanyProfileActionPayload) {
  const { payload: companyId, meta: callback } = action;

  try {
    const res: AxiosResponse<false | CompanyProfile> = yield call(
      CompanyService.getProfile,
      companyId
    );

    if (res.data) {
      yield put(actions.fetchCompanyProfileSuccess(res.data));
      callback && callback(res.data as CompanyProfile);
    } else {
      const errorMessage = 'Failed to fetch company Profile!';
      yield put(actions.fetchCompanyProfileFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to fetch company Profile!');
    yield put(actions.fetchCompanyProfileFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* fetchPluginPrimaryContactSaga(
  action: PayloadAction<{
    companyId: number;
  }>
) {
  const { companyId } = action.payload;

  try {
    const res: AxiosResponse<false | PluginPrimaryContact> = yield call(
      CompanyService.getPluginPrimaryContact,
      companyId
    );

    if (res.data) {
      yield put(actions.fetchPluginPrimaryContactSuccess(res.data));
    } else {
      const errorMessage = 'Failed to fetch primary contact information!';
      yield put(actions.fetchPluginPrimaryContactFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to fetch primary contact information!');
    yield put(actions.fetchPluginPrimaryContactFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* companyProfileSaga() {
  yield takeLatest(actions.fetchCompanyProfile, fetchCompanyProfileSaga);
  yield takeLatest(actions.fetchPluginPrimaryContact, fetchPluginPrimaryContactSaga);
}
