import { SuccessErrorCallback } from '@optx/models/callback';
import { createAction } from '@reduxjs/toolkit';
import * as types from './actionTypes';

export const resetPassword = createAction(
  types.RESET_PASSWORD,
  (currentPassword: string, newPassword: string, callback: SuccessErrorCallback) => ({
    payload: {
      currentPassword,
      newPassword,
    },
    meta: callback,
  })
);

export const resetPasswordSuccess = createAction(types.RESET_PASSWORD_SUCCESS);

export const resetPasswordFail = createAction<string>(types.RESET_PASSWORD_FAIL);
