import React from 'react';
import { Col, Row } from 'antd';
// hooks
import useInjectRegionSettings from '@optx/common/hooks/inject/useInjectRegionSettings';
import useFetchRegionOptions from '@optx/common/hooks/init/useFetchRegionOptions';
import { useInjectResetPassword, useInjectProfilePicture } from '@optx/common/hooks/inject';
// components
import PrivateComponent from '@components/common/PrivateComponent';
import PageLoader from './PageLoader';
import Information from './Information';
import UserProfileTabs from './Tabs';
// styles
import { Styled } from './UserProfile.styled';

const UserProfile: React.FC = () => {
  // inject
  useInjectResetPassword();
  useInjectProfilePicture();
  useInjectRegionSettings();
  // fetch
  useFetchRegionOptions();

  return (
    <PrivateComponent accessFor={['analyst_general', 'partner_user']}>
      <Styled.UserProfileWrapper>
        <PageLoader />
        <Row
          align="top"
          gutter={[
            { xs: 24, sm: 24, lg: 24, xl: 24 },
            { xs: 24, sm: 24, lg: 24, xl: 24 },
          ]}
        >
          <Col span={24}>
            <Information />
          </Col>
          <Col span={24}>
            <UserProfileTabs />
          </Col>
        </Row>
      </Styled.UserProfileWrapper>
    </PrivateComponent>
  );
};

export default UserProfile;
