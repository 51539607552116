/**
 * Scrolls the table to the left when sorting, addressing the issue in Firefox.
 *
 * @param {React.RefObject<HTMLDivElement>} tableRef - Reference to the HTML div element containing the table.
 * @returns {void}
 */

const scrollTableOnSorting = (tableRef: React.RefObject<HTMLDivElement>) => {
  if (tableRef.current) {
    // scroll table to the left when sorting, issue for firefox
    const header = tableRef.current.querySelector('.ant-table-header');
    const body = tableRef.current.querySelector('.ant-table-body');
    if (header) header.scrollLeft = 0;
    if (body) body.scrollLeft = 0;
  }
};

export default scrollTableOnSorting;
