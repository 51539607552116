import { call, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { FavoriteListsSearchData } from '@models/lists/search';
// services
import { SavedSearchesService } from '@services/api';
import * as actions from './actions';

// search
export function* searchLists(data: FavoriteListsSearchData) {
  const res: AxiosResponse<any> = yield call(SavedSearchesService.getFavoriteLists, data);

  yield put(
    actions.fetchFavoriteListsSuccess({
      data: res.data.lists,
      total: res.data.total_list_count,
      ...data,
      showEmptyLists: data.showEmptyLists || false,
    })
  );
}
