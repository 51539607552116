import React from 'react';

import { HistogramGroup } from '@optx/models/filters';

export interface IFiltersContext {
  histograms: HistogramGroup;
  onManualFilterChange?: (filterKey: string) => void;
}

const initialValue: IFiltersContext = {
  histograms: {},
};

export const FiltersContext = React.createContext<IFiltersContext>(initialValue);
