import { AxiosResponse } from 'axios';
import { ColumnType } from 'antd/lib/table';
import { Dictionary } from 'lodash';
// services
import { AnalystService } from '../services/api';
import NotificationService from '../services/NotificationService';
// components
import analystColumns from '@features/checklist-research-insights/components/Table/columns/analystColumn';
import companyColumns from '@features/checklist-research-insights/components/Table/columns/companyColumn';

interface ExportChecklistResearchColumns {
  columns: Dictionary<any>;
}

export const exportFileCSV = (fileName: string, query: string, isCompany?: boolean) => {
  let columns;

  if (isCompany) {
    columns = companyColumns;
  } else {
    columns = analystColumns;
  }

  const gridHeaders: ExportChecklistResearchColumns = { columns: {} };
  columns.forEach((column: ColumnType<any>) => {
    const headerKey = column.dataIndex as string;
    const title = column.title;

    gridHeaders.columns[headerKey] = title as string;

    if (headerKey === 'company_url') {
      gridHeaders.columns.company_url = 'URL';
    } else if (headerKey === 'row_actions') {
      delete gridHeaders.columns.row_actions;
    }
  });

  return AnalystService.exportChecklistResearch(query, gridHeaders, !!isCompany)
    .then((response: AxiosResponse<string>) => {
      const element = document.createElement('a');

      element.setAttribute(
        'href',
        `data:application/csv;charset=utf-8, ${encodeURIComponent(response.data)}`
      );
      element.setAttribute('download', `${fileName}.csv`);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      NotificationService.success('Export CSV file success!');
    })
    .catch(() => {
      NotificationService.error('Export CSV file error');
    });
};
