import { takeLatest, put, call, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
// models
import { AxiosResponse } from 'axios';
// constants
import { BULK_EDIT_COMPANY_LIMIT_SET_AS_SOFTWARE_COMPANY } from '@optx/config/bulkConfig';
// services
import { UserService } from '@optx/services/api';
import NotificationService from '@optx/services/NotificationService';
// redux
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { actions as editFieldsActions } from '../../edit-fields';
import * as actions from './actions';
import { BulkSoftware, BulkSoftwareResponse } from './interfaces';

function* bulkSoftwareSaga(action: PayloadAction<BulkSoftware>) {
  const { selectedCompanies, isSoftware, page = 1, rationale, isAdminOrAnalyst } = action.payload;
  const companies = [...selectedCompanies];
  const selectedCompaniesCount: number = selectedCompanies.length;

  const totalPageCount = Math.ceil(
    selectedCompaniesCount / BULK_EDIT_COMPANY_LIMIT_SET_AS_SOFTWARE_COMPANY
  );
  const startCompany = (page - 1) * BULK_EDIT_COMPANY_LIMIT_SET_AS_SOFTWARE_COMPANY;
  const companiesBatch = companies.slice(
    startCompany,
    startCompany + BULK_EDIT_COMPANY_LIMIT_SET_AS_SOFTWARE_COMPANY
  );

  const getCompanyCount =
    totalPageCount === page
      ? selectedCompaniesCount
      : page * BULK_EDIT_COMPANY_LIMIT_SET_AS_SOFTWARE_COMPANY;

  try {
    const res: AxiosResponse<BulkSoftwareResponse> = yield call(
      UserService.bulkCompanySoftwareEvaluation,
      companiesBatch.map(company => company.company_id),
      isSoftware,
      rationale
    );

    const isCancel: boolean = yield select(bulkActionsSelectors.editFields.cancel);
    const percentage = Math.round((getCompanyCount / selectedCompaniesCount) * 100);

    if (!isCancel) {
      if (res.data) {
        if (totalPageCount !== page) {
          yield put(editFieldsActions.updateCompleted(false));
          yield put(editFieldsActions.updateProgress(true));
          yield put(editFieldsActions.updatePercentage(percentage));

          yield put(
            actions.bulkSoftwareSuccess({
              data: res.data.data,
              count: selectedCompaniesCount,
              isAdminOrAnalyst,
            })
          );
          yield put(
            actions.bulkSoftware({
              selectedCompanies,
              isSoftware,
              page: page + 1,
              rationale,
              isAdminOrAnalyst,
            })
          );
        } else {
          yield put(editFieldsActions.updateProgress(false));
          yield put(editFieldsActions.updateCompleted(true));
          yield put(editFieldsActions.updatePercentage(percentage));

          yield put(
            actions.bulkSoftwareSuccess({
              data: res.data.data,
              count: selectedCompaniesCount,
              isAdminOrAnalyst,
            })
          );
          NotificationService.success('Companies having been set as Software Company successfully');
        }
      }
    } else {
      yield put(editFieldsActions.updateProgress(false));
      yield put(editFieldsActions.updateCompleted(true));
      yield put(editFieldsActions.updatePercentage(percentage));

      yield put(
        actions.bulkSoftwareSuccess({
          data: res.data.data,
          count: selectedCompaniesCount,
          isAdminOrAnalyst,
        })
      );

      const message = 'Canceled to set as Software Company!';
      NotificationService.success(message);
    }
  } catch (e: any) {
    // save the title to reducer, create a new action
    yield put(editFieldsActions.updateProgress(false));
    yield put(editFieldsActions.updateCompleted(true));
    yield put(
      editFieldsActions.updatePercentage(
        BULK_EDIT_COMPANY_LIMIT_SET_AS_SOFTWARE_COMPANY * (page - 1)
      )
    );
    const message = 'Failed to set as Software Company!';
    NotificationService.error(message);
  }
}

export default function* bulkEditSaga() {
  yield takeLatest(actions.bulkSoftware, bulkSoftwareSaga);
}
