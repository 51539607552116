import { useEffect } from 'react';

const useInitialFetch = (
  shouldFetch: boolean,
  fetch: (...props: any) => void,
  ...fetchArgs: any
) => {
  useEffect(() => {
    shouldFetch && fetch && fetch(...fetchArgs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // [] is similar to component did mount so is called only once.
};

export default useInitialFetch;
