import React from 'react';
import { useSelector } from 'react-redux';
// models
import { TableCellSearches } from '@optx/models/table/Cell';
// redux
import { selectors as savedSearchesSelectors } from '@redux/company/saved-searches';
// hooks
import useCompanySavedSearches from '@optx/common/hooks/saved-searches/useCompanySavedSearches';
// components
import { mapTagsToNames } from '@utils/filters/mapTagsToNames';
import { mapTagsToNamesAndOperators } from '@utils/filters/mapTagsToNamesAndOperators';
import FilterTags from './FilterTags';

const TableCellFilters: React.FC<TableCellSearches> = ({ row }) => {
  // TODO: change filterSources code to be able to reuse the cell later
  // also use list directly
  const { handleSearchResults } = useCompanySavedSearches();

  const savedSearches = useSelector(savedSearchesSelectors.getSavedSearchesById);
  const savedSearchesAllData = useSelector(savedSearchesSelectors.getAllSearch);
  const listType = row.search_info?.type || null;

  const filterNames =
    listType === 'combined'
      ? mapTagsToNamesAndOperators(
          row.parent_search_ids,
          savedSearchesAllData,
          row.logical_operators
        )
      : mapTagsToNames(row.filteredTags!);

  const handleClick = () => {
    if (row.unique_id) {
      handleSearchResults(row.unique_id, row.title, row.search_info.type || '');
    }
  };

  return (
    <FilterTags
      filterNames={filterNames}
      onClick={handleClick}
      savedSearches={savedSearches}
      listType={listType}
    />
  );
};

export default TableCellFilters;
