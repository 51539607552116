import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
// models
import { CompanyTouchesResponse, TouchType } from '@optx/models/company/CompanyTouch';
import { CompanyTouchesSearchState, SearchTouchesPayload } from './interfaces';
import { SuccessResponse } from '@optx/redux/ui/modals/add-document-upload-touch/interfaces';
// utils
import { formatTouchHistoryNotes } from '@optx/utils/touches';
// redux
import { fetchReducer, fetchFailReducer } from '@redux/feature/fetch/reducers';
import { actions as uploadFileActions } from '@redux/ui/modals/add-document-upload-touch';
import * as actions from './actions';

const initialState: CompanyTouchesSearchState = {
  data: null,
  loading: false,
  error: '',
  fetchedAt: '',
  pagination: {
    pageNumber: 0,
    pageSize: 25,
  },
  companyId: null,
};

// fetch touches
const fetchTouchesReducer: CaseReducer<
  CompanyTouchesSearchState,
  PayloadAction<CompanyTouchesResponse, any, SearchTouchesPayload>
> = (draftState, action) => {
  const searchPaylod = action.meta;
  const currentPage = draftState.pagination.pageNumber;

  const data = cloneDeep(action.payload);
  formatTouchHistoryNotes(data);

  draftState.loading = false;
  draftState.fetchedAt = new Date().toISOString();
  draftState.companyId = searchPaylod.companyId;
  draftState.pagination.pageNumber = searchPaylod.pagination.pageNumber;

  if (data) {
    const { touches_left: touchesLeft, touch_history: touchHistory, number_of_touches } = data;

    if (currentPage < searchPaylod.pagination.pageNumber) {
      // page has been changed due to infinit scroll or initial search
      if (!draftState.data) {
        draftState.data = data;
      } else {
        draftState.data.touches_left = touchesLeft;
        draftState.data.touch_history.scheduled = [
          ...draftState.data.touch_history.scheduled,
          ...touchHistory.scheduled,
        ];
        draftState.data.touch_history.last_six_weeks = [
          ...draftState.data.touch_history.last_six_weeks,
          ...touchHistory.last_six_weeks,
        ];
        draftState.data.touch_history.over_six_weeks = [
          ...draftState.data.touch_history.over_six_weeks,
          ...touchHistory.over_six_weeks,
        ];
        draftState.data.touch_history.over_six_months = [
          ...draftState.data.touch_history.over_six_months,
          ...touchHistory.over_six_months,
        ];
        draftState.data.number_of_touches = number_of_touches;
      }
    } else {
      // filter has reset
      draftState.data = data;
    }
  }
};

const uploadFilesSuccessReducer: CaseReducer<
  CompanyTouchesSearchState,
  PayloadAction<SuccessResponse[]>
> = (draftState, action) => {
  const payload = action.payload;

  if (payload && draftState.data) {
    draftState.data.touch_history.last_six_weeks.unshift({
      ...payload[0].payload,
      touchtype: 'Document Upload' as TouchType,
      touchid: payload[0].payload.touchId,
    });

    draftState.data.number_of_touches.last_six_weeks =
      draftState.data.number_of_touches.last_six_weeks + 1;
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    // fetch company touches
    .addCase(actions.fetchTouches, fetchReducer)
    .addCase(actions.fetchTouchesSuccess, fetchTouchesReducer)
    .addCase(actions.fetchTouchesFail, fetchFailReducer)
    // upload
    .addCase(uploadFileActions.uploadFilesSuccess, uploadFilesSuccessReducer)
);

export default reducer;
