import React from 'react';
import { useSelector } from 'react-redux';
import StatsPresentation from '@optx/shared/view/molecules/statistics/StatsPresentation';
import { selectors as CompaniesETSelectors } from '../state';
// styles
import StyledStatsSection from './StatsSection/StatsSection.styles';

export const AllTimeHighlights = () => {
  const allTimeHighlightsData = useSelector(CompaniesETSelectors.highlights.etAlltimeData);

  return (
    <StyledStatsSection.SubSection>
      <StatsPresentation
        title={allTimeHighlightsData?.title}
        items={allTimeHighlightsData?.items}
        isDateTitle={false}
      />
    </StyledStatsSection.SubSection>
  );
};

export default AllTimeHighlights;
