import React from 'react';
import { Layout } from 'antd';
import { BasicProps } from 'antd/lib/layout/layout';
import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';

const { Content } = Layout;

interface MainContentProps extends BasicProps {
  hasFixedHeader?: boolean;
}

const MainContent = styled(({ hasFixedHeader, ...restProps }: MainContentProps) => (
  <Content {...restProps} />
))`
  margin-left: 16px;
  margin-right: 16px;

  ${up('lg')} {
    margin-left: 40px;
    margin-right: 40px;
  }

  ${props =>
    props.hasFixedHeader &&
    css`
      margin-top: 64px;
    `}
`;

export default MainContent;
