import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { reducers as fetchReducers } from '@redux/feature/fetch';

import * as actions from './actions';
import { EditCompanyFund, FundState } from './interfaces';

export const initialState: FundState = {
  companyId: null,
  error: null,
  loading: false,
};

const addFund: CaseReducer<FundState, PayloadAction<EditCompanyFund>> = (draftState, action) => {
  const { companyId } = action.payload;
  draftState.companyId = companyId;
  draftState.loading = true;
};

const addFundSuccess: CaseReducer<FundState, PayloadAction<EditCompanyFund>> = (
  draftState,
  action
) => {
  draftState.loading = false;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.addFund, addFund)
    .addCase(actions.addFundSuccess, addFundSuccess)
    .addCase(actions.addFundFail, fetchReducers.fetchFailReducer)
);

export default reducer;
