import React from 'react';
import styled from 'styled-components';
import { Tag, Tooltip, Typography } from 'antd';
// models
import { TableCellSearches } from '@optx/models/table/Cell';
// hooks
import useCompanySavedSearches from '@hooks/saved-searches/useCompanySavedSearches';

const CellWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const { Text } = Typography;

const TableCellSearchName: React.FC<TableCellSearches> = ({ value, row }) => {
  const { handleSearchResults } = useCompanySavedSearches();

  const handleClick = () => {
    if (row.unique_id) {
      handleSearchResults(row.unique_id, row.title, row.search_info.type || '');
    }
  };

  return (
    <CellWrapper onClick={handleClick}>
      <Text ellipsis>
        <Tooltip
          className="keep-space-sequence"
          overlayClassName="keep-space-sequence"
          title={value}
        >
          {value}
        </Tooltip>
      </Text>
      {row.is_default && <Tag color="volcano">Default View</Tag>}
    </CellWrapper>
  );
};

export default TableCellSearchName;
