import styled from 'styled-components';
import { components } from 'react-select';
import { Checkbox as $Checkbox } from 'antd';

const GroupHeading = styled(components.GroupHeading)`
  &.multiselect__group-heading {
    color: rgba(0, 0, 0, 0.85);
    padding: 8px 12px;
    cursor: pointer;
    font-size: inherit;
  }

  &:hover {
    background-color: #deebff;
  }
`;

const Checkbox = styled($Checkbox)`
  margin: 0 8px 0 0;
`;

export const Styled = {
  GroupHeading,
  Checkbox,
};
