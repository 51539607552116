import React from 'react';
import { FieldArray } from 'formik';
// models
import { RangeOptionBase } from '@optx/models/RangeOption';
import { RangeFilter } from '@optx/models/filters';
// components
import { FormCheckableRangeOption } from '@optx/models/Option';
import FilterRange from './FilterRange';

interface FilterRangeProps {
  filter: RangeFilter<Array<RangeOptionBase>>;
}

const FilterRangeList: React.FC<FilterRangeProps> = ({ filter }) => {
  return (
    <FieldArray
      name={filter.column}
      render={arrayHelpers => (
        <>
          {(arrayHelpers.form.values[filter.column] as Array<FormCheckableRangeOption>).map(
            (range, index) => (
              <FilterRange
                key={index}
                arrayHelpers={arrayHelpers}
                filter={filter}
                index={index}
                option={range}
              />
            )
          )}
        </>
      )}
    />
  );
};

export default React.memo(FilterRangeList);
