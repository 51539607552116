import React, { useEffect, useState } from 'react';
import { Col, Typography, Input } from 'antd';
import { useField, useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import { Form } from 'formik-antd';
import styled from 'styled-components';
// models
import { BaseField } from '@optx/models/companyFields';
import { FieldUsedFor } from './interface';
// constants
import { numberBigRegExpEndingDot, numberBigRegExp } from '@constants/regex';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { numberToMillions } from '@utils/number';

const validationFields = ['capital_raised', 'last_round_amount'];

const InputWrapper = styled.div`
  &.has-errors .ant-input,
  &.has-errors .ant-input-affix-wrapper {
    border: 1px solid #ff7875;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  }

  &.valid-input .ant-input-affix-wrapper:hover,
  &.valid-input .ant-input-affix-wrapper,
  &.valid-input .ant-input,
  &.valid-input .ant-input:hover {
    border: 1px solid #d9d9d9;
  }
`;

const validateField = (amount: string | number, fieldName: string) => {
  return validationFields.includes(fieldName) &&
    ((amount && Number.isNaN(Number(amount)) && amount !== DEFAULT_EMPTY_VALUE) || amount < 0)
    ? 'has-errors'
    : 'valid-input';
};

interface InputMillionsFieldProps {
  field: BaseField;
  fieldUsedFor?: FieldUsedFor;
}

const InputMillionsField: React.FC<InputMillionsFieldProps> = ({ field, fieldUsedFor }) => {
  const [selected, , setSelected] = useField<number | null>(field.id);
  const [amount, setAmount] = useState<string | undefined>(undefined);

  const formik = useFormikContext();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;

    if (
      numberBigRegExp.test(inputValue) ||
      inputValue === '' ||
      inputValue === DEFAULT_EMPTY_VALUE
    ) {
      setAmount(isNaN(parseInt(inputValue)) ? '' : inputValue);
    } else if (numberBigRegExpEndingDot.test(inputValue)) {
      setAmount(inputValue);
    }
  };

  useEffect(() => {
    if (fieldUsedFor === 'editAllInfo') {
      setAmount(
        selected.value === null || isNaN(selected.value)
          ? ''
          : (Number(selected.value)! / 1000000).toString()
      );
    } else if (field?.value || field?.value === 0) {
      if (field.value === DEFAULT_EMPTY_VALUE) {
        setAmount('');
      } else {
        setAmount((Number(field.value)! / 1000000).toString());
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  useEffect(() => {
    if (amount && numberBigRegExp.test(amount)) {
      if (!isEqual(selected.value, numberToMillions(amount))) {
        setSelected.setValue(numberToMillions(amount), true);
      }
    } else if (amount && numberBigRegExpEndingDot.test(amount)) {
      const amountRemovedDot = amount.slice(0, -1);
      setSelected.setValue(numberToMillions(amountRemovedDot));
    } else if (amount === '' || amount === DEFAULT_EMPTY_VALUE) {
      if (selected.value !== null) {
        setSelected.setValue(null);
      }
    } else if (amount !== undefined) {
      setSelected.setValue(Number(amount));
    }

    setTimeout(() => {
      formik.validateForm();
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  return (
    <Col span={8} className={field.id}>
      <Form.Item name={field.id}>
        <Typography.Text>
          {field.label}
          <span> (in millions)</span>
        </Typography.Text>
        <InputWrapper className={validateField(amount ?? '', field.id)}>
          <Input
            value={amount}
            onChange={handleChange}
            placeholder={field.placeholder}
            disabled={!field.editable}
          />
        </InputWrapper>
      </Form.Item>
    </Col>
  );
};

export default React.memo(InputMillionsField);
