import styled from 'styled-components';
import { Typography } from 'antd';

export const StyledCardTitle = styled(Typography.Title)`
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-weight: 400;
  font-size: 24px !important;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0 !important;
`;

export const StyledCardSubTitle = styled(Typography.Text)`
  font-weight: 400;
  font-size: 16px !important;
  line-height: 24px;
  color: #90a0b7;
`;

export default {
  StyledCardTitle,
  StyledCardSubTitle,
};
