import React from 'react';
import { Menu } from 'antd';
// components
// eslint-disable-next-line max-len
import ToggleSearchViewSort from '@components/pages/UserLists/components/SearchGrid/SearchGridControls/ToggleSearchViewSort';
import { ResetColumnWidthsMenu } from '@features/columns/update-columns/components';
import ClearSortButton from '@components/pages/UserLists/components/SearchGrid/SearchGridControls/ClearSortButton';
import DisplayColumnToggle from './DisplayColumnToggle';

const ListsMenu = () => {
  return (
    <Menu>
      <DisplayColumnToggle key={1} />
      <ResetColumnWidthsMenu key={2} />
      <ClearSortButton key={3} />
      <ToggleSearchViewSort key={4} />
    </Menu>
  );
};

export default ListsMenu;
