import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import queryString from 'query-string';
// constants
import appRoutes from '@constants/routes';
// redux
import { selectors } from '@features/analyst-home/state';
// components
import { TotalsCard } from './components';
import Styled from './components/Sidebar.styles';

const TouchManagement = () => {
  const history = useHistory();

  const data = useSelector(selectors.getTouchManagementData);
  const redirects = useSelector(selectors.getTouchManagementRedirect);
  const dates = useSelector(selectors.getTouchManagementDates);
  const loading = useSelector(selectors.getInsightsLoading);

  const handleRedirects = (label: string) => {
    if (!redirects || !(label in redirects)) {
      return;
    }

    const { start_date, end_date, tab } = queryString.parse(redirects[label], {
      arrayFormat: 'comma',
    });

    const selectedPeriod =
      dates.find(date => date.start === start_date && date.end === end_date)?.label ?? 'This Week';
    const selectedTab = tab ?? '*';

    history.push(
      `${appRoutes.scheduledTouches}?selectedPeriod=${selectedPeriod}&selectedTab=${selectedTab}`
    );
  };

  return (
    <Styled.SubSection>
      <TotalsCard data={data} handleRedirects={handleRedirects} loading={loading} />
    </Styled.SubSection>
  );
};

export default TouchManagement;
