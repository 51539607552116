import { BarProps, TooltipProps, XAxisProps } from 'recharts';
import {
  defaultAxisLineProps,
  xAxisTickDefaultProps,
} from '@optx/components/common/charts/LineChart/config';

export const commonBarProps: Omit<BarProps, 'dataKey'> = {
  radius: [10, 10, 0, 0],
};

export const leftBarProps: Omit<BarProps, 'dataKey'> = {
  ...commonBarProps,
  fill: '#1890FF',
};

export const rightBarProps: Omit<BarProps, 'dataKey'> = {
  fill: '#C2CFE0',
  ...commonBarProps,
};

export const axisLineProps: React.SVGProps<SVGSVGElement> = {
  ...defaultAxisLineProps,
  style: {
    // space between XAxis line and bars.
    transform: 'translateY(4px)',
  },
};

export const xAxisProps: XAxisProps = {
  axisLine: axisLineProps,
  tickLine: false,
  tick: xAxisTickDefaultProps,
  tickMargin: 10,
  interval: 'preserveStartEnd',
};

// tooltip
export const tooltipWrapperStyles: React.CSSProperties = {
  zIndex: 1,
};

const tooltipCursor: React.CSSProperties = {
  opacity: 0.2,
};

const tooltipLabelStyle: React.CSSProperties = {
  whiteSpace: 'pre-line',
};

export const tooltipProps: TooltipProps = {
  wrapperStyle: tooltipWrapperStyles,
  labelStyle: tooltipLabelStyle,
  allowEscapeViewBox: { y: true },
  cursor: tooltipCursor,
};
