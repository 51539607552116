import styled from 'styled-components';
import { Card as ANTDCard } from 'antd';
import { down } from 'styled-breakpoints';

import $Card from '@optx/components/common/cards/Card';
import * as StyledTitle from '../styled';
import FilterSingleSelect from '../FilterSingleSelect';

const SecondaryTitle = styled(StyledTitle.SecondaryTitle)`
  &.ant-typography {
    margin-bottom: 0;
  }
`;

const Card = styled($Card)`
  .ant-card-body {
    display: flex;
    flex-wrap: wrap;
    /* Issue with Grid if the type is not actual ANTD Grid type is not hiding padding */
    padding: 0;
  }

  .ant-card-loading-content {
    width: 100%;
  }
`;

const GridChart = styled(ANTDCard.Grid)`
  flex: 1;
`;

const GridOtherDealStages = styled(ANTDCard.Grid)`
  width: calc(33% - 40px);
  min-width: 264px;

  ${down('xl')} {
    width: 100%;
  }
`;

const PreviousPeriodSelect = styled(FilterSingleSelect)`
  min-width: 330px;
`;

export const Styled = {
  SecondaryTitle,
  Card,
  GridChart,
  GridOtherDealStages,
  PreviousPeriodSelect,
};
