import React from 'react';
import { components, MenuListComponentProps } from 'react-select';
import { SelectOption } from '@optx/models/Option';
import SelectAll from './SelectAll';

const { MenuList } = components;

const SelectAllMenuList =
  (hasCustomNoTouchesOption?: boolean) => (props: MenuListComponentProps<SelectOption>) => {
    const { children, ...restProps } = props;

    return (
      <MenuList {...restProps}>
        <SelectAll {...props} hasCustomNoTouchesOption={hasCustomNoTouchesOption} />
        {children}
      </MenuList>
    );
  };

export default SelectAllMenuList;
