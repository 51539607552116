import moment from 'moment';
import { isEmpty, orderBy } from 'lodash';
// models
import { UpcomingTouchesNotificationDetails } from '@optx/models/notifications';
import { ImportedCompany } from '@optx/features/list-import/state/interfaces';
import { SelectOption } from '@optx/models/Option';
// constants
import { ISO_DATE_FORMAT, NOTIFICATION_DATE_FRAME_FORMAT } from '@optx/constants/format/date';

export const formatNumber = (n: string | number) => String(n).replace(/(.)(?=(\d{3})+$)/g, '$1,');

export const hasBlankOrNoneLabel = (item: string) => {
  if (item === undefined) return false;

  return ['blank', 'none'].includes(item.toLowerCase());
};

export const hasBlankLabel = (item: string) => {
  return ['blank'].includes(item.toLowerCase());
};

export const showRationaleForStage = (selectedOption: string) =>
  ['Dead', 'Pass'].includes(selectedOption);

export const showOpinionForNextSteps = (selectedOption: string) => selectedOption === 'Other';

export const parseDataByPastTime = (data: UpcomingTouchesNotificationDetails[]) => {
  const results = {
    data: {
      Today: {
        dateData: [] as unknown as UpcomingTouchesNotificationDetails[],
        dateFrame: '',
      },
    },
    count: 0,
  };

  const dataSort = orderBy(data, item => moment(item.next_update).format(ISO_DATE_FORMAT), ['asc']);
  const todayNew = moment().format(ISO_DATE_FORMAT);
  const filteredData = dataSort.filter(
    item => moment(item.next_update).diff(todayNew, 'days') <= 0
  );

  // Logic for 'Today' notifications
  const todayDate = moment().subtract(0, 'd');
  const todayDateFrame = todayDate.format(NOTIFICATION_DATE_FRAME_FORMAT);

  results.data.Today.dateFrame = `${todayDateFrame}`;

  filteredData.forEach(item => {
    results.data.Today.dateData.unshift(item);
    results.count++;
  });

  return results;
};

export const parseDataByNextTime = (data: UpcomingTouchesNotificationDetails[]) => {
  const results = {
    data: {
      Today: {
        dateData: [] as unknown as UpcomingTouchesNotificationDetails[],
        dateFrame: '',
      },
      Tomorrow: {
        dateData: [] as unknown as UpcomingTouchesNotificationDetails[],
        dateFrame: '',
      },
      'This week': {
        dateData: [] as unknown as UpcomingTouchesNotificationDetails[],
        dateFrame: '',
      },
      'Next week': {
        dateData: [] as unknown as UpcomingTouchesNotificationDetails[],
        dateFrame: '',
      },
    },
    count: 0,
  };

  const dataSort = orderBy(data, item => moment(item.next_update).format(ISO_DATE_FORMAT), ['asc']);
  const todayNew = moment().format(ISO_DATE_FORMAT);
  const filteredData = dataSort.filter(
    item => moment(item.next_update).diff(todayNew, 'days') >= 0
  );

  // Logic for 'Today' notifications
  const todayDate = moment().add(0, 'd');
  const today = todayDate.format(ISO_DATE_FORMAT);
  const todayDateFrame = todayDate.format(NOTIFICATION_DATE_FRAME_FORMAT);

  results.data.Today.dateFrame = `${todayDateFrame}`;

  // Logic for 'Tomorrow' notifications
  const tomorrowDate = moment().add(1, 'd');
  const tomorrow = tomorrowDate.format(ISO_DATE_FORMAT);
  const tomorrowDateFrame = tomorrowDate.format(NOTIFICATION_DATE_FRAME_FORMAT);

  results.data.Tomorrow.dateFrame = `${tomorrowDateFrame}`;

  // Logic for 'This Week' notifications
  const lastDayOfThisWeek = moment().add(7, 'd').format(ISO_DATE_FORMAT);

  // Logic for 'Next week' notifications
  const startDayOfNextWeek = moment().add(6, 'd').format(ISO_DATE_FORMAT);
  const lastDayOfNextWeek = moment().add(14, 'd').format(ISO_DATE_FORMAT);

  filteredData.forEach(item => {
    const futureTouchDate = moment(item.next_update);

    if (futureTouchDate.isSame(today)) {
      results.data.Today.dateData.push(item);
      results.count++;
    } else if (futureTouchDate.isSame(tomorrow)) {
      results.data.Tomorrow.dateData.push(item);
      results.count++;
    } else if (futureTouchDate.isBetween(tomorrow, lastDayOfThisWeek)) {
      results.data['This week'].dateData.push(item);
      results.count++;
    } else if (futureTouchDate.isBetween(startDayOfNextWeek, lastDayOfNextWeek)) {
      results.data['Next week'].dateData.push(item);
      results.count++;
    }
  });

  return results;
};

export const convertPayloadImport = (dataImport: Array<{}>) => {
  const result: Array<{}> = [];
  let data = {
    data: [] as unknown as {},
  };

  if (!isEmpty(dataImport)) {
    dataImport.forEach((value: { [x: string]: string }) => {
      let cmp = '';
      let url = '';

      for (const pr in value) {
        if (typeof pr == 'string') {
          switch (pr.toLowerCase()) {
            case 'company':
              cmp = pr;
              break;
            case 'url':
              url = pr;
              break;
            default:
              break;
          }
        }
      }

      if (cmp && url && value[cmp]) {
        result.push({
          Company: value[cmp].trim(),
          URL: value[url].trim(),
          Website: value[url].trim(),
        });
      }
    });
  }

  data = { data: result };
  const dataString = JSON.stringify(data);

  return { dataString, data: data.data as ImportedCompany[] };
};

export const copyToClipboard = (value: string | JSX.Element) => {
  if (!navigator.clipboard) {
    return;
  }

  navigator.clipboard.writeText(value as string);
};

/**
 * convertLinkedinURL - called to convert Linkedin URL to acceptable, if it's invalid.
 * @param {string} linkedinUrlValue - value that user entered to input field
 */
export const convertLinkedinURL = (linkedinUrlValue: string | null | undefined) => {
  if (!linkedinUrlValue) return linkedinUrlValue;
  let linkedinUrl = linkedinUrlValue;

  if (linkedinUrlValue.includes('/mwlite/')) {
    linkedinUrl = linkedinUrlValue.replace('/mwlite/', '/');
  }

  // Remove subdomain if is present
  linkedinUrl = linkedinUrl.replace(/\/\/(.*?)linkedin/g, '//www.linkedin');

  switch (!!linkedinUrl) {
    case linkedinUrl.startsWith('https://www.linkedin.com/in/'):
    case linkedinUrl.startsWith('https://linkedin.com/in/'):
    case linkedinUrl.startsWith('https://www.linkedin.com/in'):
    case linkedinUrl.startsWith('http://www.linkedin.com/in'):
      return linkedinUrl;

    case linkedinUrl.startsWith('www.linkedin.com/in/'):
    case linkedinUrl.startsWith('www.linkedin.com/in'):
      return linkedinUrl.replace('www', 'https://www');

    case linkedinUrl.startsWith('http://www.linkedin.com/in/'):
    case linkedinUrl.startsWith('http://linkedin.com/in/'):
      return linkedinUrl.replace('http', 'https');

    case linkedinUrl.startsWith('linkedin.com/in/'):
    case linkedinUrl.startsWith('linkedin.com/in'):
      return linkedinUrl.replace('linkedin', 'https://www.linkedin');

    default:
      return `https://www.linkedin.com/in/${linkedinUrl}`;
  }
};

/**
 *  makeUnique - called to make unique array of objects by keys
 * @param {any} array - source array to make unique
 * @param {string[]} keys - keys to base the array to be made unique
 */
export const makeUnique = (array: any[], keys: string[]) => {
  if (!keys.length || !array.length) return [];

  return array.reduce((list, item) => {
    const hasItem = list.find((listItem: any) => keys.every(key => listItem[key] === item[key]));
    if (!hasItem) list.push(item);

    return list;
  }, []);
};

/**
 * Normalizes a given value to its corresponding label
 * @param {SelectOption} value - The value to be normalized
 * @param {SelectOption[]} options - The list of options to search for the normalized value
 *
 * @returns {string} The normalized value's label
 */
export const normalizeValue = (value: SelectOption, options: SelectOption[]) => {
  if (value.value !== 'blank') {
    const option = options.find(item => item.value.toString() === value.value);

    if (option) {
      return option.label;
    }
  }

  return value.value;
};
