import React from 'react';
import { useSelector } from 'react-redux';
import { Dictionary } from 'lodash';
import queryString from 'query-string';
// constants
import predefinedFilters from '@components/common/table/TableSourceScrubLists/utils/predefinedFilters';
// models
import { SourceScrubList } from '@models/SourceScrubList';
import { PreselectedFilter } from '@models/filters';
// redux
import { selectors as filterSourcesSelectors } from '@redux/company/filters';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
// utils
import { searchSourceScrubInNewTab } from '@utils/search';
import mapFiltersToURLParams from '@utils/filters/mapFiltersToURLParams';
// components
import Icon from '@components/common/Icon';

interface TableCellNewTabProps {
  value: any;
  row: SourceScrubList;
  filterKey:
    | 'number_of_companies_in_et'
    | 'number_of_companies_not_in_et'
    | 'number_of_last_touch_over_6_months'
    | 'number_of_future_touches';
}

const TableCellNewTab: React.FC<TableCellNewTabProps> = ({ value, row, filterKey }) => {
  const clearedFilter = useSelector(filterSelectors.getClearedFilter('advancedSearch'));
  const sources = useSelector(filterSourcesSelectors.getFiltersMap);

  const listId = row && row.id;

  let query = '';

  if (predefinedFilters[filterKey]) {
    // insert filter info in filters.
    const filter: Dictionary<PreselectedFilter> = {
      ...clearedFilter,
      ...predefinedFilters[filterKey],
    };

    const mappedFilters = mapFiltersToURLParams(sources, filter);
    query = queryString.stringify(mappedFilters, {
      arrayFormat: 'comma',
    });
  }

  return (
    <a
      className="new-tab-number"
      href={`/ss-lists/${listId}?${query}`}
      onClick={e => {
        e.preventDefault();
        if (predefinedFilters[filterKey])
          searchSourceScrubInNewTab(listId, clearedFilter, sources, predefinedFilters[filterKey]);
      }}
    >
      <Icon color="#1890ff" iconName="open-in-new" /> {value}
    </a>
  );
};

export default TableCellNewTab;
