import React from 'react';
import { Menu, MenuProps } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
// constants
import appRoutes from '@constants/routes';
// redux
import { resetColumnWidths as resetAdvancedSearch } from '@redux/company/search/columns/actions';
import { resetColumnWidths as resetMyCompanies } from '@redux/my-companies/columns/actions';
import { resetColumnWidths as resetLists } from '@redux/lists/search/columns/actions';
import { resetColumnWidths as resetCompanyOutReach } from '@redux/company-outreach/columns/actions';
import { selectors as viewTypeSelectors } from '@features/grid/view-type';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';

export const ResetColumnWidthsMenu = ({ onClick, ...restProps }: MenuItemProps) => {
  const dispatch = useDispatch();

  const searchView = useSelector(viewTypeSelectors.searchView('advancedSearch'));
  const myCompaniesView = useSelector(viewTypeSelectors.searchView('myCompanies'));
  const listView = useSelector(viewTypeSelectors.searchView('lists'));
  const companyOutReachView = useSelector(viewTypeSelectors.searchView('outreach'));

  const { advancedSearchPathname } = useIsAnalyst();

  const handleClick: MenuProps['onClick'] = info => {
    if (window.location.pathname === advancedSearchPathname) {
      dispatch(resetAdvancedSearch('column_widths_advanced_search'));
    } else if (window.location.pathname.startsWith('/user-lists')) {
      dispatch(resetLists('column_widths_lists'));
    } else if (window.location.pathname === appRoutes.myCompanies) {
      dispatch(resetMyCompanies('column_widths_my_companies'));
    }

    if (window.location.pathname.startsWith('/all-reports')) {
      dispatch(resetCompanyOutReach('column_widths_sourcing_outreach_detail'));
    }

    onClick && onClick(info);
  };

  if (window.location.pathname === advancedSearchPathname && searchView === 'long-card') {
    return null;
  }

  if (window.location.pathname.startsWith('/user-lists') && listView === 'long-card') {
    return null;
  }

  if (window.location.pathname.startsWith('/ss-lists') && listView === 'long-card') {
    return null;
  }

  if (window.location.pathname.startsWith('/all-reports') && companyOutReachView === 'long-card') {
    return null;
  }

  if (window.location.pathname === appRoutes.myCompanies && myCompaniesView === 'long-card') {
    return null;
  }

  return (
    <Menu.Item onClick={handleClick} {...restProps}>
      Reset column widths
    </Menu.Item>
  );
};
