/**
 * Checks if the page was reloaded
 *
 * @returns {boolean} true if the page was reloaded, false otherwise
 */
export const getIsPageReloaded = () => {
  return (
    window.performance
      .getEntriesByType('navigation')
      // ts doesn't know that nav also includes a type property,
      // so we'll ignore this issue for now
      // @ts-ignore
      .map(nav => nav.type)
      .includes('reload')
  );
};
