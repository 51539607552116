import { cloneDeep } from 'lodash';
import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// constants
import {
  analystColumnDisplayInitialState,
  companyColumnDisplayInitialState,
  initialAnalystColumnOrder,
  initialCompanyColumnOrder,
} from '@optx/constants/table/columnDisplay/checklist-research-insights';
import { DEFAULT_COMPANY_OWNER_VALUE } from '@optx/constants/table/company';
// models
import Company, { ProductCategory } from '@optx/models/Company';
import { CompanyColumnDisplay } from '@optx/models/table/Columns';
import { SelectOption } from '@optx/models/Option';
import {
  SaveResearchRationaleResponse,
  ScoreRationaleResponse,
} from '@features/add-research-rationale/state/interfaces';
import { ColumnKeys, AnalystResponse } from '../../models';
// utils
import { showRationaleForStage } from '@optx/utils/helpers';
// redux
import { SearchState } from '../interfaces';
import { actions } from '../actions';
import { fetchReducer, fetchFailReducer } from '@optx/redux/feature/fetch/reducers';
import { actions as searchCountActions } from '@features/grid/search-count';
import { actions as editFieldActions } from '@optx/features/company/edit-fields/state';
import { actions as researchRationaleActions } from '@features/add-research-rationale/state';
import {
  CustomValue,
  EditFieldSuccess,
  MultipleFieldsValue,
  UpdateFieldsPayload,
} from '@optx/features/company/edit-fields/state/interfaces';

const initialState: SearchState = {
  data: [],
  loading: false,
  columnDisplay: cloneDeep(analystColumnDisplayInitialState),
  columnOrder: initialAnalystColumnOrder,
  error: '',
};

const fetchAnalystSuccessReducer: CaseReducer<SearchState, PayloadAction<AnalystResponse>> = (
  draftState,
  action
) => {
  const { analysts } = action.payload;

  draftState.data = analysts;

  draftState.columnDisplay = cloneDeep(analystColumnDisplayInitialState);
  draftState.columnOrder = initialAnalystColumnOrder;

  draftState.loading = false;
  draftState.error = '';
};

const fetchCompanySuccessReducer: CaseReducer<SearchState, PayloadAction<Company[]>> = (
  draftState,
  action
) => {
  const data = action.payload;

  draftState.data = data;
  draftState.columnDisplay = cloneDeep(companyColumnDisplayInitialState);
  draftState.columnOrder = initialCompanyColumnOrder;

  draftState.loading = false;
  draftState.error = '';
};

const toggleDefaultTableViewReducer: CaseReducer<SearchState> = draftState => {
  draftState.loading = true;
};

const searchCountSuccessReducer: CaseReducer<SearchState> = draftState => {
  draftState.loading = false;
};

const fetchCompanyCountFailReducer: CaseReducer<SearchState> = draftState => {
  draftState.loading = false;
};

const saveColumnsReducer: CaseReducer<
  SearchState,
  PayloadAction<{ columnDisplay: CompanyColumnDisplay<any>; columnOrder: ColumnKeys[] }>
> = (draftState, action) => {
  const { columnDisplay, columnOrder } = action.payload;
  draftState.columnDisplay = columnDisplay;
  draftState.columnOrder = columnOrder;
};

const updateFieldsSuccessReducer: CaseReducer<SearchState, PayloadAction<EditFieldSuccess>> = (
  draftState,
  action
) => {
  const {
    fieldName,
    companyId,
    value,
    score,
    scoreGrowth,
    isPartner,
    companyOwnerData,
    ilScore,
    checklist_reviewed_by,
    checklist_reviewed_date,
    ilScoreGrowth,
    defaultOptxScore,
    isPsgCompany,
  } = action.payload;

  const companyIndex = (draftState.data as Company[])?.findIndex(c => c.company_id === companyId);
  const company = draftState.data[companyIndex] as Company;

  if (company) {
    const fieldKeyValue = (value as UpdateFieldsPayload).value;
    const rationale = (value as UpdateFieldsPayload).rationale;
    const optionSelected = (value as UpdateFieldsPayload).value as SelectOption;

    if (checklist_reviewed_date) {
      company.checklist_reviewed_date = checklist_reviewed_date as string | null;
    }

    if (checklist_reviewed_by) {
      company.checklist_reviewed_by = checklist_reviewed_by as string | null;
    }

    if (score !== undefined) {
      company.score = score ?? 'N/A';
    }

    if (typeof scoreGrowth === 'number') {
      company.score_growth = scoreGrowth.toString();
    }

    if (defaultOptxScore === 'il') {
      company.il_optx_score = ilScore as number;
      company.il_score_growth = ilScoreGrowth?.toString() as string;
    }

    if (!Array.isArray(fieldName)) {
      if (fieldName === 'Software Company') {
        company.is_software = ((value as UpdateFieldsPayload).value as unknown as boolean) || false;

        company.is_software_rationale = rationale as string;

        if (isPartner) {
          company.is_software_verified = false;
        } else {
          company.is_software_verified = true;
        }

        company.score = score as number;
      }

      if (fieldName === 'PSG Fit' && typeof score === 'number') {
        company.is_interesting = (value as UpdateFieldsPayload).value as unknown as boolean;

        company.interesting_rationale = rationale as string;

        company.score = score;
      }

      if (fieldName === 'Company Owner') {
        if (companyOwnerData) {
          company.company_owner =
            companyOwnerData.company_owner_name === null
              ? DEFAULT_COMPANY_OWNER_VALUE
              : companyOwnerData.company_owner_name;
        } else {
          company.company_owner = DEFAULT_COMPANY_OWNER_VALUE;
        }
      }

      if (fieldName === 'Pipeline Rank') {
        company.pipeline_rank = optionSelected.label;
      }

      if (fieldName === 'Senior Advisor Thesis') {
        company.senior_advisor_thesis = optionSelected.label;
      }

      if (fieldName === 'Lead Source') {
        company.lead_source = optionSelected.label;
      }

      if (fieldName === 'Fund') {
        company.fund = optionSelected.label;
        company.is_psg_company = isPsgCompany as boolean;
      }

      if (fieldName === 'Stage') {
        company.stage = optionSelected.label;
        company.is_psg_company = isPsgCompany as boolean;

        if (showRationaleForStage(optionSelected.label)) {
          company.stage_rationale = rationale as string;
        } else {
          company.stage_rationale = null;
        }
      }

      if (fieldName === 'Product Category') {
        const newCategories = ((value as UpdateFieldsPayload).value as string)
          .split(',')
          .map(item => ({
            category: item,
            is_valid: true,
          }));

        const previousCategories = [...(company.product_category as ProductCategory[])];

        const combinedArray = newCategories.map(value => {
          const duplicateValue = previousCategories.find(item => item.category === value.category);

          return duplicateValue !== undefined ? duplicateValue : value;
        });

        company.product_category = ['blank'].includes(
          (value as UpdateFieldsPayload).value as string
        )
          ? null
          : combinedArray;
      }

      if (fieldName === 'Add-on') {
        const previousAddon = company.addon;
        const newAddon = ((value as UpdateFieldsPayload).value as CustomValue)
          .optionalValue as string;

        if ('data' in draftState && previousAddon !== newAddon) {
          // Remove company if addon was changed in ADDON MGMT Tab
          draftState.data =
            (draftState.data as Company[])?.filter(company => company.company_id !== companyId) ??
            null;
        } else {
          company.addon = newAddon;
          company.addon_bool = ((value as UpdateFieldsPayload).value as CustomValue)
            .value as boolean;
        }
      }

      if (fieldName === 'Linkedin Profile') {
        company.linkedin = fieldKeyValue as never;
      }

      if (fieldName === 'Website Status') {
        company.url_status = fieldKeyValue ? 'active' : 'not active';
      }
    } else {
      if (fieldName.includes('Stage')) {
        const optionSelected = (value as MultipleFieldsValue).value.Stage;
        company.stage = optionSelected.label;
        company.is_psg_company = isPsgCompany as boolean;

        if (showRationaleForStage(optionSelected.label)) {
          company.stage_rationale = rationale as string;
        } else {
          company.stage_rationale = null;
        }
      }

      if (fieldName.includes('Pipeline Rank')) {
        const optionSelected = (value as MultipleFieldsValue).value['Pipeline Rank'];
        company.pipeline_rank = optionSelected.label;
      }

      if (fieldName.includes('Company Owner')) {
        if (companyOwnerData) {
          company.company_owner =
            companyOwnerData.company_owner_name === null
              ? DEFAULT_COMPANY_OWNER_VALUE
              : companyOwnerData.company_owner_name;
        } else {
          company.company_owner = DEFAULT_COMPANY_OWNER_VALUE;
        }
      }
    }
  }
};

const submitResearchRationaleSuccessReducer: CaseReducer<
  SearchState,
  PayloadAction<SaveResearchRationaleResponse>
> = (draftState, action) => {
  const { company_id: companyId, fields, scoreValues, scoreRationale } = action.payload;

  const companyIndex = (draftState.data as Company[])?.findIndex(c => c.company_id === companyId);
  const company = draftState.data[companyIndex] as Company;

  if (company) {
    if (fields.psg_fit) {
      company!.is_interesting = fields.psg_fit.value as boolean | null;
      company!.interesting_rationale = fields.psg_fit.rationale as string | null;
      // score values for default score
      company!.score = scoreValues?.fitScore as number;
      company!.score_growth = scoreValues?.fitScoreGrowth?.toString() || null;
      // score values for israel score
      company!.il_optx_score = scoreValues?.fitIsraelScore as number;
      company!.il_score_growth = scoreValues?.fitIsraelScoreGrowth?.toString() || null;
    }

    if (fields.software_company) {
      if (!company!.is_software_verified) {
        company!.is_software_verified = !company!.is_software_verified;
      }

      company!.is_software = fields.software_company.value as boolean;
      company!.is_software_rationale = fields.software_company.rationale as string | null;
      // score values for default score
      company!.score = scoreValues?.softwareScore as number;
      company!.score_growth = scoreValues?.softwareScoreGrowth?.toString() || null;
      // score values for israel score
      company!.il_optx_score = scoreValues?.softwareIsraelScore as number;
      company!.il_score_growth = scoreValues?.softwareIsraelScoreGrowth?.toString() || null;
    }

    if (scoreValues && scoreValues.checklistReviewedBy) {
      company!.checklist_reviewed_by = scoreValues.checklistReviewedBy;
    }

    if (scoreValues && scoreValues.checklistReviewedDate) {
      company!.checklist_reviewed_date = scoreValues.checklistReviewedDate;
    }

    // if api returns false for this property, it means no verification was sent for optx score
    if (scoreRationale !== false) {
      if ((scoreRationale as ScoreRationaleResponse).score_type === 'il_optx_score') {
        company!.il_optx_score_rationale = (scoreRationale as ScoreRationaleResponse).rationale;
        company!.il_optx_score_rationale_reason = (
          scoreRationale as ScoreRationaleResponse
        ).rationale_reason;
        company!.il_optx_score_rationale_reason_type = (
          scoreRationale as ScoreRationaleResponse
        ).rationale_reason_type;
        company!.il_optx_score_verified = (
          scoreRationale as ScoreRationaleResponse
        ).optx_score_verified;
      } else {
        company!.optx_score_rationale = (scoreRationale as ScoreRationaleResponse).rationale;
        company!.optx_score_rationale_reason = (
          scoreRationale as ScoreRationaleResponse
        ).rationale_reason;
        company!.optx_score_rationale_reason_type = (
          scoreRationale as ScoreRationaleResponse
        ).rationale_reason_type;
        company!.optx_score_verified = (
          scoreRationale as ScoreRationaleResponse
        ).optx_score_verified;
      }
    }
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.search.fetchAnalyst, fetchReducer)
    .addCase(actions.search.fetchAnalystSuccess, fetchAnalystSuccessReducer)
    .addCase(actions.search.fetchAnalystFail, fetchFailReducer)
    .addCase(actions.ui.toggleDefaultTableView, toggleDefaultTableViewReducer)
    .addCase(actions.search.fetchCompany, fetchReducer)
    .addCase(actions.search.fetchCompanySuccess, fetchCompanySuccessReducer)
    .addCase(actions.search.fetchCompanyFail, fetchFailReducer)
    .addCase(searchCountActions.searchCount, toggleDefaultTableViewReducer)
    .addCase(searchCountActions.searchCountSuccess, searchCountSuccessReducer)
    .addCase(searchCountActions.searchCountFailure, fetchCompanyCountFailReducer)
    .addCase(editFieldActions.updateFieldsSuccess, updateFieldsSuccessReducer)
    .addCase(actions.search.saveColumns, saveColumnsReducer)
    .addCase(
      researchRationaleActions.submitResearchRationaleSuccess,
      submitResearchRationaleSuccessReducer
    )
);

export default reducer;
