import { createFeatureActionType } from '@optx/utils/feature';
import { featureName } from '../../featureName';

export const FETCH_COMPANY_ACTIVITY = createFeatureActionType(
  featureName,
  'FETCH_COMPANY_ACTIVITY'
);
export const FETCH_COMPANY_ACTIVITY_SUCCESS = createFeatureActionType(
  featureName,
  'FETCH_COMPANY_ACTIVITY_SUCCESS'
);
export const FETCH_COMPANY_ACTIVITY_FAIL = createFeatureActionType(
  featureName,
  'FETCH_COMPANY_ACTIVITY_FAIL'
);
