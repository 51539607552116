import styled from 'styled-components';

import FilterSingleSelect from '../FilterSingleSelect';

const Select = styled(FilterSingleSelect)`
  min-width: 330px;
`;

export const Styled = {
  Select,
};
