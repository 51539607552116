import { CaseReducer, PayloadAction, createReducer } from '@reduxjs/toolkit';
import { fetchReducer, fetchFailReducer } from '@optx/redux/feature/fetch/reducers';
import {
  ChangedCompayFieldsState,
  ChangedCompanyFieldsResponse,
  ChangedCompanyFieldsPayload,
  ChangedCompanyField,
} from './interfaces';
import * as actions from './actions';

const initialState: ChangedCompayFieldsState = {
  data: [],
  count: 0,
  error: '',
  loading: false,
  pagination: {
    pageNumber: 1,
    pageSize: 25,
  },
  hasReachedResultsEnd: false,
  searchQuery: undefined,
  startDate: undefined,
  endDate: undefined,
  fieldsToFilter: 0,
  internal: undefined,
  optx: undefined,
};

const fetchChangedCompanyFieldsReducer: CaseReducer<
  ChangedCompayFieldsState,
  PayloadAction<ChangedCompanyFieldsResponse, any, ChangedCompanyFieldsPayload>
> = (draftState, action) => {
  const fieldsToOmit = ['Ebitda Numeric High', 'Last Rev Update Amount High'];

  const results = action.payload.results.filter(
    field => !fieldsToOmit.includes(field.field_changed)
  );

  if (!draftState.data) {
    draftState.data = results;
  } else if (draftState.pagination.pageNumber < action.meta.pagination.pageNumber) {
    draftState.data = [...draftState.data, ...results];
  } else {
    draftState.data = results;
  }

  if (draftState.data.length > 0 && results.length === 0) {
    draftState.hasReachedResultsEnd = true;
  }

  draftState.loading = false;
  draftState.searchQuery = action.meta.field;
  draftState.startDate = action.meta.startDate;
  draftState.endDate = action.meta.endDate;
  draftState.count = action.payload.count;
  draftState.pagination = action.meta.pagination;
  draftState.internal = action.meta.internal;
  draftState.optx = action.meta.optx;
};

const editAuditTrialFieldsReducer: CaseReducer<
  ChangedCompayFieldsState,
  PayloadAction<ChangedCompanyField>
> = (draftState, { payload }) => {
  if (payload.before_value !== payload.after_value) {
    draftState.data = [payload, ...draftState.data];

    draftState.count++;
  }
};

const clearCompanyFieldsReducer: CaseReducer<ChangedCompayFieldsState> = draftState => {
  draftState.data = [];
  draftState.count = 0;
  draftState.hasReachedResultsEnd = false;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.fetchChangedCompanyFields, fetchReducer)
    .addCase(actions.fetchChangedCompanyFieldsSuccess, fetchChangedCompanyFieldsReducer)
    .addCase(actions.fetchChangedCompanyFieldsFail, fetchFailReducer)
    .addCase(actions.editCompanyAuditTrailFields, editAuditTrialFieldsReducer)
    .addCase(actions.clearCompanyFields, clearCompanyFieldsReducer)
);

export default reducer;
