import { AxiosResponse } from 'axios';
import { Dictionary } from 'lodash';
import { saveAs } from 'file-saver';
import { SourcingInfo } from '@optx/screens/App/AllReports/components/CompanySearchDropdown/SearchMenu/interfaces';
import NotificationService from '../services/NotificationService';
import { CompanyColumnType } from '../components/common/table/Companies/columns/virtual-column-config';

interface ExportCompanyColumns {
  columns: Dictionary<string>;
}

// If a column title needs to be replaced, add the dataIndex and the new title here.
const columnNamesReplace: Dictionary<string> = {
  company_url: 'URL',
  company_name: 'Company',
};

/**
 * Converts an array of dictionaries into a CSV formatted string.
 *
 * @template T - The type of the values in the dictionary.
 * @param {Array<Dictionary<T>>} data - The array of dictionaries to be converted to CSV.
 * @returns {string} - The CSV formatted string representing the input data.
 */
const convertToCsv = <T>(data: Array<Dictionary<T>>) => {
  const csvRows = [];

  // Add header row
  const headers = Object.keys(data[0]);
  csvRows.push(headers.join(','));

  // Add data rows
  for (const row of data) {
    const values = headers.map(header => `"${row[header]}"`);
    csvRows.push(values.join(','));
  }

  return csvRows.join('\r\n');
};

/**
 * Exports the provided data to a CSV file.
 *
 * @template T - The type of data to be exported
 * @param payload - An array of dictionaries containing the data to be exported
 * @returns A Blob representing the CSV file
 */
export const exportDataToCSV = <T>(payload: Array<Dictionary<T>>, fileName: string) => {
  const csvString = convertToCsv(payload);

  const blob = new Blob([csvString], { type: 'text/csv' });

  return saveAs(blob, `${fileName} - errors.csv`);
};

export const exportFileCSV = (
  fileName: string,
  filtersSearchCriteria: Dictionary<string>,
  columns: CompanyColumnType[],
  exportToCSV: (
    filters: Dictionary<string>,
    body: {
      columns: Dictionary<string>;
    },
    sourcingInfo?: SourcingInfo
  ) => Promise<AxiosResponse<string>>,
  includeDescription = false,
  hideNotification = false,
  sourcingOutReachInfo?: SourcingInfo
) => {
  const gridHeaders: ExportCompanyColumns = { columns: {} };
  // get the homepage table headers
  columns.forEach(column => {
    if (
      column.dataIndex !== 'row_actions' &&
      !(column.dataIndex === 'company_description' && !includeDescription)
    ) {
      const headerKey = column.dataIndex as string;
      const title = columnNamesReplace[column.dataIndex as string]
        ? columnNamesReplace[column.dataIndex as string]
        : column.title;

      gridHeaders.columns[headerKey] = title as string;

      if (includeDescription && column.dataIndex === 'company_name') {
        gridHeaders.columns.company_description = 'Description';
      }

      if (column.dataIndex === 'last_touch_date') {
        gridHeaders.columns.last_touch_initiator_name = 'Last Touch by (Initiator)';
        gridHeaders.columns.last_touch_type = 'Last Touch Type';
      }

      if (column.dataIndex === 'next_touch_date') {
        gridHeaders.columns.next_touch_initiator_name = 'Future Touch by (Initiator)';
        gridHeaders.columns.next_touch_type = 'Future Touch Type';
      }
    }
  });

  // Manually added et_company_id column to export
  gridHeaders.columns.et_company_id = 'EquityTouch UID';

  // include primary contact data
  gridHeaders.columns.pc_first_name = 'Primary contact first name';
  gridHeaders.columns.pc_last_name = 'Primary contact last name';
  gridHeaders.columns.pc_title = 'Primary contact title';
  gridHeaders.columns.pc_email = 'Primary contact email';
  gridHeaders.columns.pc_phone = 'Primary Contact Phone';
  gridHeaders.columns.pc_linkedin = 'Primary Contact Linkedin';

  return exportToCSV(filtersSearchCriteria, gridHeaders, sourcingOutReachInfo)
    .then((response: AxiosResponse<string>) => {
      const blob = new Blob([response.data], { type: 'text/csv' });
      saveAs(blob, `${fileName}.csv`);
      if (!hideNotification) NotificationService.success('Export CSV file success!');
    })
    .catch(() => {
      NotificationService.error('Export CSV file error');
    });
};
