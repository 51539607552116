import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// models
import { GridKeys } from '@optx/models/grid';
// constants
import { defaultOptxView } from '@optx/constants/table/columnDisplay/company-search';
// redux
import { selectors as savedSearchesSelectors } from '@redux/company/saved-searches';
import { getViewLabel } from '@optx/features/grid/view/state/selectors';
import { actions as viewActions } from '@features/grid/view';
import { selectors as suggestedSearchesSelectors } from '@redux/company/suggested-searches';

const useRefreshView = (gridKey: GridKeys) => {
  const dispatch = useDispatch();

  const searchTitle = useSelector(getViewLabel(gridKey));
  const allSavedSearches = Object.keys(useSelector(savedSearchesSelectors.getTitles));
  const allSugestedSearchesTitles = useSelector(
    suggestedSearchesSelectors.getSuggestedSearchesTitleList
  );
  const { views } = useSelector(savedSearchesSelectors.getViews);
  const viewData = views.find(view => view.is_default === true) || views[0];

  useEffect(() => {
    if (
      searchTitle &&
      !allSavedSearches.includes(searchTitle) &&
      !allSugestedSearchesTitles.includes(searchTitle) &&
      searchTitle !== defaultOptxView.title
    ) {
      dispatch(
        viewActions.applyView({
          gridKey: gridKey,
          data: { ...viewData },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useRefreshView;
