import React, { Dispatch, SetStateAction, useRef } from 'react';
import { Button, Card, Row, Col, Typography } from 'antd';
import { Dictionary } from 'lodash';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
// models
import { EquityTouchFetchedField, EquityTouchCheck } from '@models/equityTouch';
import { SelectOption } from '@models/Option';
import { ProductCategory } from '@optx/models/Company';
// constants
import { companyInfo, psgContext } from '@constants/equitytouch';
import appRoutes from '@constants/routes';
// utils
import { parseInitialFormData, equityTouchValidationSchema } from '@utils/equityTouch';
import { getCompanyProfileURL, getOriginPath } from '@optx/utils/routes';
// redux
import { useSelector } from 'react-redux';
import { selectors } from '@redux/company/equity-touch';
// components
import { FormFields } from '@features/company/add-to-equity-touch';
import AutoSave from '@components/common/AutoSave';
import SimilaritiesModal from './SimilaritiesModal';
// styled Components
import Styles from './EquityTouch.style';

interface EquityTouchFormProps {
  initialFormData: Dictionary<EquityTouchFetchedField>;
  availability?: Dictionary<EquityTouchCheck>;
  id: string;
  handleSubmit: (
    formData: Dictionary<
      string | number | boolean | number[] | string[] | null | SelectOption | ProductCategory[]
    >
  ) => void;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

const EquityTouchForm: React.FC<EquityTouchFormProps> = ({
  initialFormData,
  availability,
  id,
  handleSubmit,
  showModal,
  setShowModal,
}) => {
  const formRef = useRef<any>(null);
  const temporaryFormValues = useSelector(selectors.getTemporaryFormValues);
  const history = useHistory();

  const setActiveAddon = () => {
    formRef?.current?.setTouched({ 'Add-On For': true });
    formRef?.current?.setTouched({ 'Add-On': true });
  };

  const handleCancelClick = () => {
    if (getOriginPath(document.referrer) === appRoutes.chromeExtension)
      history.push(getCompanyProfileURL(Number(id)));
    else history.goBack();
  };

  if (temporaryFormValues && temporaryFormValues['Add-On For']) {
    formRef?.current?.setFieldValue('Add-On For', temporaryFormValues['Add-On For']);
    setActiveAddon();
  }

  if (temporaryFormValues && temporaryFormValues['Add-On']) {
    formRef?.current?.setFieldValue('Add-On', temporaryFormValues['Add-On']);
    setActiveAddon();
  }

  return (
    <Formik
      initialValues={parseInitialFormData(initialFormData)}
      onSubmit={handleSubmit}
      validateOnBlur
      validateOnChange
      validationSchema={equityTouchValidationSchema}
      enableReinitialize
      innerRef={instance => {
        formRef.current = instance;
      }}
    >
      <Form layout="vertical">
        <AutoSave valuesSelector={selectors.getFormValues} formDataSelector={selectors.getData} />
        <Row justify="space-between" align="bottom" className="add-to-equity-touch__heading">
          <Col>
            <Typography.Title level={4} className="add-to-equity-touch__title">
              Add to Equity Touch
            </Typography.Title>
          </Col>
          <Col>
            <Form.Item name="add-to-et-btn">
              <Styles.Cancel onClick={handleCancelClick}>Cancel</Styles.Cancel>
              <Button
                type="primary"
                onClick={() => {
                  if (formRef?.current && !formRef?.current?.isValid) {
                    Object.keys(formRef?.current?.errors).forEach(field => {
                      formRef?.current?.setTouched({ [field]: true });
                    });
                  }

                  if (formRef && formRef?.current) {
                    formRef?.current?.validateForm(temporaryFormValues);
                  }
                }}
                htmlType="submit"
              >
                Add to Equity Touch
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20} className="add-to-equity-touch__wrapper">
          <Col xl={12} lg={24}>
            <Card title="Company Info" className="add-to-equity-touch__card">
              <FormFields fields={companyInfo} initialFormData={initialFormData} />
            </Card>
          </Col>
          <Col xl={12} lg={24}>
            <Card title="PSG Context and Rank" className="add-to-equity-touch__card">
              <FormFields fields={psgContext} initialFormData={initialFormData} />
            </Card>
          </Col>
        </Row>
        <Row />
        <SimilaritiesModal
          availability={availability}
          id={id}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </Form>
    </Formik>
  );
};

export default EquityTouchForm;
