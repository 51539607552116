import React, { ChangeEvent, useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Button, Tooltip } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import { isEqual } from 'lodash';
import moment from 'moment';
// models
import { FinancialFields, FinancialKeys } from '@optx/models/Financial';
import { FinancialFieldRangeValue, FinancialFieldValues } from '@optx/models/Company';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { FINANCIAL_KEYS } from '@optx/constants/opportunity-presentation';
import { NUMBER_FORMAT } from '@optx/constants/format/number';
import { CompanyProfileTabs } from '@optx/constants/routes';
import globalConfig from '@optx/constants/config';
// utils
import { normalizeNegativeSignValues } from '@optx/utils/utils';
import { roundNumber } from '@optx/utils/number';
import { validateField } from '@optx/utils/editFields';
import { roundingPresentationValues } from '@optx/utils/dealPresentation';
import { isOptionChecked } from '@optx/utils/option';
import { highlightText } from '@optx/utils/text';
// redux
import { actions, selectors } from '@redux/company/opportunity-presentation';
import { selectors as profileSelectors } from '@redux/company/profile';
import { getFinancialFilterOptions } from '@optx/redux/company/filters/selectors';
import { selectors as cardSelectors } from '@features/long-card/company-card';
// hooks
import { useIsChromePlugin } from '@optx/common/hooks/useIsChromePlugin';
// components
import EditPopover from '@optx/components/common/popover/EditPopover';
import InputWithDropdown from '@optx/shared/view/molecules/FormFields/InputWithDropdown';
// eslint-disable-next-line max-len
import { TooltipLink } from '@components/pages/CompanyProfile/ProfileTabs/DealPresentation/styles/DealPresentation.styled';
import { InlineEdit } from '@optx/shared/view/molecules/edit/InlineEdit';

const FINANCIAL_OPTIONS_FIELD: {
  [K in FinancialKeys]: FinancialFields;
} = {
  ARR: 'last_arr_value',
  EBITDA: 'ebitda_numeric',
  Revenue: 'last_rev_update_amount',
  'GM %': 'last_gross_margin',
};

type TableCellFinancialValueProps = {
  value: number | string | FinancialFieldRangeValue;
  growth?: number;
  title: string;
  isFromGrid?: boolean;
  updatedFieldFromGrid?: (value: FinancialFieldValues) => void;
  payload: { key: FinancialKeys; year: number; estimated: boolean; user?: string; date?: string };
};

export const TableCellFinancialValue: React.FC<TableCellFinancialValueProps> = ({
  value,
  growth,
  title,
  payload,
  isFromGrid,
  updatedFieldFromGrid,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isChromePlugin } = useIsChromePlugin();

  const company = useSelector(profileSelectors.getProfile);
  const options = useSelector(getFinancialFilterOptions(FINANCIAL_OPTIONS_FIELD[payload.key]));
  const companyCard = useSelector(cardSelectors.getProfile);
  const loading = useSelector(selectors.isLoading);

  const [closePopup, setClosePopup] = useState(false);
  const [fieldValue, setFieldValue] = useState<string | number>(DEFAULT_EMPTY_VALUE);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean | undefined>(undefined);

  const getRangeValueLabel = useCallback(
    (rangeValue: FinancialFieldRangeValue) => {
      const amountValue = options.find(option => isEqual(option.rangeValue, rangeValue))?.label;

      if (amountValue) {
        return amountValue;
        // fix for old hardcoded range values for gm to display min value
      } else if (!amountValue && payload.key === 'GM %') {
        return rangeValue.min;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  const getFieldValue = (value: string | number | FinancialFieldRangeValue) => {
    if (value === DEFAULT_EMPTY_VALUE || value === undefined) {
      return '';
    }

    if (typeof value === 'number' && payload.key === 'GM %') {
      return value;
    }

    if (typeof value === 'number' && payload.key !== 'GM %') {
      return value / 1000000;
    }

    if (typeof value === 'string' || typeof value === 'number') {
      return value;
    }

    return getRangeValueLabel(value) ?? '';
  };

  const cellValue = useMemo(() => {
    if (value === undefined || value === DEFAULT_EMPTY_VALUE) {
      return DEFAULT_EMPTY_VALUE;
    }

    if (payload.key === 'GM %' && typeof value !== 'object') {
      return `${value}%`;
    }

    if (typeof value === 'string') {
      return value;
    }

    if (typeof value === 'number') {
      return roundNumber(value, NUMBER_FORMAT).toUpperCase();
    }

    return getRangeValueLabel(value) ?? '';
  }, [value, payload.key, getRangeValueLabel]);

  let growthStatus: string;

  if (growth !== undefined && growth > 0) {
    growthStatus = 'positive';
  } else if (growth !== undefined && growth < 0) {
    growthStatus = 'negative';
  } else {
    growthStatus = 'neutral';
  }

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(event.target.value);
  };

  const handleVisibilityChange = () => {
    !loading && handleCancel();
  };

  const handleCancel = () => {
    setFieldValue(getFieldValue(value));
    setClosePopup(true);
  };

  const handleSelectOption = (event: MenuInfo) => {
    setFieldValue(event.key);
  };

  const handleSave = () => {
    const key = FINANCIAL_KEYS[payload.key];

    if (validateField(fieldValue, payload.key, options)) {
      const isFromDropdown = isOptionChecked(fieldValue, options);
      let value: string | number | FinancialFieldRangeValue = fieldValue;

      if (typeof fieldValue === 'string' && fieldValue.length === 0) {
        value = DEFAULT_EMPTY_VALUE;
      } else if (payload.key === 'GM %' && !isFromDropdown) {
        value = parseFloat(fieldValue as string);
      } else if (!isNaN(Number(fieldValue))) {
        value = roundingPresentationValues((fieldValue as number) * 1000000);
      } else if (isFromDropdown) {
        const selectedOption = options.find(option => option.label === fieldValue);

        if (selectedOption?.rangeValue) {
          value = selectedOption.rangeValue;
        }
      }

      if (isFromGrid) {
        const updatedFieldPayload = {
          financial: {
            [key as string]: {
              year: payload.year,
              estimated: payload.estimated,
              value,
            },
          },
        };
        updatedFieldFromGrid && updatedFieldFromGrid(updatedFieldPayload);
      } else {
        const payloadValue = {
          company_id: (company?.company_id as number) || (companyCard?.company_id as number),
          financial: {
            [key as string]: {
              year: payload.year,
              estimated: payload.estimated,
              value,
            },
          },
        };
        dispatch(actions.updateOpportunityPresentation(payloadValue));
      }

      setClosePopup(true);
    }
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  useEffect(() => {
    setFieldValue(getFieldValue(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const isSaveBtnDisabled = String(fieldValue) === String(getFieldValue(value));

  const handleTooltipLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setClosePopup(true);
    event.stopPropagation();
    setIsTooltipVisible(false);
    setTimeout(() => {
      setIsTooltipVisible(undefined);
    }, 0);
  };

  const tooltipLink = `${location.pathname}?referrer=%2F&tab=${CompanyProfileTabs.HISTORY}&field=${payload.key}`;

  const formattedDate = moment(payload.date).format(globalConfig.short_date.DATE_FORMAT);

  const tooltipContent = (
    <div onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => event.stopPropagation()}>
      <div>Added by: {payload.user}</div>
      <div>on {formattedDate}</div>
      {!isChromePlugin && (
        <TooltipLink to={tooltipLink} onClick={handleTooltipLinkClick}>
          See full audit trail
        </TooltipLink>
      )}
    </div>
  );

  const valueElement = (
    <span style={highlightText(cellValue.toString())} className="value">
      {normalizeNegativeSignValues(cellValue.toString())}
    </span>
  );

  const content = (
    <div
      onKeyDown={handleDropdownKeyEvent}
      tabIndex={0}
      className={FINANCIAL_KEYS[payload.key] === 'ebitda' ? 'ebitda-value' : ''}
    >
      <InputWithDropdown
        value={(fieldValue as string)?.toString()}
        options={options}
        placeholder={FINANCIAL_KEYS[payload.key] !== 'gm' ? 'in millions of dollars' : title}
        onInputChange={handleChangeInput}
        onSelectOption={handleSelectOption}
        allowClear
        closeOnSelect
      />

      <div className="profile-information__popover-buttons">
        <Button className="profile-information__cancel" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className="profile-information__save"
          type="primary"
          onClick={handleSave}
          disabled={isSaveBtnDisabled}
        >
          Save
        </Button>
      </div>
    </div>
  );

  if (isFromGrid) {
    return (
      <InlineEdit
        action={
          <EditPopover
            content={content}
            title={title}
            closePopup={closePopup}
            setClosePopup={setClosePopup}
            placement="topRight"
            onVisibilityChange={handleVisibilityChange}
            destroyOnHide
            customOverlayStyle={{ width: '385px' }}
          ></EditPopover>
        }
        fill
      >
        <span className="financial-value">
          <span className="value">{normalizeNegativeSignValues(cellValue.toString())}</span>
          {growth !== undefined && (
            <div>
              (
              <span className={`growth ${normalizeNegativeSignValues(growthStatus.toString())}`}>
                {`${
                  growthStatus === 'negative'
                    ? normalizeNegativeSignValues(growth.toString())
                    : normalizeNegativeSignValues(growth.toString()) ?? 0
                }% `}
              </span>
              {payload.key === 'EBITDA' ? 'Margin' : 'Growth'})
            </div>
          )}
        </span>
      </InlineEdit>
    );
  }

  return (
    <EditPopover
      content={content}
      title={title}
      closePopup={closePopup}
      setClosePopup={setClosePopup}
      placement="topRight"
      onVisibilityChange={handleVisibilityChange}
      destroyOnHide
      customOverlayStyle={{ width: '385px' }}
    >
      <span className="financial-value">
        {cellValue !== DEFAULT_EMPTY_VALUE ? (
          <Tooltip title={tooltipContent} trigger={['click', 'hover']} visible={isTooltipVisible}>
            {valueElement}
          </Tooltip>
        ) : (
          valueElement
        )}
        {growth !== undefined && (
          <div>
            (
            <span className={`growth ${normalizeNegativeSignValues(growthStatus.toString())}`}>
              {`${
                growthStatus === 'negative'
                  ? normalizeNegativeSignValues(growth.toString())
                  : normalizeNegativeSignValues(growth.toString()) ?? 0
              }% `}
            </span>
            {payload.key === 'EBITDA' ? 'Margin' : 'Growth'})
          </div>
        )}
      </span>
    </EditPopover>
  );
};
