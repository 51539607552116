import { takeLatest, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
// models
import { SearchViewType } from '@optx/models/user';
import Company from '@optx/models/Company';
import { GridKeys, GridPayloadMain } from '@optx/models/grid';
// constants
import { LONG_CARD_VIEW_DEFAULT_PAGE_SIZE } from '@optx/constants/pagination';
// utils
import { getErrorMessage } from '@utils/api/errors';
// redux
import { actions as employeGrowthActions } from '@features/company/graphs/employee';
import { actions as scoreGrowthActions } from '@features/company/graphs/score';
import { actions as fundingRoundsActions } from '@redux/company/funding-rounds';
import { actions as userInformationActions } from '@redux/user/information';
import {
  selectors as paginationSelectors,
  actions as paginationActions,
} from '@features/grid/pagination';
import { selectors as searchSelectors } from '@features/grid/search';
import * as actions from './actions';
import * as selectors from './selectors';
import { actions as longCardPaginationActions } from '@features/grid/pagination-long-card';
import { actions as toggleViewTransitionActions } from '@optx/features/grid/view-transition';

function* toggleSearchViewSaga(action: PayloadAction<GridPayloadMain<SearchViewType>>) {
  const { gridKey, data: newView } = action.payload;
  const oldView: SearchViewType = yield select(selectors.searchView(gridKey));

  let paginationLongCardActions: any;

  if (gridKey === 'advancedSearch') {
    paginationLongCardActions = longCardPaginationActions.changeLongCardPagination({
      gridKey: 'advancedSearch',
      data: { pageNumber: 1, pageSize: LONG_CARD_VIEW_DEFAULT_PAGE_SIZE },
    });
  } else if (gridKey === 'lists') {
    paginationLongCardActions = longCardPaginationActions.changeLongCardPagination({
      gridKey: 'lists',
      data: { pageNumber: 1, pageSize: LONG_CARD_VIEW_DEFAULT_PAGE_SIZE },
    });
  } else if (gridKey === 'myCompanies') {
    paginationLongCardActions = longCardPaginationActions.changeLongCardPagination({
      gridKey: 'myCompanies',
      data: { pageNumber: 1, pageSize: LONG_CARD_VIEW_DEFAULT_PAGE_SIZE },
    });
  } else if (gridKey === 'outreach') {
    paginationLongCardActions = longCardPaginationActions.changeLongCardPagination({
      gridKey: 'outreach',
      data: { pageNumber: 1, pageSize: LONG_CARD_VIEW_DEFAULT_PAGE_SIZE },
    });
  }

  const listsGridKey = (
    window.location.pathname.includes('ss-lists')
      ? 'sslists'
      : gridKey === 'lists'
      ? 'watchlists'
      : gridKey
  ) as GridKeys;

  const { pageNumber, pageSize } = yield select(paginationSelectors.getPagination(listsGridKey));

  const companies: Company[] = yield select(searchSelectors.getCompanies(gridKey));

  try {
    if (newView !== oldView) {
      if (newView === 'table') {
        if (companies.length !== pageSize || pageNumber !== 1) {
          yield put(
            paginationActions.changePagination({
              gridKey: listsGridKey,
              data: { pageNumber: 1, pageSize },
            })
          );
          yield put(paginationLongCardActions);
        }
      } else if (companies.length > LONG_CARD_VIEW_DEFAULT_PAGE_SIZE || pageNumber !== 1) {
        yield put(toggleViewTransitionActions.toggleViewTransition(true));
        yield put(paginationLongCardActions);

        if (companies.length > 0) {
          yield put(employeGrowthActions.fetchEmployeeGrowth(companies[0].company_id));
          yield put(
            scoreGrowthActions.fetchScoreGrowth({
              company_id: companies[0].company_id,
              company_url: companies[0].company_url,
            })
          );
          yield put(fundingRoundsActions.getCompanyFundingRounds(companies[0].company_id));
        }
      }

      yield put(actions.toggleSearchViewSuccess({ gridKey: gridKey, data: newView }));

      let newViewKeys: string;

      if (gridKey === 'advancedSearch') {
        newViewKeys = 'search_view_home';
      } else if (gridKey === 'lists') {
        newViewKeys = 'search_view_list';
      } else if (gridKey === 'myCompanies') {
        newViewKeys = 'search_view_my_companies';
      } else {
        newViewKeys = 'sourcing_outreach_view_home';
      }

      yield put(userInformationActions.updateUserSettings({ [newViewKeys]: newView }));
    }
  } catch (error: any) {
    const defaultError = 'Failed to Save Search View Type';
    getErrorMessage(error, defaultError);
  }
}

export default function* SearchViewSaga() {
  yield takeLatest(actions.toggleSearchView, toggleSearchViewSaga);
}
