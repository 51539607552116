import { createSelector } from 'reselect';
// models
import { AppState } from '@optx/redux/interfaces';
import { SearchPayload } from '@optx/models/api/contacts';
import { SpecialFilterTag } from '@optx/models/tags';
// utils
import mapTags from '@optx/utils/filters/mapTags';
import { generateUniqueKey } from '@optx/utils/uuid';
import { sortColumns } from '@utils/table/sorting';
// redux
import { selectors as userSelectors } from '@redux/user/information';
import { getDefaultScore } from '@optx/redux/user/information/selectors';
import { selectors as filterSourcesSelectors } from '../../filters';
import { getSearchCount } from '@optx/features/grid/search-count/state/selectors';
import { selectors as paginationSelectors } from '@features/grid/pagination';
// components
import defaultColumns from '../../../../components/common/table/TableContactsSearch/defaultColumns';
import { getSearchKey as searchKey } from '@optx/features/grid/searchkey/state/selectors';

const selectContactsSearchState = (state: AppState) => state.contactsSearch.search;

export const getError = createSelector(selectContactsSearchState, state => state.error);

export const isInitialFetch = createSelector(
  selectContactsSearchState,
  state => !state.fetchedAt && !state.loading && !state.error
);

export const isLoading = createSelector(selectContactsSearchState, state => state.loading);

export const getPagination = createSelector(selectContactsSearchState, state => state.pagination);

export const getContacts = createSelector(selectContactsSearchState, state => {
  const { byId, allIds } = state;

  return allIds.map(contactId => byId[contactId]);
});

export const getSearchKey = searchKey('contacts');

const selectSearchState = (state: AppState) => state.contactsSearch;

export const getSorting = createSelector(selectSearchState, state => state.sort.sortBy);

export const isMultiSort = createSelector(selectSearchState, state => state.sort.multiSort);

export const getFilter = createSelector(selectSearchState, state => state.filter.filter);

export const getData = createSelector(selectSearchState, state => state.filter.data);

export const getClearedFilter = createSelector(selectSearchState, state => state.filter.clear);

export const getSearchTitle = createSelector(selectSearchState, state => state.search.searchTitle);

export const getTags = createSelector(
  getFilter,
  getData,
  getClearedFilter,
  getSearchKey,
  (filter, sources, clearedFilter, searchKey) => {
    const tags = mapTags(filter, sources, clearedFilter);

    if (searchKey) {
      tags.unshift({
        filter: 'keyword' as SpecialFilterTag,
        uniqueKey: generateUniqueKey(),
        label: 'Keyword',
        values: [
          {
            value: searchKey,
          },
        ],
      });
    }

    return tags;
  }
);

export const getSearchData = createSelector(
  paginationSelectors.getPagination('contacts'),
  getSearchCount('contacts'),
  getSorting,
  getFilter,
  getSearchKey,
  getSearchTitle,
  (pagination, total, sortBy, filter, searchKey, searchTitle) => {
    const data: SearchPayload = {
      pagination,
      total,
      sortBy,
      filter,
      searchKey,
      searchTitle,
    };

    return data;
  }
);

export const shouldInitialFetch = createSelector(
  userSelectors.loaded,
  filterSourcesSelectors.loaded,
  isInitialFetch,
  (userInfoLoaded, filtersLoaded, shouldFetch) => userInfoLoaded && filtersLoaded && shouldFetch
);

export const getGridColumns = createSelector(
  getSorting,
  isMultiSort,
  getDefaultScore,
  (sorting, isMultiSort, defaultOptxScore) =>
    sortColumns(defaultColumns, sorting, defaultOptxScore, { isMultiSort })
);
