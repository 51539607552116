import React, { ReactNode } from 'react';
// redux
import Company, { CompanyProfile } from '@optx/models/Company';
// hooks
import useIsAdminOrAnalyst from '@optx/common/hooks/useisAdminOrAnalyst';
// components
import EditFieldOPTX from '@optx/features/company/edit-fields/components/EditFieldOPTX';

interface TableCellScoreAdminProps {
  record: Company | CompanyProfile;
  children: ReactNode;
}

const TableCellScoreAdmin: React.FC<TableCellScoreAdminProps> = ({ record, children }) => {
  const isAdminOrAnalyst = useIsAdminOrAnalyst();

  if (isAdminOrAnalyst) {
    return (
      <EditFieldOPTX record={record} isFromGrid>
        {children}
      </EditFieldOPTX>
    );
  }

  return <>{children}</>;
};

export default React.memo(TableCellScoreAdmin);
