import { createSelector } from 'reselect';
// models
import { Column } from '@optx/models/table/Columns';
import { SearchState } from '../interfaces';

export const getData = (state: SearchState) => state.data;
export const getLoading = (state: SearchState) => state.loading;
export const getError = (state: SearchState) => state.error;
export const getColumnDisplay = (state: SearchState) => state.columnDisplay;
export const getColumnOrder = (state: SearchState) => state.columnOrder;

// get list of columns separated into pinned columns and sortable columns
export const getColumnLists = createSelector(
  getColumnDisplay,
  getColumnOrder,
  (columnDisplay, columnOrder) => {
    const sortableColumns: Column[] = [];

    columnOrder.forEach(columnId => {
      const column = {
        id: columnId,
        name: (columnDisplay[columnId] as Column).name,
        visible: (columnDisplay[columnId] as Column).visible,
        width: (columnDisplay[columnId] as Column).width,
      };

      sortableColumns.push(column);
    });

    return sortableColumns;
  }
);
