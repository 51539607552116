import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { ChartData } from '@optx/models/charts/data';
import { FETCH_EMPLOYEE_GROWTH } from './actionTypes';
import { fetchCompanyEmployeeGrowthSuccess, fetchCompanyEmployeeGrowthFail } from './actions';
import { CompanyService } from '../../../services/api';
import NotificationService from '../../../services/NotificationService';
import { FetchCompanyEmployeeGrowthAction } from './interfaces';

export function* fetchCompanyEmployeeGrowthSaga(action: FetchCompanyEmployeeGrowthAction) {
  const { payload: companyId, meta: callback } = action;

  try {
    const res: AxiosResponse<ChartData> = yield call(CompanyService.getEmployeeGrowth, companyId);

    if (res.data) {
      yield put(fetchCompanyEmployeeGrowthSuccess(res.data));
      callback && callback(res.data as ChartData);
    } else {
      const errorMessage = 'Failed to fetch Employee Growth!';
      yield put(fetchCompanyEmployeeGrowthFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = 'Failed to fetch Employee Growth!';
    yield put(fetchCompanyEmployeeGrowthFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* companyEmployeeGrowthSaga() {
  yield takeLatest(FETCH_EMPLOYEE_GROWTH, fetchCompanyEmployeeGrowthSaga);
}
