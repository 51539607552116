import queryString from 'query-string';
import { Dictionary } from 'lodash';
import { AxiosRequestConfig } from 'axios';
// models
import { SearchPayloadRequest } from '@optx/models/api/contacts';
import { SaveSearchAsListPayload } from '@optx/models/api/WatchLists';
import { CompanyWatchList } from '@optx/models/WatchList';
import { SortByRule } from '@optx/models/table/sorting';
import Company from '@optx/models/Company';
import { ColumnKeys } from '@optx/models/table/Columns';
import {
  ListsSearchData,
  FavoriteListsSearchData,
  InitialFavoriteListsSearchData,
} from '@optx/models/lists/search';
// utils
import { cleanObjectWithEmptyValues } from '@optx/utils/filters/filterHelpers';
import { mapSearchQuery, mapSortQuery } from '@optx/utils/search';
// local
import ApiClient, { API_URL } from './axios-client';

class SavedSearchesService {
  static client: ApiClient = new ApiClient(`${API_URL}/user/lists`);

  // company saved search
  static getCompanySearches(searchData: ListsSearchData | undefined) {
    const payload = {
      ...(searchData && searchData.query && { query: searchData!.query }),
      ...(searchData && searchData.pagination && { page: searchData.pagination.pageNumber }),
      ...(searchData && searchData.pagination && { per_page: searchData.pagination.pageSize }),
      ...(searchData && searchData.sortBy?.length && { sort_by: searchData?.sortBy?.[0]?.id }),
      ...(searchData &&
        searchData.sortBy?.length && { sort_order: searchData.sortBy[0].desc ? 'desc' : 'asc' }),
    };

    const query = queryString.stringify(payload);

    return SavedSearchesService.client.get(`/overview?${query}`);
  }

  static createCompanySearch(
    title: string,
    searchKey: string,
    searchCount: number,
    filters?: Dictionary<string | Array<number | string>>,
    sorting?: SortByRule<Company>[],
    columns?: string,
    columnOrder?: ColumnKeys[],
    pinnedColumns?: string,
    isDefault?: boolean,
    id?: number,
    newTitle?: string,
    defaultOptx?: string
  ) {
    let sortQuery = {};
    let lastPinnedColumns;
    let lastColumns;
    let lastColumnOrder;

    if (sorting) {
      sortQuery = mapSortQuery(sorting);
    }

    if (defaultOptx === 'il') {
      lastColumns = columns?.split(',').filter((item: string) => {
        if (item === 'score') {
          return false;
        }

        return true;
      });

      lastPinnedColumns = pinnedColumns?.split(',').filter((item: string) => {
        if (item === 'score') {
          return false;
        }

        return true;
      });
      lastColumnOrder = columnOrder?.filter((item: string) => {
        if (item === 'score') {
          return false;
        }

        return true;
      });
    }

    if (defaultOptx !== 'il') {
      lastColumns = columns?.split(',').filter((item: string) => {
        if (item === 'il_optx_score') {
          return false;
        }

        return true;
      });

      lastPinnedColumns = pinnedColumns?.split(',').filter((item: string) => {
        if (item === 'il_optx_score') {
          return false;
        }

        return true;
      });
      lastColumnOrder = columnOrder?.filter((item: string) => {
        if (item === 'il_optx_score') {
          return false;
        }

        return true;
      });
    }

    const queryPayload = {
      ...(searchKey ? { query: searchKey } : {}),
      ...sortQuery,
      columns: lastColumns?.join() ?? null,
      column_order: lastColumnOrder?.join() ?? null,
      pinned_columns: lastPinnedColumns?.join() ?? null,
      // for a saved search to be unset as default, send empty value for is_default
      is_default: isDefault || '',
      unique_id: id,
      number_of_results: searchCount,
      new_list_title: newTitle,
    };

    const appliedFilters = cleanObjectWithEmptyValues({ ...filters });
    const data = {
      ...appliedFilters,
      ...queryPayload,
      list_title: decodeURIComponent(title),
    };

    return SavedSearchesService.client.post('', data, {
      headers: {
        'content-type': 'application/json',
      },
    });
  }

  static renameCompanySearch(listId: number | string, title: string) {
    const payload = {
      title,
      list_id: listId,
    };

    return SavedSearchesService.client.put('/title', payload);
  }

  static deleteCompanySearch(id: number) {
    const payload = {
      list_id: id,
    };

    return SavedSearchesService.client.delete(`?${queryString.stringify(payload)}`);
  }

  // lists
  /**
   * Fetch favorite lists except data for predefined favorite list.
   */

  static initialFetchFavoriteLists(searchData: InitialFavoriteListsSearchData | null) {
    const payload = {
      ...(searchData && { show_empty_lists: searchData.showEmptyLists }),
      ...(searchData && searchData.sortBy?.length && { sort_by: searchData.sortBy[0].id }),
      ...(searchData &&
        searchData.sortBy?.length && { sort_order: searchData.sortBy[0].desc ? 'desc' : 'asc' }),
      ...(searchData && { show_empty_lists: searchData.showEmptyLists }),
      ...(searchData && searchData.popup && { popup: searchData.popup }),
    };

    const query = queryString.stringify(payload);

    return SavedSearchesService.client.get(`/favorites/overview?${query}`);
  }

  static getFavoriteLists(searchData: FavoriteListsSearchData | null, excludeCompanyId?: number) {
    let payload;

    if (excludeCompanyId) {
      payload = { exclude_company_id: excludeCompanyId };
    } else if (searchData?.fetchAll && searchData?.sortBy) {
      const payload = {
        ...(searchData!.query && { query: searchData!.query }),
        ...(searchData && searchData.sortBy.length && { sort_by: searchData.sortBy[0].id }),
        ...(searchData && searchData.pagination && { page: searchData.pagination.pageNumber }),
        ...(searchData && searchData.pagination && { per_page: searchData.pagination.pageSize }),
        ...(searchData &&
          searchData.sortBy.length && { sort_order: searchData.sortBy[0].desc ? 'desc' : 'asc' }),
        ...(searchData && { show_empty_lists: searchData.showEmptyLists }),
      };
      const query = queryString.stringify(payload);

      return SavedSearchesService.client.get(`/favorites/overview?${query}`);
    } else {
      payload = {
        ...(searchData!.query && { query: searchData!.query }),
        ...(searchData && searchData.pagination && { page: searchData.pagination.pageNumber }),
        ...(searchData && searchData.pagination && { per_page: searchData.pagination.pageSize }),
        ...(searchData && searchData.sortBy?.length && { sort_by: searchData.sortBy[0].id }),
        ...(searchData &&
          searchData.sortBy?.length && { sort_order: searchData.sortBy[0].desc ? 'desc' : 'asc' }),
        ...(searchData && { show_empty_lists: searchData.showEmptyLists }),
      };
    }

    const query = queryString.stringify(payload);

    return SavedSearchesService.client.get(`/favorites/overview?${query}`);
  }

  static getFavoriteByTitle(title: string) {
    const payload = {
      title,
    };
    const query = queryString.stringify(payload);

    return SavedSearchesService.client.get(`/favorites?${query}`);
  }

  // TODO: Change this when the API is ready
  // static getFavoriteById(id: number) {
  //   const payload = { list_id: id };
  //   const query = queryString.stringify(payload);

  //   return SavedSearchesService.client.get(`/favorites?${query}`);
  // }

  static deleteFavoriteList(listId: number) {
    const queryPayload = {
      list_id: listId,
    };

    return SavedSearchesService.client.delete(`/favorites?${queryString.stringify(queryPayload)}`);
  }

  static renameFavoriteList(payload: { title: string; list_id: number }) {
    return SavedSearchesService.client.put(`/favorites/title?${queryString.stringify(payload)}`);
  }

  /**
   * Create a list or add company to existing list.
   * @param payload
   */
  static addCompanyToList(payload: {
    title: string;
    company_id: number;
    error_on_conflict?: boolean;
  }) {
    const config: AxiosRequestConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    };
    const query = queryString.stringify(payload);

    return SavedSearchesService.client.put(`/favorites?${query}`, {}, config);
  }

  static deleteCompanyInList(payload: { list_id: number[]; company_id: number }) {
    return SavedSearchesService.client.delete(
      `/favorites/company?${queryString.stringify(payload, { arrayFormat: 'comma' })}`
    );
  }

  /**
   * Save search as a named list.
   * @param title list title.
   * @param searchData search parameterss.
   */
  static saveSearchAsList(title: string, searchData: SearchPayloadRequest) {
    const payload = mapSearchQuery(searchData) as unknown as SaveSearchAsListPayload;
    payload.list_title = title;

    return SavedSearchesService.client.post<CompanyWatchList>(
      `/save_search?${queryString.stringify(payload, { arrayFormat: 'comma' })}`
    );
  }

  static getCompanyListByTitle = (title: string) =>
    SavedSearchesService.client.get(`?title=${title}`);

  // contacts
  static getContactSearches(searchData: ListsSearchData) {
    const payload = {
      ...(searchData.query && { query: searchData!.query }),
      ...(searchData.pagination && { page: searchData.pagination.pageNumber }),
      ...(searchData.pagination && { per_page: searchData.pagination.pageSize }),
    };

    const query = queryString.stringify(payload);

    return SavedSearchesService.client.get(`/contacts/overview?${query}`);
  }

  static createContactSearch(
    title: string,
    searchKey: string,
    filters: Dictionary<string | Array<number | string>>
  ) {
    const queryPayload = {
      ...filters,
      ...(searchKey ? { query: searchKey } : {}),
    };
    let query = queryString.stringify(queryPayload, { arrayFormat: 'comma' });
    // list_title needs to be at the end of the query due to API constraints.
    query += `&list_title=${encodeURIComponent(title)}`;

    const config: AxiosRequestConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    };

    return SavedSearchesService.client.put(`/contacts?${query}`, {}, config);
  }

  static renameContactSearch(listId: number | string, title: string) {
    const payload = {
      title,
      list_id: listId,
    };

    return SavedSearchesService.client.put('/contacts/title', payload);
  }

  static deleteContactSearch(id: number | string) {
    const payload = {
      list_id: id,
    };

    return SavedSearchesService.client.delete(`/contacts?${queryString.stringify(payload)}`);
  }

  // suggested searches
  static getSuggestedSearches = () => SavedSearchesService.client.get('/system/overview');
}

export default SavedSearchesService;
