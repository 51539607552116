import React from 'react';
import { Tag } from 'antd';
import { TagProps } from 'antd/lib/tag';
import { useDispatch, useSelector } from 'react-redux';
// utils
import { searchInNewTab } from '@optx/utils/search';
// redux
import { actions as searchActions } from '@features/grid/search';
import { selectors as filterSourcesSelectors } from '@redux/company/filters';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';

interface IsSoftwareTagProps extends TagProps {
  isSoftware: boolean;
  newTab?: boolean;
}

const IsSoftwareTag: React.FC<IsSoftwareTagProps> = ({
  isSoftware,
  newTab,
  onClick,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const clearedFilter = useSelector(filterSelectors.getClearedFilter('advancedSearch'));
  const sources = useSelector(filterSourcesSelectors.getFiltersMap);
  const { advancedSearchPathname } = useIsAnalyst();

  if (!isSoftware) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (newTab) {
      searchInNewTab(clearedFilter, sources, 'is_software', '1', {}, advancedSearchPathname);
    } else {
      dispatch(
        searchActions.loadCompaniesByFilter('is_software', { gridKey: 'advancedSearch', data: '1' })
      );

      onClick && onClick(event);
    }
  };

  return (
    <Tag className="special-tag show-child" onClick={handleClick} {...restProps}>
      Software
    </Tag>
  );
};

export default IsSoftwareTag;
