import { takeLatest, put, fork, take } from 'redux-saga/effects';
// redux
import { actions } from '../actions';
import { saga as featuresSagas } from './sagas';

function* initSaga() {
  yield put(actions.filters.fetchFilters());
  yield put(actions.company.fetchCompanyActivity());
  yield put(actions.insights.fetchInsightsStats());
  yield take(actions.filters.fetchFiltersSuccess);
  yield put(actions.activity.fetchInitActivity());
  yield put(actions.activity.fetchInitTouchActivity());
}

export function* saga() {
  yield takeLatest(actions.init, initSaga);
  yield fork(featuresSagas);
}
