import { createAction } from '@reduxjs/toolkit';
import { CompanyProfile, PluginPrimaryContact } from '@optx/models/Company';
import * as types from './types';

export const fetchCompanyProfile = createAction(
  types.fetchCompany.REQUEST,
  (companyId: number, callback?: (data: CompanyProfile) => void) => ({
    payload: companyId,
    meta: callback,
  })
);

export const fetchCompanyProfileSuccess = createAction<CompanyProfile>(types.fetchCompany.SUCCESS);

export const fetchCompanyProfileFail = createAction<string>(types.fetchCompany.FAILURE);

export const fetchPluginPrimaryContact = createAction<{
  companyId: number;
}>(types.fetchPluginPrimaryContact.REQUEST);

export const fetchPluginPrimaryContactSuccess = createAction<PluginPrimaryContact>(
  types.fetchPluginPrimaryContact.SUCCESS
);

export const fetchPluginPrimaryContactFail = createAction<string>(
  types.fetchPluginPrimaryContact.FAILURE
);
