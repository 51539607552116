import React from 'react';
import { useMount } from '@umijs/hooks';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
// constants
import { cardRowGutter } from '@optx/constants/spacing';
// redux
import { CompaniesInETTable, actions, useInject } from '@optx/features/equity-touch-integration';
// hooks
import { useInject as useInjectAnalystsLeaderboard } from '@optx/components/pages/AnalystsLeaderboard/hooks';
// components
import { PrivateComponent } from '@optx/components/common/PrivateComponent';
import Card from '@components/common/cards';
import {
  NewCompaniesAddedBarChart,
  FilterSingleSelectDateFilter,
  FiltersModal,
  Header,
  CompaniesInETPagination,
} from '@features/equity-touch-integration/components';
import PageLoader from './PageLoader';
import Highlights from './Highlights';
import Styled from './EquityTouchIntegration.styled';
import ShowingTitle from './ShowingTitle';
import ReportBreadcrumb from '../AllReports/components/ReportBreadcrumb';

const EquityTouchIntegration = () => {
  useInject();
  useInjectAnalystsLeaderboard();

  const dispatch = useDispatch();

  useMount(() => {
    dispatch(actions.init());
  });

  return (
    <PrivateComponent accessFor="tracking">
      <PageLoader />
      <Header />
      <Styled.Wrapper className="equity-touch-integration">
        <Styled.MainContent className="main-content">
          <ReportBreadcrumb reportPage="Equity Touch Integration" />

          <Row align="middle">
            <Col>
              <Styled.PageTitle>Equity Touch Integration</Styled.PageTitle>
            </Col>
            <Col style={{ marginLeft: 20 }}>
              <ShowingTitle />
            </Col>
          </Row>
          <Row
            gutter={cardRowGutter}
            justify="space-between"
            style={{ marginTop: 0, paddingTop: '21px', paddingBottom: '42px' }}
          >
            <Col xs={24} xl={12}>
              <Styled.ByAnalystsOrTeamMultiSelect filterKey="analyst_id" allowSelectAll />
            </Col>
            <Col>
              <FilterSingleSelectDateFilter />
            </Col>
          </Row>
          <Row gutter={cardRowGutter} style={{ paddingBottom: '42px' }}>
            <Col span={24}>
              <Card $transparent>
                <Row justify="space-between">
                  <Col>
                    <Styled.TypographyTitle level={4}>
                      # of New Companies Added to ET
                    </Styled.TypographyTitle>
                    <Styled.SecondaryTitle>
                      This is the total number of companies added to Equity Touch by the sourcing
                      group
                    </Styled.SecondaryTitle>
                  </Col>
                </Row>
                <NewCompaniesAddedBarChart />
              </Card>
            </Col>
            <Col span={24}>
              <CompaniesInETTable />
              <CompaniesInETPagination />
            </Col>
          </Row>
        </Styled.MainContent>
        <Highlights />
      </Styled.Wrapper>
      <FiltersModal />
    </PrivateComponent>
  );
};

export default EquityTouchIntegration;
