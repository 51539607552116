import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectors, actions } from '../../../redux/contacts/filters';
import { useShouldFetch } from '../fetch';

const useInitContactFilters = () => {
  const shouldFetch = useSelector(selectors.shouldInitialFetch);
  const dispatch = useDispatch();
  const fetchFilters = useCallback(() => dispatch(actions.fetchFilters()), [dispatch]);
  useShouldFetch(shouldFetch, fetchFilters);
};

export default useInitContactFilters;
