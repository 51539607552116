import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// redux
import { selectors as profileSelectors } from '@redux/company/profile';
import { selectors, actions } from '@redux/company/addon-management';
import { selectors as fullScreenSelectors } from '@redux/ui/settings/fullscreen';
// components
import ClearSearchButton from '@shared/view/molecules/Buttons/ClearSearchButton';
import GridFullScreen from '@optx/shared/view/molecules/FullScreen/GridFullScreen';
import FilterTags from './FilterTags';
import CompaniesSearch from './CompaniesSearch';
import { Styled } from './SearchHeader.styled';
import { MainHeader } from '@optx/core/layout';

const SearchHeader = () => {
  const dispatch = useDispatch();
  const isClearedFilter = useSelector(selectors.isClearedFilter);

  const options = useSelector(selectors.getTags);
  const companyId = useSelector(profileSelectors.getCompanyId);
  const fullscreen = useSelector(fullScreenSelectors.isFullscreen);

  const filteredOptions = useMemo(() => options.filter(item => !item.isDefault), [options]);

  const onClear = () => {
    dispatch(actions.clearSearch());

    if (
      filteredOptions.length === 1 &&
      filteredOptions[0].filter === 'origin' &&
      companyId !== undefined
    ) {
      dispatch(
        actions.fetchCompanyAddonManagement({ companyId: companyId, shouldResetPageNumber: true })
      );
    }
  };

  return (
    <MainHeader className="main-header">
      <div className="search-header-content addons-header-content">
        <CompaniesSearch className="antd-search-input" autoComplete="off" />
        <Styled.Spacer size={4}>
          <FilterTags options={filteredOptions} />
          <ClearSearchButton isVisible={isClearedFilter} title="Clear All" onClick={onClear} />
          {fullscreen && <GridFullScreen />}
        </Styled.Spacer>
      </div>
    </MainHeader>
  );
};

export default SearchHeader;
