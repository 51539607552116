// redux
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { actions } from '../../../redux/company/funding-rounds';

interface useInitCompanyFundingRoundsFunction {
  (): {
    fetchCompanyFundingRounds(companyId: number): void;
  };
}

const useInitCompanyFundingRounds: useInitCompanyFundingRoundsFunction = () => {
  const dispatch = useDispatch();

  const fetchCompanyFundingRounds = useCallback(
    (companyId: number) => dispatch(actions.getCompanyFundingRounds(companyId)),
    [dispatch]
  );

  return { fetchCompanyFundingRounds };
};

export default useInitCompanyFundingRounds;
