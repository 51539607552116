import { createReducer } from '@reduxjs/toolkit';
import { reducers } from '@redux/feature/fetch';
// Local
// models
import { TouchesAcrossRegionState } from './interfaces';
// redux
import * as actions from './actions';

const initialState: TouchesAcrossRegionState = {
  data: null,
  error: '',
  loading: false,
  fetchedAt: null,
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.fetchTouchesAcrossRegion, reducers.fetchReducer)
    .addCase(actions.fetchTouchesAcrossRegionSuccess, reducers.fetchSuccessReducer)
    .addCase(actions.fetchTouchesAcrossRegionFail, reducers.fetchFailReducer)
);

export default reducer;
