import React, { useMemo } from 'react';
import { Select, Typography } from 'antd';
import { useField } from 'formik';
import { FormItem } from 'formik-antd';
import { OptionTypeBase } from 'react-select';
// models
import { BaseField } from '@optx/models/companyFields';
// components
import CheckboxIcon from './CheckboxIcon';
import Styled from './MultiSelectField.styled';

const { Option } = Select;

interface MultiSelectObjectFieldProps {
  field: BaseField<OptionTypeBase[]>;
}

const MultiSelectObjectField: React.FC<MultiSelectObjectFieldProps> = ({ field }) => {
  const [selected, , setSelectedOption] = useField<OptionTypeBase[] | null | undefined>(field.id);

  const dataList = field.data?.filter((option: OptionTypeBase) => option.value);

  const handleChange = (value: string, type: 'select' | 'deselect') => {
    if (type === 'select') {
      if (value === 'all') {
        setSelectedOption.setValue(field.data);
      } else {
        const selectedOption = field.data?.find((option: OptionTypeBase) => option.value === value);

        if (selectedOption) {
          setSelectedOption.setValue([
            ...(selected.value ? selected.value?.filter(option => option.value) : []),
            selectedOption,
          ]);
        }
      }
    } else if (type === 'deselect') {
      if (value === 'all') {
        setSelectedOption.setValue([]);
      } else {
        const updatedSelected = selected.value?.filter(option => option.value !== value);
        setSelectedOption.setValue(updatedSelected ?? null);
      }
    }
  };

  const normalizedSelected = useMemo(() => {
    let selectedOptions = selected.value?.reduce((result: string[], option: OptionTypeBase) => {
      if (option.value) {
        result.push(option.value);
      }

      return result;
    }, []);

    if (selectedOptions?.length && selectedOptions.length === dataList.length) {
      selectedOptions = ['all', ...selectedOptions];
    }

    return selectedOptions;
  }, [selected.value, dataList]);

  const selectClass = normalizedSelected?.includes('all') ? 'all-selected' : '';

  return (
    <>
      <Styled.SelectWrapper span={8} className={field.id}>
        <Typography.Text>{field.label}</Typography.Text>
        <FormItem name={field.id}>
          <Select
            className={`multi-select-field ${selectClass}`}
            showSearch
            showArrow
            value={normalizedSelected}
            menuItemSelectedIcon={CheckboxIcon}
            onSelect={(value, option) => handleChange(value, 'select')}
            onDeselect={(value, option) => handleChange(value, 'deselect')}
            optionFilterProp="children"
            mode="multiple"
            getPopupContainer={trigger => trigger.parentElement!}
            disabled={!field.editable}
            tagRender={props => {
              if (props.value === 'all') {
                return (
                  <span>
                    Select: <span className="all-blue">ALL</span>
                  </span>
                );
              }

              return <span>{props.label || ''}</span>;
            }}
            filterOption={(input: string, option: OptionTypeBase | undefined) => {
              const fullName = `${option?.children.props.children}`;

              return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {dataList && (
              <Option key="all" value="all" className="left-checkbox-option select-all-option">
                <span>(select all / none)</span>
              </Option>
            )}
            {dataList?.map((option: OptionTypeBase) => (
              <Option
                key={option.value as string}
                value={option.value as string}
                className="left-checkbox-option"
              >
                <span>{option.label}</span>
              </Option>
            ))}
          </Select>
        </FormItem>
      </Styled.SelectWrapper>
      <Styled.ContactSelectGlobal />
    </>
  );
};

export default React.memo(MultiSelectObjectField);
