import { Dictionary } from 'lodash';
import { createAction, PrepareAction } from '@reduxjs/toolkit';
// models
import { PreselectedFilter } from '@optx/models/filters';
// redux
import * as types from './types';
import {
  FetchHistogramsActionPayload,
  FetchHistogramsSuccessActionPayload,
  FilterChangedPayload,
  ToggleRefreshPayload,
} from './interfaces';

export const fetchHistogramFilters = createAction<PrepareAction<FetchHistogramsActionPayload>>(
  types.fetchHistograms.REQUEST,
  (filter: Dictionary<PreselectedFilter>, histogramList: Array<string>) => ({
    payload: { filter, histogramList },
  })
);

export const fetchHistogramFiltersSuccess = createAction<FetchHistogramsSuccessActionPayload>(
  types.fetchHistograms.SUCCESS
);

export const fetchHistogramFiltersFail = createAction<string>(types.fetchHistograms.FAILURE);

export const toggleRefresh = createAction<PrepareAction<ToggleRefreshPayload>>(
  types.TOGGLE_REFRESH,
  (enabled: boolean, filterValues: Dictionary<PreselectedFilter>) => ({
    payload: {
      enabled,
      values: filterValues,
    },
  })
);

export const filterChanged = createAction<PrepareAction<FilterChangedPayload>>(
  types.FILTER_CHANGED,
  (filterKey: string, values?: Dictionary<PreselectedFilter>) => ({
    payload: {
      filterKey,
      values,
    },
  })
);

export const edit = createAction<boolean>(types.EDIT);

export const applyEdit = createAction(types.APPLY_EDIT);

// Action called when histogram searches for default histogram but the default histogram is already cached.
export const resetToDefault = createAction(types.RESET_TO_DEFAULT);

export const clear = createAction(types.CLEAR);
