import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import JWTStorage from '../../../storage/localStorage/jwtStorage';
// constants
import appRoutes from '../../../constants/routes';

interface PrivateRouteProps extends RouteProps {
  // TODO: Add correct types
  component: React.ComponentType<RouteProps> | any;
}

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  return (
    <Route
      {...rest}
      render={props => (
        <>
          {JWTStorage.exists() && Component && <Component {...props} />}

          {!JWTStorage.exists() && (
            <Redirect to={{ pathname: appRoutes.login, state: { from: props.location } }} />
          )}
        </>
      )}
    />
  );
};

export default PrivateRoute;
