import React from 'react';
import { LegendValueFormatter } from 'recharts';
import moment from 'moment';

import globalConfig from '@constants/config';
import { LegendText } from '@components/common/charts/LineChart/LegendFormatter/styles';

export const createLegendFormatter: (date: string | null) => LegendValueFormatter =
  date => value => {
    const momentDate = moment(date);

    const text =
      date && momentDate.isValid()
        ? `AS OF ${momentDate.format(globalConfig.short_date.DATE_FORMAT)} Last day of period`
        : String(value).toUpperCase();

    return <LegendText>{text}</LegendText>;
  };
