import { createFeatureAction } from '@utils/feature';
import featureName from '../../featureName';
import * as types from './actionTypes';
import { EditCompanyFund } from './interfaces';

export const addFund = createFeatureAction<EditCompanyFund>(featureName, types.ADD_COMPANY_FUND);
export const addFundSuccess = createFeatureAction<EditCompanyFund>(
  featureName,
  types.ADD_COMPANY_FUND_SUCCESS
);
export const addFundFail = createFeatureAction<string>(featureName, types.ADD_COMPANY_FUND_FAIL);
