import styled from 'styled-components';
import { Modal, Row, Col, Result } from 'antd';
import { ModalProps } from 'antd/lib/modal';

interface ModalWrapperProps extends ModalProps {
  background?: string;
}

const ModalWrapper = styled(Modal)<ModalWrapperProps>`
  top: 50%;
  transform: translateY(-50%);
  padding: 0;

  .ant-modal-header,
  .ant-modal-footer {
    border-color: #e8e8e8;
  }

  .ant-modal-body {
    background: ${({ background }) => background || '#f3f6f9'};
  }

  th.ant-table-cell {
    padding: 19px 7px;
  }

  .ant-table-wrapper {
    max-height: 60vh;
    overflow-y: auto;
  }

  .ant-btn:first-child {
    display: inline-block;
  }
`;

const RowWrapper = styled(Row)`
  justify-content: space-between;
  padding: 13px 15px;
`;

const BoxWrapper = styled(Col)`
  width: calc(50% - 17px);
  padding: 20px 30px;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(66, 17, 115, 0.06);
  border-radius: 8px;

  .ant-typography:not(.multiselect__value-container .ant-typography) {
    display: inline-block;
    margin-bottom: 13px;
  }
`;
const ResultWrapper = styled(Result)`
  padding-bottom: 48px;

  .ant-result-icon {
    margin-bottom: 0;
  }
`;

export const Styled = {
  ModalWrapper,
  RowWrapper,
  BoxWrapper,
  ResultWrapper,
};
