import { call, select, put } from 'redux-saga/effects';
import { Dictionary, isEqual } from 'lodash';
import queryString from 'query-string';
// models
import { PreselectedFilter } from '@models/filters';
import { SortByRule } from '@models/table/sorting';
import { GridKeys, GetGridSearchData } from '@models/grid';
import { PageInfo } from '@models/table/Pagination';
import { SearchViewType } from '@models/user';
import Company from '@models/Company';
// constants
import { PAGINATION_INITIAL_STATE } from '@constants/grid';
import { COMPANY_DEFAULT_SORT } from '@constants/table/sort/defaultSort';
import { DEFAULT_PAGINATION } from '@optx/constants/pagination';
// utils
import { mapSortQuery } from '@optx/utils/search';
// redux
import { selectors as companySearchSelectors } from '@redux/company/search/search';
import { selectors as viewTypeSelectors } from '@features/grid/view-type';
import { getFiltersQuery } from '@redux/company/search/search';
import { selectors as filterSelectors } from '../filter';

import { selectors as searchKeySelectors } from '@features/grid/searchkey';

import { selectors as listSearchSelectors } from '@redux/lists/search/search';
import { selectors as listsDetailsSelectors } from '@redux/lists/details';

import { selectors as myCompaniesSortSelectors } from '@redux/my-companies/sort';
import { selectors as myCompaniesFilterCardsSelectors } from '@optx/redux/my-companies/filters-cards';

import { selectors as companyOutreachFilterSelectors } from '@redux/company-outreach/filters';
import { selectors as companyOutreachSortSelectors } from '@redux/company-outreach/sort';

import { selectors as addonSelectors } from '@redux/company/addon-management';

import {
  selectors as userInformationSelectors,
  actions as userInformationActions,
} from '@redux/user/information';
import { selectors as paginationSelectors } from '@features/grid/pagination';
import { selectors as longCardPaginationSelectors } from '@features/grid/pagination-long-card';

/**
 * Get the search data of the grid based on the grid key and pagination.
 *
 * @param gridKey The grid key used to determine the search data.
 * @param pagination The pagination info to be used for the search.
 *
 * @returns The grid search data object containing the search key,
 * filter (new Filter), original filter, sort by and pagination info.
 */
export function* getGridSearchDataSaga(gridKey: GridKeys, pagination?: PageInfo) {
  let searchKey: string = '';
  let filter: Dictionary<PreselectedFilter> = {};
  let clearedFilters: Dictionary<PreselectedFilter> = {};
  let defaultFilters: Dictionary<PreselectedFilter> = {};
  let subFilter: string = '';
  let filterQuery: Dictionary<string | (string | number)[]> = {};
  let sortBy: SortByRule<any>[] = [];
  let view: SearchViewType = 'table';
  let paginationData: PageInfo = DEFAULT_PAGINATION;

  switch (gridKey) {
    case 'advancedSearch':
      searchKey = yield select(searchKeySelectors.getSearchKey('advancedSearch'));
      filter = yield select(filterSelectors.getFilter('advancedSearch'));
      clearedFilters = yield select(filterSelectors.getClearedFilter('advancedSearch'));
      defaultFilters = yield select(filterSelectors.getDefaultFilter('advancedSearch'));
      sortBy = yield select(companySearchSelectors.getSorting);
      view = yield select(viewTypeSelectors.searchView('advancedSearch'));

      const companyGridPagination: PageInfo = yield select(
        paginationSelectors.getPagination(gridKey)
      );
      const companyLongCardPagination: PageInfo = yield select(
        longCardPaginationSelectors.getLongCardPagination('advancedSearch')
      );
      paginationData = view === 'long-card' ? companyLongCardPagination : companyGridPagination;
      break;

    case 'myCompanies':
      searchKey = yield select(searchKeySelectors.getSearchKey('myCompanies'));
      filter = yield select(filterSelectors.getFilter('myCompanies'));
      clearedFilters = yield select(filterSelectors.getClearedFilter('myCompanies'));
      defaultFilters = yield select(filterSelectors.getDefaultFilter('myCompanies'));
      subFilter = yield select(myCompaniesFilterCardsSelectors.getSubFilter);
      sortBy = yield select(myCompaniesSortSelectors.getSorting);
      view = yield select(viewTypeSelectors.searchView('myCompanies'));

      const myCompaniesGridPagination: PageInfo = yield select(
        paginationSelectors.getPagination(gridKey)
      );
      const myCompaniesLongCardPagination: PageInfo = yield select(
        longCardPaginationSelectors.getLongCardPagination('myCompanies')
      );
      paginationData =
        view === 'long-card' ? myCompaniesLongCardPagination : myCompaniesGridPagination;
      break;

    case 'sslists':
    case 'watchlists':
    case 'lists':
      searchKey = yield select(searchKeySelectors.getListsSearchKey);

      const isSourceScrubRoute: boolean = yield select(listsDetailsSelectors.isSourceScrubRoute);

      filter = yield select(
        filterSelectors.getFilter(isSourceScrubRoute ? 'sslists' : 'watchlists')
      );
      clearedFilters = yield select(
        filterSelectors.getClearedFilter(isSourceScrubRoute ? 'sslists' : 'watchlists')
      );
      defaultFilters = yield select(
        filterSelectors.getDefaultFilter(isSourceScrubRoute ? 'sslists' : 'watchlists')
      );
      sortBy = yield select(listSearchSelectors.getSorting);
      view = yield select(viewTypeSelectors.searchView('lists'));

      const listGridPagination: PageInfo = yield select(
        paginationSelectors.getPagination(isSourceScrubRoute ? 'sslists' : 'watchlists')
      );
      const listLongCardPagination: PageInfo = yield select(
        longCardPaginationSelectors.getLongCardPagination(
          isSourceScrubRoute ? 'sslists' : 'watchlists'
        )
      );
      paginationData = view === 'long-card' ? listLongCardPagination : listGridPagination;
      break;

    case 'outreach':
      searchKey = yield select(searchKeySelectors.getSearchKey('outreach'));
      filter = yield select(companyOutreachFilterSelectors.getFilter);
      clearedFilters = yield select(companyOutreachFilterSelectors.getClearedFilter);
      subFilter = yield select(companyOutreachFilterSelectors.getSubFilter);
      sortBy = yield select(companyOutreachSortSelectors.getSorting);
      view = yield select(viewTypeSelectors.searchView('outreach'));

      const outreachGridPagination: PageInfo = yield select(
        paginationSelectors.getPagination(gridKey)
      );
      const outreachLongCardPagination: PageInfo = yield select(
        longCardPaginationSelectors.getLongCardPagination('outreach')
      );
      paginationData = view === 'long-card' ? outreachLongCardPagination : outreachGridPagination;
      break;

    case 'addons':
      sortBy = yield select(addonSelectors.sortBy);
      filter = yield select(filterSelectors.getFilter('addons'));
      searchKey = yield select(searchKeySelectors.getSearchKey('addons'));
      clearedFilters = yield select(filterSelectors.getClearedFilter('addons'));

      view = yield select(viewTypeSelectors.searchView('addons'));
      const addonGridPagination: PageInfo = yield select(
        paginationSelectors.getPagination(gridKey)
      );
      const addonLongCardPagination: PageInfo = yield select(
        longCardPaginationSelectors.getLongCardPagination('addons')
      );
      paginationData = view === 'long-card' ? addonLongCardPagination : addonGridPagination;

      break;

    default:
      break;
  }

  if (pagination) paginationData = pagination;

  filterQuery = yield call(getFiltersQuery, filter);

  const gridSearchData: GetGridSearchData = {
    searchKey,
    filter: filterQuery,
    subFilter,
    defaultFilters,
    originalFilter: filter,
    clearedFilters,
    sortBy,
    view,
    pagination: paginationData,
  };

  return gridSearchData;
}

/**
 * Update user's info based on the gridKey and pagination.
 *
 * @param {GridKeys} gridKey - The grid key to be used to update the user settings.
 * @param {PageInfo} pagination - The pagination information to be used to update the user settings.
 * @param {boolean} isReturnUserSettings - A boolean flag to indicate if
 * the user settings should be returned instead of being dispatched. Defaults to false.
 *
 * @returns {Object} - If isReturnUserSettings is true, the user settings object is returned.
 */
export function* userInfoUpdateSaga(
  gridKey: GridKeys,
  pagination: PageInfo,
  isReturnUserSettings: boolean = false,
  isSorting: boolean = false,
  sortBy?: SortByRule<Company>[]
) {
  let userPageSize: number = PAGINATION_INITIAL_STATE.pageSize;
  let userPageNumber: number = PAGINATION_INITIAL_STATE.pageNumber;

  let results_per_page = '';
  let results_page_number = '';
  let results_sorting = '';

  switch (gridKey) {
    case 'advancedSearch':
      userPageSize = yield select(userInformationSelectors.getResultsPerPage);
      userPageNumber = yield select(userInformationSelectors.getResultsPageNumber);
      results_per_page = 'search';
      results_page_number = 'search';
      results_sorting = 'company';
      break;

    case 'myCompanies':
      userPageSize = yield select(userInformationSelectors.getMyCompaniesResultsPerPage);
      userPageNumber = yield select(userInformationSelectors.getMyCompaniesResultsPageNumber);
      results_per_page = 'my_companies';
      results_page_number = 'my_companies';
      break;

    case 'contacts':
      userPageSize = yield select(userInformationSelectors.getContactResultsPerPage);
      userPageNumber = yield select(userInformationSelectors.getContactResultsPageNumber);
      results_per_page = 'contact';
      results_page_number = 'contact';
      break;

    case 'sslists':
    case 'watchlists':
    case 'lists':
      const isSourceScrubRoute: boolean = yield select(listsDetailsSelectors.isSourceScrubRoute);
      const userListPageNumber: number = yield select(
        userInformationSelectors.getUserListSearchesResultsPageNumber
      );
      const ssListPageNumber: number = yield select(
        userInformationSelectors.getSSListSearchesResultsPageNumber
      );
      userPageSize = yield select(userInformationSelectors.getUserListResultsPerPage);
      userPageNumber = isSourceScrubRoute ? userListPageNumber : ssListPageNumber;

      results_per_page = 'user_list_search';
      results_page_number = 'user_list_search';
      results_sorting = 'user_list';

      if (isSourceScrubRoute && pagination.pageNumber !== ssListPageNumber) {
        results_page_number = 'ss_list_search';
      }

      break;

    case 'outreach':
      userPageSize = yield select(userInformationSelectors.getSourcingOutReachPerPage);
      const outreachPagination: PageInfo = yield select(
        paginationSelectors.getPagination('outreach')
      );
      userPageNumber = outreachPagination.pageNumber;
      results_per_page = 'sourcing_outreach';
      results_page_number = 'sourcing_outreach';
      break;

    case 'addons':
      userPageSize = yield select(userInformationSelectors.getAddonPerPage);
      userPageNumber = yield select(userInformationSelectors.getAddonPageNumber);
      results_per_page = 'addon';
      results_page_number = 'addon';
      break;

    default:
      break;
  }

  results_per_page += '_results_per_page';
  results_page_number += '_results_page_number';
  results_sorting += '_sorting';

  let userSettings: { [key: string]: number | string } = {};

  if (userPageSize !== pagination.pageSize) userSettings[results_per_page] = pagination.pageSize;
  if (userPageNumber !== pagination.pageNumber)
    userSettings[results_page_number] = pagination.pageNumber;

  if (isSorting) {
    const sorting = sortBy ? sortBy : COMPANY_DEFAULT_SORT;

    const sortQuery = queryString.stringify(mapSortQuery(sorting), {
      arrayFormat: 'comma',
    });

    userSettings[results_sorting] = sortQuery;
  }

  if (!isReturnUserSettings && !isEqual(userSettings, {}))
    yield put(userInformationActions.updateUserSettings(userSettings));
  else return userSettings;
}
