import React from 'react';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';

const DefaultTooltip = styled(Tooltip)`
  display: block;
`;

export const TableCellDefaultTooltip = (value: any) => {
  if (!value || value === DEFAULT_CELL_VALUE) {
    return <>{DEFAULT_CELL_VALUE}</>;
  }

  return (
    <DefaultTooltip title={value} className="text-truncate">
      {value}
    </DefaultTooltip>
  );
};

export default TableCellDefaultTooltip;
