import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Space } from 'antd';
// hooks
import { useImportListsFavorites } from '@features/list-import/hooks';
import { EditShareListModal } from '@features/list-share';
// redux
import { actions as modalActions } from '@redux/ui/modals/my-watchlist-csv-error';
import { selectors as listsSelectors, actions as listsActions } from '@redux/favorite-lists/lists';
import { selectors as listsAndSearchesSelectors } from '@features/lists-and-searches';
// components
import ClearSearchButton from '@shared/view/molecules/Buttons/ClearSearchButton';
import MyWatchlistsTable from './MyWatchlistsTable';
import MyWatchlistsSearch from './MyWatchlistsSearch';
import CsvUpload from '../../../CsvUpload';
import { MainHeader } from '../../../common/layout';
import Styled from './MyWatchlists.styles';
import FilterTags from '../FilterTags';
import { GridPagination } from '@features/grid/pagination/components';

const MyWatchlists = () => {
  const dispatch = useDispatch();
  const query = useSelector(listsSelectors.getQuery);
  const pageNumber = useSelector(listsSelectors.getPageNumber);
  const pageSize = useSelector(listsAndSearchesSelectors.pageSize);
  const totalItemsCount = useSelector(listsSelectors.getTotal);

  const { displayCSVErrorModal, handleImportListFavorites } = useImportListsFavorites();

  const [errors, setErrors] = useState(false);

  const onCloseCSVErrorModal = () => {
    dispatch(modalActions.toggleModal());
    setErrors(!errors);
  };

  const handlePageChange = useCallback(
    (pageNumber: number, perPage: number = pageSize) =>
      dispatch(listsActions.favoriteListsPagination({ pageNumber, pageSize: perPage })),
    [dispatch, pageSize]
  );

  return (
    <>
      <MainHeader className="main-header">
        <div className="search-header-content">
          <MyWatchlistsSearch className="antd-search-input" autoComplete="off" />
          <FilterTags listType="Favorite Lists" />
          <Space size={4} style={{ marginLeft: 'auto' }}>
            <ClearSearchButton
              title="Clear Search"
              isVisible={!!query}
              onClick={() => {
                return dispatch(
                  listsActions.fetchFavoriteLists({ showEmptyLists: false, query: '' })
                );
              }}
            />
          </Space>
        </div>
      </MainHeader>
      <Styled.StyledDrawer
        placement="left"
        closable={false}
        onClose={onCloseCSVErrorModal}
        visible={displayCSVErrorModal}
        width="60%"
        zIndex={1031}
        className="list-import-error"
      >
        <CsvUpload
          errors={errors}
          close={onCloseCSVErrorModal}
          onSubmit={handleImportListFavorites}
        />
      </Styled.StyledDrawer>
      <MyWatchlistsTable />
      <GridPagination
        onPageChange={handlePageChange}
        totalItemsCount={totalItemsCount}
        pageNumber={pageNumber}
        pageSize={pageSize}
      />
      <EditShareListModal />
    </>
  );
};

export default MyWatchlists;
