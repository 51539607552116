import { Dictionary, each } from 'lodash';
import { NavigationPathItem } from '@optx/models/menu/main-navigation';

/**
 * Get default submenu opened keys for menu.
 * @param items
 */
export const getDefaultOpenedKeys = (
  items: Array<NavigationPathItem>,
  exceptions: Dictionary<boolean> = {}
) => {
  const defaultOpenedKeys: Array<string> = [];

  each(items, item => {
    if (item.type === 'submenu' && !exceptions[item.path]) {
      defaultOpenedKeys.push(item.path);
    }
  });

  return defaultOpenedKeys;
};

/**
 * Get submenu opened keys for menu.
 * @param items
 */
export const getOpenedKeys = (items: Array<NavigationPathItem>, path: string) => {
  const defaultOpenedKeys: Array<string> = [];

  each(items, item => {
    if (item.type === 'submenu') {
      each(item.items!, submenuItem => {
        if (submenuItem.path === path) {
          defaultOpenedKeys.push(item.path);
        }
      });
    }
  });

  return defaultOpenedKeys;
};
