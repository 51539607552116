import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { reducers as fetchReducers } from '@redux/feature/fetch';

import * as actions from './actions';
import { CompanySectorState, EditCompanySector } from './interfaces';

export const initialState: CompanySectorState = {
  companyId: null,
  error: null,
  loading: false,
};

const addSector: CaseReducer<CompanySectorState, PayloadAction<EditCompanySector>> = (
  draftState,
  action
) => {
  const { companyId } = action.payload;
  draftState.companyId = companyId;
  draftState.loading = true;
};

const addSectorSuccess: CaseReducer<CompanySectorState, PayloadAction<EditCompanySector>> = (
  draftState,
  action
) => {
  draftState.loading = false;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.addSector, addSector)
    .addCase(actions.addSectorSuccess, addSectorSuccess)
    .addCase(actions.addSectorFail, fetchReducers.fetchFailReducer)
);

export default reducer;
