import { BarProps, XAxisProps, YAxisProps, TooltipProps, LegendProps } from 'recharts';
// components
import {
  defaultAxisLineProps,
  yAxisTickDefaultProps,
  tickDefaultProps,
} from '@optx/components/common/charts/LineChart/config';

export const commonBarProps: Omit<BarProps, 'dataKey'> = {
  radius: [0, 0, 0, 0],
};

export const xAxisProps: XAxisProps = {
  axisLine: defaultAxisLineProps,
  tickLine: false,
  tick: {
    ...tickDefaultProps,
    style: {
      marginleft: 10,
    },
  },
  tickMargin: 10,
};

export const yAxisProps: YAxisProps = {
  tickLine: false,
  tick: yAxisTickDefaultProps,
  axisLine: defaultAxisLineProps,
  tickMargin: 10,
};

export const yAxisPropsHorizontalBar: YAxisProps = {
  tickLine: false,
  tick: { ...yAxisTickDefaultProps, textAnchor: 'start' },
  axisLine: defaultAxisLineProps,
  tickMargin: 90,
};

const tooltipWrapperStyles: React.CSSProperties = {
  zIndex: 1,
};

const tooltipCursor: React.CSSProperties = {
  opacity: 0.2,
};

export const tooltipProps: TooltipProps = {
  wrapperStyle: tooltipWrapperStyles,
  allowEscapeViewBox: { y: true },
  cursor: tooltipCursor,
};

const legendWrapperStyles: React.CSSProperties = {
  paddingLeft: 110,
};
export const legendProps: LegendProps = {
  align: 'left',
  verticalAlign: 'top',
  height: 40,
  wrapperStyle: legendWrapperStyles,
  iconType: 'circle',
  iconSize: 11,
};
