import { createSelector } from 'reselect';
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as savedSearchSelectors } from '../../company/saved-searches';
import { selectors as companyFiltersSelectors } from '../../company/filters';
import { selectors as userInformationSelectors } from '../../user/information';

export const shouldInitialSearch = createSelector(
  userInformationSelectors.loaded,
  companyFiltersSelectors.filtersLoaded,
  searchSelectors.shouldInitialFetch('lists'),
  savedSearchSelectors.loaded,
  (loadedUserInfo, loadedFilters, shouldFetchCompanies, loadSavedSearches) =>
    loadedUserInfo && loadedFilters && shouldFetchCompanies && loadSavedSearches
);
