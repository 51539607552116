import styled, { createGlobalStyle } from 'styled-components';
import { Col, Menu, Dropdown, Typography } from 'antd';
import { styledColors } from '@optx/utils/theme/colors';

const ContactSelectGlobal = createGlobalStyle`
  .left-checkbox-option {
    .ant-tag {
      margin: 0 5px;
    }
    .ant-select-item-option-content {
      order: 2;
    }
    .ant-select-item-option-state {
      order: 1;
      margin-right: 12px;
    }
  }
`;

const SelectWrapper = styled(Col)`
  .ant-select-selection-item {
    background: transpare;
    border: 0 none;
  }

  .ant-select {
    .ant-select-selection-overflow {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &-item {
        overflow: hidden;
        text-overflow: ellipsis;
        & + .ant-select-selection-overflow-item:before {
          content: ', ';
          vertical-align: top;
        }
        &.ant-select-selection-overflow-item-suffix:before {
          display: none;
        }
      }
    }
  }
  .ant-select-selection-overflow-item {
    display: inline-block;
    vertical-align: middle;
  }
  .ant-select-open
    .ant-select-selector
    .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-suffix) {
    display: none;
  }

  .multiselect__control,
  .multiselect__value-container {
    height: 32px;
    min-height: 32px;
  }
  .anticon-search {
    margin-top: -3px;
  }

  .multi-select-field {
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background: transparent !important;
    }
    .ant-select-item-option:not(.ant-select-item-option-disabled):hover {
      background: #e6f7ff !important;
    }

    .select-all-option {
      padding: 5px 12px 7px;
      margin-bottom: 8px;
      border-bottom: 1px solid #d9d9d9;
    }
  }

  .all-selected {
    .ant-select-selection-overflow {
      text-overflow: inherit !important;
    }

    .ant-select-selection-overflow-item {
      line-height: 26px;

      .all-blue {
        color: rgb(24, 144, 255);
      }
      & + .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-suffix) {
        opacity: 0 !important;
      }
    }
  }
`;
const MenuWrapper = styled(Menu)`
  width: 400px;
  padding: 10px 20px;
  .ant-card-meta-detail {
    overflow: visible;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .company_owner_id--multiselect,
  .stage--multiselect {
    width: 260px;
    margin-right: 135px;
  }

  .ant-card-bordered {
    border: 0;
  }
  .menu-buttons-wrapper {
    padding: 10px 0;
    button {
      margin-right: 8px;
    }
  }
  .touch--multiselect-async-wrapper {
    width: 100%;
  }
  .multiselect-async-creatable-wrapper {
    width: auto;
    margin-right: 160px;
  }
  .touch--multiselect-async {
    .multiselect__menu {
      position: absolute;
      bottom: 100%;
      top: auto;
      margin: 0;
    }
    .multiselect__control,
    .multiselect__value-container {
      height: 32px;
      min-height: 32px;
    }
    .anticon-search {
      margin-top: -3px;
    }
  }
`;

const DropdownWrapper = styled(Dropdown)`
  text-align: left;
  display: inline-block;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.0015);
  border-radius: 2px;
  margin: 0px 13px 5px 0px;
  padding: 0px 5px;
  width: 100%;
  overflow: hidden;
  &.ant-btn:focus .anticon-down,
  .anticon-down {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const TriggerWrapper = styled.div`
  color: ${styledColors.gray8};
  display: flex;
  position: relative;
  .rc-overflow-item {
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:nth-last-child(2) {
      div .comma {
        display: none;
      }
    }
  }
`;

const SelectedLabel = styled(Typography.Text)`
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  margin-left: 5px;
  margin-right: 5px;
  .label-popover {
    color: rgba(0, 0, 0, 0.85);
    display: inline-block;
    vertical-align: top;
  }
  .visible-label {
    display: inline-block;
    vertical-align: top;
    max-width: 115px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const PopupTooltipContainer = styled.div`
  background: #fff;
  border: 1px solid #d9d9d9;
  padding: 4px 10px;
  min-height: 30px;
  cursor: pointer;
`;

export default {
  ContactSelectGlobal,
  SelectWrapper,
  MenuWrapper,
  DropdownWrapper,
  TriggerWrapper,
  SelectedLabel,
  PopupTooltipContainer,
};
