import React from 'react';
import { useDispatch } from 'react-redux';
import { Menu, MenuProps } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { ListType } from '@models/WatchList';
import { actions } from '../state/share-list';

interface ShareWatchListMenuItemProps extends MenuItemProps {
  listId: number | string;
  listType: ListType;
}

const ShareWatchListMenuItem: React.FC<ShareWatchListMenuItemProps> = ({
  listId,
  listType,
  onClick,
  ...restProps
}) => {
  const dispatch = useDispatch();

  const handleClick: MenuProps['onClick'] = info => {
    onClick && onClick(info);
    dispatch(actions.editShareList(listId, listType));
  };

  return <Menu.Item onClick={handleClick} {...restProps} />;
};

export default ShareWatchListMenuItem;
