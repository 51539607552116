/* eslint-disable no-param-reassign */
import { createSelector } from 'reselect';
import { selectors as localSelectors } from '../../bulk-software';
import { selectFeatureState } from './selectFeature';

const softwareState = createSelector(selectFeatureState, state => {
  return state.software;
});

const getSuccessCount = createSelector(softwareState, localSelectors.getSuccessCount);

export default { getSuccessCount };
