import React from 'react';
import AsyncCreatable from 'react-select/async-creatable';
import classNames from 'classnames';
import { OptionsType, InputActionMeta, ValueType, GroupedOptionsType } from 'react-select';
// models
import { SelectOption } from '@optx/models/Option';
import { MultiSelectAsyncProps } from './interfaces';
// hooks
import { useAsyncSelect } from './AsyncSelect';
// components
import {
  DropdownIndicator,
  MultiValueRemove,
  MultiValueLabel,
  ValueContainer,
  Option,
} from './components';
// styles
import './style.scss';

type AsyncCreatableMultiSelectProps = MultiSelectAsyncProps & {
  disableValueRemove?: boolean;
  className?: string;
  wrapperClassName?: string;
  selectRef?:
    | string
    | ((instance: AsyncCreatable<SelectOption> | null) => void)
    | React.RefObject<AsyncCreatable<SelectOption>>
    | null
    | undefined;
  onMenuClose?: VoidFunction;
  onTagClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  /**
   * Children nodes added before tags in the same container.
   */
  tagsChildrenStart?: React.ReactNode;
  /**
   * Children nodes added after tags in the same container.
   */
  tagsChildrenEnd?: React.ReactNode;
  value?: OptionsType<SelectOption>;
  bodyDisplay?: boolean;
  validateCreateOption?: (value: string) => boolean;
};

const AsyncCreatableMultiSelect: React.FC<AsyncCreatableMultiSelectProps> = ({
  loadOptions,
  onChange,
  onInputChange,
  preDefinedSelectedValues,
  disableValueRemove,
  tagsChildrenStart,
  tagsChildrenEnd,
  className,
  wrapperClassName,
  onMenuClose,
  selectRef,
  bodyDisplay,
  onTagClick,
  value,
  validateCreateOption,
}) => {
  const { selectedOption, listSelectedItems, MultiValueContainer, handleChange } = useAsyncSelect({
    onChange,
    preDefinedSelectedValues,
    onTagClick,
    value,
  });

  return (
    <div className={classNames('multiselect-wrapper', wrapperClassName)}>
      <AsyncCreatable
        ref={selectRef}
        className={className}
        value={selectedOption}
        onChange={handleChange}
        onInputChange={onInputChange}
        isMulti
        hideSelectedOptions={false}
        isClearable={false}
        loadOptions={loadOptions}
        cacheOptions
        components={{
          MultiValueContainer,
          MultiValueLabel,
          // Temporary empty value until things are changed.
          MultiValueRemove: disableValueRemove ? () => null : MultiValueRemove,
          ValueContainer,
          DropdownIndicator,
          Option: props =>
            disableValueRemove ? <Option {...props} disabled /> : <Option {...props} />,
        }}
        classNamePrefix="multiselect-select"
        placeholder="search"
        onMenuClose={onMenuClose}
        isValidNewOption={
          validateCreateOption
            ? (
                inputValue: string,
                value: ValueType<SelectOption<string>>,
                options:
                  | OptionsType<SelectOption<string>>
                  | GroupedOptionsType<SelectOption<string>>
              ) => {
                return (
                  // adding a custom validation function for isValidNewOption prop removes existing check for
                  // preventing option from appearing both as an option and as a creatable option.
                  // before calling the validation function we need to check for existing option
                  !(options as SelectOption[]).some(option => option.value === inputValue.trim()) &&
                  validateCreateOption(inputValue)
                );
              }
            : undefined
        }
      />
      {bodyDisplay && (
        <div className="multiselect-list__body">
          {tagsChildrenStart}
          {listSelectedItems}
          {tagsChildrenEnd}
        </div>
      )}
    </div>
  );
};

export default AsyncCreatableMultiSelect;
