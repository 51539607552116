import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actions as searchActions } from '@features/grid/search';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';

const useSugestedSearches = (): {
  handleSearchResults: (title: string) => void;
} => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { advancedSearchPathname } = useIsAnalyst();

  const handleSearchResults = (title: string) => {
    history.push(advancedSearchPathname);
    dispatch(searchActions.loadSuggestedSearch({ gridKey: 'advancedSearch', data: { title } }));
  };

  return { handleSearchResults };
};

export default useSugestedSearches;
