import React from 'react';
import { useSelector } from 'react-redux';
// redux
import { selectors as favoriteListsSelectors } from '@redux/favorite-lists/lists';
// components
import InProgress from './InProgress';
import Completed from './Completed';

interface SaveSearchAsListCompletionProps {
  percentage: number;
  totalCompanies: number;
}

const SaveSearchAsListCompletion: React.FC<SaveSearchAsListCompletionProps> = ({
  percentage,
  totalCompanies,
}) => {
  const completed = useSelector(favoriteListsSelectors.isCompleted);

  return completed ? (
    <Completed totalCompanies={totalCompanies} />
  ) : (
    <InProgress percentage={percentage} />
  );
};

export default SaveSearchAsListCompletion;
