import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { Dictionary, each } from 'lodash';
// models
import { UserInformation } from '@optx/models/user';
// interfaces
import { MyCompaniesFilterCardsState } from './interfaces';
import { SearchStateData } from '@optx/models/search';
// redux
import { actions as userInformationActions } from '../../user/information';
import { actions as searchActions } from '@features/grid/search';
import * as actions from './actions';

export const initialState: MyCompaniesFilterCardsState = {
  // equity touch source is added for every search
  cards: {},
  selectedCard: null,
  selectedCardId: 'card1',
  subFilter: '',
};

// Update persisted filter to be used when filters are loaded.
const fetchUserInformationSuccessReducer: CaseReducer<
  MyCompaniesFilterCardsState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const selectedCardId = action.payload.settings.session_settings
    ? action.payload.settings.session_settings.my_companies_selected_card_id
    : '';

  if (selectedCardId) {
    draftState.selectedCardId = selectedCardId;
  }

  draftState.sessionFilter = action.payload.settings.session_settings?.my_companies_filters;
};

const fetchMyCompaniesCardsSuccessReducer: CaseReducer<
  MyCompaniesFilterCardsState,
  PayloadAction<Dictionary<SearchStateData>>
> = (draftState, action) => {
  if (draftState.selectedCardId) {
    const card = action.payload[draftState.selectedCardId];
    draftState.selectedCard = { ...card, id: draftState.selectedCardId };

    each(draftState.selectedCard?.data, (subFilter, subFilterKey) => {
      if (draftState.sessionFilter?.includes(Object.keys(subFilter)[0])) {
        draftState.subFilter = subFilterKey;

        return false;
      }

      return true;
    });
  }

  draftState.cards = action.payload;
};

const changeCardReducer: CaseReducer<MyCompaniesFilterCardsState, PayloadAction<string>> = (
  draftState,
  action
) => {
  draftState.selectedCard = { ...draftState.cards[action.payload], id: action.payload };
  draftState.selectedCardId = action.payload;
  draftState.subFilter = '';
};

const changeCardSubfilterSuccessReducer: CaseReducer<
  MyCompaniesFilterCardsState,
  PayloadAction<{ cardId: string; subFilter: string }>
> = (draftState, action) => {
  const { cardId, subFilter } = action.payload;
  draftState.selectedCard = { ...draftState.cards[cardId], id: cardId };
  draftState.selectedCardId = cardId;
  draftState.subFilter = subFilter;
};

const clearFilterSuccessReducer: CaseReducer<
  MyCompaniesFilterCardsState,
  PayloadAction<SearchStateData | null | undefined>
> = (draftState, action) => {
  const { payload: selectedCard } = action;

  if (selectedCard) {
    draftState.selectedCard = selectedCard;
  }

  draftState.subFilter = '';
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(searchActions.clearFilterSuccess, clearFilterSuccessReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
    .addCase(actions.fetchMyCompaniesCardsSuccess, fetchMyCompaniesCardsSuccessReducer)
    .addCase(actions.changeCard, changeCardReducer)
    .addCase(actions.changeCardSubfilterSuccess, changeCardSubfilterSuccessReducer)
);

export default reducer;
