import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import searchReducer from '../../../redux/contacts/search/reducer';
import { saga as contactsSearchSaga } from '../../../redux/contacts/search/search';

const useInjectContactsSearch = () => {
  useInjectReducer({
    key: 'contactsSearch',
    reducer: searchReducer,
  });
  useInjectSaga({
    key: 'contactsSearch',
    saga: contactsSearchSaga,
  });
};

export default useInjectContactsSearch;
