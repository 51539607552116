import { createSelector } from 'reselect';
import { selectors as loaderSelectors } from '@optx/features/request';
import { featureName } from '../../featureName';
import { CompaniesInEquityTouchStateWithHistory } from '../interfaces';
import * as localSearch from './search';
import * as localFilters from './filters';
import * as localSort from './sort';
import * as localPagination from './pagination';
import * as localCharts from './charts';
import * as localHiglights from './highlights';
import { types } from '../types';

const selectFeatureState = (state: any) =>
  (state[featureName] as CompaniesInEquityTouchStateWithHistory).present;
const selectSearchState = createSelector(selectFeatureState, state => state.search);
const selectFiltersState = createSelector(selectFeatureState, state => state.filters);
const selectSortState = createSelector(selectFeatureState, state => state.sort);
const selectPaginationState = createSelector(selectFeatureState, state => state.pagination);
const selectUIState = createSelector(selectFeatureState, state => state.ui);
const selectChartsState = createSelector(selectFeatureState, state => state.charts);
const selectHighlightsState = createSelector(selectFeatureState, state => state.highlights);

// search
export const isLoadingSearch = loaderSelectors.createLoadingSelector([types.search.fetch.SELECTOR]);

const companiesInET = createSelector(selectSearchState, localSearch.selectCompaniesInET);

const search = {
  isLoading: isLoadingSearch,
  data: companiesInET,
  isInitialized: createSelector(selectSearchState, localSearch.isInitialized),
};

// filters
const isLoadingFilters = loaderSelectors.createLoadingSelector([
  types.filters.fetchFilters.SELECTOR,
]);

const filters = {
  isLoading: isLoadingFilters,
  selectFilter: createSelector(selectFiltersState, localFilters.getFilter),
  selectDefaultFilter: createSelector(selectFiltersState, localFilters.getDefaultFilter),
  selectClearedFilter: createSelector(selectFiltersState, localFilters.getClearedFilter),
  selectNormalizedFilters: createSelector(selectFiltersState, localFilters.getNormalizedFilters),
  filterQuery: createSelector(selectFiltersState, localFilters.filterQuery),
  filterQueryForStats: createSelector(selectFiltersState, localFilters.filterQueryForStats),
  getFilters: createSelector(selectFiltersState, localFilters.getFilters),
  getValues: createSelector(selectFiltersState, localFilters.getValues),
  sources: createSelector(selectFiltersState, localFilters.sources),
  isCLearedFilter: createSelector(selectFiltersState, localFilters.isClearedFilter),
  getTags: createSelector(selectFiltersState, localFilters.getTags),
  loaded: createSelector(selectFiltersState, localFilters.loaded),
};

// sort
const sort = {
  sortBy: createSelector(selectSortState, localSort.selectSortBy),
  isMultiSort: createSelector(selectSortState, localSort.isMultiSort),
  getGridColumns: createSelector(selectSortState, localSort.getGridColumns),
};

// pagination
const pagination = {
  total: createSelector(selectPaginationState, localPagination.total),
  pageNumber: createSelector(selectPaginationState, localPagination.pageNumber),
  pageSize: createSelector(selectPaginationState, localPagination.pageSize),
  pageCount: createSelector(selectPaginationState, localPagination.pageCount),
  pagination: createSelector(selectPaginationState, localPagination.selectPagination),
};

const ui = {
  isOpenFilterModal: createSelector(selectUIState, state => state.filtersModal),
  isLoading: loaderSelectors.createLoadingSelector([
    types.filters.fetchFilters.SELECTOR,
    types.search.fetch.SELECTOR,
  ]),
};

const highlights = {
  etAlltimeLoading: createSelector(selectHighlightsState, localHiglights.etAlltimeLoading),
  etAlltimeData: createSelector(selectHighlightsState, localHiglights.etAlltimeData),
  etMergeLoading: createSelector(selectHighlightsState, localHiglights.etMergeLoading),
  etMergeData: createSelector(selectHighlightsState, localHiglights.etMergeData),
  etSpentTimeLoading: createSelector(selectHighlightsState, localHiglights.etSpentTimeLoading),
  etSpentTimeData: createSelector(selectHighlightsState, localHiglights.etSpentTimeData),
};

const charts = {
  getLoading: createSelector(selectChartsState, localCharts.getLoading),
  getData: createSelector(selectChartsState, localCharts.getChartsData),
};

export const pageLoading = createSelector(
  // @ts-ignore
  ui.isLoading,
  highlights.etAlltimeLoading,
  highlights.etMergeLoading,
  highlights.etSpentTimeLoading,
  charts.getLoading,
  filters.isLoading,
  (
    isLoading: boolean,
    etAlltime: boolean,
    etMerge: boolean,
    etSpentTime: boolean,
    chart: boolean,
    filters: boolean
  ) => isLoading || etAlltime || etMerge || etSpentTime || chart || filters
);

export const selectors = {
  search,
  filters,
  sort,
  pagination,
  ui,
  highlights,
  charts,
};
