import { fork } from 'redux-saga/effects';

import { saga as companyNotesSaga } from './companyNotes';
import { saga as searchNotesSaga } from './search';
import { saga as profileNotesSaga } from './profile';

function* notesSaga() {
  yield fork(companyNotesSaga);
  yield fork(searchNotesSaga);
  yield fork(profileNotesSaga);
}

export default notesSaga;
