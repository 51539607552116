import { Link } from 'react-router-dom';
import styled from 'styled-components';
// components
import Icon from '@components/common/Icon';

const LinkOpenInNewTab = styled(Link)`
  color: #000;

  &:hover {
    color: $blue-6;
  }
`;

const OpenInNewTabIcon = styled(Icon)`
  margin-left: 10px;
  width: auto;
  height: auto;
`;

const CompanyLink = styled(Link)`
  font-weight: 600;
  color: $blue-6;

  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 5px;
  max-width: 100%;
`;

export const Styled = {
  LinkOpenInNewTab,
  OpenInNewTabIcon,
  CompanyLink,
};
