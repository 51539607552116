import React from 'react';
import numeral from 'numeral';
// models
import { TableCellWatchlists } from '@optx/models/table/Cell';
// hooks
import useMyWatchlists from '@hooks/my-watchlists/useMyWatchlists';

const TableCellLink: React.FC<TableCellWatchlists> = ({ value, row }) => {
  const { handleMyWatchlistsResults } = useMyWatchlists();

  const handleClick = () => {
    if (row.unique_id) {
      handleMyWatchlistsResults(row.unique_id.toString());
    }
  };

  // check if value is already formatted
  let formattedVal =
    value.toString() === numeral(value).format('0') ? numeral(value).format('0,0') : value;

  return (
    <div role="presentation" onClick={handleClick}>
      {formattedVal}
    </div>
  );
};

export default TableCellLink;
