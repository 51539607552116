import { Typography } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
`;

export const Title = styled(Typography.Title)`
  /* Dark BLue */
  color: #334d6e;
`;

export const TitleValue = styled(Typography.Text)`
  /* Daybreak Blue / blue-6 */
  color: #1890ff;
  margin: 0 30px 0 0;
  font-weight: 600;
  font-size: 20px;
`;

const Styles = {
  Wrapper,
  Title,
  TitleValue,
};

export default Styles;
