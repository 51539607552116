import React, { useState, useContext, Context } from 'react';
import { Typography, Input } from 'antd';
// models
import { BulkEditFieldContext } from '@optx/models/bulkActions';

interface EditRationaleProps {
  context: Context<BulkEditFieldContext>;
  isValid: boolean;
  validation: (isValid: boolean) => void;
}

export const EditRationale: React.FC<EditRationaleProps> = ({ context, isValid, validation }) => {
  const { setSelectedData } = useContext(context);

  const [value, setValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setValue(value);

    if (value.length > 500 && isValid) {
      validation(false);
    } else if (value.length <= 500 && !isValid) {
      validation(true);
    }
  };

  const handleBlur = () => {
    setSelectedData(value);
  };

  return (
    <>
      <Typography.Text className="ant-modal-title">Add New Rationale</Typography.Text>
      <Input.TextArea rows={6} value={value} onChange={handleChange} onBlur={handleBlur} />
      {!isValid && (
        <Typography.Text style={{ color: '#ff4d4f' }}>Maximum 500 characters</Typography.Text>
      )}
    </>
  );
};
