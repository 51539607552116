import React, { useState } from 'react';
import { Typography, Row } from 'antd';
import { OptionsType } from 'react-select';
// models
import { SelectOption } from '@optx/models/Option';
import { SuccessErrorCallback } from '@optx/models/callback';
// constants
import { SELECT_UNKNOWN } from '@optx/components/common/select/constants';
// hooks
import useAsyncSearch from '@optx/common/hooks/select/useAsyncSearch';
// components
import { AsyncMultiSelect } from '@optx/shared/view/molecules/Select';

interface MultiSelectAsyncFieldProps {
  selectedOptions: SelectOption[];
  endpoint: string;
  label: string;
  changeHandler: (value: SelectOption[]) => void;
}

const MultiSelectAsyncField: React.FC<MultiSelectAsyncFieldProps> = ({
  selectedOptions,
  endpoint,
  label,
  changeHandler,
}) => {
  const { loadOptions } = useAsyncSearch({ endpoint: endpoint });
  const [fetchedOptions, setFetchedOptions] = useState<OptionsType<SelectOption>>([]);

  const getLoadOptionsDelay = (query: string, callback: SuccessErrorCallback) => {
    if (query.length > 2) {
      loadOptions(query, options => {
        let callbackResult;

        if (options) {
          options.unshift(SELECT_UNKNOWN);
          const updatedOptions = options.map((option: SelectOption) => {
            return {
              ...option,
              label: option.label,
              value: option.id ?? option.value,
            };
          });
          callbackResult = callback(updatedOptions);

          // set current filter to a cached variable.
          setFetchedOptions(updatedOptions);
        } else {
          const emptyOptions = [];
          emptyOptions.unshift(SELECT_UNKNOWN);
          callbackResult = callback(emptyOptions);

          // set current filter to empty.
          setFetchedOptions(emptyOptions);
        }

        return callbackResult;
      });
    }
  };

  const handleSelectChange = (options: SelectOption[]) => {
    changeHandler(options);
  };

  return (
    <>
      <Row className="form-item-wrapper" gutter={[0, 0]}>
        <Typography.Text>{label}</Typography.Text>
      </Row>
      <Row>
        <div role="presentation" className="touch--multiselect-async-wrapper">
          <AsyncMultiSelect
            className="touch--multiselect-async"
            onChange={options => {
              handleSelectChange(options as SelectOption[]);
            }}
            loadOptions={getLoadOptionsDelay}
            defaultOptions={fetchedOptions}
            defaultValue={selectedOptions}
            loadingMessage={({ inputValue }) =>
              inputValue.length > 2 ? 'Loading...' : 'Begin typing'
            }
          />
        </div>
      </Row>
    </>
  );
};

export default React.memo(MultiSelectAsyncField);
