import React from 'react';
import { Dropdown, Button, Tooltip, Menu } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
// constants
import { COLUMNS_SOURCE_MANEGEMENT_TITLE } from '@optx/constants/titles';
// components
import DisplayColumnToggle from './DisplayColumnToggle';
import ToggleSortType from '../ToggleSortType';

const GridDropdown = () => {
  return (
    <Tooltip title={COLUMNS_SOURCE_MANEGEMENT_TITLE} placement="topRight">
      <Dropdown
        overlay={
          <Menu>
            <DisplayColumnToggle />
            <ToggleSortType />
          </Menu>
        }
        trigger={['click']}
        placement="bottomRight"
      >
        <Button type="text" icon={<SettingOutlined style={{ fontSize: 19 }} />} />
      </Dropdown>
    </Tooltip>
  );
};

export default React.memo(GridDropdown);
