import { createAction } from '@reduxjs/toolkit';
import { CompanyFundingRounds } from '@optx/models/Company';
import * as types from './types';

export const getCompanyFundingRounds = createAction(
  types.GET_COMPANY_FUNDING_ROUNDS,
  (companyId: number) => ({
    payload: companyId,
  })
);

export const getCompanyFundingRoundsSuccess = createAction(
  types.GET_COMPANY_FUNDING_ROUNDS_SUCCESS,
  (content: CompanyFundingRounds, companyId: number) => ({
    payload: content,
    meta: companyId,
  })
);

export const getCompanyFundingRoundsFail = createAction(
  types.GET_COMPANY_FUNDING_ROUNDS_FAIL,
  (error: string) => ({
    payload: error,
  })
);

export default {
  getCompanyFundingRounds,
  getCompanyFundingRoundsSuccess,
  getCompanyFundingRoundsFail,
};
