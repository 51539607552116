import React, { useState } from 'react';
import styled from 'styled-components';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// models
import { ImportSimilarCompanies } from '@optx/models/Company';
import { TableRowSelection } from 'antd/lib/table/interface';
// redux
import {
  actions as importActions,
  selectors as importSelectors,
} from '@features/list-import/state';
// style
import { Styled } from '../BulkImportWatchlist.styled';
import { columns } from './defaultColumnsSimilarCompanies';

const Title = styled.h6`
  font-weight: bold;
  margin-bottom: 25px;
  font-size: 16px;
`;

const rowKey: keyof ImportSimilarCompanies = 'company_name';

const SimilarCompaniesModal = () => {
  const [selectedCompany, setSelectedCompany] = useState<ImportSimilarCompanies | null>(null);
  const dispatch = useDispatch();
  const isVisible = useSelector(importSelectors.showSimilarCompanies);
  const similarCompanies: ImportSimilarCompanies[] = useSelector(
    importSelectors.getSimilarCompanies
  );

  const handleOk = () => {
    if (!selectedCompany) return;

    dispatch(
      importActions.editCompany({
        Company: selectedCompany.company_name,
        URL: selectedCompany.company_url,
        Website: selectedCompany.company_url,
        raw_url: selectedCompany.raw_url,
      })
    );
    dispatch(importActions.showSimilarCompanies(false));
  };

  const handleCancel = () => {
    setSelectedCompany(null);
    dispatch(importActions.showSimilarCompanies(false));
  };

  const rowSelection: TableRowSelection<ImportSimilarCompanies> = {
    type: 'radio',
    onSelect: (record: ImportSimilarCompanies) => {
      setSelectedCompany(record);
    },
  };

  return (
    <Styled.ModalWrapper
      title="Similar Companies"
      visible={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={750}
      destroyOnClose
      okText="Select"
      cancelText="Close"
      background="unset"
    >
      <Title>Select a company to use for the import</Title>
      <Table
        rowKey={rowKey}
        tableLayout="fixed"
        columns={columns}
        dataSource={similarCompanies}
        pagination={false}
        bordered
        className="legacy-table"
        sticky
        style={{ maxHeight: '45vh', overflowY: 'auto' }}
        rowSelection={rowSelection}
      />
    </Styled.ModalWrapper>
  );
};

export default SimilarCompaniesModal;
