import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// models
import CompanyProfile from '@models/Company';
// constants
import ROUTES from '@optx/constants/routes';
// redux
import {
  selectors as viewTypeSelectors,
  actions as viewTypeActions,
} from '@features/grid/view-type';
// components
import LongCard from '@features/company/long-card/LongCard';
import Styled from '@features/company/long-card/LongCardView.styled';
import { selectors as cardSelectors } from '../state';
import useHeaderHeight from '../../card-pannel/utils/useHeaderHeight';
// hooks
import useLoadCompanyData from '../../hooks/useLoadCompanyData';

interface CompanyCardProps {
  company: CompanyProfile;
}

const CompanyCard: React.FC<CompanyCardProps> = ({ company }) => {
  const dispatch = useDispatch();
  const activeCard = useSelector(cardSelectors.getProfile);
  const cardRef = React.useRef<HTMLInputElement>(null);
  const isChangedCompanyCard = useSelector(
    viewTypeSelectors.isChangedCompanyCard('advancedSearch')
  );
  // Touches
  const headerHeight = useHeaderHeight(window.location.pathname === ROUTES.myCompanies ? 126 : 64);
  const active = useMemo(() => {
    if (activeCard?.company_id === company.company_id) {
      return true;
    }

    return false;
  }, [activeCard, company.company_id]);

  const { loadCompanyData } = useLoadCompanyData();

  const handleHeightOfCompanyOutReachCards = () => {
    if (window.location.pathname.startsWith('/all-reports')) {
      return 297;
    }

    return 0;
  };

  const openPannel = (id: number, url: string | null) => {
    dispatch(viewTypeActions.changedCompanyCard({ gridKey: 'advancedSearch', data: false }));
    loadCompanyData(id, url);

    const distanceToTop = cardRef?.current?.getBoundingClientRect().top;

    if (distanceToTop) {
      window.scrollBy({
        top: distanceToTop - headerHeight - handleHeightOfCompanyOutReachCards(),
        behavior: 'smooth',
      });
    }
  };

  // We only want to scroll into view if the card has been selected with the arrows
  useEffect(() => {
    const distanceToTop = cardRef?.current?.getBoundingClientRect().top;

    if (active && isChangedCompanyCard && distanceToTop) {
      window.scrollBy({
        top: distanceToTop - headerHeight - handleHeightOfCompanyOutReachCards(),
        behavior: 'smooth',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, isChangedCompanyCard]);

  return (
    <div ref={cardRef}>
      <Styled.CompanyCard
        hoverable
        onClick={() => openPannel(company.company_id, company.company_url)}
        $active={active}
      >
        <LongCard company={company} />
      </Styled.CompanyCard>
    </div>
  );
};

export default CompanyCard;
