import { Breadcrumb } from 'antd';
import { BarChartOutlined, CalendarOutlined } from '@ant-design/icons';
// constants
import appRoutes from '@constants/routes';
// components
import { StatsSection } from './components';
import Styled from './Sidebar.styled';
import TouchManagement from './TouchManagement';
import PipelineOutreach from './PipelineOutreach';
import DateFilterButton from './components/DateFilterButton';
import ActivityInsights from './ActivityInsights';

const Sidebar = () => {
  return (
    <Styled.StatsWrapper>
      <StatsSection
        title="Touch Management"
        icon={<CalendarOutlined style={{ fontSize: 31, color: 'white' }} />}
        buttonURL={appRoutes.scheduledTouches}
        className="touch"
        blueHeight={108}
      >
        <TouchManagement />
      </StatsSection>
      <StatsSection
        title="My Pipeline & Outreach Recency"
        icon={<BarChartOutlined style={{ fontSize: 31, color: 'white' }} />}
        className="pipeline-outreach"
        blueHeight={153}
      >
        <PipelineOutreach />
      </StatsSection>
      <StatsSection
        title="Activity Insights"
        icon={<BarChartOutlined style={{ fontSize: 31, color: 'white' }} />}
        buttonURL={appRoutes.reports.sourcingOutreachSummary}
        blueHeight={176}
        className="insights"
        extraContent={
          <>
            <DateFilterButton />
            <Breadcrumb>
              <Breadcrumb.Item>My Changes</Breadcrumb.Item>
              <Breadcrumb.Item>Team Average</Breadcrumb.Item>
            </Breadcrumb>
          </>
        }
      >
        <ActivityInsights />
      </StatsSection>
    </Styled.StatsWrapper>
  );
};

export default Sidebar;
