import React, { FC, useEffect, useState } from 'react';
import { TrendingLine } from '@optx/components/common/charts';
import { useSelector } from 'react-redux';
import { RadioChangeEvent } from 'antd/lib/radio';
// models
import Company from '@models/Company';
import { ChartBarIntervalKeys } from '@optx/models/charts/data';
import { ChangeFieldsPayload } from '@optx/features/add-research-rationale/state/interfaces';
// constants
import { OPTX_SCORE_RATIONALE_KEY } from '@optx/features/add-research-rationale/constants';
// utils
import { getIsPsgCompany } from '@utils/company';
// redux
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as cardSelectors } from '@features/long-card/company-card';
// components
import logoCompany from '@assets/images/logo-company.png';
import ScoreRationale from '@optx/features/add-research-rationale/components/rationale/ScoreRationale';
import * as Styled from '../AddResearchRationale.styles';

type OptxScoreRationaleProps = {
  company: Company;
  isShowingRationale?: boolean;
  onChange: (field?: ChangeFieldsPayload, rationale?: ChangeFieldsPayload) => void;
  defaultScore: string;
};

let selectSelector: any;

if (window.location.pathname.startsWith('/user-lists')) {
  selectSelector = searchSelectors.getTrending('lists');
} else if (window.location.pathname.startsWith('/chrome-extension-new')) {
  selectSelector = cardSelectors.getTrending;
} else if (window.location.pathname.startsWith('/my-companies')) {
  selectSelector = searchSelectors.getTrending('myCompanies');
} else {
  selectSelector = searchSelectors.getTrending('advancedSearch');
}

const OptxScoreRationale: FC<OptxScoreRationaleProps> = ({
  company,
  onChange,
  isShowingRationale,
  defaultScore,
}) => {
  const [period, setPeriod] = useState<ChartBarIntervalKeys>('1Y');

  const trending: ChartBarIntervalKeys = useSelector(selectSelector);

  const isChromePlugin = window.location.pathname.startsWith('/chrome-extension');

  const trendKey = defaultScore === 'us' ? 'optx_score_trends' : 'il_optx_score_trends';

  useEffect(() => {
    if (typeof trending === 'string') {
      setPeriod(trending);
    }
  }, [trending]);

  const handleNanOptxScore = () => {
    const score = company[defaultScore === 'us' ? 'score' : 'il_optx_score'];

    if (typeof score !== 'number') {
      return 'N/A';
    }

    return score;
  };

  const handleRationaleChange = (e: RadioChangeEvent) => {
    onChange(undefined, { key: OPTX_SCORE_RATIONALE_KEY, value: e.target.value });
  };

  if (!company) {
    return null;
  }

  return (
    <Styled.OptxScoreContainer isChromePlugin={isChromePlugin}>
      {isShowingRationale === false && (
        <Styled.OptxScoreContentGraphicContainer>
          <Styled.OptxScore>
            <Styled.Logo src={logoCompany} />

            <span>
              {company[trendKey] && (
                <TrendingLine
                  points={company[trendKey]![period]}
                  color="#1890FF"
                  svgWidth={130}
                  svgHeight={130}
                />
              )}
            </span>

            {getIsPsgCompany(company) ? (
              <Styled.PSGCompanyLabel>PSG</Styled.PSGCompanyLabel>
            ) : (
              <Styled.OptxScoreNumber>{handleNanOptxScore()}</Styled.OptxScoreNumber>
            )}
          </Styled.OptxScore>
        </Styled.OptxScoreContentGraphicContainer>
      )}
      {isShowingRationale === true && (
        <>
          <Styled.ScoreRationaleDivider />
          <ScoreRationale
            handleRationaleChange={handleRationaleChange}
            normalizedScore={handleNanOptxScore()}
          />
        </>
      )}
    </Styled.OptxScoreContainer>
  );
};

export default OptxScoreRationale;
