import styled from 'styled-components';
import { Button } from 'antd';

export const SyledButton = styled(Button)`
  /* Gray / gray-7 */
  color: #8c8c8c;
  font-size: 16px;
  line-height: 24px;
  pointer-events: none;
  padding: 6px 40px;
  height: 36px;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 150px;
  background: linear-gradient(180deg, #f0f0f0 -110.27%, rgba(196, 196, 196, 0) 100%);

  ${SyledButton} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default {
  Wrapper,
  Button: SyledButton,
};
