import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import suggestedSearchesReducer, {
  sagas as suggestedSearches,
} from '../../../redux/company/suggested-searches';

const useInjectSuggestedSearches = () => {
  useInjectReducer({
    key: 'suggestedSearches',
    reducer: suggestedSearchesReducer,
  });
  useInjectSaga({
    key: 'suggestedSearches',
    saga: suggestedSearches,
  });
};

export default useInjectSuggestedSearches;
