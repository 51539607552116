import React from 'react';
import styled, { css } from 'styled-components';

interface WrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  $showActionOnHover?: boolean;
  $fill?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: inline-flex;
  max-width: 100%;

  ${props =>
    props.$showActionOnHover &&
    css`
      :hover {
        ${ActionWrapper} {
          display: block;
        }
      }
    `}

  ${props =>
    props.$fill &&
    css`
      width: 100%;
    `}
`;

interface ChildrenWrapperProps {
  $hideActionOnChildrenHover?: boolean;
}

const ChildrenWrapper = styled.div<ChildrenWrapperProps>`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;

  ${props =>
    props.$hideActionOnChildrenHover &&
    css`
      :hover {
        + * {
          visibility: hidden !important;
        }
      }
    `}
`;

const ActionWrapper = styled.div`
  visibility: hidden;
  flex: 0 0 auto;
`;

export const Styled = {
  Wrapper,
  ChildrenWrapper,
  ActionWrapper,
};

interface InlineEditProps {
  action: React.ReactNode;
  showActionOnHover?: boolean;
  fill?: boolean;
  hideActionOnChildrenHover?: boolean;
}

export const InlineEdit: React.FC<InlineEditProps> = React.memo(
  ({ children, action, showActionOnHover, fill, hideActionOnChildrenHover }) => {
    return (
      <Wrapper className="inline-edit-wrapper" $showActionOnHover={showActionOnHover} $fill={fill}>
        <ChildrenWrapper
          className="children-wrapper"
          $hideActionOnChildrenHover={hideActionOnChildrenHover}
        >
          {children}
        </ChildrenWrapper>
        <ActionWrapper className="action-wrapper">{action}</ActionWrapper>
      </Wrapper>
    );
  }
);

InlineEdit.defaultProps = {
  showActionOnHover: true,
};
