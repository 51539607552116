import { createSelector } from 'reselect';
// models
import { GridKeysMain } from '@models/grid';
import { selectFeatureState, StateSlice } from '../../selectFeature';
// utils
import { preProcessSearch } from '@utils/search';

const selectSearchFeatureState = (state: StateSlice) => selectFeatureState(state).search;

export const getSearchKey = (gridKey: GridKeysMain) =>
  createSelector(selectSearchFeatureState, state => preProcessSearch(state[gridKey].searchKey));

export const getTotalCount = (gridKey: GridKeysMain) =>
  createSelector(selectSearchFeatureState, state => state[gridKey].total);

export const isLoading = (gridKey: GridKeysMain) =>
  createSelector(selectSearchFeatureState, state => state[gridKey].loading);
