import styled, { css } from 'styled-components';
import { Tag as $Tag } from 'antd';

const ContentContainer = styled.div<{ isHeightOverflow?: boolean; isRationaleRequired?: boolean }>`
  .profile-information__subtitle {
    margin-top: 22px;
    padding-top: 14px;
    padding-bottom: ${({ isHeightOverflow }) => (isHeightOverflow ? '0' : '12px')};
    border-top: 1px solid #d9d9d9;

    ${({ isRationaleRequired }) =>
      isRationaleRequired &&
      css`
        .subtitle-additional-text {
          color: red;
        }
      `}
  }

  .rationale_select-async {
    display: block;
    width: 100%;
  }

  .ant-typography {
    padding-bottom: 2px;
    margin-bottom: 0 !important;
  }

  .ant-radio-group {
    width: 100%;

    .ant-radio-button-wrapper {
      width: 100%;
    }

    .ant-radio-button-wrapper-checked {
      z-index: auto;
    }
  }
`;

const Tag = styled($Tag)`
  margin-top: 2px;
  margin-bottom: 2px;
  max-width: 100%;
  white-space: inherit;
  word-break: break-word;
`;

export const Styled = {
  Tag,
  ContentContainer,
};
