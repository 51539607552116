import { createFeatureActionType } from '@optx/utils/feature';
import { featureName } from '../../featureName';

export const FETCH_INIT_ACTIVITY = createFeatureActionType(featureName, 'FETCH_INIT_ACTIVITY');
export const FETCH_ACTIVITY = createFeatureActionType(featureName, 'FETCH_ACTIVITY');
export const FETCH_ACTIVITY_SUCCESS = createFeatureActionType(
  featureName,
  'FETCH_ACTIVITY_SUCCESS'
);
export const FETCH_ACTIVITY_FAIL = createFeatureActionType(featureName, 'FETCH_ACTIVITY_FAIL');

export const FETCH_INIT_TOUCH_ACTIVITY = createFeatureActionType(
  featureName,
  'FETCH_INIT_TOUCH_ACTIVITY'
);
export const FETCH_TOUCH_ACTIVITY = createFeatureActionType(featureName, 'FETCH_TOUCH_ACTIVITY');
export const FETCH_TOUCH_ACTIVITY_SUCCESS = createFeatureActionType(
  featureName,
  'FETCH_TOUCH_ACTIVITY_SUCCESS'
);
export const FETCH_TOUCH_ACTIVITY_FAIL = createFeatureActionType(
  featureName,
  'FETCH_TOUCH_ACTIVITY_FAIL'
);
