import { createSelector } from 'reselect';
import { TouchesCount } from '@optx/models/company/CompanyTouch';
import { AppState } from '@optx/redux/interfaces';
import { selectors as fetchSelectors } from '../../../feature/fetch';

// specific selectors
const selectSearchState = (state: AppState) => state.companyTouches.search;

export const isLoading = createSelector(selectSearchState, fetchSelectors.isLoadingSelector);

export const shouldInitialFetch = createSelector(
  selectSearchState,
  fetchSelectors.shouldInitialFetchSelector
);

export const getTouches = createSelector(selectSearchState, state => state.data);

export const getPagination = createSelector(selectSearchState, state => state.pagination);

export const hasMore = createSelector(selectSearchState, state =>
  state.data ? !!state.data.touches_left : false
);

export const getCompanyId = createSelector(selectSearchState, state => state.companyId);

export const getTouchesCount = createSelector(selectSearchState, state => {
  if (!state.data) {
    return {
      scheduled: 0,
      last_six_weeks: 0,
      over_six_months: 0,
      over_six_weeks: 0,
    } as TouchesCount;
  }

  return state.data.number_of_touches;
});

export const getTotalTouches = createSelector(selectSearchState, state => {
  let totalTouches: number = 0;

  if (state.data) {
    const { number_of_touches: numberOfTouches } = state.data;

    totalTouches =
      numberOfTouches.scheduled +
      numberOfTouches.last_six_weeks +
      numberOfTouches.over_six_months +
      numberOfTouches.over_six_weeks;
  }

  return totalTouches;
});
