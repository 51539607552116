import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// local
import * as actions from './actions';
import { BulkAiMlResponse, BulkAiMlSuccess } from './interfaces';

const initialState: BulkAiMlResponse = {
  count: 0,
  successCount: 0,
  data: [],
};

const bulkAiMlSuccessReducer: CaseReducer<
  BulkAiMlResponse,
  PayloadAction<{ data: BulkAiMlSuccess[]; count: number; successCount: number }>
> = (draftState, action) => {
  draftState.data = action.payload.data;
  draftState.count = action.payload.count;
  draftState.successCount = action.payload.successCount;
};

const reducer = createReducer(initialState, builder =>
  builder.addCase(actions.bulkAiMlSuccess, bulkAiMlSuccessReducer)
);

export default reducer;
