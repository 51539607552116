import styled from 'styled-components';
import { Menu, Button } from 'antd';
import { styledColors } from '@utils/theme/colors';

const MenuWrapper = styled(Menu)`
  .anticon {
    color: ${styledColors.blue6};
    margin-right: 8px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

const ButtonLink = styled(Button)`
  border: 0;
  padding: 0;
  height: 0;

  &:hover {
    background: transparent;
  }
`;

export { MenuWrapper, ButtonLink };
