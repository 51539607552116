import styled from 'styled-components';
import Card from '@components/common/cards';
import bg from '@assets/images/scheduled-touches-bg.png';

const StyledCard = styled(Card)`
  background: url(${bg});
  background-size: cover;
  background-position: bottom right;
  background-repeat: no-repeat;
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  .ant-card-body {
    flex: 1;
    padding: 16px 40px;
  }

  .ant-card-head {
    border-bottom: 0;
    margin-bottom: 0;
    padding: 32px 40px 0 40px;
  }
`;

export default {
  StyledCard,
};
