import React from 'react';
import { components, MultiValueProps } from 'react-select';
import { CloseOutlined } from '@ant-design/icons';
import { SelectOption } from '@optx/models/Option';

/**
 * Selected value remove element.
 */
const MultiValueRemove: React.FC<MultiValueProps<SelectOption>> = props => {
  const { data, innerProps } = props;

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    // Use click handler from select binded to item removal.
    innerProps.onClick();
  };

  if (!data.closable) {
    return null;
  }

  return (
    <components.MultiValueRemove {...props}>
      <CloseOutlined onClick={handleClick} />
    </components.MultiValueRemove>
  );
};

export default MultiValueRemove;
