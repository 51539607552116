import React from 'react';
import { Styled } from './OrderedList.styled';
import { OrderedListProps } from './OrderedList.interfaces';

export { Styled };

const RenderLiItem = (children: any, index: number) => (
  <Styled.Item key={index}>{children}</Styled.Item>
);

export const OrderedList: React.FC<OrderedListProps> = ({
  dataSource,
  renderItem = RenderLiItem,
  ...restProps
}) => <Styled.OrderedList {...restProps}>{dataSource.map(renderItem)}</Styled.OrderedList>;

OrderedList.defaultProps = {
  $marker: true,
};
