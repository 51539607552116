import React from 'react';

interface FavoriteButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  isFavorite?: boolean;
}

export const FavoriteButton: React.FC<FavoriteButtonProps> = ({
  isFavorite,
  children,
  ...restProps
}) => {
  return (
    <button type="button" {...restProps}>
      {isFavorite ? 'Remove from Favorites' : 'Add to Favorites'}
    </button>
  );
};

FavoriteButton.defaultProps = {
  className: 'p-0',
};
