import React from 'react';
import { Tooltip } from 'antd';
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
import { ContactPhone, CompanyUserContact } from '@optx/models/api/contacts';

export const TableCellPhones = (value: ContactPhone[] | null, record: CompanyUserContact) => {
  if (!value?.length) {
    return record.phone ? (
      <Tooltip title={record.phone}>{record.phone}</Tooltip>
    ) : (
      DEFAULT_CELL_VALUE
    );
  }

  const handlePhones = () => {
    if (record.phone && value.some(value => value.phone_number !== record.phone)) {
      const listOfPhoneNumbers = value.map(phone => phone.phone_number).concat(record.phone);

      return Array.from(new Set(listOfPhoneNumbers)).join(', ');
    }

    return value.map(phone => phone.phone_number).join(', ');
  };

  return <Tooltip title={handlePhones()}>{handlePhones()}</Tooltip>;
};

export default TableCellPhones;
