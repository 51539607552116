import React from 'react';
// hooks
import useMyWatchlists from '@hooks/my-watchlists/useMyWatchlists';
import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';
import { TableCellWatchlists } from '@optx/models/table/Cell';

const TableCellDefault: React.FC<TableCellWatchlists> = ({ value, row }) => {
  const { handleMyWatchlistsResults } = useMyWatchlists();

  const handleClick = () => {
    if (row.unique_id) {
      handleMyWatchlistsResults(row.unique_id.toString());
    }
  };

  return (
    <div role="presentation" onClick={handleClick}>
      {value || DEFAULT_CELL_VALUE}
    </div>
  );
};

export default TableCellDefault;
