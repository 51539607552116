import React from 'react';
import { Button, Tooltip } from 'antd';
import { ButtonProps } from 'antd/lib/button';

const hintsTooltipText = (
  <ul className="search-hints-list">
    <li className="search-hints-item">Use ; for logical OR</li>
    <li className="search-hints-item">Use , for logical AND</li>
    <li className="search-hints-item">Use &quot; for exact match</li>
  </ul>
);

const SearchCompaniesHints: React.FC<ButtonProps> = props => (
  <Tooltip placement="bottom" title={hintsTooltipText} className="search-tooltip-content">
    <Button type="link" size="large" {...props}>
      HINTS
    </Button>
  </Tooltip>
);

export default SearchCompaniesHints;
