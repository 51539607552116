import { createAction } from '@reduxjs/toolkit';
import { types } from '../types';
import * as company from './company';
import * as insights from './insights';
import * as filters from './filters';
import * as activity from './activity';

const init = createAction(types.init);

export const actions = {
  init,
  company,
  insights,
  filters,
  activity,
};
