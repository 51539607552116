import { combineReducers } from 'redux';
import { UserListsState } from './interfaces';
import listReducer from './details';
import searchReducer from './search';

const reducer = combineReducers<UserListsState>({
  details: listReducer,
  search: searchReducer,
});

export default reducer;
