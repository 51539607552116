import React from 'react';
import { Result, Progress } from 'antd';
import styled from 'styled-components';

const ProgressWrapper = styled(Progress)`
  .ant-progress-inner {
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.08);
  }
`;

const ResultWrapper = styled(Result)`
  padding: 48px 32px;
`;

interface InProgressProps {
  percentage: number;
}

const InProgress: React.FC<InProgressProps> = ({ percentage }) => {
  return (
    <>
      <ProgressWrapper percent={percentage} showInfo={false} />
      <ResultWrapper
        status="success"
        title="In Progress"
        subTitle="Depending on the number of companies, this may take awhile"
        icon={<></>}
      />
    </>
  );
};

export default InProgress;
