import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';

import { CognitoLogoutState } from './interfaces';

import * as actions from './actions';

export const initialState: CognitoLogoutState = {
  loading: false,
  error: '',
  url: '',
};

const cognitoLogOutReducer: CaseReducer<CognitoLogoutState> = draftState => {
  draftState.loading = true;
  draftState.error = '';
};

const cognitoLogOutFailReducer: CaseReducer<CognitoLogoutState, PayloadAction<string>> = (
  draftState,
  action
) => {
  draftState.loading = false;
  draftState.error = action.payload;
};

const cognitoLogOutSuccessReducer: CaseReducer<CognitoLogoutState, PayloadAction<string>> = (
  draftState,
  action
) => {
  draftState.loading = false;
  draftState.url = action.payload;
};

export default createReducer(initialState, builder =>
  builder
    .addCase(actions.fetchCognitoLogoutURL, cognitoLogOutReducer)
    .addCase(actions.fetchCognitoLogoutURLSuccess, cognitoLogOutSuccessReducer)
    .addCase(actions.fetchCognitoLogoutURLFail, cognitoLogOutFailReducer)
);
