import { createAction } from '@reduxjs/toolkit';
import { SearchAutocomplete } from '@optx/models/search-autocomplete';
import {
  FETCH_USER_LISTS_SEARCH_AUTOCOMPLETE,
  FETCH_USER_LISTS_SEARCH_AUTOCOMPLETE_SUCCESS,
} from './types';

export const fetchSearchAutocomplete = createAction(FETCH_USER_LISTS_SEARCH_AUTOCOMPLETE);

export const fetchSearchAutocompleteSuccess = createAction<Array<SearchAutocomplete>>(
  FETCH_USER_LISTS_SEARCH_AUTOCOMPLETE_SUCCESS
);
