import { takeLatest, call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary } from 'lodash';
// models
import { BaseFilter, FilterSource, PreselectedFilter } from '@optx/models/filters';
import { SearchSave, SearchSaveResponse } from '@optx/models/search';
import { ListsSearchData } from '@optx/models/lists/search';
import { PageInfo } from '@optx/models/table/Pagination';
// services
import { SavedSearchesService } from '@services/api';
import NotificationService from '@services/NotificationService';
// utils
import { preProcessSearch } from '@optx/utils/search';
import mapFiltersToURLParams from '@utils/filters/mapFiltersToURLParams';
// redux
import { selectors as userSelectors, actions as userActions } from '@redux/user/information';
import { actions as listsAndSearchesActions } from '@features/lists-and-searches/pagination';
import { selectors as listsAndSearchesSelectors } from '@features/lists-and-searches';
import { AxiosResponse } from 'axios';
import * as actions from './actions';
import * as selectors from './selectors';
import { selectors as filterSourcesSelectors } from '../filters';
import { getSearch } from './selectors';
import { actions as searchActions, selectors as searchSelectors } from '../search/search';
import { searchSavedSearches } from './sagasReusable';

export function* searchSavedSearchesFail() {
  const errorMessage = 'Error fetching watch lists!';
  yield put(actions.fetchContactSavedSearchesFail(errorMessage));
  NotificationService.error(errorMessage);
}

export function* fetchContactsSavedSearchesSaga(action: PayloadAction<string>) {
  const pageNumber: number = yield select(selectors.getPageNumber);
  const userPageNumber: number = yield select(
    userSelectors.getSavedContactSearchesResultsPageNumber
  );
  const pageSize: number = yield select(listsAndSearchesSelectors.pageSize);
  const pagination = { pageNumber, pageSize };

  const query: string = yield select(selectors.getQuery);
  const searchData: ListsSearchData = {
    query: action.payload || '',
    pagination:
      action.payload && action.payload !== query ? { ...pagination, pageNumber: 1 } : pagination,
  };

  try {
    yield call(searchSavedSearches, searchData);

    if (searchData.pagination.pageNumber !== userPageNumber) {
      yield put(
        userActions.updateUserSettings({
          saved_contact_serches_page_number: searchData.pagination.pageNumber,
          saved_contact_searches_filters: action.payload || '',
        })
      );
    } else {
      yield put(
        userActions.updateUserSettings({
          saved_contact_searches_filters: action.payload || '',
        })
      );
    }
  } catch (e: any) {
    yield call(searchSavedSearchesFail);
  }
}

export function* savedContactsSearchPaginationSaga(action: PayloadAction<PageInfo>) {
  const pagination = action.payload;
  const userPageNumber: number = yield select(
    userSelectors.getSavedContactSearchesResultsPageNumber
  );
  const pageSize: number = yield select(listsAndSearchesSelectors.pageSize);
  const query: string = yield select(selectors.getQuery);
  const searchData: ListsSearchData = {
    query,
    pagination: action.payload,
  };

  try {
    yield call(searchSavedSearches, searchData);

    if (pagination.pageSize !== pageSize && searchData.pagination.pageNumber !== userPageNumber) {
      yield put(
        userActions.updateUserSettings({
          lists_and_searches_results_per_page: pagination.pageSize,
          saved_contact_serches_page_number: searchData.pagination.pageSize,
        })
      );
      yield put(
        listsAndSearchesActions.updateMyWatchlistTabsPagination({
          actions: ['watchlists', 'sourceScrub', 'companySearches'],
          pagination,
        })
      );
    } else if (pagination.pageSize !== pageSize) {
      yield put(
        userActions.updateUserSettings({
          lists_and_searches_results_per_page: pagination.pageSize,
        })
      );
      yield put(
        listsAndSearchesActions.updateMyWatchlistTabsPagination({
          actions: ['watchlists', 'sourceScrub', 'companySearches'],
          pagination,
        })
      );
    } else if (searchData.pagination.pageNumber !== userPageNumber) {
      yield put(
        userActions.updateUserSettings({
          saved_contact_serches_page_number: searchData.pagination.pageSize,
        })
      );
    }
  } catch (e: any) {
    yield call(searchSavedSearchesFail);
  }
}

export function* saveSearchSaga(
  action: PayloadAction<{ title: string; filters: Dictionary<PreselectedFilter> }>
) {
  const { title, filters } = action.payload;

  try {
    const searchKey: string = yield select(searchSelectors.getSearchKey);
    const filterMap: Dictionary<BaseFilter> = yield select(filterSourcesSelectors.getFiltersMap);
    const filterURLParams = mapFiltersToURLParams(filterMap, filters);
    const res: AxiosResponse<SearchSaveResponse> = yield call(
      SavedSearchesService.createContactSearch,
      title,
      searchKey,
      filterURLParams
    );

    if (res.data) {
      const filterSources: FilterSource[] = yield select(filterSourcesSelectors.getFilterSources);
      const clearedFilters: Dictionary<PreselectedFilter> = yield select(
        searchSelectors.getClearedFilter
      );

      yield put(actions.saveContactSearchSuccess(res.data, filterSources, clearedFilters));
    } else {
      const errorMessage = 'Error saving contacts search!';
      yield put(actions.saveContactSearchFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = 'Error saving contacts search!';
    yield put(actions.saveContactSearchFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* deleteSearchSaga(action: PayloadAction<number>) {
  const searchId = action.payload;

  try {
    // @ts-ignore
    const res = yield call(SavedSearchesService.deleteContactSearch, searchId);

    if (res.data) {
      yield put(actions.deleteContactSearchSuccess(searchId));
      const successMessage = 'Contacts search deleted successfully';
      NotificationService.success(successMessage);
    } else {
      const errorMessage = 'Error deleting contacts search!';
      yield put(actions.deleteContactSearchFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = 'Error deleting contacts search!';
    yield put(actions.deleteContactSearchFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* renameSearchSaga(
  action: PayloadAction<{ searchId: number | string; title: string }>
) {
  const { searchId, title } = action.payload;

  try {
    // @ts-ignore
    const res = yield call(SavedSearchesService.renameContactSearch, searchId, title);

    if (res.data) {
      yield put(actions.renameContactSearchSuccess(searchId, title));
      const successMessage = 'Contacts search renamed successfully';
      NotificationService.success(successMessage);
    } else {
      const errorMessage = 'Error renaming contacts search!';
      yield put(actions.renameContactSearchFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    let errorMessage: string;

    if (
      Object.values(e.response.data.errors)[0] ===
      `Contact search list with a title ${title} already exists.`
    ) {
      errorMessage = Object.values(e.response.data.errors)[0] as string;
    } else {
      errorMessage = 'Error renaming contacts search!';
    }

    yield put(actions.renameContactSearchFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* resetToSearchSaga(
  action: PayloadAction<{ searchId: number | string; title: string }>
) {
  const { searchId, title } = action.payload;

  const savedSearch: SearchSave = yield select(state => getSearch(state, searchId));
  const { searchKey, filters } = savedSearch;
  yield put(
    searchActions.reset(
      false,
      { searchKey: searchKey ? preProcessSearch(searchKey) : undefined, filter: filters },
      title
    )
  );
}

export default function* contactsSavedSearchesSaga() {
  yield takeLatest(actions.fetchContactSavedSearches, fetchContactsSavedSearchesSaga);
  yield takeLatest(actions.saveContactSearch, saveSearchSaga);
  yield takeLatest(actions.deleteContactSearch, deleteSearchSaga);
  yield takeLatest(actions.renameContactSearch, renameSearchSaga);
  yield takeLatest(actions.resetToSearch, resetToSearchSaga);
  yield takeLatest(actions.savedContactsSearchPagination, savedContactsSearchPaginationSaga);
}
