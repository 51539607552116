import { Button, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { ColumnType } from 'antd/lib/table';
import { useDispatch, useSelector } from 'react-redux';
// models
import { TableColumnType } from '@optx/models/Financial';
// utils
import { createColumn } from '@optx/utils/dealPresentation';
// redux
import { selectors, actions } from '@redux/company/opportunity-presentation';
// components
import AddFinancialYearModal from './AddFinancialYearModal';
import * as Styled from '../styles/DealPresentation.styled';
import OtherFinancialsInformation from './OtherFinancialsInformation';

const Financials: React.FC<{ companyId: number; isChromeExtension?: boolean }> = ({
  companyId,
  isChromeExtension,
}) => {
  const dispatch = useDispatch();
  const isOpenModal = useSelector(selectors.isAddYearsOpenModal);
  const [columns, setColumns] = useState<ColumnType<TableColumnType>[]>([]);

  const getAllFinancialYears = useSelector(selectors.getAllFinancialYears);
  const getData = useSelector(selectors.getData);

  const handleToggle = useCallback(() => dispatch(actions.setAddYearsOpenModal()), [dispatch]);

  useEffect(() => {
    setColumns(createColumn(getAllFinancialYears));
    setColumns(columns => [
      ...columns,
      {
        title: () => {
          return (
            <Button className="add-years-button" type="link" onClick={handleToggle}>
              + ADD YEARS
            </Button>
          );
        },
        width: 210,
        className: 'add-years-column',
      },
    ]);
  }, [getAllFinancialYears, handleToggle]);

  return (
    <Styled.FinancialsWrapper isChromeExtension={isChromeExtension}>
      <Table
        className="financials-table"
        dataSource={getData}
        columns={columns}
        rowKey={record => record.name + record.key}
        pagination={false}
        scroll={{ x: '100%' }}
      />
      <OtherFinancialsInformation isChromeExtension={isChromeExtension} companyId={companyId} />
      <AddFinancialYearModal
        companyId={companyId}
        isOpen={isOpenModal}
        isChromeExtension={isChromeExtension}
        toggle={handleToggle}
      />
    </Styled.FinancialsWrapper>
  );
};

export default Financials;
