import React, { useMemo } from 'react';
// components
import { PieChart } from '@optx/features/charts/PieChart';

const COLORS = ['#52C41A', '#eb2f96'];

interface VerifiedFitChartProps {
  data: { label: string; value: string | number }[];
  verifiedFit: string;
}

const VerifiedFitChart: React.FC<VerifiedFitChartProps> = ({ data, verifiedFit }) => {
  const chartData = useMemo(
    () =>
      data.map((item, index) => {
        return {
          label: item.label,
          value: Number(item.value),
          color: COLORS[index],
        };
      }),
    [data]
  );

  return (
    <div>
      <div className="pie-chart">
        <PieChart
          data={chartData}
          chartHeight={130}
          dataKey="value"
          innerRadius={55}
          outerRadius={65}
          paddingAngle={-5}
          cornerRadius={10}
          cx="50%"
          cy="50%"
        />
        <span className="total">
          <b>{verifiedFit}</b>Verified Fit
        </span>
      </div>
    </div>
  );
};

export default VerifiedFitChart;
