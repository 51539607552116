import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';
import * as actions from './actions';
import * as models from './interfaces';
import { ListsService } from '../../../services/api';

// search
export function* searchSourceScrubLists(data: models.SearchPayload) {
  const res: AxiosResponse<models.SourceScrubListsResponse> = yield call(
    ListsService.searchSourceScrubLists,
    data
  );
  yield put(actions.searchSourceScrubListsSuccess(res.data, data));
}
