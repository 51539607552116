import React from 'react';
import { useInjectReducer } from 'redux-injectors';
// styles
import './styles.scss';
// redux
import { useSelector } from 'react-redux';
import reducer, { selectors as loaderSelectors } from '@redux/global-loader';
import Styled from './GlobalLoader.styles';

const useInjectLoader = () => {
  useInjectReducer({ key: 'loaderReducer', reducer });
};

const GlobalLoader = () => {
  useInjectLoader();
  const isLoading = useSelector(loaderSelectors.isLoading);

  if (!isLoading) {
    return null;
  }

  return (
    <Styled.Preloader>
      <Styled.Status>
        <Styled.Spinner />
      </Styled.Status>
    </Styled.Preloader>
  );
};

export default React.memo(GlobalLoader);
