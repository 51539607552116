import { AxiosResponse } from 'axios';
import { pick } from 'lodash';
import { CompanyUserContact } from '@optx/models/api/contacts';
import { SalesloftEmails, SalesloftContact } from '@optx/models/Salesloft';

/**
 * parse different types of errors coming from api for adding to salesloft
 * @param {AxiosResponse<any>} error object with error message
 * @param {string} defaultMessage a default message,
 * @param {(url: string) => void)} callback optional callback
 */
export const handleSalesloftError = (
  error: AxiosResponse<any>,
  defaultMessage: string,
  callback?: (url: string) => void
) => {
  let errorMessage = defaultMessage;
  const errorApi = JSON.parse(error.request.responseText);

  if (error.status === 424) {
    if (errorApi.errors['424 Failed Dependency'] === 'SalesLoft Application not authorized.') {
      callback && callback('');
      errorMessage = 'Authentication Required';
    } else if (errorApi.errors['424 Failed Dependency'].includes('email')) {
      errorMessage = errorApi.errors['424 Failed Dependency'];
    } else {
      errorMessage = 'Cadence is already in progress for this person';
    }
  } else if (error.status === 400) {
    const errorFieldList = [] as Array<string>;
    Object.keys(errorApi.errors).forEach(errorField => {
      switch (errorField) {
        case 'first_name':
          errorFieldList.push('First Name');
          break;
        case 'last_name':
          errorFieldList.push('Last Name');
          break;
        case 'title':
          errorFieldList.push('Title');
          break;
        case 'primary_email':
          errorFieldList.push('Email');
          break;
        default:
          break;
      }
    });
  }

  return errorMessage;
};

/**
 * compile a string of emails or undefined values, separated by space.
 * the values itself come from email field inputs, some can contain actual email
 * and some can be undefined.
 * the returned value needs to a string of emails separated by commas
 * @param {string} emails string of emails or undefined values, separated by single space
 */
export const emailFieldsToString = (emails: string) =>
  emails
    .replace(/undefined/g, '')
    .trim()
    .replaceAll(' ', ',')
    .replace(/,{2,3}/, ',');

/**
 * parse error messages coming from api into more user friendly messages
 * @param {string} message error message to parse
 */
export const parseSalesloftErrors = (message: string | null) => {
  if (message && message.includes('SalesLoft Application not authorized')) {
    return 'Not logged in to Sales Loft';
  }

  if (message && message.includes('SalesLoft API request failed')) {
    return 'Cadence is already in progress for this person';
  }

  return message;
};

/**
 * validate data for a list of contacts, each contact must have
 * first and last name, title and primary email.
 * return a list of all errors for each contact
 * @param {Partial<CompanyUserContact | SalesloftContact>[]} contacts list of contacts
 * @param {number[] | undefined} contactIds list of contact ids, selected by user
 */
export const getContactErrors = (
  contacts: Partial<CompanyUserContact | SalesloftContact>[],
  contactIds?: number[]
) => {
  const errorMessages: string[] = [];
  let filteredContacts = contacts;

  if (contactIds) {
    filteredContacts = contacts.filter(contact => contactIds.includes(contact.person_id as number));
  }

  filteredContacts.forEach(contact => {
    const { first_name: firstName, last_name: lastName, title, email } = contact;
    const primaryEmail = (contact as SalesloftContact).primary_email;
    let errorMsg = '';

    if (!firstName) {
      errorMsg += 'first name ';
    }

    if (!lastName) {
      errorMsg += 'last name ';
    }

    if (!title) {
      errorMsg += 'title ';
    }

    if (!(email || primaryEmail)) {
      errorMsg += 'email ';
    }

    if (errorMsg) {
      errorMessages.push(
        `${
          !firstName && !lastName ? 'Contact' : `${firstName!} ${lastName?.concat("'s")}`
        } email is missing `
      );
    }
  });

  return errorMessages;
};

/**
 * format the emails for salesloft
 * @param {string | null} emails comma separated email list
 */
export const getSalesloftEmails: (emails: string | null) => SalesloftEmails = emails => {
  const salesloftEmails: SalesloftEmails = {};

  if (emails) {
    emails.split(',').forEach((email, index) => {
      switch (index) {
        case 0:
          salesloftEmails.primary_email = email;
          break;
        case 1:
          salesloftEmails.secondary_email = email;
          break;
        case 2:
          salesloftEmails.tertiary_email = email;
          break;
        case 3:
          salesloftEmails.quarternary_email = email;
          break;
        default:
          break;
      }
    });
  }

  return salesloftEmails;
};

/**
 * based on an array of ids, return the contacts selected and only
 * the required properties
 * @param {CompanyUserContact[]} contacts all contacts
 * @param {number[]} contactIds selected contact ids
 */
// typescript throws error for potentially sending back undefined,
// but we're the value returned by find method
// @ts-ignore
export const getSelectedContacts: (
  contacts: CompanyUserContact[],
  contactIds: number[]
) => SalesloftContact[] = (contacts, contactIds) => {
  // eslint-disable-next-line consistent-return
  return contactIds.map(id => {
    const contact = contacts.find(contact => contact.person_id === id);

    if (contact) {
      return {
        ...pick(contact, [
          'first_name',
          'last_name',
          'person_id',
          'title',
          'phone',
          'phone_type',
          'additional_phones',
        ]),
        ...getSalesloftEmails(contact.email),
      };
    }

    return null;
  });
};
