import { createAction } from '@reduxjs/toolkit';
// models
import { types } from '../types';
import { FiltersState } from '../interfaces';

export const fetchFilters = createAction(types.filters.FETCH_FILTERS);
export const fetchFiltersSuccess = createAction<FiltersState>(types.filters.FETCH_FILTERS_SUCCESS);
export const fetchFiltersFail = createAction<string>(types.filters.FETCH_FILTERS_FAIL);

export const updateFilter = createAction<{ value: [string, string] }>(types.filters.UPDATE_FILTER);
