import styled from 'styled-components';
import { Menu } from 'antd';

const MenuList = styled(Menu)`
  .ant-dropdown-menu-item[aria-disabled='false'] {
    cursor: pointer;
  }
`;

export default {
  MenuList,
};
