/* eslint-disable max-len */
import { Dictionary, each, isEqual } from 'lodash';
import { SearchPayloadRequest } from '@optx/models/api/contacts';
import queryString from 'query-string';
// services
import NotificationService from '@services/NotificationService';
// constants
import appRoutes from '@constants/routes';
import { FINANCIAL_YEARS, SEARCH_MINIMUM_CHAR_COUNT } from '@constants/search';
import {
  columnDisplayInitialState,
  customUIViewIds,
  customUIViews,
} from '@constants/table/columnDisplay/company-search';
import { COMPANY_DEFAULT_SORT } from '@constants/table/sort/defaultSort';
import SORT_ORDER from '@constants/table/sortOrder';
// models
import { PageInfo } from '@models/table/Pagination';
import { CustomUIViewIds } from '@optx/models/user';
import { SortByRule } from '@models/table/sorting';
import Company from '@models/Company';
import { PreselectedFilter, BaseFilter } from '@models/filters';
import { SearchSave } from '@models/search';
import {
  SelectOption,
  LogicOption,
  FormCheckableRangeOption,
  FormCheckboxOption,
} from '@optx/models/Option';
// utils
import mapFiltersToURLParams from '@utils/filters/mapFiltersToURLParams';
import { getDefaultVisibleColumns } from '@utils/columnsDefaultVisible';
import { validateMinChars } from '@utils/validation';

interface SearchCounterPayload {
  query?: string;
  [k: string]: any;
}

interface SearchPayload extends SearchCounterPayload {
  per_page?: number;
  page?: number;
  column_name?: string;
  analyst_id?: string | number;
  sort_fields?: Array<string>;
  sort_orders?: Array<string>;
}

/**
 * Map search data to API query data.
 * @param searchData
 */
export const mapSearchQuery = (
  searchData: SearchPayloadRequest,
  includePagination = true,
  performanceTesting?: string | null,
  hasCustomColumnYears = true
) => {
  const {
    pagination: { pageNumber, pageSize },
    sortBy,
    filter,
    searchKey,
    tableGridQuery,
    sourcingOutReachColumnName,
    analystId,
  } = searchData;
  const payload: SearchPayload = {};

  // if function is used for checking filters against
  // saved searches don't include the pagination
  if (includePagination) {
    payload.per_page = pageSize;
    payload.page = pageNumber;
  }

  if (hasCustomColumnYears) {
    payload.arr_years = FINANCIAL_YEARS;
    payload.ebitda_years = FINANCIAL_YEARS;
    payload.growth_years = FINANCIAL_YEARS;
  }

  if (performanceTesting != null) {
    payload.useormpagination = true;
  }

  if (tableGridQuery) {
    const parsedGridQuery = queryString.parse(tableGridQuery);

    const parsedGridValues = Object.values(parsedGridQuery);

    Object.keys(parsedGridQuery).forEach((key, index) => {
      if (key === 'analyst_name') {
        return;
      }

      if (key === 'touch_type' || key === 'so_stage') {
        payload[key] = parsedGridValues[index]?.toString().split(',');
      } else {
        payload[key] = parsedGridValues[index];
      }
    });
  }

  if (sourcingOutReachColumnName) {
    payload.column_name = sourcingOutReachColumnName;
  }

  if (analystId === '' || analystId) {
    payload.analyst_id = analystId;
  }

  // map sort
  if (sortBy && sortBy.length) {
    const sortFields: Array<string> = [];
    const sortOrders: Array<string> = [];

    searchData.sortBy.forEach(sortRule => {
      sortFields.push(sortRule.id);
      sortOrders.push(sortRule.desc ? SORT_ORDER.DESCENDING : SORT_ORDER.ASCENDING);
    });

    payload.sort_fields = sortFields;
    payload.sort_orders = sortOrders;
  }

  Object.keys(filter).forEach(key => {
    if (key === 'last_touch_initiator') {
      payload[`${key}_id`] = filter[key];
    } else {
      payload[key] = filter[key];
    }
  });

  // map search key
  if (searchKey) {
    payload.query = searchKey;
  }

  if (typeof searchData?.searchOrigin === 'number') {
    payload.search_origin = searchData.searchOrigin;
  }

  return payload;
};

/**
 * Map sorting to API query data.
 * @param sortBy
 */
export const mapSortQuery = (sortBy: Array<SortByRule<any>>) => {
  const payload: SearchPayload = {};

  // map sort
  if (sortBy && sortBy.length) {
    const sortFields: Array<string> = [];
    const sortOrders: Array<string> = [];

    sortBy.forEach(sortRule => {
      sortFields.push(sortRule.id);
      sortOrders.push(sortRule.desc ? SORT_ORDER.DESCENDING : SORT_ORDER.ASCENDING);
    });

    payload.sort_fields = sortFields;
    payload.sort_orders = sortOrders;
  }

  return payload;
};

/**
 * Map pagination to API query data.
 * @param pagination
 */
export const mapPaginationQuery = (pagination: PageInfo) => {
  const payload: SearchPayload = {};

  // map pagination
  if (pagination) {
    payload.per_page = pagination.pageSize;
    payload.page = pagination.pageNumber;
  }

  return payload;
};

export const mapSearchCounterQuery = (
  searchKey: string,
  filter: Dictionary<undefined | string | Array<string | number>>,
  sourcingOutReachAnalystId?: string | number,
  sourcingOutReachColumnName?: string,
  listType?: string
) => {
  const payload: SearchCounterPayload = {};

  Object.keys(filter).forEach(key => {
    if (key === 'last_touch_initiator') {
      payload[`${key}_id`] = filter[key];
    } else {
      payload[key] = filter[key];
    }
  });

  if (sourcingOutReachAnalystId !== undefined) {
    payload.analyst_id = sourcingOutReachAnalystId;
  }

  if (sourcingOutReachColumnName) {
    payload.column_name = sourcingOutReachColumnName;
  }

  // map search key
  if (searchKey) {
    payload.query = searchKey;
  }

  return payload;
};

// function to check new search requests against saved searches
export const matchSavedSearches = (
  data: SearchPayloadRequest,
  savedSearchList: Array<SearchSave>,
  includeSorting = false
) => {
  let searchTitle = '';
  const filterString = queryString.stringify(
    mapSearchQuery(
      {
        ...data,
        sortBy: includeSorting ? data.sortBy : [],
      },
      false
    ),
    {
      arrayFormat: 'comma',
      encode: false,
    }
  );

  savedSearchList.forEach(search => {
    if (search.search_criteria === filterString) {
      searchTitle = search.title;
    }
  });

  return searchTitle;
};

/**
 * stringify filter, sorting and search key into a string to be compared with somewething like
 * the search criteria from a saved search
 * @param {Dictionary<string | Array<number | string>>} filter filter query
 * @param {Array<SortingRule<Company>> | undefined} sortBy sorting
 * @param {string} searchKey the search term
 */
export const getFilterString = (
  filter: Dictionary<string | Array<number | string>>,
  sortBy: Array<SortByRule<Company>> | undefined,
  searchKey: string
) => {
  return queryString.stringify(
    mapSearchQuery(
      {
        pagination: {
          pageNumber: 1,
          pageSize: 50,
        },
        filter,
        searchKey,
        sortBy: sortBy || [],
      },
      false,
      null,
      false
    ),
    {
      arrayFormat: 'comma',
      encode: false,
    }
  );
};

/**
 * organize saved searches by what settings (filters, sorting, columns) each of them have saved
 * @param {SearchSave[]} views list of saved searches
 */
const getArrangedSavedSearches = (views: SearchSave[]) => {
  // arrange the saved searches into separate arrays by priority.
  // we want to return the view with the most data points matched, instead of how old or new it is
  // priority matching is as follows:
  // 01. match filters, sorting, column visibility, column order and pinned columns
  // 02. match filters, sorting, column visibility and column order
  // 03. match filters, sorting, column visibility - for views created before order and pin functionality was added
  // 04. match filters, sorting and pinned columns
  // 05. match filters and sorting
  // 06. match filters, column visibility, column order and pinned columns
  // 07. match filters, column visibility and column order
  // 08. match filters and column visibility
  // 09. match filters and pinned columns
  // 10. match filters
  // 11. match sorting, column visibility, column order and pinned columns
  // 12. match sorting, column visibility and column order
  // 13. match sorting and column visibility - for views created before order and pin functionality was added
  // 14. match sorting and pinned columns
  // 15. match sorting
  // 16. match column visibility, column order and pinned columns
  // 17. match column visibility and column order
  // 18. match column visibility - for views created before order and pin functionality was added
  // 19. match pinned columns
  // after these iterations try to match OPTX Default view
  // when a match is found return the title and view data and stop the search
  // eslint-disable-next-line
  const arrangedSavedSearches: Array<SearchSave[]> = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ];
  views.forEach(view => {
    const {
      search_criteria: criteria,
      sortBy,
      columns: viewColumns,
      column_order: columnOrder,
      pinned_columns: pinnedColumns,
      searchKey,
    } = view;
    const withFilters = hasFilters(criteria) || searchKey;
    const withVisibility = viewColumns !== null;
    const withOrder = columnOrder !== null;
    const withPins = pinnedColumns !== null && pinnedColumns !== '';

    if (withFilters && sortBy && withVisibility && withOrder && withPins) {
      arrangedSavedSearches[0].push(view);
    } else if (withFilters && sortBy && withVisibility && withOrder && !withPins) {
      arrangedSavedSearches[1].push(view);
    } else if (withFilters && sortBy && withVisibility && !withOrder && !withPins) {
      arrangedSavedSearches[2].push(view);
    } else if (withFilters && sortBy && !withVisibility && !withOrder && withPins) {
      arrangedSavedSearches[3].push(view);
    } else if (withFilters && sortBy && !withVisibility && !withOrder && !withPins) {
      arrangedSavedSearches[4].push(view);
    } else if (withFilters && !sortBy && withVisibility && withOrder && withPins) {
      arrangedSavedSearches[5].push(view);
    } else if (withFilters && !sortBy && withVisibility && withOrder && !withPins) {
      arrangedSavedSearches[6].push(view);
    } else if (withFilters && !sortBy && withVisibility && !withOrder && !withPins) {
      arrangedSavedSearches[7].push(view);
    } else if (withFilters && !sortBy && !withVisibility && !withOrder && withPins) {
      arrangedSavedSearches[8].push(view);
    } else if (withFilters && !sortBy && !withVisibility && !withOrder && !withPins) {
      arrangedSavedSearches[9].push(view);
    } else if (!withFilters && sortBy && withVisibility && withOrder && withPins) {
      arrangedSavedSearches[10].push(view);
    } else if (!withFilters && sortBy && withVisibility && withOrder && !withPins) {
      arrangedSavedSearches[11].push(view);
    } else if (!withFilters && sortBy && withVisibility && !withOrder && !withPins) {
      arrangedSavedSearches[12].push(view);
    } else if (!withFilters && sortBy && !withVisibility && !withOrder && withPins) {
      arrangedSavedSearches[13].push(view);
    } else if (!withFilters && sortBy && !withVisibility && !withOrder && !withPins) {
      arrangedSavedSearches[14].push(view);
    } else if (!withFilters && !sortBy && withVisibility && withOrder && withPins) {
      arrangedSavedSearches[15].push(view);
    } else if (!withFilters && !sortBy && withVisibility && withOrder && !withPins) {
      arrangedSavedSearches[16].push(view);
    } else if (!withFilters && !sortBy && withVisibility && !withOrder && !withPins) {
      arrangedSavedSearches[17].push(view);
    } else if (!withFilters && !sortBy && !withVisibility && !withOrder && withPins) {
      arrangedSavedSearches[18].push(view);
    }
  });

  return arrangedSavedSearches;
};

/**
 * this function is called for each type of arranged saved searches. depending on the type of search
 * we are trying to match with, we set certain values for each saved setting (filter, sort, columns)
 * @param {Dictionary<string | Array<number | string>>} currentFilter applied filter
 * @param {string} searchKey search term used
 * @param {Array<SortingRule<any>>} sortBy applied sorting
 * @param {number} size size of the list of saved searches we are looking through
 * @param {number} index position of the list we are searching through
 * @param {string} filter current saved search's filter string, including sorting
 * @param {string | null} columns current saved search's visible columns
 * @param {string | null} columnOrder current saved search's column order
 * @param {string | null} pinnedColumns current saved search's pinned columns
 */
const getUpdatedSavedSearchSettings = (
  currentFilter: Dictionary<string | Array<number | string>>,
  searchKey: string,
  sortBy: Array<SortByRule<any>>,
  size: number,
  index: number,
  filter: string,
  columns: string | null,
  columnOrder: string | null,
  pinnedColumns: string | null
) => {
  let filterString = filter;
  let visibleColumns: string | null = columns;
  let order: string | null = columnOrder;
  let pins: string | null = pinnedColumns;

  // depending on the type of saved search we are trying to match update query string and columns
  if (size) {
    switch (index) {
      case 1: {
        pins = '';
        break;
      }

      case 2: {
        order = null;
        pins = '';
        break;
      }

      case 3: {
        visibleColumns = null;
        order = null;
        break;
      }

      case 4: {
        visibleColumns = null;
        order = null;
        pins = '';
        break;
      }

      case 5: {
        filterString = getFilterString(currentFilter, undefined, searchKey);
        break;
      }

      case 6: {
        filterString = getFilterString(currentFilter, undefined, searchKey);
        pins = '';
        break;
      }

      case 7: {
        filterString = getFilterString(currentFilter, undefined, searchKey);
        order = null;
        pins = '';
        break;
      }

      case 8: {
        filterString = getFilterString(currentFilter, undefined, searchKey);
        visibleColumns = null;
        order = null;
        break;
      }

      case 9: {
        filterString = getFilterString(currentFilter, undefined, searchKey);
        visibleColumns = null;
        order = null;
        pins = '';
        break;
      }

      case 10: {
        filterString = queryString.stringify(mapSortQuery(sortBy), {
          arrayFormat: 'comma',
        });
        break;
      }

      case 11: {
        filterString = queryString.stringify(mapSortQuery(sortBy), {
          arrayFormat: 'comma',
        });
        pins = '';
        break;
      }

      case 12: {
        filterString = queryString.stringify(mapSortQuery(sortBy), {
          arrayFormat: 'comma',
        });
        order = null;
        pins = '';
        break;
      }

      case 13: {
        filterString = queryString.stringify(mapSortQuery(sortBy), {
          arrayFormat: 'comma',
        });
        visibleColumns = null;
        order = null;
        break;
      }

      case 14: {
        filterString = queryString.stringify(mapSortQuery(sortBy), {
          arrayFormat: 'comma',
        });
        visibleColumns = null;
        order = null;
        pins = '';
        break;
      }

      case 15: {
        filterString = '';
        break;
      }

      case 16: {
        filterString = '';
        pins = '';
        break;
      }

      case 17: {
        filterString = '';
        order = null;
        pins = '';
        break;
      }

      case 18: {
        filterString = '';
        visibleColumns = null;
        order = null;
        break;
      }
    }
  }

  return { filterString, visibleColumns, order, pins };
};

/**
 * function used to compare filter string for saved searches
 * @param {string | null} searchFilterString filter string from the saved search or null
 * @param {string | null} currentFilterString current filter string or null
 */
const compareFilterString = (
  searchFilterString: string | null,
  currentFilterString: string | null
) => {
  if (searchFilterString === currentFilterString) {
    return true;
  }

  if (searchFilterString && currentFilterString) {
    const searchList = searchFilterString.split('&');
    const currentList = currentFilterString.split('&');

    if (
      searchList.length === currentList.length &&
      searchList.every(columnId => currentList.includes(columnId))
    ) {
      return true;
    }
  }

  return false;
};

/**
 * function used to compare column visibility for saved searched created before and
 * after implementation of sorting and pinning functionality
 * @param {string | null} searchColumns column string from the saved search or null
 * @param {string | null} currentColumns current column visibility as string or null
 */
export const compareColumnVisibility = (
  searchColumns: string | null,
  currentColumns: string | null
) => {
  // check for equality first
  if (searchColumns === currentColumns) {
    return true;
  }

  // if both values are string, we need to compare every column id,
  // without taking into account the order of the items. this is needed for
  // saved searches with columns saved before implementation of sorting and pinning functionality
  if (searchColumns && currentColumns) {
    const searchList = searchColumns.replace(',row_actions', '').split(',');
    const currentList = currentColumns.split(',');

    const areEqual = searchList.every(columnId => {
      if (columnId === 'score' || columnId === 'il_optx_score') {
        return currentList.includes('score') || currentList.includes('il_optx_score');
      }

      return currentList.includes(columnId);
    });

    if (areEqual) {
      return true;
    }
  }

  return false;
};

/**
 * function used to compared pinned columns
 * @param searchPinnedColumns pinned columns string from the saved search or null
 * @param currentPinnedColumns current pinned columns as string or null
 */
export const comparePinnedColumns = (
  searchPinnedColumns: string | null,
  currentPinnedColumns: string | null
) => {
  const normalizedSearchPinnedColumns = searchPinnedColumns
    ?.replace(/[\s,]/g, '')
    .replace(/il_optx_score|score/g, '');
  const normalizedCurrentPinnedColumns = currentPinnedColumns
    ?.replace(/[\s,]/g, '')
    .replace(/il_optx_score|score/g, '');

  if (normalizedSearchPinnedColumns === normalizedCurrentPinnedColumns) {
    return true;
  }

  if (
    (!normalizedSearchPinnedColumns || normalizedSearchPinnedColumns === '') &&
    (!normalizedCurrentPinnedColumns || normalizedCurrentPinnedColumns === '')
  ) {
    return true;
  }

  return false;
};

/**
 * function used to compared column order
 * @param searchColumnOrder column order string from the saved search or null
 * @param currentColumnOrder current column order as string or null
 */
export const compareColumnOrder = (
  searchColumnOrder: string | null,
  currentColumnOrder: string | null
) => {
  if (searchColumnOrder === currentColumnOrder) {
    return true;
  }

  if (searchColumnOrder && currentColumnOrder) {
    const searchList = searchColumnOrder?.replace(/[\s,]/g, '').replace(/il_optx_score|score/g, '');
    const currentList = currentColumnOrder
      ?.replace(/[\s,]/g, '')
      .replace(/il_optx_score|score/g, '');

    const isColumnOrderEqual = searchList === currentList;

    return isColumnOrderEqual;
  }

  return false;
};

/**
 * function to try and match a set of filters, sorting and column visiblity list to a view *
 * @param {SearchPayloadRequest} data search data
 * @param {string} columns string list of visible columns
 * @param {SearchSave[]} views saved searches
 * @param {SearchSave} defaultOptxView OPTX Default view
 */
export const matchView = (
  data: SearchPayloadRequest,
  columns: string,
  columnOrder: string | null,
  pinnedColumns: string | null,
  views: SearchSave[],
  defaultOptxView: SearchSave
) => {
  const { filter, sortBy, searchKey } = data;
  const currentFilter = { ...filter };
  let matchedTitle: string | undefined;
  let matchedView: SearchSave | undefined;

  // specific case for watchlists
  delete currentFilter.saved_list_id;
  delete currentFilter.ss_list_id;

  const arrangedSavedSearches: Array<SearchSave[]> = getArrangedSavedSearches(views);

  each(arrangedSavedSearches, (searches, index) => {
    const { filterString, visibleColumns, order, pins } = getUpdatedSavedSearchSettings(
      currentFilter,
      searchKey,
      sortBy,
      searches.length,
      index,
      getFilterString(currentFilter, sortBy, searchKey),
      columns,
      columnOrder,
      pinnedColumns
    );

    each(searches, search => {
      if (
        compareFilterString(search.search_criteria, filterString) &&
        compareColumnVisibility(search.columns, visibleColumns) &&
        compareColumnOrder(search.column_order, order) &&
        comparePinnedColumns(search.pinned_columns, pins)
      ) {
        matchedTitle = search.title;
        matchedView = search;

        return false;
      }

      return true;
    });

    if (matchedTitle) {
      return false;
    }

    return true;
  });

  const filterString = getFilterString(currentFilter, sortBy, searchKey);

  if (
    !matchedTitle &&
    filterString === defaultOptxView.search_criteria &&
    isEqual(sortBy, COMPANY_DEFAULT_SORT) &&
    compareColumnVisibility(columns, getDefaultVisibleColumns(columnDisplayInitialState)) &&
    compareColumnOrder(columnOrder, defaultOptxView.column_order) &&
    comparePinnedColumns(pinnedColumns, defaultOptxView.pinned_columns)
  ) {
    matchedTitle = defaultOptxView.title;
    matchedView = defaultOptxView;
  }

  if (
    !matchedTitle &&
    compareColumnVisibility(columns, customUIViews.sourcingOrder.columns) &&
    compareColumnOrder(columnOrder, customUIViews.sourcingOrder.column_order) &&
    comparePinnedColumns(pinnedColumns, customUIViews.sourcingOrder.pinned_columns)
  ) {
    matchedTitle = customUIViews.sourcingOrder.title;
    matchedView = customUIViews.sourcingOrder;
  }

  return {
    matchedTitle,
    matchedView,
  };
};

/**
 * check a stringified query for filters
 * @param {string} query stringified filters and sorting
 */
export const hasFilters = (query: string) => {
  const parsedFilters = queryString.parse(query, { arrayFormat: 'comma' });
  const filterKeys = Object.keys(parsedFilters);

  if (
    !filterKeys.length ||
    (filterKeys.length === 2 && filterKeys.includes('sort_fields')) ||
    (filterKeys.length === 1 && filterKeys.includes('query'))
  ) {
    return false;
  }

  return true;
};

/**
 * function to make a new search in a new tab applying a specific filter
 * @param {Dictionary<PreselectedFilter>} clearedFilter cleared filter
 * @param {Dictionary<BaseFilter<any>>} sources filter data
 * @param {string} filterKey filter name
 * @param {string} filterValue filter value
 */
export const searchInNewTab = (
  clearedFilter: Dictionary<PreselectedFilter>,
  sources: Dictionary<BaseFilter<any>>,
  filterKey: string,
  filterValue:
    | string
    | SelectOption[]
    | FormCheckboxOption[]
    | LogicOption[]
    | FormCheckableRangeOption[],
  extraQueries: Dictionary<string | (string | number)[]> = {},
  searchPathname?: string
) => {
  // insert filter info in filters.
  const filter: Dictionary<PreselectedFilter> = {
    ...clearedFilter,
    [filterKey]: filterValue,
  };

  const mappedFilters = mapFiltersToURLParams(sources, filter);
  const query = queryString.stringify(mappedFilters, {
    arrayFormat: 'comma',
  });
  const extraQueriesString = queryString.stringify(extraQueries, { arrayFormat: 'comma' });

  const newLocation = `${window.location.origin}${searchPathname}?${query}${
    extraQueriesString && `&${extraQueriesString}`
  }`;
  window.open(newLocation, '_blank');
};

/**
 * function to make a new search in a new tab applying a specific filter
 * @param {Dictionary<PreselectedFilter>} clearedFilter cleared filter
 * @param {Dictionary<BaseFilter<any>>} sources filter data
 * @param {string} filterKey filter name
 * @param {string} filterValue filter value
 */
export const searchSourceScrubInNewTab = (
  listId: string,
  clearedFilter: Dictionary<PreselectedFilter>,
  sources: Dictionary<BaseFilter<any>>,
  filters: Dictionary<PreselectedFilter>
) => {
  // insert filter info in filters.
  const filter: Dictionary<PreselectedFilter> = {
    ...clearedFilter,
    ...filters,
  };

  const mappedFilters = mapFiltersToURLParams(sources, filter);
  const query = queryString.stringify(mappedFilters, {
    arrayFormat: 'comma',
  });
  const newLocation = `${window.location.origin}${appRoutes.userLists.sourceScrubLists.replace(
    ':id',
    listId
  )}?${query}`;
  window.open(newLocation, '_blank');
};

/**
 * Remove a trailing slash, remove space character from a string
 * @param {string | undefined} text - keyword of search
 */

export const preProcessSearch = (text: string | undefined) => {
  return text?.trim()?.replace(/\/+$/, '') || '';
};

/**
 * function to validate the input search value and
 * display an error popup and set a boolean state
 * @param {string} value the value that needs to be checked
 * @param setValidSearchKey set state
 */
export const validateSearchTerm = (
  value: string,
  setValidSearchKey: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (validateMinChars(value, SEARCH_MINIMUM_CHAR_COUNT)) {
    // if string is all spaces
    if (value.trim().length === 0) {
      setValidSearchKey(false);
      NotificationService.error('Search term can not contain only spaces');

      return false;
    }

    setValidSearchKey(true);

    return true;
  }

  setValidSearchKey(false);
  NotificationService.error('3 chars or more');

  return false;
};

/**
 * check if a view is a custom ui view
 * @param {string | number} id view id
 */
export const isCustomUIView = (id: string | number) =>
  customUIViewIds.includes(id as CustomUIViewIds);
