import { useLayoutEffect, useState } from 'react';
import { useSize } from '@umijs/hooks';

interface UseMaxDisplayedChildrenOptions {
  hideChildClass?: string;
  rightOffset?: number;
  moreClassName?: string;
  updateContentDependency?: any;
  listType?: string | null;
}

interface UseMaxDisplayedChildrenResult {
  domRef: React.MutableRefObject<HTMLDivElement>;
  /**
   * Number of hidden children. (Count more)
   */
  countHiddenChildren: number;
  hiddenChildrenIdList: string[];
}

/**
 * Compute the maximum number of children a wrapper can contain and hide children overflowing.
 * All children must be rendered and the hook just adds a class to children that need to be hidden inside the container.
 */
const useMaxDisplayedChildren = ({
  hideChildClass = 'hide',
  updateContentDependency,
  listType,
}: UseMaxDisplayedChildrenOptions = {}): UseMaxDisplayedChildrenResult => {
  const [tagsWrapperSize, tagsWrapperRef] = useSize<HTMLDivElement>();
  const [countHiddenChildren, setCountHiddenChildren] = useState<number>(0);
  const [hiddenChildrenIdList, setHiddenChildrenIdList] = useState<string[]>([]);

  useLayoutEffect(() => {
    if (tagsWrapperRef.current) {
      const hiddenChildren: string[] = [];
      // convert HTMLCollection to Array of  HTMLElement.
      const children: Array<HTMLElement> = Array.prototype.slice.call(
        tagsWrapperRef.current.children
      );
      // sort children by width, so we can hide the tags
      // that take the most amount of space
      const childrenSorted =
        listType === 'combined' ? children : children.sort((a, b) => a.offsetWidth - b.offsetWidth);

      const childrensWidths = childrenSorted.map(
        child =>
          child.offsetWidth +
          Number.parseInt(window.getComputedStyle(child).marginLeft) +
          Number.parseInt(window.getComputedStyle(child).marginRight)
      );

      let maxIndex = 0;
      let tagsWidth = 0;
      // tagsWrapperRef is positioned using flex styling. by hiding it's content, tagsWrapperRef
      // stretches to inhabit all available space. this way we can get the width available for displaying tags.
      // just using the .hide class and progressively hiding tags causes issue on ipad, where tagsWrapperRef's width
      // is always the sum of it's children even though they're hidden using position absolute and visibility hidden
      tagsWrapperRef.current.classList.add('hide-content');
      const availableWidth = tagsWrapperRef.current.clientWidth;

      // fix for cases where we can't fit any tags at all,
      // predominantly on smaller rezolutions, like ipad
      if (childrensWidths[0] > availableWidth) {
        for (let i = 0; i < childrenSorted.length; i++) {
          childrenSorted[i].classList.add(hideChildClass);
          hiddenChildren.push(childrenSorted[i].id);
        }
      } else {
        // iterate through tags and display them until their added widths
        // is bigger then the available width and save the index
        for (let i = 0; i < childrensWidths.length; i++) {
          tagsWidth += childrensWidths[i];

          if (tagsWidth <= availableWidth) {
            childrenSorted[i].classList.remove(hideChildClass);
          } else {
            maxIndex = i;

            break;
          }
        }

        // hide the tags that don't fit
        if (maxIndex > 0) {
          for (let i = maxIndex; i < childrenSorted.length; i++) {
            // setTimeout is used to avoid skipping adding .hide class
            // when resizing for SingleTag component
            setTimeout(() => childrenSorted[i].classList.add(hideChildClass));
            hiddenChildren.push(childrenSorted[i].id);
          }
        }
      }

      setHiddenChildrenIdList(hiddenChildren);
      setCountHiddenChildren(hiddenChildren.length);

      tagsWrapperRef.current.classList.remove('hide-content');
    }

    // updateContentDependency can be used to trigger content recalculation
  }, [tagsWrapperSize.width, tagsWrapperRef, hideChildClass, updateContentDependency, listType]);

  return {
    domRef: tagsWrapperRef,
    countHiddenChildren,
    hiddenChildrenIdList,
  };
};

export default useMaxDisplayedChildren;
