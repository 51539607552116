import React, { useMemo } from 'react';
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
// models
import {
  EmailsSentOption,
  DateTimePeriod,
} from '@models/feature/analystsLeaderboard/EmailStatistics';
// components
import { MinimumSizeBar } from '@optx/components/common/charts/components';
import { createLabelFormatter, createTickFormatter } from './helpers';
import * as config from './config';

type DateRangeValue = [string | null, string | null];

interface EmailsSentChartProps {
  data: Array<EmailsSentOption>;
  timePeriod?: DateTimePeriod;
  selectedInterval: DateRangeValue;
}

type DataKey = keyof EmailsSentOption;

const EmailsSentChart: React.FC<EmailsSentChartProps> = ({
  data,
  timePeriod,
  selectedInterval,
}) => {
  const tickFormatter = useMemo(() => createTickFormatter(timePeriod), [timePeriod]);
  const labelFormatter = useMemo(
    () => createLabelFormatter(timePeriod, selectedInterval, data),
    [timePeriod, selectedInterval, data]
  );

  return (
    <ResponsiveContainer height={86}>
      <BarChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 10 }} barSize={8}>
        <XAxis dataKey={'date' as DataKey} {...config.xAxisProps} tickFormatter={tickFormatter} />
        <Tooltip separator=": " labelFormatter={labelFormatter} {...config.tooltipProps} />
        <Bar
          dataKey={'total_emails' as DataKey}
          name="Total Emails"
          {...config.leftBarProps}
          shape={MinimumSizeBar}
        />
        <Bar
          dataKey={'average_emails' as DataKey}
          name="Team Average Emails"
          {...config.rightBarProps}
          shape={MinimumSizeBar}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default EmailsSentChart;
