import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// local
import * as actions from './actions';
import { BulkSoftwareResponse, BulkSoftwareSuccess } from './interfaces';

const initialState: BulkSoftwareResponse = {
  count: 0,
  data: [],
};

const bulkSoftwareSuccessReducer: CaseReducer<
  BulkSoftwareResponse,
  PayloadAction<{ data: BulkSoftwareSuccess[]; count: number }>
> = (draftState, action) => {
  draftState.data = action.payload.data;
  draftState.count = action.payload.count;
};

const reducer = createReducer(initialState, builder =>
  builder.addCase(actions.bulkSoftwareSuccess, bulkSoftwareSuccessReducer)
);

export default reducer;
