import { createAction } from '@reduxjs/toolkit';
import * as types from './types';
import { UserListPageState } from './interfaces';

export const fetchUserList = createAction<boolean>(types.FETCH_USER_LIST);
export const fetchUserListFieldSuccess = createAction<Array<UserListPageState>>(
  types.FETCH_USER_LIST_FIELD_SUCCESS
);
export const fetchUserListFieldFail = createAction<string>(types.FETCH_USER_LIST_FIELD_FAIL);

export const fetchPsgUserListFieldSuccess = createAction<Array<UserListPageState>>(
  types.FETCH_PSG_USER_LIST_FIELD_SUCCESS
);
export const fetchPsgUserListFieldFail = createAction<string>(types.FETCH_PSG_USER_LIST_FIELD_FAIL);

export const fetchUserListShareSuccess = createAction<Array<UserListPageState>>(
  types.FETCH_USER_LIST_SHARE_SUCCESS
);
export const fetchUserListShareFail = createAction<string>(types.FETCH_USER_LIST_SHARE_FAIL);
