import { CompanyExtendedKeys } from '@optx/models/table/Columns';

/**
 * Get the name of a property/column belonging to company related data
 * Can be updated with other properties as needed
 * @param {CompanyKeys} key company property
 */

export const mapCompanyPropName = (key: CompanyExtendedKeys) => {
  let name = '';

  switch (key) {
    case 'num_employees':
      name = 'Number of Employees';
      break;
    case 'capital_raised':
      name = 'Total Raised';
      break;
    case 'raise_date':
      name = 'Last Funding Date';
      break;
    case 'last_raised_amount':
      name = 'Last Round';
      break;
    case 'last_rev_update_amount':
      name = 'Revenue';
      break;
    case 'ebitda_amount':
      name = 'EBITDA';
      break;
    case 'stage':
      name = 'Stage';
      break;
    case 'pipeline_rank':
      name = 'Pipeline Rank';
      break;
    case 'company_owner_id':
      name = 'Company Owner';
      break;
    case 'fund':
      name = 'Fund';
      break;
    case 'rationale':
      name = 'Rationale';
      break;

    default:
      break;
  }

  return name;
};
