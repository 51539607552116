import React from 'react';
import { Typography, Radio, Space, Button, Divider } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { useDispatch, useSelector } from 'react-redux';
// services
import NotificationService from '@optx/services/NotificationService';
// models
import { UpdateUserSettingsPayload } from '@optx/models/api/user';
import { SuccessErrorCallback } from '@optx/models/callback';
// utils
import { getNewPipelineReportQuery } from '@features/pipeline-report/utils';
// redux
import { actions, selectors } from '@redux/user/information/';
// hooks
import { useToggle } from '@optx/common/hooks/modal';
// components
import ChangeDateFormatModal from './Modals/ChangeDateFormatModal';

const GeneralInfo = () => {
  const defaultScoreValue = useSelector(selectors.getDefaultScore);
  const pipelineReportQuery = useSelector(selectors.getPipelineReportQuery);

  const dispatch = useDispatch();
  const [isOpenModal, toggleModal] = useToggle();

  const callback: SuccessErrorCallback = (success, error) => {
    if (error) {
      NotificationService.error('The page was not saved!');
    } else {
      success && NotificationService.success('The page was saved!');
      // When change the default optx score, api send correct data after reload the page
      window.location.reload();
    }
  };

  const onChange = (e: RadioChangeEvent) => {
    const optxScoreSetting = e.target.value;

    dispatch(
      actions.updateUserSettings(
        {
          profile_settings: { optx_score_settings: optxScoreSetting },
          pipeline_report: getNewPipelineReportQuery(pipelineReportQuery, optxScoreSetting),
        } as Partial<UpdateUserSettingsPayload>,
        callback
      )
    );
  };

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    if (event && event.currentTarget) {
      event.currentTarget.blur();
    }

    toggleModal();
  };

  return (
    <div className="optx-score-settings">
      <Typography.Title level={5}>Default OPTX Score</Typography.Title>
      <Typography.Text type="secondary" className="optx-score-warning">
        Switching your OPTX Score type will change the score calculation across the entire platform,
        including saved searches
      </Typography.Text>
      <Radio.Group onChange={onChange} value={defaultScoreValue || 'us'} size="large">
        <Space direction="vertical">
          <Radio value="us">United States (PSG Default)</Radio>
          <Radio value="il">Israel</Radio>
        </Space>
      </Radio.Group>
      <Divider />
      <Typography.Title level={5}>Default Date Format</Typography.Title>
      <Button onClick={handleToggle}>Change Date Format</Button>
      <ChangeDateFormatModal isOpen={isOpenModal} toggle={toggleModal} />
    </div>
  );
};

export default React.memo(GeneralInfo);
