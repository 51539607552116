import React from 'react';
import { Tabs } from 'antd';
// hooks
import { useLocationAwareTabs } from '@optx/common/hooks/tabs';
// components
import GeneralInfo from './General';
// style
import { Styled } from '../UserProfile.styled';
import OptxRecommendNotifications from './OptxNotifications';

const { TabPane } = Tabs;

const TABS = {
  GENERAL_INFO: '1',
  OPTX_RECOMMENDED_NOTIFICATIONS: '2',
};

const UserProfileTabs = () => {
  // hooks
  const { defaultActiveKey, onChange } = useLocationAwareTabs({
    defaultActiveKey: TABS.GENERAL_INFO,
  });

  return (
    <Styled.TabWrapper>
      <Tabs
        animated={false}
        defaultActiveKey={defaultActiveKey}
        onChange={key => {
          onChange(key);
        }}
      >
        <TabPane tab="General Info" key={TABS.GENERAL_INFO}>
          <GeneralInfo />
        </TabPane>
        <TabPane tab="OPTX Recommended Notifications" key={TABS.OPTX_RECOMMENDED_NOTIFICATIONS}>
          <OptxRecommendNotifications />
        </TabPane>
      </Tabs>
    </Styled.TabWrapper>
  );
};

export default UserProfileTabs;
