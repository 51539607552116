import { takeLatest, call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { CompanyService, UserService } from '@services/api';
import { AxiosResponse } from 'axios';
import { PageInfo } from '@optx/models/table/Pagination';
import { CompanyMergesResponse } from '@optx/models/company/CompanyMerges';
import NotificationService from '@services/NotificationService';
import { getErrorMessage } from '@optx/utils/api/errors';
import { actions as customGlobalLoaderActions } from '@features/custom-global-loader';
import * as actions from './actions';
import * as selectors from './selectors';
import {
  CompanyMergesPayload,
  UnmergeCompaniesPayload,
  UnmergeCompaniesResponse,
} from './interfaces';

export function* fetchCompanyMergesSaga(action: PayloadAction<CompanyMergesPayload, any, boolean>) {
  const searchPayload = action.payload;

  try {
    const res: AxiosResponse<CompanyMergesResponse> = yield call(
      CompanyService.getCompanyMerges,
      searchPayload
    );
    yield put(actions.fetchMergesSuccess(res.data, searchPayload));
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Error fetching company merges!');
    yield put(actions.fetchMergesFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

function* unmergeCompaniesSaga(action: PayloadAction<UnmergeCompaniesPayload>) {
  const pagination: PageInfo = yield select(selectors.getCompanyMergesPagination);
  const shouldCopyData: boolean = yield select(selectors.getShouldCopyData);
  const { selectedCompanies, companyId } = action.payload;
  const companies = [...selectedCompanies];

  const sourceIds = companies.map(company => +company.id);
  yield put(actions.setUnmergeLoading(true));

  try {
    const res: AxiosResponse<UnmergeCompaniesResponse> = yield call(
      UserService.unmergeCompanies,
      companyId,
      sourceIds,
      shouldCopyData
    );

    if (res.data) {
      const successMessage = 'Companies unmerged successfully';
      NotificationService.success(successMessage);

      yield put(actions.setUnmergeCompanyId(res.data));
      yield put(actions.setUnmergeLoading(false));
      yield put(actions.setUnmergeCompleted(true));
      yield put(actions.resetPagination());
      yield put(actions.fetchMerges({ companyId, page: pagination.pageNumber, reset: true }));
    }
  } catch (e: any) {
    const message = getErrorMessage(e, 'Failed to unmerged companies!');
    NotificationService.error(message);

    yield put(actions.setUnmergeLoading(false));
    yield put(actions.setUnmergeCompleted(true));
    yield put(actions.setUnmergeError(message));
  } finally {
    yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
  }
}

export default function* companyTouchesSaga() {
  yield takeLatest(actions.fetchMerges, fetchCompanyMergesSaga);
  yield takeLatest(actions.unmergeCompanies, unmergeCompaniesSaga);
}
