import { createAction } from '@reduxjs/toolkit';
// interfaces/types
import { FilterSource } from '@optx/models/filters';
import * as types from './types';
import {
  ChangeCardSubfilterPayload,
  DefaultSourcingOutReachFilters,
  SourcingFilterOptions,
  UpdateSourcingOutReachFiltersPayload,
} from './interfaces';

export const updateSourcingFilterOptions = createAction<SourcingFilterOptions>(
  types.UPDATE_SOURCING_FILTER_OPTIONS
);

export const updateSourcingOutReachFilters = createAction<UpdateSourcingOutReachFiltersPayload>(
  types.UPDATE_SOURCING_OUTREACH_FILTERS
);

export const fetchSourcingOutReachFiltersSuccess = createAction<FilterSource>(
  types.FETCH_SOURCING_OUT_REACH_FILTERS_SUCCESS
);

export const resetSessionFilter = createAction(types.RESET_SESSION_FILTERS);

export const resetStoreFilter = createAction(types.RESET_STORE_FILTERS);

export const setDefaultSourcingOutReachFilters = createAction<DefaultSourcingOutReachFilters>(
  types.SET_DEFAULT_SOURCING_OUT_REACH_FILTERS
);

export const changeCard = createAction<string>(types.CHANGE_CARD);

export const changeCardSubfilter = createAction<ChangeCardSubfilterPayload>(
  types.CHANGE_CARD_SUBFILTER
);

export const changeCardSubfilterSuccess = createAction<ChangeCardSubfilterPayload>(
  types.CHANGE_CARD_SUBFILTER_SUCCESS
);
