import React from 'react';
import { Button } from 'antd';
import { replaceObjectForCopy } from '@optx/utils/replaceObjectForCopy';
import { copyToClipboard } from '../../utils/helpers';

interface CopyButtonProps {
  text?: string | JSX.Element;
  list?: JSX.Element[];
  copyText?: string; // if `text` props is JSX.Element, then `copyText` is required and pass to `copyToClipboard`
}

export const CopyButton: React.FC<CopyButtonProps> = ({ text, list, copyText }) => {
  return (
    <div style={{ maxWidth: 700, overflowX: 'auto', overflowY: 'hidden' }}>
      {text ? <span>{text}</span> : <div>{list}</div>}
      <div className="text-center">
        <Button
          size="small"
          onClick={() => {
            // eslint-disable-next-line no-nested-ternary
            text
              ? copyToClipboard(text)
              : copyText
              ? copyToClipboard(copyText)
              : copyToClipboard(replaceObjectForCopy(list!));
          }}
        >
          COPY
        </Button>
      </div>
    </div>
  );
};

export default CopyButton;
