import React from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import { SearchSave } from '@optx/models/search';
import {
  TableCellSearch,
  TableCellFilters,
  TableCellActions,
  TableCellSearchKey,
} from '../cells/contacts';

const cellUpdates: ColumnsType<SearchSave> = [
  {
    key: 'title',
    dataIndex: 'title',
    title: 'Title',
    render: (value, row) => <TableCellSearch value={value} row={row} />,
    className: 'cell-title',
  },
  {
    key: 'searchKey',
    dataIndex: 'searchKey',
    title: 'Keywords',
    render: (value, row) => <TableCellSearchKey value={value} row={row} />,
    className: 'cell-key',
  },
  {
    key: 'filters',
    dataIndex: 'filters',
    title: 'Filters Applied',
    render: (value, row) => <TableCellFilters value={value} row={row} />,
    className: 'cell-filters',
  },
  {
    key: 'row_actions',
    dataIndex: 'row_actions',
    title: 'Action',
    render: (value, row) => <TableCellActions value={value} row={row} />,
    align: 'center',
    className: 'cell-actions',
  },
];

export default cellUpdates;
