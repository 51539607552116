import { createSelector } from 'reselect';
// constants
import { defaultColumns } from '@components/common/table/TableUserManagement';
// utils
import { sortColumns } from '@optx/utils/table/sorting';
// models/interfaces
import { AppState } from '@optx/redux/interfaces';
// redux
import { getDefaultScore } from '../user/information/selectors';

const userManagementState = (state: AppState) => state.userManagement;
const userEditModalState = (state: AppState) => state.userManagement.edit;

export const isLoading = createSelector(userManagementState, state => state.loading);
export const shouldFetch = createSelector(
  userManagementState,
  state => !state.loading && !state.fetchedAt
);
export const userManagementData = createSelector(userManagementState, state => state.data);

export const getSorting = createSelector(userManagementState, state => state.sortBy);

export const getGridColumns = createSelector(
  getSorting,
  getDefaultScore,
  (sorting, defaultOptxScore) => sortColumns(defaultColumns, sorting, defaultOptxScore)
);

// editing
export const modalLoading = createSelector(userEditModalState, state => state.loading);

export const getEditData = createSelector(userEditModalState, state => state.data);
