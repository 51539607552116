import { Dispatch, SetStateAction } from 'react';
import { isEqual } from 'lodash';
// models
import { FinancialFieldType } from '@optx/models/Company';
import { SelectOption } from '@optx/models/Option';
import { FieldOptions } from '../state/interfaces';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import {
  ABBR_NEGATIVE_VALUES_SPECIAL_OPTIONS_ERROR_MESSAGE,
  ABBREVIATION_WITH_NEGATIVE_VALUES_ERROR_MESSAGE,
} from '@optx/constants/table/cells';
import { NUMBER_FORMAT } from '@optx/constants/format/number';
// utils
import {
  getMostRecentAmount,
  getMostRecentYear,
  isOptionChecked,
  getMostRecentSource,
} from '@optx/utils/option';
import { roundNumber, normalizeMonetaryMinusValues } from '@optx/utils/number';
import { validateFloatAbbreviation } from '@optx/utils/validation';

interface FinancialFieldProps {
  amount: string | undefined;
  fieldListValues: FinancialFieldType[] | null;
  options: SelectOption<string>[] | undefined;
  isFromSource: boolean | undefined;
  setLabel: Dispatch<SetStateAction<string>>;
  setAmount: Dispatch<SetStateAction<string | undefined>>;
  setYear: Dispatch<SetStateAction<string | number | undefined>>;
  setIsFromSource: Dispatch<SetStateAction<boolean | undefined>>;
  setDisabled: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<string>>;
  fieldName: FieldOptions;
  customFinancialYear?: number;
}

const useFinancialField = ({
  amount,
  fieldListValues,
  options,
  isFromSource,
  setLabel,
  setAmount,
  setYear,
  setIsFromSource,
  setDisabled,
  setError,
  fieldName,
  customFinancialYear,
}: FinancialFieldProps) => {
  const updateFinancialFields = () => {
    if (fieldListValues?.length) {
      const amountValue = getMostRecentAmount(fieldListValues, customFinancialYear);
      setIsFromSource(getMostRecentSource(fieldListValues));

      // check to see if value for amount was a string option, a number or range option
      if (typeof amountValue === 'string') {
        setLabel(amountValue === '' ? DEFAULT_EMPTY_VALUE : amountValue);
        setAmount(amountValue === DEFAULT_EMPTY_VALUE ? '' : amountValue);
      } else if (typeof amountValue === 'number' && fieldName === 'GM %') {
        setAmount(amountValue.toString());
        setLabel(`${amountValue.toString()}%`);
      } else if (typeof amountValue === 'number' || !amountValue) {
        const abbrLabel = roundNumber(amountValue, NUMBER_FORMAT);
        setLabel(normalizeMonetaryMinusValues(abbrLabel));
        setAmount((amountValue! / 1000000).toString());
      } else {
        const amountOption = options?.find(option => isEqual(option.rangeValue, amountValue));

        if (amountOption) {
          setLabel(amountOption.label);
          setAmount(amountOption.label);
          // fix for old hardcoded range values for gm to display min value
        } else if (!amountOption && fieldName === 'GM %') {
          setAmount(amountValue.min?.toString());
        }
      }

      setYear(customFinancialYear || getMostRecentYear(fieldListValues));
    } else {
      setYear(customFinancialYear || new Date().getFullYear());
      setLabel(DEFAULT_EMPTY_VALUE);
      setAmount('');
    }
  };

  const isAmountValid = () => {
    if (
      amount !== null &&
      amount !== undefined &&
      (validateFloatAbbreviation(amount, true) || isOptionChecked(amount, options))
    ) {
      return true;
    }

    return false;
  };

  const validateSave = () => {
    const errorMessage = options
      ? ABBR_NEGATIVE_VALUES_SPECIAL_OPTIONS_ERROR_MESSAGE
      : ABBREVIATION_WITH_NEGATIVE_VALUES_ERROR_MESSAGE;
    let isValid = true;

    if (!isAmountValid()) {
      isValid = false;
      setError(errorMessage);
    }

    if (!isValid) {
      setDisabled(true);
    }

    return isValid;
  };

  const isAmountValidForSource = (year: number) => {
    const errorMessage = 'Amount cannot be empty for source!';
    const findSourceInfo = fieldListValues?.filter(item => item.year === year)[0]?.from_source;

    if (!findSourceInfo || (findSourceInfo && amount !== '')) {
      return true;
    }

    setError(errorMessage);

    return false;
  };

  return { updateFinancialFields, isAmountValid, validateSave, isAmountValidForSource };
};

export default useFinancialField;
