import React, { useState } from 'react';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
// models
import { Company } from '@models/Company';
// components
import AddContactBtn from './AddContactBtn';
import SelectContacts from './SelectContacts';
import { SelectAddContact } from './SelectAddContact';
import { Styled } from './ContactSelect.styled';

interface ContactSelectProps {
  companyData: Company;
  isCell?: boolean;
  handleChange: (selectedValues: number[]) => void;
  selected?: number[];
  selectedPrimary?: boolean;
  size?: SizeType;
}

const ContactSelect: React.FC<ContactSelectProps> = ({
  companyData,
  isCell,
  handleChange,
  selected,
  selectedPrimary,
  size = 'middle',
}) => {
  const [visible, setVisible] = useState(false);
  const contacts = companyData.contacts;
  const { company_id: id, company_name: name, company_url: url } = companyData;
  const contactData = {
    company_id: id,
    company_name: name,
    company_url: url,
  };

  if (contacts?.length) {
    return (
      <Styled.SelectContact>
        <SelectContacts
          contacts={contacts}
          selectedContacts={selected}
          company={companyData}
          isCell={isCell}
          handleChange={handleChange}
          showAddContactForm={() => setVisible(true)}
          selectedPrimary={selectedPrimary}
          size={size}
        />
        <SelectAddContact
          onCancel={() => setVisible(false)}
          visible={visible}
          contactData={contactData}
        />
      </Styled.SelectContact>
    );
  }

  return (
    <Styled.SelectContact>
      <AddContactBtn setVisible={setVisible} />
      <SelectAddContact
        onCancel={() => setVisible(false)}
        visible={visible}
        contactData={contactData}
      />
    </Styled.SelectContact>
  );
};

export default ContactSelect;
