import { createFeatureActionType } from '@optx/utils/feature';
import * as search from './search';
import * as filters from './filters';
import * as sort from './sort';
import { featureName } from '../../featureName';

const init = createFeatureActionType(featureName, 'INIT');

export const types = {
  search,
  filters,
  sort,
  init,
};
