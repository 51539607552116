import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import { Form } from 'formik-antd';
import { isEqual } from 'lodash';
// models
import { EditAllInfoForm, SearchFieldLabels } from '@optx/models/api/editAllInfo';
import { SelectOption } from '@optx/models/Option';
// constants
import { EDIT_ALL_INFO_FORM, EDIT_ALL_TABS } from '../constants/editAllCompany';
// services
import NotificationService from '@optx/services/NotificationService';
// utils
import validationAllInfo from '../utils/validation';
// redux
import {
  selectors as editAllDialogSelectors,
  actions as editAllDialogActions,
} from '@optx/features/edit-all-info/state';
import { selectors as featuredSelectors } from '@components/feature/company-individual-edit/state/index';
// hooks
import { useInjectEditAllCompany } from '@optx/features/edit-all-info';
// components
import { Styled } from './EditAllDialog.styled';
import EditAllModalTitle from './EditAllModalTitle';
import EditAllCompanyInfo from './EditAllCompanyInfo';
import TabLoader from './TabLoader';
import CompanyDrawerBanner from '@optx/features/add-touch/components/CompanyDrawerBanner';
import EditAllTabsGroup from './EditAllTabsGroup';

interface EditAllDialogProps {
  isChromeExtension?: boolean;
}

const EditAllDialog: React.FC<EditAllDialogProps> = ({ isChromeExtension }) => {
  useInjectEditAllCompany();
  const dispatch = useDispatch();

  const company = useSelector(editAllDialogSelectors.getEditAllCompany);
  const isFinancialsLoaded = useSelector(editAllDialogSelectors.isFinancialsLoaded);
  const isOpen = useSelector(editAllDialogSelectors.isOpen);
  const editAllFields = useSelector(editAllDialogSelectors.getEditAllFields);
  const proprietaryFields = useSelector(editAllDialogSelectors.getEditPropInfo);
  const eqtView = useSelector(editAllDialogSelectors.getEditEqtView);
  const gridOrder = useSelector(editAllDialogSelectors.getEditGridOrder);
  const editIdInfo = useSelector(editAllDialogSelectors.getEditIdInfo);

  const initialState = useSelector(editAllDialogSelectors.getInitialEditAllState);

  const stageOptions = useSelector(
    featuredSelectors.companyIndividualEdit.stageOptions
  ) as SelectOption[];

  const [activeTab, setActiveTab] = useState<string>('editAllFieldsTab');
  const [activeEditTabIndex, setActiveEditTabIndex] = useState<number>(0);
  const [tabTitle, setTabTitle] = useState<string>(EDIT_ALL_TABS[activeEditTabIndex].title);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggle = useCallback(() => {
    if (formRef.current) {
      formRef.current.resetForm();
    }

    return dispatch(
      editAllDialogActions.toggleCompanyEditAllFormDialog({
        companyId: company.company_id,
        companyName: company.company_name,
        company: company,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, company]);

  const handleCreateCompanyEdit = () => {
    if (formRef.current) {
      formRef.current.submitForm();

      if (formRef.current.errors) {
        const errors = Object.keys(formRef.current.errors);
        errors.forEach(error =>
          NotificationService.error(
            error.charAt(0).toUpperCase() +
              error.toString().replaceAll('_', ' ').slice(1) +
              ' is required!'
          )
        );
      }
    }
  };

  // Form
  const formRef = useRef<FormikProps<Partial<EditAllInfoForm>> | null>(null);

  let formInitialValues = {
    ...EDIT_ALL_INFO_FORM,
    ...(initialState ? initialState : {}),
    companyId: company.company_id?.toString() || '',
  };

  const handleInitialValues = () => {
    if (formRef.current?.values) {
      if (isEqual(formRef.current.values, formInitialValues)) {
        return formRef.current.values;
      }

      return formInitialValues;
    }

    return formInitialValues;
  };

  const handleSubmit = (values: Partial<EditAllInfoForm>) => {
    let formValues = { ...values };

    if (!isEqual(formInitialValues, formValues)) {
      dispatch(
        editAllDialogActions.updateCompanyEditAllInfo({
          editAllFields: formValues,
        })
      );
    } else {
      // if no changes close the modal
      toggle();
    }
  };

  useEffect(() => {
    if (!isOpen && formRef.current?.values && !isEqual(formRef.current.values, formInitialValues)) {
      formRef.current.resetForm();
    }

    if (!isOpen) {
      setActiveTab('editAllFieldsTab');
      setActiveEditTabIndex(0);
      setTabTitle(EDIT_ALL_TABS[0].title);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleTabChange = (tabKey: string) => {
    setIsLoading(true);
    setActiveTab(tabKey);
  };

  useEffect(() => {
    const newTabIndex = EDIT_ALL_TABS.findIndex(tab => tab.key === activeTab);
    setActiveEditTabIndex(newTabIndex);
    setTabTitle(EDIT_ALL_TABS[newTabIndex].title);
    // Simulate loading delay
    setTimeout(() => {
      setIsLoading(false); // Set loading state to false when content is ready
    }, 300); // Adjust the delay as needed
  }, [activeTab]);

  const tabSource = (key: string) => {
    switch (key) {
      case 'editAllFieldsTab':
        return editAllFields;
      case 'eqtViewTab':
        return eqtView;
      case 'proprietaryFieldsTab':
        return proprietaryFields;
      case 'gridOrderTab':
        return gridOrder;
      case 'editIdInfoTab':
        return editIdInfo;
      default:
        return editAllFields;
    }
  };

  // flatted fields for search
  const flattedFields = tabSource(activeTab)
    .map(item => item.data)
    .flat();

  const title = (
    <EditAllModalTitle toggle={toggle} handleCreateCompanyEdit={handleCreateCompanyEdit} />
  );

  const stageRationaleRequiredValues = useMemo(() => {
    const requiredValues = stageOptions?.reduce((arr: any[], item) => {
      if (item.label === 'Dead' || item.label === 'Pass') {
        return [...arr, item.value];
      }

      return [...arr];
    }, []);

    return requiredValues;
  }, [stageOptions]);

  const fieldsLabels = useMemo(() => {
    return flattedFields.reduce((acumulator, field) => {
      if (field?.id) {
        return {
          ...acumulator,
          [field.id]: field.label,
        };
      }

      return acumulator;
    }, {} as SearchFieldLabels);
  }, [flattedFields]);

  return (
    <>
      <Styled.DrawerEditAll
        placement={isChromeExtension ? 'bottom' : 'right'}
        isChromeExtension={isChromeExtension}
        onClose={toggle}
        visible={isOpen}
        className={isChromeExtension ? 'company-touches touch-extension' : 'company-touches'}
        height={isChromeExtension ? 'calc(100% - 60px)' : '100%'}
        width={isChromeExtension ? '640px' : '750px'}
        title={title}
      >
        <div className="ant-drawer-body-inner">
          <CompanyDrawerBanner company={company} />
          <EditAllTabsGroup handleTabChange={handleTabChange} activeTab={activeTab} />
          {initialState && (
            <Styled.EditAllContainer>
              <Formik
                initialValues={handleInitialValues()}
                enableReinitialize
                onSubmit={handleSubmit}
                validationSchema={validationAllInfo(stageRationaleRequiredValues)}
                validateOnMount={true}
                innerRef={instance => {
                  formRef.current = instance;
                }}
                component={() => {
                  return (
                    <Form layout="vertical" style={{ position: 'relative' }}>
                      <div>
                        <TabLoader loading={isLoading} />
                        {isOpen && !isLoading && isFinancialsLoaded && (
                          <EditAllCompanyInfo
                            companyFields={tabSource(activeTab)}
                            searchFields={flattedFields}
                            searchFieldsLabels={fieldsLabels}
                            title={tabTitle}
                            hasSearch={EDIT_ALL_TABS[activeEditTabIndex].hasSearch}
                            tabKey={activeTab}
                            className={
                              isChromeExtension
                                ? `${activeTab} edit-all-prop-info--chrome-extension`
                                : `${activeTab}`
                            }
                          />
                        )}
                      </div>
                    </Form>
                  );
                }}
              />
            </Styled.EditAllContainer>
          )}
        </div>
      </Styled.DrawerEditAll>
    </>
  );
};

export default React.memo(EditAllDialog);
