import React, { Context, useState, useContext, useEffect } from 'react';
import { Typography } from 'antd';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';
// models
import { BulkEditFieldContext } from '@optx/models/bulkActions';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// styles
import { Styled } from './EditAiMl.styles';

interface EditSoftwareProps {
  context: Context<BulkEditFieldContext>;
}

export const EditAiMl: React.FC<EditSoftwareProps> = ({ context }) => {
  const { setSelectedData } = useContext(context);

  const [radioValue, setRadioValue] = useState(DEFAULT_EMPTY_VALUE);

  useEffect(() => {
    setSelectedData(DEFAULT_EMPTY_VALUE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e: RadioChangeEvent) => {
    setSelectedData(e.target.value);
    setRadioValue(e.target.value);
  };

  return (
    <Styled.ContentWrapper className="popover-radio__container">
      <Typography.Text className="ant-modal-title">Select AI / ML</Typography.Text>
      <Radio.Group
        className="popover-radio ant-popover-content-wrapper"
        onChange={onChange}
        value={radioValue}
      >
        <Styled.RadioStyled value>Yes</Styled.RadioStyled>
        <Styled.RadioStyled value={false}>No</Styled.RadioStyled>
        <Styled.RadioStyled value={DEFAULT_EMPTY_VALUE}>Unknown</Styled.RadioStyled>
      </Radio.Group>
    </Styled.ContentWrapper>
  );
};
