import React from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import { SearchSave } from '@optx/models/search';
import {
  TableCellFilters,
  TableCellActions,
  TableCellSearchName,
  TableCellSorting,
  TableCellDate,
  TableCellNumber,
  TableCellSearchKey,
  TableCellColumnsConfiguration,
} from '../cells/company';

const cellUpdates: ColumnsType<SearchSave> = [
  {
    key: 'title',
    dataIndex: 'title',
    title: 'Search Name',
    render: (value, row) => <TableCellSearchName value={value} row={row} />,
    width: 150,
    sorter: true,
  },
  {
    key: 'searchKey',
    dataIndex: 'searchKey',
    title: 'Keywords',
    render: (value, row) => <TableCellSearchKey value={value} row={row} />,
    width: 100,
  },
  {
    key: 'filters',
    dataIndex: 'filters',
    title: 'Filters Applied',
    render: (value, row) => <TableCellFilters value={value} row={row} />,
    width: 510,
  },
  {
    key: 'sorting',
    dataIndex: 'sorting',
    title: 'Sorting',
    render: (value, row) => <TableCellSorting value={value} row={row} />,
    width: 80,
  },
  {
    key: 'columns_configuration',
    dataIndex: 'columns_configuration',
    title: 'Columns Configuration',
    render: (value, row) => <TableCellColumnsConfiguration value={value} row={row} />,
    width: 90,
  },
  {
    key: 'created_at',
    dataIndex: 'created_at',
    title: 'Date Created',
    align: 'right',
    render: (value, row) => <TableCellDate value={value} row={row} />,
    width: 80,
    sorter: true,
  },
  {
    key: 'count',
    dataIndex: 'count',
    title: '# of Companies',
    align: 'right',
    render: (value, row) => <TableCellNumber value={value} row={row} />,
    width: 90,
  },
  {
    key: 'row_actions',
    dataIndex: 'row_actions',
    title: 'Action',
    align: 'center',
    className: 'cell-actions',
    render: (value, row) => <TableCellActions value={value} row={row} />,
    width: 60,
  },
];

export default cellUpdates;
