import { createSelector } from 'reselect';
import { AppState } from '@redux/interfaces';
import FilterCountCard from '@optx/models/cards/FilterCountCard';
// models
import { CriteriaKeys } from '@optx/models/feature/analystsLeaderboard/CardCriteria';
import { SelectOption } from '@optx/models/Option';
import { FilterColumns } from '@optx/models/feature/analystsLeaderboard/filters';
import format from '@optx/utils/format';
import { selectors as userInformationSelectors } from '@redux/user/information';
import { selectors as requestSelectors } from '@features/request';
import { selectors as localFiltersSelectors } from './filters';
import { selectors as localSearchSelectors } from './search';
import { selectors as localTouchesAcrossRegionSelectors } from './touchesAcrossRegion';
import { selectors as emailStatisticsSelectors } from './emailStatistics';
import { selectors as smartTouchScoreSelectors } from './smartTouchScore';
import { selectors as additionalStatisticsSelectors } from './additionalsStatistics';
import {
  selectors as localPipelineInformationSelectors,
  types as pipelineInformationTypes,
} from './pipeline-information';
import { pipelineCriteria } from '../constants/custom-criteria';

const selectAnalystsLeaderboard = (state: AppState) => state.analystsLeaderboard;
const getFiltersState = (state: AppState) => selectAnalystsLeaderboard(state).filters;
const getSearchState = (state: AppState) => selectAnalystsLeaderboard(state).search;
const getTouchesAcrossRegionState = (state: AppState) =>
  selectAnalystsLeaderboard(state).touchesAcrossRegion;
const getEmailStatisticsState = (state: AppState) =>
  selectAnalystsLeaderboard(state).emailStatistics;
const getSmartTouchScoreState = (state: AppState) =>
  selectAnalystsLeaderboard(state).smartTouchScore;
const getAdditionalStatisticsState = (state: AppState) =>
  selectAnalystsLeaderboard(state).additionalStatistics;
const getPipelineInformationState = (state: AppState) =>
  selectAnalystsLeaderboard(state).pipelineInformation;

// Common
export const isLoading = createSelector(
  userInformationSelectors.isLoading,
  isLoadingFilters,
  isLoadingSearch,
  (loadingUserInformation, loadingFilters, loadingSearch) =>
    loadingUserInformation || loadingFilters || loadingSearch
);

// Filters
const getDefaultValues = (state: AppState) =>
  localFiltersSelectors.selectDefaultValues(getFiltersState(state));

const getCommonValues = (state: AppState) =>
  localFiltersSelectors.selectCommonValues(getFiltersState(state));

const getAllValues = (state: AppState) =>
  localFiltersSelectors.getAllValues(getFiltersState(state));

/**
 * Get common values and values for current selected criteria.
 * @param state app state.
 */
const getFilterValues = (state: AppState) =>
  localFiltersSelectors.selectValues(getFiltersState(state));

const getNormalizedFilters = (state: AppState) =>
  localFiltersSelectors.selectNormalizedFilters(getFiltersState(state));

function isLoadingFilters(state: AppState) {
  return localFiltersSelectors.isLoading(getFiltersState(state));
}

const shouldFetchFilters = (state: AppState) =>
  localFiltersSelectors.shouldFetch(getFiltersState(state));

const getFilter = (state: AppState, filterKey: string) =>
  localFiltersSelectors.getFilter(getFiltersState(state), filterKey);

const getSelectOptions = (state: AppState, filterKey: string) =>
  localFiltersSelectors.getSelectOptions(getFiltersState(state), filterKey);

const allowAnalystsEditing = (state: AppState) =>
  localFiltersSelectors.allowAnalystsEditing(getFiltersState(state));

const getSelectValue = (state: AppState, filterKey: string, criteria?: string) =>
  localFiltersSelectors.getSelectValue(getFiltersState(state), { filterKey, criteria });

const getRadioValue = (state: AppState, filterKey: string, criteria?: string) =>
  localFiltersSelectors.getRadioValue(getFiltersState(state), { filterKey, criteria });

const getSingleDateRangesValue = (state: AppState, filterKey: string) =>
  localFiltersSelectors.getSingleDateRangesValue(getFiltersState(state), filterKey);

const getSingleDateRangesOptions = (state: AppState, filterKey: string) =>
  localFiltersSelectors.getSingleDateRangesOptions(getFiltersState(state), filterKey);

const getCurrentDateRangeOption = (state: AppState, filterKey: string) =>
  localFiltersSelectors.getCurrentDateRangeOption(getFiltersState(state), filterKey);

const getChartLines = (state: AppState, filterKey: FilterColumns) =>
  localFiltersSelectors.getChartLines(getFiltersState(state), filterKey);

const getTags = (state: AppState) => localFiltersSelectors.getTags(getFiltersState(state));

const showClearAll = (state: AppState) =>
  localFiltersSelectors.showClearAll(getFiltersState(state));

const isCommonFilter = (state: AppState, filterKey: string) =>
  localFiltersSelectors.isCommonFilter(getFiltersState(state), filterKey);

const getCommonFiltersQuery = (state: AppState) =>
  localFiltersSelectors.getCommonFiltersQuery(getFiltersState(state));

const getSelectedPeriodLabel = (state: AppState) =>
  localFiltersSelectors.getSelectedPeriodLabel(getFiltersState(state));

const isAllTimeDate = (state: AppState) =>
  localFiltersSelectors.isAllTimeDate(getFiltersState(state));

const getValuesByCriteria = (state: AppState, criteria: string) =>
  localFiltersSelectors.getValuesByCriteria(getFiltersState(state), criteria);

export const filters = {
  isLoading: isLoadingFilters,
  shouldFetch: shouldFetchFilters,
  getCommonValues,
  getAllValues,
  getDefaultValues,
  getFilterValues,
  getFilter,
  getNormalizedFilters,
  getSelectOptions,
  getSelectValue,
  allowAnalystsEditing,
  getRadioValue,
  getSingleDateRangesValue,
  getSingleDateRangesOptions,
  getCurrentDateRangeOption,
  isAllTimeDate,
  getChartLines,
  getTags,
  showClearAll,
  isCommonFilter,
  getCommonFiltersQuery,
  getSelectedPeriodLabel,
  getValuesByCriteria,
};

// Search
export const getChartInfo = (state: AppState) =>
  localSearchSelectors.getChartInfo(getSearchState(state));

const getData = (state: AppState) => localSearchSelectors.getData(getSearchState(state));

const FILTER_CRITERIA_COLUMN: FilterColumns = 'filter_criteria';

export const getCards = createSelector(
  (state: AppState) =>
    filters.getSelectOptions(state, FILTER_CRITERIA_COLUMN) as Array<SelectOption>,
  getData,
  (criteriaOptions, searchInformation): Array<FilterCountCard> => {
    if (criteriaOptions.length) {
      return criteriaOptions.map(criteriaOption => {
        const { label, value: criteria } = criteriaOption;
        const cardInfo: FilterCountCard = {
          key: criteria,
          title: label,
        };
        const creiteriaInfo = searchInformation && searchInformation[criteria as CriteriaKeys];

        if (creiteriaInfo) {
          cardInfo.count = creiteriaInfo.total;
          cardInfo.trending = creiteriaInfo.growth;

          cardInfo.options = creiteriaInfo.items.map(item => {
            return {
              label: item.label,
              value: format(item.value, item.format),
            };
          });
        }

        return cardInfo;
      });
    }

    return [];
  }
);

function isLoadingSearch(state: AppState) {
  return localSearchSelectors.isLoading(getSearchState(state));
}

export const search = {
  isLoading: isLoadingSearch,
  getCards,
  getChartInfo,
  getData,
};

// Touches across region.
function isLoadingTouchesAcrossRegion(state: AppState) {
  return localTouchesAcrossRegionSelectors.isLoading(getTouchesAcrossRegionState(state));
}

const getTouchLocations = (state: AppState) =>
  localTouchesAcrossRegionSelectors.getLocations(getTouchesAcrossRegionState(state));

const getTopTouches = (state: AppState) =>
  localTouchesAcrossRegionSelectors.topTouches(getTouchesAcrossRegionState(state));

export const touchesAcrossRegion = {
  isLoading: isLoadingTouchesAcrossRegion,
  getLocations: getTouchLocations,
  getTopTouches,
};

// Emails statistics
const getEmailsStatistics = (state: AppState) =>
  emailStatisticsSelectors.selectEmailStatistics(getEmailStatisticsState(state));

const isFetchingEmailsStatistics = (state: AppState) =>
  emailStatisticsSelectors.isLoading(getEmailStatisticsState(state));

export const emailStatistics = {
  getEmailsStatistics,
  isFetching: isFetchingEmailsStatistics,
};

// Smart Touch Score
const getSmartTouchScore = (state: AppState) =>
  smartTouchScoreSelectors.selectSmartTouchScore(getSmartTouchScoreState(state));

const isFetchingSmartTouchScore = (state: AppState) =>
  smartTouchScoreSelectors.isLoading(getSmartTouchScoreState(state));

export const smartTouchScore = {
  getSmartTouchScore,
  isFetching: isFetchingSmartTouchScore,
};

// Additional Statistics
const getAdditionalStatistics = (state: AppState) =>
  additionalStatisticsSelectors.selectAdditionalStatistics(getAdditionalStatisticsState(state));

export const additionalStatistics = {
  getAdditionalStatistics,
};

// pipeline information

const getPipelineInformation = (state: AppState) =>
  localPipelineInformationSelectors.selectPipelineInfo(getPipelineInformationState(state));

const selectPipelineCount = (state: AppState) =>
  localPipelineInformationSelectors.selectCount(getPipelineInformationState(state));

const selectPipelineOtherDealStages = (state: AppState) =>
  localPipelineInformationSelectors.selectOtherDealStages(getPipelineInformationState(state));

const selectPipelineInfo = (state: AppState) =>
  localPipelineInformationSelectors.selectPipelineInfo(getPipelineInformationState(state));

const selectSelectedPeriodDate = (state: AppState) =>
  localPipelineInformationSelectors.selectSelectedPeriodDate(getPipelineInformationState(state));

const selectPreviousPeriodDate = (state: AppState) =>
  localPipelineInformationSelectors.selectPreviousPeriodDate(getPipelineInformationState(state));

const selectPreviousPeriodName = createSelector(
  (state: AppState) => filters.getSelectValue(state, 'previous_period', pipelineCriteria),
  value => {
    return value.length ? value[0].label : 'Previous Period';
  }
);

const isLoadingPipelineInformation = requestSelectors.createLoadingSelector([
  pipelineInformationTypes.fetch.SELECTOR,
]);

export const pipelineInformation = {
  isLoading: isLoadingPipelineInformation,
  getPipelineInformation,
  selectCount: selectPipelineCount,
  selectOtherDealStages: selectPipelineOtherDealStages,
  selectPipelineInfo,
  selectSelectedPeriodDate,
  selectPreviousPeriodDate,
  selectPreviousPeriodName,
};
