import { createAction } from '@reduxjs/toolkit';
// models
import {
  SelectedCompaniesPayload,
  FetchBulkEditData,
  FetchBulkEditDataSuccess,
  BulkUpdateDataSuccess,
} from './interfaces';
import * as types from './types';

export const selectCompanyIds = createAction<SelectedCompaniesPayload>(types.selectCompanyIds);

export const fetchBulkEditData = createAction<FetchBulkEditData>(types.fetchBulkEditData);
export const fetchBulkEditDataSuccess = createAction<FetchBulkEditDataSuccess>(
  types.fetchBulkEditDataSuccess
);

export const bulkUpdateDataSuccess = createAction<BulkUpdateDataSuccess>(
  types.bulkUpdateDataSuccess
);
