import { createSelector } from 'reselect';
import { AppState } from '@optx/redux/interfaces';

const salesloftState = (state: AppState) => state.salesloft;

export const isLoading = createSelector(salesloftState, state => state.loading);

export const getAuthUrl = createSelector(salesloftState, state => state.authUrl);

export const getCadences = createSelector(salesloftState, state => {
  return state?.cadences || [];
});

export const getPostData = createSelector(salesloftState, state => state.postData);
