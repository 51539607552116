import React from 'react';
import { Card } from 'antd';
import classnames from 'classnames';
// styles
import './styles.scss';
// interface
import { MyCompaniesCardProps } from './interface';
// components
import MyCompaniesCardTitle from './MyCompaniesCardTitle';
import CardContentChart from './Content/CardContentChart';
import CardContentColumn from './Content/CardContentColumn';

const MyCompaniesCard: React.FC<MyCompaniesCardProps> = props => {
  const { data, onClick, selected } = props;
  const { description, variation, values, filters } = data;

  return (
    <Card
      hoverable
      className={classnames('my-companies-card', { active: selected })}
      title={<MyCompaniesCardTitle {...props} />}
      onClick={onClick}
      bordered={false}
    >
      <div className="my-companies-card__content">
        {description ? (
          <div className="my-companies-card__content-description">{description}</div>
        ) : null}
        {values || filters ? (
          <>
            {variation === 'barchart' ? (
              <CardContentChart {...props} />
            ) : (
              <CardContentColumn values={values} data={data} />
            )}
          </>
        ) : null}
      </div>
    </Card>
  );
};

export default MyCompaniesCard;
