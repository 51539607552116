import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// models
import { FilterSource } from '@models/filters';
import { CompanyFiltersMeta } from '@models/search';
import { ResetFilter } from '@features/grid/search/state/interfaces';
// utils
import { filtersWithHistogram } from '@utils/filters/preselectedValues';
// redux
import { fetchReducer } from '@redux/feature/fetch/reducers';
import {
  HistogramFiltersState,
  FetchHistogramsSuccessActionPayload,
  FilterChangedPayload,
  ToggleRefreshPayload,
} from './interfaces';
import { GridPayload } from '@optx/models/grid';

export const fetchHistogramsFiltersSuccess: CaseReducer<
  HistogramFiltersState,
  PayloadAction<FetchHistogramsSuccessActionPayload>
> = (draftState, action) => {
  const { data, isDefault, companyCount } = action.payload;
  // Merge data values; if the changed filter is one that has histogram,
  // the response will contain other histograms except the one changed.
  const newData = data
    ? { ...(draftState.edit ? draftState.edit.data : draftState.data), ...data }
    : {};

  if (!draftState.fetchedAt) {
    // If it was just enabled update all histograms data.
    if (draftState.edit) {
      draftState.edit.data = newData;
    }

    draftState.data = newData;
  } else if (draftState.edit) {
    // Is in edit mode.
    draftState.edit.data = newData;
  } else {
    draftState.data = newData;
  }

  draftState.searchCount = companyCount;
  draftState.loading = false;
  draftState.error = '';

  if (isDefault && !draftState.defaultHistograms) {
    draftState.defaultHistograms = newData;
  }

  draftState.fetchedAt = new Date().toISOString();
};

// external reducers
export const fetchCompanyFiltersSuccessReducer: CaseReducer<
  HistogramFiltersState,
  PayloadAction<Array<FilterSource>, any, CompanyFiltersMeta>
> = (draftState, action) => {
  draftState.allFilters = filtersWithHistogram(action.payload);
};

export const fetchSearchCompaniesCountSuccessReducer: CaseReducer<
  HistogramFiltersState
> = draftState => {
  draftState.searchCount = undefined;
};

export const toggleHistogramRefresh: CaseReducer<
  HistogramFiltersState,
  PayloadAction<ToggleRefreshPayload>
> = (draftState, action) => {
  const { enabled } = action.payload;

  draftState.enabled = enabled;

  if (!enabled) {
    draftState.data = {};
    draftState.searchCount = undefined;
    draftState.edit = null;
    draftState.fetchedAt = null;
    draftState.lastChangedFilter = undefined;
  } else {
    // for now we know histograms can be enabled only when in edit modal (filters modal).
    draftState.edit = {
      data: {},
      changedFilters: [],
    };
  }
};

export const resetToDefaultReducer: CaseReducer<HistogramFiltersState> = draftState => {
  draftState.loading = false;
  draftState.changedFilters = [];

  if (draftState.defaultHistograms) {
    draftState.data = draftState.defaultHistograms;
    draftState.searchCount = draftState.defaultHistograms.search_count as number;

    if (draftState.edit) {
      draftState.edit.data = draftState.defaultHistograms;
      draftState.edit.changedFilters = [];
    }
  }
};

export const fetchHistogramsReducer: CaseReducer<HistogramFiltersState> = draftState => {
  if (draftState.enabled) {
    fetchReducer(draftState);
  }
};

export const resetReducer: CaseReducer<HistogramFiltersState> = draftState => {
  draftState.changedFilters = [];
  draftState.lastChangedFilter = undefined;

  if (draftState.enabled) {
    if (draftState.defaultHistograms) {
      draftState.data = draftState.defaultHistograms;

      if (draftState.edit) {
        draftState.edit.data = draftState.defaultHistograms;
        draftState.edit.changedFilters = [];
      }
    }
  }
};

export const clearReducer: CaseReducer<HistogramFiltersState> = draftState => {
  draftState.changedFilters = [];
  draftState.lastChangedFilter = undefined;

  if (draftState.enabled) {
    draftState.data = {};

    if (draftState.edit) {
      draftState.edit.data = {};
      draftState.edit.changedFilters = [];
    }
  }
};

export const filterChangedReducer: CaseReducer<
  HistogramFiltersState,
  PayloadAction<FilterChangedPayload>
> = (draftState, action) => {
  const { filterKey } = action.payload;

  if (draftState.enabled) {
    draftState.lastChangedFilter = filterKey;

    if (draftState.edit) {
      const index = draftState.edit.changedFilters.indexOf(filterKey);

      if (index === -1) {
        draftState.edit.changedFilters.push(filterKey);
      }
    } else {
      const index = draftState.changedFilters.indexOf(filterKey);

      if (index === -1) {
        draftState.changedFilters.push(filterKey);
      }
    }
  }
};

export const editReducer: CaseReducer<HistogramFiltersState, PayloadAction<boolean>> = (
  draftState,
  action
) => {
  if (draftState.enabled) {
    const isEditing = action.payload;

    if (isEditing) {
      draftState.edit = {
        data: draftState.data,
        changedFilters: [],
      };
    } else {
      draftState.edit = null;
      draftState.lastChangedFilter = undefined;
      draftState.searchCount = undefined;
    }
  }
};

export const applyEditReducer: CaseReducer<HistogramFiltersState> = draftState => {
  if (draftState.enabled) {
    draftState.lastChangedFilter = undefined;

    if (draftState.edit) {
      draftState.data = draftState.edit.data;
      draftState.changedFilters = draftState.edit.changedFilters;

      // Modal is closed so need to reset edit.
      draftState.edit = null;
    }
  }
};

export const resetFilterReducer: CaseReducer<
  HistogramFiltersState,
  PayloadAction<ResetFilter | GridPayload<ResetFilter>>
> = (draftState, action) => {
  if (draftState.enabled) {
    const tag = (action.payload as GridPayload<ResetFilter>).data
      ? (action.payload as GridPayload<ResetFilter>).data.selectedTag
      : (action.payload as ResetFilter).selectedTag;
    draftState.lastChangedFilter = undefined;

    const index = draftState.changedFilters.indexOf(tag.filter);

    if (index !== -1) {
      draftState.changedFilters.splice(index, 1);
    }
  }
};
