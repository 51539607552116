import React, { useState, useRef, useMemo } from 'react';
import { Button } from 'antd';
// models
import Company, { CompanyProfile } from '@optx/models/Company';
// services
import { CompanyService } from '@optx/services/api';
// hooks
import useUpdateFields from '../../hooks/useUpdateFields';
// components
import EditPopover from '../../../../../components/common/popover/EditPopover';
import LocationSearchInput from './LocationSearchInput';
// styles
import Styled from './EditFieldLocation.styled';

interface EditFieldLocationProps {
  record: Company | CompanyProfile;
  children: React.ReactNode;
}

const EditFieldLocation: React.FC<EditFieldLocationProps> = ({ record, children }) => {
  const value = record.location;
  const title = 'Edit "Location" value';
  const [customValue, setCustomValue] = useState('');
  const [locationId, setLocationId] = useState('');
  const fieldName = 'Location';
  const [closePopup, setClosePopup] = useState(false);
  const [error, setError] = useState('Address should contain street with number and name!');
  const [showError, setShowError] = useState(false);
  const popoverContentRef = useRef(null);

  const { updateField } = useUpdateFields({
    fieldName,
    companyId: record.company_id,
    companyUrl: record.company_url,
    successMessage: 'Location has been changed!',
    service: CompanyService.companyLocationChange,
  });

  const handleSave = () => {
    if (locationId.length === 0) {
      setError('Please select one of the addresses from the list!');
      setShowError(true);

      return;
    }

    if (locationId.length) {
      updateField(locationId, 'location');
      setClosePopup(true);
      setLocationId('');
      setCustomValue('');
    } else {
      setShowError(true);
    }
  };

  const handleCancel = () => {
    setClosePopup(true);
    setShowError(false);
    setLocationId('');
    setCustomValue('');
  };

  const handleChange = (locationValue: string, locationId: string) => {
    setCustomValue(locationValue);
    setLocationId(locationId);
    setShowError(false);
  };

  const isDisabled = useMemo(() => {
    return record.location === customValue;
  }, [record.location, customValue]);

  const content = (
    <Styled.LocationPopover ref={popoverContentRef}>
      <LocationSearchInput
        handleChange={handleChange}
        placeholder={value}
        address={customValue === 'Unknown' ? '-' : customValue}
      />
      {showError && <p className="error-message">{error}</p>}
      <Styled.LocationButtonsContainer>
        <Button className="location-change__cancel" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          disabled={isDisabled}
          className="location-change__save"
          type="primary"
          onClick={handleSave}
        >
          Save
        </Button>
      </Styled.LocationButtonsContainer>
    </Styled.LocationPopover>
  );

  return (
    <Styled.ContainerCompanyLocation>
      <EditPopover
        content={content}
        title={title}
        closePopup={closePopup}
        setClosePopup={setClosePopup}
        placement="bottom"
        destroyOnHide
      >
        {children}
      </EditPopover>
    </Styled.ContainerCompanyLocation>
  );
};

export default React.memo(EditFieldLocation);
