import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import uiReducer from '../../../redux/ui';
import { saga } from '../../../redux/ui/modals/company-touches';

const useInjectUI = () => {
  useInjectReducer({
    key: 'ui',
    reducer: uiReducer,
  });
  useInjectSaga({
    key: 'ui',
    saga,
  });
};

export default useInjectUI;
