import React, { useMemo, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu, Affix } from 'antd';
import AntIcon, {
  SearchOutlined,
  ContactsOutlined,
  SettingOutlined,
  BarChartOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import { SiderProps } from 'antd/lib/layout';
import { Dictionary } from 'lodash';
// constants
import navigationPaths, { NAVIGATION_SUBMENUES } from '@constants/main-navigation';
import appRoutes from '@constants/routes';
// utils
import { getDefaultOpenedKeys, getOpenedKeys } from '@optx/core/layout/MainNavigation/utils';
// hooks
import {
  useInjectAuth,
  useInjectCompanyLists,
  useInjectMyCompanies,
  useInjectPoolingGenerator,
  useInjectSuggestedSearches,
} from '@hooks/inject';
import useIsAnalyst from '@hooks/useIsAnalyst';
// components
import Icon from '@components/common/Icon';
import AddResearchRationale from '@optx/features/add-research-rationale';
import { PrivateMenuItem } from '@components/common/menu/menu-items';
import { PrivateSubmenu } from '@components/common/menu/submenu';
import { LogOut, Notifications, ProfileSubMenu, SuggestedSearch } from './menu-items';
import NavigationLogo from './NavigationLogo';
import NavigationTrigger from './NavigationTrigger';

const { Sider } = Layout;
const { SubMenu } = Menu;

const useInject = () => {
  useInjectAuth();
  // Inject in common component for state to be available in all pages.
  // For example when slice state initialization depends on user information,
  // but if state is not injected there will be no initialization.
  useInjectCompanyLists();
  useInjectPoolingGenerator();
  useInjectMyCompanies();
  useInjectSuggestedSearches();
};

const defaultOpenedKeysExceptions: Dictionary<boolean> = {
  [NAVIGATION_SUBMENUES.profile]: true,
};

const defaultOpenedKeys = getDefaultOpenedKeys(navigationPaths, defaultOpenedKeysExceptions);

const MainNavigation: React.FC<SiderProps> = ({ collapsed, ...restProps }) => {
  useInject();

  const location = useLocation();

  const [openedKeys, setOpenedKeys] = useState<Array<string>>(collapsed ? [] : defaultOpenedKeys);
  const [clickedOutsideNotifications, setClickedOutsideNotifications] = useState(false);
  const selectedKeys = useMemo(() => [location.pathname], [location.pathname]);
  const { isAnalyst, advancedSearchPathname } = useIsAnalyst();

  useEffect(() => {
    if (!collapsed) {
      // Update opened submenues when location path changes.
      const keys = getOpenedKeys(navigationPaths, location.pathname);

      if (keys.length && !openedKeys.includes(keys[0])) {
        const newKeys = [...openedKeys, ...keys];
        setOpenedKeys(newKeys);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, collapsed]);

  const handleSubmenuOpenChange = (keys: any) => {
    setOpenedKeys(keys);
  };

  const handleSubMenuSelect = (keys: any) => {
    if (collapsed) {
      setOpenedKeys([]);
    }
  };

  const handleBottomSubmenuSelect = () => {
    setClickedOutsideNotifications(true);
  };

  return (
    <Sider
      trigger={<NavigationTrigger />}
      collapsible
      width={256}
      className="main-navigation"
      collapsed={collapsed}
      {...restProps}
    >
      <Link to={appRoutes.home}>
        <NavigationLogo />
      </Link>
      <Menu
        theme="dark"
        selectedKeys={selectedKeys}
        openKeys={openedKeys}
        onOpenChange={handleSubmenuOpenChange}
        onSelect={handleSubMenuSelect}
        mode="inline"
        className="main-navigation__menu-top"
      >
        <Menu.Item
          key={1}
          style={{ paddingLeft: 0, paddingRight: 0 }}
          className="main-navigation__menu-custom-item"
        >
          <Notifications
            clickedOutsideNotifications={clickedOutsideNotifications}
            setClickedOutsideNotifications={setClickedOutsideNotifications}
          />
        </Menu.Item>
        {isAnalyst && (
          <PrivateMenuItem key={appRoutes.home} accessFor="analyst_general" icon={<HomeOutlined />}>
            <Link to={appRoutes.home}>Home</Link>
          </PrivateMenuItem>
        )}
        <Menu.Item key={advancedSearchPathname} icon={<SearchOutlined />}>
          <Link to={advancedSearchPathname}>Advanced Search</Link>
        </Menu.Item>
        <SubMenu
          key={NAVIGATION_SUBMENUES.newOpportunities}
          icon={<AntIcon component={() => <Icon iconName="new-opportunities" />} />}
          title="New Opportunities"
        >
          <SuggestedSearch
            title="US New Opportunities"
            label="US Funds"
            key="us-new-opportunities"
          />
          <SuggestedSearch
            title="Europe Weekly Leads"
            label="Europe Funds"
            key="eu-new-opportunities"
          />
        </SubMenu>
        <SubMenu
          key={NAVIGATION_SUBMENUES.pipeline}
          icon={<AntIcon component={() => <Icon iconName="pipeline" />} />}
          title="Manage My Pipeline"
        >
          <Menu.Item key={appRoutes.myCompanies}>
            <Link to={appRoutes.myCompanies}>My Companies</Link>
          </Menu.Item>
          <Menu.Item key={appRoutes.lists.myWatchList}>
            <Link to={appRoutes.lists.myWatchList}>My Watchlist</Link>
          </Menu.Item>
          <Menu.Item key={appRoutes.lists.companySavedSearches}>
            <Link to={appRoutes.lists.companySavedSearches}>Saved Company Searches</Link>
          </Menu.Item>
          {/* <Menu.Item key={appRoutes.notes}>
            <Link to={appRoutes.notes}>My Global Notes</Link>
          </Menu.Item> */}
          <Menu.Item key={appRoutes.scheduledTouches}>
            <Link to={appRoutes.scheduledTouches}>Touch Management</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key={NAVIGATION_SUBMENUES.findNewCompanies}
          icon={<AntIcon component={() => <Icon iconName="business" />} />}
          title="Find New Companies"
        >
          <Menu.Item key={appRoutes.lists.suggestedSearches}>
            <Link to={appRoutes.lists.suggestedSearches}>Suggested Searches</Link>
          </Menu.Item>
          <Menu.Item key={appRoutes.lists.sourceScrubLists}>
            <Link to={appRoutes.lists.sourceScrubLists}>Source Scrub Lists</Link>
          </Menu.Item>
          <SuggestedSearch
            title="Recently Funded"
            label="Browse by Recently Funded"
            key="recently-funded"
          />
        </SubMenu>
        <SubMenu key={NAVIGATION_SUBMENUES.contacts} icon={<ContactsOutlined />} title="Contacts">
          <Menu.Item key={appRoutes.contacts}>
            <Link to={appRoutes.contacts}>All Contacts</Link>
          </Menu.Item>
          <Menu.Item key={appRoutes.lists.contactSearches}>
            <Link to={appRoutes.lists.contactSearches}>Saved Contact Searches</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key={NAVIGATION_SUBMENUES.workforceManagement}
          icon={<BarChartOutlined />}
          title="Reports"
        >
          <Menu.Item key={appRoutes.reports.allReports}>
            <Link to={appRoutes.reports.allReports}>All Reports</Link>
          </Menu.Item>
        </SubMenu>
        <PrivateSubmenu
          accessFor={['admin_portal_link']}
          key={NAVIGATION_SUBMENUES.admin}
          icon={<SettingOutlined />}
          title="Admin Portal"
        >
          <PrivateMenuItem key={appRoutes.admin.users} accessFor="admin_portal_link">
            <Link to={appRoutes.admin.users}>All Users</Link>
          </PrivateMenuItem>
        </PrivateSubmenu>
      </Menu>
      <Affix offsetBottom={48} style={{ marginTop: 'auto' }}>
        <Menu
          theme="dark"
          selectedKeys={selectedKeys}
          openKeys={openedKeys}
          onSelect={handleBottomSubmenuSelect}
          onOpenChange={handleSubmenuOpenChange}
          mode="inline"
        >
          <ProfileSubMenu key={NAVIGATION_SUBMENUES.profile}>
            <PrivateMenuItem key={appRoutes.user.profile} accessFor="profile">
              <Link to={appRoutes.user.profile}>My Profile Page</Link>
            </PrivateMenuItem>
            <LogOut key="12" />
          </ProfileSubMenu>
        </Menu>
      </Affix>
      <AddResearchRationale />
    </Sider>
  );
};

export default React.memo(MainNavigation);
