import { createAction } from '@reduxjs/toolkit';
import { Dictionary } from 'lodash';
// models
import Company from '@models/Company';
import { ChartBarIntervalKeys } from '@models/charts/data';
import { GridPayloadMain } from '@models/grid';
import { PreselectedFilter } from '@models/filters';
import { SuccessErrorCallback } from '@models/callback';
import { LoadSuggestedSearch, ResetFilter } from './interfaces';
import { SearchPayload } from '@models/api/contacts';
import { SearchAutocomplete } from '@models/search-autocomplete';
import { SearchStateData } from '@models/search';
import * as types from './types';

export const initialCompaniesSearch = createAction<
  GridPayloadMain<Partial<SearchPayload> | undefined>
>(types.INITIAL_COMPANIES_SEARCH);

export const searchCompanies = createAction(
  types.COMPANIES_SEARCH,
  (payload: GridPayloadMain<Partial<SearchPayload> | undefined>, resetSubFilter?: boolean) => ({
    payload,
    meta: resetSubFilter,
  })
);

export const searchCompaniesSuccess = createAction(
  types.COMPANIES_SEARCH_SUCCESS,
  (companies: Company[], searchPayload: GridPayloadMain<Partial<SearchPayload>>) => ({
    payload: companies,
    meta: searchPayload,
  })
);

export const searchCompaniesFail = createAction<GridPayloadMain<string>>(
  types.COMPANIES_SEARCH_FAIL
);

export const cancelCompanySearch = createAction<GridPayloadMain<boolean>>(
  types.CANCEL_COMPANY_SEARCH
);

export const hideCancelSearch = createAction<GridPayloadMain<boolean>>(types.HIDE_CANCEL_SEARCH);

export const updateTrending = createAction<GridPayloadMain<ChartBarIntervalKeys>>(
  types.UPDATE_TRENDING
);

export const clearSearch = createAction(
  types.CLEAR_COMPANY_SEARCH,
  (
    customClearedFilter: GridPayloadMain<Dictionary<PreselectedFilter> | boolean | undefined>,
    callback?: SuccessErrorCallback
  ) => ({
    payload: customClearedFilter,
    meta: callback,
  })
);

export const clearSearchSuccess = createAction<GridPayloadMain<boolean>>(
  types.CLEAR_COMPANY_SEARCH_SUCCESS
);

export const resetSearch = createAction(
  types.RESET_COMPANY_SEARCH,
  (shouldSort: GridPayloadMain<boolean>, callback?: SuccessErrorCallback) => ({
    payload: shouldSort,
    meta: callback,
  })
);

export const resetSearchSuccess = createAction<GridPayloadMain<boolean>>(
  types.RESET_COMPANY_SEARCH_SUCCESS
);

// add search id, title and listType to store when appyling a saved search
export const loadSavedSearch = createAction(
  types.LOAD_SAVED_SEARCH,
  (payload: GridPayloadMain<{ searchId: number | string; title: string; listType: string }>) => ({
    payload,
  })
);

export const loadSuggestedSearch = createAction<GridPayloadMain<LoadSuggestedSearch>>(
  types.LOAD_SUGGESTED_SEARCH
);

export const resetFilter = createAction<GridPayloadMain<ResetFilter>>(types.RESET_FILTER);

export const loadAutocompleteSearch = createAction<GridPayloadMain<SearchAutocomplete>>(
  types.LOAD_AUTOCOMPLETE_SEARCH
);

export const loadCompaniesByFilter = createAction(
  types.LOAD_COMPANIES_BY_FILTER,
  (filterKey: string, value: GridPayloadMain<PreselectedFilter>) => ({
    payload: value,
    meta: filterKey,
  })
);

export const saveSearchTitle = createAction<GridPayloadMain<string>>(types.SAVE_SEARCH_TITLE);

export const deleteSavedList = createAction<GridPayloadMain<string>>(types.DELETE_SAVED_LIST);

// external actions

export const loadCompaniesByFilterCompleted = createAction(
  types.LOAD_COMPANIES_BY_FILTER_COMPLETED
);

export const loadDiversityFilter = createAction<string>(types.LOAD_DIVERSITY_FILTER);

export const initializeLongCardSearch = createAction(types.INITIALIZE_LONG_CARD_SEARCH);

// sourcing outreach actions
export const shouldUpdateSourcingOutReachAnalystInfo = createAction<boolean>(
  types.SHOULD_UPDATE_SOURCING_OUT_REACH_ANALYST_INFO
);

export const updateSourcingOutReachAnalystInfo = createAction<{
  tableGridQuery: string;
}>(types.UPDATE_SOURCING_OUT_REACH_ANALYST_INFO);

export const removeFilter = createAction<GridPayloadMain<ResetFilter>>(types.REMOVE_FILTER);

export const clearFilterSuccess = createAction<SearchStateData | null | undefined>(
  types.CLEAR_FILTER_SUCCESS
);
