import React from 'react';
import { Tag } from 'antd';
// constants
import { OPERATOR_VALUES } from '@optx/constants/combineSearch';

// Generate the More Tags tag
export const generateMoreTags = (totalTagsToDisplay: number, listType?: string | null) => {
  return totalTagsToDisplay > 0 ? (
    // for combined search divide number on 2 to show number of extra searches only, not include operators
    <Tag color="blue">
      {listType === 'combined' ? Math.ceil(totalTagsToDisplay / 2) : totalTagsToDisplay} more
    </Tag>
  ) : null;
};

// Display tags list in a popover
export const generateTagsListPopover = (
  filterNames: string[],
  hiddenChildrenIdList: string[],
  listType: string | null
) => {
  let remainingTagsList = [...filterNames];
  remainingTagsList = remainingTagsList.filter(tag =>
    hiddenChildrenIdList.includes(tag?.substr(0, tag.indexOf(':')))
  );

  if (listType === 'combined') {
    return (
      <div className="company-list__popover">
        {hiddenChildrenIdList.map((filterName, index) => {
          const className = OPERATOR_VALUES.includes(filterName)
            ? 'saved__filter-value'
            : 'saved__filter-name';

          return (
            <Tag key={index} id={filterName} className="saved__filter">
              <span className={className}>{filterName}</span>
            </Tag>
          );
        })}
      </div>
    );
  }

  return (
    <div className="company-list__popover">
      {remainingTagsList.map((filter, index) => {
        const filterItem = filter.split(':');

        return (
          <div key={index}>
            <Tag key={index} className="saved__filter">
              <span className="saved__filter-name">{`${filterItem[0]}:`}</span>
              <span className="saved__filter-value">{filterItem[1]}</span>
            </Tag>
          </div>
        );
      })}
    </div>
  );
};
