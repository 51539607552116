import React from 'react';

interface DropdownAddContextContext {
  dropdownVisible: boolean;
  setDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DropdownAddContext = React.createContext<DropdownAddContextContext>({
  dropdownVisible: false,
  setDropdownVisible: () => {},
});
