import styled from 'styled-components';

const TextWrapper = styled.div`
  text-align: center;
  margin: 0 8px 0 12px;
`;

const SelectText = styled.div`
  color: #e8e8e8;
  margin-top: -5px;
`;

const FitText = styled.span`
  color: #334d6e;
  font-weight: 600;
`;

const Thumb = styled.div`
  border-radius: 21px;
  width: 42px;
  height: 42px;
  padding: 7px;
  cursor: pointer;
`;

const ThumbUp = styled(Thumb)`
  margin-right: 6px;
  padding: 5px 5px 5px 8px;
  border: 1px solid #52c41a;
`;

const ThumbDown = styled(Thumb)`
  padding: 9px 5px 5px 5px;
  border: 1px solid #f5222d;
`;

const Styles = {
  TextWrapper,
  SelectText,
  FitText,
  ThumbUp,
  ThumbDown,
};

export default Styles;
