import { Tooltip } from 'antd';
import styled from 'styled-components';

const CellPopover = styled.div`
  min-width: 200px;
  width: 100%;
  .singleselect-wrapper {
    width: 100%;
    min-width: 200px;
  }
`;

const DefaultTooltip = styled(Tooltip)`
  display: block;
`;

export default {
  CellPopover,
  DefaultTooltip,
};
