import { createAction } from '@reduxjs/toolkit';
// models
import { SmartTouchScore } from '@optx/models/feature/analystsLeaderboard/SmartTouchScore';
// types
import * as types from './types';

// fetch
export const fetch = createAction(types.FETCH);
export const fetchSuccess = createAction<SmartTouchScore>(types.FETCH_SUCCESS);
export const fetchFail = createAction<string>(types.FETCH_FAIL);
