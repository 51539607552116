import { createSelector } from 'reselect';
import { selectors as loaderSelectors } from '@optx/features/request';
import * as localSearch from './search';
import * as localFilters from './filters';
import { CompanyTouchesState } from '../interfaces';
import { featureName } from '../../featureName';
import * as types from '../types/search';

const selectFeatureState = (state: any) => state[featureName] as CompanyTouchesState;
const selectSearchState = createSelector(selectFeatureState, state => state?.search);
const selectFiltersState = createSelector(selectFeatureState, state => state?.filters);

const companiesTouches = createSelector(selectSearchState, localSearch.getTouches);

export const isLoadingSearch = loaderSelectors.createLoadingSelector([
  types.fetchCompanyTouches.SELECTOR,
]);

const getPagination = createSelector(selectSearchState, state => state?.pagination);

const search = {
  isLoading: isLoadingSearch,
  data: companiesTouches,
  // @ts-ignore
  pagination: createSelector(selectSearchState, getPagination),
  companyId: createSelector(selectSearchState, localSearch.getCompanyId),
  count: createSelector(selectSearchState, localSearch.getTouchesCount),
  total: createSelector(selectSearchState, localSearch.getTotalTouches),
};

const filters = {
  isLoading: createSelector(selectFiltersState, localFilters.isLoading),
};

export const selectors = {
  search,
  filters,
};
