import styled from 'styled-components';
import { Col } from 'antd';

const LocationFieldWrapper = styled(Col)`
  .location-input-wrapper {
    position: relative;
    z-index: 1;
  }
  .location-search-menu {
    position: absolute;
    left: 0;
    top: 100%;
    min-width: 100%;
    width: 100%;
    z-index: 3;
    padding: 5px 0;
  }
  .location-search-menu li {
    line-height: 20px;
    height: auto;
    margin: 0 !important;
    padding: 5px 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  .location-search-menu li:hover,
  .location-search-menu li.ant-select-item-option-selected {
    background-color: #f5f5f5;
  }
`;
export default {
  LocationFieldWrapper,
};
