import React, { useEffect } from 'react';
import { Table } from 'antd';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { useDispatch, useSelector } from 'react-redux';
// models
import { TableChangeCallback } from '@optx/models/table/antd-table';
import { CompanyTabLists } from '@optx/models/Company';
// constants
import { CompanyProfileTabs } from '@optx/constants/routes';
// utils
import { generateUniqueKey } from '@optx/utils/uuid';
import { mapSorting } from '@utils/table/sorting';
// redux
import { selectors as listsSelectors, actions as listActions } from '@optx/redux/company/lists';
import { selectors as userSelectors } from '@redux/user/information';
// hooks
import { useInjectCompanyTabList } from '@hooks/inject';
import { useInject as useInjectCompanyIndividualEdit } from '@optx/components/feature/company-individual-edit';
import { useFetchLists } from '@components/pages/CompanyProfile/ProfileTabs/Lists/hooks';
// components
import FixedLoader from '@optx/components/common/loader';
// styles
import './lists.scss';
import { SortByRule } from '@optx/models/table/sorting';

interface ListsProps {
  companyId: number;
  activeKey?: string;
  isExtension?: boolean;
}

const Lists: React.FC<ListsProps> = ({ companyId, activeKey, isExtension }) => {
  useInjectCompanyTabList();
  useInjectCompanyIndividualEdit();
  const dispatch = useDispatch();
  const fetchLists = useFetchLists(companyId);

  const loading = useSelector(listsSelectors.isLoading);
  const hasMore = useSelector(listsSelectors.getHasMore);
  const paginationPayload = useSelector(listsSelectors.getPaginationQuery);
  const getListData = useSelector(listsSelectors.getListData);
  const defaultColumns = useSelector(listsSelectors.getGridColumns);
  const userInformationLoaded = useSelector(userSelectors.loaded);

  useEffect(() => {
    if ((activeKey === CompanyProfileTabs.LISTS || isExtension) && userInformationLoaded) {
      fetchLists({
        companyId,
        ...paginationPayload,
        pageNumber: paginationPayload.pageNumber,
        sortBy: paginationPayload.sortBy,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey, userInformationLoaded]);

  const handleLoadMore = () => {
    fetchLists({
      companyId,
      ...paginationPayload,
      pageNumber: paginationPayload.pageNumber + 1,
      sortBy: paginationPayload.sortBy,
    });
  };

  const onChange: TableChangeCallback<CompanyTabLists> = (pagination, filters, sorter, extra) => {
    switch (extra.action) {
      case 'sort': {
        const newSorting = mapSorting(sorter, paginationPayload.sortBy);
        dispatch(
          listActions.sortCompanyTabLists({
            companyId,
            ...paginationPayload,
            pageNumber: 1,
            sortBy: newSorting.sortBy as Array<SortByRule<any>>,
          })
        );
        break;
      }

      default:
        break;
    }
  };

  const infiniteRef = useInfiniteScroll<HTMLDivElement>({
    loading,
    hasNextPage: hasMore,
    onLoadMore: handleLoadMore,
  });

  const data = React.useMemo(
    () =>
      getListData.map(item => ({
        unique_id: item.unique_id,
        title: item.title,
        origin: item.origin,
        owner_name: item.owner_name,
        list_type: item.list_type,
        count: item.count,
        clickable: item.clickable,
        created_at: item.created_at,
        modified_at: item.modified_at,
      })),
    [getListData]
  );

  return (
    <div ref={infiniteRef}>
      {isExtension && <FixedLoader loading={loading} />}
      <Table
        tableLayout="fixed"
        columns={defaultColumns}
        dataSource={data}
        onChange={onChange}
        showSorterTooltip={false}
        pagination={false}
        rowKey={generateUniqueKey}
        className="legacy-table list-table"
        bordered
      />
    </div>
  );
};

export default Lists;
