import React, { useState, useContext, Context } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'antd';
// models
import { SelectOption } from '@optx/models/Option';
import { BulkEditFieldContext } from '@optx/models/bulkActions';
// components
import SingleSelect from '@optx/components/common/select/SingleSelect';
// redux
import { selectors } from '@components/feature/company-individual-edit/state';

interface EditPipelineRankProps {
  context: Context<BulkEditFieldContext>;
}

export const EditPipelineRank: React.FC<EditPipelineRankProps> = ({ context }) => {
  const { setSelectedData } = useContext(context);

  const initialSelectValue = { value: '', label: '' };
  const [selectedValue, setSelectedValue] = useState(initialSelectValue);

  const selectOptions = [
    { value: 'blank', label: 'None' },
    ...useSelector(selectors.companyIndividualEdit.rankOptions),
  ];
  const isLoadingInfo = useSelector(selectors.companyIndividualEdit.isLoadingInfo);

  return (
    <>
      <Typography.Text className="ant-modal-title">Select New Pipeline Rank</Typography.Text>

      <SingleSelect
        options={selectOptions}
        onChange={option => {
          setSelectedValue(option as SelectOption);
          setSelectedData([option as SelectOption]);
        }}
        value={selectedValue}
        menuIsOpen
        isLoading={isLoadingInfo}
        className="single-select-menu-is-opened-container"
        maxMenuHeight={200}
      />
    </>
  );
};
