import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

// constants
import appRoutes from '@constants/routes';
// utils
import mapFiltersToURLParams from '@optx/utils/filters/mapFiltersToURLParams';
// models
import { SourcingOutreachUserDataKey } from '@optx/features/sourcing-outreach-summary/models/SourcingOutreach';
// redux
import { actions as sourcingOutReachViewActions } from '@features/grid/view';
import { selectors } from '@optx/features/sourcing-outreach-summary/state/selectors/commonFilters';

interface TableCellCompaniesCountProps {
  columnName: SourcingOutreachUserDataKey;
  value: number;
  analystName: string;
}

interface AnalystFilter {
  label: string;
  value: string;
}

export const TableCellCompaniesCount: React.FC<TableCellCompaniesCountProps> = ({
  value,
  analystName,
  columnName,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { normalized, filter } = useSelector(selectors.filters.getFilters);
  const query = useSelector(selectors.filters.searchKey);
  const normalizedFilter = {
    ...filter,
    analyst_id: (filter.analyst_id as AnalystFilter[])?.filter(item => item.label === analystName),
  };

  if (!value) {
    return <span>{value}</span>;
  }

  const queryData = {
    ...mapFiltersToURLParams(normalized, normalizedFilter),
    ...(query && { outreach_query: query }),
  };

  const payload = queryString.stringify(queryData, {
    arrayFormat: 'comma',
  });

  const handleClick = (columnName: SourcingOutreachUserDataKey) => {
    dispatch(sourcingOutReachViewActions.resetViewToSourcingOrder());

    history.push({
      pathname: appRoutes.reports.sourcingOutreachSummaryCompanies,
      state: { column_name: columnName, analyst_name: [analystName], filters: payload },
    });
  };

  return (
    <span onClick={() => handleClick(columnName)} role="presentation">
      {value}
    </span>
  );
};
