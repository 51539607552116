import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { defaultColumns } from '@components/common/table/TableBulkSalesloft';
import { Table } from 'antd';
import { actions as bulkSalesloftActions } from '@optx/features/bulk-actions/salesloft';
import { useLocation } from 'react-router';
import CadenceTitle from '../common/CardTitle';
import { CardWrapper } from '../SalesloftBulk.styled';
import { SalesloftAddContact } from './SalesloftAddContact';

const SelectedCompanies: React.FC = () => {
  const dispatch = useDispatch();
  const count = useSelector(bulkActionsSelectors.editFields.selectedCompaniesCount);
  const attentionCount = useSelector(bulkActionsSelectors.salesloft.getAttentionCount);
  const modalVisible = useSelector(bulkActionsSelectors.salesloft.modalVisible);
  const selectedCompanies = useSelector(bulkActionsSelectors.salesloft.salesloftBulkCompanies);
  const location = useLocation();
  const subtitle = location.pathname.includes('salesloft-bulk')
    ? 'Identify which contacts from each company to target'
    : `${attentionCount} companies need attention`;

  return (
    <CardWrapper
      title={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <CadenceTitle title={`${count} Selected Companies`} subtitle={subtitle} />
      }
    >
      <Table
        tableLayout="fixed"
        rowKey={'company_id'}
        columns={defaultColumns}
        dataSource={selectedCompanies}
        pagination={false}
        bordered
        className="legacy-table eqt-bulk__table-selected"
      />
      <SalesloftAddContact
        onCancel={() => {
          dispatch(bulkSalesloftActions.setModalVisible(false));
        }}
        visible={modalVisible}
        contactData={{}}
      />
    </CardWrapper>
  );
};

export default SelectedCompanies;
