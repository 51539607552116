import React from 'react';
import styled from 'styled-components';
import { IndicatorProps } from 'react-select';
// components
import { CloseCircleFilled } from '@ant-design/icons';

const StyledClearIndicator = styled(CloseCircleFilled)`
  color: #c1c1c1;
  font-size: 12px;
  cursor: pointer;
`;

const ClearIndicator: React.FC<IndicatorProps<any>> = props => {
  const { children = <StyledClearIndicator />, innerProps } = props;

  return <div {...innerProps}>{children}</div>;
};

export default ClearIndicator;
