import React from 'react';
import { Menu, MenuItemProps } from 'antd';
import { useDispatch } from 'react-redux';
// models
import { UpdateListAssociation } from '@optx/models/WatchList';
// components
import Popconfirm from '@optx/shared/view/molecules/Popconfirm/Popconfirm';
// redux
import { actions } from '@optx/redux/favorite-lists/lists';

interface RemoveListAssociationMenuItemProps extends MenuItemProps {
  listId: number | string;
}

const RemoveListAssociationMenuItem: React.FC<RemoveListAssociationMenuItemProps> = ({
  listId,
  ...restProps
}) => {
  const dispatch = useDispatch();

  const handleConfirm = () => {
    const payload: UpdateListAssociation = { listId, addonCompany: 'blank' };
    dispatch(actions.updateFavoriteListAssociation(payload));
  };

  return (
    <Popconfirm title="List Association" onConfirm={() => handleConfirm()} action="Remove">
      {/* prevent dropdown from closing when clicking on this menu item */}
      <Menu.Item {...restProps} onClick={e => e.domEvent.stopPropagation()}>
        Remove List Association (Add-ons)
      </Menu.Item>
    </Popconfirm>
  );
};

export default RemoveListAssociationMenuItem;
