import { createAction } from '@reduxjs/toolkit';
import * as types from './actionTypes';
import { PageScrollHistory } from './interfaces';

const prepareSaveScrollAction = (
  pageKey: string,
  scrollHistory: Partial<PageScrollHistory> = {}
) => ({
  payload: {
    key: pageKey,
    scrollHistory,
  },
});

export const saveScrollPosition = createAction(types.SAVE_SCROLL_POSITION, prepareSaveScrollAction);
export const saveTableRow = createAction(types.SAVE_TABLE_ROW, prepareSaveScrollAction);
export const resetScrollHistory = createAction<string>(types.RESET_SCROLL_HISTORY);
