import React from 'react';
import { useSelector } from 'react-redux';
// redux
import { selectors } from '../../../redux/user-management';
// components
import FixedLoader from '../../common/loader';

const PageLoader = () => {
  const loading = useSelector(selectors.isLoading);

  return <FixedLoader loading={loading} />;
};

export default PageLoader;
