const LOWER_SUFFIX = '_lower';
const UPPER_SUFFIX = '_upper';

/**
 * Get lower range filter key.
 * @param column filter column
 */
export const lowerRangeKey = (column: string) => `${column}${LOWER_SUFFIX}`;

/**
 * Get upper range filter key.
 * @param column filter column
 */
export const upperRangeKey = (column: string) => `${column}${UPPER_SUFFIX}`;
