import React from 'react';
import { PlusOutlined } from '@ant-design/icons';

interface AddContactBtnProps {
  setVisible: (visible: boolean) => void;
}

const AddContactBtn: React.FC<AddContactBtnProps> = ({ setVisible }) => {
  return (
    <button
      type="button"
      className="change-contact-cell"
      onClick={() => {
        setVisible(true);
      }}
    >
      <span>Add Contact</span> <PlusOutlined />
    </button>
  );
};

export default AddContactBtn;
