import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// models
import { DateRangeOption } from '@optx/models/Option';
// components
import SingleSelectDateRanges from '@components/common/filters/SingleSelectDateRanges';
// redux
import { actions as searchActions, selectors as searchSelectors } from '@features/grid/search';
import {
  selectors as filterSelectors,
  actions as filterActions,
} from '@redux/company-outreach/filters';

interface FilterSingleSelectDateRangesProps {
  filterKey: 'ddate';
}

const FilterSingleSelectDateRanges: React.FC<FilterSingleSelectDateRangesProps> = ({
  filterKey,
}) => {
  const dispatch = useDispatch();

  const searchKey = useSelector(searchSelectors.getSearchKey('outreach'));

  const options = useSelector(filterSelectors.getSourcingFiltersOptionsDate);

  const value = useSelector(filterSelectors.getSourcingFilterOptionsDateValue);

  const handleChange = (option: DateRangeOption) => {
    dispatch(
      filterActions.updateSourcingFilterOptions({
        filterKey,
        label: option.label,
        value: [option.start, option.end],
      })
    );
    dispatch(
      searchActions.searchCompanies({
        gridKey: 'outreach',
        data: { searchKey, shouldResetPageNumber: true },
      })
    );
  };

  return (
    <SingleSelectDateRanges
      value={(value as [string, null]) || [null, null]}
      data={options}
      onChange={handleChange}
    />
  );
};

export default FilterSingleSelectDateRanges;
