import React, {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
  useLayoutEffect,
  useContext,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import moment from 'moment';
// models
import { SortRule } from '@models/table/sorting';
import { CompanyUserContact } from '@models/api/contacts';
import { TableChangeCallback } from '@models/table/antd-table';
// constants
import routes from '@constants/routes';
import { CONTACT_DEFAULT_SORT } from '@constants/table/sort/defaultSort';
import { PRESENT_DATE_FORMAT, ISO_DATE_FORMAT } from '@optx/constants/format/date';
// utils
import { mapSorting } from '@utils/table/sorting';
import { getContactId } from '@utils/contact';
import { checkOverflow } from '@optx/utils/utils';
// redux
import { selectors } from '@redux/contacts/search/search';
import { actions as contactsSortActions } from '@redux/contacts/search/sort';
import { selectors as fullscreenSelectors } from '@redux/ui/settings/fullscreen';
import { selectors as searchCountSelectors } from '@features/grid/search-count';
import { selectors as paginationSelectors } from '@features/grid/pagination';
// hooks
import { useVirtualScrollPosition, VirtualTableScrollYContext } from '@features/scroll-history';
import useActiveTableRow from '@hooks/dom/useActiveTableRow';
import useIsMount from '@hooks/useIsMount';
import { useSize } from '@umijs/hooks';
import { useVT } from 'virtualizedtableforantd4';
import { getSessionSettings } from '@optx/redux/user/information/selectors';
// components
import ExternalScroll from '@optx/components/common/external-scroll/ExternalScroll';
import { Styled } from './ContactsTable.styled';
import { GridPagination } from '@features/grid/pagination/components';

const paginationId = 'grid-pagination';

const ContactsTable: React.FC = () => {
  const data = useSelector(selectors.getContacts);
  const isMultiSort = useSelector(selectors.isMultiSort);
  const sessionSettings = useSelector(getSessionSettings);
  const columns = useSelector(selectors.getGridColumns);
  const sorting = useSelector(selectors.getSorting);
  const { pageNumber, pageSize } = useSelector(paginationSelectors.getPagination('contacts'));
  const totalItemsCount = useSelector(searchCountSelectors.getSearchCount('contacts'));
  const isFullscreen = useSelector(fullscreenSelectors.isFullscreen);
  const searchTitle = useSelector(selectors.getSearchTitle);
  const isLoadingCount = useSelector(searchCountSelectors.getSearchCountLoading('contacts'));
  const isLoadingContacts = useSelector(selectors.isLoading);
  const { onScroll, initialScrollPosition } = useVirtualScrollPosition('contacts');
  const [currentScroll, setCurrentScroll] = useState(0);
  const tableRef = useRef<HTMLDivElement>(null);
  const [externalScroll, setExternalScroll] = useState<Element | null>(null);

  const externalScrollElement = document.querySelector('.external-scroll');

  useEffect(() => {
    if (externalScrollElement !== null) {
      setExternalScroll(externalScrollElement);
    }
  }, [externalScrollElement]);

  const normalizedContacts = useMemo(() => {
    const normalizedContacts = data.map(contact => ({
      ...contact,
      ...(contact.tenure_start_date && {
        tenure_start_date: moment(contact.tenure_start_date).format(ISO_DATE_FORMAT),
      }),
      ...(contact.tenure_end_date && {
        tenure_end_date:
          contact.tenure_end_date !== PRESENT_DATE_FORMAT
            ? moment(contact.tenure_end_date).format(ISO_DATE_FORMAT)
            : contact.tenure_end_date,
      }),
    }));

    return normalizedContacts;
  }, [data]);

  const modifiedColumns = useMemo(
    () =>
      columns.map(item => {
        if (item.title !== 'Company' && item.title !== 'Email') {
          return {
            ...item,
            className: `${item.className} not-interactive`,
          };
        }

        return item;
      }),
    [columns]
  );

  const dispatch = useDispatch();
  const isMount = useIsMount();

  const onSortChange = useCallback(
    (sort: SortRule<CompanyUserContact>) => dispatch(contactsSortActions.changeSortAction(sort)),
    [dispatch]
  );

  useEffect(() => {
    if (!isMount) {
      onSortChange({ sortBy: CONTACT_DEFAULT_SORT });
    }

    setTimeout(() => {
      vtImperativeScroll.current?.scrollTo(initialScrollPosition);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMultiSort]);

  const vtImperativeScroll = useRef<any>();

  useEffect(() => {
    vtImperativeScroll.current?.scrollTo(0);
  }, [pageNumber, pageSize]);

  const onChange: TableChangeCallback<CompanyUserContact> = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    switch (extra.action) {
      case 'sort': {
        const parsedSort =
          sessionSettings?.contact_filters &&
          queryString.parse(sessionSettings?.contact_filters, {
            arrayFormat: 'comma',
          });

        const sort = mapSorting(
          sorter,
          sorting,
          parsedSort && (parsedSort?.query as string),
          CONTACT_DEFAULT_SORT,
          undefined,
          true
        );

        onSortChange(sort as SortRule<any>);
        break;
      }

      default:
        break;
    }
  };

  const [vt] = useVT(
    () => ({
      initTop: initialScrollPosition,
      scroll: { y: currentScroll },
      onScroll,
      ref: vtImperativeScroll,
    }),
    [currentScroll, initialScrollPosition]
  );

  const onRow = useActiveTableRow<CompanyUserContact>(getContactId, routes.contacts);

  const [windowSize] = useSize(window.document.documentElement);
  const ctx = useContext(VirtualTableScrollYContext);

  // @ts-ignoreu
  const [mainHeaderHeight] = useSize(document.getElementById('mainHeader'));
  // @ts-ignoreu
  const [tableHeader] = useSize(document.querySelector('.ant-table-header'));

  useLayoutEffect(() => {
    setTimeout(() => {
      if (tableRef.current) {
        const tableHeaderHeight =
          tableRef.current?.querySelector('.ant-table-header')?.getBoundingClientRect().height || 0;

        if (tableHeaderHeight) {
          const tableDOMRect = tableRef.current.getBoundingClientRect();
          const windowHeight = window.innerHeight;
          const paginationHeight =
            document.querySelector(`${paginationId}`)?.getBoundingClientRect().height || 0;
          const bottomSpace = tableDOMRect.bottom - windowHeight + paginationHeight;
          const nextScroll =
            bottomSpace < 0
              ? Math.abs(bottomSpace) + tableDOMRect.height - tableHeaderHeight
              : tableDOMRect.height - tableHeaderHeight - bottomSpace;
          setCurrentScroll(nextScroll - 66);
        }
      }
    }, 0);
  }, [ctx.height, windowSize.height, tableHeader.height, mainHeaderHeight.height]);

  const contentRef = useRef<HTMLDivElement | null>(
    tableRef.current?.querySelector('.ant-table-body') || null
  );

  if (!contentRef.current && tableRef.current) {
    contentRef.current = tableRef.current.querySelector('.ant-table-body') || null;
  }

  return (
    <>
      <Styled.ContactTableWrapper>
        {data.length && !isLoadingContacts ? (
          <ExternalScroll refElement={contentRef} isVirtualGrid />
        ) : null}
        <div ref={tableRef}>
          <Styled.ContactTable
            columns={modifiedColumns}
            className="legacy-table"
            dataSource={normalizedContacts}
            rowKey={getContactId}
            onChange={onChange}
            pagination={false}
            scroll={{ y: currentScroll, x: true }}
            components={vt}
            showSorterTooltip={false}
            bordered
            onRow={onRow}
            isEmpty={!data.length}
            overflowHeight={checkOverflow(externalScroll)}
          />
        </div>
      </Styled.ContactTableWrapper>

      <GridPagination
        id={paginationId}
        gridKey="contacts"
        totalItemsCount={totalItemsCount}
        pageNumber={pageNumber}
        pageSize={pageSize}
        searchCountPageSize={data.length}
        isFullscreen={isFullscreen}
        searchCountStyle={{ fontSize: 20 }}
        searchTitle={searchTitle}
        listType={{
          one: 'Contact',
          many: 'Contacts',
        }}
        isLoadingCount={isLoadingCount}
        isLoadingList={isLoadingContacts}
      />
    </>
  );
};

export default React.memo(ContactsTable);
