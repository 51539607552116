import React from 'react';
import { CartesianGridProps, LegendProps, LineProps, XAxisProps, YAxisProps } from 'recharts';

// Grid
export const cartesianGridProps: CartesianGridProps = {
  horizontal: false,
  vertical: false,
};

// Tick
export const tickDefaultProps: React.SVGProps<SVGElement> = {
  fill: '#C2CFE0',
  fontSize: 10,
  fontWeight: 600,
};

export const xAxisTickDefaultProps: React.SVGProps<SVGElement> = {
  ...tickDefaultProps,
  style: {
    marginRight: 10,
  },
};

export const yAxisTickDefaultProps: React.SVGProps<SVGElement> = {
  ...tickDefaultProps,
  fontSize: 12,
};

// X/Y axis
export const defaultAxisLineProps: React.SVGProps<SVGSVGElement> = {
  stroke: '#C2CFE0',
  strokeWidth: 1,
};

export const xAxisProps: XAxisProps = {
  padding: { left: 50, right: 50 },
  axisLine: defaultAxisLineProps,
  tickLine: false,
  dataKey: 'name',
  tick: xAxisTickDefaultProps,
  tickMargin: 20,
};

export const yAxisProps: YAxisProps = {
  axisLine: defaultAxisLineProps,
  tickLine: false,
  tick: yAxisTickDefaultProps,
  tickMargin: 12,
};

// Lines
export const lineProps: Omit<LineProps, 'dataKey'> = {
  strokeWidth: 3,
  type: 'monotone',
  stroke: '#1890FF',
};

// Legend
const legendWrapperStyles: React.CSSProperties = {
  paddingLeft: 110,
};

export const legendProps: LegendProps = {
  align: 'left',
  verticalAlign: 'top',
  height: 40,
  wrapperStyle: legendWrapperStyles,
  iconType: 'circle',
  iconSize: 11,
};
