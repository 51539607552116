import { createAction } from '@reduxjs/toolkit';
import { CompanyTouchesOptionsResponse } from '@optx/models/company/CompanyTouch';
import { types } from '../types';

// fetch touches filters
export const fetchTouchesFilters = createAction<number>(types.filters.fetchTouchesFilters.REQUEST);
export const fetchTouchesFiltersSuccess = createAction<CompanyTouchesOptionsResponse>(
  types.filters.fetchTouchesFilters.SUCCESS
);
export const fetchTouchesFiltersFail = createAction<string>(
  types.filters.fetchTouchesFilters.FAILURE
);
