import styled, { css } from 'styled-components';
import { Tag } from 'antd';

interface SingleTagProps {
  index: number;
  lastIndex: number;
}

// SingTag component is the same Tag but styled to look like one solid tag for combine search
// used to utilize existing functionality for hiding tags that doesn't fit on screen

const SingleTag = styled(Tag)<SingleTagProps>`
  margin: 0px !important;
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;

  ${({ index }) =>
    index === 0 &&
    css`
      border-left: 1px solid #d9d9d9;
    `}

  ${({ index }) =>
    index % 2 !== 0 &&
    css`
      padding: 0;
    `}

  ${({ index, lastIndex }) =>
    index === lastIndex &&
    css`
      border-right: 1px solid #d9d9d9;
      padding-right: 23px;
    `}
`;

const CloseTagIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: -20px;
  z-index: 100;
  margin-right: 15px;
  cursor: pointer;
`;

const ETTagMyCompanies = styled(Tag)`
  span {
    color: #4180d6;
  }
`;

export const Styled = {
  ETTagMyCompanies,
  SingleTag,
  CloseTagIcon,
};
