import React from 'react';
import { Menu } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import PrivateComponent, { PrivateComponentProps } from '../../PrivateComponent';

interface PrivateMenuItemProps extends MenuItemProps, PrivateComponentProps {}

const PrivateMenuItem = ({ accessFor, ...restProps }: PrivateMenuItemProps) => {
  return (
    <PrivateComponent accessFor={accessFor}>
      <Menu.Item {...restProps} />
    </PrivateComponent>
  );
};

export default PrivateMenuItem;
