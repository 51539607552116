import React, { useState, useEffect } from 'react';
import { Col, Radio, Row, Tag, Typography } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { useSelector } from 'react-redux';
// models
import { RationaleType } from '@features/add-research-rationale/state/interfaces';
// constants
import {
  PSG_FIT_RATIONALE_KEY,
  SOFTWARE_RATIONALE_KEY,
  OPTX_SCORE_UPPER_RATIONALE_KEY,
  OPTX_SCORE_LOWER_RATIONALE_KEY,
} from '@features/add-research-rationale/constants';
import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';
// redux
import { AppState } from '@optx/redux/interfaces';
import { getFormRationales } from '@optx/redux/company/filters/selectors';
// components
import OtherOptions from './OtherOptions';
import * as Styled from '../AddResearchRationale.styles';

interface OptionsRationaleProps {
  disabled?: boolean;
  fieldName: RationaleType;
  rationaleValue: string | null;
  handleRationaleChange: (e: RadioChangeEvent) => void;
  handleOtherOptionRationaleChange: (e: string) => void;
}

const getRationaleTitle = (fieldName: string) => {
  let rationaleTitle = '';

  if (fieldName === 'Software Company') {
    rationaleTitle = 'Please indicate why you believe this is not a software company';
  }

  if (fieldName === 'PSG Fit') {
    rationaleTitle = 'Please indicate why you believe this is not a PSG Fit company';
  }

  if (fieldName === 'Score Lower') {
    rationaleTitle = 'Why score should be lower?';
  }

  if (fieldName === 'Score Upper') {
    rationaleTitle = 'Why score should be higher?';
  }

  return rationaleTitle;
};

const OptionsRationale: React.FC<OptionsRationaleProps> = ({
  disabled,
  rationaleValue,
  handleRationaleChange,
  handleOtherOptionRationaleChange,
  fieldName,
}) => {
  const [showTagRationale, setShowTagRationale] = React.useState<boolean>();
  const [optionValue, setOptionValue] = useState<string | null>(rationaleValue);

  const rationaleOptions = useSelector((state: AppState) => {
    switch (fieldName) {
      case 'Software Company':
        return getFormRationales(state, SOFTWARE_RATIONALE_KEY) ?? [];
      case 'PSG Fit':
        return getFormRationales(state, PSG_FIT_RATIONALE_KEY) ?? [];

      case 'Score Lower':
        return getFormRationales(state, OPTX_SCORE_LOWER_RATIONALE_KEY) ?? [];

      case 'Score Upper':
        return getFormRationales(state, OPTX_SCORE_UPPER_RATIONALE_KEY) ?? [];

      default:
        return [];
    }
  });

  const isChromePlugin = window.location.pathname.startsWith('/chrome-extension');

  const showIsOther = () => {
    const values = rationaleOptions.map(option => option.value).slice(0, -1);

    if (rationaleValue === null) {
      return false;
    }

    return (
      !values.includes(rationaleValue) &&
      rationaleValue !== DEFAULT_CELL_VALUE &&
      rationaleValue?.length > 0
    );
  };

  // if a reason is set for other option, set the value to "other", so that the
  // option is in the active state
  useEffect(() => {
    if (
      rationaleValue &&
      rationaleValue !== DEFAULT_CELL_VALUE &&
      !rationaleOptions.some(option => option.value === rationaleValue)
    ) {
      setOptionValue('other');
    } else {
      setOptionValue(rationaleValue);
    }
  }, [rationaleOptions, rationaleValue]);

  return (
    <Styled.RationalesWrapper>
      <Row className="profile-information__subtitle">
        <Typography.Text>{getRationaleTitle(fieldName)}</Typography.Text>
      </Row>
      <Radio.Group
        buttonStyle="solid"
        className="rationale-radio-group"
        onChange={e => {
          setShowTagRationale(false);
          handleRationaleChange(e);
        }}
        value={optionValue}
      >
        {rationaleOptions?.map(option => (
          <Col key={option.value} style={{ textAlign: 'center', marginTop: 10, padding: 0 }}>
            <Radio.Button
              disabled={!!disabled}
              style={{ width: isChromePlugin ? '540px' : '450px' }}
              value={option.value}
            >
              {option.label}
            </Radio.Button>
          </Col>
        ))}
      </Radio.Group>
      {showIsOther() && (
        <div style={{ marginTop: '10px' }}>
          <Typography.Text>Please Explain</Typography.Text>
          <div style={{ width: `${isChromePlugin ? '542px' : '450px'}` }}>
            <OtherOptions
              disabled={!!disabled}
              onInputChange={e => {
                setShowTagRationale(true);
                handleOtherOptionRationaleChange(e);
              }}
              rationaleValue={rationaleValue}
              rationaleOptions={rationaleOptions}
            />
          </div>
          {showTagRationale && (
            <Tag color="blue" style={{ marginTop: '5px' }}>
              {rationaleValue}
            </Tag>
          )}
        </div>
      )}
    </Styled.RationalesWrapper>
  );
};

export default OptionsRationale;
