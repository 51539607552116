import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import searchReducer, { saga } from '@redux/company/search';

const useInjectHomePage = () => {
  useInjectReducer({
    key: 'homePage',
    reducer: searchReducer,
  });
  useInjectSaga({
    key: 'homePage',
    saga,
  });
};

export default useInjectHomePage;
