import React, { useEffect } from 'react';
import { message, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
// constants
import appRoutes from '@constants/routes';

const { Link } = Typography;

const ViewWrapper = styled.span`
  .ant-typography {
    color: #1890ff;
  }
`;

const SavedViewMessage: React.FC = ({ children }) => <ViewWrapper>{children}</ViewWrapper>;

const useSaveViewMessage = (isVisible: boolean, toggleModalFilters: VoidFunction) => {
  const history = useHistory();

  const handleClick = () => {
    message.destroy();
    history.push(appRoutes.lists.companySavedSearches);
    toggleModalFilters();
  };

  useEffect(() => {
    if (isVisible) {
      message.info({
        content: (
          <SavedViewMessage>
            A Custom Default Filter has been implemented. <Link onClick={handleClick}>Go Here</Link>{' '}
            to change it
          </SavedViewMessage>
        ),
        duration: 0,
        style: {
          marginTop: '140px',
        },
      });
    } else {
      message.destroy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
};

export default useSaveViewMessage;
