import { useInjectSaga, useInjectReducer } from 'redux-injectors';
import reducer, { sagas } from '../../../redux/company/opportunity-presentation';

const useInjectOpportunityPresentation = () => {
  useInjectReducer({
    key: 'opportunityPresentation',
    reducer,
  });
  useInjectSaga({
    key: 'opportunityPresentation',
    saga: sagas,
  });
};

export default useInjectOpportunityPresentation;
