import React from 'react';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { MenuProps } from 'antd/lib/menu';
//constants
import appRoutes from '@constants/routes';
// redux
import { selectors as fullscreenSelectors } from '@redux/ui/settings/fullscreen';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';
// components
import styled from 'styled-components';
import SaveSearchAsList from '../../SaveSearchAsListButton';
import { useLocation } from 'react-router';
import ClearAllSortingMenuItem from '@optx/features/company-sorting/ClearAllSortingMenuItem';
import ToggleSortTypeMenuItem from '@optx/features/company-sorting/ToggleSortTypeMenuItem';

interface SearchMenuProps extends MenuProps {
  hideDropdown: () => void;
  isAddonManagement?: boolean;
}
const StyledSearchMenu = styled(Menu)`
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%) !important;
  &:not(.ant-menu-horizontal) li.ant-menu-item {
    color: inherit;
    &-active,
    &-selected {
      background-color: transparent;
    }
    &:hover {
      color: inherit;
      background-color: #f5f5f5;
    }
  }
`;

const SearchMenu: React.FC<SearchMenuProps> = ({
  children,
  hideDropdown,
  isAddonManagement,
  ...rest
}) => {
  const isFullscreen = useSelector(fullscreenSelectors.isFullscreen);
  const { pathname } = useLocation();
  const { advancedSearchPathname } = useIsAnalyst();

  const handleMenuLists = () => {
    if (pathname.startsWith('/user-lists') || pathname.startsWith('/ss-lists')) {
      return [
        <ClearAllSortingMenuItem gridName="userLists" />,
        <ToggleSortTypeMenuItem gridName="userLists" />,
      ];
    }

    if (pathname === appRoutes.myCompanies) {
      return [
        <ClearAllSortingMenuItem gridName="myCompanies" />,
        <ToggleSortTypeMenuItem gridName="myCompanies" />,
      ];
    }

    if (pathname === advancedSearchPathname) {
      return [
        <ClearAllSortingMenuItem gridName="advancedSearch" />,
        <ToggleSortTypeMenuItem gridName="advancedSearch" />,
      ];
    }
  };

  return (
    <StyledSearchMenu className="search-menu">
      {!isAddonManagement && <SaveSearchAsList hideDropdown={hideDropdown} />}
      {React.cloneElement(children as React.ReactElement<any>, { hideDropdown })}
      {isFullscreen && handleMenuLists()}
    </StyledSearchMenu>
  );
};

export default SearchMenu;
