import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import reducer, { saga } from '@redux/lists';

const useInjectCompanyLists = () => {
  useInjectReducer({
    key: 'lists',
    reducer,
  });
  useInjectSaga({
    key: 'lists',
    saga,
  });
};

export default useInjectCompanyLists;
