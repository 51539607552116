import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

// selector
import { selectors as userSelectors } from '@redux/user/information';

// validate if analytics has started.
let hasGAInitialized = false;

// reusable hooks for analytics
const useAnalytics = () => {
  const getAnalyticsId = useSelector(userSelectors.getAnalyticsId);
  const userId = useSelector(userSelectors.getUserId);

  if (getAnalyticsId && userId && !hasGAInitialized) {
    ReactGA.initialize(getAnalyticsId, { gaOptions: { userId: userId } });

    hasGAInitialized = true;
  }

  return hasGAInitialized;
};

export default useAnalytics;
