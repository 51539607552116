import React from 'react';
import { ChartTicks } from '@optx/models/charts/data';

const ReversedTicks: React.FC<ChartTicks> = ({ x, height, payload: { value } }) => {
  return (
    <text x={x} y={value === 1 ? 10 : height + 20} dy={7} fill="#C2CFE0">
      {value}
    </text>
  );
};

export default ReversedTicks;
