import { createAction } from '@reduxjs/toolkit';
import {
  ColumnKeys,
  CompanyColumnDisplay,
  ColumnWidth,
  ColumnWidthUserSettings,
} from '@optx/models/table/Columns';
// redux
import * as types from './types';

export const saveColumns = createAction(
  types.SAVE_COLUMNS,
  (columnDisplay: CompanyColumnDisplay<ColumnKeys>, columnOrder: ColumnKeys[]) => ({
    payload: {
      columnDisplay,
      columnOrder,
    },
  })
);

export const updateColumns = createAction(
  types.UPDATE_COLUMNS,
  (columns: string | null, columnOrder: string | null, pinnedColumns: string | null) => ({
    payload: {
      columns,
      columnOrder,
      pinnedColumns,
    },
  })
);

export const saveColumnWidth = createAction<ColumnWidth>(types.SAVE_COLUMN_WIDTH);

export const resetColumnWidths = createAction<ColumnWidthUserSettings>(types.RESET_COLUMN_WIDTHS);
