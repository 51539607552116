import { Dictionary } from 'lodash';
import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { CompanyFilterType, FilterSource, PreselectedFilter } from '@models/filters';
import { FilterTag } from '@models/tags';
import { normalizeFiltersByColumn } from '@utils/filters/normalize';
import { injectFilter } from '@utils/filters/injectFilter';
import { mapPreselectedFilters } from '@utils/filters/preselectedValues';
import { parseFilter } from '@optx/utils/filters/parseFilters';
import { actions as userInformationActions } from '@redux/user/information';
import { UserInformation } from '@optx/models/user';
import { FilterMultiTextOption, SelectOption } from '@optx/models/Option';
import { FiltersState } from '../interfaces';
import { actions } from '../actions';

const initialState: FiltersState = {
  data: [],
  filter: {},
  values: {},
  clear: {},
  preselected: {},
  persistedFilter: '',
  normalized: {},
  fetchedAt: null,
};

const fetchSuccessReducer: CaseReducer<FiltersState, PayloadAction<Array<FilterSource>>> = (
  draftState,
  action
) => {
  const sourceData = action.payload[0].data.map(item =>
    item.column === 'date_added_et'
      ? { ...item, default_value: item.data[2], type: 'date_range_radio' as CompanyFilterType }
      : item
  );
  const sources = action.payload.flatMap(item => ({ ...item, data: sourceData }));
  const preselected = mapPreselectedFilters(sources, true, 2);
  draftState.preselected = preselected;
  const clearedFilter = mapPreselectedFilters(sources, false, 2);
  const normalized = normalizeFiltersByColumn(action.payload);
  // @ts-ignore for now
  draftState.normalized = normalized;

  const defaultAnalysts: PreselectedFilter = normalized.analyst_id
    ? normalized.analyst_id?.data
    : [];

  if (draftState.persistedFilter !== undefined) {
    const persistedFilter = draftState.persistedFilter;
    const [parsedFilters] = parseFilter(persistedFilter, action.payload);
    const parsedFiltersData = {
      ...parsedFilters,
      date_added_et: (parsedFilters.date_added_et as SelectOption[]).map(item => item.value),
      analyst_id: parsedFilters.analyst_id
        ? parsedFilters.analyst_id
        : parsedFilters.analyst_id === undefined && persistedFilter.includes('analyst_id')
        ? []
        : defaultAnalysts,
    };

    draftState.values = parsedFiltersData;
    draftState.filter = parsedFiltersData;
  }

  if (draftState.persistedFilter === '') {
    const preselectedData = {
      ...preselected,
      analyst_id:
        (preselected.analyst_id as FilterMultiTextOption[]).length === 0
          ? defaultAnalysts
          : preselected.analyst_id,
    };
    draftState.values = preselectedData;
    draftState.filter = preselectedData;
  }

  draftState.clear = { ...clearedFilter, analyst_id: defaultAnalysts };
  draftState.preselected = { ...preselected, analyst_id: draftState.filter.analyst_id };
  draftState.data = sources;
  draftState.fetchedAt = new Date().toISOString();
};

const clearFiltersReducer: CaseReducer<FiltersState> = draftState => {
  draftState.filter = draftState.clear;
  draftState.values = draftState.clear;
};

const resetToDefaultReducer: CaseReducer<FiltersState> = draftState => {
  draftState.filter = draftState.preselected;
  draftState.values = draftState.preselected;
};

const applyFiltersReducer: CaseReducer<
  FiltersState,
  PayloadAction<Dictionary<PreselectedFilter>>
> = (draftState, action) => {
  draftState.filter = action.payload;
  draftState.values = action.payload;
};

const resetFilterReducer: CaseReducer<FiltersState, PayloadAction<FilterTag>> = (
  draftState,
  action
) => {
  const tag = action.payload;
  const { normalized, clear, filter } = draftState;

  const newFilters = injectFilter(normalized, filter, clear, tag.filter, tag.optionId);

  draftState.filter = newFilters;
  draftState.values = newFilters;
};

const updateValueReducer: CaseReducer<
  FiltersState,
  PayloadAction<{ key: string; value: PreselectedFilter }>
> = (draftState, action) => {
  const { key, value } = action.payload;
  draftState.filter = { ...draftState.filter, [key]: value };
  draftState.values = { ...draftState.values, [key]: value };
};

// External reducers
// Update persisted filter to be used when filters are loaded.
const fetchUserInformationSuccess: CaseReducer<FiltersState, PayloadAction<UserInformation>> = (
  draftState,
  action
) => {
  const persistedFilter = action.payload.settings.session_settings
    ? action.payload.settings.session_settings.new_et_companies_filters
    : undefined;

  if (persistedFilter !== undefined) {
    draftState.persistedFilter = persistedFilter;
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.filters.fetchSuccess, fetchSuccessReducer)
    .addCase(actions.filters.clearFilters, clearFiltersReducer)
    .addCase(actions.filters.resetToDefault, resetToDefaultReducer)
    .addCase(actions.filters.applyFilters, applyFiltersReducer)
    .addCase(actions.filters.resetFilter, resetFilterReducer)
    .addCase(actions.filters.updateValue, updateValueReducer)
    // External reducers
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccess)
);

export default reducer;
