import React from 'react';
import { Tooltip as T } from 'antd';
import styled from 'styled-components';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';

const Tooltip = styled(T)`
  display: block;
`;

interface DefaultTooltipProps {
  value: any;
}

const DefaultTooltip: React.FC<DefaultTooltipProps> = ({ value }) => {
  if (!value || value === DEFAULT_CELL_VALUE) {
    return <>{DEFAULT_CELL_VALUE}</>;
  }

  return (
    <Tooltip title={value} className="text-truncate">
      {value}
    </Tooltip>
  );
};

export default DefaultTooltip;
