import { selectors as fetchSelectors } from '@optx/redux/feature/fetch';

import { AnalystsLeaderboardSearchState } from './interfaces';

export const isLoading = (state: AnalystsLeaderboardSearchState) =>
  fetchSelectors.isLoadingSelector(state);

export const getChartInfo = (state: AnalystsLeaderboardSearchState) =>
  state.data ? state.data.data : [];

export const getData = (state: AnalystsLeaderboardSearchState) => state.data;
