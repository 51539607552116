import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { sagas } from '@redux/favorite-lists/lists';
import sagaImport from '@optx/features/list-import/state/sagas';
import favoriteListsReducer from '@redux/favorite-lists';
import { sagas as sagasPopup } from '@redux/favorite-lists/lists-popup';

const useInjectFavoriteLists = () => {
  useInjectReducer({
    key: 'favoriteLists',
    reducer: favoriteListsReducer,
  });
  useInjectSaga({
    key: 'favoriteLists',
    saga: sagas,
  });
  useInjectSaga({
    key: 'favoriteListsPopup',
    saga: sagasPopup,
  });
  useInjectSaga({
    key: 'favoriteListsImport',
    saga: sagaImport,
  });
};

export default useInjectFavoriteLists;
