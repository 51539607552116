import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// local
import { fetchReducer, fetchFailReducer } from '../../feature/fetch/reducers';
import { ExtensionProfileResponse, ExtensionProfileState } from './interface';
import * as actions from './actions';

const initialState: ExtensionProfileState = {
  loading: false,
  error: '',
  profiles: [],
  fetchedAt: '',
};

const fetchExtensionProfileSuccessReducer: CaseReducer<
  ExtensionProfileState,
  PayloadAction<ExtensionProfileResponse[]>
> = (draftState, action) => {
  draftState.loading = false;
  draftState.fetchedAt = new Date().toISOString();

  draftState.profiles = action.payload;
};

const clearExtensionProfileReducer: CaseReducer<ExtensionProfileState> = draftState => {
  draftState.profiles = [];
  draftState.fetchedAt = '';
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.fetchExtensionCompanies, fetchReducer)
    .addCase(actions.fetchExtensionCompaniesSuccess, fetchExtensionProfileSuccessReducer)
    .addCase(actions.fetchExtensionCompaniesFail, fetchFailReducer)
    .addCase(actions.clearExtensionCompanies, clearExtensionProfileReducer)
);

export default reducer;
