import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { Dictionary } from 'lodash';
import queryString from 'query-string';
// models
import { Filter, CompanyFilterColumn, FilterSource } from '@models/filters';
import { CompanyFiltersMeta, SearchSaveResponse } from '@models/search';
import Option from '@models/Option';
import { UserInformation } from '@optx/models/user';
// constants
import { customUIViews } from '@optx/constants/table/columnDisplay/company-search';
// utils
import { mapFiltersOptions } from '@utils/filters/mapFiltersOptions';
import { normalizeFiltersByColumn } from '@utils/filters/normalize';
import { preselectedValues } from '@utils/filters/preselectedValues';
import { parseFilter } from '@utils/filters/parseFilters';
import { parseSorting } from '@utils/filters/parseSorting';
import { hasFilters } from '@utils/search';
// redux
import { actions as userInformationActions } from '@redux/user/information';
import { fetchReducer, fetchFailReducer } from '@redux/feature/fetch/reducers';
import * as actions from './actions';
import {
  CompanyFiltersState,
  NormalizedFilters,
  FormRationaleResponse,
  NextSteps,
  AlertSettingsResponse,
} from './interfaces';

const initialState: CompanyFiltersState = {
  data: [],
  error: '',
  loading: false,
  fetchedAt: null,
  filters: {},
  normalizedFilters: {
    location: {},
  },
  preselected: {},
  options: {},
  defaultViews: {
    data: [],
    filters: {},
    searchKey: '',
    sortBy: null,
    columns: null,
    columnOrder: null,
    pinnedColumns: null,
  },
  shouldNotFetch: false,
  formRationale: {},
  nextSteps: [],
  alertSettings: {},
  searchOrigin: [],
};

const updateDefaultView = (draftState: CompanyFiltersState, payload: SearchSaveResponse) => {
  const {
    search_criteria: searchCriteria,
    columns,
    column_order: columnOrder,
    pinned_columns: pinnedColumns,
  } = payload;

  draftState.defaultViews.data = [payload];
  draftState.defaultViews.columns = null;
  draftState.defaultViews.columnOrder = null;
  draftState.defaultViews.pinnedColumns = null;
  draftState.defaultViews.searchKey = '';

  if (hasFilters(searchCriteria)) {
    const [parsedFilters] = parseFilter(searchCriteria, draftState.data);
    draftState.defaultViews.filters = parsedFilters;
  } else {
    const [parsedFilters] = parseFilter('', draftState.data);
    draftState.defaultViews.filters = parsedFilters;
  }

  if (/^.*query.*$/.test(searchCriteria)) {
    const data = queryString.parse(searchCriteria);
    draftState.defaultViews.searchKey = data.query as string;
  }

  const { sortBy } = parseSorting(searchCriteria);
  draftState.defaultViews.sortBy = sortBy.length ? sortBy : null;

  if (columns !== null) {
    draftState.defaultViews.columns = columns;

    if (columnOrder) {
      draftState.defaultViews.columnOrder = columnOrder;
    }
  }

  if (pinnedColumns !== null) {
    draftState.defaultViews.pinnedColumns = pinnedColumns;
  }
};

/**
 * Normalize specific filters needed in other places.
 * @param filters
 */
function getNormalizedFilters(filters: Dictionary<Filter>) {
  const normalizedFilters: NormalizedFilters = { location: {} };

  // handle locations
  if (filters.location) {
    const locationData = filters['location' as CompanyFilterColumn].data as Array<Option<string>>;
    normalizedFilters.location = locationData.reduce<Dictionary<string>>((acumulator, option) => {
      const newAcumulator = acumulator;
      newAcumulator[option.value] = option.name!;

      return newAcumulator;
    }, {});
  }

  return normalizedFilters;
}

const fetchCompanyFiltersSuccessReducer: CaseReducer<
  CompanyFiltersState,
  PayloadAction<Array<FilterSource>, any, CompanyFiltersMeta>
> = (draftState, action) => {
  draftState.loading = false;
  const normalizedByColumn = normalizeFiltersByColumn(action.payload);
  // @ts-ignore for now
  draftState.filters = normalizedByColumn;
  draftState.normalizedFilters = getNormalizedFilters(normalizedByColumn);
  draftState.fetchedAt = new Date().toISOString();
  draftState.preselected = preselectedValues(action.payload);
  draftState.data = action.payload;
  draftState.searchOrigin = action.meta.searchOrigin;
  // @ts-ignore weird issue. Same type from method and model and still showing error :(.
  draftState.options = mapFiltersOptions(action.payload);

  if (action.meta && action.meta.defaultViews.length) {
    const {
      search_criteria: searchCriteria,
      columns,
      column_order: columnOrder,
      pinned_columns: pinnedColumns,
    } = action.meta.defaultViews[0];
    const [parsedFilters] = parseFilter(searchCriteria, action.payload);
    const { sortBy } = parseSorting(searchCriteria);
    draftState.defaultViews.data = action.meta.defaultViews;
    draftState.defaultViews.filters = parsedFilters;
    draftState.defaultViews.sortBy = sortBy.length ? sortBy : null;

    // related to views
    // we need to update column related settings, if the values don't come back as null
    if (columns !== null) {
      draftState.defaultViews.columns = columns;

      if (columnOrder) {
        draftState.defaultViews.columnOrder = columnOrder;
      }
    }

    if (pinnedColumns !== null) {
      draftState.defaultViews.pinnedColumns = pinnedColumns;
    }

    if (/^.*query.*$/.test(searchCriteria)) {
      const data = queryString.parse(searchCriteria);

      draftState.defaultViews.searchKey = data.query as string;
    }
  }
};

const changeDefaultViewReducer: CaseReducer<
  CompanyFiltersState,
  PayloadAction<SearchSaveResponse | undefined>
> = (draftState, action) => {
  if (action.payload) {
    // weird typescript issue. "CompanyFiltersState" type is set for draftState in this
    // reducer function and on "updateDefaultView" where it is being passed, yet
    // typescript is throwing an error anyway
    // @ts-ignore
    updateDefaultView(draftState, action.payload);
  } else {
    draftState.defaultViews = initialState.defaultViews;
  }
};

const stopFetchingFiltersReducer: CaseReducer<CompanyFiltersState, PayloadAction<boolean>> = (
  draftState,
  action
) => {
  draftState.shouldNotFetch = action.payload;
};

const fetchUserInformationSuccessReducer: CaseReducer<
  CompanyFiltersState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const customUIViewId = action.payload.settings?.default_view;

  if (customUIViewId) {
    // @ts-ignore
    updateDefaultView(draftState, customUIViews[customUIViewId]);
  }
};

const setFormRationalesReducer: CaseReducer<
  CompanyFiltersState,
  PayloadAction<FormRationaleResponse>
> = (draftState, action) => {
  draftState.formRationale = action.payload;
};

const setNextStepsReducer: CaseReducer<CompanyFiltersState, PayloadAction<NextSteps[]>> = (
  draftState,
  action
) => {
  draftState.nextSteps = action.payload;
};

const setAlertSettingsReducer: CaseReducer<
  CompanyFiltersState,
  PayloadAction<AlertSettingsResponse>
> = (draftState, action) => {
  draftState.alertSettings = action.payload;
};

const reducer = createReducer<CompanyFiltersState>(initialState, builder =>
  builder
    .addCase(actions.fetchCompanyFilters, fetchReducer)
    .addCase(actions.fetchCompanyFiltersSuccess, fetchCompanyFiltersSuccessReducer)
    .addCase(actions.fetchCompanyFiltersFail, fetchFailReducer)
    .addCase(actions.stopFetchingFilters, stopFetchingFiltersReducer)
    .addCase(actions.changeDefaultView, changeDefaultViewReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
    .addCase(actions.setFormRationales, setFormRationalesReducer)
    .addCase(actions.setAlertSettings, setAlertSettingsReducer)
    .addCase(actions.setNextSteps, setNextStepsReducer)
);

export default reducer;
