import React from 'react';
import { Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
// constants
import { cardRowGutter } from '@optx/constants/spacing';
// redux
import { actions } from '@features/pipeline-report/state';
// hooks
import {
  useInject as useInjectCompanyIndividualEdit,
  useInitialFetchEditOptions,
} from '@optx/components/feature/company-individual-edit';
import { useInjectEditFields } from '@optx/features/company/edit-fields';
import { useInjectCompanyReview } from '@optx/features/company-review';
import { useInject } from '@features/pipeline-report/useInject';
// components
import {
  Header,
  OpportunitiesBarChart,
  FundAssociationChart,
  CompaniesGrid,
  FiltersModal,
} from '@features/pipeline-report/components';
import ReportBreadcrumb from '../AllReports/components/ReportBreadcrumb';
import PageLoader from './PageLoader';
// styled
import Styled from './PipelineReport.styled';

const PipelineReport = () => {
  useInject();
  useInjectCompanyIndividualEdit();
  useInjectEditFields();
  useInitialFetchEditOptions();
  useInjectCompanyReview();

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(actions.fetchFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageLoader />
      <Header />
      <Styled.Wrapper className="pipeline-report">
        <Styled.MainContent className="main-content">
          <Styled.PageFiltersWrapper>
            <ReportBreadcrumb reportPage="Pipeline Report" />
            <Styled.PageTitle>Pipeline Report</Styled.PageTitle>
            <Styled.QuickFiltersContent>
              <Styled.QuickFilterWrapper>
                <Styled.ByAnalystsOrTeamMultiSelect filterKey="company_owner_id" allowSelectAll />
              </Styled.QuickFilterWrapper>
              <Styled.QuickFilterWrapper>
                <Styled.SingleSelectFilter />
              </Styled.QuickFilterWrapper>
            </Styled.QuickFiltersContent>
          </Styled.PageFiltersWrapper>
          <Row gutter={cardRowGutter} style={{ marginTop: '-100px' }}>
            <Col span={14} xs={24} xl={12}>
              <OpportunitiesBarChart />
            </Col>
            <Col span={10} xs={24} xl={12}>
              <FundAssociationChart />
            </Col>
          </Row>
          <Row gutter={cardRowGutter} style={{ paddingTop: '42px', paddingBottom: '21px' }}>
            <Col span={24}>
              <CompaniesGrid />
            </Col>
          </Row>
        </Styled.MainContent>
      </Styled.Wrapper>
      <FiltersModal />
    </>
  );
};

export default PipelineReport;
