import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import queryString from 'query-string';
// models
import { APIBoolean } from '@optx/models/api/generic';
import {
  AddCompanyReviewPayload,
  AddCompanyReviewResponse,
  AddCompanyReviewSuccess,
} from './interfaces';
import { ChangedCompanyFieldsPayload } from '@optx/features/company/audit-trail/state/interfaces';
// services
import { UserService } from '@optx/services/api';
import NotificationService from '@optx/services/NotificationService';
import { CompanyProfileTabs } from '@optx/constants/routes';
// utils
import { getErrorMessage } from '@optx/utils/api/errors';
// redux
import { selectors as userSelectors } from '@redux/user/information';
import { actions as customGlobalLoaderActions } from '@features/custom-global-loader';
import {
  actions as auditActions,
  selectors as auditSelectors,
} from '@optx/features/company/audit-trail/state';
import * as actions from './actions';

export function* addCompanyReviewSaga(action: PayloadAction<AddCompanyReviewPayload>) {
  yield put(customGlobalLoaderActions.toggle({ loading: true, customText: '' }));

  const { tab } = queryString.parse(window.location.search);

  const userId: number = yield select(userSelectors.getUserId);
  const { companyId } = action.payload;

  const payload = {
    companyId,
    userId,
  };

  try {
    const res: AxiosResponse<AddCompanyReviewResponse> = yield call(
      UserService.reviewCompany,
      payload
    );

    if (res.data) {
      const payload: AddCompanyReviewSuccess = {
        companyId,
        reviewed: res.data.reviewed,
        reviewedBy: res.data.reviewed_by,
        reviewedDate: res.data.reviewed_date,
      };
      yield put(actions.addCompanyReviewSuccess(payload));

      NotificationService.success('Company reviewed successfully!');

      if (tab === CompanyProfileTabs.HISTORY) {
        const field: string = yield select(auditSelectors.getSearchQuery);
        const optx: APIBoolean = yield select(auditSelectors.getOPTX);
        const internal: APIBoolean = yield select(auditSelectors.getInternal);
        const startDate: string = yield select(auditSelectors.getStartDate);
        const endDate: string = yield select(auditSelectors.getEndDate);
        const fetchPayload: ChangedCompanyFieldsPayload = {
          companyId,
          pagination: { pageNumber: 1, pageSize: 25 },
          field,
          internal,
          optx,
          startDate,
          endDate,
        };

        yield put(auditActions.fetchChangedCompanyFields(fetchPayload));
      }
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Failed to mark company as reviewed');
    yield put(actions.addCompanyReviewFail(errorMessage));
    NotificationService.error(errorMessage);
  } finally {
    yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
  }
}

export default function* inAddCompanyReviewSaga() {
  yield takeLatest(actions.addCompanyReview, addCompanyReviewSaga);
}
