import { AppState } from '@optx/redux/interfaces';
import { createSelector } from 'reselect';

const getModalState = (state: AppState) => state.ui.modals.companyNotes;

export const isOpen = createSelector(getModalState, state => state.open);

export const getCompanyInfo = createSelector(getModalState, state => {
  const info = {
    company_id: state.companyId,
    company_name: state.companyName,
  };

  return info;
});
