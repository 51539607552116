import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { Dictionary, pickBy } from 'lodash';
// models
import {
  CompanyWatchList,
  AddCompanyToWatchList,
  DeleteCompanyFromWatchList,
  UpdateListPermission,
  UpdateListAssociation,
  PublicWatchListActionPayload,
} from '@models/WatchList';
import { PageInfo } from '@models/table/Pagination';
import { UserInformation } from '@optx/models/user';
import { SortByRule } from '@models/table/sorting';
import {
  BulkAddToWatchList,
  BulkRemoveFromWatchList,
} from '@optx/features/bulk-actions/bulk-watchlist/state/interfaces';
// utils
import { normalizeData } from '@utils/utils';
import { parseSorting } from '@optx/utils/filters/parseSorting';
// constants
import { FAVORITE_LISTS_DEFAULT_SORT } from '@constants/table/sort/defaultSort';
// reducer
import { actions as pipelineReportActions } from '@features/pipeline-report/state';
import * as bulkWatchlistsActions from '@features/bulk-actions/bulk-watchlist/state/actions';
import { fetchReducer, fetchFailReducer } from '@redux/feature/fetch/reducers';
import { actions as userInformationActions } from '@redux/user/information';
import * as actions from './actions';
import { FavoriteListsState } from './interfaces';

export const initialState: FavoriteListsState = {
  byId: {},
  allIds: [],
  allWatchlists: {},
  error: '',
  loading: false,
  fetchedAt: '',
  total: 0,
  query: '',
  pageNumber: 1,
  title: '',
  completed: false,
  percentage: 0,
  cancel: false,
  sortBy: FAVORITE_LISTS_DEFAULT_SORT,
  showEmptyLists: null,
  loadingPublic: false,
  errorPublic: '',
};

const initialFetchFavoriteListsSuccessReducer: CaseReducer<
  FavoriteListsState,
  PayloadAction<{
    data: Array<CompanyWatchList>;
    total: number;
    sortBy: Array<SortByRule<any>>;
    showEmptyLists: boolean;
  }>
> = (draftState, action) => {
  const { data, showEmptyLists } = action.payload;

  const watchlists: CompanyWatchList[] = [];
  const allWatchlists: CompanyWatchList[] = data;

  const watchListsById = {} as Dictionary<CompanyWatchList>;
  const allWatchlistsById = {} as Dictionary<CompanyWatchList>;

  watchlists.forEach((listItem: CompanyWatchList) => {
    watchListsById[listItem.unique_id] = listItem;
  });
  allWatchlists.forEach((listItem: CompanyWatchList) => {
    allWatchlistsById[listItem.unique_id] = listItem;
  });

  const filteredAllWatchlists = data
    .filter(item => item.share_permission !== 1)
    .reduce(normalizeData('unique_id'), {}) as Dictionary<CompanyWatchList>;

  draftState.allWatchlists = filteredAllWatchlists;

  draftState.byId = allWatchlistsById;

  draftState.allIds = watchlists.map(listItem => listItem.unique_id);
  draftState.total = action.payload.total;
  draftState.loading = false;
  draftState.sortBy = action.payload.sortBy;
  draftState.showEmptyLists = showEmptyLists;

  if (!draftState.fetchedAt) {
    draftState.fetchedAt = new Date().toISOString();
  }
};

// fetch favorite lists success reducer
const fetchFavoriteListsSuccessReducer: CaseReducer<
  FavoriteListsState,
  PayloadAction<{
    data: Array<CompanyWatchList>;
    query: string;
    total: number;
    pagination: PageInfo;
    fetchAll?: boolean;
    sortBy: Array<SortByRule<any>>;
    showEmptyLists: boolean;
  }>
> = (draftState, action) => {
  const { data, fetchAll, pagination, showEmptyLists } = action.payload;

  let watchlists: CompanyWatchList[] = [];
  const allWatchlists: CompanyWatchList[] = data;

  if (fetchAll) {
    watchlists = data.slice(0, pagination.pageSize);
  } else {
    watchlists = data;
  }

  const watchListsById = {} as Dictionary<CompanyWatchList>;
  const allWatchlistsById = {} as Dictionary<CompanyWatchList>;

  watchlists.forEach((listItem: CompanyWatchList) => {
    watchListsById[listItem.unique_id] = listItem;
  });
  allWatchlists.forEach((listItem: CompanyWatchList) => {
    allWatchlistsById[listItem.unique_id] = listItem;
  });

  draftState.byId = allWatchlistsById;

  draftState.allIds = watchlists.map(listItem => listItem.unique_id);
  draftState.query = action.payload.query || '';
  draftState.total = action.payload.total;
  draftState.pageNumber = action.payload.pagination.pageNumber;
  draftState.loading = false;
  draftState.sortBy = action.payload.sortBy;
  draftState.showEmptyLists = showEmptyLists;

  if (!draftState.fetchedAt) {
    draftState.fetchedAt = new Date().toISOString();
  }
};

export const fetchUserInformationSuccessReducer: CaseReducer<
  FavoriteListsState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const sorting = action.payload.settings.session_settings?.favorites_list_sorting;
  const query = action.payload.settings.session_settings?.my_watchlists_filters;
  const pageNumber = action.payload.settings.session_settings?.favorites_list_page_number;

  if (sorting) {
    const { sortBy } = parseSorting(sorting);
    draftState.sortBy = sortBy;
  }

  if (query) {
    draftState.query = query;
  }

  if (pageNumber) {
    draftState.pageNumber = pageNumber;
  }
};

// delete list success
const deleteFavoriteListSuccessReducer: CaseReducer<
  FavoriteListsState,
  PayloadAction<number | string>
> = (draftState, action) => {
  const listId = action.payload;
  draftState.loading = false;
  draftState.byId = pickBy(draftState.byId, (value, key) => key !== listId.toString());
  draftState.allWatchlists = pickBy(
    draftState.allWatchlists,
    (value, key) => key !== listId.toString()
  );
  const index = draftState.allIds.findIndex(id => id === listId);

  if (index !== -1) {
    draftState.allIds.splice(index, 1);
  }

  draftState.total -= 1;
};

// create list success
const createListSuccessReducer: CaseReducer<
  FavoriteListsState,
  PayloadAction<{
    companyId: number;
    list: CompanyWatchList;
  }>
> = (draftState, action) => {
  const { list } = action.payload;

  draftState.loading = false;
  draftState.allWatchlists = { ...draftState.allWatchlists, [list.unique_id]: list };
};

// delete list success
const renameFavoriteListSuccessReducer: CaseReducer<
  FavoriteListsState,
  PayloadAction<{ list_id: number | string; title: string }>
> = (draftState, action) => {
  draftState.loading = false;
  draftState.byId[action.payload.list_id].title = action.payload.title;

  if (draftState.allWatchlists[action.payload.list_id]) {
    draftState.allWatchlists[action.payload.list_id].title = action.payload.title;
  }
};

// remove list success
const removeSharedListSuccessReducer: CaseReducer<
  FavoriteListsState,
  PayloadAction<number | string>
> = (draftState, action) => {
  const listId = action.payload;
  draftState.loading = false;
  draftState.byId = pickBy(draftState.byId, (value, key) => key !== listId.toString());
  draftState.allWatchlists = pickBy(
    draftState.allWatchlists,
    (value, key) => key !== listId.toString()
  );
  const index = draftState.allIds.findIndex(id => id === listId);

  if (index !== -1) {
    draftState.allIds.splice(index, 1);
  }

  draftState.total -= 1;
};

const updateFavoriteListCountReducer: CaseReducer<
  FavoriteListsState,
  PayloadAction<{ list_id: number | string; count: number }>
> = (draftState, action) => {
  const { list_id: listId, count } = action.payload;
  draftState.loading = false;

  if (draftState.byId[listId]) {
    draftState.byId[listId].count = count!;
  }
};

// add company to list success
const addCompanyToListSuccessReducer: CaseReducer<
  FavoriteListsState,
  PayloadAction<AddCompanyToWatchList>
> = (draftState, action) => {
  const { watchLists } = action.payload;

  draftState.loading = false;
  watchLists.forEach(list => {
    draftState.allWatchlists[list.unique_id].count =
      Number(draftState.allWatchlists[list.unique_id].count) + watchLists.length;
    draftState.byId[list.unique_id].count =
      Number(draftState.byId[list.unique_id].count) + watchLists.length;
  });
};

// delete company from list success
const deleteCompanyFromListSuccessReducer: CaseReducer<
  FavoriteListsState,
  PayloadAction<DeleteCompanyFromWatchList>
> = (draftState, action) => {
  draftState.loading = false;
  draftState.byId[action.payload.listId].count--;
  draftState.allWatchlists[action.payload.listId].count--;
};

// save search as list success
const saveSearchAsListSuccessReducer: CaseReducer<
  FavoriteListsState,
  PayloadAction<Dictionary<CompanyWatchList>>
> = (draftState, action) => {
  for (const uniqueId in action.payload) {
    if (Object.prototype.hasOwnProperty.call(action.payload, uniqueId)) {
      const newList: CompanyWatchList = { ...action.payload[uniqueId], unique_id: uniqueId };

      draftState.byId[uniqueId] = newList;
      draftState.allIds.unshift(Number(uniqueId));
      draftState.allWatchlists[uniqueId] = action.payload[uniqueId];
    }
  }
};

const updateFavoriteListPermissionReducer: CaseReducer<
  FavoriteListsState,
  PayloadAction<UpdateListPermission>
> = (draftState, action) => {
  const listId = action.payload.listId;
  draftState.byId[listId].share_permission = action.payload.userPermission;
  draftState.loading = false;
};

const updateFavoriteListAssociationSuccessReducer: CaseReducer<
  FavoriteListsState,
  PayloadAction<UpdateListAssociation>
> = (draftState, action) => {
  const { listId, addonCompany, addonCompanyId } = action.payload;

  draftState.loading = false;

  if (draftState.byId[listId]) {
    draftState.byId[listId].addon_company = addonCompany;
    draftState.byId[listId].addon_company_id = addonCompanyId;
  }

  if (draftState.allWatchlists[listId]) {
    draftState.allWatchlists[listId].addon_company = addonCompany;
    draftState.allWatchlists[listId].addon_company_id = addonCompanyId;
  }
};

const resetFetchedAtReducer: CaseReducer<FavoriteListsState> = draftState => {
  draftState.fetchedAt = '';
};

const fetchFavoriteListPagination: CaseReducer<FavoriteListsState> = draftState => {
  draftState.loading = true;
};

const updateListCountReducer: CaseReducer<FavoriteListsState, PayloadAction<number>> = (
  draftState,
  { payload }
) => {
  if (draftState.allWatchlists[payload]) {
    draftState.allWatchlists[payload].count++;
  }

  if (draftState.byId[payload]) {
    draftState.byId[payload].count++;
  }
};

const updateCompletedReducer: CaseReducer<FavoriteListsState, PayloadAction<boolean>> = (
  draftState,
  { payload }
) => {
  draftState.completed = payload;
  draftState.cancel = false;
};

const updatePercentageReducer: CaseReducer<FavoriteListsState, PayloadAction<number>> = (
  draftState,
  { payload }
) => {
  draftState.percentage = payload;
};

const saveTitleReducer: CaseReducer<FavoriteListsState, PayloadAction<string>> = (
  draftState,
  { payload }
) => {
  draftState.title = payload;
};

const cancelSaveSearchAsListReducer: CaseReducer<FavoriteListsState> = draftState => {
  draftState.cancel = true;
};

const resetSaveSearchAsListReducer: CaseReducer<FavoriteListsState> = draftState => {
  draftState.cancel = false;
  draftState.percentage = 0;
  draftState.completed = false;
};

const bulkRemoveFromWatchListReducer: CaseReducer<
  FavoriteListsState,
  PayloadAction<BulkRemoveFromWatchList>
> = (draftState, action) => {
  const count = action.payload.selectedCompanies.length;
  const { listId } = action.payload;

  if (draftState.byId[listId] && typeof draftState.byId[listId].count === 'number') {
    draftState.byId[listId].count -= count;
  }

  if (
    draftState.allWatchlists[listId] &&
    typeof draftState.allWatchlists[listId].count === 'number'
  ) {
    draftState.allWatchlists[listId].count -= count;
  }
};

const bulkAddToWatchListReducer: CaseReducer<
  FavoriteListsState,
  PayloadAction<BulkAddToWatchList>
> = (draftState, action) => {
  const count = action.payload.selectedCompanies.length;
  const { selectedData } = action.payload;

  selectedData.forEach(list => {
    if (draftState.byId[list.value]) {
      draftState.byId[list.value].count = Number(draftState.byId[list.value].count) + Number(count);
    }

    if (draftState.allWatchlists[list.value]) {
      draftState.allWatchlists[list.value].count =
        Number(draftState.allWatchlists[list.value].count) + Number(count);
    }
  });
};

const publicWatchListReducer: CaseReducer<FavoriteListsState> = draftState => {
  draftState.loadingPublic = true;
  draftState.errorPublic = '';
};

const publicWatchListSuccessReducer: CaseReducer<
  FavoriteListsState,
  PayloadAction<PublicWatchListActionPayload>
> = (draftState, action) => {
  const { list_id: listId, is_public: isPublic, origin } = action.payload;

  if (draftState.byId[listId as number]) {
    draftState.byId[listId as number] = {
      ...draftState.byId[listId as number],
      is_public: isPublic,
      origin: origin,
    };
  }

  if (draftState.allWatchlists[listId as number]) {
    draftState.allWatchlists[listId as number] = {
      ...draftState.allWatchlists[listId as number],
      is_public: isPublic,
      origin: origin,
    };
  }

  draftState.loadingPublic = false;
};

const publicWatchListFailReducer: CaseReducer<FavoriteListsState, PayloadAction<string>> = (
  draftState,
  action
) => {
  draftState.loadingPublic = false;
  draftState.errorPublic = action.payload;
};

const reducer = createReducer(initialState, builder =>
  builder
    // initial fetch favorites lists
    .addCase(actions.initialFetchFavoritesLists, fetchReducer)
    .addCase(actions.initialFetchFavoritesListsSuccess, initialFetchFavoriteListsSuccessReducer)
    // fetch favorites lists
    .addCase(actions.fetchFavoriteLists, fetchReducer)
    .addCase(actions.fetchFavoriteListsSuccess, fetchFavoriteListsSuccessReducer)
    .addCase(actions.fetchFavoriteListsFail, fetchFailReducer)
    // sort favorites lists
    .addCase(actions.sortFavoriteLists, fetchReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
    // pagination
    .addCase(actions.favoriteListsPagination, fetchFavoriteListPagination)
    // create favorite list
    .addCase(actions.createFavoriteList, fetchReducer)
    .addCase(actions.createFavoriteListSuccess, createListSuccessReducer)
    .addCase(actions.createFavoriteListFail, fetchFailReducer)
    // delete favorite list
    .addCase(actions.deleteFavoriteList, fetchReducer)
    .addCase(actions.deleteFavoriteListSuccess, deleteFavoriteListSuccessReducer)
    .addCase(actions.deleteFavoriteListFail, fetchFailReducer)
    // remove favorite list
    .addCase(actions.removeSharedList, fetchReducer)
    .addCase(actions.removeSharedListSuccess, removeSharedListSuccessReducer)
    .addCase(actions.removeSharedListFail, fetchFailReducer)
    // rename favorite list
    .addCase(actions.renameFavoriteList, fetchReducer)
    .addCase(actions.renameFavoriteListSuccess, renameFavoriteListSuccessReducer)
    .addCase(actions.renameFavoriteListFail, fetchFailReducer)
    // update favorite list count after bulk remove
    .addCase(actions.updateFavoriteListCount, updateFavoriteListCountReducer)
    // add company to list
    .addCase(actions.addCompanyToList, fetchReducer)
    .addCase(actions.addCompanyToListSuccess, addCompanyToListSuccessReducer)
    .addCase(actions.addCompanyToListFail, fetchFailReducer)
    // delete company in list
    .addCase(actions.deleteCompanyFromList, fetchReducer)
    .addCase(actions.deleteCompanyFromListSuccess, deleteCompanyFromListSuccessReducer)
    .addCase(actions.deleteCompanyFromListFail, fetchFailReducer)
    // save search as list
    .addCase(actions.saveSearchAsListSuccess, saveSearchAsListSuccessReducer)
    .addCase(pipelineReportActions.saveSearchAsListSuccess, saveSearchAsListSuccessReducer)
    .addCase(actions.updateCompleted, updateCompletedReducer)
    .addCase(actions.updatePercentage, updatePercentageReducer)
    .addCase(actions.cancelSaveSearchAsList, cancelSaveSearchAsListReducer)
    .addCase(actions.resetSaveSearchAsList, resetSaveSearchAsListReducer)
    .addCase(actions.saveTitle, saveTitleReducer)
    .addCase(actions.saveSearchAsListFail, fetchFailReducer)
    // update list
    .addCase(actions.updateFavoriteListPermission, updateFavoriteListPermissionReducer)
    // add list association
    .addCase(actions.updateFavoriteListAssociation, fetchReducer)
    .addCase(
      actions.updateFavoriteListAssociationSuccess,
      updateFavoriteListAssociationSuccessReducer
    )
    .addCase(actions.updateFavoriteListAssociationFail, fetchFailReducer)
    // reset fetchedAt
    .addCase(actions.resetFetchedAt, resetFetchedAtReducer)
    // edit imported list
    .addCase(actions.updateListCount, updateListCountReducer)
    // bulk remove
    .addCase(bulkWatchlistsActions.bulkRemoveFromWatchList, bulkRemoveFromWatchListReducer)
    .addCase(bulkWatchlistsActions.bulkAddToWatchList, bulkAddToWatchListReducer)
    // public list
    .addCase(actions.publicWatchList, publicWatchListReducer)
    .addCase(actions.publicWatchListSuccess, publicWatchListSuccessReducer)
    .addCase(actions.publicWatchListFail, publicWatchListFailReducer)
);

export default reducer;
