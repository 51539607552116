import React, { useMemo } from 'react';
import { Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// models
import { FilterTag } from '@optx/models/tags';
// redux
import { actions, selectors } from '@optx/features/pipeline-report/state';
// components
import { MainHeader } from '@optx/core/layout';
import { lib } from '@features/company-search';
import ClearSearchButton from '@shared/view/molecules/Buttons/ClearSearchButton';
import FilterTags from './FilterTags';
import PipelineReportSearchDropdown from './PipelineReportSearchDropdown';

const { HeaderContent } = lib;

const Header = () => {
  const dispatch = useDispatch();
  const isClearedFilter = useSelector(selectors.isClearedFilter);

  const options = useSelector(selectors.getTags);

  const onClear = () => {
    dispatch(actions.clearFilters());
  };

  const filteredOptions = useMemo(() => options.filter(item => !item.isDefault), [options]);

  return (
    <MainHeader className="main-header fixed">
      <HeaderContent>
        <Space size={4}>
          <div style={{ width: 700 }}>
            <FilterTags options={filteredOptions as FilterTag[]} />
          </div>
          <ClearSearchButton isVisible={isClearedFilter} title="Clear All" onClick={onClear} />
          <PipelineReportSearchDropdown />
        </Space>
      </HeaderContent>
    </MainHeader>
  );
};

export default Header;
