/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dictionary, uniqBy } from 'lodash';
import moment from 'moment';
// models
import { EquityTouchFetchedField } from '@optx/models/equityTouch';
// constants
import globalConfig from '@optx/constants/config';
import { AUTOSAVE_TIMER_DURATION } from '@constants/equitytouch';
// redux
import { updateTemporaryFormValues } from '@redux/company/equity-touch/actions';
import { getTemporaryFormValues } from '@optx/redux/company/equity-touch/selectors';
import { actions as userActions } from '@redux/user/information';
import { SelectOption } from '../../models/Option';

interface AutoSaveType {
  valuesSelector?: any;
  formDataSelector?: any;
}

const AutoSave: React.FC<AutoSaveType> = ({ valuesSelector, formDataSelector }) => {
  const formik = useFormikContext();
  const dispatch = useDispatch();
  const getValues = useSelector(valuesSelector);
  const temporaryFormValues = useSelector(getTemporaryFormValues);
  const initialFormData: Dictionary<EquityTouchFetchedField<any>> = useSelector(formDataSelector);
  const { action: routerAction } = useHistory();

  const save = useCallback(
    (
      newValues: Dictionary<string | number | boolean | number[] | string[] | null | SelectOption>,
      formData: Dictionary<EquityTouchFetchedField<any>>,
      extraDataValue?: any
    ) => {
      dispatch(
        updateTemporaryFormValues({
          ...newValues,
          contacts: uniqBy(newValues.contacts as number[], (contact: number) => contact),
          ...(temporaryFormValues &&
            'salesloft_contacts' in temporaryFormValues && {
              salesloft_contacts: temporaryFormValues?.salesloft_contacts,
            }),
        })
      );

      dispatch(
        userActions.updateUserSettings({
          et_form_auto_save: {
            values: {
              ...newValues,
              ...(temporaryFormValues &&
                'salesloft_contacts' in temporaryFormValues && {
                  salesloft_contacts: temporaryFormValues?.salesloft_contacts,
                }),
              contacts:
                'contacts' in newValues &&
                uniqBy(newValues.contacts as number[], (contact: number) => contact),
            },
            formData,
            date: moment().format(globalConfig.short_date.DATE_FORMAT),
            ...extraDataValue,
          },
        } as Partial<any>)
      );
    },
    [dispatch, temporaryFormValues]
  );

  useEffect(() => {
    if (Object.keys((getValues as any) || {}).length !== 0) {
      if (routerAction === 'POP') {
        formik.setValues({
          ...(formik?.values as Dictionary<
            string | number | boolean | number[] | string[] | null | SelectOption
          >),
          ...(getValues as Dictionary<
            string | number | boolean | number[] | string[] | null | SelectOption
          >),
        });
      }

      formik?.validateForm({
        ...(formik?.values as Dictionary<
          string | number | boolean | number[] | string[] | null | SelectOption
        >),
        ...(getValues as Dictionary<
          string | number | boolean | number[] | string[] | null | SelectOption
        >),
      });
    }
  }, [getValues, routerAction]);

  const handleAutosave = (
    newValues: Dictionary<string | number | boolean | number[] | string[] | null | SelectOption>,
    formData: Dictionary<EquityTouchFetchedField<any>>,
    extraDataValue?: any
  ) => save(newValues, formData, extraDataValue);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const formikValuesExists =
      Object.keys(
        (formik?.values as Dictionary<
          string | number | boolean | number[] | string[] | null | SelectOption
        >) || {}
      ).length !== 0;
    const formData = Object.keys(initialFormData || {}).length !== 0;
    const valuesExists = Object.keys((getValues as any) || {}).length !== 0;

    if (formikValuesExists && valuesExists && formData) {
      const timer = setTimeout(() => {
        handleAutosave(
          {
            ...(getValues as Dictionary<
              string | number | boolean | number[] | string[] | null | SelectOption
            >),
            ...(temporaryFormValues as any),
            ...(formik.values as Dictionary<
              string | number | boolean | number[] | string[] | null | SelectOption
            >),
          },
          initialFormData
        );
      }, AUTOSAVE_TIMER_DURATION);

      return () => clearTimeout(timer);
    }
  }, [formik?.values, initialFormData, getValues]);

  return null;
};

export default AutoSave;
