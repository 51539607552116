import { takeLatest, put, select } from 'redux-saga/effects';
import { History } from 'history';
import {
  actions as userInformationActions,
  selectors as userInformationSelectors,
} from '@redux/user/information';
import { selectors as routerSelectors } from '@redux/router';
// constants
import { ALL_REPORTS } from '@constants/routes';
// redux
import * as actions from '../actions';
import * as selectors from '../selectors';
import { PAGE_LOADED } from '../init/types';

/**
 * Initialize when page is loaded and if user comes from other pages.
 */
export function* pageLoadedSaga() {
  const shouldFetchFilters: boolean = yield select(selectors.filters.shouldFetch);
  const loaded: boolean = yield select(userInformationSelectors.loaded);

  if (loaded && shouldFetchFilters) {
    yield put(actions.filters.fetch());
  }
}

/**
 * Initialize filters when user information have been loade.
 * User is on analysts leaderboard page.
 */
export function* initFilters() {
  const router: History = yield select(routerSelectors.getRouter);

  if (router.location.pathname === ALL_REPORTS.sourcingAnalystsLeaderboard) {
    const shouldFetchFilters: boolean = yield select(selectors.filters.shouldFetch);

    if (shouldFetchFilters) {
      yield put(actions.filters.fetch());
    }
  }
}

export default function* initSagas() {
  yield takeLatest(PAGE_LOADED, pageLoadedSaga);
  yield takeLatest(userInformationActions.fetchUserInformationSuccess, initFilters);
}
