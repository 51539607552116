import { SpecialCriteria } from '@optx/models/feature/analystsLeaderboard/SpecialCriteria';
import { specialCriterias } from '../constants/specialCriteria';

export function isSpecialCriteria(criteria?: string) {
  if (!criteria) {
    return false;
  }

  return specialCriterias[criteria as SpecialCriteria];
}
