import styled, { css } from 'styled-components';
import { Radio, Drawer, Collapse } from 'antd';
import { RadioGroupProps } from 'antd/lib/radio';
import { RadioButtonProps } from 'antd/lib/radio/radioButton';
// components
import { IconProps } from '@optx/components/common/Icon/Icon';
import Icon from '@optx/components/common/Icon';

export const StyledDrawer = styled(Drawer)`
  & .ant-drawer-title {
    flex: 1;
    background-color: white;
  }
`;

interface AddResearchRationaleProps {
  isChromePlugin: boolean | undefined;
}

export const PanelContainer = styled(Collapse.Panel)`
  background-color: white;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 10px !important;

  span.card-title {
    color: red;
    font-size: 12px;
  }
`;

export const DependentPanelContainer = styled(PanelContainer)<{
  canBeShown: boolean;
}>`
  ${({ canBeShown }) => css`
    display: ${canBeShown ? 'block' : 'none'};
  `}
`;

export const CardInfoDivider = styled.div<AddResearchRationaleProps>`
  ${({ isChromePlugin }) => css`
    width: ${isChromePlugin ? '105%' : '106%'};
  `}
  height: 1px;
  background-color: rgb(216, 216, 216);
  margin-left: -12px;
  margin-top: 12px;
`;

export const PanelCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TitleContainer = styled.div<AddResearchRationaleProps>`
  display: flex;
  justify-content: space-between;
  background-color: white;

  h2 {
    font-size: ${({ isChromePlugin }) => (isChromePlugin ? '22px' : '24px')};
    line-height: ${({ isChromePlugin }) => (isChromePlugin ? '28px' : '32px')};
    margin: 0px;
  }
`;

export const CollapsableRationaleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: 'center';
  justify-content: space-between;
`;

export const ButtonsContainer = styled.div<AddResearchRationaleProps>`
  display: flex;
  gap: 16px;
`;

export const CompanyInfoContainer = styled.div`
  padding: 17px 21px 14px 29px;
  border-bottom: 1px solid #e6e6e6;
  margin-left: -24px;
  margin-right: -24px;
`;

export const ProfileInformationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
`;

interface Logo {
  hasLogo: boolean;
}

export const CompanyLogo = styled.div<Logo>`
  flex-basis: 78px;
  height: 78px;
  border-radius: 39px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14);
  background-color: #f4f4f8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  ${({ hasLogo }) => {
    if (!hasLogo) {
      return css`
        img {
          width: 48px;
          height: 48px;
        }
      `;
    }

    return css`
      img {
        width: 100%;
      }
    `;
  }}
`;

export const CompanySecondaryInfo = styled.div`
  display: flex;
  flex-direction: column;

  .et-label {
    width: 15%;
  }
`;

export const CompanyName = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  color: #334d6e;
`;

export const Address = styled.div`
  font-size: 14px;
  color: #90a0b7;
  font-weight: bold;
`;

export const ContentTitle = styled.h3`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #000;
  margin: 24px 0 0;
`;

export const ContentSubTitle = styled.h4`
  font-size: 12px;
  margin-bottom: 24px;
  color: #bfbfbf;
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
`;

export const ThumbsIcon = styled(Icon)`
  width: 16px;
  height: 16px;
`;

export const ThumbsUpIcon = styled(ThumbsIcon)`
  color: #52c41a;
`;

export const FitWrapper = styled.div`
  min-width: 180px;
  min-height: 25px;
  position: relative;
  float: right;
  display: inline-flex;
  margin: 4px 0 0 0;
`;

export const TextWrapper = styled.div`
  text-align: center;
  margin: 0 8px 0 12px;
`;

export const SelectText = styled.div`
  color: #e8e8e8;
  margin-top: -5px;
`;

export const FitText = styled.span`
  color: #334d6e;
  font-weight: 600;
`;

export const Thumb = styled.div`
  border-radius: 21px;
  width: 42px;
  height: 42px;
  padding: 7px;
  margin-right: 6px;
  cursor: pointer;
`;

export const ThumbUp = styled(Thumb)`
  padding: 5px 5px 5px 8px;
  border: 1px solid #52c41a;
`;

export const ThumbDown = styled(Thumb)`
  padding: 9px 5px 5px 5px;
  border: 1px solid #f5222d;
`;

interface OptxScoreContainerProps {
  isChromePlugin: boolean;
}

export const OptxScoreContainer = styled.div<OptxScoreContainerProps>`
  ${({ isChromePlugin }) =>
    css`
      width: ${isChromePlugin ? '105%' : '106%'};
    `}
  height: 85px;
  margin-left: -12px;
`;

export const OptxScoreContentGraphicContainer = styled.div`
  background-color: rgb(245, 247, 250);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 96px;
`;

export const ScoreRationaleDivider = styled.div`
  width: 105%;
  height: 1px;
  background-color: rgb(216, 216, 216);
  margin-left: -4px;
  margin-top: -4px;
`;

export const ScoreQuestion = styled.div`
  background: white;
  margin-top: -20px;

  display: flex;
  flex-direction: column;

  padding: 20px;
  border-radius: 0px 0px 8px 8px;

  border-top: 2px solid #eeeeee;

  h3 {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const OptxScore = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  width: 93%;
  height: 52px;
  border-radius: 8px;
  padding: 12px 17px;
`;

export const Logo = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 5px;
  object-fit: contain;
`;

export const OptxScoreNumber = styled.span`
  color: #62aaeb;
  font-weight: bold;
  font-size: 18px;
`;

export const RadioButton = styled(Radio.Button)<RadioButtonProps>`
  width: 42px !important;
  height: 42px !important;
  border-radius: 100% !important;
  padding: 0 !important;
  border-width: 1px !important;

  ${({ checked }) => css`
    &:first-child {
      background: ${checked ? '#52C41A' : '#fff'};
    }
    &:last-child {
      background: ${!checked ? '#F5222D' : '#fff'};
    }
  `}

  &::before {
    content: none !important;
  }

  span:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .icon {
      margin: 0 !important;
    }
  }
`;

interface ThumbProps extends IconProps {
  checked?: boolean;
}

export const IconThumbsUp = styled(ThumbsIcon)<ThumbProps>`
  ${({ checked }) => css`
    color: ${checked ? '#fff' : '#52C41A'};
  `}
`;

export const IconThumbsDown = styled(ThumbsIcon)<ThumbProps>`
  ${({ checked }) => css`
    color: ${!checked ? '#fff' : '#F5222D'};
  `}
`;
interface CustomRadioGroupProps extends RadioGroupProps {
  columns: number;
  isChromePlugin: boolean;
}

export const CustomRadioGroup = styled(Radio.Group)<CustomRadioGroupProps>`
  max-width: 100%;
  display: grid;
  margin-left: 16px;
  ${({ columns, isChromePlugin }) => css`
    grid-template-columns: repeat(${columns}, 1fr);
    width: ${isChromePlugin ? '560px' : '460px'};
  `}

  .ant-radio-wrapper {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    margin-right: 0;
  }

  .ant-radio-wrapper::after {
    position: absolute;
    content: '';
    background: #f5f5f5;
    height: 4px;
    width: 100%;
    top: 6px;
  }

  .ant-radio {
    z-index: 1;
  }
`;

export const RationalesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 0;
`;

export const PSGCompanyLabel = styled.span`
  color: rgb(30, 58, 81);
  font: bold 42px 'Times New Roman';
`;
