import { useSelector } from 'react-redux';
import { getIsAdminOrAnalyst } from '@optx/redux/user/information/selectors';

const useIsAdminOrAnalyst = () => {
  const value = useSelector(getIsAdminOrAnalyst);

  return value;
};

export default useIsAdminOrAnalyst;
