import {
  FilterSource,
  RangeFilter,
  BaseFilter,
  PreselectedFilter,
  MultiRangeNumberFilter,
  MultiSelectFilter,
  MultiCheckboxFilter,
  FilterGroupDataSection,
} from '@optx/models/filters';
import { Dictionary } from 'lodash';
import {
  RangeOptionBase,
  CheckableRangeOption,
  SingleCheckboxRangeOption,
} from '@optx/models/RangeOption';
import Option, {
  SelectOption,
  FormCheckboxOption,
  LogicOption,
  LogicOptionResponse,
  FormCheckableRangeOption,
  DateRangeOption,
  FilterMultiTextOption,
} from '@optx/models/Option';
// utils
import { mapToSelectOption } from '../option';

export function preselectedValues(sources: Array<FilterSource>): Dictionary<Array<string>> {
  const preselectedValues: Dictionary<Array<string>> = {};
  const preselectedNames: Dictionary<Array<string>> = {};

  sources.forEach(source => {
    if (source.data) {
      source.data.forEach(filter => {
        if (filter.preselected && filter.preselected.length) {
          const filterPreselectedNames: Array<string> = [];

          preselectedValues[filter.column] = filter.preselected.map(item => {
            if (item.name) {
              filterPreselectedNames.push(item.name);
            }

            return item.value ? item.value.toString() : item.toString();
          });

          if (filterPreselectedNames.length) {
            preselectedNames[`${filter.column}_label`] = filterPreselectedNames;
          }
        }
      });
    }
  });

  return {
    ...preselectedValues,
    ...preselectedNames,
  };
}

export function mapPreselectedFilters(
  sources: Array<FilterSource>,
  includePreselected = true,
  defaultDateIndex: number = 0
) {
  const preselectedFilters: Dictionary<PreselectedFilter> = {};

  sources.forEach(source => {
    if (source.data) {
      (source.data as Array<BaseFilter>).forEach(filter => {
        switch (filter.type) {
          case 'range_input': {
            const options: Array<FormCheckableRangeOption> = getRangeInputOptions(
              filter as RangeFilter<[RangeOptionBase, SelectOption[]]>
            );

            preselectedFilters[filter.column] = options;
            break;
          }

          case 'checkbox': {
            const options = getCheckboxOptions(filter, includePreselected);
            preselectedFilters[filter.column] = options;
            // custom option
            const customOption = getCheckboxCustomOption(filter as MultiCheckboxFilter);

            if (customOption) {
              preselectedFilters[`${filter.column}_custom`] = customOption;
            }

            break;
          }

          case 'multi_text': {
            const options = getMultiTextOptions(
              filter as BaseFilter<Array<{ name: string; placeholder?: string }>>
            );
            preselectedFilters[filter.column] = options;
            break;
          }

          case 'logic_checkbox': {
            const options = getLogicCheckboxOptions(
              filter as BaseFilter<Array<LogicOptionResponse>>
            );
            preselectedFilters[filter.column] = options;
            break;
          }

          case 'multiple_range_nr': {
            const options = getMultiRangeNumberOptions(filter as MultiRangeNumberFilter);
            preselectedFilters[filter.column] = options;
            // custom option
            const customOption = getMultiRangeCustomOption(filter as MultiRangeNumberFilter);

            if (customOption) {
              preselectedFilters[`${filter.column}_custom`] = customOption;
            }

            break;
          }

          case 'date_range': {
            const options = getDateRangeOptions(filter as RangeFilter<null>);
            preselectedFilters[filter.column] = options;
            break;
          }

          case 'date_range_radio': {
            const options = getDateRangesOptions(
              filter as BaseFilter<Array<DateRangeOption>>,
              defaultDateIndex
            );
            preselectedFilters[filter.column] = options;
            break;
          }

          case 'radio': {
            preselectedFilters[filter.column] = getRadioOption(
              filter as BaseFilter<Array<Option>>,
              includePreselected
            );

            break;
          }

          case 'endpoint_query':
          case 'multi_select':

          // eslint-disable-next-line no-fallthrough
          case 'single_select': {
            const options = getMultiSelectOptions(
              filter as MultiSelectFilter<Array<Option>>,
              includePreselected
            );
            preselectedFilters[filter.column] = options;

            break;
          }

          // for the moment add empty values, it's necessary to display error message for validation
          // in the future, if this filter will be included in a preselected filter, depending on the
          //  data format sent from api, the function should be updated
          case 'single_select_helper': {
            const option = getSingleSelectHelperOptions(
              filter as BaseFilter<Array<{ name: string; value: string }>>
            );
            preselectedFilters[filter.column] = option;

            break;
          }

          case 'filter_group_custom': {
            const data = filter.data as FilterGroupDataSection[];

            data.forEach(section => {
              switch (section.type) {
                case 'multi_select': {
                  preselectedFilters[section.column] = getMultiSelectOptions(
                    section as MultiSelectFilter<Array<Option>>,
                    includePreselected
                  );
                  break;
                }

                case 'checkbox': {
                  const options = getCheckboxOptions(
                    section as BaseFilter<any>,
                    includePreselected
                  );
                  preselectedFilters[section.column] = options;
                  // custom option
                  const customOption = getCheckboxCustomOption(section as MultiCheckboxFilter);

                  if (customOption) {
                    preselectedFilters[`${section.column}_custom`] = customOption;
                  }

                  break;
                }

                case 'radio': {
                  preselectedFilters[section.column] = getRadioOption(
                    filter as BaseFilter<Array<Option>>,
                    includePreselected
                  );

                  break;
                }

                case 'date_range': {
                  preselectedFilters[section.column] = getDateRangeOptions(
                    section as RangeFilter<null>
                  );
                  break;
                }

                default:
                  break;
              }
            });

            break;
          }

          default:
            break;
        }
      });
    }
  });

  return preselectedFilters;
}

export function getRadioOption(filter: BaseFilter<Array<Option>>, includePreselected = true) {
  const preselected = filter.preselected as Array<string>;
  let defaultValue = '';

  if (preselected && preselected.length) {
    defaultValue = includePreselected ? preselected[0] : '';
  } else {
    defaultValue = (filter.data && filter.data.length && filter.data[0].value) || '';
  }

  return defaultValue;
}

export function getDateRangeOptions(filter: RangeFilter<null>) {
  const { placeholders } = filter as RangeFilter<null>;
  const options: Array<SelectOption> = [];

  options.push({
    label: placeholders.min,
    value: '',
  });

  options.push({
    label: placeholders.max,
    value: '',
  });

  return options;
}

export function getDateRangesOptions(
  filter: BaseFilter<Array<DateRangeOption>>,
  defaultDateIndex: number = 0
): [string | null, string | null] {
  const values: [string | null, string | null] = [null, null];

  if (!filter.preselected || !filter.preselected.length) {
    // If there is no options preselected select first option on data.
    if (filter.data && filter.data.length) {
      const firstOption = filter.data[defaultDateIndex];

      return [firstOption.start, firstOption.end];
    }

    return values;
  }

  return filter.preselected as [string | null, string | null];
}

export function getMultiTextOptions(
  filter: BaseFilter<Array<{ name: string; placeholder?: string }>>
) {
  const options: Array<FilterMultiTextOption> = [];

  filter.data.forEach(item => {
    options.push({
      label: item.name,
      value: '',
      placeholder: item.placeholder,
    });
  });

  return options;
}

export function getCheckboxOptions(filter: BaseFilter<any>, includePreselected: boolean = true) {
  const options: Array<FormCheckboxOption> = [];
  const preselected: Dictionary<string> = {};

  if (includePreselected && filter.preselected) {
    (filter.preselected as Array<string>).forEach(item => {
      preselected[item] = item;
    });
  }

  (filter as BaseFilter<Array<Option>>).data.forEach(option => {
    options.push({
      label: option.name as string,
      value: option.value,
      checked: includePreselected ? !!preselected[option.value] : false,
    });
  });

  return options;
}

export function getLogicCheckboxOptions(filter: BaseFilter<Array<LogicOptionResponse>>) {
  const options: Array<LogicOption> = [];

  (filter as BaseFilter<Array<LogicOptionResponse>>).data.forEach(option => {
    options.push({
      label: option.name!,
      value: option.value,
      in: !!option.in,
      notIn: !!option.not_in,
    });
  });

  return options;
}

export function getMultiRangeNumberOptions(filter: MultiRangeNumberFilter) {
  const options: Array<CheckableRangeOption> = [];

  filter.data.forEach(option => {
    options.push({
      label: option.name,
      min: option.min,
      max: option.max,
      checked: false,
    });
  });

  return options;
}

export function getMultiRangeCustomOption(
  filter: MultiRangeNumberFilter
): CheckableRangeOption<string> | undefined {
  if (!filter.custom) {
    return undefined;
  }

  const option: CheckableRangeOption<string> = {
    checked: false,
    min: '',
    max: '',
    label: '',
  };

  return option;
}

export function getCheckboxCustomOption(
  filter: MultiCheckboxFilter
): SingleCheckboxRangeOption<string> | undefined {
  if (!filter.custom) {
    return undefined;
  }

  const option: SingleCheckboxRangeOption<string> = {
    checked: false,
    label: '',
    value: '',
    min: '',
    max: '',
  };

  return option;
}

export function getRangeInputOptions(filter: RangeFilter<[RangeOptionBase, SelectOption[]]>) {
  const options: Array<FormCheckableRangeOption> = [];

  const [range, dataCheck] = filter.data;
  let check: SelectOption[] = [];

  if (Array.isArray(dataCheck)) {
    check = dataCheck;
  } else {
    check = [dataCheck];
  }

  options.push({
    ...range,
    range: [range.min, range.max],
    check: check ? check.map(check => ({ ...check, checked: false })) : undefined,
  });

  return options;
}

export function getMultiSelectOptions(
  filter: MultiSelectFilter<Array<Option>>,
  includePreselected = true
) {
  if (includePreselected && filter.preselected) {
    return filter.preselected.map(option => mapToSelectOption(option, filter.format_name));
  }

  return undefined;
}

export function filtersWithHistogram(sources: Array<FilterSource>): Array<string> {
  const filtersList: Array<string> = [];

  sources.forEach(source => {
    if (source.data) {
      source.data.forEach(filter => {
        if (filter.hasHistogram) {
          filtersList.push(filter.column);
        }
      });
    }
  });

  return filtersList;
}

export function getSingleSelectHelperOptions(filter: BaseFilter<Array<Option>>) {
  const option: SelectOption = {
    label: '',
    value: '',
  };

  return option;
}
