export function fitToLocations(map: google.maps.Map, locations: Array<google.maps.LatLngLiteral>) {
  const bounds = new google.maps.LatLngBounds();

  // Create bounds from markers
  for (let index = 0; index < locations.length; index++) {
    const latlng = locations[index];
    bounds.extend(latlng);
  }

  // Don't zoom in too far on only one marker
  if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
    const extendPoint1 = new google.maps.LatLng(
      bounds.getNorthEast().lat() + 0.01,
      bounds.getNorthEast().lng() + 0.01
    );
    const extendPoint2 = new google.maps.LatLng(
      bounds.getNorthEast().lat() - 0.01,
      bounds.getNorthEast().lng() - 0.01
    );

    bounds.extend(extendPoint1);
    bounds.extend(extendPoint2);
  }

  map.fitBounds(bounds);

  // Adjusting zoom here doesn't work :)
}

/**
 * Fit map to single world view.
 * @param map google map instance.
 */
export function worldViewFit(map: google.maps.Map) {
  const worldBounds = new google.maps.LatLngBounds(
    new google.maps.LatLng(90, -180), // Top-left
    new google.maps.LatLng(-90, 180) // Bottom-right
  );
  map.fitBounds(worldBounds, 0);
  const actualBounds = map.getBounds();

  if (
    actualBounds &&
    actualBounds.getSouthWest().lng() === -180 &&
    actualBounds.getNorthEast().lng() === 180
  ) {
    // @ts-ignore
    map.setZoom(map.getZoom() + 1);
  }
}

/**
 * Generate unique key from location coordinates.
 * @param location
 */
export function createLocationKey(location: google.maps.LatLngLiteral) {
  return location.lat + location.lng;
}
