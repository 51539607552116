import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ValueType } from 'react-select';
// models
import { SelectOption } from '@optx/models/Option';
// utils
import { getSingleSelectOptions } from '@optx/utils/filters/mapFiltersOptions';
// redux
import { selectors, actions } from '@features/pipeline-report/state';
// components
import { SingleSelect } from '@shared/view/molecules/Select';

export const FilterSingleSelect: React.FC = props => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState<Array<SelectOption>>([]);
  const [option, setOption] = useState<SelectOption>();

  const {
    normalized: { country: normalizedData },
    filter,
  } = useSelector(selectors.getFilters);
  const fundAssociation = useSelector(selectors.fundAssociation);

  useEffect(() => {
    if (normalizedData) {
      const sortedOptions = getSingleSelectOptions(normalizedData);

      setOptions(sortedOptions);

      setOption(sortedOptions.find(option => option.label === filter.region));
    }
  }, [normalizedData, filter]);

  const getFunds = useCallback(
    (country?: string) => {
      return fundAssociation.reduce((acc, item) => {
        if (country) {
          if (country === 'Europe') {
            if (item.label.includes('Europe')) {
              if (acc === '') {
                return item.label;
              }

              return `${acc},${item.label}`;
            }

            return acc;
          }

          if (!item.label.includes('Europe')) {
            if (acc === '') {
              return item.label;
            }

            return `${acc},${item.label}`;
          }

          return acc;
        }

        if (acc === '') {
          return item.label;
        }

        return `${acc},${item.label}`;
      }, '' as string);
    },
    [fundAssociation]
  );

  const updateValue = useCallback(
    (newValue: ValueType<SelectOption>) => {
      let value;
      let key = 'fund';
      const { label } = newValue as SelectOption;

      if (label === 'Europe') {
        value = getFunds('Europe');
      } else if (label === 'North America + Israel') {
        value = getFunds('North America + Israel');
      } else if (label === 'Israel') {
        key = 'is_il_optx_score';
        value = true;
      } else {
        value = '';
      }

      setOption(options.find(option => option.label === label));

      dispatch(actions.updateValue({ filter: { value, key } }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, fundAssociation]
  );

  return (
    <SingleSelect
      options={options}
      onChange={updateValue}
      value={option}
      placeholder={normalizedData ? normalizedData.placeholders : undefined}
      valuePrefix="By Region:"
      {...props}
    />
  );
};
