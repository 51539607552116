import styled from 'styled-components';

const AsyncWrapper = styled.div`
  .multiselect-select__control {
    border: 1px solid #d9d9d9;
    border-radius: 0;

    .multiselect-select__value-container {
      padding-left: 34px;
      height: 40px;

      .multiselect-select__value-container__icon {
        position: absolute;
        top: 10px;
        left: 12px;
        height: 14px;
      }
    }

    .multiselect-select__indicator-separator {
      display: none;
    }
  }
`;

const Styles = {
  AsyncWrapper,
};

export default Styles;
