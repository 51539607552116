import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// redux
import {
  selectors as userInformationSelectors,
  actions as userInformationActions,
} from '@redux/user/information';
// components
import ToggleButton from '@components/common/molecules/buttons/ToggleButton';

const QuickFiltersToggle = () => {
  const dispatch = useDispatch();
  const showUserListQuickFilters = useSelector(userInformationSelectors.showUserListQuickFilters);

  const handleClick = () => {
    dispatch(
      userInformationActions.updateUserSettings({
        user_list_show_quick_filters: !showUserListQuickFilters,
      })
    );
  };

  const title = `${showUserListQuickFilters ? 'Hide' : 'Show'} Quick Filters`;

  return (
    <ToggleButton onClick={handleClick} hide={Boolean(showUserListQuickFilters)} title={title} />
  );
};

export default React.memo(QuickFiltersToggle);
