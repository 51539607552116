import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actions as searchActions } from '../../../redux/contacts/saved-searches';
// constants
import appRoutes from '../../../constants/routes';

interface useSavedContactsSearchesFunction {
  (): { handleSearchResults(id: number | string, title: string): void };
}

const useSavedContactsSearches: useSavedContactsSearchesFunction = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSearchResults = (id: number | string, title: string) => {
    history.push(appRoutes.contacts);
    dispatch(searchActions.resetToSearch(id, title));
  };

  return { handleSearchResults };
};

export default useSavedContactsSearches;
