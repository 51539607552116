import { call, put, takeLatest, take, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary } from 'lodash';
import queryString from 'query-string';
// services
import { AnalystService, FilterService } from '@services/api';
import NotificationService from '@services/NotificationService';
// constants
import { FAVORITE_LIST_OWNER } from '@optx/constants/lists';
// models
import { AxiosResponse } from 'axios';
import { PipelineReportSingleSelectPayload } from './interfaces';
import { FilterGroup, PreselectedFilter } from '@optx/models/filters';
import { CompanyWatchList } from '@optx/models/WatchList';
import { SearchPayloadRequest } from '@optx/models/api/contacts';
// utils
import mapFiltersToURLParams from '@optx/utils/filters/mapFiltersToURLParams';
import { getErrorMessage } from '@optx/utils/api/errors';
// redux
import {
  selectors as userSelectors,
  actions as userInformationActions,
} from '@redux/user/information';
import { actions as fundActions } from '@components/feature/company-individual-edit/state/fund';
import { actions as companyEditFields } from '@features/company/edit-fields/state';
// local
import * as actions from './actions';
import * as selectors from './selectors';
import { PipelineReportCompanies } from '../models';

const getQueryRegionData = (
  filter: Dictionary<PreselectedFilter>,
  action?: PayloadAction<PipelineReportSingleSelectPayload | undefined>
) => {
  let queryRegionData = {};

  if (action?.payload?.filter && ['fund', 'is_il_optx_score'].includes(action.payload.filter.key)) {
    queryRegionData = { [action.payload.filter.key]: action.payload.filter.value };
  } else if (filter.is_il_optx_score) {
    queryRegionData = { is_il_optx_score: true };
  } else if (filter.fund) {
    queryRegionData = { fund: filter.fund };
  }

  return queryRegionData;
};

function* getFilterQuery(action?: PayloadAction<PipelineReportSingleSelectPayload | undefined>) {
  const { normalized, filter, clear } = yield select(selectors.getFilters);

  let queryData: Dictionary<PreselectedFilter> = {};

  if (action?.payload?.shouldClear) {
    const defaultScore: string = yield select(userSelectors.getDefaultScore);

    const normalizedFilter = {
      ...clear,
      ...(clear.company_owner_id && {
        company_owner_id: clear.company_owner_id.length === 0 ? [''] : clear.company_owner_id,
      }),
    };

    queryData = {
      ...mapFiltersToURLParams(normalized, normalizedFilter),
      ...(defaultScore === 'il' ? { is_il_optx_score: true } : {}),
      country: undefined,
    };
  } else {
    const normalizedFilter = {
      ...filter,
      ...(filter.company_owner_id && {
        company_owner_id: filter.company_owner_id.length === 0 ? [''] : filter.company_owner_id,
      }),
    };

    queryData = {
      ...mapFiltersToURLParams(normalized, normalizedFilter),
      ...getQueryRegionData(filter, action),
      country: undefined,
    };

    if (normalizedFilter.region === 'Israel') {
      delete queryData.fund;
      queryData.is_il_optx_score = true;
    }

    if (queryData.fund === '') {
      delete queryData.fund;
    }
  }

  return queryString.stringify(queryData, {
    arrayFormat: 'comma',
  });
}

export function* fetchPipelineReportSaga(
  action?: PayloadAction<PipelineReportSingleSelectPayload | undefined>
) {
  const query: string = yield call(getFilterQuery, action);

  try {
    const res: AxiosResponse = yield call(AnalystService.getPipelineReportsCompany, query);

    if (res.data) {
      yield put(actions.fetchPipelineReportCompanySuccess(res.data.companies));
      yield put(userInformationActions.updateUserSettings({ pipeline_report: query }));
    } else {
      const errorMessage = 'Pipeline reports fail!';
      yield put(actions.fetchPipelineReportCompanyFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Pipeline reports fail, Server error!');
    yield put(actions.fetchPipelineReportCompanyFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* fetchPipelineReportCardSaga(
  action?: PayloadAction<PipelineReportSingleSelectPayload | undefined>
) {
  const query: string = yield call(getFilterQuery, action);

  try {
    const res: AxiosResponse = yield call(AnalystService.getPipelineReportsCard, query);

    if (res.data) {
      yield put(actions.fetchPipelineReportCardSuccess(res.data));
    } else {
      const errorMessage = 'Pipeline reports fail!';
      yield put(actions.fetchPipelineReportCardFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Pipeline reports fail, Server error!');
    yield put(actions.fetchPipelineReportCardFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* fetchFiltersSaga() {
  try {
    const shouldFetchUserInformation: boolean = yield select(userSelectors.shouldFetch);

    if (shouldFetchUserInformation) {
      yield take(userInformationActions.fetchUserInformationSuccess);
    }

    const defaultScore: string = yield select(userSelectors.getDefaultScore);

    const res: AxiosResponse = yield call(FilterService.getOptions, FilterGroup.PipelineReport);

    yield put(actions.fetchFiltersSuccess({ filters: res.data.filters, defaultScore }));

    yield put(actions.fetchPipelineReportCard());

    yield take(actions.fetchPipelineReportCardSuccess);

    yield put(actions.fetchPipelineReportCompany());
  } catch (error: any) {
    const errorMessage = getErrorMessage(
      error as Error,
      'Failed to fetch filters for Pipeline Reports!'
    );

    yield put(actions.fetchFiltersFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* saveSearchAsListSaga(action: PayloadAction<{ title: string }>) {
  const { title } = action.payload;

  const { normalized, filter } = yield select(selectors.getFilters);
  const filterQuery = mapFiltersToURLParams(normalized, filter);
  const userName: string = yield select(userSelectors.getFullName);
  const companyData: PipelineReportCompanies[] = yield select(selectors.pipelineReportCompanies);

  const searchData: SearchPayloadRequest = {
    searchKey: '',
    filter: { ...filterQuery, fund: filter?.fund },
    pagination: { pageNumber: 0, pageSize: 0 },
    sortBy: [],
  };

  try {
    const res: AxiosResponse<Dictionary<CompanyWatchList>> = yield call(
      AnalystService.saveAsListPipelineReport,
      title,
      searchData
    );

    const getKeys = Object.keys(res.data)[0];
    const value = res.data[getKeys];

    res.data[Object.keys(res.data)[0]] = {
      ...res.data[Object.keys(res.data)[0]],
      owner_name: userName,
      origin: FAVORITE_LIST_OWNER,
    };

    yield put(
      actions.saveSearchAsListSuccess({ [getKeys]: { ...value, count: companyData.length } })
    );
    NotificationService.success(`Create watchlist ${title} success!`);
  } catch (e: any) {
    // save the title to reducer, create a new action
    const message = `Create watchlist ${title} failed!`;
    yield put(actions.saveSearchAsListFail(message));
    NotificationService.error(message);
  }
}

function* updateFilterSaga(action?: PayloadAction<PipelineReportSingleSelectPayload>) {
  yield put(actions.fetchPipelineReportCard(action?.payload));

  yield take(actions.fetchPipelineReportCardSuccess);

  yield put(actions.fetchPipelineReportCompany(action?.payload));
}

/**
 * reset filter saga is separated from the update one
 * because the action payload mustn't be passed on the saga,
 * only on the reducer.
 */
function* resetFilterSaga() {
  yield put(actions.fetchPipelineReportCard());

  yield take(actions.fetchPipelineReportCardSuccess);

  yield put(actions.fetchPipelineReportCompany());
}

function* clearFiltersSaga() {
  const payload = { filter: { key: '', value: '' }, shouldClear: true };

  yield put(actions.fetchPipelineReportCard(payload));

  yield take(actions.fetchPipelineReportCardSuccess);

  yield put(actions.fetchPipelineReportCompany(payload));
}

export default function* alertsSaga() {
  yield takeLatest(actions.fetchPipelineReportCompany, fetchPipelineReportSaga);
  yield takeLatest(actions.fetchPipelineReportCard, fetchPipelineReportCardSaga);
  // filters
  yield takeLatest(actions.fetchFilters, fetchFiltersSaga);
  yield takeLatest(actions.updateValue, updateFilterSaga);
  yield takeLatest(actions.clearFilters, clearFiltersSaga);
  yield takeLatest(actions.resetToDefault, updateFilterSaga);
  yield takeLatest(actions.applyFilters, updateFilterSaga);
  yield takeLatest(actions.resetFilter, resetFilterSaga);
  yield takeLatest(actions.saveSearchAsList, saveSearchAsListSaga);
  // external
  yield takeLatest(companyEditFields.updateFieldsSuccess, fetchPipelineReportCardSaga);
  yield takeLatest(fundActions.addFundSuccess, fetchPipelineReportCardSaga);
}
