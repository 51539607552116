import { createSelector } from 'reselect';
import { getUserId } from '../information/selectors';
import { UserListState } from './interfaces';

const userListState = (state: any) => state.userList as UserListState;

export const isShareLoading = createSelector(userListState, state => state.share.loading);
export const isFieldLoading = createSelector(userListState, state => state.field.loading);

export const shouldFieldFetch = createSelector(
  userListState,
  state => !state.field.loading && !state.field.fetchedAt
);
export const shouldShareFetch = createSelector(
  userListState,
  state => !state.share.loading && !state.share.fetchedAt
);

export const getShareUserListData = createSelector(userListState, state => state.share.data);
export const getFieldUserListData = createSelector(userListState, state => state.field.data);

export const getUserListOptions = createSelector(getFieldUserListData, state =>
  state.map(item => ({
    label: item.label,
    value: item.value.toString(),
  }))
);

export const getSeniorDealTeamLeadOptions = createSelector(getFieldUserListData, state => {
  const seniorDealTeamOptions = state.filter(list => typeof list.value !== 'string');

  return seniorDealTeamOptions.map(item => ({
    label: item.label,
    value: item.value.toString(),
  }));
});

/**
 * Get users except current logged in user.
 */
export const getUserList = createSelector(getShareUserListData, getUserId, (userList, userId) => {
  return userList.filter(user => user.value !== userId && typeof user.value !== 'string');
});

export const getPsgUsers = createSelector(userListState, state =>
  state.psgUsers.data.map(item => ({
    value: item.value.toString(),
    label: item.label,
  }))
);
