import React from 'react';
import { Dropdown, Button, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
// utils
import { useVisibleChange } from '@optx/utils/useVisibleChange';
// components
import ExportCSVButton from './ExportCSVButton';
import SaveSearchAsListButton from './SaveSearchAsListButton';

const PipelineReportSearchDropdown: React.FC = () => {
  const { dropdownOpen, toggleDropdownOpen } = useVisibleChange();

  const menu = (
    <Menu>
      <ExportCSVButton onChangeVisible={toggleDropdownOpen} />
      <SaveSearchAsListButton onChangeVisible={toggleDropdownOpen} />
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      placement="bottomRight"
      visible={dropdownOpen}
      onVisibleChange={toggleDropdownOpen}
    >
      <Button type="text" icon={<EllipsisOutlined style={{ fontSize: 28 }} />} />
    </Dropdown>
  );
};

export default PipelineReportSearchDropdown;
