import React from 'react';
import { Row, Col, Typography } from 'antd';
import moment from 'moment';
import { BellOutlined } from '@ant-design/icons';
// models
import CompanyTouch from '@optx/models/company/CompanyTouch';
// constants
import { TOUCH_DATE_FORMAT } from './constants';
// components
import TouchIcon from './TouchIcon';
import Attendees from './Attendees';

interface TouchHeaderProps {
  touch: CompanyTouch;
  scheduled?: boolean;
}

const TouchHeader: React.FC<TouchHeaderProps> = ({ touch, scheduled }) => {
  return (
    <Row gutter={[20, 0]}>
      <Col flex="0">
        {scheduled ? (
          <BellOutlined
            className={touch.past_due ? 'is-past-due' : ''}
            style={{ fontSize: 19, color: '#1890FF' }}
          />
        ) : (
          <TouchIcon type={touch.touchtype} />
        )}
      </Col>
      <Col flex="auto">
        <Typography.Title className="touch-item__title" level={4}>
          {touch.subject}
        </Typography.Title>
        <Typography.Text className="touch-item__date">
          {scheduled &&
            (touch.past_due ? (
              <Typography.Text type="danger">PAST DUE: </Typography.Text>
            ) : (
              'SCHEDULED: '
            ))}
          {moment(touch.startdate).format(TOUCH_DATE_FORMAT)}
        </Typography.Text>
        <Typography.Text className="touch-item__attendees">
          {!scheduled && touch.past_due && <span className="touch-item__past-due">PAST DUE:</span>}
          <span>{`${touch.initiatorname} (initiator) `}</span>
          <Attendees attendees={touch.attendees} />
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default TouchHeader;
