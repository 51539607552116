export enum TotalsCardLabels {
  this_week = 'This Week',
  this_month = 'This Month',
  all_open_touches = 'All Open',
  past_due = 'Past Due',
  Dead = 'Dead',
  Pass = 'Pass',
  add_ons = 'Add-ons',
  rank_5s = 'Rank 5s',
  new = 'New',
  positive = 'Positive',
  negative = 'Negative',
  added_to_track = 'Add to Track',
  rank_of_5 = 'Rank of 5',
  rank_of_4 = 'Rank of 4',
}

export enum labelNameMap {
  companies_reached = '# of Companies Reached',
  stage_change = '# of Stage Change',
  companies_added = '# of Companies Added',
  qualified_leads = '# of Qualified Lead',
}
