/**
 * @description The storage item name
 * @type {string}
 */
const IS_ANALYST = 'is_analyst';

/**
 * @description get item from localStorage
 * @return {boolean}
 */
const getIsAnalyst = () => {
  return localStorage.getItem(IS_ANALYST) === 'true';
};

/**
 * @description Set value to localStorage
 * @param {boolean} value item value
 */
const setIsAnalyst = (value: boolean) => {
  localStorage.setItem(IS_ANALYST, value.toString());
};

/**
 * @description remove is_analyst from localStorage
 */
const removeIsAnalyst = () => {
  localStorage.removeItem(IS_ANALYST);
};

export default {
  getIsAnalyst,
  setIsAnalyst,
  removeIsAnalyst,
};
