import { createFeatureAction } from '@utils/feature';
import featureName from '../../featureName';
import * as types from './types';
import { EditCompanySubSector } from './interfaces';

export const addSubSector = createFeatureAction<EditCompanySubSector>(
  featureName,
  types.ADD_COMPANY_SUB_SECTOR
);

export const addSubSectorSuccess = createFeatureAction<EditCompanySubSector>(
  featureName,
  types.ADD_COMPANY_SUB_SECTOR_SUCCESS
);

export const addSubSectorFail = createFeatureAction<string>(
  featureName,
  types.ADD_COMPANY_SUB_SECTOR_FAIL
);
