import React from 'react';
import { Card } from 'antd';

const { Meta } = Card;

interface SearchFilterCardProps {
  label?: string | React.ReactNode;
  className?: string;
}

const SearchFilterCard: React.FC<SearchFilterCardProps> = ({ label, className, children }) => {
  return (
    <Card className={`filter-form__card ${className || ''}`}>
      <Meta title={label} description={children} />
    </Card>
  );
};

export default SearchFilterCard;
