import React, { useCallback, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Input } from 'antd';
// redux
import { actions as contactsActions } from '../../../redux/contacts/search/search';
// components
import SearchInput, { SearchInputProps } from '../../common/form/input/SearchInput';

interface CompanySearchProps extends Omit<SearchInputProps, 'onSearch' | 'value'> {}

const ContactsSearch: React.FC<CompanySearchProps> = ({ onFocus, onBlur, ...restProps }) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const inputRef = useRef<Input | null>(null);

  const search = useCallback(
    (searchKey: string) => {
      dispatch(contactsActions.searchContacts({ searchKey }));
    },
    [dispatch]
  );

  const handleSearch = (searchKey: string) => {
    // close the dropdown by clicking on input.
    inputRef.current!.input?.click();
    search(searchKey);
    setQuery('');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  return (
    <SearchInput
      ref={inputRef}
      {...restProps}
      onChange={handleChange}
      onSearch={handleSearch}
      value={query}
      placeholder="Search for contacts by keyword"
    />
  );
};

export default ContactsSearch;
