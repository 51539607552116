import React, { useCallback } from 'react';
import { Modal } from 'antd';
import { Dictionary } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
// models
import { PreselectedFilter } from '@optx/models/filters';
// utils
import { normalizeFilter } from '@optx/utils/filters/filterHelpers';
// redux
import { actions, selectors } from '@features/checklist-research-insights/state';
import { actions as modalActions, selectors as modalSelectors } from '@redux/ui/modals/gridModals';
// components
import FiltersModalContent from './FiltersModalContent';

const FiltersModal = () => {
  const filter = useSelector(selectors.filters.selectFilter);
  const filterSources = useSelector(selectors.filters.sources);
  const modalIsOpen = useSelector(modalSelectors.isOpen('checklistResearchInsights'));
  const searchKey = useSelector(selectors.filters.searchKey);

  const dispatch = useDispatch();

  const onSearch = useCallback(
    (searchKey?: string, filter?: Dictionary<PreselectedFilter>) => {
      let normalizedFilter: Dictionary<PreselectedFilter> = {};

      if (filter) {
        Object.keys(filter).map((item: string) => {
          if (!Array.isArray(filter[item])) {
            normalizedFilter = { ...normalizedFilter, [item]: [filter[item]] as PreselectedFilter };
          } else {
            normalizedFilter = { ...normalizedFilter, [item]: filter[item] };
          }
        });
      }

      dispatch(
        actions.filters.applyFilters({
          searchKey,
          filter: normalizedFilter as Dictionary<PreselectedFilter>,
        })
      );
    },
    [dispatch]
  );

  const handleClear = useCallback(() => {
    dispatch(actions.filters.clearFilters());
  }, [dispatch]);

  const handleReset = useCallback(() => {
    dispatch(actions.filters.resetToDefault());
  }, [dispatch]);

  const toggleModalFilters = () =>
    dispatch(modalActions.toggleModal({ gridKey: 'checklistResearchInsights' }));

  const handleSearch = (key: string, filter?: Dictionary<PreselectedFilter>) => {
    const normalizedFilter = normalizeFilter(filter);
    onSearch(key, normalizedFilter);
    toggleModalFilters();
  };

  return (
    <Modal
      className="ant-modal-full ant-modal--filters"
      title={null}
      visible={modalIsOpen}
      footer={null}
      onCancel={toggleModalFilters}
      closable={false}
      destroyOnClose
    >
      <FiltersModalContent
        filter={filter}
        filterSources={filterSources}
        keySearch={searchKey as string}
        onSearch={handleSearch}
        onClose={toggleModalFilters}
        onClear={handleClear}
        onReset={handleReset}
      />
    </Modal>
  );
};

export default FiltersModal;
