import { createSelector } from 'reselect';
import { Dictionary } from 'lodash';
import { EditShareListFormUserOption } from '@optx/models/feature/shareList/form';
import { ShareListUser } from '@optx/models/feature/shareList/ShareListInfo';
import { UserList } from '@optx/redux/user/user-list/interfaces';
import { ListAccessRights } from '@optx/models/WatchList';
import { selectors as usersSelectors } from '@redux/user/user-list';
import shareList from './shareList';
import ui from './ui';

// cluster/common selectors for share list and user info
// @ts-ignore
const isLoading = createSelector(
  shareList.isLoadingInfo,
  usersSelectors.isShareLoading,
  (loadingInfo: boolean, loadingUsers: boolean) => loadingUsers || loadingInfo
);

const userOptions = createSelector(
  // @ts-ignore
  usersSelectors.getShareUserListData,
  shareList.listOwner,
  shareList.accessRightsOptions,
  shareList.otherUsersAccessRightsOptions,
  // @ts-ignore
  (users, owner, accessRightsOptions, otherUsersAccessOptions) => {
    // Find default access rights for owner and other users.
    const otherUsersDefaultAccessRights = otherUsersAccessOptions.length
      ? otherUsersAccessOptions[0].value
      : ListAccessRights.ReadOnly;

    const ownerDefaultAccessRights =
      accessRightsOptions && accessRightsOptions.length
        ? accessRightsOptions[0].value
        : ListAccessRights.ReadOnly;

    const usersWithoutSeniorAdvisorOptions = users.filter(
      (list: UserList) => typeof list.value !== 'string'
    );

    if (!owner) {
      return usersWithoutSeniorAdvisorOptions.map((user: UserList) => {
        const shareUser: EditShareListFormUserOption = {
          ...user,
          accessRights: otherUsersDefaultAccessRights,
          accessRightsOptions: otherUsersAccessOptions,
        };

        return shareUser;
      });
    }

    // Keep owner on the top of the list.
    const mappedUsers: Array<EditShareListFormUserOption> = [];
    let ownerIndex = -1;

    usersWithoutSeniorAdvisorOptions.forEach((user: any, index: number) => {
      const mappedUser: EditShareListFormUserOption = {
        ...user,
        accessRights: otherUsersDefaultAccessRights,
        accessRightsOptions: otherUsersAccessOptions,
      };

      if (owner.user_id === user.value) {
        mappedUser.disabled = true;
        ownerIndex = index;
        mappedUser.accessRightsOptions = accessRightsOptions;
        mappedUser.accessRights = ownerDefaultAccessRights;
      }

      mappedUsers.push(mappedUser);
    });

    const finalUsers = [
      mappedUsers[ownerIndex],
      ...mappedUsers.slice(0, ownerIndex),
      ...mappedUsers.slice(ownerIndex + 1),
    ];

    return finalUsers;
  }
);

/**
 * Users list is shared with.
 */
// @ts-ignore
const selectedUsers = createSelector(
  userOptions,
  shareList.sharedListUsers,
  // @ts-ignore
  (users, sharedUsers) => {
    const sharedUsersDict: Dictionary<ShareListUser> = {};

    sharedUsers.forEach((sharedUser: any) => {
      sharedUsersDict[sharedUser.user_id] = sharedUser;
    });

    return users
      .filter((user: any) => sharedUsersDict[user.value])
      .map((user: any) => {
        const isEqOwner = sharedUsersDict[user.value].is_owner === true;
        const sharedUser = { ...user, disabled: isEqOwner };
        sharedUser.accessRights = sharedUsersDict[user.value].preselected.value;

        return sharedUser;
      });
  }
);

export default {
  shareList,
  ui,
  // cluster/common selectors
  isLoading,
  userOptions,
  selectedUsers,
};
