import { ColumnsType } from 'antd/lib/table';
import { CompanyWatchList } from '@optx/models/WatchList';
import TableCellName from './cols/TableCellName';

const columns: ColumnsType<CompanyWatchList> = [
  {
    title: 'Name',
    dataIndex: 'title',
    render: TableCellName,
  },
  {
    title: 'Owner',
    dataIndex: 'owner_name',
    width: 170,
  },
];

export default columns;
