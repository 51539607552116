import styled from 'styled-components';

interface ContentProps {
  isArr?: boolean;
}

const Content = styled.div<ContentProps>`
  width: ${props => (props.isArr ? '265px' : '300px')};

  .amount-wrapper {
    position: relative;
    margin-bottom: 16px;
    flex-direction: column;
  }

  .error {
    position: absolute;
    top: 58px;
    line-height: 1.2;
    left: 0;
  }

  .ant-row {
    padding: 0 !important;
  }
  .ant-input-affix-wrapper {
    margin-bottom: 20px;
    > input.ant-input {
      margin: 0;
    }
  }
`;

export default Content;
