import React from 'react';
import Company from '@optx/models/Company';
import { Cell } from '../../../../../models/table/Cell';
import { cellIsEqual } from '../../AntTable/cells/memoize';
import { DEFAULT_CELL_VALUE } from '../../../../../constants/table/cells';
import { normalizeNegativeSignValues } from '@optx/utils/utils';

export const TableCellPercentageGrowth: React.FC<Cell<Company>> = ({ value, record }) => {
  let textClassName = '';

  if (value !== null) {
    if (value > 0) {
      textClassName = 'cell-growth';
    }
  } else {
    textClassName = 'empty-cell';
  }

  return (
    <span className={textClassName}>
      {value > 0 ? '+' : ''}
      {normalizeNegativeSignValues(value?.toString())}
      {value !== DEFAULT_CELL_VALUE ? '%' : ''}
    </span>
  );
};

export default React.memo(TableCellPercentageGrowth, cellIsEqual);
