import { combineReducers } from 'redux';
import { CompanyGraphsState } from './interfaces';
// reducers
import { reducer as employeeGrowthReducer } from '../employee';
import { reducer as scoreGrowthReducer } from '../score';

const reducer = combineReducers<CompanyGraphsState>({
  employeeGrowth: employeeGrowthReducer,
  scoreGrowth: scoreGrowthReducer,
});

export default reducer;
