import { createAction } from '@reduxjs/toolkit';
import { SortRule } from '@models/table/sorting';
// redux
import { PageInfo } from '@optx/models/table/Pagination';
import * as types from './types';
import * as models from './interfaces';

export const searchSourceScrubLists = createAction<Partial<models.SearchPayload> | undefined>(
  types.SEARCH_SOURCE_SCRUB_LISTS
);

export const searchSourceScrubListsSuccess = createAction(
  types.SEARCH_SOURCE_SCRUB_LISTS_SUCCESS,
  (sourceScrubLists: models.SourceScrubListsResponse, searchPayload: models.SearchPayload) => ({
    payload: sourceScrubLists,
    meta: searchPayload,
  })
);

export const searchSourceScrubListsFail = createAction<string>(
  types.SEARCH_SOURCE_SCRUB_LISTS_FAIL
);

export const changeSourceScrubPagination = createAction<PageInfo>(
  types.CHANGE_SOURCE_SCRUB_LISTS_PAGINATION
);

export const sortSourceScrub = createAction<SortRule<any>>(types.SORT_SOURCE_SCRUB_LISTS);

export const fetchSourceScrubListsTotal = createAction(types.FETCH_SOURCE_SCRUB_LISTS_TOTAL);
export const fetchSourceScrubListsTotalSuccess = createAction<number>(
  types.FETCH_SOURCE_SCRUB_LISTS_TOTAL_SUCCESS
);
export const fetchSourceScrubListsTotalFail = createAction<string>(
  types.FETCH_SOURCE_SCRUB_LISTS_TOTAL_FAIL
);
