import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { Dictionary, values } from 'lodash';
// models
import { SuccessErrorCallback } from '@optx/models/callback';
// interfaces
import { GeoRangePayload } from './interfaces';
// redux
import { setGeoRange, setGeoRangeSuccess, setGeoRangeFail } from './actions';
// services
import { SearchService } from '../../services/api';
import NotificationService from '../../services/NotificationService';

// Validate georange.
export function* setGeoRangeSaga(
  action: PayloadAction<GeoRangePayload, any, SuccessErrorCallback>
) {
  const { payload } = action;

  try {
    const res: AxiosResponse<Dictionary<string>> = yield call(
      SearchService.getGeoRangeOptions,
      payload
    );

    if (res.data) {
      yield put(setGeoRangeSuccess(res.data));
    } else {
      const errorMessage = 'Failed to set Geo Range!';
      yield put(setGeoRangeFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = `Failed to set Geo Range! - ${values(e.response.data.errors).join(' ')}`;
    yield put(setGeoRangeFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* GeoRangeSaga() {
  yield takeLatest(setGeoRange.type, setGeoRangeSaga);
}
