import { combineReducers } from 'redux';

// models
import listImportReducer from '@optx/features/list-import/state';
import { ListsState } from './interface';
// reducers
import favoriteListsReducer from './lists';
import companyListsReducer from './company-lists';
import listsPopupReducer from './lists-popup';

const reducer = combineReducers<ListsState>({
  lists: favoriteListsReducer,
  listImport: listImportReducer,
  companyLists: companyListsReducer,
  listsPopup: listsPopupReducer,
});

export default reducer;
