import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Space } from 'antd';

import { useInjectEditFields } from '@optx/features/company/edit-fields';
import { useInject as useInjectScroll } from '@features/scroll-history';
//constants
import { HTML } from '@constants/fullScreen';
// redux
import {
  actions as searchContactsActions,
  selectors as searchContactsSelectors,
} from '@redux/contacts/search/search';
import {
  selectors as contactsFiltersSelectors,
  actions as contactsFiltersActions,
} from '@redux/contacts/filters';
import {
  selectors as savedSearchesSelectors,
  actions as savedSearchesActions,
} from '@redux/contacts/saved-searches';
import {
  actions as fullScreenActions,
  selectors as fullscreenSelectors,
} from '@redux/ui/settings/fullscreen';
// components
import ClearSearchButton from '@shared/view/molecules/Buttons/ClearSearchButton';
import { MainHeader, MainContent } from '../../common/layout';
import ContactsTable from './ContactsTable';
import PageLoader from './PageLoader';
import SecondaryHeader from './SecondaryHeader';
import ContactsSearch from './ContactsSearch';
import SaveSearch from './SaveSearch';
import ShowFilterButton from './SecondaryHeader/ShowFilterButton';
import ContactsSearchDropdown from './SecondaryHeader/ContactsSearchDropdown';
import FilterTags from './FilterTags';
import GridFullScreen from '@optx/shared/view/molecules/FullScreen/GridFullScreen';
// hooks
import { useShouldFetch, useInitialFetch } from '../../../common/hooks/fetch';
import {
  useInjectContactsSavedSearches,
  useInjectContactsFilterSources,
  useInjectContactsSearch,
} from '../../../common/hooks/inject';
import { useReduxFullScreen } from '../../../common/hooks/fullscreen';

const useInjectReducerSaga = () => {
  useInjectContactsSearch();
  useInjectContactsFilterSources();
  useInjectContactsSavedSearches();
  useInjectScroll();
  useInjectEditFields();
};

const Contacts: React.FC = () => {
  // inject reducers, sagas.
  useInjectReducerSaga();
  const dispatch = useDispatch();

  // initialize contacts filters if needed
  const shouldInitialFetchFilters = useSelector(contactsFiltersSelectors.shouldInitialFetch);
  const fetchContactsFilters = useCallback(
    () => dispatch(contactsFiltersActions.fetchFilters()),
    [dispatch]
  );
  useInitialFetch(shouldInitialFetchFilters, fetchContactsFilters);

  // initialize saved searches
  const shouldInitialFetchSearches = useSelector(savedSearchesSelectors.shouldInitialFetch);
  const fetchSavedSearches = useCallback(
    () => dispatch(savedSearchesActions.fetchContactSavedSearches()),
    [dispatch]
  );
  useShouldFetch(shouldInitialFetchSearches, fetchSavedSearches);

  // search contacts
  const shouldFetchContacts = useSelector(searchContactsSelectors.shouldInitialFetch);
  const searchContacts = useCallback(
    () => dispatch(searchContactsActions.searchContacts()),
    [dispatch]
  );
  useShouldFetch(shouldFetchContacts, searchContacts);

  // fullscreen functionality
  const toggle = useCallback(() => dispatch(fullScreenActions.toggleFullScreen()), [dispatch]);
  const fullscreen = useSelector(fullscreenSelectors.isFullscreen);

  const { isFullscreen } = useReduxFullScreen({ dom: HTML, fullscreen, toggle });

  const contentClassNames = classNames('main-content has-fixed-header grid-page', {
    fullscreen: isFullscreen,
  });

  const innerContentClassNames = classNames('position-relative', {
    'fullscreen-contacts': isFullscreen,
    'mt-3': !isFullscreen,
  });

  const getAllTags = useSelector(searchContactsSelectors.getTags);

  const tagsLength = useMemo(
    () => getAllTags.filter(item => item.isDefault === false),
    [getAllTags]
  );

  return (
    <>
      <PageLoader />
      <MainHeader className="main-header fixed">
        <div className="search-header-content contacts-header-content">
          <ContactsSearch className="antd-search-input" autoComplete="off" />
          <Space size={4} style={{ marginLeft: 'auto', width: '100%', justifyContent: 'right' }}>
            <FilterTags />
            <ClearSearchButton
              isVisible={tagsLength.length !== 0}
              title="Deselect All"
              onClick={() => dispatch(searchContactsActions.reset(true))}
            />
            <SaveSearch />
            <ShowFilterButton />
            <ContactsSearchDropdown isFullScreen={isFullscreen} />
            {isFullscreen && (
              <>
                <GridFullScreen hideFullScreenIcon />
              </>
            )}
          </Space>
        </div>
      </MainHeader>
      <MainContent className={contentClassNames}>
        <div className={innerContentClassNames}>
          <SecondaryHeader />
          <ContactsTable />
        </div>
      </MainContent>
    </>
  );
};

export default Contacts;
