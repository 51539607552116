import React from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import queryString from 'query-string';

// models
import { SourcingOutreachColumnNamesKey } from '@optx/features/sourcing-outreach-summary/models/SourcingOutreach';
import appRoutes from '@constants/routes';
// utils
import mapFiltersToURLParams from '@optx/utils/filters/mapFiltersToURLParams';
// redux
import { selectors } from '@optx/features/sourcing-outreach-summary/state';
import { actions as sourcingOutReachViewActions } from '@features/grid/view';
import { Styled } from './SourcingOutreachTable.styled';

export const SourcingOutreachTableFooter: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const totalColumnsData = useSelector(selectors.search.totalColumnsData);
  const usersData = useSelector(selectors.search.data);

  const { normalized, filter } = useSelector(selectors.filters.getFilters);
  const query = useSelector(selectors.filters.searchKey);
  const analystFilterData = normalized.analyst_id?.data;

  const queryData = {
    ...mapFiltersToURLParams(normalized, filter),
    ...(query && { outreach_query: query }),
  };

  const payload = queryString.stringify(queryData, {
    arrayFormat: 'comma',
  });

  const handleClick = (columnName: SourcingOutreachColumnNamesKey) => {
    if (totalColumnsData && totalColumnsData[columnName] !== 0) {
      let analystName: string | string[] = 'All';

      if (analystFilterData.length > usersData.length) {
        analystName = usersData.map(user => user.user_name);
      }

      dispatch(sourcingOutReachViewActions.resetViewToSourcingOrder());

      history.push({
        pathname: appRoutes.reports.sourcingOutreachSummaryCompanies,
        state: { column_name: columnName, analyst_name: analystName, filters: payload },
      });
    }
  };

  if (!totalColumnsData) {
    return null;
  }

  return (
    <Styled.TableFooter>
      <Table.Summary.Cell index={0} className="total">
        <span>Total</span>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={1} className="companies_reached">
        <span
          onClick={() => {
            handleClick('companies_reached');
          }}
          role="presentation"
        >
          {totalColumnsData.companies_reached}
        </span>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={2} className="total_dtl">
        <span
          onClick={() => {
            handleClick('total_dtl');
          }}
          role="presentation"
        >
          {totalColumnsData.total_dtl}
        </span>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={3} className="past_due_touches">
        <span
          onClick={() => {
            handleClick('past_due_touches');
          }}
          role="presentation"
        >
          {totalColumnsData.past_due_touches}
        </span>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={4} className="stale_touches">
        <span
          onClick={() => {
            handleClick('stale_touches');
          }}
          role="presentation"
        >
          {totalColumnsData.stale_touches}
        </span>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={5} className="stale_accounts">
        <span
          onClick={() => {
            handleClick('stale_accounts');
          }}
          role="presentation"
        >
          {totalColumnsData.stale_accounts}
        </span>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={6} className="companies_added">
        <span
          onClick={() => {
            handleClick('companies_added');
          }}
          role="presentation"
        >
          {totalColumnsData.companies_added}
        </span>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={7} className="revived_touches">
        <span
          onClick={() => {
            handleClick('revived_touches');
          }}
          role="presentation"
        >
          {totalColumnsData.revived_touches}
        </span>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={8} className="transferred_to_touches">
        <span
          onClick={() => {
            handleClick('transferred_to_touches');
          }}
          role="presentation"
        >
          {totalColumnsData.transferred_to_touches}
        </span>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={9} className="transferred_from_touches">
        <span
          onClick={() => {
            handleClick('transferred_from_touches');
          }}
          role="presentation"
        >
          {totalColumnsData.transferred_from_touches}
        </span>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={10} className="emails_sent">
        <span
          onClick={() => {
            handleClick('emails_sent');
          }}
          role="presentation"
        >
          {totalColumnsData.emails_sent}
        </span>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={11} className="calls">
        <span
          onClick={() => {
            handleClick('calls');
          }}
          role="presentation"
        >
          {totalColumnsData.calls}
        </span>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={12} className="meetings">
        <span
          onClick={() => {
            handleClick('meetings');
          }}
          role="presentation"
        >
          {totalColumnsData.meetings}
        </span>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={13} className="stage_change">
        <span
          onClick={() => {
            handleClick('stage_change');
          }}
          role="presentation"
        >
          {totalColumnsData.stage_change}
        </span>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={14} className="start_qo_touches">
        <span
          onClick={() => {
            handleClick('start_qo_touches');
          }}
          role="presentation"
        >
          {totalColumnsData.start_qo_touches}
        </span>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={15} className="end_qo_touches">
        <span
          onClick={() => {
            handleClick('end_qo_touches');
          }}
          role="presentation"
        >
          {totalColumnsData.end_qo_touches}
        </span>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={16} className="qualified_opportunities_platform">
        <span
          onClick={() => {
            handleClick('qualified_opportunities_platform');
          }}
          role="presentation"
        >
          {totalColumnsData.qualified_opportunities_platform}
        </span>
      </Table.Summary.Cell>
    </Styled.TableFooter>
  );
};
