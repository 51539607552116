/* eslint-disable no-param-reassign */
import { createSelector } from 'reselect';
import { selectors as localSelectors } from '../../bulk-ai-ml';
import { selectFeatureState } from './selectFeature';

const aiMlState = createSelector(selectFeatureState, state => {
  return state.aiMl;
});

const getSuccessCount = createSelector(aiMlState, localSelectors.getSuccessCount);

export default { getSuccessCount };
