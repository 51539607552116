import styled, { css } from 'styled-components';

const Wrapper = styled.div<{ hidden?: boolean }>`
  width: 100%;
  height: 80px;
  box-sizing: border-box;

  ${props =>
    props.hidden &&
    css`
      visibility: hidden;
      width: 0;
      height: 0;
    `}
`;

export const Styled = {
  Wrapper,
};
