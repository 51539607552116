import React from 'react';
import { useSelector } from 'react-redux';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
// components

import Completed from './Completed';
import BulkEditingResults from './BulkEditingResults';
import InProgress from './InProgress';

const FinalStep: React.FC = () => {
  const completed = useSelector(bulkActionsSelectors.salesloft.isCompleted);

  return (
    <>
      {completed ? <Completed /> : <InProgress />}
      <BulkEditingResults />
    </>
  );
};

export default FinalStep;
