import { createReducer, CaseReducer, PayloadAction, Dictionary } from '@reduxjs/toolkit';
// models
import { UserInformation } from '@models/user';
import { Filter, FilterSource, PreselectedFilter } from '@models/filters';
import { SearchSaveResponse } from '@models/search';
import { FilterTag } from '@optx/models/tags';
import {
  UpdateFieldsPayload,
  EditFieldSuccess,
} from '@optx/features/company/edit-fields/state/interfaces';
import { CompanyUserContact } from '@optx/models/api/contacts';
import {
  ContactsSearchState,
  SearchContactsPayload,
  ResetSearchPayload,
  SearchSuccessPayload,
} from '@redux/contacts/search/search/interfaces';
import { GridPayload } from '@models/grid';
import { PageInfo } from '@models/table/Pagination';
// utils
import { getContactId } from '@optx/utils/contact';
// redux
import { actions as primaryContactsActions } from '@redux/company/primary-contacts';
import { actions as editFieldsActions } from '@optx/features/company/edit-fields/state';
import { types as userInformationTypes } from '../../../user/information';
import * as actions from './actions';
import { actions as sortActions } from '../sort';
import { saveContactSearchSuccess } from '../../saved-searches/actions';
import { fetchReducer, fetchFailReducer } from '../../../feature/fetch/reducers';
import { actions as paginationActions } from '@features/grid/pagination';

/**
 * Contacts initial state.
 */
export const initialState: ContactsSearchState = {
  loading: false,
  error: '',
  fetchedAt: '',
  byId: {},
  allIds: [],
  pagination: {
    pageNumber: 1,
    pageSize: 50,
  },
  defaultPageSize: 50,
  searchTitle: '',
};

// search
const searchReducer: CaseReducer<
  ContactsSearchState,
  PayloadAction<Partial<SearchContactsPayload> | undefined>
> = (draftState, action) => {
  fetchReducer(draftState);
};

const searchSuccessReducer: CaseReducer<
  ContactsSearchState,
  PayloadAction<SearchSuccessPayload>
> = (draftState, action) => {
  draftState.loading = false;
  // reset contacts
  draftState.allIds = [];
  draftState.byId = {};
  // update with new data
  action.payload.data.forEach(contact => {
    const id = getContactId(contact);
    draftState.allIds.push(id);
    draftState.byId[id] = contact;
  });
  draftState.searchTitle = action.payload.searchTitle;

  draftState.fetchedAt = new Date().toISOString();
};

// sort
const changeSortReducer: CaseReducer<ContactsSearchState> = draftState => {
  fetchReducer(draftState);
};

// reset
const resetReducer: CaseReducer<ContactsSearchState, PayloadAction<ResetSearchPayload>> = (
  draftState,
  action
) => {
  const { searchTitle } = action.payload;
  draftState.searchTitle = searchTitle;

  fetchReducer(draftState);
};

// external reducers
const fetchUserInformationSuccessReducer: CaseReducer<
  ContactsSearchState,
  PayloadAction<UserInformation, typeof userInformationTypes.FETCH_USER_INFORMATION_SUCCESS>
> = (draftState, action) => {
  const userInfo = action.payload;

  if (userInfo.settings.contact_results_per_page) {
    draftState.pagination.pageSize = userInfo.settings.contact_results_per_page;
  }

  if (userInfo.settings.session_settings?.contact_results_page_number) {
    draftState.pagination.pageNumber =
      userInfo.settings.session_settings.contact_results_page_number;
  }
};

const saveSearchSuccessReducer: CaseReducer<
  ContactsSearchState,
  PayloadAction<
    SearchSaveResponse,
    any,
    {
      filterSources: Array<FilterSource<Filter<any>>>;
      clearedFilters: Dictionary<PreselectedFilter>;
    }
  >
> = (draftState, action) => {
  draftState.searchTitle = action.payload.title;
};

const removeFilterReducer: CaseReducer<ContactsSearchState, PayloadAction<FilterTag>> = (
  draftState,
  action
) => {
  draftState.loading = true;
};

// edit contacts fields

const editUserInformationSuccessReducer: CaseReducer<
  ContactsSearchState,
  PayloadAction<EditFieldSuccess>
> = (draftState, action) => {
  const { fieldName, value, companyId } = action.payload;
  const personId = (value as UpdateFieldsPayload).companyId;

  const user: CompanyUserContact | undefined = draftState.byId[`${personId}-${companyId}`];
  const fieldKeyValue = (value as UpdateFieldsPayload).value;

  if (user && fieldName === 'Linkedin Profile') {
    draftState.byId[`${personId}-${companyId}`].linkedin = fieldKeyValue as never;
  }
};

const updateLinkedinURLSuccessReducer: CaseReducer<
  ContactsSearchState,
  PayloadAction<Partial<CompanyUserContact>>
> = (draftState, action) => {
  const { linkedin, person_id: personId, company_id: companyId } = action.payload;

  const user: CompanyUserContact | undefined = draftState.byId[`${personId}-${companyId}`];

  if (user) {
    draftState.byId[`${personId}-${companyId}`].linkedin = linkedin as string;
  }
};

const changePaginationReducer: CaseReducer<
  ContactsSearchState,
  PayloadAction<GridPayload<PageInfo>>
> = (draftState, action) => {
  const { gridKey } = action.payload;

  if (gridKey === 'contacts') fetchReducer(draftState);
};

export const reducer = createReducer<ContactsSearchState>(initialState, builder =>
  builder
    // search
    .addCase(actions.searchContacts, searchReducer)
    .addCase(actions.searchContactsSuccess, searchSuccessReducer)
    .addCase(actions.searchContactsFail, fetchFailReducer)
    // reset all
    .addCase(actions.reset, resetReducer)
    // external reducers
    .addCase(paginationActions.changePagination, changePaginationReducer)
    // user information
    .addCase(
      userInformationTypes.FETCH_USER_INFORMATION_SUCCESS,
      fetchUserInformationSuccessReducer
    )
    .addCase(sortActions.changeSortAction, changeSortReducer)
    .addCase(saveContactSearchSuccess, saveSearchSuccessReducer)
    .addCase(actions.removeFilter, removeFilterReducer)
    .addCase(editFieldsActions.updateFieldsSuccess, editUserInformationSuccessReducer)
    .addCase(primaryContactsActions.editPrimaryContactSuccess, updateLinkedinURLSuccessReducer)
);

export default reducer;
