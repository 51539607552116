import React from 'react';

interface ILayoutContext {
  menuCollapsed: boolean;
}

const initialValue: ILayoutContext = {
  menuCollapsed: false,
};

export const LayoutContext = React.createContext<ILayoutContext>(initialValue);
