import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { Row, Col, Typography } from 'antd';
import { Checkbox } from 'formik-antd';
import { useField } from 'formik';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
// models
import { MultiCheckboxFilter } from '@models/filters';
import { FormCheckboxOption, SelectOption } from '@models/Option';
// components
import SearchFilterCard from './SearchFilterCard';
import { FiltersContext } from '../FiltersContext';

interface FilterCheckboxProps {
  filter: MultiCheckboxFilter;
  isFullRow?: boolean;
}

const FilterCheckboxAsRadio: React.FC<FilterCheckboxProps> = ({ filter, isFullRow = false }) => {
  const touchKey = filter.column.split('_')[0];
  const isPossibilityToUnselectRef = useRef(false);

  const [field, , fieldHelpers] = useField<Array<FormCheckboxOption>>(filter.column);
  const [touchField] = useField<Array<SelectOption> | undefined>(`${touchKey}_touch`);
  const [touchTypeField] = useField<Array<FormCheckboxOption>>(`${touchKey}_touch_type`);
  const [touchTypeDateField] = useField<Array<FormCheckboxOption>>(`${touchKey}_touch_type_date`);
  const [touchDateField] = useField<Array<SelectOption>>(`${touchKey}_touch_date`);

  const isTouchSelected = useMemo(() => {
    return !!touchField.value?.length;
  }, [touchField.value]);

  const isTouchTypeChecked = useMemo(() => {
    return touchTypeField.value.some(option => option.checked);
  }, [touchTypeField.value]);

  const isTouchTypeDateChecked = useMemo(() => {
    return touchTypeDateField.value.some(option => option.checked);
  }, [touchTypeDateField.value]);

  const isTouchDateFieldSelected = useMemo(() => {
    return touchDateField.value.some(option => option.value);
  }, [touchDateField.value]);

  const [shouldBeDefaultValue, setShouldBeDefaultValue] = useState(false);

  const { onManualFilterChange } = useContext(FiltersContext);

  useEffect(() => {
    const isOptionChecked = field.value.some(option => option.checked);

    if (shouldBeDefaultValue && !isOptionChecked) {
      let fieldValue = field.value.map(option => {
        if (option.value === '0') {
          return {
            ...option,
            checked: true,
          };
        }

        return option;
      });

      fieldHelpers.setValue(fieldValue);
      setShouldBeDefaultValue(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldBeDefaultValue]);

  useEffect(() => {
    if (
      isTouchSelected ||
      isTouchTypeChecked ||
      isTouchTypeDateChecked ||
      isTouchDateFieldSelected
    ) {
      isPossibilityToUnselectRef.current = false;
    } else {
      isPossibilityToUnselectRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isSelection =
      isTouchSelected || isTouchTypeChecked || isTouchTypeDateChecked || isTouchDateFieldSelected;

    isPossibilityToUnselectRef.current = !isSelection;
    setShouldBeDefaultValue(isSelection);
  }, [isTouchSelected, isTouchTypeChecked, isTouchTypeDateChecked, isTouchDateFieldSelected]);

  const onChangeAsRadio = (event: CheckboxChangeEvent) => {
    const eventTargetIndex = parseInt(event.target.name?.split('[')[1]?.split(']')[0] as string);
    const previousEventTargetValue = field.value[eventTargetIndex].checked;
    const shouldBeChanged = !previousEventTargetValue && event.target.checked;
    let fieldValue = field.value;

    if (isPossibilityToUnselectRef.current) {
      fieldValue = fieldValue.map((option: FormCheckboxOption, index: number) => {
        if (index === eventTargetIndex) {
          return {
            ...option,
            checked: event.target.checked,
          };
        } else {
          return {
            ...option,
            checked: false,
          };
        }
      });
    }

    if (shouldBeChanged && !isPossibilityToUnselectRef.current) {
      fieldValue = fieldValue.map((option: FormCheckboxOption, index: number) => {
        if (index === eventTargetIndex) {
          return {
            ...option,
            checked: true,
          };
        } else {
          return {
            ...option,
            checked: false,
          };
        }
      });
    }

    fieldHelpers.setValue(fieldValue);

    if (shouldBeChanged) {
      // Delay onChange for the next tick in order to allow form values update.
      setTimeout(() => {
        onManualFilterChange && onManualFilterChange(filter.column);
      }, 0);
    }
  };

  return (
    <SearchFilterCard label={filter.label}>
      <Row>
        {field.value.map((option: FormCheckboxOption, index) => (
          <Col key={index} span={isFullRow ? 24 : 12}>
            <Checkbox
              className="no-wrap-checkbox"
              name={`${filter.column}[${index}].checked`}
              fast
              disabled={option.disabled}
              onChange={onChangeAsRadio}
            >
              <Typography.Text title={option.label} ellipsis>
                {option.label}
              </Typography.Text>
            </Checkbox>
          </Col>
        ))}
      </Row>
    </SearchFilterCard>
  );
};

export default React.memo(FilterCheckboxAsRadio);
