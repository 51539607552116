import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Pagination } from '@shared/view/molecules/Pagination/Pagination';
// constants
import { isFirefox } from '@optx/constants/os/browser';
// redux
import styled from 'styled-components';
import { actions, selectors } from '../state';

interface Props {
  isInEt?: boolean;
}

const widthBasedOnBrowser = isFirefox ? 'calc(101% - 15px)' : 'calc(100% - 20px)';

const PaginationWrapper = styled.div<Props>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  height: 50px;
  background: #fff;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 15%);
  border-radius: 2px;

  @media (min-width: 1700px) {
    width: ${props => (props.isInEt ? widthBasedOnBrowser : '100%')};
  }
`;

export const CompaniesInETPagination = () => {
  const dispatch = useDispatch();
  const { pageNumber, pageSize } = useSelector(selectors.pagination.pagination);
  const totalItemsCount = useSelector(selectors.pagination.total);

  const onPageChange = (page: number, perPage: number = pageSize) =>
    dispatch(actions.pagination.changePage({ pageNumber: page, pageSize: perPage }));

  return (
    <PaginationWrapper isInEt>
      <Pagination
        activePage={pageNumber}
        itemsCountPerPage={pageSize}
        totalItemsCount={totalItemsCount}
        onChange={onPageChange}
      />
    </PaginationWrapper>
  );
};

export default CompaniesInETPagination;
