import { useSelector, useDispatch } from 'react-redux';
// helpers
import { convertPayloadImport } from '@utils/helpers';
// redux
import { actions as listImportActions } from '@optx/features/list-import/state';
import { selectors as modalSelectors } from '@redux/ui/modals/my-watchlist-csv-error';

const useImportListsFavorites = () => {
  const dispatch = useDispatch();
  const displayCSVErrorModal = useSelector(modalSelectors.isOpen);

  const handleImportListFavorites = (title: string, dataImport: Array<{}>) => {
    const { data } = convertPayloadImport(dataImport);

    dispatch(listImportActions.setLoading(true));
    dispatch(listImportActions.updateImportCompanies({ data, title }));
    dispatch(listImportActions.importFavoriteList());
  };

  return {
    handleImportListFavorites,
    displayCSVErrorModal,
  };
};

export default useImportListsFavorites;
