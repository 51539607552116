import React from 'react';
import { Menu } from 'antd';
import Popconfirm from '@optx/shared/view/molecules/Popconfirm/Popconfirm';

interface ActionleteMenuItemProps {
  onConfirm?: () => void;
  onCancel?: () => void;
  type?: string;
  title: string;
  action: 'Delete' | 'Remove';
}

const ActionMenuItem: React.FC<ActionleteMenuItemProps> = ({
  onConfirm,
  onCancel,
  title,
  action,
  ...restProps
}) => (
  <Popconfirm
    title={title}
    onConfirm={() => onConfirm && onConfirm()}
    onCancel={() => onCancel && onCancel()}
    action={action}
  >
    {/* prevent dropdown from closing when clicking on this menu item */}
    <Menu.Item {...restProps} onClick={e => e.domEvent.stopPropagation()}>
      {action}
    </Menu.Item>
  </Popconfirm>
);

export default ActionMenuItem;
