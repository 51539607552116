import React from 'react';
import { Popover } from 'antd';

import { FilterTagValue } from '@optx/models/tags';
import { FILTER_TAGS_MAX_COUNT } from '@optx/constants/tags';

interface TagsWithPopoverProps {
  filterTagValues: FilterTagValue[];
  maxCount?: number;
}

const TagsWithPopover: React.FC<TagsWithPopoverProps> = ({
  filterTagValues,
  maxCount = FILTER_TAGS_MAX_COUNT,
}) => {
  const filteredValues = filterTagValues.filter(option => !option.isDefault);
  const displayedValues = filteredValues.splice(0, maxCount);

  if (maxCount >= filterTagValues.length) {
    return (
      <span>
        {filterTagValues
          .filter(option => !option.isDefault)
          .map(option => option.value)
          .join(', ')}
      </span>
    );
  }

  return (
    <span>
      {displayedValues.map(option => option.value).join(', ')}
      {', '}
      <Popover
        className="label-popover"
        content={filteredValues.map(option => option.value).join(', ')}
      >
        {filteredValues.length} more
      </Popover>
    </span>
  );
};

export default TagsWithPopover;
