import React from 'react';
import { Tooltip, Typography } from 'antd';
import { TableCellSearches } from '@optx/models/table/Cell';
import useSugestedSearches from '@optx/common/hooks/saved-searches/useSugestedSearches';

const TableCellSearch: React.FC<TableCellSearches> = ({ value, row }) => {
  const { handleSearchResults } = useSugestedSearches();

  const handleClick = () => {
    if (row.unique_id) {
      handleSearchResults(row.title);
    }
  };

  return (
    <div role="presentation" onClick={handleClick}>
      <Typography.Text style={{ maxWidth: '100%' }} ellipsis>
        <Tooltip
          className="keep-space-sequence"
          overlayClassName="keep-space-sequence"
          title={value}
        >
          {value}
        </Tooltip>
      </Typography.Text>
    </div>
  );
};

export default TableCellSearch;
