import React from 'react';
import { useSelector } from 'react-redux';
//models
import Company from '@optx/models/Company';
import { FieldOptions, ServiceType } from '@optx/features/company/edit-fields/state/interfaces';
import { FinancialFields } from '@optx/models/Financial';
//constants
import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';
//redux
import { getFinancialFilterOptions } from '@optx/redux/company/filters/selectors';
//components
import { EditFieldFinancial } from '@optx/features/company/edit-fields/components';

interface TableCellEditFinancialFieldProps {
  company: Company;
  fieldKey: 'revenue_list' | 'ebitda_list' | 'annual_recurring_revenue' | 'gross_margin_list';
  filterName: FinancialFields;
  service: ServiceType;
  isFromGrid: boolean;
  extraKeys?: string[];
  fieldName: FieldOptions;
  customFinancialYear?: number;
}

export const TableCellEditFinancialField: React.FC<TableCellEditFinancialFieldProps> = ({
  company,
  fieldKey,
  fieldName,
  service,
  filterName,
  isFromGrid,
  customFinancialYear,
}) => {
  const financialOptions = useSelector(getFinancialFilterOptions(filterName));

  const handleFieldListValues = () => {
    if ((fieldName === 'ARR' || fieldName === 'GM %') && company[fieldKey]) {
      const fieldListValues = company[fieldKey]?.filter(
        item => item.value !== '' && item.value !== DEFAULT_CELL_VALUE
      );

      return fieldListValues || null;
    }

    return company[fieldKey];
  };

  return (
    <EditFieldFinancial
      companyId={company.company_id}
      companyUrl={company.company_url}
      fieldListValues={handleFieldListValues()}
      fieldKey={fieldKey}
      options={financialOptions}
      service={service}
      fieldName={fieldName}
      isFromGrid={isFromGrid}
      customFinancialYear={customFinancialYear}
    />
  );
};

export default React.memo(TableCellEditFinancialField);
