import { Tag } from 'antd';
// components
import Icon from '@components/common/Icon';

const PoweredByAITag = () => {
  return (
    <Tag className="powered-by-ai-indicator">
      <Icon iconName="artificial-intelligence" style={{ fontSize: 16 }} />
      <span className="powered-by-ai-tag">Powered by AI</span>
    </Tag>
  );
};

export default PoweredByAITag;
