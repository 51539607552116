import React from 'react';
// components
import SearchInput, {
  SearchInputProps,
} from '../../../../components/common/form/input/SearchInput';

interface CompanySearchProps extends Omit<SearchInputProps, 'onSearch' | 'value'> {}

const ScheduledTouchesSearch: React.FC<CompanySearchProps> = ({ ...restProps }) => {
  return <SearchInput {...restProps} placeholder="Search for companies, notes, ect." />;
};

export default ScheduledTouchesSearch;
