import { createSelector } from 'reselect';
import { GridKeys } from '@optx/models/grid';
import { selectFeatureState } from '../../selectFeature';

export const getView = (gridKey: GridKeys) => {
  return createSelector(selectFeatureState, state => state.view[gridKey]);
};

export const getViewId = (gridKey: GridKeys) => {
  return createSelector(selectFeatureState, state => state.view[gridKey]?.id);
};

export const getViewLabel = (gridKey: GridKeys) => {
  return createSelector(selectFeatureState, state => state.view[gridKey].label);
};
