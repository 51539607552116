import { Middleware } from 'redux';
import { PayloadAction } from '@reduxjs/toolkit';
// models
import { AppState } from '@optx/redux/interfaces';
// services
import NotificationService from '@services/NotificationService';
// redux
import { NOTIFY } from './types';
import { INotifyMeta, INotifyPayload } from './interfaces';

// {} legacy type parameter added to satisfy interface signature
const notificationMiddleware: Middleware<{}, AppState> = store => next => action => {
  if (action.type === NOTIFY) {
    const { payload, meta } = action as PayloadAction<INotifyPayload, any, INotifyMeta>;
    const { type, message } = payload;
    const { error, options } = meta;

    NotificationService[type](message, options);

    if (process.env.NODE_ENV === 'development' && error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  } else {
    next(action);
  }
};

export default notificationMiddleware;
