import { call, put } from 'redux-saga/effects';
import { InitialFavoriteListsSearchData } from '@models/lists/search';
// services
import { SavedSearchesService } from '@services/api';
import * as actions from './actions';

// search
export function* searchLists(data: InitialFavoriteListsSearchData) {
  // @ts-ignore
  const res = yield call(SavedSearchesService.initialFetchFavoriteLists, data);

  yield put(
    actions.fetchFavoriteListsPopupSuccess({
      data: res.data.lists,
      ...data,
      showEmptyLists: data.showEmptyLists || false,
    })
  );
}
