import { ViewOption } from '@optx/models/search';
import { createAction } from '@reduxjs/toolkit';
import * as types from './types';
import { GridPayload } from '@optx/models/grid';

export const applyView = createAction<GridPayload<ViewOption>>(types.APPLY_VIEW);

export const applyViewSuccess = createAction<ViewOption>(types.APPLY_VIEW_SUCCESS);

export const resetViewToSourcingOrder = createAction(types.RESET_VIEW_TO_SOURCING_ORDER);
