import { createSelector } from 'reselect';
// models
import { AppState } from '@redux/interfaces';
import {
  ProfileEditPrimaryContact,
  ProfileEditPrimaryContactWithSelectOptions,
} from '@models/Company';
// constants
import { PRESENT_DATE_FORMAT } from '@optx/constants/format/date';
// utils
import { getPhoneNumbers, mapToSelectOptions } from '@utils/contact';
import { selectors as companyIndividualSelectors } from '@components/feature/company-individual-edit/state';

const primaryContactsState = (state: AppState) => state.primaryContacts;

export const isLoading = createSelector(primaryContactsState, state => state.loading);
export const getError = createSelector(primaryContactsState, state => state.error);
export const getPrimaryContacts = createSelector(primaryContactsState, state => state.data || []);
export const getEditedContact = createSelector(primaryContactsState, state => state.editedContact);

function getNames(fullName: string) {
  const names = fullName.split(' ');
  const firstName = names[0];
  const lastName = names.splice(1).join(' ');

  return {
    firstName,
    lastName,
  };
}

// @ts-ignore
export const getEditContactInitialValues = createSelector(
  getEditedContact,
  companyIndividualSelectors.companyIndividualEdit.phoneOptions,
  // @ts-ignore
  (editedContact, phoneOptions) => {
    if (!editedContact) {
      return {
        first_name: '',
        last_name: '',
        email: '',
        emails: [],
        additional_phones: [],
        title: '',
        tenure_start_date: '',
        tenure_end_date: '',
        linkedin: '',
        primary_contact: false,
        psg_function: [],
        slack_channel: [],
        portfolio_executive: false,
      } as ProfileEditPrimaryContact;
    }

    const data: ProfileEditPrimaryContactWithSelectOptions = {
      first_name: editedContact.first_name,
      last_name: editedContact.last_name,
      email: editedContact.email,
      emails: editedContact.emails,
      additional_phones: getPhoneNumbers(editedContact, phoneOptions),
      title: editedContact.title,
      tenure_start_date: editedContact.tenure_start_date,
      tenure_end_date:
        editedContact.tenure_end_date !== PRESENT_DATE_FORMAT
          ? editedContact.tenure_end_date
          : null,
      linkedin: editedContact.linkedin,
      primary_contact: !(
        // to update when api will send a boolean instead of a string
        // @ts-ignore
        (editedContact.primary_contact === 'False' || editedContact.primary_contact === false)
      ),
      psg_function: mapToSelectOptions(editedContact?.psg_function as string[]),
      slack_channel: mapToSelectOptions(editedContact?.slack_channel as string[]),
      portfolio_executive: editedContact.portfolio_executive,
    };

    if (!editedContact.first_name || !editedContact.last_name) {
      const names = getNames(editedContact.full_name);

      data.first_name = names.firstName;
      data.last_name = names.lastName;
    }

    return data;
  }
);
