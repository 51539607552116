import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer } from 'redux-injectors';
import { useCallback } from 'react';
import {
  actions as displayColumnModalActions,
  selectors as displayColumnModalSelectors,
} from '../../../redux/ui/modals/display-column';
import uiReducer from '../../../redux/ui';

const useDisplayColumnModal = (): [boolean, VoidFunction] => {
  useInjectReducer({
    key: 'ui',
    reducer: uiReducer,
  });
  const open = useSelector(displayColumnModalSelectors.displayDialog);
  const dispatch = useDispatch();
  const toggle = useCallback(
    () => dispatch(displayColumnModalActions.toggleDisplayColumnDialog()),
    [dispatch]
  );

  return [open, toggle];
};

export default useDisplayColumnModal;
