import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isEqual } from 'lodash';
// models
import { EquityTouchContactForm, EquityTouchDatabaseMatch } from '@models/equityTouch';
import { CompanyUserContact, ContactEmails } from '@models/api/contacts';
// constants
import { EQT_CONTACT_FORM_INITIAL_STATE } from '@constants/equitytouch';
import { PRESENT_DATE_FORMAT, ISO_DATE_FORMAT } from '@optx/constants/format/date';
// utils
import { mapPhoneNumbers, getPhoneNumbers, mapToSelectOptions } from '@utils/contact';
import { getEmailsFromString } from '@utils/equityTouch';
// redux
import { actions as equityTouchActions } from '@redux/company/equity-touch';
import { actions as bulkEqtActions } from '@features/bulk-actions/equity-touch';
import { selectors as companyIndividualEditSelectors } from '@components/feature/company-individual-edit/state';
// components
import { SelectOption } from '@optx/models/Option';
import { ContactForm } from '@optx/features/contact-form';

interface EquityTouchEditContactProps {
  visible: boolean;
  contactData: Partial<CompanyUserContact> | undefined;
  onCancel: () => void;
}

export const EquityTouchEditContact: React.FC<EquityTouchEditContactProps> = ({
  visible,
  contactData,
  onCancel,
}) => {
  const isAddEquityTouchBulkAction = window.location.href.includes('equity-touch-bulk');

  const dispatch = useDispatch();
  const individualEditOptions = useSelector(
    companyIndividualEditSelectors.companyIndividualEdit.getAllOptions
  );
  const selectOptions = useSelector(
    companyIndividualEditSelectors.companyIndividualEdit.phoneOptions
  );
  let initialValues: EquityTouchContactForm = EQT_CONTACT_FORM_INITIAL_STATE;

  if (contactData) {
    initialValues = {
      first_name: contactData.first_name,
      last_name: contactData.last_name,
      title: contactData.title,
      additional_phones: getPhoneNumbers(contactData, selectOptions),
      tenure_start_date: contactData.tenure_start_date,
      tenure_end_date:
        contactData.tenure_end_date === PRESENT_DATE_FORMAT ? null : contactData.tenure_end_date,
      linkedin: contactData.linkedin,
      primary_contact: contactData.primary_contact || false,
      emails: getEmailsFromString(contactData.email),
      email: null,
      psg_function: mapToSelectOptions(contactData?.psg_function as string[]),
      slack_channel: mapToSelectOptions(contactData?.slack_channel as string[]),
      portfolio_executive: contactData.portfolio_executive,
    };
  }

  const handleSubmit = (values: Partial<EquityTouchContactForm>) => {
    const formValues = { ...values };
    const emailList = formValues.emails as ContactEmails[];
    const { psg_function: psgFunction, slack_channel: slackChannel } = values;
    // .map() gives an error if used on a variable with multiple types, fixed with any[]
    const psgValues = psgFunction
      ? (psgFunction as any[]).map((item: SelectOption<string> | string) => {
          if (typeof item === 'string') {
            return item;
          }

          return item.value;
        })
      : [];
    // .map() gives an error if used on a variable with multiple types, fixed with any[]
    const slackChannelValues = slackChannel
      ? (slackChannel as any[])?.map((item: SelectOption<string> | string) => {
          if (typeof item === 'string') {
            return item;
          }

          return item.value;
        })
      : [];

    const newContact = {
      ...formValues,
      psg_function: psgValues ? (psgValues as Array<string>) : [],
      slack_channel: psgValues ? (slackChannelValues as Array<string>) : [],
      email: emailList
        .filter(value => value.email !== '')
        .map(val => val.email)
        .join(','),
      person_id: contactData?.person_id as number,
      company_id: contactData?.company_id,
      tenure_start_date: formValues.tenure_start_date
        ? moment(formValues.tenure_start_date).format(ISO_DATE_FORMAT)
        : null,
      tenure_end_date:
        formValues.tenure_end_date && formValues.tenure_end_date !== PRESENT_DATE_FORMAT
          ? moment(formValues.tenure_end_date).format(ISO_DATE_FORMAT)
          : formValues.tenure_end_date === PRESENT_DATE_FORMAT
          ? formValues.tenure_end_date
          : null,
      is_edited: true,
      phones: mapPhoneNumbers(
        formValues,
        individualEditOptions?.person_phone_type.received_values as EquityTouchDatabaseMatch[]
      ),
      additional_phones: undefined,
    };

    if (!isEqual(initialValues, values)) {
      dispatch(
        equityTouchActions.saveContact(newContact, () => {
          onCancel();
        })
      );

      if (isAddEquityTouchBulkAction) {
        dispatch(bulkEqtActions.updateBulkContactSuccess(newContact));
      }
    }
  };

  return (
    <ContactForm
      type="edit"
      visible={visible}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    />
  );
};
