import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { UserService, CompanyService } from '@services/api';
import NotificationService from '@services/NotificationService';
import { getErrorMessage } from '@utils/api/errors';
import { AxiosResponse } from 'axios';
import { AddTagPayload, DeleteTagPayload, SuccessCompanyTag } from './interfaces';
import * as actions from './actions';

export function* addTagSaga(action: PayloadAction<AddTagPayload>) {
  try {
    const res: AxiosResponse<SuccessCompanyTag> = yield call(
      UserService.addCompanyTag,
      action.payload
    );

    if (res.data) {
      yield put(actions.addCompanyTagSuccess({ ...res.data, companyId: action.payload.companyId }));
      NotificationService.success('Add tag success!');
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to add tag!');
    NotificationService.error(errorMessage);
  }
}

export function* deleteTagSaga(action: PayloadAction<DeleteTagPayload>) {
  const { id, companyId } = action.payload;

  try {
    yield call(CompanyService.deleteTag, id as number, companyId);
    yield put(actions.deleteCompanyTagSuccess(action.payload));
    NotificationService.success('Delete tag success!');
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to delete tag!');
    NotificationService.error(errorMessage);
  }
}

export default function* tagsSaga() {
  yield takeLatest(actions.addCompanyTag, addTagSaga);
  yield takeLatest(actions.deleteCompanyTag, deleteTagSaga);
}
