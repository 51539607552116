import { createReducer, PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { SortRule } from '@models/table/sorting';
// models
import { SortState } from '@redux/contacts/search/sort/interfaces';
// constants
import { CONTACT_DEFAULT_SORT } from '@constants/table/sort/defaultSort';
// redux
import { actions as userInformationActions } from '@redux/user/information';
import { UserInformation } from '@optx/models/user';
import { parseSorting } from '@optx/utils/filters/parseSorting';
import { multiSortAction, changeSortAction } from './actions';
import { actions as searchActions } from '../search';

const initialState: SortState = {
  multiSort: false,
  sortBy: CONTACT_DEFAULT_SORT,
};

export const changeSortReducer: CaseReducer<SortState, PayloadAction<SortRule<any>>> = (
  draftState,
  action
) => {
  draftState.sortBy = action.payload.sortBy;
};

export const createMultiSortReducer =
  (initState = initialState): CaseReducer<SortState, PayloadAction<boolean>> =>
  (draftState, action) => {
    const multiSort = action.payload;
    draftState.multiSort = multiSort;

    if (!multiSort) {
      draftState.sortBy = initState.sortBy;
    }
  };

export const fetchUserInformationSuccessReducer: CaseReducer<
  SortState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const sorting = action.payload.settings.session_settings?.contact_sorting;

  if (sorting) {
    const { sortBy, isMultiSort } = parseSorting(sorting);
    draftState.sortBy = sortBy;
    draftState.multiSort = isMultiSort;
  }
};

const multiSortReducer = createMultiSortReducer(initialState);

export const resetReducer: CaseReducer<SortState> = () => initialState;

const reducer = createReducer<SortState>(initialState, builder =>
  builder
    .addCase(multiSortAction, multiSortReducer)
    .addCase(changeSortAction, changeSortReducer)
    .addCase(searchActions.reset, resetReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
);

export default reducer;
