import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import AuthReducer, { saga as AuthSaga } from '@redux/auth';
import saga from '@redux/login/saga';

const useInjectLogin = () => {
  useInjectReducer({
    key: 'auth',
    reducer: AuthReducer,
  });
  useInjectSaga({
    key: 'auth',
    saga: AuthSaga,
  });
  useInjectSaga({
    key: 'login',
    saga,
  });
};

export default useInjectLogin;
