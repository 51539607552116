import styled from 'styled-components';

const CellPopover = styled.div`
  min-width: 300px;
  .singleselect-wrapper {
    min-width: 300px;
  }
`;

export default {
  CellPopover,
};
