export const FETCH_FAVORITES = 'favorites/FETCH_FAVORITES';
export const FETCH_FAVORITES_SUCCESS = 'favorites/FETCH_FAVORITES_SUCCESS';
export const FETCH_FAVORITES_FAIL = 'favorites/FETCH_FAVORITES_FAIL';

export const ADD_FAVORITE = 'favorites/ADD_FAVORITE';
export const ADD_FAVORITE_SUCCESS = 'favorites/ADD_FAVORITE_SUCCESS';
export const ADD_FAVORITE_FAIL = 'favorites/ADD_FAVORITE_FAIL';

export const DELETE_FAVORITE = 'favorites/DELETE_FAVORITE';
export const DELETE_FAVORITE_SUCCESS = 'favorites/DELETE_FAVORITE_SUCCESS';
export const DELETE_FAVORITE_FAIL = 'favorites/DELETE_FAVORITE_FAIL';
