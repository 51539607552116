import { createAction } from '@reduxjs/toolkit';
// models
import { UploadFile } from 'antd/lib/upload/interface';
import { EncodedFile, SuccessResponse, UploadFilesPayload } from './interfaces';
// constants
import {
  REMOVE_FILE_ENCODING,
  TOGGLE_DOCUMENT_UPLOAD_MODAL,
  UPDATE_FILE_ENCODING_LIST,
  UPDATE_FILE_LIST,
  UPLOAD_FILES,
  UPLOAD_FILES_ERROR,
  UPLOAD_FILES_SUCCESS,
  FAILED_UPLOAD_FILES,
} from './types';

export const toggleModal = createAction(TOGGLE_DOCUMENT_UPLOAD_MODAL);

export const updateFileList = createAction<UploadFile[]>(UPDATE_FILE_LIST);
export const updateFileEncodingList = createAction<EncodedFile>(UPDATE_FILE_ENCODING_LIST);
export const removeFileEncoding = createAction<string>(REMOVE_FILE_ENCODING);

export const uploadFiles = createAction<UploadFilesPayload>(UPLOAD_FILES);
export const uploadFilesSuccess = createAction<SuccessResponse[]>(UPLOAD_FILES_SUCCESS);
export const uploadFilesError = createAction<string>(UPLOAD_FILES_ERROR);

export const failedUploadFiles = createAction(FAILED_UPLOAD_FILES);
