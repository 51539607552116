import { useCallback } from 'react';
import { NotesSearchQuery } from '@optx/redux/company/notes/search/interfaces';
import { useDispatch } from 'react-redux';
import { actions as notesSearchActions } from '../../../redux/company/notes/search';

export const useFetchNotes = () => {
  const dispatch = useDispatch();
  const fetchNotes = useCallback(
    (payload?: NotesSearchQuery) => dispatch(notesSearchActions.searchNotes(payload)),
    [dispatch]
  );

  return fetchNotes;
};
