import React, {
  useCallback,
  useState,
  ReactText,
  useRef,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pick, isEmpty } from 'lodash';
import { useVT } from 'virtualizedtableforantd4';
import { useSize } from '@umijs/hooks';
// models
import Company from '@optx/models/Company';
import { SortByRule } from '@optx/models/table/sorting';
import { TableChangeCallback } from '@optx/models/table/antd-table';
import { SelectOption } from '@optx/models/Option';
// constants
import routes from '@constants/routes';
import { BULK_ACTIONS_FIELDS } from '@optx/constants/equitytouch';
import { COMPANY_DEFAULT_SORT } from '@constants/table/sort/defaultSort';
// utils
import { stripUrlSlashes } from '@optx/utils/url';
import { mapSorting } from '@optx/utils/table/sorting';
import { checkOverflow } from '@optx/utils/utils';
import { getIsPageReloaded } from '@utils/document';
// hooks
import useIsMount from '@optx/common/hooks/useIsMount';
import {
  useVirtualScrollPosition,
  VirtualTableScrollYContext,
} from '@optx/features/scroll-history';
// redux
import { selectors as userSelectors } from '@redux/user/information';
import { selectors as searchCountSelectors } from '@features/grid/search-count';
import { selectors as fetchCountSelectors } from '@features/grid/fetched-count';
import {
  selectors as addonSelectors,
  actions as addonActions,
} from '@redux/company/addon-management';
import { actions as selectedCompaniesActions } from '@features/bulk-actions/selected-companies';
import { actions as mergeCompaniesActions } from '@features/bulk-actions/bulk-merge-companies';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { selectors as paginationSelectors } from '@features/grid/pagination';
import * as profileSelectors from 'src/components/pages/CompanyProfile/selectors';
import { selectors as fullscreenSelectors } from '@redux/ui/settings/fullscreen';
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as filterSelectors } from '@features/grid/filter';
// components
import { columns } from '@components/pages/CompanyProfile/ProfileTabs/Addon/table/defaultColumns';
import ExternalScroll from '@optx/components/common/external-scroll';
import { Styled } from '@components/common/table/Companies/CompanyTable.styled';
import FiltersModal from '@optx/components/pages/CompanyProfile/ProfileTabs/Addon/components/FiltersModal';
import { GridPagination } from '@features/grid/pagination/components';
import AddonGridControls from './components/AddonGridControls';
import SecondaryQuickFiltersContainer from './components/SecondaryQuickFiltersContainer';
import { GridContext } from './AddonManagementContext';

const rowKey: keyof Company = 'company_id';

interface AddonGridProps {
  companyId: number;
}

const AddonGrid: React.FC<AddonGridProps> = ({ companyId }) => {
  const [selectedData, setSelectedData] = useState<SelectOption[] | string | boolean>([]);
  const [hasSelectViewChanged, setHasSelectViewChanged] = useState<boolean>(false);
  const [rationaleValue, setRationaleValue] = useState<string>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<ReactText[]>([]);
  const isMount = useIsMount();
  const dispatch = useDispatch();
  const isLoading = useSelector(searchSelectors.isLoading('addons'));
  const initCompanies = useSelector(searchSelectors.getCompanies('addons'));
  const getMergeCompaniesStatus: boolean = useSelector(
    bulkActionsSelectors.mergeCompanies.getMergeCompaniesStatus
  );
  const defaultOptxScore = useSelector(userSelectors.getDefaultScore);
  const sorting = useSelector(addonSelectors.sortBy);
  const totalItemsCount = useSelector(searchCountSelectors.getSearchCount('addons'));
  const fetchedCount = useSelector(fetchCountSelectors.getFetchedCount('addons'));
  const selectedFilters = useSelector(filterSelectors.getFilter('addons'));
  const selectedAdditionalFilters = useSelector(addonSelectors.getAdditionalFiltersData);
  const { pageNumber, pageSize } = useSelector(paginationSelectors.getPagination('addons'));
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const gridSelectedCompanyIds = useSelector(
    bulkActionsSelectors.selectedCompanies.getSelectedCompanies('addonManagement')
  );
  const isLoadingCount = useSelector(searchSelectors.isLoadingCount('addons'));
  const isLoadingProfile = useSelector(profileSelectors.isLoading);
  const fullscreen = useSelector(fullscreenSelectors.isFullscreen);
  const shouldInitialFetch = useSelector(searchSelectors.shouldInitialFetch('addons'));

  const [windowSize] = useSize(window.document.documentElement);
  const [currentScroll, setCurrentScroll] = useState<number>(windowSize.height || 0);
  const [externalScroll, setExternalScroll] = useState<Element | null>(null);
  const prevCompaniesRef = useRef<Array<Company>>([]);
  const ctx = useContext(VirtualTableScrollYContext);

  const filteredColumns = useMemo(() => {
    let gridColumns;

    if (defaultOptxScore === 'il') {
      gridColumns = columns.filter(item => item.dataIndex !== 'score');
    } else {
      gridColumns = columns.filter(item => item.dataIndex !== 'il_optx_score');
    }

    return gridColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const getNextScroll = useCallback(() => {
    let tableHeaderHeight = 0;

    if (tableWrapperRef.current) {
      tableHeaderHeight =
        tableWrapperRef.current.querySelector('.ant-table-header')?.getBoundingClientRect()
          .height || 0;
    }

    const virtualScrollSubstractElements = document.querySelectorAll(
      '[data-virtual-scroll-substract="addon-management-grid"]'
    );

    let substractHeight = 0;

    virtualScrollSubstractElements.forEach(element => {
      const styles = window.getComputedStyle(element, null);
      const margin = parseInt(styles.marginBottom);

      substractHeight += element.getBoundingClientRect().height + margin;
    });

    // Add in styles bottom padding the same size as pagination.
    const nextScroll =
      windowSize.height !== undefined ? windowSize.height - tableHeaderHeight - substractHeight : 0;

    return nextScroll;
  }, [windowSize.height]);

  const { onScroll, initialScrollPosition } = useVirtualScrollPosition(
    `${routes.profile}/${companyId}`
  );

  const [tableSize] = useSize(
    () => document.querySelector('.addon-management-grid-wrapper table') as HTMLElement
  );

  useEffect(() => {
    if (companyId) {
      dispatch(addonActions.clearAddonManagementFetchedAt());
      dispatch(
        addonActions.fetchAdditionalFilters({ companyId: companyId, shouldBeAddedToLoading: true })
      );
    }

    // fix table being cropped when navigating to other pages and coming back
    setTimeout(() => {
      vtImperativeScroll.current?.scrollTo(initialScrollPosition);
    }, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedFilters) && selectedAdditionalFilters.length && shouldInitialFetch) {
      const isFromLists = document.referrer.includes('lists');
      const shouldResetPageNumber = isFromLists && !getIsPageReloaded();

      dispatch(
        addonActions.fetchCompanyAddonManagement({
          companyId,
          shouldResetPageNumber,
        })
      );
    }
  }, [companyId, dispatch, selectedFilters, selectedAdditionalFilters, shouldInitialFetch]);

  useEffect(() => {
    let companyWasRemoved = false;

    if (prevCompaniesRef.current.length - initCompanies.length === 1) {
      const uniqueCompanies = prevCompaniesRef.current.filter(
        prevCompany =>
          !initCompanies.some(initCompanie => initCompanie.company_id === prevCompany.company_id)
      );

      // in this condition we are checking if the unique company that was removed is only one
      // and if previous companies are the same that in initCompanies to ensure that we are fetching
      // filters in case of removing company but not changing filters
      if (uniqueCompanies.length === 1) {
        const normalizedPrevCompanies = prevCompaniesRef.current.filter(
          company => company.company_id !== uniqueCompanies[0].company_id
        );
        const containsAllObjectsBesidesOne = normalizedPrevCompanies.every(prevCompany =>
          initCompanies.some(initCompany => initCompany.company_id === prevCompany.company_id)
        );

        companyWasRemoved = containsAllObjectsBesidesOne;
      }
    }

    // in this condition we are checking if addon has changed for any of the companies
    // if yes we need to fetch additional filters to ensure that count for filter options are correct
    const hasAddonChanged = initCompanies.some(initCompany => {
      const prevCompany = prevCompaniesRef.current.find(
        prevCompany => prevCompany.company_id === initCompany.company_id
      );

      return prevCompany && initCompany.addon !== prevCompany.addon;
    });

    // in this condition we are checking if addon has changed for any of the companies
    // or we have deleted only one particular company
    // if yes we need to fetch additional filters to ensure that count for filter options are correct
    if ((hasAddonChanged || companyWasRemoved) && companyId) {
      dispatch(
        addonActions.fetchAdditionalFilters({ companyId: companyId, shouldBeAddedToLoading: false })
      );

      if (!companyWasRemoved) {
        dispatch(
          addonActions.fetchCompanyAddonManagement({
            companyId,
            shouldResetPageNumber: false,
          })
        );
      }
    }

    prevCompaniesRef.current = initCompanies;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, initCompanies]);

  const vtImperativeScroll = useRef<any>();

  /**
   * Each time a new search success is made reset scroll.
   */
  useEffect(() => {
    if (!isMount && !getMergeCompaniesStatus) {
      vtImperativeScroll.current.scrollTo(0);
    }

    if (getMergeCompaniesStatus) {
      dispatch(mergeCompaniesActions.invertStatusAfterMerge());
    }

    if (selectedRowKeys.length) {
      setSelectedRowKeys([]);
      dispatch(
        selectedCompaniesActions.selectCompanyIds({
          gridName: 'addonManagement',
          selectedCompanies: [],
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedCount]);

  useEffect(() => {
    if (!isMount && !getMergeCompaniesStatus) {
      vtImperativeScroll.current.scrollTo(0);
    }

    if (getMergeCompaniesStatus) {
      dispatch(mergeCompaniesActions.invertStatusAfterMerge());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const [vt] = useVT(
    () => ({
      initTop: initialScrollPosition,
      scroll: { y: currentScroll },
      onScroll,
      ref: vtImperativeScroll,
    }),
    [currentScroll, initialScrollPosition]
  );

  /**
   * Update scroll each time the size of the wrapper changes or window size changes.
   * This can happen when let's say a column is added/removed and increases/decreases the height of table header.
   * Scroll doesn't include table header size.
   */
  useEffect(() => {
    setTimeout(() => {
      const nextScroll = getNextScroll();
      // Leave a 5px space at the top when computing exact height.
      const offset = 200;
      setCurrentScroll(nextScroll - offset);
    }, 0);
  }, [ctx.height, getNextScroll]);

  const contentRef = useRef<HTMLDivElement | null>(
    tableWrapperRef.current?.querySelector('.ant-table-body') || null
  );

  if (!contentRef.current && tableWrapperRef.current) {
    // Create the ref for external scroll. Unfortunately we don't have access to it directly as element.
    contentRef.current = tableWrapperRef.current.querySelector('.ant-table-body') || null;
  }

  useEffect(() => {
    if (gridSelectedCompanyIds.length) {
      setSelectedRowKeys(gridSelectedCompanyIds.map(company => company.company_id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // overflow
  const externalScrollElement = document.querySelector('.external-scroll');

  useEffect(() => {
    if (externalScrollElement !== null) {
      setExternalScroll(externalScrollElement);
    }
  }, [externalScrollElement]);

  const onSelectChange = (selectedRowKeys: ReactText[], selectedRows: Company[]) => {
    const selectedCompaniesData = selectedRows.map(company => {
      return {
        ...pick(company, BULK_ACTIONS_FIELDS),
        company_url: company.company_url ? stripUrlSlashes(company.company_url) : null,
      };
    });

    setSelectedRowKeys(selectedRowKeys);
    dispatch(
      selectedCompaniesActions.selectCompanyIds({
        gridName: 'addonManagement',
        // selectedCompanies object properties are picked from Company model
        // strange conflict between Partial and Pick
        // @ts-ignore
        selectedCompanies: selectedCompaniesData,
      })
    );
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
    getCheckboxProps: (record: Company) => ({
      name: record.company_id.toString(),
    }),
  };

  const handleChange: TableChangeCallback<Company> = (pagination, filters, sorter, extra) => {
    switch (extra.action) {
      case 'sort': {
        const sort = mapSorting(sorter, sorting, null, COMPANY_DEFAULT_SORT);
        dispatch(addonActions.changeSortCompanyAddonManagement(sort.sortBy as SortByRule<any>[]));

        dispatch(
          addonActions.fetchCompanyAddonManagement({
            companyId,
            shouldResetPageNumber: true,
          })
        );

        if (tableWrapperRef.current) {
          // scroll table to the left when sorting, issue for firefox
          const header = tableWrapperRef.current.querySelector('.ant-table-header');
          const body = tableWrapperRef.current.querySelector('.ant-table-body');
          if (header) header.scrollLeft = 0;
          if (body) body.scrollLeft = 0;
        }

        break;
      }

      default:
        break;
    }
  };

  return (
    <>
      <GridContext.Provider
        value={{
          selectedData,
          setSelectedData,
          gridName: 'addonManagement',
          hasSelectViewChanged,
          setHasSelectViewChanged,
          rationaleValue,
          setRationaleValue,
        }}
      >
        <SecondaryQuickFiltersContainer />
        <AddonGridControls />
        <FiltersModal />
        {initCompanies?.length && !isLoading ? (
          <ExternalScroll refElement={contentRef} contentWidth={tableSize.width} isVirtualGrid />
        ) : null}
        <div className="addon-management-grid-wrapper" ref={tableWrapperRef}>
          <Styled.CompanyTable
            size="small"
            rowKey={rowKey}
            rowSelection={rowSelection}
            tableLayout="fixed"
            columns={filteredColumns}
            dataSource={initCompanies ?? undefined}
            pagination={false}
            bordered
            onChange={handleChange}
            showSorterTooltip={false}
            sticky
            style={{ maxHeight: '100vh', marginBottom: '70px' }}
            // virtualization
            scroll={{ y: currentScroll }} // It's important for using VT!!! DO NOT FORGET!!!
            components={vt}
            isEmpty={!initCompanies?.length ?? 0}
            overflowHeight={checkOverflow(externalScroll)}
          />
        </div>
        <GridPagination
          gridKey="addons"
          totalItemsCount={totalItemsCount}
          pageNumber={pageNumber}
          pageSize={pageSize}
          searchCountPageSize={initCompanies.length}
          selectedCountType="addonManagement"
          isLoadingCount={isLoadingCount}
          listType={{
            one: 'Company',
            many: 'Companies',
          }}
          isFullscreen={fullscreen}
          isLoadingList={isLoadingProfile}
        />
      </GridContext.Provider>
    </>
  );
};

export default AddonGrid;
