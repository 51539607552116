import styled from 'styled-components';
import { MainContent } from '@optx/components/common/layout';
import { Title } from '@optx/shared/view/atoms/Typography';
import { Typography } from 'antd';
import { AnalystsMultiSelect } from '@features/equity-touch-integration/components';

const StyledMainContent = styled(MainContent)``;

const StatsWrapper = styled.div``;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${StatsWrapper},
  ${StyledMainContent} {
    min-width: 1px;
    width: 100%;
  }

  @media (min-width: 1600px) {
    flex-wrap: nowrap;

    ${StatsWrapper} {
      flex: 0;
      min-width: 500px;
      max-width: 500px;
    }

    ${StyledMainContent} {
      flex: 1;
      margin-top: 23px;
    }
  }
`;

const PageTitle = styled(Title)`
  margin-top: 10px;
`;

const ByAnalystsOrTeamMultiSelect = styled(AnalystsMultiSelect)`
  max-width: 350px;
`;

const TypographyTitle = styled(Typography.Title)`
  &.ant-typography {
    /* Dark BLue */
    color: #334d6e;
    margin: 0;
  }
`;
const SecondaryTitle = styled(Typography.Paragraph)`
  /* Gray */
  color: #90a0b7;
`;

const Styled = {
  Wrapper,
  StatsWrapper,
  MainContent: StyledMainContent,
  PageTitle,
  TypographyTitle,
  SecondaryTitle,
  ByAnalystsOrTeamMultiSelect,
};

export default Styled;
