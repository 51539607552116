import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { selectors as companyFiltersSelectors } from '../../../redux/company/filters';
import { selectors, actions } from '../../../redux/company/suggested-searches';
import { useShouldFetch } from '../fetch';

const useFetchSuggestedSearches = () => {
  const dispatch = useDispatch();
  const filtersLoaded = useSelector(companyFiltersSelectors.filtersLoaded);
  const shouldFetchSearches = useSelector(selectors.shouldFetchSearches);
  const fetchSuggestedSearches = useCallback(
    () => dispatch(actions.fetchSuggestedSearches()),
    [dispatch]
  );

  useShouldFetch(filtersLoaded && shouldFetchSearches, fetchSuggestedSearches);
};

export default useFetchSuggestedSearches;
