import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { Styled } from './TextareaEditor.styled';
import 'react-quill/dist/quill.snow.css';

interface TextareaEditorProps {
  placeholder: string;
  textValue?: string | null;
  handleChange: (value: string) => void;
}

const TextareaEditor: React.FC<TextareaEditorProps> = ({
  placeholder,
  textValue = null,
  handleChange,
}) => {
  const [editorHtml, setEditorHtml] = useState('');

  const handleChangeTextarea = (value: string) => {
    setEditorHtml(value);
    handleChange(value);
  };

  useEffect(() => {
    if (textValue && textValue !== editorHtml) {
      setEditorHtml(textValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textValue]);

  return (
    <Styled.TextareaContainer>
      <ReactQuill
        theme="snow"
        onChange={handleChangeTextarea}
        value={editorHtml}
        modules={{
          toolbar: [
            [{ header: '1' }, { header: '2' }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link', 'image'],
            ['clean'],
          ],
          clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
          },
        }}
        formats={[
          'header',
          'font',
          'size',
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'list',
          'bullet',
          'indent',
          'link',
          'image',
          'video',
        ]}
        bounds=".textarea-bond"
        placeholder={placeholder}
      />
    </Styled.TextareaContainer>
  );
};

export default TextareaEditor;
