import React from 'react';
import { useSelector } from 'react-redux';
// models
import { DropdownAddItem } from '@models/DropdownAdd';
import DropdownAddMenuItem from './DropdownAddMenuItem';
// utils
import { verifyAccess } from '@utils/verifyAccess';
// redux
import { selectors } from '@redux/user/information';
import { Styled } from './DropdownAddMenu.styled';

interface DropdownAddMenuProps {
  items: DropdownAddItem[];
  actionIndex: (value: number | undefined) => void;
}

const DropdownAddMenu: React.FC<DropdownAddMenuProps> = ({
  items,
  actionIndex,
  ...menuItemProps
}) => {
  const components = useSelector(selectors.authorizedComponents);

  return (
    <>
      <Styled.DropdownMenu className="dropdown-add" selectable={false}>
        {items.map((item, index, array) => {
          const component = (
            <DropdownAddMenuItem actionIndex={actionIndex} {...item} key={item.key} index={index} />
          );

          return !item.accessFor
            ? component
            : verifyAccess(components, item.accessFor)
            ? component
            : null;
        })}
      </Styled.DropdownMenu>
    </>
  );
};

export default DropdownAddMenu;
