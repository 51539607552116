import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// hooks
import { useImportListsFavorites } from '@features/list-import/hooks';
// components
import { DropdownImport } from '@features/list-import/components';
// redux
import { selectors as favoriteListsSelectors } from '@redux/favorite-lists/lists';
import { BulkImportWatchlist } from './BulkImportWatchlist';

const WatchListDropdownImport = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { handleImportListFavorites } = useImportListsFavorites();
  // for import
  const listsFavorites = useSelector(favoriteListsSelectors.getLists);

  const onImportList = (title: string, data: Array<{}>) => {
    handleImportListFavorites(title, data);
    setIsModalVisible(true);
  };

  return (
    <>
      <DropdownImport listsFavorites={listsFavorites} onImportList={onImportList} />
      <BulkImportWatchlist setIsModalVisible={setIsModalVisible} isModalVisible={isModalVisible} />
    </>
  );
};

export default WatchListDropdownImport;
