import * as yup from 'yup';
import { SelectOption } from '@optx/models/Option';

interface FilterModel {
  geo_range: Array<SelectOption>;
  geo_country: SelectOption;
}

const messages = {
  required: 'Field is required!',
  number: 'Field must be a number!',
};

/**
 * Get path to the item value.
 * @param index index in list
 */
const getGeoRangeValuePath = (index: number) => `geo_range[${index}].value`;

const isValidNumber = (value?: string) => {
  if (!value) {
    return true;
  }

  const isNumber = /^\d*$/gm.test(value);

  return isNumber;
};

// Zip Code is required if Range has value and viceversa.
const geoRangeSchema = yup
  .array<SelectOption>()
  .test('geo_range', '', function validateGeoCode(geolocationList: Array<SelectOption>) {
    const zipCode = geolocationList?.[0]?.value;
    const geoRange = geolocationList?.[1]?.value;

    // test if range is number
    if (!isValidNumber(geoRange)) {
      return this.createError({ message: messages.number, path: getGeoRangeValuePath(1) });
    }

    // test if one of them has value and the other is missing
    if (zipCode && !geoRange) {
      return this.createError({ message: messages.required, path: getGeoRangeValuePath(1) });
    }

    if (geoRange && !zipCode) {
      return this.createError({ message: messages.required, path: getGeoRangeValuePath(0) });
    }

    return true;
  });

const geoCountrySchema = yup
  .object<SelectOption>()
  .test('geo_country', '', function validateGeoCountry(geoCountry: SelectOption) {
    const geoRange = this.parent.geo_range;
    const code = geoRange?.[0]?.value;

    if (code && geoCountry && !geoCountry.value) {
      return this.createError({ message: messages.required, path: 'geo_country' });
    }

    if (geoCountry && geoCountry.value && !code) {
      return this.createError({ message: messages.required, path: getGeoRangeValuePath(0) });
    }

    return true;
  });

// filter form validation schema
const validationSchema = yup.object<FilterModel>({
  geo_range: geoRangeSchema,
  geo_country: geoCountrySchema,
});

export default validationSchema;
