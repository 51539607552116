import { Tag as $Tag, Typography } from 'antd';
import styled from 'styled-components';

const { Title: $Title } = Typography;

const Wrapper = styled.div`
  margin: 2px 0 0;
`;

const Tag = styled($Tag)`
  margin-top: 2px;
  margin-bottom: 2px;
  max-width: 100%;
  white-space: inherit;
  word-break: break-word;
`;

const Title = styled($Title)`
  font-weight: normal;
  margin: 0 !important;
`;

export default {
  Wrapper,
  Tag,
  Title,
};
