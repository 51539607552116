import styled from 'styled-components';
import { Card, Row } from 'antd';

const SalesloftBulkWrapper = styled.div`
  .salesloft-bulk-header {
    background-color: white;
    height: 64px;
    padding: 11px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      font-size: 20px;
      font-weight: 600;
      margin: 0;
    }
    button:last-child {
      margin-left: 10px;
    }
  }
`;

const CardWrapper = styled(Card)`
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(66, 17, 115, 0.06);
  margin-bottom: 20px;
`;

const SalesloftCardsRow = styled(Row)`
  padding: 28px 35px;
`;

export { SalesloftBulkWrapper, CardWrapper, SalesloftCardsRow };
