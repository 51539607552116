import { AxiosResponse } from 'axios';
import { Dictionary } from 'lodash';
import columns from '@components/common/table/TableContactsSearch/defaultColumns';
import { CompanyService } from '../services/api';
import NotificationService from '../services/NotificationService';

interface ExportContactsColumns {
  columns: Dictionary<string>;
}

export const exportFileCSV = (
  fileName: string,
  filtersSearchCriteria: Dictionary<string>,
  hideNotification = false
) => {
  const gridHeaders: ExportContactsColumns = { columns: {} };
  columns.forEach(column => {
    const headerKey = column.dataIndex as string;
    const title = column.title;

    gridHeaders.columns[headerKey] = title as string;

    if (column.dataIndex === 'email') {
      gridHeaders.columns.email_dist = 'Email for Distribution';
    }

    if (column.dataIndex === 'tenure_start_date') {
      gridHeaders.columns.tenure_start_date = 'Tenure Start Date';
      gridHeaders.columns.tenure_end_date = 'Tenure End Date';
    }
  });

  return CompanyService.exportContactsToCSV(filtersSearchCriteria, gridHeaders)
    .then((response: AxiosResponse<string>) => {
      const element = document.createElement('a');

      element.setAttribute(
        'href',
        `data:application/csv;charset=utf-8, ${encodeURIComponent(response.data)}`
      );
      element.setAttribute('download', `${fileName}.csv`);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      if (!hideNotification) NotificationService.success('Export CSV file success!');
    })
    .catch(() => {
      NotificationService.error('Export CSV file error');
    });
};
