import { combineReducers } from 'redux';

import { reducer as histogramFiltersReducer } from '@features/histograms/histograms-my-companies';
// models
import { MyCompaniesState } from './interfaces';
// reducers
import sortReducer from './sort';
import filterCardsReducer from './filters-cards';
import columns from './columns';

const reducer = combineReducers<MyCompaniesState>({
  filterCards: filterCardsReducer,
  histogramFilters: histogramFiltersReducer,
  sort: sortReducer,
  columns,
});

export default reducer;
