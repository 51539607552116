import React, { Context, useState, useContext, useEffect } from 'react';
import { Typography, Row, Col } from 'antd';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import styled from 'styled-components';
// models
import { BulkEditFieldContext } from '@optx/models/bulkActions';
import { AppState } from '@optx/redux/interfaces';
// redux
import { useSelector } from 'react-redux';
import { getFormRationales } from '@optx/redux/company/filters/selectors';
import { getIsAdminOrAnalyst } from '@optx/redux/user/information/selectors';
import CreateOtherRaionale from '@optx/features/company/edit-fields/components/CreateOtherRationale';

interface EditSoftwareProps {
  context: Context<BulkEditFieldContext>;
}

const ContentWrapper = styled.div`
  .popover-radio__container {
    display: flex;
    flex-direction: column;
    gap: 10;
  }

  .ant-radio {
    top: 0;

    .ant-radio-inner:after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) !important;
    }
  }

  .rationale_select-async {
    display: block;
    width: 100%;
  }

  .ant-radio-group {
    width: 100%;

    .ant-radio-button-wrapper {
      width: 100%;
    }
  }
`;

export const EditSoftware: React.FC<EditSoftwareProps> = ({ context }) => {
  const { setSelectedData } = useContext(context);

  const [radioValue, setRadioValue] = useState(false);
  const [isOther, setIsOther] = useState<string>();
  const { setRationaleValue } = useContext(context);

  const isAdminOrAnalyst = useSelector(getIsAdminOrAnalyst);

  const rationaleOptions = useSelector(
    (state: AppState) => getFormRationales(state, 'is_software_rationales') || []
  );

  useEffect(() => {
    setSelectedData(false);
    setRationaleValue('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e: RadioChangeEvent) => {
    setSelectedData(e.target.value);
    setRadioValue(e.target.value);
  };

  const handleRationaleChange = (e: RadioChangeEvent) => {
    setIsOther(e.target.value);
    setRationaleValue(e.target.value);
  };

  const handleOtherChange = (option: string | undefined) => {
    setRationaleValue(option);
  };

  return (
    <ContentWrapper className="popover-radio__container">
      <Typography.Text className="ant-modal-title">Select New Software</Typography.Text>
      <Radio.Group
        className="popover-radio ant-popover-content-wrapper"
        onChange={onChange}
        value={radioValue}
      >
        <Radio value style={{ display: 'flex', alignItems: 'center', lineHeight: 2 }}>
          Yes
        </Radio>
        <Radio value={false} style={{ display: 'flex', alignItems: 'center', lineHeight: 2 }}>
          No
        </Radio>
      </Radio.Group>
      {isAdminOrAnalyst && radioValue === false && (
        <>
          <Row style={{ marginTop: 13 }}>
            <Typography.Text className="ant-modal-title" style={{ fontWeight: '500' }}>
              Train the algorithm and tell us why(optional)
            </Typography.Text>
          </Row>
          <Radio.Group buttonStyle="solid" onChange={handleRationaleChange}>
            {rationaleOptions?.map(option => (
              <Col style={{ textAlign: 'center', marginTop: 10 }}>
                <Radio.Button value={option.value}>{option.label}</Radio.Button>
              </Col>
            ))}
          </Radio.Group>
          {isOther === 'other' && (
            <Row style={{ marginTop: 20 }}>
              <Typography.Text>Please Explain</Typography.Text>
              <CreateOtherRaionale onInputChange={handleOtherChange} isSoftwareModal={true} />
            </Row>
          )}
        </>
      )}
    </ContentWrapper>
  );
};
