import { blue } from '@ant-design/colors';
import Option from '@optx/models/Option';
import React from 'react';
import styled from 'styled-components';

const Label = styled.div`
  font-size: 12px;
  /* Gray */
  color: #90a0b7;
  opacity: 0.8;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
`;

export const Value = styled.div`
  font-size: 16px;
  font-weight: 600;
  /* Daybreak Blue / blue-6 */
  color: ${blue[6]};
  opacity: 0.8;
`;

interface OptionValueProps {
  option: Option<React.ReactNode>;
}

/**
 * KPI Option
 * @param param0
 */
const OptionValue = ({ option }: OptionValueProps) => {
  return (
    <div>
      <Label>{option.label}</Label>
      <Value>{option.value}</Value>
    </div>
  );
};

export default OptionValue;
