import React from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
// models
import { UserManagement } from '@optx/models/UserManagement';
import { Cell } from '@models/table/Cell';
// redux
import { actions as userEditmodalActions } from '@redux/ui/modals/user-edit';
import { actions } from '@redux/user-management';
// styles
import Styles from '@components/pages/UserManagement/UserManagement.style';

const TableCellActions: React.FC<Cell<UserManagement>> = ({ record }) => {
  const dispatch = useDispatch();

  const handleDisable = () => {
    dispatch(actions.disableUser(record.id));
  };

  const handleUnlock = () => {
    dispatch(actions.unlockUser(record.id));
  };

  const handleEdit = () => {
    dispatch(actions.fetchUserEditInfo(record.id));
    dispatch(userEditmodalActions.toggleUserEditModal());
  };

  const menu = (
    <Styles.MenuWrapper>
      <Menu.Item>
        <button disabled={record.status === 'No Access'} type="button" onClick={handleDisable}>
          Disable User Access
        </button>
      </Menu.Item>
      {record.locked && (
        <Menu.Item>
          <button type="button" onClick={handleUnlock}>
            Unlock
          </button>
        </Menu.Item>
      )}
      <Menu.Item>
        <button type="button" onClick={handleEdit}>
          Edit User
        </button>
      </Menu.Item>
    </Styles.MenuWrapper>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <EllipsisOutlined />
    </Dropdown>
  );
};

export default TableCellActions;
