import React from 'react';
import { Tag, Typography } from 'antd';
import { TagProps } from 'antd/lib/tag';
import Overflow from 'rc-overflow';
import { FilterTag } from '@optx/models/tags';

const { Text } = Typography;

interface FilterTagValueProps extends TagProps {
  item: FilterTag;
}

const FilterTagValue: React.FC<FilterTagValueProps> = ({ item, ...props }) => {
  return (
    <Overflow.Item component="span">
      <Tag {...props}>
        <Text style={{ color: '#595959', textTransform: 'uppercase' }}>{`${item.label}: `}</Text>
        <Text style={{ color: '#1890FF' }}>{item.stringifiedValues || ' - '}</Text>
      </Tag>
    </Overflow.Item>
  );
};

export default FilterTagValue;
