import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import filterSourcesReducer, { saga as filterSourcesSaga } from '@redux/company/filters';

const useInjectCompanyFilterSources = () => {
  useInjectReducer({
    key: 'filters',
    reducer: filterSourcesReducer,
  });
  useInjectSaga({
    key: 'filtersSaga',
    saga: filterSourcesSaga,
  });
};

export default useInjectCompanyFilterSources;
