import { createAction } from '@reduxjs/toolkit';
import * as types from './actionTypes';
import { RegionOptions } from '../../../models/user';

export const fetchRegionOptions = createAction(types.FETCH_REGION_OPTIONS);

export const fetchRegionOptionsSuccess = createAction<Array<RegionOptions>>(
  types.FETCH_REGION_OPTIONS_SUCCESS
);

export const fetchRegionOptionsFail = createAction<string>(types.FETCH_REGION_OPTIONS_FAIL);
