import { ColumnType } from 'antd/lib/table';
// redux
import { ImportSimilarCompanies } from '@optx/models/Company';
// components
import { TableCellURL, TableCellCompanyName } from '@components/common/table/AntTable/cells';
import { TableCellYearFounded, TableCellLocation } from './cells';

export const columns: ColumnType<ImportSimilarCompanies>[] = [
  {
    dataIndex: 'company_url',
    title: '',
    render: (value: string, record: ImportSimilarCompanies) => (
      <TableCellURL value={value} record={{ url: record.raw_url }} />
    ),
    width: 42,
    align: 'center',
  },
  {
    dataIndex: 'company_name',
    title: 'Company Name',
    render: TableCellCompanyName,
  },
  {
    dataIndex: 'year_founded',
    title: 'Year Founded',
    render: TableCellYearFounded,
  },
  {
    dataIndex: 'city',
    title: 'Location',
    render: TableCellLocation,
  },
];
