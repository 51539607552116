const emailRegex = /([mailto:\w.\-pL]+@\w+.[\w.\-pL]+)/gi;

const replaceEmailTouchNotes = (notes: string) => {
  return notes.replace(emailRegex, (match: string) => {
    if (match.includes('mailto:')) {
      return '';
    }

    return `<a href="mailto:${match}">${match}</a>`;
  });
};

export default replaceEmailTouchNotes;
