import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
  ReactText,
  useMemo,
  SyntheticEvent,
} from 'react';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { useVT } from 'virtualizedtableforantd4';
import { useSize } from '@umijs/hooks';
import { pick } from 'lodash';
// models
import { SortRule } from '@models/table/sorting';
import Company from '@optx/models/Company';
import { TableChangeCallback } from '@optx/models/table/antd-table';
import { ColumnType } from 'antd/lib/table';
import { ResizeCallbackData } from 'react-resizable';
import { ColumnKeys } from '@optx/models/table/Columns';
// constants
import useActiveTableRow from '@optx/common/hooks/dom/useActiveTableRow';
import routes from '@constants/routes';
import { BULK_ACTIONS_FIELDS } from '@constants/equitytouch';
import defaultColumns from '@components/common/table/Companies/columns/virtual-column-config';
import {
  MYCOMPANIES_DEFAULT_SORT_US,
  MYCOMPANIES_DEFAULT_SORT_IL,
} from '@optx/constants/table/sort/defaultSort';
// utils
import { mapSorting } from '@utils/table/sorting';
import { stripUrlSlashes } from '@optx/utils/url';
import { checkOverflow } from '@optx/utils/utils';
import scrollTableOnSorting from '@optx/utils/scrollTable';
// redux
import { getSessionSettings, getDefaultScore } from '@optx/redux/user/information/selectors';
import { actions as selectedCompaniesActions } from '@features/bulk-actions/selected-companies';
import { actions as mergeCompaniesActions } from '@features/bulk-actions/bulk-merge-companies';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { selectors as fetchCountSelectors } from '@optx/features/grid/fetched-count';
import { selectors as searchSelectors } from '@features/grid/search';
import { actions, selectors as sortSelectors } from '@redux/my-companies/sort';
import {
  selectors as columnSelectors,
  actions as columnActions,
} from '@redux/my-companies/columns';
import { useVirtualScrollPosition, VirtualTableScrollYContext } from '@features/scroll-history';
import { selectors as paginationSelectors } from '@features/grid/pagination';
// hooks
import useIsMount from '@hooks/useIsMount';
import useNonVirtualizedTable from '@hooks/useNonVirtualizedTable';
import useMultiSortNumbers from '@hooks/useMultiSortNumbers';
import useNavigateOnCompanyNameClick from '@optx/common/hooks/useNavigateOnCompanyNameClick';
// components
import { ResizeColumn } from '@optx/features/company-search/lib/Table/columns';
import { Styled } from '@components/common/table/Companies/CompanyTable.styled';
import ExternalScroll from '../../common/external-scroll';
import { Styled as MyCompaniesStyled } from './MyCompaniesTable.styled';

const rowKey: keyof Company = 'company_id';
let isMouseDown = false;

const MyCompaniesTable: React.FC = () => {
  // hooks
  const dispatch = useDispatch();
  const isMount = useIsMount();
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const virtualizedActive = useNonVirtualizedTable();
  useNavigateOnCompanyNameClick('myCompanies');

  // selectors
  const fetchedCount = useSelector(fetchCountSelectors.getFetchedCount('myCompanies'));
  const companies = useSelector(searchSelectors.getCompanies('myCompanies'));
  const isLoading = useSelector(searchSelectors.isLoading('myCompanies'));
  const sessionSettings = useSelector(getSessionSettings);
  const defaultOptxScore = useSelector(getDefaultScore);
  const isMultiSort = useSelector(sortSelectors.isMultiSort);
  const reduxColumns = useSelector(columnSelectors.getColumns);
  const sorting = useSelector(sortSelectors.getSorting);
  const { pageNumber } = useSelector(paginationSelectors.getPagination('myCompanies'));
  const getMergeCompaniesStatus: boolean = useSelector(
    bulkActionsSelectors.mergeCompanies.getMergeCompaniesStatus
  );
  const gridSelectedCompanyIds = useSelector(
    bulkActionsSelectors.selectedCompanies.getSelectedCompanies('myCompanies')
  );
  const myCompaniesDefaultSort =
    defaultOptxScore === 'us' ? MYCOMPANIES_DEFAULT_SORT_US : MYCOMPANIES_DEFAULT_SORT_IL;

  // state
  const [selectedRowKeys, setSelectedRowKeys] = useState<ReactText[]>([]);
  const [columns, setColumns] = useState(defaultColumns);

  const onSortChange = useCallback(
    (sort: SortRule<Company>) => dispatch(actions.changeSortAction(sort)),
    [dispatch]
  );

  const onChange: TableChangeCallback<Company> = (pagination, filters, sorter, extra) => {
    switch (extra.action) {
      case 'sort': {
        // while resizing columns, prevent sorting from being triggered.
        // this happens when decreasing the width of a column and letting go of
        // the mouse button over the column header
        if (!isMouseDown) {
          const parsedSort =
            sessionSettings?.my_companies_filters &&
            queryString.parse(sessionSettings?.my_companies_filters, {
              arrayFormat: 'comma',
            });

          const sort = mapSorting(
            sorter,
            sorting,
            parsedSort && (parsedSort?.query as string),
            myCompaniesDefaultSort,
            isMultiSort
          );
          onSortChange(sort as SortRule<any>);

          // scroll table to the left when sorting, issue for firefox
          scrollTableOnSorting(tableWrapperRef);
        }

        break;
      }

      default:
        break;
    }
  };

  /**
   * We need table content size for external scroll.
   */
  const [tableSize] = useSize(
    () => document.querySelector('.my-companies-grid-wrapper table') as HTMLElement
  );

  const onRow = useActiveTableRow<Company>('company_id', routes.myCompanies);
  const [windowSize] = useSize(window.document.documentElement);
  const [currentScroll, setCurrentScroll] = useState<number>(windowSize.height || 0);
  const [externalScroll, setExternalScroll] = useState<Element | null>(null);

  const ctx = useContext(VirtualTableScrollYContext);

  const getNextScroll = useCallback(() => {
    let tableHeaderHeight = 0;

    if (tableWrapperRef.current) {
      tableHeaderHeight =
        tableWrapperRef.current.querySelector('.ant-table-header')?.getBoundingClientRect()
          .height || 0;
    }

    const virtualScrollSubstractElements = document.querySelectorAll(
      '[data-virtual-scroll-substract="my-companies-grid"]'
    );

    let substractHeight = 0;

    virtualScrollSubstractElements.forEach(element => {
      const styles = window.getComputedStyle(element, null);
      const margin = parseInt(styles.marginBottom);

      substractHeight += element.getBoundingClientRect().height + margin;
    });

    // Add in styles bottom padding the same size as pagination.
    const nextScroll =
      windowSize.height !== undefined ? windowSize.height - tableHeaderHeight - substractHeight : 0;

    return nextScroll;
  }, [windowSize.height]);

  const { onScroll, initialScrollPosition } = useVirtualScrollPosition(routes.myCompanies);

  // fix for scrolling table to the left
  useEffect(() => {
    if (!isLoading && tableWrapperRef?.current) {
      const body = tableWrapperRef.current.querySelector('.ant-table-body');

      if (body) {
        body.scrollLeft = 0;
      }
    }
  }, [isLoading]);

  useEffect(() => {
    // fix table being cropped when navigating to other pages and coming back
    setTimeout(() => {
      vtImperativeScroll.current?.scrollTo(initialScrollPosition);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const vtImperativeScroll = useRef<any>();

  /**
   * Each time a new search success is made reset scroll.
   */
  useEffect(() => {
    if (!isMount && !getMergeCompaniesStatus) {
      vtImperativeScroll.current?.scrollTo(0);
    }

    if (getMergeCompaniesStatus) {
      dispatch(mergeCompaniesActions.invertStatusAfterMerge());
    }

    if (selectedRowKeys.length) {
      setSelectedRowKeys([]);
      dispatch(
        selectedCompaniesActions.selectCompanyIds({
          gridName: 'myCompanies',
          selectedCompanies: [],
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedCount]);

  useEffect(() => {
    if (!isMount && !getMergeCompaniesStatus) {
      vtImperativeScroll.current?.scrollTo(0);
    }

    if (getMergeCompaniesStatus) {
      dispatch(mergeCompaniesActions.invertStatusAfterMerge());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const [vt, setVt] = useVT(
    () => ({
      initTop: initialScrollPosition,
      scroll: { y: currentScroll },
      onScroll,
      ref: vtImperativeScroll,
    }),
    [currentScroll, initialScrollPosition]
  );

  /**
   * Update scroll each time the size of the wrapper changes or window size changes.
   * This can happen when let's say a column is added/removed and increases/decreases the height of table header.
   * Scroll doesn't include table header size.
   */
  useEffect(() => {
    setTimeout(() => {
      const nextScroll = getNextScroll();
      // Leave a 180px space at the top when computing exact height. Fix: display header column of table
      const offset = 180;
      setCurrentScroll(nextScroll - offset);
    }, 0);
  }, [ctx.height, getNextScroll]);

  const contentRef = useRef<HTMLDivElement | null>(
    tableWrapperRef.current?.querySelector('.ant-table-body') || null
  );

  if (!contentRef.current && tableWrapperRef.current) {
    // Create the ref for external scroll. Unfortunately we don't have access to it directly as element.
    contentRef.current = tableWrapperRef.current.querySelector('.ant-table-body') || null;
  }

  useEffect(() => {
    if (gridSelectedCompanyIds.length) {
      setSelectedRowKeys(gridSelectedCompanyIds.map(company => company.company_id));
    } else {
      setSelectedRowKeys([]);
    }
  }, [gridSelectedCompanyIds]);

  useMultiSortNumbers(tableWrapperRef, sorting, isMultiSort, isLoading);

  const onSelectChange = (selectedRowKeys: ReactText[], selectedRows: Company[]) => {
    const selectedCompaniesData = selectedRows.map(company => {
      return {
        ...pick(company, BULK_ACTIONS_FIELDS),
        company_url: company.company_url ? stripUrlSlashes(company.company_url) : null,
      };
    });
    setSelectedRowKeys(selectedRowKeys);
    dispatch(
      selectedCompaniesActions.selectCompanyIds({
        gridName: 'myCompanies',
        // selectedCompanies object properties are picked from Company model
        // strange conflict between Partial and Pick
        // @ts-ignore
        selectedCompanies: selectedCompaniesData,
      })
    );
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
    getCheckboxProps: (record: Company) => ({
      name: record.company_id.toString(),
    }),
  };
  // overflow
  const externalScrollElement = document.querySelector('.external-scroll');

  // columns resize functionality

  const handleResizeStart = () => {
    isMouseDown = true;
  };

  const handleResizeStop = (e: SyntheticEvent, data: ResizeCallbackData) => {
    dispatch(
      columnActions.saveColumnWidth({
        userSetting: 'column_widths_my_companies',
        columnId: data.node.offsetParent?.classList[1] as ColumnKeys,
        width: data.size.width,
      })
    );
    setTimeout(() => {
      isMouseDown = false;
    }, 0);
  };

  useMemo(
    () =>
      setVt({
        header: {
          cell: props => {
            return (
              <ResizeColumn
                handleResizeStart={handleResizeStart}
                handleResizeStop={handleResizeStop}
                {...props}
              />
            );
          },
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setVt]
  );

  const updateFinalColumns = (columns: ColumnType<any>[]) => {
    return columns.map((col: any, index: number) => ({
      ...col,
      onHeaderCell: (column: any) => ({
        width: column.width,
        onResize: handleResize(index),
      }),
      ...(virtualizedActive
        ? {}
        : {
            onCell: () => ({
              style: {
                'max-width': '409px',
                'min-width': '120px',
              },
            }),
          }),
    }));
  };

  const mergedColumns = useMemo(() => {
    return updateFinalColumns(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, virtualizedActive]);

  const handleResize = (index: number) => (e: any, other: any) => {
    const nextColumns = [...columns];
    nextColumns[index] = {
      ...nextColumns[index],
      width: other.size.width,
    };

    setColumns(nextColumns);
  };

  useEffect(() => {
    if (externalScrollElement !== null) {
      setExternalScroll(externalScrollElement);
    }
  }, [externalScrollElement]);

  useEffect(() => {
    // @ts-ignore
    setColumns(reduxColumns);
  }, [reduxColumns]);

  return (
    <Styled.CompanyTableWrapper>
      {companies.length && !isLoading ? (
        <ExternalScroll refElement={contentRef} contentWidth={tableSize.width} isVirtualGrid />
      ) : null}
      <div
        className="my-companies-grid-wrapper"
        ref={tableWrapperRef}
        style={{ paddingBottom: 64 }}
      >
        <MyCompaniesStyled.Table
          size="small"
          rowKey={rowKey}
          rowSelection={rowSelection}
          columns={mergedColumns}
          dataSource={companies}
          bordered
          pagination={false}
          showSorterTooltip={false}
          onChange={onChange}
          onRow={onRow}
          // virtualization
          scroll={{ y: currentScroll, x: true }} // It's important for using VT!!! DO NOT FORGET!!!
          components={virtualizedActive ? vt : undefined}
          isEmpty={!companies.length}
          overflowHeight={checkOverflow(externalScroll)}
        />
      </div>
    </Styled.CompanyTableWrapper>
  );
};

export default React.memo(MyCompaniesTable);
