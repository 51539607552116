import { createAction } from '@reduxjs/toolkit';
import { SocialMediaChartData } from '@optx/models/charts/data';
import * as types from './types';

// fetch company touches actions
export const fetchFacebookData = createAction<number>(types.fetchFacebookData.REQUEST);
export const fetchFacebookDataSuccess = createAction<SocialMediaChartData>(
  types.fetchFacebookData.SUCCESS
);

export const fetchFacebookDataFail = createAction<string>(types.fetchFacebookData.FAILURE);
