import styled from 'styled-components';
import { Tag as $Tag } from 'antd';

const ContentContainer = styled.div`
  width: 300px;

  .profile-information__subtitle {
    margin-top: 22px;
    padding-top: 14px;
    padding-bottom: 12px;
    border-top: 1px solid #d9d9d9;
  }

  .rationale_select-async {
    display: block;
    width: 100%;
  }

  .ant-typography {
    padding-bottom: 2px;
  }
`;

const InputWrap = styled.div`
  padding: 20px 0 0 0;
`;

const Tag = styled($Tag)`
  margin-top: 7px;
  margin-bottom: 2px;
  max-width: 100%;
  white-space: inherit;
  word-break: break-word;

  .remove-tag {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.45);

    &:hover {
      color: rgba(0, 0, 0, 0.85);
    }
  }
`;

export const Styled = { ContentContainer, InputWrap, Tag };
