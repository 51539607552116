import { createAction } from '@reduxjs/toolkit';
import { PageInfo } from '@optx/models/table/Pagination';
import { GridPayload } from '@models/grid';
import { CHANGE_LONG_CARD_PAGINATION, CHANGE_LONG_CARD_PAGINATION_SUCCESS } from './types';

export const changeLongCardPagination = createAction<GridPayload<PageInfo>>(
  CHANGE_LONG_CARD_PAGINATION
);
export const changeLongCardPaginationSuccess = createAction<GridPayload<PageInfo>>(
  CHANGE_LONG_CARD_PAGINATION_SUCCESS
);
