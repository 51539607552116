// models
import { Filter, BaseFilter } from '@optx/models/filters';

// filter_group type in order to display the last_manual_update and last_automatic_update in one card.
export const createLastDataUpdateGroup = (filterGroup: Array<Filter<any>>) => {
  const tempFilterData: Array<Filter<any>> = [];

  filterGroup.forEach((filterField, index) => {
    if (
      filterField.column !== 'last_manual_data_update' &&
      filterField.column !== 'last_automatic_data_update'
    ) {
      tempFilterData.push(filterField);
    } else if (filterField.column === 'last_manual_data_update') {
      tempFilterData.push({
        index: filterField.index,
        column: 'last_manual_data_update',
        type: 'filter_group',
        data: [],
        label: 'Last Data Change',
        tooltip: null,
        placeholders: null,
        endpoint: '',
        depends_on: [],
        groupItems: [],
      });

      const lastIndexData = tempFilterData[tempFilterData.length - 1] as BaseFilter;
      lastIndexData.groupItems?.push(filterField);

      const lastAutomaticUpdate = filterGroup.find(
        item => item.column === 'last_automatic_data_update'
      );

      if (lastAutomaticUpdate) {
        lastIndexData.groupItems?.push(lastAutomaticUpdate);
      }
    }
  });

  return tempFilterData;
};
