import React, { useCallback } from 'react';
import ReactSelect, { Props } from 'react-select';
// models
import { SelectOption } from '@optx/models/Option';
// components
import {
  MultiValueRemove,
  MultiValueLabel,
  ValueContainer,
  DropdownIndicator,
  Option,
} from '../components';
import { SelectAllMenuList, SelectableGroupHeading } from '../MultiSelect/components';
import { Styled } from '../MultiSelect/MultiSelect.styled';
import { MultiValueContainer } from './components';
import { isOptionDisabled } from '../utils';

const MultiSelectInline: React.FC<Props<SelectOption>> = ({
  options,
  value,
  onChange,
  ...restProps
}) => {
  // eslint-disable-next-line
  const MenuListMemoized = useCallback(SelectAllMenuList(), []);

  return (
    <Styled.MultiSelectWrapper>
      <ReactSelect
        options={options}
        value={value}
        onChange={onChange}
        hideSelectedOptions={false}
        components={{
          MultiValueContainer,
          MultiValueLabel,
          MultiValueRemove,
          ValueContainer,
          DropdownIndicator,
          Option,
          GroupHeading: SelectableGroupHeading,
          MenuList: MenuListMemoized,
        }}
        {...restProps}
        // Leave isMulti at the end to not be overriden.
        backspaceRemovesValue={false}
        isMulti
      />
    </Styled.MultiSelectWrapper>
  );
};

MultiSelectInline.defaultProps = {
  placeholder: 'search',
  classNamePrefix: 'multiselect',
  isClearable: false,
  hideSelectedOptions: false,
  closeMenuOnSelect: false,
  isOptionDisabled,
};

export default MultiSelectInline;
