import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { fetchReducer, fetchFailReducer } from '@optx/redux/feature/fetch/reducers';
import { CognitoLoginState, CognitoButton } from './interfaces';
import * as actions from './actions';

export const initialState: CognitoLoginState = {
  loading: false,
  error: '',
  cognitoButtons: [],
};

const fetchCognitoLoginURLSuccessReducer: CaseReducer<
  CognitoLoginState,
  PayloadAction<CognitoButton[]>
> = (draftState, action) => {
  draftState.loading = false;

  if (Array.isArray(action.payload)) {
    draftState.cognitoButtons = action.payload;
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.fetchCognitoLoginURL, fetchReducer)
    .addCase(actions.fetchCognitoLoginURLSuccess, fetchCognitoLoginURLSuccessReducer)
    .addCase(actions.fetchCognitoLoginURLFail, fetchFailReducer)
);

export default reducer;
