import { call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { Dictionary } from 'lodash';
// models
import { SearchPayloadRequest } from '@optx/models/api/contacts';
import { ViewOption } from '@optx/models/search';
import { PreselectedFilter } from '@optx/models/filters';
import { UpdateUserSettingsPayload } from '@optx/models/api/user';
import { SortByRule } from '@optx/models/table/sorting';
import { PageInfo } from '@optx/models/table/Pagination';
import { SelectOption } from '@optx/models/Option';
import { GridPayload } from '@optx/models/grid';
// utils
import { mapSortQuery, hasFilters, preProcessSearch } from '@utils/search';
import { normalizeViewsColumns } from '@optx/utils/utils';
// redux
import { actions } from '@features/grid/view';
import { selectors as userInformationSelectors } from '@redux/user/information';
import { selectors as outreachSearchSelectors } from '@redux/company-outreach/search';
import { actions as searchCountActions } from '@features/grid/search-count';
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as filterSelectors } from '@redux/company-outreach/filters';
import {
  actions as savedSearchesActions,
  selectors as savedSearchesSelectors,
} from '@redux/company/saved-searches';
import { actions as columnActions } from '@redux/company-outreach/columns';
import { getFiltersQuery } from '@redux/company/search/search/sagasReusable';
import { selectors as paginationSelectors } from '@features/grid/pagination';
import { searchCompanies, searchCompaniesFail } from '@features/grid/search/state/sagasReusable';

export function* resetViewToSourcingOrderSaga() {
  const { views } = yield select(savedSearchesSelectors.getViews);
  const defaultScoreValue: string = yield select(userInformationSelectors.getDefaultScore);

  const viewData = views.find(
    (view: ViewOption) => view.unique_id.toString() === 'sourcingOrder'
  ) as ViewOption;

  const { validatedColumns, validatedColumnsOrder } = normalizeViewsColumns(
    viewData,
    defaultScoreValue
  );

  yield put(
    actions.applyView({
      gridKey: 'outreach',
      data: {
        ...viewData,
        columns: validatedColumns,
        column_order: validatedColumnsOrder,
      },
    })
  );
}

export function* applyViewSaga(action: PayloadAction<GridPayload<ViewOption>>) {
  const {
    columns,
    column_order: columnOrder,
    pinned_columns: pinnedColumns,
    filters: viewFilters,
    searchKey: viewSearchKey,
    sortBy,
    search_criteria: searchCriteria,
    unique_id: id,
    title,
    search_info: searchInfo,
  } = action.payload.data;

  const gridKey = 'outreach';

  const pagination: PageInfo = yield select(paginationSelectors.getPagination(gridKey));
  const isCombinedSearch = searchInfo?.type === 'combined';
  const tableGridQuery: string = yield select(searchSelectors.getTableGridQuery(gridKey));
  const userPageNumber: number = yield select(userInformationSelectors.getResultsPageNumber);
  const analystIdFilter: [string | null, string | null] | SelectOption<string> | undefined =
    yield select(filterSelectors.getSourcingOptitonsFilterValues('analyst_id'));
  const columnNameSourcingOutReachFilter:
    | [string | null, string | null]
    | SelectOption<string>
    | undefined = yield select(filterSelectors.getSourcingOptitonsFilterValues('column_name'));
  let filter: Dictionary<PreselectedFilter> | undefined;
  let searchKey: string = yield select(searchSelectors.getSearchKey(gridKey));

  // @ts-ignore
  const listOfAnalystIds: number[] = analystIdFilter?.map(
    (list: { label: string; value: number }) => list.value
  );

  const sourcingOutReachColumnName = (columnNameSourcingOutReachFilter as SelectOption<string>)
    ?.value;

  if (hasFilters(searchCriteria) || id === 'default') {
    filter = viewFilters;
    searchKey = viewSearchKey ? preProcessSearch(viewSearchKey) : '';
  }

  if (!hasFilters(searchCriteria) && viewSearchKey) {
    filter = yield select(outreachSearchSelectors.getClearedFilter);
    searchKey = viewSearchKey ? preProcessSearch(viewSearchKey) : '';
  }

  yield put(columnActions.updateColumns(columns, columnOrder, pinnedColumns));

  if (isCombinedSearch || filter || sortBy) {
    const currentSortBy: SortByRule<any>[] = yield select(outreachSearchSelectors.getSorting);
    const currentFilter: Dictionary<PreselectedFilter> = yield select(
      outreachSearchSelectors.getFilters
    );
    let filterQuery: Dictionary<string | Array<number | string>> = yield call(
      getFiltersQuery,
      filter || currentFilter
    );

    // before we separated watchlist from advanced search page it is possible that
    // some saved searches include the list id
    // if we have old saved searches that include list id, remove it.
    // when applying a view we should not include the list id
    // @ts-ignore
    filterQuery = { ...filterQuery, ss_list_id: undefined, saved_list_id: undefined };

    const searchData: SearchPayloadRequest = {
      searchKey,
      fromSavedList: typeof id === 'number' ? id : undefined,
      listType: searchInfo?.type || '',
      analystId: listOfAnalystIds?.toString(),
      tableGridQuery,
      sourcingOutReachColumnName,
      filter: filterQuery,
      pagination: {
        ...pagination,
        pageNumber: 1,
      },
      sortBy: sortBy || currentSortBy,
    };

    const sessionSettings: Partial<UpdateUserSettingsPayload> = {};

    if (sortBy) {
      const sortQuery = queryString.stringify(mapSortQuery(sortBy), {
        arrayFormat: 'comma',
      });
      sessionSettings.sourcing_outreach_detail_sorting = sortQuery;
    }

    if (columns !== null) {
      sessionSettings.sourcing_outreach_detail_columns = columns;

      if (columnOrder) {
        sessionSettings.sourcing_outreach_detail_column_order = columnOrder;
      }
    }

    if (pinnedColumns !== null) {
      sessionSettings.sourcing_outreach_detail_pinned_columns = pinnedColumns;
    }

    const pageNumberChanged = searchData.pagination.pageNumber !== userPageNumber;

    if (pageNumberChanged) {
      sessionSettings.search_results_page_number = searchData.pagination.pageNumber;
    }

    const updateSession =
      filter && !sortBy && !columns && !pinnedColumns && !pageNumberChanged
        ? true
        : sessionSettings;

    const gridKey = 'outreach';

    try {
      yield call(
        searchCompanies,
        searchData,
        gridKey,
        filter || currentFilter,
        undefined,
        title,
        updateSession
      );

      if (filter) {
        yield put(searchCountActions.searchCount({ data: searchData, gridKey: 'outreach' }));
      }

      // yield put(paginationActions.changePaginationSuccess(searchData.pagination));
    } catch (error) {
      yield call(searchCompaniesFail, gridKey, error);
    }
  } else {
    yield put(
      savedSearchesActions.applyViewSuccess({ id, label: title, pageAlias: 'companyOutReach' })
    );
  }
}
