import React, { useState } from 'react';
import { Tooltip, Button } from 'antd';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
// redux
import { selectors } from '@features/equity-touch-integration/state/selectors/index';
// utils
import { mapSortQuery } from '@optx/utils/search';
import mapFilterToURLParams from '@utils/filters/mapFiltersToURLParams';
import { exportFileCSV } from '@utils/csvHandlerET';
// constants
import { COMPANY_CSV_SIZE_LIMIT } from '@constants/limit';

const FILE_NAME = 'saved search';

interface ExportCSVButtonProps {
  hideDropdown?: () => void;
  fileName?: string;
}

const ExportCSVButton: React.FC<ExportCSVButtonProps> = ({ hideDropdown, fileName }) => {
  const searchCompanyCount = useSelector(selectors.pagination.total);
  const { filter, normalized } = useSelector(selectors.filters.getFilters);
  const sortBy = useSelector(selectors.sort.sortBy);
  const [isLoading, setIsLoading] = useState(false);

  const handleExportCSV = () => {
    const params = mapFilterToURLParams(normalized, filter);
    const convertSortBy = mapSortQuery(sortBy);

    const parameterFilter = {
      ...convertSortBy,
      ...params,
    };

    const query = queryString.stringify(parameterFilter, {
      arrayFormat: 'comma',
    });

    setIsLoading(true);

    exportFileCSV(fileName || FILE_NAME, query).then(() => {
      setIsLoading(false);
    });

    hideDropdown && hideDropdown();
  };

  const disabled = !searchCompanyCount || searchCompanyCount > COMPANY_CSV_SIZE_LIMIT || isLoading;

  return (
    <Tooltip title="Up to 100K rows" placement="bottom">
      <Button type="text" onClick={handleExportCSV} disabled={disabled}>
        {isLoading ? 'Exporting' : 'Export to .csv'}
      </Button>
    </Tooltip>
  );
};

export default ExportCSVButton;
