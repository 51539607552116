/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dictionary, forEach } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, Modal, Button } from 'antd';
import styled from 'styled-components';
import { ModalProps } from 'antd/lib/modal';
// redux
import {
  actions as favoriteListsActions,
  selectors as favoriteListsSelectors,
} from '@redux/favorite-lists/lists';
import { selectors as searchCountSelectors } from '@features/grid/search-count';
import { selectors as listsSelectors } from '@redux/lists/details';
// validation
import { saveListSchema } from '@optx/common/validation/save-search';
// components
import { DropdownSaveSearch as Dropdown } from '@optx/components/common/dropdowns';
import { LoadingIcon } from '@optx/shared/view/molecules/loader';
// constants
import { COMPANY_CSV_SIZE_LIMIT } from '@constants/limit';
import SaveSearchAsListCompletion from './SaveSearchAsList/SaveSearchAsListCompletion';

interface ModalWrapperProps extends ModalProps {
  background?: string;
}

interface SaveSearchAsListButtonProps {
  hideDropdown?: () => void;
}

const ModalWrapper = styled(Modal)<ModalWrapperProps>`
  top: 50%;
  transform: translateY(-50%);
  padding: 0;

  .ant-modal-header,
  .ant-modal-footer {
    border-color: #e8e8e8;
  }

  .ant-modal-body {
    background: ${({ background }) => background || '#f3f6f9'};
  }

  th.ant-table-cell {
    padding: 19px 7px;
  }

  .ant-table-wrapper {
    max-height: 60vh;
    overflow-y: auto;
  }

  .ant-btn:first-child {
    display: inline-block;
  }
`;

const label = 'Save Search as List';

const SaveSearchAsList: React.FC<SaveSearchAsListButtonProps> = ({
  hideDropdown,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isSourceSrcub = useSelector(listsSelectors.isSourceScrubRoute);
  const companyCount = useSelector(searchCountSelectors.getSearchCount('advancedSearch'));
  const listCount = useSelector(
    searchCountSelectors.getSearchCount(isSourceSrcub ? 'sslists' : 'watchlists')
  );
  const myCompaniesCount = useSelector(searchCountSelectors.getSearchCount('myCompanies'));
  const savedLists = useSelector(favoriteListsSelectors.getLists);
  // its chunk approach
  const isCompleted = useSelector(favoriteListsSelectors.isCompleted);
  const isCanceled = useSelector(favoriteListsSelectors.isCancel);
  const getPercentage = useSelector(favoriteListsSelectors.getPercentage);
  const getTitle = useSelector(favoriteListsSelectors.getTitle);
  const isError = useSelector(favoriteListsSelectors.getError);

  const isMyCompanyPage = location.pathname.split('/')[1] === 'my-companies';
  const isUserLists = location.pathname.split('/')[1] === 'user-lists';
  const isSSLists = location.pathname.split('/')[1] === 'ss-lists';

  const [totalCompanies, setTotalCompanies] = useState(0);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [percentage, setPercentage] = useState<number>();
  const [confirmationVisibility, setConfirmationVisibility] = useState(false);
  const [clickedCancel, setClickedCancel] = useState(false);

  useEffect(() => {
    if (isMyCompanyPage) {
      setTotalCompanies(myCompaniesCount);
    } else if (isUserLists || isSSLists) {
      setTotalCompanies(listCount);
    } else {
      setTotalCompanies(companyCount);
    }
  }, [companyCount, isUserLists, isMyCompanyPage, listCount, myCompaniesCount, isSSLists]);

  useEffect(() => {
    setPercentage(((getPercentage as number) / totalCompanies) * 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPercentage]);

  const savedListTitles = useMemo(() => {
    const titleDictionary: Dictionary<boolean> = {
      Favorites: true,
    };
    forEach(savedLists, (list, key) => {
      titleDictionary[list.title] = true;
    });

    return titleDictionary;
  }, [savedLists]);

  const validationSchema = useMemo(() => saveListSchema(savedListTitles), [savedListTitles]);

  const saveSearchAsList = useCallback(
    (title: string) => {
      setPopoverVisible(true);
      hideDropdown && hideDropdown();
      dispatch(favoriteListsActions.saveSearchAsList({ title }));
    },
    [dispatch, hideDropdown]
  );

  const onCancel = () => {
    if (!isCanceled) {
      if (isCompleted && !isError) {
        handleOk();
      } else {
        setConfirmationVisibility(true);
        hideDropdown && hideDropdown();
      }
    }
  };

  const handleOk = () => {
    if (!getTitle) {
      setClickedCancel(false);
      setPopoverVisible(false);
      dispatch(favoriteListsActions.resetSaveSearchAsList());
    } else {
      dispatch(favoriteListsActions.tryAgainSaveSearchAsList());
    }
  };

  const handleOkConfirmation = () => {
    if (isCompleted) {
      setPopoverVisible(false);
    } else {
      setClickedCancel(true);
      dispatch(favoriteListsActions.cancelSaveSearchAsList());
    }

    handleCancelConfirmation();
  };

  const handleCancelConfirmation = () => {
    setConfirmationVisibility(false);
    hideDropdown && hideDropdown();
  };

  const saveAsListMyCompanies = !myCompaniesCount || myCompaniesCount > COMPANY_CSV_SIZE_LIMIT;

  const saveAsList = !companyCount || companyCount > COMPANY_CSV_SIZE_LIMIT;

  const saveAsListWatchList = !listCount || listCount > COMPANY_CSV_SIZE_LIMIT;

  return (
    <>
      <ModalWrapper
        title={label}
        visible={popoverVisible}
        onOk={handleOk}
        onCancel={onCancel}
        width={840}
        okButtonProps={{ loading: !isCompleted }}
        okText={!getTitle ? 'OK' : 'Try Again'}
        cancelButtonProps={{
          style: { display: isCompleted ? 'none' : 'initial' },
          disabled: isCanceled,
        }}
        destroyOnClose
        maskClosable={false}
      >
        {clickedCancel && !isCompleted ? (
          <LoadingIcon />
        ) : (
          <SaveSearchAsListCompletion
            totalCompanies={totalCompanies}
            percentage={percentage as number}
          />
        )}
      </ModalWrapper>
      <Dropdown
        onSave={saveSearchAsList}
        onCancel={hideDropdown}
        validationSchema={validationSchema}
        label={label}
        disabled={
          isMyCompanyPage
            ? saveAsListMyCompanies
            : isUserLists || isSSLists
            ? saveAsListWatchList
            : saveAsList
        }
        Toggle={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Tooltip title="Up to 100K rows" placement="left">
            <Button
              type="text"
              disabled={
                isMyCompanyPage
                  ? saveAsListMyCompanies
                  : isUserLists || isSSLists
                  ? saveAsListWatchList
                  : saveAsList
              }
            >
              {label}
            </Button>
          </Tooltip>
        }
      />

      <Modal
        title="Confirmation"
        visible={confirmationVisibility}
        onOk={handleOkConfirmation}
        okButtonProps={{ disabled: isCanceled }}
        onCancel={handleCancelConfirmation}
      >
        Are you sure you want to cancel the operation?
      </Modal>
    </>
  );
};

export default SaveSearchAsList;
