import React, { useState, useLayoutEffect, useCallback, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useVT } from 'virtualizedtableforantd4';
import { TableProps } from 'antd/es/table';
import { useSize } from '@umijs/hooks';
// redux
import { selectors } from '@optx/features/pipeline-report/state';
// hooks
import { useIsLaptopOrSmaller } from '@optx/common/hooks/responsive';
import useIsMount from '@optx/common/hooks/useIsMount';
import useNonVirtualizedTable from '@optx/common/hooks/useNonVirtualizedTable';
// components
import Table from '@shared/view/organims/Table';
// local
import columns from './columns';

const PipelineTable: React.FC<TableProps<any>> = props => {
  const data = useSelector(selectors.pipelineReportCompanies);
  const vtImperativeScroll = useRef<any>();

  const useIsLaptopSmaller = useIsLaptopOrSmaller();
  const virtualizedActive = useNonVirtualizedTable();
  const isMount = useIsMount();

  const [windowSize] = useSize(window.document.documentElement);
  const [gridSize, tableWrapperRef] = useSize<HTMLDivElement>();
  const [currentScroll, setCurrentScroll] = useState(0);

  /**
   * Each time new pagination is done - resets the scroll.
   */
  useEffect(() => {
    if (!isMount) {
      vtImperativeScroll.current?.scrollTo(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // fix table being cropped when navigating to other pages and coming back
    setTimeout(() => {
      vtImperativeScroll.current?.scrollTo(1);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [vt] = useVT(
    () => ({
      initTop: 1,
      scroll: { y: currentScroll },
      ref: vtImperativeScroll,
    }),
    [currentScroll]
  );

  const getNextScroll = useCallback(() => {
    let tableHeaderHeight = 0;

    if (tableWrapperRef.current) {
      tableHeaderHeight =
        tableWrapperRef.current.querySelector('.ant-table-header')?.getBoundingClientRect()
          .height || 0;
    }

    const virtualScrollSubstractElements = document.querySelectorAll(
      '[data-virtual-scroll-substract="companies-in-et"]'
    );

    let substractHeight = 0;

    virtualScrollSubstractElements.forEach(element => {
      const styles = window.getComputedStyle(element, null);
      const margin = parseInt(styles.marginBottom);

      substractHeight += element.getBoundingClientRect().height + margin;
    });

    // Add in styles bottom padding the same size as pagination.
    const nextScroll =
      windowSize.height !== undefined ? windowSize.height - tableHeaderHeight - substractHeight : 0;

    return nextScroll;
  }, [tableWrapperRef, windowSize.height]);

  /**
   * Update scroll each time the size of the wrapper changes or window size changes.
   * This can happen when let's say a column is added/removed and increases/decreases the height of table header.
   * Scroll doesn't include table header size.
   */
  useLayoutEffect(() => {
    setTimeout(() => {
      const nextScroll = getNextScroll();

      setCurrentScroll(nextScroll);
    }, 0);
  }, [gridSize.height, getNextScroll]);

  return (
    <div ref={tableWrapperRef} style={{ marginTop: 20 }}>
      <Table
        columns={columns}
        className="pipeline-table"
        dataSource={data}
        rowKey={'company_id'}
        size="small"
        bordered
        style={{ height: 450 }}
        {...props}
        // virtualization
        scroll={{ x: useIsLaptopSmaller ? 1500 : '100%', y: currentScroll || 250 }}
        components={virtualizedActive ? vt : undefined}
      />
    </div>
  );
};

export default PipelineTable;
