import { useEffect } from 'react';
import { useHistory } from 'react-router';
import queryString, { ParsedQuery } from 'query-string';
import { merge } from 'lodash';

interface UseLocationAwareTabsOptions {
  onChange?: (activeKey: string) => void;
  queryKey?: string;
  defaultActiveKey: string;
  activeKey?: string;
  setActiveKey?: (activeKey: string) => void;
}

const defaultOptions: UseLocationAwareTabsOptions = {
  queryKey: 'tab',
  defaultActiveKey: '',
};

const useLocationAwareTabs = (options: UseLocationAwareTabsOptions) => {
  let finalOptions = options;

  if (options !== defaultOptions) {
    finalOptions = merge(defaultOptions, options, {});
  }

  const { onChange, queryKey, defaultActiveKey, activeKey, setActiveKey } = finalOptions;

  const history = useHistory();
  const location = history.location;
  const query = queryString.parse(location.search);
  const value = (query[queryKey!] as string) || undefined;

  const updateLocation = (newKey: string) => {
    // include other query options beside tab if there are any.
    const newQuery: ParsedQuery<string> = {
      ...query,
      [queryKey!]: newKey,
    };
    delete newQuery.field;
    history.replace({ search: queryString.stringify(newQuery) });
  };

  // Update location search query with default active key if there is no tab state saved in query on mount.
  useEffect(() => {
    if (location.search) {
      if (value === null || value === undefined) {
        updateLocation(defaultActiveKey);
      }
    } else {
      updateLocation(defaultActiveKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set active tab if url query was updated.
  useEffect(() => {
    const query = queryString.parse(location.search);

    if (query.tab && query.tab !== activeKey) {
      setActiveKey && setActiveKey(query.tab as string);
    }
  }, [location, setActiveKey, activeKey]);

  const handleTabChange = (activeKey: string) => {
    updateLocation(activeKey);
    onChange && onChange(activeKey);
  };

  return {
    defaultActiveKey: value,
    onChange: handleTabChange,
  };
};

export default useLocationAwareTabs;
