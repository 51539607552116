import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Button, Tooltip, Typography } from 'antd';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
// models
import { ParsedUpcomingTouchesNotificationData } from '@optx/models/notifications';
// constants
import { LONG_MONTH_YEAR_DATE_FORMAT } from '@optx/constants/format/date';
// utils
import { getCompanyProfileURL } from '@utils/routes';
// redux
import { selectors as userSelectors } from '@redux/user/information';
// components
import { IconCompany } from '@components/icons';
import { ScoreTrending } from '@components/common/trending';
import KeyPerformanceIndicator from '@components/common/Text/KeyPerformanceIndicator';

interface TouchesNotificationItemProps {
  name: string;
  data: ParsedUpcomingTouchesNotificationData;
  onDismissTouch: (id: number) => void;
}

const touchesNotificationText = (
  notes: string | null,
  reasonTag: string,
  isReasonTag: boolean,
  name?: string
) => {
  const textLength = name === 'upcomingTouches' ? 41 : 49;

  if (isReasonTag) {
    return (
      <Tooltip title={reasonTag.length > textLength && reasonTag}>
        <b>
          {reasonTag.length > textLength ? reasonTag.slice(0, textLength).concat('...') : reasonTag}
        </b>
      </Tooltip>
    );
  }

  return <span>{notes || ''}</span>;
};

const getUrl = (item: any) => {
  const hasId = item.company_id;

  if (!hasId) {
    return '#';
  }

  return getCompanyProfileURL(item.company_id);
};

const TouchesNotificationItem: React.FC<TouchesNotificationItemProps> = ({
  name,
  data,
  onDismissTouch,
}) => {
  const defaultOptxScore = useSelector(userSelectors.getDefaultScore);

  return (
    <div className={`notification-item ${name}`}>
      {map(data.data, (value, key) => {
        if (value && value.dateData.length) {
          return (
            <div key={key.toString()} className="notification-item__block">
              {name !== 'pastDueTouches' && (
                <div className="notification-item__title">
                  {key}
                  {value.dateFrame && ':'}{' '}
                  <span className="notification-item__title-details">{value.dateFrame}</span>
                </div>
              )}
              {value.dateData.map((item, index) => {
                return (
                  <div key={item.unique_id} className="media-object notification-item__main">
                    <div className="media-object-section notification-item__left">
                      <div className="notification-item__avatar">
                        <Link
                          to={getUrl(item)}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            cursor: 'pointer',
                            pointerEvents: 'auto',
                          }}
                        >
                          {item.company_logo && (
                            <img src={item.company_logo} alt="notification-logo" />
                          )}
                          {!item.company_logo && <IconCompany />}
                        </Link>
                      </div>
                    </div>
                    <div className="media-object-section" style={{ flex: 1 }}>
                      <div className="notification-item__info">
                        <div className="notification-item__company-name">
                          <Tooltip title={item.company_name.length > 34 && item.company_name}>
                            <Link
                              to={getUrl(item)}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                zIndex: 8,
                                cursor: 'pointer',
                                pointerEvents: 'auto',
                              }}
                            >
                              <Typography.Text ellipsis>
                                {item.company_name.length > 34
                                  ? item.company_name.slice(0, 34).concat('...')
                                  : item.company_name}
                              </Typography.Text>
                            </Link>
                          </Tooltip>

                          <div className="notification-item__message">
                            <span>
                              {touchesNotificationText(item.notes, item.reason_tag, true, name)}
                            </span>
                            <span>
                              DATE:{' '}
                              {item.next_update
                                ? moment(item.next_update).format('MMMM DD, YYYY')
                                : ''}
                            </span>
                            <div className="notification-item__message-bottom">
                              {touchesNotificationText(item.notes, item.reason_tag, false)}
                              {['upcomingTouches', 'pastDueTouches'].includes(name) && (
                                <Button
                                  type="primary"
                                  style={{ zIndex: 2 }}
                                  size="small"
                                  ghost
                                  onClick={() => onDismissTouch(item.unique_id)}
                                >
                                  Dismiss
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="notification-item__top">
                          {item.last_touch_date && (
                            <KeyPerformanceIndicator
                              label="Last Touch"
                              value={item.last_touch_date}
                              formatValue={val => moment(val).format(LONG_MONTH_YEAR_DATE_FORMAT)}
                            />
                          )}
                          {item.extra_context && (
                            <ScoreTrending
                              score={
                                defaultOptxScore !== 'il'
                                  ? item.extra_context.optx_score!
                                  : item.extra_context.il_optx_score!
                              }
                              growth={
                                defaultOptxScore !== 'il'
                                  ? item.extra_context.optx_score_growth!
                                  : item.extra_context.il_optx_score_growth!
                              }
                              label={defaultOptxScore !== 'il' ? 'OPTX SCORE' : 'IL OPTX SCORE'}
                              customFontSize="16px"
                              isPSGCompany={item.is_psg_company}
                              isFromNotifications
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }

        return null;
      })}
    </div>
  );
};

TouchesNotificationItem.defaultProps = {
  name: '',
};

export default TouchesNotificationItem;
