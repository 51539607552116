import DropdownAdd from '@optx/components/DropdownAdd';
import { Col, Button, Typography } from 'antd';
import styled, { css } from 'styled-components';

const { Text } = Typography;

const CompanyNameWrapper = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const CompanyLocation = styled(Text)`
  svg {
    width: 14px;
    margin-right: 6px;
  }
  position: relative;
`;

const CompanyName = styled.p`
  margin: 0;
  font-size: 20px;
  font-weight: bold;
`;

const Icons = styled(Col)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-top: 15px;
  svg {
    font-size: 22px;
  }
`;

const WebsiteIcon = styled.a`
  margin-right: 20px;
  color: #334d6e;

  &:hover {
    color: #1890ff;
  }

  &.not-active {
    color: red;
  }
`;

const StyledDropDown = styled(DropdownAdd)`
  svg {
    color: rgb(175, 189, 209);
    font-size: 30px;
    transition: color 0.3s;

    &:hover {
      color: #1890ff;
    }
  }

  &.ant-dropdown-open {
    svg {
      color: #1890ff;
    }
  }
`;

const Wrapper = styled(Col)`
  display: flex;
  margin: 30px 0;
  align-items: 'center';
`;

const ArrowButton = styled(Button)`
  color: rgb(24, 144, 255);
  &:hover {
    color: rgb(24, 144, 255);
  }

  ${props =>
    props.disabled &&
    css`
      .ant-icon {
        color: 'rgb(175, 189, 209)';
      }
    `}
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

export const CardHeaderStyles = {
  CompanyNameWrapper,
  CompanyName,
  Icons,
  WebsiteIcon,
  Wrapper,
  ArrowButton,
  ButtonWrapper,
  CompanyLocation,
  StyledDropDown,
};

export default CardHeaderStyles;
