import { createSelector } from 'reselect';
import * as localSelectors from '../share-list/selectors';
import { selectFeatureState } from './selectFeature';

const selectShareListState = createSelector(selectFeatureState, state => state.shareList);

// slice selectors
const isLoadingInfo = createSelector(selectShareListState, localSelectors.isLoading);

const error = createSelector(selectShareListState, localSelectors.getError);

const isSubmitting = createSelector(selectShareListState, localSelectors.isSubmitting);

const failedLoadingInfo = createSelector(selectShareListState, localSelectors.failedLoadingInfo);

const listOwner = createSelector(selectShareListState, localSelectors.getListOwner);

const listType = createSelector(selectShareListState, localSelectors.getListType);

const editedSharedList = createSelector(selectShareListState, localSelectors.getEditedSharedList);

/**
 * All/owner access rights.
 */
const accessRightsOptions = createSelector(
  selectShareListState,
  localSelectors.getAccessRightsOptions
);

/**
 * Other users available access rights excepting owner.
 */
const otherUsersAccessRightsOptions = createSelector(
  selectShareListState,
  localSelectors.getOtherUsersAccessRightsOptions
);

const sharedListUsers = createSelector(selectShareListState, localSelectors.getSharedListUsers);

export default {
  isLoadingInfo,
  error,
  isSubmitting,
  failedLoadingInfo,
  listOwner,
  listType,
  editedSharedList,
  accessRightsOptions,
  otherUsersAccessRightsOptions,
  sharedListUsers,
};
