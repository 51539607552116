import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// models
import { ChartResponse } from '@optx/models/feature/equity-touch-integration/charts';
import { ChartState } from '../interfaces';
// redux
import * as actions from '../actions/charts';

const initialState: ChartState = {
  data: [],
  error: '',
  loading: false,
};

const fetchChartsReducer: CaseReducer<ChartState> = draftState => {
  draftState.loading = true;
};

const fetchChartsSuccessReducer: CaseReducer<ChartState, PayloadAction<Array<ChartResponse>>> = (
  draftState,
  action
) => {
  const mappedData = action.payload
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .reduce((acc: any, curr, index, data) => {
      if (curr.x_axis.startsWith('Q')) {
        const date = new Date(curr.date);
        const year = date.getFullYear();

        const nextDate = new Date(data[index + 1]?.date);

        if (nextDate.getFullYear() !== year) {
          acc.push({
            ...curr,
            x_axis: new Date(curr.date).getFullYear().toString(),
          });
        } else {
          acc.push({
            ...curr,
            x_axis: '',
          });
        }

        return acc;
      }

      acc.push(curr);

      return acc;
    }, []);

  draftState.data = mappedData;
  draftState.loading = false;
};

const fetchChartsFailReducer: CaseReducer<ChartState, PayloadAction<string>> = (
  draftState,
  action
) => {
  draftState.loading = false;
  draftState.error = action.payload;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.fetchCharts, fetchChartsReducer)
    .addCase(actions.fetchChartsSuccess, fetchChartsSuccessReducer)
    .addCase(actions.fetchChartsFail, fetchChartsFailReducer)
);

export default reducer;
