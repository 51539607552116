import React, { useContext } from 'react';
// components
import { Context } from '../common/Context';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

const Steps: React.FC = () => {
  const { step } = useContext(Context);
  let component = null;

  switch (step) {
    case 0:
      component = <Step1 />;
      break;
    case 1:
      component = <Step2 />;
      break;
    case 2:
      component = <Step3 />;
      break;
    default:
      return null;
  }

  return <>{component}</>;
};

export default Steps;
