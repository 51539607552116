import React from 'react';
import moment from 'moment';
// models
import { SourceScrubList } from '@optx/models/SourceScrubList';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
import globalConfig from '@optx/constants/config';
// components
import CellLink from '../CellLink';

interface TableCellDefaultProps {
  value: any;
  row: SourceScrubList;
}

const TableCellDefault: React.FC<TableCellDefaultProps> = ({ value, row }) => {
  return (
    <CellLink id={row.id?.toString()}>
      {value && value !== DEFAULT_CELL_VALUE
        ? moment(value).format(globalConfig.grid_date.DATE_FORMAT)
        : DEFAULT_CELL_VALUE}{' '}
    </CellLink>
  );
};

export default TableCellDefault;
