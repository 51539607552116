import React, { useMemo, useState } from 'react';
import { RadioButtonProps } from 'antd/lib/radio/radioButton';
import moment, { Moment } from 'moment';
import { DateRangeOption } from '@optx/models/Option';
import { ISO_DATE_FORMAT } from '@constants/format/date';
import Styled from './styled';

// rc-picker types
type EventValue<DateType> = DateType | null;
type RangeValue<DateType> = [EventValue<DateType>, EventValue<DateType>] | null;

interface RadioButtonPickerProps extends Omit<RadioButtonProps, 'onChange'> {
  option: DateRangeOption;
  onChange: (option: DateRangeOption) => void;
}

type DateValue = string | null;

const RadioButtonPicker: React.FC<RadioButtonPickerProps> = ({
  children,
  option,
  onChange,
  ...restProps
}) => {
  const [localValues, setLocalValues] = useState<[DateValue, DateValue]>([null, null]);
  const [customDateOpened, setCustomDateOpened] = useState(false);
  // Control active date when selection is done.
  const [activePickerIndex, setActivePickerIndex] = useState<0 | 1>(0);

  const datePickerRadioClick = () => {
    if (!customDateOpened) {
      setActivePickerIndex(0);
      setCustomDateOpened(prevState => !prevState);
      setLocalValues([null, null]);
    }
  };

  const datePickerChange = (values: RangeValue<Moment>) => {
    setCustomDateOpened(false);
    const [start, end] = values as [Moment, Moment];
    const newOption: DateRangeOption = {
      ...option,
      start: start.format(ISO_DATE_FORMAT),
      end: end.format(ISO_DATE_FORMAT),
    };

    setLocalValues([newOption.start, newOption.end]);
    onChange(newOption);
  };

  const handleCalendarChange = () => {
    if (activePickerIndex === 0) {
      setActivePickerIndex(1);
    } else {
      setActivePickerIndex(0);
    }
  };

  const rangePickerValue = useMemo(
    () =>
      localValues[0] !== null
        ? (localValues.map(value => moment(value)) as [Moment, Moment])
        : undefined,
    [localValues]
  );

  return (
    <Styled.RadioButton onClick={datePickerRadioClick} {...restProps}>
      {children}
      <Styled.RangePicker
        value={rangePickerValue}
        open={customDateOpened}
        onChange={datePickerChange}
        onOpenChange={setCustomDateOpened}
        activePickerIndex={activePickerIndex}
        onCalendarChange={handleCalendarChange}
      />
    </Styled.RadioButton>
  );
};

export default RadioButtonPicker;
