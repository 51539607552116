import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// models
import { ListAccessRights } from '@models/WatchList';
import { TableCellSearches } from '@models/table/Cell';
// utils
import { getLocationMainGridKey } from '@utils/grid';
import { hasFilters } from '@optx/utils/search';
// redux
import { actions as searchActions } from '@features/grid/search';
import { actions as savedSearchesActions } from '@redux/company/saved-searches';
import { selectors as userListSelectors } from '@redux/user/user-list';
// components
import DropdownDot from '@components/feature/drop-down/saved-searches/ListActions';

const TableCellActions: React.FC<TableCellSearches> = ({ row }) => {
  const userListShare = useSelector(userListSelectors.getUserList);
  const { unique_id: uniqueId, title, shared_with: sharedWith, search_info } = row;
  const type = search_info?.type || '';
  const dispatch = useDispatch();

  const deleteSearch = useCallback(
    (searchId: number | string) => {
      dispatch(savedSearchesActions.deleteSearch({ id: searchId }));

      const gridKey = getLocationMainGridKey();
      if (gridKey) dispatch(searchActions.deleteSavedList({ gridKey, data: title }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const renameSearch = useCallback(
    (title: string, searchId: number | string) =>
      dispatch(savedSearchesActions.renameSearch(searchId, title)),
    [dispatch]
  );

  return (
    <DropdownDot
      type={type}
      handleDeleteList={deleteSearch}
      handleRenameList={renameSearch}
      isRight
      listId={uniqueId || 0}
      title={title}
      sharedWith={sharedWith}
      userListShare={userListShare}
      accessRights={ListAccessRights.Edit}
      hasFilters={hasFilters(row.search_criteria)}
    />
  );
};

export default TableCellActions;
