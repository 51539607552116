import { takeLatest, call, all, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
// models
import {
  WatchlistsTabsPaginationActions,
  WatchlistsTabsPaginationActionsKeys,
} from '@optx/models/WatchList';
import { PageInfo } from '@optx/models/table/Pagination';
import { SortByRule } from '@optx/models/table/sorting';
// redux
import { searchLists, selectors as watchlistSelector } from '@redux/favorite-lists/lists';
import {
  searchSourceScrubLists,
  selectors as sourceScrubSelectors,
} from '@redux/user/source-scrub-lists';
import {
  searchSavedSearches,
  selectors as savedCompanySearchesSelectors,
} from '@redux/company/saved-searches';
import {
  searchSavedSearches as contactSearchSavedSearches,
  selectors as savedContactSearchesSelectors,
} from '@redux/contacts/saved-searches';
import * as actions from './actions';

// when we change page size in one tab, make requests
// and update the rest of the tabs
function* listsAndSearchesPaginationActions(
  action: WatchlistsTabsPaginationActionsKeys,
  pagination: PageInfo
) {
  switch (action) {
    case 'watchlists': {
      const query: string = yield select(watchlistSelector.getQuery);
      const sortBy: Array<SortByRule<any>> = yield select(watchlistSelector.getSortBy);
      yield call(searchLists, { query, pagination, sortBy: sortBy || [], showEmptyLists: false });
      break;
    }

    case 'sourceScrub': {
      const query: string = yield select(sourceScrubSelectors.getQuery);
      const sortBy: Array<SortByRule<any>> = yield select(sourceScrubSelectors.getSort);
      yield call(searchSourceScrubLists, { query, pagination, sortBy: sortBy || [] });

      break;
    }

    case 'companySearches': {
      const query: string = yield select(savedCompanySearchesSelectors.getQuery);
      yield call(searchSavedSearches, { query, pagination });
      break;
    }

    case 'contactSearches': {
      const query: string = yield select(savedContactSearchesSelectors.getQuery);
      yield call(contactSearchSavedSearches, { query, pagination });
      break;
    }

    default:
      break;
  }
}

function* updateMyWatchlistTabsPaginationSaga(
  action: PayloadAction<WatchlistsTabsPaginationActions>
) {
  const { actions, pagination } = action.payload;
  yield all(actions.map(action => call(listsAndSearchesPaginationActions, action, pagination)));
}

export default function* contactsSavedSearchesSaga() {
  yield takeLatest(actions.updateMyWatchlistTabsPagination, updateMyWatchlistTabsPaginationSaga);
}
