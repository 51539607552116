import { useRef, useEffect } from 'react';
import { debounce } from 'lodash';
/**
 * This hook leverages lodash's debounce function to delay the execution of the
 * provided callback until after a specified delay has elapsed since the last time
 * the debounced function was invoked. This is particularly useful for scenarios
 * such as search input where you want to limit the rate of API calls.
 *
 * @param {function} callback - The function to be debounced. This function will be called
 * with the latest value after the delay period has passed.
 * @param {number} delay - The delay in milliseconds to wait before invoking the callback.
 * @returns {function} - The debounced version of the callback function.
 */

function useDebouncedSearch(callback: (value: string) => void, delay: number) {
  const debouncedCallbackRef = useRef(debounce(callback, delay));

  useEffect(() => {
    const debouncedCallback = debouncedCallbackRef.current;

    return () => {
      debouncedCallback.cancel();
    };
  }, []);

  return debouncedCallbackRef.current;
}

export default useDebouncedSearch;
