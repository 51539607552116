import { createSelector } from 'reselect';
import { selectors as localSelectors } from '../../score';
import { selectFeatureState } from './selectFeature';

const scoreGrowthState = createSelector(selectFeatureState, state => {
  return state.scoreGrowth;
});

// slice selectors
const getScoreGrowthData = createSelector(scoreGrowthState, localSelectors.getData);
const getPSGDate = createSelector(scoreGrowthState, localSelectors.getPSGDate);

const isLoading = createSelector(selectFeatureState, state => {
  return state.scoreGrowth.loading;
});

export default {
  getScoreGrowthData,
  getPSGDate,
  isLoading,
};
