import { createSelector } from 'reselect';
// redux
import { selectors as companyFiltersSelectors } from '@redux/company/filters';
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as filterSelectors } from '@features/grid/filter';
import { selectors as myCompaniesSortSelectors } from '@redux/my-companies/sort';
import { selectors as userSelectors } from '@redux/user/information';

export const isLoading = createSelector(
  companyFiltersSelectors.isLoading,
  searchSelectors.isLoading('myCompanies'),
  myCompaniesSortSelectors.isLoading,
  (loadingFilters, loadingMyCompaniesSearch, loadingMyCompaniesSort) =>
    loadingFilters || loadingMyCompaniesSearch || loadingMyCompaniesSort
);

export const shouldFetchMyCompanies = createSelector(
  userSelectors.loaded,
  companyFiltersSelectors.filtersLoaded,
  filterSelectors.getFiltersLoaded('myCompanies'),
  searchSelectors.fetched('myCompanies'),
  (loadedUser, loadedFilters, loadedMyCompaniesFilters, loadedMyCompanies) =>
    loadedUser && loadedFilters && loadedMyCompaniesFilters && !loadedMyCompanies
);
