import { createSelector } from 'reselect';
import { AppState } from '@optx/redux/interfaces';

const selectInBusinessEvaluation = (state: AppState) => state.companyInBusinessEvaluation;

export const isLoading = createSelector(selectInBusinessEvaluation, state => state.loading);

export const getCurrentCompany = createSelector(
  selectInBusinessEvaluation,
  state => state.companyId
);
