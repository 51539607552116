import moment, { Moment } from 'moment';
import { cloneDeep } from 'lodash';
// models
import {
  ChartBarIntervalKeys,
  ChartBarItem,
  ChartBars,
  ChartData,
  ChartIntervalKeys,
  ChartItem,
  SocialMediaChartData,
} from '@models/charts/data';
// constants
import { ISO_DATE_FORMAT } from '@optx/constants/format/date';

/**
 * check whether or not a date is part of an interval of time,
 * starting from the present. for instance, inside the last 3 months
 * @param {string} date the date to be checked
 * @param {string} numberOfMonths the period of time in months
 */
export const checkIntervalDate = (date: string, numberOfMonths: number) => {
  const firstDate = new Date(date);
  const months = new Date();
  months.setMonth(months.getMonth() - numberOfMonths);

  if (firstDate.getTime() - months.getTime() > 0) {
    return true;
  }

  return false;
};

/**
 * check whether or not to show data for a time interval
 * if the data inside a time period doesn't cover the whole interval
 * don't show it and return empty array for that time period
 * @param {ChartData} growthList the data coming from API
 */
export const clearIntervalData = (growthList: ChartData) => {
  const data = { ...growthList };

  for (const intervalKey of Object.keys(data)) {
    if (
      intervalKey === '6M' &&
      checkIntervalDate(data[intervalKey as ChartIntervalKeys].data[0].date, 3)
    ) {
      data['6M'].data = [];
      data['1Y'].data = [];
      data['2Y'].data = [];
      break;
    } else if (
      intervalKey === '1Y' &&
      checkIntervalDate(data[intervalKey as ChartIntervalKeys].data[0].date, 6)
    ) {
      data['1Y'].data = [];
      data['2Y'].data = [];
      break;
    } else if (
      intervalKey === '2Y' &&
      checkIntervalDate(data[intervalKey as ChartIntervalKeys].data[0].date, 12)
    ) {
      data['2Y'].data = [];
      break;
    }
  }

  return data;
};

/**
 * calculate the quarter based on a date
 * @param intervalStartDate the date used to return quarter
 */
export const getInitialIntervalQuarter = (intervalStartDate: Date) => {
  let quarter = '';

  for (let i = 0; i < 12; i++) {
    if (intervalStartDate.getMonth() === i) {
      quarter = `Q${Math.ceil((i + 1) / 3)} ${moment(intervalStartDate).format('YY')}`;
      break;
    }
  }

  return quarter;
};

/**
 * add empty labels that will be displayed inside the graph
 * the graph's timeline should show all the months/quarters from the time period
 * if the data doesn't start from the first month/quarter, add empty labels at the beginning
 * @param {ChartItem[]} intervalData all data for a time period
 * @param {number} numberOfMonths the time period, calculated using months
 */
export const addEmptyLabels = (intervalData: ChartItem[], numberOfMonths: number) => {
  const data = [...intervalData];
  const intervalStartDate = new Date();
  intervalStartDate.setMonth(intervalStartDate.getMonth() - numberOfMonths);

  // we are displaying labels manually.
  // we need to check if we have to add empty labels and if so
  // we need to recalculate the points position, because from API,
  // the points always start from 0, but we need to take into account
  // the time period for which we have no data
  const initialQuarter = getInitialIntervalQuarter(intervalStartDate);
  let distinctMonths = 0;

  // to check whether or not we need to add empty labels and recalculate point positions,
  // for year intervals we check the starting quarter and for months, instead of checking
  // the starting month, we check the number of distinct months inside the data, to avoid
  // having the first point starting from a different position other than 0
  if (numberOfMonths < 12) {
    data.forEach((point, index) => {
      if (
        index < data.length - 1 &&
        moment(point.date).format('M') !== moment(data[index + 1].date).format('M')
      ) {
        distinctMonths++;
      }
    });
  }

  if (
    (numberOfMonths > 6 && intervalData[0].quarter !== initialQuarter) ||
    (numberOfMonths < 12 && distinctMonths < numberOfMonths - 1)
  ) {
    const startDate = moment(new Date()).subtract(numberOfMonths, 'months');
    const totalDays = moment(intervalData[intervalData.length - 1].date).diff(startDate, 'days');

    data.forEach((point, index) => {
      if (index <= data.length - 1) {
        data[index].position = (moment(point.date).diff(startDate, 'days') * 100) / totalDays;
      }
    });

    // also add the start date for the period, used as a start point for
    // displaying the labels
    data.unshift({
      date: moment(intervalStartDate).format(ISO_DATE_FORMAT),
      quarter: '',
    });
  }

  return data;
};

/**
 * parse data coming from api, for each particular time period
 * @param {ChartData} growthList data coming from api
 */
export const parseLineGraphDataWithPosition = (growthList: ChartData) => {
  const data = clearIntervalData(growthList);
  Object.keys(data).forEach(intervalKey => {
    const intervalData = data[intervalKey as ChartIntervalKeys].data;

    switch (intervalKey) {
      case '3M':
        if (intervalData.length) {
          data[intervalKey as ChartIntervalKeys].data = addEmptyLabels(intervalData, 3);
        }

        break;
      case '6M':
        if (intervalData.length) {
          data[intervalKey as ChartIntervalKeys].data = addEmptyLabels(intervalData, 6);
        }

        break;
      case '1Y':
        if (intervalData.length) {
          data[intervalKey as ChartIntervalKeys].data = addEmptyLabels(intervalData, 12);
        }

        break;
      case '2Y':
        if (intervalData.length) {
          data[intervalKey as ChartIntervalKeys].data = addEmptyLabels(intervalData, 24);
        }

        break;

      default:
        break;
    }
  });

  return data;
};

/**
 * calculate individual graph points position
 * @param {ChartData} growthList graph data
 */
export const parseLineGraphDataWithoutPosition = (growthList: ChartData) => {
  const data: ChartData = clearIntervalData(growthList);

  Object.keys(data).forEach(intervalKey => {
    const key = intervalKey as ChartIntervalKeys;
    let intervalData = data[key].data;

    if (intervalData.length) {
      const numberOfMonths = getNoOfMonths(key);
      intervalData = addEmptyLabels(intervalData, numberOfMonths);
      // used to calculate the position on the graph, using milliseconds
      // get the milliseconds for the whole interval,
      // calculate the position proportionally
      const latestDate = moment(intervalData[intervalData.length - 1].date);
      let formattedDate: Moment | string = moment(intervalData[0].date);
      let updatedDate: string | null = null;

      if (!formattedDate.isValid()) {
        const [day, month, year] = intervalData[0].date.split('.');
        const dateNew = new Date(+year, +month - 1, +day);
        formattedDate = moment(dateNew).format(ISO_DATE_FORMAT);
        updatedDate = `${year}-${month}-${day}`;
      }

      const totalNumberOfMilliseconds = latestDate.diff(formattedDate);
      intervalData.forEach((dataPoint, index) => {
        if (index === 0 && updatedDate) {
          intervalData[index].date = updatedDate;
        }

        const diff = latestDate.diff(dataPoint.date);
        intervalData[index].position =
          ((totalNumberOfMilliseconds - diff) * 100) / totalNumberOfMilliseconds || 0;
      });
    }

    data[key].data = intervalData;
  });

  return data;
};

/**
 * find and return the first item in the array that has data
 * @param {ChartItem[]} data the data belonging to a time period
 */
export const getFirstValidDate = (data: ChartItem[]) => {
  return data.find(item => item.quarter !== '');
};

/**
 * function to add empty bars for graph with time periods
 * for this type of graph, for each month/quarter there is only one corresponding value,
 * so we can tell exactly how many empty months/quarters we need to
 * add depending on the number of items returned from api
 * @param {ChartBarItem[]} intervalData all data for a time period
 * @param {number} numberOfMonths the time period, calculated using months
 * @param {boolean} isMonth check whether the data is represented with months or quarters
 */
export const addEmptyBars = (
  intervalData: ChartBarItem[],
  numberOfMonths: number,
  isMonth?: boolean,
  isYear?: boolean
) => {
  let data = intervalData;
  const intervalStartDate = new Date();
  intervalStartDate.setMonth(intervalStartDate.getMonth() - numberOfMonths);
  // label will be used to extract the month/quarter
  // ex. month: "Nov 20", ex. quarter: "Q4 20"
  const label = intervalData[0].x_axis;

  // different calculation depending on whether we're displaying by month or quarter or year
  if (isMonth) {
    const oldestMonth = Number(moment(label, 'MMM YY').format('M'));

    // check if we need to add empty labels
    if (oldestMonth !== intervalStartDate.getMonth()) {
      let startMonth = oldestMonth - 1;
      let currentYear = Number(moment(label, 'MMM YY').format('YYYY'));

      for (let i = numberOfMonths - intervalData.length; i > 0; i--) {
        // check if the time period required to add empty labels spans two consecutive years
        if (startMonth === 0) {
          startMonth = 12;
          currentYear -= 1;
        }

        data = [
          {
            x_axis: moment(`${currentYear} ${startMonth} 1`).format('MMM YY'),
            round_data: null,
            total_investment: null,
          },
          ...data,
        ];
        startMonth -= 1;
      }
    }
  } else if (isYear) {
    data = [];
    let currentYear = Number(moment(label.toString().slice(-2), 'YY').format('YYYY'));
    const currentDate = new Date().getFullYear();

    // generate data for each year from interval data to the current year
    if (currentYear < currentDate) {
      for (let i = currentYear; i <= currentYear; i++) {
        if (i === currentYear) {
          data.push({
            x_axis: i.toString(),
            total_investment: intervalData[0].total_investment,
            round_data: intervalData[0].round_data,
          });
        } else {
          data.push({
            x_axis: i.toString(),
            total_investment: null,
            round_data: null,
          });
        }
      }
    } else if (currentYear >= currentDate - numberOfMonths / 12) {
      // generate data for the last three years
      for (let i = currentYear - 2; i <= currentYear; i++) {
        if (i === currentYear) {
          data.push({
            x_axis: i.toString(),
            total_investment: intervalData[0].total_investment,
            round_data: intervalData[0].round_data,
          });
        } else {
          data.push({
            x_axis: i.toString(),
            total_investment: null,
            round_data: null,
          });
        }
      }
    }
  } else if (numberOfMonths / 3 - intervalData.length > 0) {
    let currentYear = Number(moment(label.toString().slice(-2), 'YY').format('YYYY'));
    let startQuarter = Number(label.toString().charAt(1)) - 1;

    // check if starting point should be from a new year
    if (startQuarter === 1) {
      startQuarter = 4;
      currentYear -= 1;
    }

    for (let i = numberOfMonths / 3 - intervalData.length; i > 0; i--) {
      if (startQuarter === 0) {
        startQuarter = 4;
        currentYear -= 1;
      }

      data = [
        {
          x_axis: `Q${startQuarter} ${moment(currentYear.toString()).format('YY')}`,
          round_data: null,
          total_investment: null,
        },
        ...data,
      ];
      startQuarter -= 1;
    }
  }

  return data;
};

/**
 * function to check and add empty labels for each time period
 * @param {ChartBars} chartData data from API
 */
export const parseBarData = (chartData: ChartBars) => {
  let data = chartData;

  Object.keys(data ?? {}).forEach(intervalKey => {
    const intervalData = data[intervalKey as ChartBarIntervalKeys].data;

    switch (intervalKey) {
      case '3M':
        if (intervalData.length) {
          data = { ...data, '3M': { data: addEmptyBars(intervalData, 3, true) } };
        }

        break;
      case '6M':
        if (intervalData.length) {
          data = { ...data, '6M': { data: addEmptyBars(intervalData, 6, true) } };
        }

        break;
      case '1Y':
        if (intervalData.length) {
          data = { ...data, '1Y': { data: addEmptyBars(intervalData, 12) } };
        }

        break;
      case '2Y':
        if (intervalData.length) {
          data = { ...data, '2Y': { data: addEmptyBars(intervalData, 24) } };
        }

        break;

      case 'ALL':
        if (intervalData.length === 1) {
          data = { ...data, ALL: { data: addEmptyBars(intervalData, 36, false, true) } };
        }

        break;

      default:
        break;
    }
  });

  return data;
};

/**
 * return number of months for time interval
 * @param {ChartBarIntervalKeys} intervalKey chart data object key
 */
// eslint-disable-next-line consistent-return
export const getNoOfMonths = (intervalKey: ChartBarIntervalKeys) => {
  switch (intervalKey) {
    case '3M':
      return 3;
    case '6M':
      return 6;
    case '1Y':
      return 12;
    case '2Y':
    default:
      return 24;
  }
};

/**
 * we are not displaying a label (tick) for each point, instead we are displaying
 * a label for each month/quarter, so we are creating and setting them manually
 * @param {ChartData} data chart data
 * @param {ChartIntervalKeys} key interval property name
 */
export const getChartTicks = (data: ChartData, key: ChartIntervalKeys) => {
  const tickList = [] as string[];
  let acc = 1;

  if (key === '1Y' || key === '2Y') {
    acc = 3;
  }

  for (let i = 0; i <= getNoOfMonths(key)!; i += acc) {
    const firstDate = data[key].data[0].date;

    if (key === '1Y' || key === '2Y') {
      const quarter = `Q${Math.ceil(Number(moment(firstDate).add(i, 'months').format('M')) / 3)}`;
      const year = `${moment(firstDate).add(i, 'months').format('YY')}`;
      tickList.push(`${quarter} ${year}`);
    } else {
      tickList.push(moment(firstDate).add(i, 'months').format('MMM YY'));
    }
  }

  return tickList;
};

/**
 * function to calculate values for inverted points to display the chart reversed
 * @param {SocialMediaChartData} data chart data coming from api
 */
export const parseReversedValues = (data: SocialMediaChartData) => {
  const chartData: SocialMediaChartData = cloneDeep(data);
  Object.keys(chartData).forEach(key => {
    if (chartData[key as ChartIntervalKeys].data.length) {
      const maxValue = Math.max(
        ...chartData[key as ChartIntervalKeys].data.map(item => item.value),
        0
      );
      chartData[key as ChartIntervalKeys].lowest_rank = maxValue;

      chartData[key as ChartIntervalKeys].data.forEach((chartPoint, index) => {
        chartData[key as ChartIntervalKeys].data[index].reversedValue =
          chartPoint.value === null ? null : maxValue - chartPoint.value;
      });
    }
  });

  return chartData;
};
