import { call, put, select } from 'redux-saga/effects';
// models
import { ListsSearchData } from '@models/lists/search';
// services
import { SavedSearchesService } from '@services/api';
import { FilterSource, PreselectedFilter } from '@optx/models/filters';
import { Dictionary } from 'lodash';
import { selectors as filterSourcesSelectors } from '../filters';
import { fetchSavedSearchesSuccess } from './actions';
import { selectors as filterSelectors } from '@optx/features/grid/filter';

// search
export function* searchSavedSearches(data: ListsSearchData) {
  const filterSources: FilterSource[] = yield select(filterSourcesSelectors.getCompanyFilters);
  const clearedFilters: Dictionary<PreselectedFilter> = yield select(
    filterSelectors.getClearedFilter('advancedSearch')
  );
  // @ts-ignore
  const res = yield call(SavedSearchesService.getCompanySearches, data);
  yield put(
    fetchSavedSearchesSuccess(
      res.data.lists,
      filterSources,
      clearedFilters,
      res.data.total_list_count,
      data.query,
      data.pagination,
      data.sortBy,
      data.addCustomViews,
      data.defaultCustomUIView
    )
  );
}
