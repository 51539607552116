import React from 'react';
import { Styled } from './Trending.styled';
// components
import TrendingValue from './TrendingValue';

interface ScoreTrendingProps {
  score: number | React.ReactNode;
  growth: number | null;
  label?: string;
  isPSGCompany?: boolean;
  customFontSize?: string;
  isLongCardView?: boolean;
  isFromNotifications?: boolean;
}

const ScoreTrending: React.FC<ScoreTrendingProps> = ({
  score,
  growth,
  label,
  isPSGCompany,
  customFontSize,
  isLongCardView,
  isFromNotifications,
}) => {
  return (
    <div className={`score-trending ${isPSGCompany && 'psg-company'}`}>
      {isPSGCompany ? (
        <Styled.PSGCompanyLabel
          isLongCardView={isLongCardView}
          isFromNotifications={isFromNotifications}
        >
          PSG
        </Styled.PSGCompanyLabel>
      ) : (
        <>
          <Styled.ScoreTrendingGrowth className="score-trending__growth">
            <span className="score-trending__growth-score">
              {typeof score !== 'number' ? 'N/A' : score}
            </span>
            <TrendingValue customFontSize={customFontSize} value={growth} />
          </Styled.ScoreTrendingGrowth>
          {label && <div className="score-trending__label">{label}</div>}
        </>
      )}
    </div>
  );
};

export default React.memo(ScoreTrending);
