import React from 'react';
import { useLocation } from 'react-router-dom';

import Company from '@models/Company';
import { Cell } from '@models/table/Cell';
import { TableCellMyWatchlistsActions } from '../../TableMyWatchlists/cells';
import { TableCellAdvancedSearchActions } from '../../TableAdvancedSearch/cells';
import { TableCellMyCompaniesActions } from '../../TableMyCompanies/cells';

const TableCellActions: React.FC<Cell<Company>> = ({ value, record, index }) => {
  const { pathname } = useLocation();

  if (pathname.startsWith('/user-lists')) {
    return <TableCellMyWatchlistsActions value={value} record={record} index={index} />;
  }

  if (pathname.startsWith('/my-companies')) {
    return <TableCellMyCompaniesActions value={value} record={record} index={index} />;
  }

  return <TableCellAdvancedSearchActions value={value} record={record} index={index} />;
};

export default React.memo(TableCellActions);
