import React from 'react';
import { components, ControlProps } from 'react-select';
import { SingleSelectProps } from '../interfaces';
import { Styled } from './Control.styled';

/**
 * Dropdown search area.
 */
export const Control: React.FC<ControlProps<any>> = ({ children, ...restProps }) => {
  const selectProps: SingleSelectProps = restProps.selectProps;
  const { SearchIcon, valuePrefix } = selectProps;

  return (
    <components.Control {...restProps}>
      {(SearchIcon || valuePrefix) && (
        <Styled.PrefixWrapper>
          {SearchIcon && <Styled.PrefixIconWrapper>{SearchIcon}</Styled.PrefixIconWrapper>}
          {valuePrefix && <Styled.Prefix>{valuePrefix}</Styled.Prefix>}
        </Styled.PrefixWrapper>
      )}

      {children}
    </components.Control>
  );
};
