import { createSelector } from 'reselect';
import queryString from 'query-string';
import moment from 'moment';
// models
import { SelectOption } from '@optx/models/Option';
import { FiltersState } from '@features/equity-touch-integration/state/interfaces';
// constants
import globalConfig from '@optx/constants/config';
// utils
import mapFiltersToURLParams from '@optx/utils/filters/mapFiltersToURLParams';
import mapTags from '@optx/utils/filters/mapTags';

export const loaded = (state: FiltersState) => !!state.fetchedAt;

export const getFilter = (state: FiltersState) => state.filter;

export const sources = (state: FiltersState) => state.data;

export const getDefaultFilter = (state: FiltersState) => state.preselected;

export const getClearedFilter = (state: FiltersState) => state.clear;

export const getNormalizedFilters = (state: FiltersState) => state.normalized;

export const filterQuery = (state: FiltersState) => {
  const { normalized, filter } = state;

  return queryString.stringify(mapFiltersToURLParams(normalized, filter), {
    arrayFormat: 'comma',
  });
};

export const filterQueryForStats = (state: FiltersState) => {
  const { normalized, filter } = state;

  const startdatetime = (filter.date_added_et as SelectOption[]).map(item =>
    typeof item.value === 'string'
      ? moment(item.value).format(globalConfig.short_date.DATE_FORMAT)
      : item
  );

  const analystIds = (filter?.analyst_id as Array<SelectOption>)?.length
    ? (filter.analyst_id as Array<SelectOption>)?.map((item: any) => item.value ?? item).join(',')
    : normalized.analyst_id.data?.map((item: any) => item.value).join(',');

  return queryString.stringify(
    { ...mapFiltersToURLParams(normalized, filter), analyst_id: analystIds, startdatetime },
    {
      arrayFormat: 'comma',
    }
  );
};

export const getFilters = (state: FiltersState) => {
  const { normalized, filter } = state;

  return { normalized, filter };
};

export const getValues = (state: FiltersState) => {
  const { values } = state;

  return values;
};

export const getTags = createSelector(
  getValues,
  sources,
  getClearedFilter,
  (values, filterSources, clearedFilter) => {
    return mapTags(values, filterSources, clearedFilter, [], {
      date_range_radio: 2,
    });
  }
);

export const isClearedFilter = createSelector(getTags, tags => {
  const tagNotDefault = tags.find(tag => {
    if (tag.isDefault === false && tag.values?.length > 0) {
      return true;
    }

    return false;
  });

  return !!tagNotDefault;
});
