/**
 * @description The storage item name
 * @type {string}
 */
const DISPLAY = 'extension_display';

/**
 * @description get item from localStorage
 * @return {boolean}
 */
const isDisplay = () => localStorage.getItem(DISPLAY);

/**
 * @description Set value to localStorage
 * @param {string} value item value
 */
const toggleDisplay = (value: boolean) => {
  localStorage.setItem(DISPLAY, value.toString());
};

export default {
  isDisplay,
  toggleDisplay,
};
