import React from 'react';
import GrayRectangle from '../../common/buttons/GrayRectangleBtn';

interface EmptyChartProps {
  className?: string;
  text?: string;
  width?: string | number;
}

const EmptyChart: React.FC<EmptyChartProps> = ({ className, text = 'No History', width }) => {
  return (
    <div className={`${className} profile-chart-card__empty`} style={{ width }}>
      <GrayRectangle text={text} />
    </div>
  );
};

EmptyChart.defaultProps = {
  className: 'profile-chart-card__no-data',
  width: 'calc(100% - 84px)',
};

export default EmptyChart;
