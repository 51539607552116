import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { CompanyFundingRounds } from '@optx/models/Company';
// api
import CompanyService from '../../../services/api/CompanyService';
// action types
import { GET_COMPANY_FUNDING_ROUNDS } from './types';
// actions
import { getCompanyFundingRoundsSuccess, getCompanyFundingRoundsFail } from './actions';

export function* getCompanyFundingRoundsSaga(action: PayloadAction<number>) {
  try {
    const res: AxiosResponse<CompanyFundingRounds> = yield call(
      CompanyService.getCompanyFundingRounds,
      action.payload
    );

    if (res.data) {
      yield put(getCompanyFundingRoundsSuccess(res.data, action.payload));
    } else {
      yield put(getCompanyFundingRoundsFail('Error for company funding rounds!'));
    }
  } catch (e: any) {
    yield put(getCompanyFundingRoundsFail('Error for company funding rounds!'));
  }
}

export default function* companyFundingRoundsSaga() {
  yield takeLatest(GET_COMPANY_FUNDING_ROUNDS, getCompanyFundingRoundsSaga);
}
