import React, { useCallback } from 'react';
import { Modal } from 'antd';
import { Dictionary } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
// models
import { PreselectedFilter } from '@optx/models/filters';
// utils
import { normalizeFilter } from '@optx/utils/filters/filterHelpers';
// redux
import { actions, selectors } from '@optx/features/sourcing-outreach-summary/state';
import { actions as modalActions, selectors as modalSelectors } from '@redux/ui/modals/gridModals';
// components
import FiltersModalContent from './FiltersModalContent';

const FiltersModal = () => {
  const filter = useSelector(selectors.filters.selectFilter);
  const filterSources = useSelector(selectors.filters.sources);
  const modalIsOpen = useSelector(modalSelectors.isOpen('outreach'));
  const searchKey = useSelector(selectors.filters.searchKey);

  const dispatch = useDispatch();

  const onSearch = useCallback(
    (searchKey?: string, filter?: Dictionary<PreselectedFilter>) => {
      if (filter && !Array.isArray(filter.stage_change)) {
        const normalizedFilter = { ...filter, stage_change: [filter.stage_change] };
        dispatch(
          actions.filters.applyFilters({
            searchKey,
            filter: normalizedFilter as Dictionary<PreselectedFilter>,
          })
        );
      } else {
        dispatch(actions.filters.applyFilters({ searchKey, filter }));
      }
    },
    [dispatch]
  );

  const handleClear = useCallback(() => {
    dispatch(actions.filters.clearFilters());
  }, [dispatch]);

  const handleReset = useCallback(() => {
    dispatch(actions.filters.resetToDefault());
  }, [dispatch]);

  const toggleModalFilters = () => dispatch(modalActions.toggleModal({ gridKey: 'outreach' }));

  const handleSearch = (key: string, filter?: Dictionary<PreselectedFilter>) => {
    const normalizedFilter = normalizeFilter(filter);
    onSearch(key, normalizedFilter);
    toggleModalFilters();
  };

  return (
    <Modal
      className="ant-modal-full ant-modal--filters"
      title={null}
      visible={modalIsOpen}
      footer={null}
      onCancel={toggleModalFilters}
      closable={false}
      destroyOnClose
    >
      <FiltersModalContent
        filter={filter}
        filterSources={filterSources}
        keySearch={searchKey as string}
        onSearch={handleSearch}
        onClose={toggleModalFilters}
        onClear={handleClear}
        onReset={handleReset}
      />
    </Modal>
  );
};

export default FiltersModal;
