import * as yup from 'yup';
// constants
import { ONLY_DIGITS_ERROR_MESSAGE } from '@optx/constants/table/cells';
// utils
import { validatePositiveNumbers } from '@optx/utils/validation';

const validationAllInfo = (stageRequiredValues: any[]) => {
  const validationSchema = yup.object({
    last_round: yup.object().nullable(),
    acquiring_company: yup
      .string()
      .nullable()
      .test('requiredConditional', 'Field is required!', function (value) {
        return (
          this.parent.last_round?.label !== 'Acquisition' ||
          (value && !!value && this.parent.last_round?.label === 'Acquisition')
        );
      }),
    addon_bool: yup.boolean().nullable(),
    addon: yup
      .string()
      .nullable()
      .test('requiredConditional', 'Field is required!', function (value) {
        return (value && !!value && this.parent.addon_bool) || !this.parent.addon_bool;
      }),
    stage_rationale: yup
      .string()
      .nullable()
      .test('requiredConditional', 'Field is required!', function (value) {
        return (
          !stageRequiredValues.some(item => item === this.parent.stage) ||
          (value && !!value && stageRequiredValues.some(item => item === this.parent.stage))
        );
      }),
    banker_name: yup.string().test('requiredConditional', 'Field is required!', function (value) {
      return (value && !!value && this.parent.banker) || !this.parent.banker;
    }),
    capital_raised: yup
      .number()
      .nullable()
      .test('requiredConditional', ONLY_DIGITS_ERROR_MESSAGE, function (value) {
        return validatePositiveNumbers(value);
      }),
    last_round_amount: yup
      .number()
      .nullable()
      .test('requiredConditional', ONLY_DIGITS_ERROR_MESSAGE, function (value) {
        return validatePositiveNumbers(value);
      }),
    last_round_date: yup.string().nullable(),
  });

  return validationSchema;
};

export default validationAllInfo;
