import { takeLatest, select, call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
// services
import { ListsService } from '@services/api';
import NotificationService from '@services/NotificationService';
// models
import { SourceScrubList } from '@models/SourceScrubList';
import { CompanyWatchList } from '@models/WatchList';
// redux
import { selectors as sourceScrubListsSelectors } from '@redux/user/source-scrub-lists';
import { getLists } from '@redux/favorite-lists/lists/selectors';
import { AxiosResponse } from 'axios';
import { Dictionary } from 'lodash';
import {
  loadCompanyListDetailsSuccess,
  resetLoadingState,
  loadCompanyListDetailsFailure,
  loadCompanyListDetails,
} from './actions';

export function* loadCompanyListDetailsSaga(
  action: PayloadAction<{ id: string; isSourceScrub: boolean }>
) {
  try {
    const { id: listId, isSourceScrub } = action.payload;
    let list: SourceScrubList | CompanyWatchList;

    if (isSourceScrub) {
      const sourceScrubLists: SourceScrubList[] = yield select(
        sourceScrubListsSelectors.getSourceScrubLists
      );
      list = sourceScrubLists.find(({ id }: SourceScrubList) => id === listId) as SourceScrubList;

      if (!list) {
        const res: AxiosResponse<any> = yield call(ListsService.getSourceScrubList, Number(listId));

        if (res.data) {
          list = res.data.lists[0];
        } else {
          const errorMessage = 'Error fetching list!';
          yield put(loadCompanyListDetailsFailure(errorMessage));
          NotificationService.error(errorMessage);
        }
      }
    } else {
      const savedSearchesLists: Dictionary<CompanyWatchList> = yield select(getLists);
      list = savedSearchesLists[listId];

      // TODO: Change this when the API is ready
      // if (!list) {
      //   const res = yield call(SavedSearchesService.getFavoriteById, Number(listId));

      //   if (res.data) {
      //     list = res.data.lists[0];
      //   } else {
      //     const errorMessage = 'Error fetching list!';
      //     yield put(actions.loadCompanyListDetailsFailure(errorMessage));
      //     NotificationService.error(errorMessage);
      //   }
      // }
    }

    if (list) yield put(loadCompanyListDetailsSuccess(list));
    if (!list) yield put(resetLoadingState());
  } catch (error: any) {
    const errorMessage = 'Error fetching list!';
    yield put(loadCompanyListDetailsFailure(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* userListsDetailsSaga() {
  yield takeLatest(loadCompanyListDetails, loadCompanyListDetailsSaga);
}
