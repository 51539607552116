import { call, put, takeLatest, select, fork, cancel, cancelled } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
// models
import { SortByRule, SortRule } from '@models/table/sorting';
import { SearchPayloadRequest } from '@optx/models/api/contacts';
import Company from '@optx/models/Company';
import { PageInfo } from '@optx/models/table/Pagination';
import { SearchViewType } from '@optx/models/user';
import { GetGridSearchData } from '@models/grid';
// constants
import { COMPANY_DEFAULT_SORT } from '@constants/table/sort/defaultSort';
import { LONG_CARD_VIEW_DEFAULT_PAGE_SIZE } from '@optx/constants/pagination';
// redux
import * as selectors from './selectors';
import { actions as searchActions, selectors as searchSelectors } from '@features/grid/search';
import { actions as sortActions } from '../sort';
import { selectors as viewTypeSelectors } from '@features/grid/view-type';
import {
  selectors as paginationSelectors,
  actions as paginationActions,
} from '@features/grid/pagination';
import { actions as longCardPaginationActions } from '@features/grid/pagination-long-card';
import { searchCompanies, searchCompaniesFail } from '@features/grid/search/state/sagasReusable';
import { userInfoUpdateSaga, getGridSearchDataSaga } from '@features/grid/state/sagasReusable';
import { sagas as searchFiltersSagas } from '@optx/features/grid/filter';
import { getViewId } from '@optx/features/grid/view/state/selectors';
import { getListType } from '@optx/features/grid/search/state/selectors';

// sort
export function* changeSortSaga(action: PayloadAction<SortRule<Company>>) {
  const gridKey = 'advancedSearch';

  const searchTitle: string = yield select(searchSelectors.getSearchName('advancedSearch'));
  const view: SearchViewType = yield select(viewTypeSelectors.searchView('advancedSearch'));
  const regularPagination: PageInfo = yield select(
    paginationSelectors.getPagination('advancedSearch')
  );
  const pagination = {
    pageNumber: 1,
    pageSize: view === 'table' ? regularPagination.pageSize : LONG_CARD_VIEW_DEFAULT_PAGE_SIZE,
  };

  const {
    filter: filterQuery,
    searchKey,
    originalFilter: filter,
  }: GetGridSearchData = yield call(getGridSearchDataSaga, gridKey, pagination);

  const sortBy = action.payload.sortBy;
  const id: number = yield select(getViewId(gridKey));
  const listType: string = yield select(getListType(gridKey));

  const searchData: SearchPayloadRequest = {
    searchKey,
    filter: filterQuery,
    pagination,
    sortBy,
    fromSavedList: id,
    listType,
  };

  try {
    const userSettings: { [key: string]: number | string } = yield call(
      userInfoUpdateSaga,
      gridKey,
      pagination,
      true,
      true,
      sortBy
    );

    const isAdvancedSearch = gridKey === 'advancedSearch';

    yield call(
      searchCompanies,
      searchData,
      gridKey,
      filter,
      undefined,
      isAdvancedSearch ? searchTitle : undefined,
      userSettings
    );

    if (view === 'long-card') {
      yield put(
        longCardPaginationActions.changeLongCardPaginationSuccess({
          gridKey: gridKey,
          data: pagination,
        })
      );
    } else {
      yield put(paginationActions.changePaginationSuccess({ gridKey, data: pagination }));
    }
  } catch (error: any) {
    yield call(searchCompaniesFail, gridKey, error);
  }
}

export function* resetSortSaga() {
  const gridKey = 'advancedSearch';

  const view: SearchViewType = yield select(viewTypeSelectors.searchView('advancedSearch'));
  const regularPagination: PageInfo = yield select(
    paginationSelectors.getPagination('advancedSearch')
  );
  const pagination = {
    pageNumber: 1,
    pageSize: view === 'table' ? regularPagination.pageSize : LONG_CARD_VIEW_DEFAULT_PAGE_SIZE,
  };

  const {
    filter: filterQuery,
    searchKey,
    originalFilter: filter,
  }: GetGridSearchData = yield call(getGridSearchDataSaga, gridKey, pagination);

  const sortBy = COMPANY_DEFAULT_SORT;

  const searchData: SearchPayloadRequest = {
    searchKey,
    filter: filterQuery,
    pagination,
    sortBy,
  };

  try {
    yield call(searchCompanies, searchData, gridKey, filter);

    yield put(sortActions.resetSortSuccessAction());

    yield call(userInfoUpdateSaga, gridKey, pagination);

    if (view === 'long-card') {
      yield put(
        longCardPaginationActions.changeLongCardPaginationSuccess({
          gridKey: gridKey,
          data: pagination,
        })
      );
    } else {
      yield put(paginationActions.changePaginationSuccess({ gridKey, data: pagination }));
    }
  } catch (error: any) {
    yield call(searchCompaniesFail, gridKey, error);
  }
}

export function* multiSortSaga(
  action: PayloadAction<boolean, any, Array<SortByRule<any>> | undefined>
) {
  const gridKey = 'advancedSearch';
  const isMultiSort = action.payload;
  const previousSorting = action.meta;
  const currentSorting: SortByRule<any>[] = yield select(selectors.getSorting);

  try {
    const cancelSearch: boolean = yield select(searchSelectors.isCancelled(gridKey));

    // Only make the call to API if previous sort was changed to single sort and
    // previous sorting is the same as next sorting.
    if (!isMultiSort && previousSorting !== currentSorting) {
      yield put(searchActions.hideCancelSearch({ gridKey, data: true }));
      yield put(sortActions.changeSortAction({ sortBy: currentSorting }));
    }

    if (cancelSearch) {
      yield cancel();
    }
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      yield put(searchActions.cancelCompanySearch({ gridKey, data: false }));
    }
  }
}

export default function* companiesSaga() {
  yield takeLatest(sortActions.changeSortAction, changeSortSaga);
  yield takeLatest(sortActions.multiSortAction, multiSortSaga);
  yield takeLatest(sortActions.resetSortAction, resetSortSaga);
  yield fork(searchFiltersSagas);
}
