import { createAction } from '@reduxjs/toolkit';
// models
import { PipelineInformation } from '@optx/models/feature/analystsLeaderboard/PipelineInformation';
// types
import * as types from './types';

// fetch
export const fetch = createAction(types.fetch.REQUEST);
export const fetchSuccess = createAction<PipelineInformation>(types.fetch.SUCCESS);
export const fetchFail = createAction<string>(types.fetch.FAILURE);
