import { RangeFilter, BaseFilter, HistogramGroup } from '@optx/models/filters';
import { FormCheckableRangeOption } from '@optx/models/Option';
import { Dictionary } from 'lodash';

export interface QuickFiltersListItemProps extends RangeFilter<Array<FormCheckableRangeOption>> {
  label: string;
}

type QuickFilter =
  | 'score'
  | 'il_optx_score'
  | 'capital_raised'
  | 'num_employees'
  | 'emp_growth_percent';

const quickFilters: Array<QuickFilter> = [
  'score',
  'il_optx_score',
  'capital_raised',
  'num_employees',
  'emp_growth_percent',
];

const labels: Record<QuickFilter, string> = {
  score: 'OPTX Score',
  il_optx_score: 'IL Score',
  capital_raised: 'Total Raised',
  num_employees: 'Number of Employees',
  emp_growth_percent: 'Employee Growth Percentage Y/Y',
};

/**
 * Create the structure for Quick Filters
 */
export const getQuickFiltersList = (
  filterSources: Dictionary<BaseFilter<any>>,
  onFilterFieldChange?: (fieldName: string) => void,
  histogram?: HistogramGroup,
  isIlScore?: boolean
): QuickFiltersListItemProps[] => {
  const customQuickFilters = isIlScore
    ? quickFilters.filter(item => item !== 'score')
    : quickFilters.filter(item => item !== 'il_optx_score');

  return customQuickFilters.map(filterKey => {
    const filter = filterSources[filterKey] as RangeFilter<Array<FormCheckableRangeOption>>;

    return {
      ...filter,
      // onChange: onFilterFieldChange,
      label: labels[filterKey],
      // histogram: filterHistogram,
    };
  });
};
