import React from 'react';
import { useHistory } from 'react-router';
// components
import Styles from './Sidebar.styles';

interface StatsSectionProps {
  title: string;
  icon: React.ReactElement;
  buttonURL?: string;
  backgroundImage?: string;
  extraContent?: React.ReactElement;
  blueHeight?: number;
  className?: string;
}

const StatsSection: React.FC<StatsSectionProps> = ({
  icon,
  title,
  buttonURL,
  backgroundImage,
  children,
  extraContent,
  blueHeight,
  className,
}) => {
  const history = useHistory();

  return (
    <Styles.Section backgroundImage={backgroundImage} className={className}>
      <Styles.Banner style={{ height: blueHeight ?? '150px' }} />
      <Styles.Body>
        <Styles.BodyContent className="stats-body-content">
          <Styles.TitleWrapper>
            {icon}
            <Styles.Title>{title}</Styles.Title>
            {buttonURL && (
              <Styles.Button onClick={() => history.push(buttonURL)} ghost>
                View More
              </Styles.Button>
            )}
          </Styles.TitleWrapper>
          {extraContent && extraContent}
          {children}
        </Styles.BodyContent>
      </Styles.Body>
    </Styles.Section>
  );
};

export default StatsSection;
