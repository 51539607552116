import { createFeatureActionType } from '@optx/utils/feature';
import { featureName } from '../../featureName';

// analyst
export const FETCH_ANALYST = createFeatureActionType(featureName, 'FETCH_ANALYST');
export const FETCH_ANALYST_SUCCESS = createFeatureActionType(featureName, 'FETCH_ANALYST_SUCCESS');
export const FETCH_ANALYST_FAIL = createFeatureActionType(featureName, 'FETCH_ANALYST_FAIL');

// company
export const FETCH_COMPANY = createFeatureActionType(featureName, 'FETCH_COMPANY');
export const FETCH_COMPANY_SUCCESS = createFeatureActionType(featureName, 'FETCH_COMPANY_SUCCESS');
export const FETCH_COMPANY_FAIL = createFeatureActionType(featureName, 'FETCH_COMPANY_FAIL');

// columns
export const SAVE_COLUMNS = createFeatureActionType(featureName, 'SAVE_COLUMNS');
