import React from 'react';
import { Typography } from 'antd';
import { CommonProps, GroupType } from 'react-select';
// models
import { SelectOption } from '@optx/models/Option';
// utils
import { hasGroups } from '@optx/utils/select/select';
// Local
// models
import { MultiSelectProps } from '../../interfaces';

interface AllMultiValueContainerProps extends CommonProps<SelectOption> {
  data: SelectOption;
}

/**
 * Display select ALL inside dropdown when is the case.
 */
const AllMultiValueContainer: React.FC<AllMultiValueContainerProps> = props => {
  const { selectProps, data } = props;
  const { allPrefix } = selectProps as MultiSelectProps;

  // For now keep function without memoization because sometimes it can receive
  // the same value reference but different size and will not update show all.
  const showSelectAll = () => {
    const options = selectProps.options;
    const value = selectProps.value as Array<SelectOption> | null;

    if (!value || !value.length || data.value !== value[0].value) {
      return false;
    }

    let allOptions: Array<SelectOption> = [];

    if (hasGroups(options)) {
      options?.forEach((option: SelectOption | GroupType<SelectOption>) => {
        const groupOptions = (option as GroupType<SelectOption>).options;

        if (groupOptions) {
          // grouped options
          allOptions = allOptions.concat(groupOptions);
        } else {
          // single option
          allOptions.push(option as SelectOption);
        }
      });
    } else {
      allOptions = [...(options as Array<SelectOption>)];
    }

    // If there are disabled options don't show all.
    if (allOptions.some(item => item.disabled)) {
      return false;
    }

    return value.length === allOptions.length;
  };

  if (showSelectAll()) {
    return (
      <Typography.Text>
        {allPrefix || 'Select'}: <Typography.Text style={{ color: '#1890FF' }}>ALL</Typography.Text>
      </Typography.Text>
    );
  }

  return null;
};

export default AllMultiValueContainer;
