import React from 'react';
import { Col } from 'antd';
import styled from 'styled-components';
// utils
import { styledColors } from '@optx/utils/theme/colors';
// hooks
import { useToggle } from '@optx/common/hooks/modal';
// components
import Icon from '@optx/components/common/Icon';
import ChangePasswordModal from '../Modals/Password';

const PenIcon = styled(Icon)<{ $visible?: boolean }>`
  cursor: pointer;

  :hover {
    color: ${styledColors.blue6};
  }
`;

const EditFieldPassword = () => {
  const [isOpenModal, toggleModal] = useToggle();

  return (
    <>
      <Col className="user-profile-information__edit password" onClick={toggleModal}>
        <span>**************</span>
        <PenIcon iconName="pen" />
      </Col>
      <ChangePasswordModal isOpen={isOpenModal} toggle={toggleModal} />
    </>
  );
};

export default EditFieldPassword;
