import React from 'react';
import { Row, Col, Tag } from 'antd';
// constants
import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';
import { defaultAvatarImage } from '@optx/constants/images';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// components
import { CompanyAvatar } from '../../../components/common/avatar';
import { IconLocation } from '../../../components/icons';
import OwnerAvatar from '@shared/view/organims/OwnerAvatar';
import { TouchCompany } from '@optx/redux/ui/modals/company-touches/interfaces';
import { EditAllCompany } from '@optx/features/edit-all-info/state/interfaces';

interface CompanyDrawerBannerProps {
  company: TouchCompany | EditAllCompany;
}

const CompanyDrawerBanner: React.FC<CompanyDrawerBannerProps> = ({ company }) => {
  const companyLogo = company.logo_url !== DEFAULT_EMPTY_VALUE ? company.logo_url : null;

  return (
    <Row
      gutter={[48, 48]}
      justify="space-between"
      align="middle"
      className="company-intro flex-nowrap"
    >
      <Col>
        <Row justify="space-between" align="middle" className="flex-nowrap">
          <Col>
            <CompanyAvatar
              className="company-avatar--profile"
              logoUrl={companyLogo}
              companyName={company.company_name || ''}
            />
          </Col>
          <Col>
            <span>
              {!company.is_in_et ? (
                <Tag color="gray">Not in ET</Tag>
              ) : (
                <Tag color="green">In ET</Tag>
              )}
            </span>
            <div className="company-name">{company && company.company_name}</div>
            <div className="company-info">
              <span className="company-location">
                <IconLocation />
                {company.location || DEFAULT_CELL_VALUE}
              </span>{' '}
              <span className="company-year-founded">
                (Founded in {company.year_founded || DEFAULT_CELL_VALUE})
              </span>
            </div>
          </Col>
        </Row>
      </Col>
      <Col style={{ display: 'flex', alignItems: 'flex-end', marginLeft: 'auto' }}>
        {company.company_owner !== 'Unowned' && (
          <OwnerAvatar
            ownerId={company.company_owner_id}
            name={company.company_owner}
            ownerImage={company.owner_img || defaultAvatarImage}
            title="PSG OWNER"
          />
        )}
      </Col>
    </Row>
  );
};

export default CompanyDrawerBanner;
