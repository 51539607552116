import styled from 'styled-components';
import { Row, Col, Typography, Card } from 'antd';
import { styledColors } from '@utils/theme/colors';

const HeaderWrapper = styled(Row)`
  flex-flow: row nowrap;
  position: relative;
  background: white;
  padding: 0 35px;
  margin-bottom: 160px;

  .ant-col {
    flex: 1 1;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content {
    .ant-steps-item-title {
      color: ${styledColors.blue6};
    }

    .ant-steps-item-description {
      color: #8c8c8c;
    }
  }
`;

const StepsWrapper = styled(Col)`
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: calc(100% + 25px);
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(66, 17, 115, 0.06);
  padding: 15px;
  width: 654px;

  .ant-steps-item-content {
    margin-left: 15px;
  }
`;

const ButtonWrapper = styled(Col)`
  text-align: right;
  margin: 30px 0 20px;
  .ant-btn {
    margin: 0 0 7px 7px;
  }
`;

const TextCount = styled(Typography.Title)`
  max-width: 38%;
  &&& {
    margin: 30px 0 20px;
    color: #334d6e;
  }
`;

const RowWrapper = styled(Row)`
  padding: 0px 35px 50px;

  .eqt-bulk__table-selected {
    th {
      padding: 20px 7px;
    }

    td.is_in_et,
    td.score {
      text-align: right;
    }

    td.is_interesting {
      text-align: center;
    }
  }

  .ant-input-number {
    width: 100%;
  }

  .ant-tag {
    cursor: pointer;
  }
`;

const CardWrapper = styled(Card)`
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(66, 17, 115, 0.06);
  margin-bottom: 20px;
`;

export { HeaderWrapper, StepsWrapper, ButtonWrapper, TextCount, RowWrapper, CardWrapper };
