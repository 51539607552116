import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dictionary } from 'lodash';
// models
import { PreselectedFilter } from '@optx/models/filters';
// utils
import { normalizeFilter } from '@optx/utils/filters/filterHelpers';
// redux
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import { selectors as myCompaniesSearchSelectors } from '@redux/my-companies/search';
import { selectors as searchSelectors } from '@features/grid/search';
import { actions as filterCardsActions } from '@optx/redux/my-companies/filters-cards';
import { selectors as viewSelectors } from '@features/grid/view';
// components
import SecondaryQuickFiltersPanel from '@components/feature/secondary-quick-filters/SecondaryQuickFiltersPanel';
import ExportToCSV from './ExportToCSV';
import ShowFilterButton from '@optx/features/grid/filter/components/ShowFilterButton';

interface SecondaryQuickFiltersContainerProps {
  onHeightChange: (height: number) => void;
}

const SecondaryQuickFiltersContainer: React.FC<SecondaryQuickFiltersContainerProps> = ({
  onHeightChange,
}) => {
  const dispatch = useDispatch();
  const panelRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!panelRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      if (panelRef.current?.clientHeight) {
        onHeightChange(panelRef.current?.clientHeight);
      }
    });
    resizeObserver.observe(panelRef.current);

    return () => resizeObserver.disconnect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filter = useSelector(filterSelectors.getFilter('myCompanies'));
  const histograms = useSelector(myCompaniesSearchSelectors.selectHistograms);
  const keySearch = useSelector(searchSelectors.getSearchKey('myCompanies'));
  const options = useSelector(filterSelectors.getTags('myCompanies'));
  const view = useSelector(viewSelectors.getView('myCompanies'));

  // call /search query
  const onSearch = useCallback(
    (searchKey: string, filter: Dictionary<PreselectedFilter>) => {
      const normalizedFilter = normalizeFilter(filter);
      dispatch(
        filterCardsActions.fetchMyCompaniesCards({
          searchKey,
          filter: normalizedFilter,
          shouldResetPageNumber: true,
        })
      );
    },
    [dispatch]
  );

  return (
    <div ref={panelRef}>
      <SecondaryQuickFiltersPanel
        onSearch={onSearch}
        filter={filter}
        histograms={histograms}
        keySearch={keySearch}
        options={options}
        isMyCompany
        viewId={view.id}
      >
        <ShowFilterButton gridKey={'myCompanies'} />
        <ExportToCSV />
      </SecondaryQuickFiltersPanel>
    </div>
  );
};

export default React.memo(SecondaryQuickFiltersContainer);
