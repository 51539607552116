import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { Button, Card } from 'antd';
import { MainContent } from '@optx/components/common/layout';

const ProfilePictureCard = styled(Card)`
  background: linear-gradient(0deg, #064885 0%, #137edf 119.38%);

  .media-object {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
  }
  h4 {
  }
  span.name {
    color: white;
    text-transform: capitalize;
    margin: 10px 0 3px;
    font-size: 1.2rem;
  }
  span {
    text-transform: uppercase;
    color: #90a0b7;
    font-weight: 500;
  }
  .ant-card-body {
    padding: 0 0 1rem;
  }
  .media-profile-picture {
    position: relative;
    cursor: pointer;
    padding: 0;
    border-radius: 50%;
    overflow: hidden;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      display: inline-block;
      position: absolute;
      top: 0px;
      z-index: 2;
      left: 0px;
      background: black;
      border-radius: 50%;
      opacity: 0;
    }

    img {
      border-radius: 50%;
    }

    .icon-camera {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 40%;
      height: 40%;
      z-index: 2;
      opacity: 0;
      color: $white;
    }

    &:hover {
      &::before {
        opacity: 0.7;
      }

      .icon-camera {
        opacity: 0.7;
      }
    }
    .ant-image {
      vertical-align: top;
      border-radius: 50%;
    }
    .ant-image-mask {
      border-radius: 50%;
    }
  }
`;

const HiddenInput = styled.input`
  height: 0 !important;
  width: 0 !important;
  overflow: hidden;
  opacity: 0;
`;

const Label = styled.label``;

const InformationWrapper = styled(Card)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -2px;
  font-weight: 600;

  .ant-row {
    min-height: 25px;
  }

  .ant-card-body {
    padding: 20px 25px;
  }

  .user-profile-information {
    &__section-title {
      padding: 0 0 8px;
      font-size: 16px;
      line-height: 24px;
      height: initial !important;
      color: $gray-9;

      &:not(.first-child) {
        padding-top: 20px;
      }
    }
    .proprietary-icon {
      .anticon {
        vertical-align: middle;
        margin-left: 7px;
        width: 21px;
        height: 21px;

        svg {
          position: relative;
          top: -2px;
          width: 100%;
          height: 100%;
        }
      }
    }

    .edit-pen {
      padding-left: 10px;
      visibility: hidden;
    }

    .icon-pen {
      width: 18px;
      height: 18px;
    }

    &__label {
      width: 39%;
      padding-right: 4%;
      line-height: 22px;
      color: #90a0b7;
    }

    &__value {
      color: #374961;
      width: 61%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__edit {
      color: #1890ff;

      & > div {
        gap: 8px;
      }

      &:hover {
        cursor: pointer;
        .edit-pen {
          visibility: visible;
        }
      }
    }
  }
  .ant-row .password {
    svg {
      visibility: hidden;
    }
  }
  .ant-row .password:hover svg {
    visibility: visible;
  }
`;

const TabWrapper = styled.div<{ className?: string }>`
  background: white;
  height: 100%;

  .ant-tabs-nav-list {
    padding: 2px 30px;
    margin-top: 8px;
  }

  .ant-tabs-content-holder {
    padding: 15px 30px 30px;
  }

  .optx-score-settings {
    .optx-score-warning {
      display: block;
      margin: 10px 0;
    }
  }
`;

const UserProfileWrapper = styled(MainContent)`
  .ant-row-top {
    margin: 10px !important;
    flex-wrap: wrap;
  }

  ${up('lg')} {
    .ant-row-top {
      display: grid;
      grid-template-columns: minmax(350px, 1fr) minmax(900px, 4fr);
    }
  }
  .ant-col {
    height: 100%;
  }
`;

const StyledButton = styled(Button)`
  margin-right: 1rem;
`;

export const Styled = {
  ProfilePictureCard,
  HiddenInput,
  Label,
  InformationWrapper,
  TabWrapper,
  UserProfileWrapper,
  StyledButton,
};
