import React from 'react';
// components
import { EquityTouchSimilarities } from '@optx/models/equityTouch';
import { ColumnType } from 'antd/lib/table';
// cells
import { TableCellDefault, TableCellMatch } from '@components/common/table/AntTable/cells';
import { TableCellName, TableCellCompanyURL } from './cells';

const columns: ColumnType<EquityTouchSimilarities>[] = [
  {
    dataIndex: 'url',
    title: '',
    className: 'align-center',
    render: (value: string, record: EquityTouchSimilarities) => (
      <TableCellCompanyURL value={value} record={{ url: record.raw_url }} />
    ),
    width: 40,
  },
  {
    dataIndex: 'name',
    title: 'Company Name',
    render: (value: string, record: EquityTouchSimilarities) => (
      <TableCellName value={value} record={record} />
    ),
    width: 240,
  },
  {
    dataIndex: 'year_founded',
    title: 'Year Founded',
    className: 'align-right',
    render: TableCellDefault,
    width: 120,
  },
  {
    dataIndex: 'is_url_match',
    title: 'URL Match',
    className: 'align-right',
    render: TableCellMatch,
    width: 120,
  },
];

export default columns;
