import React from 'react';
import { Button, Tooltip } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
// redux
import { actions as CompaniesETActions } from '@optx/features/equity-touch-integration/state';

const ShowFilterButton = () => {
  const dispatch = useDispatch();

  const toggleModal = (e: React.MouseEvent<HTMLElement>) => {
    e.currentTarget.blur();
    dispatch(CompaniesETActions.ui.toggleFiltersModal());
  };

  return (
    <Tooltip title="Filter" placement="bottom">
      <Button type="text" onClick={toggleModal} icon={<FilterOutlined />} />
    </Tooltip>
  );
};

export default ShowFilterButton;
