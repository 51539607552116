import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import { LinkedinOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
// models
import { CompanyContactIcons, CompanyUserContact } from '@models/api/contacts';
// utils
import { convertToURL } from '@optx/utils/url';
// components
import CopyButton from '@components/common/CopyButton';
import Icon from '@components/common/Icon';

export const TableCellIcons = (value: CompanyContactIcons, row: Partial<CompanyUserContact>) => {
  let phones: string[] | string = [];

  if (row.phone) {
    phones.push(row.phone);
  }

  if (row.additional_phones && row.additional_phones.length) {
    row.additional_phones.forEach(phone => (phones as string[]).push(phone.phone_number));
  }

  if (phones.length) {
    phones = Array.from(new Set(phones)).join(', ');
  }

  return (
    <Row align="middle">
      {phones.length ? (
        <Col>
          <Tooltip title={<CopyButton text={phones as string} />}>
            <PhoneOutlined className={row.email ? '' : 'no-email'} />
          </Tooltip>
        </Col>
      ) : (
        ''
      )}
      {value?.linkedin && (
        <Col>
          <a
            href={convertToURL(value.linkedin)}
            target="_blank"
            rel="noopener noreferrer"
            className="d-flex"
          >
            <LinkedinOutlined />
          </a>
        </Col>
      )}
      <Col>
        {row?.email && (
          <Tooltip title={<CopyButton text={row.email} />}>
            <MailOutlined />
          </Tooltip>
        )}
      </Col>
      <Col>{value?.salesloft && <Icon iconName="salesloft" />}</Col>
    </Row>
  );
};

export default TableCellIcons;
