import React from 'react';
import { Tooltip, Tag as AntDTag, Typography } from 'antd';
import styled from 'styled-components';
// models
import { CompanyWatchList } from '@optx/models/WatchList';

const Tag = styled(AntDTag)`
  cursor: pointer;
`;

const NameWrapper = styled.div`
  max-width: 400px;
  display: flex;
`;

const TableCellName = (value: string, record: CompanyWatchList) => {
  const { extra_options: extraOptions, is_public: isPublic } = record;
  const isETGroup = extraOptions && typeof extraOptions.et_group_id === 'number';

  return (
    <NameWrapper>
      <Tooltip title={value}>
        <Typography.Text ellipsis>{value}</Typography.Text>
      </Tooltip>
      {isETGroup && (
        <span>
          &nbsp;
          <Tag color="gold">ET</Tag>
        </span>
      )}
      {isPublic && (
        <span>
          &nbsp;
          <Tag color="gold">Public</Tag>
        </span>
      )}
    </NameWrapper>
  );
};

export default TableCellName;
