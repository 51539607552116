import React from 'react';
import Styled from './GrayRectangleBtn.styles';

interface GrayRectangleBtnProps {
  text: string;
}

const GrayRectangleBtn: React.FC<GrayRectangleBtnProps> = ({ text }) => {
  return <Styled.GrayButton type="button">{text}</Styled.GrayButton>;
};

export default GrayRectangleBtn;
