import React, { useEffect, useState } from 'react';
import { Row } from 'antd';
import { useDispatch } from 'react-redux';
import { GlobalOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
// models
import Company from '@models/Company';
// redux
import { actions as viewTypeActions } from '@features/grid/view-type';
// components
import Icon from '@components/common/Icon';
import { IconCompany, IconLocation } from '@components/icons';
// hooks
import useReferrerProfileLink from '@hooks/useReferrerLink';
import useLoadCompanyData from '../../hooks/useLoadCompanyData';
// styles
import Styles from '@features/company/long-card/LongCardView.styled';
import CardHeaderStyles from './CardPannelHeader.styles';

interface CardPannelHeaderProps {
  companies: Array<Company>;
  selectedCompany: Company;
}

const CardPannelHeader: React.FC<CardPannelHeaderProps> = ({ companies, selectedCompany }) => {
  const dispatch = useDispatch();
  const {
    company_id: companyId,
    company_name: name,
    location,
    logo_url: logoUrl,
    raw_url: website,
    url_status: urlStatus,
  } = selectedCompany;

  const logo = logoUrl !== '-' ? logoUrl : null;
  let currentCompanyIndex = companies.findIndex(company => company.company_id === companyId);

  const { referrerUrl } = useReferrerProfileLink(companyId);

  const [disableLeftArrow, setDisableLeftArrow] = useState(true);
  const [disableRightArrow, setDisableRightArrow] = useState(false);

  const { loadCompanyData } = useLoadCompanyData();

  const changePrevCompany = () => {
    // This action is used to know when a company card is selected using arrows
    // This is useful for scrolling into view of that company card
    dispatch(viewTypeActions.changedCompanyCard({ gridKey: 'advancedSearch', data: true }));

    if (currentCompanyIndex > 0) {
      currentCompanyIndex--;
    }

    if (currentCompanyIndex >= 0) {
      const { company_id: currentCompanyId, company_url: currentCompanyUrl } =
        companies[currentCompanyIndex];

      loadCompanyData(currentCompanyId, currentCompanyUrl);
    }
  };

  const changeNextCompany = () => {
    dispatch(viewTypeActions.changedCompanyCard({ gridKey: 'advancedSearch', data: true }));

    if (currentCompanyIndex < companies.length - 1) {
      currentCompanyIndex++;
    }

    if (currentCompanyIndex <= companies.length - 1) {
      const { company_id: currentCompanyId, company_url: currentCompanyUrl } =
        companies[currentCompanyIndex];

      loadCompanyData(currentCompanyId, currentCompanyUrl);
    }
  };

  useEffect(() => {
    if (currentCompanyIndex > 0) {
      setDisableLeftArrow(false);
    }

    if (companies.length === 1 || currentCompanyIndex >= companies.length - 1) {
      setDisableRightArrow(true);
    }
  }, [setDisableLeftArrow, setDisableRightArrow, currentCompanyIndex, companies.length]);

  return (
    <Row gutter={[16, 0]}>
      <CardHeaderStyles.Wrapper span={17}>
        <Styles.CenteredColumn span={6}>
          <Styles.ImageWrapper>
            {!logo ? <IconCompany /> : <img src={logo || undefined} alt={name} />}
          </Styles.ImageWrapper>
        </Styles.CenteredColumn>
        <CardHeaderStyles.CompanyNameWrapper span={18}>
          <CardHeaderStyles.CompanyName>
            {name}
            <Styles.CompanyProfileLink to={referrerUrl} target="_blank">
              <Icon iconName="open-in-new" />
            </Styles.CompanyProfileLink>
          </CardHeaderStyles.CompanyName>
          <CardHeaderStyles.CompanyLocation type="secondary">
            <IconLocation />
            {location}
          </CardHeaderStyles.CompanyLocation>
        </CardHeaderStyles.CompanyNameWrapper>
      </CardHeaderStyles.Wrapper>
      <CardHeaderStyles.Icons span={7}>
        <Row align="middle">
          {website && (
            <CardHeaderStyles.WebsiteIcon
              href={website}
              title={website}
              className={urlStatus === 'active' ? undefined : 'not-active'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GlobalOutlined />
            </CardHeaderStyles.WebsiteIcon>
          )}

          <CardHeaderStyles.StyledDropDown record={companies[currentCompanyIndex]} />

          <CardHeaderStyles.ButtonWrapper>
            <CardHeaderStyles.ArrowButton
              disabled={disableLeftArrow}
              onClick={changePrevCompany}
              type="text"
            >
              <LeftOutlined />
            </CardHeaderStyles.ArrowButton>

            <CardHeaderStyles.ArrowButton
              disabled={disableRightArrow}
              onClick={changeNextCompany}
              type="text"
            >
              <RightOutlined />
            </CardHeaderStyles.ArrowButton>
          </CardHeaderStyles.ButtonWrapper>
        </Row>
      </CardHeaderStyles.Icons>
    </Row>
  );
};

export default CardPannelHeader;
