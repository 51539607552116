import React, { useMemo, useState, useEffect } from 'react';
import { RadioButtonProps } from 'antd/lib/radio/radioButton';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarOutlined } from '@ant-design/icons';
// models
import { DateRangeOption } from '@optx/models/Option';
// constants
import { ISO_DATE_FORMAT } from '@constants/format/date';
import globalConfig from '@optx/constants/config';
// redux
import { changeSelectedDate, changeSelectedPeriod } from '@optx/redux/scheduled-touches/actions';
import { getSelectedPeriod } from '@optx/redux/scheduled-touches/selectors';
import { getCards, getSelectedDate } from '@optx/redux/scheduled-touches/selectors';
// style
import Styled from './styled';
import './style.scss';

// rc-picker types
type EventValue<DateType> = DateType | null;
type RangeValue<DateType> = [EventValue<DateType>, EventValue<DateType>] | null;

interface RadioButtonPickerProps extends Omit<RadioButtonProps, 'onChange'> {
  option: DateRangeOption;
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  setSelectedCard: React.Dispatch<React.SetStateAction<string>>;
  value: number;
}

type DateValue = string | null;

const RadioButtonPicker: React.FC<RadioButtonPickerProps> = ({
  selectedIndex,
  setSelectedIndex,
  setSelectedCard,
  option,
  value,
  ...restProps
}) => {
  const [localValues, setLocalValues] = useState<[DateValue, DateValue]>([null, null]);
  const [customDateOpened, setCustomDateOpened] = useState(false);
  const dispatch = useDispatch();
  const allCards = useSelector(getCards);
  const selectedPeriod = useSelector(getSelectedPeriod);
  const selectedData = useSelector(getSelectedDate);

  useEffect(() => {
    setLocalValues(selectedData);
  }, [selectedData]);

  const datePickerRadioClick = () => {
    if (!customDateOpened) {
      setCustomDateOpened(prevState => !prevState);
      setLocalValues([null, null]);
    }
  };

  const datePickerChange = (values: RangeValue<Moment>) => {
    if (!values?.[0] && !values?.[1]) {
      setLocalValues([null, null]);
      setSelectedIndex(0);
      dispatch(
        changeSelectedDate([Object.values(allCards)[0][1].start, Object.values(allCards)[0][1].end])
      );
      dispatch(changeSelectedPeriod(Object.keys(allCards)[0]));
      setSelectedCard(Object.values(allCards)[0][1].label!);

      return;
    }

    let getLabel: string = 'Custom Range';
    const [start, end] = values as [Moment, Moment];
    Object.values(allCards).map((item: any) => {
      if (
        item[0].start === moment(start).format(ISO_DATE_FORMAT) &&
        item[0].end === moment(end).format(ISO_DATE_FORMAT)
      ) {
        getLabel = item[0].label;
      } else if (
        item[1] &&
        item[1].start === moment(start).format(ISO_DATE_FORMAT) &&
        item[1].end === moment(end).format(ISO_DATE_FORMAT)
      ) {
        getLabel = item[1].label;
      }

      return false;
    });

    setCustomDateOpened(false);
    const newOption: DateRangeOption = {
      ...option,
      start: start.format(ISO_DATE_FORMAT),
      end: end.format(ISO_DATE_FORMAT),
    };

    setLocalValues([newOption.start, newOption.end]);
    dispatch(changeSelectedDate([newOption.start, newOption.end]));
    dispatch(changeSelectedPeriod(getLabel!));
  };

  const rangePickerValue = useMemo(
    () =>
      localValues[0] !== null
        ? (localValues.map(value => moment(value)) as [Moment, Moment])
        : undefined,
    [localValues]
  );

  useEffect(() => {
    if (selectedPeriod !== 'Custom Range') {
      setLocalValues([null, null]);
    }
  }, [selectedPeriod]);

  useEffect(() => {
    if (selectedIndex !== value) {
      setLocalValues([null, null]);
    }
  }, [value, selectedIndex]);

  return (
    <Styled.RadioButton onClick={datePickerRadioClick} value={value} {...restProps}>
      <Styled.RangePicker
        separator={<CalendarOutlined style={{ color: '#000', opacity: 0.25 }} />}
        onClick={datePickerRadioClick}
        ranges={{
          Yesterday: [
            moment((allCards as any).Today[0].start),
            moment((allCards as any).Today[0].end),
          ],
          'Last Week': [
            moment((allCards as any)['This Week'][0].start),
            moment((allCards as any)['This Week'][0].end),
          ],
          'Last Month': [
            moment((allCards as any)['This Month'][0].start),
            moment((allCards as any)['This Month'][0].end),
          ],
          'Last Quarter': [
            moment((allCards as any)['This Quarter'][0].start),
            moment((allCards as any)['This Quarter'][0].end),
          ],
        }}
        placeholder={['Start', 'End']}
        value={rangePickerValue}
        format={globalConfig.short_date.DATE_FORMAT}
        open={customDateOpened}
        onChange={datePickerChange}
        onOpenChange={setCustomDateOpened}
      />
    </Styled.RadioButton>
  );
};

export default RadioButtonPicker;
