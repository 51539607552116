import { createAction } from '@reduxjs/toolkit';
import {
  CompanyImport,
  CompanyImportFailedData,
  ImportSimilarCompanies,
} from '@optx/models/Company';
import * as types from './types';
import { ImportedCompany, ImportResults, PercentageCompleted } from './interfaces';

// import favorite list
export const importFavoriteList = createAction<void>(types.IMPORT_FAVORITE_LIST);
export const importFavoriteListSuccess = createAction<CompanyImport>(
  types.IMPORT_FAVORITE_LIST_SUCCESS
);
export const updateImportCompanies = createAction(
  types.updateImportCompanies,
  (payload: { title: string; data: ImportedCompany[] }) => ({ payload })
);
export const updateImportCompaniesIds = createAction(
  types.updateImportCompaniesIds,
  (payload: Array<{ companyName: string; id: number; URL: string; raw_url: string | null }>) => ({
    payload,
  })
);
// edit imported company
export const editCompany = createAction<ImportedCompany>(types.editCompany);
export const setIsEditing = createAction<boolean>(types.setIsEditing);
export const editCompanies = createAction<CompanyImportFailedData[]>(types.editCompanies);
export const editCompanySuccess = createAction<CompanyImport>(types.editCompanySuccess);
export const editingCompanyIndex = createAction<number | null>(types.editingCompanyIndex);
export const removeFailedCompamy = createAction<{ Company: string; URL: string }>(
  types.removeFailedCompamy
);
export const removeFailedCompamies = createAction<string[]>(types.removeFailedCompamies);
export const updateCanceledImportsNumber = createAction<number>(types.updateCanceledImportsNumber);

export const updatePercentage = createAction<PercentageCompleted>(types.updatePercentage);
export const updateProgress = createAction<boolean>(types.updateProgress);
export const resetProgress = createAction<void>(types.resetProgress);
export const updateCompleted = createAction<boolean>(types.updateCompleted);
export const updateCanceledImports = createAction<CompanyImportFailedData[]>(
  types.updateCanceledImports
);
export const cancel = createAction<boolean>(types.cancel);
export const saveResults = createAction<ImportResults>(types.saveResults);
export const saveListId = createAction<number>(types.saveListId);

export const setLoading = createAction<boolean>(types.isLoading);
export const showSimilarCompanies = createAction<boolean>(types.showSimilarCompanies);
export const setSimilarCompanies = createAction<ImportSimilarCompanies[]>(
  types.setSimilarCompanies
);

export const setFailedImportsOnRequest = createAction<ImportedCompany[]>(
  types.setFailedImportsOnRequest
);

export const removeFailedImportsOnRequest = createAction<string>(
  types.removeFailedImportsOnRequest
);

export const retryImport = createAction<void>(types.retryImport);
export const setFailedBatches = createAction<number>(types.setFailedBatches);
