import { useMemo } from 'react';
import { Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// redux
import { actions, selectors } from '@features/checklist-research-insights/state';
import { selectors as fullScreenSelectors } from '@redux/ui/settings/fullscreen';
// components
import { MainHeader } from '@optx/core/layout';
import GridFullScreen from '@optx/shared/view/molecules/FullScreen/GridFullScreen';
import FilterTags from './FilterTags';
import { HeaderContent } from '@optx/features/company-search/lib';
import ClearSearchButton from '@shared/view/molecules/Buttons/ClearSearchButton';
import CRISearchDropdown from '../CRISearchDropdown';
import Search from './Search';
import ShowFilterButton from '@optx/features/grid/filter/components/ShowFilterButton';

const Header = () => {
  const dispatch = useDispatch();
  const isClearedFilter = useSelector(selectors.filters.isCLearedFilter);
  const fullscreen = useSelector(fullScreenSelectors.isFullscreen);

  const options = useSelector(selectors.filters.getTags);

  const onClear = () => {
    dispatch(actions.filters.clearFilters());
  };

  const filteredOptions = useMemo(() => options.filter(item => !item.isDefault), [options]);

  return (
    <MainHeader className="main-header fixed">
      <HeaderContent>
        <Search className="antd-search-input" autoComplete="off" />
        <Space size={4}>
          <div style={{ width: 700 }}>
            <FilterTags options={filteredOptions} />
          </div>
          <ClearSearchButton isVisible={isClearedFilter} title="Clear All" onClick={onClear} />
          <ShowFilterButton gridKey={'checklistResearchInsights'} placement={'bottom'} />
          <CRISearchDropdown />
          {fullscreen && <GridFullScreen />}
        </Space>
      </HeaderContent>
    </MainHeader>
  );
};

export default Header;
