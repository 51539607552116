import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// models
import { EquityTouchContactForm, EquityTouchDatabaseMatch } from '@models/equityTouch';
// utils
import { formatEditContactForm } from '@optx/utils/contact';
import { CompanyUserContact } from '@optx/models/api/contacts';
// redux
import { actions, selectors } from '@redux/company/primary-contacts';
import { selectors as modalSelectors } from '@redux/ui/modals/edit-profile-contact';
import { selectors as companyIndividualEditSelectors } from '@components/feature/company-individual-edit/state';
// components
import { ContactForm } from '@optx/features/contact-form';

interface EditContactFormContainerProps {
  isExtension?: boolean;
}

const EditContactFormContainer: React.FC<EditContactFormContainerProps> = ({
  isExtension = false,
}) => {
  const dispatch = useDispatch();
  const initialValues = useSelector(selectors.getEditContactInitialValues);
  const modalOpen = useSelector(modalSelectors.isOpen);
  const componentUnmount = useRef(false);
  const individualEditOptions = useSelector(
    companyIndividualEditSelectors.companyIndividualEdit.getAllOptions
  );

  const cancelEditing = useCallback(() => {
    dispatch(actions.cancelEditingPrimaryContact());
  }, [dispatch]);

  const editPrimaryContact = useCallback(
    (contactInformation: Partial<CompanyUserContact>) => {
      dispatch(
        actions.editPrimaryContact(
          formatEditContactForm(
            contactInformation,
            individualEditOptions?.person_phone_type.received_values as EquityTouchDatabaseMatch[]
          )
        )
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, individualEditOptions]
  );

  useEffect(() => {
    return () => {
      componentUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    return () => {
      if (componentUnmount.current) {
        cancelEditing();
      }
    };
  }, [cancelEditing]);

  return (
    <ContactForm
      type="edit"
      initialValues={initialValues as EquityTouchContactForm}
      visible={modalOpen}
      onCancel={cancelEditing}
      onSubmit={editPrimaryContact}
      isExtension={isExtension}
    />
  );
};

export default EditContactFormContainer;
