export interface GlobalConfig {
  short_date: {
    DATE_FORMAT: string;
    GOOGLE_MAPS_API_KEY: string;
  };
  grid_date: {
    DATE_FORMAT: string;
  };
  GOOGLE_LOCATION_API_KEY: string;
}

/**
 * Global configuration.
 */
const config: GlobalConfig = {
  short_date: {
    // Default to US standard.
    DATE_FORMAT: 'MM/DD/YYYY',
    GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '<<API_KEY>>',
  },
  grid_date: {
    // Default grid format
    DATE_FORMAT: 'MMM DD, YYYY',
  },
  GOOGLE_LOCATION_API_KEY:
    process.env.REACT_APP_GOOGLE_LOCATION_API_KEY &&
    typeof process.env.REACT_APP_GOOGLE_LOCATION_API_KEY === 'string' &&
    process.env.REACT_APP_GOOGLE_LOCATION_API_KEY.length
      ? process.env.REACT_APP_GOOGLE_LOCATION_API_KEY
      : '<<LOCATION_API_KEY>>',
};

export default config;
