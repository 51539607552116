import React, { useState, useCallback, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { size } from 'lodash';
import { Drawer, Button, Input } from 'antd';
// styles
import './style.scss';
// models
import { CompanyNotification } from '@optx/models/Company';
import { AppState } from '@optx/redux/interfaces';
// constants
import { MAX_CHAR_LENGTH } from '@constants/notes';
// redux
import { selectors, actions } from '@redux/company/notes/companyNotes';
import {
  selectors as modalSelectors,
  actions as modalActions,
} from '@redux/ui/modals/company-notes';
// hooks
import useShouldFetch from '@optx/common/hooks/fetch/useShouldFetch';
import { useInjectUI, useInjectFavoriteLists } from '@optx/common/hooks/inject';
// components
import { LoadingBlock } from '@optx/shared/view/molecules/loader';
import iconCompany from '../../assets/images/company_ico@2x.png';
import CardNotification from './CardNotification';

const CompanyNotes: React.FC = () => {
  useInjectUI();
  useInjectFavoriteLists();
  const { company_id: companyId, company_name: companyName } = useSelector(
    modalSelectors.getCompanyInfo
  );

  const dispatch = useDispatch();
  const fetchNotes = useCallback(
    () => dispatch(actions.fetchCompanyNotes(companyId)),
    [dispatch, companyId]
  );
  const onCreateCompanyNote = useCallback(
    (noteText: string) =>
      dispatch(actions.addCompanyNote({ companyId, title: companyName, noteText })),
    [dispatch, companyId, companyName]
  );
  const onDeleteCompanyNote = useCallback(
    (noteId: number) => dispatch(actions.deleteCompanyNote({ companyId, noteId })),
    [dispatch, companyId]
  );
  const toggle = useCallback(() => {
    setNoteTextState('');

    return dispatch(
      modalActions.toggleCompanyNotesDialog({
        companyId,
        companyName,
      })
    );
  }, [dispatch, companyId, companyName]);

  const isOpen = useSelector(modalSelectors.isOpen);
  const shouldFetchNotes = useSelector<AppState, boolean>(state =>
    selectors.shouldFetch(state, companyId)
  );
  const isLoading = useSelector(selectors.isLoading);
  const errorNotifications = useSelector(selectors.getCompanyNotesError);
  const notifications = useSelector<AppState, Array<CompanyNotification>>(state =>
    selectors.getCompanyNotes(state, companyId)
  );

  useShouldFetch(shouldFetchNotes, fetchNotes);
  // local state
  const [noteText, setNoteTextState] = useState('');

  const onChangeText = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNoteTextState(event.target.value);
  };

  const handleCreateCompanyNote = () => {
    if (noteText) {
      onCreateCompanyNote(noteText);
    }

    if (!isLoading) {
      setNoteTextState('');
    }
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
      handleCreateCompanyNote();
    }
  };

  return (
    <>
      <LoadingBlock loading={isLoading} />
      <Drawer
        title="Notes"
        placement="right"
        onClose={toggle}
        visible={isOpen}
        className="company-notes"
      >
        <div className="company-buttons">
          <Button className="btn-cancel" onClick={toggle}>
            CANCEL
          </Button>{' '}
          <Button
            type="primary"
            className="btn-send"
            disabled={!noteText || !!errorNotifications}
            onClick={handleCreateCompanyNote}
          >
            SAVE
          </Button>
        </div>
        <div className="company-info">
          <div className="company-logo">
            <img
              style={{ width: 48, height: 48 }}
              src={iconCompany}
              alt="icon company"
              className=""
            />
          </div>
          <div className="company-name">{companyName}</div>
        </div>
        <div className="company-notification">
          {!errorNotifications ? (
            notifications.map(note => (
              <CardNotification
                key={note.note_unique_id}
                note={note}
                onDeleteCompanyNote={onDeleteCompanyNote}
              />
            ))
          ) : (
            <div className="message-error">{errorNotifications}</div>
          )}
        </div>
        <div className="company-box-reply">
          <div className="company-box-reply__title">
            <span className="count-word">
              {size(noteText)}
              /240
            </span>
          </div>
          <Input.TextArea
            name="note_text"
            value={noteText}
            id="exampleText"
            placeholder="Im typing a note/comment here"
            onChange={onChangeText}
            onKeyDown={handleDropdownKeyEvent}
            tabIndex={0}
            maxLength={MAX_CHAR_LENGTH}
          />
        </div>
      </Drawer>
    </>
  );
};

export default React.memo(CompanyNotes);
