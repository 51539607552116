import React from 'react';
import { TableCellSearches } from '@optx/models/table/Cell';
import useSugestedSearches from '../../../../../../common/hooks/saved-searches/useSugestedSearches';
import FilterTags from '../company/FilterTags';
import { mapTagsToNames } from '../../../../../../utils/filters/mapTagsToNames';

const TableCellFilters: React.FC<TableCellSearches> = ({ row }) => {
  // TODO: change filterSources code to be able to reuse the cell later
  // also use list directly
  const { handleSearchResults } = useSugestedSearches();

  const filterNames = mapTagsToNames(row.filteredTags!);

  const handleClick = () => {
    if (row.unique_id) {
      handleSearchResults(row.title);
    }
  };

  return <FilterTags filterNames={filterNames} onClick={handleClick} />;
};

export default TableCellFilters;
