const predefinedFilters = {
  number_of_companies_in_et: {
    source_tag_filter: [
      {
        in: true,
        label: 'EquityTouch',
        notIn: false,
        value: 'et',
      },
    ],
  },
  number_of_companies_not_in_et: {
    source_tag_filter: [
      {
        in: false,
        label: 'EquityTouch',
        notIn: true,
        value: 'et',
      },
    ],
  },

  number_of_last_touch_over_6_months: {
    last_touch_date: [
      { label: '7-12 Months Ago', value: '12_6', checked: true },
      { label: '+1 Year', value: '120_12', checked: true },
      { label: 'Never', value: 'blank', checked: true },
    ],
    source_tag_filter: [
      {
        in: true,
        label: 'EquityTouch',
        notIn: false,
        value: 'et',
      },
    ],
  },

  number_of_future_touches: {
    next_touch_date: [
      { label: 'Past Due', value: 'day_-120_day_0', checked: true },
      { label: 'This Month', value: 'month_0_month_1', checked: true },
      { label: 'Next Month', value: 'month_1_month_2', checked: true },
      { label: 'Next 2-3 Months', value: 'month_2_month_3', checked: true },
      { label: 'Next 3-6 Months', value: 'month_3_month_6', checked: true },
      { label: '6+ Months', value: 'month_6_month_120', checked: true },
    ],
  },
};

export default predefinedFilters;
