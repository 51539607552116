import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Space } from 'antd';
// models
import Company from '@optx/models/Company';
// redux
import { selectors as profileSelectors } from '@redux/company/profile';
import { actions as modalActions } from '@redux/ui/modals/company-touches';

const TouchesIntro: React.FC = () => {
  const dispatch = useDispatch();

  const company = useSelector(profileSelectors.getProfile) as Company;

  const handleCreateTouch = () => {
    dispatch(
      modalActions.toggleCompanyTouchesDialog({
        companyId: company.company_id,
        companyName: company.company_name,
        company,
      })
    );
  };

  return (
    <Row style={{ marginBottom: 0 }} gutter={[30, { xs: 15, sm: 0 }]}>
      <Col>
        <Space size="middle">
          <Button onClick={handleCreateTouch}>Add Touch Note</Button>
        </Space>
      </Col>
    </Row>
  );
};

export default TouchesIntro;
