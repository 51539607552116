import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  .ant-card-meta-detail .ant-card-meta-title {
    margin-bottom: 0;
    line-height: 24px;
  }
`;

export { Container };
