import React from 'react';
import { ReactComponent as LogoBackground } from '../../../../assets/svg/logo-background.svg';
import { ReactComponent as LogoIcon } from '../../../../assets/svg/icons/logo.svg';
// styles
import './styles.scss';

const Logo = () => {
  return (
    <div className="navigation-logo">
      <LogoBackground className="navigation-logo__background" />
      <div className="navigation-logo__content">
        <LogoIcon className="navigation-logo__icon" />
        <span className="navigation-logo__text">OPTX</span>
      </div>
    </div>
  );
};

export default Logo;
