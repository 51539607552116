import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { selectors, actions } from '../../../redux/company/saved-searches';
import { selectors as companyFiltersSelectors } from '../../../redux/company/filters';
import { useShouldFetch } from '../fetch';

const useFetchSavedSearches = () => {
  const dispatch = useDispatch();
  const filtersLoaded = useSelector(companyFiltersSelectors.filtersLoaded);
  const shouldFetchSearches = useSelector(selectors.shouldFetch);
  const fetchSavedSearches = useCallback(
    () => dispatch(actions.initializeSavedSearchesAndViews()),
    [dispatch]
  );

  // load saved searches after filters in order to map proper data using filters.
  useShouldFetch(filtersLoaded && shouldFetchSearches, fetchSavedSearches);
};

export default useFetchSavedSearches;
