import { createAction } from '@reduxjs/toolkit';
// models
import { types } from '../types';
import { AnalystResponse } from '../../models/analyst';
import Company from '@optx/models/Company';
import { CompanyColumnDisplay } from '@optx/models/table/Columns';
import { ColumnKeys } from '../../models/grid';

// analyst
export const fetchAnalyst = createAction(types.search.FETCH_ANALYST);
export const fetchAnalystSuccess = createAction<AnalystResponse>(
  types.search.FETCH_ANALYST_SUCCESS
);
export const fetchAnalystFail = createAction<string>(types.search.FETCH_ANALYST_FAIL);

// company
export const fetchCompany = createAction(types.search.FETCH_COMPANY);
export const fetchCompanySuccess = createAction<Company[]>(types.search.FETCH_COMPANY_SUCCESS);
export const fetchCompanyFail = createAction<string>(types.search.FETCH_COMPANY_FAIL);

export const saveColumns = createAction(
  types.search.SAVE_COLUMNS,
  (columnDisplay: CompanyColumnDisplay<any>, columnOrder: ColumnKeys[]) => ({
    payload: {
      columnDisplay,
      columnOrder,
    },
  })
);
