import { createAction } from '@reduxjs/toolkit';
import { Dictionary } from 'lodash';
// types
import * as types from './actionTypes';

export const setGeoRange = createAction(
  types.SEARCH_GEO_RANGE,
  (geoRangeZipCode: string, geoRangeCountry: string) => ({
    payload: {
      code: geoRangeZipCode,
      country_name: geoRangeCountry,
    },
  })
);

export const setGeoRangeSuccess = createAction<Dictionary<string>>(types.SEARCH_GEO_RANGE_SUCCESS);

export const setGeoRangeFail = createAction<string>(types.SEARCH_GEO_RANGE_FAIL);
