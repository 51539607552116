import axios, { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios';
import { CANCEL } from 'redux-saga';

export const API_URL = process.env.REACT_APP_API_ROOT || 'http://localhost:8000';

const CancelToken = axios.CancelToken;

class ApiClient {
  private client: AxiosInstance;

  private baseURL: string = '';

  constructor(baseUrl: string = API_URL) {
    this.baseURL = baseUrl;
    this.client = axios;
  }

  private createURL(url: string) {
    return `${this.baseURL}${url}`;
  }

  // original Axios methods
  get<Response>(url: string, conf: AxiosRequestConfig = {}) {
    const source = CancelToken.source();
    const request = this.client.get<Response>(this.createURL(url), {
      cancelToken: source.token,
      ...conf,
    });
    // Cancel saga task.Usefull when using takeLatest and in the application the same task is called twice,
    // only the second task will continue running and the first task will be canceled.
    // @ts-ignore
    request[CANCEL] = source.cancel;

    return request;
  }

  delete<Response>(url: string, conf: AxiosRequestConfig = {}) {
    const source = CancelToken.source();
    const request = this.client.delete<Response>(this.createURL(url), {
      cancelToken: source.token,
      ...conf,
    });
    // Cancel saga task.Usefull when using takeLatest and in the application the same task is called twice,
    // only the second task will continue running and the first task will be canceled.
    // @ts-ignore
    request[CANCEL] = source.cancel;

    return request;
  }

  head<Response>(url: string, conf: AxiosRequestConfig = {}) {
    const source = CancelToken.source();
    const request = this.client.head<Response>(this.createURL(url), {
      cancelToken: source.token,
      ...conf,
    });
    // Cancel saga task.Usefull when using takeLatest and in the application the same task is called twice,
    // only the second task will continue running and the first task will be canceled.
    // @ts-ignore
    request[CANCEL] = source.cancel;

    return request;
  }

  post<Response>(url: string, data?: any, conf: AxiosRequestConfig = {}) {
    const source = CancelToken.source();
    const request = this.client.post<Response>(this.createURL(url), data, {
      cancelToken: source.token,
      ...conf,
    });
    // Cancel saga task.Usefull when using takeLatest and in the application the same task is called twice,
    // only the second task will continue running and the first task will be canceled.
    // @ts-ignore
    request[CANCEL] = source.cancel;

    return request;
  }

  put<Response>(url: string, data?: any, conf: AxiosRequestConfig = {}) {
    const source = CancelToken.source();
    const request = this.client.put<Request, AxiosResponse<Response>>(this.createURL(url), data, {
      cancelToken: source.token,
      ...conf,
    });
    // Cancel saga task.Usefull when using takeLatest and in the application the same task is called twice,
    // only the second task will continue running and the first task will be canceled.
    // @ts-ignore
    request[CANCEL] = source.cancel;

    return request;
  }

  patch<Response>(url: string, data?: any, conf: AxiosRequestConfig = {}) {
    const source = CancelToken.source();
    const request = this.client.patch<Response>(this.createURL(url), data, {
      cancelToken: source.token,
      ...conf,
    });
    // Cancel saga task.Usefull when using takeLatest and in the application the same task is called twice,
    // only the second task will continue running and the first task will be canceled.
    // @ts-ignore
    request[CANCEL] = source.cancel;

    return request;
  }
}

export default ApiClient;
