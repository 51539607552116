import { createFeatureAction } from '@utils/feature';
import featureName from '../../featureName';
import * as actionTypes from './actionTypes';
import { EditSeniorAdvisor } from './interfaces';

export const addSeniorAdviser = createFeatureAction<EditSeniorAdvisor>(
  featureName,
  actionTypes.ADD_SENIOR_ADVISER
);

export const addSeniorAdviserSuccess = createFeatureAction<EditSeniorAdvisor>(
  featureName,
  actionTypes.ADD_SENIOR_ADVISER_SUCCESS
);

export const addSeniorAdviserFail = createFeatureAction<string>(
  featureName,
  actionTypes.ADD_SENIOR_ADVISER_FAIL
);
