import React from 'react';
import Pagination from 'react-js-pagination';
import { useDispatch } from 'react-redux';
import {
  DoubleLeftOutlined,
  LeftOutlined,
  RightOutlined,
  DoubleRightOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
// models
import { SelectedCompaniesGrids } from '@models/bulkActions';
import { GridKeys } from '@models/grid';
import { SearchStateData } from '@models/search';
// redux
import { actions as paginationActions } from '@features/grid/pagination';
// components
import { SelectedCount } from '@features/bulk-actions/selected-companies';
import { PaginationPerPage } from '@features/grid/pagination/components';
import SearchCount from '@features/grid/search-count/components/SearchCount';

type GridPaginationProps = {
  id?: string;
  pageSize: number;
  searchCountPageSize?: number;
  isFullscreen?: boolean;
  totalItemsCount: number;
  pageNumber: number;
  selectedCountType?: SelectedCompaniesGrids;
  isLoadingCount?: boolean;
  searchCountStyle?: React.CSSProperties;
  searchTitle?: string;
  listType?: {
    one: string;
    many: string;
  };
  isLoadingList?: boolean;
  selectedCard?: SearchStateData | null;
} & (
  | {
      onPageChange: (page: number, perPage?: number) => void;
      gridKey?: never;
    }
  | {
      onPageChange?: never;
      gridKey: GridKeys;
    }
);

const GridPagination: React.FC<GridPaginationProps> = ({
  id,
  onPageChange,
  pageSize,
  searchCountPageSize,
  totalItemsCount,
  isFullscreen,
  pageNumber,
  selectedCountType,
  isLoadingCount,
  gridKey,
  searchCountStyle,
  searchTitle,
  listType,
  isLoadingList,
  selectedCard,
}) => {
  const dispatch = useDispatch();

  if (isLoadingCount) {
    return (
      <div data-virtual-scroll-substract="my-companies-grid" className="grid-pagination">
        <div className="grid-pagination--content" style={{ justifyContent: 'center' }}>
          <LoadingOutlined style={{ color: '#4ce1b6', fontSize: '22px' }} />
        </div>
      </div>
    );
  }

  let handlePageChange = onPageChange;

  if (gridKey)
    handlePageChange = (page: number, perPage: number = pageSize) =>
      dispatch(
        paginationActions.changePagination({
          gridKey,
          data: { pageNumber: page, pageSize: perPage },
        })
      );

  if (totalItemsCount === 0 || !handlePageChange) return null;

  return (
    <div
      id={id}
      data-virtual-scroll-substract={selectedCountType === 'myCompanies' && 'my-companies-grid'}
      className="grid-pagination"
    >
      <div className="grid-pagination--content">
        <div className="grid-pagination--per-page">
          <PaginationPerPage onPageChange={handlePageChange} perPage={pageSize} />
          {selectedCountType && <SelectedCount type={selectedCountType} />}

          {isFullscreen && gridKey && (
            <SearchCount
              style={{ marginLeft: '12px' }}
              isFullScreen={isFullscreen}
              gridName={gridKey}
              className="text-ellipsis"
            />
          )}
        </div>

        <Pagination
          activePage={pageNumber}
          itemsCountPerPage={pageSize}
          totalItemsCount={totalItemsCount}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="pagination-item"
          innerClass="pagination-list"
          linkClass="pagination-link"
          firstPageText={<DoubleLeftOutlined />}
          prevPageText={<LeftOutlined />}
          nextPageText={<RightOutlined />}
          lastPageText={<DoubleRightOutlined />}
        />
      </div>
    </div>
  );
};

export default GridPagination;
