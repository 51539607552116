import styled from 'styled-components';
import { Col } from 'antd';

const FinancialEditAllContainer = styled.div`
  .edit-all-prop-info {
    background: #fff;
    padding: 24px 0 0 0;

    &--title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }
    .ant-collapse {
      width: 100%;
    }
    &.edit-all-prop-info--chrome-extension {
      h1.ant-typography {
        font-size: 14px;
      }
      span.ant-typography {
        font-size: 12px;
      }
    }
    .ant-collapse-borderless {
      background: transparent none;
    }
    .ant-collapse-header {
      font-size: 16px;
      box-shadow: 0 0 3px #c2cfe0;
      padding: 8px 24px;
      color: #595959;
      font-weight: 600;

      .anticon-right {
        font-size: 16px;
        color: #1890ff;
        svg {
          transform: rotate(-90deg);
        }
      }
    }
    .ant-collapse-content-box {
      background: rgba(224, 231, 239, 0.2);
      padding: 26px 24px 16px 24px !important;
    }

    .ant-select-selection-overflow {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .ant-select-selector .ant-select-selection-placeholder {
      z-index: 1;
      font-size: 14px;
    }
    .ant-select-customize-input .ant-select-arrow {
      top: 50%;
    }
    .link-btn {
      padding: 8px, 16px, 8px, 16px;
      color: #1890ff;
      text-transform: uppercase;
      border: 0 none;
      box-shadow: 0 none;
      background: transparent none;
    }
    &:not(.editAllFieldsTab) {
      .ant-col {
        &.last_investors {
          margin-right: 33%;
        }
        &.net_retention {
          max-width: 16.6666%;
          padding-left: 3px !important;
        }
        &.gross_retention {
          max-width: 16.6666%;
          padding-right: 3px !important;
        }
        &.grid-spacer:before {
          content: '';
          display: block;
          height: 1px;
          background: #d8dce5;
          margin: 10px -24px 0;
        }
      }
    }
    .ant-form-item {
      margin: 0;
      text-align: left;
    }
    .is_interesting {
      text-align: left;
    }
    &.edit-all-chrome-plugin {
      .ant-col {
        &.addon,
        &.acquiring_company {
          .addon--multiselect-async {
            min-width: auto;
          }
        }
      }
    }
  }
`;

const SearchedFieldContainer = styled.div`
  background: rgba(224, 231, 239, 0.2);
  padding: 26px 24px 16px 24px !important;
`;

const CustomCol = styled(Col)`
  & > .ant-col {
    margin-bottom: 16px;
  }
`;
export const Styled = {
  FinancialEditAllContainer,
  SearchedFieldContainer,
  CustomCol,
};
