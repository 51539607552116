import { createSelector } from 'reselect';
import { Dictionary } from '@reduxjs/toolkit';
// models
import { AnalystHomeState, TouchDataState, ActivityState } from '../interfaces';
import { AppState } from '@optx/redux/interfaces';
import { PreselectedFilter } from '@optx/models/filters';
import Option from '@models/Option';
// constants
import { labelNameMap } from '@features/analyst-home/constants/labels';
// utils
import { parseFilter } from '@optx/utils/filters/parseFilters';
// redux
import * as searchFilters from '@redux/company/filters/selectors';

const selectFeatureState = (state: AppState) => state.analystHome as AnalystHomeState;

const getCompanyActivityDataLoading = createSelector(
  selectFeatureState,
  state => state.company.loading
);

const selectCompanyActivityData = createSelector(selectFeatureState, state => state.company?.data);

const getInsightsStats = createSelector(selectFeatureState, state => state.insights.data);

const getInsightsLoading = createSelector(selectFeatureState, state => state.insights.loading);
const getPipelineData = createSelector(selectFeatureState, state => {
  const insightData = state.insights.data;
  // to reoreder totals object
  const totalsOrder = ['rank_5s', 'add_ons', 'Pass', 'Dead'];

  if (insightData) {
    const {
      stages,
      companies_touched: touches,
      my_companies: totalTouches,
      ...totals
    } = insightData.pipline_outreach_recency;

    const stagesData = Object.entries(stages ?? {}).map(([item, value]) => ({
      label: item,
      value,
    }));
    const touchesData = Object.entries(touches ?? {}).map(([item, value]) => ({
      label: item,
      value,
    }));
    const totalsData = Object.entries(totals ?? {})
      .sort(function (a, b) {
        return totalsOrder.indexOf(a[0]) - totalsOrder.indexOf(b[0]);
      })
      .map(([item, value]) => ({
        label: item,
        value,
      }));

    return {
      stages: stagesData,
      touches: touchesData,
      touchTotal: totalTouches ?? 0,
      totals: totalsData,
    };
  }

  return { stages: [], touches: [], touchTotal: 0, totals: [] };
});

const getTouchManagementData = createSelector(selectFeatureState, state => {
  const insightData = state.insights.data;

  if (insightData) {
    const touches: { [key in keyof TouchDataState]: number | null } = insightData.count_touches;

    return Object.entries(touches).map(([item, value]) => ({
      label: item,
      value,
      color: item === 'past_due' ? 'red' : undefined,
    }));
  }

  return [];
});

const getActivityInsightsData = createSelector(selectFeatureState, state => {
  // to reoreder activity insight object
  const acitivityInsightOrder = [
    'companies_reached',
    'stage_change',
    'companies_added',
    'qualified_leads',
  ];

  const activityInsightData = state.activity.insight.data;

  if (activityInsightData) {
    return Object.entries(activityInsightData ?? {})
      .sort(function (a, b) {
        return acitivityInsightOrder.indexOf(a[0]) - acitivityInsightOrder.indexOf(b[0]);
      })
      .map(([item, value]) => {
        const { analyst, team_avg, ...totals } = value;
        const label = labelNameMap[item as keyof ActivityState];

        return {
          label: label,
          values: [value.analyst, value.team_avg],
          totals: totals,
        };
      });
  }

  return [];
});

const getActivityTouchInsightsData = createSelector(selectFeatureState, state => {
  const activityTouchInsightData = state.activity.touch.data;

  if (activityTouchInsightData) {
    const touchByType: Option<number>[] = Object.entries(
      activityTouchInsightData.count_touch_by_type ?? {}
    ).map(([item, value]) => {
      return {
        label: item,
        value: value as number,
        disabled: item === 'Sales Loft Emails',
      };
    });

    return {
      chartData: activityTouchInsightData.stat_touch_last_week,
      countTouch: activityTouchInsightData.stat_count_touch,
      touchLegends: touchByType,
    };
  }

  return { chartData: [], touchLegends: [], countTouch: { analyst_team_avg: 0, analyst: 0 } };
});

const getTouchInsightsLoading = createSelector(
  selectFeatureState,
  state => state.activity.touch.loading
);

const getFiltersLoading = createSelector(selectFeatureState, state => {
  return state.filters.loading;
});

const getActivityInsightsDates = createSelector(selectFeatureState, state => {
  const filterdata = state.filters.data;

  if (filterdata) {
    const dates = filterdata.activity_insights_date;

    return dates;
  }

  return [];
});

const getActivityInsightsDateFilter = createSelector(
  selectFeatureState,
  state => state.filters.value.activity_insights_date
);

const getOutreachRecencyFilters = createSelector(
  selectFeatureState,
  searchFilters.getCompanyFilters,
  (state, companyFilters) => {
    const filterdata = state.filters.data;

    if (filterdata) {
      const redirects = filterdata.outreach_recency_redirect;
      const mappedFilters: Dictionary<Dictionary<PreselectedFilter>> = {};

      Object.keys(redirects).forEach(key => {
        const [filters] = parseFilter(redirects[key], companyFilters);
        mappedFilters[key] = filters as Dictionary<PreselectedFilter>;
      });

      return mappedFilters;
    }

    return {};
  }
);

const getTouchManagementRedirect = createSelector(
  selectFeatureState,
  state => state.filters.data?.touch_management_redirect || null
);

const getTouchManagementDates = createSelector(
  selectFeatureState,
  state => state.filters.data?.touch_management_date || []
);

const getActivityInsightsRedirect = createSelector(
  selectFeatureState,
  state => state.filters.data?.activity_insights_redirect || {}
);

const getTouchTypesRedirect = createSelector(
  selectFeatureState,
  state => state.filters.data?.touch_types_redirect || {}
);
const getSearchCardsBlocks = createSelector(selectFeatureState, state => {
  const companies = state.filters.data?.find_new_companies;

  return companies;
});

export const selectors = {
  getCompanyActivityData: selectCompanyActivityData,
  getSearchCardsBlocks,
  getCompanyActivityDataLoading,
  getTouchInsightsLoading,
  getPipelineData,
  getInsightsLoading,
  getActivityInsightsData,
  getFiltersLoading,
  getActivityTouchInsightsData,
  getActivityInsightsDates,
  getActivityInsightsDateFilter,
  getTouchManagementData,
  getTouchManagementRedirect,
  getTouchManagementDates,
  getInsightsStats,
  getOutreachRecencyFilters,
  getActivityInsightsRedirect,
  getTouchTypesRedirect,
};
