import { call, put, select, takeLatest } from 'redux-saga/effects';
import queryString from 'query-string';
// utils
import mapFiltersToURLParams from '@utils/filters/mapFiltersToURLParams';
// services
import { AnalystService } from '@services/api';
// Local
// redux
import { Dictionary } from 'lodash';
import { BaseFilter, PreselectedFilter } from '@optx/models/filters';
import { AxiosResponse } from 'axios';
import { TouchesAcrossRegionResponse } from '@optx/models/feature/analystsLeaderboard/touchesAcrossRegion';
import * as selectors from '../selectors';
import * as actions from '../actions';

export function* fetchTouchesAcrossRegionSaga() {
  const values: Dictionary<PreselectedFilter> = yield select(selectors.filters.getFilterValues);
  const filtersByColumn: Dictionary<BaseFilter<any>> = yield select(
    selectors.filters.getNormalizedFilters
  );

  try {
    const queryValues = mapFiltersToURLParams(filtersByColumn, values);
    const query = queryString.stringify(queryValues, { arrayFormat: 'comma' });
    const res: AxiosResponse<TouchesAcrossRegionResponse> = yield call(
      AnalystService.getMapInfo,
      query
    );

    yield put(actions.touchesAcrossRegion.fetchTouchesAcrossRegionSuccess(res.data));
  } catch (e: any) {
    yield put(
      actions.touchesAcrossRegion.fetchTouchesAcrossRegionFail(
        'Error fetching touches across region!'
      )
    );
  }
}

export default function* touchesAcrossRegionSagas() {
  yield takeLatest(
    actions.touchesAcrossRegion.fetchTouchesAcrossRegion,
    fetchTouchesAcrossRegionSaga
  );
}
