import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Formik, FormikValues, FormikProps } from 'formik';
import { Dictionary } from 'lodash';
import { Row, Col, Button, Typography, Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
// models
import { FilterSource, PreselectedFilter } from '@optx/models/filters';
// components
import SearchInput from '@optx/components/common/form/input/SearchInput';
import FiltersForm from './FiltersForm';

const { Title } = Typography;

interface FilterFormProps {
  filter: Dictionary<PreselectedFilter>;
  filterSources: Array<FilterSource>;
  //   search
  keySearch: string;
  onSearch: (key: string, filter: Dictionary<PreselectedFilter>) => void;
  validationSchema?: any;
  onClose?: () => void;
  onClear: VoidFunction;
  onReset: VoidFunction;
}

const FiltersModalContent: React.FC<FilterFormProps> = ({
  filter,
  filterSources,
  keySearch,
  onSearch,
  validationSchema,
  onClose,
  onClear,
  onReset,
}) => {
  // form
  const formRef = useRef<FormikProps<FormikValues> | null>();

  // formik submit handler
  const handleSubmit = () => {
    handleSearch(inputSearch);
  };

  // manually trigger submit
  const triggerSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  // search
  const handleSearch = (searchKey: string) => {
    if (formRef.current) {
      // Make any parent state updates with batched state updates.
      // This happens in an async callback or any event from WebAPIs https://developer.mozilla.org/en-US/docs/Web/Events
      // More info: https://github.com/facebook/react/issues/14259
      ReactDOM.unstable_batchedUpdates(() => onSearch(searchKey, formRef.current!.values));
    }
  };

  const [inputSearch, setInputSearch] = useState('');

  useEffect(() => {
    setInputSearch(keySearch);
  }, [keySearch]);

  const handleKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearch(event.target.value);
  };

  const handleReset = () => {
    onReset();

    // use same function to reset and clear filters and sorting
    // send a boolean to check if only reseting
    // filters or also resetting sorting
    setInputSearch('');

    if (formRef.current) {
      formRef.current.resetForm();
    }
  };

  const handleClear = () => {
    onClear();

    // use same function to reset and clear filters and sorting
    // send a boolean to check if only reseting
    // filters or also resetting sorting
    setInputSearch('');

    if (formRef.current) {
      formRef.current.resetForm();
    }
  };

  const handleClose = () => {
    onClose && onClose();
    // clear temporary state
    formRef.current && formRef.current.resetForm(filter);
  };

  return (
    <div className="filter__wrapper">
      <div className="filter-submit-wrapper">
        <div className="filter-submit__search-input">
          <SearchInput
            onSearch={handleSearch}
            value={inputSearch}
            onChange={handleKeyChange}
            placeholder="Search for analysts by keyword"
          />
        </div>
        <Row style={{ padding: '20px 40px' }}>
          <Col xs={24} md={6} lg={12}>
            <Space align="center" style={{ width: '100%', flexWrap: 'wrap' }}>
              <Title level={2} style={{ fontWeight: 'normal', margin: 0 }}>
                Filters
              </Title>
            </Space>
          </Col>
          <Col xs={24} md={18} lg={12}>
            <Space style={{ width: '100%', justifyContent: 'flex-end' }} align="center">
              <Button type="default" size="large" onClick={handleClear}>
                Deselect All
              </Button>
              <Button type="default" size="large" onClick={handleReset}>
                Restore Defaults
              </Button>
              <Button type="primary" size="large" onClick={triggerSubmit}>
                Apply Filters
              </Button>
              <Button
                type="link"
                icon={<CloseOutlined />}
                size="large"
                onClick={handleClose}
                style={{ color: '#000' }}
              />
            </Space>
          </Col>
        </Row>
      </div>
      <Formik
        initialValues={filter}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
        innerRef={instance => {
          formRef.current = instance;
        }}
        validateOnChange
        validateOnBlur
      >
        <FiltersForm filterSources={filterSources} />
      </Formik>
    </div>
  );
};

export default FiltersModalContent;
