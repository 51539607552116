import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
// utils
import { sliceURLQuery } from '@optx/utils/url';
// redux
import { actions as savedSearchesActions } from '../../../../redux/company/saved-searches';
// components
import SearchInput, { SearchInputProps } from '../../../common/form/input/SearchInput';

interface CompanySearchProps extends Omit<SearchInputProps, 'onSearch' | 'value'> {}

const SavedSearchesSearch: React.FC<CompanySearchProps> = ({ ...restProps }) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');

  const search = useCallback(
    (searchKey: string) => {
      dispatch(savedSearchesActions.fetchSavedSearches(searchKey));
      setQuery('');
    },
    [dispatch]
  );

  const handleSearch = (searchKey: string) => {
    const slicedQuery = sliceURLQuery(searchKey);
    search(slicedQuery);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const slicedQuery = sliceURLQuery(event.target.value);
    setQuery(slicedQuery);
  };

  return (
    <SearchInput
      {...restProps}
      onChange={handleChange}
      onSearch={handleSearch}
      value={query}
      placeholder="Search for company saved searches by keyword"
    />
  );
};

export default SavedSearchesSearch;
