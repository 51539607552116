import { createSelector } from 'reselect';
// models
import { AppState } from '@optx/redux/interfaces';

const selectCompanyRelationshipManagement = (state: AppState) =>
  state.companyRelationshipManagement;

export const isLoading = createSelector(
  selectCompanyRelationshipManagement,
  state => state.loading
);

export const getError = createSelector(selectCompanyRelationshipManagement, state => state.error);

export const getRelationshipManagementData = createSelector(
  selectCompanyRelationshipManagement,
  state => state.data
);
