import { createAction } from '@reduxjs/toolkit';
import {
  CompanyWatchList,
  DeleteCompanyFromWatchList,
  FetchFavoriteLists,
  FetchFavoriteListsPopup,
  AddCompanyToWatchListBulk,
} from '@models/WatchList';
import {
  SortingRule,
  CreateListPopupPayload,
  CreateListPopupSuccessPayload,
  CompanyToListPayload,
  DeleteCompanyFromList,
} from './interfaces';
import * as types from './types';

// initial fetch favorite lists
export const initialFetchFavoritesListsPopup = createAction(
  types.INITIAL_FETCH_FAVORITE_LISTS_POPUP
);

export const initialFetchFavoritesListsPopupSuccess = createAction<FetchFavoriteListsPopup>(
  types.INITIAL_FETCH_FAVORITE_LISTS_POPUP_SUCCESS
);

// fetch favorite lists
export const fetchFavoriteListsPopup = createAction<FetchFavoriteLists | undefined>(
  types.FETCH_FAVORITE_LISTS_POPUP
);
export const fetchFavoriteListsPopupSuccess = createAction<FetchFavoriteListsPopup>(
  types.FETCH_FAVORITE_LISTS_POPUP_SUCCESS
);

export const fetchFavoriteListsPopupFail = createAction<string>(types.FETCH_FAVORITE_LISTS_FAIL);

// sort favorite lists
export const sortFavoriteListsPopup = createAction<SortingRule>(types.SORT_FAVORITE_LISTS);

// create favorite list
export const createFavoriteListPopup = createAction<CreateListPopupPayload>(
  types.CREATE_FAVORITE_LIST
);
export const createFavoriteListPopupSuccess = createAction<CreateListPopupSuccessPayload>(
  types.CREATE_FAVORITE_LIST_SUCCESS
);
export const createFavoriteListPopupFail = createAction<string>(types.CREATE_FAVORITE_LIST_FAIL);

export const deleteCompanyFromListPopup = createAction<DeleteCompanyFromList>(
  types.DELETE_COMPANY_FROM_LIST
);
export const deleteCompanyFromListPopupSuccess = createAction<DeleteCompanyFromWatchList>(
  types.DELETE_COMPANY_FROM_LIST_SUCCESS
);

export const deleteCompanyFromListPopupFail = createAction<string>(
  types.DELETE_COMPANY_FROM_LIST_FAIL
);

// add company in list
export const addCompanyToListPopup = createAction<CompanyToListPayload>(types.ADD_COMPANY_TO_LIST);
export const addCompanyToListPopupSuccess = createAction(
  types.ADD_COMPANY_TO_LIST_SUCCESS,
  (companyId: number, watchLists: CompanyWatchList[], ownerName?: string) => ({
    payload: { companyId, watchLists, ownerName },
  })
);
export const addCompanyToListPopupFail = createAction<string>(types.ADD_COMPANY_TO_LIST_FAIL);

export const resetFetchedAt = createAction(types.resetFetchedAt);

export const addCompanyToListPopupBulkActionSuccess = createAction<AddCompanyToWatchListBulk>(
  types.ADD_COMPANY_TO_LIST_BULK_SUCCESS
);
