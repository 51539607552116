// Import favorite list
export const IMPORT_FAVORITE_LIST = 'favorite-lists/list-import/IMPORT_FAVORITE_LIST';
export const IMPORT_FAVORITE_LIST_SUCCESS =
  'favorite-lists/list-import/IMPORT_FAVORITE_LIST_SUCCESS';

export const updatePercentage = 'favorite-lists/list-import/UPDATE_PERCENTAGE';
export const updateProgress = 'favorite-lists/list-import/UPDATE_PROGRESS';
export const resetProgress = 'favorite-lists/list-import/RESET_PROGRESS';
export const updateCompleted = 'favorite-lists/list-import/UPDATE_COMPLETED';
export const updateImportCompanies = 'favorite-lists/list-import/UPDATE_IMPORT_COMPANIES';
export const updateCanceledImports = 'favorite-lists/list-import/UPDATE_CANCELED_COMPANIES';
export const updateImportCompaniesIds = 'favorite-lists/list-import/UPDATE_IMPORT_COMPANIES_IDS';
export const removeFailedCompamy = 'favorite-lists/list-import/REMOVE_FAILED_COMPANY';
export const removeFailedCompamies = 'favorite-lists/list-import/REMOVE_FAILED_COMPANIES';
export const updateCanceledImportsNumber =
  'favorite-lists/list-import/UPDATE_CANCELED_COMPANIES_NUMBER';

export const cancel = 'favorite-lists/list-import/CANCEL';
export const saveResults = 'favorite-lists/list-import/SAVE_RESULTS';
export const saveListId = 'favorite-lists/list-import/SAVE_LIST_ID';

export const isLoading = 'favorite-lists/list-import/IS_LOADING';

export const editCompany = 'favorite-lists/list-import/EDIT_COMPANY';
export const setIsEditing = 'favorite-lists/list-import/SET_IS_EDITING';
export const editCompanies = 'favorite-lists/list-import/EDIT_COMPANIES';
export const editCompanySuccess = 'favorite-lists/list-import/EDIT_COMPANY_SUCCESS';
export const editingCompanyIndex = 'favorite-lists/list-import/EDITING_COMPANY_INDEX';
export const showSimilarCompanies = 'favorite-lists/list-import/SHOW_SIMILAR_COMPANIES';
export const setSimilarCompanies = 'favorite-lists/list-import/SET_SIMILAR_COMPANIES';
export const setFailedImportsOnRequest = 'favorite-lists/list-import/SET_FAILED_IMPORTS_ON_REQUEST';
export const removeFailedImportsOnRequest =
  'favorite-lists/list-import/REMOVE_FAILED_IMPORTS_ON_REQUEST';

export const retryImport = 'favorite-lists/list-import/RETRY_IMPORT';
export const setFailedBatches = 'favorite-lists/list-import/SET_FAILED_BATCHES';
