import { call, put, takeLatest, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary } from 'lodash';
import queryString from 'query-string';
// models
import { SearchPayloadRequest } from '@models/api/contacts';
import { PreselectedFilter } from '@models/filters';
import { SortByRule, SortRule } from '@models/table/sorting';
import { PageInfo } from '@models/table/Pagination';
import Company from '@models/Company';
import { SearchViewType } from '@models/user';
import { SearchStateData } from '@models/search';
import { GridKeys } from '@models/grid';
import { ChangeCardSubfilterPayload } from '../filters-cards/interfaces';
// constants
import {
  MYCOMPANIES_DEFAULT_SORT_US,
  MYCOMPANIES_DEFAULT_SORT_IL,
} from '@constants/table/sort/defaultSort';
import { LONG_CARD_VIEW_DEFAULT_PAGE_SIZE } from '@constants/pagination';
// utils
import { mapSortQuery } from '@utils/search';
import { myCompaniesCardsFilters } from '@utils/myCompanies';
// redux
import { actions as histogramActions } from '@features/histograms/histograms-my-companies';
import { getFiltersQuery } from '@redux/company/search/search/sagasReusable';
import {
  selectors as userInformationSelectors,
  actions as userInformationActions,
} from '@redux/user/information';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import {
  selectors as paginationSelectors,
  actions as paginationActions,
} from '@features/grid/pagination';
import { actions as longCardPaginationActions } from '@features/grid/pagination-long-card';
import { selectors as searchSelectors } from '@features/grid/search';
import * as selectors from './selectors';
import { actions as sortActions, selectors as sortSelectors } from '../sort';
import { selectors as viewTypeSelectors } from '@features/grid/view-type';
import { selectors as filterCardsSelectors, actions as filterCardsActions } from '../filters-cards';
import { searchCompanies, searchCompaniesFail } from '@features/grid/search/state/sagasReusable';
import { getViewId } from '@optx/features/grid/view/state/selectors';
import { getListType } from '@optx/features/grid/search/state/selectors';

export function* changeSortSaga(action: PayloadAction<SortRule<Company>>) {
  const sortBy = action.payload.sortBy;

  const gridKey: GridKeys = 'myCompanies';
  const id: number = yield select(getViewId(gridKey));
  const listType: string = yield select(getListType(gridKey));

  const selectedCard: SearchStateData = yield select(filterCardsSelectors.getSelectedCard);
  const subFilter: string = yield select(filterCardsSelectors.getSubFilter);
  const filter: Dictionary<PreselectedFilter> = yield select(
    filterSelectors.getFilter('myCompanies')
  );
  const searchKey: string = yield select(searchSelectors.getSearchKey(gridKey));
  const regularPagination: PageInfo = yield select(paginationSelectors.getPagination(gridKey));
  const view: SearchViewType = yield select(viewTypeSelectors.searchView('myCompanies'));
  const userPageNumber: number = yield select(
    userInformationSelectors.getMyCompaniesResultsPageNumber
  );

  const pagination = {
    pageNumber: 1,
    pageSize: view === 'table' ? regularPagination.pageSize : LONG_CARD_VIEW_DEFAULT_PAGE_SIZE,
  };
  const customClearedFilter: Dictionary<PreselectedFilter> = yield select(
    filterSelectors.getCustomClearedFilter('myCompanies')
  );
  const filterQuery: Dictionary<string | (string | number)[]> = yield call(
    getFiltersQuery,
    listType === 'combined' ? customClearedFilter : filter
  );

  const searchData: SearchPayloadRequest = {
    searchKey,
    filter: subFilter
      ? // @ts-ignore
        // @ts-ignore
        { ...selectedCard.filter, ...selectedCard.data[subFilter], ...filterQuery }
      : { ...selectedCard.filter, ...filterQuery },
    pagination,
    sortBy,
    fromSavedList: id,
    listType,
  };

  const sortQuery = queryString.stringify(mapSortQuery(sortBy), {
    arrayFormat: 'comma',
  });

  try {
    if (searchData.pagination.pageNumber !== userPageNumber) {
      yield call(searchCompanies, searchData, 'myCompanies', filter, undefined, undefined, {
        my_companies_sorting: sortQuery,
        my_companies_results_page_number: searchData.pagination.pageNumber,
      });
    } else {
      yield call(searchCompanies, searchData, 'myCompanies', filter, undefined, undefined, {
        my_companies_sorting: sortQuery,
      });
    }

    if (view === 'long-card') {
      yield put(
        longCardPaginationActions.changeLongCardPaginationSuccess({ gridKey, data: pagination })
      );
    } else {
      yield put(
        paginationActions.changePaginationSuccess({ gridKey: 'myCompanies', data: pagination })
      );
    }
  } catch (error: any) {
    yield call(searchCompaniesFail, 'myCompanies', error);
  }
}

export function* resertSortSaga() {
  const defaultOptxScore: string = yield select(userInformationSelectors.getDefaultScore);
  const sortBy =
    defaultOptxScore === 'us' ? MYCOMPANIES_DEFAULT_SORT_US : MYCOMPANIES_DEFAULT_SORT_IL;

  const selectedCard: SearchStateData = yield select(filterCardsSelectors.getSelectedCard);
  const subFilter: string = yield select(filterCardsSelectors.getSubFilter);
  const filter: Dictionary<PreselectedFilter> = yield select(
    filterSelectors.getFilter('myCompanies')
  );
  const searchKey: string = yield select(searchSelectors.getSearchKey('myCompanies'));
  const regularPagination: PageInfo = yield select(
    paginationSelectors.getPagination('myCompanies')
  );
  const userPageNumber: number = yield select(
    userInformationSelectors.getMyCompaniesResultsPageNumber
  );
  const view: SearchViewType = yield select(viewTypeSelectors.searchView('myCompanies'));

  const pagination = {
    pageNumber: 1,
    pageSize: view === 'table' ? regularPagination.pageSize : LONG_CARD_VIEW_DEFAULT_PAGE_SIZE,
  };
  const filterQuery: Dictionary<string | (string | number)[]> = yield call(getFiltersQuery, filter);

  const searchData: SearchPayloadRequest = {
    searchKey,
    filter: subFilter
      ? // @ts-ignore
        // @ts-ignore
        { ...selectedCard.filter, ...selectedCard.data[subFilter], ...filterQuery }
      : { ...selectedCard.filter, ...filterQuery },
    pagination: {
      ...pagination,
      pageNumber: 1,
    },
    sortBy,
  };

  const sortQuery = queryString.stringify(mapSortQuery(sortBy), {
    arrayFormat: 'comma',
  });

  try {
    yield call(searchCompanies, searchData, 'myCompanies', filter, true);
    yield put(sortActions.resetSortSuccessAction());

    if (searchData.pagination.pageNumber !== userPageNumber) {
      yield put(
        userInformationActions.updateUserSettings({
          my_companies_sorting: sortQuery,
          my_companies_results_page_number: searchData.pagination.pageNumber,
        })
      );
    } else {
      yield put(userInformationActions.updateUserSettings({ my_companies_sorting: sortQuery }));
    }

    if (view === 'long-card') {
      yield put(
        longCardPaginationActions.changeLongCardPaginationSuccess({
          gridKey: 'myCompanies',
          data: pagination,
        })
      );
    } else {
      yield put(
        paginationActions.changePaginationSuccess({ gridKey: 'myCompanies', data: pagination })
      );
    }
  } catch (error: any) {
    yield call(searchCompaniesFail, 'myCompanies', error);
  }
}

export function* multiSortSaga(
  action: PayloadAction<boolean, any, Array<SortByRule<any>> | undefined>
) {
  const isMultiSort = action.payload;
  const currentSorting: SortByRule<any>[] = yield select(sortSelectors.getSorting);

  const sortingObject: SortRule<any> = {
    sortBy: currentSorting,
  };

  try {
    // Only make the call to API if previous sort was changed to single sort
    if (!isMultiSort) {
      yield put(sortActions.changeSortAction(sortingObject));
    }
  } catch (error: any) {
    yield call(searchCompaniesFail, 'myCompanies', error);
  }
}

export function* changeCardSubfilterSaga(action: PayloadAction<ChangeCardSubfilterPayload>) {
  const { cardId } = action.payload;
  const cards: Dictionary<SearchStateData> = yield select(filterCardsSelectors.getMyCompaniesCards);
  const selectedCardId: string = yield select(filterCardsSelectors.getSelectedCardId);
  const filter: Dictionary<PreselectedFilter> = yield select(
    filterSelectors.getFilter('myCompanies')
  );
  const searchKey: string = yield select(searchSelectors.getSearchKey('myCompanies'));
  const sortBy: SortByRule<any>[] = yield select(sortSelectors.getSorting);
  const allFiltersWithHistograms: string[] = yield select(selectors.selectAllFiltersWithHistograms);
  let subFilter: string = yield select(filterCardsSelectors.getSubFilter);
  let selectedCard: SearchStateData = yield select(filterCardsSelectors.getSelectedCard);
  const regularPagination: PageInfo = yield select(
    paginationSelectors.getPagination('myCompanies')
  );
  const view: SearchViewType = yield select(viewTypeSelectors.searchView('myCompanies'));
  const id: number = yield select(getViewId('myCompanies'));
  const listType: string = yield select(getListType('myCompanies'));
  const userPageNumber: number = yield select(
    userInformationSelectors.getMyCompaniesResultsPageNumber
  );

  const pagination = {
    pageNumber: 1,
    pageSize: view === 'table' ? regularPagination.pageSize : LONG_CARD_VIEW_DEFAULT_PAGE_SIZE,
  };

  if (selectedCardId === cardId && subFilter === action.payload.subFilter) {
    subFilter = '';
  } else if (selectedCardId === cardId && subFilter !== action.payload.subFilter) {
    subFilter = action.payload.subFilter;
  } else if (selectedCardId !== cardId) {
    selectedCard = cards[action.payload.cardId];
    subFilter = action.payload.subFilter;
  }

  const customClearedFilter: Dictionary<PreselectedFilter> = yield select(
    filterSelectors.getCustomClearedFilter('myCompanies')
  );
  const filterQuery: Dictionary<string | (string | number)[]> = yield call(
    getFiltersQuery,
    listType === 'combined' ? customClearedFilter : filter
  );
  const filterPayload = subFilter
    ? {
        ...selectedCard.filter,
        // @ts-ignore
        ...selectedCard.data[subFilter],
        ...filterQuery,
        source_tag_filter: selectedCard.filter.source_tag_filter,
        ...myCompaniesCardsFilters(selectedCard, {
          nextTouchDate: filterQuery.next_touch_date as any,
          lastTouchDateDays: filterQuery.last_touch_date_days as any,
          stage: filterQuery.stage as any,
        }),
        saved_list_id: selectedCard.filter.saved_list_id,
      }
    : {
        ...selectedCard.filter,
        ...filterQuery,
        source_tag_filter: selectedCard.filter.source_tag_filter,
        ...myCompaniesCardsFilters(selectedCard, {
          nextTouchDate: filterQuery.next_touch_date as any,
          lastTouchDateDays: filterQuery.last_touch_date_days as any,
          stage: filterQuery.stage as any,
        }),
      };

  const searchData: SearchPayloadRequest = {
    searchKey,
    filter: filterPayload,
    pagination: {
      ...pagination,
      pageNumber: 1,
    },
    sortBy,
    fromSavedList: id,
    listType,
  };

  try {
    if (searchData.pagination.pageNumber !== userPageNumber) {
      yield call(searchCompanies, searchData, 'myCompanies', filter, undefined, undefined, {
        my_companies_results_page_number: searchData.pagination.pageNumber,
      });
    } else {
      yield call(searchCompanies, searchData, 'myCompanies', filter, undefined, undefined, {
        my_companies_selected_card_id: cardId,
      });
    }

    yield put(filterCardsActions.changeCardSubfilterSuccess({ cardId, subFilter }));
    yield put(histogramActions.fetchHistogramFilters(filter, allFiltersWithHistograms));

    if (view === 'long-card') {
      yield put(
        paginationActions.changePaginationSuccess({ gridKey: 'myCompanies', data: pagination })
      );
    } else {
      yield put(
        paginationActions.changePaginationSuccess({
          gridKey: 'myCompanies',
          data: { ...pagination, pageNumber: 1 },
        })
      );
    }
  } catch (error: any) {
    yield call(searchCompaniesFail, 'myCompanies', error);
  }
}

export default function* companiesSaga() {
  yield takeLatest(sortActions.changeSortAction, changeSortSaga);
  yield takeLatest(sortActions.multiSortAction, multiSortSaga);
  yield takeLatest(sortActions.resetSortAction, resertSortSaga);
  yield takeLatest(filterCardsActions.changeCardSubfilter, changeCardSubfilterSaga);
}
