import React from 'react';
import Company from '@optx/models/Company';
import { formatNumber } from '../../../../../utils/helpers';
import { Cell } from '../../../../../models/table/Cell';
import { cellIsEqual } from './memoize';
import { DEFAULT_CELL_VALUE } from '../../../../../constants/table/cells';

const TableCellNumber: React.FC<Cell<Company>> = ({ value }) =>
  // @ts-ignore
  value === DEFAULT_CELL_VALUE ? DEFAULT_CELL_VALUE : formatNumber(Number(value));

export default React.memo(TableCellNumber, cellIsEqual);
