import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  CompanyTouchesOptionsResponse,
  TouchInitiator,
  TouchTypeResponse,
  CompanyTouchesResponse,
} from '@optx/models/company/CompanyTouch';
import { SelectOption } from '@optx/models/Option';
import { fetchReducer, fetchFailReducer, fetchSuccessReducer } from '@redux/feature/fetch/reducers';
// import * as actions from '../actions/filters';
// models
import { CompanyTouchesFiltersState, SearchTouchesPayload } from '../interfaces';
import { actions } from '../actions';

const initialState: CompanyTouchesFiltersState = {
  data: null,
  loading: false,
  error: '',
  fetchedAt: '',
  initiators: [],
  touchTypes: [],
};

// fetch touches filters reducer
const fetchTouchesFiltersSuccessReducer: CaseReducer<
  CompanyTouchesFiltersState,
  PayloadAction<CompanyTouchesOptionsResponse>
> = (draftState, action) => {
  fetchSuccessReducer(draftState, action);

  const { available_initiators: availableInitiators, available_touch_types: availableTouchTypes } =
    action.payload;

  const initiators = mapInitiators(availableInitiators);
  draftState.initiators = initiators;
  draftState.currentInititator = initiators.length ? initiators[0].value : undefined;

  const touchTypes = mapTouchTypes(availableTouchTypes);
  draftState.touchTypes = touchTypes;
  draftState.currentTouchType = touchTypes.length ? touchTypes[0].value : undefined;

  draftState.fetchedAt = new Date().toISOString();
};

const ALL_OPTION: SelectOption = {
  label: 'All',
  value: '*',
};

function mapInitiators(initiators: Array<TouchInitiator>) {
  // initialize with all option
  const options: Array<SelectOption> = [];

  if (!initiators.length) {
    return options;
  }

  options.push(ALL_OPTION);

  initiators.forEach(item =>
    options.push({
      label: item.initiatorname,
      value: item.initiatorid === null ? '' : item.initiatorid.toString(),
    })
  );

  return options;
}

function mapTouchTypes(touchTypes: Array<TouchTypeResponse>) {
  // initialize with all option
  const options: Array<SelectOption> = [];

  if (!touchTypes.length) {
    return options;
  }

  options.push(ALL_OPTION);

  touchTypes.forEach(item =>
    options.push({
      label: item.touchtype,
      value: item.touchtype,
    })
  );

  return options;
}

// search success
// fetch touches
const fetchTouchesSuccessReducer: CaseReducer<
  CompanyTouchesFiltersState,
  PayloadAction<CompanyTouchesResponse, any, SearchTouchesPayload>
> = (draftState, action) => {
  const searchPayload = action.meta;
  const { number_of_touches: numberOfTouches } = action.payload;

  if (numberOfTouches && draftState.data) {
    draftState.data.number_of_touches =
      numberOfTouches.scheduled +
      numberOfTouches.last_six_weeks +
      numberOfTouches.over_six_months +
      numberOfTouches.over_six_weeks;
  }

  draftState.currentInititator = searchPayload.initiatorId || '*';
  draftState.currentTouchType = searchPayload.touchType || '*';
};

const reducer = createReducer(initialState, builder =>
  builder
    // fetch company touches filters
    .addCase(actions.filters.fetchTouchesFilters, fetchReducer)
    .addCase(actions.filters.fetchTouchesFiltersSuccess, fetchTouchesFiltersSuccessReducer)
    .addCase(actions.filters.fetchTouchesFiltersFail, fetchFailReducer)
    // search success
    .addCase(actions.search.fetchTouchesSuccess, fetchTouchesSuccessReducer)
);

export default reducer;
