import { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
// models
import { CompanyActivityCardData } from '../../state/interfaces';
// constants
import { SHORT_MONTH_YEAR_DATE_FORMAT } from '@optx/constants/format/date';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { CompanyProfileTabs } from '@optx/constants/routes';
// utils
import { getCompanyProfileURL } from '@optx/utils/routes';
import { getDateColor } from '@optx/utils/text';
// redux
import { selectors as userSelectors } from '@redux/user/information';
import { selectors } from '@features/analyst-home/state';
// components
import { IconCompany } from '@optx/components/icons';
import { TrendingValue } from '@optx/components/common/trending';
import Icon from '@components/common/Icon';
import Styled from './CompanyActivityCard.styled';
import CompanyActivityCardSkeleton from './CompanyActivityCardSkeleton';

interface CompanyActivityCardProps {
  data: CompanyActivityCardData;
}

const CompanyActivityCard: FC<CompanyActivityCardProps> = ({ data }) => {
  const history = useHistory();
  const defaultOptxScore = useSelector(userSelectors.getDefaultScore);
  const loading = useSelector(selectors.getCompanyActivityDataLoading);
  const lastValue = defaultOptxScore === 'il' ? data.il_optx_score : data.score;

  const isPSGCompany = data.is_psg_company;

  const handleCardClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    // if user clicks on 'open in new' icon in most cases event target is svg but sometimes it can be 'path' or 'a' tag
    // so if it is path we need to get parent node and check if it has class 'icon-open-in-new' or if it is a tag
    // with class 'company_link'
    if (
      !(e.target as HTMLElement).classList.contains('icon-open-in-new') &&
      !((e.target as HTMLElement).parentNode as HTMLElement).classList.contains(
        'icon-open-in-new'
      ) &&
      !(e.target as HTMLElement).classList.contains('company_link')
    )
      history.push(
        getCompanyProfileURL(
          data.company_id,
          `tab=${CompanyProfileTabs.RELATIONSHIP_MANAGEMENT}&referrer=/`
        )
      );
  };

  if (loading) {
    return <CompanyActivityCardSkeleton />;
  }

  return (
    <Styled.CompanyActivityCard className="ant-card-hoverable" onClick={handleCardClick}>
      <div className="company-avatar">
        {data.logo_url ? <img src={data.logo_url!} alt={data.company_name} /> : <IconCompany />}
      </div>
      <div className="content">
        <span className="company">
          <Link
            to={getCompanyProfileURL(
              data.company_id,
              `tab=${CompanyProfileTabs.RELATIONSHIP_MANAGEMENT}`
            )}
            className="company_name"
          >
            {data.company_name}
          </Link>
          <Link
            to={getCompanyProfileURL(
              data.company_id,
              `tab=${CompanyProfileTabs.RELATIONSHIP_MANAGEMENT}&referrer=/`
            )}
            target="_blank"
            className="company_link"
          >
            <Icon iconName="open-in-new" />
          </Link>
        </span>
        <span className="location">{data.location}</span>
      </div>
      <div className="stats">
        <div className="d-flex flex-column">
          <span className="last-touch" style={{ color: getDateColor(data.last_touch_date) }}>
            {data.last_touch_date
              ? moment(new Date(data.last_touch_date)).format(SHORT_MONTH_YEAR_DATE_FORMAT)
              : DEFAULT_EMPTY_VALUE}
          </span>
          <span>LAST TOUCH</span>
        </div>
        <div className="d-flex flex-column">
          {isPSGCompany ? (
            <Styled.PSGCompanyLabel>PSG</Styled.PSGCompanyLabel>
          ) : (
            <>
              <span className="optx d-flex align-items-center">
                <span>{lastValue ?? 'N/A'}</span>
                <TrendingValue
                  value={defaultOptxScore === 'il' ? data.il_score_growth : data.score_growth}
                />
              </span>
              <span>{defaultOptxScore === 'il' ? 'IL OPTX SCORE' : 'OPTX SCORE'}</span>
            </>
          )}
        </div>
      </div>
    </Styled.CompanyActivityCard>
  );
};

export default CompanyActivityCard;
