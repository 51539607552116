import React, { useCallback } from 'react';
import { TableCellSearches } from '@optx/models/table/Cell';
import useCompanySavedSearches from '@optx/common/hooks/saved-searches/useCompanySavedSearches';
import { TableCellDefault } from '../../../AntTable/cells/TableCellDefault';

const TableCellSearchKey: React.FC<TableCellSearches> = ({ value, row }) => {
  const { handleSearchResults } = useCompanySavedSearches();

  const transformSearchKey = useCallback((value?: string | string[]) => {
    if (!value) return undefined;

    if (value instanceof Array) {
      return value.map(item => item.trim()).join(', ');
    }

    return value;
  }, []);

  const handleClick = () => {
    if (row.unique_id) {
      handleSearchResults(row.unique_id, row.title, row.search_info.type || '');
    }
  };

  return (
    <div role="presentation" onClick={handleClick}>
      <TableCellDefault value={transformSearchKey(value)} />
    </div>
  );
};

export default TableCellSearchKey;
