import styled, { css } from 'styled-components';
import { Card, Input, DatePicker } from 'antd';

const AuditTrailWrapper = styled(Card)<{ $hasFields: boolean }>`
  .ant-card-body {
    padding: 0;
  }

  .ant-row {
    padding: 15px 24px 0;
  }

  .profile-rationale > .ant-row:not(.profile-information__subtitle) {
    padding: 0;
  }

  .ant-tabs {
    overflow: unset;

    .ant-tabs-content-holder {
      ${props =>
        props.$hasFields &&
        css`
          margin-top: 70px;
        `}
      position: relative;
      padding: 15px 24px 0;

      &:before {
        ${props =>
          props.$hasFields &&
          css`
            content: '';
            display: block;
            position: absolute;
            top: -70px;
            left: calc(24% - 2px);
            width: 2px;
            height: 90px;
            background: #f0f0f0;
          `}
      }
    }

    .ant-timeline-item {
      &-label {
        width: calc(20% - 12px);
        font-weight: 600;
        color: #616f82;
      }

      &-head,
      &-tail {
        left: 22%;
      }

      .ant-timeline-item-content {
        left: calc(22% - 4px);
        width: calc(70% - 12px);
      }
    }

    .ant-tabs-nav {
      border-bottom: 2px solid #f0f0f0;
      margin: 0;
      padding: 15px 24px 0;
    }

    .ant-tabs-tab {
      color: #000000 !important;
      text-transform: none !important;
      letter-spacing: unset !important;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #1890ff !important;
    }
    .ant-tabs-ink-bar {
      background: #1890ff !important;
    }

    .ant-timeline-item-pending {
      .ant-timeline-item-tail {
        top: 0;
        height: 100%;
        border-left: 2px solid #f0f0f0;
      }

      .ant-timeline-item-head {
        display: none;
      }
    }
  }
`;

const Label = styled.span`
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  /* Gray */
  color: #90a0b7;
`;

const Value = styled.span<{ isDescription?: boolean }>`
  font-weight: 600;
  /* Blue */
  color: #1890ff;

  ${({ isDescription }) =>
    isDescription &&
    css`
      span  {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    `}
`;

const Rationale = styled.span<{ isAfterRationale?: boolean }>`
  display: inline-block;
  padding-left: 5px;

  ${props =>
    props.isAfterRationale &&
    css`
      font-weight: 600;
      /* Blue */
      color: #1890ff;
    `}
`;

const Opinion = styled.span`
  font-weight: 600;
  /* Blue */
  color: #1890ff;
`;

const FormerValue = styled.span<{ isDescription?: boolean }>`
  color: #90a0b7;

  ${({ isDescription }) =>
    isDescription &&
    css`
      span  {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    `}
`;

const User = styled.span`
  display: block;
  font-weight: 600;
  color: #000000;
`;

const Bar = styled.div`
  width: 1px;
  height: 31px;
  background: #d9d9d9;
  display: block;
  position: absolute;
  left: 47%;
  top: 0;
  padding: 0;
`;

const SearchField = styled(Input.Search)`
  margin-left: 0;
  padding-left: 0;
  .ant-input-wrapper {
    display: flex;
    flex-direction: row-reverse;
    margin-left: 0;
    padding-left: 0;
    .ant-input {
      padding-left: 35px;
    }
    .ant-input-group-addon:last-child,
    .ant-input-group-addon {
      position: absolute;
      z-index: 2;
      top: 1px;
      left: 1px;
      .ant-btn {
        vertical-align: top;
        border: 0 none;
        height: 29px;
      }
      &:hover .ant-btn {
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
  .ant-input-search-icon {
    margin-left: 0;
    &:before {
      display: none;
    }
  }
`;

const { RangePicker } = DatePicker;

const CustomRangePicker = styled(RangePicker)`
  .left-calendar {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 35%;
    padding: 10px;
  }

  .ant-picker-input {
    width: calc(100% - 20px);
    &:first-of-type {
      margin-right: 20px;
    }
  }

  .ant-picker-range-separator {
    padding: 0;
  }

  .ant-picker-active-bar {
    display: none;
  }
`;

export const Styles = {
  AuditTrailWrapper,
  Label,
  Value,
  Rationale,
  Opinion,
  FormerValue,
  User,
  Bar,
  SearchField,
  CustomRangePicker,
};
