import React, { useState, useEffect } from 'react';
import querystring from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Checkbox, Col } from 'antd';
import { Form, Input } from 'formik-antd';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
// constants
import { CognitoErrors } from '@constants/api';
import { isChrome } from '@optx/constants/os/browser';
import appRoutes from '@constants/routes';
// redux
import { LoginPayload } from '@redux/login/interface';
import { userLogin, userLoginCrossTabs } from '@redux/login/actions';
import {
  actions as cognitoLogoutActions,
  selectors as cognitoLogoutSelectors,
} from '@redux/auth/cognitoLogout';
import {
  actions as cognitoLoginActions,
  selectors as cognitoLoginSelectors,
} from '@redux/auth/cognitoLogin';
// hooks
import { useInjectLogin } from '@optx/common/hooks/inject';
// storage
import { JWTStorage, ExtensionStorage, CognitoStorage } from '@optx/storage/localStorage';
import { setAxiosAuthorizationHeaders } from '@optx/modules/axios';
// components
import logoCompany from '@assets/images/logo-company.png';
import { iframePostMessage } from '@optx/utils/window';
import { Styled } from './Login.styled';
interface LoginValues {
  userId: string;
  password: string;
}

const initialValues: LoginValues = {
  userId: '',
  password: '',
};

const Login: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState('');
  useInjectLogin();

  const history = useHistory();

  const dispatch = useDispatch();
  const shouldFetchCognitoURL = useSelector(cognitoLoginSelectors.shouldFetchCognitoURL);

  const cognitoButtons = useSelector(cognitoLoginSelectors.cognitoButtons);

  const shouldFetchCognitoLogoutURL = useSelector(
    cognitoLogoutSelectors.shouldFetchCognitoLogoutURL
  );

  const getCognitoError = () => {
    // Get cognito error from store and initalize form error.
    const cognitoErrorCode = CognitoStorage.getErrorCode();
    let errorMessage = '';

    if (cognitoErrorCode !== CognitoErrors.None) {
      // handle cognito error cases

      switch (cognitoErrorCode) {
        case CognitoErrors.InvalidEmail: {
          errorMessage = `Account not found for ${CognitoStorage.getError()}`;
          break;
        }

        default:
          errorMessage = '';
      }

      CognitoStorage.clearErrors();
    }

    return errorMessage;
  };

  useEffect(() => {
    const token = handleToken();

    if (window.self !== window.top && window.name !== '') {
      const extensionDisplay: boolean = ExtensionStorage.isDisplay() !== 'false';
      iframePostMessage('hide-optx-extension', Boolean(extensionDisplay));
    }

    // if user logout on extension, the window name is Logout - Extension. The condition will close the new window
    if (window.name === 'Logout - Extension') {
      window.close();

      return;
    }

    handleCognitoURLQueryError();

    if (shouldFetchCognitoLogoutURL) {
      dispatch(cognitoLogoutActions.fetchCognitoLogoutURL());
    }

    if (!token) {
      if (shouldFetchCognitoURL) {
        dispatch(cognitoLoginActions.fetchCognitoLoginURL());
      }
    } else {
      if (window.self !== window.top) {
        history.push(appRoutes.chromeExtension);
      } else {
        history.push(appRoutes.home);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCookieStorage = (event: { changed: any; deleted: any }) => {
    for (const change of event.changed) {
      if (change.name === 'optx_token') {
        dispatch(userLoginCrossTabs({ isChromeExtension: window.name !== '' }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    if (isChrome) {
      //@ts-ignore
      window?.cookieStore?.addEventListener('change', handleCookieStorage, true);

      return () => {
        //@ts-ignore
        window?.cookieStore?.removeEventListener('change', handleCookieStorage, true);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  window.addEventListener('storage', () => {
    const token = JWTStorage.getJWT();
    const isExtension = window.self !== window.top;
    let sameRoute;

    if (isExtension) {
      sameRoute = window.self.location.pathname !== appRoutes.chromeExtension;
    } else {
      sameRoute = window.self.location.pathname !== appRoutes.home;
    }

    if (token && sameRoute) {
      setAxiosAuthorizationHeaders(token as string);

      if (isExtension) {
        history.push(appRoutes.chromeExtension);
      } else {
        // when token refreshes, we want to reload the page
        // in this case, if token isn't valid for whatever reason,
        // after reload user will be redirected to login page
        history.push(history.location.pathname);
      }
    }
  });

  /**
   * Fetch cognito logout URL if we have email error in the URL and store the error for processing
   * on the next redirect on this page from logout URL.
   */
  const handleCognitoURLQueryError = () => {
    const { error_code: errorCode, error_info: errorInfo } = querystring.parse(
      window.location.search
    );

    if (parseInt(errorCode as string) === CognitoErrors.InvalidEmail) {
      CognitoStorage.setErrorCode(errorCode as string);
      CognitoStorage.saveError(errorInfo as string);
      setErrorMessage(getCognitoError());
    }
  };

  const handleSubmit = (values: LoginValues) => {
    const payload: LoginPayload = {
      headers: {
        'X-User-Id': values.userId.replace(/[‘’]/g, "'"),
        'X-Api-Key': values.password.replace(/[‘’]/g, "'"),
      },
      isChromeExtension: window.name !== '',
    };

    dispatch(userLogin(payload, handleLoginResponse));
  };

  // callback for login.
  const handleLoginResponse = (response: any, errorMessage: string) => {
    if (errorMessage) {
      setErrorMessage(errorMessage);
    }
  };

  useEffect(() => {
    if (
      window.location.ancestorOrigins?.item(0) !== window.location?.origin &&
      window.location?.origin !== null
    )
      dispatch(userLoginCrossTabs({ isChromeExtension: window.name !== '' }));
  }, [dispatch]);

  // Initialize token from URL in case of Cognito login.
  const handleToken = () => {
    const { token } = querystring.parse(window.location.search);

    if (token) {
      document.cookie = `optx_token=${token}; SameSite=None; Secure;`;
      setAxiosAuthorizationHeaders(token as string);
      JWTStorage.setJWT(token as string);

      if (window.name === 'Cognito Login - Extension')
        setTimeout(() => {
          window.close();
        }, 300);
    }

    return token;
  };

  const onCognitoLogin = (cognitoUrl: string) => {
    const isExtension = window.self !== window.top;

    if (cognitoUrl && isExtension) {
      window.open(cognitoUrl, 'Cognito Login - Extension', 'statusbar=no,height=600,width=800');
    } else {
      window.location.assign(cognitoUrl);
    }
  };

  return (
    <Styled.LoginWrapper $isExtension={window.name !== ''}>
      <>
        <Styled.AccountBg />
        <Styled.WrapperPage>
          <Styled.Card>
            <Row justify="center">
              <Styled.LogoWrapper href="index.html">
                <Styled.Logo src={logoCompany} />
                <Styled.CompanyTitle>OPTX</Styled.CompanyTitle>
              </Styled.LogoWrapper>
            </Row>
            <Styled.FormWrapper>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                component={() => (
                  <Form>
                    {errorMessage && (
                      <div className="row alert alert-danger" role="alert">
                        <div className="col-12">{errorMessage}</div>
                      </div>
                    )}
                    <Form.Item name="userId">
                      <Input placeholder="User ID" name="userId" type="text" />
                    </Form.Item>

                    <Form.Item name="password">
                      <Input name="password" type="password" placeholder="Password" />
                    </Form.Item>

                    <Row>
                      <Styled.SignInButton htmlType="submit">Sign In</Styled.SignInButton>
                    </Row>
                    <Row>
                      <Checkbox>Keep me signed in</Checkbox>
                    </Row>

                    <Row>
                      {cognitoButtons.map((btn, index) => (
                        <Styled.CognitoButton
                          key={index}
                          onClick={() => onCognitoLogin(btn.link)}
                          disabled={!btn.link}
                        >
                          {btn.title}
                        </Styled.CognitoButton>
                      ))}
                    </Row>

                    <Row>
                      <Col span="sm-14">
                        <a href="pages-recoverpw.html" className="text-muted">
                          <i className="mdi mdi-lock" /> <small>Forgot your password ?</small>
                        </a>
                      </Col>
                      <Col span="sm-10">
                        <a href="pages-register.html" className="text-muted">
                          <i className="mdi mdi-account-circle" />{' '}
                          <small>Create an account ?</small>
                        </a>
                      </Col>
                    </Row>
                  </Form>
                )}
              />
            </Styled.FormWrapper>
          </Styled.Card>
        </Styled.WrapperPage>
      </>
    </Styled.LoginWrapper>
  );
};

export default Login;
