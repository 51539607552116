import React from 'react';

export const breakLines = (value: string) => {
  return value.split(',').map((item, key) => {
    return (
      <span key={key}>
        {item}
        <br />
      </span>
    );
  });
};
