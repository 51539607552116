import queryString from 'query-string';
// models
import { RouteAliases } from '@optx/models/routes';
import { GridKeys } from '@optx/models/grid';
// constants
import appRoutes, { CompanyProfileTabs } from '@constants/routes';
// storage
import { AnalystStorage } from '@optx/storage/localStorage';

/**
 * Compute company profile URL.
 * @param {number} companyId company identifier.
 * @param {string} searchParams optional stringified search parameters
 */
export const getCompanyProfileURL = (companyId: number, searchParams?: string) =>
  `${appRoutes.profile.replace(':id', companyId.toString())}?${searchParams || ''}`;

/**
 * Compute add to equity touch URL
 * @param {number} companyId company identifier
 * @param {string} searchParams optional stringified search parameters
 */
export const geEquityTouchURL = (companyId: number, searchParams?: string) =>
  `${appRoutes.addToEquityTouch.replace(':id', companyId.toString())}?${searchParams || ''}`;

/**
 * Compute add to salesloft URL
 * @param {number} companyId company identifier
 * @param {string} searchParams optional stringified search parameters
 */
export const getAddToSalesLoftURL = (companyId: number, searchParams?: string) =>
  `${appRoutes.addToSalesloft.replace(':id', companyId.toString())}?${searchParams || ''}`;

/**
 * Compute list URL.
 * @param listId list identifier.
 */
export const getListURL = (listId: string) => appRoutes.userLists.list.replace(':id', listId);

/**
 * Compute source scrub list URL.
 * @param listId list identifier.
 */
export const getSourceScrubListURL = (listId: string) =>
  appRoutes.userLists.sourceScrubLists.replace(':id', listId);

/**
 * get the pathname from a string url that's including the protocol
 * @param {string} url a url
 */
export const getOriginPath = (url: string) => {
  return url.replace(window.location.origin, '');
};

/**
 * Stringify a referrer parameter used in a url
 * @param {string} value the parameter's value
 */
export const stringifyReferrerUrlParam = (value: string) =>
  queryString.stringify({ referrer: value });

/**
 * create profile url using referrer param from url, if it exists,
 * or a fallback path sent as a second argument
 * @param {number} companyId company's id
 * @param {string} pathName a fallback path
 */
export const profileRedirectPath = (companyId: number, pathName: string) => {
  const parsedUrl = queryString.parseUrl(window.location.href);
  const {
    query: { referrer },
  } = parsedUrl;

  return getCompanyProfileURL(
    Number(companyId),
    stringifyReferrerUrlParam((referrer as string) || pathName)
  );
};

/**
 * get page name based on URL
 * to be added new items as need arises
 */
export const getRouteAlias: (companyId?: number | number[]) => RouteAliases | null = companyId => {
  const { pathname, search } = window.location;

  if (pathname.includes('user-lists')) {
    return 'userLists';
  }

  if (pathname.startsWith(appRoutes.lists.myWatchList)) {
    return 'watchList';
  }

  if (pathname.startsWith('/all-reports/sourcing-outreach')) {
    return 'companyOutReach';
  }

  if (pathname.endsWith('/all-reports')) {
    return 'allReports';
  }

  if (pathname.includes('chrome-extension-new')) {
    return 'chromeExtension';
  }

  if (pathname === appRoutes.myCompanies) {
    return 'myCompanies';
  }

  const isAnalyst = AnalystStorage.getIsAnalyst();
  const advancedSearchPathname = isAnalyst ? appRoutes.advancedSearch : appRoutes.home;

  if (pathname === advancedSearchPathname) {
    return 'advancedSearch';
  }

  if (pathname === appRoutes.contacts) {
    return 'contacts';
  }

  if (
    search.includes(`tab=${CompanyProfileTabs.ADDON_MGMT}`) &&
    ((typeof companyId === 'number' && companyId && !pathname.includes(companyId.toString())) ||
      (Array.isArray(companyId) && companyId.length > 0))
  ) {
    return 'companyAddonManagement';
  }

  if (search.includes(`tab=${CompanyProfileTabs.HISTORY}`)) {
    return 'companyHistory';
  }

  if (pathname.includes('profile')) {
    return 'companyProfile';
  }

  if (pathname.includes('ss-lists')) {
    return 'sourceScrubLists';
  }

  if (pathname.includes('checklist-research-insights')) {
    return 'checklistResearchInsights';
  }

  return null;
};

export const getRouteAliasFromGridKey: (gridKey: GridKeys) => RouteAliases = gridKey => {
  switch (gridKey) {
    case 'addons':
      return 'companyAddonManagement';

    case 'outreach':
      return 'companyOutReach';

    case 'sslists':
      return 'sourceScrubLists';

    case 'watchlists':
      return 'userLists';

    case 'lists':
      return 'watchList';

    default:
      return gridKey;
  }
};
