// eslint-disable-next-line import/no-cycle
import { ListTagsProps } from '@optx/models/tags';

export function orderTags(tags: ListTagsProps['tags']) {
  const orderTags = tags?.slice().sort((a, b) => {
    if (a.title.toLowerCase() < b.title.toLowerCase()) {
      return -1;
    }

    if (a.title.toLowerCase() > b.title.toLowerCase()) {
      return 1;
    }

    return 0;
  });

  return orderTags;
}
