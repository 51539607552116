import { useSelector } from 'react-redux';
import { useContext } from 'react';
// models
import { SalesloftContact } from '@optx/models/Salesloft';
import { CompanyProfile } from '@optx/models/Company';
// utils
import { getContactErrors } from '@optx/utils/salesloft';
// services
import NotificationService from '@optx/services/NotificationService';
// redux
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { Context } from '@optx/screens/App/EquityTouchBulk/components/common/Context';

const useValidateIndividualReview = () => {
  const { setErrors } = useContext(Context);

  const companyData = useSelector(
    bulkActionsSelectors.equityTouch.getCompanyData
  ) as CompanyProfile;
  const temporaryData = useSelector(bulkActionsSelectors.equityTouch.getTemporaryData);

  const validate = () => {
    const companyCadence = (temporaryData?.step2?.[companyData.company_id] as any)?.cadence;
    const companySLContacts = (temporaryData?.step2?.[companyData.company_id] as any)
      ?.salesloft_contacts;

    const errors = getContactErrors(
      companyData.contacts,
      ((companySLContacts as SalesloftContact[])?.map(contact => contact?.person_id) as number[]) ||
        []
    );

    if (
      !companyData.is_in_et &&
      !companyCadence &&
      Array.isArray(companySLContacts) &&
      companySLContacts?.length
    ) {
      NotificationService.error(
        'No cadence selected. Please select a cadence or deselect contact(s)'
      );

      return false;
    }

    if (
      !companyData.is_in_et &&
      companyCadence &&
      (!Array.isArray(companySLContacts) || !companySLContacts?.length)
    ) {
      NotificationService.error(
        'No contact was selected for Sales Loft. Please select at least one contact or deselect the cadence.'
      );

      return false;
    }

    if (!companyData.is_in_et && errors.length) {
      setErrors(errors);
      NotificationService.error(
        'The selected contact(s) for Salesloft has some missing information'
      );

      return false;
    }

    return true;
  };

  return { validate };
};

export default useValidateIndividualReview;
