import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import reducer, { saga } from '../../../redux/user/user-list';

const useInjectUserList = () => {
  useInjectReducer({
    key: 'userList',
    reducer,
  });
  useInjectSaga({
    key: 'userList',
    saga,
  });
};

export default useInjectUserList;
