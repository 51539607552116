import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Tooltip } from 'antd';
// redux
import { selectors as fullscreenSelectors } from '@redux/ui/settings/fullscreen';
import { selectors as listsSelectors } from '@optx/common/selectors/lists';
import { selectors as listDetailsSelectors } from '@redux/lists/details';
import { selectors as viewTypeSelectors } from '@features/grid/view-type';
import { selectors as gridViewSelectors, actions as gridViewActions } from '@features/grid/view';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import * as searchesSelectors from 'src/components/pages/UserLists/components/PageLoader/selectors';
// components
import { BulkActionsButton } from '@features/bulk-actions/actions-menu';
import { SourcingOutReachContext } from './SourcingOutReachContext';
import GridView from '@optx/features/grid/view/components/GridView';
import { TrendingControls } from '@optx/components/pages/Home/SearchGridControls';
import SaveSearch from '@optx/components/pages/Home/Header/SaveSearch/SaveSearch';
import GridDropdown from './GridDropdown';
import GridFullScreen from '@optx/shared/view/molecules/FullScreen/GridFullScreen';
import ToggleLongCardViewContainer from './ToggleLongCardViewContainer';
import ToggleTableViewContainer from './ToggleTableViewContainer';
// styles
import Styled from './SearchGridControls.styles';
import CardPannelSorting from './CardPannelSorting';
import SearchCount from '@optx/features/grid/search-count/components/SearchCount';

const SearchGridControls = () => {
  const location = useLocation();
  const isFullscreen = useSelector(fullscreenSelectors.isFullscreen);
  const activeView = useSelector(gridViewSelectors.getView('outreach'));
  let listName = useSelector(listsSelectors.getSelectedListName);
  const viewType = useSelector(viewTypeSelectors.searchView('outreach'));
  const selectedCompanies = useSelector(
    bulkActionsSelectors.selectedCompanies.getSelectedCompanies('companiesOutReach')
  );
  const loading = useSelector(searchesSelectors.listIsLoading);
  const sourceScrubListCompanyName = useSelector(listDetailsSelectors.getListData);

  const applyView = gridViewActions.applyView;

  if (!listName.length && sourceScrubListCompanyName) {
    listName = sourceScrubListCompanyName.title;
  }

  if (isFullscreen && viewType === 'table') {
    return null;
  }

  return (
    <>
      <Styled.SearchGridWrapper isFullScreen={isFullscreen} align="middle">
        <Styled.PageTitle ellipsis>
          <span className="d-flex align-items-center">
            {!isFullscreen && (
              <Tooltip title="Companies" className="mr-3 text-truncate">
                {!loading && 'Companies'}
              </Tooltip>
            )}
            <SearchCount gridName="outreach" searchViewType={viewType} />
          </span>
        </Styled.PageTitle>

        {!isFullscreen && (
          <Styled.ControlsWrapper>
            <SaveSearch showButton={false} viewId={activeView.id} />
            <TrendingControls />
            {viewType === 'table' && (
              <Styled.GridViewWrapper>
                <GridView
                  gridKey="outreach"
                  activeView={activeView}
                  applyView={applyView}
                  customWidth="350px"
                  isFromSourcingOutReach
                />
              </Styled.GridViewWrapper>
            )}
            {viewType === 'long-card' && <CardPannelSorting />}
            <ToggleTableViewContainer />
            <ToggleLongCardViewContainer />
            {viewType === 'table' && <GridFullScreen />}
            <GridDropdown viewType={viewType} />
            {!!selectedCompanies.length && viewType === 'table' && (
              <BulkActionsButton
                context={SourcingOutReachContext}
                selectedCompanies={selectedCompanies}
                referrer={encodeURIComponent(location.pathname)}
              />
            )}
          </Styled.ControlsWrapper>
        )}
      </Styled.SearchGridWrapper>
    </>
  );
};

export default React.memo(SearchGridControls);
