import { Select } from 'antd';
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { SelectProps, SelectValue } from 'antd/lib/select';
// models
import { SelectOption } from '@optx/models/Option';
// redux
import * as sortActions from '@redux/lists/search/sort/actions';
import { selectors as searchSelectors } from '@redux/lists/search/search';
import { selectors as userSelectors } from '@redux/user/information';
import { selectors as viewTypeSelectors } from '@features/grid/view-type';
// components
import {
  mapOptionsToLabeledValues,
  PrefixedSelectionItem,
} from '@optx/components/common/select/Select/PrefixedSelectionItem';

const StyledLabel = styled(PrefixedSelectionItem)`
  .prefixed-selection-item__label {
    color: #1890ff;
  }
`;

const StyledOption = styled(Select.Option)`
  .prefixed-selection-item__label {
    color: rgba(0, 0, 0, 0.65);
  }
`;

const CardViewSelect = ({ className, ...restProps }: SelectProps<SelectValue>) => {
  return <Select dropdownClassName={className} {...restProps} />;
};

const StyledSelect = styled(CardViewSelect)`
  .prefixed-selection-item__label {
    color: rgba(0, 0, 0, 0.65);
  }
`;

const CardPannelSorting = () => {
  const dispatch = useDispatch();
  const sorting = useSelector(searchSelectors.getSorting);
  const defautlOptxScore = useSelector(userSelectors.getDefaultScore);
  const viewType = useSelector(viewTypeSelectors.searchView('lists'));

  const [value, setValue] = useState<string>(sorting[0]?.id);

  const optxScoreLabel = defautlOptxScore === 'il' ? 'Highest IL OPTX Score' : 'Highest OPTX Score';
  const optxScore = defautlOptxScore === 'il' ? 'il_optx_score' : 'score';

  const sortingValues = [optxScore, 'capital_raised', 'num_employees', 'emp_growth_percent'];

  const touchTypes: Array<SelectOption> = [
    {
      label: optxScoreLabel,
      value: optxScore,
    },
    { label: 'Top Raised', value: 'capital_raised' },
    { label: 'Number of Employees', value: 'num_employees' },
    { label: 'Employee Growth % Y/Y', value: 'emp_growth_percent' },
  ];

  const typesOptions = mapOptionsToLabeledValues('Sort by: ', touchTypes, StyledLabel);

  useEffect(() => {
    if (['score', 'il_optx_score'].includes(value)) {
      setValue(sortingValues[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (sorting.length && sorting[0].id !== value) {
      setValue(sorting[0].id);
    }

    if (viewType === 'long-card') {
      window.scrollTo(0, 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  const changeSorting = (value: SelectValue) => {
    dispatch(sortActions.changeSortAction({ sortBy: [{ id: value as string, desc: true }] }));
    setValue(value as string);
  };

  const selectValue = useMemo(
    () => (sortingValues.includes(value) ? value : 'Sort by: Choose Sorting'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  return (
    <StyledSelect
      onChange={changeSorting}
      bordered={false}
      value={selectValue}
      dropdownMatchSelectWidth={false}
    >
      {typesOptions.map(option => (
        <StyledOption key={option.value} value={option.value}>
          {option.label}
        </StyledOption>
      ))}
    </StyledSelect>
  );
};

export default React.memo(CardPannelSorting);
