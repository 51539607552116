import React, { useEffect, useState } from 'react';
import { Tag, Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// constants
import { generateMoreTags, generateTagsListPopover } from '@features/tags/tags/Tags';
import { FILTER_TAGS_MAX_COUNT } from '@constants/tags';
// models
import { GridKeysMain } from '@optx/models/grid';
// utils
import { getRouteAlias } from '@optx/utils/routes';
import { getLocationMainGridKey } from '@optx/utils/grid';
// redux
import { actions as searchActions } from '@features/grid/search';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
// hooks
import { useMaxDisplayedChildren } from '@optx/common/hooks/dom';
// components
import { Styled } from './SingleTag.styled';
import Icon from '@components/common/Icon/Icon';

interface FilterTagsProps {
  filterNames: Array<string>;
  savedSearches?: Array<number | string>;
  onClick?: VoidFunction;
  listType?: string | null;
}

const FilterTags: React.FC<FilterTagsProps> = ({
  filterNames,
  onClick,
  savedSearches,
  listType = null,
}) => {
  const dispatch = useDispatch();
  const location = getRouteAlias();
  const gridKey = getLocationMainGridKey();
  const showSingleTagCloseIcon =
    listType === 'combined' && location !== 'watchList' && filterNames.length > 0;
  // send the saved searches list so that when redux store is updated tags are updated as well
  const { domRef, countHiddenChildren, hiddenChildrenIdList } = useMaxDisplayedChildren({
    updateContentDependency: savedSearches,
    listType,
  });
  const [moreTags, setMoreTags] = useState(generateMoreTags(countHiddenChildren, listType));
  const [remainingTags, setRemainingTags] = useState(
    generateTagsListPopover(filterNames, hiddenChildrenIdList, listType)
  );

  useEffect(() => {
    setMoreTags(generateMoreTags(countHiddenChildren, listType));
    setRemainingTags(generateTagsListPopover(filterNames, hiddenChildrenIdList, listType));
  }, [filterNames, savedSearches, hiddenChildrenIdList, countHiddenChildren, listType]);

  const customClearedFilter = useSelector(
    filterSelectors.getCustomClearedFilter(gridKey as GridKeysMain)
  );

  const handleClose = () => {
    dispatch(
      searchActions.clearSearch({
        gridKey: gridKey as GridKeysMain,
        data: customClearedFilter,
      })
    );
  };

  return (
    <div
      className="d-flex"
      role="presentation"
      onClick={onClick}
      style={{ width: '100%', display: 'flex' }}
    >
      {filterNames && (
        <>
          <div
            ref={domRef}
            className="tags-wrapper"
            style={{
              margin: 0,
              flex: 1,
              display: location === 'watchList' ? 'inline-block' : 'flex',
            }}
          >
            {listType === 'combined' && location === 'myCompanies' && (
              <Styled.ETTagMyCompanies>
                EQUITYTOUCH: <span>In</span>
              </Styled.ETTagMyCompanies>
            )}
            {listType === 'combined' ? (
              <>
                {location !== 'watchList' ? (
                  <>
                    {filterNames.map((nameOrOperator, index) => {
                      // index of search name is always an even number
                      // index of operator is always an odd number
                      const isEven = index % 2 === 0;
                      const style: React.CSSProperties = isEven ? {} : { color: '#4180d6' };

                      return (
                        <Styled.SingleTag
                          index={index}
                          lastIndex={filterNames.length - countHiddenChildren - 1}
                          key={index}
                          id={nameOrOperator}
                        >
                          <span style={style}>{nameOrOperator}</span>
                        </Styled.SingleTag>
                      );
                    })}
                  </>
                ) : (
                  filterNames.map((nameOrOperator, index) => {
                    const isEven = index % 2 === 0;
                    const style: React.CSSProperties = isEven
                      ? { textTransform: 'uppercase' }
                      : { color: '#4180d6' };

                    return (
                      <Tag key={index} id={nameOrOperator} className="saved__filter">
                        <span style={style}>{nameOrOperator}</span>
                      </Tag>
                    );
                  })
                )}
              </>
            ) : (
              <>
                {filterNames.map((filter, index) => {
                  const [name, valuesString] = filter.split(':');
                  const values = valuesString.split(',');
                  let finalValuesString = values.slice(0, FILTER_TAGS_MAX_COUNT).join(', ');

                  if (values.length > FILTER_TAGS_MAX_COUNT) {
                    finalValuesString += `, ${values.length - FILTER_TAGS_MAX_COUNT} more`;
                  }

                  return (
                    <Tag
                      key={index}
                      id={filter.substr(0, filter.indexOf(':'))}
                      className="saved__filter"
                    >
                      <span className="saved__filter-name">{`${name}:`}</span>
                      <span className="saved__filter-value">{finalValuesString}</span>
                    </Tag>
                  );
                })}
              </>
            )}
          </div>
          {showSingleTagCloseIcon && (
            <Styled.CloseTagIcon>
              <Icon iconName="close" style={{ fontSize: 12 }} onClick={handleClose} />
            </Styled.CloseTagIcon>
          )}
          {moreTags && (
            <Popover overlayClassName="ant-popover-tags" content={remainingTags}>
              {moreTags}
            </Popover>
          )}
        </>
      )}
    </div>
  );
};

FilterTags.defaultProps = {
  filterNames: [],
};

export default FilterTags;
