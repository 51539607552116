import { combineReducers } from 'redux';
// models
import { CompanyTouchesState } from './interfaces';
// reducers
import filtersReducer from './filters';
import searchReducer from './search';

const companyTouchesReducer = combineReducers<CompanyTouchesState>({
  filters: filtersReducer,
  search: searchReducer,
});

export default companyTouchesReducer;
