import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// redux
import { actions, selectors } from '@optx/features/sourcing-outreach-summary/state';
import { selectors as fullScreenSelectors } from '@redux/ui/settings/fullscreen';
// components
import { MainHeader } from '@optx/core/layout';
import ClearSearchButton from '@shared/view/molecules/Buttons/ClearSearchButton';
import FilterTags from './FilterTags';
import AnalystsSearch from './AnalystsSearch';
import GridFullScreen from '@optx/shared/view/molecules/FullScreen/GridFullScreen';
import SourcingOutreachSearchDropdown from '../SourcingOutreachSearchDropdown';
import { Styled } from './SearchHeader.styled';
import ShowFilterButton from '@optx/features/grid/filter/components/ShowFilterButton';

const SearchHeader = () => {
  const dispatch = useDispatch();
  const isClearedFilter = useSelector(selectors.filters.isCLearedFilter);
  const fullscreen = useSelector(fullScreenSelectors.isFullscreen);

  const options = useSelector(selectors.filters.getTags);

  const onClear = () => {
    dispatch(actions.filters.clearFilters());
  };

  const filteredOptions = useMemo(() => options.filter(item => !item.isDefault), [options]);

  return (
    <MainHeader className="main-header">
      <div className="search-header-content analysts-header-content">
        <AnalystsSearch className="antd-search-input" autoComplete="off" />
        <Styled.Spacer size={4}>
          <FilterTags options={filteredOptions} />
          <ClearSearchButton isVisible={isClearedFilter} title="Clear All" onClick={onClear} />
          <ShowFilterButton gridKey={'outreach'} placement={'bottom'} />
          <SourcingOutreachSearchDropdown />
          {fullscreen && <GridFullScreen />}
        </Styled.Spacer>
      </div>
    </MainHeader>
  );
};

export default SearchHeader;
