import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import savedSearchesReducer, {
  sagas as savedSearchesSagas,
} from '../../../redux/contacts/saved-searches';

const useInjectContactsSavedSearches = () => {
  useInjectReducer({
    key: 'contactsSavedSearches',
    reducer: savedSearchesReducer,
  });
  useInjectSaga({
    key: 'contactsSavedSearches',
    saga: savedSearchesSagas,
  });
};

export default useInjectContactsSavedSearches;
