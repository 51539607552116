import { FilterTag } from '@optx/models/tags';

export const mapTagsToNames = (filteredTags: Array<FilterTag>) => {
  return filteredTags
    ?.map((filter: FilterTag) => {
      if (!Array.isArray(filter.values)) return null;

      if (filter.isDefault === true) {
        return null;
      }

      const values = filter.values.map(child => child.value);

      if (!values.length) {
        return null;
      }

      return `${filter.label}: ${values.join(', ')}`;
    })
    .filter(item => item !== null) as string[];
};
