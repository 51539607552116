import queryString from 'query-string';
import { AsyncMultiSelectPayload } from '@optx/redux/async-multiselect/interfaces';
import ApiClient, { API_URL } from './axios-client';

class AsyncMultiSelect {
  static client: ApiClient = new ApiClient(`${API_URL}`);

  static getOptions(payload: AsyncMultiSelectPayload) {
    const queryPayload = {
      query: payload.query,
      ...(payload.additionalOptions && payload.additionalOptions),
    };
    const query = queryString.stringify(queryPayload, { arrayFormat: 'comma' });

    return AsyncMultiSelect.client.get(`${payload.endpoint}?${query}`);
  }
}

export default AsyncMultiSelect;
