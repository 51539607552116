import { Dictionary } from 'lodash';
import { PreselectedFilter } from '@models/filters';

export const myCompaniesPreselectedFilter: Dictionary<PreselectedFilter> = {
  source_tag_filter: [
    {
      label: 'Source Scrub',
      value: 'ss',
      in: false,
      notIn: false,
    },
    {
      label: 'EquityTouch',
      value: 'et',
      in: true,
      notIn: false,
    },
    {
      label: 'Crunch Base',
      value: 'cb',
      in: false,
      notIn: false,
    },
    {
      label: 'Glassdoor',
      value: 'gd',
      in: false,
      notIn: false,
    },
    {
      label: 'G2Crowd',
      value: 'g2',
      in: false,
      notIn: false,
    },
    {
      label: 'Capterra',
      value: 'ct',
      in: false,
      notIn: false,
    },
    {
      label: 'Orbis',
      value: 'bvd',
      in: false,
      notIn: false,
    },
    {
      label: 'Optx',
      value: 'optx',
      in: false,
      notIn: false,
    },
    {
      label: 'IVC',
      value: 'ivc',
      in: false,
      notIn: false,
    },
  ],
};
