import React, { useState } from 'react';
import { Timeline } from 'antd';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
// models
import Company from '@optx/models/Company';
// redux
import { selectors as userSelectors } from '@redux/user/information';
import { selectors } from '../state';

// components
import DangerousInnerHTML from '@optx/components/common/DangerousInnerHTML';
import TimelineIntervalLabel from './TimelineIntervalLabel';
import PageLoader from './PageLoader';
import TimelineTouchItem from './TimelineTouchItem';
// styles
import './style.scss';

interface TouchesTimeline {
  company: Company;
  isExtension?: boolean;
}

const TouchesTimeline: React.FC<TouchesTimeline> = ({ company, isExtension }) => {
  const [activeKey, setActiveKey] = useState<string | string[]>('');

  const touches = useSelector(selectors.search.data);
  const loading = useSelector(selectors.search.isLoading);
  const touchesCount = useSelector(selectors.search.count);
  const hasTouchAccess = useSelector(userSelectors.getHasAccessToTouches);

  if (!touches) {
    return <DangerousInnerHTML type="p" innerHTML="" />;
  }

  const {
    touch_history: {
      last_six_weeks: lastSixWeeks,
      over_six_months: overSixMonths,
      over_six_weeks: overSixWeeks,
      scheduled,
    },
  } = touches;

  // ISSUE: seems there is a bug and Timeline.Item has to be direct children of Timeline otherwise the label isn't
  // displayed. List can't be mapped inside Fragment or any other element :(.
  return (
    <>
      <PageLoader loading={loading} />
      <Timeline mode="left" className="ant-timeline-label">
        {scheduled.map((touch, index) => {
          const futureTouchClassName = classnames(
            'timeline-item--touch',
            'timeline-item--touch-future',
            {
              bordered: index === scheduled.length - 1,
            }
          );

          return (
            <TimelineTouchItem
              key={index}
              touch={touch}
              touchesCount={scheduled.length}
              index={index}
              setActiveKey={setActiveKey}
              activeKey={activeKey}
              timelineInterval="SCHEDULED TOUCHES"
              company={company}
              colorInterval="#87919f"
              hasTouchAccess={hasTouchAccess}
              className={futureTouchClassName}
              renderCount={() => `${scheduled.length} scheduled`}
            />
          );
        })}
        {!scheduled.length && (
          <Timeline.Item
            className="timeline-item--touch  timeline-item--touch-future timeline-item--touch-no-future bordered"
            label={<TimelineIntervalLabel title="SCHEDULED TOUCHES" renderCount={() => ''} />}
          >
            No Future Touch Scheduled
          </Timeline.Item>
        )}
        {lastSixWeeks.map((touch, index) => (
          <TimelineTouchItem
            key={index}
            touch={touch}
            touchesCount={touchesCount.last_six_weeks}
            index={index}
            setActiveKey={setActiveKey}
            activeKey={activeKey}
            timelineInterval="LAST 6 WEEKS"
            company={company}
            colorInterval="#13C2C2"
            hasTouchAccess={hasTouchAccess}
            isExtension={isExtension}
            className="ant-timeline--past"
          />
        ))}
        {overSixWeeks.map((touch, index) => (
          <TimelineTouchItem
            key={index}
            touch={touch}
            touchesCount={touchesCount.over_six_weeks}
            index={index}
            setActiveKey={setActiveKey}
            activeKey={activeKey}
            timelineInterval="MORE THAN 6 WEEKS"
            company={company}
            colorInterval="#FA8C16"
            hasTouchAccess={hasTouchAccess}
            isExtension={isExtension}
            className="ant-timeline--past"
          />
        ))}
        {overSixMonths.map((touch, index) => (
          <TimelineTouchItem
            key={index}
            touch={touch}
            touchesCount={touchesCount.over_six_months}
            index={index}
            setActiveKey={setActiveKey}
            activeKey={activeKey}
            timelineInterval="MORE THAN 6 MONTHS"
            company={company}
            colorInterval="#FA541C"
            hasTouchAccess={hasTouchAccess}
            isExtension={isExtension}
            className="ant-timeline--past"
          />
        ))}
      </Timeline>
    </>
  );
};

export default TouchesTimeline;
