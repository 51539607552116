import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
// constants
import { cardRowGutter } from '@constants/spacing';
// components
import Card from '@components/common/cards';
// local
// hooks
import { useInject } from '@components/pages/AnalystsLeaderboard/hooks';
// redux
import { actions as initActions } from '@components/pages/AnalystsLeaderboard/state/init/index';
// styles
import {
  PageTitle,
  ByAnalystsOrTeamMultiSelect,
} from '@components/pages/AnalystsLeaderboard/components/styled';
// components
import ReportBreadcrumb from '@optx/screens/App/AllReports/components/ReportBreadcrumb';
import LeaderboardChart from './LeaderboardChart';
import FilterCards from './Cards/FilterCards';
import ChartCardHead from './ChartCardHead';
import FilterSingleSelectDateRanges from './FilterSingleSelectDateRanges';
import Header from './Header';
import PageLoader from './PageLoader';
import AnalystsTouches from './AnalystsTouches';
import EmailStatistics from './EmailStatistics';
import SmartTouchScore from './SmartTouchScore';
import StatsSection from './StatsSection';
import AdditionalStatistics from './AdditionalStatistics';
import { PipelineInformation } from './PipelineInformation';
import Styled from './AnalystsLeaderboard.styled';

const AnalystsLeaderboard = () => {
  useInject();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initActions.pageLoaded());
  }, [dispatch]);

  return (
    <>
      <PageLoader />
      <Header />

      <Styled.Wrapper>
        <Styled.MainContent className="main-content">
          <ReportBreadcrumb reportPage="Sourcing Team Stats" />
          <PageTitle>Sourcing Team Stats</PageTitle>
          <Row gutter={cardRowGutter} justify="space-between" style={{ marginBottom: 0 }}>
            <Col xs={24} xl={12}>
              <ByAnalystsOrTeamMultiSelect filterKey="analyst_id" allowSelectAll />
            </Col>
            <Col>
              <FilterSingleSelectDateRanges filterKey="startdatetime" />
            </Col>
          </Row>
          <Row gutter={cardRowGutter} style={{ paddingTop: '21px', paddingBottom: '42px' }}>
            <Col xs={24} xl={24}>
              <FilterCards />
            </Col>
          </Row>
          <Row gutter={cardRowGutter} style={{ paddingBottom: '42px' }}>
            <Col span={24}>
              <Card $transparent>
                <ChartCardHead />
                <LeaderboardChart />
              </Card>
            </Col>
            <Col span={24}>
              <PipelineInformation />
            </Col>
            <Col span={24}>
              <AnalystsTouches />
            </Col>
          </Row>
        </Styled.MainContent>
        <Styled.StatsWrapper>
          <StatsSection title="My Stats Breakdown">
            <SmartTouchScore />
            <EmailStatistics />
          </StatsSection>
          <StatsSection title="OPTX Stats">
            <AdditionalStatistics />
          </StatsSection>
        </Styled.StatsWrapper>
      </Styled.Wrapper>
    </>
  );
};

export default AnalystsLeaderboard;
