import { createFeatureActionType } from '@optx/utils/feature';
import featureName from '../featureName';

export const bulkEditField = createFeatureActionType(featureName, 'BULK_EDIT_FIELD');
export const bulkEditFieldSuccess = createFeatureActionType(featureName, 'BULK_EDIT_FIELD_SUCCESS');

export const updateProgress = createFeatureActionType(featureName, 'UPDATE_PROGRESS');

export const updateCompleted = createFeatureActionType(featureName, 'UPDATE_COMPLETED');

export const updatePercentage = createFeatureActionType(featureName, 'UPDATE_PERCENTAGE');

export const saveResults = createFeatureActionType(featureName, 'SAVE_RESULTS');

export const resetProgress = createFeatureActionType(featureName, 'RESET_PROGRESS');

export const cancel = createFeatureActionType(featureName, 'CANCEL');
