import { call, put, takeLatest, select, takeEvery, take } from 'redux-saga/effects';
import queryString from 'query-string';
import { AxiosResponse } from 'axios';

import { getErrorMessage } from '@utils/api/errors';
import { CompanyService } from '@services/api';
import NotificationService from '@services/NotificationService';
import mapFiltersToURLParams from '@optx/utils/filters/mapFiltersToURLParams';
import { mapSortQuery } from '@optx/utils/search';
import { SelectOption } from '@optx/models/Option';
import { PageInfo } from '@optx/models/table/Pagination';
import {
  selectors as userInformationSelectors,
  actions as userInformationActions,
} from '@redux/user/information';
import { actions } from '../actions';
import { selectors } from '../selectors';

export function* search() {
  // @ts-ignore
  const { normalized, filter } = yield select(selectors.filters.getFilters);
  // @ts-ignore
  const pagination = yield select(selectors.pagination.pagination);
  // @ts-ignore
  const sortBy: SortByRule<any>[] = yield select(selectors.sort.sortBy);

  let normalizedData;
  let filterData;

  const isAllOrEmptyAnalysts =
    normalized.analyst_id?.data?.length === (filter?.analyst_id as Array<SelectOption>)?.length ||
    (filter?.analyst_id as Array<SelectOption>)?.length === 0;

  if (isAllOrEmptyAnalysts) {
    normalizedData = { ...normalized };
    filterData = {
      ...filter,
      ...(filter.analyst_id && {
        analyst_id: filter.analyst_id.length === 0 ? [''] : filter.analyst_id,
      }),
    };
  } else {
    normalizedData = { ...normalized };
    filterData = { ...filter };
  }

  const convertSortBy = mapSortQuery(sortBy);
  const convertPagination = {
    per_page: pagination.pageSize,
    page: pagination.pageNumber,
  };

  const queryData = {
    ...convertPagination,
    ...convertSortBy,
    ...mapFiltersToURLParams(normalizedData, filterData),
  };

  const query = queryString.stringify(queryData, {
    arrayFormat: 'comma',
  });

  try {
    const res: AxiosResponse = yield call(CompanyService.getCompaniesInET, query);
    yield put(actions.search.fetchETCompaniesSuccess(res.data));
    yield put(actions.charts.fetchChartsSuccess(res.data.series));
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to fetch Companies in EquityTouch!');

    yield put(actions.search.fetchETCompaniesFail(errorMessage));
    NotificationService.error(errorMessage);

    // @ts-ignore
    const searchIsInitialized = yield select(selectors.search.isInitialized);

    // Only undo after first search, don't undo to initial state otherwise will also  reset filters data.
    if (searchIsInitialized) {
      // UNDO feature state
      yield put(actions.undo());
    }
  }
}

function* fetchETCompaniesSaga() {
  yield put(actions.search.fetchETCompanies());
  yield take(actions.charts.fetchChartsSuccess);
  yield put(actions.highlights.etMergeHistoryFetch());
  yield put(actions.highlights.etAlltimeHighlightsFetch());
  yield put(actions.highlights.timeSpentFetch());

  // @ts-ignore
  const { normalized, filter } = yield select(selectors.filters.getFilters);
  // @ts-ignore
  const pagination: PageInfo = yield select(selectors.pagination.pagination);
  const userPageNumber: number = yield select(
    userInformationSelectors.getETCompaniesListPageNumber
  );

  const normalizedFilter = {
    ...filter,
    ...(filter.analyst_id && {
      analyst_id: filter.analyst_id.length === 0 ? [''] : filter.analyst_id,
    }),
  };

  const query = queryString.stringify(mapFiltersToURLParams(normalized, normalizedFilter), {
    arrayFormat: 'comma',
  });

  if (pagination.pageNumber !== userPageNumber) {
    yield put(
      userInformationActions.updateUserSettings({
        new_et_companies_filters: query,
        new_et_companies_page_number: pagination.pageNumber,
      })
    );
  } else {
    yield put(userInformationActions.updateUserSettings({ new_et_companies_filters: query }));
  }
}

function* changePaginationSaga() {
  yield put(actions.search.fetchETCompanies());

  // @ts-ignore
  const pagination: PageInfo = yield select(selectors.pagination.pagination);
  const userPageNumber: number = yield select(
    userInformationSelectors.getETCompaniesListPageNumber
  );

  if (pagination.pageNumber !== userPageNumber) {
    yield put(
      userInformationActions.updateUserSettings({
        new_et_companies_page_number: pagination.pageNumber,
      })
    );
  }
}

function* changeSortingSaga() {
  yield put(actions.search.fetchETCompanies());

  // @ts-ignore
  const pagination: PageInfo = yield select(selectors.pagination.pagination);
  const userPageNumber: number = yield select(
    userInformationSelectors.getETCompaniesListPageNumber
  );
  // @ts-ignore
  const sortBy: SortByRule<any>[] = yield select(selectors.sort.sortBy);

  const sortQuery = queryString.stringify(mapSortQuery(sortBy), {
    arrayFormat: 'comma',
  });

  if (pagination.pageNumber !== userPageNumber) {
    yield put(
      userInformationActions.updateUserSettings({
        new_et_companies_page_number: pagination.pageNumber,
        new_et_companies_sorting: sortQuery,
      })
    );
  } else {
    yield put(
      userInformationActions.updateUserSettings({
        new_et_companies_sorting: sortQuery,
      })
    );
  }
}

export default function* companiesInETSaga() {
  yield takeEvery(actions.search.fetchETCompanies, search);

  yield takeLatest(actions.filters.clearFilters, fetchETCompaniesSaga);
  yield takeLatest(actions.filters.resetToDefault, fetchETCompaniesSaga);
  yield takeLatest(actions.filters.applyFilters, fetchETCompaniesSaga);
  yield takeLatest(actions.filters.updateValue, fetchETCompaniesSaga);
  yield takeLatest(actions.filters.resetFilter, fetchETCompaniesSaga);

  yield takeLatest(actions.pagination.changePage, changePaginationSaga);
  yield takeLatest(actions.sort.sort, changeSortingSaga);
}
