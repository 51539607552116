import React, { useState, useContext, Context, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Space, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ValueType } from 'react-select';
import styled from 'styled-components';
// models
import { SelectOption } from '@optx/models/Option';
import { BulkEditFieldContext } from '@optx/models/bulkActions';
// constants
import { FAVORITE_LIST_SELECT_OPTIONS } from '@optx/constants/lists';
// redux
import {
  selectors as watchListsPopupSelectors,
  actions as watchListsActions,
} from '@redux/favorite-lists/lists-popup';
// components
import { MultiSelectCreatable, SingleSelect } from '@optx/shared/view/molecules/Select';
// styles
import { Styled } from './EditWatchlist.styled';

interface EditWatchListProps {
  context: Context<BulkEditFieldContext>;
}

const { Text } = Typography;

const ErrorMessage = styled(Text)`
  position: absolute;
  bottom: -35px;
  left: 0;
  font-size: 14px;
`;

export const EditWatchList: React.FC<EditWatchListProps> = ({ context }) => {
  const { setSelectedData } = useContext(context);
  const [title, setTitle] = useState('');
  const [isCreateList, setIsCreateList] = useState(false);
  const [watchListError, setWatchListError] = useState({ title: 'duplicating', status: false });
  const [selectedValue, setSelectedValue] = useState<SelectOption[]>([]);
  const [selectBoxOptions, setSelectBoxOptions] = useState<SelectOption[]>([]);
  const [newLists, setNewLists] = useState<SelectOption[]>([]);

  const dispatch = useDispatch();
  const sortActive = useSelector(watchListsPopupSelectors.getSortSelect);
  const defaultValue = [
    {
      value: sortActive.value,
      label: sortActive.label,
    },
  ];
  useEffect(() => {
    setSelectBoxOptions([...newLists, ...selectOptions]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortActive]);

  const getWatchlistsById = useSelector(watchListsPopupSelectors.getWatchlistsById());

  const selectOptions = getWatchlistsById.map(list => ({
    value: list.unique_id as string,
    label: `${list.title} (@${list.owner_name}) - (${list.count})`,
    isET: list.is_et_group,
    isPublic: list.is_public,
  }));

  const closeCreateList = () => {
    setTitle('');
    setIsCreateList(false);
    setWatchListError(prev => {
      return { ...prev, status: false };
    });
  };

  const handleCreateOption = (option: string, fromInput?: string) => {
    const newOption = {
      value: option,
      label: option,
      isET: false,
      isPublic: false,
      className: 'primary',
    };

    const options = selectBoxOptions;
    setNewLists(prevState => [newOption as SelectOption, ...prevState]);
    setSelectBoxOptions([newOption, ...options]);

    // @ts-ignore
    setSelectedValue(prevState => [...prevState, newOption as SelectOption]);
    setSelectedData([newOption as SelectOption].concat(selectedValue));
    setWatchListError(prev => {
      return { ...prev, status: false };
    });
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWatchListError(prev => {
      return { ...prev, status: false };
    });
    setTitle(event.target.value);
  };

  const changeButtonAction = () => {
    if (!isCreateList) {
      setTitle('');
      setIsCreateList(true);
    } else if (title.length) {
      const listTitleIndex = getWatchlistsById.findIndex(x => x.title === title.trim());
      const selectedValueTitleIndex = selectedValue.findIndex(x => x.label === title.trim());
      const inCurrentList = selectBoxOptions.findIndex(x => x.label === title.trim());

      if (listTitleIndex !== -1 || selectedValueTitleIndex !== -1 || inCurrentList !== -1) {
        setWatchListError({ title: 'duplicating', status: true });
      } else if (!title.trim().length) {
        setWatchListError({ title: 'without chars', status: true });
      } else {
        handleCreateOption(title.trim(), 'but');
        setIsCreateList(false);
      }
    }
  };

  const handleSorting = useCallback(
    (selectedValue: ValueType<SelectOption>) => {
      dispatch(
        watchListsActions.sortFavoriteListsPopup({
          sorting: (selectedValue as SelectOption).value,
        })
      );
    },
    [dispatch]
  );

  return (
    <>
      <Typography.Text className="ant-modal-title">Select Watchlist(s)</Typography.Text>

      <MultiSelectCreatable
        options={selectBoxOptions.length ? selectBoxOptions : selectOptions}
        onChange={option => {
          // @ts-ignore
          setSelectedValue(option as SelectOption);
          setSelectedData([option as SelectOption].flat());
        }}
        onCreateOption={handleCreateOption}
        value={selectedValue}
        menuIsOpen
        className="bulk-action-multiselect"
      />

      <Styled.Divider />

      <Space style={{ marginBottom: 20, display: 'block' }}>
        <SingleSelect
          className="watch-list__sorting bulk-action-watch-list__sorting"
          dropdownClassName="watch-list__sorting-dropdown"
          bordered={false}
          valuePrefix="Sort list menu by:"
          value={defaultValue}
          SearchIcon={false}
          options={FAVORITE_LIST_SELECT_OPTIONS}
          onChange={handleSorting}
        />
      </Space>

      <Styled.CreateListWrapper>
        {isCreateList && (
          <>
            <Input
              type="text"
              name="title"
              value={title}
              className={watchListError.status ? 'error' : ''}
              placeholder="Watchlist Name"
              onChange={handleTitleChange}
            />
            {watchListError.status && (
              <ErrorMessage type="danger">
                {watchListError.title === 'duplicating'
                  ? 'List already exists'
                  : 'List title should have at least 1 char'}
              </ErrorMessage>
            )}
          </>
        )}

        <Button
          type={isCreateList ? 'primary' : 'default'}
          disabled={isCreateList && title.length === 0}
          onClick={changeButtonAction}
          className={isCreateList ? 'btn-create-list-opened' : 'btn-create-list-closed'}
        >
          {`+ ${isCreateList ? 'Create List' : 'Add New List'}`}
        </Button>

        {isCreateList && <CloseOutlined onClick={closeCreateList} />}
      </Styled.CreateListWrapper>
    </>
  );
};
