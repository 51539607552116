import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
// styles
import '@optx/components/pages/Home/styles.scss';
// models
import { SelectOption } from '@optx/models/Option';
// constants
import { HTML } from '@constants/fullScreen';
// redux
import { selectors as listsSearchSelectors } from '@redux/lists/search/search';
import { actions as searchActions, selectors as searchSelectors } from '@features/grid/search';
import {
  selectors as fullscreenSelectors,
  actions as fullScreenActions,
} from '@redux/ui/settings/fullscreen';
import { selectors as userInformationSelectors } from '@redux/user/information';
import * as pageSelectors from '@redux/pages/userLists/selectors';
import {
  selectors as listDetailsSelectors,
  actions as listDetailsActions,
} from '@redux/lists/details';
import {
  actions as sourceScrubListsActions,
  selectors as sourceScrubListsSelectors,
} from '@redux/user/source-scrub-lists';
// hooks
import { useInject as useInjectCompanyGraphs } from '@features/company/graphs';
import {
  useInjectCompanySavedSearches,
  useInjectCompanyLists,
  useInjectUI,
  useInjectEquityTouch,
  useInjectFavoriteLists,
  useInjectUserList,
  useInjectShareList,
  useInjectSourceScrubLists,
  useInjectContactsSearch,
  useInjectCompanyFundingRounds,
  useInjectCompanyInBusinessEvaluation,
  useInjectCompanyActive,
  useInjectScore,
} from '@hooks/inject';
import {
  useInject as useInjectCompanyIndividualEdit,
  useInitialFetchEditOptions,
} from '@components/feature/company-individual-edit';
import { useReduxFullScreen } from '@hooks/fullscreen';
import {
  useInitFavorites,
  useFetchSavedSearches,
  useInitFilters,
  useFetchUserList,
} from '@hooks/init';
import {
  useInject as useInjectScroll,
  useVirtualTableContainerSize,
} from '@features/scroll-history';
import { useInject as useInjectTouches } from '@features/company-touches';
import { useInject as useInjectCompanyCard } from '@features/long-card/company-card';
import { useShouldFetch } from '@hooks/fetch';
import { useInjectCompanyReview } from '@optx/features/company-review';
import { useInject as useInjectAnalystHome } from '@optx/features/analyst-home';
// components
import CompanyNotes from '@components/CompanyNotes';
import CompanyTouches from '@features/add-touch/components';
import { MainHeader, MainContent } from '@components/common/layout';
import ErrorComponent from '@components/common/ErrorComponent';
import { StickyHeader } from '@components/common/StickyHeader.styled';
import { useInjectEditFields } from '@optx/features/company/edit-fields';
import { FilterHeader } from './components/Header';
import PageLoader from './components/PageLoader';
import FiltersModal from './components/FiltersModal';
import SecondaryQuickFiltersContainer from './components/SecondaryQuickFiltersContainer';
import CompanySearchDisplay from './CompanySearchDisplay';
import SearchGridControls from './components/SearchGrid/SearchGridControls/SearchGridControls';
import QuickFilters from './components/QuickFilters';
import { UserListContext } from './components/SearchGrid/ListsContext';
import EditAllDialog from '@optx/features/edit-all-info/components/EditAllDialog';
import DocumentUploadModal from '@optx/features/add-touch/components/DocumentUploadModal';

const useInjectPage = () => {
  // initialize states and sagas
  useInjectUI();
  useInjectEditFields();
  useInjectCompanySavedSearches();
  useInjectCompanyLists();
  useInitFavorites();
  useInjectEquityTouch();
  useInjectFavoriteLists();
  useInjectUserList();
  useInjectShareList();
  useInjectSourceScrubLists();
  useInjectContactsSearch();
  useInjectCompanyIndividualEdit();
  useInjectCompanyFundingRounds();
  useInjectScroll();
  useInjectTouches();
  useInjectCompanyCard();
  useInjectCompanyGraphs();
  useInjectCompanyInBusinessEvaluation();
  useInjectCompanyActive();
  useInjectScore();
  useInjectCompanyReview();
  useInjectAnalystHome();
};

const useInitHome = () => {
  useInjectPage();

  useFetchUserList();
  useInitFilters();
  useInitialFetchEditOptions();
  useFetchSavedSearches();
};

const MyListsPage: React.FC = () => {
  useInitHome();
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = useState<SelectOption[] | string | boolean>([]);
  const [rationaleValue, setRationaleValue] = useState<string>();
  const [hasSelectViewChanged, setHasSelectViewChanged] = useState<boolean>(false);

  const shouldFetch = useSelector(pageSelectors.shouldInitialSearch);
  const shouldInitialFetchSearches = useSelector(sourceScrubListsSelectors.shouldFetch);
  const listId = useSelector(listDetailsSelectors.getRouteListId);
  const isSourceScrubList = useSelector(listDetailsSelectors.isSourceScrubRoute);
  const listType = useSelector(searchSelectors.getListType('lists'));

  const fetchSourceScrubLists = useCallback(
    () => dispatch(sourceScrubListsActions.searchSourceScrubLists()),
    [dispatch]
  );

  useShouldFetch(shouldInitialFetchSearches, fetchSourceScrubLists);

  // full screen
  const fullscreen = useSelector(fullscreenSelectors.isFullscreen);
  const showUserListQuickFilters = useSelector(userInformationSelectors.showUserListQuickFilters);
  const error = useSelector(searchSelectors.getError('lists'));
  const histogramsAreEmpty = useSelector(listsSearchSelectors.histogramsAreEmpty);

  const loadList = useCallback(
    (id, isSourceScrub) =>
      dispatch(listDetailsActions.loadCompanyListDetails({ id, isSourceScrub })),
    [dispatch]
  );
  const searchCompanies = useCallback(
    () => dispatch(searchActions.initialCompaniesSearch({ gridKey: 'lists', data: undefined })),
    [dispatch]
  );

  useEffect(() => {
    if (shouldFetch) {
      loadList(listId, isSourceScrubList);
      searchCompanies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);

  const toggle = useCallback(() => dispatch(fullScreenActions.toggleFullScreen()), [dispatch]);

  const { isFullscreen } = useReduxFullScreen({ dom: HTML, fullscreen, toggle });

  const contentClassNames = classnames('main-content grid-page', {
    fullscreen: isFullscreen,
    'show-quick-filters': showUserListQuickFilters,
    'show-quick-filters--small': showUserListQuickFilters && histogramsAreEmpty,
  });

  const virtualTableScrollYContext = useVirtualTableContainerSize();
  const containerRef = virtualTableScrollYContext.containerRef as React.RefObject<HTMLDivElement>;

  return (
    <UserListContext.Provider
      value={{
        selectedData,
        setSelectedData,
        gridName: 'watchlists',
        hasSelectViewChanged,
        setHasSelectViewChanged,
        rationaleValue,
        setRationaleValue,
      }}
    >
      {error ? (
        <ErrorComponent />
      ) : (
        <>
          <MainHeader className="main-header fixed">
            <FilterHeader />
          </MainHeader>
          <StickyHeader offsetTop="64px">
            <SecondaryQuickFiltersContainer />
            {listType !== 'combined' && <QuickFilters />}
            <SearchGridControls />
          </StickyHeader>
          <MainContent
            className={contentClassNames}
            style={{ marginTop: isFullscreen ? '5rem' : '0' }}
          >
            <div ref={containerRef} className="position-relative">
              <PageLoader />
              <CompanySearchDisplay />
              <CompanyNotes />
              <CompanyTouches />
              <EditAllDialog />
              <FiltersModal />
            </div>
          </MainContent>
        </>
      )}
      <DocumentUploadModal />
    </UserListContext.Provider>
  );
};

export default MyListsPage;
