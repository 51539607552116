import { combineReducers } from 'redux';
// models
import { SearchContactsState } from './interfaces';
// reducers
import searchReducer from './search';
import sortReducer from './sort';
import filterReducer from './filter';

const reducer = combineReducers<SearchContactsState>({
  search: searchReducer,
  sort: sortReducer,
  filter: filterReducer,
});

export default reducer;
