import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { Menu } from 'antd';
// redux
import { selectors as viewTypeSelectors } from '@features/grid/view-type';
import { actions as sortActions, selectors } from '@redux/my-companies/sort';

const ToggleSortType = ({ onClick, ...restProps }: MenuItemProps) => {
  const dispatch = useDispatch();
  const isMultiSort = useSelector(selectors.isMultiSort);
  const sorting = useSelector(selectors.getSorting);
  const searchView = useSelector(viewTypeSelectors.searchView('myCompanies'));
  const multiSort = useCallback(() => {
    if (!isMultiSort) {
      const tableElement = document.querySelector('.ant-table-body');
      tableElement?.scrollTo(0, 0);
    }

    return dispatch(sortActions.multiSortAction(!isMultiSort, sorting));
  }, [dispatch, isMultiSort, sorting]);

  if (searchView === 'long-card') {
    return null;
  }

  return (
    <Menu.Item onClick={multiSort} {...restProps}>
      {isMultiSort ? 'Enable Single Sort' : 'Enable Multi Sort'}
    </Menu.Item>
  );
};

export default ToggleSortType;
