import { fork } from 'redux-saga/effects';
import { saga as searchSaga } from './search';
import { saga as listsDetailsSaga } from './details';

function* listsSagas() {
  yield fork(listsDetailsSaga);
  yield fork(searchSaga);
}

export default listsSagas;
