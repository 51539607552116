import React from 'react';
import { Row, Col } from 'antd';
// styles
import './styles.scss';
// models
import { CompanyProfile } from '@models/Company';
// utils
import { formatNumber } from '@utils/helpers';
import { formatValueToMillions, formatPercentDefault } from '@utils/format';
import { validateField } from '@optx/utils/editFields';
// components
import Icon from '@components/common/Icon';
import { TrendingValue } from '@components/common/trending';
import { EditFieldSingleSelectText } from '@optx/features/company/edit-fields/components';
import { UserService } from '@optx/services/api';
import CompanyKPI from './CompanyKPI';
import CompanyEditKPI from './CompanyEditKPI';

interface CompanyKPIListProps {
  company: CompanyProfile;
}

const DEFAULT_VALUE = '-';

const growthPercentFormat = (value: number | undefined | null) =>
  typeof value === 'number' ? formatPercentDefault(value) : DEFAULT_VALUE;

const CompanyKPIList: React.FC<CompanyKPIListProps> = ({ company }) => {
  return (
    <div className="company-kpis-wrapper">
      <Row>
        <Col xs={12} lg={{ span: 'auto' }}>
          <CompanyEditKPI
            company={company}
            value={company.num_employees as string}
            icon={<Icon style={{ fontSize: 18 }} iconName="people" />}
            label="Number of Employees"
            fieldKey="num_employees"
          />
        </Col>
        <Col xs={12} lg={{ span: 'auto' }}>
          <CompanyKPI
            icon={<Icon iconName="person-add" />}
            label="Employee Growth Percentage Y/Y"
            title={
              typeof company.emp_growth_percent === 'number' ? (
                <TrendingValue
                  format={growthPercentFormat}
                  value={company.emp_growth_percent}
                  customFontSize="16px"
                />
              ) : (
                DEFAULT_VALUE
              )
            }
          />
        </Col>
        <Col xs={12} lg={{ span: 'auto' }}>
          <CompanyEditKPI
            company={company}
            value={company.capital_raised}
            icon={<Icon iconName="dollar" />}
            label="Total Raised"
            fieldKey="capital_raised"
          />
        </Col>
        <Col xs={12} lg={{ span: 'auto' }}>
          <EditFieldSingleSelectText
            fieldName="Last Round"
            formatValue={formatValueToMillions}
            validation={value => validateField(value, 'Last Round', undefined)}
            service={UserService.updateCompanyUserValues}
            company={company}
          />
        </Col>
        <Col xs={12} lg={{ span: 'auto' }}>
          <CompanyKPI
            icon={<Icon iconName="website-rank" />}
            title={
              company.sw_website_rank_per_country
                ? formatNumber(company.sw_website_rank_per_country)
                : DEFAULT_VALUE
            }
            label="US Website Rank"
          />
        </Col>
      </Row>
    </div>
  );
};

export default CompanyKPIList;
