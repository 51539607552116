import React, { Context, useContext, useRef } from 'react';
import useAsyncSearch from '@optx/common/hooks/select/useAsyncSearch';
import { Typography } from 'antd';
import { countBy, pickBy } from 'lodash';
// models
import { SelectOption } from '@optx/models/Option';
import { BulkEditFieldContext } from '@optx/models/bulkActions';
// constants
import { COMPANY_TAG_ENDPOINT } from '@constants/asyncEndpoints';
// redux
import { useSelector } from 'react-redux';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { selectors as searchSelectors } from '@features/grid/search';
// components
import './style.scss';
import MultiSelectAsync from '@optx/shared/view/molecules/Select/AsyncMultiSelect';
import CompanyTagValue from './CompanyTagValue';

interface BulkAddTagsProps {
  context: Context<BulkEditFieldContext>;
}

const BulkAddTags: React.FC<BulkAddTagsProps> = ({ context }) => {
  const addTagRef = useRef(null);
  const { setSelectedData, gridName } = useContext(context);
  const companiesTagInMyCompaniesPage = useSelector(searchSelectors.getCompanies('myCompanies'));
  const companiesTagInMyHomePage = useSelector(searchSelectors.getCompanies('advancedSearch'));
  const getSeletedCompanies = useSelector(
    bulkActionsSelectors.selectedCompanies.getSelectedCompanies(gridName)
  );
  const companiesTagInMyWatchList = useSelector(searchSelectors.getCompanies('lists'));

  const handleCompaniesTag = () => {
    if (gridName === 'advancedSearch') {
      return companiesTagInMyHomePage;
    }

    if (gridName === 'myCompanies') {
      return companiesTagInMyCompaniesPage;
    }

    if (gridName === 'watchlists') {
      return companiesTagInMyWatchList;
    }

    return [];
  };

  const companiesTagsFiltered = getSeletedCompanies
    .map((_, index) => {
      return handleCompaniesTag().filter(
        company => company.company_id === getSeletedCompanies[index].company_id
      );
    })
    .flat();

  const arrayOfTags = companiesTagsFiltered.map(item => item.cs_tags.split('|').slice(1)).flat();
  const occurrencesOfTags = countBy(arrayOfTags);
  const tagNames = Object.keys(
    pickBy(occurrencesOfTags, (value, key) => value === getSeletedCompanies.length)
  );

  const tagsFiltered = tagNames.filter(item => item !== '' && /\S/.test(item));

  const tagsMapped = tagsFiltered.map(item => {
    return {
      label: item,
      value: item,
    };
  });

  const { loadOptions } = useAsyncSearch({
    endpoint: COMPANY_TAG_ENDPOINT,
  });

  return (
    <div ref={addTagRef}>
      <div>
        <Typography.Text className="ant-modal-title">Existing Sharing Tags</Typography.Text>
      </div>
      <div className="d-flex flex-wrap" style={{ marginBottom: '5%', rowGap: '8px' }}>
        {tagsMapped.map((item, index) => (
          <CompanyTagValue key={index} item={item} />
        ))}
      </div>
      <div style={{ marginBottom: '2%' }}>
        <Typography.Text className="ant-modal-title">Add new Tags</Typography.Text>
      </div>
      <MultiSelectAsync
        onChange={option => {
          // @ts-ignore
          setSelectedData(option as SelectOption);
        }}
        isCreatable
        loadOptions={loadOptions}
        menuIsOpen
        className="bulk-action-multiselect"
        maxMenuHeight={200}
      />
    </div>
  );
};

export default BulkAddTags;
