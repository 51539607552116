import { useEffect, useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// models
import { CompanyProfile } from '@optx/models/Company';
// constants
import { CompanyProfileTabs } from '@optx/constants/routes';
// utils
import { getCompanyProfileURL } from '@optx/utils/routes';
// redux
import { actions as companyTouchesActions } from '@redux/ui/modals/company-touches';
import { selectors as userSelectors } from '@redux/user/information';
import {
  actions as relationshipManagementActions,
  selectors as relationshipManagementSelectors,
} from '@redux/company/relationship-management';
import { actions as companyProfileActions } from '@optx/redux/company/profile';
// components
import { PoweredByAITag } from '@optx/components/common/powered-by-ai-tag';
import Indicator from './Indicator';
import { FeedbackMechanism } from '@optx/components/common/feedback-mechanism';
import { OverlayLoader } from '@optx/components/common/loader';
// styles
import { StyledCard, Highlights } from './RelationshipMGMTSummary.styles';

const { Text, Paragraph } = Typography;

interface RelationshipMGMTSummaryProps {
  company: CompanyProfile;
}

const RelationshipMGMTSummary: React.FC<RelationshipMGMTSummaryProps> = ({ company }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const data = useSelector(relationshipManagementSelectors.getRelationshipManagementData);
  const error = useSelector(relationshipManagementSelectors.getError);
  const hasTouchAccess = useSelector(userSelectors.getHasAccessToTouches);

  const [loadingDescription, setLoadingDescription] = useState(false);

  const isInET = company.is_in_et;
  const totalNumberOfTouches = Number(data?.tabs_data.find(tab => tab.id === 0)?.value || null);
  const hasContent = !error && totalNumberOfTouches > 0;

  useEffect(() => {
    dispatch(relationshipManagementActions.fetchRelationshipManagement(company.company_id));
  }, [dispatch, company]);

  const handleViewMore = () => {
    history.push(
      getCompanyProfileURL(
        company.company_id,
        `${location.search.slice(1, -1)}${CompanyProfileTabs.RELATIONSHIP_MANAGEMENT}`
      )
    );
  };

  const handleAddTouchNote = () => {
    dispatch(
      companyTouchesActions.toggleCompanyTouchesDialog({
        companyId: company.company_id,
        companyName: company.company_name,
        company,
      })
    );
  };

  const handleThumbUp = () => {
    dispatch(companyProfileActions.aiRelationshipSummary(true));
  };

  const handleThumbDown = () => {
    setLoadingDescription(true);
    dispatch(
      companyProfileActions.aiRelationshipSummary(false, () => {
        setLoadingDescription(false);
      })
    );
  };

  const Content = () => {
    return (
      <>
        <Row gutter={[10, 10]} style={{ marginBottom: 15 }}>
          {data?.tabs_data.map(tab => (
            <Col span={24} md={12} lg={8}>
              <Indicator
                label={tab.label}
                value={tab.value}
                type={tab.type}
                lastActivity={tab.last_activity}
              />
            </Col>
          ))}
        </Row>
        {company?.highlights && (
          <>
            <Row align="middle">
              <Col className="relationship-summary__section-title" style={{ marginRight: '10px' }}>
                Highlights
              </Col>
              <Col>
                <PoweredByAITag />
              </Col>
              <Col>
                <FeedbackMechanism
                  value={company.is_ai_highlights_approved}
                  isGenerated={true}
                  handleThumbUp={handleThumbUp}
                  handleThumbDown={handleThumbDown}
                />
              </Col>
            </Row>
            <Col
              span={24}
              className="company-source-descriptions__text"
              style={{ paddingRight: 0, paddingLeft: 0 }}
            >
              {loadingDescription ? (
                <OverlayLoader isOverlay loading />
              ) : (
                <Highlights dangerouslySetInnerHTML={{ __html: company.highlights }} />
              )}
            </Col>
          </>
        )}
      </>
    );
  };

  const NoContent = () => {
    return (
      <Row gutter={[10, 10]}>
        <Col>
          <div className="relationship-summary__section-title no-content">
            There are no highlights
          </div>
          <Paragraph className="d-flex flex-column">
            <Text type="secondary">PSG has not yet reached out to this company</Text>
          </Paragraph>
        </Col>
      </Row>
    );
  };

  return (
    <StyledCard
      title={
        <div className="d-flex">
          <div className="flex-grow-1">Relationship Management Summary</div>
          {hasContent && <Button onClick={handleViewMore}>View More</Button>}
          {!hasContent && isInET && hasTouchAccess && (
            <Button onClick={handleAddTouchNote}>Add Touch Note</Button>
          )}
        </div>
      }
      className="relationship-summary__card profile-box"
    >
      {hasContent ? <Content /> : <NoContent />}
    </StyledCard>
  );
};

export default RelationshipMGMTSummary;
