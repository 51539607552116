import { createAction } from '@reduxjs/toolkit';
import { Dictionary } from 'lodash';
// models
import { CompanyWatchList } from '@optx/models/WatchList';
// redux
import * as types from './types';

/**
 * Fetch lists not including specified company.
 */
export const fetchListsExcludingCompany = createAction<number>(types.FETCH_LISTS_EXCLUDING_COMPANY);
export const fetchListsExcludingCompanySuccess = createAction(
  types.FETCH_LISTS_WITHOUT_COMPANY_SUCCESS,
  (companyId: number, listsExclusingCompany: Dictionary<CompanyWatchList>) => ({
    payload: {
      companyId,
      lists: listsExclusingCompany,
    },
  })
);
export const fetchListsExcludingCompanyFail = createAction<string>(
  types.FETCH_LISTS_WITHOUT_COMPANY_FAIL
);
