import { ColumnType } from 'antd/lib/table';
// models
import { FileDetails } from '@optx/models/files';
import TableCellFileName from './cells/TableCellFileName';
import { TableCellDate } from '../AntTable/cells';
// constants
import globalConfig from '@optx/constants/config';

const columns: ColumnType<FileDetails>[] = [
  {
    dataIndex: 'name',
    key: 'name',
    title: 'File Name',
    width: 200,
    sorter: true,
    render: (value, record) => <TableCellFileName value={value} record={record} />,
  },
  {
    dataIndex: 'registered_at',
    key: 'registered_at',
    title: 'Date Uploaded',
    width: 100,
    sorter: true,
    render: (value, record) => (
      <TableCellDate value={value} format={globalConfig.grid_date.DATE_FORMAT} record={record} />
    ),
  },
  {
    dataIndex: 'author',
    key: 'author',
    title: 'Author',
    width: 100,
    sorter: true,
  },
  {
    dataIndex: 'file_type',
    key: 'file_type',
    title: 'File Type',
    width: 80,
    sorter: true,
  },
  {
    dataIndex: 'origin',
    key: 'origin',
    title: 'Origin',
    width: 90,
    sorter: true,
  },
  {
    dataIndex: 'notes',
    key: 'notes',
    title: 'Notes',
    width: 200,
    sorter: true,
  },
];

export default columns;
