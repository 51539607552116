import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// redux
import { actions as searchActions, selectors as searchSelectors } from '@features/grid/search';
import * as selectors from './selectors';

// components
import { FixedLoaderWithCancel } from '../../../common/loader';

const PageLoader: React.FC = () => {
  const loading = useSelector(selectors.isLoading);
  const saveSearchLoading = useSelector(selectors.saveSearchLoading);
  const isInitialSearch = useSelector(searchSelectors.isInitialSearch('advancedSearch'));
  const hideCancelSearch = useSelector(searchSelectors.hideCancelSearch('advancedSearch'));

  const [localLoading, setlocalLoading] = useState(loading);

  const dispatch = useDispatch();

  useEffect(() => {
    // This makes the loader close after the cancel button.
    if (!loading && !isInitialSearch) {
      setTimeout(() => {
        setlocalLoading(loading);
      }, 100);
    } else {
      setlocalLoading(loading);
    }
  }, [loading, isInitialSearch]);

  const cancelSearch = () => {
    dispatch(searchActions.cancelCompanySearch({ gridKey: 'advancedSearch', data: true }));
  };

  const showCancelButton = () => {
    if (hideCancelSearch) {
      return false;
    }

    if (saveSearchLoading && !isInitialSearch) {
      return true;
    }

    return undefined;
  };

  return (
    <FixedLoaderWithCancel
      loading={localLoading}
      showCancel={showCancelButton()}
      onCancel={cancelSearch}
    />
  );
};

export default React.memo(PageLoader);
