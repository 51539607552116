import { createAction } from '@reduxjs/toolkit';
// models
import { types } from '../types';
import { ActivityState, TouchActivityState } from '../interfaces';

export const fetchInitActivity = createAction(types.activity.FETCH_INIT_ACTIVITY);
export const fetchActivity = createAction(types.activity.FETCH_ACTIVITY);
export const fetchActivitySuccess = createAction<ActivityState>(
  types.activity.FETCH_ACTIVITY_SUCCESS
);
export const fetchActivityFail = createAction<string>(types.activity.FETCH_ACTIVITY_FAIL);

export const fetchInitTouchActivity = createAction(types.activity.FETCH_INIT_TOUCH_ACTIVITY);
export const fetchTouchActivity = createAction(types.activity.FETCH_TOUCH_ACTIVITY);
export const fetchTouchActivitySuccess = createAction<TouchActivityState>(
  types.activity.FETCH_TOUCH_ACTIVITY_SUCCESS
);
export const fetchTouchActivityFail = createAction<string>(
  types.activity.FETCH_TOUCH_ACTIVITY_FAIL
);
