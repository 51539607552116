import { createAction } from '@reduxjs/toolkit';
import { GlassDoorChartData } from '@optx/models/charts/data';
import * as types from './types';

// fetch company touches actions
export const fetchGlassDoorData = createAction<number>(types.fetchGlassDoorData.REQUEST);
export const fetchGlassDoorDataSuccess = createAction<GlassDoorChartData>(
  types.fetchGlassDoorData.SUCCESS
);

export const fetchGlassDoorDataFail = createAction<string>(types.fetchGlassDoorData.FAILURE);
