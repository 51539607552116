import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// models
import { FilterTag, SpecialFilterTag } from '@optx/models/tags';
// redux
import { selectors as searchSelectors } from '@redux/company-outreach/search';
import { actions as searchActions } from '@features/grid/search';
import { actions as filterActions } from '@redux/company-outreach/filters';
import { actions as modalActions } from '@redux/ui/modals/gridModals';
// components
import { FilterTagsOverflow } from '@optx/features/tags/overflow-tags';
import { SelectOption } from '@optx/models/Option';

const FilterTags = () => {
  const dispatch = useDispatch();
  const options = useSelector(searchSelectors.getTags);
  const defaultDateRange = useSelector(searchSelectors.getDefaultDateRange);
  const defaultAnalystIds = useSelector(searchSelectors.getDefaultAnalystId);
  const defaultColumnName = useSelector(searchSelectors.getDefaultColumnName);

  const toggleFiltersModal = useCallback(
    () => dispatch(modalActions.toggleModal({ gridKey: 'outreach' })),
    [dispatch]
  );

  const handleFilterRemove = (tag: FilterTag) => () => {
    // Handle removal of search keyword special tag.
    if ((tag.filter as SpecialFilterTag) === 'keyword') {
      dispatch(
        searchActions.searchCompanies({
          gridKey: 'outreach',
          data: { searchKey: '', shouldResetPageNumber: true },
        })
      );

      return;
    }

    if (tag.filter === 'analyst_name') {
      dispatch(
        filterActions.updateSourcingFilterOptions({
          filterKey: 'analyst_id',
          value: defaultAnalystIds as unknown as SelectOption<string>,
        })
      );
    }

    if (tag.filter === 'date_range') {
      dispatch(
        filterActions.updateSourcingFilterOptions({
          filterKey: 'ddate',
          value: defaultDateRange,
        })
      );
    }

    if (tag.filter === 'column_name') {
      dispatch(
        filterActions.updateSourcingFilterOptions({
          filterKey: 'column_name',
          value: defaultColumnName as unknown as SelectOption<string>,
        })
      );
    }

    dispatch(
      searchActions.removeFilter({
        gridKey: 'outreach',
        data: { selectedTag: tag, filterTags: filteredOptions },
      })
    );
  };

  const filteredOptions = useMemo(
    () => options.filter(item => !item.isDefault && item.values?.length > 0),
    [options]
  );

  return (
    <FilterTagsOverflow
      options={filteredOptions}
      onClick={toggleFiltersModal}
      onClose={handleFilterRemove}
      multipleValue
    />
  );
};

export default FilterTags;
