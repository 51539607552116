import { createFeatureActionType } from '@optx/utils/feature';
import * as search from './search';
import * as filters from './filters';
import * as sort from './sort';
import * as pagination from './pagination';
import * as ui from './ui';
import * as charts from './charts';
import * as highlights from './highlights';
import { featureName } from '../../featureName';

const init = createFeatureActionType(featureName, 'INIT');
const undo = createFeatureActionType(featureName, 'UNDO');

export const types = {
  search,
  filters,
  sort,
  pagination,
  ui,
  init,
  undo,
  charts,
  highlights,
};
