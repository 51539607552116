import { createAction } from '@reduxjs/toolkit';
import * as search from './search';
import * as filters from './filters';
import * as sort from './sort';
import * as pagination from './pagination';
import * as ui from './ui';
import * as highlights from './highlights';
import * as charts from './charts';
import { types } from '../types';

const init = createAction(types.init);
const undo = createAction(types.undo);

export const actions = {
  // feature
  init,
  undo,
  // slices
  search,
  filters,
  sort,
  pagination,
  ui,
  highlights,
  charts,
};
