import { PayloadAction, createReducer, current } from '@reduxjs/toolkit';
import queryString from 'query-string';
// models
import { FilterState, FiltersState } from '../interfaces';
import { UserInformation } from '@optx/models/user';
// redux
import { actions as userInformationActions } from '@redux/user/information';
import { fetchReducer, fetchFailReducer, fetchSuccessReducer } from '@redux/feature/fetch/reducers';
import { actions } from '../actions';

export const initialState: FilterState = {
  data: null,
  value: {
    activity_insights_date: ['', ''],
  },
  loading: false,
  error: '',
};

const updateFilterReducer = (
  draftState: FilterState,
  action: PayloadAction<{ value: [string, string] }>
) => {
  const { value } = action.payload;
  draftState.value.activity_insights_date = value;
};

const fetchFiltersSuccessReducer = (
  draftState: FilterState,
  action: PayloadAction<FiltersState>
) => {
  fetchSuccessReducer(draftState, action);

  const activityInsightDate = action.payload.activity_insights_date[0];
  const persistData = current(draftState.value.activity_insights_date);

  if (persistData[0] === '') {
    draftState.value.activity_insights_date = [activityInsightDate.start, activityInsightDate.end];
  }
};

const fetchUserInformationSuccessReducer = (
  draftState: FilterState,
  action: PayloadAction<UserInformation>
) => {
  const filters = action.payload.settings.session_settings?.analyst_home_touch_activity_filters;

  if (filters) {
    const data = queryString.parse(filters);

    if (data.date_lower && data.date_upper) {
      draftState.value.activity_insights_date = [
        data.date_lower as string,
        data.date_upper as string,
      ];
    }
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.filters.fetchFilters, fetchReducer)
    .addCase(actions.filters.fetchFiltersSuccess, fetchFiltersSuccessReducer)
    .addCase(actions.filters.fetchFiltersFail, fetchFailReducer)
    .addCase(actions.filters.updateFilter, updateFilterReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
);

export default reducer;
