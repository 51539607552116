import { useInjectSaga } from 'redux-injectors';
import { saga } from '../../../redux/company/score-growth';

const useInjectScoreGrowth = () => {
  useInjectSaga({
    key: 'scoreGrowth',
    saga,
  });
};

export default useInjectScoreGrowth;
