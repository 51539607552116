import styled from 'styled-components';
import { Title } from '@optx/shared/view/atoms/Typography';

const PageTitle = styled(Title)`
  h1& {
    color: #1d1d1d;
    margin: 0 20px 0 0;
    font-weight: normal;

    &.is-fullscreen {
      margin-top: 80px;
    }
  }

  span {
    color: #8596ad;
    font-size: 20px;
  }
`;

export default {
  PageTitle,
};
