import { createAction } from '@reduxjs/toolkit';
import { SocialMediaChartData } from '@optx/models/charts/data';
import * as types from './types';

// fetch company touches actions
export const fetchLinkedinData = createAction<number>(types.fetchLinkedinData.REQUEST);
export const fetchLinkedinDataSuccess = createAction<SocialMediaChartData>(
  types.fetchLinkedinData.SUCCESS
);

export const fetchLinkedinDataFail = createAction<string>(types.fetchLinkedinData.FAILURE);
