import React, { useContext } from 'react';
import { BasicProps } from 'antd/lib/layout/layout';
import { MainHeader as MainHeaderView } from '@shared/view/molecules/layout';
import styled, { css } from 'styled-components';
import { LayoutContext } from './LayoutContext';

interface MainHeaderProps extends BasicProps {
  fixed?: boolean;
}

interface StyledMainHeaderProps extends MainHeaderProps {
  hasSider: boolean;
  hasSiderCollapsed: boolean;
}

const fixedStyles = css`
  position: fixed;
  width: 100%;
`;

const StyledMainHeader = styled(
  ({ fixed, hasSider, hasSiderCollapsed, ...props }: StyledMainHeaderProps) => (
    <MainHeaderView {...props} />
  )
)`
  ${props => props.fixed && fixedStyles}

  ${props =>
    props.fixed &&
    props.hasSider &&
    css`
      width: calc(100% - 256px);
    `}

  ${props =>
    props.fixed &&
    props.hasSiderCollapsed &&
    css`
      width: calc(100% - 80px);
    `}
`;

const MainHeader: React.FC<MainHeaderProps> = props => {
  const layout = useContext(LayoutContext);

  return <StyledMainHeader hasSider hasSiderCollapsed={layout.menuCollapsed} {...props} />;
};

export default MainHeader;
