import styled from 'styled-components';
import { Typography } from 'antd';

const Wrapper = styled.div`
  display: flex;
  padding: 10px 0;
  align-items: center;
  min-width: 190px;
`;

const Text = styled(Typography.Text)`
  color: #90a0b7;
  font-size: 14px;
  line-height: 22px;
  flex: 1;
  margin: 0 10px 0 0;
`;

const Total = styled.div`
  color: #1890ff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  flex: 0;
`;

const Styles = {
  Wrapper,
  Text,
  Total,
};

export default Styles;
