import { createAction, PrepareAction } from '@reduxjs/toolkit';
import { SortByRule } from '@models/table/sorting';
import { types } from '../types';
import { ToggleMultiSortPayload } from '../interfaces';

export const sort = createAction<Array<SortByRule<any>>>(types.sort.SORT);

const prepareToggleMultiSort: PrepareAction<ToggleMultiSortPayload> = (
  isMultiSort: boolean,
  sortBy?: Array<SortByRule<any>>
) => ({
  payload: {
    multiSort: isMultiSort,
    sortBy,
  },
});

export const toggleMultisort = createAction(types.sort.TOGGLE_MULTI_SORT, prepareToggleMultiSort);
