import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import * as models from '@optx/models/Notes';
import * as actions from './actions';
import { ProfileNotes, ProfileNotesState } from './interfaces';
import { fetchReducer, fetchFailReducer } from '../../../feature/fetch/reducers';

const initialState: ProfileNotesState = {
  loading: false,
  error: '',
  data: {
    pinned_notes: [],
    unpinned_notes: [],
  },
  sorting: 'desc',
};

const fetchProfileNotesSuccessReducer: CaseReducer<
  ProfileNotesState,
  PayloadAction<{
    data: ProfileNotes;
    sorting: models.noteSorting;
  }>
> = (draftState, action) => {
  draftState.data = action.payload.data;
  draftState.sorting = action.payload.sorting;
  draftState.loading = true;
  draftState.error = '';
};

const editNoteSuccessReducer: CaseReducer<ProfileNotesState, PayloadAction<models.EditNote>> = (
  draftState,
  action
) => {
  const { pinId, newText, isPinned } = action.payload;
  const notes = isPinned ? draftState.data.pinned_notes : draftState.data.unpinned_notes;
  const index = notes.findIndex(note => note.pin_id === pinId);

  if (index !== -1) {
    if (isPinned) {
      draftState.data.pinned_notes[index].note_text = newText;
    } else {
      draftState.data.unpinned_notes[index].note_text = newText;
    }
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    // fetch opportunity presentation
    .addCase(actions.fetchProfileNotes, fetchReducer)
    .addCase(actions.fetchProfileNotesSuccess, fetchProfileNotesSuccessReducer)
    .addCase(actions.fetchProfileNotesFail, fetchFailReducer)
    // update store with new and edited note
    .addCase(actions.editNoteSuccess, editNoteSuccessReducer)
    // delete note
    .addCase(actions.deleteProfileNote, fetchReducer)
    .addCase(actions.deleteProfileNoteFail, fetchFailReducer)
);

export default reducer;
