import { Select } from 'antd';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { SelectProps, SelectValue } from 'antd/lib/select';
// models
import {
  scheduledTouchesSortBy,
  ScheduledTouchesSortBy,
} from '@optx/redux/scheduled-touches/interfaces';
// redux
import { getSortValue } from '@redux/scheduled-touches/selectors';
import { selectors as userSelectors } from '@redux/user/information';
import {
  changeScheduledTouchesSortBy,
  fetchScheduledTouches,
} from '@redux/scheduled-touches/actions';
// components
import {
  mapOptionsToLabeledValues,
  PrefixedSelectionItem,
} from '@components/common/select/Select/PrefixedSelectionItem';

const StyledLabel = styled(PrefixedSelectionItem)`
  .prefixed-selection-item__label {
    color: #1890ff;
    font-size: 14px;
  }
`;

const StyledOption = styled(Select.Option)`
  .prefixed-selection-item__label {
    color: rgba(0, 0, 0, 0.65);
  }
`;

const CardViewSelect = ({ className, ...restProps }: SelectProps<SelectValue>) => {
  return <Select dropdownClassName={className} {...restProps} />;
};

const StyledSelect = styled(CardViewSelect)`
  .prefixed-selection-item__label {
    color: rgba(0, 0, 0, 0.65);
  }
`;

const SortBy = () => {
  const dispatch = useDispatch();
  const sortBy = useSelector(getSortValue);
  const defautlOptxScore = useSelector(userSelectors.getDefaultScore);

  const typesOptions = mapOptionsToLabeledValues(
    'Sort by: ',
    scheduledTouchesSortBy(defautlOptxScore),
    StyledLabel
  );

  const changeSorting = (value: SelectValue) => {
    dispatch(changeScheduledTouchesSortBy(value as ScheduledTouchesSortBy));
    dispatch(fetchScheduledTouches());
  };

  return (
    <StyledSelect onChange={changeSorting} bordered={false} value={sortBy}>
      {typesOptions.map(option => (
        <StyledOption key={option.value} value={option.value}>
          {option.label}
        </StyledOption>
      ))}
    </StyledSelect>
  );
};

export default React.memo(SortBy);
