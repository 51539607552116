import { createSelector } from 'reselect';
import { AppState } from '@optx/redux/interfaces';
import createCachedSelector from 're-reselect';

const selectFavoritesState = (state: AppState) => state.favorites;

export const shouldInitialFetch = createSelector(selectFavoritesState, state => {
  return !state.loading && !state.fetchedAt;
});

export const isLoading = createSelector(selectFavoritesState, state => state.loading);

export const getFavorites = createSelector(selectFavoritesState, state => {
  const { byId, allIds } = state;

  return allIds.map(id => byId[id]);
});

export const isFavorite = createCachedSelector(
  selectFavoritesState,
  (state: AppState, companyId: number) => companyId,
  (state, companyId) => !!state.byId[companyId]
)((state, companyId) => companyId);

export const getError = createSelector(selectFavoritesState, state => state.error);

export const getCompanyById = createCachedSelector(
  selectFavoritesState,
  (state: AppState, companyId: number) => companyId,
  (state, companyId) => state.byId[companyId]
)((state, companyId) => companyId);
