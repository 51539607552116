import styled from 'styled-components';
import PopoverComponent from '@optx/shared/view/molecules/Popover';
import { Tag as AntTag } from 'antd';

const Popover = styled(PopoverComponent)`
  max-width: 500px;
  width: 500px;

  padding-left: 0;

  &.ant-popover-placement-right {
    .ant-popover-arrow {
      margin-left: -10px;
    }
  }

  &.ant-popover-placement-rightTop,
  &.ant-popover-placement-leftTop {
    .ant-popover-arrow {
      top: 51px;
    }
  }
`;

interface PopoverTitleProps {
  fullWidth?: boolean;
}

const PopoverTitle = styled.span<PopoverTitleProps>`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  /* Gray / gray-9 */
  color: #262626;

  margin-right: 4px;

  display: inline-block;
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PopoverTitleWrapper = styled.div`
  width: 100%;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .parent-company-tag {
    margin-left: auto;
    max-width: 40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .enabled-tag {
    &:hover {
      cursor: pointer;
    }
  }

  & a {
    display: flex;
    font-size: 18px;
    color: #262626; /* Gray / gray-9 */
  }

  & a:hover {
    color: #1890ff;
  }
`;

const PopoverContentDescription = styled.p.attrs((props: { isMarginItem?: boolean }) => props)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0px;
  /* Gray / gray-8 */
  color: #595959;

  & a {
    color: #595959;
  }

  & a:hover {
    color: #1890ff;
  }

  &:last-of-type {
    margin: ${props => (props.isMarginItem ? '22px 0px' : '0px 0px 22px 0')};
  }
`;

const TagsWrapper = styled.div`
  padding-bottom: 12px;
  .ant-tag--special {
    margin: 5px 8px 0 0;
    font-size: 12px;
    background: #d6e4ff;
    /* Dark BLue */
    color: #334d6e;
  }
`;

interface TagProps {
  left?: number;
}

const Tag = styled(AntTag)<TagProps>`
  margin: 5px 8px 0 0;
  font-size: 12px;
  position: ${({ left }) => (left ? 'absolute' : 'static')};
  left: ${({ left }) => `${left}px` || 'unset'};
`;

const SubTitle = styled.span`
  font-weight: 600;
`;

const TagContainer = styled.span`
  display: inline-flex;
  flex-wrap: wrap;
  height: 27px;
  overflow-y: hidden;
  max-width: 100%;
  position: relative;
  padding-right: 70px;
`;

export default {
  Popover,
  PopoverTitle,
  PopoverContentDescription,
  PopoverTitleWrapper,
  TagsWrapper,
  Tag,
  SubTitle,
  TagContainer,
};
