import { useState } from 'react';

// reusable hooks for tabs
const useTabs = (initialTab: string): [string, (tab: string) => void] => {
  const [activeTab, setActiveTab] = useState(initialTab);

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return [activeTab, toggle];
};

export default useTabs;
