import * as yup from 'yup';
import { regexValidEmail } from '../../constants/regex';
import { UserInvitationModalProps } from './interfaces';

const message = {
  required: 'Field is required!',
  email: 'Email must be valid!',
};

export const validationSchema = yup.object<UserInvitationModalProps>({
  role: yup.string().required(message.required),
  email: yup
    .string()
    .test('valid', message.email, value => {
      if (value) {
        const emailListSplit = value.split(',');
        let valid = true;
        emailListSplit.forEach((email: string) => {
          const cleanedEmail = email.trim();

          if (cleanedEmail.search(regexValidEmail) === -1) {
            valid = false;
          }
        });

        return valid;
      }

      return false;
    })
    .required(message.required),
});
