import React from 'react';
import { useSelector } from 'react-redux';
import { SalesloftBulkResults } from '@models/bulkActions';
import { selectors as bulkSelectors } from '@features/bulk-actions';
import ErrorReason from './ErrorReason';

export const TableCellErrorReasonSalesloft = (value: string, record: SalesloftBulkResults) => {
  const companies = useSelector(bulkSelectors.salesloft.salesloftBulkCompanies);
  const { company_id: companyId, contacts } = record;
  const company = companies.find(company => company.company_id === companyId);

  const sentContacts = companies.find(
    company => company.company_id === companyId
  )?.salesloft_contacts;

  if (
    contacts[0].sl_message === 'SalesLoft API request failed: email_address: has already been taken'
  ) {
    return 'Email is already in use';
  }

  if (contacts[0].sl_message === 'Canceled by user') {
    return 'Canceled by user';
  }

  if (contacts[0].sl_message === 'No contacts available' && company?.contacts.length) {
    return 'No contact(s) were selected';
  }

  if (!sentContacts?.length) {
    return contacts[0].sl_message;
  }

  return <ErrorReason contactsResponse={contacts} sentContacts={sentContacts} />;
};

export default TableCellErrorReasonSalesloft;
