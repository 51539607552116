import React from 'react';
import numeral from 'numeral';
// models
import { TableCellSearches } from '@optx/models/table/Cell';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
// hooks
import useCompanySavedSearches from '@hooks/saved-searches/useCompanySavedSearches';

const TableCellNumber: React.FC<TableCellSearches> = ({ value, row }) => {
  const { handleSearchResults } = useCompanySavedSearches();

  const handleClick = () => {
    if (row.unique_id) {
      handleSearchResults(row.unique_id, row.title, row.search_info.type || '');
    }
  };

  return (
    <div onClick={handleClick} role="presentation">
      {value ? numeral(value).format('0,0') : DEFAULT_CELL_VALUE}
    </div>
  );
};

export default TableCellNumber;
