/* eslint-disable react/jsx-curly-newline */
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Space } from 'antd';
//constants
import { HTML } from '@optx/constants/fullScreen';
// redux
import ClearSearchButton from '@shared/view/molecules/Buttons/ClearSearchButton';
import { getUserId } from '@redux/user/information/selectors';
import {
  actions as sourceScrubListsActions,
  selectors as sourceScrubListsSelectors,
} from '../../../../redux/user/source-scrub-lists';
import {
  actions as fullScreenActions,
  selectors as fullscreenSelectors,
} from '../../../../redux/ui/settings/fullscreen';
import { selectors as listsAndSearchesSelectors } from '@features/lists-and-searches';
// hooks
import { useShouldFetch } from '../../../../common/hooks/fetch';
import { useReduxFullScreen } from '../../../../common/hooks/fullscreen';
// components
import SourceScrubListsTable from './SourceScrubListsTable';
import { GridPagination } from '@features/grid/pagination/components';
import { MainHeader } from '../../../common/layout';
import SourceScrubSearch from './SourceScrubSearch';
import FilterTags from '../FilterTags';
import GridFullScreen from '@optx/shared/view/molecules/FullScreen/GridFullScreen';

const SourceScrubLists: React.FC = () => {
  const shouldInitialFetchSearches = useSelector(sourceScrubListsSelectors.shouldFetch);
  const query = useSelector(sourceScrubListsSelectors.getQuery);
  const userId = useSelector(getUserId);
  const pageNumber = useSelector(sourceScrubListsSelectors.getPageNumber);
  const pageSize = useSelector(listsAndSearchesSelectors.pageSize);
  const totalItemsCount = useSelector(sourceScrubListsSelectors.getTotal);
  const dispatch = useDispatch();

  const fetchSourceScrubLists = useCallback(
    () => userId && dispatch(sourceScrubListsActions.searchSourceScrubLists({ query })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, userId]
  );

  // fullscreen functionality
  const toggle = useCallback(() => dispatch(fullScreenActions.toggleFullScreen()), [dispatch]);
  const fullscreen = useSelector(fullscreenSelectors.isFullscreen);

  useReduxFullScreen({ dom: HTML, fullscreen, toggle });

  useShouldFetch(shouldInitialFetchSearches && !!userId, fetchSourceScrubLists);

  const handlePageChange = useCallback(
    (pageNumber: number, perPage: number = pageSize) =>
      dispatch(
        sourceScrubListsActions.changeSourceScrubPagination({ pageNumber, pageSize: perPage })
      ),
    [dispatch, pageSize]
  );

  return (
    <div>
      <MainHeader className="main-header">
        <div className="search-header-content">
          <SourceScrubSearch className="antd-search-input" autoComplete="off" />
          <FilterTags listType="Source Scrub" />
          <Space size={4} style={{ marginLeft: 'auto' }}>
            <ClearSearchButton
              isVisible={!!query}
              onClick={() =>
                dispatch(sourceScrubListsActions.searchSourceScrubLists({ query: '' }))
              }
            />
            <GridFullScreen />
          </Space>
        </div>
      </MainHeader>
      <SourceScrubListsTable />
      <GridPagination
        onPageChange={handlePageChange}
        totalItemsCount={totalItemsCount || 0}
        pageNumber={pageNumber}
        pageSize={pageSize}
      />
    </div>
  );
};

export default SourceScrubLists;
