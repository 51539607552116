import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Row, Col } from 'antd';
// redux
import { selectors as editAllDialogSelectors } from '@optx/features/edit-all-info/state';

interface EditAllModalTittleProps {
  toggle: () => void;
  handleCreateCompanyEdit: () => void;
}

const EditAllModalTittle: React.FC<EditAllModalTittleProps> = ({
  toggle,
  handleCreateCompanyEdit,
}) => {
  const saveBtnStatus = useSelector(editAllDialogSelectors.getDisableSaveBtnFor);

  return (
    <Row justify="space-between">
      <Col span={12}>
        <div className="modal-title">
          <span>Edit Company Info</span>
        </div>
      </Col>
      <Col>
        <div className="company-buttons">
          <Button className="btn-cancel" onClick={toggle}>
            CANCEL
          </Button>{' '}
          <Button
            type="primary"
            className="btn-send"
            onClick={handleCreateCompanyEdit}
            disabled={!!saveBtnStatus}
          >
            SAVE
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default React.memo(EditAllModalTittle);
