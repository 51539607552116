import React, { useMemo } from 'react';
import { Menu, Typography } from 'antd';
import { MenuProps } from 'antd/lib/menu';
import { TagsOutlined, FilterOutlined } from '@ant-design/icons';
// models
import { SearchAutocomplete } from '@models/search-autocomplete';
// utils
import { parseFiltersKeys } from '@utils/filters/parseFilters';

const { Text } = Typography;

const getFilterTexts = (filters: string[]) => {
  return filters.reduce((accumulator: string[], currValue: string, currIdx: number) => {
    const isDuplicate = accumulator.indexOf(currValue) === currIdx && currValue;
    if (isDuplicate) return accumulator;

    const filterText = parseFiltersKeys(currValue);
    if (!filterText) return accumulator;

    const listOfFilters = [...accumulator, filterText];

    const listOfFiltersWithoutDuplicates = Array.from(new Set(listOfFilters));

    return listOfFiltersWithoutDuplicates;
  }, []);
};

const getMenuItemText = (
  searchTitle: string,
  searchType: string | null,
  filters: (string | undefined)[],
  searchKeyword: string
) => {
  const newFilters = [...filters.filter(filter => filter !== undefined)];

  let prefix = null;
  let itemText = null;

  if (searchType?.startsWith('saved')) {
    prefix = 'Saved Search: ';
    itemText = searchTitle;
  }

  if (!prefix && !itemText && searchTitle.startsWith('-recent-search-suggested-')) {
    prefix = 'Suggested Search: ';
    itemText = searchTitle.replace('-recent-search-suggested-', '');
  }

  if (!prefix && !itemText && newFilters.length === 1) {
    prefix = '1 Filter: ';
    itemText = newFilters[0] || '';
  }

  if (!prefix && !itemText && newFilters.length > 1) {
    prefix = `${newFilters.length} Filters: `;
    itemText = newFilters.join(', ');
  }

  if (!prefix && !itemText && newFilters.length === 0 && searchKeyword) {
    prefix = null;
    itemText = `"${searchKeyword}"`;
  }

  // fallback
  if (!itemText) {
    itemText = searchTitle;
  }

  return (
    <>
      {prefix && <Text strong>{prefix}</Text>}
      {itemText}
    </>
  );
};

interface AutocompleteMenuProps extends MenuProps {
  onClickHandler?: (item: SearchAutocomplete) => {};
  list: SearchAutocomplete[];
  fromUserList?: boolean;
}

const AutocompleteMenu: React.FC<AutocompleteMenuProps> = ({
  onClick,
  onClickHandler,
  list,
  fromUserList = false,
  ...restProps
}) => {
  if (!list.length) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const listWithoutDuplicates = useMemo(() => {
    return list
      .map(item => {
        return {
          ...item,
          search_info: {
            ...item.search_info,
            filters: item.search_info.filters
              .filter(currentItem => !currentItem.startsWith('sort') && currentItem)
              .sort()
              .join(''),
          },
        };
      })
      .filter(
        (item, index, array) =>
          array.findIndex(
            currentItem => currentItem.search_info.filters === item.search_info.filters
          ) === index
      )
      .map(item => {
        return {
          ...item,
          search_info: {
            ...item.search_info,
            filters:
              list.find(initialItem => initialItem.unique_id === item.unique_id)?.search_info
                .filters || [],
          },
        };
      });
  }, [list]);

  const handleClick: MenuProps['onClick'] = info => {
    const { key } = info;
    const index = Number.parseInt(key as string);
    const autocompletItem = listWithoutDuplicates[index];

    onClick && onClick(info);
    onClickHandler && onClickHandler(autocompletItem);
  };

  return (
    <Menu onClick={handleClick} className="recentsearches-list" {...restProps}>
      <Menu.ItemGroup
        key="autocompletegroup1"
        title="RECENT SEARCHES"
        className="recentsearches-title"
      >
        {listWithoutDuplicates.map((autocompleteItem, index) => {
          const {
            search_info: { type: searchType, filters: searchFilters, keyword: searchKeyword },
          } = autocompleteItem;

          const filterTexts = getFilterTexts(searchFilters);

          if (filterTexts.length && searchKeyword) {
            filterTexts.unshift(`"${searchKeyword}"`);
          }

          const menuItemSpanTitle =
            // eslint-disable-next-line no-nested-ternary
            autocompleteItem.search_info && searchType !== null && searchType.startsWith('saved')
              ? autocompleteItem.title
              : filterTexts.length
              ? filterTexts.join(', ')
              : `"${(autocompleteItem.search_info && searchKeyword) || ''}"`;

          const resultCount = autocompleteItem.count;
          const resultCountText =
            resultCount !== null && !fromUserList
              ? `(${resultCount} ${resultCount === 1 ? 'result' : 'results'})`
              : null;

          return (
            <Menu.Item key={index}>
              {(searchType !== null && searchType.startsWith('saved')) || filterTexts.length ? (
                <FilterOutlined className="recentsearches-icon" />
              ) : (
                <TagsOutlined className="recentsearches-icon" />
              )}
              <span className="item-truncate" title={menuItemSpanTitle}>
                &nbsp;
                {getMenuItemText(autocompleteItem.title, searchType, filterTexts, searchKeyword)}
              </span>
              <span>
                &nbsp;
                {resultCountText}
              </span>
            </Menu.Item>
          );
        })}
      </Menu.ItemGroup>
    </Menu>
  );
};

export default React.memo(AutocompleteMenu);
