import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
// constants
import { defaultColumns } from '@components/common/table/TableBulkAddingResults';
// redux
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';

const BulkEditingResults: React.FC = () => {
  const results = useSelector(bulkActionsSelectors.equityTouch.results);

  return (
    <Table
      tableLayout="fixed"
      columns={defaultColumns}
      rowKey={'company_id'}
      dataSource={results}
      pagination={false}
      bordered
      className="legacy-table"
      style={{ maxHeight: '45vh', overflowY: 'auto' }}
    />
  );
};

export default BulkEditingResults;
