import React from 'react';
import classnames from 'classnames';

interface CompanyKPIProps {
  icon: React.ReactNode;
  title?: React.ReactNode;
  label: string;
  className?: string;
}

const CompanyKPI: React.FC<CompanyKPIProps> = ({ className, icon, title, label }) => {
  const kpiClassName = classnames('media-object company-kpi', className);

  return (
    <div className={kpiClassName}>
      <div className="media-object-section">
        <div className="company-kpi__icon">{icon}</div>
      </div>
      <div className="media-object-section">
        <div className="company-kpi__title">{title}</div>
        <div className="company-kpi__label">{label}</div>
      </div>
    </div>
  );
};

export default CompanyKPI;
