import { createAction } from '@reduxjs/toolkit';
import { Dictionary } from 'lodash';
import { CompanyUserContact } from '@models/api/contacts';
import { AddTouchToCompanyPayload, TouchesFinancialPayload } from '@models/api/touches';
import { FilterSource } from '@models/filters';
import { CompanyFieldsGroup } from '@optx/models/companyFields';
import { PsgParticipant } from '@models/api/user';
import { SelectOption } from '@optx/models/Option';
import { CompanyTouchSuccessResponse } from '@optx/models/company/CompanyTouch';
import {
  INIT_COMPANY_TOUCHES_DIALOG,
  TOGGLE_COMPANY_TOUCHES_DIALOG,
  ADD_COMPANY_TOUCHES,
  ADD_COMPANY_TOUCHES_SUCCESS,
  ADD_COMPANY_TOUCHES_FAIL,
  ADD_COMPANY_TOUCHES_ERROR_VALIDATION,
  GET_PSG_PARTICIPANTS,
  GET_PSG_PARTICIPANTS_SUCCESS,
  GET_PSG_PARTICIPANTS_FAIL,
  GET_TOUCHES_COMPANY_CONTACTS,
  GET_TOUCHES_COMPANY_CONTACTS_SUCCESS,
  GET_TOUCHES_COMPANY_CONTACTS_FAIL,
  GET_TOUCHES_FILTERS,
  GET_TOUCHES_FILTERS_SUCCESS,
  GET_TOUCHES_FILTERS_FAIL,
  CHANGE_MODAL_TITLE,
  CHANGE_SAVE_BTN_STATUS,
  EDIT_TOUCH,
  EDIT_TOUCH_FAIL,
  GET_TOUCHES_FINANCIAL_FILTERS,
  GET_TOUCHES_FINANCIAL_FILTERS_SUCCESS,
  GET_TOUCHES_FINANCIAL_FILTERS_FAIL,
  UPDATE_TOUCH_COMPANY_INFO_SUCCESS,
  UPDATE_TOUCH_COMPANY_INFO_FAIL,
  CLEAR_ADDITIONAL_FIELDS_ERROR,
  SET_ADDITIONAL_FIELDS_ERROR,
  CHANGE_ADDITIONAL_FIELDS_ERROR_TEXT,
  CHANGE_SAVE_BTN_STATUS_BY_ADDITIONAL_FIELDS,
} from './actionTypes';
import { EditCompanyTouches, ChangeErrorText, validationErrorsTypes } from './interfaces';

export const initCompanyTouchesDialog = createAction<number | undefined>(
  INIT_COMPANY_TOUCHES_DIALOG
);

export const toggleCompanyTouchesDialog = createAction<EditCompanyTouches>(
  TOGGLE_COMPANY_TOUCHES_DIALOG
);

export const getPsgParticipants = createAction(GET_PSG_PARTICIPANTS);
export const getPsgParticipantsSuccess = createAction<PsgParticipant[]>(
  GET_PSG_PARTICIPANTS_SUCCESS
);
export const getPsgParticipantsFail = createAction<string>(GET_PSG_PARTICIPANTS_FAIL);

export const getTouchesFilters = createAction(GET_TOUCHES_FILTERS);
export const getTouchesFiltersSuccess = createAction<Array<FilterSource>>(
  GET_TOUCHES_FILTERS_SUCCESS
);
export const getTouchesFiltersFail = createAction<string>(GET_TOUCHES_FILTERS_FAIL);

export const getTouchCompanyContacts = createAction<number>(GET_TOUCHES_COMPANY_CONTACTS);
export const getTouchCompanyContactsSuccess = createAction<Array<CompanyUserContact>>(
  GET_TOUCHES_COMPANY_CONTACTS_SUCCESS
);
export const getTouchCompanyContactsFail = createAction<string>(GET_TOUCHES_COMPANY_CONTACTS_FAIL);

export const addTouchToCompany = createAction<AddTouchToCompanyPayload>(ADD_COMPANY_TOUCHES);
export const addTouchToCompanySuccess = createAction<CompanyTouchSuccessResponse>(
  ADD_COMPANY_TOUCHES_SUCCESS
);
export const addTouchToCompanyErrorValidation = createAction<validationErrorsTypes>(
  ADD_COMPANY_TOUCHES_ERROR_VALIDATION
);
export const addTouchToCompanyFail = createAction<string>(ADD_COMPANY_TOUCHES_FAIL);

export const changeModalTitle = createAction<string>(CHANGE_MODAL_TITLE);

export const changeSaveBtnStatus = createAction<string | null>(CHANGE_SAVE_BTN_STATUS);

export const changeSaveBtnStatusByAdditionalFields = createAction<boolean>(
  CHANGE_SAVE_BTN_STATUS_BY_ADDITIONAL_FIELDS
);

export const editTouch = createAction<AddTouchToCompanyPayload>(EDIT_TOUCH);
export const editTouchFail = createAction<string>(EDIT_TOUCH_FAIL);

export const getTouchFinancialFilters = createAction<string | number>(
  GET_TOUCHES_FINANCIAL_FILTERS
);
export const getTouchFinancialFiltersSuccess = createAction<Array<CompanyFieldsGroup>>(
  GET_TOUCHES_FINANCIAL_FILTERS_SUCCESS
);
export const getTouchFinancialFiltersFail = createAction<string>(
  GET_TOUCHES_FINANCIAL_FILTERS_FAIL
);

export const updateTouchCompanyInfoSuccess = createAction<Partial<TouchesFinancialPayload>>(
  UPDATE_TOUCH_COMPANY_INFO_SUCCESS
);
export const updateTouchCompanyInfoFail = createAction<string>(UPDATE_TOUCH_COMPANY_INFO_FAIL);

export const clearAdditionalFieldsError = createAction(CLEAR_ADDITIONAL_FIELDS_ERROR);
export const setAdditionalFieldsError = createAction(
  SET_ADDITIONAL_FIELDS_ERROR,
  (error: string, errorReasonValue: Dictionary<SelectOption>) => ({
    payload: error,
    meta: errorReasonValue,
  })
);
export const changeAdditionalFieldsErrorText = createAction<ChangeErrorText>(
  CHANGE_ADDITIONAL_FIELDS_ERROR_TEXT
);
