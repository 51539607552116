import React from 'react';
import styled from 'styled-components';
import { Tag, Typography, Popover } from 'antd';
import { FilterTag } from '@optx/models/tags';
import FilterTagValue from './FilterTagValue';

const { Text } = Typography;

const TagMoreFilters = styled(Tag)`
  cursor: pointer;
  flex: 0;
`;

const TagMoreFiltersText = styled(Text)`
  color: #1890ff;
`;

interface FilterMoreTagsProps {
  items: FilterTag[];
  closable?: (item: FilterTag) => boolean;
  onClose: (item: FilterTag) => () => void;
  toggleFiltersModal?: () => void;
}

const FilterTagMore: React.FC<FilterMoreTagsProps> = ({
  items,
  closable,
  onClose,
  toggleFiltersModal,
}) => {
  return (
    <>
      {items.length && (
        <Popover
          overlayClassName="ant-popover-tags"
          placement="bottom"
          content={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <>
              {items.map(option => (
                <div key={option.uniqueKey}>
                  <FilterTagValue
                    key={option.uniqueKey}
                    item={option}
                    onClose={onClose(option)}
                    closable={closable ? closable(option) : true}
                  />
                </div>
              ))}
            </>
          }
        >
          <TagMoreFilters className="tag-more-filters" onClick={toggleFiltersModal}>
            <TagMoreFiltersText>
              {`${items.length} more ${items.length === 1 ? 'filter' : 'filters'}`}
            </TagMoreFiltersText>
          </TagMoreFilters>
        </Popover>
      )}
    </>
  );
};

export default FilterTagMore;
