import { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
// redux
import { selectors, actions } from '@features/checklist-research-insights/state';
import { selectors as fullscreenSelectors } from '@redux/ui/settings/fullscreen';
// components
import SearchCount from '@optx/features/grid/search-count/components/SearchCount';

const ViewByWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  h1 {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    color: black;
  }
  .ant-select-selector {
    font-size: 24px;
    font-weight: 400;
    color: #1890ff;
  }

  span.count {
    font-size: 20px;
    font-weight: 400;
    color: #8997b1;
  }
`;

const ViewBy = () => {
  const dispatch = useDispatch();
  const [defaultView, setDefaultView] = useState<'analyst' | 'company'>('analyst');

  const defaultTableView = useSelector(selectors.ui.defaultTableView);
  const isFullscreen = useSelector(fullscreenSelectors.isFullscreen);

  useEffect(() => {
    setDefaultView(defaultTableView);
  }, [defaultTableView]);

  const handleTableView = (value: 'analyst' | 'company') => {
    setDefaultView(value);
    dispatch(actions.ui.toggleDefaultTableView(value));
  };

  return (
    <ViewByWrapper>
      <h1>View by: </h1>
      <Select
        value={defaultView}
        bordered={false}
        onChange={handleTableView}
        options={[
          {
            value: 'company',
            label: 'Companies Reviewed',
          },
          {
            value: 'analyst',
            label: 'Analysts Leaderboard View',
          },
        ]}
      />
      {defaultTableView === 'company' && !isFullscreen && (
        <span className="count">
          <SearchCount gridName="checklistResearchInsights" />
        </span>
      )}
    </ViewByWrapper>
  );
};

export default ViewBy;
