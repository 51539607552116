import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
import ShareListInfo from '@models/feature/shareList/ShareListInfo';
import { reducers as fetchReducers } from '@redux/feature/fetch';
import { EditShareListActionPayload, ShareListState } from './interfaces';
import * as actions from './actions';

export const initialState: ShareListState = {
  data: null,
  error: null,
  loading: false,
  editedList: null,
  owner: null,
  listType: null,
};

const editShareListReducer: CaseReducer<
  ShareListState,
  PayloadAction<EditShareListActionPayload>
> = (draftState, action) => {
  const { listId, type } = action.payload;
  draftState.editedList = listId;
  draftState.data = null;
  draftState.owner = null;
  draftState.listType = type;
};

const cancelEditShareListReducer: CaseReducer<ShareListState> = draftState => {
  draftState.data = null;
  draftState.editedList = null;
  draftState.owner = null;
  draftState.editedList = null;
  draftState.error = '';
  draftState.listType = null;
};

const fetchShareListInfoSuccess: CaseReducer<ShareListState, PayloadAction<ShareListInfo>> = (
  draftState,
  action
) => {
  fetchReducers.fetchSuccessReducer(draftState, action);
  const owner = action.payload.shared_with.find(user => user.is_owner);
  draftState.owner = owner || null;
};

const shareListSuccessReducer: CaseReducer<ShareListState> = draftState => {
  draftState.data = null;
  draftState.owner = null;
  draftState.loading = false;
  draftState.editedList = null;
  draftState.listType = null;
};

const reducer = createReducer(initialState, builder =>
  builder
    // fetch share list info
    .addCase(actions.fetchShareListInfo, fetchReducers.fetchReducer)
    .addCase(actions.fetchShareListInfoSuccess, fetchShareListInfoSuccess)
    .addCase(actions.fetchShareListInfoFail, fetchReducers.fetchFailReducer)
    // share list
    .addCase(actions.shareList, fetchReducers.fetchReducer)
    .addCase(actions.shareListSuccess, shareListSuccessReducer)
    .addCase(actions.shareListFail, fetchReducers.fetchFailReducer)
    // edit share list
    .addCase(actions.editShareList, editShareListReducer)
    .addCase(actions.cancelEditShareList, cancelEditShareListReducer)
);

export default reducer;
