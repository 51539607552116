import { createAction } from '@reduxjs/toolkit';
import { SuccessErrorCallback } from '@optx/models/callback';
import { AsyncMultiSelectPayload } from './interfaces';
import {
  SEARCH_ASYNC_MULTISELECT,
  SEARCH_ASYNC_MULTISELECT_SUCCESS,
  SEARCH_ASYNC_MULTISELECT_FAIL,
} from './actionTypes';

export const searchAsyncMultiSelect = createAction(
  SEARCH_ASYNC_MULTISELECT,
  (payload: AsyncMultiSelectPayload, callback: SuccessErrorCallback) => ({
    payload,
    meta: callback,
  })
);

export const fetchAsyncMultiSelectSuccess = createAction<Array<any>>(
  SEARCH_ASYNC_MULTISELECT_SUCCESS
);

export const fetchAsyncMultiSelectFail = createAction<string>(SEARCH_ASYNC_MULTISELECT_FAIL);
