import React from 'react';
import styled from 'styled-components';
import ContactSelect from './ContactSelect';

const SelectContactCell = styled.div`
  .change-contact-cell {
    width: 100%;
    color: #1890ff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .anticon svg {
      width: 16px;
      height: 16px;
    }
  }
  button:disabled,
  button[disabled] {
    color: gray;
    opacity: 0.5;
  }
  .ant-select-disabled.ant-select-multiple {
    .ant-select-selector {
      background: initial;
      opacity: 0.5;
    }
    .ant-select-arrow .anticon svg {
      color: gray;
      opacity: 0.5;
    }
  }
`;

const TableCellContact: React.FC = (value, record) => {
  return (
    <SelectContactCell>
      <ContactSelect companyData={record} />
    </SelectContactCell>
  );
};

export default TableCellContact;
