import { ColumnType } from 'antd/lib/table';
// redux
import { EquityTouchBulkResults } from '@optx/models/bulkActions';
// components
import { TableCellURL, TableCellCompanyName } from '@components/common/table/AntTable/cells';
import {
  TableCellErrorReasonEquityTouch,
  TableCellEquityTouchUrl,
  TableCellEtSalesloftBulkUrl,
  TableCellStatus,
  TableCellActionsEquityTouch,
} from './cells';

const columns: ColumnType<EquityTouchBulkResults>[] = [
  {
    dataIndex: 'company_url',
    title: '',
    render: (value: string, record: EquityTouchBulkResults) => (
      <TableCellURL value={value} record={{ url: record.raw_url }} />
    ),
    width: 42,
    align: 'center',
  },
  {
    dataIndex: 'company_name',
    title: 'Company Name',
    render: TableCellCompanyName,
    width: 250,
  },
  {
    dataIndex: 'et_url',
    title: 'View in Equity Touch',
    render: TableCellEquityTouchUrl,
    width: 250,
  },
  {
    dataIndex: 'sl_url',
    title: 'View in Sales Loft',
    render: TableCellEtSalesloftBulkUrl,
    width: 250,
  },
  {
    dataIndex: 'et_sync_status',
    title: 'Sync Status',
    render: TableCellStatus,
    width: 100,
  },
  {
    dataIndex: 'et_message',
    title: 'Error Reason',
    render: TableCellErrorReasonEquityTouch,
    width: 250,
  },
  {
    dataIndex: 'sl_resolve',
    title: 'Action',
    render: TableCellActionsEquityTouch,
    className: 'contact-cell',
    width: 380,
  },
];

export default columns;
