import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';

import { reducers as fetchReducers } from '@redux/feature/fetch';

import * as actions from './actions';
import { CompanyStageState, EditCompanyStageActionPayload } from './interfaces';

export const initialState: CompanyStageState = {
  companyId: null,
  loading: false,
  error: null,
};

const addCompanyStage: CaseReducer<
  CompanyStageState,
  PayloadAction<EditCompanyStageActionPayload>
> = (draftState, action) => {
  const { companyId } = action.payload;
  draftState.companyId = companyId;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.addCompanyStage, addCompanyStage)
    .addCase(actions.addCompanyStageFail, fetchReducers.fetchFailReducer)
);

export default reducer;
