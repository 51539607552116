import { message as antdMessage } from 'antd';

export const DEFAULT_CELL_VALUE = '-';

export const DEFAULT_LAST_TOUCH = 'Never';
export const DEFAULT_NEXT_TOUCH = 'Not Scheduled';
export const ABBREVIATION_ERROR_MESSAGE =
  'Field only supports digits and letters for abbreviation (K, M, B, T)';

export const ABBREVIATION_WITH_NEGATIVE_VALUES_ERROR_MESSAGE =
  'Characters allowed: - for negative values, digits, letters (K, M, B, T)';

export const ABBR_NEGATIVE_VALUES_SPECIAL_OPTIONS_ERROR_MESSAGE =
  'Characters allowed: - for negative values, digits, letters (K, M, B, T), special options';

export const ONLY_DIGITS_ERROR_MESSAGE = 'Field only supports digits';
export const ONLY_DIGITS_AND_PERCENTAGE_ERROR_MESSAGE =
  'Field only supports digits and percentage symbol';
export const ONLY_DIGITS_AND_OPTIONS_ERROR_MESSAGE =
  'Field only supports digits and special options';

export const message = () => {
  antdMessage.config({ maxCount: 1, duration: 1.2 });

  return antdMessage;
};

export const LINK_URL_VALUE_ERROR_MESSAGE = 'Field only supports valid URL links';
