import styled from 'styled-components';
import { Button as AntButton, Card as AntCard } from 'antd';
import bgAccount from '../../../assets/images/bg-account.png';

const LoginWrapper = styled.div<{ $isExtension: boolean }>`
  width: ${props => (props.$isExtension ? '630px' : 'initial')};
`;

const LogoWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  line-height: 70px;
  margin-bottom: 20px;
`;

const CompanyTitle = styled.span`
  display: inline-block;
  color: #495057;
  font-size: 28px;
  line-height: 70px;
  font-weight: 500;
`;

const Logo = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 5px;
`;

const Card = styled(AntCard)`
  padding: 10px;
  border: 7px double #5b6be8;
  border-radius: 10px;

  .ant-card-body {
    padding: 0;
  }
`;

const SignInButton = styled(AntButton)`
  display: block;
  width: 100%;
  color: #3b3b3b;
  border-radius: 3px;
  font-size: 15px;
  height: 36px;
`;

const CognitoButton = styled(AntButton)`
  display: block;
  width: 100%;
  background-color: #0084fa;
  border: 1px solid #0084fa;
  color: #fff;
  border-radius: 3px;
  font-size: 15px;
  height: 36px;

  :hover {
    color: #fff;
    background-color: #4557e5;
    border: 1px solid #4557e5;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &[disabled] {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    &:hover {
      color: #fff;
      background-color: #007bff;
      border-color: #007bff;
    }
  }
`;

const FormWrapper = styled.div`
  padding: 1rem;

  .ant-form-item-control {
    &:focus {
      border-color: #5b6be8;
      box-shadow: none;
    }
    input::placeholder {
      color: #000;
      font-weight: 400;
      font-size: 15px;
    }
  }

  .ant-row {
    margin-bottom: 20px;
    :last-child {
      margin-top: 30px;
      margin-bottom: 0;
    }
  }
`;

const AccountBg = styled.div`
  background-image: url(${bgAccount});
  background-repeat: repeat;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
`;

const WrapperPage = styled.div`
  margin: 7.5% auto;
  max-width: 460px;
  position: relative;

  .logo-admin {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    line-height: 70px;
  }

  .card {
    border: 7px double $primary;
    border-radius: 10px;
  }
`;

export const Styled = {
  LogoWrapper,
  CompanyTitle,
  FormWrapper,
  Logo,
  Card,
  SignInButton,
  CognitoButton,
  AccountBg,
  WrapperPage,
  LoginWrapper,
};
