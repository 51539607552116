import React from 'react';
import { Input } from 'antd';

interface AddWatchListProps {
  title: string;
  onTitleChange: (newTitle: string) => void;
  handleDropdownKeyEvent: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const AddWatchList: React.FC<AddWatchListProps> = ({
  title,
  onTitleChange,
  handleDropdownKeyEvent,
}) => {
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onTitleChange(event.target.value);
  };

  return (
    <div className="menu-create" onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
      <Input
        type="text"
        name="title"
        value={title || ''}
        placeholder="Watchlist Name"
        onChange={handleTitleChange}
      />
    </div>
  );
};

export default AddWatchList;
