import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  ColumnDisplay,
  ColumnKeys,
  CompanyColumnDisplay,
  ColumnWidth,
} from '@optx/models/table/Columns';
import { getPinnedColumns, getNewColumnDisplay } from '@optx/utils/columns';

export const updateColumnReducer = (
  draftState: ColumnDisplay,
  columnVisibility: string | null | undefined,
  columnOrder: string | null | undefined,
  pinnedColumns: string | null | undefined,
  updateVisibility: boolean,
  updatePins: boolean
) => {
  // if both column visibility and pins are saved we just need to update
  // the columnDisplay object with the appropriate values for each column
  // and just save the column order as is
  if (updateVisibility && updatePins) {
    draftState.columnDisplay = getNewColumnDisplay(
      draftState.columnDisplay,
      columnVisibility as string,
      pinnedColumns as string
    );

    if (columnOrder) {
      draftState.columnOrder = columnOrder?.split(',') as ColumnKeys[];
    }
    // if we only have column visibility saved, we need to update the visibility property
    // but keep in mind that pinned columns should  always be on top
  } else if (updateVisibility && !updatePins) {
    const newColumnDisplay = getNewColumnDisplay(
      draftState.columnDisplay,
      columnVisibility as string
    );

    const pinnedColumnsList = getPinnedColumns(newColumnDisplay).split(',');
    const newColumnOrder = columnOrder?.split(',');

    draftState.columnDisplay = newColumnDisplay;

    // the column order can influence the position of existing pinned columns and move them
    // so that they are not first in the list anymore, because of this
    // we need to update the column order to move the pinned columns to the top
    if (newColumnOrder) {
      draftState.columnOrder = pinnedColumnsList.concat(
        newColumnOrder.filter(columnId => !newColumnDisplay[columnId as ColumnKeys].pinned)
      ) as ColumnKeys[];
    }

    // if we only have pinned columns saved, we need to update the pinned property of each column
    // and mark them as visible. also we need to update the column order and move them to the top
  } else if (!updateVisibility && updatePins) {
    const newColumnDisplay: CompanyColumnDisplay | {} = {};

    Object.keys(draftState.columnDisplay).forEach(columnId => {
      // @ts-ignore
      newColumnDisplay[columnId as ColumnKeys] = {
        ...draftState.columnDisplay[columnId as ColumnKeys],
        // update pinned property for each column and only update visible
        // property to true for pinned columns
        ...(pinnedColumns?.includes(columnId) && { visible: true }),
        pinned: pinnedColumns?.includes(columnId) || false,
      };
    });

    draftState.columnDisplay = newColumnDisplay as CompanyColumnDisplay;

    if (pinnedColumns?.length) {
      const pinnedColumnsList = getPinnedColumns(newColumnDisplay as CompanyColumnDisplay).split(
        ','
      );

      draftState.columnOrder = pinnedColumnsList.concat(
        draftState.columnOrder.filter(columnId => !draftState.columnDisplay[columnId].pinned)
      ) as ColumnKeys[];
    }
    // special case for dragging column header only
  } else if (!columnVisibility && !pinnedColumns && columnOrder) {
    draftState.columnOrder = columnOrder.split(',') as ColumnKeys[];
  }
};

export const saveColumnWidthReducer: CaseReducer<ColumnDisplay, PayloadAction<ColumnWidth>> = (
  draftState,
  action
) => {
  const { columnId, width } = action.payload;

  if (columnId && draftState.columnDisplay[columnId]) {
    draftState.columnDisplay[columnId].width = width;
  }
};
