import React, { useMemo } from 'react';
// components
import { PieChart } from '@optx/features/charts/PieChart';

const COLORS = ['#2AC8C8', '#FFC069', '#FF7A45'];

interface PieChartStatsProps {
  data: { label: string; value: number | null }[];
  totalCompany: number;
  handleRedirects?: (label: string) => void;
}

const PieChartStats: React.FC<PieChartStatsProps> = ({ data, totalCompany, handleRedirects }) => {
  const chartData = useMemo(
    () =>
      data.map((item, index) => {
        return {
          label: item.label,
          value: Number(item.value),
          color: item.label === 'Never' ? '#ddd' : COLORS[index],
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <div className="pie-chart-wrapper">
      <div className="pie-chart">
        <PieChart
          data={chartData}
          chartHeight={130}
          dataKey="value"
          innerRadius={55}
          outerRadius={65}
          paddingAngle={-5}
          cx="50%"
          cy="50%"
        />
        <span className="total">
          <b onClick={() => handleRedirects && handleRedirects('My Companies')}>{totalCompany}</b>My{' '}
          {totalCompany === 1 ? 'Company' : 'Companies'}
        </span>
      </div>
      <div className="pie-chart-legends">
        {chartData.map(item => (
          <button key={item.label} onClick={() => handleRedirects && handleRedirects(item.label)}>
            <span className="count" style={{ backgroundColor: item.color }}>
              {item.value}
            </span>
            <span className="label">{item.label}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default PieChartStats;
