import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';

const StyledWrapper = styled.div`
  padding-top: 100px;
  text-align: center;
`;

interface ErrorComponentProps {
  onRedirect?: VoidFunction;
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({ onRedirect }) => {
  const { advancedSearchPathname } = useIsAnalyst();

  return (
    <StyledWrapper>
      <h3>Something went wrong</h3>

      <Link to={advancedSearchPathname} onClick={onRedirect}>
        <Button size="small">Go Back </Button>
      </Link>
    </StyledWrapper>
  );
};

export default React.memo(ErrorComponent);
