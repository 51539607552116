import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// redux
import { actions, selectors } from '@redux/company/active';

interface useActiveEdiableFieldFunction {
  (isFromHistory?: boolean): {
    loading: boolean;
    companyId: number | null;
    addCompanyActive(companyId: number, isBusiness: boolean): void;
  };
}

const useActiveEditableField: useActiveEdiableFieldFunction = isFromHistory => {
  const loading = useSelector(selectors.isLoading);
  const companyId = useSelector(selectors.getCurrentCompany);
  const dispatch = useDispatch();

  const addCompanyActive = useCallback(
    (companyId: number, active: boolean) =>
      dispatch(actions.addCompanyActive({ companyId, active, isFromHistory })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  return { loading, companyId, addCompanyActive };
};

export default useActiveEditableField;
