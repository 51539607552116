import { SourcingOutreachColumnNamesKey } from '@optx/features/sourcing-outreach-summary';

export const LIST_OF_COLUMN_NAMES: { [K in SourcingOutreachColumnNamesKey]: string } = {
  calls: 'Calls Completed',
  companies_added: 'New Companies',
  companies_reached: 'Companies Touched',
  emails_sent: 'Emails Sent',
  past_due_touches: 'Companies with Incomplete Touches',
  qualified_opportunities_platform: 'Net New (QO)',
  stage_change: 'Stage Modified',
  stale_accounts: 'Stale Accounts',
  total_dtl: 'Owned Companies',
  stale_touches: 'Companies with Stale Touches',
  revived_touches: 'Revived',
  transferred_to_touches: 'Transferred To',
  transferred_from_touches: 'Transferred From',
  meetings: 'Meetings Completed',
  start_qo_touches: 'Start QO',
  end_qo_touches: 'End QO',
  number_touches: 'Touches',
  qualified_opportunities_addon: 'New Qualified Ops (Add-ons)',
};
