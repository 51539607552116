/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMount } from '@umijs/hooks';
import { ChartIntervalKeys, QuartersChart, SocialMediaChartItem } from '@optx/models/charts/data';
import Icon from '@components/common/Icon';
import LineChart from '../LineChart';
import { selectors as localSelectors, actions as localActions } from '../../state/website-rank';

interface WWWebsiteRankProps {
  companyId: number;
  chartInterval: ChartIntervalKeys;
}

const WWWebsiteRankChart: React.FC<WWWebsiteRankProps> = ({ companyId, chartInterval }) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line prefer-const
  let quarters: QuartersChart = {};

  useMount(() => {
    dispatch(localActions.fetchWWWebsiteRank({ companyId, usOnly: false }));
  });

  const chartData = useSelector(localSelectors.getData);

  const activeChartData = chartData[chartInterval];

  const outputDuplicateQuarters = activeChartData.data?.map((item: SocialMediaChartItem) => {
    if (!quarters[item.quarter]) {
      quarters[item.quarter] = true;

      return item;
    }

    return { ...item, quarter: '' };
  });

  const newActiveChartDataWithUniqueQuarters = {
    ...activeChartData,
    data: outputDuplicateQuarters,
  };

  const isAlexaDataPresented = activeChartData.data.some(item => item.source_db === 'ax');

  const referenceLineData = isAlexaDataPresented
    ? chartInterval === '3M' || chartInterval === '6M'
      ? activeChartData.data.filter(item => item.source_db === 'sw')[0]?.date
      : activeChartData.data.length !== 1
      ? activeChartData.data.findIndex(item => item.source_db === 'sw')
      : activeChartData.data.filter(item => item.source_db === 'sw')[0]?.quarter
    : undefined;

  const title = 'WW Website Rank ';

  return (
    <LineChart
      icon={<Icon iconName="website-rank-blue" className="chart-icon website-rank" />}
      dataKey="reversedValue"
      labelKey={chartInterval === '3M' || chartInterval === '6M' ? 'date' : 'quarter'}
      kpiLabel="Date"
      labelKeyAlt="date"
      title={title}
      referenceLineData={referenceLineData}
      chartInterval={chartInterval}
      data={newActiveChartDataWithUniqueQuarters}
      headerValue={activeChartData.best_rank as number}
      headerLabel="best rank"
      headerValueExtra={activeChartData.current_rank as number}
      headerLabelExtra="current rank"
      chartLabel="Rank over time"
      isReversed
      range={[
        1,
        `dataMax + ${
          referenceLineData !== undefined &&
          typeof activeChartData?.lowest_rank === 'number' &&
          activeChartData.lowest_rank / 2
        }`,
      ]}
      ticks={[1, activeChartData.lowest_rank as number]}
    />
  );
};

export default React.memo(WWWebsiteRankChart);
