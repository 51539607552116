import { Table } from 'antd';
import styled, { css } from 'styled-components';
import DefaultTable from '@shared/view/organims/Table';

const SourcingOutreachTableWrapper = styled.div<{
  externalScrollTopPosition: number | null;
}>`
  .ant-table-body {
    overflow: auto;
  }
  .ant-table-summary {
    border-bottom: 8px solid #f0f2f5;
  }

  .ant-table-sticky-scroll {
    display: none;
  }

  ${props =>
    props.externalScrollTopPosition &&
    css`
      .external-scroll-wrapper {
        top: ${props.externalScrollTopPosition}px;
      }
    `}
`;

const SourcingOutreachTable = styled(DefaultTable)`
  .ant-table-body tr {
    height: 47px;

    &.ant-table-row:hover {
      & > td {
        background-color: #fff;
      }
    }

    &.ant-table-row {
      & > td:hover {
        background-color: #e6f7ff;
      }
    }
  }
  .ant-table-cell {
    border: 1px solid #d9d9d9;
  }

  .ant-table-body {
    overflow-y: auto;
    max-height: none !important;

    td.user_name {
      cursor: auto !important;

      span {
        font-weight: 600;
        color: #334d6e;
      }
    }
  }

  tbody.ant-table-tbody {
    td:not(.user_name) {
      text-align: right;
      padding: 0;

      span {
        display: block;
        padding: 12px 8px;
        color: #096dd9;
      }
    }
  }

  .ant-table-thead {
    font-size: 13px;

    th.ant-table-cell.user_name {
      z-index: 6;
    }

    th.ant-table-cell {
      div.ant-table-column-sorters > span {
        color: #262626;
        font-weight: 600;
      }
    }
    .ant-table-column-title {
      position: static;
    }

    th.ant-table-cell.stale_accounts,
    th.ant-table-cell.revived_touches,
    th.ant-table-cell.qualified_opportunities_platform {
      position: relative;

      span.anticon.anticon-info-circle {
        display: inline-block;
        position: absolute;
        top: 6px;
        right: 6px;
      }
    }
    ant-table-column-has-sorters {
      padding-right: 10px;
    }
  }

  .ant-table-column-has-sorters .ant-table-column-sorter {
    margin-right: 0;
  }

  .ant-table-column-sorter {
    margin-right: 8px;
  }
`;

const TableFooter = styled(Table.Summary.Row)`
  position: sticky;
  bottom: 0;
  background-color: #074783;

  .ant-table-cell {
    padding: 0;
    color: #fff;

    span {
      display: block;
      padding: 16px 8px;
      transition: background-color 0.2s ease-in;

      &:hover {
        background-color: #2690db;
      }
    }
  }

  .ant-table-cell.total {
    background-color: #074783;
    cursor: auto !important;
  }

  .ant-table-cell:first-child {
    font-weight: 600;
  }

  .ant-table-cell:not(:first-child) {
    text-align: right;
  }
`;

export const Styled = {
  SourcingOutreachTable,
  SourcingOutreachTableWrapper,
  TableFooter,
};
