import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
import { CompanyTabLists } from '@optx/models/Company';

const { Text } = Typography;

const ListURL = styled.a`
  color: #1890ff;
  max-width: 90%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;

const TableCellListURL: React.FC = (value: any, record: CompanyTabLists) => {
  const urlPath = record.list_type === 'Watchlist' ? '/user-lists/' : '/ss-lists/';

  return (
    <>
      {value && record.clickable ? (
        <ListURL
          href={`${urlPath + record.unique_id}?title=${encodeURIComponent(record.title)}`}
          title={value}
          className="company-name__profile-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {value || DEFAULT_CELL_VALUE}
        </ListURL>
      ) : (
        <Text style={{ fontWeight: 600, cursor: 'default' }}>{value || DEFAULT_CELL_VALUE}</Text>
      )}
    </>
  );
};

export default TableCellListURL;
