import { useState, useEffect } from 'react';

const useNonVirtualizedTable = () => {
  const [virtualizedActive, setVirtualizedActive] = useState<boolean>(true);

  useEffect(() => {
    if (process.env.REACT_APP_API_ROOT?.search('dev-optx') !== -1) {
      if (window && (window as any).innerHeight > 29999) setVirtualizedActive(false);
      else {
        setVirtualizedActive(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerHeight]);

  return virtualizedActive;
};

export default useNonVirtualizedTable;
