import { takeLatest, call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { CompanyTouchesFiltersInterface } from './interfaces';
import { CompanyTouchesOptionsResponse } from '@optx/models/company/CompanyTouch';
import * as actions from './actions';
import { CompanyService } from '../../../../services/api';
import NotificationService from '../../../../services/NotificationService';

export function* fetchCompanyTouchesFiltersSaga(
  action: PayloadAction<number | CompanyTouchesFiltersInterface>
) {
  let companyId;

  if (typeof action.payload === 'number') {
    companyId = action.payload;
  } else {
    companyId = action.payload.companyId;
  }

  try {
    const res: AxiosResponse<CompanyTouchesOptionsResponse> = yield call(
      CompanyService.fetchTouchesFilters,
      companyId
    );
    res.data.currentInitiator =
      typeof action.payload === 'number' ? undefined : action.payload.currentInitiator;
    res.data.currentType =
      typeof action.payload === 'number' ? undefined : action.payload.currentType;
    yield put(actions.fetchTouchesFiltersSuccess(res.data));
  } catch (e: any) {
    const errorMessage = 'Error fetch company touches filters!';
    yield put(actions.fetchTouchesFiltersFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* touchesFiltersSaga() {
  yield takeLatest(actions.fetchTouchesFilters.type, fetchCompanyTouchesFiltersSaga);
}
