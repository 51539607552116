import React from 'react';
import { Typography } from 'antd';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { stringListToView } from '@optx/utils/format';

const TableCellStringList = (value: Array<string> | null) => {
  const viewValue = stringListToView(value, 'None');

  return viewValue ? (
    <Typography.Text title={viewValue} style={{ maxWidth: '100%' }} ellipsis>
      {viewValue}
    </Typography.Text>
  ) : (
    DEFAULT_EMPTY_VALUE
  );
};

export default TableCellStringList;
