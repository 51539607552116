import { Dictionary } from 'lodash';
import { createAction } from '@reduxjs/toolkit';
import { SortByRule } from '@optx/models/table/sorting';
import { PreselectedFilter } from '@optx/models/filters';
import { SuccessErrorCallback } from '@optx/models/callback';
import { FilterTag } from '@optx/models/tags';
import {
  AddonManagementRamoveTagPayload,
  AddonManagementResponse,
  RemoveCompanyFromAddonManagementPayload,
  AdditionalFiltersPayload,
  AdditionalFiltersResponse,
  AdditionalFiltersFailPayload,
  BulkRemoveAddon,
} from '@optx/redux/company/addon-management/interfaces';
import * as types from './actionTypes';
import { SearchPayload } from '@optx/models/api/contacts';
import { ResetFilter } from '@features/grid/search/state/interfaces';

export const fetchCompanyAddonManagement = createAction<{
  companyId: number;
  shouldResetPageNumber?: boolean;
  filter?: Dictionary<PreselectedFilter>;
}>(types.FETCH_COMPANY_ADDON_MANAGEMENT);
export const fetchCompanyAddonManagementSuccess = createAction(
  types.FETCH_COMPANY_ADDON_MANAGEMENT_SUCCESS,
  (response: AddonManagementResponse, searchPayload: SearchPayload) => ({
    payload: response,
    meta: searchPayload,
  })
);
export const fetchCompanyAddonManagementFail = createAction<string>(
  types.FETCH_COMPANY_ADDON_MANAGEMENT_FAIL
);

export const changeSortCompanyAddonManagement = createAction<Array<SortByRule<any>>>(
  types.CHANGE_SORT_COMPANY_ADDON_MANAGEMENT
);

export const bulkRemoveAddon = createAction<BulkRemoveAddon>(types.BULK_REMOVE_ADDON);

export const togglePortfolioCompanies = createAction<void>(types.TOGGLE_PORTFOLIO_COMPANIES);

export const clearSearch = createAction(
  types.CLEAR_ADDON_MANAGEMENT_SEARCH,
  (customClearedFilter?: Dictionary<PreselectedFilter>, callback?: SuccessErrorCallback) => ({
    payload: customClearedFilter,
    meta: callback,
  })
);

export const resetFilter = createAction<ResetFilter>(types.RESET_ADDON_MANAGEMENT_FILTER);
export const removeFilterTag = createAction<AddonManagementRamoveTagPayload>(
  types.REMOVE_ADDON_MANAGEMENT_FILTER_TAG
);

export const setSearchKey = createAction<string>(types.SET_SEARCH_KEY);
export const setSelectedFilters = createAction<Dictionary<PreselectedFilter>>(
  types.SET_SELECTED_FILTERS
);

// additional filters
export const fetchAdditionalFilters = createAction<AdditionalFiltersPayload>(
  types.FETCH_ADDITIONAL_FILTERS
);

export const fetchAdditionalFiltersSuccess = createAction<AdditionalFiltersResponse>(
  types.FETCH_ADDITIONAL_FILTERS_SUCCESS
);

export const fetchAdditionalFiltersFail = createAction<AdditionalFiltersFailPayload>(
  types.FETCH_ADDITIONAL_FILTERS_FAIL
);
export const updateAdditionalFilters = createAction<{
  key: string;
  value: PreselectedFilter;
  companyId: number;
  isPaginationChanged?: boolean;
  shouldResetPageNumber?: boolean;
}>(types.UPDATE_ADDITIONAL_FILTERS);

export const resetAdditionalFilter = createAction<FilterTag>(types.RESET_ADDITIONAL_FILTER);

export const removeCompanyFromAddonManagement =
  createAction<RemoveCompanyFromAddonManagementPayload>(types.REMOVE_COMPANY_FROM_ADDON_MANAGEMENT);

export const removeCompanyFromAddonManagementSuccess = createAction<{ companyId: number }>(
  types.REMOVE_COMPANY_FROM_ADDON_MANAGEMENT_SUCCESS
);

export const removeCompanyFromAddonManagementFail = createAction<string>(
  types.REMOVE_COMPANY_FROM_ADDON_MANAGEMENT_FAIL
);

export const clearAddonManagementFetchedAt = createAction(types.CLEAR_ADDON_MANAGEMENT_FETCHED_AT);
