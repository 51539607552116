import React, { useMemo, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Button } from 'antd';
import { PushpinOutlined } from '@ant-design/icons';
import { each, cloneDeep } from 'lodash';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// models
import { CompanyColumnDisplay, ColumnKeys, Column } from '@optx/models/table/Columns';
// utils
import { mapPinnedColumns, mapSortableColumns, restoreColumns } from '@optx/utils/columns';
// redux
import { selectors as userSelectors } from '@redux/user/information';
// components
import Icon from '@components/common/Icon';
import { TableColumnFilterContext } from '@optx/components/common/table/TableColumnFilterContext';
import Styled from './TableColumnFilter.styled';

const selectAll = (check: boolean, columns: CompanyColumnDisplay) => {
  const columnDisplay: CompanyColumnDisplay = cloneDeep(columns);

  each(columnDisplay, (value, columnId) => {
    if (!value.pinned) {
      columnDisplay[columnId as ColumnKeys].visible = check;
    }
  });

  return columnDisplay;
};

const reOrder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface TableColumnFilterProps {
  gridView?: JSX.Element;
  handlePin?: (columnId: ColumnKeys, shouldPin: boolean) => void;
  columnData: CompanyColumnDisplay<any>;
  columnOrder: any[];
  initialColumnState: CompanyColumnDisplay<any>;
  initialColumnOrder: any[];
}

const TableColumnFilter: React.FC<TableColumnFilterProps> = ({
  gridView,
  handlePin,
  columnData,
  columnOrder,
  initialColumnState,
  initialColumnOrder,
}) => {
  const {
    columnDisplay,
    setColumnDisplay,
    sortableColumns,
    setSortableColumns,
    pinnedColumns,
    setPinnedColumns,
  } = useContext(TableColumnFilterContext);

  const defaultOptxScore = useSelector(userSelectors.getDefaultScore);

  const handleSelect = (e: CheckboxChangeEvent) => {
    const column = columnDisplay[e.target.name! as ColumnKeys];
    setColumnDisplay({
      ...columnDisplay,
      [column.id]: { ...column, visible: e.target.checked },
    });
  };

  const handleSelectAll = (e: CheckboxChangeEvent) => {
    setColumnDisplay(selectAll(e.target.checked, columnDisplay));
  };

  const handleClear = () => {
    const newColumns = selectAll(false, columnDisplay);
    setColumnDisplay(newColumns);
  };

  const handleRestore = (e: React.MouseEvent<HTMLButtonElement>) => {
    // focus is continue after click to button. blur() is used to remove focus
    e.currentTarget.blur();
    setColumnDisplay(restoreColumns(columnData, initialColumnState));
    setPinnedColumns &&
      setPinnedColumns(
        mapPinnedColumns(initialColumnState, initialColumnOrder, defaultOptxScore as string)
      );
    setSortableColumns(
      mapSortableColumns(initialColumnState, initialColumnOrder, defaultOptxScore as string)
    );
  };

  useEffect(() => {
    setColumnDisplay(columnData);
    setPinnedColumns &&
      setPinnedColumns(mapPinnedColumns(columnData, columnOrder, defaultOptxScore as string));
    setSortableColumns(mapSortableColumns(columnData, columnOrder, defaultOptxScore as string));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnData]);

  const checkAll = useMemo(() => {
    let isChecked = true;

    each(columnDisplay, (value, columnId) => {
      if (!value.visible) {
        isChecked = false;

        return false;
      }

      return undefined;
    });

    return isChecked;
  }, [columnDisplay]);

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reOrder(sortableColumns, result.source.index, result.destination.index);

    setSortableColumns(newItems as Column[]);
  };

  const handleIsraelScoreColumn = (columnName: string) => {
    if (~columnName.search('il_optx_score')) {
      if (defaultOptxScore !== 'il') {
        return true;
      }

      return false;
    }

    if (~columnName.search('score')) {
      if (defaultOptxScore !== 'us') {
        return true;
      }
    }

    return false;
  };

  return (
    <>
      <Styled.ColumnHeader>
        {gridView && gridView}
        <Button onClick={handleRestore}>Restore Default</Button>
      </Styled.ColumnHeader>
      <Styled.ColumnWrapper className="select-all">
        <div>
          <Checkbox checked={checkAll} onChange={handleSelectAll}>
            Select All
          </Checkbox>
        </div>
        <div>
          <Button type="link" onClick={handleClear}>
            CLEAR ALL
          </Button>
        </div>
      </Styled.ColumnWrapper>
      {pinnedColumns &&
        pinnedColumns.map((column, index) => {
          const pinned = column.pinned;

          return (
            <Styled.ColumnWrapper key={index} className={pinned ? 'filtered' : ''}>
              <Checkbox
                checked={columnDisplay[column.id].visible}
                name={column.id as string}
                onChange={handleSelect}
                disabled={pinned}
              >
                {column.name}
              </Checkbox>
              {handlePin && (
                <PushpinOutlined
                  className="pin"
                  onClick={() => handlePin(column.id as ColumnKeys, false)}
                />
              )}
            </Styled.ColumnWrapper>
          );
        })}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {sortableColumns.map((column: any, index: any) =>
                handleIsraelScoreColumn(column.id as string) === true ? null : (
                  <Draggable key={column.id} draggableId={column.id} index={index}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <Styled.ColumnWrapper key={index}>
                          <Checkbox
                            checked={columnDisplay[column.id as string].visible}
                            name={column.id as string}
                            onChange={handleSelect}
                            disabled={handleIsraelScoreColumn(column.id as string)}
                          >
                            {column.name}
                          </Checkbox>
                          {handleIsraelScoreColumn(column.id as string) === true
                            ? null
                            : handlePin && (
                                <PushpinOutlined
                                  className="pin"
                                  onClick={() => handlePin(column.id, true)}
                                />
                              )}

                          <div {...provided.dragHandleProps}>
                            <Icon iconName="drag-and-drop" className="handle" />
                          </div>
                        </Styled.ColumnWrapper>
                      </div>
                    )}
                  </Draggable>
                )
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default TableColumnFilter;
