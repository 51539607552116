import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'antd';
import { TableProps } from 'antd/es/table';
// models
import { SortByRule } from '@optx/models/table/sorting';
import { TableChangeCallback } from '@optx/models/table/antd-table';
import { SourcingOutreachUserData } from '@optx/features/sourcing-outreach-summary/models/SourcingOutreach';
// utils
import { mapSorting } from '@optx/utils/table/sorting';
import { generateUniqueKey } from '@optx/utils/uuid';
// redux
import { selectors, actions, pageLoading } from '@optx/features/sourcing-outreach-summary/state';
// hooks
import { useSize } from '@umijs/hooks';
// components
import ExternalScroll from '@components/common/external-scroll';
import { SourcingOutreachTableFooter } from './SourcingOutreachTableFooter';
import { Styled } from './SourcingOutreachTable.styled';

const SourcingOutreachTable: React.FC<TableProps<any>> = props => {
  const dispatch = useDispatch();
  const data = useSelector(selectors.search.data);
  const columns = useSelector(selectors.sort.getGridColumns);
  const sortBy = useSelector(selectors.sort.sortBy);
  const loading = useSelector(pageLoading);
  const tableRef = useRef<HTMLDivElement>(null);
  const [externalScrollTopPosition, setExternalScrollTopPosition] = useState<number | null>(null);

  const handleChange: TableChangeCallback<SourcingOutreachUserData> = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    switch (extra.action) {
      case 'sort': {
        const { sortBy: sortByVal } = mapSorting(sorter, sortBy);
        dispatch(actions.sort.sort(sortByVal as Array<SortByRule<any>>));
        break;
      }

      default:
        break;
    }
  };

  const [tableSize] = useSize(
    () => document.querySelector('.sourcing-outreach-table table') as HTMLElement
  );
  const contentRef = useRef<HTMLDivElement | null>(
    tableRef.current?.querySelector('.ant-table-body') || null
  );

  if (!contentRef.current && tableRef.current) {
    contentRef.current = tableRef.current.querySelector('.ant-table-body') || null;
  }

  const tableSummaryElement = document.getElementsByClassName('ant-table-summary')[0];

  window.addEventListener('resize', () => {
    if (tableSummaryElement) {
      const tableSummaryPositionDetails = tableSummaryElement?.getBoundingClientRect();
      setExternalScrollTopPosition(tableSummaryPositionDetails.top - 20);
    }
  });

  useEffect(() => {
    const tableSummary = document.querySelector('.ant-table-summary');

    if (tableSummary) {
      const tableSummaryPositionDetails = tableSummary?.getBoundingClientRect();
      setExternalScrollTopPosition(tableSummaryPositionDetails.top - 20);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableSummaryElement, tableSummaryElement?.getBoundingClientRect(), data.length]);

  return (
    <Styled.SourcingOutreachTableWrapper externalScrollTopPosition={externalScrollTopPosition}>
      {data.length && !loading ? (
        <ExternalScroll refElement={contentRef} contentWidth={tableSize.width} isVirtualGrid />
      ) : null}
      <div ref={tableRef}>
        <Styled.SourcingOutreachTable
          columns={columns}
          dataSource={data}
          className="legacy-table sourcing-outreach-table"
          rowKey={generateUniqueKey}
          onChange={handleChange}
          sticky
          pagination={false}
          bordered
          showSorterTooltip={false}
          tableLayout="fixed"
          summary={() =>
            data.length ? (
              <Table.Summary fixed>
                <SourcingOutreachTableFooter />
              </Table.Summary>
            ) : undefined
          }
        />
      </div>
    </Styled.SourcingOutreachTableWrapper>
  );
};

export default React.memo(SourcingOutreachTable);
