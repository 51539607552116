import React from 'react';

import { Switch, Row } from 'antd';
import styled from 'styled-components';

const TitleWrapper = styled(Row)`
  padding-right: 35px;
  .label {
    margin-left: 12px;
    font-size: 14px;
    font-weight: normal;
  }
  .switch_wrapper {
    display: flex;
    align-items: center;
  }
`;

export const AddContactHeader: React.FC<{
  title: string;
  handleChange: (checked: boolean) => void;
  isPrimary?: boolean;
}> = ({ handleChange, title, isPrimary }) => {
  return (
    <TitleWrapper justify="space-between" align="middle">
      <span>{title}</span>
      <div className="switch_wrapper">
        <Switch checked={isPrimary} onChange={(checked, e) => handleChange(checked)} />

        <span className="label">Primary Contact</span>
      </div>
    </TitleWrapper>
  );
};
