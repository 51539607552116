import { CaseReducer, PayloadAction, createReducer } from '@reduxjs/toolkit';
// models
import { RelationshipManagement, RelationshipManagementState } from './interfaces';
// redux
import { actions } from '.';

const initialState: RelationshipManagementState = {
  data: null,
  loading: false,
  error: '',
};

const fetchRelationshipManagementReducer: CaseReducer<RelationshipManagementState> = draftState => {
  draftState.loading = true;
  draftState.error = '';
};

const fetchRelationshipManagementSuccessReducer: CaseReducer<
  RelationshipManagementState,
  PayloadAction<RelationshipManagement>
> = (draftState, action) => {
  draftState.loading = false;
  draftState.data = action.payload;
};

const fetchRelationshipManagementFailReducer: CaseReducer<
  RelationshipManagementState,
  PayloadAction<string>
> = (draftState, action) => {
  draftState.loading = false;
  draftState.error = action.payload;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.fetchRelationshipManagement, fetchRelationshipManagementReducer)
    .addCase(actions.fetchRelationshipManagementSuccess, fetchRelationshipManagementSuccessReducer)
    .addCase(actions.fetchRelationshipManagementFail, fetchRelationshipManagementFailReducer)
);

export default reducer;
