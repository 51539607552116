/**
 * This file builds what we use now as structure for My Companies cards.
 *
 * Currently, we display four cards where:
 * - two of them should display a graph bar and
 * - the other two should display two rows with two columns each.
 *
 * Since both type of cards require different colors for fonts and graphics, it was necessary
 * to create something to map these changes.
 *
 * Both buildValues() and buildGraphicValues() generate structures for the cards content area.
 */

import { Dictionary, isEmpty, values as _values, keys, each } from 'lodash';
// models
import { LogicOption } from '@optx/models/Option';
import { FilterSource } from '@optx/models/filters';
import { SearchStateData } from '@models/search';
// constants
import { ConvertFromAPIToText, cardTitles } from '../constants/my-companies';
// components
import {
  MyCompaniesIconType,
  MyCompaniesCardDataProps,
} from '../components/common/cards/MyCompaniesCard/interface';

const colorMap: Dictionary<Dictionary<string>> = {
  no_touches: { value: '#90a0b7', graph: '#c2cfe0' },
  more_than_6_months_ago: { value: '#ff4d4f', graph: '#ff7875' },
  in_more_than_6_months: { value: '#ff4d4f', graph: '#ff7875' },
  '6_weeks_to_6_months_ago': { value: '#fa8c16', graph: '#ffa940' },
  in_6_weeks_to_6_months: { value: '#fa8c16', graph: '#ffa940' },
  within_6_weeks: { value: '#13c2c2', graph: '#13c2c2' },
};

const icons: Array<MyCompaniesIconType> = [
  'AlertOutlined',
  'ClockCircleOutlined',
  'StarOutlined',
  'CalendarOutlined',
];

/**
 * The buildValues() function creates the structure for the card with columns.
 * This function will return the following structure:
 * {
 *  label: string;
 *  value: number;
 *  className: string; (optional)
 *  valueProps: string; (optional)
 * }
 */
const buildValues = (values: Array<Dictionary<number>>) => {
  return values.map((value: Dictionary<number>, index: number) => {
    return {
      label: ConvertFromAPIToText[keys(value)[0]],
      value: _values(value)[0] || 0,
      valueProps: {
        style: { color: index === 0 ? '#ff4d4f' : '' },
      },
      className: index !== 0 ? 'kpi--default' : '',
    };
  });
};

/**
 * The buildFilters() function creates the structure for the card subfilters.
 * The label will be sent to the API to filter
 */
const buildFilters = (filters: Dictionary<string>) => {
  const filtersArray = [];

  for (const filter of Object.entries(filters)) {
    filtersArray.push({
      label: filter[0],
      value: filter[1],
    });
  }

  return filtersArray;
};

/**
 * Get the currentValue and transform into a valid value.
 */
const getGraphValue = (value: number) => {
  const graphValueCalc = value === 1 ? 1 : parseFloat(Math.log(value).toFixed(2));
  const currentGraphValue =
    graphValueCalc === -Infinity || graphValueCalc === Infinity ? 0 : graphValueCalc;

  return currentGraphValue;
};

/**
 * Map the values for the cards that use barchart as template.
 */
const mapGraphValues = (values: Array<Dictionary<number>>, graphTotal: number) => {
  return values.map((value: Dictionary<number>) => {
    const currentItemKey = keys(value)[0];
    const currentValue = _values(value)[0] || 0;
    const currentGraphValue = getGraphValue(currentValue);

    // calc the percentage of each value to use in the bar chart
    const graphValuePercentOfTotal = Math.floor((currentGraphValue * 100) / graphTotal);
    const graphValue = isNaN(graphValuePercentOfTotal) ? 0 : graphValuePercentOfTotal;

    return {
      label: ConvertFromAPIToText[currentItemKey],
      value: currentValue,
      valueProps: { style: { color: colorMap[currentItemKey].value } },
      graphValue,
      graphColor: colorMap[currentItemKey].graph,
    };
  });
};

/**
 * The buildGraphicValues() creates the structure for the card with a bar chart.
 *
 * The rules for bar chart are:
 * - If there is value, the respective color shall be shown in the bar chart;
 * - If it's 0, then the respective color shall not be displayed;
 *
 * For the bar chart, it is being used the <Progress /> component from Ant Design,
 * that's why it is needed to calculate a percentage value in order to fill all props
 * from the Progress component.
 *
 * Note that the value color and the bar chart color are different.
 *
 * The structure returned will be:
 * {
 *  label: string;
 *  value: number;
 *  valueStyle: Dictionary<string>;
 *  graphValue: number;
 *  graphColor: string;
 * }
 */
const buildGraphicValues = (values: Array<Dictionary<number>>) => {
  // calculate the total based on the sum of each graph value
  const graphTotal = values
    .map(value => {
      return getGraphValue(_values(value)[0] || 0);
    })
    .reduce((previousValue, currentValue) => previousValue + currentValue);

  return mapGraphValues(values, graphTotal);
};

/**
 * Map the cards list and construct the card using the new structure.
 */
const mapCardsList = (cardsKeys: Array<string>, cardsValues: Array<SearchStateData>) => {
  return cardsValues.map((card, index) => {
    const finalCard: MyCompaniesCardDataProps = {
      id: cardsKeys[index],
      title: card.title,
      filters: buildFilters(card.data),
      secondTitle: `${card.total} ${card.total === 1 ? 'Company' : 'Companies'}`,
      values:
        index === 0 || index === 1 ? buildGraphicValues(card.values) : buildValues(card.values),
      icon: icons[index],
    };

    if (index === 0 || index === 1) {
      finalCard.description = 'Touch recency across companies';
      finalCard.variation = 'barchart';
    } else {
      finalCard.variation = 'columns';
    }

    if (index === 0) {
      finalCard.colSizeFirstBigger = true;
    }

    if (index === 1) {
      finalCard.colSizeFirstBigger = false;
    }

    return finalCard;
  });
};

/**
 * This function getMyCompaniesCardsList() returns an array of cards with the defined specs.
 * For the My Companies Dashboard it needs the use of different icons for each card,
 * different titles, different value for sub-titles, different descriptions (or non-existent) and
 * different content structure (be it chart bar or columns).
 *
 * For some cards there were added a property colSizeFirstBigger to inform the size of each column.
 */
export const getMyCompaniesCardsList = (cards: Dictionary<SearchStateData>) => {
  const cardsKeys = keys(cards);
  const cardsValues = _values(cards);

  if (cardsKeys.length) {
    const cardsList: Array<MyCompaniesCardDataProps> = mapCardsList(cardsKeys, cardsValues);

    return cardsList;
  }

  return [];
};

/**
 * process sub filters for each card
 * @param {Dictionary<SearchStateData>} data card related data coming from api
 */
export const processCardList = (data: Dictionary<SearchStateData>) => {
  const cardsList = { ...data };

  for (const cardKey in cardsList) {
    if (cardsList) {
      const values = _values(cardsList[cardKey].values);

      cardsList[cardKey] = {
        ...cardsList[cardKey],
        values,
        title: cardTitles[cardKey],
      };
    }
  }

  return cardsList;
};

/**
 * parse source tag filter from a an array of values
 * @param {Array<LogicOption>} base object for source tag filter
 * @param {string[]} filterValues values for the filter
 */
export const parseSourceTagFilter = (base: Array<LogicOption>, filterValues: string[]) => {
  const values: Dictionary<boolean> = {};

  filterValues.forEach(item => {
    values[item] = true;
  });

  return base.map((option: any) => {
    const finalOption = { ...option };

    if (values[`${option.value}-1-0`]) {
      finalOption.in = true;
    }

    if (values[`${option.value}-0-1`]) {
      finalOption.notIn = true;
    }

    return finalOption;
  });
};

/**
 * get corresponding company owner using the current user's display name
 * @param {Array<FilterSource>} filterSources filter data from api
 * @param {string} userName current user display name
 */
export const getCompanyOwner = (filterSources: Array<FilterSource>, userName: string) => {
  let companyOwner: Array<{ value: string; label: string }> | undefined;

  each(filterSources, (source, index) => {
    each(source.data, (filter, index) => {
      if (filter.column === 'company_owner_id') {
        const owner = filter.data.find(
          (owner: { name: string; value: string }) => owner.name === userName
        );

        if (owner) {
          companyOwner = [{ value: owner.value, label: owner.name }];
        }

        return false;
      }

      return true;
    });

    if (companyOwner) {
      return false;
    }

    return true;
  });

  return companyOwner;
};

export const myCompaniesCardsFilters = (
  selectedCard: SearchStateData | null,
  { nextTouchDate = [], lastTouchDateDays = [], stage = [] }
): Dictionary<string | Array<number | string>> => {
  if (!selectedCard) return {};

  if (selectedCard.id === 'card1') {
    return {
      next_touch_date: isEmpty(nextTouchDate) ? selectedCard.filter.next_touch_date : nextTouchDate,
      last_touch_date_days: isEmpty(lastTouchDateDays)
        ? selectedCard.filter.last_touch_date_days
        : lastTouchDateDays,
      stage: isEmpty(stage) ? selectedCard.filter.stage : stage,
    };
  }

  return {};
};
