import React, { SyntheticEvent } from 'react';
import { Resizable, ResizableProps, ResizeCallbackData } from 'react-resizable';

const maxLimit = window.innerWidth / 2;
// @ts-ignore
interface ResizeColumnProps extends ResizableProps {
  handleResizeStart: VoidFunction;
  handleResizeStop: (e: SyntheticEvent, data: ResizeCallbackData) => void;
  onResize: (e: React.SyntheticEvent, data: ResizeCallbackData) => any;
  width: number;
}

export const ResizeColumn: React.FC<ResizeColumnProps> = ({
  handleResizeStop,
  handleResizeStart,
  onResize,
  width,
  ...restProps
}) => {
  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <span
          role="presentation"
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        />
      }
      onResize={onResize}
      onResizeStart={handleResizeStart}
      onResizeStop={(e, data) => handleResizeStop(e, data)}
      draggableOpts={{ enableUserSelectHack: false }}
      minConstraints={[50, 50]}
      maxConstraints={[maxLimit, maxLimit]}
      axis="x"
      resizeHandles={['e']}
    >
      <th {...restProps} />
    </Resizable>
  );
};
