import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Tabs } from 'antd';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
// styles
import './style.scss';
// models
import { CompanyProfile } from '@optx/models/Company';
// constants
import { CompanyProfileTabs } from '@optx/constants/routes';
// redux
import { actions as fileActions, selectors as fileSelectors } from '@redux/company/files';
import { actions as listActions } from '@redux/company/lists';
import { actions as historyActions } from '@features/company/audit-trail/state';
// hooks
import { History } from '@features/company/merges';
import { SocialMedia } from '@optx/features/company/social-media';
import { useLocationAwareTabs } from '@optx/common/hooks/tabs';
// components
import DocumentUploadModal from '@optx/features/add-touch/components/DocumentUploadModal';
import ProfileOverview from './ProfileOverview';
import DealPresentation from './DealPresentation';
import NotesAndTouches from './NotesAndTouches';
import Files from './Files';
import Lists from './Lists';
import NewsFeed from './NewsFeed';
import AddonGrid from './Addon';

const { TabPane } = Tabs;

interface ProfileTabsProps {
  company: CompanyProfile;
}

const ProfileTabs: React.FC<ProfileTabsProps> = ({ company }) => {
  const location = useLocation();
  const { tab } = queryString.parse(location.search);
  const [activeKey, setActiveKey] = useState(
    (tab as string) || CompanyProfileTabs.PROFILE_OVERVIEW
  );
  const { defaultActiveKey, onChange } = useLocationAwareTabs({
    defaultActiveKey: CompanyProfileTabs.PROFILE_OVERVIEW,
    activeKey: activeKey,
    setActiveKey: setActiveKey,
  });
  const fetchFilesForCompanyId = useSelector(fileSelectors.fetchForCompanyId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fetchFilesForCompanyId && fetchFilesForCompanyId !== company.company_id) {
      dispatch(fileActions.clearFilesData());
    }
  }, [company.company_id, dispatch, fetchFilesForCompanyId]);

  useEffect(() => {
    dispatch(historyActions.clearCompanyFields());

    return () => {
      dispatch(listActions.clearCompanyTabList());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Tabs
        animated={false}
        activeKey={activeKey}
        defaultActiveKey={defaultActiveKey}
        onChange={key => {
          setActiveKey(key);
          onChange(key);
        }}
      >
        <TabPane tab="Overview" key={CompanyProfileTabs.PROFILE_OVERVIEW}>
          <ProfileOverview company={company} />
        </TabPane>
        <TabPane tab="Relationship Management" key={CompanyProfileTabs.RELATIONSHIP_MANAGEMENT}>
          <NotesAndTouches activeProfileKey={activeKey} />
        </TabPane>
        <TabPane tab="Proprietary Info" key={CompanyProfileTabs.PROPRIETARY_INFO}>
          <DealPresentation company={company} />
        </TabPane>
        <TabPane tab="Add-on MGMT" key={CompanyProfileTabs.ADDON_MGMT}>
          <AddonGrid companyId={company.company_id} />
        </TabPane>
        <TabPane tab="Lists" key={CompanyProfileTabs.LISTS}>
          <Lists companyId={company.company_id} activeKey={activeKey} />
        </TabPane>
        <TabPane tab="Social Media" key={CompanyProfileTabs.SOCIAL_MEDIA}>
          <SocialMedia companyId={company.company_id} />
        </TabPane>
        <TabPane tab="History" key={CompanyProfileTabs.HISTORY}>
          <History companyId={company.company_id} activeProfileKey={activeKey} />
        </TabPane>
        <TabPane tab="Files" key={CompanyProfileTabs.FILES}>
          <Files companyId={company.company_id} isInET={company.is_in_et} />
        </TabPane>
        <TabPane tab="News Feed" key={CompanyProfileTabs.NEWS_FEED}>
          <NewsFeed company={company} />
        </TabPane>
      </Tabs>
      <DocumentUploadModal />
    </>
  );
};

export default ProfileTabs;
