import React from 'react';
import { TooltipProps } from 'recharts';

import { PipelineInterval } from '@optx/models/feature/analystsLeaderboard/PipelineInformation';
import StyledTooltip from '@optx/components/common/charts/Tooltip.styles';

type OptionKey = keyof PipelineInterval;

export const TooltipContent = (props: TooltipProps) => {
  const { active, payload, separator, labelStyle } = props;

  if (active && payload && payload.length) {
    const tooltipPayload = payload[0];
    const data = tooltipPayload.payload as PipelineInterval;
    const dataKey = tooltipPayload.dataKey as OptionKey;

    return (
      <StyledTooltip.Wrapper>
        <StyledTooltip.Label style={{ ...labelStyle }}>{data.label}</StyledTooltip.Label>
        <div style={{ color: tooltipPayload.color }}>
          {tooltipPayload.name}
          {separator}
          {data[dataKey]}
        </div>
      </StyledTooltip.Wrapper>
    );
  }

  return null;
};
