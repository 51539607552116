import { createAction } from '@reduxjs/toolkit';
// models
import { FilterSource } from '@optx/models/filters';
// Local
// models
import { UpdateValuePayload, FilterMeta } from './interfaces';
// types
import * as types from './types';

// fetch
export const fetch = createAction(types.FETCH);
export const fetchSuccess = createAction<Array<FilterSource>>(types.FETCH_SUCCESS);
export const fetchFail = createAction<string>(types.FETCH_FAIL);
// change
export const clearAllFilters = createAction(types.CLEAR_ALL_FILTERS);
export const clearFilter = createAction<FilterMeta>(types.CLEAR_FILTER);
export const updateValue = createAction<UpdateValuePayload>(types.UPDATE_VALUE);
