import { createAction } from '@reduxjs/toolkit';
import { ExtensionProfileResponse } from './interface';
import * as types from './types';

export const fetchExtensionCompanies = createAction(
  types.FETCH_EXTENSION_COMPANIES,
  (url: string, callback?: VoidFunction) => ({
    payload: url,
    meta: callback,
  })
);

export const fetchExtensionCompaniesSuccess = createAction<ExtensionProfileResponse[]>(
  types.FETCH_EXTENSION_COMPANIES_SUCCESS
);

export const fetchExtensionCompaniesFail = createAction<string>(
  types.FETCH_EXTENSION_COMPANIES_FAIL
);

export const clearExtensionCompanies = createAction(types.CLEAR_EXTENSION_COMPANIES);
