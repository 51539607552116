import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { reducers as fetchReducers } from '@redux/feature/fetch';

import * as actions from './actions';
import { EditOpportunityType, OpportunityTypeState } from './interfaces';

export const initialState: OpportunityTypeState = {
  companyId: null,
  error: null,
  loading: false,
};

const addOpportunityType: CaseReducer<OpportunityTypeState, PayloadAction<EditOpportunityType>> = (
  draftState,
  action
) => {
  const { companyId } = action.payload;
  draftState.companyId = companyId;
  draftState.loading = true;
};

const addOpportunityTypeSuccess: CaseReducer<
  OpportunityTypeState,
  PayloadAction<EditOpportunityType>
> = (draftState, action) => {
  draftState.loading = false;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.addOpportunityType, addOpportunityType)
    .addCase(actions.addOpportunityTypeSuccess, addOpportunityTypeSuccess)
    .addCase(actions.addOpportunityTypeFail, fetchReducers.fetchFailReducer)
);

export default reducer;
