import React from 'react';
import styled from 'styled-components';
import Icon from '../../../Icon';

const ThumbsIcon = styled(Icon)`
  width: 16px;
  height: 16px;
`;

const ThumbsUpIcon = styled(ThumbsIcon)`
  color: #52c41a;
`;

export const TableCellFit = (value: boolean) => {
  let label: string | React.ReactNode = '';

  if (value === true) {
    label = <ThumbsUpIcon iconName="thumbs-up" />;
  } else if (value === false) {
    label = <ThumbsIcon iconName="thumbs-down" />;
  } else {
    label = 'Unknown';
  }

  return <>{label}</>;
};
