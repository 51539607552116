import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
// models
import { CompanyFundingRounds } from '@optx/models/Company';
import { CompanyFundingRoundsState } from './interfaces';
import { EditFieldSuccess } from '@optx/features/company/edit-fields/state/interfaces';
// constants
import { INITIAL_STATE_BAR_CHART } from '@constants/charts';
// redux
import { fetchReducer, fetchSuccessReducer, fetchFailReducer } from '@redux/feature/fetch/reducers';
import * as actions from './actions';
import { actions as editFieldsActions } from '@features/company/edit-fields/state';

const initialState: CompanyFundingRoundsState = {
  error: '',
  loading: false,
  data: {
    total_raised: '0',
    number_of_rounds: 0,
    total_investors: 0,
    last_round: null,
    investment_history: [],
    funding_rounds_graph: INITIAL_STATE_BAR_CHART,
    funding_types: [],
    graph_message: '',
  },
};

const companyFundingRoundsSuccessReducer: CaseReducer<
  CompanyFundingRoundsState,
  PayloadAction<CompanyFundingRounds>
> = (draftState, action) => {
  fetchSuccessReducer(draftState, action);
  draftState.data = action.payload;
};

const updateFieldsSuccessReducer: CaseReducer<
  CompanyFundingRoundsState,
  PayloadAction<EditFieldSuccess>
> = (draftState, action) => {
  const { value } = action.payload.value as { value: string };

  draftState.data.total_raised = value;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.getCompanyFundingRounds, fetchReducer)
    .addCase(actions.getCompanyFundingRoundsSuccess, companyFundingRoundsSuccessReducer)
    .addCase(actions.getCompanyFundingRoundsFail, fetchFailReducer)
    .addCase(editFieldsActions.updateFieldsSuccess, updateFieldsSuccessReducer)
);

export default reducer;
