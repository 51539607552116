import React, { useMemo, useState, useEffect, useContext } from 'react';
import { Slider, Checkbox } from 'formik-antd';
import { Row, Col } from 'antd';
import { useField } from 'formik';
import { SliderMarks } from 'antd/lib/slider';
import { Dictionary } from 'lodash';
import numeral from 'numeral';
// models
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RangeFilter } from '@optx/models/filters';
import { RangeOptionBase } from '@optx/models/RangeOption';
import { FormCheckableRangeOption, SelectOption } from '@optx/models/Option';
// utils
import {
  parseNumberFromAbbreviation,
  isNegative,
  convertToPositive,
  logarithmNumber,
} from '@utils/number';
// components
import Histogram from '@optx/components/common/histogram';
import RangeInputNumber from './RangeInputNumber';
import { FiltersContext } from '../../FiltersContext';

interface FilterRangeLogContentProps {
  filter: RangeFilter<Array<RangeOptionBase>>;
  index: number;
}

// generate the logarithm scale
const logarithmScaleNumber = (maxlval: number, minlval: number, maxpos: number, minpos: number) =>
  (maxlval - minlval) / (maxpos - minpos);

// this whole function has the whole logic to convert a logarithm number into a valid number.
// it is used when the user change the value using the slider.
const getValueFromLogarithm = (
  value: number,
  minPosition: number,
  maxPosition: number,
  minLogarithmVal: number,
  maxLogarithmVal: number,
  marksList?: Array<Dictionary<number>>
) => {
  // use these values to calculate the scale and the position of the selected value
  const minpos = convertToPositive(minPosition);
  const maxpos = convertToPositive(maxPosition);

  const minlval = convertToPositive(minLogarithmVal);
  const maxlval = convertToPositive(maxLogarithmVal);

  // calc the scale
  const scale = logarithmScaleNumber(maxlval, minlval, maxpos, minpos);

  // gets the real value of the logarithm position
  let valueMath = Math.exp((convertToPositive(value) - minpos) * scale + minlval);
  valueMath = isNegative(value) ? -Math.abs(valueMath) : valueMath;

  // if it has marks, it calcs an approx. position of the value and the dot.
  if (marksList) {
    // it calcs the range of the value
    const newMinPosition = marksList.filter(mark => {
      return Math.abs(value - mark.index) <= 0.3;
    });

    // if it finds only one mark, change it, otherwise, keep current value
    if (newMinPosition.length === 1) {
      valueMath = newMinPosition[0].mark;
    }
  }

  return valueMath;
};

const getLogarithmFromValue = (
  value: number,
  minPosition: number,
  maxPosition: number,
  minLogarithmVal: number,
  maxLogarithmVal: number
) => {
  const scale = logarithmScaleNumber(maxLogarithmVal, minLogarithmVal, maxPosition, minPosition);

  return minPosition + (logarithmNumber(value) - minLogarithmVal) / scale;
};

const FilterRange: React.FC<FilterRangeLogContentProps> = ({ filter, index }) => {
  const fieldKey = `${filter.column}[${index}]`;
  const [field, , helpers] = useField<FormCheckableRangeOption>(fieldKey);
  const [minField, , minFieldHelpers] = useField<number | undefined>(`${fieldKey}.range[0]`);
  const [maxField, , maxFieldHelpers] = useField<number | undefined>(`${fieldKey}.range[1]`);
  const { onManualFilterChange } = useContext(FiltersContext);

  // Saves the initial min and max values of each slider
  const [initialMaxValueLog, setInitialMaxValueLog] = useState(0);
  const [initialMaxValue, setInitialMaxValue] = useState<number>(0);
  const [initialMinValueLog, setInitialMinValueLog] = useState(0);
  const [initialMinValue, setInitialMinValue] = useState<number>(0);

  // Saves the current slider range values
  const [currentSlideValue, setCurrentSlideValue] = useState<[number, number]>([0, 0]);

  const [marksList, setMarksList] = useState<Array<Dictionary<number>>>([]);

  const formatter = useMemo(
    () =>
      filter.is_formatted
        ? (value: string | number | undefined) => numeral(value).format('0a')
        : undefined,
    [filter]
  );
  const parser = useMemo(
    () => (filter.is_formatted ? parseNumberFromAbbreviation : undefined),
    [filter]
  );

  useEffect(() => {
    /* These variables get and save the real min and max values from the filter API */
    const filterInitialMin = field.value.min === 0 ? 0 : logarithmNumber(field.value.min);
    const filterInitialMax = field.value.max === 0 ? 0 : logarithmNumber(field.value.max);

    setInitialMinValueLog(filterInitialMin);
    setInitialMinValue(parseFloat(filterInitialMin.toFixed(1)));

    setInitialMaxValueLog(filterInitialMax);
    setInitialMaxValue(parseFloat(filterInitialMax.toFixed(1)));

    /* These variables get and save the min and max values selected in the slider */
    const logMinValue = field.value.range[0] === 0 ? 0 : logarithmNumber(field.value.range[0]);
    const logMaxValue = field.value.range[1] === 0 ? 0 : logarithmNumber(field.value.range[1]);

    setCurrentSlideValue([parseFloat(logMinValue.toFixed(1)), parseFloat(logMaxValue.toFixed(1))]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* This function defines the dots/marks in the slider. */
  const marks: SliderMarks | undefined = useMemo(() => {
    if (!field.value.disabled && filter.slider_dots && filter.slider_dots.length) {
      const dotsPosition: Dictionary<number> = {};
      const newMarksList: Array<Dictionary<number>> = [];

      filter.slider_dots.forEach(mark => {
        if (mark >= field.value.min && mark <= field.value.max) {
          const newIndex =
            logarithmNumber(mark) === Infinity || logarithmNumber(mark) === -Infinity
              ? 0
              : parseFloat(logarithmNumber(mark).toFixed(1));

          dotsPosition[newIndex] = mark;

          // This one is used on the slider onChange
          newMarksList.push({
            index: newIndex,
            mark,
          });
        }
      });

      setMarksList(newMarksList);

      return dotsPosition;
    }

    return undefined;
  }, [filter.slider_dots, field.value.disabled, field.value.min, field.value.max]);

  const onChange = (value: any) => {
    /* This code is meant to calc the logarithmic scale for the slider
       while preserving the original values for the input. */
    let newValue: FormCheckableRangeOption = {
      ...field.value,
    };
    const [min, max] = value as [number, number];

    const minValueMath = getValueFromLogarithm(
      min,
      initialMinValue,
      initialMaxValue,
      initialMinValueLog,
      initialMaxValueLog,
      marksList
    );
    const maxValueMath = getValueFromLogarithm(
      max,
      initialMinValue,
      initialMaxValue,
      initialMinValueLog,
      initialMaxValueLog,
      marksList
    );

    newValue = {
      ...newValue,
      range: [parseInt(minValueMath.toFixed(1)), parseInt(maxValueMath.toFixed(1))],
    };

    helpers.setValue(newValue);

    setCurrentSlideValue(value as [number, number]);
  };

  const onChangeInputMin = (value: any) => {
    minFieldHelpers.setValue(value);

    const newValue = getLogarithmFromValue(
      value,
      initialMinValue,
      initialMaxValue,
      initialMinValueLog,
      initialMaxValueLog
    );

    setCurrentSlideValue([parseFloat(newValue.toFixed(1)), currentSlideValue[1]]);

    // Call on change on the next tick.
    setTimeout(() => {
      onManualFilterChange && onManualFilterChange(filter.column);
    }, 0);
  };

  const onChangeInputMax = (value: any) => {
    maxFieldHelpers.setValue(value);

    const newValue = getLogarithmFromValue(
      value,
      initialMinValue,
      initialMaxValue,
      initialMinValueLog,
      initialMaxValueLog
    );

    setCurrentSlideValue([currentSlideValue[0], parseFloat(newValue.toFixed(1))]);

    // Call on change on the next tick.
    setTimeout(() => {
      onManualFilterChange && onManualFilterChange(filter.column);
    }, 0);
  };

  const onAfterChange = () => {
    // Call on change on the next tick.
    setTimeout(() => {
      onManualFilterChange && onManualFilterChange(filter.column);
    }, 0);
  };

  // this function gets the current value from the input and display in the slider tooltip
  const generateTooltip = (value: number | undefined) => {
    const newValue =
      value === currentSlideValue[0]
        ? numeral(field.value.range[0]).format('0a')
        : numeral(field.value.range[1]).format('0a');

    return newValue;
  };

  const handleCheckbox = (e: CheckboxChangeEvent) => {
    // Delay onChange for next tick to allow form to update it's values.
    const check = field.value.check;
    let newCheck = check;

    if (check && check.length > 1) {
      // issue with antd's type
      // @ts-ignore
      const checkIndex = Number(e.nativeEvent.target.id);
      const disabled = e.target.checked && checkIndex === 1;

      if (e.target.checked) {
        if (checkIndex === 0) {
          newCheck = [
            { ...check[0], checked: true },
            { ...check[1], checked: false },
          ];
        } else if (checkIndex === 1) {
          newCheck = [
            { ...check[0], checked: false },
            { ...check[1], checked: true },
          ];
        }
      } else if (!e.target.checked) {
        newCheck = [
          { ...check[0], checked: false },
          { ...check[1], checked: false },
        ];
      }

      helpers.setValue({
        ...field.value,
        disabled,
        range: disabled ? [field.value.min, field.value.max] : field.value.range,
        check: newCheck,
      });
    }

    setTimeout(() => {
      onManualFilterChange && onManualFilterChange(filter.column);
    }, 0);
  };

  useEffect(() => {
    const check = field.value.check;

    if (check && check.length > 1 && check[1].checked) {
      helpers.setValue({
        ...field.value,
        disabled: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {!field.value.disabled && (
        <Histogram
          histogramList={filter.histogram}
          min={minField.value ? minField.value : 0}
          max={maxField.value ? maxField.value : 0}
        />
      )}
      <Row>
        <Col span={24}>
          {initialMaxValue > 0 && (
            <Slider
              name={`${fieldKey}.range`}
              range
              min={initialMinValue}
              max={initialMaxValue}
              fast
              onChange={onChange}
              value={currentSlideValue}
              marks={marks}
              step={0.1}
              tipFormatter={generateTooltip}
              disabled={field.value.disabled}
              onAfterChange={onAfterChange}
            />
          )}
          {field.value.disabled && (
            <Slider
              name={`${fieldKey}.range`}
              range
              min={0}
              max={0}
              fast
              disabled={field.value.disabled}
            />
          )}
        </Col>
        <Col span={12} className="filter-form__slider-input">
          <RangeInputNumber
            formatter={formatter}
            // @ts-ignore
            parser={parser}
            value={minField.value}
            onChange={onChangeInputMin}
            disabled={field.value.disabled}
          />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }} className="filter-form__slider-input">
          <RangeInputNumber
            formatter={formatter}
            // @ts-ignore
            parser={parser}
            value={maxField.value}
            onChange={onChangeInputMax}
            disabled={field.value.disabled}
          />
        </Col>
        {field.value.check &&
          field.value.check.map((item: SelectOption) => (
            <Col key={index} span={24} style={{ marginTop: 8 }}>
              <Checkbox
                name={`${fieldKey}.check.checked`}
                disabled={item.disabled}
                fast
                onChange={handleCheckbox}
                id={`${index}`}
              >
                {item.label}
              </Checkbox>
            </Col>
          ))}
      </Row>
    </div>
  );
};

export default FilterRange;
