import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import reducer, { saga } from '@redux/company/active';

const useInjectCompanyActive = () => {
  useInjectReducer({
    key: 'companyActive',
    reducer,
  });
  useInjectSaga({
    key: 'companyActive',
    saga,
  });
};

export default useInjectCompanyActive;
