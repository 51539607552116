import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// models
import { GridPayload } from '@models/grid';
import { PageInfo } from '@models/table/Pagination';
import { UserInformation } from '@models/user';
import { PaginationState } from './interfaces';
// constants
import { GRID_KEYS, PAGINATION_INITIAL_STATE } from '@constants/grid';
// utils
import { generateGridReducerInitialState } from '@utils/grid';
// redux
import * as actions from './actions';
import { actions as userInformationActions } from '@redux/user/information';

const initialState: PaginationState = generateGridReducerInitialState(
  GRID_KEYS,
  PAGINATION_INITIAL_STATE
);

// external actions reducers
const fetchUserInformationSuccessReducer: CaseReducer<
  PaginationState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const userInfo = action.payload;

  // advanced search
  if (userInfo.settings.search_results_per_page) {
    draftState.advancedSearch.pageSize = userInfo.settings.search_results_per_page;
  }

  if (userInfo.settings.session_settings.search_results_page_number) {
    draftState.advancedSearch.pageNumber =
      userInfo.settings.session_settings.search_results_page_number;
  }

  // my companies
  if (userInfo.settings.my_companies_results_per_page) {
    draftState.myCompanies.pageSize = userInfo.settings.my_companies_results_per_page;
  }

  if (userInfo.settings.session_settings.my_companies_results_page_number) {
    draftState.myCompanies.pageNumber =
      userInfo.settings.session_settings.my_companies_results_page_number;
  }

  // contacts
  if (userInfo.settings.contact_results_per_page) {
    draftState.contacts.pageSize = userInfo.settings.contact_results_per_page;
  }

  if (userInfo.settings.session_settings.contact_results_page_number) {
    draftState.contacts.pageNumber = userInfo.settings.session_settings.contact_results_page_number;
  }

  // watchlist & sslist
  if (userInfo.settings.user_list_search_results_per_page) {
    draftState.watchlists.pageSize = userInfo.settings.user_list_search_results_per_page;
    draftState.sslists.pageSize = userInfo.settings.user_list_search_results_per_page;
  }

  if (userInfo.settings.session_settings.user_list_search_results_page_number) {
    draftState.watchlists.pageNumber =
      userInfo.settings.session_settings.user_list_search_results_page_number;
  }

  if (userInfo.settings.session_settings.ss_list_search_results_page_number) {
    draftState.sslists.pageNumber =
      userInfo.settings.session_settings.ss_list_search_results_page_number;
  }

  // outreach
  if (userInfo.settings.session_settings.sourcing_outreach_results_per_page) {
    draftState.outreach.pageSize =
      userInfo.settings.session_settings.sourcing_outreach_results_per_page;
  }

  if (userInfo.settings.session_settings.sourcing_outreach_results_page_number) {
    draftState.outreach.pageNumber =
      userInfo.settings.session_settings.sourcing_outreach_results_page_number;
  }

  // addons
  if (userInfo.settings.session_settings.addon_results_per_page) {
    draftState.addons.pageSize = userInfo.settings.session_settings.addon_results_per_page;
  }
};

const changePaginationSuccessReducer: CaseReducer<
  PaginationState,
  PayloadAction<GridPayload<PageInfo>>
> = (draftState, action) => {
  const { gridKey, data } = action.payload;

  draftState[gridKey] = data;
};

const reducer = createReducer<PaginationState>(initialState, builder =>
  builder
    .addCase(actions.changePaginationSuccess, changePaginationSuccessReducer)
    // external reducers
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
);

export default reducer;
