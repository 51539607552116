import { fork } from 'redux-saga/effects';
// redux
import { saga as selectedCompaniesSaga } from '../../selected-companies';
import { saga as bulkEditSaga } from '../../edit-fields';
import { saga as equityTouchSaga } from '../../equity-touch';
import { saga as salesloftSaga } from '../../salesloft';
import { saga as bulkAddToWatchListSaga } from '../../bulk-watchlist';
import { saga as bulkMergeCompaniesSaga } from '../../bulk-merge-companies';
import { saga as bulkSoftwareSaga } from '../../bulk-software';
import { saga as bulkAiMlSaga } from '../../bulk-ai-ml';

export default function* bulkActionsSagas() {
  yield fork(selectedCompaniesSaga);
  yield fork(bulkEditSaga);
  yield fork(equityTouchSaga);
  yield fork(salesloftSaga);
  yield fork(bulkAddToWatchListSaga);
  yield fork(bulkMergeCompaniesSaga);
  yield fork(bulkSoftwareSaga);
  yield fork(bulkAiMlSaga);
}
