import styled from 'styled-components';
import { Card } from 'antd';

export const StyledCard = styled(Card)`
  .ant-btn {
    border-color: $blue-6;
    color: $blue-6;
  }

  .relationship-summary {
    border-radius: 10px;
    height: 100%;

    .ant-card-body {
      padding: 13px 10px;
      height: 100%;
    }

    &__section-title {
      color: var(--gray-6);
      font-weight: bold;
    }

    &__title {
      color: #9ba9bd;
      font-size: 14px;
      font-weight: 600;
    }

    &__value {
      color: #1890ff;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .sub-info {
    font-size: 12px;
    font-weight: 400;

    &-value {
      color: #000000;
    }

    &-title {
      color: #90a0b7;
    }
  }

  &__sub-info {
    font-size: 12px;
    line-height: 10px;
  }

  &__title {
    color: $lightdark7;
  }

  &__value {
    font-size: 16px;
    font-weight: bold;
    color: $blue-6;

    &.highlighted {
      color: $warning;
    }
  }

  &__section-title {
    padding: 20px 0 8px;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    color: $gray-9;

    &.no-content {
      padding-top: 5px;
    }
  }
`;

export const Highlights = styled.span`
  h3 {
    color: #90a0b7;
  }
`;
