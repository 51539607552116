import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input } from 'antd';
import classnames from 'classnames';
// redux
import { actions as searchActions, selectors as searchSelectors } from '@features/grid/search';
// components
import SearchInput, { SearchInputProps } from '@optx/components/common/form/input/SearchInput';
// utils
import { sliceURLQuery } from '@optx/utils/url';
import { validateSearchTerm } from '@utils/search';
// components
import ApplySearchButton from '../ApplySearchButton';

interface CompanySearchProps extends Omit<SearchInputProps, 'onSearch' | 'value'> {}

const CompanySearch: React.FC<CompanySearchProps> = ({
  onFocus,
  onBlur,
  className,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const searchKey = useSelector(searchSelectors.getSearchKey('outreach'));
  const [query, setQuery] = useState('');
  const [validSearchKey, setValidSearchKey] = useState(true);
  const inputRef = useRef<Input | null>(null);

  const search = useCallback(
    (searchKey: string) => {
      dispatch(
        searchActions.searchCompanies({
          gridKey: 'outreach',
          data: { searchKey, shouldResetPageNumber: true },
        })
      );
      setQuery('');
    },
    [dispatch]
  );

  useEffect(() => {
    setQuery('');
  }, [searchKey]);

  const slicedQuery = sliceURLQuery(query);

  useEffect(() => {
    setQuery(slicedQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleSearch = (searchKey: string) => {
    // close the dropdown by clicking on input.
    inputRef.current!.input?.click();
    search(searchKey);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);

    if (event.target.value.length === 3 || event.target.value.length === 0) {
      setValidSearchKey(true);
    }
  };

  return (
    <>
      <SearchInput
        ref={inputRef}
        {...restProps}
        onChange={handleChange}
        iconSize={22}
        onSearch={handleSearch}
        value={query}
        placeholder="Search for companies,notes,etc"
        className={classnames(className, { 'search-term-error': !validSearchKey })}
      />
      {query && (
        <ApplySearchButton
          onSearch={() => search(query)}
          validation={() => validateSearchTerm(query, setValidSearchKey)}
        />
      )}
    </>
  );
};

export default React.memo(CompanySearch);
