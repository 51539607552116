import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { fetchFailReducer } from '@redux/feature/fetch/reducers';
import * as actions from './actions';
import { CompanyActivePayload, CompanyActiveState, CompanyActiveResponse } from './interfaces';

const initialState: CompanyActiveState = {
  companyId: null,
  error: '',
  loading: false,
};

const addCompanyActiveReducer: CaseReducer<
  CompanyActiveState,
  PayloadAction<CompanyActivePayload>
> = (draftState, action) => {
  draftState.companyId = action.payload.companyId;
  draftState.loading = true;
  draftState.error = '';
};

const addCompanyActiveSuccessReducer: CaseReducer<
  CompanyActiveState,
  PayloadAction<CompanyActiveResponse>
> = (draftState, action) => {
  draftState.loading = false;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.addCompanyActive, addCompanyActiveReducer)
    .addCase(actions.addCompanyActiveSuccess, addCompanyActiveSuccessReducer)
    .addCase(actions.addCompanyActiveFail, fetchFailReducer)
);

export default reducer;
