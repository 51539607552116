import React, { useState, useMemo, useRef, useContext } from 'react';
import { useField, useFormikContext } from 'formik';
import { ActionMeta, InputActionMeta, OptionsType, ValueType } from 'react-select';
import { Dictionary } from 'lodash';
// models
import { MultiselectAsyncFilter } from '@optx/models/filters';
import { SelectOption } from '@optx/models/Option';
// constants
import { SELECT_NONE } from '@components/common/select/constants';
// hooks
import useAsyncSearch from '@optx/common/hooks/select/useAsyncSearch';
// components
import TooltipLabel from '@optx/components/common/TooltipLabel';
import MultiSelectAsync from './MultiSelectAsync';
import SearchFilterCard from '../SearchFilterCard';
import Styled from './FilterMultiSelectAsync.styled';
import { FiltersContext } from '../../FiltersContext';

interface FilterMultiSelectAsyncOptions {
  filter: MultiselectAsyncFilter;
}

// build the parameteres for the endpoint
const loadOptionsBuilder = (
  endpoint: string,
  dependsOn: Array<string>,
  formValues: Dictionary<Array<SelectOption>>
) => {
  let buildLoadOptions = { endpoint, dependenciesOptions: {} };

  // if the dropdown is dependent of another one (or more than one). Eg: country, state and city.
  if (dependsOn.length) {
    let buildDependsOn: Dictionary<Array<string>> = {};

    dependsOn.forEach((item: string) => {
      buildDependsOn = {
        ...buildDependsOn,
        [item]: formValues && formValues[item] && formValues[item].map(option => option.value),
      };
    });

    buildLoadOptions = {
      ...buildLoadOptions,
      dependenciesOptions: { ...buildDependsOn },
    };
  }

  return buildLoadOptions;
};

const FilterMultiSelectAsync: React.FC<FilterMultiSelectAsyncOptions> = ({ filter }) => {
  // formik field and form (context)
  const [field, , helpers] = useField<OptionsType<SelectOption>>(filter.column);
  const { values: formValues } = useFormikContext<Dictionary<Array<SelectOption>>>();
  // local state
  const allowSelectNone = useMemo(
    () => filter.empty_state && filter.empty_state.find(item => item.value === SELECT_NONE.value),
    [filter.empty_state]
  );
  const initialOptions = useMemo(() => (allowSelectNone ? [SELECT_NONE] : []), [allowSelectNone]);
  const [fetchedOptions, setFetchedOptions] = useState<OptionsType<SelectOption>>(initialOptions);
  const isChanged = useRef(false);
  const [inputValue, setInputValue] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>();
  const { onManualFilterChange } = useContext(FiltersContext);

  const getLoadOptionsBuilder = loadOptionsBuilder(filter.endpoint, filter.depends_on, formValues);
  const { loadOptions } = useAsyncSearch(getLoadOptionsBuilder);

  const handleChange = (values: ValueType<SelectOption>, action: ActionMeta<SelectOption>) => {
    switch (action.action) {
      case 'remove-value': {
        // Removal was done with remove button (tag): https://react-select.com/advanced#action-meta.
        updateHistograms();
        break;
      }

      default: {
        isChanged.current = true;
        break;
      }
    }

    helpers.setValue(values as OptionsType<SelectOption>);
  };

  const getLoadOptions = (
    query: string,
    callback: (options: OptionsType<SelectOption<string>>) => void
  ) => {
    if (query.length >= 2) {
      loadOptions(query, options => {
        if (allowSelectNone) {
          options.unshift(SELECT_NONE);
        }

        setFetchedOptions(options);
        callback(options);
      });
    } else {
      setFetchedOptions(initialOptions);
      callback(initialOptions);
    }
  };

  // when menu is closed, clear cached values.
  const onMenuClose = () => {
    setFetchedOptions(initialOptions);
    setMenuIsOpen(false);
    setInputValue('');
  };

  const updateHistograms = () => {
    isChanged.current = false;

    // Call on change on the next tick.
    setTimeout(() => {
      onManualFilterChange && onManualFilterChange(filter.column);
    }, 0);
  };

  // if fields were selected, unselected or removed, call getHistogram
  const handleBlur = () => {
    if (isChanged.current) {
      updateHistograms();
    }
  };

  const handleInputChange = (newValue: string, { action }: InputActionMeta) => {
    switch (action) {
      case 'input-change': {
        setInputValue(newValue);
        break;
      }

      case 'menu-close': {
        let menuIsOpen;

        if (inputValue) {
          menuIsOpen = true;
        }

        setMenuIsOpen(menuIsOpen);

        break;
      }

      default:
        break;
    }
  };

  return (
    <SearchFilterCard
      label={<TooltipLabel label={filter.label} tooltip={filter.tooltip} />}
      className="multi-select-async"
    >
      <Styled.StartTyping className="start_typing">
        Start typing to see the results
      </Styled.StartTyping>
      <MultiSelectAsync
        defaultValue={field.value}
        defaultOptions={fetchedOptions}
        onChange={handleChange}
        loadOptions={getLoadOptions}
        onBlur={handleBlur}
        placeholder={filter.placeholders}
        className={`${filter.column}--multiselect-async`}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        menuIsOpen={menuIsOpen}
        onMenuClose={onMenuClose}
        onMenuOpen={() => setMenuIsOpen(true)}
      />
    </SearchFilterCard>
  );
};

export default React.memo(FilterMultiSelectAsync);
