import React from 'react';
import { LegendValueFormatter } from 'recharts';
// styles
import { LegendText } from './styles';

const LegendFormatter: LegendValueFormatter = (value, _entry) => (
  <LegendText>{(value as string).toUpperCase()}</LegendText>
);

export default LegendFormatter;
