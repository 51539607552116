import { Col, Row } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { OptxScoreConfidence, PSGFitAssessment, SoftwareCompanyAccuracy } from './StatsCards';

const gridGutter: Gutter | [Gutter, Gutter] = [
  { xs: 24, sm: 24, xl: 40 },
  { xs: 24, sm: 24, xl: 40 },
];

const StatsCard = () => {
  return (
    <Row gutter={gridGutter} className="checklist-research-insights">
      <Col span={24} xl={8}>
        <OptxScoreConfidence />
      </Col>
      <Col span={24} xl={8}>
        <PSGFitAssessment />
      </Col>
      <Col span={24} xl={8}>
        <SoftwareCompanyAccuracy />
      </Col>
    </Row>
  );
};

export default StatsCard;
