import { createSelector } from 'reselect';
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as companyFiltersSelectors } from '../../company/filters';
import { selectors as userInformationSelectors } from '../../user/information';
import { selectors as savedSearchesSelector } from '../../company/saved-searches';

export const shouldInitialSearch = createSelector(
  userInformationSelectors.loaded,
  companyFiltersSelectors.filtersLoaded,
  searchSelectors.shouldInitialFetch('advancedSearch'),
  savedSearchesSelector.loaded,
  (loadedUserInfo, loadedFilters, shouldFetchCompanies, savedSearchLoaded) =>
    loadedUserInfo && loadedFilters && shouldFetchCompanies && savedSearchLoaded
);
