import { SortByRule, SortRule } from '@models/table/sorting';
import { createAction } from '@reduxjs/toolkit';
import { SORT, MULTI_SORT, RESET_SORT, RESET_SORT_SUCCESS, SIMILAR_COMPANIES } from './types';

export const changeSortAction = createAction<SortRule<any>>(SORT);

export const multiSortAction = createAction(
  MULTI_SORT,
  (multiSort: boolean, sortBy?: Array<SortByRule<any>>) => ({
    payload: multiSort,
    meta: sortBy,
  })
);

export const similarCompaniesSortingAction = createAction<boolean>(SIMILAR_COMPANIES);

export const resetSortAction = createAction(RESET_SORT);
export const resetSortSuccessAction = createAction<Array<SortByRule<any>> | undefined>(
  RESET_SORT_SUCCESS
);
