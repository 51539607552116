import { CompanyNotification } from '@optx/models/Company';
import { createAction } from '@reduxjs/toolkit';
import * as types from './types';

// get company notes actions
export const fetchCompanyNotes = createAction<number>(types.FETCH_COMPANY_NOTES);

export const fetchCompanyNotesSuccess = createAction(
  types.FETCH_COMPANY_NOTES_SUCCESS,
  (companyId: number, notes: Array<CompanyNotification>) => ({
    payload: notes,
    meta: companyId,
  })
);

export const fetchCompanyNotesFail = createAction<string>(types.FETCH_COMPANY_NOTES_FAIL);

// add company note actions
export const addCompanyNote = createAction<{
  companyId: number;
  title: string;
  noteText: string;
}>(types.ADD_COMPANY_NOTE);

export const addCompanyNoteSuccess = createAction<CompanyNotification>(
  types.ADD_COMPANY_NOTE_SUCCESS
);

export const addCompanyNoteFail = createAction<string>(types.ADD_COMPANY_NOTE_FAIL);

// delete company note actions
export const deleteCompanyNote = createAction<{ companyId: number; noteId: number }>(
  types.DELETE_COMPANY_NOTE
);

export const deleteCompanyNoteSuccess = createAction<number>(types.DELETE_COMPANY_NOTE_SUCCESS);

export const deleteCompanyNoteFail = createAction<string>(types.DELETE_COMPANY_NOTE_FAIL);
