import { FEATURE } from '../constants';

const FEATURE_SLICE = `${FEATURE} filters`;

// fetch
export const FETCH = `${FEATURE_SLICE}/fetch`;
export const FETCH_SUCCESS = `${FEATURE_SLICE}/fetch success`;
export const FETCH_FAIL = `${FEATURE_SLICE}/fetch fail`;
// change
export const CLEAR_ALL_FILTERS = `${FEATURE_SLICE}/clear all`;
export const CLEAR_FILTER = `${FEATURE_SLICE}/clear filter`;
export const FILTER_CHANGE = `${FEATURE_SLICE}/filter changed`;
// update
export const UPDATE_VALUE = `${FEATURE_SLICE}/update value`;
export const SET_VALUES = `${FEATURE_SLICE}/set values`;
