import { CaseReducer, createReducer, PayloadAction, isAnyOf } from '@reduxjs/toolkit';
import * as actions from './actions';
import { EditField, EditFieldState } from './interfaces';

const initialState: EditFieldState = {
  companyId: null,
  error: '',
  loading: false,
};

const updatedEditFieldsReducer: CaseReducer<EditFieldState, PayloadAction<EditField>> = (
  draftState,
  action
) => {
  const { companyId } = action.payload;

  draftState.companyId = companyId;
  draftState.loading = true;
};

const updatedEditFieldsSuccessFailReducer: CaseReducer<EditFieldState> = draftState => {
  draftState.loading = false;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addMatcher(isAnyOf(actions.updateFields), updatedEditFieldsReducer)
    .addMatcher(isAnyOf(actions.updateFieldsSuccess), updatedEditFieldsSuccessFailReducer)
    .addMatcher(isAnyOf(actions.updateFieldsFail), updatedEditFieldsSuccessFailReducer)
);

export default reducer;
