import React from 'react';
import { Popconfirm as PopconfirmAntd } from 'antd';

interface PopconfirmProps {
  onConfirm?: () => void;
  onCancel?: () => void;
  title?: string;
  disabled?: boolean;
  action: 'Delete' | 'Remove';
}

const Popconfirm: React.FC<PopconfirmProps> = ({
  onConfirm,
  onCancel,
  title,
  disabled = false,
  children,
  action,
}) => (
  <PopconfirmAntd
    title={`Are you sure you want to ${action} ${title}?`}
    onConfirm={() => onConfirm && onConfirm()}
    onCancel={() => onCancel && onCancel()}
    okText={`${action} ${title}`}
    okButtonProps={{
      type: 'primary',
      danger: true,
    }}
    cancelText="Cancel"
    disabled={disabled}
  >
    {children}
  </PopconfirmAntd>
);

export default Popconfirm;
