import React, { useMemo } from 'react';
import { GroupType, MenuListComponentProps } from 'react-select';
import { CheckboxProps } from 'antd/lib/checkbox';

import { SelectOption } from '@optx/models/Option';
import { hasGroups } from '@optx/utils/select/select';
import { SELECT_ALL } from '@constants/select';
import { Styled } from './SelectAll.styled';

type CheckOptions = Pick<CheckboxProps, 'checked' | 'disabled'>;

const SelectAll = ({
  getValue,
  setValue,
  options,
  hasCustomNoTouchesOption,
}: MenuListComponentProps<SelectOption> & { hasCustomNoTouchesOption?: boolean }) => {
  const value = getValue() as Array<SelectOption>;

  const allOptions = useMemo(() => {
    let allOptions: Array<SelectOption> = [];

    if (hasGroups(options)) {
      options?.forEach((option: SelectOption | GroupType<SelectOption>) => {
        const groupOptions = (option as GroupType<SelectOption>).options;

        if (groupOptions) {
          // grouped options
          allOptions = allOptions.concat(groupOptions);
        } else {
          // single option
          allOptions.push(option as SelectOption);
        }
      });
    } else {
      allOptions = [...options];
    }

    return allOptions;
  }, [options]);

  const checkProps: CheckOptions = useMemo(() => {
    if (!value) {
      return {
        checked: false,
        disabled: false,
      };
    }

    // Disable select all if no options are enabled.
    const enabledOptions = allOptions.filter(item => !item.disabled);
    const isDisabled = !enabledOptions.length;

    return {
      checked: value.length === allOptions.length,
      disabled: isDisabled,
    };
  }, [allOptions, value]);

  const handleChange = (checked: boolean) => {
    if (checked) {
      if (hasGroups(options)) {
        let newValues: Array<SelectOption> = [];

        options.forEach(option => {
          if ((option as unknown as GroupType<SelectOption>).options) {
            // grouped options
            newValues = newValues.concat((option as unknown as GroupType<SelectOption>).options);
          } else {
            newValues.push(option);
          }
        });

        const filteredValues = newValues.filter(
          item => value.some(val => val.value === item.value) || !item.disabled
        );

        setValue(filteredValues, 'set-value');
      } else {
        const filteredValues = options.filter(
          item => value.some(val => val.value === item.value) || !item.disabled
        );

        setValue(filteredValues, 'set-value');
      }
    } else {
      // if there are disabled options checked we should keep them.
      setValue(
        value.filter(option => option.disabled),
        'set-value'
      );
    }
  };

  const handleClick = () => {
    handleChange(!checkProps.checked);
  };

  return (
    <Styled.SelectAllWrapper
      onClick={handleClick}
      hasCustomNoTouchesOption={hasCustomNoTouchesOption}
    >
      <Styled.Checkbox {...checkProps} />
      {SELECT_ALL.label}
    </Styled.SelectAllWrapper>
  );
};

export default SelectAll;
