import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
// models
import { CompanyUserContact, ContactPhone } from '@models/api/contacts';
import { SalesloftEditData } from '@models/Salesloft';
// constants
import { PRESENT_DATE_FORMAT, ISO_DATE_FORMAT } from '@optx/constants/format/date';
// utils
import { emailFieldsToString } from '@utils/salesloft';
// redux
import { fetchFailReducer, fetchReducer } from '@redux/feature/fetch/reducers';
import { findIndex } from 'lodash';
import { actions as bulkSalesloftActions } from '@features/bulk-actions/salesloft';

import { PrimaryContactsState } from './interfaces';
import * as actions from './actions';

const initialState: PrimaryContactsState = {
  error: '',
  data: [],
  loading: false,
  editedContact: null,
};

const fetchPrimaryContactsSuccessReducer: CaseReducer<
  PrimaryContactsState,
  PayloadAction<Array<CompanyUserContact>>
> = (draftState, action) => {
  draftState.loading = false;

  draftState.data = action.payload.map(contact => ({
    ...contact,
    ...(contact.tenure_start_date && {
      tenure_start_date: moment(contact.tenure_start_date).format(ISO_DATE_FORMAT),
    }),
    ...(contact.tenure_end_date && {
      tenure_end_date:
        contact.tenure_end_date !== PRESENT_DATE_FORMAT
          ? moment(contact.tenure_end_date).format(ISO_DATE_FORMAT)
          : contact.tenure_end_date,
    }),
  }));
};

const addContactSuccessReducer: CaseReducer<
  PrimaryContactsState,
  PayloadAction<Partial<CompanyUserContact>>
> = (draftState, action) => {
  const companyContacts = draftState.data;
  const newContact = {
    ...(action.payload as CompanyUserContact),
    additional_phones: action.payload.phones as ContactPhone[],
    icons: {
      phone: action.payload?.icons?.phone,
      linkedin: action.payload?.linkedin || null,
      email: action.payload?.icons?.email || false,
      salesloft: action.payload?.icons?.salesloft || false,
    },
    primary_contact: !!action.payload.primary_contact,
  };

  const mappedContacts = action.payload.primary_contact
    ? companyContacts.map(contact => ({ ...contact, primary_contact: false }))
    : companyContacts;

  if (draftState.data && mappedContacts) {
    draftState.data = [...mappedContacts, ...[newContact]];
  }
};

const updatePrimaryContactsReducer: CaseReducer<
  PrimaryContactsState,
  PayloadAction<SalesloftEditData>
> = (draftState, action) => {
  const {
    person_id: personId,
    primary_contact: isPrimaryContact,
    primary_email: primary,
    secondary_email: secondary,
    tertiary_email: tertiary,
    quarternary_email: quarternary,
    tenure_end_date: tenureEndDate,
    tenure_start_date: tenureStartDate,
  } = action.payload;
  let shouldUpdatePrimaryContact = false;
  draftState.data.forEach((contact, index) => {
    if (contact.person_id === personId) {
      if (draftState.data[index].primary_contact !== isPrimaryContact && isPrimaryContact) {
        shouldUpdatePrimaryContact = true;
      }

      draftState.data[index] = { ...draftState.data[index], ...action.payload };
      draftState.data[index].full_name = `${action.payload.first_name} ${action.payload.last_name}`;

      if (primary) {
        draftState.data[index].email = emailFieldsToString(
          `${primary} ${secondary} ${tertiary} ${quarternary}`
        );
      }

      draftState.data[index].additional_phones = action.payload.phones || null;

      if (tenureEndDate !== undefined) {
        draftState.data[index].tenure_end_date = tenureEndDate;
      }

      if (tenureStartDate !== undefined) {
        draftState.data[index].tenure_start_date = tenureStartDate;
      }
    }
  });

  // we should have only one primary contact, if user changes the primary contact
  // then we need to check and reset the previous primary contact
  if (shouldUpdatePrimaryContact) {
    const index = draftState.data.findIndex(
      item => item.primary_contact && item.person_id !== personId
    );

    if (index !== -1) {
      draftState.data[index].primary_contact = false;
    }
  }
};

const editPrimaryContactSuccessReducer: CaseReducer<
  PrimaryContactsState,
  PayloadAction<Partial<CompanyUserContact>>
> = (draftState, action) => {
  draftState.loading = false;
  const { person_id: personId, primary_contact: isPrimaryContact } = action.payload;
  const primaryContact = draftState.data.find(contact => contact.primary_contact);

  if (primaryContact && primaryContact.person_id !== personId && isPrimaryContact) {
    primaryContact.primary_contact = false;
  }

  // Find item index using _.findIndex
  const index = findIndex(draftState.data, { person_id: personId });

  // Replace item at index using native splice
  draftState.data.splice(index, 1, {
    ...draftState.data[index],
    ...(action.payload as CompanyUserContact),
    full_name: `${action.payload.first_name} ${action.payload.last_name}`,
    additional_phones: action.payload.phones as ContactPhone[],
    is_edited: true,
    icons: {
      ...draftState.data[index]?.icons,
      linkedin: action.payload.linkedin || null,
    },
  });
};

const isEditingPrimaryContactReducer: CaseReducer<
  PrimaryContactsState,
  PayloadAction<CompanyUserContact>
> = (draftState, action) => {
  const additionalProps: Partial<CompanyUserContact> = {};
  if (action.payload.emails.length === 0) additionalProps.emails = [{ email: '' }];

  if (!action.payload.phone && action.payload.additional_phones?.length === 0) {
    additionalProps.additional_phones = [{ phone_number: '', phone_type_id: undefined }];
  }

  draftState.editedContact = { ...action.payload, ...additionalProps };
};

const cancelEditingPrimaryContactReducer: CaseReducer<PrimaryContactsState> = draftState => {
  draftState.editedContact = null;
  draftState.error = '';
};

const clearPrimaryContactsReducer: CaseReducer<PrimaryContactsState> = draftState => {
  draftState.data = [];
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.fetchPrimaryContacts, fetchReducer)
    .addCase(actions.fetchPrimaryContactsSuccess, fetchPrimaryContactsSuccessReducer)
    .addCase(actions.fetchPrimaryContactsFail, fetchFailReducer)
    .addCase(actions.updatePrimaryContacts, updatePrimaryContactsReducer)
    .addCase(actions.clearPrimaryContacts, clearPrimaryContactsReducer)
    // Edit profile contact
    .addCase(bulkSalesloftActions.addContactSuccess, addContactSuccessReducer)
    .addCase(actions.editingPrimaryContact, isEditingPrimaryContactReducer)
    .addCase(actions.cancelEditingPrimaryContact, cancelEditingPrimaryContactReducer)
    .addCase(actions.editPrimaryContact, fetchReducer)
    .addCase(actions.editPrimaryContactSuccess, editPrimaryContactSuccessReducer)
    .addCase(actions.editPrimaryContactFail, fetchFailReducer)
);

export default reducer;
