import { takeLatest, call, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
// models
import { SearchAutocomplete } from '@optx/models/search-autocomplete';
// services
import { SearchService } from '@services/api';
import NotificationService from '@services/NotificationService';
// redux
import { fetchSearchAutocomplete, fetchSearchAutocompleteSuccess } from './actions';

export function* fetchSearchAutocompleteSaga() {
  try {
    const res: AxiosResponse<Array<SearchAutocomplete>> = yield call(
      SearchService.getCompanySearchAutocomplete
    );

    yield put(fetchSearchAutocompleteSuccess(res.data));
  } catch (error: any) {
    NotificationService.error('Error fetching search autocomplete!');
  }
}

export default function* searchAutocompleteSaga() {
  yield takeLatest(fetchSearchAutocomplete, fetchSearchAutocompleteSaga);
}
