import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import NotificationService from '@services/NotificationService';
import { CompanyService } from '@services/api';
import { ADD_COMPANY_PORTFOLIO } from './types';
import { updateCompanyPortfolioFail, updateCompanyPortfolioSuccess } from './actions';

import { CompanyPortfolio } from './interfaces';

export function* updateCompanyPortfolioSaga(action: PayloadAction<CompanyPortfolio>) {
  try {
    yield call(CompanyService.updatePortfolio, action.payload);
    yield put(updateCompanyPortfolioSuccess(action.payload));
    NotificationService.success('PSG platform company successfully updated');
  } catch (e: any) {
    const errorMessage = 'Error updating company portfolio!';
    yield put(updateCompanyPortfolioFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* portfolioSaga() {
  yield takeLatest(ADD_COMPANY_PORTFOLIO, updateCompanyPortfolioSaga);
}
