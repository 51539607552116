import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
// components
import KeyPerformanceIndicator from '@components/common/Text/KeyPerformanceIndicator';
// redux
import { selectors as touchesFiltersSelectors } from '@redux/company/touches/filters';

const TouchesSummary: React.FC = () => {
  const kpis = useSelector(touchesFiltersSelectors.getSummaryKPIs);

  return (
    <Row style={{ marginBottom: 0 }} gutter={[30, { xs: 15, sm: 0 }]}>
      {kpis.map((kpi, index) => (
        <Col key={index}>
          <KeyPerformanceIndicator
            valueProps={{
              className: kpi.label !== 'Last Touch Date' ? 'color-blue-6' : '',
            }}
            label={kpi.label}
            value={kpi.value}
            isInvalidValue
          />
        </Col>
      ))}
    </Row>
  );
};

export default TouchesSummary;
