import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Tooltip } from 'antd';
// utils
import { filterOption } from '@optx/utils/select/select';
// redux
import {
  actions as salesloftActions,
  selectors as salesloftSelectors,
} from '@redux/contacts/salesloft';
import { SalesloftContext } from '../SalesloftContext';

const { Option } = Select;

const SelectCadence: React.FC = () => {
  const dispatch = useDispatch();
  const { setCadenceId } = useContext(SalesloftContext);
  const options = useSelector(salesloftSelectors.getCadences);
  const postData = useSelector(salesloftSelectors.getPostData);

  const handleChange = (value: number) => {
    setCadenceId(value || null);
    const cadence = options.find(option => option.id === value);
    dispatch(
      salesloftActions.salesloftSelectCadence({
        ...(cadence as any),
        company_id: Number(postData.company_id),
      })
    );
  };

  return (
    <Tooltip title={postData.contactIds?.length ? '' : 'Select a contact'}>
      <Select
        showSearch
        disabled={postData.contactIds?.length === 0}
        placeholder="Select a cadence"
        onChange={handleChange}
        optionFilterProp="children"
        filterOption={filterOption}
      >
        <Option key="blank" value="">
          Select a cadence
        </Option>
        {options.map(option => (
          <Option key={option.id} value={option.id}>
            {option.name}
          </Option>
        ))}
      </Select>
    </Tooltip>
  );
};

export default SelectCadence;
