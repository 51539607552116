import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
// models
import { SelectedCompanies } from '@models/bulkActions';
// components
import { SalesloftAddContact } from '@optx/screens/App/SalesloftBulk/components/salesloft/SalesloftAddContact';
import SelectContacts from '@features/bulk-actions/equity-touch/components/SelectContacts';
import { SelectContacts as SalesloftSelectContact } from '@features/bulk-actions/salesloft/components/SelectContacts';
import AddContactBtn from '@optx/shared/view/molecules/contacts/AddContactBtn';

interface ContactSelectProps {
  companyData: SelectedCompanies;
}

const ContactSelect: React.FC<ContactSelectProps> = ({ companyData }) => {
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const contacts = companyData.contacts;
  const { company_id: id, company_name: name, company_url: url } = companyData;
  const contactData = {
    company_id: id,
    company_name: name,
    company_url: url,
  };

  if (contacts?.length) {
    if (location.pathname.includes('salesloft-bulk')) {
      return (
        <>
          <SalesloftSelectContact
            contacts={contacts}
            companyId={companyData.company_id}
            isCell
            showAddContactForm={() => setVisible(true)}
          />
          <SalesloftAddContact
            onCancel={() => setVisible(false)}
            visible={visible}
            contactData={contactData}
          />
        </>
      );
    }

    if (location.pathname.includes('equity-touch-bulk')) {
      return (
        <>
          <SelectContacts
            contacts={contacts}
            companyId={companyData.company_id}
            isCell
            showAddContactForm={() => setVisible(true)}
          />
          <SalesloftAddContact
            onCancel={() => setVisible(false)}
            visible={visible}
            contactData={contactData}
          />
        </>
      );
    }
  }

  return (
    <>
      <AddContactBtn setVisible={setVisible} />
      <SalesloftAddContact
        onCancel={() => setVisible(false)}
        visible={visible}
        contactData={contactData}
      />
    </>
  );
};

export default ContactSelect;
