import { createSelector } from 'reselect';
import { isEqual } from 'lodash';
// models
import { AppState } from '@optx/redux/interfaces';
// constants
import {
  MYCOMPANIES_DEFAULT_SORT_US,
  MYCOMPANIES_DEFAULT_SORT_IL,
} from '@optx/constants/table/sort/defaultSort';
// redux
import { getDefaultScore } from '@optx/redux/user/information/selectors';

// specific selectors
const sortState = (state: AppState) => state.myCompanies.sort;

export const getDefaultSorting = createSelector(sortState, state => state.sortBy);

export const getSorting = createSelector(
  getDefaultSorting,
  getDefaultScore,
  (sorting, defaultOptxScore) => {
    let sortBy = sorting;

    if (isEqual(sorting, MYCOMPANIES_DEFAULT_SORT_US) && defaultOptxScore === 'il') {
      sortBy = MYCOMPANIES_DEFAULT_SORT_IL;
    } else if (isEqual(sorting, MYCOMPANIES_DEFAULT_SORT_IL) && defaultOptxScore === 'us') {
      sortBy = MYCOMPANIES_DEFAULT_SORT_US;
    }

    return sortBy;
  }
);

export const isMultiSort = createSelector(sortState, state => state.multiSort);

export const isLoading = createSelector(sortState, state => state.loading);
