import React, { useMemo, useCallback, useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dictionary } from 'lodash';
import { Row } from 'antd';
import { Formik, FormikValues, FormikProps } from 'formik';
import { Form } from 'formik-antd';
import { CSSTransition } from 'react-transition-group';

// models
import { RangeFilter, PreselectedFilter } from '@optx/models/filters';
import { FormCheckableRangeOption } from '@optx/models/Option';
import { generateUniqueKey } from '@utils/uuid';
// redux
import { selectors as companyFiltersSelectors } from '@redux/company/filters';
import { selectors as listsSearchSelectors } from '@redux/lists/search/search';
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as userInformationSelectors } from '@redux/user/information';
import { actions as searchActions } from '@features/grid/search';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import { selectors as listsDetailsSelectors } from '@optx/redux/lists/details';
// utils
import { getQuickFiltersList, QuickFiltersListItemProps } from '@utils/filters/quickFilters';
import { actions as histogramActions } from '@features/histograms/histograms-list-details';
import {
  IFiltersContext,
  FiltersContext,
} from '@components/feature/company-filters/FiltersContext';
// components
import FilterRange from '@components/feature/company-filters/Filter/FilterRange';

const QuickFilters: React.FC = () => {
  const dispatch = useDispatch();

  const histograms = useSelector(listsSearchSelectors.selectHistograms);
  const isSourceScrub = useSelector(listsDetailsSelectors.isSourceScrubRoute);
  const showUserListQuickFilters = useSelector(userInformationSelectors.showUserListQuickFilters);
  const defaultOptxScore = useSelector(userInformationSelectors.getDefaultScore);
  const isIsraelScore = defaultOptxScore === 'il';
  const filter = useSelector(filterSelectors.getFilter(isSourceScrub ? 'sslists' : 'watchlists'));
  const filterSources = useSelector(companyFiltersSelectors.getFiltersMap);
  const keySearch = useSelector(searchSelectors.getSearchKey('lists'));

  // This is an old issue where some filters have bad integration and
  // don't take values from formik properly and the UI is not updated (ex: FilterRangeContent -> currentSlideValue).
  const [rerenderKey, setRerenderKey] = useState(generateUniqueKey);

  useEffect(() => {
    // Force rerender after filter changes.
    setRerenderKey(generateUniqueKey());
  }, [filter]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialFilter, setInitialFilter] = useState(filter);

  // Dummy use effect to fix formik reinitialization not happening just with enableReinitialize.
  useEffect(() => {
    setInitialFilter(filter);
  }, [filter]);

  // form
  const formRef = useRef<FormikProps<FormikValues> | null>(null);

  // call /search query
  const onSearch = useCallback(
    (searchKey: string, filter: Dictionary<PreselectedFilter>) =>
      dispatch(searchActions.searchCompanies({ gridKey: 'lists', data: { searchKey, filter } })),
    [dispatch]
  );

  // search
  const handleSearch = (instance?: FormikProps<FormikValues>) => {
    const submittedValues = instance ? instance.values : formRef.current!.values;

    onSearch(keySearch, submittedValues);
  };

  // form submit
  const handleSubmit = () => {
    handleSearch();
  };

  const onFilterFieldChange = (fieldName: string) => {
    dispatch(histogramActions.filterChanged(fieldName, formRef.current?.values));
    handleSearch();
  };

  const filtersContext: IFiltersContext = {
    histograms,
    onManualFilterChange: onFilterFieldChange,
  };

  const quickFiltersList = useMemo((): QuickFiltersListItemProps[] | null => {
    if (Object.keys(filterSources).length) {
      return getQuickFiltersList(filterSources, undefined, undefined, isIsraelScore);
    }

    return null;
  }, [filterSources, isIsraelScore]);

  if (!quickFiltersList) {
    return null;
  }

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <FiltersContext.Provider value={filtersContext}>
      <CSSTransition
        in={showUserListQuickFilters}
        timeout={300}
        classNames="quick-filters-transition"
        unmountOnExit
      >
        <div className="quick-filters-group">
          <Formik
            initialValues={filter}
            onSubmit={handleSubmit}
            enableReinitialize
            innerRef={formRef}
            validateOnChange
            validateOnBlur
          >
            <Form key={rerenderKey} onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
              <Row gutter={26}>
                {quickFiltersList.map((item, index) => (
                  <FilterRange filter={item as RangeFilter<Array<FormCheckableRangeOption>>} />
                ))}
              </Row>
            </Form>
          </Formik>
        </div>
      </CSSTransition>
    </FiltersContext.Provider>
  );
};

export default React.memo(QuickFilters);
