import { put, select, takeLatest } from 'redux-saga/effects';
// redux
import { actions as searchActions } from '@features/grid/search';
import {
  actions as viewTransitionActions,
  selectors as viewTransitionSelectors,
} from '@features/grid/view-transition';

function* resetViewTransitionSaga() {
  const isViewTransition: boolean = yield select(viewTransitionSelectors.getViewTransition);

  if (isViewTransition) {
    yield put(viewTransitionActions.toggleViewTransition(false));
  }
}

export default function* viewTransitionSaga() {
  yield takeLatest(searchActions.searchCompaniesSuccess, resetViewTransitionSaga);
}
