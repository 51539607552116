import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'antd';
import moment from 'moment';
// constants
import { PRESENT_DATE_FORMAT, ISO_DATE_FORMAT } from '@optx/constants/format/date';
// redux
import { selectors as profileSelectors } from '@redux/company/profile';
// components
import AddContactProfile from '../AddContactProfile';
import { defaultColumns } from '@components/common/table/TableContactInformation';
// styles
import { Styled } from './ProfilePrimaryContacts.styled';

const ProfilePrimaryContacts: React.FC = () => {
  const contacts = useSelector(profileSelectors.getProfileContacts);

  const sortedContacts = useMemo(() => {
    const contactsCopy = contacts.map(contact => ({
      ...contact,
      ...(contact.tenure_start_date && {
        tenure_start_date: moment(contact.tenure_start_date).format(ISO_DATE_FORMAT),
      }),
      ...(contact.tenure_end_date && {
        tenure_end_date:
          contact.tenure_end_date !== PRESENT_DATE_FORMAT
            ? moment(contact.tenure_end_date).format(ISO_DATE_FORMAT)
            : contact.tenure_end_date,
      }),
    }));

    return contactsCopy.sort((a, b) => a.full_name.localeCompare(b.full_name));
  }, [contacts]);

  return (
    <Card title="Primary Contacts" className="profile-box" extra={<AddContactProfile />}>
      <Styled.Table
        rowKey="person_id"
        columns={defaultColumns}
        dataSource={sortedContacts}
        bordered
        pagination={sortedContacts.length > 150 ? { position: ['bottomLeft'] } : false}
        tableLayout="fixed"
        className="overflow-auto primary-contacts-grid legacy-table"
      />
    </Card>
  );
};

export default React.memo(ProfilePrimaryContacts);
