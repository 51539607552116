import { Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

const Label = styled(Text)`
  font-size: 10px;
  font-weight: 600;
  color: #334d6e;
  display: block;
  padding: 0px 7px;
`;

const Value = styled(Text)`
  font-size: 18px;
  font-weight: 600;
  color: #1890ff;
  display: block;
  padding: 6px;
  line-height: 1.3;
`;

const Item = styled.div`
  background: #e6f7ff;
  border: 1px solid #91d5ff;
  height: 72px;
`;

const UnknownLabel = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: #1890ff;
  display: block;
`;

const Styles = {
  Label,
  Value,
  Item,
  UnknownLabel,
};

export default Styles;
