import styled from 'styled-components';
import TouchTypeStyled from './components/TouchTypeInsight.style';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${TouchTypeStyled.Wrapper},
  .main-content {
    min-width: 1px;
    width: 100%;
    margin-top: 40px;
  }

  @media (min-width: 1600px) {
    flex-wrap: nowrap;

    ${TouchTypeStyled.Wrapper} {
      flex: 0;
      min-width: 400px;
      margin-top: 0;
      max-width: 400px;
    }

    .main-content {
      flex: 1;
      width: 100%;
      margin-top: 23px;
    }
  }

  .touches-list-wrapper {
    width: 100%;
  }
`;
