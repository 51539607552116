import React from 'react';
import Styled from './Info.styled';

interface InfoProps {
  title: string;
  className?: string;
}

const InfoContent = () => {
  return (
    <>
      <Styled.PopoverContentDescription>
        <b>Email</b> = <b>1</b> point
      </Styled.PopoverContentDescription>
      <Styled.PopoverContentDescription>
        <b>Voicemail</b> = <b>5</b> points
      </Styled.PopoverContentDescription>
      <Styled.PopoverContentDescription>
        <b>Call</b> = <b>10</b> points
      </Styled.PopoverContentDescription>
      <Styled.PopoverContentDescription>
        <b>Meeting</b> = <b>20</b> points
      </Styled.PopoverContentDescription>
    </>
  );
};

const Info: React.FC<InfoProps> = ({ title, ...restProps }) => {
  return (
    <Styled.Popover
      align={{
        offset: [15, -55],
      }}
      arrowPointAtCenter
      placement="rightTop"
      title={<Styled.PopoverTitle>{title}</Styled.PopoverTitle>}
      content={InfoContent}
    >
      <Styled.Icon {...restProps} />
    </Styled.Popover>
  );
};

export default Info;
