import { Dictionary } from 'lodash';
import { Filter, FilterSource } from '@optx/models/filters';

/**
 * Normalize filter data into a dictionary with filter column as keys and filter data as value.
 * All filter data containing options with number values are converted to
 * string to work properly when used with formik library.
 * @param sources
 *
 */
export function normalizeFiltersByColumn(sources: Array<FilterSource>): Dictionary<Filter> {
  const normalizedFilters: Dictionary<Filter> = {};

  sources.forEach(source => {
    if (source.data) {
      source.data.forEach(filter => {
        normalizedFilters[filter.column] = filter;

        if (filter.type === 'filter_group_custom') {
          filter.data.forEach((section: Filter<any>) => {
            normalizedFilters[section.column] = section;
          });
        }
      });
    }
  });

  return normalizedFilters;
}
