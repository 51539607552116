/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useMemo } from 'react';
import { useField } from 'formik';
import { Row, Col, Typography } from 'antd';
import { InputNumber, Checkbox } from 'formik-antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
// models
import { MultiRangeNumberFilter } from '@models/filters';
import { CheckableRangeOption } from '@models/RangeOption';
// components
import { abbreviateNumber, parseNumberFromAbbreviation } from '@optx/utils/number';
import SearchFilterCard from './SearchFilterCard';
import { FiltersContext } from '../FiltersContext';

interface FilterMultiRangeProps {
  filter: MultiRangeNumberFilter;
}

const FilterMultiRangeCustom: React.FC<FilterMultiRangeProps> = ({ filter }) => {
  const baseKey = `${filter.column}_custom`;
  const [field, , fieldHelpers] = useField<CheckableRangeOption<string>>(baseKey);
  const [checkboxField, ,] = useField<Array<CheckableRangeOption>>(filter.column);
  const [showCustomRanges, setShowCustomRanges] = useState<boolean>(field.value.checked || false);
  const [isFieldChanged, setIsFieldChanged] = useState(false);
  const [isBlur, setIsBlur] = useState(true);
  const { onManualFilterChange } = useContext(FiltersContext);
  const isWWWebsiteRank = filter.column === 'sw_website_rank';

  const onFocus = () => {
    setIsBlur(false);
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsBlur(true);

    if (isFieldChanged) {
      const currentValue = parseInt(event.target.value);

      let isBlocked = false;
      checkboxField.value.forEach((blockedRange, index) => {
        if (blockedRange.disabled) {
          const nextIndex = index + 1;

          if (
            currentValue >= blockedRange.min &&
            currentValue <= blockedRange.max &&
            currentValue < checkboxField.value[nextIndex].min
          ) {
            isBlocked = true;
          }
        }
      });

      fieldHelpers.setValue({
        ...field.value,
        min: isBlocked && currentValue === parseInt(field.value.min) ? '' : field.value.min,
        max: isBlocked && currentValue === parseInt(field.value.max) ? '' : field.value.max,
      });

      setIsFieldChanged(false);

      // if value has been changed for both fields, call the function
      if (!isBlocked) {
        // Call on change on the next tick.
        setTimeout(() => {
          onManualFilterChange && onManualFilterChange(filter.column);
        }, 0);
      }
    }
  };

  function handleChangeShowCustomRanges(event: CheckboxChangeEvent) {
    if (event.target.checked) {
      setShowCustomRanges(true);
      fieldHelpers.setValue({
        ...field.value,
        checked: true,
      });
    } else {
      setShowCustomRanges(false);

      fieldHelpers.setValue({
        ...field.value,
        checked: false,
        min: '',
        max: '',
      });

      // If we have at least one value call change.
      if (typeof field.value.min === 'number' || typeof field.value.max === 'number') {
        // Call on change on the next tick.
        setTimeout(() => {
          onManualFilterChange && onManualFilterChange(filter.column);
        }, 0);
      }
    }
  }

  const formatter = useMemo(
    () => (value: string | number | undefined, inputType: 'min' | 'max') =>
      value ? abbreviateNumber({ value, maxValue: parseInt(field.value[inputType]) }) : '',
    []
  );

  const parser = useMemo(
    () => (value: string | number | undefined, inputType: 'min' | 'max') =>
      value ? parseNumberFromAbbreviation(value as string, undefined, undefined, undefined) : '',
    []
  );

  const onChangeMin = (value: string | number | undefined) => {
    setIsBlur(false);

    if (value !== field.value.min) {
      setIsFieldChanged(true);
    }
  };

  const onChangeMax = (value: string | number | undefined) => {
    setIsBlur(false);

    if (value !== field.value.max) {
      setIsFieldChanged(true);
    }
  };

  if (!filter.custom) {
    return null;
  }

  return (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Checkbox name={`${baseKey}.checked`} onChange={handleChangeShowCustomRanges}>
          Custom Range
        </Checkbox>
      </Col>
      {showCustomRanges && (
        <>
          <Col span={11}>
            <InputNumber
              name={`${baseKey}.min`}
              placeholder={filter.placeholders.min}
              formatter={
                (isWWWebsiteRank && isBlur) || !isWWWebsiteRank
                  ? (value: string | number | undefined) => formatter(value, 'min')
                  : undefined
              }
              parser={value => parser(value, 'min') as string}
              onBlur={onBlur}
              onFocus={onFocus}
              onChange={onChangeMin}
            />
          </Col>
          <Col span={11} offset={2}>
            <InputNumber
              name={`${baseKey}.max`}
              formatter={
                isBlur ? (value: string | number | undefined) => formatter(value, 'max') : undefined
              }
              parser={value => parser(value, 'max') as string}
              placeholder={filter.placeholders.max}
              onBlur={onBlur}
              onFocus={onFocus}
              onChange={onChangeMax}
            />
          </Col>
        </>
      )}
    </Row>
  );
};

const FilterMultiRange: React.FC<FilterMultiRangeProps> = ({ filter }) => {
  const [field, ,] = useField<Array<CheckableRangeOption>>(filter.column);
  const { onManualFilterChange } = useContext(FiltersContext);

  const onChange = () => {
    // Call on change on the next tick.
    setTimeout(() => {
      onManualFilterChange && onManualFilterChange(filter.column);
    }, 0);
  };

  return (
    <SearchFilterCard label={filter.label}>
      <Row>
        {field.value.map((option, index) => (
          <Col span={12} key={index}>
            <Checkbox
              className="no-wrap-checkbox"
              name={`${filter.column}[${index}.checked]`}
              disabled={option.disabled}
              onChange={onChange}
              fast
            >
              <Typography.Text title={option.label} ellipsis>
                {option.label}
              </Typography.Text>
            </Checkbox>
          </Col>
        ))}
      </Row>
      {filter.custom && <FilterMultiRangeCustom filter={filter} />}
    </SearchFilterCard>
  );
};

export default React.memo(FilterMultiRange);
