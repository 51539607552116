import { call, put, takeLatest, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
// models
import { CompanyDatePresented, UpdateOpportunityDealPreparation } from '@optx/models/Company';
// services
import { CompanyService } from '@services/api';
import NotificationService from '@services/NotificationService';
// redux
import * as actions from './actions';
import { actions as presentantionActions } from '../opportunity-presentation';
import { OpportunityPresentationState } from '../opportunity-presentation/interfaces';

export function* updateCompanyDatePresentedSaga(action: PayloadAction<CompanyDatePresented>) {
  try {
    const presentation: OpportunityPresentationState = yield select(
      state => state.opportunityPresentation
    );
    // @ts-ignore
    const res = yield call(CompanyService.updateDatePresented, {
      ...action.payload,
      value: action.payload.newDate,
    });

    if (res.data) {
      yield put(actions.updateCompanyDatePresentedSuccess(action.payload.newDate));

      if (presentation && presentation.data)
        yield put(
          presentantionActions.updateOpportunityPresentationSuccess({
            ...presentation.data,
            deal_preparation: {
              ...(presentation.data.deal_preparation as UpdateOpportunityDealPreparation),
              date_presented: res.data.date_presented,
            },
            company_id: action.payload.companyId,
          })
        );

      if (!action.payload.isDatePresentedModified) {
        NotificationService.success('Company date presented updated successfully!');
      }
    } else {
      NotificationService.error('Failed to update date!');
    }
  } catch (e: any) {
    NotificationService.error('Failed to update date!');
  }
}

export default function* datePresentedSaga() {
  yield takeLatest(actions.updateCompanyDatePresented, updateCompanyDatePresentedSaga);
}
