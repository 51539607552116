import { Dictionary } from 'lodash';
import { BaseFilter } from '@models/filters';
import { FilterSource } from '@models/filters';
import { CompanyFieldsGroup } from '@optx/models/companyFields';
import { CompanyUserContact } from '@optx/models/api/contacts';
import { PsgParticipant } from '@models/api/user';
import { SelectOption } from '@optx/models/Option';
import Company, { CompanySourceTag } from '@optx/models/Company';
import CompanyTouch from '@optx/models/company/CompanyTouch';
import { TouchesCreateForm } from '@models/api/touches';
import { FieldOptions } from '@optx/features/company/edit-fields/state/interfaces';

export interface FiltersResponse {
  filters: FilterSource[];
}

export interface TouchCompany {
  company_id: number;
  company_owner: string | null;
  owner_img: string | null;
  company_name: string;
  company_url: string | null;
  logo_url: string | null;
  is_in_et: boolean;
  location: string | null;
  year_founded: string | number | null;
  company_owner_id: number | null;
  stage: string | null;
  contacts: Partial<CompanyUserContact>[];
  pipeline_rank: number | string | null;
  source_tag: Array<CompanySourceTag>;
  stage_rationale: string | null;
}
export type TouchCompanyKeys = keyof TouchCompany;

export const companyTouchesInfo: TouchCompanyKeys[] = [
  'company_id',
  'company_owner',
  'owner_img',
  'company_name',
  'company_url',
  'logo_url',
  'is_in_et',
  'location',
  'year_founded',
  'company_owner_id',
  'stage',
  'contacts',
  'pipeline_rank',
  'source_tag',
  'stage_rationale',
];

export interface AdditionalFieldsError {
  error_initial_text: string;
  error_text: string;
  error_value: Dictionary<SelectOption> | null;
  error_field: string;
}

export interface CompanyTouchesDialogState {
  shouldInitialFetch: boolean;
  open: boolean;
  companyId: number;
  companyName: string;
  company: TouchCompany;
  filters: Dictionary<BaseFilter> | null;
  editTouch: CompanyTouch | null;
  error: string;
  additionalFieldsError: AdditionalFieldsError;
  modalTitle: string;
  disableSaveBtnFor: string | null;
  disableSaveBtnByAdditionalFields: boolean;
  psgParticipants: PsgParticipant[];
  financials: CompanyFieldsGroup[];
  initialFinancialState: Partial<TouchesCreateForm> | null;
  hasFinancialsLoaded: boolean;
  validationErrors: Record<validationErrorsTypes, boolean>;
}

export type validationErrorsTypes = 'noTouchErrors' | 'noEditFieldErrors' | 'noFinancialErrors';

export interface EditCompanyTouches {
  companyId: number;
  companyName: string;
  company: Company | TouchCompany;
  touch?: CompanyTouch;
  isFromGrid?: boolean;
}
export interface GetTouchesFilters {
  companyId: number | null;
}

export interface ChangeErrorText {
  value: SelectOption<string>;
  fieldName: FieldOptions;
}
