import styled from 'styled-components';
import { Row } from 'antd';
// components
import Card from '@components/common/cards';
// components
import StyledInfo from './Info.styled';

const CardRow = styled(Row)`
  justify-content: left;
  .ant-col {
    flexbasis: 20%;
    width: 20%;
    @media all and (max-width: 1650px) {
      flexbasis: 33.33%;
      width: 33.33%;
    }
  }
`;
const CardOptionsWrapper = styled.div`
  display: flex;

  & > * {
    flex: 1;
  }
`;

const StyledCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;

  &.qualified_opportunities_ops {
    cursor: auto;
  }

  .ant-card-head {
    border-bottom: none;
  }

  .ant-card-body {
    padding: 0;
  }

  ${StyledInfo.Icon} {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }
`;

export default {
  CardRow,
  CardOptionsWrapper,
  Card: StyledCard,
};
