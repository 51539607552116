import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import uiReducer from '../../../redux/ui';
import { saga } from '@redux/ui/modals/add-document-upload-touch';

const useInjectDocumentUploadModal = () => {
  useInjectReducer({
    key: 'ui',
    reducer: uiReducer,
  });
  useInjectSaga({
    key: 'documentUploadTouchSaga',
    saga,
  });
};

export default useInjectDocumentUploadModal;
