import React from 'react';
import classnames from 'classnames';
import { components, OptionProps as OProps } from 'react-select';
import { Checkbox } from 'antd';
// Constants
import { SELECT_ALL } from '../constants';

/**
 * Option inside the dropdown list.
 */

interface OptionProps extends OProps<any> {
  disabled?: boolean;
}

const Option: React.FC<OptionProps> = props => {
  const { isSelected, children, data, isDisabled, disabled } = props;

  return (
    <components.Option
      className={classnames({
        'multiselect-select__option--all': data.value === SELECT_ALL.value,
      })}
      {...props}
    >
      <Checkbox checked={isSelected} disabled={isDisabled || (disabled && isSelected)}>
        {children}
      </Checkbox>
    </components.Option>
  );
};

export default Option;
