import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from 'antd';
// redux
import { selectors, actions } from '../state';

const ToggleSortType = () => {
  const dispatch = useDispatch();
  const isMultiSort = useSelector(selectors.sort.multiSort);
  const sorting = useSelector(selectors.sort.sortBy);

  const multiSort = useCallback(() => {
    if (!isMultiSort) {
      const tableElement = document.querySelector('.ant-table-body');
      tableElement?.scrollTo(0, 0);
    }

    dispatch(actions.sort.multiSort(!isMultiSort, sorting));
  }, [dispatch, isMultiSort, sorting]);

  return (
    <Menu.Item onClick={multiSort}>
      {isMultiSort ? 'Enable Single Sort' : 'Enable Multi Sort'}
    </Menu.Item>
  );
};

export default ToggleSortType;
