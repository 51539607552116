import React, { useEffect, useState } from 'react';
import { Card, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import {
  Area,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Line,
  ComposedChart,
  Legend,
  TooltipProps,
} from 'recharts';
import moment from 'moment';
import numeral from 'numeral';
// models
import { ChartData, ChartIntervalKeys, ChartItem, ChartItemKeys } from '@optx/models/charts/data';
// constants
import { NUMBER_FORMAT } from '@optx/constants/format/number';
import { SHORT_MONTH_YEAR_DATE_FORMAT } from '@optx/constants/format/date';
// utils
import { getFirstValidDate, getChartTicks } from '../../../utils/charts';
// components
import KeyPerformanceIndicator from '../../common/Text/KeyPerformanceIndicator';
import EmptyChart from './EmptyChart';
import LineChartTooltip from './LineChartTooltip';
import CombinedChartTicks from './CombinedChartTicks';
import { ReactComponent as Gradients } from '../../../assets/svg/gradient-definitions.svg';
import Styles from './ChartHeader.style';

interface LinearCombinedChartProps {
  data: ChartData;
  isLoading?: boolean;
  dataKey: string;
  dataSecondaryKey?: string;
  labelKey: string;
  title: string;
  kpiLabel: string;
  className?: string;
  legend?: React.ReactElement;
}

const LinearCombinedChart: React.FC<LinearCombinedChartProps> = ({
  data,
  isLoading = false,
  dataKey,
  dataSecondaryKey,
  labelKey,
  title,
  kpiLabel,
  className,
  legend,
}) => {
  let defaultKey = '3M' as ChartIntervalKeys;

  if (data['2Y'].data.length) {
    defaultKey = '2Y';
  } else if (data['1Y'].data.length) {
    defaultKey = '1Y';
  } else if (data['6M'].data.length) {
    defaultKey = '6M';
  }

  const [interval, setIntervalValue] = useState<'' | ChartIntervalKeys>('');
  const [maxValue, setMaxValue] = useState<string | number | undefined | null>('-');
  const [minValue, setMinValue] = useState<string | number | undefined | null>('-');
  const [growth, setGrowth] = useState<string | number | undefined | null>('-');
  const [growthClassName, setGrowthClassName] = useState('');
  const [chartPoints, setChartPoints] = useState<ChartItem[]>([]);
  const [historyStartsWidth, setHistoryStartsWidth] = useState<string | undefined>();
  const [ticks, setTicks] = useState<Array<string>>([]);

  const handleSizeChange = (e: RadioChangeEvent) => {
    const key = e.target.value as ChartIntervalKeys;
    setIntervalValue(key);
    setMaxValue(data[key]?.max);
    setMinValue(data[key]?.min);
    setGrowth(data[key]?.growth);
    setChartPoints(data[key]?.data || []);

    if (data[key]?.growth! > 0) {
      setGrowthClassName('kpi--positive');
    } else if (data[key]?.growth! < 0) {
      setGrowthClassName('kpi--negative');
    } else {
      setGrowthClassName('');
    }

    setTicks(getChartTicks(data, key));
  };

  const handleClassName = (key: ChartIntervalKeys) => {
    if (data[key]?.growth! > 0) {
      setGrowthClassName('kpi--positive');
    } else if (data[key]?.growth! < 0) {
      setGrowthClassName('kpi--negative');
    } else {
      setGrowthClassName('');
    }
  };

  useEffect(() => {
    if (data[defaultKey].data.length) {
      // calculate and save the width of "history starts from" element
      // it's width will be proportional to the number of empty labels compared to
      // the number of data points that have values
      if (defaultKey === '3M') {
        let emptyLabels = 0;

        data[defaultKey].data.forEach((item: ChartItem) => {
          if (item[dataKey as ChartItemKeys] === undefined) {
            emptyLabels++;
          }
        });

        if (emptyLabels) {
          setHistoryStartsWidth(`calc(${data[defaultKey].data[1].position!}% - 90px`);
        }
      }

      if (!interval) {
        setIntervalValue(defaultKey);
        setChartPoints(data[defaultKey].data);
      } else {
        setChartPoints(data[interval].data);
      }

      setTicks(getChartTicks(data, defaultKey));
    }

    setMaxValue(data[defaultKey]?.max);
    setMinValue(data[defaultKey]?.min);

    if (interval) {
      setGrowth(data[interval]?.growth);
      handleClassName(interval);
    } else {
      setGrowth(data[defaultKey]?.growth);
      handleClassName(defaultKey);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const header = (
    <Styles.ChartHeaderWrapper>
      <Styles.ChartTitleWrapper>{title}</Styles.ChartTitleWrapper>
      <Radio.Group value={interval} onChange={handleSizeChange}>
        {Object.keys(data).map((key, index) => {
          const isDisabled = !data[key as ChartIntervalKeys].data.length;

          return (
            <Radio.Button key={index} value={key} disabled={isDisabled}>
              {key}
            </Radio.Button>
          );
        })}
      </Radio.Group>
    </Styles.ChartHeaderWrapper>
  );

  const extra = (
    <div>
      <KeyPerformanceIndicator
        label="High"
        value={typeof maxValue === 'number' ? maxValue!.toString() : '-'}
      />
      <KeyPerformanceIndicator
        label="Low"
        value={typeof minValue === 'number' ? minValue!.toString() : '-'}
      />
      <KeyPerformanceIndicator
        label={kpiLabel}
        value={typeof growth === 'number' ? `${growth}%` : '-'}
        className={growthClassName}
      />
    </div>
  );

  return (
    <Card title={header} extra={extra} className="profile-chart-card extra">
      <Gradients />
      {isLoading ? (
        <Styles.LoadingIconWrapper>
          <Styles.LoadingIcon />
        </Styles.LoadingIconWrapper>
      ) : (
        <div
          className={`profile-chart-card__container  ${legend ? 'has-legend' : ''}`}
          style={{ height: '180px' }}
        >
          <ResponsiveContainer>
            <ComposedChart data={chartPoints} margin={{ left: 24, top: 5 }}>
              {!chartPoints.length && (
                <CartesianGrid
                  vertical={false}
                  horizontal={false}
                  fill="url(#chartEmptyGradient)"
                />
              )}

              <XAxis
                dataKey={labelKey}
                tickLine={false}
                stroke="#C2CFE0"
                tickSize={15}
                ticks={ticks}
                interval={0}
                type="number"
                tick={props => (
                  <CombinedChartTicks
                    startDate={chartPoints.length ? chartPoints[0].date : ''}
                    endDate={chartPoints.length ? chartPoints[chartPoints.length - 1].date : ''}
                    intervalKey={interval}
                    {...props}
                  />
                )}
              />
              <YAxis
                orientation="right"
                tickCount={2}
                tickMargin={10}
                tickLine={false}
                axisLine={false}
                stroke="#C2CFE0"
                type="number"
                tickFormatter={(value: string | number | undefined) => {
                  return numeral(value).format(NUMBER_FORMAT);
                }}
              />
              <Tooltip
                wrapperStyle={{ zIndex: 1 }}
                content={(props: TooltipProps) => (
                  <LineChartTooltip
                    primaryKey={dataKey}
                    secondaryKey={dataSecondaryKey}
                    primaryLabel="Employee Count:"
                    secondaryLabel="Job Openings:"
                    {...props}
                  />
                )}
                cursor={false}
              />
              {legend && chartPoints.length && (
                <Legend content={legend} align="left" verticalAlign="bottom" height={0} />
              )}
              <Area
                type="monotone"
                dataKey={dataKey}
                stroke="#1890FF"
                strokeWidth="2px"
                fillOpacity={1}
                fill="url(#lineChartGradient)"
                dot={{
                  fill: chartPoints.length > 20 ? 'transparent' : '#fff',
                  strokeWidth: chartPoints.length > 20 ? 0 : 1,
                  r: 4,
                }}
                connectNulls
              />
              {dataSecondaryKey && (
                <Line
                  type="monotone"
                  dataKey={dataSecondaryKey}
                  stroke="#722ED1"
                  strokeDasharray="3 5"
                  strokeWidth="2px"
                  dot={{
                    strokeDasharray: '0',
                    fill: chartPoints.length > 20 ? 'transparent' : '#fff',
                    strokeWidth: chartPoints.length > 20 ? 0 : 1,
                    r: 4,
                  }}
                  connectNulls
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
          {!chartPoints.length && <EmptyChart />}
          {!!historyStartsWidth && !data[Object.keys(data)[1] as ChartIntervalKeys].data.length && (
            <EmptyChart
              className="chart-empty-labels"
              text={`History Starts ${moment(getFirstValidDate(data[defaultKey].data)?.date).format(
                SHORT_MONTH_YEAR_DATE_FORMAT
              )}`}
              width={historyStartsWidth}
            />
          )}
        </div>
      )}
    </Card>
  );
};

export default LinearCombinedChart;
