import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { LinkProps } from 'antd/lib/typography/Link';

const { Link } = Typography;

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledLink = styled(Link)<LinkProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

/**
 * Content added as children will be clickable and redirect on Link to property.
 * Component is using click propagation on container to the Link.
 */
const ContentLink: React.FC<LinkProps> = ({ children, ...restProps }) => {
  return (
    <StyledWrapper>
      <StyledLink {...restProps} />
      {children}
    </StyledWrapper>
  );
};

export default ContentLink;
