export const FETCH_SCHEDULED_TOUCHES_DASHBOARD = 'FETCH_SCHEDULED_TOUCHES_DASHBOARD';
export const FETCH_SCHEDULED_TOUCHES_DASHBOARD_SUCCESS =
  'FETCH_SCHEDULED_TOUCHES_DASHBOARD_SUCCESS';
export const FETCH_SCHEDULED_TOUCHES_DASHBOARD_FAIL = 'FETCH_SCHEDULED_TOUCHES_DASHBOARD_FAIL';

export const FETCH_SCHEDULED_TOUCHES = 'FETCH_SCHEDULED_TOUCHES';
export const FETCH_SCHEDULED_TOUCHES_SUCCESS = 'FETCH_SCHEDULED_TOUCHES_SUCCESS';
export const FETCH_SCHEDULED_TOUCHES_FAIL = 'FETCH_SCHEDULED_TOUCHES_FAIL';

export const RESET_SCHEDULED_TOUCHES_FILTER = 'RESET_SCHEDULED_TOUCHES_FILTER';

export const FETCH_SCHEDULED_TOUCHES_COUNT = 'FETCH_SCHEDULED_TOUCHES_COUNT';
export const FETCH_SCHEDULED_TOUCHES_COUNT_SUCCESS = 'FETCH_SCHEDULED_TOUCHES_COUNT_SUCCESS';
export const FETCH_SCHEDULED_TOUCHES_COUNT_FAIL = 'FETCH_SCHEDULED_TOUCHES_COUNT_FAIL';

export const FETCH_SCHEDULED_TOUCHES_NEXT_PAGE = 'FETCH_SCHEDULED_TOUCHES_NEXT_PAGE';
export const FETCH_SCHEDULED_TOUCHES_NEXT_PAGE_SUCCESS =
  'FETCH_SCHEDULED_TOUCHES_NEXT_PAGE_SUCCESS';
export const FETCH_SCHEDULED_TOUCHES_NEXT_PAGE_FAIL = 'FETCH_SCHEDULED_TOUCHES_NEXT_PAGE_FAIL';

export const CHANGE_SCHEDULED_TOUCHES_SORT_BY = 'CHANGE_SCHEDULED_TOUCHES_SORT_BY';
export const CHANGE_SCHEDULED_TOUCHES_STAGE_TYPE = 'CHANGE_SCHEDULED_TOUCHES_STAGE_TYPE';
export const CHANGE_SCHEDULED_TOUCHES_TOUCH_TYPE = 'CHANGE_SCHEDULED_TOUCHES_TOUCH_TYPE';
export const CHANGE_SCHEDULED_TOUCHES_RANK_TYPE = 'CHANGE_SCHEDULED_TOUCHES_RANK_TYPE';
export const CHANGE_SELECTED_DATE = 'CHANGE_SELECTED_DATE';
export const CHANGE_SELECTED_PERIOD = 'CHANGE_SELECTED_PERIOD';
export const CHANGE_SELECTED_TAB = 'CHANGE_SELECTED_TAB';
