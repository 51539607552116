import { call, put, takeLatest, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { values as _values } from 'lodash';
// models
import { SortByRule } from '@optx/models/table/sorting';
// services
import { UserManagement } from '@services/api';
import NotificationService from '@services/NotificationService';
// redux
import {
  selectors as userManagementSelectors,
  actions as userManagementActions,
} from '@redux/user-management';
import { AxiosResponse } from 'axios';
import * as actions from './actions';

export function* sendUserInviteSaga(action: PayloadAction<{ email: string; role: string }>) {
  const sorting: SortByRule<any>[] = yield select(userManagementSelectors.getSorting);

  try {
    const res: AxiosResponse<any> = yield call(UserManagement.setInvite, action.payload);

    if (!res.data) {
      yield put(actions.sendUserInviteSuccess(res.data));

      NotificationService.success('Invitation Sent!');

      yield put(actions.toggleUserInvitationDialog());

      // if the invite was a success, refresh the grid to get new data.
      yield put(userManagementActions.fetchUserManagement(sorting));
    } else {
      let errorMessage = 'Failed to send User Invitation!';

      if (res.data.errors) {
        errorMessage = _values(res.data.errors).toString();
      }

      yield put(actions.sendUserInviteFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    let errorMessage = 'Failed to send User Invitation!';

    if (error.response.data && error.response.data.errors) {
      errorMessage = _values(error.response.data.errors).toString();
    }

    yield put(actions.sendUserInviteFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* userInvitationSaga() {
  yield takeLatest(actions.sendUserInvite, sendUserInviteSaga);
}
