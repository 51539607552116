import { combineReducers } from 'redux';
import undoable from 'redux-undo';
import { CompaniesInEquityTouchState } from '../interfaces';
import search from './search';
import filters from './filters';
import sort from './sort';
import pagination from './pagination';
import ui from './ui';
import charts from './charts';
import highlights from './highlights';
import { actions } from '../actions';

const stateReducer = combineReducers<CompaniesInEquityTouchState>({
  search,
  filters,
  sort,
  pagination,
  ui,
  charts,
  highlights,
});

export const reducer = undoable(stateReducer, {
  limit: 2,
  undoType: actions.undo.toString(),
});
