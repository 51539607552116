import React, { useState } from 'react';
import { Dropdown, Button, Menu, Tooltip } from 'antd';

// components
import { EllipsisOutlined } from '@ant-design/icons';
import ExportCSVButton from './SearchHeader/ExportCSVButton';

const SourcingOutreachSearchDropdown: React.FC = () => {
  const [visible, setVisible] = useState(false);

  const menu = (
    <Menu>
      <ExportCSVButton hideDropdown={() => setVisible(!visible)} />
    </Menu>
  );

  return (
    <Tooltip title="Export">
      <Dropdown
        overlay={menu}
        trigger={['click']}
        placement="bottomRight"
        visible={visible}
        onVisibleChange={() => setVisible(!visible)}
      >
        <Button type="text" icon={<EllipsisOutlined style={{ fontSize: 28 }} />} />
      </Dropdown>
    </Tooltip>
  );
};

export default SourcingOutreachSearchDropdown;
