import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, Input } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import classnames from 'classnames';
// constants
import { SearchAutocomplete } from '@models/search-autocomplete';
// utils
import { sliceURLQuery } from '@optx/utils/url';
import { parseFilter } from '@utils/filters/parseFilters';
import { validateSearchTerm } from '@utils/search';
// redux
import { selectors as searchSelectors } from '@features/grid/search';
import { actions as searchActions } from '@features/grid/search';
import {
  selectors as autocompleteSelectors,
  actions as searchAutocompleteActions,
} from '@features/grid/autocomplete';
import { selectors as savedSearchesSelectors } from '@redux/company/saved-searches';
import { selectors as filterSourcesSelectors } from '@redux/company/filters';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';
// components
import ApplySearchButton from '../ApplySearchButton';
import SearchInput, { SearchInputProps } from '@optx/components/common/form/input/SearchInput';
import { AutocompleteMenu } from '@optx/features/grid/autocomplete/components';

interface CompanySearchProps extends Omit<SearchInputProps, 'onSearch' | 'value'> {}

const CompanySearch: React.FC<CompanySearchProps> = ({
  onFocus,
  onBlur,
  className,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const searchKey = useSelector(searchSelectors.getSearchKey('advancedSearch'));
  const allSavedSearchesList = useSelector(savedSearchesSelectors.getAllSearch);
  const filterSources = useSelector(filterSourcesSelectors.getCompanyFilters);
  const [query, setQuery] = useState('');
  const [validSearchKey, setValidSearchKey] = useState(true);
  const inputRef = useRef<Input | null>(null);
  const autocompleteList = useSelector(autocompleteSelectors.getAutocompleteList);
  const { advancedSearchPathname } = useIsAnalyst();

  // initialize autocomplete
  const fetchSearchAutocomplete = useCallback(
    () => dispatch(searchAutocompleteActions.fetchSearchAutocomplete()),
    [dispatch]
  );

  const loadAutocompleteSearch = useCallback(
    (item: SearchAutocomplete) => {
      if (
        item.search_info?.type !== null &&
        item.search_info?.type.startsWith('recent') &&
        item.title.startsWith('-recent-search-suggested-')
      ) {
        const title = item.title.replace('-recent-search-suggested-', '');

        return dispatch(
          searchActions.loadSuggestedSearch({
            gridKey: 'advancedSearch',
            data: { title },
          })
        );
      }

      if (item.search_info?.type !== null && item.search_info?.type.startsWith('saved')) {
        const searchId = allSavedSearchesList.find(el => el.title === item.title)?.unique_id;

        return dispatch(
          searchActions.loadSavedSearch({
            gridKey: 'advancedSearch',
            data: {
              searchId: searchId as string | number,
              title: item.title,
              listType: item.search_info.type,
            },
          })
        );
      }

      if (
        item.search_info?.type !== null &&
        !item.search_info?.type.startsWith('saved') &&
        item.search_info?.filters.filter(el => !el.startsWith('query')).length
      ) {
        const filter = parseFilter(item.search_criteria, filterSources)[0];
        const searchKey = item.search_info.keyword;

        const isSimilarCompaniesSearch = filter.similarCompanies;

        return dispatch(
          searchActions.searchCompanies({
            gridKey: 'advancedSearch',
            data: {
              searchKey,
              filter,
              shouldResetPageNumber: true,
              isSimilarCompaniesSearch: !!isSimilarCompaniesSearch,
            },
          })
        );
      }

      return dispatch(
        searchActions.loadAutocompleteSearch({ gridKey: 'advancedSearch', data: item })
      );
    },
    [dispatch, filterSources, allSavedSearchesList]
  );

  useEffect(() => {
    fetchSearchAutocomplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  const search = useCallback(
    (searchKey: string) => {
      dispatch(
        searchActions.searchCompanies({
          gridKey: 'advancedSearch',
          data: { searchKey, shouldResetPageNumber: true },
        })
      );
      setQuery('');
    },
    [dispatch]
  );

  useEffect(() => {
    setQuery('');
  }, [searchKey]);

  const slicedQuery = sliceURLQuery(query);

  useEffect(() => {
    setQuery(slicedQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;

  const handleSearch = (searchKey: string) => {
    // close the dropdown by clicking on input.
    inputRef.current!.input?.click();
    search(searchKey);

    if (pathname !== advancedSearchPathname) {
      history.push(advancedSearchPathname);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);

    if (event.target.value.length === 3 || event.target.value.length === 0) {
      setValidSearchKey(true);
    }
  };

  return (
    <>
      <Dropdown
        overlay={
          <AutocompleteMenu list={autocompleteList} onClickHandler={loadAutocompleteSearch} />
        }
        trigger={['click']}
      >
        <SearchInput
          ref={inputRef}
          {...restProps}
          onChange={handleChange}
          onSearch={handleSearch}
          value={query}
          placeholder="Search for companies by keyword (3 chars or more)"
          className={classnames(className, { 'search-term-error': !validSearchKey })}
        />
      </Dropdown>
      {query && (
        <ApplySearchButton
          onSearch={() => search(query)}
          validation={() => validateSearchTerm(query, setValidSearchKey)}
        />
      )}
    </>
  );
};

export default React.memo(CompanySearch);
