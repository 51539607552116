import React, { useState, ChangeEvent, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Row, Typography } from 'antd';
import styled from 'styled-components';
// models
import { CompanyImportFailedData } from '@optx/models/Company';
// components
import EditPopover from '@components/common/popover/EditPopover';
// hooks
import { useOnClickOutside } from '@optx/common/hooks/useOnClickOutside';
// redux
import {
  actions as importActions,
  selectors as importSelectors,
} from '@features/list-import/state';
// styles
import { Styled } from './EditCompany.styled';

interface EditCompanyProps {
  companyImportData: CompanyImportFailedData;
  companyIndex?: number | null;
}

enum InputName {
  company = 'company',
  url = 'url',
}

const Text = styled.span`
  font-weight: 600;
  color: #1890ff;
`;

const EditCompany: React.FC<EditCompanyProps> = ({
  companyImportData: { Company: company, URL: url, raw_url: rawUrl },
  companyIndex,
}) => {
  const isCompleted = useSelector(importSelectors.isCompleted);
  const isEditing = useSelector(importSelectors.getIsEditing);
  const dispatch = useDispatch();
  const [companyName, setCompanyName] = useState(company);
  const [companyURL, setCompanyURL] = useState(url);
  const [closePopup, setClosePopup] = useState(false);
  const popoverContentRef = useRef(null);

  const onVisibilityChange = () => {
    setCompanyName(company);
    setCompanyURL(url);
  };

  useOnClickOutside(popoverContentRef, () => onVisibilityChange());

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    if (event.target.name === InputName.company) {
      setCompanyName(value);
    } else if (event.target.name === InputName.url) {
      setCompanyURL(value);
    }
  };

  const handleSave = () => {
    companyIndex !== undefined && dispatch(importActions.editingCompanyIndex(companyIndex));

    dispatch(
      importActions.editCompany({
        Company: companyName,
        URL: companyURL,
        Website: companyURL,
        raw_url: rawUrl,
      })
    );
    setClosePopup(true);
  };

  const handleCancel = () => {
    setClosePopup(true);
    dispatch(importActions.editingCompanyIndex(null));
  };

  const content = (
    <Styled.ContentContainer ref={popoverContentRef}>
      <Row>
        <Typography.Text>Company Name</Typography.Text>
        <Input name={InputName.company} value={companyName} onChange={handleChange} />
      </Row>
      <Row>
        <Typography.Text>URL</Typography.Text>
        <Input name={InputName.url} value={companyURL} onChange={handleChange} />
      </Row>
      <div className="buttons-container">
        <Button className="cancel" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className="save"
          type="primary"
          onClick={handleSave}
          disabled={!isCompleted || isEditing}
        >
          Try Import Again
        </Button>
      </div>
    </Styled.ContentContainer>
  );

  return (
    <EditPopover
      content={content}
      title="Edit info"
      closePopup={closePopup}
      setClosePopup={setClosePopup}
      onVisibilityChange={onVisibilityChange}
      hidePenIcon
    >
      <Text>Resolve</Text>
    </EditPopover>
  );
};

export default EditCompany;
