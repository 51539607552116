import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';

// models
import { UserInformation } from '@optx/models/user';
import { HistogramFiltersState } from '../../base-histograms/state/interfaces';
import { SearchCountPayload } from '@optx/features/grid/search-count/state/interfaces';
// utils
import { actions as userInformationActions } from '@redux/user/information';

// redux
import { fetchFailReducer } from '@redux/feature/fetch/reducers';
import { models, reducers } from '@features/histograms/base-histograms';
import { actions as searchActions } from '@features/grid/search';
import { actions as companyFilterActions } from '@redux/company/filters';
import { actions as searchCountActions } from '@features/grid/search-count';
import * as actions from './actions';

const initialState: models.HistogramFiltersState = {
  data: {},
  allFilters: [],
  lastChangedFilter: undefined,
  changedFilters: [],
  edit: null,
  searchCount: undefined,
  defaultHistograms: null,
  enabled: false,
  // fetch
  error: '',
  loading: false,
  fetchedAt: null,
};

const fetchUserInformationSuccessReducer: CaseReducer<
  models.HistogramFiltersState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  draftState.enabled = action.payload.settings.my_companies_filters_refresh;
};

export const searchCountSuccessReducer: CaseReducer<
  HistogramFiltersState,
  PayloadAction<SearchCountPayload>
> = (draftState, action) => {
  const { gridName } = action.payload;

  if (gridName === 'myCompanies') {
    draftState.searchCount = undefined;
  }
};

const reducer = createReducer<models.HistogramFiltersState>(initialState, builder =>
  builder
    .addCase(actions.fetchHistogramFilters, reducers.fetchHistogramsReducer)
    .addCase(actions.fetchHistogramFiltersSuccess, reducers.fetchHistogramsFiltersSuccess)
    .addCase(actions.fetchHistogramFiltersFail, fetchFailReducer)
    .addCase(actions.toggleRefresh, reducers.toggleHistogramRefresh)
    .addCase(actions.filterChanged, reducers.filterChangedReducer)
    .addCase(actions.edit, reducers.editReducer)
    .addCase(actions.applyEdit, reducers.applyEditReducer)
    .addCase(actions.resetToDefault, reducers.resetToDefaultReducer)
    // external reduces
    .addCase(
      companyFilterActions.fetchCompanyFiltersSuccess,
      reducers.fetchCompanyFiltersSuccessReducer
    )
    .addCase(searchActions.clearSearch, reducers.resetReducer)
    .addCase(searchCountActions.searchCountSuccess, searchCountSuccessReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
    .addCase(searchActions.removeFilter, reducers.resetFilterReducer)
);

export default reducer;
