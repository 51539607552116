import { call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { Dictionary } from 'lodash';
// models
import { SearchPayloadRequest } from '@optx/models/api/contacts';
import { ViewOption } from '@optx/models/search';
import { UpdateUserSettingsPayload } from '@optx/models/api/user';
import { PreselectedFilter } from '@optx/models/filters';
import { SortByRule } from '@optx/models/table/sorting';
import { PageInfo } from '@optx/models/table/Pagination';
import { GridPayload } from '@optx/models/grid';
// utils
import { mapSortQuery, hasFilters, preProcessSearch } from '@utils/search';
// redux
import { selectors as listsSelectors } from '@redux/lists/details';
import {
  selectors as userInformationSelectors,
  actions as userInformationActions,
} from '@redux/user/information';
import { actions as searchCountActions } from '@features/grid/search-count';
import { selectors as listsSearchSelectors } from '@redux/lists/search/search/';
import { actions as searchActions, selectors as searchSelectors } from '@features/grid/search';
import { actions as columnActions } from '@redux/lists/search/columns';
import { actions as savedSearchesActions } from '@redux/company/saved-searches';
import { actions as sortActions } from '@redux/lists/search/sort';
import {
  selectors as paginationSelectors,
  actions as paginationActions,
} from '@features/grid/pagination';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import { searchCompanies, searchCompaniesFail } from '@features/grid/search/state/sagasReusable';
import { getFiltersQuery } from '@redux/company/search/search/sagasReusable';

export function* applyViewSaga(action: PayloadAction<GridPayload<ViewOption>>) {
  const {
    columns,
    column_order: columnOrder,
    pinned_columns: pinnedColumns,
    filters: viewFilters,
    searchKey: viewSearchKey,
    sortBy,
    search_criteria: searchCriteria,
    unique_id: id,
    title,
    search_info: searchInfo,
  } = action.payload.data;

  const listId: string = yield select(listsSelectors.getRouteListId);
  const isSourceScrubRoute: boolean = yield select(listsSelectors.isSourceScrubRoute);
  const gridKey = isSourceScrubRoute ? 'sslists' : 'watchlists';
  const pagination: PageInfo = yield select(paginationSelectors.getPagination(gridKey));
  const isCombinedSearch = searchInfo?.type === 'combined';
  const userSessionSettingsUserListPageNumber: number = yield select(
    userInformationSelectors.getUserListSearchesResultsPageNumber
  );
  const userSessionSettingsSSListPageNumber: number = yield select(
    userInformationSelectors.getSSListSearchesResultsPageNumber
  );
  let filter: Dictionary<PreselectedFilter> | undefined;
  let searchKey: string = yield select(searchSelectors.getSearchKey('lists'));

  if (hasFilters(searchCriteria) || id === 'default') {
    filter = viewFilters;
    searchKey = viewSearchKey ? preProcessSearch(viewSearchKey) : '';
  }

  if (!hasFilters(searchCriteria) && viewSearchKey) {
    filter = yield select(
      filterSelectors.getClearedFilter(isSourceScrubRoute ? 'sslists' : 'watchlists')
    );
    searchKey = viewSearchKey ? preProcessSearch(viewSearchKey) : '';
  }

  yield put(columnActions.updateColumns(columns, columnOrder, pinnedColumns));

  if (isCombinedSearch || filter || sortBy) {
    const currentSortBy: SortByRule<any>[] = yield select(listsSearchSelectors.getSorting);

    if (!filter) {
      filter = yield select(
        filterSelectors.getFilter(isSourceScrubRoute ? 'sslists' : 'watchlists')
      );
    }

    const customClearedFilter: Dictionary<PreselectedFilter> = yield select(
      filterSelectors.getCustomClearedFilter(gridKey)
    );

    // for old saved searches it's possible to have watch list id saved.
    // need to reset the corresponding list id filter, especially for source scrub lists
    filter = {
      ...(isCombinedSearch ? { ...customClearedFilter } : { ...filter }),
      ...(isSourceScrubRoute
        ? { ss_list_id: listId, saved_list_id: undefined }
        : { saved_list_id: listId, ss_list_id: undefined }),
    };

    const filterQuery: Dictionary<string | (string | number)[]> = yield call(
      getFiltersQuery,
      filter
    );

    const searchData: SearchPayloadRequest = {
      searchKey,
      fromSavedList: typeof id === 'number' ? id : undefined,
      listType: searchInfo?.type || '',
      filter: filterQuery,
      pagination: {
        ...pagination,
        pageNumber: 1,
      },
      sortBy: sortBy || currentSortBy,
    };

    const sessionSettings: Partial<UpdateUserSettingsPayload> = {};

    if (sortBy) {
      const sortQuery = queryString.stringify(mapSortQuery(sortBy), {
        arrayFormat: 'comma',
      });
      sessionSettings.user_list_sorting = sortQuery;
    }

    if (columns !== null) {
      sessionSettings.user_list_display_columns = columns;

      if (columnOrder) {
        sessionSettings.user_list_column_order = columnOrder;
      }
    }

    if (pinnedColumns !== null) {
      sessionSettings.user_list_pinned_columns = pinnedColumns;
    }

    const shouldUpdateSSListPageNumberSettings =
      isSourceScrubRoute &&
      searchData.pagination.pageNumber !== userSessionSettingsSSListPageNumber;

    if (shouldUpdateSSListPageNumberSettings) {
      sessionSettings.ss_list_search_results_page_number = searchData.pagination.pageNumber;
    }

    const shouldUpdateUserListPageNumberSettings =
      isSourceScrubRoute &&
      searchData.pagination.pageNumber !== userSessionSettingsUserListPageNumber;

    if (shouldUpdateUserListPageNumberSettings) {
      sessionSettings.user_list_search_results_page_number = searchData.pagination.pageNumber;
    }

    const updateSession =
      filter &&
      !sortBy &&
      !columns &&
      !pinnedColumns &&
      !shouldUpdateSSListPageNumberSettings &&
      !shouldUpdateUserListPageNumberSettings
        ? true
        : sessionSettings;

    try {
      yield call(searchCompanies, searchData, 'lists', filter, undefined, title, updateSession);

      if (sortBy) {
        if (sortBy.length > 1) {
          yield put(searchActions.resetSearchSuccess({ gridKey: 'lists', data: true }));
        } else {
          yield put(sortActions.resetSortSuccessAction(sortBy));
        }
      }

      if (filter) {
        yield put(
          searchCountActions.searchCount({
            data: searchData,
            gridKey: isSourceScrubRoute ? 'sslists' : 'watchlists',
          })
        );
      }

      yield put(
        paginationActions.changePaginationSuccess({
          gridKey,
          data: searchData.pagination,
        })
      );
    } catch (error: any) {
      yield call(searchCompaniesFail, 'lists', error);
    }
  } else {
    yield put(
      userInformationActions.updateUserSettings({
        user_list_display_columns: columns as string,
        user_list_column_order: columnOrder as string,
        user_list_pinned_columns: pinnedColumns as string,
      })
    );
    yield put(savedSearchesActions.applyViewSuccess({ id, label: title, pageAlias: 'userLists' }));
  }
}
