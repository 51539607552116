import React from 'react';
import {
  StatsSection,
  // TimeSpentStatistics,
  AllTimeHighlights,
  CompaniesInETStatistics,
} from '@features/equity-touch-integration/components';
import Styled from './EquityTouchIntegration.styled';

const Highlights = () => {
  return (
    <Styled.StatsWrapper>
      <StatsSection title="Highlights">
        <AllTimeHighlights />
        <CompaniesInETStatistics />
        {/* <TimeSpentStatistics /> */}
      </StatsSection>
    </Styled.StatsWrapper>
  );
};

export default Highlights;
