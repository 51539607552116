/* eslint-disable no-nested-ternary */
import { takeLatest, call, put, select, cancel, cancelled } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary, values } from 'lodash';
import { AxiosResponse } from 'axios';
import queryString from 'query-string';
// models
import { BaseFilter, FilterSource, PreselectedFilter } from '@models/filters';
import { ListsSearchData } from '@models/lists/search';
import { PageInfo } from '@models/table/Pagination';
import { SortByRule } from '@optx/models/table/sorting';
import {
  SaveView,
  SearchSave,
  ModifyView,
  ViewOption,
  DeleteCompanySearch,
  RenameCompanySearch,
  SearchSaveResponse,
} from '@models/search';
import { CustomUIViewIds, UserInformation, UserSettings } from '@optx/models/user';
import { RouteAliases } from '@optx/models/routes';
import { ColumnKeys } from '@optx/models/table/Columns';
import { GridKeysMain } from '@optx/models/grid';
// constants
import {
  customUIViews,
  customUIViewIds,
  defaultOptxView,
} from '@optx/constants/table/columnDisplay/company-search';
// services
import { SavedSearchesService } from '@services/api';
import NotificationService from '@services/NotificationService';
// utils
import { getErrorMessage } from '@utils/api/errors';
import mapFiltersToURLParams from '@utils/filters/mapFiltersToURLParams';
import { mapSortQuery, hasFilters, preProcessSearch } from '@utils/search';
import { getRouteAlias } from '@utils/routes';
import { fetchAfterDelete } from '@optx/utils/combineSearch';
// redux
import { selectors as myCompaniesColumnSelectors } from '@redux/my-companies/columns';
import { actions as autoCompleteActions } from '@features/grid/autocomplete';
import { selectors as listsCommonSelectors } from '@redux/lists/search/common';
import { selectors as columnSelectors } from '@redux/company/search/columns';
import { selectors as userSelectors, actions as userActions } from '@redux/user/information';
import { selectors as listsAndSearchesSelectors } from '@features/lists-and-searches';
import { actions as listsAndSearchesActions } from '@features/lists-and-searches/pagination';
import { mapSearchSave } from '@optx/redux/feature/saved-search/reducers';
import { selectors as filterSourcesSelectors, actions as filterActions } from '../filters';
import { selectors as companySearchSelectors } from '../search/search';
import { actions as searchActions, selectors as searchSelectors } from '@features/grid/search';
import { selectors as listsSelectors } from '../../lists/search/search';
import { selectors as listsDetailsSelectors } from '@redux/lists/details';
import { selectors as searchCountSelectors } from '@features/grid/search-count';
import { selectors as myCompaniesSortSelectors } from '../../my-companies/sort';
import * as actions from './actions';
import * as selectors from './selectors';
import { searchSavedSearches } from './sagasReusable';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import {
  actions as savedSearchesActions,
  selectors as searchesSelectors,
} from '@redux/company/saved-searches';
import { getViewLabel } from '@optx/features/grid/view/state/selectors';

export function* searchSavedSearchesFail() {
  const errorMessage = 'Error fetching watch lists!';
  yield put(actions.fetchSavedSearchesFail(errorMessage));
  NotificationService.error(errorMessage);
}

export function* fetchSavedSearchesSaga(action: PayloadAction<string>) {
  const sorting: SortByRule<any>[] = yield select(selectors.getSortBy);
  const pageNumber: number = yield select(selectors.getPageNumber);
  const userPageNumber: number = yield select(
    userSelectors.getSavedCompanySearchesResultsPageNumber
  );
  const defaultCustomUIView: CustomUIViewIds = yield select(userSelectors.getDefaultCustomUIView);
  const pageSize: number = yield select(listsAndSearchesSelectors.pageSize);
  const pagination = {
    pageNumber,
    pageSize,
  };
  const query: string = yield select(selectors.getQuery);
  const searchData: ListsSearchData = {
    query: action.payload || '',
    sortBy: sorting,
    pagination:
      action.payload && action.payload !== query ? { ...pagination, pageNumber: 1 } : pagination,
    addCustomViews: true,
    defaultCustomUIView,
  };

  try {
    yield call(searchSavedSearches, searchData);

    if (searchData.pagination.pageNumber !== userPageNumber) {
      yield put(
        userActions.updateUserSettings({
          saved_company_serches_page_number: searchData.pagination.pageNumber,
          saved_company_searches_filters: action.payload || '',
        })
      );
    } else {
      yield put(
        userActions.updateUserSettings({
          saved_company_searches_filters: action.payload || '',
        })
      );
    }
  } catch (e: any) {
    yield call(searchSavedSearchesFail);
  }
}

export function* savedSearchesPaginationSaga(action: PayloadAction<PageInfo>) {
  const pagination = action.payload;
  const sorting: SortByRule<any>[] = yield select(selectors.getSortBy);
  const pageSize: number = yield select(listsAndSearchesSelectors.pageSize);
  const query: string = yield select(selectors.getQuery);
  const userPageNumber: number = yield select(
    userSelectors.getSavedCompanySearchesResultsPageNumber
  );
  const defaultCustomUIView: CustomUIViewIds = yield select(userSelectors.getDefaultCustomUIView);
  const searchData: ListsSearchData = {
    query,
    sortBy: sorting,
    pagination: action.payload,
    addCustomViews: true,
    defaultCustomUIView,
  };

  try {
    yield call(searchSavedSearches, searchData);

    if (pagination.pageSize !== pageSize && searchData.pagination.pageNumber !== userPageNumber) {
      yield put(
        userActions.updateUserSettings({
          lists_and_searches_results_per_page: pagination.pageSize,
          saved_company_serches_page_number: searchData.pagination.pageNumber,
        })
      );
      yield put(
        listsAndSearchesActions.updateMyWatchlistTabsPagination({
          actions: ['watchlists', 'sourceScrub', 'contactSearches'],
          pagination,
        })
      );
    } else if (pagination.pageSize !== pageSize) {
      yield put(
        userActions.updateUserSettings({
          lists_and_searches_results_per_page: pagination.pageSize,
        })
      );
      yield put(
        listsAndSearchesActions.updateMyWatchlistTabsPagination({
          actions: ['watchlists', 'sourceScrub', 'contactSearches'],
          pagination,
        })
      );
    } else if (searchData.pagination.pageNumber !== userPageNumber) {
      yield put(
        userActions.updateUserSettings({
          saved_company_serches_page_number: searchData.pagination.pageNumber,
        })
      );
    }
  } catch (e: any) {
    yield call(searchSavedSearchesFail);
  }
}

export function* sortCompanySearchesListsSaga(action: PayloadAction<SortByRule<any>[]>) {
  const sorting = action.payload;
  const pageNumber: number = yield select(selectors.getPageNumber);
  const userPageNumber: number = yield select(
    userSelectors.getSavedCompanySearchesResultsPageNumber
  );
  const defaultCustomUIView: CustomUIViewIds = yield select(userSelectors.getDefaultCustomUIView);
  const pageSize: number = yield select(listsAndSearchesSelectors.pageSize);
  const pagination = {
    pageNumber,
    pageSize,
  };
  const query: string = yield select(selectors.getQuery);
  const searchData: ListsSearchData = {
    query,
    pagination: action.payload ? { ...pagination, pageNumber: 1 } : pagination,
    sortBy: sorting,
    addCustomViews: true,
    defaultCustomUIView,
  };
  const sortQuery = queryString.stringify(mapSortQuery(sorting), {
    arrayFormat: 'comma',
  });

  try {
    yield call(searchSavedSearches, searchData);

    if (searchData.pagination.pageNumber !== userPageNumber) {
      yield put(
        userActions.updateUserSettings({
          saved_company_serches_sorting: sortQuery,
          saved_company_serches_page_number: searchData.pagination.pageNumber,
        })
      );
    } else {
      yield put(userActions.updateUserSettings({ saved_company_serches_sorting: sortQuery }));
    }
  } catch (e: any) {
    yield call(searchSavedSearchesFail);
  }
}

export function* saveSearchSaga(
  action: PayloadAction<{ title: string; filters: Dictionary<PreselectedFilter> }>
) {
  const { title, filters } = action.payload;
  const searchKey: string = yield select(companySearchSelectors.getSearchKey);
  const filterSourcesMap: Dictionary<BaseFilter> = yield select(
    filterSourcesSelectors.getFiltersMap
  );
  const searchCount: number = yield select(companySearchSelectors.getSearchCount);
  const filterQuery = mapFiltersToURLParams(filterSourcesMap, filters);
  const filterSources: FilterSource[] = yield select(filterSourcesSelectors.getCompanyFilters);
  const clearedFilters: Dictionary<PreselectedFilter> = yield select(
    filterSelectors.getClearedFilter('advancedSearch')
  );

  try {
    const res: AxiosResponse<SearchSaveResponse> = yield call(
      SavedSearchesService.createCompanySearch,
      title,
      preProcessSearch(searchKey),
      searchCount,
      filterQuery
    );
    const cancelSearch: boolean = yield select(searchSelectors.isCancelled('advancedSearch'));

    if (cancelSearch) {
      yield cancel();
    }

    if (res.data) {
      yield put(actions.saveSearchSuccess(res.data, filterSources, clearedFilters));
      NotificationService.success(`${title} company search was saved.`);
    } else {
      const errorMessage = 'Error saving company search!';
      yield put(actions.saveSearchFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage =
      e.response.data && e.response.data.errors
        ? values(e.response.data.errors).toString()
        : 'Error saving company search!';
    yield put(actions.saveSearchFail(errorMessage));
    NotificationService.error(errorMessage);
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      yield put(searchActions.cancelCompanySearch({ gridKey: 'advancedSearch', data: false }));
      // canceles the loading state for saved searches
      yield put(actions.saveSearchFail(''));
    }
  }
}

function* saveViewSaga(action: PayloadAction<SaveView>) {
  // check whether we are saving a search from my watchlist
  // use it to load corresponding filters, sorting and columns
  const pageAlias: RouteAliases | null = getRouteAlias();
  let filters: Dictionary<PreselectedFilter>;
  let filterQuery;
  let sorting: SortByRule<any>[];
  let columns: string;
  let columnOrder: ColumnKeys[];
  let pinnedColumns: string;
  const {
    filters: saveFilters,
    sorting: saveSorting,
    columns: saveColumns,
    pinnedColumns: savePinnedColumns,
    title,
    isDefault,
  } = action.payload;

  const defaultOptx: string = yield select(userSelectors.getDefaultScore);

  if (saveFilters) {
    filters =
      pageAlias === 'userLists'
        ? yield select(filterSelectors.getFilter('watchlists'))
        : pageAlias === 'myCompanies'
        ? yield select(filterSelectors.getFilter('myCompanies'))
        : pageAlias === 'sourceScrubLists'
        ? yield select(filterSelectors.getFilter('sslists'))
        : yield select(filterSelectors.getFilter('advancedSearch'));

    // if we are saving a search from my watchlist, remove list id parameter,
    // we shouldn't save it as part of the filters anymore
    filters = { ...filters, ss_list_id: undefined, saved_list_id: undefined };
    const filterSourcesMap: Dictionary<BaseFilter> = yield select(
      filterSourcesSelectors.getFiltersMap
    );
    filterQuery = mapFiltersToURLParams(filterSourcesMap, filters);
  }

  if (saveSorting) {
    sorting =
      pageAlias === 'userLists'
        ? yield select(listsSelectors.getSorting)
        : pageAlias === 'myCompanies'
        ? yield select(myCompaniesSortSelectors.getSorting)
        : yield select(companySearchSelectors.getSorting);
  }

  if (saveColumns) {
    columns =
      pageAlias === 'userLists'
        ? yield select(listsCommonSelectors.getVisibleColumnString)
        : pageAlias === 'myCompanies'
        ? yield select(myCompaniesColumnSelectors.getVisibleColumnString)
        : yield select(columnSelectors.getVisibleColumnString);
    columnOrder =
      pageAlias === 'userLists'
        ? yield select(listsCommonSelectors.getColumnOrder)
        : pageAlias === 'myCompanies'
        ? yield select(myCompaniesColumnSelectors.getColumnOrder)
        : yield select(columnSelectors.getColumnOrder);
  }

  if (savePinnedColumns) {
    pinnedColumns =
      pageAlias === 'userLists'
        ? yield select(listsCommonSelectors.getPinnedColumnsString)
        : pageAlias === 'myCompanies'
        ? yield select(myCompaniesColumnSelectors.getPinnedColumnsString)
        : yield select(columnSelectors.getPinnedColumnsString);
  }

  const searchKey: string =
    pageAlias === 'userLists'
      ? yield select(searchSelectors.getSearchKey('lists'))
      : pageAlias === 'myCompanies'
      ? yield select(searchSelectors.getSearchKey('myCompanies'))
      : yield select(companySearchSelectors.getSearchKey);

  const isSourceScrub: boolean = yield select(listsDetailsSelectors.isSourceScrubRoute);

  const searchCount: number = ['userLists', 'sourceScrubLists'].includes(pageAlias as string)
    ? yield select(listsSelectors.getSearchCount(isSourceScrub))
    : ['myCompanies', 'advancedSearch'].includes(pageAlias as string)
    ? yield select(searchCountSelectors.getSearchCount(pageAlias as GridKeysMain))
    : yield select(companySearchSelectors.getSearchCount);

  try {
    const res: AxiosResponse<SearchSaveResponse> = yield call(
      SavedSearchesService.createCompanySearch,
      title,
      saveFilters ? preProcessSearch(searchKey) : '',
      searchCount,
      filterQuery,
      // @ts-ignore
      sorting,
      // @ts-ignore
      columns,
      // @ts-ignore
      columnOrder || undefined,
      // @ts-ignore
      pinnedColumns,
      isDefault,
      undefined,
      undefined,
      defaultOptx
    );

    if (res.data) {
      const filterSources: FilterSource[] = yield select(filterSourcesSelectors.getCompanyFilters);
      const clearedFilters: Dictionary<PreselectedFilter> = yield select(
        filterSelectors.getClearedFilter('advancedSearch')
      );

      if (isDefault) {
        yield put(actions.makeFiltersDefaultSuccess(res.data.unique_id as number));
        yield put(filterActions.changeDefaultView(res.data));
      }

      yield put(actions.saveSearchSuccess(res.data, filterSources, clearedFilters, true));

      if (saveFilters && pageAlias === 'advancedSearch') {
        yield put(searchActions.saveSearchTitle({ gridKey: 'advancedSearch', data: title }));
      }

      yield put(
        actions.applyViewSuccess({
          id: res.data.unique_id,
          label: res.data.title,
          pageAlias,
        })
      );

      NotificationService.success(`${title} company search was saved.`);
    }

    yield put(autoCompleteActions.fetchSearchAutocomplete());
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Error saving company search!');
    yield put(actions.saveSearchFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

// this saga is called only for "OPTX Default" and views coming from backend
function* modifyViewSaga(action: PayloadAction<ModifyView, any, ViewOption>) {
  const {
    filters: formSaveFilters,
    sorting: formSaveSorting,
    columns: formSaveColumns,
    pinnedColumns: formSavePinnedColumns,
    title: formTitle,
    isDefault,
    id,
  } = action.payload;
  const savedSearches: SearchSave[] = yield select(selectors.getAllViews);
  const defaultSearch = savedSearches.find(search => search.is_default);
  const pageAlias: RouteAliases | null = getRouteAlias();

  // if a custom UI view is set as default and we want to set optx default view as default
  // we just need to set custom UI view "is_default" property to false and reset it's user setting
  if (
    defaultSearch &&
    customUIViewIds.includes(defaultSearch.unique_id as CustomUIViewIds) &&
    isDefault &&
    id === 'default'
  ) {
    yield put(
      userActions.updateUserSettings({
        default_view: '',
      })
    );

    yield put(
      actions.modifyCustomUIViewSuccess(
        undefined,
        defaultSearch.unique_id as CustomUIViewIds,
        false
      )
    );

    yield put(filterActions.changeDefaultView());

    NotificationService.success(`${defaultOptxView.title} company search was saved.`);

    return;
  }

  let editedView;
  let saveFilters;
  let saveSorting;
  let saveColumns;
  let savePinnedColumns;

  // when editing a view, check whether we need to save new data or leave out data
  // for the default state we have a special case. we don't a saved search coming from api for "OPTX Default",
  // so we create it in UI. that's why when setting OPTX Default as the default view, we will send the current
  // default view with the is_default property being false.
  if (id === 'default') {
    editedView = { ...defaultSearch } as SearchSave;
    editedView.is_default = false;
    saveFilters = hasFilters(editedView.search_criteria);
    saveSorting = !!editedView.sortBy;
    saveColumns = !!editedView.columns;
    savePinnedColumns = !!editedView.pinned_columns;
  } else {
    editedView = { ...action.meta };
    editedView.is_default = isDefault;
    saveFilters = formSaveFilters;
    saveSorting = formSaveSorting;
    saveColumns = formSaveColumns;
    savePinnedColumns = formSavePinnedColumns;
  }

  let filters: Dictionary<PreselectedFilter>;
  let sorting: SortByRule<any>[];
  let columns: string;
  let columnOrder: ColumnKeys[];
  let pinnedColumns: string;

  if (
    saveFilters &&
    !hasFilters(editedView.search_criteria) &&
    editedView.search_info.type !== 'combined'
  ) {
    filters =
      pageAlias === 'userLists'
        ? yield select(filterSelectors.getFilter('lists'))
        : pageAlias === 'myCompanies'
        ? yield select(filterSelectors.getFilter('myCompanies'))
        : yield select(filterSelectors.getFilter('advancedSearch'));
  } else if (saveFilters && hasFilters(editedView.search_criteria)) {
    filters = editedView.filters;
  }

  if (saveSorting && !editedView.sortBy) {
    sorting =
      pageAlias === 'userLists'
        ? yield select(listsSelectors.getSorting)
        : pageAlias === 'myCompanies'
        ? yield select(myCompaniesSortSelectors.getSorting)
        : yield select(companySearchSelectors.getSorting);
  } else if (saveSorting && editedView.sortBy) {
    sorting = editedView.sortBy;
  }

  if (saveColumns && typeof editedView.columns !== 'string') {
    const displayedColumns: string =
      pageAlias === 'userLists'
        ? yield select(listsCommonSelectors.getVisibleColumnString)
        : pageAlias === 'myCompanies'
        ? yield select(myCompaniesColumnSelectors.getVisibleColumnString)
        : yield select(columnSelectors.getVisibleColumnString);
    columns = displayedColumns;
    columnOrder =
      pageAlias === 'userLists'
        ? yield select(listsCommonSelectors.getColumnOrder)
        : pageAlias === 'myCompanies'
        ? yield select(myCompaniesColumnSelectors.getColumnOrder)
        : yield select(columnSelectors.getColumnOrder);
  } else if (saveColumns && typeof editedView.pinned_columns === 'string') {
    columns = editedView.columns as string;
  }

  if (savePinnedColumns && typeof editedView.pinned_columns !== 'string') {
    const displayedPinnedColumns: string =
      pageAlias === 'userLists'
        ? yield select(listsCommonSelectors.getPinnedColumnsString)
        : pageAlias === 'myCompanies'
        ? yield select(myCompaniesColumnSelectors.getPinnedColumnsString)
        : yield select(columnSelectors.getPinnedColumnsString);
    pinnedColumns = displayedPinnedColumns;
  } else if (savePinnedColumns && typeof editedView.pinned_columns === 'string') {
    pinnedColumns = editedView.pinned_columns as unknown as string;
  }

  const filterSourcesMap: Dictionary<BaseFilter> = yield select(
    filterSourcesSelectors.getFiltersMap
  );
  // @ts-ignore
  const filterQuery = filters ? mapFiltersToURLParams(filterSourcesMap, filters) : undefined;
  const defaultOptx: string = yield select(userSelectors.getDefaultScore);
  const searchKey = editedView.searchKey ? preProcessSearch(editedView.searchKey) : '';

  try {
    const res: AxiosResponse<SearchSaveResponse> = yield call(
      SavedSearchesService.createCompanySearch,
      editedView.title,
      searchKey,
      editedView.count,
      filterQuery,
      // @ts-ignore
      sorting,
      // @ts-ignore
      columns,
      // @ts-ignore
      columnOrder,
      // @ts-ignore
      pinnedColumns,
      editedView.is_default,
      editedView.unique_id as number,
      id !== 'default' && editedView.title !== formTitle ? formTitle : undefined,
      defaultOptx
    );
    const cancelSearch: boolean = yield select(searchSelectors.isCancelled('advancedSearch'));

    if (cancelSearch) {
      yield cancel();
    }

    if (res.data) {
      const filterSources: FilterSource[] = yield select(filterSourcesSelectors.getCompanyFilters);
      const clearedFilters: Dictionary<PreselectedFilter> = yield select(
        filterSelectors.getClearedFilter('advancedSearch')
      );
      yield put(actions.modifyViewSuccess(res.data, filterSources, clearedFilters));

      // update filters in default view in store when changing the default property of a view
      if (id === 'default' || (defaultSearch && defaultSearch.unique_id === id && !isDefault)) {
        yield put(filterActions.changeDefaultView());
      } else {
        yield put(filterActions.changeDefaultView(res.data));
      }

      // if a default custom UI view is set as default and we make a regular view to be the
      // new default, set current custom UI view's "is_default" property to false and
      // reset it's user setting
      if (
        defaultSearch &&
        customUIViewIds.includes(defaultSearch.unique_id as CustomUIViewIds) &&
        isDefault
      ) {
        yield put(
          userActions.updateUserSettings({
            default_view: '',
          })
        );

        yield put(
          actions.modifyCustomUIViewSuccess(
            undefined,
            defaultSearch.unique_id as CustomUIViewIds,
            false
          )
        );
      }

      NotificationService.success(
        `${id === 'default' ? formTitle : editedView.title} company search was saved.`
      );
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Error saving company search!');
    yield put(actions.saveSearchFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

// this saga is called only when changing default option on custom UI views, which are hardcoded on the UI side
function* modifyCustomUIViewSaga(
  action: PayloadAction<{ id: CustomUIViewIds; isDefault: boolean }>
) {
  const { id, isDefault } = action.payload;

  if (isDefault) {
    const savedSearches: SearchSave[] = yield select(selectors.getAllViews);
    const defaultSearch = savedSearches.find(search => search.is_default);

    const defaultOptx: string = yield select(userSelectors.getDefaultScore);

    // if we're setting a custom UI view to default, but we have a regular view
    // previously set as default, then we need to make a request and change
    // that view's default setting
    if (defaultSearch) {
      try {
        const filterSourcesMap: Dictionary<BaseFilter> = yield select(
          filterSourcesSelectors.getFiltersMap
        );
        const filterQuery = mapFiltersToURLParams(filterSourcesMap, defaultSearch.filters);
        const res: AxiosResponse<SearchSaveResponse> = yield call(
          SavedSearchesService.createCompanySearch,
          defaultSearch.title,
          defaultSearch.searchKey ? preProcessSearch(defaultSearch.searchKey) : '',
          defaultSearch.count,
          filterQuery,
          defaultSearch.sortBy,
          defaultSearch.columns === null ? undefined : defaultSearch.columns,
          defaultSearch.column_order === null
            ? undefined
            : (defaultSearch.column_order.split(',') as ColumnKeys[]),
          defaultSearch.pinned_columns === null ? undefined : defaultSearch.pinned_columns,
          false,
          defaultSearch.unique_id as number,
          undefined,
          defaultOptx
        );

        const cancelSearch: boolean = yield select(searchSelectors.isCancelled('advancedSearch'));

        if (cancelSearch) {
          yield cancel();
        }

        if (res.data) {
          const filterSources: FilterSource[] = yield select(
            filterSourcesSelectors.getCompanyFilters
          );
          const clearedFilters: Dictionary<PreselectedFilter> = yield select(
            filterSelectors.getClearedFilter('advancedSearch')
          );
          yield put(
            actions.modifyCustomUIViewSuccess(
              mapSearchSave(res.data, clearedFilters, filterSources),
              id,
              true
            )
          );

          NotificationService.success(`${defaultSearch.title} company search was saved.`);
        }
      } catch (e: any) {
        const errorMessage = getErrorMessage(e, 'Error saving company search!');
        yield put(actions.saveSearchFail(errorMessage));
        NotificationService.error(errorMessage);
      }
    } else {
      yield put(actions.modifyCustomUIViewSuccess(undefined, id, true));
      yield put(filterActions.changeDefaultView(defaultSearch));
      NotificationService.success(`${customUIViews[id].title} company search was saved.`);
    }

    yield put(
      userActions.updateUserSettings({
        default_view: id,
      })
    );

    yield put(filterActions.changeDefaultView(customUIViews[id]));
  } else {
    yield put(
      userActions.updateUserSettings({
        default_view: '',
      })
    );
    yield put(actions.modifyCustomUIViewSuccess(undefined, id, false));
    yield put(filterActions.changeDefaultView());
  }
}

export function* deleteSearchSaga(action: PayloadAction<DeleteCompanySearch>) {
  const { id: searchId, viewId } = action.payload;

  const savedSearches: SearchSave[] = yield select(searchesSelectors.getSavedSearches);
  const shouldRefetch = fetchAfterDelete(searchId, savedSearches);

  try {
    const res: AxiosResponse<SearchSaveResponse> = yield call(
      SavedSearchesService.deleteCompanySearch,
      Number(searchId)
    );

    if (res.data) {
      yield put(actions.deleteSearchSuccess(Number(searchId)));

      if (shouldRefetch) {
        yield put(savedSearchesActions.initializeSavedSearchesAndViews());
      }

      if (searchId === viewId) {
        yield put(actions.applyViewSuccess({ id: '', label: '', pageAlias: null }));
      }

      const searchTitle: string = yield select(getViewLabel('advancedSearch'));
      const allSavedSearches = Object.keys(yield select(searchesSelectors.getTitles));

      // if condition is true, useRefreshView hook will work
      // to make applying default view smooth, we have to set loader in advance
      if (searchTitle && !allSavedSearches.includes(searchTitle)) {
        yield put(savedSearchesActions.setLoader());
      }

      const successMessage = 'Company search deleted successfully';
      NotificationService.success(successMessage);
    } else {
      const errorMessage = 'Error deleting company search!';
      yield put(actions.deleteSearchFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = 'Error deleting company search!';
    yield put(actions.deleteSearchFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* renameSearchSaga(action: PayloadAction<RenameCompanySearch>) {
  const { searchId, title, viewId } = action.payload;
  const pageAlias: RouteAliases | null = getRouteAlias();

  try {
    const res: AxiosResponse<SearchSaveResponse> = yield call(
      SavedSearchesService.renameCompanySearch,
      searchId,
      title
    );

    if (res.data) {
      yield put(actions.renameSearchSuccess(searchId, title));

      if (searchId === viewId) {
        yield put(actions.applyViewSuccess({ id: searchId, label: title, pageAlias }));
      }

      const searchTitle: string = yield select(getViewLabel('advancedSearch'));
      const allSavedSearches = Object.keys(yield select(searchesSelectors.getTitles));

      // if condition is true, useRefreshView hook will work
      // to make applying default view smooth, we have to set loader in advance
      if (searchTitle && !allSavedSearches.includes(searchTitle)) {
        yield put(savedSearchesActions.setLoader());
      }

      const successMessage = 'Company search renamed successfully';
      NotificationService.success(successMessage);
    } else {
      const errorMessage = 'Error renaming company search!';
      yield put(actions.renameSearchFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    let errorMessage: string;

    if (
      Object.values(e.response.data.errors)[0] ===
      `Company search list with a title ${title} already exists.`
    ) {
      errorMessage = Object.values(e.response.data.errors)[0] as string;
    } else {
      errorMessage = 'Error renaming company search!';
    }

    yield put(actions.renameSearchFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

function* makeFiltersDefaultSaga(action: PayloadAction<number | string, any, boolean>) {
  const id = action.payload;

  const defaultOptx: string = yield select(userSelectors.getDefaultScore);
  const filterSourcesMap: Dictionary<BaseFilter> = yield select(
    filterSourcesSelectors.getFiltersMap
  );

  const savedSearches: SearchSave[] = yield select(selectors.getAllViews);
  const defaultSearch = savedSearches.find(search => search.is_default);

  let modifyViewId = id;
  let isCreatedViewDefault = true;

  // conditions to check whether we want to set a user created view as default or not
  // and get the view data that we want to send to backend
  if (
    (customUIViewIds.includes(id as CustomUIViewIds) && defaultSearch) ||
    id === defaultSearch?.unique_id
  ) {
    modifyViewId = defaultSearch.unique_id;
    isCreatedViewDefault = false;
  }

  const savedSearch: SearchSave = yield select(state =>
    selectors.getSearch(state, modifyViewId as string)
  );

  const {
    searchKey,
    filters,
    title,
    count: searchCount,
    sortBy,
    columns,
    column_order: columnOrder,
    pinned_columns: pinnedColumns,
    unique_id: uniqueId,
  } = savedSearch;

  const filterQuery = mapFiltersToURLParams(filterSourcesMap, filters);

  try {
    // we need to make the call to company search endpoint only if we make
    // a user created view as default, remove it as default or if we make a
    // custom UI view default and we currently have a user created view already as default.
    // when we switch default states between user created views, it is enough to make a
    // single request for the new default view
    if (
      !customUIViewIds.includes(id as CustomUIViewIds) ||
      (customUIViewIds.includes(id as CustomUIViewIds) &&
        defaultSearch &&
        !customUIViewIds.includes(defaultSearch.unique_id as CustomUIViewIds))
    ) {
      const res: AxiosResponse<SearchSaveResponse> = yield call(
        // @ts-ignore
        SavedSearchesService.createCompanySearch,
        title,
        preProcessSearch(searchKey),
        searchCount,
        filterQuery,
        sortBy,
        columns,
        columnOrder?.split(','),
        pinnedColumns,
        isCreatedViewDefault,
        uniqueId,
        undefined,
        defaultOptx
      );

      if (res.data) {
        const filterSources: FilterSource[] = yield select(
          filterSourcesSelectors.getCompanyFilters
        );
        const clearedFilters: Dictionary<PreselectedFilter> = yield select(
          filterSelectors.getClearedFilter('advancedSearch')
        );
        yield put(actions.modifyViewSuccess(res.data, filterSources, clearedFilters));

        if (action.meta === undefined) {
          yield put(filterActions.changeDefaultView());
        } else {
          yield put(filterActions.changeDefaultView(res.data));
        }
      }
    }

    // we need to make the call to user settings endpoint to set the custom UI view
    // as default only if we are setting it as default or unsetting it, either by
    // using the menu action for that custom view or choosing a user created view as default
    if (
      customUIViewIds.includes(id as CustomUIViewIds) ||
      customUIViewIds.includes(defaultSearch?.unique_id as CustomUIViewIds)
    ) {
      const defaultView =
        customUIViewIds.includes(id as CustomUIViewIds) &&
        !customUIViewIds.includes(defaultSearch?.unique_id as CustomUIViewIds)
          ? customUIViews[id].unique_id
          : '';
      yield put(
        userActions.updateUserSettings({
          default_view: defaultView as CustomUIViewIds,
        })
      );
    }

    NotificationService.success(`${title} company search was updated.`);
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Error saving company search!');
    yield put(actions.saveSearchFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

function* initializeSavedSearchesAndViewsSaga() {
  const settings: UserSettings = yield select(userSelectors.getSettings);
  const savedQuery = settings.session_settings?.saved_company_searches_filters ?? '';
  const defaultCustomUIView: CustomUIViewIds = yield select(userSelectors.getDefaultCustomUIView);

  try {
    const res: AxiosResponse<any> = yield call(SavedSearchesService.getCompanySearches, {
      query: savedQuery,
    } as ListsSearchData);

    if (res.data) {
      const filterSources: FilterSource[] = yield select(filterSourcesSelectors.getCompanyFilters);
      const clearedFilters: Dictionary<PreselectedFilter> = yield select(
        filterSelectors.getClearedFilter('advancedSearch')
      );
      const userSettings: UserInformation = yield select(userSelectors.getUserSettings);
      const isAnalyst: boolean = yield select(userSelectors.getIsAnalyst);

      yield put(
        actions.initializeSavedSearchesAndViewsSuccess(
          res.data.lists,
          filterSources,
          clearedFilters,
          res.data.total_list_count,
          savedQuery,
          {
            pageNumber: 1,
            pageSize: userSettings.settings?.lists_and_searches_results_per_page || 50,
          },
          undefined,
          undefined,
          defaultCustomUIView,
          isAnalyst
        )
      );
    }
  } catch (e: any) {
    yield call(searchSavedSearchesFail);
  }
}

export default function* companySavedSearchesSaga() {
  yield takeLatest(actions.fetchSavedSearches, fetchSavedSearchesSaga);
  yield takeLatest(actions.saveSearch, saveSearchSaga);
  yield takeLatest(actions.saveView, saveViewSaga);
  yield takeLatest(actions.modifyView, modifyViewSaga);
  yield takeLatest(actions.modifyCustomUIView, modifyCustomUIViewSaga);
  yield takeLatest(actions.deleteSearch, deleteSearchSaga);
  yield takeLatest(actions.renameSearch, renameSearchSaga);
  yield takeLatest(actions.savedCompanySearchPagination, savedSearchesPaginationSaga);
  yield takeLatest(actions.makeFiltersDefault, makeFiltersDefaultSaga);
  yield takeLatest(actions.initializeSavedSearchesAndViews, initializeSavedSearchesAndViewsSaga);
  yield takeLatest(actions.sortCompanySearchesLists, sortCompanySearchesListsSaga);
}
