import React, { FC } from 'react';

interface Normalize {
  value: number | null;
  min: number;
  max: number;
  scaleMin: number;
  scaleMax: number;
}

interface NormalizeDataset {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
}

interface TrendingLineProps {
  points: Array<number | null>;
  color: string;
  svgWidth?: number;
  svgHeight?: number;
  viewBoxWidth?: number;
  viewBoxHeight?: number;
  padding?: number;
}

const normalize = ({ value, min, max, scaleMin = 0, scaleMax = 1 }: Normalize) => {
  // If the `min` and `max` are the same value, it means our dataset is flat.
  // For now, let's assume that flat data should be aligned to the bottom.
  if (min === max) {
    return scaleMin;
  }

  if (value === null) {
    return scaleMin + scaleMax * 2;
  }

  return scaleMin + ((value - min) * (scaleMax - scaleMin)) / (max - min);
};

const normalizeDataset = (
  data: Array<number | null>,
  { minX, maxX, minY, maxY }: NormalizeDataset
) => {
  // For the X axis, we want to normalize it based on its index in the array.
  // For the Y axis, we want to normalize it based on the element's value.
  //
  // X axis is easy: just evenly-space each item in the array.
  // For the Y axis, we first need to find the min and max of our array,
  // and then normalize those values between 0 and 1.
  const numbersData = data.filter(point => !Number.isNaN(point)) as number[];
  const boundariesX = { min: 0, max: data.length - 1 };
  const boundariesY = {
    min: Math.min(...numbersData),
    max: Math.max(...numbersData),
  };

  const areAllPointsEqual = numbersData.every((val, i, arr) => val === arr[0]);
  const normalizedData = data.map((point, index) => ({
    x: normalize({
      value: index,
      min: boundariesX.min,
      max: boundariesX.max,
      scaleMin: minX,
      scaleMax: maxX,
    }),
    y: normalize({
      value: point,
      min: boundariesY.min,
      max: boundariesY.max,
      scaleMin: areAllPointsEqual ? minY / 2 : minY,
      scaleMax: maxY,
    }),
  }));

  return normalizedData;
};

const TrendingLine: FC<TrendingLineProps> = ({
  svgWidth = 50,
  svgHeight = 15,
  viewBoxWidth = 50,
  viewBoxHeight = 15,
  points = [],
  padding = 1,
  color,
}) => {
  let isPositive = false;
  const invalidPoints = points.every(point => point === null || point === 0);
  const areAllPointsEqual = points.every((val, i, arr) => val === arr[0]);
  const pointsTrend = points.filter(point => point !== null) as number[];
  const { 0: first, length, [length - 1]: last } = pointsTrend;
  const normalizedPoints = normalizeDataset(
    points.filter(point => point !== null),
    {
      minX: padding,
      maxX: viewBoxWidth - padding,
      minY: viewBoxHeight - padding,
      maxY: padding,
    }
  )
    .map(({ x, y }, i) => {
      if (pointsTrend.length < 5) {
        // if we don't have 5 points, skip rendering line for missing points
        return `${(svgWidth / pointsTrend.length) * (i + 1)},${y}`;
      }

      return `${x},${y}`;
    })
    .join(' ');

  switch (true) {
    case length === 1:
      isPositive = true;
      break;

    case length > 1: {
      if (first < last) {
        isPositive = true;
        break;
      }

      if (areAllPointsEqual) {
        isPositive = true;
        break;
      }

      isPositive = false;
      break;
    }

    default:
      isPositive = false;
      break;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={svgWidth}
      height={svgHeight}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
    >
      {!invalidPoints && (
        <polyline
          points={normalizedPoints}
          stroke={isPositive ? color : '#D9D9D9'}
          strokeWidth="2"
          fill="none"
        />
      )}
    </svg>
  );
};

export default TrendingLine;
