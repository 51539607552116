import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router-dom';
// models
import { SalesloftContact } from '@optx/models/Salesloft';
import { CompanyProfile } from '@optx/models/Company';
// constants
import { ISO_DATE_FORMAT } from '@optx/constants/format/date';
// utils
import { validateContactLimit } from '@optx/utils/contact';
import { contactFilterOption, reorderPrimaryContact } from '@optx/utils/select/select';
// redux
import { getSalesloftEmails } from '@optx/utils/salesloft';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { actions as bulkActions } from '@features/bulk-actions/equity-touch';
// components
import ContactOptionIcon from '@optx/shared/view/molecules/contacts/ContactOptionIcon';
import Styled from '@optx/shared/view/molecules/Select/Styled/ContactSelectWrapper.styled';
import ContactOption from '@optx/shared/view/molecules/contacts/ContactOption';
import { CompanyUserContact } from '@optx/models/api/contacts';
import ContactTags from '@optx/shared/view/molecules/contacts/ContactTags';
import { Context } from '@optx/screens/App/EquityTouchBulk/components/common/Context';

const { Option } = Select;

interface SelectContactProps {
  contacts: SalesloftContact[];
  companyId: number;
  isCell?: boolean;
}

const SelectContactsIndividually: React.FC<SelectContactProps> = ({ contacts, companyId }) => {
  const { step, errors, setErrors } = useContext(Context);
  const dispatch = useDispatch();
  // @ts-ignore
  const { grid } = useParams();
  const temporaryData = useSelector(bulkActionsSelectors.equityTouch.getTemporaryData);
  const formData = useSelector(bulkActionsSelectors.equityTouch.getFormData);
  const companiesData = useSelector(
    bulkActionsSelectors.selectedCompanies.getSelectedCompanies(grid)
  );
  const companyEqtData = useSelector(bulkActionsSelectors.equityTouch.getCompanyEqtData(companyId));
  const companyData = useSelector(
    bulkActionsSelectors.equityTouch.getCompanyData
  ) as CompanyProfile;
  const [selectedContacts, setSelectedContacts] = useState<number[]>([]);

  useEffect(() => {
    if (companyEqtData && Array.isArray(companyEqtData?.salesloft_contacts)) {
      setSelectedContacts(
        reorderPrimaryContact(
          (companyEqtData?.salesloft_contacts as SalesloftContact[]).map(
            contact => contact.person_id as number
          ),
          contacts
        )
      );
    } else {
      setSelectedContacts([]);
    }

    if (errors) {
      setErrors([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyEqtData]);

  const handleSelection = (value: number, type: 'select' | 'deselect') => {
    if (errors.length) {
      setErrors([]);
    }

    const contact = contacts.find(contact => contact.person_id === value);

    if (
      type === 'select' &&
      companyEqtData &&
      companyEqtData?.salesloft_contacts &&
      !validateContactLimit((companyEqtData?.salesloft_contacts as SalesloftContact[])?.length + 1)
    ) {
      return;
    }

    if (type === 'select') {
      if (contact) {
        if (
          Array.isArray((temporaryData?.step2?.[companyData.company_id] as any)?.salesloft_contacts)
        ) {
          setSelectedContacts(
            reorderPrimaryContact([...(selectedContacts as any), contact.person_id], contacts)
          );
          dispatch(
            bulkActions.updateTemporaryData({
              step2: {
                ...temporaryData?.step2,
                [companyData?.company_id]: {
                  ...(temporaryData?.step2?.[companyData.company_id] as any),
                  salesloft_contacts: [
                    ...(temporaryData?.step2?.[companyData.company_id] as any).salesloft_contacts,
                    {
                      ...contact,
                      ...getSalesloftEmails(contact.email as string | null),
                    },
                  ],
                },
              } as any,
            })
          );
          dispatch(
            bulkActions.autoSave({
              step: step + 1,
              grid,
              queue: companyData.company_id,
              companiesById: {
                ...temporaryData?.step2,
                [companyData?.company_id]: {
                  ...(temporaryData?.step2?.[companyData.company_id] as any),
                  salesloft_contacts: [
                    ...(temporaryData?.step2?.[companyData.company_id] as any).salesloft_contacts,
                    {
                      ...contact,
                      ...getSalesloftEmails(contact.email as string | null),
                    },
                  ],
                },
              },
              companiesData,
              firstStepData: (temporaryData as any).step1,
              formData: formData as any,
              date: moment().format(ISO_DATE_FORMAT),
            })
          );
        } else {
          setSelectedContacts([contact?.person_id as number]);
          dispatch(
            bulkActions.updateTemporaryData({
              step2: {
                ...temporaryData?.step2,
                [companyData?.company_id]: {
                  ...(temporaryData?.step2?.[companyData.company_id] as any),
                  salesloft_contacts: [
                    {
                      ...contact,
                      ...getSalesloftEmails(contact.email as string | null),
                    },
                  ],
                },
              } as any,
            })
          );
          dispatch(
            bulkActions.autoSave({
              step: step + 1,
              grid,
              queue: companyData.company_id,
              companiesById: {
                ...temporaryData?.step2,
                [companyData?.company_id]: {
                  ...(temporaryData?.step2?.[companyData.company_id] as any),
                  salesloft_contacts: [
                    {
                      ...contact,
                      ...getSalesloftEmails(contact.email as string | null),
                    },
                  ],
                },
              },
              companiesData,
              firstStepData: (temporaryData as any).step1,
              formData: formData as any,
              date: moment().format(ISO_DATE_FORMAT),
            })
          );
        }
      }
    }

    if (type === 'deselect') {
      const salesloftContacts = [
        ...(temporaryData?.step2?.[companyData.company_id] as any)?.salesloft_contacts,
      ];
      const removeContactIndex = [...(salesloftContacts as SalesloftContact[])].findIndex(
        contact => contact.person_id === value
      );

      salesloftContacts.splice(removeContactIndex, 1);
      setSelectedContacts(
        reorderPrimaryContact(
          [...salesloftContacts].map(contact => contact.person_id),
          contacts
        )
      );
      dispatch(
        bulkActions.updateTemporaryData({
          step2: {
            ...temporaryData?.step2,
            [companyData?.company_id]: {
              ...(temporaryData?.step2?.[companyData.company_id] as any),
              salesloft_contacts: salesloftContacts,
            },
          } as any,
        })
      );
      dispatch(
        bulkActions.autoSave({
          step: step + 1,
          grid,
          queue: companyData.company_id,
          companiesById: {
            ...temporaryData?.step2,
            [companyData?.company_id]: {
              ...(temporaryData?.step2?.[companyData.company_id] as any),
              salesloft_contacts: salesloftContacts,
            },
          },
          companiesData,
          firstStepData: (temporaryData as any).step1,
          formData: formData as any,
          date: moment().format(ISO_DATE_FORMAT),
        })
      );
    }
  };

  const getDisabledStatus = () => {
    if ((companyData as CompanyProfile).is_in_et) return true;
    const tempCompData = temporaryData?.step2?.[companyData.company_id] as any;
    if (
      tempCompData &&
      Array.isArray(tempCompData?.salesloft_contacts) &&
      tempCompData?.salesloft_contacts?.length
    )
      return false;

    return (
      (tempCompData && tempCompData?.cadence === undefined) ||
      (tempCompData && tempCompData?.cadence === '') ||
      (tempCompData && !('cadence' in tempCompData))
    );
  };

  return (
    <>
      <Styled.SelectWrapper
        showArrow
        placeholder="Select contacts"
        onSelect={(value, option) => handleSelection(Number(value), 'select')}
        onDeselect={(value, option) => handleSelection(Number(value), 'deselect')}
        disabled={getDisabledStatus()}
        optionFilterProp="children"
        filterOption={contactFilterOption}
        mode="multiple"
        value={selectedContacts}
        dropdownMatchSelectWidth={250}
        tagRender={props => <ContactTags {...props} />}
        menuItemSelectedIcon={ContactOptionIcon}
      >
        {contacts.map(contact => (
          <Option
            key={contact.person_id as number}
            value={contact.person_id as number}
            className="contact-option"
          >
            <ContactOption contact={contact as CompanyUserContact} />
          </Option>
        ))}
      </Styled.SelectWrapper>
      <Styled.ContactSelectGlobal />
    </>
  );
};

export default SelectContactsIndividually;
