import React, { useState } from 'react';
import { Dropdown, Button, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { EQUITY_TOUCH_INTEGRATION } from '@optx/constants/exportedFileName';
import ExportCSVButton from './Header/ExportCSVButton';

const ETIntegrationSearchDropdown: React.FC = () => {
  const [visible, setVisible] = useState(false);

  const menu = (
    <Menu>
      <ExportCSVButton
        fileName={EQUITY_TOUCH_INTEGRATION}
        hideDropdown={() => setVisible(!visible)}
      />
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      placement="bottomRight"
      visible={visible}
      onVisibleChange={() => setVisible(!visible)}
    >
      <Button type="text" icon={<EllipsisOutlined style={{ fontSize: 28 }} />} />
    </Dropdown>
  );
};

export default ETIntegrationSearchDropdown;
