import { GRID_KEYS, GRID_MODALS_INITIAL_STATE } from '@optx/constants/grid';
import { generateGridReducerInitialState } from '@optx/utils/grid';
import { CaseReducer, PayloadAction, createReducer } from '@reduxjs/toolkit';
import { toggleModal } from './actions';
import { FiltersModalState, ToggleModalPayload } from './interfaces';

const initialState: FiltersModalState = generateGridReducerInitialState(
  GRID_KEYS,
  GRID_MODALS_INITIAL_STATE
);

export const gridToggleReducer: CaseReducer<
  FiltersModalState,
  PayloadAction<ToggleModalPayload>
> = (draftState, action) => {
  const { gridKey } = action.payload;

  draftState[gridKey].isModalOpen = !draftState[gridKey].isModalOpen;
};

const reducer = createReducer<FiltersModalState>(initialState, builder =>
  builder.addCase(toggleModal, gridToggleReducer)
);

export default reducer;
