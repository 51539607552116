import { utils as requestUtils } from '@features/request';
import { featureName } from '../../featureName';

export const fetchWWWebsiteRank = requestUtils.createActionTypes(
  featureName,
  'FETCH_WW_WEBSITE_RANK'
);
export const fetchUSWebsiteRank = requestUtils.createActionTypes(
  featureName,
  'FETCH_US_WEBSITE_RANK'
);
