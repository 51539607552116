import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { saga as histogramSaga } from '@features/histograms/histograms-my-companies';
// redux
import myCompaniesReducer from '@redux/my-companies/reducer';
import { saga } from '@redux/my-companies/search';
import { saga as filtersCardsSaga } from '@optx/redux/my-companies/filters-cards';
import { saga as columnsSaga } from '@redux/my-companies/columns';

const useInjectMyCompanies = () => {
  useInjectReducer({
    key: 'myCompanies',
    reducer: myCompaniesReducer,
  });
  useInjectSaga({
    key: 'myCompaniesSearchSaga',
    saga,
  });
  useInjectSaga({
    key: 'myCompaniesFiltersCardsSaga',
    saga: filtersCardsSaga,
  });
  useInjectSaga({
    key: 'myCompaniesHistogramsSaga',
    saga: histogramSaga,
  });
  useInjectSaga({
    key: 'myCompaniesColumnsSaga',
    saga: columnsSaga,
  });
};

export default useInjectMyCompanies;
