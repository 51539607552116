import { useState } from 'react';

const useToggle = (initialState: boolean = false): [boolean, VoidFunction] => {
  const [isOpen, setIsOpen] = useState(initialState);
  const toggle = () => setIsOpen(!isOpen);

  return [isOpen, toggle];
};

export default useToggle;
