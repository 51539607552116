import styled from 'styled-components';

import Icon from '../../../../components/common/Icon/index';

const ThumbsIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  margin-left: 15px;
`;

const ThumbsUpIcon = styled(ThumbsIcon)`
  color: #52c41a;
`;

const FitCellInnerTextContainer = styled.span`
  margin-left: 15px;
`;

const TableCellInner = styled.span`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Styled = {
  ThumbsIcon,
  ThumbsUpIcon,
  FitCellInnerTextContainer,
  TableCellInner,
};
