import React, { ChangeEvent, useEffect, useRef } from 'react';
import { MenuInfo, MenuClickEventHandler } from 'rc-menu/lib/interface';
import { Dropdown, Menu, Input as AntdInput } from 'antd';
import { Input } from 'formik-antd';
// models
import { SelectOption } from '@optx/models/Option';
// utils
import { checkForNegativeSelectedOption, highlightText } from '@optx/utils/text';
// hooks
import { useOnClickOutside } from '@optx/common/hooks/useOnClickOutside';

interface InputWithDropdownProps {
  name?: string;
  index?: number;
  options?: SelectOption[];
  placeholder?: string;
  customWidth?: string;
  value?: string;
  showDropdown?: boolean;
  onInputChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onSelectOption: (event: MenuInfo, index: number | undefined) => void;
  onClick?: () => void;
  allowClear?: boolean;
  open?: boolean;
  dropDownStyle?: React.CSSProperties;
  closeOnSelect?: boolean;
  hideDropdownOnFocusOut?: boolean;
  overlayClassName?: string;
}

const InputWithDropdown: React.FC<InputWithDropdownProps> = ({
  name,
  index,
  options,
  placeholder,
  value,
  showDropdown,
  onInputChange,
  onClick,
  onSelectOption,
  allowClear,
  open = undefined,
  customWidth = 'max-content',
  dropDownStyle,
  closeOnSelect,
  hideDropdownOnFocusOut = false,
  overlayClassName,
}) => {
  const dropdownContentRef = useRef<any>(null);

  const [visibleDropdown, setVisibleDropdown] = React.useState(false);

  useOnClickOutside(dropdownContentRef, () => open === undefined && setVisibleDropdown(false));

  useEffect(() => {
    setVisibleDropdown(open ?? false);
  }, [open]);

  useEffect(() => {
    if (hideDropdownOnFocusOut) {
      const listener = (event: Event) => {
        // Do nothing if focuse is on the ref's element or descendent elements
        if (
          !dropdownContentRef.current ||
          (dropdownContentRef.current && dropdownContentRef.current.contains(event.target))
        ) {
          return;
        }

        setVisibleDropdown(false);
      };

      document.addEventListener('focusin', listener);

      return () => {
        document.removeEventListener('focusin', listener);
      };
    }
  }, [dropdownContentRef, hideDropdownOnFocusOut]);

  if (!options?.length) {
    return null;
  }

  const handleMenuClick: MenuClickEventHandler = event => {
    onSelectOption(event, index);
    closeOnSelect && setVisibleDropdown(false);
  };

  return (
    <div
      ref={dropdownContentRef}
      style={{ width: customWidth }}
      className={checkForNegativeSelectedOption(value) ? 'negative-option--input' : ''}
    >
      <Dropdown
        overlayClassName={overlayClassName}
        overlay={
          // eslint-disable-next-line react/jsx-wrap-multilines
          visibleDropdown ? (
            <Menu onClick={handleMenuClick} tabIndex={-1}>
              {options.map(option => (
                <Menu.Item
                  style={highlightText(option.label)}
                  disabled={option.disabled}
                  key={option.value}
                  id={option.value}
                >
                  {option.label}
                </Menu.Item>
              ))}
            </Menu>
          ) : (
            <></>
          )
        }
        trigger={['click']}
        getPopupContainer={() => dropdownContentRef.current as unknown as HTMLElement}
        overlayStyle={dropDownStyle}
        visible={visibleDropdown}
      >
        {name ? (
          <Input
            name={name}
            placeholder={placeholder}
            onChange={onInputChange}
            allowClear={allowClear}
            onFocus={() => showDropdown && setVisibleDropdown(true)}
          />
        ) : (
          <AntdInput
            onClick={onClick}
            value={value}
            placeholder={placeholder}
            onChange={onInputChange}
            allowClear={allowClear}
            onFocus={() => setVisibleDropdown(true)}
          />
        )}
      </Dropdown>
    </div>
  );
};

export default InputWithDropdown;
