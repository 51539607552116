import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { Dictionary, values } from 'lodash';
import queryString from 'query-string';
import { SortByRule } from '@models/table/sorting';
// models
import { SearchSave, SearchSaveResponse } from '@models/search';
import { FilterSource, Filter, PreselectedFilter } from '@models/filters';
import { PageInfo } from '@models/table/Pagination';
import { ContactsSavedSearchesState } from '@redux/feature/saved-search/interfaces';
// constants
import { customUIViewIds, customUIViews } from '@optx/constants/table/columnDisplay/company-search';
// utils
import { parseFilter } from '@utils/filters/parseFilters';
// constants
import SORT_ORDER from '@constants/table/sortOrder';
// redux
import { fetchReducer } from '@redux/feature/fetch/reducers';
import mapTags from '@utils/filters/mapTags';
import { hasFilters } from '@optx/utils/search';
import { CustomUIViewIds, UserInformation } from '@optx/models/user';

// initial state
export const initialState: ContactsSavedSearchesState = {
  allIds: [],
  byId: {},
  loading: false,
  error: '',
  fetchedAt: '',
  total: 0,
  query: '',
  pageNumber: 1,
  sortBy: [],
  viewIds: [],
  views: {},
};

// fetch
export const fetchSavedSearchesReducer: CaseReducer<ContactsSavedSearchesState> = draftState => {
  fetchReducer(draftState);
  draftState.allIds = [];
  draftState.byId = {};
};

export const mapSortBy = (searchQueryString: string) => {
  const filterParsed = queryString.parse(searchQueryString, {
    arrayFormat: 'comma',
  }) as Dictionary<Array<string>>;

  const sorting: Array<SortByRule<any>> = [];

  if (filterParsed.sort_fields && filterParsed.sort_orders) {
    if (
      typeof filterParsed.sort_fields === 'string' &&
      typeof filterParsed.sort_orders === 'string'
    ) {
      const sort: SortByRule<any> = {
        desc: filterParsed.sort_orders !== SORT_ORDER.ASCENDING,
        id: filterParsed.sort_fields,
      };

      sorting.push(sort);
    } else {
      filterParsed.sort_fields.map((sortItem: string, index: number) =>
        sorting.push({
          id: sortItem,
          desc: filterParsed.sort_orders![index] !== SORT_ORDER.ASCENDING,
        })
      );
    }
  }

  return sorting.length ? sorting : undefined;
};

/**
 * Map SearchSaveResponse to SearchSave.
 * @param savedSeachResponse
 * @param filterSources
 */
export const mapSearchSave = (
  savedSeachResponse: SearchSaveResponse,
  clearedFilters: Dictionary<PreselectedFilter>,
  filterSources: Array<FilterSource<Filter<any>>> = []
) => {
  const [parsedFilters, keySearch] = parseFilter(savedSeachResponse.search_criteria, filterSources);

  const options = mapTags(parsedFilters, filterSources, clearedFilters);

  const mappedItem: SearchSave = {
    ...savedSeachResponse,
    columns: savedSeachResponse.columns,
    searchKey: keySearch as string,
    filters: parsedFilters,
    sortBy: mapSortBy(savedSeachResponse.search_criteria),
    filteredTags: options,
  };

  return mappedItem;
};

export const fetchSavedSearchesSuccessReducer: CaseReducer<
  ContactsSavedSearchesState,
  PayloadAction<
    Array<SearchSaveResponse> | Dictionary<SearchSaveResponse>,
    any,
    {
      filterSources: Array<FilterSource<Filter<any>>>;
      clearedFilters: Dictionary<PreselectedFilter>;
      total?: number;
      query?: string;
      pagination?: PageInfo;
      sortBy?: Array<SortByRule<any>>;
      addCustomViews?: boolean;
      defaultCustomUIView?: CustomUIViewIds;
    }
  >
> = (draftState, action) => {
  const { payload: savedSearchesResponse, meta } = action;
  const {
    filterSources,
    clearedFilters,
    total,
    query,
    pagination,
    sortBy,
    addCustomViews,
    defaultCustomUIView,
  } = meta;

  draftState.allIds = [];
  values(savedSearchesResponse).forEach(savedSeachResponse => {
    const { unique_id: id } = savedSeachResponse;

    draftState.allIds.push(id);

    draftState.byId[id] = mapSearchSave(savedSeachResponse, clearedFilters, filterSources);
  });

  // if we are on the last page, push custom UI views and set default property
  if (
    addCustomViews &&
    pagination &&
    total &&
    Math.ceil(total / pagination.pageSize) === pagination.pageNumber
  ) {
    customUIViewIds.forEach(id => {
      draftState.allIds.push(id);

      draftState.byId[id] = mapSearchSave(
        { ...customUIViews[id], is_default: defaultCustomUIView === id },
        clearedFilters,
        filterSources
      );
    });
  }

  draftState.shouldFetch = false;

  draftState.loading = false;
  draftState.fetchedAt = new Date().toISOString();
  draftState.total = total;
  draftState.query = query;
  draftState.sortBy = sortBy;

  if (pagination) {
    draftState.pageNumber = pagination.pageNumber;
  }
};

// create search
export const saveSearchSuccessReducer: CaseReducer<
  ContactsSavedSearchesState,
  PayloadAction<
    SearchSaveResponse,
    any,
    {
      filterSources: Array<FilterSource<Filter<any>>>;
      clearedFilters: Dictionary<PreselectedFilter>;
      shouldFetch?: boolean;
    }
  >
> = (draftState, action) => {
  const {
    payload: searchSaveResponse,
    meta: { filterSources, clearedFilters, shouldFetch },
  } = action;
  const { unique_id: uniqueId } = searchSaveResponse;

  if (!draftState.allIds.includes(uniqueId)) {
    draftState.allIds.unshift(uniqueId);
  }

  if (!draftState.viewIds.includes(uniqueId)) {
    draftState.viewIds.unshift(uniqueId);
  }

  draftState.byId[uniqueId] = mapSearchSave(searchSaveResponse, clearedFilters, filterSources);
  draftState.views[uniqueId] = draftState.byId[uniqueId];
  draftState.loading = false;

  draftState.shouldFetch = shouldFetch;

  const isCountableForSavedSearch = hasFilters(searchSaveResponse.search_criteria);

  if (isCountableForSavedSearch) {
    draftState.total! += 1;
  }
};

// delete search
export const deleteSearchSuccessReducer: CaseReducer<
  ContactsSavedSearchesState,
  PayloadAction<number>
> = (draftState, action) => {
  const searchId = action.payload;
  const searchIndex = draftState.allIds.indexOf(searchId);
  const viewIndex = draftState.viewIds.indexOf(searchId);

  if (searchIndex > -1) {
    draftState.allIds.splice(searchIndex, 1);
    delete draftState.byId[searchId];
  }

  if (draftState.viewIds.length) {
    draftState.viewIds.splice(viewIndex, 1);
    delete draftState.views[searchId];
  }

  draftState.loading = false;
  draftState.total! -= 1;
};

// rename search
export const renameSearchSuccessReducer: CaseReducer<
  ContactsSavedSearchesState,
  PayloadAction<{ searchId: number | string; title: string }>
> = (draftState, action) => {
  const { searchId, title } = action.payload;
  draftState.byId[searchId].title = title;

  if (draftState.views[searchId]) {
    draftState.views[searchId].title = title;
  }

  draftState.loading = false;
};

export const fetchUserInformationSuccessReducer: CaseReducer<
  ContactsSavedSearchesState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const query: string | undefined =
    action.payload.settings.session_settings?.saved_contact_searches_filters;
  const pageNumber: number | undefined =
    action.payload.settings.session_settings?.saved_contact_serches_page_number;

  if (query) {
    draftState.query = query;
  }

  if (pageNumber) {
    draftState.pageNumber = pageNumber;
  }
};
