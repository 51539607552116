import React, { useMemo } from 'react';
import { forEach, Dictionary } from 'lodash';
import { CommonProps, ActionMeta, GroupedOptionsType } from 'react-select';

// models
import { SelectOption } from '@optx/models/Option';
import { Styled } from './SelectableGroupHeading.styled';

type SelectableGroupHeadingProps = CommonProps<SelectOption> & {
  children: React.ReactChildren;
  id: string;
};

const SelectableGroupHeading = (props: SelectableGroupHeadingProps) => {
  const { children, id, selectProps } = props;

  // Parse group index from identifier.
  const numbers = id.match(/\d+/gi) as RegExpMatchArray;
  const groupIndex = Number.parseInt(numbers[numbers.length - 1]);
  const group = (selectProps!.options as GroupedOptionsType<SelectOption>)[groupIndex];
  const value = selectProps.value;

  const handleChange = (checked: boolean) => {
    const meta: ActionMeta<SelectOption> = {
      action: 'set-value',
    };

    const groupOptions: Dictionary<any> = {};

    forEach(group.options, option => {
      groupOptions[option.value] = true;
    });

    if (checked) {
      // Group was checked.
      const valuesOutsideGroup = (value as Array<SelectOption>).filter(
        option => !groupOptions[option.value]
      );

      const filteredGroupOptions = group.options.filter(option => !option.disabled);
      selectProps.onChange!(valuesOutsideGroup.concat(filteredGroupOptions), meta);
    } else {
      // Group was unchecked.
      const newValue = (value as Array<SelectOption>).filter(option => !groupOptions[option.value]);
      selectProps.onChange!(newValue, meta);
    }
  };

  // Check if group should be checked or not.
  const isChecked = useMemo(() => {
    if (!value) {
      return false;
    }

    const currentValues: Dictionary<boolean> = {};
    (value as Array<SelectOption>).forEach(option => {
      currentValues[option.value] = true;
    });

    for (let index = 0; index < group.options.length; index++) {
      const option = group.options[index];

      if (!option.disabled && !currentValues[option.value]) {
        return false;
      }
    }

    return true;
  }, [group.options, value]);

  const isDisabled = useMemo(() => !group.options.some(item => !item.disabled), [group.options]);

  const handleClick = () => {
    handleChange(!isChecked);
  };

  return (
    <Styled.GroupHeading {...props} onClick={handleClick}>
      <Styled.Checkbox checked={isChecked && !isDisabled} disabled={isDisabled} />
      {children}
    </Styled.GroupHeading>
  );
};

export default SelectableGroupHeading;
