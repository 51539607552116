import moment from 'moment';
/**
 * Checks how old a date is and returns a color or empty string
 * @param {string} date - date
 */

export const getDateColor = (
  date: string | null | number | undefined,
  canBePastDueDate?: boolean
) => {
  if (!date) {
    return '';
  }

  if (canBePastDueDate) {
    const today = moment(new Date());
    const targetDate = moment(date);

    return today.isAfter(targetDate, 'day') ? '#F5222D' : '#13C2C2';
  }

  const sixWeeks = 1000 * 60 * 60 * 24 * 7 * 6;
  const sixMonths = 1000 * 60 * 60 * 24 * 183;
  const today = new Date();
  const targetDate = new Date(date);
  const dateDiff = today.getTime() - targetDate.getTime();
  let color = '';

  if (dateDiff > sixMonths) {
    color = '#FA541C';
  } else if (dateDiff > sixWeeks) {
    color = '#FA8C16';
  } else {
    color = '#13C2C2';
  }

  return color;
};

/**
 * Check for dropdown option with negative ranges that are displayed between parentheses
 * @param {string} value value to be checked
 * @returns boolean
 */
export const checkForNegativeSelectedOption = (value: string | number | null | undefined) =>
  typeof value === 'string' && value.startsWith('(');

/**
 * Returns an object with a color property if the checkFunction returns true
 * for the given value, otherwise returns an empty object.
 * @param value - The string to be highlighted.
 * @param color - The color to be used for highlighting. Defaults to '#f5222d'.
 * @param checkFunction - The function used to determine if the value should be
 * highlighted. Defaults to checkForNegativeSelectedOption.
 * @returns An object with a color property if the checkFunction returns true
 * for the given value, otherwise returns an empty object.
 */
export const highlightText = (
  value: string | undefined,
  color: string = '#f5222d',
  checkFunction: (
    value: string | number | null | undefined
  ) => boolean = checkForNegativeSelectedOption
) => (checkFunction(value) ? { color } : {});
