import { Col, Row } from 'antd';
import styled from 'styled-components';

// Funding / Ownership Structure

interface FundingOwnershipStructureProps {
  isChromeExtension?: boolean;
  ChromeExtensionCustomWidth?: string;
  isSecondColumn?: boolean;
}

export const FundingOwnershipStructureWrapper = styled.div<FundingOwnershipStructureProps>`
  display: grid;
  grid-template-columns: 50% 50%;

  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    grid-template-columns: ${props => (props.isChromeExtension ? 'repeat(2, 1fr)' : '1fr')};
  }

  margin-top: ${props => (props.isChromeExtension ? '4px' : '0')};
  margin-left: 24px;
  margin-bottom: 22px;

  .edit-field--container {
    .edit-field--value {
      display: inline-block;
      height: 19px;
      max-width: ${props => (props.isChromeExtension ? '80px' : '100px')};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media screen and (min-width: 1280px) {
      .edit-field--value {
        max-width: ${props => (props.isChromeExtension ? '80px' : '190px')};
      }
    }

    @media screen and (min-width: 1440px) {
      .edit-field--value {
        max-width: ${props => (props.isChromeExtension ? '80px' : '170px')};
      }
    }

    @media screen and (min-width: 1600px) {
      .edit-field--value {
        max-width: ${props => (props.isChromeExtension ? '80px' : '180px')};
      }
    }

    @media screen and (min-width: 1920px) {
      .edit-field--value {
        max-width: ${props => (props.isChromeExtension ? '80px' : '240px')};
      }
    }

    .icon-pen {
      width: 18px;
      height: 18px;
    }
    .edit-pen {
      position: absolute;
      top: -1px;

      padding-left: 10px;
      visibility: hidden;
    }

    &:hover {
      cursor: pointer;
      .edit-pen {
        visibility: visible;
      }
    }
  }
`;

export const FirstFundingOwnershipStructureRowContainer = styled(
  Row
)<FundingOwnershipStructureProps>`
  .edit-field--container {
    position: ${props => (props.isChromeExtension ? 'relative' : '')};
    right: ${props => (props.isChromeExtension ? '0px' : '8%')};
  }
`;

export const SecondFundingOwnershipStructureRowContainer = styled(
  Row
)<FundingOwnershipStructureProps>`
  .edit-field--container {
    position: ${props => (props.isChromeExtension ? 'relative' : '')};
    right: ${props => (props.isChromeExtension ? '3rem' : '0px')};
  }
`;

export const FundingOwnershipStructureLabel = styled(Col)<FundingOwnershipStructureProps>`
  min-width: ${props => (props.isChromeExtension ? '61%' : '55%')};
  padding-right: 4%;
  line-height: 22px;
  font-size: 14px;
  font-weight: 600;
  color: #90a0b7;
`;

export const FundingOwnershipStructureValue = styled(Col)<FundingOwnershipStructureProps>`
  max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 22px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  color: #1890ff;

  @media screen and (min-width: 1024px) {
    right: ${props => (props.isChromeExtension ? '0' : '1em')};
  }

  @media screen and (min-width: 1280px) {
    right: ${props => (props.isChromeExtension ? '0' : '4em')};
  }

  @media screen and (min-width: 1441px) {
    right: ${props => (props.isChromeExtension ? '0' : '2em')};
  }

  @media screen and (min-width: 1600px) {
    right: ${props => (props.isChromeExtension ? '0' : '5em')};
  }

  @media screen and (min-width: 1920px) {
    right: ${props => (props.isChromeExtension ? '0' : '8em')};
  }
`;

// Customer KPI

interface CustomerKPIProps {
  isChromePlugin?: boolean;
}

export const CustomerKPIWrapper = styled.div<CustomerKPIProps>`
  display: grid;
  grid-template-columns: 57% 45%;

  .second-customer-kpi-row {
    position: relative;
    right: 3rem;
  }

  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    grid-template-columns: ${props => (props.isChromePlugin ? 'repeat(2, 1fr)' : '1fr')};
    .second-customer-kpi-row {
      position: relative;
      right: 0;
    }
  }

  margin-top: ${props => (props.isChromePlugin ? '4px' : '0')};
  margin-left: ${props => (props.isChromePlugin ? '24px' : '30px')};
  margin-bottom: 22px;

  .edit-field--container {
    .edit-field--value {
      display: inline-block;
      max-width: 80px;
      height: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .icon-pen {
      width: 18px;
      height: 18px;
    }
    .edit-pen {
      padding-left: 10px;
      visibility: hidden;
    }

    &:hover {
      cursor: pointer;
      .edit-pen {
        visibility: visible;
      }
    }
  }
`;

export const FirstCustomerKPIRow = styled(Row)<TechnologyProps>`
  .edit-field--container {
    position: ${props => (props.isChromeExtension ? 'relative' : '')};
    right: ${props => (props.isChromeExtension ? '0px' : '8%')};
  }
`;

export const SecondCustomerKPIRow = styled(Row)<TechnologyProps>`
  .edit-field--container {
    position: ${props => (props.isChromeExtension ? 'relative' : '')};
    right: ${props => (props.isChromeExtension ? '4rem' : '0px')};
  }

  :last-child {
    margin-bottom: 4rem;
  }
`;

export const CustomerKPILabel = styled(Col)<CustomerKPIProps>`
  min-width: ${props => (props.isChromePlugin ? '62%' : '55%')};
  padding-right: 4%;
  line-height: 22px;
  font-size: 14px;
  font-weight: 600;
  color: #90a0b7;
`;

export const CustomerKPIValues = styled(Col)<CustomerKPIProps>`
  line-height: 22px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  color: #1890ff;

  @media screen and (min-width: 1024px) {
    right: ${props => (props.isChromePlugin ? '0' : '1em')};
  }

  @media screen and (min-width: 1280px) {
    right: ${props => (props.isChromePlugin ? '0' : '4em')};
  }

  @media screen and (min-width: 1441px) {
    right: ${props => (props.isChromePlugin ? '0' : '2em')};
  }

  @media screen and (min-width: 1600px) {
    right: ${props => (props.isChromePlugin ? '0' : '5em')};
  }

  @media screen and (min-width: 1920px) {
    right: ${props => (props.isChromePlugin ? '0' : '8em')};
  }
`;

// Technology

interface TechnologyProps {
  isChromeExtension?: boolean;
}

export const TechnologyWrapper = styled.div<TechnologyProps>`
  margin-top: ${props => (props.isChromeExtension ? '22px' : '0')};
  margin-left: 24px;
  margin-bottom: 22px;

  @media screen and (max-width: 1024px) {
    display: grid;
    grid-template-columns: 32rem;
  }

  .edit-field--container {
    .edit-pen {
      padding-left: 10px;
      visibility: hidden;
    }

    .icon-pen {
      width: 18px;
      height: 18px;
    }

    &:hover {
      cursor: pointer;
      .edit-pen {
        visibility: visible;
      }
    }
  }
`;

export const TechnologyLabel = styled(Col)`
  @media screen and (max-width: 1023px) {
    min-width: 50%;
  }
  @media screen and (min-width: 1600px) {
    min-width: 42%;
  }
  min-width: 55%;
  padding-right: 4%;
  line-height: 22px;
  font-size: 14px;
  font-weight: 600;
  color: #90a0b7;
`;

export const TechnologyValues = styled(Col)<TechnologyProps>`
  max-width: 45%;
  right: ${props => (props.isChromeExtension ? '23%' : '27%')};
  line-height: 22px;
  font-size: 14px;
  font-weight: 600;
  color: #1890ff;
`;
