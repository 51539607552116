import * as yup from 'yup';
// models
import { SelectOption } from '@optx/models/Option';
// constants
import {
  ONLY_DIGITS_AND_OPTIONS_ERROR_MESSAGE,
  ONLY_DIGITS_ERROR_MESSAGE,
} from '@optx/constants/table/cells';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { isOptionChecked } from '@optx/utils/option';
import { validatePositiveNumbers } from '@optx/utils/validation';

const validationTouch = ({
  ebitdaOptions,
  revenueOptions,
  arrOptions,
  gmOptions,
}: {
  ebitdaOptions: SelectOption[];
  revenueOptions: SelectOption[];
  arrOptions: SelectOption[];
  gmOptions: SelectOption[];
}) => {
  const validationSchema = yup.object({
    typeId: yup.string().required('Field is required!').notOneOf(['select']).label('Type'),
    touchDate: yup.string().required('Field is required!'),
    touchTime: yup.string().required('Field is required!'),
    initiator: yup.string().required('Field is required!'),
    capital_raised: yup
      .number()
      .nullable()
      .test('requiredConditional', ONLY_DIGITS_ERROR_MESSAGE, function (value) {
        return validatePositiveNumbers(value);
      }),
    last_round_amount: yup
      .number()
      .nullable()
      .test('requiredConditional', ONLY_DIGITS_ERROR_MESSAGE, function (value) {
        return validatePositiveNumbers(value);
      }),
    acquiring_company: yup
      .string()
      .nullable()
      .test('requiredConditional', 'Field is required!', function (value) {
        return (
          (value && !!value && this.parent.last_round?.label === 'Acquisition') ||
          this.parent.last_round?.label !== 'Acquisition'
        );
      }),
    banker_name: yup.string().test('requiredConditional', 'Field is required!', function (value) {
      return (value && !!value && this.parent.banker) || !this.parent.banker;
    }),
    last_round: yup.object().nullable(),
    revenue: yup.array().test('financialsConditional', ONLY_DIGITS_ERROR_MESSAGE, function (value) {
      let finalReturn = true;

      value &&
        // @ts-ignore
        value.forEach(item => {
          if (
            item.value &&
            Number.isNaN(Number(item.value)) &&
            !isOptionChecked(item.value, revenueOptions) &&
            item.value !== DEFAULT_EMPTY_VALUE
          ) {
            finalReturn = false;
          }
        });

      return finalReturn;
    }),
    annual_recurring_revenue: yup
      .array()
      .test('financialsConditional', ONLY_DIGITS_ERROR_MESSAGE, function (value) {
        let finalReturn = true;

        value &&
          // @ts-ignore
          value.forEach(item => {
            if (
              item.value &&
              Number.isNaN(Number(item.value)) &&
              !isOptionChecked(item.value, arrOptions) &&
              item.value !== DEFAULT_EMPTY_VALUE
            ) {
              finalReturn = false;
            }
          });

        return finalReturn;
      }),
    gm: yup.array().test('financialsConditional', ONLY_DIGITS_ERROR_MESSAGE, function (value) {
      let finalReturn = true;

      value &&
        // @ts-ignore
        value.forEach(item => {
          if (
            item.value &&
            Number.isNaN(Number(item.value)) &&
            !isOptionChecked(item.value, gmOptions) &&
            item.value !== DEFAULT_EMPTY_VALUE
          ) {
            finalReturn = false;
          }
        });

      return finalReturn;
    }),
    ebitda: yup
      .array()
      .test('financialsConditional', ONLY_DIGITS_AND_OPTIONS_ERROR_MESSAGE, function (value) {
        let finalReturn = true;

        value &&
          // @ts-ignore
          value.forEach(item => {
            if (
              item.value &&
              Number.isNaN(Number(item.value)) &&
              !isOptionChecked(item.value, ebitdaOptions) &&
              item.value !== DEFAULT_EMPTY_VALUE
            ) {
              finalReturn = false;
            }
          });

        return finalReturn;
      }),
  });

  return validationSchema;
};

export default validationTouch;
