import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tag, Collapse } from 'antd';
// models
import Company from '@optx/models/Company';
import {
  ChangeFieldsPayload,
  RationaleType,
} from '@features/add-research-rationale/state/interfaces';
// constants
import {
  SOFTWARE_RATIONALE_KEY,
  PSG_FIT_RATIONALE_KEY,
  OPTX_SCORE_RATIONALE_KEY,
  SCORE_CARD_NAME,
  FIT_CARD_NAME,
  SOFTWARE_CARD_NAME,
} from '@features/add-research-rationale/constants';
import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';
// utils
import { iframePostMessage } from '@optx/utils/window';
// hooks
import { useInjectFavoriteLists, useInjectAddRationale } from '@optx/common/hooks/inject';
import { useInjectEditFields } from '@optx/features/company/edit-fields';
// redux
import { selectors, actions } from '@features/add-research-rationale/state';
import { selectors as userSelectors } from '@redux/user/information';
// components
import Header from '@features/add-research-rationale/components/Header';
import OptxScoreRationale from '@features/add-research-rationale/components/fields/OptxScoreRationale';
import * as Styled from '@features/add-research-rationale/components/AddResearchRationale.styles';
import iconCompany from '@optx/assets/images/company_ico@2x.png';
import '@features/add-research-rationale/components/style.scss';
import CollapsableFields from './fields/CollapsableFields';
import ThumbActions from './rationale/ThumbActions';
import CollapsableCardInfo from './rationale/CollapsableCardInfo';

const handleCardTitle = (cardName: string, defaultScore?: string) => {
  let cardTitle: JSX.Element | string = '';

  if (cardName === SOFTWARE_CARD_NAME) {
    cardTitle = (
      <>
        Software Company <span className="card-title">&#42;Required</span>
      </>
    );
  }

  if (cardName === FIT_CARD_NAME) {
    cardTitle = (
      <>
        PSG FIT <span className="card-title">&#42;Required</span>
      </>
    );
  }

  if (cardName === SCORE_CARD_NAME) {
    cardTitle = (
      <>
        {defaultScore === 'il' ? 'IL ' : ''}OPTX Score{' '}
        <span className="card-title">&#42;Required</span>
      </>
    );
  }

  return cardTitle;
};

interface AddResearchRationaleProps {
  isInChromeExtension?: boolean;
}

const AddResearchRationale: React.FC<AddResearchRationaleProps> = ({ isInChromeExtension }) => {
  useInjectAddRationale();
  useInjectFavoriteLists();
  useInjectEditFields();
  const dispatch = useDispatch();

  const company = useSelector(selectors.getCompanyInfo);
  const defaultScore = useSelector(userSelectors.getDefaultScore);
  const isOpen = useSelector(selectors.isOpen);
  const rationale = useSelector(selectors.getRationale);
  const activeRationaleScoreType = useSelector(selectors.getActiveRationaleReasonType);
  const [accordionKey, setAccordionKey] = useState<string>('');

  const handleChange = (field?: ChangeFieldsPayload, rationale?: ChangeFieldsPayload) => {
    if (field) {
      dispatch(actions.changeFields({ key: field.key, value: field.value }));
    }

    if (rationale) {
      dispatch(
        actions.changeRationale({
          key: rationale.key,
          value: rationale.value,
          defaultScore,
        })
      );
    }
  };

  const toggle = useCallback(() => {
    if (isInChromeExtension) iframePostMessage('open-drawer', Boolean(false));
    setAccordionKey('');

    if (company && company.company_id) {
      dispatch(
        actions.toggleAddResearchRationale({
          companyId: company.company_id,
        })
      );
    }
  }, [company, dispatch, isInChromeExtension]);

  const getScoreRationaleReason = useCallback(() => {
    if (company && company.company_id) {
      if (defaultScore === 'us') {
        return company.optx_score_rationale_reason;
      }

      return company.il_optx_score_rationale_reason;
    }

    return null;
  }, [company, defaultScore]);

  const handleCard = (cardName: string) => {
    if (cardName === SOFTWARE_CARD_NAME && company?.is_software === false) {
      setAccordionKey(cardName);
    }

    if (cardName === FIT_CARD_NAME && company?.is_interesting === false) {
      setAccordionKey(cardName);
    }

    const optxScoreVerified =
      defaultScore === 'us' ? company?.optx_score_verified : company?.il_optx_score_verified;

    if (cardName === SCORE_CARD_NAME && optxScoreVerified === false) {
      setAccordionKey(cardName);
    }
  };

  if (!company) return null;

  const updateFields = (value: boolean | null, optionType: string) => {
    setAccordionKey(value === false ? optionType : '');

    if (optionType === 'Software') {
      dispatch(actions.changeFields({ key: 'is_software', value }));

      if (value === true) {
        dispatch(actions.changeRationale({ key: SOFTWARE_RATIONALE_KEY, value: null }));
      }

      if (value === false || value === null) {
        dispatch(actions.revertFieldsChanges({ key: SOFTWARE_CARD_NAME, value }));
      }
    }

    if (optionType === 'PSG Fit') {
      dispatch(actions.changeFields({ key: 'is_interesting', value }));

      if (value === true) {
        dispatch(actions.changeRationale({ key: PSG_FIT_RATIONALE_KEY, value: null }));
      }

      if (value === false || value === null) {
        dispatch(actions.revertFieldsChanges({ key: FIT_CARD_NAME, value }));
      }
    }

    if (optionType === 'Score') {
      dispatch(
        actions.changeFields({
          key: `${defaultScore === 'us' ? 'optx_score_verified' : 'il_optx_score_verified'}`,
          value,
        })
      );

      if (value === true || value === null) {
        dispatch(actions.changeRationale({ key: OPTX_SCORE_RATIONALE_KEY, value: null }));
      }
    }
  };

  return (
    <Styled.StyledDrawer
      title={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Header
          toggle={toggle}
          rationale={rationale}
          company={company}
          setAccordionKey={setAccordionKey}
        />
      }
      placement={isInChromeExtension ? 'bottom' : 'right'}
      visible={isOpen}
      onClose={toggle}
      className={`${isInChromeExtension ? 'plugin ' : ''}add-research-rationale`}
      closeIcon={false}
      height={isInChromeExtension ? 'calc(100vh - 60px)' : undefined}
      destroyOnClose
    >
      {!isInChromeExtension && (
        <Styled.CompanyInfoContainer>
          <Styled.ProfileInformationContainer>
            <Styled.CompanyLogo
              hasLogo={!!(company?.logo_url && company.logo_url !== DEFAULT_CELL_VALUE)}
            >
              <img
                src={
                  company?.logo_url && company.logo_url !== DEFAULT_CELL_VALUE
                    ? company.logo_url
                    : iconCompany
                }
                alt="logo"
              />
            </Styled.CompanyLogo>
            <Styled.CompanySecondaryInfo>
              <div className="et-label">
                {!company?.source_tag.includes('et') ? (
                  <Tag color="gray">Not in ET</Tag>
                ) : (
                  <Tag color="green">In ET</Tag>
                )}
              </div>
              <Styled.CompanyName>{company?.company_name}</Styled.CompanyName>
              <Styled.Address>{company?.city}</Styled.Address>
            </Styled.CompanySecondaryInfo>
          </Styled.ProfileInformationContainer>
        </Styled.CompanyInfoContainer>
      )}
      <div>
        <Styled.ContentTitle>Validate the following company attributes</Styled.ContentTitle>
        <Styled.ContentSubTitle>
          Your input will be used to help train the algorithm, improving data accuracy
        </Styled.ContentSubTitle>
        <Collapse
          bordered={false}
          accordion
          activeKey={accordionKey}
          onChange={key => handleCard(key as string)}
          style={{
            display: 'grid',
            gap: '26px',
            backgroundColor: 'transparent',
          }}
        >
          <Styled.PanelContainer
            header={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <CollapsableCardInfo
                title={handleCardTitle(SOFTWARE_CARD_NAME)}
                description="Validate if this is a software company"
              >
                <ThumbActions
                  onUpdateThumbsChecked={updateFields}
                  collapsableOptions="Software"
                  isChecked={company.is_software}
                  activeKey={accordionKey}
                />
              </CollapsableCardInfo>
            }
            key={SOFTWARE_CARD_NAME}
            showArrow={false}
          >
            <CollapsableFields
              onChange={handleChange}
              valueRationale={company.is_software_rationale}
              typeOptions="Software Company"
              collapsableValue={false}
              storeRationale={rationale}
            />
          </Styled.PanelContainer>
          <Styled.DependentPanelContainer
            canBeShown={company.is_software}
            showArrow={false}
            header={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <CollapsableCardInfo
                title={handleCardTitle(FIT_CARD_NAME)}
                description="Indicate if this company is a PSG FIT"
              >
                <ThumbActions
                  onUpdateThumbsChecked={updateFields}
                  collapsableOptions="PSG Fit"
                  isChecked={company.is_interesting}
                  activeKey={accordionKey}
                />
              </CollapsableCardInfo>
            }
            key={FIT_CARD_NAME}
          >
            <CollapsableFields
              onChange={handleChange}
              valueRationale={company.interesting_rationale}
              typeOptions="PSG Fit"
              collapsableValue={false}
              storeRationale={rationale}
            />
          </Styled.DependentPanelContainer>

          <Styled.DependentPanelContainer
            canBeShown={!!(company.is_software && company.is_interesting === true)}
            showArrow={false}
            header={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <>
                <CollapsableCardInfo
                  title={handleCardTitle(SCORE_CARD_NAME, defaultScore)}
                  description="Do you agree with the score?"
                >
                  <ThumbActions
                    onUpdateThumbsChecked={updateFields}
                    collapsableOptions="Score"
                    isChecked={
                      defaultScore === 'us'
                        ? company.optx_score_verified
                        : company.il_optx_score_verified
                    }
                    activeKey={accordionKey}
                  />
                </CollapsableCardInfo>
                <Styled.CardInfoDivider isChromePlugin={isInChromeExtension} />
                <OptxScoreRationale
                  company={company as Company}
                  onChange={handleChange}
                  isShowingRationale={false}
                  defaultScore={defaultScore}
                />
              </>
            }
            key={SCORE_CARD_NAME}
          >
            <OptxScoreRationale
              company={company as Company}
              onChange={handleChange}
              isShowingRationale
              defaultScore={defaultScore}
            />
            {activeRationaleScoreType !== null && (
              <CollapsableFields
                score={company[defaultScore === 'us' ? 'score' : 'il_optx_score']}
                onChange={handleChange}
                valueRationale={getScoreRationaleReason()}
                typeOptions={activeRationaleScoreType as RationaleType}
                collapsableValue={false}
                storeRationale={rationale}
              />
            )}
          </Styled.DependentPanelContainer>
        </Collapse>
      </div>
    </Styled.StyledDrawer>
  );
};

export default AddResearchRationale;
