import React from 'react';
import { Pie, ResponsiveContainer, PieChart as PChart, Cell, PieProps } from 'recharts';

interface PieChartProps extends PieProps {
  chartHeight?: number;
  data: { label: string; value: string | number; color: string }[];
  dataKey: string;
  totalText?: string;
}

const PieChart: React.FC<PieChartProps> = ({ chartHeight, data, dataKey, ...props }) => {
  return (
    <ResponsiveContainer height={chartHeight ?? 200} width={chartHeight ?? 200}>
      <PChart>
        <Pie
          data={data}
          innerRadius={60}
          outerRadius={80}
          cy="50%"
          fill="#8884d8"
          dataKey={dataKey}
          stroke="none"
          {...props}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PChart>
    </ResponsiveContainer>
  );
};

export default PieChart;
