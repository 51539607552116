import {
  regexFloatAbbreviation,
  regexIntegerAbbreviation,
  regexFloatAndNegativeAbbreviation,
  regexLinkedinURL,
  numberRegExp,
} from '../constants/regex';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';

/**
 * function to validate a string to see if it matches an abbreviated number format
 * allow the value to be an empty string
 * for input fields where the validation is performed as the user types,
 * because the regex checks the string as a whole, we need to manually perform a check for the decimal point,
 * otherwise the regex will prevent user from entering decimal point or return error
 * @param {string} value string value to validate
 */
export const validateFloatAbbreviation = (value: string, canBeNegative: boolean = false) => {
  const decimalPointCount = value.split('.').length - 1;
  const decimalPointIndex = value.indexOf('.');
  const regexValidation = canBeNegative
    ? regexFloatAndNegativeAbbreviation
    : regexFloatAbbreviation;

  if (
    value === '' ||
    (value[value.length - 1] === '.' && decimalPointCount < 2 && decimalPointIndex > 0) ||
    regexValidation.test(value)
  ) {
    return true;
  }

  return false;
};

/**
 * function to validate a string to see if it matches an abbreviated number format
 * allow the value to be an empty string
 * doesn't allow decimal numbers
 * @param {string} value string value to validate
 */
export const validateIntegerAbbreviation = (value: string) => {
  if (value === '' || regexIntegerAbbreviation.test(value)) {
    return true;
  }

  return false;
};

/**
 * function to validate a string's length
 * @param {string} value the value that needs to be checked
 * @param {number} charLimit a number to check the value's length against
 */
export const validateMinChars = (value: string, charLimit: number) => {
  if (value.length >= charLimit) {
    return true;
  }

  return false;
};

/**
 * function to validate a url link
 * @param {string} value the value that need to be checked
 */

export const validateLinkedinURL = (value: string) => {
  return regexLinkedinURL.test(value.toLowerCase());
};

/**
 * function to validate if it is number
 * @param {string} value string value to validate
 */

export const validateOnlyNumbers = (value: string) => {
  if (value === '') return true;

  return numberRegExp.test(value);
};

/**
 * Validates if the given value is a positive number.
 *
 * @param value - The value to be validated.
 * @returns Returns true if the value is a positive number, otherwise returns false.
 */
export function validatePositiveNumbers(value: any): boolean {
  let finalReturn = true;

  if ((value && Number.isNaN(Number(value)) && value !== DEFAULT_EMPTY_VALUE) || value < 0) {
    finalReturn = false;
  }

  return finalReturn;
}
