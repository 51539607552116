import React from 'react';
// utils
import { formatPercentDefault } from '@utils/format';
// components
import Icon from '@components/common/Icon';
import Styled from './TrendingValue.styles';

type FormatValue = number | null | undefined;

type TrendingValueProps = React.HTMLAttributes<HTMLSpanElement> & {
  value: number | null;
  format?: (value: FormatValue) => string | FormatValue;
};

type TrendingDirection = 'up' | 'down' | 'none';

const getTrending = (value: number): TrendingDirection => {
  if (value > 0) {
    return 'up';
  }

  if (value < 0) {
    return 'down';
  }

  return 'none';
};

const TrendingValue: React.FC<TrendingValueProps> = ({
  value,
  format = formatPercentDefault,
  ...restProps
}) => {
  let icon: React.ReactNode | null = null;

  if (typeof value !== 'number') {
    return null;
  }

  if (value > 0) {
    icon = <Icon iconName="trending-up" />;
  } else if (value < 0) {
    icon = <Icon iconName="trending-down" />;
  }

  return (
    <Styled.TrendingValue trending={getTrending(value)} {...restProps}>
      {icon} {format(value)}
    </Styled.TrendingValue>
  );
};

export default TrendingValue;
