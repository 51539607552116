import React from 'react';
import { useSelector } from 'react-redux';
// redux
import { selectors as filterCardsSelectors } from '@optx/redux/my-companies/filters-cards';
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as paginationSelectors } from '@features/grid/pagination';
import { selectors as searchCountSelectors } from '@features/grid/search-count';
// hooks
import { useVirtualTableContainerSize, VirtualTableScrollYContext } from '@features/scroll-history';
// components
import MyCompaniesTable from './MyCompaniesTable';
import CardContent from './CardContent';
import { GridPagination } from '@features/grid/pagination/components';

interface CompanySearchDisplayProps {
  isLongCardView: boolean;
}

/**
 * Switch between card and grid content.
 * Grid content needs to be separated from card content
 * due to how it checks the height of it's wrapper on size change and
 * we don't need this functionality on cards because it might create performance issues.
 */
const CompanySearchDisplay: React.FC<CompanySearchDisplayProps> = ({ isLongCardView }) => {
  const virtualTableScrollYContext = useVirtualTableContainerSize();
  const containerRef = virtualTableScrollYContext.containerRef as React.RefObject<HTMLDivElement>;

  const { pageNumber, pageSize } = useSelector(paginationSelectors.getPagination('myCompanies'));
  const isLoadingCompanies = useSelector(searchCountSelectors.getSearchCountLoading('myCompanies'));
  const selectedCard = useSelector(filterCardsSelectors.getSelectedCard);
  const companies = useSelector(searchSelectors.getCompanies('myCompanies'));
  const totalItemsCount = useSelector(searchCountSelectors.getSearchCount('myCompanies'));
  const isLoadingCount = useSelector(searchCountSelectors.getSearchCountLoading('myCompanies'));

  return (
    <>
      {isLongCardView ? (
        <CardContent />
      ) : (
        <div ref={containerRef}>
          <VirtualTableScrollYContext.Provider value={virtualTableScrollYContext}>
            <MyCompaniesTable />
          </VirtualTableScrollYContext.Provider>

          <GridPagination
            gridKey="myCompanies"
            pageNumber={pageNumber}
            pageSize={pageSize}
            searchCountPageSize={companies.length}
            totalItemsCount={totalItemsCount}
            isLoadingCount={isLoadingCount}
            selectedCountType="myCompanies"
            searchCountStyle={{ fontSize: 20 }}
            listType={{
              one: 'Company',
              many: 'Companies',
            }}
            isLoadingList={isLoadingCompanies}
            selectedCard={selectedCard}
          />
        </div>
      )}
    </>
  );
};

export default CompanySearchDisplay;
