import React from 'react';
import { Typography, Tooltip } from 'antd';
import { EquityTouchBulkResults } from '@models/bulkActions';

export const TableCellEquityTouchUrl = (value: string, record: EquityTouchBulkResults) => {
  if (!value) {
    return 'Not in ET';
  }

  return (
    <Tooltip title={record.company_name}>
      <Typography.Link ellipsis href={value} target="_blank" style={{ width: '100%' }}>
        {record.company_name}
      </Typography.Link>
    </Tooltip>
  );
};

export default TableCellEquityTouchUrl;
