import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectors as userSelectors } from '@redux/user/information';
import { actions } from '../../../redux/user/region-settings';
import { useInitialFetch } from '../fetch';

const useFetchRegionOptions = () => {
  const dispatch = useDispatch();
  const shouldFetchRegionOptions = useSelector(userSelectors.shouldFetchRegionOptions);
  const fetchRegionOptions = useCallback(() => dispatch(actions.fetchRegionOptions()), [dispatch]);
  useInitialFetch(shouldFetchRegionOptions, fetchRegionOptions);
};

export default useFetchRegionOptions;
