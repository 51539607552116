import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { reducer, saga } from '@redux/company/extension-profile';

const useInjectExtensionProfile = () => {
  useInjectReducer({
    key: 'extension',
    reducer,
  });
  useInjectSaga({
    key: 'extension',
    saga,
  });
};

export default useInjectExtensionProfile;
