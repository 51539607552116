import React from 'react';
// models
import { TableCellWatchlists } from '@optx/models/table/Cell';
// utils
import { formatDate } from '@utils/format';
// hooks
import useMyWatchlists from '@hooks/my-watchlists/useMyWatchlists';

const TableCellDate: React.FC<TableCellWatchlists> = ({ value, row }) => {
  const { handleMyWatchlistsResults } = useMyWatchlists();

  const handleClick = () => {
    if (row.unique_id) {
      handleMyWatchlistsResults(row.unique_id.toString());
    }
  };

  return (
    <div role="presentation" onClick={handleClick}>
      {formatDate(value, 'monthDayYear')}
    </div>
  );
};

export default TableCellDate;
