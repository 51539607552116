import { takeLatest, call, put, select } from 'redux-saga/effects';
import { FilterSource, PreselectedFilter } from '@optx/models/filters';
import { Dictionary } from 'lodash';
import * as actions from './actions';
import { selectors as filterSourcesSelectors } from '../filters';
import { SavedSearchesService } from '../../../services/api';
import NotificationService from '../../../services/NotificationService';
import { selectors as filterSelectors } from '@optx/features/grid/filter';

export function* fetchSuggestedSearchesSaga() {
  try {
    // @ts-ignore
    const res = yield call(SavedSearchesService.getSuggestedSearches);

    if (res.data) {
      const filterSources: FilterSource[] = yield select(filterSourcesSelectors.getCompanyFilters);
      const clearedFilters: Dictionary<PreselectedFilter> = yield select(
        filterSelectors.getClearedFilter('advancedSearch')
      );

      yield put(
        actions.fetchSuggestedSearchesSuccess(
          res.data.lists,
          filterSources,
          clearedFilters,
          res.data.total_list_count
        )
      );
    } else {
      const errorMessage = 'Error fetching suggested searches!';
      yield put(actions.fetchSuggestedSearchesFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = 'Error fetching suggested searches!';
    yield put(actions.fetchSuggestedSearchesFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* suggestedSearchesSaga() {
  yield takeLatest(actions.fetchSuggestedSearches, fetchSuggestedSearchesSaga);
}
