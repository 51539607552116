import styled from 'styled-components';
import { up } from 'styled-breakpoints';

const ProfileOverviewContainer = styled.div`
  .profile-overview__row {
    flex-wrap: wrap;
  }

  ${up('xxl')} {
    .profile-overview__row {
      display: grid;
      grid-template-columns: minmax(450px, 1fr) minmax(600px, 2fr);
    }
  }
`;

const Styles = { ProfileOverviewContainer };

export default Styles;
