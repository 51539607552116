import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// models
import { Company } from '@models/Company';
// services
import { CompanyService } from '@optx/services/api';
// redux
import { selectors as profileSelectors } from '@redux/company/profile';
import {
  selectors as addonSelectors,
  actions as addonActions,
} from '@redux/company/addon-management';
import { actions as editFieldActions } from '@optx/features/company/edit-fields/state';
// components
import Popconfirm from '@optx/shared/view/molecules/Popconfirm/Popconfirm';

interface RemoveAsAddonProps {
  record: Company;
}

const RemoveAsAddon: React.FC<RemoveAsAddonProps> = ({ record }) => {
  const dispatch = useDispatch();
  const { addon, company_id: companyId } = record;
  const companyProfileName = useSelector(profileSelectors.getCompanyName);
  const relatedListsIds = useSelector(
    addonSelectors.getOriginAdditionalFilterRelatedListsIds(companyId)
  );

  const handleConfirm = () => {
    const payload = {
      companyId,
      value: {
        value: null,
        optionValue: false,
      },
      successMessage: 'Add-on updated successfully!',
      errorMessage: 'Add-on update failed, Server error!',
    };

    if (companyProfileName === addon) {
      dispatch(
        editFieldActions.updateFields({
          companyUrl: record.company_url,
          companyId: companyId,
          value: payload,
          service: CompanyService.updateAddon,
          fieldName: 'Add-on',
          isFromHistory: false,
          canBeDuplicatedLoader: true,
        })
      );

      if (relatedListsIds.length) {
        dispatch(
          addonActions.removeCompanyFromAddonManagement({
            list_id: relatedListsIds,
            company_id: companyId,
          })
        );
      }
    } else if (relatedListsIds.length) {
      dispatch(
        addonActions.removeCompanyFromAddonManagement({
          list_id: relatedListsIds,
          company_id: companyId,
        })
      );
    } else {
      dispatch(
        editFieldActions.updateFields({
          companyUrl: record.company_url,
          companyId: companyId,
          value: payload,
          service: CompanyService.updateAddon,
          fieldName: 'Add-on',
          isFromHistory: false,
          canBeDuplicatedLoader: true,
        })
      );
    }
  };

  return (
    <Popconfirm title="as Add-on" onConfirm={() => handleConfirm()} action="Remove">
      <div>Remove as Add-on</div>
    </Popconfirm>
  );
};

export default RemoveAsAddon;
