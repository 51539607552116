import { createAction } from '@reduxjs/toolkit';
import { SortByRule, SortRule } from '@models/table/sorting';
import { types } from '../types';

export const sort = createAction<Array<SortByRule<any>>>(types.sort.SORT);

export const changeSort = createAction<SortRule<any>>(types.sort.CHANGE_SORT);
export const changeSortSuccess = createAction<SortRule<any>>(types.sort.CHANGE_SORT_SUCCESS);
export const multiSort = createAction(
  types.sort.MULTI_SORT,
  (multiSort: boolean, sortBy?: Array<SortByRule<any>>) => ({
    payload: multiSort,
    meta: sortBy,
  })
);

export const resetMultiSort = createAction(types.sort.RESET_MULTI_SORT);
