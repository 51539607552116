import numeral from 'numeral';
// models
import { AbbreviateNumberParams } from '@optx/models/number';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';

// format number to short notation (K, M...)
export const abbreviateNumber = ({
  value,
  maxValue,
  dot,
  roundHalfEven,
  format = '0a',
}: AbbreviateNumberParams) => {
  if (Number(value) >= maxValue! && dot !== undefined) {
    // eslint-disable-next-line no-bitwise
    return `${numeral(dot).format(format, n => (roundHalfEven ? roundToHalfEven(n) : ~~n))}+`;
  }

  if (typeof value === 'string') {
    return numeral(value.toUpperCase()).format(format, n =>
      // eslint-disable-next-line no-bitwise
      roundHalfEven ? roundToHalfEven(n) : ~~n
    );
  }

  // eslint-disable-next-line no-bitwise
  return numeral(value).format(format, n => (roundHalfEven ? roundToHalfEven(n) : ~~n));
};

/**
 * check if a number is Even
 * @param n number to be checked
 */
export function isEven(n: number) {
  return n % 2 === 0;
}

/**
 * function to round a number to half even
 * @param value number to be rounded
 */
export function roundToHalfEven(value: number) {
  const roundedValue = Math.round(value);

  const expression = (value > 0 ? value : -value) % 1 === 0.5;

  if (expression) {
    if (isEven(roundedValue)) {
      return roundedValue;
    }

    return roundedValue - 1;
  }

  return roundedValue;
}

/**
 * function to format a monetary value when have a minus character in the beginning
 * @param value
 */
export const normalizeMonetaryMinusValues = (value: string) => {
  const word = value;
  const splitedWord = word.split('');

  if (splitedWord[0].includes('-') && value.length > 1) {
    const formatedValue = word.replace('-', '');

    return `-$${formatedValue}`;
  }

  if (value === DEFAULT_CELL_VALUE) {
    return value;
  }

  if (!splitedWord[0].includes('$')) {
    return `$${value}`;
  }

  return value;
};

/**
 * Abbreviate number greater than a threshold.
 * @param value
 * @param thresholdValue
 * @param defaultValue no numeric value defaults to "-"
 * @param isRoundHalfEven
 */
export const abbreviateValue = (
  value: number | string | null,
  thresholdValue: number = 999,
  defaultValue: string | number = DEFAULT_CELL_VALUE,
  isRoundHalfEven: boolean = false,
  format?: string | undefined
) => {
  if (!value) return defaultValue;

  if (value > thresholdValue) {
    return abbreviateNumber({ value, roundHalfEven: isRoundHalfEven, format });
  }

  return value;
};

// parse short notation to number
export const parseNumberFromAbbreviation = (
  value: string | undefined,
  maxFilterValue?: number,
  minFilterValue?: number,
  inputType?: 'min' | 'max'
) => {
  if (value === '') {
    if (inputType === 'min') {
      return numeral(minFilterValue).value();
    }

    if (inputType === 'max') {
      return numeral(maxFilterValue).value();
    }
  }

  let finalValue = value;

  if (typeof value === 'string' && value![value!.length - 1] === '+') {
    finalValue = value?.substring(0, value.length - 1);
  }

  if (typeof finalValue === 'string' && /[a-z]+$/.test(finalValue)) {
    finalValue = finalValue.toUpperCase();
  }

  const inputValue = numeral(finalValue).value();

  if (inputValue! > maxFilterValue!) {
    return numeral(maxFilterValue).value();
  }

  if (inputValue! < minFilterValue!) {
    return numeral(minFilterValue).value();
  }

  return inputValue;
};

// return true if value is negative
export const isNegative = (value: number) => Math.sign(value) === -1;

// converts negative number to positive
export const convertToPositive = (value: number) => (isNegative(value) ? Math.abs(value) : value);

// convert number to logarithm
export const logarithmNumber = (value: number) => {
  let newValue = value;

  // Logarithm doesnt calc negative values, so covert number to positive,
  // get the Math.log value and convert to negative.
  if (isNegative(newValue)) {
    newValue = Math.abs(newValue);

    return -Math.abs(Math.log(newValue));
  }

  return Math.log(newValue);
};

/**
 * Compute percentage value from total.
 * @param value
 * @param total
 * @param decimals number of decimals. Defaults to 0 decimals.
 */
export const computePercent = (value: number, total: number, decimals = 0) => {
  try {
    if (total === 0) {
      return 0;
    }

    return Number(((value / total) * 100).toFixed(decimals));
  } catch (error: any) {
    return 0;
  }
};

/**
 * Round negative numbers the same way as positive numbers
 * @param number
 * @param format
 */
export const roundNumber = (number: string | number | undefined | null, format: string) => {
  let value = number;

  if (value === null || value === undefined) return '';

  // some value is "$421.4214,42 (plus unknown) and we need to remove the plus unknown part"
  if (value && value.toString().includes(' (plus Unknown)')) {
    value = value.toString().split(' (')[0];
  }

  if (Number(value) < 0) {
    return `-${numeral(Math.abs(Number(value))).format(format)}`;
  }

  return numeral(value).format(format);
};

/**
 * Takes in a number or string and multiplies it by 1,000,000, then adds the decimal value if present.
 * @param {number | string} input - The number or string to be multiplied and summed.
 * @returns {number} The result of multiplying and summing the input.
 */

export const numberToMillions = (input: number | string): number => {
  let isInputNegative = Number.isNaN(Number(input)) ? false : Number(input) < 0;
  const normalizedInput = isInputNegative ? String(input).replace('-', '') : String(input);

  const parts = normalizedInput.split('.');
  const firstPart = Number(parts[0]) * 1000000;
  const secondPart = parts[1] ? Number('0.' + parts[1]) * 1000000 : 0;

  let result = firstPart + secondPart;

  if (isInputNegative) {
    result = -result;
  }

  return result;
};
