import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import reducer, { saga } from '../../../redux/user/information';

const useInjectUserInformation = () => {
  useInjectReducer({
    key: 'user',
    reducer,
  });
  useInjectSaga({
    key: 'user',
    saga,
  });
};

export default useInjectUserInformation;
