import { Typography } from 'antd';
import styled from 'styled-components';
// components
import { MainContent } from '@optx/components/common/layout';
import { Title } from '@optx/shared/view/atoms/Typography';
import { AnalystsMultiSelect, FilterSingleSelect } from '@features/pipeline-report/components';

const StyledMainContent = styled(MainContent)``;

const StatsWrapper = styled.div``;

const PageFiltersWrapper = styled.div``;

const QuickFiltersContent = styled.div``;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background: #f5f7fa;

  ${StatsWrapper},
  ${StyledMainContent} {
    min-width: 1px;
    width: 100%;
  }

  @media (min-width: 1600px) {
    flex-wrap: nowrap;

    ${StatsWrapper} {
      flex: 0;
      min-width: 500px;
      max-width: 500px;
    }

    ${StyledMainContent} {
      flex: 1;
    }
  }
  .ant-table-row {
    .location,
    .senior_deal_team_lead {
      span {
        color: #1a1a1a;
      }
    }
  }

  .opportunities-chart-wrapper,
  .fund-association-chart-wrapper {
    height: 262px;
    background: #fff;
  }

  ${PageFiltersWrapper} {
    padding: 23px 40px 100px;
    margin-bottom: 34px;
    margin-left: -40px;
    margin-right: -40px;
    background: linear-gradient(360deg, #064885 0%, #137edf 124.9%);
    position: relative;

    .ant-breadcrumb {
      .ant-breadcrumb-link {
        color: white;
      }

      a:not(:hover),
      .ant-breadcrumb-separator {
        color: #bae7ff !important;
      }
    }
  }

  ${QuickFiltersContent} {
    display: flex;
    align-items: left;
    gap: 11px;
    flex-wrap: nowrap;
    position: relative;
    z-index: 4;
    padding-bottom: 20px;
  }
`;

const PageTitle = styled(Title)`
  margin-top: 10px;
  color: white !important;
`;

const QuickFilterWrapper = styled.div`
  .ant-select-selector {
    min-width: auto;
    border: 1px solid #1890ff !important;
    background: #074681 !important;
    font-size: 14px;
    white-space: no-wrap;
    color: #ffffff !important;
    &:hover {
      cursor: pointer;
      border: 1px solid #1890ff;
    }

    .prefixed-selection-item {
      &__prefix {
        color: #ffffff;
      }

      &__label {
        color: #1890ff;
      }
    }
  }
  .ant-select-arrow {
    color: #bae7ff !important;
  }
`;

const ByAnalystsOrTeamMultiSelect = styled(AnalystsMultiSelect)`
  min-width: auto;

  .multiselect__control {
    border: 1px solid #1890ff;
    border-radius: 3px;
    flex-wrap: nowrap;

    &:hover {
      cursor: pointer;
      border: 1px solid #1890ff;
    }
  }
  .multiselect__placeholder {
    font-size: 14px;
    color: #ffffff;
    white-space: no-wrap;
  }
  .multiselect__value-container {
    padding-left: 16px;
    background: #074681;
    color: #ffffff;
  }
  .ant-typography {
    color: #ffffff;
  }

  .anticon-search {
    display: none;
  }
  .multiselect__indicators {
    background: #074681;
  }
  .multiselect__indicator {
    color: #ffffff !important;
  }
`;

const StyledSingleSelectFilter = styled(FilterSingleSelect)`
  min-width: 220px;

  .styled-single-select__value-container {
    padding-left: 0;
  }

  .styled-single-select__control {
    padding-left: 0;
    background-color: #074681 !important;
    border: 1px solid #1890ff;
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      border: 1px solid #1890ff;
    }
    & > span {
      margin-left: 8px;

      & > span:last-child {
        font-size: 14px !important;
        color: #ffffff;
      }
    }
    .anticon {
      color: #ffffff;
    }
  }
  .anticon-search {
    display: none;
  }
`;

const TypographyTitle = styled(Typography.Title)`
  &.ant-typography {
    /* Dark BLue */
    color: #334d6e;
    margin: 0;
  }
`;
const SecondaryTitle = styled(Typography.Paragraph)`
  /* Gray */
  color: #90a0b7;
`;

const Styled = {
  Wrapper,
  StatsWrapper,
  MainContent: StyledMainContent,
  PageTitle,
  TypographyTitle,
  SecondaryTitle,
  ByAnalystsOrTeamMultiSelect,
  PageFiltersWrapper,
  QuickFiltersContent,
  QuickFilterWrapper,
  SingleSelectFilter: StyledSingleSelectFilter,
};

export default Styled;
