import React from 'react';
import { useSelector } from 'react-redux';
import { Tag, Popover } from 'antd';
import queryString from 'query-string';
import { Dictionary } from 'lodash';
import { TableCellSearches } from '@optx/models/table/Cell';
import { useMaxDisplayedChildren } from '@optx/common/hooks/dom';
import { parseFilterSorting } from '@utils/filters/parseFilters';
import { selectors } from '@redux/company/filters';
import useSugestedSearches from '@optx/common/hooks/saved-searches/useSugestedSearches';
import { ParsedFilterSorting } from '@models/filters';
import { generateMoreTags } from '@features/tags/tags/Tags';

const mapSavedFilter = (sortField: ParsedFilterSorting, index: number) => (
  <Tag key={index} id={sortField.name} className="saved__filter">
    <span className="saved__filter-name">{`${sortField.name}: `}</span>
    <span className="saved__filter-value">{sortField.order}</span>
  </Tag>
);

const TableCellSorting: React.FC<TableCellSearches> = ({ row }) => {
  const { domRef, countHiddenChildren, hiddenChildrenIdList } = useMaxDisplayedChildren();
  const { handleSearchResults } = useSugestedSearches();
  const filterSources = useSelector(selectors.getCompanyFilters);
  const filterParsed = queryString.parse(row.search_criteria || '', {
    arrayFormat: 'comma',
  }) as Dictionary<Array<string>>;
  let sorting = [] as Array<ParsedFilterSorting>;

  if (filterParsed.sort_fields && filterParsed.sort_orders) {
    sorting = parseFilterSorting(filterParsed, filterSources);
  }

  const handleClick = () => {
    if (row.unique_id) {
      handleSearchResults(row.title);
    }
  };

  const moreTags = generateMoreTags(countHiddenChildren);

  return (
    <div className="d-flex" role="presentation" onClick={handleClick}>
      {sorting && (
        <>
          <div
            ref={domRef}
            className="tags-wrapper"
            style={{ margin: 0, flex: 1, display: 'inline-block' }}
          >
            {sorting.map(mapSavedFilter)}
          </div>
          {moreTags && (
            <Popover
              overlayClassName="ant-popover-tags"
              content={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <div className="company-list__popover">
                  {sorting
                    .filter(sortItem => hiddenChildrenIdList.includes(sortItem.name || ''))
                    .map(mapSavedFilter)}
                </div>
              }
            >
              {moreTags}
            </Popover>
          )}
        </>
      )}
    </div>
  );
};

export default TableCellSorting;
