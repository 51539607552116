import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Tooltip, Affix } from 'antd';
// models
import { GridKeys } from '@optx/models/grid';
// redux
import { selectors as fullscreenSelectors } from '@redux/ui/settings/fullscreen';
import { selectors as listsSelectors } from '@optx/common/selectors/lists';
import { selectors as listDetailsSelectors } from '@redux/lists/details';
import { selectors as viewSelectors, actions as viewActions } from '@features/grid/view';
import { selectors as viewTypeSelectors } from '@features/grid/view-type';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import * as searchesSelectors from 'src/components/pages/UserLists/components/PageLoader/selectors';
// constants
import { SEARCH_GRID_CONTROLS_OFFSET } from '@constants/ui';
// components
import { UserListContext } from '@components/pages/UserLists/components/SearchGrid/ListsContext';
import { BulkActionsButton } from '@features/bulk-actions/actions-menu';
import GridDropdown from '@optx/components/pages/Home/SearchGridControls/GridDropdown';
import GridView from '@optx/features/grid/view/components/GridView';
import GridFullScreen from '@optx/shared/view/molecules/FullScreen/GridFullScreen';
import { TrendingControls } from '@optx/components/pages/Home/SearchGridControls';
import SearchCount from '@features/grid/search-count/components/SearchCount';
import QuickFiltersToggle from './QuickFiltersToggle';
import ToggleLongCardViewContainer from './ToggleLongCardViewContainer';
import ToggleTableViewContainer from './ToggleTableViewContainer';
// styles
import Styled from './SearchGridControls.styles';
import CardPannelSorting from './CardPannelSorting';

const SearchGridControls = () => {
  const location = useLocation();

  const { pathname } = window.location;
  const gridKey: GridKeys = pathname.includes('ss-lists') ? 'sslists' : 'watchlists';

  const isFullscreen = useSelector(fullscreenSelectors.isFullscreen);
  let listName = useSelector(listsSelectors.getSelectedListName);
  const isSourceScrubRoute = useSelector(listDetailsSelectors.isSourceScrubRoute);
  const activeView = useSelector(viewSelectors.getView(gridKey));
  const applyView = viewActions.applyView;
  const viewType = useSelector(viewTypeSelectors.searchView('lists'));
  const selectedCompanies = useSelector(
    bulkActionsSelectors.selectedCompanies.getSelectedCompanies('watchlists')
  );
  const loading = useSelector(searchesSelectors.listIsLoading);
  const sourceScrubListCompanyName = useSelector(listDetailsSelectors.getListData);

  if (!listName.length && sourceScrubListCompanyName) {
    listName = sourceScrubListCompanyName.title;
  }

  return (
    <Affix offsetTop={SEARCH_GRID_CONTROLS_OFFSET}>
      <>
        <Styled.SearchGridWrapper align="middle">
          <Styled.PageTitle ellipsis>
            <span className="d-flex align-items-center">
              <Tooltip title={listName} className="mr-3 text-truncate">
                {!loading && listName}
              </Tooltip>
              <SearchCount
                gridName={isSourceScrubRoute ? 'sslists' : 'watchlists'}
                searchViewType={viewType}
              />
            </span>
          </Styled.PageTitle>

          <Styled.ControlsWrapper>
            <TrendingControls />
            {viewType === 'table' && (
              <GridView gridKey={gridKey} activeView={activeView} applyView={applyView} />
            )}
            {viewType === 'long-card' && <CardPannelSorting />}
            <ToggleTableViewContainer />
            <ToggleLongCardViewContainer />
            {viewType === 'table' && !isFullscreen && <GridFullScreen />}
            <QuickFiltersToggle />
            <GridDropdown />
            {!!selectedCompanies.length && viewType === 'table' && (
              <BulkActionsButton
                context={UserListContext}
                selectedCompanies={selectedCompanies}
                referrer={encodeURIComponent(location.pathname)}
              />
            )}
          </Styled.ControlsWrapper>
        </Styled.SearchGridWrapper>
      </>
    </Affix>
  );
};

export default React.memo(SearchGridControls);
