import styled from 'styled-components';
import { Card } from 'antd';

const StyledCard = styled(Card)`
  .ant-card {
    border-radius: 8px;
  }

  .ant-card-body {
    overflow-y: auto;
    max-height: 510px;
  }
`;

export default {
  StyledCard,
};
