import React from 'react';
import { Tooltip } from 'antd';

export const TableCellErrorReason = (value: string) => {
  if (!value) {
    return null;
  }

  return (
    <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={value}>
      <span style={{ display: 'block' }}>{value}</span>
    </Tooltip>
  );
};

export default TableCellErrorReason;
