import styled from 'styled-components';
// components
import StyledTooltip from '@optx/components/common/charts/Tooltip.styles';

const PreviousPeriodLabel = styled(StyledTooltip.Label)`
  margin: 10px 0 0;
`;

const Styled = {
  PreviousPeriodLabel,
};

export default Styled;
