import React from 'react';
import { BreadcrumbProps } from 'antd/lib/breadcrumb';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import appRoutes from '@constants/routes';

interface ReportBreadcrumbProps extends BreadcrumbProps {
  reportPage?: string;
  onClick?: () => void;
  hasSourcingOutReach?: boolean;
}

const ReportBreadcrumb: React.FC<ReportBreadcrumbProps> = ({
  reportPage,
  onClick,
  hasSourcingOutReach,
}) => (
  <Breadcrumb>
    <Breadcrumb.Item>Reports</Breadcrumb.Item>
    <Breadcrumb.Item>
      <Link onClick={onClick} to={appRoutes.reports.allReports}>
        All Reports
      </Link>
    </Breadcrumb.Item>
    {hasSourcingOutReach && (
      <Breadcrumb.Item>
        <Link onClick={onClick} to={appRoutes.reports.sourcingOutreachSummary}>
          Sourcing Outreach Summary
        </Link>
      </Breadcrumb.Item>
    )}

    {reportPage && <Breadcrumb.Item>{reportPage}</Breadcrumb.Item>}
  </Breadcrumb>
);

export default ReportBreadcrumb;
