import { createSelector } from 'reselect';
import { AppState } from '@optx/redux/interfaces';
import { selectors as userInformationSelectors } from '@redux/user/information';
import { SortByRule } from '@optx/models/table/sorting';
import { mapScheduledTouchesTags } from '@optx/utils/filters/mapTags';
import { ScheduledTouchesFilterTags } from './interfaces';

export const scheduledTouchesState = (state: AppState) => state.scheduledTouches;

export const getPagination = createSelector(scheduledTouchesState, state => state.pagination);
export const getSortValue = createSelector(scheduledTouchesState, state => state.sortBy);
export const getSortBy = createSelector(
  scheduledTouchesState,
  userInformationSelectors.getDefaultScore,
  (state, defaultOptxScore) => {
    const getSort = state.sortBy.split('.');

    const sort = getSort.map((item: string) => {
      if (item === 'score') {
        if (defaultOptxScore === 'il') {
          return 'il_optx_score';
        }

        return 'score';
      }

      if (item === 'il_optx_score') {
        if (defaultOptxScore === 'il') {
          return 'il_optx_score';
        }

        return 'score';
      }

      return item;
    });

    return [
      {
        id: sort[0],
        desc: sort[1] === 'desc',
      },
    ] as SortByRule<string>[];
  }
);
export const getStageType = createSelector(scheduledTouchesState, state => state.stageType);
export const getTouchType = createSelector(scheduledTouchesState, state => state.touchType);
export const getRankType = createSelector(scheduledTouchesState, state => state.rankType);
export const getAllStageTypes = createSelector(
  scheduledTouchesState,
  state => state.dashboard.stages
);
export const getAllTouchTypes = createSelector(
  scheduledTouchesState,
  state => state.dashboard.touchTypes
);
export const getAllRankTypes = createSelector(
  scheduledTouchesState,
  state => state.dashboard.ranks
);
export const getDates = createSelector(scheduledTouchesState, state => state.dashboard.inputDates);
export const getCards = createSelector(scheduledTouchesState, state => state.dashboard.cards);
export const getTabs = createSelector(scheduledTouchesState, state => state.dashboard.tabs);
export const getSelectedTab = createSelector(scheduledTouchesState, state => state.selectedTab);
export const getSelectedPeriod = createSelector(
  scheduledTouchesState,
  state => state.selectedPeriod
);
export const getSelectedDate = createSelector(
  scheduledTouchesState,
  state => state.selectedDateRage
);

export const getTouches = createSelector(scheduledTouchesState, state => state.touches.data);
export const getTotalTouches = createSelector(
  scheduledTouchesState,
  state => state.remainedTouches
);
export const getTabsCount = createSelector(scheduledTouchesState, state => state.count.data?.tabs);
export const getCardsCount = createSelector(
  scheduledTouchesState,
  state => state.count.data?.cards
);

export const getTags = createSelector(
  getStageType,
  getTouchType,
  getRankType,
  getAllStageTypes,
  getSelectedPeriod,
  getSelectedDate,
  (stageType, touchType, rankType, allStageTypes, selectedPeriod, selectedDateRange) => {
    const stageTypeName = allStageTypes.find(item => item.value === stageType)?.name;

    const scheduledTouchesFiltersObject: ScheduledTouchesFilterTags = {
      stageType: stageTypeName || '',
      touchType,
      rankType,
      selectedPeriod,
      selectedDateRange,
    };
    const tags = mapScheduledTouchesTags(scheduledTouchesFiltersObject);

    return tags;
  }
);

export const showClearedFilter = createSelector(getTags, tags => {
  if (tags.length > 0) {
    return true;
  }

  return false;
});

export const isDashboardLoading = createSelector(
  scheduledTouchesState,
  state => state.dashboard.loading
);
export const isTouchesLoading = createSelector(
  scheduledTouchesState,
  state => state.touches.loading
);
export const isTouchesCountLoading = createSelector(
  scheduledTouchesState,
  state => state.count.loading
);
export const shouldFetchTouches = createSelector(
  scheduledTouchesState,
  state => !state.touches.loading && !state.touches.fetchedAt
);
export const isLoading = createSelector(
  isDashboardLoading,
  isTouchesLoading,
  isTouchesCountLoading,
  (isDashboardLoading, isTouchesLoading, isTouchesCountLoading) =>
    isDashboardLoading || isTouchesCountLoading || isTouchesLoading
);

export const getInsight = createSelector(scheduledTouchesState, state => state.insight);
export const getInsightTotal = createSelector(scheduledTouchesState, state => state.insightTotal);
