import { createReducer, CaseReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { BulkMergeCompaniesResponse } from './interfaces';

const initialState: BulkMergeCompaniesResponse = {
  status: false,
};

const fetchMergeReducer: CaseReducer<BulkMergeCompaniesResponse> = (draftState, action) => {
  draftState.status = true;
};

const mergeStatusInvertReducer: CaseReducer<BulkMergeCompaniesResponse> = draftState => {
  draftState.status = false;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.bulkMergeCompanies, fetchMergeReducer)
    .addCase(actions.invertStatusAfterMerge, mergeStatusInvertReducer)
);

export default reducer;
