import { createReducer, CaseReducer } from '@reduxjs/toolkit';
import { Dictionary } from 'lodash';
import * as typeSuffix from '../../constants';

const initialState: Dictionary<boolean> = {};

const regexString = `(.*)_(${typeSuffix.REQUEST}|${typeSuffix.SUCCESS}|${typeSuffix.FAILURE})`;
const actionTypeRegex = new RegExp(regexString);

const fetchReducer: CaseReducer<Dictionary<boolean>> = (draftState, action) => {
  const { type } = action;
  const matches = actionTypeRegex.exec(type);

  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return;

  const [, requestName, requestState] = matches;

  // Store whether a request is happening at the moment or not
  // e.g. will be true when receiving GET_TODOS_REQUEST
  // and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
  draftState[requestName] = requestState === typeSuffix.REQUEST;
};

const reducer = createReducer(initialState, builder => builder.addDefaultCase(fetchReducer));

export default reducer;
