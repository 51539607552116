import { combineReducers } from 'redux';
import { ShareListFeatureState } from './interfaces';
import shareListReducer from './share-list';
import uiReducer from './ui';

const reducer = combineReducers<ShareListFeatureState>({
  shareList: shareListReducer,
  ui: uiReducer,
});

export default reducer;
