import { AxiosResponse } from 'axios';
import { call, takeLatest, put, all, take, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
// models
import { CompanyProfile } from '@models/Company';
// constants
import { TOUCHES_PER_PAGE } from '@features/long-card/company-card/constants/touches';
// services
import { CompanyService } from '@services/api';
import NotificationService from '@services/NotificationService';
// redux
import { actions as employeeGrowthActions } from '@features/company/graphs/employee';
import { actions as scoreGrowthActions } from '@features/company/graphs/score';
import {
  actions as cardActions,
  selectors as cardSelectors,
} from '@features/long-card/company-card';
import { actions as touchActions, selectors as touchSelectors } from '@features/company-touches';
import { actions as userListActions } from '@redux/user/user-list';
import { actions as opportunityActions } from '@redux/company/opportunity-presentation';
import { actions as filterActions } from '@redux/company/filters';
import { actions as editActions } from '@components/feature/company-individual-edit/state';
import * as actions from './actions';
import { ExtensionProfileResponse } from './interface';

export function* fetchExtensionCompaniesSaga(
  action: PayloadAction<string, any, VoidFunction | undefined>
) {
  const { payload: hostURL, meta: callback } = action;

  try {
    const res: AxiosResponse<ExtensionProfileResponse[]> = yield call(
      CompanyService.getExtensionCompanies,
      hostURL
    );

    if (res.data) {
      yield put(actions.fetchExtensionCompaniesSuccess(res.data));
      const companyId = res.data[0].company_id;
      const touchPagination = touchSelectors.search.pagination;

      yield all([
        put(cardActions.fetchCompanyProfile(companyId, () => callback && callback())),
        put(editActions.fetchSelectOptions('')),
        put(filterActions.fetchCompanyFilters()),
        put(userListActions.fetchUserList(false)),
        put(touchActions.filters.fetchTouchesFilters(companyId)),
        put(
          touchActions.search.fetchTouches({
            companyId,
            pagination: { ...touchPagination, pageSize: TOUCHES_PER_PAGE, pageNumber: 1 },
          })
        ),
        put(opportunityActions.fetchOpportunityPresentation(companyId)),
        put(employeeGrowthActions.fetchEmployeeGrowth(companyId)),
      ]);

      yield take(cardActions.fetchCompanyProfileSuccess);
      const activeProfile: CompanyProfile = yield select(cardSelectors.getProfile);

      if (activeProfile)
        yield put(
          scoreGrowthActions.fetchScoreGrowth({
            company_id: activeProfile.company_id,
            company_url: activeProfile.company_url,
          })
        );
    } else {
      callback && callback();
    }
  } catch (error: any) {
    const errorMessage = 'Failed to fetch company from Equity Touch!';
    yield put(actions.fetchExtensionCompaniesFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* equityTouchSaga() {
  yield takeLatest(actions.fetchExtensionCompanies, fetchExtensionCompaniesSaga);
}
