import { createReducer, CaseReducer } from '@reduxjs/toolkit';
// constants
import { INITIAL_STATE_CHART } from '@optx/constants/charts';
// redux
import { fetchFailReducer, fetchReducer, fetchSuccessReducer } from '@redux/feature/fetch/reducers';
import { SocialMediaChartData } from '@optx/models/charts/data';
import { LOCATION_CHANGE } from 'connected-react-router';
import ROUTES from '@optx/constants/routes';
import * as actions from './actions';
import { SocialMediaGraphState } from '../interfaces';

const initialState: SocialMediaGraphState = {
  data: INITIAL_STATE_CHART as SocialMediaChartData,
  loading: false,
  error: '',
};

const clearStateReducer: CaseReducer<SocialMediaGraphState> = (draftState, action) => {
  if (action.payload.location.pathname.includes(ROUTES.profile)) {
    draftState.data = INITIAL_STATE_CHART as SocialMediaChartData;
  }
};

export const reducer = createReducer<SocialMediaGraphState>(initialState, builder =>
  builder
    .addCase(actions.fetchTwitterData, fetchReducer)
    .addCase(actions.fetchTwitterDataSuccess, fetchSuccessReducer)
    .addCase(actions.fetchTwitterDataFail, fetchFailReducer)
    .addCase(LOCATION_CHANGE, clearStateReducer)
);
