import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
// utils
import {
  getMultiSelectOptions,
  orderAnalystsByCustom,
} from '@optx/utils/filters/mapFiltersOptions';
// models
import { MultiSelectFilterColumns } from '@optx/models/feature/analystsLeaderboard/filters';
import { SelectOption } from '@models/Option';
// hooks
import useLocalMultiSelect from '@optx/common/hooks/select/useLocalMultiSelect';
// components
import { MultiSelectInline, MultiSelectProps } from '@shared/view/molecules/Select';
// redux
import { actions, selectors } from '../../state';

interface MultiSelectFilterProps extends MultiSelectProps {
  filterKey: MultiSelectFilterColumns;
}

export const MultiSelectFilter: React.FC<MultiSelectFilterProps> = ({
  filterKey,
  ...restProps
}) => {
  const [options, setOptions] = useState<Array<SelectOption>>();
  const dispatch = useDispatch();
  const { normalized, values } = useSelector(selectors.filters.getFilters);
  const normalizedData = normalized[filterKey];

  const valuesData = values[filterKey];

  useEffect(() => {
    if (normalizedData) {
      const sortedOptions = orderAnalystsByCustom(
        getMultiSelectOptions(normalizedData as any) as any
      );

      setOptions(sortedOptions);
    }
  }, [normalizedData]);

  const updateValue = useCallback(
    (newValue: Array<SelectOption>) => {
      const isValueExist = _.isEqual(valuesData, newValue);

      // don't dispatch if user select same value
      if (isValueExist) {
        return;
      }

      dispatch(actions.filters.updateFilters({ value: newValue, key: filterKey }));
    },
    [dispatch, valuesData, filterKey]
  );

  const { localValue, onBlur, onChange } = useLocalMultiSelect(valuesData as any, updateValue);

  return (
    <MultiSelectInline
      options={options}
      onBlur={onBlur}
      onChange={onChange}
      value={localValue}
      placeholder={normalizedData ? normalizedData.placeholders : undefined}
      allPrefix={normalizedData ? normalizedData.placeholders : undefined}
      {...restProps}
    />
  );
};

export default MultiSelectFilter;
