import React from 'react';
import { Menu, MenuProps } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { useInjectSaga } from 'redux-injectors';
// redux
import { useDispatch, useSelector } from 'react-redux';
import logOutSaga from '../../../../redux/auth/authentication/saga';
import {
  actions as cognitoLogoutActions,
  selectors as cognitoLogoutSelectors,
} from '../../../../redux/auth/cognitoLogout';
import * as authActions from '../../../../redux/auth/authentication/actions';
// hooks
import { useInitialFetch } from '../../../../common/hooks/fetch';

// Inject reducers/sagas
const useInject = () => {
  useInjectSaga({ key: 'logOut', saga: logOutSaga });
};

/**
 * Logout Menu Item.
 * @param param0
 */
const LogOut = ({ onClick, ...restProps }: MenuItemProps) => {
  useInject();
  const dispatch = useDispatch();

  // initialize cognito login URL
  const shouldFetchCognitoLogoutURL = useSelector(
    cognitoLogoutSelectors.shouldFetchCognitoLogoutURL
  );
  const fetchCognitoLogoutURL = () => dispatch(cognitoLogoutActions.fetchCognitoLogoutURL());
  useInitialFetch(shouldFetchCognitoLogoutURL, fetchCognitoLogoutURL);

  const logOut = () => dispatch(authActions.logOut({ isChromeExtenison: false }));

  const handleClick: MenuProps['onClick'] = info => {
    logOut();
    onClick && onClick(info);
  };

  return (
    <Menu.Item onClick={handleClick} {...restProps}>
      Log Out
    </Menu.Item>
  );
};

export default LogOut;
