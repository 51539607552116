import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import reducer, { saga } from '../../../redux/company/in-business';

const useInjectCompanyInBusinessEvaluation = () => {
  useInjectReducer({
    key: 'companyInBusinessEvaluation',
    reducer,
  });
  useInjectSaga({
    key: 'companyInBusinessEvaluationSaga',
    saga,
  });
};

export default useInjectCompanyInBusinessEvaluation;
