import { AxiosResponse } from 'axios';
import queryString from 'query-string';
import { call, put, select, takeLatest, take } from 'redux-saga/effects';
// services
import { AnalystService } from '@services/api';
import NotificationService from '@services/NotificationService';
// utils
import { getErrorMessage } from '@utils/api/errors';
//redux
import {
  actions as userInformationActions,
  selectors as userInformationSelectors,
} from '@redux/user/information';
import { actions } from '../actions';
import { selectors } from '../selectors';
import {
  InsightsDataState,
  FiltersState,
  TouchActivityState,
  CompanyActivityResponse,
  ActivityState,
} from '../interfaces';

export function* fetchCompanyActivitySaga() {
  try {
    const res: AxiosResponse<CompanyActivityResponse> = yield call(
      AnalystService.getCompanyActivity
    );

    if (res.data) {
      yield put(actions.company.fetchCompanyActivitySuccess(res.data));
    } else {
      yield put(actions.company.fetchCompanyActivityFail('Failed to fetch company activity!'));
      NotificationService.error('Failed to fetch company activity!');
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to fetch company activity!');

    yield put(actions.company.fetchCompanyActivityFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* fetchInsightsStatsSaga() {
  try {
    const res: AxiosResponse<InsightsDataState> = yield call(
      AnalystService.getAnalystHomeInsightsStats
    );

    if (res.data) {
      yield put(actions.insights.fetchInsightsStatsSuccess(res.data));
    } else {
      yield put(
        actions.insights.fetchInsightsStatsFail('Failed to fetch My Pipeline & Outreach Recency!')
      );
      NotificationService.error('Failed to fetch My Pipeline & Outreach Recency!');
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to fetch My Pipeline & Outreach Recency!');

    yield put(actions.insights.fetchInsightsStatsFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* fetchTouchActivitySaga() {
  try {
    const dates: [string, string] = yield select(selectors.getActivityInsightsDateFilter);
    const payload = { date_lower: dates[0], date_upper: dates[1] };

    const res: AxiosResponse<TouchActivityState> = yield call(
      AnalystService.getAnalystHomeTouchActivity,
      payload
    );

    if (res.data) {
      yield put(actions.activity.fetchTouchActivitySuccess(res.data));
    } else {
      yield put(
        actions.activity.fetchTouchActivityFail('Failed to fetch My Pipeline & Outreach Recency!')
      );
      NotificationService.error('Failed to fetch My Pipeline & Outreach Recency!');
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to fetch My Pipeline & Outreach Recency!');

    yield put(actions.activity.fetchTouchActivityFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* fetchActivitySaga() {
  try {
    const dates: [string, string] = yield select(selectors.getActivityInsightsDateFilter);
    const payload = { ddate_lower: dates[0], ddate_upper: dates[1] };

    const res: AxiosResponse<ActivityState> = yield call(
      AnalystService.getAnalystHomeActivity,
      payload
    );

    if (res.data) {
      yield put(actions.activity.fetchActivitySuccess(res.data));
    } else {
      yield put(
        actions.activity.fetchActivityFail('Failed to fetch My Pipeline & Outreach Recency!')
      );
      NotificationService.error('Failed to fetch My Pipeline & Outreach Recency!');
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to fetch My Pipeline & Outreach Recency!');

    yield put(actions.activity.fetchActivityFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* fetchFiltersSaga() {
  try {
    const res: AxiosResponse<FiltersState> = yield call(AnalystService.getAnalystHomeFilters);

    if (res.data) {
      yield put(actions.filters.fetchFiltersSuccess(res.data));
    } else {
      yield put(actions.filters.fetchFiltersFail('Failed to fetch filters!'));
      NotificationService.error('Failed to fetch filters!');
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to fetch filters!');

    yield put(actions.filters.fetchFiltersFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* updateFilterSaga() {
  const dates: [string, string] = yield select(selectors.getActivityInsightsDateFilter);

  const payload = { date_lower: dates[0], date_upper: dates[1] };
  const query = queryString.stringify(payload, {
    arrayFormat: 'comma',
  });

  yield put(
    userInformationActions.updateUserSettings({
      analyst_home_touch_activity_filters: query,
    })
  );

  yield put(actions.activity.fetchActivity());
  yield put(actions.activity.fetchTouchActivity());
}

export function* fetchInitActivitySaga() {
  const userIsLoaded: boolean = yield select(userInformationSelectors.loaded);

  if (!userIsLoaded) {
    yield take(userInformationActions.fetchUserInformationSuccess);
  }

  yield put(actions.activity.fetchActivity());
}

export function* fetchInitTouchActivitySaga() {
  const userIsLoaded: boolean = yield select(userInformationSelectors.loaded);

  if (!userIsLoaded) {
    yield take(userInformationActions.fetchUserInformationSuccess);
  }

  yield put(actions.activity.fetchTouchActivity());
}

export function* saga() {
  yield takeLatest(actions.company.fetchCompanyActivity, fetchCompanyActivitySaga);
  yield takeLatest(actions.insights.fetchInsightsStats, fetchInsightsStatsSaga);
  yield takeLatest(actions.activity.fetchInitActivity, fetchInitActivitySaga);
  yield takeLatest(actions.activity.fetchActivity, fetchActivitySaga);
  yield takeLatest(actions.activity.fetchInitTouchActivity, fetchInitTouchActivitySaga);
  yield takeLatest(actions.activity.fetchTouchActivity, fetchTouchActivitySaga);
  yield takeLatest(actions.filters.fetchFilters, fetchFiltersSaga);
  yield takeLatest(actions.filters.updateFilter, updateFilterSaga);
}
