/* eslint max-len: 0 */
import React from 'react';
import classnames from 'classnames';

interface IconProps extends React.SVGProps<SVGElement> {}

const Camera: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className={classnames('icon icon-camera', className)}
    >
      <title>camera</title>
      <path d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z" />
    </svg>
  );
};

export { Camera };

export const ThumbsUp: React.FC<IconProps> = () => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="thumbs-up"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8500 11000"
      width="20px"
      height="20px"
    >
      <path d="M7727 8644l-1133 0c-520,598 -1085,1036 -1692,1313 -191,16 -334,24 -425,24 -259,0 -552,-47 -881,-138l-272 138 -326 0c-744,0 -1117,-267 -1117,-798 0,-31 5,-75 15,-138 -546,-44 -821,-305 -821,-780 0,-51 5,-121 15,-212 -202,-57 -355,-148 -458,-275 -104,-124 -156,-285 -156,-477 0,-155 55,-324 166,-500 -272,-293 -409,-619 -409,-979 0,-415 225,-749 676,-1003 451,-251 1047,-378 1791,-378l189 0c-60,-456 -200,-897 -417,-1322l-122 -212c-262,-501 -394,-889 -394,-1161 0,-754 396,-1226 1192,-1413l220 -54 381 1687 1405 1495c96,112 261,327 500,645l212 280c228,309 389,498 482,565 94,70 244,104 451,104l889 0c272,580 409,1179 409,1790 0,596 -124,1198 -370,1799zm-252 -394c159,-461 236,-902 236,-1322 0,-495 -85,-982 -259,-1464 -637,0 -1036,-39 -1197,-114 -163,-75 -407,-342 -731,-798 -129,-176 -326,-428 -583,-749l-1526 -1625 -303 -1407c-508,174 -762,498 -762,975 0,246 85,515 259,803l98 168c397,659 594,1286 594,1884l8 213 -275 0c-803,0 -1410,80 -1814,238 -407,161 -609,402 -609,726 0,293 106,541 316,743 210,203 467,304 770,304l464 5 1376 -620c-208,-313 -487,-471 -837,-471 -91,0 -208,21 -348,59 -142,42 -308,104 -502,184l-358 16 -357 -381 616 -8c179,-75 340,-132 487,-166 146,-36 280,-54 402,-54 648,0 1122,329 1420,987l-259 122c319,156 565,430 738,819l-298 127c234,148 449,399 646,751l-469 221c256,189 464,453 622,798l-933 417c150,31 280,47 386,47l295 -8c674,-389 1221,-863 1641,-1420l1046 0zm-3462 -1101c-176,-236 -388,-389 -637,-454l-1132 508 -402 0c-381,0 -669,-54 -866,-161 -64,91 -98,179 -98,259 0,156 78,272 231,353 155,78 386,116 694,116l806 0 1404 -621zm319 888c-23,-31 -44,-57 -59,-77 -122,-140 -267,-252 -433,-332l-1164 516 -1228 -8 -23 98c0,298 295,449 889,449l570 0 1448 -646zm161 1024c-36,-44 -62,-80 -83,-106 -96,-138 -218,-259 -365,-371l-1086 485 -700 0 0 46c0,340 246,508 739,508l248 0 1247 -562z" />
    </svg>
  );
};

export const ThumbsDown: React.FC<IconProps> = () => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="thumbs-down"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8500 11000"
      width="20px"
      height="20px"
    >
      <path d="M824 2025l1133 0c520,-598 1085,-1036 1692,-1314 191,-15 334,-23 425,-23 259,0 552,47 881,137l272 -137 326 0c744,0 1117,267 1117,798 0,31 -5,75 -15,138 546,44 821,305 821,780 0,51 -5,121 -16,212 203,57 355,148 459,275 104,124 156,285 156,477 0,155 -55,323 -166,500 272,292 409,619 409,979 0,415 -225,749 -676,1003 -451,251 -1047,378 -1791,378l-189 0c60,456 200,897 417,1322l122 212c262,501 394,889 394,1161 0,754 -396,1226 -1192,1413l-220 54 -381 -1687 -1405 -1495c-96,-112 -261,-327 -500,-645l-212 -280c-228,-309 -389,-498 -482,-565 -94,-70 -244,-104 -451,-104l-889 0c-272,-580 -409,-1179 -409,-1790 0,-596 124,-1198 370,-1799zm252 394c-159,461 -236,902 -236,1322 0,495 85,982 259,1464 637,0 1036,39 1197,114 163,75 407,342 731,795 129,179 326,431 583,752l1526 1625 303 1404c508,-171 762,-495 762,-972 0,-246 -85,-515 -259,-803l-98 -168c-397,-659 -594,-1286 -594,-1884l-8 -213 275 0c803,0 1410,-80 1814,-238 407,-161 609,-402 609,-726 0,-293 -106,-541 -316,-743 -210,-203 -467,-304 -770,-304l-464 -5 -1376 620c208,313 487,471 837,471 91,0 208,-21 348,-59 142,-42 308,-104 502,-184l358 -16 357 381 -616 8c-179,75 -340,132 -487,166 -146,36 -280,54 -402,54 -648,0 -1122,-329 -1420,-987l259 -122c-319,-156 -565,-430 -736,-819l295 -127c-233,-148 -448,-399 -645,-754l469 -218c-256,-189 -464,-453 -622,-798l933 -417c-150,-31 -280,-47 -386,-47l-295 8c-674,389 -1221,863 -1641,1420l-1046 0zm3461 1101c177,236 389,387 638,454l1132 -508 402 0c381,0 669,54 865,161 65,-91 99,-179 99,-259 0,-156 -78,-273 -231,-353 -155,-78 -386,-116 -694,-116l-806 0 -1405 621zm-318 -888c23,31 44,57 59,75 122,142 267,254 433,334l1164 -516 1228 8 23 -98c0,-298 -295,-449 -889,-449l-570 0 -1448 646zm-161 -1024c36,44 62,80 83,106 96,138 218,259 365,371l1086 -485 700 0 0 -46c0,-340 -246,-508 -739,-508l-248 0 -1247 562z" />
    </svg>
  );
};

const IconTwitter: React.FC<IconProps> = ({ className }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    className={classnames('icon-twitter', className)}
  >
    <path d="M32 7.075c-1.175 0.525-2.444 0.875-3.769 1.031 1.356-0.813 2.394-2.1 2.887-3.631-1.269 0.75-2.675 1.3-4.169 1.594-1.2-1.275-2.906-2.069-4.794-2.069-3.625 0-6.563 2.938-6.563 6.563 0 0.512 0.056 1.012 0.169 1.494-5.456-0.275-10.294-2.888-13.531-6.862-0.563 0.969-0.887 2.1-0.887 3.3 0 2.275 1.156 4.287 2.919 5.463-1.075-0.031-2.087-0.331-2.975-0.819 0 0.025 0 0.056 0 0.081 0 3.181 2.263 5.838 5.269 6.437-0.55 0.15-1.131 0.231-1.731 0.231-0.425 0-0.831-0.044-1.237-0.119 0.838 2.606 3.263 4.506 6.131 4.563-2.25 1.762-5.075 2.813-8.156 2.813-0.531 0-1.050-0.031-1.569-0.094 2.913 1.869 6.362 2.95 10.069 2.95 12.075 0 18.681-10.006 18.681-18.681 0-0.287-0.006-0.569-0.019-0.85 1.281-0.919 2.394-2.075 3.275-3.394z" />
  </svg>
);

export { IconTwitter };

const IconStart: React.FC<IconProps> = ({ fill, stroke }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill={fill}
        fillOpacity="1"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 18.01l-3.423 1.767c-.965.498-2.16.134-2.667-.814a1.908 1.908 0 0 1-.198-1.23l.653-3.743-2.769-2.65a1.914 1.914 0 0 1-.035-2.742c.303-.306.7-.504 1.13-.566l3.827-.546 1.711-3.405c.483-.96 1.667-1.354 2.645-.88.39.188.705.498.897.88l1.711 3.405 3.827.546c1.08.154 1.827 1.138 1.67 2.198a1.927 1.927 0 0 1-.576 1.11l-2.769 2.65.654 3.743c.184 1.055-.538 2.058-1.613 2.239a2.007 2.007 0 0 1-1.252-.195L12 18.01z"
      />
    </svg>
  );
};

IconStart.defaultProps = {
  fill: 'none',
  stroke: '#B8BFCB',
};

interface IconBellProps extends React.SVGProps<SVGElement> {
  size?: string;
}

const IconBell: React.FC<IconBellProps> = ({ fill, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <desc>Created with sketchtool.</desc>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="icn/favorite-default"
          transform="translate(-6.000000, -6.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="Group-5">
            <g id="notification_ico" transform="translate(6.000000, 6.000000)">
              <path
                d="M16.0711554,7.96507 C16.0711554,5.00467288 13.8822416,2.55431411 11.0114798,2.08054064 L11.0114798,0 L8.98855057,0 L8.98855057,2.08054064 C6.11781911,2.55428424 3.92887498,5.0047625 3.92887498,7.96507 L3.92887498,13.0348749 C3.92531761,14.1329181 3.02175784,15.0208998 1.9047619,15.0208998 L1.9047619,17.0139447 L6.96443749,17.0127776 C6.96443749,18.661575 8.32452127,20 10,20 C11.6754787,20 13.0355625,18.661575 13.0355625,17.0127776 L18.0952381,17.0127776 L18.0952381,15.0220628 C16.9770583,15.0220628 16.071125,14.1305561 16.071125,13.0313479 L16.0711554,7.96507 Z M9.9047619,4 C12.1139009,4 13.9047619,5.790861 13.9047619,8 L13.9047619,15 L5.9047619,15 L5.9047619,8 C5.9047619,5.790861 7.69562291,4 9.9047619,4 Z M8.98858093,17.0126901 L11.0115101,17.0126901 C11.0115101,17.5646392 10.5597274,18.0091976 10.0000607,18.0091976 C9.44033334,18.0091976 8.98858093,17.5646093 8.98858093,17.0126901 Z"
                id="Shape"
              />
              <path
                d="M15,15 L5,15 L5,7.32908265 C5,4.94065125 7.51862061,3 10,3 C12.4827295,3 15,4.9419501 15,7.32908265 L15,15 Z"
                id="Path"
                opacity="0.160505022"
              />
              <path
                d="M2.19207615,7.61904762 C2.19207615,5.53631533 3.1737589,3.65038689 4.76190476,2.28594056 L3.20971183,0.952380952 C1.22710344,2.65827828 0,5.01488979 0,7.61904762 L2.19207615,7.61904762 Z"
                id="Path"
              />
              <path
                d="M20,7.61904762 C20,5.01533166 18.7732186,2.65906346 16.7885654,0.952380952 L15.2380952,2.28684548 C16.8258243,3.65111685 17.8072495,5.53675357 17.8072495,7.61904762 L20,7.61904762 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconBell.defaultProps = {
  fill: '#B8BFCB',
  size: '20px',
};

const IconCompany: React.FC<IconProps> = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    style={{
      shapeRendering: 'geometricPrecision',
      textRendering: 'geometricPrecision',
      imageRendering: 'auto',
    }}
    width={width ?? '100px'}
    height={height ?? '100px'}
    viewBox="0 0 846.66 1058.325"
    x="0px"
    y="0px"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <defs />
    <g style={{ transform: 'translate(0, 90px)' }}>
      <path
        fill="#000000"
        d="M273.62 313.04l0 -203.72 150.82 -79.54 150.82 79.54 0 202.46 60.3 -34.67 100.04 57.51 0 482.26c-208.18,0 -416.36,0 -624.53,0l0 -482.26 100.05 -57.51 62.5 35.93zm142.58 464.09l0 -698.18 -102.84 54.23 0 643.95 102.84 0zm-204.78 0l0 -454.21 59.98 34.48 0 419.73 -59.98 0zm423.83 0l0 -454.21 -59.99 34.48 0 419.73 59.99 0z"
      />
    </g>
    <text
      x="0"
      y="861.66"
      fill="#000000"
      fontSize="5px"
      fontWeight="bold"
      fontFamily="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"
    >
      Created by wardehpillai
    </text>
    <text
      x="0"
      y="866.66"
      fill="#000000"
      fontSize="5px"
      fontWeight="bold"
      fontFamily="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"
    >
      from the Noun Project
    </text>
  </svg>
);

const IconMail: React.FC<IconProps> = ({ fill }) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>AB6F94F9-B95A-424A-86E5-27ECD89A8595</title>
      <desc>Created with sketchtool.</desc>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Stockholm-icons-/-Communication-/-Mail">
          <rect id="bound" x="0" y="0" width="24" height="24" />
          <path
            d="M19,6 C20.1045695,6 21,6.8954305 21,8 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 Z M19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 L18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 Z"
            id="Combined-Shape"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
};

IconMail.defaultProps = {
  fill: '#000000',
};

const IconPhone: React.FC<IconProps> = ({ fill }) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>01C7F50F-883C-433A-9EB5-58CB6F69847C</title>
      <desc>Created with sketchtool.</desc>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Stockholm-icons-/-Communication-/-Incoming-call">
          <rect id="bound" x="0" y="0" width="24" height="24" />
          <path
            d="M9.8604543,6.01162174 C9.94073619,5.93133984 10.0459506,5.88077119 10.1587919,5.86823326 C10.4332453,5.83773844 10.6804547,6.03550595 10.7109496,6.30995936 L11.2341533,11.0187935 C11.2382309,11.0554911 11.2382309,11.0925274 11.2341533,11.129225 C11.2036585,11.4036784 10.9564491,11.6014459 10.6819957,11.5709511 L5.97316161,11.0477473 C5.86032028,11.0352094 5.75510588,10.9846407 5.67482399,10.9043588 C5.47956184,10.7090967 5.47956184,10.3925142 5.67482399,10.197252 L7.06053236,8.81154367 L5.55907018,7.31008149 C5.36380803,7.11481935 5.36380803,6.79823686 5.55907018,6.60297471 L6.26617696,5.89586793 C6.46143911,5.70060578 6.7780216,5.70060578 6.97328374,5.89586793 L8.47474592,7.39733011 L9.8604543,6.01162174 Z"
            id="Shape"
            fill="#000000"
            fillRule="nonzero"
            opacity="0.3"
          />
          <path
            d="M12.0799676,14.7839934 L14.2839934,12.5799676 C14.8927139,11.9712471 15.0436229,11.0413042 14.6586342,10.2713269 L14.5337539,10.0215663 C14.1487653,9.25158901 14.2996742,8.3216461 14.9083948,7.71292558 L17.6411989,4.98012149 C17.836461,4.78485934 18.1530435,4.78485934 18.3483056,4.98012149 C18.3863063,5.01812215 18.4179321,5.06200062 18.4419658,5.11006808 L19.5459415,7.31801948 C20.3904962,9.0071287 20.0594452,11.0471565 18.7240871,12.3825146 L12.7252616,18.3813401 C11.2717221,19.8348796 9.12170075,20.3424308 7.17157288,19.6923882 L4.75709327,18.8875616 C4.49512161,18.8002377 4.35354162,18.5170777 4.4408655,18.2551061 C4.46541191,18.1814669 4.50676633,18.114554 4.56165376,18.0596666 L7.21292558,15.4083948 C7.8216461,14.7996742 8.75158901,14.6487653 9.52156634,15.0337539 L9.77132688,15.1586342 C10.5413042,15.5436229 11.4712471,15.3927139 12.0799676,14.7839934 Z"
            id="Path-76"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
};

IconPhone.defaultProps = {
  fill: '#000000',
};

const IconChart: React.FC<IconProps> = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0142 16.8629H15.0001C15.5485 16.8629 15.993 16.3712 15.993 15.7645V5.87895C15.993 5.27227 15.5485 4.78056 15.0001 4.78056H13.0142C12.4658 4.78056 12.0213 5.27227 12.0213 5.87895V15.7645C12.0213 16.3712 12.4658 16.8629 13.0142 16.8629ZM7.38761 16.8629H9.37347C9.9219 16.8629 10.3664 16.3712 10.3664 15.7645V1.48536C10.3664 0.878679 9.9219 0.386963 9.37347 0.386963H7.38761C6.83918 0.386963 6.39468 0.878679 6.39468 1.48536V15.7645C6.39468 16.3712 6.83918 16.8629 7.38761 16.8629ZM1.761 16.8629H3.74686C4.29529 16.8629 4.73979 16.3712 4.73979 15.7645V11.3709C4.73979 10.7646 4.29529 10.2725 3.74686 10.2725H1.761C1.21257 10.2725 0.768066 10.7646 0.768066 11.3709V15.7645C0.768066 16.3712 1.21257 16.8629 1.761 16.8629Z"
      fill="#FFF"
    />
  </svg>
);

const IconNotification: React.FC<IconProps> = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <desc>Created with sketchtool.</desc>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Stockholm-icons-/-General-/-Notification#2">
          <rect id="bound" x="0" y="0" width="24" height="24" />
          <path
            d="M13,5.5 C13,8.53756612 15.4624339,11 18.5,11 C19.0200759,11 19.5232921,10.9278151 20.0001762,10.7929177 L20,17 C20,18.6568542 18.6568542,20 17,20 L7,20 C5.34314575,20 4,18.6568542 4,17 L4,7 C4,5.34314575 5.34314575,4 7,4 L13.2068013,4.00081772 C13.0720847,4.47740703 13,4.98028537 13,5.5 Z"
            id="icon-notification"
            fill="#0089be"
          />
          <circle id="Oval" fill="#4dacd1" cx="18.5" cy="5.5" r="2.5" />
        </g>
      </g>
    </svg>
  );
};

const IconAlarmClock: React.FC<IconProps> = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <desc>Created with sketchtool.</desc>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Stockholm-icons-/-Home-/-Alarm-clock">
          <rect id="bound" x="0" y="0" width="24" height="24" />
          <path
            d="M7.14359305,19.3578269 C7.67151586,19.7616911 8.25121217,20.1012903 8.87099797,20.3649403 L7.89205065,22.0604779 C7.61590828,22.5387706 7.00431787,22.7026457 6.52602525,22.4265033 C6.04773263,22.150361 5.88385747,21.5387706 6.15999985,21.0604779 Z M16.8643339,19.3517549 L17.8320512,21.0301278 C18.1081936,21.5084204 17.9443184,22.1200108 17.4660258,22.3961532 C17.0218969,22.6525711 16.4628327,22.5295862 16.1641373,22.1277608 L16.1000004,22.0301278 L15.1370175,20.3615254 L15.1959927,20.3360797 C15.5374409,20.1871181 15.8664774,20.0150168 16.1811207,19.8217571 C16.1975891,19.8116416 16.2139101,19.8015355 16.2301919,19.7913724 C16.329648,19.7292934 16.4276935,19.6650548 16.5242116,19.5987536 C16.5416259,19.5867888 16.5583717,19.5751891 16.5750709,19.5635273 L16.6871441,19.4838237 L16.6871441,19.4838237 L16.8643339,19.3517549 L16.8643339,19.3517549 Z"
            id="clock-combined"
            fill="#bac3cc"
          />
          <path
            d="M12,5 C16.418278,5 20,8.581722 20,13 C20,17.418278 16.418278,21 12,21 C7.581722,21 4,17.418278 4,13 C4,8.581722 7.581722,5 12,5 Z M19.068812,3.25407593 L20.8181344,5.00339833 C21.4039208,5.58918477 21.4039208,6.53893224 20.8181344,7.12471868 C20.2323479,7.71050512 19.2826005,7.71050512 18.696814,7.12471868 L16.9474916,5.37539627 C16.3617052,4.78960984 16.3617052,3.83986237 16.9474916,3.25407593 C17.5332781,2.66828949 18.4830255,2.66828949 19.068812,3.25407593 Z M5.29862906,2.88207799 C5.8844155,2.29629155 6.83416297,2.29629155 7.41994941,2.88207799 C8.00573585,3.46786443 8.00573585,4.4176119 7.41994941,5.00339833 L7.41994941,5.00339833 L5.29862906,7.12471868 C4.71284263,7.71050512 3.76309516,7.71050512 3.17730872,7.12471868 C2.59152228,6.53893224 2.59152228,5.58918477 3.17730872,5.00339833 L3.17730872,5.00339833 Z"
            id="clock"
            fill="#bac3cc"
          />
          <path
            d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z"
            id="clock-needle"
            fill="#364862"
          />
        </g>
      </g>
    </svg>
  );
};

const IconLike: React.FC<IconProps> = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>8AAC51E3-62BB-44B6-8614-2C29A227090C</title>
      <desc>Created with sketchtool.</desc>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Stockholm-icons-/-General-/-Like">
          <rect id="bound" x="0" y="0" width="24" height="24" />
          <path
            d="M9,10 L9,19 L10.1525987,19.3841996 C11.3761964,19.7920655 12.6575468,20 13.9473319,20 L17.5405883,20 C18.9706314,20 20.2018758,18.990621 20.4823303,17.5883484 L21.231529,13.8423552 C21.5564648,12.217676 20.5028146,10.6372006 18.8781353,10.3122648 C18.6189212,10.260422 18.353992,10.2430672 18.0902299,10.2606513 L14.5,10.5 L14.5,10.5 L14.8641964,6.49383981 C14.9326895,5.74041495 14.3774427,5.07411874 13.6240179,5.00562558 C13.5827848,5.00187712 13.5414031,5 13.5,5 C12.5694044,5 11.7070439,5.48826024 11.2282564,6.28623939 L9,10 L9,10 Z"
            id="like-hand"
            fill="#364862"
          />
          <rect id="like-rectangle" fill="#bac3cc" x="2" y="9" width="5" height="11" rx="1" />
        </g>
      </g>
    </svg>
  );
};

const IconSun: React.FC<IconProps> = ({ color }) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <desc>Created with sketchtool.</desc>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="option/reminder"
          transform="translate(-24.000000, -81.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <g id="Group-9-Copy-4">
            <g id="Group-2">
              <g id="Group-5" transform="translate(0.000000, 81.000000)">
                <g id="sun_ico" transform="translate(24.000000, 0.000000)">
                  <path
                    d="M12.1904762,5.57142857 C8.54111015,5.57142857 5.57142857,8.54111015 5.57142857,12.1904762 C5.57142857,15.8398422 8.54111015,18.8095238 12.1904762,18.8095238 C15.8398422,18.8095238 18.8095238,15.8398422 18.8095238,12.1904762 C18.8095238,8.54111015 15.8398422,5.57142857 12.1904762,5.57142857 Z"
                    id="Shape"
                    stroke={color}
                    strokeWidth="2"
                    fillOpacity="0.32"
                  />
                  <path
                    d="M12.1904762,3.55555556 C12.4708571,3.55555556 12.6984127,3.328 12.6984127,3.04761905 L12.6984127,1.01587302 C12.6984127,0.735492063 12.4708571,0.507936508 12.1904762,0.507936508 C11.9100952,0.507936508 11.6825397,0.735492063 11.6825397,1.01587302 L11.6825397,3.04761905 C11.6825397,3.328 11.9100952,3.55555556 12.1904762,3.55555556 Z"
                    id="Shape"
                  />
                  <path
                    d="M12.1904762,20.8253968 C11.9100952,20.8253968 11.6825397,21.0529524 11.6825397,21.3333333 L11.6825397,23.3650794 C11.6825397,23.6454603 11.9100952,23.8730159 12.1904762,23.8730159 C12.4708571,23.8730159 12.6984127,23.6454603 12.6984127,23.3650794 L12.6984127,21.3333333 C12.6984127,21.0529524 12.4708571,20.8253968 12.1904762,20.8253968 Z"
                    id="Shape"
                  />
                  <path
                    d="M7.1791746,4.52673016 C7.27365079,4.68926984 7.44431746,4.78069841 7.61904762,4.78069841 C7.70539683,4.78069841 7.7927619,4.75936508 7.87301587,4.71263492 C8.11580952,4.57142857 8.19911111,4.2615873 8.05892063,4.01879365 L7.04304762,2.25930159 C6.90184127,2.01549206 6.59098413,1.93219048 6.34920635,2.07339683 C6.1064127,2.2135873 6.02311111,2.52342857 6.16330159,2.7672381 L7.1791746,4.52673016 Z"
                    id="Shape"
                  />
                  <path
                    d="M17.2017778,19.8542222 C17.0605714,19.6104127 16.7497143,19.5271111 16.5079365,19.6683175 C16.2651429,19.8085079 16.1818413,20.1193651 16.3220317,20.3621587 L17.3379048,22.1216508 C17.432381,22.2852063 17.6030476,22.375619 17.7787937,22.375619 C17.8651429,22.375619 17.9525079,22.3542857 18.0327619,22.3075556 C18.2755556,22.1673651 18.3588571,21.8565079 18.2186667,21.6137143 L17.2017778,19.8542222 Z"
                    id="Shape"
                  />
                  <path
                    d="M2.25930159,7.04304762 L4.01879365,8.05892063 C4.09803175,8.10565079 4.18539683,8.12698413 4.27174603,8.12698413 C4.44749206,8.12698413 4.61815873,8.03657143 4.71263492,7.87301587 C4.8528254,7.63022222 4.76952381,7.31936508 4.52673016,7.1791746 L2.7672381,6.16330159 C2.52444444,6.02311111 2.2135873,6.10539683 2.07339683,6.34920635 C1.93219048,6.592 2.01549206,6.90285714 2.25930159,7.04304762 Z"
                    id="Shape"
                  />
                  <path
                    d="M22.1216508,17.3379048 L20.3621587,16.3220317 C20.1193651,16.1818413 19.8085079,16.264127 19.6683175,16.5079365 C19.528127,16.7507302 19.6114286,17.0615873 19.8542222,17.2017778 L21.6137143,18.2176508 C21.6939683,18.264381 21.7813333,18.2857143 21.8676825,18.2857143 C22.0434286,18.2857143 22.2140952,18.1953016 22.3085714,18.031746 C22.4487619,17.7889524 22.3654603,17.4780952 22.1216508,17.3379048 Z"
                    id="Shape"
                  />
                  <path
                    d="M3.55555556,12.1904762 C3.55555556,11.9100952 3.328,11.6825397 3.04761905,11.6825397 L1.01587302,11.6825397 C0.735492063,11.6825397 0.507936508,11.9100952 0.507936508,12.1904762 C0.507936508,12.4708571 0.735492063,12.6984127 1.01587302,12.6984127 L3.04761905,12.6984127 C3.328,12.6984127 3.55555556,12.4708571 3.55555556,12.1904762 Z"
                    id="Shape"
                  />
                  <path
                    d="M23.3650794,11.6825397 L21.3333333,11.6825397 C21.0529524,11.6825397 20.8253968,11.9100952 20.8253968,12.1904762 C20.8253968,12.4708571 21.0529524,12.6984127 21.3333333,12.6984127 L23.3650794,12.6984127 C23.6454603,12.6984127 23.8730159,12.4708571 23.8730159,12.1904762 C23.8730159,11.9100952 23.6454603,11.6825397 23.3650794,11.6825397 Z"
                    id="Shape"
                  />
                  <path
                    d="M4.01879365,16.3220317 L2.25930159,17.3379048 C2.01650794,17.4780952 1.93320635,17.7889524 2.07339683,18.031746 C2.16685714,18.1953016 2.33752381,18.2857143 2.51326984,18.2857143 C2.59961905,18.2857143 2.68698413,18.264381 2.7672381,18.2176508 L4.52673016,17.2017778 C4.76952381,17.0615873 4.8528254,16.7507302 4.71263492,16.5079365 C4.57142857,16.264127 4.25955556,16.1818413 4.01879365,16.3220317 Z"
                    id="Shape"
                  />
                  <path
                    d="M20.1092063,8.12698413 C20.1955556,8.12698413 20.2829206,8.10565079 20.3631746,8.05892063 L22.1226667,7.04304762 C22.3654603,6.90285714 22.4487619,6.592 22.3085714,6.34920635 C22.1673651,6.10539683 21.8565079,6.02311111 21.6147302,6.16330159 L19.8552381,7.1791746 C19.6124444,7.31936508 19.5291429,7.63022222 19.6693333,7.87301587 C19.7627937,8.03657143 19.9334603,8.12698413 20.1092063,8.12698413 Z"
                    id="Shape"
                  />
                  <path
                    d="M7.87301587,19.6683175 C7.63022222,19.5271111 7.31936508,19.6104127 7.1791746,19.8542222 L6.16330159,21.6137143 C6.02311111,21.8565079 6.1064127,22.1673651 6.34920635,22.3075556 C6.42946032,22.3532698 6.5168254,22.375619 6.6031746,22.375619 C6.77892063,22.375619 6.9495873,22.2852063 7.04406349,22.1216508 L8.05993651,20.3621587 C8.19911111,20.1193651 8.11580952,19.8095238 7.87301587,19.6683175 Z"
                    id="Shape"
                  />
                  <path
                    d="M16.5079365,4.71263492 C16.5881905,4.75834921 16.6755556,4.78069841 16.7619048,4.78069841 C16.9376508,4.78069841 17.1083175,4.69028571 17.2027937,4.52673016 L18.2186667,2.7672381 C18.3588571,2.52444444 18.2755556,2.2135873 18.0327619,2.07339683 C17.7899683,1.93219048 17.4791111,2.01549206 17.3389206,2.25930159 L16.3230476,4.01879365 C16.1818413,4.2615873 16.2651429,4.57142857 16.5079365,4.71263492 Z"
                    id="Shape"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconSun.defaultProps = {
  color: '#1967AE',
};

const IconRainBow: React.FC<IconProps> = ({ color }) => {
  return (
    <svg
      width="26px"
      height="13px"
      viewBox="0 0 26 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <desc>Created with sketchtool.</desc>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="option/reminder"
          transform="translate(-23.000000, -144.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <g id="Group-9-Copy-4">
            <g id="Group-2">
              <g id="Group-5-Copy" transform="translate(0.000000, 142.000000)">
                <g id="rainbow_ico" transform="translate(24.000000, 3.000000)">
                  <g id="icons">
                    <path
                      d="M12,0 C5.383,0 0,4.80625 0,10.7142857 C0,10.9607143 0.224,11.1607143 0.5,11.1607143 C0.776,11.1607143 1,10.9607143 1,10.7142857 C1,5.29910714 5.935,0.892857143 12,0.892857143 C18.065,0.892857143 23,5.29910714 23,10.7142857 C23,10.9607143 23.224,11.1607143 23.5,11.1607143 C23.776,11.1607143 24,10.9607143 24,10.7142857 C24,4.80625 18.617,0 12,0 Z"
                      id="Shape"
                      stroke={color}
                    />
                    <path
                      d="M20.5,11.1607143 C20.776,11.1607143 21,10.9607143 21,10.7142857 C21,6.28303571 16.962,2.67857143 12,2.67857143 C7.038,2.67857143 3,6.28303571 3,10.7142857 C3,10.9607143 3.224,11.1607143 3.5,11.1607143 C3.776,11.1607143 4,10.9607143 4,10.7142857 C4,6.77589286 7.589,3.57142857 12,3.57142857 C16.411,3.57142857 20,6.77589286 20,10.7142857 C20,10.9607143 20.224,11.1607143 20.5,11.1607143 Z"
                      id="Shape"
                    />
                    <path
                      d="M17,10.7142857 C17,10.9607143 17.224,11.1607143 17.5,11.1607143 C17.776,11.1607143 18,10.9607143 18,10.7142857 C18,7.75982143 15.309,5.35714286 12,5.35714286 C8.691,5.35714286 6,7.75982143 6,10.7142857 C6,10.9607143 6.224,11.1607143 6.5,11.1607143 C6.776,11.1607143 7,10.9607143 7,10.7142857 C7,8.25267857 9.243,6.25 12,6.25 C14.757,6.25 17,8.25267857 17,10.7142857 Z"
                      id="Shape"
                      stroke="#0066B5"
                      strokeWidth="0.5"
                    />
                    <path
                      d="M14.5,11.1607143 C14.776,11.1607143 15,10.9607143 15,10.7142857 C15,9.2375 13.654,8.03571429 12,8.03571429 C10.346,8.03571429 9,9.2375 9,10.7142857 C9,10.9607143 9.224,11.1607143 9.5,11.1607143 C9.776,11.1607143 10,10.9607143 10,10.7142857 C10,9.72946429 10.897,8.92857143 12,8.92857143 C13.103,8.92857143 14,9.72946429 14,10.7142857 C14,10.9607143 14.224,11.1607143 14.5,11.1607143 Z"
                      id="Shape"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconRainBow.defaultProps = {
  color: '#1967AE',
};

const IconClouds: React.FC<IconProps> = ({ color }) => {
  return (
    <svg
      width="24px"
      height="20px"
      viewBox="0 0 24 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <desc>Created with sketchtool.</desc>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="option/reminder"
          transform="translate(-24.000000, -199.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <g id="Group-9-Copy-4">
            <g id="Group-2">
              <g id="Group-5-Copy-2" transform="translate(0.000000, 199.000000)">
                <g id="clouds_ico" transform="translate(24.000000, 0.000000)">
                  <path
                    d="M14.0606061,1.97530864 C14.3282424,1.97530864 14.5454545,1.75407407 14.5454545,1.48148148 L14.5454545,0.49382716 C14.5454545,0.221234568 14.3282424,0 14.0606061,0 C13.7929697,0 13.5757576,0.221234568 13.5757576,0.49382716 L13.5757576,1.48148148 C13.5757576,1.75407407 13.7929697,1.97530864 14.0606061,1.97530864 Z"
                    id="Shape"
                  />
                  <path
                    d="M8.23272727,4.14518519 C8.32775758,4.24197531 8.45187879,4.28938272 8.576,4.28938272 C8.70012121,4.28938272 8.82424242,4.24098765 8.91927273,4.14518519 C9.10836364,3.95259259 9.10836364,3.63950617 8.91927273,3.44691358 L8.23272727,2.74864198 C8.04363636,2.55604938 7.73624242,2.55604938 7.54715152,2.74864198 C7.35806061,2.94123457 7.35806061,3.25432099 7.54715152,3.44691358 L8.23272727,4.14518519 Z"
                    id="Shape"
                  />
                  <path
                    d="M21.8181818,9.87654321 L22.7878788,9.87654321 C23.0555152,9.87654321 23.2727273,9.65530864 23.2727273,9.38271605 C23.2727273,9.11012346 23.0555152,8.88888889 22.7878788,8.88888889 L21.8181818,8.88888889 C21.5505455,8.88888889 21.3333333,9.11012346 21.3333333,9.38271605 C21.3333333,9.65530864 21.5505455,9.87654321 21.8181818,9.87654321 Z"
                    id="Shape"
                  />
                  <path
                    d="M19.5461818,4.28938272 C19.670303,4.28938272 19.7944242,4.24098765 19.8894545,4.14518519 L20.5750303,3.44691358 C20.7641212,3.25432099 20.7641212,2.94123457 20.5750303,2.74864198 C20.3859394,2.55604938 20.0785455,2.55604938 19.8894545,2.74864198 L19.2029091,3.44691358 C19.0138182,3.63950617 19.0138182,3.95259259 19.2029091,4.14518519 C19.2979394,4.24098765 19.4220606,4.28938272 19.5461818,4.28938272 Z"
                    id="Shape"
                  />
                  <path
                    d="M12.278303,4.2291358 C14.2380606,4.75160494 15.9330909,5.98518519 17.0501818,7.70074074 C17.1481212,7.84987654 17.3178182,7.92987654 17.4933333,7.92 C18.153697,7.86469136 18.816,7.9308642 19.4996364,8.10864198 C19.5393939,8.11950617 19.5801212,8.12444444 19.6198788,8.12444444 L19.6295758,8.12444444 C19.9098182,8.14320988 20.1241212,7.90716049 20.1241212,7.63061728 C20.1241212,7.52888889 20.0940606,7.43506173 20.0426667,7.35703704 C19.1854545,4.72296296 16.7970909,2.96296296 14.0606061,2.96296296 C13.5127273,2.96296296 12.8698182,3.07654321 12.2511515,3.28098765 C12.0465455,3.3491358 11.9098182,3.54666667 11.9166061,3.76493827 C11.9233939,3.98419753 12.0707879,4.17283951 12.278303,4.2291358 Z"
                    id="Shape"
                  />
                  <path
                    d="M17.9393939,9.88888889 C17.6270402,9.88888889 17.3028384,9.93261828 16.9510455,10.0238092 L16.1590015,10.2291209 L15.80095,9.49339954 C14.739646,7.31263993 12.5699681,5.9382716 10.1818182,5.9382716 C7.13722694,5.9382716 4.51887449,8.19359866 4.00487092,11.2559034 L3.90488097,11.8516185 L3.33106431,12.0403218 C1.94174633,12.497208 1,13.8078984 1,15.308642 C1,17.210793 2.5142517,18.7530864 4.36363636,18.7530864 L17.9393939,18.7530864 C20.3240513,18.7530864 22.2727273,16.7683239 22.2727273,14.3209877 C22.2727273,11.8736514 20.3240513,9.88888889 17.9393939,9.88888889 Z"
                    id="Shape"
                    stroke={color}
                    strokeWidth="2"
                    fillOpacity="0.32"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconClouds.defaultProps = {
  color: '#1967AE',
};

const IconMoon: React.FC<IconProps> = ({ color }) => {
  return (
    <svg
      width="26px"
      height="26px"
      viewBox="0 0 26 26"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <desc>Created with sketchtool.</desc>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        fillOpacity="0.32"
      >
        <g
          id="option/reminder"
          transform="translate(-26.000000, -254.000000)"
          fill={color}
          fillRule="nonzero"
          stroke={color}
          strokeWidth="2"
        >
          <g id="Group-9-Copy-4">
            <g id="Group-2">
              <g id="Group-5-Copy-3" transform="translate(22.000000, 254.000000)">
                <g id="moon_ico">
                  <path
                    d="M17.2990691,15 C17.2990691,10.7793103 19.2796593,6.88965517 22.5278273,4.48965517 C22.6862745,4.40689655 22.6862745,4.24137931 22.6862745,4.07586207 C22.6862745,3.91034483 22.6070509,3.82758621 22.4486037,3.74482759 C21.1810259,3.24827586 19.8342246,3 18.4874233,3 C12.1495346,3 7,8.37931034 7,15 C7,21.6206897 12.1495346,27 18.4874233,27 C19.8342246,27 21.1810259,26.7517241 22.4486037,26.2551724 C22.6070509,26.1724138 22.6862745,26.0896552 22.6862745,25.9241379 C22.6862745,25.7586207 22.6070509,25.5931034 22.5278273,25.5103448 C19.2796593,23.1103448 17.2990691,19.2206897 17.2990691,15 Z"
                    id="Shape"
                    transform="translate(14.843137, 15.000000) rotate(-45.000000) translate(-14.843137, -15.000000) "
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconMoon.defaultProps = {
  color: '#1967AE',
};

const IconImport: React.FC<IconProps> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="43" viewBox="0 0 50 43">
    <path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z" />
  </svg>
);

const IconSearch: React.FC<IconProps> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path
      fill="#8891A5"
      fillRule="nonzero"
      d="M12.7 11.23a6.777 6.777 0 0 0 1.4-4.174C14.1 3.18 11 0 7.1 0S0 3.18 0 7.056s3.2 7.056 7.1 7.056c1.6 0 3.1-.497 4.2-1.392l3 2.982c.2.199.5.298.7.298.2 0 .5-.1.7-.298.4-.398.4-.994 0-1.391l-3-3.081zm-5.6.795c-2.8 0-5.1-2.186-5.1-4.97 0-2.782 2.3-5.067 5.1-5.067s5.1 2.285 5.1 5.068c0 2.783-2.3 4.969-5.1 4.969z"
    />
  </svg>
);

const IconFaceBook: React.FC<IconProps> = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fab"
    data-icon="facebook-f"
    className="icon-face"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 320 512"
  >
    <path
      fill="currentColor"
      d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
    />
  </svg>
);

const IconLinkedin: React.FC<IconProps> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className="icon-linked"
  >
    <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
  </svg>
);

const IconLocation: React.FC<IconProps> = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          d="M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173.004,321.035
			c6.636,7.391,18.222,7.378,24.846,0c7.065-7.868,173.004-194.147,173.004-321.035C441.425,83.182,358.244,0,256,0z M256,278.719
			c-51.442,0-93.292-41.851-93.292-93.293S204.559,92.134,256,92.134s93.291,41.851,93.291,93.293S307.441,278.719,256,278.719z"
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);

const IconArrowDown: React.FC<IconProps> = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 21.825 21.825"
    xmlSpace="preserve"
  >
    <path
      fill="#c2c8cf"
      d="M16.791,13.254c0.444-0.444,1.143-0.444,1.587,0c0.429,0.444,0.429,1.143,0,1.587l-6.65,6.651
      c-0.206,0.206-0.492,0.333-0.809,0.333c-0.317,0-0.603-0.127-0.81-0.333l-6.65-6.651c-0.444-0.444-0.444-1.143,0-1.587
      s1.143-0.444,1.587,0l4.746,4.762V1.111C9.791,0.492,10.299,0,10.918,0c0.619,0,1.111,0.492,1.111,1.111v16.904L16.791,13.254z"
    />
  </svg>
);

const IconThreeDots: React.FC<IconProps> = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <g>
          <circle fill="#c2c8cf" cx="256" cy="256" r="64" />
          <circle fill="#c2c8cf" cx="256" cy="448" r="64" />
          <circle fill="#c2c8cf" cx="256" cy="64" r="64" />
        </g>
      </g>
    </g>
  </svg>
);

const IconPencil: React.FC<IconProps> = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 528.899 528.899"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M328.883,89.125l107.59,107.589l-272.34,272.34L56.604,361.465L328.883,89.125z M518.113,63.177l-47.981-47.981
   c-18.543-18.543-48.653-18.543-67.259,0l-45.961,45.961l107.59,107.59l53.611-53.611
   C532.495,100.753,532.495,77.559,518.113,63.177z M0.3,512.69c-1.958,8.812,5.998,16.708,14.811,14.565l119.891-29.069
   L27.473,390.597L0.3,512.69z"
      />
    </g>
  </svg>
);

const IconTrash: React.FC<IconProps> = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 486.4 486.4"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          d="M446,70H344.8V53.5c0-29.5-24-53.5-53.5-53.5h-96.2c-29.5,0-53.5,24-53.5,53.5V70H40.4c-7.5,0-13.5,6-13.5,13.5
     S32.9,97,40.4,97h24.4v317.2c0,39.8,32.4,72.2,72.2,72.2h212.4c39.8,0,72.2-32.4,72.2-72.2V97H446c7.5,0,13.5-6,13.5-13.5
     S453.5,70,446,70z M168.6,53.5c0-14.6,11.9-26.5,26.5-26.5h96.2c14.6,0,26.5,11.9,26.5,26.5V70H168.6V53.5z M394.6,414.2
     c0,24.9-20.3,45.2-45.2,45.2H137c-24.9,0-45.2-20.3-45.2-45.2V97h302.9v317.2H394.6z"
        />
        <path
          d="M243.2,411c7.5,0,13.5-6,13.5-13.5V158.9c0-7.5-6-13.5-13.5-13.5s-13.5,6-13.5,13.5v238.5
     C229.7,404.9,235.7,411,243.2,411z"
        />
        <path
          d="M155.1,396.1c7.5,0,13.5-6,13.5-13.5V173.7c0-7.5-6-13.5-13.5-13.5s-13.5,6-13.5,13.5v208.9
     C141.6,390.1,147.7,396.1,155.1,396.1z"
        />
        <path
          d="M331.3,396.1c7.5,0,13.5-6,13.5-13.5V173.7c0-7.5-6-13.5-13.5-13.5s-13.5,6-13.5,13.5v208.9
     C317.8,390.1,323.8,396.1,331.3,396.1z"
        />
      </g>
    </g>
  </svg>
);

const IconCheckList: React.FC<IconProps> = () => (
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#1967ae"
      d="M8 21c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm0-12c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm0 24.33c-1.47 0-2.67 1.19-2.67 2.67s1.2 2.67 2.67 2.67 2.67-1.19 2.67-2.67-1.2-2.67-2.67-2.67zm6 4.67h28v-4h-28v4zm0-12h28v-4h-28v4zm0-16v4h28v-4h-28z"
    />
    <path d="M0 0h48v48h-48z" fill="none" />
  </svg>
);

const IconEarth: React.FC<IconProps> = ({ fill, stroke, className }) => (
  <svg className={className} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={fill}
      stroke={stroke}
      d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 30c-1.967 0-3.84-0.407-5.538-1.139l7.286-8.197c0.163-0.183 0.253-0.419 0.253-0.664v-3c0-0.552-0.448-1-1-1-3.531 0-7.256-3.671-7.293-3.707-0.188-0.188-0.442-0.293-0.707-0.293h-4c-0.552 0-1 0.448-1 1v6c0 0.379 0.214 0.725 0.553 0.894l3.447 1.724v5.871c-3.627-2.53-6-6.732-6-11.489 0-2.147 0.484-4.181 1.348-6h3.652c0.265 0 0.52-0.105 0.707-0.293l4-4c0.188-0.188 0.293-0.442 0.293-0.707v-2.419c1.268-0.377 2.61-0.581 4-0.581 2.2 0 4.281 0.508 6.134 1.412-0.13 0.109-0.256 0.224-0.376 0.345-1.133 1.133-1.757 2.64-1.757 4.243s0.624 3.109 1.757 4.243c1.139 1.139 2.663 1.758 4.239 1.758 0.099 0 0.198-0.002 0.297-0.007 0.432 1.619 1.211 5.833-0.263 11.635-0.014 0.055-0.022 0.109-0.026 0.163-2.541 2.596-6.084 4.208-10.004 4.208z"
    />
  </svg>
);

export {
  IconStart,
  IconBell,
  IconCompany,
  IconMail,
  IconPhone,
  IconChart,
  IconNotification,
  IconAlarmClock,
  IconLike,
  IconSun,
  IconRainBow,
  IconClouds,
  IconMoon,
  IconImport,
  IconSearch,
  IconFaceBook,
  IconLinkedin,
  IconLocation,
  IconArrowDown,
  IconThreeDots,
  IconPencil,
  IconTrash,
  IconCheckList,
  IconEarth,
};
