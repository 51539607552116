/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import {
  Area,
  ResponsiveContainer,
  XAxis,
  YAxis,
  AxisDomain,
  Tooltip,
  TooltipProps,
  Line,
  ComposedChart,
  Label,
} from 'recharts';
import moment from 'moment';
// models
import { SocialMediaChartInterval } from '@optx/models/charts/data';
// utils
// components
import EmptyChart from '@optx/components/feature/charts/EmptyChart';
import LineChartTooltip from './LineChartTooltip';
import { Styles as ChartStyles } from './LineChart.style';
import { Styles } from './DoubleLineChart.styled';
import ChartHeader from './ChartHeader';

interface DoubleLineChartProps {
  data: SocialMediaChartInterval;
  dataKey: string;
  secondaryDataKey: string;
  labelKey?: string;
  labelKeyAlt?: string;
  checkLabelValue?: string;
  headerValue: number;
  headerValueExtra?: number;
  headerLabel: string;
  headerLabelExtra?: string;
  title: string;
  icon: React.ReactNode;
  kpiLabel: string;
  chartInterval: string;
  stars: number;
  range?: [AxisDomain, AxisDomain];
  chartLabel?: string;
  className?: string;
}

const DoubleLineChart: React.FC<DoubleLineChartProps> = ({
  data,
  dataKey,
  secondaryDataKey,
  labelKey,
  labelKeyAlt,
  icon,
  title,
  headerValue,
  headerLabel,
  headerLabelExtra,
  headerValueExtra,
  chartLabel,
  range,
  stars,
}) => {
  return (
    <Styles.DoubleChartWrapper
      className="profile-chart-card extra"
      title={
        <ChartHeader
          stars={stars}
          icon={icon}
          title={title}
          headerValue={headerValue}
          headerLabel={headerLabel}
          headerValueExtra={headerValueExtra}
          headerLabelExtra={headerLabelExtra}
        />
      }
    >
      {Object.keys(data.data).length ? (
        <ChartStyles.ChartLabel>{chartLabel}</ChartStyles.ChartLabel>
      ) : (
        ''
      )}
      <div className="profile-chart-card__container">
        {!Object.keys(data.data).length && (
          <EmptyChart className="chart-empty-labels" text="No History" width="calc(100% - 141px)" />
        )}
        <ResponsiveContainer>
          <ComposedChart data={data.data} margin={{ left: 24, top: 25 }}>
            <defs>
              <linearGradient id="blueGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#C2E6F8" stopOpacity="1" />
                <stop offset="95%" stopColor="#e6f7ff" stopOpacity="0" />
              </linearGradient>
            </defs>
            <XAxis
              dataKey={labelKey}
              tickLine={false}
              stroke="#C2CFE0"
              tickSize={15}
              tickFormatter={val => (labelKey === 'date' ? moment(val).format('MMM YY') : val)}
            />
            <YAxis
              orientation="right"
              tickCount={2}
              tickMargin={5}
              tickLine={false}
              axisLine
              stroke="#C2CFE0"
              type="number"
            >
              {Object.keys(data.data).length && (
                <Label
                  value="# OF REVIEWERS"
                  color="#188fffa7"
                  position="outside"
                  angle={-90}
                  className="axis-label"
                  // x-axis position
                  dx={25}
                />
              )}
            </YAxis>

            {Object.keys(data.data).length && (
              <YAxis
                dataKey={secondaryDataKey}
                yAxisId="rating"
                axisLine
                stroke="#C2CFE0"
                tickLine={false}
                orientation="left"
                tickCount={2}
                tickMargin={10}
                type="number"
              >
                <Label
                  value="RATING"
                  color="#722ed1b7"
                  position="outside"
                  angle={-90}
                  className="axis-label"
                  dx={-10}
                />
              </YAxis>
            )}
            <Tooltip
              content={(props: TooltipProps) => (
                <LineChartTooltip
                  primaryKey={dataKey}
                  primaryLabel="Score Value:"
                  secondaryKey={secondaryDataKey}
                  secondaryLabel="Rating"
                  {...props}
                />
              )}
              cursor={false}
            />

            <Area
              type="monotone"
              dataKey={dataKey}
              stroke="#1890FF"
              strokeWidth="2px"
              fillOpacity={1}
              fill="url(#blueGradient)"
              dot={{ fill: '#fff', strokeWidth: 1, r: 4 }}
            />
            <Line
              yAxisId="rating"
              type="monotone"
              dataKey={secondaryDataKey}
              stroke="#722ED1"
              strokeDasharray="3 5"
              strokeWidth="2px"
              dot={{ strokeDasharray: '0', strokeWidth: 1, r: 4 }}
              connectNulls
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </Styles.DoubleChartWrapper>
  );
};

export default DoubleLineChart;
