import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, SubmitButton } from 'formik-antd';
import { Col, Row, Button, PageHeader, Descriptions } from 'antd';
import { Formik, FormikProps } from 'formik';
import { CloseCircleFilled } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import FixedLoader from '@optx/components/common/loader';
import { selectors } from '../../redux/lists/details';

interface CsvUploadProps {
  errors?: boolean;
  close: () => void;
  onSubmit: (title: string, dataImport: Array<{}>) => void;
}

interface CompanyImport {
  companyName: Array<string>;
  companyURL: Array<string>;
}

const message = {
  required: 'Field is required!',
};

const validationSchema = yup.object<CompanyImport>({
  companyName: yup.array().of(yup.string().required(message.required)),
  companyURL: yup.array().of(yup.string().required(message.required)),
});

const CsvUpload: React.FC<CsvUploadProps> = ({ errors, close, onSubmit }) => {
  const formRef = useRef<FormikProps<CompanyImport> | null>(null);

  const companiesList = useSelector(selectors.getCSVImportCompanies);
  const companiesListTitle = companiesList?.title;

  const [companiesListTable, setCompaniesListTable] = useState(
    companiesList?.data.failed_company_data || []
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (companiesListTable.length > 0) {
      setLoading(false);
    }
  }, [companiesListTable]);

  useEffect(() => {
    setCompaniesListTable(companiesList?.data.failed_company_data || []);
  }, [errors, companiesList]);

  const initialValues: CompanyImport = {
    companyName: companiesListTable.map(company => ''),
    companyURL: companiesListTable.map(company => ''),
  };

  // Submit the form
  const handleSubmit = (values: CompanyImport) => {
    setLoading(true);

    // @ts-ignore
    if (validationSchema.isValid(values)) {
      const fixedCompanies = [];
      const companiesTotal = values.companyName.length;

      for (let i = 0; i < companiesTotal; i++) {
        fixedCompanies.push({
          Company: values.companyName[i],
          URL: values.companyURL[i],
          Website: values.companyURL[i],
        });
      }

      onSubmit(companiesListTitle || '', fixedCompanies);
      close();
    }

    setLoading(false);

    return validationSchema.isValid(values);
  };

  // Remove the company that should not be imported
  const updateCompaniesList = (index: number) => {
    setCompaniesListTable(companiesListTable =>
      companiesListTable.filter((company, companyIndex) => companyIndex !== index)
    );

    initialValues.companyName.splice(index, 1);
    initialValues.companyURL.splice(index, 1);

    if (companiesListTable.length === 0) {
      close();
    }
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      formRef.current?.submitForm();
    }
  };

  return (
    <>
      {loading ? (
        <FixedLoader loading={loading} />
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
          innerRef={formRef}
          validationSchema={validationSchema}
          component={({ dirty, isValid }) => (
            <Form layout="vertical" onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
              <PageHeader
                className="list-import-error__header"
                title={`Fix Upload Errors (${companiesListTable.length})`}
                extra={[
                  <Button key="2" size="large" onClick={close}>
                    Exit and Ignore Errors
                  </Button>,
                  <SubmitButton
                    key="1"
                    type="primary"
                    size="large"
                    disabled={!dirty || !isValid}
                    loading={loading}
                  >
                    Save and Fix Errors
                  </SubmitButton>,
                ]}
              />
              <div className="list-import-error__body">
                <Row>
                  <Col span={24}>
                    <div className="ant-message list-import-error__message">
                      <span>
                        <div className="ant-message-notice">
                          <div className="ant-message-notice-content">
                            <div className="ant-message-custom-content ant-message-error">
                              <CloseCircleFilled />
                              <span>
                                <strong>ERROR</strong>
                                {` ${
                                  companiesListTable.length > 1 ? 'These companies' : 'This company'
                                } cannot be found. Please update name and or URL`}
                              </span>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                  </Col>
                </Row>

                {companiesListTable.map((item, index) => (
                  <div className="list-import-error__companies" key={index}>
                    <Row>
                      <Col span={24} className="list-import-error__companies-button">
                        <Button
                          size="large"
                          onClick={() => {
                            updateCompaniesList(index);
                          }}
                        >
                          Ignore and Cancel Import
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={16}>
                        <Descriptions bordered layout="vertical">
                          <Descriptions.Item label="Company Name">{item.Company}</Descriptions.Item>
                          <Descriptions.Item label="URL">{item.URL}</Descriptions.Item>
                        </Descriptions>
                      </Col>
                      <Col flex="auto" />
                    </Row>
                    <Row>
                      <Col span={16}>
                        <Row>
                          <Col span={11}>
                            <Form.Item
                              label="Company Name"
                              name={`companyName[${index}]`}
                              required
                              hasFeedback
                            >
                              <Input name={`companyName[${index}]`} />
                            </Form.Item>
                          </Col>
                          <Col span={11} offset={2}>
                            <Form.Item
                              label="Company URL"
                              name={`companyURL[${index}]`}
                              required
                              hasFeedback
                            >
                              <Input name={`companyURL[${index}]`} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8} />
                    </Row>
                  </div>
                ))}
              </div>
            </Form>
          )}
        />
      )}
    </>
  );
};

export default CsvUpload;
