export const SEARCH_SOURCE_SCRUB_LISTS = 'SEARCH_SOURCE_SCRUB_LISTS';
export const SEARCH_SOURCE_SCRUB_LISTS_SUCCESS = 'SEARCH_SOURCE_SCRUB_LISTS_SUCCESS';
export const SEARCH_SOURCE_SCRUB_LISTS_FAIL = 'SEARCH_SOURCE_SCRUB_LISTS_FAIL';

export const CHANGE_SOURCE_SCRUB_LISTS_PAGINATION = 'CHANGE_SOURCE_SCRUB_LISTS_PAGINATION';

export const SORT_SOURCE_SCRUB_LISTS = 'SORT_SOURCE_SCRUB_LISTS';

export const FETCH_SOURCE_SCRUB_LISTS_TOTAL = 'FETCH_SOURCE_SCRUB_LISTS_TOTAL';
export const FETCH_SOURCE_SCRUB_LISTS_TOTAL_SUCCESS = 'FETCH_SOURCE_SCRUB_LISTS_TOTAL_SUCCESS';
export const FETCH_SOURCE_SCRUB_LISTS_TOTAL_FAIL = 'FETCH_SOURCE_SCRUB_LISTS_TOTAL_FAIL';
