import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// models
import { OpportunityPresentationState } from './interfaces';
import { TouchesFinancialPayload } from '@models/api/touches';
import { CompanyFundingRounds, OpportunityPresentation } from '@optx/models/Company';
import {
  CustomValue,
  EditFieldSuccess,
  UpdateFieldsPayload,
} from '@optx/features/company/edit-fields/state/interfaces';
import { EditAllCompanyResponse } from '@optx/models/api/editAllInfo';
// redux
import * as actions from './actions';
import { actions as fundingRoundActions } from '@redux/company/funding-rounds';
import { actions as updateCompanyInfoActions } from '@redux/ui/modals/company-touches';
import { fetchReducer, fetchFailReducer, fetchSuccessReducer } from '../../feature/fetch/reducers';
import { actions as editFieldActions } from '@optx/features/company/edit-fields/state';
import { actions as editAllInfoActions } from '@optx/features/edit-all-info/state';

const initialState: OpportunityPresentationState = {
  data: null,
  loading: false,
  editableYear: null,
  isAddYearsOpenModal: false,
  error: '',
  fetchedAt: '',
};

// fetch opportunity
const fetchOpportunityReducer: CaseReducer<OpportunityPresentationState> = draftState => {
  draftState.error = '';
};

const setAddYearsOpenModalReducer: CaseReducer<OpportunityPresentationState> = draftState => {
  draftState.isAddYearsOpenModal = !draftState.isAddYearsOpenModal;

  if (draftState.editableYear) {
    draftState.editableYear = null;
  }
};

const editAddYearsOpenModalReducer: CaseReducer<
  OpportunityPresentationState,
  PayloadAction<{ year: number }>
> = (draftState, { payload }) => {
  draftState.editableYear = payload.year;
  draftState.isAddYearsOpenModal = true;
};

const fetchOpportunitySuccessReducer: CaseReducer<
  OpportunityPresentationState,
  PayloadAction<OpportunityPresentation | null>
> = (draftState, action) => {
  fetchSuccessReducer(draftState, action);
  draftState.fetchedAt = new Date().toISOString();
};

const addFinancialYearReducer: CaseReducer<OpportunityPresentationState> = draftState => {
  draftState.loading = true;
  draftState.error = '';
};

const updateCompanyInfoSuccessReducer: CaseReducer<
  OpportunityPresentationState,
  PayloadAction<Partial<TouchesFinancialPayload>>
> = (draftState, { payload }) => {
  if (draftState.data && draftState.data.company_id === payload.company_id) {
    if (payload.financials && draftState.data.financial) {
      if (payload.financials.revenue) {
        draftState.data.financial.revenue = payload.financials.revenue;
      }

      if (payload.financials.ebitda) {
        draftState.data.financial.ebitda = payload.financials.ebitda;
      }

      if (payload.financials.gm) {
        draftState.data.financial.gm = payload.financials.gm;
      }

      if (payload.financials.annual_recurring_revenue) {
        draftState.data.financial.annual_recurring_revenue =
          payload.financials.annual_recurring_revenue;
      }

      if (payload.financials.revenue_by_country) {
        draftState.data.financial.revenue_by_country = payload.financials.revenue_by_country;
      }

      if (payload.financials.financial_notes) {
        draftState.data.financial.financial_notes = payload.financials.financial_notes;
      }

      if (payload.financials.cashflow) {
        draftState.data.financial.cashflow.value = payload.financials.cashflow;
      }

      if (payload.financials.revenue_model) {
        draftState.data.financial.revenue_model.value = payload.financials.revenue_model;
      }
    }

    if (payload.funding) {
      if (draftState.data.deal_preparation) {
        if (typeof payload.funding.banker === 'boolean') {
          draftState.data.deal_preparation.banker.value = payload.funding.banker;
        }

        draftState.data.deal_preparation.banker_name =
          payload.funding.banker_name && payload.funding.banker_name !== ''
            ? payload.funding.banker_name
            : null;

        if (payload.funding.equity_check) {
          draftState.data.deal_preparation.asking_amount = payload.funding.equity_check;
        }

        if (payload.funding.deal_type) {
          draftState.data.deal_preparation.deal_type.value = payload.funding.deal_type;
        }

        if (payload.funding.transaction_notes) {
          draftState.data.deal_preparation.transaction_notes = payload.funding.transaction_notes;
        }

        if (payload.funding.next_steps) {
          draftState.data.deal_preparation.next_steps.value = payload.funding.next_steps;
        }
      }

      if (draftState.data.funding) {
        if (payload.funding.last_round_date) {
          draftState.data.funding.last_round_date = payload.funding.last_round_date;
        }

        if (payload.funding.cap_table) {
          draftState.data.funding.cap_table = payload.funding.cap_table;
        }

        draftState.data.funding.last_investors = payload.funding.last_investors || null;
      }

      if (payload.customer_kpis && draftState.data.customer_kpis) {
        if (payload.customer_kpis.customers_number) {
          draftState.data.customer_kpis.customers_number = payload.customer_kpis.customers_number;
        }

        if (
          typeof payload.customer_kpis.customer_other_rationale === 'string' &&
          draftState.data.customer_kpis.customer_type.extra_data
        ) {
          draftState.data.customer_kpis.customer_type.extra_data.customer_other_rationale =
            payload.customer_kpis.customer_other_rationale;
        }

        if (payload.customer_kpis.customer_type) {
          draftState.data.customer_kpis.customer_type.value = payload.customer_kpis.customer_type;
        }

        if (payload.customer_kpis.sales_and_marketing) {
          draftState.data.customer_kpis.sales_and_marketing.value =
            payload.customer_kpis.sales_and_marketing;
        }

        if (payload.customer_kpis.sales_cycle) {
          draftState.data.customer_kpis.sales_cycle.value = payload.customer_kpis.sales_cycle;
        }

        if (payload.customer_kpis.acv) {
          draftState.data.customer_kpis.acv = payload.customer_kpis.acv;
        }

        if (payload.customer_kpis.nps) {
          draftState.data.customer_kpis.nps = payload.customer_kpis.nps;
        }

        if (payload.customer_kpis.clt) {
          draftState.data.customer_kpis.clt = payload.customer_kpis.clt;
        }

        if (payload.customer_kpis.gross_retention) {
          draftState.data.customer_kpis.gross_retention = payload.customer_kpis.gross_retention;
        }

        if (payload.customer_kpis.net_retention) {
          draftState.data.customer_kpis.net_retention = payload.customer_kpis.net_retention;
        }

        if (payload.customer_kpis.customer_churn) {
          draftState.data.customer_kpis.customer_churn = payload.customer_kpis.customer_churn;
        }

        if (payload.customer_kpis.customer_concentration) {
          draftState.data.customer_kpis.customer_concentration =
            payload.customer_kpis.customer_concentration;
        }

        if (payload.customer_kpis.cac) {
          draftState.data.customer_kpis.cac = payload.customer_kpis.cac;
        }

        if (payload.customer_kpis.ltv_cac) {
          draftState.data.customer_kpis.ltv_cac = payload.customer_kpis.ltv_cac;
        }
      }

      if (payload.technology && draftState.data.technology) {
        if (payload.technology.delivery_model) {
          draftState.data.technology.delivery_model.value = payload.technology.delivery_model;
        }

        if (payload.technology.cloud_provider) {
          draftState.data.technology.cloud_provider.value = payload.technology.cloud_provider;
        }

        if (payload.technology.architecture) {
          draftState.data.technology.architecture.value = payload.technology.architecture;
        }

        if (payload.technology.technology_notes) {
          draftState.data.technology.technology_notes = payload.technology.technology_notes;
        }
      }
    }

    if (payload.general_info) {
      if (payload.general_info.other_notes && draftState.data.financial) {
        draftState.data.financial.other_notes = payload.general_info.other_notes;
      }
    }
  }
};

const updateFieldsSuccessReducer: CaseReducer<
  OpportunityPresentationState,
  PayloadAction<EditFieldSuccess>
> = (draftState, action) => {
  const { fieldName, companyId, value } = action.payload;

  if (draftState.data && draftState.data.company_id === companyId) {
    const fieldKeyValue = (value as UpdateFieldsPayload).value;
    const extraKeys = (value as UpdateFieldsPayload).extraKeys;
    const extraValues = (value as UpdateFieldsPayload).extraValues;

    if (!Array.isArray(fieldName)) {
      const funding = draftState.data.funding;

      if (fieldName === 'Capital Raised' && funding) {
        funding.capital_raised = fieldKeyValue as number;
      }

      if (fieldName === 'Last Round' && funding) {
        const lastRoundData = (value as UpdateFieldsPayload).lastRoundData;

        if (fieldKeyValue === 0) {
          funding.last_round_amount = 'Unknown';
        } else {
          funding.last_round_amount = fieldKeyValue as number;
        }

        funding.last_round.value = lastRoundData?.last_round as string;
        funding.last_investors = lastRoundData?.last_investors || [];
        funding.last_round_date = lastRoundData?.raise_date as string;

        if (!lastRoundData?.parentcompany || lastRoundData?.parentcompany === 'blank') {
          funding.acquiring_company = '';
        } else {
          funding.acquiring_company = lastRoundData?.parentcompany as string;
        }
      }

      if (fieldName === 'Next Steps' && draftState.data.deal_preparation) {
        draftState.data.deal_preparation.next_steps.value = (fieldKeyValue as CustomValue)
          .value as string;

        if (extraKeys?.[0] === 'next_steps_opinion' && extraValues?.[0] !== undefined) {
          draftState.data.deal_preparation.next_steps_opinion = extraValues?.[0] as string;
        }
      }

      if (fieldName === 'Last Funding Date' && funding) {
        funding.last_round_date = fieldKeyValue as string;
      }

      if (fieldName === 'Investor Name' && funding) {
        funding.last_investors = fieldKeyValue as string[];
      }
    }
  }
};

const companyFundingRoundsSuccessReducer: CaseReducer<
  OpportunityPresentationState,
  PayloadAction<CompanyFundingRounds>
> = (draftState, action) => {
  if (draftState.data) {
    const { total_raised, last_round } = action.payload;

    if (draftState.data.funding) {
      // @ts-ignore
      draftState.data.funding.capital_raised = total_raised;

      if (last_round?.amount === 'Unknown') {
        draftState.data.funding.last_round_amount = last_round?.amount;
      } else if (last_round?.amount) {
        draftState.data.funding.last_round_amount = Number(
          last_round?.amount?.split(',').join('').replace('$', '')
        );
      }

      // for last_round?.round === '(N/A)' Last Round is Unknown
      // for last_round?.round === 'N/A' Last Round is null
      if (last_round?.round === '(N/A)') {
        draftState.data.funding.last_round.value = 'Unknown';
      } else if (last_round?.round && last_round?.round !== 'N/A') {
        draftState.data.funding.last_round.value = last_round?.round;
      }
    }
  }
};

const updateAllCompanyEditAllInfoSuccessReducer: CaseReducer<
  OpportunityPresentationState,
  PayloadAction<Partial<EditAllCompanyResponse>>
> = (draftState, { payload }) => {
  if (draftState.data?.financial) {
    if (payload.annual_recurring_revenue_growth) {
      draftState.data.financial.annual_recurring_revenue_growth =
        payload.annual_recurring_revenue_growth;
    }

    if (payload.revenue_growth) {
      draftState.data.financial.revenue_growth = payload.revenue_growth;
    }

    if (payload.ebitda_margin) {
      draftState.data.financial.ebitda_margin = payload.ebitda_margin;
    }

    if (draftState.data.funding) {
      if (payload.last_raised_amount === 'Unknown') {
        draftState.data.funding.last_round_amount = payload.last_raised_amount;
      }

      if (payload.capital_raised !== undefined) {
        draftState.data.funding.capital_raised = payload.capital_raised;
      }
    }
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    // fetch opportunity presentation
    .addCase(actions.fetchOpportunityPresentation, fetchOpportunityReducer)
    .addCase(actions.fetchOpportunityPresentationSuccess, fetchOpportunitySuccessReducer)
    .addCase(actions.fetchOpportunityPresentationFail, fetchFailReducer)
    .addCase(actions.setAddYearsOpenModal, setAddYearsOpenModalReducer)
    .addCase(actions.editAddYearsOpenModal, editAddYearsOpenModalReducer)
    // update opportunity presentation
    .addCase(actions.updateOpportunityPresentation, fetchReducer)
    .addCase(actions.updateOpportunityPresentationSuccess, fetchOpportunitySuccessReducer)
    .addCase(actions.updateOpportunityPresentationFail, fetchFailReducer)
    // add years
    .addCase(actions.addFinancialYear, addFinancialYearReducer)
    .addCase(actions.addFinancialYearSuccess, fetchOpportunitySuccessReducer)
    .addCase(actions.addFinancialYearFail, fetchFailReducer)
    // edit years
    .addCase(actions.editFinancialYearSuccess, fetchOpportunitySuccessReducer)
    .addCase(actions.editFinancialYearFail, fetchFailReducer)
    // delete years
    .addCase(actions.deleteFinancialYearSuccess, fetchOpportunitySuccessReducer)
    .addCase(actions.deleteFinancialYearFail, fetchFailReducer)
    // update Company Info
    .addCase(
      updateCompanyInfoActions.updateTouchCompanyInfoSuccess,
      updateCompanyInfoSuccessReducer
    )
    .addCase(editAllInfoActions.updateCompanyEditAllInfoSuccess, updateCompanyInfoSuccessReducer)
    .addCase(
      editAllInfoActions.updateAllCompanyEditAllInfoSuccess,
      updateAllCompanyEditAllInfoSuccessReducer
    )
    .addCase(editFieldActions.updateFieldsSuccess, updateFieldsSuccessReducer)
    .addCase(fundingRoundActions.getCompanyFundingRoundsSuccess, companyFundingRoundsSuccessReducer)
);

export default reducer;
