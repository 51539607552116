export const FETCH_USER_MANAGEMENT = 'FETCH_USER_MANAGEMENT';
export const FETCH_USER_MANAGEMENT_SUCCESS = 'FETCH_USER_MANAGEMENT_SUCCESS';
export const FETCH_USER_MANAGEMENT_FAIL = 'FETCH_USER_MANAGEMENT_FAIL';
export const GET_RELOADED_DATA = 'GET_RELOADED_DATA';
export const RESEND_USER_INVITE = 'RESEND_USER_INVITE';
export const RESEND_USER_INVITE_SUCCESS = 'RESEND_USER_INVITE_SUCCESS';
export const RESEND_USER_INVITE_FAIL = 'RESEND_USER_INVITE_FAIL';
export const USER_DISABLE = 'USER_DISABLE';
export const USER_DISABLE_SUCCESS = 'USER_DISABLE_SUCCESS';
export const USER_UNLOCK = 'USER_UNLOCK';
export const USER_UNLOCK_SUCCESS = 'USER_UNLOCK_SUCCESS';
export const USER_UNLOCK_FAIL = 'USER_UNLOCK_FAIL';

export const FETCH_USER_EDIT_INFO = 'FETCH_USER_EDIT_INFO';
export const FETCH_USER_EDIT_INFO_SUCCESS = 'FETCH_USER_EDIT_INFO_SUCCESS';
export const FETCH_USER_EDIT_INFO_FAIL = 'FETCH_USER_EDIT_INFO_FAIL';

export const SUBMIT_USER_INFO = 'SUBMIT_USER_INFO';
export const SUBMIT_USER_INFO_SUCCESS = 'SUBMIT_USER_INFO_SUCCESS';
export const SUBMIT_USER_INFO_FAIL = 'SUBMIT_USER_INFO_FAIL';

export const CHANGE_SORT_SUCCESS = 'CHANGE_SORT_SUCCESS';
