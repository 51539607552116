import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Menu, MenuProps, Typography, Tag } from 'antd';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
// models
import { ViewOption, View } from '@models/search';
// utils
import { normalizeViewsColumns } from '@optx/utils/utils';
// redux
import { selectors as savedSearchesSelectors } from '@redux/company/saved-searches';
import { selectors as searchSelectors } from '@features/grid/search';
import { actions as modalActions } from '@redux/ui/modals/save-search';
import {
  selectors as columnSelectors,
  actions as columnActions,
} from '@redux/ui/modals/display-column';
// redux
import { selectors as userSelectors } from '@redux/user/information/';
// components
import { EllipsisOutlined, DownOutlined } from '@ant-design/icons';
import Styled from './GridView.styles';
import { GridKeys, GridPayload } from '@optx/models/grid';

const { Item } = Menu;
const { Text } = Typography;

interface GridViewProps {
  gridKey: GridKeys;
  activeView: View;
  isFromSourcingOutReach?: boolean;
  applyView: ActionCreatorWithPayload<GridPayload<ViewOption>, string>;
  customWidth?: string;
}

const GridView: React.FC<GridViewProps> = ({
  gridKey,
  activeView,
  applyView,
  customWidth,
  isFromSourcingOutReach,
}) => {
  const dispatch = useDispatch();
  const defaultScoreValue = useSelector(userSelectors.getDefaultScore);

  const { views } = useSelector(savedSearchesSelectors.getViews);
  const isColumnModalOpen = useSelector(columnSelectors.displayDialog);
  const searchName = useSelector(searchSelectors.getSearchName);
  const [key, setKey] = useState(activeView.id);
  const [visible, setVisible] = useState(false);

  const findDefaultView = useMemo(() => views.find(view => view.is_default), [views]);

  const handleItemClick: MenuProps['onClick'] = info => {
    // @ts-ignore
    setKey(info.key);
    setVisible(false);
    const viewData = views.find(view => view.unique_id.toString() === info.key) as ViewOption;

    const { validatedColumns, validatedColumnsOrder } = normalizeViewsColumns(
      viewData,
      defaultScoreValue
    );

    console.log('dispatch action, gridKey: ', gridKey);
    dispatch(
      applyView({
        gridKey: gridKey,
        data: { ...viewData, columns: validatedColumns, column_order: validatedColumnsOrder },
      })
    );
  };

  const handleButton = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    const id = e.currentTarget.id;
    dispatch(modalActions.toggleSaveSearchModal(id));

    if (isColumnModalOpen) {
      dispatch(columnActions.toggleDisplayColumnDialog());
    }

    setVisible(false);
  };

  const handleVisible = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    if (activeView.label && !activeView.id) {
      setKey('default');
    } else {
      setKey(activeView.id);
    }
  }, [activeView, searchName]);

  const menu = (
    <Styled.MenuWrapper customWidth={customWidth} selectedKeys={[key.toString()]}>
      {views.map(view => (
        <Item key={view.unique_id} data-label={view.title} onClick={handleItemClick}>
          <Styled.MenuItem>
            <Text
              style={{
                width: 200,
              }}
              ellipsis
            >
              {view.title}
            </Text>
            {view.is_default && <Tag color="volcano">Default Search</Tag>}
            <EllipsisOutlined id={view.unique_id.toString()} onClick={handleButton} />
          </Styled.MenuItem>
        </Item>
      ))}
    </Styled.MenuWrapper>
  );

  return (
    <Styled.DropdownWrapper
      visible={visible}
      onVisibleChange={handleVisible}
      overlay={menu}
      trigger={['click']}
      placement="bottomLeft"
    >
      <Button>
        <Styled.TriggerWrapper>
          Grid View:
          <Styled.SelectedLabel ellipsis>
            {activeView.label || findDefaultView?.title || 'Select View'}
          </Styled.SelectedLabel>
          <DownOutlined />
        </Styled.TriggerWrapper>
      </Button>
    </Styled.DropdownWrapper>
  );
};

export default React.memo(GridView);
