import { createSelector } from 'reselect';
import { AppState } from '@optx/redux/interfaces';
import { errorSelector } from '@optx/redux/feature/fetch/selectors';

const getCompanyProfileState = (state: AppState) => state.companyProfile;

export const shouldInitialFetch = createSelector(getCompanyProfileState, state => !state.loading);

export const getError = createSelector(getCompanyProfileState, errorSelector);

export const getSelectedDescription = createSelector(
  getCompanyProfileState,
  state => state.data?.selectedDescription
);

export const isLoading = createSelector(getCompanyProfileState, state => state.loading);

export const getProfile = createSelector(getCompanyProfileState, state => state.data);

export const getProfileProductCategory = createSelector(getCompanyProfileState, state =>
  state.data ? state.data.product_category : ''
);

export const getProfileContacts = createSelector(getCompanyProfileState, state =>
  state.data ? state.data.contacts : []
);

export const getProfileOptxScore = createSelector(getCompanyProfileState, state =>
  state.data ? state.data.score : null
);

export const getCompanySources = createSelector(getCompanyProfileState, state =>
  state.data ? state.data.sources : []
);

export const getIsInBusiness = createSelector(
  getCompanyProfileState,
  state => state.data?.is_in_business || false
);

export const getCompanyId = createSelector(getCompanyProfileState, state => state.data?.company_id);
export const getCompanyUrl = createSelector(
  getCompanyProfileState,
  state => state.data?.company_url
);

export const getCompanyName = createSelector(
  getCompanyProfileState,
  state => state.data?.company_name || ''
);
