import React, { useCallback, useMemo } from 'react';
import { Menu, MenuProps } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
// redux
import { selectors as searchSelectors } from '@redux/company/search/search';
import {
  selectors as myCompaniesSortSelectors,
  actions as myCompaniesSortActions,
} from '@redux/my-companies/sort';
import { actions as sortActions } from '@redux/company/search/sort';
import { selectors as viewTypeSelectors } from '@features/grid/view-type';
import { actions as userListSortActions } from '@redux/lists/search/sort';
import { selectors as userListSearchSelectors } from '@redux/lists/search/search';
import { selectors as companyOutReachSearchSelectors } from '@redux/company-outreach/search';
import { actions as companyOutReachSortActions } from '@redux/company-outreach/sort';

interface ToggleSortTypeMenuItemProps extends MenuItemProps {
  gridName: 'advancedSearch' | 'myCompanies' | 'userLists' | 'companyOutReach';
}

const ToggleSortTypeMenuItem = ({
  onClick,
  gridName,
  ...restProps
}: ToggleSortTypeMenuItemProps) => {
  const sortingSelectors = {
    advancedSearch: searchSelectors,
    myCompanies: myCompaniesSortSelectors,
    userLists: userListSearchSelectors,
    companyOutReach: companyOutReachSearchSelectors,
  };

  const sortingActions = useMemo(() => {
    return {
      advancedSearch: sortActions,
      myCompanies: myCompaniesSortActions,
      userLists: userListSortActions,
      companyOutReach: companyOutReachSortActions,
    };
  }, []);

  const searchViewSelectors = {
    advancedSearch: viewTypeSelectors.searchView('advancedSearch'),
    myCompanies: viewTypeSelectors.searchView('myCompanies'),
    userLists: viewTypeSelectors.searchView('lists'),
    companyOutReach: viewTypeSelectors.searchView('outreach'),
  };

  const dispatch = useDispatch();
  const isMultiSort = useSelector(sortingSelectors[gridName].isMultiSort);
  const sorting = useSelector(sortingSelectors[gridName].getSorting);
  const searchView = useSelector(searchViewSelectors[gridName]);
  const toggleSortType = useCallback(
    () => dispatch(sortingActions[gridName].multiSortAction(!isMultiSort, sorting)),
    [dispatch, gridName, isMultiSort, sorting, sortingActions]
  );

  const handleClick: MenuProps['onClick'] = info => {
    onClick && onClick(info);
    toggleSortType();
  };

  if (searchView === 'long-card') return null;

  return (
    <Menu.Item onClick={handleClick} {...restProps}>
      {isMultiSort ? 'Enable Single Sort' : 'Enable Multi Sort'}
    </Menu.Item>
  );
};

export default ToggleSortTypeMenuItem;
