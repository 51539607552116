import React from 'react';
import { useSelector } from 'react-redux';
import { Tooltip, Typography } from 'antd';
import { EquityTouchBulkResults } from '@models/bulkActions';
import { SalesloftContact } from '@optx/models/Salesloft';
import { selectors as bulkSelectors } from '@features/bulk-actions';
import ErrorReason from './ErrorReason';

export const TableCellErrorReasonEquityTouch = (value: string, record: EquityTouchBulkResults) => {
  const { company_id: companyId, salesloftContacts: contacts } = record;
  const companyData = useSelector(bulkSelectors.equityTouch.getCompanyEqtData(companyId));

  if (value && value.includes('already')) {
    return value;
  }

  const { salesloftContacts } = record;

  if (typeof value === 'string' && record.et_resolve !== 'Add contact to ET') {
    return (
      <Tooltip title={value}>
        <Typography.Text ellipsis style={{ width: '100%' }}>
          {value}
        </Typography.Text>
      </Tooltip>
    );
  }

  if (
    salesloftContacts.length === 1 &&
    salesloftContacts[0].person_id === null &&
    salesloftContacts[0].sl_resolve
  ) {
    return (
      <Tooltip title={salesloftContacts[0].sl_message}>
        <Typography.Text ellipsis style={{ width: '100%' }}>
          {salesloftContacts[0].sl_message}
        </Typography.Text>
      </Tooltip>
    );
  }

  if (salesloftContacts.length === 1 && salesloftContacts[0].person_id === null) {
    return null;
  }

  const sentContacts = companyData?.salesloft_contacts as SalesloftContact[];

  return <ErrorReason contactsResponse={contacts} sentContacts={sentContacts} />;
};

export default TableCellErrorReasonEquityTouch;
