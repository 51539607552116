import moment from 'moment';
// models
import CompanyTouch, { CompanyTouchesResponse } from '@models/company/CompanyTouch';
import { TouchesCreateForm, TouchesFinancialPayload } from '@models/api/touches';
import { CompanyFieldsGroup, BaseField } from '@optx/models/companyFields';
import { FinancialField } from '@optx/features/field-factory';
import { FinancialYearsChangeLog } from '@optx/features/edit-all-info/state/interfaces';
// constants
import { DEFAULT_EMPTY_VALUE } from '@constants/value';
import { ISO_DATE_FORMAT } from '@optx/constants/format/date';
// utils
import { formatDate, DateFormat } from '@utils/format';

/**
 * Format touch note text to include HTML spacings.
 * @param text
 */
const formatNotesText = (text: string = '') => text.replace(/\n/g, '<br />');

/**
 * Format notes text in touches to be HTML friendly.
 * @param data touches response
 */
export const formatTouchHistoryNotes = (data: CompanyTouchesResponse) => {
  const { touch_history: touchHistory } = data;

  const keys = Object.keys(touchHistory);

  keys.forEach(key => {
    (touchHistory[key] as Array<CompanyTouch>).forEach(touch => ({
      ...touch,
      notes: formatNotesText(touch.notes),
    }));
  });
};

export const formatLastTouch = (lastTouch: string, format?: DateFormat) => {
  if (lastTouch === 'Never' || !lastTouch) {
    return 'NEVER';
  }

  return formatDate(lastTouch, format);
};

// Sometimes, company score growth is sent as a DEFAULT_EMPTY_VALUE instead of '0'
export const formatCompanyScore = (scoreGrowth: string) => {
  if (scoreGrowth === DEFAULT_EMPTY_VALUE) {
    return 0;
  }

  return parseInt(scoreGrowth);
};

/**
 * Format touch touch filters to form initial values.
 * @param values filters response as FinancialsFilter.
 */

const financialValues = ['revenue', 'ebitda', 'annual_recurring_revenue', 'gm'];

export const formatInitialValues = (values: Array<CompanyFieldsGroup<BaseField>>) => {
  let initialValues: Partial<TouchesCreateForm> = {};
  values.forEach(value => {
    if (value.data) {
      value.data.forEach(item => {
        initialValues = {
          ...initialValues,
          [item.id]: financialValues.includes(item.id) ? item.data : item.value,
        };
      });
    }
  });

  return initialValues;
};

/**
 * Formats the final values for financial payload
 *
 * @param form - The form data
 * @param initialValues - The initial values of the form
 *
 * @returns The formatted final payload or null if initialValues is null
 */
export const formatFinalValues = (
  form: Partial<TouchesCreateForm>,
  initialValues: Partial<TouchesCreateForm> | null,
  financialInput: Array<CompanyFieldsGroup> | null,
  yearChangeLog?: FinancialYearsChangeLog
) => {
  if (initialValues === null) return null;

  let finalPayload: Partial<TouchesFinancialPayload> = {};

  financialInput?.forEach(item => {
    if (item.tag && !finalPayload[item.tag]) {
      finalPayload[item.tag] = {};
    }

    if (item.data) {
      item.data.forEach((formField: BaseField) => {
        const selectedCat = item.tag ?? formField.tag;

        if (!finalPayload[selectedCat]) {
          finalPayload[selectedCat] = {};
        }

        let fieldValue;

        switch (formField.id) {
          case 'banker':
            fieldValue =
              typeof form[formField.id] === 'boolean'
                ? form[formField.id]?.toString() === 'true'
                : initialValues[formField.id];

            break;

          case 'customer_type':
            fieldValue =
              form[formField.id] !== undefined ? form[formField.id] : initialValues[formField.id];

            if (formField.extra_data) {
              const extraDataKey = 'customer_other_rationale';
              const extraDataValue =
                form[extraDataKey] !== undefined
                  ? form[extraDataKey]
                  : formField.extra_data[extraDataKey].value;

              let customerTypeData = { [extraDataKey as string]: extraDataValue };
              finalPayload[selectedCat] = { ...finalPayload[selectedCat], ...customerTypeData };
            }

            break;

          default:
            fieldValue =
              // @ts-ignore
              form[formField.id] !== undefined ? form[formField.id] : initialValues[formField.id];

            break;
        }

        if (fieldValue !== undefined) {
          // @ts-ignore
          finalPayload[selectedCat][formField.id] = fieldValue;
        }
      });
    }
  });

  finalPayload = {
    ...finalPayload,
    funding: formatFundingValues(finalPayload.funding as TouchesFinancialPayload['funding']),
    financials: formatFinancialValues(
      finalPayload.financials as TouchesFinancialPayload['financials'],
      yearChangeLog
    ),
    company_id: parseInt(form.companyId ?? '0'),
  };

  return finalPayload;
};

/**
 * Formats the funding values. Sets default last round data if some
 * last round input in the form was changed.
 *
 * @param fundingFields - The fields from 'Funding / Ownership Structure' section
 *
 * @returns The formatted funding fields
 */
function formatFundingValues(
  fundingFields: TouchesFinancialPayload['funding'] | null
): TouchesFinancialPayload['funding'] {
  if (!fundingFields) return {};

  let { last_round, last_round_amount, last_round_date, last_investors, ...rest } = {
    ...fundingFields,
  };

  const hasLastRoundValue = last_round?.label !== DEFAULT_EMPTY_VALUE;
  const hasLastRoundAmountValue = !!last_round_amount;
  const hasLastRoundDateValue = last_round_date !== '';
  const hasLastInvestorsValue = Array.isArray(last_investors) && last_investors.length > 0;

  // Last Round Type should be set to Unknown if not provided
  if (
    !hasLastRoundValue &&
    (hasLastRoundAmountValue || hasLastRoundDateValue || hasLastInvestorsValue)
  ) {
    last_round = { label: 'Unknown', value: 0 };
  }

  // Last Round Amount should be set to 0 if not provided
  if (
    !hasLastRoundAmountValue &&
    (hasLastRoundValue || hasLastRoundDateValue || hasLastInvestorsValue)
  ) {
    last_round_amount = 0;
  }

  // Last Round Date should be set to today if some last round info is provided
  if (
    !hasLastRoundDateValue &&
    (hasLastRoundValue || hasLastRoundAmountValue || hasLastInvestorsValue)
  ) {
    last_round_date = moment().format(ISO_DATE_FORMAT);
  }

  return {
    last_round,
    last_round_amount,
    last_round_date,
    last_investors,
    ...rest,
  };
}

function formatFinancialValues(
  fields: TouchesFinancialPayload['financials'] | null,
  yearChangeLog?: FinancialYearsChangeLog
): TouchesFinancialPayload['financials'] {
  if (!fields) {
    return {};
  }

  if (!yearChangeLog) {
    return fields;
  }

  const newFields = { ...fields };

  newFields.annual_recurring_revenue = formatFinancialFieldData(
    fields.annual_recurring_revenue,
    yearChangeLog
  );

  newFields.revenue = formatFinancialFieldData(fields.revenue, yearChangeLog);

  newFields.gm = formatFinancialFieldData(fields.gm, yearChangeLog);

  newFields.ebitda = formatFinancialFieldData(fields.ebitda, yearChangeLog);

  return newFields;
}

function formatFinancialFieldData(
  data: FinancialField[] | null | undefined,
  yearChangeLog: FinancialYearsChangeLog
) {
  if (!data) return [];

  return data.map((financialYear: FinancialField) => {
    const logFinancialYear = yearChangeLog[financialYear.year];

    if (!logFinancialYear) {
      return financialYear;
    }

    return {
      ...logFinancialYear,
      value: financialYear.value,
    };
  });
}
