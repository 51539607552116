import React, { useEffect, useRef, useState, useContext } from 'react';
import { OptionsType } from 'react-select';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Button, Checkbox, Row, Tooltip, Typography } from 'antd';
import { Link, useLocation } from 'react-router-dom';
// models
import { SelectOption } from '@optx/models/Option';
import { SuccessErrorCallback } from '@optx/models/callback';
import Company, { CompanyProfile, CompanyUserValuesKeys } from '@optx/models/Company';
import { CustomValue, ServiceType } from '../state/interfaces';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { CompanyProfileTabs } from '@optx/constants/routes';
import { COMPANY_NAME_ENDPOINT } from '@constants/asyncEndpoints';
// hooks
import useAsyncSearch from '@optx/common/hooks/select/useAsyncSearch';
import useUpdateFields from '../hooks/useUpdateFields';
import useReferrerProfileLink from '@hooks/useReferrerLink';
// components
import EditCellPen from '@optx/components/common/table/Companies/styled-cells/EditCellPen';
import EditPopover from '@optx/components/common/popover/EditPopover';
import { InlineEdit } from '@optx/shared/view/molecules/edit/InlineEdit';
import { DropdownAddContext } from '@optx/components/DropdownAdd/DropdownAddContext';
import Icon from '@components/common/Icon';
// styles
import { Styled } from './EditFieldCheckBoxSelectAsync.styled';

interface EditFieldCheckBoxAsyncProps {
  value: string | null;
  record: Company | CompanyProfile | undefined;
  fieldName: 'Add-on';
  fieldKey?: CompanyUserValuesKeys;
  hidePenIcon?: boolean;
  isFromGridDropDown?: boolean;
  dropdownLabel?: string;
  service: ServiceType;
  successMessage: string;
  errorMessage: string;
  type?: 'check' | 'company';
  isFromGrid: boolean;
  hasAddonLink?: boolean;
  isFromHistory?: boolean;
}

const EditFieldCheckBoxSelectAsync: React.FC<EditFieldCheckBoxAsyncProps> = ({
  value,
  record,
  fieldName,
  fieldKey,
  isFromGrid,
  service,
  successMessage,
  errorMessage,
  type = 'check',
  isFromGridDropDown,
  dropdownLabel,
  hidePenIcon,
  hasAddonLink,
  isFromHistory,
}) => {
  const { setDropdownVisible } = useContext(DropdownAddContext);
  const location = useLocation();

  const { updateField } = useUpdateFields({
    fieldName,
    companyId: record?.company_id ?? 0,
    companyUrl: record?.company_url ?? null,
    service,
    successMessage,
    errorMessage,
    isFromHistory,
  });

  const addonCheck = value !== null && value !== '';

  const popoverContentRef = useRef(null);
  const [closePopup, setClosePopup] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState(addonCheck);
  const [companyValue, setCompanyValue] = useState({
    label: value,
    value,
    id: record?.addon_company_id,
  });
  const [fetchedOptions, setFetchedOptions] = useState<OptionsType<SelectOption>>();
  const { loadOptions } = useAsyncSearch({ endpoint: COMPANY_NAME_ENDPOINT });

  const { referrerUrl } = useReferrerProfileLink(record?.addon_company_id ?? 0);

  useEffect(() => {
    if (value === companyValue.value) {
      setButtonDisable(true);
    } else if (!checkboxValue || (checkboxValue && companyValue.label !== null)) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [checkboxValue, companyValue.label, value, companyValue.value]);

  useEffect(() => {
    setCheckboxValue(addonCheck);
    setCompanyValue({ label: value, value, id: record?.addon_company_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  if (!record) return null;

  const title = `${fieldName} Management`;

  const updateCompanyAddonCheckbox = (event: CheckboxChangeEvent) => {
    const { checked } = event.target;
    setCheckboxValue(checked);
    if (checked === false) setCompanyValue({ label: null, value: null, id: null });
  };

  const handleSave = () => {
    setClosePopup(true);
    setDropdownVisible(false);
    const payload: CustomValue = {
      value: checkboxValue,
      optionalValue: companyValue.value,
      additionalValue: companyValue.id,
    };

    if (addonCheck === checkboxValue && value === companyValue.value) {
      return null;
    }

    return updateField(payload, fieldKey);
  };

  const handleCancel = () => {
    setCompanyValue({ label: value, value, id: record?.addon_company_id });
    setCheckboxValue(addonCheck);
    setClosePopup(true);
  };

  const getLoadOptionsDelay = (query: string, callback: SuccessErrorCallback) => {
    if (query.length > 2) {
      loadOptions(query, options => {
        options.unshift();
        const callbackResult = callback(options);

        // set current filter to a cached variable.
        setFetchedOptions(options);

        return callbackResult;
      });
    }
  };

  const onAsyncMultiselectMenuClose = () => {
    setFetchedOptions([]);
  };

  const getDisabledStatus = () => {
    if (!checkboxValue) return false;

    if (checkboxValue) {
      if (!companyValue.label) return true;
    }

    return false;
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  const content = (
    <Styled.ContentContainer
      className="profile-information__financial"
      ref={popoverContentRef}
      onKeyDown={handleDropdownKeyEvent}
      tabIndex={0}
    >
      <Row>
        <Checkbox
          defaultChecked={checkboxValue}
          checked={checkboxValue}
          onChange={updateCompanyAddonCheckbox}
        >
          Company is an Add-on
        </Checkbox>
      </Row>
      <Row style={{ marginTop: 8, width: 280 }}>
        <Typography.Text>Associated Company / Platform</Typography.Text>
        <Styled.StyledAsyncSingleSelect
          loadOptions={getLoadOptionsDelay}
          className="addon--multiselect-async"
          onChange={(e: any) => setCompanyValue(e)}
          value={companyValue}
          onSelectResetsInput={false}
          loadingMessage={
            ({ inputValue }: { inputValue: string }) =>
              inputValue.length > 2 ? 'Loading...' : 'Begin typing'
            // eslint-disable-next-line react/jsx-curly-newline
          }
          isDisabled={!checkboxValue}
          controlShouldRenderValue
          closeMenuOnSelect
          blurInputOnSelect={false}
          onMenuClose={onAsyncMultiselectMenuClose}
          defaultOptions={fetchedOptions}
        />
        {getDisabledStatus() && (
          <Typography.Text type="danger">Select a parent company</Typography.Text>
        )}
      </Row>
      <div
        className="profile-information__popover-buttons"
        style={{ margin: getDisabledStatus() ? '7px 0 0 -16px' : '29px 0 0 -16px' }}
      >
        <Button className="profile-information__cancel" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className="profile-information__save"
          type="primary"
          disabled={buttonDisable}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </Styled.ContentContainer>
  );

  if (hasAddonLink && record?.addon_company_id) {
    const link =
      `/profile/${record?.addon_company_id}` +
      location.search.replace(/&tab=[^&]*/, `&tab=${CompanyProfileTabs.ADDON_MGMT}`);

    return (
      <Styled.AddOnLinkContainer>
        <InlineEdit
          showActionOnHover={true}
          action={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <EditCellPen
              content={content}
              title={title}
              onVisibilityChange={handleCancel}
              closePopup={closePopup}
              setClosePopup={setClosePopup}
            />
          }
          fill
        >
          {value ? (
            <Tooltip title={value}>
              <Link to={link}>{value}</Link>
            </Tooltip>
          ) : (
            DEFAULT_EMPTY_VALUE
          )}
        </InlineEdit>
      </Styled.AddOnLinkContainer>
    );
  }

  if (!isFromGrid || isFromGridDropDown) {
    return (
      <EditPopover
        content={content}
        title={title}
        onVisibilityChange={handleCancel}
        closePopup={closePopup}
        hidePenIcon={hidePenIcon}
        setClosePopup={setClosePopup}
      >
        {isFromHistory
          ? 'Edit Field'
          : isFromGrid ||
            (type === 'check' ? (
              <Checkbox checked={addonCheck} style={{ pointerEvents: 'none' }} />
            ) : (
              <Tooltip title={value ?? DEFAULT_EMPTY_VALUE}>{value ?? DEFAULT_EMPTY_VALUE}</Tooltip>
            ))}
        {dropdownLabel && dropdownLabel}
      </EditPopover>
    );
  }

  return (
    <InlineEdit
      action={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <EditCellPen
          content={content}
          title={title}
          onVisibilityChange={handleCancel}
          closePopup={closePopup}
          setClosePopup={setClosePopup}
        />
      }
      fill
    >
      {value ? (
        <InlineEdit
          action={
            <Styled.NewTabLink
              to={referrerUrl + `&tab=${CompanyProfileTabs.ADDON_MGMT}`}
              target="_blank"
            >
              <Icon iconName="open-in-new" />
            </Styled.NewTabLink>
          }
        >
          <Styled.DefaultTooltip title={value}>
            <Link to={referrerUrl + `&tab=${CompanyProfileTabs.ADDON_MGMT}`}>{value}</Link>
          </Styled.DefaultTooltip>
        </InlineEdit>
      ) : (
        DEFAULT_EMPTY_VALUE
      )}
    </InlineEdit>
  );
};

export default React.memo(EditFieldCheckBoxSelectAsync);
