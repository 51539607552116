import { SelectOption } from './Option';
import { PageInfo } from './table/Pagination';
import { SortByRule } from './table/sorting';

export enum ListAccessRights {
  // List can only be downloaded.
  ReadOnly = 1,
  // Full access to list. It can be renamed, deleted, shared etc.
  Edit,
  // List can be downloaded and shared.
  ShareOnly,
}

export interface CompanyWatchList {
  title: string;
  count: number;
  date: string;
  global_list?: boolean;
  extra_options?: WatchListMeta;
  unique_id: string | number;
  share_permission: ListAccessRights;
  origin?: string;
  owner_name?: string;
  is_et_group?: boolean;
  addon_company?: string;
  addon_company_id?: number;
  modified_at: string | null;
  is_public: boolean;
  is_owner: boolean;
}

export interface WatchListMeta {
  list_type: string;
  read_only: boolean;
  et_group_id?: number;
  is_public?: boolean;
}

export type ListType = 'saved_search' | 'watchlist' | 'combined';

export interface AddCompanyToWatchList {
  companyId: number;
  watchLists: CompanyWatchList[];
  ownerName?: string;
}

export interface AddCompanyToWatchListBulk {
  companyIds: number[];
  watchLists: SelectOption[];
  ownerName?: string;
}
export interface DeleteCompanyFromWatchList {
  listId: string;
  companyId: number;
}

export interface UpdateListPermission {
  listId: number;
  userPermission: number;
}

export interface CreateCompanyToWatchList {
  companyId?: number;
  title: string;
  name: string;
  id: number;
}

export interface FetchFavoriteLists {
  query: string;
  sortBy?: Array<SortByRule<any>>;
  fetchAll?: boolean;
  showEmptyLists?: boolean;
}
export interface FetchFavoriteListsPopup {
  data: Array<CompanyWatchList>;
  sortBy: Array<SortByRule<any>>;
  fetchAll?: boolean;
  showEmptyLists: boolean;
}

export interface UpdateListAssociation {
  listId: number | string;
  addonCompany?: string;
  addonCompanyId?: number;
}

export type WatchlistsTabsPaginationActionsKeys =
  | 'watchlists'
  | 'sourceScrub'
  | 'companySearches'
  | 'contactSearches';

export interface WatchlistsTabsPaginationActions {
  actions: WatchlistsTabsPaginationActionsKeys[];
  pagination: PageInfo;
}
export type WatchlistsSorting = 'recently' | 'title-asc' | 'title-desc' | 'owner';

export interface PublicWatchListActionPayload {
  is_public: boolean;
  list_id?: number;
  origin?: string;
}
