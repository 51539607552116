import React from 'react';
import { useSelector } from 'react-redux';
import { SelectOption } from '@optx/models/Option';

import { OrderedList, Styled as StyledOrderedList } from '@shared/view/molecules/List/OrderedList';
import { selectors } from '../../state';
import { TypographyTitle, SecondaryTitle } from '../styled';
import { LabelValuePair } from './LabelValuePair';

const renderStageItem = (stage: SelectOption, index: number) => (
  <StyledOrderedList.Item key={index}>
    <LabelValuePair label={stage.label} value={stage.value} />
  </StyledOrderedList.Item>
);

export const OtherDealStages = () => {
  const dealStages = useSelector(selectors.pipelineInformation.selectOtherDealStages);

  return (
    <div>
      <TypographyTitle level={5}>Other Deal Stages</TypographyTitle>
      <SecondaryTitle>There are 12 different deal stages</SecondaryTitle>

      <OrderedList dataSource={dealStages} renderItem={renderStageItem} />
    </div>
  );
};
