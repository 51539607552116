import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card } from 'antd';
// models
import { TableChangeCallback } from '@optx/models/table/antd-table';
import { FileDetails } from '@optx/models/files';
import { SortByRule } from '@optx/models/table/sorting';
// constants
import { FILES_DEFAULT_SORT } from '@optx/constants/table/sort/defaultSort';
// utils
import { mapSorting } from '@optx/utils/table/sorting';
// redux
import { actions as fileActions, selectors as fileSelectors } from '@optx/redux/company/files';
import { actions as documentUploadTouchActions } from '@redux/ui/modals/add-document-upload-touch';
// hooks
import { useShouldFetch } from '@optx/common/hooks/fetch';
import { useInjectFiles } from '@optx/common/hooks/inject';
import useIsAdminOrAnalyst from '@optx/common/hooks/useisAdminOrAnalyst';
// components
import MultiSelectFilter from './MultiSelectFilter';
import { Styled } from '@components/common/table/Companies/CompanyTable.styled';

interface FileProps {
  companyId: number;
  isInET?: boolean;
}

const StyledFilterHeader = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 16px;

  & > * {
    display: inline-block;
    width: 200px;
    margin-right: 10px;
  }
`;

const Files: React.FC<FileProps> = ({ companyId, isInET }) => {
  useInjectFiles();

  const getIsAdminOrAnalyst = useIsAdminOrAnalyst();
  const dispatch = useDispatch();
  const shouldFetch = useSelector(fileSelectors.shouldFetch);
  const fetchFilesForCompanyId = useSelector(fileSelectors.fetchForCompanyId);
  const files = useSelector(fileSelectors.getFileData);
  const columns = useSelector(fileSelectors.getColumns);
  const sorting = useSelector(fileSelectors.sortBy);

  const shouldFetchFiles = companyId !== fetchFilesForCompanyId || shouldFetch;
  const fetchFiles = () => dispatch(fileActions.fetchFiles());
  const fetchFilesFilters = () => dispatch(fileActions.fetchFilesFilters());
  useShouldFetch(shouldFetchFiles, fetchFiles);
  useShouldFetch(shouldFetchFiles, fetchFilesFilters);

  const handleChange: TableChangeCallback<FileDetails> = (pagination, filters, sorter, extra) => {
    switch (extra.action) {
      case 'sort': {
        const { sortBy } = mapSorting(sorter, sorting, null, FILES_DEFAULT_SORT);
        dispatch(fileActions.sortFiles(sortBy as SortByRule<any>[]));

        break;
      }

      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(fileActions.fetchForCompanyId(companyId));
  }, [dispatch, companyId]);

  const handleNewFileButton = () => {
    dispatch(documentUploadTouchActions.toggleModal());
  };

  return (
    <Card
      title={<strong>{`Files (${files.length})`}</strong>}
      bordered={false}
      style={{ marginBottom: 16 }}
      extra={
        isInET &&
        getIsAdminOrAnalyst && (
          <Button onClick={handleNewFileButton} type="default">
            Add New File
          </Button>
        )
      }
    >
      <StyledFilterHeader>
        <MultiSelectFilter filterKey="file_type" companyId={companyId} />
        <MultiSelectFilter filterKey="origin" companyId={companyId} />
      </StyledFilterHeader>
      <Styled.CompanyTable
        size="small"
        columns={columns}
        rowKey={'file_id'}
        dataSource={files || []}
        onChange={handleChange}
        tableLayout="fixed"
        showSorterTooltip={false}
        style={{ overflowX: 'auto' }}
        pagination={false}
        sticky
        bordered
      />
    </Card>
  );
};

export default Files;
