import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
// models
import { UserInformation } from '@optx/models/user';
import { ColumnDisplay } from '@optx/models/table/Columns';
// constants
import {
  columnDisplayInitialState,
  initialColumnOrder,
} from '@optx/constants/table/columnDisplay/company-search';
// redux
import { gridResetColumnWidthsReducer, gridUpdateColumnWidthsReducer } from '@optx/redux/grid';
import { reducers as columnReducers } from '@optx/features/columns/update-columns';
import { actions as userInformationActions } from '@redux/user/information';
import * as actions from './actions';

const initialState: ColumnDisplay = {
  columnDisplay: cloneDeep(columnDisplayInitialState),
  columnOrder: initialColumnOrder,
};

const saveColumnsReducer: CaseReducer<ColumnDisplay, PayloadAction<ColumnDisplay>> = (
  draftState,
  action
) => {
  const { columnDisplay, columnOrder } = action.payload;
  draftState.columnDisplay = columnDisplay;
  draftState.columnOrder = columnOrder;
};

const updateColumnsReducer: CaseReducer<
  ColumnDisplay,
  PayloadAction<{
    columns: string | null;
    columnOrder: string | null;
    pinnedColumns: string | null;
  }>
> = (draftState, action) => {
  const { columns, columnOrder, pinnedColumns } = action.payload;

  columnReducers.updateColumnReducer(
    draftState,
    columns,
    columnOrder,
    pinnedColumns,
    columns !== null,
    pinnedColumns !== null
  );
};

const fetchUserInformationSuccessReducer: CaseReducer<
  ColumnDisplay,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const columns = action.payload.settings.session_settings
    ? action.payload.settings.session_settings.my_companies_columns
    : undefined;

  const columnOrder = action.payload.settings.session_settings
    ? action.payload.settings.session_settings.my_companies_column_order
    : undefined;

  const pinnedColumns = action.payload.settings.session_settings
    ? action.payload.settings.session_settings.my_companies_pinned_columns
    : undefined;

  columnReducers.updateColumnReducer(
    draftState,
    columns,
    columnOrder,
    pinnedColumns,
    typeof columns === 'string',
    typeof pinnedColumns === 'string'
  );

  gridUpdateColumnWidthsReducer(draftState, action.payload.settings.column_widths_my_companies);
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.saveColumns, saveColumnsReducer)
    .addCase(actions.updateColumns, updateColumnsReducer)
    .addCase(actions.saveColumnWidth, columnReducers.saveColumnWidthReducer)
    .addCase(actions.resetColumnWidths, gridResetColumnWidthsReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
);

export default reducer;
