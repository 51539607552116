// eslint-disable-next-line import/no-cycle
import { SelectedCompaniesGrids } from '@optx/models/bulkActions';
import { SelectedCompaniesState } from './interfaces';

export const getCompanies = (gridName: SelectedCompaniesGrids) => (state: SelectedCompaniesState) =>
  state[gridName];

export const checkEquityTouch =
  (gridName: SelectedCompaniesGrids) => (state: SelectedCompaniesState) =>
    state[gridName].every(company => company.is_in_et);

export const checkCompaniesOwner =
  (gridName: SelectedCompaniesGrids, analyst: string) => (state: SelectedCompaniesState) =>
    state[gridName].every(company => company.company_owner === analyst);
