import React from 'react';
import { useSelector } from 'react-redux';
// components
import FixedLoader from '@components/common/loader';
// redux
import * as CompaniesETSelectors from '@optx/features/equity-touch-integration/state';

const PageLoader = () => {
  const loading = useSelector(CompaniesETSelectors.pageLoading);

  return <FixedLoader loading={loading} />;
};

export default PageLoader;
