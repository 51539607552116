import { MailOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
`;

interface EmailValidationMessageProps {
  showMessage: boolean;
}

const EmailValidationMessage: React.FC<EmailValidationMessageProps> = ({ showMessage }) => {
  return (
    <Wrapper>
      <MailOutlined style={{ color: !showMessage ? '#52c41a' : '#F5222D', fontSize: 24 }} />
      {showMessage ? 'This is not a valid email' : 'This is a valid email'}
    </Wrapper>
  );
};

export default EmailValidationMessage;
