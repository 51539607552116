import React from 'react';
import { TooltipProps } from 'recharts';
import { Styled } from './HistogramTooltip.styled';

export const HistogramTooltip = (props: TooltipProps) => {
  const { payload, active } = props;

  // display tooltip only for count > 0
  if (active && payload && payload[0].value > 0) {
    return (
      <Styled.Wrapper>
        <span>
          {`Range : ${payload && payload[0].payload.min_value} - ${
            payload && payload[0].payload.max_value
          }`}
        </span>
        <br />
        <span>{`Count : ${payload && payload[0].value}`}</span>
      </Styled.Wrapper>
    );
  }

  return null;
};
