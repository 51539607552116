import React from 'react';
import { Row, Col } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';

const RowWrapper = styled(Row)`
  .ant-col .anticon svg {
    height: 23px;
    width: 63px;
  }
`;

const TableCellStatus = (value: boolean | string) => {
  let isSuccess: boolean;

  if (typeof value === 'string') {
    isSuccess = !value;
  } else {
    isSuccess = value;
  }

  return (
    <RowWrapper justify="space-between">
      <Col>{isSuccess === true ? 'Success' : 'Fail'}</Col>
      <Col>
        {isSuccess === true ? (
          <CheckCircleFilled style={{ color: '#52c41a' }} />
        ) : (
          <CloseCircleFilled style={{ color: '#f5222d' }} />
        )}
      </Col>
    </RowWrapper>
  );
};

export default TableCellStatus;
