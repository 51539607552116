import styled, { css } from 'styled-components';
import { Tag as $Tag } from 'antd';
import Icon from '@optx/components/common/Icon';

const ContentContainer = styled.div<{ isSoftware: boolean }>`
   {
    ${props =>
      css`
        width: ${props.isSoftware ? '375px' : '300px'};
      `}
  }

  .profile-information__subtitle {
    margin-top: 22px;
    padding-top: 14px;
    padding-bottom: 12px;
    border-top: 1px solid #d9d9d9;
  }

  .rationale_select-async {
    display: block;
    width: 100%;
  }

  .ant-typography {
    padding-bottom: 2px;
  }

  .ant-radio-group {
    width: 100%;

    .ant-radio-button-wrapper {
      width: 100%;
    }
  }
`;

const Tag = styled($Tag)`
  margin-top: 2px;
  margin-bottom: 2px;
  max-width: 100%;
  white-space: inherit;
  word-break: break-word;
`;

const ThumbsIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  margin-left: 15px;
`;

const ThumbsUpIcon = styled(ThumbsIcon)`
  color: #52c41a;
`;

const TableCellInner = styled.div`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Styled = {
  Tag,
  ContentContainer,
  ThumbsIcon,
  ThumbsUpIcon,
  TableCellInner,
};
