import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dictionary } from 'lodash';
// models
import { PreselectedFilter } from '@optx/models/filters';
// utils
import { normalizeFilter } from '@optx/utils/filters/filterHelpers';
// redux
import { selectors as companySearchSelectors } from '@redux/company/search/search';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import { actions as searchActions, selectors as searchSelectors } from '@features/grid/search';
import { selectors as viewSelectors } from '@features/grid/view';
// components
import SecondaryQuickFiltersPanel from '@components/feature/secondary-quick-filters/SecondaryQuickFiltersPanel';
import ExportToCSV from './Header/CompanySearchDropdown/SearchMenu/ExportToCSV';
import ShowFilterButton from '@optx/features/grid/filter/components/ShowFilterButton';

const SecondaryQuickFiltersContainer: React.FC = () => {
  const dispatch = useDispatch();

  const filter = useSelector(filterSelectors.getFilter('advancedSearch'));
  const histograms = useSelector(companySearchSelectors.selectHistograms);
  const keySearch = useSelector(searchSelectors.getSearchKey('advancedSearch'));
  const options = useSelector(filterSelectors.getTags('advancedSearch'));

  const view = useSelector(viewSelectors.getView('advancedSearch'));

  // call /search query
  const onSearch = useCallback(
    (searchKey: string, filter: Dictionary<PreselectedFilter>) => {
      const normalizedFilter = normalizeFilter(filter);
      dispatch(
        searchActions.searchCompanies({
          gridKey: 'advancedSearch',
          data: { searchKey, filter: normalizedFilter, shouldResetPageNumber: true },
        })
      );
    },
    [dispatch]
  );

  return (
    <SecondaryQuickFiltersPanel
      onSearch={onSearch}
      filter={filter}
      histograms={histograms}
      keySearch={keySearch}
      options={options}
      viewId={view.id}
    >
      <ShowFilterButton gridKey={'advancedSearch'} placement={'bottom'} />
      <ExportToCSV />
    </SecondaryQuickFiltersPanel>
  );
};

export default React.memo(SecondaryQuickFiltersContainer);
