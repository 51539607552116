import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'antd';
// models
import { PreselectedFilter } from '@optx/models/filters';
// utils
import validationSchema from '@utils/filtersValidationSchema';
import { normalizeFilter } from '@optx/utils/filters/filterHelpers';
// redux
import { Dictionary } from 'lodash';
import { selectors as searchSelectors } from '@features/grid/search';
import { actions as searchActions } from '@features/grid/search';
import { selectors as companyFiltersSelectors } from '@redux/company/filters';
import { selectors as modalSelectors, actions as modalActions } from '@redux/ui/modals/gridModals';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import { selectors as listsDetailsSelectors } from '@optx/redux/lists/details';
// hooks
import useSaveViewMessage from '@optx/common/hooks/filters/useSaveViewMessage';
// components
import FiltersModalContent from './FiltersModalContent';

const gridKey = 'lists';

const FiltersModal = () => {
  const dispatch = useDispatch();

  const onSearch = useCallback(
    (searchKey: string, filter: Dictionary<PreselectedFilter>) =>
      dispatch(
        searchActions.searchCompanies({
          gridKey,
          data: { searchKey, filter, shouldResetPageNumber: true, shouldHandleTouchFilters: true },
        })
      ),
    [dispatch]
  );

  const isSourceScrub = useSelector(listsDetailsSelectors.isSourceScrubRoute);

  const filter = useSelector(filterSelectors.getFilter(isSourceScrub ? 'sslists' : 'watchlists'));
  const keySearch = useSelector(searchSelectors.getSearchKey(gridKey));
  const filterSources = useSelector(companyFiltersSelectors.getCompanyFilters);
  const defaultFilters = useSelector(companyFiltersSelectors.getDefaultFilters);

  const clearSearch = useCallback(
    (shouldSort: boolean) => {
      if (shouldSort) {
        dispatch(searchActions.clearSearch({ gridKey, data: undefined }));
      } else {
        dispatch(searchActions.resetSearch({ gridKey, data: true }));
      }
    },
    [dispatch]
  );

  // modal
  const modalIsOpen = useSelector(modalSelectors.isOpen(isSourceScrub ? 'sslists' : 'watchlists'));
  const toggleModalFilters = useCallback(
    () => dispatch(modalActions.toggleModal({ gridKey: isSourceScrub ? 'sslists' : 'watchlists' })),
    [dispatch, isSourceScrub]
  );
  useSaveViewMessage(modalIsOpen && !!Object.keys(defaultFilters).length, toggleModalFilters);

  const handleSearch = (key: string, filter: Dictionary<PreselectedFilter>) => {
    const normalizedFilter = normalizeFilter(filter);
    onSearch(key, normalizedFilter);
    toggleModalFilters();
  };

  return (
    <Modal
      className="ant-modal-full ant-modal--filters"
      title={null}
      visible={modalIsOpen}
      footer={null}
      onCancel={toggleModalFilters}
      closable={false}
      destroyOnClose
    >
      <FiltersModalContent
        filter={filter}
        filterSources={filterSources}
        keySearch={keySearch}
        onClearFilter={clearSearch}
        onSearch={handleSearch}
        validationSchema={validationSchema}
        onClose={toggleModalFilters}
        modalIsOpen={modalIsOpen}
      />
    </Modal>
  );
};

export default FiltersModal;
