import { utils as requestUtils } from '@features/request';
import { createFeatureActionType } from '@optx/utils/feature';
import { featureName } from '../featureName';

export const fetchHistograms = requestUtils.createActionTypes(featureName, 'FETCH');

export const TOGGLE_REFRESH = createFeatureActionType(featureName, 'TOGGLE_REFRESH');
export const FILTER_CHANGED = createFeatureActionType(featureName, 'FILTER_CHANGED');
export const EDIT = createFeatureActionType(featureName, 'EDIT');
export const APPLY_EDIT = createFeatureActionType(featureName, 'APPLY_EDIT');
export const RESET_TO_DEFAULT = createFeatureActionType(featureName, 'RESET_TO_DEFAULT');
export const CLEAR = createFeatureActionType(featureName, 'CLEAR');
