import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tag, Button, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
// models
import { CompanyProfile } from '@optx/models/Company';
// constants
import { CompanyProfileTabs } from '@optx/constants/routes';
// services
import { UserService } from '@optx/services/api';
// redux
import { actions as editFieldActions } from '@optx/features/company/edit-fields/state';
import { selectors as userSelectors } from '@redux/user/information';
// styles
import { Styled } from './EditParentCompanyInfluence.styled';

type EditParentCompanyInfluenceProps = {
  company: CompanyProfile;
  handleParentCompanyClick: () => void;
};

const EditParentCompanyInfluence: React.FC<EditParentCompanyInfluenceProps> = ({
  company,
  handleParentCompanyClick,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    use_parent_company: initialValue,
    company_id: companyId,
    parentcompany: parentCompanyName,
    company_name: companyName,
  } = company;
  const { tab } = queryString.parse(location.search);
  const [open, setOpen] = useState(false);
  const [radioValue, setRadioValue] = useState<boolean>(initialValue as boolean);

  const defautlOptxScore = useSelector(userSelectors.getDefaultScore);

  const handleOk = () => {
    const payload = {
      companyId,
      value: radioValue,
      successMessage: 'Parent company influence successfully updated',
      errorMessage: 'Error updating parent company influence!',
    };
    dispatch(
      editFieldActions.updateFields({
        companyUrl: company?.company_url,
        companyId: company.company_id,
        value: payload,
        service: UserService.updateCompanyInfo,
        fieldName: 'Use Parent Company',
        isFromHistory: tab === CompanyProfileTabs.HISTORY,
      })
    );
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setRadioValue(initialValue);
  };

  const onChange = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
  };

  useEffect(() => {
    setRadioValue(initialValue as boolean);
  }, [initialValue]);

  const label = (
    <>
      Parent Company
      {parentCompanyName !== 'unknown' && (
        <Button
          className="btn--open-company-tag"
          type="primary"
          ghost
          onClick={handleParentCompanyClick}
          size="large"
        >
          View Parent Company
        </Button>
      )}
    </>
  );

  return (
    <>
      <Tag color="red" onClick={handleOpen} role="presentation">
        Parent Company: {parentCompanyName}
      </Tag>
      <Styled.ModalWrapper
        title={label}
        visible={open}
        onCancel={handleClose}
        onOk={handleOk}
        okText="Save"
        okButtonProps={{ disabled: radioValue === initialValue }}
        width={720}
      >
        <div>
          <b>&quot;{parentCompanyName}&quot;</b> is listed as the parent company for{' '}
          <b>&quot;{companyName}&quot;</b>
        </div>
        <div>
          {`Should this relationship affect the ${
            defautlOptxScore === 'il' ? 'IL OPTX Score' : 'OPTX Score'
          } and set it to 0?`}
        </div>
        <Radio.Group
          onChange={onChange}
          value={radioValue}
          className="popover-radio ant-popover-content-wrapper"
        >
          <Radio value>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Styled.ModalWrapper>
    </>
  );
};

export default React.memo(EditParentCompanyInfluence);
