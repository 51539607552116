import { call, put, takeLatest, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
// models
import { FilterResponse } from '@optx/models/filters';
import { UserSettings } from '@optx/models/user';
// services
import { FilterService } from '@services/api';
// redux
import { selectors as userSelectors } from '@redux/user/information';
import * as actions from './actions';

function* getCompanyFiltersSaga() {
  const settings: UserSettings = yield select(userSelectors.getSettings);
  const isAnalyst: boolean = yield select(userSelectors.getIsAnalyst);

  try {
    const res: AxiosResponse<FilterResponse> = yield call(FilterService.getOptions);

    if (res.data) {
      yield put(
        actions.fetchCompanyFiltersSuccess(res.data.filters, {
          defaultViews: res.data.default_views,
          searchOrigin: res.data.search_origin,
          settings,
          isAnalyst,
        })
      );
      yield put(actions.setFormRationales(res.data?.forms?.rationales || {}));
      yield put(actions.setNextSteps(res.data?.forms?.next_steps?.next_step_choices || []));
      yield put(actions.setAlertSettings(res.data?.forms?.alert_settings || {}));
    } else {
      yield put(actions.fetchCompanyFiltersFail('Error fetch company filters!'));
    }
  } catch (e: any) {
    yield put(actions.fetchCompanyFiltersFail('Error fetch company filters!'));

    if (e.response.status === 401) {
      yield put(actions.stopFetchingFilters(true));
    }
  }
}

export default function* companyFiltersSaga() {
  yield takeLatest(actions.fetchCompanyFilters, getCompanyFiltersSaga);
}
