import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Table, Row, Col, Typography } from 'antd';
// constants
import { defaultColumns } from '@components/common/table/TableSalesloft';
// redux
import { selectors as primaryContactsSelectors } from '@redux/company/primary-contacts';
import SelectContacts from './SelectContacts';
import { SalesloftContext } from '../SalesloftContext';

const SalesloftTable: React.FC = () => {
  const { errors } = useContext(SalesloftContext);

  const contacts = useSelector(primaryContactsSelectors.getPrimaryContacts);

  return (
    <>
      <Row gutter={20} className="add-to-equity-touch__salesloft">
        <Col span={12} className="salesloft-label">
          <Typography.Text>Contacts</Typography.Text>
          <SelectContacts />
          {!!errors.length &&
            errors.map(error => (
              <Typography.Text type="danger" style={{ display: 'block' }}>
                {error}
              </Typography.Text>
            ))}
        </Col>
      </Row>
      <Table
        key={Math.random()}
        rowKey={row => row.person_id as number}
        columns={defaultColumns}
        dataSource={contacts}
        bordered
        pagination={false}
        tableLayout="fixed"
        className="overflow-auto primary-contacts-grid legacy-table"
        locale={{ emptyText: 'There are no contacts. Add at least one contact to continue' }}
      />
    </>
  );
};

export default SalesloftTable;
