import { createSelector } from 'reselect';
// redux
import { selectors as localSelectors } from '../../edit-fields';
import { selectFeatureState } from './selectFeature';

const editingFieldsState = createSelector(selectFeatureState, state => {
  return state.editingFields;
});

// slice selectors
const results = createSelector(editingFieldsState, localSelectors.getResults);

const isInProgress = createSelector(editingFieldsState, localSelectors.isInProgress);
const isCompleted = createSelector(editingFieldsState, localSelectors.isCompleted);

const percentageCompleted = createSelector(
  editingFieldsState,
  localSelectors.getPercentageCompleted
);

const selectedCompaniesCount = createSelector(
  editingFieldsState,
  localSelectors.getSelectedCompaniesCount
);

const successfulCount = createSelector(results, results => {
  const successCompanies = results.filter(company => company.sync_status);

  if (successCompanies) {
    return successCompanies.length;
  }

  return 0;
});

const cancel = createSelector(editingFieldsState, localSelectors.cancel);
export default {
  results,
  isInProgress,
  isCompleted,
  percentageCompleted,
  selectedCompaniesCount,
  successfulCount,
  cancel,
};
