import React from 'react';
import { LabeledValue } from 'antd/lib/select';
import classNames from 'classnames';
// interfaces
import { SelectOption } from '@optx/models/Option';
// styles
import './styles.scss';

export interface PrefixedSelectionItemProps {
  prefix: string;
  label: string | number;
  className?: string;
}

export const PrefixedSelectionItem: React.FC<PrefixedSelectionItemProps> = ({
  prefix,
  label,
  className,
}) => {
  return (
    <span className={classNames('prefixed-selection-item', className)}>
      <span className="prefixed-selection-item__prefix">{prefix}</span>
      <span className="prefixed-selection-item__label">{label}</span>
    </span>
  );
};

/**
 * Inject prefix into options label.
 * @param prefix prefix to be injected
 * @param options options list
 */
export const mapOptionsToLabeledValues = (
  prefix: string,
  options: Array<SelectOption>,
  SelectionItem: React.FC<PrefixedSelectionItemProps> = PrefixedSelectionItem,
  existParent: boolean = false
) =>
  options.map<LabeledValue>(option => ({
    label: <SelectionItem prefix={prefix} label={option.label} />,
    value: option.value,
    parent: existParent ? option.parent : undefined,
  }));

export default PrefixedSelectionItem;
