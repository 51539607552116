import React from 'react';
import { useLocation } from 'react-router-dom';
import { Tabs } from 'antd';
import { useSelector } from 'react-redux';
// models
import Company, { CompanyProfile } from '@models/Company';
// redux
import { selectors, TouchesTimeline } from '@features/company-touches';
import { selectors as cardSelectors } from '@features/long-card/company-card';
import { OPTXInsights } from '@features/optx-insights';
import NewsFeed from '@components/pages/CompanyProfile/ProfileTabs/NewsFeed';
import { selectors as pannelSelectors } from '../state';
// components
import CardPannelHeader from './CardPannelHeader';
import CardPannelGraphs from './CardPannelGraphs';
import useHeaderHeight from '../utils/useHeaderHeight';
import { Styled } from './CardPannel.styled';

const { TabPane } = Tabs;

const TABS = {
  history: '1',
  insights: '2',
  news: '3',
};

interface CardPannelProps {
  companies: Array<Company>;
}

const CardPannel: React.FC<CardPannelProps> = ({ companies }) => {
  const isLoading = useSelector(pannelSelectors.pannelLoading);
  const selectedCompany = useSelector(cardSelectors.getProfile);
  const headerHeight = useHeaderHeight(64);

  const location = useLocation();

  // has touches
  const totalTouches = useSelector(selectors.search.total);

  return (
    <Styled.CardPannelWrapper
      loading={isLoading}
      $location={location.pathname}
      $topOffset={headerHeight}
    >
      {selectedCompany && (
        <>
          <CardPannelHeader companies={companies} selectedCompany={selectedCompany} />

          <Tabs
            animated={false}
            defaultActiveKey={totalTouches === 0 ? TABS.insights : TABS.history}
          >
            <TabPane tab="OPTX INSIGHTS" key={TABS.insights} style={{ width: '100%' }}>
              <OPTXInsights companyProfile={selectedCompany as CompanyProfile} />
              <CardPannelGraphs companyProfile={selectedCompany as CompanyProfile} />
            </TabPane>
            <TabPane tab="TOUCH HISTORY" key={TABS.history} style={{ width: '100%' }}>
              <TouchesTimeline company={selectedCompany} />
            </TabPane>
            <TabPane tab="NEWS FEED" key={TABS.news} style={{ width: '100%' }}>
              <NewsFeed company={selectedCompany} />
            </TabPane>
          </Tabs>
        </>
      )}
    </Styled.CardPannelWrapper>
  );
};

export default CardPannel;
