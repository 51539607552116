import { createReducer } from '@reduxjs/toolkit';
// redux
import { reducers as fetchReducers } from '@redux/feature/fetch';
// local
import { AnalystsEmailStatisticsState } from './interfaces';
// redux
import * as actions from './actions';

const initialState: AnalystsEmailStatisticsState = {
  data: null,
  error: '',
  loading: false,
  fetchedAt: null,
};

/**
 * Interval grouping does not show more than 12 data points:
 * 1 Day = grouped by 2 hours
 * 2 days – 12 days = day
 * 13 days – 84 days = week
 * 84 days – 1 year = month
 * > 1 year = year
 */
const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.fetch, fetchReducers.fetchReducer)
    .addCase(actions.fetchSuccess, fetchReducers.fetchSuccessReducer)
    .addCase(actions.fetchFail, fetchReducers.fetchFailReducer)
);

export default reducer;
