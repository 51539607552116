import styled from 'styled-components';
import Card from '@components/common/cards';

export const StatsCardsContainer = styled(Card)`
  height: 100%;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .pie-chart {
    position: relative;
    width: 130px;
    height: 130px;
    margin: 0px auto;

    .total {
      position: absolute;
      width: 130px;
      font-size: 17px;
      color: #262626;
      font-weight: 400;
      top: 29%;
      line-height: 30px;
      text-align: center;

      b {
        font-size: 28px;
        font-weight: 400;
        color: #52C41A;
        display: block;
      }
    }
`;

interface StatsLabelProps {
  isSmall?: boolean;
}

export const StatsLabel = styled.div<StatsLabelProps>`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;

  div {
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
    display: flex;
    gap: 3px;
    color: #52c41a;

    .first-value:first-child {
      font-size: ${props => (props.isSmall ? '16px' : '20px')};
      color: #1890ff;

      &.dash:after {
        content: '/';
        display: inline-block;
        margin-left: 3px;
      }
    }
  }

  small {
    color: #8083a3;
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
  }
`;

export const ViewRationaleButton = styled.button`
  color: #1890ff;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-end;
`;
