import React from 'react';
import { ButtonProps } from 'antd/lib/button';
// components
import Icon from '@components/common/Icon';
import { SearchViewButton } from './ToggleViewButton.styles';

interface ToggleTableViewProps extends ButtonProps {
  active: boolean;
  color?: string;
}

const ToggleTableView: React.FC<ToggleTableViewProps> = ({ active, color, ...restProps }) => {
  return (
    <SearchViewButton
      type="text"
      color={color}
      $active={active}
      icon={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <span>
          <Icon iconName="grid" />
        </span>
      }
      {...restProps}
    />
  );
};

export default ToggleTableView;
