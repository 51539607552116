import { SourcingOutreachState } from '../interfaces';

export const selectSourcingOutreachUsers = (state: SourcingOutreachState) => {
  const { allIds, byUserId } = state;

  return allIds.map(id => byUserId[id]);
};

export const isInitialized = (state: SourcingOutreachState) => !!state.fetchedAt;

export const sourcingOutreachCards = (state: SourcingOutreachState) => state.cards;

export const sourcingOutreachTotalColumns = (state: SourcingOutreachState) =>
  state.totalColumnsData;
