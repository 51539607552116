import React from 'react';
import { useSelector } from 'react-redux';
// redux
import { selectors as importSelectors } from '@optx/features/list-import/state';
// constants
import { useVT } from 'virtualizedtableforantd4';
import { columns } from './defaultColumns';
import Styled from './BulkEditingResults.styles';

const BulkEditingResults: React.FC = () => {
  const results = useSelector(importSelectors.getResults);

  const [vt] = useVT(
    () => ({
      initTop: 0,
      scroll: { y: 250 },
      debug: true,
    }),
    []
  );

  return (
    <Styled.CustomTable
      id="import-table"
      rowKey={(record, index) => `${record?.company_id || index} ${record?.Company}${record?.URL}`}
      tableLayout="fixed"
      columns={columns}
      dataSource={results}
      pagination={false}
      bordered
      className="legacy-table"
      sticky
      style={{ maxHeight: '45vh', overflowY: 'auto' }}
      // virtualization
      scroll={{ y: 250 }} // It's important for using VT!!! DO NOT FORGET!!!
      components={vt}
    />
  );
};

export default BulkEditingResults;
