import { useEffect } from 'react';

const useShouldFetch = (
  shouldFetch: boolean,
  fetch: (...props: any) => void,
  ...fetchArgs: any
) => {
  useEffect(() => {
    shouldFetch && fetch && fetch(...fetchArgs);
  }, [fetch, fetchArgs, shouldFetch]);
};

export default useShouldFetch;
