import { createSelector } from 'reselect';
import { selectors as fetchSelectors } from '@redux/feature/fetch';
import { CompanyTouchesFiltersState } from '../interfaces';

// specific selectors
const selectFiltersState = (state: CompanyTouchesFiltersState) => state;

export const isLoading = createSelector(selectFiltersState, fetchSelectors.isLoadingSelector);

export const shouldInitialFetch = createSelector(
  selectFiltersState,
  fetchSelectors.shouldInitialFetchSelector
);

export const isLoaded = createSelector(selectFiltersState, state => !!state.fetchedAt);

export const getTypesOptions = createSelector(selectFiltersState, state => state.touchTypes);

export const getCurrentInitiator = createSelector(
  selectFiltersState,
  state => state.currentInititator
);

export const getCurrentTouchType = createSelector(
  selectFiltersState,
  state => state.currentTouchType
);

export const getInitiatorOptions = createSelector(selectFiltersState, state => state.initiators);
