import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { actions } from '../../actions';

const initialState: boolean = false;

const toggleReducer: CaseReducer<boolean, PayloadAction<boolean | undefined>> = (
  draftState,
  action
) => {
  if (typeof action.payload === 'boolean') {
    return action.payload;
  }

  return !draftState;
};

const reducer = createReducer(initialState, builder =>
  builder.addCase(actions.ui.toggleFiltersModal, toggleReducer)
);

export default reducer;
