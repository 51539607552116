import { createSelector } from 'reselect';
import { selectors as userSelectors } from '@redux/user/information';
import { selectors as advancedSearchSearchSelectors } from '@redux/company/search/search';
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as companyFiltersSelectors } from '@redux/company/filters';
import { selectors as saveSearchSelectors } from '@redux/company/saved-searches';
import { selectors as userListSelectors } from '@redux/user/user-list';
import { selectors as equityTouchSelectors } from '@redux/company/equity-touch';

export const isLoading = createSelector(
  userSelectors.isLoading,
  companyFiltersSelectors.isLoading,
  searchSelectors.isLoading('advancedSearch'),
  saveSearchSelectors.isLoading,
  userListSelectors.isFieldLoading,
  equityTouchSelectors.isLoading,
  advancedSearchSearchSelectors.loadingHistograms,
  advancedSearchSearchSelectors.getSortingLoading,
  (
    loadingUser,
    loadingFilters,
    searching,
    savingSearch,
    userManagement,
    equityTouch,
    loadingHistograms,
    getSortingLoading
  ) =>
    loadingUser ||
    loadingFilters ||
    searching ||
    savingSearch ||
    userManagement ||
    equityTouch ||
    loadingHistograms ||
    getSortingLoading
);

export const saveSearchLoading = createSelector(
  searchSelectors.isLoading('advancedSearch'),
  saveSearchSelectors.isLoading,
  (searching, savingSearch) => searching || savingSearch
);
