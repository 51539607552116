import React from 'react';
import { useSelector } from 'react-redux';
// import { ClockCircleOutlined } from '@ant-design/icons';
// import { StatsCompare } from '@optx/shared/view/molecules/statistics';
import StatsPresentation from '@optx/shared/view/molecules/statistics/StatsPresentation';
import StyledStatsSection from '../StatsSection/StatsSection.styles';
// import { formatHours } from '../../utils/format';
import { selectors } from '../../state';

// const formatAverage = (value: number, suffix = 'Team Average') => `${formatHours(value)} ${suffix}`;

const AdditionalStatistics = () => {
  const additionalStatistics = useSelector(selectors.additionalStatistics.getAdditionalStatistics);

  return (
    <>
      <StyledStatsSection.SubSection>
        <StatsPresentation
          title={additionalStatistics?.all_time.title}
          items={additionalStatistics?.all_time.items}
        />
      </StyledStatsSection.SubSection>
      {/* <StyledStatsSection.SubSection>
        <StyledStatsSection.SubSectionTitle>
          <ClockCircleOutlined />
          Time Spent in OPTX
        </StyledStatsSection.SubSectionTitle>
        {additionalStatistics && (
          <StatsCompare
            title="AVERAGE # OF HOURS A DAY"
            values={[
              additionalStatistics.time_spent_optx.items[0].value,
              additionalStatistics.time_spent_optx.items[1].value,
            ]}
            renderMainValue={formatHours}
            renderCompareValue={formatAverage}
          />
        )}
      </StyledStatsSection.SubSection> */}
    </>
  );
};

export default AdditionalStatistics;
