import { Tag } from 'antd';
import { TagProps } from 'antd/lib/tag';
import styled, { css } from 'styled-components';

const OverflowTagsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 0;

  .special-tag {
    font-size: 12px;
    background: #d6e4ff;
    /* Dark BLue */
    color: #334d6e;
  }
`;

const TagsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 0;
  flex-wrap: wrap;

  .special-tag {
    font-size: 12px;
    background: #d6e4ff;
    /* Dark BLue */
    color: #334d6e;
  }
  .ant-tag {
    margin: 5px 5px 0px 0px;
  }
`;

const StyledTag = styled<React.FC<TagProps & { $notTruncated?: boolean }>>(Tag)`
  cursor: pointer;
  ${props =>
    props.$notTruncated &&
    css`
      cursor: pointer;
      margin: 5px 10px 5px 0 !important;
    `}
`;

export const Styled = {
  OverflowTagsWrapper,
  TagsWrapper,
  StyledTag,
};
