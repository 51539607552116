import styled from 'styled-components';

const AddYearBoxWrapper = styled.div`
  background: #fff;
  box-shadow: 0 0 10px #575757;
  border: 1px solid #1890ff;
  margin-top: 16px;

  .heading-row {
    padding: 16px;
    border-bottom: 1px solid #c2cfe080;
  }
  .body-row {
    padding: 16px;
  }
  .year-select {
    min-height: 235px;
  }
  .cancel-btn {
    border: 1px solid #d9d9d9;
    color: ##595959;
    margin-right: 10px;
  }
  .save-year-btn:not(:disabled) {
    background: #1890ff;
    color: #ffffff;
    border: 1px solid #1890ff;
  }
  .ant-select-dropdown {
    top: 36px !important;
  }
`;

export default { AddYearBoxWrapper };
