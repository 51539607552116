import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { each } from 'lodash';
import { AxiosResponse } from 'axios';
import { CompanyNotification } from '@optx/models/Company';
import { noteSorting } from '@optx/models/Notes';
import * as actions from './actions';
import { actions as notesSearchActions } from '../search';
import { selectors as profileNotesSelectors, actions as profileNotesActions } from '../profile';
import * as profileNotesModels from '../profile/interfaces';
// services
import { UserService } from '../../../../services/api';
import NotificationService from '../../../../services/NotificationService';

const getPinId = (notes: profileNotesModels.ProfileNotes, noteId: number) => {
  let id;
  const allNotes = notes.pinned_notes.concat(notes.unpinned_notes);

  each(allNotes, note => {
    if (note.unique_id === noteId) {
      id = note.pin_id;

      return false;
    }

    return true;
  });

  return id;
};

export function* addCompanyNoteSaga(
  action: PayloadAction<{
    companyId: number;
    title: string;
    noteText: string;
  }>
) {
  const { companyId, title, noteText } = action.payload;

  try {
    const res: AxiosResponse<CompanyNotification> = yield call(
      UserService.createCompanyNote,
      companyId,
      title,
      noteText
    );

    if (res.data) {
      const sorting: noteSorting = yield select(profileNotesSelectors.getSorting);
      yield put(actions.addCompanyNoteSuccess(res.data));
      yield put(notesSearchActions.resetSearch());
      yield put(profileNotesActions.fetchProfileNotes({ companyId, sorting }));

      NotificationService.success(`Create note for ${title} success!`);
    } else {
      const errorMessage = `Create note for ${title} fail!`;
      yield put(actions.addCompanyNoteFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = `Create note for ${title} fail, Server error!!`;
    yield put(actions.addCompanyNoteFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* fetchCompanyNotesSaga(action: PayloadAction<number>) {
  const companyId = action.payload;

  try {
    const res: AxiosResponse<CompanyNotification[]> = yield call(UserService.getNotes, companyId);

    if (res.data) {
      yield put(actions.fetchCompanyNotesSuccess(companyId, res.data));
    } else {
      const errorMessage = 'Get company notes fail!';
      yield put(actions.fetchCompanyNotesFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = 'Get company notes fail, Server error!';
    yield put(actions.fetchCompanyNotesFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* deleteCompanyNoteSaga(
  action: PayloadAction<{ companyId: number; noteId: number }>
) {
  const { companyId, noteId } = action.payload;

  try {
    const res: AxiosResponse<number> = yield call(UserService.deleteCompanyNote, noteId);

    if (res.data) {
      const notes: profileNotesModels.ProfileNotes = yield select(
        profileNotesSelectors.getProfileNotes
      );
      const pinId = getPinId(notes, Number(noteId));

      yield put(actions.deleteCompanyNoteSuccess(noteId));
      yield put(notesSearchActions.resetSearch());

      if (pinId) {
        yield put(profileNotesActions.deleteProfileNote({ companyId, pinId }));
      }

      NotificationService.success('Delete note success!');
    } else {
      const errorMessage = 'Delete note fail!';
      yield put(actions.deleteCompanyNoteFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = 'Delete note fail, Server Error!';
    yield put(actions.deleteCompanyNoteFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* companyNotesSaga() {
  yield takeLatest(actions.addCompanyNote, addCompanyNoteSaga);
  yield takeLatest(actions.fetchCompanyNotes, fetchCompanyNotesSaga);
  yield takeLatest(actions.deleteCompanyNote, deleteCompanyNoteSaga);
}
