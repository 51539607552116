import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import _ from 'lodash';
// redux
import { selectors, actions } from '@redux/favorite-lists/lists';
// hooks
import { useShouldFetch } from '../fetch';

const useInitFetchFavoriteLists = () => {
  const dispatch = useDispatch();
  const shouldFetch = useSelector(selectors.shouldInitialFetch);
  const getAllWatchlists = useSelector(selectors.getAllWatchlists);
  const allWatchlistIsEmpty = _.isEmpty(getAllWatchlists);

  const fetchFilters = useCallback(
    () => dispatch(actions.initialFetchFavoritesLists()),
    [dispatch]
  );

  useShouldFetch(shouldFetch && allWatchlistIsEmpty, fetchFilters);
};

export default useInitFetchFavoriteLists;
