import React, { useState, useRef, useCallback } from 'react';
import { Radio, Form } from 'formik-antd';
import { Formik, FormikProps } from 'formik';
import { Modal, Button, Row, Col, Typography } from 'antd';
import moment from 'moment';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
// models
import { SuccessErrorCallback } from '@optx/models/callback';
import { UserRegionSettings } from '@optx/models/user';
import { UpdateUserSettingsPayload } from '@optx/models/api/user';
// services
import NotificationService from '@optx/services/NotificationService';
// redux
import {
  getRegionOptions,
  getRegionSettingsInitialValues,
} from '@optx/redux/user/information/selectors';
import { actions as userActions } from '@redux/user/information';
// components
import { OverlayLoader } from '@components/common/loader';
// styles
import { Styled } from '../../../UserProfile.styled';

const { Title } = Typography;

interface ChangeDateFormatModalProps {
  isOpen: boolean;
  toggle: VoidFunction;
}

interface InitialValues {
  short_date: string;
  grid_date: string;
}

const ChangeDateFormatModal: React.FC<ChangeDateFormatModalProps> = ({ isOpen, toggle }) => {
  // local state
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const regionOptions = useSelector(getRegionOptions);
  const initialValues = useSelector(getRegionSettingsInitialValues);
  const date = moment(new Date());
  const formRef = useRef<FormikProps<InitialValues> | null>(null);

  const changeRegionSettings = useCallback(
    (payload, callback?: SuccessErrorCallback) => {
      dispatch(
        userActions.updateUserSettings(payload as Partial<UpdateUserSettingsPayload>, callback)
      );
    },
    [dispatch]
  );

  const handleToggle = () => {
    toggle();
  };

  const handleSubmit = (values: InitialValues) => {
    const payload = { region_settings: {} as UserRegionSettings };

    Object.keys(values).forEach(value => {
      const region = regionOptions.settings.find(option => option.date_format_label === value);
      const dateFormat = region?.settings.find(
        item => item.id === values[value as keyof InitialValues]
      );
      payload.region_settings[value as keyof UserRegionSettings] = {
        id: values[value as keyof InitialValues],
        date_format: dateFormat?.settings.date_format || '',
      };
    });

    const callback: SuccessErrorCallback = (success, error) => {
      setLoading(false);

      if (error) {
        NotificationService.error(error);
      } else {
        success && NotificationService.success('Successfully updated date format');
        handleToggle();
      }
    };

    if (formRef.current) {
      if (!isEqual(formRef.current.initialValues, formRef.current.values)) {
        changeRegionSettings(payload, callback);
      } else {
        handleToggle();
      }
    }
  };

  const footerModal = (isValid: boolean) => {
    return (
      <Row justify="end">
        <Styled.StyledButton type="primary" htmlType="submit" disabled={isValid}>
          Change Date Format
        </Styled.StyledButton>
        <Button onClick={handleToggle}>Cancel</Button>
      </Row>
    );
  };

  return (
    <Modal
      visible={isOpen}
      footer={footerModal}
      title="Date Format"
      onCancel={handleToggle}
      destroyOnClose
    >
      <OverlayLoader loading={loading} />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        innerRef={instance => {
          formRef.current = instance;
        }}
      >
        {() => {
          return (
            <Form layout="vertical">
              <Row>
                {regionOptions.settings.map(setting => (
                  <Col span={12}>
                    <Title level={4} style={{ fontWeight: 'normal', fontSize: 16 }}>
                      {setting.date_format_title}
                    </Title>
                    <Form.Item name={setting.date_format_label}>
                      <Radio.Group name={setting.date_format_label}>
                        {setting.settings.map((option, index) => (
                          <Col key={index} span={24}>
                            <Radio name={setting.date_format_label} value={option.id}>
                              {`${option.region} ${date.format(option.settings.date_format)}`}
                            </Radio>
                          </Col>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                ))}
              </Row>
              {footerModal(false)}
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ChangeDateFormatModal;
