import React, { useEffect, useState, useRef } from 'react';
import { Card, Radio, Tooltip as AntdTooltip } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import {
  Area,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  TooltipProps,
} from 'recharts';
import moment from 'moment';
// models
import { ChartData, ChartIntervalKeys, ChartItem, ChartItemKeys } from '@models/charts/data';
// constants
import globalConfig from '@optx/constants/config';
import { ISO_DATE_FORMAT, SHORT_MONTH_YEAR_DATE_FORMAT } from '@optx/constants/format/date';
// utils
import { getFirstValidDate, getChartTicks } from '@utils/charts';
// components
import { ScoreTrending } from '@components/common/trending';
import KeyPerformanceIndicator from '@components/common/Text/KeyPerformanceIndicator';
import { ReactComponent as Gradients } from '@assets/svg/gradient-definitions.svg';
import EmptyChart from './EmptyChart';
import LineChartTooltip from './LineChartTooltip';
import CombinedChartTicks from './CombinedChartTicks';
import Styles from './ChartHeader.style';

interface LinearChartProps {
  data: ChartData;
  isLoading?: boolean;
  dataKey: string;
  labelKey: string;
  title: string;
  kpiLabel: string;
  className?: string;
  isTrending?: boolean;
  psgDate?: string | null;
  headerValue?: number;
  growth?: string;
  isPSGCompany?: boolean;
  isExtension?: boolean;
}

const LinearChart: React.FC<LinearChartProps> = ({
  data,
  isLoading = false,
  dataKey,
  labelKey,
  title,
  kpiLabel,
  isTrending,
  headerValue,
  growth,
  psgDate,
  isPSGCompany = false,
  isExtension = false,
}) => {
  const [interval, setIntervalValue] = useState<'' | ChartIntervalKeys>('');
  const [maxValue, setMaxValue] = useState<string | number | undefined | null>('-');
  const [minValue, setMinValue] = useState<string | number | undefined | null>('-');
  const [growthClassName, setGrowthClassName] = useState('');
  const [chartPoints, setChartPoints] = useState<ChartItem[]>([]);
  const [historyStartsWidth, setHistoryStartsWidth] = useState<string | undefined>();
  const [ticks, setTicks] = useState<Array<string>>([]);
  const [formatGrowth, setFormatGrowth] = useState<number | null>(null);
  const [psgDotCoordinate, setPsgDotCoordinate] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const areaRef = useRef<any>();
  const areDefaultTicksSetRef = useRef<boolean>(false);

  let defaultKey = '3M' as ChartIntervalKeys;

  if (data['2Y'].data.length) {
    defaultKey = '2Y';
  } else if (data['1Y'].data.length) {
    defaultKey = '1Y';
  } else if (data['6M'].data.length) {
    defaultKey = '6M';
  }

  const updateGrowth = (key: ChartIntervalKeys) => {
    if (!isNaN(Number(data[key]?.growth))) {
      return setFormatGrowth(data[key]?.growth as number);
    }

    return setFormatGrowth(null);
  };

  const handleSizeChange = (e: RadioChangeEvent) => {
    const key = e.target.value as ChartIntervalKeys;

    setIntervalValue(key);
    setMaxValue(data[key]?.max);
    setMinValue(data[key]?.min);
    setChartPoints((data[key]?.data || []).filter(item => item?.quarter !== ''));
    updateGrowth(key);

    if (data[key]?.growth! > 0) {
      setGrowthClassName('kpi--positive');
    } else if (data[key]?.growth! < 0) {
      setGrowthClassName('kpi--negative');
    } else {
      setGrowthClassName('');
    }

    setTicks(getChartTicks(data, key));
  };

  useEffect(() => {
    updateGrowth(interval || defaultKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data[defaultKey], interval, defaultKey]);

  useEffect(() => {
    if (data[defaultKey].data.length) {
      // calculate and save the width of "history starts from" element
      // it's width will be proportional to the number of empty labels compared to
      // the number of data points that have values
      if (defaultKey === '3M') {
        let emptyLabels = 0;

        data[defaultKey].data.forEach((item: ChartItem) => {
          if (item[dataKey as ChartItemKeys] === undefined) {
            emptyLabels++;
          }
        });

        if (emptyLabels) {
          setHistoryStartsWidth(`calc(${data[defaultKey].data[1]?.position!}% - 90px`);
        }
      }

      let points: ChartItem[] = [];

      if (!interval) {
        setIntervalValue(defaultKey);
        points = data[defaultKey].data;
      } else {
        points = data[interval].data;
      }

      setChartPoints(points.filter(item => item.quarter !== ''));

      if (!areDefaultTicksSetRef.current) {
        setTicks(getChartTicks(data, defaultKey));
        areDefaultTicksSetRef.current = true;
      }
    }

    setMaxValue(data[defaultKey]?.max);
    setMinValue(data[defaultKey]?.min);

    if (data[defaultKey]?.growth! > 0) {
      setGrowthClassName('kpi--positive');
    } else if (Number(growth) < 0) {
      setGrowthClassName('kpi--negative');
    } else {
      setGrowthClassName('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, interval]);

  useEffect(() => {
    if (isPSGCompany && areaRef.current) {
      const points = areaRef.current.props.points;

      if (points?.length > 0) {
        points.map((item: any, index: number) => {
          const psgDateMoment = moment(psgDate, ISO_DATE_FORMAT);
          const itemDateMoment = moment(item.payload.date, ISO_DATE_FORMAT);

          if (psgDateMoment.isSameOrBefore(itemDateMoment)) {
            if (
              psgDateMoment.isBetween(
                moment(points[index - 1]?.date, ISO_DATE_FORMAT),
                itemDateMoment,
                undefined,
                '()'
              )
            ) {
              setPsgDotCoordinate({ x: item.x - 45, y: isExtension ? -30 : -40 });

              return;
            } else if (index === 0 && psgDateMoment.isSameOrBefore(itemDateMoment)) {
              setPsgDotCoordinate({ x: item.x, y: isExtension ? -30 : -40 });

              return;
            } else if (index === points.length - 1 && psgDateMoment.isSame(itemDateMoment)) {
              setPsgDotCoordinate({ x: item.x - 16, y: isExtension ? -30 : -40 });

              return;
            } else if (psgDateMoment.isSame(itemDateMoment)) {
              setPsgDotCoordinate({ x: item.x - 16, y: isExtension ? -30 : -40 });

              return;
            }
          } else if (psgDateMoment.isSameOrAfter(itemDateMoment)) {
            setPsgDotCoordinate({ x: item.x - 16, y: isExtension ? -30 : -40 });

            return;
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartPoints, interval]);

  const header = (
    <Styles.ChartHeaderWrapper>
      <Styles.ChartTitleWrapper>{title}</Styles.ChartTitleWrapper>
      <Radio.Group value={interval} onChange={handleSizeChange}>
        {Object.keys(data).map((key, index) => {
          const isDisabled = !data[key as ChartIntervalKeys].data.length;

          return (
            <Radio.Button key={index} value={key} disabled={isDisabled}>
              {key}
            </Radio.Button>
          );
        })}
      </Radio.Group>
    </Styles.ChartHeaderWrapper>
  );

  const extra = (
    <div className={isTrending ? 'profile-chart-card__trending' : ''}>
      <KeyPerformanceIndicator
        label="High"
        value={typeof maxValue === 'number' ? maxValue!.toString() : '-'}
      />
      <KeyPerformanceIndicator
        label="Low"
        value={typeof minValue === 'number' ? minValue!.toString() : '-'}
      />
      {isTrending ? (
        <ScoreTrending
          score={headerValue}
          growth={formatGrowth}
          label={kpiLabel}
          customFontSize="16px"
          isPSGCompany={isPSGCompany}
        />
      ) : (
        <KeyPerformanceIndicator
          label={kpiLabel}
          value={typeof growth === 'number' ? `${growth}%` : '-'}
          className={growthClassName}
        />
      )}
    </div>
  );

  const renderDot = (props: any) => {
    return (
      <circle cx={props.cx} cy={props.cy} r={4} stroke="#1890FF" strokeWidth={1} fill="#fff" />
    );
  };

  const renderActiveDot = (props: any) => {
    let color = '#1890FF',
      xCoord = props.cx,
      yCoord = props.cy;

    return <circle cx={xCoord} cy={yCoord} r={1.5} stroke={color} strokeWidth={1} fill={color} />;
  };

  return (
    <Card title={header} extra={extra} className="profile-chart-card extra">
      <Gradients />
      {isLoading ? (
        <Styles.LoadingIconWrapper>
          <Styles.LoadingIcon />
        </Styles.LoadingIconWrapper>
      ) : (
        <div className="profile-chart-card__container">
          <ResponsiveContainer>
            <AreaChart data={chartPoints} margin={{ left: 24, top: 5 }}>
              <XAxis
                dataKey={labelKey}
                tickLine={false}
                stroke="#C2CFE0"
                tickSize={15}
                ticks={ticks}
                interval={0}
                type="number"
                tick={props => (
                  <CombinedChartTicks
                    startDate={chartPoints.length ? chartPoints[0].date : ''}
                    endDate={chartPoints.length ? chartPoints[chartPoints.length - 1].date : ''}
                    intervalKey={interval}
                    {...props}
                  />
                )}
              />
              <YAxis
                orientation="right"
                tickCount={2}
                tickMargin={10}
                tickLine={false}
                axisLine={false}
                stroke="#C2CFE0"
                type="number"
              />
              <Tooltip
                wrapperStyle={{ zIndex: 1 }}
                content={(props: TooltipProps) => (
                  <LineChartTooltip primaryKey={dataKey} primaryLabel="Score Value:" {...props} />
                )}
                cursor={false}
              />
              <Area
                type="monotone"
                dataKey={dataKey}
                stroke="#1890FF"
                strokeWidth="2px"
                fillOpacity={1}
                fill="url(#lineChartGradient)"
                dot={renderDot}
                ref={areaRef}
                connectNulls
                activeDot={renderActiveDot}
              />
            </AreaChart>
          </ResponsiveContainer>
          {!chartPoints.length && <EmptyChart text={isPSGCompany ? 'PSG Company' : undefined} />}
          {!isPSGCompany &&
            !!historyStartsWidth &&
            !data[Object.keys(data)[1] as ChartIntervalKeys].data.length && (
              <EmptyChart
                className="chart-empty-labels"
                text={`History Starts ${moment(
                  getFirstValidDate(data[defaultKey].data)?.date
                ).format(SHORT_MONTH_YEAR_DATE_FORMAT)}`}
                width={historyStartsWidth}
              />
            )}
          {chartPoints.length > 0 && isPSGCompany && psgDate && (
            <div
              style={{
                position: 'absolute',
                top: psgDotCoordinate.y,
                left: psgDotCoordinate.x,
                background: '#214d89',
                width: 32,
                borderRadius: 32,
                height: 32,
                fontFamily: 'Times New Roman',
                lineHeight: '32px',
                textAlign: 'center',
                color: 'white',
              }}
            >
              <AntdTooltip
                title={moment(psgDate, ISO_DATE_FORMAT).format(globalConfig.grid_date.DATE_FORMAT)}
              >
                PSG
              </AntdTooltip>
            </div>
          )}
        </div>
      )}
    </Card>
  );
};

export default LinearChart;
