import { createReducer, CaseReducer } from '@reduxjs/toolkit';
// constants
import { INITIAL_GlASS_DOOR_CHART } from '@optx/constants/charts';
// redux
import { fetchFailReducer, fetchReducer, fetchSuccessReducer } from '@redux/feature/fetch/reducers';
import { LOCATION_CHANGE } from 'connected-react-router';
import * as actions from './actions';
import { GlassDoorGraphState } from './interface';

const initialState: GlassDoorGraphState = {
  data: INITIAL_GlASS_DOOR_CHART,
  loading: false,
  error: '',
};

const clearStateReducer: CaseReducer<GlassDoorGraphState> = (draftState, action) => {
  if (!action.payload.location.pathname.includes('profile')) {
    draftState.data = INITIAL_GlASS_DOOR_CHART;
  }
};

export const reducer = createReducer<GlassDoorGraphState>(initialState, builder =>
  builder
    .addCase(actions.fetchGlassDoorData, fetchReducer)
    .addCase(actions.fetchGlassDoorDataSuccess, fetchSuccessReducer)
    .addCase(actions.fetchGlassDoorDataFail, fetchFailReducer)
    .addCase(LOCATION_CHANGE, clearStateReducer)
);
