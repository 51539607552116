import React from 'react';

// models
import { SourcingOutreachUserDataKey } from '@optx/features/sourcing-outreach-summary/models/SourcingOutreach';
import { CriteriaKeys } from '@features/sourcing-outreach-summary/models';
// constants
import cardInfoDictionary from 'src/features/sourcing-outreach-summary/constants/cardInfo';
// components
import Info from '@features/sourcing-outreach-summary/components/TotalCards/Info';

interface TableColumnTitleProps {
  columnName: SourcingOutreachUserDataKey;
  title: string;
}

export const TableColumnTitle: React.FC<TableColumnTitleProps> = ({ title, columnName }) => {
  const renderInfo = (cardKey: CriteriaKeys) => {
    const info = cardInfoDictionary[cardKey];

    if (info) {
      return <Info info={info} />;
    }

    return null;
  };

  return (
    <span>
      {title}
      <span
        onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
          event.stopPropagation();
        }}
        role="presentation"
      >
        {renderInfo(columnName as CriteriaKeys)}
      </span>
    </span>
  );
};
