import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, select } from 'redux-saga/effects';
// models
import { APIBoolean } from '@optx/models/api/generic';
// services
import { UserService } from '@services/api';
import NotificationService from '@services/NotificationService';
import { getErrorMessage } from '@utils/api/errors';
// redux
import { actions as customGlobalLoaderActions } from '@features/custom-global-loader';
import {
  actions as auditActions,
  selectors as auditSelectors,
} from '@features/company/audit-trail/state';
import { actions as userValuesActions } from '@redux/company/user-values';
import { UpdateFieldsPayload } from '@optx/features/company/edit-fields/state/interfaces';
import * as actions from './actions';
import { CompanyIsInBusinessPayload } from './interfaces';

export function* addInBusinessEvaluationSaga(action: PayloadAction<CompanyIsInBusinessPayload>) {
  const { companyId, isInBusiness, isFromHistory } = action.payload;
  yield put(customGlobalLoaderActions.toggle({ loading: true, customText: '' }));

  try {
    const customPayload: UpdateFieldsPayload = {
      companyId,
      value: isInBusiness,
    };

    // @ts-ignore
    const res = yield call(UserService.addCompanyInBusinessEvaluation, customPayload);
    yield put(actions.addCompanyInBusinessEvaluationSuccess(res.data, companyId, isInBusiness));
    yield put(
      userValuesActions.updateCompanyTrendingLines({
        employeeCountTrends: res.data?.employee_count_trends,
        optxScoreTrends: res.data?.optx_score_trends,
        companyId: action.payload.companyId,
      })
    );

    if (isFromHistory) {
      const { pageSize } = yield select(auditSelectors.getPagination);
      const field: string = yield select(auditSelectors.getSearchQuery);
      const optx: APIBoolean = yield select(auditSelectors.getOPTX);
      const internal: APIBoolean = yield select(auditSelectors.getInternal);
      const startDate: string = yield select(auditSelectors.getStartDate);
      const endDate: string = yield select(auditSelectors.getEndDate);

      yield put(
        auditActions.fetchChangedCompanyFields({
          companyId,
          pagination: {
            pageNumber: 1,
            pageSize,
          },
          field,
          internal,
          optx,
          startDate,
          endDate,
        })
      );
    }

    NotificationService.success('Company in business state updated successfully!');
    yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
  } catch (e: any) {
    yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
    const errorMessage = getErrorMessage(e, 'Failed to update company in business state!');
    yield put(actions.addCompanyInBusinessEvaluationFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* inBusinessEvaluationSaga() {
  yield takeLatest(actions.addCompanyInBusinessEvaluation, addInBusinessEvaluationSaga);
}
