import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, select } from 'redux-saga/effects';
// constants
import { FAVORITE_LIST_ID } from '@constants/lists';
// models
import Company from '@models/Company';
// services
import { UserService } from '@services/api';
import NotificationService from '@services/NotificationService';
// redux
import { getFilterListId } from '@redux/lists/details/selectors';
import { deleteCompanyInCurrentList } from '@redux/lists/details/actions';
import { AxiosResponse } from 'axios';
import {
  fetchFavoritesSuccess,
  fetchFavoritesFail,
  fetchFavorites,
  deleteFavoriteSuccess,
  deleteFavorite,
  deleteFavoriteFail,
  addFavoriteFail,
  addFavorite,
  addFavoriteSuccess,
} from './actions';
import * as favoritesSelectors from './selectors';

export function* fetchFavoritesSaga() {
  try {
    const res: AxiosResponse<Company[]> = yield call(UserService.getFavorites);

    if (res.data) {
      yield put(fetchFavoritesSuccess(res.data));
    } else {
      const errorMessage = 'Failed to fetch Favorites!!!';
      yield put(fetchFavoritesFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = 'Failed to fetch Favorites! Server Error!';
    yield put(fetchFavoritesFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* addFavoriteSaga(action: PayloadAction<number>) {
  const companyId = action.payload;

  try {
    const res: AxiosResponse<any> = yield call(UserService.addFavorite, companyId);

    if (res.data) {
      yield put(addFavoriteSuccess(companyId));
      NotificationService.success('Company added to favorites!');
      yield put(fetchFavorites());
    } else {
      const errorMessage = 'Failed to add company to favorites!';
      yield put(addFavoriteFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = 'Failed to add company to favorites! Server Error!';
    yield put(addFavoriteFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* deleteFavoriteSaga(action: PayloadAction<number>) {
  const companyId = action.payload;

  try {
    const res: AxiosResponse<any> = yield call(UserService.deleteFavorite, companyId);

    if (res.data) {
      // remove company from grid if favorites list is selected
      const currentListId: string = yield select(getFilterListId);

      if (currentListId === FAVORITE_LIST_ID || !currentListId) {
        const company: Company = yield select(state =>
          favoritesSelectors.getCompanyById(state, companyId)
        );
        yield put(deleteCompanyInCurrentList(company.company_id));
      }

      yield put(deleteFavoriteSuccess(companyId));
      NotificationService.success('Company removed from favorites!');
    } else {
      const errorMessage = 'Failed to delete company from favorites!';
      yield put(deleteFavoriteFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = 'Failed to delete company from favorites! Server Error!';
    yield put(deleteFavoriteFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* favoritesSaga() {
  yield takeLatest(fetchFavorites, fetchFavoritesSaga);
  yield takeLatest(addFavorite, addFavoriteSaga);
  yield takeLatest(deleteFavorite, deleteFavoriteSaga);
}
