/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';

import {
  Area,
  ResponsiveContainer,
  XAxis,
  YAxis,
  AreaChart,
  AxisDomain,
  Tooltip,
  TooltipProps,
  ReferenceLine,
  Label,
} from 'recharts';
import moment from 'moment';
import classnames from 'classnames';
// models
import { SocialMediaChartInterval } from '@optx/models/charts/data';
// components
import EmptyChart from '@optx/components/feature/charts/EmptyChart';
import LineChartTooltip from './LineChartTooltip';
import { Styles } from './LineChart.style';
import ChartHeader from './ChartHeader';
import ReversedTicks from './ReversedTicks';

interface LineChartProps {
  data: SocialMediaChartInterval;
  dataKey: string;
  labelKey?: string;
  labelKeyAlt?: string;
  checkLabelValue?: string;
  headerValue: number;
  headerValueExtra?: number;
  headerLabel: string;
  headerLabelExtra?: string;
  title: string;
  icon: React.ReactNode;
  kpiLabel: string;
  chartInterval: string;
  range?: [AxisDomain, AxisDomain];
  chartLabel?: string;
  className?: string;
  isReversed?: boolean;
  ticks?: number[];
  referenceLineData?: number | string;
}

const LineChart: React.FC<LineChartProps> = ({
  data,
  dataKey,
  labelKey,
  icon,
  title,
  headerValue,
  headerLabel,
  headerLabelExtra,
  headerValueExtra,
  chartLabel,
  range,
  isReversed,
  referenceLineData,
  ticks,
}) => {
  return (
    <Styles.LineChartWrapper
      className={classnames('profile-chart-card extra', {
        has_overflow: referenceLineData !== undefined,
      })}
      title={
        <ChartHeader
          icon={icon}
          title={title}
          headerValue={headerValue}
          headerLabel={headerLabel}
          headerValueExtra={headerValueExtra}
          headerLabelExtra={headerLabelExtra}
        />
      }
    >
      {Object.keys(data.data).length ? <Styles.ChartLabel>{chartLabel}</Styles.ChartLabel> : ''}
      <div className="profile-chart-card__container">
        {!Object.keys(data.data).length && (
          <EmptyChart className="chart-empty-labels" text="No History" />
        )}
        <ResponsiveContainer className={'area-charts'}>
          <AreaChart data={data.data} margin={{ left: 24, top: 25 }}>
            <defs>
              <linearGradient id="blueGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#C2E6F8" stopOpacity="1" />
                <stop offset="95%" stopColor="#e6f7ff" stopOpacity="0" />
              </linearGradient>
            </defs>
            <XAxis
              dataKey={labelKey}
              tickLine={false}
              stroke="#C2CFE0"
              tickSize={15}
              tickFormatter={val => (labelKey === 'date' ? moment(val).format('MMM YY') : val)}
            />
            <YAxis
              orientation="right"
              tickCount={2}
              tickMargin={0.5}
              tickLine={false}
              axisLine={false}
              stroke="#C2CFE0"
              type="number"
              domain={range}
              ticks={ticks}
              tick={isReversed ? props => <ReversedTicks {...props} /> : true}
            />
            <Tooltip
              content={(props: TooltipProps) => (
                <LineChartTooltip
                  primaryKey={isReversed ? 'value' : dataKey}
                  primaryLabel="Score Value:"
                  {...props}
                />
              )}
              cursor={false}
              allowEscapeViewBox={{ x: false, y: true }}
            />
            {referenceLineData && (
              <ReferenceLine isFront strokeWidth={1} x={referenceLineData} stroke="gray">
                <Label value="SimilarWeb" position="insideTopLeft" fill="#C2CFE0" />
                <Label value="Alexa" position="insideTopRight" fill="#C2CFE0" />
              </ReferenceLine>
            )}
            <Area
              type="monotone"
              dataKey={dataKey}
              stroke="#1890FF"
              strokeWidth="2px"
              fillOpacity={1}
              fill="url(#blueGradient)"
              dot={{ fill: '#fff', strokeWidth: 1, r: 4 }}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </Styles.LineChartWrapper>
  );
};

export default LineChart;
