import React from 'react';
import Overflow from 'rc-overflow';
import { TagProps } from 'antd/lib/tag';
// models
import { ListTag } from '@optx/models/tags';
// constants
import appRoutes from '@constants/routes';
// styles
import ListTagStyled from '@features/tags/tags/ListTag.styles';

interface ListTagValueeProps extends TagProps {
  item: ListTag;
  isNewTab?: boolean;
}

const ListTagValue: React.FC<ListTagValueeProps> = ({ item, isNewTab, ...props }) => {
  // when a tag is clicked
  const handleTagClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    window.open(appRoutes.userLists.list.replace(':id', item.list_id as string), '_blank');
  };

  return (
    <Overflow.Item component="span">
      <ListTagStyled color="cyan" onClick={handleTagClick} {...props}>
        {item.title}
      </ListTagStyled>
    </Overflow.Item>
  );
};

export default ListTagValue;
