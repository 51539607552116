import { createSelector } from 'reselect';
// models
import { AppState } from '@optx/redux/interfaces';
// redux
import { getIsAdminOrAnalyst } from '@optx/redux/user/information/selectors';

const getTouchState = (state: AppState) => state.ui.modals.companyTouches;

export const shouldInitialFetch = createSelector(getTouchState, state => state.shouldInitialFetch);

export const isOpen = createSelector(getTouchState, state => state.open);

export const getPsgParticipants = createSelector(getTouchState, state =>
  state.psgParticipants.map(item => ({
    value: item.value,
    label: item.label,
    is_et_user: item.is_et_user,
    has_psg_email: item.has_psg_email,
    optx_id: item.optx_id,
  }))
);
export const getTouchCompany = createSelector(getTouchState, state => state.company);

export const getTouchFilters = createSelector(
  getTouchState,
  getIsAdminOrAnalyst,
  (state, getIsAdminOrAnalyst) => {
    const filters = state.filters;

    if (filters !== null) {
      const touchesTypesData = filters!.touches_types.data;

      const mappedData = touchesTypesData.filter((item: any) => {
        if (!getIsAdminOrAnalyst && item.name === 'Document Upload') {
          return false;
        }

        return item;
      });

      return { ...filters, touches_types: { ...filters!.touches_types, data: mappedData } };
    }

    return {};
  }
);

export const getTouchFinancials = createSelector(getTouchState, state => state.financials);

export const editTouch = createSelector(getTouchState, state => state.editTouch);

export const getModalTitle = createSelector(getTouchState, state => state.modalTitle);

export const getDisableSaveBtnFor = createSelector(getTouchState, state => state.disableSaveBtnFor);

export const getDisableSaveBtnByAdditionalFields = createSelector(
  getTouchState,
  state => state.disableSaveBtnByAdditionalFields
);

export const getAdditionalFieldsError = createSelector(
  getTouchState,
  state => state.additionalFieldsError
);

export const getTouchCompanyRank = createSelector(
  getTouchState,
  state => state.company.pipeline_rank
);

export const getTouchCompanyStage = createSelector(getTouchState, state => state.company.stage);
export const getTouchCompanyStageRationale = createSelector(
  getTouchState,
  state => state.company.stage_rationale
);

export const getTouchCompanyOwner = createSelector(
  getTouchState,
  state => state.company.company_owner
);

export const getTouchCompanyContacts = createSelector(
  getTouchState,
  state => state.company.contacts
);

export const hasCompanyCard = (state: AppState) => {
  // @ts-ignore
  if (state['company-card']) {
    return true;
  } else {
    return false;
  }
};

export const hasFinancialsLoaded = createSelector(
  getTouchState,
  state => state.hasFinancialsLoaded
);
export const getInitialFinancialState = createSelector(
  getTouchState,
  state => state.initialFinancialState
);

export const getNoErrorsOnSubmitState = createSelector(getTouchState, state => {
  return (
    state.validationErrors.noTouchErrors &&
    state.validationErrors.noEditFieldErrors &&
    state.validationErrors.noFinancialErrors
  );
});
