import { useDispatch } from 'react-redux';
import React, { useEffect, useState, ChangeEvent, useMemo } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Button, Checkbox, Tooltip, Typography, Input, Space } from 'antd';
import styled from 'styled-components';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// components
import EditPopover from '@optx/components/common/popover/EditPopover';
// redux
import { actions } from '@redux/company/opportunity-presentation';

const { Text } = Typography;

const Error = styled(Text)`
  position: absolute;
  bottom: 70px;
  left: 16px;
`;

interface EditFieldCheckboxTextProps {
  companyId: number;
  checkValue: boolean | null;
  textValue: string | undefined;
  checkboxKey: string;
  textInputKey: string;
  isCheckbox?: boolean;
  isFromHistory?: boolean;
}

const EditFieldCheckboxText: React.FC<EditFieldCheckboxTextProps> = ({
  companyId,
  checkValue,
  textValue,
  checkboxKey,
  textInputKey,
  isCheckbox,
  isFromHistory,
}) => {
  const dispatch = useDispatch();

  const [closePopup, setClosePopup] = useState(false);
  const [checked, setChecked] = useState<boolean>(!!checkValue);
  const [fieldValue, setFieldValue] = useState<string | undefined>(textValue ?? undefined);

  const label = useMemo(() => {
    if ((isCheckbox && checkValue === null) || (!isCheckbox && textValue === undefined)) {
      return DEFAULT_EMPTY_VALUE;
    } else if (isCheckbox && checkValue === true) {
      return 'Yes';
    } else if (isCheckbox && checkValue === false) {
      return 'No';
    }

    return textValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textValue, checkValue]);

  const disabledSave = useMemo(() => {
    const conditionChecked =
      checked &&
      (!fieldValue ||
        (checked === checkValue && textValue === fieldValue) ||
        textValue === fieldValue);

    const conditionNotChecked = !checked && !textValue;

    return conditionChecked || conditionNotChecked;
  }, [checked, fieldValue, checkValue, textValue]);

  useEffect(() => {
    setChecked(!!checkValue);
  }, [checkValue]);

  useEffect(() => {
    setFieldValue(textValue);
  }, [textValue]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(event.target.value);
  };

  const handleCheckbox = (e: CheckboxChangeEvent) => {
    const value = e.target.checked;

    if (!value) {
      setFieldValue('');
    }

    setChecked(value);
  };

  const handleCancel = () => {
    setFieldValue(textValue);
    setChecked(checkValue ?? false);
    setClosePopup(true);
  };

  const handleSave = () => {
    if (fieldValue !== textValue || checked !== checkValue) {
      const payload = {
        company_id: companyId,
        deal_preparation: {
          [checkboxKey]: checked,
          [textInputKey]: fieldValue || null,
        },
      };
      dispatch(actions.updateOpportunityPresentation(payload, isFromHistory));
    }

    setClosePopup(true);
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  const content = (
    <Space direction="vertical">
      <Checkbox checked={checked} onChange={handleCheckbox}>
        Banker
      </Checkbox>
      <div style={{ width: '300px' }} onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
        <Text>Name of banker</Text>

        <Input
          type="text"
          placeholder="Enter text"
          onChange={handleChange}
          value={fieldValue as string | undefined}
          disabled={!checked}
          style={{ marginBottom: '10px' }}
        />
        {checked && !fieldValue && <Error type="danger">Input a banker name</Error>}
        <div className="profile-information__popover-buttons">
          <Button className="profile-information__cancel" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="profile-information__save"
            type="primary"
            onClick={handleSave}
            disabled={disabledSave}
          >
            Save
          </Button>
        </div>
      </div>
    </Space>
  );

  return (
    <EditPopover
      content={content}
      title="Banker"
      closePopup={closePopup}
      onVisibilityChange={handleCancel}
      setClosePopup={setClosePopup}
      hidePenIcon={isFromHistory ? true : false}
    >
      {isFromHistory ? (
        'Edit Field'
      ) : isCheckbox ? (
        label
      ) : (
        <Tooltip title={textValue}>
          <span>{label}</span>
        </Tooltip>
      )}
    </EditPopover>
  );
};

export default React.memo(EditFieldCheckboxText);
