import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// models
import { FilterTag } from '@optx/models/tags';
// components
import { FilterTagsOverflow } from '@optx/features/tags/overflow-tags';
// constants
import { pipelineCriteria } from '../../constants/custom-criteria';
// redux
import { selectors } from '../../state';
import * as actions from '../../state/filters/actions';

/**
 * Check if tag is editable.
 * Handle situation when only analyst is showed as an option in analysts multiselect and
 * user is not allowed to uncheck the option.
 * @param filterColumn filter key.
 * @param allowAnalystsEditing showld analysts selection be editable.
 */
const isTagEditable = (filterColumn: string, allowAnalystsEditing: boolean) => {
  if (filterColumn !== 'analyst_id') {
    return true;
  }

  return allowAnalystsEditing;
};

const FilterTags = () => {
  const dispatch = useDispatch();
  const options = useSelector(selectors.filters.getTags);
  const allowAnalystsEditing = useSelector(selectors.filters.allowAnalystsEditing);

  const handleFilterRemove = (tag: FilterTag) => () => {
    let criteria: string | undefined;

    if (tag.filter === 'previous_period' || tag.filter === 'pipeline_companies_rank') {
      criteria = pipelineCriteria;
    }

    dispatch(actions.clearFilter({ filterKey: tag.filter, criteria }));
  };

  const filteredOptions = useMemo(
    () => options.filter(item => !item.isDefault && item.values?.length > 0),
    [options]
  );

  // Don't return null or anything prior to this return. The ref will be lost and max displayed index logic won't work.
  return (
    <FilterTagsOverflow
      options={filteredOptions}
      onClose={handleFilterRemove}
      closable={(item: FilterTag) => isTagEditable(item.filter, allowAnalystsEditing)}
      isFullWidth
      multipleValue
    />
  );
};

export default FilterTags;
