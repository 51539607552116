import React, { useCallback } from 'react';
import { Modal } from 'antd';
import { Dictionary } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
// models
import { PreselectedFilter } from '@optx/models/filters';
// utils
import validationSchema from '@utils/filtersValidationSchema';
import { normalizeFilter } from '@optx/utils/filters/filterHelpers';
// redux
import { SuccessErrorCallback } from '@optx/models/callback';
import { selectors as profileSelectors } from '@redux/company/profile';
import {
  selectors as addonManagementSelectors,
  actions as addonManagementActions,
} from '@redux/company/addon-management';
import { selectors as companyFiltersSelectors } from '@redux/company/filters';
import { selectors as modalSelectors, actions as modalActions } from '@redux/ui/modals/gridModals';
import { actions as searchKeyActions } from '@features/grid/searchkey';
import { selectors as filterSelectors } from '@features/grid/filter';
// components
import useSaveViewMessage from '@hooks/filters/useSaveViewMessage';
import FiltersModalContent from './FiltersModalContent';

const FiltersModal = () => {
  const dispatch = useDispatch();
  const companyId = useSelector(profileSelectors.getCompanyId);

  const onSearch = useCallback(
    (searchKey: string, filter: Dictionary<PreselectedFilter>) => {
      if (companyId !== undefined) {
        dispatch(addonManagementActions.setSearchKey(searchKey));
        dispatch(searchKeyActions.setSearchKey({ data: searchKey, gridKey: 'addons' }));
        dispatch(addonManagementActions.setSelectedFilters(filter));
        dispatch(
          addonManagementActions.fetchCompanyAddonManagement({
            companyId: companyId,
            shouldResetPageNumber: true,
            filter,
          })
        );
      }
    },
    [dispatch, companyId]
  );

  const filter = useSelector(filterSelectors.getFilter('addons'));
  const keySearch = useSelector(addonManagementSelectors.getSearchKey);
  const filterSources = useSelector(companyFiltersSelectors.getCompanyFilters);
  const defaultFilters = useSelector(companyFiltersSelectors.getDefaultFilters);

  const clearSearch = useCallback(
    (callback: SuccessErrorCallback) => {
      dispatch(addonManagementActions.clearSearch(undefined, callback));
    },
    [dispatch]
  );

  // modal
  const modalIsOpen = useSelector(modalSelectors.isOpen('addons'));
  const toggleModalFilters = () => dispatch(modalActions.toggleModal({ gridKey: 'addons' }));
  useSaveViewMessage(modalIsOpen && !!Object.keys(defaultFilters).length, toggleModalFilters);

  const handleSearch = (key: string, filter: Dictionary<PreselectedFilter>) => {
    const normalizedFilter = normalizeFilter(filter);
    onSearch(key, normalizedFilter);
    toggleModalFilters();
  };

  return (
    <Modal
      className="ant-modal-full ant-modal--filters"
      title={null}
      visible={modalIsOpen}
      footer={null}
      onCancel={toggleModalFilters}
      closable={false}
      destroyOnClose
    >
      <FiltersModalContent
        filter={filter}
        filterSources={filterSources}
        keySearch={keySearch}
        onClearFilter={clearSearch}
        onSearch={handleSearch}
        validationSchema={validationSchema}
        onClose={toggleModalFilters}
        modalIsOpen={modalIsOpen}
      />
    </Modal>
  );
};

export default FiltersModal;
