import moment from 'moment';
import { Card } from 'antd';
// models
import { TouchType } from '@models/company/CompanyTouch';
// constants
import globalConfig from '@optx/constants/config';
// utils
import { getDateColor } from '@utils/text';
// components
import TouchIcon from '@optx/features/company-touches/components/TouchIcon';
import { MultipleDate } from '@components/common/MultipleDate';

interface IndicatorProps {
  label: string;
  type?: TouchType | TouchType[];
  value: string | string[];
  lastActivity?: string;
}

const Indicator = ({ label, type, value, lastActivity }: IndicatorProps) => {
  return (
    <Card className="relationship-summary">
      <div className="relationship-summary__title">{label}</div>
      <div className="d-flex align-items-center justify-content-between">
        {type ? (
          <div
            className="relationship-summary__value-date relationship-summary__value"
            style={{
              color: `${getDateColor(
                typeof value === 'string' ? value : value[0],
                label === 'Next Schedule Touch'
              )}`,
            }}
          >
            {label === 'Next Schedule Touch' ? (
              <MultipleDate
                dates={value as string[]}
                format={globalConfig.grid_date.DATE_FORMAT}
                additionalParameter={type as TouchType[]}
                emptyValue={'None'}
              />
            ) : (
              moment(value).format(globalConfig.grid_date.DATE_FORMAT)
            )}
          </div>
        ) : (
          <div className="relationship-summary__value">{value ? value : 'None'}</div>
        )}
        {(label === 'Next Schedule Touch' ? (type as TouchType[])[0] : type) && (
          <TouchIcon
            type={typeof type === 'string' ? type : (type as TouchType[])[0]}
            color="#000000"
          />
        )}
      </div>
      {lastActivity && (
        <div className="relationship-summary__sub-info">
          <span className="sub-info-title">Last Activity:</span>{' '}
          <span className="sub-info-value">
            {moment(lastActivity).format(globalConfig.grid_date.DATE_FORMAT)}
          </span>
        </div>
      )}
    </Card>
  );
};

export default Indicator;
