import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { Dictionary, each } from 'lodash';
import queryString from 'query-string';
// constants
import {
  UserInformation,
  UserSettingsType,
  UserSessionType,
  UserSettings,
  UserSessionSettings,
  RegionOptions,
} from '@optx/models/user';
import { UpdateUserSettingsPayload } from '@optx/models/api/user';
import globalConfig from '@constants/config';
import { sessionPropList } from '@constants/user';
// utils
import { dataURItoBlob } from '@utils/string';
// models
import { SuccessErrorCallback } from '@optx/models/callback';
import { actions as eqtActions } from '@redux/company/equity-touch';
import { UserInformationState } from './interfaces';
// redux
import * as actions from './actions';
import { actions as profileImageActions } from '../profile-image';
import { actions as regionOptionsActions } from '../region-settings';

import { fetchReducer, fetchFailReducer } from '../../feature/fetch/reducers';

export const initialState: UserInformationState = {
  data: null,
  authorizedComponents: {},
  regionOptions: {
    settings: [],
    loading: false,
    fetchedAt: null,
    error: '',
  },
  loading: false,
  error: '',
  fetchedAt: null,
  picture: '',
};

const fetchUserInformationSuccessReducer: CaseReducer<
  UserInformationState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const { components, settings } = action.payload;

  draftState.data = action.payload;
  draftState.loading = false;
  draftState.fetchedAt = new Date().toISOString();

  draftState.authorizedComponents = components.reduce<Dictionary<boolean>>(
    (acumulator, componentType) => {
      const newAcumulator = acumulator;
      newAcumulator[componentType] = true;

      return newAcumulator;
    },
    {}
  );

  // update user image
  const pictureBase64 = action.payload.profile.picture;
  const imageBlob = dataURItoBlob(pictureBase64);

  if (imageBlob) {
    window.URL.revokeObjectURL(draftState.picture);
    draftState.picture = window.URL.createObjectURL(imageBlob);
  }

  // update global config

  if (
    settings.region_settings &&
    (settings.region_settings.short_date || settings.region_settings.grid_date)
  ) {
    globalConfig.short_date.DATE_FORMAT = settings.region_settings.short_date.date_format;
    globalConfig.grid_date.DATE_FORMAT = settings.region_settings.grid_date.date_format;
  }

  // Show my companies cards if user didn't yet used hide button to update API.
  if (
    draftState.data?.settings?.show_my_companies_cards &&
    typeof settings.show_my_companies_cards !== 'boolean'
  ) {
    draftState.data.settings.show_my_companies_cards = true;
  }

  // restore default filter values if new curated List is opened from profile list tab
  const search = window.location.search;
  const path = window.location.pathname;

  if (search.length && (path.startsWith('/ss-list') || path.startsWith('/user-lists'))) {
    const prevFilters = settings.session_settings.user_list_filters;
    const listId = path.split('/')[2];
    const isSameAsCurrentList = prevFilters?.indexOf(listId) !== -1;

    if (!isSameAsCurrentList) {
      const normalizedPrevFilters = prevFilters
        ? queryString.parse(prevFilters, { arrayFormat: 'comma' })
        : '';

      if (
        normalizedPrevFilters &&
        (normalizedPrevFilters.saved_list_id || normalizedPrevFilters.ss_list_id)
      ) {
        const filterKey = normalizedPrevFilters.saved_list_id ? 'saved_list_id' : 'ss_list_id';
        const filterKeysToRemove = Object.keys(normalizedPrevFilters).filter(
          key => key !== filterKey
        );

        filterKeysToRemove.forEach(e => delete normalizedPrevFilters[e]);
        draftState.data.settings.session_settings.user_list_filters =
          queryString.stringify(normalizedPrevFilters);
      }
    }
  }
};

// fetch region settings
const fetchRegionOptionsSuccessReducer: CaseReducer<
  UserInformationState,
  PayloadAction<Array<RegionOptions>>
> = (draftState, action) => {
  draftState.regionOptions.settings = action.payload;
  draftState.regionOptions.loading = false;
  draftState.regionOptions.fetchedAt = new Date().toISOString();
};

const updateUserSettingsReducer: CaseReducer<
  UserInformationState,
  PayloadAction<Partial<UpdateUserSettingsPayload>>
> = (draftState, action) => {
  if (
    action.payload.column_widths_advanced_search ||
    action.payload.column_widths_lists ||
    action.payload.column_widths_my_companies
  ) {
    return;
  }

  draftState.loading = true;
  draftState.error = '';
};

const updateUserSettingsSuccessReducer: CaseReducer<
  UserInformationState,
  PayloadAction<Partial<UpdateUserSettingsPayload>>
> = (draftState, action) => {
  const settings = action.payload;

  // separate user settings from session settings into separate objects
  // and use them to update the store
  const userSettings: Partial<UserSettings> = {};
  const sessionSettings: Partial<UserSessionSettings> = {};

  each(settings, (value, key) => {
    if (sessionPropList.includes(key)) {
      sessionSettings[key as UserSessionType] = value as undefined;
    } else {
      userSettings[key as UserSettingsType] = value as undefined;
    }
  });

  if (userSettings.region_settings?.grid_date || userSettings.region_settings?.short_date) {
    globalConfig.short_date.DATE_FORMAT = userSettings.region_settings.short_date.date_format;
    globalConfig.grid_date.DATE_FORMAT = userSettings.region_settings.grid_date.date_format;
  }

  if (draftState.data) {
    if (draftState.data.settings) {
      draftState.data!.settings! = {
        ...draftState.data!.settings,
        ...userSettings,
      };

      draftState.data!.settings!.session_settings = {
        ...draftState.data!.settings!.session_settings,
        ...sessionSettings,
      };
    }
  }

  draftState.loading = false;
};

const updateProfileImageReducer: CaseReducer<
  UserInformationState,
  PayloadAction<Blob | null, any, SuccessErrorCallback>
> = (draftState, action) => {
  // release the memory for old picture and create new url
  window.URL.revokeObjectURL(draftState.picture);
  draftState.picture = window.URL.createObjectURL(action.payload as Blob);
};

const resetEquityTocuhReducer: CaseReducer<UserInformationState> = draftState => {
  draftState.data!.settings!.session_settings!.et_form_auto_save = null;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.fetchUserInformation, fetchReducer)
    .addCase(actions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
    .addCase(actions.fetchUserInformationFail, fetchFailReducer)
    .addCase(regionOptionsActions.fetchRegionOptionsSuccess, fetchRegionOptionsSuccessReducer)
    .addCase(actions.updateUserSettings, updateUserSettingsReducer)
    .addCase(actions.updateUserSettingsSuccess, updateUserSettingsSuccessReducer)
    .addCase(actions.updateUserSettingsFail, fetchFailReducer)
    .addCase(profileImageActions.updateProfileImage, updateProfileImageReducer)
    .addCase(eqtActions.resetAddToEquityTouch, resetEquityTocuhReducer)
);

export default reducer;
