import React from 'react';
import { Row, Col } from 'antd';
// components
import Card from '@components/common/cards';
import ErrorBoundary from '@optx/components/common/ErrorBoundary';
import TouchesMap from './TouchesMap';
import { TypographyTitle } from '../styled';
import TopTouches from './TopTouches';
import Styles from './AnalystsTouches.styles';

export const AnalystsTouches = () => {
  return (
    <Card $transparent>
      <Row>
        <Col span={24}>
          <TypographyTitle level={4}># of Touches Across Region</TypographyTitle>
          <Styles.SecondaryTitle>
            This is the total # of touches across the sourcing group
          </Styles.SecondaryTitle>
        </Col>
      </Row>
      <Styles.Content>
        <Col flex={1}>
          <ErrorBoundary placeholder={<div>Ups, something went wrong with the map!</div>}>
            <TouchesMap />
          </ErrorBoundary>
        </Col>
        <Col>
          <TopTouches />
        </Col>
      </Styles.Content>
    </Card>
  );
};

export default AnalystsTouches;
