// fetch share list info
export const FETCH_SHARE_LIST_INFO = 'FETCH_SHARE_LIST_INFO';
export const FETCH_SHARE_LIST_INFO_SUCCESS = 'FETCH_SHARE_LIST_INFO_SUCCESS';
export const FETCH_SHARE_LIST_INFO_FAIL = 'FETCH_SHARE_LIST_INFO_FAIL';
// edit share list
export const EDIT_SHARE_LIST = 'EDIT_SHARE_LIST';
export const CANCEL_EDIT_SHARE_LIST = 'CANCEL_EDIT_SHARE_LIST';
// share list
// TODO: if later all lists are edited this way we should rename constant to SHARE_LIST.
// right now there is already an action type called SHARE_LIST in user/share
export const SHARE_LIST = 'SHARE_WATCH_LIST';
export const SHARE_LIST_SUCCESS = 'SHARE_WATCH_LIST_SUCCESS';
export const SHARE_LIST_FAIL = 'SHARE_WATCH_LIST_FAIL';
