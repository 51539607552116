import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 0px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
`;

const Label = styled.p`
  margin: 0;
`;

const Styled = {
  Wrapper,
  Label,
};

export default Styled;
