import * as Yup from 'yup';
// models
import { SearchItem } from '@optx/features/combine-search/state/interfaces';

export const validationSchema = (existingNames: string[]) =>
  Yup.object().shape({
    searchName: Yup.string()
      .required('Search name is required!')
      .test('unique-search-name', 'Search name already exists', function (value) {
        return !existingNames.includes(value);
      }),
    first_search_id: Yup.string().required('Please select a search to combine'),
    searchFieldsList: Yup.array().of(
      Yup.object().shape({
        search_id: Yup.string()
          .required('Please select a search to combine')
          .test('is-unique', 'Duplicate search selected', function (value) {
            // @ts-ignore
            // get access to all form values
            const schema = this.options.from[1].value;

            const allSearchIds = schema.searchFieldsList.map((item: SearchItem) => {
              return item.search_id;
            });
            allSearchIds.push(schema.first_search_id);

            // check if we have current select value more than once in the array
            // that means we have duplicate in the form and "false" will trigger validation
            return !(allSearchIds.filter((id: number) => id === Number(value)).length > 1);
          }),
      })
    ),
  });

export default validationSchema;
