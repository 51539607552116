import styled from 'styled-components';
import Icon from '@components/common/Icon';

const EllipsisExpander = styled.button`
  background-color: #eaecef;
  height: 13px;
  border: 0;
  border-radius: 1px;
  color: #444d56;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  height: 12px;
  line-height: 6px;
  padding: 0 5px 5px;
  text-decoration: none;
  vertical-align: middle;
`;

const StickyNoteIcon = styled(Icon)`
  height: rem-calc(15);
`;

export default {
  EllipsisExpander,
  StickyNoteIcon,
};
