import React, { useMemo } from 'react';
import Styles from './StatsCompareAndSource.styled';

type StatsCompareValues = [number, number];

interface StatsCompareAndSourceProps {
  title: string;
  values: StatsCompareValues;
  isPercentage: boolean;
}

const computePercent = (value1: number, value2: number) => (value1 / value2) * 100;

const getFilledPercentages = (firstValue: number, secondValue: number) => {
  let firstValueProgress = 100;
  let secondValueProgress = 100;

  // Compute smallest value percent.
  if (firstValue > secondValue) {
    secondValueProgress = computePercent(secondValue, firstValue);
  } else if (firstValue < secondValue) {
    firstValueProgress = computePercent(firstValue, secondValue);
  } else if (firstValue === 0) {
    // If both are equal to zero then we don't fill percentage.
    firstValueProgress = 0;
    secondValueProgress = 0;
  }

  return [firstValueProgress, secondValueProgress];
};

const StatsCompareAndSource: React.FC<StatsCompareAndSourceProps> = ({
  title,
  values,
  isPercentage = false,
}) => {
  const [mainValue, comparedLabel] = values;
  const comparedValue = Number(comparedLabel.toString().split(' ')[0]);

  const [mainfilledProgress, comparedfilledProgress] = useMemo(
    () => getFilledPercentages(mainValue, comparedValue),
    [comparedValue, mainValue]
  );

  return (
    <Styles.Wrapper>
      <Styles.Title>{title}</Styles.Title>
      <Styles.ComparedValueBlue>{mainValue}</Styles.ComparedValueBlue>
      <Styles.ComparedProgress size="small" percent={mainfilledProgress} showInfo={false} />
      <Styles.MainStatProgress size="small" percent={comparedfilledProgress} showInfo={false} />
      <Styles.BottomWrapper>
        <Styles.MainStatValue>{comparedLabel}</Styles.MainStatValue>
        <Styles.MainStatLabel>From OPTX</Styles.MainStatLabel>
      </Styles.BottomWrapper>
    </Styles.Wrapper>
  );
};

export default StatsCompareAndSource;
