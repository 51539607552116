import moment from 'moment';
import { LabelFormatter, TickFormatterFunction } from 'recharts';
import {
  DateTimePeriod,
  EmailsSentOption,
} from '@optx/models/feature/analystsLeaderboard/EmailStatistics';

type DateRangeValue = [string | null, string | null];

type CreateLabelFormatter = (
  timerPeriod: DateTimePeriod | undefined,
  selectedInterval: DateRangeValue,
  options: Array<EmailsSentOption>
) => LabelFormatter;

/**
 * Create tooltip label formatter.
 * @param timerPeriod type of time period.
 * @param selectedInterval current selected stats date time interval.
 * @param options chart options.
 */
export const createLabelFormatter: CreateLabelFormatter =
  (timerPeriod, selectedInterval, options) => label => {
    switch (timerPeriod) {
      case 'hour': {
        return handleLabelHourFormatter(label as string);
      }

      case 'week': {
        return handleLabelWeekFormatter(label as string, selectedInterval, options);
      }

      case 'month':

      // eslint-disable-next-line no-fallthrough
      case 'year': {
        // December 28, 2020
        return `# of emails sent in ${label}`;
      }

      case 'day': {
        return `# of emails sent ${label}`;
      }

      default:
        return `# of emails on ${label}`;
    }
  };

/**
 * Handle tooltip label hour formatter.
 * @param label label.
 */
function handleLabelHourFormatter(label: string) {
  // February 01, 00:00
  const receivedFormat = 'MMMM DD, HH:mm';
  const date = moment(label, receivedFormat);
  const dayFormat = 'MMMM DD, YYYY';
  const hourFormat = 'HH:mm';
  const endDate = date.clone().add(2, 'hours');

  return `# of emails on ${date.format(dayFormat)} between\n${date.format(
    hourFormat
  )} and ${endDate.format(hourFormat)} hours`;
}

/**
 * Handle tooltip label week formatter.
 * @param label label.
 * @param selectedInterval current selected stats date time interval.
 * @param options chart options.
 */
function handleLabelWeekFormatter(
  label: string,
  selectedInterval: DateRangeValue,
  options: Array<EmailsSentOption>
) {
  // December 28, 2020
  const receivedFormat = 'MMMM DD, YYYY';
  const optionIndex = options.findIndex(option => option.date === label);

  if (optionIndex !== -1) {
    if (optionIndex + 1 === options.length) {
      const dateEndString = moment(selectedInterval[1]).format(receivedFormat);

      return `# of emails between\n${label} and ${dateEndString}`;
    }

    const dateEndString = moment(options[optionIndex + 1].date)
      .subtract(1, 'days')
      .format(receivedFormat);

    return `# of emails between\n${label} and ${dateEndString}`;
  }

  return `# of emails sent ${label}`;
}

/**
 * Create tooltip tick formatter.
 * @param format
 */
export const createTickFormatter: (format: DateTimePeriod | undefined) => TickFormatterFunction =
  format => (value: string) => {
    switch (format) {
      case 'day': {
        // Display only week day.
        // Wed Jan 27 2021
        const receivedFormat = 'ddd MMM DD YYYY';
        const date = moment(value, receivedFormat);
        const chartFormat = 'dd';

        return date.format(chartFormat);
      }

      default:
        break;
    }

    return value;
  };
