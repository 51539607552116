/**
 * Profile page selectors
 */

import { createSelector } from 'reselect';
import { socialMediaSelectors } from '@optx/features/company/social-media';
import { auditSelectors } from '@optx/features/company/audit-trail';
import { selectors as filtersSelectors } from '@redux/company/filters';
import { selectors as profileSelectors } from '@redux/company/profile';
import { selectors as favoritesSelectors } from '@redux/favorites';
import { selectors as touchesFiltersSelectors } from '@redux/company/touches/filters';
import { selectors as touchesSearchSelectors } from '@redux/company/touches/search';
import { selectors as equityTouchSelectors } from '@redux/company/equity-touch';
import { selectors as fundingSelectors } from '@redux/company/funding-rounds';
import { selectors as listsSelector } from '@optx/redux/company/lists';
import { selectors as addonManagementSelectors } from '@redux/company/addon-management';
import { isLoading as searchIsLoading } from '@features/grid/search/state/selectorsReusable';

const loadingNotesTouches = createSelector(
  touchesFiltersSelectors.isLoading,
  touchesSearchSelectors.isLoading,
  (loadingTouchesFilters, loadingTouchesSearch) => loadingTouchesFilters || loadingTouchesSearch
);

export const isLoading = createSelector(
  filtersSelectors.isLoading,
  profileSelectors.isLoading,
  favoritesSelectors.isLoading,
  equityTouchSelectors.isLoading,
  fundingSelectors.isLoading,
  socialMediaSelectors.socialMediaLoading,
  loadingNotesTouches,
  auditSelectors.loading,
  listsSelector.isLoading,
  searchIsLoading('addons'),
  addonManagementSelectors.loadingHistograms,
  (
    loadingFilters,
    loadingProfile,
    loadingFavorites,
    equityTouch,
    funding,
    socialMedia,
    loadingTouches,
    loadingAuditTrail,
    loadingListsTable,
    searchIsLoading,
    histogramIsLoading
  ) => {
    return (
      loadingFilters ||
      loadingProfile ||
      loadingFavorites ||
      equityTouch ||
      funding ||
      socialMedia ||
      loadingTouches ||
      loadingAuditTrail ||
      loadingListsTable ||
      searchIsLoading ||
      histogramIsLoading
    );
  }
);
