import { useDispatch, useSelector } from 'react-redux';
// redux
import { actions as auditTrailActions } from '@features/company/audit-trail/state';
import { selectors as userInformationSelectors } from '@redux/user/information';
// models
import { FieldValue } from '@features/company/audit-trail/state/interfaces';

interface HistoryTabFunction {
  updateHistoryFields: ({ afterValue, beforeValue, fieldChanged }: UpdateFieldsType) => void;
}
interface UpdateFieldsType {
  afterValue: FieldValue;
  beforeValue: FieldValue;
  fieldChanged: string;
  dontShowName?: boolean;
  opinion?: string | null;
}

export const useHistoryTab = (): HistoryTabFunction => {
  const dispatch = useDispatch();
  const fullName = useSelector(userInformationSelectors.getFullName);

  const updateHistoryFields = ({
    afterValue,
    beforeValue,
    fieldChanged,
    dontShowName,
    opinion,
  }: UpdateFieldsType) => {
    dispatch(
      auditTrailActions.editCompanyAuditTrailFields({
        after_value: afterValue,
        before_value: beforeValue,
        field_changed: fieldChanged,
        opinion: opinion ? opinion : undefined,
        user: dontShowName ? null : fullName,
        modified_at: new Date().toISOString(),
        internal: true,
      })
    );
  };

  return { updateHistoryFields };
};
