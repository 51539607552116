import React from 'react';
import { useSelector } from 'react-redux';
// redux
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
// components
import { SelectedCompaniesGrids, SelectedCompaniesKeys } from '@optx/models/bulkActions';
import InProgress from './InProgress';
import Completed from './Completed';
import BulkEditingResults from './BulkEditingResults';

interface BulkEditCompletionProps {
  visibilityChange: (visibility: boolean) => void;
  fieldName: string;
  gridName: SelectedCompaniesGrids;
  editField: SelectedCompaniesKeys;
}

const BulkEditCompletion: React.FC<BulkEditCompletionProps> = ({
  visibilityChange,
  fieldName,
  editField,
  gridName,
}) => {
  const completed = useSelector(bulkActionsSelectors.editFields.isCompleted);

  return (
    <>
      {completed ? (
        <Completed fieldName={fieldName} gridName={gridName} />
      ) : (
        <InProgress visibilityChange={visibilityChange} />
      )}
      {!['remove_from_watchlist', 'is_software', 'is_ai_ml'].includes(editField) ? (
        <BulkEditingResults />
      ) : null}
    </>
  );
};

export default BulkEditCompletion;
