import React from 'react';
import { AdditionalStatisticsItems } from '@optx/models/feature/analystsLeaderboard/AdditionalStatistics';
import { formatDate } from '@optx/utils/format';
import Styles from './StatsPresentation.styles';

interface StatsPresentationProps {
  title?: string;
  items: AdditionalStatisticsItems | undefined;
  isDateTitle?: boolean;
}

const StatsPresentation: React.FC<StatsPresentationProps> = ({
  title,
  items,
  isDateTitle = true,
}) => {
  let formatedTitle = '';

  if (title && isDateTitle) {
    formatedTitle = title
      .split('-')
      .map(date => formatDate(date, 'shortDate'))
      .join(' - ');
  }

  if (!isDateTitle) {
    formatedTitle = title as string;
  }

  return (
    <Styles.Wrapper>
      <Styles.SubSectionWrapper>
        <Styles.Title>{formatedTitle}</Styles.Title>
      </Styles.SubSectionWrapper>
      <Styles.SubSectionWrapper>
        {items?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <Styles.SubTitle>{item.label}</Styles.SubTitle>
              <Styles.MainStatValue>{item.value}</Styles.MainStatValue>
            </React.Fragment>
          );
        })}
      </Styles.SubSectionWrapper>
    </Styles.Wrapper>
  );
};

export default StatsPresentation;
