import React from 'react';
import numeral from 'numeral';
// models
import { SourceScrubList } from '@optx/models/SourceScrubList';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
// components
import CellLink from '../CellLink';

interface TableCellDefaultTooltipProps {
  value: any;
  row: SourceScrubList;
}

const TableCellDefaultTooltip: React.FC<TableCellDefaultTooltipProps> = ({ value, row }) => {
  const formattedVal = value ? numeral(value).format('0,0') : DEFAULT_CELL_VALUE;

  return <CellLink id={row.id?.toString()}>{formattedVal}</CellLink>;
};

export default TableCellDefaultTooltip;
