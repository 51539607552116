import React from 'react';
import { LoadIcon32 } from '../../LoadingIcon';
import { Styled } from './Loader.styles';

interface FixedLoaderProps {
  loading?: boolean;
  isAbsolute?: boolean;
}

const FixedLoader: React.FC<FixedLoaderProps> = ({ loading, children, isAbsolute = false }) => {
  const isChromePlugin = window.location.pathname.startsWith('/chrome-extension-new');

  if (loading) {
    return (
      <Styled.Loader
        className={isAbsolute ? 'loading' : 'loading fixed'}
        isChromePlugin={isChromePlugin}
      >
        {children || <LoadIcon32 />}
      </Styled.Loader>
    );
  }

  return null;
};

export default FixedLoader;
