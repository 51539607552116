export const CompanyProfileTabs = {
  PROFILE_OVERVIEW: '1',
  RELATIONSHIP_MANAGEMENT: '2',
  PROPRIETARY_INFO: '3',
  ADDON_MGMT: '4',
  LISTS: '5',
  SOCIAL_MEDIA: '6',
  HISTORY: '7',
  FILES: '8',
  NEWS_FEED: '9',
};

export const ADMIN_ROUTES = {
  users: '/admin/users',
};

export const USER_ROUTES = {
  profile: '/user/profile',
};

export const LISTS_ROUTES = {
  myWatchList: '/lists',
  companySavedSearches: '/lists/company-searches',
  suggestedSearches: '/lists/suggested-searches',
  sourceScrubLists: '/lists/source-scrub-lists',
  contactSearches: '/lists/saved-searches',
};

export const ALL_REPORTS = {
  allReports: '/all-reports',
  sourcingAnalystsLeaderboard: '/all-reports/analysts-leaderboard',
  checklistResearchInsights: '/all-reports/checklist-research-insights',
  equityTouchIntegration: '/all-reports/admin/etintegration',
  pipelineReport: '/all-reports/admin/pipeline-report',
  sourcingOutreachSummary: '/all-reports/sourcing-outreach-summary',
  sourcingOutreachSummaryCompanies: '/all-reports/sourcing-outreach/companies-list',
};

export const DEFAULT_REPORTS = {
  allReports: '/all-reports',
  sourcingAnalystsLeaderboard: '/all-reports/analysts-leaderboard',
  checklistResearchInsights: '/all-reports/checklist-research-insights',
  sourcingOutreachSummary: '/all-reports/sourcing-outreach-summary',
  sourcingOutreachSummaryCompanies: '/all-reports/sourcing-outreach/companies-list',
  pipelineReport: '/all-reports/admin/pipeline-report',
};

export const USER_LISTS_ROUTES = {
  list: '/user-lists/:id',
  sourceScrubLists: '/ss-lists/:id',
};

const ROUTES = {
  admin: ADMIN_ROUTES,
  contacts: '/contacts',
  home: '/',
  lists: LISTS_ROUTES,
  advancedSearch: '/search',
  analystHome: '/analyst-home',
  login: '/login',
  notes: '/notes',
  user: USER_ROUTES,
  myCompanies: '/my-companies',
  profile: '/profile/:id',
  reports: ALL_REPORTS,
  defaultReports: DEFAULT_REPORTS,
  userLists: USER_LISTS_ROUTES,
  addToEquityTouch: '/add-to-equity-touch/:id',
  addToSalesloft: '/add-to-salesloft/:id',
  chromeExtension: '/chrome-extension-new',
  scheduledTouches: '/scheduled-touches',
};

export default ROUTES;
