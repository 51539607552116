import React, { useMemo, Fragment } from 'react';
import { CardInfo, CardInfoSection } from '@optx/models/feature/analystsLeaderboard/CarfInfo';
import Styled from './Info.styled';

interface InfoProps {
  info: CardInfo;
  className?: string;
}

const mapInfoSection = (section: CardInfoSection, index: number) => {
  return (
    <Fragment key={index}>
      <Styled.PopoverContentTitle>{section.title}</Styled.PopoverContentTitle>
      <Styled.PopoverContentDescription>{section.description}</Styled.PopoverContentDescription>
    </Fragment>
  );
};

const Info: React.FC<InfoProps> = ({ info, ...restProps }) => {
  const content = useMemo(() => info.content.map(mapInfoSection), [info]);

  return (
    <Styled.Popover
      align={{
        offset: [15, -55],
      }}
      arrowPointAtCenter
      placement="rightTop"
      title={<Styled.PopoverTitle>{info.title}</Styled.PopoverTitle>}
      content={content}
    >
      <Styled.Icon {...restProps} />
    </Styled.Popover>
  );
};

export default Info;
