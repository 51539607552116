import React, { useCallback, useState } from 'react';
import { Typography } from 'antd';
import { Pie, Sector, Legend, ResponsiveContainer, PieChart } from 'recharts';
import ChartStyle from './PieChartWithHover.styled';

const RADIAN = Math.PI / 180;

interface PieChartWithHoverDataType {
  label: string;
  percentage: string | number;
  company_count: number;
}

interface PieChartWithHoverProps {
  title: string;
  data: PieChartWithHoverDataType[];
  dataKey?: string;
  total: string;
}

const PieChartWithHover: React.FC<PieChartWithHoverProps> = ({
  title,
  data,
  dataKey = 'value',
  total,
}) => {
  const [activeIndex, setActiveIndex] = useState(NaN);

  const chartData = React.useMemo(() => {
    let filteredData = data.filter(item => item.company_count !== 0);
    let color = '#8884d8';

    if (filteredData.length === 0) {
      filteredData = data;
      filteredData.push(
        ...[
          { label: 'Empty', percentage: 100, company_count: 1 },
          { label: '', percentage: 0, company_count: 0 },
        ]
      );
      color = '#ddd';
    } else {
      filteredData = data;
      filteredData.push({ label: '', percentage: 0, company_count: 0 });
    }

    setActiveIndex(filteredData.length - 1); // need it cause display total label

    return { data: filteredData, color };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onPieEnter = useCallback((_, index) => setActiveIndex(index), [setActiveIndex]);

  const resetActiveIndex = () => setActiveIndex(chartData.data.length - 1);

  const renderActiveShape = (props: any) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      percent,
      endAngle,
      fill,
      label,
      midAngle,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * cos;
    const y = cy + radius * sin;
    const isSmallPercentage = percent * 100 < 8;

    const mx = cx + (outerRadius + 5) * cos;
    const my = cy + (outerRadius + 13) * sin;
    const dx = x > cx ? -7 : -15;
    let ex = mx + (cos >= 0 ? 1 : -1) * 1;
    const ey = my + (cos >= 0 ? 1 : -4) * 1;
    const textAnchor = cos >= 0 ? 'start' : 'start';

    if (isSmallPercentage && ex < 180) ex -= 17;

    return (
      <g>
        {label !== '' && (
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius + 6}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
        )}
        <text
          x={isSmallPercentage ? ex : x - 2.5}
          dx={isSmallPercentage ? 0 : dx}
          y={isSmallPercentage ? ey : y}
          fontSize={11.5}
          fontWeight={500}
          fill={isSmallPercentage || label === 'Empty' ? 'black' : 'white'}
          textAnchor={textAnchor}
          dominantBaseline="central"
        >
          {label !== '' && `${(percent * 100).toFixed(0)}%`}
        </text>
        <text
          x={cx}
          y={cy}
          dy={-3}
          textAnchor="middle"
          fontSize={22}
          color="#171721"
          fontWeight={700}
        >
          {label === 'Empty' ? '$0' : total}
        </text>
        <text
          x={cx}
          y={cy}
          dy={18}
          textAnchor="middle"
          color="#8083A3"
          fontSize={12}
          fontWeight={500}
        >
          total
        </text>
      </g>
    );
  };

  const renderLegendComponent = (props: any) => {
    const { payload } = props;

    return (
      <ChartStyle.LegendGroup>
        <Typography.Title level={5}>{title}</Typography.Title>
        <ChartStyle.LegendWrapper>
          {payload.map(
            (entry: any, index: number) =>
              entry.payload.label !== 'Empty' &&
              entry.payload.label !== '' && (
                <ChartStyle.LegendItem
                  key={index}
                  isDisable={entry.payload.company_count === 0}
                  onMouseEnter={() => entry.payload.company_count !== 0 && setActiveIndex(index)}
                  onMouseLeave={resetActiveIndex}
                  isActive={activeIndex === index}
                >
                  <div
                    style={{
                      background: entry.payload.company_count !== 0 ? entry.payload.fill : '#ddd',
                    }}
                  />
                  <span>{entry.payload.label}</span>
                </ChartStyle.LegendItem>
              )
          )}
        </ChartStyle.LegendWrapper>
      </ChartStyle.LegendGroup>
    );
  };

  return (
    <ResponsiveContainer height={220}>
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={chartData.data}
          width={220}
          height={220}
          cx="50%"
          innerRadius={55}
          outerRadius={80}
          fill={chartData.color}
          dataKey={dataKey}
          onMouseEnter={onPieEnter}
          onMouseLeave={resetActiveIndex}
          paddingAngle={0}
        />
        <Legend
          width={330}
          height={220}
          layout="vertical"
          verticalAlign="middle"
          align="right"
          content={renderLegendComponent}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartWithHover;
