import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useCallback } from 'react';
import uiReducer from '../../../redux/ui';
import {
  saga,
  actions as userInvitationModalActions,
  selectors as userInvitationModalSelectors,
} from '../../../redux/ui/modals/user-invitation';

export const useUserInvitationModal = (): [boolean, VoidFunction] => {
  useInjectReducer({
    key: 'ui',
    reducer: uiReducer,
  });
  const open = useSelector(userInvitationModalSelectors.userInvitationDialog);
  const dispatch = useDispatch();
  const toggle = useCallback(
    () => dispatch(userInvitationModalActions.toggleUserInvitationDialog()),
    [dispatch]
  );

  return [open, toggle];
};

export const useSendUserInvitation = () => {
  useInjectSaga({
    key: 'userInviteSaga',
    saga,
  });
  const dispatch = useDispatch();
  const sendUserInvite = useCallback(
    payload => dispatch(userInvitationModalActions.sendUserInvite(payload)),
    [dispatch]
  );

  return sendUserInvite;
};
