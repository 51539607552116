import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { Form, Input } from 'formik-antd';
import { Button, Col, Typography } from 'antd';
import { isEqual } from 'lodash';
// models
import { BaseField } from '@optx/models/companyFields';
import { FieldUsedFor } from './interface';
//constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { checkSaveButton } from '@optx/utils/proprietaryInfo';
import { handleDropdownKeyEvent } from '@optx/utils/handleDropdownKeyEvent';
// components
import EditPopover from '@optx/components/common/popover/EditPopover';

const { TextArea } = Input;

interface TextareaFieldProps {
  field?: BaseField;
  value?: string | null;
  characterLimit?: number;
  fieldName?: string;
  fieldType?: string;
  onSave?: (fieldType?: string) => void;
  onCancel?: () => void;
  fieldUsedFor?: FieldUsedFor;
}

const TextareaField: React.FC<TextareaFieldProps> = ({
  field,
  fieldName,
  fieldType,
  value,
  characterLimit,
  onCancel,
  onSave,
  fieldUsedFor,
}) => {
  const [selected, , setSelected] = useField<string>(field?.id || fieldType || '');
  const [closePopup, setClosePopup] = useState(false);

  useEffect(() => {
    if (field?.value && fieldUsedFor !== 'editAllInfo') {
      if (!isEqual(field.value, selected.value)) {
        setSelected.setValue(field.value);
      }
    }

    if (value) {
      setSelected.setValue(value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field?.value, value]);

  const handleCancel = () => {
    onCancel && onCancel();
    setClosePopup(true);
  };

  const handleVisibilityChange = (visibility: boolean) => {
    if (value && visibility) {
      setSelected.setValue(value);
    }

    if (!value && visibility) {
      setSelected.setValue('');
    }
  };

  if (field) {
    return (
      <Col span={24} className={field.id}>
        <Form.Item name={field.id}>
          <Typography.Title>{field.label}</Typography.Title>
          <TextArea
            rows={3}
            name={field.id}
            placeholder={field.placeholder}
            disabled={!field.editable}
            value={selected.value ?? ''}
            maxLength={characterLimit}
            fast
          />
        </Form.Item>
      </Col>
    );
  }

  const content = (
    <Form.Item name={fieldType ?? ''}>
      <div
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
          handleDropdownKeyEvent(event, setClosePopup, onSave, fieldType, true)
        }
        tabIndex={0}
      >
        <Typography.Text>Edit "{fieldName}" value</Typography.Text>
        <TextArea
          maxLength={characterLimit}
          value={selected.value ?? ''}
          rows={3}
          name={fieldType ?? ''}
          placeholder="value"
        />
        <div className="profile-information__popover-buttons">
          <Button className="profile-information__cancel" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="profile-information__save"
            type="primary"
            disabled={checkSaveButton(selected.value, value)}
            onClick={() => {
              onSave && onSave(fieldType);
              setClosePopup(true);
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Form.Item>
  );

  return (
    <EditPopover
      closePopup={closePopup}
      customOverlayStyle={{ height: '14rem' }}
      onVisibilityUpdate={handleVisibilityChange}
      setClosePopup={setClosePopup}
      content={content}
    >
      {value ? value : DEFAULT_EMPTY_VALUE}
    </EditPopover>
  );
};

export default React.memo(TextareaField);
