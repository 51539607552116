import { createSelector } from 'reselect';
import { AppState } from '@optx/redux/interfaces';
import createCachedSelector from 're-reselect';
import { selectors as fetchSelectors } from '../../feature/fetch';
import { selectors as filterSelectors } from '../filters';
import { getSavedSearchesSelector, getTitlesSelector } from '../../feature/saved-search/selectors';

// specific selectors
const selectContactsSavedSearchesState = (state: AppState) => state.contactsSavedSearches;

export const isLoading = createSelector(
  selectContactsSavedSearchesState,
  fetchSelectors.isLoadingSelector
);

export const getError = createSelector(
  selectContactsSavedSearchesState,
  fetchSelectors.errorSelector
);

export const getSavedSearches = createSelector(
  selectContactsSavedSearchesState,
  getSavedSearchesSelector
);

export const shouldFetch = createSelector(
  selectContactsSavedSearchesState,
  state => !state.loading && !state.fetchedAt
);

export const shouldInitialFetch = createSelector(
  filterSelectors.fetchedFilters,
  shouldFetch,
  (fetchedFilters, shouldFetch) => fetchedFilters && shouldFetch
);

export const getSearch = createCachedSelector(
  selectContactsSavedSearchesState,
  (state: AppState, searchId: number | string) => searchId,
  (state, searchId) => state.byId[searchId]
)((state, searchId) => searchId);

export const getTitles = createSelector(selectContactsSavedSearchesState, getTitlesSelector);

export const getTotal = createSelector(selectContactsSavedSearchesState, state => state.total);

export const getQuery = createSelector(selectContactsSavedSearchesState, state => state.query);

export const getPageNumber = createSelector(
  selectContactsSavedSearchesState,
  state => state.pageNumber
);

export const getSavedSearchesById = createSelector(
  selectContactsSavedSearchesState,
  state => state.allIds
);
