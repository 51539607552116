import styled from 'styled-components';

const Wrapper = styled.div`
  width: 300px;
  background: #c2cfe0;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 15%);

  .pie-chart {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0px auto;

    .total {
      position: absolute;
      width: 200px;
      font-size: 14px;
      color: #001529;
      font-weight: 400;
      top: 35%;
      line-height: 30px;
      text-align: center;

      b {
        font-size: 30px;
        font-weight: 600;
        color: #1890ff;
        display: block;
      }
    }
  }

  .chart-legends {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    gap: 10px;

    div.chart-legend {
      min-width: 200px;
      width: 100%;
      max-width: 350px;
      height: 30px;
      display: flex;
    }
    .count {
      text-align: center;
      line-height: 30px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      width: 52px;
      color: white;
      font-weight: 700;
      font-size: 14px;
    }

    .label {
      flex: 1;
      line-height: 30px;
      padding-left: 10px;
      font-size: 12px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      color: #262626;
      font-weight: 600;
    }
  }
`;

const Styled = {
  Wrapper,
};

export default Styled;
