import React, { useState, useEffect } from 'react';
import { Dropdown, Button, Input, Menu } from 'antd';
import './style.scss';
import NotificationService from '@optx/services/NotificationService';

interface DropdownInputProps {
  onToggle: VoidFunction;
  isOpen: boolean;
  title: string;
  name: string;
  isRight: boolean;
  onInputChange?: (value: string) => void;
  onRenameList: (value: string) => void;
}

const DropdownInput: React.FC<DropdownInputProps> = ({
  onToggle,
  isOpen,
  title,
  name,
  isRight,
  onInputChange,
  onRenameList,
}) => {
  const [input, setInput] = useState(title);

  const handleToggle = () => {
    setInput('');
    onToggle();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);

    if (onInputChange) {
      onInputChange(event.target.value);
    }
  };

  const handleRenameList = () => {
    if (input.trim().length === 0) {
      setInput('');
      NotificationService.warn('Enter valid name');
    } else if (input.length > 0) {
      onRenameList(input.trim());
      handleToggle();
    }
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLUListElement>) => {
    if (event.key === 'Enter') {
      handleRenameList();
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setInput(title);
    }
  }, [isOpen, title]);

  const menu = (
    <Menu onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
      <div className="menu-header">
        <span>{name}</span>
      </div>
      <Menu.Item key={1} className="menu-item menu-input">
        <Input
          type="text"
          name="input"
          value={input}
          placeholder="input"
          autoFocus
          onChange={handleInputChange}
        />
      </Menu.Item>
      <div className="menu-footer">
        <Button type="primary" disabled={!input} onClick={handleRenameList}>
          {name}
        </Button>
      </div>
    </Menu>
  );

  return (
    <Dropdown
      overlayClassName="dropdown-input"
      visible={isOpen}
      onVisibleChange={handleToggle}
      overlay={menu}
      trigger={['click']}
      placement="bottomRight"
    >
      <div />
    </Dropdown>
  );
};

DropdownInput.defaultProps = {
  isOpen: false,
  title: '',
  name: '',
  isRight: false,
  onRenameList: () => {},
  onInputChange: () => {},
};

export default DropdownInput;
