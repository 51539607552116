/* eslint-disable no-param-reassign */
import { createSelector } from 'reselect';
import { selectors as localSelectors } from '../../bulk-merge-companies';
import { selectFeatureState } from './selectFeature';

const mergeCompaniesState = createSelector(selectFeatureState, state => {
  return state.mergeCompanies;
});

const getMergeCompaniesStatus = createSelector(mergeCompaniesState, localSelectors.getStatus);

export default { getMergeCompaniesStatus };
