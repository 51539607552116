import styled from 'styled-components';
import { Row } from 'antd';
import { up } from 'styled-breakpoints';
// components
import { SecondaryTitle } from '../styled';

const Content = styled(Row)`
  padding-top: 16px;

  ${up('xl')} {
    padding-top: 45px;
  }
`;

const StyledSecondaryTitle = styled(SecondaryTitle)`
  &.ant-typography {
    margin-bottom: 0;
  }
`;

const Styles = { Content, SecondaryTitle: StyledSecondaryTitle };

export default Styles;
