import { call, put, takeLatest, fork } from 'redux-saga/effects';
import { notifyAction } from '@optx/core/notifications';
import { FilterService } from '@services/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
// models
import { Filter, FilterSource } from '@optx/models/filters';
// constants
import { pipelineCriteria } from '../../constants/custom-criteria';
// redux
import * as actions from '../actions';
import { UpdateValuePayload, FilterMeta } from '../filters/interfaces';
import { isSpecialCriteria } from '../../utils/isSpecialCriteria';

export function* getFiltersSaga() {
  try {
    const res: AxiosResponse<FilterSource<Filter<any>>[]> = yield call(
      FilterService.getAnalystLeaderboardOptions
    );

    yield put(actions.filters.fetchSuccess(res.data));
  } catch (error: any) {
    yield put(notifyAction('error', 'Error fetching analysts leaderboard filters!', { error }));
  }
}

export function* searchSaga() {
  yield put(actions.search.search());
  yield put(actions.touchesAcrossRegion.fetchTouchesAcrossRegion());
}

export function* updateValueSaga(action: PayloadAction<UpdateValuePayload>) {
  if (!isSpecialCriteria(action.payload.criteria)) {
    yield put(actions.search.search());
    yield put(actions.touchesAcrossRegion.fetchTouchesAcrossRegion());
  }
}

export function* clearFilterSaga(action: PayloadAction<FilterMeta>) {
  const { criteria } = action.payload;

  if (criteria !== pipelineCriteria) {
    yield fork(searchSaga);
  }
}

export default function* analystsSaga() {
  yield takeLatest(actions.filters.fetch, getFiltersSaga);
  yield takeLatest(actions.filters.fetchSuccess, searchSaga);
  yield takeLatest(actions.filters.clearAllFilters, searchSaga);
  yield takeLatest(actions.filters.clearFilter, clearFilterSaga);
  yield takeLatest(actions.filters.updateValue, updateValueSaga);
}
