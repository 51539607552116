export const MAX_FILE_SIZE_MB = 15;

export type SUPPORTED_FILE_TYPES =
  | '.doc'
  | '.docs'
  | '.docx'
  | '.pdf'
  | '.pps'
  | '.ppsx'
  | '.ppt'
  | '.pptx'
  | '.txt'
  | '.xls'
  | '.xlsx'
  | '.zip';

export const SUPPORTED_FILE_TYPES_LIST: SUPPORTED_FILE_TYPES[] = [
  '.doc',
  '.docs',
  '.docx',
  '.pdf',
  '.pps',
  '.ppsx',
  '.ppt',
  '.pptx',
  '.txt',
  '.xls',
  '.xlsx',
  '.zip',
];

export const getFileExtension = (fileName: string) => {
  return fileName.substring(fileName.lastIndexOf('.'));
};
