import React from 'react';
// models
import { TouchType } from '@optx/models/company/CompanyTouch';
// components
import TouchIcon from '@optx/features/company-touches/components/TouchIcon';

interface ScheduledTouchItemInfoProps {
  type: TouchType;
  title: 'Scheduled' | 'Past Due' | 'Completed';
}

const colors = {
  Scheduled: '#1890ff',
  'Past Due': '#f5222d',
  Completed: '#13c2c2',
};

const ScheduledTouchItemInfo: React.FC<ScheduledTouchItemInfoProps> = ({ type, title }) => {
  return (
    <div className="scheduled-touch-item-info">
      <TouchIcon className={title.replace(' ', '-').toLowerCase()} type={type} />
      <span style={{ color: colors[title] }}>{title.toUpperCase()}</span>
    </div>
  );
};

export default ScheduledTouchItemInfo;
