import React from 'react';
import { SalesloftBulkResultCellError, SalesloftBulkContactResponse } from '@models/bulkActions';
import { Tooltip, Typography } from 'antd';
import { SalesloftContact } from '@optx/models/Salesloft';

interface ErrorReasonProps {
  contactsResponse: SalesloftBulkContactResponse[];
  sentContacts: SalesloftContact[];
}

export const ErrorReason: React.FC<ErrorReasonProps> = ({ contactsResponse, sentContacts }) => {
  const failedContacts = contactsResponse.filter(contact => !contact.sl_sync_status);

  if (!failedContacts) {
    return null;
  }

  const failedResults: SalesloftBulkResultCellError[] = [];

  failedContacts.forEach(contact => {
    const sentContact = sentContacts.find(
      sentContact => sentContact.person_id === contact.person_id
    );
    failedResults.push({
      name: `${sentContact?.first_name} ${sentContact?.last_name}`,
      error: contact.sl_message as string,
    });
  });

  const title = failedResults.map(result => <div>{`${result.name}: ${result.error}`}</div>);
  const text = failedResults.map(result => `${result.name}: ${result.error}`).join(', ');

  return (
    <Tooltip title={title}>
      <Typography.Text ellipsis style={{ width: '100%' }}>
        {text}
      </Typography.Text>
    </Tooltip>
  );
};

export default ErrorReason;
