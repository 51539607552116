import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// redux
import { HighlightsResponse } from '@optx/models/feature/equity-touch-integration/highlights';
// models
import { HighlightsState } from '../interfaces';
// redux
import * as actions from '../actions/highlights';

const initialState: HighlightsState = {
  etMergeHistory: {
    data: null,
    loading: false,
  },
  etAlltimeHighlights: {
    data: null,
    loading: false,
  },
  timeSpent: {
    data: null,
    loading: false,
  },
  error: '',
};

const etAlltimeHighlightsReducer: CaseReducer<HighlightsState> = draftState => {
  draftState.etAlltimeHighlights.loading = true;
};

const etAlltimeHighlightsSuccessReducer: CaseReducer<
  HighlightsState,
  PayloadAction<HighlightsResponse>
> = (draftState, action) => {
  draftState.etAlltimeHighlights.data = action.payload;
  draftState.etAlltimeHighlights.loading = false;
};

const etAlltimeHighlightsFailReducer: CaseReducer<HighlightsState, PayloadAction<string>> = (
  draftState,
  action
) => {
  draftState.error = action.payload;
  draftState.etAlltimeHighlights.loading = false;
};

const etMergeHistoryReducer: CaseReducer<HighlightsState> = draftState => {
  draftState.etMergeHistory.loading = true;
};

const etMergeHistorySuccessReducer: CaseReducer<
  HighlightsState,
  PayloadAction<HighlightsResponse>
> = (draftState, action) => {
  draftState.etMergeHistory.data = action.payload;
  draftState.etMergeHistory.loading = false;
};

const etMergeHistoryFailReducer: CaseReducer<HighlightsState, PayloadAction<string>> = (
  draftState,
  action
) => {
  draftState.error = action.payload;
  draftState.etMergeHistory.loading = false;
};

const timeSpentReducer: CaseReducer<HighlightsState> = draftState => {
  draftState.timeSpent.loading = true;
};

const timeSpentSuccessReducer: CaseReducer<HighlightsState, PayloadAction<any>> = (
  draftState,
  action
) => {
  draftState.timeSpent.data = action.payload;
  draftState.timeSpent.loading = false;
};

const timeSpentFailReducer: CaseReducer<HighlightsState, PayloadAction<string>> = (
  draftState,
  action
) => {
  draftState.error = action.payload;
  draftState.timeSpent.loading = false;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.etAlltimeHighlightsFetch, etAlltimeHighlightsReducer)
    .addCase(actions.etAlltimeHighlightsSuccess, etAlltimeHighlightsSuccessReducer)
    .addCase(actions.etAlltimeHighlightsFail, etAlltimeHighlightsFailReducer)
    .addCase(actions.etMergeHistoryFetch, etMergeHistoryReducer)
    .addCase(actions.etMergeHistorySuccess, etMergeHistorySuccessReducer)
    .addCase(actions.etMergeHistoryFail, etMergeHistoryFailReducer)
    .addCase(actions.timeSpentFetch, timeSpentReducer)
    .addCase(actions.timeSpentSuccess, timeSpentSuccessReducer)
    .addCase(actions.timeSpentFail, timeSpentFailReducer)
);

export default reducer;
