import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const LoadingIcon = styled(LoadingOutlined)`
  font-size: 42px;
  height: 42px;
  color: #4ce1b6;
`;

const LoadingIconWrapper = styled.div`
  height: 180px;
  display: flex;
  justify-content: center;
  vertical-align: center;
`;

const ChartHeaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ChartTitleWrapper = styled.div`
  margin-right: 20px;
`;

const Styles = {
  LoadingIcon,
  LoadingIconWrapper,
  ChartHeaderWrapper,
  ChartTitleWrapper,
};

export default Styles;
