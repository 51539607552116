import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Button, Table } from 'antd';
import { Title } from '@optx/shared/view/atoms/Typography';
// models
import { TableChangeCallback } from '@optx/models/table/antd-table';
import { UserManagement as UserManagementTable } from '@optx/models/UserManagement';
// utils
import { mapSorting } from '@utils/table/sorting';
// redux
import { selectors, actions } from '@redux/user-management';
// hooks
import { useUserInvitationModal } from '@hooks/init/useUserInvitationModal';
import { useFetchUserManagement } from '@hooks/init';
// components
import UserInvitationModal from '@components/UserInvitation/index';
import { PrivateComponent } from '@components/common/PrivateComponent';
import { MainContent } from '@components/common/layout';
import PageLoader from './PageLoader';
import EditUserModal from './EditUserModal';

const UserManagement: React.FC = () => {
  const dispatch = useDispatch();
  const [, toggleUserInvitation] = useUserInvitationModal();
  useFetchUserManagement();
  const data = useSelector(selectors.userManagementData);
  const columns = useSelector(selectors.getGridColumns);
  const sorting = useSelector(selectors.getSorting);
  const modifiedColumns = useMemo(
    () =>
      columns.map(item => {
        if (item.title !== 'Status' && item.title !== 'Action') {
          return {
            ...item,
            className: `${item.className} not-interactive`,
          };
        }

        return item;
      }),
    [columns]
  );

  const handleChange: TableChangeCallback<UserManagementTable> = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    switch (extra.action) {
      case 'sort': {
        const { sortBy } = mapSorting(sorter, sorting);
        dispatch(actions.fetchUserManagement(sortBy));
        break;
      }

      default:
        break;
    }
  };

  return (
    <PrivateComponent accessFor="admin_portal_link">
      <PageLoader />
      <MainContent className="admin-page main-content mt-3">
        <Row>
          <Col span={24}>
            <div className="card m-b-30">
              <div className="card-body">
                <Row>
                  <Col span={18}>
                    <Title>Users</Title>
                  </Col>
                  <Col span={6} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={toggleUserInvitation}>
                      Invite User
                    </Button>
                  </Col>
                </Row>
                <Table
                  className="search-results legacy-table"
                  columns={modifiedColumns}
                  rowKey={'id'}
                  dataSource={data}
                  pagination={false}
                  tableLayout="fixed"
                  bordered
                  showSorterTooltip={false}
                  onChange={handleChange}
                />
              </div>
            </div>
          </Col>
        </Row>
      </MainContent>
      <UserInvitationModal />
      <EditUserModal />
    </PrivateComponent>
  );
};

export default UserManagement;
