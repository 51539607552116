import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// models
import { SuccessErrorCallback } from '@optx/models/callback';
import { CompanyUserValuesKeys } from '@optx/models/Company';
import { UpdateFields } from '@optx/models/feature/edit-field/EditFieldRadioProps';
import { LastRoundData, CustomValue, UpdateFieldsPayload } from '../state/interfaces';
import { EditValue } from '@models/edit-fields';
// redux
import { actions as editFieldActions } from '../state/index';

const useUpdateFields = ({
  fieldName,
  extraKeys,
  companyId,
  companyUrl,
  personId,
  service,
  successMessage,
  errorMessage,
  isPartner,
  isFromHistory,
  isContact,
}: UpdateFields) => {
  const dispatch = useDispatch();

  const updateField = useCallback(
    (
      value: EditValue,
      fieldKey?: CompanyUserValuesKeys,
      lastRoundData?: LastRoundData,
      callback?: (data?: SuccessErrorCallback, showHistory?: boolean) => void,
      rationale?: string,
      entityType?: string,
      extraValues?: Array<EditValue>
    ) => {
      const payload: UpdateFieldsPayload = {
        value,
        companyId: isContact && personId ? personId : companyId,
        fieldKey,
        extraKeys,
        extraValues,
        lastRoundData,
        year: value ? ((value as CustomValue).optionalValue as number) : undefined,
        successMessage,
        errorMessage,
        rationale,
        entityType,
      };
      dispatch(
        editFieldActions.updateFields({
          companyId,
          companyUrl,
          callback,
          value: payload,
          service,
          fieldName,
          isPartner,
          isFromHistory,
        })
      );
    },
    [
      companyId,
      isContact,
      personId,
      extraKeys,
      successMessage,
      companyUrl,
      errorMessage,
      dispatch,
      service,
      fieldName,
      isPartner,
      isFromHistory,
    ]
  );

  return { updateField };
};

export default useUpdateFields;
