import { createSelector } from 'reselect';
import { sortColumns } from '@optx/utils/table/sorting';
import { getDefaultScore } from '@optx/redux/user/information/selectors';
import { SortState } from '../interfaces';
import columns from '../../components/CompaniesInETTable/columns';

export const isMultiSort = (state: SortState) => state.multiSort;
export const selectSortBy = (state: SortState) => state.sortBy;

export const getGridColumns = createSelector(
  selectSortBy,
  getDefaultScore,
  (sorting, defaultOptxScore) => sortColumns(columns, sorting, defaultOptxScore)
);
