import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
// styles
import './styles.scss';
// models
import { SelectOption } from '@optx/models/Option';
// constants
import { HTML } from '@constants/fullScreen';
// redux
import {
  selectors as fullscreenSelectors,
  actions as fullScreenActions,
} from '@redux/ui/settings/fullscreen';
import { selectors as userInformationSelectors } from '@redux/user/information';
import { selectors as companySearchSelectors } from '@redux/company/search/search';
import { actions as searchActions } from '@features/grid/search';
import * as pageSelectors from '@redux/pages/home/selectors';
// hooks
import { useReduxFullScreen } from '@optx/common/hooks/fullscreen';
import { useInitialFetchEditOptions } from '@components/feature/company-individual-edit';
import {
  useFetchSavedSearches,
  useInitFavorites,
  useInitFilters,
  useFetchUserList,
} from '@optx/common/hooks/init';
import { useInjectCompanyReview } from '@optx/features/company-review';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
// components
import { MainHeader, MainContent } from '@optx/components/common/layout';
import { useShouldFetch } from '@optx/common/hooks/fetch';
import { useInjectEditFields } from '@optx/features/company/edit-fields';
import CompanySearchDisplay from './CompanySearchDisplay';
import FilterHeader from './Header';
import CompanyNotes from '../../CompanyNotes';
import CompanyTouches from '@features/add-touch/components';
import PageLoader from './PageLoader';
import SecondaryQuickFiltersContainer from './SecondaryQuickFiltersContainer';
import QuickFilters from './QuickFilters';
import SearchGridControls from './SearchGridControls/SearchGridControls';
import FiltersModal from '@optx/features/grid/filter/components';
import { GridContext } from './HomeContext';
import { StickyHeader } from '../../common/StickyHeader.styled';
import { useInjectPage } from './useInjectPage';
import EditAllDialog from '@optx/features/edit-all-info/components/EditAllDialog';
import DocumentUploadModal from '@optx/features/add-touch/components/DocumentUploadModal';

const HomePage: React.FC = () => {
  useInjectPage();
  useInjectEditFields();
  useInitFilters();
  useInitialFetchEditOptions();
  useInitFavorites();
  useFetchSavedSearches();
  useInjectCompanyReview();
  useFetchUserList(false);

  const [selectedData, setSelectedData] = useState<SelectOption[] | string | boolean>([]);
  const [hasSelectViewChanged, setHasSelectViewChanged] = useState<boolean>(false);
  const [rationaleValue, setRationaleValue] = useState<string>();
  const searchKey = useSelector(companySearchSelectors.getSearchKey);
  const filter = useSelector(filterSelectors.getFilter('advancedSearch'));

  const dispatch = useDispatch();
  const shouldFetch = useSelector(pageSelectors.shouldInitialSearch);

  const searchCompanies = useCallback(() => {
    dispatch(searchActions.initialCompaniesSearch({ gridKey: 'advancedSearch', data: {} }));
  }, [dispatch]);
  useShouldFetch(shouldFetch, searchCompanies);

  // full screen
  const fullscreen = useSelector(fullscreenSelectors.isFullscreen);
  const showQuickFilters = useSelector(userInformationSelectors.showQuickFilters);
  const histogramsAreEmpty = useSelector(companySearchSelectors.histogramsAreEmpty);
  const toggle = useCallback(() => dispatch(fullScreenActions.toggleFullScreen()), [dispatch]);
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const { isFullscreen } = useReduxFullScreen({ dom: HTML, fullscreen, toggle });

  const contentClassNames = React.useMemo(
    () =>
      classnames('main-content grid-page', {
        fullscreen: isFullscreen,
        'show-quick-filters': showQuickFilters,
        'show-quick-filters--small': showQuickFilters && histogramsAreEmpty,
      }),
    [isFullscreen, showQuickFilters, histogramsAreEmpty]
  );

  return (
    <GridContext.Provider
      value={{
        selectedData,
        setSelectedData,
        gridName: 'advancedSearch',
        hasSelectViewChanged,
        setHasSelectViewChanged,
        rationaleValue,
        setRationaleValue,
      }}
    >
      <MainHeader className="main-header fixed">
        <FilterHeader />
      </MainHeader>
      <StickyHeader isFullScreen={fullscreen} offsetTop="64px">
        <SecondaryQuickFiltersContainer />
        {showFilters && <QuickFilters />}
        <SearchGridControls open={showFilters} setOpen={setShowFilters} />
      </StickyHeader>
      <MainContent className={contentClassNames}>
        <PageLoader />
        <CompanySearchDisplay />
        <CompanyNotes />
        <CompanyTouches />
        <EditAllDialog />
        <FiltersModal
          filter={filter}
          keySearch={searchKey}
          gridName="Advanced Search"
          gridKey={'advancedSearch'}
        />
      </MainContent>
      <DocumentUploadModal />
    </GridContext.Provider>
  );
};

export default HomePage;
