// fetch opportunity presentation
export const FETCH_OPPORTUNITY_PRESENTATION =
  'company/deal-presentation/FETCH_OPPORTUNITY_PRESENTATION';
export const FETCH_OPPORTUNITY_PRESENTATION_SUCCESS =
  'company/deal-presentation/FETCH_OPPORTUNITY_PRESENTATION_SUCCESS';
export const FETCH_OPPORTUNITY_PRESENTATION_FAIL =
  'company/deal-presentation/FETCH_OPPORTUNITY_PRESENTATION_FAIL';
// update opportunity presentation
export const UPDATE_OPPORTUNITY_PRESENTATION =
  'company/deal-presentation/UPDATE_OPPORTUNITY_PRESENTATION';
export const UPDATE_OPPORTUNITY_PRESENTATION_SUCCESS =
  'company/deal-presentation/UPDATE_OPPORTUNITY_PRESENTATION_SUCCESS';
export const UPDATE_OPPORTUNITY_PRESENTATION_FAIL =
  'company/deal-presentation/UPDATE_OPPORTUNITY_PRESENTATION_FAIL';

export const SET_ADD_YEARS_OPEN_MODAL = 'company/deal-presentation/SET_ADD_YEARS_OPEN_MODAL';

export const EDIT_ADD_YEARS_OPEN_MODAL = 'company/deal-presentation/EDIT_ADD_YEARS_OPEN_MODAL';

export const ADD_FINANCIAL_YEAR = 'company/deal-presentation/ADD_FINANCIAL_YEAR';
export const ADD_FINANCIAL_YEAR_SUCCESS = 'company/deal-presentation/ADD_FINANCIAL_YEAR_SUCCESS';
export const ADD_FINANCIAL_YEAR_FAIL = 'company/deal-presentation/ADD_FINANCIAL_YEAR_FAIL';

export const DELETE_FINANCIAL_YEAR = 'company/deal-presentation/DELETE_FINANCIAL_YEAR';
export const DELETE_FINANCIAL_YEAR_SUCCESS =
  'company/deal-presentation/DELETE_FINANCIAL_YEAR_SUCCESS';
export const DELETE_FINANCIAL_YEAR_FAIL = 'company/deal-presentation/DELETE_FINANCIAL_YEAR_FAIL';

export const EDIT_FINANCIAL_YEAR = 'company/deal-presentation/EDIT_FINANCIAL_YEAR';
export const EDIT_FINANCIAL_YEAR_SUCCESS = 'company/deal-presentation/EDIT_FINANCIAL_YEAR_SUCCESS';
export const EDIT_FINANCIAL_YEAR_FAIL = 'company/deal-presentation/EDIT_FINANCIAL_YEAR_FAIL';
