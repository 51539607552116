import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// models
import { SortByRule } from '@models/table/sorting';
import { UserInformation } from '@optx/models/user';
// utils
import { parseSorting } from '@optx/utils/filters/parseSorting';
// redux
import { actions as userInformationActions } from '@redux/user/information';
import { SortState } from '../interfaces';
import { actions } from '../actions';
import { DEFAULT_SORT } from '../../constants/table';

export const initialState: SortState = {
  sortBy: DEFAULT_SORT,
};

const sortReducer: CaseReducer<SortState, PayloadAction<Array<SortByRule<any>>>> = (
  draftState,
  action
) => {
  draftState.sortBy = action.payload;
};

export const fetchUserInformationSuccessReducer: CaseReducer<
  SortState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const sorting = action.payload.settings.session_settings?.sourcing_outreach_sorting;

  if (sorting) {
    const { sortBy } = parseSorting(sorting);
    draftState.sortBy = sortBy;
  }
};

const resetToDefaultReducer: CaseReducer<SortState> = () => initialState;

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.sort.sort, sortReducer)
    .addCase(actions.filters.clearFilters, resetToDefaultReducer)
    .addCase(actions.filters.resetToDefault, resetToDefaultReducer)
    // external reducer
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
);

export default reducer;
