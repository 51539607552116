import React from 'react';
import { Row, Col } from 'antd';
// models
import { CompanyProfile } from '@optx/models/Company';
// constants
import { formatMoney, formatQuarter } from '@optx/utils/format';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { abbreviateValue } from '@optx/utils/number';
// styles
import Styles from './Kpi.styles';

interface KPIProps {
  companyProfile: CompanyProfile;
}

const KPI: React.FC<KPIProps> = ({ companyProfile }) => {
  const lastRaisedAmmount = companyProfile.capital_raised;
  let totalRaisedLabel;
  let plusUnknownLabel;

  if (
    lastRaisedAmmount === undefined ||
    lastRaisedAmmount === null ||
    lastRaisedAmmount === '-' ||
    lastRaisedAmmount === ''
  ) {
    totalRaisedLabel = DEFAULT_EMPTY_VALUE;
  }

  if (lastRaisedAmmount === 'Unknown') {
    totalRaisedLabel = 'Unknown';
  }

  let value;

  if (lastRaisedAmmount === null) {
    value = DEFAULT_EMPTY_VALUE;
  } else if (lastRaisedAmmount.toString().indexOf(' (')) {
    value = lastRaisedAmmount.toString().split(' (')[0];
  } else {
    value = lastRaisedAmmount;
  }

  totalRaisedLabel = formatMoney(value);

  if (value === 'Unknown') {
    totalRaisedLabel = 'Unknown';
  }

  if (value === DEFAULT_EMPTY_VALUE) {
    totalRaisedLabel = DEFAULT_EMPTY_VALUE;
  }

  if (companyProfile.is_capital_raise_has_unknown) {
    plusUnknownLabel = '(plus Unknown)';
  }

  return (
    <Row className="kpi-row" justify="center">
      <Col span={4} className="kpi-col">
        <Styles.Item>
          <Styles.Value>
            {totalRaisedLabel}
            {plusUnknownLabel && <Styles.UnknownLabel>{plusUnknownLabel}</Styles.UnknownLabel>}
          </Styles.Value>
          <Styles.Label>TOTAL RAISED</Styles.Label>
        </Styles.Item>
      </Col>
      <Col span={4} className="kpi-col">
        <Styles.Item>
          <Styles.Value>
            {formatQuarter(companyProfile.raise_date) || DEFAULT_EMPTY_VALUE}
          </Styles.Value>
          <Styles.Label>LAST FUNDING DATE</Styles.Label>
        </Styles.Item>
      </Col>
      <Col span={4} className="kpi-col">
        <Styles.Item>
          <Styles.Value>{abbreviateValue(companyProfile.num_employees)}</Styles.Value>
          <Styles.Label>TOTAL # OF EMPLOYEES</Styles.Label>
        </Styles.Item>
      </Col>
      <Col span={4} className="kpi-col">
        <Styles.Item>
          <Styles.Value>
            {companyProfile.emp_growth_percent !== null
              ? `${companyProfile.emp_growth_percent}%`
              : DEFAULT_EMPTY_VALUE}
          </Styles.Value>
          <Styles.Label>Y/Y EMPLOYEE GROWTH %</Styles.Label>
        </Styles.Item>
      </Col>
      <Col span={4} className="kpi-col">
        <Styles.Item>
          <Styles.Value>
            {companyProfile.sw_website_rank_per_country || DEFAULT_EMPTY_VALUE}
          </Styles.Value>
          <Styles.Label>US WEBSITE RANK</Styles.Label>
        </Styles.Item>
      </Col>
    </Row>
  );
};

export default KPI;
