import { combineReducers } from 'redux';

import { SourcingOutreachCombinedState } from '../interfaces';
import search from './search';
import filters from './filters';
import sort from './sort';

export const reducer = combineReducers<SourcingOutreachCombinedState>({
  search,
  filters,
  sort,
});
