import styled from 'styled-components';
import Radio from 'antd/lib/radio';

const ContentWrapper = styled.div`
  .popover-radio__container {
    display: flex;
    flex-direction: column;
    gap: 10;
  }

  .ant-radio {
    top: 0;

    .ant-radio-inner:after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) !important;
    }
  }

  .rationale_select-async {
    display: block;
    width: 100%;
  }

  .ant-radio-group {
    width: 100%;

    .ant-radio-button-wrapper {
      width: 100%;
    }
  }
`;
const RadioStyled = styled(Radio)`
  display: flex;
  alignitems: center;
  lineheight: 2;
`;

export const Styled = {
  ContentWrapper,
  RadioStyled,
};
