import React from 'react';
import { ColumnRender } from '@models/table/antd-table';
import { lib as companySearchLib } from '@features/company-search';
import { CompanyInEquityTouch } from '../../../../models/CompanyInEquityTouch';

const {
  Table: {
    columns: { CompanyNameRender },
  },
} = companySearchLib;

export const TableCellCompanyName: ColumnRender<string, CompanyInEquityTouch> = (value, record) => (
  <CompanyNameRender name={value} companyId={record.merge_id} />
);
