import { takeLatest, call, put, select } from 'redux-saga/effects';
import { UserService } from '@optx/services/api';
import { getErrorMessage } from '@optx/utils/api/errors';
import NotificationService from '@optx/services/NotificationService';
import { selectors as userSelectors } from '@redux/user/information';
import { SortByRule } from '@optx/models/table/sorting';
import { Pagination } from '@optx/models/table/Pagination';
import { AxiosResponse } from 'axios';
import {
  fetchScheduledTouchesCountSuccess,
  fetchScheduledTouchesCountFail,
  fetchScheduledTouches,
  fetchScheduledTouchesFail,
  fetchScheduledTouchesSuccess,
  fetchScheduledTouchesDashboard,
  fetchScheduledTouchesDashboardSuccess,
  fetchScheduledTouchesDashboardFail,
  changeSelectedDate,
  fetchScheduledTouchesCount,
  fetchScheduledTouchesNextPage,
  fetchScheduledTouchesNextPageSuccess,
  fetchScheduledTouchesNextPageFail,
} from './actions';
import {
  getTouchType,
  getStageType,
  getSortBy,
  getSelectedTab,
  getSelectedDate,
  getPagination,
  getRankType,
} from './selectors';
import {
  DashboardSuccess,
  ScheduledTouchesCountResponse,
  ScheduledTouchesResponse,
  SCHEDULED_TOUCHES_DEFAULT_SORT,
} from './interfaces';

function* fetchScheduledTouchesSaga() {
  const touchType: string = yield select(getTouchType);
  const sortBy: SortByRule<string>[] = yield select(getSortBy);
  const stageType: string = yield select(getStageType);
  const rankType: string = yield select(getRankType);
  const selectedTab: string = yield select(getSelectedTab);
  const selectedDate: [string | null, string | null] = yield select(getSelectedDate);
  const pagination: Pagination = yield select(getPagination);

  try {
    yield put(fetchScheduledTouchesCount());
    const res: AxiosResponse<ScheduledTouchesResponse> = yield call(
      UserService.getScheduledTouches,
      selectedTab,
      selectedDate[0] as string,
      selectedDate[1] as string,
      sortBy,
      pagination,
      touchType,
      stageType,
      rankType
    );

    if (res.data) {
      yield put(fetchScheduledTouchesSuccess(res.data));
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Failed to fetch scheduled touches!');
    yield put(fetchScheduledTouchesFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

function* fetchScheduledTouchesNextPageSaga() {
  const touchType: string = yield select(getTouchType);
  const sortBy: SortByRule<string>[] = yield select(getSortBy);
  const stageType: string = yield select(getStageType);
  const rankType: string = yield select(getRankType);
  const selectedTab: string = yield select(getSelectedTab);
  const selectedDate: [string | null, string | null] = yield select(getSelectedDate);
  const pagination: Pagination = yield select(getPagination);

  try {
    const res: AxiosResponse<ScheduledTouchesResponse> = yield call(
      UserService.getScheduledTouches,
      selectedTab,
      selectedDate[0] as string,
      selectedDate[1] as string,
      sortBy,
      pagination,
      touchType,
      stageType,
      rankType
    );

    if (res.data) {
      yield put(fetchScheduledTouchesNextPageSuccess(res.data));
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Failed to fetch scheduled touches!');
    yield put(fetchScheduledTouchesNextPageFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

function* fetchScheduledTouchesDashboardSaga() {
  const defaultOptxScore: string = yield select(userSelectors.getDefaultScore);
  let sortBy: SortByRule<any>;
  if (defaultOptxScore === 'il')
    // @ts-ignore
    sortBy = SCHEDULED_TOUCHES_DEFAULT_SORT.replace('score', 'il_optx_score');
  // @ts-ignore
  if (defaultOptxScore === 'us') sortBy = SCHEDULED_TOUCHES_DEFAULT_SORT;

  try {
    const res: AxiosResponse<DashboardSuccess> = yield call(
      UserService.getScheduledTouchesDashboard
    );

    if (res.data) {
      // @ts-ignore
      yield put(fetchScheduledTouchesDashboardSuccess({ ...res.data, sortBy }));
      yield call(fetchScheduledTouchesSaga);
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Failed to fetch scheduled touches Dashboard!');
    yield put(fetchScheduledTouchesDashboardFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

function* fetchScheduledTouchesCountSaga() {
  const touchType: string = yield select(getTouchType);
  const stageType: string = yield select(getStageType);
  const rankType: string = yield select(getRankType);
  const selectedDate: [string | null, string | null] = yield select(getSelectedDate);

  try {
    const res: AxiosResponse<ScheduledTouchesCountResponse> = yield call(
      UserService.getScheduledTouchesCount,
      selectedDate[0] as string,
      selectedDate[1] as string,
      touchType,
      stageType,
      rankType
    );

    if (res.data) {
      yield put(fetchScheduledTouchesCountSuccess(res.data));
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Failed to fetch scheduled touches!');
    yield put(fetchScheduledTouchesCountFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

function* changeSelectedDateSaga() {
  yield call(fetchScheduledTouchesSaga);
}

export default function* companyIndividualEditSagas() {
  yield takeLatest(fetchScheduledTouches, fetchScheduledTouchesSaga);
  yield takeLatest(fetchScheduledTouchesNextPage, fetchScheduledTouchesNextPageSaga);
  yield takeLatest(fetchScheduledTouchesCount, fetchScheduledTouchesCountSaga);
  yield takeLatest(fetchScheduledTouchesDashboard, fetchScheduledTouchesDashboardSaga);
  yield takeLatest(changeSelectedDate, changeSelectedDateSaga);
}
