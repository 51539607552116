/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  YAxisProps,
} from 'recharts';
import { merge } from 'lodash';
// models
import { yAxisProps, yAxisPropsHorizontalBar } from '@optx/features/charts/config';
import { cartesianGridProps } from '@optx/components/common/charts/LineChart/config';
// components
import { MinimumSizeBar } from '@optx/components/common/charts/components';

interface HorizontalBarChartProps {
  chartHeight?: number;
  data: { label: string; [key: string]: string | number }[];
  dataKey: string;
  labelKey: string;
  color?: string;
}

const valueYAxisDifferentProps: YAxisProps = {
  tick: {
    fill: '#334D6E',
  },
};

const valueYAxisProps = merge({}, yAxisProps, valueYAxisDifferentProps);

const HorizontalBarChart: React.FC<HorizontalBarChartProps> = ({
  chartHeight,
  data,
  dataKey,
  labelKey,
  color,
}) => {
  return (
    <ResponsiveContainer height={chartHeight ?? 200}>
      <BarChart
        data={data}
        style={{ margin: '20px 0 0' }}
        layout="vertical"
        syncId="syncId"
        barGap={0}
        barCategoryGap={15}
        barSize={18}
      >
        <CartesianGrid {...cartesianGridProps} />
        {/* YAxis are displayed in reversed order. */}
        <YAxis
          {...valueYAxisProps}
          dataKey={dataKey}
          type="category"
          yAxisId={0}
          axisLine={false}
          width={40}
          tickMargin={17}
          tick={{ dx: 13, textAnchor: 'end', fontWeight: 'bold' }}
        />
        <YAxis
          {...yAxisPropsHorizontalBar}
          dataKey={labelKey}
          type="category"
          yAxisId={1}
          width={90}
          tickMargin={80}
        />
        <XAxis dataKey={dataKey} domain={[0, 'dataMax']} type="number" hide />
        <Bar
          dataKey={dataKey}
          fill="#096DD9"
          shape={MinimumSizeBar}
          radius={[0, 8, 8, 0]}
          width={200}
          background={{ fill: color ?? '#FAFAFA' }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default HorizontalBarChart;
