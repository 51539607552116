import { UserService } from '@optx/services/api';
import NotificationService from '@optx/services/NotificationService';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { Dictionary } from 'lodash';
import moment from 'moment';
// models
import { EditFieldRadioProps } from '@optx/models/feature/edit-field/EditFieldRadioProps';
import { SelectOption } from '@optx/models/Option';
import Company from '@optx/models/Company';
import { APIBoolean } from '@optx/models/api/generic';
import {
  EditField,
  UpdateFieldsPayload,
  UpdateVerifyCompanyPayload,
  CustomValue,
} from './interfaces';
// constants
import { CompanyProfileTabs } from '@optx/constants/routes';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { ISO_DATE_FORMAT } from '@optx/constants/format/date';
import { USER_VALUES_FIELDS } from '../constants';
// utils
import { mapCompanyPropName } from '@optx/utils/mapCompanyPropName';
import { getErrorMessage } from '@utils/api/errors';
import { getLocationMainGridKey } from '@optx/utils/grid';
// redux
import { actions as fundingRoundsActions } from '@redux/company/funding-rounds';
import { actions as userValuesActions } from '@redux/company/user-values';
import { actions as customGlobalLoaderActions } from '@features/custom-global-loader';
import { actions as presentantionActions } from '@redux/company/opportunity-presentation/index';
import { selectors as addonManegmentSelectors } from '@redux/company/addon-management';
import { selectors as searchSelectors } from '@features/grid/search';
import { actions as searchCountActions } from '@features/grid/search-count';
import { actions as modalActions } from '@redux/ui/modals/company-touches';
import { actions as scoreGrowthActions } from '@features/company/graphs/score';
import { EditDateFields } from '@optx/components/feature/company-individual-edit/state/date-fields/interfaces';
import {
  actions as auditActions,
  selectors as auditSelectors,
} from '@features/company/audit-trail/state';
import { actions as checklistResearchActions } from '@features/checklist-research-insights/state/actions';
import { selectors as routerSelectors } from '@redux/router';
import { selectors as userSelectors } from '@redux/user/information';
import * as actions from './actions';

export function* updateVerifiedSoftwareSaga(action: PayloadAction<UpdateVerifyCompanyPayload>) {
  const { companyId, value: isVerified } = action.payload;

  const payload: EditDateFields = { companyId, is_software_verified: isVerified };

  try {
    yield call(UserService.updateCompanyInfo, payload);
    yield put(
      actions.updateVerifyCompanySoftwareSuccess({
        companyId,
        value: isVerified,
      })
    );
    NotificationService.success(`Software company is ${isVerified ? 'verified' : 'unverified'}!`);
  } catch (e: any) {
    const message = 'Failed to update verify!';
    yield put(actions.updateVerifyCompanySoftwareFail(message));
    NotificationService.error(message);
  }
}

export function* updateEditFields(action: PayloadAction<EditField>) {
  const {
    companyId,
    companyUrl,
    fieldName,
    value,
    service,
    callback,
    isPartner,
    isFromHistory,
    canBeDuplicatedLoader,
    isFromTouch,
  } = action.payload;

  let payload = value;

  const updatingTrendingLinesFields = [
    'PSG FIT',
    ...USER_VALUES_FIELDS,
    'Use Parent Company',
    'Software Company',
    'Number of Employees',
  ];

  const defaultOptxScore: string = yield select(userSelectors.getDefaultScore);
  const gridKey = getLocationMainGridKey();
  const financialKeys = fieldName === 'ARR' || fieldName === 'GM %';
  let company: Company | undefined;

  if (gridKey) {
    company = yield select(searchSelectors.getCompanyById(gridKey, companyId));
  }

  if (!canBeDuplicatedLoader) {
    yield put(customGlobalLoaderActions.toggle({ loading: true, customText: '' }));
  }

  if (typeof fieldName === 'string' && fieldName === 'Use Parent Company') {
    payload = { companyId, use_parent_company: (value as EditFieldRadioProps).value };
  } else if (fieldName === 'Last Raised Amount') {
    const lastRaisedAmount = (value as UpdateFieldsPayload).value;
    const lastFundingDate =
      company?.raise_date && company?.raise_date !== DEFAULT_EMPTY_VALUE
        ? company?.raise_date
        : moment().format(ISO_DATE_FORMAT);

    payload = {
      ...(value as UpdateFieldsPayload),
      value: { last_raised_amount: lastRaisedAmount, raise_date: lastFundingDate },
    };
  }

  const isLinkedin = fieldName === 'Linkedin Profile';

  try {
    const res: AxiosResponse<any> = yield call(service, payload);

    const customValue = {
      value: res.data?.linkedin_url,
      companyId: (value as UpdateFieldsPayload).companyId,
      fieldKey: (value as UpdateFieldsPayload).fieldKey,
      entityType: (value as UpdateFieldsPayload).entityType,
    };

    const raiseDate =
      company?.raise_date && company?.raise_date !== DEFAULT_EMPTY_VALUE
        ? company?.raise_date
        : moment().format(ISO_DATE_FORMAT);

    if (!financialKeys) {
      yield put(
        actions.updateFieldsSuccess({
          fieldName,
          companyId,
          value: isLinkedin ? customValue : (value as UpdateFieldsPayload),
          score: res.data?.optx_score,
          scoreGrowth: res.data?.optx_score_growth,
          ilScore: res.data?.il_optx_score,
          ilScoreGrowth: res.data?.il_optx_score_growth,
          companyOwnerData: res.data?.extra_data,
          checklist_reviewed_by: res.data.checklist_reviewed_by,
          checklist_reviewed_date: res.data.checklist_reviewed_date,
          location: res.data?.location,
          psg_date: res.data?.psg_date,
          city: res.data?.city,
          state: res.data?.state,
          country: res.data?.country,
          isPsgCompany: res.data?.is_psg_company,
          isPartner,
          defaultOptxScore,
          raiseDate: fieldName === 'Last Raised Amount' ? raiseDate : undefined,
          lastRevenueGrowth: res.data?.last_rev_growth ?? '',
        })
      );
    }

    if (fieldName === 'ARR') {
      const customValue = {
        value: res.data?.financial.annual_recurring_revenue,
        companyId: (value as UpdateFieldsPayload).companyId,
        fieldKey: (value as UpdateFieldsPayload).fieldKey,
      };

      yield put(
        actions.updateFieldsSuccess({
          fieldName,
          companyId,
          value: customValue,
          companyOwnerData: null,
        })
      );
    }

    if (fieldName === 'Last Raised Amount' || fieldName === 'Capital Raised') {
      yield put(fundingRoundsActions.getCompanyFundingRounds(companyId));
    }

    if (fieldName === 'Add-on') {
      yield put(
        actions.updateFieldsSuccess({
          fieldName,
          companyId,
          value: isLinkedin ? customValue : (value as UpdateFieldsPayload),
          score: res.data?.optx_score,
          scoreGrowth: res.data?.optx_score_growth,
          ilScore: res.data?.il_optx_score,
          ilScoreGrowth: res.data?.il_optx_score_growth,
          companyOwnerData: res.data?.extra_data,
          location: res.data?.location,
          city: res.data?.city,
          state: res.data?.state,
          country: res.data?.country,
          isPartner,
          defaultOptxScore,
        })
      );

      const companyAddon: string | null = yield select(
        addonManegmentSelectors.getCurrentCompanyAddon(companyId)
      );
      const newAddon = ((value as UpdateFieldsPayload).value as CustomValue)
        .optionalValue as string;

      if (companyAddon !== newAddon) {
        yield put(searchCountActions.decrementAddonCount());
      }
    }

    if (fieldName === 'GM %') {
      const customValue = {
        value: res.data?.financial.gm,
        companyId: (value as UpdateFieldsPayload).companyId,
        fieldKey: (value as UpdateFieldsPayload).fieldKey,
      };

      yield put(
        actions.updateFieldsSuccess({
          fieldName,
          companyId,
          value: customValue,
          companyOwnerData: null,
        })
      );
    }

    /**
     * Check if last revenu is from source since we need to send a
     * second action to add the last value from source
     * so we can update both changed value and add value from source
     * - this only applies on empty value updates
     */
    if (
      typeof fieldName === 'string' &&
      fieldName === 'Revenue' &&
      ((value as UpdateFieldsPayload)?.value as CustomValue)?.value === DEFAULT_EMPTY_VALUE &&
      res.data &&
      res.data.last_revenue?.from_source &&
      res.data.last_revenue?.value
    ) {
      const secondValue = { ...(value as UpdateFieldsPayload) };
      (secondValue.value as CustomValue) = {
        value: res.data.last_revenue.value,
        from_source: res.data.last_revenue.from_source,
        optionalValue: res.data.last_revenue.year,
      };

      yield put(
        actions.updateFieldsSuccess({
          fieldName,
          companyId,
          value: secondValue,
          score: res.data?.optx_score,
          scoreGrowth: res.data?.optx_score_growth,
          ilScore: res.data?.il_optx_score,
          ilScoreGrowth: res.data?.il_optx_score_growth,
          companyOwnerData: res.data?.extra_data,
          location: res.data?.location,
          city: res.data?.city,
          state: res.data?.state,
          country: res.data?.country,
          isPartner,
          defaultOptxScore,
          lastRevenueGrowth: res.data?.last_rev_growth ?? '',
        })
      );
    }

    /**
     * Check if last EBITDA is from source since we need to send a
     * second action to add the last value from source
     * so we can update both changed value and add value from source
     * - this only applies on empty value updates
     */
    if (
      typeof fieldName === 'string' &&
      fieldName === 'EBITDA' &&
      ((value as UpdateFieldsPayload)?.value as CustomValue)?.value === DEFAULT_EMPTY_VALUE &&
      res.data &&
      res.data.last_ebitda?.from_source &&
      res.data.last_ebitda?.value
    ) {
      const secondValue = { ...(value as UpdateFieldsPayload) };
      (secondValue.value as CustomValue) = {
        value: res.data.last_ebitda.value,
        from_source: res.data.last_ebitda.from_source,
        optionalValue: res.data.last_ebitda.year,
      };

      yield put(
        actions.updateFieldsSuccess({
          fieldName,
          companyId,
          value: secondValue,
          score: res.data?.optx_score,
          scoreGrowth: res.data?.optx_score_growth,
          ilScore: res.data?.il_optx_score,
          ilScoreGrowth: res.data?.il_optx_score_growth,
          companyOwnerData: res.data?.extra_data,
          location: res.data?.location,
          city: res.data?.city,
          state: res.data?.state,
          country: res.data?.country,
          isPartner,
          defaultOptxScore,
        })
      );
    }

    if (typeof fieldName === 'string' && updatingTrendingLinesFields.includes(fieldName)) {
      yield put(
        userValuesActions.updateCompanyTrendingLines({
          employeeCountTrends: res.data?.employee_count_trends,
          optxScoreTrends: res.data?.optx_score_trends,
          companyId: action.payload.companyId,
        })
      );
    }

    if (typeof fieldName === 'string' && fieldName === 'Number of Employees') {
      yield put(
        userValuesActions.updateCompanyEmployeeGrowthPercentage({
          companyId: action.payload.companyId,
          empGrowthPercent: res.data.emp_growth_percent,
        })
      );
    }

    if (
      (typeof fieldName === 'string' &&
        companyUrl &&
        ['Stage', 'Fund', 'PSG Fit'].includes(fieldName)) ||
      (Array.isArray(fieldName) && fieldName.includes('Stage'))
    ) {
      yield put(
        scoreGrowthActions.fetchScoreGrowth({
          company_id: companyId,
          company_url: companyUrl as string | null,
        })
      );
    }

    if (
      typeof fieldName === 'string' &&
      ['PSG Fit', 'Software Company'].includes(fieldName) &&
      window.location.pathname.includes('checklist-research-insights')
    ) {
      yield put(checklistResearchActions.card.fetchCard());
    }

    if ((value as UpdateFieldsPayload).fieldKey === 'date_presented') {
      yield put(presentantionActions.fetchOpportunityPresentation(action.payload.companyId));
    }

    if (
      (value as UpdateFieldsPayload).fieldKey === 'ebitda_list' ||
      (value as UpdateFieldsPayload).fieldKey === 'revenue_list' ||
      (value as UpdateFieldsPayload).fieldKey === 'annual_recurring_revenue' ||
      (value as UpdateFieldsPayload).fieldKey === 'gross_margin_list'
    ) {
      // To update the score growth, we need to fetch the opportunities again
      yield put(presentantionActions.fetchOpportunityPresentation(action.payload.companyId));
    }

    NotificationService.success(
      (value as UpdateFieldsPayload).successMessage! ||
        `Company ${mapCompanyPropName(
          (value as UpdateFieldsPayload).fieldKey!
        )} updated successfully!`
    );

    callback && callback(true, res.data.show_on_history ?? true);

    // getting the current tab from Company Profile
    // @ts-ignore
    const tabNumber = yield select(routerSelectors.getLocation);

    if (isFromHistory || tabNumber.query.tab === CompanyProfileTabs.HISTORY) {
      const { pageSize } = yield select(auditSelectors.getPagination);
      const field: string = yield select(auditSelectors.getSearchQuery);
      const optx: APIBoolean = yield select(auditSelectors.getOPTX);
      const internal: APIBoolean = yield select(auditSelectors.getInternal);
      const startDate: string = yield select(auditSelectors.getStartDate);
      const endDate: string = yield select(auditSelectors.getEndDate);

      yield put(
        auditActions.fetchChangedCompanyFields({
          companyId,
          pagination: {
            pageNumber: 1,
            pageSize,
          },
          field,
          internal,
          optx,
          startDate,
          endDate,
        })
      );
    }

    if (!canBeDuplicatedLoader) {
      yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
    }

    if (isFromTouch) {
      yield put(modalActions.addTouchToCompanyErrorValidation('noEditFieldErrors'));
    }
  } catch (error: any) {
    yield put(
      actions.updateFieldsFail({
        fieldName,
      })
    );

    if (isFromTouch) {
      yield put(
        modalActions.setAdditionalFieldsError(
          getErrorMessage(error, ''),
          (value as UpdateFieldsPayload).value as unknown as Dictionary<SelectOption>
        )
      );
    }

    if (!(value as UpdateFieldsPayload)?.errorMessage) {
      NotificationService.error(getErrorMessage(error, 'Failed to update value!'));
    } else {
      NotificationService.error(
        (value as UpdateFieldsPayload).errorMessage! || 'Failed to update value!'
      );
    }

    yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
  }
}

export default function* companyChangedFieldsSaga() {
  yield takeEvery(actions.updateFields, updateEditFields);
  yield takeLatest(actions.updateVerifyCompanySoftware, updateVerifiedSoftwareSaga);
}
