import React from 'react';
import { Row, Col } from 'antd';
import AddContactFormSalesloft from './AddContactFormSalesloft';

const SelectContactTitle: React.FC = () => {
  return (
    <Row>
      <Col span={16}>Step 1: Select a Contact</Col>
      <Col span={8} className="title-button__wrapper">
        <AddContactFormSalesloft />
      </Col>
    </Row>
  );
};

export default SelectContactTitle;
