/* eslint-disable react/jsx-wrap-multilines */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Gutter } from 'antd/lib/grid/row';
// models
import { CompanyProfile } from '@optx/models/Company';
// redux
import {
  selectors as opportunitySelectors,
  actions as opportunityActions,
} from '@redux/company/opportunity-presentation';
import { actions as companyEditAllActions } from '@optx/features/edit-all-info/state';
// components
import DealPreparation from './components/DealPreparation';
import Financials from './components/Financials';
import FundingOwnershipStructure from './components/FundingOwnershipStructure';
import CustomerKPI from './components/CustomerKPI';
import Technology from './components/Technology';
import ExportModal from './components/ExportModal';
import {
  ContainerButtons,
  CustomerKPICard,
  DealPreparationCard,
  FinancialsCard,
  FundingCard,
  PropietaryInfoContainer,
  TechnologyCard,
} from './styles/DealPresentation.styled';

const gridGutter: Gutter | [Gutter, Gutter] = [
  { xs: 24, sm: 24, xl: 40 },
  { xs: 24, sm: 24, xl: 40 },
];

interface DealPresentationProps {
  company: CompanyProfile;
}

const DealPresentation: React.FC<DealPresentationProps> = ({ company }) => {
  const opportunityData = useSelector(opportunitySelectors.getOpportunityPresentation);
  const [openModal, setOpenModal] = useState(false);
  const buttonRefExport = useRef<HTMLButtonElement>(null);
  const buttonRefEditAll = useRef<HTMLButtonElement>(null);
  const buttonRefAddYear = useRef<HTMLButtonElement>(null);

  const dispatch = useDispatch();
  const fetchPresentation = useCallback(
    (companyId: number) => dispatch(opportunityActions.fetchOpportunityPresentation(companyId)),
    [dispatch]
  );

  const handleToggleModal = () => {
    buttonRefExport.current?.blur();
    setOpenModal(prev => !prev);
  };

  const handleEditAll = () => {
    buttonRefEditAll.current?.blur();
    dispatch(
      companyEditAllActions.toggleCompanyEditAllFormDialog({
        companyId: company.company_id,
        companyName: company.company_name,
        company,
      })
    );
  };

  // Fetch presentation data when company identifier changes.
  useEffect(() => {
    if (!opportunityData) {
      fetchPresentation(company.company_id);
    } else if (opportunityData.company_id !== company.company_id) {
      fetchPresentation(company.company_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.company_id]);

  return (
    <>
      <Row gutter={gridGutter} className="deal-preparation">
        <Col span={24} xl={24} className="financial-card">
          <FinancialsCard
            title="Financials"
            headStyle={{ border: 'none' }}
            bordered
            extra={
              <>
                <ContainerButtons>
                  <Button
                    ref={buttonRefExport}
                    onClick={handleToggleModal}
                    style={{ marginRight: '12px' }}
                  >
                    Export
                  </Button>
                  <Button type="primary" ref={buttonRefExport} onClick={handleEditAll}>
                    Edit All
                  </Button>
                </ContainerButtons>
                <Button
                  ref={buttonRefAddYear}
                  style={{ marginLeft: '4rem', position: 'absolute', top: '1rem' }}
                  size="small"
                  onClick={() => {
                    dispatch(opportunityActions.setAddYearsOpenModal());
                    buttonRefAddYear.current?.blur();
                  }}
                >
                  + Add Years
                </Button>
              </>
            }
          >
            <Financials companyId={company.company_id} isChromeExtension={false} />
          </FinancialsCard>
          <PropietaryInfoContainer>
            <DealPreparationCard title="Deal Preparation" bordered>
              <DealPreparation company={company} />
            </DealPreparationCard>
            <Col className="proprietary-info-cards">
              <FundingCard title="Funding / Ownership Structure" bordered>
                <FundingOwnershipStructure company={company} isChromeExtension={false} />
              </FundingCard>
              <CustomerKPICard title="Customer KPI" bordered>
                <CustomerKPI company={company} />
              </CustomerKPICard>
              <TechnologyCard title="Technology" bordered>
                <Technology company={company} isChromeExtension={false} />
              </TechnologyCard>
            </Col>
          </PropietaryInfoContainer>
        </Col>
      </Row>
      <ExportModal company={company} isOpen={openModal} toggle={handleToggleModal} />
    </>
  );
};

export default DealPresentation;
