import React from 'react';
import CadenceTitle from '../common/CardTitle';
import { CardWrapper } from '../SalesloftBulk.styled';
import SelectCadence from './SelectCadence';

interface AddCadenceProps {
  readOnly?: boolean;
}

const AddCadence: React.FC<AddCadenceProps> = ({ readOnly }) => {
  return (
    <CardWrapper
      title={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <CadenceTitle
          title="Select a Cadence"
          subtitle="The primary contact from each company will automatically be added to this SL Cadence"
        />
      }
    >
      <SelectCadence readOnly={readOnly} />
    </CardWrapper>
  );
};

export default AddCadence;
