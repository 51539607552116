import React from 'react';
import { ColumnType } from 'antd/lib/table';
import numeral from 'numeral';
// services
import { UserService, CompanyService } from '@optx/services/api';
// models
import { PipelineReportCompanies } from '@features/pipeline-report/models';
import Company from '@optx/models/Company';
// constants
import { NEXT_STEPS_OPINION_ENDPOINT } from '@constants/asyncEndpoints';
// components
import { TableCellDefault } from '@optx/components/common/table/AntTable/cells';
import {
  EditFieldCheckBoxSelectAsync,
  EditFieldSingleSelect,
  EditFieldSingleSelectOther,
} from '@optx/features/company/edit-fields/components';
import { TableCellCompanyName, TableCellCompanyURL, TableCellDate, TableCellTeams } from './cells';

const columns: Array<ColumnType<PipelineReportCompanies & Company>> = [
  {
    dataIndex: 'company_url',
    render: (value: string | null, record: Company) => (
      <TableCellCompanyURL value={value} record={record} />
    ),
    width: 36,
    className: 'align-center',
  },
  {
    dataIndex: 'company_name',
    title: 'Company Name',
    render: (value: string, record: Company) => (
      <TableCellCompanyName value={value} record={record as Company} />
    ),
    width: 150,
    ellipsis: true,
    className: 'name',
  },
  {
    dataIndex: 'stage',
    title: 'Stage',
    render: (value: string, record: Company) => (
      <EditFieldSingleSelect
        isFromGrid
        service={CompanyService.updateCompanyStage}
        fieldName="Stage"
        value={value ?? 'None'}
        successMessage="Company stage updated successfully"
        errorMessage="Company stage update failed, Server error!"
        record={record}
      />
    ),
  },
  {
    dataIndex: 'addon',
    title: 'Add-on For',
    render: (value: string, record: Company) => (
      <EditFieldCheckBoxSelectAsync
        fieldName="Add-on"
        record={record}
        service={CompanyService.updateAddon}
        isFromGrid
        successMessage="Add-on updated successfully"
        errorMessage=""
        value={value}
      />
    ),
  },
  {
    dataIndex: 'location',
    title: 'Location',
    width: 100,
    className: 'location',
    render: TableCellDefault,
  },
  {
    dataIndex: 'asking_amount',
    title: 'Equity Check (ask amount)',
    width: 100,
    render: (value: string) => {
      if (value === 'TBD') return 'TBD';

      if (value === '0') return 0;

      const result = numeral(Number(value)).format('0.[0]a');

      return result;
    },
  },
  {
    dataIndex: 'fund',
    title: 'Fund',
    width: 120,
    render: (value: string, record: Company) => (
      <EditFieldSingleSelect
        isFromGrid
        fieldName="Fund"
        service={CompanyService.updateCompanyFund}
        value={value}
        successMessage="Company fund updated successfully"
        errorMessage="Company fund update failed, Server error!"
        record={record}
      />
    ),
  },
  {
    dataIndex: 'senior_deal_team_lead',
    title: 'Senior Deal Team Lead',
    className: 'senior_deal_team_lead',
    ellipsis: true,
    render: (value: string, record: Company) => <TableCellTeams value={value} record={record} />,
  },
  {
    dataIndex: 'deal_team',
    title: 'Deal Team',
    ellipsis: true,
    render: (value: string, record: Company) => <TableCellTeams value={value} record={record} />,
  },
  {
    dataIndex: 'ops_team',
    title: 'OPS Team',
    ellipsis: true,
    render: (value: string, record: Company) => <TableCellTeams value={value} record={record} />,
  },
  {
    dataIndex: 'lead_source',
    title: 'Lead Source',
    ellipsis: false,
    render: (value: string, record: Company) => (
      <EditFieldSingleSelect
        isFromGrid
        value={value ?? 'None'}
        record={record}
        service={CompanyService.updateLeadSource}
        successMessage="Lead Source updated successfully"
        errorMessage="Lead Source update failed, Server error!"
        fieldName="Lead Source"
      />
    ),
  },
  {
    dataIndex: 'ic_status_date',
    title: 'IC Status',
    render: (value: string, record: Company) => <TableCellDate value={value} record={record} />,
  },
  {
    dataIndex: 'expected_close_date',
    title: 'Close Date',
    render: (value: string, record: Company) => <TableCellDate value={value} record={record} />,
  },
  {
    dataIndex: 'next_steps',
    title: 'Next Steps',
    className: 'next_steps',
    render: (value: string, record: Company) => (
      <EditFieldSingleSelectOther
        dropdownPlacement="topRight"
        isFromGrid
        service={UserService.updateNextSteps}
        fieldName="Next Steps"
        value={value}
        successMessage="Company next steps updated successfully!"
        errorMessage="Company next steps update failed, Server error!"
        record={record}
        fieldOtherKey={'next_steps_opinion'}
        otherEndpoint={NEXT_STEPS_OPINION_ENDPOINT}
        fieldOtherValue={record.next_steps_opinion}
      />
    ),
    width: 110,
    ellipsis: true,
  },
];

export default columns;
