import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import { LinkedinOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
// models
import { CompanyContactIcons, CompanyUserContact } from '@models/api/contacts';
// utils
import { convertToURL } from '@optx/utils/url';
// components
import CopyButton from '@components/common/CopyButton';
import Icon from '@components/common/Icon';
// styled components
import Styles from '@components/pages/EquityTouch//components/EquityTouch.style';

export const TableCellIcons = (value: CompanyContactIcons, row: Partial<CompanyUserContact>) => {
  let phones: string[] | string = [];

  if (row.phone) {
    phones.push(row.phone);
  }

  if (row.additional_phones && row.additional_phones.length) {
    row.additional_phones.forEach(phone => {
      if (!(phones as string[]).includes(phone.phone_number))
        (phones as string[]).push(phone.phone_number);
    });
  }

  if (phones.length) {
    phones = phones.join(', ');
  }

  return (
    <Styles.IconsWrapper>
      <Row align="middle">
        {phones.length ? (
          <Col>
            <Tooltip title={<CopyButton text={phones as string} />}>
              <PhoneOutlined className={row.email ? '' : 'no-email'} />
            </Tooltip>
          </Col>
        ) : (
          ''
        )}
        {row.linkedin && (
          <Col>
            <a href={convertToURL(row.linkedin)} target="_blank" rel="noopener noreferrer">
              <LinkedinOutlined />
            </a>
          </Col>
        )}
        {row.email && (
          <Col>
            <Tooltip title={<CopyButton text={row.email} />}>
              <MailOutlined />
            </Tooltip>
          </Col>
        )}
        {row.salesloft && (
          <Col>
            <Icon iconName="salesloft" />
          </Col>
        )}
      </Row>
    </Styles.IconsWrapper>
  );
};

export default TableCellIcons;
