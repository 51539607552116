import React, { useRef, useState, useEffect } from 'react';
import AsyncCreatable from 'react-select/async-creatable';
import { ActionMeta, InputActionMeta } from 'react-select';
// models
import { SelectOption } from '@optx/models/Option';
import { SuccessErrorCallback } from '@optx/models/callback';
// constants
import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';
import { COMPANY_RATIONALE_ENDPOINT } from '@constants/asyncEndpoints';
// hooks
import { useToggle } from '@optx/common/hooks/modal';
import useAsyncSearch from '@optx/common/hooks/select/useAsyncSearch';
import { FormRationale } from '@optx/redux/company/filters/interfaces';

interface OtherOptionsProps {
  disabled: boolean;
  onInputChange: (e: any) => void;
  rationaleValue: string | null;
  rationaleOptions: FormRationale[];
}

const OtherOptions: React.FC<OtherOptionsProps> = ({
  disabled,
  onInputChange,
  rationaleValue,
  rationaleOptions,
}) => {
  const [showAddButton, toggleShowAddButton] = useToggle(false);
  const [inputValue, setInputValue] = useState<SelectOption | null>(null);

  useEffect(() => {
    // set initial reason for "other" option, if it exists
    if (
      rationaleValue &&
      rationaleValue !== DEFAULT_CELL_VALUE &&
      !rationaleOptions.some(option => option.value === rationaleValue)
    ) {
      setInputValue({ value: rationaleValue, label: rationaleValue });
      onInputChange(rationaleValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (option: any, actionMeta: ActionMeta<SelectOption<string | number>>) => {
    switch (actionMeta.action) {
      // @ts-ignore
      case 'select-option':
        onInputChange(option.value);
        setInputValue(option);

      // eslint-disable-next-line no-fallthrough
      case 'create-option': {
        onInputChange(option.value);
        setInputValue(option);

        toggleShowAddButton();
        break;
      }

      default:
        break;
    }
  };

  const { loadOptions } = useAsyncSearch({
    endpoint: COMPANY_RATIONALE_ENDPOINT,
  });

  const selectRef = useRef<AsyncCreatable<SelectOption> | null>(null);

  const handleMenuClose = () => {
    if (!showAddButton) {
      toggleShowAddButton();
    }

    setInputValue(null);
  };

  const handleInputChange = (newValue: string, { action }: InputActionMeta) => {
    switch (action) {
      case 'input-change': {
        // This prevents a bug where the query is called without the last letter of previous query
        // eg: If user writes 'auto', query is 'auto'. If the second time user writes 'auto', query will be 'aut'.
        // This seems to fix above problem;
        loadOptionsAfterInputChange(newValue, () => {});
        break;
      }

      default:
        break;
    }
  };

  const loadOptionsAfterInputChange = (query: string, callback: SuccessErrorCallback) => {
    loadOptions(query, options => {
      const callbackResult = callback(options);

      return callbackResult;
    });
  };

  return (
    <AsyncCreatable
      isDisabled={disabled}
      value={inputValue}
      loadOptions={loadOptionsAfterInputChange}
      className="rationale_select-async"
      onChange={onChange}
      styles={{
        menu: baseStyle => ({
          ...baseStyle,
          position: 'sticky',
        }),
      }}
      onInputChange={handleInputChange}
      controlShouldRenderValue
      selectRef={selectRef}
      onMenuClose={handleMenuClose}
      closeMenuOnSelect
      bodyDisplay={false}
      disableValueRemove
    />
  );
};

export default OtherOptions;
