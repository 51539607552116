import { createAction } from '@reduxjs/toolkit';
// models
import { AddTagPayload, DeleteTagPayload, SuccessCompanyTag } from './interfaces';
// redux
import * as types from './types';

// add company tag
export const addCompanyTag = createAction<AddTagPayload>(types.ADD_COMPANY_TAG);
export const addCompanyTagSuccess = createAction<SuccessCompanyTag>(types.ADD_COMPANY_TAG_SUCCESS);
// delete company tag
export const deleteCompanyTag = createAction<DeleteTagPayload>(types.DELETE_COMPANY_TAG);
export const deleteCompanyTagSuccess = createAction<DeleteTagPayload>(
  types.DELETE_COMPANY_TAG_SUCCESS
);
