import React from 'react';
import { Table, Card } from 'antd';
// constants
import { defaultColumns } from '@components/common/table/TableContactInformation';
import { CompanyUserContact } from '@models/api/contacts';
import AddContactExtension from './AddContactExtension';

interface ContactsTableProps {
  contacts: CompanyUserContact[];
}

const ContactsTable: React.FC<ContactsTableProps> = ({ contacts }) => {
  return (
    <Card bordered={false} title="All Contacts" extra={<AddContactExtension />}>
      <Table
        rowKey="person_id"
        columns={defaultColumns}
        dataSource={contacts}
        bordered
        pagination={false}
        tableLayout="fixed"
        className="overflow-auto primary-contacts-grid legacy-table"
      />
    </Card>
  );
};

export default ContactsTable;
