import React from 'react';
import { useSelector } from 'react-redux';
import { Space } from 'antd';
import numeral from 'numeral';
// models
import { LastRound } from '@optx/models/Company';
// redux
import { selectors } from '../../../../../../redux/company/funding-rounds';
// utils
import { formatMoney } from '../../../../../../utils/format';
import { DEFAULT_CELL_VALUE } from '../../../../../../constants/table/cells';

const formatLastRoundValue = (lastRound: LastRound | null) => {
  let lastRoundLabel;

  if (lastRound === null) {
    lastRoundLabel = 'N/A / N/A';
  } else {
    const amount = lastRound?.amount;
    const round = lastRound?.round;

    if (!amount && !round) {
      lastRoundLabel = DEFAULT_CELL_VALUE;
    }

    if (amount === undefined || amount === null || amount === '-' || amount === '') {
      lastRoundLabel = `N/A / ${round}`;
    } else if (amount === 'Unknown') {
      lastRoundLabel = `Unknown / ${round}`;
    } else if (amount) {
      lastRoundLabel = `${formatMoney(numeral(amount).value())} / ${round}`;
    }

    if (!lastRoundLabel) {
      lastRoundLabel = DEFAULT_CELL_VALUE;
    }
  }

  return lastRoundLabel;
};

const formatTotalRaised = (totalRaised: string | number | null) => {
  let totalraisedLabel;

  if (
    totalRaised === undefined ||
    totalRaised === null ||
    totalRaised === '-' ||
    totalRaised === ''
  ) {
    totalraisedLabel = DEFAULT_CELL_VALUE;
  } else if (totalRaised === 'Unknown') {
    totalraisedLabel = 'Unknown';
  } else if (~totalRaised.toString().indexOf(' (')) {
    const totalRaisedValue = totalRaised.toString().split(' (')[0];
    totalraisedLabel = `${formatMoney(totalRaisedValue)} (plus Unknown)`;
  } else {
    totalraisedLabel = formatMoney(totalRaised);
  }

  return totalraisedLabel;
};

const ExtraContent: React.FC = () => {
  const lastRound = useSelector(selectors.getLastRound);
  const numberOfRounds = useSelector(selectors.getNumberOfRounds);
  const totalRaised = useSelector(selectors.getTotalRaised);

  return (
    <Space size="middle">
      <div className="funding-rounds-counter">
        <div className="funding-rounds-counter__value">
          {numberOfRounds} {numberOfRounds === 0 || numberOfRounds > 1 ? 'Rounds' : 'Round'}
        </div>
        <div className="funding-rounds-counter__title">Total # of Rounds</div>
      </div>
      <div className="funding-rounds-counter">
        <div className="funding-rounds-counter__value">{formatTotalRaised(totalRaised)}</div>
        <div className="funding-rounds-counter__title">Total Raised</div>
      </div>
      <div className="funding-rounds-counter">
        <div className="funding-rounds-counter__value">{formatLastRoundValue(lastRound)}</div>
        <div className="funding-rounds-counter__title">Last Round Amount / Type</div>
      </div>
    </Space>
  );
};

export default ExtraContent;
