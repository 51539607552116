import { call, put, takeLatest, select } from 'redux-saga/effects';
import { Dictionary } from 'lodash';
import { PayloadAction } from '@reduxjs/toolkit';
import queryString from 'query-string';
// models
import { HistogramGroup, PreselectedFilter } from '@optx/models/filters';
// constants
import AppRoutes from '@constants/routes';
// services
import NotificationService from '@services/NotificationService';
import { CompanyService } from '@services/api';
import { getErrorMessage } from '@utils/api/errors';
// utils
import { normalizeFilter } from '@optx/utils/filters/filterHelpers';
// redux
import { saga as routerSagas } from '@redux/router';
import { actions as userInformationActions } from '@redux/user/information';
import { getFiltersQuery, handleTouchFilters } from '@redux/company/search/search/sagasReusable';
import { selectors as searchSelectors } from '@redux/lists/search';
import { selectors as listsSelectors } from '@redux/lists/details';
import { actions as globalFiltersActions } from '@redux/company/filters';
import { interfaces as histogramInterfaces } from '@features/histograms/histograms-advanced-search';
import * as actions from './actions';
import { selectors as filterSelectors } from '@optx/features/grid/filter';

export function* fetchHistogramFiltersSaga(
  action: PayloadAction<histogramInterfaces.FetchHistogramsActionPayload>
) {
  try {
    const histogramsEnabled: boolean = yield select(searchSelectors.histogramsEnabled);

    if (!histogramsEnabled) {
      return;
    }

    const listId: string = yield select(listsSelectors.getRouteListId);
    const isSourceScrubRoute: boolean = yield select(listsSelectors.isSourceScrubRoute);

    let filter: Dictionary<PreselectedFilter> = action.payload.filter || {};

    // Inject list id in filter.
    filter = {
      ...filter,
      ...(isSourceScrubRoute ? { ss_list_id: listId } : { saved_list_id: listId }),
    };

    const defaultHistogramKeys: Array<string> = yield select(
      searchSelectors.selectAllFiltersWithHistograms
    );

    filter = yield call(handleTouchFilters, filter);

    const filterQueryPayload: Dictionary<string | Array<number | string>> = yield call(
      getFiltersQuery,
      filter
    );
    const defaultHistograms: HistogramGroup = yield select(searchSelectors.selectDefaultHistograms);
    const filterQueryString = queryString.stringify(filterQueryPayload, { arrayFormat: 'comma' });
    const isDefaultHistogram = !filterQueryString;

    if (isDefaultHistogram && defaultHistograms) {
      yield put(actions.resetToDefault());
    } else {
      // @ts-ignore
      const res = yield call(CompanyService.getHistograms, {
        filterQuery: filterQueryPayload,
        histogramList: defaultHistogramKeys,
      });

      yield put(
        actions.fetchHistogramFiltersSuccess({
          data: res.data,
          companyCount: res.data.company_count,
          isDefault: isDefaultHistogram,
        })
      );
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Error fetching histograms!');
    yield put(actions.fetchHistogramFiltersFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

function* filterChangeSaga(action: PayloadAction<histogramInterfaces.FilterChangedPayload>) {
  let { values } = action.payload;
  values = normalizeFilter(values);
  yield put(actions.fetchHistogramFilters(values));
}

function* toggleRefreshSaga(action: PayloadAction<histogramInterfaces.ToggleRefreshPayload>) {
  let { enabled, values } = action.payload;
  values = normalizeFilter(values);

  if (enabled) {
    yield put(actions.fetchHistogramFilters(values));
  }

  yield put(userInformationActions.updateUserSettings({ user_list_filters_refresh: enabled }));
}

/**
 * Matches lists details or source scrub list details route.
 */
function* getRouteMatcher() {
  const matcher: boolean = yield call(routerSagas.matchesLocation, [
    AppRoutes.userLists.list,
    AppRoutes.userLists.sourceScrubLists,
  ]);

  return matcher;
}

/**
 * Initialize histograms after filters are fetched on home page.
 */
function* initializeHistogramsSaga() {
  const matcher: boolean = yield call(getRouteMatcher);
  const isSourceScrubRoute: boolean = yield select(listsSelectors.isSourceScrubRoute);

  if (matcher) {
    // when retrieving filters also make call to update histograms.
    // this is necessary for when the app is refreshed and histograms
    const allFiltersWithHistograms: Array<string> = yield select(
      searchSelectors.selectAllFiltersWithHistograms
    );
    const filter: Dictionary<PreselectedFilter> = yield select(
      filterSelectors.getFilter(isSourceScrubRoute ? 'sslists' : 'watchlists')
    );
    yield put(actions.fetchHistogramFilters(filter, allFiltersWithHistograms));
  }
}

export default function* companyHistogramsSaga() {
  yield takeLatest(actions.fetchHistogramFilters, fetchHistogramFiltersSaga);
  yield takeLatest(globalFiltersActions.fetchCompanyFiltersSuccess, initializeHistogramsSaga);
  yield takeLatest(actions.filterChanged, filterChangeSaga);
  yield takeLatest(actions.toggleRefresh, toggleRefreshSaga);
  yield takeLatest(actions.init, initializeHistogramsSaga);
}
