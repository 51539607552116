import { createSelector } from 'reselect';
// models
import { AppState } from '@optx/redux/interfaces';

// specific selectors
const sortState = (state: AppState) => state.companyOutSourcing.sort;

export const getSorting = createSelector(sortState, state => state.sortBy);

export const isMultiSort = createSelector(sortState, state => state.multiSort);

export const isLoading = createSelector(sortState, state => state.loading);
