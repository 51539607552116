import React from 'react';
import ReactSelect from 'react-select';
import styled, { css } from 'styled-components';

import { SingleSelectProps } from './interfaces';

const Select = styled((props: SingleSelectProps) => <ReactSelect {...props} />)`
  min-width: 200px;
  width: 100%;

  .styled-single-select {
    &__control {
      border: 1px solid #d9d9d9;
      border-radius: 0;

      &--is-focused {
        border: 1px solid #d9d9d9;
      }
    }

    &__value-container {
      height: 40px;
    }

    &__placeholder {
      color: #bfbfbf;
      font-size: 16px;
      line-height: 24px;
    }

    &__indicator {
      color: #000000;
      &-separator {
        display: none;
      }
    }

    &__menu {
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      border-radius: 2px;
      margin-top: 4px;
      margin-bottom: 4px;
      z-index: 2;
    }

    &__option {
      color: rgba(0, 0, 0, 0.85);

      &--is-selected {
        background: none;
      }

      &--is-focused {
        background: #deebff !important;
      }
    }

    &__single-value {
      ${props =>
        props.valuePrefix &&
        css`
          color: #1890ff;
        `}
    }
  }

  &.bulk-action-watch-list__sorting {
    .styled-single-select {
      &__control {
        border: 0;
        justify-content: center;
        box-shadow: none;

        span {
          margin: 0;
          font-size: 14px;
        }
      }

      &__value-container {
        padding: 0;
        flex: 0.77;
      }

      &__indicator {
        color: #1890ff;
      }
    }
  }
`;

Select.defaultProps = {
  classNamePrefix: 'styled-single-select',
};

export const Styled = {
  Select,
};
