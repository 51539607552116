import { CaseReducer, PayloadAction, createReducer } from '@reduxjs/toolkit';
// models
import { ViewTransitionState } from './interfaces';
// constants
import { VIEW_TRANSITION_INITIAL_STATE } from '@optx/constants/grid';
// redux
import * as actions from './actions';

const initialState: ViewTransitionState = VIEW_TRANSITION_INITIAL_STATE;

export const toggleViewTransitionReducer: CaseReducer<
  ViewTransitionState,
  PayloadAction<boolean>
> = (draftState, action) => {
  draftState.isViewTransition = action.payload;
};

const reducer = createReducer<ViewTransitionState>(initialState, builder =>
  builder.addCase(actions.toggleViewTransition, toggleViewTransitionReducer)
);

export default reducer;
