import { createFeatureActionType } from '@optx/utils/feature';
import featureName from '../featureName';

export const TOGGLE_ADD_RESEARCH_RATIONALE = createFeatureActionType(
  featureName,
  'TOGGLE_ADD_RESEARCH_RATIONALE'
);
export const TOGGLE_ADD_RESEARCH_RATIONALE_SUCCESS = createFeatureActionType(
  featureName,
  'TOGGLE_ADD_RESEARCH_RATIONALE_SUCCESS'
);
export const CHANGE_FIELDS = createFeatureActionType(featureName, 'CHANGE_FIELDS');
export const CHANGE_RATIONALE = createFeatureActionType(featureName, 'CHANGE_RATIONALE');

export const REVERT_FIELDS_CHANGES = createFeatureActionType(featureName, 'REVERT_FIELDS_CHANGES');

export const SUBMIT_RESEARCH_RATIONALE = createFeatureActionType(
  featureName,
  'SUBMIT_RESEARCH_RATIONALE'
);
export const SUBMIT_RESEARCH_RATIONALE_SUCCESS = createFeatureActionType(
  featureName,
  'SUBMIT_RESEARCH_RATIONALE_SUCCESS'
);
