import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { isEqual } from 'lodash';
// models
import { CompanyFieldsGroup } from '@optx/models/companyFields';
import { EditAllInfo, EditAllFieldsGroup, FinancialYearsChangeLog } from './interfaces';
import {
  UpdateEditAllFields,
  EditAllInfoForm,
  CompanyEditAllDataPayload,
  EditAllCompanyResponse,
} from '@models/api/editAllInfo';
import { TouchesFinancialPayload } from '@models/api/touches';
import { PageInfo } from '@optx/models/table/Pagination';
import { RouteAliases } from '@optx/models/routes';
import { APIBoolean } from '@optx/models/api/generic';
// services
import { CompanyService } from '@services/api';
import NotificationService from '@services/NotificationService';
// utils
import { getRouteAlias } from '@optx/utils/routes';
import { getErrorMessage } from '@utils/api/errors';
import { formatFinalValues } from '@utils/touches';
// redux
import { selectors as profileSelectors } from '@redux/company/profile';
import { actions as customGlobalLoaderActions } from '@features/custom-global-loader';
import { actions as fundingRoundsActions } from '@redux/company/funding-rounds';
import {
  actions as auditFieldsActions,
  selectors as auditFieldsSelectors,
} from '@features/company/audit-trail/state';
import { actions as scoreGrowthActions } from '@features/company/graphs/score';
import { actions as actionEmployeeGrowthActions } from '@features/company/graphs/employee';
import * as actions from './actions';
import { selectors as editAllInfoSelectors } from './index';
import { actions as opportunityActions } from '@redux/company/opportunity-presentation';

function* toggleCompanyEditAllFormSaga(action: PayloadAction<EditAllInfo>) {
  const isModalOpen: boolean = yield select(editAllInfoSelectors.isOpen);

  if (isModalOpen) {
    yield put(actions.getEditAllInfo(action.payload.companyId));
  }
}

function* updateEditAllFormSaga(action: PayloadAction<UpdateEditAllFields>) {
  const { editAllFields: touchForm } = action.payload;

  const pageAlias: RouteAliases | null = getRouteAlias();

  yield put(customGlobalLoaderActions.toggle({ loading: true }));
  const initialFinancialState: Partial<EditAllInfoForm> | null = yield select(
    editAllInfoSelectors.getInitialEditAllState
  );

  const financialInfo: Array<CompanyFieldsGroup> = yield select(
    editAllInfoSelectors.getEditAllTabs
  );
  const financialYearsChangeLog: FinancialYearsChangeLog = yield select(
    editAllInfoSelectors.getFinancialYearsChangeLog
  );

  const initialFinancialData = formatFinalValues(
    { companyId: touchForm.companyId },
    initialFinancialState,
    financialInfo
  );

  const financialData = formatFinalValues(
    touchForm,
    initialFinancialState,
    financialInfo,
    financialYearsChangeLog
  );

  try {
    if (financialData && !isEqual(initialFinancialData, financialData)) {
      const normalizedFinancialData = {
        ...financialData,
        funding: {
          ...financialData.funding,
          capital_raised:
            initialFinancialData?.funding?.capital_raised !== null &&
            financialData?.funding?.capital_raised === null
              ? 0
              : financialData?.funding?.capital_raised,
        },
      };

      const resFinancialInfo: AxiosResponse<Partial<EditAllCompanyResponse>> = yield call(
        CompanyService.updateEditAllCompanyInfo,
        normalizedFinancialData
      );

      if (resFinancialInfo.data) {
        const financialSuccessMessage = 'Company Info updated successfully!';
        NotificationService.success(financialSuccessMessage);
        const updatedFinancialData = {
          ...financialData,
          financials: {
            gm: resFinancialInfo.data.gross_margin_list || [],
            annual_recurring_revenue: resFinancialInfo.data.annual_recurring_revenue || [],
            ebitda: resFinancialInfo.data.ebitda_list || [],
            revenue: resFinancialInfo.data.revenue_list || [],
            cashflow: financialData.financials?.cashflow,
            revenue_by_country: financialData.financials?.revenue_by_country,
            revenue_model: financialData.financials?.revenue_model,
            financial_notes: financialData.financials?.financial_notes,
          },
        };

        if (touchForm.companyId) {
          yield put(opportunityActions.fetchOpportunityPresentation(Number(touchForm.companyId)));
        }

        yield put(actions.updateCompanyEditAllInfoSuccess(updatedFinancialData));
        yield put(actions.updateAllCompanyEditAllInfoSuccess(resFinancialInfo.data));

        const {
          acquiring_company: fundingDataAcquiringCompany,
          ...fundingData
        }: TouchesFinancialPayload['funding'] = financialData.funding || {};

        const {
          acquiring_company: resFundingDataAcquiringCompany,
          ...resFundingData
        }: TouchesFinancialPayload['funding'] = financialData.funding || {};

        const fundingHasDiff =
          fundingData !== undefined &&
          resFundingData !== undefined &&
          !isEqual(fundingData, resFundingData);

        const acquiringCompanyHasDiff =
          fundingDataAcquiringCompany !== resFundingDataAcquiringCompany &&
          !!fundingDataAcquiringCompany !== !!resFundingDataAcquiringCompany;

        if (fundingHasDiff || acquiringCompanyHasDiff) {
          yield put(
            fundingRoundsActions.getCompanyFundingRounds(parseInt(touchForm.companyId || '0'))
          );
        }

        if (pageAlias === 'companyHistory') {
          const historyPagination: PageInfo = yield select(auditFieldsSelectors.getPagination);
          const field: string = yield select(auditFieldsSelectors.getSearchQuery);
          const optx: APIBoolean = yield select(auditFieldsSelectors.getOPTX);
          const internal: APIBoolean = yield select(auditFieldsSelectors.getInternal);
          const startDate: string = yield select(auditFieldsSelectors.getStartDate);
          const endDate: string = yield select(auditFieldsSelectors.getEndDate);

          yield put(
            auditFieldsActions.fetchChangedCompanyFields({
              companyId: parseInt(touchForm.companyId || '0'),
              pagination: historyPagination,
              field,
              internal,
              optx,
              startDate,
              endDate,
            })
          );
        }
      } else {
        const financialErrorMessage = 'Failed to update Company Info!';
        NotificationService.error(financialErrorMessage);
        yield put(actions.updateCompanyEditAllInfoFail(financialErrorMessage));
      }
    }
  } catch (e: any) {
    const errorMessageCatch = getErrorMessage(e, 'Failed to update Company Info!');
    NotificationService.error(errorMessageCatch);
    yield put(actions.updateCompanyEditAllInfoFail(errorMessageCatch));
  } finally {
    yield put(customGlobalLoaderActions.toggle({ loading: false }));
  }
}

function* getEditAllInfoSaga(action: PayloadAction<string>) {
  yield put(customGlobalLoaderActions.toggle({ loading: true, customText: '' }));

  try {
    const res: AxiosResponse<Record<EditAllFieldsGroup, CompanyFieldsGroup[]>> = yield call(
      CompanyService.getEditAllCompanyInfo,
      action.payload
    );

    if (res.data) {
      yield put(actions.getEditAllInfoSuccess(res.data));
    } else {
      const errorMessage = 'Failed to load financials!';
      yield put(actions.getEditAllInfoFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to load financials!');
    NotificationService.error(errorMessage);
    yield put(actions.getEditAllInfoFail(errorMessage));
  }

  yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
}

function* updateCompanyEditAllInfoSuccessSaga(
  action: PayloadAction<Partial<CompanyEditAllDataPayload>>
) {
  const companyId: number | undefined = yield select(profileSelectors.getCompanyId);

  if (companyId && companyId === action.payload.company_id) {
    const companyUrl: string | null = yield select(profileSelectors.getCompanyUrl);
    const company = {
      company_id: companyId,
      company_url: companyUrl,
    };

    yield put(scoreGrowthActions.fetchScoreGrowth(company));
    yield put(actionEmployeeGrowthActions.fetchEmployeeGrowth(companyId));
    yield put(fundingRoundsActions.getCompanyFundingRounds(companyId));
  }
}

export default function* editAllSaga() {
  yield takeLatest(actions.toggleCompanyEditAllFormDialog, toggleCompanyEditAllFormSaga);
  yield takeLatest(actions.getEditAllInfo, getEditAllInfoSaga);
  yield takeLatest(actions.updateCompanyEditAllInfo, updateEditAllFormSaga);
  yield takeLatest(actions.updateCompanyEditAllInfoSuccess, updateCompanyEditAllInfoSuccessSaga);
}
