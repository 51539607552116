import React from 'react';
import styled from 'styled-components';
import { GlobalOutlined } from '@ant-design/icons';
// models
import { CompanyInEquityTouch } from '@optx/features/equity-touch-integration/models';
import { Cell } from '@models/table/Cell';

const CompanyLink = styled.a`
  color: #c2cfe0;
  font-size: 22px;
  line-height: 1;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;

  &.not-active {
    color: red;
  }

  > svg {
    width: auto;
    height: auto;
  }
`;

const TableCellCompanyURL: React.FC<Cell<CompanyInEquityTouch>> = ({ record }) => {
  const { url_status: urlStatus, raw_url: rawUrl } = record;

  return (
    <>
      {rawUrl && (
        <CompanyLink
          href={rawUrl}
          title={rawUrl}
          target="_blank"
          className={urlStatus === 'active' ? undefined : 'not-active'}
          rel="noopener noreferrer"
        >
          <GlobalOutlined />
        </CompanyLink>
      )}
    </>
  );
};

export default TableCellCompanyURL;
