import { call, put, takeLatest, select, fork } from 'redux-saga/effects';
import queryString from 'query-string';
import { PayloadAction } from '@reduxjs/toolkit';
// models
import { UserInformation } from '@optx/models/user';
import { UpdateUserSettingsPayload } from '@optx/models/api/user';
import { CompanyUserContact, SearchPayload, SearchPayloadRequest } from '@models/api/contacts';
import { SearchSave } from '@models/search';
import {
  ResetSearchPayload,
  SearchContactsPayload,
} from '@redux/contacts/search/search/interfaces';
// utils
import mapFiltersToURLParams from '@optx/utils/filters/mapFiltersToURLParams';
import { matchSavedSearches, mapSearchCounterQuery, mapSortQuery } from '@optx/utils/search';

// services
import { Dictionary } from 'lodash';
import { BaseFilter } from '@optx/models/filters';
import { AxiosResponse } from 'axios';
import NotificationService from '../../../../services/NotificationService';
import SearchService from '../../../../services/api/SearchService';
// redux
import { getSavedSearches } from '../../saved-searches/selectors';
import { actions as searchKeyActions } from '@features/grid/searchkey';
import * as actions from './actions';
import { actions as sortActions } from '../sort';
import { getSearchData, getSearchKey } from './selectors';
import { actions as searchCountActions } from '@features/grid/search-count';
import { selectors as filtersSelectors } from '../../filters';
import { SEARCH_CONTACTS, RESET_SEARCH_CONTACTS, REMOVE_FILTER } from './actionTypes';
import {
  selectors as userInformationSelectors,
  actions as userInformationActions,
} from '../../../user/information';
import { actions as paginationActions } from '@features/grid/pagination';

/**
 * Update contacts filters for current session.
 */
function* updateFilterSettings(
  payload: SearchPayloadRequest,
  optionalSettings: Partial<UpdateUserSettingsPayload> = {}
) {
  const newPayload = { ...payload };
  // @ts-ignore
  delete newPayload.sortBy;
  // @ts-ignore
  delete newPayload.pagination;

  const queryPayload = mapSearchCounterQuery(newPayload.searchKey, newPayload.filter);

  const query = queryString.stringify(queryPayload, { arrayFormat: 'comma' });
  const userSettings: UserInformation | null = yield select(
    userInformationSelectors.getUserSettings
  );

  try {
    if (
      (userSettings && query !== userSettings.settings.session_settings?.contact_filters) ||
      Object.keys(optionalSettings).length
    ) {
      yield put(
        userInformationActions.updateUserSettings({ contact_filters: query, ...optionalSettings })
      );
    }
  } catch (error: any) {
    NotificationService.error('Failed persisting filters settings!');
  }
}

export function* searchContactsSaga(searchTitle?: string) {
  const searchPayload: SearchPayload = yield select(getSearchData);
  const filterMap: Dictionary<BaseFilter> = yield select(filtersSelectors.getFiltersMap);
  const filterURLParam = mapFiltersToURLParams(filterMap, searchPayload.filter);
  const userPageNumber: number = yield select(userInformationSelectors.getContactResultsPageNumber);
  const userSettings: UserInformation | null = yield select(
    userInformationSelectors.getUserSettings
  );
  let payloadRequest: SearchPayloadRequest = {
    ...searchPayload,
    filter: filterURLParam,
  };

  const checkUpdateFilters = { ...payloadRequest };
  // @ts-ignore
  delete checkUpdateFilters.sortBy;
  // @ts-ignore
  delete checkUpdateFilters.pagination;

  const queryPayload = mapSearchCounterQuery(
    checkUpdateFilters.searchKey,
    checkUpdateFilters.filter
  );

  const query = queryString.stringify(queryPayload, { arrayFormat: 'comma' });

  if (userSettings && query !== userSettings.settings.session_settings?.contact_filters) {
    payloadRequest = {
      ...payloadRequest,
      pagination: {
        ...payloadRequest.pagination,
        pageNumber: 1,
      },
    };
  }

  try {
    const res: AxiosResponse<CompanyUserContact[]> = yield call(
      SearchService.searchContacts,
      payloadRequest
    );

    if (res.data) {
      const savedSearches: Array<SearchSave> = yield select(getSavedSearches);
      let title = '';

      if (typeof searchTitle === 'string') {
        title = searchTitle;
      }

      if (!title) {
        title = matchSavedSearches(payloadRequest, savedSearches);
      }

      yield put(actions.searchContactsSuccess(res.data, title));

      if (payloadRequest.pagination.pageNumber !== userPageNumber) {
        yield call(updateFilterSettings, payloadRequest, {
          contact_results_page_number: payloadRequest.pagination.pageNumber,
        });
      } else {
        yield call(updateFilterSettings, payloadRequest);
      }

      yield put(
        paginationActions.changePaginationSuccess({
          gridKey: 'contacts',
          data: payloadRequest.pagination,
        })
      );
    } else {
      yield put(actions.searchContactsFail('Search contacts fail!'));
    }
  } catch (e: any) {
    const errorMessage = 'Search contacts fail, Server error!';
    yield put(actions.searchContactsFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* searchContactsCountSaga() {
  const searchPayload: SearchPayload = yield select(getSearchData);
  const filterMap: Dictionary<BaseFilter> = yield select(filtersSelectors.getFiltersMap);
  const filterURLParam = mapFiltersToURLParams(filterMap, searchPayload.filter);

  const customSearch: SearchPayloadRequest = {
    ...searchPayload,
    filter: filterURLParam,
  };

  yield put(searchCountActions.searchCount({ data: customSearch, gridKey: 'contacts' }));
}

export function* changeSortSaga() {
  const searchPayload: SearchPayload = yield select(getSearchData);
  const filterMap: Dictionary<BaseFilter> = yield select(filtersSelectors.getFiltersMap);
  const filterURLParam = mapFiltersToURLParams(filterMap, searchPayload.filter);
  const userPageNumber: number = yield select(userInformationSelectors.getContactResultsPageNumber);
  const payloadRequest: SearchPayloadRequest = {
    ...searchPayload,
    filter: filterURLParam,
    pagination: {
      pageNumber: 1,
      pageSize: searchPayload.pagination.pageSize,
    },
  };

  const sortQuery = queryString.stringify(mapSortQuery(searchPayload.sortBy), {
    arrayFormat: 'comma',
  });

  try {
    const res: AxiosResponse<CompanyUserContact[]> = yield call(
      SearchService.searchContacts,
      payloadRequest
    );

    if (res.data) {
      yield put(actions.searchContactsSuccess(res.data, searchPayload.searchTitle));

      if (payloadRequest.pagination.pageNumber !== userPageNumber) {
        yield put(
          userInformationActions.updateUserSettings({
            contact_sorting: sortQuery,
            contact_results_page_number: payloadRequest.pagination.pageNumber,
          })
        );
      } else {
        yield put(userInformationActions.updateUserSettings({ contact_sorting: sortQuery }));
      }

      yield put(
        paginationActions.changePaginationSuccess({
          gridKey: 'contacts',
          data: payloadRequest.pagination,
        })
      );
    } else {
      yield put(actions.searchContactsFail('Search contacts fail!'));
    }
  } catch (e: any) {
    const errorMessage = 'Search contacts fail, Server error!';
    yield put(actions.searchContactsFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* resetSaga(action: PayloadAction<ResetSearchPayload>) {
  yield put(
    searchKeyActions.setSearchKey({
      data: action.payload.resetTo?.searchKey || '',
      gridKey: 'contacts',
    })
  );

  const searchPayload: SearchPayload = yield select(getSearchData);
  const filterMap: Dictionary<BaseFilter> = yield select(filtersSelectors.getFiltersMap);
  const filterURLParam = mapFiltersToURLParams(filterMap, searchPayload.filter);
  const userPageNumber: number = yield select(userInformationSelectors.getContactResultsPageNumber);
  const payloadRequest: SearchPayloadRequest = {
    ...searchPayload,
    filter: filterURLParam,
    pagination: {
      pageNumber: 1,
      pageSize: searchPayload.pagination.pageSize,
    },
  };

  const sortQuery = queryString.stringify(mapSortQuery(searchPayload.sortBy), {
    arrayFormat: 'comma',
  });

  try {
    const res: AxiosResponse<CompanyUserContact[]> = yield call(
      SearchService.searchContacts,
      payloadRequest
    );

    if (res.data) {
      yield put(actions.searchContactsSuccess(res.data, action.payload.searchTitle || ''));

      if (payloadRequest.pagination.pageNumber !== userPageNumber) {
        yield fork(updateFilterSettings, payloadRequest, {
          contact_sorting: sortQuery,
          contact_results_page_number: payloadRequest.pagination.pageNumber,
        });
      } else {
        yield fork(updateFilterSettings, payloadRequest, { contact_sorting: sortQuery });
      }

      yield put(
        paginationActions.changePaginationSuccess({
          gridKey: 'contacts',
          data: payloadRequest.pagination,
        })
      );
      yield fork(searchContactsCountSaga);
    } else {
      yield put(actions.searchContactsFail('Search contacts fail!'));
    }
  } catch (e: any) {
    const errorMessage = 'Search contacts fail, Server error!';
    yield put(actions.searchContactsFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* searchWithCountSaga(
  action: PayloadAction<Partial<SearchContactsPayload> | undefined>
) {
  const data = action.payload;
  const searchKey: string = yield select(getSearchKey);

  if (!data) {
    yield put(searchKeyActions.setSearchKey({ data: searchKey, gridKey: 'contacts' }));
  } else {
    yield put(searchKeyActions.setSearchKey({ data: data?.searchKey || '', gridKey: 'contacts' }));
  }

  yield fork(searchContactsSaga);
  yield fork(searchContactsCountSaga);
}

export default function* searchContactsSagas() {
  yield takeLatest(SEARCH_CONTACTS, searchWithCountSaga);
  yield takeLatest(REMOVE_FILTER, searchWithCountSaga);
  yield takeLatest(RESET_SEARCH_CONTACTS, resetSaga);
  yield takeLatest(sortActions.changeSortAction, changeSortSaga);
}
