import React from 'react';
import { Typography } from 'antd';
import numeral from 'numeral';
import { useSelector } from 'react-redux';
// models
import { GridKeys, GridKeysMain } from '@optx/models/grid';
// utils
import { getPageSize } from '@utils/table/pagination';
// redux
import { selectors as searchCountSelectors } from '@features/grid/search-count';
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as listsSelectors } from '@redux/lists/details';
import { selectors as paginationSelectors } from '@features/grid/pagination';

interface ShowingTitleProps {
  gridKey: GridKeys | GridKeysMain;
}

const ShowingTitle = ({ gridKey }: ShowingTitleProps) => {
  const isSourceScrubRoute = useSelector(listsSelectors.isSourceScrubRoute);
  const listKey: GridKeys = isSourceScrubRoute && gridKey === 'lists' ? 'sslists' : 'watchlists';
  const searchCompanyCount = useSelector(
    searchCountSelectors.getSearchCount(gridKey === 'lists' ? listKey : gridKey)
  );
  const companies = useSelector(searchSelectors.getCompanies(gridKey as GridKeysMain));
  const pageNumber = useSelector(
    paginationSelectors.getPageNumber(gridKey === 'lists' ? listKey : gridKey)
  );

  const showingText = `Showing (${getPageSize(companies.length, searchCompanyCount, pageNumber)} 
  of ${numeral(searchCompanyCount).format('0,0')} 
  ${searchCompanyCount !== 1 ? 'Companies' : 'Company'})`;

  return (
    <Typography.Title
      level={4}
      type="secondary"
      style={{ fontWeight: 'normal', margin: '2px 0 0 0' }}
      title={showingText}
      ellipsis
    >
      {showingText}
    </Typography.Title>
  );
};

export default ShowingTitle;
