import { createSelector } from 'reselect';
import { selectors as userSelectors } from '@redux/user/information';
import { selectors as companySearchSelectors } from '@redux/company/search/search';
import { selectors as companyFiltersSelectors } from '@redux/company/filters';
import { selectors as saveSearchSelectors } from '@redux/company/saved-searches';
import { selectors as userListSelectors } from '@redux/user/user-list';
import { selectors as equityTouchSelectors } from '@redux/company/equity-touch';
import { isLoading as searchIsLoading } from '@features/grid/search/state/selectorsReusable';

export const isLoading = createSelector(
  userSelectors.isLoading,
  companyFiltersSelectors.isLoading,
  searchIsLoading('outreach'),
  saveSearchSelectors.isLoading,
  userListSelectors.isFieldLoading,
  equityTouchSelectors.isLoading,
  companySearchSelectors.loadingHistograms,
  (
    loadingUser,
    loadingFilters,
    searching,
    savingSearch,
    userManagement,
    equityTouch,
    loadingHistograms
  ) =>
    loadingUser ||
    loadingFilters ||
    searching ||
    savingSearch ||
    userManagement ||
    equityTouch ||
    loadingHistograms
);

export const saveSearchLoading = createSelector(
  searchIsLoading('outreach'),
  saveSearchSelectors.isLoading,
  (searching, savingSearch) => searching || savingSearch
);
