import { createSelector } from 'reselect';
// utils
import mapTags from '@optx/utils/filters/mapTags';
// local
import { generateUniqueKey } from '@optx/utils/uuid';
import { PipelineReportState } from './interfaces';
import { featureName } from '../featureName';

const pipelineReportState = (state: Record<typeof featureName, PipelineReportState>) =>
  state[featureName];

export const pipelineReportCompanies = createSelector(
  pipelineReportState,
  state => state.companies.data
);
export const pipelineReportFilters = createSelector(pipelineReportState, state => state.filters);
export const pipelineReportOpportunities = createSelector(
  pipelineReportState,
  state => state.opportunities
);
export const pipelineReportCardLoading = createSelector(
  pipelineReportState,
  state => state.loading
);

export const pipelineReportCompanyLoading = createSelector(
  pipelineReportState,
  state => state.companies.loading
);

export const pipelineReportLoading = createSelector(
  pipelineReportState,
  state => state.companies.loading || state.loading
);

export const filterModalIsOpen = createSelector(pipelineReportState, state => state.modalIsOpen);
export const totalFund = createSelector(pipelineReportState, state => state.totalFund);
export const fundAssociation = createSelector(pipelineReportState, state => state.fundAssociation);

export const getFilters = createSelector(pipelineReportState, state => {
  const { normalized, filter, clear } = state.filters;

  return { normalized, filter, clear };
});

export const getValues = createSelector(pipelineReportState, state => {
  const { values } = state.filters;

  return { ...values };
});

export const getFilterSources = createSelector(pipelineReportState, state => {
  const { data } = state.filters;

  return data;
});

export const getTags = createSelector(pipelineReportFilters, filters => {
  const { data, values, clear } = filters;

  let fundValue;

  if (values.fund) {
    let valueOfFund;

    if (Array.isArray(values.fund)) {
      valueOfFund = values.fund;
    } else {
      valueOfFund = (values.fund as string).split(',');
    }

    fundValue = {
      filter: 'country',
      label: 'Fund',
      values: (valueOfFund as string[]).map(item => ({ value: item })),
      isDefault: false,
      stringifiedValues: undefined,
      uniqueKey: generateUniqueKey(),
    };

    return [...mapTags(values, data, clear, []), fundValue];
  }

  return mapTags(values, data, clear, []);
});

export const isClearedFilter = createSelector(getTags, tags => {
  const tagNotDefault = tags.find(tag => {
    if (tag.isDefault === false && tag.values?.length > 0) {
      return true;
    }

    return false;
  });

  return !!tagNotDefault;
});

export const saveSearchLoading = createSelector(
  pipelineReportState,
  state => state.saveSearchLoading
);
