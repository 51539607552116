import { fork } from 'redux-saga/effects';
import { saga as histogramFiltersSaga } from '@features/histograms/histograms-advanced-search';
import { saga as searchSaga } from './search';
import columnsSaga from './columns/sagas';

export default function* searchCompaniesSaga() {
  yield fork(searchSaga);
  yield fork(histogramFiltersSaga);
  yield fork(columnsSaga);
}
