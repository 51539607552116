import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';

// models
import { UserInformation } from '@optx/models/user';
// utils
import { actions as userInformationActions } from '@redux/user/information';
// redux
import { fetchFailReducer } from '@redux/feature/fetch/reducers';
import { actions as companyFilterActions } from '@redux/company/filters';
import {
  clearSearch as clearSearchSearchActions,
  resetSearch as resetSearchSearchActions,
  resetFilter as resetFilterSearchActions,
} from '@features/grid/search/state/actions';
import { actions as searchCountActions } from '@features/grid/search-count';
import { models, reducers } from '@features/histograms/base-histograms';
import * as actions from './actions';
import { SearchCountPayload } from '@optx/features/grid/search-count/state/interfaces';

const initialState: models.HistogramFiltersState = {
  data: {},
  allFilters: [],
  lastChangedFilter: undefined,
  changedFilters: [],
  edit: null,
  searchCount: undefined,
  defaultHistograms: null,
  enabled: false,
  // fetch
  error: '',
  loading: false,
  fetchedAt: null,
};

// external reducers
export const searchCountSuccessReducer: CaseReducer<
  models.HistogramFiltersState,
  PayloadAction<SearchCountPayload>
> = (draftState, action) => {
  const { gridName } = action.payload;

  if (gridName === 'advancedSearch') {
    draftState.searchCount = undefined;
  }
};

const fetchUserInformationSuccessReducer: CaseReducer<
  models.HistogramFiltersState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  draftState.enabled = action.payload.settings.filters_refresh;
};

export const reducer = createReducer<models.HistogramFiltersState>(initialState, builder =>
  builder
    .addCase(actions.fetchHistogramFilters, reducers.fetchHistogramsReducer)
    .addCase(actions.fetchHistogramFiltersSuccess, reducers.fetchHistogramsFiltersSuccess)
    .addCase(actions.fetchHistogramFiltersFail, fetchFailReducer)
    .addCase(actions.toggleRefresh, reducers.toggleHistogramRefresh)
    .addCase(actions.filterChanged, reducers.filterChangedReducer)
    .addCase(actions.edit, reducers.editReducer)
    .addCase(actions.applyEdit, reducers.applyEditReducer)
    .addCase(actions.resetToDefault, reducers.resetToDefaultReducer)
    .addCase(actions.clear, reducers.clearReducer)
    // external reduces
    .addCase(
      companyFilterActions.fetchCompanyFiltersSuccess,
      reducers.fetchCompanyFiltersSuccessReducer
    )
    .addCase(clearSearchSearchActions, reducers.resetReducer)
    .addCase(resetSearchSearchActions, reducers.resetReducer)
    .addCase(searchCountActions.searchCountSuccess, searchCountSuccessReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
    .addCase(resetFilterSearchActions, reducers.resetFilterReducer)
);
