import React from 'react';
import { Menu } from 'antd';
import { FoundCompany } from '@optx/models/Company';
import Styled from './styled/Header.styled';

interface MatchesMenuProps {
  matches: Array<FoundCompany>;
  onClick: (companyId: number) => void;
}

const MatchesMenu = ({ matches, onClick: handleClick }: MatchesMenuProps) => {
  return (
    <Styled.ActionMenu>
      {matches.map(match => (
        <Menu.Item
          key={match.company_id}
          onClick={() => {
            handleClick(match.company_id);
          }}
        >
          {match.company_name}
        </Menu.Item>
      ))}
    </Styled.ActionMenu>
  );
};

export default MatchesMenu;
