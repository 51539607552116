import React from 'react';
import { Breadcrumb } from 'antd';
import { useSelector } from 'react-redux';
import { selectors as listsSelectors } from '@optx/common/selectors/lists';

const { Item } = Breadcrumb;

export const ListName: React.FC = ({ ...restProps }) => {
  const listName = useSelector(listsSelectors.getSelectedListName);

  return <Item {...restProps}>{listName}</Item>;
};

export default ListName;
