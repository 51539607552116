import { FC, useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select } from 'antd';
// models
import { SelectOption } from '@optx/models/Option';
// common
import PrefixedSelectionItem, {
  mapOptionsToLabeledValues,
} from '@optx/components/common/select/Select/PrefixedSelectionItem';
// redux
import {
  getStageType,
  getTouchType,
  getAllStageTypes,
  getAllRankTypes,
  getAllTouchTypes,
  getRankType,
} from '@redux/scheduled-touches/selectors';
import {
  changeScheduledTouchesStageType,
  changeScheduledTouchesTouchType,
  changeScheduledTouchesRankType,
  fetchScheduledTouches,
} from '@redux/scheduled-touches/actions';

interface CustomSelectProps {
  type: 'touchType' | 'stageType' | 'rankType';
}

const CustomSelect: FC<CustomSelectProps> = ({ type }) => {
  const selectedTouchType = useSelector(getTouchType);
  const selectedStageType = useSelector(getStageType);
  const selectedRankType = useSelector(getRankType);
  const stageOptions = useSelector(getAllStageTypes);
  const touchOptions = useSelector(getAllTouchTypes);
  const rankOptions = useSelector(getAllRankTypes);
  const [currentValue, setCurrentValue] = useState('');
  const dispatch = useDispatch();

  const touchTypesOptions = useMemo(
    () =>
      mapOptionsToLabeledValues(
        'Touch Type: ',
        touchOptions.map(item => ({
          ...item,
          label: item.name,
          value: item.value,
        })) as Array<SelectOption>,
        PrefixedSelectionItem,
        true
      ),
    [touchOptions]
  );
  const stageTypesOptions = useMemo(
    () =>
      mapOptionsToLabeledValues(
        'Stage Type: ',
        stageOptions.map(({ name, value }) => ({ label: name, value }))
      ),
    [stageOptions]
  );
  const rankTypesOptions = useMemo(
    () =>
      mapOptionsToLabeledValues(
        'Rank: ',
        rankOptions.map(item => ({ label: item, value: item }))
      ),
    [rankOptions]
  );

  let selectOptions: any[] = [];

  if (type === 'stageType') {
    selectOptions = stageTypesOptions;
  } else if (type === 'touchType') {
    selectOptions = touchTypesOptions;
  } else if (type === 'rankType') {
    selectOptions = rankTypesOptions;
  }

  const handleChange = useCallback(
    (value: string) => {
      if (type === 'stageType') {
        dispatch(changeScheduledTouchesStageType(value));
      } else if (type === 'touchType') {
        dispatch(changeScheduledTouchesTouchType(value));
      } else if (type === 'rankType') {
        dispatch(changeScheduledTouchesRankType(value));
      }

      dispatch(fetchScheduledTouches());
    },
    [dispatch, type]
  );

  useEffect(() => {
    if (type === 'stageType') {
      setCurrentValue(selectedStageType);
    } else if (type === 'touchType') {
      setCurrentValue(selectedTouchType);
    } else if (type === 'rankType') {
      setCurrentValue(selectedRankType);
    }
  }, [selectedRankType, selectedStageType, selectedTouchType, type]);

  return (
    <Select onChange={handleChange} value={currentValue} virtual={false}>
      {selectOptions.map(option => (
        <Select.Option
          key={option.value}
          value={option.value}
          className={option.parent ? 'sub-option' : ''}
        >
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CustomSelect;
