import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import AntIcon from '@ant-design/icons';
// services
import { UserService } from '@optx/services/api';
// redux
import { selectors as profileSelectors } from '@redux/company/profile';
// components
import { EditFieldRadioRationale, EditFieldRadio } from '@features/company/edit-fields/components';
import { getIsAdminOrAnalyst } from '@optx/redux/user/information/selectors';
import Icon from '../../../../common/Icon';
import PSGFitDefault from './PSGFitDefault';

interface PSGFitProps {
  isFromHistory?: boolean;
}

const ThumbsUp = (
  <AntIcon
    component={() => (
      <Icon iconName="thumbs-up" style={{ color: '#52C41A', fontSize: 27, marginTop: '-4px' }} />
    )}
  />
);
const ThumbsDown = (
  <AntIcon
    component={() => (
      <Icon iconName="thumbs-down" style={{ color: '#FA541C', fontSize: 27, marginTop: '-2px' }} />
    )}
  />
);

const PSGFit: React.FC<PSGFitProps> = ({ isFromHistory }) => {
  const company = useSelector(profileSelectors.getProfile);
  const isAdminOrAnalyst = useSelector(getIsAdminOrAnalyst);

  if (!company) {
    return null;
  }

  let icon: React.ReactNode = <></>;

  if (company.is_interesting === true) {
    icon = ThumbsUp;
  } else if (company.is_interesting === false) {
    icon = ThumbsDown;
  }

  return (
    <div className="psg-fit-wrapper">
      {company.is_interesting !== null ? (
        <>
          {isAdminOrAnalyst ? (
            <EditFieldRadioRationale
              record={company}
              value={company.is_interesting}
              isFromGrid={false}
              service={UserService.updateFitEvaluation}
              successMessage="Company PSG fit updated successfully!"
              errorMessage="Failed to update value!"
              fieldName="PSG Fit"
              isFromHistory={isFromHistory}
              placement="leftTop"
              destroyOnHide
              showOnlyRadio
              hidePen
            >
              <Button
                type="text"
                style={{ fontWeight: 600, textTransform: 'uppercase' }}
                icon={icon}
                className="ant-btn--is-fit d-flex"
                size="small"
              >
                PSG Fit
              </Button>
            </EditFieldRadioRationale>
          ) : (
            <EditFieldRadio
              record={company}
              value={company.is_interesting}
              isFromGrid={false}
              service={UserService.updateFitEvaluation}
              successMessage="Company PSG fit updated successfully!"
              errorMessage="Failed to update value!"
              fieldName="PSG Fit"
              isFromHistory={isFromHistory}
              placement="leftTop"
              showOnlyRadio
              hidePen
            >
              <Button
                type="text"
                style={{ fontWeight: 600, textTransform: 'uppercase' }}
                icon={icon}
                className="ant-btn--is-fit d-flex"
                size="small"
              >
                PSG Fit
              </Button>
            </EditFieldRadio>
          )}
        </>
      ) : (
        <PSGFitDefault />
      )}
    </div>
  );
};

export default React.memo(PSGFit);
