import { selectors as fetchSelectors } from '@redux/feature/fetch';
import { ShareListState } from './interfaces';

export const isLoading = (state: ShareListState) => fetchSelectors.isLoadingSelector(state);

export const getError = (state: ShareListState) => fetchSelectors.errorSelector(state);

export const isSubmitting = (state: ShareListState) => !!state.data && state.loading;

export const failedLoadingInfo = (state: ShareListState) => !state.data && !!state.error;

export const getListOwner = (state: ShareListState) => state.owner;

export const getListType = (state: ShareListState) => state.listType;

export const getAccessRightsOptions = (state: ShareListState) => {
  if (!state.data) {
    return [];
  }

  return state.data.access_rights;
};

/**
 * Select other users allowed access rights except owner.
 * @param state
 */
export const getOtherUsersAccessRightsOptions = (state: ShareListState) => {
  if (!state.data) {
    return [];
  }

  return state.data.share_options;
};

export const getSharedListUsers = (state: ShareListState) => {
  if (!state.data) {
    return [];
  }

  return state.data.shared_with;
};

export const getEditedSharedList = (state: ShareListState) => state.editedList;
