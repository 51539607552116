import React from 'react';
import styled from 'styled-components';
import { Tooltip, Typography } from 'antd';
import { DEFAULT_CELL_VALUE } from '../../../../../constants/table/cells';

const StyledDefaultCell = styled(Typography.Text)`
  max-width: 100%;
  /* Gray / gray-8 */
  color: #595959;
  margin-bottom: 0 !important;
`;

interface CellNotificationNameProps {
  value: any;
  customStyle: React.CSSProperties;
}

export const CellNotifcationName: React.FC<CellNotificationNameProps> = ({
  value,
  customStyle,
  ...restProps
}) => {
  return (
    <StyledDefaultCell style={customStyle} ellipsis {...restProps}>
      {value ? <Tooltip title={value}>{value}</Tooltip> : DEFAULT_CELL_VALUE}
    </StyledDefaultCell>
  );
};

/**
 *
 * @param value Default cell render for table column.
 */
const CellNotificationNameRender = (value: any, customStyle: React.CSSProperties) => {
  return <CellNotifcationName value={value} customStyle={customStyle} />;
};

export default CellNotificationNameRender;
