import { Progress } from 'antd';
import styled from 'styled-components';

const DocumentUploadItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;

  padding: 10px;
  margin-top: 30px;

  &.upload--progress {
    border: 1px solid #bae7ff;
    background: rgba(230, 247, 255, 0.5);
  }

  &.upload--success {
    border: 1px solid #d9f7be;
    background: #f6ffed;
  }
`;

const FileIconWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding: 12px;
  font-size: 24px;
  color: #1890ff;
  background: #fff;
`;

const FileInfo = styled.div`
  display: flex;
  font-size: 12px;
  width: 40%;
  max-width: 180px;

  > span {
    align-self: center;
    margin-left: 10px;
    line-height: 1.15;
  }
`;

const FileName = styled.span`
  display: inline-block;
  width: 100%;
  max-width: 130px;

  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const FileSize = styled.span`
  display: inline-block;
  width: 100%;
  color: #8c8c8c;
`;

const UploadProgress = styled(Progress)`
  width: 45%;

  & > .ant-progress-text {
    color: #8c8c8c;
  }
`;

export const Styled = {
  DocumentUploadItem,
  FileIconWrapper,
  FileInfo,
  FileName,
  FileSize,
  UploadProgress,
};
