interface LoadingState {
  loading: boolean;
}

interface ShouldFetchState {
  shouldFetch?: boolean;
}

/**
 * Get loading state.
 * @param state
 * @returns is loading state
 */
export const isLoadingSelector = (state: LoadingState) => state?.loading;
export const getShouldFetch = (state: ShouldFetchState) => state.shouldFetch;

interface ShouldInitialFetchState extends LoadingState {
  fetchedAt: null | string;
}

/**
 * Should initial fetch.
 * @param state
 */
export const shouldInitialFetchSelector = (state: ShouldInitialFetchState) =>
  !state.fetchedAt && !state.loading;

interface ErrorState {
  error: string | null;
}

/**
 * Error selector.
 * @param state
 */
export const errorSelector = (state: ErrorState) => state.error;
