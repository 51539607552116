import { Dictionary } from 'lodash';
import { createAction } from '@reduxjs/toolkit';
import { CompanyUserContact } from '@models/api/contacts';
import * as models from '@models/equityTouch';
import { SelectOption } from '@models/Option';
import * as types from './types';

export const equityTouchAvailability = createAction(
  types.CHECK_EQUITY_TOUCH_AVAILABILITY,
  (id: string, callback: (data: Dictionary<models.EquityTouchCheck>) => void) => ({
    payload: id,
    meta: callback,
  })
);

export const fetchEquityTouchFormData = createAction(
  types.FETCH_EQUITY_TOUCH_FORM_DATA,
  (id: number, callback: (data: Dictionary<models.EquityTouchFetchedField>) => void) => ({
    payload: id,
    meta: callback,
  })
);

export const fetchEquityTouchFormDataSuccess = createAction<
  Dictionary<models.EquityTouchFetchedField>
>(types.FETCH_EQUITY_TOUCH_FORM_DATA_SUCCESS);

export const fetchEquityTouchFormDataFail = createAction<string>(
  types.FETCH_EQUITY_TOUCH_FORM_DATA_FAIL
);

export const addToEquityTouch = createAction(
  types.ADD_TO_EQUITY_TOUCH,
  (
    id: string,
    formData: Dictionary<
      | string
      | models.EquityTouchSubmitField
      | models.GroupsSubmitField
      | models.WatchlistsSubmitField[]
    >,
    formValues: Dictionary<string | number | boolean | number[] | string[] | null | SelectOption>,
    callback: (data: { url: string; hasError?: boolean } | string) => void
  ) => ({
    payload: { id, formData, formValues },
    meta: callback,
  })
);

export const addToEquityTouchSuccess = createAction<
  Dictionary<string | number | boolean | number[] | string[] | null | SelectOption>
>(types.ADD_TO_EQUITY_TOUCH_SUCCESS);

export const updateEquityTouchSelectedContacts = createAction<number[]>(
  types.UPDATE_EQUITY_TOUCH_SELECTED_CONTACTS
);

export const updateEquityTouchSelectedSalesLoftContacts = createAction<number[]>(
  types.UPDATE_EQUITY_TOUCH_SELECTED_SALESLOFT_CONTACTS
);

export const resetAddToEquityTouch = createAction(types.RESET_ADD_TO_EQUITY_TOUCH);

export const saveContact = createAction(
  types.EQUITY_TOUCH_SAVE_CONTACT,
  (payload: Partial<CompanyUserContact>, callback?: VoidFunction) => ({
    payload,
    meta: callback,
  })
);
export const saveContactFail = createAction<string>(types.EQUITY_TOUCH_SAVE_CONTACT_FAIL);

export const addContact = createAction<Partial<CompanyUserContact>>(types.EQUITY_TOUCH_ADD_CONTACT);

export const updateCompanyETData = createAction<models.UpdateCompanyET>(
  types.UPDATE_COMPANY_ET_DATA
);

export const updateTemporaryFormValues = createAction<
  Dictionary<string | number | boolean | number[] | string[] | null | SelectOption>
>(types.UPDATE_TEMPORARY_FORM_VALUES);

export const autoSave = createAction<Dictionary<
  string | number | boolean | number[] | string[] | null | SelectOption
> | null>(types.AUTO_SAVE);
