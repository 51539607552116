import { Dictionary } from 'lodash';
import { createAction } from '@reduxjs/toolkit';
// interfaces/types
import { SearchCompanyPayload } from '@models/search';
import * as types from './types';
import { SearchStateData } from '@models/search';
import { ChangeCardSubfilterPayload } from './interfaces';

export const initializeMyCompaniesCards = createAction(types.INITIALIZE_MY_COMPANIES_CARDS);

export const fetchMyCompaniesCards = createAction(
  types.FETCH_MY_COMPANIES_CARDS,
  (payload: Partial<SearchCompanyPayload>, resetSubFilter?: boolean) => ({
    payload,
    meta: resetSubFilter,
  })
);
export const fetchMyCompaniesCardsSuccess = createAction<Dictionary<SearchStateData>>(
  types.FETCH_MY_COMPANIES_CARDS_SUCCESS
);
export const fetchMyCompaniesCardsFail = createAction<string>(types.FETCH_MY_COMPANIES_CARDS_FAIL);

export const changeCard = createAction<string>(types.CHANGE_CARD);

export const changeCardSubfilter = createAction<ChangeCardSubfilterPayload>(
  types.CHANGE_CARD_SUBFILTER
);

export const changeCardSubfilterSuccess = createAction<ChangeCardSubfilterPayload>(
  types.CHANGE_CARD_SUBFILTER_SUCCESS
);
