import { TableCellCompanyName } from '@components/common/table/AntTable/cells';
import { ColumnsType } from 'antd/lib/table';
import { SelectedCompanies } from '@optx/models/bulkActions';

export const BULK_EDIT_DEFAULT_COLUMNS: ColumnsType<SelectedCompanies> = [
  {
    dataIndex: 'company_name',
    title: 'Company Name',
    render: TableCellCompanyName,
  },
];
