import React, { useEffect, useState } from 'react';
import { Col, Typography } from 'antd';
import { OptionTypeBase } from 'react-select';
import { useField } from 'formik';
import { Select, FormItem } from 'formik-antd';
import { isEqual } from 'lodash';
// models
import { BaseField } from '@optx/models/companyFields';
import { FieldUsedFor } from './interface';

const { Option } = Select;

interface SingleSelectObjectFieldProps {
  field: BaseField;
  fieldUsedFor?: FieldUsedFor;
}

const SingleSelectObjectField: React.FC<SingleSelectObjectFieldProps> = ({
  field,
  fieldUsedFor,
}) => {
  const [selectedOption, , setSelectedOption] = useField<OptionTypeBase>(field?.id || '');
  const [selectedState, setSelectedState] = useState<string>();

  const handleChange = (value: string) => {
    setSelectedState(value);
  };

  useEffect(() => {
    if (typeof field?.value === 'object' && field.value !== null) {
      setSelectedState(field.value.value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  useEffect(() => {
    if (
      field &&
      !isEqual(
        {
          value: selectedState,
          label: field.data.find((option: OptionTypeBase) => option.value === selectedState)?.label,
        },
        selectedOption.value
      )
    ) {
      setSelectedOption.setValue({
        value: selectedState,
        label: field.data.find((option: OptionTypeBase) => option.value === selectedState)?.label,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);

  return (
    <Col span={8} className={field.id}>
      <Typography.Text>{field.label}</Typography.Text>
      <FormItem name={field.id}>
        <Select
          showSearch
          name={field.id}
          value={selectedState}
          onChange={handleChange}
          getPopupContainer={trigger => trigger.parentElement!}
          filterOption={(input: string, option: OptionTypeBase | undefined) => {
            const fullName = option ? `${option?.children.props.children}` : '';

            return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          disabled={field.editable === false}
        >
          {field.data &&
            field.data.length &&
            field.data?.map((option: OptionTypeBase) => (
              <Option key={option.value as string} value={option.value as string}>
                <span>{option.label}</span>
              </Option>
            ))}
        </Select>
      </FormItem>
    </Col>
  );
};

export default React.memo(SingleSelectObjectField);
