export const FETCH_COMPANY_ADDON_MANAGEMENT = 'FETCH_COMPANY_ADDON_MANAGEMENT';
export const FETCH_COMPANY_ADDON_MANAGEMENT_SUCCESS = 'FETCH_COMPANY_ADDON_MANAGEMENT_SUCCESS';
export const FETCH_COMPANY_ADDON_MANAGEMENT_FAIL = 'FETCH_COMPANY_ADDON_MANAGEMENT_FAIL';

export const CHANGE_SORT_COMPANY_ADDON_MANAGEMENT = 'CHANGE_SORT_COMPANY_ADDON_MANAGEMENT';
export const TOGGLE_PORTFOLIO_COMPANIES = 'TOGGLE_PORTFOLIO_COMPANIES';
export const CLEAR_ADDON_MANAGEMENT_SEARCH = 'CLEAR_ADDON_MANAGEMENT_SEARCH';
export const CLEAR_ADDON_MANAGEMENT_FETCHED_AT = 'CLEAR_ADDON_MANAGEMENT_FETCHED_AT';
export const RESET_ADDON_MANAGEMENT_FILTER = 'RESET_ADDON_MANAGEMENT_FILTER';
export const REMOVE_ADDON_MANAGEMENT_FILTER_TAG = 'REMOVE_ADDON_MANAGEMENT_FILTER_TAG';
export const SET_SEARCH_KEY = 'SET_SEARCH_KEY';
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS';
export const FETCH_ADDITIONAL_FILTERS = 'FETCH_ADDITIONAL_FILTERS';
export const FETCH_ADDITIONAL_FILTERS_SUCCESS = 'FETCH_ADDITIONAL_FILTERS_SUCCESS';
export const FETCH_ADDITIONAL_FILTERS_FAIL = 'FETCH_ADDITIONAL_FILTERS_FAIL';
export const UPDATE_ADDITIONAL_FILTERS = 'UPDATE_ADDITIONAL_FILTERS';
export const RESET_ADDITIONAL_FILTER = 'RESET_ADDITIONAL_FILTER';
export const REMOVE_COMPANY_FROM_ADDON_MANAGEMENT = 'REMOVE_COMPANY_FROM_ADDON_MANAGEMENT';
export const REMOVE_COMPANY_FROM_ADDON_MANAGEMENT_SUCCESS =
  'REMOVE_COMPANY_FROM_ADDON_MANAGEMENT_SUCCESS';
export const REMOVE_COMPANY_FROM_ADDON_MANAGEMENT_FAIL =
  'REMOVE_COMPANY_FROM_ADDON_MANAGEMENT_FAIL';
export const BULK_REMOVE_ADDON = 'BULK_REMOVE_ADDON';
