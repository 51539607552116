import { createSelector } from 'reselect';
import { featureName } from '../../featureName';
import { SourcingOutreachCombinedState } from '../interfaces';
import * as localFilters from './filters';

type StateSlice = Record<typeof featureName, SourcingOutreachCombinedState>;

export const selectFeatureState = (state: StateSlice) => state[featureName];
const selectFiltersState = createSelector(selectFeatureState, state => state.filters);

const filters = {
  getFilters: createSelector(selectFiltersState, localFilters.getFilters),
  searchKey: createSelector(selectFiltersState, localFilters.getSearchKey),
};

export const selectors = {
  filters,
};
