import React from 'react';
// models
import { SelectOption } from '@optx/models/Option';
// utils
import { checkOption } from '@optx/utils/select/select';
import { hasBlankOrNoneLabel, hasBlankLabel } from '@optx/utils/helpers';
// local
import Styled from './MultiSelectTags.styled';

interface PreviousMultiSelectedTagsProps<OptionTypeBase> {
  options: Array<OptionTypeBase>;
  showKey?: 'label' | 'value';
  hasNoneValue?: boolean;
  previousSelectedOptions: Array<any>;
  previousSelectedOptionsListTitle?: string;
}

const PreviousMultiSelectedTags = <OptionTypeBase extends SelectOption = SelectOption>({
  options,
  showKey = 'label',
  hasNoneValue = false,
  previousSelectedOptions,
  previousSelectedOptionsListTitle,
}: React.PropsWithChildren<PreviousMultiSelectedTagsProps<OptionTypeBase>>) => {
  const filterLabel = hasNoneValue ? hasBlankLabel : hasBlankOrNoneLabel;

  let shouldDisplayPreviousSelectedOptions = true;

  // Don't show tags if all options are selected.
  if (previousSelectedOptions.length === options.length) {
    shouldDisplayPreviousSelectedOptions = false;
  }

  return (
    <>
      {previousSelectedOptions.length !== 0 && (
        <Styled.Wrapper className="multiselect-tags-wrapper">
          {previousSelectedOptionsListTitle && (
            <Styled.Title level={5}>{previousSelectedOptionsListTitle}</Styled.Title>
          )}
          {shouldDisplayPreviousSelectedOptions
            ? previousSelectedOptions.map(option => {
                if (checkOption(showKey, filterLabel, option)) {
                  return '';
                }

                return (
                  <Styled.Tag color="blue" key={option.value}>
                    {option[showKey]}
                  </Styled.Tag>
                );
              })
            : 'All users'}
        </Styled.Wrapper>
      )}
    </>
  );
};

export default PreviousMultiSelectedTags;
