import { takeLatest, put, call, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
// models
import { BulkAiMl, BulkAiMlResponse } from './interfaces';
// constants
import { BULK_EDIT_COMPANY_LIMIT_SET_AS_AI_ML_COMPANY } from '@optx/config/bulkConfig';
// services
import { UserService } from '@optx/services/api';
import NotificationService from '@optx/services/NotificationService';
// redux
import { actions as customGlobalLoaderActions } from '@features/custom-global-loader';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { actions as editFieldsActions } from '../../edit-fields';
import * as actions from './actions';

function* bulkAiMlSaga(action: PayloadAction<BulkAiMl>) {
  const { selectedCompanies, isAiMl, page = 1 } = action.payload;
  const companies = [...selectedCompanies];
  const selectedCompaniesCount: number = selectedCompanies.length;

  const totalPageCount = Math.ceil(
    selectedCompaniesCount / BULK_EDIT_COMPANY_LIMIT_SET_AS_AI_ML_COMPANY
  );
  const startCompany = (page - 1) * BULK_EDIT_COMPANY_LIMIT_SET_AS_AI_ML_COMPANY;
  const companiesBatch = companies.slice(
    startCompany,
    startCompany + BULK_EDIT_COMPANY_LIMIT_SET_AS_AI_ML_COMPANY
  );

  const getCompanyCount =
    totalPageCount === page
      ? selectedCompaniesCount
      : page * BULK_EDIT_COMPANY_LIMIT_SET_AS_AI_ML_COMPANY;

  try {
    const res: AxiosResponse<BulkAiMlResponse> = yield call(
      UserService.bulkCompanyAiMl,
      companiesBatch.map(company => company.company_id),
      isAiMl
    );

    const isCancel: boolean = yield select(bulkActionsSelectors.editFields.cancel);
    const percentage = Math.round((getCompanyCount / selectedCompaniesCount) * 100);

    if (!isCancel) {
      if (res.data) {
        if (totalPageCount !== page) {
          yield put(editFieldsActions.updateCompleted(false));
          yield put(editFieldsActions.updateProgress(true));
          yield put(editFieldsActions.updatePercentage(percentage));

          yield put(
            actions.bulkAiMlSuccess({
              data: res.data.data,
              count: selectedCompaniesCount,
              successCount: getCompanyCount,
            })
          );
          yield put(
            actions.bulkAiMl({
              selectedCompanies,
              isAiMl,
              page: page + 1,
            })
          );
        } else {
          yield put(editFieldsActions.updateProgress(false));
          yield put(editFieldsActions.updateCompleted(true));
          yield put(editFieldsActions.updatePercentage(percentage));

          yield put(
            actions.bulkAiMlSuccess({
              data: res.data.data,
              count: selectedCompaniesCount,
              successCount: getCompanyCount,
            })
          );
          NotificationService.success('Companies having been updated successfully');
        }
      }
    } else {
      yield put(editFieldsActions.updateProgress(false));
      yield put(editFieldsActions.updateCompleted(true));
      yield put(editFieldsActions.updatePercentage(percentage));

      yield put(
        actions.bulkAiMlSuccess({
          data: res.data.data,
          count: selectedCompaniesCount,
          successCount: getCompanyCount,
        })
      );

      yield put(editFieldsActions.cancel(false));
      yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));

      const message = 'Canceled to update AI / ML for Company!';
      NotificationService.success(message);
    }
  } catch (e: any) {
    // save the title to reducer, create a new action
    yield put(editFieldsActions.updateProgress(false));
    yield put(editFieldsActions.updateCompleted(true));
    yield put(
      editFieldsActions.updatePercentage(BULK_EDIT_COMPANY_LIMIT_SET_AS_AI_ML_COMPANY * (page - 1))
    );
    const message = 'Failed to update AI / ML for Company!';
    NotificationService.error(message);
  }
}

export default function* bulkEditSaga() {
  yield takeLatest(actions.bulkAiMl, bulkAiMlSaga);
}
