import { createSelector } from 'reselect';
import { featureName } from '../featureName';
import { ChangedCompayFieldsState } from './interfaces';

const selectFeatureState = (state: any) => state[featureName] as ChangedCompayFieldsState;

export const getFields = createSelector(selectFeatureState, state => state.data);

export const loading = createSelector(selectFeatureState, state => state.loading);

export const getSearchQuery = createSelector(selectFeatureState, state => state.searchQuery);
export const getStartDate = createSelector(selectFeatureState, state => state.startDate);
export const getEndDate = createSelector(selectFeatureState, state => state.endDate);

export const getPagination = createSelector(selectFeatureState, state => state.pagination);
export const getCount = createSelector(selectFeatureState, state => state.count);
export const getFieldsToFilter = createSelector(selectFeatureState, state => state.fieldsToFilter);

export const getInternal = createSelector(selectFeatureState, state => state.internal);
export const getOPTX = createSelector(selectFeatureState, state => state.optx);

export const getHasReachedResultsEnd = createSelector(
  selectFeatureState,
  state => state.hasReachedResultsEnd
);
