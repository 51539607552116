import styled from 'styled-components';

const PSGCompanyLabel = styled.span<{ isLongCardView?: boolean; isFromNotifications?: boolean }>`
  text-align: center;
  color: #1e3a51;
  font-weight: bold;
  font-family: 'Times New Roman';
  margin-right: 15px;
  margin-left: 18px;
  font-size: ${props =>
    props.isLongCardView ? '30px' : props.isFromNotifications ? '26px' : '28px'};
`;

const ScoreTrendingGrowth = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  justify-content: center;
`;

const TrendingValueSpan = styled.span`
  display: flex;
  align-items: center;
  gap: 3px;
`;

export const Styled = { PSGCompanyLabel, ScoreTrendingGrowth, TrendingValueSpan };
