import { createAction } from '@reduxjs/toolkit';
// models
import { CompanyBulkEditingResults } from '@optx/models/bulkActions';
import * as types from './types';
import { BulkEditFields, BulkEditFieldsSuccess } from './interfaces';

export const bulkEditField = createAction<BulkEditFields>(types.bulkEditField);

export const bulkEditFieldSuccess = createAction<BulkEditFieldsSuccess>(types.bulkEditFieldSuccess);

export const updateProgress = createAction<boolean>(types.updateProgress);

export const updateCompleted = createAction<boolean>(types.updateCompleted);

export const updatePercentage = createAction<number>(types.updatePercentage);

export const saveResults = createAction<CompanyBulkEditingResults[]>(types.saveResults);

export const resetProgress = createAction(types.resetProgress);

export const cancel = createAction<boolean>(types.cancel);
