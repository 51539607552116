import React from 'react';
// components
import FixedLoader from '@components/common/loader';
import { useSelector } from 'react-redux';
import { isLoading } from './selectors';

const PageLoader = () => {
  const isLoadingPage = useSelector(isLoading);

  return <FixedLoader loading={isLoadingPage} />;
};

export default PageLoader;
