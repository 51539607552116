/* eslint-disable max-len */
import React, { useCallback, useEffect } from 'react';
import { Card, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// redux
import {
  actions as salesloftActions,
  selectors as salesloftSelectors,
} from '@redux/contacts/salesloft';
// components
import SalesloftTable from './SalesloftTable';
import SelectCadence from './SelectCadence';
import SelectContactTitle from './SelectContactTitle';
import CadenceTitle from '@optx/screens/App/SalesloftBulk/components/common/CardTitle';
// hooks
import { useSalesloftAuth } from '../../../../common/hooks/salesloft';

const SalesloftCadence: React.FC = () => {
  const dispatch = useDispatch();
  const { fetchSalesloftAuth } = useSalesloftAuth();
  const cadences = useSelector(salesloftSelectors.getCadences);

  const fetchCadences = useCallback(
    (payload: string, cb?: () => void) =>
      dispatch(salesloftActions.fetchSalesloftCadences(payload, cb)),
    [dispatch]
  );

  const receiveMessage = (event: any) => {
    if (event.data === 'Salesloft authentication succeeded') {
      if (cadences.length <= 1) {
        fetchCadences('', undefined);
        dispatch(salesloftActions.salesloftPreloadContacts());
      }
    }
  };

  window.addEventListener('message', receiveMessage, false);

  useEffect(() => {
    if (cadences.length <= 1) {
      fetchCadences('', () => {
        fetchSalesloftAuth('', url => {
          window.open(
            url,
            'popUpWindow',
            'height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
          );
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row gutter={16} className="add-to-salesloft__wrapper">
      <Col span={24} xl={12}>
        <Card title={<SelectContactTitle />}>
          <SalesloftTable />
        </Card>
      </Col>
      <Col span={24} xl={12}>
        <Card
          className="cadence-card"
          title={
            <CadenceTitle
              title="Step 2: Select a Cadence"
              subtitle="The primary contact from the company will automatically be added to this SL Cadence"
            />
          }
        >
          <SelectCadence />
        </Card>
      </Col>
    </Row>
  );
};

export default SalesloftCadence;
