import { createAction } from '@reduxjs/toolkit';
import { SocialMediaChartData } from '@optx/models/charts/data';
import * as types from './types';

// fetch company touches actions
export const fetchCapterraData = createAction<number>(types.fetchCapterraData.REQUEST);
export const fetchCapterraDataSuccess = createAction<SocialMediaChartData>(
  types.fetchCapterraData.SUCCESS
);

export const fetchCapterraDataFail = createAction<string>(types.fetchCapterraData.FAILURE);
