import React from 'react';
import moment from 'moment';
import { Tooltip } from 'antd';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
import { NOT_AVAILABLE_VALUE } from '@constants/value';
import { PRESENT_DATE_FORMAT, SHORT_MONTH_YEAR_DATE_FORMAT } from '@optx/constants/format/date';
// utils
import { isIsoDateString } from '@utils/string';
import { formatDateFromDatePicker } from '@utils/date';

const TableCellDateInterval = (value: string, record: any) => {
  const { tenure_end_date: endDate } = record;
  let formattedValue = DEFAULT_CELL_VALUE;
  const trimedDate = value && formatDateFromDatePicker(value);

  if (value || endDate) {
    const formattedEndDate = isIsoDateString(endDate)
      ? moment(endDate).format(SHORT_MONTH_YEAR_DATE_FORMAT)
      : endDate || PRESENT_DATE_FORMAT;

    const formatedStartDate = isIsoDateString(trimedDate)
      ? moment(trimedDate).format(SHORT_MONTH_YEAR_DATE_FORMAT)
      : NOT_AVAILABLE_VALUE;

    formattedValue = `${formatedStartDate} - ${formattedEndDate}`;
  }

  if (formattedValue === DEFAULT_CELL_VALUE) {
    return <>{formattedValue}</>;
  }

  return <Tooltip title={formattedValue}>{formattedValue}</Tooltip>;
};

export default TableCellDateInterval;
