import {
  SalesloftEditData,
  SalesloftCadences,
  SalesloftBulkPostData,
} from '@optx/models/Salesloft';
import ApiClient, { API_URL } from './axios-client';

class SalesloftService {
  static client: ApiClient = new ApiClient(`${API_URL}/salesloft`);

  // fetch authentication url
  static getAuthUrl() {
    return SalesloftService.client.get('/oauth_login_url');
  }

  // fetch cadences
  static getCadences() {
    return SalesloftService.client.get('/cadences');
  }

  // post data
  static postSalesloftData(data: SalesloftEditData) {
    return SalesloftService.client.post('/add_person', data);
  }

  // add new contact
  static addPersonBulk(cadence: SalesloftCadences, companies: SalesloftBulkPostData[]) {
    const postData = {
      cadence_id: cadence.id,
      team_cadence: cadence.team_cadence,
      cadence_name: cadence.name,
      companies,
    };

    return SalesloftService.client.post('/add_person_bulk', postData);
  }

  // make this request after authorization call to improve performance
  static preloadContacts() {
    return SalesloftService.client.get('/preload_people');
  }
}

export default SalesloftService;
