import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Checkbox, Button } from 'antd';
// utils
import { getCompanyProfileURL } from '@optx/utils/routes';
import columns from '@components/common/table/TableCompanyMerges/defaultColumns';
// redux
import { selectors as mergeSelectors, actions as mergeActions } from '../state';
// styles
import { Styled } from './UnmergeModal.styled';

interface UnmergeModalProps {
  companyId: number;
}

const UnmergeModal: React.FC<UnmergeModalProps> = ({ companyId }) => {
  const isInProgress = useSelector(mergeSelectors.getUnmergeLoading);
  const isCompleted = useSelector(mergeSelectors.getUnmergeCompleted);
  const isModalVisible = useSelector(mergeSelectors.getUnmergeModalVisible);
  const selectedCompanies = useSelector(mergeSelectors.getSelectedCompanies);
  const shouldCopyData = useSelector(mergeSelectors.getShouldCopyData);
  const unmergedCompanyId = useSelector(mergeSelectors.getUnmergedCompanyId);
  const unmergeError = useSelector(mergeSelectors.getUnmergeError);
  const dispatch = useDispatch();

  const handleOk = () => {
    if (isCompleted) {
      dispatch(mergeActions.resetUnmerge());
    } else {
      dispatch(
        mergeActions.unmergeCompanies({
          companyId,
          selectedCompanies,
        })
      );
    }
  };

  const handleCancel = () => {
    if (!isInProgress) {
      dispatch(mergeActions.resetUnmerge());
    }
  };

  const openInNewTab = () => {
    unmergedCompanyId && window.open(getCompanyProfileURL(unmergedCompanyId), '_blank');
  };

  return (
    <Styled.ModalWrapper
      title="Un-Merge Selected Companies"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={840}
      okButtonProps={{ loading: isInProgress }}
      okText={isCompleted ? 'OK' : 'Unmerge'}
      destroyOnClose
      maskClosable={false}
      cancelButtonProps={{
        disabled: isCompleted,
      }}
    >
      {!isCompleted && (
        <>
          <Styled.ResultWrapper
            subTitle="By unmerging, the selected companies will be removed from this companies record and a new
        company record will be created."
            icon={<></>}
          />
          <Table
            dataSource={selectedCompanies}
            columns={columns}
            pagination={false}
            rowKey="id"
            bordered
            className="legacy-table overflow-auto"
            tableLayout="fixed"
          />
          <Checkbox
            className="mt-4"
            checked={shouldCopyData}
            onChange={() => dispatch(mergeActions.toggleShouldCopyData())}
          >
            Should OPTX data be copied to new company?
          </Checkbox>
        </>
      )}
      {isCompleted && (
        <>
          <Styled.ResultWrapper
            subTitle={`${!unmergeError ? 'Un-Merge Completed' : unmergeError}`}
            icon={<></>}
            style={{ paddingTop: unmergeError ? '48px' : 0 }}
          />
          {!unmergeError && (
            <Button className="d-flex mx-auto" type="primary" onClick={openInNewTab}>
              Show in OPTX
            </Button>
          )}
        </>
      )}
    </Styled.ModalWrapper>
  );
};

export default UnmergeModal;
