import { createSelector } from 'reselect';
import { sortColumns } from '@utils/table/sorting';
import { AppState } from '@optx/redux/interfaces';
import { defaultColumns } from '@components/common/table/TableCompanyTabLists';
import { getDefaultScore } from '@optx/redux/user/information/selectors';

const selectCompanyTabList = (state: AppState) => state.companyTabLists;

export const isLoading = createSelector(selectCompanyTabList, state => state?.loading);
export const shouldFetch = createSelector(
  selectCompanyTabList,
  state => !state.loading && !state.fetchedAt
);
export const getListData = createSelector(selectCompanyTabList, state => state.data);

export const getHasMore = createSelector(selectCompanyTabList, state => state.hasMore);
export const getPaginationQuery = createSelector(selectCompanyTabList, state => state.pagination);

export const getGridColumns = createSelector(
  getPaginationQuery,
  getDefaultScore,
  (pagination, defaultOptxScore) => sortColumns(defaultColumns, pagination.sortBy, defaultOptxScore)
);
