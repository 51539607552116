import { Dictionary } from 'lodash';
import { FilterSource, Filter, PreselectedFilter } from '@models/filters';
import { PageInfo } from '@models/table/Pagination';
import { SearchSaveResponse } from '@models/search';
import { SortByRule } from '@models/table/sorting';
import { CustomUIViewIds } from '@optx/models/user';

export const prepareFetchSuccess = (
  searchSaveResponses: Dictionary<SearchSaveResponse>,
  filterSources: Array<FilterSource<Filter<any>>>,
  clearedFilters: Dictionary<PreselectedFilter>,
  total?: number,
  query?: string,
  pagination?: PageInfo,
  sortBy?: Array<SortByRule<any>>,
  addCustomViews?: boolean,
  defaultCustomUIView?: CustomUIViewIds,
  isAnalyst?: boolean
) => ({
  payload: searchSaveResponses,
  meta: {
    filterSources,
    clearedFilters,
    total,
    query,
    pagination,
    sortBy,
    addCustomViews,
    defaultCustomUIView,
    isAnalyst,
  },
});

// save search
export const prepareSaveSearch = (title: string, filters: Dictionary<PreselectedFilter>) => ({
  payload: {
    title,
    filters,
  },
});

export const prepareSaveSearchSuccess = (
  searchSaveResponses: SearchSaveResponse,
  filterSources: Array<FilterSource<Filter<any>>>,
  clearedFilters: Dictionary<PreselectedFilter>,
  shouldFetch?: boolean
) => ({
  payload: searchSaveResponses,
  meta: { filterSources, clearedFilters, shouldFetch },
});

// rename search
export const renameSearchPrepare = (
  searchId: number | string,
  newTitle: string,
  viewId?: string | number
) => ({
  payload: {
    searchId,
    title: newTitle,
    viewId,
  },
});

// add search id and title to store when appyling a saved search
export const prepareSavedSearch = (searchId: number | string, title: string) => ({
  payload: {
    searchId,
    title,
  },
});
