import styled, { css } from 'styled-components';

interface StickyHeaderProps {
  offsetTop?: string;
  dynamicOffsetTop?: number;
  isFullScreen?: boolean;
  isMyCompanies?: boolean;
}

export const StickyHeader = styled.div.attrs(props => ({
  id: 'mainHeader',
}))`
  position: sticky;
  margin-bottom: ${props => (props.isFullScreen ? (props.isMyCompanies ? '1%' : '5%') : null)};
  top: ${(props: StickyHeaderProps) => (!props.offsetTop ? '0' : props.offsetTop)};
  z-index: 10;
  background-color: #f0f2f5;

  ${(props: StickyHeaderProps) =>
    props.dynamicOffsetTop &&
    css`
      top: ${props.dynamicOffsetTop + 64}px;
    `}
`;
