import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
// models
import { CompanyUserContact } from '@models/api/contacts';
// utils
import { getErrorMessage } from '@optx/utils/api/errors';
// redux
import { actions as editContactModalActions } from '@redux/ui/modals/edit-profile-contact';
import { actions as loaderActions } from '@features/custom-global-loader';
import * as types from './types';
import * as actions from './actions';
import * as selectors from './selectors';
import { CompanyService } from '../../../services/api';
import NotificationService from '../../../services/NotificationService';

export function* fetchPrimaryContactsSaga(action: PayloadAction<string>) {
  try {
    const res: AxiosResponse<Array<CompanyUserContact>> = yield call(
      CompanyService.getPrimaryContacts,
      action.payload
    );

    if (res.data) {
      yield put(actions.fetchPrimaryContactsSuccess(res.data));
    } else {
      const errorMessage = 'Failed to fetch primary contacts!';
      yield put(actions.fetchPrimaryContactsFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = 'Failed to fetch primary contacts!';
    yield put(actions.fetchPrimaryContactsFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* editProfilePrimaryContactSaga(action: PayloadAction<Partial<CompanyUserContact>>) {
  yield put(loaderActions.toggle({ loading: true }));

  const editedContact: CompanyUserContact = yield select(selectors.getEditedContact);
  // api sends the phones numbers inside a additional_phones property
  // but we need to send them inside a phones prop
  const finalValues: Partial<CompanyUserContact> = {
    ...action.payload,
    additional_phones: undefined,
    phones: action.payload.additional_phones || [],
    // Add needed information for API call.
    person_id: editedContact!.person_id,
    company_id: editedContact!.company_id,
  };

  try {
    const res: AxiosResponse<CompanyUserContact> = yield call(
      CompanyService.editPrimaryContact,
      finalValues
    );

    if (res.data) {
      finalValues.linkedin = res.data.linkedin;
      finalValues.tenure_start_date = res.data.tenure_start_date;
      finalValues.tenure_end_date = res.data.tenure_end_date;
      yield put(editContactModalActions.toggle(false));
    }

    yield put(actions.editPrimaryContactSuccess(finalValues));
    yield put(actions.cancelEditingPrimaryContact());
    yield put(loaderActions.toggle({ loading: false }));
  } catch (error: any) {
    const message = getErrorMessage(error, 'Failed to edit primary contact!');
    NotificationService.error(message);
    yield put(actions.editPrimaryContactFail(message));
    yield put(loaderActions.toggle({ loading: false }));
  }
}

export function* editingPrimaryContactSaga(action: PayloadAction<Partial<CompanyUserContact>>) {
  yield put(editContactModalActions.toggle(true));
}

export function* cancelEditingPrimaryContactSaga() {
  yield put(editContactModalActions.toggle(false));
}

export default function* primaryContactsSaga() {
  yield takeLatest(types.FETCH_PRIMARY_CONTACTS, fetchPrimaryContactsSaga);
  yield takeLatest(actions.editPrimaryContact, editProfilePrimaryContactSaga);
  yield takeLatest(actions.editingPrimaryContact, editingPrimaryContactSaga);
  yield takeLatest(actions.cancelEditingPrimaryContact, cancelEditingPrimaryContactSaga);
}
