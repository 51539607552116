import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as favoriteSelectors, actions as favoriteActions } from '@redux/favorites';

/**
 * Hook used to toggle a company favorite status.
 * @param companyId company identifier.
 */
export const useCompanyFavorite = (companyId: number) => {
  const dispatch = useDispatch();
  const isFavorite = useSelector((state: any) => favoriteSelectors.isFavorite(state, companyId));

  const toggleFavorite = useCallback(() => {
    if (isFavorite) {
      dispatch(favoriteActions.deleteFavorite(companyId));
    } else {
      dispatch(favoriteActions.addFavorite(companyId));
    }
  }, [dispatch, isFavorite, companyId]);

  return {
    isFavorite,
    toggleFavorite,
  };
};
