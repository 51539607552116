import React from 'react';
import { Layout } from 'antd';
import classnames from 'classnames';
import { BasicProps } from 'antd/lib/layout/layout';

const { Content } = Layout;

interface MainContentProps extends BasicProps {
  hasFixedHeader?: boolean;
}

const MainContent: React.FC<MainContentProps> = ({ hasFixedHeader, className, ...restProps }) => {
  const contentClassName = classnames(className, { 'has-fixed-header': hasFixedHeader });

  return <Content className={contentClassName} {...restProps} />;
};

MainContent.defaultProps = {
  className: 'main-content',
};

export default React.memo(MainContent);
