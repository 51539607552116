import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import { styledColors } from '@utils/theme/colors';
import { useSelector } from 'react-redux';
import selectedCompanies from '@features/bulk-actions/state/selectors/selectedCompanies';
import { SelectedCompaniesGrids } from '@optx/models/bulkActions';

const CountWrapper = styled(Typography.Text)`
  font-weight: bold;
  color: ${styledColors.blue6};
  margin-left: 20px;
`;

interface SelectedCountProps {
  type: SelectedCompaniesGrids;
}

export const SelectedCount: React.FC<SelectedCountProps> = React.memo(({ type }) => {
  const selectedCompaniesCount = useSelector(selectedCompanies.getSelectedCompanies(type)).length;

  if (!selectedCompaniesCount) return null;

  return (
    <CountWrapper>
      {selectedCompaniesCount} {selectedCompaniesCount > 1 ? 'Companies' : 'Company'} selected
    </CountWrapper>
  );
});
