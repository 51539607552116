import React from 'react';
import { Tag } from 'antd';
import { TagProps } from 'antd/lib/tag';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// models
import { SelectOption } from '@models/Option';
// utils
import { searchInNewTab } from '@utils/search';
// redux
import { actions as searchActions } from '@features/grid/search';
import { selectors as filterSourcesSelectors } from '@redux/company/filters';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';

const diversityKey = 'diversity';

interface DiversityTagsProps extends TagProps {
  diversityTags: Array<string> | null;
  newTab?: boolean;
}

const DiversityTags: React.FC<DiversityTagsProps> = ({
  diversityTags,
  newTab,
  onClick,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const clearedFilter = useSelector(filterSelectors.getClearedFilter('advancedSearch'));
  const sources = useSelector(filterSourcesSelectors.getFiltersMap);
  const { advancedSearchPathname } = useIsAnalyst();

  if (!diversityTags) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (newTab) {
      const filterValue = event.currentTarget.innerText;

      const option: SelectOption = sources[diversityKey].data.find(
        (option: SelectOption) => option.value === filterValue
      );

      if (option) {
        searchInNewTab(clearedFilter, sources, diversityKey, [option], {}, advancedSearchPathname);
      }
    } else {
      history.push(advancedSearchPathname);
      dispatch(searchActions.loadDiversityFilter(event.currentTarget.innerText));
      onClick && onClick(event);
    }
  };

  return (
    <>
      {diversityTags.map((tag, index) => (
        <Tag className="special-tag" onClick={handleClick} {...restProps} key={index}>
          {tag}
        </Tag>
      ))}
    </>
  );
};

export default DiversityTags;
