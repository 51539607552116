import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ListAccessRights } from '@optx/models/WatchList';
import { TableCellSearches } from '@optx/models/table/Cell';
import { actions as savedSearchesActions } from '@redux/contacts/saved-searches';
import ListActions from '@components/feature/drop-down/saved-searches/ListActions';

const TableCellActions: React.FC<TableCellSearches> = ({ row }) => {
  const { unique_id: uniqueId, title } = row;

  const dispatch = useDispatch();
  const deleteSearch = useCallback(
    (searchId: number | string) => dispatch(savedSearchesActions.deleteContactSearch(searchId)),
    [dispatch]
  );
  const renameContactSearch = useCallback(
    (title: string, searchId: number | string) =>
      dispatch(savedSearchesActions.renameContactSearch(searchId, title)),
    [dispatch]
  );

  return (
    <ListActions
      handleDeleteList={deleteSearch}
      handleRenameList={renameContactSearch}
      isRight
      listId={uniqueId || 0}
      title={title}
      accessRights={ListAccessRights.Edit}
      isContacts
    />
  );
};

export default TableCellActions;
