import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { AppState } from '@optx/redux/interfaces';

const selectCompanyNotes = (state: AppState) => state.notes.companyNotes;

export const isLoading = createSelector(selectCompanyNotes, state => state.loading);

export const makeSelectIsLoadingCompanyNotes = () =>
  createSelector(selectCompanyNotes, state => state.loading);

export const getCompanyNotesError = createSelector(selectCompanyNotes, state => state.error);

export const makeSelectCompanyNotesError = () =>
  createSelector(selectCompanyNotes, state => state.error);

export const shouldFetch = createCachedSelector(
  selectCompanyNotes,
  (state: AppState, companyId: number) => companyId,
  (state, companyId) => companyId !== -1 && !state.loading && !state.companyNotes[companyId]
)((state, companyId) => companyId);

export const makeSelectShouldFetch = (companyId: number) =>
  createSelector(selectCompanyNotes, state => !state.loading && !state.companyNotes[companyId]);

export const getCompanyNotes = createCachedSelector(
  selectCompanyNotes,
  (state: AppState, companyId: number) => companyId,
  (state, companyId) => {
    const noteIds = state.companyNotes[companyId];
    const { notesById } = state;

    if (noteIds) {
      return noteIds.map(noteId => notesById[noteId]);
    }

    return [];
  }
)((state, companyId) => companyId);

export const makeSelectGetCompanyNotes = (companyId: number) =>
  createSelector(selectCompanyNotes, state => {
    const noteIds = state.companyNotes[companyId];
    const { notesById } = state;

    if (noteIds) {
      return noteIds.map(noteId => notesById[noteId]);
    }

    return [];
  });
