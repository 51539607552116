import React from 'react';
import Styles from './EmployeeLegend.style';

const EmployeeLegend: React.FC = () => {
  return (
    <Styles.Wrapper>
      <Styles.LegendWrapper>
        <Styles.Legend />
      </Styles.LegendWrapper>
      <Styles.LegendText>Job Openings</Styles.LegendText>
    </Styles.Wrapper>
  );
};

export default EmployeeLegend;
