import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
// services
import { FilterService } from '@services/api';
import NotificationService from '@services/NotificationService';
// models
import { FilterGroup } from '@models/filters';
// utils
import { getErrorMessage } from '@utils/api/errors';
//redux
import { actions } from '../actions';

export function* fetchFiltersSaga() {
  try {
    const res: AxiosResponse = yield call(
      FilterService.getOptions,
      FilterGroup.ChecklistResearchInsightReport
    );

    if (res.data) {
      yield put(actions.filters.fetchSuccess(res.data.filters));
    } else {
      const errorMessage = 'Failed to fetch filters for Checklist Research Insights!';

      yield put(actions.filters.fetchFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(
      error,
      'Failed to fetch filters for Checklist Research Insights!'
    );

    yield put(actions.filters.fetchFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* filtersSaga() {
  yield takeLatest(actions.filters.fetch, fetchFiltersSaga);
}
