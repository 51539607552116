import React, { useState } from 'react';
import { Dropdown, Button, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
// components
import ExportCSVButton from './Header/ExportCSVButton';

const CRISearchDropdown: React.FC = () => {
  const [visible, setVisible] = useState(false);

  const menu = (
    <Menu>
      <ExportCSVButton hideDropdown={() => setVisible(!visible)} />
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      placement="bottomRight"
      visible={visible}
      onVisibleChange={() => setVisible(!visible)}
    >
      <Button type="text" icon={<EllipsisOutlined style={{ fontSize: 28 }} />} />
    </Dropdown>
  );
};

export default CRISearchDropdown;
