import { combineReducers } from 'redux';
// models
import { ListsAndSearchesState } from './interfaces';
// reducers
import paginationReducer from '../pagination';

const reducer = combineReducers<ListsAndSearchesState>({
  pageSize: paginationReducer,
});

export default reducer;
