import React from 'react';
import { Tag } from 'antd';

interface ListTagMoreProps {
  count: number;
  onTagMoreClick?: VoidFunction;
}

const ListTagMore: React.FC<ListTagMoreProps> = ({ count, onTagMoreClick }) => {
  return (
    <Tag color="cyan" onClick={onTagMoreClick && onTagMoreClick}>{`${count} more ${
      count === 1 ? 'list' : 'lists'
    }`}</Tag>
  );
};

export default ListTagMore;
