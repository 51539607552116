/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  YAxisProps,
} from 'recharts';
import { merge } from 'lodash';
// models
import { yAxisProps, yAxisPropsHorizontalBar } from '@optx/features/charts/config';
import { cartesianGridProps } from '@optx/components/common/charts/LineChart/config';

interface HorizontalBarChartRightLabelProps {
  chartHeight?: number;
  data: { label: string; value: number | null }[];
  dataKey: string;
  labelKey: string;
  color?: string;
  handleRedirects?: (label: string) => void;
}

const valueYAxisDifferentProps: YAxisProps = {
  tick: {
    fill: '#334D6E',
  },
};

const valueYAxisProps = merge({}, yAxisProps, valueYAxisDifferentProps);

const HorizontalBarChartRightLabel: React.FC<HorizontalBarChartRightLabelProps> = ({
  chartHeight,
  data,
  dataKey,
  labelKey,
  color,
  handleRedirects,
}) => {
  return (
    <ResponsiveContainer width={'100%'} height={chartHeight ?? 160}>
      <BarChart data={data} layout="vertical" syncId="syncId" barSize={10}>
        <CartesianGrid {...cartesianGridProps} />
        {/* YAxis are displayed in reversed order. */}
        <YAxis
          {...valueYAxisProps}
          dataKey={dataKey}
          type="category"
          yAxisId={0}
          axisLine={false}
          width={38}
          onClick={(_e, index: number) => handleRedirects && handleRedirects(data[index].label)}
          tick={{
            dx: 10,
            textAnchor: 'end',
            fill: '#1890FF',
            fontSize: 14,
            fontWeight: 'bold',
          }}
        />
        <Bar
          dataKey={dataKey}
          fill="#096DD9"
          radius={[0, 8, 8, 0]}
          width={49}
          barSize={10}
          background={{ fill: color ?? '#FAFAFA', radius: 8 }}
        />
        <XAxis dataKey={dataKey} domain={[0, 'dataMax']} type="number" hide />
        <YAxis
          {...yAxisPropsHorizontalBar}
          dataKey={labelKey}
          type="category"
          orientation="right"
          yAxisId={1}
          width={145}
          axisLine={false}
          tick={{
            dx: -92,
            textAnchor: 'start',
            style: { cursor: 'default' },
            fontWeight: '400',
            fill: '#334D6E',
          }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default HorizontalBarChartRightLabel;
