import styled from 'styled-components';
import { up } from 'styled-breakpoints';

const Section = styled.section`
  /* height: 100%; */
  background: #c2cfe0;

  &:nth-child(1) {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  }
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin: 0 0 30px;
  /* Gray / gray-1 */
  color: #ffffff;
`;

const Body = styled.div`
  padding: 0 16px 16px;

  ${up('md')} {
    padding: 0 40px 40px;
  }
`;

const BodyContent = styled.div`
  margin-top: -130px;
`;

const Banner = styled.div`
  height: 150px;
  background: linear-gradient(0deg, #064885 0%, #137edf 119.38%);
`;

const SubSection = styled.div`
  /* Gray / gray-1 */
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;

  padding: 18px 20px 14px 20px;
  margin-bottom: 36px;

  ${up('md')} {
    padding: 25px 40px;
  }
`;

const SubSectionTitle = styled.h3`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 20px;

  .anticon:first-child {
    color: #1890ff;
    font-size: 24px;
    margin: 0 15px 0 0;
  }

  .radaricon {
    font-size: 28px;
    margin-right: 12px;
  }
`;

const Styles = {
  Section,
  Title,
  Banner,
  Body,
  BodyContent,
  // subsection
  SubSection,
  SubSectionTitle,
};

export default Styles;
