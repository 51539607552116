import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// models
import { CardsState } from '../interfaces';
// redux
import { actions } from '../actions';

export const initialState: CardsState = {
  software: {},
  psgFit: {},
  score: {},
};

const fetchCardSuccessReducer: CaseReducer<
  CardsState,
  PayloadAction<Omit<CardsState, 'psgFit'> & { psg_fit: Record<string, string | null> }>
> = (draftState, action) => {
  const cards = action.payload;

  draftState.software = cards.software;
  draftState.score = cards.score;
  draftState.psgFit = cards.psg_fit;
};

const reducer = createReducer(initialState, builder =>
  builder.addCase(actions.card.fetchCardSuccess, fetchCardSuccessReducer)
);

export default reducer;
