import React, { useState, useEffect, useRef } from 'react';
import { Button, Row, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
// models
import { BaseFilter, FilterSource, Filter } from '@optx/models/filters';
import { FilterTagValue, FilterTag } from '@optx/models/tags';
import { GridKeysMain } from '@optx/models/grid';
// utils
import { getLocationMainGridKey } from '@optx/utils/grid';
// redux
import { selectors as searchSelectors } from '@features/grid/search';
// components
import FilterFactory from '@optx/components/feature/company-filters/Filter/FilterFactory';
import TagsWithPopover from '@optx/components/common/TagsWithPopover';
// styles
import Styled from './SecondaryQuickFilters.styles';

interface SecondaryQuickFilterProps {
  filter: BaseFilter<any>;
  onApply: () => void;
  onReset: () => void;
  source: FilterSource<Filter<any>>;
  options: FilterTag[];
  isMyCompany?: boolean;
  isAddon?: boolean;
}

const SecondaryQuickFilter: React.FC<SecondaryQuickFilterProps> = ({
  filter,
  onApply,
  onReset,
  source,
  options,
  isMyCompany,
  isAddon,
}) => {
  const listType = useSelector(
    searchSelectors.getListType(getLocationMainGridKey() as GridKeysMain)
  );
  const isFilterDisabled = listType === 'combined';

  const [isVisible, setIsVisible] = useState(false);
  const [targetOffset, setTargetOffset] = useState<[number, number]>([0, 0]);
  const FILTER_TAG_DEFAULT_VALUE = [
    {
      value: 'Any',
    },
  ];

  const getShortFilterLabel = (filter: BaseFilter<any>) => {
    const filterLabels = {
      sw_website_rank_per_country: 'US Website Rank',
      company_owner_id: 'Company Owner',
      num_employees: '# of Employees',
      capital_raised: 'Total Raised ($)',
      source_tag_filter: 'Data Source',
    };

    return (filterLabels as any)[filter.column] || filter.label;
  };

  const [filterTagValues, setFilterTagValues] =
    useState<FilterTagValue[]>(FILTER_TAG_DEFAULT_VALUE);

  const shortenTagLabel = (value: string) => {
    return value.replace('EquityTouch', 'ET');
  };

  useEffect(() => {
    const filterTags = options.filter(option => option.filter === filter.column);

    if (
      filterTags?.length === 1 &&
      filterTags[0].values?.length > 0 &&
      filter.column !== 'source_tag_filter'
    ) {
      setFilterTagValues(filterTags[0].values);
    } else if (filterTags?.length > 0 && filterTags[0].values?.length > 0) {
      const modifiedFilterTagValues = filterTags.reduce(
        (array: FilterTagValue[], filterValue: FilterTag) => {
          filterValue.values.forEach(item =>
            array.push({
              ...item,
              value: `${shortenTagLabel(filterValue.label)}: ${item.value}`,
            })
          );

          return array;
        },
        []
      );
      setFilterTagValues(modifiedFilterTagValues);
    } else {
      setFilterTagValues([
        {
          value: 'Any',
        },
      ]);
    }
  }, [options, filter.column]);

  const handleCancel = () => {
    onReset();
    setIsVisible(false);
  };

  const handleApply = () => {
    onApply();
    setIsVisible(false);
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLUListElement>) => {
    if (event.key === 'Enter') {
      handleApply();
    }
  };

  const triggerRef = useRef<HTMLDivElement>(null);

  const handleVisibleChange = (visible: boolean) => {
    if (isAddon && visible && (filter.column === 'stage' || filter.column === 'next_steps')) {
      const trigger = triggerRef.current;

      if (trigger) {
        const boundingLimits = trigger.getBoundingClientRect();
        const { height, bottom } = boundingLimits;
        const menuHeight = 440;

        // add a +10 offset to prevent scroll on bottom issue
        const yCoord = window.innerHeight - bottom < menuHeight ? menuHeight + height + 10 : 0;

        // adding a positive offset means it goes up on y-axis or left on x-axis,
        // relative to the trigger (filter label button)
        setTargetOffset([0, yCoord]);
      }
    }

    setIsVisible(visible);
  };

  const menu = (
    <Styled.MenuWrapper
      className={isMyCompany ? 'my-companies--filters' : ''}
      onKeyDown={handleDropdownKeyEvent}
      tabIndex={0}
    >
      <div>
        <FilterFactory isAddon={isAddon} filter={filter} source={source} />
        <Row justify="end" className="menu-buttons-wrapper">
          <Button style={{ marginRight: 8 }} onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleApply}>
            Apply
          </Button>
        </Row>
      </div>
    </Styled.MenuWrapper>
  );

  const content: JSX.Element = (
    <div>
      <Styled.DropdownWrapper
        visible={isVisible}
        onVisibleChange={flag => {
          !flag ? handleCancel() : handleVisibleChange(flag);
        }}
        overlay={menu}
        trigger={['click']}
        placement="bottomLeft"
        align={{ targetOffset }}
        disabled={isFilterDisabled}
      >
        <Button ref={triggerRef}>
          <Styled.TriggerWrapper>
            {getShortFilterLabel(filter)}:
            <Styled.SelectedLabel ellipsis>
              <TagsWithPopover filterTagValues={filterTagValues} />
            </Styled.SelectedLabel>
            <DownOutlined />
          </Styled.TriggerWrapper>
        </Button>
      </Styled.DropdownWrapper>
    </div>
  );

  return isFilterDisabled ? (
    <Tooltip title="Not supported for combined searches" placement="bottom">
      {content}
    </Tooltip>
  ) : (
    content
  );
};

export default SecondaryQuickFilter;
