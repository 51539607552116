import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Tooltip } from 'antd';
// models
import { CompanyProfile } from '@optx/models/Company';
import { SelectOption } from '@optx/models/Option';
import { ServiceType } from '@optx/features/company/edit-fields/state/interfaces';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// redux
import { selectors } from '@redux/user/user-list';
// hooks
import useUpdateFields from '@optx/features/company/edit-fields/hooks/useUpdateFields';
// components
import MultiSelect from '@optx/shared/view/molecules/Select/MultiSelect';
import EditPopover from '@optx/components/common/popover/EditPopover';
import { InlineEdit } from '@optx/shared/view/molecules/edit/InlineEdit';
import EditCellPen from '@optx/components/common/table/Companies/styled-cells/EditCellPen';
import Styled from './CellPopoverMultiselect.styled';

interface EditFieldMultiSelectProps {
  fieldName: 'OPS Team' | 'Deal Team' | 'Senior Deal Team Lead';
  record: CompanyProfile;
  value: string[] | null;
  labelValue?: string;
  hidePenIcon?: boolean;
  successMessage?: string;
  errorMessage?: string;
  isFromGrid: boolean;
  getPopupContainer?: ((triggerNode: HTMLElement) => HTMLElement) | undefined;
  isDynamicPopoverHeight?: boolean;
  destroyOnHide?: boolean;
  isFromHistory?: boolean;
  service: ServiceType;
}

const EditFieldMultiSelect: React.FC<EditFieldMultiSelectProps> = ({
  value,
  fieldName,
  isFromGrid,
  isFromHistory,
  labelValue,
  errorMessage,
  successMessage,
  hidePenIcon,
  destroyOnHide,
  getPopupContainer,
  isDynamicPopoverHeight,
  service,
  record,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { updateField } = useUpdateFields({
    companyUrl: record.company_url,
    companyId: record.company_id,
    service,
    fieldName,
    successMessage,
    errorMessage,
    isFromHistory,
  });

  const listSelectors = {
    'OPS Team': selectors.getUserListOptions,
    'Deal Team': selectors.getPsgUsers,
    'Senior Deal Team Lead': selectors.getSeniorDealTeamLeadOptions,
  };

  const list = useSelector(listSelectors[fieldName]);
  const [selectedValues, setSelectedValues] = useState<SelectOption<string>[]>([]);
  const [closePopup, setClosePopup] = useState<boolean>(false);

  const valueSelected = useCallback(() => {
    if (value) {
      const membersName: { value: string; label: string }[][] = [];
      value.forEach((_, index) => {
        const element = value[index];
        const options = list.filter(option => option.label === element);
        membersName.push(options);
      });

      return membersName.flat();
    }

    return [];
  }, [list, value]);

  const handleSave = () => {
    if (value?.toString() !== selectedValues.map(value => value.label).toString()) {
      updateField(selectedValues);
      setClosePopup(true);
    }

    setClosePopup(true);
  };

  const handleCancel = () => {
    setClosePopup(true);
    setSelectedValues(valueSelected);
  };

  const handleAllValuesSelected = () => {
    if (value?.length === list.length) {
      return 'ALL';
    }

    return value ? value.join(', ') : value;
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  useEffect(() => {
    if (!closePopup) {
      setSelectedValues(valueSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closePopup, value]);

  const getIsDisabled = () => {
    if (!selectedValues.length && !value?.length) return true;

    let allValuesExist = true;
    value?.forEach(item =>
      selectedValues.find(option => option.label === item) ? false : (allValuesExist = false)
    );

    let disabled = false;
    if (allValuesExist && selectedValues.length === value?.length) disabled = true;

    return disabled;
  };

  const content = (
    <div
      className="addon-popover"
      role="presentation"
      onKeyDown={handleDropdownKeyEvent}
      tabIndex={0}
    >
      <Styled.CellPopoverMultiselect>
        <MultiSelect
          value={selectedValues}
          getPopupContainer={(trigger: any) => trigger.parentElement}
          onChange={(option, action) => {
            setSelectedValues(option as SelectOption[]);
          }}
          onMenuClose={() => containerRef.current && containerRef.current.focus()}
          options={list}
          hasButtons
        />
        <div className="profile-information__popover-buttons">
          <Button className="profile-information__cancel" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="profile-information__save"
            type="primary"
            disabled={getIsDisabled()}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </Styled.CellPopoverMultiselect>
    </div>
  );

  if (isFromGrid) {
    return (
      <InlineEdit
        action={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <EditCellPen
            setClosePopup={setClosePopup}
            closePopup={closePopup}
            content={content}
            title={`Edit "${fieldName}" value`}
          />
        }
        fill
      >
        {value}
      </InlineEdit>
    );
  }

  return (
    <EditPopover
      content={content}
      title={`Edit "${fieldName}" value `}
      setClosePopup={setClosePopup}
      closePopup={closePopup}
      onVisibilityChange={handleCancel}
      getPopupContainer={getPopupContainer}
      destroyOnHide={destroyOnHide}
      isDynamicPopoverHeight={isDynamicPopoverHeight}
      hidePenIcon={hidePenIcon}
    >
      {labelValue !== undefined ? (
        labelValue
      ) : (
        <Tooltip
          className="edit-field--text"
          title={handleAllValuesSelected() || DEFAULT_EMPTY_VALUE}
        >
          {handleAllValuesSelected() || DEFAULT_EMPTY_VALUE}
        </Tooltip>
      )}
    </EditPopover>
  );
};

export default React.memo(EditFieldMultiSelect);
