import { EditAllTabsProps } from '@optx/models/company/CompanyEditAll';
import { EditAllInfoKeys } from '../state/interfaces';
import { EditAllInfoForm } from '@optx/models/api/editAllInfo';

// Define an array to store the edit all tabs
export const EDIT_ALL_TABS: Array<EditAllTabsProps> = [
  {
    name: 'All Fields',
    key: 'editAllFieldsTab',
    title: 'All Fields',
    hasSearch: true,
  },
  {
    name: 'EQT VIEW',
    key: 'eqtViewTab',
    title: 'Equity Touch Ordering',
  },
  {
    name: 'Proprietary Info',
    key: 'proprietaryFieldsTab',
    title: 'Proprietary Info',
  },
  {
    name: 'Grid Order',
    key: 'gridOrderTab',
    title: 'Grid Order',
  },
  {
    name: 'ID Info',
    key: 'editIdInfoTab',
    title: 'Identifying Information',
  },
];

export const COMPANY_ALL_INFO_KEYS: EditAllInfoKeys[] = [
  'company_id',
  'company_owner',
  'owner_img',
  'company_name',
  'company_url',
  'logo_url',
  'is_in_et',
  'location',
  'year_founded',
  'company_owner_id',
  'stage',
  'contacts',
  'pipeline_rank',
  'source_tag',
];

export const EDIT_ALL_INFO_FORM: EditAllInfoForm = {
  companyId: '',
  annual_recurring_revenue: [],
  revenue: [],
  gm: [],
  ebitda: [],
  revenue_model: [],
  revenue_by_country: '',
  cashflow: '',
  financial_notes: '',
  other_notes: '',
  capital_raised: '',
  cap_table: '',
  last_round_date: '',
  last_round: undefined,
  last_round_amount: undefined,
  acquiring_company: '',
  banker: undefined,
  banker_name: '',
  deal_type: '',
  equity_check: '',
  next_steps: '',
  transaction_notes: '',
  customers_number: undefined,
  customer_type: [],
  sales_and_marketing: '',
  sales_cycle: [],
  acv: '',
  clt: '',
  nps: '',
  net_retention: undefined,
  gross_retention: undefined,
  customer_churn: '',
  customer_concentration: '',
  cac: '',
  ltv_cac: '',
  delivery_model: [],
  cloud_provider: [],
  architecture: [],
  technology_notes: '',
  primary_contact: undefined,
  product_category: [],
  sub_sector: undefined,
  num_employees: undefined,
  competitors: [],
  fte_location: '',
  sub_vertical: [],
};
