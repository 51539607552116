import { each } from 'lodash';
// models
import { CompanyColumnDisplay } from '@optx/models/table/Columns';

/**
 * return a string list of default columns
 * @param columns columns object
 */
export const getDefaultVisibleColumns = (columns: CompanyColumnDisplay) => {
  const visibleColumns: string[] = [];

  each(columns, (value, key) => {
    if (value.visible) {
      visibleColumns.push(key);
    }
  });

  return visibleColumns.join(',');
};
