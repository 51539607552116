import { useInjectSaga } from 'redux-injectors';
import { saga } from '../../../redux/user/reset-password';

const key = 'resetPassword';

const useInjectResetPassword = () => {
  useInjectSaga({ key, saga });
};

export default useInjectResetPassword;
