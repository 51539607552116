import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ValueType } from 'react-select';
// models
import { SelectOption } from '@models/Option';
// redux
import { selectors, actions } from '@optx/redux/company/files';
// components
import { MultiSelectInline, MultiSelectProps } from '@shared/view/molecules/Select';
import * as Styled from './MultiSelectFilter.styled';

interface MultiSelectFilterProps extends MultiSelectProps {
  filterKey: 'file_type' | 'origin';
  companyId: number;
}

enum Prefix {
  'file_type' = 'File Type',
  'origin' = 'Origin',
}

export const MultiSelectFilter: React.FC<MultiSelectFilterProps> = ({ filterKey, companyId }) => {
  const dispatch = useDispatch();

  const [value, setValue] = useState<ValueType<SelectOption>>([]);

  const filters = useSelector(selectors.getFilters);
  const getOptions = useSelector(selectors.getOptions);

  const options: Array<SelectOption> = useMemo(() => {
    return getOptions[filterKey]?.map(option => ({
      label: option,
      value: option,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOptions]);

  useEffect(() => {
    if (filters[filterKey]) {
      setValue(
        (filters[filterKey] as string[]).map((filter: string) => ({
          label: filter,
          value: filter,
        }))
      );
    } else {
      setValue(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, options, companyId]);

  const handleChange = (value: ValueType<SelectOption>) => {
    setValue(value);
  };

  const handleBlur = () => {
    dispatch(
      actions.changeFilter({
        key: filterKey,
        value:
          typeof value === 'string' ? value : (value as SelectOption[]).map(option => option.value),
      })
    );
  };

  return (
    <Styled.Wrapper>
      <MultiSelectInline
        options={options}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        allPrefix={Prefix[filterKey]}
        placeholder={Prefix[filterKey]}
      />
    </Styled.Wrapper>
  );
};

export default MultiSelectFilter;
