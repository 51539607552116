import { combineReducers } from 'redux';
import { AnalystHomeState } from '../interfaces';
import company from './company';
import insights from './insights';
import filters from './filters';
import activity from './activity';

export const reducer = combineReducers<AnalystHomeState>({
  company,
  insights,
  filters,
  activity,
});
