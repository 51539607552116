import { SelectOption } from '@models/Option';

export const psgFunctionOptions: SelectOption<string>[] = [
  { label: 'CEO', value: 'CEO' },
  { label: 'COO', value: 'COO' },
  { label: 'Corporate Development', value: 'Corporate Development' },
  { label: 'CTO', value: 'CTO' },
  { label: 'Customer Success/Support', value: 'Customer Success/Support' },
  { label: 'Executive Chairman', value: 'Executive Chairman' },
  { label: 'Finance', value: 'Finance' },
  { label: 'HR', value: 'HR' },
  { label: 'Legal', value: 'Legal' },
  { label: 'Marketing', value: 'Marketing' },
  { label: 'President/COO', value: 'President/COO' },
  { label: 'Product', value: 'Product' },
  { label: 'Sales', value: 'Sales' },
  { label: 'Technology', value: 'Technology' },
];

export const slackChannelOptions: SelectOption<string>[] = [
  { label: 'psg-ceo', value: 'psg-ceo' },
  { label: 'psg-cfo', value: 'psg-cfo' },
  { label: 'psg-executive_teams', value: 'psg-executive_teams' },
  { label: 'psg-gtm', value: 'psg-gtm' },
  { label: 'psg-hr', value: 'psg-hr' },
  { label: 'psg-techleader', value: 'psg-techleader' },
];

export const CONTACT_SELECT_LIMIT = 20;
