import React from 'react';
import { OptionProps } from 'react-select';
// Constants
import { SELECT_NONE } from '@components/common/select/constants';
import { SelectOption } from '@optx/models/Option';
import { StyledOption } from '@shared/view/molecules/Select/components';
import Styled from './SelectNoneOption.styled';

export const Option: React.FC<OptionProps<SelectOption>> = props => {
  const { children, ...restProps } = props;
  const { isSelected, data, isDisabled } = restProps;

  const isSelectNone = data.value === SELECT_NONE.value && data.label === SELECT_NONE.label;

  return (
    // @ts-ignore react-select theme is claching with styled-components theme property.
    // react-select property overrides the styled components one,
    // meaning getting dynamic values in styled components won't work.
    <Styled.Option highlight={isSelectNone} {...restProps}>
      <StyledOption.Checkbox checked={isSelected} disabled={isDisabled}>
        {children}
      </StyledOption.Checkbox>
    </Styled.Option>
  );
};

export default Option;
