import { AxiosError } from 'axios';
import { map, Dictionary } from 'lodash';
import APIErrorResponse from '@models/api/ApiErrorResponse';
import ErrorMessage from '@models/api/ErrorMessage';

const getAPIErrors = (error: AxiosError<APIErrorResponse>) => {
  if (error.isAxiosError && error.response) {
    const response = error.response;

    return map<Dictionary<string>, ErrorMessage>(response.data?.errors, (value, key) => ({
      type: key,
      description: value,
    }));
  }

  return [];
};

export const errorResponseFormat = (errorMessages: Array<ErrorMessage>) =>
  errorMessages.map(errorMessage => errorMessage.description).join('/n');

/**
 * Get messages from API error response or normal Javascript error.
 * @param error API error or simple error.
 * @param defaultError default error to be returned if the error is not from API.
 * @param errorMessagesFormat formatter function for api error messages.
 * OBS: axios error is an extension of Error.
 */
export const getErrorMessage = (
  error: AxiosError<APIErrorResponse> | Error,
  defaultError: string,
  errorMessagesFormat = errorResponseFormat
) => {
  // If is Javascript error.
  if (!(error as AxiosError).isAxiosError) {
    // eslint-disable-next-line no-console
    console.error(error);

    return defaultError;
  }

  // API error.
  const errorMessages = getAPIErrors(error as AxiosError<APIErrorResponse, any>);

  if (errorMessages.length) {
    return errorMessagesFormat(errorMessages);
  }

  return defaultError;
};
