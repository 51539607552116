import React from 'react';
import { Result, Button, Progress } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
// redux
import { selectors as importSelectors } from '@optx/features/list-import/state';

const ProgressWrapper = styled(Progress)`
  .ant-progress-inner {
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.08);
  }
`;

const ResultWrapper = styled(Result)`
  padding: 48px 32px;
`;

interface InProgressProps {
  visibilityChange: (visibility: boolean) => void;
}

const InProgress: React.FC<InProgressProps> = ({ visibilityChange }) => {
  const percentage = useSelector(importSelectors.getPercentageCompleted);

  const handleCancel = (event: React.MouseEvent<HTMLElement>) => {
    if (event && event.currentTarget) {
      event.currentTarget.blur();
    }

    visibilityChange(true);
  };

  return (
    <>
      <ProgressWrapper percent={percentage} showInfo={false} />
      <ResultWrapper
        status="success"
        title="In Progress"
        subTitle="Depending on the number of companies, this may take awhile"
        icon={<></>}
        extra={[<Button onClick={handleCancel}>Cancel</Button>]}
      />
    </>
  );
};

export default InProgress;
