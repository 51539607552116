/**
 * Convert base64 to raw binary data held in a string
 * OBS: doesn't handle URLEncoded DataURIs
 * @param dataURI base64 string
 * @returns binary data
 */
export function dataURItoBlob(dataURI: string) {
  try {
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob
    const bb = new Blob([ab], { type: mimeString });

    return bb;
  } catch (error: any) {
    return null;
  }
}

/**
 * Regular expression for matching date strings in the format 'YYYY-MM-DD'.
 */
export const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

/**
 * Check if value is ISO 8601 formmated string YYYY-MM-DD.
 * @param value
 */
export const isIsoDateString = (value: any) => {
  if (typeof value !== 'string') {
    return false;
  }

  return dateRegex.test(value);
};

/**
 * Removes all spaces from the given string.
 *
 * @param value - The input string from which spaces are to be removed.
 * @returns A new string with all spaces removed.
 */
export const removeSpace = (value: string = '') => value.replace(/\s+/g, '');

/**
 * Validates the provided string value for creating an option.
 *
 * @param value - The string value to be validated.
 * @returns Returns true if the value is not empty after trimming, otherwise false.
 */
export const isNotOnlyEmptySpaces = (value: string) => {
  if (value.trim() === '') {
    return false;
  }

  return true;
};
