import { PreselectedFilter, BaseFilter } from '@optx/models/filters';
import { Dictionary, cloneDeep } from 'lodash';
import { LogicOption } from '@optx/models/Option';

/**
 * Inject filter or filter option from specific source filters into other filters.
 * @param filterSources all filter sources.
 * @param destinationFilters original filters where we want to inject the new filter or filter option.
 * @param sourceFilters source filter containing filter or filter option we want to inject.
 * @param filterKey key of the filter to be injected.
 * @param optionId option identifier in filter to be injected.
 * @returns a clone of destination filters with filter or filter option from source injected.
 */
export const injectFilter = (
  filterSources: Dictionary<BaseFilter<any>>,
  destinationFilters: Dictionary<PreselectedFilter>,
  sourceFilters: Dictionary<PreselectedFilter>,
  filterKey: string,
  optionId?: string | number
) => {
  // clone all current filters
  const newFilters = cloneDeep(destinationFilters);
  const filterSource: BaseFilter<any> | undefined = filterSources[filterKey];

  if (optionId !== undefined) {
    // handle case when only a specific option from filter is removed.
    switch (filterSource.type) {
      case 'logic_checkbox': {
        const itemIndex = optionId as number;
        const sourceFilterOption = (sourceFilters[filterKey] as Array<LogicOption>)[itemIndex];
        (newFilters[filterKey] as Array<LogicOption>)[itemIndex] = { ...sourceFilterOption };

        break;
      }

      default:
        break;
    }
  } else {
    // is platform/portfolio is not a defined filter, is a special case to search by specific tag.
    if (filterKey === 'is_platform') {
      newFilters[filterKey] = undefined;
    } else {
      newFilters[filterKey] = sourceFilters[filterKey];
    }

    if (filterKey === 'geo_range') {
      newFilters.geo_country = sourceFilters.geo_country;
    }

    if (filterSource) {
      switch (filterSource.type) {
        case 'multiple_range_nr': {
          // handle custom checkbox case
          const customCheckKey = `${filterKey}_custom`;
          newFilters[customCheckKey] = sourceFilters[customCheckKey];

          break;
        }

        case 'checkbox': {
          // handle custom checkbox range case
          const customCheckKey = `${filterKey}_custom`;
          newFilters[customCheckKey] = sourceFilters[customCheckKey];

          break;
        }

        default:
          break;
      }
    }
  }

  if (
    filterKey === 'country' &&
    Array.isArray(destinationFilters.europe_tier) &&
    destinationFilters.europe_tier.length
  ) {
    return {
      ...newFilters,
      europe_tier: [],
    };
  }

  return newFilters;
};
