import React from 'react';
import { ColumnType } from 'antd/es/table';
// models
import { CompanyFundingRoundsHistory } from '@optx/models/Company';
// constants
import { NOT_AVAILABLE_VALUE } from '@optx/constants/value';
import globalConfig from '@optx/constants/config';
// components
import { DEFAULT_LAST_TOUCH, DEFAULT_NEXT_TOUCH } from '@optx/constants/table/cells';
import { TableCellDefault, TableCellMoney, TableCellDate } from '../AntTable/cells';
import { TableCellInvestorNames } from './cells';

export const columns: ColumnType<CompanyFundingRoundsHistory>[] = [
  {
    dataIndex: 'date',
    key: 'date',
    title: 'Funding Round Date',
    render: (value: string, record: CompanyFundingRoundsHistory, index: number) => (
      <TableCellDate
        value={value}
        format={globalConfig.grid_date.DATE_FORMAT}
        record={record}
        defaultValue={
          value !== DEFAULT_LAST_TOUCH && value !== DEFAULT_NEXT_TOUCH ? undefined : value
        }
      />
    ),
    className: 'not-interactive',
  },
  {
    dataIndex: 'round',
    key: 'round',
    title: 'Transaction Name',
    render: TableCellDefault,
    className: 'not-interactive',
  },
  {
    dataIndex: 'investor_count',
    key: 'investor_count',
    title: 'Number of investors',
    align: 'right',
    render: TableCellDefault,
    className: 'not-interactive',
  },
  {
    dataIndex: 'amount',
    key: 'amount',
    title: 'Money Raised',
    align: 'right',
    render: (value: number, record: CompanyFundingRoundsHistory, index: number) =>
      value === null ? NOT_AVAILABLE_VALUE : <TableCellMoney value={value} record={record} />,
    className: 'not-interactive',
  },
  {
    dataIndex: 'investor_names',
    key: 'investor_names',
    title: 'Investors',
    render: TableCellInvestorNames,
    className: 'not-interactive',
  },
];

export default columns;
