import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { SortByRule } from '@models/table/sorting';
import { actions as userInformationActions } from '@redux/user/information';
import { UserInformation } from '@optx/models/user';
import { parseSorting } from '@optx/utils/filters/parseSorting';
import { SortState, ToggleMultiSortPayload } from '../interfaces';
import { actions } from '../actions';
import { DEFAULT_SORT } from '../../constants/table';

export const initialState: SortState = {
  multiSort: false,
  sortBy: DEFAULT_SORT,
};

const sortReducer: CaseReducer<SortState, PayloadAction<Array<SortByRule<any>>>> = (
  draftState,
  action
) => {
  draftState.sortBy = action.payload;
};

const toggleMultiSortReducer: CaseReducer<SortState, PayloadAction<ToggleMultiSortPayload>> = (
  draftState,
  action
) => {
  const { multiSort, sortBy } = action.payload;

  draftState.multiSort = multiSort;

  if (sortBy) {
    draftState.sortBy = sortBy;
  }
};

export const fetchUserInformationSuccessReducer: CaseReducer<
  SortState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const sorting = action.payload.settings.session_settings?.new_et_companies_sorting;

  if (sorting) {
    const { sortBy } = parseSorting(sorting);
    draftState.sortBy = sortBy;
  }
};

const resetToDefaultReducer: CaseReducer<SortState> = () => initialState;

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.sort.sort, sortReducer)
    .addCase(actions.sort.toggleMultisort, toggleMultiSortReducer)
    .addCase(actions.filters.clearFilters, resetToDefaultReducer)
    .addCase(actions.filters.resetToDefault, resetToDefaultReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
);

export default reducer;
