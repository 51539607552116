import React, { memo } from 'react';
import * as Styled from '../StatsCards.styled';

interface StatsLabelProps {
  value: [string | undefined | null, string | undefined | null];
  label: string;
  isSmall?: boolean;
  color?: string;
}

const StatsLabel: React.FC<StatsLabelProps> = ({ value, label, color = '', isSmall = false }) => {
  const [first, second] = value;

  return (
    <Styled.StatsLabel isSmall={isSmall}>
      <div>
        {first && (
          <span
            className={`first-value ${second ? 'dash' : ''}`}
            style={{ color: color || '#1890FF' }}
          >
            {first}
          </span>
        )}
        {second && <span>{second}</span>}
      </div>
      <small>{label}</small>
    </Styled.StatsLabel>
  );
};

export default memo(StatsLabel);
