import { combineReducers } from 'redux';
// models
import { AnalytsLeaderboardState } from './interfaces';
// redux
import filters from './filters';
import search from './search';
import touchesAcrossRegion from './touchesAcrossRegion';
import emailStatistics from './emailStatistics';
import smartTouchScore from './smartTouchScore';
import additionalStatistics from './additionalsStatistics';
import pipelineInformation from './pipeline-information';

const reducer = combineReducers<AnalytsLeaderboardState>({
  filters,
  search,
  touchesAcrossRegion,
  emailStatistics,
  smartTouchScore,
  additionalStatistics,
  pipelineInformation,
});

export default reducer;
