import React, { useState } from 'react';
import { Button } from 'antd';
import { SalesloftAddContact } from '@optx/screens/App/SalesloftBulk/components/salesloft/SalesloftAddContact';
import { useSelector } from 'react-redux';
import { selectors } from '@redux/company/equity-touch';

interface AddContactEquityTouchProps {
  id: string | number;
}

const AddContactEquityTouch: React.FC<AddContactEquityTouchProps> = ({ id }) => {
  const [visible, setVisible] = useState(false);
  const formData = useSelector(selectors.getData);

  const contactData = {
    company_id: parseInt(id as string),
    company_name: formData['Company Name'].from_database as string,
    company_url: (formData.Website.from_database as string) || null,
  };

  return (
    <>
      <Button
        onClick={() => {
          setVisible(true);
        }}
      >
        {' '}
        Add Contact
      </Button>
      <SalesloftAddContact
        visible={visible}
        contactData={contactData}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </>
  );
};

export default AddContactEquityTouch;
