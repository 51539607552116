import { select } from 'redux-saga/effects';
import { matchPath } from 'react-router-dom';
import * as selectors from './selectors';

export function* matchesLocation(paths: string[]) {
  const location: Location = yield select(selectors.getLocation);

  const matcher = paths.some(path => !!matchPath(location.pathname, path));

  return matcher;
}
