import React from 'react';
import { BarData, ContentRenderer, RectangleProps } from 'recharts';
import { RectRadius } from '@models/recharts';
import { getRectanglePath } from '@utils/chart/recharts';

const MINIMAL_BAR_HEIGHT = 3;

type MinimumSizeBarProps = BarData &
  RectangleProps & { radius: RectRadius; minimalHeight?: number };

const MinimumSizeBar: React.FC<MinimumSizeBarProps> = ({
  fill,
  x,
  y,
  width,
  height,
  radius,
  value,
  minimalHeight = MINIMAL_BAR_HEIGHT,
}) => {
  let finalHeight = isNaN(height) ? 0 : height;
  let finalY = isNaN(y) ? 0 : y;

  if (value !== null && value !== 0 && height < minimalHeight) {
    finalHeight = minimalHeight;
    finalY = y + height - MINIMAL_BAR_HEIGHT;
  }

  return (
    <path d={getRectanglePath(x, finalY, width, finalHeight, radius)} stroke="none" fill={fill} />
  );
};

// Even if bar shape receives value and other properties from BarData,
// Typescript definition is not update and need to use as syntax.
export default MinimumSizeBar as ContentRenderer<RectangleProps>;
