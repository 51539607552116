import React, { useRef, useState } from 'react';
import AsyncCreatable from 'react-select/async-creatable';
import { ActionMeta, InputActionMeta, ValueType } from 'react-select';
import { MenuPlacement } from 'react-select/src/types';
// models
import { SelectOption } from '@optx/models/Option';
import { SuccessErrorCallback } from '@optx/models/callback';
// hooks
import { useToggle } from '@optx/common/hooks/modal';
import useAsyncSearch from '@optx/common/hooks/select/useAsyncSearch';

interface CreateOtherOptionsProps {
  onInputChange: (option: SelectOption) => void;
  apiEndpoint: string;
  isDisabled?: boolean;
  setMenuMaxHeight?: number;
  menuPlacement?: MenuPlacement;
}

const CreateOtherOptions: React.FC<CreateOtherOptionsProps> = ({
  onInputChange,
  apiEndpoint,
  isDisabled,
  setMenuMaxHeight,
  menuPlacement,
}) => {
  const [showAddButton, toggleShowAddButton] = useToggle(false);
  const [inputValue, setInputValue] = useState<SelectOption | null>(null);

  const onChange = (
    option: ValueType<SelectOption<string>>,
    actionMeta: ActionMeta<SelectOption<string | number>>
  ) => {
    switch (actionMeta.action) {
      // @ts-ignore
      case 'select-option':
        onInputChange(option as SelectOption);
        setInputValue(option as SelectOption);
        break;

      case 'create-option': {
        onInputChange(option as SelectOption);
        setInputValue(option as SelectOption);

        toggleShowAddButton();
        break;
      }

      default:
        break;
    }
  };

  const { loadOptions } = useAsyncSearch({
    endpoint: apiEndpoint,
  });

  const selectRef = useRef<AsyncCreatable<SelectOption> | null>(null);

  const handleMenuClose = () => {
    if (!showAddButton) {
      toggleShowAddButton();
    }

    setInputValue(null);
  };

  const handleInputChange = (newValue: string, { action }: InputActionMeta) => {
    switch (action) {
      case 'input-change': {
        // This prevents a bug where the query is called without the last letter of previous query
        // eg: If user writes 'auto', query is 'auto'. If the second time user writes 'auto', query will be 'aut'.
        // This seems to fix above problem;
        loadOptionsAfterInputChange(newValue, () => {});
        break;
      }

      default:
        break;
    }
  };

  const loadOptionsAfterInputChange = (query: string, callback: SuccessErrorCallback) => {
    loadOptions(query, options => {
      const callbackResult = callback(options);

      return callbackResult;
    });
  };

  return (
    <AsyncCreatable
      isDisabled={!!isDisabled}
      value={inputValue}
      loadOptions={loadOptionsAfterInputChange}
      className="other_select-async"
      onChange={onChange}
      onInputChange={handleInputChange}
      controlShouldRenderValue
      selectRef={selectRef}
      onMenuClose={handleMenuClose}
      closeMenuOnSelect
      bodyDisplay={false}
      disableValueRemove
      maxMenuHeight={setMenuMaxHeight ? setMenuMaxHeight : undefined}
      menuPlacement={menuPlacement}
    />
  );
};

export default CreateOtherOptions;
