import React from 'react';
import { useSelector } from 'react-redux';
import { EquityTouchBulkResults } from '@models/bulkActions';
import { selectors as bulkSelectors } from '@features/bulk-actions';
import { SalesloftContact } from '@optx/models/Salesloft';
import SalesloftUrl from './SalesloftUrl';

const TableCellEtSalesloftBulkUrl = (value: string, record: EquityTouchBulkResults) => {
  const { company_id: companyId, salesloftContacts: contacts } = record;
  const companyData = useSelector(bulkSelectors.equityTouch.getCompanyEqtData(companyId));

  const sentContacts = companyData?.salesloft_contacts as SalesloftContact[];

  if (!sentContacts) {
    return 'Not in Sales Loft';
  }

  return <SalesloftUrl contactsResponse={contacts} sentContacts={sentContacts} />;
};

export default TableCellEtSalesloftBulkUrl;
