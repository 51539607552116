// fetch saved searches
export const INITIALIZE_SAVED_SEARCHES_AND_VIEWS =
  'company/saved-searches/INITIALIZE_SAVED_SEARCHES_AND_VIEWS';
export const INITIALIZE_SAVED_SEARCHES_AND_VIEWS_SUCCESS =
  'company/saved-searches/INITIALIZE_SAVED_SEARCHES_AND_VIEWS_SUCCESS';

export const FETCH_SAVED_SEARCHES = 'company/saved-searches/FETCH_SAVED_SEARCHES';
export const FETCH_SAVED_SEARCHES_SUCCESS = 'company/saved-searches/FETCH_SAVED_SEARCHES_SUCCESS';
export const FETCH_SAVED_SEARCHES_FAIL = 'company/saved-searches/FETCH_SAVED_SEARCHES_FAIL';

// save search
export const SAVE_SEARCH = 'company/saved-searches/SAVE_SEARCH';
export const SAVE_SEARCH_SUCCESS = 'company/saved-searches/SAVE_SEARCH_SUCCESS';
export const SAVE_SEARCH_FAIL = 'company/saved-searches/SAVE_SEARCH_FAIL';

// save view
export const SAVE_VIEW = 'company/saved-searches/SAVE_VIEW';
export const MODIFY_VIEW = 'company/saved-searches/MODIFY_VIEW';
export const MODIFY_VIEW_SUCCESS = 'company/saved-searches/MODIFY_VIEW_SUCCESS';

export const MODIFY_CUSTOM_UI_VIEW = 'company/saved-searches/MODIFY_CUSTOM_UI_VIEW';
export const MODIFY_CUSTOM_UI_VIEW_SUCCESS = 'company/saved-searches/MODIFY_CUSTOM_UI_VIEW_SUCCESS';

// update saved searches
export const UPDATE_SAVED_SEARCHES = 'company/saved-searches/UPDATE_SAVED_SEARCHES';

// apply view success
export const APPLY_VIEW_SUCCESS = 'company/saved-searches/APPLY_VIEW_SUCCESS';

// delete search
export const DELETE_SEARCH = 'company/saved-searches/DELETE_SEARCH';
export const DELETE_SEARCH_SUCCESS = 'company/saved-searches/DELETE_SEARCH_SUCCESS';
export const DELETE_SEARCH_FAIL = 'company/saved-searches/DELETE_SEARCH_FAIL';

// delete search
export const RENAME_SEARCH = 'company/saved-searches/RENAME_SEARCH';
export const RENAME_SEARCH_SUCCESS = 'company/saved-searches/RENAME_SEARCH_SUCCESS';
export const RENAME_SEARCH_FAIL = 'company/saved-searches/RENAME_SEARCH_FAIL';

// pagination
export const SAVED_COMPANY_SEARCH_PAGINATION =
  'company/saved-searches/SAVED_COMPANY_SEARCH_PAGINATION';

// sort
export const SORT_SAVED_SEARCHES = 'company/saved-searches/SORT_SAVED_SEARCHES';

export const MAKE_FILTERS_DEFAULT = 'company/saved-searches/MAKE_FILTERS_DEFAULT';

export const MAKE_FILTERS_DEFAULT_SUCCESS = 'company/saved-searches/MAKE_FILTERS_DEFAULT_SUCCESS';

export const UPDATE_QUERY = 'company/saved-searches/UPDATE_QUERY';

export const SET_LOADER = 'company/saved-searches/SET_LOADER';
