import React from 'react';
import styled, { css } from 'styled-components';

interface OrderedListProps extends React.LiHTMLAttributes<any> {
  $marker?: boolean;
}

const Item = styled.li`
  padding: 10px 0;
  color: #90a0b7;
  font-size: 14px;
  line-height: 22px;

  ::marker {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #334d6e;
  }
`;

const OrderedList = styled.ol<OrderedListProps>`
  padding-inline-start: 15px;

  ${props =>
    props.$marker === false &&
    css`
      padding-inline-start: 0;
      list-style: none;
    `}
`;

OrderedList.defaultProps = {
  $marker: true,
};

export const Styled = {
  OrderedList,
  Item,
};
