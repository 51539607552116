import { Layout } from 'antd';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

const { Header } = Layout;

const MainHeader = styled(Header)`
  z-index: 10;
  transition: all 0.2s ease;
  /* Gray / gray-1 */
  background: #ffffff;
  /* Gray / gray-5 */
  border: 1px solid #d9d9d9;
  padding: 0 16px;

  & > * {
    line-height: 1;
  }

  ${up('md')} {
    padding: 0 40px;
  }
`;

export default MainHeader;
