import numeral from 'numeral';
import { ColumnType } from 'antd/es/table';
// models
import { SourceScrubList } from '@optx/models/SourceScrubList';
// components
import {
  TableCellDefaultTooltip,
  TableCellDate,
  TableCellActions,
  TableCellNewTab,
  TableCellDefault,
} from './cells';

const cellUpdates: Array<ColumnType<SourceScrubList>> = [
  {
    dataIndex: 'title',
    title: 'List Name',
    className: 'title',
    sorter: true,
    render: (value, row, index) => (
      <TableCellDefaultTooltip value={value} row={row} index={index} />
    ),
    width: 450,
  },
  {
    dataIndex: 'startdate',
    title: 'Start Date',
    className: 'startdate',
    sorter: true,
    align: 'left',
    render: (value, row) => <TableCellDate value={value} row={row} />,
    width: 100,
  },
  {
    dataIndex: 'enddate',
    title: 'End Date',
    className: 'enddate',
    sorter: true,
    align: 'left',
    render: (value, row) => <TableCellDate value={value} row={row} />,
    width: 100,
  },
  {
    dataIndex: 'last_refreshed_at',
    title: 'Last Refreshed Date',
    className: 'last_refreshed_at',
    sorter: true,
    align: 'left',
    render: (value, row) => <TableCellDate value={value} row={row} />,
    width: 140,
  },
  {
    dataIndex: 'number_of_companies',
    title: '# of Companies',
    className: 'number_of_companies',
    sorter: true,
    align: 'right',
    render: (value, row) => <TableCellDefault value={value} row={row} />,
    width: 140,
  },
  {
    dataIndex: 'number_of_companies_in_et',
    title: '# of Companies in ET',
    className: 'number_of_companies_in_et',
    sorter: true,
    align: 'right',
    render: (value: any, record: SourceScrubList) => {
      const formattedVal = numeral(value).format('0,0');

      return (
        <TableCellNewTab filterKey="number_of_companies_in_et" value={formattedVal} row={record} />
      );
    },
    width: 170,
  },
  {
    dataIndex: 'number_of_companies_not_in_et',
    title: '# Not in ET',
    className: 'number_of_companies_not_in_et',
    sorter: true,
    align: 'right',
    render: (value: any, record: SourceScrubList) => {
      const formattedVal = numeral(value).format('0,0');

      return (
        <TableCellNewTab
          filterKey="number_of_companies_not_in_et"
          value={formattedVal}
          row={record}
        />
      );
    },
    width: 110,
  },
  {
    dataIndex: 'number_of_last_touch_over_6_months',
    title: '# W/O a Touch < 6M',
    className: 'number_of_last_touch_over_6_months',
    sorter: true,
    align: 'right',
    render: (value: any, record: SourceScrubList) => {
      const formattedVal = numeral(value).format('0,0');

      return (
        <TableCellNewTab
          filterKey="number_of_last_touch_over_6_months"
          value={formattedVal}
          row={record}
        />
      );
    },
    width: 170,
  },
  {
    dataIndex: 'number_of_future_touches',
    title: '# of Future Touches',
    className: 'number_of_future_touches',
    sorter: true,
    align: 'right',
    render: (value: any, record: SourceScrubList) => {
      const formattedVal = numeral(value).format('0,0');

      return (
        <TableCellNewTab filterKey="number_of_future_touches" value={formattedVal} row={record} />
      );
    },
    width: 130,
  },
  {
    dataIndex: 'row_actions',
    title: 'Action',
    align: 'center',
    className: 'cell-actions',
    render: (value, row, index) => <TableCellActions row={row} />,
    width: 60,
  },
];

export default cellUpdates;
