import { useSelector } from 'react-redux';
// redux
import { selectors } from '@features/grid/view-transition';
// components
import LongCardView from './LongCardView';

const CardContent = () => {
  const isViewTransition = useSelector(selectors.getViewTransition);

  return <div>{!isViewTransition && <LongCardView />}</div>;
};

export default CardContent;
