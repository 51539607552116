import React from 'react';
import { Tooltip } from 'antd';

export const TableCellLocation = (value: string) => {
  if (!value) {
    return null;
  }

  return <Tooltip title={value}>{value}</Tooltip>;
};

export default TableCellLocation;
