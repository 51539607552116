import { createSelector } from 'reselect';
import { AppState } from '@optx/redux/interfaces';
import createCachedSelector from 're-reselect';
import { Dictionary, forEach } from 'lodash';
import { selectors as fetchSelectors } from '../../feature/fetch';
import { selectors as listsSelectors } from '../lists-popup';

// specific selectors
const selectListsState = (state: AppState) => state.favoriteLists;
const selectCompanyListsState = createSelector(selectListsState, state => state.companyLists);

export const isLoading = createSelector(selectCompanyListsState, fetchSelectors.isLoadingSelector);

/**
 * Get lists including company.
 */
export const getCompanyLists = createCachedSelector(
  listsSelectors.getAllWatchlists,
  selectCompanyListsState,
  (state: AppState, companyId: number) => companyId,
  (lists, listsState, companyId) => {
    // By default we have lists exclusing company from API.
    const listsExcludingCompany = listsState.byId[companyId] || undefined;

    if (!listsExcludingCompany) {
      return undefined;
    }

    // Compute lists including company comparing all lists with lists excluding company.
    const listsIncludingCompany: Dictionary<boolean> = {};

    forEach(lists, (list, listId) => {
      if (!listsExcludingCompany[listId]) {
        listsIncludingCompany[listId] = true;
      }
    });

    return listsIncludingCompany;
  }
)((state, companyId) => companyId);
