import { selectors as fetchSelectors } from '@optx/redux/feature/fetch';
import { createSelector } from 'reselect';
import { TouchesAcrossRegionState } from './interfaces';

export const getTouchesAcrossRegion = (state: TouchesAcrossRegionState) => state.data;

export const isLoading = (state: TouchesAcrossRegionState) =>
  fetchSelectors.isLoadingSelector(state);

export const getLocations = createSelector(getTouchesAcrossRegion, touches => {
  if (!touches) {
    return [];
  }

  return touches.locations;
});

export const topTouches = createSelector(getTouchesAcrossRegion, touches => {
  if (!touches) {
    return [];
  }

  return touches.top_five;
});
