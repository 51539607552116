import React, { useState, useEffect, useRef, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Radio, Tooltip } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { CheckCircleTwoTone } from '@ant-design/icons';
// models
import { EditFieldRadioProps } from '@optx/models/feature/edit-field/EditFieldRadioProps';
// constants
import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';
// components
import { Styled } from '@optx/features/company/edit-fields/components/TableCellFit.styled';
import EditPopover from '@optx/components/common/popover/EditPopover';
import EditCellPen from '@optx/components/common/table/Companies/styled-cells/EditCellPen';
import { InlineEdit } from '@optx/shared/view/molecules/edit/InlineEdit';
import { DropdownAddContext } from '@optx/components/DropdownAdd/DropdownAddContext';
// redux
import { getIsPartner } from '@optx/redux/user/information/selectors';
// hooks
import { URLStatus } from '@optx/models/Company';
import useUpdateFields from '../hooks/useUpdateFields';

const EditFieldRadio: React.FC<EditFieldRadioProps> = ({
  record,
  value,
  fieldName,
  labelValue,
  isFromGrid,
  showOnlyRadio,
  service,
  successMessage,
  errorMessage,
  onClick,
  hidePen,
  isFromHistory,
  triggerLabel,
  placement,
  children,
  onChangeRadioValue,
}) => {
  const isPartner = useSelector(getIsPartner);

  const { updateField } = useUpdateFields({
    fieldName,
    companyId: record?.company_id ?? 0,
    companyUrl: record?.company_url ?? null,
    service,
    successMessage,
    errorMessage,
    isPartner,
    isFromHistory,
  });
  const [closePopup, setClosePopup] = useState(false);
  const [radioValue, setRadioValue] = useState<boolean>(value as boolean);
  const afterValue = useRef<string>();
  // Close DropDownAdd popup
  const { setDropdownVisible, dropdownVisible } = useContext(DropdownAddContext);

  useEffect(() => {
    setRadioValue(value as boolean);
  }, [value]);

  if (!record) return null;

  const gridValue = value ? 'Yes' : 'No';
  const gridWebsiteValue = (value as URLStatus) === 'active' ? 'Active' : 'Not Active';
  const gridAiMlValue =
    (value as boolean | undefined) === undefined || value === null || value === DEFAULT_CELL_VALUE
      ? 'Unknown'
      : value
      ? 'Yes'
      : 'No';

  const customLabel = isFromGrid && fieldName === 'Software Company' ? 'Software' : fieldName;

  let icon: React.ReactNode = '';

  const customValue = value === null || value === undefined ? DEFAULT_CELL_VALUE : value;

  const customWebsiteValue = fieldName === 'Website Status' ? value === 'active' : customValue;

  const handlePopoverLabel = () => {
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }

    if (fieldName === 'Website Status') {
      return value === 'active' ? 'Active' : 'Not Active';
    }

    return 'Unknown';
  };

  const onChange = (e: RadioChangeEvent) => {
    const value = e.target.value === DEFAULT_CELL_VALUE ? null : e.target.value;

    onClick && onClick();

    updateField(value);
    afterValue.current = e.target.value;
    setRadioValue(e.target.value);
    onChangeRadioValue && onChangeRadioValue(e.target.value);
    setClosePopup(true);
    setDropdownVisible(!dropdownVisible);
  };

  const handleEqualOptions = () => {
    if (value === radioValue) {
      setClosePopup(true);
    }
  };

  if (value === true) {
    icon = <Styled.ThumbsUpIcon iconName="thumbs-up" />;
  } else if (value === false) {
    icon = <Styled.ThumbsIcon iconName="thumbs-down" />;
  } else {
    icon = <Styled.FitCellInnerTextContainer>Unknown</Styled.FitCellInnerTextContainer>;
  }

  const content = (
    <Radio.Group onChange={onChange} value={customWebsiteValue}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Radio onClick={handleEqualOptions} className="d-block" value>
          Yes
          {fieldName === 'Software Company' && radioValue}
        </Radio>
        <Radio onClick={handleEqualOptions} value={false}>
          No
          {fieldName === 'Software Company' && !radioValue}
        </Radio>
        {['PSG Fit', 'AI / ML'].includes(fieldName) && (
          <Radio value={DEFAULT_CELL_VALUE}>Unknown</Radio>
        )}
      </div>
    </Radio.Group>
  );

  const gridContent = (
    <Styled.TableCellInner>
      {fieldName === 'Website Status'
        ? gridWebsiteValue
        : fieldName === 'AI / ML'
        ? gridAiMlValue
        : gridValue}
      {!labelValue && record.is_software_verified && fieldName === 'Software Company' && (
        <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 19, marginLeft: '5px' }} />
      )}
    </Styled.TableCellInner>
  );

  if (!isFromGrid && isFromHistory) {
    return (
      <EditPopover
        content={content}
        title={`Edit "${fieldName}" value`}
        closePopup={closePopup}
        setClosePopup={setClosePopup}
        hidePenIcon={hidePen}
      >
        {triggerLabel || labelValue}
      </EditPopover>
    );
  }

  if (fieldName === 'Website Status' && record.company_url === null) {
    return (
      <Tooltip title="No URL Available">
        <span>No URL Available</span>
      </Tooltip>
    );
  }

  if (isFromGrid) {
    return (
      <InlineEdit
        action={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <EditCellPen
            setClosePopup={setClosePopup}
            closePopup={closePopup}
            content={content}
            title={`Edit "${customLabel}" value`}
          />
        }
        fill
      >
        {['Software Company', 'Website Status', 'AI / ML'].includes(fieldName) ? gridContent : icon}
      </InlineEdit>
    );
  }

  return showOnlyRadio ? (
    <EditPopover
      content={content}
      title={`Edit "${fieldName}" value`}
      closePopup={closePopup}
      setClosePopup={setClosePopup}
      hidePenIcon={hidePen}
      placement={placement}
    >
      {children}
    </EditPopover>
  ) : (
    <EditPopover
      content={content}
      title={`Edit "${fieldName}" value`}
      closePopup={closePopup}
      setClosePopup={setClosePopup}
      hidePenIcon={hidePen}
    >
      {labelValue || handlePopoverLabel()}
      {!labelValue && record.is_software_verified && fieldName === 'Software Company' && (
        <CheckCircleTwoTone
          twoToneColor="#52c41a"
          style={{ fontSize: 21, marginLeft: 6, position: 'relative', top: 2 }}
        />
      )}
    </EditPopover>
  );
};

export default React.memo(EditFieldRadio);
