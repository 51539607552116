import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
// models
import { FilterSource, PreselectedFilter } from '@optx/models/filters';
import { UserInformation } from '@models/user';
// utils
import { normalizeFiltersByColumn } from '@utils/filters/normalize';
import { mapPreselectedFilters } from '@utils/filters/preselectedValues';
import { mapFiltersOptions } from '@optx/utils/filters/mapFiltersOptions';
import { parseFilter } from '@utils/filters/parseFilters';
// redux
import { reducers } from '@redux/feature/fetch';
import { actions as userInformationActions } from '@redux/user/information';
// local
// models
import { Dictionary, forEach } from 'lodash';
import { AnalystsLeaderboardFiltersState, UpdateValuePayload, FilterMeta } from './interfaces';
// redux
import * as actions from './actions';
import { filtersByCriteria } from './constants';

const initialState: AnalystsLeaderboardFiltersState = {
  data: [],
  error: '',
  loading: false,
  fetchedAt: null,
  byColumn: {},
  // current values
  criteriaValues: {},
  options: {},
  commonValues: {},
  // default values from API.
  defaultValues: {},
  defaultCommonValues: {},
  defaultCriteriaValues: {},
  // clear values.
  clearValues: {},
  grouped_values: {},
};

const fetchSuccess: CaseReducer<
  AnalystsLeaderboardFiltersState,
  PayloadAction<Array<FilterSource>>
> = (draftState, action) => {
  reducers.fetchSuccessReducer(draftState, action);
  const normalizedByColumn = normalizeFiltersByColumn(action.payload);
  // @ts-ignore for now
  draftState.byColumn = normalizedByColumn;
  const apiValues = mapPreselectedFilters(action.payload);
  const clearValues = mapPreselectedFilters(action.payload, false);
  // Pipeline previous period is required and empty value can't be used as clear value.
  clearValues.previous_period = apiValues.previous_period;

  // const defaultClearValues = getCommonValues(clearValues, filtersByCriteria);
  const defaultClearCriteriaValues = getCriteriaValues(clearValues, filtersByCriteria);

  draftState.clearValues = clearValues;

  const apiCommonValues = getCommonValues(apiValues, filtersByCriteria);
  const apiCriteriaValues = getCriteriaValues(apiValues, filtersByCriteria);
  draftState.commonValues = apiCommonValues;
  draftState.criteriaValues = apiCriteriaValues;

  if (draftState.persistedFilter !== undefined) {
    // If there is a persisted filter on API with no previous_period add default value.
    // The filter is required and pipeline information call will fail initially until user changes a filter.
    let persistedFilter = draftState.persistedFilter;

    if (!persistedFilter.includes('previous_period')) {
      persistedFilter += '&previous_period=7';
    }

    // Load persisted filter if available.
    const [parsedFilters] = parseFilter(persistedFilter, action.payload);
    draftState.commonValues = getCommonValues(parsedFilters, filtersByCriteria);
    const criteriaValues = getCriteriaValues(parsedFilters, filtersByCriteria);

    // Merge default criteria values with persisted values.
    forEach(defaultClearCriteriaValues, (defaultValue, criteria) => {
      const mergedValues: Dictionary<PreselectedFilter> = {
        ...defaultValue,
        ...criteriaValues[criteria],
      };

      criteriaValues[criteria] = mergedValues;
    });

    draftState.criteriaValues = criteriaValues;
    delete draftState.persistedFilter;
  }

  draftState.defaultCommonValues = apiCommonValues;
  draftState.defaultCriteriaValues = apiCriteriaValues;
  draftState.defaultValues = apiValues;
  // @ts-ignore weird issue. Same type from method and model and still showing error :(.
  draftState.options = mapFiltersOptions(action.payload);
  draftState.fetchedAt = new Date().toISOString();
};

function getCommonValues(
  values: Dictionary<PreselectedFilter>,
  filtersByCriteria: Dictionary<Array<string>>
) {
  const commonValues: Dictionary<PreselectedFilter> = {};
  const criteria: Dictionary<boolean> = {};

  forEach(filtersByCriteria, filterColumns => {
    if (filterColumns) {
      filterColumns.forEach(column => {
        criteria[column] = true;
      });
    }
  });

  forEach(values, (value, key) => {
    if (!criteria[key]) {
      commonValues[key] = value;
    }
  });

  return commonValues;
}

function getCriteriaValues(
  values: Dictionary<PreselectedFilter>,
  filtersByCriteria: Dictionary<Array<string>>
) {
  const criteriaValues: Dictionary<Dictionary<PreselectedFilter>> = {};

  forEach(filtersByCriteria, (filterColumns, key) => {
    if (filterColumns) {
      criteriaValues[key] = {};

      filterColumns.forEach(column => {
        // the pipeline_companies_rank filter has two options, one of them having the value as "is_rank_of_5",
        // but when applying this filter api expects to receive "true". this should ideally be fixed from api, but
        // for now will use this workaround.
        if (column === 'pipeline_companies_rank' && values[column] === 'true') {
          criteriaValues[key][column] = 'is_rank_of_5';
        } else {
          criteriaValues[key][column] = values[column];
        }
      });
    }
  });

  return criteriaValues;
}

const updateValue: CaseReducer<
  AnalystsLeaderboardFiltersState,
  PayloadAction<UpdateValuePayload>
> = (draftState, action) => {
  const { filterKey, value, criteria } = action.payload;

  if (criteria) {
    draftState.criteriaValues[criteria][filterKey] = value;
  } else {
    draftState.commonValues[filterKey] = value;
  }
};

const clearAllFilters: CaseReducer<AnalystsLeaderboardFiltersState> = draftState => {
  const { commonValues, defaultCommonValues, defaultCriteriaValues } = draftState;

  // reset filters keeping select criteria/card only.
  const currentCriteria = commonValues.filter_criteria;
  draftState.commonValues = defaultCommonValues;
  draftState.commonValues.filter_criteria = currentCriteria;
  draftState.criteriaValues = defaultCriteriaValues;
};

const clearFilter: CaseReducer<AnalystsLeaderboardFiltersState, PayloadAction<FilterMeta>> = (
  draftState,
  action
) => {
  const { filterKey, criteria } = action.payload;
  const { commonValues, clearValues } = draftState;
  const clearValue = clearValues[filterKey];

  if (commonValues[filterKey]) {
    // check if is in common
    draftState.commonValues[filterKey] = clearValue;
  } else if (criteria) {
    draftState.criteriaValues[criteria][filterKey] = clearValue;
  } else {
    // is criteria value.
    const currentCriteria = commonValues.filter_criteria as string;
    draftState.criteriaValues[currentCriteria][filterKey] = clearValue;
  }
};

// External reducers
// Update persisted filter to be used when filters are loaded.
const fetchUserInformationSuccess: CaseReducer<
  AnalystsLeaderboardFiltersState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const persistedFilter = action.payload.settings.session_settings
    ? action.payload.settings.session_settings.analysts_leaderboard_filters
    : undefined;

  if (persistedFilter !== undefined) {
    draftState.persistedFilter = persistedFilter;
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.fetch, reducers.fetchReducer)
    .addCase(actions.fetchSuccess, fetchSuccess)
    .addCase(actions.fetchFail, reducers.fetchFailReducer)
    .addCase(actions.updateValue, updateValue)
    .addCase(actions.clearAllFilters, clearAllFilters)
    .addCase(actions.clearFilter, clearFilter)
    // External reducers
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccess)
);

export default reducer;
