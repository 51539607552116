import React from 'react';
import { Typography } from 'antd';
import numeral from 'numeral';
import { useSelector } from 'react-redux';
// utils
import { getPageSize } from '@utils/table/pagination';
// redux
import { selectors as CompaniesETSelectors } from '@optx/features/equity-touch-integration/state';

const ShowingTitle = () => {
  const searchCompanyCount = useSelector(CompaniesETSelectors.pagination.total);
  const pageCount = useSelector(CompaniesETSelectors.pagination.pageCount);

  const showingText = `Showing (${getPageSize(pageCount, searchCompanyCount)} 
  of ${numeral(searchCompanyCount).format('0,0')} 
  ${searchCompanyCount !== 1 ? 'Companies' : 'Company'})`;

  return (
    <Typography.Title
      level={4}
      type="secondary"
      style={{ fontWeight: 'normal', margin: '2px 0 0 0' }}
      title={showingText}
      ellipsis
    >
      {showingText}
    </Typography.Title>
  );
};

export default ShowingTitle;
