/* eslint-disable max-len */
import { SalesloftBulkState } from '@optx/features/bulk-actions/salesloft/state/interfaces';
import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// models
import {
  SelectedCompanies,
  ResolveSalesloftSuccess,
  SalesloftContactsBulk,
  SalesloftCompanyContacts,
  SalesloftBulkResults,
} from '@models/bulkActions';
import { CompanyUserContact } from '@optx/models/api/contacts';
import { SalesloftContact, SalesloftCadences } from '@optx/models/Salesloft';
// utils
import { getSalesloftEmails } from '@optx/utils/salesloft';
import { getEmailsFromString } from '@optx/utils/equityTouch';
// redux
import * as equityTouchActions from '@optx/features/bulk-actions/equity-touch/state/actions';
import * as actions from './actions';

const initialState: SalesloftBulkState = {
  companies: [],
  results: [],
  error: '',
  cadenceId: 0,
  modalVisible: false,
  loading: false,
  inProgress: false,
  completed: false,
  percentageCompleted: 0,
  selectedCompaniesCount: 0,
  cancel: false,
};

const fetchCompaniesContactsSuccessReducer: CaseReducer<
  SalesloftBulkState,
  PayloadAction<{ contacts: SalesloftCompanyContacts[]; selectedCompanies: SelectedCompanies[] }>
> = (draftState, action) => {
  const { contacts, selectedCompanies } = action.payload;

  const companiesWithContacts = selectedCompanies.map(company => {
    const selectContactIndex = contacts.findIndex(item => item.company_id === company.company_id);
    const primaryContact = contacts[selectContactIndex].contacts.find(
      contact => contact.primary_contact
    );

    return {
      ...company,
      contacts: contacts[selectContactIndex].contacts.map(contact => {
        return {
          ...contact,
          company_id: company.company_id,
          email: contact.primary_email,
          emails: getEmailsFromString(contact.primary_email),
          ...getSalesloftEmails(contact.primary_email as string | null),
        };
      }),
      salesloft_contacts: primaryContact
        ? [
            {
              ...primaryContact,
              company_id: company.company_id,
              ...getSalesloftEmails(primaryContact.primary_email as string | null),
            },
          ]
        : [],
    };
  });

  draftState.companies = companiesWithContacts;
  draftState.loading = false;
};

const addPersonBulkReducer: CaseReducer<SalesloftBulkState, PayloadAction<SalesloftCadences>> = (
  draftState,
  action
) => {
  draftState.cadenceId = action.payload.id;
};

const setModalVisibleReducer: CaseReducer<SalesloftBulkState, PayloadAction<boolean>> = (
  draftState,
  action
) => {
  draftState.modalVisible = action.payload;
};

const updatePercentageReducer: CaseReducer<SalesloftBulkState, PayloadAction<number>> = (
  draftState,
  action
) => {
  const addProgress = action.payload;

  if (addProgress === 0 || addProgress === 100) {
    draftState.percentageCompleted = action.payload;
  } else {
    draftState.percentageCompleted += action.payload;
  }
};

const cancelReducer: CaseReducer<SalesloftBulkState, PayloadAction<boolean>> = (
  draftState,
  action
) => {
  draftState.cancel = action.payload;
};

const resetProgressReducer: CaseReducer<SalesloftBulkState> = draftState => {
  return initialState;
};

const addNewContactSuccessReducer: CaseReducer<
  SalesloftBulkState,
  PayloadAction<Partial<CompanyUserContact>>
> = (draftState, action) => {
  const newContact = {
    ...action.payload,
    primary_contact: !!action.payload.primary_contact,
    ...getSalesloftEmails(action.payload.email as string),
  };
  const index = draftState.companies.findIndex(
    company => company.company_id === newContact.company_id
  );

  if (index !== -1) {
    if (newContact.primary_contact) {
      const primaryContactIndex = draftState.companies[index].contacts.findIndex(
        el => el.primary_contact === true
      );

      const primarySalesLoftContactIndex = draftState.companies[
        index
      ]?.salesloft_contacts.findIndex(el => el.person_id === newContact.person_id);

      if (primaryContactIndex !== -1) {
        draftState.companies[index].contacts[primaryContactIndex].primary_contact = false;
      }

      if (primarySalesLoftContactIndex !== -1) {
        draftState.companies[index].salesloft_contacts[primarySalesLoftContactIndex] = newContact;
      } else {
        draftState.companies[index].salesloft_contacts = [newContact];
      }
    }
  }

  if (draftState.companies[index]?.contacts) {
    draftState.companies[index].contacts = draftState.companies[index]?.contacts.concat([
      {
        company_id: newContact.company_id,
        company_name: newContact.company_name,
        ...newContact,
      },
    ]);
  }

  draftState.loading = false;
};

const resolveSalesloftSuccessReducer: CaseReducer<
  SalesloftBulkState,
  PayloadAction<ResolveSalesloftSuccess>
> = (draftState, action) => {
  const { company_id: companyId, contacts: resolvedContacts } = action.payload;
  const index = draftState.results.findIndex(result => result.company_id === companyId);

  if (index !== -1) {
    if (
      draftState.results[index].contacts.length === 1 &&
      draftState.results[index].contacts[0].person_id === null
    ) {
      draftState.results[index].contacts = resolvedContacts;
    } else {
      resolvedContacts.forEach(contact => {
        const resultIndex = draftState.results[index].contacts.findIndex(
          result => result.person_id === contact.person_id
        );

        if (resultIndex !== -1) {
          draftState.results[index].contacts[resultIndex] = contact;
        }
      });
    }
  }

  draftState.loading = false;
};

const addPersonBulkFailReducer: CaseReducer<SalesloftBulkState, PayloadAction<string>> = (
  draftState,
  action
) => {
  draftState.inProgress = false;
  draftState.error = action.payload;
};

const saveResultsReducer: CaseReducer<SalesloftBulkState, PayloadAction<SalesloftBulkResults[]>> = (
  draftState,
  action
) => {
  draftState.results = [...draftState.results, ...action.payload];
};

const updateProgressReducer: CaseReducer<SalesloftBulkState, PayloadAction<boolean>> = (
  draftState,
  action
) => {
  draftState.inProgress = action.payload;
};

const updateCompletedReducer: CaseReducer<SalesloftBulkState, PayloadAction<boolean>> = (
  draftState,
  action
) => {
  draftState.completed = action.payload;
};

const resolveSalesloftReducer: CaseReducer<SalesloftBulkState> = draftState => {
  draftState.loading = true;
};

const updateBulkContactSuccessReducer: CaseReducer<
  SalesloftBulkState,
  PayloadAction<Partial<CompanyUserContact>>
> = (draftState, action) => {
  const contact = action.payload;
  const { company_id: companyId } = contact;
  const index = draftState.companies.findIndex(company => company.company_id === companyId);

  if (index !== -1) {
    const contactIndex = draftState.companies[index].contacts.findIndex(
      item => item.person_id === contact.person_id
    );

    if (contactIndex !== -1) {
      draftState.companies[index].contacts[contactIndex] = {
        ...draftState.companies[index].contacts[contactIndex],
        ...contact,
        additional_phones: contact.phones || null,
      };
    }

    const salesloftContactIndex = draftState.companies[index]?.salesloft_contacts.findIndex(
      item => item.person_id === contact.person_id
    );

    if (salesloftContactIndex !== -1) {
      draftState.companies[index].salesloft_contacts[salesloftContactIndex] = {
        ...draftState.companies[index]?.salesloft_contacts[salesloftContactIndex],
        ...contact,
        ...getSalesloftEmails(contact.email as string),
        additional_phones: contact.phones || null,
      };
    }
  }
};

const salesloftSelectContactsBulkReducer: CaseReducer<
  SalesloftBulkState,
  PayloadAction<SalesloftContactsBulk>
> = (draftState, action) => {
  const { id, companyId, type, selectedContactData } = action.payload;
  const index = draftState.companies.findIndex(item => item.company_id === companyId);

  if (type === 'select') {
    if (selectedContactData) {
      if (Array.isArray(draftState.companies[index].salesloft_contacts)) {
        (draftState.companies[index].salesloft_contacts as SalesloftContact[]).push(
          selectedContactData
        );
      } else {
        draftState.companies[index].salesloft_contacts = [selectedContactData];
      }
    }
  } else if (type === 'deselect') {
    const removeContactIndex = (
      draftState.companies[index]?.salesloft_contacts as SalesloftContact[]
    ).findIndex(contact => contact.person_id === id);

    (draftState.companies[index]?.salesloft_contacts as SalesloftContact[]).splice(
      removeContactIndex,
      1
    );
  } else {
    draftState.companies[index].salesloft_contacts = [];
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.fetchCompaniesContactsSuccess, fetchCompaniesContactsSuccessReducer)
    .addCase(actions.addPersonBulk, addPersonBulkReducer)
    .addCase(actions.addPersonBulkFail, addPersonBulkFailReducer)
    .addCase(actions.saveResults, saveResultsReducer)
    .addCase(actions.updateProgress, updateProgressReducer)
    .addCase(actions.updateCompleted, updateCompletedReducer)
    .addCase(actions.updatePercentage, updatePercentageReducer)
    .addCase(actions.cancel, cancelReducer)
    .addCase(actions.resetProgress, resetProgressReducer)
    .addCase(actions.addContactSuccess, addNewContactSuccessReducer)
    .addCase(actions.resolveSalesloft, resolveSalesloftReducer)
    .addCase(actions.resolveSalesloftSuccess, resolveSalesloftSuccessReducer)
    .addCase(actions.setModalVisible, setModalVisibleReducer)
    .addCase(equityTouchActions.updateBulkContactSuccess, updateBulkContactSuccessReducer)
    .addCase(actions.salesloftSelectContactsBulk, salesloftSelectContactsBulkReducer)
);

export default reducer;
