import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// utils
import { getListURL } from '@optx/utils/routes';
// redux
import { actions as listActions } from '@redux/lists/details';
import { actions as searchActions, selectors as searchSelectors } from '@features/grid/search';

const useMyWatchlists = (): {
  handleMyWatchlistsResults: (id: string) => void;
} => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isInitialSearch: boolean = useSelector(searchSelectors.isInitialSearch('lists'));

  const handleMyWatchlistsResults = (id: string) => {
    history.push(getListURL(id));
    dispatch(searchActions.hideCancelSearch({ gridKey: 'lists', data: true }));

    if (!isInitialSearch) {
      dispatch(listActions.loadCompanyList({ id, isSourceScrub: false }));
    }
  };

  return { handleMyWatchlistsResults };
};

export default useMyWatchlists;
