import { FC, useState } from 'react';
// models
import { ListTag } from '@optx/models/tags';
// constants
import appRoutes from '@constants/routes';
// utils
import { orderTags } from '@optx/utils/orderTags';
// components
import { ListTagsOverflow } from '@optx/features/tags/overflow-tags';
// styles
import ListTagStyled from '@features/tags/tags/ListTag.styles';

interface ListTagsCollapsableProps {
  tags: ListTag[] | null;
}

const ListTagsCollapsable: FC<ListTagsCollapsableProps> = ({ tags }) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const onTagMoreClick = () => {
    setCollapsed(false);
  };

  const handleTagClick = (item: ListTag) => {
    window.open(appRoutes.userLists.list.replace(':id', item.list_id as string), '_blank');
  };

  return (
    <div className="tags-collapsable__wrapper">
      {collapsed ? (
        <ListTagsOverflow
          tags={tags}
          className={`list-tags__custom-styling ${collapsed ? 'collapsed' : ''}`}
          onTagMoreClick={onTagMoreClick}
        />
      ) : (
        orderTags(tags)?.map((item: ListTag, index: number) => (
          <ListTagStyled color="cyan" onClick={() => handleTagClick(item)}>
            {item.title}
          </ListTagStyled>
        ))
      )}
    </div>
  );
};

export default ListTagsCollapsable;
