import React from 'react';
// redux
import Company from '@optx/models/Company';
// hooks
import useIsAdminOrAnalyst from '@optx/common/hooks/useisAdminOrAnalyst';
// components
import {
  EditFieldRadioRationale,
  EditFieldRadio,
} from '@optx/features/company/edit-fields/components';
import { FieldOptions, ServiceType } from '@optx/features/company/edit-fields/state/interfaces';

interface TableCellRadioAdminProps {
  value: any;
  record: Company;
  fieldName: FieldOptions;
  destroyOnHide?: boolean;
  isFromGrid: boolean;
  service: ServiceType;
  successMessage: string;
  errorMessage: string;
}

const TableCellRadioAdmin: React.FC<TableCellRadioAdminProps> = ({
  value,
  record,
  fieldName,
  service,
  successMessage,
  errorMessage,
  destroyOnHide,
  isFromGrid,
}) =>
  useIsAdminOrAnalyst() ? (
    <EditFieldRadioRationale
      value={value}
      record={record}
      fieldName={fieldName}
      service={service}
      successMessage={successMessage}
      errorMessage={errorMessage}
      destroyOnHide={destroyOnHide}
      isFromGrid={isFromGrid}
    />
  ) : (
    <EditFieldRadio
      value={value}
      record={record}
      fieldName={fieldName}
      service={service}
      successMessage={successMessage}
      errorMessage={errorMessage}
      isFromGrid={isFromGrid}
    />
  );
export default React.memo(TableCellRadioAdmin);
