import { createFeatureAction } from '@utils/feature';
import featureName from './featureName';
import * as actionTypes from './actionTypes';
import {
  DashboardSuccess,
  ScheduledTouchesSortBy,
  ScheduledTouchesResponse,
  ScheduledTouchesCountResponse,
} from './interfaces';

export const fetchScheduledTouchesDashboard = createFeatureAction<void>(
  featureName,
  actionTypes.FETCH_SCHEDULED_TOUCHES_DASHBOARD
);
export const resetScheduledTouchesFilter = createFeatureAction<void>(
  featureName,
  actionTypes.RESET_SCHEDULED_TOUCHES_FILTER
);
export const fetchScheduledTouchesDashboardSuccess = createFeatureAction<DashboardSuccess>(
  featureName,
  actionTypes.FETCH_SCHEDULED_TOUCHES_DASHBOARD_SUCCESS
);
export const fetchScheduledTouchesDashboardFail = createFeatureAction<string>(
  featureName,
  actionTypes.FETCH_SCHEDULED_TOUCHES_DASHBOARD_FAIL
);

export const fetchScheduledTouchesNextPage = createFeatureAction<void>(
  featureName,
  actionTypes.FETCH_SCHEDULED_TOUCHES_NEXT_PAGE
);
export const fetchScheduledTouchesNextPageSuccess = createFeatureAction<ScheduledTouchesResponse>(
  featureName,
  actionTypes.FETCH_SCHEDULED_TOUCHES_NEXT_PAGE_SUCCESS
);
export const fetchScheduledTouchesNextPageFail = createFeatureAction<string>(
  featureName,
  actionTypes.FETCH_SCHEDULED_TOUCHES_NEXT_PAGE_FAIL
);

export const fetchScheduledTouches = createFeatureAction<void>(
  featureName,
  actionTypes.FETCH_SCHEDULED_TOUCHES
);
export const fetchScheduledTouchesSuccess = createFeatureAction<ScheduledTouchesResponse>(
  featureName,
  actionTypes.FETCH_SCHEDULED_TOUCHES_SUCCESS
);
export const fetchScheduledTouchesFail = createFeatureAction<string>(
  featureName,
  actionTypes.FETCH_SCHEDULED_TOUCHES_FAIL
);

export const fetchScheduledTouchesCount = createFeatureAction<void>(
  featureName,
  actionTypes.FETCH_SCHEDULED_TOUCHES_COUNT
);
export const fetchScheduledTouchesCountSuccess = createFeatureAction<ScheduledTouchesCountResponse>(
  featureName,
  actionTypes.FETCH_SCHEDULED_TOUCHES_COUNT_SUCCESS
);
export const fetchScheduledTouchesCountFail = createFeatureAction<string>(
  featureName,
  actionTypes.FETCH_SCHEDULED_TOUCHES_COUNT_FAIL
);

export const changeScheduledTouchesSortBy = createFeatureAction<ScheduledTouchesSortBy>(
  featureName,
  actionTypes.CHANGE_SCHEDULED_TOUCHES_SORT_BY
);

export const changeScheduledTouchesTouchType = createFeatureAction<string>(
  featureName,
  actionTypes.CHANGE_SCHEDULED_TOUCHES_TOUCH_TYPE
);

export const changeScheduledTouchesStageType = createFeatureAction<string>(
  featureName,
  actionTypes.CHANGE_SCHEDULED_TOUCHES_STAGE_TYPE
);

export const changeScheduledTouchesRankType = createFeatureAction<string>(
  featureName,
  actionTypes.CHANGE_SCHEDULED_TOUCHES_RANK_TYPE
);

export const changeSelectedDate = createFeatureAction<[string | null, string | null]>(
  featureName,
  actionTypes.CHANGE_SELECTED_DATE
);
export const changeSelectedPeriod = createFeatureAction<string>(
  featureName,
  actionTypes.CHANGE_SELECTED_PERIOD
);
export const changeSelectedTab = createFeatureAction<string>(
  featureName,
  actionTypes.CHANGE_SELECTED_TAB
);
