import React from 'react';
import { Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// redux
import {
  selectors as viewTypeSelectors,
  actions as viewTypeActions,
} from '@features/grid/view-type';
// consts
import ToggleTableView from '@optx/components/common/long-card/ToggleTableView';
import { TABLE_VIEW_TITLE } from '@optx/constants/titles';

const ToggleTableViewContainer = () => {
  const dispatch = useDispatch();
  const searchView = useSelector(viewTypeSelectors.searchView('myCompanies'));

  const toggleTableView = () => {
    if (searchView !== 'table') {
      window.scrollTo(0, 0);
    }

    dispatch(viewTypeActions.toggleSearchView({ gridKey: 'myCompanies', data: 'table' }));
  };

  const active = searchView === 'table';

  return (
    <Tooltip title={TABLE_VIEW_TITLE}>
      <ToggleTableView onClick={toggleTableView} active={active} />
    </Tooltip>
  );
};

export default React.memo(ToggleTableViewContainer);
