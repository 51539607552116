import React from 'react';
import { ColumnType } from 'antd/lib/table';
// constants
import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';
import globalConfig from '@optx/constants/config';
// models
import { UserManagement } from '@models/UserManagement';
// cells
import {
  TableCellDefault,
  TableCellEmail,
  TableCellDate,
} from '@components/common/table/AntTable/cells';
import { TableCellActions, TableCellStatus } from './cells';

const columns: ColumnType<UserManagement>[] = [
  {
    dataIndex: 'display_name',
    title: 'User',
    width: 130,
    render: TableCellDefault,
    sorter: true,
  },
  {
    dataIndex: 'email',
    title: 'Email',
    width: 130,
    render: TableCellEmail,
    sorter: true,
  },
  {
    dataIndex: 'last_activity',
    title: 'Last Activity',
    width: 70,
    render: (value: string | null, record: UserManagement, index: number) => (
      <TableCellDate
        value={value}
        format={globalConfig.grid_date.DATE_FORMAT}
        record={record}
        defaultValue={!value || value === DEFAULT_CELL_VALUE ? DEFAULT_CELL_VALUE : undefined}
      />
    ),
    sorter: true,
  },
  {
    dataIndex: 'role',
    title: 'Role',
    width: 60,
    render: TableCellDefault,
    sorter: true,
  },
  {
    dataIndex: 'status',
    title: 'Status',
    render: (value: string, record: UserManagement) => (
      <TableCellStatus value={value} record={record} />
    ),
    sorter: true,
    width: 80,
  },
  {
    dataIndex: 'row_actions',
    title: 'Action',
    className: 'row_actions',
    render: (value: string, record: UserManagement) => (
      <TableCellActions value={value} record={record} />
    ),
    width: 65,
  },
];

export default columns;
