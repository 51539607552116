import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Tooltip, Modal } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { Dictionary } from 'lodash';
// redux
import { selectors as modalSelectors, actions as modalActions } from '@redux/ui/modals/gridModals';
import FilterForm from '../FilterForm';
import { PreselectedFilter } from '../../../../models/filters';
import { selectors as contactsFilters } from '../../../../redux/contacts/filters';
import {
  actions as contactsSearchActions,
  selectors as contactsSearchSelectors,
} from '../../../../redux/contacts/search/search';

const ShowFilterButton = () => {
  const dispatch = useDispatch();

  const filterSources = useSelector(contactsFilters.getFilterSources);
  const filter = useSelector(contactsSearchSelectors.getFilter);
  const searchKey = useSelector(contactsSearchSelectors.getSearchKey);

  // USOSD-1105 the issue is that when selecting range values and deselecting/restoring multiple times
  // in a row without applying search, the slider does not reset to default values
  // this is caused by having an onChange prop on the Slider component which prevents the slider
  // from being updated when manually changing the form, like when deselecting/restoring
  // because when you're just changing filter values the store is not updated, by getting the loading selector,
  // when requesting the endpoint to deselect/restore filters also force the slider component to update
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loading = useSelector(contactsSearchSelectors.isLoading);

  const search = useCallback(
    (searchKey?: string, filter?: Dictionary<PreselectedFilter>) =>
      dispatch(contactsSearchActions.searchContacts({ searchKey, filter })),
    [dispatch]
  );
  const reset = useCallback(
    (clearFilter: boolean) => dispatch(contactsSearchActions.reset(clearFilter)),
    [dispatch]
  );

  // modal
  const modalIsOpen = useSelector(modalSelectors.isOpen('contacts'));
  const toggleModalFilters = () => dispatch(modalActions.toggleModal({ gridKey: 'contacts' }));

  const toggleModalFiltersButton = (e: React.MouseEvent<HTMLElement>) => {
    e.currentTarget.blur();
    dispatch(modalActions.toggleModal({ gridKey: 'contacts' }));
  };

  const handleSearch = (key: string, filter?: Dictionary<PreselectedFilter>) => {
    search(key, filter);
    toggleModalFilters();
  };

  return (
    <>
      <Tooltip title="Filter">
        <Button type="text" onClick={toggleModalFiltersButton} icon={<FilterOutlined />} />
      </Tooltip>

      <Modal
        className="ant-modal-full ant-modal--filters"
        title={null}
        visible={modalIsOpen}
        footer={null}
        onCancel={toggleModalFilters}
        closable={false}
      >
        <FilterForm
          filter={filter}
          filterSources={filterSources}
          keySearch={searchKey}
          onClearFilter={reset}
          onSearch={handleSearch}
          onClose={toggleModalFilters}
        />
      </Modal>
    </>
  );
};

export default ShowFilterButton;
