import React, { useState, useEffect, ReactElement } from 'react';
import { Dropdown, Button, Tooltip } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router';
// models
import { SearchViewType } from '@models/user';
// constants
import { COLUMNS_SOURCE_MANEGEMENT_TITLE } from '@optx/constants/titles';
// components
import { CompaniesOutReachMenu } from './GridMenu';

interface GridDropdownProps {
  viewType: SearchViewType;
}

const GridDropdown: React.FC<GridDropdownProps> = ({ viewType }) => {
  const [overlayMenu, setOverlayMenu] = useState<ReactElement | null>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.startsWith('/all-reports')) {
      setOverlayMenu(CompaniesOutReachMenu({ viewType }));
    }
  }, [pathname, viewType]);

  if (!overlayMenu) return null;

  return (
    <Tooltip title={COLUMNS_SOURCE_MANEGEMENT_TITLE} placement="topRight">
      <Dropdown overlay={overlayMenu} trigger={['click']} placement="bottomRight">
        <Button type="text" icon={<SettingOutlined style={{ fontSize: 19, color: 'white' }} />} />
      </Dropdown>
    </Tooltip>
  );
};

export default React.memo(GridDropdown);
