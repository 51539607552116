import React from 'react';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import AntIcon from '@ant-design/icons';
import { SubMenuProps } from 'antd/lib/menu/SubMenu';
// redux
import { selectors as userInformationSelectors } from '../../../../../redux/user/information';
// components
import UserPicture from './UserPicture';
// styles
import './styles.scss';

const { SubMenu } = Menu;

const ProfileSubMenu: React.FC<SubMenuProps> = props => {
  const fullName = useSelector(userInformationSelectors.getFullName);
  const email = useSelector(userInformationSelectors.getEmail);

  return (
    <SubMenu
      className="profile-submenu"
      icon={<AntIcon component={UserPicture} />}
      title={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <>
          <span className="profile-submenu__title text-ellipsis">{fullName}</span>
          <span className="profile-submenu__email text-ellipsis">{email}</span>
        </>
      }
      {...props}
    />
  );
};

export default ProfileSubMenu;
