import React, { useCallback } from 'react';
import { Modal } from 'antd';
import { Dictionary } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
// models
import { PreselectedFilter } from '@optx/models/filters';
// utils
import { normalizeFilter } from '@optx/utils/filters/filterHelpers';
// redux
import { selectors, actions } from '@features/pipeline-report/state';
// components
import FiltersModalContent from './FiltersModalContent';

const FiltersModal = () => {
  const { filter } = useSelector(selectors.getFilters);
  const filterSources = useSelector(selectors.getFilterSources);
  const modalIsOpen = useSelector(selectors.filterModalIsOpen);

  const dispatch = useDispatch();
  const onSearch = useCallback(
    (filter: Dictionary<PreselectedFilter>) => {
      dispatch(actions.applyFilters(filter));
    },
    [dispatch]
  );

  const handleClear = useCallback(() => {
    dispatch(actions.clearFilters());
  }, [dispatch]);
  const handleReset = useCallback(() => {
    dispatch(actions.resetToDefault());
  }, [dispatch]);

  const toggleModalFilters = () => dispatch(actions.toggleFiltersModal());

  const handleSearch = (filter: Dictionary<PreselectedFilter>) => {
    const normalizedFilter = normalizeFilter(filter);
    onSearch(normalizedFilter);
    toggleModalFilters();
  };

  return (
    <Modal
      className="ant-modal-full ant-modal--filters"
      title={null}
      visible={modalIsOpen}
      footer={null}
      onCancel={toggleModalFilters}
      closable={false}
      destroyOnClose
    >
      <FiltersModalContent
        filter={filter}
        filterSources={filterSources}
        onSearch={handleSearch}
        onClose={toggleModalFilters}
        onClear={handleClear}
        onReset={handleReset}
      />
    </Modal>
  );
};

export default FiltersModal;
