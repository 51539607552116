import { createSelector } from 'reselect';
import { selectors as fetchSelectors } from '@redux/feature/fetch';
import { CompanyTouchesSearchState } from '../interfaces';
import { DEFAULT_TOUCHES_COUNT } from '../../constants/touches';

// specific selectors
const selectSearchState = (state: CompanyTouchesSearchState) => state;

export const shouldInitialFetch = createSelector(
  selectSearchState,
  fetchSelectors.shouldInitialFetchSelector
);

export const getTouches = createSelector(selectSearchState, state => state.data);

export const hasMore = createSelector(selectSearchState, state =>
  state.data ? !!state.data.touches_left : false
);

export const getCompanyId = createSelector(selectSearchState, state => state.companyId);

export const getTouchesCount = createSelector(selectSearchState, state => {
  if (!state.data) {
    return DEFAULT_TOUCHES_COUNT;
  }

  return state.data.number_of_touches;
});

export const getTotalTouches = createSelector(selectSearchState, state => {
  let totalTouches: number = 0;

  if (state.data) {
    const { number_of_touches: numberOfTouches } = state.data;

    totalTouches =
      numberOfTouches.scheduled +
      numberOfTouches.last_six_weeks +
      numberOfTouches.over_six_months +
      numberOfTouches.over_six_weeks;
  }

  return totalTouches;
});
