import React, { useEffect, useState } from 'react';
import { RadioChangeEvent, Button, Col, Row, Typography } from 'antd';
import { Form } from 'formik-antd';
import { useField } from 'formik';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { CheckCircleTwoTone } from '@ant-design/icons';
// models
import { AppState } from '@optx/redux/interfaces';
import { BaseField } from '@optx/models/companyFields';
import { CompanyFormRationale } from '@optx/models/filters';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import {
  SOFTWARE_RATIONALE_KEY,
  PSG_FIT_RATIONALE_KEY,
  STAGE_RATIONALE_KEY,
} from '@features/add-research-rationale/constants';
// redux
import {
  actions as companyEditAllActions,
  selectors as companyEditAllSelectors,
} from '@optx/features/edit-all-info/state';
import { getFormRationales } from '@optx/redux/company/filters/selectors';
// components
import RationaleSection from '@features/company/edit-fields/components/RationaleSection';
import Icon from '@components/common/Icon';
import { Styled } from './RadioGroupField.styled';

interface RadioGroupFieldProps {
  field: BaseField;
}
interface RationaleListInterface {
  [key: string]: CompanyFormRationale;
}

const rationaleList = {
  software_rationale: SOFTWARE_RATIONALE_KEY,
  psg_fit_rationales: PSG_FIT_RATIONALE_KEY,
  stage_rationale: STAGE_RATIONALE_KEY,
} as RationaleListInterface;

const RadioGroupField: React.FC<RadioGroupFieldProps> = ({ field }) => {
  const [selected, , setSelected] = useField<string | null>(field?.id || '');
  const [fieldRequired, , setFieldRequired] = useField<boolean | string | number | null>(
    field.requiredFor?.field ?? ''
  );
  const [sectionVisible, setSectionVisible] = useState<boolean>(false);
  const [rationaleBoxVisible, setRationaleBoxVisible] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [otherRationale, setOtherRationale] = useState<string | null>(null);
  const [oldValue, setOldValue] = useState<string[]>([]);

  const rationaleFor = rationaleList[field.id] ?? 'stage_pass_dead_rationales';
  const rationaleOptions = useSelector(
    (state: AppState) => getFormRationales(state, rationaleFor) ?? []
  );
  const dispatch = useDispatch();
  const saveBtnStatus = useSelector(companyEditAllSelectors.getDisableSaveBtnFor);

  useEffect(() => {
    setSelectedOption(selected.value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  useEffect(() => {
    if (field.requiredFor) {
      const requiredList = Array.isArray(field.requiredFor.value)
        ? [...field.requiredFor.value]
        : [field.requiredFor.value];
      const isRequiredValue = requiredList.some(item => isEqual(item, fieldRequired.value));

      const fieldValue = fieldRequired.value?.toString();

      if (isRequiredValue && !oldValue[0]) {
        setSectionVisible(true);
      } else if (isRequiredValue && oldValue[0] !== fieldRequired.value) {
        setSectionVisible(true);

        dispatch(companyEditAllActions.changeSaveBtnStatus(field.id));
        setSelectedOption(null);
      } else if (fieldRequired.value === null || (!!fieldValue && !isRequiredValue)) {
        setSectionVisible(false);
        setRationaleBoxVisible(false);
        setSelected.setValue(null);
        setSelectedOption(null);
      } else if (
        !!fieldValue &&
        (isEqual(fieldRequired.value, requiredList[0]) ||
          isEqual(fieldRequired.value, requiredList[1]))
      ) {
        dispatch(companyEditAllActions.changeSaveBtnStatus(field.id));
      }

      if (!!fieldValue) {
        setOldValue([fieldValue, ...oldValue]);
      } else if (fieldRequired.value === null) {
        setOldValue(['null', ...oldValue]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldRequired.value]);

  const handleRationaleChange = (e: RadioChangeEvent) => {
    const newValue = e.target.value;
    setSelectedOption(newValue);
  };

  const showIsOtherOrTag = (key?: string) => {
    const values = rationaleOptions.map(option => option.value).slice(0, -1);

    if (key === 'tag') {
      return !!(
        selectedOption &&
        selectedOption !== DEFAULT_EMPTY_VALUE &&
        otherRationale &&
        !values.includes(selectedOption)
      );
    }

    return !!(
      key !== 'tag' &&
      selectedOption &&
      !values.includes(selectedOption) &&
      selectedOption !== DEFAULT_EMPTY_VALUE &&
      selectedOption.length > 0
    );
  };

  const handleOtherChange = (newValue: string | null) => {
    setOtherRationale(newValue);
    setSelectedOption(newValue);
  };

  const handleRationaleSave = () => {
    setRationaleBoxVisible(false);
    setSelected.setValue(selectedOption);
    dispatch(companyEditAllActions.changeSaveBtnStatus(null));

    const values = rationaleOptions.map(option => option.value).slice(0, -1);

    if (selectedOption && values.includes(selectedOption)) {
      setOtherRationale(null);
    }
  };

  const handleRationaleCancel = () => {
    dispatch(companyEditAllActions.changeSaveBtnStatus(null));
    setSelectedOption(selected.value);

    if (oldValue[1] === 'true' || oldValue[1] === 'false') {
      setFieldRequired.setValue(oldValue[1] === 'true' ? true : false);
    } else if (oldValue[1] === 'null') {
      setFieldRequired.setValue(null);
    } else if (!isNaN(Number(oldValue[1]))) {
      setFieldRequired.setValue(Number(oldValue[1]));
    } else if (oldValue[1]) {
      setFieldRequired.setValue(String(oldValue[1]));
    }

    setOldValue([...oldValue.slice(1)]);

    if (selectedOption || field.id !== 'stage_rationale') {
      setRationaleBoxVisible(false);

      return;
    }
  };

  const handleRationaleOpen = () => {
    setOldValue([oldValue[0], ...oldValue]);
    dispatch(companyEditAllActions.changeSaveBtnStatus(field.id));
  };

  const selectedLabel = rationaleOptions.find(option => option.value === selectedOption)?.label;
  const label = selectedOption === null ? 'Not selected' : selectedLabel || 'Other';

  let stageLabel = '';

  if (field.id === 'stage_rationale') {
    stageLabel =
      (fieldRequired.value === field.requiredFor.value[0] && 'Dead') ||
      (fieldRequired.value === field.requiredFor.value[1] && 'Pass') ||
      '';
  }

  useEffect(() => {
    if (!saveBtnStatus) {
      setRationaleBoxVisible(false);
    } else if (saveBtnStatus && saveBtnStatus !== field.id) {
      setRationaleBoxVisible(false);
    } else if (saveBtnStatus && saveBtnStatus === field.id) {
      setRationaleBoxVisible(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveBtnStatus]);

  return (
    <>
      {sectionVisible && !rationaleBoxVisible && (
        <Row gutter={[0, 0]} style={{ width: '100%' }}>
          <Col span={24}>
            <Form.Item name={field.id}>
              <Styled.RationaleVerifiedWrapper>
                <CheckCircleTwoTone twoToneColor="#52c41a" />
                {['Dead', 'Pass'].includes(stageLabel) ? (
                  <span className="rationale-label">Add {stageLabel} Rationale: </span>
                ) : (
                  <div className="rationale-label">Rationale: </div>
                )}
                <div className="rationale-value" onClick={() => handleRationaleOpen()}>
                  <span>{label}</span>
                  <div className="rationale-edit-icon">
                    <Icon iconName="pen" />
                  </div>
                </div>
              </Styled.RationaleVerifiedWrapper>
            </Form.Item>
          </Col>
        </Row>
      )}
      {sectionVisible && rationaleBoxVisible && (
        <Styled.ChangeRationaleWrapper>
          <Row gutter={[0, 0]}>
            <Col span={24}>
              <Row justify="space-between" className="heading-wrapper">
                <Col>
                  {stageLabel ? (
                    <div>
                      <Typography.Title>
                        Add {stageLabel} Rationale:{' '}
                        <span className="required-label">(Required) </span>
                      </Typography.Title>
                    </div>
                  ) : (
                    <Typography.Text>{field.label}</Typography.Text>
                  )}
                </Col>
                <Col>
                  <Button onClick={handleRationaleCancel} className="cancel-btn">
                    Cancel
                  </Button>
                  <Button
                    onClick={handleRationaleSave}
                    className="save-rationale-btn"
                    disabled={!selectedOption && field.id === 'stage_rationale'}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
              <Row className="body-row">
                <RationaleSection
                  rationaleOptions={rationaleOptions}
                  rationaleValue={label === 'Other' ? 'other' : selectedOption}
                  currRationaleValue={otherRationale}
                  handleRationaleChange={handleRationaleChange}
                  showIsOtherOrTag={showIsOtherOrTag}
                  handleOtherChange={handleOtherChange}
                  isFromTouch={true}
                  isRationaleRequired={false}
                />
              </Row>
            </Col>
          </Row>
        </Styled.ChangeRationaleWrapper>
      )}
    </>
  );
};

export default React.memo(RadioGroupField);
