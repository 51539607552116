import React, { useRef } from 'react';

import { Menu, Divider, Button, Dropdown, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Input } from 'formik-antd';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { useToggle } from '../../../../common/hooks/modal';
import './styles.scss';

interface DropdownSaveSearchProps {
  onSave: (title: string) => void;
  onCancel?: () => void;
  validationSchema?: yup.ObjectSchema<SaveSearchValues>;
  label?: string;
  Toggle?: React.ReactNode;
  disabled?: boolean;
}

export interface SaveSearchValues {
  title: string;
}

const initialValues: SaveSearchValues = {
  title: '',
};

const validationSchema = yup.object<SaveSearchValues>({
  title: yup.string().required('Field is required!'),
});

const DropdownSaveSearch: React.FC<DropdownSaveSearchProps> = ({
  onSave,
  validationSchema,
  onCancel,
  label,
  Toggle,
  disabled,
}) => {
  const [dropdownOpen, toggleDropdownOpen] = useToggle(false);
  const formRef = useRef<FormikProps<SaveSearchValues> | null>(null);

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm();
    }
  };

  const handleSave = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const handleToggle = () => {
    if (dropdownOpen) {
      resetForm();
    }

    onCancel && onCancel();

    toggleDropdownOpen();
  };

  const handleSubmit = (values: SaveSearchValues) => {
    onSave(values.title);
    toggleDropdownOpen();
    resetForm();
  };

  const toggle = Toggle || (
    <Button className="btn btn-primary">
      <PlusOutlined className="mr-1" />
      {label}
    </Button>
  );

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" disabled className="dropdown-item--actions">
        <Typography.Text className="dropdown-header text-center">{label}</Typography.Text>
        <Divider />
        <Form.Item name="title" className="and-item-save-search-list">
          <Input name="title" placeholder="List Name" />
        </Form.Item>
        <Divider />
        <Button onClick={handleToggle}>Cancel</Button>
        <Button type="primary" onClick={handleSave}>
          Save
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
      innerRef={instance => {
        formRef.current = instance;
      }}
      component={() => (
        <Form onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
          <Dropdown
            overlay={menu}
            overlayClassName="dropdown--save-list"
            trigger={['click']}
            disabled={disabled}
            onVisibleChange={resetForm}
          >
            {toggle}
          </Dropdown>
        </Form>
      )}
    />
  );
};

DropdownSaveSearch.defaultProps = {
  validationSchema,
  label: 'Save Search',
};

export default DropdownSaveSearch;
