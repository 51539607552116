type roleLabelConvertType = {
  [key: string]: string;
};

export const mappingRole = (label: string) => {
  if (!label) return '';
  const roleLabelConver: roleLabelConvertType = {
    analyst: 'Analyst',
    administrator: 'Administrator',
    partner_user: 'Partner',
  };

  return roleLabelConver[label];
};
