import React from 'react';
import { Popover as AntPopver } from 'antd';
import { PopoverProps } from 'antd/lib/popover';

/**
 * Wrapper for ANT Popover allowing styles with styled-components.
 * By default in ANT Popover className is passed to the child
 * and due to how styled-components work we pass it to overlayClassName to allow popover styling.
 */
const Popover: React.FC<PopoverProps> = ({ className, ...rest }) => {
  return <AntPopver overlayClassName={className} {...rest} />;
};

export default Popover;
