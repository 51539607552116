import React, { useState } from 'react';
import { Tooltip, Button } from 'antd';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
// redux
import { selectors as searchSelectors } from '@redux/contacts/search/search';
import { selectors as searchCountSelectors } from '@features/grid/search-count';
import { selectors as filterSourcesSelectors } from '@redux/contacts/filters';
// utils
import mapFilterToParams from '@utils/filters/mapFiltersToURLParams';
import { exportFileCSV } from '@utils/csvHandlerContacts';
import { mapSortFieldQuery } from '@utils/mapSortFieldQuery';
import { mapSortOrderQuery } from '@utils/mapSortOrderQuery';
// constants
import { COMPANY_CSV_SIZE_LIMIT } from '@constants/limit';

const FILE_NAME = 'saved contacts search';

interface ExportCSVButtonProps {
  handleVisibleChange: () => void;
}

const ExportCSVButton = ({ handleVisibleChange }: ExportCSVButtonProps) => {
  const contacts = useSelector(searchSelectors.getContacts);
  const total = useSelector(searchCountSelectors.getSearchCount('contacts'));
  const searchContactsCount = total;
  const searchHasValues = contacts.length > 0;
  const searchKey = useSelector(searchSelectors.getSearchKey);
  const filter = useSelector(searchSelectors.getFilter);
  const sortBy = useSelector(searchSelectors.getSorting);
  const filtersMap = useSelector(filterSourcesSelectors.getFiltersMap);
  const [isLoading, setIsLoading] = useState(false);

  const handleExportCSV = () => {
    const params = mapFilterToParams(filtersMap, filter);

    const paramsQuery = queryString.stringify(
      { ...params, ...(searchKey ? { query: searchKey } : {}) },
      { arrayFormat: 'comma' }
    );

    const parameterFilter = {
      filters: paramsQuery,
      sortFields: queryString.stringify(
        { sort_fields: sortBy.map(mapSortFieldQuery) },
        { arrayFormat: 'comma' }
      ),
      sortOrders: queryString.stringify(
        {
          sort_orders: sortBy.map(mapSortOrderQuery),
        },
        { arrayFormat: 'comma' }
      ),
    };

    setIsLoading(true);

    exportFileCSV(FILE_NAME, parameterFilter).then(() => {
      setIsLoading(false);
      handleVisibleChange && handleVisibleChange();
    });
  };

  const disabled = !searchHasValues || searchContactsCount > COMPANY_CSV_SIZE_LIMIT || isLoading;

  return (
    <Tooltip title="Up to 100K rows" placement="bottom">
      <Button type="text" onClick={handleExportCSV} disabled={disabled}>
        {isLoading ? 'Exporting' : 'Export to .csv'}
      </Button>
    </Tooltip>
  );
};

export default ExportCSVButton;
