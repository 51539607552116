import React, { ReactText, useState, useContext, useEffect } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
// models
import { EquityTouchContact } from '@models/equityTouch';
import { CompanyProfile } from '@models/Company';
// constants
import { CONTACT_SELECT_LIMIT } from '@optx/constants/contacts';
import { defaultColumns } from '@components/common/table/TableContactsEquityTouchBulk';
import { PRESENT_DATE_FORMAT, ISO_DATE_FORMAT } from '@optx/constants/format/date';
// utils
import { validateContactLimit } from '@optx/utils/contact';
// redux

import { actions as equityTouchActions } from '@features/bulk-actions/equity-touch';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
// components
import { Context } from '../common/Context';

interface ContactsTableProps {
  readOnly?: boolean;
}

const ContactsTable: React.FC<ContactsTableProps> = ({ readOnly }) => {
  const dispatch = useDispatch();
  const { step, setSelectedContacts } = useContext(Context);
  // @ts-ignore
  const { grid } = useParams();
  const temporaryData = useSelector(bulkActionsSelectors.equityTouch.getTemporaryData);
  const companiesById = useSelector(bulkActionsSelectors.equityTouch.getCompanies);
  const companyData = useSelector(
    bulkActionsSelectors.equityTouch.getCompanyData
  ) as CompanyProfile;
  const contacts = useSelector(bulkActionsSelectors.equityTouch.getContacts);
  const companiesData = useSelector(
    bulkActionsSelectors.selectedCompanies.getSelectedCompanies(grid)
  );
  const formData = useSelector(bulkActionsSelectors.equityTouch.getFormData);
  const [displayedContacts, setDisplayedContacts] = useState<EquityTouchContact[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<ReactText[]>([]);

  const onSelectChange = (selectedRowKeys: ReactText[], selectedRows: EquityTouchContact[]) => {
    if (validateContactLimit(selectedRowKeys.length)) {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedContacts(selectedRows);
    }

    dispatch(
      equityTouchActions.updateTemporaryData({
        step2: {
          ...temporaryData?.step2,
          [companyData?.company_id]: {
            ...(temporaryData?.step2?.[companyData.company_id] as any),
            contacts: selectedRows || [],
          },
        } as any,
      })
    );
    dispatch(
      equityTouchActions.autoSave({
        step: step + 1,
        grid,
        queue: companyData.company_id,
        companiesById: {
          ...temporaryData.step2,
          [companyData?.company_id]: {
            ...(temporaryData?.step2?.[companyData.company_id] as any),
            contacts: selectedRows || [],
          },
        },
        companiesData,
        firstStepData: (temporaryData as any).step1,
        formData: formData as any,
        date: moment().format(ISO_DATE_FORMAT),
      })
    );
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: () => ({
      disabled: readOnly,
    }),
  };

  useEffect(() => {
    if (Object.keys(companyData).length && Object.keys(companiesById).length && !readOnly) {
      let selectContacts: ReactText[] = [];
      const selectedCompanyById = companiesById[(companyData as CompanyProfile).company_id];

      if (selectedCompanyById && selectedCompanyById.contacts) {
        selectContacts = (selectedCompanyById.contacts as EquityTouchContact[])
          .slice(0, CONTACT_SELECT_LIMIT)
          .map(contact => contact.person_id as number);
      }

      setSelectedRowKeys(selectContacts);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts, companyData, companiesById]);

  useEffect(() => {
    if (contacts) {
      const updatedTentureDateContacts = contacts.map(contact => ({
        ...contact,
        ...(contact.tenure_start_date && {
          tenure_start_date: moment(contact.tenure_start_date).format(ISO_DATE_FORMAT),
        }),
        ...(contact.tenure_end_date && {
          tenure_end_date:
            contact.tenure_end_date !== PRESENT_DATE_FORMAT
              ? moment(contact.tenure_end_date).format(ISO_DATE_FORMAT)
              : contact.tenure_end_date,
        }),
      }));
      setDisplayedContacts(updatedTentureDateContacts);
    }
  }, [contacts]);

  useEffect(() => {
    setSelectedRowKeys(
      //@ts-ignore
      temporaryData.step2?.[companyData?.company_id]?.contacts
        ?.slice(0, CONTACT_SELECT_LIMIT)
        .map((contact: { person_id: number }) => contact.person_id as number) || []
    );
  }, [companyData?.company_id, temporaryData.step2]);

  return (
    <Table
      columns={defaultColumns}
      dataSource={displayedContacts}
      rowKey={contact => contact.person_id as number}
      rowSelection={rowSelection}
      bordered
      pagination={false}
      tableLayout="fixed"
      className="overflow-auto legacy-table"
    />
  );
};

export default ContactsTable;
