import { combineReducers } from 'redux';
// models
import { BulkActionsState } from './interfaces';
// redux
import { selectedCompaniesReducer } from '../selected-companies';
import { editingFieldsReducer } from '../edit-fields';
import { equityTouchReducer } from '../equity-touch';
import { bulkSalesloftReducer } from '../salesloft';
import { mergeCompaniesReducer } from '../bulk-merge-companies';
import { bulkSoftwareReducer } from '../bulk-software';
import { bulkAiMlReducer } from '../bulk-ai-ml';

const reducer = combineReducers<BulkActionsState>({
  selectedCompanies: selectedCompaniesReducer,
  editingFields: editingFieldsReducer,
  equityTouch: equityTouchReducer,
  salesloft: bulkSalesloftReducer,
  mergeCompanies: mergeCompaniesReducer,
  software: bulkSoftwareReducer,
  aiMl: bulkAiMlReducer,
});

export default reducer;
