import { createFeatureActionType } from '@optx/utils/feature';
import featureName from '../featureName';

export const selectCompanyIds = createFeatureActionType(featureName, 'SELECT_COMPANY_IDS');

export const fetchBulkEditData = createFeatureActionType(featureName, 'FETCH_BULK_EDIT_DATA');
export const fetchBulkEditDataSuccess = createFeatureActionType(
  featureName,
  'FETCH_BULK_EDIT_DATA_SUCCESS'
);
export const bulkUpdateDataSuccess = createFeatureActionType(
  featureName,
  'BULK_UPDATE_DATA_SUCCESS'
);
