import styled from 'styled-components';
import { Title } from '@optx/shared/view/atoms/Typography';
import { Row, Col } from 'antd';

interface SearchGridWrapperProps {
  isFullScreen: boolean;
}

const SearchGridWrapper = styled(Row)<SearchGridWrapperProps>`
  min-height: ${props => (props.isFullScreen ? '56px' : '86px')};
  background-color: transparent;

  .ant-typography,
  .ant-typography-ellipsis,
  .h1,
  .span {
    color: #ffffff;
    font-size: 24px;
    font-weight: 600;
  }
`;

const ControlsWrapper = styled(Col)`
  position: relative;

  .prefixed-selection-item__label {
    color: #1890ff;
  }
  .ant-select-arrow,
  .prefixed-selection-item__prefix {
    color: #ffffff;
  }
  .ant-select-single.ant-select-open .ant-select-selection-item,
  .ant-select-selection-item {
    color: #ffffff;
  }

  .ant-btn,
  .ant-dropdown-trigger,
  .ant-typography,
  .ant-typography-ellipsis,
  .ant-typography-ellipsis-single-line {
    font-size: 15px;
  }
  .ant-btn {
    margin-left: 8px;
  }

  .ant-btn-icon-only {
    margin-left: 4px;
  }
`;

const PageTitle = styled(Title)`
  h1& {
    flex: 1 1 20%;
    margin: 0;
    color: #ffffff;
  }
`;

const PageTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const GridViewWrapper = styled.span`
  .ant-dropdown-trigger {
    background: #074681;
    border: 1px solid #1890ff;
    border-radius: 3px;
    color: #ffffff;

    .ant-typography {
      color: #1890ff;
      font-weight: 400;
    }
    .anticon {
      color: #ffffff;
    }
  }
`;

export default {
  SearchGridWrapper,
  PageTitleWrapper,
  ControlsWrapper,
  PageTitle,
  GridViewWrapper,
};
