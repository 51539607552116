import { createSelector } from 'reselect';
import featureName from '../featureName';
import { CustomGlobalLoader } from './interfaces';

type StateSlice = Record<typeof featureName, CustomGlobalLoader>;

const selectFeatureState = (state: StateSlice) => state[featureName];

export const loading = createSelector(selectFeatureState, state => state.loading);
export const customText = createSelector(selectFeatureState, state => state.customText);
