import {
  useInjectContactsSavedSearches,
  useInjectContactsFilterSources,
  useInjectCompanySavedSearches,
  useInjectSuggestedSearches,
  useInjectShareList,
  useInjectUserList,
  useInjectSourceScrubLists,
  useInjectFavoriteLists,
  useInjectMyPipeline,
  useInjectContactsSearch,
  useInjectCombineSearch,
  useInjectOpportunityPresentation,
} from '@optx/common/hooks/inject';

export const useInjectPage = () => {
  // company
  useInjectMyPipeline();
  useInjectSuggestedSearches();
  useInjectCompanySavedSearches();
  useInjectUserList();
  useInjectCombineSearch();
  useInjectOpportunityPresentation();

  // contacts
  useInjectContactsFilterSources();
  useInjectContactsSearch();
  useInjectContactsSavedSearches();

  useInjectFavoriteLists();
  useInjectShareList();
  useInjectSourceScrubLists();
};
