import { Typography } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;

  .qualified_opportunities_ops {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .qualified_opportunities_platform,
    .qualified_opportunities_addon {
      min-width: 115px;
      cursor: pointer;
    }
  }

  .additional-title {
    color: #bfbfbf;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    font-family: 'Lato', sans-serif;
  }
  .trending-percentage {
    vertical-align: top;
    line-height: 32px;
  }
`;

export const Title = styled(Typography.Title)`
  &.ant-typography {
    color: #334d6e;
  }
`;

export const TitleValue = styled(Typography.Text)`
  /* Daybreak Blue / blue-6 */
  color: #1890ff;
  margin: 0 8px 0 0;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.5px;
  font-family: 'Lato', sans-serif;
  span:last-child {
    vertical-align: top;
  }
`;

const Styles = {
  Wrapper,
  Title,
  TitleValue,
};

export default Styles;
