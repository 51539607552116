import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ValueType } from 'react-select';
// models
import { SingleSelectColumns } from '@optx/models/feature/analystsLeaderboard/filters';
import { SelectOption } from '@models/Option';
import { AppState } from '@optx/redux/interfaces';
import { MultiSelectFilter } from '@optx/models/filters';
// components
import { SingleSelect, SingleSelectProps } from '@shared/view/molecules/Select';

// Local
import { selectors, actions } from '../state';

interface FilterSingleSelectProps extends SingleSelectProps {
  filterKey: SingleSelectColumns;
  criteria?: string;
}

const FilterSingleSelect: React.FC<FilterSingleSelectProps> = ({
  filterKey,
  criteria,
  ...restProps
}) => {
  const dispatch = useDispatch();

  const filter = useSelector((state: AppState) =>
    selectors.filters.getFilter(state, filterKey)
  ) as MultiSelectFilter<Array<SelectOption>>;
  const options = useSelector((state: AppState) =>
    selectors.filters.getSelectOptions(state, filterKey)
  );
  const value = useSelector((state: AppState) =>
    selectors.filters.getSelectValue(state, filterKey, criteria)
  );

  const updateValue = useCallback(
    (newValue: ValueType<SelectOption>) => {
      let finalValue = newValue;

      if (newValue && !Array.isArray(newValue)) {
        finalValue = [newValue as SelectOption];
      }

      dispatch(actions.filters.updateValue({ value: finalValue, filterKey, criteria }));
    },
    [dispatch, filterKey, criteria]
  );

  return (
    <SingleSelect
      options={options}
      onChange={updateValue}
      value={value}
      placeholder={filter ? filter.placeholders : undefined}
      {...restProps}
    />
  );
};

export default FilterSingleSelect;
