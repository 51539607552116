import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import reducer, { saga } from '../../../redux/company/touches';

const useInjectCompanyTouches = () => {
  useInjectReducer({
    key: 'companyTouches',
    reducer,
  });
  useInjectSaga({
    key: 'companyTouches',
    saga,
  });
};

export default useInjectCompanyTouches;
