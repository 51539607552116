import { ColumnType } from 'antd/lib/table';
// redux
import { CompanyBulkEditingResults } from '@optx/models/bulkActions';
// components
import {
  TableCellURL,
  TableCellCompanyName,
  TableCellStatus,
} from '@components/common/table/AntTable/cells';
import { TableCellErrorReason } from './cells';

const columns: ColumnType<CompanyBulkEditingResults>[] = [
  {
    dataIndex: 'company_url',
    title: '',
    render: (value: string, record: CompanyBulkEditingResults) => (
      <TableCellURL value={value} record={{ url: record.raw_url }} />
    ),
    width: 42,
    align: 'center',
  },
  {
    dataIndex: 'company_name',
    title: 'Company Name',
    render: TableCellCompanyName,
  },
  {
    dataIndex: 'sync_status',
    title: 'Sync Status',
    render: TableCellStatus,
  },
  {
    dataIndex: 'error_reason',
    title: 'Error Reason',
    className: 'contact-cell',
    render: TableCellErrorReason,
  },
];

export default columns;
