import React from 'react';
import { BulkEquityTouchContext } from '@optx/models/bulkActions';

export const Context = React.createContext<BulkEquityTouchContext>({
  step: 0,
  setStep: step => {},
  title: '',
  setTitle: title => {},
  submitForm: null,
  setSubmitForm: submit => {},
  cadence: undefined,
  setCadence: cadence => {},
  selectedContacts: [],
  setSelectedContacts: contacts => {},
  addon: undefined,
  setAddon: addon => {},
  isAddon: false,
  setIsAddon: addon => {},
  errors: [],
  setErrors: errors => {},
});
