import React from 'react';
import { Row, Col } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
// hooks
import {
  useInitialFetchEditOptions,
  useInject as useInjectCompanyIndividualEdit,
} from '@components/feature/company-individual-edit';
// components
import Touches from './Touches';
import ProfilePrimaryContacts from './ProfilePrimaryContacts';
import EditContact from './EditContact';

const gridGutter: Gutter | [Gutter, Gutter] = [
  { xs: 24, sm: 24, xl: 40 },
  { xs: 24, sm: 24, xl: 40 },
];

interface NotesAndTouchesProps {
  activeProfileKey: string;
}

const NotesAndTouches: React.FC<NotesAndTouchesProps> = ({ activeProfileKey }) => {
  useInjectCompanyIndividualEdit();
  useInitialFetchEditOptions();

  return (
    <Row gutter={gridGutter} className="notes-and-touches">
      <Col span={24} xl={12}>
        <Touches activeProfileKey={activeProfileKey} />
      </Col>
      <Col span={24} xl={12}>
        <EditContact />
        <ProfilePrimaryContacts />
        {/* <ProfileNotes /> */}
      </Col>
    </Row>
  );
};

export default NotesAndTouches;
