import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { components, IndicatorProps, ValueContainerProps } from 'react-select';
import { useField } from 'formik';
import AsyncSelect from 'react-select/async';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
// models
import { SuccessErrorCallback } from '@models/callback';
import { SelectOption } from '@models/Option';
import { EquityTouchDatabaseMatch, EquityTouchFetchedField } from '@models/equityTouch';
// constants
import { GET_ET_COMPANIES_ENDPOINT } from '@constants/asyncEndpoints';
// redux
import { selectors } from '@redux/company/equity-touch';
// hooks
import useAsyncSearch from '@hooks/select/useAsyncSearch';
// styled components
import Styles from './EquityTouchAddon.style';

/**
 * customValueContainer - style the search area of the dropdown.
 */
const ValueContainer: React.FC<ValueContainerProps<any>> = ({ children, ...restProps }) => (
  <components.ValueContainer {...restProps}>
    <span className="multiselect-select__value-container__icon">
      <SearchOutlined />
    </span>
    {children}
  </components.ValueContainer>
);

/**
 * customDropdownIndicator - style the arrow of the dropdown.
 */
const DropdownIndicator: React.FC<IndicatorProps<any>> = props => (
  <components.DropdownIndicator {...props}>
    <DownOutlined />
  </components.DropdownIndicator>
);
interface EquityTouchAddonProps {
  name: string;
  initialValue: EquityTouchFetchedField;
}

const EquityTouchAddon: React.FC<EquityTouchAddonProps> = ({ name, initialValue }) => {
  const formValues = useSelector(selectors.getFormValues);
  const [defaultValues, setDefaultValues] = useState<
    Array<EquityTouchDatabaseMatch | SelectOption>
  >([{ value: '', label: 'Select' }, ...initialValue.received_values]);
  const [field, , helpers] = useField(name);
  const { loadOptions } = useAsyncSearch({ endpoint: GET_ET_COMPANIES_ENDPOINT });
  const hasFormValues = !!Object.keys(formValues).length;

  useEffect(() => {
    hasFormValues && helpers.setValue(formValues[name]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFormValues, formValues, name]);

  useEffect(() => {
    // if an error comes back from api after submit, update addon
    // with value selected from form, check against initial value coming from api
    if (
      hasFormValues &&
      formValues[name] &&
      !defaultValues.some(
        item => (item as SelectOption).value === (formValues[name] as SelectOption).value
      )
    ) {
      setDefaultValues([...defaultValues, formValues[name] as SelectOption]);
    }

    helpers.setValue(hasFormValues ? formValues[name] : initialValue.received_values[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value: any) => {
    // add the option if it doesn't already exist
    if (!defaultValues.find(option => (option as SelectOption).value === value.value)) {
      setDefaultValues([...defaultValues, value]);
    }

    if (value.value === '') {
      helpers.setValue('');

      return;
    }

    helpers.setValue(value);
  };

  const getLoadOptionsDelay = (query: string, callback: SuccessErrorCallback) => {
    if (query.length > 2) {
      loadOptions(query, options => callback(options));
    }
  };

  return (
    <Styles.AsyncWrapper>
      <AsyncSelect
        cacheOptions
        loadOptions={getLoadOptionsDelay}
        defaultOptions={defaultValues}
        value={field.value}
        defaultValue={hasFormValues ? initialValue.database_matched : initialValue.received_values}
        onChange={handleChange}
        classNamePrefix="multiselect-select"
        components={{
          ValueContainer,
          DropdownIndicator,
        }}
      />
    </Styles.AsyncWrapper>
  );
};

export default EquityTouchAddon;
