import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { useAnalytics } from '../../../common/hooks/analytics';

const Analytics: React.FC = () => {
  const location = useLocation();
  const hasGAInitialized = useAnalytics();

  useEffect(() => {
    if (hasGAInitialized) {
      ReactGA.set({ page: location.pathname });
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  }, [hasGAInitialized, location]);

  return null;
};

export default React.memo(Analytics);
