import { combineReducers } from 'redux';

// BIG ISSUE: TO BE INVESTIGATED
// For some reason if I try to import directly from feature doesn't work and module is undefined for reducers.
import { reducers as histogramsReducers } from '@optx/features/histograms/histograms-list-details/state';
import { UserListsSearchState } from './interfaces';
// reducers
import sortReducer from './sort';
import columns from './columns';

const reducer = combineReducers<UserListsSearchState>({
  histogramFilters: histogramsReducers.rootReducer,
  sort: sortReducer,
  columns,
});

export default reducer;
