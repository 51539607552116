import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
// redux
import { selectors as searchSelectors } from '../../../../redux/contacts/search/search';
import { actions as sortActions } from '../../../../redux/contacts/search/sort';

interface ToggleSortTypeProps {
  isFullScreen?: boolean;
}

const ToggleSortType = ({ isFullScreen }: ToggleSortTypeProps) => {
  const dispatch = useDispatch();
  const isMultiSort = useSelector(searchSelectors.isMultiSort);
  const multiSort = useCallback(
    () => dispatch(sortActions.multiSortAction(!isMultiSort)),
    [dispatch, isMultiSort]
  );

  return (
    <Button type={isFullScreen ? 'text' : 'default'} onClick={multiSort}>
      {isMultiSort ? 'Enable Single Sort' : 'Enable Multi Sort'}
    </Button>
  );
};

export default ToggleSortType;
