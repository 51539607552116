import React from 'react';
import styled from 'styled-components';
import { MenuListComponentProps, components } from 'react-select';
import { LoadingOutlined } from '@ant-design/icons';

const StyledLoadingOutlined = styled(LoadingOutlined)`
  font-size: 16px;
  color: #4ce1b6;

  width: 14px;
  margin: auto;
  margin-top: 8px;
  display: flex;
`;

const StyledMenuList = styled(components.MenuList)`
  min-height: 40px;
`;

const MenuListWithLoader: (isLoading: boolean) => React.FC<MenuListComponentProps<any>> =
  isLoading => props =>
    (
      <StyledMenuList {...props}>
        {props.children}
        {isLoading && <StyledLoadingOutlined />}
      </StyledMenuList>
    );

export default MenuListWithLoader;
