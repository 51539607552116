import React, { useState, useEffect, ReactElement } from 'react';
import { Dropdown, Button, Tooltip } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
// constants
import appRoutes from '@constants/routes';
import { COLUMNS_SOURCE_MANEGEMENT_TITLE } from '@optx/constants/titles';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';
// components
import { GridMenu, ListsMenu, MyCompaniesMenu } from './GridMenu';

const GridDropdown = () => {
  const [overlayMenu, setOverlayMenu] = useState<ReactElement | null>(null);
  const { pathname } = useLocation();
  const { advancedSearchPathname } = useIsAnalyst();

  useEffect(() => {
    if (pathname === appRoutes.myCompanies) {
      setOverlayMenu(MyCompaniesMenu);
    } else if (pathname.startsWith('/user-lists') || pathname.startsWith('/ss-lists')) {
      setOverlayMenu(ListsMenu);
    } else if (pathname === advancedSearchPathname) {
      setOverlayMenu(GridMenu);
    }
  }, [pathname, advancedSearchPathname]);

  if (!overlayMenu) return null;

  return (
    <Tooltip title={COLUMNS_SOURCE_MANEGEMENT_TITLE} placement="topRight">
      <Dropdown overlay={overlayMenu} trigger={['click']} placement="bottomRight">
        <Button type="text" icon={<SettingOutlined style={{ fontSize: 19 }} />} />
      </Dropdown>
    </Tooltip>
  );
};

export default React.memo(GridDropdown);
