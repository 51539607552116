import styled, { css } from 'styled-components';
import DefaultTable from '@shared/view/organims/Table';

const ContactTableWrapper = styled.div`
  .ant-table-body {
    overflow: hidden;
  }

  .ant-table-sticky-scroll {
    display: none;
  }
`;

const ContactTable = styled(DefaultTable)<{
  isEmpty?: boolean;
  overflowHeight?: number | null;
}>`
  ${props =>
    !props.isEmpty &&
    props.overflowHeight &&
    css`
      .ant-table-body::after {
        content: '';
        display: block;
        position: absolute;
        height: ${props.overflowHeight}px;
        width: 100%;
        bottom: 0;
        z-index: 2;
        background: #fff;
      }
    `}
  .ant-table-body tr {
    height: 47px;
  }
  .ant-table-column-sorter {
    margin-right: 8px;
  }
`;

export const Styled = {
  ContactTable,
  ContactTableWrapper,
};
