import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
// redux
import { selectors as searchSelectors } from '@redux/company/search/search';
import { actions as sortActions } from '@redux/company/search/sort';

const ClearSortButton = ({ onClick, ...restProps }: MenuItemProps) => {
  const dispatch = useDispatch();
  const sorting = useSelector(searchSelectors.getSorting);

  const clearSort = () => {
    dispatch(sortActions.resetSortAction());
  };

  if (!sorting.length) {
    return null;
  }

  return (
    <Menu.Item onClick={clearSort} {...restProps}>
      Clear All Sorting
    </Menu.Item>
  );
};

export default ClearSortButton;
