import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { isEmpty } from 'lodash';
// redux
import { selectors, actions } from '@redux/favorite-lists/lists-popup';
// hooks
import { useShouldFetch } from '../fetch';

const useInitFetchFavoriteListsPopup = () => {
  const dispatch = useDispatch();
  const shouldFetch = useSelector(selectors.shouldInitialFetch);
  const getAllWatchlists = useSelector(selectors.getAllWatchlists);
  const allWatchlistIsEmpty = isEmpty(getAllWatchlists);
  const fetchFiltersPopup = useCallback(
    () => dispatch(actions.initialFetchFavoritesListsPopup()),
    [dispatch]
  );

  useShouldFetch(shouldFetch && allWatchlistIsEmpty, fetchFiltersPopup);
};

export default useInitFetchFavoriteListsPopup;
