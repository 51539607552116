import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
// models
import { SelectOption } from '@optx/models/Option';
import CompanyIndividualEditOptions from '@models/feature/companyIndividualEdit/CompanyIndividualEditOptions';
import { ScoreAndTrendsResponse } from '@optx/models/score';
// services
import { CompanyService } from '@services/api';
import NotificationService from '@services/NotificationService';
// utils
import { getErrorMessage } from '@utils/api/errors';
// redux
import * as companyOwnerActions from '../companyOwner/actions';
import * as companyIndividualEditActions from '../companyIndividualEdit/actions';
import * as companyRationaledActions from '../rationale/actions';
import { EditCompanyOwner } from '../companyOwner/interfaces';
import { EditCompanyRationale } from '../rationale/interfaces';

function* fetchSelectOptionsSaga(action: PayloadAction<string | null>) {
  const fields = action.payload;

  try {
    const res: AxiosResponse<CompanyIndividualEditOptions> = yield call(
      CompanyService.getEquityTouchSelectOptions,
      fields
    );
    yield put(companyIndividualEditActions.fetchSelectOptionsSuccess(res.data));
  } catch (error: any) {
    const message = getErrorMessage(error, 'Failed fetching individual edit select options!');
    yield put(companyIndividualEditActions.fetchSelectOptionsFail(message));
  }
}

function* addRationaleSaga(action: PayloadAction<EditCompanyRationale>) {
  const { companyId, rationale } = action.payload;

  try {
    yield call(CompanyService.updateCompanyRationale, companyId, rationale);

    const successMessage = 'Company rationale updated successfully';
    NotificationService.success(successMessage);
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Company rationale update failed, Server error!');
    NotificationService.error(errorMessage);
  }
}

function* addCompanyOwnerSaga(action: PayloadAction<EditCompanyOwner>) {
  const { companyId, companyOwnerId, companyOwnerName } = action.payload;
  const value: SelectOption = {
    label: companyOwnerName,
    value: `${companyOwnerId}`,
  };

  try {
    const res: AxiosResponse<Partial<ScoreAndTrendsResponse>> = yield call(
      CompanyService.updateCompanyOwner,
      { value, companyId }
    );

    if (res.data) {
      yield put(
        companyOwnerActions.addCompanyOwnerSuccess({
          ...action.payload,
          companyOwnerData: res.data?.extra_data,
        })
      );
      const successMessage = 'Company owner updated successfully';
      NotificationService.success(successMessage);
    } else {
      const errorMessage = 'Company owner update failed, Server error!';
      yield put(companyOwnerActions.addCompanyOwnerFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Company owner update failed, Server error!');
    yield put(companyOwnerActions.addCompanyOwnerFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* companyIndividualEditSagas() {
  yield takeLatest(companyIndividualEditActions.fetchSelectOptions, fetchSelectOptionsSaga);
  yield takeLatest(companyOwnerActions.addCompanyOwner, addCompanyOwnerSaga);
  yield takeLatest(companyRationaledActions.addRationale, addRationaleSaga);
}
