export const DEFAULT_SORT_COMPANIES = [
  {
    id: 'company_name',
    desc: false,
  },
];

export const DEFAULT_SORT_ANALYST = [
  {
    id: 'total_companies_reviewed_cids',
    desc: true,
  },
];
