import { SalesloftBulkState } from './interfaces';

export const companies = (state: SalesloftBulkState) => state.companies;
export const results = (state: SalesloftBulkState) => state.results;
export const contacts = (state: SalesloftBulkState) =>
  state.companies.map(company => ({
    company_id: company.company_id,
    company_name: company.company_name,
    company_website: company.company_url,
    contacts: company.salesloft_contacts,
  }));
export const inProgress = (state: SalesloftBulkState) => state.inProgress;
export const isCompleted = (state: SalesloftBulkState) => state.completed;
export const modalVisible = (state: SalesloftBulkState) => state.modalVisible;
export const getPercentageCompleted = (state: SalesloftBulkState) => state.percentageCompleted;
export const cancel = (state: SalesloftBulkState) => state.cancel;
export const getCadenceId = (state: SalesloftBulkState) => state.cadenceId;
