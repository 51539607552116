import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { Col, Empty, Row } from 'antd';
// models
import { GridKeysMain } from '@models/grid';
// constants
import {
  LONG_CARD_VIEW_DEFAULT_PAGE_SIZE,
  LONG_CARD_VIEW_DEFAULT_LOAD_MORE_SIZE,
} from '@constants/pagination';
// redux
import { CompanyCard } from '@features/long-card/company-card';
import {
  actions as paginationActions,
  selectors as paginationSelectors,
} from '@features/grid/pagination-long-card';
import { selectors as searchCountSelectors } from '@features/grid/search-count';
import { selectors as searchSelectors } from '@features/grid/search';
import { CardPannel } from '@optx/features/long-card/card-pannel';
import { selectors as fullscreenSelectors } from '@redux/ui/settings/fullscreen';
import { actions as viewTypeActions } from '@features/grid/view-type';
// hooks
import useLoadCompanyData from '@optx/features/long-card/hooks/useLoadCompanyData';

const gridKey: GridKeysMain = 'myCompanies';

const LongCardView = () => {
  const dispatch = useDispatch();

  const companiesCount = useSelector(searchCountSelectors.getSearchCount(gridKey));
  const companies = useSelector(searchSelectors.getCompanies(gridKey));
  const loading = useSelector(searchSelectors.isLoading(gridKey));
  const isFullScreen = useSelector(fullscreenSelectors.isFullscreen);
  const { pageNumber, pageSize } = useSelector(
    paginationSelectors.getLongCardPagination('myCompanies')
  );

  const handleLoadMore = () => {
    const newPageSize: number = pageSize + LONG_CARD_VIEW_DEFAULT_LOAD_MORE_SIZE;

    // This resets the state so that the active company doesn't scroll into view when the page loads more cards.
    dispatch(viewTypeActions.changedCompanyCard({ gridKey, data: false }));
    dispatch(
      paginationActions.changeLongCardPagination({
        gridKey,
        data: { pageNumber, pageSize: newPageSize },
      })
    );
  };

  const { loadCompanyData } = useLoadCompanyData();

  useEffect(() => {
    loadCompanyData(companies[0]?.company_id, companies[0]?.company_url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies[0]?.company_id]);

  useEffect(() => {
    // when loadingCount is true it means that there's a new search,
    // so reset values
    if (pageSize === LONG_CARD_VIEW_DEFAULT_PAGE_SIZE) {
      window.scrollTo(0, 0);
    }
  }, [pageSize]);

  const infiniteScrollRef = useInfiniteScroll<HTMLDivElement>({
    loading,
    hasNextPage: companies.length < companiesCount,
    onLoadMore: handleLoadMore,
  });

  return (
    <div ref={infiniteScrollRef}>
      <Row style={{ marginTop: isFullScreen ? '-5rem' : '0' }} gutter={[16, 0]}>
        <Col xl={14} xxl={12}>
          {companies.map((company, index) => {
            return <CompanyCard key={index} company={company} />;
          })}
        </Col>
        <Col xl={14} xxl={12}>
          {companiesCount > 0 ? <CardPannel companies={companies} /> : <Empty />}
        </Col>
      </Row>
    </div>
  );
};

export default LongCardView;
