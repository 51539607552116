export const CHECK_EQUITY_TOUCH_AVAILABILITY = 'CHECK_EQUITY_TOUCH_AVAILABILITY';

export const FETCH_EQUITY_TOUCH_FORM_DATA = 'FETCH_EQUITY_TOUCH_FORM_DATA';
export const FETCH_EQUITY_TOUCH_FORM_DATA_SUCCESS = 'FETCH_EQUITY_TOUCH_FORM_DATA_SUCCESS';
export const FETCH_EQUITY_TOUCH_FORM_DATA_FAIL = 'FETCH_EQUITY_TOUCH_FORM_DATA_FAIL';

export const ADD_TO_EQUITY_TOUCH = 'ADD_TO_EQUITY_TOUCH';
export const ADD_TO_EQUITY_TOUCH_SUCCESS = 'ADD_TO_EQUITY_TOUCH_SUCCESS';

export const ADD_TO_EQUITY_TOUCH_UPDATE_SEARCH = 'ADD_TO_EQUITY_TOUCH_UPDATE_SEARCH';

export const UPDATE_EQUITY_TOUCH_SELECTED_CONTACTS = 'UPDATE_EQUITY_TOUCH_SELECTED_CONTACTS';
export const UPDATE_EQUITY_TOUCH_SELECTED_SALESLOFT_CONTACTS =
  'UPDATE_EQUITY_TOUCH_SELECTED_SALESLOFT_CONTACTS';

export const RESET_ADD_TO_EQUITY_TOUCH = 'RESET_ADD_TO_EQUITY_TOUCH';

export const EQUITY_TOUCH_SAVE_CONTACT = 'EQUITY_TOUCH_SAVE_CONTACT';
export const EQUITY_TOUCH_SAVE_CONTACT_FAIL = 'EQUITY_TOUCH_SAVE_CONTACT_FAIL';
export const EQUITY_TOUCH_ADD_CONTACT = 'EQUITY_TOUCH_ADD_CONTACT';

export const UPDATE_COMPANY_ET_DATA = 'UPDATE_COMPANY_ET_DATA';
export const AUTO_SAVE = 'AUTO_SAVE';
export const UPDATE_TEMPORARY_FORM_VALUES = 'UPDATE_TEMPORARY_FORM_VALUES';
