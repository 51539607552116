import { SortByRule } from '@models/table/sorting';

export const COMPANY_DEFAULT_SORT: Array<SortByRule<any>> = [
  {
    id: 'company_name',
    desc: false,
  },
];

export const CONTACT_DEFAULT_SORT: Array<SortByRule<any>> = [
  {
    id: 'last_name',
    desc: false,
  },
];

export const MYCOMPANIES_DEFAULT_SORT_US: Array<SortByRule<any>> = [
  {
    id: 'score',
    desc: true,
  },
];

export const MYCOMPANIES_DEFAULT_SORT_IL: Array<SortByRule<any>> = [
  {
    id: 'il_optx_score',
    desc: true,
  },
];

export const ANALYST_HOME_DEFAULT_SORT_US: Array<SortByRule<any>> = [
  {
    id: 'score',
    desc: true,
  },
];

export const ANALYST_HOME_DEFAULT_SORT_IL: Array<SortByRule<any>> = [
  {
    id: 'il_optx_score',
    desc: true,
  },
];

export const USER_DEFAULT_SORT: Array<SortByRule<any>> = [
  {
    id: 'display_name',
    desc: false,
  },
];

export const COMPANY_TAB_LISTS_DEFAULT_SORT: Array<SortByRule<any>> = [
  {
    id: 'modified_at',
    desc: true,
  },
];

export const SOURCE_SCRUB_LISTS_DEFAULT_SORT: Array<SortByRule<any>> = [
  {
    id: 'last_refreshed_at',
    desc: true,
  },
];

export const FAVORITE_LISTS_DEFAULT_SORT: Array<SortByRule<any>> = [
  {
    id: 'modified_at',
    desc: true,
  },
];

export const FAVORITE_LISTS_POPUP_DEFAULT_SORT: Array<SortByRule<any>> = [
  {
    id: 'recently_used',
    desc: true,
  },
];

// list of columns that should sort descending first when selected;
export const SORT_DESCENDING_BY_DEFAULT = ['score', 'year_founded'];

export const SCHEDULED_TOUCHES_DEFAULT_SORT: Array<SortByRule<any>> = [
  {
    id: 'optx',
    desc: false,
  },
];

export const FILES_DEFAULT_SORT: Array<SortByRule<any>> = [
  {
    id: 'registered_at',
    desc: true,
  },
];
