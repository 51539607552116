import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
// components
import { AsyncSingleSelect } from '@optx/shared/view/molecules/Select';

const ContentContainer = styled.div`
  .ant-row {
    padding: 0 !important;
  }
`;
const AddOnLinkContainer = styled.div`
  .action-wrapper {
    visibility: hidden;
    position: absolute;
    right: 0;
    top: 0;
  }

  &:hover {
    .action-wrapper {
      visibility: visible;
    }
  }

  .inline-edit-wrapper {
    display: inline-block;
    padding-right: 30px;
    position: relative;
    width: auto;
  }

  .children-wrapper {
    display: inline-block;
    flex: none;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StyledAsyncSingleSelect = styled(AsyncSingleSelect)`
  display: 'block';
  width: 100%;
`;

const DefaultTooltip = styled(Tooltip)`
  display: block;
  padding-right: 5px;
`;

const NewTabLink = styled(Link)`
  color: #000000;
  padding: 0 3px;
`;

export const Styled = {
  ContentContainer,
  AddOnLinkContainer,
  StyledAsyncSingleSelect,
  DefaultTooltip,
  NewTabLink,
};
