import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { Dictionary } from 'lodash';
import * as typeSuffix from '../../constants';
import * as actions from '../actions';

const initialState: Dictionary<string> = {};

const regexString = `(.*)_(${typeSuffix.REQUEST}|${typeSuffix.FAILURE})`;
const actionTypeRegex = new RegExp(regexString);

const fetchReducer: CaseReducer<Dictionary<string>> = (draftState, action) => {
  const { type, payload } = action as PayloadAction<string>;
  const matches = actionTypeRegex.exec(type);

  // not a *_REQUEST / *_FAILURE actions, so we ignore them
  if (!matches) return;

  const [, requestName, requestState] = matches;

  // Store errorMessage
  // e.g. stores errorMessage when receiving GET_TODOS_FAILURE
  // else clear errorMessage when receiving GET_TODOS_REQUEST
  draftState[requestName] = requestState === typeSuffix.FAILURE ? payload : '';
};

const resetErrorReducer: CaseReducer<Dictionary<string>, PayloadAction<string>> = (
  draftState,
  action
) => {
  draftState[action.payload] = '';
};

const reducer = createReducer(initialState, builder =>
  builder.addCase(actions.resetError, resetErrorReducer).addDefaultCase(fetchReducer)
);

export default reducer;
