import { generateDynamicColumnKeys } from '@optx/utils/columns';

export const SEARCH_MINIMUM_CHAR_COUNT = 3;
export const FINANCIAL_YEARS = [new Date().getFullYear()];
export const YEARS_LABELS = {
  current: new Date().getFullYear().toString(),
  future: (new Date().getFullYear() + 1).toString(),
  'past 1 year': (new Date().getFullYear() - 1).toString(),
  'past 2 years': (new Date().getFullYear() - 2).toString(),
};
export const generateArrCurrentColumnKey = `${generateDynamicColumnKeys('arr', 'current')}`;
export const generateEbitdaCurrentColumnKey = `${generateDynamicColumnKeys('ebitda', 'current')}`;
export const generateGrowthCurrentColumnKey = `${generateDynamicColumnKeys('growth', 'current')}`;
