import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// models
import { ColumnKeys } from '../../models/grid';
import { CompanyColumnDisplay } from '@optx/models/table/Columns';
// constants
import {
  analystColumnDisplayInitialState,
  companyColumnDisplayInitialState,
  initialAnalystColumnOrder,
  initialCompanyColumnOrder,
} from '@optx/constants/table/columnDisplay/checklist-research-insights';
// redux
import { selectors, actions } from '@features/checklist-research-insights/state';
// hooks
import { useDisplayColumnModal } from '@optx/common/hooks/init';
// components
import TableColumnFilter from '@optx/components/common/table/TableColumnFilter';
import { TableColumnFilterContext } from '@optx/components/common/table/TableColumnFilterContext';
import { DrawerHeader } from '@optx/features/columns/DrawerHeader';
// style
import { Styled } from '@components/common/table/DisplayColumnModal.styled';

const DisplayColumnModal: React.FC = () => {
  const dispatch = useDispatch();
  const [open, toggle] = useDisplayColumnModal();

  const sortable = useSelector(selectors.search.getColumnLists);
  const columnData = useSelector(selectors.search.getColumnDisplay);
  const columnOrder = useSelector(selectors.search.getColumnOrder);
  const getDefaultTableView = useSelector(selectors.ui.defaultTableView);

  const [columnDisplay, setColumnDisplay] = useState(columnData);
  const [sortableColumns, setSortableColumns] = useState(sortable);

  const handleApply = () => {
    toggle();
    dispatch(
      actions.search.saveColumns(
        columnDisplay as CompanyColumnDisplay<any>,
        sortableColumns.map(column => column.id) as unknown as ColumnKeys[]
      )
    );
  };

  const handleClose = () => {
    toggle();
    setColumnDisplay(columnData);
    setSortableColumns(sortable);
  };

  return (
    <TableColumnFilterContext.Provider
      value={{
        columnDisplay,
        setColumnDisplay,
        sortableColumns,
        setSortableColumns,
      }}
    >
      <Styled.Drawer
        visible={open}
        onClose={handleClose}
        placement="right"
        closable
        width="485"
        title={<DrawerHeader onApply={handleApply} />}
        destroyOnClose
      >
        <div>
          <TableColumnFilter
            columnData={columnData}
            columnOrder={columnOrder}
            initialColumnOrder={
              getDefaultTableView === 'company'
                ? initialCompanyColumnOrder
                : initialAnalystColumnOrder
            }
            initialColumnState={
              getDefaultTableView === 'company'
                ? companyColumnDisplayInitialState
                : analystColumnDisplayInitialState
            }
          />
        </div>
      </Styled.Drawer>
    </TableColumnFilterContext.Provider>
  );
};

export default React.memo(DisplayColumnModal);
