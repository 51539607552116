import selectedCompanies from './selectedCompanies';
import editFields from './editingFields';
import equityTouch from './equityTouch';
import salesloft from './salesloft';
import mergeCompanies from './mergeCompanies';
import software from './software';
import aiMl from './aiMl';

export default {
  selectedCompanies,
  editFields,
  equityTouch,
  salesloft,
  mergeCompanies,
  software,
  aiMl,
};
