import styled from 'styled-components';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const UploadOutlinedIcon = styled(UploadOutlined)`
  font-size: 24px !important;
  color: #1890ff;
  background: #ffffff;
  border-radius: 50%;
  padding: 10px;
`;

const UploadText = styled.span`
  color: #1890ff;
`;

const DragAndDrop = styled(Dragger)`
  position: relative;
  --supported-file-types-position: -25px;

  margin-bottom: calc(var(--supported-file-types-position) * -1) !important;
  background: rgba(230, 247, 255, 0.5) !important;
  border-color: #bfbfbf !important;

  > .ant-upload {
    padding: 40px 0 !important;
  }

  &.ant-upload.ant-upload-drag.ant-upload-drag-hover,
  &:hover {
    border-color: #1890ff !important;
  }

  &.ant-upload.ant-upload-drag.ant-upload-disabled {
    background: #fafafa !important;
    border-color: #bfbfbf !important;

    ${UploadText}, ${UploadOutlinedIcon} {
      color: #bfbfbf;
    }
  }

  span.file-size {
    width: 100%;
    line-height: 1;
    text-align: left;
    font-size: 12px;
    color: #8c8c8c;
    cursor: default;
    pointer-events: none;
    position: absolute;
    left: 0;
    // The amount of space for the top line was added and 5px was added to subtract the space in between.
    bottom: calc(2 * var(--supported-file-types-position) + 5px);
  }
`;

const DragAndDropContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
`;

const SupportedFileTypes = styled.span`
  position: absolute;
  left: 0;
  bottom: var(--supported-file-types-position);
  width: 100%;
  line-height: 1;
  text-align: left;
  font-size: 12px;
  color: #8c8c8c;
  cursor: default;
  pointer-events: none;
`;

export const Styled = {
  DragAndDrop,
  DragAndDropContent,
  UploadOutlinedIcon,
  SupportedFileTypes,
  UploadText,
};
