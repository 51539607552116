import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Row, Typography } from 'antd';
// utils
import { getOriginPath } from '@optx/utils/routes';
// constants
import appRoutes from '@constants/routes';
// redux
import { selectors as salesloftSelectors } from '@redux/contacts/salesloft';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';
// local
import { SalesloftContext } from '../SalesloftContext';

interface HeaderProps {
  handleSubmit: () => void;
}

const Header: React.FC<HeaderProps> = ({ handleSubmit }) => {
  const { cadenceId } = useContext(SalesloftContext);
  const postData = useSelector(salesloftSelectors.getPostData);
  const history = useHistory();
  const location = useLocation();
  const { advancedSearchPathname } = useIsAnalyst();

  const onCancel = () => {
    if (
      getOriginPath(document.referrer) === appRoutes.chromeExtension ||
      getOriginPath(document.referrer) === location.pathname
    ) {
      history.push(advancedSearchPathname);
    } else {
      history.goBack();
    }
  };

  return (
    <Row style={{ marginBottom: '33px' }} className="add-to-salesloft__header">
      <Col span={16}>
        <Typography.Title level={4} className="add-to-salesloft__title">
          Add to Sales Loft Cadence
        </Typography.Title>
      </Col>
      <Col className="add-to-salesloft__header-buttons">
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          type="primary"
          onClick={handleSubmit}
          disabled={!postData.contactIds?.length || !cadenceId}
        >
          Add to Sales Loft Cadence
        </Button>
      </Col>
    </Row>
  );
};

export default Header;
