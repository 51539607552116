import { createSelector } from 'reselect';
import { NotificationDetails } from '@optx/models/notifications';
import { NotificationsState } from './interfaces';
import { featureName } from '../featureName';

const alertsState = (state: Record<typeof featureName, NotificationsState>) => state[featureName];

export const alertsInboxState = createSelector(alertsState, state => state.inbox);
export const alertsHistoryState = createSelector(alertsState, state => state.history);
export const alertsUpcomingTouchesState = createSelector(
  alertsState,
  state => state.touches.upcoming
);
export const alertsPastDueTouchesState = createSelector(
  alertsState,
  state => state.touches.pastDue
);

export const alertsNewList = createSelector(alertsState, state => {
  const alerts: Array<number> = [];

  if (state.inbox.data.length) {
    state.inbox.data.forEach((alert: NotificationDetails) => {
      if (!alert.viewed_at) {
        alerts.push(alert.id);
      }
    });
  }

  return alerts;
});

export const getRoadmapUrl = createSelector(alertsState, state => state.roadmapUrl);

export const getAlertsPageNumber = createSelector(alertsState, state => state?.inbox?.pageNr);
