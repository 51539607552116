import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { FETCH_COGNITO_LOGIN_URL } from './actionTypes';
import { fetchCognitoLoginURLFail, fetchCognitoLoginURLSuccess } from './actions';
// services
import NotificationService from '../../../services/NotificationService';
import { AuthService } from '../../../services/api';
import { CognitoButton } from './interfaces';

export const errorMessage = 'Cognito Login URL failure!';

export function* fetchCognitoLoginURLSaga() {
  try {
    const res: AxiosResponse<CognitoButton[]> = yield call(AuthService.getCognitoURL);

    if (res.data) {
      yield put(fetchCognitoLoginURLSuccess(res.data));
    } else {
      yield put(fetchCognitoLoginURLFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    yield put(fetchCognitoLoginURLFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* cognitoLogOutSaga() {
  yield takeLatest(FETCH_COGNITO_LOGIN_URL, fetchCognitoLoginURLSaga);
}
