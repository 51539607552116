import React, { useCallback, useState, useEffect, SetStateAction, Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
// constants
import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';
import {
  SOFTWARE_CARD_NAME,
  FIT_CARD_NAME,
  SCORE_CARD_NAME,
} from '@features/add-research-rationale/constants';
// redux
import { selectors as userSelectors } from '@redux/user/information';
import { selectors, actions } from '../state';
import { FieldType, AddResearchRationaleCompany } from '../state/interfaces';
// styles
import * as Styled from './AddResearchRationale.styles';

interface HeaderProps {
  rationale: FieldType;
  toggle: () => void;
  company: AddResearchRationaleCompany;
  setAccordionKey: Dispatch<SetStateAction<string>>;
}

const Header: React.FC<HeaderProps> = ({ toggle, rationale, company, setAccordionKey }) => {
  const dispatch = useDispatch();
  const defaultScore = useSelector(userSelectors.getDefaultScore);
  const [disabled, setDisabled] = useState<boolean>(true);
  const initialRationale = useSelector(selectors.getInitialRationale);
  const initialFieldsValues = useSelector(selectors.getInitialFields);
  const activeScoreRationaleReasonType = useSelector(selectors.getActiveRationaleReasonType);
  const fieldsValues = useSelector(selectors.getFields);
  const isChromePlugin = window.location.pathname.startsWith('/chrome-extension');

  const onSubmit = useCallback(() => {
    toggle();

    dispatch(actions.submitResearchRationale());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleCancel = () => {
    toggle();
  };

  // design requirement for initial field collapsed state: "When 2 or all , have thumbs down and
  // user opens the Rationale, first tab should be expanded and the other 2 collapsed".
  // this functionality was added here, because in AddResearchRationale mount doesn't work
  // properly with destroyOnClose prop from antd Drawer component, version 4.6.5
  useEffect(() => {
    const optxScoreVerified =
      defaultScore === 'us' ? company.optx_score_verified : company.il_optx_score_verified;

    if (company) {
      const cardData = [
        {
          name: SOFTWARE_CARD_NAME,
          value: company.is_software,
        },
        {
          name: FIT_CARD_NAME,
          value: company.is_interesting,
        },
        {
          name: SCORE_CARD_NAME,
          value: optxScoreVerified,
        },
      ];

      if (cardData.filter(card => card.value === false)?.length > 1) {
        const cardName = cardData.find(card => card.value === false)?.name;
        setAccordionKey(cardName || '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isSoftwareFieldChanged = fieldsValues.is_software !== initialFieldsValues.is_software;

    const isSoftwareRationaleChanged =
      rationale.is_software_rationales !== initialRationale.is_software_rationales;

    const isInterestingFieldChanged =
      fieldsValues.is_interesting !== initialFieldsValues.is_interesting;

    const isPSGFitRationaleChanged =
      rationale.psg_fit_rationales !== initialRationale.psg_fit_rationales;

    const isScoreFieldChanged =
      fieldsValues.optx_score_verified !== initialFieldsValues.optx_score_verified;

    const isScoreRationaleChanged =
      rationale.optx_score_rationales !== initialRationale.optx_score_rationales;

    const isScoreRationaleLowerReasonChanged =
      rationale.optx_score_lower_rationales !== initialRationale.optx_score_lower_rationales;

    const isScoreRationaleUpperReasonChanged =
      rationale.optx_score_upper_rationales !== initialRationale.optx_score_upper_rationales;

    const isStateChanged =
      isSoftwareFieldChanged ||
      isSoftwareRationaleChanged ||
      isInterestingFieldChanged ||
      isPSGFitRationaleChanged ||
      isScoreFieldChanged ||
      isScoreRationaleChanged ||
      isScoreRationaleLowerReasonChanged ||
      isScoreRationaleUpperReasonChanged;

    const oneFieldOpenedCondition =
      fieldsValues.is_software === false &&
      (!rationale.is_software_rationales ||
        rationale.is_software_rationales === DEFAULT_CELL_VALUE);

    const twoFieldsOpenedCondition =
      fieldsValues.is_software === true &&
      fieldsValues.is_interesting === false &&
      (!rationale.psg_fit_rationales || rationale.psg_fit_rationales === DEFAULT_CELL_VALUE);

    const threeFieldsOpenedCondition =
      fieldsValues.is_software === true &&
      fieldsValues.is_interesting === true &&
      fieldsValues.optx_score_verified === false &&
      (!rationale.optx_score_rationales || rationale.optx_score_rationales === DEFAULT_CELL_VALUE);

    const threeFieldsOpenedExpandedCondition =
      fieldsValues.is_software === true &&
      fieldsValues.is_interesting === true &&
      fieldsValues.optx_score_verified === false &&
      rationale.optx_score_rationales &&
      rationale.optx_score_rationales !== DEFAULT_CELL_VALUE &&
      activeScoreRationaleReasonType !== null &&
      (!rationale.optx_score_lower_rationales ||
        rationale.optx_score_lower_rationales === DEFAULT_CELL_VALUE) &&
      (!rationale.optx_score_upper_rationales ||
        rationale.optx_score_upper_rationales === DEFAULT_CELL_VALUE);

    if (isStateChanged && fieldsValues.is_software !== null) {
      if (oneFieldOpenedCondition) {
        setDisabled(true);
      } else if (twoFieldsOpenedCondition) {
        setDisabled(true);
      } else if (threeFieldsOpenedCondition) {
        setDisabled(true);
      } else if (threeFieldsOpenedExpandedCondition) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else {
      setDisabled(true);
    }
  }, [
    rationale,
    initialRationale,
    fieldsValues,
    initialFieldsValues,
    activeScoreRationaleReasonType,
  ]);

  return (
    <Styled.TitleContainer isChromePlugin={isChromePlugin}>
      <h2>Key Info Checklist</h2>
      <Styled.ButtonsContainer isChromePlugin={isChromePlugin}>
        <Button className="btn-cancel" onClick={handleCancel}>
          CANCEL
        </Button>
        <Button type="primary" className="btn-send" onClick={onSubmit} disabled={disabled}>
          SAVE
        </Button>
      </Styled.ButtonsContainer>
    </Styled.TitleContainer>
  );
};

export default Header;
