import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import styled, { css } from 'styled-components';

export const SearchViewButton = styled<React.FC<ButtonProps & { $active?: boolean }>>(Button)`
  ${props =>
    css`
      color: ${props.color ? 'white' : 'rgb(178,189,207)'};
    `}
  svg {
    font-size: 20px;
  }

  ${props =>
    props.$active &&
    css`
      color: #1890ff;
    `}
`;
