import { useState } from 'react';
import { Button } from 'antd';
// components
import Icon from '../Icon';
import * as Styles from './FeedbackMechanism.styles';

interface FeedbackMechanismProps {
  value: boolean | null;
  isGenerated: boolean;
  handleThumbUp: () => void;
  handleThumbDown: () => void;
  handleRegenerate?: () => void;
  setIsThumbsDown?: (state: boolean) => void;
}

const FeedbackMechanism: React.FC<FeedbackMechanismProps> = ({
  value,
  isGenerated,
  handleThumbUp,
  handleThumbDown,
  handleRegenerate,
  setIsThumbsDown,
}) => {
  const [defaultValue, setDefaultValue] = useState<boolean | null>(value || null);

  const handleThumbsUp = () => {
    setDefaultValue(true);
    !defaultValue && handleThumbUp && handleThumbUp();
  };

  const handleThumbsDown = () => {
    handleThumbDown && handleThumbDown();
    setIsThumbsDown && setIsThumbsDown(true);
    setDefaultValue(false);

    setTimeout(() => {
      setDefaultValue(null);
      setIsThumbsDown && setIsThumbsDown(false);
    }, 2000);
  };

  const onRegenerateClick = () => {
    setDefaultValue(null);
    handleRegenerate && handleRegenerate();
  };

  return (
    <Styles.FeedbackMechanismWrapper>
      {isGenerated && (
        <>
          <Button
            className="thumbs-up"
            type="link"
            disabled={defaultValue === true}
            icon={
              <Icon
                iconName="thumbs-up"
                style={{ fontSize: 20 }}
                className={defaultValue ? 'active' : ''}
              />
            }
            onClick={handleThumbsUp}
          />
          <Button
            className="thumbs-down"
            type="link"
            icon={
              <Icon
                iconName="thumbs-down"
                style={{ fontSize: 20 }}
                className={defaultValue === false ? 'active' : ''}
              />
            }
            onClick={handleThumbsDown}
          />
        </>
      )}
      {handleRegenerate && (
        <Button
          className="reload"
          type="link"
          icon={<Icon iconName="reload" style={{ fontSize: 16 }} />}
          onClick={onRegenerateClick}
        />
      )}
    </Styles.FeedbackMechanismWrapper>
  );
};

export default FeedbackMechanism;
