import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ValueType } from 'react-select';
// models
import { FAVORITE_LIST_SELECT_OPTIONS } from '@constants/lists';
import { SelectOption } from '@optx/models/Option';
// redux
import {
  selectors as watchListsSelectors,
  actions as watchListsActions,
} from '@redux/favorite-lists/lists-popup';
// components
import { SingleSelect } from '@shared/view/molecules/Select';
import SortWrapperStyled from './SortSelectLists.styles';

const SortSelectLists = () => {
  const dispatch = useDispatch();
  const sortActive = useSelector(watchListsSelectors.getSortSelect);
  const defaultValue = [
    {
      value: sortActive.value,
      label: sortActive.label,
    },
  ];

  const handleSorting = useCallback(
    (selectedValue: ValueType<SelectOption>) => {
      dispatch(
        watchListsActions.sortFavoriteListsPopup({
          sorting: (selectedValue as SelectOption).value,
        })
      );
    },
    [dispatch]
  );

  return (
    <SortWrapperStyled className="watch-list__sort">
      <SingleSelect
        className="watch-list__sorting"
        dropdownClassName="watch-list__sorting-dropdown"
        bordered={false}
        valuePrefix="Sort by:"
        value={defaultValue}
        SearchIcon={false}
        options={FAVORITE_LIST_SELECT_OPTIONS}
        onChange={handleSorting}
      />
    </SortWrapperStyled>
  );
};

export default SortSelectLists;
