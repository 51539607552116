import React from 'react';
import classNames from 'classnames';
// styles
import { Styled } from './OwnerAvatar.styled';

interface OwnerAvatarProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  ownerId: number | null;
  name: string | null;
  title: string;
  ownerImage?: string;
  onClick?: () => void;
}

const OwnerAvatar: React.FC<OwnerAvatarProps> = ({
  ownerId,
  name,
  title,
  ownerImage,
  className,
  onClick,
  ...restProps
}) => {
  if (!ownerId) {
    return null;
  }

  const finalClassName = classNames('media-object user-avatar', className);

  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <Styled.UserAvatar>
      <div className={finalClassName} {...restProps}>
        <div className="media-object-section">
          <div
            role="presentation"
            className={
              onClick ? 'user-avatar__name user-avatar__name--clickable' : 'user-avatar__name'
            }
            onClick={handleClick}
          >
            {name}
          </div>
          <div className="user-avatar__title">{title}</div>
        </div>
        <div className="media-object-section">
          <div role="presentation" className="user-avatar__image">
            <img src={ownerImage} alt="avatar" />
          </div>
        </div>
      </div>
    </Styled.UserAvatar>
  );
};

export default React.memo(OwnerAvatar);
