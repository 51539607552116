import React from 'react';
import { BulkEditFieldContext } from '@optx/models/bulkActions';

export const UserListContext = React.createContext<BulkEditFieldContext>({
  selectedData: [],
  setSelectedData: () => {},
  gridName: 'watchlists',
  hasSelectViewChanged: false,
  setHasSelectViewChanged: () => {},
  rationaleValue: undefined,
  setRationaleValue: () => {},
});
