export const FETCH_PRIMARY_CONTACTS = 'FETCH_PRIMARY_CONTACTS';
export const FETCH_PRIMARY_CONTACTS_SUCCESS = 'FETCH_PRIMARY_CONTACTS_SUCCESS';
export const FETCH_PRIMARY_CONTACTS_FAIL = 'FETCH_PRIMARY_CONTACTS_FAIL';

export const UPDATE_PRIMARY_CONTACT = 'UPDATE_PRIMARY_CONTACT';

export const EDITING_PRIMARY_CONTACT = 'EDITING_PRIMARY_CONTACT';
export const CANCEL_EDITING_PRIMARY_CONTACT = 'CANCEL_EDITING_PRIMARY_CONTACT';

export const EDIT_PRIMARY_CONTACT = 'EDIT_PRIMARY_CONTACT';
export const EDIT_PRIMARY_CONTACT_SUCCESS = 'EDIT_PRIMARY_CONTACT_SUCCESS';
export const EDIT_PRIMARY_CONTACT_FAIL = 'EDIT_PRIMARY_CONTACT_FAIL';

export const CLEAR_PRIMARY_CONTACTS = 'CLEAR_PRIMARY_CONTACTS';
