import { createAction } from '@reduxjs/toolkit';
import { CompanyUserContact } from '@models/api/contacts';
import { FilterTag } from '@optx/models/tags';
import { SearchContactsPayload, ResetTo } from './interfaces';
import * as types from './actionTypes';

// search
export const searchContacts = createAction<Partial<SearchContactsPayload> | undefined>(
  types.SEARCH_CONTACTS
);

// reset search
const prepareSearchContactsSuccess = (data: Array<CompanyUserContact>, searchTitle?: string) => ({
  payload: {
    data,
    searchTitle,
  },
});

export const searchContactsSuccess = createAction(
  types.SEARCH_CONTACTS_SUCCESS,
  prepareSearchContactsSuccess
);

export const searchContactsFail = createAction<string>(types.SEARCH_CONTACTS_FAIL);

// reset search
const prepareReset = (clearFilter: boolean, resetTo?: ResetTo, searchTitle?: string) => ({
  payload: {
    clearFilter,
    resetTo,
    searchTitle,
  },
});

export const removeFilter = createAction<FilterTag>(types.REMOVE_FILTER);

export const reset = createAction(types.RESET_SEARCH_CONTACTS, prepareReset);
