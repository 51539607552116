import styled from 'styled-components';

const ContentContainer = styled.div`
  .ant-row {
    padding: 0 !important;
  }

  .buttons-container {
    margin: 0 0 0 -16px;
    width: calc(100% + 32px);
    padding-top: 10px;
    border-top: 1px solid #d9d9d9;
    text-align: right;
  }

  .ant-input {
    margin-bottom: 20px;
  }

  .cancel {
    margin-right: 8px;
  }

  .save {
    margin-right: 16px;
  }
`;

export const Styled = {
  ContentContainer,
};
