import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import createCachedSelector from 're-reselect';
// models
import { AppState } from '@optx/redux/interfaces';
import { localSelectors as localHistogramSelectors } from '@features/histograms/base-histograms';
// redux
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import { getSearchKey as searchKey } from '@optx/features/grid/searchkey/state/selectors';
import {
  getSearchCountLoading,
  getSearchCount as searchCount,
} from '@optx/features/grid/search-count/state/selectors';
import { getTotalCount } from '@features/grid/search/state/selectorsReusable';

export const selectHomeState = (state: AppState) => state.homePage;

export const isLoadingSearchCount = getSearchCountLoading('advancedSearch');

export const getSorting = createSelector(selectHomeState, state => state.sort.sortBy);
export const getSortingLoading = createSelector(selectHomeState, state => state.sort.loading);

export const isMultiSort = createSelector(selectHomeState, state => state.sort.multiSort);

export const isSimilarCompaniesSorting = createSelector(
  selectHomeState,
  state => state.sort.isSimilarCompaniesSorting
);

export const getClearedFilterValue = createCachedSelector(
  filterSelectors.getClearedFilter('advancedSearch'),
  (state: AppState, column: string) => column,
  (state, column) => {
    return state[column];
  }
)((state, column) => column);

export const getSearchKey = searchKey('advancedSearch');

/**
 * Show if current search has seach term or a filter set.
 */
export const searchHasValues = createSelector(
  getSearchKey,
  filterSelectors.getFilter('advancedSearch'),
  (searchKey, filter) => !!searchKey || !isEmpty(filter)
);

// Histogram filters.
const selectHistogramsState = (state: AppState) => state.homePage.histogramFilters;

export const selectEditHistograms = createSelector(
  selectHistogramsState,
  localHistogramSelectors.selectEditHistograms
);

export const selectHistograms = createSelector(
  selectHistogramsState,
  localHistogramSelectors.histograms
);

export const selectAllFiltersWithHistograms = createSelector(
  selectHistogramsState,
  localHistogramSelectors.allFiltersWithHistograms
);

export const getHistogramSearchCount = createSelector(
  selectHistogramsState,
  localHistogramSelectors.searchCount
);

export const selectChangedFilters = createSelector(
  selectHistogramsState,
  localHistogramSelectors.changedFilters
);

export const loadingHistograms = createSelector(selectHistogramsState, state => state.loading);

export const getSearchCount = searchCount('advancedSearch');

/**
 * Search count for filters modal. Usually if histograms are enabled the call will update this count.
 * When the search is applied the count will be the one in search.
 */
export const getEditModeSearchCount = createSelector(
  getTotalCount('advancedSearch'),
  getHistogramSearchCount,
  (searchCount, histogramSearchCount) =>
    typeof histogramSearchCount === 'number' ? histogramSearchCount : searchCount
);

export const histogramsInitialized = createSelector(
  selectHistogramsState,
  localHistogramSelectors.isInitialized
);

export const selectHistogramRequestFilters = createSelector(
  selectHistogramsState,
  localHistogramSelectors.histogramRequestFilters
);

export const selectDefaultHistograms = createSelector(
  selectHistogramsState,
  localHistogramSelectors.defaultHistograms
);

export const histogramsEnabled = createSelector(
  selectHistogramsState,
  localHistogramSelectors.isEnabled
);

export const histogramsAreEmpty = createSelector(
  selectHistogramsState,
  localHistogramSelectors.histogramsAreEmpty
);
