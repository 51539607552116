import DropdownAdd from '@optx/components/DropdownAdd';
import { Col, Button } from 'antd';
import styled, { css } from 'styled-components';

const CompanyNameWrapper = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  .location-text {
    color: #90a0b7;
    font-weight: bold;
    line-height: 1.1428;
    display: block;
    min-width: 255px;
    svg {
      margin-right: 3.5px;
    }
    p {
      margin: 0;
    }
  }
`;

const CompanyName = styled.div`
  font-size: 20px;
  line-height: 1.5;
  p {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    color: #334d6e;
    position: relative;
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    overflow: hidden;
    padding-right: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Icons = styled(Col)`
  display: flex;
  max-width: 100%;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid #f0f0f0;
  svg {
    font-size: 22px;
  }
`;

const WebsiteIcon = styled.a`
  margin-right: 20px;
`;

const StyledDropDown = styled(DropdownAdd)`
  svg {
    color: rgb(175, 189, 209);
    font-size: 30px;
  }
`;

const Wrapper = styled(Col)`
  display: flex;
  margin: 0;
  align-items: flex-start;
  padding: 0 !important;

  .image-wrapper {
    margin: 0 !important;
  }
`;

const ArrowButton = styled(Button)`
  color: rgb(24, 144, 255);
  &:hover {
    color: rgb(24, 144, 255);
  }

  ${props =>
    props.disabled &&
    css`
      .ant-icon {
        color: 'rgb(175, 189, 209)';
      }
    `}
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const OwnerWrapper = styled(Col)`
  margin-top: 38px;
  max-width: 100%;
  margin-left: -8px;
  margin-right: -8px;
  .extension-header-owner {
    margin: 0;
  }
`;

const SocialMediaWrapper = styled.div`
   {
    position: relative;
    width: 100%;
    margin-top: -5px;
    ul {
      right: 0px;
      margin-bottom: 10px;
      top: -2px;
      position: absolute;
      padding-right: 0px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: no-wrap;
      list-style: none;

      @media (max-width: 1024px) {
        padding-right: 10px;
      }

      li {
        padding: 2px 0 2px 10px;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      li:has(a > svg.icon-bvdorbis) {
        padding-top: 0;
        padding-bottom: 0;
      }

      svg {
        color: #0050b3;
      }

      svg:not(.icon-bvdorbis) {
        width: 20px;
        height: 20px;

        path,
        rect {
          fill: inherit;
          color: #0050b3;
          fill-opacity: 1;
        }
      }

      svg.icon-bvdorbis {
        width: 22px;
        height: 26px;
      }
    }
  }
`;

const HeaderRight = styled(Col)`
  display: flex;
  margin: 0;
  flex-direction: column;
  align-items: flex-end;
  max-width: 41%;
`;
const CompanyHeaderWrapper = styled.div`
  padding: 20px;
  .ant-col-4 {
    max-width: 18%;
  }
`;

export const CardHeaderStyles = {
  CompanyNameWrapper,
  CompanyName,
  Icons,
  WebsiteIcon,
  Wrapper,
  ArrowButton,
  ButtonWrapper,
  StyledDropDown,
  SocialMediaWrapper,
  HeaderRight,
  OwnerWrapper,
  CompanyHeaderWrapper,
};

export default CardHeaderStyles;
