import { AxiosResponse } from 'axios';
import { Dictionary } from 'lodash';
// models
import { SourcingOutreachColumnNamesKey } from '@optx/features/sourcing-outreach-summary';
// constants
import columns from '../features/sourcing-outreach-summary/components/SourcingOutreachTable/columns';
import { LIST_OF_COLUMN_NAMES } from '@optx/constants/sourcingOutReachColumnNames';
// services
import { AnalystService } from '../services/api';
import NotificationService from '../services/NotificationService';

interface ExportSourcingOutreachColumns {
  columns: Dictionary<string>;
}

export const exportFileCSV = (fileName: string, query: string) => {
  const gridHeaders: ExportSourcingOutreachColumns = { columns: {} };

  columns.forEach(column => {
    let columnLabel = '';

    if (typeof column.title === 'string') {
      columnLabel = column.title;
    } else {
      columnLabel = LIST_OF_COLUMN_NAMES[column.dataIndex as SourcingOutreachColumnNamesKey];
    }

    gridHeaders.columns[column.dataIndex as string] = columnLabel;
  });

  return AnalystService.exportSourcingOutreachToCSV(query, gridHeaders)
    .then((response: AxiosResponse<string>) => {
      const element = document.createElement('a');

      element.setAttribute(
        'href',
        `data:application/csv;charset=utf-8, ${encodeURIComponent(response.data)}`
      );
      element.setAttribute('download', `${fileName}.csv`);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      NotificationService.success('Export CSV file success!');
    })
    .catch(() => {
      NotificationService.error('Export CSV file error!');
    });
};
