import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { Dictionary } from 'lodash';
// models
import { PayloadAction } from '@reduxjs/toolkit';
// services
import { getErrorMessage } from '@utils/api/errors';
import { CompanyService } from '@services/api';
import NotificationService from '@services/NotificationService';
// redux
import * as actions from './actions';
import { FetchBulkEditData } from './interfaces';

function* fetchBulkEditDataSuccessSaga(action: PayloadAction<FetchBulkEditData>) {
  const { companyIds, gridName, field } = action.payload;
  const defaultErrorMessage = 'Failed to fetch bulk edit data!';

  try {
    const res: AxiosResponse<
      Dictionary<{
        fund: string;
        rationale: string;
      }>
    > = yield call(CompanyService.getBulkEditData, companyIds);

    if (res.data) {
      yield put(actions.fetchBulkEditDataSuccess({ gridName, dataFields: res.data, field }));
    } else {
      NotificationService.error(defaultErrorMessage);
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, defaultErrorMessage);
    NotificationService.error(errorMessage);
  }
}

export default function* selectedCompaniesSaga() {
  yield takeLatest(actions.fetchBulkEditData, fetchBulkEditDataSuccessSaga);
}
