import React from 'react';
// models
import {
  Filter,
  FilterSource,
  MultiCheckboxFilter,
  MultiSelectFilter,
  RangeFilter,
  FilterGroupDataSection,
  BaseFilter,
} from '@optx/models/filters';
import Option from '@optx/models/Option';
// constants
import { FILTERS_WITH_CHECKBOX_AS_RADIO } from '@optx/constants/filters';
// components
import FilterMultiSelect from '../FilterMultiSelect';
import FilterCheckbox from '../FilterCheckbox';
import FilterCheckboxAsRadio from '../FilterCheckboxAsRadio';
import FilterDateRange from '../FilterDateRange';
import FilterRadio from '../FilterRadio';

interface FilterGroupCustomSectionProps {
  section: FilterGroupDataSection;
  source: FilterSource<Filter<any>>;
}

const FilterGroupCustomSection: React.FC<FilterGroupCustomSectionProps> = ({ section, source }) => {
  switch (section.type) {
    case 'multi_select':
      return <FilterMultiSelect filter={section as MultiSelectFilter<Option[]>} source={source} />;

    case 'checkbox':
      if (FILTERS_WITH_CHECKBOX_AS_RADIO.includes(section.column)) {
        return <FilterCheckboxAsRadio filter={section as MultiCheckboxFilter} isFullRow />;
      } else {
        return <FilterCheckbox filter={section as MultiCheckboxFilter} isFullRow />;
      }

    case 'radio':
      return <FilterRadio filter={section as BaseFilter<any>} />;

    case 'date_range':
      return <FilterDateRange filter={section as RangeFilter<null>} />;

    default:
      return null;
  }
};

export default React.memo(FilterGroupCustomSection);
