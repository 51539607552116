import { AppState } from '@optx/redux/interfaces';
import { createSelector } from 'reselect';

const getCompanyEditAllState = (state: AppState) => state.companyEditAllForm;

export const shouldInitialFetch = createSelector(
  getCompanyEditAllState,
  state => state.shouldInitialFetch
);

export const isOpen = createSelector(getCompanyEditAllState, state => state.open);

export const getEditAllCompany = createSelector(getCompanyEditAllState, state => state.company);

export const getEditAllFields = createSelector(getCompanyEditAllState, state => state.allFields);
export const getEditPropInfo = createSelector(getCompanyEditAllState, state => state.propInfo);
export const getEditEqtView = createSelector(getCompanyEditAllState, state => state.eqtView);
export const getEditGridOrder = createSelector(getCompanyEditAllState, state => state.gridOrder);
export const getEditIdInfo = createSelector(getCompanyEditAllState, state => state.idInfo);

export const getEditAllTabs = createSelector(getCompanyEditAllState, state => {
  return [
    ...state.idInfo,
    ...state.propInfo,
    ...state.eqtView,
    ...state.gridOrder,
    ...state.allFields,
  ];
});

export const isFinancialsLoaded = createSelector(
  getCompanyEditAllState,
  state => state.isFinancialsLoaded
);
export const getInitialEditAllState = createSelector(
  getCompanyEditAllState,
  state => state.initialState
);

export const getDisableSaveBtnFor = createSelector(
  getCompanyEditAllState,
  state => state.disableSaveBtnFor
);

export const getFinancialYearsChangeLog = createSelector(
  getCompanyEditAllState,
  state => state.financialYearsChangeLog
);
