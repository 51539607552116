import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
// models
import Option from '@optx/models/Option';
import { AppState } from '@optx/redux/interfaces';
import FilterCountCard from '@optx/models/cards/FilterCountCard';
import { CriteriaKeys } from '@optx/models/feature/analystsLeaderboard/CardCriteria';
// constants
import { cardRowGutter } from '@optx/constants/spacing';
// components
import OptionValue from '@components/common/Text/OptionValue';
// Local
// constants
import cardInfoDictionary from '../../constants/cardInfo';
// redux
import { selectors, actions } from '../../state';
// components
import Styles from './FilterCards.styles';
import Info from './Info';

const getValue = (value?: number) => {
  return typeof value === 'number' ? value : null;
};

/**
 * Map past due option.
 * @param option
 * @param index
 */
const mapPastDueCardOption = (
  option: Option<React.ReactNode>,
  index: number
): Option<React.ReactNode> => {
  if (index === 1) {
    return {
      ...option,
      value: <Styles.PastDue $value={option.value as number}>{option.value}</Styles.PastDue>,
    };
  }

  return option;
};

/**
 * Custom mapping to show red value when value is positive in past due.
 * @param card
 */
const mapCardOption = (card: FilterCountCard) => {
  if (card.key === 'touches') {
    return {
      ...card,
      options: card.options ? card.options.map(mapPastDueCardOption) : [],
    };
  }

  return card;
};

const FilterCards = () => {
  const dispatch = useDispatch();
  const cards = useSelector(selectors.getCards);
  const selectedCard = useSelector((state: AppState) =>
    selectors.filters.getRadioValue(state, 'filter_criteria')
  );

  const mappedCards = useMemo(() => cards.map(mapCardOption), [cards]);

  const handleCardClick = (cardKey: string) => {
    if (selectedCard !== cardKey) {
      dispatch(actions.filters.updateValue({ value: cardKey, filterKey: 'filter_criteria' }));
    }
  };

  const renderInfo = (cardKey: CriteriaKeys) => {
    const info = cardInfoDictionary[cardKey];

    if (info) {
      return <Info info={info} />;
    }

    return null;
  };

  return (
    <Row gutter={cardRowGutter}>
      {mappedCards.map((card, index) => (
        <Col key={index} xs={24} md={8}>
          <Styles.Card
            title={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Styles.CardTitle
                active={selectedCard === card.key}
                title={card.title}
                value={getValue(card.count)}
                trending={getValue(card.trending)}
              />
            }
            hoverable
            onClick={() => handleCardClick(card.key)}
            $size="small"
            $active={selectedCard === card.key}
            $transparent
          >
            <Styles.CardOptionsWrapper>
              {card.options?.map((option, index) => (
                <OptionValue key={index} option={option} />
              ))}
            </Styles.CardOptionsWrapper>
            {renderInfo(card.key as CriteriaKeys)}
          </Styles.Card>
        </Col>
      ))}
    </Row>
  );
};

export default FilterCards;
