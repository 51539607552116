import React from 'react';
import classNames from 'classnames';
import Styled from './OwnerInfo.styled';

interface OwnerInfoProps {
  name: string;
  email: string;
  className?: string;
  ownerImage?: string;
}

const OwnerInfo: React.FC<OwnerInfoProps> = ({ name, email, className, ownerImage }) => {
  const finalClassName = classNames('media-object ', className);

  return (
    <Styled.Wrapper className={finalClassName}>
      <div className="media-object-section">
        <Styled.UserAvatar>
          <img src={ownerImage} alt="avatar" />
        </Styled.UserAvatar>
      </div>
      <div className="media-object-section">
        <Styled.Name>{name}</Styled.Name>
        <address>
          <Styled.Email href={`mailto:${email}`}>{email}</Styled.Email>
        </address>
      </div>
    </Styled.Wrapper>
  );
};

export default OwnerInfo;
