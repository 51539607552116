import React, { useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'antd';
//redux
import { actions, selectors } from '@redux/company/opportunity-presentation';
//components
import {
  TechnologyWrapper,
  TechnologyLabel,
  TechnologyValues,
} from '../styles/ProprietaryInfoCards.styled';
import TextareaField from '@optx/features/field-factory/TextareaField';
import { CompanyProfile } from '@optx/models/Company';
import MultiSelectField from '@optx/features/field-factory/MultiSelectField';
import SingleSelectField from '@optx/features/field-factory/SingleSelectField';

interface TechnologyProps {
  company: CompanyProfile;
  isChromeExtension: boolean;
}

const Technology: React.FC<TechnologyProps> = ({ company, isChromeExtension }) => {
  const dispatch = useDispatch();
  const technologyInfo = useSelector(selectors.getTechnologyData);
  let fieldName: string | undefined = undefined;

  const formRef = useRef<FormikProps<any> | null>(null);

  const handleUpdateField = (fieldType?: string) => {
    fieldName = fieldType;

    if (formRef?.current) {
      formRef.current.submitForm();
    }
  };

  const handleSubmit = () => {
    const payload = {
      company_id: company.company_id,
      technology: {
        [fieldName as string]: formRef.current?.values[fieldName as string],
      },
    };
    dispatch(actions.updateOpportunityPresentation(payload));
    formRef.current?.resetForm();
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={handleSubmit}
      innerRef={instance => {
        formRef.current = instance;
      }}
    >
      <TechnologyWrapper isChromeExtension={isChromeExtension}>
        <Row>
          <TechnologyLabel>Delivery Model</TechnologyLabel>
          <TechnologyValues isChromeExtension={isChromeExtension}>
            <MultiSelectField
              onSave={handleUpdateField}
              options={technologyInfo?.delivery_model.data}
              value={(technologyInfo?.delivery_model.value as string[]) || []}
              fieldName="Delivery Model"
              fieldType="delivery_model"
            />
          </TechnologyValues>
        </Row>
        <Row>
          <TechnologyLabel>Architecture</TechnologyLabel>
          <TechnologyValues isChromeExtension={isChromeExtension}>
            <MultiSelectField
              fieldName="Architecture"
              options={technologyInfo?.architecture.data}
              value={(technologyInfo?.architecture.value as string[]) || []}
              fieldType="architecture"
              onSave={handleUpdateField}
            />
          </TechnologyValues>
        </Row>
        <Row>
          <TechnologyLabel>Cloud Provider</TechnologyLabel>
          <TechnologyValues isChromeExtension={isChromeExtension}>
            <SingleSelectField
              fieldName="Cloud Provider"
              options={technologyInfo?.cloud_provider.data}
              value={(technologyInfo?.cloud_provider.value as string) || ''}
              fieldType="cloud_provider"
              onSave={handleUpdateField}
            />
          </TechnologyValues>
        </Row>
        <Row>
          <TechnologyLabel>Tech Note</TechnologyLabel>
          <TechnologyValues isChromeExtension={isChromeExtension} className="tech-note">
            <TextareaField
              fieldName="Tech Note"
              fieldType="technology_notes"
              characterLimit={500}
              value={technologyInfo?.technology_notes}
              onSave={handleUpdateField}
            />
          </TechnologyValues>
        </Row>
      </TechnologyWrapper>
    </Formik>
  );
};

export default Technology;
