/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
// models
import { Cell } from '@models/table/Cell';
// redux
import {
  actions as importActions,
  selectors as importSelectors,
} from '@features/list-import/state';
import { CompanyImportFailedData } from '@optx/models/Company';

const Text = styled.span`
  font-weight: 600;
  color: #1890ff;
`;

const TableCellSimilarCompanies: React.FC<Cell<CompanyImportFailedData>> = ({
  record,
  index,
  value,
}) => {
  const dispatch = useDispatch();
  const isCompleted = useSelector(importSelectors.isCompleted);
  const isEditing = useSelector(importSelectors.getIsEditing);

  const handleClick = () => {
    if (!isCompleted || isEditing || !value || value === 0) return;

    index !== undefined && dispatch(importActions.editingCompanyIndex(index));
    dispatch(importActions.setSimilarCompanies(record.possible_matches));
    dispatch(importActions.showSimilarCompanies(true));
  };

  return <Text onClick={handleClick}>{value === undefined ? 0 : value}</Text>;
};

export default TableCellSimilarCompanies;
