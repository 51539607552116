import React from 'react';
import { Spin } from 'antd';
import { SpinProps } from 'antd/es/spin';
import { LoadingOutlined } from '@ant-design/icons';
import { Styled } from './Loader.styles';

interface OverlayLoaderProps extends SpinProps {
  loading?: boolean;
  isOverlay?: boolean;
}
const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const OverlayLoader: React.FC<OverlayLoaderProps> = ({ loading, isOverlay, ...rest }) => {
  if (loading) {
    return !isOverlay ? (
      <Styled.Overlay>
        <Spin {...rest} indicator={loadingIcon} />
      </Styled.Overlay>
    ) : (
      <Spin {...rest} indicator={loadingIcon} />
    );
  }

  return null;
};

OverlayLoader.defaultProps = {
  loading: false,
};

export default OverlayLoader;
