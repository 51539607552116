import React from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart as RechartsLineChart,
  LineChartProps as RechartsLineChartProps,
  LineProps,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
// utils
import { getPassThrough, PassThroughProps } from '@utils/componentFactory';
// props configuration
import {
  xAxisProps,
  yAxisProps,
  lineProps as defaultLineProps,
  legendProps,
  cartesianGridProps,
} from './config';
// components
import LegendFormatter from './LegendFormatter';
import EmptyChart from '../EmptyChart/EmptyChart';

interface LineChartFactoryProps {
  XAxisNode?: typeof XAxis | (() => JSX.Element);
  YAxisNode?: typeof YAxis | (() => JSX.Element);
  TooltipNode?: typeof Tooltip | (() => JSX.Element);
  LegendNode?: typeof Legend | (() => JSX.Element);
  LineNode?: typeof Line | (() => JSX.Element);
  CartesianGridNode?: typeof CartesianGrid | (() => JSX.Element);
  EmptyChartNode?: typeof EmptyChart | (() => JSX.Element);
}

interface LineChartProps extends RechartsLineChartProps {
  lines: Array<LineProps>;
}

export type PassThroughPrimitives = Record<keyof LineChartFactoryProps, string>;

/**
 * Main primitive names that compose LineChart component.
 */
export const primitives: PassThroughPrimitives = {
  XAxisNode: 'XAxisNode',
  YAxisNode: 'YAxisNode',
  TooltipNode: 'TooltipNode',
  LegendNode: 'LegendNode',
  LineNode: 'LineNode',
  CartesianGridNode: 'CartesianGridNode',
  EmptyChartNode: 'EmptyChartNode',
};

export const lineChartFactory = ({
  XAxisNode = XAxis,
  YAxisNode = YAxis,
  TooltipNode = Tooltip,
  LegendNode = Legend,
  LineNode = Line,
  CartesianGridNode = CartesianGrid,
  EmptyChartNode = EmptyChart,
  passThrow,
}: LineChartFactoryProps & PassThroughProps<any, keyof LineChartFactoryProps> = {}) => {
  const passProps = getPassThrough(passThrow);

  const LineChart = (props: LineChartProps) => {
    const { data, lines, ...restProps } = props;

    if (!data || !data.length) {
      return <EmptyChartNode />;
    }

    return (
      <RechartsLineChart data={data} margin={{ bottom: 20 }} {...restProps}>
        <CartesianGridNode {...cartesianGridProps} {...passProps(props, 'CartesianGridNode')} />
        <XAxisNode {...xAxisProps} {...passProps(props, 'XAxisNode')} />
        <YAxisNode {...yAxisProps} {...passProps(props, 'YAxisNode')} />
        <TooltipNode {...passProps(props, 'TooltipNode')} />
        {data && data.length && (
          <LegendNode
            formatter={LegendFormatter}
            {...legendProps}
            {...passProps(props, 'LegendNode')}
          />
        )}
        {lines.map(lineProps => (
          <LineNode
            key={lineProps.dataKey as string}
            {...defaultLineProps}
            {...lineProps}
            {...passProps(props, 'LineNode')}
          />
        ))}
      </RechartsLineChart>
    );
  };

  return LineChart;
};

export default lineChartFactory();
