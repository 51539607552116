import React from 'react';
import { Card, Col, Typography } from 'antd';
import { CardProps } from 'antd/lib/card';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const { Text } = Typography;

const CompanyCard = styled<React.FC<CardProps & { $active?: boolean }>>(Card)`
  margin-bottom: 30px;
  border-top: 3px solid transparent;
  &:hover {
    border-top: 3px solid #0092ff;
  }
  ${props =>
    props.$active &&
    css`
      border-top: 3px solid #0092ff;
      box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),
        0 5px 12px 4px rgb(0 0 0 / 9%);
    `}
`;

const LogoColumn = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  box-shadow: inset 0px 0px 0px #d9d9d9, -1px 0px 6px #d9d9d9;
  border-radius: 20px;
  margin-right: 12px;
  padding: 5px;
  height: 85px;
  min-width: 85px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    padding: 10px;
  }
`;

const ScoreTrendingWrapper = styled.div`
  margin-top: 20px;
  .score-trending__label {
    font-size: 12px !important;
  }
`;

const CompanyNameWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const CompanyName = styled.p`
  display: flex;
  align-items: baseline;
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  color: #334d6e;
`;

const CompanyProfileLink = styled(Link)`
  margin-left: 5px;
  color: #334d6e;
  font-size: 18px;
  position: absolute;
  bottom: 4px;
  right: 0;
  svg {
    width: 18px;
    height: 18px;
    fill: #334d6e;
    stroke: #334d6e;
  }
`;

const CompanyLocation = styled(Text)`
  svg {
    width: 14px;
    margin-right: 6px;
  }
`;

const CompanyDetails = styled(Text)`
  display: block;
`;

const WebsiteIcon = styled.a`
  font-size: 23px;
  margin-left: 20px;
  line-height: 23px;
  align-self: stretch;
`;

const Description = styled(Text)`
  font-size: 14px;
  margin: 20px 0;
`;

const Label = styled.span`
  display: block;
  font-size: 12px;
  font-weight: bold;
`;
const Value = styled.span`
  display: block;
  font-size: 16px;
  color: rgb(175, 189, 209);
  font-weight: bold;
`;

const DateValue = styled(Value)`
  color: #fa8c16;
  text-transform: uppercase;
`;

const CenteredColumn = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Styled = {
  CompanyCard,
  LogoColumn,
  ImageWrapper,
  CompanyNameWrapper,
  ScoreTrendingWrapper,
  CompanyName,
  CompanyProfileLink,
  CompanyLocation,
  CompanyDetails,
  WebsiteIcon,
  Description,
  Label,
  Value,
  DateValue,
  CenteredColumn,
};

export default Styled;
