import { createSelector } from 'reselect';
import { AppState } from '@optx/redux/interfaces';
import createCachedSelector from 're-reselect';
import { find as _find } from 'lodash';
// redux
import { getSavedSearchesSelector } from '../../feature/saved-search/selectors';

// specific selectors
const selectCompanySuggestedSearchesState = (state: AppState) => state.suggestedSearches;
const selectCompanyFilters = (state: AppState) => state.filters;

export const getSuggestedSearches = createSelector(
  selectCompanySuggestedSearchesState,
  getSavedSearchesSelector
);

export const shouldFetch = createSelector(
  selectCompanySuggestedSearchesState,
  state => !state.fetchedAt
);

export const shouldFetchSearches = createSelector(
  selectCompanySuggestedSearchesState,
  state => !state.loading && !state.fetchedAt
);

const filtersLoaded = createSelector(selectCompanyFilters, state => !!state.fetchedAt);
export const shouldInitialFetch = createSelector(
  filtersLoaded,
  shouldFetch,
  (fetchedFilters, shouldFetch) => fetchedFilters && shouldFetch
);

export const isLoading = createSelector(
  selectCompanySuggestedSearchesState,
  state => state.loading
);

export const getSearch = createCachedSelector(
  selectCompanySuggestedSearchesState,
  (state: AppState, searchId: number) => searchId,
  (state, searchId) => state.byId[searchId]
)((state, searchId) => searchId);

export const getSuggestedSearch = (title: string) =>
  createSelector(selectCompanySuggestedSearchesState, state =>
    _find(state.byId, search => search.title === title)
  );

export const getTotal = createSelector(selectCompanySuggestedSearchesState, state => state.total);

export const getSuggestedSearchesTitleList = createSelector(
  selectCompanySuggestedSearchesState,
  state => state.allIds.map(id => state.byId[id].title)
);
