import React from 'react';
import Icon, { IconProps } from '@optx/components/common/Icon/Icon';
import styled from 'styled-components';
import { styledColors } from '@utils/theme/colors';

export const OpenInNewTabIcon = styled((props: IconProps) => (
  <Icon {...props} iconName="open-in-new" />
))`
  width: 18px;
  height: 18px;
  color: #000;
  margin-left: 10px;

  &:hover {
    color: ${styledColors.blue6};
  }
`;
