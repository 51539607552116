export const ADD_COMPANY_NOTE = 'ADD_COMPANY_NOTE';
export const ADD_COMPANY_NOTE_SUCCESS = 'ADD_COMPANY_NOTE_SUCCESS';
export const ADD_COMPANY_NOTE_FAIL = 'ADD_COMPANY_NOTE_FAIL';

export const DELETE_COMPANY_NOTE = 'DELETE_COMPANY_NOTE';
export const DELETE_COMPANY_NOTE_SUCCESS = 'DELETE_COMPANY_NOTE_SUCCESS';
export const DELETE_COMPANY_NOTE_FAIL = 'DELETE_COMPANY_NOTE_FAIL';

export const FETCH_COMPANY_NOTES = 'FETCH_COMPANY_NOTES';
export const FETCH_COMPANY_NOTES_SUCCESS = 'FETCH_COMPANY_NOTES_SUCCESS';
export const FETCH_COMPANY_NOTES_FAIL = 'FETCH_COMPANY_NOTES_FAIL';
