/* eslint-disable max-len */
import { CriteriaKeys, CardInfo } from '@features/sourcing-outreach-summary/models';

const qualifiedOpportunitiesPlatform: CardInfo = {
  title: 'Calculation Explanations',
  content: [
    {
      title: 'Net New (QO)',
      description:
        "Distinct Companies where the Stage changed to (Qualified Lead, VWIA, Target, OR Priority) within the selected date range AND the Stage was Modified By the Person AND (the Company's Add-on is False OR NULL)",
    },
  ],
};

const qualifiedOpportunitiesOps: CardInfo = {
  title: 'Calculation Explanations',
  content: [
    {
      title: 'Sum of "New Qualified Opportunities created (Platform)"',
      description:
        'New Qualified Opportunities created (Platform) = Stage change from Lead/Track/Pass to QL/VWIA/Target/Priority/IOI/LOI and do not have the add-on flag checked.',
    },
  ],
};

const staleAccounts: CardInfo = {
  title: 'Calculation Explanations',
  content: [
    {
      title: 'Stale Account',
      description:
        'An account that is owned by a sourcing team member with no touch in the last 90 days AND without a future touch (unless the company is marked as PASS or DEAD or Portfolio Company)',
    },
  ],
};

const revivedTouches: CardInfo = {
  title: 'Calculation Explanations',
  content: [
    {
      title: 'Revived',
      description:
        'A Revived company is one that was not previously owned by the current company owner AND was previously a Stale Lead',
    },
  ],
};

const cardInfo: Record<CriteriaKeys, CardInfo | null> = {
  qualified_opportunities_platform: qualifiedOpportunitiesPlatform,
  qualified_opportunities_ops: qualifiedOpportunitiesOps,
  stale_accounts: staleAccounts,
  revived_touches: revivedTouches,
};

export default cardInfo;
