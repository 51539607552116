import queryString from 'query-string';
import ApiClient, { API_URL } from './axios-client';
import { FilterGroup } from '../../models/filters';

class FilterService {
  static client: ApiClient = new ApiClient(`${API_URL}/filter`);

  /**
   * Get filters options from a filter group.
   * @param filterGroup type of filter groups.
   * @param filterNames filter names. If no filter names list is provided or value is set to null will
   * get all available filters options from specified group.
   */
  static getOptions(filterGroup: FilterGroup = FilterGroup.Company, filterNames?: Array<string>) {
    const payload = {
      filter_names: filterNames || '*',
      filter_group: filterGroup,
    };

    return FilterService.client.get(
      `/options?${queryString.stringify(payload, { arrayFormat: 'comma' })}`
    );
  }

  static companiesOutReachFilters() {
    return FilterService.client.get('/sourcing_companies_options');
  }

  static getAnalystLeaderboardOptions() {
    return FilterService.client.get('/leaderboard_options');
  }
}

export default FilterService;
