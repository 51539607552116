import moment, { Moment } from 'moment';
import { SelectOption } from '@optx/models/Option';

export const isFutureDate = (current: Moment) => {
  return current && current > moment().endOf('day');
};

export const formatDateFromDatePicker = (date: string) => {
  // 2021-08-03T08:38:19.965Z
  // The date picker from formik-antd returns date strings in the format above.
  // We need to remove the part after "T", including
  const newDate = date.indexOf('T') !== -1 ? date.substring(0, date.indexOf('T')) : date;

  return newDate;
};

/**
 * Get year range list staring with current year + 2
 * @param startYear start year of array
 * @param yearsInAdvance number of years to add to current year
 * @returns {Array<SelectOption>}
 */
export const getYearsList = (startYear: number = 1900, yearsInAdvance: number = 0) => {
  let maxYear = new Date().getFullYear() + yearsInAdvance;
  const years: Array<SelectOption> = [];

  for (let index = maxYear; index >= startYear; index--) {
    years.push({
      value: index.toString(),
      label: index.toString(),
    });
  }

  return years;
};

/**
 * Get the function with the date limits that user can select for Last Round Date.
 * This can be passed into the disabledDate property of antd's Datepicker
 * @param currentInputDate current last round date in date input
 * @param secondToLastRoundDate date of previous last round
 * @param numberOfRounds total number of rounds
 * @returns a function that can be passed into the disabledDate property of antd's Datepicker
 */
export const getLastRoundDisabledDate =
  (currentInputDate: moment.Moment, secondToLastRoundDate: moment.Moment, numberOfRounds: number) =>
  (current: moment.Moment) => {
    // user should not select a future date for Last Round Date
    if (current.isAfter(moment().endOf('day'))) {
      return true;
    }

    if (numberOfRounds === 0) {
      return false;
    }

    // if one round, limit to date of last round
    if (numberOfRounds === 1) {
      return current.isBefore(currentInputDate);
    }

    // if multiple rounds then limit to date of previous to last round
    if (numberOfRounds > 1) {
      if (!secondToLastRoundDate || !secondToLastRoundDate.isValid()) {
        return false;
      }

      return current.isBefore(secondToLastRoundDate);
    }

    return false;
  };
