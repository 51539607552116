import React from 'react';
import { components, ValueContainerProps } from 'react-select';
import { SearchOutlined } from '@ant-design/icons';

/**
 * Dropdown search area.
 */
const ValueContainer: React.FC<ValueContainerProps<any>> = props => {
  const { children } = props;

  return (
    <components.ValueContainer {...props}>
      <span className="multiselect-select__value-container__icon">
        <SearchOutlined />
      </span>
      {children}
    </components.ValueContainer>
  );
};

export default ValueContainer;
