import { createSelector } from 'reselect';
// models
import { AppState } from '@optx/redux/interfaces';
// utils
import { sortColumns } from '@optx/utils/table/sorting';
// constants
import { defaultColumns } from '@optx/components/common/table/TableCompanyFiles';

const filesState = (state: AppState) => state.files;

export const isLoading = createSelector(filesState, state => state?.loading);
export const shouldFetch = createSelector(
  filesState,
  state => !state?.loading && !state?.fetchedAt
);
export const getFileData = createSelector(filesState, state => state?.data);
export const fetchForCompanyId = createSelector(filesState, state => state?.companyId);
export const sortBy = createSelector(filesState, state => state?.sortBy);
export const getColumns = createSelector(filesState, state =>
  sortColumns(defaultColumns, state?.sortBy)
);
export const getFilters = createSelector(filesState, state => state?.filter?.values);
export const getOptions = createSelector(filesState, state => state?.filter?.options);
