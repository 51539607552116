import Option, { SelectableOption } from '@optx/models/Option';
import { useMemo } from 'react';

interface SelectOptionClickHandler {
  (nextValue: string): void;
}

const mapSelectedOption = (value: string | undefined, option: Option) => {
  if (value === option.value) {
    return {
      ...option,
      selected: true,
    } as SelectableOption;
  }

  return { ...option };
};

const useSingleSelect = (
  value: string | undefined,
  options: Array<Option>,
  onClick: SelectOptionClickHandler
) => {
  const mappedOptions: Array<SelectableOption> = useMemo(
    () => options.map(option => mapSelectedOption(value, option)),
    [options, value]
  );

  const handleClick = (nextValue: string) => {
    if (nextValue !== value) {
      onClick(nextValue);
    }
  };

  return {
    options: mappedOptions,
    onClick: handleClick,
  };
};

export default useSingleSelect;
