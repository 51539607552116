import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// models
import { TableCellWatchlists } from '@optx/models/table/Cell';
// constants
import { FAVORITE_LIST_ID } from '@constants/lists';
// services
import { CompanyService } from '@optx/services/api';
// components
import { exportFileCSV } from '@utils/csvHandler';
// redux
import { selectors as userListSelectors } from '@redux/user/user-list';
import { actions as favoriteListsActions } from '@redux/favorite-lists/lists';
import { selectors as listSelectors, actions as listActions } from '@redux/lists/details';
import { actions as pipelineActions } from '@redux/my-pipeline';
import DropdownDot from '@components/feature/drop-down/saved-searches/ListActions';
import { columns } from '@optx/components/common/table/Companies/columns/virtual-column-config';

const TableCellActions: React.FC<TableCellWatchlists> = ({ row }) => {
  const {
    unique_id: id,
    title,
    share_permission: permission,
    owner_name: ownerName,
    addon_company: associatedCompany,
    is_owner: isOwner,
  } = row;

  const dispatch = useDispatch();

  const currentFilteredListId = useSelector(listSelectors.getFilterListId);
  const userListShare = useSelector(userListSelectors.getUserList);

  const handleRenameList = (title: string, listId: number | string) => {
    dispatch(favoriteListsActions.renameFavoriteList({ title, list_id: listId }));
  };

  const handleRemoveList = (listId: number | string) => {
    dispatch(favoriteListsActions.removeSharedList(listId));
  };

  const handleClearFilterList = () => dispatch(listActions.clearCompanyList());

  const handleDeleteListFavorites = (listId: number | string) => {
    // clear current list before deleting it
    if (currentFilteredListId.toString() === listId.toString()) {
      handleClearFilterList();
    }

    dispatch(favoriteListsActions.deleteFavoriteList(listId));
  };

  const handleExportListFavorites = (
    listId: string,
    fileName: string,
    includeDescription = false
  ) => {
    const companyData =
      listId === FAVORITE_LIST_ID
        ? { company: `saved_list_id=${FAVORITE_LIST_ID}` }
        : { company: `saved_list_id=${[listId]}` };

    dispatch(pipelineActions.setLoading(true));
    exportFileCSV(
      fileName,
      companyData,
      columns,
      CompanyService.exportToCSV,
      includeDescription,
      true
    ).then(() => {
      dispatch(pipelineActions.setLoading(false));
    });
  };

  return (
    <>
      <DropdownDot
        type="list"
        isRight
        listId={id}
        title={title}
        userListShare={userListShare}
        handleDeleteList={handleDeleteListFavorites}
        handleRenameList={handleRenameList}
        handleRemoveList={handleRemoveList}
        onDownloadList={handleExportListFavorites}
        accessRights={permission}
        ownerName={ownerName}
        listAssociation={associatedCompany}
        isOwner={isOwner}
      />
    </>
  );
};

export default TableCellActions;
