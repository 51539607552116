import React from 'react';
import styled, { keyframes } from 'styled-components';

import { LoadIcon32 } from '@optx/components/LoadingIcon';

const animation = keyframes`
from {
  transform: rotate(0deg) scale(2);
}

to {
  transform: rotate(360deg) scale(2);
}
`;
const LoadingWrapper = styled.div`
  height: 46px;
  svg {
    position: absolute;
    top: 45%;
    left: 45%;
    z-index: 1000;
    transform: translate(-50%, -50%);
    animation: linear ${animation} 2s infinite;
    width: 32px;
    height: 32px;
  }
`;

const LoadingIcon = () => {
  return (
    <LoadingWrapper>
      <LoadIcon32 />
    </LoadingWrapper>
  );
};

export default LoadingIcon;
