import { useSelector } from 'react-redux';
import { selectors } from '@features/grid/view-transition';
import LongCardView from './LongCardView';

const CardContent = () => {
  const isViewTransition = useSelector(selectors.getViewTransition);

  return <div className="position-relative">{!isViewTransition && <LongCardView />}</div>;
};

export default CardContent;
