import React, { useMemo } from 'react';
import { Select, Typography } from 'antd';
import { useField } from 'formik';
import { FormItem } from 'formik-antd';
import { OptionTypeBase } from 'react-select';
// models
import { BaseField } from '@optx/models/companyFields';
// components
import CheckboxIcon from './CheckboxIcon';
import Styled from './MultiSelectField.styled';

const { Option } = Select;

interface MultiSelectAllFieldProps {
  field: BaseField<OptionTypeBase[]>;
}

const MultiSelectAllField: React.FC<MultiSelectAllFieldProps> = ({ field }) => {
  const [selected, , setSelectedOption] = useField<string[] | null>(field.id);

  const handleChange = (value: string, type: 'select' | 'deselect') => {
    if (type === 'select') {
      if (value === 'all') {
        setSelectedOption.setValue(field.data.map(option => option.value as string));
      } else {
        setSelectedOption.setValue([...(selected.value || []), value]);
      }
    } else if (type === 'deselect') {
      if (value === 'all') {
        setSelectedOption.setValue([]);
      } else {
        const updatedSelected = selected.value?.filter(option => option !== value);
        setSelectedOption.setValue([...(updatedSelected || [])]);
      }
    }
  };

  const normalizedSelected = useMemo(() => {
    let selectedOptions = selected.value || [];

    if (selectedOptions?.length && selectedOptions.length === field.data.length) {
      selectedOptions = ['all', ...selectedOptions];
    }

    return selectedOptions;
  }, [selected.value, field.data]);

  const selectClass = normalizedSelected?.includes('all') ? 'all-selected' : '';

  return (
    <>
      <Styled.SelectWrapper span={8} className={field.id}>
        <Typography.Text>{field.label}</Typography.Text>
        <FormItem name={field.id}>
          <Select
            showSearch
            showArrow
            className={`multi-select-field ${selectClass}`}
            value={normalizedSelected}
            menuItemSelectedIcon={CheckboxIcon}
            onSelect={(value, option) => handleChange(value, 'select')}
            onDeselect={(value, option) => handleChange(value, 'deselect')}
            optionFilterProp="children"
            mode="multiple"
            getPopupContainer={trigger => trigger.parentElement!}
            disabled={!field.editable}
            tagRender={props => {
              if (props.value === 'all') {
                return (
                  <span>
                    Select: <span className="all-blue">ALL</span>
                  </span>
                );
              }

              return <span>{props.label || ''}</span>;
            }}
            filterOption={(input: string, option: OptionTypeBase | undefined) => {
              const fullName = `${option?.children.props.children}`;

              return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {field.data && (
              <Option key="all" value="all" className="left-checkbox-option select-all-option">
                <span>(select all / none)</span>
              </Option>
            )}
            {field.data?.map((option: OptionTypeBase) => (
              <Option
                key={option.value as string}
                value={option.value as string}
                className="left-checkbox-option"
              >
                <span>{option.label}</span>
              </Option>
            ))}
          </Select>
        </FormItem>
      </Styled.SelectWrapper>
      <Styled.ContactSelectGlobal />
    </>
  );
};

export default React.memo(MultiSelectAllField);
