import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { reducers as fetchReducers } from '@redux/feature/fetch';
import { DealTeamState, EditDealTeam } from './interfaces';
import * as actions from './actions';

export const initialState: DealTeamState = {
  companyId: null,
  error: null,
  loading: false,
};

const addDealTeamMember: CaseReducer<DealTeamState, PayloadAction<EditDealTeam>> = (
  draftState,
  action
) => {
  const { companyId } = action.payload;
  draftState.companyId = companyId;
  draftState.loading = true;
};

const addDealTeamMemberSuccess: CaseReducer<DealTeamState, PayloadAction<EditDealTeam>> = (
  draftState,
  action
) => {
  draftState.loading = false;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.addDealTeamMember, addDealTeamMember)
    .addCase(actions.addDealTeamMemberSuccess, addDealTeamMemberSuccess)
    .addCase(actions.addDealTeamMemberFail, fetchReducers.fetchFailReducer)
);

export default reducer;
