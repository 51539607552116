import { combineReducers } from 'redux';

import { ChecklistResearchInsightState } from '../interfaces';
import filters from './filters';
import sort from './sort';
import ui from './ui';
import search from './search';
import cards from './cards';

export const reducer = combineReducers<ChecklistResearchInsightState>({
  filters,
  cards,
  search,
  sort,
  ui,
});
