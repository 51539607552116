import { takeLatest, call, put, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
// models
import { CompanyImport, CompanyImportFailedData } from '@optx/models/Company';
import { actions as customGlobalLoaderActions } from '@features/custom-global-loader';
import { SortByRule } from '@optx/models/table/sorting';
// utils
import { convertPayloadImport } from '@optx/utils/helpers';
import {
  constants as importConstants,
  selectors as importSelectors,
} from '@optx/features/list-import/state';
// redux
import {
  actions as favoriteListsActions,
  selectors as favoriteListsSelectors,
} from '@redux/favorite-lists/lists';
import * as actions from '@optx/features/list-import/state/actions';
// services
import NotificationService from '@services/NotificationService';
import { CompanyService } from '@services/api';
import { ImportedCompany } from './interfaces';

let importCompaniesCopy: Array<ImportedCompany> = [];
let retryImportCompaniesCopy: Array<ImportedCompany> = [];
const listOfCompanies: CompanyImportFailedData[] = [];

function* importFavoriteListSaga() {
  const cancelImport: boolean = yield select(importSelectors.cancel);
  const importCompanies: ImportedCompany[] = yield select(importSelectors.getImportCompanies);
  const importCompaniesCount: number = yield select(importSelectors.getImportCompaniesCount);
  const title: string = yield select(importSelectors.getWatchlistTitle);

  if (importCompaniesCopy.length === 0) {
    importCompaniesCopy = importCompanies.slice();
  }

  const companiesBatch = importCompaniesCopy.slice(
    0,
    importConstants.BULK_IMPORT_LIMIT
  ) as ImportedCompany[];
  const currentResults: CompanyImportFailedData[] = yield select(importSelectors.getResults);
  const nextResults: CompanyImportFailedData[] = [];

  try {
    const { dataString } = convertPayloadImport(companiesBatch);
    const res: AxiosResponse<CompanyImport> = yield call(CompanyService.importFavoriteList, {
      title,
      data: dataString,
    });

    if (Object.keys(res.data).length === 0) {
      // If import failed on backend
      yield put(
        actions.updatePercentage({
          result: (importConstants.BULK_IMPORT_LIMIT / importCompaniesCount) * 100,
        })
      );
      yield put(actions.setFailedImportsOnRequest(companiesBatch));

      companiesBatch.forEach(company => {
        const { URL, Company, raw_url } = company;
        nextResults.push({
          URL,
          Company,
          Reason: 'Failed to Import',
          possible_matches: [],
          count_possible_matches: 0,
          raw_url,
        });
      });

      const finalResults: CompanyImportFailedData[] = currentResults.concat(nextResults);

      yield put(actions.saveResults({ data: finalResults, sort: true }));
      importCompaniesCopy.splice(0, importConstants.BULK_IMPORT_LIMIT);

      if (!cancelImport) {
        if (importCompaniesCopy.length > 0) {
          yield put(actions.importFavoriteList());
        } else {
          yield put(actions.updateProgress(false));
          yield put(actions.updateCompleted(true));
          yield put(actions.updatePercentage({ result: 100 }));
        }
      }
    } else if (res.data) {
      yield put(actions.saveListId(res.data.list_id));
      yield put(actions.importFavoriteListSuccess(res.data));

      yield put(
        actions.updatePercentage({
          result: (importConstants.BULK_IMPORT_LIMIT / importCompaniesCount) * 100,
        })
      );

      const successCompaniesIds = Object.entries(res.data.successful_companies).map(
        ([key, value]) => ({
          companyName: value[0].optx_company_name,
          id: +key,
          URL: value[1],
          raw_url: value[2],
        })
      );
      const successCompaniesMapped = successCompaniesIds.map(company => {
        return {
          URL: company.URL,
          Company: company.companyName,
          Reason: '',
          possible_matches: [],
          count_possible_matches: 0,
          raw_url: company.raw_url,
        };
      });

      listOfCompanies.push(...successCompaniesMapped, ...(res.data.failed_company_data || []));

      yield put(actions.saveResults({ data: listOfCompanies, sort: true }));

      importCompaniesCopy.splice(0, importConstants.BULK_IMPORT_LIMIT);

      if (!cancelImport) {
        if (importCompaniesCopy.length > 0) {
          yield put(actions.importFavoriteList());
        } else {
          yield put(actions.updateProgress(false));
          yield put(actions.updateCompleted(true));
          yield put(actions.updatePercentage({ result: 100 }));
        }
      } else {
        listOfCompanies.length = 0;
        const canceledCompanies: CompanyImportFailedData[] = [];
        const prevResults: CompanyImportFailedData[] = yield select(importSelectors.getResults);

        if (importCompaniesCopy.length <= 100) {
          importCompaniesCopy.forEach(company => {
            const { Company, URL, raw_url } = company;
            canceledCompanies.push({
              URL,
              Company,
              Reason: 'Canceled by user',
              possible_matches: [],
              count_possible_matches: 0,
              raw_url,
            });
          });
          yield put(
            actions.saveResults({
              data: prevResults.concat(canceledCompanies as CompanyImportFailedData[]),
              sort: true,
            })
          );
          yield put(actions.updateCanceledImports(canceledCompanies as CompanyImportFailedData[]));
        } else {
          yield put(actions.saveResults({ data: prevResults, sort: true }));
        }

        yield put(actions.updateCanceledImportsNumber(importCompaniesCopy.length));
        yield put(actions.updateProgress(false));
        yield put(actions.updateCompleted(true));
        yield put(actions.cancel(false));
        yield put(actions.updatePercentage({ result: 100 }));
        yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
      }

      if (res.data.results) {
        const query: string = yield select(favoriteListsSelectors.getQuery);
        const sorting: SortByRule<any>[] = yield select(favoriteListsSelectors.getSortBy);
        yield put(
          favoriteListsActions.fetchFavoriteLists({ query, sortBy: sorting, fetchAll: true })
        );
      }
    }
  } catch (e: any) {
    // If there is an error cancel the import

    if (e.response.data?.failed_company_data) {
      companiesBatch.forEach((company, index) => {
        const { URL, Company, raw_url } = company;
        listOfCompanies.push({
          URL,
          Company,
          Reason: e.response.data?.failed_company_data[index].Reason,
          possible_matches: [],
          count_possible_matches: 0,
          raw_url,
        });
      });
      yield put(actions.saveResults({ data: listOfCompanies, sort: true }));
    }

    const prevResults: CompanyImportFailedData[] = yield select(importSelectors.getResults);
    yield put(actions.saveResults({ data: prevResults, sort: true }));
    yield put(actions.setFailedImportsOnRequest(importCompaniesCopy));
    yield put(actions.cancel(false));
    yield put(actions.updateProgress(false));
    yield put(actions.updateCompleted(true));
    yield put(actions.updatePercentage({ result: 100 }));
    listOfCompanies.length = 0;

    NotificationService.error('Import file fail, Server error!');
  }

  listOfCompanies.length = 0;
}

function* editCompanySaga(action: PayloadAction<ImportedCompany>) {
  const title: string = yield select(importSelectors.getWatchlistTitle);
  const { dataString } = convertPayloadImport([action.payload]);
  const currentResults: CompanyImportFailedData[] = yield select(importSelectors.getResults);
  const editingCompanyIndex: number = yield select(importSelectors.getEditingCompanyIndex);
  const listsIds: number[] = yield select(favoriteListsSelectors.getAllListIds);
  const previewsCompany = currentResults[editingCompanyIndex].Company;
  const currentListId: number = yield select(importSelectors.getListId);
  const previewsURL = currentResults[editingCompanyIndex].URL;

  const isListExists = listsIds.includes(currentListId);

  try {
    yield put(actions.setIsEditing(true));
    const res: AxiosResponse<CompanyImport> = yield call(CompanyService.importFavoriteList, {
      title,
      data: dataString,
    });

    if (res.data?.results) {
      const failedCompany = res.data.failed_company_data ?? [];
      yield put(actions.setIsEditing(false));

      if (!currentListId) {
        yield put(actions.saveListId(res.data.list_id));
      }

      if (failedCompany.length === 0) {
        // If no errors update results
        const finalResults = Array.from(currentResults);

        if (res.data.list_id) {
          Object.entries(res.data.successful_companies).map(([key, value]) => {
            finalResults[editingCompanyIndex] = {
              Company: value[0]?.optx_company_name || action.payload.Company,
              URL: action.payload.URL,
              Reason: '',
              possible_matches: [],
              count_possible_matches: 0,
              raw_url: action.payload.raw_url,
            };
          });
        }

        if (finalResults) {
          yield put(actions.saveResults({ data: finalResults, sort: false }));
          yield put(actions.removeFailedCompamy({ Company: previewsCompany, URL: previewsURL }));
          yield put(actions.removeFailedImportsOnRequest(previewsCompany));

          if (currentListId && isListExists) {
            yield put(favoriteListsActions.updateListCount(currentListId || res.data.list_id));
          } else {
            const query: string = yield select(favoriteListsSelectors.getQuery);
            const sorting: SortByRule<any>[] = yield select(favoriteListsSelectors.getSortBy);
            yield put(
              favoriteListsActions.fetchFavoriteLists({ query, sortBy: sorting, fetchAll: true })
            );
          }
        }
      } else {
        const finalResults = Array.from(currentResults);
        finalResults[editingCompanyIndex] = failedCompany[0];
        finalResults[editingCompanyIndex] = {
          ...failedCompany[0],
        };
        yield put(actions.saveResults({ data: finalResults, sort: false }));
      }

      const successCompaniesIds = Object.entries(res.data.successful_companies).map(
        ([key, value]) => ({
          companyName: value[0]?.optx_company_name,
          id: +key,
          URL: value[1],
          raw_url: value[2],
        })
      );
      yield put(actions.updateImportCompaniesIds(successCompaniesIds));
    } else {
      // if editing failed
      const failedCompany = res.data.failed_company_data ?? [];
      const finalResults = Array.from(currentResults);
      finalResults[editingCompanyIndex] = failedCompany[0];
      yield put(actions.saveResults({ data: finalResults, sort: false }));
      yield put(actions.setIsEditing(false));
    }
  } catch (e: any) {
    if (Object.keys(e.response.data)) {
      const updatedResults = [...currentResults];
      const failedCompany = e.response.data.failed_company_data[0];
      updatedResults[editingCompanyIndex] = {
        Company: failedCompany?.Company,
        URL: failedCompany?.URL,
        Reason: failedCompany?.Reason,
        possible_matches: failedCompany?.possible_matches,
        count_possible_matches: failedCompany?.count_possible_matches,
        raw_url: failedCompany?.raw_url,
      };
      yield put(actions.saveResults({ data: updatedResults, sort: false }));
    }

    NotificationService.error('Edit company fail, Server error!');
    yield put(actions.setIsEditing(false));
  }
}

function* resetProgressSaga() {
  yield (importCompaniesCopy = []);
  yield (retryImportCompaniesCopy = []);
}

function* retryImportSaga() {
  const cancelImport: boolean = yield select(importSelectors.cancel);
  const failedImportsOnRequestCompanies: ImportedCompany[] = yield select(
    importSelectors.getFailedImportsOnRequest
  );

  const failedBatches: number = yield select(importSelectors.getFailedBatches);
  const title: string = yield select(importSelectors.getWatchlistTitle);

  const importCompaniesCount: number = yield select(importSelectors.getImportCompaniesCount);

  if (retryImportCompaniesCopy.length === 0) {
    retryImportCompaniesCopy = failedImportsOnRequestCompanies.slice();
  }

  const companiesBatch = retryImportCompaniesCopy.slice(
    failedBatches * importConstants.BULK_RETRY_IMPORT_LIMIT,
    importConstants.BULK_RETRY_IMPORT_LIMIT
  ) as ImportedCompany[];
  const currentResults: CompanyImportFailedData[] = yield select(importSelectors.getResults);
  yield put(actions.updateProgress(true));

  yield put(
    actions.updatePercentage({
      result: (currentResults.length / importCompaniesCount) * 100,
      isPrevious: true,
    })
  );

  try {
    const { dataString } = convertPayloadImport(companiesBatch);
    const res: AxiosResponse<CompanyImport> = yield call(CompanyService.importFavoriteList, {
      title,
      data: dataString,
    });

    if (Object.keys(res.data).length === 0) {
      // If import failed on backend
      yield put(
        actions.updatePercentage({
          result: (importConstants.BULK_RETRY_IMPORT_LIMIT / importCompaniesCount) * 100,
        })
      );

      yield put(actions.setFailedBatches(failedBatches + 1));

      if (!cancelImport) {
        if (retryImportCompaniesCopy.length > 0 && companiesBatch.length > 0) {
          yield put(actions.retryImport());
        } else {
          yield put(actions.updateProgress(false));
          yield put(actions.updateCompleted(true));
          yield put(actions.updatePercentage({ result: 100 }));
          yield put(actions.setFailedBatches(0));
        }
      }
    } else if (res.data.results) {
      yield put(actions.saveListId(res.data.list_id));
      yield put(actions.importFavoriteListSuccess(res.data));

      yield put(
        actions.updatePercentage({
          result: (importConstants.BULK_RETRY_IMPORT_LIMIT / importCompaniesCount) * 100,
        })
      );

      yield put(actions.removeFailedCompamies(companiesBatch.map(batch => batch.Company)));

      const successCompaniesIds = Object.entries(res.data.successful_companies).map(
        ([key, value]) => ({
          companyName: value[0]?.optx_company_name,
          id: +key,
          URL: value[1],
          raw_url: value[2],
        })
      );

      const successCompaniesMapped = successCompaniesIds.map(company => {
        return {
          URL: company.URL,
          Company: company.companyName,
          Reason: '',
          possible_matches: [],
          count_possible_matches: 0,
          raw_url: company.raw_url,
        };
      });

      listOfCompanies.push(...successCompaniesMapped, ...(res.data.failed_company_data || []));

      const listUpdated = listOfCompanies.concat(currentResults);

      yield put(actions.saveResults({ data: listUpdated, sort: true }));
      yield put(actions.editCompanies(listUpdated));
      retryImportCompaniesCopy.splice(
        failedBatches * importConstants.BULK_RETRY_IMPORT_LIMIT,
        importConstants.BULK_RETRY_IMPORT_LIMIT
      );

      if (!cancelImport) {
        if (retryImportCompaniesCopy.length > 0 && companiesBatch.length > 0) {
          yield put(actions.retryImport());
        } else {
          yield put(actions.updateProgress(false));
          yield put(actions.updateCompleted(true));
          yield put(actions.updatePercentage({ result: 100 }));
          yield put(actions.setFailedBatches(0));
        }
      } else {
        listOfCompanies.length = 0;
        const canceledCompanies: CompanyImportFailedData[] = [];
        const prevResults: CompanyImportFailedData[] = yield select(importSelectors.getResults);

        if (retryImportCompaniesCopy.length <= 100) {
          retryImportCompaniesCopy.forEach(company => {
            const { Company, URL, raw_url } = company;
            canceledCompanies.push({
              URL,
              Company,
              Reason: 'Canceled by user',
              possible_matches: [],
              count_possible_matches: 0,
              raw_url,
            });
          });
          yield put(
            actions.saveResults({
              data: prevResults.concat(canceledCompanies as CompanyImportFailedData[]),
              sort: true,
            })
          );
          yield put(actions.updateCanceledImports(canceledCompanies as CompanyImportFailedData[]));
        } else {
          yield put(actions.saveResults({ data: prevResults, sort: true }));
        }

        yield put(actions.updateCanceledImportsNumber(retryImportCompaniesCopy.length));
        yield put(actions.updateProgress(false));
        yield put(actions.updateCompleted(true));
        yield put(actions.cancel(false));
        yield put(actions.updatePercentage({ result: 100 }));
        yield put(actions.setFailedBatches(0));
        yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
      }

      if (res.data.results) {
        const query: string = yield select(favoriteListsSelectors.getQuery);
        const sorting: SortByRule<any>[] = yield select(favoriteListsSelectors.getSortBy);
        yield put(
          favoriteListsActions.fetchFavoriteLists({ query, sortBy: sorting, fetchAll: true })
        );
      }
    }
  } catch (e: any) {
    // If there is an error cancel the import

    const prevResults: CompanyImportFailedData[] = yield select(importSelectors.getResults);
    yield put(actions.saveResults({ data: prevResults, sort: true }));
    yield put(actions.cancel(false));
    yield put(actions.updateProgress(false));
    yield put(actions.updateCompleted(true));
    yield put(actions.updatePercentage({ result: 100 }));
    yield put(actions.setFailedBatches(0));

    NotificationService.error('Import file fail, Server error!');
  }

  listOfCompanies.length = 0;
}

export default function* importListsSagas() {
  yield takeLatest(actions.importFavoriteList, importFavoriteListSaga);
  yield takeLatest(actions.editCompany, editCompanySaga);
  yield takeLatest(actions.resetProgress, resetProgressSaga);
  yield takeLatest(actions.retryImport, retryImportSaga);
}
