import { createAction } from '@reduxjs/toolkit';
import Company from '@models/Company';
import * as types from './types';

// fetch actions
export const fetchFavorites = createAction(types.FETCH_FAVORITES);
export const fetchFavoritesSuccess = createAction<Company[]>(types.FETCH_FAVORITES_SUCCESS);
export const fetchFavoritesFail = createAction<string>(types.FETCH_FAVORITES_FAIL);

// add actions
export const addFavorite = createAction<number>(types.ADD_FAVORITE);
export const addFavoriteSuccess = createAction<number>(types.ADD_FAVORITE_SUCCESS);
export const addFavoriteFail = createAction<string>(types.ADD_FAVORITE_FAIL);

// delete actions
export const deleteFavorite = createAction<number>(types.DELETE_FAVORITE);
export const deleteFavoriteSuccess = createAction<number>(types.DELETE_FAVORITE_SUCCESS);
export const deleteFavoriteFail = createAction<string>(types.DELETE_FAVORITE_FAIL);
