import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Space, Tag } from 'antd';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down } from 'styled-breakpoints';
// models
import { CompanyProfile, SocialMediaProps } from '@optx/models/Company';
import { CompanyService } from '@optx/services/api';
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
import { defaultAvatarImage } from '@constants/images';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { socialMediaMapping } from '@optx/utils/socialMedia';
// redux
import { actions as modalActions } from '@redux/ui/modals/company-touches';
import { selectors as userSelectors } from '@redux/user/information';
import { actions as companyEditAllActions } from '@optx/features/edit-all-info/state';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';
// components
import DropdownAdd from '@optx/components/DropdownAdd';
import IsraelFlag from '@optx/assets/images/flags/israel_flag.jpg';
import EditFieldLocation from '@optx/features/company/edit-fields/components/edit-field-location/EditFieldLocation';
import { EditFieldSingleSelect } from '@optx/features/company/edit-fields/components';
import Icon from '../../../common/Icon';
import { IconLocation } from '../../../icons';
import OwnerAvatar from '@shared/view/organims/OwnerAvatar';

interface CompanyProfileLinksProps {
  company: CompanyProfile;
}

const CompanyProfileLinks: React.FC<CompanyProfileLinksProps> = ({ company }) => {
  const dispatch = useDispatch();
  const { advancedSearchPathname } = useIsAnalyst();

  const isDesktopOrSmaller = useBreakpoint(down('lg'));

  const isInET = company.is_in_et;

  const hasTouchAccess = useSelector(userSelectors.getHasAccessToTouches);

  // const toggleNotesModal = useCallback(
  //   (companyId: number, companyName: string) =>
  //     dispatch(
  //       notesModalActions.toggleCompanyNotesDialog({
  //         companyId,
  //         companyName,
  //       })
  //     ),
  //   [dispatch]
  // );

  // const toggleCompanyNotes = () => {
  //   company && toggleNotesModal(company.company_id, company.company_name);
  // };

  const onOwnerClick = () => {
    const origin = window.location.origin;
    const ownerId = company.company_owner_id;
    const newLocation = `${origin}${advancedSearchPathname}?company_owner_id=${ownerId}`;
    window.open(newLocation, '_blank');
  };

  const handleCreateTouch = () => {
    dispatch(
      modalActions.toggleCompanyTouchesDialog({
        companyId: company.company_id,
        companyName: company.company_name,
        company,
      })
    );
  };

  const handleEditAll = () => {
    dispatch(
      companyEditAllActions.toggleCompanyEditAllFormDialog({
        companyId: company.company_id,
        companyName: company.company_name,
        company,
      })
    );
  };

  const socialMediaList: Array<SocialMediaProps> = useMemo(() => {
    return socialMediaMapping(company);
  }, [company]);

  return (
    <>
      <Row className="company-details-row-wrapper">
        <Col className="company-details-col">
          <Row>
            <Col>
              <span className="company-name">
                {company.raw_url ? (
                  <a
                    href={company.raw_url}
                    title={company.raw_url!}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {company.company_name}
                  </a>
                ) : (
                  <>{company.company_name}</>
                )}
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              {!company.is_in_business && <Tag color="red">Out of Business</Tag>}
              {!company.source_tag.includes('et') && !company.is_in_et ? (
                <Tag color="gray">Not in ET</Tag>
              ) : (
                <Tag color="green">In ET</Tag>
              )}
              {company.is_in_israel && (
                <span>
                  <img
                    src={IsraelFlag}
                    alt="Israel"
                    style={{ borderRadius: 4, marginRight: 8 }}
                    height={20}
                  />
                </span>
              )}
              <span className="company-year-founded">
                <span>
                  <span className="company-year-founded-edit">
                    <EditFieldSingleSelect
                      fieldName="Year Founded"
                      value={
                        company.year_founded === 'None' ? DEFAULT_CELL_VALUE : company.year_founded
                      }
                      record={company}
                      service={CompanyService.updateCompanyYearFounded}
                      successMessage="Company year founded updated successfully!"
                      errorMessage="Company year founded update failed, Server error!"
                      isFromGrid={false}
                      useTooltip={false}
                    />
                  </span>
                </span>
              </span>
            </Col>
          </Row>
        </Col>
        <Col className="company-links-col">
          <div className="profile-edit-actions">
            <Button type="default" onClick={handleEditAll} className="editallbutton">
              Edit
            </Button>
            {isInET && hasTouchAccess && (
              <Button type="primary" onClick={handleCreateTouch} className="notesbutton">
                Add Touch Note
              </Button>
            )}
            <DropdownAdd record={company} isBlue />
          </div>
          {!isDesktopOrSmaller && (
            <div className="socialmedia">
              <ul>
                {socialMediaList.map((media, index) => (
                  <li key={`socialmedia-${index}`}>
                    <a
                      href={media.url}
                      title={media.title}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon
                        iconName={media.icon}
                        style={{
                          fill:
                            media.icon === 'website' && company.url_status === 'not active'
                              ? 'red'
                              : '#0050b3',
                        }}
                      />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {company.company_owner !== 'Unowned' && (
            <OwnerAvatar
              ownerId={company.company_owner_id}
              name={company.company_owner}
              ownerImage={company.owner_img || defaultAvatarImage}
              title="PSG OWNER"
              onClick={onOwnerClick}
              className="profile-hero__owner_avatar"
            />
          )}
        </Col>
      </Row>
      <Row className="company-location-row">
        <Col>
          <Space size={0}>
            <div className="company-location">
              <IconLocation />
              <EditFieldLocation record={company}>
                <span>{company.location || DEFAULT_EMPTY_VALUE}</span>
              </EditFieldLocation>
            </div>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default CompanyProfileLinks;
