import styled from 'styled-components';
import { Drawer as AntDrawer } from 'antd';

const DrawerTouches = styled(AntDrawer)`
  &.touch-extension {
    max-width: 640px;
  }
  .modal-title {
    font-size: 24px;
  }
  .ant-drawer-close {
    display: none;
  }
  .row-spacer {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .row-spacer-top {
    padding-top: 24px;
  }
  .row-spacer-bottom {
    padding-bottom: 16px;
  }
  &.touch-extension {
    .row-spacer {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .row-spacer-top {
      padding-top: 12px;
    }
    .row-spacer-bottom {
      padding-bottom: 12px;
    }
    .ant-radio-button-wrapper {
      padding: 0 10px;
    }
  }
  .company-intro {
    background: #ffffff;
    border-bottom: 1px solid #c2cfe0;
    padding: 14px 0;
  }
  .flex-nowrap {
    flex-wrap: nowrap;
  }
  .company-avatar--profile {
    max-width: 75px;
    max-height: 75px;
    margin-right: 15px;
  }
  .company-name {
    font-size: 20px;
    line-height: 1.4;
    font-weight: 600;
    color: #334d6e;
    display: block;
    padding-top: 5px;
  }
  .company-info {
    max-width: 500px;
  }
  .company-location {
    font-size: 14px;
    color: #90a0b7;
    font-weight: 700;
    svg {
      width: 14px;
      margin-right: 6px;
      vertical-align: baseline;
    }
  }
  .company-year-founded {
    font-size: 14px;
    color: #90a0b7;
    font-weight: 700;
  }
  .company-owner-name {
    padding-right: 10px;
    font-size: 14px;
    color: #90a0b7;
    font-weight: 700;
  }
  .media-object {
    margin: 0;
  }
  .ant-drawer-body {
    background: rgba(224, 231, 239, 0.2);
    padding-top: 0;
    .ant-select-selection-overflow {
      min-height: 30px;
    }
  }
  &.touch-extension .ant-drawer-body .ant-select-selection-overflow {
    min-height: 22px;
  }

  .company-additional-info-wrapper {
    display: block !important;
    margin-left: -24px;
    margin-right: -24px;
    padding: 12px 24px 2px;
    background-color: #fff;
    border-bottom: 1px solid #c2cfe0;

    .company-additional-info {
      display: flex;
      gap: 12px;

      .add-touch-select {
        max-width: fit-content;
        margin-bottom: 10px;

        & > div {
          max-width: calc(100% - 48px);
          min-width: fit-content;
          height: 24px;

          .styled-single-select__control {
            height: 24px;
            min-width: fit-content;
            min-height: 24px;
          }

          .styled-single-select__menu {
            z-index: 3;
          }

          .styled-single-select__single-value {
            margin-left: 0px;
            overflow: initial;
            font-weight: 600;
            font-size: 14px;
            margin-top: -2px;
          }

          .styled-single-select__indicators {
            .styled-single-select__indicator {
              padding-left: 0;

              .anticon {
                margin-left: 0;
              }
            }
          }
        }

        &.is-error {
          .styled-single-select__control {
            border: 1px solid #ff4d4f !important;
            box-shadow: 0 0 0 1px #ff4d4f !important;
          }
        }

        &.is-disabled {
          cursor: not-allowed;
        }
      }

      .styled-single-select__value-container {
        min-width: fit-content;
        height: 21px;
        min-height: 21px;
        margin-left: 0;

        & > span {
          margin-left: 6px;
        }

        span span.ant-typography {
          color: #1890ff;
          font-weight: 600;
        }

        & > div:last-child {
          height: 21px;
          min-height: 21px;
          padding: 0;
          margin: 0;
          margin-top: -5px;
          margin-left: 5px;
        }
      }

      .styled-single-select__control {
        span:nth-child(1) {
          font-size: 14px;
          margin-left: 4px;
          margin-top: -4px;
        }
      }

      .ant-select-single {
        .prefixed-selection-item__label {
          color: #1890ff;
        }
      }
    }

    .company-additional-info__error {
      color: #ff4d4f;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
`;

const TouchContainer = styled.div`
  position: relative;

  &.is-error:after {
    content: '';
    position: absolute;
    top: 0;
    right: -24px;
    bottom: -24px;
    left: -24px;
    background: #00000073;
    z-index: 2;
  }

  h1 {
    font-size: 16px;
  }
  .ant-typography {
    display: block;
  }
  .ant-select {
    width: 100%;
  }
  .ant-form-item-control-input {
    min-height: 24px;
  }
  .ant-select-selector {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const Styled = {
  TouchContainer,
  DrawerTouches,
};
