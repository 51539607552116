import { fork } from 'redux-saga/effects';
import { sagas as filtersSaga } from './filters';
import { sagas as searchSaga } from './search';

function* touchesSaga() {
  yield fork(filtersSaga);
  yield fork(searchSaga);
}

export default touchesSaga;
