import { createFeatureAction } from '@utils/feature';
import featureName from '../../featureName';
import * as actionTypes from './actionTypes';
import { EditOpportunityType } from './interfaces';

export const addOpportunityType = createFeatureAction<EditOpportunityType>(
  featureName,
  actionTypes.ADD_OPPORTUNITY_TYPE
);

export const addOpportunityTypeSuccess = createFeatureAction<EditOpportunityType>(
  featureName,
  actionTypes.ADD_OPPORTUNITY_TYPE_SUCCESS
);

export const addOpportunityTypeFail = createFeatureAction<string>(
  featureName,
  actionTypes.ADD_OPPORTUNITY_TYPE_FAIL
);
