import styled, { css } from 'styled-components';
import DefaultTable from '@shared/view/organims/Table';
import { styledColors } from '@utils/theme/colors';

const CompanyTableWrapper = styled.div`
  .ant-table-wrapper {
    position: relative;
    z-index: 1;
  }
  .external-scroll-wrapper,
  .external-scroll {
    bottom: 64px;
  }
  .react-resizable {
    position: relative;
    overflow: hidden;
    background-clip: padding-box;
  }

  .ant-table-body {
    overflow: hidden;
    min-height: 18px;
  }

  .react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
    z-index: 1;
  }

  .dragHandler:hover {
    cursor: move;
    background-color: #ccc;
  }

  .resizeHandle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
    z-index: 5;
  }
`;

const CompanyTable = styled(DefaultTable)<{
  isEmpty?: boolean;
  overflowHeight?: number | null;
}>`
  ${props =>
    !props.isEmpty &&
    props.overflowHeight &&
    css`
      .ant-table-body::after {
        content: '';
        display: block;
        position: absolute;
        height: ${props.overflowHeight}px;
        width: 100%;
        bottom: 0;
        z-index: 2;
        background: #fff;
      }
    `}
  .ant-table-body {
    min-height: 18px;
  }

  /* Temporary styles until we fix issue with DropdownAdd; seems can't be imported and used styled on it
  because is undefined */
  .ant-spin-dot-item {
    display: none;
  }

  .row_actions {
    .anticon-ellipsis {
      transform: scale(1.7);
      color: #c2cfe0;

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }

  .ant-table-cell.row_actions.ant-table-cell-fix-right.ant-table-cell-fix-right-first::after {
    border-right: 1px solid #f0f0f0 !important;
  }

  .ant-table-selection {
    text-align: center;
    display: block;
  }

  /* row hover */
  .ant-table-row {
    :hover {
      .row_actions .anticon-ellipsis,
      .company_url .anticon-global {
        color: #000;
      }

      .company_url {
        .anticon-global {
          :hover {
            color: inherit;
          }
        }
      }

      .row_actions {
        .anticon-ellipsis {
          :hover {
            color: ${styledColors.blue6};
          }
        }
      }
    }
  }

  .active-row {
    & > td {
      background-color: ${styledColors.blue1};
    }
  }

  .ant-table-header {
    .ant-table-cell::after {
      content: attr(data-order);
      width: auto;
      position: absolute;
      top: unset;
      bottom: 58%;
      right: 5px;
      left: unset;
      font-size: 14px;
      color: #1890ff;
      transform: unset;
      box-shadow: none;
    }
  }
`;

export const Styled = {
  CompanyTableWrapper,
  CompanyTable,
};
