import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
// models
import { ChartData, ChartIntervalKeys } from '@optx/models/charts/data';
// services
import { CompanyService } from '@services/api';
import NotificationService from '@services/NotificationService';
// utils
import { getErrorMessage } from '@utils/api/errors';
import { parseLineGraphDataWithPosition } from '@optx/utils/charts';
// redux
import { INITIAL_STATE_CHART } from '@optx/constants/charts';
import * as actions from './actions';

export function* fetchEmployeeGrowthSaga(action: PayloadAction<number>) {
  const companyId = action.payload;

  try {
    const res: AxiosResponse<ChartData> = yield call(CompanyService.getEmployeeGrowth, companyId);
    const growthList = res.data;

    if (growthList[Object.keys(growthList)[0] as ChartIntervalKeys].data.length) {
      yield put(actions.fetchEmployeeGrowthSuccess(parseLineGraphDataWithPosition(growthList)));
    } else {
      yield put(actions.fetchEmployeeGrowthSuccess(INITIAL_STATE_CHART));
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Failed to fetch Employee Growth!');
    yield put(actions.fetchEmployeeGrowthFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* employeeGrowthSaga() {
  yield takeLatest(actions.fetchEmployeeGrowth, fetchEmployeeGrowthSaga);
}
