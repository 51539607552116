import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
// redux
import { selectors, actions } from '@redux/ui/modals/company-touches';
// hooks
import { useShouldFetch } from '../fetch';

const useInitCompanyTouchesDialog = (companyId?: number) => {
  const dispatch = useDispatch();
  const shouldFetch = useSelector(selectors.shouldInitialFetch);

  const fetchFilters = useCallback(
    () => dispatch(actions.initCompanyTouchesDialog(companyId)),
    [dispatch, companyId]
  );

  useShouldFetch(shouldFetch, fetchFilters);
};

export default useInitCompanyTouchesDialog;
