import styled from 'styled-components';

type TrendingDirection = 'up' | 'down' | 'none';

const TrendingValue = styled.span<{ trending?: TrendingDirection }>`
  color: ${props => {
    switch (props.trending) {
      case 'up':
        // green-6
        return '#52c41a';
      case 'down':
        // red-6
        return '#f5222d';
      default:
        return 'inherit';
    }
  }};
`;

export default { TrendingValue };
