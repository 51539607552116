import React from 'react';
import { DoubleLeftOutlined } from '@ant-design/icons';

const NavigationTrigger = () => {
  return (
    <>
      <DoubleLeftOutlined />
      <span>Collapse Menu</span>
    </>
  );
};

export default NavigationTrigger;
