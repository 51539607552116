import React from 'react';
import { Button, Result } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
// utils
import { getCompanyProfileURL } from '@optx/utils/routes';

interface ConfirmationProps {
  id: number;
  showConfirmation: string;
  hasError: boolean;
}

const Confirmation: React.FC<ConfirmationProps> = ({ id, showConfirmation, hasError }) => {
  const location = useLocation();
  const history = useHistory();

  const handleClick = () => {
    history.push(getCompanyProfileURL(id, location.search.replace('?', '')));
  };

  return (
    <Result
      status={hasError ? 'error' : 'success'}
      title={
        hasError
          ? 'Company has been added to Equity Touch with errors.'
          : 'Company Sucessfully Added to Equity Touch'
      }
      subTitle="It may take up to 5 minutes for the company to show in Equity Touch"
      extra={[
        <Button type="primary" key="0" onClick={handleClick}>
          View in OPTX
        </Button>,
        <Button key="1" href={showConfirmation} target="_blank">
          View in Equity Touch
        </Button>,
      ]}
    />
  );
};

export default Confirmation;
