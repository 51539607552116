import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// models
import { MultiSelectFilterColumns } from '@optx/models/feature/analystsLeaderboard/filters';
import { SelectOption } from '@models/Option';
import { AppState } from '@optx/redux/interfaces';
import { MultiSelectFilter } from '@optx/models/filters';
// hooks
import useLocalMultiSelect from '@optx/common/hooks/select/useLocalMultiSelect';
// components
import { MultiSelectInline, MultiSelectProps } from '@shared/view/molecules/Select';
// Local
import { selectors, actions } from '../state';

interface FilterMultiSelectProps extends MultiSelectProps {
  filterKey: MultiSelectFilterColumns;
  criteria?: string;
}

const FilterMultiSelect: React.FC<FilterMultiSelectProps> = ({
  filterKey,
  criteria,
  ...restProps
}) => {
  const dispatch = useDispatch();

  const filter = useSelector((state: AppState) =>
    selectors.filters.getFilter(state, filterKey)
  ) as MultiSelectFilter<Array<SelectOption>>;
  const options = useSelector((state: AppState) =>
    selectors.filters.getSelectOptions(state, filterKey)
  );
  const value = useSelector((state: AppState) =>
    selectors.filters.getSelectValue(state, filterKey, criteria)
  );

  const updateValue = useCallback(
    (newValue: Array<SelectOption>) => {
      dispatch(actions.filters.updateValue({ value: newValue, filterKey, criteria }));
    },
    [dispatch, filterKey, criteria]
  );

  const { localValue, onBlur, onChange } = useLocalMultiSelect(value, updateValue);

  return (
    <MultiSelectInline
      options={options}
      onBlur={onBlur}
      onChange={onChange}
      value={localValue}
      placeholder={filter ? filter.placeholders : undefined}
      allPrefix={filter ? filter.placeholders : undefined}
      {...restProps}
    />
  );
};

export default FilterMultiSelect;
