import { DatePicker, Radio } from 'antd';
import styled from 'styled-components';

const RangePicker = styled(DatePicker.RangePicker)`
  padding: 0 11px;
  height: 100%;
  background: #fff;
  border: none;
  color: #1890ff !important;
  width: fit-content;
  box-shadow: none;

  .ant-picker-range-separator {
    border-right: 1px solid #d9d9d9;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 11px;
  }

  .ant-picker-input {
    width: fit-content;

    input {
      color: #1890ff;
    }
    input::placeholder {
      color: #595959;
      opacity: 1;
    }
  }
`;

const RadioButton = styled(Radio.Button)`
  width: 230px;
  padding: 0;
  position: absolute;
  top: 15px;
  right: 15px;

  &:hover {
    position: absolute;
  }
`;

export default {
  RangePicker,
  RadioButton,
};
