import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
// models
import { CompanyProfile } from '@optx/models/Company';
// redux
import { selectors as cardSelectors } from '@features/long-card/company-card';
import { selectors as userSelectors } from '@redux/user/information';
import graphSelectors from '@features/company/graphs/state/selectors';
// components
import LinearCombinedChart from '@optx/components/feature/charts/LinearCombinedChart';
import EmployeeLegend from '@optx/components/feature/charts/EmployeeLegend';
import LinearChart from '@optx/components/feature/charts/LinearChart';
import FixedLoader from '@optx/components/common/loader';

interface CardPannelGraphsProps {
  companyProfile: CompanyProfile;
  shouldUpdate: boolean | undefined;
}

const CardPannelGraphs: FC<CardPannelGraphsProps> = ({ companyProfile, shouldUpdate }) => {
  const employeeGraphData = useSelector(graphSelectors.employee.getEmployeeGrowthData);
  const scoreGraphData = useSelector(graphSelectors.score.getScoreGrowthData);
  const getPSGDate = useSelector(graphSelectors.score.getPSGDate);
  const isLoadingProfile = useSelector(cardSelectors.isLoading);
  const isLoadingEmployee = useSelector(graphSelectors.employee.isLoading);
  const isLoadingOPTX = useSelector(graphSelectors.score.isLoading);
  const defaultOptxScore = useSelector(userSelectors.getDefaultScore);

  return (
    <>
      <Row>
        <Col style={{ width: '100%', position: 'relative' }}>
          {!isLoadingEmployee && !isLoadingProfile && (
            <LinearCombinedChart
              data={employeeGraphData}
              dataKey="count_emp"
              dataSecondaryKey="count_job"
              labelKey="position"
              title="Employee Count"
              kpiLabel="%Growth"
              className="profile-chart employee-chart"
              legend={<EmployeeLegend />}
            />
          )}

          <FixedLoader loading={isLoadingEmployee} isAbsolute />
        </Col>
      </Row>
      <Row>
        <Col style={{ width: '100%' }}>
          {!isLoadingOPTX &&
            !isLoadingProfile &&
            (defaultOptxScore !== 'il' ? (
              <LinearChart
                data={scoreGraphData}
                dataKey="score_value"
                labelKey="position"
                title="OPTX Score"
                kpiLabel="OPTX Score"
                headerValue={companyProfile.score}
                isTrending
                className="profile-chart score-chart"
                psgDate={getPSGDate}
                isPSGCompany={companyProfile.is_psg_company}
                isExtension
              />
            ) : (
              <LinearChart
                data={scoreGraphData}
                dataKey="score_value"
                labelKey="position"
                title="IL OPTX Score"
                kpiLabel="IL OPTX Score"
                headerValue={companyProfile.il_optx_score}
                isTrending
                psgDate={getPSGDate}
                className="profile-chart score-chart"
                isPSGCompany={companyProfile.is_psg_company}
                isExtension
              />
            ))}
          <FixedLoader loading={isLoadingOPTX} isAbsolute />
        </Col>
      </Row>
    </>
  );
};

export default CardPannelGraphs;
