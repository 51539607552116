import styled from 'styled-components';

const FeedbackMechanismWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  min-height: 25px;
  gap: 7px;
  margin-left: 5px;

  * {
    color: #d9d9d9;
  }

  .thumbs-down {
    position: relative;
    top: 3px;
    width: 22px;
    height: 20px;

    .active path {
      fill: #fa541c;
    }
  }
  .thumbs-up {
    position: relative;
    width: 22px;
    height: 20px;

    .active path {
      fill: #52c41a;
    }
  }
  .reload {
    position: relative;
    width: 16px;
    height: 16px;
  }
`;

export { FeedbackMechanismWrapper };
