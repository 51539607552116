import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import reducer, { sagas as saga } from '../../../redux/user/source-scrub-lists';

const useInjectSourceScrubLists = () => {
  useInjectReducer({
    key: 'sourceScrubLists',
    reducer,
  });
  useInjectSaga({
    key: 'sourceScrubLists',
    saga,
  });
};

export default useInjectSourceScrubLists;
