import React from 'react';
import Styled from './styled';

const EmptyChart: React.FC = ({ children = 'No Data' }) => {
  return (
    <Styled.Wrapper>
      <Styled.Button size="middle">{children}</Styled.Button>
    </Styled.Wrapper>
  );
};

export default EmptyChart;
