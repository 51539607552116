import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import queryString from 'query-string';
// models
import { CompanyImport } from '@optx/models/Company';
import { UserInformation } from '@optx/models/user';
import { SourceScrubList } from '@models/SourceScrubList';
import { CompanyWatchList } from '@models/WatchList';
// redux
import { reducers as fetchReducers } from '@redux/feature/fetch';
import { actions as UserInformationActions } from '@redux/user/information';

import { ListState } from './interfaces';
import * as actions from './actions';
import {
  clearSearchSuccess as clearSearchSuccessSearchActions,
  resetSearchSuccess as resetSearchSuccessSearchActions,
} from '@features/grid/search/state/actions';

const initialState: ListState = {
  // current list identifier
  showListId: '',
  // should search based on current list id
  showListContent: false,
  filterList: {
    // current filtered list identifier
    id: '',
    // apply filters only for company in list
    filter: false,
  },
  isSourceScrub: false,
  loading: false,
  error: '',
  data: undefined,
};

const loadCompanyListSuccessReducer: CaseReducer<
  ListState,
  PayloadAction<{ id: string; isSourceScrub: boolean }>
> = (draftState, action) => {
  const listId = action.payload.id;
  draftState.showListId = listId;
  draftState.showListContent = true;
  draftState.filterList = {
    id: listId,
    filter: true,
  };
  draftState.isSourceScrub = action.payload.isSourceScrub;
};

const filterCompanyListReducer: CaseReducer<ListState, PayloadAction<string>> = (
  draftState,
  action
) => {
  const listId = action.payload;
  draftState.showListId = '';
  draftState.showListContent = false;
  draftState.filterList = {
    id: listId,
    filter: true,
  };
};

const clearCompanyListReducer: CaseReducer<ListState> = draftState => {
  draftState.showListId = '';
  draftState.showListContent = false;
  draftState.filterList = {
    id: '',
    filter: false,
  };
  draftState.isSourceScrub = false;
};

const filterCSVCompanyErrorListReducer: CaseReducer<
  ListState,
  PayloadAction<{ data: CompanyImport; title: string }>
> = (draftState, action) => {
  draftState.CSVImportCompanies = action.payload;
};

// external reducers

interface ListFilters {
  saved_list_id?: number;
  ss_list_id?: number;
}

const fetchUserInformationSuccessReducer: CaseReducer<ListState, PayloadAction<UserInformation>> = (
  draftState,
  action
) => {
  if (action.payload.settings.session_settings) {
    const { company_filters: companyFilters } = action.payload.settings.session_settings;

    if (companyFilters) {
      const isSimpleList = companyFilters.includes('saved_list_id');
      const isSourceScrubList = companyFilters.includes('ss_list_id');

      if (isSimpleList || isSourceScrubList) {
        const filters = queryString.parse(companyFilters) as ListFilters;
        const listId = String((filters.saved_list_id || filters.ss_list_id) as number);
        draftState.showListId = listId;
        draftState.showListContent = true;
        draftState.filterList = {
          id: listId,
          filter: true,
        };
        draftState.isSourceScrub = isSourceScrubList;
      }
    }
  }
};

const loadCompanyListDetailsSuccessReducer: CaseReducer<
  ListState,
  PayloadAction<SourceScrubList | CompanyWatchList>
> = (draftState, action) => {
  draftState.loading = false;
  draftState.data = action.payload;
};

const resetLoadingState: CaseReducer<ListState> = draftState => {
  draftState.loading = false;
};

const resetStateReducer: CaseReducer<ListState> = () => {
  return initialState;
};

export const reducer = createReducer<ListState>(initialState, builder =>
  builder
    .addCase(actions.loadCompanyList, fetchReducers.fetchReducer)
    .addCase(actions.loadCompanyListSuccess, loadCompanyListSuccessReducer)
    .addCase(actions.filterCompanyList, filterCompanyListReducer)
    .addCase(actions.clearCompanyList, clearCompanyListReducer)
    .addCase(actions.filterCSVCompanyErrorList, filterCSVCompanyErrorListReducer)
    .addCase(actions.loadCompanyListDetails, fetchReducers.fetchReducer)
    .addCase(actions.loadCompanyListDetailsSuccess, loadCompanyListDetailsSuccessReducer)
    .addCase(actions.loadCompanyListDetailsFailure, fetchReducers.fetchFailReducer)
    .addCase(actions.loadCompanyListDetailsReset, resetStateReducer)
    .addCase(actions.resetLoadingState, resetLoadingState)
    // External actions
    .addCase(clearSearchSuccessSearchActions, clearCompanyListReducer)
    .addCase(resetSearchSuccessSearchActions, clearCompanyListReducer)
    .addCase(UserInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
);

export default reducer;
