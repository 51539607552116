import React, { useCallback } from 'react';
// models
import { CompanyNotification } from '@models/Company';
import { PopoverProps } from 'antd/lib/popover';
// redux
import { useDispatch } from 'react-redux';
import { actions } from '@redux/company/notes/search';
// components
import EmptyPopover from '../common/popover/EmptyPopover';
import EditNoteContent from './EditNoteContent';

interface PopoverEditNote extends PopoverProps {
  isOpen: boolean;
  toggle: () => void;
  record: CompanyNotification;
  /**
   * close popover automatically after saving company to list.
   */
  autoClose?: boolean;
}

const PopoverEditNote: React.FC<PopoverEditNote> = ({
  isOpen,
  toggle,

  autoClose,
  record,
  ...restProps
}) => {
  // local state
  const dispatch = useDispatch();
  const handleToggle = useCallback(
    (manualClose?: boolean) => {
      if (!manualClose || autoClose) {
        toggle();
      }
    },
    [toggle, autoClose]
  );

  const updateNote = useCallback(
    (text: string) => dispatch(actions.updateNote(record, text)),
    [dispatch, record]
  );

  return (
    <EmptyPopover
      placement="topLeft"
      content={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <EditNoteContent
          handleToggle={() => handleToggle()}
          noteText={record && record.note_text}
          onNoteSave={noteText => {
            updateNote(noteText);
            handleToggle();
          }}
        />
      }
      visible={isOpen}
      overlayClassName="edit-note-popover"
      onVisibleChange={handleToggle}
      {...restProps}
    />
  );
};

PopoverEditNote.defaultProps = {
  isOpen: false,
  toggle: () => {},
  autoClose: true,
};

export default PopoverEditNote;
