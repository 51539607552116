import React from 'react';
import { Space } from 'antd';
// components
import { MainHeader } from '@components/common/layout';

const Header = () => {
  return (
    <MainHeader className="main-header fixed">
      <div className="search-header-content" style={{ height: '100%' }}>
        <Space size={4} style={{ marginLeft: 'auto' }} />
      </div>
    </MainHeader>
  );
};

export default Header;
