import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Redirect, RouteProps, matchPath } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
// core
import { defaultTheme } from '@optx/core/theme';
// constants
import appRoutes from '@constants/routes';
import { NOTES_ENABLED } from '@constants/notes';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';
import { useInject } from './useInject';
// components
import Notes from '@components/pages/Notes';
import Loader from '@components/feature/loader/GlobalLoader';
import { CustomGlobalLoader } from '@features/custom-global-loader/components';
import CompanyProfile from '@components/pages/CompanyProfile';
import Login from '@optx/components/pages/Login/Login';
import CompanyList from '@components/pages/CompanyList';
import MyCompanies from '@components/pages/MyCompanies';
import Contacts from '@components/pages/Contacts';
import AddToEquityTouch from '@components/pages/EquityTouch';
import Salesloft from '@components/pages/SalesLoft/Salesloft';
import UserManagement from '@components/pages/UserManagement';
import AnalystsLeaderboard from '@components/pages/AnalystsLeaderboard';
import UserLists from '@components/pages/UserLists';
import ErrorComponent from '@optx/components/common/ErrorComponent';
import PrivateRoute from '@optx/components/common/route/PrivateRoute';
import PublicRoute from '@optx/components/common/route/PublicRoute';
import MainLayout from '@optx/core/layout';
import HomePage from '@optx/components/pages/Home';
import SourcingOutreachSummary from './SourcingOutreachSummary';
import CompanyListSourcingOutReach from './AllReports/CompanyListSourcingOutReach';
import UserProfile from './UserProfile';
import PipelineReport from './PipelineReport';
import ChromeExtension from '../ChromeExtension/ChromeExtension';
import ScheduledTouches from './ScheduledTouches';
import EquityTouchIntegration from './EquityTouchIntegration';
import ChecklistResearchInsights from './ChecklistResearchInsights/ChecklistResearchInsights';
import EquityTouchBulk from './EquityTouchBulk';
import SalesloftBulk from './SalesloftBulk';
import AllReports from './AllReports/AllReports';
import AnalystHome from './AnalystHome';
// styles
import 'react-toastify/dist/ReactToastify.css';

const MainContent = () => {
  const { isAnalyst, advancedSearchPathname } = useIsAnalyst();

  return (
    <Switch>
      <PrivateRoute exact path={advancedSearchPathname} component={HomePage} />
      <PrivateRoute
        exact
        path={isAnalyst ? appRoutes.home : appRoutes.analystHome}
        component={AnalystHome}
      />
      <PrivateRoute path={appRoutes.myCompanies} component={MyCompanies} />
      <PrivateRoute path={appRoutes.lists.myWatchList} component={CompanyList} />
      {NOTES_ENABLED && <PrivateRoute exact path={appRoutes.notes} component={Notes} />}
      <PrivateRoute path={appRoutes.userLists.list} component={UserLists} />
      <PrivateRoute path={appRoutes.userLists.sourceScrubLists} component={UserLists} />
      <PrivateRoute exact path={appRoutes.scheduledTouches} component={ScheduledTouches} />
      <PrivateRoute exact path="/profile/:id" component={CompanyProfile} />
      <PrivateRoute exact path={appRoutes.user.profile} component={UserProfile} />
      <PrivateRoute exact path={appRoutes.contacts} component={Contacts} />
      <PrivateRoute exact path={appRoutes.admin.users} component={UserManagement} />
      <PrivateRoute exact path={appRoutes.reports.allReports} component={AllReports} />
      <PrivateRoute
        exact
        path={appRoutes.reports.equityTouchIntegration}
        component={EquityTouchIntegration}
      />
      <PrivateRoute path={appRoutes.reports.pipelineReport} component={PipelineReport} />
      <PrivateRoute
        path={appRoutes.reports.checklistResearchInsights}
        component={ChecklistResearchInsights}
      />
      <PrivateRoute exact path={appRoutes.addToEquityTouch} component={AddToEquityTouch} />
      <PrivateRoute
        path={appRoutes.reports.sourcingOutreachSummary}
        component={SourcingOutreachSummary}
      />
      <PrivateRoute
        path={appRoutes.reports.sourcingOutreachSummaryCompanies}
        component={CompanyListSourcingOutReach}
      />
      <PrivateRoute exact path={appRoutes.chromeExtension} component={ChromeExtension} />
      <PrivateRoute exact path="/equity-touch-bulk/step-:step/:grid" component={EquityTouchBulk} />
      <PrivateRoute exact path="/salesloft-bulk/:grid" component={SalesloftBulk} />
      <PrivateRoute path={appRoutes.addToSalesloft} component={Salesloft} />
      <PrivateRoute
        path={appRoutes.reports.sourcingAnalystsLeaderboard}
        component={AnalystsLeaderboard}
      />
      <PrivateRoute component={ErrorComponent} />

      <PublicRoute
        path=""
        component={() => null}
        render={() => <Redirect to={appRoutes.login} />}
      />
    </Switch>
  );
};

const headContent = (
  <>
    <Helmet titleTemplate="OPTX Dashboard" defaultTitle="OPTX Dashboard">
      <meta name="description" content="OPTX Dashboard application" />
    </Helmet>
    <ToastContainer autoClose={3000} position={toast.POSITION.TOP_CENTER} theme="light" />
    <Loader />
    <CustomGlobalLoader />
  </>
);

const App = () => {
  useInject();

  return (
    <ThemeProvider theme={defaultTheme}>
      {headContent}
      <Switch>
        <PublicRoute path={appRoutes.login} component={Login} />
        <PrivateRoute
          component={(props: RouteProps) => {
            return (
              <MainLayout
                extensionLayout={
                  matchPath(props.location?.pathname as string, '/chrome-extension-new')?.isExact
                }
              >
                {<MainContent />}
              </MainLayout>
            );
          }}
        />
      </Switch>
    </ThemeProvider>
  );
};

export default App;
