import { createAction } from '@reduxjs/toolkit';
// models
import {
  TOGGLE_ADD_RESEARCH_RATIONALE,
  TOGGLE_ADD_RESEARCH_RATIONALE_SUCCESS,
  CHANGE_FIELDS,
  CHANGE_RATIONALE,
  REVERT_FIELDS_CHANGES,
  SUBMIT_RESEARCH_RATIONALE,
  SUBMIT_RESEARCH_RATIONALE_SUCCESS,
} from './actionsTypes';
import {
  AddResearchRationale,
  AddResearchRationaleSuccess,
  ChangeFieldsPayload,
  SaveResearchRationaleResponse,
} from './interfaces';

export const toggleAddResearchRationale = createAction<AddResearchRationale>(
  TOGGLE_ADD_RESEARCH_RATIONALE
);

export const toggleAddResearchRationaleSuccess = createAction<AddResearchRationaleSuccess>(
  TOGGLE_ADD_RESEARCH_RATIONALE_SUCCESS
);

export const changeFields = createAction<ChangeFieldsPayload>(CHANGE_FIELDS);
export const changeRationale = createAction<ChangeFieldsPayload>(CHANGE_RATIONALE);

export const revertFieldsChanges = createAction<ChangeFieldsPayload>(REVERT_FIELDS_CHANGES);

export const submitResearchRationale = createAction(SUBMIT_RESEARCH_RATIONALE);
export const submitResearchRationaleSuccess = createAction<SaveResearchRationaleResponse>(
  SUBMIT_RESEARCH_RATIONALE_SUCCESS
);
