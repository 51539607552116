import React from 'react';
import { RadioChangeEvent } from 'antd/lib/radio';
import { Col, Radio, Row, Typography } from 'antd';
import { useSelector } from 'react-redux';
// models
import { AppState } from '@optx/redux/interfaces';
//utils
import { getRange } from '@optx/utils/researchRationale';
// redux
import { getFormRationales } from '@optx/redux/company/filters/selectors';
import { selectors } from '@features/add-research-rationale/state';

// locals
import { OPTX_SCORE_RATIONALE_KEY } from '../../constants';
import * as Styled from '../AddResearchRationale.styles';

interface ScoreRationaleProps {
  handleRationaleChange: (e: RadioChangeEvent) => void;
  normalizedScore: number | 'N/A';
}

const ScoreRationale: React.FC<ScoreRationaleProps> = ({
  handleRationaleChange,
  normalizedScore,
}) => {
  const rationaleOptions = useSelector((state: AppState) =>
    getFormRationales(state, OPTX_SCORE_RATIONALE_KEY)
  );
  const rationale = useSelector(selectors.getRationale);

  const disabledRange = normalizedScore === 'N/A' ? 'none' : getRange(normalizedScore);

  const isChromePlugin = window.location.pathname.startsWith('/chrome-extension');

  return (
    <Styled.RationalesWrapper>
      <Row>
        <Typography.Text
          style={{
            marginLeft: '15px',
            position: 'relative',
            bottom: '10px',
            color: 'rgb(0,0,0,0.45)',
          }}
        >
          What score would you assign?
        </Typography.Text>
      </Row>
      <Row>
        <Styled.CustomRadioGroup
          buttonStyle="solid"
          isChromePlugin={isChromePlugin}
          onChange={handleRationaleChange}
          value={rationale.optx_score_rationales}
          columns={rationaleOptions?.length || 0}
        >
          {rationaleOptions?.map(option => {
            return (
              <Col key={option.value} style={{ textAlign: 'center', marginTop: 10 }}>
                <Radio value={option.value} disabled={option.value === disabledRange}>
                  {option.label}
                </Radio>
              </Col>
            );
          })}
        </Styled.CustomRadioGroup>
      </Row>
    </Styled.RationalesWrapper>
  );
};

export default ScoreRationale;
