import React from 'react';
import Styles from './StatsSection.styles';

interface StatsSectionProps {
  title: string;
  icon: React.ReactNode;
}

const StatsSection: React.FC<StatsSectionProps> = ({ title, icon, children }) => {
  return (
    <Styles.Section>
      <Styles.Banner />
      <Styles.Body>
        <Styles.BodyContent>
          <Styles.Title>
            {icon} {title}
          </Styles.Title>
          {children}
        </Styles.BodyContent>
      </Styles.Body>
    </Styles.Section>
  );
};

export default StatsSection;
