import React, { useState, useEffect } from 'react';
import { Dictionary } from 'lodash';
import { Select } from 'formik-antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
// models
import { EquityTouchDatabaseMatch, EquityTouchFetchedField } from '@models/equityTouch';
import { SelectOption } from '@models/Option';
// utils
import { filterOption } from '@utils/select/select';
// redux
import { selectors } from '@redux/company/equity-touch';
// components
import { EquityTouchGroupsFieldContent } from './EquityTouchGroupsFieldContent';

interface EquityTouchGroupsFieldProps {
  dealTeamLead: any;
  initialFormData: Dictionary<EquityTouchFetchedField>;
}

const { Option } = Select;

const StyledSelect = styled(Select)`
  .ant-select-selection-item {
    gap: 5px;
  }
`;

export const EquityTouchGroupsField: React.FC<EquityTouchGroupsFieldProps> = ({
  dealTeamLead,
  initialFormData,
}) => {
  const [groups, setGroups] = useState<Array<EquityTouchDatabaseMatch | SelectOption>>([]);
  const [value, setValue] = useState<number[]>([]);
  const formValues = useSelector(selectors.getFormValues);

  useEffect(() => {
    const selectedDealTeamLead = initialFormData['Deal Team Lead'].received_values.find(
      val => (val as EquityTouchDatabaseMatch).itemId === dealTeamLead
    );

    if (selectedDealTeamLead) {
      setGroups((selectedDealTeamLead as EquityTouchDatabaseMatch).groups!);
    } else {
      setGroups([]);
    }

    if (initialFormData.Groups.from_database) {
      setValue((initialFormData.Groups.from_database as string[]).map(item => Number(item)));
    } else if ((formValues?.Groups as number[])?.length) {
      setValue(formValues.Groups as number[]);
    } else {
      setValue([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealTeamLead, formValues.Groups, initialFormData]);

  const handleChange = (value: number[]) => {
    setValue(value);
  };

  return (
    <StyledSelect
      name="Groups"
      mode="multiple"
      disabled={!(groups.length > 0)}
      placeholder="Please select Groups/Watchlists"
      value={value}
      onChange={handleChange}
      filterOption={filterOption}
      dropdownMatchSelectWidth={200}
    >
      {groups.map((group, index) => (
        <Option value={(group as EquityTouchDatabaseMatch).itemId} key={index}>
          <EquityTouchGroupsFieldContent group={group as EquityTouchDatabaseMatch} />
        </Option>
      ))}
    </StyledSelect>
  );
};
