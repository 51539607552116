// search
export const SEARCH_CONTACTS = 'contacts/search/SEARCH_CONTACTS';
export const SEARCH_CONTACTS_SUCCESS = 'contacts/search/SEARCH_CONTACTS_SUCCESS';
export const SEARCH_CONTACTS_FAIL = 'contacts/search/SEARCH_CONTACTS_FAIL';
// reset
export const RESET_SEARCH_CONTACTS = 'contacts/search/RESET_SEARCH_CONTACTS';
export const CLEAR_SEARCH_CONTACTS = 'contacts/search/CLEAR_SEARCH_CONTACTS';
// reset to
export const RESET_SEARCH_CONTACTS_TO = 'contacts/search/RESET_SEARCH_CONTACTS_TO';
export const REMOVE_FILTER = 'contacts/search/REMOVE_FILTER';
