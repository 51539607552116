import React from 'react';
import { Typography } from 'antd';

import { CommonProps } from 'react-select';
import { SingleSelectProps } from '../interfaces';
import { SelectOption } from '@optx/models/Option';

interface SingleValueContainerProps extends CommonProps<SelectOption> {
  data: SelectOption;
}

const SingleValueContainer: React.FC<SingleValueContainerProps> = props => {
  const { selectProps, data } = props;

  const { placeholder } = selectProps as SingleSelectProps;

  const valueSelected = data.label;

  return (
    <Typography.Text ellipsis>
      {placeholder}: <Typography.Text style={{ color: '#1890FF' }}>{valueSelected}</Typography.Text>
    </Typography.Text>
  );
};

export default SingleValueContainer;
