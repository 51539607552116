import queryString from 'query-string';
import { createSelector } from 'reselect';
import { CompanyWatchList } from '@optx/models/WatchList';
// constants
import { FAVORITE_LIST_ID } from '@constants/lists';
// redux
import { selectors as favoriteListsSelectors } from '@redux/favorite-lists/lists';
import { selectors as userListsSelectors } from '@redux/lists/details';
import { selectors as sourceScrubListsSelectors } from '@redux/user/source-scrub-lists';

export const getSelectedListName = createSelector(
  userListsSelectors.getRouteListId,
  favoriteListsSelectors.getLists,
  sourceScrubListsSelectors.getSourceScrubLists,
  userListsSelectors.isSourceScrubRoute,
  userListsSelectors.getListData,
  (id, savedSearchesList, sourceScrubList, isSourceScrub, listData) => {
    if (id) {
      const { title } = queryString.parse(window.location.search);

      if (title) return title;
      if (id === FAVORITE_LIST_ID) return 'My Favorites List';

      if (isSourceScrub) {
        const list = sourceScrubList.find(item => item.id.toString() === id);
        if (list) return list.title;

        return '';
      }

      if (listData) return listData.title;

      // List can be undefined when list id is loaded from user information filters on page refresh and
      // the lists are not yet loaded.
      const list = savedSearchesList[id] as CompanyWatchList | undefined;
      if (list) return list.title;
    }

    return '';
  }
);
