import React from 'react';
import { TooltipProps } from 'recharts';
// models
import { ChartBarItem } from '@optx/models/charts/data';
// constants
import { NUMBER_FORMAT } from '@optx/constants/format/number';
// utils
import { roundNumber } from '@optx/utils/number';

interface BarChartTooltipProps extends TooltipProps {
  data: ChartBarItem[];
}

const BarChartTooltip: React.FC<BarChartTooltipProps> = ({ label, data }) => {
  if (!data.length) {
    return null;
  }

  const tooltipData = data.find(item => item.x_axis === label);

  return (
    <>
      {tooltipData?.round_data &&
        tooltipData?.round_data.map((data, index) => (
          <div key={index} className="profile-chart-card__tooltip">
            <div>Amount: {data.amount ? roundNumber(data.amount, `$${NUMBER_FORMAT}`) : 'N/A'}</div>
            <div>Investors: {data.investors ? data.investors.join('; ') : 'N/A'}</div>
            <div>Round name: {data.round_name || 'N/A'}</div>
          </div>
        ))}
    </>
  );
};

export default BarChartTooltip;
