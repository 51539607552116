import React, { useRef, useEffect } from 'react';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import { Button, Typography, Input } from 'antd';
import { useField } from 'formik';
import { Form, Checkbox } from 'formik-antd';
import { Dictionary, pickBy } from 'lodash';
// models
import { ViewOption } from '@optx/models/search';
// components
import Popconfirm from '@optx/shared/view/molecules/Popconfirm/Popconfirm';
import Styled from './SaveSearch.style';

const { Text } = Typography;

const handleValidateStatus = (
  errorType: boolean,
  isSearchNameExists: boolean,
  view?: ViewOption
): ValidateStatus => {
  if ((!errorType || isSearchNameExists) && !view) {
    return 'error';
  }

  return 'success';
};

interface SaveSearchFormProps {
  view: ViewOption | undefined;
  savedListTitles: Dictionary<boolean>;
  areOptionsDisabled: boolean;
  errors: {
    title?: string;
    filters?: string;
    sorting?: string;
    columns?: string;
    isOverwritten?: string;
  };
  handletoggleModal: () => void;
  handleDropdownKeyEvent: (event: React.KeyboardEvent<HTMLFormElement>) => void;
  handleDelete: () => void;
  handleSetSaveBtnDisabled: (state: boolean) => void;
}

const SaveSearchForm: React.FC<SaveSearchFormProps> = ({
  view,
  savedListTitles,
  errors,
  areOptionsDisabled,
  handletoggleModal,
  handleDropdownKeyEvent,
  handleDelete,
  handleSetSaveBtnDisabled,
}) => {
  const isSearchNameExists = useRef<boolean>(false);
  const isSearchNameOverwritten = useRef<boolean>(false);
  const [, , helpersIsOverwritten] = useField('isOverwritten');
  const [, , helpersHasSameName] = useField('hasSameName');
  const [title, , helpersTitle] = useField('title');

  const toggleModal = () => {
    handletoggleModal();
    isSearchNameExists.current = false;
    isSearchNameOverwritten.current = false;
  };

  const handleEqualsSavedSearchNames = (element: string) => {
    const searchTitles = pickBy(savedListTitles, (value, key) => key === element);

    const searchNameExistsInTitles = Object.keys(searchTitles).some(
      title => title.trim() === element.trim()
    );

    isSearchNameExists.current = searchNameExistsInTitles;
    isSearchNameOverwritten.current = searchNameExistsInTitles;
    helpersIsOverwritten.setValue(!searchNameExistsInTitles);
    helpersHasSameName.setValue(searchNameExistsInTitles);
    helpersTitle.setValue(element);
  };

  useEffect(() => {
    handleSetSaveBtnDisabled(isSearchNameExists.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchNameExists.current]);

  return (
    <Styled.FormWrapper layout="vertical" onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
      <div className="ant-form-item">
        <div className="ant-form-item-label">
          {view ? (
            <Text>Saved Search Name</Text>
          ) : (
            <Text>Please give your search a unique name</Text>
          )}
        </div>
        <Input
          onChange={(element: React.ChangeEvent<HTMLInputElement>) =>
            handleEqualsSavedSearchNames(element.target.value)
          }
          value={title.value}
          className={handleValidateStatus(!errors.title, isSearchNameExists.current, view)}
        />
        {isSearchNameExists.current && !view ? (
          <Text type="secondary">This name already exists</Text>
        ) : (
          <Text type="secondary">unique name</Text>
        )}
        {errors.title && (
          <div className="ant-form-item-explain ant-form-item-explain-error">{errors.title}</div>
        )}

        {isSearchNameOverwritten.current && !view && (
          <div style={{ marginTop: '1rem' }}>
            {errors.isOverwritten && (
              <div className="ant-form-item-explain ant-form-item-explain-error">
                {errors.isOverwritten}
              </div>
            )}
            <Checkbox
              onChange={element => (isSearchNameExists.current = !element.target.checked)}
              name="isOverwritten"
            >
              Overwrite search name and settings
            </Checkbox>
          </div>
        )}
      </div>

      <Text>Include the following:</Text>
      <div className="view-checkboxes__wrapper">
        <Form.Item name="filters">
          <Checkbox name="filters" disabled={areOptionsDisabled}>
            Filters
          </Checkbox>
        </Form.Item>
        <Form.Item name="sorting">
          <Checkbox name="sorting" disabled={areOptionsDisabled}>
            Sort Order
          </Checkbox>
        </Form.Item>
        <Form.Item name="columns">
          <Checkbox name="columns" disabled={areOptionsDisabled}>
            Columns Shown
          </Checkbox>
        </Form.Item>
        <Form.Item name="pinnedColumns">
          <Checkbox name="pinnedColumns" disabled={areOptionsDisabled}>
            Pinned Columns
          </Checkbox>
        </Form.Item>
        {(errors.filters || errors.sorting || errors.columns) && (
          <Text type="danger">Please select at least one option from above</Text>
        )}
        <div className={`view-checkboxes__default ${view ? 'is-editing' : ''}`}>
          {view && (
            <Popconfirm
              action="Delete"
              title={view && view.title}
              onConfirm={() => handleDelete && handleDelete()}
              onCancel={toggleModal}
              disabled={areOptionsDisabled}
            >
              <Button disabled={areOptionsDisabled}>Delete Search</Button>
            </Popconfirm>
          )}
          <Form.Item name="isDefault">
            <Checkbox name="isDefault" disabled={areOptionsDisabled && view?.is_default}>
              Make My Default Search
            </Checkbox>
          </Form.Item>
        </div>
      </div>
    </Styled.FormWrapper>
  );
};

export default React.memo(SaveSearchForm);
