import styled from 'styled-components';

const ChartsWrapper = styled.div`
  display: flex;
`;

const SelectedPeriodWrapper = styled.div<{ fillContent: boolean }>`
  width: ${props => (props.fillContent ? '100%' : 'calc(50% + 70px)')};
`;

const PreviousPeriodWrapper = styled.div`
  width: calc(50% - 70px);
`;

export const Styled = {
  ChartsWrapper,
  SelectedPeriodWrapper,
  PreviousPeriodWrapper,
};
