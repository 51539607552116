import React from 'react';
import { Button } from 'antd';

interface DrawerHeaderProps {
  onApply?: VoidFunction;
}

export const DrawerHeader: React.FC<DrawerHeaderProps> = ({ onApply }) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: 100,
        }}
      >
        Show / Hide / Reorder Columns
      </div>
      <Button type="primary" onClick={onApply}>
        Apply
      </Button>
    </>
  );
};
