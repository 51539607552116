import React, { Context, useContext } from 'react';
import { Typography } from 'antd';
import { components, IndicatorProps, ValueContainerProps } from 'react-select';
import AsyncSelect from 'react-select/async';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
// models
import { SuccessErrorCallback } from '@models/callback';
// constants
import { GET_ET_COMPANIES_ENDPOINT } from '@constants/asyncEndpoints';
// hooks
import useAsyncSearch from '@hooks/select/useAsyncSearch';
// components
import { BulkEditFieldContext } from '@optx/models/bulkActions';
import Styled from './EditAddon.style';

/**
 * customValueContainer - style the search area of the dropdown.
 */
const ValueContainer: React.FC<ValueContainerProps<any>> = ({ children, ...restProps }) => (
  <components.ValueContainer {...restProps}>
    <span className="multiselect-select__value-container__icon">
      <SearchOutlined />
    </span>
    {children}
  </components.ValueContainer>
);

/**
 * customDropdownIndicator - style the arrow of the dropdown.
 */
const DropdownIndicator: React.FC<IndicatorProps<any>> = props => (
  <components.DropdownIndicator {...props}>
    <DownOutlined />
  </components.DropdownIndicator>
);

interface EditAddonProps {
  context: Context<BulkEditFieldContext>;
}

export const EditAddon: React.FC<EditAddonProps> = ({ context }) => {
  const defaultOption = [{ value: '', label: 'None' }];

  const { setSelectedData } = useContext(context);

  const { loadOptions } = useAsyncSearch({ endpoint: GET_ET_COMPANIES_ENDPOINT });

  const handleChange = (value: any) => {
    setSelectedData([value]);
  };

  const getLoadOptionsDelay = (query: string, callback: SuccessErrorCallback) => {
    if (query.length > 2) {
      loadOptions(query, options => callback(options));
    }
  };

  return (
    <>
      <Typography.Text className="ant-modal-title">Select New Add-on</Typography.Text>
      <Styled.AsyncWrapper>
        <AsyncSelect
          cacheOptions
          loadOptions={getLoadOptionsDelay}
          onChange={handleChange}
          defaultOptions={defaultOption}
          classNamePrefix="multiselect-select"
          components={{
            ValueContainer,
            DropdownIndicator,
          }}
          menuIsOpen
          closeMenuOnSelect={false}
          maxMenuHeight={200}
        />
      </Styled.AsyncWrapper>
    </>
  );
};
