import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Tooltip } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
// redux
import { actions as savedSearchesActions } from '../../../../redux/company/saved-searches';

const ClearSearchButton = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(savedSearchesActions.fetchSavedSearches(''));
  };

  return (
    <Tooltip title="Clear Search">
      <Button type="text" onClick={handleClick} icon={<CloseCircleOutlined />} />
    </Tooltip>
  );
};

export default ClearSearchButton;
