import React from 'react';
import { useSelector } from 'react-redux';
// components
import LegendCard from '@optx/components/common/legend-card';
// Local
// utils
import { getTopTouchLabel } from '../../utils/top-touches';
// redux
import { selectors } from '../../state';
// components
import { SecondaryTitle, TypographyTitle } from '../styled';
import Styles from './TopTouches.styles';

const TopTouchesLegend = () => {
  const topTouches = useSelector(selectors.touchesAcrossRegion.getTopTouches);

  return (
    <Styles.Wrapper>
      <Styles.Head>
        <TypographyTitle level={4}>Top 5 Touch Regions</TypographyTitle>
        <SecondaryTitle>Regions with the most touches</SecondaryTitle>
      </Styles.Head>
      <Styles.Body>
        {topTouches.length ? (
          <Styles.OrderedList>
            {topTouches.map((touch, index) => {
              return (
                <Styles.OrderedListItem key={index}>
                  <LegendCard text={getTopTouchLabel(touch)} info={touch.total} />
                </Styles.OrderedListItem>
              );
            })}
          </Styles.OrderedList>
        ) : (
          <>No touches</>
        )}
      </Styles.Body>
    </Styles.Wrapper>
  );
};

export default TopTouchesLegend;
