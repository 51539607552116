import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import reducer, { saga } from '../../../redux/company/files';

const useInjectFiles = () => {
  useInjectReducer({
    key: 'files',
    reducer,
  });
  useInjectSaga({
    key: 'files',
    saga,
  });
};

export default useInjectFiles;
