import React from 'react';
import moment from 'moment';
import { TooltipProps } from 'recharts';
import globalConfig from '@optx/constants/config';

interface LineChartTooltipProps extends TooltipProps {
  primaryKey: string;
  secondaryKey?: string;
  primaryLabel: string;
  secondaryLabel?: string;
}

const LineChartTooltip: React.FC<LineChartTooltipProps> = ({
  primaryKey,
  secondaryKey,
  primaryLabel,
  secondaryLabel,
  payload,
}) => {
  if (!payload || !payload!.length) {
    return null;
  }

  const data = payload![0].payload;
  const date = data.tooltipDate || data.date;

  return (
    <div className="profile-chart-card__tooltip">
      <div>{moment(date).format(globalConfig.grid_date.DATE_FORMAT)}</div>
      {data[primaryKey] !== null && (
        <div>
          {primaryLabel} {data[primaryKey]}
        </div>
      )}
      {secondaryKey && data[secondaryKey] !== undefined && (
        <div>
          {secondaryLabel} {data[secondaryKey]}
        </div>
      )}
    </div>
  );
};

export default LineChartTooltip;
