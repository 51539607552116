import { createSelector } from 'reselect';
import { AppState } from '@optx/redux/interfaces';
import { parseBarData } from '@optx/utils/charts';

const selectCompanyFundingRounds = (state: AppState) => state.companyFundingRounds;

export const getTotalRaised = createSelector(selectCompanyFundingRounds, state =>
  state.data.total_raised === null ? 'Unknown' : state.data.total_raised
);

export const getNumberOfRounds = createSelector(
  selectCompanyFundingRounds,
  state => state.data.number_of_rounds
);

export const getTotalInvestors = createSelector(
  selectCompanyFundingRounds,
  state => state.data.total_investors
);

export const getLastRound = createSelector(
  selectCompanyFundingRounds,
  state => state.data.last_round
);

export const getSecondToLastRoundDate = createSelector(selectCompanyFundingRounds, state => {
  if (state.data.investment_history.length < 2) {
    return null;
  }

  return [...state.data.investment_history.map(round => round.date)].sort((a, b) =>
    b.localeCompare(a)
  )[1];
});

export const getInvestmentHistory = createSelector(
  selectCompanyFundingRounds,
  state => state.data.investment_history
);

export const getFundingRoundsGraph = createSelector(selectCompanyFundingRounds, state =>
  parseBarData(state.data.funding_rounds_graph)
);

export const isLoading = createSelector(selectCompanyFundingRounds, state => state.loading);

export const getFundingTypes = createSelector(
  selectCompanyFundingRounds,
  state => state.data.funding_types
);

export const getFundingGraphMessage = createSelector(
  selectCompanyFundingRounds,
  state => state.data.graph_message
);
