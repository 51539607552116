import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { FilterSource } from '@optx/models/filters';
import * as actions from './actions';
import { ContactsFiltersState } from './interfaces';
import { fetchReducer, fetchFailReducer, fetchSuccessReducer } from '../../feature/fetch/reducers';
import { normalizeFiltersByColumn } from '../../../utils/filters/normalize';

// initial state
const initialState: ContactsFiltersState = {
  data: [],
  loading: false,
  error: '',
  fetchedAt: '',
  filters: {},
  preselected: {},
};

// fetch
const fetchFiltersSuccessReducer: CaseReducer<
  ContactsFiltersState,
  PayloadAction<Array<FilterSource>>
> = (draftState, action) => {
  fetchSuccessReducer(draftState, action);
  draftState.fetchedAt = new Date().toISOString();
  // @ts-ignore - same problem like on search filters
  draftState.filters = normalizeFiltersByColumn(action.payload);
};

const reducer = createReducer(initialState, builder =>
  builder
    // fetch contacts filters
    .addCase(actions.fetchFilters, fetchReducer)
    .addCase(actions.fetchFiltersSuccess, fetchFiltersSuccessReducer)
    .addCase(actions.fetchFiltersFail, fetchFailReducer)
);

export default reducer;
