import { call, put, takeLatest, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary, pickBy, size } from 'lodash';
import queryString from 'query-string';
// models
import { GetGridSearchData } from '@models/grid';
import { SearchPayload, SearchPayloadRequest } from '@models/api/contacts';
import { SuccessErrorCallback } from '@optx/models/callback';
// constants
import { BULK_EDIT_COMPANY_LIMIT_SYNCED_TO_ET } from '@optx/config/bulkConfig';
// services
import { CompanyService, SavedSearchesService } from '@services/api';
import NotificationService from '@services/NotificationService';
// utils
import { injectFilter } from '@utils/filters/injectFilter';
import { getErrorMessage } from '@optx/utils/api/errors';
import { removeTouchFilters } from '@features/grid/search/utils/filters';
// models
import { PreselectedFilter, BaseFilter } from '@optx/models/filters';
import { actions as userInformationActions } from '@redux/user/information';
import { BulkWatchlistResponse } from '@optx/features/bulk-actions/bulk-watchlist/state/interfaces';
import { mapSortQuery } from '@optx/utils/search';
import { CompanyBulkEditingResults } from '@optx/models/bulkActions';
import { SortByRule } from '@optx/models/table/sorting';
import {
  AddonManagementAdditionalFiltersResponse,
  AdditionalFiltersPayload,
  AddonManagementRamoveTagPayload,
  RemoveCompanyFromAddonManagementPayload,
  RemoveCompanyFromAddonManagementResponse,
  BulkRemoveAddon,
  BulkRemoveAddonPayload,
  AddonListItem,
  CompanyListItem,
} from '@optx/redux/company/addon-management/interfaces';
// redux
import * as companiesFilterSelectors from '@redux/company/filters/selectors';
import * as editFieldActions from '@features/bulk-actions/edit-fields/state/actions';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { selectors as filterSelectors } from '@features/grid/filter';
import { actions as searchCountActions } from '@features/grid/search-count';
import * as actions from './actions';
import * as selectors from './selectors';
import { updateFilterSettings } from '@features/grid/search/state/sagasReusable';
import { getGridSearchDataSaga } from '@features/grid/state/sagasReusable';
import { actions as searchActions, selectors as searchSelectors } from '@features/grid/search';

function* saveSortInSessionSaga() {
  const sortBy: SortByRule<any>[] = yield select(selectors.sortBy);
  const sortQuery = queryString.stringify(mapSortQuery(sortBy), {
    arrayFormat: 'comma',
  });

  yield put(
    userInformationActions.updateUserSettings({
      addon_sorting: sortQuery,
    })
  );
}

function* removeFilterTagSaga(action: PayloadAction<AddonManagementRamoveTagPayload>) {
  const tagName = action.payload.filterTag.filter;
  const tagId = action.payload.filterTag.optionId;
  const companyId = action.payload.companyId;

  if (tagName === 'keyword') {
    yield put(actions.setSearchKey(''));
  } else if (tagName === 'origin') {
    yield put(actions.resetAdditionalFilter(action.payload.filterTag));
  } else {
    const filter: Dictionary<PreselectedFilter> = yield select(filterSelectors.getFilter('addons'));
    const clearedFilters: Dictionary<PreselectedFilter> = yield select(
      filterSelectors.getClearedFilter('advancedSearch')
    );

    const normalizedFilterSources: Dictionary<BaseFilter<any>> = yield select(
      companiesFilterSelectors.getFiltersMap
    );

    let filterNew = injectFilter(normalizedFilterSources, filter, clearedFilters, tagName, tagId);

    const clearedFields = removeTouchFilters(filterNew, action.payload.filterTag);

    filterNew = { ...filterNew, ...clearedFields };

    yield put(actions.setSelectedFilters(filterNew));
  }

  yield put(
    actions.fetchCompanyAddonManagement({ companyId: companyId, shouldResetPageNumber: true })
  );
}

function* fetchAddonManagementFiltersSaga(action: PayloadAction<AdditionalFiltersPayload>) {
  const { companyId, shouldBeAddedToLoading, isBulkRemoved } = action.payload;

  try {
    const res: AxiosResponse<AddonManagementAdditionalFiltersResponse> = yield call(
      CompanyService.fetchAddonFilters,
      companyId
    );

    if (res.data) {
      yield put(
        actions.fetchAdditionalFiltersSuccess({
          filterSource: res.data.filters,
          shouldBeAddedToLoading,
        })
      );

      if (isBulkRemoved) {
        yield put(actions.fetchCompanyAddonManagement({ companyId: companyId }));
      }
    } else {
      const errorMessage = 'Failed to fetch filters for Add-on Management!';
      yield put(
        actions.fetchAdditionalFiltersFail({ error: errorMessage, shouldBeAddedToLoading })
      );
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Failed to fetch filters for Add-on Management!');
    yield put(actions.fetchAdditionalFiltersFail({ error: errorMessage, shouldBeAddedToLoading }));
    NotificationService.error(errorMessage);
  }
}

function* setSelectedFiltersSaga() {
  const gridSearchData: GetGridSearchData = yield call(getGridSearchDataSaga, 'addons');
  const { searchKey, filter: filterQuery, pagination, sortBy } = gridSearchData;
  const additionalFilterQuery: Dictionary<string | (string | number)[]> = yield select(
    selectors.getAdditionalFiltersQuery
  );

  const payloadData: SearchPayloadRequest = {
    searchKey,
    filter: {
      ...filterQuery,
      ...additionalFilterQuery,
    },
    pagination,
    sortBy,
  };

  yield call(updateFilterSettings, payloadData, 'addons');
}

function* searchCompaniesSaga(
  action: PayloadAction<{
    companyId: number;
    shouldResetPageNumber?: boolean;
    filter?: Dictionary<PreselectedFilter>;
  }>
) {
  const { companyId, shouldResetPageNumber, filter: payloadFilter } = action.payload;

  const gridSearchData: GetGridSearchData = yield call(getGridSearchDataSaga, 'addons');
  let { searchKey, originalFilter: filter, pagination, sortBy } = gridSearchData;

  if (shouldResetPageNumber) pagination = { ...pagination, pageNumber: 1 };
  if (payloadFilter) filter = payloadFilter;

  const customFilter = { ...filter };

  delete customFilter.query;

  const searchCompaniesData: SearchPayload = {
    searchKey,
    filter: customFilter,
    pagination,
    sortBy,
    companyId,
    shouldHandleTouchFilters: true,
    shouldResetPageNumber,
  };

  yield put(searchActions.searchCompanies({ gridKey: 'addons', data: searchCompaniesData }));
}

function* clearSearchSaga(
  action: PayloadAction<Dictionary<PreselectedFilter> | undefined, any, SuccessErrorCallback>
) {
  const callback: SuccessErrorCallback = yield action.meta;

  callback && callback(null);

  const companyId: number = yield select(searchSelectors.getCompanyId('addons'));

  yield put(actions.fetchCompanyAddonManagement({ companyId, shouldResetPageNumber: true }));
}

function* removeCompanyFromAddonManagementSaga(
  action: PayloadAction<RemoveCompanyFromAddonManagementPayload>
) {
  const { company_id: companyId } = action.payload;

  try {
    const res: AxiosResponse = yield call(SavedSearchesService.deleteCompanyInList, action.payload);

    if (res.data) {
      const filteredData = pickBy(
        res.data as Dictionary<RemoveCompanyFromAddonManagementResponse>,
        {
          status: false,
        }
      );
      const filteredDataSize = size(filteredData);

      if (!filteredDataSize) {
        yield put(actions.removeCompanyFromAddonManagementSuccess({ companyId }));
        const successMessage = 'Company removed successfully!';
        NotificationService.success(successMessage);
        yield put(searchCountActions.decrementAddonCount());
      } else if (filteredDataSize === 1) {
        const listId = Object.keys(filteredData)[0];
        const listName: string[] = yield select(
          selectors.getAdditionalFiltersDataListsNames([listId])
        );
        const dataList = Object.values(filteredData);

        let errorMessage = `${dataList[0].message}: ${listName[0]}!`;

        if (dataList[0].et_list_id !== null) {
          errorMessage = `${dataList[0].message} with ${listName[0]}!`;
        }

        yield put(actions.removeCompanyFromAddonManagementFail(errorMessage));
        NotificationService.error(errorMessage);
      } else {
        const listIds = Object.keys(filteredData);
        const listsNames: string[] = yield select(
          selectors.getAdditionalFiltersDataListsNames(listIds)
        );
        let errorMessage = `User has no right to modify these Watchlists: ${listsNames.join(
          ', '
        )}!`;

        yield put(actions.removeCompanyFromAddonManagementFail(errorMessage));
        NotificationService.error(errorMessage);
      }
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Failed to remove company from Add-on Management!');
    yield put(actions.removeCompanyFromAddonManagementFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

function* bulkRemoveAddonSaga(action: PayloadAction<BulkRemoveAddon>) {
  const { selectedCompanies } = action.payload;

  const companies = [...selectedCompanies];

  const companiesBatch = selectedCompanies.slice(0, BULK_EDIT_COMPANY_LIMIT_SYNCED_TO_ET);

  const listOfCompaniesIds = companiesBatch.map(company => company.company_id);

  const listOfCompaniesIdsWithAddonManuallyAdded = companiesBatch.reduce((acc: number[], item) => {
    if (item.addon) {
      acc.push(item.company_id);
    }

    return acc;
  }, []);

  const listOfCompaniesIdsFromAdditionalFilters: { listId: number; companyIds: number[] }[] =
    yield select(selectors.getListOfCompaniesIdFromAdditionalFilters(listOfCompaniesIds));

  const addonList: AddonListItem[] = [];
  const companiesList: CompanyListItem[] = [];

  listOfCompaniesIdsWithAddonManuallyAdded.forEach(companyId => {
    addonList.push({
      addon_bool: null,
      addon_company: null,
      company_id: companyId,
    });
  });

  listOfCompaniesIdsFromAdditionalFilters.forEach(company => {
    companiesList.push({
      list_id: company.listId,
      company_id: company.companyIds,
    });
  });

  const selectedCompaniesCount: number = yield select(
    bulkActionsSelectors.editFields.selectedCompaniesCount
  );

  const payload: BulkRemoveAddonPayload[] = [
    {
      companies_list: companiesList,
      addon_list: addonList,
    },
  ];

  try {
    const res: AxiosResponse<BulkWatchlistResponse[]> = yield call(
      CompanyService.bulkRemoveAddon,
      payload
    );
    companies.splice(0, BULK_EDIT_COMPANY_LIMIT_SYNCED_TO_ET);
    const cancelSearch: boolean = yield select(bulkActionsSelectors.editFields.cancel);

    if (res.data) {
      const companiesList = res.data;
      const currentResults: CompanyBulkEditingResults[] = yield select(
        bulkActionsSelectors.editFields.results
      );
      const nextResults: CompanyBulkEditingResults[] = [];

      yield put(
        editFieldActions.updatePercentage(
          Math.round((BULK_EDIT_COMPANY_LIMIT_SYNCED_TO_ET / selectedCompaniesCount) * 100)
        )
      );

      companiesBatch.forEach(company => {
        const { company_id: id, company_url, company_name, raw_url } = company;
        const failedCompany = companiesList.filter(company => company.company_id === id);

        const errorMessage = failedCompany[0]?.message;

        nextResults.push({
          company_id: id,
          company_url,
          raw_url,
          company_name,
          sync_status: errorMessage === 'Success',
          error_reason: errorMessage === 'Success' ? '' : errorMessage,
        });
      });

      const finalResults = [...currentResults, ...nextResults];
      yield put(editFieldActions.saveResults(finalResults));
    }

    if (!cancelSearch) {
      if (companies.length) {
        yield put(
          actions.bulkRemoveAddon({
            selectedCompanies: companies,
          })
        );
      } else {
        yield put(editFieldActions.updateProgress(false));
        yield put(editFieldActions.updateCompleted(true));
        yield put(editFieldActions.updatePercentage(100));
      }
    }
  } catch (error: any) {
    const message = getErrorMessage(error, 'Failed to remove from Add-on!');
    yield put(editFieldActions.updateProgress(false));
    yield put(editFieldActions.updateCompleted(true));
    yield put(editFieldActions.updatePercentage(100));

    NotificationService.error(message);
  }
}

export default function* companyProfileSaga() {
  yield takeLatest(actions.fetchCompanyAddonManagement, searchCompaniesSaga);
  yield takeLatest(actions.removeFilterTag, removeFilterTagSaga);
  yield takeLatest(actions.bulkRemoveAddon, bulkRemoveAddonSaga);
  yield takeLatest(actions.clearSearch, clearSearchSaga);
  yield takeLatest(actions.changeSortCompanyAddonManagement, saveSortInSessionSaga);
  yield takeLatest(actions.setSelectedFilters, setSelectedFiltersSaga);
  yield takeLatest(actions.fetchAdditionalFilters, fetchAddonManagementFiltersSaga);
  yield takeLatest(actions.updateAdditionalFilters, searchCompaniesSaga);
  yield takeLatest(actions.removeCompanyFromAddonManagement, removeCompanyFromAddonManagementSaga);
}
