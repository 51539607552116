import React from 'react';
// components
import { useVirtualTableContainerSize, VirtualTableScrollYContext } from '@features/scroll-history';
import SearchGrid from './SearchGrid';

const GridContent = () => {
  const virtualTableScrollYContext = useVirtualTableContainerSize();
  const containerRef = virtualTableScrollYContext.containerRef as React.RefObject<HTMLDivElement>;

  return (
    <div ref={containerRef} className="position-relative">
      <VirtualTableScrollYContext.Provider value={virtualTableScrollYContext}>
        <SearchGrid />
      </VirtualTableScrollYContext.Provider>
    </div>
  );
};

export default GridContent;
