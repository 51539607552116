import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { suggestedSearchColumns } from '../../../common/table/TableSavedSearches/columns';
import { selectors as suggestedSelectors } from '../../../../redux/company/suggested-searches';

const SuggestedSearchesTable: React.FC = () => {
  const data = useSelector(suggestedSelectors.getSuggestedSearches);
  const formattedData = useMemo(() => data.map((item, index) => ({ ...item, key: index })), [data]);

  return (
    <Table
      columns={suggestedSearchColumns}
      rowKey={'unique_id'}
      dataSource={formattedData}
      tableLayout="fixed"
      pagination={false}
      bordered
      className="search-results legacy-table"
    />
  );
};

export default React.memo(SuggestedSearchesTable);
