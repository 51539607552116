import { createAction } from '@reduxjs/toolkit';
import { WebsiteRankPayload } from '@optx/models/company/SocialMedia';
import { SocialMediaChartData } from '@optx/models/charts/data';
import * as types from './types';

// FETCH WORLD WIDE RANK
export const fetchWWWebsiteRank = createAction<WebsiteRankPayload>(
  types.fetchWWWebsiteRank.REQUEST
);

export const fetchWWWebsiteRankSuccess = createAction<SocialMediaChartData>(
  types.fetchWWWebsiteRank.SUCCESS
);

export const fetchWWWebsiteRankFail = createAction<string>(types.fetchWWWebsiteRank.FAILURE);

// FETCH US ONLY RANK
export const fetchUSWebsiteRank = createAction<WebsiteRankPayload>(
  types.fetchUSWebsiteRank.REQUEST
);
export const fetchUSWebsiteRankSuccess = createAction<SocialMediaChartData>(
  types.fetchUSWebsiteRank.SUCCESS
);
export const fetchUSWebsiteRankFail = createAction<string>(types.fetchUSWebsiteRank.FAILURE);
