import React from 'react';
import { Button, Tooltip } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { TooltipPlacement } from 'antd/lib/tooltip';

interface ClearSearchButtonType {
  title?: string;
  isVisible?: boolean;
  placement?: TooltipPlacement;
  onClick: () => void;
}

const ClearSearchButton: React.FC<ClearSearchButtonType> = ({
  title = 'Clear Search',
  isVisible = true,
  placement,
  onClick,
}) => {
  if (!isVisible) return null;

  return (
    <Tooltip title={title} placement={placement ?? 'bottom'}>
      <Button type="text" onClick={onClick} icon={<CloseCircleOutlined />} />
    </Tooltip>
  );
};

export default React.memo(ClearSearchButton);
