import React from 'react';
import { Breadcrumb } from 'antd';
import { useSelector } from 'react-redux';
import { selectors as profileSelectors } from '../../../../redux/company/profile';
// models
import { BreadcrumbItemInterface } from '../interfaces';

const { Item } = Breadcrumb;

export const RestoreDefaults: BreadcrumbItemInterface = ({ onClick, ...restProps }) => {
  const company = useSelector(profileSelectors.getProfile);
  const companyName = company ? company.company_name : 'Company';

  return <Item {...restProps}>{companyName}</Item>;
};

// eslint-disable-next-line no-underscore-dangle
RestoreDefaults.__ANT_BREADCRUMB_ITEM = true;

export default RestoreDefaults;
