import React, { useState, useEffect } from 'react';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { useDispatch } from 'react-redux';
import { Menu, MenuProps, Modal, Button, Typography } from 'antd';
// models
import { SelectOption } from '@optx/models/Option';
import { ShareList } from '@optx/redux/user/share/interfaces';
import { ListType } from '@models/WatchList';
import { UserList } from '@optx/redux/user/user-list/interfaces';
// redux
import * as actionsShare from '@redux/user/share/actions';
// components
import { MultiSelect } from '@shared/view/molecules/Select';

interface ShareListItemProps extends MenuItemProps {
  listId: number | string;
  listType?: string;
  options?: Array<UserList>;
  sharedWith?: Array<number>;
}

/**
 * Returns the corresponding ListType based on the provided readableListType.
 * @param readableListType - The readable list type to be converted to ListType.
 * @returns The corresponding ListType based on the provided readableListType.
 */
const getListType = (readableListType?: string): ListType => {
  switch (readableListType) {
    case 'list':
      return 'watchlist';
    case 'combined':
      return 'combined';
    default:
      return 'saved_search';
  }
};

/**
 * Share List Menu Item.
 */
const ShareListItem: React.FC<ShareListItemProps> = ({
  listId,
  listType,
  options,
  sharedWith,
  onClick,
  children,
  ...restProps
}) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [initialUsersSelected, setInitialUsersSelected] = useState<Array<UserList>>([]);
  const [usersSelected, setUsersSelected] = useState<Array<SelectOption>>([]);

  useEffect(() => {
    if (sharedWith && options) {
      const selectedUsers: UserList[] = options?.filter(
        option => sharedWith.includes(option.value) && option
      );

      setInitialUsersSelected(selectedUsers);
    }
  }, [sharedWith, options]);

  const handleChange = (value: any) => {
    setUsersSelected(value);
  };

  const handleShare = () => {
    if (usersSelected!.length) {
      const userIds = [] as Array<string>;
      usersSelected!.forEach(user => {
        userIds.push(user!.value as string);
      });

      const shareData = {
        share_with: userIds,
        list_id: listId,
        list_type: getListType(listType),
      } as ShareList;

      setShowModal(false);
      dispatch(actionsShare.shareListAction(shareData));
      setUsersSelected([]);
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setUsersSelected([]);
  };

  const handleClick: MenuProps['onClick'] = info => {
    setShowModal(true);
    onClick && onClick(info);
  };

  return (
    <>
      <Menu.Item onClick={handleClick} {...restProps}>
        {children}
      </Menu.Item>
      <Modal
        visible={showModal}
        title={`Which user would you like to share the ${listType || 'search'} with?`}
        centered
        onCancel={handleCancel}
        footer={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleShare} disabled={!usersSelected.length} type="primary">
              Share {listType}
            </Button>
          </>
        }
      >
        {listType === 'combined' && (
          <Typography.Text style={{ paddingBottom: '5px', display: 'block' }}>
            For combined search child searches are shared too
          </Typography.Text>
        )}
        <MultiSelect
          options={options as unknown as Array<SelectOption>}
          value={usersSelected}
          onChange={handleChange}
          previousSelectedOptions={initialUsersSelected}
          previousSelectedOptionsListTitle="Already shared with:"
          selectedOptionsListTitle="Share with:"
        />
      </Modal>
    </>
  );
};

ShareListItem.defaultProps = {
  options: [],
};

export default ShareListItem;
