import { AxiosResponse } from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';

// services
import { FilterService } from '@services/api';
import NotificationService from '@services/NotificationService';
// models
import { FilterGroup } from '@models/filters';
// utils
import { getErrorMessage } from '@utils/api/errors';
import { actions } from '../actions';
//redux
import { selectors as userInformationSelectors } from '@redux/user/information';

export function* fetchFiltersSaga() {
  const analystUserName: string = yield select(
    userInformationSelectors.getSourcingOutReachDefaultAnalyst
  );

  try {
    const res: AxiosResponse = yield call(
      FilterService.getOptions,
      FilterGroup.SourcingOutreachSummaryReport
    );

    if (res.data) {
      yield put(actions.filters.fetchSuccess(res.data.filters, analystUserName));
    }
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Failed to fetch filters for Sourcing Outreach Summary Users!'
    );

    yield put(actions.filters.fetchFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* filtersSaga() {
  yield takeLatest(actions.filters.fetch, fetchFiltersSaga);
}
