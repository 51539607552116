import React from 'react';
// utils
import { formatPercentDefault } from '@utils/format';
// components
import { TrendingValue } from '@optx/shared/view/molecules/trending';
import Styles from './CardTitle.styles';

const formatTrending = (value: string | number | null | undefined) =>
  formatPercentDefault(value, true);

interface CardTitleProps {
  className?: string;
  title: string;
  value: number | null;
  trending: number | null;
}

const CardTitle: React.FC<CardTitleProps> = ({ className, title, value, trending }) => {
  return (
    <Styles.Wrapper className={className}>
      <Styles.Title level={5}>{title}</Styles.Title>
      <div>
        <Styles.TitleValue>{value}</Styles.TitleValue>
        <TrendingValue value={trending} format={formatTrending} />
      </div>
    </Styles.Wrapper>
  );
};

export default CardTitle;
