import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, Input } from 'antd';
import classnames from 'classnames';
// models
import { SearchAutocomplete } from '@models/search-autocomplete';
// utils
import { parseFilter } from '@utils/filters/parseFilters';
import { validateSearchTerm } from '@utils/search';
// redux
import { selectors as searchSelectors } from '@features/grid/search';
import { actions as searchActions } from '@features/grid/search';
import {
  actions as searchAutocompleteActions,
  selectors as autocompleteSelectors,
} from '@features/grid/autocomplete';
import { selectors as savedSearchesSelectors } from '@redux/company/saved-searches';
import { selectors as filterSourcesSelectors } from '@redux/company/filters';
// components
import SearchInput, { SearchInputProps } from '@components/common/form/input/SearchInput';
import ApplySearchButton from '@optx/components/pages/Home/Header/ApplySearchButton';
import { AutocompleteMenu } from '@optx/features/grid/autocomplete/components';

interface CompanySearchProps extends Omit<SearchInputProps, 'onSearch' | 'value'> {}

const gridKey = 'lists';

const CompanySearch: React.FC<CompanySearchProps> = ({
  onFocus,
  onBlur,
  className,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const searchKey = useSelector(searchSelectors.getSearchKey('lists'));
  const allSavedSearchesList = useSelector(savedSearchesSelectors.getAllSearch);
  const filterSources = useSelector(filterSourcesSelectors.getCompanyFilters);
  const [query, setQuery] = useState('');
  const [validSearchKey, setValidSearchKey] = useState(true);
  const inputRef = useRef<Input | null>(null);

  const autocompleteList = useSelector(autocompleteSelectors.getAutocompleteList);

  // initialize autocomplete
  const fetchSearchAutocomplete = useCallback(
    () => dispatch(searchAutocompleteActions.fetchSearchAutocomplete()),
    [dispatch]
  );

  const loadAutocompleteSearch = useCallback(
    (item: SearchAutocomplete) => {
      if (item.search_info?.type !== null && item.search_info?.type.startsWith('saved')) {
        const searchId = allSavedSearchesList.find(el => el.title === item.title)?.unique_id;

        return dispatch(
          searchActions.loadSavedSearch({
            gridKey,
            data: {
              searchId: searchId as string | number,
              title: item.title,
              listType: item.search_info.type,
            },
          })
        );
      }

      if (
        item.search_info?.type !== null &&
        !item.search_info?.type.startsWith('saved') &&
        item.search_info?.filters.filter(el => !el.startsWith('query')).length
      ) {
        const filter = parseFilter(item.search_criteria, filterSources)[0];
        const searchKey = item.search_info.keyword;

        return dispatch(
          searchActions.searchCompanies({
            gridKey,
            data: { searchKey, filter, shouldResetPageNumber: true },
          })
        );
      }

      return dispatch(searchActions.loadAutocompleteSearch({ gridKey, data: item }));
    },
    [dispatch, filterSources, allSavedSearchesList]
  );

  useEffect(() => {
    fetchSearchAutocomplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  useEffect(() => {
    setQuery('');
  }, [searchKey]);

  const search = useCallback(
    (searchKey: string) => {
      dispatch(
        searchActions.searchCompanies({ gridKey, data: { searchKey, shouldResetPageNumber: true } })
      );
      setQuery('');
    },
    [dispatch]
  );

  const handleSearch = (searchKey: string) => {
    // close the dropdown by clicking on input.
    inputRef.current!.input?.click();
    search(searchKey);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  return (
    <>
      <Dropdown
        overlay={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <AutocompleteMenu
            list={autocompleteList}
            onClickHandler={loadAutocompleteSearch}
            fromUserList
          />
        }
        trigger={['click']}
      >
        <SearchInput
          ref={inputRef}
          {...restProps}
          onChange={handleChange}
          onSearch={handleSearch}
          value={query}
          placeholder="Search for companies by keyword (3 chars or more)"
          className={classnames(className, { 'search-term-error': !validSearchKey })}
        />
      </Dropdown>

      {query && (
        <ApplySearchButton
          onSearch={() => search(query)}
          validation={() => validateSearchTerm(query, setValidSearchKey)}
        />
      )}
    </>
  );
};

export default CompanySearch;
