import React, { useState, useEffect } from 'react';
import { Button, Input } from 'antd';
import classNames from 'classnames';
import { SearchOutlined } from '@ant-design/icons';
import { InputProps } from 'antd/lib/input';
// utils
import { validateSearchTerm } from '@utils/search';

export interface SearchInputProps extends Omit<InputProps, 'value' | 'prefix' | 'onKeyPress'> {
  onSearch?: (key: string) => void;
  iconSize?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

const SearchInput = React.forwardRef<Input, SearchInputProps>(
  ({ onSearch, value, onChange, iconSize, ...restProps }, ref) => {
    const [currentValue, setCurrentValue] = useState(value || '');
    const [validSearchKey, setValidSearchKey] = useState(true);

    useEffect(() => {
      if (value !== currentValue) {
        setCurrentValue(value || '');
      }
    }, [value, currentValue]);

    const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      setCurrentValue(event.target.value);

      if (event.target.value.length === 3 || event.target.value.length === 0) {
        setValidSearchKey(true);
      }

      onChange && onChange(event);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSearch();
      }
    };

    const handleSearch = () => {
      const validation = validateSearchTerm(currentValue, setValidSearchKey);

      if (validation) {
        onSearch && onSearch(currentValue);
      }
    };

    return (
      <Input
        value={currentValue}
        onChange={handleChangeText}
        onKeyPress={handleKeyPress}
        prefix={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Button
            type="link"
            icon={<SearchOutlined style={{ fontSize: iconSize || 19 }} />}
            onClick={handleSearch}
          />
        }
        {...restProps}
        ref={ref}
        className={classNames(restProps.className, { 'search-term-error': !validSearchKey })}
      />
    );
  }
);

SearchInput.defaultProps = {
  value: '',
  name: 'inputSearch',
};

export default SearchInput;
