import React from 'react';
import ReactJsPagination, { ReactJsPaginationProps } from 'react-js-pagination';
import {
  DoubleLeftOutlined,
  LeftOutlined,
  RightOutlined,
  DoubleRightOutlined,
} from '@ant-design/icons';

export const Pagination: React.FC<ReactJsPaginationProps> = props => (
  <ReactJsPagination
    firstPageText={<DoubleLeftOutlined />}
    prevPageText={<LeftOutlined />}
    nextPageText={<RightOutlined />}
    lastPageText={<DoubleRightOutlined />}
    {...props}
  />
);

Pagination.defaultProps = {
  pageRangeDisplayed: 5,
  itemClass: 'pagination-item',
  innerClass: 'pagination-list',
  linkClass: 'pagination-link',
};
