import React, { useCallback, useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dictionary } from 'lodash';
import { ActionMeta, ValueType } from 'react-select';
import { useField, useFormikContext } from 'formik';
// models
import Option, { SelectOption } from '@models/Option';
import { Filter, FilterSource, MultiSelectFilter } from '@optx/models/filters';
// utils
import {
  getMultiSelectOptions,
  orderAnalystsByCustom,
} from '@optx/utils/filters/mapFiltersOptions';
import { checkHelperValues, helperValueIndex } from '@optx/utils/filters/filterHelpers';
//redux
import { selectors as userInformationSelectors } from '@redux/user/information';
// components
import { MultiSelectInline } from '@shared/view/molecules/Select';
import SearchFilterCard from './SearchFilterCard';
import { FiltersContext } from '../FiltersContext';

interface FilterMultiSelectProps {
  source: FilterSource<Filter<any>>;
  filter: MultiSelectFilter<Array<Option>>;
}

export const FilterMultiSelectGroups: React.FC<FilterMultiSelectProps> = ({ source, filter }) => {
  const isChanged = useRef(false);
  const [field, , helpers] = useField<Array<Option> | undefined>(filter.column);
  const [isFilter, setFilter] = useState<boolean>(false);
  const isDefaultAnalyst = useSelector(userInformationSelectors.getSourcingOutReachDefaultAnalyst);
  const { values: formFields, getFieldHelpers } =
    useFormikContext<Dictionary<Array<SelectOption>>>();
  const { onManualFilterChange } = useContext(FiltersContext);

  const isMultiSelectDisabledForAnalyst =
    isDefaultAnalyst && filter.label === 'Analysts & Associates' ? true : false;

  const options = orderAnalystsByCustom(getMultiSelectOptions(filter as any) as any);

  const handleChange = (values: ValueType<Option>, action: ActionMeta<SelectOption>) => {
    isChanged.current = true;
    helpers.setValue(values as Array<SelectOption>);

    // check if multi select has a helper filter and
    // update the helper filter's values
    if (filter.used_for.length) {
      // if selecting option check values against helper
      // filter's entries and update it's options
      if (action.action === 'select-option' || action.action === 'set-value') {
        const helperValues = checkHelperValues(values as Array<Option>, filter.used_for[0], source);

        getFieldHelpers(filter.used_for[0]).setValue(helperValues);
      } else if (action.action === 'deselect-option' || action.action === 'remove-value') {
        // if removing option check if value is included in one of the
        // helper's filter selected options and remove it
        const index = helperValueIndex(action.option!.value, formFields[filter.used_for[0]]);

        if (index !== undefined) {
          const newValues = formFields[filter.used_for[0]];
          newValues.splice(index, 1);
          getFieldHelpers(filter.used_for[0]).setValue(newValues);
        } else if (action.option!.value === '*') {
          getFieldHelpers(filter.used_for[0]).setValue([]);
        }
      }
    }

    if (action.action === 'remove-value') {
      filterChanged();
    }
  };

  const handleBlur = () => {
    filterChanged();
  };

  const filterChanged = () => {
    if (isChanged.current) {
      isChanged.current = false;
      // Call on change on the next tick.

      setTimeout(() => {
        onManualFilterChange && onManualFilterChange(filter.column);
      }, 0);
    }
  };

  const changeMultiselectStyleBehavior = useCallback(
    (value: string) => {
      const optionsSelected = options.filter(
        option =>
          option.label?.toLowerCase().includes(value.toLowerCase()) ||
          option.value?.toLowerCase().includes(value.toLowerCase())
      );
      const filteredOptions =
        optionsSelected.find(option => option.label === 'Unowned') || filter.column === 'stage';

      if (!filteredOptions) {
        setFilter(true);
      } else {
        setFilter(false);
      }
    },
    [options, filter.column]
  );

  return (
    <SearchFilterCard label={filter.label}>
      <MultiSelectInline
        allowSelectAll
        options={options}
        onBlur={handleBlur}
        onChange={handleChange}
        value={field.value as Array<SelectOption>}
        onInputChange={value => changeMultiselectStyleBehavior(value)}
        placeholder={filter.placeholders ?? undefined}
        allPrefix={filter.placeholders ?? undefined}
        className={`${filter.column}${isFilter ? '' : '--multiselect'}`}
        menuPlacement="auto"
        isDisabled={isMultiSelectDisabledForAnalyst}
      />
    </SearchFilterCard>
  );
};

export default FilterMultiSelectGroups;
