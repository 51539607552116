export const NEXT_STEPS_OPINION_ENDPOINT = '/search/next_steps_opinion';

export const GET_ET_COMPANIES_ENDPOINT = '/search/get_et_companies';

export const COMPANY_PRODUCT_CATEGORY_ENDPOINT = '/search/company_product_category';

export const COMPANY_RATIONALE_ENDPOINT = '/search/rationale';

export const COMPANY_NAME_ENDPOINT = '/search/company_name';

export const LAST_INVESTORS_ENDPOINT = '/search/last_investors';

export const COMPANY_TAG_ENDPOINT = '/search/company_tag';
