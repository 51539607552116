import { SelectOption } from '@optx/models/Option';
import styled from 'styled-components';

interface MultiSelectWrapperProps {
  value?: SelectOption<string>;
  hasButtons?: boolean;
}

const MultiSelectWrapper = styled.div<MultiSelectWrapperProps>`
  min-width: 200px;
  width: 100%;
  color: rgba(0, 0, 0, 0.85);
  width: ${props => props.hasButtons === true && '65%'};

  .multiselect {
    &__multi-value {
      display: none;
    }

    &__control {
      border: 1px solid #d9d9d9;
      border-radius: 0;

      &--is-focused {
        border: 1px solid #d9d9d9;
      }
    }

    &__value-container {
      padding-left: 34px;
      height: 40px;
    }

    &__placeholder {
      color: #bfbfbf;
      font-size: 16px;
    }

    &__indicator {
      color: #000000;
      width: 65%;
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      border-radius: 2px;
      margin-top: 4px;
      margin-bottom: 4px;
      z-index: 2;
      .primary + .multiselect__option:not(.primary) {
        border-top: 1px solid #d9d9d9;
      }
    }

    &__option {
      background-color: inherit;
      color: inherit;
      color: rgba(0, 0, 0, 0.85);

      &:hover {
        background-color: #deebff;
        cursor: pointer;
      }

      &--is-disabled {
        &:hover {
          cursor: not-allowed;
        }
      }

      label {
        line-height: 0;
      }

      span {
        margin-top: -2px;
      }
    }

    &__group {
      > :last-child {
        padding-left: 20px;
      }
    }
  }

  &.bulk-action-multiselect,
  &.is-addon {
    .multiselect {
      &__menu {
        border: 1px solid #d9d9d9;
        border-top: 0;
        box-shadow: none;
        position: static;
        margin-top: 0;

        &-list {
          max-height: 180px;
        }
      }
    }
  }

  &.is-addon .multiselect__menu-list {
    max-height: 250px;
  }
`;

export const Styled = { MultiSelectWrapper };
