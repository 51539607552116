import { createSelector } from 'reselect';
import { selectors as localUISelectors } from '../ui';
import { selectFeatureState } from './selectFeature';

const selectUIState = createSelector(selectFeatureState, state => state.ui);

const isModalOpen = createSelector(selectUIState, localUISelectors.isOpen);

export default {
  isModalOpen,
};
