import { ColumnType } from 'antd/lib/table';

// models
import { Analyst } from '@optx/features/checklist-research-insights/models/analyst';
// components
import { TableCellDefault } from '@optx/components/common/table/AntTable/cells';

const columns: Array<ColumnType<Analyst>> = [
  {
    dataIndex: 'user_name',
    title: 'Reviewer',
    render: TableCellDefault,
    sorter: true,
    className: 'analyst-column',
    width: 170,
  },
  {
    dataIndex: 'total_companies_reviewed_cids',
    title: 'Total # of Companies Reviewed',
    render: TableCellDefault,
    sorter: true,
    className: 'analyst-column',
    width: 180,
  },
  {
    dataIndex: 'optx_score_reviewed_cids',
    title: '# of OPTX Score Reviewed',
    render: TableCellDefault,
    sorter: true,
    className: 'analyst-column',
    width: 140,
  },
  {
    dataIndex: 'il_optx_score_reviewed_cids',
    title: '# of IL OPTX Scores Reviewed',
    render: TableCellDefault,
    sorter: true,
    className: 'analyst-column',
    width: 140,
  },
  {
    dataIndex: 'optx_score_agreement_percentage',
    title: 'OPTX Score Agreement %',
    render: TableCellDefault,
    sorter: true,
    className: 'analyst-column',
    width: 140,
  },
  {
    dataIndex: 'il_optx_score_agreement_percentage',
    title: 'IL OPTX Score Agreement %',
    render: TableCellDefault,
    sorter: true,
    className: 'analyst-column',
    width: 140,
  },
  {
    dataIndex: 'psg_fits_reviewed_cids',
    title: '# of PSG Fits Reviewed',
    render: TableCellDefault,
    sorter: true,
    className: 'analyst-column',
    width: 140,
  },
  {
    dataIndex: 'psg_fits_verified_cids',
    title: '# of PSG Fits Verified Yes',
    render: TableCellDefault,
    sorter: true,
    width: 140,
  },
  {
    dataIndex: 'software_companies_reviewed_cids',
    title: '# of Software Y/N Reviewed',
    render: TableCellDefault,
    sorter: true,
    className: 'analyst-column',
    width: 140,
  },
  {
    dataIndex: 'software_changes_cids',
    title: '# of Software Y/N Changes Made',
    render: TableCellDefault,
    sorter: true,
    className: 'analyst-column',
    width: 140,
  },
  {
    dataIndex: 'software_change_accuracy',
    title: 'Accuracy of Software Y/N',
    render: TableCellDefault,
    sorter: true,
    className: 'analyst-column',
    width: 140,
  },
  {
    dataIndex: 'software_change_no_accuracy',
    title: 'Accuracy of Software No',
    render: TableCellDefault,
    sorter: true,
    className: 'analyst-column',
    width: 140,
  },
  {
    dataIndex: 'software_change_yes_accuracy',
    title: 'Accuracy of Software Yes',
    render: TableCellDefault,
    sorter: true,
    className: 'analyst-column',
    width: 140,
  },
];

export default columns;
