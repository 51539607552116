import { createSelector } from 'reselect';
// models
import { CompanyCardState } from './interfaces';
// constants
import { featureName } from '../featureName';
// redux
import { errorSelector } from '@optx/redux/feature/fetch/selectors';

const getCompanyCardState = (state: Record<typeof featureName, CompanyCardState>) =>
  state[featureName];

export const shouldInitialFetch = createSelector(getCompanyCardState, state => !state.loading);

export const getError = createSelector(getCompanyCardState, errorSelector);

export const isLoading = createSelector(getCompanyCardState, state => state.loading);

export const getProfile = createSelector(getCompanyCardState, state => state?.data);

export const getTrending = createSelector(
  getCompanyCardState,
  state => state.data?.optx_score_trends
);

export const getProfileContacts = createSelector(getCompanyCardState, state =>
  state.data ? state.data.contacts : []
);

export const getPluginPrimaryContact = createSelector(
  getCompanyCardState,
  state => state.pluginPrimaryContact
);
