import React from 'react';
import { Menu, MenuProps } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
// redux
import { getSorting } from '@redux/my-companies/sort/selectors';
import { resetSortAction } from '@redux/my-companies/sort/actions';

export const ClearSortingMenuItem = ({ onClick, ...restProps }: MenuItemProps) => {
  const dispatch = useDispatch();
  const sorting = useSelector(getSorting);
  const clearSort = () => dispatch(resetSortAction());

  if (!sorting.length) {
    return null;
  }

  const handleClick: MenuProps['onClick'] = info => {
    onClick && onClick(info);
    clearSort();
  };

  return (
    <Menu.Item onClick={handleClick} {...restProps}>
      Clear All Sorting
    </Menu.Item>
  );
};
