import React, { useState, useEffect } from 'react';
import { InputNumber as AntdInputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';

/**
 * RangeInputNumber component with change event called only on blur or enter key press.
 */
const RangeInputNumber = ({
  value,
  onChange,
  formatter,
  parser,
  onBlur,
  onPressEnter,

  ...restProps
}: InputNumberProps) => {
  const [currentValue, setCurrentValue] = useState<any>(value);
  const [updateHistogram, setupdateHistogram] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const getCurrentValue = () => {
    // override the input value
    let newValue = parser ? parser(currentValue) : currentValue;

    // reset to previous value if on parse the value format is not valid number.
    if (newValue === null) {
      newValue = value;
    }

    return newValue;
  };

  // Change value on blur.
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const newValue = getCurrentValue();

    if (updateHistogram) {
      setCurrentValue(newValue);

      onChange && onChange(newValue);
      setIsEditing(false);
    } else {
      const formattedValue = formatter ? formatter(value) : value;
      setCurrentValue(formattedValue);
    }
  };

  // Only update local state on change.
  const handleChange = (newValue: string | number | null | undefined) => {
    let formattedPrevValue;

    // initially currentValue is a string like '50M' and needs to be formatted.
    if (typeof currentValue === 'string') {
      // but do not format if includes '-' because it will ignore negative numbers
      if (currentValue.includes('-')) {
        formattedPrevValue = parseInt(currentValue);
      } else {
        formattedPrevValue = parseInt(currentValue.replace(/\D/g, ''));
      }
    } else {
      formattedPrevValue = currentValue;
    }

    if (newValue !== undefined) {
      setCurrentValue(newValue);

      // do not update histograms if initial value does not change
      if (newValue !== formattedPrevValue) {
        setupdateHistogram(true);
      }
    }
  };

  // Disable parser/formatting on focus to allow editing without formatter/parser causing value changes.
  const handleFocus = () => {
    setIsEditing(true);
    const formattedValue = formatter ? formatter(value) : currentValue;

    setCurrentValue(formattedValue);
  };

  // Call change on press enter but don't disable editing.
  const handlePressEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const newValue = getCurrentValue();
    onChange && onChange(newValue);
  };

  return (
    <AntdInputNumber
      value={currentValue}
      onBlur={handleBlur}
      onInput={handleChange}
      parser={isEditing ? undefined : parser}
      formatter={isEditing ? undefined : formatter}
      onFocus={handleFocus}
      onPressEnter={handlePressEnter}
      {...restProps}
    />
  );
};

export default RangeInputNumber;
