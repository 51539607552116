import React from 'react';
import { useSelector } from 'react-redux';
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer, YAxis, Cell } from 'recharts';
// models
import { SmartTouchScoreOption } from '@models/feature/analystsLeaderboard/SmartTouchScore';
// components
import ChartTooltip from './ChartTooltip';
import * as config from './config';
// redux
import { selectors } from '../../../state';
// styles
import Styles from '../SmartTouchScore.styled';

interface SmartTouchScoreChartProps {
  data: Array<SmartTouchScoreOption>;
}

type DataKey = keyof SmartTouchScoreOption;

const SmartTouchScoreChart = ({ data }: SmartTouchScoreChartProps) => {
  const selectedPeriodLabel = useSelector(selectors.filters.getSelectedPeriodLabel);

  return (
    <>
      <Styles.Title>Distribution Across All Analysts</Styles.Title>

      <ResponsiveContainer height={112}>
        <BarChart data={data} margin={{ top: 0, right: 0, left: -40, bottom: 10 }} barSize={8}>
          <XAxis dataKey={'x_axis' as DataKey} {...config.xAxisProps} />
          <YAxis {...config.yAxisProps} domain={[0, 'dataMax + 5']} />
          <Tooltip content={<ChartTooltip />} {...config.tooltipProps} />
          <Bar dataKey={'y_axis' as DataKey} {...config.commonBarProps}>
            {data.map(entry => (
              <Cell key={entry.y_axis} fill={entry.is_selected ? '#1890FF' : '#C2CFE0'} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>

      <Styles.Legend>SMART TOUCH SCORE OVER {selectedPeriodLabel.toUpperCase()}</Styles.Legend>
    </>
  );
};

export default SmartTouchScoreChart;
