import { Dictionary, each as _each } from 'lodash';
import moment from 'moment';
//models
import { ViewOption } from '@optx/models/search';
import { SelectOption } from '@optx/models/Option';
import Company from '@optx/models/Company';
import { CompanyUserContact } from '@models/api/contacts';
import { FieldValue } from '@optx/features/company/audit-trail/state/interfaces';
import { typeOptionsEditFieldSingleSelect } from '@optx/features/company/edit-fields/state/interfaces';
//constants
import { DEFAULT_EMPTY_VALUE, NEGATIVE_SIGN } from '@optx/constants/value';
import { LABEL_PREFIX } from '@optx/constants/descriptions';
//utils
import { mapOptionsToLabeledValues } from '@optx/components/common/select/Select/PrefixedSelectionItem';
import { getLocationMainGridKey } from './grid';

/**
 * normalize array of objects
 * @param {string} key the key to be used for each object
 */
export const normalizeData = (key: string) => {
  return (data: any, item: any) => {
    // eslint-disable-next-line no-param-reassign
    data[item[key]] = item;

    return data;
  };
};

/**
 * Checks if the given date is in the future.
 *
 * @param {string} date - The date to be checked.
 * @returns {boolean} Returns true if the date is in the future, false otherwise.
 */
export const isDateInFuture = (date: string) => {
  const currentDate = moment();

  return moment(date).isAfter(currentDate);
};

/**
 * Reorders a list of companies based on the similarity criteria and the current location context.
 *
 * @param {boolean | undefined} isSimilarCompanies - Flag indicating whether similar companies should be considered.
 * @param {Company[]} companies - The list of companies to be reordered.
 * @param {SelectOption<string>} similarCompanyInfo - Information about the similar company, including its ID.
 * @returns {Company[]} - The reordered list of companies, with the similar company (if found) placed at the beginning.
 *
 * This function checks if the `isSimilarCompanies` flag is true and if the current location is 'advancedSearch'.
 * If both conditions are met, it attempts to find the similar company in the provided list of companies using the ID
 * from `similarCompanyInfo`. If the similar company is found, it is placed at the beginning of the list, followed by
 * the remaining companies. If the similar company is not found, the original list of companies is returned.
 * If the `isSimilarCompanies` flag is false or the location is not 'advancedSearch', the original list of companies
 * is returned unchanged.
 */
export const sortSimilarCompanies = (
  isSimilarCompanies: boolean | undefined,
  companies: Company[],
  similarCompanyInfo: SelectOption<string>
) => {
  const location = getLocationMainGridKey();

  if (isSimilarCompanies && location === 'advancedSearch') {
    let sortedCompaniesList: Company[] = [];
    const similarCompanyId = similarCompanyInfo?.value.split(',').pop()!;
    const similarMainCompany = companies.find(
      company => company.company_id === Number(similarCompanyId)
    );
    const filteredCompaniesList = companies.filter(
      company => company.company_id !== Number(similarCompanyId)
    );

    if (similarMainCompany) {
      sortedCompaniesList = [similarMainCompany!, ...filteredCompaniesList];
    } else {
      sortedCompaniesList = [...companies];
    }

    return sortedCompaniesList;
  }

  return companies;
};

/**
 * @description Normalizes a given string by replacing hyphen-minus sign with a minus sign
 *
 * @param {string} value - The string to be normalized
 *
 * @returns {string} The normalized string
 */
export const normalizeNegativeSignValues = (value: string) => {
  const splitedValues = value?.split('-');

  if (splitedValues?.some(list => list !== '')) {
    return value.split('-').join(NEGATIVE_SIGN);
  }

  return value;
};

/**
 * Normalizes the view columns based on the default score value
 *
 * @param {ViewOption} viewData - The view data
 * @param {string} defaultScoreValue - The default score value
 * @returns {Object} - An object containing the validated columns and the validated columns order
 */
export const normalizeViewsColumns = (viewData: ViewOption, defaultScoreValue: string) => {
  let validatedColumns = null;
  let validatedColumnsOrder = null;

  if (defaultScoreValue === 'us') {
    validatedColumns = viewData.columns
      ? viewData.columns
          .split(',')
          .filter(column => column !== 'il_optx_score')
          .join(',')
      : null;
    validatedColumnsOrder = viewData.column_order
      ? viewData.column_order
          .split(',')
          .filter(column => column !== 'il_optx_score')
          .join(',')
      : null;
  } else if (defaultScoreValue === 'il') {
    validatedColumns = viewData.columns
      ? viewData.columns
          .split(',')
          .filter(column => column !== 'score')
          .join(',')
      : null;
    validatedColumnsOrder = viewData.column_order
      ? viewData.column_order
          .split(',')
          .filter(column => column !== 'score')
          .join(',')
      : null;
  }

  return {
    validatedColumns,
    validatedColumnsOrder,
  };
};

/**
 * returns the sur name of a full name
 * @param value term to be checked
 * @param typeOptions typeOptions of EditFieldSingleSelect
 */

export const checkSurName = (value: FieldValue, typeOptions: typeOptionsEditFieldSingleSelect) => {
  if (typeOptions === 'Company Owner' && typeof value === 'string') {
    return value?.split(' ').pop() || value;
  }

  return value;
};

/**
 * normalizeCommaValues
 *
 * @description Normalizes an array of strings by sorting them alphabetically and joining them with a comma
 *
 * @param {string[]} values - The array of strings to normalize
 *
 * @returns {string} - The normalized string
 */
export const normalizeCommaValues = (values: string[]) => {
  const spreadValues = [...values];
  const noramalizedValues = spreadValues.sort((a, b) =>
    a.toLowerCase().localeCompare(b.toLowerCase())
  );

  return noramalizedValues.join(', ');
};

/**
 * Used on async multiselect select
 * function used to remove empty spaces and unwanted commas
 * @param value value to be formatted
 */

export const formattingSquareBrackets = (value: string) => {
  if (value === '[, ]') {
    return '';
  }

  const valueSplited = value.split('');

  if (valueSplited[1] === ',') {
    return value.replace(',', '').replace(' ', '');
  }

  return value;
};

/**
 * Used to format analyst Names with commas or show All with the param is not array of String
 * @param analystNames list of Analyst Names
 */

export const getAnalystNames = (analystNames?: string[]) => {
  const listOfAnalystNames = analystNames?.sort((a, b) => a.localeCompare(b));

  if (analystNames?.length === 0) {
    return 'Any';
  }

  return listOfAnalystNames?.join(', ');
};

/**
 * Used on virtualized tables
 * Checks if there is overflow. Needed to hide scroll wrapper when there is not.
 * @param {string | undefined} el HTML element of external scroll
 */
export function checkOverflow(el: Element | null) {
  if (el) {
    const tableBody = document.getElementsByClassName('ant-table-body')[0];
    const tableBodyHeight = tableBody.clientHeight;
    const tableHeaderHeight = document.getElementsByClassName('ant-table-header')[0].clientHeight;
    const tableContentParentHeight = tableBody.parentElement?.clientHeight || 0;
    const tableContentHeight = tableBodyHeight + tableHeaderHeight;
    const heightDifference = tableContentParentHeight - tableContentHeight;

    return heightDifference;
  }

  return null;
}

/**
 * updateContacts updates the contact data of a person in the given array of contacts
 * @param {CompanyUserContact[]} tempDataContacts - array of contact data
 * @param {number} personId - id of the person to update
 * @param {string|null} tenureEndDate - end date of the tenure
 * @param {string|null} tenureStartDate - start date of the tenure
 * @returns {CompanyUserContact[] | null} - updated contact data or null if person not found
 */
export const updateContacts = (
  tempDataContacts: CompanyUserContact[],
  personId: number,
  tenureEndDate: string | null,
  tenureStartDate: string | null
): CompanyUserContact[] | null => {
  const isInContacts = tempDataContacts.find(contact => contact.person_id === personId);

  if (isInContacts) {
    const updatedContacts = tempDataContacts.map(contact => {
      if (contact.person_id === personId) {
        return {
          ...contact,
          tenure_end_date: tenureEndDate,
          tenure_start_date: tenureStartDate,
        };
      }

      return contact;
    });

    return updatedContacts;
  }

  return null;
};

/**
 * Maps description options to select options with labels and values.
 *
 * @param descriptions - A dictionary of descriptions to be mapped.
 * @returns {SelectOption[]} An array of select options with labels and values.
 */
export const mapDescriptionOptions = (descriptions: Dictionary<string> = {}) => {
  const newOptions: Array<SelectOption> = [];

  _each(descriptions, (description, key) => {
    newOptions.push({
      label: key === 'AI' ? 'Generated' : key,
      value: key,
    });
  });

  return mapOptionsToLabeledValues(LABEL_PREFIX, newOptions);
};

/**
 * Get the text for Tooltip components.
 *
 * @param valueList - A string list containing the options
 * @returns A string containing either the list concatenated,
 * or the default value for when list is empty
 */
export const getTooltipText = (valueList?: string[]) => {
  return valueList?.length ? normalizeCommaValues(valueList) : DEFAULT_EMPTY_VALUE;
};
