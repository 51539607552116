import React from 'react';
import { useSelector } from 'react-redux';
import { MailOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import { StatsCompare } from '@optx/shared/view/molecules/statistics';
import { AppState } from '@optx/redux/interfaces';
import { computePercent } from '@utils/number';
import StyledStatsSection from '../StatsSection/StatsSection.styles';
import EmailsSentChart from './EmailsSentChart';
import EmailsSentTitle from './EmailsSentTitle';
import { selectors } from '../../state';

// touches
const formatTouchesAverage = (value: number) => `${value} Team Average`;

// personalized emails
const formatValueWithPercent = (value: number, percent: number, hidePercentage = false) => {
  const formatPercent = `(${percent}%)`;

  return `${value} ${hidePercentage ? '' : formatPercent}`;
};

const formatAverageWithPercent = (
  value: number,
  percent: number,
  suffix = 'Team Average',
  hidePercentage = false
) => {
  const formatPercent = `(${percent}%)`;

  return `${value} ${hidePercentage ? '' : formatPercent} ${suffix}`;
};

const EmailStatistics = () => {
  const emailInfo = useSelector(selectors.emailStatistics.getEmailsStatistics);
  const isFetching = useSelector(selectors.emailStatistics.isFetching);
  const selectedInterval = useSelector((state: AppState) =>
    selectors.filters.getSingleDateRangesValue(state, 'startdatetime')
  );

  return (
    <StyledStatsSection.SubSection>
      <StyledStatsSection.SubSectionTitle>
        <MailOutlined />
        Emails
      </StyledStatsSection.SubSectionTitle>

      <Skeleton loading={isFetching}>
        {emailInfo && (
          <>
            <EmailsSentTitle />
            <EmailsSentChart
              data={emailInfo.email_touches_statistics.data}
              timePeriod={emailInfo.email_touches_statistics.datetime_period}
              selectedInterval={selectedInterval}
            />

            <StatsCompare
              title="# OF TOTAL EMAILS SENT"
              values={[
                emailInfo.email_touches_statistics.total_emails,
                emailInfo.email_touches_statistics.total_avg_emails,
              ]}
              renderCompareValue={formatTouchesAverage}
            />
            <StatsCompare
              title="# OF EMAILS SENT FROM SALES LOFT"
              values={[
                emailInfo.salesloft_email_statistics.total_email_sent,
                emailInfo.salesloft_email_statistics.team_avg_sent,
              ]}
              renderMainValue={
                value =>
                  formatValueWithPercent(
                    value,
                    computePercent(
                      emailInfo.salesloft_email_statistics.total_email_sent,
                      emailInfo.email_touches_statistics.total_emails
                    ),
                    emailInfo.email_touches_statistics.total_emails === 0
                  )
                // eslint-disable-next-line react/jsx-curly-newline
              }
              renderCompareValue={
                value =>
                  formatAverageWithPercent(
                    value,
                    computePercent(
                      emailInfo.salesloft_email_statistics.team_avg_sent,
                      emailInfo.email_touches_statistics.total_avg_emails
                    ),
                    'Team Avg.',
                    emailInfo.email_touches_statistics.total_avg_emails === 0
                  )
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
            <StatsCompare
              title="# OF EMAILS OPENED SENT FROM SALES LOFT"
              values={[
                emailInfo.salesloft_email_statistics.total_opened_sent,
                emailInfo.salesloft_email_statistics.team_avg_opened_sent,
              ]}
              renderMainValue={
                value =>
                  formatValueWithPercent(
                    value,
                    computePercent(
                      emailInfo.salesloft_email_statistics.total_opened_sent,
                      emailInfo.salesloft_email_statistics.total_email_sent
                    ),
                    emailInfo.salesloft_email_statistics.total_email_sent === 0
                  )
                // eslint-disable-next-line react/jsx-curly-newline
              }
              renderCompareValue={
                value =>
                  formatAverageWithPercent(
                    value,
                    computePercent(
                      emailInfo.salesloft_email_statistics.team_avg_opened_sent,
                      emailInfo.salesloft_email_statistics.team_avg_sent
                    ),
                    'Team Avg.',
                    emailInfo.salesloft_email_statistics.team_avg_sent === 0
                  )
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
          </>
        )}
      </Skeleton>
    </StyledStatsSection.SubSection>
  );
};

export default EmailStatistics;
