import React from 'react';
import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';
import { RouterLink, OpenInNewTabIcon } from '@shared/view/organims/Table/columns';
import { InlineEdit } from '@shared/view/molecules/edit/InlineEdit';
import { Tooltip } from 'antd';

interface CompanyNameRenderProps {
  name: string;
  companyId: string | number;
}

const stopEventPropagation = (event: React.MouseEvent) => {
  event.stopPropagation();
};

export const CompanyNameRender: React.FC<CompanyNameRenderProps> = ({ name, companyId }) => (
  <InlineEdit
    action={
      // eslint-disable-next-line react/jsx-wrap-multilines
      <RouterLink onClick={stopEventPropagation} to={`/profile/${companyId}`} target="_blank">
        <OpenInNewTabIcon iconName="open-in-new" />
      </RouterLink>
    }
  >
    <Tooltip title={name || DEFAULT_CELL_VALUE} placement="topLeft">
      <RouterLink onClick={stopEventPropagation} to={`/profile/${companyId}`}>
        {name || DEFAULT_CELL_VALUE}
      </RouterLink>
    </Tooltip>
  </InlineEdit>
);
