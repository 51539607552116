import { createAction } from '@reduxjs/toolkit';
import { CompanyMergesResponse } from '@optx/models/company/CompanyMerges';
import * as types from './types';
import {
  CompanyMergesPayload,
  SelectedCompaniesPayload,
  UnmergeCompaniesPayload,
  UnmergeCompaniesResponse,
} from './interfaces';

// fetch company touches actions
export const fetchMerges = createAction<CompanyMergesPayload>(types.fetchCompanyMerges.REQUEST);
export const fetchMergesSuccess = createAction(
  types.fetchCompanyMerges.SUCCESS,
  (response: CompanyMergesResponse, searchPayload: CompanyMergesPayload) => ({
    payload: response,
    meta: searchPayload,
  })
);
export const fetchMergesFail = createAction<string>(types.fetchCompanyMerges.FAILURE);
export const unmergeCompanies = createAction<UnmergeCompaniesPayload>(types.unmergeCompanies);
export const setSelectedCompanies = createAction<SelectedCompaniesPayload>(
  types.setSelectedCompanies
);
export const setUnmergeCompleted = createAction<boolean>(types.setUnmergeCompleted);
export const setUnmergeLoading = createAction<boolean>(types.setUnmergeLoading);
export const resetUnmerge = createAction(types.resetUnmerge);
export const toggleUnmergeModal = createAction<boolean>(types.toggleUnmergeModal);
export const toggleShouldCopyData = createAction(types.toggleShouldCopyData);
export const resetPagination = createAction(types.resetPagination);
export const setUnmergeCompanyId = createAction<UnmergeCompaniesResponse>(
  types.setUnmergeCompanyId
);
export const setUnmergeError = createAction<string>(types.setUnmergeError);
