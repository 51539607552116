import { useEffect, useState } from 'react';
import { ActionMeta, ValueType } from 'react-select';
import { isEqual } from 'lodash';
// models
import { SelectOption } from '@optx/models/Option';

/**
 * Multi select hook needed to update store only after it is blured and not on each item selection.
 * @param value multiselect store value.
 * @param updateValue function to update store value.
 */
const useLocalMultiSelect = (
  value: Array<SelectOption> | undefined,
  updateValue: (newValue: Array<SelectOption>) => void
) => {
  const [localValue, setLocalValue] = useState<ValueType<SelectOption>>(value);
  const [isLocalChange, setIsLocalChange] = useState(false);

  useEffect(() => {
    // Update local value when received value changes and was not a local change.
    if (!isLocalChange && !isEqual(localValue, value)) {
      setLocalValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleBlur = () => {
    // Update store only on blur.
    if (!isEqual(value, localValue)) {
      updateValue(localValue as Array<SelectOption>);
      setIsLocalChange(false);
    }
  };

  const handleChange = (value: ValueType<SelectOption>, actionMeta: ActionMeta<SelectOption>) => {
    switch (actionMeta.action) {
      case 'remove-value':
        // Call update if tag is removed.
        updateValue(value as Array<SelectOption>);
        break;

      default: {
        // Only update locally.
        setLocalValue(value);
        setIsLocalChange(true);
        break;
      }
    }
  };

  return {
    onBlur: handleBlur,
    onChange: handleChange,
    localValue,
  };
};

export default useLocalMultiSelect;
