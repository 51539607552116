import React, { useMemo } from 'react';
import { ResponsiveContainer, BarChart, XAxis, YAxis, Bar, Cell, Tooltip } from 'recharts';

// models
import { Histogram as HistogramDataProps } from '@optx/models/filters';
import { HistogramTooltip } from './HistogramTooltip';
import { Styled } from './Histogram.styled';

const COLOR_SELECTED = '#91D5FF';
const COLOR_DISABLED = '#f5f5f5';

// logic to change the bar colors of the histogram
const buildHistogramData = (
  list: Array<HistogramDataProps> | undefined,
  initial: number,
  final: number
) => {
  const newList: Array<HistogramDataProps> = [];

  if (list) {
    list.forEach((entry: HistogramDataProps) => {
      const newEntry = { ...entry, color: COLOR_DISABLED };
      newEntry.color =
        (entry.min_value >= initial || entry.max_value >= initial) &&
        (entry.min_value <= final || entry.max_value <= final)
          ? COLOR_SELECTED
          : COLOR_DISABLED;

      // if x length is 0, hide the bar.
      if (!entry.x) {
        newEntry.color = '#ffffff';
      }

      newList.push(newEntry);
    });
  }

  return newList;
};

export interface HistogramProps {
  histogramList?: Array<HistogramDataProps>;
  min: number;
  max: number;
}

export const Histogram: React.FC<HistogramProps> = ({ histogramList, min, max }) => {
  const data = useMemo(
    () => buildHistogramData(histogramList, min, max),
    [histogramList, max, min]
  );

  return (
    <Styled.Wrapper hidden={!data.length} className="histogram-wrapper">
      <ResponsiveContainer width="100%" height={80}>
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 5,
          }}
        >
          <XAxis hide dataKey="min_value" />
          <YAxis hide dataKey="x" allowDataOverflow domain={['dataMin', 'dataMax']} scale="sqrt" />
          <Tooltip content={<HistogramTooltip />} cursor={{ fill: '#f1f1f1' }} />
          <Bar dataKey="x" radius={[10, 10, 10, 10]} minPointSize={10}>
            {data.map((entry: HistogramDataProps, index: number) => (
              <Cell cursor="pointer" fill={entry.color} key={`cell-${index}`} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Styled.Wrapper>
  );
};
