import React from 'react';
import { useSelector } from 'react-redux';
// models
import { TableCellSearches } from '@optx/models/table/Cell';
// redux
import { selectors as savedSearchesSelectors } from '@redux/contacts/saved-searches';
// hooks
import useSavedContactsSearches from '@optx/common/hooks/saved-searches/useSavedContactsSearches';
// components
import { mapTagsToNames } from '@utils/filters/mapTagsToNames';
import FilterTags from '../company/FilterTags';

const TableCellFilters: React.FC<TableCellSearches> = ({ row }) => {
  // TODO: change filterSources code to be able to reuse the cell later
  // also use list directly
  const { handleSearchResults } = useSavedContactsSearches();

  const filterNames = mapTagsToNames(row.filteredTags!);
  const savedSearches = useSelector(savedSearchesSelectors.getSavedSearchesById);

  const handleViewList = () => {
    if (row.unique_id) {
      handleSearchResults(row.unique_id, row.title);
    }
  };

  return (
    <FilterTags filterNames={filterNames} onClick={handleViewList} savedSearches={savedSearches} />
  );
};

export default TableCellFilters;
