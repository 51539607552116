import { useInjectReducer } from 'redux-injectors';
import featureName from './featureName';
import reducer from './state';

export const useInject = () => {
  useInjectReducer({
    key: featureName,
    reducer,
  });
};
