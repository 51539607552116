import React from 'react';
import { ColumnType } from 'antd/es/table';
// models
import { CompanyTabLists } from '@optx/models/Company';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
import globalConfig from '@optx/constants/config';
// components
import { TableCellDefault, TableCellListURL, TableCellDate } from '../AntTable/cells';

export const columns: ColumnType<CompanyTabLists>[] = [
  {
    dataIndex: 'title',
    key: 'title',
    title: 'List Name',
    render: TableCellListURL,
    width: '30%',
  },
  {
    dataIndex: 'owner_name',
    key: 'owner_name',
    title: 'Owner',
    render: TableCellDefault,
  },
  {
    dataIndex: 'origin',
    key: 'origin',
    title: 'Origin',
    render: TableCellDefault,
  },
  {
    dataIndex: 'created_at',
    title: 'Start Date',
    className: 'created_at',
    sorter: true,
    align: 'left',
    render: (value: string, record: CompanyTabLists, index: number) => (
      <TableCellDate
        value={value}
        format={globalConfig.grid_date.DATE_FORMAT}
        record={record}
        defaultValue={!value || value === DEFAULT_CELL_VALUE ? DEFAULT_CELL_VALUE : undefined}
      />
    ),
  },
  {
    dataIndex: 'modified_at',
    title: 'Date Updated',
    className: 'modified_at',
    sorter: true,
    align: 'left',
    render: (value: string, record: CompanyTabLists, index: number) => (
      <TableCellDate
        value={value}
        format={globalConfig.grid_date.DATE_FORMAT}
        record={record}
        defaultValue={!value || value === DEFAULT_CELL_VALUE ? DEFAULT_CELL_VALUE : undefined}
      />
    ),
  },
];

export default columns;
