import styled from 'styled-components';
import { Drawer } from 'antd';

const StyledDrawer = styled(Drawer)`
  .list-import-error.ant-drawer-left {
    visibility: hidden;

    &.ant-drawer-open {
      visibility: visible;

      .ant-drawer-body {
        padding: 0;
        background: #f3f5f6;

        .list-import-error__header {
          background: #fff;
          border-bottom: 1px solid #e3e3e3;
        }

        .list-import-error__body {
          padding: 24px;

          .list-import-error__message {
            position: relative;
            top: 0;

            .ant-message-notice {
              text-align: left;
              padding-left: 0;

              .anticon-close-circle {
                vertical-align: text-bottom;
              }
            }
          }

          .list-import-error__companies {
            border-bottom: 1px solid #e3e3e3;
            padding: 20px 0;

            &:last-child {
              border: none;
            }

            .list-import-error__companies-button {
              text-align: right;
              padding-bottom: 20px;
            }

            .ant-descriptions {
              padding-bottom: 20px;

              .ant-descriptions-item-content {
                background-color: #fff;
              }
            }
          }
        }
      }
    }
  }
`;

export default {
  StyledDrawer,
};
