import React, { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';
// constants
import { CompanyProfileTabs } from '@optx/constants/routes';
// redux
import {
  selectors as profileSelectors,
  actions as companyProfileActions,
} from '@redux/company/profile';
import { actions as opportunityActions } from '@redux/company/opportunity-presentation';
import { actions as fundingRoundsActions } from '@redux/company/funding-rounds';
import { actions as fetchFavoritesActions } from '@redux/favorites';
// hooks
import { useHistoryTab } from '@optx/common/hooks/history';
import {
  useInject as useInjectCompanyIndividualEdit,
  useInitialFetchEditOptions,
} from '@optx/components/feature/company-individual-edit';
import { useFetchUserList } from '@optx/common/hooks/init';
import { useInject as useInjectCompanyGraphs } from '@features/company/graphs';
import { usePrevious } from '@optx/common/hooks/usePrevious';
import {
  useInjectOpportunityPresentation,
  useInjectCompanyRelationshipManagement,
} from '@optx/common/hooks/inject';
import { useInject as useInjectCompanyCard } from '@features/long-card/company-card';
// components
import { useInjectEditFields } from '@optx/features/company/edit-fields';
import { useInjectCompanyReview } from '@optx/features/company-review';
// components
import EditAllDialog from '@features/edit-all-info/components/EditAllDialog';
import CompanyTouches from '@features/add-touch/components';
import PageLoader from './PageLoader';
import ProfileBanner from './ProfileBanner';
import ProfileTabs from './ProfileTabs';
import CompanyProfileErrorComponent from './CompanyProfileErrorComponent';
// styles
import './styles.scss';

interface ProfileRouteMatch {
  id: string;
}

const Profile: React.FC = () => {
  useInjectCompanyIndividualEdit();
  useInjectEditFields();
  useInitialFetchEditOptions();
  useInjectCompanyReview();
  useInjectOpportunityPresentation();
  useInjectCompanyRelationshipManagement();
  useInjectCompanyGraphs();
  useInjectCompanyCard();

  const dispatch = useDispatch();
  useFetchUserList(false);

  const { updateHistoryFields } = useHistoryTab();
  const history = useHistory();
  const company = useSelector(profileSelectors.getProfile);
  const score = useSelector(profileSelectors.getProfileOptxScore);
  const { tab } = queryString.parse(history.location.search);
  const error = useSelector(profileSelectors.getError);
  const companyId = history.location.pathname.split('/').splice(1);
  const firstRender = useRef<boolean>(true);
  const previousScore = usePrevious(score);

  const handleUpdateOptxScore = useCallback(() => {
    updateHistoryFields({
      afterValue: score,
      beforeValue: previousScore,
      dontShowName: true,
      fieldChanged: 'OPTX SCORE',
    });
  }, [previousScore, score, updateHistoryFields]);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  useEffect(() => {
    if (tab === CompanyProfileTabs.HISTORY && firstRender.current === false) {
      setTimeout(() => {
        handleUpdateOptxScore();
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleUpdateOptxScore, score]);

  const {
    params: { id },
  } = useRouteMatch<ProfileRouteMatch>();

  // redux state/actions
  const getProfile = useCallback(
    (companyId: number) => {
      dispatch(companyProfileActions.fetchCompanyProfile(companyId));
    },
    [dispatch]
  );

  const fetchCompanyFundingRounds = useCallback(
    (companyId: number) => dispatch(fundingRoundsActions.getCompanyFundingRounds(companyId)),
    [dispatch]
  );

  const fetchFavorites = useCallback(
    () => dispatch(fetchFavoritesActions.fetchFavorites()),
    [dispatch]
  );

  // component did mount
  useEffect(() => {
    if (id) {
      const companyId = Number(id.replace(':', ''));
      getProfile(companyId);
      fetchCompanyFundingRounds(companyId);
      fetchFavorites();
    }

    return () => {
      dispatch(companyProfileActions.resetCompanyProfile());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchPresentation = useCallback(
    (companyId: number) => dispatch(opportunityActions.fetchOpportunityPresentation(companyId)),
    [dispatch]
  );

  useEffect(() => {
    fetchPresentation(Number(companyId[1]));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="profile-company">
      <PageLoader />
      {!error ? (
        company && (
          <div className="profile-content">
            <div className="profile-inner">
              <ProfileBanner company={company} />
              <div className="profile-body">
                <ProfileTabs company={company} />
              </div>
            </div>
          </div>
        )
      ) : (
        <CompanyProfileErrorComponent />
      )}
      {/* <CompanyNotes /> */}
      <CompanyTouches />
      <EditAllDialog />
    </div>
  );
};

export default Profile;
