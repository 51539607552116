import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// constants
import { INITIAL_STATE_CHART } from '@constants/charts';
// utils
import { parseReversedValues } from '@utils/charts';
// redux
import { fetchFailReducer, fetchReducer } from '@redux/feature/fetch/reducers';
import { SocialMediaChartData } from '@models/charts/data';
import { LOCATION_CHANGE } from 'connected-react-router';
import * as actions from './actions';
import { SocialMediaWebsiteRankState } from '../interfaces';

const initialState: SocialMediaWebsiteRankState = {
  data: {
    nonUs: INITIAL_STATE_CHART as SocialMediaChartData,
    us: INITIAL_STATE_CHART as SocialMediaChartData,
  },
  loading: false,
  error: '',
};

const fetchUSWebsiteRankSuccessReducer: CaseReducer<
  SocialMediaWebsiteRankState,
  PayloadAction<any>
> = (draftState, action) => {
  draftState.data.us = parseReversedValues(action.payload);
  draftState.loading = false;
};

const fetchWWWebsiteRankSuccessReducer: CaseReducer<
  SocialMediaWebsiteRankState,
  PayloadAction<any>
> = (draftState, action) => {
  draftState.data.nonUs = parseReversedValues(action.payload);
  draftState.loading = false;
};

const clearStateReducer: CaseReducer<SocialMediaWebsiteRankState> = (draftState, action) => {
  if (!action.payload.location.pathname.includes('profile')) {
    draftState.data.us = INITIAL_STATE_CHART as SocialMediaChartData;
    draftState.data.nonUs = INITIAL_STATE_CHART as SocialMediaChartData;
  }
};

export const reducer = createReducer<SocialMediaWebsiteRankState>(initialState, builder =>
  builder
    .addCase(actions.fetchWWWebsiteRank, fetchReducer)
    .addCase(actions.fetchWWWebsiteRankSuccess, fetchWWWebsiteRankSuccessReducer)
    .addCase(actions.fetchWWWebsiteRankFail, fetchFailReducer)
    .addCase(actions.fetchUSWebsiteRank, fetchReducer)
    .addCase(actions.fetchUSWebsiteRankSuccess, fetchUSWebsiteRankSuccessReducer)
    .addCase(actions.fetchUSWebsiteRankFail, fetchFailReducer)
    .addCase(LOCATION_CHANGE, clearStateReducer)
);
