import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Image, Modal, Typography, Row } from 'antd';
// models
import { SuccessErrorCallback } from '@optx/models/callback';
// redux
import { selectors as userInfoSelectors } from '@redux/user/information';
import { actions as profileImageActions } from '@redux/user/profile-image';
// hooks
import { useToggle } from '@optx/common/hooks/modal';
// components
import { PictureCrop } from '@components/common/picture';
import { Camera } from '@components/icons';
// style
import { Styled } from '../../UserProfile.styled';

/**
 * Read data as url from file input.
 * @param onLoad on file load handler.
 * @return input change chandler.
 */
const useSelectFile = (onLoad: (reader: FileReader) => void) => {
  const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => onLoad(reader));
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return onSelectFile;
};

interface ProfilePictureProps {
  className?: string;
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({ className }) => {
  const dispatch = useDispatch();
  const updateImage = (image: Blob | null, callback: SuccessErrorCallback) =>
    dispatch(profileImageActions.updateProfileImage(image, callback));

  const {
    name: displayName,
    picture,
    title,
  } = useSelector(userInfoSelectors.getProfileInformation);
  // local state
  const [isOpenModal, toggleModal] = useToggle();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const imgRef = useRef<Blob | null>(null);

  const [src, setSrc] = useState<string | null>(null);

  useEffect(() => {
    if (!isOpenModal && inputRef.current) {
      inputRef.current.value = '';
    }
  }, [isOpenModal]);

  const handleFileLoad = (reader: FileReader) => {
    setSrc(reader.result as string);
    toggleModal();
  };

  const onSelectFile = useSelectFile(handleFileLoad);

  /**
   * Crop complete callback.
   * OBS: this method is called each time the crop completes. Don't use state updates in it.
   * @param image
   */
  const handleCropComplete = (image: Blob | null) => {
    imgRef.current = image;
  };

  const handleSubmit = () => {
    const callback: SuccessErrorCallback = success => {
      if (success) {
        toggleModal();
      }
    };

    updateImage(imgRef.current, callback);
  };

  return (
    <>
      <Styled.ProfilePictureCard className={className} bordered={false}>
        <div className="media-object">
          <Styled.Label htmlFor="fileUpload" className="media-profile-picture">
            <Image
              width={100}
              height={100}
              src={picture}
              alt="user"
              className="rounded-circle"
              onClick={toggleModal}
            />
            <Camera />
            <Styled.HiddenInput
              id="fileUpload"
              ref={inputRef}
              type="file"
              accept="image/*"
              onChange={onSelectFile}
            />
          </Styled.Label>
          <Typography.Text className="name">{displayName}</Typography.Text>
          <Typography.Text>{title}</Typography.Text>
        </div>
      </Styled.ProfilePictureCard>
      <Modal visible={isOpenModal} onOk={handleSubmit} onCancel={toggleModal}>
        <Typography.Title>User Profile Photo</Typography.Title>
        <Row justify="center">
          <PictureCrop src={src} onCropComplete={handleCropComplete} />
        </Row>
      </Modal>
    </>
  );
};

export default ProfilePicture;
