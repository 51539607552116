import styled from 'styled-components';
import Card from '@components/common/cards';
import { Typography } from 'antd';

const StyledCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;

  .reports-card__logo {
    position: absolute;
    height: 62px;
    width: 62px;

    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .ant-card-head {
    height: 90px;
    position: relative;
    background: linear-gradient(79.88deg, #072d4f -1.05%, #074d92 105.51%);
    border-radius: 4px 4px 0px 0px;
  }

  .ant-card-body {
    flex: 1;
    padding: 15px 24px;
    height: 200px;

    .ant-typography {
      padding: 0;
    }
  }
`;
const StyledCardTitle = styled(Typography.Title)`
  font-style: normal;
  font-size: 16px;
  margin-top: 33px;
  /* identical to box height, or 140% */

  text-align: center;
`;

const StyledDescription = styled(Typography.Text)`
  display: block;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
`;

export default {
  Card: StyledCard,
  CardTitle: StyledCardTitle,
  CardDescription: StyledDescription,
};
