import { useCallback, RefObject } from 'react';

interface UseAsyncMenuPositionOptions {
  parentEl: RefObject<HTMLElement>;
  isHeightOverflow?: boolean;
  distanceFromBottom?: number;
}

/**
 * A custom hook that determines the optimal position for a menu based on the
 * provided options and the current state of the parent element and window.
 *
 * @param {UseAsyncMenuPositionOptions} options - Configuration options for determining the menu position.
 * @param {React.RefObject<HTMLElement>} options.parentEl - A reference to the parent element of the menu.
 * @param {number} [options.distanceFromBottom=200] - The minimum distance from the bottom of the window to the menu.
 * @param {boolean} options.isHeightOverflow - A flag indicating if the height of the menu overflows the window height.
 *
 * @returns {Object} An object containing the `getMenuPlacement` function.
 * @returns {Function} returns.getMenuPlacement - A function that calculates and returns the
 * optimal menu placement ('top', 'bottom', or 'auto').
 */

const useAsyncMenuPosition = (options: UseAsyncMenuPositionOptions) => {
  const { parentEl, distanceFromBottom = 200, isHeightOverflow } = options;

  const getMenuPlacement = useCallback(() => {
    if (isHeightOverflow) {
      return 'auto';
    }

    const editPositionH = parentEl.current?.getBoundingClientRect().top;
    const windowH = window.innerHeight;

    if (editPositionH && windowH) {
      if (windowH - editPositionH < distanceFromBottom) {
        return 'top';
      } else {
        return 'bottom';
      }
    }

    return 'auto';
  }, [parentEl, isHeightOverflow, distanceFromBottom]);

  return {
    getMenuPlacement: getMenuPlacement,
  };
};

export default useAsyncMenuPosition;
