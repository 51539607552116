import React from 'react';
import { Tag } from 'antd';
import { TagProps } from 'antd/lib/tag';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// models
import { FormCheckableRangeOption } from '@models/Option';
// utils
import { searchInNewTab } from '@optx/utils/search';
// redux
import { actions as searchActions } from '@features/grid/search';
import { selectors as filterSourcesSelectors } from '@redux/company/filters';
import { selectors as userInformationSelectors } from '@redux/user/information';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';

interface IsPSGCompanyTagProps extends TagProps {
  isCompany: boolean;
  newTab?: boolean;
}

const IsPSGCompanyTag: React.FC<IsPSGCompanyTagProps> = ({
  isCompany,
  newTab,
  onClick,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const clearedFilter = useSelector(filterSelectors.getClearedFilter('advancedSearch'));
  const sources = useSelector(filterSourcesSelectors.getFiltersMap);
  const filter = useSelector(filterSelectors.getFilter('advancedSearch'));
  const scoreType = useSelector(userInformationSelectors.getDefaultScore);
  const { advancedSearchPathname } = useIsAnalyst();

  if (!isCompany) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const filterKey = scoreType === 'us' ? 'score' : 'il_optx_score';

    const filterValue = (filter[filterKey] as FormCheckableRangeOption[]).map(option => {
      const check = option.check?.map(checkbox =>
        checkbox.value === 'is_psg_only' ? { ...checkbox, checked: true } : checkbox
      );

      return { ...option, check };
    });

    if (newTab) {
      searchInNewTab(clearedFilter, sources, filterKey, filterValue, {}, advancedSearchPathname);
    } else {
      history.push(advancedSearchPathname);
      dispatch(
        searchActions.loadCompaniesByFilter(filterKey, {
          gridKey: 'advancedSearch',
          data: filterValue,
        })
      );

      onClick && onClick(event);
    }
  };

  return (
    <Tag className="special-tag show-child" onClick={handleClick} {...restProps}>
      PSG Portfolio Company
    </Tag>
  );
};

export default IsPSGCompanyTag;
