import { isEqual } from 'lodash';
// models
import { FilterFormat } from '@models/filters';
import Option, { APIMultiSelectOption, SelectOption } from '@models/Option';
import { FinancialFieldRangeValue, FinancialFieldType } from '@optx/models/Company';
import { FinancialField } from '@optx/features/field-factory';

/**
 * Map lists of Option (from API) or string to SelectOption.
 * @param option
 * @param format
 */
export const mapToSelectOption = (option: string | Option<string>, format?: FilterFormat) => {
  const value = typeof option === 'string' ? option : option.value?.toString();
  let label = '';
  let parent = undefined;

  if (typeof option === 'string') {
    label = option;
  } else {
    label = option.name || option.value;

    if (option.parent) {
      parent = option.parent;
    }
  }

  return {
    value,
    label,
    parent,
    disabled: (option as Option<string>).disabled,
    entries: (option as APIMultiSelectOption).entries,
    ...(typeof option !== 'string' && typeof option.count === 'number' && { count: option.count }),
  };
};

/**
 * function to get a dropdown option's text, by searching through using it's value
 * @param {string | number} value the value we use to identify the option
 * @param {SelectOption[]} options list of options to look through
 */
export const getOptionLabel = (value: string | number | undefined, options: SelectOption[]) => {
  let label = value;
  const index = options.findIndex(option => option.value === value);

  if (index !== -1) {
    label = options[index].label;
  }

  return label;
};

/**
 * Returns an array of labels or values from a given array of SelectOption objects
 * @param {'label' | 'value'} key - The key to be used to get the labels or values
 * @param {SelectOption[]} options - The array of SelectOption objects
 */
export const getArrayLabels = (key: 'label' | 'value', options: SelectOption[]) => {
  const listArray = options.map(option => option[key]);

  return listArray;
};

/**
 * function to check if a value is part of a set of options
 * @param {string | number | null} value value to check against
 * @param {SelectOption[] | undefined} options set of options
 */
export const isOptionChecked = (
  value: string | number | null | FinancialFieldRangeValue,
  options?: SelectOption[]
) =>
  options?.some(option =>
    isEqual(typeof value !== 'object' ? option.value : option.rangeValue, value)
  );

/**
 * find the most recent year that has an amount associated and return that value
 * @param {FinancialFieldType[] | null} options list of options
 * @param {number} [year] - An optional year to filter the financial options.
 */

export const getMostRecentAmount = (options: FinancialFieldType[] | null, year?: number) => {
  if (options && options.length) {
    if (year) {
      return options.find(option => option.year === year)?.value ?? '';
    }

    return options[0].value;
  }

  return undefined;
};

/**
 * get the most recent year available from the list
 * @param {FinancialFieldType[] | null} options list of options
 */
export const getMostRecentYear = (options: FinancialFieldType[] | null) => {
  if (options && options.length) {
    return options[0].year;
  }

  return undefined;
};

/**
 * get the most recent source available from the list
 * @param {FinancialFieldType[] | null} options list of options
 */
export const getMostRecentSource = (options: FinancialFieldType[] | null) => {
  if (options && options.length) {
    return !options.some(option => option.from_source === undefined);
  }

  return false;
};

/**
/* Generates an array of years for use in a select dropdown.
* @param extraYear - Optional extra year to be included in the list.
* @param listOfFinancialYears - Optional list of financial years to check against.
* @param isEstimatedYear - Optional flag to indicate if the year is estimated.
* @param isFinancialTable - Optional flag to indicate if the list is for a financial table.
* @returns {Array<SelectOption>} An array of objects containing the year values and labels
*/
export const generateYears = (
  extraYear?: number,
  listOfFinancialYears?: FinancialField[],
  isEstimatedYear?: boolean,
  isFinancialTable?: boolean
): SelectOption[] => {
  const MAX_YEAR_RANGE = 5;
  const yearRegex = /^(19|20)\d{2}$/;
  const maxYear = new Date().getFullYear() + 2;
  const minYear = new Date().getFullYear() - 2;

  const years: SelectOption[] = [];

  for (let i = 0; i < MAX_YEAR_RANGE; i++) {
    const value = maxYear - i;
    const listOfYears = listOfFinancialYears?.reduce((acc: number[], list: FinancialField) => {
      const isYearInList = isFinancialTable
        ? list.year === value
        : list.estimated === isEstimatedYear;

      if (isYearInList) {
        acc.push(list.year);
      }

      return acc;
    }, []);

    const isValueInList = listOfYears && listOfYears.find(year => year === value);

    if (isValueInList) {
      years.push({ value: value.toString(), label: value.toString(), disabled: true });
    } else {
      years.push({ value: value.toString(), label: value.toString(), disabled: false });
    }
  }

  if (
    extraYear &&
    extraYear <= maxYear + 3 &&
    extraYear >= minYear - 18 &&
    yearRegex.test(extraYear.toString())
  ) {
    const isExtraYearDataDisabled =
      isFinancialTable && listOfFinancialYears?.some(year => year.year === extraYear);

    const extraYearData = {
      value: extraYear!.toString(),
      label: extraYear!.toString(),
      disabled: isExtraYearDataDisabled,
    };

    if (extraYear > maxYear) {
      return [extraYearData, ...years];
    }

    if (extraYear < minYear && extraYear < maxYear) {
      return [...years, extraYearData];
    }
  }

  return years;
};
