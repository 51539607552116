import styled from 'styled-components';
// styles
import StyledTooltip from '@optx/components/common/charts/Tooltip.styles';

const Label = styled(StyledTooltip.Label)`
  white-space: pre-line;
`;

const Total = styled.div<{ $selected: boolean }>`
  color: ${props => (props.$selected ? '#1890FF' : '#C2CFE0')};
`;

const UserNames = styled.div`
  color: #1890ff;
  white-space: normal;
`;

export default {
  Label,
  Total,
  UserNames,
};
