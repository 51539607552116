import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { Dictionary, isEqual } from 'lodash';
import { matchPath } from 'react-router';
// models
import { GridKeys, GridPayload } from '@optx/models/grid';
import { Filter, FilterSource } from '@optx/models/filters';
import { CompanyFiltersMeta, SearchSaveResponse } from '@optx/models/search';
import { SearchPayload } from '@optx/models/api/contacts';
import Company from '@optx/models/Company';
import { UserInformation } from '@optx/models/user';
import { FilterState } from './interfaces';
// constants
import appRoutes from '../../../../constants/routes';
import { FILTERS_INITIAL_STATE, GRID_FILTER_KEYS } from '@optx/constants/grid';
import { myCompaniesPreselectedFilter } from './constants';
// utils
import { mapPreselectedFilters } from '../../../../utils/filters/preselectedValues';
import { parseFilter } from '../../../../utils/filters/parseFilters';
import { generateGridReducerInitialState, getLocationGridKey } from '@utils/grid';
// redux
import { actions as companyFiltersActions } from '@redux/company/filters/index';
import { actions as savedSearchesActions } from '@redux/company/saved-searches/index';
import { actions as searchActions } from '@features/grid/search';
import { actions as listsActions } from '@redux/lists/details/index';
import { actions as userInformationActions } from '@redux/user/information/index';
import { actions as myCompaniesActions } from '@optx/redux/my-companies/filters-cards/index';
import { actions as addonManagementActions } from '@optx/redux/company/addon-management';

const initialState: FilterState = generateGridReducerInitialState(
  GRID_FILTER_KEYS,
  FILTERS_INITIAL_STATE
);

// external actions reducers
export const fetchFiltersSuccessReducer: CaseReducer<
  FilterState,
  PayloadAction<Array<FilterSource>, any, CompanyFiltersMeta>
> = (draftState, action) => {
  const preselected = mapPreselectedFilters(action.payload);
  const clearedFilter = mapPreselectedFilters(action.payload, false);

  const sourceScrubListMatch = matchPath(window.location.pathname, {
    path: appRoutes.userLists.sourceScrubLists,
    exact: true,
  });

  const userListMatch = matchPath(window.location.pathname, {
    path: appRoutes.userLists.list,
    exact: true,
  });

  const isSsList = sourceScrubListMatch && sourceScrubListMatch;
  const isUserList = userListMatch && userListMatch;

  GRID_FILTER_KEYS.forEach(key => {
    if (key === 'watchlists' || key === 'sslists') {
      draftState[key].preselected = clearedFilter;
    } else if (key === 'myCompanies') {
      draftState[key].preselected = myCompaniesPreselectedFilter;
    } else {
      draftState[key].preselected = preselected;
    }

    // Set custom cleared filter for each grid.
    let customClearedFilter = clearedFilter;

    if (key === 'advancedSearch') {
      customClearedFilter = {
        ...clearedFilter,
        is_software: preselected.is_software,
      };
    }

    if (key === 'myCompanies') {
      customClearedFilter = {
        ...clearedFilter,
        ...draftState.myCompanies.preselected,
      };
    }

    draftState[key].customClearedFilter = customClearedFilter;
    draftState[key].clear = clearedFilter;

    let currentFilter = preselected;

    const searchPathname = action.meta.isAnalyst ? appRoutes.advancedSearch : appRoutes.home;

    /**
     * '?usefor=testing' is used for testing purposes only.
     * We don't want to load the filter from the query
     * and use persisted filters to load.
     *
     */
    if (
      window.location.search &&
      window.location.pathname === searchPathname &&
      window.location.search !== '?usefor=testing'
    ) {
      // handle redirect with filter.It has priority over persisted filter.
      const queryFilter = window.location.search.replace('?', '');
      const [parsedFilters] = parseFilter(queryFilter, action.payload);

      currentFilter = parsedFilters;
    } else if (
      key === 'advancedSearch' &&
      draftState.advancedSearch.persistedFilter !== undefined
    ) {
      // Load persisted filter if available.
      const [parsedFilters] = parseFilter(
        draftState.advancedSearch.persistedFilter,
        action.payload
      );
      currentFilter = parsedFilters;
    }

    if (window.location.search && (isSsList || isUserList)) {
      // handle redirect with filter.It has priority over persisted filter.
      const queryFilter = window.location.search.replace('?', '');
      const [parsedFilters] = parseFilter(queryFilter, action.payload);

      currentFilter = parsedFilters;
    } else if (key === 'sslists' && draftState.sslists.persistedFilter !== undefined) {
      // Load persisted filter if available.
      const [parsedFilters] = parseFilter(draftState.sslists.persistedFilter, action.payload);
      currentFilter = parsedFilters;
    } else if (key === 'watchlists' && draftState.watchlists.persistedFilter !== undefined) {
      // Load persisted filter if available.
      const [parsedFilters] = parseFilter(draftState.watchlists.persistedFilter, action.payload);
      currentFilter = parsedFilters;
    }

    if (key === 'myCompanies' && draftState.myCompanies.persistedFilter !== undefined) {
      // Load persisted filter if available.
      const [parsedFilters] = parseFilter(draftState.myCompanies.persistedFilter, action.payload);
      currentFilter = parsedFilters;
    } else if (key === 'myCompanies') {
      currentFilter = draftState[key].customClearedFilter;
    }

    draftState[key].filter = currentFilter;
    // remove persisted filter, is not needed anymore.
    delete draftState[key].persistedFilter;

    draftState[key].fetchedAt = new Date().toISOString();
  });
};

export const searchCompaniesSuccessReducer: CaseReducer<
  FilterState,
  PayloadAction<Array<Company>, any, GridPayload<Partial<SearchPayload>>>
> = (draftState, action) => {
  const { data, gridKey } = action.meta;

  if (!gridKey) return;

  if (gridKey === 'lists') {
    const listKey = getLocationGridKey();

    if (
      listKey &&
      data?.filter &&
      !isEqual(draftState[listKey as GridKeys]?.filter, data?.filter)
    ) {
      draftState[listKey as GridKeys].filter = data.filter;
    }
  }

  if (GRID_FILTER_KEYS.includes(gridKey)) {
    if (data?.filter && !isEqual(draftState[gridKey].filter, data.filter)) {
      draftState[gridKey].filter = data.filter;
    }
  }
};

export const filterCompanyListSuccessReducer: CaseReducer<FilterState, PayloadAction<string>> = (
  draftState,
  action
) => {
  const gridKey = getLocationGridKey();
  if (!gridKey) return;

  draftState[gridKey].filter.saved_list_id = action.payload;
};

export const clearCompanyListReducer: CaseReducer<FilterState> = draftState => {
  const gridKey = getLocationGridKey();
  if (!gridKey) return;

  delete draftState[gridKey].filter.saved_list_id;
  delete draftState[gridKey].filter.ss_list_id;
};

// Update persisted filter to be used when filters are loaded.
const fetchUserInformationSuccess: CaseReducer<FilterState, PayloadAction<UserInformation>> = (
  draftState,
  action
) => {
  const { settings } = action.payload;

  // advanced search
  if (settings.session_settings.company_filters) {
    draftState.advancedSearch.persistedFilter = settings.session_settings.company_filters;
  }

  // my companies
  if (settings.session_settings.my_companies_filters) {
    draftState.myCompanies.persistedFilter = settings.session_settings.my_companies_filters;
  }

  // lists
  if (settings.session_settings.user_list_filters) {
    draftState.watchlists.persistedFilter = settings.session_settings.user_list_filters;
    draftState.sslists.persistedFilter = settings.session_settings.user_list_filters;
  }

  // addons
  if (settings.session_settings.addon_filters) {
    draftState.addons.persistedFilter = settings.session_settings.addon_filters;
  }

  // sslist
  if (settings.session_settings.source_scrub_list_filters) {
    draftState.sslists.persistedFilter = settings.session_settings.source_scrub_list_filters;
  }
};

const initializeSavedSearchesAndViewsSuccessReducer: CaseReducer<
  FilterState,
  PayloadAction<
    Array<SearchSaveResponse> | Dictionary<SearchSaveResponse>,
    any,
    {
      filterSources: Array<FilterSource<Filter<any>>>;
      isAnalyst?: boolean;
    }
  >
> = (draftState, action) => {
  const allSavedSearches = action.payload as Array<SearchSaveResponse>;
  const { filterSources, isAnalyst } = action.meta;

  const query = window.location.search ? window.location.search.replace('?', '') : undefined;

  const listId = query?.split('=')[1];
  const list = query?.split('=')[0] === 'saved_search_id';

  const searchPathname = isAnalyst ? appRoutes.advancedSearch : appRoutes.home;

  if (list && window.location.pathname === searchPathname) {
    const savedSearch = allSavedSearches.find(search => search.unique_id.toString() === listId);

    if (savedSearch) {
      const queryFilter = savedSearch.search_criteria;
      const [parsedFilters] = parseFilter(queryFilter, filterSources);
      draftState.advancedSearch.filter = parsedFilters;
    }
  }
};

// MyCompanies change card reducer
const changeCardReducer: CaseReducer<FilterState, PayloadAction<string>> = (draftState, action) => {
  draftState.myCompanies.filter = {
    ...draftState.myCompanies.preselected,
    ...draftState.myCompanies.filter,
  };
  draftState.myCompanies.customClearedFilter = {
    ...draftState.myCompanies.customClearedFilter,
    ...draftState.myCompanies.preselected,
  };
};

// Addons clear filter reducer
const clearAddonManagementFilterReducer: CaseReducer<FilterState> = draftState => {
  draftState.addons.filter = {
    ...draftState.addons.customClearedFilter,
  };
};

const reducer = createReducer<FilterState>(initialState, builder =>
  builder
    // external reducers
    .addCase(addonManagementActions.clearSearch, clearAddonManagementFilterReducer)
    .addCase(companyFiltersActions.fetchCompanyFiltersSuccess, fetchFiltersSuccessReducer)
    .addCase(searchActions.searchCompaniesSuccess, searchCompaniesSuccessReducer)
    .addCase(myCompaniesActions.changeCard, changeCardReducer)
    // lists
    .addCase(listsActions.filterCompanyListSuccess, filterCompanyListSuccessReducer)
    .addCase(listsActions.clearCompanyList, clearCompanyListReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccess)
    .addCase(
      savedSearchesActions.initializeSavedSearchesAndViewsSuccess,
      initializeSavedSearchesAndViewsSuccessReducer
    )
);

export default reducer;
