import React from 'react';
import { useSelector } from 'react-redux';
// models
import Company from '@models/Company';
import { Cell } from '@models/table/Cell';
// redux
import { selectors as viewTypeSelectors } from '@features/grid/view-type';
// components
import DropdownAdd from '@components/DropdownAdd';

const TableCellMyWatchlistsActions: React.FC<Cell<Company>> = ({ value, record, index }) => {
  const view = useSelector(viewTypeSelectors.searchView('lists'));

  return <DropdownAdd record={record} index={index} view={view} />;
};

export default TableCellMyWatchlistsActions;
