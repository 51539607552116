import React from 'react';
import { OptionProps } from 'react-select';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
// utils
import { getListURL } from '@optx/utils/routes';
// constants
import { SELECT_ALL } from '@constants/select';
// components
import Icon from '@components/common/Icon';
import Styled from './Option.styled';

export { Styled };

/**
 * Option inside the dropdown list.
 * OBS: this option fixes option label click closing modal.
 * TO FIX LATER: the checkbox still closes modal when clicking on bottom right corner margin :(.
 * If children is in checkbox it will close on selection outside checkbox.
 * If needs to be changed and allow selection on entire option the children should be outside check.
 */
export const Option: React.FC<OptionProps<any>> = props => {
  const { children, ...restProps } = props;
  const { isSelected, data, isDisabled } = restProps;

  return (
    // @ts-ignore react-select theme is claching with styled-components theme property.
    // react-select property overrides the styled components one,
    // meaning getting dynamic values in styled components won't work.
    <Styled.Option
      highlight={data.value === SELECT_ALL.value}
      className={(data.className as string) + (data.parent ? ' sub-option' : '')}
      {...restProps}
    >
      <Styled.Checkbox checked={isSelected} disabled={isDisabled} />

      <Tooltip title={children}>
        <span className={`option-value${data.isBold ? '-bold' : ''}`} data-value={children}>
          {children}
        </span>
      </Tooltip>
      {data.isET && <Styled.Tag color="gold">ET</Styled.Tag>}
      {data.isPublic && <Styled.Tag color="gold">Public</Styled.Tag>}
      {typeof data.count === 'number' && <span>&nbsp;({data.count})</span>}
      {typeof data.count === 'number' && data.value !== 'blank' && data.canBeOpened && (
        <div
          className="open-in-new-wrapper"
          onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            event.stopPropagation();
          }}
        >
          <Link className="open-in-new" to={getListURL(data.value)} target="_blank">
            <Icon iconName="open-in-new" />
          </Link>
        </div>
      )}
    </Styled.Option>
  );
};

export default Option;
