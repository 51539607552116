import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import reducer from './reducer';
import { sagas as paginationSagas } from './pagination';
import { saga as searchCountSaga } from './search-count';
import { sagas as paginationSaga } from './pagination';
import { saga as viewSaga } from './view';
import { saga as autocompleteSaga } from './autocomplete';
import { sagas as filterSagas } from './filter';
import { saga as searchSaga } from './search';
import { saga as viewTypeSaga } from './view-type';
import { sagas as paginationLongCardSaga } from './pagination-long-card';
import { featureName } from './featureName';

const useInject = () => {
  useInjectReducer({ key: featureName, reducer });
  useInjectSaga({ key: 'pagination', saga: paginationSagas });
  useInjectSaga({ key: 'autocomplete', saga: autocompleteSaga });
  useInjectSaga({ key: 'search-count', saga: searchCountSaga });
  useInjectSaga({ key: 'pagination', saga: paginationSaga });
  useInjectSaga({ key: 'view', saga: viewSaga });
  useInjectSaga({ key: 'autocomplete', saga: autocompleteSaga });
  useInjectSaga({ key: 'filter', saga: filterSagas });
  useInjectSaga({ key: 'search', saga: searchSaga });
  useInjectSaga({ key: 'viewType', saga: viewTypeSaga });
  useInjectSaga({ key: 'paginationLongCard', saga: paginationLongCardSaga });
};

export default useInject;
