import React from 'react';
import { ColumnType } from 'antd/lib/table';
// redux
import { CompanyImportFailedData } from '@optx/models/Company';
// components
import {
  TableCellURL,
  TableCellCompanyName,
  TableCellStatus,
} from '@components/common/table/AntTable/cells';
import { TableCellErrorReason } from '@optx/components/common/table/TableBulkEditingResults/cells';
import { TableCellSimilarCompanies, TableCellActions } from './cells';

export const columns: ColumnType<CompanyImportFailedData>[] = [
  {
    dataIndex: 'URL',
    title: '',
    render: (value, record) => <TableCellURL value={value} record={{ url: record.raw_url }} />,
    width: 42,
    align: 'center',
  },
  {
    dataIndex: 'Company',
    title: 'Company Name',
    render: TableCellCompanyName,
  },
  {
    dataIndex: 'count_possible_matches',
    title: 'Similar Companies',
    render: (value: number | undefined, record: CompanyImportFailedData, index: number) => (
      <TableCellSimilarCompanies value={value} record={record} index={index} />
    ),
  },
  {
    dataIndex: 'Reason',
    title: 'Sync Status',
    render: TableCellStatus,
  },
  {
    dataIndex: 'Reason',
    title: 'Error Reason',
    render: TableCellErrorReason,
  },
  {
    dataIndex: 'row_actions',
    title: 'Action',
    className: 'row_actions',
    render: (value: unknown, record: CompanyImportFailedData, index: number) => (
      <TableCellActions value={value} record={record} index={index} />
    ),
    width: 80,
    fixed: 'right',
  },
];
