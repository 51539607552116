import { createAction } from '@reduxjs/toolkit';
// models
import * as types from './actionTypes';
import { RelationshipManagement } from './interfaces';

export const fetchRelationshipManagement = createAction<number>(
  types.FETCH_RELATIONSHIP_MANAGEMENT
);
export const fetchRelationshipManagementSuccess = createAction<RelationshipManagement>(
  types.FETCH_RELATIONSHIP_MANAGEMENT_SUCCESS
);
export const fetchRelationshipManagementFail = createAction<string>(
  types.FETCH_RELATIONSHIP_MANAGEMENT_FAIL
);
