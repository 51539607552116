export const FAVORITE_LIST_ID = 'favorites';
export const FAVORITE_LIST_NAME = 'Favorites';
export const FAVORITE_LIST_OWNER = 'OPTX';
export const FAVORITE_LIST_SELECT_OPTIONS = [
  {
    value: 'recently',
    label: 'Recently accessed',
  },
  {
    value: 'title-asc',
    label: 'Name A to Z',
  },
  {
    value: 'title-desc',
    label: 'Name Z to A',
  },
  {
    value: 'owner',
    label: 'Owner',
  },
];
