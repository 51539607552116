import { AxiosResponse } from 'axios';
import { Dictionary } from 'lodash';
import columns from '../features/equity-touch-integration/components/CompaniesInETTable/columns';
import { CompanyService } from '../services/api';
import NotificationService from '../services/NotificationService';

interface ExportETColumns {
  columns: Dictionary<string>;
}

export const exportFileCSV = (fileName: string, query: string) => {
  const gridHeaders: ExportETColumns = { columns: {} };
  columns.forEach(column => {
    const headerKey = column.dataIndex as string;
    const title = column.title;

    gridHeaders.columns[headerKey] = title as string;

    if (column.dataIndex === 'url') {
      gridHeaders.columns.url = 'URL';
    }
  });

  return CompanyService.exportETIntegrationToCSV(query, gridHeaders)
    .then((response: AxiosResponse<string>) => {
      const element = document.createElement('a');

      element.setAttribute(
        'href',
        `data:application/csv;charset=utf-8, ${encodeURIComponent(response.data)}`
      );
      element.setAttribute('download', `${fileName}.csv`);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      NotificationService.success('Export CSV file success!');
    })
    .catch(() => {
      NotificationService.error('Export CSV file error');
    });
};
