import React from 'react';
import { StepBackwardOutlined, StepForwardOutlined } from '@ant-design/icons';
import { Divider, Button } from 'antd';
import styled from 'styled-components';
// utils
import { profileRedirectPath } from '@optx/utils/routes';
// hooks
import { useHistory, useLocation } from 'react-router-dom';

const StyledDivider = styled(Divider)`
  border-right: 2px solid #b0d2f0;
  height: 20px;
`;

interface ProfileNavigationProps {
  previousCompanyId: number;
  nextCompanyId: number;
}

const ProfileNavigation: React.FC<ProfileNavigationProps> = ({
  previousCompanyId,
  nextCompanyId,
}) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <div className="profile-navigation">
      <Button
        type="link"
        disabled={!previousCompanyId}
        onClick={() => {
          previousCompanyId &&
            history.push(profileRedirectPath(Number(previousCompanyId), location.pathname));
        }}
      >
        <StepBackwardOutlined />
        Previous Company
      </Button>
      <StyledDivider type="vertical" />
      <Button
        type="link"
        disabled={!nextCompanyId}
        onClick={() => {
          nextCompanyId &&
            history.push(profileRedirectPath(Number(nextCompanyId), location.pathname));
        }}
      >
        <StepForwardOutlined />
        Next Company
      </Button>
    </div>
  );
};

export default ProfileNavigation;
