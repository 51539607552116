import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
// components
import SearchInput from '@optx/components/common/form/input/SearchInput';
import SearchCompaniesHints from '@optx/components/pages/Home/SearchCompaniesHints';

export interface SearchInputTempProps {
  onSearch: (key: string) => void;
  onChange: (newKey: string) => void;
  keySearch: string | undefined;
  inputRef?: ((instance: Input | null) => void) | React.RefObject<Input> | null;
}

const SearchInputTemp: React.FC<SearchInputTempProps> = ({
  onSearch,
  onChange,
  keySearch,
  inputRef,
}) => {
  const [inputSearch, setInputSearch] = useState(keySearch);

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearch(event.target.value);
  };

  useEffect(() => {
    setInputSearch(keySearch);
  }, [keySearch]);

  return (
    <div className="filter-submit__search-input">
      <SearchInput
        onSearch={onSearch}
        value={inputSearch}
        onChange={handleChange}
        onBlur={handleBlur}
        suffix={<SearchCompaniesHints />}
        ref={inputRef}
        placeholder="Search for companies by keyword (3 chars or more)"
      />
    </div>
  );
};

export default SearchInputTemp;
