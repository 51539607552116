import { createSelector } from 'reselect';
import { selectors as localSelectors } from '../../employee';
import { selectFeatureState } from './selectFeature';

const employeeGrowthState = createSelector(selectFeatureState, state => {
  return state.employeeGrowth;
});

// slice selectors
const getEmployeeGrowthData = createSelector(employeeGrowthState, localSelectors.getData);

const isLoading = createSelector(selectFeatureState, state => {
  return state.employeeGrowth.loading;
});

export default {
  getEmployeeGrowthData,
  isLoading,
};
