import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// models
import { FilterTag, SpecialFilterTag } from '@optx/models/tags';
// redux
import { selectors as profileSelectors } from '@redux/company/profile';
import { actions as searchActions } from '@features/grid/search';
import { actions as modalActions } from '@redux/ui/modals/gridModals';
import { actions } from '@redux/company/addon-management';
// components
import { FilterTagsOverflow } from '@optx/features/tags/overflow-tags';

export const FilterTags: React.FC<{ options: FilterTag[] }> = ({ options }) => {
  const dispatch = useDispatch();
  const companyId = useSelector(profileSelectors.getCompanyId);

  const toggleFiltersModal = useCallback(
    () => dispatch(modalActions.toggleModal({ gridKey: 'addons' })),
    [dispatch]
  );

  const handleFilterRemove = (tag: FilterTag) => () => {
    if ((tag.filter as SpecialFilterTag) === 'keyword') {
      dispatch(
        searchActions.searchCompanies({
          gridKey: 'addons',
          data: { searchKey: '', shouldResetPageNumber: true, companyId },
        })
      );

      return;
    }

    dispatch(
      searchActions.resetFilter({
        gridKey: 'addons',
        data: { selectedTag: tag, filterTags: filteredOptions },
      })
    );
    dispatch(actions.resetAdditionalFilter(tag));
  };

  const filteredOptions = useMemo(
    () => options.filter(item => !item.isDefault && item.values?.length > 0),
    [options]
  );

  return (
    <FilterTagsOverflow
      options={filteredOptions}
      onClick={toggleFiltersModal}
      onClose={handleFilterRemove}
      multipleValue
    />
  );
};

export default FilterTags;
