import React, { ReactHTML, HTMLAttributes } from 'react';

interface DangerousInnerHTMLProps extends HTMLAttributes<HTMLElement> {
  type?: keyof ReactHTML;
  innerHTML: string;
}

interface DangerouslySetInnerHTML {
  __html: string;
}

const DangerousInnerHTML = ({
  type = 'div',
  innerHTML,
  children,
  ...restProps
}: DangerousInnerHTMLProps) => {
  const dangerouslySetInnerHTML: DangerouslySetInnerHTML = {
    __html: innerHTML,
  };

  return React.createElement(type, { ...restProps, dangerouslySetInnerHTML });
};

export default DangerousInnerHTML;
