import { call, put, takeLatest, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { values as _values } from 'lodash';
// models
import { SortByRule } from '@models/table/sorting';
import {
  UserInfo,
  UserEdit,
  UserManagement as UserManagementType,
} from '@optx/models/UserManagement';
// services
import NotificationService from '@services/NotificationService';
import { UserManagement, UserService } from '@services/api';
// utils
import { getErrorMessage } from '@optx/utils/api/errors';
// redux
import { AxiosResponse } from 'axios';
import * as selectors from './selectors';
import * as actions from './actions';

export function* fetchUserManagementSaga(
  action: PayloadAction<Array<SortByRule<any>> | undefined>
) {
  let sorting: SortByRule<any>[] = yield select(selectors.getSorting);

  if (action.payload) {
    sorting = action.payload;
  }

  try {
    const res: AxiosResponse<UserManagementType[]> = yield call(
      UserManagement.getUserList,
      sorting
    );

    if (res.data) {
      yield put(actions.fetchUserManagementSuccess(res.data));

      if (action.payload) {
        yield put(actions.changeSortSuccess(action.payload));
      }
    } else {
      const errorMessage = 'Failed to fetch User Information!';
      yield put(actions.fetchUserManagementFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = 'Failed to fetch User Information!';
    yield put(actions.fetchUserManagementFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* resendUserInviteSaga(action: PayloadAction<{ userId: number }>) {
  try {
    const res: AxiosResponse<
      {
        userId: number;
      }[]
    > = yield call(UserManagement.setResendInvitation, action.payload);

    if (!res.data) {
      yield put(actions.resendUserInviteSuccess(res.data));
      NotificationService.success('Invitation Sent!');
    } else {
      const errorMessage = 'Failed to resend User Invitation!';
      yield put(actions.resendUserInviteFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    let errorMessage = 'Failed to resend User Invitation!';

    if (error.response.data && error.response.data.errors) {
      errorMessage = _values(error.response.data.errors).toString();
    }

    yield put(actions.resendUserInviteFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* disableUserSaga(action: PayloadAction<number>) {
  const { payload } = action;

  try {
    yield call(UserService.disableUser, payload);
    yield put(actions.disableUserSuccess(payload));
  } catch (error: any) {
    const errorMessage = 'Failed to disable user!';
    NotificationService.error(errorMessage);
  }
}

export function* unlockUserSaga(action: PayloadAction<number>) {
  const { payload } = action;

  try {
    yield call(UserService.unlockUser, payload);
    yield put(actions.unlockUserSuccess(payload));
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to unlock user!');
    NotificationService.error(errorMessage);
    yield put(actions.unlockUserFail(errorMessage));
  }
}

export function* fetchUserEditInfoSaga(action: PayloadAction<number>) {
  const { payload } = action;

  try {
    const res: AxiosResponse<UserEdit> = yield call(UserManagement.getUserEditInfo, payload);

    if (res.data) {
      yield put(actions.fetchUserEditInfoSuccess(res.data));
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to load user information');
    NotificationService.error(errorMessage);
    yield put(actions.fetchUserManagementFail(errorMessage));
  }
}

export function* submitUserInfoSaga(action: PayloadAction<UserInfo>) {
  const { payload } = action;
  const postData = { ...payload, email_list: (payload.email_list as string).split(',') };
  const roleList: UserEdit = yield select(selectors.getEditData);
  const roleName = roleList.access_role_options.find(role => role.value === payload.role_id)?.label;

  try {
    const res: AxiosResponse<boolean> = yield call(UserManagement.postUserInfo, postData);

    if (res.data) {
      yield put(actions.submitUserInfoSuccess({ ...payload, roleName }));
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to update user information');
    NotificationService.error(errorMessage);
  }
}

export default function* userManagementSaga() {
  yield takeLatest(actions.fetchUserManagement, fetchUserManagementSaga);
  yield takeLatest(actions.resendUserInvite, resendUserInviteSaga);
  yield takeLatest(actions.disableUser, disableUserSaga);
  yield takeLatest(actions.unlockUser, unlockUserSaga);
  yield takeLatest(actions.fetchUserEditInfo, fetchUserEditInfoSaga);
  yield takeLatest(actions.submitUserInfo, submitUserInfoSaga);
}
