import { PayloadAction, createReducer } from '@reduxjs/toolkit';
// models
import { ActivityState, ActivityStateType, TouchActivityState } from '../interfaces';
// redux
import { actions } from '../actions';
import { fetchReducer, fetchFailReducer, fetchSuccessReducer } from '@redux/feature/fetch/reducers';

export const initialState: ActivityStateType = {
  touch: {
    data: null,
    loading: false,
    error: '',
  },
  insight: {
    data: null,
    loading: false,
    error: '',
  },
};

const fetchActivityReducer = (draftState: ActivityStateType) => {
  fetchReducer(draftState.insight);
};

const fetchActivitySuccessReducer = (
  draftState: ActivityStateType,
  action: PayloadAction<ActivityState>
) => {
  fetchSuccessReducer(draftState.insight, action);
};

const fetchActivityFailReducer = (draftState: ActivityStateType, action: PayloadAction<string>) => {
  fetchFailReducer(draftState.insight, action);
};

const fetchTouchActivityReducer = (draftState: ActivityStateType) => {
  fetchReducer(draftState.touch);
};

const fetchTouchActivitySuccessReducer = (
  draftState: ActivityStateType,
  action: PayloadAction<TouchActivityState>
) => {
  fetchSuccessReducer(draftState.touch, action);
};

const fetchTouchActivityFailReducer = (
  draftState: ActivityStateType,
  action: PayloadAction<string>
) => {
  fetchFailReducer(draftState.touch, action);
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.activity.fetchActivity, fetchActivityReducer)
    .addCase(actions.activity.fetchActivitySuccess, fetchActivitySuccessReducer)
    .addCase(actions.activity.fetchActivityFail, fetchActivityFailReducer)
    .addCase(actions.activity.fetchTouchActivity, fetchTouchActivityReducer)
    .addCase(actions.activity.fetchTouchActivitySuccess, fetchTouchActivitySuccessReducer)
    .addCase(actions.activity.fetchTouchActivityFail, fetchTouchActivityFailReducer)
);

export default reducer;
