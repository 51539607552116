const errorCodeKey = 'cognitoErrorCode';
const errorDetailsKey = 'cognitoError';

const getError = () => localStorage.getItem(errorDetailsKey);

const saveError = (errorMessage: string) => localStorage.setItem(errorDetailsKey, errorMessage);

const clearErrors = () => {
  localStorage.removeItem(errorCodeKey);
  localStorage.removeItem(errorDetailsKey);
};

const getErrorCode = (): number => {
  const errorCode = localStorage.getItem(errorCodeKey);

  if (errorCode === null) {
    return -1;
  }

  return parseInt(errorCode);
};

const setErrorCode = (errorCode: number | string) =>
  localStorage.setItem(errorCodeKey, String(errorCode));

export default {
  getError,
  saveError,
  clearErrors,
  getErrorCode,
  setErrorCode,
};
