/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import { RadioChangeEvent } from 'antd/lib/radio';
// models
import {
  ChangeFieldsPayload,
  FieldType,
  RationaleType,
} from '@features/add-research-rationale/state/interfaces';
// utils
import { getRangeValues, getValuesRalation } from '@optx/utils/researchRationale';
// constants
import {
  SOFTWARE_RATIONALE_KEY,
  PSG_FIT_RATIONALE_KEY,
  OPTX_SCORE_UPPER_RATIONALE_KEY,
  OPTX_SCORE_LOWER_RATIONALE_KEY,
} from '../../constants';
// components
import OptionsRationale from '../rationale/OptionsRationale';

interface CollapsableFieldsProps {
  score?: number;
  collapsableValue?: boolean | null;
  typeOptions: RationaleType;
  valueRationale: string | null;
  storeRationale: FieldType;
  onChange: (field?: ChangeFieldsPayload, rationale?: ChangeFieldsPayload) => void;
}

const CollapsableFields: React.FC<CollapsableFieldsProps> = ({
  score,
  onChange,
  collapsableValue,
  valueRationale,
  storeRationale,
  typeOptions,
}) => {
  const [rationaleValue, setRationaleValue] = useState<string | null>(valueRationale ?? '');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const typeOptionsRef = useRef<string>('');

  useEffect(() => {
    const scoreRationale = storeRationale.optx_score_rationales;
    const rangeValues = typeof scoreRationale === 'string' && getRangeValues(scoreRationale);

    if (
      rangeValues &&
      rangeValues.length > 0 &&
      typeof score === 'number' &&
      !isNaN(Number(score)) &&
      getValuesRalation(rangeValues, score) === 'equal'
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [storeRationale, score]);

  useEffect(() => {
    typeOptionsRef.current = typeOptions;
  }, [typeOptions]);

  const rationaleKey =
    typeOptions === 'PSG Fit'
      ? PSG_FIT_RATIONALE_KEY
      : typeOptions === 'Software Company'
      ? SOFTWARE_RATIONALE_KEY
      : typeOptions === 'Score Upper'
      ? OPTX_SCORE_UPPER_RATIONALE_KEY
      : OPTX_SCORE_LOWER_RATIONALE_KEY;

  const handleRationaleChange = (e: RadioChangeEvent) => {
    setRationaleValue(e.target.value);
    onChange(undefined, { key: rationaleKey, value: e.target.value });
  };

  const handleOtherOptionRationaleChange = (option: string) => {
    if (
      (typeOptionsRef.current === 'Score Upper' && typeOptions === 'Score Lower') ||
      (typeOptionsRef.current === 'Score Lower' && typeOptions === 'Score Upper')
    ) {
      setRationaleValue(null);
    } else {
      setRationaleValue(option);
      onChange(undefined, { key: rationaleKey, value: option });
    }
  };

  useEffect(() => {
    setRationaleValue(storeRationale[rationaleKey] as string | null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeRationale]);

  return (
    <>
      {collapsableValue === false && (
        <OptionsRationale
          disabled={
            (rationaleKey === OPTX_SCORE_UPPER_RATIONALE_KEY ||
              rationaleKey === OPTX_SCORE_LOWER_RATIONALE_KEY) &&
            collapsableValue === false
              ? isDisabled
              : false
          }
          rationaleValue={rationaleValue}
          handleOtherOptionRationaleChange={handleOtherOptionRationaleChange}
          handleRationaleChange={handleRationaleChange}
          fieldName={typeOptions}
        />
      )}
    </>
  );
};

export default CollapsableFields;
