import { CaseReducer, PayloadAction, createReducer } from '@reduxjs/toolkit';
// models
import { GridPayload } from '@optx/models/grid';
import { ViewOption, ViewSuccess } from '@optx/models/search';
// constants
import { GRID_VIEW_KEYS, VIEW_INITIAL_STATE } from '@optx/constants/grid';
// utils
import { generateGridReducerInitialState } from '@optx/utils/grid';
// redux
import { applyView } from './actions';
import { ViewState } from './interfaces';
import { actions as savedSearchesActions } from '@redux/company/saved-searches';
import { actions as searchActions } from '@features/grid/search';

const initialState: ViewState = generateGridReducerInitialState(GRID_VIEW_KEYS, VIEW_INITIAL_STATE);

const applyViewReducer: CaseReducer<ViewState, PayloadAction<GridPayload<ViewOption>>> = (
  draftState,
  action
) => {
  const { gridKey, data } = action.payload;

  draftState[gridKey] = { id: data.unique_id, label: data.title };
};

const applyViewSuccessReducer: CaseReducer<ViewState, PayloadAction<ViewSuccess>> = (
  draftState,
  action
) => {
  const { id, label, pageAlias } = action.payload;

  if (pageAlias === 'advancedSearch' || pageAlias === null) {
    draftState.advancedSearch.id = id;
    draftState.advancedSearch.label = label;
  }

  if (pageAlias === 'myCompanies') {
    draftState.myCompanies.id = id;
    draftState.myCompanies.label = label;
  }

  if (pageAlias === 'companyOutReach') {
    draftState.outreach.id = id;
    draftState.outreach.label = label;
  }

  if (pageAlias === 'watchList' || pageAlias === 'userLists') {
    draftState.watchlists.id = id;
    draftState.watchlists.label = label;
  }

  if (pageAlias === 'sourceScrubLists') {
    draftState.sslists.id = id;
    draftState.sslists.label = label;
  }
};

// this is for the case when we rename/delete saved search and access another saved search from the list
// we want to make sure that previous saved search label that is used in useRefreshView
// is removed from redux store to not to trigger useRefreshView incorrectly

const loadSavedSearchReducer: CaseReducer<ViewState> = draftState => {
  draftState.advancedSearch.label = null;
};

const reducer = createReducer<ViewState>(initialState, builder => {
  builder.addCase(applyView, applyViewReducer);
  builder.addCase(savedSearchesActions.applyViewSuccess, applyViewSuccessReducer);
  builder.addCase(searchActions.loadSavedSearch, loadSavedSearchReducer);
});

export default reducer;
