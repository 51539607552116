import React from 'react';
import classnames from 'classnames';

interface PaginationPerPageProps {
  onPageChange: (page: number, perPage: number) => void;
  perPage: number;
}

const perPageValues = [50, 150, 500];

const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  event.preventDefault();
};

const PaginationPerPage: React.FC<PaginationPerPageProps> = ({ onPageChange, perPage }) => {
  const createPageSizeChangeHandler = (pageSize: number, isActive: boolean) => () => {
    if (!isActive) {
      onPageChange(1, pageSize);
    }
  };

  return (
    <div className="per-page-container">
      <ul className="pagination-list">
        {perPageValues.map((item, index) => {
          const active = item === perPage;

          return (
            <li
              role="presentation"
              key={index}
              className={classnames('pagination-item', { active })}
              onClick={createPageSizeChangeHandler(item, active)}
            >
              <a className="pagination-link" href="/#" onClick={handleLinkClick}>
                {item}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PaginationPerPage;
