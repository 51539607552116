import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { FETCH_COGNITO_LOGOUT_URL } from './actionTypes';
import { fetchCognitoLogoutURLFail, fetchCognitoLogoutURLSuccess } from './actions';
// services
import { AuthService } from '../../../services/api';

export function* fetchCognitoLogOutURLSaga() {
  try {
    const res: AxiosResponse<string> = yield call(AuthService.getCognitoLogOutURL);

    if (res.data) {
      yield put(fetchCognitoLogoutURLSuccess(res.data));
    } else {
      yield put(fetchCognitoLogoutURLFail('Cognito LogOut URL failure!'));
    }
  } catch (e: any) {
    yield put(fetchCognitoLogoutURLFail('Cognito LogOut URL failure!'));
  }
}

export default function* cognitoLogOutSaga() {
  yield takeLatest(FETCH_COGNITO_LOGOUT_URL, fetchCognitoLogOutURLSaga);
}
