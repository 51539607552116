// models
import { Filter, BaseFilter } from '@optx/models/filters';

/**
 * Create a new custom filter type called "filter_group_georange" that uses the
 * filter_group type in order to display the geo_country and geo_range in one card.
 */
export const createGeoRangeGroup = (filterGroup: Array<Filter<any>>) => {
  const tempFilterData: Array<Filter<any>> = [];

  filterGroup.forEach((filterField, index) => {
    if (filterField.column !== 'geo_country' && filterField.column !== 'geo_range') {
      tempFilterData.push(filterField);
    } else if (filterField.column === 'geo_country') {
      tempFilterData.push({
        index: filterField.index,
        column: 'geo_range_group',
        type: 'filter_group_georange',
        data: [],
        label: 'Geographic Range',
        tooltip: null,
        placeholders: null,
        endpoint: '',
        depends_on: [],
        groupItems: [],
      });

      const lastIndexData = tempFilterData[tempFilterData.length - 1] as BaseFilter;
      lastIndexData.groupItems?.push(filterField);

      const geoRangeField = filterGroup.find(item => item.column === 'geo_range');

      if (geoRangeField) {
        lastIndexData.groupItems?.push(geoRangeField);
      }
    }
  });

  return tempFilterData;
};
