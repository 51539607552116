import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';

/**
 * Converts the given number of bytes to megabytes (MB).
 * If the input is not provided, returns the default empty value.
 *
 * @param bytes - The number of bytes to be converted to megabytes.
 * @returns A string representing the value in megabytes, with a precision of one decimal place and suffixed with 'MB'.
 * If the value is less than 1MB, returns '<1MB'.
 */
export const bytesToMB = (bytes?: number) => {
  if (!bytes) return DEFAULT_EMPTY_VALUE;

  const valueInMB = bytes / 1024 / 1024;

  if (valueInMB < 1) return '<1MB';

  return `${valueInMB.toFixed(1)}MB`;
};
