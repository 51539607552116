import React from 'react';
import { useSelector } from 'react-redux';
// components
import FixedLoader from '@components/common/loader';
// redux
import * as SourcingOutreachSelectors from '@optx/features/sourcing-outreach-summary/state';

const PageLoader = () => {
  const loading = useSelector(SourcingOutreachSelectors.pageLoading);

  return <FixedLoader loading={loading} />;
};

export default PageLoader;
