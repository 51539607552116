import { SortByRule, SortRule } from '@models/table/sorting';
import { createAction } from '@reduxjs/toolkit';
import {
  USER_LISTS_SORT,
  USER_LISTS_MULTI_SORT,
  RESET_USER_LISTS_SORT,
  RESET_USER_LISTS_SORT_SUCCESS,
} from './types';

export const changeSortAction = createAction<SortRule<any>>(USER_LISTS_SORT);
export const multiSortAction = createAction(
  USER_LISTS_MULTI_SORT,
  (multiSort: boolean, sortBy?: Array<SortByRule<any>>) => ({
    payload: multiSort,
    meta: sortBy,
  })
);

export const resetSortAction = createAction(RESET_USER_LISTS_SORT);
export const resetSortSuccessAction = createAction<Array<SortByRule<any>> | undefined>(
  RESET_USER_LISTS_SORT_SUCCESS
);
