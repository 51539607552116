import { createSelector } from 'reselect';
import { AppState } from '@optx/redux/interfaces';

const selectProfileNotes = (state: AppState) => state.notes.profileNotes;

export const isLoading = createSelector(selectProfileNotes, state => state.loading);

export const getProfileNotes = createSelector(selectProfileNotes, state => state.data);

export const getSorting = createSelector(selectProfileNotes, state => state.sorting);
