import styled from 'styled-components';
import { Col } from 'antd';

const SelectContactWrapper = styled(Col)`
  .ant-select-item-option-content {
    white-space: normal;
    text-overflow: initial;
  }
`;
export default {
  SelectContactWrapper,
};
