import React from 'react';
import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// models
import { TableCellPipelineReport } from '@optx/models/table/Cell';
// redux
import { selectors } from '@optx/components/feature/company-individual-edit/state';

const TableCellTeams: React.FC<TableCellPipelineReport> = ({ value }) => {
  const list = useSelector(selectors.companyIndividualEdit.dealTeamOptions);
  const selectOptions = list.map(list => ({
    label: list.label.toString(),
    value: list.value.toString(),
  }));

  const handleAllValuesSelected = () => {
    if (value.length === 0) {
      return <>{DEFAULT_EMPTY_VALUE}</>;
    }

    if (value.length === selectOptions.length) {
      return <>ALL</>;
    }

    if (Array.isArray(value)) {
      return value.join(', ');
    }

    return value;
  };

  if (value === null) {
    return <>{DEFAULT_EMPTY_VALUE}</>;
  }

  return (
    <Tooltip placement="topLeft" title={value && Array.isArray(value) ? value.join(', ') : value}>
      {handleAllValuesSelected()}
    </Tooltip>
  );
};

export default TableCellTeams;
