import React from 'react';
import { Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import { RadarChartOutlined } from '@ant-design/icons';
// components
import { StatsCompare } from '@optx/shared/view/molecules/statistics';
import SmartTouchScoreChart from './SmartTouchScoreChart';
// redux
import { selectors } from '../../state';
// styles
import StyledStatsSection from '../StatsSection/StatsSection.styles';
import Info from './Info';

// touches
const formatTouchesAverage = (value: number) => `${Math.round(value)} Team Average`;

const SmartTouchScore = () => {
  const selectedPeriodLabel = useSelector(selectors.filters.getSelectedPeriodLabel);
  const scoreData = useSelector(selectors.smartTouchScore.getSmartTouchScore);
  const isFetching = useSelector(selectors.smartTouchScore.isFetching);

  return (
    <StyledStatsSection.SubSection>
      <StyledStatsSection.SubSectionTitle>
        <RadarChartOutlined className="radaricon" />
        Smart Touch Score
        <Info title="Number of points for each touch type" />
      </StyledStatsSection.SubSectionTitle>
      <Skeleton loading={isFetching}>
        {scoreData && (
          <>
            <StatsCompare
              title={`MY TOTAL SCORE ${selectedPeriodLabel.toUpperCase()}`}
              values={[scoreData.selected_average, scoreData.team_average]}
              renderCompareValue={formatTouchesAverage}
            />

            <SmartTouchScoreChart data={scoreData.histogram} />
          </>
        )}
      </Skeleton>
    </StyledStatsSection.SubSection>
  );
};

export default SmartTouchScore;
