export const INITIALIZE_COMPANIES_OUT_REACH_CARDS = 'INITIALIZE_COMPANIES_OUT_REACH_CARDS';

export const FETCH_COMPANIES_OUT_REACH_CARDS =
  ' companies-outreach/FETCH_COMPANIES_OUT_REACH_CARDS';
export const FETCH_COMPANIES_OUT_REACH_CARDS_SUCCESS =
  ' companies-outreach/FETCH_COMPANIES_OUT_REACH_CARDS_SUCCESS';
export const FETCH_COMPANIES_OUT_REACH_CARDS_FAIL =
  ' companies-outreach/FETCH_COMPANIES_OUT_REACH_CARDS_FAIL';

export const FETCH_SOURCING_OUT_REACH_FILTERS_SUCCESS =
  'companies-outreach/FETCH_SOURCING_OUT_REACH_FILTERS_SUCCESS';

export const SET_DEFAULT_SOURCING_OUT_REACH_FILTERS =
  'companies-outreach/SET_DEFAULT_SOURCING_OUT_REACH_FILTERS';

export const UPDATE_SOURCING_OUTREACH_FILTERS =
  'companies-outreach/UPDATE_SOURCING_OUTREACH_FILTERS';

export const RESET_SESSION_FILTERS = 'companies-outreach/RESET_SESSION_FILTERS';

export const RESET_STORE_FILTERS = 'companies-outreach/RESET_STORE_FILTERS';

export const UPDATE_SOURCING_FILTER_OPTIONS = 'companies-outreach/UPDATE_SOURCING_FILTER_OPTIONS';

export const CHANGE_CARD = ' companies-outreach/CHANGE_CARD';
export const CHANGE_CARD_SUBFILTER = ' companies-outreach/CHANGE_CARD_SUBFILTER';
export const CHANGE_CARD_SUBFILTER_SUCCESS = ' companies-outreach/CHANGE_CARD_SUBFILTER_SUCCESS';
