import { createAction } from '@reduxjs/toolkit';
// models
import { HighlightsResponse } from '@optx/models/feature/equity-touch-integration/highlights';
// types
import * as types from '../types/highlights';

// et merge history
export const etMergeHistoryFetch = createAction(types.ET_MERGE_HISTORY_FETCH);
export const etMergeHistorySuccess = createAction<HighlightsResponse>(
  types.ET_MERGE_HISTORY_SUCCESS
);
export const etMergeHistoryFail = createAction<string>(types.ET_MERGE_HISTORY_FAIL);
// et alltime highlights
export const etAlltimeHighlightsFetch = createAction(types.ET_ALLTIME_HIGHLIGHTS_FETCH);
export const etAlltimeHighlightsSuccess = createAction<HighlightsResponse>(
  types.ET_ALLTIME_HIGHLIGHTS_SUCCESS
);
export const etAlltimeHighlightsFail = createAction<string>(types.ET_ALLTIME_HIGHLIGHTS_FAIL);
// time spent
export const timeSpentFetch = createAction(types.TIME_SPENT_FETCH);
export const timeSpentSuccess = createAction<HighlightsResponse>(types.TIME_SPENT_SUCCESS);
export const timeSpentFail = createAction<string>(types.TIME_SPENT_FAIL);
