import { Dictionary } from 'lodash';

import { FilterSource, Filter } from '@optx/models/filters';

export function mapSourcesByFilter(sources: Array<FilterSource<Filter<any>>>) {
  const sourcesByFilter: Dictionary<FilterSource<Filter<any>>> = {};

  sources.forEach(source => {
    source.data.forEach(filter => {
      sourcesByFilter[filter.column] = source;
    });
  });

  return sourcesByFilter;
}
