import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Select } from 'antd';
// utils
import { filterOption } from '@utils/select/select';
// redux
import { selectors as salesloftSelectors } from '@redux/contacts/salesloft';
// components
import { Context } from '../common/Context';

const SelectWrapper = styled(Select)`
  width: 50%;
`;

const { Option } = Select;

interface SelectCadenceProps {
  readOnly?: boolean;
}

const SelectCadence: React.FC<SelectCadenceProps> = ({ readOnly }) => {
  const { setCadence } = useContext(Context);
  const options = useSelector(salesloftSelectors.getCadences);

  const [value, setValue] = useState<number | undefined>(undefined);

  const handleChange = (value: any) => {
    setValue(value);
    setCadence(options.filter(cadence => cadence.id === value)[0]);
  };

  return (
    <SelectWrapper
      showSearch
      placeholder="Select a cadence"
      onChange={handleChange}
      optionFilterProp="children"
      value={value}
      disabled={readOnly}
      filterOption={filterOption}
      dropdownMatchSelectWidth={250}
    >
      <Option key="blank" value="">
        Select a cadence
      </Option>
      {options.map(option => (
        <Option key={option.id} value={option.id}>
          {option.name}
        </Option>
      ))}
    </SelectWrapper>
  );
};

export default SelectCadence;
