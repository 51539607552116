import { createSelector } from 'reselect';

// redux
import { selectors as companyFiltersSelectors } from '@redux/company/filters';
import { selectors as companiesOutReachFilterSelectors } from '@redux/company-outreach/filters';
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as savedSearchesSelector } from '@redux/company/saved-searches';
import { selectors as userSelectors } from '@redux/user/information';

export const isLoading = createSelector(
  companyFiltersSelectors.isLoading,
  searchSelectors.isLoading('outreach'),
  savedSearchesSelector.isLoading,
  (loadingFilters, loadingCompaniesOutReachSearch, loadingSavedSearch) =>
    loadingFilters || loadingCompaniesOutReachSearch || loadingSavedSearch
);

export const shouldFetchCompaniesOutReach = createSelector(
  userSelectors.loaded,
  companyFiltersSelectors.filtersLoaded,
  companiesOutReachFilterSelectors.sourcingOutReachFiltersLoaded,
  savedSearchesSelector.loaded,
  (loadedUser, loadedFilters, loadedCompaniesOutReachFilters, savedSearchLoaded) =>
    loadedUser && loadedFilters && loadedCompaniesOutReachFilters && savedSearchLoaded
);
