import React from 'react';
import { Tooltip, Typography } from 'antd';
// models
import { TableCellSearches } from '@optx/models/table/Cell';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
// hooks
import useSavedContactsSearches from '../../../../../../common/hooks/saved-searches/useSavedContactsSearches';

const TableCellSearchKey: React.FC<TableCellSearches> = ({ value, row }) => {
  const { handleSearchResults } = useSavedContactsSearches();

  const handleViewList = () => {
    if (row.unique_id) {
      handleSearchResults(row.unique_id, row.title);
    }
  };

  return (
    <div role="presentation" onClick={handleViewList}>
      <Typography.Text style={{ maxWidth: '100%' }} ellipsis>
        {value ? <Tooltip title={value}>{value}</Tooltip> : DEFAULT_CELL_VALUE}
      </Typography.Text>
    </div>
  );
};

export default TableCellSearchKey;
