import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray, Formik, FormikProps, FormikValues } from 'formik';
import { Form, Input, Radio, Select } from 'formik-antd';
import {
  Menu,
  MenuProps,
  Modal,
  Button,
  Typography,
  Space,
  Input as InputRef,
  Tooltip,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
//constants
import { INITIAL_NUMBER_OF_SEARCHES, OPERATOR_VALUES } from '@optx/constants/combineSearch';
// utils
import {
  createSelectOptions,
  getOrdinalNumber,
  createCombineSearchData,
} from '@optx/utils/combineSearch';
import { filterOption } from '@optx/utils/select/select';
// redux
import { selectors as searchesSelectors } from '@redux/company/saved-searches';
import * as actions from '@optx/features/combine-search/state/actions';
import { selectors as savedSearchesSelectors } from '@redux/company/saved-searches';
// validation
import { validationSchema } from '@optx/common/validation/combine-search';

const { Option } = Select;

interface CombineSearchItemProps {
  listId: number | string;
  listType?: string;
  hasFilters?: boolean;
  children?: React.ReactNode;
}

const CombineSearchMenuItem: React.FC<CombineSearchItemProps> = ({
  listId,
  listType,
  hasFilters,
  children,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const inputRef = useRef<InputRef>(null);
  const formRef = useRef<FormikProps<FormikValues> | null>(null);
  const form = formRef.current;
  const initialSavedSearches = useSelector(searchesSelectors.getSavedSearches);
  const options = createSelectOptions(initialSavedSearches);
  const savedSearchesNames: string[] = Object.keys(useSelector(savedSearchesSelectors.getTitles));
  const isDisabled = listType === 'combined' ? false : !hasFilters;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [numberOfSearchFields, setNumberOfSearchFields] = useState<number>(2);

  const initialValues = {
    searchName: '',
    first_search_id: listId,
    searchFieldsList: [{ operator: 'Or', search_id: '' }],
  };

  useEffect(() => {
    if (showModal && inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  }, [showModal]);

  const handleClick: MenuProps['onClick'] = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setNumberOfSearchFields(INITIAL_NUMBER_OF_SEARCHES);
    form?.resetForm();
    form?.setErrors({});
  };

  const handleSubmit = (values: any) => {
    const data = createCombineSearchData(values);
    dispatch(actions.combineSearch(data));
    handleClose();
  };

  return (
    <>
      <Menu.Item {...restProps} disabled={isDisabled} onClick={handleClick}>
        {hasFilters ? (
          children
        ) : (
          <Tooltip title="Search doesn't have filters" placement="left">
            {children}
          </Tooltip>
        )}
      </Menu.Item>
      <Modal
        visible={showModal}
        title={'Which saved searches would you like to combine?'}
        centered
        onCancel={handleClose}
        footer={
          <>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="primary" onClick={() => form?.submitForm()}>
              Save
            </Button>
          </>
        }
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema(savedSearchesNames)}
          innerRef={formRef}
        >
          <Form layout="vertical">
            <Form.Item label="Give this new, combined search a name" name="searchName">
              <Input name="searchName" ref={inputRef} />
            </Form.Item>
            <Form.Item label={'Select the 1st save search to combine'} name="first_search_id">
              <Select
                name="first_search_id"
                placeholder="Search"
                showSearch
                filterOption={filterOption}
              >
                {options.map((option, index) => (
                  <Option key={index} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <FieldArray
              name="searchFieldsList"
              render={arrayHelpers => {
                return (
                  <>
                    {(arrayHelpers.form.values.searchFieldsList as any[]).map((fields, index) => (
                      <React.Fragment key={index}>
                        <Form.Item
                          label="Select the desired operator"
                          name={`searchFieldsList.${index}.operator`}
                        >
                          <Radio.Group
                            name={`searchFieldsList.${index}.operator`}
                            options={OPERATOR_VALUES}
                            optionType="button"
                            buttonStyle="solid"
                          />
                        </Form.Item>
                        <Form.Item
                          label={`Select the ${getOrdinalNumber(index + 2)} save search to combine`}
                          name={`searchFieldsList.${index}.search_id`}
                        >
                          <Select
                            name={`searchFieldsList.${index}.search_id`}
                            placeholder="Search"
                            showSearch
                            filterOption={filterOption}
                            onChange={() =>
                              form?.setFieldTouched(
                                `searchFieldsList.${index}.search_id`,
                                true,
                                false
                              )
                            }
                          >
                            {options.map((option, index) => (
                              <Option key={index} value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </React.Fragment>
                    ))}
                    <Button
                      style={{ padding: '4px 6px 4px 6px' }}
                      icon={<PlusOutlined style={{ color: '#1890FF' }} />}
                      onClick={() => {
                        arrayHelpers.push({ operator: 'Or', search_id: '' });
                        setNumberOfSearchFields(prev => prev + 1);
                      }}
                      disabled={options.length === numberOfSearchFields}
                    >
                      Add Another Search
                    </Button>
                  </>
                );
              }}
            />
            <Space direction="vertical" style={{ display: 'block', marginTop: '24px' }}>
              <Typography.Text strong>Please Note:</Typography.Text>
              <Typography.Text>Each search will still be independently operational</Typography.Text>
            </Space>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default CombineSearchMenuItem;
