import styled from 'styled-components';
import { Form } from 'formik-antd';

const FormWrapper = styled(Form)`
  .view-checkboxes__wrapper {
    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item-explain {
      display: none;
    }
  }

  .view-checkboxes__default {
    text-align: right;

    &.is-editing {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
    }
  }

  .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
  .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
  .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
  .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
    top: 1rem;
  }
  .ant-input.error {
    background-color: #fff;
    border-color: #ff4d4f;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  }
`;

const Styles = {
  FormWrapper,
};

export default Styles;
