import { fork } from 'redux-saga/effects';
// redux
import { saga as histogramFiltersSaga } from '@features/histograms/histograms-list-details';
import { saga as searchSaga } from './search';
import { saga as columnsSaga } from './columns';
import { saga as viewTransitionSaga } from '@optx/features/grid/view-transition';

export default function* searchSagas() {
  yield fork(searchSaga);
  yield fork(viewTransitionSaga);
  yield fork(histogramFiltersSaga);
  yield fork(columnsSaga);
}
