import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, actions } from '../../../redux/user/user-list';
import useInitialFetch from '../fetch/useInitialFetch';

const useFetchUserList = (isShare: boolean = false) => {
  const shouldFetch = useSelector(
    isShare ? selectors.shouldShareFetch : selectors.shouldFieldFetch
  );

  const dispatch = useDispatch();
  const fetchUserList = useCallback(
    () => dispatch(actions.fetchUserList(isShare)),
    [dispatch, isShare]
  );
  useInitialFetch(shouldFetch, fetchUserList);
};

export default useFetchUserList;
