import React from 'react';
import { Popover } from 'antd';
import { PopoverProps } from 'antd/lib/popover';

/**
 * EmptyPopover component.
 * Allow adding popover logic in places where the trigger of the popover can't be a direct child,
 * usually when there is dropdown menu that trigger visibility of the popover.
 *
 * IMPORTANT!: Component has to be used in a wrapper with empty-popover-wrapper class or a relative position.
 *
 * From ANTD documentation Popover has to have a direct child and can't be used without it,
 * otherwise will cause issues like body overflow and improper position.
 *
 * @param props
 */
const EmptyPopover: React.FC<PopoverProps> = props => {
  return (
    <Popover trigger="click" {...props}>
      {/* Just an dummy trigger that doesn't actually trigger anything on click. */}
      <div className="empty-popover-trigger" />
    </Popover>
  );
};

export default EmptyPopover;
