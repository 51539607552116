import styled from 'styled-components';

const StatsWrapper = styled.div`
  min-width: 1px;
  width: 100%;  
  flex-direction: column;
  display: none;

  @media (min-width: 1420px) {
      display: flex;
      flex: 0;
      min-width: 400px;
      max-width: 400px;
    }
  }


  .pipeline-outreach {
    .recharts-responsive-container {
      box-sizing: content-box;
      padding: 0px 20px 14px;
      margin-left: -20px;
      margin-bottom: 10px;

      & + div {
        box-sizing: content-box;
        width: 100%;
        padding: 14px 20px 14px;
        margin-left: -20px;
        margin-bottom: 25px;
        border-bottom: 1px solid #e3e5e8;
        border-top: 1px solid #e3e5e8;

        &:last-child {
          border: 0;
        }
      }
    }

    & .pie-chart-wrapper {
      display: flex;
      justify-content: space-between;

      .pie-chart-legends {
        button {
          padding-inline: 0;
          padding-block: 0;
          width: 167px;
          border: 1px solid #e3e5e8;
          border-radius: 4px;
          display: flex;
          align-items: center;
          margin-bottom: 9px;
          .label {
            font-size: 12px;
            color: #595959;
            font-weight: 600;
            padding-left: 10px;
          }
          .count {
            width: 52px;
            height: 29px;
            display: block;
            text-align: center;
            line-height: 29px;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            font-size: 14px;
            font-weight: 700;
            color: white;
          }
        }
      }
      
      .pie-chart {
        position: relative;
        width: 130px;
        height: 130px;
        margin-right: auto;

        .total {
          position: absolute;
          width: 130px;
          font-size: 12px;
          color: #334d6e;
          font-weight: 400;
          top: 35%;
          line-height: 20px;
          text-align: center;

          b {
            cursor: pointer;
            font-size: 16px;
            font-weight: 400;
            color: #1890ff;
            display: block;

            &:hover {
              color: #096dd9;
            }
          }
        }
      }
    }
  }

  .recharts-cartesian-axis-tick-value {
    cursor: pointer;

    &[fill='#1890FF']:hover {
      fill: #096dd9;
    }
  }
`;

const Styled = {
  StatsWrapper,
};

export default Styled;
