import { createSelector } from 'reselect';
// models
import Company from '@optx/models/Company';
// utils
import { filterSortableVisibleColumns } from '@optx/utils/table/column-visibility';
import { sortColumns } from '@optx/utils/table/sorting';
// redux
import { selectors as loaderSelectors } from '@optx/features/request';
import { featureName } from '../../featureName';
import { ChecklistResearchInsightState } from '../interfaces';
import * as localFilters from './filters';
import AnalystColumn from '../../components/Table/columns/analystColumn';
import CompanyColumn from '../../components/Table/columns/companyColumn';
import * as localSort from './sort';
import * as localSearch from './search';
import * as localCards from './cards';
import { getDefaultScore } from '@optx/redux/user/information/selectors';
import { getSearchCount } from '@optx/features/grid/search-count/state/selectors';
import { types } from '../types';
import { getSearchKey } from '@optx/features/grid/searchkey/state/selectors';

type StateSlice = Record<typeof featureName, ChecklistResearchInsightState>;

export const selectFeatureState = (state: StateSlice) => state[featureName];

const selectFiltersState = createSelector(selectFeatureState, state => state.filters);
const selectSortState = createSelector(selectFeatureState, state => state.sort);
const selectUIState = createSelector(selectFeatureState, state => state.ui);
const selectSearchState = createSelector(selectFeatureState, state => state.search);
const selectCardState = createSelector(selectFeatureState, state => state.cards);

// filters
const isLoadingFilters = loaderSelectors.createLoadingSelector([
  types.filters.fetchFilters.SELECTOR,
]);

const filters = {
  isLoading: isLoadingFilters,
  selectFilter: createSelector(selectFiltersState, localFilters.getFilter),
  selectDefaultFilter: createSelector(selectFiltersState, localFilters.getDefaultFilter),
  selectClearedFilter: createSelector(selectFiltersState, localFilters.getClearedFilter),
  selectNormalizedFilters: createSelector(selectFiltersState, localFilters.getNormalizedFilters),
  filterQuery: createSelector(selectFiltersState, localFilters.filterQuery),
  getFilters: createSelector(selectFiltersState, localFilters.getFilters),
  sources: createSelector(selectFiltersState, localFilters.sources),
  isCLearedFilter: createSelector(
    selectFiltersState,
    getSearchKey('checklistResearchInsights'),
    (state, searchKey) => {
      return localFilters.isClearedFilter(state, searchKey);
    }
  ),
  getTags: createSelector(
    selectFiltersState,
    getSearchKey('checklistResearchInsights'),
    (state, searchKey) => {
      return localFilters.getTags(state, searchKey);
    }
  ),
  loaded: createSelector(selectFiltersState, localFilters.loaded),
  searchKey: createSelector(
    selectFiltersState,
    getSearchKey('checklistResearchInsights'),
    (state, searchKey) => {
      return localFilters.getSearchKey(state, searchKey);
    }
  ),
};

// sort
const sort = {
  sortBy: createSelector(selectSortState, localSort.selectSortBy),
  multiSort: createSelector(selectSortState, localSort.selectMultiSort),
};

// ui
const ui = {
  defaultTableView: createSelector(selectUIState, state => state.defaultTableView),
  isLoading: loaderSelectors.createLoadingSelector([types.filters.fetchFilters.SELECTOR]),
};

// search
const search = {
  getData: createSelector(selectSearchState, localSearch.getData),
  getLoading: createSelector(selectSearchState, localSearch.getLoading),
  getError: createSelector(selectSearchState, localSearch.getError),
  getCount: getSearchCount('checklistResearchInsights'),
  getColumnLists: createSelector(selectSearchState, localSearch.getColumnLists),
  getColumnOrder: createSelector(selectSearchState, localSearch.getColumnOrder),
  getColumnDisplay: createSelector(selectSearchState, localSearch.getColumnDisplay),
};

// cards
const cards = {
  getScore: createSelector(selectCardState, localCards.getScore),
  getSoftware: createSelector(selectCardState, localCards.getSoftware),
  getPsgFit: createSelector(selectCardState, localCards.getPsgFit),
};

export const pageLoading = createSelector(
  ui.isLoading,
  filters.isLoading,
  search.getLoading,
  (isLoading, filters, search) => isLoading || filters || search
);

/**
 * Get displayed columns.
 * Used for defining the columns table
 */
export const getDisplayedColumns = createSelector(
  search.getColumnDisplay,
  search.getColumnOrder,
  ui.defaultTableView,
  (columnDisplay, order, defaultView) => {
    if (defaultView === 'company')
      return filterSortableVisibleColumns(CompanyColumn, columnDisplay, order);

    return filterSortableVisibleColumns(AnalystColumn, columnDisplay, order, false);
  }
);

export const getCompanyById = (companyId: number) =>
  createSelector(search.getData, companies => {
    const findCompany = (companies as Company[]).find(
      (company: Company) => company.company_id === companyId
    );

    return findCompany;
  });

export const getColumns = createSelector(
  getDisplayedColumns,
  sort.sortBy,
  getDefaultScore,
  sort.multiSort,
  (columns, sorting, defaultOptxScore, isMultiSort) =>
    // @ts-ignore
    sortColumns(columns, sorting?.sortBy || sorting, defaultOptxScore, {
      isMultiSort,
    })
);

export const selectors = {
  filters,
  sort,
  ui,
  search,
  cards,
};
