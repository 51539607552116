import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
// utils
import { getDateColor } from '@utils/text';
import { normalizeNegativeSignValues } from '@optx/utils/utils';
// redux
import { selectors as profileSelectors } from '@redux/company/profile';

interface KeyPerformanceIndicatorProps {
  value: number | string;
  label: string;
  formatValue?: (value: number | string) => string;
  className?: string;
  valueProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;
  isInvalidValue?: boolean;
}

const KeyPerformanceIndicator: React.FC<KeyPerformanceIndicatorProps> = ({
  value,
  label,
  formatValue,
  className,
  valueProps,
  isInvalidValue = false,
}) => {
  let formattedValue = value;
  const company = useSelector(profileSelectors.getProfile);
  const lastTouchDate = company?.last_touch_date;

  if (formatValue) {
    formattedValue = formatValue(value);
  }

  const kpiClassName = classnames('kpi', className, {
    'kpi--positive': typeof value === 'number' && value > 0,
    'kpi--negative': typeof value === 'number' && value < 0,
  });

  const valueClassName = valueProps && valueProps.className;

  return (
    <div className={kpiClassName}>
      <span
        {...valueProps}
        style={
          // eslint-disable-next-line no-nested-ternary
          label === 'Last Touch Date' || label === 'Last Touch'
            ? isInvalidValue
              ? { color: `${getDateColor(lastTouchDate)}` }
              : { color: `${getDateColor(value)}` }
            : undefined
        }
        className={classnames('kpi__value', valueClassName)}
      >
        {normalizeNegativeSignValues(formattedValue.toString())}
      </span>
      <span className="kpi__label">{label}</span>
    </div>
  );
};

export default React.memo(KeyPerformanceIndicator);
