import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// models
import { GridPayload } from '@models/grid';
import { PageInfo } from '@optx/models/table/Pagination';
import { PaginationLongCardState } from './interfaces';
//constants
import { GRID_KEYS, LONGCARD_PAGINATION_INITIAL_STATE } from '@constants/grid';
// utils
import { generateGridReducerInitialState } from '@utils/grid';
// redux
import * as actions from './actions';

const initialState: PaginationLongCardState = generateGridReducerInitialState(
  GRID_KEYS,
  LONGCARD_PAGINATION_INITIAL_STATE
);

const changeLongCardPaginationSuccessReducer: CaseReducer<
  PaginationLongCardState,
  PayloadAction<GridPayload<PageInfo>>
> = (draftState, action) => {
  const { gridKey, data } = action.payload;

  draftState[gridKey] = data;
};

const reducer = createReducer<PaginationLongCardState>(initialState, builder =>
  builder.addCase(actions.changeLongCardPaginationSuccess, changeLongCardPaginationSuccessReducer)
);

export default reducer;
