import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { AxiosResponse } from 'axios';
// services
import NotificationService from '@optx/services/NotificationService';
import CompanyService from '@optx/services/api/CompanyService';
// utils
import { mapSortQuery } from '@optx/utils/search';
// redux
import { actions as userActions } from '@redux/user/information';
import { CompanyTabListPaginationState, CompanyTabListResponse } from './interfaces';
import {
  getCompanyTabLists,
  getCompanyTabListsSuccess,
  getCompanyTabListsFail,
  sortCompanyTabLists,
} from './actions';

export function* getCompanyTabListsSaga(action: PayloadAction<CompanyTabListPaginationState>) {
  try {
    const res: AxiosResponse<CompanyTabListResponse> = yield call(
      CompanyService.getCompanyTabLists,
      action.payload
    );

    if (res.data) {
      yield put(getCompanyTabListsSuccess(res.data, action.payload));
    } else {
      const errorMessage = 'Company list fail!';
      yield put(getCompanyTabListsFail('Error for company lists rounds!'));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = 'Company list fail!';
    yield put(getCompanyTabListsFail('Error for company lists rounds!'));
    NotificationService.error(errorMessage);
  }
}

export function* sortCompanyListsSaga(action: PayloadAction<CompanyTabListPaginationState>) {
  const sortBy = action.payload.sortBy;

  const sortQuery = queryString.stringify(mapSortQuery(sortBy), {
    arrayFormat: 'comma',
  });

  try {
    const res: AxiosResponse<CompanyTabListResponse> = yield call(
      CompanyService.getCompanyTabLists,
      action.payload
    );
    yield put(userActions.updateUserSettings({ profile_lists_tab_sorting: sortQuery }));

    if (res.data) {
      yield put(getCompanyTabListsSuccess(res.data, action.payload));
    } else {
      const errorMessage = 'Company list fail!';
      yield put(getCompanyTabListsFail('Error for company lists rounds!'));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = 'Company list fail!';
    yield put(getCompanyTabListsFail('Error for company lists rounds!'));
    NotificationService.error(errorMessage);
  }
}

export default function* companySaga() {
  yield takeLatest(getCompanyTabLists, getCompanyTabListsSaga);
  yield takeLatest(sortCompanyTabLists, sortCompanyListsSaga);
}
