import React, { useMemo } from 'react';
import classnames from 'classnames';
// redux
import { useDispatch, useSelector } from 'react-redux';
import {
  actions as filterCardsActions,
  selectors as filterCardsSelectors,
} from '@optx/redux/my-companies/filters-cards';

interface CardSubFiltersProps {
  cardId: string;
  value: number | string;
  label: string;
  filter: string;
  filterId: number;
  formatValue?: (value: number | string) => string;
  className?: string;
  valueProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;
}

const CardSubFilters: React.FC<CardSubFiltersProps> = ({
  cardId,
  value,
  label,
  filter,
  formatValue,
  className,
  valueProps,
}) => {
  let formattedValue = value;

  const selectedCardId = useSelector(filterCardsSelectors.getSelectedCardId);
  const subFilter = useSelector(filterCardsSelectors.getSubFilter);
  const dispatch = useDispatch();

  if (formatValue) {
    formattedValue = formatValue(value);
  }

  const valueClassName = valueProps && valueProps.className;

  const toggleFilter = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();

    dispatch(filterCardsActions.changeCardSubfilter({ cardId, subFilter: filter }));
  };

  const selected = useMemo(() => {
    if (subFilter === filter) {
      // if the filters label is the same on different cards check for cardID
      if (cardId === selectedCardId) {
        return true;
      }

      return false;
    }

    return false;
  }, [filter, subFilter, selectedCardId, cardId]);

  const kpiClassName = classnames('kpi', className, {
    'kpi--positive': typeof value === 'number' && value > 0,
    'kpi--negative': typeof value === 'number' && value < 0,
    active: selected,
  });

  return (
    <div onClick={toggleFilter} className={kpiClassName} role="presentation">
      <span {...valueProps} className={classnames('kpi__value', valueClassName)}>
        {formattedValue}
      </span>
      <span className="kpi__label">{label}</span>
    </div>
  );
};

export default CardSubFilters;
