import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// utils
import { getMultiSelectOptions } from '@optx/utils/filters/mapFiltersOptions';
// models
import { MultiSelectFilterColumns } from '@optx/models/feature/analystsLeaderboard/filters';
import { SelectOption } from '@models/Option';
// hooks
import useLocalMultiSelect from '@optx/common/hooks/select/useLocalMultiSelect';
// components
import { MultiSelectInline, MultiSelectProps } from '@shared/view/molecules/Select';
// redux
import {
  actions as SourcingOutreachActions,
  selectors as SourcingOutreachSelectors,
} from '../state';
import { selectors as userInformationSelectors } from '@redux/user/information';

interface MultiSelectFilterProps extends MultiSelectProps {
  filterKey: MultiSelectFilterColumns;
}

export const MultiSelectFilter: React.FC<MultiSelectFilterProps> = ({
  filterKey,
  ...restProps
}) => {
  const [options, setOptions] = useState<Array<SelectOption>>();
  const dispatch = useDispatch();
  const filterData = useSelector(SourcingOutreachSelectors.filters.getFilters);
  const normalizedData = filterData.normalized[filterKey];
  const isDefaultAnalyst = useSelector(userInformationSelectors.getSourcingOutReachDefaultAnalyst);

  const isMultiSelectDisabledForAnalyst =
    isDefaultAnalyst && filterKey === 'analyst_id' ? true : false;

  const valuesData = useSelector(SourcingOutreachSelectors.filters.getValues);
  const values = valuesData[filterKey];

  useEffect(() => {
    if (normalizedData) {
      const sortedOptions = getMultiSelectOptions(normalizedData as any) as any;

      setOptions(sortedOptions);
    }
  }, [normalizedData]);

  const updateValue = useCallback(
    (newValue: Array<SelectOption>) => {
      dispatch(SourcingOutreachActions.filters.updateFilters({ value: newValue, key: filterKey }));
    },
    [dispatch, filterKey]
  );

  const { localValue, onBlur, onChange } = useLocalMultiSelect(values as any, updateValue);

  return (
    <MultiSelectInline
      options={options}
      onBlur={onBlur}
      onChange={onChange}
      value={localValue}
      isDisabled={isMultiSelectDisabledForAnalyst}
      placeholder={normalizedData ? normalizedData.placeholders : undefined}
      allPrefix={normalizedData ? normalizedData.placeholders : undefined}
      {...restProps}
    />
  );
};

export default MultiSelectFilter;
