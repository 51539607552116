import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchFilters, fetchFiltersSuccess, fetchFiltersFail } from './actions';
import { FilterService } from '../../../services/api';
import { FilterGroup } from '../../../models/filters';

export function* fetchContactsFiltersSaga() {
  try {
    // @ts-ignore
    const res = yield call(FilterService.getOptions, FilterGroup.Contacts);

    if (res.data) {
      yield put(fetchFiltersSuccess(res.data.filters));
    } else {
      yield put(fetchFiltersFail('Error fetch filters for contacts!'));
    }
  } catch (e: any) {
    yield put(fetchFiltersFail('Error fetch filters for contacts!'));
  }
}

export default function* contactsFiltersSaga() {
  yield takeLatest(fetchFilters.type, fetchContactsFiltersSaga);
}
