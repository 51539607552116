import React, { useCallback } from 'react';
import { Modal } from 'antd';
import { Dictionary } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
// models
import { PreselectedFilter } from '@optx/models/filters';
// utils
import { normalizeFilter } from '@optx/utils/filters/filterHelpers';
// redux
import {
  actions as CompaniesETActions,
  selectors as CompaniesETSelectors,
} from '@optx/features/equity-touch-integration/state';
// components
import FiltersModalContent from './FiltersModalContent';

const FiltersModal = () => {
  const filter = useSelector(CompaniesETSelectors.filters.selectFilter);
  const filterSources = useSelector(CompaniesETSelectors.filters.sources);
  const modalIsOpen = useSelector(CompaniesETSelectors.ui.isOpenFilterModal);

  const dispatch = useDispatch();
  const onSearch = useCallback(
    (filter: Dictionary<PreselectedFilter>) => {
      dispatch(CompaniesETActions.filters.applyFilters(filter));
    },
    [dispatch]
  );

  const handleClear = useCallback(() => {
    dispatch(CompaniesETActions.filters.clearFilters());
  }, [dispatch]);
  const handleReset = useCallback(() => {
    dispatch(CompaniesETActions.filters.resetToDefault());
  }, [dispatch]);

  const toggleModalFilters = () => dispatch(CompaniesETActions.ui.toggleFiltersModal());

  const handleSearch = (filter: Dictionary<PreselectedFilter>) => {
    const normalizedFilter = normalizeFilter(filter);
    onSearch(normalizedFilter);
    toggleModalFilters();
  };

  return (
    <Modal
      className="ant-modal-full ant-modal--filters"
      title={null}
      visible={modalIsOpen}
      footer={null}
      onCancel={toggleModalFilters}
      closable={false}
      destroyOnClose
    >
      <FiltersModalContent
        filter={filter}
        filterSources={filterSources}
        onSearch={handleSearch}
        onClose={toggleModalFilters}
        onClear={handleClear}
        onReset={handleReset}
      />
    </Modal>
  );
};

export default FiltersModal;
