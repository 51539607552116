import React from 'react';
import { useSelector } from 'react-redux';
// redux
import { selectors as viewTypeSelectors } from '@features/grid/view-type';
// components
import CardContent from './CardContent';
import GridContent from './GridContent';

/**
 * Switch between card and grid content.
 * Grid content needs to be separated from card content
 * due to how it checks the height of it's wrapper on size change and
 * we don't need this functionality on cards because it might create performance issues.
 */
const CompanySearchDisplay = () => {
  const searchView = useSelector(viewTypeSelectors.searchView('lists'));

  return <>{searchView === 'long-card' ? <CardContent /> : <GridContent />}</>;
};

export default CompanySearchDisplay;
