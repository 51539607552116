import styled, { css } from 'styled-components';

const Container = styled.div`
  .search-header-content {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;

    .ant-input-affix-wrapper,
    .ant-dropdown-trigger,
    .antd-search-input {
      margin-left: 12px;
    }
    .rc-overflow {
      right: 0;
    }
  }
  .ant-btn-icon-only {
    margin-left: 10px;
    vertical-align: top;
  }
`;

const CompanyCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  background: #064885;
  background: linear-gradient(360deg, #064885 0%, #137edf 124.9%);
  .ant-table-column-sorters,
  .ant-table-column-sorter,
  .ant-table-column-sorter-full,
  .ant-table-column-sorter-inner {
    margin-right: -8px;
  }

  .ant-table-container {
    width: 101%;
    position: absolute;
    background-color: white;
  }

  .ant-breadcrumb {
    line-height: 1.5715;
    font-size: 14px;
  }
  .ant-breadcrumb-separator,
  .ant-breadcrumb a {
    color: #bae7ff !important;
  }
  .ant-breadcrumb-link {
    color: #ffffff;
  }
  .multiselect__control {
    border: 1px solid #1890ff;
    background: #074681;
    border-radius: 3px;
    flex-wrap: nowrap;
    &:hover {
      cursor: pointer;
      border: 1px solid #1890ff;
    }
  }
  .multiselect__value-container {
    color: #ffffff;
    .ant-typography-ellipsis,
    .multiselect__placeholder,
    .multiselect__indicator {
      color: #ffffff;
    }
    .multiselect__placeholder {
      font-size: 14px;
    }
  }
  .multiselect__indicators {
    background: #074681;

    .anticon {
      color: #ffffff;
    }
  }
  div.styled-single-select__control {
    padding-left: 0;
    background: #074681;
    border: 1px solid #1890ff;
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      border: 1px solid #1890ff;
    }
    .ant-typography-ellipsis,
    .anticon {
      color: #ffffff;
    }
    .styled-single-select__placeholder {
      color: #ffffff;
      font-size: 14px;
    }
  }
  .ant-table .ant-table-column-sorter {
    margin-right: 0;
  }
`;

interface CompanyCardContainerProps {
  isLongCard?: boolean;
}

const CompanyCardContainer = styled.div<CompanyCardContainerProps>`
  ${props =>
    css`
      padding: ${props.isLongCard ? '23px 40px 0px 40px' : '23px 40px 40px 40px'};
    `}
  width: 100%;
  .anticon-setting:hover,
  .ant-btn-icon-only:hover {
    color: #1890ff !important;
  }
`;

const ContainerAnalystName = styled.div`
  display: flex;
  align-items: center;
  .span,
  svg {
    height: 2em;
    position: relative;
    color: #bae7ff;
  }
`;

const BackButtonText = styled.span`
  color: #bae7ff;
  margin-left: 10px;
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
`;

const AnalystName = styled.span`
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  width: 60rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 7px;
`;

const FilterContainer = styled.div`
  align-items: center;
  margin-top: 12px;
  display: flex;
  gap: 11px;
  justify-content: space-between;
  position: relative;
  z-index: 5;

  @media all and (max-width: 1550px) {
    display: block;
  }
`;

const FilterSelectContainer = styled.div`
  display: flex;
  gap: 11px;
  justify-content: flex-start;
  @media all and (max-width: 1550px) {
    margin-bottom: 11px;
  }
  .bLcoCK {
    min-width: 250px;
  }
`;

const FilterDateContainer = styled.div`
  display: flex;
  gap: 11px;
  justify-content: flex-end;
  @media all and (max-width: 1550px) {
    justify-content: flex-start;
  }
`;

export {
  CompanyCardInfo,
  ContainerAnalystName,
  BackButtonText,
  AnalystName,
  CompanyCardContainer,
  FilterContainer,
  FilterSelectContainer,
  FilterDateContainer,
  Container,
};
