import { createAction } from '@reduxjs/toolkit';
import {
  SEARCH_COUNT,
  SEARCH_COUNT_SUCCESS,
  SEARCH_COUNT_FAILURE,
  DECREMENT_ADDON_COUNT,
} from './types';
import { SearchCountPayload } from './interfaces';
import { SearchPayloadRequest } from '@optx/models/api/contacts';
import { GridKeys, GridPayload } from '@optx/models/grid';

export const searchCount = createAction<
  GridPayload<SearchPayloadRequest | string> & {
    companyId?: number;
  }
>(SEARCH_COUNT);

export const searchCountSuccess = createAction<SearchCountPayload>(SEARCH_COUNT_SUCCESS);

export const searchCountFailure = createAction<GridKeys>(SEARCH_COUNT_FAILURE);

export const decrementAddonCount = createAction(DECREMENT_ADDON_COUNT);
