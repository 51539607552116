import { select } from 'redux-saga/effects';
import { Dictionary, cloneDeep } from 'lodash';
// models
import {
  BaseFilter,
  FilterGroupDataSection,
  FilterSource,
  PreselectedFilter,
  MultiselectMappedFilter,
} from '@models/filters';
import { FormCheckboxOption } from '@models/Option';
// constants
import { FILTER_TOUCHES } from '@constants/filters';
// utils
import mapFiltersToURLParams from '@utils/filters/mapFiltersToURLParams';
//redux
import { selectors as filtersSelectors } from '@redux/company/filters';

export function* getFiltersQuery(filters: Dictionary<PreselectedFilter>) {
  const normalizedFilters: Dictionary<BaseFilter> = yield select(filtersSelectors.getFiltersMap);

  return mapFiltersToURLParams(normalizedFilters, filters);
}

/**
 * Handle touch filters.
 * When user checks the checkboxes for exclude type or any date in the touches filters,
 * API should also receive all touches except "None".
 * @param {Dictionary<PreselectedFilter>} filter - The filter from search saga
 */
export function* handleTouchFilters(filter: Dictionary<PreselectedFilter>) {
  const newFilter = cloneDeep(filter);

  const touchesExcludeFilters = [
    { data: newFilter.completed_touch_type as FormCheckboxOption[], type: 'completed' },
    { data: newFilter.schedule_touch_type as FormCheckboxOption[], type: 'schedule' },
  ];
  const touchesTypeDateFilters = [
    { data: newFilter.completed_touch_type_date as FormCheckboxOption[], type: 'completed' },
    { data: newFilter.schedule_touch_type_date as FormCheckboxOption[], type: 'schedule' },
  ];
  const touchesDateFilters = [
    { data: newFilter.completed_touch_date as FormCheckboxOption[], type: 'completed' },
    { data: newFilter.schedule_touch_date as FormCheckboxOption[], type: 'schedule' },
  ];
  const touchesFilters = [
    ...touchesExcludeFilters,
    ...touchesTypeDateFilters,
    ...touchesDateFilters,
  ];

  let shouldAddAllTouches = {
    completed: false,
    schedule: false,
  };

  touchesFilters.forEach(touchesFilter => {
    const { data, type } = touchesFilter;

    let isFilterValid = false;
    const newFilterTouch =
      type === 'completed' ? newFilter.completed_touch : newFilter.schedule_touch;

    if (!(newFilterTouch as MultiselectMappedFilter[])?.length) {
      isFilterValid = data?.some(touchFilter => {
        const checkboxCondition = touchFilter.checked;
        const dateRangeCondition =
          (touchFilter.label === 'Date Start' || touchFilter.label === 'Date End') &&
          touchFilter.value !== '';

        if (checkboxCondition || dateRangeCondition) return true;
      });
    }

    if (isFilterValid) {
      if (type === 'completed') shouldAddAllTouches.completed = true;
      else shouldAddAllTouches.schedule = true;
    }
  });

  if (shouldAddAllTouches.completed || shouldAddAllTouches.schedule) {
    const filterSources: FilterSource[] = yield select(filtersSelectors.getCompanyFilters);

    // completed and scheduled touches both have the same names and values
    const completedTouchesFilter = filterSources[3].data.find(
      filter => filter.column === 'completed_touches_group'
    )?.data[1] as FilterGroupDataSection;

    let touches = completedTouchesFilter.data?.filter(obj => obj.value !== 'blank');

    if (!touches) {
      touches = FILTER_TOUCHES.map(value => ({
        value: value,
        name: value,
      }));
    }

    const mappedTouches: MultiselectMappedFilter[] = touches.map(touch => {
      return {
        disabled: undefined,
        entries: undefined,
        label: touch.name,
        value: touch.value,
        ...(touch.parent && { parent: touch.parent }),
      };
    });

    if (shouldAddAllTouches.completed) newFilter.completed_touch = mappedTouches;
    if (shouldAddAllTouches.schedule) newFilter.schedule_touch = mappedTouches;
  }

  return newFilter;
}
