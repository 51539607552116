import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// models
import { CompanyTouchesFiltersState } from './interfaces';
import { SearchTouchesPayload } from '../search/interfaces';
import { SelectOption } from '@optx/models/Option';
import {
  CompanyTouchesOptionsResponse,
  CompanyTouchesResponse,
  TouchInitiator,
  TouchTypeResponse,
} from '@optx/models/company/CompanyTouch';
import * as actions from './actions';
import { fetchTouchesSuccess } from '../search/actions';
import {
  fetchReducer,
  fetchFailReducer,
  fetchSuccessReducer,
} from '../../../feature/fetch/reducers';

const initialState: CompanyTouchesFiltersState = {
  data: null,
  loading: false,
  error: '',
  fetchedAt: '',
  initiators: [],
  touchTypes: [],
};

// fetch touches filters reducer
const fetchTouchesFiltersSuccessReducer: CaseReducer<
  CompanyTouchesFiltersState,
  PayloadAction<CompanyTouchesOptionsResponse>
> = (draftState, action) => {
  fetchSuccessReducer(draftState, action);

  const { available_initiators: availableInitiators, available_touch_types: availableTouchTypes } =
    action.payload;

  const initiators = mapInitiators(availableInitiators);
  draftState.initiators = initiators;
  draftState.currentInititator = initiators.length ? initiators[0].value : undefined;

  const touchTypes = mapTouchTypes(availableTouchTypes);
  draftState.touchTypes = touchTypes;
  draftState.currentTouchType = touchTypes.length ? touchTypes[0].value : undefined;

  draftState.fetchedAt = new Date().toISOString();

  const currentInitiator = action.payload.currentInitiator;

  if (currentInitiator) {
    draftState.currentInititator = currentInitiator;
  }

  const currentType = action.payload.currentType;

  if (currentType) {
    draftState.currentTouchType = currentType;
  }
};

const ALL_OPTION: SelectOption = {
  label: 'All',
  value: '*',
};

function mapInitiators(initiators: Array<TouchInitiator>) {
  // initialize with all option
  const options: Array<SelectOption> = [];

  if (!initiators.length) {
    return options;
  }

  options.push(ALL_OPTION);

  initiators.forEach(item =>
    options.push({
      label: item.initiatorname,
      value: item.initiatorid === null ? '' : item.initiatorid.toString(),
    })
  );

  return options;
}

const fetchTouchesSuccessReducer: CaseReducer<
  CompanyTouchesFiltersState,
  PayloadAction<CompanyTouchesResponse, any, SearchTouchesPayload>
> = (draftState, action) => {
  const searchPayload = action.meta;

  draftState.currentInititator = searchPayload.initiatorId || '*';
  draftState.currentTouchType = searchPayload.touchType || '*';
};

function mapTouchTypes(touchTypes: Array<TouchTypeResponse>) {
  // initialize with all option
  const options: Array<SelectOption> = [];

  if (!touchTypes.length) {
    return options;
  }

  options.push(ALL_OPTION);

  touchTypes.forEach(item =>
    options.push({
      label: item.touchtype,
      value: item.touchtype,
    })
  );

  return options;
}

const reducer = createReducer(initialState, builder =>
  builder
    // fetch company touches filters
    .addCase(actions.fetchTouchesFilters, fetchReducer)
    .addCase(fetchTouchesSuccess, fetchTouchesSuccessReducer)
    .addCase(actions.fetchTouchesFiltersSuccess, fetchTouchesFiltersSuccessReducer)
    .addCase(actions.fetchTouchesFiltersFail, fetchFailReducer)
);

export default reducer;
