import styled from 'styled-components';
import { up } from 'styled-breakpoints';
// components
import { MainContent } from '@optx/components/common/layout';
import { Title } from '@optx/shared/view/atoms/Typography';
import {
  MultiSelectFilter,
  FilterSingleSelect,
} from '@optx/features/checklist-research-insights/components/Filters/';

const StyledMainContent = styled(MainContent)``;

const PageFiltersWrapper = styled.div``;

const PageTitleContent = styled.div``;

const QuickFiltersContent = styled.div``;

const QuickFilterWrapper = styled.div`
  .ant-select-selector {
    min-width: auto;
    border: 1px solid #1890ff !important;
    background: #074681 !important;
    font-size: 14px;
    white-space: no-wrap;
    color: #ffffff !important;
    &:hover {
      cursor: pointer;
      border: 1px solid #1890ff;
    }

    .prefixed-selection-item {
      &__prefix {
        color: #ffffff;
      }

      &__label {
        color: #1890ff;
      }
    }
  }
  .ant-select-arrow {
    color: #bae7ff !important;
  }
`;

const CardsWrapper = styled.div``;

const TableContentWrapper = styled.div``;

const Wrapper = styled.div<{ isFullScreen?: boolean }>`
  margin-top: ${({ isFullScreen }) => (isFullScreen ? '58px' : '0px')};
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 50px;

  ${TableContentWrapper}  {
    margin-bottom: 40px;

    .ant-table-wrapper {
      overflow: auto;
    }
    .legacy-table table {
      table-layout: auto !important;
      tbody .ant-table-cell {
        cursor: auto;
      }
      tbody {
        .stage,
        .pipeline_rank,
        .is_interesting,
        .is_software {
          cursor: pointer;
        }
      }
    }
  }

  ${StyledMainContent} {
    min-width: 1px;
    width: 100%;

    margin-left: 0;
    margin-right: 0;

    ${up('lg')} {
      margin-left: 0;
      margin-right: 0;
    }
  }

  ${PageFiltersWrapper},
  ${TableContentWrapper} {
    padding-left: 16px;
    padding-right: 16px;

    ${up('lg')} {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  ${PageFiltersWrapper} {
    padding-top: 23px;
    margin-bottom: 34px;
    padding-bottom: 100px;
    background: linear-gradient(360deg, #064885 0%, #137edf 124.9%);
    position: relative;

    .ant-breadcrumb {
      .ant-breadcrumb-link {
        color: white;
      }

      a:not(:hover),
      .ant-breadcrumb-separator {
        color: #bae7ff !important;
      }
    }
  }

  ${PageTitleContent} {
    display: flex;
    justify-content: space-between;

    .ant-col:last-child {
      padding-top: 7px;
    }
    @media all and (max-width: 1550px) {
      display: block;
      margin-bottom: 20px;
    }
  }

  ${QuickFiltersContent} {
    display: flex;
    align-items: center;
    gap: 11px;
    flex-wrap: nowrap;
    position: relative;
    z-index: 4;
    padding-bottom: 20px;
  }

  ${CardsWrapper} {
    position: relative;
    width: calc(100% - 80px);
    margin: 0px auto;
    margin-top: -125px;
    margin-bottom: 30px;
    z-index: 3;

    .ant-col {
      padding-bottom: 7px;
    }
  }

  @media (min-width: 1600px) {
    flex-wrap: nowrap;

    ${StyledMainContent} {
      flex: 1;
    }
  }
`;
const PageTitle = styled(Title)`
  margin-top: 10px;
  color: white !important;
`;

const StyledMultiSelectFilter = styled(MultiSelectFilter)`
  min-width: auto;

  .multiselect__control {
    border: 1px solid #1890ff;
    border-radius: 3px;
    flex-wrap: nowrap;

    &:hover {
      cursor: pointer;
      border: 1px solid #1890ff;
    }
  }
  .multiselect__placeholder {
    font-size: 14px;
    color: #ffffff;
    white-space: no-wrap;
  }
  .multiselect__value-container {
    padding-left: 16px;
    background: #074681;
    color: #ffffff;
  }
  .ant-typography {
    color: #ffffff;
  }

  .anticon-search {
    display: none;
  }
  .multiselect__indicators {
    background: #074681;
  }
  .multiselect__indicator {
    color: #ffffff !important;
  }
`;

const StyledSingleSelectFilter = styled(FilterSingleSelect)`
  min-width: 220px;

  div.styled-single-select__value-container {
    padding-left: 0;
  }

  div.styled-single-select__control {
    padding-left: 0;
    background: #074681;
    border: 1px solid #1890ff;
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      border: 1px solid #1890ff;
    }
    & > span {
      margin-left: 8px;

      & > span:last-child {
        font-size: 14px !important;
        color: #ffffff;
      }
    }
    .anticon {
      color: #ffffff;
    }
  }

  .anticon.anticon-search {
    display: none;
  }
`;

const TableTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Styled = {
  Wrapper,
  MainContent: StyledMainContent,
  PageTitle,
  PageFiltersWrapper,
  PageTitleContent,
  TableTitleContainer,
  TableContentWrapper,
  QuickFiltersContent,
  QuickFilterWrapper,
  CardsWrapper,
  MultiSelectFilter: StyledMultiSelectFilter,
  SingleSelectFilter: StyledSingleSelectFilter,
};

export default Styled;
