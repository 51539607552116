import React from 'react';
import moment from 'moment';
// models
import { Cell } from '@models/table/Cell';

import { cellIsEqual } from './memoize';

interface CustomCell extends Cell<any> {
  format?: string;
  // if you want to override format pass something in "defaultValue" prop
  defaultValue?: string;
}

const TableCellDate: React.FC<CustomCell> = ({ value, format, defaultValue }) => (
  <>{defaultValue || moment(value).format(format)}</>
);

export default React.memo(TableCellDate, cellIsEqual);
