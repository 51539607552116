import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Skeleton, Row, Col, Alert, Switch } from 'antd';
import { ValueType } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field, Input, Select } from 'formik-antd';
import { Formik, FormikProps, FieldProps } from 'formik';
// models
import { SelectOption } from '@optx/models/Option';
import {
  EditShareListForm,
  EditShareListFormUserOption,
} from '@optx/models/feature/shareList/form';
// redux
import { actions as shareListActions, selectors } from '../state';
import { selectors as listSelectors } from '@optx/features/list-share/state';
import {
  actions as favoriteListsActions,
  selectors as favoriteListsSelectors,
} from '@redux/favorite-lists/lists';
// components
import OwnerInfo from './OwnerInfo';
import Styled from './EditShareListModal.styled';
import MultiSelect from '@optx/components/common/form/formik/FormikMultiSelect';

const emptyInitialValues: EditShareListForm = {
  share_with: [],
  note: '',
};

const EditShareListModal = () => {
  const formWrapperRef = useRef<any>(null);
  const formRef = useRef<FormikProps<EditShareListForm> | null>(null);
  const dispatch = useDispatch();
  const modalIsOpen = useSelector(selectors.ui.isModalOpen);

  const userOptions = useSelector(selectors.userOptions);
  const initialSelectedUsers = useSelector(selectors.selectedUsers);
  const loadingInfo = useSelector(selectors.isLoading);
  const loadingPublic = useSelector(favoriteListsSelectors.isLoadingPublic);
  const isSubmitting = useSelector(selectors.shareList.isSubmitting);
  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);
  const apiError = useSelector(selectors.shareList.error);
  const [selectedUsers, setSelectedUsers] = useState<ValueType<SelectOption<string | number>>[]>(
    initialSelectedUsers as any[]
  );
  const failedLoadingInfo = useSelector(selectors.shareList.failedLoadingInfo);
  //@ts-ignore
  const listId = useSelector(listSelectors.shareList.editedSharedList);
  const lists = useSelector(favoriteListsSelectors.getLists);
  const list = lists[listId as number];
  const hasPermissionToPublic = list?.is_owner;

  const [isPublic, setIsPublic] = useState<boolean>(false);

  const cancelEditShareList = useCallback(
    () => dispatch(shareListActions.cancelEditShareList()),
    [dispatch]
  );
  const shareList = useCallback(
    (values: EditShareListForm) => {
      dispatch(shareListActions.shareList(values));
      setSelectedUsers([]);
    },
    [dispatch]
  );

  const onCancel = () => {
    if (formRef.current) {
      formRef.current.resetForm();
    }

    cancelEditShareList();
    setSelectedUsers([]);
    setButtonDisabled(true);
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const handlePublicSubmit = () => {
    if (listId) {
      dispatch(
        favoriteListsActions.publicWatchList({ list_id: listId, is_public: !list?.is_public })
      );
    }
  };

  const initialValues = useMemo(() => {
    return {
      ...emptyInitialValues,
      share_with: initialSelectedUsers,
    } as EditShareListForm;
  }, [initialSelectedUsers]);

  useEffect(() => {
    setSelectedUsers(selectedUsers as any[]);
    setButtonDisabled(selectedUsers.length < 1);
  }, [selectedUsers]);

  useEffect(() => {
    if (list) {
      setIsPublic(list.is_public);
    }
  }, [list]);

  const footer = loadingInfo ? null : undefined;

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLFormElement | HTMLDivElement>) => {
    if (event.key === 'Enter') {
      setTimeout(() => {
        handleSubmit();
      }, 100);
    }
  };

  const multiSelectKeyEvent = (e: any) => {
    if (e.key === 'Enter') {
      formWrapperRef.current?.focus();
      e.preventDefault();
    }
  };

  const handleDisableButton = () => {
    if (isButtonDisabled) {
      setButtonDisabled(false);
    }
  };

  const handleEditLabelMissing = (user: EditShareListFormUserOption) => {
    const currentAccessRight = user.accessRights;

    const customAccessRightsOptions = [...user.accessRightsOptions];

    const editPermissionValue = 2;

    const isEditOptionExists = customAccessRightsOptions.find(
      option => option.value === editPermissionValue
    );

    if (currentAccessRight === editPermissionValue && !isEditOptionExists) {
      customAccessRightsOptions.push({
        label: 'Edit',
        value: 2,
      });

      return customAccessRightsOptions;
    } else {
      return user.accessRightsOptions;
    }
  };

  const handleDropdownList = () => {
    const disabledOwners = initialValues.share_with.reduce((acc: string[], list) => {
      if (list.disabled) {
        acc.push(list.label);
      }

      return acc;
    }, []);
    const userOptionsList = (userOptions as EditShareListFormUserOption[]).map(user => {
      const isDisabled = disabledOwners.includes(user.label);

      return {
        ...user,
        disabled: isDisabled,
      };
    });

    return userOptionsList;
  };

  const handleShareWithValuesOrder = (value: EditShareListFormUserOption[]) => {
    const orderByOwner = value.sort((a, b) => {
      if (a.disabled && !b.disabled) {
        return -1;
      } else if (!a.disabled && b.disabled) {
        return 1;
      } else {
        return 0;
      }
    });

    return orderByOwner;
  };

  return (
    <Styled.Modal
      visible={modalIsOpen}
      title={
        <Styled.TitleContentWrapper>
          <Styled.Title>Manage List Permissions</Styled.Title>
          {hasPermissionToPublic && (
            <div>
              <Styled.Button
                type="text"
                loading={loadingPublic}
                onClick={handlePublicSubmit}
                icon={<Switch checked={isPublic} className="mr-2" />}
                size="small"
              >
                <Styled.Text>{isPublic ? 'Make not public' : 'Public list'}</Styled.Text>
              </Styled.Button>
            </div>
          )}
        </Styled.TitleContentWrapper>
      }
      centered
      onCancel={onCancel}
      okText="Save"
      okButtonProps={{
        onClick: handleSubmit,
        loading: isSubmitting,
        disabled: failedLoadingInfo || isButtonDisabled,
      }}
      width={hasPermissionToPublic ? 615 : 600}
      footer={footer}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={shareList}
        enableReinitialize
        innerRef={formRef}
        component={({ values }) => {
          if (failedLoadingInfo) {
            return <Alert type="error" message={apiError} />;
          }

          return (
            <Skeleton loading={loadingInfo} active>
              <Form layout="vertical" onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
                {apiError && <Alert type="error" message={apiError} style={{ marginBottom: 25 }} />}
                <Row gutter={20}>
                  <Col span={24}>
                    <Form.Item name="share_with" label="Select User(s)" required>
                      <div role="presentation" onKeyDown={handleDropdownKeyEvent} tabIndex={1}>
                        <Field name="share_with">
                          {(fieldProps: FieldProps) => (
                            // @ts-ignore
                            <MultiSelect
                              {...fieldProps}
                              options={handleDropdownList() as unknown as any[]}
                              customOnChange={value => setSelectedUsers(value)}
                              onKeyDown={multiSelectKeyEvent}
                            />
                          )}
                        </Field>
                      </div>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="note" label="Add a note">
                      <Input.TextArea name="note" rows={4} ref={formWrapperRef} />
                    </Form.Item>
                  </Col>
                </Row>
                {!!values.share_with.length && (
                  <>
                    <Styled.ShareUsersHeader justify="space-between" align="middle">
                      <Col>Users with access</Col>
                      <Col>Permissions</Col>
                    </Styled.ShareUsersHeader>
                    <Styled.SharedUsersWrapper>
                      {handleShareWithValuesOrder(values.share_with).map((user, index) => {
                        return (
                          <Styled.ShareUserRow
                            key={user.value}
                            justify="space-between"
                            align="middle"
                          >
                            <Col>
                              <OwnerInfo
                                name={user.label}
                                email={user.email}
                                ownerImage={user.img}
                              />
                            </Col>
                            <Col>
                              <Select
                                name={`share_with[${index}].accessRights`}
                                options={handleEditLabelMissing(user)}
                                onChange={handleDisableButton}
                                disabled={user.disabled || user.accessRightsOptions.length === 1}
                                style={{ width: 136 }}
                              />
                            </Col>
                          </Styled.ShareUserRow>
                        );
                      })}
                    </Styled.SharedUsersWrapper>
                  </>
                )}
              </Form>
            </Skeleton>
          );
        }}
      />
    </Styled.Modal>
  );
};

export default EditShareListModal;
