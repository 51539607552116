/* eslint-disable react/jsx-curly-newline */
import React, { useState, Context, useContext, useCallback, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Menu, Dropdown, Button, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
// utils
import { getRouteAlias } from '@optx/utils/routes';
// models
import {
  BulkEditFieldContext,
  SelectedCompanies,
  SelectedCompaniesKeys,
} from '@optx/models/bulkActions';
import { SelectOption } from '@optx/models/Option';
// constants
import ROUTES from '@constants/routes';
// redux
import { actions as bulkActionsET } from '@features/bulk-actions/equity-touch';
import { selectors as userSelectors } from '@redux/user/information';
import { selectors as individualEditSelectors } from '@components/feature/company-individual-edit/state';
import { selectors as listDetailsSelectors } from '@redux/lists/details';
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { actions as favoriteListsActions } from '@redux/favorite-lists/lists-popup';
// components
import {
  BulkEditModal,
  BulkEditField,
  actions as editFieldsActions,
} from '@features/bulk-actions/edit-fields';
import { MenuWrapper, ButtonLink } from './BulkActionsButton.styled';

const { Item } = Menu;

interface BulkActionsButtonProps {
  context: Context<BulkEditFieldContext>;
  selectedCompanies: SelectedCompanies[];
  referrer: string;
}

const BulkActionsButton: React.FC<BulkActionsButtonProps> = ({
  context,
  selectedCompanies,
  referrer,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { gridName } = useContext(context);
  const [visible, setIsModalVisible] = useState(false);
  const [title, setTitle] = useState('');
  const [field, setField] = useState<SelectedCompaniesKeys | undefined>();
  const [fieldTitle, setFieldTitle] = useState('');
  const [selectedColumn, setSelectedColumn] = useState<string>('');
  const [isValid, setIsValid] = useState(true);
  const [changedSelection, setChangedSelection] = useState(false);
  const userLastName = useSelector(userSelectors.getLastName);
  const companiesInMyHomePage = useSelector(searchSelectors.getCompanies('advancedSearch'));
  const companiesInMyCompaniesPage = useSelector(searchSelectors.getCompanies('myCompanies'));
  const companiesInMyWatchList = useSelector(searchSelectors.getCompanies('lists'));
  const addonManagementCompanies = useSelector(searchSelectors.getCompanies('addons'));
  const companiesOutReachPage = useSelector(searchSelectors.getCompanies('outreach'));

  const userFullName = useSelector(userSelectors.getFullName);
  const companyOwnerOptions = useSelector(
    individualEditSelectors.companyIndividualEdit.companyOwnerOptions
  );
  const components = useSelector(userSelectors.authorizedComponents);
  const areAllCompaniesInEqt = useSelector(
    bulkActionsSelectors.selectedCompanies.areAllCompaniesInEqt(gridName)
  );
  const areCompaniesAssigned = useSelector(
    bulkActionsSelectors.selectedCompanies.areCompaniesAssigned(gridName, userFullName)
  );
  const hasEditPermission = useSelector(listDetailsSelectors.getListEditPermission);

  const sessionSettings = useSelector(userSelectors.getSessionSettings);
  const { pathname } = useLocation();

  const isAdmin = Object.keys(components).includes('admin_portal_link');
  const isRemoveFromWatchList = gridName === 'watchlists';
  const isPartnerUser = Object.keys(components).includes('partner_user');
  const canMergeCompanies = isAdmin || isPartnerUser;
  const view = getRouteAlias();

  useEffect(() => {
    if (!sessionSettings?.et_form_auto_save && changedSelection) {
      const params = pathname.split('/');
      const listType = params[1];
      const listId = params[2];
      history.push({
        pathname: `/equity-touch-bulk/step-1/${gridName}`,
        state: { listId, listType },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionSettings?.et_form_auto_save, changedSelection]);

  const openModal = (
    title: string,
    field: SelectedCompaniesKeys,
    fieldTitle: string,
    column: string
  ) => {
    setIsModalVisible(true);
    setTitle(title);
    setField(field);
    setFieldTitle(fieldTitle);
    setSelectedColumn(column);

    if (field === 'watchlist') {
      // update List
      dispatch(favoriteListsActions.fetchFavoriteListsPopup());
    }

    // functionality for getting fund and rationale data for displaying
    // in the selected companies table. UI changes were added, but the code for
    // requesting the data is commented until api is completed.

    // if (field === 'fund' || field === 'rationale') {
    //   dispatch(
    //     selectedCompaniesActions.fetchBulkEditData({
    //       companyIds: selectedCompanies.map(company => company.company_id),
    //       gridName,
    //       field,
    //     })
    //   );
    // }
  };

  const handleCompaniesList = () => {
    if (gridName === 'advancedSearch') {
      return companiesInMyHomePage;
    }

    if (gridName === 'myCompanies') {
      return companiesInMyCompaniesPage;
    }

    if (gridName === 'watchlists') {
      return companiesInMyWatchList;
    }

    if (gridName === 'addonManagement') {
      return addonManagementCompanies;
    }

    if (gridName === 'companiesOutReach') {
      return companiesOutReachPage;
    }

    return [];
  };

  const handleOpenUrl = () => {
    const URLlinks = selectedCompanies.map(companies => companies.company_id);

    const orderedLink = handleCompaniesList()
      .map((_, index) => {
        return URLlinks.filter(company => company === handleCompaniesList()[index].company_id);
      })
      .flat();

    const orderedLinkLimited = orderedLink.slice(0, 20);
    orderedLinkLimited.forEach(link => window.open(`/profile/${link}`));
  };

  const changeVisibility = (visible: boolean) => {
    setIsModalVisible(visible);
  };

  const handleBulkEquityTouch = (event: { preventDefault: () => void }) => {
    if (areAllCompaniesInEqt) {
      message.error('All companies selected are already in Equity Touch');
    } else {
      event.preventDefault();
      dispatch(bulkActionsET.autoSave(null));
      dispatch(bulkActionsET.resetProgress());
      setChangedSelection(true);
    }
  };

  const handleBulkSalesloft = () => {
    history.push(`/salesloft-bulk/${gridName}?action=bulk&referrer=${referrer}`);
  };

  const handleClaimAsOwner = useCallback(() => {
    const owner: SelectOption | undefined = companyOwnerOptions.find(
      option => option.label === userLastName
    );

    if (!owner) {
      message.error("System can't find corresponding Company Owner");

      return;
    }

    if (areCompaniesAssigned) {
      message.error('Selected companies are already assigned to you');
    } else {
      setIsModalVisible(true);
      setTitle('Claim as Owner');
      setFieldTitle('Company Owner');
      setSelectedColumn('company_owner');
      dispatch(editFieldsActions.updateProgress(true));
      dispatch(
        editFieldsActions.bulkEditField({
          selectedCompanies,
          selectedData: [owner],
          field: 'company_owner_id',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areCompaniesAssigned, companyOwnerOptions, dispatch, selectedCompanies, userLastName]);

  const handleMergeCompanies = useCallback(() => {
    setIsModalVisible(true);
    setTitle('Are you sure you want to merge these companies?');
    setFieldTitle('Merge Companies');
    setField('merge_companies');
    setSelectedColumn('merge_companies');
  }, []);

  const handleRemoveFromWatchList = useCallback(() => {
    setIsModalVisible(true);
    setTitle('Remove From Watchlist');
    setField('remove_from_watchlist');
    setFieldTitle('Remove From WatchList');
    setSelectedColumn('remove_from_watchlist');
  }, []);

  const handleRemoveFromAddonManegement = useCallback(() => {
    setIsModalVisible(true);
    setTitle('Remove From Add-on Management');
    setField('remove_from_addon_management');
    setFieldTitle('Remove From Add-on Management');
    setSelectedColumn('remove_from_addon_management');
  }, []);

  const menu = (
    <MenuWrapper>
      {ROUTES.myCompanies !== location.pathname && gridName !== 'addonManagement' && (
        <Item key="0">
          <ButtonLink type="text" onClick={handleBulkEquityTouch}>
            Add to Equity Touch
          </ButtonLink>
        </Item>
      )}

      {gridName === 'addonManagement' ? (
        <Item key="1" onClick={() => openModal('Set Add-on', 'addon', 'Add-on', 'addon')}>
          Make an Add-on
        </Item>
      ) : (
        <Item key="1">
          <ButtonLink type="text" onClick={handleBulkSalesloft}>
            Add to Sales Loft Cadence
          </ButtonLink>
        </Item>
      )}

      {gridName === 'addonManagement' ? (
        <Item key="2" onClick={() => handleRemoveFromAddonManegement()}>
          Remove as Add-on
        </Item>
      ) : (
        <Item key="2" onClick={() => openModal('Watchlist', 'watchlist', 'Watchlist', 'watchlist')}>
          Create or add to Watchlist
        </Item>
      )}
      {hasEditPermission && isRemoveFromWatchList && (
        <Item key="3" onClick={handleRemoveFromWatchList}>
          Remove From Watchlist
        </Item>
      )}
      <Item key="4" onClick={() => openModal('Edit Stage', 'stage', 'Stage', 'stage')}>
        Edit Stage
      </Item>
      <Item key="5" onClick={() => openModal('Bulk Add Tag', 'cs_tags', 'Tags', 'tags')}>
        Add tags
      </Item>
      <Item key="6" onClick={() => handleOpenUrl()}>
        Open Url in new Tab
      </Item>
      <Item
        key="7"
        onClick={() =>
          openModal('Set Pipeline Rank', 'pipeline_rank', 'Pipeline Rank', 'pipeline_rank')
        }
      >
        Set Pipeline Rank
      </Item>
      <Item key="8" onClick={() => openModal('Set Fund', 'fund', 'Fund', 'fund')}>
        Set Fund
      </Item>
      <Item
        key="9"
        onClick={() => openModal('Set Rationale', 'rationale', 'Rationale', 'rationale')}
      >
        Set Rationale
      </Item>
      <Item
        key="10"
        onClick={() =>
          openModal('Set as Software Company', 'is_software', 'Software', 'is_software')
        }
      >
        Set as Software Company
      </Item>
      <Item
        key="11"
        onClick={() =>
          openModal('Set Company Owner', 'company_owner_id', 'Company Owner', 'company_owner')
        }
      >
        Change Ownership
      </Item>
      <Item
        key="12"
        onClick={() => openModal('Set Lead Source', 'lead_source', 'Lead Source', 'lead_source')}
      >
        Change Lead Source
      </Item>
      <Item
        key="13"
        onClick={() =>
          openModal(
            'Set Senior Advisor Thesis',
            'senior_advisor_thesis',
            'Senior Advisor Thesis',
            'senior_advisor_thesis'
          )
        }
      >
        Change Senior Advisor Thesis
      </Item>
      {gridName !== 'addonManagement' ? (
        <Item key="14" onClick={() => openModal('Set Add-on', 'addon', 'Add-on', 'addon')}>
          Make an Add-on
        </Item>
      ) : (
        <Item key="14">
          <ButtonLink type="text" onClick={handleBulkSalesloft}>
            Add to Sales Loft Cadence
          </ButtonLink>
        </Item>
      )}

      {gridName === 'addonManagement' && (
        <Item
          key="15"
          onClick={() => openModal('Watchlist', 'watchlist', 'Watchlist', 'watchlist')}
        >
          Create or add to Watchlist
        </Item>
      )}

      {!isPartnerUser && !isAdmin && (
        <Item key="16" onClick={handleClaimAsOwner}>
          Claim as owner
        </Item>
      )}
      {hasEditPermission && canMergeCompanies && view !== 'myCompanies' && (
        <Item key="17" onClick={handleMergeCompanies}>
          Merge Companies
        </Item>
      )}
      <Item
        key="18"
        onClick={() => openModal('Set Sub-sector', 'sub_sector', 'Sub-sector', 'sub_sector')}
      >
        Set Sub-sector
      </Item>
      <Item
        key="19"
        onClick={() =>
          openModal(
            'Add Product Category',
            'product_category',
            'Product Category',
            'product_category'
          )
        }
      >
        Add Product Category
      </Item>
      <Item
        key="20"
        onClick={() => openModal('Set AI / ML Company', 'is_ai_ml', 'AI / ML', 'is_ai_ml')}
      >
        Set AI / ML Company
      </Item>
    </MenuWrapper>
  );

  return (
    <>
      <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
        <Button type="primary">
          Actions <DownOutlined />
        </Button>
      </Dropdown>
      <BulkEditModal
        title={title}
        visible={visible}
        changeVisibility={changeVisibility}
        editField={field as SelectedCompaniesKeys}
        editFieldTitle={fieldTitle}
        column={selectedColumn}
        context={context}
        selectedCompanies={selectedCompanies}
        isValid={isValid}
        validation={setIsValid}
      >
        <BulkEditField
          field={field as SelectedCompaniesKeys}
          context={context}
          isValid={isValid}
          validation={setIsValid}
        />
      </BulkEditModal>
    </>
  );
};

export default BulkActionsButton;
