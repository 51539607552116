import React from 'react';
import { Row, Col, Progress } from 'antd';
// components
import CardSubFilters from './CardSubFilters';
import { MyCompaniesCardValues, MyCompaniesCardProps } from '../interface';

const defaultColSize = { span: 8 };

const CardContentChart: React.FC<MyCompaniesCardProps> = ({ data, colSize }) => {
  const { values, filters, id } = data;

  if (!values) {
    return null;
  }

  const firstValue = values[0].graphValue || 0;
  const secondValue = values[1].graphValue || 0;
  const thirdValue = values[2].graphValue || 0;

  return (
    <>
      <Row gutter={8}>
        {values.map((cardValue: MyCompaniesCardValues, index: number) => (
          <Col {...((colSize && colSize[index]) || defaultColSize)} key={index}>
            <CardSubFilters
              key={index}
              cardId={id}
              value={cardValue.value}
              label={cardValue.label}
              filter={filters[index].label}
              filterId={index}
              valueProps={cardValue.valueProps}
              className={cardValue.className}
            />
          </Col>
        ))}
      </Row>
      {firstValue || secondValue || thirdValue ? (
        <Progress
          percent={firstValue + secondValue}
          success={{
            percent: firstValue,
            strokeColor: values[0].graphColor,
          }}
          strokeColor={values[1].graphColor}
          trailColor={thirdValue ? values[2].graphColor : '#fff'}
          showInfo={false}
        />
      ) : null}
    </>
  );
};

export default CardContentChart;
