import React from 'react';
import { Tooltip, Typography } from 'antd';
// models
import { TableCellWatchlists } from '@optx/models/table/Cell';
// hooks
import useMyWatchlists from '@hooks/my-watchlists/useMyWatchlists';
import { Styled } from './TableCellName.styled';

const TableCellName: React.FC<TableCellWatchlists> = ({ value, row }) => {
  const { handleMyWatchlistsResults } = useMyWatchlists();
  const { extra_options: extraOptions, is_public: isPublic } = row;

  const isETGroup = extraOptions && typeof extraOptions.et_group_id === 'number';

  const handleClick = () => {
    if (row.unique_id) {
      handleMyWatchlistsResults(row.unique_id.toString());
    }
  };

  return (
    <Styled.Wrapper role="presentation" onClick={handleClick}>
      <Typography.Text style={{ maxWidth: '100%' }} ellipsis>
        <Tooltip
          className="keep-space-sequence"
          overlayClassName="keep-space-sequence"
          title={value}
        >
          {value}
        </Tooltip>
      </Typography.Text>
      {isETGroup && (
        <span>
          &nbsp;
          <Styled.Tag color="gold">ET</Styled.Tag>
        </span>
      )}
      {isPublic && (
        <span>
          &nbsp;
          <Styled.Tag color="gold">Public</Styled.Tag>
        </span>
      )}
    </Styled.Wrapper>
  );
};

export default TableCellName;
