import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import queryString from 'query-string';

// models
import { FilterSource, PreselectedFilter, CompanyFilterType } from '@models/filters';
import { FilterTag } from '@models/tags';
import { FilterMultiTextOption, SelectOption } from '@optx/models/Option';
import { UserInformation } from '@optx/models/user';
// utils
import { normalizeFiltersByColumn } from '@utils/filters/normalize';
import { injectFilter } from '@utils/filters/injectFilter';
import { mapPreselectedFilters } from '@utils/filters/preselectedValues';
import { parseFilter } from '@optx/utils/filters/parseFilters';
// redux
import { actions as userInformationActions } from '@redux/user/information';
import { FiltersState, ApplyFiltersPayload } from '../interfaces';
import { actions } from '../actions';

const initialState: FiltersState = {
  data: [],
  filter: {},
  values: {},
  clear: {},
  preselected: {},
  persistedFilter: '',
  normalized: {},
  fetchedAt: null,
  searchKey: '',
};

const fetchSuccessReducer: CaseReducer<
  FiltersState,
  PayloadAction<Array<FilterSource>, any, string | null>
> = (draftState, action) => {
  const sourceData = action.payload[0].data.map(item =>
    // eslint-disable-next-line no-nested-ternary
    item.column === 'ddate'
      ? {
          ...item,
          default_value: item.data[0],
          type: 'date_range_radio' as CompanyFilterType,
        }
      : item.column === 'stage_change'
      ? {
          ...item,
          default_value: item.data[2],
        }
      : item
  );

  const sources = action.payload.flatMap(item => ({ ...item, data: sourceData }));

  const defaultAnalystName = action.meta;

  const preselected = mapPreselectedFilters(sources, true, 0);
  draftState.preselected = preselected;
  const clearedFilter = mapPreselectedFilters(sources, false, 0);
  const normalized = normalizeFiltersByColumn(action.payload);
  // @ts-ignore for now
  draftState.normalized = normalized;

  const defaultAnalysts = normalized.analyst_id.data
    .map((item: any) => ({
      label: item.name,
      value: item.value.toString(),
    }))
    .filter((item: any) =>
      typeof defaultAnalystName === 'string' ? item.label === defaultAnalystName : item
    );

  const defaultTouches = normalized.touch_type.data.map((item: any) => ({
    label: item.name,
    value: item.value.toString(),
  }));

  const defaultStages = normalized.so_stage.data.map((item: any) => ({
    label: item.name,
    value: item.value.toString(),
  }));

  const defaultStageChange = [
    {
      label: 'All',
      value: '',
    },
  ];

  if (draftState.persistedFilter !== undefined) {
    const persistedFilter = draftState.persistedFilter;
    const [parsedFilters] = parseFilter(persistedFilter, action.payload);

    const parsedFiltersData = {
      ...parsedFilters,
      ddate: (parsedFilters.ddate as SelectOption[]).map(item => item.value),
      analyst_id: parsedFilters.analyst_id
        ? parsedFilters.analyst_id
        : parsedFilters.analyst_id === undefined && persistedFilter.includes('analyst_id')
        ? []
        : defaultAnalysts,
      touch_type:
        parsedFilters.touch_type &&
        (parsedFilters.touch_type as FilterMultiTextOption[])?.length !== 0
          ? parsedFilters.touch_type
          : defaultTouches,
      so_stage:
        parsedFilters.so_stage && (parsedFilters.so_stage as FilterMultiTextOption[])?.length !== 0
          ? parsedFilters.so_stage
          : defaultStages,
      stage_change: parsedFilters.stage_change ? parsedFilters.stage_change : defaultStageChange,
    };

    draftState.values = parsedFiltersData;
    draftState.filter = parsedFiltersData;
  }

  if (draftState.persistedFilter === '') {
    const preselectedData = {
      ...preselected,
      analyst_id:
        (preselected.analyst_id as FilterMultiTextOption[]).length === 0
          ? defaultAnalysts
          : preselected.analyst_id,
      touch_type:
        (preselected.touch_type as FilterMultiTextOption[]).length === 0
          ? defaultTouches
          : preselected.touch_type,
      so_stage:
        (preselected.so_stage as FilterMultiTextOption[]).length === 0
          ? defaultStages
          : preselected.so_stage,
      stage_change: defaultStageChange,
    };
    draftState.values = preselectedData;
    draftState.filter = preselectedData;
  }

  draftState.clear = {
    ...clearedFilter,
    analyst_id: defaultAnalysts,
    touch_type: defaultTouches,
    so_stage: defaultStages,
    stage_change: defaultStageChange,
  };
  draftState.preselected = {
    ...preselected,
    analyst_id: draftState.filter.analyst_id,
    touch_type: draftState.filter.touch_type,
    so_stage: draftState.filter.so_stage,
    stage_change: draftState.filter.stage_change,
  };
  draftState.data = sources;
  draftState.fetchedAt = new Date().toISOString();
};

const clearFiltersReducer: CaseReducer<FiltersState> = draftState => {
  draftState.filter = draftState.clear;
  draftState.values = draftState.clear;
  draftState.searchKey = '';
};

const resetToDefaultReducer: CaseReducer<FiltersState> = draftState => {
  draftState.filter = draftState.preselected;
  draftState.values = draftState.preselected;
  draftState.searchKey = '';
};

const applyFiltersReducer: CaseReducer<
  FiltersState,
  PayloadAction<Partial<ApplyFiltersPayload>>
> = (draftState, action) => {
  if (action.payload && action.payload.filter) {
    draftState.filter = action.payload.filter;
    draftState.values = action.payload.filter;
  }

  if (action.payload) {
    draftState.searchKey = action.payload.searchKey ? action.payload.searchKey : '';
  }
};

const resetFilterReducer: CaseReducer<FiltersState, PayloadAction<FilterTag>> = (
  draftState,
  action
) => {
  const tag = action.payload;

  if (tag.filter === 'keyword') {
    draftState.searchKey = '';
  } else {
    const { normalized, clear, filter } = draftState;

    const newFilters = injectFilter(normalized, filter, clear, tag.filter, tag.optionId);
    draftState.filter = newFilters;
    draftState.values = newFilters;
  }
};

const updateValueReducer: CaseReducer<
  FiltersState,
  PayloadAction<{ key: string; value: PreselectedFilter }>
> = (draftState, action) => {
  const { key, value } = action.payload;
  draftState.filter = { ...draftState.filter, [key]: value };
  draftState.values = { ...draftState.values, [key]: value };
};

// External reducers
// Update persisted filter to be used when filters are loaded.
const fetchUserInformationSuccessReducer: CaseReducer<
  FiltersState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const persistedSearch = action.payload.settings.session_settings.sourcing_outreach_filters
    ? action.payload.settings.session_settings.sourcing_outreach_filters
    : undefined;

  if (persistedSearch !== undefined) {
    draftState.persistedFilter = persistedSearch;
  }

  if (persistedSearch !== undefined && /^.*outreach_query.*$/.test(persistedSearch)) {
    const data = queryString.parse(persistedSearch);

    draftState.searchKey = data.outreach_query as string;
  } else {
    draftState.searchKey = '';
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.filters.fetchSuccess, fetchSuccessReducer)
    .addCase(actions.filters.clearFilters, clearFiltersReducer)
    .addCase(actions.filters.resetToDefault, resetToDefaultReducer)
    .addCase(actions.filters.applyFilters, applyFiltersReducer)
    .addCase(actions.filters.resetFilter, resetFilterReducer)
    .addCase(actions.filters.updateFilters, updateValueReducer)
    // External reducer
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
);

export default reducer;
