import queryString from 'query-string';

/**
 * Function used to handle updateUserSettings data.
 * When changing account region, it affects the pipeline_report query saved in user endpoint.
 * @param pipelineReportQuery filter query saved in the user endpoint
 * @param newOptxScoreSetting new account region selected
 * @returns
 */
export const getNewPipelineReportQuery = (
  pipelineReportQuery: string,
  newOptxScoreSetting: string
) => {
  const newQuery = queryString.parse(pipelineReportQuery, { arrayFormat: 'comma' });

  if (newOptxScoreSetting === 'us') {
    // default to All Regions if the selected region is US
    delete newQuery.is_il_optx_score;
  } else if (newOptxScoreSetting === 'il') {
    // default to Israel if the selected region is IL
    newQuery.is_il_optx_score = 'true';
  }

  delete newQuery.fund;

  return queryString.stringify(newQuery, { arrayFormat: 'comma' });
};
