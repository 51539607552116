import React from 'react';
// constants
import { TotalsCardLabels } from '@optx/features/analyst-home/constants/labels';
// components
import Styles from './Sidebar.styles';
import { LineSkeleton } from '../../Skeleton';

interface TotalsCardProps {
  data: {
    label: string;
    value: number | null;
    color?: string;
  }[];
  isPipeline?: boolean;
  handleRedirects?: (label: string) => void;
  loading: boolean;
}

const TotalsCard: React.FC<TotalsCardProps> = ({
  data,
  loading,
  isPipeline = false,
  handleRedirects,
}) => {
  return (
    <Styles.TotalsCardWrapper>
      {loading ? (
        <div className={isPipeline ? 'chart-loading' : 'chart-loading-wrapper'}>
          {Array.from({ length: 4 }).map((_, index) => (
            <LineSkeleton key={index} width={'60px'} isCenter={false} />
          ))}
        </div>
      ) : (
        data.map(item => {
          const label = TotalsCardLabels[item.label as keyof typeof TotalsCardLabels];

          return (
            <Styles.TotalsCardItem key={item.label}>
              <Styles.TotalsCardItemLabel>{label}</Styles.TotalsCardItemLabel>
              <Styles.TotalsCardItemValue
                color={item.color}
                style={{
                  cursor: handleRedirects ? 'pointer' : 'inherit',
                }}
                onClick={() => handleRedirects && handleRedirects(label)}
                role="button"
              >
                {item.value}
              </Styles.TotalsCardItemValue>
            </Styles.TotalsCardItem>
          );
        })
      )}
    </Styles.TotalsCardWrapper>
  );
};

export default TotalsCard;
