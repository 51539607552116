import { Typography } from 'antd';
import styled from 'styled-components';

const ShowingCount = styled(Typography.Text)`
  font-size: 16px;
  color: rgb(137, 151, 177);
`;

export const StyledShowingCount = {
  ShowingCount,
};
