import queryString from 'query-string';
import { SortByRule } from '@models/table/sorting';

/**
 * Parse sorting from stringified to array
 * @param {string} sortQuery
 */
export const parseSorting = (sortQuery: string) => {
  const sortBy: Array<SortByRule<any>> = [];
  let isMultiSort = false;
  const parsedSort = queryString.parse(sortQuery, { arrayFormat: 'comma' });

  if (typeof parsedSort.sort_fields === 'string') {
    sortBy.push({
      id: parsedSort.sort_fields,
      desc: parsedSort.sort_orders === 'desc',
    });
  } else {
    parsedSort.sort_fields?.forEach((field, index) => {
      sortBy.push({
        id: field as string,
        desc: parsedSort.sort_orders![index] === 'desc',
      });
    });
    isMultiSort = true;
  }

  return { sortBy, isMultiSort };
};
