import { Progress } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 0 0 20px;
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  /* Dark BLue */
  color: #334d6e;
  opacity: 0.8;
  font-family: 'Lato', sans-serif;
`;

const MainStatValue = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* Daybreak Blue / blue-6 */
  color: #1890ff;
  opacity: 0.8;
`;
const MainStatLabel = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  /* Daybreak Blue / blue-6 */
  color: #1890ff;
  opacity: 0.8;
`;

const ComparedValue = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  /* Gray */
  color: #90a0b7;
  opacity: 0.8;
`;
const ComparedValueBlue = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* Gray */
  color: #595959;
  opacity: 0.8;
`;

const StyledPropgress = styled(Progress)`
  display: block;
  line-height: 1;
  margin: 0 0 4px;

  &.ant-progress-line {
    font-size: 4px;

    .ant-progress-bg {
      /* Height is set directly on element and can't be overriden otherwise. */
      height: 4px !important;
    }
  }
`;

const MainStatProgress = styled(StyledPropgress)`
  .ant-progress-bg {
    /* Daybreak Blue / blue-6 */
    background-color: #1890ff;
  }
`;

const ComparedProgress = styled(StyledPropgress)`
  .ant-progress-bg {
    /* Gray */
    background-color: #90a0b7;
  }
`;

const Styles = {
  Wrapper,
  BottomWrapper,
  Title,
  MainStatValue,
  MainStatProgress,
  MainStatLabel,
  ComparedValue,
  ComparedValueBlue,
  ComparedProgress,
};

export default Styles;
