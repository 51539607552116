import { ActionCreatorWithPreparedPayload, createAction } from '@reduxjs/toolkit';
import ShareListInfo from '@optx/models/feature/shareList/ShareListInfo';
import { ListType } from '@optx/models/WatchList';
import { EditShareListForm } from '@optx/models/feature/shareList/form';
import { createFeatureActionType, createFeatureAction } from '@utils/feature';
import featureName from '../../featureName';
import * as actionTypes from './actionTypes';
import { EditShareListActionPayload } from './interfaces';

// edit share list
export const editShareList: ActionCreatorWithPreparedPayload<
  [number | string, ListType],
  EditShareListActionPayload
> = createAction(
  createFeatureActionType(featureName, actionTypes.EDIT_SHARE_LIST),
  (listId, type) => ({
    payload: { listId, type },
  })
);
export const cancelEditShareList = createFeatureAction(
  featureName,
  actionTypes.CANCEL_EDIT_SHARE_LIST
);
// share list
export const shareList = createFeatureAction<EditShareListForm>(
  featureName,
  actionTypes.SHARE_LIST
);
export const shareListSuccess = createFeatureAction(featureName, actionTypes.SHARE_LIST_SUCCESS);
export const shareListFail = createFeatureAction<string>(featureName, actionTypes.SHARE_LIST_FAIL);
// fetch share list info
export const fetchShareListInfo = createFeatureAction<number>(
  featureName,
  actionTypes.FETCH_SHARE_LIST_INFO
);
export const fetchShareListInfoSuccess = createFeatureAction<ShareListInfo>(
  featureName,
  actionTypes.FETCH_SHARE_LIST_INFO_SUCCESS
);
export const fetchShareListInfoFail = createFeatureAction<string>(
  featureName,
  actionTypes.FETCH_SHARE_LIST_INFO_FAIL
);
