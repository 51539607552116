import React from 'react';
import { Layout } from 'antd';
import { BasicProps } from 'antd/lib/layout/layout';
import classNames from 'classnames';

const { Header } = Layout;

interface MaineHeaderProps extends BasicProps {
  fixed?: boolean;
}

const MainHeader: React.FC<MaineHeaderProps> = ({ fixed, className, ...restProps }) => {
  const headerClassName = classNames(className, { fixed });

  return <Header className={headerClassName} {...restProps} />;
};

MainHeader.defaultProps = {
  className: 'main-header',
};

export default React.memo(MainHeader);
