import React, { useMemo } from 'react';
import { forEach } from 'lodash';
import { useField } from 'formik';
import { Form, Input, FormItemProps } from 'formik-antd';
import { Select } from 'antd';
import { EquityTouchFetchedField, EquityTouchKeys, DescriptionFormItem } from '@models/equityTouch';

type SourceDescriptionFieldItemProps = Omit<FormItemProps, 'children'> & {
  formItem: EquityTouchFetchedField;
  fieldName: EquityTouchKeys;
  readOnly?: boolean;
};

const descriptionFieldName: EquityTouchKeys = 'Description';

export const SourceDescriptionFieldItem: React.FC<SourceDescriptionFieldItemProps> = ({
  className,
  formItem,
  fieldName,
  fieldKey,
  readOnly,
  ...restProps
}) => {
  const [textField, , textFieldhelpers] = useField({
    name: descriptionFieldName,
  });

  const {
    descriptions,
    options,
    label: selectLabel,
  } = (formItem as DescriptionFormItem).meta.sources;

  const handleSelectChange = (value: string) => {
    // Update description text on change.
    textFieldhelpers.setValue(descriptions[value] || '');
  };

  // Compute select value based on current description.
  const selectValue = useMemo(() => {
    let newSelectValue: string | undefined;

    if (options.length) {
      forEach(descriptions, (value, textKey) => {
        if (value === textField.value) {
          newSelectValue = textKey;

          return false;
        }

        return undefined;
      });
    }

    return newSelectValue;
  }, [descriptions, options.length, textField.value]);

  return (
    <>
      {options.length && (
        <Form.Item name={fieldName} label={selectLabel}>
          <Select
            placeholder={`Please select ${fieldName}`}
            onChange={handleSelectChange}
            options={options}
            value={selectValue}
            disabled={readOnly}
          />
        </Form.Item>
      )}
      <Form.Item
        className={className}
        label={formItem.label}
        key={fieldName}
        required={formItem.is_required}
        {...restProps}
      >
        <Input.TextArea name={fieldName} rows={formItem.rows || 1} disabled={readOnly} />
      </Form.Item>
    </>
  );
};
