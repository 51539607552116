import { useInjectSaga } from 'redux-injectors';
import { saga } from '../../../redux/user/profile-image';

const key = 'profilePicture';

const useInjectProfilePicture = () => {
  useInjectSaga({ key, saga });
};

export default useInjectProfilePicture;
