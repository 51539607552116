import styled from 'styled-components';
import { up } from 'styled-breakpoints';

const Wrapper = styled.div`
  flex: 0;
  padding: 0 16px;
  white-space: nowrap;

  ${up('xl')} {
    padding: 0 50px;
  }
`;

const Head = styled.div``;

const Body = styled.div`
  display: inline-block;

  ${up('xl')} {
    padding-top: 20px;
  }
`;

const OrderedList = styled.ol`
  padding-inline-start: 15px;
`;

const OrderedListItem = styled.li`
  ::marker {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    /* Dark BLue */
    color: #334d6e;
  }
`;

const Styles = {
  Wrapper,
  Head,
  Body,
  OrderedList,
  OrderedListItem,
};

export default Styles;
