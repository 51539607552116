import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Cell,
  Tooltip,
  TooltipProps,
} from 'recharts';
// models
import { Histogram as HistogramDataProps } from '@optx/models/filters';
// styles
import './styles.scss';

const COLOR_SELECTED = '#91D5FF';
const COLOR_DISABLED = '#f5f5f5';

const CustomTooltip = (props: TooltipProps) => {
  const { payload, active } = props;

  // display tooltip only for count > 0
  if (active && payload && payload[0].value > 0) {
    return (
      <div className="filter-histogram__tooltip">
        <span>
          {`Range : ${payload && payload[0].payload.min_value} - ${
            payload && payload[0].payload.max_value
          }`}
        </span>
        <br />
        <span>{`Count : ${payload && payload[0].value}`}</span>
      </div>
    );
  }

  return null;
};

// logic to change the bar colors of the histogram
const buildHistogramData = (
  list: Array<HistogramDataProps> | undefined,
  initial: number,
  final: number
) => {
  const newList: Array<HistogramDataProps> = [];

  if (list) {
    list.forEach((entry: HistogramDataProps) => {
      const newEntry = { ...entry, color: COLOR_DISABLED };
      newEntry.color =
        (entry.min_value >= initial || entry.max_value >= initial) &&
        (entry.min_value <= final || entry.max_value <= final)
          ? COLOR_SELECTED
          : COLOR_DISABLED;

      // if x length is 0, hide the bar.
      if (!entry.x) {
        newEntry.color = '#ffffff';
      }

      newList.push(newEntry);
    });
  }

  return newList;
};

export interface HistogramProps {
  histogramList?: Array<HistogramDataProps>;
  min: number;
  max: number;
}

const Histogram: React.FC<HistogramProps> = ({ histogramList, min, max }) => {
  // setting and updating initial histogram data
  const [histogramData, setHistogramData] = useState<Array<HistogramDataProps>>(
    buildHistogramData(histogramList, min, max)
  );

  // update histogram when range values are different
  useEffect(() => {
    setHistogramData(buildHistogramData(histogramList, min, max));
  }, [min, max, histogramList]);

  return (
    <div className={classnames('filter-histogram', { 'histogram-hidden': !histogramData.length })}>
      <ResponsiveContainer width="100%" height={80}>
        <BarChart
          data={histogramData}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 5,
          }}
        >
          <XAxis hide dataKey="min_value" />
          <YAxis hide dataKey="x" allowDataOverflow domain={['dataMin', 'dataMax']} scale="sqrt" />
          <Tooltip content={<CustomTooltip />} cursor={{ fill: '#f1f1f1' }} />
          <Bar dataKey="x" radius={[10, 10, 10, 10]} minPointSize={10}>
            {histogramData.map((entry: HistogramDataProps, index: number) => (
              <Cell cursor="pointer" fill={entry.color} key={`cell-${index}`} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Histogram;
