import React, { useRef } from 'react';
import { useSize } from '@umijs/hooks';

export interface IVirtualTableScrollYContext {
  height?: number;
  containerRef: React.RefObject<HTMLElement | null>;
}

const initialValue: IVirtualTableScrollYContext = {
  containerRef: React.createRef(),
};

export const VirtualTableScrollYContext =
  React.createContext<IVirtualTableScrollYContext>(initialValue);

/**
 * Keep track of one of table top parents in order to resize grid content.
 */
export const useVirtualTableContainerSize = (): IVirtualTableScrollYContext => {
  const ref = useRef<HTMLElement | null>(null);
  const [containerSize] = useSize(ref.current);

  // useLayoutEffect(() => {
  //   console.log('Container Size CHanged: ', containerSize.height);
  // }, [containerSize.height]);
  // const debouncedValue = useDebounce(containerSize.height, 500);

  return {
    containerRef: ref,
    height: containerSize.height,
  };
};
