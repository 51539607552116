import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    background-color: rgba($info, 0.2);
  }
  13% {
    background-color: rgba($info, 0.2);
  }
  15% {
    background-color: rgba($info, 0.9);
  }
  28% {
    background-color: rgba($info, 0.9);
  }
  30% {
    background-color: rgba($info, 0.2);
  }
  43% {
    background-color: rgba($info, 0.2);
  }
  45% {
    background-color: rgba($info, 0.9);
  }
  70% {
    background-color: rgba($info, 0.9);
  }
  74% {
    background-color: rgba($info, 0.2);
  }
  100% {
    background-color: rgba($info, 0.9);
  }
`;

const borderPulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgb(255, 255, 255), 0 0 0 1px rgba($info, 0.8);
  }
  40% {
    box-shadow: 0 0 0 1px rgb(255, 255, 255), 0 0 0 2px rgba($info, 0.8);
  }
  80% {
    box-shadow: 0 0 0 3px $white, 0 0 1px 3px rgba($info, 0.8);
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(1080deg);
  }
`;

const Preloader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $bg-body;
  z-index: 9999999;
`;

const Status = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
`;

const Spinner = styled.div`
  position: absolute;
  width: 78px;
  height: 78px;
  left: 50%;
  margin-left: -39px;
  margin-top: -39px;

  &:before {
    content: '';
    position: absolute;
    width: 45px;
    height: 45px;
    top: 50%;
    margin-top: -23px;
    left: 50%;
    margin-left: -23px;
    border-width: 2px 1px;
    border-style: solid;
    border-color: $info rgba($info, 0.3);
    border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    animation: ${spin} 3.45s infinite;
    -o-animation: ${spin} 3.45s infinite;
    -ms-animation: ${spin} 3.45s infinite;
    -webkit-animation: ${spin} 3.45s infinite;
    -moz-animation: ${spin} 3.45s infinite;
  }
  &:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    top: 50%;
    margin-top: -6px;
    left: 50%;
    margin-left: -6px;
    background-color: $info;
    border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    animation: ${pulse} 6.9s infinite, ${borderPulse} 6.9s infinite;
    -o-animation: ${pulse} 6.9s infinite, ${borderPulse} 6.9s infinite;
    -ms-animation: ${pulse} 6.9s infinite, ${borderPulse} 6.9s infinite;
    -webkit-animation: ${pulse} 6.9s infinite, ${borderPulse} 6.9s infinite;
    -moz-animation: ${pulse} 6.9s infinite, ${borderPulse} 6.9s infinite;
  }
`;

export default {
  Preloader,
  Status,
  Spinner,
};
