import React from 'react';
import { ChartIntervalKeys } from '@optx/models/charts/data';
import { useSelector, useDispatch } from 'react-redux';
import { useMount } from '@umijs/hooks';
import Icon from '@optx/components/common/Icon';
import { selectors as localSelectors, actions as localActions } from '../../state/g2';
import DoubleLineChart from '../DoubleLineChart';

interface SocialMediaProps {
  companyId: number;
  chartInterval: ChartIntervalKeys;
}

const G2Chart: React.FC<SocialMediaProps> = ({ companyId, chartInterval }) => {
  const dispatch = useDispatch();
  useMount(() => dispatch(localActions.fetchG2Data(companyId)));

  const chartData = useSelector(localSelectors.getData);
  const activeChartData = chartData[chartInterval];

  return (
    <DoubleLineChart
      stars={activeChartData.overall_stars as number}
      icon={<Icon iconName="g2crowd" className="chart-icon" />}
      dataKey="value"
      labelKey={chartInterval === '3M' || chartInterval === '6M' ? 'date' : 'quarter'}
      kpiLabel="Date"
      labelKeyAlt="Rating"
      secondaryDataKey="secondary_value"
      data={activeChartData}
      headerValue={activeChartData.num_of_reviews as number}
      headerLabel="# of Reviews"
      chartLabel="Rating & Number of Reviewers over time"
      title="G2 Rank"
      chartInterval={chartInterval}
    />
  );
};

export default React.memo(G2Chart);
