import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { reducers as fetchReducers } from '@redux/feature/fetch';

import * as actions from './actions';
import { CompanySubSectorState, EditCompanySubSector } from './interfaces';

export const initialState: CompanySubSectorState = {
  companyId: null,
  error: null,
  loading: false,
};

const addSubSector: CaseReducer<CompanySubSectorState, PayloadAction<EditCompanySubSector>> = (
  draftState,
  action
) => {
  const { companyId } = action.payload;
  draftState.companyId = companyId;
  draftState.loading = true;
};

const addSubSectorSuccess: CaseReducer<
  CompanySubSectorState,
  PayloadAction<EditCompanySubSector>
> = (draftState, action) => {
  draftState.loading = false;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.addSubSector, addSubSector)
    .addCase(actions.addSubSectorSuccess, addSubSectorSuccess)
    .addCase(actions.addSubSectorFail, fetchReducers.fetchFailReducer)
);

export default reducer;
