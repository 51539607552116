import React from 'react';
// models
import { ColumnType } from 'antd/lib/table';
import { CompanyUserContact } from '@optx/models/api/contacts';
// constants
import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';

// cells
import {
  TableCellActions,
  TableCellIcons,
  TableContactName,
} from '@components/common/table/TableContactsEquityTouch/cells';
import {
  TableCellDateInterval,
  TableCellCeoRating,
  TableCellDefaultTooltip,
  TableCellEmail,
  TableCellPhones,
  TableCellLinkedin,
  TableCellBoolean,
} from '@components/common/table/AntTable/cells';
import { TableCellDefault } from '../../AntTable/cells/TableCellDefault';
import { TableCellList } from '../../TableContactInformation/cells';

const columns: ColumnType<CompanyUserContact>[] = [
  {
    dataIndex: 'source_db',
    title: 'Source',
    width: 70,
    render: (value: any) =>
      !value ? <TableCellDefault value={DEFAULT_CELL_VALUE} /> : <TableCellDefault value={value} />,
    className: 'not-interactive',
  },
  {
    dataIndex: 'full_name',
    title: 'Name',
    className: 'full_name',
    width: 250,
    render: TableContactName,
  },
  {
    dataIndex: 'title',
    title: 'Title',
    className: 'title',
    width: 160,
    render: TableCellDefaultTooltip,
  },
  {
    dataIndex: 'ceo_approval',
    title: 'CEO Rank',
    className: 'rank',
    width: 80,
    render: value => TableCellCeoRating(value, '-'),
  },
  {
    dataIndex: 'tenure_start_date',
    title: 'Tenure',
    className: 'tenure',
    width: 170,
    render: TableCellDateInterval,
  },
  {
    dataIndex: 'email',
    title: 'Email',
    width: 100,
    render: TableCellEmail,
  },
  {
    dataIndex: 'additional_phones',
    title: 'Phones',
    width: 100,
    render: TableCellPhones,
    className: 'not-interactive',
  },
  {
    dataIndex: 'linkedin',
    title: 'LinkedIn Hyperlink',
    width: 100,
    render: TableCellLinkedin,
  },
  {
    dataIndex: 'psg_function',
    title: 'PSG Function',
    width: 100,
    ellipsis: false,
    render: (value: any, record: any, index: number) => (
      <TableCellList value={value} record={record} />
    ),
    className: 'not-interactive',
  },
  {
    dataIndex: 'slack_channel',
    title: 'Slack Channel',
    width: 100,
    ellipsis: false,
    render: (value: any, record: any, index: number) => (
      <TableCellList value={value} record={record} />
    ),
    className: 'not-interactive',
  },
  {
    dataIndex: 'portfolio_executive',
    title: 'Portfolio Executive',
    width: 100,
    render: TableCellBoolean,
    className: 'not-interactive',
  },
  {
    dataIndex: 'sources',
    title: 'Sources',
    className: 'sources',
    width: 180,
    render: TableCellIcons,
  },
  {
    dataIndex: 'actions',
    title: '',
    className: 'row_actions sticky sticky-right sources',
    width: 45,
    render: TableCellActions,
  },
];

export default columns;
