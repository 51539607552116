import { createSelector } from 'reselect';
import { SocialMediaState } from '../interfaces';
import { featureName } from '../../featureName';

const getSocialMediaState = (state: Record<typeof featureName, SocialMediaState>) =>
  state[featureName];

export const getUSOnlyData = createSelector(
  getSocialMediaState,
  state => state.websiteRankData.data.us
);
export const getData = createSelector(
  getSocialMediaState,
  state => state.websiteRankData.data.nonUs
);
export const loading = createSelector(getSocialMediaState, state => state.websiteRankData.loading);
