import React from 'react';
import { useSelector } from 'react-redux';
// redux
import { loadingHistograms } from '@redux/my-companies/search/selectors';
// components
import FixedLoader from '@components/common/loader';

const HistogramLoader = () => {
  const loading = useSelector(loadingHistograms);

  return <FixedLoader loading={loading} />;
};

export default React.memo(HistogramLoader);
