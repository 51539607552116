import { createFeatureActionType } from '@optx/utils/feature';
import { featureName } from '../../featureName';

const featureSlice = `${featureName} highlights`;

// ET Merge History
export const ET_MERGE_HISTORY_FETCH = createFeatureActionType(
  featureSlice,
  'et-merge-history fetch'
);
export const ET_MERGE_HISTORY_SUCCESS = createFeatureActionType(
  featureSlice,
  'et-merge-history fetch success'
);
export const ET_MERGE_HISTORY_FAIL = createFeatureActionType(
  featureSlice,
  'et-merge-history fetch fail'
);

// ET Alltime Highlights
export const ET_ALLTIME_HIGHLIGHTS_FETCH = createFeatureActionType(
  featureSlice,
  'et-alltime-highlights fetch'
);
export const ET_ALLTIME_HIGHLIGHTS_SUCCESS = createFeatureActionType(
  featureSlice,
  'et-alltime-highlights fetch success'
);
export const ET_ALLTIME_HIGHLIGHTS_FAIL = createFeatureActionType(
  featureSlice,
  'et-alltime-highlights fetch fail'
);

// Time Spent Highlights
export const TIME_SPENT_FETCH = createFeatureActionType(featureSlice, 'time-spent fetch');
export const TIME_SPENT_SUCCESS = createFeatureActionType(featureSlice, 'time-spent fetch success');
export const TIME_SPENT_FAIL = createFeatureActionType(featureSlice, 'time-spent fetch fail');
