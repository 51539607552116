import { createSelector } from 'reselect';
import { selectors as contactsSearchSelectors } from '../../../redux/contacts/search/search';
import { selectors as saveSearchSelectors } from '../../../redux/contacts/saved-searches';
import { selectors as filtersSelectors } from '../../../redux/contacts/filters';

export const isLoading = createSelector(
  contactsSearchSelectors.isLoading,
  filtersSelectors.isLoading,
  saveSearchSelectors.isLoading,
  (loadingSearch, loadingFilters, loadingSaveSearches) =>
    loadingSearch || loadingFilters || loadingSaveSearches
);
