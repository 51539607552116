import styled from 'styled-components';

const Wrapper = styled.span`
  &,
  & span {
    display: flex;
    justify-content: flex-end;
    margin-right: 8px;
  }
`;

export const Styled = {
  Wrapper,
};
