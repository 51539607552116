import { Dictionary } from 'lodash';

export const ConvertFromAPIToText: Dictionary<string> = {
  // Recent tocuh
  '6_weeks_to_6_months_ago': 'OVER 6 WKS',
  more_than_6_months_ago: 'OVER 6 MONTHS',
  no_touches: 'NEVER TOUCHED',
  // Future touch
  in_6_weeks_to_6_months: 'OVER 6 WKS',
  in_more_than_6_months: 'OVER 6 MONTHS',
  within_6_weeks: 'WITHIN 6 WKS',
  '8_14_days_away': '8 TO 14 DAYS AWAY',
  past_due: 'PAST DUE',
  next_30_days: 'NEXT 30 DAYS',
  next_7_days: 'NEXT 7 DAYS',
};

export const cardTitles: Dictionary<string> = {
  card1: 'Without a Recent Touch',
  card2: 'With a Scheduled Touch',
  card3: 'My Favorites Scheduled Touches',
  card4: 'Scheduled Touches in the Next 30 Days',
};
