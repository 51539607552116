import { CaseReducer, PayloadAction, createReducer } from '@reduxjs/toolkit';
// models
import { SearchViewType, UserInformation } from '@optx/models/user';
import { GridPayloadMain } from '@optx/models/grid';
// constants
import { GRID_KEYS_SEARCH, VIEW_TYPE_INITIAL_STATE } from '@optx/constants/grid';
// utils
import { generateGridReducerInitialState } from '@optx/utils/grid';
// redux
import * as actions from './actions';
import { ViewTypeState } from './interface';
import { actions as userInformationActions } from '@redux/user/information';

const initialState: ViewTypeState = generateGridReducerInitialState(
  GRID_KEYS_SEARCH,
  VIEW_TYPE_INITIAL_STATE
);

export const toggleSearchViewSuccessReducer: CaseReducer<
  ViewTypeState,
  PayloadAction<GridPayloadMain<SearchViewType>>
> = (draftState, action) => {
  const { data, gridKey } = action.payload;
  draftState[gridKey].searchView = data;
};

export const changedCompanyCardReducer: CaseReducer<
  ViewTypeState,
  PayloadAction<GridPayloadMain<boolean>>
> = (draftState, action) => {
  const { data, gridKey } = action.payload;
  draftState[gridKey].changedCompanyCard = data;
};

const fetchUserInformationSuccessReducer: CaseReducer<
  ViewTypeState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const sessionSettings = action.payload.settings.session_settings;

  // advanced search
  if (sessionSettings.search_view_home) {
    const searchView: SearchViewType = sessionSettings.search_view_home
      ? sessionSettings.search_view_home
      : 'table';
    draftState.advancedSearch.searchView = searchView;
  }

  // my companies
  if (sessionSettings.search_view_my_companies) {
    const searchView: SearchViewType = sessionSettings.search_view_my_companies
      ? sessionSettings.search_view_my_companies
      : 'table';
    draftState.myCompanies.searchView = searchView;
  }

  // watchlist & sslist
  if (sessionSettings.search_view_list) {
    const searchView: SearchViewType = sessionSettings.search_view_list
      ? sessionSettings.search_view_list
      : 'table';
    draftState.lists.searchView = searchView;
  }

  // outreach
  if (sessionSettings.sourcing_outreach_view_home) {
    const searchView: SearchViewType = sessionSettings.sourcing_outreach_view_home
      ? sessionSettings.sourcing_outreach_view_home
      : 'table';
    draftState.outreach.searchView = searchView;
  }
};

const reducer = createReducer<ViewTypeState>(initialState, builder =>
  builder
    .addCase(actions.toggleSearchViewSuccess, toggleSearchViewSuccessReducer)
    .addCase(actions.changedCompanyCard, changedCompanyCardReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
);

export default reducer;
