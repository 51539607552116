import { Select } from 'antd';
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as sortActions from '@redux/company-outreach/sort/actions';
import { selectors as searchSelectors } from '@redux/company-outreach/search';
import { selectors as viewTypeSelectors } from '@features/grid/view-type';
import styled from 'styled-components';
import {
  mapOptionsToLabeledValues,
  PrefixedSelectionItem,
} from '@optx/components/common/select/Select/PrefixedSelectionItem';
import { SelectOption } from '@optx/models/Option';
import { SelectProps, SelectValue } from 'antd/lib/select';

const StyledLabel = styled(PrefixedSelectionItem)`
  .prefixed-selection-item__label {
    color: #1890ff;
  }
`;

const StyledOption = styled(Select.Option)`
  .prefixed-selection-item__label {
    color: rgba(0, 0, 0, 0.65);
  }
`;

const CardViewSelect = ({ className, ...restProps }: SelectProps<SelectValue>) => {
  return <Select dropdownClassName={className} {...restProps} />;
};

const StyledSelect = styled(CardViewSelect)`
  .prefixed-selection-item__label {
    color: rgba(0, 0, 0, 0.65);
  }
`;

const touchTypes: Array<SelectOption> = [
  { label: 'Highest OPTX Score', value: 'score' },
  { label: 'Top Raised', value: 'capital_raised' },
  { label: 'Number of Employees', value: 'num_employees' },
  { label: 'Employee Growth % Y/Y', value: 'emp_growth_percent' },
];

const typesOptions = mapOptionsToLabeledValues('Sort by: ', touchTypes, StyledLabel);

const sortingValues = ['score', 'capital_raised', 'num_employees', 'emp_growth_percent'];

const CardPannelSorting = () => {
  const dispatch = useDispatch();
  const sorting = useSelector(searchSelectors.getSorting);
  const viewType = useSelector(viewTypeSelectors.searchView('outreach'));

  const [value, setValue] = useState<string>(sorting[0]?.id);

  useEffect(() => {
    if (sorting.length && sorting[0].id !== value) {
      setValue(sorting[0].id);
    }

    if (viewType === 'long-card') {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  const changeSorting = (value: SelectValue) => {
    dispatch(sortActions.changeSortAction({ sortBy: [{ id: value as string, desc: true }] }));
    setValue(value as string);
  };

  const selectValue = useMemo(
    () => (sortingValues.includes(value) ? value : 'Sort by: Choose Sorting'),
    [value]
  );

  return (
    <StyledSelect
      onChange={changeSorting}
      bordered={false}
      value={selectValue}
      dropdownMatchSelectWidth={false}
    >
      {typesOptions.map(option => (
        <StyledOption key={option.value} value={option.value}>
          {option.label}
        </StyledOption>
      ))}
    </StyledSelect>
  );
};

export default React.memo(CardPannelSorting);
