import queryString from 'query-string';
import { GridKeys, GridKeysMain, GridPayload } from '@optx/models/grid';
import { ReferrerUrlParam, RouteAliases } from '@models/routes';
import { getRouteAlias } from './routes';
import { FilterSearchOriginName } from '@models/filters';

export const generateGridReducerInitialState = (gridKeys: GridKeys[], initialState: any) => {
  // workaround to get past typescript error
  // Type '{}' is missing the following properties from type 'PaginationState':
  // advancedSearch, watchlists, myCompanies, addons, outreach
  const gridList = gridKeys.map(key => [key, initialState]);

  return Object.fromEntries(gridList);
};

/**
 * Checks if the given payload is a GridPayload.
 * @template T The type of the payload data.
 * @param payload The payload to check.
 * @returns True if the payload is a GridPayload, false otherwise.
 */
export const isGridPayload = <T>(payload: any): payload is GridPayload<T> => {
  return payload.gridKey !== undefined;
};

/**
 * getLocationGridKey
 * @description Returns the grid key based on the route alias
 * @param {RouteAliases} [alias] - The route alias. If empty, function checks for window's location instead
 * @returns {GridKeys | ''} The grid key or an empty string
 */
export const getLocationGridKey = (alias?: RouteAliases | null) => {
  const routeAlias = alias ? alias : getRouteAlias();

  let gridKey: GridKeys | '';

  switch (routeAlias) {
    case 'companyOutReach':
      gridKey = 'outreach';
      break;

    case 'userLists':
      gridKey = 'watchlists';
      break;

    case 'sourceScrubLists':
      gridKey = 'sslists';
      break;

    case 'companyAddonManagement':
      gridKey = 'addons';
      break;

    case 'advancedSearch':
    case 'myCompanies':
    case 'contacts':
      gridKey = routeAlias;
      break;

    default:
      gridKey = '';
      break;
  }

  return gridKey;
};

/**
 * getLocationMainGridKey
 * @description Returns the main grid key based on the route alias
 * @param {RouteAliases} [alias] - The route alias. If empty, function checks for window's location instead
 * @returns {GridKeysMain | ''} The grid key or an empty string
 */
export const getLocationMainGridKey = (
  alias?: RouteAliases | null,
  companyId?: number | number[]
) => {
  const routeAlias = alias ? alias : getRouteAlias(companyId);

  let gridKeyMain: GridKeysMain | '';

  switch (routeAlias) {
    case 'sourceScrubLists':
    case 'userLists':
    case 'watchList':
      gridKeyMain = 'lists';
      break;

    case 'advancedSearch':
    case 'myCompanies':
      gridKeyMain = routeAlias;
      break;

    case 'companyOutReach':
      gridKeyMain = 'outreach';
      break;

    case 'companyAddonManagement':
    case 'companyProfile':
      gridKeyMain = 'addons';
      break;

    default:
      gridKeyMain = '';
      break;
  }

  return gridKeyMain;
};

/**
 * getSearchOriginKeyFromGridKey
 * Returns the search origin key from the given grid key
 * @param {GridKeys} gridKey - The grid key to get the search origin key from
 * @returns {FilterSearchOriginName} - The search origin key
 */
export const getSearchOriginKeyFromGridKey = (gridKey: GridKeys) => {
  let searchOriginKey: FilterSearchOriginName = 'advanced_search';

  switch (gridKey) {
    case 'advancedSearch':
      searchOriginKey = 'advanced_search';
      break;

    case 'sslists':
      searchOriginKey = 'source_scrub_list';
      break;

    case 'watchlists':
      searchOriginKey = 'my_watchlist';
      break;

    case 'myCompanies':
    case 'outreach':
      searchOriginKey = 'my_companies';
      break;

    default:
      break;
  }

  return searchOriginKey;
};

/**
 * Retrieves the grid key from the referral URL parameter.
 * @returns The grid key based on the referral URL parameter.
 */
export const getGridKeyFromReferral = () => {
  let gridKey: GridKeys | null = null;

  const searchParams: ReferrerUrlParam = queryString.parse(window.location.search);

  switch (searchParams.referrer) {
    case '/':
    case '/search':
      gridKey = 'advancedSearch';
      break;

    case '/ss-lists':
      gridKey = 'sslists';
      break;

    case '/user-lists':
      gridKey = 'watchlists';
      break;

    case '/my-companies':
      gridKey = 'myCompanies';
      break;

    default:
      gridKey = getLocationGridKey() as GridKeys;
  }

  return gridKey;
};
