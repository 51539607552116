import React from 'react';
import { useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
// redux
import { selectors as salesloftSelectors } from '@redux/contacts/salesloft';

interface AddContactBtnProps {
  setVisible: (visible: boolean) => void;
}

const AddContactBtn: React.FC<AddContactBtnProps> = ({ setVisible }) => {
  const postData = useSelector(salesloftSelectors.getCadences);

  return (
    <button
      disabled={postData?.length === 0}
      type="button"
      className="change-contact-cell"
      onClick={() => {
        setVisible(true);
      }}
    >
      <span>Add Contact</span> <PlusOutlined />
    </button>
  );
};

export default AddContactBtn;
