import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { useField } from 'formik';
import { Form, DatePicker } from 'formik-antd';
import { Col, Typography, Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
// models
import { BaseField } from '@optx/models/companyFields';
// constants
import globalConfig from '@optx/constants/config';
import { ISO_DATE_FORMAT } from '@constants/format/date';
// components
import Styled from './DateRangeField.styled';

interface DatepickerFieldProps {
  field: BaseField;
}

const loadValue = (dateValue: string | string[] | null, dateformat: string) => {
  if (dateValue && typeof dateValue === 'string') {
    const date = moment(dateValue).format(dateformat);
    if (date.toLocaleLowerCase() === 'invalid date') return [null];

    return [moment(dateValue)];
  }

  if (Array.isArray(dateValue) && dateValue.length !== 0) {
    const dates = dateValue
      .filter(date => moment(date).format(dateformat).toLocaleLowerCase() !== 'invalid date')
      .map(date => moment(date));

    return dates;
  }

  return [null];
};

const DateRangeield: React.FC<DatepickerFieldProps> = ({ field }) => {
  const [selectedDate, , helpersDate] = useField<string[] | null>(field?.id || '');
  const [displayedDates, setDisplayedDates] = useState<Array<Moment | null>>([]);

  function handleDateChange(date: moment.Moment | null, index: number) {
    const newDates = [...displayedDates];
    newDates[index] = date;
    setDisplayedDates(newDates);

    const dateArr: string[] = newDates.reduce((acc: string[], date) => {
      if (date !== null) {
        acc.push(date.format(ISO_DATE_FORMAT));
      }

      return acc;
    }, []);

    helpersDate.setValue(dateArr);
  }

  useEffect(() => {
    setDisplayedDates(loadValue(selectedDate.value, ISO_DATE_FORMAT));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  const handleAddDate = () => {
    if (displayedDates.length >= 10) return;

    setDisplayedDates(prevState => [...prevState, null]);
  };

  const handleRemoveDate = (itemIndex: number) => {
    setDisplayedDates(prevState => {
      if (prevState.length === 1) return [null];

      return [...prevState].filter((item, index) => index !== itemIndex);
    });
    const updatedValues = selectedDate.value
      ? [...selectedDate.value].filter((item, index) => index !== itemIndex)
      : null;
    helpersDate.setValue(updatedValues);
  };

  return (
    <Col span={8} className={field.id + ' date-range-filter'}>
      <Form.Item name={field.id}>
        <Styled.DatepickerWrapper>
          <Typography.Text>{field.label}</Typography.Text>
          {displayedDates.map((date, index) => (
            <Styled.DatepickerRow>
              <DatePicker
                value={date ? date : undefined}
                defaultValue={date ? date : undefined}
                onChange={(date: moment.Moment | null) => handleDateChange(date, index)}
                format={globalConfig.short_date.DATE_FORMAT}
                name={`${field.id}-${index}`}
                allowClear={false}
                getPopupContainer={trigger => trigger.parentElement!}
              />
              <CloseCircleOutlined
                className="remove_icon"
                twoToneColor="#898989"
                style={{
                  color: '#898989',
                  fontSize: 19,
                  marginTop: 7,
                  marginLeft: 10,
                }}
                onClick={() => handleRemoveDate(index)}
              />
            </Styled.DatepickerRow>
          ))}
          {displayedDates.length < 10 && displayedDates[0] !== null && (
            <Button
              onClick={handleAddDate}
              style={{ marginTop: '0px', width: '100%', color: '#1890ff' }}
            >
              Add a Date
            </Button>
          )}
        </Styled.DatepickerWrapper>
      </Form.Item>
    </Col>
  );
};

export default React.memo(DateRangeield);
