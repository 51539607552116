/**
 * List page loader selectors
 */

import { createSelector } from 'reselect';
import { selectors as filtersSelectors } from '@redux/company/filters';
import { selectors as contactsFiltersSelectors } from '@redux/contacts/filters';
import { selectors as contactsSearchesSelectors } from '@redux/contacts/saved-searches';
import { selectors as companySavedSearchSelectors } from '@redux/company/saved-searches';
import { selectors as myPipelineSelectors } from '@redux/my-pipeline';
import { selectors as listsSelectors } from '@redux/favorite-lists/lists';
import { selectors as sourceScrubSelectors } from '@redux/user/source-scrub-lists';

export const isLoading = createSelector(
  filtersSelectors.isLoading,
  contactsFiltersSelectors.isLoading,
  contactsSearchesSelectors.isLoading,
  companySavedSearchSelectors.isLoading,
  myPipelineSelectors.isLoading,
  listsSelectors.isLoading,
  sourceScrubSelectors.isLoading,
  (
    loadingFilters,
    loadingContactsFilters,
    loadingContactsSearches,
    loadingCompanySavedSearches,
    loadingMyPipeline,
    loadingListsTable,
    loadingSourceScrubTable
  ) =>
    loadingFilters ||
    loadingContactsFilters ||
    loadingCompanySavedSearches ||
    loadingContactsSearches ||
    loadingMyPipeline ||
    loadingListsTable ||
    loadingSourceScrubTable
);
