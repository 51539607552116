import { combineReducers } from 'redux';
import { NotesState } from './interfaces';
import searchReducer from './search';
import companyNotes from './companyNotes';
import profileNotes from './profile';

const notesReducer = combineReducers<NotesState>({
  search: searchReducer,
  companyNotes,
  profileNotes,
});

export default notesReducer;
