import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import * as actions from './actions';
import { UserService } from '../../../services/api';
import NotificationService from '../../../services/NotificationService';
import { UserListPageState } from './interfaces';

export function* fetchUserListSaga(action: PayloadAction<boolean>) {
  const isShare = action.payload;
  let query;

  if (!isShare) {
    query = {
      role: 1,
      status: 'Access,Invitation Sent',
    };
  } else {
    query = {
      status: 'Access,Invitation Sent',
    };
  }

  try {
    const res: AxiosResponse<UserListPageState[]> = yield call(UserService.fetchUserList, query);

    if (res.data) {
      if (!isShare) {
        yield put(actions.fetchUserListFieldSuccess(res.data));
      } else {
        yield put(actions.fetchUserListShareSuccess(res.data));
      }
    } else {
      const errorMessage = 'Failed to fetch user list!';

      if (!isShare) {
        yield put(actions.fetchUserListFieldFail(errorMessage));
      } else {
        yield put(actions.fetchUserListShareFail(errorMessage));
      }

      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = 'Failed to fetch user list!';

    if (!isShare) {
      yield put(actions.fetchUserListFieldFail(errorMessage));
    } else {
      yield put(actions.fetchUserListShareFail(errorMessage));
    }

    NotificationService.error(errorMessage);
  }

  const customQuery = {
    status: 'Access,Invitation Sent',
  };

  try {
    const res: AxiosResponse<UserListPageState[]> = yield call(
      UserService.fetchUserList,
      customQuery,
      true
    );

    if (res.data) {
      yield put(actions.fetchPsgUserListFieldSuccess(res.data));
    }
  } catch (error: any) {
    const errorMessage = 'Failed to fetch user list!';
    yield put(actions.fetchPsgUserListFieldFail(errorMessage));

    NotificationService.error(errorMessage);
  }
}

export default function* userListSaga() {
  yield takeLatest(actions.fetchUserList.type, fetchUserListSaga);
}
