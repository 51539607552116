import React from 'react';
import ErrorComponent from '@optx/components/common/ErrorComponent';
import { useDispatch } from 'react-redux';
import { actions as companyProfileActions } from '../../../redux/company/profile';

const CompanyProfileErrorComponent = () => {
  const dispatch = useDispatch();

  const resetError = () => dispatch(companyProfileActions.fetchCompanyProfileFail(''));

  return <ErrorComponent onRedirect={resetError} />;
};

export default CompanyProfileErrorComponent;
