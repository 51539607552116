import React from 'react';
import { useLocation } from 'react-router-dom';
// constants
import APP_ROUTES from '@constants/routes';
// components
import WatchListDropdownImport from '@features/list-import/components/WatchListDropdownImport';

const TabsExtraContent = () => {
  const location = useLocation();
  let watchlistElement = null;

  if (location.pathname === APP_ROUTES.lists.myWatchList) {
    watchlistElement = <WatchListDropdownImport />;
  }

  return watchlistElement;
};

export default TabsExtraContent;
