import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// models
import { SalesloftBulkResults, SalesloftBulkContactResponse } from '@models/bulkActions';
// redux
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { actions as salesloftBulkActions } from '@features/bulk-actions/salesloft';
// components
import { SelectContacts } from '@optx/features/bulk-actions/salesloft/components/SelectContacts';
import { Button, Row, Col, Tooltip } from 'antd';
import Actions from './Actions';

export const TableCellActionsSalesloft = (value: string, record: SalesloftBulkResults) => {
  const dispatch = useDispatch();

  const companies = useSelector(bulkActionsSelectors.salesloft.salesloftBulkCompanies);
  const { company_id: companyId, contacts } = record;
  const company = companies.find(company => company.company_id === companyId);

  const sentContacts = company?.salesloft_contacts as SalesloftBulkContactResponse[];

  if (
    contacts.every(contact => contact.sl_sync_status) ||
    contacts.every(contact => contact.sl_message === 'Person is a member of this cadence')
  ) {
    return null;
  }

  const handleSubmit = () => {
    dispatch(
      salesloftBulkActions.resolveSalesloft({
        companyId,
        all: true,
      })
    );
  };

  if (contacts[0].sl_message === 'No contacts available' && company?.contacts.length) {
    return (
      <Row justify="space-between" gutter={[5, 0]}>
        <Col style={{ width: 'calc(100% - 165px)' }}>
          <SelectContacts contacts={company!.contacts} companyId={companyId} />
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <Tooltip title={!sentContacts.length ? 'Select contact(s)' : ''}>
            <Button type="primary" disabled={!sentContacts.length} onClick={handleSubmit}>
              Add To SL Cadence
            </Button>
          </Tooltip>
        </Col>
      </Row>
    );
  }

  const handleResolve = () => {
    dispatch(
      salesloftBulkActions.resolveSalesloft({
        companyId,
      })
    );
  };

  return (
    <Actions
      companyId={record.company_id}
      companyName={record.company_name}
      url={record.company_website as string}
      contactsResponse={contacts}
      sentContacts={sentContacts}
      onResolve={handleResolve}
    />
  );
};

export default TableCellActionsSalesloft;
