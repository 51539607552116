import { createSelector } from 'reselect';
// utils
import { makeUnique } from '@optx/utils/helpers';
import { addToUniqueYears } from '@optx/utils/dealPresentation';
// models
import { AppState } from '@optx/redux/interfaces';
import { AllFinancialYearsType } from '@optx/models/Financial';
import { FinancialFieldRangeValue } from '@optx/models/Company';
// redux
import { selectors as fetchSelectors } from '@redux/feature/fetch';

// specific selectors
const selectOpportunityPresentationState = (state: AppState) => state.opportunityPresentation;

export const isLoading = createSelector(
  selectOpportunityPresentationState,
  fetchSelectors.isLoadingSelector
);

export const isAddYearsOpenModal = createSelector(
  selectOpportunityPresentationState,
  state => state.isAddYearsOpenModal
);

export const getOpportunityPresentation = createSelector(
  selectOpportunityPresentationState,
  state => state.data
);

export const canFetch = createSelector(
  selectOpportunityPresentationState,
  state => !state.loading && !state.fetchedAt
);

export const getDealPreparationValue = createSelector(selectOpportunityPresentationState, state => {
  if (state.data?.deal_preparation) {
    const {
      asking_amount: askingAmount,
      deal_type: dealType,
      banker,
      banker_name: bankerName,
      next_steps: nextSteps,
      transaction_notes: transactionNotes,
      next_steps_opinion: nextStepsOpinion,
    } = state.data.deal_preparation;

    return {
      asking_amount: askingAmount,
      deal_type: dealType,
      banker,
      transaction_notes: transactionNotes,
      banker_name: bankerName,
      next_steps: nextSteps,
      next_steps_opinion: nextStepsOpinion,
    };
  }

  return {};
});

// temporary selectors as we have filters in 2.8 version
export const getDealTypeOptions = createSelector(selectOpportunityPresentationState, state => {
  if (state.data?.deal_preparation?.deal_type) {
    return state.data.deal_preparation.deal_type.data.map(item => ({
      value: item.value,
      label: item.label,
    }));
  }

  return [
    { label: 'Majority', value: 'Majority' },
    {
      label: 'Minority',
      value: 'Minority',
    },
    { label: 'Flexible', value: 'Flexible' },
  ];
});

export const getFinancialsData = createSelector(selectOpportunityPresentationState, state => {
  return state.data?.financial;
});

export const getCustomerKpisData = createSelector(selectOpportunityPresentationState, state => {
  return state.data?.customer_kpis;
});

export const getFundingOwnershipData = createSelector(selectOpportunityPresentationState, state => {
  return state.data?.funding;
});

export const getTechnologyData = createSelector(selectOpportunityPresentationState, state => {
  return state.data?.technology;
});

export const getCurrentEditableYear = createSelector(selectOpportunityPresentationState, state => {
  return state.editableYear;
});

export const getAllFinancialYears = createSelector(getFinancialsData, financial => {
  // first element will has lengf elements
  const data: AllFinancialYearsType[] = [];

  // Process received data arrays and add them to the initial data
  financial?.revenue?.forEach(item =>
    addToUniqueYears(data, item.year as number, item.estimated as boolean)
  );
  financial?.annual_recurring_revenue?.forEach(item =>
    addToUniqueYears(data, item.year as number, item.estimated as boolean)
  );
  financial?.ebitda?.forEach(item =>
    addToUniqueYears(data, item.year as number, item.estimated as boolean)
  );
  financial?.gm?.forEach(item =>
    addToUniqueYears(data, item.year as number, item.estimated as boolean)
  );

  // Get the current year
  const currentYear = new Date().getFullYear();

  // Check if there is no data for the current year; if not, add the default current year
  if (
    !financial?.revenue?.some(item => item.year === currentYear) &&
    !financial?.annual_recurring_revenue?.some(item => item.year === currentYear) &&
    !financial?.ebitda?.some(item => item.year === currentYear) &&
    !financial?.gm?.some(item => item.year === currentYear)
  ) {
    addToUniqueYears(data, currentYear, true); // Current year with estimated set to true
  }

  // Check if there is no data for the previous year; if not, add the default previous year
  if (
    !financial?.revenue?.some(item => item.year === currentYear - 1) &&
    !financial?.annual_recurring_revenue?.some(item => item.year === currentYear - 1) &&
    !financial?.ebitda?.some(item => item.year === currentYear - 1) &&
    !financial?.gm?.some(item => item.year === currentYear - 1)
  ) {
    addToUniqueYears(data, currentYear - 1, false); // Previous year with estimated set to false
  }

  // Check if there is no data for the previous -1 year; if not, add the default previous -1 year
  if (
    !financial?.revenue?.some(item => item.year === currentYear - 2) &&
    !financial?.annual_recurring_revenue?.some(item => item.year === currentYear - 2) &&
    !financial?.ebitda?.some(item => item.year === currentYear - 2) &&
    !financial?.gm?.some(item => item.year === currentYear - 2)
  ) {
    addToUniqueYears(data, currentYear - 2, false); // Previous year with estimated set to false
  }

  // Check if there is no data for the future year; if not, add the default future year
  if (
    !financial?.revenue?.some(item => item.year === currentYear + 1) &&
    !financial?.annual_recurring_revenue?.some(item => item.year === currentYear + 1) &&
    !financial?.ebitda?.some(item => item.year === currentYear + 1) &&
    !financial?.gm?.some(item => item.year === currentYear + 1)
  ) {
    addToUniqueYears(data, currentYear + 1, true); // Future year with estimated set to true
  }

  return makeUnique(data, ['year', 'estimated']).sort((a: any, b: any) => b.year - a.year);
});

export const getCurrentFinancialYearInfo = createSelector(
  getFinancialsData,
  getAllFinancialYears,
  getCurrentEditableYear,
  (state, financialYears, currentEditableYear) => {
    const initialData: {
      year: number | string | null;
      estimated: boolean | null;
      arr: number | string | FinancialFieldRangeValue;
      revenue: number | string | FinancialFieldRangeValue;
      ebitda: number | string | FinancialFieldRangeValue;
      gm: number | string | FinancialFieldRangeValue;
    } = {
      year: currentEditableYear || new Date().getFullYear(),
      estimated: false,
      arr: '',
      revenue: '',
      ebitda: '',
      gm: '',
    };

    const currentRevenueValue = state?.revenue?.find(
      revenue => revenue.year === currentEditableYear
    )?.value;
    const currentEbitdaValue = state?.ebitda?.find(
      ebitda => ebitda.year === currentEditableYear
    )?.value;
    const currentGmValue = state?.gm?.find(gm => gm.year === currentEditableYear)?.value;
    const currentArrValue = state?.annual_recurring_revenue?.find(
      arr => arr.year === currentEditableYear
    )?.value;
    const currentEstimatedYear = financialYears?.find(
      (financialYear: { year: number | null }) => financialYear.year === currentEditableYear
    )?.estimated;

    if (currentRevenueValue) {
      initialData.revenue = currentRevenueValue;
    }

    if (currentEstimatedYear !== undefined) {
      initialData.estimated = currentEstimatedYear;
    }

    if (currentGmValue) {
      initialData.gm = currentGmValue;
    }

    if (currentArrValue) {
      initialData.arr = currentArrValue;
    }

    if (currentEbitdaValue) {
      initialData.ebitda = currentEbitdaValue;
    }

    return initialData;
  }
);

export const getData = createSelector(getFinancialsData, financial => {
  type InitialData = { [x: string]: number | string | FinancialFieldRangeValue };

  const initialData: {
    arr: InitialData;
    revenue: InitialData;
    ebitda: InitialData;
    gm: InitialData;
  } = {
    arr: {},
    revenue: {},
    ebitda: {},
    gm: {},
  };

  financial?.revenue?.map(item => {
    initialData.revenue = {
      ...initialData.revenue,
      [`${item.year}_${item.estimated ? 'estimated' : 'actual'}`]: item.value || 0,
      [`${item.year}_source_info`]: item.source_info || '',
      [`${item.year}_date`]: item.date || '',
    };

    return true;
  });

  financial?.revenue_growth?.map(item => {
    initialData.revenue = {
      ...initialData.revenue,
      [`${item.year}_${item.estimated ? 'estimated' : 'actual'}_growth`]: item.value || 0,
    };

    return true;
  });

  financial?.ebitda?.map(item => {
    initialData.ebitda = {
      ...initialData.ebitda,
      [`${item.year}_${item.estimated ? 'estimated' : 'actual'}`]: item.value || 0,
      [`${item.year}_source_info`]: item.source_info || '',
      [`${item.year}_date`]: item.date || '',
    };

    return true;
  });

  financial?.ebitda_margin?.map(item => {
    initialData.ebitda = {
      ...initialData.ebitda,
      [`${item.year}_${item.estimated ? 'estimated' : 'actual'}_growth`]: item.value || 0,
    };

    return true;
  });

  financial?.annual_recurring_revenue?.map(item => {
    initialData.arr = {
      ...initialData.arr,
      [`${item.year}_${item.estimated ? 'estimated' : 'actual'}`]: item.value || 0,
      [`${item.year}_source_info`]: item.source_info || '',
      [`${item.year}_date`]: item.date || '',
    };

    return true;
  });

  financial?.annual_recurring_revenue_growth?.map(item => {
    initialData.arr = {
      ...initialData.arr,
      [`${item.year}_${item.estimated ? 'estimated' : 'actual'}_growth`]: item.value || 0,
    };

    return true;
  });

  financial?.gm?.map(item => {
    initialData.gm = {
      ...initialData.gm,
      [`${item.year}_${item.estimated ? 'estimated' : 'actual'}`]: item.value || 0,
      [`${item.year}_source_info`]: item.source_info || '',
      [`${item.year}_date`]: item.date || '',
    };

    return true;
  });

  const result = [
    {
      key: '1',
      name: 'ARR',
      ...initialData.arr,
    },
    {
      key: '2',
      name: 'Revenue',
      ...initialData.revenue,
    },
    {
      key: '3',
      name: 'GM %',
      ...initialData.gm,
    },
    {
      key: '4',
      name: 'EBITDA',
      ...initialData.ebitda,
    },
  ];

  return result;
});
