import { SearchSaveResponse } from '@optx/models/search';

/**
 * Maps tags to names and operators based on the provided parentIds, allSearches, and operators.
 *
 * @param parentIds - An array of numbers representing the parent IDs.
 * @param allSearches - An array of SearchSaveResponse objects containing all searches.
 * @param operators - An array of strings representing operators.
 * @returns An array of strings containing the mapped names and operators.
 */
export const mapTagsToNamesAndOperators = (
  parentIds: number[] | undefined,
  allSearches: SearchSaveResponse[],
  operators: string[] | undefined
): string[] => {
  const parentSearches = parentIds?.map(
    id => allSearches.find(search => search.unique_id === id)?.title
  );

  if (!parentSearches || !operators) {
    return [];
  }

  return parentSearches.flatMap((parentSearch, i) => {
    if (operators[i] !== undefined) {
      return [parentSearch, operators[i]] as string[];
    } else {
      return [parentSearch] as string[];
    }
  });
};
