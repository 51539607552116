import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
// constants
import APP_ROUTES from '@constants/routes';
// redux
import {
  getIsAnalyst,
  getHasAccessToPipelineReport,
  getHasAccessToSourcingOutreachSummary,
  getHasAccessToChecklistResearchInsightsReport,
} from '@optx/redux/user/information/selectors';
// components;
import EquityTouchIntegration from '@optx/screens/App/EquityTouchIntegration';
import PipelineReport from '@optx/screens/App/PipelineReport';
import ChecklistResearchInsights from '@optx/screens/App/ChecklistResearchInsights/ChecklistResearchInsights';
import AnalystsLeaderboard from '@optx/components/pages/AnalystsLeaderboard';
import SourcingOutreachSummary from '@optx/screens/App/SourcingOutreachSummary';
import { ReactComponent as LogoIcon } from '../../../../../assets/svg/icons/logo.svg';
// styles
import Styled from './ReportCards.styled';

interface ReportCardProps {
  name: string;
  content: React.ReactNode;
  className?: string;
  showUniversalSearchBar?: boolean;
  path: string;
  isAnalyst?: boolean;
  isVisible?: boolean;
  description: string;
}

const getCardVisibility = (card: ReportCardProps) => {
  return !Object.keys(card).includes('isVisible') || card.isVisible;
};

const ReportCards: React.FC = () => {
  const isAnalyst = useSelector(getIsAnalyst);
  const showPipelineReport = useSelector(getHasAccessToPipelineReport);
  const showSourcingOutreachReport = useSelector(getHasAccessToSourcingOutreachSummary);
  const showChecklistResearchInsightsReport = useSelector(
    getHasAccessToChecklistResearchInsightsReport
  );

  const reportCards: Array<ReportCardProps> = [
    {
      name: 'Sourcing Team Stats',
      content: AnalystsLeaderboard,
      path: APP_ROUTES.reports.sourcingAnalystsLeaderboard,
      description: 'Dynamic Dashboard for Sourcing team activities',
    },
    {
      name: 'Bi-Weekly Pipeline Report',
      content: PipelineReport,
      path: APP_ROUTES.reports.pipelineReport,
      description: 'Snapshot of all Active Companies',
      isVisible: showPipelineReport,
    },
    {
      name: 'Equity Touch Integration',
      content: EquityTouchIntegration,
      path: APP_ROUTES.reports.equityTouchIntegration,
      isAnalyst,
      description: 'Details of Companies added to EquityTouch',
    },
    {
      name: 'Checklist Research Insights',
      content: ChecklistResearchInsights,
      path: APP_ROUTES.reports.checklistResearchInsights,
      description: 'Details of the results of our User Feedback',
      isVisible: showChecklistResearchInsightsReport,
    },
    {
      name: 'Sourcing Outreach Summary',
      content: SourcingOutreachSummary,
      path: APP_ROUTES.reports.sourcingOutreachSummary,
      isVisible: showSourcingOutreachReport,
      description: "Dynamic view of Sourcing KPI's",
    },
  ];

  return (
    <Row gutter={[20, 20]} className="row-spacer">
      {reportCards.map(
        (card, index) =>
          getCardVisibility(card) &&
          !card.isAnalyst && (
            <Col xs={24} sm={12} lg={12} xl={6} xxl={6} key={index}>
              <Link to={card.path}>
                <Styled.Card
                  hoverable
                  className="reports-card"
                  title={<LogoIcon className="reports-card__logo" />}
                  bordered={false}
                >
                  <Styled.CardTitle level={5}>{card.name}</Styled.CardTitle>
                  <Styled.CardDescription>{card.description}</Styled.CardDescription>
                </Styled.Card>
              </Link>
            </Col>
          )
      )}
    </Row>
  );
};

export default ReportCards;
