import React, { useState, useEffect } from 'react';
import { Select } from 'formik-antd';
import { useSelector } from 'react-redux';
import { useField, useFormikContext } from 'formik';
import styled from 'styled-components';
// models
import { SelectOption } from '@optx/models/Option';
import { EquityTouchDatabaseMatch } from '@models/equityTouch';
import { CompanyProfile } from '@models/Company';
// utils
import { filterOption } from '@optx/utils/select/select';
// redux
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { EquityTouchGroupsFieldContent } from '@components/pages/EquityTouch/components/EquityTouchGroupsFieldContent';

const { Option } = Select;

const StyledSelect = styled(Select)`
  .ant-select-selection-item {
    gap: 5px;
  }
`;

interface BulkEquityTouchGroupsField {
  readOnly?: boolean;
}

const BulkEquityTouchGroupsField: React.FC<BulkEquityTouchGroupsField> = ({ readOnly }) => {
  const fieldName = 'Deal Team Lead';
  const eqtData = useSelector(bulkActionsSelectors.equityTouch.getFormData);
  const companyData = useSelector(bulkActionsSelectors.equityTouch.getCompanyData);
  const companiesById = useSelector(bulkActionsSelectors.equityTouch.getCompanies);

  const [groups, setGroups] = useState<Array<EquityTouchDatabaseMatch | SelectOption>>([]);
  const [value, setValue] = useState<number[]>([]);
  const {
    // @ts-ignore
    values: { 'Deal Team Lead': dealTeamLead },
  } = useFormikContext();
  const [, , helpers] = useField('Groups');

  useEffect(() => {
    const selectedDealTeamLead = eqtData[fieldName].received_values.find(
      val => (val as EquityTouchDatabaseMatch).itemId === dealTeamLead
    );

    if (selectedDealTeamLead) {
      setGroups((selectedDealTeamLead as EquityTouchDatabaseMatch).groups!);
    } else {
      setGroups([]);
    }

    if (eqtData.Groups.from_database) {
      setValue((eqtData.Groups.from_database as string[]).map(item => Number(item)));
    } else {
      setValue([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealTeamLead, eqtData]);

  useEffect(() => {
    if (Object.keys(companyData).length) {
      const selectedGroups = companiesById?.[(companyData as CompanyProfile).company_id].Groups;

      if (Array.isArray(selectedGroups)) {
        setValue(selectedGroups as number[]);
        helpers.setValue(selectedGroups as number[]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companiesById, companyData]);

  return (
    <StyledSelect
      name="Groups"
      mode="multiple"
      defaultValue={value}
      disabled={readOnly || !(groups.length > 0)}
      placeholder="Please select Groups/Watchlists"
      filterOption={filterOption}
      showSearch
    >
      {groups.map((group, index) => (
        <Option value={(group as EquityTouchDatabaseMatch).itemId} key={index}>
          <EquityTouchGroupsFieldContent group={group as EquityTouchDatabaseMatch} />
        </Option>
      ))}
    </StyledSelect>
  );
};

export default BulkEquityTouchGroupsField;
