import React from 'react';
// redux
import Company from '@optx/models/Company';
// hooks
import useIsAdminOrAnalyst from '@optx/common/hooks/useisAdminOrAnalyst';
// components
import {
  EditFieldRadioRationale,
  EditFieldRadio,
} from '@optx/features/company/edit-fields/components';
import { UserService } from '@optx/services/api';

interface TableCellFitRationaleProps {
  value: any;
  record: Company;
}

const TableCellFitRationale: React.FC<TableCellFitRationaleProps> = ({ value, record }) =>
  useIsAdminOrAnalyst() ? (
    <EditFieldRadioRationale
      value={value}
      record={record}
      fieldName="PSG Fit"
      service={UserService.updateFitEvaluation}
      successMessage="Company PSG fit updated successfully!"
      errorMessage="Failed to update value!"
      destroyOnHide
      isFromGrid
    />
  ) : (
    <EditFieldRadio
      value={value}
      record={record}
      fieldName="PSG Fit"
      service={UserService.updateFitEvaluation}
      successMessage="Company PSG fit updated successfully!"
      errorMessage="Failed to update value!"
      isFromGrid
    />
  );
export default React.memo(TableCellFitRationale);
