import React from 'react';
import { TableCellPipelineReport } from '@optx/models/table/Cell';
import { lib as companySearchLib } from '@features/company-search';

const {
  Table: {
    columns: { CompanyNameRender },
  },
} = companySearchLib;

export const TableCellCompanyName: React.FC<TableCellPipelineReport> = ({ value, record }) => {
  return <CompanyNameRender name={value} companyId={record.company_id} />;
};
